import * as classNames from 'classnames';
import ReactSignatureCanvas from 'react-signature-canvas';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import BackspaceIcon from '@mui/icons-material/Backspace';

import { GREEN_COLOR_2, RED_COLOR } from '../../../../../../constants/colorVariables';
import GSignatureCanvas from '../../../../../common/GSignatureCanvas';
import makeGStyles from '../../../../../../styles/makeGStyles';
import StepComponentWrapper from '../StepComponentWrapper';
import { getIntercomTargetProp } from '../../../../../../services';

const useStyles = makeGStyles(theme => ({
    root: {},
    signatureContainer: {
        position: 'relative',
        color: theme.palette.secondary.main,
        border: '2px solid',
        padding: 4,
        '&$borderNone': {
            border: 'none'
        }
    },
    colorRed: {
        color: RED_COLOR
    },
    colorGreen: {
        color: GREEN_COLOR_2
    },
    signatureHeading: {
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%, -80%)',
        padding: '0 4px',
        background: theme.palette.common.white,
        lineHeight: 1.25,
        textAlign: 'center',
        userSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: '-moz-none',
        WebkitUserSelect: 'none',
        fontSize: 12,
        width: 'max-content',
        maxWidth: '90%',
        '@media (min-width: 375px)': {
            fontSize: 14,
            padding: '0 8px',
        },
    },
    signatureFooter: {
        bottom: 0,
        top: 'unset',
        transform: 'translate(-50%, 50%)',
        width: 'max-content'
    },
    clearSignBtn: {
        fontStyle: 'italic',
        textTransform: 'none',
        marginTop: 8,
        display: 'flex',
        margin: '0 auto',
        '& svg': {
            fontSize: 16
        }
    },
    signature: {
        filter: 'grayscale(1)',
        WebkitFilter: 'grayscale(1)'
    },
    borderNone: {}
}), { name: 'SignatureComponent' });

const defaultHeading =
    <>
        Sign below to verify and attest that the
        information provided is complete and accurate
    </>
    ;

interface Props {
    signerFullName: string | null;
    hasSignError: boolean;
    hasSigned: boolean;
    signatureUrl: string | null;
    heading?: JSX.Element | string;
    canvasRef: React.MutableRefObject<ReactSignatureCanvas | null>;
    onStopDrawing: () => void;
    clearSignature: () => void;
    signerStateLicenceNumber?: string;
    signatureHeadingClass?: string;
    intercomTargetProp?: string;
    intercomTargetPropClearSignature?: string;
}

const SignatureComponent = (props: Props) => {
    const {
        signerFullName,
        hasSignError,
        hasSigned,
        heading,
        signerStateLicenceNumber,
        signatureUrl,
        canvasRef,
        onStopDrawing,
        signatureHeadingClass,
        clearSignature,
        intercomTargetProp,
        intercomTargetPropClearSignature,
    } = props;

    const classes = useStyles();

    return (
        <StepComponentWrapper hideBorder intercomTargetProp={intercomTargetProp}>
            <Grid
                container={true}
                justifyContent="center"
            >
                <Grid
                    item={true}
                    xs={12}
                    className={classNames(
                        classes.signatureContainer,
                        signatureUrl && classes.borderNone,
                        hasSignError && classes.colorRed,
                        (hasSigned && !hasSignError || signatureUrl) && classes.colorGreen,
                    )}
                >
                    <Typography color="inherit" className={classNames(classes.signatureHeading, signatureHeadingClass)}>
                        {heading ?? defaultHeading}
                    </Typography>

                    {signatureUrl
                        ? <img
                            src={signatureUrl}
                            width="100%"
                            height="100%"
                            className={classNames(classes.marginAuto, classes.pointerEventsNone, classes.signature)}
                        />
                        : <GSignatureCanvas
                            canvasRef={canvasRef}
                            canvasRatio={2.5}
                            minStrokeWidth={1}
                            maxStrokeWidth={3}
                            useThemePenColor={true}
                            onStopDrawing={onStopDrawing}
                        />
                    }

                    {signerFullName &&
                        <Typography
                            color="inherit"
                            className={classNames(classes.signatureHeading, classes.signatureFooter)}
                        >
                            {signerFullName}{signerStateLicenceNumber && `, #${signerStateLicenceNumber}`}
                        </Typography>
                    }
                </Grid >

                {!signatureUrl &&
                    <Button
                        color="secondary"
                        size="small"
                        className={classes.clearSignBtn}
                        onClick={clearSignature}
                        {...getIntercomTargetProp(intercomTargetPropClearSignature)}
                    >
                        <BackspaceIcon color="inherit" />&nbsp;Clear my signature
                    </Button>
                }
            </Grid >
        </StepComponentWrapper >
    );
};

export default SignatureComponent;
