import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanNotLoginProps } from '../utils';
import CanNotLoginWrapper from '../widgets/CanNotLoginWrapper';

const CanNotLogin = (props: CanNotLoginProps) => {
    const { accountType, activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanNotLoginWrapper onReturnClick={onStepChange}>
            <Typography
                align="center"
                color="secondary"
                className={classNames(
                    classes.fontSize28,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Contact {accountType} Directly
            </Typography>
            <Typography
                color="secondary"
                align="left"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                It looks like you won't be able to log in to {activeCase.fname}'s {accountType} account. No
                worries, we can still help. You will need to contact Apple directly at 1-800-692-7743. They will
                be able to walk you through each option and give you step-by-step instructions.
            </Typography>
            <a
                href={'tel:1-800-692-7753'}
                className={classNames(
                    classes.cursorPointer,
                    classes.textDecorationNone,
                )}
            >
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classNames(
                        classes.button,
                        classes.marginTop20
                    )}
                >
                    Call Apple Directly
                </Button>
            </a>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Important. Please read.
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            If you choose to delete {activeCase.fname}'s account, you will not be able to access
                            iTunes, iBooks, and App Store purchases.
                        </li>
                        <li className={classes.marginTop10}>
                            {activeCase.fname}'s photos, videos and documents stored in iCloud will be
                            permanently deleted.
                        </li>
                        <li className={classes.marginTop10}>
                            You will not receive messages sent to {activeCase.fname}'s account via iMessage,
                            FaceTime, or iCloud Mail.
                        </li>
                        <li className={classes.marginTop10}>
                            You will not be able to sign in or use services such as iCloud, iTunes, iBooks, the
                            App Store, Apple Pay, iMessage, FaceTime, and Find My iPhone.
                        </li>
                        <li className={classes.marginTop10}>
                            {activeCase.fname}'s data associated with Apple services will be permanently deleted.
                            Some exceptions may apply.
                        </li>
                        <li className={classes.marginTop10}>
                            Deletion does not cancel any repairs or Apple Store orders.
                        </li>
                    </ul>
                </Grid>
            </Grid>
        </CanNotLoginWrapper>
    );
};

export default CanNotLogin;
