import classNames from "classnames";
import { DATA_PRIVATE } from "../../../../../styles/Helper.styles";
import makeGStyles from "../../../../../styles/makeGStyles";
import { useGSelector } from "../../../../../types";
import { ConfigurableFieldKey, isFieldHiddenFromUser } from "../../../../../shared/death_certificate/validators/config";

const useStyles = makeGStyles(theme => ({
    label: {
        fontWeight: 500,
        display: 'inline-block',
    },
    labelHtml: {
        display: 'inline-flex',
        verticalAlign: 'top',
        flex: 'flex-wrap'
    },
    innerSpan: {
        display: 'flex',
        flex: 'flex-wrap'
    },
}), { name: 'OverviewLabel' });

export enum ArraySeparator {
    comma = ', ',
    colon = '; ',
}

interface Props {
    /**
     *  NOTE: it should only be `undefined` for required fields
     */
    fieldKey: ConfigurableFieldKey | undefined;
    label: string | null;
    html: string | Array<string> | null;
    alwaysVisible?: boolean;
    skipTextTransformation?: boolean;
    privateData?: boolean;
    arraySeparator?: ArraySeparator;
}
const OverviewLabel = (props: Props) => {
    const { label, html, skipTextTransformation, privateData, arraySeparator, alwaysVisible, fieldKey } = props;

    const classes = useStyles();

    const isVisible = useGSelector(({
        userSession: { userData },
        deathCertificateConfigState: { activeDeathCertificateConfig }
    }) => {
        if (!fieldKey) {
            return true;
        }

        const field = activeDeathCertificateConfig?.revision.fields.find((f) => f.ui_field_key === fieldKey);
        const isEnabled = Boolean(field);
        const isHiddenFromUser = field && userData ? isFieldHiddenFromUser(field, userData) : false;

        return isEnabled && !isHiddenFromUser;
    });

    if (!isVisible && !html) {
        return null;
    }

    // Add the DATA_PRIVATE class to elements that contain private data
    const spanClassNames = classNames({ [classes.innerSpan]: true, [DATA_PRIVATE]: !!privateData });
    let renderedHtml: Array<JSX.Element> = [];

    if (!html && !alwaysVisible) {
        return null;
    } else if (typeof html === 'string') {
        renderedHtml = [
            <span
                key={`${html}_${0}`}
                className={spanClassNames}
                style={{
                    display: 'inline',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {html}
            </span>
        ];
    } else if (Array.isArray(html) && html.length > 0) {
        renderedHtml = html.map((htmlString: string, index: number, htmlArray: string[]) => (
            <span
                className={spanClassNames}
                style={{
                    display: 'inline',
                    whiteSpace: 'pre-wrap',
                }}
                key={`${htmlString}_${index}`}
            >
                {htmlString}{htmlArray.length > 1 && index < htmlArray.length - 1 ? arraySeparator : ''}
            </span>
        ));
    } else if (Array.isArray(html) && html.length === 0 && !alwaysVisible) {
        return null;
    }

    return (
        <span className={classes.displayBlock} style={{ display: 'block' }}>
            {label && <span
                className={classes.label}
                style={{
                    fontWeight: 700,
                    display: 'inline-block',
                    textTransform: 'capitalize',
                }}
            >
                {label}:&nbsp;
            </span>}
            <span
                className={classNames(
                    !html || skipTextTransformation ? classes.textTransformNone : '',
                    classes.labelHtml
                )}
                style={{
                    display: 'inline',
                    verticalAlign: 'top',
                    textTransform: html ? 'capitalize' : 'lowercase'
                }}
            >
                {renderedHtml}
            </span>
        </span>
    );
};

export default OverviewLabel;