import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';

import {
    GatherCaseUX,
    DeathCertificateUpdateRequest,
    VitalDates,
    DeathCertificateType,
    generateDeathCertificate,
    EntitySummary,
    DeathCertificateConfigUX,
    UserProfile,
    UserRoles,
    FuneralHomeUX,
    NullLongAddress,
} from '../../../shared/types';

import DeathCertificateContent, { TopTabKey, isTopTabKey } from './DeathCertificateContent';
import { US_STATES } from '../../../shared/locale';
import TabsSelector, { TabType as TabsSelectorTab } from '../../common/TabsSelector';
import GCircularProgress from '../../common/GCircularProgress';
import { deathCertificateValidator } from '../../../shared/death_certificate/validators';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';
import { GStyles } from '../../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        marginTop: 50,
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        '@media (max-width: 584px)': {
            marginTop: 58,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 66,
        },
    },
    mainContainer: {
        marginBottom: '75px'
    },
    gCircular: {
        background: 'none',
        width: '32px !important',
        height: '32px !important',
        top: '33%',
        color: theme.palette.secondary.main
    },
});

interface Props {
    selectedCase: GatherCaseUX;
    helpers: EntitySummary[];
    isEditMode: boolean;
    activeConfig: DeathCertificateConfigUX | null;
    user: UserProfile;
    topOffset?: number;
    saveDeathCertificate: (updateRequest: DeathCertificateUpdateRequest) => void;
    updateVitalDates: (changes: VitalDates) => void;
    openConfigPopper: (event: React.MouseEvent<HTMLElement>) => void;
}

interface State {
    selectedTab: TopTabKey;
}

const setAbbreviationForState = (funeralHomeState: string) => {
    let filingStateAbbreviation = undefined;
    let filingStateObj = US_STATES.find(state => state.name === funeralHomeState);
    if (filingStateObj) {
        filingStateAbbreviation = filingStateObj.abbreviation;
    }
    return filingStateAbbreviation;
};

export const populateDeathCertificateFromCase = (params: {
    deathCertificate: DeathCertificateType;
    funeralHome: FuneralHomeUX;
}) => {
    const { deathCertificate, funeralHome } = params;
    // one other special part of the initial death certificate is the default state to file
    // should come from the funeral home if not defined
    if (!deathCertificate.life.residencePlace?.filingState) {
        const filingState = setAbbreviationForState(funeralHome.state);
        if (!deathCertificate.life.residencePlace) {
            deathCertificate.life.residencePlace = {
                ...NullLongAddress,
                filingState,
            };
        } else {
            deathCertificate.life.residencePlace.filingState = setAbbreviationForState(funeralHome.state);
        }
    }
};

type CombinedProps = Props &
    WithStyles<'root' | 'mainContainer' | 'gCircular'>;

class DeathCertificate extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            selectedTab: TopTabKey.questions,
        };
    }

    setSelectedTab = (key: string) => {
        if (!isTopTabKey(key)) {
            console.warn('Invalid top tab key', key);
            return;
        }
        this.setState({ selectedTab: key });
    };

    getTabs = () => {
        const {
            classes,
            selectedCase,
            helpers,
            activeConfig,
            user,
        } = this.props;

        const questionsStatusOnly =
            deathCertificateValidator(selectedCase.death_certificate, activeConfig, selectedCase, helpers, user, true);

        const isFHorGOM = UserRoles.isFHorGOMUser(user);
        const isFamilyMode = user.is_family_view_on;

        const tabs: TabsSelectorTab[] = [{
            key: TopTabKey.questions,
            element: (
                <>
                    <Typography color="secondary">Questions</Typography>
                    <GCircularProgress
                        variant="determinate"
                        value={questionsStatusOnly.percentComplete}
                        className={classes.gCircular}
                    />
                    {selectedCase.death_certificate_locked
                        ? <LockIcon
                            color="secondary"
                            sx={{ mt: '4px' }}
                            className={GStyles.fontSize22}
                        />
                        : <FormatListNumberedIcon
                            color="secondary"
                            sx={{ mt: '3px' }}
                            className={GStyles.fontSize22}
                        />
                    }
                </>
            ),
        }, {
            key: TopTabKey.approval,
            element: (
                <>
                    <Typography color="secondary">Approval</Typography>
                    <ThumbUpIcon color="secondary" className={GStyles.fontSize32} />
                </>
            ),
        }];
        if (isFHorGOM) {
            tabs.push({
                key: TopTabKey.overview,
                disabled: isFamilyMode,
                disabledTooltip: isFamilyMode ? 'Disabled in Family Safe Mode' : undefined,
                element: (
                    <>
                        <Typography color="secondary">Overview</Typography>
                        <DescriptionIcon color="secondary" className={GStyles.fontSize32} />
                    </>
                ),
            });
        }

        return tabs;
    };

    render() {
        const {
            classes,
            isEditMode,
            selectedCase,
            helpers,
            saveDeathCertificate,
            updateVitalDates,
            openConfigPopper,
            topOffset,
        } = this.props;
        const {
            selectedTab,
        } = this.state;

        // initialize death certificate
        const deathCertificate = selectedCase.death_certificate || generateDeathCertificate();
        populateDeathCertificateFromCase({
            deathCertificate,
            funeralHome: selectedCase.funeral_home,
        });

        return (
            <Grid className={classes.mainContainer}>
                <TabsSelector
                    onClick={(key: string | number) => {
                        if (typeof key === 'number') {
                            this.setSelectedTab(key.toString());
                        } else {
                            this.setSelectedTab(key);
                        }
                    }}
                    selectedKey={selectedTab}
                    tabs={this.getTabs()}
                />
                <DeathCertificateContent
                    selectedCase={selectedCase}
                    deathCertificate={deathCertificate}
                    isEditMode={isEditMode}
                    topOffset={topOffset}
                    helpers={helpers}
                    selectedTab={selectedTab}
                    saveDeathCertificate={saveDeathCertificate}
                    updateVitalDates={updateVitalDates}
                    setSelectedTab={this.setSelectedTab}
                    openConfigPopper={openConfigPopper}
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(DeathCertificate);
