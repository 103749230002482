import { Component } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import ConfirmationDialog from '../../common/ConfirmationDialog';
import { convertHexToRGBA } from '../../../services';
import { StoreState } from '../../../types';
import EditNoteDialog from './EditNote.dialog';
import { TaskNote, TaskUpdateRequestUX } from '../../../shared/types';
import { updateTask } from '../../../actions/task/Task.action';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import Note from './Note';
import NoteAnimation from './NoteAnimation';

const styles: StyleRulesCallback<Theme, Props, Classes> = theme => ({
    root: {},
    mainCointainer: {},
    header: {
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.20),
        '& p': {
            fontSize: 12,
            '@media (min-width: 368px)': {
                fontSize: 14,
            }
        }
    },
    taskNotesList: {
        padding: 0,
        minHeight: 'calc(100vh - 192px)',
        maxHeight: 'calc(100vh - 250px)',
        paddingTop: '0.5em !important',
        overflowY: 'auto',
        '@media (min-width: 536px)': {
            padding: '0 32px',
        },
    },
    notesTitleText: {
        textAlign: 'center',
        '& span': {
            display: 'block'
        }
    }
});

function mapStateToProps({ userSession, noteState }: StoreState) {
    return {
        userSession,
        isLoading: noteState.isLoading
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    caseUuid: string;
    gatherCaseFname: string;
    taskNotes: TaskNote[];
    zIndex: number;
};

interface State {
    activeTaskNote: TaskNote | null;
    isEditCaseDialogOpen: boolean;
    isDeleteConfirmationDialogOpen: boolean;
}

type Classes = 'root' | 'mainCointainer' | 'header' | 'taskNotesList' | 'notesTitleText';
type StyledProps = Props & WithStyles<Classes>;

type CombinedProps = StyledProps & InjectedProps;

class TaskNotes extends Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            activeTaskNote: null,
            isEditCaseDialogOpen: false,
            isDeleteConfirmationDialogOpen: false
        };
    }

    renderEditCaseDialog = () => {
        const { zIndex } = this.props;
        const { isEditCaseDialogOpen, activeTaskNote } = this.state;

        return (
            <EditNoteDialog
                note={activeTaskNote && activeTaskNote.notes[0] || null}
                open={Boolean(isEditCaseDialogOpen && activeTaskNote)}
                onClose={() => this.closeEditCaseDialog()}
                saveNote={note => this.updateTaskNote(note)}
                header={activeTaskNote && `"${activeTaskNote.task_title}" Task Note` || ''}
                zIndex={zIndex}
            />
        );
    };

    renderDeleteConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isDeleteConfirmationDialogOpen } = this.state;

        const deleteDialogHeader = 'Are you sure? Deleting this case note cannot be reversed.';
        const deleteDialogConfirmationButton = 'Delete Note';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={this.closeDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={() => this.updateTaskNote()}
                zIndex={zIndex}
            />
        );
    };

    render() {
        const { classes, gatherCaseFname, taskNotes, isLoading } = this.props;
        return (
            <>
                <Grid item xs={12} className={classes.mainCointainer}>
                    <Grid item xs={12} className={classes.header}>
                        <Typography color="primary" className={classes.notesTitleText}>
                            <span>
                                This list shows all notes added to any Task or Step on {gatherCaseFname}'s Case.
                            </span>
                            <span>
                                If a Task is visible to a family helper, the Task Note will also be visible.
                            </span>
                            <span>
                                Tracking Steps and Step Notes are never visible to family helpers.
                            </span>
                        </Typography>
                    </Grid>
                    {isLoading &&
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    }
                    {!isLoading && <TransitionGroup className={classes.taskNotesList}>
                        {taskNotes.map(taskNote =>
                            <NoteAnimation key={taskNote.task_id}>
                                <Note
                                    taskTitle={taskNote.task_title}
                                    note={taskNote.notes}
                                    updateByUser={taskNote.updated_by_user}
                                    updatedTime={taskNote.updated_by_user.time}
                                    onEdit={taskNote.is_tracking_step ? undefined : () => this.editNote(taskNote)}
                                    onDelete={taskNote.is_tracking_step
                                        ? undefined
                                        : () => this.deleteTaskNote(taskNote)
                                    }
                                />
                            </NoteAnimation>
                        )}
                    </TransitionGroup>}
                    {this.renderEditCaseDialog()}
                </Grid>

                {this.renderDeleteConfirmationDialog()}
            </>
        );
    }

    editNote = (note: TaskNote) => {
        this.setState({
            isEditCaseDialogOpen: true,
            activeTaskNote: note,
        });
    };

    closeEditCaseDialog = () => {
        this.setState({
            isEditCaseDialogOpen: false,
            activeTaskNote: null
        });
    };

    closeDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: false,
        });
    };

    updateTaskNote = async (note?: string) => {
        const { dispatch, userSession, caseUuid } = this.props;
        const { activeTaskNote } = this.state;

        if (!activeTaskNote || !userSession.userData) {
            return;
        }

        const changes: TaskUpdateRequestUX = {
            note: note || null
        };

        await dispatch(updateTask(activeTaskNote.task_id, changes, caseUuid, activeTaskNote.task_template));

        this.setState({
            activeTaskNote: null,
            isEditCaseDialogOpen: false,
            isDeleteConfirmationDialogOpen: false
        });
    };

    deleteTaskNote = (note: TaskNote) => {
        this.setState({
            isDeleteConfirmationDialogOpen: true,
            activeTaskNote: note
        });
    };
}

export default withState(mapStateToProps)(withStyles(styles)(TaskNotes));
