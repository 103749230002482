import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';

import { convertHexToRGBA, RouteBuilder, showIntercom } from '../../services';
import { downloadFromURL } from '../../services/doc.service';

import { GatherCaseUX, UserRoles, DocPacketUX, FeatureKey, DocUX } from '../../shared/types';

import { StoreState } from '../../types';

import SignDigitallySVG from './svg/SignDigitallySVG';

import DocPacketSummaryCard from './widgets/DocPacketSummaryCard';
import { downloadCaseDoc } from '../../actions/Doc.action';
import { registerAppError as _registerAppError } from '../../actions/errors';
import { WhenDisabled, isEnabled, WhenEnabled } from '../common/AppFeature';
import { deleteDocPacket, generateDocPacketDownloadUrl } from '../../actions/DocPacket.action';
import UserAvatar from '../common/UserAvatar';
import DocPacketMenu from './widgets/DocPacketMenu';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';
import CaseFiles from './CaseFiles';
import GPopper from '../common/GPopper';
import GLink from '../common/GLink';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '4px !important',
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: `14px solid ${convertHexToRGBA(theme.palette.primary.main, 0.18)}`,
        },
    },
    popperPositionLeft: {
        '&[data-popper-placement*="bottom"]': {
            left: '5px !important',
        },
    },
    popperPositionRight: {
        '&[data-popper-placement*="bottom"]': {
            left: '-5px !important',
        },
    },
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
        width: 288,
        height: 540,
        maxHeight: 540,
        '@media (min-width: 360px)': {
            width: 320,
        },
        '@media (min-width: 420px)': {
            width: 368,
        },
        '@media (min-width: 500px)': {
            width: 450,
        },
    },
    popperContent: {
        textAlign: 'center',
        padding: 0,
        borderRadius: 8,
        height: '100%',
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    arrow: {
        background: theme.palette.common.white,
        clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
        left: '5px !important',
    },
    tabsContainer: {
        filter: 'drop-shadow(0px 0px 0px transparent)',
        '& button': {
            margin: '0 4px',
        },
        '& header': {
            backgroundColor: `${convertHexToRGBA(theme.palette.primary.main, 0.18)}`,
        },
        '& $badge': {
            color: theme.palette.common.white,
            left: 'calc(100% - 8px)',
        },
    },
    items: {
        overflowY: 'auto',
        height: 308,
        flexWrap: 'wrap',
        display: 'flex',
        '&$isCompactView': {
            flexWrap: 'no-wrap',
            display: 'block',
            height: 'calc(100% - 48px)',
            // height: '100%',
            // position: 'relative'
        },
    },
    tabLabelContainer: {
        padding: '16px 24px',
    },
    createNewPacketContainer: {
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), ' +
            '0px 4px 5px 0px rgba(0,0,0,0.14), ' +
            '0px 1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: `1px dashed ${theme.palette.primary.main}`,
        margin: 12,
        textdocumentCounterWidgetAlign: 'center',
        borderRadius: 8,
        '@media (min-width: 500px)': {
            margin: '12px 32px',
        },
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        '& p': {
            lineHeight: '1em',
            fontSize: 26,
            fontWeight: 200,
            marginBottom: 8,
        },
        '& a': {
            width: '100%',
            height: '100%',
            color: 'inherit',
            textDecoration: 'none',
        },
        '& button': {
            padding: '16px 0',
        },
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
    },
    fabExtended: {
        color: theme.palette.secondary.main,
        width: 'max-content',
        margin: 'auto',
        textTransform: 'none',
        fontWeight: 400,
        fontSize: 10,
        padding: '2px 16px',
        borderRadius: 24,
        minHeight: 'auto',
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    svgContainer: {
        margin: '16px 0 8px',
        '& svg': {
            maxWidth: 260,
            maxHeight: 120,
        },
    },
    label: {
        fontWeight: 200,
        lineHeight: '1.25em',
    },
    signNotEnabled: {
        padding: '8px 8px',
    },
    itemHeight: {
        height: 492,
    },
    disabledPaper: {
        height: 'unset !important',
        '@media (min-width: 360px)': {
            width: '320px !important',
        },
    },
    disabledArrow: {
        borderBottom: `14px solid ${theme.palette.common.white} !important`,
    },
    headerAvatar: {
        float: 'right',
        border: '1px solid #fff',
        width: 24,
        height: 24,
        fontSize: 12,
    },
    topText: {
        minHeight: 36,
        marginTop: 4,
    },
    linkText: {
        fontWeight: 200,
        fontSize: 15,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    disabledTopText: {
        fontSize: 21,
        fontWeight: 200,
        '@media (min-width: 360px)': {
            fontSize: 23,
        },
    },
    arrowLeft: {
        left: '-4px !important',
    },
    isCompactView: {},
    badge: {},
});

type StyledProps = Props &
    WithStyles<
        | 'root'
        | 'popoverPaper'
        | 'popper'
        | 'signNotEnabled'
        | 'fabExtended'
        | 'popperContent'
        | 'arrow'
        | 'tabsContainer'
        | 'tabLabelContainer'
        | 'label'
        | 'topButton'
        | 'createNewPacketContainer'
        | 'items'
        | 'badge'
        | 'svgContainer'
        | 'arrowOuter'
        | 'isCompactView'
        | 'itemHeight'
        | 'disabledPaper'
        | 'disabledArrow'
        | 'topText'
        | 'headerAvatar'
        | 'linkText'
        | 'disabledTopText'
        | 'popperPositionRight'
        | 'popperPositionLeft'
        | 'arrowLeft'
    >;

function mapStateToProps({ userSession, docPacketState, casesState, funeralHomeState, caseDocState }: StoreState) {
    const { userData } = userSession;
    const { docs } = caseDocState;

    return {
        caseDocs: docs,
        docPackets: docPacketState.docPackets,
        userSession,
        isDocPacketsLoading: docPacketState.isLoading,
        isAutoFormsEnabled: isEnabled(FeatureKey.DOC_LIBRARY_AND_AUTOFORMS, funeralHomeState, casesState, userSession),
        isESignEnabled: isEnabled(FeatureKey.DOCUMENTS_ESIGN, funeralHomeState, casesState, userSession),
        isFHorGOM: UserRoles.isFHorGOMUser(userData),
        isGOM: Boolean(userData && UserRoles.isGOMRole(userData.role)),
    };
}

type Props = ReturnType<typeof mapStateToProps> & {
    dispatch: AppDispatch;
    activeCase: GatherCaseUX;
    anchorEle: HTMLElement | null;
    closePopper: () => void;
    zIndex: number;
    setReminderAnchorEl: (anchorEl: HTMLElement | null) => void;
    reminderAnchorEl: HTMLElement | null;
    isOnFamilyPage?: boolean;
    hasSendReminderPopperOpen?: (isOpen: boolean) => void;
    disabledDocPacketIds?: number[];
    popperPosition?: 'left' | 'right';
};

interface State {
    dd214MenuAnchorEle: HTMLElement | null;
    tabIndex: number;
    isCompactView: boolean;
    activeDocId: number | null;
    isSendReminderPopperOpen: boolean;
    isDownloading: boolean;
    isDeleteConfirmationDialogOpen: boolean;
    docPacketMenuAnchorEle: HTMLElement | null;
    signerMenuAnchorEle: HTMLElement | null;
    activeDocPacketId: number | null;
    isChangeEmailDialogOpen: boolean;
    isHelperPopperOpen: boolean;
    isDocMenuOpen: boolean;
    arrowRef: HTMLSpanElement | null;
}

class UploadDocsPopper extends React.Component<StyledProps, State> {
    state: State = {
        tabIndex: 0,
        dd214MenuAnchorEle: null,
        isCompactView: true,
        activeDocId: null,
        isDownloading: false,
        isDeleteConfirmationDialogOpen: false,
        docPacketMenuAnchorEle: null,
        signerMenuAnchorEle: null,
        activeDocPacketId: null,
        isSendReminderPopperOpen: false,
        isChangeEmailDialogOpen: false,
        isHelperPopperOpen: false,
        isDocMenuOpen: false,
        arrowRef: null,
    };

    getActiveDocPacket = () => {
        const { docPackets } = this.props;
        const { activeDocPacketId } = this.state;
        const activeDocPacket = docPackets.find((ele) => ele.id === activeDocPacketId);
        if (!activeDocPacket) {
            return null;
        }
        return activeDocPacket;
    };

    renderPacketsContent = () => {
        const {
            docPackets,
            isDocPacketsLoading,
            activeCase,
            zIndex,
            isGOM,
            reminderAnchorEl,
            setReminderAnchorEl,
            disabledDocPacketIds,
        } = this.props;
        const { isDeleteConfirmationDialogOpen, docPacketMenuAnchorEle, signerMenuAnchorEle } = this.state;

        return (
            <>
                {this.renderCreateNewPacket()}
                {isDocPacketsLoading ? (
                    <CircularProgress color="primary" />
                ) : (
                    docPackets.map((docPacket) => (
                        <DocPacketSummaryCard
                            key={docPacket.id}
                            activeCase={activeCase}
                            docPacket={docPacket}
                            packetLink={RouteBuilder.DocPacketPage({
                                caseName: activeCase.name,
                                funeralHomeKey: activeCase.funeral_home.key,
                                docPacketId: docPacket.id,
                            })}
                            onDownloadUnsignedPacket={() => this.handleDownloadUnsignedPacket(docPacket)}
                            onDownloadSignedDoc={this.handleDownloadSignedDoc}
                            onDeletePacket={this.handleDeleteDocPacket}
                            zIndex={zIndex + 1}
                            isGOM={isGOM}
                            isChangeEmailDialogOpen={(isOpen) => this.isChangeEmailDialogOpen(isOpen)}
                            isHelperPopperOpen={(isOpen) => this.isHelperPopperOpen(isOpen)}
                            isDeleteConfirmationDialogOpen={isDeleteConfirmationDialogOpen}
                            setDeleteConfirmationDialogOpen={this.toggleVisibilityDeleteConfirmationDialog}
                            menuAnchorEle={docPacketMenuAnchorEle}
                            setMenuAnchorEle={(e) => this.setDocPacketMenuAnchorEle(e, docPacket.id)}
                            setReminderAnchorEl={setReminderAnchorEl}
                            reminderAnchorEl={reminderAnchorEl}
                            setSignerMenuAnchorEle={this.setSignerMenuAnchorEle}
                            signerMenuAnchorEle={signerMenuAnchorEle}
                            disabled={Boolean(disabledDocPacketIds && disabledDocPacketIds.indexOf(docPacket.id) >= 0)}
                            toggleDocMenuOpen={this.toggleDocMenuOpen}
                        />
                    ))
                )}
                <WhenDisabled feature={FeatureKey.DOCUMENTS_ESIGN}>{this.eSignatureNotEnabled()}</WhenDisabled>
                {this.renderDocPacketItemMenu()}
            </>
        );
    };

    renderDocPacketItemMenu = () => {
        const { activeCase, zIndex } = this.props;
        const { docPacketMenuAnchorEle } = this.state;
        const activeDocPacket = this.getActiveDocPacket();
        if (!activeDocPacket) {
            return null;
        }
        return (
            <DocPacketMenu
                anchorEle={docPacketMenuAnchorEle}
                docPacket={activeDocPacket}
                activeCase={activeCase}
                setAnchorEl={(e) => this.setDocPacketMenuAnchorEle(e, activeDocPacket.id)}
                closeMenu={this.closeDocPacketMenu}
                zIndex={zIndex + 1}
                hideIcon
            />
        );
    };

    toggleVisibilityDeleteConfirmationDialog = (toggleValue?: boolean) => {
        if (toggleValue !== undefined) {
            this.setState({
                isDeleteConfirmationDialogOpen: Boolean(toggleValue),
            });
        } else {
            this.setState((prevState) => ({
                isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            }));
        }
    };

    closeDocPacketMenu = () => {
        this.toggleVisibilityDeleteConfirmationDialog(false);
        this.setState({
            docPacketMenuAnchorEle: null,
            activeDocPacketId: null,
        });
    };

    setSignerMenuAnchorEle = (anchorEle: HTMLElement | null) => {
        this.setState({
            signerMenuAnchorEle: anchorEle,
        });
    };

    setDocPacketMenuAnchorEle = (docPacketMenuAnchorEle: HTMLElement | null, activeDocPacketId: number) => {
        this.setState({
            activeDocPacketId,
            docPacketMenuAnchorEle,
        });
    };

    handleDeleteDocPacket = async () => {
        const { dispatch, activeCase } = this.props;
        const docPacket = this.getActiveDocPacket();
        if (!docPacket) {
            return;
        }
        dispatch(deleteDocPacket(docPacket, activeCase));
    };

    handleDownloadSignedDoc = async (doc: DocUX) => {
        const { activeCase, dispatch } = this.props;
        return dispatch(downloadCaseDoc(activeCase.uuid, doc.id));
    };

    handleDownloadUnsignedPacket = async (docPacket: DocPacketUX) => {
        const { dispatch, activeCase } = this.props;

        this.setState({ isDownloading: true });
        const presignedUrl = await dispatch(generateDocPacketDownloadUrl(docPacket, activeCase));
        this.setState({ isDownloading: false });

        if (!presignedUrl) {
            return;
        }
        downloadFromURL(presignedUrl, `${activeCase.fname}_${activeCase.lname}_packet_${docPacket.id}.pdf`);
    };

    renderContent = () => {
        const { anchorEle, classes, activeCase, caseDocs, userSession, docPackets, isESignEnabled, isFHorGOM } =
            this.props;
        const { tabIndex, isDeleteConfirmationDialogOpen } = this.state;

        const docPacketCount: number = docPackets.length;
        if (!anchorEle && !isDeleteConfirmationDialogOpen) {
            return null;
        }
        // filterMyDocs
        /* 
            const uploadedDocList
            UserRoles.isFHRole
            UserRoles.isGOMRole
            if(userSession.userData)
        */

        const uploadedCaseFilesCount: number = caseDocs.length;

        const caseFileLabel: string = !isFHorGOM ? `${activeCase.fname}'s  Files` : 'Case Files';
        const isCompactView = tabIndex === 1 || this.state.isCompactView;
        const templateFileLabel: string = 'Packets';

        return (
            <Grid item xs={12} className={classes.popperContent}>
                <Grid xs={12} item className={classes.tabsContainer}>
                    <AppBar position="static">
                        <Tabs
                            value={tabIndex}
                            onChange={this.handleTabChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {isFHorGOM && (
                                <Tab
                                    label={
                                        <Badge
                                            color="primary"
                                            badgeContent={docPacketCount}
                                            classes={{ badge: classes.badge }}
                                            hidden={docPacketCount === 0}
                                        >
                                            {templateFileLabel}
                                        </Badge>
                                    }
                                    classes={{ root: classes.tabLabelContainer }}
                                />
                            )}
                            <Tab
                                label={
                                    <Badge
                                        color="primary"
                                        badgeContent={uploadedCaseFilesCount}
                                        classes={{ badge: classes.badge }}
                                        hidden={uploadedCaseFilesCount === 0}
                                    >
                                        {caseFileLabel}
                                    </Badge>
                                }
                                classes={{ root: classes.tabLabelContainer }}
                            />
                        </Tabs>
                    </AppBar>
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.items,
                        isCompactView && classes.isCompactView,
                        isESignEnabled && classes.itemHeight,
                    )}
                >
                    <Grid container alignItems="center" justifyContent="center" className={classes.topText}>
                        <UserAvatar
                            user={activeCase}
                            className={classes.headerAvatar}
                            isLoading={userSession.isUserPhotoSaving}
                        />
                        &nbsp;
                        <Typography color="secondary">
                            {tabIndex === 0 ? 'Signature packets' : 'Case files'} for {activeCase.full_name}
                        </Typography>
                    </Grid>
                    {tabIndex === 0 && isFHorGOM ? (
                        this.renderPacketsContent()
                    ) : (
                        <CaseFiles
                            activeCase={activeCase}
                            zIndex={1330}
                            compactView={false}
                            tabIndex={tabIndex}
                            setIsDocMenuOpen={this.toggleDocMenuOpen}
                        />
                    )}
                </Grid>
            </Grid>
        );
    };

    eSignatureNotEnabled = () => {
        const { classes } = this.props;

        return (
            <div className={classes.signNotEnabled}>
                <Grid item className={classNames(GStyles.textCenter, classes.svgContainer)}>
                    <SignDigitallySVG themeFillClass={GStyles.fillPrimary} />
                </Grid>

                <Grid item xs={12} className={GStyles.textCenter}>
                    <Typography variant="subtitle1" color="secondary" className={classes.label}>
                        eSignature is Not Enabled
                    </Typography>
                </Grid>
            </div>
        );
    };

    renderCreateNewPacket = () => {
        const { classes, activeCase } = this.props;

        return (
            <Card className={classes.createNewPacketContainer}>
                <GLink
                    to={RouteBuilder.DocPacketPage({
                        caseName: activeCase.name,
                        funeralHomeKey: activeCase.funeral_home.key,
                    })}
                >
                    <CardActionArea>
                        <Typography color="primary" align="center">
                            Create Signature Packet
                        </Typography>

                        <div className={classes.fabExtended}>Click to Select Documents</div>
                    </CardActionArea>
                </GLink>
            </Card>
        );
    };

    isSendReminderPopperOpen = (isOpen: boolean) => {
        this.setState({ isSendReminderPopperOpen: isOpen });
    };

    isChangeEmailDialogOpen = (isOpen: boolean) => {
        this.setState({ isChangeEmailDialogOpen: isOpen });
    };

    isHelperPopperOpen = (isOpen: boolean) => {
        this.setState({ isHelperPopperOpen: isOpen });
    };

    toggleDocMenuOpen = (isOpen: boolean) => {
        this.setState({ isDocMenuOpen: isOpen });
    };

    render() {
        const { classes, anchorEle, closePopper, isAutoFormsEnabled, zIndex, reminderAnchorEl, popperPosition } =
            this.props;
        const {
            isSendReminderPopperOpen,
            dd214MenuAnchorEle,
            isDeleteConfirmationDialogOpen,
            docPacketMenuAnchorEle,
            signerMenuAnchorEle,
            isChangeEmailDialogOpen,
            isHelperPopperOpen,
            isDocMenuOpen,
        } = this.state;

        return (
            <GPopper
                anchorEle={anchorEle}
                className={classNames(
                    classes.popper,
                    popperPosition === 'left' && classes.popperPositionLeft,
                    popperPosition === 'right' && classes.popperPositionRight,
                )}
                arrowClass={classNames(
                    classes.arrow,
                    popperPosition === 'left' && classes.arrowLeft,
                    !isAutoFormsEnabled && classes.disabledArrow,
                )}
                paperClass={classNames(classes.popoverPaper, !isAutoFormsEnabled && classes.disabledPaper)}
                zIndex={zIndex + 1}
                closePopper={(e) => {
                    if (
                        docPacketMenuAnchorEle ||
                        dd214MenuAnchorEle ||
                        isDeleteConfirmationDialogOpen ||
                        !anchorEle ||
                        reminderAnchorEl ||
                        signerMenuAnchorEle ||
                        isSendReminderPopperOpen ||
                        isChangeEmailDialogOpen ||
                        isHelperPopperOpen ||
                        isDocMenuOpen
                    ) {
                        return;
                    }
                    this.setSignerMenuAnchorEle(null);
                    closePopper();
                    this.setState({ tabIndex: 0 });
                }}
                placement="bottom"
            >
                <WhenEnabled feature={FeatureKey.DOC_LIBRARY_AND_AUTOFORMS}>{this.renderContent()}</WhenEnabled>
                <WhenDisabled feature={FeatureKey.DOC_LIBRARY_AND_AUTOFORMS}>{this.autoFormsDisabled()}</WhenDisabled>
            </GPopper>
        );
    }

    autoFormsDisabled = () => {
        const { classes } = this.props;
        return (
            <div className={classes.signNotEnabled}>
                <Grid item xs={12} className={GStyles.textCenter}>
                    <Typography variant="subtitle1" align="center" color="primary" className={classes.disabledTopText}>
                        Signatures Not Enabled
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="primary"
                        className={classes.linkText}
                        noWrap={false}
                        onClick={(e) => showIntercom()}
                    >
                        Click to contact Gather to enable
                    </Typography>
                </Grid>

                <Grid item className={classNames(GStyles.textCenter, classes.svgContainer)}>
                    <SignDigitallySVG themeFillClass={GStyles.fillPrimary} />
                </Grid>

                <Grid item xs={12} className={GStyles.textCenter}>
                    <Typography variant="subtitle1" color="secondary" className={classes.label}>
                        Autofill and eSign your forms in seconds
                    </Typography>
                </Grid>
            </div>
        );
    };

    // toggleLayout = (view: 'COMPACT_LAYOUT' | 'LARGE_LAYOUT') => {
    //     this.setState({ isCompactView: view === 'COMPACT_LAYOUT' });
    // }

    handleTabChange = (e: React.ChangeEvent<{}>, tabIndex: number) => {
        this.setState({
            tabIndex,
            activeDocId: null,
        });
    };

    closeMenu = () => {
        this.setState({ dd214MenuAnchorEle: null });
    };

    handleArrowRef = (node: HTMLSpanElement | null) => {
        this.setState({ arrowRef: node });
    };
}

export default withState(mapStateToProps)(withStyles(styles)(UploadDocsPopper));
