import moment from 'moment-timezone';
import {
    ModerationItem,
    ModerationCategory,
    ModerationCategorySingleDisplayLookup,
    ModerationStatus
} from '../../../shared/types';
import { joinNameParts } from '../../../shared/utils';

export const getModerationVars = (item: ModerationItem, category: ModerationCategory) => {

    const { moderation_decision } = item;

    const moderationTime = moment(moderation_decision && moderation_decision.created_time || new Date()).fromNow();
    const moderator = moderation_decision && moderation_decision.moderated_by;
    const isPending = !moderation_decision || moderation_decision.approval_status === ModerationStatus.pending;
    const isApproved = moderation_decision
        ? moderation_decision.approval_status === ModerationStatus.approved : false;
    const isBlocked = moderation_decision
        ? moderation_decision.approval_status === ModerationStatus.blocked : false;
    const moderatorName = moderator
        ? moderator.is_gom_user ? 'Gather' : joinNameParts(moderator)
        : '';
    const itemType = ModerationCategorySingleDisplayLookup[category];

    let moderationText = '';
    if (isPending) {
        moderationText = `This ${itemType} is not yet visible`;
    } else if (isApproved && moderator) {
        moderationText = `${itemType} approved by ${moderatorName} ${moderationTime}`;
    } else if (isBlocked && moderator) {
        moderationText = `${itemType} blocked by ${moderatorName} ${moderationTime}`;
    }

    return {
        moderationText,
        moderationTime,
        moderator,
        moderatorName,
        isPending,
        isApproved,
        isBlocked,
    };
};
