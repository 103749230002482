import * as React from 'react';
import classNames from 'classnames';
import { getDataURIFromFile, emptyValueValidator, convertHexToRGBA } from '../../../services';

import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';

import AddAPhoto from '@mui/icons-material/AddAPhoto';

import UserAvatar from '../../../components/common/UserAvatar';

import {
    UserProfile,
    PhotoTransformationsType,
    PhotoScopeEnum,
    UserRoles,
    EntityCaseRole,
    isEntityCaseRole,
    EntitySummary,
    LongAddress,
    GmapsSearchType,
    isLongAddress,
    EntityUpdateValidateErrorEnum
} from '../../../shared/types';

import PhotoCropper from '../../profileImage/PhotoCropper';
import TextField from '@mui/material/TextField';

import GGroupButtons from '../../common/GGroupButtons';
import { APP_SECONDARY_COLOR, SKYBLUE_COLOR_2 } from '../../../constants/colorVariables';
import ChangeGatherViewGSwitch from '../../family/ChangeGatherViewGSwitch';
import { canEditPersonPhoto, canEditCaseRole } from '../../../shared/authority/can';
import GmapsSearch, { GmapsSearchAddress } from '../../gmapsSearch/GmapsSearch';
import PhoneNumberMask from '../../common/PhoneNumberMask';
import Typography from '@mui/material/Typography';
import { EntityTypeLookup } from './HelperUserSettings';
import { TeamUserSettingsTab } from './TeamUserSettings';
import { MySettingsTab } from '.';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        display: 'block',
        width: '100%',
        '@media (min-width: 768px)': {
            marginBottom: 0
        }
    },
    avatar: {
        width: 80,
        height: 80,
        margin: 'auto',
        boxShadow: theme.shadows[5],
    },
    button: {
        margin: theme.spacing(),
    },
    form: {
        padding: '0 16px',
        maxWidth: 280,
        width: '100%',
        margin: 'auto',
        '@media (min-width: 360px)': {
            maxWidth: 320,
        },
        '@media (min-width: 768px)': {
            padding: '0 8px 0 16px',
            margin: 0,
            maxWidth: 'initial'
        }
    },
    userButtonsGrid: {
        textAlign: 'center',
        marginBottom: 10,
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(2)': {
                marginTop: -2
            }
        }
    },
    gGroupButtonsStyles: {
        minWidth: 128,
        minHeight: 40,
    },
    adminButtonActive: {
        background: SKYBLUE_COLOR_2,
        color: `${theme.palette.common.white} !important`,
        '&:hover': {
            background: `${convertHexToRGBA(SKYBLUE_COLOR_2, 0.8)} !important`
        }
    },
    guestButtonActive: {
        background: APP_SECONDARY_COLOR,
        color: `${theme.palette.common.white} !important`,
        '&:hover': {
            background: `${convertHexToRGBA(APP_SECONDARY_COLOR, 0.8)} !important`
        }
    },
    adminButton: {
        borderRight: 0,
        color: SKYBLUE_COLOR_2,
        '&:hover': {
            borderRight: 0,
            background: convertHexToRGBA(SKYBLUE_COLOR_2, 0.2),
        }
    },
    guestButton: {
        color: APP_SECONDARY_COLOR,
        '&:hover': {
            background: convertHexToRGBA(APP_SECONDARY_COLOR, 0.2),
        }
    },
    overlapDot: {
        top: 4,
        left: 4,
        fontSize: 12,
        background: SKYBLUE_COLOR_2,
        height: '1em',
        width: '1em',
        borderRadius: '50%',
        zIndex: 1,
        position: 'absolute',
    },
    gmapSearch: {
        width: '100%',
        maxWidth: 280,
        '@media (min-width: 360px)': {
            maxWidth: 320,
        },
        '@media (min-width: 768px)': {
            maxWidth: 356
        }
    },
    margin0: {
        margin: 0
    },
    emailPhoneContainer: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.primary.main}`,
        background: convertHexToRGBA(theme.palette.primary.main, 0.08),
        padding: '10px 8px 16px !important',
        '& $emailPhoneHelperText': {
            fontSize: 12,
            textAlign: 'center',
            marginTop: 12,
            lineHeight: 1.25,
            '& span': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        }
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 760,
        '@media (min-width: 768px)': {
            flexDirection: 'row',
            justifyContent: 'center'
        },

    },
    emailPhoneForm: {
        margin: '20px auto 0',
        padding: '0 16px',
        '@media (min-width: 768px)': {
            padding: '0 16px 0 8px',
            margin: 0
        }
    },
    fhHeading: {
        textAlign: 'center',
        margin: '4px 0 32px'
    },
    controlSuggestionClass: {
        position: 'absolute',
        textAlign: 'center'
    },
    logoutButtonOuter: {
        display: 'block',
        paddingTop: 4,
        textAlign: 'center',
    },
    paddingRight: {
        color: '#676767',
        paddingRight: '10px',
    },
    emailPhoneHelperText: {},
});

type StyledProps = Props & WithGStyles<'root' | 'avatar' | 'button' | 'form' | 'userButtonsGrid'
    | 'gGroupButtonsStyles' | 'adminButtonActive' | 'guestButtonActive' | 'adminButton' | 'guestButton'
    | 'overlapDot' | 'gmapSearch' | 'margin0' | 'emailPhoneContainer' | 'detailsContainer' | 'emailPhoneForm'
    | 'fhHeading' | 'controlSuggestionClass' | 'logoutButtonOuter' | 'paddingRight' | 'emailPhoneHelperText'>;

interface Props {
    isSaving: boolean;
    isDialogOpen: boolean;
    selectedPerson: EntitySummary;
    isUserPhotoSaving: boolean;
    isMe: boolean;
    isViewAsGOM: boolean;
    onViewAsGOMChange: (isViewAsGOM: boolean) => void;
    onFirstNameChange: (fname: string) => void;
    onMiddleNameChange: (mname: string) => void;
    onLastNameChange: (lname: string) => void;
    onStateLicenseChange: (stateLicenseNumber: string) => void;
    updateUserPhoto: (
        entity: EntitySummary,
        photo: string,
        transformations: PhotoTransformationsType,
        isMe: boolean,
    ) => void;
    setSettingsUserButton: (entityCaseRole: EntityCaseRole) => void;
    entityCaseRole: EntityCaseRole | null;
    loggedInUser: UserProfile;
    gatherCaseId: number | null;
    onHomeAddressChange: (homeAddress: LongAddress, useAddressDescription: boolean) => void;
    useAddressDescription: boolean;
    homeAddressLong: LongAddress;
    zIndex: number;
    isFHUser: boolean;
    email: string;
    phone: string;
    isEmailValid: boolean;
    isPhoneValid: boolean;
    onEmailChange: (email: string) => void;
    onPhoneChange: (phone: string) => void;
    disableLoginCredentials: boolean;
    changeTab: (tab: MySettingsTab) => void;
    logoutUserSession: () => void;
    entityValidation: EntityUpdateValidateErrorEnum | null;
}

interface State {
    isPhotoCropperOpen: boolean;
    uploadedImage: string;
}

class MyDetailsForm extends React.Component<StyledProps, State> {
    state: State = {
        isPhotoCropperOpen: false,
        uploadedImage: '',
    };

    protected fileUploadInput: HTMLInputElement;

    registerFileUpload = (fileUploadInput: HTMLInputElement) => {
        this.fileUploadInput = fileUploadInput;
    };

    handleFileUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length !== 0) {
            const dataURI = await getDataURIFromFile(event.target.files[0]);
            this.setState({
                uploadedImage: dataURI,
            });
            this.openPhotoCropper();
        }
    };

    uploadNewImage = () => {
        this.fileUploadInput.click();
    };

    saveNewImage = async (transformations: PhotoTransformationsType) => {
        const { updateUserPhoto, selectedPerson, isMe } = this.props;
        const { uploadedImage } = this.state;

        updateUserPhoto(selectedPerson, uploadedImage, transformations, isMe);
    };

    renderImageAvatar = () => {
        const {
            classes, selectedPerson, isUserPhotoSaving, entityCaseRole, gatherCaseId, loggedInUser
        } = this.props;

        const canEditPhoto =
            canEditPersonPhoto(loggedInUser, selectedPerson, gatherCaseId);

        if (selectedPerson.photo || isUserPhotoSaving) {
            return (
                <UserAvatar
                    user={selectedPerson}
                    className={classes.avatar}
                    onClick={e => canEditPhoto && this.uploadNewImage() || undefined}
                    size={80}
                    isLoading={isUserPhotoSaving}
                    showOverlapDot={entityCaseRole === EntityCaseRole.admin}
                    overlapDotClass={entityCaseRole === EntityCaseRole.admin
                        && classes.overlapDot || undefined}
                />
            );
        }
        return (
            <Avatar className={classes.avatar} onClick={e => canEditPhoto && this.uploadNewImage() || undefined}>
                <AddAPhoto className={classes.avtarIcon} />
            </Avatar>
        );
    };

    closePhotoCropper = () => {
        this.setState({
            isPhotoCropperOpen: false,
        });
    };

    openPhotoCropper = () => {
        this.setState({
            isPhotoCropperOpen: true,
        });
    };

    renderFHContent = () => {
        const {
            classes,
            isEmailValid,
            onEmailChange,
            isSaving,
            disableLoginCredentials,
            email,
            selectedPerson,
            onPhoneChange,
            isPhoneValid,
            phone,
            changeTab,
            logoutUserSession,
            entityValidation
        } = this.props;

        const emailInUse = entityValidation === EntityUpdateValidateErrorEnum.duplicateEmail ||
            entityValidation === EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail;
        const phoneInUse = entityValidation === EntityUpdateValidateErrorEnum.duplicatePhone ||
            entityValidation === EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail;

        return (
            <form
                className={classNames(
                    classes.overflowHidden,
                    classes.form,
                    classes.emailPhoneForm,
                )}
                autoComplete="off"
            >
                <Grid item xs={12} className={classes.emailPhoneContainer}>
                    <FormControl
                        className={classNames(classes.width100, classes.textLeft)}
                    >
                        <TextField
                            label={`${selectedPerson.fname}'s Primary Email`}
                            type="email"
                            id="_email"
                            name="email"
                            value={email}
                            className={classes.width100}
                            onChange={(e) => onEmailChange(e.target.value)}
                            autoComplete="new-password"
                            disabled={isSaving || disableLoginCredentials}
                            error={!isEmailValid || emailInUse}
                            color="secondary" />
                        {!isEmailValid ?
                            <FormHelperText id="email-format-helper">
                                Email is invalid.
                            </FormHelperText> : null}
                        {emailInUse &&
                            <FormHelperText id="email-format-helper">
                                This email is already in use.
                            </FormHelperText>
                        }
                    </FormControl>

                    <Typography color="secondary" className={classes.emailPhoneHelperText}>
                        This is the email that will be used to log in to Gather. You can
                        &nbsp;
                        <span onClick={(e) => changeTab(TeamUserSettingsTab.communications)}>click here </span>
                        &nbsp;
                        to adjust which email is shown to families.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classNames(classes.emailPhoneContainer, classes.marginTop20)}>
                    <FormControl className={classNames(classes.width100, classes.textLeft)}>
                        <TextField
                            label={`${selectedPerson.fname}'s Primary Phone Number`}
                            type="tel"
                            id="phone-number"
                            name="phoneNumber"
                            value={phone}
                            InputProps={{
                                inputComponent: PhoneNumberMask
                            }}
                            className={classes.width100}
                            onChange={(e) => onPhoneChange(e.target.value)}
                            autoComplete="new-password"
                            disabled={isSaving || disableLoginCredentials}
                            error={!isPhoneValid || phoneInUse}
                            color="secondary" />
                        {!isPhoneValid ?
                            <FormHelperText id="email-format-helper">
                                Phone Number is invalid.
                            </FormHelperText> : null}
                        {phoneInUse &&
                            <FormHelperText id="email-format-helper">
                                This phone number is already in use.
                            </FormHelperText>
                        }
                    </FormControl>

                    <Typography color="secondary" className={classes.emailPhoneHelperText}>
                        This is the phone number that will be used to log in to Gather. This should be a smart phone.
                        You can
                        &nbsp;
                        <span onClick={(e) => changeTab(TeamUserSettingsTab.communications)}>click here</span>
                        &nbsp;
                        to adjust which phone number is shown to families.
                    </Typography>
                </Grid>
                <Grid
                    item
                    className={classes.logoutButtonOuter}
                >
                    <Button
                        color="primary"
                        className={classes.button}
                        size="large"
                        onClick={logoutUserSession}
                    >
                        <PowerSettingsNew className={classNames(classes.paddingRight)} />
                        LOG OUT ON THIS DEVICE
                    </Button>
                </Grid>
            </form>
        );
    };

    render() {
        const {
            classes,
            selectedPerson,
            isSaving,
            onFirstNameChange,
            onMiddleNameChange,
            onLastNameChange,
            onStateLicenseChange,
            setSettingsUserButton,
            entityCaseRole,
            isViewAsGOM,
            onViewAsGOMChange,
            isMe,
            loggedInUser,
            gatherCaseId,
            zIndex,
            homeAddressLong,
            onHomeAddressChange,
            isFHUser,
            useAddressDescription
        } = this.props;
        const {
            isPhotoCropperOpen,
            uploadedImage
        } = this.state;

        const { fname, mname, lname, state_license_number } = selectedPerson;
        const isSelectedUserAFHUser = UserRoles.isFHUser(selectedPerson);
        const isSelectedUserAGOMUser = UserRoles.isGOMUser(selectedPerson);
        const isSelectedUserAFamilyUser = gatherCaseId !== null
            ? UserRoles.isFamilyOnCase(selectedPerson, gatherCaseId)
            : false;
        const canEditRole =
            Boolean(gatherCaseId !== null && canEditCaseRole(loggedInUser, selectedPerson, gatherCaseId));
        const canEditPhoto = canEditPersonPhoto(loggedInUser, selectedPerson, gatherCaseId);

        return (
            <div className={classes.root}>
                <Grid container justifyContent="center">
                    {isSelectedUserAFamilyUser
                        && entityCaseRole
                        && canEditRole
                        && <Grid item xs={12} className={classes.userButtonsGrid}>
                            <GGroupButtons
                                buttons={[
                                    {
                                        label: EntityTypeLookup[EntityCaseRole.admin],
                                        value: EntityCaseRole.admin,
                                        className: classes.adminButton
                                    }, {
                                        label: EntityTypeLookup[EntityCaseRole.guest],
                                        value: EntityCaseRole.guest,
                                        className: classes.guestButton
                                    }
                                ]}
                                activeButton={entityCaseRole}
                                onClick={(value) => isEntityCaseRole(value) && setSettingsUserButton(value)}
                                activeClass={entityCaseRole === EntityCaseRole.admin &&
                                    classes.adminButtonActive || classes.guestButtonActive
                                }
                                controlClasses={classes.gGroupButtonsStyles}
                            />
                        </Grid>}
                    {isFHUser && <Typography color="secondary" className={classes.fhHeading}>
                        The settings below apply for {selectedPerson.fname} {selectedPerson.lname} across all cases
                        and funeral homes
                    </Typography>}
                    <Grid
                        item
                        xs={12}
                        className={classNames(classes.textCenter, isFHUser && classes.marginBottom20)}
                    >
                        <input
                            type="file"
                            accept="image/gif, image/jpeg, image/png"
                            className={classes.displayNone}
                            ref={this.registerFileUpload}
                            onChange={this.handleFileUploadEvent}
                            onClick={e => {
                                if (!canEditPhoto) {
                                    return;
                                }
                                const element = e.target as HTMLInputElement;
                                // clear this value to so that same photo can be chosen each time
                                element.value = '';
                            }}
                        />
                        {this.renderImageAvatar()}
                        {canEditPhoto
                            && <Button
                                className={classNames(classes.button, classes.colorBlack2)}
                                size="small"
                                onClick={this.uploadNewImage}
                            >
                                {`${selectedPerson.photo ? 'CHANGE' : 'UPLOAD'} PHOTO`}
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.detailsContainer}>
                        <form className={classes.form} autoComplete="off" >
                            <FormControl
                                className={classNames(
                                    classes.width100,
                                    classes.textLeft,
                                    classes.inputWithError
                                )}
                            >
                                <TextField
                                    required
                                    error={emptyValueValidator(fname)}
                                    label="First Name"
                                    id="fname"
                                    name="fname"
                                    autoComplete="new-password"
                                    value={fname}
                                    className={classes.width100}
                                    onChange={(e) => onFirstNameChange(e.target.value)}
                                    disabled={isSaving}
                                />
                                {emptyValueValidator(fname) ?
                                    <FormHelperText id="firstName-helper">
                                        This field is required
                                    </FormHelperText> : null
                                }
                            </FormControl>

                            <FormControl
                                className={classNames(
                                    classes.width100,
                                    classes.textLeft,
                                    classes.inputWithError
                                )}
                            >
                                <TextField
                                    required={false}
                                    label="Middle Name"
                                    id="mname"
                                    name="mname"
                                    autoComplete="new-password"
                                    value={mname || ''}
                                    className={classes.width100}
                                    onChange={(e) => onMiddleNameChange(e.target.value)}
                                    disabled={isSaving}
                                />
                            </FormControl>

                            <FormControl
                                className={classNames(
                                    classes.width100,
                                    classes.textLeft,
                                    classes.inputWithError
                                )}
                            >
                                <TextField
                                    required
                                    error={emptyValueValidator(lname)}
                                    label="Last Name"
                                    id="lname"
                                    name="lname"
                                    autoComplete="new-password"
                                    value={lname || ''}
                                    className={classes.width100}
                                    onChange={(e) => onLastNameChange(e.target.value)}
                                    disabled={isSaving}
                                />
                                {emptyValueValidator(lname) ?
                                    <FormHelperText id="lastName-helper">
                                        This field is required
                                    </FormHelperText> : null
                                }
                            </FormControl>

                            {isSelectedUserAFHUser &&
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.inputWithError
                                    )}
                                >
                                    <TextField
                                        label="State License Number"
                                        id="stateLicenseNumber"
                                        name="stateLicenseNumber"
                                        autoComplete="new-password"
                                        className={classes.width100}
                                        value={state_license_number || ''}
                                        onChange={(e) => onStateLicenseChange(e.target.value)}
                                        disabled={isSaving}
                                    />
                                </FormControl>
                            }

                            {isSelectedUserAFHUser &&
                                <Grid item xs={12} className={classes.positionRelative}>
                                    <GmapsSearch
                                        formControlStyleClasses={classes.margin0}
                                        controlClasses={classNames(classes.gmapSearch)}
                                        controlSuggestionClasses={classes.controlSuggestionClass}
                                        textLabel={`${selectedPerson.fname}'s Physical Address`}
                                        type={GmapsSearchType.longAddress}
                                        value={homeAddressLong}
                                        onSetPlace={(homeAddressPlace: GmapsSearchAddress, useDescription: boolean) => {
                                            if (isLongAddress(homeAddressPlace)) {
                                                onHomeAddressChange(homeAddressPlace, useDescription);
                                            }
                                        }
                                        }
                                        useDescription={useAddressDescription}
                                        error={false}
                                        required={false}
                                        disabled={false}
                                        zIndex={zIndex + 1}
                                    />
                                </Grid>
                            }

                            {isSelectedUserAGOMUser && isMe &&
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.inputWithError
                                    )}
                                >
                                    <ChangeGatherViewGSwitch
                                        isViewAsGOM={isViewAsGOM}
                                        handleViewAsGOMChange={onViewAsGOMChange}
                                    />
                                </FormControl>
                            }
                        </form>
                        {isFHUser && this.renderFHContent()}
                    </Grid>
                </Grid>

                <PhotoCropper
                    scope={PhotoScopeEnum.web}
                    imageURI={uploadedImage}
                    callBackAction={this.saveNewImage}
                    isDialogOpen={isPhotoCropperOpen}
                    closeDialog={this.closePhotoCropper}
                    zIndex={zIndex + 1}
                />
            </div>
        );
    }
}

export default withGStyles(styles)(MyDetailsForm);
