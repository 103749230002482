import { DateTimeFormat, emptyStringParser, formatDateString } from "../../../../../services";
import { ConfigurableMilitaryFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { YesNoUnknownEnum } from "../../../../../shared/types";
import { OverviewSectionCommonProps } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
    hideDataIfNoMilitary?: boolean;
}
const MilitaryOverview = (props: Props) => {
    const { deathCertificate, showHeading, divider, printMode, alwaysVisible, hideDataIfNoMilitary } = props;

    const militaryService = deathCertificate.military.military || null;
    const branches: string[] | null = deathCertificate.military.militaryBranch || null;
    const rank: string | null = emptyStringParser(deathCertificate.military.militaryRank) || null;
    const yearsServed: string | null = deathCertificate.military.militaryYears
        && emptyStringParser(deathCertificate.military.militaryYears.toString()) || null;
    const msn: string | null = emptyStringParser(deathCertificate.military.militaryServiceNumber) || null;
    const honors: Array<string> | null = deathCertificate.military.militaryHonors || null;
    let scdt: string | null = null;

    const fromServiceDate = formatDateString(deathCertificate.military.serviceDateFrom || '', DateTimeFormat.LongDate);
    const toServiceDate = formatDateString(deathCertificate.military.serviceDateTo || '', DateTimeFormat.LongDate);

    if (typeof deathCertificate.military.disabilityContributedToDeath === 'boolean') {
        scdt = deathCertificate.military.disabilityContributedToDeath ? 'Yes' : 'No';
    }

    if (hideDataIfNoMilitary
        && (militaryService === YesNoUnknownEnum.No || militaryService === YesNoUnknownEnum.Unknown)
    ) {
        return (
            <div>
                <SectionTypography
                    variant="body1"
                    style={{
                        fontWeight: 400,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'capitalize',
                        fontFamily: 'system-ui, sans-serif',
                    }}
                >
                    <OverviewLabel
                        label="Military Service"
                        html={militaryService}
                        alwaysVisible={alwaysVisible}
                        fieldKey={undefined}
                    />
                </SectionTypography>
            </div>
        );
    }

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    MILITARY
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Military Service"
                    html={militaryService}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Branch"
                    html={branches}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.militaryBranch}
                />

                <OverviewLabel
                    label="Highest Military Rank"
                    html={rank}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.militaryRank}
                />

                <OverviewLabel
                    label="Service Date (From)"
                    html={fromServiceDate}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.fromServiceDate}
                />

                <OverviewLabel
                    label="Service Date (To)"
                    html={toServiceDate}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.toServiceDate}
                />

                <OverviewLabel
                    label="Years Served"
                    html={yearsServed}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.yearsServed}
                />

                <OverviewLabel
                    label="Military Service Number"
                    html={msn}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.militaryServiceNumber}
                />

                <OverviewLabel
                    label="Military Honors"
                    html={honors}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.militaryHonors}
                />

                <OverviewLabel
                    label="Did service-connected disability contribute to death"
                    html={scdt}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMilitaryFieldKey.serviceConnectedDisability}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default MilitaryOverview;