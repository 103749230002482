import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import { ORANGE_COLOR, SUCCESS_COLOR } from '../../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        height: 36,
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&$small': {
            height: 28,
            '&$extended': {
                padding: '0 6px 0 12px',
                '& svg': {
                    fontSize: 22
                },
                '& p': {
                    fontSize: 12,
                }
            }
        },
        '&$round': {
            width: 36,
            borderRadius: '50%'
        },
        '&$extended': {
            padding: '4px 6px 4px 12px',
            maxWidth: 'fit-content',
            borderRadius: 24,
            display: 'flex',
            '& svg': {
                fontSize: 26,
            },
            '& p': {
                fontSize: 16,
                margin: 'auto 4px',
                lineHeight: '26px',
            }
        },
        '&$default': {
            maxWidth: 'fit-content',
            padding: '8px 24px',
            borderRadius: 8,
            '& p': {
                fontSize: 16
            }
        },
        '&$primary': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            border: '1px solid transparent'
        },
        '&$inverted': {
            background: theme.palette.common.white,
            color: theme.palette.primary.main,
            border: '1px solid',
        },
        '&$orange': {
            background: `${ORANGE_COLOR}`,
            color: theme.palette.common.white,
            border: '1px solid transparent'
        },
        '&$orangeInverted, &$green': {
            background: theme.palette.common.white,
            width: 28,
            height: 28,
        },
        '&$orangeInverted': {
            color: ORANGE_COLOR,
        },
        '&$green': {
            color: SUCCESS_COLOR,
        },
        '& $icon': {
            '&$default': {},
            '&$precedent': {},
        }
    },
    primary: {},
    inverted: {},
    orange: {},
    orangeInverted: {},
    green: {},
    round: {},
    extended: {},
    default: {},
    precedent: {},
    icon: {},
    small: {}
});

type StyledProps = Props & WithGStyles<'root' | 'divider' | 'label' | 'primary' | 'inverted' | 'round' | 'precedent'
    | 'default' | 'icon' | 'small' | 'orangeInverted' | 'green'>;

interface Props {
    color: 'primary' | 'inverted' | 'orange' | 'orangeInverted' | 'green';
    variant: 'round' | 'extended' | 'default';
    icon?: {
        root: JSX.Element;
        position?: 'precedent';
        size?: number;
    };
    label?: string;
    size?: 'small';
    fontSize?: number;
    fontWeight?: number;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Badge = (props: StyledProps) => {
    const {
        classes,
        color,
        variant,
        label,
        icon,
        size,
        onClick,
        fontSize,
        fontWeight
    } = props;

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            onClick={onClick}
        >
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.root,
                    classes[color],
                    classes[variant],
                    size && classes[size]
                )}
            >
                {icon && icon.position === 'precedent' &&
                    <SvgIcon
                        className={classNames(
                            classes[icon.position],
                            classes.icon
                        )}
                        style={{ fontSize: icon.size }}
                    >
                        {icon.root}
                    </SvgIcon>
                }

                {label &&
                    <Typography
                        color="inherit"
                        style={{
                            fontSize,
                            fontWeight
                        }}
                    >
                        {label}
                    </Typography>
                }

                {icon && !icon.position &&
                    <SvgIcon
                        className={classNames(classes.default, classes.icon)}
                        style={{ fontSize: icon.size }}
                    >
                        {icon.root}
                    </SvgIcon>
                }
            </Grid>
        </Grid >
    );
};

export default withGStyles(styles)(Badge);
