import { find, orderBy, take, takeRight, min } from 'lodash';
import Dinero from 'dinero.js';
import {
    PricingModelEnum,
    ProductContractItemUX,
    ProductContractUX,
    ProductPackageUX,
    ProductSubPackage,
    ProductTaxRateUX,
    ProductTaxRateBracketRequest,
    ProductSummary,
    AssetType,
} from '../types';
import { getContractItemsForPackage } from './packages';
import { isPackageDiscount, isPackagePlaceholder, isAllowanceCredit } from './utils';

const DEFAULT_FORMAT = '$0,0.00';

export const getDinero = (price: number, assetType: string): Dinero.Dinero => {
    let priceAsInteger = Math.round(price);
    if (isNaN(priceAsInteger)) {
        console.warn('getDinero price is NaN');
        priceAsInteger = 0;
    }
    return Dinero({
        amount: priceAsInteger,
        currency: assetType,
    });
};

export const formatDinero = (dinero: Dinero.Dinero, format?: string) =>
    dinero.toFormat(format || DEFAULT_FORMAT);

export const formatPrice = (price: number, assetType: string, format?: string) =>
    formatDinero(getDinero(price, assetType), format);

const pennyMatcher = /^[^\-\.\d]*(-?[\d,]*\.?\d?\d?)/;
export function toPennyFormat(input: string): string {
    const result = input.match(pennyMatcher);
    if (result && result.length === 2) {
        return result[1].replace(',', '');
    } else {
        return input;
    }
}

export function toPennyValue(input: string): Dinero.Dinero {
    const amount = Math.round(parseFloat(toPennyFormat(input)) * 100);
    if (isNaN(amount)) {
        return Dinero({ amount: 0.0, currency: 'USD' });
    } else {
        return Dinero({ amount, currency: 'USD' });
    }
}

export const getPackagePrice = (productPackage: ProductPackageUX | ProductSubPackage) => {
    if (productPackage.price === null) {
        return null;
    }
    return Dinero({
        amount: productPackage.price,
        currency: productPackage.asset_type,
    });
};

export const getContractItemListPrice = (item: ProductContractItemUX) => Dinero({
    amount: item.list_price,
    currency: item.asset_type,
});

export const getContractItemPriceAdjustment = (item: ProductContractItemUX) => Dinero({
    amount: item.price_adjustment,
    currency: item.asset_type,
});

export const getContractSubTotal = (contract: { sub_total: number | null; asset_type: AssetType }) => Dinero({
    amount: contract.sub_total || 0,
    currency: contract.asset_type,
});

export const getContractTaxTotal = (contract: { tax_total: number | null; asset_type: AssetType }) => Dinero({
    amount: contract.tax_total || 0,
    currency: contract.asset_type,
});

export const getContractItemPriceTotal = (item: ProductContractItemUX) => {
    return getContractItemListPrice(item).add(getContractItemPriceAdjustment(item));
};

export const calculateProductListPrice = (product: ProductSummary, quantity: number): Dinero.Dinero | null => {
    const { pricing_model, asset_type, base_price, var_price, var_increment, base_quantity } = product;

    const basePrice = base_price !== null ? Dinero({ amount: base_price, currency: asset_type }) : undefined;
    const variablePrice = var_price !== null ? Dinero({ amount: var_price, currency: asset_type }) : undefined;

    switch (pricing_model) {

        case PricingModelEnum.fixed:
        case PricingModelEnum.allowance:
            if (!basePrice) {
                return null;
            }
            return basePrice;

        case PricingModelEnum.base_plus_variable:
            if (!basePrice || !variablePrice || !var_increment) {
                return null;
            }

            const adjQuantity = quantity - (base_quantity || 0);
            const variableAmount = adjQuantity <= 0 ?
                Dinero() // 0
                : variablePrice.multiply(Math.ceil(adjQuantity / var_increment));
            return basePrice.add(variableAmount);

        case PricingModelEnum.variable:
            if (!variablePrice || !var_increment) {
                return null;
            }
            // use var_increment if quantity == 0 - required for package pricing to work correctly
            return variablePrice.multiply(Math.ceil((quantity || var_increment) / var_increment));
        case PricingModelEnum.manual:
        default:
            return null;
    }
};

export const calculateContractItemListPrice = (item: ProductContractItemUX, quantity?: number) => {
    // if manual pricing -> product will not have a valid list price
    if (item.product && item.product.pricing_model !== PricingModelEnum.manual) {
        return calculateProductListPrice(item.product, quantity || item.quantity);
    } else {
        return getContractItemListPrice(item);
    }
};

// get the list price of the contractItem based on the quantity included with the package or the quantity
export const calculateContractItemPackageListPrice = (item: ProductContractItemUX) => {
    // if the item has a quantity_in_package then use that, but only if quantity > quantity_in_package
    // this is because increases in quantity above the included amount should not be included in package price
    // and a quantity below the included amount should also not change the package price
    const effectiveQuantity = !item.quantity_in_package ?
        item.quantity : min([item.quantity_in_package, item.quantity]);
    return calculateContractItemListPrice(item, effectiveQuantity);
};

// This function also exists in a SQL fn as "calculate_contract_tax" (used for calculating contract-level tax)
export const applyTaxRate = (priceInCents: number, taxRate: ProductTaxRateUX): number => {
    let taxTotal = 0.0;
    for (const bracket of taxRate.brackets) {
        const minimum = bracket.minimum;
        const maximum = bracket.maximum || null;
        if (priceInCents <= minimum) {
            continue; // skip if minimum is larger than the price
        }
        let amountForBracket;
        if (maximum !== null && priceInCents > maximum) {
            amountForBracket = maximum - minimum;
        } else {
            amountForBracket = priceInCents - minimum;
        }
        const taxForBracket = amountForBracket * (bracket.rate / 100);
        taxTotal = taxTotal + taxForBracket;
    }
    return taxTotal; // returns taxTotal with fractional cents as decimal
};

export const calculateTaxes = (
    priceInCents: number,
    taxRateId: number,
    taxRates: ProductTaxRateUX[],
): { taxes: number; taxRate: ProductTaxRateUX | null } => {

    const taxRate = find(taxRates, (tr) => tr.id === taxRateId);

    if (!taxRate) {
        return { taxes: 0.0, taxRate: null };
    }

    const taxes = applyTaxRate(priceInCents, taxRate);

    return { taxes, taxRate };
};

export const calculateTotalForItems = (contractItems: ProductContractItemUX[]): Dinero.Dinero => {
    const validItems = contractItems.filter((item) => !item.delete_revision);

    const reducer = (accumulator: Dinero.Dinero, item: ProductContractItemUX) =>
        accumulator.add(getContractItemPriceTotal(item));
    return validItems.reduce(reducer, Dinero());
};

export const calculateContractTotal = (contract: ProductContractUX) => {
    const subTotal = getContractSubTotal(contract);
    const taxTotal = getContractTaxTotal(contract);
    return subTotal.add(taxTotal);
};

export const calculateAndDisplayContractTotal = (
    contract: ProductContractUX,
    format?: string,
) => {
    const grandTotal = calculateContractTotal(contract);
    return formatDinero(grandTotal, format);
};

export const calculateAndDisplayTotalForItems = (
    contractItems: ProductContractItemUX[],
    format?: string,
): string => {
    return formatDinero(calculateTotalForItems(contractItems), format);
};

export const getProductDisplayPrice = (product: ProductSummary, format?: string): string => {

    const price = calculateProductListPrice(product, product.var_default_quantity || 0);
    if (price) {
        return formatDinero(price, format);
    } else if (product.pricing_model === PricingModelEnum.manual) {
        return 'Specify Price';
    } else {
        return 'Price Unknown';
    }
};

export const calculateProductPackagePrice = (product: ProductSummary): Dinero.Dinero | null => {

    return calculateProductListPrice(product, product.var_default_quantity || 0);
};

export const getContractItemDisplayPrice = (item: ProductContractItemUX, format?: string): string =>
    formatDinero(getContractItemPriceTotal(item), format);

export const getPackageDisplayPrice = (
    productPackage: ProductPackageUX | ProductSubPackage,
    format?: string,
): string | null => {
    const price = getPackagePrice(productPackage);
    return price ? formatDinero(price, format) : null;
};

export const getDisplayPrice = (
    product?: ProductSummary,
    contractItem?: ProductContractItemUX | null,
    format?: string,
): string => {
    return contractItem ? getContractItemDisplayPrice(contractItem, format)
        : product ? getProductDisplayPrice(product, format) : '';
};

export const getVariablePriceDisplay = (product: ProductSummary) => {
    const {
        pricing_model,
        var_price,
        var_increment,
        var_increment_units,
        base_quantity,
        asset_type,
    } = product;

    if (var_price === null || pricing_model === PricingModelEnum.fixed) {
        return null;
    }

    const incrementStr = var_increment && var_increment > 1 ? `${var_increment} ` : '';
    const needsS = Boolean(var_increment_units && !var_increment_units.endsWith('s'));
    const unitsStr = `${var_increment_units || ''}${incrementStr && needsS ? 's' : ''}`;
    const varMinimumStr = base_quantity
        ? ` over ${base_quantity} ${var_increment_units || ''}${needsS ? 's' : ''}`
        : '';

    const varPrice = formatPrice(var_price, asset_type);
    return `${varPrice} per ${incrementStr}${unitsStr}${varMinimumStr}`;
};

interface PackageItemForListPriceRange {
    max_selections: number;
    options: {
        product: ProductSummary | null;
        sub_package: ProductSubPackage | null;
    }[];
}

export const calculateListPriceRangeOfPackage = (packageItems: PackageItemForListPriceRange[]) => {
    let minPrice = Dinero();
    let maxPrice = Dinero();

    packageItems.forEach((item) => {
        const itemPrices: Dinero.Dinero[] = item.options.map((opt): Dinero.Dinero | null => {
            const { product, sub_package } = opt;
            if (product) {
                return calculateProductPackagePrice(product);
            }
            if (sub_package) {
                // TODO: if subPackage doesn't have a price the subPackage value of items won't be added
                return getPackagePrice(sub_package);
            }
            return null;
        }).filter((price): price is Dinero.Dinero => price !== null);
        const sortedItemPrices = orderBy(itemPrices, (d) => d.getAmount());
        // X = max_selections
        // pick the lowest X item prices (first X)
        const minItemPricesToAdd = take(sortedItemPrices, item.max_selections);
        minItemPricesToAdd.forEach((minimum) => {
            minPrice = minPrice.add(minimum);
        });
        // pick the highest X item prices (last X)
        const maxItemPricesToAdd = takeRight(sortedItemPrices, item.max_selections);
        maxItemPricesToAdd.forEach((max) => {
            maxPrice = maxPrice.add(max);
        });
    });

    return [minPrice, maxPrice];
};

export const calculatePackagePercentDiscount = (packagePrice: Dinero.Dinero, maxListPrice: Dinero.Dinero) => {
    return Math.round(100 * (1 - packagePrice.getAmount() / maxListPrice.getAmount()));
};

// get the package's list price total for all items added to the contract - not including package discount/premium
export const calcListPriceOfContractItems = (contractItems: ProductContractItemUX[]): Dinero.Dinero => {

    // only include non-deleted, package items that are not a package discount or allowance credit
    const validItems = contractItems.filter((item) =>
        !item.delete_revision && !isPackageDiscount(item) && !isPackagePlaceholder(item) &&
        !isAllowanceCredit(item) && item.package_id);

    const reducer = (accumulator: Dinero.Dinero, item: ProductContractItemUX) => {
        const listPrice = calculateContractItemPackageListPrice(item);
        if (listPrice) {
            return accumulator.add(listPrice);
        }
        return accumulator;
    };
    return validItems.reduce(reducer, Dinero());
};

// get the difference between package price and the list price of all the products that are in the package
export const calculatePackageAdjustment = (
    pkg: ProductPackageUX,
    contractItems: ProductContractItemUX[],
): { adjustment: Dinero.Dinero; adjustmentPercent: number } => {

    const packagePrice = getPackagePrice(pkg);
    if (!packagePrice) {
        // if packagePrice is null that means there is no package price and therefore no discount/premium
        return { adjustment: Dinero(), adjustmentPercent: 0 };
    }
    const contractItemsForPackage = getContractItemsForPackage(contractItems, pkg.id);

    const priceOfContractItems = calcListPriceOfContractItems(contractItemsForPackage);

    const adjustment = packagePrice.subtract(priceOfContractItems);
    const adjustmentPercent = adjustment.getAmount() / priceOfContractItems.getAmount();
    return { adjustment, adjustmentPercent };
};

export const calculateAllowanceCredit = (
    allowanceItem: ProductContractItemUX,
    contractItems: ProductContractItemUX[],
): Dinero.Dinero | null => {
    if (!allowanceItem.product || allowanceItem.product.base_price === null) {
        return null;
    }

    const itemsUsingAllowance = contractItems.filter((item) => {
        return !item.delete_revision && !isAllowanceCredit(item) && item.allowance_item === allowanceItem.id;
    });
    const total = calculateTotalForItems(itemsUsingAllowance);
    const creditAmount = -1 * (min([total.getAmount(), allowanceItem.product.base_price]) || 0);
    return Dinero({ amount: creditAmount, currency: allowanceItem.asset_type });
};

export const getProductQuantity = (product?: ProductSummary, contractItem?: ProductContractItemUX) => {

    if (contractItem) {
        return contractItem.quantity;
    } else if (product) {
        return product.var_default_quantity || product.base_quantity || 1;
    } else {
        return 1; // shouldn't happen
    }
};

export const calcTaxForContractItem = (
    contractItem: ProductContractItemUX,
    contractItems: ProductContractItemUX[],
    packages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
): { taxes: number; taxRate: ProductTaxRateUX | null } => {

    const { product } = contractItem;
    const taxRateId = contractItem.tax_rate_id || (product && product.tax_rate_id || null);

    if (!taxRateId) {
        return { taxes: 0.0, taxRate: null };
    }

    let listPrice: number = contractItem.list_price;
    if (contractItem.package_id) {
        const pkg = find(packages, (p) => p.id === contractItem.package_id);
        // get item's list price based on this package (this FN is also used to determine pkg discount)
        const itemPkgListPrice = calculateContractItemPackageListPrice(contractItem);
        if (!itemPkgListPrice || !pkg) {
            return { taxes: 0.0, taxRate: null };
        }

        const { adjustmentPercent } = calculatePackageAdjustment(pkg, contractItems);

        // apply discount/premium % to the item's package list price
        const itemAdjustment = itemPkgListPrice.multiply(adjustmentPercent);
        const adjustedPkgListPrice = itemPkgListPrice.add(itemAdjustment);

        // add adjusted package list price to the remaining list price (applies if quantity > default_quantity)
        const fullListPrice = getContractItemListPrice(contractItem);
        const itemNonPkgListPrice = fullListPrice.subtract(itemPkgListPrice);

        // use this effective list price for the pkg instead of the item's list price
        listPrice = adjustedPkgListPrice.add(itemNonPkgListPrice).getAmount();
    }
    // take price_adjustment into account before calculating tax
    const totalPrice = getContractItemPriceTotal({ ...contractItem, list_price: listPrice });

    // taxes are calculated using the total price (including any adjustment)
    const { taxes, taxRate } = calculateTaxes(totalPrice.getAmount(), taxRateId, taxRates);

    return { taxes, taxRate };
};

export const convertTaxRateBracketsToString = (taxRate: ProductTaxRateUX): string => {
    const { asset_type, brackets } = taxRate;
    const bracketStrList = brackets.map((eq) => {
        const minStr = formatPrice(eq.minimum, asset_type, '0.00');
        const maxStr = eq.maximum ? formatPrice(eq.maximum, asset_type, '0.00') : null;
        return maxStr ? `${minStr}-${maxStr}@${eq.rate}` : `${minStr}+@${eq.rate}`;
    });
    const bracketsStr = bracketStrList.join(', ');
    return bracketsStr;
};

const BRACKET_REGEX = /^(\d+\.?\d*)(?:-(\d+\.?\d*)|\+)@(\d+\.?\d*)$/;

export const convertStringToTaxRateBrackets = (bracketsStr: string): ProductTaxRateBracketRequest[] | null => {

    const bracketStrList = bracketsStr.split(/\s*,\s*/);

    const brackets = bracketStrList.reduce((accumulator: ProductTaxRateBracketRequest[], bracketStr: string) => {
        const matches = bracketStr.match(BRACKET_REGEX);
        if (!matches) {
            return accumulator;
        }
        const minimum = matches[1];
        const maximum = matches[2];
        const rate = matches[3];

        if (!minimum || !rate) {
            return accumulator;
        }

        accumulator.push({
            minimum: Math.round(Number(minimum) * 100),
            maximum: maximum ? Math.round(Number(maximum) * 100) : null,
            rate: Number(rate),
        });
        return accumulator;
    }, []);
    if (brackets.length !== bracketStrList.length) {
        console.warn('Bad tax brackets', JSON.stringify({ brackets, bracketStrList }));
        return null;
    }
    return brackets;
};

export const displayTaxRate = (taxRate: ProductTaxRateUX | null): string => {
    return taxRate ? `${taxRate.name} (${taxRate.description || ''})` : '';
};
