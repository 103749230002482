import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { GLOBAL_STYLED_PROPS } from '../../../../../styles';
import withGStyles from '../../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, ContractItemGridProps> = theme => ({
    root: {},
    item: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        '& $label': {
            textTransform: 'capitalize',
            fontSize: 14,
            lineHeight: '1.25em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                '& span': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            },
            '& svg': {
                fontSize: 16,
                marginRight: 4,
                cursor: 'pointer',
                width: 16,
            },
        },
        '& $iconButton': {
            marginLeft: 4,
            height: 24,
            width: 24,
            '& svg': {
                fontSize: 16,
                margin: '0 !important'
            },
        },
    },
    chip: {
        color: theme.palette.secondary.main,
        height: '22px !important',
        border: '1px solid',
        borderRadius: '16px !important',
        boxSizing: 'border-box !important' as 'border-box',
        padding: '0 4px !important',
        fontSize: '12px !important',
        // TODO(Jonathan): fix types in material ui v5
        fontWeight: '500 !important' as unknown as number,
        lineHeight: '1em !important',
        textTransform: 'capitalize',
        cursor: 'pointer',
        '&$dashed': {
            border: '1px dashed',
        },
        '& svg': {
            fontSize: 16,
            margin: '0 !important'
        },
        '& span': {
            padding: '0 4px 0 0',
            textDecoration: 'none !important',
        }
    },
    dashed: {},
    label: {},
    iconButton: {},
});

export type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'item' | 'label' | 'iconButton'
    | 'chip' | 'dashed'>;

interface ContractItemGridProps {
    caseFirstName: string;
    isContractFrozen: boolean;
    amount: string;
    label: string;
    isPrintMode: boolean;
    openDetailMenu: (anchorEle: HTMLElement) => void;
}

const ContractItem = (props: ContractItemGridProps & StyledProps) => {

    const { classes, isContractFrozen, label, amount, openDetailMenu, caseFirstName, isPrintMode } = props;

    return (
        <Grid
            item
            xs={12}
            className={classes.item}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                margin: 'auto',
                maxWidth: 420
            }}
        >
            <Tooltip
                title={`Click to view ${caseFirstName}'s statement`}
                placement="top"
                enterDelay={500}
                leaveDelay={200}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.label,
                        !isPrintMode && classes.chip,
                        !isContractFrozen && !isPrintMode && classes.dashed
                    )}
                    onClick={e => openDetailMenu(e.currentTarget)}
                    style={{
                        textTransform: 'capitalize',
                        fontSize: isPrintMode ? 12 : 14,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: isPrintMode ? 200 : 400,
                        margin: isPrintMode ? 0 : undefined
                    }}
                >
                    {isContractFrozen ?
                        <LockIcon
                            style={{ verticalAlign: 'text-top', width: 16 }}
                            color="primary"
                        />
                        :
                        <LockOpenIcon
                            style={{ verticalAlign: 'text-top', width: 16 }}
                            color="primary"
                        />}
                    &nbsp;<span>{label}</span>
                </Typography>
            </Tooltip>

            <Typography
                color="secondary"
                className={classNames(
                    classes.label,
                    classes.fontWeight500,
                    'notranslate'
                )}
                style={{
                    fontSize: isPrintMode ? 12 : 14,
                    fontWeight: isPrintMode ? 200 : 400,
                    margin: isPrintMode ? 0 : undefined
                }}
            >
                {amount}
                {!isPrintMode && <IconButton
                    className={classNames(
                        classes.iconButton,
                        'icon-button',
                    )}
                    id="view-contract-more-button"
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={(e) => {
                        e.stopPropagation();
                        openDetailMenu(e.currentTarget);
                    }}
                    size="large">
                    <MoreVertIcon />
                </IconButton>}
            </Typography>
        </Grid >
    );
};

export default withGStyles(styles)(ContractItem);
