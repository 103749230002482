import { useGStyles } from '../../../../common/hooks/useGStyles';
import { OnlineAccountType, ResetPasswordStepsProps } from '../utils';
import ResetPasswordStepsContent from '../widgets/ResetPasswordStepsContent';


const ResetPasswordSteps = (props: ResetPasswordStepsProps) => {
    const { activeCase, onStepChange } = props;

    const classes = useGStyles();

    const steps = [
        <>
            <a
                href="https://iforgot.apple.com/password/verify/appleid"
                target="blank"
                className={classes.colorSecondary}
            >
                Click here
            </a>
            {' '} to reset {activeCase.fname}’s {OnlineAccountType.Apple} password
        </>,
        `Enter the email address that ${activeCase.fname} used for Apple`,
        <>
            Follow the instructions given by Apple. Please note that you may need to access another one
            of {activeCase.fname}'s Apple devices (like an iPhone) in order to complete the password reset.
        </>,

    ];

    return (
        <ResetPasswordStepsContent
            caseFName={activeCase.fname}
            accountType={OnlineAccountType.Apple}
            steps={steps}
            onReturnClick={onStepChange}
            onAbleToLogin={onStepChange}
            onUnableToLogin={onStepChange}
        />
    );
};

export default ResetPasswordSteps;
