import { PropsWithChildren } from "react";
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

import { GREEN_COLOR_4, RED_COLOR } from "../../../../../constants/colorVariables";
import BorderedSection from "../../../../common/BorderedSection";

const useStyles = makeStyles<Theme, Props>(theme => ({
    root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        boxSizing: props => props.extendedWidth ? 'border-box' : undefined,
        maxWidth: props => props.extendedWidth ? undefined : 425,
        border: props => props.hideBorder ? 'none' : `2px solid`,
        color: props => props.error
            ? RED_COLOR
            : props.success
                ? GREEN_COLOR_4
                : theme.palette.primary.main,
        borderRadius: 12,
        perspective: 600,
        WebkitPerspective: 600,
        width: props => `calc(100% - 24px) ${props.extendedWidth ? '!important' : ''}`,
    },
    heading: {
        color: props => props.error
            ? RED_COLOR
            : props.success
                ? GREEN_COLOR_4
                : 'inherit'
    },
}), { name: 'StepComponentWrapper' });

interface Props {
    className?: string;
    error?: boolean;
    success?: boolean;
    heading?: string;
    hideBorder?: boolean;
    extendedWidth?: boolean;
    intercomTargetProp?: string;
}
const StepComponentWrapper = (props: PropsWithChildren<Props>) => {
    const { children, heading, className, intercomTargetProp } = props;

    const classes = useStyles(props);

    return (
        <BorderedSection
            heading={heading}
            classes={{
                root: classNames(classes.root, className),
                heading: classes.heading
            }}
            intercomTargetProp={intercomTargetProp}
        >
            {children}
        </BorderedSection>
    );
};

export default StepComponentWrapper;