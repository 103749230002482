import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanLoginProps } from '../utils';
import CanLoginWrapper from '../widgets/CanLoginWrapper';

const CanLogin = (props: CanLoginProps) => {
    const { accountType, activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanLoginWrapper
            accountType={accountType}
            caseFName={activeCase.fname}
            onStepChange={onStepChange}
        >
            <Typography
                align="left"
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Since you have the ability to log in to {activeCase.fname}'s {accountType} account, this process
                will be much faster. {accountType} has created a data and privacy site that will walk you through
                each step of deleting {activeCase.fname}'s account in just a few minutes. Simply click the link
                below to get started.
            </Typography>
            <a
                href="https://privacy.apple.com/"
                target="blank"
                className={classNames(
                    classes.cursorPointer,
                    classes.textDecorationNone,
                )}
            >
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classNames(
                        classes.button,

                        classes.marginTop20
                    )}
                >
                    Click to Begin Account Deletion
                </Button>
            </a>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Important. Please read.
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            After deleting {activeCase.fname}'s account, you will not be able to access iTunes,
                            iBooks, and App Store purchases.
                        </li>
                        <li className={classes.marginTop10}>
                            {activeCase.fname}'s photos, videos and documents stored in iCloud will be
                            permanently deleted.
                        </li>
                        <li className={classes.marginTop10}>
                            We recommend downloading any of this content prior to deleting {activeCase.fname}'s
                            account.
                        </li>
                        <li className={classes.marginTop10}>
                            You will not receive messages sent to {activeCase.fname}'s account via iMessage,
                            FaceTime, or iCloud Mail.
                        </li>
                        <li className={classes.marginTop10}>
                            You will not be able to sign in or use services such as iCloud, iTunes, iBooks, the
                            App Store, {accountType} Pay, iMessage, FaceTime, and Find My iPhone.
                        </li>
                        <li className={classes.marginTop10}>
                            {activeCase.fname}'s data associated with {accountType} services will be permanently
                            deleted. Some exceptions may apply.
                        </li>
                        <li className={classes.marginTop10}>
                            Deletion does not cancel any repairs or {accountType} Store orders.
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Typography
                color="secondary"
                align="left"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop20
                )}
            >
                {'If you have any questions, '}
                <a
                    href={'tel:1-800-692-7753'}
                    className={classes.colorSecondary}
                >
                    {'click here'}
                </a>
                {' to call Apple directly (1-800-692-7753)'}
            </Typography>
        </CanLoginWrapper>
    );
};

export default CanLogin;
