import classNames from 'classnames';

import { RED_COLOR } from '../../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../../services/app.service';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import { TaskPreview } from '../../../../shared/types';
import moment from 'moment-timezone';
import { DateTimeFormat, getStandardDateText } from '../../../../shared/utils';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import withGStyles from '../../../../styles/WithGStyles';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    taskBorderRight: {
        color: theme.palette.primary.main,
        borderRight: '1px solid',
    },
    taskSectionBorderRadius: {
        borderRadius: '12px 0px 0px 12px',
    },
    badgeRoot: {
        display: 'flex',
        verticalAlign: 'middle',
        position: 'relative',
    },
    badge: {
        left: 'unset',
        top: 4,
        right: 4,
        borderRadius: 50,
        color: theme.palette.common.white,
        background: RED_COLOR,
        height: 28,
        width: 28,
        fontSize: 12,
    },
    avatarBadge: {
        left: 'unset',
        top: 13,
        right: 6,
        borderRadius: 50,
        color: theme.palette.common.white,
        background: RED_COLOR,
        height: 20,
        width: 20,
        fontSize: 12,
    },
    avatarRightSection: {
        paddingLeft: 8,
        '& $nameSection': {
            fontSize: 18,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    taskSection: {
        padding: '8px 4px 0px',
        borderBottom: '1px solid',
        color: convertHexToRGBA(theme.palette.common.white, 0.3),
        '&:last-child': {
            color: theme.palette.common.white,
            borderBottom: '1px solid',
        },
    },
    lineHeightForAvatar: {
        lineHeight: 1.25,
    },
    listSubHeader: {
        textTransform: 'uppercase',
        fontWeight: 400,
        lineHeight: '20px',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginBottom: '1px',
    },
    padding4: {
        padding: 4,
    },
    avatarDiv: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: 12,
        },
    },
    taskCaseBackground: {
        background: theme.palette.primary.light,
        color: convertHexToRGBA(theme.palette.common.white, 0.3),
        borderBottom: '1px solid',
    },
    avatarSize: {
        height: 40,
        width: 40,
        fontSize: 16,
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    iconButtonWidth: {
        maxHeight: 36,
        maxWidth: 36,
    },
    flexTaskSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconSize: {
        alignSelf: 'flex-end',
        display: 'flex',
        '& svg': {
            fontSize: 24,
            color: theme.palette.common.white,
        },
    },
    subtitle: {},
    nameSection: {},
    lineHeight: {},
});

interface Props {
    task: TaskPreview;
    onComplete: () => void;
    onSkip: () => void;
}

type StyledProps = Props &
    GLOBAL_STYLED_PROPS &
    WithStyles<
        | 'root'
        | 'badgeRoot'
        | 'badge'
        | 'avatarBadge'
        | 'avatarRightSection'
        | 'taskSection'
        | 'nameSection'
        | 'lineHeight'
        | 'avatarSize'
        | 'flexTaskSection'
        | 'listSubHeader'
        | 'padding4'
        | 'avatarDiv'
        | 'lineHeightForAvatar'
        | 'taskCaseBackground'
        | 'iconButtonWidth'
        | 'iconSize'
        | 'subtitle'
        | 'flexDayEvents'
    >;

const Task = (props: StyledProps) => {
    const { classes, task, onComplete, onSkip } = props;

    const dueDateString =
        task.complete_by_time &&
        getStandardDateText({
            timezone: moment.tz.guess(),
            targetDate: task.complete_by_time,
            prefix: 'Due',
            pastPrefix: ':',
        });
    const isOverdue = dueDateString?.startsWith(':');

    return (
        <div className={classes.taskSection} key={task.task_id}>
            <Typography
                component="p"
                color="primary"
                className={classNames(classes.fontWeight400, classes.lineHeightForAvatar, classes.fontSize18)}
            >
                {task.task_title}
            </Typography>

            {task.complete_by_time &&
                (isOverdue ? (
                    <>
                        <Typography component="span" color="error" sx={{ display: 'inline-block' }}>
                            Overdue
                        </Typography>
                        <Typography color="#fff" fontSize={12} sx={{ display: 'inline-block' }}>
                            {dueDateString}
                        </Typography>
                    </>
                ) : (
                    <Typography color="#fff" fontSize={12}>
                        {dueDateString}
                    </Typography>
                ))}
            <div className={classes.flexTaskSection}>
                <Tooltip
                    title={moment(task.assigned_time).format(DateTimeFormat.LongDateWithTime)}
                    enterDelay={400}
                    placement="top"
                >
                    <Typography component="p" color="primary" className={classes.fontSize12}>
                        Assigned By {task.assigned_by_full_name} {moment(task.assigned_time).fromNow()}
                    </Typography>
                </Tooltip>
                <div className={classNames(classes.iconSize, classes.cursorPointer)}>
                    <Tooltip title={`Click to complete this task`} placement="top" enterDelay={400}>
                        <DoneIcon onClick={onComplete} />
                    </Tooltip>
                    <Tooltip title={`Click to skip this task`} placement="top" enterDelay={400}>
                        <ClearIcon onClick={onSkip} />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default withGStyles(styles)(Task);
