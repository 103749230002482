import { GatherCaseUX, FuneralHomeUX } from '../shared/types';
import { AppDispatch } from '../store';

export const OPEN_ACCESS_RESTRICTED_DIALOG_STATE = 'OPEN_ACCESS_RESTRICTED_DIALOG_STATE';
export type OPEN_ACCESS_RESTRICTED_DIALOG_STATE = typeof OPEN_ACCESS_RESTRICTED_DIALOG_STATE;

interface OpenAccessRestrictedDialogState {
    type: OPEN_ACCESS_RESTRICTED_DIALOG_STATE;
    zIndex: number;
}

export function openAccessRestrictedDialogState(zIndex: number): OpenAccessRestrictedDialogState {
    return {
        type: OPEN_ACCESS_RESTRICTED_DIALOG_STATE,
        zIndex
    };
}

export const openAccessRestrictedDialog = (zIndex: number) => {
    return async (dispatch: AppDispatch) => {
        dispatch(openAccessRestrictedDialogState(zIndex));
    };
};

export const CLOSE_ACCESS_RESTRICTED_DIALOG_STATE = 'CLOSE_ACCESS_RESTRICTED_DIALOG_STATE';
export type CLOSE_ACCESS_RESTRICTED_DIALOG_STATE = typeof CLOSE_ACCESS_RESTRICTED_DIALOG_STATE;

interface CloseAccessRestrictedDialogState {
    type: CLOSE_ACCESS_RESTRICTED_DIALOG_STATE;
}

export function closeAccessRestrictedDialogState(): CloseAccessRestrictedDialogState {
    return {
        type: CLOSE_ACCESS_RESTRICTED_DIALOG_STATE
    };
}

export const closeAccessRestrictedDialog = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(closeAccessRestrictedDialogState());
    };
};

export function isDemoMode(activeFuneralHome: FuneralHomeUX | null, activeCase: GatherCaseUX | null): boolean {
    if (activeFuneralHome) {
        return activeFuneralHome.is_demo;
    } else if (activeCase) {
        return activeCase.funeral_home.is_demo;
    } else {
        return false;
    }
}

export type AccessRestrictedAction = OpenAccessRestrictedDialogState | CloseAccessRestrictedDialogState;
