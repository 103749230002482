import { useMemo } from 'react';
import arrayMove from 'array-move';

import { TransitionGroup } from 'react-transition-group';
import SlideZoomListTransition from '../../SlideZoomListTransition';
import { CaseTaskUX, GatherCaseUX, TaskState, TaskType, TrackingStepType } from '../../../../shared/types';
import ReorderStep from './ReorderStep';
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd, SortEvent } from 'react-sortable-hoc';

import { useGDispatch } from '../../../../types';
import partition from 'lodash/partition';
import { updateTaskRanks } from '../../../../actions/task/Task.action';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { KEEP_TRACK_TRADEMARK } from '../../../../constants';

const useStyles = makeStyles(
    (theme: Theme) => ({
        sortableContainer: {
            [theme.breakpoints.down(768)]: {
                paddingLeft: 20,
            },
        },
        marginBottom20: {
            marginBottom: 20,
        },
    }),
    { name: 'ReorderTracking' },
);

const SortableItem = SortableElement(ReorderStep);

interface SortableListProps {
    trackingSteps: CaseTaskUX[];
    hasCompletedSteps: boolean;
    openSkipStepDialog: (stepId: number) => void;
    onClick: (step: CaseTaskUX) => void;
    onDueDateClick: (stepId: number) => void;
}

type SortListProps = SortableListProps & SortableContainerProps;

const SortableList = SortableContainer<SortListProps>((props: SortListProps) => {
    const { trackingSteps, hasCompletedSteps, openSkipStepDialog, onClick, onDueDateClick } = props;

    return (
        <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {trackingSteps.map((step, index) => (
                <SortableItem
                    isFirstItem={!hasCompletedSteps && index === 0}
                    key={step.id}
                    index={index}
                    trackingStep={step}
                    openSkipStepDialog={openSkipStepDialog}
                    onClick={onClick}
                    onDueDateClick={onDueDateClick}
                />
            ))}
        </ul>
    );
});

interface Props {
    trackingSteps: CaseTaskUX[];
    selectedCase: GatherCaseUX;
    openSkipStepDialog: (stepId: number) => void;
    onClick: (step: CaseTaskUX) => void;
    onDueDateClick: (stepId: number) => void;
}

const ReorderTracking = (props: Props) => {
    const { trackingSteps, selectedCase, openSkipStepDialog, onClick, onDueDateClick } = props;

    const classes = useStyles();

    const [incompleteSteps, completedSteps] = useMemo(() => {
        const steps = trackingSteps.filter((step) => step.tracking_step_type !== TrackingStepType.finalize);
        return partition(steps, (ts) => ts.state === TaskState.incomplete);
    }, [trackingSteps]);

    const dispatch = useGDispatch();

    const handleSort = ({ oldIndex, newIndex }: SortEnd, _: SortEvent) => {
        const updatedIncompleteSteps = arrayMove(incompleteSteps, oldIndex, newIndex);
        const updatedSteps = [...completedSteps, ...updatedIncompleteSteps];
        dispatch(updateTaskRanks(updatedSteps, trackingSteps, selectedCase, TaskType.tracking_step));
    };

    if (!selectedCase.keeptrack_assigned_time) {
        return (
            <Grid container alignItems="center" justifyContent="center" flexGrow={1} className={classes.marginBottom20}>
                <Typography color="secondary" align="center" fontSize={16}>
                    Your steps will display here once you initialize a new {KEEP_TRACK_TRADEMARK}
                </Typography>
            </Grid>
        );
    }

    return (
        <TransitionGroup className={classes.sortableContainer}>
            <SlideZoomListTransition key="bulk-edit-list">
                {completedSteps.map((step, index) => (
                    <ReorderStep
                        key={step.id}
                        trackingStep={step}
                        isFirstItem={index === 0}
                        openSkipStepDialog={openSkipStepDialog}
                        onClick={onClick}
                        onDueDateClick={onDueDateClick}
                    />
                ))}

                <SortableList
                    lockAxis="y"
                    useDragHandle
                    useWindowAsScrollContainer
                    hasCompletedSteps={!!completedSteps.length}
                    onSortEnd={handleSort}
                    trackingSteps={incompleteSteps}
                    openSkipStepDialog={openSkipStepDialog}
                    onClick={onClick}
                    onDueDateClick={onDueDateClick}
                />
            </SlideZoomListTransition>
        </TransitionGroup>
    );
};

export default ReorderTracking;
