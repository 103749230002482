import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from '../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../services/app.service';
import { Theme } from '@mui/material/styles';

import { WithStyles, StyleRulesCallback } from '@mui/styles/withStyles';

export function styles<Props extends object>(): StyleRulesCallback<Theme, Props> {
    return (theme) => ({
        root: {},
        button: {
            padding: '4px 16px',
            width: 160,
        },
        pendingApprovals: {
            fontWeight: 500,
            fontSize: 20,
        },
        dialogHeaderContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '12px 0',
            '@media (min-width: 600px)': {
                margin: '20px 0',
            },
        },
        width100: {
            width: '100%',
        },
        approveColor: {
            color: GREEN_COLOR,
        },
        blockColor: {
            color: RED_COLOR,
        },
        memorySharedBy: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 12,
        },
        firstMemory: {
            borderTop: `3px Solid ${theme.palette.primary.main}`,
        },
        memory: {
            borderBottom: `1px Dashed ${theme.palette.primary.main}`,
            padding: '4px 4px 0',
            '@media (min-width: 536px)': {
                padding: '4px 0 0',
            },
            '&$lastMemory': {
                borderBottom: `2px Solid ${theme.palette.primary.main}`,
                marginBottom: 12,
            },
            '&$approved': {
                '& $condolence': {
                    color: GREEN_COLOR,
                },
                '& $sharedByContainer': {
                    '& p': {
                        color: GREEN_COLOR,
                    },
                },
                '& $userAvatar': {
                    border: `1px solid ${GREEN_COLOR}`,
                },
            },
            '&$blocked': {
                '& $condolence': {
                    color: RED_COLOR,
                },
                '& $sharedByContainer': {
                    '& p': {
                        color: RED_COLOR,
                    },
                },
                '& $userAvatar': {
                    border: `1px solid ${RED_COLOR}`,
                },
            },
        },
        visitor: {
            display: 'grid',
            padding: '6px 0 6px 8px',
            '&$approved': {
                '& $visiterAvatar': {
                    border: `1px solid ${GREEN_COLOR}`,
                },
                '& $sharedByContainer': {
                    '& p': {
                        color: GREEN_COLOR,
                    },
                },
            },
            '&$blocked': {
                color: RED_COLOR,
                '& $visiterAvatar': {
                    border: `1px solid ${RED_COLOR}`,
                },
                '& $sharedByContainer': {
                    '& p': {
                        color: RED_COLOR,
                    },
                },
            },
        },
        menuIconButton: {
            width: 24,
            height: 24,
            '& svg': {
                fontSize: 20,
            },
        },
        memoryApproveBlockButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            gridRowEnd: 4,
            gridRowStart: 3,
            gridColumnEnd: 3,
            gridColumnStart: 1,
            '@media (min-width: 700px)': {
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 2,
                gridColumnEnd: 2,
                marginRight: 8,
                justifyContent: 'flex-end',
            },
            '& button': {
                minHeight: 24,
                padding: 2,
                textTransform: 'capitalize',
            },
        },
        memoryContainer: {
            '@media (min-width: 536px)': {
                margin: '0 32px',
            },
        },
        condolence: {
            fontWeight: 300,
            fontSize: 16,
            margin: '4px 8px 12px',
            lineHeight: 1.25,
        },
        sharedByContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& p': {
                lineHeight: 1.25,
            },
        },
        tabAppbar: {
            boxShadow: 'none',
            margin: '0',
            background: theme.palette.common.white,
        },
        tabsRoot: {
            overflowX: 'auto',
            marginTop: 20,
            '@media (min-width: 600px)': {
                marginTop: 0,
            },
        },
        tabsFlexContainer: {
            justifyContent: 'center',
            overflowX: 'auto',
            minWidth: 540,
        },
        tabsScrollableContainer: {
            overflow: 'auto',
            overflowX: 'auto',
            marginBottom: '0 !important',
        },
        tabRoot: {
            textTransform: 'initial',
            fontWeight: theme.typography.fontWeightRegular,
            opacity: 1,
            margin: '0 8px',
            minHeight: 36,
            minWidth: 72,
            color: `${theme.palette.secondary.main} !important`,
            '&$approved': {
                color: `${GREEN_COLOR} !important`,
            },
            '&$blocked': {
                color: `${RED_COLOR} !important`,
            },
            '& $tabLabel': {
                fontSize: 16,
                whiteSpace: 'nowrap',
                color: 'inherit',
                '&$hasPending': {
                    color: ORANGE_COLOR,
                },
            },
        },
        displayFlex: {
            display: 'flex',
        },
        memoryOptions: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media (min-width: 400px)': {
                flexDirection: 'row',
            },
        },
        visitorOptions: {
            gridRowEnd: 2,
            gridRowStart: 1,
            gridColumnEnd: 3,
            gridColumnStart: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            '@media (min-width: 700px)': {
                gridRowStart: 1,
                gridRowEnd: 3,
                gridColumnStart: 2,
                gridColumnEnd: 2,
            },
        },
        userAvatar: {
            width: 28,
            height: 28,
            fontSize: 16,
        },
        indicatorClass: {
            '&$approved': {
                backgroundColor: GREEN_COLOR,
            },
            '&$blocked': {
                backgroundColor: RED_COLOR,
            },
        },
        fontStyleInherit: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        tabTopText: {
            lineHeight: 1,
            fontSize: 12,
        },
        visiterAvatar: {
            width: 60,
            height: 60,
        },
        visitorDetails: {
            gridRowEnd: 3,
            gridRowStart: 2,
            gridColumnEnd: 3,
            gridColumnStart: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '8px 0',
            '@media (min-width: 700px)': {
                margin: 0,
                gridRowStart: 1,
                gridRowEnd: 3,
            },
            '& p': {
                lineHeight: 1.2,
                fontSize: 16,
            },
        },
        accountCreated: {
            display: 'none',
            '@media (min-width: 700px)': {
                display: 'inline',
            },
        },
        marginLeft8: {
            marginLeft: 8,
        },
        hasPending: {},
        parentFlagDiv: {
            display: 'flex',
            justifyContent: 'center',
            margin: '3px 0px',
        },
        flagDiv: {
            backgroundColor: convertHexToRGBA(RED_COLOR, 0.18),
            padding: '2px 24px',
            borderRadius: '5px',
            border: '1px solid' + RED_COLOR,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: RED_COLOR,
            '& svg': {
                color: 'inherit',
            },
        },
        flexFlag: {
            display: 'flex',
            color: `${RED_COLOR} !important`,
            '& svg': {
                color: 'inherit',
            },
        },
        approved: {},
        blocked: {},
        tabLabel: {},
        lastMemory: {},
    });
}

export type StyledProps = WithStyles<'root'
    | 'button' | 'pendingApprovals' | 'dialogHeaderContainer' | 'width100' | 'hasPending'
    | 'approveColor' | 'blockColor' | 'memorySharedBy' | 'lastMemory' | 'memory' | 'firstMemory'
    | 'menuIconButton' | 'memoryContainer' | 'visitorOptions' | 'memoryApproveBlockButtonContainer'
    | 'condolence' | 'sharedByContainer' | 'tabLabel' | 'tabRoot' | 'displayFlex' | 'tabsScrollableContainer'
    | 'tabsFlexContainer' | 'tabsRoot' | 'tabAppbar' | 'memoryOptions' | 'userAvatar' | 'approved'
    | 'blocked' | 'fontStyleInherit' | 'indicatorClass' | 'tabTopText' | 'marginLeft8'
    | 'visiterAvatar' | 'visitor' | 'visitorDetails' | 'accountCreated' | 'flagDiv' | 'parentFlagDiv'
    | 'fontWeight400' | 'flexFlag'>;
