import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DevicesIcon from '@mui/icons-material/Devices';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';

import { DATA_PRIVATE } from '../../../../../styles/Helper.styles';
import FuneralHomeLogo from '../../../../common/FuneralHomeLogo';
import { SelfLoginStyledProps } from '../SelfLogin.styles';
import { ExistingUserStep } from '../SelfLoginDialog';
import { useState } from 'react';

interface Props extends SelfLoginStyledProps {
    hasFHLogo: boolean;
    isActive: boolean;
    invalidCredentials: boolean;
    hasLoginDetailsError: boolean;
    loginErrorMessage: string;
    existingUserLoginEmailPhone: string;
    password: string;
    isLoading: boolean;
    resetInvalidFlag: () => void;
    goToSubStep: (subStep: ExistingUserStep) => void;
    goBack: () => void;
    attemptLogin: () => void;
    setPassword: (password: string) => void;
    setExistingUserEmailPhone: (data: string) => void;
}

const LoginStep = (props: Props) => {
    const {
        classes,
        hasFHLogo,
        password,
        hasLoginDetailsError,
        existingUserLoginEmailPhone,
        loginErrorMessage,
        invalidCredentials,
        isActive,
        isLoading,
        setPassword,
        resetInvalidFlag,
        goBack,
        goToSubStep,
        attemptLogin,
        setExistingUserEmailPhone
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const emailPhone = existingUserLoginEmailPhone;

    return (
        <Fade
            timeout={600}
            in={isActive}
            appear
            mountOnEnter
            unmountOnExit
        >
            <Grid item className={classNames(classes.loginDetailsContainer, classes.login)}>
                <Grid
                    item
                    xs={12}
                    className={classNames(classes.userDetails, classes.marginBottom20)}
                >
                    <Typography className={classes.fontSize16}>Welcome back!</Typography>
                    <Typography
                        color="secondary"
                        className={classes.heading}
                    >
                        Please log in to continue
                    </Typography>
                    <form
                        autoComplete="off"
                        onSubmit={e => (e.preventDefault(), attemptLogin())}
                    >
                        <FormControl
                            className={classNames(
                                classes.loginFormControl,
                                classes.loginForm,
                                classes.marginBottom10
                            )}
                            error={invalidCredentials || hasLoginDetailsError && !emailPhone}
                        >
                            <DevicesIcon color="secondary" className={classes.marginTop16} />
                            <TextField
                                label="Your Email or Mobile number"
                                color="primary"
                                fullWidth
                                value={emailPhone}
                                error={invalidCredentials || hasLoginDetailsError && !emailPhone.length}
                                onChange={evt => setExistingUserEmailPhone(evt.target.value)}
                                className={classes.loginTextField}
                                onFocus={e => resetInvalidFlag()}
                            />
                            {loginErrorMessage ?
                                <FormHelperText className={classes.loginErrorText}>
                                    {loginErrorMessage}
                                </FormHelperText>
                                : invalidCredentials ?
                                    <FormHelperText className={classes.loginErrorText}>
                                        Sorry, these credentials are invalid.
                                    </FormHelperText>
                                    : !emailPhone.length && hasLoginDetailsError &&
                                    <Typography color="error" align="center" className={classes.loginErrorText}>
                                        Email or Phone is required.
                                    </Typography>
                            }
                        </FormControl>
                        <FormControl
                            className={classNames(
                                classes.loginFormControl,
                                classes.loginForm
                            )}
                            error={invalidCredentials || hasLoginDetailsError && !password.length}
                        >
                            <LockIcon color="secondary" className={classes.marginTop16} />
                            <TextField
                                label="Your Existing Password"
                                color="primary"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                value={password}
                                error={invalidCredentials || hasLoginDetailsError && !password.length}
                                onChange={evt => setPassword(evt.target.value)}
                                className={classNames(classes.loginTextField, DATA_PRIVATE)}
                                onFocus={e => resetInvalidFlag()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex={-1}
                                                aria-label="Toggle password visibility"
                                                onClick={evt => setShowPassword(prev => !prev)}
                                                className={classes.passwordInputIcon}
                                                size="large">
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {invalidCredentials ?
                                <FormHelperText className={classes.loginErrorText}>
                                    Sorry, these credentials are invalid.
                                </FormHelperText>
                                : !password.length && hasLoginDetailsError &&
                                <Typography color="error" align="center" className={classes.loginErrorText}>
                                    Password is required.
                                </Typography>
                            }
                        </FormControl>

                        <Grid container justifyContent="space-between" alignContent="space-between">
                            <Grid
                                item
                                className={classes.gridBtn}
                            >
                                <Button
                                    color="secondary"
                                    className={classNames(classes.btnForgetPw, classes.loginInputField)}
                                    tabIndex={-1}
                                >
                                    <span
                                        className={classNames(
                                            classes.btnForgetPw,
                                            classes.loginInputField,
                                            classes.fontWeight500
                                        )}
                                        onClick={e => goToSubStep(ExistingUserStep.passwordReset)}
                                    >
                                        forgot Password ?
                                        <p
                                            className={classNames(
                                                classes.colorSecondary,
                                                classes.fontWeight400,
                                                classes.margin_0
                                            )}
                                        >
                                            Click Here
                                        </p>
                                    </span>
                                </Button>
                                <Button
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    className={classes.btnLogin}
                                    disabled={isLoading}
                                    onClick={e => attemptLogin()}
                                >
                                    Log In
                                    {isLoading &&
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>

                <Grid
                    item
                    sx={{ mt: 'auto !important' }}
                    className={classes.continueButtonContainer}
                >
                    <Typography color="secondary" className={classes.haveAccount}>
                        I don't have an account.&nbsp;
                        <span className={classes.loginText} onClick={e => goBack()}>
                            Create one now
                        </span>
                    </Typography>
                </Grid>

                {hasFHLogo && <FuneralHomeLogo className={classNames(classes.fhLogo, classes.marginBottom24)} />}
            </Grid>
        </Fade>
    );
};

export default LoginStep;