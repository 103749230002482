import { CaseInsuranceState } from "../types";
import { GatherAction } from "../actions";
import {
    ATTACHING_POLICY_TO_CASE,
    ATTACHED_POLICY_TO_CASE,
    UNATTACHING_POLICY_TO_CASE,
    UNATTACHED_POLICY_TO_CASE,
} from "../actions/Insurance.action";

export const caseInsuranceInitData = {
    isLoading: false,
};

export const caseInsuranceState = (
    state: CaseInsuranceState = caseInsuranceInitData,
    action: GatherAction
): CaseInsuranceState => {
    switch (action.type) {
        case ATTACHING_POLICY_TO_CASE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ATTACHED_POLICY_TO_CASE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case UNATTACHING_POLICY_TO_CASE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case UNATTACHED_POLICY_TO_CASE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};