import { useEffect, useState } from 'react';
import {
    DeathCertificatePatchRequest,
    GmapsSearchType,
    LongAddress,
    NullLongAddress,
    isLongAddress,
} from '../../../shared/types';
import GDialogWithTextField from '../../common/GDialogWithTextField';
import GmapsSearch, { GmapsSearchAddress } from '../../gmapsSearch/GmapsSearch';
import { GStyles } from '../../../styles/GStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGDispatch } from '../../../types';
import { patchDeathCertificate } from '../../../actions/GatherCase.action';

const useStyles = makeStyles((theme) => ({
    gmapsSearch: {
        maxWidth: 'unset',
        width: '100%',
    },
    margin0: {
        margin: 0,
    },
    controlSuggestionClasses: {
        position: 'relative',
    },
}));

interface Props {
    zIndex: number;
    open: boolean;
    pickupAddress: LongAddress | null;
    caseFullName: string;
    caseUuid: string;
    onClose: () => void;
    onSaveCallback?: () => void;
}
const PickupAddressDialog = (props: Props) => {
    const { open, pickupAddress, caseFullName, caseUuid, zIndex, onSaveCallback, onClose } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const [address, setAddress] = useState<LongAddress | null>(null);

    useEffect(() => {
        setAddress(pickupAddress);
    }, [pickupAddress]);

    const handleAddressChange = (_pickupAddress: GmapsSearchAddress) => {
        if (isLongAddress(_pickupAddress)) {
            setAddress(_pickupAddress);
        }
    };

    const handleSave = async () => {
        if (!address) {
            return;
        }

        const changes: DeathCertificatePatchRequest = {
            about: { pickUpAddress: address },
        };

        onClose();

        await dispatch(
            patchDeathCertificate({
                changes,
                caseUuid: caseUuid,
            }),
        );

        onSaveCallback?.();
    };

    return (
        <GDialogWithTextField
            zIndex={zIndex}
            title="Decedent Pickup Address"
            initialValue={null}
            isDialogOpen={open}
            textFieldLabel={''}
            closeDialog={onClose}
            onSave={handleSave}
            skipValidation
            description={
                <>
                    <span className={GStyles.fontWeight600}>{caseFullName}'s</span> Pickup Address
                </>
            }
            renderCustomTextfield={() => (
                <GmapsSearch
                    type={GmapsSearchType.longAddress}
                    controlClasses={classes.gmapsSearch}
                    textLabel={'Pickup Address'}
                    value={address ?? (pickupAddress ?? NullLongAddress)}
                    error={false}
                    onSetPlace={handleAddressChange}
                    required={false}
                    zIndex={1320}
                    formControlStyleClasses={GStyles.margin0}
                    controlSuggestionClasses={classes.controlSuggestionClasses}
                />
            )}
        />
    );
};

export default PickupAddressDialog;
