import { memoize } from 'lodash';
import moment from 'moment-timezone';
import { LocationUX, LocationType, GatherEvent, EventType, EventCreateRequest } from '../../../../shared/types';
import { GTabData } from '../../../common/GTabs';

const generateBackwardDates = (): GTabData[] => {
    let backwardDateArray: GTabData[] = [];
    let today = new Date();
    today.setHours(12); // Set the time at Noon - middle of the day
    let currentDate = moment(today).subtract(1, 'days');

    for (let i = 60; i > 0; i--) {
        const label = i === 60 ? 'YESTERDAY' : `${61 - i} DAYS AGO`;
        const description = currentDate.format('ddd, MMMM Do').toUpperCase();

        const content = {
            label,
            description: [description]
        };
        backwardDateArray.push({
            value: currentDate.toDate(),
            id: i + 400,
            content,
            tabIndex: 1
        });
        currentDate.subtract(1, 'days');
    }
    return backwardDateArray.reverse();
};

const generateForwardDates = (): GTabData[] => {
    let dateArray: GTabData[] = [];
    let today = new Date();
    today.setHours(12); // Set the time at Noon - middle of the day
    let currentDate = moment(today); // easy way to clone a date

    for (let i = 0; i <= 90; i++) {
        const label = i === 0 ? 'TODAY' : i === 1 ? 'TOMORROW' : `IN ${i} DAYS`;
        const description = currentDate.format('ddd, MMMM Do').toUpperCase();

        const content = {
            label,
            description: [description],
        };
        dateArray.push({
            value: currentDate.toDate(),
            id: i,
            content,
            tabIndex: 2,
            autoFocus: i === 0 ? true : undefined,
        });
        currentDate.add(1, 'day');
    }
    return dateArray;
};

export const generateDates = memoize((dayOfYear: number): GTabData[] => {
    const finalDates = generateBackwardDates().concat(generateForwardDates());
    return finalDates;
});

const generateHours = (): GTabData[] => {
    let hoursArray = [];

    for (let i = 0; i < 12; i++) {
        const hourValue = i.toString();
        const content = {
            label: `${i === 0 ? '12' : hourValue}:`,
        };

        hoursArray.push({ id: i, content, value: Number(hourValue), tabIndex: 2 });
    }
    return hoursArray;
};

const generateMinutes = (): GTabData[] => {
    let minutesArray = [];

    for (let i = 0; i < 4; i++) {
        const minuteValue = i === 0 ? `00` : (i * 15).toString();

        const content = {
            label: `:${minuteValue}`,
        };
        minutesArray.push({ id: i, content, value: Number(minuteValue), tabIndex: 2 });
    }
    return minutesArray;
};

const generateMeridiems = (): GTabData[] => {
    let hoursArray = [];

    for (let i = 0; i < 2; i++) {
        const meridiemValue = i === 0 ? 'AM' : 'PM';

        const content = {
            label: meridiemValue,
        };

        hoursArray.push({ id: i, content, value: i, tabIndex: 2 });
    }
    return hoursArray;
};

const generateDuration = (): GTabData[] => {
    let durationArray = [];
    let content = {
        label: `15 minutes`,
        description: ['Duration'],
    };
    durationArray.push({ id: 10, content, value: 15, tabIndex: 2 });

    content = {
        label: `30 minutes`,
        description: [],
    };
    durationArray.push({ id: 0, content, value: 30, tabIndex: 2 });

    content = {
        label: `45 minutes`,
        description: [],
    };
    durationArray.push({ id: 1, content, value: 45, tabIndex: 2 });

    content = {
        label: `1 hour`,
        description: [],
    };
    durationArray.push({ id: 2, content, value: 60, tabIndex: 2 });

    content = {
        label: `1hr 15min`,
        description: [],
    };
    durationArray.push({ id: 3, content, value: 75, tabIndex: 2 });

    content = {
        label: `1hr 30min`,
        description: [],
    };
    durationArray.push({ id: 4, content, value: 90, tabIndex: 2 });

    content = {
        label: `1hr 45min`,
        description: [],
    };
    durationArray.push({ id: 5, content, value: 105, tabIndex: 2 });

    content = {
        label: `2 hours`,
        description: [],
    };
    durationArray.push({ id: 6, content, value: 120, tabIndex: 2 });

    content = {
        label: `2hr 30min`,
        description: [],
    };
    durationArray.push({ id: 7, content, value: 150, tabIndex: 2 });

    content = {
        label: `3 hours`,
        description: [],
    };
    durationArray.push({ id: 8, content, value: 180, tabIndex: 2 });

    content = {
        label: `3hr 30min`,
        description: [],
    };
    durationArray.push({ id: 9, content, value: 210, tabIndex: 2 });

    for (let i = 4; i < 9; i++) {
        content = {
            label: `${i} hours`,
            description: [],
        };
        durationArray.push({ id: 9 + i, content, value: i * 60, tabIndex: 2 });
    }

    return durationArray;
};

export const filterLocations = (locations: LocationUX[]) => {
    // return saved event locations, funeral_home locations, and active event's location (in case it isn't saved)
    return locations.filter((loc) => loc.location_type === LocationType.funeral_home || loc.is_saved);
};

export const HOURS_LIST = generateHours();
export const MINUTES_LIST = generateMinutes();
export const MERIDIEM_LIST = generateMeridiems();
export const DURATION_LIST = generateDuration();

export const isEventTypeArrangement = (event: EventCreateRequest | GatherEvent) =>
    (event.event_type === EventType.arrangement);

export const generateEvent = (eventType: EventType, existing?: GatherEvent): EventCreateRequest => {
    const arrangementConferenceText = 'Arrangement Conference';

    let startTime: Date;
    let endTime: Date;
    let name: string;
    let isPrivate: boolean;
    if (existing) {
        startTime = existing.start_time;
        endTime = existing.end_time;
        name = isEventTypeArrangement(existing)
            ? existing.name || arrangementConferenceText
            : existing.name;
        isPrivate = existing.is_private;
    } else {
        const startMoment = moment().startOf('hour').add(1, 'hour');
        startTime = startMoment.toDate();
        endTime = startMoment.clone().add(1, 'hour').toDate();
        name = eventType === EventType.arrangement
            ? arrangementConferenceText
            : '';
        isPrivate = eventType === EventType.arrangement;
    }

    const eventReq: EventCreateRequest = {
        name,
        description: existing ? existing.description : null,
        editable_by_family: existing ? existing.editable_by_family : false,
        start_time: startTime,
        end_time: endTime,
        hide_end_time: existing ? existing.hide_end_time : false,
        event_type: existing ? existing.event_type : eventType,
        location_id: existing ? existing.location_id : null,
        is_streamable: existing ? existing.is_streamable : false, // default to not streamable
        is_private: isPrivate,
        message: existing ? existing.message : '',
    };
    return eventReq;
};
