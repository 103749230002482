import Typography from '@mui/material/Typography';
import classNames from "classnames";
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import FRQuestion from './FR.Question';

interface Props {
    caseFName: string;
    onStepChange(step: number): void;
}
const FRQuestionFour = (props: Props) => {
    const { caseFName, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <FRQuestion
            question={`Were ${caseFName}'s expenses paid for with burial insurance or a pre-paid funeral?`}
            onAccept={() => onStepChange(8)}
            onDeny={() => onStepChange(9)}
        >
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                This INCLUDES burial or funeral insurance, a pre-paid funeral contract, a pre-paid
                trust for funeral expenses, or an irrevocable trust for Medicare. This DOES NOT
                INCLUDE Life insurance proceeds.
            </Typography>
        </FRQuestion>
    );
};

export default FRQuestionFour;