import { useState, useRef, useMemo, useCallback, useEffect } from 'react';

import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { List, ListRowProps } from 'react-virtualized/dist/commonjs/List';

import makeStyles from '@mui/styles/makeStyles';
import { ScrollParams } from 'react-virtualized';

interface Props<T> {
    data: Array<T>;
    render: (item: T, recomputeListHeight: () => void, index: number) => JSX.Element;
    defaultCellHeight: number;
    lastChild?: JSX.Element;
    scrollElement?: Element;
    firstChild?: JSX.Element;
}

const useStyles = makeStyles({
    root: {
        '&:focus': {
            outline: 'none !important'
        }
    },
}, { name: 'VerticalReactVirtualizedList' });

function VerticalReactVirtualizedList<T>(props: Props<T>) {
    const { data, scrollElement, lastChild, firstChild, defaultCellHeight, render } = props;

    const classes = useStyles();

    const [listScrolled, setListScrolled] = useState(false);

    const listRef = useRef<List>(null);

    const cache = useMemo(
        () => new CellMeasurerCache({
            defaultHeight: defaultCellHeight,
            fixedWidth: true
        }),
        [defaultCellHeight]
    );

    const scrollElementDefined = !!scrollElement;
    /**
     * This function will recalculate the 
     * Grid Size so that the design remains
     * consistent when CRUD operations are performed
     */
    const recomputeListHeight = useCallback(() => {
        if (listRef.current) {
            cache.clearAll();
            if (scrollElementDefined) {
                listRef.current.measureAllRows();
                listRef.current.recomputeGridSize();
            } else {
                listRef.current.forceUpdateGrid();
            }
        }
    }, [cache, scrollElementDefined]);

    // recomputing the sizes when data changes
    useEffect(recomputeListHeight, [data, recomputeListHeight]);

    /**
     * CellMeasurer is used to auto calculate the
     * dimensions of item and it stores the data in cache 
     */
    const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
        const itemIndex = firstChild ? index - 1 : index;
        const item = data[itemIndex < 0 ? 0 : itemIndex];
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}
            >
                {({ registerChild }) => (
                    <div
                        ref={node => node && registerChild ? registerChild(node) : undefined}
                        style={style}
                    >
                        {firstChild && index === 0 && firstChild}
                        {firstChild && index === 0 ? undefined : item && render(item, recomputeListHeight, index)}
                        {index === data.length - 1 && lastChild}
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const rowCount = data.length + (lastChild ? 1 : 0) + (firstChild ? 1 : 0);

    /**
     * Window Scroller will help to scroll the Grid items
     * using body scrollbar
     */
    return (
        <WindowScroller
            scrollElement={scrollElement}
            onResize={recomputeListHeight}
        >
            {({ width, height, onChildScroll, scrollTop }) => (
                <List
                    className={classes.root}
                    deferredMeasurementCache={cache}
                    overscanRowCount={0}
                    rowCount={rowCount}
                    rowHeight={cache.rowHeight}
                    rowRenderer={rowRenderer}
                    height={height}
                    width={width}
                    autoHeight={scrollElement ? undefined : true}
                    autoWidth
                    scrollTop={scrollElement
                        ? listScrolled
                            ? undefined
                            : 0
                        : scrollTop}
                    onScroll={(params: ScrollParams) => {
                        onChildScroll(params);
                        if (!listScrolled && params.scrollTop !== 0) {
                            setListScrolled(true);
                        }
                    }}
                    ref={listRef}
                />
            )}
        </WindowScroller>
    );
}

export default VerticalReactVirtualizedList;
