import Typography from "@mui/material/Typography";
import { CaseBelongingDecisionType } from "../../../../../shared/types";

interface Props {
    switchValue: CaseBelongingDecisionType;
    caseFirstName: string;
}

const QuestionText = (props: Props) => {
    const { switchValue, caseFirstName } = props;

    switch (switchValue) {
        case CaseBelongingDecisionType.return_to_family:
            return <Typography color="primary">Has item been returned?</Typography>;

        case CaseBelongingDecisionType.keep:
            return <Typography color="primary">
                {`Has item been permanently kept with ${caseFirstName}?`}
            </Typography>;

        case CaseBelongingDecisionType.donate:
            return <Typography color="primary">Has item been donated?</Typography>;

        case CaseBelongingDecisionType.dispose:
            return <Typography color="primary">Has item been thrown away?</Typography>;

        case CaseBelongingDecisionType.unsure:
            return <Typography color="primary">This is just a temporary designation.
                To finalize, you will need to select an option above.</Typography>;

        default:
            return <Typography color="primary">Has item been returned?</Typography>;
    }
};

export default QuestionText;