import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
    getFullNameFromCase,
    emptyStringParser,
    getFullNameFromUser,
    getFhAddress,
    getFormattedPhoneNumber,
    formatDateString,
    getUserAddress
} from '../../../../services/app.service';
import { NULL_STRING } from '../../../../constants';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import FileCopy from '@mui/icons-material/FileCopy';
import LockIcon from '@mui/icons-material/Lock';

import { GatherCaseUX, UserRoles, FuneralHomeUX, UserProfile } from '../../../../shared/types';
import { SnackBarVariant } from '../../../../types';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import { DATA_PRIVATE } from '../../../../styles/Helper.styles';
import { FreezeType, FREEZE_TYPE_ADDRESS_LOOKUP } from '.';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    activeCase: GatherCaseUX;
    userData: UserProfile | null;
    freezeType: FreezeType;
    funeralHomeData: FuneralHomeUX | null;
}

const CommonFreezeCredit = (props: Props) => {
    const {
        activeCase,
        userData,
        freezeType,
        funeralHomeData,
        setAppSnackbar
    } = props;

    const classes = useTaskDialogStyles();

    const isFhUser: boolean = UserRoles.isFHUser(userData);

    const birthDate = activeCase.dob_date
        && activeCase.dob_date !== '' ? formatDateString(activeCase.dob_date) : '___';
    const deathDate = activeCase.dod_start_date
        && activeCase.dod_start_date !== '' ? formatDateString(activeCase.dod_start_date) : '___';
    const ssn: string = activeCase.death_certificate
        && activeCase.death_certificate.about.ssn
        && emptyStringParser(activeCase.death_certificate.about.ssn)
        ? activeCase.death_certificate.about.ssn : NULL_STRING;
    const caseAddress: string = activeCase.death_certificate
        && emptyStringParser(activeCase.death_certificate.life.residencePlace.description)
        ? activeCase.death_certificate.life.residencePlace.description : NULL_STRING;

    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;
    const helperEmail: string = userData && userData.email || NULL_STRING;

    let helperPhone: string = NULL_STRING;
    if (userData && userData.phone) {
        const phoneNumber = getFormattedPhoneNumber(userData.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    } else if (isFhUser && activeCase.funeral_home.phone) {
        const phoneNumber = getFormattedPhoneNumber(activeCase.funeral_home.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    }

    const helperRelation: string = isFhUser
        ? `I am the family-appointed Funeral Director from ${activeCase.funeral_home.name}` : NULL_STRING;

    const helperAddress: string = (
        isFhUser ? getFhAddress(funeralHomeData) : getUserAddress(userData)
    ) || NULL_STRING;

    const caseFullName = getFullNameFromCase(activeCase);
    const caseFirstName = activeCase.fname;

    const copyEmailBody = () => {
        setAppSnackbar('Letter copied to your clipboard', 'success');
    };

    const renderEmailBody = () => {
        return `Dear ${freezeType} Team,

${caseFullName} recently passed away and we need to place a notification of ` +
            `"Deceased- Do not issue credit" on ${caseFirstName}'s account, effectively immediately. Please also ` +
            `permanently remove ${caseFirstName}'s name from all future mailing lists for preapproved offers.

Please also send a copy of ${caseFirstName}'s current credit report to my address 
listed below.

- Decedent's name: ${caseFullName}
- Date of Birth: ${birthDate}
- Date of Passing: ${deathDate}
- Social Security Number: ${ssn}
- Most recent Address: ${caseAddress}

- My name: ${helperName} 
- My email: ${helperEmail}
- My phone number: ${helperPhone}
- My relationship to ${caseFirstName}: ${helperRelation}
- My Address: ${helperAddress}

Per your request, I am also including the following: ${NULL_STRING}
(If you are the estate executor: A court-issued Letter of Appointment of Executor)
(If you are ${caseFirstName}'s spouse: A copy of the Marriage Certificate)

I am also including a certified copy of ${caseFirstName}'s Death Certificate.

It would be greatly appreciated if you can confirm via email when this action is 
completed.

Thanks for your help in this important matter,
${helperName}`;
    };

    const emailBody: string = renderEmailBody();

    const tooltipText = `This will copy this letter to your device clipboard so that you can paste it 
                            in another program.`;

    const freezeTypeAddress = FREEZE_TYPE_ADDRESS_LOOKUP[freezeType];

    return (
        <TaskDialogStepWrapper
            heading={`Freeze ${caseFirstName}'s credit with ${freezeType}`}
            icon={<LockIcon color="primary" />}
        >
            <Grid item>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    {freezeType} is one of the three credit bureaus you will need to contact. The easiest way to
                    freeze {caseFirstName}'s credit at {freezeType} is to send them a letter. To make it as
                    easy as possible for you, we've already typed out the letter with as much of the required
                    information as possible. We recommend you send this letter certified mail with return
                    receipt requested.

                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Dear {freezeType} Team,
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    {caseFullName} recently passed away and we need to place a
                    notification of "Deceased - Do not issue credit" on {caseFirstName}'s account,
                    effective immediately. Please also permanently remove {caseFirstName}'s name from
                    all future mailing lists for preapproved offers.
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        Please also send a copy of {caseFirstName}'s current credit report to my address
                        listed below.
                    </span>
                    <span className={classNames(classes.displayBlock, classes.marginTop20, classes.fontWeight300)}>
                        - Decedent's name: <span className={classes.fontWeight500}>
                            {caseFullName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - Date of Birth: <span className={classes.fontWeight500}>{birthDate}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - Date of Passing: <span className={classes.fontWeight500}>{deathDate}</span>
                    </span>

                    <span className={classes.displayBlock}>
                        - Social Security Number:
                        <span className={classNames(classes.fontWeight500, DATA_PRIVATE)}>{ssn}</span>
                    </span>

                    <span className={classes.displayBlock}>
                        - Most recent Address: <span className={classes.fontWeight500}>{caseAddress}</span>
                    </span>

                    <span className={classNames(classes.displayBlock, classes.marginTop20)}>
                        - My name: <span className={classes.fontWeight500}>{helperName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My email: <span className={classes.fontWeight500}>{helperEmail}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My phone number: <span className={classes.fontWeight500}>{helperPhone}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My relationship to {caseFirstName}: <span className={classes.fontWeight500}>
                            {helperRelation}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My Address: <span className={classes.fontWeight500}>{helperAddress}</span>
                    </span>

                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    Per your request, I am also including the following: ___
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (If you are the estate executor: <span className={classes.fontWeight500}>
                            A court-issued Letter of Appointment of Executor</span>)
                    </span>
                    <span className={classes.displayBlock}>
                        (If you are {caseFirstName}'s spouse: <span className={classes.fontWeight500}>
                            A copy of the Marriage Certificate</span>)
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classNames(classes.displayBlock, classes.marginTop20)}>
                        I am also including a certified copy of {caseFirstName}'s Death Certificate.
                    </span>
                    <span className={classNames(classes.displayBlock, classes.marginTop20)}>
                        It would be greatly appreciated if you can confirm via email when this action is
                        completed.
                    </span>

                    <span
                        className={classNames(
                            classes.displayBlock,
                            classes.marginTop20
                        )}
                    >
                        Thanks for your help in this important matter,
                        <span className={classes.displayBlock}>
                            {helperName}
                        </span>
                    </span>
                </Typography>

            </Grid>

            <Grid
                item
                xs={12}
                md={8}
                className={classNames(classes.marginAuto, DATA_PRIVATE)}
            >
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.marginTop12}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this letter
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                className={classNames(
                    classes.marginAuto,
                    classes.textLeft,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize16,
                        classes.fontWeight300,
                    )}
                >
                    Mail this letter to the following address:
                    <span className={classNames(classes.displayBlock, classes.fontWeight500)}>
                        {freezeType}
                    </span>
                    <span className={classNames(classes.displayBlock, classes.fontWeight500)}>
                        {freezeTypeAddress?.poBox}
                    </span>
                    <span className={classNames(classes.displayBlock, classes.fontWeight500)}>
                        {freezeTypeAddress?.address}
                    </span>
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop30,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Don't forget to include the following...
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        <li>
                            If you are the estate executor: <span className={classes.fontWeight500}>
                                A court-issued Letter of Appointment of Executor</span>
                        </li>
                        <li>
                            If you are {caseFirstName}'s spouse: <span className={classes.fontWeight500}>
                                A copy of the Marriage Certificate</span>
                        </li>
                        <li>
                            A certified copy of {caseFirstName}'s Death Certificate
                        </li>
                    </Typography>
                </Grid>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default CommonFreezeCredit;
