import * as React from 'react';
import classNames from 'classnames';

import { compose } from 'redux';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AppBar from '@mui/material/AppBar/AppBar';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import ClearIcon from '@mui/icons-material/Clear';
import LockIcon from '@mui/icons-material/Lock';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CancelIcon from '@mui/icons-material/Cancel';
import TodayIcon from '@mui/icons-material/Today';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

import TermsOfServiceDialog from './TermService.Dialog';

import PriceWidget from '../../family/goodsAndServices//widgets/PriceWidget';
import {
    FuneralHomeDemoSettings,
    PaymentRequest,
    PaymentMode,
    PaymentMethod,
    PaymentType,
    EntityType
} from '../../../shared/types';
import { formatPrice } from '../../../shared/goods_and_services/pricing';
import { CardButton } from './widgets';
import PlaidPaymentDialog from '../../family/goodsAndServices/payment/PlaidPayment.dialog';
import Dinero from 'dinero.js';
import { Payer } from '../../family/goodsAndServices/payment';
import { UserSession } from '../../../types';
import { joinNameParts } from '../../../shared/utils';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { SlideTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';
import OnboardingPaymentDialog from './OnboardingPayment.dialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Calendly: any;

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    getStartedDialogPaper: {
        '@media (min-width: 960px)': {
            width: '720px !important',
            maxWidth: '720px !important',
        },
        '@media (max-width: 695.95px)': {
            margin: '0 !important',
        }
    },
    getStartedDialog: {
        minWidth: '290px',
        '& h2': {
            height: 50,
            marginTop: 5,
        },
    },
    getStartedDialogContent: {
        background: '#fff',
        zIndex: 0,
        padding: '0px !important',
        margin: '8px 0px !important',

    },
    footer: {},
    heading: {},
    description: {},
    onBoardingFeeSection: {
        color: theme.palette.primary.main,
        padding: 10,
        borderRadius: 14,
        width: 200,
        '& ul': {
            listStyle: 'none',
            paddingLeft: 4,
            margin: 0,
            '& li': {
                fontSize: 12,
                lineHeight: '16px',
                color: theme.palette.primary.main,
            }
        }
    },
    oneTimeFeeText: {
        fontWeight: 200,
        textAlign: 'center',
        marginBottom: 8,
    },
    recommendedPayMethText: {
        margin: '10px auto',
        width: 260,
        height: 28,
        fontSize: 14,
    },
    leftListSection: {
        margin: 'auto',
        '@media (min-width: 624px)': {
            padding: '8px 30px 8px 0px',
            borderRight: `1px solid ${theme.palette.secondary.main}`,
        }
    },
    rightListSection: {
        maxWidth: '358px',
        width: '100%',
        margin: 'auto',
    },
    payWithBankCardSection: {
        margin: '40px auto 20px',
        width: 300,
        '@media (min-width: 400px)': {
            width: 354,
        },
        '@media (min-width: 624px)': {
            width: 610,
        }
    },
    recommendedPayMethLists: {
        textAlign: 'center',
        margin: '40px 0',
        '& ul': {
            listStyle: 'none',
            width: 'fit-content',
            margin: 'auto',
            paddingLeft: 0,
            '& li': {
                fontSize: 14,
                lineHeight: '24px',
                fontWeight: 200,
                margin: 'auto',
                textAlign: 'left',
                color: theme.palette.secondary.main,
                '& >svg': {
                    fontSize: 16,
                    verticalAlign: 'middle',
                    paddingRight: 4,
                },
                '& >a': {
                    color: theme.palette.secondary.main,
                }
            },
        }
    },
    listIcons: {
        fontSize: 14,
        verticalAlign: 'middle',
        paddingLeft: 4,
        color: theme.palette.secondary.main,
    },
    tabsFlexContainer: {
        justifyContent: 'center',
        overflowX: 'auto',
        minWidth: 308,
    },
    tabsScrollableContainer: {
        overflow: 'auto',
        overflowX: 'auto',
        marginBottom: '0 !important',
    },
    tabAppbar: {
        boxShadow: 'none',
        margin: '5px 0',
        background: '#fff',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 140,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 1,
        margin: '0 8px',
        height: 30,
        fontSize: 16,
    },
    tabsRoot: {
        overflowX: 'auto',
    },
    priceWidgetDollar: {
        fontSize: '14px !important',
    },
    priceWidgetCent: {
        fontSize: '8px !important',
        margin: '0px !important',
    },
    closeIcon: {
        position: 'absolute',
        top: 8,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        },
    },
    checkTermsConditions: {
        fontSize: 12,
        Color: theme.palette.secondary.main,
        paddingTop: 4,
        fontWeight: 200,
        '& a': {
            cursor: 'pointer'
        },
        '@media (min-width: 400px)': {
            padding: '4px 0 0 10px',
            fontSize: 14,
        }
    },
    termsConditionsCheckBox: {
        '& svg': {
            fontSize: 20,
        }
    },
    checkboxRoot: {
        width: 32,
        height: 32,
    },
    welcomeContainer: {
        margin: '0 12px',
        textAlign: 'center',
        '& $heading': {
            fontSize: 32,
            fontWeight: 200,
            textAlign: 'center',
        },
        '& $description': {
            fontSize: 16,
            fontWeight: 200,
            textAlign: 'center',
            margin: 8,
        },
        '& $hrDiv': {
            backgroundColor: theme.palette.secondary.main,
            height: 1,
        },
        '& $svgContainer': {
            textAlign: 'center',
            paddingTop: 68,
            '& svg': {
                fontSize: 88,
                color: theme.palette.primary.main,
            }
        },
        '& >button': {
            borderRadius: 20,
        },
        '& $footer': {
            textAlign: 'right',
            marginTop: 110,
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0 52px',
        },
    },
    hrDiv: {},
    svgContainer: {},
    stylePayLabel: {
        whiteSpace: 'pre',
        fontSize: 20,
        color: theme.palette.common.white,
        fontWeight: 200,
        fontFamily: '"system-ui", sans-serif !important'
    },
    styleSubLabel: {
        fontWeight: 200,
        fontSize: 12,
        color: theme.palette.common.white,
    },
});

type StyledProps = Props & DialogProps & WithGStyles<'root' | 'getStartedDialogPaper' | 'getStartedDialog'
    | 'getStartedDialogContent' | 'footer' | 'heading' | 'description' | 'onBoardingFeeSection' | 'oneTimeFeeText'
    | 'recommendedPayMethText' | 'leftListSection' | 'rightListSection' | 'payWithBankCardSection'
    | 'recommendedPayMethLists' | 'listIcons' | 'tabsFlexContainer' | 'tabsScrollableContainer'
    | 'tabAppbar' | 'tabRoot' | 'tabsRoot' | 'priceWidgetDollar' | 'priceWidgetCent'
    | 'closeIcon' | 'checkTermsConditions' | 'termsConditionsCheckBox' | 'checkboxRoot' | 'welcomeContainer'
    | 'hrDiv' | 'svgContainer' | 'stylePayLabel' | 'styleSubLabel'>;

interface DialogProps {
    fullScreen: boolean;
}

export interface Props {
    isDialogOpen: boolean;
    funeralHomeIcon: string | null;
    closeDialog: () => void;
    demoSettings: FuneralHomeDemoSettings | null;
    userSession: UserSession;
    zIndex: number;
}

type FHPaymentMethod = Extract<PaymentMethod, PaymentMethod.plaid | PaymentMethod.online>;

export interface State {
    paymentMethod: FHPaymentMethod;
    acceptTerms: boolean;
    isPayFeeClicked: boolean;
    isPlaidDialogOpen: boolean;
    isCardDialogOpen: boolean;
    isTermsOfServiceDialogOpen: boolean;
}

class GetStartedDialog extends React.Component<StyledProps, State> {
    protected startX: number;
    constructor(props: StyledProps & Props) {
        super(props);
        this.state = {
            paymentMethod: PaymentMethod.plaid,
            acceptTerms: false,
            isPayFeeClicked: false,
            isPlaidDialogOpen: false,
            isCardDialogOpen: false,
            isTermsOfServiceDialogOpen: false,
        };
    }

    renderPaymentScreen = () => {
        const { classes, demoSettings, zIndex } = this.props;
        const { paymentMethod, acceptTerms, isPayFeeClicked, isTermsOfServiceDialogOpen } = this.state;
        if (demoSettings === null) {
            return <Grid>Hmmm... looks like we aren't quite ready to onboard you yet...</Grid>;
        }
        const { ob_fee, monthly_fee, ob_fee_waived, ob_fee_status } = demoSettings;
        const obFeeString = formatPrice(ob_fee || 0, 'USD');
        const monthlyFeeString = formatPrice(monthly_fee || 0, 'USD');

        return (
            <>
                {ob_fee_waived || ob_fee_status === 'succeeded' ? this.renderWelcomeScreen() :
                    <Grid>
                        <ClearIcon
                            color="secondary"
                            className={classes.closeIcon}
                            onClick={this.handleCloseDialog}
                            style={{ zIndex }}
                        />
                        <AppBar position="static" className={classes.tabAppbar}>
                            <Tabs
                                value={paymentMethod}
                                onChange={this.handleTabChangeEvent}
                                classes={{
                                    root: classes.tabsRoot,
                                    flexContainer: classes.tabsFlexContainer,
                                    scrollableX: classes.tabsScrollableContainer,
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab
                                    label="PAY WITH BANK"
                                    value="plaid"
                                    classes={{
                                        root: classes.tabRoot,
                                    }}
                                    className={paymentMethod === PaymentMethod.plaid
                                        && classes.colorPrimary || undefined}
                                />
                                <Tab
                                    label="PAY WITH CARD"
                                    value="online"
                                    classes={{
                                        root: classes.tabRoot,
                                    }}
                                    className={paymentMethod === PaymentMethod.online
                                        && classes.colorPrimary || undefined}
                                />
                            </Tabs>
                        </AppBar>

                        <Grid container className={classes.payWithBankCardSection}>
                            <div className={classes.leftListSection}>
                                <div
                                    className={classNames(
                                        classes.onBoardingFeeSection,
                                        classes.background_primary_0_1_8
                                    )}
                                >
                                    <Typography color="primary" className={classes.oneTimeFeeText}>
                                        One-time Onboarding Fee
                                    </Typography>
                                    <PriceWidget
                                        priceStr={obFeeString}
                                        size={'large'}
                                    />
                                    <Typography className={classes.colorPrimary}>
                                        This one-time fee includes...
                                    </Typography>
                                    <ul>
                                        {demoSettings.ob_items.split('\n')
                                            .map((l, i) => <li key={i}>- {l}</li>)}
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.rightListSection}>
                                <Grid item className={classes.recommendedPayMethLists}>
                                    <CardButton
                                        icon={paymentMethod === PaymentMethod.plaid ? 'account_balance' : 'credit_card'}
                                        label={<div className={classes.stylePayLabel}>
                                            PAY
                                            <PriceWidget priceStr={obFeeString} size={'xsmall'} />
                                            &nbsp;ONE-TIME FEE
                                        </div>}
                                        subLabel={<>
                                            <Typography className={classes.styleSubLabel}>
                                                Money-back Guarantee
                                            </Typography>
                                            <Typography className={classes.styleSubLabel}>Cancel Anytime</Typography>
                                        </>}
                                        onClick={this.payOneTimeFee}
                                    />
                                    <label
                                        className={classNames(
                                            classes.checkTermsConditions,
                                            classes.cursorPointer,
                                            isPayFeeClicked && !acceptTerms ? classes.colorRed : classes.colorSecondary
                                        )}
                                        htmlFor={'i_agree'}
                                    >
                                        <Checkbox
                                            tabIndex={-1}
                                            className={classes.termsConditionsCheckBox}
                                            classes={{
                                                root: classes.checkboxRoot
                                            }}
                                            value={String(acceptTerms)}
                                            onChange={this.toggleAcceptTerms}
                                            id="i_agree"
                                        />
                                        I understand and agree to these&nbsp;
                                        <a className={classes.textUnderline}
                                            onClick={(e) => this.toggleTermsOfServiceDialog(e)}>
                                            terms of service
                                        </a>
                                    </label>
                                    <ul>
                                        <li>
                                            {paymentMethod === PaymentMethod.plaid && <Chip
                                                label="Recommended Payment Method"
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.recommendedPayMethText}
                                                icon={<VerifiedUser className={classes.listIcons} />}
                                            />}
                                        </li>
                                        <li>
                                            <LockIcon />
                                            We use bank level 256-bit encryption
                                        </li>
                                        <li>
                                            <AccountBalanceIcon />
                                            We never store your
                                            &nbsp;{paymentMethod === PaymentMethod.plaid ? 'bank ' : 'card '}
                                            credentials
                                        </li>
                                        <li>
                                            <CancelIcon />
                                            Cancel or switch payment anytime
                                        </li>
                                        <li>
                                            <TodayIcon />
                                            Ongoing monthly amount:&nbsp;
                                            <PriceWidget
                                                priceStr={monthlyFeeString}
                                                size={'xsmall'}
                                                color={'secondary'}
                                                customClasses={{
                                                    price: classes.priceWidgetDollar,
                                                    cents: classes.priceWidgetCent,
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </Grid>
                            </div>
                        </Grid>
                        <TermsOfServiceDialog
                            closeDialog={(e: React.MouseEvent<HTMLElement>) => this.toggleTermsOfServiceDialog(e)}
                            isDialogOpen={isTermsOfServiceDialogOpen}
                            agreement={demoSettings.agreement_content}
                            zIndex={zIndex + 1}
                        />
                    </Grid>
                }
            </>
        );
    };

    toggleTermsOfServiceDialog = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.setState((prevState) => ({
            isTermsOfServiceDialogOpen: !prevState.isTermsOfServiceDialogOpen,
        }));
    };

    renderWelcomeScreen = () => {
        const { classes, demoSettings } = this.props;

        return (
            <Grid item xs={12} className={classes.welcomeContainer}>
                <Typography color="primary" className={classes.heading}>
                    Congrats and Welcome!
                </Typography>
                <div className={classes.hrDiv} />
                <Typography color="secondary" className={classes.description}>
                    {`We are honored to partner with you and we promise to work hard every day to earn your trust as 
                    we do our best to help you help even more families.`}
                </Typography>
                <div className={classes.svgContainer}>
                    <EmojiPeopleIcon />
                </div>
                <Typography color="primary" className={classes.description}>
                    Speaking of hard work, our team is already getting everything ready for you!
                </Typography>
                <Typography color="primary" className={classes.description}>
                    {`Click below to select an onboarding time that works for you and your team and we will make sure 
                    everything is set up perfectly before the call.`}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={e => Calendly.initPopupWidget(
                        { url: demoSettings ? demoSettings.calendly_url : 'https://calendly.com/gathersupport' }
                    )}
                >
                    Schedule Your Onboarding Call
                </Button>
                <div className={classes.footer}>
                    <Button
                        size="small"
                        onClick={this.handleCloseDialog}
                    >
                        Done
                        <KeyboardArrowRight />
                    </Button>
                </div>
            </Grid>
        );
    };

    render() {
        const { classes, isDialogOpen, fullScreen, demoSettings, userSession, zIndex } = this.props;
        const { paymentMethod } = this.state;

        const { userData } = userSession;
        const amount = demoSettings ? demoSettings.ob_fee || 0 : 0;
        const payerName = userData ? joinNameParts(userData) : '';
        const paymentInfo: PaymentRequest = {
            payment_id: 0,
            method: paymentMethod,
            mode: PaymentMode.IN_PERSON,
            type: PaymentType.ONBOARDING,
            amount: Dinero({ amount, currency: 'USD' }),
            merch_fee: Dinero({ amount: 0, currency: 'USD' }),
            payer_id: userData ? userData.entity_id : 0,
            payer_name: payerName,
            memo: 'Onboarding Fees',
            funeralHomeCaseId: null,
            funeralHomeId: demoSettings ? demoSettings.id : undefined,
            is_anon: false,
        };
        const payer: Payer = {
            payerEntity: {
                id: 0,
                type: EntityType.customer,
                fname: userData ? userData.fname : '',
                lname: userData && userData.lname ? userData.lname : '',
                email: userData ? userData.email : null,
                phone: userData ? userData.phone : null,
                user_profile_id: userData ? userData.id : null,
            },
            user: !userData ? undefined : {
                user_id: userData.id,
                entity_id: userData.entity_id,
                role: userData.role,
                invited_by: userData.invited_by || userData.id,
                invited_by_name: userData.invited_by_name || '',
                invited_time: userData.invited_time,
                funeral_home_ids: userData.funeral_home_ids,
                has_logged_in: userData.has_logged_in,
            },
        };

        return (
            <>
                <Dialog
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={this.handleCloseDialog}
                    fullScreen={fullScreen}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.getStartedDialog}
                    classes={{
                        paper: classes.getStartedDialogPaper
                    }}
                    scroll="body"
                    style={{ zIndex }}
                >
                    <DialogContent className={classes.getStartedDialogContent}>
                        {this.renderPaymentScreen()}
                    </DialogContent>
                </Dialog >
                <PlaidPaymentDialog
                    key={`${amount}:${payer.payerEntity.user_profile_id ?? -1}_PlaidPayment`}
                    isDialogOpen={this.state.isPlaidDialogOpen}
                    paymentInfo={paymentInfo}
                    isRestricted
                    closeDialog={() => this.setState({ isPlaidDialogOpen: false })}
                    payer={payer}
                    zIndex={zIndex + 1}
                />
                <OnboardingPaymentDialog
                    key={`${amount}:${payer.payerEntity.user_profile_id ?? -1}_OnlinePayment`}
                    isDialogOpen={this.state.isCardDialogOpen}
                    paymentInfo={paymentInfo}
                    closeDialog={() => this.setState({ isCardDialogOpen: false })}
                    payer={payer}
                    zIndex={zIndex + 1}
                />
            </>
        );
    }

    handleCloseDialog = () => {
        this.setState({
            isPayFeeClicked: false,
            acceptTerms: false,
        });
        this.props.closeDialog();
    };

    handleTabChangeEvent = (event: React.FormEvent<{}>, paymentMethod: FHPaymentMethod) => {
        this.setState({ paymentMethod });
    };

    toggleAcceptTerms = () => {
        this.setState((prevState) => ({
            acceptTerms: !prevState.acceptTerms,
        }));
    };

    payOneTimeFee = () => {
        this.setState({
            isPayFeeClicked: true,
            isPlaidDialogOpen: this.state.paymentMethod === PaymentMethod.plaid && this.state.acceptTerms,
            isCardDialogOpen: this.state.paymentMethod === PaymentMethod.online && this.state.acceptTerms,
        });
    };
}

export default compose(
    withGStyles(styles),
    withFullScreen('md'),
)(GetStartedDialog) as React.ComponentType<Props>;
