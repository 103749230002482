import { GatherCaseUX, UserProfile } from "../../../../shared/types";
import { SnackBarVariant } from "../../../../types";
import { AMAZON_STEPS_LOOKUP } from "./closeAmazonAccount";
import { APPLE_STEPS_LOOKUP } from "./closeAppleAccount";
import { HULU_STEPS_LOOKUP } from "./closeHuluAccount";
import { NETFLIX_STEPS_LOOKUP } from "./closeNetflixAccount";
import { PANDORA_STEPS_LOOKUP } from "./closePandoraAccount";
import { SPOTIFY_STEPS_LOOKUP } from "./closeSpotifyAccount";
import { AMAZON_ICON, APPLE_ICON, HULU_ICON, NETFLIX_ICON, PANDORA_ICON, SPOTIFY_ICON } from "./OnlineAccountIcons";
import { AccessToEmailQueProps } from "./widgets/AccessToEmailQue";
import { LogInToAccountQueProps } from "./widgets/LogInToAccountQue";

export enum OnlineAccountType {
    Amazon = 'Amazon',
    Apple = 'Apple',
    Hulu = 'Hulu',
    Netflix = 'Netflix',
    Pandora = 'Pandora',
    Spotify = 'Spotify'
}

export enum OnlineAccountDeactivateStep {
    LogInToAccountQueType = 'LogInToAccountQue',
    AccessToEmailQueType = 'AccessToEmailQue',
    ResetPasswordStepsType = 'ResetPasswordSteps',
    CanLoginType = 'CanLogin',
    CanNotLoginType = 'CanNotLogin'
}


export interface CanLoginProps {
    activeCase: GatherCaseUX;
    accountType: OnlineAccountType;
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    onStepChange: (step: OnlineAccountDeactivateStep) => void;
}

export interface CanNotLoginProps {
    userData: UserProfile | null;
    activeCase: GatherCaseUX;
    accountType: OnlineAccountType;
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    onStepChange: (step: OnlineAccountDeactivateStep) => void;
}

export interface ResetPasswordStepsProps {
    activeCase: GatherCaseUX;
    onStepChange: (step: OnlineAccountDeactivateStep) => void;
}

export type OnlineAccountStepLookup = {
    [OnlineAccountDeactivateStep.LogInToAccountQueType]: (props: LogInToAccountQueProps) => JSX.Element;
    [OnlineAccountDeactivateStep.AccessToEmailQueType]: (props: AccessToEmailQueProps) => JSX.Element;
    [OnlineAccountDeactivateStep.ResetPasswordStepsType]: (props: ResetPasswordStepsProps) => JSX.Element;
    [OnlineAccountDeactivateStep.CanLoginType]: (props: CanLoginProps) => JSX.Element;
    [OnlineAccountDeactivateStep.CanNotLoginType]: (props: CanNotLoginProps) => JSX.Element;
};

type StepsLookupType = Record<OnlineAccountType, OnlineAccountStepLookup>;

export const StepsLookup: StepsLookupType = {
    [OnlineAccountType.Amazon]: AMAZON_STEPS_LOOKUP,
    [OnlineAccountType.Apple]: APPLE_STEPS_LOOKUP,
    [OnlineAccountType.Hulu]: HULU_STEPS_LOOKUP,
    [OnlineAccountType.Netflix]: NETFLIX_STEPS_LOOKUP,
    [OnlineAccountType.Pandora]: PANDORA_STEPS_LOOKUP,
    [OnlineAccountType.Spotify]: SPOTIFY_STEPS_LOOKUP
};

export const IconLookup: Record<OnlineAccountType, JSX.Element> = {
    [OnlineAccountType.Amazon]: AMAZON_ICON,
    [OnlineAccountType.Apple]: APPLE_ICON,
    [OnlineAccountType.Hulu]: HULU_ICON,
    [OnlineAccountType.Netflix]: NETFLIX_ICON,
    [OnlineAccountType.Pandora]: PANDORA_ICON,
    [OnlineAccountType.Spotify]: SPOTIFY_ICON
};
