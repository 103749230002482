import * as t from 'io-ts';
import { Photo, PhotoTransformationsType, PhotoType } from './photo';

export enum AlbumTypeEnum {
    generic = 'generic',
    case = 'case',
    slideshow = 'slideshow',
    rememberPreview = 'rememberPreview'
}

export enum AlbumDownloadStatus {
    started = 'started',
    pending = 'pending',
    completed = 'completed',
    failed = 'failed',
}

export interface AlbumRecord {
    id: number;
    name: string;
    type: AlbumTypeEnum;
    task_id: number |  null;
    gather_case_id: number;
    download_url: string | null;
    download_created: Date | null;
    download_started: Date | null;
    download_status: AlbumDownloadStatus | null;
    download_expiry: Date | null;
    download_count: number;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
}

export interface AlbumEntryRecord {
    id: number;
    rank: number;
    album_id: number;
    photo_view_id: number;
    updated_time: Date;
}

export interface AlbumTaskInfoMap {
    [taskId: number]: AlbumTaskInfo;
}

export interface AlbumTaskInfo {
    album: AlbumRecord;
    count: number;  // number of entries
}

export interface AlbumEntryMappedType extends AlbumEntryRecord {
    photo_id: number;
    transformations: PhotoTransformationsType | null;
    photo_type: PhotoType;
    public_id: string;
    width: number;
    height: number;
    uploaded_by: number | null;
    timeSinceUploaded: string;
    is_pending_decision: boolean;
}

export interface DeleteReturnType {
    entry?: AlbumEntryMappedType;
    references?: string[];
}

export type AlbumIdOrCase = number | 'case';

export interface AlbumDownloadState {
    status: AlbumDownloadStatus | null;
    count: number;
    url: string | null;
}

export interface AlbumWithEntries extends AlbumRecord {
    entries: AlbumEntryMappedType[];
}

// used to update the rank order
export const AlbumRankOrder = t.type({
    album_entry_id: t.number,
    rank: t.number
});
export interface AlbumRankOrderType extends t.TypeOf<typeof AlbumRankOrder> {}

export const AlbumRankOrderInput = t.type({rankOrder: t.array(AlbumRankOrder)});
export interface AlbumRankOrderInputType extends t.TypeOf<typeof AlbumRankOrderInput> {}

export function isAlbumEntry(photo: AlbumEntryMappedType | Photo | null | undefined): photo is AlbumEntryMappedType {
    return !!photo && (photo as AlbumEntryMappedType).photo_view_id !== undefined;
}