import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanLoginProps } from '../utils';
import CanLoginWrapper from '../widgets/CanLoginWrapper';

const CanLogin = (props: CanLoginProps) => {
    const { activeCase, accountType, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanLoginWrapper
            accountType={accountType}
            caseFName={activeCase.fname}
            onStepChange={onStepChange}
        >
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                If {activeCase.fname} had an unpaid Pandora account, simply go to Pandora, log in
                as {activeCase.fname} and follow the instructions listed below.
            </Typography>
            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    From a mobile device:
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li className={classes.marginTop10}>
                            Go to {activeCase.fname}'s Station List.
                        </li>
                        <li className={classes.marginTop10}>
                            Tap the menu icon then open Settings.
                        </li>
                        <li className={classes.marginTop10}>
                            Select Account, then tap Delete Account.
                        </li>
                    </ul>
                </li>
                <li className={classes.marginTop10}>
                    From a computer:
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li className={classes.marginTop10}>
                            Click the avatar in the upper right corner of the screen.
                        </li>
                        <li className={classes.marginTop10}>
                            Visit Settings and then Account.
                        </li>
                        <li className={classes.marginTop10}>
                            Click Delete Pandora Account at the bottom of the page.
                        </li>
                    </ul>
                </li>
            </ul>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                If {activeCase.fname} was being charged for Pandora and you need to cancel all future payments,
                you will first need to determine how {activeCase.fname} initially signed up. There are five
                different ways to purchase a Pandora subscription:
            </Typography>
            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    Directly through the Pandora Website
                </li>
                <li className={classes.marginTop10}>
                    iTunes and App Store, using your iPhone, iPod, or iPad
                </li>
                <li className={classes.marginTop10}>
                    Google Play, using your Android Device
                </li>
                <li className={classes.marginTop10}>
                    Amazon Appstore, using the Kindle App
                </li>
                <li className={classes.marginTop10}>
                    Roku, using a Roku Pandora App.
                </li>
            </ul>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                If you aren't sure how {activeCase.fname} purchased their subscription or who was
                billing {activeCase.fname}, there are a couple of ways to find out:
            </Typography>
            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    From a computer go to www.pandora.com/settings/subscription (make sure to log in
                    as {activeCase.fname})
                </li>
                <li className={classes.marginTop10}>
                    Check {activeCase.fname}'s bank statement for details on the latest charge for Pandora.
                </li>
            </ul>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                After confirming which party is charging {activeCase.fname} (iTunes, Google Play, Amazon,
                Roku or Pandora), you can click the link below for further instructions on
                canceling {activeCase.fname}'s Pandora subscription.
            </Typography>
            <Grid>
                <a
                    href={'https://help.pandora.com/s/article/Cancel-your-Subscription-1519949295562?' +
                        'language=en_US_'}
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Cancel {activeCase.fname}'s Pandora
                        Subscription <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </CanLoginWrapper>
    );
};

export default CanLogin;