import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import BaseErrorPage from './BaseErrorPage';
import { useHideIntercom } from './hooks/useHideIntercom';

const PageNotFound = () => {
    useHideIntercom();

    const heading = 'Sorry, our search came up empty.';

    return (
        <BaseErrorPage
            hideIntercomAction
            HeadingIcon={DeviceUnknownIcon}
            heading={heading}
        />
    );
};

export default PageNotFound;
