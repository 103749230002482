import { useMemo } from 'react';
import { openTeamInvitationDialog } from '../../../actions/Dialog.action';
import { canBeAssignedToCase } from '../../../shared/authority/can';
import { getFuneralHomeId } from '../../../shared/types';
import { useGDispatch, useGSelector } from '../../../types';
import PickTeamMemberPure, { PickTeamMemberPureProps } from './PickTeamMemberPure';

interface Props
    extends Omit<PickTeamMemberPureProps, 'team' | 'funeralHomeId' | 'gatherCase' | 'onInvite' | 'userData'> {}

// Gets the team, funeralHomeId, gatherCase, and user from the global Redux state
// If you don't want to use Redux state use PickTeamMemberPure
const PickTeamMember = (props: Props) => {
    const team = useGSelector(({ teamState }) => teamState.team);
    const funeralHomeId = useGSelector(({ funeralHomeState, casesState }) =>
        getFuneralHomeId(funeralHomeState.activeFuneralHome, casesState.selectedCase, casesState.publicCase),
    );
    const gatherCase = useGSelector(({ casesState }) => casesState.selectedCase);
    const userData = useGSelector(({ userSession }) => userSession.userData);

    const teamThatCanBeAssigned = useMemo(() => {
        return team.filter(e => canBeAssignedToCase(e, funeralHomeId));
    }, [team, funeralHomeId]);

    const dispatch = useGDispatch();

    const handleInvite = (_zIndex: number) => {
        dispatch(
            openTeamInvitationDialog({
                zIndex: _zIndex + 1,
                onAddedCallback: props.onAssign,
            }),
        );
    };

    return (
        <PickTeamMemberPure
            {...props}
            team={teamThatCanBeAssigned}
            funeralHomeId={funeralHomeId}
            gatherCase={gatherCase}
            userData={userData}
            onInvite={handleInvite}
        />
    );
};

export default PickTeamMember;
