import { useCallback } from 'react';
import { useGSelector } from '../../types';

import ConfirmationDialog from '../common/ConfirmationDialog';
import { closeGlobalDeleteDialog, deleteCasePhoto } from '../../actions/Photo.action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

const ConfirmPhotoGlobalDeleteDialog = () => {
    const { isDialogOpen, references, albumEntryId, gatherCase, zIndex } = useGSelector(({ deleteGlobalState }) => ({
        isDialogOpen: deleteGlobalState.isDialogOpen,
        references: deleteGlobalState.references,
        albumEntryId: deleteGlobalState.albumEntryId,
        gatherCase: deleteGlobalState.gatherCase,
        zIndex: deleteGlobalState.zIndex
    }));

    const dispatch = useDispatch<AppDispatch>();

    const closeDeletePhotoConfirmationDialog = () => {
        dispatch(closeGlobalDeleteDialog());
    };

    const handleDeletePhoto = useCallback(() => {
        if (albumEntryId && gatherCase) {
            dispatch(deleteCasePhoto({ gatherCase, albumEntryId, force: true, zIndex }));
            dispatch(closeGlobalDeleteDialog());
        }
    }, [dispatch, albumEntryId, gatherCase, zIndex]);

    return (
        <ConfirmationDialog
            header={'Photo in use, are you sure?'}
            subHeader={'This photo is being used in the following places:'}
            list={references}
            text={'Deleting it will permanently remove it from all these places.'}
            confirmationButtonText={'Delete Photo'}
            cancelButtonText={'Nevermind'}
            onClose={closeDeletePhotoConfirmationDialog}
            open={isDialogOpen}
            onConfirm={handleDeletePhoto}
            zIndex={zIndex}
        />
    );
};

export default ConfirmPhotoGlobalDeleteDialog;