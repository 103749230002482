import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import { DateTimeFormat, getDatesDiffDurations, joinNameParts } from "../../../services";
import GTooltip from "../../common/GTooltip";
import UserAvatar from "../../common/UserAvatar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material";
import { NoteUpdatedByUser } from "../../../shared/types";
import { getLongKeepTrackDuration } from "../bodyTracking/QRStepper/CompletedStep";
import { GStyles } from "../../../styles/GStyles";

const Header = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '6px 0',
});

const UserDetails = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const TaskTitle = styled(Typography)({
    fontWeight: 500,
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: '13px'
});

const NoteDetails = styled(Typography)({
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 300
});

const ActionButton = styled(IconButton)({
    width: 32,
    height: 32,
    '& svg': {
        fontSize: 26
    }
});

const NoteText = styled(Typography)({
    fontSize: 16,
    fontWeight: 300,
    maxWidth: 'calc(100% - 58px)',
    width: 'fit-content',
    paddingBottom: 8,
    whiteSpace: 'pre-wrap',
});

const DISABLED_ACTION_TOOLTIP = 'Cannot modify tracking step notes';

const EDIT_CASE_NOTE_TOOLTIP = 'Click to edit this Case Note';
const DELETE_CASE_NOTE_TOOLTIP = 'Click to delete this Case Note';

const EDIT_TASK_NOTE_TOOLTIP = 'Click to edit this Task Note';
const DELETE_TASK_NOTE_TOOLTIP = 'Click to delete this Task Note';


interface Props {
    note: string | string[];
    updateByUser: NoteUpdatedByUser;
    updatedTime: Date;
    taskTitle?: string;
    onEdit?: () => void;
    onDelete?: () => void;
}

const Note = (props: Props) => {
    const { note, updateByUser, updatedTime, taskTitle, onEdit, onDelete } = props;

    const userFullName = joinNameParts(updateByUser);

    const completedDurations = getDatesDiffDurations({
        older: moment(updatedTime),
        newer: moment(),
    });
    const completedDurationLong = getLongKeepTrackDuration({
        durations: completedDurations,
        withAgo: true,
    });

    const editIconTitle = !onEdit
        ? DISABLED_ACTION_TOOLTIP
        : taskTitle
            ? EDIT_TASK_NOTE_TOOLTIP
            : EDIT_CASE_NOTE_TOOLTIP;

    const deleteIconTitle = !onDelete
        ? DISABLED_ACTION_TOOLTIP
        : taskTitle
            ? DELETE_TASK_NOTE_TOOLTIP
            : DELETE_CASE_NOTE_TOOLTIP;

    return (
        <>
            <Header item xs={12}>
                <UserDetails >
                    <UserAvatar
                        user={updateByUser}
                        size={42}
                        sx={{
                            width: 42,
                            height: 42
                        }}
                    />
                    <Grid ml="6px">
                        {taskTitle &&
                            <TaskTitle color="secondary">
                                {taskTitle}
                            </TaskTitle>
                        }
                        <NoteDetails color="secondary">
                            {taskTitle && 'Note Added By'} {userFullName}
                        </NoteDetails>

                        <GTooltip enterDelay={600} title={completedDurationLong || ''}>
                            <NoteDetails color="secondary">
                                {updatedTime
                                    && moment(updatedTime)
                                        .format(DateTimeFormat.LongDateWithTimeAndTZ)
                                }
                            </NoteDetails>
                        </GTooltip>
                    </Grid>
                </UserDetails>

                <div>
                    <GTooltip title={editIconTitle} contentDisabled={!onEdit}>
                        <ActionButton
                            onClick={onEdit}
                            size="large"
                            disabled={!onEdit}
                        >
                            <EditIcon />
                        </ActionButton>
                    </GTooltip>
                    <GTooltip title={deleteIconTitle} contentDisabled={!onDelete}>
                        <ActionButton
                            onClick={onDelete}
                            size="large"
                            disabled={!onDelete}
                        >
                            <DeleteIcon />
                        </ActionButton>
                    </GTooltip>
                </div>
            </Header>
            {typeof note === 'string'
                ? <NoteText
                    color="secondary"
                    onClick={onEdit}
                    className={GStyles.cursorPointer}
                >
                    "{note}"
                </NoteText>
                : note.map((n, i) =>
                    <NoteText
                        key={i}
                        color="secondary"
                        onClick={onEdit}
                        className={onEdit ? GStyles.cursorPointer : undefined}
                    >
                        "{n}"
                    </NoteText>
                )}
        </>
    );
};

export default Note;