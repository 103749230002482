import {
    GetPolicyAndAssociationsResponse,
    InsurancePoliciesDataSourceUX,
    insurancePoliciesDSFromRequest,
    InsurancePoliciesDSRequest,
    InsurancePolicyRecord,
    InsurancePolicyUpdateRecord,
} from '../shared/types';
import { AppDispatch } from '../store';
import { downloadFromAPI, getFromAPI, patchAPI, postToAPI } from './index';
import { registerAppError } from './errors';
import { log } from '../logger';
import { AllowedAcceptTypes } from '../types';

export function getPolicyAndAssociations(params: { policyId: number; funeralHomeId: number }) {
    const { policyId, funeralHomeId } = params;
    return async (dispatch: AppDispatch): Promise<GetPolicyAndAssociationsResponse | null> => {
        if (!policyId) {
            return null;
        }
        const url = `funeralhome/${funeralHomeId}/insurance/policy/${policyId}`;
        return getFromAPI<GetPolicyAndAssociationsResponse>(url, dispatch);
    };
}

export const ATTACHING_POLICY_TO_CASE = 'ATTACHING_POLICY_TO_CASE';
export type ATTACHING_POLICY_TO_CASE = typeof ATTACHING_POLICY_TO_CASE;

interface AttachingPolicyToCase {
    isLoading: boolean;
    policyId: number;
    type: ATTACHING_POLICY_TO_CASE;
}

function attachingPolicyToCase(policyId: number): AttachingPolicyToCase {
    return {
        isLoading: true,
        policyId,
        type: ATTACHING_POLICY_TO_CASE,
    };
}

export const ATTACHED_POLICY_TO_CASE = 'ATTACHED_POLICY_TO_CASE';
export type ATTACHED_POLICY_TO_CASE = typeof ATTACHED_POLICY_TO_CASE;

interface AttachedPolicyToCase {
    isLoading: boolean;
    policyId: number;
    type: ATTACHED_POLICY_TO_CASE;
}

function attachedPolicyToCase(policyId: number): AttachedPolicyToCase {
    return {
        isLoading: false,
        policyId,
        type: ATTACHED_POLICY_TO_CASE,
    };
}

export function attachPolicyToCase(params: { policyId: number; funeralHomeId: number; caseUuid: string }) {
    const { policyId, funeralHomeId, caseUuid } = params;
    return async (dispatch: AppDispatch) => {
        const url = `funeralhome/${funeralHomeId}/insurance/policy/${policyId}/attach/${caseUuid}`;
        dispatch(attachingPolicyToCase(policyId));
        const policy = await patchAPI<InsurancePolicyUpdateRecord>(url, {}, dispatch);

        if (!policy) {
            dispatch(registerAppError('Failed to attach insurance policy'));
            return null;
        }

        dispatch(attachedPolicyToCase(policyId));
        return policy;
    };
}

export const UNATTACHING_POLICY_TO_CASE = 'UNATTACHING_POLICY_TO_CASE';
export type UNATTACHING_POLICY_TO_CASE = typeof UNATTACHING_POLICY_TO_CASE;

interface UnAttachingPolicyToCase {
    isLoading: boolean;
    policyId: number;
    type: UNATTACHING_POLICY_TO_CASE;
}

function unAttachingPolicyToCase(policyId: number): UnAttachingPolicyToCase {
    return {
        isLoading: true,
        policyId,
        type: UNATTACHING_POLICY_TO_CASE,
    };
}

export const UNATTACHED_POLICY_TO_CASE = 'UNATTACHED_POLICY_TO_CASE';
export type UNATTACHED_POLICY_TO_CASE = typeof UNATTACHED_POLICY_TO_CASE;

interface UnAttachedPolicyToCase {
    isLoading: boolean;
    type: UNATTACHED_POLICY_TO_CASE;
}

function unAttachedPolicyToCase(): UnAttachedPolicyToCase {
    return {
        isLoading: false,
        type: UNATTACHED_POLICY_TO_CASE,
    };
}

export function unAttachPolicyToCase(params: { policyId: number; funeralHomeId: number }) {
    return async (dispatch: AppDispatch) => {
        const { policyId, funeralHomeId } = params;
        const url = `funeralhome/${funeralHomeId}/insurance/policy/${policyId}/unattach`;
        dispatch(unAttachingPolicyToCase(policyId));
        const policy = await patchAPI<InsurancePolicyUpdateRecord>(url, {}, dispatch);

        if (!policy) {
            dispatch(registerAppError('Failed to attach insurance policy'));
            return null;
        }
        dispatch(unAttachedPolicyToCase());
        return policy;
    };
}

export const REMOVED_POLICY_FROM_DASHBOARD = 'REMOVED_POLICY_FROM_DASHBOARD';
export type REMOVED_POLICY_FROM_DASHBOARD = typeof REMOVED_POLICY_FROM_DASHBOARD;

interface RemovedPolicyFromDashboard {
    type: REMOVED_POLICY_FROM_DASHBOARD;
    policyId: number;
}

function removedPolicyFromDashboard(policyId: number): RemovedPolicyFromDashboard {
    return {
        type: REMOVED_POLICY_FROM_DASHBOARD,
        policyId,
    };
}

export function removePolicyFromDashboard(params: { policyId: number; funeralHomeId: number }) {
    const { policyId, funeralHomeId } = params;
    return async (dispatch: AppDispatch) => {
        dispatch(removedPolicyFromDashboard(policyId));
        const url = `funeralhome/${funeralHomeId}/insurance/policy/${policyId}`;
        const updatedPolicy = await patchAPI<InsurancePolicyRecord>(
            url,
            { removed_from_dashboard_time: new Date() },
            dispatch,
        );
        if (!updatedPolicy) {
            dispatch(registerAppError('Failed to remove insurance policy from dashboard'));
            return null;
        }
        return updatedPolicy;
    };
}

export function updateInsurancePolicy(params: {
    policyId: number;
    funeralHomeId: number;
    changes: InsurancePolicyUpdateRecord;
}) {
    const { policyId, funeralHomeId, changes } = params;
    return async (dispatch: AppDispatch) => {
        const url = `funeralhome/${funeralHomeId}/insurance/policy/${policyId}`;
        const updatedPolicy = await patchAPI<InsurancePolicyUpdateRecord>(url, changes, dispatch);

        if (!updatedPolicy) {
            dispatch(registerAppError('Failed to update insurance policy'));
            return null;
        }
        return updatedPolicy;
    };
}

export function downloadPolicyDocuments(params: {
    policyId: number;
    funeralHomeId: number;
    fileName: string;
}) {
    const { policyId, funeralHomeId, fileName } = params;
    return async (dispatch: AppDispatch): Promise<void> => {
        const url = `funeralhome/${funeralHomeId}/insurance/policy/${policyId}/documents`;
        try {
            await downloadFromAPI(url, dispatch, null, AllowedAcceptTypes.application_json, true, fileName);
        } catch (e) {
            dispatch(registerAppError(
                `This Policy PDF was not sent to Gather from Homesteaders Life Company.
                Please contact Homesteaders Life Company for this policy PDF.`
            ));
        }
    };
}

export function loadInsurancePoliciesForLegacyReport(request: InsurancePoliciesDSRequest) {
    return async (dispatch: AppDispatch): Promise<InsurancePoliciesDataSourceUX[] | null> => {
        try {
            insurancePoliciesDSFromRequest(request);
        } catch (e) {
            log.warn('Failed to validate InsurancePoliciesDSRequest', { request, e });
            return null;
        }
        if (request.funeralHomeIds.length === 0) {
            return [];
        }
        // route requires a FH so just pick the first one
        const url = `funeralhome/${request.funeralHomeIds[0]}/insurance/policies/legacyreport`;

        const res = await postToAPI<InsurancePoliciesDataSourceUX[]>(url, request, dispatch);
        if (!res) {
            dispatch(registerAppError('Failed to load data for report.'));
        }
        return res;
    };
}

export type InsurancePolicyAction =
    | AttachingPolicyToCase
    | AttachedPolicyToCase
    | UnAttachingPolicyToCase
    | UnAttachedPolicyToCase
    | RemovedPolicyFromDashboard;
