import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import { SlideTransition } from '../../common/Transitions';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles((theme: Theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
            }
        },
    },
    width100: {
        width: '100%'
    },
    textLeft: {
        textAlign: 'left',
    },
    marginTop20: {
        marginTop: 20
    },
    actionsButton: {
        justifyContent: 'space-between'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.secondary.main
    },
    textAreaMinHeight: {
        minHeight: '38px'
    },
    dialogPaper: {}
}), { name: 'EditNoteDialog' });

interface Props {
    header: string;
    open: boolean;
    note: string | null;
    saveNote: (note: string) => void;
    onClose: () => void;
    zIndex: number;
    allowNull?: boolean;
};

const EditNoteDialog = (props: Props) => {

    const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
    const [note, setNote] = useState<string>('');

    const { header, open, onClose, zIndex, allowNull } = props;

    const classes = useStyles();

    useEffect(() => {
        setNote(props.note === null ? '' : props.note);
    }, [props.note]);

    const saveNote = () => {
        if (allowNull) {
            props.saveNote(note.trim());
            setNote(note.trim());
            return;
        }
        if (!note || !note.trim()) {
            setIsSaveClicked(true);
            return;
        }
        props.saveNote(note.trim());
    };

    const handleClose = () => {
        setNote(props.note || '');
        onClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                keepMounted
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classNames(classes.dialogPaper, classes.width100)
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    classes={{ root: classes.dialogTitle }}
                >
                    {header}
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12} className={classes.width100}>
                        <FormControl
                            className={classNames(
                                classes.width100,
                                classes.textLeft,
                                classes.marginTop20
                            )}
                        >
                            <TextField
                                fullWidth
                                autoComplete="case-note"
                                label="Edit Here..."
                                type="text"
                                value={note}
                                onChange={(e) => (
                                    setNote(e.target.value),
                                    setIsSaveClicked(false)
                                )}
                                multiline
                                error={isSaveClicked && (!note || !note.trim())}
                                InputProps={{
                                    classes: {
                                        root: classes.textAreaMinHeight,
                                        inputMultiline: classes.textAreaMinHeight,
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions classes={{ root: classes.actionsButton }}>
                    <Button variant="text" color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        color="primary"
                        variant="text"
                        onClick={saveNote}
                    >
                        Save Note
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditNoteDialog;