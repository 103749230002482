import * as React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { removeNonDigits } from '../../shared/utils';
import { InputBaseComponentProps } from '@mui/material';

const PhoneNumberMask = React.forwardRef(
    function PhoneNumberMask(
        props: InputBaseComponentProps,
        forwardedRef: React.Ref<HTMLElement>
    ) {
        const { inputRef, ...other } = props;
        const maskedOtherProps = other as MaskedInputProps;

        const defaultMask = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        return (
            <MaskedInput
                {...maskedOtherProps}
                ref={ref => ref?.inputElement && typeof forwardedRef === 'function' && forwardedRef(ref.inputElement)}
                // mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                mask={(val) => {
                    let digitsOnly = removeNonDigits(val);
                    // remove leading 1 (if exists)
                    if (digitsOnly.startsWith('1')) {
                        digitsOnly = digitsOnly.substring(1);
                    }
                    // 10 digits is a valid US phone number
                    // However, we allow more input on the mask, this will allow when people cut and paste in 
                    // something like +1-208-555-1234
                    // but we are allowing any number of extra digits right now (but we use the validator to 
                    // flag the numbers that do not match a standard 10 digit syntax) but we leave it up to 
                    // the user to correct if they want. 

                    if (digitsOnly.length > 10) {
                        for (let i = 0; i < digitsOnly.length - 10; i++) {
                            defaultMask.push(/\d/);
                        }
                    }
                    return defaultMask;
                }}
                placeholderChar={'\u2000'}
                showMask
                guide={false}
            />
        );
    }
);

export default PhoneNumberMask;
