import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Fade from '@mui/material/Fade';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountType, OnlineAccountDeactivateStep } from '../utils';

interface Props {
    accountType: OnlineAccountType;
    caseFName: string;
    onStepChange: (step: OnlineAccountDeactivateStep) => void;
}

const CanLoginWrapper = (props: PropsWithChildren<Props>) => {
    const { accountType, caseFName, children, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <Fade in>
            <Grid>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    <span
                        className={classes.cursorPointer}
                        onClick={() => onStepChange(OnlineAccountDeactivateStep.LogInToAccountQueType)}
                    >
                        <IconButton color="primary" size="large">
                            <UndoIcon />
                        </IconButton>
                        Return to previous question
                    </span>
                </Typography>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.fontSize28,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    Delete {caseFName}'s {accountType} Account
                </Typography>

                {children}
            </Grid>
        </Fade>
    );
};

export default CanLoginWrapper;
