import * as React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import Clear from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import {
    ProductTaxRateUX, ProductTaxRateRequest,
} from '../../shared/types';
import TaxRateDetailsDialog from './TaxRateDetails.dialog';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

interface Props {
    zIndex: number;
    isDialogOpen: boolean;
    taxRates: ProductTaxRateUX[];
    isGatherUser: boolean;
    closeDialog: () => void;
    onCreate?: (taxRate: ProductTaxRateRequest) => void;
    onUpdate?: (id: number, taxRate: ProductTaxRateRequest) => void;
    onSelect?: (taxRate: ProductTaxRateUX | null) => void;
}

interface State {
    searchText: string;
    selectedTaxRateId: number | null;
    isDetailsDialogOpen: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    createNewContainer: {
        width: '100%',
        textAlign: 'right',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)'
    },
    listContainer: {
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        }
    },
    editIcon: {
        margin: 0,
        cursor: 'pointer',
        minWidth: 24,
    },
    textFieldContainer: {
        textAlign: 'center',
        flexBasis: 0,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon'
    | 'searchField' | 'heading' | 'listContainer'
    | 'noResultsFoundSection' | 'createNewContainer' | 'editIcon' | 'textFieldContainer'>;

type CombinedProps = DialogProps & StyledProps;
class AvailableTaxRates extends React.Component<CombinedProps, State> {
    state: State = {
        searchText: '',
        selectedTaxRateId: null,
        isDetailsDialogOpen: false,
    };

    applySearchFilter = (taxRates: ProductTaxRateUX[]) => {
        const { searchText } = this.state;
        if (!searchText) {
            return taxRates;
        }
        const search = searchText.toLowerCase();
        return taxRates.filter((taxRate) => {
            const name = taxRate.name.toLowerCase();
            const description = (taxRate.description || '').toLowerCase();
            return name.includes(search) || description.includes(search)
                || search.includes(name) || description && search.includes(description);
        });
    };

    renderNoResultsFoundSection = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography
                    color="secondary"
                    component="p"
                    className={classes.marginTop20}
                >
                    Sorry, no results found.
                </Typography>
            </Grid >
        );
    };

    renderTaxRateList = (taxRates: ProductTaxRateUX[]) => {
        const { classes, isGatherUser, onSelect, onUpdate } = this.props;
        if (taxRates.length === 0) {
            return this.renderNoResultsFoundSection();
        }

        const isClickEnabled = onSelect !== undefined;

        return (
            <List component="nav" className={classes.listContainer}>
                <Divider />

                <ListItem
                    key={-1}
                    button={isClickEnabled ? undefined : false}
                    divider
                    onClick={isClickEnabled ? () => this.handleClick(null) : undefined}
                >
                    <ListItemText primary={'Not Taxable'} />
                </ListItem>

                {taxRates.map((taxRate) => (
                    <ListItem
                        key={taxRate.id}
                        button={isClickEnabled ? undefined : false}
                        divider
                        onClick={isClickEnabled ? () => this.handleClick(taxRate) : undefined}
                    >
                        <ListItemText
                            primary={taxRate.name}
                            secondary={taxRate.description}
                        />
                        {isGatherUser && onUpdate !== undefined &&
                            <ListItemIcon
                                className={classes.editIcon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.toggleDetailsDialog(taxRate.id);
                                }}
                            >
                                <EditIcon />
                            </ListItemIcon>
                        }
                    </ListItem>
                ))}
            </List>
        );
    };

    render() {
        const {
            classes,
            zIndex,
            fullScreen,
            isDialogOpen,
            taxRates,
            isGatherUser,
            onCreate,
        } = this.props;
        const { searchText, isDetailsDialogOpen, selectedTaxRateId } = this.state;

        const filteredTaxRates = this.applySearchFilter(taxRates);

        const selectedTaxRate = selectedTaxRateId ?
            find(taxRates, { id: selectedTaxRateId }) : undefined;

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.dialogHeader,
                            classes.backgroundPrimary
                        )}
                    >
                        <Clear
                            className={classNames(classes.clearIcon)}
                            onClick={this.handleClose}
                        />
                        <Typography
                            component="p"
                            className={classNames(classes.heading)}
                            align="left"
                        >
                            <span>Available Tax Rates</span>
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid item xs={12} className={classes.textFieldContainer}>
                            <form
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    autoComplete="off"
                                    className={classes.searchField}
                                    fullWidth
                                    autoFocus
                                    value={searchText}
                                    id="input-with-icon-textfield"
                                    placeholder="Start typing to filter list..."
                                    name="searchText"
                                    onChange={this.handleSearchChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid className={classes.scrollAuto}>
                            {this.renderTaxRateList(filteredTaxRates)}
                        </Grid>
                    </DialogContent>

                    {isGatherUser && onCreate !== undefined &&
                        <DialogActions>
                            <Grid item className={classes.createNewContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.toggleDetailsDialog()}
                                >
                                    <AccountBalanceIcon /> &nbsp;Create New Tax Rate
                                </Button>
                            </Grid>
                        </DialogActions>
                    }
                </Dialog>
                {
                    isGatherUser &&
                    <TaxRateDetailsDialog
                        isDialogOpen={isDetailsDialogOpen}
                        closeDialog={this.toggleDetailsDialog}
                        onTaxRateSave={this.handleTaxRateSave}
                        existingTaxRate={selectedTaxRate}
                        zIndex={zIndex + 1}
                    />
                }
            </>
        );
    }

    handleClick = (taxRate: ProductTaxRateUX | null) => {
        const { onSelect } = this.props;
        if (onSelect) {
            onSelect(taxRate);
            this.handleClose();
        }
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        this.setState({
            searchText: target.value
        });
    };

    handleClose = () => {
        this.setState({ searchText: '' });
        this.props.closeDialog();
    };

    handleTaxRateSave = (taxRate: ProductTaxRateRequest) => {

        const { onCreate, onUpdate } = this.props;
        const { selectedTaxRateId } = this.state;

        if (selectedTaxRateId !== null && onUpdate) {
            onUpdate(selectedTaxRateId, taxRate);
        } else if (selectedTaxRateId === null && onCreate) {
            onCreate(taxRate);
        }
    };

    toggleDetailsDialog = (taxRateId?: number) => {
        this.setState((prevState) => ({
            isDetailsDialogOpen: !prevState.isDetailsDialogOpen,
            selectedTaxRateId: taxRateId || null,
        }));
    };
}

export default withFullScreen()(withGStyles(styles)(AvailableTaxRates));
