import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import GSwitch from '../../../../../common/inputElements/GSwitch';
import makeGStyles from '../../../../../../styles/makeGStyles';
import StepComponentWrapper from '../StepComponentWrapper';
import { AnswerWithUUID, ChecklistQuestionsConfig, ChecklistQuestionsQuestion } from '../../../../../../shared/types';
import { OnAnswerChangedFunction, OnValidityChangedFunction } from './types';

const useStyles = makeGStyles(theme => ({
    root: {},
    marginLeft4: {
        marginLeft: 4
    },
    marginLeft_4: {
        marginLeft: -4
    },
    maxHeight28: {
        maxHeight: 28
    },
    formControlLabel: {
        marginLeft: 0,
    },
}), { name: 'ChecklistQuestionsComponent' });

interface Props {
    isStepCompleted: boolean;
    config: ChecklistQuestionsConfig | null;
    componentId: number;
    saveAttempted: boolean;
    onValidityChanged: OnValidityChangedFunction;
    onAnswerChanged: OnAnswerChangedFunction;
}

const ChecklistQuestionsComponent = (props: Props) => {
    const {
        config,
        componentId,
        saveAttempted,
        onValidityChanged,
        onAnswerChanged,
        isStepCompleted
    } = props;

    const classes = useStyles();

    const headerText = config?.header_text;

    const [questions, setQuestions] = useState<ChecklistQuestionsQuestion[]>([]);

    // update questions when config updates (especially necessary for Back Office preview)
    useEffect(() => {
        setQuestions(config?.questions ?? []);
    }, [config?.questions]);

    const isValid = useMemo(() => {
        return questions.every((q) => !q.is_required || q.answer === 'true');
    }, [questions]);

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    useEffect(() => {
        const answers: AnswerWithUUID[] = questions
            .filter((q) => q.answer !== undefined)
            .map((q) => ({
                uuid: q.uuid,
                answer: q.answer ?? '',
            })
            );
        onAnswerChanged(componentId, answers);
    }, [onAnswerChanged, componentId, questions]);

    const handleSwitch = (uuid: string, isChecked: boolean) => {
        const newQuestions: ChecklistQuestionsQuestion[] = questions.map((q) => {
            if (q.uuid === uuid) {
                return {
                    ...q,
                    answer: isChecked.toString(),
                };
            } else {
                return q;
            }
        });
        setQuestions(newQuestions);
    };

    return (
        <StepComponentWrapper
            hideBorder
            error={!isValid && saveAttempted}
            success={isValid}
            className={classes.padding_0_12}
        >
            {headerText &&
                <Typography color="secondary" className={classNames(classes.fontWeight500, classes.marginLeft4)}>
                    {headerText}
                </Typography>
            }

            <Grid container={true} direction="column">
                {questions.map((question) => {
                    const isChecked = question.answer === 'true';
                    const isError = saveAttempted && !isChecked && question.is_required;
                    return <GSwitch
                        disabled={isStepCompleted}
                        key={question.uuid}
                        required={question.is_required}
                        label={`${question.question_text}${question.is_required ? ' *' : ''}`}
                        checked={isChecked}
                        onChangeCallBack={(checked) => !isStepCompleted && handleSwitch(question.uuid, checked)}
                        formControlLabelClasses={classNames(classes.formControlLabel, classes.maxHeight28)}
                        labelClasses={classNames(
                            classes.marginLeft_4,
                            isError ? classes.colorRed : classes.colorSecondary
                        )}
                    />;
                })}
            </Grid>
        </StepComponentWrapper>
    );
};

export default ChecklistQuestionsComponent;