import * as React from 'react';
import classNames from 'classnames';

import GAutocomplete, { SuggestionType } from './inputElements/GAutocomplete';
import {
    EntityRelationshipType,
    EntityRelationship,
    isEntityRelationship,
    EntityRelationshipDisplayLookup,
    EntityRelationshipTypeDisplayLookup,
} from '../../shared/types';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SchoolIcon from '@mui/icons-material/School';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { RED_COLOR } from '../../constants/colorVariables';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    customButton: {
        margin: 7,
        fontSize: 14,
        textTransform: 'capitalize',
        fontWeight: 400,
        height: 80,
        width: 76,
        '& $icon': {
            fontSize: 42,
            marginBottom: 4
        },
        '& $buttonLabel': {
            display: 'flex',
            flexDirection: 'column'
        },
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
    },
    label: {
        fontSize: 16,
        marginBottom: 8
    },
    selectTextField: {
        width: '100%',
        marginTop: 24,
    },
    helperText: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontStyle: 'italic',
        lineHeight: '2em',
        fontSize: 13
    },
    textColorRed: {
        color: RED_COLOR
    },
    gDownshiftPaper: {
        overflow: 'hidden auto',
        maxHeight: 184
    },
    buttonLabel: {},
    icon: {}
});
const FAMILY_RELATIONS: SuggestionType<EntityRelationship>[] = [
    { key: EntityRelationship.spouse, value: EntityRelationshipDisplayLookup[EntityRelationship.spouse] },
    { key: EntityRelationship.husband, value: EntityRelationshipDisplayLookup[EntityRelationship.husband] },
    { key: EntityRelationship.exhusband, value: EntityRelationshipDisplayLookup[EntityRelationship.exhusband] },
    { key: EntityRelationship.wife, value: EntityRelationshipDisplayLookup[EntityRelationship.wife] },
    { key: EntityRelationship.exwife, value: EntityRelationshipDisplayLookup[EntityRelationship.exwife] },
    { key: EntityRelationship.partner, value: EntityRelationshipDisplayLookup[EntityRelationship.partner] },
    {
        key: EntityRelationship.domesticPartner,
        value: EntityRelationshipDisplayLookup[EntityRelationship.domesticPartner]
    },
    {
        key: EntityRelationship.significantOther,
        value: EntityRelationshipDisplayLookup[EntityRelationship.significantOther]
    },
    { key: EntityRelationship.fiance, value: EntityRelationshipDisplayLookup[EntityRelationship.fiance] },
    { key: EntityRelationship.father, value: EntityRelationshipDisplayLookup[EntityRelationship.father] },
    { key: EntityRelationship.stepFather, value: EntityRelationshipDisplayLookup[EntityRelationship.stepFather] },
    { key: EntityRelationship.fatherInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.fatherInLaw] },
    { key: EntityRelationship.mother, value: EntityRelationshipDisplayLookup[EntityRelationship.mother] },
    { key: EntityRelationship.stepMother, value: EntityRelationshipDisplayLookup[EntityRelationship.stepMother] },
    { key: EntityRelationship.motherInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.motherInLaw] },
    { key: EntityRelationship.son, value: EntityRelationshipDisplayLookup[EntityRelationship.son] },
    { key: EntityRelationship.stepSon, value: EntityRelationshipDisplayLookup[EntityRelationship.stepSon] },
    { key: EntityRelationship.sonInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.sonInLaw] },
    { key: EntityRelationship.daughter, value: EntityRelationshipDisplayLookup[EntityRelationship.daughter] },
    { key: EntityRelationship.stepDaughter, value: EntityRelationshipDisplayLookup[EntityRelationship.stepDaughter] },
    { key: EntityRelationship.daughterInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.daughterInLaw] },
    { key: EntityRelationship.child, value: EntityRelationshipDisplayLookup[EntityRelationship.child] },
    { key: EntityRelationship.stepChild, value: EntityRelationshipDisplayLookup[EntityRelationship.stepChild] },
    { key: EntityRelationship.brother, value: EntityRelationshipDisplayLookup[EntityRelationship.brother] },
    { key: EntityRelationship.stepBrother, value: EntityRelationshipDisplayLookup[EntityRelationship.stepBrother] },
    { key: EntityRelationship.brotherInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.brotherInLaw] },
    { key: EntityRelationship.halfBrother, value: EntityRelationshipDisplayLookup[EntityRelationship.halfBrother] },
    { key: EntityRelationship.sister, value: EntityRelationshipDisplayLookup[EntityRelationship.sister] },
    { key: EntityRelationship.stepSister, value: EntityRelationshipDisplayLookup[EntityRelationship.stepSister] },
    { key: EntityRelationship.sisterInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.sisterInLaw] },
    { key: EntityRelationship.halfSister, value: EntityRelationshipDisplayLookup[EntityRelationship.halfSister] },
    { key: EntityRelationship.sibling, value: EntityRelationshipDisplayLookup[EntityRelationship.sibling] },
    { key: EntityRelationship.grandfather, value: EntityRelationshipDisplayLookup[EntityRelationship.grandfather] },
    {
        key: EntityRelationship.stepGrandfather,
        value: EntityRelationshipDisplayLookup[EntityRelationship.stepGrandfather]
    },
    {
        key: EntityRelationship.grandfatherInLaw,
        value: EntityRelationshipDisplayLookup[EntityRelationship.grandfatherInLaw]
    },
    {
        key: EntityRelationship.greatGrandfather,
        value: EntityRelationshipDisplayLookup[EntityRelationship.greatGrandfather]
    },
    { key: EntityRelationship.grandmother, value: EntityRelationshipDisplayLookup[EntityRelationship.grandmother] },
    {
        key: EntityRelationship.stepGrandmother,
        value: EntityRelationshipDisplayLookup[EntityRelationship.stepGrandmother]
    },
    {
        key: EntityRelationship.grandmotherInLaw,
        value: EntityRelationshipDisplayLookup[EntityRelationship.grandmotherInLaw]
    },
    {
        key: EntityRelationship.greatGrandmother,
        value: EntityRelationshipDisplayLookup[EntityRelationship.greatGrandmother]
    },
    { key: EntityRelationship.grandparent, value: EntityRelationshipDisplayLookup[EntityRelationship.grandparent] },
    {
        key: EntityRelationship.greatGrandparent,
        value: EntityRelationshipDisplayLookup[EntityRelationship.greatGrandparent]
    },
    { key: EntityRelationship.grandson, value: EntityRelationshipDisplayLookup[EntityRelationship.grandson] },
    { key: EntityRelationship.stepGrandson, value: EntityRelationshipDisplayLookup[EntityRelationship.stepGrandson] },
    { key: EntityRelationship.grandsonInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.grandsonInLaw] },
    { key: EntityRelationship.greatGrandson, value: EntityRelationshipDisplayLookup[EntityRelationship.greatGrandson] },
    { key: EntityRelationship.granddaughter, value: EntityRelationshipDisplayLookup[EntityRelationship.granddaughter] },
    {
        key: EntityRelationship.stepGranddaughter,
        value: EntityRelationshipDisplayLookup[EntityRelationship.stepGranddaughter]
    },
    {
        key: EntityRelationship.granddaughterInLaw,
        value: EntityRelationshipDisplayLookup[EntityRelationship.granddaughterInLaw]
    },
    {
        key: EntityRelationship.greatGranddaughter,
        value: EntityRelationshipDisplayLookup[EntityRelationship.greatGranddaughter]
    },
    { key: EntityRelationship.grandchild, value: EntityRelationshipDisplayLookup[EntityRelationship.grandchild] },
    {
        key: EntityRelationship.greatGrandchild,
        value: EntityRelationshipDisplayLookup[EntityRelationship.greatGrandchild]
    },
    { key: EntityRelationship.uncle, value: EntityRelationshipDisplayLookup[EntityRelationship.uncle] },
    { key: EntityRelationship.stepUncle, value: EntityRelationshipDisplayLookup[EntityRelationship.stepUncle] },
    { key: EntityRelationship.uncleInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.uncleInLaw] },
    { key: EntityRelationship.greatUncle, value: EntityRelationshipDisplayLookup[EntityRelationship.greatUncle] },
    { key: EntityRelationship.aunt, value: EntityRelationshipDisplayLookup[EntityRelationship.aunt] },
    { key: EntityRelationship.stepAunt, value: EntityRelationshipDisplayLookup[EntityRelationship.stepAunt] },
    { key: EntityRelationship.auntInLaw, value: EntityRelationshipDisplayLookup[EntityRelationship.auntInLaw] },
    { key: EntityRelationship.greatAunt, value: EntityRelationshipDisplayLookup[EntityRelationship.greatAunt] },
    { key: EntityRelationship.nephew, value: EntityRelationshipDisplayLookup[EntityRelationship.nephew] },
    { key: EntityRelationship.greatNephew, value: EntityRelationshipDisplayLookup[EntityRelationship.greatNephew] },
    { key: EntityRelationship.niece, value: EntityRelationshipDisplayLookup[EntityRelationship.niece] },
    { key: EntityRelationship.greatNiece, value: EntityRelationshipDisplayLookup[EntityRelationship.greatNiece] },
    { key: EntityRelationship.cousin, value: EntityRelationshipDisplayLookup[EntityRelationship.cousin] },
    { key: EntityRelationship.secondCousin, value: EntityRelationshipDisplayLookup[EntityRelationship.secondCousin] },
];

const NO_RELATIONS: SuggestionType<EntityRelationship>[] = [
];

// const OTHER_RELATIONS: SuggestionType[] = [
//     { key: 'A', value: 'Acquaintance' },
//     { key: 'O', value: 'Other' },
// ];

const ACQUAINTANCE_BUTTONS: AcquaintanceButtonType[] = [
    {
        label: EntityRelationshipType.family,
        icon: <SupervisedUserCircleIcon />,
        displayMessage: EntityRelationshipTypeDisplayLookup[EntityRelationshipType.family],
        suggestedRelations: FAMILY_RELATIONS
    },
    {
        label: EntityRelationshipType.friend,
        icon: <EmojiPeopleIcon />,
        displayMessage: EntityRelationshipTypeDisplayLookup[EntityRelationshipType.friend],
        suggestedRelations: NO_RELATIONS
    },
    {
        label: EntityRelationshipType.school,
        icon: <SchoolIcon />,
        displayMessage: EntityRelationshipTypeDisplayLookup[EntityRelationshipType.school],
        suggestedRelations: NO_RELATIONS
    },
    {
        label: EntityRelationshipType.work,
        icon: <LocationCityIcon />,
        displayMessage: EntityRelationshipTypeDisplayLookup[EntityRelationshipType.work],
        suggestedRelations: NO_RELATIONS
    },
    {
        label: EntityRelationshipType.church,
        icon: <MenuBookIcon />,
        displayMessage: EntityRelationshipTypeDisplayLookup[EntityRelationshipType.church],
        suggestedRelations: NO_RELATIONS
    },
    {
        label: EntityRelationshipType.other,
        icon: <PermIdentityIcon />,
        displayMessage: '______ (Fill in the Relation)', // NOTE: this display message is different because it is used 
        // As the sub-heading only on the selection, but the alias 
        // should always be set (forced by the front end)
        suggestedRelations: NO_RELATIONS
    },
];

export const getRelationshipDisplayValue = (
    relationshipDetails: {
        relationship: EntityRelationship | null;
        relationship_alias: string | null;
        relationship_type: EntityRelationshipType | null;
    },
    useDefaultButtonValue?: boolean): string => {

    const { relationship, relationship_alias, relationship_type } = relationshipDetails;
    let displayRelationshipValue = ''; // by default lets set it to the Button display value
    if (useDefaultButtonValue && relationship_type) {
        const selectedButton = ACQUAINTANCE_BUTTONS.find(b => b.label === relationship_type);
        if (selectedButton) {
            displayRelationshipValue = selectedButton.displayMessage;
        }
    }
    if (relationship_alias && relationship_alias.trim().length > 0) {
        displayRelationshipValue = relationship_alias.trim();
    } else { // Ok Relationship Alias is not set Lets determine what we need to enter
        if (relationship_type && relationship_type === EntityRelationshipType.family
            && relationship && relationship !== EntityRelationship.other
            && EntityRelationshipDisplayLookup[relationship]) {
            displayRelationshipValue = EntityRelationshipDisplayLookup[relationship];
        }
    }
    return displayRelationshipValue;
};

const CustomButton = (props: AcquaintanceButtonType & { isActive: boolean } & StyledProps & ButtonProps) => {
    const { classes, isActive, icon, label, displayMessage, suggestedRelations, ...others } = props;

    return (
        <Button
            {...others}
            color="primary"
            variant={isActive && 'contained' || 'outlined'}
            className={classes.customButton}
        >
            <span className={classes.buttonLabel}>
                <SvgIcon className={classes.icon}>{icon}</SvgIcon>
                {label}
            </span>
        </Button>
    );
};

type AcquaintanceButtonType = {
    key?: number | string;
    label: EntityRelationshipType;
    icon: JSX.Element;
    displayMessage: string;
    suggestedRelations: SuggestionType<EntityRelationship>[];
};

type StyledProps = WithStyles<'root' | 'customButton' | 'label' | 'selectTextField'
    | 'helperText' | 'textColorRed' | 'gDownshiftPaper' | 'buttonLabel' | 'icon'>;

interface Props {
    isDeceased?: boolean;
    caseFName: string;
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    setRelationship: (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string) => void;
    userFName?: string;
    saveAttempted?: boolean;
    isSendInvitation?: boolean;
    relationshipType: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationshipAlias: string | null;
    hasError?: boolean;
    isRequired?: boolean;
    hideLabel?: boolean;
    hideBottomText?: boolean;
    isMe?: true;
    buttonsDisabled?: boolean;
}

const AcquaintanceButtons = (props: StyledProps & Props) => {

    const {
        isDeceased,
        caseFName,
        userFName,
        formType,
        classes,
        saveAttempted,
        relationship,
        relationshipAlias,
        relationshipType,
        hasError,
        isRequired,
        hideLabel,
        hideBottomText,
        isMe,
        buttonsDisabled,
        setRelationship
    } = props;


    const onAcquaintanceButtonClick = (selectedRelationshipType: EntityRelationshipType) => {
        setRelationship(selectedRelationshipType, null, '');
    };

    const onRelationshipChange = (
        evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ) => {
        const _relationshipAlias = relationshipType && relationshipType !== EntityRelationshipType.family
            || (relationship !== evt.target.value && evt.target.value !== '') ? evt.target.value : '';
        setRelationship(relationshipType, relationship, _relationshipAlias || '');
    };
    const onRelationshipSelect = (suggestion: SuggestionType<string>) => {
        const { key } = suggestion;

        const relationshipButton = ACQUAINTANCE_BUTTONS.find(b => b.label === relationshipType);

        if (!relationshipButton) { // we should always find the relationshipButton 
            return;
        }
        const relationshipSuggestions = relationshipButton.suggestedRelations;
        const relationshipKey = relationshipSuggestions.find(r => r.key.toLowerCase() === key.toLowerCase());
        const relationshipString = relationshipKey ? `${relationshipKey.key}` : '';
        let _relationship: EntityRelationship | null = null;
        if (isEntityRelationship(relationshipString)) {
            _relationship = relationshipString;
        }
        setRelationship(relationshipType, _relationship, '');
    };


    const activeAcquaintance = ACQUAINTANCE_BUTTONS.find(b => b.label === relationshipType);
    const userFirstName = userFName && userFName.trim() || formType === 'INVITE_ADMIN' && 'Admin' || 'Guest';
    let defaultRelationshipAlias =  // this is very similar toz the displayRelationship
        relationshipAlias && relationshipAlias.trim().length > 0 ? relationshipAlias : '';
    if (defaultRelationshipAlias === ''
        && relationshipType && relationshipType === EntityRelationshipType.family
        && relationship && relationship !== EntityRelationship.other
    ) {
        defaultRelationshipAlias = EntityRelationshipDisplayLookup[relationship] || relationship;
    }

    const displayRelationship = relationshipAlias && relationshipAlias.trim().length > 0 ?
        relationshipAlias : relationship && relationship !== EntityRelationship.other ?
            EntityRelationshipDisplayLookup[relationship] || relationship
            : activeAcquaintance ? activeAcquaintance.displayMessage : '';

    return (
        <>
            {!hideLabel && <Typography color="primary" className={classes.label}>
                How {isDeceased && 'did' || 'does'} {userFirstName} know {caseFName}?
            </Typography>}

            {ACQUAINTANCE_BUTTONS.map((b, i) => (
                <CustomButton
                    {...b}
                    key={b.label}
                    isActive={Boolean(b.label === relationshipType)}
                    classes={classes}
                    disabled={buttonsDisabled}
                    onClick={e => {
                        e.stopPropagation();
                        onAcquaintanceButtonClick(b.label);
                    }}
                />

            ))}
            {activeAcquaintance &&
                (activeAcquaintance.suggestedRelations.length > 0 ||
                    activeAcquaintance.label === EntityRelationshipType.other ||
                    activeAcquaintance.label === EntityRelationshipType.church) &&
                <GAutocomplete
                    fullWidth
                    placeholder={activeAcquaintance.label === EntityRelationshipType.other ?
                        'Type Relationship' : 'Select Relationship'}
                    label={`${userFirstName} ${!isMe ? isDeceased ? 'was' : 'is' : ''} ${caseFName}'s...`}
                    className={classes.selectTextField}
                    listboxClassName={classes.gDownshiftPaper}
                    variant="outlined"
                    suggestions={activeAcquaintance ? activeAcquaintance.suggestedRelations : []}
                    inputValue={defaultRelationshipAlias}
                    selectedKey={relationship || ''}
                    onChange={onRelationshipChange}
                    onSelectItem={onRelationshipSelect}
                    error={(activeAcquaintance.label === EntityRelationshipType.other && saveAttempted &&
                        Boolean(relationshipAlias && relationshipAlias.trim().length === 0)) || hasError}
                    required={activeAcquaintance.label === EntityRelationshipType.other || isRequired}
                    InputLabelProps={{ shrink: true }}
                    popperClassName={GStyles.width100}
                    InputProps={{
                        classes: {
                            notchedOutline: 'notranslate'
                        }
                    }}
                    size="small"
                />}

            {(activeAcquaintance && !hideBottomText) &&
                <Typography className={classNames(
                    classes.helperText,
                    (activeAcquaintance.label === EntityRelationshipType.other && saveAttempted &&
                        (!relationshipAlias || (relationshipAlias && relationshipAlias.trim().length === 0))) &&
                    classes.textColorRed
                )}>
                    {userFirstName} {isMe ? '' : isDeceased ? 'was ' : 'is '}
                    {caseFName}'s {displayRelationship}
                </Typography>}
        </>
    );
};

export default withStyles(styles)(AcquaintanceButtons);


