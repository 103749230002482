import { useMemo } from 'react';
import { isCaseAdmin } from '../../../services';
import { EntitySummary, GatherCaseUX, getCaseEntity, getUserFuneralHome } from '../../../shared/types';
import BaseAssign, { BaseAssignEntity, BaseAssignProps } from './BaseAssign';

export const getSecondaryTextForEntitySummary = (params: {
    entity: EntitySummary | null;
    gatherCase: Pick<GatherCaseUX, 'id' | 'fname' | 'funeral_home_id'>;
}): string | null => {
    const { entity, gatherCase } = params;
    const userFHDetails = getUserFuneralHome(entity, gatherCase.funeral_home_id);
    if (userFHDetails) {
        return userFHDetails?.visible_title || null;
    }

    const caseEntity = getCaseEntity(entity, gatherCase.id);
    if (caseEntity?.display_relationship?.trim()) {
        return `${gatherCase.fname}'s ${caseEntity.display_relationship}`;
    }
    return null;
};

export const injectBaseAssignEntityFields = (params: {
    entityList: EntitySummaryForBaseAssign[];
    gatherCase: Pick<GatherCaseUX, 'id' | 'fname' | 'funeral_home_id'> | null;
}): (EntitySummary & BaseAssignEntity)[] => {
    const { entityList, gatherCase } = params;
    return entityList.map((entity) => ({
        ...entity,
        isAdmin: entity.isAdmin ?? (gatherCase ? isCaseAdmin({ entity, gatherCase }) : false),
        secondaryText:
            entity.secondaryText ?? (gatherCase ? getSecondaryTextForEntitySummary({ entity, gatherCase }) : null),
    }));
};

export type EntitySummaryForBaseAssign = EntitySummary & Partial<BaseAssignEntity>;

export type CaseForBaseAssign = Pick<GatherCaseUX, 'id' | 'fname' | 'funeral_home_id'>;

export type BaseAssignEntitySummary =EntitySummaryForBaseAssign & BaseAssignEntity;

export interface Props extends Omit<BaseAssignProps<BaseAssignEntitySummary>, 'items' | 'assignedTo'> {
    items: EntitySummaryForBaseAssign[];
    assignedTo?: EntitySummary[];
    gatherCase: CaseForBaseAssign | null;
    isLoading?: boolean;
}

const BaseAssignForEntitySummary = (props: Props) => {
    const { items, assignedTo, gatherCase, ...rest } = props;

    const mappedItems: BaseAssignEntitySummary[] = useMemo(
        () => injectBaseAssignEntityFields({ entityList: items, gatherCase }),
        [items, gatherCase],
    );
    const mappedAssignedTo: BaseAssignEntitySummary[] | undefined = useMemo(
        () => (assignedTo ? injectBaseAssignEntityFields({ entityList: assignedTo, gatherCase }) : undefined),
        [assignedTo, gatherCase],
    );
    return <BaseAssign items={mappedItems} assignedTo={mappedAssignedTo} {...rest} />;
};

export default BaseAssignForEntitySummary;
