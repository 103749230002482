import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FeatureKey, GatherCasePublic, RememberStoreCTA } from '../../../../../shared/types';
import { SelfLoginStyledProps } from '../SelfLogin.styles';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalFlorist, Park } from '@mui/icons-material';
import { FLOWER_BACKGROUND_IMAGE, TREE_BACKGROUND_IMAGE } from '../../../../../constants';
import makeGStyles from '../../../../../styles/makeGStyles';
import { FlowerIconBar, TreeIconBar } from '../../../../remember/flowerSales/FlowerSalesHeader';
import useFeatureEnabled from '../../../../common/hooks/useFeatureEnabled';
import { RememberStoreRoute, RememberStoreType, RouteBuilder } from '../../../../../shared/navigationUtils';
import GLinkWithButton from '../../../../common/GLinkWithButton';
import classNames from 'classnames';

interface StyleProps {
    backgroundImage: string;
    isMobile: boolean;
    frameCount: number;
}

const useStyles = makeGStyles((theme: Theme) => {
    return {
        root: ({ isMobile }: StyleProps) => ({
            height: isMobile ? '100vh' : '100%',
        }),
        adFrame: ({ backgroundImage, isMobile, frameCount }: StyleProps) => ({
            backgroundImage: `url('${backgroundImage}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: isMobile ? theme.spacing(2) : theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minHeight: isMobile ? 'auto' : '400px',
            position: 'relative',
            gap: 20,
            paddingTop: 100,
            flexBasis: !isMobile && frameCount < 2 ? '100%' : undefined,
            maxWidth: !isMobile && frameCount < 2 ? '100%' : undefined,
            '& .MuiSvgIcon-root': {
                color: theme.palette.common.white,
                fontSize: 30,
            },
            '& h2': {
                color: theme.palette.common.white,
                fontWeight: 500,
                textShadow: '0px 2px 9px rgba(0, 0, 0, 0.95)',
                fontSize: isMobile ? 24 : 32,
                marginBottom: theme.spacing(1),
            },
            '& p': {
                maxWidth: 491,
                color: theme.palette.common.white,
                fontWeight: 500,
                textShadow: '0px 2px 9px rgba(0, 0, 0, 0.95)',
                fontSize: isMobile ? 18 : 14,
                marginBottom: theme.spacing(2),
            },
        }),
        buyButton: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            marginTop: theme.spacing(2),
            '&:hover': {
                color: theme.palette.common.white,
            },
        },
        wideButtonGrid: {
            '@media (max-width: 900px)': {
                display: 'none',
            },
            position: 'absolute',
            bottom: 50,
            textAlign: 'center',
            width: '100%',
            '& button': {
                backgroundColor: theme.palette.primary.main,
                minWidth: 300,
                color: theme.palette.common.white,
            },
        },
        narrowButtonGrid: {
            '@media (min-width: 900px)': {
                display: 'none',
            },
            textAlign: 'center',
            width: '100%',
            '& button': {
                marginTop: 20,
                backgroundColor: theme.palette.primary.main,
                minWidth: 300,
                color: theme.palette.common.white,
            },
        },
    };
}, { name: 'SendFlowerStep' });

interface AdFrameProps {
    backgroundImage: string;
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    buttonLabel: string;
    header: JSX.Element;
    isMobile: boolean;
    frameCount: 1 | 2;
    route: RememberStoreRoute;
    includeDoneButton: boolean;
    gaClassName?: string;
    onClose: () => void;
}

export const AdFrame = (props: AdFrameProps) => {

    const {
        backgroundImage,
        icon,
        title,
        subtitle,
        buttonLabel,
        header,
        isMobile,
        frameCount,
        route,
        gaClassName,
        onClose,
        includeDoneButton,
    } = props;

    const classes = useStyles({ backgroundImage, isMobile, frameCount });

    return (
        <Grid
            item
            xs={12}
            md={6}
            className={classes.adFrame}
        >
            {header}
            {icon}
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
            <GLinkWithButton
                to={route}
                buttonClass={classNames(`${gaClassName ? gaClassName : ''}`, classes.buyButton)}
                GButtonProps={{
                    onClick: onClose
                }}
            >
                {buttonLabel}
            </GLinkWithButton>

            {includeDoneButton && <Grid item xs={12} className={classes.narrowButtonGrid} >
                <Button variant="contained" onClick={onClose}>
                    DONE
                </Button>
            </Grid>}

        </Grid>
    );
};

interface Props extends SelfLoginStyledProps {
    publicCase: GatherCasePublic;
    onCloseDialog: () => void;
    onForceCloseDialog: () => void;
}

const SendFlowersStep = (props: Props) => {
    const { publicCase, onCloseDialog, onForceCloseDialog } = props;
    const theme = useTheme();
    const treesEnabled = useFeatureEnabled(FeatureKey.PLANT_TREES)
        && publicCase.options.plant_trees;
    const flowersEnabled = useFeatureEnabled(FeatureKey.REMEMBER_FLOWER_LINK)
        && publicCase.options.sell_flowers;
    const frameCount = (treesEnabled ? 1 : 0) + (flowersEnabled ? 1 : 0);
    // If only one side is enabled, then always show the mobile version of the view
    const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('xs'));

    const fname = publicCase.display_fname;
    const flowerSubtitle = `Sympathy flowers are a simple way to let ${fname}'s
        loved ones know that you are thinking about them.`;
    const treeSubtitle = `Plant a tree in ${fname}'s memory with one of our
        re-forestation projects across the country.`;

    const flowerLink = RouteBuilder.RememberStore({
        caseName: publicCase.name,
        cta: RememberStoreCTA.LoginFlow,
        storeType: RememberStoreType.flowers,
    });

    const treeLink = RouteBuilder.RememberStore({
        caseName: props.publicCase.name,
        cta: RememberStoreCTA.LoginFlow,
        storeType: RememberStoreType.trees,
    });

    const classes = useStyles({
        backgroundImage: '',
        isMobile,
        frameCount,
    });

    // This should never happen, but just in case
    if (frameCount !== 1 && frameCount !== 2) {
        return (
            <Grid item xs={12} >
                <Button variant="contained" onClick={onCloseDialog}>
                    DONE
                </Button>
            </Grid>
        );
    }

    return (
        <Grid container direction={isMobile ? 'column' : 'row'} className={classes.root}>
            {flowersEnabled && <AdFrame
                backgroundImage={FLOWER_BACKGROUND_IMAGE}
                icon={<LocalFlorist />}
                title="Send beautiful flowers from a local florist."
                subtitle={flowerSubtitle}
                buttonLabel="SEND FLOWERS TO FAMILY"
                header={<FlowerIconBar barType='login' />}
                isMobile={isMobile}
                frameCount={frameCount}
                route={flowerLink}
                onClose={onForceCloseDialog}
                includeDoneButton={frameCount === 1}
            />}
            {treesEnabled && <AdFrame
                gaClassName='tree-login-flow'
                backgroundImage={TREE_BACKGROUND_IMAGE}
                icon={<Park />}
                title="Plant a memorial tree in a forest in need."
                subtitle={treeSubtitle}
                buttonLabel={`PLANT A TREE FOR ${fname}`}
                header={<TreeIconBar barType='login' />}
                isMobile={isMobile}
                frameCount={frameCount}
                route={treeLink}
                onClose={onForceCloseDialog}
                includeDoneButton={true}
            />}
            <Grid item xs={12} className={classes.wideButtonGrid} >
                <Button variant="contained" onClick={onCloseDialog}>
                    DONE
                </Button>
            </Grid>
        </Grid>
    );
};

export default SendFlowersStep;