import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

export enum SubscriptionType {
    case_service_reminder = 'case: service reminder',
    case_service_summary = 'case: service summary',
    case_new_memories = 'case: new memories',
    case_responses_to_me = 'case: responses to me',
    report_some_report = 'report: some report',
    obit_by_website_id = 'obit: by_website_id',
    obit_by_newObject_id = 'obit: by_newObject_id'
}

export const SubscriptionTypeDefinition = t.union([
    t.literal(SubscriptionType.case_service_reminder),
    t.literal(SubscriptionType.case_service_summary),
    t.literal(SubscriptionType.case_new_memories),
    t.literal(SubscriptionType.case_responses_to_me),
    t.literal(SubscriptionType.report_some_report),
    t.literal(SubscriptionType.obit_by_website_id),
    t.literal(SubscriptionType.obit_by_newObject_id),
]);

export type SubscriptionTypeType = t.TypeOf<typeof SubscriptionTypeDefinition>;

export type EntitySubscriptionType = SubscriptionType.case_service_reminder 
    | SubscriptionType.case_service_summary 
    | SubscriptionType.case_new_memories;

export enum NotificationMethod {
    sms = 'sms',
    email = 'email',
}

export const NotificationMethodDefinition = t.union([
    t.literal(NotificationMethod.sms),
    t.literal(NotificationMethod.email),
]);

export type NotificationMethodType = t.TypeOf<typeof NotificationMethodDefinition>;
/**
 * EntitySubscriptionRecord matches the database table
 */
export interface EntitySubscriptionRecord {
    id: number;
    entity_id: number;
    uuid: string;
    subscription_type: SubscriptionType;
    notification_method: NotificationMethodType; // email | sms
    opt_in_time: Date | null;
    opt_in_ip: string | null;
    opt_out_time: Date | null;
    opt_out_ip: string | null;
    last_failed_time: Date | null;
    last_failed_msg: string | null;
    consecutive_failed_msg_count: number | null;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
    website_id: number | null;
    gather_case_id: number | null;
    memory_id: number | null;
};

/**
 * EntitySubscription matches the subquery subscription_json located
 * in the finalllUXForEntityIdList db script
 */
export interface EntitySubscription extends Pick<EntitySubscriptionRecord,
    'id' |
    'entity_id' |
    'uuid' |
    'subscription_type' |
    'notification_method' |
    'deleted_time' |
    'website_id' |
    'gather_case_id' |
    'memory_id'
> {

};

/**
 * Subscription Upsert IOTS Type
 * 
 */

export interface EntitySubscriptionUpsertRecord extends Partial<Omit<EntitySubscriptionRecord,
    'id' |
    'uuid' |
    'entity_id' |
    'subscription_type' |
    'deleted_time' |
    'notification_method'
>> {
    'updated_by': number;
    'updated_time': Date;
    'subscription_type': SubscriptionType;
    'deleted_time': Date | null;
    'notification_method': NotificationMethodType;
};


export const EntitySubscriptionUpdateRequestDefinition = t.type({
    uuid: t.string,
    deleted_time: t.union([DateFromISOString, t.null]),
});

export type EntitySubscriptionUpdateRequest = t.TypeOf<typeof EntitySubscriptionUpdateRequestDefinition>;


/**
 *  Subscription Inserts IOTS Type
 */
export const EntitySubscriptionInsertRequestDefinition = t.type({
    uuid: t.string,
    entity_id: t.union([t.number, t.null]),
    notification_method: NotificationMethodDefinition,
    subscription_details: t.union([
        t.type({
            subscription_type: t.literal(SubscriptionType.obit_by_website_id),
            website_id: t.number,

        }),
        t.type({
            subscription_type: t.union([
                t.literal(SubscriptionType.case_new_memories),
                t.literal(SubscriptionType.case_service_reminder),
                t.literal(SubscriptionType.case_service_summary),
            ]),
            gather_case_id: t.number,
        }),
        t.type({
            subscription_type: t.literal(SubscriptionType.case_responses_to_me),
            memory_id: t.number,
        }),
    ]),
    deleted_time: t.union([DateFromISOString, t.null]),
});

export type EntitySubscriptionInsertRequest = t.TypeOf<typeof EntitySubscriptionInsertRequestDefinition>;


export interface EntitySubscriptionInsertRecord extends Pick<EntitySubscriptionRecord,
    'entity_id' |
    'uuid' |
    'subscription_type' |
    'notification_method' |
    'created_by' |
    'updated_by' |
    'deleted_time' |
    'deleted_by'
> {
    website_id?: number | null;
    gather_case_id?: number | null;
    memory_id?: number | null;
};

export const EntitySubscriptionInsertOrUpdateDefinition = t.union([
    EntitySubscriptionUpdateRequestDefinition,
    EntitySubscriptionInsertRequestDefinition,
]);
export type EntitySubscriptionInsertOrUpdateRequest = t.TypeOf<typeof EntitySubscriptionInsertOrUpdateDefinition>;

export function isSubscriptionInsert(subscription: EntitySubscription
    | EntitySubscriptionUpdateRequest
    | EntitySubscriptionInsertRequest | null | undefined): subscription is EntitySubscriptionInsertRequest {
    return (
        !!subscription
        && "subscription_details" in subscription && typeof subscription.subscription_details === "object"
        && (subscription as EntitySubscriptionInsertRequest).subscription_details !== null
    );
}

export function isSubscriptionUpdateRequest(obj: {
    uuid: string;
    deleted_time: Date | null;
} | null | undefined): obj is EntitySubscriptionUpdateRequest {
    return !!obj
        && typeof obj.uuid === "string"
        && (obj.deleted_time instanceof Date || obj.deleted_time === null);
}

export interface GatherCaseSubscriptionRecord {
    id: number;
    gather_case_id: number;
    uuid: string;
    subscription_type: SubscriptionType;
    notification_method: NotificationMethodType;
    opt_in_time: Date | null;
    opt_in_ip: string | null;
    opt_out_time: Date | null;
    opt_out_ip: string | null;
    last_failed_time: Date | null;
    last_failed_msg: string | null;
    consecutive_failed_msg_count: number | null;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
    website_id: number | null;
    entity_id: number | null;
    memory_id: number | null;
};