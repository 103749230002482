import { updateCaseNumber } from "../../../../actions/GatherCase.action";
import { getFullNameFromCase } from "../../../../services";
import { GatherCaseUX } from "../../../../shared/types/case";
import { GStyles } from "../../../../styles/GStyles";
import { useGDispatch } from "../../../../types";
import GDialogWithTextField from "../../../common/GDialogWithTextField";

type CaseForCaseNumberDialog = Pick<GatherCaseUX, 'uuid' | 'fname' | 'lname' | 'case_number'>;

interface Props<T extends CaseForCaseNumberDialog> {
    zIndex: number;
    activeCase: T;
    closeDialog: () => void;
    isDialogOpen: boolean;
    isMultiline?: boolean;
    numberOfRows?: number;
    onCaseNumberUpdated?: (caseNumber: string, activeCase: T) => void;
}

const CaseNumberDialog = <T extends CaseForCaseNumberDialog>(props: Props<T>) => {
    const { zIndex, isDialogOpen, activeCase, isMultiline, numberOfRows, closeDialog, onCaseNumberUpdated } = props;
    const dispatch = useGDispatch();

    const handleSave = async (caseNumber: string) => {
        const newCaseNumber = !caseNumber.trim() ? '' : caseNumber.trim();
        const result = await dispatch(updateCaseNumber(activeCase.uuid, newCaseNumber));
        if (result) {
            onCaseNumberUpdated?.(caseNumber, activeCase);
        }
    };

    return (
        <GDialogWithTextField
            zIndex={zIndex + 1}
            title="Case Number"
            initialValue={activeCase.case_number || ''}
            description={<>
                Enter a case number that you would like to use for&nbsp;
                <span className={GStyles.fontWeight600}>{getFullNameFromCase(activeCase)}</span>
            </>}
            isDialogOpen={isDialogOpen}
            textFieldLabel="Type Case Number Here"
            closeDialog={closeDialog}
            isMultiline={isMultiline}
            numberOfRows={numberOfRows}
            skipValidation
            onSave={handleSave}
        />
    );
};

export default CaseNumberDialog;
