import { WithStyles } from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import { OrganizationDisplayTypes, RolodexOrgResult } from '../../../shared/types';
import { RowClickHandlerFunc } from './utils';

type StyledProps = WithStyles<
    'firstItem' | 'heading' | 'subHeading' | 'profession' | 'location' | 'assignments' | 'tabContainerDivider'
>;

type Props = {
    org: RolodexOrgResult;
    rowClickHandler: RowClickHandlerFunc;
} & StyledProps;

const OrganizationRow = (props: Props) => {
    const { org, rowClickHandler, classes } = props;

    return (
        <>
            <Grid item xs={12} className={classes.firstItem} onClick={() => rowClickHandler(org)}>
                <Typography color="primary" className={classes.heading}>
                    <BusinessIcon color="primary" />&nbsp;{org.name}
                </Typography>
                <Grid item xs={12} className={classes.subHeading}>
                    {org.orgType &&
                        <Typography color="secondary" className={classes.profession}>
                            {org.otherText !== null ? org.otherText : OrganizationDisplayTypes[org.orgType]}
                        </Typography>
                    }
                    {org.address &&
                        <Typography color="secondary" className={classes.location}>
                            <LocationOnIcon color="secondary" />&nbsp;{org.address}
                        </Typography>
                    }
                    <Typography color="secondary" className={classes.assignments}>
                        <AssignmentIndIcon color="secondary" />&nbsp;{org.contactCount || 0} Contacts
                    </Typography>
                </Grid>
            </Grid>
            <Divider color="secondary" className={classes.tabContainerDivider} />
        </>
    );
};

export default OrganizationRow;
