import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import { GatherCaseUX } from '../../../../shared/types';
import AssignTask from '../../../assignmentPoppers/AssignTask';
import { PendingSteps, TrackingItem, TrackingItemType } from '../BodyTrackingContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStyles } from './QRPendingSteps';
import { useGSelector } from '../../../../types';
import { useQRStepperCommonStyles } from '.';
import GLink from '../../../common/GLink';
import { RouteBuilder, dueDateTooltipString, getIntercomTargetProp, getStandardDateText } from '../../../../services';
import { canCompleteTrackingSteps, canModifyTaskDueDates } from '../../../../shared/authority/can';
import GTooltip from '../../../common/GTooltip';
import { TOOLTIPS } from '../../../../constants';
import moment from 'moment-timezone';
import { Box } from '@mui/material';

interface Props {
    step: TrackingItem;
    isLast: boolean;
    zIndex: number;
    selectedCase: GatherCaseUX;
    hasFinalizeStep?: boolean;
    onMenuClick?: (anchor: HTMLElement) => void;
    intercomTargetProp?: string;
    onDueDateClick?: (stepId: number) => void;
}
const PendingStep = (props: Props) => {
    const { zIndex, step, isLast, selectedCase, hasFinalizeStep, onMenuClick, intercomTargetProp, onDueDateClick } =
        props;

    const classes = useStyles();
    const commonStyles = useQRStepperCommonStyles();

    const isAddAction = step.itemType === TrackingItemType.add_action;
    const isAddMedallion = step.itemType === TrackingItemType.add_medallion;
    const isAddActionOrAddNote = step.itemType === TrackingItemType.note || isAddAction || isAddMedallion;

    const trackingStep = useGSelector(({ tasksState }) =>
        tasksState.trackingSteps.find((tStep) => tStep.id === step.stepId),
    );

    const userProfile = useGSelector(({ userSession }) => userSession.userData);
    const funeralHome = useGSelector(({ funeralHomeState }) => funeralHomeState.activeFuneralHome);
    const canCompleteTracking = Boolean(funeralHome && canCompleteTrackingSteps(userProfile, funeralHome.id));

    const handleClick = () => {
        if (canCompleteTracking && step.onClick) {
            step.onClick();
        }
    };
    const stepLink = step.link && canCompleteTracking ? step.link : RouteBuilder.Disabled;

    const canEditDueDate = Boolean(funeralHome && canModifyTaskDueDates(userProfile, funeralHome.id));

    const dueDateString =
        trackingStep?.complete_by_time &&
        getStandardDateText({
            timezone: moment.tz.guess(),
            targetDate: trackingStep.complete_by_time,
            prefix: 'Due',
            pastPrefix: ':',
        });
    return (
        <Grid container flexWrap="nowrap" className={commonStyles.stepWrapper} key={step.key}>
            <Grid className={commonStyles.stepLeftContainer}>
                <div
                    className={classNames(
                        commonStyles.iconContainer,
                        step.icon && commonStyles.borderRadius50,
                        step.iconImage && commonStyles.stepImageBorderRadius,
                        isAddActionOrAddNote && classes.addButtons,
                        isAddMedallion && classes.addMedallion,
                        !canCompleteTracking && commonStyles.opacity30Important,
                    )}
                >
                    {step.icon && (
                        <GLink to={stepLink}>
                            <GTooltip
                                contentDisabled={!canCompleteTracking}
                                title={!canCompleteTracking ? TOOLTIPS.DISABLED_FEATURE : ''}
                            >
                                <IconButton
                                    className={(step.label === PendingSteps.addNote && classes.fontSize20) || undefined}
                                    disabled={!canCompleteTracking}
                                    onClick={handleClick}
                                >
                                    {step.icon}
                                </IconButton>
                            </GTooltip>
                        </GLink>
                    )}
                    {step.iconImage && (
                        <GLink to={stepLink}>
                            <Button
                                className={commonStyles.stepIconImage}
                                onClick={handleClick}
                                disabled={!canCompleteTracking}
                            >
                                {step.iconImage}
                            </Button>
                        </GLink>
                    )}
                </div>

                {(hasFinalizeStep || !isLast) && (
                    <div
                        className={classNames(commonStyles.stepConnector, isLast && commonStyles.stepConnectorButton)}
                    />
                )}
            </Grid>
            <Grid className={commonStyles.stepContainer}>
                <Grid
                    container
                    flexWrap="nowrap"
                    className={classNames(
                        classes.pendingStepsBtn,
                        isAddActionOrAddNote && classes.addActionButton,
                        !canCompleteTracking && commonStyles.opacity30Important,
                    )}
                >
                    <Grid item xs={12}>
                        <GLink to={stepLink}>
                            <GTooltip
                                contentDisabled={!canCompleteTracking}
                                title={!canCompleteTracking ? TOOLTIPS.DISABLED_FEATURE : ''}
                                contentDisabledContainerClass={classes.widthFitContent}
                            >
                                <Typography
                                    color="secondary"
                                    className={classNames(
                                        classes.fontSize18,
                                        (isAddAction || isAddMedallion) && classes.buttonTextFontsize,
                                        !canCompleteTracking && 'disabled',
                                    )}
                                    onClick={handleClick}
                                    {...getIntercomTargetProp(
                                        intercomTargetProp ? `${intercomTargetProp}-PendingStepLabel` : ``,
                                    )}
                                >
                                    {step.label}
                                </Typography>
                            </GTooltip>
                        </GLink>

                        <Grid container alignItems="center">
                            {!isAddActionOrAddNote && trackingStep && (
                                <AssignTask
                                    showAssignTaskLink
                                    zIndex={zIndex + 1}
                                    activeCase={selectedCase}
                                    task={trackingStep}
                                    intercomTargetProp={`TrackingPage-TrackTab-AssignTask`}
                                />
                            )}

                            {trackingStep && onDueDateClick && (trackingStep.complete_by_time || canEditDueDate )&&  (
                                <>
                                    <Box
                                        position="relative"
                                        height={22}
                                        px={1.5}
                                        sx={{
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 'calc(50% - 2px)',
                                                left: 'calc(50% - 2px)',
                                                width: 4,
                                                height: 4,
                                                bgcolor: 'secondary.main',
                                                borderRadius: '50%',
                                            },
                                        }}
                                    />

                                    <Grid item>
                                        <GTooltip title={dueDateTooltipString(trackingStep.complete_by_time)}>
                                            <Typography
                                                color="secondary"
                                                onClick={canEditDueDate ? () => onDueDateClick(trackingStep.id) 
                                                    : undefined}
                                            >
                                                {dueDateString?.startsWith(':') && (
                                                    <Typography component="span" color="error">
                                                        Overdue
                                                    </Typography>
                                                )}
                                                {dueDateString || (canEditDueDate ? '+ Due Date' : '')}
                                            </Typography>
                                        </GTooltip>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    {!isAddActionOrAddNote && onMenuClick && (
                        <IconButton
                            color="secondary"
                            className={commonStyles.moreVertIcon}
                            onClick={(evt) => onMenuClick(evt.currentTarget)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PendingStep;
