import {
    STREAM_DEVICES_LOADED,
    STREAM_DEVICES_LOADING,
    STREAM_DEVICES_LOAD_FAILED,
    ADD_STREAM_DEVICE,
    SET_STREAM_DEVICE,
    STREAMABLE_EVENTS_LOADED,
    STREAMABLE_EVENTS_LOADING,
    STREAMABLE_EVENTS_LOAD_FAILED,
    SET_ACTIVE_STREAM_REPORT,
    DELETE_STREAM_DEVICE,
    SET_STREAM_BILLING_REPORT,
    AVAILABLE_STREAM_DEVICES_LOADED,
} from '../actions/LiveStream.action';
import * as constants from '../constants';
import { GatherAction } from '../actions';
import { LiveStreamState } from '../types';
import { LOADED_PRIVATE_CASE, LOADED_PUBLIC_CASE } from '../actions/GatherCase.action';
import { UPDATE_EVENT } from '../actions/GatherEvent.action';
import {
    REMOVE_STREAM_ASSET,
    ADD_BACK_STREAM_ASSET,
    UPDATING_VIDEO_ASSET_RANKS,
    UPDATING_VIDEO_ASSET_RANKS_FAILED,
} from '../actions/video.action';

export const liveStreamInitData: LiveStreamState = {
    streamableEvents: [],
    isEventsLoading: false,
    streamDevices: [],
    availableStreamDevices: [],
    isDevicesLoading: false,
    activeStreamReport: [],
    liveStreamBillingReport: [],
};

export const liveStreamState = (
    state: LiveStreamState = liveStreamInitData,
    action: GatherAction,
): LiveStreamState => {
    switch (action.type) {
        case LOADED_PRIVATE_CASE: {
            if (state.streamableEvents.some((evt) => evt.gather_case_id !== action.gatherCase.id)) {
                // if there are no events OR events from a different case then reset the state
                return liveStreamInitData;
            } else {
                // don't clear out events unless necessary
                // this prevents a race condition if the loading of events beats the Remember page load
                return {
                    ...liveStreamInitData,
                    streamableEvents: state.streamableEvents,
                };
            }
        }
        case constants.USER_LOGGED_OUT:
            return liveStreamInitData;
        case STREAM_DEVICES_LOADED: {
            return {
                ...state,
                streamDevices: action.streamDevices,
                isDevicesLoading: false,
            };
        }
        case STREAM_DEVICES_LOADING: {
            return {
                ...state,
                isDevicesLoading: true,
            };
        }
        case STREAM_DEVICES_LOAD_FAILED: {
            return {
                ...state,
                isDevicesLoading: false,
            };
        }
        case AVAILABLE_STREAM_DEVICES_LOADED: {
            return {
                ...state,
                availableStreamDevices: action.availableStreamDevices,
                isDevicesLoading: false,
            };
        }
        case ADD_STREAM_DEVICE: {
            return {
                ...state,
                streamDevices: [...state.streamDevices, action.streamDevice],
            };
        }
        case SET_STREAM_DEVICE: {
            return {
                ...state,
                streamDevices: state.streamDevices.map((d) => d.id === action.deviceId ? action.streamDevice : d),
            };
        }
        case DELETE_STREAM_DEVICE: {
            return {
                ...state,
                streamDevices: state.streamDevices.filter((d) => d.id !== action.deviceId)
            };
        }
        case LOADED_PUBLIC_CASE: {
            return {
                ...state,
                streamableEvents: action.events,
            };
        }
        case STREAMABLE_EVENTS_LOADED: {
            return {
                ...state,
                streamableEvents: action.events,
                streamDevices: action.devices ? action.devices : state.streamDevices,
                isEventsLoading: false,
            };
        }
        case STREAMABLE_EVENTS_LOADING: {
            return {
                ...state,
                isEventsLoading: true,
            };
        }
        case STREAMABLE_EVENTS_LOAD_FAILED: {
            return {
                ...state,
                isEventsLoading: false,
            };
        }
        case SET_ACTIVE_STREAM_REPORT: {
            return {
                ...state,
                activeStreamReport: action.report,
            };
        }
        case SET_STREAM_BILLING_REPORT: {
            return {
                ...state,
                liveStreamBillingReport: action.report,
            };
        }
        case UPDATE_EVENT: {
            return {
                ...state,
                streamableEvents: state.streamableEvents.map((e) => {
                    if (e.id === action.eventId) {
                        return {
                            ...e,
                            ...action.event,
                        };
                    } else {
                        return e;
                    }
                }),
            };
        }
        case REMOVE_STREAM_ASSET: {
            return {
                ...state,
                streamableEvents: state.streamableEvents.map((evt) => {
                    if (evt.id === action.eventId) {
                        return {
                            ...evt,
                            playback_urls: {
                                ...evt.playback_urls,
                                replay: evt.playback_urls.replay.filter((sa) => sa.asset_id !== action.assetId),
                            },
                        };
                    } else {
                        return evt;
                    }
                })
            };
        }
        case ADD_BACK_STREAM_ASSET: {
            return {
                ...state,
                streamableEvents: state.streamableEvents.map((evt) => {
                    if (evt.id === action.eventId) {
                        return {
                            ...evt,
                            playback_urls: {
                                ...evt.playback_urls,
                                replay: [...evt.playback_urls.replay, action.asset],
                            },
                        };
                    } else {
                        return evt;
                    }
                })
            };
        }
        case UPDATING_VIDEO_ASSET_RANKS: {
            return {
                ...state,
                streamableEvents: state.streamableEvents.map((evt) => {
                    if (evt.id === action.eventId) {
                        return {
                            ...evt,
                            playback_urls: {
                                ...evt.playback_urls,
                                replay: action.optimisticAssets,
                            },
                        };
                    } else {
                        return evt;
                    }
                }),
            };
        }
        case UPDATING_VIDEO_ASSET_RANKS_FAILED: {
            return {
                ...state,
                streamableEvents: state.streamableEvents.map((evt) => {
                    if (evt.id === action.eventId) {
                        return {
                            ...evt,
                            playback_urls: {
                                ...evt.playback_urls,
                                replay: action.rollbackAssets,
                            },
                        };
                    } else {
                        return evt;
                    }
                }),
            };
        }
        default:
            return state;
    }
};
