import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import { TaskTemplateType, TaskUpdateRequestUX, CaseTaskUX } from '../../shared/types';
import { GatherCaseUX } from '../../shared/types/case';
import TextField from '@mui/material/TextField/TextField';
import { debounce } from 'lodash';
import { GLOBAL_STYLED_PROPS } from '../../styles';
import IconButton from '@mui/material/IconButton/IconButton';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import CloseIcon from '@mui/icons-material/Cancel';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withGStyles from '../../styles/WithGStyles';

interface Props {
    selectedCase: GatherCaseUX;
    taskType: CaseTaskUX | null;
    label: string;
    locked: boolean;
    snackbarMessage: string;
    updateTask: (
        taskId: number,
        taskChanges: TaskUpdateRequestUX,
        caseUuid: string,
        taskTemplate: TaskTemplateType | null
    ) => Promise<CaseTaskUX | null>;
    rootClass?: string;
}

interface State {
    isCustomSnackbarOpen: boolean;
    note: string;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        color: theme.palette.secondary.main,
        boxShadow: theme.shadows[5],
        margin: '24px 7px 0',
        maxWidth: 480,
        border: '1px solid',
        borderRadius: 4,
        padding: '4px 16px 16px',
        '& label': {
            maxWidth: 'calc(100vw - 72px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    inputMultilineClass: {
        minHeight: 120
    },
    snackbarContent: {
        minWidth: 'unset',
        '@media (max-width: 960px)': {
            flexGrow: 0
        },
    },
    displayTable: {
        display: 'table'
    }
});

type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'inputMultilineClass'
    | 'snackbarContent' | 'displayTable'>;

class TaskNoteTextField extends Component<Props & StyledProps, State> {

    state: State = {
        isCustomSnackbarOpen: false,
        note: this.props.taskType?.note ?? ''
    };

    protected debounceSyncTaskNote = debounce(this.props.updateTask, 500);

    componentDidUpdate(prevProps: Props) {
        const { taskType } = this.props;

        if (prevProps.taskType?.note !== taskType?.note) {
            this.setState({ note: this.props.taskType?.note ?? '' });
        }
    }

    componentWillUnmount() {
        this.debounceSyncTaskNote.flush();
    }

    toggleCustomSnackbar = (isCustomSnackbarOpen: boolean) => {
        this.setState({
            isCustomSnackbarOpen
        });
    };

    renderCustomSnackbar = () => {
        const { classes, snackbarMessage } = this.props;
        const { isCustomSnackbarOpen } = this.state;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{
                    root: classes.displayTable
                }}
                open={isCustomSnackbarOpen}
                autoHideDuration={6000}
                onClose={() => this.toggleCustomSnackbar(false)}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classNames(
                            classes.backgroundPrimary,
                            classes.snackbarContent
                        )
                    }
                }}
                message={<span id="message-id">{snackbarMessage}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => this.toggleCustomSnackbar(false)}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        );
    };

    render() {
        const { taskType, classes, label, locked, rootClass } = this.props;
        const { note } = this.state;

        if (!taskType) {
            return null;
        }

        return (
            <>
                <Grid container justifyContent="center">
                    <Grid
                        item
                        xs={12}
                        className={classNames(classes.root, rootClass)}
                    >
                        <TextField
                            fullWidth
                            multiline
                            InputProps={{
                                classes: {
                                    root: classes.inputMultilineClass,
                                    inputMultiline: classes.inputMultilineClass,
                                }
                            }}
                            label={label}
                            name="notes"
                            type="text"
                            value={note}
                            disabled={locked}
                            onChange={this.handleNoteChange}
                            onClick={e => this.toggleCustomSnackbar(locked)}
                        />
                    </Grid>
                </Grid>
                {this.renderCustomSnackbar()}
            </>
        );
    }

    handleNoteChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { taskType, selectedCase } = this.props;

        if (!taskType) {
            return;
        }

        // creates a copy of the value since react synthetic events are
        // reused and will have changed by the time debounce runs
        const note = JSON.parse(JSON.stringify(target.value));

        this.setState(
            { note },
            () => {
                const changes: TaskUpdateRequestUX = { note: this.state.note };
                this.debounceSyncTaskNote(
                    taskType.id,
                    changes,
                    selectedCase.uuid,
                    taskType.template_type
                );
            }
        );
    };
}

export default withGStyles(styles)(TaskNoteTextField);
