import { Component } from 'react';
import { times } from 'lodash';
import classNames from 'classnames';
const PDFJS = require('pdfjs-dist/legacy/build/pdf');

PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.min.js';

import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import DocPreviewCard, { PDFDocument } from './DocPreviewCard';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    fullPage: {
        margin: 0,
        width: '100%',
        display: 'flex',
        '@media (min-width: 960px)': {
            overflowX: 'hidden',
            height: 'calc(100vh - 64px)', // subtract the header's height
            width: '60%',
            minWidth: '60%',
        },
        '&$fullWidth': {
            width: '100%',
            height: '100%'
        },
        '& $inner': {
            display: 'flex',
            flexDirection: 'row',
            width: 'max-content',
            margin: '0 auto',
            overflowX: 'auto',
            '@media (min-width: 960px)': {
                flexDirection: 'column',
                overflowX: 'hidden',
            },
        },
    },
    thumbnailView: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginTop: 10,
        width: 'calc(100% - 50px)',
        display: 'flex',
        '& $inner': {
            display: 'flex',
            flexDirection: 'row',
            width: 'max-content',
            margin: 'auto',
            overflowX: 'auto'
        },
    },
    isStackedView: {
        width: 190,
        minWidth: 190,
        height: 238,
        margin: '24px auto 0',
        '@media (min-width: 960px)': {
            margin: '18px auto 36px',
        },
        '& $inner': {
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'block',
            zIndex: 0,
            transform: 'translateX(-12px)',
        }
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    fullWidth: {},
    docPreviewCardRoot: {},
    tabIndicator: {},
    inner: {}
});

type Props = {
    pdfS3URL: string;
    fullWidth?: boolean;
    thumbnailView?: boolean;
    isStackedView?: boolean;
    hidePageNumber?: boolean;
    scrollListener?: (element: HTMLDivElement) => void;
};

interface State {
    pdf: PDFDocument | null;
}

const getPDFfromUrl = (fileUrl: string): Promise<PDFDocument> => {
    return new Promise((resolve) => {
        const request = new XMLHttpRequest();
        request.open('GET', fileUrl, true);
        request.responseType = 'blob';
        request.onloadend = function () {
            const reader = new FileReader();
            reader.onloadend = async function () {
                const pdf = await PDFJS.getDocument(reader.result).promise;
                resolve(pdf);
            };
            reader.readAsArrayBuffer(request.response);
        };
        request.send();
    });
};

type StyledProps = Props & WithStyles<'root' | 'inner' | 'fullPage' | 'thumbnailView' | 'isStackedView'
    | 'loadingContainer' | 'fullWidth'>;

class DocPreview extends Component<StyledProps, State> {
    state: State = {
        pdf: null,
    };

    async componentDidMount() {

        const { pdfS3URL } = this.props;

        if (pdfS3URL) {
            const pdf = await getPDFfromUrl(pdfS3URL);
            this.setState({
                pdf,
            });
        }
    }

    renderPages = () => {
        const { classes, thumbnailView, isStackedView, hidePageNumber, fullWidth } = this.props;
        const { pdf } = this.state;

        if (!pdf) {
            return (
                <div className={classes.loadingContainer}>
                    <CircularProgress color="primary" />
                </div>
            );
        }

        return times(pdf.numPages).map((pageNum) =>
            <DocPreviewCard
                key={pageNum}
                pdf={pdf}
                totalPages={pdf.numPages}
                pageNumber={pageNum + 1}
                thumbnailView={thumbnailView}
                isStackedView={isStackedView}
                omitCanvasRendering={isStackedView && pageNum > 0}
                hidePageNumber={hidePageNumber}
                fullWidth={fullWidth}
            />
        );
    };

    render() {
        const {
            classes,
            thumbnailView,
            isStackedView,
            fullWidth,
            scrollListener
        } = this.props;
        const { pdf } = this.state;

        const tooltip = 'You will see a large preview of your documents, once the final version is generated.';

        return (
            <div
                className={classNames(
                    thumbnailView ? classes.thumbnailView : classes.fullPage,
                    isStackedView && classes.isStackedView,
                    fullWidth && classes.fullWidth
                )}
            >
                <Tooltip title={isStackedView && pdf ? tooltip : ''} placement="top">
                    <div
                        className={classes.inner}
                        onScroll={event => scrollListener && scrollListener(event.currentTarget)}
                    >
                        {this.renderPages()}
                    </div>
                </Tooltip>
            </div>
        );
    }
}

export default withStyles(styles)(DocPreview);
