import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import {
    joinNameParts,
    getIntercomTargetProp,
} from '../../../../../services';
import {
    TrackingStepCompleteRequest,
    isNoteConfig,
    isFinePrintConfig,
    isLocationConfig,
    isParagraphConfig,
    isSignatureConfig,
    S3FileCreateRequest,
    TaskComponentType,
    UserProfile,
    isTextQuestionsConfig,
    isFileUploadConfig,
    isChecklistQuestionsConfig,
    isBelongingsConfig,
    isFingerprintsConfig,
    isCaseConfirmationConfig,
    isWhoPerformedConfig,
    TaskComponentCaseUpdateRequest,
    TaskComponentQuestionAnswer,
    isMultipleChoiceConfig,
    getValidConfig,
    TaskUserType,
    TaskComponentUX,
    GatherCaseSummary,
    isAdditionalSignatureConfig,
    TrackingStepType,
    CaseTaskUX,
    TaskLocationUX,
} from '../../../../../shared/types';
import { useGDispatch } from '../../../../../types';
import PerformerComponent from './components/PerformerComponent';
import UploadDocsComponent from './components/UploadDocsComponent';
import CaseBelongingsComponent from './components/CaseBelongingsComponent';
import SelectLocation from '../../QRStepper/selectLocation/SelectLocation';
import { TrackingContentContext } from './CompleteStep.dialog';
import { completeTrackingStep } from '../../../../../actions/task/Task.action';
import AdditionalSignatureComponent from './components/additionalSignatureComponent/AdditionalSignatureComponent';
import { log } from '../../../../../logger';
import CaseConfirmationComponent from './components/caseConfirmationComponent/CaseConfirmationComponent';
import FingerprintsComponent from './components/FingerprintsComponent';
import SignatureComponent from './components/SignatureComponent';
import ChecklistQuestionsComponent from './components/ChecklistQuestionsComponent';
import TextQuestionsComponent from './components/TextQuestionsComponent';
import CompleteStepDialogFooter from './CompleteStep.dialogFooter';
import StepPrerequisites, { Prereq } from './StepPrerequisites';
import FinePrintComponent from './components/FinePrintComponent';
import ParagraphComponent from './components/ParagraphComponent';
import NoteComponent from './components/NoteComponent';
import { values } from 'lodash';
import { DUMMY_CASE, OnAnswerChangedFunction, OnValidityChangedFunction } from './components/types';
import MultipleChoiceComponent from './components/MultipleChoiceComponent';
import makeGStyles from '../../../../../styles/makeGStyles';
import classNames from 'classnames';
import { uploadSignatureFromRef } from '../../utils';
import FamilySharing, { FamilySharingSwitch } from '../../FamilySharing';
import { PrereqSummary } from
    '../../../../workflows/editWorkFlow/editSteps/savePrerequisitesDialog/SavePrerequisitesDialogContent';
import UpdateIcon from '@mui/icons-material/Update';
import { renewCaseWorkflow } from '../../../../../actions/GatherCase.action';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeGStyles(theme => ({
    paddingBottom8: {
        paddingBottom: 8
    },
    fyiText: {
        boxSizing: 'border-box',
        maxWidth: 'calc(100% - 24px)',
        margin: '28px auto 0',
        width: 'fit-content',
        background: theme.palette.primary.main,
        borderRadius: '12px 12px 0 0',
        padding: '8px',
        [theme.breakpoints.up(425)]: {
            padding: '8px 20px'
        }
    },
    orangeBtn: {
        boxSizing: 'border-box',
        maxWidth: 'calc(100% - 24px)',
        margin: 'auto',
        width: 'fit-content',
        background: '#ff9f06',
        color: '#fff',
        borderRadius: '12px 12px 0 0',
        padding: '8px',
        [theme.breakpoints.up(425)]: {
            padding: '8px 20px'
        },
        border: 0,
        textTransform: 'none',
        '&:hover': {
            background: '#ff9f06',
            border: 0,
        },
    },
    marginBottom32: {
        marginBottom: 32
    }
}), { name: 'CompleteStep' });

export interface CompleteStepCase extends GatherCaseSummary { }

export interface CompleteActiveStep {
    id: number;
    marked_complete_by: TaskUserType | null;
    marked_complete_time: Date | null;
    tracking_step_type: TrackingStepType | null;
    components: TaskComponentUX[];
    title: string;
    past_tense_title: string | null;
    visible_to_family: boolean;
    signature_url: string | null;
    additional_signature_url: string | null;
    performed_by: TaskUserType | null;
    task_location: CaseTaskUX['task_location'];
}

export interface Props {
    activeStep: CompleteActiveStep;
    user: UserProfile;
    selectedCase: CompleteStepCase | null; // null case is for Back Office preview
    zIndex: number;
    rootClass?: string;
    workflowName: string;
    prereqs: Prereq[];
    availablePrereqs: PrereqSummary[];
    fhName: string;
    onUpdatePrereqs: (prereqKeys: number[]) => void;
    onChangeActiveStep: (stepId: number) => void;
    closeDialog: () => void;
    overrideCompleteStepRequest?: (req: TrackingStepCompleteRequest) => Promise<void>;
    classActiveStep?: string;
}

interface ComponentAnswers extends Record<number, TaskComponentQuestionAnswer> { };

const CompleteStep = (props: Props) => {
    const {
        selectedCase,
        zIndex,
        user,
        activeStep,
        rootClass,
        workflowName,
        prereqs,
        availablePrereqs,
        fhName,
        onUpdatePrereqs,
        onChangeActiveStep,
        overrideCompleteStepRequest,
        classActiveStep,
    } = props;

    const classes = useStyles();

    const [saveAttempted, setSaveAttempted] = useState(false);
    const [hasSigned, setHasSigned] = useState(false);
    const [hasAdditionalSigned, setHasAdditionalSigned] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<TaskLocationUX | null>(null);
    const [performedBy, setPerformedBy] = useState<TaskUserType | null>(null);
    const [validComponents, setValidComponents] = useState<Record<number, boolean>>({});
    const [answers, setAnswers] = useState<ComponentAnswers>({});
    const [isVisibleToFamily, setIsVisibleToFamily] = useState<boolean>(false);
    const [isLocationVisibleToFamily, setIsLocationVisibleToFamily] = useState<boolean>(false);
    const [renewingWorkflow, setRenewingWorkflow] = useState<boolean>(false);

    useEffect(() => {
        if (activeStep.performed_by) {
            setPerformedBy(activeStep.performed_by);
        } else if (!activeStep.marked_complete_time) {
            setPerformedBy(user);
        } else {
            // if this is a completed step and we don't have a performer than assume no performer
            setPerformedBy(null);
        }
    }, [user, activeStep.performed_by, activeStep.marked_complete_time]);

    const isInitializeStep = activeStep.tracking_step_type === TrackingStepType.initialize;
    const isFinalizeStep = activeStep.tracking_step_type === TrackingStepType.finalize;

    const isLocationSelectedAndDifferent = selectedLocation !== null &&
        selectedCase?.current_task_location?.id !== selectedLocation.id;

    const familySharingSwitchForStep: FamilySharingSwitch = {
        name: activeStep.past_tense_title ?? activeStep.title,
        isVisible: isVisibleToFamily || isInitializeStep || isFinalizeStep,
        disabled: isInitializeStep || isFinalizeStep,
        setVisibility: setIsVisibleToFamily,
    };

    let familySharingSwitchForLocation: FamilySharingSwitch | undefined = undefined;
    if (isLocationSelectedAndDifferent && selectedLocation) {
        familySharingSwitchForLocation = {
            name: `Moved to ${selectedLocation.name}`,
            isVisible: isLocationVisibleToFamily,
            setVisibility: setIsLocationVisibleToFamily,
        };
    }

    const isCompleted = !!activeStep.marked_complete_by;
    const overridingDefaultFamilySharing = isVisibleToFamily && !activeStep.visible_to_family && !isCompleted;

    useEffect(() => {
        if (isCompleted) {
            setSelectedLocation(activeStep.task_location);
        }
    }, [isCompleted, activeStep.task_location]);

    const userFullName = joinNameParts(user);
    const dispatch = useGDispatch();

    const signatureCanvas = useRef<SignatureCanvas>(null);
    const additionalSignatureCanvas = useRef<SignatureCanvas>(null);

    const caseUuid = selectedCase?.uuid ?? null; // don't use DUMMY_CASE here
    const caseFname = (selectedCase || DUMMY_CASE).fname;

    useEffect(() => {
        if (isCompleted) {
            signatureCanvas.current?.off();
            additionalSignatureCanvas.current?.off();
        }
    }, [isCompleted]);

    useEffect(() => {
        setIsVisibleToFamily(activeStep.visible_to_family);
    }, [activeStep.visible_to_family]);

    const handleValidityChange = useCallback<OnValidityChangedFunction>((
        componentId: number,
        isValid: boolean,
    ) => {
        setValidComponents((prev) => ({
            ...prev,
            [componentId]: isValid,
        }));
    }, []);

    const handleAnswerChange = useCallback<OnAnswerChangedFunction>((
        componentId: number,
        answer: TaskComponentQuestionAnswer,
    ) => {
        setAnswers((prev) => ({
            ...prev,
            [componentId]: answer,
        }));
    }, []);

    const hasCompletedAllPrereqs = useMemo(
        () => prereqs.every((prereq) => prereq.marked_complete_time !== null),
        [prereqs],
    );

    const hasError = (signatureCanvas.current?.isEmpty())
        || !values<boolean>(validComponents).every((val) => val)
        || !hasCompletedAllPrereqs
        || !performedBy;

    const clearSignature = (canvas: SignatureCanvas | null, isAdditionalSignature?: boolean) => {
        canvas?.clear();
        canvas?.on();
        if (isAdditionalSignature) {
            setHasAdditionalSigned(false);
        } else {
            setHasSigned(false);
        }
    };

    const handleCompleteClick = async () => {
        if (isCompleted || !selectedCase) {
            props.closeDialog();
            return;
        }
        setSaveAttempted(true);

        if (hasError) {
            return;
        }

        setIsSaving(true);

        const signature: S3FileCreateRequest | null = await uploadSignatureFromRef({
            ref: signatureCanvas.current,
            dispatch,
            caseUuid: selectedCase.uuid,
        });
        if (!signature) {
            setIsSaving(false);
            return;
        }

        // handle additional signature -- if included
        const additionalSignature: S3FileCreateRequest | null = await uploadSignatureFromRef({
            ref: additionalSignatureCanvas.current,
            dispatch,
            caseUuid: selectedCase.uuid,
        });

        // convert answers for request
        let componentUpdates: TaskComponentCaseUpdateRequest[] = [];
        for (const componentId of Object.keys(answers)) {
            if (answers[componentId] !== undefined) {
                componentUpdates.push({
                    task_component_id: Number(componentId),
                    answer: answers[componentId],
                });
            }
        }

        const completeRequest: TrackingStepCompleteRequest = {
            signature,
            additional_signature: additionalSignature,
            performed_by: performedBy?.user_id,
            component_updates: componentUpdates,
            task_location_id: selectedLocation?.id ?? null,
            // initialize & finalize steps are always visible to family
            visible_to_family: isVisibleToFamily || isInitializeStep
                || isFinalizeStep,
            move_step_visible_to_family: isLocationSelectedAndDifferent ? isLocationVisibleToFamily : null,
        };

        if (overrideCompleteStepRequest) {
            await overrideCompleteStepRequest(completeRequest);
        } else {
            const result = await dispatch(completeTrackingStep({
                completeRequest,
                caseUuid: selectedCase.uuid,
                taskId: activeStep.id,
                trackingStepType: activeStep.tracking_step_type,
            }));
            if (result) {
                props.closeDialog();
            } else {
                setIsSaving(false);
            }
        }
    };

    const renewWorkflow = async () => {
        setRenewingWorkflow(true);
        if (selectedCase != null) {
            const result = await dispatch(renewCaseWorkflow({
                caseUuid: selectedCase.uuid,
            }));
            if (result) {
                props.closeDialog();
            }
        }
        setRenewingWorkflow(false);
    };

    return (
        <div className={rootClass}>
            {!isInitializeStep &&
                <StepPrerequisites
                    workflowName={workflowName}
                    zIndex={zIndex}
                    prereqs={prereqs}
                    availablePrereqs={availablePrereqs}
                    isStepCompleted={isCompleted}
                    hideWarningInDialog={true}
                    onPrereqClick={onChangeActiveStep}
                    onSave={onUpdatePrereqs}
                />
            }

            {isCompleted &&
                <ParagraphComponent
                    className={classNames(classes.marginTop24, classes.paddingBottom8)}
                    align="center"
                    text={`This step has been marked completed. For your protection, once a step is marked completed,
                        it can't be undone. The following shows what was specified at the time of completion.`}
                />
            }

            {activeStep.components.map((component) => {

                switch (component.type) {
                    case TaskComponentType.who_performed: {
                        const config = getValidConfig(isWhoPerformedConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }

                        return (
                            <PerformerComponent
                                key={component.id}
                                zIndex={zIndex + 1}
                                stepTitle={activeStep.title}
                                user={user}
                                performedBy={performedBy}
                                completedBy={activeStep.marked_complete_by}
                                completedTime={activeStep.marked_complete_time}
                                isDisabled={!Boolean(config?.allow_completion_by_someone_else)}
                                onAssignPerformer={setPerformedBy}
                            />
                        );
                    }
                    case TaskComponentType.case_confirmation: {
                        const config = getValidConfig(isCaseConfirmationConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                            return null;
                        }

                        return (
                            <CaseConfirmationComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                zIndex={zIndex}
                                heading="Case Confirmation"
                                saveAttempted={saveAttempted}
                                selectedCase={selectedCase}
                                onValidityChanged={handleValidityChange}
                            />
                        );
                    }
                    case TaskComponentType.location: {
                        const config = getValidConfig(isLocationConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                            return null;
                        }

                        return (
                            <SelectLocation
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                caseFName={caseFname}
                                context={TrackingContentContext.dialog}
                                currentLocation={selectedCase?.current_task_location ?? null}
                                selectedLocation={selectedLocation}
                                funeralHomeId={selectedCase?.funeral_home_id ?? null}
                                saveAttempted={saveAttempted}
                                onTaskLocationSelect={location => setSelectedLocation(location)}
                                onAnswerChanged={handleAnswerChange}
                                onValidityChanged={handleValidityChange}
                                caseUuid={caseUuid}
                                intercomTargetProp={`CompleteKeepTrackStep-SelectLocation`}
                            />
                        );
                    }
                    case TaskComponentType.fingerprint: {
                        const config = getValidConfig(isFingerprintsConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <FingerprintsComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                                zIndex={zIndex}
                                caseUuid={caseUuid}
                                caseFname={caseFname}
                            />
                        );
                    }
                    case TaskComponentType.personal_belongings: {
                        const config = getValidConfig(isBelongingsConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }

                        return (
                            <CaseBelongingsComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                zIndex={zIndex}
                                caseFname={caseFname}
                                caseUuid={caseUuid}
                                userFullName={joinNameParts(user)}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                            />
                        );
                    }
                    case TaskComponentType.file_upload: {
                        const config = getValidConfig(isFileUploadConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <UploadDocsComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                caseUuid={caseUuid}
                                userId={user.user_id}
                                zIndex={zIndex + 1}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                            />
                        );
                    }
                    case TaskComponentType.additional_signature: {
                        const config = getValidConfig(isAdditionalSignatureConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <AdditionalSignatureComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                canvasRef={additionalSignatureCanvas}
                                additionalSignatureUrl={activeStep.additional_signature_url}
                                onStopDrawing={() => setHasAdditionalSigned(true)}
                                clearSignature={() => clearSignature(additionalSignatureCanvas.current, true)}
                                saveAttempted={saveAttempted}
                                hasSigned={hasAdditionalSigned}
                                onValidityChanged={handleValidityChange}
                                onAnswerChanged={handleAnswerChange}
                            />
                        );
                    }
                    case TaskComponentType.signature: {
                        const config = getValidConfig(isSignatureConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <SignatureComponent
                                signatureUrl={activeStep.signature_url}
                                key={component.id}
                                heading={config?.verification_text}
                                signerFullName={
                                    isCompleted ? joinNameParts(activeStep.marked_complete_by ?? {}) : userFullName}
                                canvasRef={signatureCanvas}
                                onStopDrawing={() => setHasSigned(true)}
                                clearSignature={() => clearSignature(signatureCanvas.current)}
                                hasSignError={saveAttempted && !hasSigned}
                                hasSigned={hasSigned}
                                signerStateLicenceNumber={user.state_license_number || undefined}
                                intercomTargetProp={`CompleteKeepTrackStep-SignatureComponent`}
                                intercomTargetPropClearSignature={`CompleteKeepTrackStep-ClearSignature`}
                            />
                        );
                    }
                    case TaskComponentType.note: {
                        const config = getValidConfig(isNoteConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <NoteComponent
                                isDisabled={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                                onAnswerChanged={handleAnswerChange}
                                intercomTargetProp={`CompleteKeepStep-RemovalNotes`}
                            />
                        );
                    }
                    case TaskComponentType.checklist_questions: {
                        const config = getValidConfig(isChecklistQuestionsConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <ChecklistQuestionsComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                                onAnswerChanged={handleAnswerChange}
                            />
                        );
                    }
                    case TaskComponentType.text_questions: {
                        const config = getValidConfig(isTextQuestionsConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <TextQuestionsComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                                onAnswerChanged={handleAnswerChange}
                            />
                        );
                    }
                    case TaskComponentType.fine_print: {
                        const config = getValidConfig(isFinePrintConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <FinePrintComponent
                                key={component.id}
                                finePrint={config?.text}
                            />
                        );
                    }
                    case TaskComponentType.paragraph: {
                        const config = getValidConfig(isParagraphConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <ParagraphComponent
                                key={component.id}
                                text={config?.text}
                                intercomTargetProp={`CompleteKeepTrackStep-OnceYouSignParagraph`}
                            />
                        );
                    }
                    case TaskComponentType.multiple_choice_question: {
                        const config = getValidConfig(isMultipleChoiceConfig, component);
                        if (!config) {
                            log.warn('Invalid config', { component, activeStep });
                        }
                        return (
                            <MultipleChoiceComponent
                                isStepCompleted={isCompleted}
                                key={component.id}
                                componentId={component.id}
                                config={config}
                                saveAttempted={saveAttempted}
                                onValidityChanged={handleValidityChange}
                                onAnswerChanged={handleAnswerChange}
                            />
                        );
                    }
                    case TaskComponentType.family_sharing: {
                        return (
                            <FamilySharing
                                zIndex={zIndex + 1}
                                fhName={fhName}
                                key={component.id}
                                switchOne={isInitializeStep
                                    ? familySharingSwitchForLocation
                                    : familySharingSwitchForStep
                                }
                                switchTwo={isInitializeStep
                                    ? familySharingSwitchForStep
                                    : familySharingSwitchForLocation
                                }
                                isDisabled={isCompleted || !selectedCase}
                                intercomTargetPropComponent={`CompleteKeepTrackStep-FamilySharing`}
                                intercomTargetPropToggle={`CompleteKeepTrackStep-FamilySharingToggle`}
                                intercomTargetPropFooter={`CompleteKeepTrackStep-FamilySharingFooter`}
                            />
                        );
                    }
                    default: {
                        log.warn('No component for component type', { component, activeStep });
                        return null;
                    }
                }
            })}

            <CompleteStepDialogFooter
                user={user}
                performedBy={performedBy}
                stepTitle={activeStep.title}
                isSaving={isSaving}
                hasError={hasError && saveAttempted}
                completedBy={activeStep.marked_complete_by}
                isStepSharedWithFamily={overridingDefaultFamilySharing}
                onCompleteTaskClick={handleCompleteClick}
                rootClass={classes.marginBottom32}
                intercomTargetProp={`CompleteKeepTrackStep-CompleteStepButton`}
                intercomTargetPropVisibility={`CompleteKeepTrackStep-Visibility`}
                intercomTargetPropMarkedComplete={`CompleteKeepTrackStep-MarkedCompleteBy`}
                intercomTargetPropPerformedBy={`CompleteKeepTrackStep-PerformedBy`}
                intercomTargetPropAllStepsCustomizable={`CompleteKeepTrackStep-AllStepsCustomizable`}
            />

            <Tooltip
                title="To give you more control and predictability, new adjustments to the details of a Workflow
                    are designed to only apply when first switching to that WorkFlow. So if you make changes to a
                    WorkFlow after it has been applied to a case, you won't see those changes reflected on that
                    case until you click this button."
                placement="top"
                enterDelay={600}
            >
                <Button
                    variant="outlined"
                    className={classNames(classes.flexCentred, classes.orangeBtn,
                        !renewingWorkflow ? classes.cursorPointer : undefined,
                        classActiveStep ? classActiveStep : undefined
                    )}
                    onClick={!renewingWorkflow ? renewWorkflow : undefined}
                    {...getIntercomTargetProp(`CompleteKeepTrackStep-UpdateStepButton`)}
                >
                    <UpdateIcon />&nbsp;&nbsp;&nbsp;
                    <span>
                        Did you update this Step recently?
                        Click to see the new one
                    </span>
                </Button>
            </Tooltip>
        </div>
    );
};

export default CompleteStep;