import classNames from 'classnames';

import ConfigurableField, { mapStateToProps } from '../ConfigurableField';
import DCTextFieldWithCheckbox, { Props as DCTextFieldWithCheckboxProps } from './DCTextFieldWithCheckbox';
import HoverListener from '../../../../../common/HoverListener';
import { GLOBAL_STYLED_PROPS } from '../../../../../../styles';
import { ORANGE_COLOR } from '../../../../../../constants/colorVariables';
import withGStyles from '../../../../../../styles/WithGStyles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withState from '../../../../../common/utilHOC/WithState';
import { ConfigurableFieldKey } from '../../../../../../shared/death_certificate/validators/config';
import { AppDispatch } from '../../../../../../store';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        backgroundColor: `${ORANGE_COLOR}4C`,
        borderRadius: '5px 5px 0px 0px',
    },
});

type StyleProps = GLOBAL_STYLED_PROPS & WithStyles<'root'>;

interface Props extends Omit<DCTextFieldWithCheckboxProps, 'required' | 'id'> {
    id: ConfigurableFieldKey;
    dispatch: AppDispatch;
    isEditMode: boolean;
}

type CombinedProps = Props & StyleProps & ReturnType<typeof mapStateToProps>;

class ConfigurableTextFieldWithCheckbox extends ConfigurableField<string, CombinedProps> {

    render() {
        const {
            isEditMode,
            classes,
            rootClass,
            labelClass,
            textFieldClass,
            isChecked,
            ...otherProps
        } = this.props;
        const { isVisible, isRequired, isEnabled, isHiddenFromFamily } = this.state;

        if (!isVisible) {
            return null;
        }

        return (
            <HoverListener
                onHoverEnter={this.openPopper}
                onHoverLeave={this.closePopper}
                enterDelayMs={300}
                leaveDelayMs={500}
            >
                <DCTextFieldWithCheckbox
                    {...otherProps}
                    isHiddenFromFamily={isHiddenFromFamily}
                    isChecked={isChecked}
                    required={isChecked ? false : isRequired}
                    labelClass={classNames(labelClass, !isEnabled && classes.strikeThrough)}
                    textFieldClass={classNames(textFieldClass, !isEnabled && classes.strikeThrough)}
                    rootClass={classNames(rootClass, isEditMode && classes.root)}
                />
                {this.renderPopper()}
            </HoverListener>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(ConfigurableTextFieldWithCheckbox));
