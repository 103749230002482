import * as React from 'react';
import classNames from 'classnames';

import {
    getPhotoUrl,
    DEFAULT_WIDTH,
    DEFAULT_HEIGHT,
    DEFAULT_INNER_ALBUM_MARGIN,
} from '../../../services';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import LockIcon from '@mui/icons-material/Lock';

import { AlbumPhoto as AlbumPhotoType } from '../../../types';

import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { CloudinaryTransformationsType } from '../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../styles/WithGStyles';

const innerWidth = DEFAULT_WIDTH - DEFAULT_INNER_ALBUM_MARGIN;
const innerHeight = DEFAULT_HEIGHT - DEFAULT_INNER_ALBUM_MARGIN;

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    blurPhoto: {
        position: 'absolute',
        top: 0,
        left: 0,
        filter: 'blur(4px)',
        opacity: 0.7,
        width: '100%',
        height: '100%',
        zIndex: 1,
        borderRadius: 6,
    },
    photoWrapper: {
        position: 'relative',
        zIndex: 4,
        filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
    },
    mainPhoto: {
        border: '1px solid #FBFBFB',
        maxWidth: innerWidth - 8,
        maxHeight: innerHeight,
        borderRadius: 2,
        boxSizing: 'border-box',
        zIndex: 2,
        position: 'relative',
        cursor: 'pointer !important'
    },
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT,
        position: 'relative',
        verticalAlign: 'top',
        textAlign: 'center',
        flexWrap: 'wrap',
        borderRadius: 6,
        margin: '0 auto',
        transition: 'transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '0 8px 8px',
    },
    loaderContainer: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        margin: 0,
    },
    transformMe: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        transition: 'transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        transform: 'scale(1)'
    },
    dragHandleIcon: {
        position: 'absolute',
        top: -24,
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        zIndex: 3,
        fontSize: 42
    },
    circle: {
        position: 'absolute',
        top: -25,
        left: '50%',
        transform: 'translateX(-50%) scale(1)',
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: 'white',
        filter: 'blur(4px)',
        zIndex: 2,
    },
    dragHandle: {
        position: 'absolute',
        top: 4,
        left: 8,
        color: theme.palette.common.white,
        zIndex: 5
    },
    editPhotoButton: {
        minWidth: 80,
        padding: 0,
        position: 'absolute',
        bottom: '50%',
        opacity: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        maxWidth: 'calc(100% - 32px)',
        fontSize: 14,
        whiteSpace: 'nowrap',
        minHeight: 24,
        borderRadius: '0px 0px 6px 6px',
        maxHeight: 24,
        backgroundColor: theme.palette.common.white,
        textTransform: 'capitalize',
        border: 'none',
        transition: 'all 400ms cubic-bezier(0.4, 0, 0.2, 1) 400ms !important',
        zIndex: 1,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            border: 'none',
        },
        '& svg': {
            fontSize: 18
        }
    },
    transformMePhoto: {
        transition: 'transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        width: '100%',
        '&:hover': {
            '& $editPhotoButton': {
                bottom: '-20px !important',
                opacity: 1,
            },
            transform: 'scale(1.05)',
        }
    },
    buttonPosition: {
        bottom: '-20px !important',
        opacity: 1,
    },
    scale1_05: {
        transform: 'scale(1.05)'
    },
    lockIcon: {
        position: 'absolute',
        top: -24,
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        zIndex: 3,
        fontSize: 28
    },
    editPhotoLabel: {}
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'mainPhoto' | 'blurPhoto' | 'imageBox'
    | 'loadingText' | 'loaderContainer' | 'downloadIcon' | 'transformMe' | 'photoWrapper' | 'dragHandle' | 'scale1_05'
    | 'circle' | 'dragHandleIcon' | 'editPhotoButton' | 'transformMePhoto' | 'buttonPosition' | 'editPhotoLabel'
    | 'lockIcon'>;

interface Props {
    photo: AlbumPhotoType;
    index: number;
    isOpenPhotoPopper: boolean;
    isLocked: boolean;
    activePhotoId: string | null;
    setPhotoCircleRef: (ref: HTMLDivElement | null) => void;
    setPhotoNumberRef: (ref: HTMLDivElement | null) => void;
    setEditButtonRef: (ref: HTMLDivElement | null) => void;
    setPhotoRef: (ref: HTMLDivElement | null) => void;
    openPopper: (event: React.MouseEvent<HTMLElement>, photoId: string, index: number) => void;
}

const AlbumPhoto = (props: StyledProps) => {
    const {
        classes,
        photo,
        isOpenPhotoPopper,
        activePhotoId,
        setPhotoCircleRef,
        setPhotoRef,
        openPopper,
        index,
        isLocked,
        setPhotoNumberRef
    } = props;

    if (!photo.photo) {
        return (<div
            className={classNames(
                classes.imageBox,
                classes.cursorPointer,
            )}
        />);
    }

    const mainImageTrans: CloudinaryTransformationsType[] = [{
        width: innerWidth,
        height: innerHeight,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'fit'
    }];

    const blurImageTrans: CloudinaryTransformationsType[] = [{
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'fill'
    }];

    if (photo.photo.transformations && photo.photo.transformations.cloudinary) {
        mainImageTrans.unshift(photo.photo.transformations.cloudinary);
        blurImageTrans.unshift(photo.photo.transformations.cloudinary);
    }

    const id = photo.gatherId;

    // we use a second image for the blur as it makes applying
    return (
        <div
            className={classNames(
                classes.transformMePhoto,
                isOpenPhotoPopper && activePhotoId === id && classes.scale1_05
            )}
        >
            <div
                className={classes.imageBox}
                ref={ref => setPhotoRef(ref)}
            >
                {photo.photo &&
                    <div
                        className={classes.transformMe}
                        id={id}
                    >
                        <div id={id}>
                            <div ref={ref => setPhotoCircleRef(ref)} className={classes.circle} id={id} />
                            {!isLocked
                                && <DragHandleIcon color="primary" className={classes.dragHandleIcon} />
                                || <LockIcon color="primary" className={classes.lockIcon} />
                            }
                        </div>
                        <img
                            src={getPhotoUrl(photo.photo.public_id, blurImageTrans)}
                            className={classes.blurPhoto}
                            id={id}
                        />
                        <div className={classes.photoWrapper} id={id}>
                            <div
                                ref={ref => setPhotoNumberRef(ref)}
                                className={classes.dragHandle}
                                id={id}
                            >
                                {index}
                            </div>
                            <img
                                src={getPhotoUrl(photo.photo.public_id, mainImageTrans)}
                                className={classes.mainPhoto}
                                id={`mainPhoto-${id}`}
                                onClick={(e) => openPopper(e, id, index)}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default withGStyles(styles)(AlbumPhoto);
