import * as React from 'react';
import classNames from 'classnames';
import Dinero from 'dinero.js';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DevicesIcon from '@mui/icons-material/Devices';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';

import { compose } from 'redux';

import { Payer, calculateProcessingFeesInCents, calculateAmountInCents, PaymentFieldToolTip } from '.';
import { PaymentRequest, GatherCaseUX, PaymentMethod } from '../../../../shared/types';
import { StoreState, FinanceState } from '../../../../types';
import {
    connectStripeReader,
    collectStripeTerminalPayment,
    STRIPE_TERMINAL,
    STRIPE_TERMINAL_STATE,
    initializeStripeTerminal,
    setReaderDisplay,
    clearReaderDisplay
} from '../../../../actions/Finance.action';
import { toPennyValue, formatDinero } from '../../../../shared/goods_and_services/pricing';
import { getFormattedPhoneNumber } from '../../../../services';
import CardReaderSVG from './svg/CardReaderSVG';
import CardReaderWithCardSVG from './svg/CardReaderWithCardSVG';
import TerminalSVG from './svg/TerminalSVG';
import { joinNameParts } from '../../../../shared/utils';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import { SlideTransition } from '../../../common/Transitions';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { Tooltip } from '@mui/material';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    dRoot: {},
    dialogPaper: {
        width: '100%',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            borderRadius: 4,
            maxHeight: 'calc(100% - 96px)',
            maxWidth: 520,
            minHeight: 560,
            height: 'auto',
            width: 520,
        }
    },
    dialogContent: {
        overflowX: 'hidden',
        height: 'calc(100% - 50px)', // footer button height
    },
    footerButton: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        '& button': {
            minWidth: '100%',
            borderRadius: 0,
            textTransform: 'capitalize',
            fontWeight: '200',
            fontSize: 22,
            padding: 12,
            height: 80,
            '@media (min-width: 600px)': {
                height: 'auto'
            }
        },
        '&$extendedFooter': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& button': {
                minWidth: '50%',
            },
        },
    },
    errorText: {
        fontSize: 22,
        lineHeight: '18px',
        fontWeight: 200,
    },
    cardReaderType: {
        textAlign: 'center',
        '& p': {
            fontWeight: 200,
            color: theme.palette.primary.main,
            marginTop: 36,
        },
        '& button': {
            display: 'flex',
            margin: '12px auto',
            '& svg': {
                width: 24,
            }
        }
    },
    bufferLineCont: {
        width: 'calc(100% - 32px)',
        margin: 'auto',
        position: 'relative',
        paddingTop: 18,
        marginBottom: 16,
        '& span': {
            position: 'absolute',
            top: 0,
            fontSize: 36,
            left: 0,
            color: theme.palette.primary.main,
        },
        '& $bufferLine': {
            width: 'calc(100% - 60px)',
            margin: 'auto',
            marginLeft: 36,
            [theme.breakpoints.up('sm')]: {
                width: 376,
                marginLeft: 35,
            }
        },
        [theme.breakpoints.up('sm')]: {
            width: 436,
        },
        '& $solidLine': {
            height: 2,
            backgroundColor: theme.palette.primary.main,
        },
        '& svg': {
            width: 36,
            position: 'absolute',
            top: 0,
            right: 0,
        }
    },
    checkIcon: {
        left: 'calc(50% - 18px) !important',
        background: theme.palette.common.white,
        zIndex: 1,
    },
    progressCircleCont: {
        position: 'relative',
        margin: '56px auto 8px',
        '& >svg': {
            width: 160,
            position: 'absolute',
            left: 'calc(50% - 80px)',
            top: 32,
        }
    },
    textField: {
        width: 200,
        margin: '8px 0',
        color: theme.palette.secondary.main,
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
        }
    },
    multiLineTextField: {
        marginTop: 20,
        width: 280,
        '& textarea': {
            minHeight: 43, // calculated as per the padding in mui
            maxHeight: 300
        },
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 12,
        '& button': {
            '& svg': {
                width: 24,
            }
        }
    },
    totalAmount: {
        color: theme.palette.primary.main,
        borderTop: '1px solid',
        width: 120,
        margin: '8px auto',
        textAlign: 'center',
        padding: '8px 20px',
        fontSize: 18,
    },
    previewReceipt: {
        width: 340,
        margin: '28px auto',
        textAlign: 'center',
        padding: '16px 16px 8px',
        borderRadius: 12,
        '& p': {
            color: theme.palette.primary.main,
            fontSize: 20,
            borderBottom: '1px solid',
        },
        '& div': {
            margin: '16px auto',
            '& p': {
                borderBottom: 'none',
                fontSize: 16,
                lineHeight: '20px',
                '& span': {
                    fontWeight: 200,
                }
            },
        },
        '& $memoText': {
            borderBottom: 'none',
            fontSize: 16,
            fontWeight: 200,
            marginTop: 8,
            lineHeight: '20px',
        },
    },
    clickableText: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    trnasformY180: {
        transform: 'rotateY(180deg)',
        '-ms-transform': 'rotateY(180deg)',
        '-webkit-transform': 'rotateY(180deg)',
    },
    padding_0_8: {
        padding: '0 8px'
    },
    cardReaderContainer: {
        textAlign: 'center',
        '& svg': {
            width: 160,
            margin: '80px auto 28px',
        }
    },
    deviceIcon: {
        left: '0px !important'
    },
    extendedFooter: {},
    bufferLine: {},
    solidLine: {},
    memoText: {},
    textAreaRoot: {},
    progress: {},
    cardReaderDialog: {},
});

interface Props {
    isDialogOpen: boolean;
    financeState: FinanceState;
    paymentInfo: PaymentRequest;
    stripeLocation: string | null;
    payer: Payer;
    activeCase: GatherCaseUX;
    closeDialog: (activeReceipt: number | null) => void;
    updatePayment: (paymentInfo: PaymentRequest) => void;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    openMySettingsDialog: () => void;
    zIndex: number;
    dispatch: AppDispatch;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    saveClicked: boolean;
    isAmountValid: boolean;
    isMerchFeeValid: boolean;
    isTotalValid: boolean;
    amount: string;
    merchFee: string;
    total: string;
    memo: string;
}

type StyledProps = Props & WithGStyles<'root' | 'cardReaderDialog' | 'dialogPaper' | 'dialogContent' | 'dRoot'
    | 'progress' | 'footerButton' | 'errorText' | 'cardReaderType' | 'bufferLineCont' | 'bufferLine' | 'solidLine'
    | 'checkIcon' | 'progressCircleCont' | 'textField' | 'multiLineTextField' | 'bottomContent' | 'totalAmount'
    | 'background_primary_0_0_12' | 'previewReceipt' | 'memoText' | 'clickableText' | 'transformY180'
    | 'textAreaRoot' | 'padding_0_8' | 'cardReaderContainer' | 'extendedFooter' | 'deviceIcon'>;

class CardReaderDialog extends React.Component<StyledProps & DialogProps, State> {

    constructor(props: StyledProps & DialogProps) {
        super(props);
        const { paymentInfo } = this.props;
        const { amount, merch_fee } = paymentInfo;
        const total = amount.add(merch_fee);
        this.state = {
            saveClicked: false,
            isAmountValid: true,
            isMerchFeeValid: true,
            isTotalValid: true,
            amount: (amount.getAmount() / 100.0).toFixed(2),
            merchFee: (merch_fee.getAmount() / 100.0).toFixed(2),
            total: (total.getAmount() / 100.0).toFixed(2),
            memo: paymentInfo.memo,
        };
    }

    closeDialog = (activeReceipt: number | null) => {
        clearReaderDisplay();
        // Reset the state...
        this.setState({ saveClicked: false });
        // Tell the parent to close this dialog
        this.props.closeDialog(activeReceipt);
    };
    calculateMerchFee(newAmount: Dinero.Dinero) {
        const { financeState } = this.props;
        const { feeSchedule } = financeState.transactions;
        return Dinero({
            amount: calculateProcessingFeesInCents(newAmount.getAmount(), feeSchedule, PaymentMethod.card),
            currency: 'USD'
        });
    }

    onChangeAmount(amount: string) {
        const newAmount = toPennyValue(amount);
        const newFee = this.calculateMerchFee(newAmount);
        const newTotal = newAmount.add(newFee);
        this.setState({
            amount,
            merchFee: (newFee.getAmount() / 100.0).toFixed(2),
            total: (newTotal.getAmount() / 100.0).toFixed(2),
        });
    }

    calculateAmount(newTotal: Dinero.Dinero) {
        const { financeState } = this.props;
        const { feeSchedule } = financeState.transactions;
        return Dinero({
            amount: calculateAmountInCents(newTotal.getAmount(), feeSchedule, PaymentMethod.card),
            currency: 'USD'
        });
    }

    onChangeTotal(total: string) {
        const newTotal = toPennyValue(total);
        const newAmount = this.calculateAmount(newTotal);
        const newFee = this.calculateMerchFee(newAmount);
        this.setState({
            amount: (newAmount.getAmount() / 100.0).toFixed(2),
            merchFee: (newFee.getAmount() / 100.0).toFixed(2),
            total,
        });
    }

    onChangeProcessingFee(merchFee: string) {
        const newFee = toPennyValue(merchFee);
        const amount = toPennyValue(this.state.amount);
        const newTotal = amount.add(newFee);
        this.setState({
            merchFee,
            total: (newTotal.getAmount() / 100.0).toFixed(2),
        });
    }

    onChangeMemo(memo: string) {
        this.setState({ memo });
    }
    // This pops up the confirmation screen in the app and on the reader
    showConfirmationScreen() {
        const { activeCase } = this.props;
        const { amount, merchFee, total } = this.state;

        const newAmount = toPennyValue(amount);
        if (newAmount.isZero() || newAmount.isNegative()) {
            this.setState({ isAmountValid: false });
            return;
        }
        const newMerchFee = toPennyValue(merchFee);
        if (newMerchFee.isNegative()) {
            this.setState({ isMerchFeeValid: false });
            return;
        }
        const newTotal = toPennyValue(total);
        if (newTotal.isZero() || newTotal.isNegative()) {
            this.setState({ isTotalValid: false });
            return;
        }

        const lineItems = [{
            description: `${activeCase.fname} ${activeCase.lname}`,
            amount: newAmount.getAmount(),
            quantity: 1
        }];
        if (newMerchFee.isPositive()) {
            lineItems.push({
                description: 'Processing Fee',
                amount: newMerchFee.getAmount(),
                quantity: 1
            });
        }
        const readerDisplay: STRIPE_TERMINAL.CartDetails = {
            type: 'cart',
            cart: {
                line_items: lineItems,
                total: newAmount.add(newMerchFee).getAmount(),
                currency: 'usd'
            }
        };
        setReaderDisplay(readerDisplay);
        this.setState({ saveClicked: true });
    }

    submitPayment() {
        const { paymentInfo, dispatch } = this.props;
        const { amount, merchFee, memo } = this.state;
        const newAmount = toPennyValue(amount);
        const newMerchFee = toPennyValue(merchFee);
        const newInfo = {
            ...paymentInfo,
            amount: newAmount,
            merch_fee: newMerchFee,
            memo
        };
        dispatch(collectStripeTerminalPayment(newInfo));
        this.props.updatePayment(newInfo);
    }

    renderTopStatusBar = (isConnected: boolean, isBufferLine: boolean, reader: STRIPE_TERMINAL.Reader | null) => {
        const { classes } = this.props;

        const statusText = isConnected ? 'Connected' : 'Connecting';
        return (
            <Grid xs={12} item className={classes.textCenter}>
                <Grid className={classes.bufferLineCont}>
                    <DevicesIcon className={classes.deviceIcon} />
                    {isConnected && <CheckCircleIcon className={classes.checkIcon} />}
                    {isBufferLine ?
                        <LinearProgress
                            className={classNames(
                                classes.bufferLine,
                                // TODO activeState !== 'state9' && classes.transformY180
                            )}
                            variant="buffer"
                            value={0}
                            valueBuffer={0}
                            color="primary"
                        />
                        : <div className={classNames(classes.bufferLine, classes.solidLine)} />
                    }
                    <TerminalSVG themeFillClass={classes.fillPrimary} />
                </Grid>
                <Typography color="primary" className={classes.fontWeight200}>
                    {statusText} to {reader ? reader.label : 'NO READER'}
                </Typography>
            </Grid>
        );
    };

    renderInitializing = () => {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid xs={12} item className={classes.textCenter}>
                    <FuneralHomeLogo />
                </Grid>
                <Grid xs={12} item className={classes.textCenter}>
                    <div className={classes.progressCircleCont}>
                        <CircularProgress size={240} thickness={0.5} color="primary" />
                        <CardReaderSVG themeFillClass={classes.fillPrimary} />
                    </div>
                    <Typography color="primary" className={classes.fontWeight200}>
                        Establishing a secure connection...
                    </Typography>
                </Grid>

                <Grid item className={classes.footerButton}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.closeDialog(null)}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderConnectionError = (
        title: string,
        message: string,
        buttonLabel: string,
        onClick: () => void,
    ) => {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid xs={12} item className={classes.textCenter}>
                    <FuneralHomeLogo />
                </Grid>
                <Grid
                    xs={12}
                    item
                    className={classNames(
                        classes.cardReaderContainer,
                        classes.padding_0_8
                    )}
                >
                    <CardReaderWithCardSVG themeFillClass={classes.fillPrimary} />
                    <Typography className={classNames(classes.colorRed, classes.errorText)}>
                        {title}
                    </Typography>
                    <Typography className={classNames(classes.fontWeight200, classes.colorRed, classes.fontSize14)}>
                        {message}
                    </Typography>
                </Grid>

                <Grid item className={classNames(classes.footerButton, classes.extendedFooter)}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.closeDialog(null)}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onClick}
                    >
                        <RefreshIcon />&nbsp;{buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderReaderSelector = () => {
        const { classes, financeState, dispatch } = this.props;
        const { discoveredReaders } = financeState.terminalDiscoverResults;

        // TODO: show an error screen if no readers are available

        return (
            <Grid container>
                <Grid xs={12} item className={classes.cardReaderType}>
                    <Typography>
                        Select your card reader below
                    </Typography>
                    {discoveredReaders.map(reader => (
                        <Button
                            key={reader.id}
                            color="primary"
                            variant="outlined"
                            onClick={() => dispatch(connectStripeReader(reader))}
                        >
                            <TerminalSVG themeFillClass={classes.fillPrimary} />
                            &nbsp;{reader.label}
                        </Button>
                    ))}
                </Grid>

                <Grid item className={classes.footerButton}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.closeDialog(null)}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderPaymentScreen = () => {
        const { financeState, paymentInfo, payer } = this.props;
        const { terminalPaymentState } = financeState;
        const { saveClicked } = this.state;
        const payerName = payer.user ? payer.payerEntity.fname : joinNameParts(payer.payerEntity);

        switch (terminalPaymentState) {
            case 'NOT_STARTED':
                if (saveClicked) {
                    return this.renderConfirmationScreen();
                } else {
                    return this.renderPaymentInputScreen();
                }
            case 'CAPTURED':
                this.closeDialog(paymentInfo.payment_id || null);
                return null;
            case 'FETCHING_PAYMENT_INTENT':
                return this.renderCollectingScreen('Sending Payment to Card Reader...');
            case 'COLLECTING_PAYMENT':
                return this.renderCollectingScreen(`You can now swipe ${payerName}'s card`);
            case 'PROCESSING_PAYMENT':
            case 'COLLECTED':
            case 'CAPTURING_PAYMENT':
                return this.renderCollectingScreen('Processing secure payment');
            case 'CAPTURE_FAILED':
            case 'COLLECTION_FAILED':
            case 'INTENT_FAILED':
            case 'PROCESSING_FAILED':
                return this.renderConnectionError(
                    'Payment failed',
                    financeState.errorMessage || '',
                    'Exit',
                    () => this.closeDialog(null)
                );
            default:
                return this.renderConnectionError(
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `Unrecognized payment state '${terminalPaymentState}'`,
                    'If this error message does not go away within a few seconds, please contact Gather support',
                    'Help',
                    () => alert('Please contact Gather support')
                );
        }
    };

    renderPaymentInputScreen = () => {
        const { classes, financeState } = this.props;
        const { isAmountValid, isMerchFeeValid, isTotalValid, amount, merchFee, total, memo } = this.state;
        const { terminalState, selectedReader } = financeState;
        const isConnected = terminalState === STRIPE_TERMINAL_STATE.CONNECTED;
        const totalFormatted = formatDinero(toPennyValue(total));

        return (
            <Grid container>
                {this.renderTopStatusBar(isConnected, !isConnected, selectedReader)}
                <Grid item xs={12} className={classNames(classes.textCenter, classes.marginTop20)}>
                    <Tooltip placement='top' enterDelay={400}
                        title={PaymentFieldToolTip.paymentSubtotal}>
                        <TextField
                            required
                            error={!isAmountValid}
                            id="amount"
                            label="Payment Subtotal"
                            value={amount}
                            onChange={e => this.onChangeAmount(e.target.value)}
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            variant="outlined"
                            className={classes.textField}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                                classes: {
                                    notchedOutline: 'notranslate'
                                }
                            }}
                            autoFocus
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} className={classes.textCenter}>
                    <Tooltip placement='top' enterDelay={400}
                        title={PaymentFieldToolTip.paymentFee}
                    >
                        <TextField
                            id="fee"
                            label="Convenience Fee"
                            error={!isMerchFeeValid}
                            value={merchFee}
                            onChange={(e) => this.onChangeProcessingFee(e.target.value)}
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            variant="outlined"
                            className={classes.textField}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                                classes: {
                                    notchedOutline: 'notranslate'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} className={classes.textCenter}>
                    <Tooltip placement='top' enterDelay={400}
                        title={PaymentFieldToolTip.paymentTotal}
                    >
                        <TextField
                            id="total"
                            label="Payment Total"
                            error={!isTotalValid}
                            value={total}
                            onChange={e => this.onChangeTotal(e.target.value)}
                            type="number"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                                classes: {
                                    notchedOutline: 'notranslate'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={12} className={classes.textCenter}>
                    <TextField
                        id="memo"
                        label="Memo (optional)"
                        multiline
                        value={memo}
                        onChange={e => this.onChangeMemo(e.target.value)}
                        className={classes.multiLineTextField}
                        InputProps={{
                            classes: {
                                root: classes.textAreaRoot,
                                notchedOutline: 'notranslate'
                            }
                        }}
                        margin="dense"
                        variant="outlined"
                        placeholder="This memo will be visible on the final receipt"
                    />
                </Grid>

                <Grid className={classes.bottomContent} item xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={!isConnected}
                        onClick={() => this.showConfirmationScreen()}
                        className={classes.marginBottom5}
                    >
                        <TerminalSVG themeFillClass={isConnected ? classes.fillWhite : classes.fillSecondary} />
                        &nbsp;Send {totalFormatted} to&nbsp;
                        {selectedReader ? selectedReader.label : 'Card Reader'}
                    </Button>
                </Grid>

                <Grid item className={classes.footerButton}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.closeDialog(null)}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderConfirmationScreen = () => {
        const { classes,
            activeCase,
            payer,
            financeState,
            openMySettingsDialog,
            onAddNonUserContactInfoClick
        } = this.props;
        const { selectedReader } = financeState;
        const { memo, total } = this.state;

        const payerName = joinNameParts(payer.payerEntity);
        const payerEmail = payer.payerEntity.email;
        const payerPhone = payer.payerEntity.phone;

        const openEditUserDialog = () => !payerEmail && !payerPhone ?
            onAddNonUserContactInfoClick(payer.payerEntity.id) : openMySettingsDialog();

        return (
            <Grid container>
                {this.renderTopStatusBar(true, false, selectedReader)}

                <Grid item className={classNames(classes.background_primary_0_0_12, classes.previewReceipt)}>
                    <Typography>
                        Please confirm payment details
                    </Typography>
                    <div>
                        <Typography>
                            Payment Method:&nbsp;
                            <span>Card Reader</span>
                        </Typography>
                        <Typography>
                            Payment Amount:&nbsp;
                            <span>${total}</span>
                        </Typography>
                    </div>
                    <div>
                        <Typography>
                            Payer:&nbsp;
                            <span
                                onClick={e => openEditUserDialog()}
                                className={classes.clickableText}
                            >
                                {payerName}
                            </span>
                        </Typography>
                        <Typography>
                            Email:&nbsp;
                            <span
                                onClick={e => openEditUserDialog()}
                                className={classes.clickableText}
                            >
                                {payerEmail || 'No email listed'}
                            </span>
                        </Typography>
                        <Typography>
                            Phone:&nbsp;
                            <span
                                onClick={e => openEditUserDialog()}
                                className={classes.clickableText}
                            >
                                {payerPhone && getFormattedPhoneNumber(payerPhone) || 'No number listed'}
                            </span>
                        </Typography>
                        <Typography>
                            Services Of:&nbsp;
                            <span>{activeCase.fname} {activeCase.lname}</span>
                        </Typography>
                    </div>
                    {memo !== '' && <Typography className={classes.memoText}>
                        "{memo}"
                    </Typography>}
                    <div className={classes.bottomContent}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.marginBottom5}
                            onClick={() => this.submitPayment()}
                        >
                            <TerminalSVG themeFillClass={classes.fillWhite} />
                            &nbsp;Confirm Details
                        </Button>
                    </div>
                </Grid>

                <Grid item className={classes.footerButton}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.closeDialog(null)}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderCollectingScreen = (message: string) => {
        const { classes, financeState } = this.props;
        const { selectedReader } = financeState;

        return (
            <Grid container>
                {this.renderTopStatusBar(true, true, selectedReader)}
                <Grid xs={12} item className={classes.cardReaderContainer}>
                    <CardReaderWithCardSVG themeFillClass={classes.fillPrimary} />
                    <Typography color="primary" className={classes.errorText}>
                        {message}
                    </Typography>
                </Grid>

                <Grid item className={classes.footerButton}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.closeDialog(null)}
                    >
                        <CancelIcon />&nbsp;Cancel
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderContent = () => {
        const { financeState, stripeLocation, dispatch } = this.props;
        const { terminalState } = financeState;

        switch (terminalState) {
            case 'NOT_INITIALIZED': case 'INITIALIZING':
                return this.renderInitializing();
            case 'INITIALIZED':
                return this.renderReaderSelector();
            case 'CONNECTED': case 'CONNECTING':
                return this.renderPaymentScreen();
            case 'INITIALIZATION_ERROR':
                return this.renderConnectionError(
                    'Unable to initialize card reader',
                    `Please check that your device is connected to the Internet.
                    You may need to refresh the application.
                    If the problem persists, please contact Gather for additional support.`,
                    'Try Again',
                    () => dispatch(initializeStripeTerminal(stripeLocation))
                );
            case 'CONNECTION_ERROR':
                return this.renderConnectionError(
                    'Cannot connect to your card reader',
                    `Please check that your device and card reader are connected to the same network.
                    Also, be sure to check that your Internet connection is working correctly.
                    If the problem persists, please contact Gather for additional support.`,
                    'Try Again',
                    () => dispatch(initializeStripeTerminal(stripeLocation))
                );
            default:
                return this.renderConnectionError(
                    'Unhandled card reader state',
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `Please contact Gather. The card reader state is '${terminalState}'`,
                    'Try Again',
                    () => dispatch(initializeStripeTerminal(stripeLocation))
                );
        }
    };

    render() {
        const {
            classes,
            isDialogOpen,
            fullScreen,
            zIndex
        } = this.props;

        return (
            <div className={classes.root}>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    onClose={() => this.closeDialog(null)}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.cardReaderDialog}
                    classes={{
                        paper: classes.dialogPaper,
                        root: classes.dRoot,
                    }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogContent className={classes.dialogContent}>
                        {isDialogOpen && this.renderContent()}
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

function mapStateToProps({ financeState }: StoreState) {
    return {
        financeState
    };
}

const component = compose(withGStyles(styles), withFullScreen('xs'))(CardReaderDialog) as React.ComponentType<Props>;

export default withState(mapStateToProps)(component);
