import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { canViewAutoModerationDetails } from '../../../../shared/authority/can';
import { ModerationDecisionUX, ModerationResponse } from '../../../../shared/types';
import withGStyles from '../../../../styles/WithGStyles';
import { useGSelector } from '../../../../types';
import { styles, StyledProps } from '../ModerationContent.styles';

interface Props {
    text: string;
    decision: ModerationDecisionUX | null;
}

const AutoModerationMemoryText = (props: Props & StyledProps) => {
    const { text, classes, decision } = props;

    // Parse the decision reason
    let moderatedMemory: ModerationResponse = new ModerationResponse(decision?.reason ?? '');
    if (decision?.reason) {
        try {
            moderatedMemory = ModerationResponse.fromRequest(JSON.parse(decision.reason));
        } catch (ex) {
            // Unable to parse the decision reason as a ModerationResponse
            // This could happen because OpenAI didn't return a valid object
            // Or, this wasn't an auto-moderated reason
            // Just swallow the exception and use default decision structure
        }
    }

    // Split up the original memory text into highlighted sections
    const sections: Array<{ text: string; reason?: string }> = [];
    let startIndex = 0;
    moderatedMemory.flagged.forEach((flagged, idx) => {
        // There's a segment prior to this flagged section that needs to be pushed on
        if (startIndex < flagged.start) {
            sections.push({ text: text.substring(startIndex, flagged.start) });
        }
        sections.push({
            text: text.substring(flagged.start, flagged.end),
            reason: flagged.reason,
        });
        startIndex = flagged.end;
    });
    // If there is additional unflagged text at the end of the string
    if (startIndex < text.length) {
        sections.push({ text: text.substring(startIndex) });
    }

    // TODO - need some CSS love here!
    return (
        <Grid item xs={12}>
            {sections.map((s, i) => (
                <span
                    key={i}
                    style={{
                        color: s.reason ? 'black' : '#757575',
                        backgroundColor: s.reason ? '#f5bcb8' : 'transparent',
                    }}
                    title={s.reason}
                >{s.text}</span>
            ))}
            {moderatedMemory.note &&
                <Grid item xs={12}>
                    <Typography
                        color="red"
                        className={classes.condolence}
                    >
                        {moderatedMemory.note}
                    </Typography>
                </Grid>}
        </Grid>
    );
};


const HumanModerationMemoryText = (props: Props & StyledProps) => {
    const { text, classes } = props;

    return (
        <Grid item xs={12}>
            <Typography
                color="secondary"
                className={classes.condolence}
            >
                "{text}"
            </Typography>
        </Grid>
    );
};


const ModerationMemoryText = (props: Props & StyledProps) => {
    const { text } = props;
    const user = useGSelector((s) => s.userSession.userData);

    if (text.length > 0) {
        if (canViewAutoModerationDetails(user)) {
            return AutoModerationMemoryText(props);
        } else {
            return HumanModerationMemoryText(props);
        }
    } else {
        return null;
    }
};

export default withGStyles(styles<Props>())(ModerationMemoryText);
