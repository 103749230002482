import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';

import { GatherCaseUX, UserProfile, UserRoles } from '../../../../shared/types';

import Typography from '@mui/material/Typography';
import { SnackBarVariant } from '../../../../types';

import { getFullNameFromUser, getFullNameFromCase } from '../../../../services/app.service';

import { NULL_STRING } from '../../../../constants';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
    userData: UserProfile | null;
}

const ClosePinterest = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
        userData,
        setAppSnackbar
    } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Email copied to your clipboard', 'success');
    };

    const renderEmailBody = (
        name: string,
        email: string,
        relation: string
    ): string => {
        return `Dear Pinterest Care Team,

${getFullNameFromCase(activeCase)}, ${'Pinterest user, recently passed away and we need to cancel their ' +
            'Pinterest account, effective immediately. The required information is listed below.'}

- User's full name: ${getFullNameFromCase(activeCase)}
- User's email: ${NULL_STRING}
- Link to user's Pinterest account: ${NULL_STRING}
(ex: pinterest.com/USERNAME. If you don’t know this, try searching for it by clicking here)
- My name: ${name}
- My email: ${email}
- My relation to ${activeCase.fname}: ${relation}

Per your request, I am also attaching the following documentation of ${activeCase.fname}'s passing: ${NULL_STRING}
${'(Choose one of the following: death certificate, obituary, news article, etc.)'}

Per your request, I am also attaching the following verification of my relation to ${activeCase.fname}: ${NULL_STRING}
${'(Choose one of the following: birth or marriage certificate, public mention of relation, family tree, ' +
            'family/household records, notarized proof of relation, etc.)'}
(Note: If your name is included in the obituary, that's sufficient)

It would be greatly appreciated if you can confirm when this action is completed.

Thanks for your help in this important matter,
${name}`;
    };

    const isFhUser: boolean = UserRoles.isFHUser(userData);

    const pinterestEmail: string = 'care@pinterest.com';
    const emailSubject: string = `Close ${getFullNameFromCase(activeCase)}'s Pinterest Service`;

    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;
    const helperEmail: string = userData && userData.email || NULL_STRING;
    const helperRelation: string = isFhUser
        ? `I am the family-appointed Funeral Director from ${activeCase.funeral_home.name}` : NULL_STRING;

    const emailBody: string = renderEmailBody(helperName, helperEmail, helperRelation);

    const tooltipText = `This will copy this email to your device clipboard so that you can paste it 
                                in another program.`;

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    In order to cancel {activeCase.fname}’s Pinterest account, you will need to send an email
                    to
                    <a
                        className={classNames(
                            classes.fontWeight500,
                            classes.textDecorationNone,
                            classes.cursorPointer,
                            classes.colorSecondary
                        )}
                        href={`mailto: ${pinterestEmail}`}
                    >
                        {` ${pinterestEmail}`}
                    </a>.
                    To make it as easy as possible for you, we’ve already typed out the mail with as much of
                    the required information as possible.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Dear Pinterest Care Team,
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    {getFullNameFromCase(activeCase)}, Pinterest user, recently passed away and we
                    need to cancel their Pinterest account, effective immediately. The required information
                    is listed below.
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        - User's full name: <span className={classes.fontWeight500}>
                            {getFullNameFromCase(activeCase)}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - User's email: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - Link to user's Pinterest account: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        {`(ex: pinterest.com/USERNAME. If you don’t know this, try searching for it by
                                clicking `}
                        <a
                            className={classes.colorSecondary}
                            target="blank"
                            href="https://pinterest.com/all/"
                        >
                            here
                        </a>)
                    </span>

                    <span className={classes.displayBlock}>
                        - My name: <span className={classes.fontWeight500}>{helperName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My email: <span className={classes.fontWeight500}>{helperEmail}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My relation to {activeCase.fname}: <span className={classes.fontWeight500}>
                            {helperRelation}</span>
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    Per your request, I am also attaching the following documentation of
                    {` ${activeCase.fname}`}'s passing: {NULL_STRING}
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (Choose one of the following: death certificate, obituary, news article, etc.)
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    Per your request, I am also attaching the following verification of my relation to
                    {` ${activeCase.fname}`}: {NULL_STRING}
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (Choose one of the following: birth or marriage certificate, public mention of relation,
                        family tree, family/household records, notarized proof of relation, etc.)
                    </span>
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (Note:  <span className={classes.fontWeight500}>
                            If your name is included in the obituary, that's sufficient</span>)
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        It would be greatly appreciated if you can confirm when this action is completed.
                    </span>

                    <span
                        className={classNames(
                            classes.displayBlock,
                            classes.marginTop20
                        )}
                    >
                        Thanks for your help in this important matter,
                        <span className={classes.displayBlock}>
                            {`${userData ? userData.fname : ''} 
                                        ${userData ? userData.lname : ''}`}
                        </span>
                    </span>
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                md={8}
                className={classNames(
                    classes.marginAuto
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize16,
                        classes.fontWeight300,
                        classes.marginTop12
                    )}
                >
                    Clicking below will open your email provider and allow you to edit and send this email.
                </Typography>
                <a
                    href={`mailto:${pinterestEmail}?` +
                        `subject=${emailSubject}&body=` + encodeURIComponent(`${emailBody}`)}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                    >
                        Click To Edit &amp; Send
                    </Button>
                </a>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                    )}
                >
                    or...
                </Typography>
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.button}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this email
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>

            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Don’t forget to attach ONE of the following...
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight500,
                        )}
                    >
                        Documentation of {activeCase.fname}'s passing:
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.colorSecondary,
                        )}
                    >
                        <li>
                            Death certificate
                        </li>
                        <li>
                            Obituary
                        </li>
                        <li>
                            News article
                        </li>
                        <li>
                            etc.
                        </li>
                    </ul>

                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight500,
                        )}
                    >
                        Verification of your relation to {activeCase.fname}:
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.colorSecondary
                        )}
                    >
                        <li>
                            Birth or marriage certificate
                        </li>
                        <li>
                            Public mention of relation
                        </li>
                        <li>
                            Family tree
                        </li>
                        <li>
                            Family/household records
                        </li>
                        <li>
                            Notarized proof of relation
                        </li>
                        <li>
                            etc.
                        </li>
                        <li>
                            Note: <span className={classes.fontWeight500}>
                                If your name is included in {activeCase.fname}'s obituary, that's sufficient
                            </span>
                        </li>
                    </ul>
                </Grid>
            </Grid>

        </TaskDialogStepWrapper>
    );
};

export default ClosePinterest;
