import * as React from 'react';
import { FeatureKey, Features } from '../../shared/types';
import { FuneralHomeState, GatherCasesState, UserSession, useGSelector } from '../../types';
import { isViewAsGOM } from '../../services';
import { isFeatureEnabled } from '../../shared/utils';

interface MappedProps {
    funeralHomeFeatures: Features | null;
    hasOverride: boolean;
}

function getFuneralHomeFeatures(funeralHomeState: FuneralHomeState, casesState: GatherCasesState): Features | null {
    const { activeFuneralHome } = funeralHomeState;
    const { selectedCase, publicCase } = casesState;
    if (activeFuneralHome) {
        return activeFuneralHome.features;
    } else if (selectedCase) {
        return selectedCase.funeral_home.features;
    } else if (publicCase) {
        return publicCase.funeral_home.features;
    }
    return null;
}

function useReduxState(): MappedProps {
    const funeralHomeState = useGSelector(state => state.funeralHomeState);
    const casesState = useGSelector(state => state.casesState);
    const userSession = useGSelector(state => state.userSession);

    return {
        funeralHomeFeatures: getFuneralHomeFeatures(funeralHomeState, casesState),
        hasOverride: isViewAsGOM(userSession),
    };
}

interface FeatureProps {
    feature: FeatureKey;
    and?: boolean;
    or?: boolean;
}

export function featureChecker(features: Features | null) {
    return (feature: FeatureKey): boolean => {
        return isFeatureEnabled(feature, features);
    };
}

const evaluateFeatureProps = (
    props: FeatureProps,
    hasOverride: boolean,
    funeralHomeFeatures: Features | null,
): boolean => {
    const { feature } = props;
    return (hasOverride || isFeatureEnabled(feature, funeralHomeFeatures))
        && (props.and !== undefined ? props.and : true)
        || (props.or !== undefined ? props.or : false);
};

export const WhenEnabled: React.FC<FeatureProps> = (props) => {
    const { hasOverride, funeralHomeFeatures } = useReduxState();
    return evaluateFeatureProps(props, hasOverride, funeralHomeFeatures)
        ? <>{props.children}</> : null;
};

export const WhenDisabled: React.FC<FeatureProps> = (props) => {
    const { hasOverride, funeralHomeFeatures } = useReduxState();
    return evaluateFeatureProps(props, hasOverride, funeralHomeFeatures)
        ? null : <>{props.children}</>;
};

export function isEnabled(
    feature: FeatureKey,
    funeralHomeState: FuneralHomeState,
    casesState: GatherCasesState,
    userSession: UserSession,
): boolean {
    const features = getFuneralHomeFeatures(funeralHomeState, casesState);
    return isViewAsGOM(userSession) || isFeatureEnabled(feature, features);
}

export function anyEnabled(params: {
    features: FeatureKey[];
    funeralHomeState: FuneralHomeState;
    casesState: GatherCasesState;
    userSession: UserSession;
}): boolean {
    const { features, funeralHomeState, casesState, userSession } = params;
    const funeralHomeFeatures = getFuneralHomeFeatures(funeralHomeState, casesState);
    return isViewAsGOM(userSession) || features.some(feature => isFeatureEnabled(feature, funeralHomeFeatures));
}

interface WhenManyEnabledProps {
    features: FeatureKey[];
}
export const WhenAnyEnabled: React.FC<WhenManyEnabledProps> = (props) => {
    const { features, children } = props;
    const { hasOverride, funeralHomeFeatures } = useReduxState();

    if (hasOverride || (funeralHomeFeatures &&
        features.some(x => isFeatureEnabled(x, funeralHomeFeatures)))) {
        return <>{children}</>;
    }
    return null;
};

export const WhenAllEnabled: React.FC<WhenManyEnabledProps> = (props) => {
    const { features, children } = props;
    const { hasOverride, funeralHomeFeatures } = useReduxState();

    if (hasOverride || (funeralHomeFeatures &&
        features.every(x => isFeatureEnabled(x, funeralHomeFeatures)))) {
        return <>{children}</>;
    }
    return null;
};

export const WhenAllDisabled: React.FC<WhenManyEnabledProps> = (props) => {
    const { features, children } = props;
    const { hasOverride, funeralHomeFeatures } = useReduxState();

    if (!hasOverride && (!funeralHomeFeatures ||
        !features.some(x => isFeatureEnabled(x, funeralHomeFeatures)))) {
        return <>{children}</>;
    }
    return null;
};

export const WebsiteSyncFeatures = [
    FeatureKey.GATHER_SYNC,
    FeatureKey.FUNERAL_ONE_SYNC,
    FeatureKey.CFS_SYNC,
    FeatureKey.FUNERAL_INNOVATIONS_SYNC,
];
