import { EntitySummary, GatherCaseFHUpdateRequest } from '../../../shared/types';

import { openTeamInvitationDialog } from '../../../actions/Dialog.action';
import { useGDispatch, useGSelector } from '../../../types';
import { updateCaseForFuneralHome } from '../../../actions/GatherCase.action';
import AssignCaseWatchersPure, { AssignCaseWatchersPureProps } from './AssignCaseWatchersPure';

interface Props
    extends Omit<
        AssignCaseWatchersPureProps,
        'userData' | 'team' | 'onWatchersChanged' | 'onInviteTeamMember' | 'isLoading'
    > {
    onWatchersUpdated?: (watchers: EntitySummary[]) => void;
}

const AssignCaseWatchers = (props: Props) => {
    const { activeCase, onWatchersUpdated } = props;

    const dispatch = useGDispatch();
    const userData = useGSelector(({ userSession }) => userSession.userData);
    const team = useGSelector(({ teamState }) => teamState.team);

    const handleUpdateWatchers = (updatedWatcherUserIds: number[], watchers: EntitySummary[]) => {
        const caseChanges: GatherCaseFHUpdateRequest = {
            case_watcher_user_ids: updatedWatcherUserIds,
        };
        dispatch(
            updateCaseForFuneralHome({
                funeralHomeId: activeCase.funeral_home_id,
                caseChanges,
                caseUuid: activeCase.uuid,
            }),
        );
        onWatchersUpdated?.(watchers);
    };

    const handleAddTM = (_zIndex: number, onAddedCallback?: (teamMember: EntitySummary) => void) => {
        dispatch(
            openTeamInvitationDialog({
                zIndex: _zIndex + 1,
                onAddedCallback,
            }),
        );
    };

    if (!userData) {
        return null;
    }

    return (
        <AssignCaseWatchersPure
            {...props}
            userData={userData}
            team={team || []}
            onWatchersChanged={handleUpdateWatchers}
            onInviteTeamMember={handleAddTM}
        />
    );
};

export default AssignCaseWatchers;
