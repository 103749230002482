import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {},
    sorryText: {
        marginTop: 20
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    noResultsDivider: {
        margin: '8px 32px'
    },
    noWorriesText: {
        padding: '0 24px'
    },
}), { name: 'AvailableDefaultDialogNoResult' });

interface Props {
    itemType: string;
    onCreateNewItemClick?: () => void;
}

const AvailableDefaultDialogNoResult = (props: Props) => {
    const { itemType, onCreateNewItemClick } = props;
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.noResultsFoundSection}>
            <Typography
                color="secondary"
                component="p"
                className={classes.sorryText}
            >
                Sorry no results found.
            </Typography>
            <Divider className={classes.noResultsDivider} />
            {onCreateNewItemClick &&
                <Typography
                    color="secondary"
                    component="p"
                    className={classes.noWorriesText}
                >
                    <span
                        className={classes.textClickable}
                        onClick={onCreateNewItemClick}
                    >
                        Click here
                    </span>
                    &nbsp;to create a new {itemType}.
                </Typography>
            }
        </Grid >
    );
};

export default AvailableDefaultDialogNoResult;
