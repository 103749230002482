import classNames from 'classnames';
import * as React from 'react';
import momentTz from 'moment-timezone';

import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRulesCallback } from '@mui/styles';
import AppBar from '@mui/material/AppBar';

import { MERIDIEM_LIST, DURATION_LIST, HOURS_LIST, MINUTES_LIST, generateDates } from './utils';
import GTabs from '../../../common/GTabs';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import withGStyles from '../../../../styles/WithGStyles';

export const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dateTimeTabs: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: 10,
    },
    marginTop0: {
        marginTop: 0
    }
});

type Classes = 'root' | 'dateTimeTabs' | 'marginTop0';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    startTime: momentTz.Moment;
    endTime: momentTz.Moment;
    onTimeChange: (params: { newEndTime: momentTz.Moment; newStartTime?: momentTz.Moment }) => void;
    onDateChange: (time: momentTz.Moment) => void;
}

const getEndTime = (durationTabIndex: number, startTime: momentTz.Moment) => {
    const duration = DURATION_LIST[durationTabIndex]
        ? DURATION_LIST[durationTabIndex].value
        : 30;
    return startTime.clone().add(Number(duration), 'minutes');
};

const ExpandedDatesView = ({
    startTime,
    endTime,
    onTimeChange,
    onDateChange,
    classes,
}: Props & StyledProps) => {

    const timezone = startTime.tz() || momentTz.tz.guess();
    const DATES = generateDates(momentTz().tz(timezone).dayOfYear());

    const timeDiff = endTime.diff(startTime, 'minutes');
    const startHours = startTime.hours();
    const startMinutes = startTime.minutes();

    const durationTabIndex = DURATION_LIST.findIndex(d => d.value === timeDiff);
    const hourTabIndex = HOURS_LIST.findIndex((h) => h.value === startHours % 12);
    const meridiemTabIndex = startHours < 12 ? 0 : 1; // AM = 0, PM = 1
    const minuteTabIndex = MINUTES_LIST.findIndex((m) => m.value === startMinutes);
    const dateTabIndex = DATES.findIndex((d) => momentTz.tz(d.value, timezone).isSame(startTime, 'day'));

    const handleChangeMeridiemEvent = (event: React.ChangeEvent<{}>, newIndex: number) => {
        event.preventDefault();

        const addHours = newIndex === 1 && meridiemTabIndex === 0
            ? 12
            : meridiemTabIndex === 1 && newIndex === 0
                ? -12
                : 0;

        const newStartTime = startTime.clone().add(addHours, 'hours');
        onTimeChange({
            newStartTime,
            newEndTime: getEndTime(durationTabIndex, newStartTime),
        });
    };

    const handleChangeMinutesEvent = (event: React.ChangeEvent<{}>, newIndex: number) => {
        event.preventDefault();

        const chosenMinutesSlot = MINUTES_LIST[newIndex].value;

        const newStartTime = startTime.clone().minute(typeof chosenMinutesSlot === 'number' ? chosenMinutesSlot : 0);
        onTimeChange({
            newStartTime,
            newEndTime: getEndTime(durationTabIndex, newStartTime),
        });
    };

    const handleChangeHoursEvent = (event: React.ChangeEvent<{}>, newIndex: number) => {
        event.preventDefault();

        const chosenHourSlot = HOURS_LIST[newIndex].value;

        const newStartTime = startTime.clone().hour(typeof chosenHourSlot === 'number'
            ? ((startHours >= 12 ? 12 : 0) + chosenHourSlot)
            : 12);
        onTimeChange({
            newStartTime,
            newEndTime: getEndTime(durationTabIndex, newStartTime),
        });
    };

    const handleChangeDateEvent = (event: React.ChangeEvent<{}>, newIndex: number) => {
        event.preventDefault();

        const chosenDate = DATES[newIndex].value;

        const newStartTime = typeof chosenDate !== 'number'
            ? momentTz.tz(chosenDate, timezone)
            : momentTz().tz(timezone);
        onDateChange(newStartTime.startOf('day'));
    };

    const handleChangeDurationEvent = (event: React.ChangeEvent<{}>, newIndex: number) => {
        event.preventDefault();

        onTimeChange({
            newEndTime: getEndTime(newIndex, startTime),
        });
    };

    return (
        <>
            <AppBar
                position="static"
                className={classNames(classes.dateTimeTabs, classes.marginTop0)}
            >
                <GTabs
                    data={DATES}
                    handleChangeEvent={handleChangeDateEvent}
                    tabIndex={dateTabIndex === -1 ? false : dateTabIndex}
                    isLabelThemeColor
                    shrinkScrollButtonSize
                />
            </AppBar>

            <AppBar position="static" className={classes.dateTimeTabs}>
                <GTabs
                    data={HOURS_LIST}
                    handleChangeEvent={handleChangeHoursEvent}
                    tabIndex={hourTabIndex === -1 ? false : hourTabIndex}
                    isLabelThemeColor
                    shrinkScrollButtonSize
                />
            </AppBar>

            <AppBar position="static" className={classes.dateTimeTabs}>
                <GTabs
                    data={MINUTES_LIST}
                    handleChangeEvent={handleChangeMinutesEvent}
                    tabIndex={minuteTabIndex === -1 ? false : minuteTabIndex}
                    alignCenter
                    isLabelThemeColor
                    shrinkScrollButtonSize
                />
            </AppBar>

            <AppBar position="static" className={classes.dateTimeTabs}>
                <GTabs
                    data={MERIDIEM_LIST}
                    handleChangeEvent={handleChangeMeridiemEvent}
                    tabIndex={meridiemTabIndex}
                    alignCenter
                    isLabelThemeColor
                    shrinkScrollButtonSize
                />
            </AppBar>

            <AppBar position="static" className={classes.dateTimeTabs}>
                <GTabs
                    data={DURATION_LIST}
                    handleChangeEvent={handleChangeDurationEvent}
                    tabIndex={durationTabIndex === -1 ? false : durationTabIndex}
                    isLabelThemeColor
                    shrinkScrollButtonSize
                />
            </AppBar>
        </>
    );
};

export default withGStyles(styles)(ExpandedDatesView);
