import { MAX_ZEBRA_HEALTH_CHECK_COUNT } from '../constants';
import { log } from '../logger';
import { isValidIpAddress } from './app.service';

const BrowserPrintPort = 9100; // 9100 | 9105
const API_URL = `http://localhost:${BrowserPrintPort}/`;
interface Device {
    name: string;
    deviceType: string;
    connection: string;
    uid: string;
    provider: string;
    manufacturer: string;
    version: number;
}

export default class ZebraBrowserPrintWrapper {
    device: Device = {} as Device;
    public healthCheckCount: number = 0;

    getAvailablePrinters = async () => {
        const config = {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
        };

        const endpoint = API_URL + 'available';

        try {
            const res = await fetch(endpoint, config);

            const data = await res.json();

            if (data && data !== undefined && data.printer && data.printer !== undefined && data.printer.length > 0) {
                return data.printer;
            }
            return this.sendError('No printers available');
        } catch (error) {
            const errorObject = this.sendError();
            if (errorObject) {
                throw new Error();
            }
        }
    };

    sendError = (msg?: string): Error | undefined => {
        this.healthCheckCount++;
        if (this.healthCheckCount > MAX_ZEBRA_HEALTH_CHECK_COUNT) {
            return;
        }
        return new Error(msg);

    };

    resetHealthCheckCount = () => {
        this.healthCheckCount = 0;
    };

    getDefaultPrinter = async (): Promise<Device> => {
        const config = {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
        };

        const endpoint = API_URL + 'default';
        if (this.healthCheckCount > MAX_ZEBRA_HEALTH_CHECK_COUNT) {
            throw this.sendError('Max health check count reached, refresh the page and try again.');
        }

        try {
            const res = await fetch(endpoint, config);
            const data = await res.text();

            if (data && data !== undefined && typeof data !== 'object' && data.split('\n\t').length === 7) {
                const deviceRaw = data.split('\n\t');

                const name = this.cleanUpString(deviceRaw[1]);
                const deviceType = this.cleanUpString(deviceRaw[2]);
                const connection = this.cleanUpString(deviceRaw[3]);
                const uid = this.cleanUpString(deviceRaw[4]);
                const provider = this.cleanUpString(deviceRaw[5]);
                const manufacturer = this.cleanUpString(deviceRaw[6]);

                return {
                    connection,
                    deviceType,
                    manufacturer,
                    name,
                    provider,
                    uid,
                    version: 0,
                };
            }
            throw this.sendError("There's no default printer");
        } catch (error) {
            log.warn(`Error: `, { error }); // this is to send us a message into sentry
            throw this.sendError(); // raise an error to be captured.
        }
    };

    setPrinter = (device: Device) => {
        this.device = device;
    };

    getPrinter = (): Device => {
        return this.device;
    };

    cleanUpString = (str: string): string => {
        const arr = str.split(':');
        const result = arr.slice(1).join(':').trim();
        return result;
    };

    queryPrinterIdentification = async () => {
        await this.write('~HI');
        await this.read();
    };

    getConfig = async () => {
        await this.write('~HH');
        const result = await this.read();
        return result;
    };

    checkPrinterStatus = async () => {
        await this.write('~hs');
        const result = await this.read();
        const errors: string[] = [];
        let isReadyToPrint = false;

        // const re = /(?:\u0002(.*?)\u0003\r\n){3}/g;
        const re = /\u0002(.*?)\u0003\r\n\u0002(.*?)\u0003\r\n\u0002(.*?)\u0003\r\n/;
        const statusString = re.exec(result || '');
        if (statusString == null) {
            errors.push('Unable to get Status from Printer');
            // the printer does not return a status string if it is in one of the following states:
            // MEDIA OUT
            // RIBBON OUT
            // HEAD OPEN
            // REWINDER FULL
            // HEAD OVER-TEMPERATURE
            return {
                isReadyToPrint,
                errors: errors,
            };
        }

        // first Status String parsed out
        const [
            communicationInterfaceSettings,
            paperOutFlag,
            pauseFlag,
            labelLength,
            numberOfFormatsInReceiveBuffer,
            bufferFullFlag,
            communicationsDiagnosticMode,
            partialFormatFlag,
            unusedBits,
            corruptRamFlag,
            tempRangeIsUnder,
            tempRangeIsOver,
        ] = statusString[1].toString().split(',');

        const [
            functionSetting,
            unusedBits2,
            headUpFlag,
            ribbonOutFlag,
            thermalTransferModeFlag,
            printMode,
            printWidthMode,
            labelWaitingFlag,
            labelsRemainingInBatch,
            formatWhilePrintingFlag,
            numberOfGraphicImagesStoredInMemory,
        ] = statusString[2].split(',');

        if (0) {
            // Adding a unreachable code to reference all destructured variables
            //
            log.warn(` StatusString: `, {
                communicationInterfaceSettings,
                paperOutFlag,
                pauseFlag,
                labelLength,
                numberOfFormatsInReceiveBuffer,
                bufferFullFlag,
                communicationsDiagnosticMode,
                partialFormatFlag,
                unusedBits,
                corruptRamFlag,
                tempRangeIsUnder,
                tempRangeIsOver,
            });
            log.warn(` StatusString2: `, {
                functionSetting,
                unusedBits2,
                headUpFlag,
                ribbonOutFlag,
                thermalTransferModeFlag,
                printMode,
                printWidthMode,
                labelWaitingFlag,
                labelsRemainingInBatch,
                formatWhilePrintingFlag,
                numberOfGraphicImagesStoredInMemory,
            });
        }
        // left in for reference on the return status string from Zebra
        // eslint-disable-next-line
        // "\u0002000,0,0,0211,000,0,0,0,000,0,0,0\u0003\r\n\u0002000,0,0,0,0,2,3,0,00000000,1,000\u0003\r\n\u00020000,0\u0003\r\n"
        // "\u030,0,0,0214,000,0,0,0,000,0,0,0" "000,0,0,0,0,2,3,0,00000000,1,000" "0000,0"

        // 000,0,0,0209,000,0,0,0,000,0,0,0
        // 000,0,0,0,0,2,3,0,00000000,1,000
        // 0000,0

        // 030,0,0,0212,000,0,0,0,000,0,0,0
        // 000,0,0,0,0,2,2,0,00000000,1,000
        // 0000,0

        // 000,0,0,0209,000,0,0,0,000,0,0,0
        // 000,0,0,0,0,2,3,0,00000000,1,000
        // 0000,0


        // eslint-disable-next-line
        //        0123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890
        // eslint-disable-next-line
        //                  10        20        30        40        50        60        70        80        90        100       110
        /*
        \u0002
        000, Communication Interface Settings
        0, Paper Out Flag (1=paper is out)
        0, pause flag (1= pause active)
        0211, Label Length ( number of dots )
        000, number of formats in receive buffer
        0, buffer full flag (1 = receive buffer full)
        0, commmunications diagnostic mode ( 1= diagnostic mode active)
        0, partial format flag (1 = partial format in progress)
        000, unused (alaways 000)
        0, corrupt RAM flag (1 = configuration data lost)
        0, temperature range (1 = under temp)
        0 temperature range (1 = over temp)
        \u0003\r\n

        \u0002
        000, function setting (convert decimal to binary then lookup in table)
                -- mmm = b7 b6 b5 b4 b3 b2 b1 b0
                b7: Media Type 0: DieCut | 1: Continuous 
                b6: Sensor Profile (0: off)
                b5: communications diagnostic
                b4: unused (0: off | 1: On)
                b3: unused (0: off | 1: On)
                b2: unused (0: off | 1: On)
                b1: unused (0: off | 1: On)
                b0: Print Mode ( o: Direct Thermal | 1 = Thermal Transfer)
        0, unused
        0, head up flag ( 1 = head in up position)
        0, ribbon out flag ( 1= ribbon is out)
        0, thermal traqnsfer mode flag ( 1 = Thermal Transfer Mode selected)
        2, Print Mode ( 2 = Tear-Off )
        3, print width mode 
        0, label waiting flag ( 1 = label waiting in peel-off mode)
        00000000,  Labels ramaining in batch
        1, format while printing flag (always 1)
        000 number of graphic images stored in memory
        \u0003\r\n

        \u0002
        0000, Password
        0 RAM (0 static RAM not installed  | 1: static RAM installed) 
        \u0003\r\n"
         
        */

        if (paperOutFlag === '1') {
            errors.push('Paper out');
        }
        if (ribbonOutFlag === '1') {
            errors.push('Ribbon out');
        }
        // const labelLengthInt = parseInt(labelLength, 10);
        // if (labelLengthInt > 220 || labelLengthInt < 150) {
        //     // this might be a range
        //     // errors.push('Label not configured correctly');
        //     log.warn(`Label Length is not configured correctly (expected 150 - 220)`, { labelLengthInt });
        // }
        if (pauseFlag === '1') {
            errors.push('Printer is Paused.');
        }
        if (parseInt(tempRangeIsOver, 10) === 1) {
            errors.push('Printer is Overheating.');
        }
        if (bufferFullFlag === '1') {
            errors.push('Buffer is full.');
        }
        if (communicationsDiagnosticMode === '1') {
            errors.push('Communication diagnostic mode is active');
        }
        if (corruptRamFlag === '1') {
            errors.push('Corrupt RAM detected');
        }

        isReadyToPrint = errors.length === 0;

        if (!isReadyToPrint && errors.length === 0) {
            errors.push('Error: Unknown Error');
        }

        return {
            isReadyToPrint,
            errors: errors,
        };
    };

    write = async (data: string) => {
        try {
            const endpoint = API_URL + 'write';

            const myData = {
                device: this.device,
                data,
            };

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain;charset=UTF-8',
                },
                body: JSON.stringify(myData),
            };
            await fetch(endpoint, config);
        } catch (error) {
            log.warn(`Error: `, { error });
        }
    };

    read = async () => {
        try {
            const endpoint = API_URL + 'read';

            const myData = {
                device: this.device,
            };

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain;charset=UTF-8',
                },
                body: JSON.stringify(myData),
            };

            const res = await fetch(endpoint, config);
            const data = await res.text();
            return data;
        } catch (error) {
            log.warn(`Error`, { error });
            return null;
        }
    };

    print = async (text: string) => {
        try {
            await this.write(text);
        } catch (error) {
            log.warn(`Error`, { error });
        }
    };

    isBrowserPrintRunning = async (): Promise<boolean> => {
        const config = {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
        };
        const endpoint = API_URL + 'available';
        try {
            const res = await fetch(endpoint, config);
            if (res.status === 200) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    isZebraConfigAvailable = async (args: { ipaddress: string }): Promise<boolean> => {
        const { ipaddress } = args;

        if (!ipaddress || ipaddress === '') {
            throw new Error('IP Address is required');
        }

        if (!isValidIpAddress(ipaddress)) {
            throw new Error('Invalid IP Address');
        }
        const url = `http://${ipaddress}/config.html`;
        try {
            await fetch(url, { mode: 'no-cors' });
            // If the request was successful, the server is available
            return true;
        } catch (error) {
            // If the request failed, the server is not available
            return false;
        }
    };
}