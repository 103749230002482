import classNames from 'classnames';

import InsetShadowIcon from '../../../common/InsetShadowIcon';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import withGStyles from '../../../../styles/WithGStyles';

interface Props {
    isLoading: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    iconContainerSize: {
        fontSize: 114,
        margin: 8,
    },
    flexDayEvents: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& $subtitle': {
            maxWidth: 250,
            textAlign: 'center',
            lineHeight: 1.25,
            minHeight: 34,
        }
    },
    borderEventSection: {
        border: '1px solid',
        color: theme.palette.secondary.main,
        borderRadius: 12
    },
    noEventHeight: {
        margin: 'auto 0',
        overflow: 'auto',
    },
    subtitle: {},
});

type StyledProps = Props & GLOBAL_STYLED_PROPS
    & WithStyles<'root' | 'subtitle' | 'borderEventSection' | 'flexDayEvents'
        | 'noEventHeight' | 'iconContainerSize'>;

const DateWiseEvent = (props: StyledProps) => {

    const { classes, isLoading } = props;

    const text = isLoading
        ? 'Loading Your Events...'
        : 'No Events Scheduled';

    return (
        <div
            className={classNames(
                classes.flexDayEvents,
                classes.noEventHeight,
            )}
        >
            <div className={classes.flexDayEvents}>
                <Typography
                    component="p"
                    color="primary"
                    className={classNames(
                        classes.fontSize18,
                    )}
                >
                    {text}
                </Typography>
                <InsetShadowIcon
                    icon={isLoading ? <DateRangeIcon /> : <CalendarTodayIcon />}
                    iconClass={classes.iconContainerSize}
                />

                <Typography
                    component="p"
                    color="primary"
                    className={classNames(
                        classes.fontSize14,
                        classes.subtitle
                    )}
                >
                    {!isLoading &&
                        `All events scheduled in Gather or on your team's calender will show up here.`
                    }
                </Typography>
            </div>
        </div>
    );
};

export default withGStyles(styles)(DateWiseEvent);
