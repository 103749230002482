import { useState } from 'react';
import classNames from 'classnames';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { getIntercomTargetProp, joinNameParts } from '../../../../../../services';
import { EntitySummary, entitySummaryToTaskUser, TaskUserType, UserProfile } from '../../../../../../shared/types';
import PickTeamMember from '../../../../../assignmentPoppers/PickTeamMember';
import UserAvatar from '../../../../../common/UserAvatar';
import StepComponentWrapper from '../StepComponentWrapper';
import makeGStyles from '../../../../../../styles/makeGStyles';
import { useGDispatch } from '../../../../../../types';
import { logoutUserSession } from '../../../../../../actions/UserSession.action';
import { log } from '../../../../../../logger';
import moment from 'moment-timezone';

const useStyles = makeGStyles(theme => ({
    cssEllipses: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    stepPerformedHeader: {
        background: theme.palette.primary.main,
        borderRadius: '8px 8px 0px 0px',
        '&$completed': {
            borderRadius: 8
        },
        '& > div:first-of-type': {
            height: 'min-content',
            width: 'unset',
        },
        '& $inner': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2px 6px',
            '& p': {
                fontSize: 12,
                display: 'flex',
                maxWidth: '40%',
                '& svg': {
                    fontSize: 16,
                },
            },
        },
    },
    stepPerformedContent: {
        padding: '16px 6px',
    },

    loggedInAs: {
        fontSize: 16,
        width: 'fit-content',
        margin: '0px auto',
        padding: 6,
    },
    whoPerformed: {
        textAlign: 'center',
        fontSize: 16,
    },
    userAvatar: {
        height: 48,
        width: 48,
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%, -50%)',
        border: `2px solid ${theme.palette.common.white}`,
    },
    userAvatarSmall: {
        height: 20,
        width: 20,
    },

    protectionText: {
        fontSize: 13,
        width: 'fit-content',
        margin: '0 auto',
        textAlign: 'center',
        lineHeight: 1.25,
    },
    assigneeBtn: {
        display: 'flex',
        margin: '0px auto',
        padding: '4px 8px',
        fontWeight: 300,
        fontSize: 16,
        textTransform: 'none',
        textDecoration: 'underline !important'
    },
    completed: {},
    inner: {}
}), { name: 'PerformerComponent' });

interface Props {
    user: UserProfile;
    stepTitle: string;
    performedBy: TaskUserType | undefined | null;
    zIndex: number;
    isDisabled: boolean;
    completedBy?: TaskUserType | null;
    completedTime?: Date | null;
    intercomTargetPropDialogName?: string;
    onAssignPerformer: (user: TaskUserType) => void;
}

const PerformerComponent = (props: Props) => {
    const {
        zIndex,
        user,
        performedBy,
        isDisabled,
        stepTitle,
        completedBy,
        completedTime,
        intercomTargetPropDialogName,
        onAssignPerformer,
    } = props;

    const classes = useStyles();

    const [ptmAnchorEl, setPtmAnchorEl] = useState<HTMLElement | null>(null);

    const dispatch = useGDispatch();

    if (!performedBy) {
        return null;
    }

    const handleAssign = (entity: EntitySummary | null) => {
        if (!entity) {
            return;
        }

        const taskUser = entitySummaryToTaskUser(entity);
        if (!taskUser) {
            log.warn('Selected a team member w/o user id', { entity });
            return;
        }
        onAssignPerformer(taskUser);
        closeAssignCase();
    };

    const openAssignCase = (event: React.MouseEvent<HTMLElement>) => {
        setPtmAnchorEl(event.currentTarget);
    };

    const closeAssignCase = () => {
        setPtmAnchorEl(null);
    };

    const performerFullName = joinNameParts(performedBy);

    const tooltip = `To help protect your reputation by increasing accountability, the completion of 
        each step is attributed to the person that is logged-in currently.`;

    return (
        <StepComponentWrapper
            intercomTargetProp={intercomTargetPropDialogName
                ? `CompleteKeepTrackStep-PerformerComponent-${intercomTargetPropDialogName}`
                : `CompleteKeepTrackStep-PerformerComponent`}
        >
            <Grid
                item xs={12}
                className={classNames(classes.stepPerformedHeader, completedBy && classes.completed)}
            >
                <Tooltip title={joinNameParts(completedBy ?? user)} enterDelay={600}>
                    <UserAvatar
                        size={48}
                        user={completedBy ?? user}
                        className={classes.userAvatar}
                        intercomTargetProp={intercomTargetPropDialogName
                            ? `CompleteKeepTrackStep-AssigneeAvatar-${intercomTargetPropDialogName}`
                            : `CompleteKeepTrackStep-AssigneeAvatar`}
                    />
                </Tooltip>

                {!completedBy &&
                    <Grid className={classes.inner}>
                        <Typography color="white">
                            <Tooltip
                                title={tooltip}
                                enterDelay={400}
                            >
                                <VerifiedUserIcon />
                            </Tooltip>
                            &nbsp;<span className={classes.cssEllipses}>{user.state_license_number}</span>
                        </Typography>

                        <Typography
                            color="white"
                            onClick={() => dispatch(logoutUserSession())}
                            className={classNames(classes.cursorPointer, classes.cssEllipses)}
                            {...getIntercomTargetProp(intercomTargetPropDialogName
                                ? `CompleteKeepTrackStep-LogOutButton-${intercomTargetPropDialogName}`
                                : `CompleteKeepTrackStep-LogOutButton`)}
                        >
                            Not You?&nbsp;<span className={classes.textUnderline}>Logout</span>
                        </Typography>
                    </Grid>
                }

                <Typography
                    color="white"
                    className={classNames(
                        classes.loggedInAs,
                        classes.flexColumnCentered,
                        completedBy && classes.marginTop24,
                        completedBy && classes.paragraphLineHeight
                    )}
                >
                    {completedBy && completedTime
                        ? <>
                            <span>Step marked complete by {joinNameParts(completedBy)}</span>
                            <span>Step performed by {performerFullName}</span>
                            <span>
                                {moment.tz(completedTime, 'america/boise').format('DD MMMM YYYY @ h:mma z')}
                            </span>
                        </>
                        : `You're Logged in as ${user.fname}`}
                </Typography>
            </Grid>

            {!completedBy &&
                <Grid item xs={12} className={classes.stepPerformedContent}>
                    <Typography
                        color="secondary"
                        className={classes.whoPerformed}
                        {...getIntercomTargetProp(intercomTargetPropDialogName
                            ? `CompleteKeepTrackStep-WhoPerformedStep-${intercomTargetPropDialogName}`
                            : `CompleteKeepTrackStep-WhoPerformedStep`)}
                    >
                        Who performed this "{stepTitle}" step?
                    </Typography>

                    <Button
                        color="secondary"
                        size="small"
                        className={classes.assigneeBtn}
                        onClick={openAssignCase}
                        disabled={isDisabled}
                        {...getIntercomTargetProp(intercomTargetPropDialogName
                            ? `CompleteKeepTrackStep-AssigneeButton-${intercomTargetPropDialogName}`
                            : `CompleteKeepTrackStep-AssigneeButton`)}
                    >
                        <UserAvatar
                            user={performedBy}
                            size={20}
                            className={classes.userAvatarSmall}
                        />
                        <span className={classes.marginLeft5}>{performerFullName}</span>
                    </Button>

                    {isDisabled &&
                        <Typography color="secondary" className={classes.protectionText}>
                            For your protection, you can't complete this step on behalf of another.
                            Whoever performed the task must log in and mark it as complete.
                        </Typography>
                    }
                </Grid>
            }

            {!isDisabled &&
                <PickTeamMember
                    zIndex={zIndex + 1}
                    handleClose={closeAssignCase}
                    onAssign={handleAssign}
                    anchorEl={ptmAnchorEl}
                    headerText="Who performed this step?"
                />
            }
        </StepComponentWrapper>
    );
};

export default PerformerComponent;