import { Component } from 'react';
import { throttle } from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import withStyles, { WithStyles, StyleRulesCallback } from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import DocPreview from '../family/docs/docPackets/docPreview';
import { downloadRolodexDoc } from '../../actions/Rolodex.action';
import { RolodexOrganization } from '../../shared/types/rolodex';
import { StoreState } from '../../types';
import { downloadCaseDoc, downloadFuneralHomeDoc, updateCaseDoc, updateFuneralHomeDoc } from '../../actions/Doc.action';
import { DocDownloadResponse } from '../../shared/types';

import DocumentRenameDialog from './DocumentRename.dialog';
import { downloadFromURL } from '../../services/doc.service';
import { isFileTypePdf, isFileTypeImage } from '../../services';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';
import { withDialogMounter } from '../../DialogMounter';
import { SlideTransition } from '../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            margin: 0,
            '@media (min-width: 1400px)': {
                minHeight: 720,
                maxHeight: 720,
                margin: 48,
                maxWidth: 1080,
            }
        },
    },
    dialogTitle: {
        zIndex: 1,
        backgroundColor: theme.palette.common.white,
        padding: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '8px',
        paddingRight: '40px !important',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            rowGap: 0,
            paddingRight: '52px !important',
        },
    },
    dialogContent: {
        padding: 0,
        '@media (min-width: 600px)': {
            padding: '0 24px'
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        zIndex: 1,
        cursor: 'pointer',
    },
    downloadButton: {
        borderRadius: 10,
        minHeight: 32,
        padding: '4px 10px',
        textTransform: 'capitalize',
        fontWeight: 400
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    filename: {
        cursor: 'pointer',
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            textDecoration: 'underline'
        },
        '@media (min-width: 600px)': {
            alignSelf: 'flex-start',
            width: 'auto'
        }
    },
    errorContainer: {
        height: '100%'
    },
    dialogPaper: {}
});

type StyledProps = WithStyles<'root' | 'dialogPaper' | 'dialogTitle'
    | 'clearIcon' | 'downloadButton' | 'loadingContainer' | 'filename' | 'dialogContent'
    | 'errorContainer'>;

const mapStateToProps = ({ casesState, funeralHomeState, userSession }: StoreState) => {
    return {
        gatherCase: casesState.selectedCase,
        activeFuneralHome: funeralHomeState.activeFuneralHome,
        userSession
    };
};

export enum DocType {
    FuneralHome = 'FuneralHome',
    Rolodex = 'Rolodex',
    Case = 'Case'
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    isDialogOpen: boolean;
    closeDialog: () => void;
    zIndex: number;
    docId: number;
    docType: DocType;
    activeFuneralHomeId: number;
    organization?: Partial<RolodexOrganization>;
    isRolodexDoc?: boolean;
    updateRolodexDocName?: (newName: string) => Promise<void>;
}

interface State {
    doc: DocDownloadResponse;
    isLoading: boolean;
    showBoxShadow: boolean;
    isRenameDocDialogOpen: boolean;
}

const INITIAL_STATE: State = {
    doc: {
        presignedurl: '',
        suffix: '',
        downloadfilename: '',
        contenttype: ''
    },
    isLoading: false,
    showBoxShadow: false,
    isRenameDocDialogOpen: false,

};

type CombinedProps = Props & StyledProps & InjectedProps;
class DocPreviewDialog extends Component<CombinedProps, State> {
    throttledScrollListener = throttle(
        (element: HTMLDivElement) => this.toggleBoxShadow(element.scrollTop),
        200
    );

    state: State = INITIAL_STATE;

    toggleBoxShadow = (topScroll: number) => {
        const { showBoxShadow } = this.state;

        if (topScroll >= 10 && !showBoxShadow) {
            this.setState({ showBoxShadow: true });
        } else if (showBoxShadow && topScroll < 10) {
            this.setState({ showBoxShadow: false });
        }
    };

    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.setDocInState();
        this.setState({ isLoading: false });
    }

    componentWillUnmount() {
        this.throttledScrollListener.cancel();
    }

    setDocInState = async () => {
        const {
            dispatch,
            docType,
            gatherCase,
            docId,
            userSession,
            activeFuneralHome,
            organization,
            activeFuneralHomeId
        } = this.props;

        let doc: DocDownloadResponse | null = null;

        if (docType === DocType.Rolodex && organization && organization.id) {
            doc = await dispatch(downloadRolodexDoc(docId, organization.id, activeFuneralHomeId));
        } else if (docType === DocType.FuneralHome && userSession && activeFuneralHome) {
            doc = await dispatch(downloadFuneralHomeDoc(userSession, activeFuneralHome, docId));
        } else if (docType === DocType.Case && gatherCase) {
            doc = await dispatch(downloadCaseDoc(gatherCase.uuid, docId));
        }

        if (doc) {
            this.setState({ doc });
        }
    };

    downloadDoc = () => {
        const { doc } = this.state;

        downloadFromURL(doc.presignedurl, doc.downloadfilename);
    };

    /** Rename Doc methods Starts */

    handleRenameDoc = async (docLabel: string) => {
        const { docType, updateRolodexDocName } = this.props;

        if (docType === DocType.Rolodex && updateRolodexDocName) {
            await updateRolodexDocName(docLabel);
        }
        if (docType === DocType.FuneralHome) {
            await this.handleFuneralHomeDocRename(docLabel);
        }
        if (docType === DocType.Case) {
            await this.handleCaseDocRename(docLabel);
        }

        this.setState(prevState => ({ doc: { ...prevState.doc, downloadfilename: docLabel } }));
    };

    handleFuneralHomeDocRename = async (docLabel: string) => {
        const { activeFuneralHome, dispatch, docId } = this.props;

        if (!activeFuneralHome) {
            return;
        }
        await dispatch(updateFuneralHomeDoc(
            docId,
            activeFuneralHome.id,
            { label: docLabel }
        ));
    };
    handleCaseDocRename = async (docLabel: string) => {
        const { dispatch, gatherCase, docId } = this.props;

        if (gatherCase) {
            await dispatch(updateCaseDoc(docId, gatherCase.uuid, { label: docLabel }));
        }
    };

    /** Rename Doc methods Ends */

    closeDialog = () => {
        const { closeDialog } = this.props;

        this.setState(INITIAL_STATE, closeDialog);
    };

    openDocRenameDialog = () => {
        this.setState({ isRenameDocDialogOpen: true });
    };

    renderDocRenameDialog = () => {
        const { zIndex } = this.props;
        const { isRenameDocDialogOpen, doc } = this.state;

        return (
            <DocumentRenameDialog
                key={doc.downloadfilename ? doc.downloadfilename : 'none'}
                isDialogOpen={isRenameDocDialogOpen}
                closeDialog={() => this.setState({ isRenameDocDialogOpen: false })}
                renameDocHandler={this.handleRenameDoc}
                zIndex={zIndex + 1}
                existingName={doc.downloadfilename}
            />
        );
    };

    renderDoc = () => {
        const { classes } = this.props;
        const { doc, isLoading } = this.state;
        const { contenttype, presignedurl } = doc;

        if (isFileTypePdf(contenttype)) {
            return (
                <DocPreview
                    pdfS3URL={presignedurl}
                    hidePageNumber
                    fullWidth
                    scrollListener={this.throttledScrollListener}
                />
            );
        }

        if (isLoading) {
            return (
                <Grid item xs={12} className={classes.loadingContainer}>
                    <CircularProgress variant="indeterminate" />
                </Grid>
            );
        }

        if (isFileTypeImage(contenttype)) {
            return <img src={presignedurl} width="100%" height="auto" />;
        }

        return (
            <Grid container justifyContent="center" alignItems="center" className={classes.errorContainer}>
                <Typography color="secondary" variant="body1" align="center">
                    This file can’t be viewed. Please download and use the appropriate program to view.
                </Typography>
            </Grid>
        );
    };

    render() {
        const { isDialogOpen, zIndex, classes } = this.props;
        const { doc, showBoxShadow } = this.state;
        const { presignedurl, downloadfilename } = doc;

        const boxShadow = '0px 2px 4px -1px rgba(0,0,0,0.2), ' +
            '0px 4px 5px 0px rgba(0,0,0,0.14), ' +
            '0px 1px 10px 0px rgba(0,0,0,0.12)';

        return <>
            <Dialog
                open={isDialogOpen}
                onClose={e => this.closeDialog()}
                className={classes.root}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                classes={{ paper: classes.dialogPaper }}
                style={{ zIndex }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ boxShadow: showBoxShadow ? boxShadow : 'none' }}>
                    <Tooltip title="Click to rename this file" placement="top" enterDelay={200}>
                        <Typography
                            className={classes.filename}
                            color="primary"
                            onClick={e => this.openDocRenameDialog()}
                        >
                            {downloadfilename}
                        </Typography>
                    </Tooltip>

                    <Grid item>
                        {presignedurl &&
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.downloadButton}
                                onClick={e => this.downloadDoc()}
                            >
                                <CloudDownloadIcon color="inherit" />&nbsp;
                                Download
                            </Button>
                        }
                        <ClearIcon
                            color="secondary"
                            className={classes.clearIcon}
                            onClick={e => this.closeDialog()}
                        />
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {this.renderDoc()}
                </DialogContent>
            </Dialog>

            {this.renderDocRenameDialog()}
        </>;
    }
}

export default withDialogMounter<Props>(withState(mapStateToProps)(withStyles(styles)(DocPreviewDialog)));
