import * as classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import ConfigureDialogFooterButton from './ConfigureDialogFooterButton';
import makeGStyles from '../../../styles/makeGStyles';

import { SlideTransition } from '../../common/Transitions';
import { PropsWithChildren, useState } from 'react';
import ConfirmationDialog from '../../common/ConfirmationDialog';

const useStyles = makeGStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        '@media (min-width: 600px)': {
            width: '100%',
            maxWidth: '100% !important',
        },
        '& $dialogPaper': {
            margin: 0,
            height: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                height: '80vh',
                borderRadius: 4
            },
            '@media (min-width: 600px)': {
                maxWidth: 570,
                width: 570,
                height: '80vh',
                borderRadius: 4
            },
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    tabContentContainer: {
        minHeight: 480,
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    scrollButtons: {
        flex: 'none',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '1px solid',
        color: theme.palette.primary.main,
        margin: '0 12px',
        flex: '0 0 auto',
        width: 'calc(100% - 24px)',
    },
    btnDisabled: {
        color: 'rgba(0,0,0,0.12)',
        background: 'rgba(0,0,0,0.12) !important',
        pointerEvents: 'none',
    },
    setContent: {
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    showText: {
        display: 'none',
        '@media (min-width: 375px)': {
            display: 'block'
        }
    },
    dialogPaper: {},
}), { name: 'CustomizedDialogWithFooterButtons' });

interface Props {
    zIndex: number;
    headerTitle: string;
    isDialogOpen: boolean;
    deleteBtnText: string;
    deleteTitle?: string;
    isSaving?: boolean;
    headerIcon?: JSX.Element;
    dialogPaperClass?: string;
    deleteConfirmationBtnText?: string;
    deleteConfirmationDescription?: string;
    handleClose: () => void;
    handleDelete?: () => void;
    handleSave: () => void;
}

interface DialogProps {
    fullScreen: boolean;
}

const CustomizedDialogWithFooterButtons = (props: PropsWithChildren<DialogProps & Props>) => {
    const {
        zIndex,
        isSaving,
        fullScreen,
        headerIcon,
        headerTitle,
        isDialogOpen,
        deleteBtnText,
        dialogPaperClass,
        children,
        deleteTitle,
        deleteConfirmationBtnText,
        deleteConfirmationDescription,
        handleSave,
        handleClose,
        handleDelete,
    } = props;
    const classes = useStyles();
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
    const onConfirm = deleteConfirmationBtnText === undefined ? undefined :
        () => {
            setDeleteConfirmOpen(false);
            handleDelete?.();
        };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={handleClose}
            className={classes.root}
            classes={{
                paper: dialogPaperClass ? dialogPaperClass : classes.dialogPaper
            }}
            style={{ zIndex }}
        >
            <DialogTitle
                className={classNames(
                    classes.dialogHeader,
                    classes.backgroundPrimary
                )}
            >
                <ClearIcon
                    className={classes.clearIcon}
                    onClick={handleClose}
                />
                <Typography
                    component="p"
                    className={classes.heading}
                    align="left"
                >
                    {headerIcon}
                    {headerTitle}
                </Typography>
            </DialogTitle>
            <div className={classes.setContent}>
                {children}

                <ConfigureDialogFooterButton
                    isSaving={isSaving}
                    deleteBtnText={deleteBtnText}
                    handleSave={handleSave}
                    handleClose={handleClose}
                    handleDelete={handleDelete ? () => setDeleteConfirmOpen(true) : undefined}
                />
            </div>
            <ConfirmationDialog
                header={deleteTitle ? deleteTitle : "Are you sure?"}
                subHeader={deleteConfirmationDescription}
                confirmationButtonText={deleteConfirmationBtnText}
                onClose={() => setDeleteConfirmOpen(false)}
                open={isDeleteConfirmOpen}
                onConfirm={onConfirm}
                zIndex={zIndex + 2}
            />
        </Dialog>
    );
};

export default CustomizedDialogWithFooterButtons;
