import {
    postToAPI,
    getFromAPI,
    downloadFromAPI,
    patchAPI,
    deleteFromAPI,
    putToAPI,
    advancedAPIRequest,
} from '.';
import { AppError, isErrorType, registerAppError, handleException } from './errors';
import {
    PaymentRequest,
    Payment,
    CaseTransactions,
    FeeConfiguration,
    FeeChange,
    StripeChargeDetails,
    PaymentMode,
    PaymentReport,
    PaymentRecord,
    PaymentType,
    RevenueReportUX,
    DateType,
    InvoiceRequest,
    LedgerExtractReport,
    PaymentFailure,
    StripeOnboardingResponse,
    PaymentMethod,
} from '../shared/types';
import moment from 'moment';
import { setFuneralHomeDemoSettings, funeralHomeUpdated } from './FuneralHome.action';
import { AppDispatch } from '../store';
import { TokenResult } from '@stripe/stripe-js/types/stripe-js/stripe';
import { log } from '../logger';

export type PAYMENT_PROCESS_STATE_TYPE = 'NOT_STARTED' | 'STARTED' | 'COMPLETE' | 'FAILED';
export const PAYMENT_PROCESS_STATE: Record<PAYMENT_PROCESS_STATE_TYPE, PAYMENT_PROCESS_STATE_TYPE> = {
    NOT_STARTED: 'NOT_STARTED',
    STARTED: 'STARTED',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED'
};

export const SET_PAYMENT_PROCESS_STATE = 'SET_PAYMENT_PROCESS_STATE';
type SET_PAYMENT_PROCESS_STATE = typeof SET_PAYMENT_PROCESS_STATE;

interface SetPaymentProcessState {
    type: SET_PAYMENT_PROCESS_STATE;
    paymentProcessState: PAYMENT_PROCESS_STATE_TYPE;
}

export function setPaymentProcessState(paymentProcessState: PAYMENT_PROCESS_STATE_TYPE): SetPaymentProcessState {
    return { type: SET_PAYMENT_PROCESS_STATE, paymentProcessState };
}

export type STRIPE_TERMINAL_STATE_TYPE = 'NOT_INITIALIZED' | 'INITIALIZING' | 'INITIALIZATION_ERROR' | 'INITIALIZED'
    | 'CONNECTING' | 'CONNECTION_ERROR' | 'CONNECTED';

export const STRIPE_TERMINAL_STATE: Record<STRIPE_TERMINAL_STATE_TYPE, STRIPE_TERMINAL_STATE_TYPE> = {
    NOT_INITIALIZED: 'NOT_INITIALIZED',
    INITIALIZING: 'INITIALIZING',
    INITIALIZATION_ERROR: 'INITIALIZATION_ERROR',
    INITIALIZED: 'INITIALIZED',
    CONNECTING: 'CONNECTING',
    CONNECTION_ERROR: 'CONNECTION_ERROR',
    CONNECTED: 'CONNECTED'
};

export const SET_STRIPE_TERMINAL_STATE = 'SET_STRIPE_TERMINAL_STATE';
type SET_STRIPE_TERMINAL_STATE = typeof SET_STRIPE_TERMINAL_STATE;

interface SetStripeTerminalState {
    type: SET_STRIPE_TERMINAL_STATE;
    terminalState: STRIPE_TERMINAL_STATE_TYPE;
}

function setStripeTerminalState(terminalState: STRIPE_TERMINAL_STATE_TYPE): SetStripeTerminalState {
    return { type: SET_STRIPE_TERMINAL_STATE, terminalState };
}

export type STRIPE_PAYMENT_STATE_TYPE = 'NOT_STARTED' | 'FETCHING_PAYMENT_INTENT'
    | 'INTENT_FAILED' | 'COLLECTING_PAYMENT' | 'COLLECTION_FAILED' | 'COLLECTED'
    | 'PROCESSING_PAYMENT' | 'PROCESSING_FAILED'
    | 'CAPTURING_PAYMENT' | 'CAPTURE_FAILED' | 'CAPTURED';

export const STRIPE_PAYMENT_STATE: Record<STRIPE_PAYMENT_STATE_TYPE, STRIPE_PAYMENT_STATE_TYPE> = {
    NOT_STARTED: 'NOT_STARTED',
    FETCHING_PAYMENT_INTENT: 'FETCHING_PAYMENT_INTENT',
    INTENT_FAILED: 'INTENT_FAILED',
    COLLECTING_PAYMENT: 'COLLECTING_PAYMENT',
    COLLECTION_FAILED: 'COLLECTION_FAILED',
    COLLECTED: 'COLLECTED',
    PROCESSING_PAYMENT: 'PROCESSING_PAYMENT',
    PROCESSING_FAILED: 'PROCESSING_FAILED',
    CAPTURING_PAYMENT: 'CAPTURING_PAYMENT',
    CAPTURE_FAILED: 'CAPTURE_FAILED',
    CAPTURED: 'CAPTURED'
};

export const SET_STRIPE_PAYMENT_STATE = 'SET_STRIPE_PAYMENT_STATE';
type SET_STRIPE_PAYMENT_STATE = typeof SET_STRIPE_PAYMENT_STATE;

interface SetStripePaymentState {
    type: SET_STRIPE_PAYMENT_STATE;
    paymentState: STRIPE_PAYMENT_STATE_TYPE;
}

function setStripePaymentState(paymentState: STRIPE_PAYMENT_STATE_TYPE): SetStripePaymentState {
    return { type: SET_STRIPE_PAYMENT_STATE, paymentState };
}

export function resetStripePaymentState() {
    return setStripePaymentState(STRIPE_PAYMENT_STATE.NOT_STARTED);
}

async function fetchTerminalConnectionToken(dispatch: AppDispatch) {
    const data: { secret: string } | null = await postToAPI<{ secret: string }>(
        'api/finance/stripe_connection_token', {}, dispatch
    );
    return data && data.secret ? data.secret : null;
}

export const SET_STRIPE_DISCOVER_RESULTS = 'SET_STRIPE_DISCOVER_RESULTS';
type SET_STRIPE_DISCOVER_RESULTS = typeof SET_STRIPE_DISCOVER_RESULTS;

interface SetStripeDiscoverResult {
    type: SET_STRIPE_DISCOVER_RESULTS;
    discoverResult: STRIPE_TERMINAL.DiscoverResult;
}

function setStripeDiscoverResult(discoverResult: STRIPE_TERMINAL.DiscoverResult): SetStripeDiscoverResult {
    return {
        type: SET_STRIPE_DISCOVER_RESULTS,
        discoverResult
    };
}

export namespace STRIPE_TERMINAL {
    export type CONNECTION_STATUS = 'connecting' | 'connected' | 'not_connected';
    export type PAYMENT_STATUS = 'not_ready' | 'ready' | 'waiting_for_input' | 'processing';
    export interface StripeConnectOptions {
        fail_if_in_use?: boolean;
    }
    interface CartDetailLineItem {
        description: string;
        amount: number;
        quantity: number;
    }
    export interface CartDetails {
        type: 'cart';
        cart: {
            line_items: CartDetailLineItem[];
            tax?: number;
            total: number;
            currency: string;
        };
    }

    export interface Terminal {
        create: (options: object) => Promise<Terminal>;
        discoverReaders: (options: { location?: string; simulated?: boolean }) => Promise<DiscoverResult>;
        connectReader: (reader: STRIPE_TERMINAL.Reader, options: StripeConnectOptions) => Promise<ConnectResult>;
        collectPaymentMethod: (clientSecret: string) => Promise<CollectResult>;
        processPayment: (paymentIntent: STRIPE_TERMINAL.PaymentIntent) => Promise<ProcessResult>;
        setReaderDisplay: (dispaly: STRIPE_TERMINAL.CartDetails) => Promise<{ message?: string }>;
        clearReaderDisplay: () => Promise<{ message?: string }>;
    }

    export interface Reader {
        id: string;
        object: 'terminal.reader';
        device_sw_version: string | null;
        device_type: string;
        ip_address: string;
        label: string;
        location: string | null;
        serial_number: string;
        status: string;
    }

    interface StripeError {
        message: string;
        code: string;
    }

    export interface PaymentIntent {
        id: number;
        // Lots of other stuff here too, but we don't use it
    }

    type MaybeError = StripeError | null;

    export interface ConnectResult {
        reader: Reader;
        error: MaybeError;
    }

    export interface DiscoverResult {
        discoveredReaders: Reader[];
        error: MaybeError;
    }

    export interface CollectResult {
        paymentIntent: PaymentIntent;
        error: MaybeError;
    }

    export interface ProcessResult extends CollectResult {
    }
}

export const SET_STRIPE_ERROR = 'SET_STRIPE_ERROR';
type SET_STRIPE_ERROR = typeof SET_STRIPE_ERROR;

interface SetStripeError {
    type: SET_STRIPE_ERROR;
    error?: string;
}

function setStripeError(error?: string): SetStripeError {
    return { type: SET_STRIPE_ERROR, error };
}

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
type SET_TRANSACTIONS = typeof SET_TRANSACTIONS;

interface SetTransactions {
    type: SET_TRANSACTIONS;
    caseTransactions: CaseTransactions;
}

function setCaseTransactions(caseTransactions: CaseTransactions): SetTransactions {
    return {
        type: SET_TRANSACTIONS,
        caseTransactions: caseTransactions,
    };
}

let terminal: STRIPE_TERMINAL.Terminal;
export function initializeStripeTerminal(stripeLocation: string | null) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.INITIALIZING));
        try {
            terminal = await (
                (window as unknown as { StripeTerminal: STRIPE_TERMINAL.Terminal }).StripeTerminal).create({
                    onFetchConnectionToken: () => fetchTerminalConnectionToken(dispatch),
                    onUnexpectedReaderDisconnect: (error: { message?: string }) => {
                        const message =
                            `Unexpected disconnect from card reader: ${error.message
                                ? error.message
                                : JSON.stringify(error)}`;
                        dispatch(registerAppError(message));
                        dispatch(setStripeError(message));
                        dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.INITIALIZATION_ERROR));
                    }
                });
        } catch (ex) {
            // Something bad happened while attempting to connect to the terminal
            if (isErrorType(ex)) {
                const message = `An error occurred while initializing the card reader: ${ex.message
                    ? ex.message
                    : JSON.stringify(ex)}`;
                dispatch(registerAppError(message));
                dispatch(setStripeError(message));
                dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.INITIALIZATION_ERROR));
            } else {
                dispatch(handleException({
                    ex,
                    userMessage: 'An error occurred while initializing the card reader'
                }));
            }
        }
        // TODO - get the terminal location identifier from the active funeral home
        const discoverResult = await terminal.discoverReaders({
            location: stripeLocation || undefined,
            simulated: stripeLocation ? false : true,
        });
        if (discoverResult.error) {
            const message = `Failed to discover card readers ${discoverResult.error.message}`;
            dispatch(registerAppError(message));
            dispatch(setStripeError(message));
            dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.INITIALIZATION_ERROR));
        } else if (discoverResult.discoveredReaders.length === 0) {
            const message = 'No available card readers';
            dispatch(setStripeDiscoverResult(discoverResult));
            dispatch(registerAppError(message));
            dispatch(setStripeError(message));
            dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.INITIALIZATION_ERROR));
        } else {
            dispatch(setStripeDiscoverResult(discoverResult));
            dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.INITIALIZED));
        }
    };
}

function checkTerminalConnection(dispatch: AppDispatch, message: string): boolean {
    if (terminal) {
        return true;
    } else {
        const error = `Unable to ${message} with the card reader because the terminal is not initialized`;
        dispatch(registerAppError(error));
        dispatch(setStripeError(error));
        dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.CONNECTION_ERROR));
        return false;
    }
}

export async function setReaderDisplay(display: STRIPE_TERMINAL.CartDetails) {
    if (terminal) {
        try {
            const result = await terminal.setReaderDisplay(display);
            if (result.message) {
                throw (result);
            }
        } catch (ex) {
            log.warn('Error while attempting to set reader display', { ex });
        }
    } else {
        log.warn('Unable to set reader display');
    }
}

export async function clearReaderDisplay() {
    if (terminal) {
        try {
            const result = await terminal.clearReaderDisplay();
            if (result.message) {
                throw (result);
            }
        } catch (ex) {
            log.warn('Error while attempting to clear reader display', { ex });
        }
    } else {
        log.warn('Unable to clear reader display when reader is not initialized');
    }
}
export const SET_STRIPE_SELECTED_READER = 'SET_STRIPE_SELECTED_READER';
type SET_STRIPE_SELECTED_READER = typeof SET_STRIPE_SELECTED_READER;

interface SetStripeSelectedReader {
    type: SET_STRIPE_SELECTED_READER;
    reader: STRIPE_TERMINAL.Reader;
}

function setStripeSelectedReader(reader: STRIPE_TERMINAL.Reader): SetStripeSelectedReader {
    return { type: SET_STRIPE_SELECTED_READER, reader };
}

export function connectStripeReader(reader: STRIPE_TERMINAL.Reader) {
    return async (dispatch: AppDispatch): Promise<void> => {
        if (checkTerminalConnection(dispatch, 'connect')) {
            dispatch(setStripeError());
            dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.CONNECTING));
            dispatch(setStripeSelectedReader(reader));
            const connectResult = await terminal.connectReader(
                reader,
                {
                    fail_if_in_use: true
                });
            if (connectResult.error) {
                const message = `Failed to connect to reader: ${connectResult.error.message}`;
                dispatch(registerAppError(message));
                dispatch(setStripeError(message));
                dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.CONNECTION_ERROR));
            } else {
                dispatch(setStripeTerminalState(STRIPE_TERMINAL_STATE.CONNECTED));
            }
        }
    };
}

type IntentResponse = {
    client_secret: string;
    payment: Payment;
};
const stripeMessageKey = 'message';
const stripeSecondaryMessageKey = 'secondaryMessage';

export function collectStripeTerminalPayment(paymentInfo: PaymentRequest) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setStripeError());
        if (checkTerminalConnection(dispatch, 'collect a payment')) {
            dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.FETCHING_PAYMENT_INTENT));
            const paymentIntent: IntentResponse | null =
                await postToAPI<IntentResponse>('api/finance/stripe_intent', { paymentInfo }, dispatch);
            if (paymentIntent) {
                dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.COLLECTING_PAYMENT));
                const collectResult = await terminal.collectPaymentMethod(paymentIntent.client_secret);
                if (collectResult.error) {
                    dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.COLLECTION_FAILED));
                    const message = `Failed to collect payment: ${collectResult.error.message}`;
                    dispatch(registerAppError(message));
                    dispatch(setStripeError(message));
                } else {
                    dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.PROCESSING_PAYMENT));
                    const processResult = await terminal.processPayment(collectResult.paymentIntent);
                    if (processResult.error) {
                        const message = `Failed to process payment: ${processResult.error.message}`;
                        dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.PROCESSING_FAILED));
                        dispatch(registerAppError(message));
                        dispatch(setStripeError(message));
                    } else {
                        dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.CAPTURING_PAYMENT));
                        const response = await advancedAPIRequest(
                            'api/finance/stripe_capture',
                            'POST',
                            {
                                payment_intent_id: processResult.paymentIntent.id,
                                payment_id: paymentIntent.payment.id,
                                sendNotfication: true,  // currently always send notification
                            },
                            dispatch
                        );
                        const caseTransactions: CaseTransactions = response ? await response.json() : null;
                        const message = caseTransactions
                            && caseTransactions.hasOwnProperty(stripeMessageKey)
                            && caseTransactions[stripeMessageKey] || '';
                        const secondaryMessage = caseTransactions
                            && caseTransactions.hasOwnProperty(stripeSecondaryMessageKey)
                            && caseTransactions[stripeSecondaryMessageKey] || '';
                        if (response && response.ok && caseTransactions) {
                            dispatch(setCaseTransactions(caseTransactions));
                            dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.CAPTURED));
                        } else {
                            dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.CAPTURE_FAILED));
                            dispatch(registerAppError(message, { secondaryMessage }));
                            dispatch(setStripeError(message));
                        }
                    }
                }
            } else {
                const message = 'Failed to initiate payment collection';
                dispatch(setStripePaymentState(STRIPE_PAYMENT_STATE.INTENT_FAILED));
                dispatch(registerAppError(message));
                dispatch(setStripeError(message));
            }
        }
    };
}

export type STRIPE_CHARGE_STATE_TYPE = 'NOT_STARTED' | 'FETCHING_TOKEN'
    | 'TOKEN_FAILED' | 'CHARGING_CARD' | 'CHARGE_FAILED' | 'CHARGED';

export const STRIPE_CHARGE_STATE: Record<STRIPE_CHARGE_STATE_TYPE, STRIPE_CHARGE_STATE_TYPE> = {
    NOT_STARTED: 'NOT_STARTED',
    FETCHING_TOKEN: 'FETCHING_TOKEN',
    TOKEN_FAILED: 'TOKEN_FAILED',
    CHARGING_CARD: 'CHARGING_CARD',
    CHARGE_FAILED: 'CHARGE_FAILED',
    CHARGED: 'CHARGED'
};

export const SET_STRIPE_CHARGE_STATE = 'SET_STRIPE_CHARGE_STATE';
type SET_STRIPE_CHARGE_STATE = typeof SET_STRIPE_CHARGE_STATE;

interface SetStripeChargeState {
    type: SET_STRIPE_CHARGE_STATE;
    chargeState: STRIPE_CHARGE_STATE_TYPE;
}

function setStripeChargeState(chargeState: STRIPE_CHARGE_STATE_TYPE): SetStripeChargeState {
    return { type: SET_STRIPE_CHARGE_STATE, chargeState };
}

export function resetStripeChargeState() {
    return setStripeChargeState(STRIPE_CHARGE_STATE.NOT_STARTED);
}

export function collectStripeOnlinePayment(
    paymentInfo: PaymentRequest,
    stripeTokenResult: TokenResult,
    sendNotification: boolean,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setStripeError());
        dispatch(setStripeChargeState(STRIPE_CHARGE_STATE.FETCHING_TOKEN));

        const token = stripeTokenResult.token;
        if (token) {
            dispatch(setStripeChargeState(STRIPE_CHARGE_STATE.CHARGING_CARD));
            try {
                if (paymentInfo.type === PaymentType.CASE) {
                    const body = { token_id: token.id, paymentInfo, sendNotification };
                    const response = await advancedAPIRequest(
                        'api/finance/stripe_charge', 'POST', body, dispatch
                    );
                    const caseTransactions: CaseTransactions = response ? await response.json() : null;
                    const message = caseTransactions
                        && caseTransactions.hasOwnProperty(stripeMessageKey)
                        && caseTransactions[stripeMessageKey] || '';
                    const secondaryMessage = caseTransactions
                        && caseTransactions.hasOwnProperty(stripeSecondaryMessageKey)
                        && caseTransactions[stripeSecondaryMessageKey] || '';
                    if (!response || !response.ok || !caseTransactions) {
                        throw new AppError(
                            message,
                            response?.status,
                            'Stripe Error',
                            {},
                            false,
                            secondaryMessage
                        );
                    } else {
                        dispatch(setStripeChargeState(STRIPE_CHARGE_STATE.CHARGED));
                        dispatch(setCaseTransactions(caseTransactions));
                    }
                } else if (paymentInfo.type === PaymentType.ONBOARDING) {
                    const body = { token_id: token.id, paymentInfo };
                    const response = await advancedAPIRequest(
                        'api/finance/stripe_onboarding_charge', 'POST', body, dispatch
                    );

                    if (response && response.ok) {
                        const {
                            demoSettings,
                            updatedFuneralHome
                        }: StripeOnboardingResponse = await response.json();

                        dispatch(setStripeChargeState(STRIPE_CHARGE_STATE.CHARGED));
                        dispatch(setFuneralHomeDemoSettings(demoSettings));
                        dispatch(funeralHomeUpdated(updatedFuneralHome));
                    } else {
                        const { message, secondaryMessage }: PaymentFailure = response ? await response.json() : null;
                        throw new AppError(
                            message,
                            response?.status,
                            'Stripe Error',
                            {},
                            false,
                            secondaryMessage
                        );
                    }
                }
            } catch (ex) {
                if (isErrorType(ex)) {
                    const message = ex.message || 'Failed to charge card';
                    let secondaryMessage = undefined;
                    if (ex.secondaryMessage !== undefined) {
                        secondaryMessage = ex.secondaryMessage;
                    }

                    dispatch(setStripeChargeState(STRIPE_CHARGE_STATE.CHARGE_FAILED));
                    dispatch(registerAppError(message, { secondaryMessage }));
                    dispatch(registerAppError(ex, { showSnackbar: false, sendToSentry: true }));
                    dispatch(setStripeError(message));
                } else {
                    dispatch(handleException({ ex, userMessage: 'Unable to charge credit card' }));
                }
            }
        } else {
            const message = 'Card Rejected';
            dispatch(setStripeChargeState(STRIPE_CHARGE_STATE.TOKEN_FAILED));
            dispatch(registerAppError(message, { sendToSentry: true }));
            dispatch(setStripeError(message));
        }
    };
}

export const SET_STRIPE_CHARGE_DETAILS = 'SET_STRIPE_CHARGE_DETAILS';
type SET_STRIPE_CHARGE_DETAILS = typeof SET_STRIPE_CHARGE_DETAILS;

interface SetStripeChargeDetails {
    type: SET_STRIPE_CHARGE_DETAILS;
    details: StripeChargeDetails;
}

function setStripeChargeDetails(details: StripeChargeDetails): SetStripeChargeDetails {
    return {
        type: SET_STRIPE_CHARGE_DETAILS,
        details
    };
}

export function getStripeChargeDetails(paymentId: number) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setStripeChargeDetails({ loading: true }));
        const resource = `api/finance/payment/${paymentId}/stripe_charge`;
        const details = await getFromAPI<StripeChargeDetails>(resource, dispatch);
        if (details) {
            dispatch(setStripeChargeDetails(details));
        } else {
            dispatch(setStripeChargeDetails({ loading: false }));
        }
    };
}

export const SET_PLAID_ERROR = 'SET_PLAID_ERROR';
type SET_PLAID_ERROR = typeof SET_PLAID_ERROR;

interface SetPlaidError {
    type: SET_PLAID_ERROR;
    error?: string;
}

function setPlaidError(error?: string): SetPlaidError {
    return { type: SET_PLAID_ERROR, error };
}

export enum PLAID_CHARGE_STATE {
    NOT_STARTED = 'NOT_STARTED',
    CREATING_LINK = 'CREATING_LINK',
    LINK_CREATED = 'LINK_CREATED',
    AUTHORIZING = 'AUTHORIZING',
    AUTH_FAILED = 'AUTH_FAILED',
    CHARGING = 'CHARGING',
    CHARGE_FAILED = 'CHARGE_FAILED',
    CHARGED = 'CHARGED'
}

export const SET_PLAID_CHARGE_STATE = 'SET_PLAID_CHARGE_STATE';
type SET_PLAID_CHARGE_STATE = typeof SET_PLAID_CHARGE_STATE;

interface SetPlaidChargeState {
    type: SET_PLAID_CHARGE_STATE;
    chargeState: PLAID_CHARGE_STATE;
}

export function setPlaidChargeState(chargeState: PLAID_CHARGE_STATE): SetPlaidChargeState {
    return { type: SET_PLAID_CHARGE_STATE, chargeState };
}

export function resetPlaidChargeState() {
    return setPlaidChargeState(PLAID_CHARGE_STATE.NOT_STARTED);
}

export const SET_PLAID_LINK_TOKEN = 'SET_PLAID_LINK_TOKEN';
type SET_PLAID_LINK_TOKEN = typeof SET_PLAID_LINK_TOKEN;

interface SetPlaidLinkToken {
    type: SET_PLAID_LINK_TOKEN;
    linkToken: string | null;
}

export function setPlaidLinkToken(linkToken: string | null): SetPlaidLinkToken {
    return { type: SET_PLAID_LINK_TOKEN, linkToken };
}

export function createPlaidLinkToken(funeralHomeCaseId: number | null) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.CREATING_LINK));
        try {
            const resource = 'api/finance/plaid_link_token';
            const response = await postToAPI<{ linkToken: string }>(resource, { funeralHomeCaseId }, dispatch);
            if (response?.linkToken) {
                dispatch(setPlaidLinkToken(response.linkToken));
                dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.LINK_CREATED));
            } else {
                throw ({ message: `Unable to get link token for Plaid auth` });
            }
        } catch (error) {
            dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.AUTH_FAILED));
            log.warn('Cannot get plaid link token', { error });
        }
    };
}

export function collectPlaidPayment(
    paymentInfo: PaymentRequest,
    publicToken: string,
    accountId: string,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setPlaidError());
        dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.CHARGING));
        try {
            if (paymentInfo.type === PaymentType.CASE) {
                // Paying for the case with a bank transfer
                const response = await advancedAPIRequest(
                    'api/finance/plaid_charge', 'POST', { publicToken, accountId, paymentInfo }, dispatch
                );
                const caseTransactions: CaseTransactions = response ? await response.json() : null;
                const message = caseTransactions
                    && caseTransactions.hasOwnProperty(stripeMessageKey) && caseTransactions[stripeMessageKey] || '';
                const secondaryMessage = caseTransactions
                    && caseTransactions.hasOwnProperty(stripeSecondaryMessageKey)
                    && caseTransactions[stripeSecondaryMessageKey] || '';

                if (!response || !response.ok || !caseTransactions) {
                    throw new AppError(
                        message,
                        response?.status,
                        'Plaid Error',
                        {},
                        false,
                        secondaryMessage
                    );
                } else {
                    dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.CHARGED));
                    dispatch(setCaseTransactions(caseTransactions));
                }
            } else if (paymentInfo.type === PaymentType.ONBOARDING) {
                // paying for the onboarding fees
                if (!paymentInfo.funeralHomeId) {
                    throw ({ message: 'Unable to charge onboarding fees without funeral home id' });
                }
                const response = await advancedAPIRequest(
                    'api/finance/stripe_onboarding_charge', 'POST', { publicToken, accountId, paymentInfo }, dispatch
                );

                if (response && response.ok) {
                    const {
                        demoSettings,
                        updatedFuneralHome
                    }: StripeOnboardingResponse = await response.json();

                    dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.CHARGED));
                    dispatch(setFuneralHomeDemoSettings(demoSettings));
                    dispatch(funeralHomeUpdated(updatedFuneralHome));
                } else {
                    const { message, secondaryMessage }: PaymentFailure = response ? await response.json() : null;
                    throw new AppError(
                        message,
                        response?.status,
                        'Plaid Error',
                        {},
                        false,
                        secondaryMessage
                    );
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                throw ({ message: `Unhandled payment type '${paymentInfo.type}` });
            }
        } catch (ex) {
            dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.CHARGE_FAILED));
            if (isErrorType(ex)) {
                const message = ex.message || 'Failed to charge account';
                const secondaryMessage = ex.secondaryMessage || undefined;

                dispatch(registerAppError(message, { secondaryMessage }));
                dispatch(registerAppError(ex, { showSnackbar: false, sendToSentry: true }));
                dispatch(setStripeError(message));
            } else {
                dispatch(handleException({ ex, userMessage: 'Failed to charge account' }));
            }
        }
    };
}

export function getEmptyCaseTransactions(): CaseTransactions {
    return {
        isLoading: true,
        funeralHomeCaseId: -1,
        transactions: [],
        payments: [],
        totals: {
            case_uuid: '',
            funeral_home_case_id: -1,
            expense_total: 0,
            collected_total: 0,
            proposed_total: 0,
        },
        feeSchedule: null
    };
}

function resetCaseTransactions(): SetTransactions {
    return {
        type: SET_TRANSACTIONS,
        caseTransactions: getEmptyCaseTransactions()
    };
}

export function addInvoice(caseUuid: string, invoiceRequest: InvoiceRequest) {
    return async (dispatch: AppDispatch): Promise<void> => {
        try {
            InvoiceRequest.fromRequest(invoiceRequest);
        } catch (ex) {
            log.warn('Failed to validate InvoiceRequest', { invoiceRequest, ex });
            return;
        }

        const transactions = await postToAPI<CaseTransactions>(
            `api/finance/invoice/case/${caseUuid}`, { invoiceRequest }, dispatch
        );
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Failed to add invoice'));
        }
    };
}

export function adjustInvoice(params: {
    amount: Dinero.Dinero;
    taxAmount: Dinero.Dinero;
    description: string;
    caseUuid: string;
    invoiceId: number;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const {
            amount,
            taxAmount,
            description,
            caseUuid,
            invoiceId,
        } = params;
        const transactions = await patchAPI<CaseTransactions>
            (`api/finance/invoice/case/${caseUuid}/invoice/${invoiceId}`, { amount, taxAmount, description }, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Failed to adjust invoice'));
        }
    };
}

export function proposePayment(paymentInfo: PaymentRequest) {
    return async (dispatch: AppDispatch): Promise<void> => {
        paymentInfo.method = PaymentMethod.unknown;
        const transactions = await postToAPI<CaseTransactions>
            ('api/finance/payment', { paymentInfo }, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Failed to create proposed payment'));
        }
    };
}

export function proposeEmptyPayments(payments: PaymentRequest[]) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const transactions = await postToAPI<CaseTransactions>
            ('api/finance/payments', { payments }, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Failed to create proposed payments'));
        }
    };
}

export function removePayer(caseUuid: string, payerId: number) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const transactions = await deleteFromAPI<CaseTransactions>
            (`api/finance/case/${caseUuid}/payer/${payerId}`, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Failed to remove payer'));
        }
    };
}

export function deletePayment(params: {
    caseUuid: string;
    paymentId: number;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { caseUuid, paymentId } = params;
        const transactions = await deleteFromAPI<CaseTransactions>
            (`api/finance/case/${caseUuid}/payment/${paymentId}`, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Failed to remove payer'));
        }
    };
}

export type RefundReason = 'duplicate' | 'fraudulent' | 'requested_by_customer';
export function refundPayment(paymentId: number, amount: number, reason: RefundReason, memo: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const transactions = await postToAPI<CaseTransactions>(
            `api/finance/payment/${paymentId}/stripe_refund`, { amount, memo, reason }, dispatch
        );
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError(
                `Error while refunding payer, please contact Gather Support regarding payment id ${paymentId}`));
        }
    };
}

export type PatchPaymentParameters = Parameters<typeof patchPayment>;
export function patchPayment(params: {
    caseUuid: string;
    paymentInfo: PaymentRequest;
    collect: boolean;
    sendNotification: boolean;
}) {
    const { caseUuid, paymentInfo, collect, sendNotification } = params;
    return async (dispatch: AppDispatch): Promise<void> => {
        if (paymentInfo.payment_id) {
            const transactions = await patchAPI<CaseTransactions>(
                `api/finance/case/${caseUuid}/payment/${paymentInfo.payment_id}`,
                { paymentInfo, collect, sendNotification },
                dispatch
            );
            if (transactions) {
                dispatch(setCaseTransactions(transactions));
            } else {
                dispatch(registerAppError('Unable to update payment'));
            }
        } else {
            dispatch(registerAppError('Cannot patch a payment without payment_id'));
        }
    };
}

export function patchPaymentMode(paymentId: number, mode: PaymentMode) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const transactions = await patchAPI<CaseTransactions>
            (`api/finance/payment/${paymentId}/mode`, { mode }, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Unable to update payment'));
        }
    };
}

export function getTransactionsForCase(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        // Clear out any existing transactions before trying to load the next one
        dispatch(resetCaseTransactions());
        const transactions = await getFromAPI<CaseTransactions | null>
            (`api/finance/transaction/case/${caseUuid}`, dispatch);
        if (transactions) {
            dispatch(setCaseTransactions(transactions));
        } else {
            dispatch(registerAppError('Unable to load transactions'));
        }
    };
}

export const SET_EXTRACT_REPORT = 'SET_EXTRACT_REPORT';
type SET_EXTRACT_SUMMARY = typeof SET_EXTRACT_REPORT;

interface SetExtractReport {
    type: SET_EXTRACT_SUMMARY;
    extractReport: LedgerExtractReport;
}

function setExtractReport(extractReport: LedgerExtractReport): SetExtractReport {
    return {
        type: SET_EXTRACT_REPORT,
        extractReport,
    };
}

export function getExtractReport(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<LedgerExtractReport | null> => {
        const extractReport = await getFromAPI<LedgerExtractReport>
            (`api/finance/extract/summary/funeral_home/${funeralHomeId}`, dispatch);
        if (extractReport) {
            dispatch(setExtractReport(extractReport));
            return extractReport;
        } else {
            dispatch(registerAppError('Failed to load the extract report'));
        }
        return null;
    };
}

export function downloadExtractData(extractId: number) {
    return async (dispatch: AppDispatch): Promise<void> => {
        downloadFromAPI(`api/finance/extract/${extractId}`, dispatch);
    };
}

export function closeExtractBatch(funeralHomeId: number, type: 'INVOICE' | 'PAYMENT') {
    return async (dispatch: AppDispatch): Promise<void> => {
        const extractReport = await putToAPI<LedgerExtractReport>('api/finance/extract', {
            type,
            funeralHomeId,
        }, dispatch);
        if (extractReport) {
            dispatch(setExtractReport(extractReport));
            // Download the most recent extract
            if (extractReport.closedBatches.length > 0) {
                dispatch(downloadExtractData(extractReport.closedBatches[0].id));
            } else {
                dispatch(registerAppError('Failed to find newly closed batch'));
            }
        } else {
            dispatch(registerAppError('Failed to close the extract batch'));
        }
    };
}

export const SET_PAYMENT_REPORT = 'SET_PAYMENT_REPORT';
type SET_PAYMENT_REPORT = typeof SET_PAYMENT_REPORT;

interface SetPaymentReport {
    type: SET_PAYMENT_REPORT;
    paymentReport: PaymentReport | null;
}

function setPaymentReport(paymentReport: PaymentReport | null): SetPaymentReport {
    return { type: SET_PAYMENT_REPORT, paymentReport };
}

export function getPaymentReport(
    startDate: Date | null,
    endDate: Date | null,
    funeralHomeId: number
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        // Set the report to null while loading
        dispatch(setPaymentReport(null));
        const startDateStr = (startDate ? startDate : moment().subtract(10, 'years').toDate()).getTime();
        const endDateStr = (endDate ? endDate : moment().add(10, 'years').toDate()).getTime();
        let resource = `api/finance/payment_report/funeralHomeId/${funeralHomeId}`;
        resource = `${resource}/startDate/${startDateStr}/endDate/${endDateStr}`;
        const paymentReport = await getFromAPI<PaymentReport>(resource, dispatch);
        dispatch(setPaymentReport(paymentReport));
    };
}

export const SET_REVENUE_REPORT = 'SET_REVENUE_REPORT';
type SET_REVENUE_REPORT = typeof SET_REVENUE_REPORT;

interface SetRevenueReport {
    type: SET_REVENUE_REPORT;
    revenueReport: RevenueReportUX[] | null;
}

function setRevenuReport(revenueReport: RevenueReportUX[] | null): SetRevenueReport {
    return { type: SET_REVENUE_REPORT, revenueReport };
}

export function getRevenueReport(
    startDate: Date | null,
    endDate: Date | null,
    funeralHomeId: number,
    dateType: DateType,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        // Set the report to null while loading
        dispatch(setRevenuReport(null));
        const startDateStr = (startDate ? startDate : moment().subtract(30, 'days').toDate()).getTime();
        const endDateStr = (endDate ? endDate : moment().add(30, 'days').toDate()).getTime();
        let resource = `api/finance/revenue_report/funeralHomeId/${funeralHomeId}`;
        resource = `${resource}/startDate/${startDateStr}/endDate/${endDateStr}/dateType/${dateType}`;
        const revenueReport = await getFromAPI<RevenueReportUX[]>(resource, dispatch);
        dispatch(setRevenuReport(revenueReport));
    };
}

export const RECONCILE_PAYMENT = 'RECONCILE_PAYMENT';
type RECONCILE_PAYMENT = typeof RECONCILE_PAYMENT;

interface ReconcilePayment {
    type: RECONCILE_PAYMENT;
    paymentId: number;
    isReconciled: boolean;
}

export function reconcilePayment(
    paymentId: number,
    isReconciled: boolean,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RECONCILE_PAYMENT, paymentId, isReconciled });
        await patchAPI<PaymentRecord>(
            `api/finance/payment/${paymentId}/reconciled`,
            { reconciled: isReconciled ? new Date() : null },
            dispatch
        );
    };
}

export const SET_FEE_CONFIGURATION = 'SET_FEE_CONFIGURATION';
type SET_FEE_CONFIGURATION = typeof SET_FEE_CONFIGURATION;

interface SetFeeConfiguration {
    type: SET_FEE_CONFIGURATION;
    feeConfiguration: FeeConfiguration | null;
}

function setFeeConfiguration(feeConfiguration: FeeConfiguration | null): SetFeeConfiguration {
    return {
        type: SET_FEE_CONFIGURATION,
        feeConfiguration
    };
}

export function getFeeConfiguration(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setFeeConfiguration(null));
        const feeConfiguration = await getFromAPI<FeeConfiguration>
            (`api/finance/fee_configuration/funeralHomeId/${funeralHomeId}`, dispatch);
        dispatch(setFeeConfiguration(feeConfiguration));
    };
}

export function saveFeeChanges(funeralHomeId: number, feeChanges: FeeChange[]) {
    return async (dispatch: AppDispatch): Promise<void> => {
        // Set fee config to null until we get the new values back...
        dispatch(setFeeConfiguration(null));
        const feeConfiguration = await patchAPI<FeeConfiguration>
            (`api/finance/fee_configuration/funeralHomeId/${funeralHomeId}`, feeChanges, dispatch);
        dispatch(setFeeConfiguration(feeConfiguration));
    };
}

export type FinanceAction = SetStripeTerminalState
    | SetStripeDiscoverResult | SetStripePaymentState | SetStripeChargeState
    | SetTransactions | SetExtractReport | SetStripeSelectedReader | SetStripeError
    | SetPaymentReport | SetFeeConfiguration | SetStripeChargeDetails
    | SetPlaidError | SetPlaidChargeState | SetPlaidLinkToken | ReconcilePayment | SetRevenueReport;
