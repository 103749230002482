import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Clear from '@mui/icons-material/Clear';
import AppBar from '@mui/material/AppBar/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';

import InvitationForm from '../../invitation/InvitationForm';
import InviteeList from '../../invitation/InviteeList';
import ConfirmationDialog from '../../common/ConfirmationDialog';

import { StoreState } from '../../../types';
import {
    UserRole,
    UserRoles,
    UserInviteStatusEnum,
    EntityCaseRole,
    CaseHelperCreateRequest,
    EntitySummary,
    getCaseRole,
} from '../../../shared/types';
import { resetInvitationStatus, inviteCaseHelper } from '../../../actions/CaseHelper.action';
import {
    HelperInvitationDialogContext,
    closeHelperInvitationDialog,
    openMySettingsDialog
} from '../../../actions/Dialog.action';
import InfoPopper from '../../invitation/InfoPopper';
import {
    canInviteUser,
} from '../../../shared/authority/can';

import HelperPopper from '../../family/helperPopper/HelperPopper';
import { joinNameParts } from '../../../shared/utils';
import { DCEntityEnum } from '../../assignmentPoppers/SelectHelper';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { SlideTransition } from '../../common/Transitions';

function mapStateToProps({
    invitationState,
    casesState,
    userSession,
    dialogState,
    funeralHomeState,
}: StoreState) {
    return {
        invitationState,
        activeCase: casesState.selectedCase,
        userSession,
        invitationDialogState: dialogState.helperInvitation,
        funeralHomeState,
        helpers: casesState.helpers,
    };
}

type Props = ReturnType<typeof mapStateToProps> & {
    dispatch: AppDispatch;
};

type State = {
    isMouseStayOnTabIcon: boolean;
    showSuccessScreen: boolean;
    activeTab: EntityCaseRole;
    firstNameValidator: boolean;
    firstName: string;
    selectedPerson: EntitySummary | null;
    isMenuOpen: boolean;
    menuAnchor?: HTMLElement;
    infoPopperAnchorEle: HTMLElement | null;
    infoPopperContentAnchorEle: HTMLElement | SVGElement | null;
    sendInvite: boolean;
    infoPopperType: EntityCaseRole | null;
    isFormDirty: boolean;
    isInviteDialogCloseConfirmationOpen: boolean;
    helperPopperAnchorEle: HTMLElement | null;
    activeEntityId: number | null;
    showStep2: boolean;
};

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            borderRadius: 0,
            height: '100%',
            maxHeight: '100%',
            '@media (min-width: 960px)': {
                // maxHeight: `calc(100% - 16px)`,
                maxWidth: 840,
                margin: '32px auto',
                height: 'auto',
                minHeight: 820,
                justifyContent: 'start',
            },
            '&$isDCEntity': {
                '@media (min-width: 960px)': {
                    minHeight: 562,
                },
            },
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: '0 0 24px !important',
        overflowX: 'hidden',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        zIndex: 1,
        cursor: 'pointer',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 140,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 1,
        display: 'flex',
        flexDirection: 'row',
        fontSize: 18,
        '& span': {
            color: theme.palette.secondary.main,
            verticalAlign: 'baseline',
        },
    },
    tabAppbar: {
        background: theme.palette.background.paper,
        boxShadow: 'none',
        margin: '32px 0 4px',
        '@media (min-width: 400px)': {
            margin: '4px 0',
        },
    },
    tabsFlexContainer: {
        color: theme.palette.secondary.main,
        justifyContent: 'center',
    },
    tabsScrollableContainer: {
        overflow: 'auto',
        overflowX: 'auto',
    },
    infoIcon: {
        color: theme.palette.secondary.main,
        fontSize: 20,
        verticalAlign: 'middle !important',
    },
    familyView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 100,
        '& p': {
            marginBottom: 2
        },
        '& svg': {
            fontSize: 100,
            color: theme.palette.primary.main,
            marginBottom: 4
        }
    },
    underlineText: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    scrollPaper: {
        display: 'block',
        overflow: 'auto'
    },
    headerTextGrid: {
        padding: '16px 24px',
        '& p': {
            fontSize: 24,
        }
    },
    isDCEntity: {},
    dialogPaper: {},
});

type StyledProps = WithGStyles<'root' | 'dialogPaper' | 'dialogContent' | 'profilePictureAvatar' |
    'logo' | 'headerTextGrid' | 'clearIcon' | 'gridList' | 'emptyAvatar' | 'tabsRoot' | 'tabRoot'
    | 'tabAppbar' | 'tabsFlexContainer' | 'tabsScrollableContainer' | 'underlineText' | 'isDCEntity'
    | 'infoIcon' | 'familyView' | 'scrollPaper'>;

const CustomTab = (props: StyledProps & TabProps) => {
    const { classes, ...others } = props;

    return (
        <Tab
            {...others}
            classes={{
                root: classes.tabRoot,
            }}
        />
    );
};

type CombinedProps = Props & StyledProps;
class Invitation extends React.Component<CombinedProps, State> {

    state: State = {
        isMouseStayOnTabIcon: false,
        firstNameValidator: false,
        firstName: '',
        activeTab: EntityCaseRole.admin,
        isMenuOpen: false,
        menuAnchor: undefined,
        selectedPerson: null,
        infoPopperAnchorEle: null,
        infoPopperContentAnchorEle: null,
        showSuccessScreen: false,
        sendInvite: true,
        infoPopperType: null,
        isFormDirty: false,
        isInviteDialogCloseConfirmationOpen: false,
        helperPopperAnchorEle: null,
        activeEntityId: null,
        showStep2: false
    };

    generateEmptyAvatarList = () => {
        const { helpers, activeCase } = this.props;
        const { activeTab } = this.state;

        const activeTabHelpers = !activeCase ? [] :
            helpers.filter((helper) => getCaseRole(helper, activeCase.id) === activeTab);

        const maxHelperCount = 5;
        return Array.from({ length: maxHelperCount - activeTabHelpers.length }, (v, i) => i);
    };

    componentDidUpdate(prevProps: Props) {
        const { invitationDialogState, userSession, activeCase } = this.props;
        const {
            isOpen,
            defaultTab,
            sendInvite,
        } = invitationDialogState;
        const { userData } = userSession;

        const isFamilyOnCase = userData && activeCase && UserRoles.isFamilyOnCase(userData, activeCase.id);
        if (defaultTab !== prevProps.invitationDialogState.defaultTab) {
            this.setState({
                activeTab: isFamilyOnCase ? EntityCaseRole.guest : defaultTab,
            });
        }
        if (sendInvite !== prevProps.invitationDialogState.sendInvite) {
            this.setState({ sendInvite: sendInvite });
        }
        const bodyElement = document.body;

        if (bodyElement) {
            bodyElement.style.overflow = isOpen && 'hidden' || 'auto';
            bodyElement.style.padding = '0px';
        }
    }

    handleInviteHelper = async (helperRequest: CaseHelperCreateRequest) => {
        const { activeCase, dispatch, invitationDialogState } = this.props;
        const { onAddedCallback } = invitationDialogState;
        if (!activeCase) {
            return null;
        }
        const response = await dispatch(inviteCaseHelper(activeCase.uuid, helperRequest));
        if (response && response.helpers && response.helpers.length > 0 && response.addedEntityId) {
            const { helpers } = response;
            const addedHelper = helpers.find((h) => h.entity_id === response.addedEntityId);
            if (addedHelper && onAddedCallback) {
                onAddedCallback(addedHelper);
            }
            this.setState({
                selectedPerson: addedHelper || null,
            });
        }

        return response;
    };

    handleClose = () => {
        const { invitationDialogState } = this.props;
        const { isFormDirty, showSuccessScreen } = this.state;
        const { relationToDeceased } = invitationDialogState;
        if (isFormDirty && !showSuccessScreen && !relationToDeceased) {
            this.setState({
                isInviteDialogCloseConfirmationOpen: true
            });
            return;
        }
        this.closeDialogAndResetInvitationStatus();
    };

    closeDialogAndResetInvitationStatus = () => {
        this.setState({
            isInviteDialogCloseConfirmationOpen: false,
        });
        const { dispatch, invitationDialogState } = this.props;
        const { defaultTab } = invitationDialogState;
        dispatch(closeHelperInvitationDialog());
        this.setState({
            activeTab: defaultTab,
            showSuccessScreen: false,
            isFormDirty: false,
            showStep2: false
        });

        this.handleResetInvitationStatus();
    };

    handleTabChangeEvent = (event: React.FormEvent<{}>, activeTab: unknown) => {
        const { invitationState } = this.props;

        if (typeof activeTab === 'string' && EntityCaseRole[activeTab]) {
            this.setState({
                activeTab: EntityCaseRole[activeTab],
                showSuccessScreen: false
            });

            if (invitationState.status === UserInviteStatusEnum.success) {
                this.handleResetInvitationStatus();
            }
        }
    };

    switchView = () => {
        const { activeTab } = this.state;
        if (activeTab === EntityCaseRole.admin) {
            this.setState({
                activeTab: EntityCaseRole.guest
            });
        } else {
            this.setState({
                activeTab: EntityCaseRole.admin
            });
        }
    };

    handleResetInvitationStatus = () => {
        const { dispatch } = this.props;
        dispatch(resetInvitationStatus());
    };

    renderFamilyView = () => {
        const { classes, funeralHomeState, activeCase } = this.props;
        if (!activeCase) {
            return null;
        }

        return (
            <Grid item xs={12} className={classes.familyView}>
                <LockIcon />
                <Typography align="center" color="primary">
                    For security reasons, ADMINS can only be invited by&nbsp;
                    {funeralHomeState.activeFuneralHome && funeralHomeState.activeFuneralHome.name}
                </Typography>
                <Typography align="center" color="primary">
                    You can <span className={classes.underlineText} onClick={() => {
                        this.setState({
                            activeTab: EntityCaseRole.guest
                        });
                    }}>invite as a GUEST</span> and then request that&nbsp;
                    <span
                        className={classes.underlineText}
                        onClick={(e) => this.openHelperPopper(e, activeCase.assignee.entity_id)}
                    >
                        {joinNameParts(activeCase.assignee)}
                    </span> upgrade them to an ADMIN.
                </Typography>
            </Grid>
        );
    };

    openHelperPopper = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, entityId: number) => {
        this.setState({
            helperPopperAnchorEle: event.currentTarget,
            activeEntityId: entityId,
        });
    };

    setInvitationFormDirty = (isFormDirty: boolean) => {
        this.setState({
            isFormDirty
        });
    };

    closeInviteDialogConfirmation = () => {
        this.setState({
            isInviteDialogCloseConfirmationOpen: false
        });
    };

    renderCloseDialogConfirmation = () => {
        const { isInviteDialogCloseConfirmationOpen } = this.state;
        const { invitationDialogState } = this.props;
        const { zIndex } = invitationDialogState;
        const confirmText = 'Are you sure you want to close before sending an invite?';
        const confirmButtonText = 'Close Invite Screen';

        return (
            <ConfirmationDialog
                header="Are you sure?"
                subHeader={confirmText}
                confirmationButtonText={confirmButtonText}
                onClose={this.closeInviteDialogConfirmation}
                open={isInviteDialogCloseConfirmationOpen}
                onConfirm={this.closeDialogAndResetInvitationStatus}
                zIndex={zIndex + 1}
            />
        );
    };

    clickAwayListener = (event: MouseEvent | TouchEvent) => {
        this.closeHelperPopper();
    };

    closeHelperPopper = () => {
        this.setState({
            helperPopperAnchorEle: null,
            activeEntityId: null,
        });
    };

    renderHelperPopper = () => {
        const { activeCase, invitationDialogState } = this.props;
        const { zIndex } = invitationDialogState;
        const { helperPopperAnchorEle, activeEntityId } = this.state;
        if (!activeCase) {
            return null;
        }
        return (
            <HelperPopper
                key={activeEntityId}
                zIndex={zIndex + 1}
                clickAwayListener={this.clickAwayListener}
                selectedCase={activeCase}
                activeEntityId={activeEntityId}
                popperAnchorEle={helperPopperAnchorEle}
                closeHelperPopper={this.closeHelperPopper}
                closeParentDialog={this.handleClose}
            />
        );

    };

    renderInfoPopper = () => {
        const { activeCase, invitationDialogState } = this.props;
        const { infoPopperAnchorEle, infoPopperType } = this.state;

        const { zIndex } = invitationDialogState;

        if (!activeCase) {
            return null;
        }

        return (
            <InfoPopper
                zIndex={zIndex + 1}
                popperAnchorEle={infoPopperAnchorEle}
                clickAwayListener={this.infoPopperClickAwayListener}
                caseFName={activeCase.fname}
                infoPopperType={infoPopperType}
            />
        );
    };

    render() {
        const {
            classes,
            invitationState,
            invitationDialogState,
            activeCase,
            userSession,
        } = this.props;
        const {
            isOpen,
            zIndex,
            selectedEntityId,
            relationship,
            relationshipType,
            relationToDeceased,
            context,
            aliveType,
            homeAddressOptional,
        } = invitationDialogState;

        const {
            activeTab,
            showSuccessScreen,
            sendInvite,
            showStep2,
            firstName
        } = this.state;
        const { userData } = userSession;

        if (!activeCase || !userData) {
            return null;
        }

        const canInviteFamilyAdmin = canInviteUser(
            userData, UserRole.User, activeCase.funeral_home.id, activeCase.id, EntityCaseRole.admin,
        );
        const isInformant = relationToDeceased === DCEntityEnum.Informant;
        const headerText = context === HelperInvitationDialogContext.addPreceededInDeath
            ? `Add People who Preceeded ${activeCase.fname} in Death`
            : context === HelperInvitationDialogContext.addSurvivors
                ? 'Add Survivors'
                : (showStep2 || !relationToDeceased)
                    ? `Invite ${firstName}`
                    : `Add ${!isInformant && `${activeCase.fname}'s` || ''} ` +
                    `${!isInformant && relationToDeceased || `${relationToDeceased}'s Name`}`;

        const isObituaryAddPerson = context === HelperInvitationDialogContext.addPreceededInDeath
            || context === HelperInvitationDialogContext.addSurvivors;
        return (
            <>
                <Dialog
                    open={isOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    disableScrollLock
                    onClose={this.handleClose}
                    className={classes.root}
                    classes={{
                        paper: classNames(
                            classes.dialogPaper,
                            (relationToDeceased || isObituaryAddPerson) && !showSuccessScreen && classes.isDCEntity
                        ),
                        scrollPaper: classes.scrollPaper
                    }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    {isOpen && <DialogContent className={classes.dialogContent}>
                        <Clear
                            color="secondary"
                            className={classes.clearIcon}
                            onClick={this.handleClose}
                        />
                        {(!showSuccessScreen && !relationToDeceased && !isObituaryAddPerson) &&
                            <>
                                <AppBar position="static" className={classes.tabAppbar}>
                                    <Tabs
                                        value={activeTab}
                                        onChange={this.handleTabChangeEvent}
                                        classes={{
                                            flexContainer: classes.tabsFlexContainer,
                                            scrollableX: classes.tabsScrollableContainer
                                        }}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        indicatorColor="primary"
                                        textColor="primary"
                                    >
                                        <CustomTab
                                            key={EntityCaseRole.admin}
                                            value={EntityCaseRole.admin}
                                            label={
                                                <>
                                                    Invite Admin&nbsp;
                                                    <Icon
                                                        className={classes.infoIcon}
                                                        onMouseLeave={e => this.handleMouseLeaveOnInfoPopper()}
                                                        onMouseEnter={e =>
                                                            this.openInfoPopper(e, EntityCaseRole.admin)
                                                        }
                                                        onMouseDown={e =>
                                                            this.openInfoPopper(e, EntityCaseRole.admin)
                                                        }
                                                    >
                                                        <InfoIcon fontSize="inherit" />
                                                    </Icon>
                                                </>
                                            }
                                            classes={classes}
                                        />
                                        <CustomTab
                                            key={EntityCaseRole.guest}
                                            value={EntityCaseRole.guest}
                                            label={
                                                <>
                                                    Add Guest&nbsp;
                                                    <Icon
                                                        className={classes.infoIcon}
                                                        onMouseLeave={e => this.handleMouseLeaveOnInfoPopper()}
                                                        onMouseEnter={e =>
                                                            this.openInfoPopper(e, EntityCaseRole.guest)
                                                        }
                                                        onMouseDown={e =>
                                                            this.openInfoPopper(e, EntityCaseRole.guest)
                                                        }
                                                    >
                                                        <InfoIcon fontSize="inherit" />
                                                    </Icon>
                                                </>
                                            }
                                            classes={classes}
                                        />
                                    </Tabs>
                                </AppBar>
                                <InviteeList
                                    emptyAvatarList={this.generateEmptyAvatarList()}
                                    activeTab={activeTab}
                                    selectedCase={activeCase}
                                    closeParentDialog={this.handleClose}
                                    zIndex={zIndex + 1}
                                />

                            </>
                            || !showSuccessScreen && <>
                                <Grid item xs={12} className={classes.headerTextGrid}>
                                    <Typography color="primary" component="p">
                                        {headerText}
                                    </Typography>
                                </Grid>
                            </>
                        }

                        {(!canInviteFamilyAdmin && activeTab === EntityCaseRole.admin)
                            && this.renderFamilyView()}

                        {((canInviteFamilyAdmin && activeTab === EntityCaseRole.admin)
                            || (canInviteFamilyAdmin && activeTab === EntityCaseRole.guest) ||
                            (!canInviteFamilyAdmin && activeTab === EntityCaseRole.guest)) &&
                            isOpen &&
                            <InvitationForm
                                zIndex={zIndex + 1}
                                showSuccessScreen={showSuccessScreen}
                                toggleSuccessScreen={() => this.setState((prevState) =>
                                    ({ showSuccessScreen: !showSuccessScreen }))}
                                similarUsers={invitationState.similarUsers}
                                selectedEntityId={selectedEntityId}
                                onInvite={this.handleInviteHelper}
                                status={invitationState.status}
                                formType={activeTab === EntityCaseRole.admin
                                    ? 'INVITE_ADMIN'
                                    : 'INVITE_GUEST'}
                                editHelper={this.toggleMySettingsDialog}
                                resetInvitationStatus={this.handleResetInvitationStatus}
                                activeCase={activeCase}
                                handleMouseLeaveOnInfoPopper={this.handleMouseLeaveOnInfoPopper}
                                openInfoPopper={this.openInfoPopper}
                                entityRole={activeTab}
                                initialSendInvite={sendInvite}
                                switchView={this.switchView}
                                canInviteFamilyAdmin={canInviteFamilyAdmin}
                                setInvitationFormDirty={this.setInvitationFormDirty}
                                closeParentDialog={this.handleClose}
                                entityRelationship={relationship}
                                entityRelationshipType={relationshipType}
                                relationToDeceased={relationToDeceased}
                                showStep2={showStep2}
                                context={context || null}
                                aliveType={aliveType}
                                toggleStep2={() => this.setState((prevState) =>
                                    ({ showStep2: !prevState.showStep2 }))}
                                setFirstName={(value) => this.setState({ firstName: value })}
                                homeAddressOptional={homeAddressOptional}
                            />
                        }
                    </DialogContent>}
                </Dialog>

                {this.renderInfoPopper()}
                {this.renderCloseDialogConfirmation()}
                {this.renderHelperPopper()}
            </>
        );
    }

    infoPopperClickAwayListener = (event: MouseEvent | TouchEvent) => {
        const { infoPopperAnchorEle } = this.state;
        const currentTarget = event.target as HTMLElement;

        if (currentTarget !== infoPopperAnchorEle) {
            this.setState({ infoPopperAnchorEle: null });
        }
    };

    openInfoPopper = (event: React.MouseEvent<HTMLElement>, infoPopperType: EntityCaseRole) => {
        const currentTarget = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isMouseStayOnTabIcon: true,
            infoPopperType
        });
        setTimeout(
            () => {
                if (this.state.isMouseStayOnTabIcon) {
                    this.setState({ infoPopperAnchorEle: currentTarget });
                }
            },
            400
        );
    };

    handleMouseLeaveOnInfoPopper = () => {
        this.setState({
            infoPopperAnchorEle: null,
            infoPopperContentAnchorEle: null,
            isMouseStayOnTabIcon: false,
        });
    };

    private toggleMySettingsDialog = () => {
        const { dispatch, invitationDialogState } = this.props;
        const { selectedPerson } = this.state;
        const { zIndex } = invitationDialogState;

        if (selectedPerson) {
            dispatch(openMySettingsDialog(zIndex + 1, selectedPerson.entity_id));
        }
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(Invitation));
