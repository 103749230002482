import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useBulkEditStyles = makeStyles((theme: Theme) => ({
    selectMenuForm: {
        margin: '12px 0px',
        textAlign: 'left',
        minWidth: 200,
    },
    selectMenu: {
        display: 'flex',
        columnGap: '8px',
        '&:focus': {
            background: 'transparent',
        },
    },
}), { name: 'BulkEditStyles' });
