import classNames from 'classnames';

import Typography from '@mui/material/Typography';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanLoginProps } from '../utils';
import CanLoginWrapper from '../widgets/CanLoginWrapper';

const CanLogin = (props: CanLoginProps) => {
    const { accountType, activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanLoginWrapper
            accountType={accountType}
            caseFName={activeCase.fname}
            onStepChange={onStepChange}
        >
            <Typography
                color="secondary"
                align="left"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Since you have the ability to log in to {activeCase.fname}'s Hulu account, this process will
                be much faster. Simply follow the steps below to close {activeCase.fname}'s account and
                remove any active subscriptions.
            </Typography>

            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    <a
                        href="https://auth.hulu.com/find_account"
                        target="blank"
                        className={classes.colorSecondary}
                    >
                        Click here
                    </a>
                    {` to delete ${activeCase.fname}'s account (make sure to log in as ${activeCase.fname})`}
                    <ul
                        className={
                            classNames(
                                classes.fontSize18,
                                classes.colorSecondary,
                                classes.textLeft,
                                classes.fontWeight300
                            )
                        }
                    >
                        <li className={classes.marginTop10}>
                            To close a free account, click "Update" next to {activeCase.fname}'s Personal Info then
                            click "Delete my free Hulu account"
                        </li>
                        <li className={classes.marginTop10}>
                            To remove a subscription so that you are no longer charged, click "Cancel Subscription"
                            and continue through the steps
                        </li>
                    </ul>
                </li>
            </ul>
            <Typography
                color="secondary"
                align="left"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop20
                )}
            >
                {'If you have any questions, '}
                <a
                    href={'tel:888-755-7907'}
                    className={classes.colorSecondary}
                >
                    {'click here'}
                </a>
                {' to call Hulu directly (888-755-7907)'}
            </Typography>
        </CanLoginWrapper>
    );
};

export default CanLogin;
