import { useMemo } from "react";
import {
    getGridNumericOperators,
    GridCellParams,
    GridFilterItem,
} from '@mui/x-data-grid-premium';

export const priceAggregateFunctions = ['sum', 'min', 'max', 'size', 'avg'];

const priceOperators = getGridNumericOperators()
    .filter(({ value }) => [
        '=',
        '!=',
        '>',
        '>=',
        '<',
        '<='
    ].indexOf(value) !== -1);

export const getNumericFilterOperators = <T>() =>
    priceOperators.map((operator) => {
        switch (operator.value) {
            case '=':
                return {
                    ...operator, label: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (Number(params.value) >= 0) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue === Number(filterItem.value);
                            }
                            return false;
                        };
                    },
                };
            case '!=':
                return {
                    ...operator, label: 'is not',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (Number(params.value) >= 0) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue !== Number(filterItem.value);
                            }
                            return false;
                        };
                    },
                };
            case '>':
                return {
                    ...operator, label: 'greater than',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (Number(params.value) >= 0) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue > filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '<':
                return {
                    ...operator, label: 'less than',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (Number(params.value) >= 0) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue < filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '>=':
                return {
                    ...operator, label: 'greater than or equals',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (Number(params.value) >= 0) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue >= filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '<=':
                return {
                    ...operator, label: 'less than or equals',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (Number(params.value) >= 0) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue <= filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            default:
                return operator;
        }
    });

const usePriceFilterOperators = () => {
    return useMemo(() => getNumericFilterOperators(), []);
};

export default usePriceFilterOperators;
