import React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { EntityCaseRole, EntitySummary, getCaseEntity } from '../../../../shared/types/user';
import OverlappedAvatars, { OverlappedAvatar } from '../../../common/OverlappedAvatars';
import { GuestPopperUserType } from '../guests/GuestPopper';
import { getRelationshipDisplayValue } from '../../../common/AcquaintanceButtons';
import { openGuestPopper } from '../../../../actions/Dialog.action';
import FamilyAndFriendDetailDialog from './dialogs/FamilyAndFriendDetail.dialog';
import { AppDispatch } from '../../../../store';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withState from '../../../common/utilHOC/WithState';
import { GStyles } from '../../../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 68,
        flexDirection: 'column'
    },
    privateEventDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            fontSize: 9,
            color: theme.palette.primary.main
        }
    },
    peopleView: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
    },
    outerContainer: {
        overflowX: 'auto',
        overflowY: 'hidden',
        maxWidth: 'fit-content',
        width: '100%',
        padding: '0 16px',
        height: 'inherit',
    },
    mainContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    avatarWrapper: {
        position: 'relative',
        pointerEvents: 'auto'
    },
    inviteButton: {
        width: '48px !important',
        height: '48px !important',
    }
});

type StyledProps = WithStyles<'root' | 'privateEventDetails' | 'peopleView' | 'outerContainer'
    | 'mainContainer' | 'avatarWrapper' | 'inviteButton'>;

interface Props {
    dispatch: AppDispatch;
    invitedHelpers: EntitySummary[];
    zIndex: number;
    openInvitationDialog: (currentTab?: EntityCaseRole | undefined) => void;
    hasCasePrivileges: boolean;
    activeCaseId: number | null;
    caseDisplayFname: string;
    hideAbstarctInfo?: boolean;
    showGuestPopper?: boolean;
    inviteButtonClass?: string;
    avatarClass?: string;
    lastAvatarClass?: string;
}

interface State {
    isFamilyAndFriendDialogOpen: boolean;
}

type CombinedProps = StyledProps & Props;
class PrivateEventUserWidget extends React.Component<CombinedProps, State> {
    state: State = {
        isFamilyAndFriendDialogOpen: false,
    };

    renderFamilyAndFriendDialog = () => {
        const { invitedHelpers, openInvitationDialog, zIndex } = this.props;
        const { isFamilyAndFriendDialogOpen } = this.state;

        return (
            <FamilyAndFriendDetailDialog
                zIndex={zIndex}
                isDialogOpen={isFamilyAndFriendDialogOpen}
                closeDialog={this.closeFamilyAndFriendDialog}
                openInvitationDialog={openInvitationDialog}
                invitedHelpers={invitedHelpers}
            />
        );
    };

    render() {
        const {
            classes,
            invitedHelpers,
            hasCasePrivileges,
            hideAbstarctInfo,
            openInvitationDialog,
            showGuestPopper,
            inviteButtonClass,
            avatarClass,
            lastAvatarClass,
        } = this.props;

        const invitedHelpersLength = invitedHelpers.length;

        return (
            <Grid item xs={12} className={classes.root}>
                {!hideAbstarctInfo && <div className={classes.privateEventDetails}>
                    <Typography align="center" color="secondary">
                        Invite Only
                    </Typography>&nbsp;
                    <FiberManualRecordIcon />&nbsp;
                    <Typography
                        align="center"
                        color="secondary"
                        className={classes.peopleView}
                        onClick={this.openFamilyAndFriendDialog}
                    >
                        {invitedHelpersLength} {(invitedHelpersLength === 0 || invitedHelpersLength === 1)
                            ? 'Person' : 'People'} Can View
                    </Typography>
                </div>}

                <div className={classes.outerContainer}>
                    <div className={classes.mainContainer}>
                        <OverlappedAvatars
                            users={invitedHelpers.filter(
                                (helper: EntitySummary): helper is EntitySummary & { user_id: number } =>
                                helper.user_id !== null
                            )}
                            onAvatarClick={showGuestPopper && this.openGuestPopper || undefined}
                            viewAllUsersButtonProps={{
                                onClick: this.openFamilyAndFriendDialog
                            }}
                            onInviteButtonClick={() => openInvitationDialog(EntityCaseRole.guest)}
                            avatarClasses={{
                                avatar: classNames(showGuestPopper && GStyles.cursorPointer || undefined,
                                    avatarClass),
                                avatarWrapper: hasCasePrivileges && classes.avatarWrapper || undefined,
                                inviteButton: classNames(classes.inviteButton, inviteButtonClass),
                                lastAvatar: lastAvatarClass || undefined,
                            }}
                            inviteButtonProps={{
                                variant: 'fab',
                                buttonPosition: 'end'
                            }}
                        />
                    </div>
                </div>
                {this.renderFamilyAndFriendDialog()}
            </Grid>
        );
    }

    closeFamilyAndFriendDialog = () => {
        this.setState({
            isFamilyAndFriendDialogOpen: false
        });
    };

    openFamilyAndFriendDialog = () => {
        this.setState({
            isFamilyAndFriendDialogOpen: true
        });
    };

    openGuestPopper = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        user: OverlappedAvatar
    ) => {
        event.persist();
        const target = event.currentTarget;

        const { activeCaseId, caseDisplayFname, dispatch, zIndex, invitedHelpers } = this.props;

        const userEntity = invitedHelpers.find(h => h.fname === user.fname && h.lname === user.lname);

        const caseEntity = activeCaseId && getCaseEntity(userEntity || null, activeCaseId);
        if (!caseEntity || !user.user_id) {
            return;
        }

        const relationship = getRelationshipDisplayValue(caseEntity, true);
        const guest: GuestPopperUserType = {
            fname: user.fname,
            entity_id: user.entity_id,
            user_id: user.user_id,
            relationship: caseDisplayFname && relationship
                ? `${caseDisplayFname}'s ${relationship}`
                : '',
            photo: user.photo || null,
            photo_transformations: user.photo_transformations || null,
            case_entity_id: caseEntity.case_entity_id
        };

        dispatch(openGuestPopper({
            zIndex: zIndex + 1,
            activeGuest: guest,
            getTarget: () => target
        }));
    };
}

export default withState()(withStyles(styles)(PrivateEventUserWidget));
