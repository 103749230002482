import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import FacebookIcon from '@mui/icons-material/Facebook';

import classNames from "classnames";
import { getRememberURLForSharing } from "../../../../../services";
import { GStyles } from "../../../../../styles/GStyles";
import PhotoHanger from "../PhotoHanger";
import HangingPhotosUploadButton, { HangingPhotosUploadCallback } from "./HangingPhotos.uploadButton";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        '& > div:first-of-type': {
            zIndex: 1,
            marginRight: 48,
            position: 'relative',
            height: 'fit-content',
        },
        '& > div:last-child': {
            zIndex: 0,
            height: 'fit-content',
            marginLeft: -16,
            '&$onePhoto': {
                '@media (min-width: 768px)': {
                    transform: 'translateY(13%)',
                }
            },
            '@media (min-width: 768px)': {
                marginLeft: 0
            },
            '@media (min-width: 768px) and (max-width: 959px)': {
                transform: 'translateY(-28%)',
            },
            '@media (min-width: 960px)': {
                transform: 'translateY(10px)'
            },
        },
    },
    fbButtonContainer: {
        '&$onePhoto': {
            transform: 'translateY(60%)',
            '@media (min-width: 425px)': {
                transform: 'translateY(14%)',
            },
            '& $photoString': {
                '@media (min-width: 425px) and (max-width: 959px)': {
                    height: 102
                }
            }
        },
        '& $photoString': {
            height: 36,
            '@media (min-width: 768px)': {
                height: 102
            },
        }
    },
    facebookButton: {
        height: 110,
        width: 120,
        textTransform: 'none',
        padding: '6px 12px',
        backgroundColor: '#1e497e',
        boxShadow: '0 2px 8px 2px rgb(0 0 0 / 35%), -60px 44px 64px rgb(0 0 0 / 15%), 0 4px 44px rgb(0 0 0 / 25%)',
        '&:hover': {
            backgroundColor: '#153358',
        },
        '& p': {
            fontWeight: 300,
            marginBottom: 8,
            fontSize: 16,
            lineHeight: '1.25em',
            color: theme.palette.common.white
        },
        '& svg': {
            fontSize: 28,
            color: theme.palette.common.white
        }
    },
    flexColumnCentered: {
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center'
    },
    photoString: {},
    onePhoto: {}
}), { name: 'RightSection' });

interface Props {
    caseFName: string;
    caseName: string;
    casePhotosCount: number;
    disabledFbButton: boolean;
    onPhotosUpload: HangingPhotosUploadCallback;
}
const RightSection = (props: Props) => {
    const { caseFName, caseName, casePhotosCount, disabledFbButton, onPhotosUpload } = props;

    const classes = useStyles();

    const webLink = encodeURI(getRememberURLForSharing(caseName));
    const webComp = encodeURIComponent(webLink);
    const fbShareLink = `http://www.facebook.com/sharer.php?u=${webComp}`;

    return (
        <Grid
            className={classNames(
                classes.root,
                casePhotosCount >= 1 && classes.onePhoto
            )}
        >
            <HangingPhotosUploadButton
                largeSize
                caseFName={caseFName}
                casePhotosCount={casePhotosCount}
                onPhotosUpload={onPhotosUpload}
            />

            <div
                className={classNames(
                    classes.flexColumnCentered,
                    classes.fbButtonContainer,
                    casePhotosCount >= 1 && classes.onePhoto,
                )}
            >
                <PhotoHanger stringHeight={0} stringClass={classes.photoString} />

                <Button
                    variant="contained"
                    tabIndex={disabledFbButton ? -1 : undefined}
                    className={classNames(classes.facebookButton, disabledFbButton && GStyles.pointerEventsNone)}
                    classes={{ root: classes.flexColumnCentered }}
                    onClick={e => window.open(fbShareLink)}
                >
                    <Typography color="primary">
                        {disabledFbButton ? 'Thank You for Sharing' : 'Share This Page'}
                    </Typography>
                    {!disabledFbButton && <FacebookIcon />}
                </Button>
            </div>
        </Grid>
    );
};

export default RightSection;