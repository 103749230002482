import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useCompleteStepStyles } from './dialogContent.styles';
import GButton from '../../../../common/GButton';
import { TaskUserType, UserProfile } from '../../../../../shared/types';
import { getIntercomTargetProp, joinNameParts } from '../../../../../services';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Props {
    hasError: boolean;
    user: UserProfile;
    completedBy?: TaskUserType | null;
    skippedBy?: TaskUserType | null;
    performedBy?: TaskUserType | null;
    stepTitle: string;
    isSaving: boolean;
    isSkipStep?: boolean;
    isStepSharedWithFamily?: boolean;
    onCompleteTaskClick: () => void;
    rootClass?: string;
    intercomTargetProp?: string;
    intercomTargetPropVisibility?: string;
    intercomTargetPropMarkedComplete?: string;
    intercomTargetPropPerformedBy?: string;
    intercomTargetPropAllStepsCustomizable?: string;
}
const CompleteStepDialogFooter = (props: Props) => {
    const {
        user,
        stepTitle,
        performedBy,
        hasError,
        isSaving,
        isSkipStep,
        completedBy,
        skippedBy,
        isStepSharedWithFamily,
        onCompleteTaskClick,
        rootClass,
        intercomTargetProp,
        intercomTargetPropVisibility,
        intercomTargetPropMarkedComplete,
        intercomTargetPropPerformedBy,
        intercomTargetPropAllStepsCustomizable,
    } = props;
    const classes = useCompleteStepStyles();

    const isStepFinished = Boolean(skippedBy || completedBy);

    const buttonText = isStepFinished && !isSaving
        ? 'Close This Window'
        : `${isSkipStep ? 'Skip' : 'Complete'} "${stepTitle}"`;

    return (
        <Grid container={true} direction="column" alignItems="center" className={classNames(classes.footerContainer,
            rootClass ? rootClass : undefined)}
        >
            <Grid item={true} xs={12} className={classes.completeSectionsText}>
                {hasError && !isSaving &&
                    <Typography align="center" className={classNames(classes.fontSize12, classes.colorRed)}>
                        Please complete the red sections above.
                    </Typography>
                }
            </Grid>

            <div className={classes.marginBottom12}>
                <GButton
                    text={buttonText}
                    isSpinning={isSaving}
                    onClick={onCompleteTaskClick}
                    disabled={hasError}
                    intercomTargetProp={intercomTargetProp}
                />
            </div>

            {isStepSharedWithFamily !== undefined &&
                <Typography
                    align="center"
                    className={classNames(
                        isStepSharedWithFamily ? classes.colorOrange : classes.colorSecondary,
                        classes.flexCentred
                    )}
                    {...getIntercomTargetProp(intercomTargetPropVisibility)}

                >
                    {isStepSharedWithFamily ?
                        <><Visibility fontSize="small" />&nbsp;Visible to select family members.
                        </>
                        : <><VisibilityOff fontSize="small" />&nbsp;Not visible to family members.</>
                    } NOT USUALLY SHARED.
                </Typography>
            }

            <Typography
                color="secondary"
                align="center"
                className={classes.paragraphLineHeight}
            >
                {isSkipStep
                    ? `Step Skipped by ${joinNameParts(skippedBy ?? user)}`
                    : <>
                        <span
                            {...getIntercomTargetProp(intercomTargetPropMarkedComplete)}
                        >Step marked complete by {joinNameParts(completedBy ?? user)}</span><br />
                        <span
                            {...getIntercomTargetProp(intercomTargetPropPerformedBy)}
                        >{performedBy && `Step performed by ${joinNameParts(performedBy)}`}</span><br />
                        <span
                            {...getIntercomTargetProp(intercomTargetPropAllStepsCustomizable)}
                        >FYI: All Tracking Steps are fully customizable!</span>
                    </>
                }
            </Typography >

        </Grid >
    );
};

export default CompleteStepDialogFooter;
