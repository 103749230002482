import Transition, { TransitionProps } from 'react-transition-group/Transition';

type Props = TransitionProps & {
    rootClass: string;
};

export const HangingPhotosAnimation = (props: Props) => {
    const { children, photoId, rootClass, ...others } = props;

    const defaultTransition = 'transform 1200ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, ' +
        'max-width 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

    const exitingTransition = 'transform 1200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, ' +
        'max-width 900ms cubic-bezier(0.4, 0, 0.2, 1) 300ms,';

    const ZoomAnimationStyle = {
        transition: defaultTransition,
        transform: 'scale(0)',
        maxWidth: 0,
    };

    const TransitionStyles = {
        entering: {
            transform: 'scale(1)',
        },
        entered: {
            transform: 'scale(1)',
            maxWidth: '100%',
        },
        exiting: {
            transition: exitingTransition,
            transform: 'scale(0)',
            maxWidth: 0,
        }
    };

    return (
        <Transition
            in
            onEntering={(node) => node.style.maxWidth = `${node.scrollWidth}px`}
            {...others}
        >
            {(state) => (
                <div
                    style={{
                        ...ZoomAnimationStyle,
                        ...TransitionStyles[state],
                        width: 'fit-content',
                        margin: 'auto'
                    }}
                    className={rootClass}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};