import { Component } from 'react';
import classNames from 'classnames';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { WithStyles } from '@mui/styles';

import { LongAddress, NullLongAddress, RolodexContact, GmapsSearchType, isLongAddress } from '../../../shared/types';
import PhoneNumberMask from '../../common/PhoneNumberMask';
import GmapsSearch, { GmapsSearchAddress } from '../../gmapsSearch/GmapsSearch';
import { toPairs } from 'lodash';

const REQUIRED_CONTACT_KEYS = [
    'title',
    'org_role',
    'fname',
    'mname',
    'lname',
    'email',
    'phone',
    'fax_number',
    'longAddress',
    'useAddressDescription'
];
const isKeyOfContact = (key: string): key is keyof RolodexContact => (
    REQUIRED_CONTACT_KEYS.indexOf(key) !== -1
);

type StyledProps = WithStyles<
    'contactSection' | 'label' | 'contactDetailSection' | 'width40' | 'marginTop8' |
    'width100' | 'textLeft' | 'margin8' | 'organizationFooterSection' | 'widthInherit' |
    'contactFooterButton' | 'marginLeftRight8' | 'formControlStyles' | 'gmapSearch' |
    'controlSuggestionClass' | 'nameLabel' | 'nameLabelFocused'
>;

interface ContactDataProps {
    id: number;
    title: string | null;
    org_role: string | null;
    fname: string;
    mname: string | null;
    lname: string;
    email: string | null;
    phone: string | null;
    fax: string | null;
    longAddress: LongAddress | null;
    useDescription: boolean;
}

interface RequiredProps {
    entryKey: number;
    zIndex: number;
    deleteHandler: (id: number) => void;
}

type OptionalProps = Partial<{
    fieldChangeEventHandler: (
        entryKey: number,
        fieldName: keyof RolodexContact,
        value: string | LongAddress | boolean | null
    ) => void;
}>;

// all incoming contact data is optional
type ContactEntryProps = RequiredProps & OptionalProps & Partial<ContactDataProps> & StyledProps;

interface ContactEntryState {
    title: string;
    org_role: string;
    fname: string;
    mname: string;
    lname: string;
    longAddress: LongAddress | null;
    useAddressDescription: boolean;
    email: string;
    phone: string;
    fax_number: string;
}

class ContactEntry extends Component<ContactEntryProps, ContactEntryState> {
    state = {
        title: this.props.title || '',
        org_role: this.props.org_role || '',
        fname: this.props.fname || '',
        mname: this.props.mname || '',
        lname: this.props.lname || '',
        longAddress: this.props.longAddress || null,
        email: this.props.email || '',
        phone: this.props.phone || '',
        fax_number: this.props.fax || '',
        useAddressDescription: this.props.useDescription || false
    };

    resetInputFields = (fullReset: boolean = true) => {
        const emptyContact = {
            title: '',
            org_role: '',
            fname: '',
            mname: '',
            lname: '',
            longAddress: null,
            email: '',
            phone: '',
            fax_number: '',
            useAddressDescription: false
        };

        if (fullReset) {
            for (const [key, value] of toPairs(emptyContact)) {
                if (isKeyOfContact(key)) {
                    this._updateStateField(key, value);
                }
            }
        }
    };

    render() {
        const { classes, deleteHandler, entryKey, zIndex } = this.props;
        const {
            title,
            org_role,
            fname: firstName,
            mname: middleName,
            lname: lastName,
            longAddress: address,
            email,
            phone,
            fax_number: fax,
            useAddressDescription: useDescription
        } = this.state;

        return (
            <Grid item xs={12} className={classes.contactSection}>
                <Grid item className={classes.label}>
                    <Typography color="primary" align="center">
                        Contact
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.contactDetailSection}>
                    <Grid item className={classNames(classes.width40, classes.marginTop8)}>
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                        >
                            <TextField
                                label={`${(firstName && lastName) ? `${firstName} ${lastName}'s` : ''} Title`}
                                name="contact-title"
                                value={title}
                                className={classes.width100}
                                onChange={(e) => this._updateStateField('title', e.target.value)}
                                autoComplete="contact-title"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={classNames(classes.width40, classes.marginTop8)}>
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                        >
                            <TextField
                                label={`${(firstName && lastName) ? `${firstName} ${lastName}'s` : ''} Role`}
                                name="contact-name-role"
                                value={org_role}
                                className={classes.width100}
                                onChange={(e) => this._updateStateField('org_role', e.target.value)}
                                autoComplete="contact-name-role"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.contactDetailSection}>
                    <Grid item className={classNames(classes.width40, classes.marginTop8)}>
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                        >
                            <TextField
                                label="First Name"
                                name="contact-first-name"
                                required
                                value={firstName}
                                className={classes.width100}
                                onChange={(e) => this._updateStateField('fname', e.target.value)}
                                autoComplete="contact-name"
                                InputLabelProps={{
                                    className: classes.nameLabel,
                                    classes: { focused: classes.nameLabelFocused }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        className={classNames(classes.width40, classes.marginTop8, classes.marginLeftRight8)}
                    >
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                        >
                            <TextField
                                label="Middle Name"
                                name="contact-middle-name"
                                required={false}
                                value={middleName}
                                className={classes.width100}
                                onChange={(e) => this._updateStateField('mname', e.target.value)}
                                autoComplete="contact-name"
                                InputLabelProps={{
                                    className: classes.nameLabel,
                                    classes: { focused: classes.nameLabelFocused }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={classNames(classes.width40, classes.marginTop8)}>
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                        >
                            <TextField
                                label="Last Name"
                                name="contact-last-name"
                                required
                                value={lastName}
                                className={classes.width100}
                                onChange={(e) => this._updateStateField('lname', e.target.value)}
                                autoComplete="contact-name"
                                InputLabelProps={{
                                    className: classes.nameLabel,
                                    classes: { focused: classes.nameLabelFocused }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                    <FormControl
                        className={classNames(classes.width100, classes.textLeft)}
                    >
                        <GmapsSearch
                            formControlStyleClasses={classes.formControlStyles}
                            controlClasses={classNames(classes.gmapSearch)}
                            controlSuggestionClasses={classNames(classes.controlSuggestionClass)}
                            textLabel={'Contact Address'}
                            type={GmapsSearchType.longAddress}
                            value={address || NullLongAddress}
                            onSetPlace={(addressResult: GmapsSearchAddress, useAddressDescription: boolean) => {
                                if (isLongAddress(addressResult)) {
                                    this._updateStateField('longAddress', addressResult);
                                    this._updateStateField('useAddressDescription', useAddressDescription);
                                }
                            }}
                            error={false}
                            required={false}
                            disabled={false}
                            zIndex={zIndex + 1}
                            useDescription={useDescription}
                        />
                    </FormControl>
                </Grid>

                <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                    <FormControl
                        className={classNames(classes.width100, classes.textLeft)}
                    >
                        <TextField
                            label="Contact Email"
                            name="contact-email"
                            type="email"
                            value={email}
                            className={classes.width100}
                            onChange={(e) => this._updateStateField('email', e.target.value)}
                            autoComplete="contact-email"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.organizationFooterSection}>
                    <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                        <FormControl className={classNames(classes.textLeft)}>
                            <TextField
                                label="Contact Phone"
                                name="contact-phone"
                                value={phone}
                                onChange={(e) => this._updateStateField('phone', e.target.value)}
                                autoComplete="contact-phone"
                                InputProps={{
                                    inputComponent: PhoneNumberMask
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                        <FormControl className={classNames(classes.textLeft, classes.widthInherit)}>
                            <TextField
                                label="Contact Fax"
                                name="contact-fax"
                                value={fax}
                                onChange={(e) => this._updateStateField('fax_number', e.target.value)}
                                autoComplete="contact-fax"
                                InputProps={{
                                    inputComponent: PhoneNumberMask
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.contactFooterButton}>
                    <Button onClick={() => deleteHandler(entryKey)}>
                        <DeleteIcon />&nbsp;Delete {`${firstName} ${middleName} ${lastName}`}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    private _updateStateField = (fieldName: keyof RolodexContact, data: string | LongAddress | boolean | null) => {
        const { fieldChangeEventHandler, entryKey } = this.props;
        const updateObj = {};
        updateObj[fieldName] = data;

        this.setState(updateObj);

        if (fieldChangeEventHandler) {
            fieldChangeEventHandler(entryKey, fieldName, data);
        }
    };
}

export default ContactEntry;
