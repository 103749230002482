import { Theme } from '@mui/material';
import { StyleRulesCallback } from '@mui/styles';
import { convertHexToRGBA, DEFAULT_HEIGHT, DEFAULT_INNER_ALBUM_MARGIN, DEFAULT_WIDTH } from '../../../../services';
import { WithGStyles } from '../../../../styles/WithGStyles';

const innerWidth = DEFAULT_WIDTH - DEFAULT_INNER_ALBUM_MARGIN;
const innerHeight = DEFAULT_HEIGHT - DEFAULT_INNER_ALBUM_MARGIN;
const imageMargin = 12;

export function styleWrapper<P extends object>() {
    const styles: StyleRulesCallback<Theme, P> = (theme) => ({
        root: {},
        grid: {
            height: DEFAULT_HEIGHT,
            width: DEFAULT_WIDTH,
            borderRadius: 4,
        },
        mainContainer: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '& .ReactVirtualized__Grid__innerScrollContainer': {
                overflow: 'visible !important',
            },
        },
        noFocus: {
            minWidth: '100%',
            padding: '30px 0',
            '&:focus': {
                outline: 'none !important',
            },
        },
        addNewPhotoContainer: {
            background: '#fff',
            color: theme.palette.secondary.main,
            position: 'relative',
            textAlign: 'center',
            height: DEFAULT_HEIGHT,
            verticalAlign: 'top',
            display: 'inline-block',
            margin: 0,
            '@media (min-width: 420px)': {
                margin: 12,
            },
        },
        addIcon: {
            margin: '0 auto',
        },
        lockIcon: {
            margin: '0 auto',
            fontSize: 32,
        },
        newPhotoOuter: {
            margin: 'auto',
            height: '100%',
        },
        imageBox: {
            position: 'relative',
            verticalAlign: 'top',
            display: 'inline-block',
            textAlign: 'center',
            height: DEFAULT_HEIGHT,
            width: DEFAULT_WIDTH,
            boxShadow: theme.shadows[5],
            borderRadius: 4,
            margin: 0,
            '@media (min-width: 368px)': {
                margin: imageMargin,
            },
        },
        listContainer: {
            display: 'flex',
            alignItems: 'left',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
            paddingTop: 28,
            border: `1px solid ${convertHexToRGBA(theme.palette.primary.main, 0.5)}`,
            borderRight: 0,
            borderLeft: 0,
        },
        loadingText: {
            color: theme.palette.secondary.main,
            margin: '-6px 8px 8px',
        },
        loaderContainer: {
            textAlign: 'center',
            margin: 12,
        },
        addButton: {
            width: innerWidth,
            height: innerHeight,
            margin: '30px 12px 0',
            borderWidth: 1,
            borderRadius: 6,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            cursor: 'pointer',
            backgroundColor: `${convertHexToRGBA(theme.palette.primary.main, 0.2)}`,
            '&:hover': {
                backgroundColor: `${convertHexToRGBA(theme.palette.primary.main, 0.4)}`,
            },
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            '& p': {
                maxWidth: 100,
                margin: '0px auto',
            },
        },
        sizeAdjust: {
            '@media (min-width: 404px)': {
                maxWidth: 384,
            },
            '@media (min-width: 596px)': {
                maxWidth: 576,
            },
            '@media (min-width: 788px)': {
                maxWidth: 768,
            },
            '@media (min-width: 980px)': {
                maxWidth: 960,
            },
            '@media (min-width: 1172px)': {
                maxWidth: 1152,
            },
            '@media (min-width: 1364px)': {
                maxWidth: 1344,
            },
            '@media (min-width: 1556px)': {
                maxWidth: 1536,
            },
            '@media (min-width: 1748px)': {
                maxWidth: 1728,
            },
            '@media (min-width: 1940px)': {
                maxWidth: 1920,
            },
            '@media (min-width: 2132px)': {
                maxWidth: 2112,
            },
            '@media (min-width: 2324px)': {
                maxWidth: 2304,
            },
        },
        preparingSnackbarContent: {
            backgroundColor: `${convertHexToRGBA(theme.palette.primary.main, 0.9)} !important`,
            color: theme.palette.common.white,
            padding: 0,
            '& $messageContainer': {
                display: 'block',
                textAlign: 'center',
            },
            '& div': {
                width: '100%',
                padding: 0,
            },
            '@media (min-width: 400px)': {
                minWidth: '368px !important',
                maxWith: '368px !important',
                width: '368px !important',
                margin: 'auto !important',
            },
            '& $headerIcon': {
                position: 'absolute',
                left: 8,
                top: 7,
                backgroundColor: 'transparent',
                '& svg': {
                    fontSize: 44,
                },
            },
            '& $clearIcon': {
                position: 'absolute',
                right: 8,
                top: 8,
                '& svg': {
                    fontSize: 30,
                },
            },
        },
        snackbarContainer: {
            bottom: '84px !important',
            zIndex: 1320,
            '@media (min-width: 400px)': {
                width: '368px !important',
                margin: 'auto !important',
            },
            '@media (min-width: 960px)': {
                bottom: '48px !important',
            },
        },
        linearProgress: {
            height: '60px !important',
            backgroundColor: 'transparent',
            borderRadius: 4,
        },
        progressText: {
            position: 'absolute',
            top: 11,
            left: 64,
            fontSize: 20,
        },
        snackbarContent: {
            backgroundColor: `${theme.palette.common.white} !important`,
            border: `1px solid ${theme.palette.primary.main} `,
            color: theme.palette.primary.main,
            padding: 0,
            '& $messageContainer': {
                display: 'block',
                textAlign: 'center',
                textDecoration: 'none',
                color: theme.palette.primary.main,
                padding: 8,
            },
            '& div': {
                position: 'relative',
                width: '100%',
                padding: '6px 0',
            },
            '@media (min-width: 400px)': {
                minWidth: '368px !important',
                maxWith: '368px !important',
                width: '368px !important',
                margin: 'auto !important',
            },
            '& $headerIcon': {
                position: 'absolute',
                left: 8,
                top: 7,
                backgroundColor: 'transparent',
                '& svg': {
                    fontSize: 44,
                },
            },
            '& $clearIcon': {
                position: 'absolute',
                right: 8,
                top: 6,
                '& svg': {
                    fontSize: 30,
                },
            },
        },
        flexDirectionColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        downloadText: {
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        disabledDownloadButton: {
            opacity: 0.4,
        },
        downloadButton: {
            fontSize: 12,
            padding: '6px 12px',
            '& svg': {
                fontSize: 24,
            },
        },
        progressSubText: {
            position: 'absolute',
            left: 64,
            bottom: 8,
        },
        albumPhotoContainer: {
            margin: '16px 0',
            cursor: 'grab',
            pointerEvents: 'auto !important' as 'auto',
            '&:active, &:focus': {
                cursor: 'grabbing',
            },
            '& $transformMe': {
                transition: 'transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                // pointerEvents: 'none',
                '&:hover': {
                    // pointerEvents: 'auto',
                    transform: 'scale(1.05)',
                },
            },
        },
        opacity0: {
            opacity: '0 !important' as unknown as number, // TODO(Jonathan): fix in material ui v5
        },
        paper: {
            padding: theme.spacing(),
        },
        popoverPaper: {
            borderRadius: 4,
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
            boxShadow: 'none',
        },
        popperContent: {
            borderRadius: 4,
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            marginTop: '-10px',
            padding: '6px 16px',
        },
        selectPhotoButton: {
            transform: 'translateY(50%)',
            fontWeight: 400,
            '& svg': {
                fontSize: 18,
            },
            zIndex: 1,
        },
        inviterOtherContainer: {
            margin: '12px 0',
        },
        downloadContainer: {
            border: `1px solid ${convertHexToRGBA(theme.palette.primary.main, 0.5)}`,
            borderLeft: 0,
            borderRight: 0,
            padding: '8px 0 12px',
            '& button': {
                marginTop: 6,
            },
            '& p': {
                maxWidth: 300,
                margin: 'auto',
                lineHeight: 1.25,
            },
        },
        lockContentBox: {
            width: '300px',
            margin: '12px auto 40px',
            borderRadius: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        footer: {
            maxWidth: 360,
            margin: 'auto',
        },
        cursorAuto: {
            cursor: 'auto !important',
        },
        classFlexDirectionColumn: {
            display: 'flex',
            width: '100%',
            textAlign: 'end',
            lineHeight: '18px',
            maxWidth: 260
        },
        classMessageContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        transformMe: {},
        messageContainer: {},
        headerIcon: {},
        clearIcon: {},
    });

    return styles;
}

export type StyledProps = WithGStyles<'root' | 'grid' | 'addNewPhotoContainer' | 'squareButtonText'
    | 'addNewPhotoInner' | 'addPhotoIcon' | 'classFlexDirectionColumn' | 'classMessageContainer'
    | 'newPhotoOuter' | 'imageBox' | 'listContainer' | 'loaderContainer' | 'loadingText' | 'sizeAdjust'
    | 'addButton' | 'addIcon' | 'preparingSnackbarContent' | 'snackbarContainer' | 'messageContainer' | 'lockIcon'
    | 'linearProgress' | 'headerIcon' | 'progressText' | 'clearIcon' | 'snackbarContent' | 'flexDirectionColumn'
    | 'downloadText' | 'albumPhotoContainer' | 'transformMe' | 'downloadButton' | 'disabledDownloadButton'
    | 'progressSubText' | 'opacity0' | 'paper' | 'popoverPaper' | 'popperContent'
    | 'selectPhotoButton' | 'inviterOtherContainer' | 'downloadContainer' | 'lockContentBox' | 'footer'
    | 'cursorAuto' | 'noFocus' | 'mainContainer'>;
