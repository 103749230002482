import { useState } from "react";
import { BulkEditButtonType, PrimaryButtonsEnum } from "./BulkEditSelectedOptionContent";
import BulkEditContainer from "./BulkEditContainer";

interface ShowHideProductDetailsProps {
    selectButton: BulkEditButtonType | null;
    selectedProductsCount: number;
    onSubmitClick: (isShowSelected: boolean) => Promise<void>;
    isSpinning: boolean;
}

const ShowHideProductDetails = (props: ShowHideProductDetailsProps) => {

    const { selectButton, selectedProductsCount, onSubmitClick, isSpinning } = props;

    const [activePrimaryButton, setActivePrimaryButton] = useState<PrimaryButtonsEnum | null>(null);

    const buttons = [
        { label: PrimaryButtonsEnum.hide, value: PrimaryButtonsEnum.hide },
        { label: PrimaryButtonsEnum.show, value: PrimaryButtonsEnum.show },
    ];

    return (
        <BulkEditContainer
            buttons={buttons}
            selectButton={selectButton}
            activePrimaryButton={activePrimaryButton}
            setActivePrimaryButton={setActivePrimaryButton}
            selectedProductsCount={selectedProductsCount}
            onSubmitClick={() => onSubmitClick(activePrimaryButton === PrimaryButtonsEnum.show)}
            disableSubmitButton={!activePrimaryButton}
            isSpinning={isSpinning}

        />
    );
};

export default ShowHideProductDetails;