import * as React from 'react';
import { some } from 'lodash';

import Typography from '@mui/material/Typography';

import { TotalPrice } from '.';
import { typographyStyle, StyledProps } from '../styles';

interface Props extends StyledProps {
    title: string | React.ReactNode;
    categoryContent: React.ReactNode;
    subtotalAmount: string;
    subtotalLabel: string;
    legalText?: JSX.Element | null;
    isCondensedView: boolean;
    isPrintMode: boolean;
    isItemizedStatement: boolean;
}

const CategoryGroupContent = ({
    title,
    legalText,
    subtotalLabel,
    categoryContent,
    subtotalAmount,
    classes,
    isPrintMode,
    isCondensedView,
    isItemizedStatement
}: Props) => {

    const isValid = React.Children.map(categoryContent, child => {
        return React.isValidElement(child);
    });

    const isValidElement = some(isValid, (item: boolean) => !!item);

    return (
        <div
            className={isCondensedView ? classes.background_primary_08 : undefined}
            style={{
                pageBreakInside: isPrintMode && isItemizedStatement ? 'avoid' : undefined,
                breakInside: isPrintMode && isItemizedStatement ? 'avoid' : undefined,
                background: isPrintMode && 'transparent' || undefined,
                width: '100%'
            }}
        >
            <div
                className={classes.sectionHeading}
                style={{
                    paddingTop: 8,
                    margin: '0 4px',
                    borderBottom: '1px solid'
                }}
            >
                {typeof title !== 'string' ? title :
                    <Typography
                        style={{
                            ...typographyStyle,
                            lineHeight: '1em',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontSize: 14,
                            fontWeight: 600,
                            color: '#2e2e2e'
                        }}
                        color="secondary"
                    >
                        {title}
                    </Typography>
                }
                {legalText || null}
            </div>

            {categoryContent}

            <TotalPrice
                classes={classes}
                label={subtotalLabel}
                price={subtotalAmount}
                noBorder={isCondensedView}
                isCondensedView={isCondensedView}
                printMode={isPrintMode}
                showAmountTopBorder={isValidElement}
            />
        </div>
    );
};

export default CategoryGroupContent;