import { EntitySummary, getCaseEntityById, getDisplayRelationshipForPerson, GatherCaseUX } from '../../types';
import { joinNameParts } from '../../utils';

export type InformantData = {
    dc_informant: number | null;
};

export const approvalValidators = {
    informant: function (activeCase: GatherCaseUX, helpers: EntitySummary[]) {
        const { dc_informant } = activeCase;
        const informant = dc_informant &&
            helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_informant)));

        const caseEntity = informant && dc_informant && getCaseEntityById(informant, dc_informant);
        const relationship = caseEntity && getDisplayRelationshipForPerson(caseEntity);

        return Boolean(informant && joinNameParts(informant) && informant.home_address && relationship);
    },
};

export const approvalMasterValidators = {
    valid: function (activeCase: GatherCaseUX, helpers: EntitySummary[]) {
        return approvalValidators.informant(activeCase, helpers);
    },
    touched: function (activeCase: GatherCaseUX) {
        return activeCase.dc_informant !== null;
    },
};