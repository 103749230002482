import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { FuneralHomeForGatherView } from './funeralHome';
import { getValidator } from './utils';

const ServiceTemplateRequestType = t.type({
    name: t.string,
    description: t.union([t.string, t.null]),
    serviceTemplateDetailIds: t.union([t.array(t.number), t.null]),
    all_funeral_homes: t.boolean,
    funeral_home_ids: t.array(t.number),
});

type ServiceTemplateRequestType = t.TypeOf<typeof ServiceTemplateRequestType>;

export interface ServiceTemplateRequest extends ServiceTemplateRequestType {
}

export class ServiceTemplateRequest {
    public static fromRequest = getValidator<ServiceTemplateRequest>(ServiceTemplateRequestType);

    public static fromServiceTemplateUX(template: ServiceTemplateUX, funeralHomeIds: number[]): ServiceTemplateRequest {
        const {
            name,
            description,
            service_details,
            all_funeral_homes,
        } = template;

        return {
            name,
            description,
            all_funeral_homes,
            funeral_home_ids: funeralHomeIds,
            serviceTemplateDetailIds: service_details
                .map(({ service_template_detail_id }) => service_template_detail_id)
                .filter((detailId) => detailId !== null) as number[]
        };
    }
}

export interface ServiceTemplateRecord {
    id: number;
    name: string;
    key: string;
    description: string | null;
    created_by: number;
    created_time: Date;
    deactivated_by: number | null;
    deactivated_time: Date | null;
    updated_by: number;
    updated_time: Date;
    all_funeral_homes: boolean;
}

export interface ServiceTemplateUX extends ServiceTemplateRecord {
    service_detail_count: number;
    service_details: DefaultServiceDetail[];
}

const ServiceDetailRequestType = t.type({
    title: t.string,
    explainer_text: t.string,
    can_edit_title_by_user: t.boolean,
    can_sort_fields_by_user: t.boolean,
    can_be_deleted_by_user: t.boolean,
    can_user_add_music: t.boolean,
    add_music_label: t.union([t.string, t.null]),
    can_user_add_person: t.boolean,
    add_person_label: t.union([t.string, t.null]),
    can_user_add_text: t.boolean,
    add_text_label: t.union([t.string, t.null]),
    can_be_deleted: t.boolean,
    data: t.array(t.any)
});

type ServiceDetailRequestType = t.TypeOf<typeof ServiceDetailRequestType>;

export interface ServiceDetailRequest extends ServiceDetailRequestType {
}

export interface CaseServiceDetailRequest extends ServiceDetailRequestType {
    default_service_detail_id: number;
}

export type ServiceDetailKey = 'CASKET_BEARERS'
| 'HONORARY_CASKET_BEARERS'
| 'FLOWER_BEARERS'
| 'BLANK';
export enum ServiceDetailKeyEnum {
    CASKET_BEARERS = 'CASKET_BEARERS',
    HONORARY_CASKET_BEARERS = 'HONORARY_CASKET_BEARERS',
    FLOWER_BEARERS = 'FLOWER_BEARERS',
    BLANK = 'BLANK'
}

export class ServiceDetailRequest {
    public static fromRequest = getValidator<ServiceDetailRequest>(ServiceDetailRequestType);

    public static fromDefaultServiceDetail(serviceDetail: ServiceDetail | DefaultServiceDetail): ServiceDetailRequest {
        const {
            title,
            explainer_text,
            can_edit_title_by_user,
            can_sort_fields_by_user,
            can_be_deleted_by_user,
            can_user_add_music,
            add_music_label,
            can_user_add_person,
            add_person_label,
            can_user_add_text,
            add_text_label,
            can_be_deleted,
            data
        } = serviceDetail;

        return {
            title,
            explainer_text,
            can_edit_title_by_user,
            can_sort_fields_by_user,
            can_be_deleted_by_user,
            can_user_add_music,
            add_music_label,
            can_user_add_person,
            add_person_label,
            can_user_add_text,
            add_text_label,
            can_be_deleted,
            data: data ? data.fields : []
        };
    }

    public static initDefault(): ServiceDetailRequest {
        return {
            title: '',
            explainer_text: '',
            can_edit_title_by_user: false,
            can_sort_fields_by_user: false,
            can_be_deleted_by_user: false,
            can_user_add_music: false,
            add_music_label: null,
            can_user_add_person: false,
            add_person_label: null,
            can_user_add_text: false,
            add_text_label: null,
            can_be_deleted: false,
            data: []
        };
    }

    public static filterNonCasketBearerServiceDetails(serviceDetails: ServiceDetail[]): ServiceDetail[] {
        return serviceDetails.filter(d =>
            d.key !== ServiceDetailKeyEnum.CASKET_BEARERS
            && d.key !== ServiceDetailKeyEnum.FLOWER_BEARERS
            && d.key !== ServiceDetailKeyEnum.HONORARY_CASKET_BEARERS
        );
    }

    public static filterCasketBearerServiceDetails(serviceDetails: ServiceDetail[]): ServiceDetail[] {
        return serviceDetails.filter(d =>
            d.key === ServiceDetailKeyEnum.CASKET_BEARERS
            || d.key === ServiceDetailKeyEnum.FLOWER_BEARERS
            || d.key === ServiceDetailKeyEnum.HONORARY_CASKET_BEARERS
        );
    }
}

const ServiceDetailDataType = t.type({
});

type ServiceDetailDataType = t.TypeOf<typeof ServiceDetailDataType>;
export interface ServiceDetailData extends ServiceDetailDataType {
    fields: ServiceDetailDataField[];
}

const DefaultServiceDetailType = t.type({
    id: t.number,
    title: t.string,
    key: t.string,
    explainer_text: t.string,
    can_edit_title_by_user: t.boolean,
    can_sort_fields_by_user: t.boolean,
    can_be_deleted_by_user: t.boolean,
    can_user_add_music: t.boolean,
    add_music_label: t.string,
    can_user_add_person: t.boolean,
    add_person_label: t.string,
    can_user_add_text: t.boolean,
    add_text_label: t.string,
    can_be_deleted: t.boolean,
    created_by: t.union([t.number, t.null]),
    created_time: t.union([DateFromISOString, t.null]),
    updated_by: t.union([t.number, t.null]),
    updated_time: t.union([t.number, t.null]),
    service_template_detail_id: t.union([t.number, t.null])
});

type DefaultServiceDetailType = t.TypeOf<typeof DefaultServiceDetailType>;
export interface DefaultServiceDetail extends DefaultServiceDetailType {
    data: ServiceDetailData;
}

export type ServiceDetailField = 'person' | 'music' | 'text';
export enum ServiceDetailFieldEnum {
    person = 'person',
    music = 'music',
    text = 'text'
}

const ServiceDetailDataFieldType = t.type({
    label: t.string,
    placeholderText: t.union([t.string, t.null]),
    helperText: t.union([t.string, t.null]),
    fieldType: t.string,
    value: t.union([t.string, t.null]),
    can_user_delete_field: t.boolean
});

type ServiceDetailDataFieldType = t.TypeOf<typeof ServiceDetailDataFieldType>;
export interface ServiceDetailDataField extends ServiceDetailDataFieldType {
    fieldType: ServiceDetailField;
}

export class ServiceDetailDataField {
    public static fromRequest = getValidator<ServiceDetailDataField>(ServiceDetailDataFieldType);

    public static initDefault(): ServiceDetailDataField {
        return {
            label: '',
            placeholderText: null,
            helperText: null,
            fieldType: 'text',
            value: null,
            can_user_delete_field: false
        };
    }

    public static initFromDefaultDetailAndFieldType(
        serviceDetail: DefaultServiceDetail | ServiceDetail, fieldType: ServiceDetailField
    ): ServiceDetailDataField {
        const label: string =
            ServiceDetailFieldEnum.text === fieldType && serviceDetail.add_text_label
            || ServiceDetailFieldEnum.music === fieldType && serviceDetail.add_music_label
            || ServiceDetailFieldEnum.person === fieldType && serviceDetail.add_person_label
            || '';
        return {
            fieldType,
            label,
            helperText: null,
            placeholderText: null,
            value: null,
            can_user_delete_field: true
        };
    }
}

export interface ServiceTemplateRequest extends ServiceTemplateRequestType {
}

const ServiceDetailType = t.type({
    id: t.number,
    title: t.string,
    key: t.string,
    explainer_text: t.string,
    detail_order: t.number,
    can_edit_title_by_user: t.boolean,
    can_sort_fields_by_user: t.boolean,
    can_be_deleted_by_user: t.boolean,
    can_user_add_music: t.boolean,
    add_music_label: t.union([t.string, t.null]),
    can_user_add_person: t.boolean,
    add_person_label: t.union([t.string, t.null]),
    can_user_add_text: t.boolean,
    add_text_label: t.union([t.string, t.null]),
    can_be_deleted: t.boolean,
    default_service_detail_id: t.union([t.number, t.null]),
    version: t.number,
    gather_case_id: t.union([t.number, t.null]),
    created_by: t.union([t.number, t.null]),
    created_time: t.union([DateFromISOString, t.null]),
    deactivated_by: t.union([t.number, t.null]),
    deactivated_time: t.union([DateFromISOString, t.null]),
    updated_by: t.union([t.number, t.null]),
    updated_time: t.union([t.number, t.null])
});

type ServiceDetailType = t.TypeOf<typeof ServiceDetailType>;
export interface ServiceDetail extends ServiceDetailType {
    data: ServiceDetailData;
}

const ServiceTemplateDetailType = t.type({
    id: t.number,
    service_template_id: t.number,
    default_service_detail_id: t.number,
    detail_order: t.number,
    created_by: t.union([t.number, t.null]),
    created_time: t.union([DateFromISOString, t.null]),
    deactivated_by: t.union([t.number, t.null]),
    deactivated_time: t.union([DateFromISOString, t.null]),
    updated_by: t.union([t.number, t.null]),
    updated_time: t.union([t.number, t.null])
});

type ServiceTemplateDetailType = t.TypeOf<typeof ServiceTemplateDetailType>;
export interface ServiceTemplateDetail extends ServiceTemplateDetailType {
}

export interface ServiceTemplateFuneralHomesResponse {
    all: FuneralHomeForGatherView[];
    selectedIds: number[];
}

export interface ServiceTemplateUpdateResponse extends ServiceTemplateFuneralHomesResponse {
    serviceTemplate: ServiceTemplateUX;
}
