import Avatar from '@mui/material/Avatar';

import PersonOutline from '@mui/icons-material/PersonOutline';
import People from '@mui/icons-material/People';

import UserAvatar from '../common/UserAvatar';
import { AvatarUser } from '../../shared/types/user';

interface Props {
    assigned_to: AvatarUser[];
    assigned_to_all: boolean;
}

const TaskAssignAvatar = (props: Props) => {
    const { assigned_to, assigned_to_all } = props;

    if (assigned_to_all) {
        return (
            <Avatar>
                <People />
            </Avatar>
        );
    } else if (assigned_to.length === 1) {
        return <UserAvatar user={assigned_to[0]} />;
    } else if (assigned_to.length > 1) {
        return <Avatar>{assigned_to.length}</Avatar>;
    }

    return (
        <Avatar>
            <PersonOutline />
        </Avatar>
    );
};

export default TaskAssignAvatar;
