import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { GREEN_COLOR, RED_COLOR } from '../../../constants/colorVariables';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { ModerationCategory } from '../../../shared/types';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRulesCallback } from '@mui/styles';
import withGStyles from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '24px 0 16px'
    },
    approveButton: {
        color: GREEN_COLOR,
        border: `1px solid ${GREEN_COLOR}`
    },
    blockButton: {
        color: RED_COLOR,
        border: `1px solid ${RED_COLOR}`
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 600px)': {
            flexDirection: 'row'
        },
        '& button': {
            fontWeight: 200,
            padding: '0 16px',
            margin: '4px 8px'
        },
    },
});

interface Props {
    itemCount: number;
    category: ModerationCategory;
    onApprove: () => void;
    onBlock: () => void;
}

export type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'approveButton' | 'blockButton'
    | 'buttonsContainer'>;

const BulkModerateButtons = (props: StyledProps & Props) => {
    const { classes, onBlock, onApprove, itemCount, category } = props;

    return (
        <Grid
            container
            className={classes.root}
        >
            <Grid item className={classes.buttonsContainer}>
                <Button
                    variant="outlined"
                    className={classes.approveButton}
                    onClick={onApprove}
                >
                    <DoneIcon />&nbsp;approve {itemCount} {category}
                </Button>
                <Button
                    variant="outlined"
                    className={classes.blockButton}
                    onClick={onBlock}
                >
                    <CloseIcon />&nbsp;block {itemCount} {category}
                </Button>
            </Grid>
        </Grid>
    );
};

export default withGStyles(styles)(BulkModerateButtons);
