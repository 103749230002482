import { Component } from 'react';

import { StyleRulesCallback } from '@mui/styles';

import GGroupButtons from '../../../../common/GGroupButtons';
import Grid from '@mui/material/Grid';
import { Theme, Typography } from '@mui/material';
import classNames from 'classnames';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import DisabledPermissionTooltip from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';
import SchoolIcon from '@mui/icons-material/School';
import { SKYBLUE_COLOR } from '../../../../../constants/colorVariables';
import { getIntercomTargetProp } from '../../../../../services';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        position: 'relative',
        marginTop: 16,
        '& button': {
            minWidth: 40,
            minHeight: 40,
            padding: 0,
        },
        '@supports (-webkit-touch-callout: none)': {
            '& button:first-of-type': {
                marginTop: 1,
            },
        },
    },
    label: {
        fontSize: 18,
        fontWeight: 200,
    },
    main: {
        transition: 'all 1s ease',
        transform: 'translateY(0) scale(1)',
        opacity: 1,
        height: 82,
        '&$hide': {
            height: 0,
            opacity: 0,
            transform: 'translateY(100%) scale(0)',
        },
    },
    learnButton: {
        position: 'absolute',
        marginTop: '40px',
        lineHeight: '2.2',
        textAlign: 'center',
        margin: '0 14px',
        backgroundColor: SKYBLUE_COLOR,
        color: theme.palette.common.white,
        borderRadius: '0px 0px 10px 10px',
        padding: '0px 12px 0px 12px',
        cursor: 'pointer',
        pointerEvents: 'auto',
        textTransform: 'none',
        fontSize: '10px',
        fontWeight: '400',
        '& svg': {
            top: 3,
            fontSize: 16,
            position: 'relative',
            margin: '0px 2px -2px -2px',
        },
    },
    hide: {},
});

type StyledProps = Props & WithGStyles<'root' | 'label' | 'main' | 'learnButton' | 'hide'>;

interface Props {
    payerCount: number;
    isHidden: boolean;
    zIndex: number;
    onClick: (payerCount: number) => void;
}

interface State {
    disabled: boolean;
}

class SelectPayersCount extends Component<StyledProps, State> {
    state: State = {
        disabled: false,
    };

    render() {
        const { disabled } = this.state;
        const { classes, payerCount, isHidden, zIndex } = this.props;

        return (
            <Grid item xs={12} className={classNames(classes.main, isHidden && classes.hide)}>
                <Typography color="primary" align="center" className={classes.label}>
                    How many payers?
                </Typography>

                <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                    {(disabledPermission) => (
                        <Grid item xs={12} className={classes.root}>
                            <GGroupButtons
                                disabled={disabled || disabledPermission}
                                buttons={[
                                    { label: '1', value: '1' },
                                    { label: '2', value: '2' },
                                    { label: '3', value: '3' },
                                    { label: '4', value: '4' },
                                    { label: '5', value: '5' },
                                    { label: '6', value: '6' },
                                    { label: '7', value: '7' },
                                    { label: '8', value: '8' },
                                ]}
                                activeButton={payerCount > 0 ? `${payerCount}` : null}
                                onClick={(v) => this.clickPayerButton(parseInt(v, 10))}
                            />
                            <br />
                            <Typography
                                variant="button"
                                component="div"
                                color="secondary"
                                className={classNames(classes.learnButton)}
                                zIndex={zIndex}
                                {...getIntercomTargetProp(`Payments-SelectPayersCount-LearnMoreButton`)}
                            >
                                <SchoolIcon />
                                <span>&nbsp;Learn More About Gather Pay</span>
                            </Typography>
                        </Grid>
                    )}
                </DisabledPermissionTooltip>
            </Grid>
        );
    }

    clickPayerButton(payerCount: number) {
        this.setState({ disabled: payerCount === 1 });
        this.props.onClick(payerCount);
    }
}

export default withGStyles(styles)(SelectPayersCount);
