import { CaseTaskUX, GatherCaseUX, UserProfile } from '../../../../shared/types';
import TaskDialogInitialState from '../TaskDialogInitialState';

interface Props {
    userData: UserProfile | null;
    activeCase: GatherCaseUX;
    zIndex: number;
    task: CaseTaskUX | null;
    onStepChange: (step: number) => void;
}

const FRInitialContent = (props: Props) => {
    const { userData, activeCase, zIndex, task, onStepChange } = props;

    return (
        <TaskDialogInitialState
            listItems={[{ id: 1, content: <></>, label: 'Do I qualify to receive a $9,000 reimbursement?' }]}
            onItemClick={() => onStepChange(1)}
            active={true}
            userData={userData}
            activeCase={activeCase}
            task={task || undefined}
            zIndex={zIndex + 1}
        >
            We believe you should get everything to which you are entitled, especially during this
            difficult time. We have provided this helpful task to ensure that you and your family have
            everything you need for FEMA's $9,000 funeral reimbursement program. Simply click below
            and we will ask you a series of simple questions to see if you qualify and we will then
            provide you with exact details of how to move forward.
        </TaskDialogInitialState>
    );
};

export default FRInitialContent;