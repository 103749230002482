
import { isEmpty, some, difference, values } from 'lodash';
import {
    UserProfile,
    UserUtils,
    UserRole,
    EntityCaseRole,
    UserRoles,
    CaseTaskUX,
    GatherEvent,
    UserUpdateRequest,
    GatherCaseUX,
    GatherCasePublic,
    EntitySummary,
    isNonUserCaseHelper,
    isUserProfile,
    ProductContractUX,
    CaseHelperUpdateRequest,
    UserPatchRequest,
    getUserFuneralHome,
    TaskTemplateType,
    isHelperModifiable,
    hasLoggedIn,
    EntityUpdateRequest,
    GatherEventRecord,
    MemoryUX,
    ModerationItem,
    getCaseEntity,
    AlbumEntryMappedType,
    ReportUX,
    ReportUpdateRequest,
    ReportCreateRequest,
    ReportDataSource,
} from '../types';
import { Permission } from '../types/permissions';
import { getPhoneNumberParts } from '../utils';

export function canInviteUser(
    inviter: UserProfile,
    inviteeRole: UserRole,
    activeFuneralHomeId: number | null,
    activeCaseId: number | null,
    inviteeCaseRole: EntityCaseRole | null,
): boolean {
    switch (inviter.role) {
        case UserRole.GOMSuperUser:
            // anyone
            return true;
        case UserRole.GOMUser:
            // anyone but GOM users
            return !UserRoles.isGOMRole(inviteeRole);
        case UserRole.FHUser:
            if (!activeFuneralHomeId) {
                console.warn('No funeralHomeId passed for FH User');
                return false;
            }
            if (UserRoles.isGOMRole(inviteeRole)) { // Neverj
                return false;
            }
            if (UserRoles.isFHRole(inviteeRole)) { // only if they have AppPermission
                return canInviteOtherTeamMembers(inviter, activeFuneralHomeId);
            }
            if (UserRoles.isFamilyRole(inviteeRole)) { // only for their Funeral Home
                return UserUtils.hasFuneralHome(inviter, activeFuneralHomeId);
            }
            return false;
        case UserRole.User: // only Family Admins and Guests can Invite other family users, and then only guest
            if (!activeCaseId) {
                console.warn('No caseId passed for Family User');
                return false;
            }
            if (UserRoles.isInvitedFamilyOnCase(inviter, activeCaseId)) {
                // only allow them to invite Guests
                return inviteeCaseRole === EntityCaseRole.guest;
            }
            return false;
        default:
            return false;
    }
}

export function canResendInvitation(
    sender: UserProfile,
    invitee: UserProfile | EntitySummary,
    activeCaseId: number | null,
): boolean {
    switch (sender.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            // anyone
            return true;
        case UserRole.FHUser:
            // anyone but Gather users
            return !UserRoles.isGOMUser(invitee) &&
                (UserRoles.isFHUser(invitee) ? UserUtils.sharesFuneralHomes(sender, invitee) : true);
        case UserRole.User:
            if (!activeCaseId) {
                console.warn('No caseId passed for Family User');
                return false;
            }
            if (UserRoles.isFamilyVisitor(sender, activeCaseId)) {
                return false;
            }
            if (UserRoles.isFamilyAdmin(sender, activeCaseId)) {
                return UserRoles.isInvitedFamilyOnCase(invitee, activeCaseId);
            }
            if (UserRoles.isFamilyGuest(sender, activeCaseId)) {
                return UserRoles.isFamilyGuest(invitee, activeCaseId);
            }
            return false;
        default:
            return false;
    }
}

export function canRevokeInvitation(
    revoker: UserProfile,
    invitee: UserProfile | EntitySummary,
    activeCaseId: number | null,
): boolean {
    switch (revoker.role) {
        case UserRole.GOMSuperUser:
            // anyone
            return true;
        case UserRole.GOMUser:
            // anyone but GOM users
            return !UserRoles.isGOMUser(invitee);
        case UserRole.FHUser:
            return !UserRoles.isGOMUser(invitee)
                && (UserUtils.sharesFuneralHomes(revoker, invitee)
                    || activeCaseId !== null && UserRoles.isInvitedFamilyOnCase(invitee, activeCaseId));
        case UserRole.User:
            if (!activeCaseId) {
                console.warn('No caseId passed for Family User');
                return false;
            }
            return UserRoles.isFamilyAdmin(revoker, activeCaseId) && UserRoles.isFamilyGuest(invitee, activeCaseId);
        default:
            return false;
    }
}

export function canAddNonUser(
    inviter: UserProfile,
    funeralHomeId: number,
    caseId: number,
): boolean {
    switch (inviter.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserUtils.hasFuneralHome(inviter, funeralHomeId);
        case UserRole.User:
            return UserRoles.isInvitedFamilyOnCase(inviter, caseId);
        default:
            return false;
    }
}

export function canDeletePerson(
    deleter: UserProfile,
    target: UserProfile | EntitySummary,
    activeFuneralHomeId: number | null,
    activeCaseId: number | null,
): boolean {

    // prevent any user from deleting themselves.
    if (deleter.user_id === target.user_id) {
        return false;
    }

    switch (deleter.role) {
        case UserRole.GOMSuperUser:
            // anyone but themselves
            return deleter.entity_id !== target.entity_id;
        case UserRole.GOMUser:
            // anyone but Gather users
            return !UserRoles.isGOMUser(target);
        case UserRole.FHUser:
            if (!activeFuneralHomeId) {
                return false;
            }
            // anyone but FHOwners, themselves, or Gather Users
            if (UserRoles.isGOMUser(target)) {
                return false;
            }
            if (UserRoles.isFHUser(target)) {
                return UserUtils.sharesFuneralHomes(deleter, target) &&
                    canRemoveOtherTeamMembers(deleter, activeFuneralHomeId);
            }
            if (activeCaseId && UserRoles.isInvitedFamilyOnCase(target, activeCaseId)) {
                return true;
            }
            if (activeCaseId && isNonUserCaseHelper(target, activeCaseId)) {
                return true;
            }
            return false;
        case UserRole.User:
            if (!activeCaseId) {
                console.warn('No caseId passed for Family User');
                return false;
            }
            if (UserRoles.isFamilyVisitor(deleter, activeCaseId)
                || UserRoles.isFamilyGuest(deleter, activeCaseId)) {
                return false;
            }
            if (UserRoles.isFamilyAdmin(deleter, activeCaseId)) {
                return (UserRoles.isFamilyGuest(target, activeCaseId) || isNonUserCaseHelper(target, activeCaseId));
            }
            return false;
        default:
            return false;
    }
}
export function canViewEntitySubscriptions(
    viewer: UserProfile,
    target: UserProfile | EntitySummary,
): boolean {
    if (viewer.entity_id === target.entity_id) { // anyone can view themselves do not need to check roles
        return true;
    }
    switch (viewer.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            if (UserRoles.isGOMUser(target)) {
                return false;
            }
            if (UserRoles.isFHUser(target)) {
                return UserUtils.sharesFuneralHomes(target, viewer);
            }
            return false;
        case UserRole.User:
            return false;
        default:
            return false;
    }
}

export function canEditEntitySubscriptions(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
): boolean {
    if (editor.entity_id === target.entity_id) { // anyone can edit themselves do not need to check roles
        return true;
    }
    switch (editor.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            if (UserRoles.isGOMUser(target)) {
                return false;
            }
            if (UserRoles.isFHUser(target)) {
                return UserUtils.sharesFuneralHomes(target, editor);
            }
            return false;
        case UserRole.User:
            return false;
        default:
            return false;
    }
}

export function canEditPersonPhoneOrEmail(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
    funeralHomeId: number | null,
    activeCaseId: number | null,
): boolean {

    if (target.entity_id === editor.entity_id) { // anyone can edit themselves do not need to check roles
        return true;
    }
    const isTargetUserModifiable = isHelperModifiable(target);
    const hasTargetUserLoggedIn = hasLoggedIn(target);

    switch (editor.role) {
        case UserRole.GOMSuperUser:
            // anyone
            return true;
        case UserRole.GOMUser:
            // anyone but other Gather users
            return !UserRoles.isGOMUser(target);
        case UserRole.FHUser:
            if (UserRoles.isGOMUser(target)) { // cannot edit GOM Users
                return false;
            }
            if (UserRoles.isFHUser(target)) {
                return UserUtils.sharesFuneralHomes(target, editor);
            }
            if (activeCaseId && UserRoles.isInvitedFamilyOnCase(target, activeCaseId)) {
                return isTargetUserModifiable;
            }
            if (activeCaseId && isNonUserCaseHelper(target, activeCaseId)) {
                return true;
            }
            if (!activeCaseId) {
                const userIntersection = UserUtils.userIntersection(editor, target);
                if (!isEmpty(userIntersection.sharesFuneralHomes)) { // shares a funeral home. Allow it for now. 
                    return true;
                }
                // the sharesCases will be used more in the future when we remove the 
                // restriction of a FHUser not being associated as a Helper/Visitor to a case
                if (!isEmpty(userIntersection.sharesCases)) { // both share a case.  
                    const canEdit = some(
                        userIntersection.sharesCases,
                        (val, caseId) => canEditPersonPhoneOrEmail(editor, target, null, parseInt(caseId, 10)));
                    return canEdit;
                }

                // does the target exist on a case that belongs to a FH that FHUser is on? 
                if (!isEmpty(userIntersection.user1FHtoUser2FamilyByFH)) {
                    const canEdit = some(
                        userIntersection.user1FHtoUser2FamilyByFH,
                        (val, caseId) => val.caseUser.case_role !== EntityCaseRole.visitor);
                    return canEdit && isTargetUserModifiable;
                }
            }

            return false;
        case UserRole.User:
            // if we do not have an activeCaseId 
            if (!activeCaseId) {
                const userIntersection = UserUtils.userIntersection(editor, target);
                if (!isEmpty(userIntersection.sharesFuneralHomes)) { // shares a funeral home. Allow it for now. 
                    return true; // NOTE: this could be used in the future once we co-mingle FHUser/CaseEntity data
                }
                // the sharesCases will be used more in the future when we remove the 
                // restriction of a FHUser not being associated as a Helper/Visitor to a case
                if (!isEmpty(userIntersection.sharesCases)) { // both share a case.  
                    const canEdit = some(
                        userIntersection.sharesCases,
                        (val, caseId) => canEditPersonPhoneOrEmail(editor, target, null, parseInt(caseId, 10)));
                    return canEdit;
                }
                return false;
            }

            if (!isTargetUserModifiable || hasTargetUserLoggedIn) {
                return false;
            }

            if (UserRoles.isFamilyGuest(editor, activeCaseId)) {
                // Guest editors - Only themselves and nonUser guests on this case
                return isNonUserCaseHelper(target, activeCaseId);

            } else if (UserRoles.isFamilyAdmin(editor, activeCaseId)) {
                // Admins editors - Themselves and Guests that have not logged in yet
                return isNonUserCaseHelper(target, activeCaseId) ||
                    UserRoles.isFamilyGuest(target, activeCaseId);

            } else { // all other cases (including Family Visitors) return false
                return false;
            }
        default:
            return false;
    }
}

export function canEditPersonPhoto(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
    activeCaseId: number | null,
): boolean {

    const isTargetUserModifiable = isHelperModifiable(target);
    if (target.entity_id === editor.entity_id) { // anyone can edit themselves do not need to check roles
        return true;
    }

    switch (editor.role) {
        case UserRole.GOMSuperUser:
            // anyone
            return true;
        case UserRole.GOMUser:
            // anyone but other Gather users
            return !UserRoles.isGOMUser(target) || target.entity_id === editor.entity_id;
        case UserRole.FHUser:
            if (UserRoles.isGOMUser(target)) {
                return false;
            }
            if (UserRoles.isFHUser(target)) {
                return UserUtils.sharesFuneralHomes(target, editor);
            }
            if (activeCaseId) {
                if (UserRoles.isFamilyVisitor(target, activeCaseId)) {
                    return false;
                }
                // family and funeral home users for their funeral home
                return UserRoles.isInvitedFamilyOnCase(target, activeCaseId) ||
                    isNonUserCaseHelper(target, activeCaseId);
            }
            if (!activeCaseId) {
                const userIntersection = UserUtils.userIntersection(editor, target);
                if (!isEmpty(userIntersection.sharesCases)) { // both share a case.  
                    const canEdit = some(
                        userIntersection.sharesCases,
                        (val, caseId) => canEditPersonPhoto(editor, target, parseInt(caseId, 10)));
                    return canEdit;
                }
                // does the target exist on a case that belongs to a FH that FHUser is on? 
                if (!isEmpty(userIntersection.user1FHtoUser2FamilyByFH)) {
                    const canEdit = some(
                        userIntersection.user1FHtoUser2FamilyByFH,
                        (val, caseId) => val.caseUser.case_role !== EntityCaseRole.visitor);
                    return canEdit && isTargetUserModifiable;
                }
            }
            return false;
        case UserRole.User:
            if (!activeCaseId) {
                const userIntersection = UserUtils.userIntersection(editor, target);
                if (!isEmpty(userIntersection.sharesCases)) { // both share a case.  
                    const canEdit = some(
                        userIntersection.sharesCases,
                        (val, caseId) => canEditPersonPhoto(editor, target, parseInt(caseId, 10)));
                    return canEdit;
                }
                // console.warn('No caseId passed for Family User (and no case intersection found)');
                return false;
            }
            if (UserRoles.isFamilyVisitor(editor, activeCaseId)) {
                return false;
            }
            if (UserRoles.isFamilyGuest(editor, activeCaseId)) {
                return isNonUserCaseHelper(target, activeCaseId);
            }
            if (UserRoles.isFamilyAdmin(editor, activeCaseId)) {
                return isNonUserCaseHelper(target, activeCaseId);
            }
            return false;
        default:
            return false;
    }
}

export function canEditNonSensitivePersonDetails(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
    funeralHomeId: number | null,
    activeCaseId: number | null,
): boolean {
    if (editor.entity_id === target.entity_id) { // anyone can edit themselves do not need to check roles
        return true;
    }

    switch (editor.role) {
        case UserRole.GOMSuperUser:
            // anyone
            return true;
        case UserRole.GOMUser:
            // anyone but other Gather users
            return !UserRoles.isGOMUser(target) || target.entity_id === editor.entity_id;
        case UserRole.FHUser: // family and funeral home users for their funeral home - except visitors
            if (UserRoles.isGOMUser(target)) { // NO GOM edits
                return false;
            }
            if (UserRoles.isFHUserOnFH(editor, funeralHomeId) && UserRoles.isFHUserOnFH(target, funeralHomeId)) {
                // Allow edits if editing FH User on same funeral home
                return true;
            }
            // If there is no case in context - FH User can not edit the family helper
            if (activeCaseId) {
                return UserRoles.isInvitedFamilyOnCase(target, activeCaseId)
                    || isNonUserCaseHelper(target, activeCaseId);
            }
            if (!activeCaseId) {
                const userIntersection = UserUtils.userIntersection(editor, target);
                if (!isEmpty(userIntersection.sharesFuneralHomes)) {
                    return true;
                }
                if (!isEmpty(userIntersection.user1FHtoUser2FamilyByFH)) {
                    const canEdit = some(
                        userIntersection.user1FHtoUser2FamilyByFH,
                        (val, fhId) => canEditNonSensitivePersonDetails(
                            editor, target, parseInt(fhId, 10), val.caseUser.gather_case_id));
                    return canEdit;
                }
                if (!isEmpty(userIntersection.sharesCases)) { // both share a case.  
                    const canEdit = some(
                        userIntersection.sharesCases,
                        (val, caseId) => canEditNonSensitivePersonDetails(editor, target, null, parseInt(caseId, 10)));
                    return canEdit;
                }
            }
            return false;

        case UserRole.User:
            if (!activeCaseId) {
                const userIntersection = UserUtils.userIntersection(editor, target);
                if (!isEmpty(userIntersection.sharesCases)) { // both share a case.  
                    const canEdit = some(
                        userIntersection.sharesCases,
                        (val, caseId) => canEditNonSensitivePersonDetails(editor, target, null, parseInt(caseId, 10)));
                    return canEdit;
                }
                // console.warn('No activeCaseId for Family user! (and no case intersections found)');
                return false;
            }
            if (UserRoles.isFamilyVisitor(editor, activeCaseId) || UserRoles.isFamilyVisitor(target, activeCaseId)) {
                return false;
            } else if (UserRoles.isFamilyGuest(editor, activeCaseId)) {
                // Guest editors - Only themselves or nonUser guests for this case
                return editor.entity_id === target.entity_id ||
                    isNonUserCaseHelper(target, activeCaseId);

            } else if (UserRoles.isFamilyAdmin(editor, activeCaseId)) {
                // Admin editors - Any family user
                return UserRoles.isInvitedFamilyOnCase(target, activeCaseId) ||
                    isNonUserCaseHelper(target, activeCaseId);

            } else {
                return false;
            }
        default:
            return false;
    }
}

export function canEditUserRole(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
    desiredRole: UserRole,
): boolean {

    const targetUser = isUserProfile(target) ? target : target.user;
    // targetUser.role should be the CURRENT role of the target user
    if (!targetUser) {
        console.warn('canEditUserRole is not targeting a user in the app');
        return false;
    }
    switch (editor.role) {
        case UserRole.GOMSuperUser:
            // anyone but family, role cannot change types
            switch (targetUser.role) {
                case UserRole.GOMSuperUser:
                case UserRole.GOMUser:
                    return UserRoles.isGOMRole(desiredRole);
                case UserRole.FHUser:
                    return UserRoles.isFHRole(desiredRole);
                default:
                    return false;
            }
        case UserRole.GOMUser:
            // only funeral home users, role must stay as a FH type
            switch (targetUser.role) {
                case UserRole.FHUser:
                    return UserRoles.isFHRole(desiredRole);
                default:
                    return false;
            }
        default:
            // FH users and family users cannot change roles
            return false;
    }
}

export function canEditCaseRole(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
    activeCaseId: number,
): boolean {
    switch (editor.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return UserRoles.isInvitedFamilyOnCase(target, activeCaseId);
        case UserRole.User:
            // family cannot change Case Role
            return false;
        default:
            return false;
    }
}

export function canEditCasePermissions(
    editor: UserProfile,
    target: UserProfile | EntitySummary,
    activeCaseId: number,
): boolean {
    switch (editor.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return UserRoles.isInvitedFamilyOnCase(target, activeCaseId);
        case UserRole.User:
            // family cannot change Case Permissions
            return false;
        default:
            return false;
    }
}

export function canViewCaseTrackingSteps(
    viewer: EntitySummary | UserProfile | null,
    activeCaseId: number,
    funeralHomeId: number,
): boolean {
    if (!viewer) {
        return false;
    }
    const userRole = UserRoles.getUserRole(viewer);

    switch (userRole) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(viewer, funeralHomeId);
        case UserRole.User:
            const activeCaseEntity = getCaseEntity(viewer, activeCaseId);
            return activeCaseEntity?.can_view_tracking_steps ?? false;
        default:
            return false;
    }
}

export function canViewCaseTrackingPage(
    viewer: EntitySummary | UserProfile | null,
    funeralHomeId: number,
): boolean {
    if (!viewer) {
        return false;
    }
    const userRole = UserRoles.getUserRole(viewer);

    switch (userRole) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(viewer, funeralHomeId);
        case UserRole.User:
            return false;
        default:
            return false;
    }
}

// ---> CaseBelogning <---
export function canGetCaseBelongings(user: UserProfile | null, funeralHomeId: number): boolean {
    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    } else if (UserRoles.isFHUserOnFH(user, funeralHomeId)) {
        return true;
    } else {
        return false;
    }
}

export function canViewCaseBelongings(
    viewer: EntitySummary | UserProfile,
    activeCaseId: number,
    funeralHomeId: number,
): boolean {
    const userRole = UserRoles.getUserRole(viewer);

    switch (userRole) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(viewer, funeralHomeId);
        case UserRole.User:
            const activeCaseEntity = getCaseEntity(viewer, activeCaseId);
            return activeCaseEntity?.can_view_belongings ?? false;
        default:
            return false;
    }
}


export function canEditCaseProfilePhoto(
    user: UserProfile | null,
    activeCaseId: number,
    funeralHomeId: number,
): boolean {
    if (!user) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(user, funeralHomeId);
        case UserRole.User:
            return UserRoles.isFamilyAdmin(user, activeCaseId);
        default:
            return false;
    }
}

enum CanEditPersonFailedReason {
    PhoneOrEmail = 'PhoneOrEmail',
    Role = 'Role',
    CaseRole = 'CaseRole',
    NonSensitive = 'NonSensitive',
    CasePermissions = 'CasePermissions',
}

const formatPhoneNumber = (phone: string): string | null => {
    const parts = getPhoneNumberParts(phone);
    if (!parts || parts.areaCode.length + parts.prefix.length + parts.subscriber.length !== 10) {
        return null;
    }
    return '+1' + parts.areaCode + parts.prefix + parts.subscriber;
};

export function canViewTeamMembers(viewer: UserProfile, funeralHomeId: number | null): boolean {
    switch (viewer.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return UserRoles.isFHorGOMUserOnFH(viewer, funeralHomeId);
        case UserRole.User:
            return UserRoles.isInvitedFamilyOnAnyCaseForFH(viewer, funeralHomeId);
        default:
            return false;
    }
}

export function canViewTeamMember(
    viewer: UserProfile,
    target: UserProfile | EntitySummary,
): boolean {
    if (UserRoles.isGOMUser(viewer)) {
        return true;
    }
    if (UserRoles.isFHUser(viewer)) {
        return UserUtils.sharesFuneralHomes(viewer, target);
    }
    return false;
}

export function canViewCaseSwitcherForFH(
    viewer: UserProfile,
    funeralHomeId: number,
): boolean {
    return UserRoles.isFHorGOMUserOnFH(viewer, funeralHomeId);
}

export function canViewCaseSwitcherForFamily(
    viewer: UserProfile,
): boolean {
    return UserRoles.isFamilyRole(viewer.role);
}

export function canEditTeamMember(
    editor: UserProfile,
    originalPerson: UserProfile | EntitySummary,
    funeralHomeId: number | null,
    changes: Partial<UserUpdateRequest> | UserPatchRequest,
): { canEdit: boolean; failReason?: CanEditPersonFailedReason } {

    const result = canEditEntityGeneric(editor, originalPerson, changes, funeralHomeId, null);
    if (!result.canEdit) {
        return result;
    }
    const originalUser = isUserProfile(originalPerson) ? originalPerson : originalPerson.user;
    if (originalUser && changes.role && changes.role !== originalUser.role
        && !canEditUserRole(editor, originalPerson, changes.role)) {
        return {
            canEdit: false,
            failReason: CanEditPersonFailedReason.Role,
        };
    }
    return {
        canEdit: true,
    };
}

export function canEditCaseHelper(
    editor: UserProfile,
    originalPerson: UserProfile | EntitySummary,
    changes: CaseHelperUpdateRequest,
    funeralHomeId: number,
    activeCaseId: number,
): { canEdit: boolean; failReason?: CanEditPersonFailedReason } {

    const result = canEditEntityGeneric(editor, originalPerson, changes, funeralHomeId, activeCaseId);
    if (!result.canEdit) {
        return result;
    }
    if (changes.case_role
        && (changes.case_role !== EntityCaseRole.guest && UserRoles.isFamilyGuest(originalPerson, activeCaseId) ||
            changes.case_role !== EntityCaseRole.admin && UserRoles.isFamilyAdmin(originalPerson, activeCaseId))
        && !canEditCaseRole(editor, originalPerson, activeCaseId)
    ) {
        return {
            canEdit: false,
            failReason: CanEditPersonFailedReason.CaseRole,
        };
    }
    if ((changes.can_view_belongings !== undefined || changes.can_view_tracking_steps !== undefined)
        && !canEditCasePermissions(editor, originalPerson, activeCaseId)
    ) {
        return {
            canEdit: false,
            failReason: CanEditPersonFailedReason.CasePermissions,
        };
    }

    return {
        canEdit: true,
    };
}

export function canEditEntity(
    editor: UserProfile,
    originalPerson: UserProfile | EntitySummary,
    changes: EntityUpdateRequest,
    funeralHomeId: number | null,
    activeCaseId: number | null,
) {
    return canEditEntityGeneric(editor, originalPerson, changes, funeralHomeId, activeCaseId);
}

function canEditEntityGeneric(
    editor: UserProfile,
    originalPerson: UserProfile | EntitySummary,
    changes: { email?: string | null; phone?: string | null },
    funeralHomeId: number | null,
    activeCaseId: number | null,

) {
    // handle the null case for funeralHomeId and activeCaseId 

    if ((changes.email !== undefined && changes.email !== originalPerson.email ||
        changes.phone !== undefined &&
        formatPhoneNumber(changes.phone || '') !== formatPhoneNumber(originalPerson.phone || ''))
        && !canEditPersonPhoneOrEmail(editor, originalPerson, funeralHomeId, activeCaseId)) {
        return {
            canEdit: false,
            failReason: CanEditPersonFailedReason.PhoneOrEmail,
        };
    }

    if (!canEditNonSensitivePersonDetails(editor, originalPerson, funeralHomeId, activeCaseId)) {
        return {
            canEdit: false,
            failReason: CanEditPersonFailedReason.NonSensitive,
        };
    }

    return {
        canEdit: true,
    };
}

export function canAssignTask(
    assigner: UserProfile,
    target: UserProfile | EntitySummary,
    activeFuneralHomeId: number,
    activeCaseId: number,
): boolean {
    switch (assigner.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            // anyone but Gather
            return !UserRoles.isGOMUser(target) &&
                (UserUtils.sharesFuneralHomes(target, assigner)
                    || UserRoles.isInvitedFamilyOnCase(target, activeCaseId));
        case UserRole.User:
            // Admin - anyone but Gather
            return UserRoles.isFamilyAdmin(assigner, activeCaseId) &&
                !UserRoles.isGOMUser(target) &&
                (UserUtils.hasFuneralHome(target, activeFuneralHomeId)
                    || UserRoles.isInvitedFamilyOnCase(target, activeCaseId));
        default:
            return false;
    }
}

export function canViewTask(
    user: UserProfile | null,
    task: CaseTaskUX,
): boolean {
    if (!user) {
        return false;
    }
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return true;
        case UserRole.User:
            // Admins and Guests that are assigned - but NOT visitors (they must be moved to guest before)
            if (UserRoles.isFamilyVisitor(user, task.gather_case_id)) {
                return false;
            }
            if (UserRoles.isFamilyAdmin(user, task.gather_case_id)) {
                return true;
            }
            if (UserRoles.isFamilyGuest(user, task.gather_case_id)) {
                return task.assigned_to_all ||
                    task.assigned_to.some((assignee) => assignee.user_id === user.user_id);
            }
            return false;
        default:
            return false;
    }
}

export function canViewEvent(
    user: UserProfile,
    event: GatherEvent,
): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
        case UserRole.User:
            if (UserRoles.isFamilyVisitor(user, event.gather_case_id)) {
                return false;
            }
            return true;
        default:
            return false;
    }
}

export function canToggleRememberPage(
    user: UserProfile,
): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
        case UserRole.User:
        default:
            return false;
    }
}

// If there is no case in context, then just make sure this is a GOM or FH user
// If there is a case in context, then make sure the user has access to that FH
export function canToggleVisibleToFamily(
    user: UserProfile | null,
    funeralHomeId?: number,
): boolean {
    if (!user) {
        return false;
    } else if (funeralHomeId === undefined) {
        return UserRoles.isFHorGOMUser(user);
    } else {
        return UserRoles.isFHUserOnFH(user, funeralHomeId);
    }
}

export function canConfigureRememberPage(params: {
    user: UserProfile | null;
    gatherCase: Pick<GatherCaseUX, 'id' | 'funeral_home_id'>;
    tasks: CaseTaskUX[];
}): boolean {
    return canUpdateCaseOptions(params);
}

export function canViewOrganizePage(params: {
    target: UserProfile | EntitySummary | null;
    funeralHomeId: number | null;
    caseId: number;
}): boolean {

    return canRetrievePrivateCaseData(params);
}

export function canDownloadLivestreamAsset(
    user: UserProfile,
): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
        case UserRole.User:
        default:
            return false;
    }
}

export function canUploadLivestreamAsset(
    user: UserProfile,
): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return true;
        case UserRole.User:
        default:
            return false;
    }
}

export function canDeleteLivestreamAsset(
    user: UserProfile,
): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return true;
        case UserRole.User:
        default:
            return false;
    }
}

export function canViewCaseAllHelpersReport(
    user: UserProfile,
    activeCaseId: number,
): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            return true;
        case UserRole.User:
            return UserRoles.isFamilyAdmin(user, activeCaseId);
        default:
            return false;
    }
}

export function canViewFullPersonPopper(
    user: UserProfile,
    target: UserProfile | EntitySummary,
    activeCaseId: number,
): boolean {

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
        case UserRole.FHUser:
            if (UserRoles.isFamilyVisitor(target, activeCaseId)) {
                return false;
            }
            return true;
        case UserRole.User:
            if (UserRoles.isFamilyVisitor(user, activeCaseId)) {
                return target.entity_id === user.entity_id;
            }
            return UserRoles.isFamilyAdmin(user, activeCaseId) || target.entity_id === user.entity_id;
        default:
            return false;
    }
}

export function canRetrievePrivateCaseData(params: {
    target: UserProfile | EntitySummary | null;
    funeralHomeId: number | null;
    caseId: number;
}): boolean {
    const { target, funeralHomeId, caseId } = params;
    const targetRole = UserRoles.getUserRole(target);
    if (!targetRole) {
        return false;
    }

    switch (targetRole) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            if (funeralHomeId === null) {
                return false;
            }
            return UserRoles.isFHUserOnFH(target, funeralHomeId);
        case UserRole.User:
            if (UserRoles.isFamilyAdmin(target, caseId)) {
                return true;
            }
            if (UserRoles.isFamilyGuest(target, caseId)) {
                const thisEntityCase = getCaseEntity(target, caseId);
                return Boolean(thisEntityCase && thisEntityCase.can_get_private_case_data);
            }
            if (UserRoles.isFamilyVisitor(target, caseId)) {
                return false;
            }
            return false;
        default:
            return false;
    }
}

export function canUpdateCase(user: UserProfile | null, gatherCase: Pick<GatherCaseUX, 'funeral_home_id'>): boolean {
    if (!user) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserUtils.hasFuneralHome(user, gatherCase.funeral_home_id);
        case UserRole.User:
        default:
            return false;
    }
}

export function canUpdateCaseForFuneralHome(
    user: UserProfile | null,
    gatherCase: GatherCaseUX,
    funeralHomeId: number,
): boolean {
    if (!user || gatherCase.funeral_home.id !== funeralHomeId) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(user, funeralHomeId);
        case UserRole.User:
        default:
            return false;
    }
}

export function canUserAccessTask(params: {
    tasks: CaseTaskUX[];
    user: UserProfile;
    templateType: TaskTemplateType;
}): boolean {
    const { tasks, user, templateType } = params;
    const task = tasks.find(t => t.template_type === templateType);
    if (!task) {
        return false;
    }
    // Admins and Guests that are assigned
    const taskAssignedToUser = Boolean(task.assigned_to.find((assignee) => assignee.user_id === user.user_id));
    return UserRoles.isFamilyAdmin(user, task.gather_case_id) ||
        task.assigned_to_all || taskAssignedToUser;
}

export function canUpdateCaseOptions(params: {
    user: UserProfile | null;
    gatherCase: Pick<GatherCaseUX, 'id' | 'funeral_home_id'>;
    tasks: CaseTaskUX[];
}): boolean {
    const { user, gatherCase, tasks } = params;
    if (!user) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(user, gatherCase.funeral_home_id);
        case UserRole.User:
            if (UserRoles.isInvitedFamilyOnCase(user, gatherCase.id)) {
                return canUserAccessTask({
                    tasks,
                    user,
                    templateType: TaskTemplateType.death_certificate,
                });
            }
            return false;
        default:
            return false;
    }
}

export function canLockDeathCertificate(user: UserProfile, gatherCase: GatherCaseUX): boolean {
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(user, gatherCase.funeral_home_id);
        case UserRole.User:
            return false;
        default:
            return false;
    }
}

export function canUnlockDeathCertificate(user: UserProfile, gatherCase: GatherCaseUX): boolean {
    return canLockDeathCertificate(user, gatherCase);
}

export function canMoveCase(params: {
    user: UserProfile | null;
    gatherCase: GatherCaseUX;
    destFuneralHomeId: number;
}): boolean {
    const { user, gatherCase, destFuneralHomeId } = params;
    if (!user) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            if (!UserRoles.isFHUserOnFH(user, gatherCase.funeral_home_id)) {
                return false;
            }
            // destination FH has to be in the same funeral_home_group of the owning FH
            if (gatherCase.funeral_home.group.funeral_homes.every((fh) => fh.id !== destFuneralHomeId)) {
                return false;
            }
            return true;
        case UserRole.User:
        default:
            return false;
    }
}

export function canDeleteCase(user: UserProfile | null, gatherCase: GatherCaseUX): boolean {
    if (!user) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserUtils.hasFuneralHome(user, gatherCase.funeral_home_id);
        case UserRole.User:
        default:
            return false;
    }
}

export const canEditContract = (user: UserProfile, contract: ProductContractUX) => {
    return UserRoles.isFHorGOMUser(user) || contract.created_by === user.id;
};

// ---> DocPacket <---
export function canCreateDocPacket(user: UserProfile, funeralHomeId: number): boolean {
    if (UserRoles.isGOMUser(user)) {
        return true;
    } else if (UserRoles.isFHUserOnFH(user, funeralHomeId)) {
        return true;
    } else {
        return false;
    }
}

export function canRetrieveDocPackets(user: UserProfile, funeralHomeId: number): boolean {
    if (UserRoles.isFHUser(user)) {
        return UserUtils.hasFuneralHome(user, funeralHomeId);
    } else {
        return true;
    }
}

export function canUpdateDocPacket(user: UserProfile, funeralHomeId: number): boolean {
    return canCreateDocPacket(user, funeralHomeId);
}

export function canDeleteDocPacket(user: UserProfile, funeralHomeId: number): boolean {
    return canCreateDocPacket(user, funeralHomeId);
}

export function canEditCaseEvent(
    user: UserProfile,
    gatherCaseEvent: GatherEventRecord,
    funeralHomeId: number,
): boolean {

    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, funeralHomeId)) {
        return true;
    }
    if (UserRoles.isInvitedFamilyOnCase(user, gatherCaseEvent.gather_case_id) && gatherCaseEvent.editable_by_family) {
        return true;
    }
    return false;
}

// TODO(Jonathan): use Josh's "isFamilyOnAnyCase" function as well
// to cover users from other cases posting memories.
interface CanCreateMemoryParams {
    funeralHomeId: number;
    caseId: number;
}

export function canCreateMemory(
    user: UserProfile | null,
    params: CanCreateMemoryParams | null,
): boolean {
    if (!user || !params) {
        return false;
    }
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(user, params.funeralHomeId); // change to isFHUserOnFH
        case UserRole.User: // include visitors
            return UserRoles.isFamilyOnCase(user, params.caseId);
        default:
            return false;
    }
}

export function canFlagContent(user: UserProfile | null): boolean {
    return Boolean(user);
}

export function canUpdateMemory(user: UserProfile, memory: MemoryUX): boolean {
    return user.id === memory.user_id;
}

export function canDeleteMemory(user: UserProfile, memory: MemoryUX): boolean {
    return UserRoles.isGOMUser(user) || user.id === memory.user_id;
}

export function canDeleteModerationItem(user: UserProfile | null, item: ModerationItem): boolean {
    return UserRoles.isGOMUser(user);
}

export function canViewAutoModerationDetails(user: UserProfile | null): boolean {
    return UserRoles.isGOMUser(user);
}

/**
 * Application Specific access 
 * 
 */

/**
 * canViewContract
 *     If GOM: true (currently to be changed in the future)
 *     If FH: check canViewCaseFinancialData 
 *     If Helper: see if they are assigned as a contract viewer
 */
export function canViewContract(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
    gatherCaseId: number | null,
): boolean {
    if (!user || !funeralHomeId || !gatherCaseId) {
        return false;
    }
    const userRole = UserRoles.getUserRole(user);

    switch (userRole) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return canViewCaseFinancialData(user, funeralHomeId);
        default:
            const caseEntity = (user.cases || []).find(u => u.gather_case_id === gatherCaseId);
            if (UserRoles.isFamilyVisitor(user, gatherCaseId)) {
                return false;
            }
            return Boolean(caseEntity && caseEntity.can_view_contract);
    }
}

/**
 * canManageDD214
 *     If GOM: true (currently to be changed in the future)
 *     If FH: check canViewCaseAutoforms 
 *     If Helper: see if they are assigned to the task or a family admin
 */
export function canManageDD214OnCase(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
    gatherCaseId: number | null,
    tasks: CaseTaskUX[],
): boolean {
    if (!user || !funeralHomeId || !gatherCaseId || !tasks) {
        return false;
    }
    const userRole = UserRoles.getUserRole(user);

    switch (userRole) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return canViewCaseAutoforms(user, funeralHomeId);
        default:
            const task = tasks.find(t => t.template_type === TaskTemplateType.form_dd_214);
            if (!task) {
                return false;
            }
            if (UserRoles.isFamilyVisitor(user, gatherCaseId)) {
                return false;
            }
            // Family Admins OR Guests that are assigned
            const taskAssignedToUser = Boolean(task.assigned_to.find((assignee) => assignee.user_id === user.user_id));
            return UserRoles.isFamilyAdmin(user, task.gather_case_id) ||
                task.assigned_to_all || taskAssignedToUser;
    }
}

export function canAdjustTheme(user: UserProfile | EntitySummary | null, activeCase: GatherCaseUX) {
    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, activeCase.funeral_home_id)) {
        return true;
    }
    if (UserRoles.isFamilyAdmin(user, activeCase.id)) {
        return true;
    }
    return false;
}

export function canDownloadCasePhotos(user: UserProfile | EntitySummary | null, activeCase: GatherCaseUX) {
    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, activeCase.funeral_home_id)) {
        return true;
    }
    if (UserRoles.isFamilyAdmin(user, activeCase.id)) {
        return true;
    }
    return false;
}

export function canUploadCoverPhoto(params: {
    user: UserProfile | EntitySummary | null;
    activeCase: GatherCaseUX | GatherCasePublic;
    tasks: CaseTaskUX[];
}) {
    const { user, activeCase, tasks } = params;
    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, activeCase.funeral_home.id)) {
        return true;
    }
    if (UserRoles.isFamilyAdmin(user, activeCase.id)) {
        return true;
    }
    if (UserRoles.isInvitedFamilyOnCase(user, activeCase.id)) {
        const task = tasks.find(t => t.template_type === TaskTemplateType.profile_and_cover_photos);
        if (!task) {
            return false;
        }
        // Guests that are assigned to the task
        const taskAssignedToUser = Boolean(task.assigned_to.find((assignee) => assignee.user_id === user.user_id));
        return task.assigned_to_all || taskAssignedToUser;
    }

    return false;
}

export function canUploadCasePhoto(params: {
    user: UserProfile | EntitySummary | null;
    activeCase: GatherCaseUX | GatherCasePublic;
}) {
    const { user, activeCase } = params;

    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, activeCase.funeral_home.id)) {
        return true;
    }
    if (UserRoles.isInvitedFamilyOnCase(user, activeCase.id)) {
        return true;
    }
    if (UserRoles.isFamilyVisitor(user, activeCase.id)) {
        return true;
    }
    return false;
}

export function canEditCasePhoto(params: {
    user: UserProfile | EntitySummary | null | undefined;
    activeCase: GatherCaseUX | GatherCasePublic;
    entry: AlbumEntryMappedType | null | undefined;
}) {
    const { user, activeCase, entry } = params;

    if (!user || !entry) {
        return false;
    }
    // Any user can edit their own uploaded photos
    if (user.user_id === entry.uploaded_by) {
        return true;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, activeCase.funeral_home.id)) {
        return true;
    }
    if (UserRoles.isInvitedFamilyOnCase(user, activeCase.id)) {
        return true;
    }
    return false;
}

export function canDeleteCasePhoto(params: {
    user: UserProfile | EntitySummary | null;
    activeCase: GatherCaseUX | GatherCasePublic;
    entry: AlbumEntryMappedType;
}) {
    const { user, activeCase, entry } = params;

    if (!user) {
        return false;
    }
    // Any user can delete their own uploaded photos
    if (user.user_id === entry.uploaded_by) {
        return true;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isFHUserOnFH(user, activeCase.funeral_home.id)) {
        return true;
    }
    if (UserRoles.isInvitedFamilyOnCase(user, activeCase.id)) {
        return true;
    }
    return false;
}

/**
 *  End Application Specific Access
 */

function checkFHUserPermission(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
    permission: Permission,
): boolean {
    if (!user || funeralHomeId === null) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (!user.funeral_homes) {
        return false;
    }
    const thisFuneralHomeDetails = getUserFuneralHome(user, funeralHomeId);
    if (thisFuneralHomeDetails && thisFuneralHomeDetails.permissions) {
        return Boolean(thisFuneralHomeDetails.permissions[permission]);
    }
    return false;
}

/**
 *  App Permissions
 *  Funeral Home Permissions (user_funeral_home table)
 *
 * @param user 
 * @param funeralHomeId 
 */

export function canManageFuneralHomeUserPermission(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.manage_permission);
}

export function canRemoveOtherTeamMembers(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.remove_other_team_members);
}

export function canInviteOtherTeamMembers(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.invite_other_team_members);
}

export function canBeAssignedToCase(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.be_assigned_to_case);
}

export function canViewCaseFinancialData(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.view_case_financial_data);
}

export function canViewCaseAutoforms(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.view_case_autoforms);
}

export function canViewCaseVitalsForCase(
    user: UserProfile | null, funeralHomeId: number | null, caseId: number | null, tasks: CaseTaskUX[]
): boolean {
    if (!user || !funeralHomeId || !caseId) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (UserRoles.isInvitedFamilyOnCase(user, caseId)) {
        return canUserAccessTask({
            tasks,
            user,
            templateType: TaskTemplateType.death_certificate,
        });
    }
    // FHUsers must have funeral_homes 
    if (!user.funeral_homes) {
        return false;
    }
    return checkFHUserPermission(user, funeralHomeId, Permission.view_case_vitals);
}

export function canViewCaseVitalsForAllCases(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.view_case_vitals);
}

export function canViewMonthlyFinancialRollup(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.view_monthly_financial_rollup);
}

export function canAccessLegacyReports(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return Boolean(
        canAccessRevenueReport(user, funeralHomeId)
        || canAccessPaymentReport(user, funeralHomeId)
        || canAccessBatchReport(user, funeralHomeId)
        || canAccessCasesReport(user, funeralHomeId)
        || canAccessHelpersReport(user, funeralHomeId)
        || canAccessRolodexReport(user, funeralHomeId)
    );
}

export function canAccessRevenueReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_revenue);
}

export function canAccessPaymentReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_payment);
}

export function canAccessBatchReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_batch);
}

export function canAccessCasesReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_cases);
}

export function canAccessHelpersReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_helpers);
}

export function canAccessRolodexReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_rolodex);
}

export function canAccessKeeptrackReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.access_report_keeptrack);
}

export function canAccessCollectionsReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.COLLECTIONS_REPORT);
}

export function canAccessOverpaidContractsReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.OVERPAID_CONTRACTS);
}

export function canAccessMerchSalesReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.MERCH_SALES_REPORT);
}

export function canAccessDiscountsReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.DISCOUNTS_REPORT);
}

export function canAccessPriceAdjustmentsReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.PRICE_ADJUSTMENTS_REPORT);
}

export function canAccessWorkflowByCaseReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.WORKFLOW_BY_CASE_REPORT);
}

export function canAccessSalesTaxReport(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.SALES_TAX_REPORT);
}

export function canModerate(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    if (!user) {
        return false;
    }
    if (UserRoles.isGOMUser(user)) {
        return true;
    }
    if (funeralHomeId && UserRoles.isFHUserOnFH(user, funeralHomeId)) {
        return true;
    }
    return false;
}

export function canModifyWorkflows(
    user: UserProfile | EntitySummary | null, funeralHomeId: number | null
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.modify_workflows);
}

export function canCompleteTrackingSteps(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.complete_tracking_steps);
}

export function canModifyTaskDueDates(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return Boolean(user && (UserRoles.isGOMUser(user) || UserRoles.isFHUserOnFH(user, funeralHomeId)));
}

export function canSkipTrackingSteps(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.skip_tracking_steps);
}

export function canEditTrackingPrerequisite(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.edit_tracking_prerequisite);
}

export function canDiscountContractItems(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CONTRACT_ITEM_DISCOUNTS);
}

export function canModifyContractItemName(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CONTRACT_MODIFY_ITEM_NAME);
}

export function canDiscountContract(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CONTRACT_DISCOUNTS);
}

export function canModifyPrices(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.MODIFY_PRICES);
}

export function canAddPayments(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.ADD_PAYMENTS);
}

export function canEditPayments(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.EDIT_PAYMENTS);
}

export function canRemovePayments(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.REMOVE_PAYMENTS);
}

export function canEditGPL(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.EDIT_GPL);
}

export function canUnfreezeContract(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CONTRACT_UNFREEZE);
}

export function canAddOneOffContractItems(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CONTRACT_ONE_OFF_ITEMS);
}

export function canAddContractItems(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.ADD_ITEMS);
}

export function canRemoveContractItems(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.REMOVE_ITEMS);
}

export function canAccessHelperReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.HELPER_REPORT);
}

export function canAccessInformantReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.INFORMANT_REPORT);
}

export function canAccessPriceListReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.PRICE_LIST);
}

export function canAccessCaseSummaryReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CASE_SUMMARY);
}

export function canAccessDispatchReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.DISPATCH_REPORT);
}

export function canAccessPrepReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.PREP_REPORT);
}

export function canAccessInsurancePoliciesReport(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return canViewCaseFinancialData(user, funeralHomeId);
}

/**
 * End FuneralHome Level App Permissions
 */

/**
 * obituaryLink Permissions
 */
export function canModifyObituaryLinks(user: UserProfile | null, gatherCase: GatherCaseUX | GatherCasePublic): boolean {
    if (!user) {
        return false;
    }

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return UserRoles.isFHUserOnFH(user, gatherCase.funeral_home.id);

        case UserRole.User:
            if (!gatherCase) {
                console.warn('No caseId passed for Family User');
                return false;
            }
            return UserRoles.isFamilyAdmin(user, gatherCase.id);

        default:
            return false;
    }
}

/**
 * End of obituaryLink permissions
 */

/**
 * Website Sync Permissions
 */
export function canSyncCase(user: UserProfile | null, funeralHomeId: number | null): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

/**
 * End of Website Sync Permissions
 */

export function canViewCaseIdPhotos(params: {
    user: UserProfile | null;
    funeralHomeId: number | null;
    gatherCaseId: number | null;
}): boolean {
    const { user, funeralHomeId } = params;
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canUploadCaseIdPhotos(params: {
    user: UserProfile | null;
    funeralHomeId: number | null;
    gatherCaseId: number | null;
}): boolean {
    const { user, funeralHomeId } = params;
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canDeleteCaseIdPhotos(params: {
    user: UserProfile | null;
    funeralHomeId: number | null;
    gatherCaseId: number | null;
}): boolean {
    const { user, funeralHomeId } = params;
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

// caseLabels
export function canAdminCaseLabels(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return checkFHUserPermission(user, funeralHomeId, Permission.CASE_LABELS);
}

export function canAccessCaseLabel(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canCreateCaseLabelCase(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canDeleteCaseLabelCase(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

// fingerprints
export function canCreateFingerprint(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canUpdateFingerprint(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canDeleteFingerprint(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canAccessFingerprint(user: UserProfile, gatherCase: GatherCaseUX): boolean {
    return canRetrievePrivateCaseData({
        target: user,
        funeralHomeId: gatherCase.funeral_home_id,
        caseId: gatherCase.id,
    });
}

// taskLocations
export function canCreateTaskLocation(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canUpdateTaskLocation(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canDeleteTaskLocation(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canAccessTaskLocation(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

// whiteboard
export function canAccessWhiteboard(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

export function canUpdateWhiteboard(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

// stickers
export function canPrintQrStickers(user: UserProfile, funeralHomeId: number): boolean {
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
}

// keeptrack tags
export const canLoadAssignKeepTrackPage = (params: {
    user: UserProfile | null;
}): boolean => {
    const { user } = params;
    return UserRoles.isFHorGOMUser(user);
};

export const canCheckKeeptrackTagAssignable = (params: {
    user: UserProfile | null;
}): boolean => {
    const { user } = params;
    return UserRoles.isFHorGOMUser(user);
};

export const canAssignKeeptrack = (params: {
    user: UserProfile;
    funeralHomeId: number;
}): boolean => {
    const { user, funeralHomeId } = params;
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
};

export const canAssignAdditionalKeepsake = (params: {
    user: UserProfile;
    funeralHomeId: number;
}): boolean => {
    const { user, funeralHomeId } = params;
    return UserRoles.isFHorGOMUserOnFH(user, funeralHomeId);
};

export const canRefundPayments = (params: {
    user: UserProfile;
    funeralHomeId: number;
}): boolean => {
    const { user, funeralHomeId } = params;
    return canEditPayments(user, funeralHomeId);
};

export const canLockObituary = (params: {
    user: UserProfile | null;
    funeralHomeId: number;
}) => {
    if (!params.user) {
        return false;
    }

    return UserRoles.isFHorGOMUserOnFH(params.user, params.funeralHomeId);
};

/* Reports */

export function canAccessReportDataSource(params: {
    datasource: ReportDataSource;
    user: UserProfile | EntitySummary | null;
    funeralHomeId: number | null;
}): boolean {
    const { datasource, user, funeralHomeId } = params;
    const permissionLookup: Record<ReportDataSource, Permission> = {
        [ReportDataSource.case_non_financial]: Permission.DATA_SOURCE_CASE_NON_FINANCIAL,
        [ReportDataSource.case_financial]: Permission.DATA_SOURCE_CASE_FINANCIAL,
        [ReportDataSource.case_vitals]: Permission.DATA_SOURCE_CASE_VITALS,
        [ReportDataSource.payments]: Permission.DATA_SOURCE_PAYMENTS,
        [ReportDataSource.events]: Permission.DATA_SOURCE_EVENTS,
        [ReportDataSource.helpers]: Permission.DATA_SOURCE_HELPERS,
        [ReportDataSource.invoice_line_items]: Permission.DATA_SOURCE_INVOICE_LINE_ITEMS,
        [ReportDataSource.notes]: Permission.DATA_SOURCE_NOTES,
        [ReportDataSource.task_and_step_activity]: Permission.DATA_SOURCE_TASK_STEP_ACTIVITY,
        [ReportDataSource.remember_page]: Permission.DATA_SOURCE_REMEMBER_PAGE,
        [ReportDataSource.insurance_policies]: Permission.DATA_SOURCE_INSURANCE_POLICIES,
    };
    return checkFHUserPermission(user, funeralHomeId, permissionLookup[datasource]);
}

export function canAccessGatherAnalytics(
    user: UserProfile | EntitySummary | null,
    funeralHomeId: number | null,
): boolean {
    return values(ReportDataSource).some((datasource) =>
        canAccessReportDataSource({ datasource, user, funeralHomeId }));
}

export const canRetrieveReport = (params: { user: UserProfile; report: ReportUX }) => {
    return UserRoles.isFHorGOMUser(params.user);
};

export const canListReports = (params: {
    user: UserProfile;
    funeralHomeId: number | null;
    isAdminQuery: boolean;
}) => {
    const { user } = params;
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            if (!params.funeralHomeId || params.isAdminQuery) {
                return false;
            }
            return UserRoles.isFHUserOnFH(user, Number(params.funeralHomeId));
        case UserRole.User:
            return false;
        default:
            return false;
    }
};

export const canCreateReport = (params: {
    user: UserProfile;
    reportRequest: ReportCreateRequest;
}): true | { error: string } => {
    const { user, reportRequest } = params;
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            const { funeral_home_ids_with_access, is_gather_report } = reportRequest;
            if (is_gather_report) {
                return { error: 'User does not have permission to create Gather reports' };
            } else if (funeral_home_ids_with_access === 'ALL') {
                return { error: 'Cannot grant report access to all funeral homes' };
            } else if (difference(funeral_home_ids_with_access, user.funeral_home_ids).length) {
                return { error: 'User does not have access to these funeral homes to grant access to the report' };
            } else {
                return true;
            }
        case UserRole.User:
            return { error: 'User does not have permission to create reports' };
        default:
            return { error: 'Unknown user type trying to create report' };
    }
};

export const canUpdateReport = (params: {
    user: UserProfile;
    existingReport: ReportUX;
    updateRequest: ReportUpdateRequest;
}): true | { error: string } => {
    const { user, existingReport, updateRequest } = params;
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            const {
                funeral_home_ids_with_access,
                is_featured_default,
                owned_by_user_id,
                is_gather_report,
            } = updateRequest;
            if (existingReport.owned_by !== user.user_id) {
                return { error: 'User does not own the report' };
            } else if (owned_by_user_id) {
                return { error: 'Cannot change the owner of a report' };
            } else if (is_featured_default) {
                return { error: 'Cannot change the featured default reports' };
            } else if (funeral_home_ids_with_access === 'ALL') {
                return { error: 'Cannot grant report access to all funeral homes' };
            } else if (funeral_home_ids_with_access &&
                difference(funeral_home_ids_with_access, user.funeral_home_ids).length) {
                    return { error: 'User does not have access to these funeral homes to grant access to the report' };
            } else if (is_gather_report !== undefined && is_gather_report !== existingReport.is_gather_report) {
                return { error: 'Cannot change if this is a Gather report or Custom report' };
            } else {
                return true;
            }
        case UserRole.User:
            return { error: 'User does not have permission to update reports' };
        default:
            return { error: 'Unknown user type trying to update report' };
    }
};

export const canDeleteReport = (params: {
    user: UserProfile;
    existingReport: ReportUX;
}) => {
    const { user, existingReport } = params;
    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return existingReport.owned_by === user.user_id;
        case UserRole.User:
            return false;
        default:
            return false;
    }
};

export const canSendReport = (params: {
    user: UserProfile;
    report: ReportUX;
}) => {
    const { user, report } = params;

    switch (user.role) {
        case UserRole.GOMSuperUser:
        case UserRole.GOMUser:
            return true;
        case UserRole.FHUser:
            return canRetrieveReport({ user, report });
        case UserRole.User:
            return false;
        default:
            return false;
    }
};

// insurance policies
export function canUpdatePolicies(user: UserProfile, funeralHomeId: number): boolean {
    return canViewCaseFinancialData(user, funeralHomeId);
}

export function canAccessPolicies(user: UserProfile | EntitySummary | null, funeralHomeId: number): boolean {
    return canViewCaseFinancialData(user, funeralHomeId);
}
