import * as React from 'react';
import classNames from 'classnames';

import Hidden from '@mui/material/Hidden';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChatIcon from '@mui/icons-material/Chat';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Person from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import Backdrop from '@mui/material/Backdrop';

import { Location } from 'history';

import {
    UserRoles,
    StartupMessage,
    getFuneralHomeId,
    getGatherCaseId,
    EntitySummary,
} from '../../../shared/types';
import { StoreState } from '../../../types';

import CaseEvents from './CaseEvents';
import { acceptStartup } from '../../../actions/GatherCase.action';
import ChangeViewGSwitch from '../../family/ChangeViewGSwitch';
import { logoutUserSession } from '../../../actions/UserSession.action';
import { openMySettingsDialog } from '../../../actions/Dialog.action';
import StartupMessageDialog from '../../funeralHomes/funeralHomeSettings/startupMessage/StartupMessage.dialog';
import { loadTeamMembersTasks } from '../../../actions/Team.action';

import { convertHexToRGBA, FuneralHomeRoutePage, isRememberPage, RouteBuilder } from '../../../services';
import CaseSwitcherFooter from './CaseSwitcherFooter';
import { setRolodexListDialogOpen } from '../../../actions/Rolodex.action';
import PickTeamMember from '../../assignmentPoppers/PickTeamMember';
import GuestSection from './GuestSection';
import { loadCaseSwitcherData } from '../../../actions/FuneralHome.action';
import { log } from '../../../logger';
import { AppDispatch } from '../../../store';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import FuneralHomeLogo from '../../common/FuneralHomeLogo';
import withState from '../../common/utilHOC/WithState';
import withLocation from '../../common/utilHOC/WithLocation';
import { compose } from 'redux';
import GPopper from '../../common/GPopper';
import GLink from '../../common/GLink';
import { withSpeedDialActions, WithSpeedDialActionsProps } from '../../common/utilHOC/WithSpeedDialActions';
import { GStyles } from '../../../styles/GStyles';

function mapStateToProps({
    casePreviewState,
    casesState,
    userSession,
    funeralHomeState,
    dialogState,
}: StoreState) {
    const { isOpen: isFinalizeTaskDialogOpen } = dialogState.finalizeTask;
    const { userData } = userSession;
    const { selectedCase, publicCase } = casesState;
    const { activeFuneralHome } = funeralHomeState;
    const funeralHomeId = getFuneralHomeId(activeFuneralHome, selectedCase, publicCase);
    const caseId = getGatherCaseId(selectedCase, publicCase);

    return {
        userSession,
        gatherCases: casePreviewState.data,
        isLoading: casePreviewState.isLoading,
        funeralHome: funeralHomeState.activeFuneralHome,
        isFHorGOMOnAnyFH: UserRoles.isFHorGOMUser(userData),
        isFHorGOMOnFH: UserRoles.isFHorGOMUserOnFH(userData, funeralHomeId),
        isInvitedFamilyOnCase: UserRoles.isInvitedFamilyOnCase(userData, caseId),
        isFamilyOnCase: Boolean(caseId && UserRoles.isFamilyOnCase(userData, caseId)),
        funeralHomeId,
        activeCase: casesState.selectedCase,
        isFinalizeTaskDialogOpen,
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    location: Location;
}

interface Props {
    popperAnchorEle: HTMLElement | null;
    closePopper: () => void;
    zIndex: number;
}

interface State {
    isYoutubeDialogOpen: boolean;
    isStartupMessageDialogOpen: boolean;
    isStartupSaving: boolean;
    selectedTeamMemberUserId: number | null;
    pickTMAnchorEl: HTMLElement | null;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        width: '100%',
    },
    familyDisplayFlex: {
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%'
    },
    footerLogo: {
        paddingBottom: 9,
        textAlign: 'center',
        width: '100%',
    },
    headerImageSize: {
        paddingTop: 9,
    },
    caseChangePopperContainer: {
        width: '96vw',
        maxWidth: 2000,
        height: '78vh',
        maxHeight: 800,
        minHeight: 300,
        boxShadow: theme.shadows[5],
        borderRadius: 12,
    },
    familyCaseChangePopperContainer: {
        width: 288,
        boxShadow: theme.shadows[5],
        '@media (min-width: 360px)': {
            width: '320px',
        },
    },
    headerCont: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    switchStyle: {
        height: 32,
        maxHeight: 32,
    },
    familySwitchHeight: {
        height: 48,
        maxHeight: 48,
        boxShadow: theme.shadows[4],
        backgroundColor: `${convertHexToRGBA(theme.palette.primary.main, 0.18)}`
    },
    headerSection: {
        display: 'flex',
        maxHeight: 32,
        minHeight: 32,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        boxShadow: theme.shadows[5],
        '@media (min-width: 600px)': {
            justifyContent: 'center',
        },
    },
    flexDashboardContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 8
    },
    carousalIcon: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none'
    },
    maxContent: {
        width: 'max-content'
    },
    filter: {
        '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
            background: 'rgba(255, 255, 255, 0.01)',
            backdropFilter: 'saturate(180%) blur(20px)',
            WebkitBackdropFilter: 'saturate(180%) blur(20px)',
        }
    },
    positionCancelButton: {
        position: 'absolute',
        right: 14,
        top: -47,
        width: 42,
        height: 42,
        zIndex: -1,
        background: `${theme.palette.common.white} !important`,
        '& svg': {
            fontSize: 52
        }
    },
    headerButton: {
        maxHeight: 'inherit',
        border: 'none',
        '& button': {
            padding: '0 8px'
        }
    },
    dashboardButton: {
        padding: '0 8px',
        '& p': {
            fontSize: 12,
            fontWeight: 500
        }
    },
    settingsButton: {
        marginLeft: 8,
        '& p': {
            fontSize: 12
        },
        '& svg': {
            fontSize: 20
        }
    },
    restrictedView: {
        height: 'fit-content',
        maxWidth: 380,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    carouselIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '30px 0px',
    },
    arrow: {
        left: '-3px !important',
        '@media (min-width: 600px)': {
            left: '5px !important',
        },
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            left: '-5px !important'
        },
    }
});

type StyledProps = Props & InjectedProps & WithSpeedDialActionsProps
    & WithStyles<'root' | 'familyDisplayFlex' | 'arrow' | 'popper'
        | 'footerLogo' | 'headerImageSize' | 'caseChangePopperContainer' | 'familyCaseChangePopperContainer'
        | 'headerCont' | 'switchStyle' | 'familySwitchHeight' | 'headerSection' | 'flexDashboardContent'
        | 'carousalIcon' | 'maxContent' | 'filter' | 'positionCancelButton' | 'headerButton' | 'dashboardButton'
        | 'settingsButton' | 'restrictedView' | 'carouselIcon'>;

class CaseSwitcher extends React.Component<StyledProps, State> {
    constructor(props: StyledProps) {
        super(props);
        const { userData } = props.userSession;
        this.state = {
            isYoutubeDialogOpen: false,
            isStartupMessageDialogOpen: false,
            isStartupSaving: false,
            selectedTeamMemberUserId: userData !== null ? userData.user_id : null,
            pickTMAnchorEl: null,
        };
    }

    componentDidUpdate(previousProps: StyledProps) {
        const {
            dispatch,
            userSession,
            popperAnchorEle,
            funeralHomeId,
            isFHorGOMOnFH,
            isFHorGOMOnAnyFH,
        } = this.props;
        const { userData } = userSession;

        if (popperAnchorEle && !previousProps.popperAnchorEle && funeralHomeId &&
            (isFHorGOMOnFH || !isFHorGOMOnAnyFH)
        ) {
            dispatch(loadCaseSwitcherData(userSession, funeralHomeId));
            this.setState({
                selectedTeamMemberUserId: userData !== null ? userData.user_id : null,
            });
        }
    }

    logoutUserSession = () => {
        const { dispatch } = this.props;
        dispatch(logoutUserSession());
    };

    openMySettingsDialog = () => {
        const { dispatch, userSession, zIndex } = this.props;

        if (userSession.userData) {
            dispatch(openMySettingsDialog(zIndex + 1, userSession.userData.entity_id));
        }

        this.closePopper();
    };

    renderToggleBar = () => {
        const { classes, isFHorGOMOnAnyFH } = this.props;
        const tooltipTitle = `Use this option to view what the family sees when logged into Gather.`;

        return (
            <Hidden only={'xs'}>
                <ChangeViewGSwitch
                    customClass={!isFHorGOMOnAnyFH
                        ? classes.familySwitchHeight
                        : classes.switchStyle
                    }
                    tooltipTitle={tooltipTitle}
                />
            </Hidden>
        );
    };

    renderStartupMessageItem = () => {
        const { activeCase } = this.props;

        if (!activeCase || !activeCase.funeral_home.is_startup_enabled) {
            return (<></>);
        }

        return (
            <ListItem
                button
                onClick={() => this.setState({ isStartupMessageDialogOpen: true })}
            >
                <ListItemIcon>
                    <ChatIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: GStyles.colorPrimary }}
                    primary="View Welcome Message"
                />
            </ListItem>
        );
    };

    closeStartupMessageDialog = async (caseUuid: string) => {
        const { isStartupMessageDialogOpen } = this.state;

        /**
         * this means that welcome message dialog was manually triggered from TOP drop-down list
         * we should avoid the unnecessary API-HIT
         */
        if (isStartupMessageDialogOpen) {
            this.setState({ isStartupMessageDialogOpen: false });
            return;
        }

        const { dispatch } = this.props;

        this.setState({ isStartupSaving: true });
        await dispatch(acceptStartup(caseUuid));
        this.setState({ isStartupSaving: false });
    };

    renderStartupMessageDialog = () => {
        const { activeCase, userSession, zIndex, isInvitedFamilyOnCase } = this.props;
        const { isStartupSaving, isStartupMessageDialogOpen } = this.state;

        if (!activeCase || !userSession.userData) {
            return null;
        }

        const { cases } = userSession.userData;
        const {
            is_startup_enabled,
            startup_button_text,
            startup_main_message,
            startup_headline_title,
            startup_documents_header,
            startup_button_helper_text,
        } = activeCase.funeral_home;

        const startupMessage: StartupMessage = {
            is_startup_enabled,
            startup_button_helper_text,
            startup_button_text,
            startup_documents_header,
            startup_headline_title,
            startup_main_message
        };

        /**
         * open preview dialog only for invited family users (excludes visitors), 
         * and only for that user who have not yet accepted the startup terms set by FH members
         * Double check and show the dialog only and only if the case Id mateches
         */
        const entityCaseSummary = cases && cases.find((c) => c.gather_case_id === activeCase.id);
        const _isStartupMessageDialogOpen = userSession.userData
            && isInvitedFamilyOnCase
            && is_startup_enabled
            && entityCaseSummary
            && entityCaseSummary.has_accepted_startup === false
            && entityCaseSummary.gather_case_id === activeCase.id;

        return (
            <StartupMessageDialog
                funeralHome={activeCase.funeral_home}
                caseId={activeCase.id}
                caseUuid={activeCase.uuid}
                startupMessage={startupMessage}
                isOpen={Boolean(isStartupMessageDialogOpen || _isStartupMessageDialogOpen)}
                closeDialog={() => this.closeStartupMessageDialog(activeCase.uuid)}
                isSaving={isStartupSaving}
                zIndex={zIndex + 1}
            />
        );
    };

    mySettingsButton = () => {
        const { classes, isFHorGOMOnFH } = this.props;
        return (
            <Grid
                item
                className={classNames(classes.headerCont, classes.headerButton)}
            >
                {isFHorGOMOnFH &&
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.openMySettingsDialog}
                        className={classNames(classes.maxContent, classes.settingsButton)}
                    >
                        <Person /> &nbsp;
                        <Hidden only={['xs']}>
                            <span>My</span>
                        </Hidden>
                        <span>&nbsp;Settings</span>
                    </Button>
                }
            </Grid>);
    };

    handlePickTM = (tm: EntitySummary | null) => {
        const { dispatch, funeralHomeId } = this.props;

        if (!tm) {
            return;
        }

        if (!funeralHomeId) {
            log.warn('Somehow no funeralHomeId', { tm });
            return;
        }
        if (!tm.user_id) {
            log.warn('Somehow team member does not have user_id', { tm });
            return;
        }

        dispatch(loadTeamMembersTasks(funeralHomeId, tm.user_id));

        this.setState({
            selectedTeamMemberUserId: tm.user_id,
        });
    };

    renderMegaMenuTopButtons = () => {
        const { classes, funeralHome } = this.props;

        return (
            <div className={classes.headerSection}>
                {this.mySettingsButton()}
                {this.renderToggleBar()}
                <Grid
                    item
                    className={
                        classNames(
                            classes.flexDashboardContent,
                            classes.maxContent,
                        )}
                >
                    {funeralHome &&
                        <Button
                            size="small"
                            color="primary"
                            className={classNames(classes.dashboardButton)}
                        >
                            <GLink
                                to={RouteBuilder.FuneralHome(funeralHome.key, FuneralHomeRoutePage.MYCASES)}
                                linkClass={classes.carousalIcon}>
                                <Typography color="primary" component="p">
                                    DASHBOARD&nbsp;
                                </Typography>
                                <ViewCarouselIcon color="primary" />
                            </GLink>
                        </Button>
                    }
                </Grid>

            </div>
        );
    };

    renderCaseSwitcherFooter = () => {
        const {
            funeralHome,
            gatherCases,
            isLoading,
            isFHorGOMOnAnyFH,
            isFHorGOMOnFH,
            userSession,
            activeCase,
        } = this.props;

        if (!funeralHome) {
            return null;
        };

        const isGuestUser = activeCase && UserRoles.isFamilyGuest(userSession.userData, activeCase.id);

        const isOnRememberPage = isRememberPage();

        return (
            <CaseSwitcherFooter
                gatherCases={gatherCases}
                isLoading={isLoading}
                isFHorGOMOnAnyFH={isFHorGOMOnAnyFH}
                isFHorGOMOnFH={isFHorGOMOnFH}
                isOnRememberPage={isOnRememberPage}
                openRolodexDialog={this.openRolodexDialog}
                isGuestUser={isGuestUser}
                funeralHome={funeralHome}
            />
        );
    };

    renderFamilyUserContent = () => {
        const {
            classes,
            userSession,
            activeCase,
            isInvitedFamilyOnCase,
            isFamilyOnCase,
            zIndex,
            speedDialActions
        } = this.props;

        if (!userSession.userData) {
            return null;
        }

        return (
            <>
                <Grid
                    item
                    xs={12}
                >
                    {this.renderCaseSwitcherFooter()}
                </Grid>
                <GuestSection
                    activeCase={activeCase}
                    isInvitedFamilyOnCase={isInvitedFamilyOnCase}
                    isFamilyOnCase={isFamilyOnCase}
                    userData={userSession.userData}
                    zIndex={zIndex}
                    closePopper={this.closePopper}
                    logoutUserSession={this.logoutUserSession}
                    openMySettingsDialog={this.openMySettingsDialog}
                    renderStartupMessageItem={this.renderStartupMessageItem}
                    speedDialActions={speedDialActions}
                />
                <Grid item xs={12}>
                    <div className={classes.footerLogo}>
                        <FuneralHomeLogo className={classes.headerImageSize} />
                    </div>
                </Grid>
            </>
        );
    };

    renderRestrictedAccessForFHUser = () => {
        const {
            classes,
            userSession,
            funeralHome,
        } = this.props;

        if (!userSession.userData) {
            return null;
        }

        const funeralHomeKey = funeralHome ? funeralHome.key : undefined;
        const dashboardLink = funeralHomeKey
            ? RouteBuilder.FuneralHome(funeralHomeKey, FuneralHomeRoutePage.MYCASES)
            : RouteBuilder.Root;

        return (
            <Grid item xs={12} display="inline-flex">
                <div className={classes.restrictedView}>
                    <Typography color="primary" >Hi {userSession.userData.fname}.</Typography>
                    <Typography color="primary" >This menu is not available because you are currently
                        viewing a case that you don't have access to.
                    </Typography>

                    <GLink
                        to={dashboardLink}
                        linkClass={classes.carousalIcon}
                    >
                        <Typography color="primary" className={classes.carouselIcon}>
                            <ViewCarouselIcon />
                            Return to My Dashboard
                        </Typography>
                    </GLink>

                    <Typography color="primary" >
                        <span
                            className={classNames(
                                GStyles.textUnderline,
                                GStyles.cursorPointer
                            )}
                            onClick={this.logoutUserSession}
                        >
                            Click here
                        </span>&nbsp;
                        to logout of your account
                    </Typography>
                </div>
            </Grid>
        );
    };

    renderCaseSwitcherData = () => {
        const {
            gatherCases,
            activeCase,
            popperAnchorEle,
            isFHorGOMOnAnyFH,
            isFHorGOMOnFH,
            zIndex,
            funeralHome,
        } = this.props;

        const { selectedTeamMemberUserId } = this.state;

        if (!isFHorGOMOnAnyFH) {
            return this.renderFamilyUserContent();
        }

        if (isFHorGOMOnAnyFH && !isFHorGOMOnFH) {
            return this.renderRestrictedAccessForFHUser();
        }

        if (isFHorGOMOnFH && popperAnchorEle) {
            return (
                <>
                    <CaseEvents
                        scrollContents={Boolean(popperAnchorEle)}
                        isOpen={!!popperAnchorEle}
                        isFHorGOMOnAnyFH={isFHorGOMOnAnyFH}
                        funeralHome={funeralHome}
                        activeCase={activeCase}
                        gatherCases={gatherCases}
                        openPickTeamMember={(e) => this.setState({ pickTMAnchorEl: e.currentTarget })}
                        selectedTeamMemberUserId={selectedTeamMemberUserId}
                        zIndex={zIndex}
                    />
                    {this.renderCaseSwitcherFooter()}
                </>
            );
        }
        return;
    };

    render() {
        const {
            classes,
            userSession,
            popperAnchorEle,
            isFHorGOMOnAnyFH,
            isFHorGOMOnFH,
            zIndex,
            isFinalizeTaskDialogOpen,
        } = this.props;

        if (!userSession.userData) {
            return null;
        }

        const { pickTMAnchorEl } = this.state;

        return (
            <>
                <Backdrop
                    open={!!popperAnchorEle && isFHorGOMOnAnyFH}
                    hidden={!isFHorGOMOnAnyFH || !popperAnchorEle}
                    className={classes.filter}
                    style={{ zIndex }}
                />

                <GPopper
                    anchorEle={popperAnchorEle}
                    paperClass={
                        isFHorGOMOnAnyFH
                            ? classes.caseChangePopperContainer
                            : classes.familyCaseChangePopperContainer
                    }
                    zIndex={zIndex}
                    className={classes.popper}
                    closePopper={e => {
                        if (pickTMAnchorEl || isFinalizeTaskDialogOpen) {
                            // TODO(MUI v5): We should look at moving the PickTeamMember component
                            // down the component tree to where it is needed so we don't have to
                            // do this work around to prevent the popper from closing when a TM is picked

                            return;
                        }

                        if (!!popperAnchorEle) {
                            this.closePopper();
                        }
                    }
                    }
                    placement='bottom'
                    arrowClass={classes.arrow}
                >
                    {popperAnchorEle &&
                        <>
                            {isFHorGOMOnAnyFH && popperAnchorEle &&

                                <IconButton
                                    className={classes.positionCancelButton}
                                    onClick={this.closePopper}

                                >
                                    <CancelIcon color="primary" />
                                </IconButton>
                            }

                            <Grid
                                container
                                direction={isFHorGOMOnAnyFH ? 'row' : 'column'}
                                wrap="nowrap"
                                className={classNames(
                                    classes.root,
                                    isFHorGOMOnAnyFH && classes.familyDisplayFlex,
                                )}
                            >
                                {isFHorGOMOnFH && this.renderMegaMenuTopButtons()}
                                {this.renderCaseSwitcherData()}
                            </Grid>
                        </>
                    }
                    <PickTeamMember
                        zIndex={zIndex + 1}
                        anchorEl={pickTMAnchorEl}
                        onAssign={this.handlePickTM}
                        handleClose={() => this.setState({ pickTMAnchorEl: null })}
                        headerText={`View a team member's tasks...`}
                        anchorReference="anchorPosition"
                    />
                </GPopper>

                {this.renderStartupMessageDialog()}

            </>
        );
    }

    openRolodexDialog = () => {
        const { dispatch } = this.props;
        this.closePopper();
        dispatch(setRolodexListDialogOpen(true));
    };

    closePopper = () => {
        const eventListEle = document.getElementById('events-list');
        if (eventListEle) {
            eventListEle.scrollTop = 0;
        }
        this.props.closePopper();
    };
}

export default compose(
    withSpeedDialActions,
    withLocation,
    withState(mapStateToProps),
    withStyles(styles)
)(CaseSwitcher) as React.ComponentType<Props>;
