import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import UserAvatar from '../../../common/UserAvatar';
import * as React from 'react';
import { Theme } from '@mui/material';
import { GuestListUser } from '../../../../shared/types';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    userAvatar: {
        transform: 'translateY(2px)',
        zIndex: 2,
        width: 52,
        height: 52,
        overflow: 'visible',
    }
});

type StyledProps = WithStyles<'userAvatar'>;

interface Props {
    user: GuestListUser | null;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

type CombinedProps = Props & StyledProps;

const HangingAvatar = React.forwardRef(
    function HangingAvatar(props: CombinedProps, ref: React.Ref<HTMLDivElement>) {
        const { user, classes, onClick } = props;

        if (!user) {
            return (
                <div className={classes.userAvatar} ref={ref} />
            );
        }
        return (
            <UserAvatar
                ref={ref}
                user={user}
                photoClassName={classes.userAvatar}
                initialsClassName={classes.userAvatar}
                style={{
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.125))',
                    cursor: 'pointer'
                }}
                size={52}
                onClick={onClick}
            />
        );
    }
);

export default withStyles(styles)(HangingAvatar);
