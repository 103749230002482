import { Theme } from '@mui/material';
import { StyleRulesCallback } from '@mui/styles';

export const InvitationFormStyles: StyleRulesCallback<Theme, {}, 'leftSection' | 'formControlContainer'
    | 'height0' | 'flagIcon' | 'moveMeUp' | 'moveMeUpAndScaleMeOut' | 'scaleMeOut' | 'formInner'
    | 'leftSectionHeightTransition' | 'gmapSearch' | 'gmapSearchContainer' | 'controlSuggestionClasses'
    | 'guestTabLeftSectionHeight' | 'adminTabLeftSectionHeight' | 'isDeceased' | 'active'
    | 'textFeildWithIcon'> = (theme) => ({

        leftSection: {
            width: '100%',
            textAlign: 'center',
            maxWidth: 320,
            padding: '0 16px',
        },
        formControlContainer: {
            alignItems: 'center',
            minHeight: 60,
            '& $height0': {
                padding: 4,
            },
            '& $textFeildWithIcon': {
                padding: 4,
            },
        },
        height0: {
            height: 0
        },
        flagIcon: {
            position: 'absolute',
            width: 20,
            top: 24,
        },
        moveMeUp: {
            transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            transform: 'translateY(0)',
            '&$active': {
                transform: 'translateY(-100%)',
            }
        },
        moveMeUpAndScaleMeOut: {
            '&$active': {
                transform: 'scale(1) translateY(-100%) !important',
            }
        },
        scaleMeOut: {
            transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            transform: 'scale(0) translateY(0)',
            '&$active': {
                transform: 'scale(1) translateY(0)',
            }
        },
        formInner: {
            transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            transform: 'translateY(0)',
            '&$active': {
                transform: 'translateY(-73px)', // height of the living/deceased toggle buttons
            },
            height: '100%'
        },
        leftSectionHeightTransition: {
            '&$adminTabLeftSectionHeight': {
                transition: 'height 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                height: 342,
                '&$active': {
                    height: '282px !important'
                },
                '@media (min-width: 760px)': {
                    height: 'auto !important',
                }
            },
            '&$guestTabLeftSectionHeight': {
                zIndex: 1320,
                transition: 'height 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                height: 362,
                '&$active': {
                    height: 416
                },
                '&$isDeceased': {
                    height: 240
                },
                '@media (min-width: 760px)': {
                    height: 'auto !important',
                }
            },
        },
        gmapSearch: {
            width: '100%',
            maxWidth: 280,
            '@media (min-width: 421px)': {
                width: 330,
                maxWidth: 360,
            },
        },
        gmapSearchContainer: {
            transition: 'opacity 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            opacity: 0,
            pointerEvents: 'none',
            '&$active': {
                opacity: 1,
                pointerEvents: 'auto',
            }
        },
        controlSuggestionClasses: {
            position: 'absolute'
        },
        guestTabLeftSectionHeight: {},
        adminTabLeftSectionHeight: {},
        isDeceased: {},
        active: {},
        textFeildWithIcon: {},
    });