import * as React from 'react';
import classNames from 'classnames';

import {
    getPhotoUrl,
    DEFAULT_WIDTH,
    DEFAULT_HEIGHT,
    PhotoOrientationType,
    PhotoSizeType,
    LANDSCAPE_ASPECT_RATIO,
    PORTRAIT_ASPECT_RATIO,
    getPhotoUrlForDownload
} from '../../services';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { GatherPhoto, PhotoStatusEnum } from '../../types';

import { downloadPhoto } from '../../services';
import { openPhotoSwipeDialog } from '../../actions/PhotoSwipe.action';
import { CloudinaryTransformationsType, isAlbumEntry } from '../../shared/types';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    photo: {
        borderRadius: 4,
    },
    downloadIcon: {
        height: 40,
        width: 40,
        filter: 'drop-shadow(0 0px 2px gray)',
        color: '#fff',
        position: 'absolute',
        bottom: 8,
        left: 4,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)'
        },
    },
    deleteIcon: {
        height: 40,
        width: 40,
        color: '#fff',
        position: 'absolute',
        bottom: 8,
        right: 4,
        filter: 'drop-shadow(0 0px 2px gray)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)'
        },
    },
    imageBox: {
        width: '100%',
        height: '100%',
        position: 'relative',
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        borderRadius: 4,
        margin: 0,
        '@media (min-width: 368px)': {
            margin: '12px 4px',
        },
        '@media (min-width: 420px)': {
            margin: 12,
        },
        '& img': {
            boxShadow: theme.shadows[5],
        }
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '0 8px 8px',
    },
    loaderContainer: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        margin: 0,
    },
    transformMe: {
        cursor: 'pointer',
        transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        transform: 'scale(1)',
        '@media (min-width: 480px)': {
            '&:hover': {
                transform: 'scale(1.1)'
            }
        },

    }
});

type StyledProps = Props & WithStyles<'root' | 'photo' | 'deleteIcon' | 'imageBox'
    | 'loadingText' | 'loaderContainer' | 'downloadIcon' | 'transformMe'>;

interface Props {
    imageId?: string;
    photo: GatherPhoto;
    photos?: GatherPhoto[];
    downloadName?: string;
    hideDelete?: boolean;
    hideDownload?: boolean;
    disablePhotoSwipeOnClick?: boolean;
    photoOptions?: {
        orientation?: PhotoOrientationType;
        size?: PhotoSizeType;
        width?: number;
        height?: number;
    };
    imgStyle?: React.CSSProperties;
    imgContainerStyle?: React.CSSProperties;
    onPhotoClick?: (target?: HTMLElement) => void;
    onPhotoDelete?: () => void;
    imgClass?: string;
}

const Photo = (props: StyledProps) => {
    const {
        classes,
        photo,
        downloadName,
        hideDelete,
        hideDownload,
        onPhotoDelete,
        onPhotoClick,
        photoOptions,
        imgContainerStyle,
        imgStyle,
        imageId,
        disablePhotoSwipeOnClick,
        photos,
        imgClass,
    } = props;

    const dispatch = useDispatch();

    const renderLoader = () => {
        return (
            <div
                className={classes.loaderContainer}
                style={{
                    width: DEFAULT_WIDTH,
                    height: DEFAULT_HEIGHT
                }}
            >
                <CircularProgress
                    color="primary"
                    size={48}
                    thickness={3}
                />
                <Typography
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    Uploading...
                </Typography>
            </div>
        );
    };

    const renderActionButtons = () => {
        return <>
            {!hideDownload &&
                <IconButton
                    className={classes.downloadIcon}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        downloadPhoto(
                            photo.photo ? getPhotoUrlForDownload(photo.photo.public_id) : null,
                            downloadName || '',
                        );
                    }}
                    size="large">
                    <CloudDownloadIcon className={GStyles.colorWhite} />
                </IconButton>
            }
            {!hideDelete &&
                <IconButton
                    className={classes.deleteIcon}
                    onClick={onPhotoDelete ? () => onPhotoDelete() : undefined}
                    size="large">
                    <DeleteIcon
                        className={GStyles.colorWhite}
                    />
                </IconButton>
            }
        </>;
    };

    const width = photoOptions && photoOptions.width || DEFAULT_WIDTH;
    const height = photoOptions && photoOptions.height
        || Math.round((photoOptions
            && photoOptions.orientation === 'landscape'
            && DEFAULT_HEIGHT / LANDSCAPE_ASPECT_RATIO)
            || (photoOptions
                && photoOptions.orientation === 'portrait'
                && DEFAULT_HEIGHT / PORTRAIT_ASPECT_RATIO)
            || DEFAULT_HEIGHT);

    const transformations: CloudinaryTransformationsType[] = [{
        width: width,
        height: height,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];

    // if we have transformations add them
    if (isAlbumEntry(photo.photo) &&
        photo.photo.transformations && photo.photo.transformations.cloudinary) {
        transformations.unshift(photo.photo.transformations.cloudinary);
    }

    const sizeStyle = {
        width: (photoOptions && photoOptions.size === 'large' && width * 2)
            || (photoOptions && photoOptions.size === 'small' && width / 2)
            || width,
        height: (photoOptions && photoOptions.size === 'large' && height * 2)
            || (photoOptions && photoOptions.size === 'small' && height / 2)
            || 'auto'
    };

    const tooltip = disablePhotoSwipeOnClick ? '' : 'Click to view full-screen';
    return (
        <div
            className={classNames(
                classes.imageBox,
                onPhotoClick && GStyles.cursorPointer,
                disablePhotoSwipeOnClick || photo.status === PhotoStatusEnum.uploading
                    ? undefined : classes.transformMe
            )}
            style={{ ...sizeStyle, ...imgContainerStyle }}
        >
            {photo.status === PhotoStatusEnum.uploading ? renderLoader()
                : !photo.photo ? null :
                    <>
                        <Tooltip title={tooltip || ''} placement="top">
                            <img
                                src={getPhotoUrl(
                                    photo.photo.public_id,
                                    transformations
                                )}
                                className={classNames(classes.photo, imgClass)}
                                onClick={e => {
                                    if (onPhotoClick) {
                                        onPhotoClick(e.currentTarget);
                                    }

                                    if (disablePhotoSwipeOnClick) {
                                        return;
                                    }

                                    if (photos) {
                                        dispatch(openPhotoSwipeDialog(
                                            'GATHER_PHOTO',
                                            photo.photo && photo.photo.public_id || null,
                                            photos
                                        ));
                                    }
                                }}
                                style={{ ...sizeStyle, ...imgStyle }}
                                id={imageId}
                            />
                        </Tooltip>
                        {renderActionButtons()}
                    </>}
        </div>
    );
};

export default withStyles(styles)(Photo);
