import * as React from 'react';
import classNames from 'classnames';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Clear from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CaseNotes from './CaseNotes';
import TaskNotes from './TaskNotes';
import WarningMessageDialog from './WarningMessage.dialog';
import { Grid, Theme } from '@mui/material';
import { StoreState } from '../../../types';
import { ORANGE_COLOR } from '../../../constants/colorVariables';
import { compose } from 'redux';
import withState from '../../common/utilHOC/WithState';
import { AppDispatch } from '../../../store';
import { SlideTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflowY: 'auto',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
                maxHeight: 'calc(100% - 52px)',
                minHeight: 'calc(100% - 52px)'
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: '0px 0 12px 0',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowY: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    clearIcon: {
        position: 'absolute',
        top: 0,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 420px)': {
            top: 12,
            fontSize: 34,
        }
    },
    tabAppbar: {
        boxShadow: 'none',
        margin: '5px 0',
        background: '#fff',
    },
    tabsRoot: {
        overflowX: 'auto',
        marginTop: 20,
        '@media (min-width: 420px)': {
            marginTop: 0,
        }
    },
    tabsFlexContainer: {
        justifyContent: 'center',
        overflowX: 'auto',
        minWidth: 330,
    },
    tabsScrollableContainer: {
        overflow: 'auto',
        overflowX: 'auto',
        marginBottom: '0 !important',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 150,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 1,
        margin: '0 8px',
        height: 30,
        fontSize: 16,
    },
    displayFlex: {
        display: 'flex'
    },
    tabBadge: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& $label': {
            marginRight: 8,
        },
        '& $noteCount': {
            height: 20,
            display: 'flex',
            padding: '0 4px',
            zIndex: 1,
            flexWrap: 'wrap',
            fontSize: '0.75rem',
            minWidth: 20,
            boxSizing: 'border-box',
            transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontWeight: 500,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            transformOrigin: '100% 0%',
            backgroundColor: ORANGE_COLOR,
            color: theme.palette.common.white
        }
    },
    noteCount: {},
    label: {},
    dialogPaper: {}
});

const mapStateToProps = ({ noteState }: StoreState) => {
    return {
        isLoading: noteState.isLoading,
        taskNotes: noteState.taskNotes,
        caseNotes: noteState.caseNotes,
    };
};

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    caseUuid: string;
    gatherCaseFname: string;
    isDialogOpen: boolean;
    closeDialog: () => void;
    zIndex: number;
    tabIndex?: number;
}

interface State {
    tabIndex: number;
    isWarningMessageDialog: boolean;
    isCaseNoteValid: boolean;
    resetCaseNotesForm: boolean;
}

type StyledProps = WithStyles<'root' | 'dialogPaper' | 'dialogHeader' | 'clearIcon'
    | 'dialogContent' | 'tabAppbar' | 'tabsRoot' | 'tabsFlexContainer' | 'tabsScrollableContainer'
    | 'tabRoot' | 'displayFlex' | 'noteCount' | 'label' | 'tabBadge'>;

type CombinedProps = Props & StyledProps & InjectedProps;
class NotesDialog extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            tabIndex: this.props.tabIndex || 0,
            isWarningMessageDialog: false,
            isCaseNoteValid: true,
            resetCaseNotesForm: false
        };
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(newProps: Props) {
        const { isDialogOpen, tabIndex } = newProps;

        if (tabIndex !== undefined && tabIndex !== this.props.tabIndex) {
            this.setState({ tabIndex });
        }
        if (isDialogOpen && !this.props.isDialogOpen) {
            this.scrollToTop();
            this.setState(
                { resetCaseNotesForm: true, isCaseNoteValid: true },
                () => {
                    this.setState({ resetCaseNotesForm: false });
                }
            );
        }
    }

    scrollToTop = () => {
        const caseNotesList = document.getElementById('case-notes-list');

        if (caseNotesList) {
            caseNotesList.scrollTo({ behavior: 'smooth', top: 0 });
        }
    };

    renderLabel = (noteCount: number, label: string) => {
        const { classes, isLoading } = this.props;

        const _noteCount = noteCount > 99 && '99+' || noteCount;

        return (
            <div className={classes.tabBadge}>
                <span className={classes.label}>{label}</span>
                {!isLoading && _noteCount > 0 ?
                    <div className={classes.noteCount}>{_noteCount}</div> : null}
            </div>
        );
    };

    render() {
        const {
            classes,
            isDialogOpen,
            caseNotes,
            taskNotes,
            caseUuid,
            gatherCaseFname,
            zIndex,
        } = this.props;

        const { tabIndex, resetCaseNotesForm } = this.state;

        const caseNotesCount = caseNotes.length;
        const taskNotesCount = taskNotes.length;

        return (
            <>
                <Dialog
                    fullScreen
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={() => this.handleClose()}
                    className={classes.root}
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                >
                    <DialogTitle className={classes.dialogHeader}>
                        <Grid item xs={12}>
                            <Clear
                                color="secondary"
                                className={classes.clearIcon}
                                onClick={() => this.handleClose()}
                                style={{ zIndex }}
                            />
                        </Grid>

                        <AppBar position="static" className={classes.tabAppbar}>
                            <Tabs
                                value={tabIndex}
                                onChange={this.handleTabChangeEvent}
                                classes={{
                                    root: classes.tabsRoot,
                                    flexContainer: classes.tabsFlexContainer,
                                    scrollableX: classes.tabsScrollableContainer,
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab
                                    label={this.renderLabel(caseNotesCount, 'Case Notes')}
                                    value={0}
                                    classes={{
                                        root: classNames(classes.tabRoot, classes.displayFlex),
                                    }}
                                />
                                <Tab
                                    label={this.renderLabel(taskNotesCount, 'Task and Step Notes')}
                                    value={1}
                                    classes={{
                                        root: classNames(classes.tabRoot, classes.displayFlex),
                                    }}
                                />
                            </Tabs>
                        </AppBar>
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        {tabIndex === 0 && <CaseNotes
                            caseUuid={caseUuid}
                            gatherCaseFname={gatherCaseFname}
                            caseNotes={caseNotes}
                            scrollToTop={this.scrollToTop}
                            setCaseNoteValue={this.setCaseNoteValue}
                            resetForm={resetCaseNotesForm}
                            zIndex={zIndex + 1}
                        />}
                        {tabIndex === 1 && <TaskNotes
                            taskNotes={taskNotes}
                            caseUuid={caseUuid}
                            gatherCaseFname={gatherCaseFname}
                            zIndex={zIndex + 1}
                        />}
                    </DialogContent>
                </Dialog>
                {this.renderWarningMessageDialog()}
            </>
        );
    }

    closeWarningMessageDialog = () => {
        this.setState({
            isWarningMessageDialog: false
        });
    };

    closeWindow = () => {
        this.setState({ isWarningMessageDialog: false });
        this.props.closeDialog();
    };

    renderWarningMessageDialog = () => {
        const { isWarningMessageDialog } = this.state;
        const { zIndex } = this.props;
        return (
            <WarningMessageDialog
                open={isWarningMessageDialog}
                closeDialog={this.closeWarningMessageDialog}
                onCloseWindow={this.closeWindow}
                zIndex={zIndex + 1}
            />
        );
    };

    closeDialog = () => {
        const { isCaseNoteValid } = this.state;

        if (!isCaseNoteValid) {
            this.setState({ isWarningMessageDialog: true });
            return;
        }

        this.props.closeDialog();
    };

    setCaseNoteValue = (isCaseNoteValid: boolean) => {
        this.setState({ isCaseNoteValid });
    };

    handleClose = () => {
        this.scrollToTop();
        this.closeDialog();
    };

    handleTabChangeEvent = (event: React.FormEvent<{}>, tabIndex: unknown) => {
        if (typeof tabIndex === 'number') {
            this.setState({ tabIndex });
        }
    };
}

export default compose(
    withState(mapStateToProps),
    withFullScreen(),
    withStyles(styles)
)(NotesDialog) as React.ComponentType<Props>;
