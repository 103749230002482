import { Button, Grid, Typography, alpha } from "@mui/material";
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import { APP_SECONDARY_COLOR } from "../../../../../constants/colorVariables";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface BulkEditOptionsProps {
    onButtonClick: (value: BulkEditButtonType | null) => void;
}

const BulkEditOptions = (props: BulkEditOptionsProps) => {
    const { onButtonClick } = props;

    const buttonTypes = Object.values(BulkEditButtonType);

    return (
        <Grid
            container
            pt={1.5}
            px={2}
            display='flex'
            justifyContent="center"
            flexDirection='column'
            alignItems='center'
        >
            <Grid item>
                <Typography color='secondary' fontSize={16}>
                    How do you want to edit selected products?
                </Typography>
            </Grid>
            <Grid item width={1} mt={1.5} sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}>
                {buttonTypes.map(type => (
                    <Button
                        key={type}
                        variant="outlined"
                        fullWidth
                        sx={{
                            borderColor: alpha(APP_SECONDARY_COLOR, 0.2),
                            justifyContent: 'space-between', color: "#000",
                            '& svg': {
                                color: `${APP_SECONDARY_COLOR}`
                            }
                        }}
                        onClick={() => onButtonClick(type)}
                    >
                        {type}
                        <ArrowForwardIcon />
                    </Button>
                ))}
            </Grid>
        </Grid>
    );
};

export default BulkEditOptions;