import { AlbumPhoto as AlbumPhotoType } from '../../../../types';
import * as React from 'react';
import { StyledProps } from './styles';
import { SortablePhotoElement } from './SortablePhotoElement';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { Grid as VirtualGrid, GridCellProps } from 'react-virtualized/dist/commonjs/Grid';

export type VirtualListProps = {
    items: AlbumPhotoType[];
    renderAddButton: JSX.Element;
    isLocked: boolean;
    isOpenPhotoPopper: boolean;
    activePhotoId: string | null;
    setPhotoCircleRef: (ref: HTMLDivElement | null, index: number) => void;
    setPhotoRef: (ref: HTMLDivElement | null, index: number) => void;
    setEditButtonRef: (ref: HTMLDivElement | null, index: number) => void;
    setContainerRef: (ref: HTMLDivElement | null, index: number) => void;
    openPopper: (event: React.MouseEvent<HTMLElement>, photoId: string, index: number) => void;
    setPhotoNumberRef: (ref: HTMLDivElement | null, index: number) => void;
    setVirtualGridRef: (ref: VirtualGrid | null) => void;
    resizeHandler: () => void;
};

type ExtraProps = {
    columnCount: number;
};

export const VirtualPhotoList = ({
    items,
    isLocked,
    classes,
    renderAddButton,
    isOpenPhotoPopper,
    activePhotoId,
    setPhotoCircleRef,
    setEditButtonRef,
    setPhotoRef,
    setContainerRef,
    openPopper,
    setPhotoNumberRef,
    setVirtualGridRef,
    resizeHandler
}: VirtualListProps & StyledProps) => {

    const cellRenderer = ({
        columnIndex,
        key,
        rowIndex,
        style,
        columnCount,
    }: GridCellProps & ExtraProps) => {
        const index = (rowIndex * columnCount + columnIndex);
        if (index === items.length) {
            return (<div key={key} style={style}>{renderAddButton}</div>);
        }
        if (index > items.length) {
            return undefined;
        }
        const photo = items[index];
        return (
            <SortablePhotoElement
                collection="hello"
                style={style}
                key={key}
                index={index}
                elementIndex={index}
                photo={photo}
                disabled={isLocked}
                classes={classes}
                isLocked={isLocked}
                isOpenPhotoPopper={isOpenPhotoPopper}
                activePhotoId={activePhotoId}
                setEditButtonRef={setEditButtonRef}
                setPhotoCircleRef={setPhotoCircleRef}
                setPhotoRef={setPhotoRef}
                setContainerRef={setContainerRef}
                openPopper={(e, photoId, idx) => openPopper(e, photoId, idx)}
                setPhotoNumberRef={(ref, idx) => setPhotoNumberRef(ref, idx)}
            />
        );
    };
    const totalElements = items.length + 1;

    return (
        <WindowScroller onResize={() => resizeHandler()}>
            {({ height, width, onChildScroll, scrollTop, isScrolling }) => {
                let columnWidth = 162;
                if (width > 368) {
                    columnWidth = 168;
                }
                if (width > 460) {
                    columnWidth = 192;
                }

                // these constants are the dimensions of SortablePhotoList's container,
                // we want the grid to have container's width rather than window width
                let gridWidth = width;
                if (width >= 404) {
                    gridWidth = 384;
                }
                if (width >= 596) {
                    gridWidth = 576;
                }
                if (width >= 788) {
                    gridWidth = 768;
                }
                if (width >= 980) {
                    gridWidth = 960;
                }
                if (width >= 1172) {
                    gridWidth = 1152;
                }
                if (width >= 1364) {
                    gridWidth = 1344;
                }
                if (width >= 1556) {
                    gridWidth = 1536;
                }
                if (width >= 1748) {
                    gridWidth = 1728;
                }
                if (width >= 1940) {
                    gridWidth = 1920;
                }
                if (width >= 2132) {
                    gridWidth = 2112;
                }
                if (width >= 2324) {
                    gridWidth = 2304;
                }

                const columnCount = Math.floor(gridWidth / columnWidth);
                const rowCount = Math.ceil(totalElements / columnCount);

                return (
                    <VirtualGrid
                        className={classes.noFocus}
                        containerStyle={{ margin: '0 auto' }}
                        columnCount={columnCount}
                        columnWidth={columnWidth}
                        overscanRowCount={2}
                        overscanColumnCount={0}
                        cellRenderer={params => cellRenderer({ ...params, columnCount })}
                        rowCount={rowCount}
                        rowHeight={192}
                        height={height}
                        autoHeight
                        width={gridWidth}
                        scrollingResetTimeInterval={300}
                        isScrolling={isScrolling}
                        isScrollingOptOut
                        scrollTop={scrollTop}
                        onScroll={onChildScroll}
                        ref={ref => setVirtualGridRef(ref)}
                    />
                );
            }}
        </WindowScroller>
    );
};
