/* eslint-disable max-len */
import { Component } from 'react';

export default class ShareIconSVG extends Component<{ color: string; className?: string }> {
    render() {
        const { color, className } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet" style={{ fill: color }} className={className}>
                <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M401 896 c-88 -89 -103 -116 -63 -116 9 0 45 28 79 62 l63 62 0 -282 c0 -275 0 -282 20 -282 20 0 20 7 20 282 l0 282 63 -62 c61 -60 88 -74 103 -51 6 10 -171 199 -186 199 -3 0 -47 -42 -99 -94z" />
                    <path d="M140 340 l0 -340 360 0 360 0 0 340 0 340 -110 0 c-103 0 -110 -1 -110 -20 0 -19 7 -20 90 -20 l90 0 0 -300 0 -300 -320 0 -320 0 0 300 0 300 90 0 c83 0 90 1 90 20 0 19 -7 20 -110 20 l-110 0 0 -340z" />
                </g>
            </svg>
        );
    }
}
