import { Component } from 'react';

import { debounce, isEqual } from 'lodash';
import {
    DeathCertificateConfigFieldSaveRequest,
    DeathCertificateConfigUX,
} from '../../../../../shared/types';
import { StoreState } from '../../../../../types';
import ConfigurablePopper from './Configurable.popper';
import SelectDialog from '../../../../common/Select.dialog';
import {
    deleteDeathCertificateConfigField,
    saveDeathCertificateConfigField,
} from '../../../../../actions/DeathCertificateConfig.action';
import { ConfigurableFieldKey, isFieldHiddenFromUser } from '../../../../../shared/death_certificate/validators/config';
import { AppDispatch } from '../../../../../store';

export function mapStateToProps({ userSession, deathCertificateConfigState }: StoreState) {
    return {
        user: userSession.userData,
        config: deathCertificateConfigState.activeDeathCertificateConfig,
    };
}

interface Props<T> extends ReturnType<typeof mapStateToProps> {
    id: ConfigurableFieldKey;
    options?: T[];
    isEditMode: boolean;
    label: string;
    dispatch: AppDispatch;
}

interface State<T> {
    visibleOptions: T[];
    isEnabled: boolean;
    isVisible: boolean;
    isRequired: boolean;
    isHiddenFromFamily: boolean;
    popperAnchorEle: HTMLElement | null;
    isOptionsDialogOpen: boolean;
}

function getInitialState<U extends string>(props: Props<U>): State<U> {
    const { config, id, isEditMode, options, user } = props;
    const field = config && config.revision.fields.find((f) => f.ui_field_key === id);
    const isEnabled = Boolean(field);
    const isHiddenFromFamily = field ? field.is_hidden_from_family : false;
    const isHiddenFromUser = field && user ? isFieldHiddenFromUser(field, user) : false;
    const isVisible = isEnabled && !isHiddenFromUser || isEditMode;
    const isRequired = Boolean(field && field.is_required);
    const visibleOptions = field && options
        ? options.filter((opt) => (field.options || []).some((o) => o === opt))
        : [];

    return {
        isEnabled,
        isVisible,
        isRequired,
        visibleOptions,
        isHiddenFromFamily,
        popperAnchorEle: null,
        isOptionsDialogOpen: false,
    };
}

abstract class ConfigurableField<T extends string, ChildProps>
    extends Component<Props<T> & ChildProps, State<T>> {

    state: State<T> = getInitialState(this.props);

    debouncedSave = debounce(this.save, 2000);

    componentDidUpdate(prevProps: Props<T> & ChildProps) {
        const { config, user } = this.props;
        const { user: prevUser, config: prevConfig } = prevProps;
        if (!prevConfig && config
            || prevUser && user && prevUser.is_family_view_on !== user.is_family_view_on) {
            this.setState(getInitialState(this.props));
        }
    }

    componentWillUnmount() {
        // save changes prior to unmounting
        this.debouncedSave.flush();
    }

    toggleEnabled = () => {
        const { isEditMode, options } = this.props;
        if (!isEditMode) {
            console.warn('DC Configuration Update attempted w/o being in edit mode');
            return;
        }
        this.setState((prevState) => ({
            isEnabled: !prevState.isEnabled,
            visibleOptions: prevState.visibleOptions.length === 0 && !prevState.isEnabled
                ? (options || []) as T[]
                : prevState.visibleOptions
        }));
        this.debouncedSave();
    };

    toggleRequired = () => {
        const { isEditMode } = this.props;
        if (!isEditMode) {
            console.warn('DC Configuration Update attempted w/o being in edit mode');
            return;
        }
        this.setState((prevState) => ({ isRequired: !prevState.isRequired }));
        this.debouncedSave();
    };

    toggleHiddenFromFamily = () => {
        const { isEditMode } = this.props;
        if (!isEditMode) {
            console.warn('DC Configuration Update attempted w/o being in edit mode');
            return;
        }
        this.setState((prevState) => ({ isHiddenFromFamily: !prevState.isHiddenFromFamily }));
        this.debouncedSave();
    };

    addOption = async (opt: T) => {
        const { options } = this.props;
        this.setState((prevState) => {
            // maintain option ranking regardless of order options were added
            const visibleOptions = [...prevState.visibleOptions, opt];
            return {
                visibleOptions: (options as T[] || []).filter((o) => visibleOptions.some((vo) => vo === o)),
            };
        });
        this.debouncedSave();
    };

    removeOption = async (opt: T) => {
        this.setState((prevState) => ({
            visibleOptions: prevState.visibleOptions.filter((o) => o !== opt),
        }));
        this.debouncedSave();
    };

    changeVisibleOptions = (visibleOptions: T[]) => {
        const { isEditMode } = this.props;
        if (!isEditMode) {
            console.warn('DC Configuration Update attempted w/o being in edit mode');
            return;
        }

        this.setState({ visibleOptions });
        this.debouncedSave();
    };

    openOptionsDialog = () => {
        this.setState({
            isOptionsDialogOpen: true,
        });
    };

    openPopper = (popperAnchorEle: HTMLElement) => {
        this.setState({
            popperAnchorEle,
        });
    };

    closePopper = () => {
        this.setState({
            popperAnchorEle: null,
        });
    };

    renderPopper = () => {
        const { label, options, isEditMode } = this.props;
        const { popperAnchorEle, isEnabled, isRequired, isHiddenFromFamily } = this.state;

        if (!isEditMode) {
            return null;
        }

        return (
            <ConfigurablePopper
                title={label}
                zIndex={1340}
                anchorEle={popperAnchorEle}
                isHiddenFromFamily={isHiddenFromFamily}
                isRemoved={!isEnabled}
                isRequired={isRequired}
                closePopper={this.closePopper}
                onConfigureOptionsClick={options ? this.openOptionsDialog : undefined}
                onHideFromFamilyChange={this.toggleHiddenFromFamily}
                onRemoveChange={this.toggleEnabled}
                onRequiredChange={this.toggleRequired}
            />
        );
    };

    renderConfigureOptionsDialog = () => {
        const { options, isEditMode } = this.props;
        const { visibleOptions, isOptionsDialogOpen } = this.state;

        if (!options || !isEditMode) {
            return null;
        }

        return (
            <SelectDialog
                isOpen={isOptionsDialogOpen}
                available={options}
                selected={visibleOptions}
                zIndex={1340 + 1}
                closeDialog={() => this.setState({ isOptionsDialogOpen: false })}
                onAdd={this.addOption}
                onRemove={this.removeOption}
                searchFn={(opt: T, search) => opt.includes(search)}
                getPrimaryDisplay={(opt: T) => opt}
                isOptionSelected={(opt: T) => visibleOptions.some((o) => o === opt)}
            />
        );
    };

    private save() {
        const { id, config, dispatch } = this.props;
        const { isEnabled, isRequired, isHiddenFromFamily, visibleOptions } = this.state;

        if (!config) {
            return;
        }

        const field = config.revision.fields.find((f) => f.ui_field_key === id) || null;

        if (isEnabled) {
            const saveRequest: DeathCertificateConfigFieldSaveRequest = {
                is_required: isRequired,
                is_hidden_from_family: isHiddenFromFamily,
                options: visibleOptions,
            };
            const initial: DeathCertificateConfigFieldSaveRequest | null = field && {
                is_required: field.is_required,
                is_hidden_from_family: field.is_hidden_from_family,
                options: field.options,
            };

            // only save if changes are made to existing or this field is being added
            if (!initial || !isEqual(saveRequest, initial)) {
                dispatch(saveDeathCertificateConfigField(config as DeathCertificateConfigUX, id, saveRequest));
            }
        } else if (field) {
            // only try to delete from API if it already exists
            dispatch(deleteDeathCertificateConfigField(config as DeathCertificateConfigUX, field));
        }
    }
}

export default ConfigurableField;
