import { Component } from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 24px',
        width: 268,
        margin: ' auto',
        textAlign: 'center',
        '@media (min-width: 400px)': {
            width: 308
        },
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        '@media (min-width: 400px)': {
            width: 360
        },
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
    docLabelDesc: {
        fontSize: 18,
        color: theme.palette.secondary.main
    }
});

interface Props {
    isDialogOpen: boolean;
    existingName?: string;
    closeDialog: () => void;
    renameDocHandler: (newLabel: string) => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

export interface State {
    docLabel: string;
    saveClicked: boolean;
}

type StyledProps = Props & WithStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent' | 'clearIcon'
    | 'bottomContent' | 'headerText' | 'docLabelDesc'>;

class DocumentRenameDialog extends Component<StyledProps & DialogProps, State> {

    constructor(props: StyledProps & DialogProps) {
        super(props);
        this.state = {
            docLabel: props.existingName || '',
            saveClicked: false,
        };
    }

    componentDidUpdate(prevProps: StyledProps & DialogProps) {
        const { existingName } = this.props;

        if (existingName !== prevProps.existingName) {
            this.setState(prevState => ({ docLabel: existingName || prevState.docLabel }));
        }
    }

    saveDocName = () => {
        const { renameDocHandler } = this.props;
        const { docLabel } = this.state;
        renameDocHandler(docLabel);
        this.setState({ docLabel: '' });
        this.onClose();
    };

    onClose = () => {
        const { closeDialog } = this.props;
        this.setState({ docLabel: '' });
        closeDialog();
    };

    render() {
        const {
            classes,
            isDialogOpen,
            zIndex
        } = this.props;
        const { saveClicked, docLabel } = this.state;
        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.onClose()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.header,
                        GStyles.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon, GStyles.colorWhite)}
                        onClick={() => this.onClose()}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classNames(GStyles.colorWhite, classes.headerText)}
                    >
                        Document Name
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                        className={GStyles.marginTop20}
                    >
                        <Typography
                            className={classes.docLabelDesc}
                            align="center"
                        >
                            Enter the new document name to use
                            <span className={GStyles.fontWeight600} />
                        </Typography>

                        <Grid item className={GStyles.width100}>
                            <FormControl
                                className={classNames(
                                    GStyles.width100,
                                    GStyles.textLeft,
                                    GStyles.marginTop20
                                )}
                            >
                                <TextField
                                    fullWidth
                                    autoComplete="new-password"
                                    label="Type Document Name Here"
                                    id="docLabel"
                                    name="docLabel"
                                    type="text"
                                    value={docLabel}
                                    error={saveClicked && !docLabel}
                                    onChange={(e) => this.setState({ docLabel: e.target.value })}
                                />
                            </FormControl>
                        </Grid>

                        <Grid
                            className={classes.bottomContent}
                            item
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={() => this.saveDocName()}
                            >
                                SAVE
                            </Button>

                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withFullScreen('xs')(withStyles(styles)(DocumentRenameDialog));
