import {
    WebsiteUX, WebsiteUpdateRequest, WebsiteCreateRequest, WebsiteFuneralHomes,
} from '../shared/types';
import { AppDispatch } from '../store';
import { deleteFromAPI, getFromAPI, patchAPI, postToAPI } from './index';
import { registerAppError } from './errors';
import { log } from '../logger';

export const WEBSITES_LOADING = 'WEBSITES_LOADING';
export type WEBSITES_LOADING = typeof WEBSITES_LOADING;

interface WebsitesLoading {
    type: WEBSITES_LOADING;
}

function websitesLoading(): WebsitesLoading {
    return {
        type: WEBSITES_LOADING,
    };
}

export const WEBSITES_LOADED = 'WEBSITES_LOADED';
export type WEBSITES_LOADED = typeof WEBSITES_LOADED;

interface WebsitesLoaded {
    type: WEBSITES_LOADED;
    websites: WebsiteUX[];
}

function websitesLoaded(websites: WebsiteUX[]): WebsitesLoaded {
    return {
        type: WEBSITES_LOADED,
        websites
    };
}

export const WEBSITE_FUNERAL_HOMES_LOADED = 'WEBSITE_FUNERAL_HOMES_LOADED';
export type WEBSITE_FUNERAL_HOMES_LOADED = typeof WEBSITE_FUNERAL_HOMES_LOADED;

interface WebsiteFuneralHomesLoaded {
    type: WEBSITE_FUNERAL_HOMES_LOADED;
    websiteFH: WebsiteFuneralHomes[];
}

function websiteFuneralHomesLoaded(websiteFH: WebsiteFuneralHomes[]): WebsiteFuneralHomesLoaded {
    return {
        type: WEBSITE_FUNERAL_HOMES_LOADED,
        websiteFH
    };
}

export const WEBSITE_CREATED = 'WEBSITE_CREATED';
export type WEBSITE_CREATED = typeof WEBSITE_CREATED;

interface WebsiteCreated {
    type: WEBSITE_CREATED;
    website: WebsiteUX;
}

function websiteCreated(website: WebsiteUX): WebsiteCreated {
    return {
        type: WEBSITE_CREATED,
        website
    };
}

export const WEBSITE_UPDATED = 'WEBSITE_UPDATED';
export type WEBSITE_UPDATED = typeof WEBSITE_UPDATED;

interface WebsiteUpdated {
    type: WEBSITE_UPDATED;
    websiteId: number;
    website: Partial<WebsiteUX>;
}

function websiteUpdated(websiteId: number, website: Partial<WebsiteUX>): WebsiteUpdated {
    return {
        type: WEBSITE_UPDATED,
        websiteId,
        website
    };
}

export const WEBSITE_DELETED = 'WEBSITE_DELETED';
export type WEBSITE_DELETED = typeof WEBSITE_DELETED;

interface WebsiteDeleted {
    type: WEBSITE_DELETED;
    websiteId: number;
}

function websiteDeleted(websiteId: number): WebsiteDeleted {
    return {
        type: WEBSITE_DELETED,
        websiteId,
    };
}

export function loadWebsites(search: string, searchBlocked: boolean) {
    return async (dispatch: AppDispatch) => {
        dispatch(websitesLoading());
        const url = `api/website?search=${search ?? ''}&block_subscriptions=${searchBlocked ? 'yes' : 'no'}`;
        const result = await getFromAPI<WebsiteUX[]>(url, dispatch);
        if (!result) {
            dispatch(registerAppError(`Problem loading websites`));
            return null;
        }

        dispatch(websitesLoaded(result));
        return result;
    };
}

export function loadWebsiteFuneralHomes() {
    return async (dispatch: AppDispatch) => {
        const result = await getFromAPI<WebsiteFuneralHomes[]>('api/website/funeralHomes', dispatch);
        if (!result) {
            dispatch(registerAppError(`Problem loading funeral homes`));
            return null;
        }
        dispatch(websiteFuneralHomesLoaded(result));
        return result;
    };
}

export function createWebsite(request: WebsiteCreateRequest) {
    return async (dispatch: AppDispatch) => {
        try {
            WebsiteCreateRequest.fromRequest(request);
        } catch (ex) {
            log.warn('Failed to validate update WebsiteUpdateRequest', { request, ex });
            return;
        }
        const website = await postToAPI<WebsiteUX>(`api/website/`, request, dispatch);
        if (!website) {
            dispatch(registerAppError('Could not create website'));
            return;
        }
        dispatch(websiteCreated(website));
    };
}

export function updateWebsite(websiteId: number, website: Partial<WebsiteUX>) {
    return async (dispatch: AppDispatch) => {
        const update: WebsiteUpdateRequest = {
            token: website.token,
            vendor: website.vendor,
            ga4_measurement_id: website.ga4_measurement_id || undefined,
            launched_time: website.launched_time || undefined,
            url: website.url || undefined,
            funeralHomeIds: (website.funeral_homes || []).map(({ id }) => id),
            block_subscriptions: website.block_subscriptions,
        };
        try {
            WebsiteUpdateRequest.fromRequest(update);
        } catch (ex) {
            log.warn('Failed to validate update WebsiteUpdateRequest', { update, ex });
            return;
        }
        dispatch(websiteUpdated(websiteId, website));
        await patchAPI<void>(`api/website/${websiteId}`, update, dispatch);
    };
}

export function deleteWebsite(websiteId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(websiteDeleted(websiteId));
        await deleteFromAPI<void>(`api/website/${websiteId}`, dispatch);
    };
}

export function verifyApplePayDomainWithStripe(websiteId: number, accountType: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(websitesLoading());
        const url = `api/website/${websiteId}/verifyapplepaydomain/${accountType}`;
        const result = await getFromAPI<{ status: string }>(url, dispatch);
        if (!result) {
            dispatch(registerAppError(`Problem verifying Apple Pay domain`));
            return null;
        }
        return result.status;
    };
}

export type WebsiteAction =
    | WebsitesLoading
    | WebsitesLoaded
    | WebsiteFuneralHomesLoaded
    | WebsiteCreated
    | WebsiteUpdated
    | WebsiteDeleted
    ;
