import * as React from 'react';
import { useGSelector } from '../../types';
import {
    canEditNonSensitivePersonDetails,
    canDeletePerson,
    canResendInvitation,
    canRevokeInvitation,
    canInviteUser,
    canAssignTask,
    canEditPersonPhoneOrEmail,
} from '../../shared/authority/can';
import { UserProfile, UserRole, EntityCaseRole, EntitySummary, getFuneralHomeId } from '../../shared/types';

function useReduxState() {
    const activeFuneralHome = useGSelector(({ funeralHomeState }) => funeralHomeState.activeFuneralHome);
    const activeCase = useGSelector(({ casesState }) => casesState.selectedCase);
    const userData = useGSelector(({ userSession }) => userSession.userData);

    return { activeFuneralHome, activeCase, userData };
}

// can invite user
interface WhenCanInviteUserProps {
    children: JSX.Element;
    role: UserRole;
    caseRole?: EntityCaseRole;
}

export const WhenCanInviteUser: React.FC<WhenCanInviteUserProps> = (props) => {
    const { role, caseRole, children } = props;

    const { activeCase, userData, activeFuneralHome } = useReduxState();

    if (!userData) {
        return null;
    }

    const funeralHomeId = getFuneralHomeId(activeFuneralHome, activeCase, null);
    const caseId = activeCase ? activeCase.id : null;

    const canEdit = canInviteUser(userData, role, funeralHomeId, caseId, caseRole || null);
    return canEdit ? children : null;
};

// can edit user
interface WhenCanEditPersonProps {
    children: JSX.Element;
    target: UserProfile | EntitySummary;
}

export const WhenCanEditPerson: React.FC<WhenCanEditPersonProps> = (props) => {
    const { target, children } = props;
    const { activeCase, userData, activeFuneralHome } = useReduxState();

    if (!userData) {
        return null;
    }
    const caseId = activeCase ? activeCase.id : null;
    const funeralHomeId = getFuneralHomeId(activeFuneralHome, activeCase, null);

    const canEdit = canEditNonSensitivePersonDetails(userData, target, funeralHomeId, caseId);
    return canEdit ? children || null : null;
};

// can edit phone or email
interface WhenCanEditPhoneOrEmailProps {
    children: JSX.Element;
    target: EntitySummary;
}

export const WhenCanEditPhoneOrEmail: React.FC<WhenCanEditPhoneOrEmailProps> = (props) => {
    const { target, children } = props;
    const { activeCase, userData, activeFuneralHome } = useReduxState();

    if (!userData) {
        return null;
    }
    const caseId = activeCase ? activeCase.id : null;
    const funeralHomeId = getFuneralHomeId(activeFuneralHome, activeCase, null);

    const canEdit = canEditPersonPhoneOrEmail(userData, target, funeralHomeId, caseId);
    return canEdit ? children || null : null;
};

// can delete user
interface WhenCanDeletePersonProps {
    children: JSX.Element;
    target: EntitySummary;
}

export const WhenCanDeletePerson: React.FC<WhenCanDeletePersonProps> = (props) => {
    const { target, children } = props;
    const { activeCase, userData, activeFuneralHome } = useReduxState();

    if (!userData) {
        return null;
    }
    const caseId = activeCase ? activeCase.id : null;
    const funeralHomeId = getFuneralHomeId(activeFuneralHome, activeCase, null);

    const canEdit = canDeletePerson(userData, target, funeralHomeId, caseId);
    return canEdit ? children : null;
};

// can resend invitation
interface WhenCanResendInvitationProps {
    children: JSX.Element;
    target: UserProfile | EntitySummary;
}

export const WhenCanResendInvitation: React.FC<WhenCanResendInvitationProps> = (props) => {
    const { target, children } = props;
    const { activeCase, userData } = useReduxState();

    if (!userData) {
        return null;
    }
    const caseId = activeCase ? activeCase.id : null;

    const canEdit = canResendInvitation(userData, target, caseId);
    return canEdit ? children : null;
};

// can revoke invitation
interface WhenCanRevokeInvitationProps {
    children: JSX.Element;
    target: UserProfile | EntitySummary;
}

export const WhenCanRevokeInvitation: React.FC<WhenCanRevokeInvitationProps> = (props) => {
    const { target, children } = props;
    const { activeCase, userData } = useReduxState();

    if (!userData) {
        return null;
    }
    const caseId = activeCase ? activeCase.id : null;

    const canEdit = canRevokeInvitation(userData, target, caseId);
    return canEdit ? children : null;
};

// can assign task
interface WhenCanAssignTaskProps {
    children: JSX.Element;
    target: UserProfile | EntitySummary;
}

export const WhenCanAssignTask: React.FC<WhenCanAssignTaskProps> = (props) => {
    const { target, children } = props;
    const { activeCase, userData, activeFuneralHome } = useReduxState();

    if (!userData) {
        return null;
    }

    const funeralHomeId = getFuneralHomeId(activeFuneralHome, activeCase, null);
    const caseId = activeCase ? activeCase.id : null;

    if (!funeralHomeId || !caseId) {
        return null;
    }

    const canEdit = canAssignTask(userData, target, funeralHomeId, caseId);
    return canEdit ? children : null;
};
