import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import * as appService from '../../../services/app.service';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import BusinessIcon from '@mui/icons-material/Business';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsIcon from '@mui/icons-material/Directions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import TextField from '@mui/material/TextField';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { WithStyles } from '@mui/styles';

import {
    DocCategory,
    DocPDFStatus,
    DocUXDetail,
    isExistingContact,
    isExistingOrganization,
    OrganizationDisplayTypes,
    RolodexDoc,
    RolodexDocInsert,
    RolodexEntry,
    rolodexRecordToUpdateRecord,
    UploadFileRequest,
    UserProfile
} from '../../../shared/types';
import PopperMenu from './PopperMenu';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import {
    deleteEntry,
    downloadRolodexDoc,
    setActiveRolodexEntry,
    setRolodexCreateEditDialogOpen,
    updateRolodexEntry,
    removeRolodexContact
} from '../../../actions/Rolodex.action';
import { debounce, isEqual } from 'lodash';
import { convertDocUploadToUXDetail } from '../../../actions/Doc.action';
import { downloadFromURL, getDataFromLocalComputer } from '../../../services/doc.service';
import { registerAppError } from '../../../actions/errors';
import Tooltip from '@mui/material/Tooltip';
import DocumentRenameDialog from '../../documentLibrary/DocumentRename.dialog';
import DocPreviewDialog, { DocType } from '../../documentLibrary/DocPreview.dialog';
import { log } from '../../../logger';
import { joinNameParts } from '../../../shared/utils';
import DocWidgetWithTabs from '../../common/DocWidget/DocWidgetWithTabs';
import { AppDispatch } from '../../../store';
import { uploadFileToS3 } from '../../../actions/S3File.action';

// TODO: pull the styles out of Rolodex.dialog.styles.ts and make a seperate styles file for this component
type StyleProps = WithStyles<
    'stepTwoContainer' | 'topSection' | 'officeName' | 'oganizationName' | 'optionsButton' |
    'contactAndNotesSection' | 'leftSection' | 'contactButtonSection' | 'contactButton' |
    'buttonLabel' | 'contactText' | 'organizationContactDetail' | 'contactLabel' | 'orgnizationAddress' |
    'marginTop8' | 'organizationContactDetailFooter' | 'rightSection' | 'internalNotesField' | 'uploadButton' |
    'docListContainer' | 'docLabelContainer' | 'docName' | 'docUploadedBy' | 'docUploadedTime' | 'fileMoreIcon' |
    'mainDivider' | 'cardSection' | 'officeStaffContainer' | 'dummyDivAssignmentIcon' | 'assignmentIcon' |
    'staffName' | 'staffProfession' | 'moreVertIcon' | 'contactDetailsSection' | 'contactDetailFooter' |
    'marginTop28' | 'marginBottom10' | 'deleteIcon' | 'menuPopper' | 'menuPaper' | 'arrow' | 'menuItem'
    | 'headerContactButtons' | 'docButton' | 'uploadButtonLabel' | 'docSectionContainer' | 'marginBottom8'
    | 'buttonProgress' | 'buttonDisabled'
>;

type Props = {
    entry: RolodexEntry;
    zIndex: number;
    dispatch: AppDispatch;
    userData: UserProfile;
} & StyleProps;

interface State {
    notes: string;
    selectedContactId: number | null;
    contactCardPopperAnchorEle: HTMLElement | null;
    isDeleteContactConfirmationDialogOpen: boolean;
    isDeleteOrganizationConfirmationDialogOpen: boolean;
    organizationPopperAnchorEle: HTMLElement | null;
    filesToUpload: RolodexDocInsert[];
    isUploading: boolean;
    targetDocHash: string;
    docPopperAnchorEle: HTMLElement | null;
    activeDoc: number | null;
    isDocUploading: boolean;
    dialogueRenameDocOpen: boolean;
    dialogueDocPreviewOpen: boolean;
}

class EntryDetails extends React.Component<Props, State> {
    private _debounceUpdateRolodexEntry = debounce(
        async (updatedEntry: RolodexEntry, funeralHomeId: number) => {
            const { dispatch } = this.props;
            const { filesToUpload } = this.state;
            const { organization, contacts } = updatedEntry;

            const hasExistingContacts = contacts.filter(isExistingContact).length === contacts.length;
            if (isExistingOrganization(organization) && hasExistingContacts) {
                const updateRolodexObj = rolodexRecordToUpdateRecord(
                    organization,
                    contacts,
                    filesToUpload,
                    funeralHomeId
                );
                return await dispatch(updateRolodexEntry(updateRolodexObj));
            }

            return null;
        },
        500
    );

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedContactId: null,
            notes: props.entry.organization.notes || '',
            contactCardPopperAnchorEle: null,
            isDeleteContactConfirmationDialogOpen: false,
            isDeleteOrganizationConfirmationDialogOpen: false,
            organizationPopperAnchorEle: null,
            filesToUpload: [],
            isUploading: false,
            targetDocHash: '',
            docPopperAnchorEle: null,
            activeDoc: null,
            isDocUploading: false,
            dialogueRenameDocOpen: false,
            dialogueDocPreviewOpen: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { organization } = this.props.entry;
        if (!isEqual(prevProps.entry.organization, organization) && organization.notes !== null) {
            this.setState({ notes: organization.notes });
        }
    }

    componentWillUnmount() {
        this._debounceUpdateRolodexEntry.flush();
    }

    render() {
        const {
            classes,
            entry,
        } = this.props;
        const { isDocUploading } = this.state;
        const { organization, contacts } = entry;

        const geoHref = organization.longAddress !== null && organization.longAddress.coords
            ? `https://google.com/maps/search/?api=1&query=${encodeURIComponent(
                `${organization.longAddress.coords[0]},${organization.longAddress.coords[1]}`
            )}`
            : '';

        return (
            <>
                <Grid item xs={12} className={classes.stepTwoContainer}>
                    <Grid item xs={12} className={classes.topSection}>
                        {(organization.name !== null && Boolean(organization.name.length)) &&
                            <>
                                <BusinessIcon color="primary" />
                                <Typography color="primary" className={classes.officeName}>
                                    {organization.name}
                                </Typography>
                            </>}
                        {organization.type &&
                            <Typography color="secondary" className={classes.oganizationName}>
                                {`${organization.other_text !== null
                                    ? organization.other_text
                                    : OrganizationDisplayTypes[organization.type]}`}
                            </Typography>
                        }
                        <Button
                            color="primary"
                            className={classes.optionsButton}
                            onClick={this.openOrganizationMenuPopper}
                        >
                            Options&nbsp;<MoreVertIcon />
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.headerContactButtons}>
                    <Tooltip
                        title={organization.phone === null ? `Add organization phone to enable this button` : ''}
                        placement="top"
                    >
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classNames(
                                    classes.contactButton,
                                    organization.phone === null && classes.buttonDisabled,
                                    classes.buttonLabel
                                )}
                                href={`tel:${organization.phone || ''}`}
                                target="_blank"
                            >
                                <span><CallIcon color="primary" /></span>
                                <span className={classes.contactText}>Call</span>
                            </Button>
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={organization.phone === null ? `Add organization phone to enable this button` : ''}
                        placement="top"
                    >
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classNames(
                                    classes.contactButton,
                                    organization.phone === null && classes.buttonDisabled,
                                    classes.buttonLabel
                                )}
                                href={`sms:${organization.phone || ''}`}
                                target="_blank"
                            >
                                <span><SmsIcon color="primary" /></span>
                                <span className={classes.contactText}>Text</span>
                            </Button>
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={organization.email === null ? `Add organization email to enable this button` : ''}
                        placement="top"
                    >
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classNames(
                                    classes.contactButton,
                                    organization.email === null && classes.buttonDisabled,
                                    classes.buttonLabel
                                )}
                                href={`mailto:${organization.email || ''}`}
                                target="_blank"
                            >
                                <span><EmailIcon color="primary" /></span>
                                <span className={classes.contactText}>Email</span>
                            </Button>
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={
                            organization.longAddress === null ? `Add organization address to enable this button` : ''
                        }
                        placement="top"
                    >
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classNames(
                                    classes.contactButton,
                                    organization.longAddress === null && classes.buttonDisabled,
                                    classes.buttonLabel,
                                )}
                                href={geoHref}
                                target="_blank"
                            >
                                <span><DirectionsIcon color="primary" /></span>
                                <span className={classes.contactText}>Directions</span>
                            </Button>
                        </div>
                    </Tooltip>
                </Grid>

                <Grid item xs={12} className={classes.contactAndNotesSection}>
                    <Grid item xs={12} className={classes.leftSection}>
                        <Grid item xs={12} className={classes.organizationContactDetail}>
                            <Typography color="secondary" className={classes.contactLabel}>
                                <LocationOnIcon />
                                Organization's Location
                            </Typography>
                            <Typography
                                color="primary"
                                className={classNames(
                                    classes.orgnizationAddress,
                                    classes.marginBottom8
                                )}
                            >
                                {organization.longAddress !== null
                                    ? appService.getRolodexAddress(organization)
                                    : 'None'}
                            </Typography>
                            <Typography
                                color="secondary"
                                className={classes.contactLabel}
                            >
                                <EmailIcon />
                                Organization's Email
                            </Typography>
                            <Typography
                                color="primary"
                                className={classNames(
                                    classes.orgnizationAddress,
                                    classes.marginBottom8
                                )}
                            >
                                {organization.email !== null ? organization.email : 'None'}
                            </Typography>
                            <Grid
                                item
                                xs={12}
                                className={classNames(classes.organizationContactDetailFooter, classes.marginBottom8)}
                            >
                                <div>
                                    <Typography color="secondary" className={classes.contactLabel}>
                                        <CallIcon />
                                        Organization's Phone
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        className={classNames(
                                            classes.orgnizationAddress,
                                            classes.marginBottom8
                                        )}
                                    >
                                        {organization.phone !== null
                                            ? appService.getFormattedPhoneNumber(organization.phone)
                                            : 'None'}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography color="secondary" className={classes.contactLabel}>
                                        <PrintIcon />
                                        Organization's Fax
                                    </Typography>
                                    <Typography color="primary" className={classes.orgnizationAddress}>
                                        {organization.fax_number !== null ?
                                            appService.getFormattedPhoneNumber(organization.fax_number) : 'None'}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.rightSection}>
                        <TextField
                            id="internal-notes-outlined"
                            label="Type Internal notes here..."
                            variant="outlined"
                            className={classes.internalNotesField}
                            multiline
                            rows={6}
                            value={this.state.notes}
                            onChange={(e) => this.setState({ notes: e.target.value })}
                            onBlur={(e) => this._handleNotesChange(e.target.value)}
                            InputProps={{ classes: { notchedOutline: 'notranslate' } }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.docSectionContainer}>
                    <DocWidgetWithTabs
                        isDocUploading={isDocUploading}
                        docs={organization.docs.map(doc => ({
                            id: doc.id,
                            name: doc.name,
                            uploadedBy: `${doc.uploaded_by_fname || ''} ${doc.uploaded_by_lname || ''}`,
                            uploadedTime: moment(doc.uploaded_time).format()
                        }))}
                        uploadButtonProps={{
                            variant: 'contained',
                            className: classes.uploadButton,
                            classes: { root: classes.uploadButtonLabel }
                        }}
                        handleUpload={this._onFileUpload}
                        menuHandler={this._openDocMenuPopper}
                    />
                </Grid>
                {/* End Case Notes Section */}

                <Grid item xs={12} className={classes.officeStaffContainer}>

                    {contacts.map((contact, index) => {
                        const nameAndTitle = `${contact.title !== null ? contact.title + ' ' : ''
                            }${contact.fname} ${contact.lname}`;
                        const sectionClass = index % 2 === 0 ? classes.leftSection : classes.rightSection;
                        const contactGeoHref = contact.longAddress !== null && contact.longAddress.coords
                            ? `https://google.com/maps/search/?api=1&query=${encodeURIComponent(
                                `${contact.longAddress.coords[0]},${contact.longAddress.coords[1]}`
                            )}`
                            : '';

                        return (
                            <Grid key={contact.id} item xs={12} className={sectionClass}>
                                <Grid item xs={12} className={classes.cardSection}>
                                    <div className={classes.dummyDivAssignmentIcon} />
                                    <AssignmentIndIcon color="primary" className={classes.assignmentIcon} />
                                    <Typography align="center" color="primary" className={classes.staffName}>
                                        {nameAndTitle}
                                    </Typography>
                                    {contact.org_role !== null &&
                                        <Typography
                                            align="center"
                                            color="secondary"
                                            className={classes.staffProfession}
                                        >
                                            {contact.org_role}
                                        </Typography>}
                                    <IconButton
                                        color="primary"
                                        className={classes.moreVertIcon}
                                        onClick={(e) => this.openContactCardMenuPopper(e, contact.id)}
                                    >
                                        <MoreVertIcon color="primary" />
                                    </IconButton>

                                    <Grid item xs={12} className={classes.contactButtonSection}>
                                        {contact.phone !== null &&
                                            <>
                                                <Tooltip
                                                    title={`Click to call ${contact.fname}`}
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        className={classes.contactButton}
                                                        href={`tel:${contact.phone}`}
                                                        target="_blank"
                                                    >
                                                        <CallIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={`Click to text ${contact.fname}`}
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        className={classes.contactButton}
                                                        href={`sms:${contact.phone}`}
                                                        target="_blank"
                                                    >
                                                        <SmsIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>}
                                        {contact.email !== null &&
                                            <Tooltip
                                                title={`Click to email ${contact.fname}`}
                                                placement="top"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    className={classes.contactButton}
                                                    href={`mailto:${contact.email}`}
                                                    target="_blank"
                                                >
                                                    <EmailIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>}
                                        {(contact.longAddress && contact.longAddress.coords) &&
                                            <Tooltip
                                                title={`Click for directions`}
                                                placement="top"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    className={classes.contactButton}
                                                    href={contactGeoHref}
                                                    target="_blank"
                                                >
                                                    <DirectionsIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>}
                                    </Grid>

                                    <Grid item xs={12} className={classes.contactDetailsSection}>
                                        {contact.email !== null &&
                                            <>
                                                <Typography color="secondary" className={classes.contactLabel}>
                                                    <EmailIcon />
                                                    Contact's Email
                                                </Typography>
                                                <Typography color="primary" className={classes.contactText}>
                                                    {contact.email}
                                                </Typography>
                                            </>}

                                        {contact.longAddress !== null &&
                                            <>
                                                <Typography
                                                    color="secondary"
                                                    className={classNames(
                                                        classes.contactLabel, classes.marginTop8
                                                    )}
                                                >
                                                    <LocationOnIcon />
                                                    Contact's Location
                                                </Typography>
                                                <Typography color="primary" className={classes.contactText}>
                                                    {appService.getRolodexAddress(contact)}
                                                </Typography>
                                            </>}

                                        <Grid
                                            item
                                            xs={12}
                                            className={classNames(
                                                classes.contactDetailFooter, classes.marginTop8
                                            )}
                                        >
                                            {contact.phone !== null &&
                                                <div>
                                                    <Typography color="secondary" className={classes.contactLabel}>
                                                        <CallIcon />
                                                        Contact's Phone
                                                    </Typography>
                                                    <Typography color="primary" className={classes.contactText}>
                                                        {appService.getFormattedPhoneNumber(contact.phone)}
                                                    </Typography>
                                                </div>
                                            }
                                            {contact.fax_number !== null &&
                                                <div>
                                                    <Typography color="secondary" className={classes.contactLabel}>
                                                        <PrintIcon />
                                                        Contact's Fax
                                                    </Typography>
                                                    <Typography color="primary" className={classes.contactText}>
                                                        {appService.getFormattedPhoneNumber(contact.fax_number)}
                                                    </Typography>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {/* contactDetails section ends */}
                    {/* Card section Ends */}
                    {/* Left Section Ends */}
                </Grid>
                {this.renderDeleteOrgConfirmationDialog()}
                {this.renderDeleteContactConfirmationDialog()}
                {this.renderContactCardPopper()}
                {this.renderOrganizationPopper()}
                {this._renderDocMenuPopper()}
                {this._renderDocumentRenameDialog()}
                {this._renderDocPreviewDialog()}
            </>
        );
    }

    renderContactCardPopper = () => {
        const { classes, zIndex } = this.props;
        const { contactCardPopperAnchorEle } = this.state;

        const menuItems = [{
            text: 'Edit Contact',
            clickHandler: this._editOrganization,
            icon: <EditIcon />
        }, {
            text: 'Delete Contact',
            clickHandler: this.openDeleteContactConfirmationDialog,
            icon: <DeleteIcon />,
            classes: [classes.deleteIcon]
        }];

        return (
            <PopperMenu
                zIndex={zIndex}
                closePopperHandler={this.closeContactCardMenuPopper}
                anchorEl={contactCardPopperAnchorEle}
                menuItems={menuItems}
                arrowEnabled
            />
        );
    };

    closeContactCardMenuPopper = () => {
        this.setState({ contactCardPopperAnchorEle: null });
    };

    renderDeleteOrgConfirmationDialog = () => {
        const { zIndex, entry } = this.props;
        const { isDeleteOrganizationConfirmationDialogOpen } = this.state;

        const deleteDialogHeader = `Are you sure you want to delete ${entry.organization.name || ''}?`;
        const deleteDialogSubHeader = 'This action cannot be undone.';
        const deleteDialogConfirmationButton = `Delete ${entry.organization.name || ''}`;
        const cancelButtonText = 'Nevermind';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                subHeader={deleteDialogSubHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                cancelButtonText={cancelButtonText}
                onClose={() => this.setState({ isDeleteOrganizationConfirmationDialogOpen: false })}
                open={isDeleteOrganizationConfirmationDialogOpen}
                onConfirm={this._deleteOrganization}
                zIndex={zIndex + 2}
            />
        );
    };

    renderDeleteContactConfirmationDialog = () => {
        const { zIndex, entry } = this.props;
        const { isDeleteContactConfirmationDialogOpen, selectedContactId } = this.state;

        const contactToDelete = selectedContactId && entry.contacts.find((c) => c.id === selectedContactId);
        const contactName = contactToDelete ? joinNameParts(contactToDelete) : '';

        const deleteDialogHeader = `Are you sure you want to delete ${contactName}?`;
        const deleteDialogSubHeader = `This action cannot be undone.`;
        const deleteDialogConfirmationButton = `Delete ${contactName}`;
        const cancelButtonText = `Nevermind`;

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                subHeader={deleteDialogSubHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                cancelButtonText={cancelButtonText}
                onClose={() => this.setState({ isDeleteContactConfirmationDialogOpen: false })}
                open={isDeleteContactConfirmationDialogOpen}
                onConfirm={this._deleteContact}
                zIndex={zIndex + 2}
            />
        );
    };

    openContactCardMenuPopper = (e: React.MouseEvent<HTMLElement>, selectedContactId: number) => {
        this.setState({
            selectedContactId,
            contactCardPopperAnchorEle: e.currentTarget
        });
    };

    renderOrganizationPopper = () => {
        const { classes, zIndex } = this.props;
        const { organizationPopperAnchorEle } = this.state;

        const menuItems = [{
            text: 'Edit Organization',
            clickHandler: this._editOrganization,
            icon: <EditIcon />
        }, {
            text: 'Delete Organization',
            clickHandler: this.openDeleteOrganizationConfirmationDialog,
            icon: <DeleteIcon />,
            classes: [classes.deleteIcon]
        }];

        return (
            <PopperMenu
                zIndex={zIndex}
                closePopperHandler={this.closeOrganizationMenuPopper}
                anchorEl={organizationPopperAnchorEle}
                menuItems={menuItems}
                arrowEnabled
            />
        );
    };

    openDeleteOrganizationConfirmationDialog = () => {
        this.setState({
            organizationPopperAnchorEle: null,
            isDeleteOrganizationConfirmationDialogOpen: true,
        });
    };

    openDeleteContactConfirmationDialog = () => {
        this.setState({
            contactCardPopperAnchorEle: null,
            isDeleteContactConfirmationDialogOpen: true,
        });
    };

    closeOrganizationMenuPopper = () => {
        this.setState({
            organizationPopperAnchorEle: null
        });
    };

    openOrganizationMenuPopper = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({
            organizationPopperAnchorEle: e.currentTarget
        });
    };

    private _editOrganization = async () => {
        const { dispatch, entry } = this.props;

        await dispatch(setActiveRolodexEntry(entry));

        this.setState({
            organizationPopperAnchorEle: null,
            contactCardPopperAnchorEle: null
        });

        await dispatch(setRolodexCreateEditDialogOpen(true));
    };

    private _deleteOrganization = () => {
        const { entry, dispatch } = this.props;

        this.setState({ isDeleteOrganizationConfirmationDialogOpen: false });
        dispatch(deleteEntry(entry));
    };

    private _deleteContact = async () => {
        const { entry, dispatch } = this.props;
        const { selectedContactId, filesToUpload } = this.state;

        if (!selectedContactId) {
            log.warn('No selected contact to delete', { entry });
            return;
        }

        const contactToDelete = entry.contacts.find((c) => c.id === selectedContactId);

        if (contactToDelete) {

            if (isExistingOrganization(entry.organization)) {
                const updateRolodexObj = rolodexRecordToUpdateRecord(
                    entry.organization,
                    entry.contacts.filter((c) => c.id !== selectedContactId),
                    filesToUpload,
                    entry.funeralHomeId
                );
                const updatedEntry = await dispatch(updateRolodexEntry(updateRolodexObj));
                dispatch(removeRolodexContact(selectedContactId));
                dispatch(setActiveRolodexEntry(updatedEntry));
            }
        }

        this.setState({
            isDeleteContactConfirmationDialogOpen: false
        });
    };

    private _handleNotesChange = async (text: string) => {
        const { entry, dispatch } = this.props;
        const { notes } = this.state;

        if (text !== notes) {
            this.setState({ notes: text });
        }

        const updatedOrg = { ...entry.organization, notes: text };
        const updatedEntry: RolodexEntry = { ...entry, organization: updatedOrg };

        dispatch(setActiveRolodexEntry(updatedEntry));
        await this._debounceUpdateRolodexEntry(updatedEntry, entry.funeralHomeId);
    };

    // TODO(Jonathan): Make this generic code so we an reuse rather than copy paste.
    private _onFileUpload = async (files: FileList | null) => {
        const { dispatch, entry } = this.props;
        const { organization, contacts, funeralHomeId } = entry;

        if (files && files.length !== 0) {
            this.setState({ isUploading: true });
            const results: UploadFileRequest[] = [];
            for (let i = 0; i < files.length; i++) {
                const result = await getDataFromLocalComputer(files[i]);
                if (!result) {
                    continue;
                }
                results.push(result);
                if (result && result.hash) {
                    this.setState({ targetDocHash: result.hash });
                }
            }

            this.setState({ isUploading: false });
            if (results.length !== files.length) {
                dispatch(registerAppError('Document failed to upload. Please verify size is less than 50MB'));
            }
            this.setState({ isDocUploading: true });
            const processedDocs = await this._handleUploadDocs(results);
            if (processedDocs !== null) {
                if (isExistingOrganization(organization)) {
                    const updateRolodexObj = rolodexRecordToUpdateRecord(
                        organization,
                        contacts,
                        processedDocs,
                        funeralHomeId
                    );
                    const updatedEntry = await dispatch(updateRolodexEntry(updateRolodexObj));
                    dispatch(setActiveRolodexEntry(updatedEntry));
                }
            }
            this.setState({ isDocUploading: false });
        }
    };

    private _handleUploadDocs = async (newDocs: UploadFileRequest[]): Promise<RolodexDocInsert[] | null> => {
        const { dispatch, userData, entry } = this.props;
        const { organization } = entry;

        if (newDocs && newDocs.length > 0) {
            const placeHolderRecords: DocUXDetail[] = newDocs.map(convertDocUploadToUXDetail);
            const processedDocs: RolodexDocInsert[] = [];
            for (let i = 0; i < newDocs.length; i++) {
                const targetDocData = placeHolderRecords.find(t => t.label === newDocs[i].name);
                if (targetDocData) {
                    const funeralHomeId = organization.funeral_home_id;
                    const { hash, suffix } = newDocs[i];

                    if (suffix) {

                        const s3DocUploadRequest = await uploadFileToS3(
                            newDocs[i],
                            `funeralhome/${funeralHomeId}/rolodex/uploadurl/${hash}/${suffix}`,
                            dispatch
                        );

                        if (s3DocUploadRequest !== null) {
                            processedDocs.push({
                                uploaded_by: userData.id,
                                parent_doc_id: null,
                                name: s3DocUploadRequest.name,
                                label: null,
                                hash: s3DocUploadRequest.hash,
                                size: s3DocUploadRequest.size,
                                is_private: true,
                                path: s3DocUploadRequest.path,
                                suffix: s3DocUploadRequest.suffix || '',
                                mimetype: s3DocUploadRequest.type,
                                icon: null,
                                doc_category: DocCategory.general,
                                pdf_status: DocPDFStatus.not_needed,
                                uploaded_time: (new Date()).toISOString(),
                            });
                        }
                    }
                }
            }
            return processedDocs;
        }

        return null;
    };

    private _handleFileDownload = async () => {
        const { activeDoc } = this.state;
        const { entry, dispatch } = this.props;
        const { organization, funeralHomeId } = entry;

        this._closeDocMenuPopper();

        if (activeDoc !== null) {
            const downloadData = await dispatch(downloadRolodexDoc(activeDoc, organization.id, funeralHomeId));

            if (downloadData === null) {
                return;
            }

            downloadFromURL(downloadData.presignedurl, downloadData.downloadfilename);
        }
    };

    private _handleFileDelete = async () => {
        const { activeDoc } = this.state;
        const { entry, dispatch } = this.props;
        const { organization, contacts, funeralHomeId } = entry;

        const docToDelete = organization.docs.find(({ id }) => id === activeDoc);
        if (docToDelete) {
            const orgDocsCopy = [...organization.docs];
            orgDocsCopy.splice(orgDocsCopy.indexOf(docToDelete), 1);
            const orgCopy = { ...organization, docs: orgDocsCopy };

            if (isExistingOrganization(orgCopy)) {
                const updateObj = rolodexRecordToUpdateRecord(orgCopy, contacts, [], funeralHomeId);
                const updatedEntry = await dispatch(updateRolodexEntry(updateObj));
                dispatch(setActiveRolodexEntry(updatedEntry));
            }
        }

        this._closeDocMenuPopper();
    };

    private _openRenameDialog = () => {
        this._closeDocMenuPopper(false);
        this.setState({ dialogueRenameDocOpen: true });
    };

    private _closeRenameDialog = () => {
        this.setState({ dialogueRenameDocOpen: false });
    };

    private _handleFileRename = async (doc: RolodexDoc, newName: string) => {
        const { entry, dispatch } = this.props;
        const { organization, contacts, funeralHomeId } = entry;

        const docToRenameIndex = organization.docs.indexOf(doc);
        if (docToRenameIndex !== -1) {
            const docListCopy = [...organization.docs];
            docListCopy[docToRenameIndex].name = newName;
            docListCopy[docToRenameIndex].label = newName;

            const updatedOrg = { ...organization, docs: docListCopy };

            if (isExistingOrganization(updatedOrg)) {
                const updateObj = rolodexRecordToUpdateRecord(updatedOrg, contacts, [], funeralHomeId);
                const updatedEntry = await dispatch(updateRolodexEntry(updateObj));
                dispatch(setActiveRolodexEntry(updatedEntry));
            }
        }
    };

    private _renderDocumentRenameDialog = () => {
        const { zIndex, entry } = this.props;
        const { organization } = entry;
        const { activeDoc, dialogueRenameDocOpen } = this.state;

        if (activeDoc !== null && organization.docs) {
            const foundDoc = organization.docs.find(d => d.id === activeDoc);

            if (foundDoc) {
                return (
                    <DocumentRenameDialog
                        key={foundDoc.name}
                        isDialogOpen={dialogueRenameDocOpen}
                        renameDocHandler={(newName) => this._handleFileRename(foundDoc, newName)}
                        existingName={foundDoc.name}
                        closeDialog={this._closeRenameDialog}
                        zIndex={zIndex + 5}
                    />
                );
            }
        }

        return <></>;
    };

    private _openDocMenuPopper = (docId: number | string, e: React.MouseEvent<HTMLElement>) => {
        if (typeof docId === 'string') {
            this.setState({ docPopperAnchorEle: e.currentTarget, activeDoc: Number(docId) });
        } else {
            this.setState({ docPopperAnchorEle: e.currentTarget, activeDoc: docId });
        }
    };

    private _closeDocMenuPopper = (clearDoc: boolean = true) => {
        const { dialogueDocPreviewOpen } = this.state;

        if (dialogueDocPreviewOpen) {
            return;
        }
        const stateChangeObj = {
            docPopperAnchorEle: null
        };

        if (clearDoc) {
            const activeDocKey = 'activeDoc';
            stateChangeObj[activeDocKey] = null;
        }

        this.setState(stateChangeObj);
    };

    private _renderDocMenuPopper = () => {
        const { zIndex } = this.props;
        const { docPopperAnchorEle } = this.state;

        const menuItems = [{
            text: 'View File',
            clickHandler: this._openDocPreviewDialog
        }, {
            text: 'Download File',
            clickHandler: this._handleFileDownload
        }, {
            text: 'Rename File',
            clickHandler: this._openRenameDialog
        }, {
            text: 'Delete File',
            clickHandler: this._handleFileDelete
        }];

        return (
            <PopperMenu
                zIndex={zIndex}
                closePopperHandler={() => this._closeDocMenuPopper()}
                anchorEl={docPopperAnchorEle}
                arrowEnabled={false}
                menuItems={menuItems}
            />
        );
    };

    private _openDocPreviewDialog = () => {
        this.setState({ dialogueDocPreviewOpen: true, docPopperAnchorEle: null });
    };

    private _closeDocPreviewDialog = () => {
        this.setState({ dialogueDocPreviewOpen: false });
    };

    private _renderDocPreviewDialog = () => {
        const { dialogueDocPreviewOpen, activeDoc } = this.state;
        const { zIndex, entry } = this.props;
        const { organization, funeralHomeId } = entry;

        const doc = organization.docs.find(d => d.id === activeDoc);

        if (!activeDoc) {
            return null;
        }

        return (
            <DocPreviewDialog
                isDialogOpen={dialogueDocPreviewOpen}
                closeDialog={this._closeDocPreviewDialog}
                zIndex={zIndex + 3}
                docId={activeDoc}
                docType={DocType.Rolodex}
                activeFuneralHomeId={funeralHomeId}
                organization={organization}
                updateRolodexDocName={async (newName) =>
                    doc && (await this._handleFileRename(doc, newName))
                }
            />
        );
    };
}

export default EntryDetails;
