import ForwardIcon from '@mui/icons-material/Forward';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import BlockUnwantedMail from './BlockUnwantedMail';
import ForwardMail from './ForwardMail';

const ForwardMailDialog = (props: TaskDialogComponentProps) => {
    const { activeCase } = props;

    const description = (
        <>
            If you need to forward {activeCase.fname}'s mail to a different address,
            simply click the option below and we will walk you through each step.
            We've also made it simple to automatically prevent all marketers from sending unwanted mail.
            If you have questions about handling {activeCase.fname}'s mail,
            simply <FhOrAssigneePhoneNumberLink activeCase={activeCase} /> to call me directly.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: `Forward ${activeCase.fname}'s Mail`,
        icon: <ForwardIcon />,
        content: <ForwardMail activeCase={activeCase} />
    }, {
        id: 2,
        icon: <UnsubscribeIcon />,
        label: 'Block Unwanted Mail',
        content: <BlockUnwantedMail activeCase={activeCase} />
    }];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: 'Block Unwanted Mail',
                description,
                listItems
            }}
        />
    );
};

export default ForwardMailDialog;