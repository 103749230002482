import * as React from 'react';
import classNames from 'classnames';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import GDialog from './GDialog';
import { GLOBAL_STYLED_PROPS } from '../../styles';
import { AvatarUser } from '../../shared/types';
import { Theme } from '@mui/material';
import withGStyles from '../../styles/WithGStyles';

const styles = <T extends AvatarUser>(): StyleRulesCallback<Theme, Props<T>> => (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                width: 460,
                margin: '32px auto',
                minWidth: 460,
                maxWidth: 460,
                maxHeight: '100%',
                overflowX: 'hidden'
            }
        },
    },
    dialogPaper: {},
    searchField: {
        margin: '0  0 16px 0',
        width: 'calc(100% - 48px)'
    },
    sorryText: {
        marginTop: 20
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    noResultsDivider: {
        margin: '8px 32px'
    },
    noWorriesText: {
        padding: '0 24px'
    },
    searchForm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
    },
    addTaskButton: {
        margin: '24px auto'
    },
    footerList: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 80,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        marginBottom: 16,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    dialogContent: {
        padding: '0 12px',
        height: '80vh',
    },
    titleClass: {
        padding: 10,
        '& p': {
            padding: 0,
            width: '100% !important',
            textAlign: 'left'
        }
    },
});

type Classes = 'root' | 'dialogPaper' | 'dialogContent' | 'addTaskButton' | 'footerList'
    | 'searchField' | 'sorryText' | 'noResultsFoundSection' | 'titleClass'
    | 'noResultsDivider' | 'noWorriesText' | 'searchForm';
type StyledProps<T extends AvatarUser> = Props<T> & GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props<T extends AvatarUser> {
    title: string | JSX.Element;
    isDialogOpen: boolean;
    zIndex: number;
    users: T[];
    hideInviteButtons?: boolean;
    disableTypography?: boolean;
    hideSearch?: boolean;
    dialogPaperClass?: string;
    dialogContentClass?: string;
    dialogContentId?: string;
    closeDialog: () => void;
    onInviteButtonClick: () => void;
    children: (user: T) => JSX.Element;
    renderHeader?: () => JSX.Element;
    renderFooter?: () => JSX.Element;
}

interface State {
    searchText: string;
    popperAnchorEle: HTMLElement | null;
    activeEntityId: number | null;
}

class BaseFamilyAndFriendDetailsDialog<T extends AvatarUser> extends React.Component<StyledProps<T>, State> {
    state: State = {
        searchText: '',
        popperAnchorEle: null,
        activeEntityId: null,
    };

    renderNoResultsFoundSection = () => {
        const { classes, onInviteButtonClick, hideInviteButtons } = this.props;

        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography
                    color="secondary"
                    component="p"
                    className={classes.sorryText}
                >
                    Sorry no results found.
                </Typography>
                {!hideInviteButtons &&
                    <>
                        <Divider className={classes.noResultsDivider} />
                        <Typography
                            color="secondary"
                            component="p"
                            className={classes.noWorriesText}
                        >
                            <span
                                className={classes.textClickable}
                                onClick={() => onInviteButtonClick()}
                            >
                                Click here
                            </span>
                            &nbsp;to invite new Guest.
                        </Typography>
                    </>
                }
            </Grid >
        );
    };

    renderFamilyAndFriendList = () => {
        const { classes, users, onInviteButtonClick, children, renderFooter } = this.props;
        const { searchText } = this.state;

        const hasNoUsers = users.length === 0;
        const filteredUsers = !searchText
            ? users
            : users.filter((h) => h.fname.toLowerCase().includes(searchText.toLowerCase()));

        return (
            <List component="nav" disablePadding>
                {!hasNoUsers && <Divider />}

                {filteredUsers.map((user) => children(user))}

                {renderFooter
                    ? renderFooter()
                    : <div
                        className={classes.footerList}
                        onClick={() => onInviteButtonClick()}
                    >
                        <AddIcon />&nbsp;Invite New Guest
                    </div>
                }
            </List>
        );
    };

    renderContent = () => {
        const { classes, users, onInviteButtonClick, renderHeader, hideSearch } = this.props;
        const { searchText } = this.state;
        const usersCount = users.length;
        if (usersCount === 0 && !hideSearch) {
            return (this.renderNoResultsFoundSection());
        }

        return (
            <Grid container>
                <Grid item xs={12} className={classes.textCenter}>
                    {renderHeader
                        ? renderHeader()
                        : <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onInviteButtonClick()}
                            className={classes.addTaskButton}
                        >
                            <PersonAddIcon />&nbsp;invite new guest
                        </Button>
                    }
                    {!hideSearch &&
                        <FormControl className={classes.searchForm}>
                            <TextField
                                autoComplete="off"
                                className={classes.searchField}
                                fullWidth
                                value={searchText}
                                id="input-with-icon-textfield"
                                placeholder={`Type to search ${usersCount} friends and family`}
                                name="searchText"
                                onChange={this.handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    }
                    {this.renderFamilyAndFriendList()}
                </Grid>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            isDialogOpen,
            zIndex,
            title,
            disableTypography,
            dialogPaperClass,
            dialogContentClass,
            dialogContentId
        } = this.props;

        return (
            <GDialog
                title={title}
                isOpen={isDialogOpen}
                onClose={this.handleClose}
                rootClass={classes.root}
                zIndex={zIndex}
                contentClass={classNames(classes.dialogContent, dialogContentClass)}
                paperClass={classNames(classes.dialogPaper, dialogPaperClass)}
                titleClass={classes.titleClass}
                disableTypography={disableTypography}
                contentId={dialogContentId}
            >
                {this.renderContent()}
            </GDialog>
        );
    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        this.setState({ searchText: target.value });
    };

    handleClose = () => {
        this.setState(
            { searchText: '' },
            () => this.props.closeDialog()
        );
    };
}

export default <T extends AvatarUser>() => withGStyles(styles<T>())(BaseFamilyAndFriendDetailsDialog);
