import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { StoreState } from '../../../types';
import { convertHexToRGBA } from '../../../services';
import UserAvatar from '../../common/UserAvatar';

import ConfirmationDialog from '../../common/ConfirmationDialog';
import EditNoteDialog from './EditNote.dialog';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import { CaseNote } from '../../../shared/types';
import {
    addCaseNote,
    updateCaseNote,
    deleteCaseNote
} from '../../../actions/Note.action';
import CircularProgress from '@mui/material/CircularProgress';
import { AppDispatch } from '../../../store';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withState from '../../common/utilHOC/WithState';
import TabsInFamilyView from '../../common/TabsInFamilyView';
import Note from './Note';
import NoteAnimation from './NoteAnimation';

const styles: StyleRulesCallback<Theme, Props, Classes> = theme => ({
    root: {},
    mainCointainer: {
        flex: 0
    },
    formControl: {
        textAlign: 'left',
        width: '100%',
        marginTop: 20
    },
    textFieldGrid: {
        width: 'calc(100% - 16px)',
        maxWidth: 520,
        margin: '0 8px',
        '@media (min-width: 536px)': {
            width: '100%',
            margin: 'auto',
        }
    },
    headerAvatar: {
        width: 28,
        height: 28
    },
    saveNoteButton: {
        padding: '2px 16px 2px 8px',
        border: `1px solid ${theme.palette.primary.main}`
    },
    saveNoteGrid: {
        maxWidth: 'fit-content',
        width: '100%',
        margin: '8px auto',
    },
    caseNotesList: {
        overflowY: 'auto',
        padding: 0,
        '@media (min-width: 536px)': {
            padding: '0 32px',
        },
    },
    header: {
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.20),
        marginBottom: '0.5em',
        '& svg': {
            fontSize: 18,
            marginRight: 8
        }
    },
    textAreaMinHeight: {
        minHeight: '38px'
    },
    inputMultilineHeight: {
        minHeight: '38px',
        maxHeight: 133,
        overflowY: 'auto',
        whiteSpace: 'inherit'
    },
    familyViewContent: {
        width: 'calc(100% - 64px)',
        margin: '0 32px',
        marginTop: 14,
        height: 'auto',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

type Classes = 'root' | 'mainCointainer' | 'header' | 'textFieldGrid' | 'formControl'
    | 'saveNoteButton' | 'saveNoteGrid' | 'headerAvatar' | 'caseNotesList' | 'textAreaMinHeight'
    | 'inputMultilineHeight' | 'familyViewContent' | 'buttonProgress';
type StyledProps = Props & WithStyles<Classes>;

function mapStateToProps({ userSession, noteState }: StoreState) {
    return {
        userSession,
        isLoading: noteState.isLoading
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    caseUuid: string;
    gatherCaseFname: string;
    caseNotes: CaseNote[];
    scrollToTop: () => void;
    resetForm: boolean;
    setCaseNoteValue: (isCaseNoteValid: boolean) => void;
    zIndex: number;
}

interface State {
    isSaving: boolean;
    note: string;
    isCaseNoteValid: boolean;
    isDeleteConfirmationDialogOpen: boolean;
    isEditCaseDialogOpen: boolean;
    activeCaseNote: CaseNote | null;
}

type CombinedProps = StyledProps & InjectedProps;

class CaseNotes extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            isSaving: false,
            note: '',
            isCaseNoteValid: true,
            isDeleteConfirmationDialogOpen: false,
            isEditCaseDialogOpen: false,
            activeCaseNote: null,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        const { resetForm } = newProps;

        if (resetForm) {
            this.setState({
                note: '',
                isCaseNoteValid: true,
                activeCaseNote: null
            });
        }
    }

    renderEditCaseDialog = () => {
        const { zIndex } = this.props;
        const { isEditCaseDialogOpen, activeCaseNote } = this.state;

        return (
            <EditNoteDialog
                open={Boolean(isEditCaseDialogOpen && activeCaseNote)}
                onClose={() => this.closeEditCaseDialog()}
                note={activeCaseNote && activeCaseNote.note || null}
                saveNote={note => this.updateCaseNote(note)}
                header={'Edit Case Note'}
                zIndex={zIndex}
            />
        );
    };

    renderDeleteConfirmationDialog = () => {
        const { isDeleteConfirmationDialogOpen } = this.state;
        const { zIndex } = this.props;

        const deleteDialogHeader = 'Are you sure? Deleting this case note cannot be reversed.';
        const deleteDialogConfirmationButton = 'Delete Note';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={this.closeDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={this.handleDeleteItem}
                zIndex={zIndex}
            />
        );
    };

    renderFamilyViewContent = () => {
        const { classes } = this.props;
        return (
            <TabsInFamilyView
                text="Case Notes are hidden while in Family View"
                icon={<SupervisedUserCircleIcon />}
                containerClass={classes.familyViewContent}
            />
        );
    };

    render() {
        const { classes, userSession, gatherCaseFname, caseNotes, isLoading } = this.props;
        const { note, isCaseNoteValid, isSaving } = this.state;
        const { userData, isUserPhotoSaving } = userSession;
        const notePlaceHolder = `Record important internal notes about ${gatherCaseFname}'s 
        case to share with your team`;
        const isFamilyViewOn = userData && userData.is_family_view_on;

        return (
            <>
                <Grid item xs={12} className={classes.mainCointainer}>
                    <Grid item xs={12} className={classes.header}>
                        <VisibilityOffIcon color="primary" />
                        <Typography color="primary">
                            Case notes are never visible to the family
                        </Typography>
                    </Grid>

                    <Grid item className={classes.textFieldGrid}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Type your note below..."
                                placeholder={notePlaceHolder}
                                value={note}
                                onChange={this.handleOnChange}
                                error={!isCaseNoteValid && note.length === 0}
                                multiline
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    classes: {
                                        root: classes.textAreaMinHeight,
                                        inputMultiline: classes.inputMultilineHeight,
                                        notchedOutline: 'notranslate'
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.saveNoteGrid}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.saveNoteButton}
                            onClick={() => this.saveCaseNote()}
                            disabled={isSaving}
                        >
                            <UserAvatar
                                user={userData}
                                className={classes.headerAvatar}
                                isLoading={isUserPhotoSaving}
                                size={42}
                            />
                            &nbsp;Save Note
                            {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </Grid>
                </Grid>

                {isFamilyViewOn && this.renderFamilyViewContent()}
                {isLoading &&
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <CircularProgress disableShrink />
                        </Grid>
                    </Grid>
                }
                {!isFamilyViewOn && !isLoading &&
                    <TransitionGroup className={classes.caseNotesList} id="case-notes-list">
                        {caseNotes.map((caseNote, i) =>
                            <NoteAnimation key={caseNote.id}>
                                <Note
                                    note={caseNote.note}
                                    updateByUser={caseNote.updated_by_user}
                                    updatedTime={caseNote.updated_time}
                                    onEdit={() => this.editCaseNote(caseNote)}
                                    onDelete={() => this.deleteCaseNote(caseNote)}
                                />
                            </NoteAnimation>
                        )}
                    </TransitionGroup>
                }

                {this.renderDeleteConfirmationDialog()}
                {this.renderEditCaseDialog()}
            </>
        );
    }

    handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { setCaseNoteValue } = this.props;
        const value = event.target.value;
        this.setState({ note: value });

        if (value.length !== 0) {
            setCaseNoteValue(false);
        }
    };

    closeDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: false,
        });
    };

    handleDeleteItem = async () => {
        const { dispatch, caseUuid } = this.props;
        const { activeCaseNote } = this.state;

        if (!activeCaseNote) {
            return;
        }

        await dispatch(deleteCaseNote(caseUuid, activeCaseNote.id));

        this.setState({ isDeleteConfirmationDialogOpen: false });
    };

    updateCaseNote = async (note: string) => {
        const { dispatch, caseUuid } = this.props;
        const { activeCaseNote } = this.state;

        if (!activeCaseNote) {
            return;
        }

        await dispatch(updateCaseNote(note, activeCaseNote.id, caseUuid));

        this.setState({
            isEditCaseDialogOpen: false,
            activeCaseNote: null
        });
    };

    saveCaseNote = async () => {
        const { setCaseNoteValue, scrollToTop, dispatch, caseUuid } = this.props;
        const { note } = this.state;

        if (!note || note.trim().length === 0) {
            this.setState({ isCaseNoteValid: false });
            return;
        }

        this.setState({ isSaving: true });

        await dispatch(addCaseNote(note, caseUuid));

        this.setState({ isSaving: false });

        this.setState({ note: '' });

        setCaseNoteValue(true);
        scrollToTop();
    };

    editCaseNote = (note: CaseNote) => {
        this.setState({
            activeCaseNote: note,
            isEditCaseDialogOpen: true
        });
    };

    closeEditCaseDialog = () => {
        this.setState({
            isEditCaseDialogOpen: false,
            activeCaseNote: null
        });
    };

    deleteCaseNote = (note: CaseNote) => {
        this.setState({
            isDeleteConfirmationDialogOpen: true,
            activeCaseNote: note,
        });
    };

}

export default withState(mapStateToProps)(withStyles(styles)(CaseNotes));
