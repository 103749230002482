import { WhiteboardState } from '../types';
import { GatherAction } from '../actions';
import {
    WHITEBOARD_CASES_REORDERED,
    WHITEBOARD_LOADED,
    WHITEBOARD_LOADING
} from '../actions/Whiteboard.action';
import { CASE_LABEL_UPDATED, LABEL_ADDED_TO_CASE, LABEL_REMOVED_FROM_CASE } from '../actions/CaseLabel.action';
import {
    CASE_NUMBER_UPDATED,
    CASE_UPDATED,
    UPDATING_CASE,
} from '../actions/GatherCase.action';
import { ADD_TASK, CREATING_MOVE_STEP } from '../actions/task/Task.action';
import { TaskType, TrackingStepType, WhiteboardCaseUX, WhiteboardStep } from '../shared/types';
import { joinNameParts } from '../services';
import { UPDATED_NOTE_COUNT } from '../actions/Whiteboard.action';

export const initWhiteboardState = {
    isLoading: false,
    whiteboardCases: [],
};

export const whiteboardState = (
    state: WhiteboardState = initWhiteboardState,
    action: GatherAction
): WhiteboardState => {
    switch (action.type) {
        case ADD_TASK: {
            const {
                gather_case_id,
                type,
                title,
                tracking_step_type,
                marked_complete_time,
                marked_complete_by,
            } = action.task;
            if (type !== TaskType.tracking_step ||
                tracking_step_type !== TrackingStepType.move ||
                !marked_complete_time || !marked_complete_by
            ) {
                return state;
            }
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(wbCase => {
                    if (wbCase.id !== gather_case_id) {
                        return wbCase;
                    }
                    const step: WhiteboardStep = {
                        step_name: title,
                        step_type: tracking_step_type,
                        completed_by_name: joinNameParts(marked_complete_by),
                        completed_by_photo: marked_complete_by.photo,
                        completed_by_photo_transformations: marked_complete_by.photo_transformations,
                        completed_by_time: marked_complete_time,
                    };
                    return {
                        ...wbCase,
                        steps_completed: [...wbCase.steps_completed, step]
                    };
                })
            };
        }
        case CREATING_MOVE_STEP: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(wbCase => {
                    if (wbCase.uuid === action.caseUuid) {
                        return {
                            ...wbCase,
                            current_task_location: {
                                id: action.taskLocation.id,
                                name: action.taskLocation.name,
                                moved_time: new Date(),
                            },
                        };
                    }
                    return wbCase;
                }),
            };
        }
        case UPDATING_CASE: {
            const { current_task_location, case_number, assignee, watchers } = action.changes;
            if (current_task_location === undefined && case_number === undefined && assignee === undefined) {
                return state;
            }
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(wbCase => {
                    if (wbCase.uuid === action.caseUuid) {
                        const changesToMake: Partial<WhiteboardCaseUX> = {};
                        if (current_task_location !== undefined) {
                            changesToMake.current_task_location = current_task_location;
                        }
                        if (case_number !== undefined) {
                            changesToMake.case_number = case_number;
                        }
                        if (assignee !== undefined && assignee.user_id !== null) {
                            changesToMake.assignee_id = assignee.user_id;
                        }
                        if (watchers !== undefined) {
                            changesToMake.watchers = watchers;
                        }
                        return {
                            ...wbCase,
                            ...changesToMake,
                        };
                    }
                    return wbCase;
                }),
            };
        }
        case CASE_UPDATED: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map((wbCase) => {
                    if (wbCase.id !== action.gatherCase.id) {
                        return wbCase;
                    }
                    return {
                        ...wbCase,
                        workflow_id: action.workflow !== undefined
                            ? action.workflow?.id ?? -1
                            : action.gatherCase.workflow_id ?? wbCase.workflow_id,
                        workflow_name: action.workflow !== undefined
                            ? action.workflow?.name ?? ''
                            : action.gatherCase.workflow_name ?? wbCase.workflow_name,
                        dc_weight: action.gatherCase.death_certificate?.about.weight !== undefined
                            ? action.gatherCase.death_certificate?.about.weight
                            : wbCase.dc_weight,
                        dc_pickup_address: action.gatherCase.death_certificate?.about.pickUpAddress !== undefined
                            ? action.gatherCase.death_certificate.about.pickUpAddress
                            : wbCase.dc_pickup_address,
                        dc_dropoff_address: action.gatherCase.death_certificate?.about.dropOffAddress !== undefined
                            ? action.gatherCase.death_certificate.about.dropOffAddress
                            : wbCase.dc_dropoff_address,
                        case_type: action.gatherCase.case_type,
                        watchers: action.gatherCase.watchers,
                    };
                }),
            };
        }
        case CASE_NUMBER_UPDATED: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map((wbCase) => {
                    if (wbCase.id !== action.gatherCase.id) {
                        return wbCase;
                    }
                    return {
                        ...wbCase,
                        case_number: action.gatherCase.case_number,
                    };
                }),
            };

        }
        case UPDATED_NOTE_COUNT: {
            return {
                ...state,
                whiteboardCases: (state.whiteboardCases || []).map(wbCase => {
                    if (wbCase.uuid === action.caseUuid) {
                        return {
                            ...wbCase,
                            notes_count: wbCase.notes_count + action.notes_count_delta,
                        };
                    }
                    return wbCase;
                })
            };
        }
        case WHITEBOARD_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case WHITEBOARD_LOADED: {
            return {
                ...state,
                whiteboardCases: action.whiteboard.whiteboardCases,
                isLoading: false
            };
        }
        case WHITEBOARD_CASES_REORDERED: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(wbCase => {
                    const rank = action.caseUuidList.indexOf(wbCase.uuid);  // uuid list should be in rank order
                    if (rank !== undefined) {
                        return {
                            ...wbCase,
                            rank: rank + 1, // rank is 1 based, indexOf is zero based
                        };
                    }
                    return wbCase;
                })
            };
        }
        case LABEL_ADDED_TO_CASE: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(c => {
                    if (c.uuid === action.caseUuid) {
                        return {
                            ...c,
                            labels: [...c.labels, action.caseLabel]
                        };
                    }
                    return c;
                })
            };
        }
        case LABEL_REMOVED_FROM_CASE: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(c => {
                    if (c.uuid === action.caseUuid) {
                        return {
                            ...c,
                            labels: c.labels.filter(l => l.id !== action.caseLabel.id)
                        };
                    }
                    return c;
                })
            };
        }
        case CASE_LABEL_UPDATED: {
            return {
                ...state,
                whiteboardCases: state.whiteboardCases.map(c => {
                    return {
                        ...c,
                        labels: c.labels.map(l => {
                            if (l.id === action.caseLabel.id) {
                                return {
                                    ...l,
                                    ...action.caseLabel
                                };
                            }
                            return l;
                        })
                    };
                })
            };
        }
        default:
            return state;
    }
};