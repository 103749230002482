import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { TransitionProps } from '@mui/material/transitions';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        position: 'sticky',
        right: 12,
        top: 2,
        zIndex: 1,
        height: 40,
        justifyContent: 'flex-end',
        display: 'flex',
        marginRight: 12,
        transform: 'translateY(100%)',
    },
    inner: {
        background: theme.palette.common.white,
        borderRadius: 12,
        boxShadow: theme.shadows[7],
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        height: 32,
        width: 'max-content',
        justifyContent: 'center',
        margin: '4px 0',
        '& svg': {
            fontSize: 28,
        },
        '& p': {
            padding: '0 4px 0 16px',
            lineHeight: '32px',
        }
    },
    arrow: {
        width: 0,
        height: 0,
        borderTop: '8px solid transparent !important',
        borderLeft: '8px solid #fff',
        borderBottom: '8px solid transparent !important',
        position: 'absolute',
        right: -7,
        background: 'transparent',
        filter: 'drop-shadow(4px 0px 2px rgba(0,0,0,0.2))'
    }
});

type StyledProps = Props & WithStyles<'root' | 'arrow' | 'inner'>;

interface Props extends TransitionProps {
    showMe: boolean;
    iconPosition?: 'start' | 'end';
    text?: string;
    icon?: JSX.Element;
    rootClass?: string;
    innerClass?: string;
    arrowClass?: string;
    backgroundColor?: string;
}

const defaultProps: Partial<Props> = {
    iconPosition: 'end',
    icon: <ArrowForwardIcon color="primary" />,
    text: 'Swipe to see more'
};

const ScrollIndicator = (props: StyledProps) => {
    const {
        classes,
        showMe,
        rootClass,
        mountOnEnter,
        unmountOnExit,
        iconPosition,
        icon,
        text,
        innerClass,
        arrowClass,
        backgroundColor
    } = props;

    return (
        <Fade
            in={showMe}
            mountOnEnter={mountOnEnter}
            unmountOnExit={unmountOnExit}
        >
            <Grid
                item
                className={classNames(classes.root, rootClass)}>
                <Grid
                    item
                    className={classNames(classes.inner, innerClass)}
                    style={{ background: backgroundColor }}
                >
                    {iconPosition === 'start' && icon}

                    <Typography color="primary" component="p">
                        {text}
                    </Typography>

                    {iconPosition === 'end' && icon}

                    <div
                        className={classNames(classes.arrow, arrowClass)}
                        style={{ borderColor: backgroundColor }}
                    />
                </Grid>
            </Grid>
        </Fade>
    );
};

ScrollIndicator.defaultProps = defaultProps;

export default withStyles(styles)(ScrollIndicator);
