
import classNames from 'classnames';
import * as appService from '../../services/app.service';
import { getFormattedPhoneNumber } from '../../services';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useGDispatch, useGSelector } from '../../types';
import { logoutUserSession } from '../../actions/UserSession.action';
import makeGStyles from '../../styles/makeGStyles';
import { UserRoles } from '../../shared/types';
import { SvgIconComponent } from '@mui/icons-material';
import GLink from './GLink';
import { RouteBuilder } from "../../services";
import { AppRoute } from "../../services";
import { useLocation } from 'react-router-dom';

const useStyles = makeGStyles(theme => ({
    root: {
        textAlign: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        marginTop: 60,
        zIndex: 1,
        overflow: 'hidden',
        display: 'flex',
        paddingBottom: 8,
    },
    headingIcon: {
        fontSize: 92,
    },
    heading: {
        fontSize: 29,
        fontWeight: 400,
        '@media (min-width: 768px)': {
            fontSize: 40,
        },
    },
    subHeading: {
        fontSize: 18,
        fontWeight: 200,
        '@media (min-width: 768px)': {
            fontSize: 22,
        },
    },
    needHelpText: {
        fontSize: '15px !important',
        fontWeight: 200,
        marginTop: 40,
    },
    chatWithUsText: {
        fontSize: '15px !important',
        fontWeight: 700,
        color: '#909090',
    },
}), { name: 'BaseErrorPage' });

interface Props {
    HeadingIcon: SvgIconComponent;
    heading: string;
    subHeading?: string;
    rootClass?: string;
    rememberPageLink?: AppRoute;
    otherFamilyPageLink?: AppRoute;
    hideUserInfo?: boolean;
    hideIntercomAction?: boolean;
};

const BaseErrorPage = (props: Props) => {

    const {
        heading,
        subHeading,
        HeadingIcon,
        rootClass,
        rememberPageLink,
        otherFamilyPageLink,
        hideUserInfo,
        hideIntercomAction
    } = props;
    const classes = useStyles();

    const location = useLocation();

    const userData = useGSelector(({ userSession }) => userSession.userData);
    const dispatch = useGDispatch();

    const isFHUser: boolean = UserRoles.isFHUser(userData);
    const isGOMUser: boolean = UserRoles.isGOMUser(userData);

    const renderUserInfo = () => {

        if (!userData) {
            return;
        }

        return (
            <Grid
                item
                xs={12} sm={8} md={8} lg={6}
                className={classNames(classes.marginAuto, classes.marginTop20)}
            >
                <Typography
                    variant="h5"
                    component="p"
                    align="center"
                    className={classNames(classes.subHeading)}
                >
                    {`You are logged in as ${appService.getFullNameFromUser(userData)}`}
                </Typography>
                {userData.email &&
                    <Typography
                        variant="h5"
                        component="p"
                        align="center"
                        className={classNames(classes.subHeading)}
                    >
                        {`(${userData.email})`}
                    </Typography>
                }
                {userData.phone &&
                    <Typography
                        variant="h5"
                        component="p"
                        align="center"
                        className={classNames(classes.subHeading)}
                    >
                        {getFormattedPhoneNumber(userData.phone)}
                    </Typography>
                }
            </Grid>
        );
    };

    return (
        <Grid container className={classNames(classes.root, rootClass)} justifyContent="center">
            <Grid item>
                <HeadingIcon
                    className={classes.headingIcon}
                    color="secondary"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    component="h1"
                    color="secondary"
                    className={classes.heading}
                >
                    {heading}
                </Typography>
                {subHeading &&
                    <Grid item xs={12} sm={8} md={8} lg={6} className={classes.marginAuto}>
                        <Typography
                            variant="h5"
                            component="p"
                            align="center"
                            color="secondary"
                            className={classes.subHeading}
                        >
                            {subHeading}
                        </Typography>
                    </Grid>
                }
                {!hideUserInfo && renderUserInfo()}
            </Grid>

            {userData &&
                <Grid item xs={12} className={classes.marginTop20}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => dispatch(logoutUserSession())}
                    >
                        Log Out
                    </Button>
                </Grid>
            }

            {(!userData && !hideUserInfo) &&
                <Grid item xs={12} className={classes.marginTop20}>
                    <GLink to={RouteBuilder.Login({
                        destination: `${location.pathname}${location.search}${location.hash}`,
                    })}>
                        <Button
                            variant="outlined"
                            color="secondary"
                        >
                            Log In
                        </Button>
                    </GLink>
                </Grid>
            }

            {otherFamilyPageLink &&
                <Grid item xs={12} className={classes.marginTop20}>
                    <GLink to={otherFamilyPageLink}>
                        <Button
                            color="secondary"
                            variant="contained"
                        >
                            Take me to the page where I have access
                        </Button>
                    </GLink>
                </Grid>
            }

            {rememberPageLink &&
                <Grid item xs={12} className={classes.marginTop20}>
                    <GLink to={rememberPageLink}>
                        <Button
                            color="secondary"
                            variant="contained"
                        >
                            Take me to the Public page
                        </Button>
                    </GLink>
                </Grid>
            }

            {(userData && !rememberPageLink) &&
                <Grid item xs={12} className={classes.marginTop20}>
                    <GLink to={RouteBuilder.Root}>
                        <Button
                            color="secondary"
                            variant="outlined"
                        >
                            {isFHUser || isGOMUser ? 'Return To My Dashboard' : 'Redirect me to my loved one'}
                        </Button>
                    </GLink>
                </Grid>
            }

            {!hideIntercomAction &&
                <Grid item className={classNames(classes.widthFitContent, classes.marginAuto)}>
                    <Grid item>
                        <Typography
                            variant="h5"
                            component="p"
                            align="center"
                            className={classes.needHelpText}
                        >
                            Need additional help?
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography
                            variant="h5"
                            component="p"
                            align="center"
                            className={classNames(classes.chatWithUsText, classes.cursorPointer)}
                            onClick={() => appService.showIntercom()}
                        >
                            Chat with us now
                        </Typography>
                    </Grid>
                </Grid>
            }
        </Grid >
    );
};

export default BaseErrorPage;