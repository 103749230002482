import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { SlideTransition } from '../../common/Transitions';

interface Props {
    open: boolean;
    closeDialog: () => void;
    onCloseWindow: () => void;
    zIndex: number;
}

const WarningMessageDialog = (props: Props) => {
    const {
        open,
        onCloseWindow,
        closeDialog,
        zIndex
    } = props;

    const header = 'Are you sure you want to close the window?';
    const subHeader = 'You will lose your unsaved note.';

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex }}
        >
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {subHeader}
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Button variant="text" onClick={closeDialog}>
                    Cancel
                </Button>
                <Button
                    onClick={onCloseWindow}
                    autoFocus
                    color="primary"
                    variant="contained"
                >
                    Close Window
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WarningMessageDialog;
