import { useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';

import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import { GREEN_COLOR } from '../../../../../constants/colorVariables';
import UserAvatar from '../../../../common/UserAvatar';
import makeGStyles from '../../../../../styles/makeGStyles';
import { AvatarUser } from '../../../../../shared/types';
import StepComponentWrapper from './StepComponentWrapper';
import SavePrerequisitesDialog from
    '../../../../workflows/editWorkFlow/editSteps/savePrerequisitesDialog/SavePrerequisites.dialog';
import { PrereqSummary } from
    '../../../../workflows/editWorkFlow/editSteps/savePrerequisitesDialog/SavePrerequisitesDialogContent';
import { useGSelector } from '../../../../../types';
import { canEditTrackingPrerequisite } from '../../../../../shared/authority/can';
import GTooltip from '../../../../common/GTooltip';
import { TOOLTIPS } from '../../../../../constants';

const useStyles = makeGStyles(theme => ({
    root: {
        background: alpha(theme.palette.primary.main, 0.18),
        borderRadius: '0px 0px 12px 12px',
        position: 'relative',
        margin: '0px auto 36px',
        borderTop: 'none',
        padding: '12px 12px 8px',
    },
    labelChip: {
        minWidth: 16,
        minHeight: 16,
        padding: 0,
        height: '16px !important',
        width: '16px !important',
        borderWidth: 1,
        border: `2px dashed`,
        borderRadius: 4,
        color: theme.palette.secondary.main,
        '&$disabled': {
            color: '#b5b5b5',
        }
    },
    avatarSizeTask: {
        height: 18,
        width: 18,
        fontSize: 9,
        textTransform: 'uppercase',
    },
    taskSvg: {
        fontSize: 14,
        padding: 2,
        borderRadius: 16,
        backgroundColor: GREEN_COLOR,
        color: theme.palette.common.white,
    },
    stepsSize: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 4,
        '& button': {
            height: 'fit-content',
            width: 'fit-content',
        },
    },
    stepsFont: {
        padding: '0px 4px 0 8px',
        fontSize: 15,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&$disabled': {
            cursor: 'initial',
            textDecoration: 'none !important',
            color: '#b5b5b5',
        }
    },
    borderRadius16: {
        borderRadius: 16
    },
    greenColor: {
        color: GREEN_COLOR
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 1.35,
        paddingBottom: 12,
    },
    rootOuter: {
        padding: '0 12px',
        maxWidth: 425,
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: 0
        }
    },
    disabled: {}
}), { name: 'StepPrerequisites' });

export interface Prereq {
    key: number;
    title: string;
    past_tense_title: string | null;
    marked_complete_by: AvatarUser | null;
    marked_complete_time: Date | null;
}

interface Props {
    isStepCompleted: boolean;
    zIndex: number;
    workflowName: string;
    prereqs: Prereq[];
    availablePrereqs: PrereqSummary[];
    hideWarningInDialog?: boolean;
    onPrereqClick: (stepKey: number) => void;
    onSave: (prereqKeys: number[]) => void;
}

const StepPrerequisites = (props: Props) => {
    const {
        isStepCompleted,
        zIndex,
        workflowName,
        prereqs,
        availablePrereqs,
        hideWarningInDialog,
        onPrereqClick,
        onSave,
    } = props;

    const classes = useStyles();
    const userProfile = useGSelector(({ userSession }) => userSession.userData);
    const funeralHome = useGSelector(({ funeralHomeState }) => funeralHomeState.activeFuneralHome);
    const canEditPrereq = funeralHome && canEditTrackingPrerequisite(userProfile, funeralHome.id);

    const [isPrereqDialogOpen, setIsPrereqDialogOpen] = useState<boolean>(false);

    const completedPrereqs = useMemo(
        () => prereqs.filter((prereq) => prereq.marked_complete_time !== null),
        [prereqs],
    );

    const prereqKeys = useMemo(() => prereqs.map((p) => p.key), [prereqs]);

    const openPrereqDialog = () => {
        setIsPrereqDialogOpen(true);
    };

    const closePrereqDialog = () => {
        setIsPrereqDialogOpen(false);
    };

    if (isStepCompleted && !prereqs.length) {
        return null;
    }

    const handleOnSave = (keys: number[]) => {

        onSave(keys);
        closePrereqDialog();
    };

    return (
        <Grid
            item
            padding={{ xs: "0 12px", sm: 0 }}
            maxWidth={425}
            width="100%"
            margin="0 auto"
        >
            <StepComponentWrapper
                className={classes.root}
                error={completedPrereqs.length !== prereqs.length}
            >
                {prereqs.length > 0 &&
                    <Typography color="primary" className={classes.heading}>
                        <span> {completedPrereqs.length} of {prereqs.length} Prerequisites Complete</span>
                        <span className={classes.fontSize13}>
                            The following must be completed before completing this step
                        </span>
                    </Typography>
                }

                <div>
                    {prereqs.map((step) => {

                        const tooltipTitle = step.marked_complete_by && step.marked_complete_time
                            ? `Step completed by ${step.marked_complete_by.fname} on
                ${moment(step.marked_complete_time).format('MM/D/YYYY @ h:mm:a z')}`
                            : '';
                        const isComplete = step.marked_complete_time !== null;

                        return (
                            <div className={classes.stepsSize} key={step.key}>
                                {isComplete ?
                                    <IconButton onClick={e => onPrereqClick(step.key)} >
                                        <CheckIcon className={classes.taskSvg} />
                                    </IconButton>
                                    : <Button className={classes.labelChip} onClick={e => onPrereqClick(step.key)} />
                                }

                                <Typography
                                    color="secondary"
                                    className={classNames(
                                        classes.stepsFont,
                                        isComplete && classes.greenColor,
                                        isStepCompleted && classes.disabled
                                    )}
                                    onClick={e => isStepCompleted ? undefined : onPrereqClick(step.key)}
                                >
                                    {isComplete ? step.past_tense_title || step.title : step.title}
                                </Typography>
                                {step.marked_complete_by &&
                                    <Tooltip title={tooltipTitle} enterDelay={400} placement="top">
                                        <UserAvatar
                                            size={20}
                                            user={step.marked_complete_by}
                                            className={classes.avatarSizeTask}
                                        />
                                    </Tooltip>
                                }
                            </div>
                        );
                    })}

                    {!isStepCompleted &&
                        <div className={classNames(classes.stepsSize, classes.justifyContentCenter)}>
                            <GTooltip
                                contentDisabled={!canEditPrereq}
                                title={!canEditPrereq
                                    ? TOOLTIPS.DISABLED_FEATURE
                                    : ''}
                            >
                                <IconButton disabled={!canEditPrereq} onClick={openPrereqDialog}>
                                    <AddIcon
                                        className={classNames(classes.labelChip,
                                            classes.borderRadius16, !canEditPrereq && classes.disabled)}
                                    />
                                </IconButton>
                            </GTooltip>

                            <GTooltip
                                contentDisabled={!canEditPrereq}
                                title={!canEditPrereq
                                    ? TOOLTIPS.DISABLED_FEATURE
                                    : ''}
                            >
                                <Typography
                                    color="secondary"
                                    className={classNames(classes.stepsFont, !canEditPrereq && classes.disabled)}
                                    onClick={canEditPrereq ? openPrereqDialog : undefined}
                                >
                                    Add a Prerequisite Step
                                </Typography>
                            </GTooltip>
                        </div>
                    }
                </div>
                <SavePrerequisitesDialog
                    isDialogOpen={isPrereqDialogOpen}
                    workflowName={workflowName}
                    availablePrereqs={availablePrereqs}
                    selectedKeys={prereqKeys}
                    zIndex={zIndex + 1}
                    hideWarning={hideWarningInDialog}
                    onSave={handleOnSave}
                    onClose={closePrereqDialog}
                />
            </StepComponentWrapper>
        </Grid>
    );
};

export default StepPrerequisites;
