import * as React from 'react';
import { orderBy } from 'lodash';

import { TableColumnType, TableBodyType, StoreState } from '../../../types';
import { AvatarUser, getDisplayTitleForFHUser } from '../../../shared/types/user';
import TableHeader from '../../common/table/TableHeader';
import InfiniteTableBody from '../../common/table/InfiniteTableBody';

import {
    EntityCaseRole,
    GatherCaseUX,
    getDisplayRelationshipForPerson,
    UserRole,
    UserRoles,
    EntitySummary,
    getCaseEntity,
} from '../../../shared/types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import classNames from 'classnames';
import { ORANGE_COLOR, RED_COLOR } from '../../../constants/colorVariables';
import { openHelperInvitationDialog, openMySettingsDialog } from '../../../actions/Dialog.action';
import { resendInvitation, removeHelper } from '../../../actions/CaseHelper.action';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { getEntityHomeAddress, getFormattedPhoneNumber } from '../../../services/app.service';
import { GLOBAL_STYLED_PROPS } from '../../../styles';
import {
    WhenCanResendInvitation,
    WhenCanRevokeInvitation,
    WhenCanEditPerson,
    WhenCanDeletePerson,
    WhenCanInviteUser,
} from '../../common/AppPermissions';
import { joinNameParts } from '../../../shared/utils';
import { getConfirmationDialogContentForHelpers } from '../helperPopper/HelperPopper';
import { canViewOrganizePage } from '../../../shared/authority/can';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    paper: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        minHeight: 564,
        boxShadow: theme.shadows[5],
    },
    tableGrid: {
        width: '100%',
        margin: '18px 0',
    },
    textCenter: {
        textAlign: 'center'
    },
    cellWidth140: {
        minWidth: 140
    },
    cellWidth200: {
        minWidth: 200,
    },
    cellWidth120: {
        minWidth: 120
    },
    secondaryMenuItem: {
        color: ORANGE_COLOR
    },
    deleteMenuItem: {
        color: RED_COLOR
    },
    overlapDotClass: {
        left: 2
    },
});

interface CaseEntityTableView {
    id: number;
    entity_id: number;
    name: string;
    display_relationship: string | null;
    case_role: 'Admin' | 'Guest' | 'Deceased';
    email: string | null;
    phone: string | null;
    home_address: string;
    invited_to_gather: string;
    avatar_user: AvatarUser;
    show_overlap_dot: boolean;
    can_view_organize: boolean;
}

const mapStateToProps = ({ casesState, userSession, tasksState }: StoreState) => {
    return {
        userSession,
        tasksState,
        helpers: casesState.helpers,
        isLoading: casesState.isHelpersLoading,
    };
};

interface Props extends ReturnType<typeof mapStateToProps> {
    selectedCase: GatherCaseUX;
    openHelperPopper: (
        entityId: number,
        event: React.MouseEvent<HTMLElement>
    ) => void;
    dispatch: AppDispatch;
    zIndex: number;
}

interface State {
    sortBy: keyof CaseEntityTableView;
    sortDirection: 'asc' | 'desc';
    menuAnchor: HTMLElement | null;
    searchText: string;
    activeHelperEntityId: number | null;
    isConfirmDialogOpen: boolean;
}

const DEFAULT_STATE: State = {
    sortBy: 'entity_id',
    sortDirection: 'asc',
    menuAnchor: null,
    searchText: '',
    activeHelperEntityId: null,
    isConfirmDialogOpen: false
};

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'paper' | 'tableGrid' | 'textCenter' |
    'cellWidth200' | 'cellWidth140' | 'cellWidth120' | 'secondaryMenuItem' | 'deleteMenuItem' |
    'overlapDotClass'>;

class CaseHelperReportTable extends React.Component<StyledProps, State> {
    state: State = {
        ...DEFAULT_STATE
    };

    getTableColumns = (): TableColumnType<CaseEntityTableView>[] => {
        const { classes } = this.props;

        return [
            {
                id: 'name',
                label: 'Name',
                isString: true,
                userAvatarProperty: 'avatar_user',
                color: 'primary',
                showUserAvatarOverlap: 'show_overlap_dot',
                userAvatarOverlapClass: classes.overlapDotClass,
                cellClass: classNames(classes.textCenter, classes.cellWidth200),
                userAvatarCallBack: true
            },
            { id: 'display_relationship', label: 'Relationship', isString: true },
            { id: 'case_role', label: 'Type', isString: true },
            { id: 'email', label: 'Email', isString: true, cellClass: classes.cellWidth200 },
            { id: 'phone', label: 'Phone', isString: true, cellClass: classes.cellWidth120 },
            { id: 'home_address', label: 'Address', isString: true, cellClass: classes.cellWidth200 },
            {
                id: 'invited_to_gather', label: 'Invited to Gather?',
                isString: true,
                disableSorting: true,
                cellClass: classes.cellWidth120
            }
        ];
    };

    generateTableComponentReadableData = (data: EntitySummary[]): TableBodyType<CaseEntityTableView> => {
        const { selectedCase } = this.props;
        const { sortBy, sortDirection } = this.state;

        const tableData: CaseEntityTableView[] = data.map((d): CaseEntityTableView | null => {

            const isFHUser = UserRoles.isFHUser(d) || selectedCase.assignee_entity_id === d.entity_id;

            const caseEntity = getCaseEntity(d, selectedCase.id);
            if (!caseEntity && !isFHUser) {
                return null;
            }

            const isAdmin = isFHUser || UserRoles.isFamilyAdmin(d, selectedCase.id);

            const avatarUser = d.is_deceased ? {
                fname: d.fname,
                lname: d.lname
            } : {
                fname: d.fname,
                lname: d.lname,
                photo: d.photo,
                photo_transformations: d.photo_transformations,
            };

            return {
                id: d.entity_id,
                entity_id: d.entity_id,
                name: joinNameParts(d),
                display_relationship: caseEntity
                    ? getDisplayRelationshipForPerson(caseEntity)
                    : isFHUser
                        ? getDisplayTitleForFHUser(d, selectedCase.funeral_home_id)
                        : null,
                case_role: d.is_deceased
                    ? 'Deceased'
                    : isAdmin
                        ? 'Admin'
                        : 'Guest',
                invited_to_gather: d.user_id && 'Yes' || 'No',
                avatar_user: avatarUser,
                show_overlap_dot: isAdmin,
                phone: getFormattedPhoneNumber(d.phone),
                email: d.email,
                home_address: getEntityHomeAddress(d) || '-',
                can_view_organize: isFHUser || canViewOrganizePage({
                    target: d,
                    funeralHomeId: selectedCase.funeral_home.id,
                    caseId: selectedCase.id,
                }),
            };
        }).filter((d): d is CaseEntityTableView => d !== null);

        return {
            data: orderBy(tableData, sortBy, sortDirection),
            propertyList: this.getTableColumns()
        };
    };

    renderMenuOptions = (helpersAndAssignee: EntitySummary[]) => {
        const { activeHelperEntityId, menuAnchor } = this.state;
        const { selectedCase, classes, zIndex } = this.props;

        const activeHelper = activeHelperEntityId &&
            helpersAndAssignee.find((e) => e.entity_id === activeHelperEntityId) || null;

        if (!activeHelper || !menuAnchor) {
            return null;
        }

        const activeCaseEntity = getCaseEntity(activeHelper, selectedCase.id);
        const isFHUser = UserRoles.isFHUser(activeHelper);

        const isActiveMenuOpen: boolean =
            Boolean(activeCaseEntity && activeCaseEntity.accepted_time || !activeHelper.user)
            || isFHUser;
        const isPendingUserMenuOpen: boolean =
            Boolean(activeCaseEntity && !activeCaseEntity.accepted_time && activeHelper.user);

        return (
            <>
                <div>
                    <Menu
                        anchorEl={menuAnchor}
                        id="card-options-menu"
                        open={isActiveMenuOpen}
                        onClose={this.closeMenu}
                        style={{ zIndex }}
                    >
                        <WhenCanEditPerson target={activeHelper}>
                            <MenuItem onClick={this.toggleMySettingsDialog}>
                                <ContactMailIcon />&nbsp;
                                Edit {activeHelper.fname}'s Details
                            </MenuItem>
                        </WhenCanEditPerson>
                        {!isFHUser &&
                            <WhenCanDeletePerson target={activeHelper}>
                                <MenuItem onClick={this.confirmBeforeRemovingHelper} className={classes.deleteMenuItem}>
                                    <PersonAddDisabledIcon />&nbsp;
                                    Delete {activeHelper.fname}
                                </MenuItem>
                            </WhenCanDeletePerson>
                        }
                        {!activeHelper.is_deceased && !activeHelper.user_id &&
                            <WhenCanInviteUser role={UserRole.User} caseRole={EntityCaseRole.guest}>
                                <MenuItem
                                    onClick={e =>
                                        this.openInvitationDialog(
                                            EntityCaseRole.guest,
                                            activeHelper.entity_id,
                                        )
                                    }
                                >
                                    <SendIcon />&nbsp;
                                    Invite {activeHelper.fname}
                                </MenuItem>
                            </WhenCanInviteUser>
                        }

                    </Menu>
                </div>
                <div>
                    <Menu
                        anchorEl={menuAnchor}
                        id="card-options-menu"
                        open={isPendingUserMenuOpen}
                        onClose={this.closeMenu}
                        style={{ zIndex }}
                    >
                        <WhenCanResendInvitation target={activeHelper}>
                            <MenuItem onClick={this.resendInvitation}>
                                <SendIcon />&nbsp;
                                Resend Invitation
                            </MenuItem>
                        </WhenCanResendInvitation>
                        <WhenCanRevokeInvitation target={activeHelper}>
                            <MenuItem onClick={this.confirmBeforeRemovingHelper}>
                                <CancelScheduleSendIcon />&nbsp;
                                Revoke Invitation
                            </MenuItem>
                        </WhenCanRevokeInvitation>
                        <WhenCanEditPerson target={activeHelper}>
                            <MenuItem onClick={this.toggleMySettingsDialog}>
                                <ContactMailIcon />&nbsp;
                                Edit {activeHelper.fname}'s Details
                            </MenuItem>
                        </WhenCanEditPerson>
                    </Menu>
                </div>
            </>
        );
    };

    renderSecondaryMenuItem = (element: CaseEntityTableView) => {
        const { selectedCase, classes } = this.props;

        if (element.can_view_organize) {
            return null;
        }

        return (
            <Tooltip
                placement="top"
                title={`This helper cannot view the Organize side of ${selectedCase && selectedCase.fname}'s case`}
                enterDelay={600}
            >
                <Grid item className={classes.secondaryMenuItem}>
                    <VisibilityOffIcon />
                </Grid>
            </Tooltip>
        );
    };

    render() {
        const {
            sortBy,
            sortDirection,
            activeHelperEntityId,
            isConfirmDialogOpen
        } = this.state;
        const {
            classes,
            zIndex,
            helpers,
            selectedCase,
            isLoading,
            openHelperPopper,
        } = this.props;

        const activeHelper = helpers.find((e) => e.entity_id === activeHelperEntityId) || null;

        const confirmationDialogContent = activeHelper &&
            getConfirmationDialogContentForHelpers(selectedCase, activeHelper);
        const isDCEntity = confirmationDialogContent && confirmationDialogContent.isDCEntity || '';
        const header = confirmationDialogContent && confirmationDialogContent.header || '';
        const confirmText = confirmationDialogContent && confirmationDialogContent.confirmText || '';
        const cancelButtonText = confirmationDialogContent && confirmationDialogContent.cancelButtonText || '';
        const confirmButtonText = confirmationDialogContent && confirmationDialogContent.confirmButtonText || '';

        const { assignee } = selectedCase;
        const helpersAndAssignee = [assignee, ...helpers];
        const tableData = this.generateTableComponentReadableData(helpersAndAssignee);

        return (
            <>
                <Grid item xs={12} className={classes.tableGrid}>
                    <Paper className={classes.paper}>
                        <Table>
                            <TableHeader
                                headerColumns={this.getTableColumns()}
                                order={sortDirection}
                                orderBy={sortBy}
                                sortHandler={this.sortHandler}
                                color="primary"
                            />
                            <InfiniteTableBody
                                tableData={tableData}
                                isDataLoading={isLoading}
                                hasMoreData={false}
                                hideActionsMenu={false}
                                handleMenuClick={this.handleMenuClick}
                                secondaryMenuItem={this.renderSecondaryMenuItem}
                                handleLoadMore={() => undefined}
                                callBackAction={(ele, _, e) => openHelperPopper(ele.id, e)}
                            />
                        </Table>
                    </Paper>
                </Grid>

                {this.renderMenuOptions(helpersAndAssignee)}

                <ConfirmationDialog
                    header={header}
                    subHeader={confirmText}
                    confirmationButtonText={confirmButtonText}
                    onClose={this.closeConfirmationDialog}
                    open={isConfirmDialogOpen}
                    onConfirm={isDCEntity ? undefined : this.removeHelper}
                    zIndex={zIndex + 1}
                    cancelButtonText={cancelButtonText}
                />
            </>
        );
    }

    openInvitationDialog = (currentTab: EntityCaseRole = EntityCaseRole.admin, entityId: number) => {
        const { dispatch, zIndex } = this.props;
        dispatch(openHelperInvitationDialog({
            zIndex: zIndex + 1,
            defaultTab: currentTab,
            sendInvite: true,
            selectedEntityId: entityId,
        }));
        this.closeMenu();
    };

    removeHelper = () => {
        const { dispatch, helpers, selectedCase } = this.props;
        const { activeHelperEntityId } = this.state;

        const activeHelper = helpers.find((e) => e.entity_id === activeHelperEntityId);
        const caseEntity = activeHelper && getCaseEntity(activeHelper, selectedCase.id);

        if (!activeHelper || !caseEntity) {
            return;
        }

        dispatch(removeHelper(selectedCase.uuid, activeHelper));
        this.closeConfirmationDialog();
    };

    resendInvitation = async () => {
        const { selectedCase, helpers, dispatch } = this.props;
        const { activeHelperEntityId } = this.state;

        const activeHelper = helpers.find((e) => e.entity_id === activeHelperEntityId);
        const caseEntity = activeHelper && getCaseEntity(activeHelper, selectedCase.id);

        if (!activeHelper || !caseEntity) {
            return;
        }

        if (!caseEntity.accepted_time && activeHelper.user) {
            await dispatch(resendInvitation(selectedCase.uuid, activeHelper));
        }
        this.closeMenu();
    };

    confirmBeforeRemovingHelper = () => {
        this.setState({
            isConfirmDialogOpen: true,
            menuAnchor: null
        });
    };

    closeConfirmationDialog = () => {
        this.setState({
            isConfirmDialogOpen: false,
            activeHelperEntityId: null
        });
    };

    toggleMySettingsDialog = () => {
        const { dispatch, zIndex } = this.props;
        const { activeHelperEntityId } = this.state;

        if (activeHelperEntityId) {
            dispatch(openMySettingsDialog(zIndex + 1, activeHelperEntityId));
        }

        this.closeMenu();
    };

    sortHandler = (
        event: React.MouseEvent<HTMLElement>,
        property: keyof CaseEntityTableView,
        isString: boolean
    ) => {
        let sortDirection: 'asc' | 'desc' = 'desc';
        if (this.state.sortBy === property && this.state.sortDirection === 'desc') {
            sortDirection = 'asc';
        }

        this.setState({
            sortDirection,
            sortBy: property
        });
    };

    handleMenuClick = (event: React.MouseEvent<HTMLElement>, ele: CaseEntityTableView) => {
        this.setState({
            menuAnchor: event.currentTarget,
            activeHelperEntityId: ele.entity_id,
        });
    };

    closeMenu = () => {
        this.setState({ menuAnchor: null });
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(CaseHelperReportTable));
