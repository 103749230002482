import { patchDeathCertificate } from "../../../actions/GatherCase.action";
import { DeathCertificatePatchRequest } from "../../../shared/types";
import { GStyles } from "../../../styles/GStyles";
import { useGDispatch } from "../../../types";
import GDialogWithTextField from "../../common/GDialogWithTextField";

interface Props {
    decedentWeight: string | null;
    caseFullName: string;
    caseUuid: string;
    zIndex: number;
    open: boolean;
    onClose: () => void;
    onSaveCallback?: () => void;
}
const DecedentWeightDialog = (props: Props) => {
    const { decedentWeight, caseFullName, open, zIndex, caseUuid, onClose, onSaveCallback } = props;

    const dispatch = useGDispatch();

    const saveCaseWeight = async (weight: string) => {
        const changes: DeathCertificatePatchRequest = {
            about: {
                weight,
            },
        };

        onClose();

        await dispatch(patchDeathCertificate({
            changes,
            caseUuid,
        }));

        onSaveCallback?.();
    };

    return (
        <GDialogWithTextField
            zIndex={zIndex}
            title="Decedent Weight"
            initialValue={decedentWeight || ''}
            description={<>
                Weight for&nbsp;
                <span className={GStyles.fontWeight600}>
                    {caseFullName}
                </span>
            </>}
            isDialogOpen={open}
            textFieldLabel="Type Decedent Weight Here"
            closeDialog={onClose}
            skipValidation
            onSave={saveCaseWeight}
        />
    );
};

export default DecedentWeightDialog;