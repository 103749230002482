import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HelpIcon from '@mui/icons-material/Help';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { APP_SECONDARY_COLOR, SKYBLUE_COLOR } from '../../../constants/colorVariables';
import TabsInFamilyView from '../../common/TabsInFamilyView';
import {
    CaseIdPhotoUX,
    GatherCaseUX,
    PhotoScopeEnum,
    PhotoTransformationsType
} from '../../../shared/types';
import FileUploader, { AcceptFileType } from '../../common/FileUploader';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import SecureBodyPhoto from './SecureBodyPhoto';
import BadgeIcon from '@mui/icons-material/Badge';
import { useGDispatch, useGSelector } from '../../../types';
import { getDataFromBlob } from '../../../services/doc.service';
import PhotoCropper from '../../profileImage/PhotoCropper';
import { getDataURIFromFile, getIntercomTargetProp } from '../../../services';
import {
    deleteCaseIdPhoto,
    loadCaseIdPhotos,
    uploadCaseIdPhoto,
} from '../../../actions/CaseIdPhotos.action';
import makeGStyles from '../../../styles/makeGStyles';
import { useEffect, useState } from 'react';

const useStyles = makeGStyles((theme) => ({
    root: {},
    marginTop30: {
        marginTop: 30,
    },
    addPhoto: {
        border: `2px dashed ${APP_SECONDARY_COLOR}`,
        borderRadius: 16,
        color: APP_SECONDARY_COLOR,
        textTransform: 'none',
        fontWeight: 400,
        width: 280,
        height: 280,
        minWidth: 280,
        '& svg': {
            fontSize: 70,
        },
        '@media (min-width: 360px)': {
            width: 320,
            height: 320,
            minWidth: 320
        },
    },
    uploadPhoto: {
        height: 280,
        '@media (min-width: 360px)': {
            height: 320,
        },
    },
    uploadAnotherPhoto: {
        marginTop: 30,
        minHeight: 64,
    },
    uploadPhotoText: {
        display: 'flex',
        fontSize: 16,
        marginTop: 12,
    },
    remoteIdentificationText: {
        width: 280,
        margin: '0 auto',
        marginTop: 20,
        fontSize: 12,
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        borderRadius: 16,
        padding: '4px 8px',
        '& svg': {
            fontSize: 16,
            float: 'left',
        },
        '@media (min-width: 360px)': {
            width: 320,
            fontSize: 14,
            '& svg': {
                fontSize: 20,
            },
        },
    },
    grid: {
        display: 'grid',
        width: 'fit-content',
        margin: 'auto',
        columnGap: '20px',
        '@media (min-width: 720px)': {
            gridTemplateColumns: 'repeat(2, minmax(320px, 320px))',
        }
    },
    loading: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    displayFlexContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row-reverse',
        },
    }
}), { name: 'Id' });

interface Props {
    selectedCase: GatherCaseUX;
    zIndex: number;
    isFamilyView: boolean;
}

const Id = (props: Props) => {
    const {
        selectedCase,
        zIndex,
        isFamilyView,
    } = props;

    const classes = useStyles();

    const dispatch = useGDispatch();

    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);
    const [activeImage, setActiveImage] = useState<CaseIdPhotoUX | null>(null);
    const [isPhotoUploading, setIsPhotoUploading] = useState<boolean>(false);
    const [isPhotoCropperOpen, setIsPhotoCropperOpen] = useState<boolean>(false);
    const [imageUri, setImageUri] = useState<string>('');
    const [filename, setFilename] = useState<string>('');

    const caseIdPhotos = useGSelector(({ casesState }) => casesState.caseIdPhotos);
    const isLoading = useGSelector(({ casesState }) => casesState.caseIdPhotosLoading);

    useEffect(() => {
        dispatch(loadCaseIdPhotos(selectedCase.uuid));
    }, [dispatch, selectedCase.uuid]);

    const renderUploadImageBtn = (btnClass: string, btnText: string, footerText?: string) => {
        return (
            <FileUploader
                handleSave={onFileUpload}
                acceptTypes={[
                    AcceptFileType.JPEG,
                    AcceptFileType.GIF,
                    AcceptFileType.PNG,
                ]}
            >
                {(inputEl) =>
                    <Button
                        className={classNames(classes.addPhoto, btnClass)}
                        onClick={() => inputEl && inputEl.click()}
                        {...getIntercomTargetProp(`TrackingPage-IDTab-AddNewIDPhoto`)}
                    >
                        <div className={classNames(classes.flexCentred, classes.flexDirectionColumn)}>
                            {footerText &&
                                <span
                                    className={classNames(
                                        classes.uploadPhotoText,
                                        classes.margin_0
                                    )}
                                >
                                    {btnText}
                                </span>
                            }

                            <AddAPhotoIcon
                                className={footerText
                                    && classNames(
                                        classes.marginTop10,
                                        classes.marginBottom10
                                    )
                                    || undefined
                                }
                            />

                            <span
                                className={classNames(
                                    classes.uploadPhotoText,
                                    footerText && classNames(classes.fontStyleItalic, classes.margin_0)
                                )}
                            >
                                {footerText || btnText}
                            </span>
                        </div>
                    </Button>
                }
            </FileUploader>
        );
    };

    const togglePhotoCropper = () => {
        setIsPhotoCropperOpen(prev => !prev);
    };

    const toggleDeleteConfirmationDialog = (img?: CaseIdPhotoUX) => {
        setIsDeleteConfirmationDialogOpen(prev => !prev);
        setActiveImage(img || null);
    };

    const handleDeleteImage = () => {
        if (activeImage !== null) {
            dispatch(deleteCaseIdPhoto(selectedCase.uuid, activeImage.s3_file_id));
        }

        toggleDeleteConfirmationDialog();
    };

    const onFileUpload = async (files: FileList | null) => {
        if (files && files.length !== 0) {
            const dataUri = await getDataURIFromFile(files[0]);

            setImageUri(dataUri);
            setFilename(files[0].name);
            togglePhotoCropper();
        }
    };

    const saveNewImage = async (_: PhotoTransformationsType, __: string, newImageBlob?: Blob) => {

        setIsPhotoUploading(true);

        if (newImageBlob) {
            const docToUpload = await getDataFromBlob(newImageBlob, filename);
            if (docToUpload) {
                await dispatch(uploadCaseIdPhoto(selectedCase.uuid, docToUpload));
            }
        }

        setIsPhotoUploading(false);
    };

    if (isFamilyView) {
        return (
            <TabsInFamilyView
                text="Photo Identification is hidden while in Family View"
                icon={<BadgeIcon />}
            />
        );
    }

    if (isLoading) {
        return (
            <Grid item xs={12} justifyContent="center" className={classes.loading}>
                <CircularProgress
                    color="primary"
                    size={86}
                    thickness={3}
                />
            </Grid>
        );
    }

    const hasPhotos = caseIdPhotos.length > 0;

    const caseFirstName = selectedCase.fname;

    const primaryBtnTxt = `Add ID Photo of ${caseFirstName}`;
    const secondaryBtnTxt = `Add another ID Photo of ${caseFirstName}`;

    const bottomText = 'Used for secure identification. Will be deleted 7 days after workflow completion.';

    const deleteDialogHeader = `Are you sure?`;
    const deleteDialogSubHeader = `Are you sure you want to delete this image? It will be gone forever.`;
    const deleteDialogConfirmationButton = `Delete Forever`;
    const deleteDialogCancelButtonText = `Don't Delete`;

    const loadingFragment = (
        <Grid
            item
            xs={12}
            justifyContent="center"
            className={classNames(classes.loading, classes.padding10)}
        >
            <CircularProgress
                color="primary"
                size={86}
                thickness={3}
            />
        </Grid>
    );

    return (
        <Grid item={true} xs={12} className={classes.textCenter}>
            <Typography
                color="secondary"
                className={classes.remoteIdentificationText}
                {...getIntercomTargetProp(`TrackingPage-IDTab-ImageVisibilityText`)}
            >
                <VisibilityOffIcon /> <span>Images will only be visible to anyone invited to perform an
                    identification of {caseFirstName}.</span>
            </Typography>

            <Grid
                item={true}
                className={classNames(
                    !isPhotoUploading && classes.marginTop30 || undefined,
                    caseIdPhotos.length > 0 ? classes.grid : classes.displayFlexContent
                )}
            >
                {(!hasPhotos && !isPhotoUploading) &&
                    renderUploadImageBtn(classes.uploadPhoto, primaryBtnTxt, bottomText)
                }

                {hasPhotos &&
                    caseIdPhotos.map((image) =>
                        <SecureBodyPhoto
                            caseUuid={selectedCase.uuid}
                            rootClass={classes.marginTop30}
                            key={image.s3_file_id}
                            zIndex={zIndex}
                            caseFname={caseFirstName}
                            photo={image}
                            onDeleteClick={() => toggleDeleteConfirmationDialog(image)}
                            imageOverlayTopText="Warning"
                            imageOverlayBottomText={
                                <>
                                    The following image is of {caseFirstName} after they
                                    passed away. This may be difficult for some to view.
                                    Please proceed only if you are comfortable doing so.
                                </>
                            }
                            usePopper
                            intercomTargetPropFlipCard={`TrackingPage-IDTab-FlipCardPhoto`}
                        />
                    )
                }
                {isPhotoUploading && loadingFragment}

                {(hasPhotos || isPhotoUploading)
                    && renderUploadImageBtn(classes.uploadAnotherPhoto, secondaryBtnTxt)
                }
            </Grid>
            
            <Typography
                color="secondary"
                style={{ background: SKYBLUE_COLOR }}
                className={classes.remoteIdentificationText}
                {...getIntercomTargetProp(`TrackingPage-IDTab-NoCameraOption`)}
            >
                <HelpIcon /> <span>Don't see the option to take an ID photo using the camera?</span>
            </Typography>

            <ConfirmationDialog
                header={deleteDialogHeader}
                subHeader={deleteDialogSubHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={toggleDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={handleDeleteImage}
                cancelButtonText={deleteDialogCancelButtonText}
                zIndex={zIndex}
            />

            <PhotoCropper
                radius={0}
                options={{
                    defaultOrientation: 'square'
                }}
                scope={PhotoScopeEnum.web}
                imageURI={imageUri}
                callBackAction={saveNewImage}
                isDialogOpen={isPhotoCropperOpen}
                closeDialog={() => togglePhotoCropper()}
                zIndex={zIndex + 1}
                maxSize={640}
                generateBlob
            />
        </Grid>
    );
};

export default Id;
