import {
    canAccessCasesReport,
    canAccessHelpersReport,
    canAccessRolodexReport,
    canAccessBatchReport,
    canAccessRevenueReport,
    canAccessPaymentReport,
    canAccessKeeptrackReport,
    canAccessCollectionsReport,
    canAccessOverpaidContractsReport,
    canAccessHelperReport,
    canAccessPriceListReport,
    canAccessCaseSummaryReport,
    canAccessInformantReport,
    canAccessMerchSalesReport,
    canAccessDiscountsReport,
    canAccessPriceAdjustmentsReport,
    canAccessWorkflowByCaseReport,
    canAccessSalesTaxReport,
    canAccessDispatchReport,
    canAccessPrepReport,
    canAccessLegacyReports,
    canAccessInsurancePoliciesReport,
} from '../shared/authority/can';
import { UserProfile, EntitySummary, FeatureKey, DateType, CaseType, ProductCategoryEnum } from '../shared/types';
import { LegacyReportPage } from '../shared/navigationUtils';
import { isEnabled } from '../components/common/AppFeature';
import { FuneralHomeState, GatherCasesState, UserSession } from '../types';

export interface DashboardLegacyReport {
    key: ReportCategoryEnum;
    canAccess: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) => boolean;
    link: LegacyReportPage;
    feature?: FeatureKey;
}

export enum ReportCategoryEnum {
    payment_report = 'Payments Report',
    batch_report = 'Batch Report',
    revenue_report = 'Revenue Report',
    cases_report = 'Cases Report',
    helpers_report = 'Helpers Report',
    rolodex_report = 'Rolodex Report',
    custom_reports = 'Custom Reports',
    keeptrack_report = 'KeepTrack Report',
    standard_reports = 'Standard Reports',
    payout_report = 'Payout Report',
    dispatch_report = 'Dispatch Report',
    prep_report = 'Prep Report',
    insurance_policies = 'Insurance Policies',
}

export const LEGACY_REPORTS: DashboardLegacyReport[] = [{
    key: ReportCategoryEnum.standard_reports,
    canAccess: canAccessLegacyReports,
    link: LegacyReportPage.STANDARDREPORTS
}, {
    key: ReportCategoryEnum.cases_report,
    canAccess: canAccessCasesReport,
    link: LegacyReportPage.CASESREPORT,
}, {
    key: ReportCategoryEnum.helpers_report,
    canAccess: canAccessHelpersReport,
    link: LegacyReportPage.HELPERSREPORT,
}, {
    key: ReportCategoryEnum.rolodex_report,
    canAccess: canAccessRolodexReport,
    link: LegacyReportPage.ROLODEXREPORT,
}, {
    key: ReportCategoryEnum.batch_report,
    canAccess: canAccessBatchReport,
    link: LegacyReportPage.BATCHREPORT,
    feature: FeatureKey.BATCH_EXTRACT
}, {
    key: ReportCategoryEnum.revenue_report,
    canAccess: canAccessRevenueReport,
    link: LegacyReportPage.REVENUEREPORT,
}, {
    key: ReportCategoryEnum.payment_report,
    canAccess: canAccessPaymentReport,
    link: LegacyReportPage.PAYMENTSREPORT,
}, {
    key: ReportCategoryEnum.keeptrack_report,
    canAccess: canAccessKeeptrackReport,
    link: LegacyReportPage.KEEPTRACKREPORT
}, {
    key: ReportCategoryEnum.dispatch_report,
    canAccess: canAccessDispatchReport,
    link: LegacyReportPage.DISPATCHREPORT,
    feature: FeatureKey.DISPATCH_REPORT,
}, {
    key: ReportCategoryEnum.prep_report,
    canAccess: canAccessPrepReport,
    link: LegacyReportPage.PREPREPORT,
    feature: FeatureKey.PREP_REPORT,
}, {
    key: ReportCategoryEnum.insurance_policies,
    canAccess: canAccessInsurancePoliciesReport,
    link: LegacyReportPage.INSURANCEPOLICIES,
    // feature: FeatureKey.INSURANCE_POLICIES_REPORT, // TODO: Add feature flag
}, {
    key: ReportCategoryEnum.custom_reports,
    canAccess: canAccessLegacyReports,
    link: LegacyReportPage.CUSTOMREPORT
}];

export const getDefaultLegacyReportLink = (
    funeralHomeState: FuneralHomeState,
    casesState: GatherCasesState,
    userSession: UserSession,
): LegacyReportPage | null => {
    if (!userSession.userData || !funeralHomeState.activeFuneralHome) {
        return null;
    }

    const defaultReport = LEGACY_REPORTS.find(report =>
        report.canAccess(userSession.userData, funeralHomeState.activeFuneralHome?.id ?? null)
        && (report.feature !== undefined
            ? isEnabled(report.feature, funeralHomeState, casesState, userSession)
            : true)
    );

    return defaultReport ? defaultReport.link : null;
};

export enum StandardReportEnum {
    collections = 'collections',
    overpaid = 'overpaid',
    aging = 'aging',
    discounts = 'discounts',
    adjustments = 'adjustments',
    merch_sales = 'merch_sales',
    sales_tax = 'sales_tax',
    helper = 'helper',
    informant = 'informant',
    price_list = 'price_list',
    case_summary = 'case_summary',
    workflow_by_case = 'workflow_by_case',
}
export interface StandardReportsRecord {
    key: StandardReportEnum;
    title: string;
    canAccess: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) => boolean;
    description: string;
    learnMoreKey: string;
    dateFilters?: DateType[];
    caseTypes?: CaseType[];
    categories?: ProductCategoryEnum[];
    directors?: EntitySummary[];
}

export const STANDARD_REPORTS: StandardReportsRecord[] = [
    {
        title: 'Case Summary',
        key: StandardReportEnum.case_summary,
        canAccess: canAccessCaseSummaryReport,
        description: `Summary of cases in Gather for a given date range, filterable by case type(s).`,
        learnMoreKey: `LearnMoreCaseSummaryReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate],
        caseTypes: Object.keys(CaseType).map(key => CaseType[key]),
    },
    {
        title: 'Informant Report',
        key: StandardReportEnum.informant,
        canAccess: canAccessInformantReport,
        description: `This report is similar to the Helper Report except that it will only show the helpers\
            that were added as the informant on the Death Certificate over the selected date range.  The date\
            range is based on the case to which the informant belongs.`,
        learnMoreKey: `LearnMoreInformantReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate],
    },
    {
        title: 'Helper Report',
        key: StandardReportEnum.helper,
        canAccess: canAccessHelperReport,
        description: `This report will show all helpers who have been added to any case for the selected\
            date range.  The date range is based on the case to which the helper belongs. A helper includes\
            any family or friend added to Gather as an Admin, Guest, Informant, or Deceased Family Member.\
            This will include invited and non-invited helpers.`,
        learnMoreKey: `LearnMoreHelperReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate],
    },
    {
        title: 'WorkFlow By Case Report',
        key: StandardReportEnum.workflow_by_case,
        canAccess: canAccessWorkflowByCaseReport,
        description: `Quickly see which WorkFlows are most popular. This report shows the WorkFlow that\
             was selected for each case over the selected date range.`,
        learnMoreKey: `LearnMoreWorkflowByCaseReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate],
        caseTypes: Object.keys(CaseType).map(key => CaseType[key]),
    },
    {
        title: 'Collections Report',
        key: StandardReportEnum.collections,
        canAccess: canAccessCollectionsReport,
        description: `Stay on top of collections across all of your cases. This report gives you a list\
             of all cases which have an open balance and will be cumulative for all time. To view cases\
             with an overpayment, please use the “Overpaid Contracts Report.”`,
        learnMoreKey: `LearnMoreCollectionsReport`,
    },
    {
        title: 'Overpaid Contracts Report',
        key: StandardReportEnum.overpaid,
        canAccess: canAccessOverpaidContractsReport,
        description: `Stay on top of any overpayments across all of your cases. This report gives you a\
             list of all cases where you have collected more than what was owed and will be cumulative for all time.`,
        learnMoreKey: `LearnMoreOverpaidContractReport`,
    },
    {
        title: 'Price List',
        key: StandardReportEnum.price_list,
        canAccess: canAccessPriceListReport,
        description: `This report downloads all products that have been entered into Gather as of today and\
            can be filtered by item category. Deleted items will not be included.`,
        learnMoreKey: `LearnMorePriceListReport`,
        categories: Object.keys(ProductCategoryEnum).map(key => ProductCategoryEnum[key]),
    },
    {
        title: 'Merch Sales Report',
        key: StandardReportEnum.merch_sales,
        canAccess: canAccessMerchSalesReport,
        description: `See item sales per case. This report can be filtered by specific item categories`,
        learnMoreKey: `LearnMoreMerchSalesReport`,
        categories: Object.keys(ProductCategoryEnum).map(key => ProductCategoryEnum[key]),
        dateFilters: [DateType.DeathDate, DateType.CreatedDate, DateType.TransactionDate],
        caseTypes: Object.keys(CaseType).map(key => CaseType[key]),
    },
    {
        title: 'Discounts by Director',
        key: StandardReportEnum.discounts,
        canAccess: canAccessDiscountsReport,
        description: `Keep tabs on the Package or Contract Discounts your team members are offering`,
        learnMoreKey: `LearnMoreDiscountsReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate, DateType.TransactionDate],
        caseTypes: Object.keys(CaseType).map(key => CaseType[key]),
    },
    {
        title: 'Price Adjustments by Director',
        key: StandardReportEnum.adjustments,
        canAccess: canAccessPriceAdjustmentsReport,
        description: `Keep tabs on the items for which your team members are adjusting prices. One time items\
            added to the contract will not be included.`,
        learnMoreKey: `LearnMorePriceAdjustmentReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate, DateType.TransactionDate],
        caseTypes: Object.keys(CaseType).map(key => CaseType[key]),
    },
    {
        title: 'Sales Tax Report',
        key: StandardReportEnum.sales_tax,
        canAccess: canAccessSalesTaxReport,
        description: `Summary of taxes charged per case. For tax remittance please refer to your bookkeeping\
             software (e.g. QuickBooks Online).`,
        learnMoreKey: `LearnMoreSalesTaxReport`,
        dateFilters: [DateType.DeathDate, DateType.CreatedDate],
    },
];

export const ProductItemCategories: Record<ProductCategoryEnum, string> = {
    [ProductCategoryEnum.care_of_loved_one]: 'Care of Loved One',
    [ProductCategoryEnum.equipment_facilities_staff]: 'Equipment and Facilities',
    [ProductCategoryEnum.transportation]: 'Transportation',
    [ProductCategoryEnum.casket]: 'Casket',
    [ProductCategoryEnum.urn]: 'Urn',
    [ProductCategoryEnum.vault]: 'Vault',
    [ProductCategoryEnum.cash_advance]: 'Cash Advances',
    [ProductCategoryEnum.memorial]: 'Memorial Products',
    [ProductCategoryEnum.flowers]: 'Flowers',
    [ProductCategoryEnum.cemetery]: 'Cemetery',
};
