import { convertHexToRGBA } from '../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';

export function styleWrapper<Props extends object>() {
    const AssignStyles: StyleRulesCallback<Theme, Props> = (theme) => ({
        root: {},
        checkboxList: {
            outline: 0,
            margin: '0px 10px 0px 20px',
            maxHeight: '400px',
            overflowX: 'hidden',
            overflowY: 'auto',
            '&$assignPopover': {
                margin: 0,
                maxHeight: 360,
                padding: '12px 8px',
                boxShadow: 'inset 0px 0px 6px 0px rgba(0, 0, 0, 0.2)',
                background: convertHexToRGBA(theme.palette.primary.main, 0.12),
                '& $checkboxItem': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
            },
        },
        checkboxItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop:"3px",
            paddingBottom: "1px"
        },
        itemLabel: {
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            alignItems: 'center',
            '& h6': {
                lineHeight: 1.2,
                fontWeight: 400,
            },
        },
        menuClass: {
            borderRadius: '4px',
            maxWidth: 360,
            width: '100%',
            overflow: 'inherit',
        },
        menuHeader: {
            padding: '8px 0px',
            textAlign: 'center',
            outline: 0,
        },
        assignButton: {
            textTransform: 'none',
            fontSize: 18,
            marginLeft: 10,
            fontWeight: 200,
            whiteSpace: 'nowrap',
        },
        assignLink: {
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'left',
            fontFamily: 'system-ui, sans-serif !important',
            color: '#757575',
            lineHeight: '22px',
        },
        assignLinkHover: {
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        assignTaskMenu: {
            fontSize: '16px',
            padding: '24px 100% 0 16px',
            display: 'block',
            height: '48px',
            width: '100%',
        },
        rootButton: {
            padding: '8px 4px 8px',
            textTransform: 'none',
        },
        nonHelperButton: {
            '& $buttonLabel': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            },
            '& $primary': {
                fontWeight: 200,
                textTransform: 'none',
            },
            '& $secondary': {
                textTransform: 'none',
                fontStyle: 'italic',
                fontWeight: 200,
            },
        },
        badgeCount: {
            width: '100%',
            '& span': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                padding: 0,
                borderRadius: '50%',
                height: 32,
                width: 32,
                fontSize: 14,
            },
        },
        userAvatar: {
            lineHeight: '1em',
            fontSize: 18,
            textTransform: 'uppercase',
            '&$nonUserAvatar': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.secondary.main,
                border: '1px dashed',
                opacity: 0.5,
            },
        },
        overlapDot: {
            top: 2,
            left: 2,
        },
        labelContainer: {
            marginLeft: 8,
            maxWidth: 210,
            '&$maxWidth240': {
                maxWidth: 240,
            },
            '& p': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                lineHeight: '1.1em',
                width: 'auto',
            },
            '& span': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                lineHeight: '1.1em',
                width: 'auto',
            },
            '& $secondary': {
                fontSize: 14,
                textTransform: 'capitalize',
                fontWeight: 400,
                '&:last-child': {
                    textTransform: 'initial',
                },
            },
            '& $primary': {
                fontSize: 16,
            },
        },
        divider: {
            backgroundColor: theme.palette.primary.main,
            margin: '4px 0',
            height: '0.3px',
        },
        popperFooter: {
            textAlign: 'center',
            '& button': {
                margin: '12px 0',
            },
        },
        badge: {
            top: 6,
            right: 8,
        },
        fadedTextWithOpacity: {
            opacity: 0.5,
            cursor: 'inherit',
        },
        widthFitContent: {
            width: 'fit-content',
        },
        height40: {
            height: 40,
        },
        itemPadding: {
            padding: '4px 8px',
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        lineHeight1_2: {
            lineHeight: '1.2 !important',
        },
        listItemText: {
            padding: '0 16px 0 8px',
            '& $secondary': {
                color: theme.palette.primary.main,
            },
        },
        disabled: {
            opacity: 0.5,
        },
        assignSecondaryButtonStyle: {
            display: 'flex',
            flexDirection: 'column',
            '& $primary': {
                fontWeight: 200,
                textTransform: 'none',
            },
            '& $secondary': {
                textTransform: 'none',
                fontStyle: 'italic',
                fontWeight: 200,
            },
        },
        selectHelperPopoverElement: {
            margin: '8px 0 4px',
        },
        maxWidth240: {},
        assignPopover: {},
        buttonLabel: {},
        primary: {},
        secondary: {},
        item: {},
        nonUserAvatar: {},
    });

    return AssignStyles;
}

export type AssignStyleProps = WithStyles<'root' | 'checkboxList' | 'checkboxItem' | 'itemLabel' | 'menuClass'
    | 'menuHeader' | 'assignButton' | 'assignLink' | 'assignLinkHover' | 'assignTaskMenu' | 'rootButton'
    | 'nonHelperButton' | 'badgeCount' | 'userAvatar' | 'overlapDot' | 'labelContainer' | 'divider' | 'popperFooter'
    | 'badge' | 'fadedTextWithOpacity' | 'widthFitContent' | 'height40' | 'itemPadding' | 'lineHeight1_2'
    | 'listItemText' | 'disabled' | 'assignSecondaryButtonStyle' | 'selectHelperPopoverElement' | 'maxWidth240'
    | 'assignPopover' | 'buttonLabel' | 'primary' | 'secondary' | 'item' | 'nonUserAvatar'>;
