import { memoize, times } from "lodash";
import Grid from "@mui/material/Grid";
import { Fragment, memo } from "react";
import { StickerType } from "./PrintQRStickers.dialog";

interface Params {
    startingColumn: number;
    currentColumn: number;
    currentRow: number;
    remainingItemsToBeFilled: number;
    elementsInOneColumn: number;
}
const shouldIncludeInNextColumn = memoize(({
    startingColumn,
    currentColumn,
    currentRow,
    remainingItemsToBeFilled,
    elementsInOneColumn
}: Params) => {
    if (remainingItemsToBeFilled <= 0) {
        return false;
    }

    for (let i = 1; i <= 8; i++) {
        const isColumnIncluded = (startingColumn + i) === currentColumn
            && currentRow < (remainingItemsToBeFilled - (i - 1) * elementsInOneColumn);

        if (isColumnIncluded) {
            return true;
        }
    }

    return false;
});

export interface QRStickersRenderItemParams {
    included: boolean;
    isNextColumn: boolean;
    isNextRow: boolean;
    currentColumn: number;
    currentRow: number;
}
interface Props {
    columns: number;
    startingColumn: number;
    startingRow: number;
    count: number;
    stickerType: StickerType;
    printMode?: boolean;
    columnSpacing?: number;
    renderItem: (params: QRStickersRenderItemParams) => JSX.Element | null;
}
const QRStickersList = (props: Props) => {
    const { columns, startingColumn, startingRow, count, columnSpacing, stickerType, printMode, renderItem } = props;

    const isQRStickers = stickerType === StickerType.QR_5160;
    const is2160Stickers = stickerType === StickerType.QR_2160;
    const is5160or2160Selected = isQRStickers || is2160Stickers;

    const elementsInOneColumn = isQRStickers ? 10 : is2160Stickers ? 8 : 5;
    const array = Array.from({ length: columns }).map(() => times(elementsInOneColumn));

    return (
        <>
            {array.map((items, currentColumn) =>
                <Grid
                    container
                    item
                    xs={is5160or2160Selected ? 6 : 4}
                    key={currentColumn}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '100%',
                        maxWidth: isQRStickers ? '33.33%' : is2160Stickers && printMode ? '2.625in' : '50%',
                        flexBasis: isQRStickers ? '33.33%' : is2160Stickers && printMode ? '100%' : '50%',
                        height: '100%',
                        flexGrow: 1,
                        boxSizing: 'border-box',
                        paddingLeft: columnSpacing,
                    }}
                >
                    {items.map((_, currentRow) => {
                        const remainingItemsToBeFilled = (startingRow + count) - elementsInOneColumn;
                        const isNextColumn = shouldIncludeInNextColumn({
                            remainingItemsToBeFilled,
                            startingColumn,
                            currentColumn,
                            currentRow,
                            elementsInOneColumn
                        });

                        const isNextRow = (startingRow + count) > currentRow
                            && startingRow < currentRow;
                        const included = ((startingRow === currentRow || isNextRow)
                            && startingColumn === currentColumn)
                            || isNextColumn;

                        return (
                            <Fragment key={currentRow}>
                                {renderItem({ included, isNextColumn, isNextRow, currentColumn, currentRow })}
                            </Fragment>
                        );
                    })}
                </Grid>
            )}
        </>
    );
};

export default memo(QRStickersList);