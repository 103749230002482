import * as React from 'react';
import { Subtract } from 'utility-types';
import { Breakpoint } from '@mui/material';
import useFullScreen from '../hooks/useFullScreen';

export interface WithFullScreenProps {
    fullScreen: boolean;
}

function withFullScreen(breakpoint?: Breakpoint) {
    return <Props extends WithFullScreenProps>(
        WrappedComponent: React.ComponentType<Props>
    ): React.ComponentType<Subtract<Props, WithFullScreenProps>> => {
        const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

        const ComponentWithFullScreen = (props: Subtract<Props, WithFullScreenProps>): JSX.Element => {
            const fullScreenMatch = useFullScreen(breakpoint ?? 'sm');

            return <WrappedComponent {...(props as Props)} fullScreen={fullScreenMatch} />;
        };
        ComponentWithFullScreen.displayName = displayName;

        return ComponentWithFullScreen;
    };
}

export default withFullScreen;
