import classNames from 'classnames';
import * as appService from '../../../../services/app.service';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { GatherCaseUX } from '../../../../shared/types';

import Typography from '@mui/material/Typography';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
}

const DeleteInstagram = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
    } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    Verified immediate family members may request the removal of {activeCase.fname}'s
                    account from Instagram. When you submit  a request for removal, Instagram will
                    require proof that you're {activeCase.fname}'s immediate family
                    member. {activeCase.fname}'s Instagram profile, photos, videos, comments,
                    likes and followers will be permanently removed.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={
                        classNames(
                            classes.detailBoxHeader,
                            classes.fontSize18,
                            classes.backgroundPrimary,
                            classes.padding10
                        )
                    }
                >
                    You will need the following...
                </Typography>
                <Grid
                    item

                    className={
                        classNames(
                            classes.padding10,
                            classes.textLeft
                        )
                    }
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        Full Name:
                        <span
                            className={classes.fontWeight500}
                            id="caseName"
                        >
                            {` ${appService.getFullNameFromCase(activeCase)}`}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Instagram Username
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Link to {activeCase.fname}'s Instagram
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Passing:
                        <span className={classes.fontWeight500} id="deathDate">
                            {activeCase.dod_start_date
                                ? ` ${appService.formatDateString(activeCase.dod_start_date)} `
                                : ''}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        One of the following proofs of direct family relationship:
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            {activeCase.fname}'s death certificate
                        </li>
                        <li className={classes.marginTop10}>
                            {activeCase.fname}'s birth certificate
                        </li>
                        <li className={classes.marginTop10}>
                            Proof of authority
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid>
                <a
                    href="https://help.instagram.com/contact/1474899482730688?helpref=faq_content"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Delete {activeCase.fname}'s Instagram <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default DeleteInstagram;
