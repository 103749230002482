import RealGNavigate, { RealGNavigateProps } from './RealGNavigate';

interface Props extends Omit<RealGNavigateProps, 'to'> {
    to: string;
}

/**
 * WARNING: Only use this component when absolutely necessary.
 * Please use GNavigate for most use cases.
 */
function GNavigateWithString(props: Props) {
    return <RealGNavigate {...props} />;
}

export default GNavigateWithString;
