import * as t from 'io-ts';
import values from "lodash/values";
import { Features, FuneralHomeRecord, FuneralHomeUXPreview, TrackingStepCompleteRequestType } from ".";
import { AppRoute } from '../navigationUtils';
import { GatherCaseRecord, GatherCaseSummary } from './case';
import { TrackingStepCompleteRequest } from "./task";
import { getValidator, Nullable } from './utils';

export enum AssignKeepTrackSearchParam {
    keepsakeId = 'k',
    trackerId = 't',
    funeralHomeKey = 'f',
    caseName = 'c',
}

export enum KeeptrackTagType {
    tracking = 'tracking',
    keepsake = 'keepsake',
}

export const isValidKeeptrackTagType = (str: unknown): str is KeeptrackTagType => {
    return Boolean(typeof str === 'string' && KeeptrackTagType[str]);
};

export enum KeeptrackCardType {
    keeptrack = 'keeptrack',
    additional = 'additional',
}

export const KeeptrackTagPrefixLookup = {
    [KeeptrackTagType.tracking]: 'T',
    [KeeptrackTagType.keepsake]: 'K',
};

// validates that tagId is a string AND that it has a valid format
export const isValidKeeptrackTag = (tagId: unknown): tagId is string => {
    return Boolean(
        typeof tagId === 'string'
        && tagId.length === 7
        && values(KeeptrackTagPrefixLookup).some((v) => v === tagId[0])
    );
};

export interface GenericTag {
    id: string;
    type: KeeptrackTagType | null;
}

export interface TrackingTag extends GenericTag {
    type: KeeptrackTagType.tracking;
}

export const isTrackingTag = (tag: GenericTag): tag is TrackingTag => {
    //  NOTE: we could get more strict and check the characters we are using
    const regexPattern = /^[tT]\w{6}$/;
    return regexPattern.test(tag.id);
};

export interface KeepsakeTag extends GenericTag {
    type: KeeptrackTagType.keepsake;
}

export const isKeepsakeTag = (tag: GenericTag): tag is KeepsakeTag => {
    const regexPattern = /^[kK]\w{6}$/;
    return regexPattern.test(tag.id);
};

export interface KeeptrackTagBatchRecord {
    id: number;
    created_time: Date;
}

export interface KeeptrackTagRecord {
    id: string;
    created_time: Date;
    batch_id: number | null;
    tag_type: KeeptrackTagType;
    is_physical: boolean;
    reserved_time: Date | null;
    received_time: Date | null;
    allocated_time: Date | null;
    deleted_time: Date | null;
}

export interface KeeptrackCardRecord {
    keepsake_id: string;
    tracker_id: string | null;
    card_type: KeeptrackCardType;
    gather_case_id: number | null;
    scanned_time: Date;
    assigned_time: Date | null;
    finalized_time: Date | null;
    deleted_time: Date | null;
}

export interface KeeptrackCardUXRecord extends KeeptrackCardRecord {
    is_physical: boolean;
}

export interface KeeptrackCardUX extends KeeptrackCardUXRecord {
}

export interface KeeptrackTagUXRecord extends
    Pick<KeeptrackTagRecord,
        'id' |
        'created_time' |
        'batch_id' |
        'tag_type' |
        'reserved_time' |
        'received_time' |
        'allocated_time' |
        'is_physical'
    >,
    Pick<Nullable<KeeptrackCardRecord>,
        'gather_case_id' |
        'card_type' |
        'keepsake_id' |
        'tracker_id' |
        'scanned_time'
    > {
    funeral_home_key: Nullable<FuneralHomeRecord>['key'];
    case_name: Nullable<GatherCaseRecord>['name'];
    is_case_deleted: boolean;
    is_ready: boolean;
    is_finalized: boolean;
}

export interface KeeptrackTagUX extends KeeptrackTagUXRecord {

}

// ---> AssignKeepTrackRequest <---
const AssignKeepTrackRequestType = t.type({
    initialize_step_id: t.number,
    initialize_step_request: TrackingStepCompleteRequestType,
    tracker: t.string,
    keepsake: t.string,
});

export interface AssignKeepTrackRequest extends t.TypeOf<typeof AssignKeepTrackRequestType> {
    initialize_step_request: TrackingStepCompleteRequest;
}

export class AssignKeepTrackRequest {
    public static fromRequest = getValidator<AssignKeepTrackRequest>(AssignKeepTrackRequestType);
}

export interface KeeptrackTagAssignableResponse {
    cardType: KeeptrackCardType;
    caseAssignedLink: AppRoute | null;
}

export enum KeepTrackCustomerType {
    disabled = 'disabled',
    no_tracker = 'no_tracker',
    tracker_optional = 'tracker_optional',
    tracker_required = 'tracker_required',
}

export const getKeepTrackCustomerType = (params: { features: Features }): KeepTrackCustomerType => {
    const { features } = params;
    if (!features.KEEP_TRACK.enabled) {
        return KeepTrackCustomerType.disabled;
    } else if (features.KEEP_TRACK_USES_TRACKERS.enabled) {
        if (features.KEEP_TRACK_REQUIRE_TRACKER.enabled) {
            return KeepTrackCustomerType.tracker_required;
        } else {
            return KeepTrackCustomerType.tracker_optional;
        }
    } else {
        return KeepTrackCustomerType.no_tracker;
    }
};

export interface KeepTrackPageResponse {
    selectedFuneralHome: FuneralHomeUXPreview;
    selectedCase: GatherCaseSummary | null;
    virtualTrackerId?: string;
    virtualKeepsakeId?: string;
}

export enum TrackingTabsEnum {
    Track = 'Track',
    Prints = 'Prints',
    Items = 'Items',
    Vitals = 'Vitals',
    ID = 'ID',
    Files = 'Files'
}

export function isTrackingTab(tab: string): tab is TrackingTabsEnum {
    return tab in TrackingTabsEnum;
}