import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import moment from 'moment';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ClearIcon from '@mui/icons-material/Clear';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import { getFormattedPhoneNumber, getPaymentMethodLabel } from '../../../../services';
import {
    FuneralHomeUX,
    GatherCaseUX,
    CaseTransactions,
    Transaction,
    PaymentMethod,
    getDisplayTitleForFHUser,
    PaymentMode
} from '../../../../shared/types';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';
import withGStyles from '../../../../styles/WithGStyles';
import { PaymentFieldToolTip, PaymentMethodIcon } from '.';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import { Tooltip } from '@mui/material';


const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 40,
        right: 12,
        fontSize: 36,
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    cashDialog: {},
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
        '& p': {
            fontSize: 12,
            fontWeight: '200',
            color: theme.palette.primary.main,
            lineHeight: '14px',
            '&$noReceiptSent': {
                fontWeight: 300,
                fontSize: 10
            }
        },
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        boxShadow: `6px 0 4px -4px rgba(0,0,0,0.14), 
            -6px 0 4px -4px rgba(0,0,0,0.14)`,
        position: 'relative',
        padding: '20px 0px 16px 0px',
        background: 'transparent',
        maxHeight: 'calc(100% - 96px)',
        '&:before': {
            background: `linear-gradient(-45deg, #fff 16px, transparent 0), 
            linear-gradient(45deg, #fff 16px, transparent 0)`,
            backgroundPosition: 'left-bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '20px 24px',
            content: '" "',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 20,
        },
        '&:after': {
            background: `linear-gradient(-45deg, transparent 16px, #fff 0), 
            linear-gradient(45deg, transparent 16px, #fff 0)`,
            backgroundPosition: 'left-bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '20px 24px',
            content: '" "',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 24,
        },

        '@media (min-width: 400px)': {
            width: 360
        },
    },
    cashDesc: {
        '& p': {
            color: theme.palette.primary.main,
        }
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0px 12px !important',
        width: '100%',
        margin: ' auto',
        textAlign: 'center',
    },
    headerContent: {
        color: theme.palette.primary.main,
        '& p': {
            color: theme.palette.primary.main,
        },
        '& svg': {
            color: theme.palette.primary.main,
        }
    },
    spinnerContent: {
        minHeight: 320,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        display: 'flex'
    },
    cardNumber: {
        margin: '4px 0px 0px 20px !important',
        color: theme.palette.primary.main,
        textAlign: 'left'
    },
    cardNumberPrint: {
        color: theme.palette.primary.main,
    },
    noReceiptSent: {}
});

interface Props {
    isDialogOpen: boolean;
    funeralHome: FuneralHomeUX | null;
    transactions: CaseTransactions;
    activeCase: GatherCaseUX;
    paymentId: number | null;
    closeDialog: () => void;
    paymentMethodName: (method: PaymentMethod | null, mode: PaymentMode | null) => string;
    zIndex: number;
    sentNotification: boolean;
    isRestricted: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    isPrintMode: boolean;
}

export const typographyStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '1.25em',
    margin: 0,
};

export const iconStyle = {
    fontSize: 88,
    width: '1em',
    height: '1em',
    display: 'inline-block',
    flexShrink: 0,
    fontFamily: 'Material Icons'
};

export const smallTyphographyStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 10,
    width: '250px',
    margin: 'auto',
    fontWeight: 200,
};

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'dialogPaper' | 'cashDialog' | 'dRoot' | 'dialogContent' | 'noReceiptSent'
        | 'clearIcon' | 'bottomContent' | 'cashDesc' | 'headerContent' | 'spinnerContent' | 'cardContainer'
        | 'cardNumber' | 'cardNumberPrint'>;

class PaymentReceiptDialog extends React.Component<StyledProps & DialogProps, State> {

    constructor(props: StyledProps & DialogProps) {
        super(props);
        this.state = {
            isPrintMode: false,
        };
    }

    renderSpinnerContent() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.spinnerContent}>
                <CircularProgress color="primary" size={90} thickness={2} />
            </Grid>
        );
    }

    renderPaymentContent(payment: Transaction) {
        const { funeralHome, activeCase, classes, closeDialog, sentNotification } = this.props;

        const method = payment.method;
        const mode = payment.mode;
        const paymentDate = moment(payment.payment_date).format('D MMMM YYYY');
        const createdDate = moment(payment.date).format('D MMMM YYYY');
        const amount = Transaction.dollarFormatUnsigned(payment, true);
        const merchFee = payment.merch_fee !== null && Transaction.dollarFormatNumber(payment.merch_fee);
        const totalAmount = Transaction.dollarFormatUnsigned(payment);
        const { isPrintMode } = this.state;
        const isNonUser = !payment.payer_user_id;
        const showReceiptSentTo = Boolean(sentNotification && (payment.payer_email || payment.payer_phone));
        const fhUserTitle = getDisplayTitleForFHUser(activeCase.assignee, activeCase.funeral_home_id);
        const isRestricted = this.props.isRestricted;

        return (
            <Grid
                xs={12}
                item
                id="print-receipt"
                style={{
                    margin: 'auto',
                    textAlign: 'center',
                }}
            >
                {!isPrintMode &&
                    <div className={classes.cardContainer}>
                        <Typography className={classes.cardNumber} style={{ ...typographyStyle }}>
                            <span style={{ fontWeight: 200 }}>{activeCase.case_number}</span>
                        </Typography>
                    </div>
                }

                {!isPrintMode &&
                    <ClearIcon
                        className={classNames(classes.clearIcon)}
                        onClick={() => closeDialog()}
                    />
                }
                <div
                    style={{
                        textAlign: 'center',
                        margin: !isPrintMode && '16px 0px 12px' || undefined
                    }}
                >
                    <FuneralHomeLogo
                        logoSize={'large'}
                        style={{ maxWidth: 280, maxHeight: 40 }}
                    />
                </div>
                {isPrintMode && funeralHome &&
                    <div style={{ marginBottom: 0 }}>
                        <Typography
                            style={{
                                ...smallTyphographyStyle,
                                borderTop: '1px solid',
                                paddingTop: 4,
                                textAlign: 'center'
                            }}
                        >
                            {funeralHome.address1}
                        </Typography>
                        <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                            {funeralHome.city && <span>{funeralHome.city}, </span>}
                            {funeralHome.state && <span>{funeralHome.state} </span>}
                            {funeralHome.postal_code && <span>{funeralHome.postal_code}</span>}
                        </Typography>
                        <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                            {getFormattedPhoneNumber(activeCase.funeral_home.phone)}
                        </Typography>
                        <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                            {activeCase.assignee.email}
                        </Typography>

                        <div
                            style={{
                                ...smallTyphographyStyle,
                                borderBottom: '1px solid',
                                paddingTop: 4,
                                textAlign: 'center'
                            }}
                        />
                        <Typography
                            className={classes.cardNumberPrint}
                            style={{
                                ...smallTyphographyStyle,
                                textAlign: 'left', fontSize: 14, marginTop: 4, width: 268
                            }}
                        >
                            {activeCase.case_number}
                        </Typography>
                    </div>
                }
                <div
                    className={classes.headerContent}
                    style={{
                        margin: 'auto 8px',
                        textAlign: 'center',
                    }}
                >
                    <PaymentMethodIcon
                        paymentMethod={method}
                        iconStyle={iconStyle}
                        classFillPrimary={classes.fillPrimary}
                    />

                    <Typography
                        style={{
                            ...typographyStyle,
                            fontSize: 24,
                            lineHeight: '18px',
                            textAlign: 'center',
                            borderBottom: '1px solid',
                            width: isPrintMode ? 250 : undefined,
                            paddingBottom: 10,
                            margin: 'auto',
                        }}
                    >
                        Payment Recorded
                    </Typography>
                </div>

                <div
                    className={classes.cashDesc}
                    style={{
                        margin: '8px 0 16px',
                        textAlign: 'center'
                    }}>
                    {method === PaymentMethod.insurance &&
                        <>
                            <Typography style={typographyStyle}>
                                Insurance Company:&nbsp;
                                <span style={{ fontWeight: 200 }}>
                                    {payment.payer_name || ''}
                                </span>
                            </Typography>
                            <Typography style={typographyStyle}>
                                Policy Number:&nbsp;
                                <span style={{ fontWeight: 200 }}>
                                    {payment.external_id || ''}
                                </span>
                            </Typography>
                        </>
                    }
                    <Typography style={typographyStyle}>
                        Payment Method:&nbsp;
                        <span style={{ fontWeight: 200, textTransform: 'capitalize' }}>
                            {getPaymentMethodLabel(method, mode)}
                        </span>
                    </Typography>
                    <Tooltip
                        enterDelay={400}
                        placement="top"
                        title={!isRestricted && PaymentFieldToolTip.paymentSubtotal}
                    >
                        <Typography style={typographyStyle}>
                            Payment Subtotal:&nbsp;
                            <span style={{ fontWeight: 200 }}>{amount}</span>
                        </Typography>
                    </Tooltip>
                    {merchFee &&
                        <Tooltip
                            enterDelay={400}
                            placement="top"
                            title={!isRestricted && PaymentFieldToolTip.paymentFee}
                        >
                            <Typography style={typographyStyle}>
                                Convenience Fee:&nbsp;
                                <span style={{ fontWeight: 200 }}>{merchFee}</span>
                            </Typography>
                        </Tooltip>
                    }
                    {merchFee &&
                        <Tooltip
                            enterDelay={400}
                            placement="top"
                            title={!isRestricted && PaymentFieldToolTip.paymentTotal}
                        >
                            <Typography style={typographyStyle}>
                                Payment Total:&nbsp;
                                <span style={{ fontWeight: 200 }}>{totalAmount}</span>
                            </Typography>
                        </Tooltip>
                    }
                    {method === PaymentMethod.check &&
                        <Typography style={typographyStyle}>
                            Check Number:&nbsp;
                            <span style={{ fontWeight: 200 }}>{payment.external_id}</span>
                        </Typography>
                    }
                    <Tooltip
                        enterDelay={400}
                        placement="top"
                        title={!isRestricted && PaymentFieldToolTip.paymentDate}
                    >
                        {method === PaymentMethod.insurance ?
                            <Typography style={typographyStyle}>
                                Recorded:&nbsp;
                                <span style={{ fontWeight: 200 }}>{paymentDate}</span>
                            </Typography>
                            :
                            <Typography style={typographyStyle}>
                                Payment Date:&nbsp;
                                <span style={{ fontWeight: 200 }}>{paymentDate}</span>
                            </Typography>
                        }
                    </Tooltip>
                    {method !== PaymentMethod.insurance &&
                        <Tooltip
                            enterDelay={400}
                            placement="top"
                            title={!isRestricted && PaymentFieldToolTip.paymentCreatedDate}
                        >
                            <Typography style={typographyStyle}>
                                Payment Created Date:&nbsp;
                                <span style={{ fontWeight: 200 }}>{createdDate}</span>
                            </Typography>
                        </Tooltip>
                    }
                </div>

                <div className={classes.cashDesc} style={{
                    margin: '8px 0 16px',
                    textAlign: 'center'
                }}>
                    <Typography style={typographyStyle}>
                        Payer:&nbsp;
                        <span style={{ fontWeight: 200 }}>{payment.payer_name}</span>
                    </Typography>
                    <Typography style={typographyStyle}>
                        Services Of:&nbsp;
                        <span style={{ fontWeight: 200 }}>
                            {activeCase.fname} {activeCase.lname}
                        </span>
                    </Typography>
                    <Typography style={typographyStyle}>
                        {fhUserTitle}:&nbsp;
                        <span style={{ fontWeight: 200 }}>
                            {activeCase.assignee.fname} {activeCase.assignee.lname}
                        </span>
                    </Typography>
                </div>

                {payment.description && <Typography
                    color="primary"
                    style={{
                        ...typographyStyle,
                        fontWeight: 200,
                        textAlign: 'center',
                        width: 250,
                        margin: 'auto',
                        lineHeight: '18px',
                        wordBreak: 'break-word'
                    }}
                >
                    "{payment.description}"
                </Typography>}

                {!isPrintMode &&
                    <Grid className={classes.bottomContent} item>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => this.printReceipt()}
                            className={classes.marginBottom5}
                        >
                            <LocalPrintshopIcon />&nbsp;Print this Receipt
                        </Button>
                        {showReceiptSentTo && !isNonUser && <>
                            <Typography>
                                This receipt was sent to {payment.payer_email || payment.payer_phone}
                            </Typography>
                            <Typography>
                                on {paymentDate}
                            </Typography>
                        </>}
                        {isNonUser && <>
                            <Typography className={classes.noReceiptSent}>
                                This receipt was not sent since the payer was not invited into Gather
                            </Typography>
                        </>}
                    </Grid>
                }
            </Grid>
        );
    }

    render() {
        const {
            classes,
            isDialogOpen,
            closeDialog,
            paymentId,
            transactions,
            zIndex
        } = this.props;

        const payment = transactions.transactions.find(t => t.payment_id === paymentId && t.type === 'PAYMENT');
        const content = payment && payment.status === 'succeeded' && !transactions.isLoading
            ? this.renderPaymentContent(payment)
            : this.renderSpinnerContent();

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                className={classes.cashDialog}
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.dRoot,
                }}
                hideBackdrop={this.state.isPrintMode}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogContent className={classes.dialogContent}>
                    {content}
                </DialogContent>
            </Dialog>
        );
    }

    printReceipt = () => {
        this.setState({ isPrintMode: true }, () => this.print());
    };

    print = () => {
        const printContents = document.getElementById('print-receipt');
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            return;
        }

        const materialIconStyles = `       
        <style>
        @font-face {
            font-family: 'Material Icons';
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
        }

        .material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
        </style>`;

        printContainer.innerHTML = printContents.innerHTML + materialIconStyles;
        window.print();

        this.setState({ isPrintMode: false });
    };
}

export default compose(withGStyles(styles), withFullScreen('xs'))(PaymentReceiptDialog) as React.ComponentType<Props>;
