import { DialogState } from '../types/DialogState';
import {
    OPEN_HELPER_INVITATION_DIALOG,
    CLOSE_HELPER_INVITATION_DIALOG,
    OPEN_TEAM_INVITATION_DIALOG,
    CLOSE_TEAM_INVITATION_DIALOG,
    OPEN_MY_SETTINGS_DIALOG,
    CLOSE_MY_SETTINGS_DIALOG,
    SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID,
    OPENED_APP_OUTDATED_DIALOG,
    CLOSED_APP_OUTDATED_DIALOG,
    OPEN_SELFLOGIN_DIALOG,
    CLOSE_SELFLOGIN_DIALOG,
    CLOSE_GUEST_POPPER,
    OPEN_GUEST_POPPER,
    OPEN_FINALIZE_TASK_DIALOG,
    CLOSE_FINALIZE_TASK_DIALOG
} from '../actions/Dialog.action';
import * as constants from '../constants';
import { GatherAction } from '../actions';
import { EntityCaseRole, TaskState } from '../shared/types';
import { HelperUserSettingsTab } from '../components/dialogs/mySettings/HelperUserSettings';

export const dialogInitData: DialogState = {
    helperInvitation: {
        isOpen: false,
        defaultTab: EntityCaseRole.admin,
        zIndex: 1320,
        sendInvite: true,
        selectedEntityId: null,
        relationshipType: null,
        relationship: null,
        relationToDeceased: null,
        onAddedCallback: null,
        context: null,
        aliveType: null,
        homeAddressOptional: false,
    },
    teamInvitation: {
        isOpen: false,
        zIndex: 1320,
        onAddedCallback: null
    },
    mySettings: {
        isOpen: false,
        entityId: null,
        zIndex: 1330,
        tab: null,
        helperTab: HelperUserSettingsTab.details,
        callback: null
    },
    appOutdated: {
        isOpen: false,
    },
    selfLogin: {
        isOpen: false,
        zIndex: 1340,
        srcAction: null,
        photosToUploadCount: null,
        onLoginSuccessCallback: null,
        onAbortCallback: null,
        onCloseCallback: null,
    },
    guestPopper: {
        activeGuest: null,
        zIndex: 1320,
        getTarget: null,
        parentId: null
    },
    finalizeTask: {
        zIndex: 1320,
        task: null,
        isOpen: false,
        state: TaskState.complete,
        onFinalized: null,
    },
};

export const dialogState = (
    state: DialogState = dialogInitData,
    action: GatherAction,
): DialogState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT: {
            return dialogInitData;
        }
        case OPEN_HELPER_INVITATION_DIALOG: {
            return {
                ...state,
                helperInvitation: {
                    ...state.helperInvitation,
                    ...action,
                    isOpen: true,
                },
            };
        }
        case SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID: {
            return {
                ...state,
                helperInvitation: {
                    ...state.helperInvitation,
                    selectedEntityId: action.selectedEntityId || null,
                }
            };
        }
        case CLOSE_HELPER_INVITATION_DIALOG: {
            return {
                ...state,
                helperInvitation: {
                    ...state.helperInvitation,
                    isOpen: false,
                    selectedEntityId: null,
                }
            };
        }
        case OPEN_TEAM_INVITATION_DIALOG: {
            return {
                ...state,
                teamInvitation: {
                    ...state.teamInvitation,
                    isOpen: true,
                    zIndex: action.zIndex,
                    onAddedCallback: action.onAddedCallback || state.teamInvitation.onAddedCallback
                },
            };
        }
        case CLOSE_TEAM_INVITATION_DIALOG: {
            return {
                ...state,
                teamInvitation: {
                    ...state.teamInvitation,
                    isOpen: false,
                    onAddedCallback: null
                }
            };
        }
        case OPEN_MY_SETTINGS_DIALOG: {
            return {
                ...state,
                mySettings: {
                    ...state.mySettings,
                    isOpen: true,
                    zIndex: action.zIndex,
                    entityId: action.entityId,
                    tab: action.tab,
                    callback: action.callback
                },
            };
        }
        case CLOSE_MY_SETTINGS_DIALOG: {
            return {
                ...state,
                mySettings: {
                    ...state.mySettings,
                    isOpen: false,
                    entityId: null,
                    tab: null,
                    callback: null
                },
            };
        }
        case OPENED_APP_OUTDATED_DIALOG: {
            return {
                ...state,
                appOutdated: {
                    ...state.appOutdated,
                    isOpen: true,
                },
            };
        }
        case CLOSED_APP_OUTDATED_DIALOG: {
            return {
                ...state,
                appOutdated: {
                    ...state.appOutdated,
                    isOpen: false,
                },
            };
        }
        case OPEN_SELFLOGIN_DIALOG: {
            return {
                ...state,
                selfLogin: {
                    ...state.selfLogin,
                    isOpen: true,
                    srcAction: action.srcAction,
                    zIndex: action.zIndex,
                    photosToUploadCount: action.photosToUploadCount,
                    customerDetails: action.customerDetails || undefined,
                    onLoginSuccessCallback: action.onLoginSuccessCallback,
                    onAbortCallback: action.onAbortCallback,
                    onCloseCallback: action.onCloseCallback,
                },
            };
        }
        case CLOSE_SELFLOGIN_DIALOG: {
            return {
                ...state,
                selfLogin: {
                    ...state.selfLogin,
                    isOpen: false,
                    srcAction: null,
                    photosToUploadCount: null,
                    customerDetails: undefined,
                    onLoginSuccessCallback: null,
                    onAbortCallback: null,
                    onCloseCallback: null,
                },
            };
        }
        case OPEN_GUEST_POPPER: {
            return {
                ...state,
                guestPopper: {
                    ...state.guestPopper,
                    zIndex: action.zIndex,
                    activeGuest: action.activeGuest,
                    getTarget: action.getTarget,
                    parentId: action.parentId
                }
            };
        }
        case CLOSE_GUEST_POPPER: {
            return {
                ...state,
                guestPopper: {
                    ...state.guestPopper,
                    activeGuest: null,
                    getTarget: null,
                    parentId: null
                }
            };
        }

        case OPEN_FINALIZE_TASK_DIALOG: {
            return {
                ...state,
                finalizeTask: {
                    ...state.finalizeTask,
                    zIndex: action.zIndex,
                    task: action.task,
                    isOpen: true,
                    state: action.state,
                    onFinalized: action.onFinalized || null,
                },
            };
        }
        case CLOSE_FINALIZE_TASK_DIALOG: {
            return {
                ...state,
                finalizeTask: dialogInitData.finalizeTask,
            };
        }

        default: {
            return state;
        }
    }
};
