import {
    S3FileCreateRequest,
    UploadFileRequest,
} from '../shared/types';
import { registerAppError } from './errors';
import { AppDispatch } from '../store';
import { uploadS3FileToS3 } from './S3File.action';

export function uploadSignature(
    uploadRequest: UploadFileRequest,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch): Promise<S3FileCreateRequest | null> => {
        if (!uploadRequest.suffix) {
            dispatch(registerAppError('Invalid file type'));
            return null;
        }
        const preSignURLRoute = `api/case/${caseUuid}/signature/uploadurl/` +
            `${uploadRequest.hash}/${uploadRequest.suffix}`;
        const s3File = await dispatch(uploadS3FileToS3({
            uploadRequest,
            preSignURLRoute,
        }));
        if (!s3File) {
            dispatch(registerAppError(`Problem uploading signature`));
            return null;
        }

        return s3File;
    };
}
