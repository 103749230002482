import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { memo, useState } from "react";
import FileUploader, { AcceptFileType } from "../../../../common/FileUploader";
import PhotoHanger from "../PhotoHanger";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '&$onePhoto': {
            '@media (max-width: 959px)': {
                transform: 'translateY(55%)',
            }
        },
        '&$largeSize': {
            '&$onePhoto': {
                padding: 0,
                transform: 'translateY(55%)',
                '@media (min-width: 960px)': {
                    transform: 'translateY(10%)',
                },
                '& $largeButtonString': {
                    height: 122,
                    '@media (min-width: 960px)': {
                        height: 180
                    }
                },
                '& $largeShareButton': {
                    top: 140,
                    '@media (min-width: 960px)': {
                        top: 198
                    }
                }
            },
            marginLeft: 62,
            paddingTop: 40,
            '@media (min-width: 960px)': {
                padding: 0
            }
        },
        '& $photoString': {
            height: 48,
            '@media (min-width: 768px)': {
                height: 64
            },
            '@media (min-width: 960px)': {
                height: 102
            },
            '&$twoPhotos': {
                '@media (min-width: 768px)': {
                    height: 102
                }
            }
        },
        '& $largeButtonString': {
            height: 68,
            '@media (min-width: 960px)': {
                height: 200
            }
        }
    },
    flexColumnCentered: {
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center'
    },
    sharePhotoButton: {
        width: 115,
        height: 115,
        padding: '6px 8px',
        boxShadow: '0 2px 8px 2px rgb(0 0 0 / 35%), -60px 44px 64px rgb(0 0 0 / 15%), 0 4px 44px rgb(0 0 0 / 25%)',
        textTransform: 'none',
        '& p, svg': {
            color: theme.palette.common.white,
        },
        '& p': {
            lineHeight: 1,
            fontWeight: 300,
            maxWidth: 120
        },
        '& svg': {
            fontSize: 40,
            marginBottom: 8
        },
        '&$largeShareButton': {
            width: 160,
            height: 160,
            position: 'absolute',
            top: 126,
            '@media (min-width: 960px)': {
                top: 218
            },
            '& svg': {
                fontSize: 52
            },
            '& p': {
                fontSize: 16
            }
        }
    },
    disabledButton: {
        pointerEvents: 'none',
        boxShadow: 'none',
        backgroundColor: `${theme.palette.primary.main} !important`
    },
    circularProgress: {
        marginBottom: 8,
        '&$largeShareButton': {
            width: '52px !important',
            height: '52px !important'
        }
    },
    largeShareButton: {},
    onePhoto: {},
    twoPhotos: {},
    photoString: {},
    largeButtonString: {},
    largeSize: {},
    onrPhoto: {}
}), { name: 'HangingPhotosUploadButton' });

export type HangingPhotosUploadCallback = (files: FileList | null, setUploading: (uploading: boolean) => void) => void;
interface Props {
    caseFName: string;
    casePhotosCount: number;
    largeSize?: boolean;
    onPhotosUpload: HangingPhotosUploadCallback;
}

const HangingPhotosUploadButton = (props: Props) => {
    const { caseFName, largeSize, casePhotosCount, onPhotosUpload } = props;

    const classes = useStyles();

    const [disabled, setDisabled] = useState(false);
    const buttonText = disabled ?
        largeSize ?
            'Uploading Your Favorite Photos'
            : 'Uploading'
        : `Upload Photos of ${caseFName}`;

    const handleFileUpload = (files: FileList | null) => {
        onPhotosUpload(files, setDisabled);
    };

    return (
        <div
            className={classNames(
                classes.flexColumnCentered,
                classes.root,
                largeSize && classes.largeSize,
                casePhotosCount && classes.onePhoto
            )}
        >
            <PhotoHanger
                stringHeight={largeSize ? 172 : 130}
                stringClass={!largeSize
                    && classNames(classes.photoString, casePhotosCount > 1 && classes.twoPhotos)
                    || classes.largeButtonString
                }
            />
            <FileUploader
                multiple
                acceptTypes={[AcceptFileType.PNG, AcceptFileType.JPEG, AcceptFileType.GIF]}
                handleSave={handleFileUpload}
            >
                {(inputRef) =>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classNames(
                            classes.sharePhotoButton,
                            largeSize && classes.largeShareButton,
                            disabled && classes.disabledButton
                        )}
                        classes={{ root: classes.flexColumnCentered }}
                        onClick={event => inputRef.click()}
                        disabled={disabled}
                    >
                        {disabled &&
                            <CircularProgress
                                className={classNames(
                                    classes.circularProgress,
                                    largeSize && classes.largeShareButton
                                )}
                            /> ||
                            <AddCircleOutline />
                        }

                        <Typography>{buttonText}</Typography>
                    </Button>
                }
            </FileUploader>
        </div>
    );
};

export default memo(HangingPhotosUploadButton);