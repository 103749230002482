import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';

import { CaseConfirmationConfig, GatherCaseSummary } from '../../../../../../../shared/types';
import IdPhotos from './IdPhotos';
import GSwitch from '../../../../../../common/inputElements/GSwitch';
import { useCompleteStepSwitchStyles } from '../../dialogContent.styles';
import ConfirmationDialog from '../../../../../../common/ConfirmationDialog';
import { deleteCaseIdPhoto } from '../../../../../../../actions/CaseIdPhotos.action';
import { useGDispatch, useGSelector } from '../../../../../../../types';
import { DUMMY_CASE, OnValidityChangedFunction } from '../types';
import StepComponentWrapper from '../../StepComponentWrapper';
import CaseSummary from './CaseSummary';
import { useGStyles } from '../../../../../../common/hooks/useGStyles';

interface Props {
    isStepCompleted?: boolean;
    componentId?: number;
    selectedCase: GatherCaseSummary | null; // null case is for Back Office preview
    config?: CaseConfirmationConfig;
    heading: string;
    saveAttempted: boolean;
    zIndex: number;
    onValidityChanged: OnValidityChangedFunction;
}

const CaseConfirmationComponent = (props: Props) => {
    const {
        componentId,
        heading,
        config,
        saveAttempted,
        selectedCase,
        zIndex,
        isStepCompleted,
        onValidityChanged,
    } = props;

    const classes = useGStyles();
    const switchClasses = useCompleteStepSwitchStyles();

    const isConfirmRequired = config?.require_case_confirmation;
    const isPhotoRequired = config?.require_death_photo_uploaded;

    const dispatch = useGDispatch();
    const idPhotos = useGSelector(({ casesState }) => casesState.caseIdPhotos);

    const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
    // if step completed assume required things were completed
    const [hasBeenSeen, setHasBeenSeen] = useState(Boolean(isPhotoRequired && isStepCompleted));
    const [hasConfirmedCase, setHasConfirmedCase] = useState(Boolean(isConfirmRequired && isStepCompleted));
    const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

    const [activeIdPhotoS3FileId, setActiveIdPhotoS3FileId] = useState<number | null>(null);

    const isValid = useMemo(() => {
        if (isConfirmRequired) {
            if (isPhotoRequired) {
                return Boolean(idPhotos.length > 0 && hasConfirmedCase);
            } else {
                return hasConfirmedCase;
            }
        } else {
            return true;
        }
    }, [isConfirmRequired, isPhotoRequired, hasConfirmedCase, idPhotos]);

    useEffect(() => {
        if (componentId) {
            onValidityChanged(componentId, isValid);
        }
    }, [onValidityChanged, componentId, isValid]);

    const isPhotoUploadDisabled = !config?.show_upload_option;

    const handleDeleteIdPhoto = () => {

        if (activeIdPhotoS3FileId !== null && selectedCase) {
            dispatch(deleteCaseIdPhoto(selectedCase.uuid, activeIdPhotoS3FileId));
        }

        closeDeleteConfirmationDialog();
    };

    const openDeleteConfirmationDialog = (s3FileId: number) => {
        setDeleteConfirmationDialogOpen(true);
        setActiveIdPhotoS3FileId(s3FileId);
    };

    const closeDeleteConfirmationDialog = () => {
        setDeleteConfirmationDialogOpen(false);
        setActiveIdPhotoS3FileId(null);
    };

    const showConfirmSwitch = isPhotoRequired
        ? isPhotoUploaded && hasBeenSeen
        : isConfirmRequired;

    const isRequiredAndValid = isValid && isConfirmRequired;

    return (
        <>
            <StepComponentWrapper
                heading={`${heading}${isConfirmRequired ? ' *' : ''}`}
                error={!isValid && saveAttempted}
                success={isRequiredAndValid}
                intercomTargetProp={`CompleteKeepTrackStep-CaseSummary-Component`}
            >
                <CaseSummary
                    zIndex={zIndex}
                    gatherCase={selectedCase || DUMMY_CASE}
                    disableEdit={isStepCompleted || !selectedCase}
                    intercomTargetPropCaseNumber={`CompleteKeepTrackStep-CaseSummary-CaseNumber`}
                    intercomTargetPropTrackerNumber={`CompleteKeepTrackStep-CaseSummary-TrackerNumber`}
                    intercomTargetPropDecedentAvatar={`CompleteKeepTrackStep-CaseSummary-DecedentAvatar`}
                    intercomTargetPropCaseType={`CompleteKeepTrackStep-CaseSummary-CaseType`}
                    intercomTargetPropDecedentName={`CompleteKeepTrackStep-CaseSummary-DecedentName`}
                    intercomTargetPropWorkFlow={`CompleteKeepTrackStep-CaseSummary-WorkFlow`}
                    intercomTargetPropCaseCreatedByOn={`CompleteKeepTrackStep-CaseSummary-CreatedByOn`}
                    intercomTargetPropDecedentDOD={`CompleteKeepTrackStep-CaseSummary-DecedentDOD`}
                    intercomTargetPropCaseFuneralHome={`CompleteKeepTrackStep-CaseSummary-FuneralHome`}
                    intercomTargetPropCaseLabels={`CompleteKeepTrackStep-CaseSummary-CaseLabels`}
                />

                <IdPhotos
                    disableAddEdit={isStepCompleted}
                    selectedCase={selectedCase}
                    disablePhotoUpload={isPhotoUploadDisabled}
                    onPhotoClick={() => setHasBeenSeen(true)}
                    onPhotoDelete={openDeleteConfirmationDialog}
                    onIsPhotoUploadedChange={setIsPhotoUploaded}
                    intercomTargetProp={`CompleteKeepTrackStep-CaseSummary-IDPhoto`}
                    intercomTargetPropAddPhoto={`CompleteKeepTrackStep-CaseSummary-AddNewIDPhoto`}
                />

                {isConfirmRequired &&
                    <div className={classNames(
                        isRequiredAndValid ? switchClasses.backgroundGreenColor : classes.backgroundPrimary,
                        !isValid && saveAttempted && classes.backgroundRed,
                        switchClasses.switchRadius,
                    )}
                    >
                        {showConfirmSwitch &&
                            <GSwitch
                                disabled={isStepCompleted}
                                required={true}
                                label={'I confirm I have the correct body *'}
                                checked={hasConfirmedCase}
                                onChangeCallBack={setHasConfirmedCase}
                                formControlLabelClasses={switchClasses.formControlLabel}
                                labelClasses={classNames(
                                    switchClasses.marginLeft_4,
                                    switchClasses.whiteColor
                                )}
                                switchBaseClasses={switchClasses.whiteColor}
                                switchCheckedClass={switchClasses.whiteColor}
                                barClass={switchClasses.secondaryBackground}
                            />
                        }

                        {isPhotoRequired &&
                            <>
                                {!isPhotoUploaded &&
                                    <Typography align="center" className={classes.colorWhite}>
                                        {`Add a secure ID photo of ${(selectedCase ?? DUMMY_CASE).fname} to continue.`}
                                    </Typography>
                                }
                                {isPhotoUploaded && !hasBeenSeen &&
                                    <Typography align="center" className={classes.colorWhite}>
                                        Click photo above to confirm you have the correct body.
                                    </Typography>
                                }
                            </>
                        }
                    </div>
                }
            </StepComponentWrapper>

            <ConfirmationDialog
                header={`Are you sure?`}
                subHeader={`Are you sure you want to delete this image? It will be gone forever.`}
                confirmationButtonText={`Delete Forever`}
                onClose={closeDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={handleDeleteIdPhoto}
                cancelButtonText={`Don't Delete`}
                zIndex={zIndex + 1}
            />
        </>
    );
};

export default CaseConfirmationComponent;
