export const TOGGLE_CONFETTI = 'TOGGLE_CONFETTI';
interface ToggleConfetti {
    type: typeof TOGGLE_CONFETTI;
    isRunning: boolean;
    timeout?: number;
}

export function toggleConfetti(isRunning: boolean, timeout?: number): ToggleConfetti {
    return {
        type: TOGGLE_CONFETTI,
        isRunning,
        timeout
    };
}

export type ConfettiAction = ToggleConfetti;