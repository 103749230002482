import classNames from 'classnames';
import { has } from 'lodash';

import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { ORANGE_COLOR } from '../../../../constants/colorVariables';

import { APP_STYLED_PROPS } from '../../../../styles/App.styles';
import { GPLContext, ProductPackageUX } from '../../../../shared/types';
import PackageCard from '../widgets/PackageCard';
import HorizontalReactVirtualizedList from '../../../common/reactVirtualized/HorizontalReactVirtualizedList';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        marginBottom: '24px !important',
        '& li': {
            marginTop: '0px !important',
        }
    },
    dashedButton: {
        border: 'dashed',
        borderWidth: 'thin',
        margin: 18,
        height: 309,
        width: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: `${ORANGE_COLOR}`,
        borderRadius: 8,
        '&:hover': {
            border: '1px solid',
        }
    },
    dashedButtonText: {
        fontSize: 20,
        textTransform: 'none',
        fontWeight: 400,
        display: 'block',
    },
    fontSize36: {
        fontSize: 36,
    }
});

type StyledProps = Props & APP_STYLED_PROPS & WithStyles<'root' | 'dashedButton' | 'dashedButtonText' | 'fontSize36'>;

interface Props {
    canUserEditContract: boolean;
    packages: ProductPackageUX[];
    contractPackageIds?: { [id: number]: number };
    isShowPrices: boolean;
    context: GPLContext;
    canCreatePackage?: boolean;
    categoryName?: string;
    openPackageDialog?: (packageId: number) => void;
    onAddPackage?: (packageId: number) => void;
    onRemovePackage?: (packageId: number) => void;
    onEditClick?: (packageId?: number) => void;
}

const Packages = (props: StyledProps) => {
    const {
        classes,
        packages,
        contractPackageIds,
        openPackageDialog,
        onAddPackage,
        onRemovePackage,
        isShowPrices,
        canUserEditContract,
        context,
        onEditClick,
        canCreatePackage,
        categoryName,
    } = props;

    return (
        <Grid container justifyContent="center" alignItems="center">
            <ul className={classNames(classes.list, classes.root)}>

                <HorizontalReactVirtualizedList
                    itemWidth={300 + (18 * 2)} // width of 1 item // 300(width) + 18px margin
                    data={packages}
                    defaultCellHeight={254}
                    render={(pkg) =>
                        <PackageCard
                            canUserEditContract={canUserEditContract}
                            key={pkg.id}
                            productPackage={pkg}
                            isShowPrices={isShowPrices}
                            isSelected={has(contractPackageIds, pkg.id)}
                            openPackageDialog={() => openPackageDialog && openPackageDialog(pkg.id)}
                            onAddPackage={() => onAddPackage && onAddPackage(pkg.id)}
                            onRemovePackage={() => onRemovePackage && onRemovePackage(pkg.id)}
                            context={context}
                            onEditClick={() => {
                                if (onEditClick) {
                                    onEditClick(pkg.id);
                                }
                            }}
                        />
                    }
                    firstChild={canCreatePackage &&
                        <Zoom in timeout={1200}>
                            <ImageListItem>
                                <Button
                                    variant="outlined"
                                    className={classes.dashedButton}
                                    onClick={() => onEditClick && onEditClick()}
                                    size="large"
                                >
                                    <PlaylistAddIcon className={classes.fontSize36} />
                                    <span className={classes.dashedButtonText}>
                                        Create {categoryName} Package
                                    </span>
                                </Button>
                            </ImageListItem>
                        </Zoom> || undefined}
                />
            </ul>
        </Grid>
    );
};

export default withGStyles(styles)(Packages);
