import classNames from "classnames";
import YouTube from "react-youtube";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import CallIcon from "@mui/icons-material/Call";

import FuneralReimbursementLogo from "./FuneralReimbursementLogo";
import { useTaskDialogStyles } from "../TaskDialogsStyles";
import { GatherCaseUX } from "../../../../shared/types";
import { NULL_STRING } from "../../../../constants";
import { getFullNameFromCase, formatDateString, emptyStringParser } from "../../../../services";
import { YouTubeOptions } from "../../../funeralHomes/funeralHomeSettings/widgets";

interface Props {
    activeCase: GatherCaseUX;
}

const youtubeOptions: YouTubeOptions = {
    height: '100%',
    width: '100%',
    playerVars: {
        autoplay: 0,
        showinfo: 0,
        rel: 0,
    }
};

const FRQualified = (props: Props) => {
    const { activeCase } = props;

    const classes = useTaskDialogStyles();

    const caseName = getFullNameFromCase(activeCase);
    const birthDate = activeCase.dob_date
        && activeCase.dob_date !== '' ? formatDateString(activeCase.dob_date) : NULL_STRING;
    const deathDate = activeCase.dod_start_date
        && activeCase.dod_start_date !== '' ? formatDateString(activeCase.dod_start_date) : NULL_STRING;
    const deathLocation: string = activeCase.death_certificate
        && emptyStringParser(activeCase.death_certificate.life.deathPlace.description)
        ? activeCase.death_certificate.life.deathPlace.description : NULL_STRING;

    const renderCallButton = () => {
        return (
            <Grid>
                <a
                    href={'tel:844-684-6333'}
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,
                            classes.marginTop30
                        )}
                    >
                        <CallIcon />&nbsp;
                        CALL FEMA TO BEGIN APPLICATION
                    </Button>
                </a>
            </Grid>
        );
    };

    return (
        <Grid
            item
            xs={12}
        >
            <Grid
                item
                xs={12}
                sm={10}
                className={classNames(
                    classes.marginAuto,
                    classes.textCenter,
                    classes.marginBottom20,
                    classes.secondStateMainGrid
                )}
            >
                <FuneralReimbursementLogo />

                <Grid>
                    <Typography
                        align="center"
                        color="secondary"
                        className={classNames(
                            classes.fontSize28,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        It looks like you qualify
                    </Typography>
                    <Typography
                        color="secondary"
                        align="left"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Based on your previous answers, it looks like you qualify for reimbursement of up to
                        $9,000 for {activeCase.fname}'s final expenses. Here is what to do next:
                    </Typography>
                    <Typography
                        color="secondary"
                        align="left"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        You will need to call FEMA directly to begin your application. This process should
                        only take about 20 minutes and their hours of operation are Monday to Friday
                        (9 am until 9 pm EST). They can be reached at 844-684-6333 or simply click below to
                        call them if you are reading this on a mobile device. We have also listed below the
                        information and the documents you will need to have ready prior to calling.
                    </Typography>
                </Grid>

                {renderCallButton()}

                <Grid item className={classes.rootVideoSection}>
                    <Typography color="secondary" align="center">
                        The following video provides additional details that may be helpful as you prepare
                        your application for reimbursement.
                    </Typography>
                    <div className={classes.videoSection}>
                        <YouTube
                            className={classes.youtubeIframeContainer}
                            videoId={'DgvN_9m58Z0'}
                            opts={youtubeOptions}
                        />
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={9}
                    className={classNames(
                        classes.colorPrimary,
                        classes.b_solid_1,
                        classes.detailBox,
                        classes.marginTop20,
                        classes.marginAuto
                    )}
                >
                    <Typography
                        className={
                            classNames(
                                classes.detailBoxHeader,
                                classes.fontSize18,
                                classes.backgroundPrimary,
                                classes.padding10
                            )
                        }
                    >
                        What information do I need?
                    </Typography>
                    <Grid
                        item
                        className={classNames(
                            classes.padding10,
                            classes.textLeft
                        )}
                    >
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                            )}
                        >
                            Name:&nbsp;
                            <span className={classes.fontWeight500}>
                                {caseName}
                            </span>
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            {caseName}'s Date of Birth:
                            <span className={classes.fontWeight500}>
                                {birthDate}
                            </span>
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            {caseName}'s Date of Death:&nbsp;
                            <span className={classes.fontWeight500}>
                                {deathDate}
                            </span>
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            {caseName}'s Location of Passing:&nbsp;
                            <span className={classes.fontWeight500}>
                                {deathLocation}
                            </span>
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            {caseName}'s Social Security number:&nbsp;
                            This can be found on the death certificate
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            The applicant's social security number, date of birth, email, mailing address,
                            contact phone numbers, and annual gross income.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Information about burial or funeral insurance policies.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Information about other funeral assistance received
                            (such as donations or voluntary organizations), if applicable
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            The applicant's routing, checking, or savings account number
                            (for direct deposit, if requested)
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={9}
                    className={classNames(
                        classes.colorPrimary,
                        classes.b_solid_1,
                        classes.detailBox,
                        classes.marginTop20,
                        classes.marginAuto
                    )}
                >
                    <Typography
                        className={classNames(
                            classes.detailBoxHeader,
                            classes.fontSize18,
                            classes.backgroundPrimary,
                            classes.padding10
                        )}
                    >
                        What documentation do I need?
                    </Typography>
                    <Grid
                        item
                        className={classNames(
                            classes.padding10,
                            classes.textLeft
                        )}
                    >
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                            )}
                        >
                            Copy of {caseName}'s death certificate.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Proof of funeral expenses incurred.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Proof of assistance received from any other source.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            <span className={classes.fontWeight500}>
                                Please remember:
                            </span>
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            The death certificate must indicate the death was caused by, “may have been caused by”
                            or “was likely a result of” COVID-19 or COVID-19-like symptoms. Similar phrases that
                            indicate a high likelihood of COVID-19 are considered sufficient attribution.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            The death must have occurred in the United States, including the U.S. territories, or
                            the District of Columbia. COVID-19 Funeral Assistance is not available for the funeral
                            expenses of U.S. citizens who died outside the United States.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Documentation for expenses (receipts, funeral home contract, etc.) must include
                            the applicant’s name as the person responsible for the expense, the deceased
                            individual’s name, the amount of funeral expenses, and that funeral expenses
                            were incurred after January 20, 2020.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            The applicant must also provide FEMA with proof of funds received from other sources
                            specifically used for funeral costs. COVID-19 Funeral Assistance may not duplicate
                            benefits received from burial or funeral insurance or financial assistance received
                            from voluntary agencies, government programs or agencies, or other sources. COVID-19
                            Funeral Assistance will be reduced by the amount of other assistance the applicant
                            received for the same expenses.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Life insurance proceeds are not considered a duplication of Funeral Assistance benefits
                            and are eligible for reimbursement under this program.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={9}
                    className={classNames(
                        classes.colorPrimary,
                        classes.b_solid_1,
                        classes.detailBox,
                        classes.marginTop20,
                        classes.marginAuto
                    )}
                >
                    <Typography
                        className={
                            classNames(
                                classes.detailBoxHeader,
                                classes.fontSize18,
                                classes.backgroundPrimary,
                                classes.padding10
                            )
                        }
                    >
                        What expenses are reimbursable?
                    </Typography>
                    <Grid
                        item
                        className={
                            classNames(
                                classes.padding10,
                                classes.textLeft
                            )
                        }
                    >
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                            )}
                        >
                            FEMA will reimburse applicants for funeral expenses related to funeral services and
                            interment or cremation. Some of these expenses include, but are not limited to:
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Transfer of remains.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Casket or URN.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Burial plot or cremation niche.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Marker or headstone.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Use of funeral home and equipment.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Cremation or interment costs.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.marginTop10
                            )}
                        >
                            Costs associated with producing and certifying the death certificates.
                        </Typography>
                    </Grid>
                </Grid>

                {renderCallButton()}
            </Grid>
        </Grid>
    );
};

export default FRQualified;