import {
    formatDateString,
    deathOrAge,
    formatDateTimeStrings,
    getAddressFromLongAddress,
    emptyStringParser,
    getFormattedPhoneNumber,
    DateTimeFormat
} from "../../../../../services";
import { ConfigurableLifeFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { GatherCaseUX } from "../../../../../shared/types";
import { OverviewSectionCommonProps, parseShortAddress } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
    activeCase: GatherCaseUX;
}
const LifeOverview = (props: Props) => {
    const { deathCertificate, activeCase, printMode, divider, showHeading, alwaysVisible } = props;

    const dob = formatDateString(activeCase.dob_date || '', DateTimeFormat.LongDate);
    const age = deathOrAge(activeCase, undefined, undefined, true);
    const cob: string | null = !deathCertificate.life.birthPlace.unknown
        && parseShortAddress(deathCertificate.life.birthPlace) || null;

    const dopStart = formatDateTimeStrings(
        activeCase.dod_start_date || '',
        activeCase.dod_start_time,
        DateTimeFormat.LongDate
    );
    const dopEnd = formatDateTimeStrings(
        activeCase.dod_end_date || '',
        activeCase.dod_end_time,
        DateTimeFormat.LongDate
    );
    const pronouncementDate = formatDateTimeStrings(
        activeCase.dop_date || '',
        activeCase.dop_time,
        DateTimeFormat.LongDate
    );

    const lop: string | null = deathCertificate && deathCertificate.life.deathLocation_listOfOptions || null;
    const aop: string | null = getAddressFromLongAddress(deathCertificate.life.deathPlace);
    const cop: string | null = deathCertificate && deathCertificate.life.deathPlace.county || null;

    const residence: string | null = deathCertificate.life.residencePlace.unknown ? 'unknown' :
        getAddressFromLongAddress(deathCertificate.life.residencePlace);
    const cor: string | null = deathCertificate.life.residencePlace
        && deathCertificate.life.residencePlace.county || null;

    const cityLimits: string | null = deathCertificate.life.residencePlace
        && deathCertificate.life.residencePlace.insideCityLimits
        && emptyStringParser(deathCertificate.life.residencePlace.insideCityLimits) || null;
    const yearsInCounty: string | null = deathCertificate.life.residencePlace
        && deathCertificate.life.residencePlace.yearsInCounty
        && emptyStringParser(deathCertificate.life.residencePlace.yearsInCounty.toString()) || null;

    const physicianName: string | null = deathCertificate.life.physicianName;
    const physicianPhoneNumber: string | null = deathCertificate.life.physicianPhoneNumber;
    const physicianFaxNumber: string | null = deathCertificate.life.physicianFaxNumber;
    const physicianAddress: string | null = getAddressFromLongAddress(deathCertificate.life.physicianAddress || null);
    const causeOfDeath = emptyStringParser(deathCertificate.life.causeOfDeath);

    const hebrewDateOfBirth = emptyStringParser(deathCertificate.life.hebrewDateOfBirth) || null;
    const hebrewDateOfDeath = emptyStringParser(deathCertificate.life.hebrewDateOfDeath) || null;

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    LIFE
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif'
                }}
            >
                <OverviewLabel
                    label="Date of Birth"
                    html={dob}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Age"
                    html={age}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="City of Birth"
                    html={cob}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label={`date of passing${activeCase.dod_end_date !== '' ? ' (from)' : ''}`}
                    html={dopStart}
                    skipTextTransformation
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                {activeCase.dod_end_date !== ''
                    && <OverviewLabel
                        label="date of passing (to)"
                        html={dopEnd}
                        skipTextTransformation
                        alwaysVisible={alwaysVisible}
                        fieldKey={undefined}
                    />}

                <OverviewLabel
                    label="date of pronouncement"
                    html={pronouncementDate}
                    skipTextTransformation
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.dateOfPronouncement}
                />

                <OverviewLabel
                    label="cause of death"
                    html={causeOfDeath}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.causeOfDeath}
                />

                <OverviewLabel
                    label="location of passing"
                    html={lop}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Address of passing"
                    html={aop}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="County of passing"
                    html={cop}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Physician's Name"
                    html={physicianName}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.physicianName}
                />

                <OverviewLabel
                    label="Physician's Phone Number"
                    html={getFormattedPhoneNumber(physicianPhoneNumber)}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.physicianPhoneNumber}
                />

                <OverviewLabel
                    label="Physician's Fax Number"
                    html={getFormattedPhoneNumber(physicianFaxNumber)}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.physicianFaxNumber}
                />

                <OverviewLabel
                    label="Physician's Physical Address"
                    html={physicianAddress}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.physicianAddress}
                />

                <OverviewLabel
                    label="residence"
                    html={residence}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.primaryResidence}
                />

                <OverviewLabel
                    label="County of Residence"
                    html={cor}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="inside city limits"
                    html={cityLimits}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.primaryResidenceInCityLimits}
                />

                <OverviewLabel
                    label="years in county"
                    html={yearsInCounty}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.yearsLivedInCounty}
                />

                <OverviewLabel
                    label="Hebrew Date of Birth"
                    html={hebrewDateOfBirth}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.hebrewDateOfBirth}
                />

                <OverviewLabel
                    label="Hebrew Date of Death"
                    html={hebrewDateOfDeath}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableLifeFieldKey.hebrewDateOfDeath}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default LifeOverview;