import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { LocationUX } from "../../../../shared/types";
import { SxProps, Theme } from '@mui/material/styles';
import { sortByProperty } from "../../../../services";

const LocationListStyledProps: Record<'root' | 'locationText' | 'minMaxWidth250'
    | 'stack', SxProps<Theme>> = {
    root: {},
    locationText: {
        fontWeight: 600,
        fontSize: 14,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    minMaxWidth250: {
        minWidth: 250,
        maxWidth: 250,
    },
    stack: {
        my: 4,
        justifyContent: "center",
        alignItems: "center",
        color: "#757575",
        display: 'inline-flex',
        [`.${autocompleteClasses.endAdornment}`]: {
            top: 'unset',
        },
    },
};

export type LocationListChangeParam = LocationUX | null | 'addLocation';

interface LocationListProps {
    locations: LocationUX[];
    selectedLocation: LocationUX | undefined;
    onChange: (event: React.ChangeEvent<{}>, location: LocationListChangeParam) => void;
    showEmptyLocation: boolean;
    zIndex: number;
}

const LocationList = ({ locations, selectedLocation, onChange, showEmptyLocation, zIndex }: LocationListProps) => {
    const sortedLocations = locations.sort(sortByProperty('name', true, 'asc'));

    return (
        <Stack
            sx={LocationListStyledProps.stack}
            direction="row"
            spacing={4}
        >
            <Autocomplete
                componentsProps={{
                    popper: {
                        style: { zIndex },
                        sx: LocationListStyledProps.minMaxWidth250,
                    },
                }}
                id="combo-box-demo"
                value={selectedLocation}
                disableClearable
                sx={LocationListStyledProps.minMaxWidth250}
                getOptionLabel={(option) => option.name || option.address.description || ''}
                options={sortedLocations}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} label="Select location" />}
                renderOption={(props, option) => {
                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {option.name}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.address.address1}  {option.address.city},{' '}
                                        {option.address.state} {option.address.postal_code}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
            <Box
                sx={LocationListStyledProps.locationText}
                onClick={(e) => onChange(e, 'addLocation')}
            >
                Add Location
                <br />
                <span style={{ fontSize: 12 }}>Click to select</span>
            </Box>
            {showEmptyLocation && (
                <Box
                    sx={LocationListStyledProps.locationText}
                    onClick={(e) => onChange(e, null)}
                >
                    No Location
                </Box>
            )}
        </Stack>
    );
};

export default LocationList;