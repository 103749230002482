import { UserSession } from '../types';
import generateUuid from 'uuid';
import { getFromAPI, postToAPI, patchAPI, deleteFromAPI, downloadFromAPI } from '.';
import {
    DocRecord,
    FuneralHomeUX,
    DocCreateRequest,
    DocDownloadResponse,
    DocUX,
    UploadFileRequest,
    DocUXDetail,
    DocCategory,
    DocPDFStatus,
    DocUpdateRequest,
    DocUploadSurveyResponse
} from '../shared/types';
import { registerAppError } from './errors';
import { AppDispatch } from '../store';
import { uploadFileToS3 } from './S3File.action';
import { getDataFromLocalComputer } from '../services/doc.service';
import { log } from '../logger';

export function convertUXtoUXDetail(docUX: DocUX): DocUXDetail {
    return {
        uid: generateUuid(),
        doc: docUX,
        label: docUX.label,
        status: 'uploaded'
    };
}

export const SET_FUNERALHOME_DOCS = 'SET_FUNERALHOME_DOCS';
export type SET_FUNERALHOME_DOCS = typeof SET_FUNERALHOME_DOCS;
interface SetFuneralHomeDocs {
    type: SET_FUNERALHOME_DOCS;
    funeralHomeDocs: DocUXDetail[];
}

export const SET_FUNERALHOME_DOC = 'SET_FUNERALHOME_DOC';
export type SET_FUNERALHOME_DOC = typeof SET_FUNERALHOME_DOC;
interface SetFuneralHomeDoc {
    type: SET_FUNERALHOME_DOC;
    uid: string;
    docUX: DocUX;
}

export const UPDATE_FUNERALHOME_DOC = 'UPDATE_FUNERALHOME_DOC';
export type UPDATE_FUNERALHOME_DOC = typeof UPDATE_FUNERALHOME_DOC;
interface UpdateFuneralHomeDoc {
    type: UPDATE_FUNERALHOME_DOC;
    docId: number;
    docChanges: Partial<DocRecord>;
}

export const SET_FUNERALHOME_DOCS_LOADING = 'SET_FUNERALHOME_DOCS_LOADING';
export type SET_FUNERALHOME_DOCS_LOADING = typeof SET_FUNERALHOME_DOCS_LOADING;
interface SetFuneralHomeDocsLoading {
    type: SET_FUNERALHOME_DOCS_LOADING;
    isLoading: boolean;
}

export const ADD_FUNERALHOME_DOCS = 'ADD_FUNERALHOME_DOCS';
export type ADD_FUNERALHOME_DOCS = typeof ADD_FUNERALHOME_DOCS;
interface AddFuneralHomeDocs {
    type: ADD_FUNERALHOME_DOCS;
    docs: DocUXDetail[];
}

export const REMOVE_FUNERALHOME_DOCS = 'REMOVE_FUNERALHOME_DOCS';
export type REMOVE_FUNERALHOME_DOCS = typeof REMOVE_FUNERALHOME_DOCS;
interface RemoveFuneralHomeDocs {
    type: REMOVE_FUNERALHOME_DOCS;
    docUids: string[];
}

function updateFuneralHomeDocInStore(docId: number, docChanges: Partial<DocRecord>): UpdateFuneralHomeDoc {
    return {
        type: UPDATE_FUNERALHOME_DOC,
        docId,
        docChanges,
    };
}

export function addFuneralHomeDocs(docs: DocUXDetail[]): AddFuneralHomeDocs {
    return {
        type: ADD_FUNERALHOME_DOCS,
        docs,
    };
}

export function removeFuneralHomeDocsFromStore(docUids: string[]): RemoveFuneralHomeDocs {
    return {
        type: REMOVE_FUNERALHOME_DOCS,
        docUids,
    };
}

function setFuneralHomeDocs(funeralHomeDocs: DocUXDetail[]): SetFuneralHomeDocs {
    return {
        type: SET_FUNERALHOME_DOCS,
        funeralHomeDocs,
    };
}

function setFuneralHomeDoc(docUX: DocUX, uid: string): SetFuneralHomeDoc {
    return {
        type: SET_FUNERALHOME_DOC,
        uid,
        docUX,
    };
}

function setFuneralHomeDocsLoading(isLoading: boolean): SetFuneralHomeDocsLoading {
    return {
        type: SET_FUNERALHOME_DOCS_LOADING,
        isLoading,
    };
}

export function loadFuneralHomeDocs(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(setFuneralHomeDocs([]));
        dispatch(setFuneralHomeDocsLoading(true));
        const funeralHomeDocs = await getFromAPI<DocUX[]>(`funeralhome/${funeralHomeId}/doc/`, dispatch);
        dispatch(setFuneralHomeDocsLoading(false));
        if (funeralHomeDocs) {
            dispatch(setFuneralHomeDocs(funeralHomeDocs.map(convertUXtoUXDetail)));
        }
    };
}

export const SET_FUNERALHOME_STARTUP_DOCUMENTS = 'SET_FUNERALHOME_STARTUP_DOCUMENTS';
export type SET_FUNERALHOME_STARTUP_DOCUMENTS = typeof SET_FUNERALHOME_STARTUP_DOCUMENTS;
export interface SetFuneralHomeStartupDocuments {
    type: SET_FUNERALHOME_STARTUP_DOCUMENTS;
    startupDocuments: DocUXDetail[];
}

function setFuneralHomeStartupDocuments(startupDocuments: DocUXDetail[]): SetFuneralHomeStartupDocuments {
    return {
        type: SET_FUNERALHOME_STARTUP_DOCUMENTS,
        startupDocuments,
    };
}

export function updateStartupDocsInStore(fhDocs: DocUXDetail[]) {
    return async (dispatch: AppDispatch) => {
        const startupDocs = fhDocs.filter((d) => d.doc && d.doc.startup_rank);
        dispatch(setFuneralHomeStartupDocuments(startupDocs));
        return null;
    };
}

export function updateFuneralHomeDoc(docId: number, funeralHomeId: number, docChanges: Partial<DocRecord>) {
    return async (dispatch: AppDispatch): Promise<DocUX[] | null> => {
        let changesToMake: Partial<DocRecord>;
        try {
            changesToMake = docChanges; // this is just a placeholder, we should be validating the new data
        } catch (ex) {
            console.warn('Failed to validate partial DocRecord object: ', docChanges, ex);
            return null;
        }
        type updatedDocResponse = { updatedDoc: DocRecord; docList: DocUX[] };
        dispatch(updateFuneralHomeDocInStore(docId, docChanges));
        const updatedDocResponse: updatedDocResponse
            | null = await patchAPI<updatedDocResponse>(
                `funeralhome/${funeralHomeId}/doc/${docId}/`,
                { docChanges: changesToMake },
                dispatch);
        if (updatedDocResponse) {
            const updatedDocs = updatedDocResponse.docList.map(convertUXtoUXDetail);
            dispatch(setFuneralHomeDocs(updatedDocs));
            dispatch(updateStartupDocsInStore(updatedDocs));
            return updatedDocResponse.docList;
        }
        return null;
    };
}

export function setStartupRankonFuneralHomeDoc(docId: number, funeralHomeId: number, startupRank: number | null) {
    return async (dispatch: AppDispatch): Promise<DocUXDetail[] | null> => {
        const resposne = await postToAPI<DocUXDetail[] | null>(
            `funeralhome/${funeralHomeId}/doc/${docId}/setstartuprank`,
            { startupRank },
            dispatch);

        if (!resposne) {
            dispatch(registerAppError('Failed to set startup rank'));
            return null;
        }

        dispatch(setFuneralHomeStartupDocuments(resposne));

        return resposne;
    };
}

export function uploadFuneralHomeDoc(
    userSession: UserSession,
    funeralHome: FuneralHomeUX,
    newDoc: UploadFileRequest,
    uid: string,
) {
    return async (dispatch: AppDispatch): Promise<DocUX | null> => {
        if (!userSession || !userSession.userData) {
            return null;
        }
        if (!newDoc.suffix) {
            dispatch(registerAppError('Invalid file type'));
            return null;
        }
        const s3DocUploadRequest = await uploadFileToS3(
            newDoc,
            `funeralhome/${funeralHome.id}/doc/uploadurl/${newDoc.hash}/${newDoc.suffix}`,
            dispatch,
        );
        if (!s3DocUploadRequest) {
            dispatch(registerAppError('Failed to upload document'));
            return null;
        }
        const insertRequest: DocCreateRequest = {
            uploaded_by: userSession.userData.id,
            parent_doc_id: null,
            name: s3DocUploadRequest.name,
            label: null,
            hash: s3DocUploadRequest.hash,
            size: s3DocUploadRequest.size,
            is_private: true,
            path: s3DocUploadRequest.path,
            suffix: s3DocUploadRequest.suffix || '',
            mimetype: s3DocUploadRequest.type,
            icon: null,
            doc_category: DocCategory.general,
            pdf_status: DocPDFStatus.none,
            task_component_id: null, // only used on case docs
        };
        const docUX = await postToAPI<DocUX>(
            `funeralhome/${funeralHome.id}/doc/new/`,
            {
                newDoc: insertRequest,
            },
            dispatch,
        );
        if (docUX) {
            dispatch(setFuneralHomeDoc(docUX, uid));
            return docUX;
        } else {
            dispatch(registerAppError('Unable to create new Document'));
        }
        return null;
    };

}

export function removeFuneralHomeDoc(userSession: UserSession, funeralHome: FuneralHomeUX, targetDocId: number) {
    return async (dispatch: AppDispatch) => {
        const deleteURL = `funeralhome/${funeralHome.id}/doc/${targetDocId}`;
        const funeralHomeDocs = await deleteFromAPI<DocUX[]>(deleteURL, dispatch);
        if (funeralHomeDocs) {
            const fhMappedDocs = funeralHomeDocs.map(convertUXtoUXDetail) || [];
            dispatch(setFuneralHomeDocs(fhMappedDocs));
            dispatch(updateStartupDocsInStore(fhMappedDocs));
            return funeralHomeDocs;
        }
        return null;
    };
}

export function downloadFuneralHomeDoc(userSession: UserSession, funeralHome: FuneralHomeUX, targetDocId: number) {
    return async (dispatch: AppDispatch): Promise<DocDownloadResponse | null> => {
        const downloadRequest = await getFromAPI<DocDownloadResponse>(
            `funeralhome/${funeralHome.id}/doc/${targetDocId}/downloadurl`,
            dispatch);
        if (!downloadRequest) {
            return null;
        }
        return downloadRequest;
    };
}

export function downloadStartupDocForFamilyUser(caseUuid: string, targetDocId: number) {
    return async (dispatch: AppDispatch): Promise<DocDownloadResponse | null> => {
        const downloadRequest = await getFromAPI<DocDownloadResponse>(
            `api/case/${caseUuid}/startupdoc/${targetDocId}`,
            dispatch);

        if (!downloadRequest) {
            dispatch(registerAppError('Failed to download document.'));
            return null;
        }
        return downloadRequest;
    };
}

export function downloadFuneralHomeIdDoc(funeralHomeId: number, targetDocId: number) {
    return async (dispatch: AppDispatch): Promise<DocDownloadResponse | null> => {
        const downloadRequest = await getFromAPI<DocDownloadResponse>(
            `funeralhome/${funeralHomeId}/doc/${targetDocId}/downloadurl`,
            dispatch);
        if (!downloadRequest) {
            return null;
        }
        return downloadRequest;
    };
}

/* ***********************************************************************************************
 * 
 *  Doc Case functions
 * ***********************************************************************************************
 */

export const SET_CASE_DOC = 'SET_CASE_DOC';
export type SET_CASE_DOC = typeof SET_CASE_DOC;
interface SetCaseDoc {
    type: SET_CASE_DOC;
    uid: string;
    docUX: DocUX;
}

export const ADD_CASE_DOCS = 'ADD_CASE_DOCS';
export type ADD_CASE_DOCS = typeof ADD_CASE_DOCS;
interface AddCaseDocs {
    type: ADD_CASE_DOCS;
    docs: DocUXDetail[];
}

export const UPDATE_CASE_DOC = 'UPDATE_CASE_DOC';
export type UPDATE_CASE_DOC = typeof UPDATE_CASE_DOC;
interface UpdateCaseDoc {
    type: UPDATE_CASE_DOC;
    docId: number;
    docChanges: Partial<DocRecord>;
}

export const SET_CASE_DOCS = 'SET_CASE_DOCS';
export type SET_CASE_DOCS = typeof SET_CASE_DOCS;
interface SetCaseDocs {
    type: SET_CASE_DOCS;
    caseDocs: DocUX[];
}

function setCaseDocs(caseDocs: DocUX[]): SetCaseDocs {
    return {
        type: SET_CASE_DOCS,
        caseDocs,
    };
}

export function loadCaseDocs(caseUuid: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setCaseDocs([]));
        const caseDocs = await getFromAPI<DocUX[]>(`api/case/${caseUuid}/doc/`, dispatch);
        if (caseDocs) {
            dispatch(setCaseDocs(caseDocs));
        }
    };
}

export function addCaseDocs(docs: DocUXDetail[]): AddCaseDocs {
    return {
        type: ADD_CASE_DOCS,
        docs,
    };
}

function setCaseDoc(docUX: DocUX, uid: string): SetCaseDoc {
    return {
        type: SET_CASE_DOC,
        uid,
        docUX,
    };
}

function updateCaseDocInStore(docId: number, docChanges: Partial<DocRecord>): UpdateCaseDoc {
    return {
        type: UPDATE_CASE_DOC,
        docId,
        docChanges,
    };
}

export function updateCaseDoc(docId: number, caseUuid: string, docChanges: DocUpdateRequest) {
    return async (dispatch: AppDispatch): Promise<DocUX[] | null> => {
        try {
            DocUpdateRequest.fromRequest(docChanges);
        } catch (ex) {
            log.warn('Failed to validate partial DocRecord object: ', { docChanges, ex });
            return null;
        }

        dispatch(updateCaseDocInStore(docId, docChanges));

        const updatedDocRecord: DocRecord | null = await patchAPI<DocRecord>(
            `api/case/${caseUuid}/doc/${docId}/`,
            { docChanges },
            dispatch);
        if (updatedDocRecord) {
            loadCaseDocs(caseUuid);
            const caseDocs = await getFromAPI<DocUX[]>(`api/case/${caseUuid}/doc/`, dispatch);
            if (caseDocs) {
                dispatch(setCaseDocs(caseDocs));
                return caseDocs;
            }
        }
        return null;
    };
}

function uploadCaseDoc(params: {
    userId: number;
    caseUuid: string;
    doc: UploadFileRequest;
    uid: string;
    docCategory: DocCategory;
    taskComponentId?: number;
}) {
    return async (dispatch: AppDispatch): Promise<DocUX | null> => {
        const {
            userId,
            caseUuid,
            doc,
            uid,
            docCategory,
            taskComponentId,
        } = params;
        if (!doc.suffix) {
            dispatch(registerAppError('Invalid file type'));
            return null;
        }
        const s3DocUploadRequest = await uploadFileToS3(
            doc,
            `api/case/${caseUuid}/doc/uploadurl/${doc.hash}/${doc.suffix}`,
            dispatch,
        );
        if (!s3DocUploadRequest) {
            dispatch(registerAppError('Failed to upload document'));
            return null;
        }
        const insertRequest: DocCreateRequest = {
            uploaded_by: userId,
            parent_doc_id: null,
            name: s3DocUploadRequest.name,
            label: null,
            hash: s3DocUploadRequest.hash,
            size: s3DocUploadRequest.size,
            is_private: true,
            path: s3DocUploadRequest.path,
            suffix: s3DocUploadRequest.suffix || '',
            mimetype: s3DocUploadRequest.type,
            icon: null,
            doc_category: docCategory,
            pdf_status: DocPDFStatus.not_needed,
            task_component_id: taskComponentId ?? null,
        };
        const newDocUX = await postToAPI<DocUX>(
            `api/case/${caseUuid}/doc/new/`,
            {
                doc: insertRequest,
            },
            dispatch,
        );
        if (newDocUX) {
            dispatch(setCaseDoc(newDocUX, uid));
            return newDocUX;
        } else {
            dispatch(registerAppError('Unable to create new Document'));
        }
        return null;
    };
}

export function createCaseDocs(params: {
    userId: number;
    caseUuid: string;
    files: FileList | null;
    docCategory: DocCategory;
    taskComponentId?: number;
}) {
    return async (dispatch: AppDispatch): Promise<(DocUX | null)[]> => {
        const { userId, caseUuid, files, docCategory, taskComponentId } = params;

        if (!files || files.length === 0) {
            return [];
        }

        const docs: UploadFileRequest[] = [];
        for (let i = 0; i < files.length; i++) {
            const result = await getDataFromLocalComputer(files[i]);
            if (!result) {
                continue;
            }
            docs.push(result);
        }

        const placeHolderRecords: DocUXDetail[] = docs.map((d) => convertDocUploadToUXDetail(d, taskComponentId));
        dispatch(addCaseDocs(placeHolderRecords));

        return Promise.all(docs.map((doc) => {
            const targetDoc = placeHolderRecords.find(t => t.label === doc.name);
            if (!targetDoc) {
                log.warn('Failed to find doc in place holder', { doc, placeHolderRecords });
                return null;
            }

            return dispatch(uploadCaseDoc({
                userId,
                caseUuid,
                doc,
                uid: targetDoc.uid,
                docCategory,
                taskComponentId,
            }));
        }));
    };
}

export function removeCaseDoc(caseUuid: string, targetDocId: number) {
    return async (dispatch: AppDispatch) => {

        const deleteURL = `api/case/${caseUuid}/doc/${targetDocId}`;
        const currentCaseDocList = await deleteFromAPI<DocUX[]>(deleteURL, dispatch);
        if (currentCaseDocList) {
            dispatch(setCaseDocs(currentCaseDocList));
            return currentCaseDocList;
        }

        return null;
    };
}

export function downloadCaseDoc(caseUuid: string, targetDocId: number) {
    return async (dispatch: AppDispatch): Promise<DocDownloadResponse | null> => {
        const downloadRequest = await getFromAPI<DocDownloadResponse>(
            `api/case/${caseUuid}/doc/${targetDocId}/downloadurl`,
            dispatch);
        if (!downloadRequest) {
            return null;
        }
        return downloadRequest;
    };

}

type ExtractFormat = 'CSV';
export function downloadDocPdfFields(funeralHomeId: number, docId: number, format: ExtractFormat) {
    return async (dispatch: AppDispatch): Promise<void> => {
        await downloadFromAPI(
            `funeralhome/${funeralHomeId}/doc/${docId}/pdffields/${format}`,
            dispatch,
        );
    };
}

export function notifyGatherOfUploadedDocs(survey: DocUploadSurveyResponse, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<void> => {
        await postToAPI(
            `funeralhome/${funeralHomeId}/doc/notify/`,
            { survey },
            dispatch,
        );
    };
}

export type DocAction = SetFuneralHomeDocs
    | SetFuneralHomeDoc
    | SetFuneralHomeDocsLoading
    | AddFuneralHomeDocs
    | RemoveFuneralHomeDocs
    | UpdateFuneralHomeDoc
    | SetCaseDocs
    | SetCaseDoc
    | AddCaseDocs
    | UpdateCaseDoc
    ;

export function convertDocUploadToUXDetail(uploadReq: UploadFileRequest | File, taskComponentId?: number): DocUXDetail {
    return {
        uid: generateUuid(),
        doc: null,
        label: uploadReq.name,
        status: 'uploading',
        taskComponentId,
    };
}
