import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GDialog from './GDialog';

import makeGStyles from '../../styles/makeGStyles';

interface Props<T> {
    available: T[];
    selected: T[];
    isOpen: boolean;
    isLoading?: boolean;
    hasMore?: boolean;
    zIndex: number;
    isOptionSelected: (option: T) => boolean;
    getPrimaryDisplay: (option: T) => string;
    getSecondaryDisplay?: (option: T) => string;
    searchFn: (option: T, searchText: string) => boolean;
    closeDialog: () => void;
    onAdd: (option: T) => Promise<void>;
    onRemove: (option: T) => Promise<void>;
    onLoadMore?: () => void;
}

const useStyles = makeGStyles((theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
                height: 'auto'
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
    },
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)'
    },
    listContainer: {},
    sorryText: {
        marginTop: 20
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    savingSpinner: {
        padding: '0px 10px',
        marginLeft: 5,
    },
    lastItem: {
        justifyContent: 'center',
    },
    lastItemText: {
        flex: 'none',
    },
    lastItemSpinner: {
        marginTop: 15,
    },
}), { name: 'SelectDialog' });

function SelectDialog<T>(props: Props<T>) {
    const {
        available,
        isOpen,
        isLoading,
        zIndex,
        isOptionSelected,
        getPrimaryDisplay,
        getSecondaryDisplay,
        searchFn,
        closeDialog,
        onAdd,
        onRemove,
    } = props;

    const classes = useStyles();
    const topScrollRef = React.useRef<HTMLDivElement>(null);
    const listScrollElRef = React.useRef<HTMLDivElement>(null);

    const [searchText, setSearchText] = React.useState('');
    const [itemsSaving, setItemsSaving] = React.useState<T[]>([]);

    React.useEffect(() => {
        if (topScrollRef.current) {
            topScrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }
    });

    const handleToggle = async (e: React.MouseEvent<HTMLElement>, item: T) => {
        e.stopPropagation();
        e.preventDefault();

        setItemsSaving((prevState) => [
            ...prevState,
            item,
        ]);
        if (isOptionSelected(item)) {
            await onRemove(item);
        } else {
            await onAdd(item);
        }
        setItemsSaving((prevState) => prevState.filter((i) => i !== item));
    };

    const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => {
        setSearchText(currentTarget.value);
    };

    const renderNoResultsFoundSection = () => {
        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography
                    color="secondary"
                    component="p"
                    className={classes.sorryText}
                >
                    Sorry no results found.
                </Typography>
            </Grid >
        );
    };

    const renderListIcon = (item: T, isSelected: boolean) => {
        const isSaving = itemsSaving.some((i) => i === item);

        if (isSaving) {
            return (
                <CircularProgress
                    size={20}
                    className={classes.savingSpinner}
                />
            );
        }

        return (
            <IconButton
                onClick={(e) => handleToggle(e, item)}
                size="large"
            >
                {isSelected ? <RemoveCircleIcon /> : <AddIcon />}
            </IconButton>
        );
    };

    const renderOptions = () => {
        if (!isLoading && available.length === 0) {
            return renderNoResultsFoundSection();
        }

        const filtered = !searchText ? available : available.filter((opt) => searchFn(opt, searchText));

        return (
            <div ref={listScrollElRef}>
                <List component="nav" className={classes.listContainer} disablePadding>
                    <Divider />
                    {filtered.map((item) => {
                        const isSelected = isOptionSelected(item);
                        const primary = getPrimaryDisplay(item);
                        return (
                            <ListItem
                                key={primary}
                                button={!isSelected ? undefined : false}
                                onClick={(e) => handleToggle(e, item)}
                                divider
                            >
                                {renderListIcon(item, isSelected)}
                                <ListItemText
                                    primary={primary}
                                    secondary={getSecondaryDisplay ? getSecondaryDisplay(item) : undefined}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        );
    };

    return (
        <GDialog
            isOpen={isOpen}
            onClose={closeDialog}
            rootClass={classes.root}
            paperClass={classes.dialogPaper}
            titleClass={classNames(
                classes.dialogHeader,
                classes.backgroundPrimary
            )}
            contentClass={classes.dialogContent}
            zIndex={zIndex}
            title="Configure Options"
        >
            <div ref={topScrollRef} />
            <Grid container>
                <Grid item xs={12} className={classes.textCenter}>
                    <TextField
                        autoComplete="off"
                        className={classes.searchField}
                        fullWidth
                        autoFocus
                        value={searchText}
                        id="input-with-icon-textfield"
                        placeholder="Type here to filter list..."
                        name="searchText"
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            )
                        }}
                    />
                    {renderOptions()}
                </Grid>
            </Grid>
        </GDialog>
    );
}

export default SelectDialog;
