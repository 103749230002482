import { Theme } from '@mui/material/styles';
import { HelperStylesClassKey, HELPER_STYLES } from './Helper.styles';
import { TypographyStylesClassKey, TYPOGRAPHY_STYLES } from './Typography.styles';
import { AppStylesClassKey, APP_STYLES } from './App.styles';
import { MarginStylesClassKey, MARGIN_STYLES } from './Margin.styles';
import { PaddingStylesClassKey, PADDING_STYLES } from './Padding.styles';
import { Styles, WithStylesOptions } from '@mui/styles/withStyles';
import { makeStyles, StyleRules } from '@mui/styles';

type GlobalClassKey = HelperStylesClassKey
    | TypographyStylesClassKey
    | AppStylesClassKey
    | PaddingStylesClassKey
    | MarginStylesClassKey;
export function GLOBAL_STYLES<Props extends object>(
    appTheme: Theme
): StyleRules<Props, GlobalClassKey> {
    return {
        ...HELPER_STYLES<Props>(),
        ...TYPOGRAPHY_STYLES<Props>(),
        ...APP_STYLES<Props>()(appTheme),
        ...MARGIN_STYLES<Props>()(appTheme),
        ...PADDING_STYLES<Props>()(appTheme)
    };
}

export type CombinedKeys<T extends string> = T | GlobalClassKey;

/**
 * @deprecated
 * use styled component for styling or `GStyles` for applying common styles  
 */
export default function makeGStyles<
    Props extends object = {},
    ClassKey extends string = string,
>(
    styles: Styles<Theme, Props, ClassKey>,
    options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
) {
    return makeStyles<Theme, Props, CombinedKeys<ClassKey>>(theme => ({
        ...(typeof styles === 'function' ? styles(theme) : styles),
        ...GLOBAL_STYLES<Props>(theme)
    }), options);
}
