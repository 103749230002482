import * as React from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { APP_SECONDARY_COLOR } from '../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../services';
import CaseHelperReportTable from './CaseHelperReportTable';
import { StoreState } from '../../../types';
import InfoPopper from '../../invitation/InfoPopper';
import { EntityCaseRole, GatherCaseUX, isNonUserCaseHelper, UserRoles } from '../../../shared/types';
import HelperPopper from '../helperPopper/HelperPopper';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';
import { SlideTransition } from '../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            '@media (min-width: 768px)': {
                maxWidth: 1280,
                maxHeight: 720,
            }
        }
    },
    dialogHeaderGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (min-width: 480px)': {
            flexDirection: 'row'
        }
    },
    numberOfUsers: {
        fontSize: 68,
        fontWeight: 600,
        color: theme.palette.primary.main,
        marginRight: 8,
        lineHeight: 1,
    },
    userType: {
        textTransform: 'capitalize',
        fontSize: 36,
        fontWeight: 300,
        margin: '5px 0 4px',
        lineHeight: 1,
    },
    helperText: {
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '& svg': {
            fontSize: 16,
            marginRight: 4
        }
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 12,
        fontSize: 28,
        cursor: 'pointer'
    },
    headerDivider: {
        background: convertHexToRGBA(APP_SECONDARY_COLOR, 0.8),
        width: 180,
        hieght: 1,
        margin: '12px 0',
        '@media (min-width: 480px)': {
            height: 68,
            width: 1,
            margin: '0 16px',
        }

    },
    landingBanner: {
        minHeight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: convertHexToRGBA(theme.palette.primary.main, 0.2),
        fontWeight: 300,
        '& svg': {
            fontSize: 20,
            marginRight: 12
        }
    },
    dialogContent: {
        padding: 0,
    },
    dialogTitle: {
        padding: '12px 0',
    },
    tableGrid: {
        margin: '0 24px',
        width: '100%',
        maxWidth: 'calc(100% - 48px)'
    },
    adminGuestMainContent: {
        height: 68,
        maxHeight: 68,
    },
    dialogTitleGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogPaper: {}
});

const mapStateToProps = ({ tasksState, casesState }: StoreState) => {
    return {
        tasksState,
        helpers: casesState.helpers,
    };
};

type Props = ReturnType<typeof mapStateToProps> & {
    isOpen: boolean;
    closeDialog: () => void;
    selectedCase: GatherCaseUX;
    zIndex: number;
};

interface State {
    isMouseStayOnTabIcon: boolean;
    infoPopperAnchorEle: HTMLElement | SVGElement | null;
    infoPopperContentAnchorEle: HTMLElement | SVGElement | null;
    infoPopperType: EntityCaseRole | null;
    helperPopperAnchorEle: HTMLElement | null;
    activeEntityId: number | null;
}

type StyledProps = WithStyles<'root' | 'dialogPaper' | 'helperText' | 'userType' | 'numberOfUsers'
    | 'textAlignCenter' | 'clearIcon' | 'headerDivider' | 'landingBanner' | 'dialogContent' | 'dialogTitle'
    | 'tableGrid' | 'dialogHeaderGrid' | 'adminGuestMainContent' | 'dialogTitleGrid'>;

class CaseHelperReportDialog extends React.Component<Props & StyledProps, State> {

    state: State = {
        isMouseStayOnTabIcon: false,
        infoPopperAnchorEle: null,
        infoPopperContentAnchorEle: null,
        infoPopperType: null,
        helperPopperAnchorEle: null,
        activeEntityId: null,
    };

    renderDialogTitle = (userCount: number, userType: string) => {
        const { classes } = this.props;

        return (
            <Grid item className={classes.dialogTitleGrid}>
                <Typography color="primary" className={classes.numberOfUsers}>
                    {userCount}
                </Typography>
                <Grid item className={classes.adminGuestMainContent}>
                    <Typography
                        color="primary"
                        className={classNames(
                            classes.userType,
                            userCount === 1 && classes.textAlignCenter
                        )}
                    >
                        {userCount === 1 && userType || `${userType}s`}
                    </Typography>
                    <Grid
                        item
                        onMouseLeave={e => this.handleMouseLeaveOnInfoPopper()}
                        onMouseEnter={e =>
                            this.openInfoPopper(
                                e,
                                userType === 'admin' ?
                                    EntityCaseRole.admin :
                                    EntityCaseRole.guest
                            )}
                        onMouseDown={e =>
                            this.openInfoPopper(
                                e,
                                userType === 'admin' ?
                                    EntityCaseRole.admin :
                                    EntityCaseRole.guest
                            )}
                    >
                        <Typography color="secondary" className={classes.helperText}>
                            <InfoIcon />
                            {`What is ${userType === 'admin' && 'an admin' || 'a guest'}?`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    render() {
        const {
            isOpen,
            classes,
            selectedCase,
            helpers,
            zIndex,
            closeDialog
        } = this.props;
        const {
            infoPopperAnchorEle,
            infoPopperType,
            helperPopperAnchorEle,
            activeEntityId,
        } = this.state;

        const adminUsersCount = helpers.filter((e) => UserRoles.isFamilyAdmin(e, selectedCase.id)).length;
        const adminCount = adminUsersCount + 1; // +1 for assignee
        const guestCount = helpers.filter((e) => UserRoles.isFamilyGuest(e, selectedCase.id)
            || isNonUserCaseHelper(e, selectedCase.id)).length;

        return (
            <>
                <Dialog
                    open={isOpen}
                    TransitionComponent={SlideTransition}
                    onClose={e => closeDialog()}
                    className={classes.root}
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                    transitionDuration={300}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        <div className={classes.dialogHeaderGrid}>
                            {this.renderDialogTitle(adminCount, 'admin')}
                            <Divider className={classes.headerDivider} />
                            {this.renderDialogTitle(guestCount, 'guest')}
                        </div>

                        <ClearIcon className={classes.clearIcon} color="primary" onClick={e => closeDialog()} />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid item xs={12} className={classes.landingBanner}>
                            <VisibilityOffIcon color="primary" />
                            <Typography color="primary">This Report is only visible to Admins</Typography>
                        </Grid>

                        <Grid container className={classes.tableGrid}>
                            <CaseHelperReportTable
                                selectedCase={selectedCase}
                                openHelperPopper={this.openHelperPopper}
                                zIndex={zIndex + 1}
                            />
                        </Grid>
                    </DialogContent>
                </Dialog>

                <InfoPopper
                    zIndex={zIndex + 1}
                    popperAnchorEle={infoPopperAnchorEle}
                    clickAwayListener={this.infoPopperClickAwayListener}
                    caseFName={selectedCase.fname}
                    infoPopperType={infoPopperType}
                />

                <HelperPopper
                    key={activeEntityId}
                    popperAnchorEle={helperPopperAnchorEle}
                    activeEntityId={activeEntityId}
                    selectedCase={selectedCase}
                    closeHelperPopper={this.closeHelperPopper}
                    zIndex={zIndex + 1}
                    clickAwayListener={this.clickAwayListener}
                    closeParentDialog={closeDialog}
                />
            </>
        );
    }

    infoPopperClickAwayListener = (event: MouseEvent | TouchEvent) => {
        const { infoPopperAnchorEle } = this.state;
        const currentTarget = event.target as HTMLElement;

        if (currentTarget !== infoPopperAnchorEle) {
            this.setState({ infoPopperAnchorEle: null });
        }
    };

    openInfoPopper = (event: React.MouseEvent<HTMLElement | SVGElement>, infoPopperType: EntityCaseRole) => {
        const currentTarget = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isMouseStayOnTabIcon: true,
            infoPopperType
        });
        setTimeout(
            () => {
                if (this.state.isMouseStayOnTabIcon) {
                    this.setState({ infoPopperAnchorEle: currentTarget });
                }
            },
            400
        );
    };

    handleMouseLeaveOnInfoPopper = () => {
        this.setState({
            infoPopperAnchorEle: null,
            infoPopperContentAnchorEle: null,
            isMouseStayOnTabIcon: false,
        });
    };

    clickAwayListener = (event: MouseEvent | TouchEvent) => {
        this.closeHelperPopper();
    };

    closeHelperPopper = () => {
        this.setState({ helperPopperAnchorEle: null });
    };

    openHelperPopper = (
        entityId: number,
        event: React.MouseEvent<HTMLElement>
    ) => {
        this.setState({
            helperPopperAnchorEle: event.currentTarget,
            activeEntityId: entityId,
        });
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(CaseHelperReportDialog));
