import { Component } from 'react';

import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import { StoreState } from '../../types';

import { closePhotoSwipe } from '../../actions/PhotoSwipe.action';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';

const events = () => {
    return [
        'beforeChange',
        'afterChange',
        'imageLoadComplete',
        'resize',
        'gettingData',
        'mouseUsed',
        'initialZoomIn',
        'initialZoomInEnd',
        'initialZoomOut',
        'initialZoomOutEnd',
        'parseVerticalMargin',
        'close',
        'unbindEvents',
        'destroy',
        'updateScrollOffset',
        'preventDragEvent',
        'shareLinkClick'
    ];
};

const urlsToPhotoswipeItemsMapper = (items: Array<string>): Photoswipe.Item[] => {
    return items.map(src => {
        return { src, h: 0, w: 0 }; 
    });
};

function mapStateToProps({ photoSwipeState }: StoreState) {
    const { isOpen, options, urls } = photoSwipeState;
    return { isOpen, options, urls };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

class GPhotoSwipe extends Component<Props> {
    private photoSwipeElement: HTMLDivElement | null;
    private photoSwipe: Photoswipe<PhotoSwipe.Options>;

    componentDidMount = () => {
        if (this.props.isOpen) {
            this.openPhotoSwipeDialog();
        }
    };

    componentDidUpdate(prevProps: Props) {
        const { isOpen } = this.props;

        if (isOpen && !prevProps.isOpen) {
            this.openPhotoSwipeDialog();
            return;
        }

        if (!isOpen && prevProps.isOpen) {
            this.closePhotoSwipeDialog();
        }
    }

    componentWillUnmount = () => {
        this.closePhotoSwipeDialog();
    };

    openPhotoSwipeDialog = () => {
        const { urls, options } = this.props;
        if (!this.photoSwipeElement) {
            return;
        }

        const photoSwipeOptions: PhotoSwipe.Options = {
            closeOnScroll: false,
            ...options
        };

        this.photoSwipe = new Photoswipe(
            this.photoSwipeElement,
            PhotoswipeUIDefault,
            urlsToPhotoswipeItemsMapper(urls),
            photoSwipeOptions
        );

        this.photoSwipe.listen('gettingData', (index, item) => {
            if (item.w !== undefined && item.w === 0 || item.h !== undefined && item.h === 0) {
                let img = new Image();
                if (item.src) {
                    img.src = item.src; 
                }
                img.onload = () => {
                    item.w = img.width;
                    item.h = img.height;
                    this.photoSwipe.invalidateCurrItems();
                    this.photoSwipe.updateSize(true);
                };
            }
        });

        events().map((event) => {
            const callback = this.props[event];
            if (callback || event === 'destroy') {
                const _this = this;
                this.photoSwipe.listen(event, (...args) => {
                    if (callback) {
                        args.unshift(this);
                        callback(...args);
                    }
                    if (event === 'destroy') {
                        _this.closePhotoSwipeDialog();
                    }
                });
            }
        });

        this.photoSwipe.init();
    };

    closePhotoSwipeDialog = () => {
        if (this.photoSwipe) {
            this.photoSwipe.close();
        }
        this.props.dispatch(closePhotoSwipe());
    };

    render() {
        return (
            // < !--Root element of PhotoSwipe.Must have class pswp.-->
            <div
                className="pswp"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
                ref={node => this.photoSwipeElement = node}
            >

                {/* <!-- Background of PhotoSwipe.
                     It's a separate element as animating opacity is faster than rgba(). --> */}
                <div className="pswp__bg" />

                {/* <!-- Slides wrapper with overflow:hidden. --> */}
                <div className="pswp__scroll-wrap">

                    {/* <!-- Container that holds slides.
                        PhotoSwipe keeps only 3 of them in the DOM to save memory.
                        Don't modify these 3 pswp__item elements, data is added later on. --> */}
                    <div className="pswp__container">
                        <div className="pswp__item" />
                        <div className="pswp__item" />
                        <div className="pswp__item" />
                    </div>

                    {/* <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. --> */}
                    <div className="pswp__ui pswp__ui--hidden">

                        <div className="pswp__top-bar">

                            {/* <!--  Controls are self-explanatory. Order can be changed. --> */}

                            <div className="pswp__counter" />

                            <button className="pswp__button pswp__button--close" title="Close (Esc)" />

                            <button className="pswp__button pswp__button--fs" title="Toggle fullscreen" />

                            <button className="pswp__button pswp__button--zoom" title="Zoom in/out" />

                            {/* <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                            <!-- element will get class pswp__preloader--active when preloader is running --> */}
                            <div className="pswp__preloader">
                                <div className="pswp__preloader__icn">
                                    <div className="pswp__preloader__cut">
                                        <div className="pswp__preloader__donut" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div className="pswp__share-tooltip" />
                        </div>

                        <button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)" />

                        <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)" />

                        <div className="pswp__caption">
                            <div className="pswp__caption__center" />
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default withState(mapStateToProps)(GPhotoSwipe);
