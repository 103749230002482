import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { UserSession } from '../../../types';
import { CaseTaskUX, GatherCaseUX } from '../../../shared/types';

import LockContent from './LockContent';
import AssignTask from '../../assignmentPoppers/AssignTask';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import withFullScreen from '../../common/utilHOC/WithFullScreen';
import GPopper from '../../common/GPopper';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    completionStatus: {
        margin: '0 8px 0',
        textAlign: 'center',
        borderBottom: '1px solid rgba(117, 117, 117, 1)',
        paddingTop: '15px',
        paddingBottom: '10px'
    },
    assignTaskCont: {
        margin: '0 8px 0',
        paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'center',
        borderTop: '1px solid rgba(117, 117, 117, 1)',
    },
    fontSize26: {
        fontSize: 26
    },
    casePhotosText: {
        fontSize: 20,
        fontWeight: 300
    },
    albumPhotosText: {
        fontSize: 24,
        fontWeight: 300
    },
    paper: {
        maxWidth: 288,
        width: 288,
        '@media (min-width: 360px)': {
            maxWidth: 330,
            width: 330,
        },
        boxShadow: theme.shadows[5],
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            left: '5px !important'
        },
    },
    arrow: {
        left: '-4px !important'
    }
});

type StyledProps = Props & WithGStyles<'root' | 'completionStatus' | 'assignTaskCont' | 'fontSize26'
    | 'casePhotosText' | 'albumPhotosText' | 'paper' | 'popper' | 'arrow'>;

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    popperAnchorEle: HTMLElement | null;
    userSession: UserSession;
    task: CaseTaskUX | null;
    activeCase: GatherCaseUX;
    locked: boolean;
    closePopper: () => void;
    lockUnlockAlbum: (isLocked: boolean) => void;
    zIndex: number;
    albumPhotosCount: number;
    casePhotosCount: number;
}

interface State {
    isChildPopperOpen: boolean;
}

type CombinedProps = DialogProps & StyledProps;
class LockPopover extends Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            isChildPopperOpen: false
        };
    }

    statusCount = () => {
        const { casePhotosCount, albumPhotosCount, classes } = this.props;

        return (
            <Grid item xs={12}>
                <Typography color="secondary" align="center" className={classes.albumPhotosText}>
                    {albumPhotosCount} {albumPhotosCount === 1 && 'Photo' || 'Photos'} Selected
                </Typography>

                <Typography color="secondary" align="center" className={classes.casePhotosText}>
                    ({casePhotosCount} {casePhotosCount === 1 && 'Photo' || 'Photos'} Available)
                </Typography>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            popperAnchorEle,
            task,
            userSession,
            activeCase,
            locked,
            closePopper,
            zIndex,
            lockUnlockAlbum
        } = this.props;
        const { isChildPopperOpen } = this.state;

        const content = (
            <>
                <Grid
                    item
                    xs={12}
                    className={classes.completionStatus}
                >
                    <Typography
                        variant="h5"
                        align="center"
                        color="secondary"
                        className={classNames(
                            classes.fontSize26,
                            classes.fontWeight200
                        )}
                    >
                        {this.statusCount()}
                    </Typography>
                </Grid>

                <LockContent
                    userSession={userSession}
                    activeCase={activeCase}
                    closeLockFormDialog={closePopper}
                    locked={locked}
                    lockUnlockAlbum={lockUnlockAlbum}
                    zIndex={zIndex}
                />

                <Grid
                    item
                    xs={12}
                    className={classes.assignTaskCont}
                >
                    {task && <AssignTask
                        zIndex={zIndex + 1}
                        task={task}
                        activeCase={activeCase}
                        setChildPopperState={this.setChildPopperState}
                    />}
                </Grid>
            </>
        );

        return (
            <GPopper
                anchorEle={popperAnchorEle}
                paperClass={classes.paper}
                className={classes.popper}
                arrowClass={classes.arrow}
                closePopper={() => {
                    if (isChildPopperOpen) {
                        return;
                    }
                    closePopper();
                }}
                zIndex={zIndex}
            >
                {content}
            </GPopper>
        );
    }

    setChildPopperState = (isChildPopperOpen: boolean) => {
        this.setState({ isChildPopperOpen });
    };
}

export default withFullScreen()(withGStyles(styles)(LockPopover));