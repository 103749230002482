import Zoom from '@mui/material/Zoom';

import UserAvatar from '../../components/common/UserAvatar';
import { UserAvatarProps } from './UserAvatar';
// import { useForkRef } from '@mui/material/utils';

interface Props extends UserAvatarProps {
    show?: boolean;
    zoomDuration: number;
}

// const UserAvatarWithZoom = React.forwardRef(
function UserAvatarWithZoom(props: Props, /* ref: React.Ref<HTMLDivElement> */) {
    const { show = true, zoomDuration, ...otherProps } = props;

    // const divRef = React.useRef<HTMLDivElement>(null);
    // const nodeRef = useForkRef<HTMLDivElement>(divRef, ref);

    return (
        <Zoom in={show} timeout={zoomDuration}>
            <UserAvatar {...otherProps} />
        </Zoom>
    );
}
// );

export default UserAvatarWithZoom;
