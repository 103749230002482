import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanLoginProps } from '../utils';
import CanLoginWrapper from '../widgets/CanLoginWrapper';

const CanLogin = (props: CanLoginProps) => {
    const { accountType, activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanLoginWrapper
            accountType={accountType}
            caseFName={activeCase.fname}
            onStepChange={onStepChange}
        >
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                If {activeCase.fname} had an unpaid Spotify account, simply go to Spotify, log in
                as {activeCase.fname} and follow the instructions listed below.
            </Typography>
            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    <a
                        href="https://support.spotify.com/us/contact-spotify-anonymous/#_"
                        target="blank"
                        className={classes.colorSecondary}
                    >
                        Click here
                    </a>
                    {` to close ${activeCase.fname}'s account (make sure to log in as ${activeCase.fname})`}
                </li>
                <li className={classes.marginTop10}>
                    Select "Account"
                </li>
                <li className={classes.marginTop10}>
                    Select "I want to close my Spotify account permanently"
                </li>
                <li className={classes.marginTop10}>
                    Continue through the steps.
                </li>
            </ul>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                If {activeCase.fname} was being charged for Spotify and you need to cancel all future payments,
                you can click the link below to cancel {activeCase.fname}'s Spotify subscription.
            </Typography>
            <Grid>
                <a
                    href="https://support.spotify.com/us/article/how-to-cancel-your-subscription/"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Cancel {activeCase.fname}'s Spotify
                        Subscription <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </CanLoginWrapper>
    );
};

export default CanLogin;