import { useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

import TextField from '@mui/material/TextField';
import { SlideTransition } from './Transitions';
import makeGStyles from '../../styles/makeGStyles';

const useStyles = makeGStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        width: '100%',
    },
    newValueInput: {
        width: '100%',
        marginTop: 20,
    },
    dialogContent: {
        minWidth: 400,
    },
});

interface Props {
    isLoading?: boolean;
    header?: string;
    subHeader?: string;
    confirmationButtonText?: string | JSX.Element;
    cancelButtonText?: string;
    dialogActionClass?: string;
    contentClass?: string;
    open: boolean;
    onClose: () => void;
    onClearValue?: () => void;
    onSave: (newValue: string) => void;
    zIndex: number;
    originalValue: string | null;
    newValueInputTextMessage: string;
    isSaving?: boolean;
    validator?: (newValue: string) => boolean;
    currentDetail?: string | JSX.Element;
    prefillOriginalValue?: boolean;
    helperText?: string;
}

const ChangeOriginalValueDialog = (props: Props) => {
    const {
        header,
        subHeader,
        confirmationButtonText,
        open,
        isLoading,
        cancelButtonText,
        dialogActionClass,
        contentClass,
        zIndex,
        newValueInputTextMessage,
        originalValue,
        isSaving,
        currentDetail,
        helperText,
        validator,
        onClose,
        onSave,
        onClearValue,
    } = props;

    const classes = useStyles();

    const [newValue, setNewValue] = useState(props.prefillOriginalValue ? props.originalValue || '' : '');
    const [confirmAttempted, setConfirmAttempted] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(e.target.value);
    };

    const isValid = (): boolean => validator ? validator(newValue) : Boolean(newValue);

    const handleConfirmClick = () => {
        if (!isValid()) {
            setConfirmAttempted(true);
            return;
        }
        onSave(newValue);
    };

    const invalid = !isValid();

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={onClose}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                keepMounted
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ zIndex: zIndex + 1 }}
            >
                <DialogTitle>{header}</DialogTitle>
                <DialogContent className={classNames(classes.dialogContent, contentClass)}>
                    <DialogContentText>{subHeader}</DialogContentText>
                    <DialogContentText>
                        {currentDetail
                            || <span>
                                Current: <b>{originalValue}</b>
                            </span>}
                    </DialogContentText>
                    <TextField
                        label={newValueInputTextMessage}
                        type="newValue"
                        id="newValue"
                        name="newValue"
                        value={newValue}
                        className={classes.newValueInput}
                        onChange={onChange}
                        autoComplete="new-password"
                        disabled={isSaving || isLoading}
                        error={invalid && confirmAttempted}
                    />
                    {helperText &&
                        <FormHelperText
                            color="secondary"
                            error={invalid && confirmAttempted}
                        >
                            {helperText}
                        </FormHelperText>
                    }
                </DialogContent>
                <DialogActions className={dialogActionClass || undefined}>
                    <Button onClick={onClose}>
                        {cancelButtonText || 'Cancel'}
                    </Button>
                    {onClearValue &&
                        <Button
                            onClick={onClearValue}
                            color="error"
                            variant="contained"
                        >
                            Clear Current
                        </Button>
                    }
                    <Button
                        onClick={handleConfirmClick}
                        autoFocus
                        color="primary"
                        variant="contained"
                        disabled={isLoading || (confirmAttempted && invalid)}
                    >
                        {confirmationButtonText || 'CONFIRM'}
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ChangeOriginalValueDialog;
