import * as React from 'react';
import classNames from 'classnames';

import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles, } from '@mui/styles/withStyles';
import { getIntercomTargetProp } from '../../../services';
import Box from '@mui/material/Box';
import { GStyles } from '../../../styles/GStyles';

export interface GswitchProps extends Omit<SwitchProps, 'classes'> {
    formControlLabelClasses?: string;
    switchBaseClasses?: string;
    labelClasses?: string;
    label?: string | JSX.Element;
    disableToggleOnTextClick?: boolean;
    iconCheckedClass?: string;
    barClass?: string;
    switchRootClass?: string;
    switchCheckedClass?: string;
    switchIconClass?: string;
    rootClass?: string;
    onChangeCallBack: (isChecked: boolean) => void;
    intercomTargetProp?: string;
}

const styles: StyleRulesCallback<Theme, GswitchProps> = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    marginRight0: {
        marginRight: 0
    },
    fontSize14: {
        fontSize: 14
    },
    opacity06: {
        opacity: 0.6,
    },
    paddingTB4: {
        paddingTop: 4,
        paddingBottom: 4,
    },
});

type StyledProps = WithStyles<'root' | 'marginRight0' | 'fontSize14' | 'opacity06' | 'paddingTB4'>;

type CombinedProps = GswitchProps & StyledProps;

class GSwitch extends React.Component<CombinedProps> {
    protected startX: number;

    render() {
        const {
            classes,
            label,
            disableToggleOnTextClick,
            onChangeCallBack,
            formControlLabelClasses,
            labelClasses,
            disabled,
            switchBaseClasses,
            iconCheckedClass,
            barClass,
            switchRootClass,
            switchCheckedClass,
            switchIconClass,
            rootClass,
            intercomTargetProp,
            sx,
            ...otherProps
        } = this.props;

        const showLabel = !disableToggleOnTextClick ? label : null;

        return (
            <Box
                sx={sx}
                className={classNames(
                    classes.paddingTB4,
                    rootClass,
                    disableToggleOnTextClick ? classes.root : undefined
                )}
                {...getIntercomTargetProp(intercomTargetProp)}
            >
                <FormControlLabel
                    label={showLabel || ''}
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                    classes={{
                        root: formControlLabelClasses,
                        label: classNames(labelClasses, !disabled && !disableToggleOnTextClick && GStyles.cursorPointer)
                    }}
                    className={disableToggleOnTextClick ? classes.marginRight0 : undefined}
                    control={
                        <Switch
                            {...otherProps}
                            disabled={disabled}
                            onChange={this.onChange}
                            color="primary"
                            classes={{
                                root: switchRootClass,
                                switchBase: switchBaseClasses,
                                track: barClass,
                                // TODO(Jonathan): Come back and check that this is styling correctly
                                // iconChecked: iconCheckedClass,
                                checked: classNames(switchCheckedClass, iconCheckedClass),
                                thumb: switchIconClass,
                            }}
                        />
                    }
                />
                {disableToggleOnTextClick &&
                    <InputLabel
                        className={classNames(
                            labelClasses,
                            classes.fontSize14,
                            disabled && classes.opacity06
                        )}
                    >
                        {label}
                    </InputLabel>
                }
            </Box>
        );
    }

    onTouchEnd = (event: React.TouchEvent<HTMLElement>) => {
        const { onChangeCallBack } = this.props;
        const touchObj = event.changedTouches[0];

        /**
         * get horizontal distance traveled by finger while in contact with surface
         */
        const distX = touchObj.pageX - this.startX;

        if (distX === 0) {
            return;
        }

        onChangeCallBack(distX > 0);
    };

    onTouchStart = (event: React.TouchEvent<HTMLElement>) => {
        const touchObj = event.changedTouches[0];
        this.startX = touchObj.pageX;
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChangeCallBack } = this.props;
        onChangeCallBack(event.target.checked);
    };
}

export default withStyles(styles)(GSwitch);
