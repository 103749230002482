import { useGStyles } from '../../../../common/hooks/useGStyles';
import { OnlineAccountType, ResetPasswordStepsProps } from '../utils';
import ResetPasswordStepsContent from '../widgets/ResetPasswordStepsContent';

const ResetPasswordSteps = (props: ResetPasswordStepsProps) => {
    const { activeCase, onStepChange } = props;

    const classes = useGStyles();

    const steps = [<>
        <a
            href="https://www.pandora.com/account/help"
            target="blank"
            className={classes.colorSecondary}
        >
            Click here
        </a>
        {' '} to reset {activeCase.fname}’s {OnlineAccountType.Pandora} password
    </>,
    `Enter ${activeCase.fname}’s email address`,
    `A reset password email will be sent to ${activeCase.fname}’s email`,
        'Follow the instructions in the email'
    ];

    return (
        <ResetPasswordStepsContent
            caseFName={activeCase.fname}
            accountType={OnlineAccountType.Pandora}
            steps={steps}
            onReturnClick={onStepChange}
            onAbleToLogin={onStepChange}
            onUnableToLogin={onStepChange}
        />
    );
};

export default ResetPasswordSteps;