import { alpha, Grid, Typography } from "@mui/material";
import {
    joinNameParts,
    formatDateString,
    DateTimeFormat,
    getPhoneNumberParts,
    dateToString
} from "../../../services";
import { formatPrice } from "../../../shared/goods_and_services/pricing";
import { Homesteaders, InsurancePolicyUX } from "../../../shared/types";
import makeGStyles from "../../../styles/makeGStyles";
import PolicyContentItem from "./PolicyContentItem";

const useStyles = makeGStyles((theme) => ({
    contentContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        [theme.breakpoints.down(600)]: {
            gridTemplateColumns: '1fr'
        },
    },
    title: {
        fontSize: 12,
        padding: '2px 7px',
    },
}), { name: 'PolicyContents' });

interface PolicyProps {
    policy: InsurancePolicyUX;
    insuranceColor: string;
}

export const formatPhoneNumber = (phone: string) => {
    const phoneParts = getPhoneNumberParts(phone);
    if (!phoneParts) {
        return null;
    };
    return `(${phoneParts.areaCode}) ${phoneParts.prefix}-${phoneParts.subscriber}`;
};

const PolicyContents = ({ policy, insuranceColor }: PolicyProps) => {

    const classes = useStyles();

    const insured = policy.insured;
    const owner = policy.owner;

    if (!policy || !insuranceColor) {
        return null;
    }

    return (
        <Grid container className={classes.contentContainer}>
            <Grid item>
                <Grid sx={{
                    bgcolor: `${alpha(insuranceColor, 0.1)}`
                }}>
                    <Typography className={classes.title}
                        sx={{
                            color: insuranceColor,
                            borderRight: `1px solid ${alpha(insuranceColor, 0.1)}`,
                            borderBottom: `1px solid ${alpha(insuranceColor, 0.1)}`,
                        }}
                    >Insured Information</Typography>
                </Grid>
                <PolicyContentItem
                    title='Insured'
                    description={joinNameParts({ fname: insured.firstName, lname: insured.lastName })}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Insured DOB'
                    description={formatDateString(insured.dateOfBirth, DateTimeFormat.DayMonthYear)}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Insured Age'
                    description={insured.age}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Insured Gender'
                    description={insured.gender}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Insured Email'
                    description={insured.email}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Insured Phone'
                    description={formatPhoneNumber(insured.phone)}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Insured Address'
                    description={`${insured.addressLineOne} ${insured.addressLineTwo
                        ? `, ${insured.addressLineTwo}` : ''}
                        ${insured.city}, ${insured.state} ${insured.zip}`}
                    titleColor={insuranceColor}
                />
            </Grid>
            <Grid item>
                <Grid sx={{
                    bgcolor: alpha(insuranceColor, 0.1)
                }}>
                    <Typography className={classes.title}
                        sx={{
                            color: insuranceColor,
                            borderRight: `1px solid ${alpha(insuranceColor, 0.1)}`,
                            borderBottom: `1px solid ${alpha(insuranceColor, 0.1)}`,
                        }}
                    >
                        Policy Information
                    </Typography>
                </Grid>
                <Grid sx={{
                    borderLeft: `1px solid ${alpha(insuranceColor, 0.1)}`,
                    borderRight: `1px solid ${alpha(insuranceColor, 0.1)}`
                }}>
                    <PolicyContentItem
                        title='Policy No.'
                        description={policy.policy_number}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Status'
                        description={Homesteaders.PolicyStatusLookup[policy.status]}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Product Type'
                        description={policy.product_type}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Face Value'
                        description={formatPrice(parseFloat(policy.face_value) * 100, 'USD')}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Issue Date'
                        description={dateToString(policy.issue_date, DateTimeFormat.DayMonthYear)}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Pay Frequency'
                        description={policy.pay_frequency}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Arrangement Total'
                        description={formatPrice(parseFloat(policy.arrangement_total) * 100, 'USD')}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Funeral Home'
                        description={policy.funeral_home.name}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title='Funeral Home Address'
                        description={`${policy.funeral_home.addressLine1}
                            ${policy.funeral_home.addressLine2}
                            ${policy.funeral_home.city}, ${policy.funeral_home.state}
                            ${policy.funeral_home.zip}`}
                        titleColor={insuranceColor}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid sx={{
                    bgcolor: `${alpha(insuranceColor, 0.1)}`
                }}>
                    <Typography className={classes.title}
                        sx={{
                            color: `${insuranceColor}`,
                            borderRight: `1px solid ${alpha(insuranceColor, 0.1)}`,
                            borderBottom: `1px solid ${alpha(insuranceColor, 0.1)}`,
                        }}
                    >Policy Owner Information</Typography>
                </Grid>
                <PolicyContentItem
                    title='Policy Owner'
                    description={joinNameParts({ fname: owner.firstName, lname: owner.lastName })}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Policy Owner DOB'
                    description={formatDateString(owner.dateOfBirth, DateTimeFormat.DayMonthYear)}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Policy Owner Age'
                    description={owner.age}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Policy Owner Gender'
                    description={owner.gender}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Policy Owner Email'
                    description={owner.email}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Policy Owner Phone'
                    description={formatPhoneNumber(owner.phone)}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title='Policy Owner Address'
                    description={
                        `${owner.addressLineOne} ${owner.addressLineTwo
                            ? `, ${owner.addressLineTwo}` : ''}
                        ${owner.city}, ${owner.state} ${owner.zip}`}
                    titleColor={insuranceColor}
                />
            </Grid>
        </Grid>
    );
};

export default PolicyContents;