import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { GLOBAL_STYLED_PROPS } from '../../../../../../styles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../../../styles/WithGStyles';

export interface Props {
    label: string;
    isChecked: boolean;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    rootClass?: string;
    formControlClass?: string;
    labelClass?: string;
    onChange: (isChecked: boolean) => void;
    handleOpenSnackbar?: () => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        width: '100%',
        textAlign: 'left',
        margin: 'auto',
    },
    formControl: {
        width: '100%',
        maxWidth: '100%',
        textAlign: 'left',
        '@media (min-width: 421px)': {
            width: 360,
            maxWidth: 360,
        },
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'formControl'>;

const DCCheckbox = (props: StyledProps) => {

    const {
        classes,
        error,
        disabled,
        required,
        label,
        rootClass,
        formControlClass,
        labelClass,
        isChecked,
        handleOpenSnackbar,
        onChange,
    } = props;

    return (
        <Grid item className={classNames(classes.root, rootClass)}>
            <FormControl
                className={classNames(classes.formControl, formControlClass)}
                required={required}
                error={error}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(_, checked) => onChange(checked)}
                            checked={isChecked}
                            tabIndex={-1}
                            color="primary"
                            disabled={disabled}
                            required={required}
                        />
                    }
                    onClick={handleOpenSnackbar}
                    classes={{
                        label: classNames(classes.fontSize12, classes.colorSecondary, labelClass)
                    }}
                    label={label}
                />
            </FormControl>
        </Grid>
    );
};

export default withGStyles(styles)(DCCheckbox);
