import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

export const MARGIN_STYLES =
    <Props extends object>(): StyleRulesCallback<Theme, Props, MarginStylesClassKey> => theme => ({
        marginAuto: {
            margin: 'auto',
        },
        margin_0: {
            margin: '0 !important',
        },
        margin_8: {
            margin: theme.spacing(),
        },
        marginTheme: {
            margin: theme.spacing(),
        },

        marginTop0: {
            marginTop: 0,
        },
        marginTop2: {
            marginTop: 2,
        },
        marginTop5: {
            marginTop: 5
        },
        marginTop8: {
            marginTop: 8
        },
        marginTop10: {
            marginTop: 10
        },
        marginTop12: {
            marginTop: 12
        },
        marginTop16: {
            marginTop: 16
        },
        marginTop20: {
            marginTop: 20,
        },
        marginTop24: {
            marginTop: 24,
        },
        marginTop30: {
            marginTop: 30,
        },
        marginTop40: {
            marginTop: 40
        },
        marginTop50: {
            marginTop: 50
        },


        marginBottom5: {
            marginBottom: 5,
        },
        marginBottom8: {
            marginBottom: 8,
        },
        marginBottom10: {
            marginBottom: 10
        },
        marginBottom16: {
            marginBottom: 16,
        },
        marginBottom20: {
            marginBottom: 20,
        },
        marginBottom24: {
            marginBottom: 24,
        },
        marginBottom40: {
            marginBottom: 40,
        },
        marginBottom50: {
            marginBottom: 50,
        },


        marginRight5: {
            marginRight: 5
        },
        marginRight10: {
            marginRight: 10
        },
        marginRight20: {
            marginRight: 20
        },


        marginLeft5: {
            marginLeft: 5
        },
        marginLeft10: {
            marginLeft: 10
        },
        marginLeft20: {
            marginLeft: 20
        },
        marginLeft28: {
            marginLeft: 28,
        },
        margin_24_0: {
            margin: '24px 0'
        },
    });

export type MarginStylesClassKey =
    'marginAuto'
    | 'margin_0'
    | 'margin_8'

    | 'marginTop0'
    | 'marginTop2'
    | 'marginTop5'
    | 'marginTop8'
    | 'marginTop10'
    | 'marginTop12'
    | 'marginTop16'
    | 'marginTop20'
    | 'marginTop24'
    | 'marginTop30'
    | 'marginTop40'
    | 'marginTop50'

    | 'marginBottom5'
    | 'marginBottom8'
    | 'marginBottom10'
    | 'marginBottom16'
    | 'marginBottom20'
    | 'marginBottom24'
    | 'marginBottom40'
    | 'marginBottom50'

    | 'marginRight5'
    | 'marginRight10'
    | 'marginRight20'

    | 'marginLeft5'
    | 'marginLeft10'
    | 'marginLeft20'
    | 'marginLeft28'

    | 'margin_24_0'
    | 'marginTheme';
export type MARGIN_STYLED_PROPS = WithStyles<MarginStylesClassKey>;
