import { CaseTaskUX } from "../../../../../shared/types";
import { OverviewSectionCommonProps } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { SectionTypography } from "./components";

interface Props extends Omit<OverviewSectionCommonProps, 'deathCertificate'> {
    dcTask: CaseTaskUX | null;
}
const NotesOverview = (props: Props) => {
    const { showHeading, printMode, dcTask } = props;

    if (!dcTask) {
        return null;
    }

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    NOTES
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label={null}
                    html={dcTask.note}
                    fieldKey={undefined}
                />
            </SectionTypography>
        </div>
    );
};

export default NotesOverview;