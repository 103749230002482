import ToggleButton, { toggleButtonClasses } from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { SxProps, Theme } from "@mui/material/styles";
import classNames from "classnames";
import { GButtonType } from "./GGroupButtons";
import makeStyles from "@mui/styles/makeStyles";


interface MakeStylesProps {
    useRememberTheme: boolean | undefined;
}
const useStyles = makeStyles((theme: Theme) => ({
    toggleBtn: (props: MakeStylesProps) => ({
        background: theme.palette.common.white,
        [`&.${toggleButtonClasses.selected}`]: {
            borderColor: props.useRememberTheme
                ? theme.palette.rememberSecondary.main
                : theme.palette.primary.main,
            color: theme.palette.common.white,
            background: `${props.useRememberTheme
                ? theme.palette.rememberSecondary.main
                : theme.palette.primary.main} !important`,
        }
    }),
}), { name: 'GToggleButtons' });

interface Props<T extends string> {
    buttons: GButtonType<T>[];
    selected: T;
    className?: string;
    disabled?: boolean;
    useRememberTheme?: boolean;
    sx?: SxProps<Theme>;
    onSelect: (value: T) => void;
    onContainerClick?: () => void;
}
const GToggleButtons = <T extends string>({
    buttons,
    selected,
    className,
    disabled,
    useRememberTheme,
    sx,
    onSelect,
    onContainerClick
}: Props<T>) => {
    const classes = useStyles({ useRememberTheme });

    return (
        <ToggleButtonGroup
            exclusive
            color={useRememberTheme ? 'rememberSecondary' : 'primary'}
            value={selected}
            className={className}
            disabled={disabled}
            onClick={onContainerClick}
            onChange={(_, value: T) => onSelect(value)}
            sx={sx}
        >
            {buttons.map(button =>
                <ToggleButton
                    color="secondary"
                    key={button.value}
                    value={button.value}
                    className={classNames(
                        classes.toggleBtn,
                        button.className,
                    )}
                >
                    {button.icon &&
                        <>{button.icon}&nbsp;</>
                    }
                    {button.label}
                </ToggleButton>
            )}
        </ToggleButtonGroup>
    );
};

export default GToggleButtons;