import * as React from 'react';
import momentTz from 'moment-timezone';
import classNames from 'classnames';
import { Location } from 'history';

import { emptyStringParser, getOverlappedEvents } from '../../../../services';

import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';

import { StoreState } from '../../../../types';
import {
    GatherEvent,
    EventType,
    LocationUX,
    LocationType,
    GoogleEvent,
    FeatureKey,
    GatherCaseUX,
    EventCreateRequest,
    EventUpdateRequest,
    GatherCasePublic,
    UserPatchRequest,
    CalendarViewType,
    TaskTemplateType,
    isGatherCaseUX,
} from '../../../../shared/types';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import AddLocationDialog from '../../../funeralHomes/addLocation/AddLocation.dialog';

import ConfirmationDialog from '../../../common/ConfirmationDialog';
import EventListByDate from '../../eventSlider/eventListByDate/EventListByDate';
import OverlappedEventMessage from '../../../common/OverlappedEventMessage';
import { isEnabled } from '../../../common/AppFeature';
import { updateEvent, addEvent, deleteEvent, setActiveEvent } from '../../../../actions/GatherEvent.action';
import { patchTeamMember } from '../../../../actions/Team.action';
import { isDateRangeValid } from '../../../../shared/utils';

import ExpandedDatesView from './ExpandedDatesView';
import CollapsedDatesView from './CollapsedDatesView';
import ConferenceVenueDetails from './ConferenceVenueDetails';
import EventNameAndAddress from './EventNameAndAddress';
import Footer from './Footer';
import SuccessScreen from './SuccessScreen';
import {
    filterLocations,
    generateEvent,
    isEventTypeArrangement,
} from './utils';
import { compose } from 'redux';
import { AppDispatch } from '../../../../store';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';
import withState from '../../../common/utilHOC/WithState';
import withGStyles from '../../../../styles/WithGStyles';
import withLocation from '../../../common/utilHOC/WithLocation';
import { Theme } from '@mui/material';
import { SlideTransition } from '../../../common/Transitions';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { withDialogMounter } from '../../../../DialogMounter';
import { LocationListChangeParam } from "./LocationList";
import GSwitch from '../../../common/inputElements/GSwitch';

function mapStateToProps(
    {
        locationState,
        casesState,
        eventState,
        funeralHomeState,
        userSession,
        tasksState
    }: StoreState,
    { selectedCase }: OwnProps
) {
    const { checklistTasks } = tasksState;
    const { gatherEvents, googleEvents, isSaving } = eventState;
    return {
        locations: locationState.locations,
        isEventSaving: isSaving,
        gatherEvents,
        googleEvents,
        isLiveStreamEnabled: isEnabled(FeatureKey.LIVE_STREAM, funeralHomeState, casesState, userSession),
        userData: userSession.userData,
        arrangementConferenceTask: checklistTasks.find(task =>
            task.template_type === TaskTemplateType.arrangement_conference
            && selectedCase.id === task.gather_case_id) || null
    };
}

interface OwnProps {
    zIndex: number;
    isDialogOpen: boolean;
    existingEvent?: GatherEvent;
    selectedCase: GatherCaseUX | GatherCasePublic;
    closeDialog: () => void;
    eventType?: EventType;
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    location: Location;
}

interface State {
    isFormChanged: boolean;
    thisEvent: EventCreateRequest;
    eventNameValidator: boolean | null;
    isAddLocationDialogOpen: boolean;
    selectedLocation: LocationUX | null;
    isEventSaving: boolean;
    isEventSaved: boolean;
    isDeleteConfirmationDialogOpen: boolean;
    overlappedEvents: (GatherEvent | GoogleEvent)[];
    isExpandedView: boolean;
    startTime: momentTz.Moment;
    endTime: momentTz.Moment;
    hasEndTimeError: boolean;
    hasTimeError: boolean;
    hideEndTimeFromRememberPage: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

export const styles: StyleRulesCallback<Theme, OwnProps> = theme => ({
    root: {
        position: 'relative',
        width: '100%',
        background: '#fff',
        zIndex: 0,
        boxShadow: '0 1px 10px 4px rgba(0, 0, 0, 0.2)',
        padding: '0',
        '&$isArrangementConference': {
            boxShadow: 'none'
        }
    },
    footerContainer: {
        paddingTop: 4,
        textAlign: 'center',
    },

    tabsContainer: {
        borderBottom: '1px solid rgba(0,0,0,0.21)',
        textAlign: 'center',
        '&$isArrangementConference': {
            boxShadow: '0px -4px 10px -2px rgb(0 0 0 / 20%)'
        }
    },
    editEventDialog: {
        '& $dialogPaper': {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            '@media (min-width: 960px)': {
                margin: 32,
                maxHeight: '90vh',
                maxWidth: 720,
            }
        },
    },
    dialogPaper: {},
    dialogTitle: {
        padding: 0,
        '&$arrangementTitle': {
            boxShadow: `0px 1px 10px 1px rgba(0, 0, 0, 0.2)`,
            textAlign: 'center',
            padding: 0,
            position: 'relative',
            zIndex: 1,
            '& $subHeading': {
                color: theme.palette.secondary.main,
                fontSize: 20,
                margin: '15px 34px',
                '@media (max-width: 400px)': {
                    fontSize: 16,
                }
            }
        },
        '& h2': {
            textAlign: 'center',
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        fontSize: 28,
        color: '#5B6E74',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    paddingBottom0: {
        paddingBottom: 0
    },
    fieldWidth: {
        minWidth: 280,
        maxWidth: 460,
        width: '100%'
    },
    padding_16_0: {
        padding: '16px 0 !important'
    },
    padding_8_0: {
        padding: '8px 0 !important'
    },
    arrangementTitle: {},
    subHeading: {},
    isArrangementConference: {}
});

type Classes = 'root' | 'paddingBottom0' | 'arrangementTitle'
    | 'tabsContainer' | 'footerContainer' | 'editEventDialog' | 'padding_16_0'
    | 'dialogPaper' | 'dialogTitle' | 'clearIcon' | 'fieldWidth'
    | 'subHeading' | 'isArrangementConference' | 'padding_8_0';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface DialogProps {
    fullScreen: boolean;
}

type CombinedProps = StyledProps & DialogProps & InjectedProps & OwnProps;
class ScheduleServicesDialog extends React.Component<CombinedProps, State> {
    protected eventNameInput: HTMLInputElement | undefined = undefined;

    constructor(props: CombinedProps) {
        super(props);

        const { userData, eventType } = props;
        const isExpandedView = !!(userData && userData.selected_calendar_view === CalendarViewType.expanded);

        const thisEvent = generateEvent(eventType || EventType.custom);

        this.state = {
            isFormChanged: false,
            isDeleteConfirmationDialogOpen: false,
            eventNameValidator: null,
            thisEvent,
            selectedLocation: null,
            isAddLocationDialogOpen: false,
            isEventSaving: false,
            isEventSaved: false,
            overlappedEvents: [],
            isExpandedView,
            startTime: momentTz(thisEvent.start_time),
            endTime: momentTz(thisEvent.end_time),
            hasTimeError: false,
            hasEndTimeError: false,
            hideEndTimeFromRememberPage: thisEvent.hide_end_time,
        };
    }

    componentDidMount() {
        const { existingEvent, locations, eventType } = this.props;
        this.loadLocations();

        if (existingEvent && existingEvent.start_time) {
            const eventLocation = locations.find((loc) =>
                loc.id === existingEvent.location_id
            );

            const timezone = eventLocation && eventLocation.address.timezone &&
                eventLocation.address.timezone || momentTz.tz.guess() || 'America/Boise';

            const startTime = momentTz.tz(existingEvent.start_time, timezone);
            const endTime = momentTz.tz(existingEvent.end_time, timezone);
            const hideEndTimeFromRememberPage = existingEvent.hide_end_time;

            this.setState({
                thisEvent: {
                    ...generateEvent(eventType || EventType.custom, existingEvent),
                    start_time: startTime.toDate(),
                },
                startTime,
                endTime,
                hideEndTimeFromRememberPage
            });
        }
    }

    componentDidUpdate(prevProps: CombinedProps, prevState: State) {
        const { startTime, endTime } = this.state;

        if (
            !prevState.startTime.isSame(startTime) ||
            !prevState.endTime.isSame(endTime)
        ) {
            // only call updateOverlappedEvents when necessary - time or date state changed
            this.updateOverlappedEvents();
        }
    }

    loadLocations = async () => {
        const { locations, existingEvent } = this.props;

        let selectedLocation: LocationUX | undefined;
        if (existingEvent) {
            selectedLocation = locations.find((loc) => loc.id === existingEvent.location_id);
        } else {
            const filteredLocations = filterLocations(locations);
            selectedLocation = filteredLocations.find((loc) => loc.location_type === LocationType.funeral_home)
                || filteredLocations[0];
        }
        this.setState({
            selectedLocation: selectedLocation || null,
        });
    };

    saveServiceSchedule = async () => {
        const {
            existingEvent,
            selectedCase,
            dispatch,
            eventType
        } = this.props;
        const { selectedLocation, startTime, endTime, hideEndTimeFromRememberPage, thisEvent } = this.state;

        if (!this.isFormValid()) {
            this.setState({ eventNameValidator: this.validateEventName() });
            if (this.eventNameInput) {
                this.eventNameInput.focus();
            }
            return;
        }

        this.setState({ isEventSaving: true });

        const locationId = selectedLocation ? selectedLocation.id : null;

        let newEvent: GatherEvent | null;

        if (existingEvent) {
            // update event
            const event: EventUpdateRequest = {
                ...thisEvent,
                location_id: locationId,
                start_time: startTime.toDate(),
                end_time: endTime.toDate(),
                hide_end_time: hideEndTimeFromRememberPage
            };
            newEvent = await dispatch(updateEvent(existingEvent.id, event, selectedCase.uuid));
        } else {
            // create new event
            const event: EventCreateRequest = {
                ...thisEvent,
                location_id: locationId,
                start_time: startTime.toDate(),
                end_time: endTime.toDate(),
                hide_end_time: hideEndTimeFromRememberPage
            };

            newEvent = await dispatch(addEvent(event, selectedCase.uuid));
        }

        this.setState({
            isEventSaved: newEvent !== null,
            isEventSaving: false
        });

        if (eventType !== EventType.arrangement) {
            this.props.closeDialog();
        }
    };

    handleDeleteEvent = async () => {
        const { dispatch, closeDialog, existingEvent, selectedCase } = this.props;

        this.closeDeleteConfirmationDialog();
        if (existingEvent) {
            const deletedEvent = await dispatch(deleteEvent(existingEvent, selectedCase));
            if (!deletedEvent) {
                return;
            }
        }

        closeDialog();
    };

    renderCollapsedDatesView = () => {
        const { startTime, endTime, hasEndTimeError, hasTimeError } = this.state;

        return (
            <CollapsedDatesView
                startTime={startTime}
                endTime={endTime}
                hasStartTimeError={hasTimeError}
                hasEndTimeError={hasEndTimeError}
                onEndTimeChange={this.handleEndTimeChange}
                onStartTimeChange={this.handleStartTimeChange}
                onDateChange={this.handleDateChange}
            />
        );
    };

    renderExpandedDatesView = () => {
        const {
            startTime,
            endTime,
        } = this.state;

        return (
            <ExpandedDatesView
                startTime={startTime}
                endTime={endTime}
                onDateChange={this.handleDateChange}
                onTimeChange={this.handleTimeChange}
            />
        );
    };

    renderVenueDetails = (isSuccessScreen?: boolean) => {
        const { isLiveStreamEnabled } = this.props;
        const {
            thisEvent,
            startTime,
            endTime,
            eventNameValidator,
            selectedLocation
        } = this.state;

        const hasEventNameError = !this.validateEventName() && eventNameValidator === false;

        return (
            <ConferenceVenueDetails
                openAddLocationDialog={this.openAddLocationDialog}
                selectedLocation={selectedLocation}
                isLiveStreamEnabled={isLiveStreamEnabled}
                eventStartTime={startTime}
                thisEvent={thisEvent}
                handleChangeIsPrivate={this.handleChangeIsPrivate}
                handleChangeIsStreamable={this.handleChangeIsStreamable}
                eventEndTime={endTime}
                hasEventNameError={hasEventNameError}
                isSuccessScreen={!!isSuccessScreen}
            />
        );
    };

    renderSuccessScreen = () => {
        const { zIndex, selectedCase, arrangementConferenceTask } = this.props;

        if (!isGatherCaseUX(selectedCase)) {
            return null;
        }

        return (
            <SuccessScreen
                renderVenueDetails={() => this.renderVenueDetails(true)}
                zIndex={zIndex + 1}
                selectedCase={selectedCase}
                editEventDetails={this.editEventDetails}
                arrangementConferenceTask={arrangementConferenceTask}
                setActiveEvent={this.setActiveEvent}
            />
        );
    };

    renderScheduleServicesForm = () => {
        const { classes, locations, existingEvent, selectedCase, zIndex } = this.props;
        const {
            selectedLocation,
            thisEvent,
            eventNameValidator,
            isEventSaved,
            overlappedEvents,
            isExpandedView,
            isEventSaving,
            isFormChanged,
            hideEndTimeFromRememberPage,
        } = this.state;

        const filteredLocations = filterLocations(locations);

        const isCaseSync = selectedCase.sync && selectedCase.sync.length > 0;
        const isPrivate = thisEvent.is_private;
        const isArrangementConference = isEventTypeArrangement(thisEvent);
        const isFormValid = this.isFormValid();

        return (
            <>
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.tabsContainer,
                        isArrangementConference && classes.isArrangementConference
                    )}
                >
                    {isExpandedView
                        ? this.renderExpandedDatesView()
                        : this.renderCollapsedDatesView()
                    }

                    <GSwitch
                        checked={hideEndTimeFromRememberPage}
                        onChangeCallBack={this.handleHideEndTimeChange}
                        label={`Hide this event’s end time from Remember Page`}
                        color='primary'
                        labelClasses={classes.colorPrimary}
                    />

                    <EventNameAndAddress
                        isExpandedView={isExpandedView}
                        caseFname={selectedCase.fname}
                        thisEvent={thisEvent}
                        eventNameValidator={eventNameValidator}
                        selectedLocation={selectedLocation}
                        filteredLocations={filteredLocations}
                        handleChangeAddressesEvent={this.handleChangeAddressesEvent}
                        handleViewChange={this._handleViewChange}
                        handleChangeNameEvent={this.handleChangeNameEvent}
                        handleEventNameKeyUpEvent={this.handleEventNameKeyUpEvent}
                        setInputRef={ref => this.eventNameInput = ref}
                        handleMessageForFamilyChange={this.handleMessageForFamilyChange}
                        zIndex={zIndex}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.footerContainer,
                        isEventSaved || existingEvent ? classes.paddingBottom0 : '',
                        isArrangementConference && classes.padding_16_0
                    )}
                >
                    {this.renderVenueDetails()}

                    <Footer
                        isCaseSync={isCaseSync}
                        isPrivate={isPrivate}
                        isEventSaved={isEventSaved}
                        isEventSaving={isEventSaving}
                        isFormChanged={isFormChanged}
                        isFormValid={isFormValid}
                        openDeleteConfirmationDialog={this.openDeleteConfirmationDialog}
                        thisEvent={thisEvent}
                        saveServiceSchedule={this.saveServiceSchedule}
                        existingEvent={existingEvent || null}
                    />

                    <OverlappedEventMessage overlappedEventsCount={overlappedEvents.length} />
                </Grid>
            </>
        );
    };

    render() {
        const {
            classes,
            selectedCase,
            isDialogOpen,
            closeDialog,
            fullScreen,
            zIndex,
        } = this.props;

        const {
            isAddLocationDialogOpen,
            selectedLocation,
            isDeleteConfirmationDialogOpen,
            isEventSaved,
            thisEvent,
            startTime,
            overlappedEvents
        } = this.state;

        const deleteDialogHeader = 'Are you sure?';
        const deleteDialogSubHeader = 'Deleting this event will remove it for everyone and cannot be undone.';
        const deleteDialogConfirmationButton = 'Delete Event';

        const isArrangementConference = isEventTypeArrangement(thisEvent);

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={closeDialog}
                className={classes.editEventDialog}
                classes={{
                    paper: classes.dialogPaper
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    className={classNames(
                        classes.dialogTitle,
                        isArrangementConference && classes.arrangementTitle,
                        isEventSaved && classes.padding_8_0
                    )}
                >
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={closeDialog}
                        tabIndex={-1}
                    />
                    {isArrangementConference
                        ? (isEventSaved
                            ? <FuneralHomeLogo />
                            : <Typography
                                variant="h5"
                                component="p"
                                className={classes.subHeading}
                            >
                                Schedule Arrangement Conference
                            </Typography>)
                        : <EventListByDate
                            selectedDate={startTime}
                            overlappedEventIds={overlappedEvents.map(evt => evt.id)}
                        />
                    }
                </DialogTitle>
                <DialogContent
                    className={classNames(
                        classes.root,
                        isArrangementConference && classes.isArrangementConference
                    )}
                >
                    <Grid container justifyContent="center">
                        {isEventSaved
                            ? this.renderSuccessScreen()
                            : <>
                                {isArrangementConference &&
                                    <EventListByDate
                                        selectedDate={startTime}
                                        overlappedEventIds={overlappedEvents.map(evt => evt.id)}
                                    />
                                }
                                {this.renderScheduleServicesForm()}
                            </>
                        }
                    </Grid>

                    <AddLocationDialog
                        isDialogOpen={isAddLocationDialogOpen}
                        closeDialog={this.handleAddLocationDialogClosed}
                        funeralHomeId={selectedCase.funeral_home.id}
                        existingLocation={selectedLocation || undefined}
                        isEventCreation
                        zIndex={zIndex + 1}
                    />

                    <ConfirmationDialog
                        header={deleteDialogHeader}
                        subHeader={deleteDialogSubHeader}
                        confirmationButtonText={deleteDialogConfirmationButton}
                        onClose={this.closeDeleteConfirmationDialog}
                        open={isDeleteConfirmationDialogOpen}
                        onConfirm={this.handleDeleteEvent}
                        zIndex={zIndex + 2}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    setActiveEvent = () => {
        const { dispatch, arrangementConferenceTask, closeDialog, existingEvent } = this.props;

        dispatch(setActiveEvent(
            (existingEvent && existingEvent.id)
            || (arrangementConferenceTask && arrangementConferenceTask.event_id)
            || null
        ));

        closeDialog();
    };

    handleMessageForFamilyChange = (message: string) => {
        this.setState(prevState => ({
            thisEvent: {
                ...prevState.thisEvent,
                message
            },
            isFormChanged: true
        }));
    };

    updateOverlappedEvents = () => {
        const { gatherEvents, googleEvents, existingEvent } = this.props;
        const { startTime, endTime } = this.state;

        let events = getOverlappedEvents([...gatherEvents, ...googleEvents], startTime, endTime);

        if (existingEvent) {
            events = events.filter((e) => e.id !== existingEvent.id);
        }

        this.setState({ overlappedEvents: events });
    };

    /**
     * validators start
     */
    validateEventName = (name: string = this.state.thisEvent.name) => {
        return emptyStringParser(name) ? true : false;
    };

    isFormValid = (): boolean => {
        const { startTime, endTime } = this.state;
        const isRangeValid = isDateRangeValid(startTime, endTime);
        const isEventNameValid = this.validateEventName();
        const valid = isEventNameValid && isRangeValid;

        return valid;
    };

    /**
     * validators end
     */

    handleChangeAddressesEvent = (event: React.ChangeEvent<{}>, location: LocationListChangeParam) => {
        event.preventDefault();

        this.setState({ isFormChanged: true });
        // find add location box
        if (location === 'addLocation') {
            this.setState({
                isAddLocationDialogOpen: true,
                selectedLocation: null,
            });
        } else {
            const selectedLocation = location;
            const tz = selectedLocation && selectedLocation.address.timezone
                || momentTz.tz.guess()
                || 'America/Boise';

            this.setState(
                { selectedLocation },
                () => this.updateTimezone(tz)
            );
        }
    };

    updateTimezone = (timezone: string) => {
        this.setState((prevState) => {
            // true === keep the local time the same
            const startTime = prevState.startTime.clone().tz(timezone, true);
            const endTime = prevState.endTime.clone().tz(timezone, true);

            return {
                startTime,
                endTime,
            };
        });
    };

    handleEventNameKeyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === 'enter') {
            const { isEventSaving, isFormChanged } = this.state;
            if (isEventSaving || !this.isFormValid() || !isFormChanged) {
                return;
            }
            this.saveServiceSchedule();
        }
    };

    handleChangeNameEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;

        this.setState((prevState) => ({
            isFormChanged: true,
            eventNameValidator: this.validateEventName(name),
            thisEvent: {
                ...prevState.thisEvent,
                name,
            },
        }));
    };

    handleChangeIsStreamable = (isChecked: boolean) => {
        this.setState((prevState) => ({
            isFormChanged: true,
            thisEvent: {
                ...prevState.thisEvent,
                is_streamable: isChecked,
            },
        }));
    };

    handleChangeIsPrivate = (isChecked: boolean) => {
        this.setState((prevState) => ({
            isFormChanged: true,
            thisEvent: {
                ...prevState.thisEvent,
                is_private: isChecked,
            },
        }));
    };

    handleDateChange = (newDate: momentTz.Moment) => {

        // start time
        const startOfExistingDate = this.state.startTime.clone().startOf('day');
        const existingStartTimeMins = this.state.startTime.diff(startOfExistingDate, 'minutes');
        const newStartTime = newDate.clone().add(existingStartTimeMins, 'minutes');

        // end time
        const existingDuration = this.state.endTime.diff(this.state.startTime, 'minutes');
        const newEndTime = newStartTime.clone().add(existingDuration, 'minutes');

        this.handleTimeChange({
            newStartTime,
            newEndTime,
        });
    };

    handleTimeChange = (params: {
        newStartTime?: momentTz.Moment;
        newEndTime?: momentTz.Moment;
    }) => {
        const { newEndTime, newStartTime } = params;

        this.setState((prevState) => {
            const isRangeValid = isDateRangeValid(
                newStartTime || prevState.startTime,
                newEndTime || prevState.endTime,
            );

            return {
                hasTimeError: !isRangeValid,
                isFormChanged: true,
                startTime: newStartTime || prevState.startTime,
                endTime: newEndTime || prevState.endTime,
            };
        });
    };

    handleEndTimeChange = (newEndTime: momentTz.Moment) => {
        this.handleTimeChange({ newEndTime });
    };

    handleStartTimeChange = (newStartTime: momentTz.Moment) => {
        this.handleTimeChange({ newStartTime });
    };

    handleHideEndTimeChange = (isChecked: boolean) => {
        this.setState({ hideEndTimeFromRememberPage: isChecked, isFormChanged: true });
    };

    editEventDetails = () => this.setState({ isEventSaved: false });

    openAddLocationDialog = () => this.setState({ isAddLocationDialogOpen: true });

    handleAddLocationDialogClosed = (savedLocation?: LocationUX) => {
        const timezone = savedLocation && savedLocation.address.timezone
            || momentTz.tz.guess()
            || 'America/Boise';

        this.setState(
            {
                isAddLocationDialogOpen: false,
                selectedLocation: savedLocation || null,
            },
            () => this.updateTimezone(timezone)
        );
    };

    closeDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: false,
        });
    };

    openDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: true,
        });
    };

    private _handleViewChange = () => {
        const { userData, dispatch, selectedCase } = this.props;
        this.setState(prevState => {
            const isExpandedView = !prevState.isExpandedView;

            if (userData !== null) {
                const selectedCalendarView = isExpandedView
                    ? CalendarViewType.expanded
                    : CalendarViewType.collapsed;
                const changes: UserPatchRequest = { selected_calendar_view: selectedCalendarView };
                dispatch(patchTeamMember(userData.id, userData.entity_id, changes, selectedCase.funeral_home.id));
            }

            return { isExpandedView };
        });
    };
}

export default compose(
    withState(mapStateToProps),
    withGStyles(styles),
    withFullScreen(),
    withLocation,
    withDialogMounter
)(ScheduleServicesDialog) as React.ComponentType<OwnProps>;
