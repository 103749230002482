import { Component } from 'react';
import classNames from 'classnames';
import Dinero from 'dinero.js';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import ClearIcon from '@mui/icons-material/Clear';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { Payer } from '.';
import { PaymentRequest } from '../../../../shared/types';
import { PatchPaymentParameters } from '../../../../actions/Finance.action';
import { formatDinero } from '../../../../shared/goods_and_services/pricing';
import { DateTimeFormat, joinNameParts } from '../../../../shared/utils';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';
import moment from 'moment-timezone';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        margin: 0,
        textAlign: 'center',
        overflow: 'hidden',
        marginBottom: 16,
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        '& $inner': {
            margin: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            '& p': {
                fontWeight: 200,
                fontSize: 14,
                textTransform: 'capitalize',
            }
        }
    },
    dialogPaper: {
        margin: 'auto',
        width: '100%',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        '@media (min-width: 600px)': {
            borderRadius: 4,
            width: 360,
            height: 'auto',
            maxHeight: 'calc(100% - 96px)',
            display: 'flex'
        },
    },
    textField: {
        width: 200,
        margin: '8px 0',
        '& label': {
            width: 'max-content',
        },
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    },
    datePickerTextField: {
        width: 200,
        zIndex: 1,
        '& input': {
            fontSize: 14,
        },
        '& input[type="date"]::-webkit-clear-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            display: 'none'
        }
    },
    headerContent: {
        textAlign: 'center',
        margin: '8px 0',
        color: theme.palette.common.white,
        '& p': {
            color: 'inherit',
            fontSize: 24,
        },
        '& svg': {
            color: 'inherit',
            fontSize: 88,
        }
    },
    footer: {
        margin: '0 12px',
        fontSize: 12,
        fontWeight: 200,
        '& a': {
            color: 'inherit'
        }
    },
    textArea: {
        width: 280,
        '& textarea': {
            minHeight: 43, // calculated as per the padding in mui
            maxHeight: 300
        },
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    total: {
        width: 120,
        borderTop: '1px solid',
        margin: '8px 0',
        padding: '8px 20px',
        textAlign: 'left',
    },
    clickable: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    buttonContainer: {
        margin: '24px 0 8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        '& button': {
            margin: 8,
        }
    },
    textAreaRoot: {},
    inner: {}
});

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'datePickerTextField'
    | 'dRoot' | 'header' | 'dialogContent' | 'clearIcon' | 'textField' | 'inner' | 'footer'
    | 'headerContent' | 'AttachMoneyIcon' | 'textArea' | 'textAreaRoot' | 'total'
    | 'clickable' | 'buttonContainer'>;

interface Props {
    isEditMode: boolean;
    isDialogOpen: boolean;
    paymentInfo: PaymentRequest;
    payer: Payer;
    caseUuid: string;
    patchPayment: (...params: PatchPaymentParameters) => void;
    closeDialog: (activeReceipt: number | null) => void;
    updatePayment: (paymentInfo: PaymentRequest) => void;
    openMySettingsDialog: () => void;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    removePayment: () => void;
    zIndex: number;
}

interface State {
    isLoading: boolean;
    isAmountValid: boolean;
}

const INITIAL_STATE = {
    amount: null,
    memo: null,
    isLoading: false,
    isAmountValid: true
};

class RecordPolicyPaymentDialog extends Component<StyledProps, State> {

    constructor(props: StyledProps) {
        super(props);
        this.state = INITIAL_STATE;
    }

    render() {
        const {
            classes,
            isDialogOpen,
            paymentInfo,
            payer,
            openMySettingsDialog,
            onAddNonUserContactInfoClick,
            isEditMode,
            removePayment,
            zIndex
        } = this.props;
        const { isAmountValid } = this.state;
        const { amount, memo, merch_fee, external_id, payment_date } = paymentInfo;

        const paymentDateString = moment(payment_date ?? new Date()).format(DateTimeFormat.ShortDate);

        const payerName = joinNameParts(payer.payerEntity);

        const payerEmail = payer.payerEntity.email;

        const payerPhone = payer.payerEntity.phone;

        const openEditUserDialog = () => !payerEmail && !payerPhone ?
            onAddNonUserContactInfoClick(payer.payerEntity.id) : openMySettingsDialog();

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.dRoot,
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.header,
                        classes.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon, classes.colorWhite)}
                        onClick={() => this.closeDialog()}
                    />
                    <div className={classes.headerContent}>
                        <AttachMoneyIcon />
                        <Typography>{isEditMode ? 'Adjust' : 'Record'} Payment on Policy</Typography>
                    </div>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid item xs={12} className={classes.inner}>
                        <Typography color="secondary">
                            Company:&nbsp;
                            <span
                                className={classNames(
                                    classes.fontWeight500,
                                    classes.clickable
                                )}
                                onClick={e => openEditUserDialog()}
                            >
                                {payerName}
                            </span>
                        </Typography>

                        <Typography color="secondary">
                            policy Number:&nbsp;
                            <span className={classes.fontWeight500}>
                                {external_id}
                            </span>
                        </Typography>

                        <Typography color="secondary">
                            Amount:&nbsp;
                            <span className={classes.fontWeight500}>
                                {formatDinero(amount)}
                            </span>
                        </Typography>

                        <Typography color="secondary">
                            fee:&nbsp;
                            <span
                                className={classNames(
                                    classes.fontWeight500,
                                    classes.textUppercase
                                )}
                            >
                                {formatDinero(merch_fee)}
                            </span>
                        </Typography>
                    </Grid>

                    <TextField
                        error={!isAmountValid}
                        required
                        label="Amount Received"
                        margin="dense"
                        variant="outlined"
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            classes: {
                                notchedOutline: 'notranslate'
                            }
                        }}
                        className={classes.textField}
                        value={amount.getAmount() / 100 || ''}
                        onChange={(e) => this.onChangeAmount(e.target.value)}
                        autoFocus
                    />

                    <Grid item xs={12}>
                        <TextField
                            id="paymentDate"
                            required
                            label="Payment Date"
                            value={paymentDateString}
                            onChange={e => this.onChangePaymentDate(e.target.value)}
                            type="date"
                            className={classes.datePickerTextField}
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            variant="outlined"
                        />
                    </Grid>

                    <TextField
                        label="Memo (optional)"
                        multiline
                        margin="dense"
                        variant="outlined"
                        value={memo || ''}
                        className={classes.textArea}
                        InputProps={{
                            classes: {
                                root: classes.textAreaRoot,
                                notchedOutline: 'notranslate'
                            }
                        }}
                        onChange={(e) => this.onChangeMemo(e.target.value)}
                    />

                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.buttonContainer,
                            isEditMode && classes.spaceBetween
                        )}
                    >
                        {isEditMode &&
                            <DisabledPermissionTooltip permission={Permission.REMOVE_PAYMENTS}>
                                {disabled =>
                                    <Button
                                        disabled={disabled}
                                        color="secondary"
                                        onClick={(e) => {
                                            removePayment();
                                            this.closeDialog();
                                        }}
                                    >
                                        remove payment
                                    </Button>
                                }
                            </DisabledPermissionTooltip>
                        }

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={e => this.patchPayment()}
                        >
                            <AttachMoneyIcon />&nbsp;{isEditMode ? 'update' : 'record'}&nbsp;
                            {amount.getAmount() !== 0 && <>{formatDinero(amount)}&nbsp;</>}payment
                            {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    validatePaymentInfo(paymentInfo: PaymentRequest) {
        const { amount } = paymentInfo;
        const isAmountValid = amount && !amount.isZero() && amount.isPositive();
        this.setState({ isAmountValid });
        return isAmountValid;
    }

    patchPayment() {
        const { closeDialog, paymentInfo, caseUuid } = this.props;
        const isAmountValid = this.validatePaymentInfo(paymentInfo);

        if (!isAmountValid || !paymentInfo.payment_id) {
            return;
        }

        this.props.patchPayment({ caseUuid, paymentInfo, collect: true, sendNotification: true });
        closeDialog(paymentInfo.payment_id);
    }

    onChangePaymentDate(paymentDateString: string) {
        const { paymentInfo, updatePayment } = this.props;
        let paymentDate = moment(paymentDateString);
        if (!paymentDate.isValid) {
            paymentDate = moment();
        }
        paymentInfo.payment_date = paymentDate;
        updatePayment(paymentInfo);
    }

    onChangeAmount(amount: string) {
        const { paymentInfo, updatePayment } = this.props;

        let newAmount = Math.round(parseFloat(amount) * 100);
        if (isNaN(newAmount)) {
            newAmount = 0;
        }
        paymentInfo.amount = Dinero({ amount: newAmount, currency: 'USD' });
        updatePayment(paymentInfo);
        this.validatePaymentInfo(paymentInfo);
    }

    onChangeMemo(memo: string) {
        const { paymentInfo, updatePayment } = this.props;
        paymentInfo.memo = memo;
        updatePayment(paymentInfo);
    }

    closeDialog = () => {
        this.props.closeDialog(null);
        this.setState(INITIAL_STATE);
    };
}

export default withGStyles(styles)(RecordPolicyPaymentDialog);
