import { EntitySummary, GatherEvent, LongAddress, RolodexContact, RolodexOrganization, UserProfile } from "../types";
import { FuneralHomeUX } from "../types/funeralHome";

export const emptyStringParser = (stringValue: string | null | undefined): null | string => {
    // return stringValue?stringValue.length === 0 ? null : stringValue.trim().length === 0 ? null : stringValue : null;
    return stringValue?.trim() || null;
};

interface DisplayAddress {
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    description: string | null;
    use_description: boolean | null;
    locationName: string | null;
    isEstablishment: boolean;
    county?: string | null;
}

export const getAddressString = (obj: DisplayAddress | null) => {
    if (!obj) {
        return null;
    }

    if (obj.use_description && obj.description && obj.description.trim()) {
        return obj.description.trim();
    }

    if (!obj.address1 && !obj.city && !obj.state) {
        return emptyStringParser(obj.description);
    }

    let address: string[] = [];

    if (obj.isEstablishment && obj.locationName && obj.locationName.trim()) {
        address.push(obj.locationName.trim());
    }

    if (obj.address1 && obj.address1.trim()) {
        address.push(obj.address1.trim());
        if (obj.address2 && obj.address2.trim()) {
            address.push(obj.address2.trim());
        }
    }

    if (obj.city && obj.city.trim()) {
        address.push(obj.city.trim());
    }

    if (obj.state && obj.state.trim()) {
        address.push(obj.state.trim());
    }

    if (obj.postalCode && obj.postalCode.trim()) {
        address.push(obj.postalCode.trim());
    }

    if (obj.county && obj.county.trim()) {
        address.push(obj.county.trim());
    }

    return emptyStringParser(address.join(', ').trim());
};

export const getFhAddress = (funeralHome: FuneralHomeUX | null): string | null => {
    if (!funeralHome) {
        return null;
    }
    return getAddressString({
        address1: funeralHome.address1,
        address2: funeralHome.address2 || null,
        city: funeralHome.city,
        state: funeralHome.state,
        postalCode: funeralHome.postal_code || null,
        description: funeralHome.long_address.description,
        use_description: funeralHome.use_address_description,
        isEstablishment: false,
        locationName: null,
    });
};

export const getUserAddress = (user: UserProfile | null): string | null => {
    if (!user) {
        return null;
    }
    return getAddressString({
        address1: user.address1,
        address2: user.address2 || null,
        city: user.city,
        state: user.state,
        postalCode: user.postal_code || null,
        description: user.address_description,
        use_description: user.use_address_description,
        isEstablishment: user.long_address && user.long_address.isEstablishment || false,
        locationName: user.long_address && user.long_address.locationName || null,
    });
};

export const getEntityHomeAddress = (entity: EntitySummary | null): string | null => {
    if (!entity || !entity.home_address) {
        return null;
    }
    return getAddressString({
        address1: entity.home_address.address1,
        address2: entity.home_address.address2 || null,
        city: entity.home_address.city,
        state: entity.home_address.state,
        postalCode: entity.home_address.postalCode,
        description: entity.home_address.description,
        use_description: entity.use_address_description,
        isEstablishment: entity.home_address && entity.home_address.isEstablishment || false,
        locationName: entity.home_address && entity.home_address.locationName || null,
    });
};

export const getAddressFromLongAddress = (longAddress: LongAddress | null): string | null => {
    if (!longAddress) {
        return null;
    }
    return getAddressString({
        address1: longAddress.address1 || null,
        address2: longAddress.address2 || null,
        city: longAddress.city || null,
        state: longAddress.state || null,
        postalCode: longAddress.postalCode || null,
        description: longAddress.description,
        use_description: false,
        isEstablishment: longAddress.isEstablishment || false,
        locationName: longAddress.locationName || null,
        county: longAddress.county || null
    });
};

export const getAddressFromGatherEvent = (event: GatherEvent | null): string | null => {
    if (!event) {
        return null;
    }
    return getAddressString({
        address1: event.address1 || null,
        address2: event.address2 || null,
        city: event.city || null,
        state: event.state || null,
        postalCode: event.postal_code || null,
        description: event.description,
        use_description: null,
        isEstablishment: false,
        locationName: event.location_name || null,
    });
};

export const getRolodexAddress = (rolodexEntity: RolodexOrganization | RolodexContact): string | null => {
    if (rolodexEntity.longAddress === null) {
        return null;
    }

    return getAddressString({
        address1: rolodexEntity.longAddress.address1,
        address2: rolodexEntity.longAddress.address2 || null,
        city: rolodexEntity.longAddress.city,
        state: rolodexEntity.longAddress.state,
        postalCode: rolodexEntity.longAddress.postalCode,
        description: rolodexEntity.longAddress.description,
        use_description: rolodexEntity.useAddressDescription,
        locationName: rolodexEntity.longAddress.locationName || null,
        isEstablishment: rolodexEntity.longAddress.isEstablishment || false,
    });
};
