import AccessRestrictedDialog from './dialogs/accessRestricted';
import HelperInvitationDialog from './dialogs/helperInvitation';
import TeamInvitationDialog from './dialogs/teamInvitation';
import MySettingsDialog from './dialogs/mySettings';
import { UserProfile, UserRoles } from '../shared/types';
import CreateRolodexDialog from './rolodex/edit/EditCreateRolodex.dialog';
import RolodexDialog from './rolodex/listing/Rolodex.dialog';
import AppOutdatedDialog from './dialogs/appOutdated/AppOutdated.dialog';
import ConfirmPhotoGlobalDeleteDialog from './profileImage/confirmGlobalPhotoDelete';
import FinalizeTaskDialog from './family/tasks/FinalizeTask.dialog';

interface Props {
    user: UserProfile | null;
}

const GlobalDialogs = (props: Props) => {
    const { user } = props;

    const isFHorGOM = UserRoles.isFHorGOMUser(user);
    return (
        <>
            <AccessRestrictedDialog />
            <HelperInvitationDialog />
            {isFHorGOM && <TeamInvitationDialog />}
            <MySettingsDialog />
            <CreateRolodexDialog />
            <RolodexDialog />
            <AppOutdatedDialog />
            <ConfirmPhotoGlobalDeleteDialog />
            <FinalizeTaskDialog />
        </>
    );
};

export default GlobalDialogs;
