import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
    ProductSummary,
    ProductTaxRateUX,
    ContractOptionsUX,
    ProductCategory,
    PricingModelEnum,
} from '../../../../shared/types';
import CreateOneTimeProductDialog from './CreateOneTimeProduct.dialog';
import VerticalReactVirtualizedList from '../../../common/reactVirtualized/VerticalReactVirtualizedList';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { calculateProductListPrice } from '../../../../shared/goods_and_services/pricing';
import { convertHexToRGBA, openInterCom } from '../../../../services';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import ListItemButton from '@mui/material/ListItemButton';
import FeatureDiscoverabilityPopper from '../../../common/FeatureDiscoverabilityPopper';
import { GStyles } from '../../../../styles/GStyles';

export type AddItemOrigin = ProductCategory | 'invoice' | 'contract_all';

interface Props {
    taxRates: ProductTaxRateUX[];
    productSummaries: ProductSummary[];
    contractOptions: ContractOptionsUX;
    isDialogOpen: boolean;
    addOrigin: AddItemOrigin;
    closeDialog: () => void;
    onSelectProduct: (
        product: ProductSummary,
        quantity: number,
        packageItemId?: number,
        allowanceItemId?: string,
        listPrice?: number,
        isAddedAsInvoice?: boolean,
    ) => void;
    onCreateOneTimeItem: (
        name: string,
        price: string,
        taxRateId: number | null,
        category: ProductCategory,
        isAddedAsInvoice: boolean,
    ) => void;
    zIndex: number;
}

interface State {
    searchText: string;
    isCreateOneTimeItemDialogOpen: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
                height: 'auto',
            },
        },
    },
    listContainer: {
        height: 'calc(100vh - 178px)',
        padding: 0,
        overflow: 'hidden',
        '@media (min-width: 480px)': {
            height: 'calc(80vh - 178px)',
            marginBottom: 52,
        },
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: 'auto',
        '@media (min-width: 480px)': {
            height: 'calc(80vh - 178px)',
            marginBottom: 32,
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
    },
    footer: {
        textAlign: 'right',
        width: 'auto',
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        },
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20,
        },
    },
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)',
    },
    sorryText: {
        marginTop: 20,
    },
    noResultsDivider: {
        margin: '8px 32px',
    },
    noWorriesText: {
        padding: '0 24px',
    },
    textUnderLine: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    listItem: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    displayIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    multiLine: {
        '& svg': {
            fontSize: 18,
            marginBottom: -3,
        },
    },
    rowColor: {
        background: convertHexToRGBA(theme.palette.secondary.main, 0.18),
    },
    dialogPaper: {},
});

type StyledProps = Props &
    WithStyles<
        | 'root'
        | 'dialogHeader'
        | 'dialogPaper'
        | 'dialogContent'
        | 'clearIcon'
        | 'footer'
        | 'searchField'
        | 'heading'
        | 'listContainer'
        | 'sorryText'
        | 'noResultsDivider'
        | 'noWorriesText'
        | 'noResultsFoundSection'
        | 'textUnderLine'
        | 'listItem'
        | 'displayIcon'
        | 'rowColor'
        | 'multiLine'
    >;

type CombinedProps = DialogProps & StyledProps;
class AddItemDialog extends React.Component<CombinedProps, State> {
    state: State = {
        searchText: '',
        isCreateOneTimeItemDialogOpen: false,
    };

    protected listScrollElRef = React.createRef<HTMLDivElement>();

    applySearchFilter = (products: ProductSummary[]) => {
        const { searchText } = this.state;
        const search = searchText.toLowerCase();

        return products.filter((product) => {
            const name = product.name.toLowerCase();
            const manufacturer = (product.manufacturer_name || '').toLowerCase();
            const modelNumber = (product.model_number || '').toLowerCase();
            const price = calculateProductListPrice(product, 0);
            const formattedPrice = price && price.toFormat('$0,0.00');
            const tagKeys = Object.keys(product.tags);
            const tagValues = tagKeys.map((key) => product.tags[key]);
            const isManual = product.pricing_model === PricingModelEnum.manual;

            return (
                name.includes(search) ||
                search.includes(name) ||
                (isManual && (search.includes('specify price') || 'specify price'.includes(search))) ||
                (manufacturer && (manufacturer.includes(search) || search.includes(manufacturer))) ||
                (price && price.toFormat('$00,0.00').includes(search)) ||
                (modelNumber && modelNumber.startsWith(search)) ||
                (formattedPrice && formattedPrice.includes(search)) ||
                tagValues.find(
                    (value) => value.toLowerCase().includes(search) || search.includes(value.toLowerCase()),
                ) ||
                tagKeys.find((key) => key.toLowerCase().includes(search) || search.includes(key.toLowerCase()))
            );
        });
    };

    renderNoResultsFoundSection = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography color="secondary" component="p" className={classes.sorryText}>
                    Sorry, no results found.
                </Typography>
                <Divider className={classes.noResultsDivider} />
                <Typography color="secondary" component="p" className={classes.noWorriesText}>
                    No worries, simply&nbsp;
                    <span className={classes.textUnderLine} onClick={this.openCreateOneTimeItemDialogOpen}>
                        click here
                    </span>
                    &nbsp;to create a new product for this statement.
                </Typography>
            </Grid>
        );
    };

    renderProduct = (product: ProductSummary) => {
        const { classes } = this.props;

        const price = calculateProductListPrice(product, 0);

        return (
            <DisabledPermissionTooltip key={product.id} permission={Permission.ADD_ITEMS}>
                {(disabled) => (
                    <ListItemButton
                        disabled={disabled}
                        key={product.id}
                        onClick={(e) => this.handleSelectProduct(product)}
                        divider
                        className={classNames(classes.listItem, product.is_hidden && classes.rowColor)}
                    >
                        <ListItemText
                            classes={{
                                primary: GStyles.fontWeight300,
                                secondary: GStyles.fontWeight300,
                            }}
                            primary={
                                <div className={product.is_hidden ? classes.multiLine : classes.displayIcon}>
                                    {product.is_hidden && (
                                        <>
                                            {' '}
                                            <VisibilityOffIcon color="primary" />
                                            &nbsp;
                                        </>
                                    )}
                                    {product.name}
                                </div>
                            }
                            secondary={
                                'Price: ' +
                                (product.pricing_model === PricingModelEnum.manual
                                    ? 'Specify Price'
                                    : (price && price.toFormat('$0,0.00')) || '')
                            }
                        />

                        {product.manufacturer_name && (
                            <Typography color="secondary" className={GStyles.fontWeight300}>
                                Manufacturer: {product.manufacturer_name}
                            </Typography>
                        )}
                        {Object.keys(product.tags).map(
                            (key, idx) =>
                                idx <= 3 && (
                                    <Typography color="secondary" key={key} className={GStyles.fontWeight300}>
                                        {key}: {product.tags[key]}
                                    </Typography>
                                ),
                        )}
                    </ListItemButton>
                )}
            </DisabledPermissionTooltip>
        );
    };

    renderProducts = () => {
        const { classes, productSummaries } = this.props;

        const filteredProducts = this.applySearchFilter(productSummaries);
        if (filteredProducts.length === 0) {
            return this.renderNoResultsFoundSection();
        }

        return (
            <div ref={this.listScrollElRef}>
                <List component="nav" className={classes.listContainer}>
                    <Divider sx={{ backgroundColor: 'transparent' }} />
                    <VerticalReactVirtualizedList
                        data={filteredProducts}
                        defaultCellHeight={67}
                        scrollElement={this.listScrollElRef.current || undefined}
                        render={this.renderProduct}
                    />
                </List>
            </div>
        );
    };

    render() {
        const { classes, taxRates, contractOptions, fullScreen, isDialogOpen, addOrigin, zIndex } = this.props;
        const { searchText, isCreateOneTimeItemDialogOpen } = this.state;

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    style={{ zIndex }}
                    classes={{ paper: classes.dialogPaper }}
                    TransitionProps={{
                        onEntered: (node) => this.forceUpdate(),
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(classes.dialogHeader, GStyles.backgroundPrimary)}
                    >
                        <Clear className={classNames(classes.clearIcon)} onClick={this.handleClose} />
                        <Typography component="p" className={classNames(classes.heading)} align="left">
                            <span>What would you like to add?</span>
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container>
                            <Grid item xs={12} className={GStyles.textCenter}>
                                <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <TextField
                                        autoComplete="off"
                                        className={classes.searchField}
                                        fullWidth
                                        autoFocus
                                        value={searchText}
                                        id="input-with-icon-textfield"
                                        placeholder="Start typing to filter list..."
                                        name="searchText"
                                        onChange={this.handleChangeEvent}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </form>
                                {this.renderProducts()}
                            </Grid>

                            <Grid item className={classes.footer}>
                                <FeatureDiscoverabilityPopper
                                    zIndex={zIndex + 1}
                                    infoText={
                                        <>
                                            One-Time Items are only added to this statement and can not be added to
                                            future statements. If you want to have this item all future statements and
                                            keep accounting consistent we recommend adding it to your GPL.{' '}
                                            <span className={GStyles.textClickable} onClick={openInterCom}>
                                                Click here
                                            </span>{' '}
                                            to chat with us to have us do that for you.
                                        </>
                                    }
                                />

                                <DisabledPermissionTooltip permission={Permission.CONTRACT_ONE_OFF_ITEMS}>
                                    {(disabled) => (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={disabled}
                                            sx={{ m: 1 }}
                                            onClick={this.openCreateOneTimeItemDialogOpen}
                                        >
                                            <PlaylistAddIcon />
                                            &nbsp;Add a one-time product
                                        </Button>
                                    )}
                                </DisabledPermissionTooltip>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <CreateOneTimeProductDialog
                    zIndex={zIndex + 1}
                    taxRates={taxRates}
                    contractOptions={contractOptions}
                    isDialogOpen={isCreateOneTimeItemDialogOpen}
                    closeDialog={this.closeCreateOneTimeItemDialogOpen}
                    onCreate={this.handleCreate}
                    productName={searchText}
                    productCategory={addOrigin === 'invoice' || addOrigin === 'contract_all' ? null : addOrigin}
                />
            </>
        );
    }

    handleCreate = (name: string, price: string, taxRateId: number | null, category: ProductCategory) => {
        const { onCreateOneTimeItem, addOrigin } = this.props;

        onCreateOneTimeItem(name, price, taxRateId, category, addOrigin === 'invoice');
        this.handleClose();
    };

    openCreateOneTimeItemDialogOpen = () => {
        this.setState({
            isCreateOneTimeItemDialogOpen: true,
        });
    };

    closeCreateOneTimeItemDialogOpen = () => {
        this.setState({
            isCreateOneTimeItemDialogOpen: false,
        });
    };

    handleSelectProduct = (product: ProductSummary) => {
        const { onSelectProduct, addOrigin } = this.props;

        const quantity = product.base_quantity || product.var_default_quantity || 1;
        onSelectProduct(product, quantity, undefined, undefined, undefined, addOrigin === 'invoice');
        this.handleClose();
    };

    handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;

        this.setState((prevState) => ({
            ...prevState,
            [name]: target.value,
        }));
    };

    handleClose = () => {
        this.setState({ searchText: '' });
        this.props.closeDialog();
    };
}

export default withFullScreen()(withStyles(styles)(AddItemDialog));
