import { deleteFromAPI, getFromAPI, patchAPI, postToAPI } from '.';
import {
    MemoryCreateRequest,
    MemoryCreateResponse,
    MemoryPrompt,
    MemoryUX,
    MemoryUpdateRequest,
    ModerationDecisionRequest,
    ModerationCategory,
    ModerationStatus,
} from '../shared/types';

import { registerAppError } from './errors';
import { makeModerationDecision } from '../api/Moderation.api';
import { setSnackbarSuccess } from './AppSnackbar.action';
import { AppDispatch } from '../store';
import { StoreState } from '../types';

export const NEW_MEMORY = 'NEW_MEMORY';
export type NEW_MEMORY = typeof NEW_MEMORY;

interface AddNewMemory {
    type: NEW_MEMORY;
    total: number;
    newMemory: MemoryUX;
}

export function addNewMemory(total: number, newMemory: MemoryUX): AddNewMemory {
    return {
        type: NEW_MEMORY,
        total,
        newMemory
    };
}

export const MEMORY_UPDATED = 'MEMORY_UPDATED';
export type MEMORY_UPDATED = typeof MEMORY_UPDATED;

interface UpdateMemory {
    type: MEMORY_UPDATED;
    updatedMemory: MemoryUX;
}

export function updateMemory(updatedMemory: MemoryUX): UpdateMemory {
    return {
        type: MEMORY_UPDATED,
        updatedMemory
    };
}

export const MEMORIES_LOADED = 'MEMORIES_LOADED';

interface MemoriesLoaded {
    type: typeof MEMORIES_LOADED;
    memories: MemoryUX[];
}

function memoriesLoaded(
    memories: MemoryUX[],
): MemoriesLoaded {
    return {
        type: MEMORIES_LOADED,
        memories,
    };
}

export const MEMORY_REMOVED = 'MEMORY_REMOVED';
export type MEMORY_REMOVED = typeof MEMORY_REMOVED;

interface RemoveMemory {
    type: MEMORY_REMOVED;
    memory: MemoryUX;
}

function removeMemory(memory: MemoryUX): RemoveMemory {
    return {
        type: MEMORY_REMOVED,
        memory
    };
}

export function loadMemories(caseName: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<MemoryUX[] | null> => {
        const { userSession } = getState();
        let path = '';
        if (userSession.userData) {
            path = `api/remember/${caseName}/memory/`;
        } else {
            path = `app/remember/${caseName}/memory/`;
        }

        const memories = await getFromAPI<MemoryUX[]>(path, dispatch);

        if (!memories) {
            return null;
        }

        dispatch(memoriesLoaded(memories));

        return memories;
    };
}

export function createMemory(caseName: string, memoryContent: string, prompt: MemoryPrompt = MemoryPrompt.Default) {
    return async (dispatch: AppDispatch): Promise<MemoryUX | null> => {
        const newMemoryData = { memory_text: memoryContent, prompt };

        try {
            MemoryCreateRequest.fromRequest(newMemoryData);

            const newMemory = await postToAPI<MemoryCreateResponse>(
                `api/remember/${caseName}/memory`,
                { memory: newMemoryData },
                dispatch
            );

            if (newMemory === null) {
                dispatch(registerAppError('Failed to create memory!'));
                return null;
            }

            const { total, memory } = newMemory;
            dispatch(addNewMemory(total, memory));

            return memory;
        } catch (error) {
            console.warn('Failed to validate MemoryCreateRequest:', newMemoryData, error);
            return null;
        }
    };
}

export function deleteCaseMemory(caseName: string, memoryId: number) {
    return async (dispatch: AppDispatch): Promise<MemoryUX | null> => {
        const deletedMemory = await deleteFromAPI<MemoryUX>(
            `api/remember/${caseName}/memory/${memoryId}`,
            dispatch
        );

        if (deletedMemory === null) {
            dispatch(registerAppError('Failed to delete memory!'));
            return null;
        }

        dispatch(removeMemory(deletedMemory));

        return deletedMemory;
    };
}

export function blockCaseMemory(params: { caseId: number; caseUuid: string; memory: MemoryUX; funeralHomeId: number }) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { caseId, caseUuid, memory, funeralHomeId } = params;
        const decisionRequest: ModerationDecisionRequest = {
            category: ModerationCategory.memories,
            items: [{
                id: memory.id,
                case_id: caseId,
            }],
            reason: '',
            approval_status: ModerationStatus.blocked,
        };

        const success = await dispatch(makeModerationDecision({
            decisionRequest,
            funeralHomeId,
            caseUuid,
        }));

        if (!success) {
            dispatch(registerAppError('Failed to block memory!'));
            return;
        }

        dispatch(setSnackbarSuccess('Memory has been blocked'));
        dispatch(removeMemory(memory));
    };
}

export function updateCaseMemory(caseName: string, memoryId: number, memoryText: string) {
    return async (dispatch: AppDispatch): Promise<MemoryUX | null> => {
        const memoryData = { memory_text: memoryText };

        try {
            MemoryUpdateRequest.fromRequest(memoryData);

            const updatedMemory = await patchAPI<MemoryUX>(
                `api/remember/${caseName}/memory/${memoryId}`,
                { memory: memoryData },
                dispatch
            );

            if (updatedMemory === null) {
                dispatch(registerAppError('Failed to update memory!'));
                return null;
            }

            dispatch(updateMemory(updatedMemory));
            return updatedMemory;
        } catch (error) {
            console.warn('Failed to validate MemoryUpdateRequest:', memoryData, error);
            return null;
        }
    };
}

export type MemoryAction = AddNewMemory
| UpdateMemory
| RemoveMemory
| MemoriesLoaded;
