import { Component } from 'react';
import classNames from 'classnames';
import * as appService from '../../../../services/app.service';
import SwipeableViews from 'react-swipeable-views';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Clear from '@mui/icons-material/Clear';

import { styleWrapper as TASKDIALOGSSTYLES } from '../TaskDialogsStyles';
import IconButton from '@mui/material/IconButton';
import { TaskDialogComponentProps } from '../TaskDialog';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';

import FRInitialContent from './FR.InitialContent';
import FRQuestionOne from './FR.QuestionOne';
import FRQuestionTwo from './FR.QuestionTwo';
import NotQualified from './NotQualified';
import FRQuestionThree from './FR.QuestionThree';
import FRQuestionFour from './FR.QuestionFour';
import FRQualified from './FR.Qualified';

type Props = TaskDialogComponentProps;

interface State {
    isInitialState: boolean;
    stepState: {
        active: number;
        prev: number | null;
    };
}

interface DialogProps {
    fullScreen: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent'
    | 'logo' | 'clearIcon' | 'textTransformNone' | 'slideClassName' | 'initialStateContainer' | 'button'
    | 'borderBottom' | 'secondStateIcon' | 'cancelAccConfirmationBtn' | 'secondStateMainGrid'
    | 'rootVideoSection' | 'videoSection' | 'detailBoxHeader' | 'detailBox' | 'paddingRight20'>;

type CombinedProps = DialogProps & StyledProps;

class FuneralReimbursementDialog extends Component<CombinedProps, State> {
    state: State = {
        isInitialState: true,
        stepState: {
            active: 0,
            prev: null
        }
    };

    protected topscroll: HTMLDivElement | null = null;

    componentDidUpdate() {
        setTimeout(
            () => {
                if (!this.topscroll) {
                    return;
                }

                this.topscroll.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            },
            50
        );
    }

    registerTopscroll = (topscroll: HTMLDivElement) => {
        this.topscroll = topscroll;
    };

    openInterCom = () => {
        appService.setElementStyleById('intercom-container', 'z-index', '1400');
        appService.setElementStyleByName('intercom-launcher-frame', 'z-index', '1400');
        appService.showIntercom();
    };

    renderInitialTitle = () => {
        const { classes } = this.props;

        return (
            <Typography
                color="secondary"
                align="left"
                className={classNames(classes.fontSize20, classes.paddingRight20)}
            >
                COVID-19 Funeral Reimbursement
            </Typography>
        );
    };

    renderBackArrowButton = () => {
        return (
            <IconButton onClick={() => this.goBack()} size="large">
                <ArrowBackIcon color="primary" />
            </IconButton >
        );
    };

    gotoStep = (step: number) => {
        this.setState((prevState) => ({
            ...prevState,
            stepState: {
                prev: prevState.stepState.active,
                active: step
            }
        }));
    };

    goBack = () => {
        this.setState((prevState) => {
            const prev = !prevState.stepState.prev
                ? prevState.stepState.active === 2
                    ? prevState.stepState.active - 1
                    : prevState.stepState.active - 2
                : prevState.stepState.prev;

            return {
                ...prevState,
                stepState: {
                    prev: prev !== null ? null : prev - 2,
                    active: prev < 0 ? 0 : prev
                }
            };
        });
    };

    render() {
        const {
            classes,
            fullScreen,
            isDialogOpen,
            closeDialog,
            zIndex
        } = this.props;
        const { stepState } = this.state;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                keepMounted
                onClose={() => closeDialog()}
                className={classes.root}
                classes={{
                    paper: classes.dialogPaper,
                }}
                style={{ zIndex: zIndex + 1 }}
            >
                <DialogTitle
                    className={classes.dialogHeader}
                >
                    <Clear
                        color="secondary"
                        className={classes.clearIcon}
                        onClick={() => closeDialog()}
                    />
                    {stepState.active === 0 ? this.renderInitialTitle() : this.renderBackArrowButton()}
                </DialogTitle>

                <DialogContent className={classNames(classes.dialogContent)}>
                    <div ref={this.registerTopscroll} />
                    <Grid container>
                        <SwipeableViews
                            axis="x"
                            index={stepState.active}
                            enableMouseEvents={false}
                            disabled
                            slideClassName={classes.slideClassName}
                        >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(step => (
                                <div key={step} style={{ height: step === stepState.active ? 'auto' : 0 }}>
                                    {this.getActiveRenderStep(step)}
                                </div>
                            ))}
                        </SwipeableViews>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    getActiveRenderStep = (step: number) => {
        const { userData, task, activeCase, zIndex, classes } = this.props;

        switch (step) {
            case 1:
                return <FRQuestionOne
                    caseFName={activeCase.fname}
                    onStepChange={this.gotoStep}
                />;

            case 2:
                return <NotQualified goBack={this.goBack}>
                    It is possible to change or amend a death certificate. This process starts
                    with contacting the person who certified the death. This may be a treating
                    doctor, a coroner or a medical examiner, and their name and address is on the death
                    certificate. Applicants may present evidence to them to support the claim the death
                    was attributable to COVID-19. If you believe you are able to amend the death
                    certificate, click the "Return to previous question" button above to continue.
                </NotQualified>;

            case 3:
                return <FRQuestionTwo
                    caseFName={activeCase.fname}
                    onStepChange={this.gotoStep}
                />;

            case 4:
                return <NotQualified goBack={this.goBack}>
                    The death must have occurred in the United States, including the U.S. territories, or
                    the District of Columbia after January 20, 2020. If these conditions aren't met, you
                    do not currently qualify.inue.
                </NotQualified>;

            case 5:
                return <FRQuestionThree onStepChange={this.gotoStep} />;

            case 6:
                return <NotQualified goBack={this.goBack}>
                    According to the program guidelines, the person applying for reimbursement must be a
                    U.S. citizen, non-citizen national, or qualified alien who paid for funeral expenses.
                </NotQualified>;

            case 7:
                return <FRQuestionFour caseFName={activeCase.fname} onStepChange={this.gotoStep} />;

            case 8:
                return <NotQualified goBack={this.goBack}>
                    <span>
                        Expenses paid for with burial/funeral insurance, or a pre-paid funeral, are considered
                        a duplication of benefits and therefore, are not eligible for reimbursement under this
                        program. Any source of payment designated specifically to pay for a funeral in
                        anticipation of a future death cannot be reimbursed under this assistance. This includes
                        burial or funeral insurance, a pre-paid funeral contract, a pre-paid trust for funeral
                        expenses, or an irrevocable trust for Medicare.
                    </span>
                    <Typography
                        color="secondary"
                        align="left"
                        component="span"
                        className={classNames(
                            classes.displayBlock,
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Life insurance proceeds are not considered a duplication of Funeral Assistance
                        benefits and are eligible for reimbursement under this program.
                    </Typography>
                </NotQualified>;

            case 9:
                return <FRQualified activeCase={activeCase} />;

            default:
                return <FRInitialContent
                    userData={userData}
                    activeCase={activeCase}
                    zIndex={zIndex}
                    task={task || null}
                    onStepChange={this.gotoStep}
                />;
        }
    };
}

export default withGStyles(TASKDIALOGSSTYLES<StyledProps>())(withFullScreen()(FuneralReimbursementDialog));