import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import makeGStyles from '../../../../styles/makeGStyles';

interface Props extends StandardTextFieldProps {
    canUserEditContract?: boolean;
    startAdornment?: JSX.Element;
    rootClass?: string;
    counter: number;
    increment?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
    widgetClasses?: {
        root?: string;
        textField?: string;
        fab?: string;
        inputRoot?: string;
        helperText?: string;
        underline?: string;
    };
    fabLabel?: {
        decrease: JSX.Element;
        increase: JSX.Element;
    };
    hideIncrementors?: boolean;
    hasPlusAdornment?: boolean;
    useUncontrolled?: boolean;
    onCounterChange: (value: number) => void;
}

const useStyles = makeGStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '4px auto 0',
    },
    container: {
        margin: '0 auto 8px',
        position: 'relative',
        width: 'fit-content',
    },
    fab: {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        filter: ` drop-shadow(0 0px 2px ${theme.palette.primary.main}) `,
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2),' +
            '0px 2px 2px 0px rgba(0,0,0,0.14), ' +
            '0px 3px 1px -2px rgba(0,0,0,0.12)',
        opacity: 1,
        height: 30,
        width: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.675,
        },
        '& span': {
            fontSize: 18
        },
        '&$restricted': {
            opacity: '0 !important',
            pointerEvents: 'none',
            boxShadow: 'none',
            background: 'transparent',
            color: 'transparent'
        }
    },
    textField: {
        width: 70,
        '& $inputUnderline': {
            '&:hover:before': {
                color: 'inherit',
                borderBottom: '2px solid !important',
            },
            '&:before': {
                color: 'inherit',
                borderBottom: '1px solid',
            },
            '&:after': {
                color: 'inherit',
                borderBottom: '2px solid',
            }
        },
        '& $inputRoot': {
            color: 'inherit',
        },
        '& $input': {
            fontSize: 30,
            fontWeight: 200,
            padding: 0,
            textAlign: 'center',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
        '& $helperText': {
            textAlign: 'center',
            fontSize: 10,
            marginTop: 4,
            fontWeight: 200,
            color: 'inherit'
        }
    },
    hasPlusAdornment: {
        '& input': {
            marginLeft: '-8px !important'
        }
    },
    restricted: {},
    helperText: {},
    input: {},
    inputRoot: {},
    inputUnderline: {},
}), { name: 'IncreaseDecreaseTextField' });

const IncreaseDecreaseTextField = (props: Props) => {
    const {
        rootClass,
        counter,
        increment = 1,
        min = 1,
        max,
        disabled,
        useUncontrolled,
        onCounterChange,
        startAdornment,
        widgetClasses,
        fabLabel,
        hasPlusAdornment,
        canUserEditContract,
        hideIncrementors,
        ...others
    } = props;

    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (!inputRef.current || !useUncontrolled) {
            return;
        }

        inputRef.current.value = (counter || '').toString();
    }, [counter, useUncontrolled]);

    const isRestricted = (canUserEditContract !== undefined && canUserEditContract === false);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={
                rootClass
                || widgetClasses && widgetClasses.root
                || classes.container
            }
        >
            <Grid
                item
                xs={12}
                className={classes.root}
            >
                {!hideIncrementors &&
                    <div
                        className={classNames(
                            classes.fab,
                            (disabled || counter === min) && classes.opacity50,
                            widgetClasses && widgetClasses.fab,
                            isRestricted && classes.restricted
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (!disabled && counter !== min) {
                                onCounterChange(counter - increment <= min ? min : counter - increment);
                            }
                        }}
                    >
                        {fabLabel && fabLabel.decrease || <RemoveIcon />}
                    </div>
                }
                <TextField
                    {...others}
                    type="number"
                    inputRef={inputRef}
                    value={useUncontrolled ? undefined : counter ?? ''}
                    className={classNames(
                        classes.textField,
                        widgetClasses && widgetClasses.textField,
                        hasPlusAdornment && classes.hasPlusAdornment
                    )}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            root: classNames(classes.inputRoot, widgetClasses && widgetClasses.inputRoot),
                            underline: classNames(widgetClasses?.underline, classes.inputUnderline)
                        },
                        startAdornment,
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: classNames(classes.helperText, widgetClasses && widgetClasses.helperText)
                        }
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(evt) => onCounterChange(Number(evt.target.value))}
                    onKeyDown={e => {
                        if (e.keyCode === 187
                            || e.keyCode === 107
                            || e.keyCode === 16
                            || e.which === 187
                            || e.which === 107
                            || e.which === 16
                            || e.key.toLowerCase() === 'e') {
                            e.preventDefault();
                            if (inputRef.current) {
                                inputRef.current.value = (counter || '').toString();
                            }
                        }
                    }}
                    disabled={isRestricted || disabled}
                />
                {!hideIncrementors &&
                    <div
                        className={classNames(
                            classes.fab,
                            (disabled || max && counter >= max) && classes.opacity50,
                            widgetClasses && widgetClasses.fab,
                            isRestricted && classes.restricted
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (!disabled && counter !== max) {
                                onCounterChange(max && (counter + increment >= max) ? max : counter + increment);
                            }
                        }}
                    >
                        {fabLabel && fabLabel.increase || <AddIcon />}
                    </div>
                }
            </Grid>
        </Grid>
    );
};

export default IncreaseDecreaseTextField;
