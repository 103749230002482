import { useRef, useMemo, useCallback, useEffect } from 'react';

import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { Grid, GridCellProps } from 'react-virtualized/dist/commonjs/Grid';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';

import makeStyles from '@mui/styles/makeStyles';

interface Props<T> {
    data: Array<T>;
    render: (item: T, measure: () => void) => JSX.Element;
    itemWidth: number;
    windowWidth: number;
    defaultCellHeight: number;
    firstChild?: JSX.Element;
}

const useStyles = makeStyles({
    root: {
        overflowX: 'hidden !important' as 'hidden',
        '&:focus': {
            outline: 'none !important',
        },
    },
}, { name: 'GridReactVirtualizedList' });

function GridReactVirtualizedList<T>(props: Props<T>) {
    const { data, itemWidth, windowWidth, firstChild, render, defaultCellHeight } = props;

    const classes = useStyles();

    const gridRef = useRef<Grid>(null);
    const windowScrollRef = useRef<WindowScroller>(null);

    const cache = useMemo(
        () => new CellMeasurerCache({
            defaultHeight: defaultCellHeight,
            fixedWidth: true
        }),
        [defaultCellHeight]
    );

    /**
     * This function will recalculate the 
     * Grid Size so that the design remains
     * consistent when CRUD operations are performed
     */
    const recomputeGridSize = useCallback(() => {
        gridRef.current?.forceUpdate();
    }, []);

    useEffect(() => {
        const timer = setTimeout(
            () => windowScrollRef.current && windowScrollRef.current.updatePosition(),
            400
        );

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => recomputeGridSize(), [data, recomputeGridSize]);

    /**
     * CellMeasurer is used to auto calculate the
     * dimensions of item and it stores the data in cache 
     */
    const cellRenderer = ({ columnIndex, key, parent, rowIndex, style }: GridCellProps) => {
        const cellColumnCount = Math.floor(windowWidth / itemWidth) || 1;
        const index = columnIndex + (rowIndex * cellColumnCount) || 0;
        const itemIndex = firstChild ? index - 1 : index;
        const item = data[itemIndex < 0 ? 0 : itemIndex];

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={columnIndex}
                key={key}
                parent={parent}
                rowIndex={rowIndex}
            >
                {({ measure, registerChild }) => (
                    <div
                        ref={node => node && registerChild ? registerChild(node) : undefined}
                        style={{ ...style, width: itemWidth, listStyleType: 'none' }}
                    >
                        {firstChild && index === 0 && firstChild}
                        {index === 0 && firstChild ? undefined : item && render(item, measure)}
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const itemCount = data.length + (firstChild ? 1 : 0);
    const columnCount = itemCount < 3 && itemCount || Math.floor(windowWidth / itemWidth) || 1;
    const rowCount = Math.ceil(itemCount / columnCount);

    /**
     * Window Scroller will help to scroll the Grid items
     * using body scrollbar
     */
    return (
        <WindowScroller
            onResize={(params) => recomputeGridSize()}
            ref={windowScrollRef}
        >
            {({ height, width, onChildScroll, scrollTop, isScrolling }) => (
                <Grid
                    className={classes.root}
                    columnCount={columnCount}
                    columnWidth={itemWidth}
                    overscanRowCount={0}
                    overscanColumnCount={0}
                    deferredMeasurementCache={cache}
                    cellRenderer={cellRenderer}
                    rowCount={rowCount}
                    rowHeight={cache.rowHeight}
                    height={height}
                    autoHeight
                    width={width}
                    autoWidth
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    onScroll={onChildScroll}
                    ref={gridRef}
                />
            )}
        </WindowScroller>
    );
}

export default GridReactVirtualizedList;