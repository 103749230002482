import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';
import { GLOBAL_STYLED_PROPS } from '../../../../../../styles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../../../styles/WithGStyles';

export interface Props {
    id: string;
    label: string;
    defaultValue?: string;
    value?: string;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    rootClass?: string;
    labelClass?: string;
    textFieldClass?: string;
    isHiddenFromFamily?: boolean;
    onBlur?: () => void;
    onChange: (value: string) => void;
    handleOpenSnackbar?: () => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        textAlign: 'left',
        marginTop: 10,
        width: 190,
        '@media (min-width: 421px)': {
            width: 280,
        },
    },
    inputDateLabel: {
        width: 'max-content',
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'inputDateLabel'>;

const DCDateField = (props: StyledProps) => {

    const {
        classes,
        id,
        error,
        disabled,
        required,
        defaultValue,
        value,
        label,
        rootClass,
        textFieldClass,
        labelClass,
        isHiddenFromFamily,
        onBlur,
        onChange,
        handleOpenSnackbar,
    } = props;

    return (
        <FormControl
            className={classNames(classes.root, rootClass)}
            required={required}
            error={error}
        >
            <TextField
                id={id}
                type="date"
                label={label}
                required={required}
                error={error}
                value={value}
                defaultValue={defaultValue}
                className={classNames(classes.width100, textFieldClass)}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                autoComplete="new-password"
                disabled={disabled}
                onClick={handleOpenSnackbar}
                InputLabelProps={{
                    shrink: true,
                    className: classes.inputDateLabel,
                    classes: {
                        root: labelClass,
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <HiddenFromFamilyAdornment
                            showMe={isHiddenFromFamily}
                            useAdornmentComponent
                        />
                    )
                }}
            />
        </FormControl>
    );
};

export default withGStyles(styles)(DCDateField);
