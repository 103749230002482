import * as React from 'react';
import classNames from 'classnames';
import { debounce, difference } from 'lodash';
// import { google } from 'google-maps';
import Downshift, { StateChangeOptions, DownshiftState } from 'downshift';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { InputProps as MaterialInputProps } from '@mui/material/Input';
import { MenuItemProps } from '@mui/material/MenuItem';
import KeyboardIcon from '@mui/icons-material/Keyboard';

import { GLOBAL_STYLED_PROPS } from '../../styles';

import {
    ShortAddress,
    LongAddress,
    NullShortAddress,
    NullLongAddress,
    ManualAddress,
    GmapsSearchType
} from '../../shared/types';

import ManualAddressDialog from './ManualAddress.dialog';
import { isTouchDevice } from '../../services';
import InputAdornment from '@mui/material/InputAdornment';
import { US_STATES } from '../../shared/locale';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../styles/WithGStyles';
import { log } from '../../logger';

// required for Google Timezone API to work (it wasn't available in @types/google-maps as of Oct 2018)
const googleMapsClient = require('@google/maps').createClient({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
});

// declare let google: google;

interface AutocompleteStructuredFormatting extends google.maps.places.AutocompleteStructuredFormatting { }

interface GmapsInputProps {
    variant?: 'outlined';
    size?: 'small';
    InputProps: MaterialInputProps;
    fullWidth: boolean;
    error: boolean;
    label: string;
    required: boolean;
    onClear: () => void;
}

interface RenderSuggestion {
    suggestion: string;
    formattedSuugestion: AutocompleteStructuredFormatting;
    index: number;
    itemProps: MenuItemProps;
    highlightedIndex: number | null;
    selectedItem: string;
}

function renderSuggestion({
    formattedSuugestion,
    suggestion,
    itemProps,
    selectedItem,
    index,
    highlightedIndex
}: RenderSuggestion) {
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;
    return (
        <MenuItem
            {...itemProps}
            // button={itemProps.button ? true : undefined}
            selected={highlightedIndex === index}
            key={suggestion}
            tabIndex={index}
            style={{
                fontWeight: isSelected ? 500 : 400
            }}
        >
            <span
                style={{
                    fontSize: 16,
                    lineHeight: '16px',
                    color: '#000',
                    display: 'block',
                    width: '100%',
                    marginBottom: '4px'
                }}
            >
                {formattedSuugestion.main_text}
            </span>
            <span
                style={{
                    fontSize: 13,
                    lineHeight: '13px',
                    color: '#999',
                    display: 'block',
                    width: '100%'
                }}
            >
                {formattedSuugestion.secondary_text}
            </span>
        </MenuItem>
    );
}

export type GmapsSearchAddress = ShortAddress | LongAddress;

export interface GmapsTimezone {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
}

interface GmapsTimezoneResponse {
    status: number;
    json: GmapsTimezone;
}

interface InputAdornmentProps {
    position: 'start' | 'end';
    content: JSX.Element;
}

interface Props {
    variant?: 'outlined';
    formControlStyleClasses?: string;
    controlClasses: Object;
    controlSuggestionClasses?: Object;
    textFieldClass?: string;
    textLabelClass?: string;
    textLabel: string;
    type: GmapsSearchType;
    value: GmapsSearchAddress;
    error: boolean;
    onSetPlace: (a: GmapsSearchAddress, useDescription: boolean) => void;
    onTimezoneReceived?: (tzInfo: GmapsTimezone | null) => void;
    required: boolean;
    disabled?: boolean;
    handleOpenSnackbar?: () => void;
    isAutoFocus?: boolean;
    requireLongAddressForManualEntry?: boolean;
    usAddressOnlyForManualEntry?: boolean;
    size?: 'small';
    zIndex: number;
    useDescription?: boolean;
    inputAdornment?: InputAdornmentProps;
}

const INITIAL_MANUAL_ADDRESS: ManualAddress = {
    locationName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    county: ''
};

interface State {
    gmaps?: google.maps.places.AutocompleteService;
    autocompleteResponses: google.maps.places.AutocompletePrediction[];
    autocompleteValue: google.maps.places.AutocompletePrediction | undefined;
    isManualAddressDialogOpen: boolean;
    searchedValue: string;
    manualAddress: ManualAddress;
    useThisLocationClicked: boolean;
    downShiftIsOpen: boolean;
    highlightedIndex: number;
    useDescription: boolean;
    forceCloseDownshift: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    input: {
        display: 'flex',
        padding: 0
    },
    inputTextWidth: {
        maxWidth: 280
    },
    formControlStyle: {
        marginTop: 20
    },
    googleSuggestionsMenu: {
        position: 'absolute',
        zIndex: 2,
        '@media (max-width: 421px)': {
            width: 'calc(100% - 48px) !important'
        }
    },
    elipsesInput: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    mapSuggestionMenu: {
        display: 'block',
        padding: '10px 8px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        lineHeight: 1,
        borderBottom: '1px solid #e6e6e6',
        height: 'auto',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
        },
    },
    googleLogoContainer: {
        textAlign: 'right',
        padding: '4px',
        lineHeight: 1
    },
    googleLogo: {
        maxHeight: 28
    },
    menuHeader: {
        fontSize: 16,
        color: '#000',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    menuDesc: {
        fontSize: 13,
        lineHeight: '13px',
        color: '#999',
        display: 'block',
        width: '100%',
        whiteSpace: 'normal'
    },
    keyboardIcon: {
        verticalAlign: 'sub',
        fontSize: 32
    },
    manualAddressItem: {
        padding: 4,
        textAlign: 'center'
    }
});

type ClassesProps = GLOBAL_STYLED_PROPS &
    WithStyles<
        | 'root'
        | 'success'
        | 'error'
        | 'info'
        | 'warning'
        | 'icon'
        | 'iconVariant'
        | 'inputTextWidth'
        | 'formControlStyle'
        | 'googleSuggestionsMenu'
        | 'elipsesInput'
        | 'mapSuggestionMenu'
        | 'googleLogoContainer'
        | 'googleLogo'
        | 'menuHeader'
        | 'menuDesc'
        | 'keyboardIcon'
        | 'textFieldPadding'
        | 'textFieldLabel'
        | 'manualAddressItem'
    >;

type StyledProps = Props & ClassesProps;

class GmapsSearch extends React.Component<StyledProps, State> {
    throttledGetPredictions = debounce(
        (t: string[], value: string) => this.getPredictions(t, value),
        500
    );

    protected gmapsRef: HTMLDivElement | undefined;

    constructor(props: StyledProps) {
        super(props);
        let gmaps;
        if (
            window.hasOwnProperty('google') &&
            google.hasOwnProperty('maps') &&
            google.maps.hasOwnProperty('places') &&
            google.maps.places.hasOwnProperty('AutocompleteService')
        ) {
            gmaps = new google.maps.places.AutocompleteService();
        }

        this.state = {
            gmaps,
            autocompleteResponses: [],
            autocompleteValue: undefined,
            isManualAddressDialogOpen: false,
            searchedValue: '',
            manualAddress: INITIAL_MANUAL_ADDRESS,
            useThisLocationClicked: false,
            downShiftIsOpen: false,
            highlightedIndex: 0,
            useDescription: this.props.useDescription || false,
            forceCloseDownshift: false,
        };
    }

    componentWillUnmount() {
        this.throttledGetPredictions.cancel();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.useDescription !== this.props.useDescription) {
            this.setState({ useDescription: this.props.useDescription || false });
        }
    }

    initializeGmaps() {
        if (
            google &&
            google.hasOwnProperty('maps') &&
            google.maps.hasOwnProperty('places') &&
            google.maps.places.hasOwnProperty('AutocompleteService')
        ) {
            this.setState({
                gmaps: new google.maps.places.AutocompleteService(),
            });
        }
    }

    mapCityResponseToShortAddress(
        response: google.maps.places.AutocompletePrediction
    ): ShortAddress {
        let a: ShortAddress = {
            city: '',
            state: '',
            country: '',
            description: response.description,
            googlePlaceID: response.place_id,
        };
        // just take the terms list, and if 3, it represents city, state, country
        // if 2 it represents city and country
        // the types length does not necessarily correspond to terms length, seen in non-us
        const numTerms = response.terms.length;
        if (numTerms >= 3) {
            a.city = response.terms[0].value;
            a.state = response.terms[1].value;
            a.country = response.terms[2].value;
        } else if (numTerms === 2) {
            a.city = response.terms[0].value;
            a.country = response.terms[1].value;
        } else if (numTerms === 1) { // never seen this, but maybe country
            a.country = response.terms[0].value;
        }
        return a;
    }

    onChange(value: string) {
        if (value === '') {
            return this.setState({
                autocompleteResponses: [],
                searchedValue: '',
                highlightedIndex: 0
            });
        }
        const { type } = this.props;
        let t: string[] = [];
        if (type === GmapsSearchType.shortAddress) {
            t = ['(cities)'];
        }

        if (this.state.gmaps !== undefined) {
            this.throttledGetPredictions(t, value);
        } else {
            this.initializeGmaps();
        }
        this.setState({
            searchedValue: value || ''
        });
    }

    mapAddressResponseToLongAddress(
        response: google.maps.places.PlaceResult | null,
        v: google.maps.places.AutocompletePrediction
    ): LongAddress {
        if (!response || !response.geometry) {
            log.warn('Gmaps response is null', { response, v });
            return NullLongAddress;
        }

        let a: LongAddress = {
            address1: '',
            address2: '',
            city: '',
            postalCode: '',
            county: '',
            state: '',
            country: '',
            isEstablishment: false,
            locationName: response.name,
            googlePlaceID: v.place_id,
            description: v.description,
            coords: [response.geometry.location.lat(), response.geometry.location.lng()],
        };

        let subLocality: string | null = null;

        if (response.address_components) {
            for (let index = 0; index < response.address_components.length; index++) {
                const addressComponent = response.address_components[index];

                if (difference(addressComponent.types, ['street_number']).length === 0) {
                    a.address1 = addressComponent.long_name + ' ' + a.address1;
                } else if (difference(addressComponent.types, ['route']).length === 0) {
                    a.address1 = a.address1 + addressComponent.long_name;
                } else if (difference(addressComponent.types, ['locality', 'political']).length === 0) {
                    a.city = addressComponent.long_name;
                } else if (
                    difference(addressComponent.types, ['administrative_area_level_2', 'political']).length === 0
                ) {
                    a.county = addressComponent.long_name;
                } else if (
                    difference(addressComponent.types, ['administrative_area_level_1', 'political']).length === 0
                ) {
                    a.state = addressComponent.long_name;
                } else if (difference(addressComponent.types, ['country', 'political']).length === 0) {
                    a.country = addressComponent.long_name;
                } else if (difference(addressComponent.types, ['postal_code']).length === 0) {
                    a.postalCode = addressComponent.long_name;
                } else if (
                    difference(addressComponent.types, ['sublocality_level_1', 'sublocality', 'political']).length === 0
                ) {
                    subLocality = addressComponent.long_name;
                }
            }
        }

        if (!a.city && subLocality) {
            // support for New York City boroughs
            a.city = subLocality;
        }

        const addressTypes = v.types;
        if (addressTypes && addressTypes.indexOf('establishment') !== -1) {
            a.isEstablishment = true;
        }

        return a;
    }

    getPredictions(t: string[], value: string) {
        if (this.state.gmaps !== undefined) {
            this.state.gmaps.getPlacePredictions(
                {
                    types: t,
                    input: value,
                },
                (res) => {
                    this.setState((state) => ({
                        autocompleteResponses: res || [],
                    }));
                }
            );
        }
    }

    onChangeSelect = (v: google.maps.places.AutocompletePrediction | string) => {
        const { onTimezoneReceived } = this.props;
        const { useDescription } = this.state;
        // let us figure out when a "clear" action happens
        // handle returning an appropriate cleared value
        if (typeof v === 'string') {
            switch (this.props.type) {
                case GmapsSearchType.shortAddress: {
                    this.props.onSetPlace(
                        {
                            ...NullShortAddress,
                            description: v,
                        },
                        useDescription
                    );
                    break;
                }
                case GmapsSearchType.longAddress: {
                    this.props.onSetPlace(
                        {
                            ...NullLongAddress,
                            description: v,
                            locationName: v,
                        },
                        useDescription
                    );
                    break;
                }
                default:
                    log.warn('Invalid type', { type: this.props.type });
                    break;
            }
            return;
        }

        if (!v.place_id) {
            return;
        }

        switch (this.props.type) {
            case GmapsSearchType.shortAddress: {
                const shortAddress: ShortAddress = this.mapCityResponseToShortAddress(v);
                if (this.props.value.hasOwnProperty('unknown')) {
                    shortAddress.unknown = this.props.value.unknown;
                }
                this.props.onSetPlace(shortAddress, useDescription);
                break;
            }
            case GmapsSearchType.longAddress: {
                if (this.gmapsRef !== undefined) {
                    if (!v.place_id) {
                        console.warn('No Google Maps place_id', JSON.stringify({ useDescription, v }));
                        return;
                    }
                    const places = new google.maps.places.PlacesService(this.gmapsRef);
                    places.getDetails(
                        {
                            placeId: v.place_id,
                        },
                        (res) => {
                            const longAddress: LongAddress =
                                this.mapAddressResponseToLongAddress(
                                    res,
                                    v
                                );

                            if (this.props.value.hasOwnProperty('unknown')) {
                                longAddress.unknown = this.props.value.unknown;
                            }

                            this.props.onSetPlace(longAddress, useDescription);
                            if (onTimezoneReceived !== undefined && longAddress.coords) {
                                googleMapsClient.timezone(
                                    {
                                        location: longAddress.coords,
                                    },
                                    (err: Object, tzResponse: GmapsTimezoneResponse) => {
                                        if (tzResponse.status !== 200 && !err) {
                                            console.warn(
                                                `Response from timezone API for placeId ${v.place_id}:`,
                                                tzResponse,
                                                err
                                            );
                                            onTimezoneReceived(null);
                                        } else {
                                            onTimezoneReceived(tzResponse.json);
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
                break;
            }
            default:
                break;
        }
    };

    onDownshiftStateChange(state: DownshiftState<LongAddress>, changes: StateChangeOptions<LongAddress>) {
        const { value } = this.props;
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownArrowDown:
                this.setState((prevState) => {
                    const newIndex = prevState.highlightedIndex + 1 > prevState.autocompleteResponses.length
                        ? 0
                        : prevState.highlightedIndex + 1;

                    return { highlightedIndex: newIndex };
                });
                return {
                    ...changes,
                    highlightedIndex: (changes.highlightedIndex || 0) + 1,
                };
            case Downshift.stateChangeTypes.keyDownArrowUp:
                this.setState((prevState) => {
                    const newIndex = prevState.highlightedIndex - 1 < 0
                        ? prevState.autocompleteResponses.length
                        : prevState.highlightedIndex - 1;

                    return { highlightedIndex: newIndex };
                });
                return changes;
            case Downshift.stateChangeTypes.blurInput:
            case Downshift.stateChangeTypes.mouseUp:
            case Downshift.stateChangeTypes.touchStart:
                if (
                    state.inputValue
                    && state.inputValue.trim()
                    && state.inputValue !== value.description
                    && (!state.selectedItem || !state.selectedItem.googlePlaceID
                        || state.inputValue !== state.selectedItem.description)
                ) {
                    this.openManualAddressDialog();
                    return changes;
                }
                if (state.inputValue === changes.inputValue) {
                    return changes;
                }
                // preserve input value on mouse up
                this.onChangeSelect(state.inputValue || '');
                return {
                    ...changes,
                    inputValue: state.inputValue,
                };
            default:
                return changes;
        }
    }

    handleClickEvent = () => {
        const { handleOpenSnackbar, disabled } = this.props;
        if (handleOpenSnackbar) {
            handleOpenSnackbar();
        }

        if (!disabled) {
            this.handleOpenDownShift();
        }
    };

    handleOpenDownShift = () => {
        const { value } = this.props;
        const initialValue = value.description || '';

        this.setState({
            downShiftIsOpen: true,
            manualAddress: INITIAL_MANUAL_ADDRESS,
            searchedValue: initialValue,
        });
        this.onChange(initialValue);
    };

    handleCloseDownShift = () => {
        this.setState({
            downShiftIsOpen: false,
            autocompleteResponses: [],
            useDescription: false,
        });
    };

    renderInput(inputProps: GmapsInputProps) {
        const { InputProps, error, label, required, variant, size } = inputProps;
        const { classes, isAutoFocus, disabled, textFieldClass, textLabelClass, inputAdornment } = this.props;
        const { forceCloseDownshift, downShiftIsOpen } = this.state;

        const textFieldProps = {
            error,
            label,
            InputProps: {
                ...InputProps,
                autoComplete: 'off',
                name: 'off',
                classes: {
                    input: classNames(
                        classes.elipsesInput,
                        size && size === 'small' && classes.textFieldPadding
                    ),
                    notchedOutline: variant === 'outlined' ? 'notranslate' : undefined
                },
                endAdornment: (
                    inputAdornment &&
                    <InputAdornment position={inputAdornment.position}>
                        {inputAdornment.content}
                    </InputAdornment>
                )
            },
            className: textFieldClass,
            autoFocus: isAutoFocus,
            required,
            onClick: this.handleClickEvent,
            onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.keyCode === 9 || e.which === 9) {
                    this.handleCloseDownShift();
                } else if (isAutoFocus && !downShiftIsOpen) {
                    this.handleOpenDownShift();
                }
            },
            onFocus: !forceCloseDownshift && !isAutoFocus && !disabled ? this.handleOpenDownShift : undefined,
            placeholder: 'Start typing to see locations…',
        };

        if (variant === 'outlined') {
            return (
                <TextField
                    {...textFieldProps}
                    variant="outlined"
                    InputLabelProps={{
                        classes: {
                            outlined: size && size === 'small' && classes.textFieldLabel || '',
                            root: textLabelClass,
                        },
                    }}
                    size={size}
                />
            );
        }

        return <TextField
            {...textFieldProps}
            InputLabelProps={{
                classes: {
                    outlined: size && size === 'small' && classes.textFieldLabel || '',
                    root: textLabelClass,
                },
            }}
            size={size}
        />;
    }

    openManualAddressDialog = () => {
        this.setSearchStringToManualAddress();
        this.setState({
            useThisLocationClicked: false,
            forceCloseDownshift: true,
            downShiftIsOpen: false,
            isManualAddressDialogOpen: true
        });
    };

    setSearchStringToManualAddress = () => {
        const { type } = this.props;
        const { searchedValue, useDescription } = this.state;

        if (useDescription) {
            this.setState({
                manualAddress: {
                    ...INITIAL_MANUAL_ADDRESS,
                    address: searchedValue,
                },
            });
            return;
        }

        searchedValue.split(',').map((addr, i) => {
            const partialAddress = addr.trim();

            if (i === 0) {
                if (type === GmapsSearchType.longAddress) {
                    this.setState(prevState => ({
                        manualAddress: {
                            ...prevState.manualAddress,
                            address: partialAddress,
                        },
                    }));
                } else if (type === GmapsSearchType.shortAddress) {
                    this.setState(prevState => ({
                        manualAddress: {
                            ...prevState.manualAddress,
                            city: partialAddress,
                        },
                    }));
                }
            } else if (i === 1) {
                if (type === GmapsSearchType.shortAddress) {
                    const enteredState = US_STATES.find(s => (
                        s.name.toLowerCase() === partialAddress.toLowerCase() ||
                        s.abbreviation.toLowerCase() === partialAddress.toLowerCase()
                    ));
                    this.setState(prevState =>
                    ({
                        manualAddress: {
                            ...prevState.manualAddress,
                            state: enteredState ? enteredState.name : '',
                        },
                    })
                    );
                }
            }
        });
    };

    closeManualAddressDialog = () => {
        this.setState({
            isManualAddressDialogOpen: false,
        });
    };

    getCompleteAddress = (address: ManualAddress, type: GmapsSearchType, useDescription: boolean) => {
        let completeAddressArray: string[] = [];

        if (useDescription) {
            return address.locationName;
        }

        if (type === GmapsSearchType.shortAddress) {
            if (address.city) {
                completeAddressArray.push(address.city);
            }
            if (address.state) {
                completeAddressArray.push(address.state);
            }
            if (address.postalCode) {
                completeAddressArray.push(address.postalCode);
            }
        } else {
            if (address.locationName) {
                completeAddressArray.push(address.locationName);
            }
            if (address.address) {
                let completeAddress = address.address;
                if (address.address2) {
                    completeAddress += ` ${address.address2}`;
                }
                completeAddressArray.push(completeAddress);
            }
            if (address.city) {
                completeAddressArray.push(address.city);
            }
            if (address.state) {
                completeAddressArray.push(address.state);
            }
            if (address.postalCode) {
                completeAddressArray.push(address.postalCode);
            }
            if (address.county) {
                completeAddressArray.push(address.county);
            }
        }

        return completeAddressArray.join(', ');
    };

    useThisLocation = () => {
        const { type } = this.props;
        const { manualAddress, useDescription } = this.state;

        if ((!manualAddress.city || !manualAddress.state) && !useDescription) {
            this.setState({
                useThisLocationClicked: true
            });
            return;
        }

        let completeAddress = '';

        if (type === GmapsSearchType.longAddress) {
            completeAddress = this.getCompleteAddress(manualAddress, type, useDescription);
            this.props.onSetPlace(
                {
                    ...NullLongAddress,
                    description: completeAddress,
                    locationName: !useDescription && manualAddress.locationName || '',
                    address1: !useDescription && manualAddress.address || '',
                    address2: !useDescription && manualAddress.address2 || '',
                    city: !useDescription && manualAddress.city || '',
                    state: !useDescription && manualAddress.state || '',
                    postalCode: !useDescription && manualAddress.postalCode || '',
                    county: !useDescription && manualAddress.county || '',
                    isEstablishment: true,
                },
                useDescription
            );
        } else {
            completeAddress = this.getCompleteAddress(manualAddress, type, useDescription);
            this.props.onSetPlace(
                {
                    ...NullShortAddress,
                    description: completeAddress,
                    city: !useDescription && manualAddress.city || '',
                    state: !useDescription && manualAddress.state || '',
                },
                useDescription
            );
        }
        this.closeManualAddressDialog();
    };

    updateManualAddress = (address: ManualAddress) => {
        this.setState({
            manualAddress: address
        });
    };

    render() {
        const {
            classes,
            textLabel,
            value,
            controlClasses,
            error,
            controlSuggestionClasses,
            type,
            formControlStyleClasses,
            requireLongAddressForManualEntry,
            usAddressOnlyForManualEntry,
            variant,
            size,
            zIndex
        } = this.props;
        const {
            autocompleteResponses,
            isManualAddressDialogOpen,
            searchedValue,
            manualAddress,
            useThisLocationClicked,
            downShiftIsOpen,
            useDescription
        } = this.state;

        const disabled = Boolean(value.unknown);
        const googleImageSrc = `/static/images/powered_by_google/desktop/powered_by_google.png`;

        return (
            <div className={classNames(classes.formControlStyle, formControlStyleClasses)}>
                <Downshift
                    onChange={this.onChangeSelect}
                    selectedItem={value}
                    isOpen={downShiftIsOpen}
                    onOuterClick={this.handleCloseDownShift}
                    onSelect={() => (this.state.highlightedIndex === autocompleteResponses.length
                        || autocompleteResponses.length === 0) && (searchedValue || '').length
                        ? this.openManualAddressDialog()
                        : this.handleCloseDownShift()}
                    itemToString={(item: google.maps.places.AutocompletePrediction) =>
                        item ? item.description : ''
                    }
                    highlightedIndex={autocompleteResponses.length === 0 ? 0 : this.state.highlightedIndex}
                    defaultHighlightedIndex={0}
                    stateReducer={(state, changes) => this.onDownshiftStateChange(state, changes)}
                >
                    {({
                        getInputProps,
                        isOpen,
                        highlightedIndex,
                        getItemProps,
                    }) => {
                        return (
                            <div>
                                <FormControl
                                    className={classNames(
                                        classes.textLeft,
                                        classes.inputTextWidth,
                                        controlClasses
                                    )}
                                >
                                    {this.renderInput({
                                        variant,
                                        size,
                                        fullWidth: true,
                                        InputProps: {
                                            ...getInputProps({
                                                onChange: (e: React.FormEvent<HTMLInputElement>) => {
                                                    this.onChange(e.currentTarget.value);
                                                }
                                            }),
                                            disabled: disabled || this.props.disabled,
                                        },
                                        error: error,
                                        label: textLabel,
                                        required: this.props.required,
                                        onClear: () => this.onChangeSelect('')
                                    })}
                                </FormControl>
                                {isOpen ? (
                                    <Paper
                                        className={classNames(
                                            controlClasses,
                                            controlSuggestionClasses ?? classes.googleSuggestionsMenu
                                        )}
                                        square
                                        style={{ zIndex }}
                                    >
                                        {autocompleteResponses.map((suggestion, index) =>
                                            renderSuggestion({
                                                suggestion: suggestion.description,
                                                formattedSuugestion: suggestion.structured_formatting,
                                                index,
                                                itemProps: getItemProps({
                                                    item: suggestion,
                                                    className: classes.mapSuggestionMenu,
                                                    onMouseEnter: (e) => this.setState({ highlightedIndex: index }),
                                                    onMouseLeave: (e) => this.setState({ highlightedIndex: 0 })
                                                }),
                                                highlightedIndex,
                                                selectedItem: value.description
                                            })
                                        )}
                                        <MenuItem
                                            tabIndex={autocompleteResponses.length}
                                            role="option"
                                            aria-selected={false}
                                            id={`downshift-simple-item-${autocompleteResponses.length}`}
                                            selected={autocompleteResponses.length === 0
                                                || highlightedIndex === autocompleteResponses.length}
                                            {...getItemProps({
                                                item: { ...value, description: searchedValue, terms: [] },
                                                className: classNames(
                                                    classes.manualAddressItem,
                                                    classes.mapSuggestionMenu
                                                ),
                                                index: autocompleteResponses.length,
                                                onClick: e => this.openManualAddressDialog(),
                                                onMouseEnter: (e) =>
                                                    this.setState({ highlightedIndex: autocompleteResponses.length }),
                                                onMouseLeave: (e) => this.setState({ highlightedIndex: 0 }),
                                            })}
                                        >
                                            <span className={classes.menuHeader}>
                                                <KeyboardIcon className={classes.keyboardIcon} />
                                                <span>{` ${searchedValue}`}</span>
                                            </span>
                                            <span className={classes.menuDesc}>
                                                {isTouchDevice() ? 'Tap' : 'Click'} to enter this address manually
                                            </span>
                                        </MenuItem>
                                        <Typography component="p" className={classes.googleLogoContainer}>
                                            <img src={googleImageSrc} alt="" className={classes.googleLogo} />
                                        </Typography>
                                    </Paper>
                                ) : null}
                            </div>
                        );
                    }}
                </Downshift>
                <div
                    ref={ref => {
                        if (ref !== null) {
                            this.gmapsRef = ref;
                        }
                    }}
                />
                <ManualAddressDialog
                    isDialogOpen={isManualAddressDialogOpen}
                    type={type}
                    manualAddress={manualAddress}
                    useThisLocationClicked={useThisLocationClicked}
                    closeDialog={this.closeManualAddressDialog}
                    updateManualAddress={this.updateManualAddress}
                    useThisLocation={this.useThisLocation}
                    requireLongAddress={requireLongAddressForManualEntry}
                    usAddressOnly={usAddressOnlyForManualEntry}
                    zIndex={zIndex + 1}
                    useDescription={useDescription}
                    toggleUseDescription={(useDesc: boolean) => this.toggleUseDescription(useDesc)}
                />
            </div>
        );
    }

    toggleUseDescription = (useDescription: boolean) => {
        const { searchedValue } = this.state;

        if (useDescription) {
            this.setState({
                useDescription: true,
                manualAddress: {
                    ...INITIAL_MANUAL_ADDRESS,
                    locationName: searchedValue,
                },
            });
        } else {
            this.setState(
                {
                    useDescription: false,
                },
                this.setSearchStringToManualAddress,
            );
        }
    };
}

export default withGStyles(styles)(GmapsSearch);
