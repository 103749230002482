import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import {
    GatherCaseUX,
    DeathCertificateParentsType,
    EntitySummary,
    getCaseEntity,
    GmapsSearchType,
    isShortAddress,
    NullShortAddress,
} from '../../../../shared/types';

import { FormParent, ParentProps } from './FormParent';
import { parentsValidators, parentsMasterValidators } from '../../../../shared/death_certificate/validators/parents';
import DCRelatedEntities from '../DCRelatedEntities';
import { DCEntityEnum } from '../../../assignmentPoppers/SelectHelper';
import { StoreState } from '../../../../types';
import FormTitle from '../common/FormTitle';
// eslint-disable-next-line max-len
import ConfigurableAddressSearchWithCheckbox from '../common/fields/addressSearchWithCheckbox/ConfigurableAddressSearchWithCheckbox';
import { ConfigurableParentFieldKey } from '../../../../shared/death_certificate/validators/config';
// eslint-disable-next-line max-len
import ConfigurableTextFieldWithCheckbox from '../common/fields/textFieldWithCheckbox/ConfigurableTextFieldWithCheckbox';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withState from '../../../common/utilHOC/WithState';
import withGStyles from '../../../../styles/WithGStyles';
import { GmapsSearchAddress } from '../../../gmapsSearch/GmapsSearch';

function mapStateToProps({ casesState, userSession }: StoreState) {
    return {
        userData: userSession.userData,
        helpers: casesState.helpers,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    activeCase: GatherCaseUX;
    onFatherChange: (caseEntityId: number | null) => void;
    onMotherChange: (caseEntityId: number | null) => void;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateParentsType;
    localFather: number | null;
    localMother: number | null;
    motherMaidenNameValidator: boolean | null;
    fatherBirthPlaceValidator: boolean | null;
    motherBirthPlaceValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateParentsType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        margin: 'auto',
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        '@media (min-width: 800px)': {
            width: 800,
        },
    },
    container: {
        width: '100%',
        maxWidth: '100%',
        '@media (min-width: 421px)': {
            width: 360,
            maxWidth: 360,
        },
        '@media (min-width: 600px)': {
            width: 280,
        },
        '@media (min-width: 800px)': {
            width: 360,
            maxWidth: 360,
        },
    },
    checkbox: {
        width: 'auto',
        marginRight: 10,
    },
    textBoxGridWidth: {
        width: 360,
    },
    checkBoxConatiner: {
        width: 'fit-content',
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'inviteHelperForm' | 'diePlace'
    | 'checkbox' | 'textBoxGridWidth' | 'checkBoxConatiner' | 'container'>;

class ParentForm extends FormParent<DeathCertificateParentsType, StyledProps, State> {

    state = {
        localData: this.props.data,
        localFather: this.props.activeCase.dc_father,
        localMother: this.props.activeCase.dc_mother,
        motherMaidenNameValidator: null,
        fatherBirthPlaceValidator: null,
        motherBirthPlaceValidator: null,
    };

    handleFatherChange = (helper: EntitySummary | null) => {
        const { onFatherChange, activeCase } = this.props;

        const caseEntity = getCaseEntity(helper, activeCase.id);
        const father = caseEntity?.case_entity_id || null;
        this.setState({
            localFather: father,
        });
        onFatherChange(father);
    };

    handleMotherChange = (helper: EntitySummary | null) => {
        const { onMotherChange, activeCase } = this.props;

        const caseEntity = getCaseEntity(helper, activeCase.id);
        const mother = caseEntity?.case_entity_id || null;
        this.setState({
            localMother: mother,
        });
        onMotherChange(mother);
    };

    handleFatherBirthPlaceUnknown = (isChecked: boolean) => {

        this.updateData(
            {
                fatherBirthPlace: {
                    ...NullShortAddress,
                    unknown: isChecked,
                },
            },
            this.validateFatherBirthPlace,
        );
    };

    handleMotherBirthPlaceUnknown = (isChecked: boolean) => {

        this.updateData(
            {
                motherBirthPlace: {
                    ...NullShortAddress,
                    unknown: isChecked,
                },
            },
            this.validateMotherBirthPlace,
        );
    };

    handleMotherMaidenNameChangeEvent = (value: string) => {
        this.updateData(
            (prevData) => ({
                motherName: {
                    ...prevData.motherName,
                    maidenName: value,
                },
            }),
            this.validateMotherMaidenName,
        );
    };

    handleMotherMaidenNameUnknown = (isChecked: boolean) => {
        if (isChecked) {
            this.updateData(
                {
                    motherName: {
                        maidenName: '',
                        unknown: true,
                    },
                },
                this.validateMotherMaidenName,
            );
        } else {
            this.updateData(
                (prevData) => ({
                    motherName: {
                        ...prevData.motherName,
                        unknown: false,
                    },
                }),
                this.validateMotherMaidenName,
            );
        }
    };

    handleFatherBirthPlace = (fatherBirthPlace: GmapsSearchAddress) => {
        if (isShortAddress(fatherBirthPlace)) {
            this.updateData({ fatherBirthPlace }, this.validateFatherBirthPlace);
        }
    };

    handleMotherBirthPlace = (motherBirthPlace: GmapsSearchAddress) => {
        if (isShortAddress(motherBirthPlace)) {
            this.updateData({ motherBirthPlace }, this.validateMotherBirthPlace);
        }
    };

    // VALIDATION

    validateMotherMaidenName = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            motherMaidenNameValidator: parentsValidators.motherName(localData, dcConfig, user),
        });
    };

    validateFatherBirthPlace = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            fatherBirthPlaceValidator: parentsValidators.fatherBorn(localData, dcConfig, user),
        });
    };

    validateMotherBirthPlace = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            motherBirthPlaceValidator: parentsValidators.motherBorn(localData, dcConfig, user),
        });
    };

    checkValid() {
        const { activeCase, helpers, dcConfig, user } = this.props;
        const { localData } = this.state;

        return parentsMasterValidators.valid(localData, dcConfig, activeCase, user, helpers);
    }

    validateAll() {
        this.validateFatherBirthPlace();
        this.validateMotherMaidenName();
        this.validateMotherBirthPlace();
    }

    checkIfTouched() {
        const { activeCase, dcConfig, user } = this.props;
        const { localData } = this.state;

        return parentsMasterValidators.touched(localData, dcConfig, activeCase, user);
    }

    render() {
        const {
            classes,
            activeCase,
            isDeathCertificateLocked,
            isEditMode,
            handleOpenSnackbar,
            userData,
            helpers,
        } = this.props;
        const {
            localData,
            localFather,
            localMother,
        } = this.state;

        const {
            fatherBirthPlace,
            motherName,
            motherBirthPlace,
        } = localData;

        const {
            motherMaidenNameValidator,
            fatherBirthPlaceValidator,
            motherBirthPlaceValidator,
        } = this.state;

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Parents" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(classes.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container alignItems="flex-start" justifyContent="center">
                            <Grid
                                sm={6}
                                xs={12}
                                item
                                className={
                                    classNames(classes.width100, classes.textCenter)
                                }
                            >
                                <div
                                    className={classNames(
                                        classes.container,
                                        classes.marginAuto,
                                        classes.marginTop30
                                    )}
                                >
                                    <DCRelatedEntities
                                        activeCase={activeCase}
                                        helpers={helpers}
                                        userData={userData}
                                        selectedCaseEntityId={localFather}
                                        onEntityChange={this.handleFatherChange}
                                        zIndex={1320}
                                        relationToDeceased={DCEntityEnum.Father}
                                        fieldsToDisplay={['fname', 'mname', 'lname', 'relationship']}
                                        requiredFields={['fname', 'lname']}
                                        isDeathCertificateLocked={isDeathCertificateLocked || isEditMode}
                                    />
                                </div>
                                <ConfigurableAddressSearchWithCheckbox
                                    id={ConfigurableParentFieldKey.fatherBirthCity}
                                    label={`In which city was ${activeCase.fname}'s father born?`}
                                    controlClass={classes.container}
                                    rootClass={classNames(
                                        classes.container,
                                        classes.textLeft,
                                        classes.marginBottom10,
                                        classes.marginAuto
                                    )}
                                    searchType={GmapsSearchType.shortAddress}
                                    value={fatherBirthPlace}
                                    onChange={this.handleFatherBirthPlace}
                                    error={fatherBirthPlaceValidator === false}
                                    disabled={isDeathCertificateLocked}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    zIndex={1320}
                                    checkboxLabel="City of birth unknown"
                                    onCheckboxChange={this.handleFatherBirthPlaceUnknown}
                                    isChecked={!!fatherBirthPlace.unknown}
                                    isEditMode={isEditMode}
                                />
                            </Grid>
                            <Grid
                                sm={6}
                                xs={12}
                                item
                                className={
                                    classNames(classes.width100, classes.textCenter)
                                }
                            >
                                <div
                                    className={classNames(
                                        classes.container,
                                        classes.marginAuto,
                                        classes.marginTop30,
                                        classes.marginBottom10
                                    )}
                                >
                                    <DCRelatedEntities
                                        activeCase={activeCase}
                                        helpers={helpers}
                                        userData={userData}
                                        selectedCaseEntityId={localMother}
                                        relationToDeceased={DCEntityEnum.Mother}
                                        zIndex={1320}
                                        onEntityChange={this.handleMotherChange}
                                        fieldsToDisplay={['fname', 'mname', 'lname', 'relationship']}
                                        requiredFields={['fname', 'lname']}
                                        isDeathCertificateLocked={isDeathCertificateLocked || isEditMode}
                                    />
                                </div>
                                <Grid
                                    item
                                    xs={12}
                                    className={
                                        classNames(classes.width100, classes.textCenter)
                                    }
                                >
                                    <ConfigurableTextFieldWithCheckbox
                                        id={ConfigurableParentFieldKey.motherMaidenName}
                                        label="Mother's Maiden name"
                                        error={motherMaidenNameValidator === false}
                                        value={motherName.maidenName || ''}
                                        onChange={this.handleMotherMaidenNameChangeEvent}
                                        onBlur={this.validateMotherMaidenName}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        checkboxLabel="Mother's maiden name unknown"
                                        onCheckboxChange={this.handleMotherMaidenNameUnknown}
                                        isChecked={motherName.unknown}
                                        isEditMode={isEditMode}
                                        rootClass={classes.container}
                                    />
                                </Grid>
                                <ConfigurableAddressSearchWithCheckbox
                                    id={ConfigurableParentFieldKey.motherBirthCity}
                                    label={`In which city was ${activeCase.fname}'s mother born?`}
                                    searchType={GmapsSearchType.shortAddress}
                                    value={motherBirthPlace}
                                    onChange={this.handleMotherBirthPlace}
                                    error={motherBirthPlaceValidator === false}
                                    disabled={isDeathCertificateLocked}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    zIndex={1320}
                                    checkboxLabel="City of birth unknown"
                                    onCheckboxChange={this.handleMotherBirthPlaceUnknown}
                                    isChecked={!!motherBirthPlace.unknown}
                                    isEditMode={isEditMode}
                                    rootClass={classNames(classes.container, classes.marginAuto)}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </div >
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(ParentForm));
