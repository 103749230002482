import { useMemo } from "react";
import { partition } from "lodash";

import makeGStyles from "../../../../styles/makeGStyles";

import { FeatureKey, WorkflowSummary, } from "../../../../shared/types";
import TaskList from "../../../workflows/editWorkFlow/editTasks/TaskList";
import classNames from "classnames";
import { alpha } from "@mui/material";
import useFeatureEnabled from "../../../common/hooks/useFeatureEnabled";

const useStyles = makeGStyles(theme => ({
    tasksContainer: {
        maxWidth: '280px !important',
        minWidth: '280px !important',
        marginBottom: 16,
        height: 'fit-content',
        '@media (min-width: 425px)': {
            maxWidth: '360px !important',
            minWidth: '360px !important',
        },
    },
    taskList: {
        padding: '2px 0 !important',
    },
    containerClass: {
        height: 'auto',
        minHeight: 220,
        border: `1px solid ${theme.palette.primary.main} !important`,
    },
    marginLeft16: {
        marginLeft: 16,
    },
    marginRight16: {
        marginRight: 16,
    },
    barHeading: {
        color: `${theme.palette.primary.main} !important`,
        border: `1px solid ${theme.palette.primary.main}`,
        borderBottom: 'none',
        background: `${alpha(theme.palette.primary.main, 0.12)} !important`,
    },
}), { name: 'WorkflowListSummary' });

interface Props {
    workflowSummary: WorkflowSummary;
}

export enum TaskListTypeEnum {
    tracking = 'Tracking Steps',
    standard = 'Standard Tasks',
    aftercare = 'After-Care Tasks',
}

const WorkflowListSummary = (props: Props) => {
    const { workflowSummary } = props;

    const classes = useStyles();
    const isAfterCareTasksDisabled = !useFeatureEnabled(FeatureKey.AFTER_CARE);

    const { selected_tracking_steps, selected_checklist_tasks } = workflowSummary;

    const [afterCareTasks, standardTasks] = useMemo(() =>
        partition(selected_checklist_tasks, (task) => task.is_after_care),
        [selected_checklist_tasks],
    );

    const getDescription = (text: 'immediate' | 'after-care') =>
        <>
            Checklist of {text} items to complete for a case.<br />
            Tasks are NOT related to the disposition of the body.
        </>;

    const TrackingStepsDescription = <>Checklist of Tracking Steps. <br />
        Steps are only related to the disposition of the body.</>;

    return (
        <>
            <TaskList
                tasks={selected_tracking_steps.map(step => ({
                    ...step,
                    id: step.workflow_task_id,
                }))}
                workflowName={workflowSummary.name}
                taskListClass={classes.taskList}
                heading={TaskListTypeEnum.tracking}
                description={TrackingStepsDescription}
                workflowSummaryWrapperClasses={{
                    rootClass: classNames(classes.tasksContainer, classes.marginLeft16),
                    containerClass: classes.containerClass,
                    innerClass: classes.justifyContentCenter,
                    barHeadingClass: classes.barHeading,
                }}
                showLoadMoreButton={true}
            />

            <TaskList
                tasks={standardTasks.map(step => ({
                    ...step,
                    id: step.workflow_task_id,
                }))}
                workflowName={workflowSummary.name}
                taskListClass={classes.taskList}
                heading={TaskListTypeEnum.standard}
                description={getDescription('immediate')}
                workflowSummaryWrapperClasses={{
                    rootClass: classes.tasksContainer,
                    containerClass: classes.containerClass,
                    innerClass: classes.justifyContentCenter,
                    barHeadingClass: classes.barHeading,
                }}
                showLoadMoreButton={true}
            />

            <TaskList
                tasks={afterCareTasks.map(step => ({
                    ...step,
                    id: step.workflow_task_id,
                }))}
                workflowName={workflowSummary.name}
                taskListClass={classes.taskList}
                heading={TaskListTypeEnum.aftercare}
                description={getDescription('after-care')}
                workflowSummaryWrapperClasses={{
                    rootClass: classNames(classes.tasksContainer, classes.marginRight16),
                    containerClass: classes.containerClass,
                    innerClass: classes.justifyContentCenter,
                    barHeadingClass: classes.barHeading,
                }}
                featureDisabled={isAfterCareTasksDisabled}
                showLoadMoreButton={true}
            />
        </>
    );
};

export default WorkflowListSummary;