import Grid from "@mui/material/Grid";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import makeGStyles from "../../../../../../styles/makeGStyles";
import GButton from "../../../../../common/GButton";

const useStyles = makeGStyles(theme => ({
    footer: {
        maxWidth: 'calc(100% - 24px)',
        margin: 'auto auto 0',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        padding: '8px 0',
    },
    hiddenUnder425: {
        display: 'none',
        '@media (min-width: 425px)': {
            display: 'initial'
        }
    },
}), { name: 'StepComponentFooter' });

interface Props {
    isSaveDisabled?: boolean;
    onSaveClick: VoidFunction;
    onClose: VoidFunction;
    onDeleteClick?: VoidFunction;
}

const StepComponentFooter = (props: Props) => {
    const {
        isSaveDisabled,
        onSaveClick,
        onClose,
        onDeleteClick,
    } = props;

    const classes = useStyles();

    return (
        <Grid container justifyContent="space-between" alignItems="center" className={classes.footer}>

            <GButton
                text="cancel"
                color="primary"
                variant="text"
                onClick={onClose}
            />

            {onDeleteClick &&
                <GButton
                    text={<>remove&nbsp;<span className={classes.hiddenUnder425}>component</span></>}
                    icon={<RemoveCircleOutlineIcon className={classes.fontSize20} />}
                    className={classes.colorRed}
                    color="inherit"
                    variant="text"
                    onClick={onDeleteClick}
                />
            }

            <GButton
                variant="outlined"
                text="save"
                disabled={isSaveDisabled}
                onClick={onSaveClick}
            />
        </Grid>
    );
};

export default StepComponentFooter;
