import { Breakpoint, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Take from v3.9.4 Material UI
// https://github.com/mui-org/material-ui/blob/v3.9.4/packages/material-ui/src/withWidth/withWidth.js#L11
export const BreakpointKeys: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

// By default, returns true if screen width is the same or greater than the given breakpoint.
export const isWidthUp = (breakpoint: Breakpoint, width: Breakpoint, inclusive = true) => {
    const breakpointKeys = BreakpointKeys;
    if (inclusive) {
      return breakpointKeys.indexOf(breakpoint) <= breakpointKeys.indexOf(width);
    }
    return breakpointKeys.indexOf(breakpoint) < breakpointKeys.indexOf(width);
};
  
// By default, returns true if screen width is the same or less than the given breakpoint.
export const isWidthDown = (breakpoint: Breakpoint, width: Breakpoint, inclusive = true) => {
    const breakpointKeys = BreakpointKeys;
    if (inclusive) {
      return breakpointKeys.indexOf(width) <= breakpointKeys.indexOf(breakpoint);
    }
    return breakpointKeys.indexOf(width) < breakpointKeys.indexOf(breakpoint);
};

/**
 * NOTE FROM MUI DOCS:
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
    const theme: Theme = useTheme();
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce(
            (output: Breakpoint | null, key: Breakpoint) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const matches = useMediaQuery(theme.breakpoints.up(key));
                return !output && matches ? key : output;
            },
            null
        ) || 'xs'
    );
}

export default useWidth;
