import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';

const ContainerFlexColumn = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center'
});

const PhotoString = styled('div')({
    backgroundColor: '#000',
    background: 'linear-gradient(180deg, #fff 0%, #000 100%)',
    width: 1,
    transform: 'translateY(1px)'
});

const ClipCircle = styled('div')({
    border: `1px solid #000`,
    width: 9,
    height: 9,
    borderRadius: '50%',
    transform: 'translateY(1px)'
});

const ClipRectangle = styled('div')({
    width: 29,
    height: 7,
    background: '#000'
});

interface Props {
    stringClass?: string;
    stringHeight: number;
}
const PhotoHanger = ({ stringClass, stringHeight }: Props) => {

    return (
        <ContainerFlexColumn
            sx={{
                transform: 'translateY(2px)',
                zIndex: 1,
            }}
        >
            <PhotoString
                className={stringClass}
                style={{ height: (!stringClass && stringHeight) || undefined }}
            />

            <ContainerFlexColumn>
                <ClipCircle />
                <ClipRectangle />
            </ContainerFlexColumn>
        </ContainerFlexColumn>
    );
};

export default PhotoHanger;