import {
    DeathCertificateRestingPlaceType,
    DeathCertificateConfigUX,
    UserProfile,
    YesNoEnum,
    RestingPlaceOptionsEnum,
    GatherCaseUX,
    CaseType,
} from '../../../shared/types';
import { isFieldOptional, ConfigurableRestingPlaceFieldKey } from './config';

export const restingPlaceValidators = {
    option: function (
        { options }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = options && options.length > 0;
        if (isValid || isFieldOptional(ConfigurableRestingPlaceFieldKey.burialMethod, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    laidToRestDate: function(
        { dispositionDate, options }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isSelected = options && options.length > 0;
        const isValid = dispositionDate && dispositionDate.length;

        if (isValid || !isSelected || isFieldOptional(ConfigurableRestingPlaceFieldKey.dispositionDate, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    specifyOther: function (
        { specifyOther, options }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = options === undefined || options === RestingPlaceOptionsEnum.Other;
        const isValid = specifyOther && specifyOther.length > 0;
        const isFieldCurrentlyOptional = isFieldOptional(
            ConfigurableRestingPlaceFieldKey.specifyOtherBurialMethod,
            config,
            user
        );

        if (isValid || !isSelected || isFieldCurrentlyOptional) {
            return !touchCheck;
        }

        return false;
    },
    ownsCemeteryProperty: function(
        { ownsCemeteryProperty }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = ownsCemeteryProperty && ownsCemeteryProperty.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableRestingPlaceFieldKey.ownCemeteryPlot, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    dispositionPlace: function (
        { dispositionPlace, options }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValidLocation = dispositionPlace
            && dispositionPlace.description
            && dispositionPlace.description.length;
        const isPlaceUnknown = dispositionPlace.unknown === true;

        if (options && (isValidLocation || isPlaceUnknown)) {
            return true;
        }

        if (isFieldOptional(ConfigurableRestingPlaceFieldKey.dispositionPlace, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    cemeteryPropertyDetails: function (
        { cemeteryPropertyDetails, ownsCemeteryProperty, options }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = options === undefined || ownsCemeteryProperty && YesNoEnum[ownsCemeteryProperty];
        const isValid = !isSelected || (cemeteryPropertyDetails && cemeteryPropertyDetails.length > 0);

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableRestingPlaceFieldKey.cemeteryPropertyDetails, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    crematoryName: function (
        { crematoryName, options }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isSelected = options === RestingPlaceOptionsEnum.Cremation 
            || options === RestingPlaceOptionsEnum['Cremation with Burial']
            || options === RestingPlaceOptionsEnum['Cremation with Entombment'];
        const isValid = crematoryName && crematoryName.length > 0;

        if (isValid
            || !isSelected
            || isFieldOptional(ConfigurableRestingPlaceFieldKey.crematoryName, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    secondDispositionPlace: function(
        { secondDispositionPlace }: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValidLocation = secondDispositionPlace &&
            secondDispositionPlace.description &&
            secondDispositionPlace.description.length;
        const isPlaceUnknown = secondDispositionPlace && secondDispositionPlace.unknown === true;

        if (isValidLocation || isPlaceUnknown) {
            return true;
        }

        if (isFieldOptional(ConfigurableRestingPlaceFieldKey.secondDispositionPlace, config, user)) {
            return !touchCheck;
        }

        return false;
    }
};

export const restingPlaceMasterValidators = {
    valid: function(
        restingPlaceForm: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ): boolean {
        return restingPlaceValidators.cemeteryPropertyDetails(restingPlaceForm, config, user)
            && restingPlaceValidators.option(restingPlaceForm, config, user)
            && restingPlaceValidators.specifyOther(restingPlaceForm, config, user)
            && restingPlaceValidators.dispositionPlace(restingPlaceForm, config, user)
            && restingPlaceValidators.secondDispositionPlace(restingPlaceForm, config, user)
            && restingPlaceValidators.laidToRestDate(restingPlaceForm, config, gatherCase, user)
            && restingPlaceValidators.ownsCemeteryProperty(restingPlaceForm, config, user)
            && restingPlaceValidators.crematoryName(restingPlaceForm, config, user);
    },
    touched: function(
        restingPlaceForm: DeathCertificateRestingPlaceType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ): boolean {
        return restingPlaceValidators.option(restingPlaceForm, config, user, true) ||
            restingPlaceValidators.specifyOther(restingPlaceForm, config, user, true) ||
            restingPlaceValidators.laidToRestDate(restingPlaceForm, config, gatherCase, user, true) ||
            restingPlaceValidators.ownsCemeteryProperty(restingPlaceForm, config, user, true) ||
            restingPlaceValidators.dispositionPlace(restingPlaceForm, config, user, true) ||
            restingPlaceValidators.secondDispositionPlace(restingPlaceForm, config, user, true) ||
            restingPlaceValidators.crematoryName(restingPlaceForm, config, user, true) ||
            restingPlaceValidators.cemeteryPropertyDetails(restingPlaceForm, config, user, true);
    },
};