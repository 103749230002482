import * as classNames from 'classnames';

import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';

import WatchIcon from '@mui/icons-material/Watch';
import DiamondIcon from '@mui/icons-material/Diamond';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForever from '@mui/icons-material/DeleteForever';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import FileUploader, { AcceptFileType } from '../../../../common/FileUploader';
import makeGStyles from '../../../../../styles/makeGStyles';
import { getIntercomTargetProp } from '../../../../../services';

const useStyles = makeGStyles(theme => ({
    root: {},
    optionalPhotoBtn: {
        width: 250,
        height: 250,
        borderRadius: 8,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        position: 'relative',
        overflow: 'hidden',
        '& svg': {
            color: 'inherit'
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    cursorAuto: {
        cursor: 'auto'
    },
    deleteIcon: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        filter: 'drop-shadow(0 0px 2px gray)',
        margin: 4,
        '& svg': {
            color: '#fff'
        }
    },
    addPhotoIcon: {
        marginTop: -12,
        fontSize: 64
    },
    gap4: {
        gap: '4px'
    },
}));

interface Props {
    optionalPhoto: string | null;
    deletePhoto: () => void;
    savePhoto: (files: FileList | null) => void;
}
const UploadOptionalPhoto = (props: Props) => {
    const { optionalPhoto, savePhoto, deletePhoto } = props;
    const classes = useStyles();

    const renderInitContent = () => (
        <div {...getIntercomTargetProp(`TrackingPage-AddBelonging-UploadPhoto`)}>
            <span className={classNames(classes.flexCentred, classes.gap4)}>
                <WatchIcon className={classes.fontSize28} />

                <DiamondIcon className={classes.fontSize40} />

                <AddAPhotoIcon className={classes.addPhotoIcon} />

                <AccountBalanceWalletIcon className={classes.fontSize36} />

                <DryCleaningIcon className={classes.fontSize32} />
            </span>

            <span className={classNames(classes.fontSize16, classes.marginTop5)}>
                Take an Optional Photo
            </span>
        </div>
    );

    return (
        <FileUploader
            acceptTypes={[AcceptFileType.PNG, AcceptFileType.JPEG, AcceptFileType.GIF]}
            handleSave={savePhoto}
        >
            {(inputRef) =>
                <ButtonBase
                    className={classNames(classes.optionalPhotoBtn, optionalPhoto && classes.cursorAuto)}
                    onClick={e => !optionalPhoto && inputRef.click()}
                    disableRipple={!!optionalPhoto}
                    disableTouchRipple={!!optionalPhoto}
                >
                    {optionalPhoto
                        ? <>
                            <img src={optionalPhoto} alt="Item Photo" />
                            <IconButton className={classes.deleteIcon} onClick={deletePhoto} component="span">
                                <DeleteForever className={classes.fontSize32} />
                            </IconButton>
                        </>
                        : renderInitContent()
                    }
                </ButtonBase>
            }
        </FileUploader>
    );
};

export default UploadOptionalPhoto;
