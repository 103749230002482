import AppBlockingIcon from "@mui/icons-material/AppBlocking";
import Divider from "@mui/material/Divider";
import { lighten } from "@mui/material/styles";
import classNames from "classnames";
import { KEEP_TRACK_TRADEMARK } from "../../../../constants";
import { KeeptrackCardType } from "../../../../shared/types";
import makeGStyles from "../../../../styles/makeGStyles";
import { RouteBuilder } from "../../../../services";
import GLinkWithButton from "../../../common/GLinkWithButton";

const useStyles = makeGStyles(theme => ({
    marginTop4: {
        marginTop: 4
    },
    initializeTracking: {
        marginLeft: 8,
        background: '#fff',
        border: `2px dashed ${theme.palette.secondary.main} !important`,
        borderRadius: 12,
        '&:hover': {
            background: lighten(theme.palette.secondary.main, 0.96)
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0
        },
        '& span': {
            paddingTop: 0
        }
    },
    initializeDivider: {
        margin: '0 0 10px',
        borderColor: theme.palette.secondary.main
    },
    btn: {
        maxWidth: 286
    },
    noInitializeStep: {
        color: `${theme.palette.secondary.main} !important`,
    },
    pointerEventsAll: {
        pointerEvents: 'all'
    },
    mb2: {
        marginBottom: 2
    },
    padding8: {
        padding: 8
    },
    marginLeft14: {
        marginLeft: -14
    },
    margin6: {
        margin: 6
    }

}), { name: 'InitializeKeepTrackButton' });

interface Props {
    caseFname: string;
    caseName: string;
    funeralHomeKey: string;
    hasInitializeStep: boolean;
    workFlowName: string | null;
    onWorkFlowSelectClick: () => void;
}

const InitializeKeepTrackButton = ({
    caseFname,
    caseName,
    funeralHomeKey,
    hasInitializeStep,
    workFlowName,
    onWorkFlowSelectClick
}: Props) => {
    const classes = useStyles();

    let content = <>
        <span className={classes.flexColumnCentered}>
            <AppBlockingIcon fontSize="large" />
        </span>

        <Divider flexItem className={classNames(classes.initializeDivider, classes.mb2, classes.marginTop2)} />

        <span className={classNames(classes.flexColumnCentered, classes.fontSize12, classes.paragraphLineHeight)}>
            {workFlowName && <span>Oops, the "{workFlowName}" WorkFlow doesn't have an Initialize step.</span>}
            {!workFlowName && <span>Oops, you must select a WorkFlow before initializing.</span>}

            <span className={classNames(classes.marginTop12, classes.paragraphLineHeight)}>
                {workFlowName && `Please add an Initialize step in the back office or `}<span
                    className={classNames(classes.textClickable, classes.pointerEventsAll)}
                    tabIndex={1}
                    onClick={onWorkFlowSelectClick}
                >
                    Select a {workFlowName && `different `}WorkFlow
                </span>
            </span>
        </span>
    </>;
    if (hasInitializeStep) {
        content = <>
            <span className={classNames(classes.flexColumnCentered, classes.margin6)}>
                <span className={classNames(classes.fontSize24, classes.padding8, classes.marginTop4)}>
                    Initialize {KEEP_TRACK_TRADEMARK}
                </span>
                <span className={classNames(classes.fontSize14, classes.marginLeft14)}>
                    Click to Begin Tracking for {caseFname}</span>
            </span>
        </>;
    }

    return (
        <GLinkWithButton
            to={RouteBuilder.KeepTrack({
                funeralHomeKey,
                caseName,
                cardType: KeeptrackCardType.keeptrack,
            })}
            disabled={!hasInitializeStep}
            GButtonProps={{
                variant: 'outlined',
                color: 'secondary',
            }}
            buttonClass={classNames(
                classes.backgroundWhite,
                classes.initializeTracking,
                classes.widthFitContent,
                classes.flexColumnCentered,
                classes.fontWeight400,
                classes.textTransformNone,
                classes.btn,
                classes.width100,
                !hasInitializeStep && classes.noInitializeStep
            )}
        >
            {content}
        </GLinkWithButton>
    );
};

export default InitializeKeepTrackButton;