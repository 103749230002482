import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface Props {
    size?: number;
    thickness?: number;
    text?: string;
    children?: JSX.Element;
    containerClass?: string;
    textClass?: string;
    loaderColor?: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        textAlign: 'center',
        display: "flex",
        width: '100%',
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '0px 8px 8px',
    },
}), { name: 'LoadingSpinner' });

const LoadingSpinner = (props: Props) => {
    const { size, thickness, text, containerClass, textClass, loaderColor } = props;

    const classes = useStyles();

    return (
        <div className={classNames(classes.loadingContainer, containerClass)}>
            <CircularProgress
                sx={{
                    color: loaderColor ? loaderColor : 'primary'
                }}
                size={size}
                thickness={thickness}
            />
            {text !== '' &&
                <Typography
                    color="secondary"
                    align="center"
                    noWrap
                    className={classNames(classes.loadingText, textClass)}
                >
                    {text || 'Loading...'}
                </Typography>
            }
        </div>
    );
};

export default LoadingSpinner;
