import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';

import { DATA_PRIVATE } from '../../../../styles/Helper.styles';

import { SnackBarVariant } from '../../../../types';
import { UserProfile, GatherCaseUX, UserRoles } from '../../../../shared/types';

import { useTaskDialogStyles } from '../TaskDialogsStyles';

import {
    getFullNameFromCase,
    emptyStringParser,
    getFullNameFromUser,
    getFormattedPhoneNumber,
} from '../../../../services/app.service';

import { NULL_STRING } from '../../../../constants';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
    userData: UserProfile | null;
}

const TMobileService = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
        userData,
        setAppSnackbar
    } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Letter copied to your clipboard', 'success');
    };

    const renderEmailbody = (
        _ssn: string,
        _helperName: string,
        _helperEmail: string,
        _helperPhone: string,
        _helperRelation: string,
    ): string => {
        return `Dear T-Mobile,

${getFullNameFromCase(activeCase)}, a T-Mobile customer, recently passed away and we need to cancel ` +
            `their T-Mobile service, effective immediately. The required information is listed below.

- Account holder's name: ${getFullNameFromCase(activeCase)}
- ${activeCase.fname}’s phone number: ${NULL_STRING}
- ${activeCase.fname}’s account number (if available): ${NULL_STRING}
- Last 4 digits of ${activeCase.fname}’s Social Security number: ${_ssn}
- My name: ${_helperName}
- My email: ${_helperEmail}
- My phone number: ${_helperPhone}
- My relationship to ${activeCase.fname}: ${_helperRelation}

Per your request, I am also including the following: ${NULL_STRING}
(Choose one: Copy of death certificate, Electronic link to memorial website, Obituary (electronic or paper), ` +
            `Copy of memorial, Attorney / legal estate documents, or Other reasonable documentation)

It would be greatly appreciated if you can confirm via email when this action is completed.

Thanks for your help in this important matter,
${_helperName}`;
    };

    const tMobileContact: string = '1-877-746-0909';

    const isFhUser: boolean = UserRoles.isFHUser(userData);

    let ssn: string = NULL_STRING;

    if (activeCase.death_certificate && activeCase.death_certificate.about.ssn
        && emptyStringParser(activeCase.death_certificate.about.ssn)) {
        const fullSsn = activeCase.death_certificate.about.ssn;
        ssn = fullSsn.substr(fullSsn.length - 4);
    }

    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;
    const helperEmail: string = userData && userData.email || NULL_STRING;

    let helperPhone: string = NULL_STRING;
    if (userData && userData.phone) {
        const phoneNumber = getFormattedPhoneNumber(userData.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    } else if (isFhUser && activeCase.funeral_home.phone) {
        const phoneNumber = getFormattedPhoneNumber(activeCase.funeral_home.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    }

    const helperRelation: string = isFhUser
        ? `I am the family-appointed Funeral Director from ${activeCase.funeral_home.name}` : NULL_STRING;

    const emailBody: string = renderEmailbody(
        ssn,
        helperName,
        helperEmail,
        helperPhone,
        helperRelation,
    );
    const tooltipText = `This will copy this letter to your device clipboard so that you can paste it 
                            in another program.`;

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    If you were added as an authorized user on {activeCase.fname}’s account, you can
                    simply call T-mobile and they will cancel {activeCase.fname}’s service. Their number
                    is {` ${tMobileContact} `}(or just dial 611 from a T-Mobile phone).

                </Typography>
                <a
                    href={`tel: ${tMobileContact}`}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop20
                        )}
                    >
                        CALL T-MOBILE
                    </Button>
                </a>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                    align="left"
                >
                    If you were not added as an authorized user on  {activeCase.fname}’s account, you will
                    need to mail a letter to T-Mobile. To make it as easy as possible for you, we’ve already
                    typed out the letter with as much of the required information as possible.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Dear T-Mobile,
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    {getFullNameFromCase(activeCase)}, a T-Mobile customer,
                    recently passed away and we need to cancel their T-Mobile service, effective
                    immediately. The required information is listed below.
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        - Account holder's name: <span className={classes.fontWeight500}>
                            {getFullNameFromCase(activeCase)}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s phone number: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s account number (if available): {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - Last 4 digits of {activeCase.fname}’s Social Security number:
                        <span className={classNames(classes.fontWeight500, DATA_PRIVATE)}>
                            &nbsp;{ssn}
                        </span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My name: <span className={classes.fontWeight500}>{helperName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My email: <span className={classes.fontWeight500}>{helperEmail}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My phone number: <span className={classes.fontWeight500}>{helperPhone}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My relationship to {activeCase.fname}: <span className={classes.fontWeight500}>
                            {helperRelation}</span>
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    Per your request, I am also including the following: ___
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (Choose one: Copy of death certificate, Electronic link to memorial website, Obituary
                        (electronic or paper), Copy of memorial, Attorney / legal estate documents, or Other
                        reasonable documentation)
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        It would be greatly appreciated if you can confirm via email when this action is
                        completed.
                    </span>

                    <span
                        className={classNames(
                            classes.displayBlock,
                            classes.marginTop20
                        )}
                    >
                        Thanks for your help in this important matter,
                        <span className={classes.displayBlock}>
                            {`${userData ? userData.fname : ''} 
                                    ${userData ? userData.lname : ''}`}
                        </span>
                    </span>
                </Typography>

            </Grid>

            <Grid
                item
                xs={12}
                md={8}
                className={classNames(classes.marginAuto, DATA_PRIVATE)}
            >
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.marginTop12}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this letter
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                className={classNames(
                    classes.marginAuto,
                    classes.textLeft,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize16,
                        classes.fontWeight300,
                    )}
                >
                    Mail this letter to the following address:
                    <span className={classNames(classes.displayBlock, classes.fontWeight500)}>
                        Customer Relations
                    </span>
                    <span className={classNames(classes.displayBlock, classes.fontWeight500)}>
                        PO Box 37380
                    </span>
                    <span className={classNames(classes.displayBlock, classes.fontWeight500)}>
                        Albuquerque, NM 87176-7380
                    </span>
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop30,
                    classes.marginAuto
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Don’t forget to attach ONE of the following...
                </Typography>
                <Grid
                    item

                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        <li>
                            Copy of death certificate
                        </li>
                        <li>
                            Electronic link to memorial website
                        </li>
                        <li>
                            Obituary (electronic link or paper)
                        </li>
                        <li>
                            Copy of memorial
                        </li>
                        <li>
                            Attorney/Legal estate documents
                        </li>
                        <li>
                            or other reasonable documentation
                        </li>
                    </Typography>
                </Grid>
            </Grid>

            <Grid>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize16,
                        classes.fontWeight300,
                        classes.marginTop30
                    )}
                >
                    <a
                        className={classNames(
                            classes.cursorPointer,
                            classes.fontWeight500,
                            classes.textDecorationNone,
                            classes.colorSecondary,
                        )}
                        target="blank"
                        href="https://support.t-mobile.com/community/contact-us"
                    >
                        {'Click here '}
                    </a>
                    <span>
                        to contact T-Mobile directly
                    </span>
                </Typography>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default TMobileService;
