import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { WithGStyles } from '../../../styles/WithGStyles';

export function wrappedStyles<Props extends object>() {
    const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
        root: {},
        uploadNewPhotoContainer: {
            // background: '#fff',
            background: 'transparent',
            color: theme.palette.secondary.main,
            position: 'relative',
            textAlign: 'center',
            verticalAlign: 'top',
            margin: 0,
            width: '100%',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 'calc(100% - 8px)',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.08)',
            },
        },
        buttonTextAbsolute: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.primary.main
        },
        addPhotoIcon: {
            fontSize: 36,
        },
        squareButtonText: {
            fontSize: 12,
            margin: 0,
        },
        downloadButton: {
            fontSize: 12,
            padding: '6px 12px',
            '& svg': {
                fontSize: 24,
            }
        },
        disabledDownloadButton: {
            opacity: 0.4,
        },
        loader: {
            margin: 24,
        },
        loaderContainer: {
            textAlign: 'center',
            margin: 12,
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        loadingText: {
            color: theme.palette.secondary.main,
            margin: '-6px 8px 8px',
        },
        containerPad: {
            padding: 20,
            '& .ReactVirtualized__Masonry__innerScrollContainer': {
                overflow: 'visible !important',
            },
        },
        masonList: {
            zIndex: 2,
        },
    });

    return styles;
}

export type StyledProps = WithGStyles<'root' | 'uploadNewPhotoContainer' | 'buttonTextAbsolute'
    | 'addPhotoIcon' | 'squareButtonText' | 'downloadButton' | 'disabledDownloadButton' | 'loader'
    | 'loaderContainer' | 'loadingText' | 'containerPad' | 'masonList'>;
