import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { dateToString, getFullNameFromEntity } from "../../../services";
import {
    canDeletePerson,
    canEditNonSensitivePersonDetails,
    canResendInvitation,
    canRevokeInvitation
} from "../../../shared/authority/can";
import {
    EntityCaseRole,
    EntitySummary,
    GatherCaseUX,
    UserRoles,
    getCaseEntity,
    getDisplayTitleForFHUser,
    isNonUserCaseHelper
} from "../../../shared/types";
import { getRelationshipDisplayValue } from "../../common/AcquaintanceButtons";
import { useGSelector } from "../../../types";
import { MouseEvent, useMemo } from "react";
import moment from "moment-timezone";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, styled } from "@mui/material/styles";
import { GREEN_COLOR, ORANGE_COLOR, SKYBLUE_COLOR_2 } from "../../../constants/colorVariables";
import { GStyles } from "../../../styles/GStyles";
import GTooltip from "../../common/GTooltip";

const Root = styled(Grid)({
    padding: '0 4px',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
});

interface EntityTypeTextProps {
    isDeceased: boolean;
    isAdminEntity: boolean;
}
const EntityTypeText = styled(Typography, {
    shouldForwardProp: propName => propName !== 'isDeceased' && propName !== 'isAdminEntity'
})<EntityTypeTextProps>(({ isDeceased, isAdminEntity }) => ({
    ...(isAdminEntity && {
        color: SKYBLUE_COLOR_2,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            color: SKYBLUE_COLOR_2,
            fontSize: 18,
        }
    }),
    ...(!isAdminEntity && {
        color: '#757575',
        fontSize: 14,
        marginLeft: 4,
        cursor: isDeceased ? 'auto !important' : 'pointer',
    }),
}));

interface EntityStatusButtonProps {
    isAccepted: boolean;
    isNonUser: boolean;
}
const EntityStatusButton = styled(Button, {
    shouldForwardProp: propName => propName !== 'isAccepted' && propName !== 'isNonUser'
})<EntityStatusButtonProps>(({ isAccepted, isNonUser }) => ({
    padding: 0,
    textTransform: 'capitalize',
    fontSize: 14,
    fontWeight: 400,
    minHeight: 24,
    color: isNonUser || !isAccepted ? ORANGE_COLOR : GREEN_COLOR,
    '& svg': {
        fontSize: 16
    },
}));

interface EntityNameProps {
    isEditable: boolean;
}
const EntityName = styled(Typography, {
    shouldForwardProp: propName => propName !== 'isEditable'
})<EntityNameProps>(({ isEditable }) => ({
    fontSize: 24,
    lineHeight: '1.25em',
    ...(isEditable && {
        cursor: 'pointer',
        fontSize: 22,
        maxWidth: 300,
        '&:hover': {
            textDecoration: 'underline'
        }
    })
}));

interface RelationshipTextProps {
    isEditable: boolean;
}
const RelationshipText = styled(Typography, {
    shouldForwardProp: propName => propName !== 'isEditable'
})<RelationshipTextProps>(({ isEditable }) => ({
    fontSize: 14,
    lineHeight: '1.25',
    fontWeight: 500,
    ...(isEditable && {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }),
    '& span': {
        color: alpha('#000', 0.3),
    },
    '#hp-heart-icon': {
        color: 'inherit',
        fontSize: 16,
        lineHeight: '12px'
    }
}));

interface Props {
    selectedCase: GatherCaseUX;
    activeEntity: EntitySummary;
    isAdminEntity: boolean;
    isMenuOpen: boolean;
    onEntityStatusClick: (event: MouseEvent<HTMLElement>) => void;
    onEntityDetailsClick: () => void;
    onEntityTypeClick: (event: MouseEvent<HTMLElement>, entityCaseRole: EntityCaseRole) => void;
}
const HelperPopperHeader = (props: Props) => {
    const {
        activeEntity,
        selectedCase,
        isAdminEntity,
        isMenuOpen,
        onEntityTypeClick,
        onEntityDetailsClick,
        onEntityStatusClick
    } = props;

    const caseEntity = getCaseEntity(activeEntity, selectedCase.id);

    const userData = useGSelector(({ userSession }) => userSession.userData);
    const partner = useGSelector(({ casesState: { helpers } }) =>
        helpers.find(helper => {
            const helperCaseEntity = getCaseEntity(helper, selectedCase.id);

            return helperCaseEntity?.case_entity_id === caseEntity?.partner_case_entity_id;
        }));

    const showMenu = useMemo(() => {
        if (!userData) {
            return false;
        }

        const activeCaseEntity = getCaseEntity(activeEntity, selectedCase.id);
        const hasActiveEntityLoggedIn = activeCaseEntity
            ? activeCaseEntity.accepted_time
            : UserRoles.isFHUser(activeEntity) || activeEntity.entity_id === selectedCase.assignee_entity_id;

        const canDelete = canDeletePerson(userData, activeEntity, selectedCase.funeral_home_id, selectedCase.id);
        const canEdit = canEditNonSensitivePersonDetails(
            userData,
            activeEntity,
            selectedCase.funeral_home_id,
            selectedCase.id
        );
        const canResend = canResendInvitation(
            userData,
            activeEntity,
            selectedCase.id
        );
        const canRevoke = canRevokeInvitation(
            userData,
            activeEntity,
            selectedCase.id
        );
        return hasActiveEntityLoggedIn || isNonUserCaseHelper(activeEntity, selectedCase.id)
            ? (canDelete || canEdit)
            : (canResend || canRevoke);
    }, [
        selectedCase.assignee_entity_id,
        selectedCase.funeral_home_id,
        selectedCase.id,
        userData,
        activeEntity]);

    if (!userData) {
        return null;
    }

    // edit self or edit all if Gather/FH user or edit familyAdmin/familyGuest if familyAdmin
    const isEditable = canEditNonSensitivePersonDetails(
        userData,
        activeEntity,
        selectedCase.funeral_home_id,
        selectedCase.id
    );
    const isFHUser = UserRoles.isFHUser(activeEntity) || activeEntity.entity_id === selectedCase.assignee_entity_id;
    const invitedTime = isFHUser
        ? selectedCase.created_time
        : caseEntity && caseEntity.created_time;
    const acceptedTime = isFHUser
        ? selectedCase.created_time
        : caseEntity && caseEntity.accepted_time;
    const optionsTooltip = !acceptedTime && activeEntity.user
        ? `${activeEntity.fname} has not accepted their invite. Click to resend.`
        : acceptedTime
            ? `Invite accepted by ${activeEntity.fname} on ${dateToString(acceptedTime, 'DD MMM YYYY')}`
            : '';

    const fhDisplayTitle = getDisplayTitleForFHUser(activeEntity, selectedCase.funeral_home_id);

    const relationshipDetails = caseEntity || {
        relationship: null, relationship_alias: '', relationship_type: null
    };
    const displayRelationship = getRelationshipDisplayValue(relationshipDetails, true);

    const entityRelation = isFHUser
        ? fhDisplayTitle
        : displayRelationship.trim()
            ? `${selectedCase.fname}'s ${displayRelationship}`
            : (<span className={classNames(isEditable && GStyles.clickable)}>
                No Relationship Specified
            </span>);

    const invitedByFname = caseEntity && caseEntity.created_by_fname;
    const invitedByText = isFHUser
        ? selectedCase.funeral_home.name
        : invitedByFname && `Invited by ${invitedByFname} ${moment(invitedTime || new Date()).fromNow()}`;

    const entityType = isAdminEntity
        ? 'Admin'
        : activeEntity.is_deceased
            ? 'Deceased'
            : 'Guest';
    const status = acceptedTime ? 'Accepted' : !activeEntity.user ? 'Not Invited' : 'Pending';

    const entityPartnerText = partner
        ? <>{activeEntity.fname} <span id="hp-heart-icon">&#9829;</span> {partner.fname}</>
        : `+ Add ${activeEntity.fname}'s Significant Other`;
    const tooltipTitle = partner
        ? `${getFullNameFromEntity(activeEntity)}'s spouse, partner, or significant other 
            is ${getFullNameFromEntity(partner)}. ` + (isEditable ? 'Click to edit this relationship.' : '')
        : `Click here to connect a spouse, partner, or significant other to ${selectedCase.fname}. 
            This is helpful information that can be used in the obituary.`;

    return (
        <Root>
            <Grid container flexWrap="unset" justifyContent="space-between" alignItems="center">
                <EntityTypeText
                    isDeceased={activeEntity.is_deceased}
                    isAdminEntity={isAdminEntity}
                    onClick={evt => (!activeEntity.is_deceased || isAdminEntity) &&
                        onEntityTypeClick(evt, isAdminEntity ? EntityCaseRole.admin : EntityCaseRole.guest)
                    }
                >
                    {isAdminEntity && <FiberManualRecordIcon />} {entityType}
                </EntityTypeText>

                {showMenu &&
                    <Tooltip title={!isMenuOpen && optionsTooltip || ''} placement="top">
                        <EntityStatusButton
                            isAccepted={!!acceptedTime}
                            isNonUser={!activeEntity.user}
                            size="small"
                            color="secondary"
                            onClick={onEntityStatusClick}
                        >
                            {status}&nbsp;
                            <MoreVertIcon />
                        </EntityStatusButton>
                    </Tooltip>
                }
            </Grid>

            <Grid container alignItems="center" direction="column">
                <Grid item>
                    <Tooltip
                        title={isEditable ? `Click to edit ${activeEntity.fname}'s Details` : ''}
                        placement="top"
                    >
                        <EntityName
                            noWrap
                            align="center"
                            color="primary"
                            isEditable={isEditable}
                            onClick={e => isEditable && onEntityDetailsClick()}
                        >
                            {`${activeEntity.fname} ${activeEntity.lname}`.trim()}
                        </EntityName>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <RelationshipText
                        align="center"
                        color="secondary"
                        isEditable={isEditable}
                        onClick={e => isEditable && onEntityDetailsClick()}
                    >
                        {entityRelation}
                    </RelationshipText>

                    {(isEditable || partner) &&
                        <GTooltip title={tooltipTitle} enterDelay={400}>
                            <RelationshipText
                                align="center"
                                color="secondary"
                                isEditable={isEditable}
                                onClick={e => isEditable && onEntityDetailsClick()}
                            >
                                {entityPartnerText}
                            </RelationshipText>
                        </GTooltip>
                    }

                    {!activeEntity.user && !isFHUser &&
                        <Typography color="secondary" align="center" fontSize={11}>
                            {activeEntity.fname} has not yet been invited
                        </Typography>
                    }
                </Grid>
            </Grid>

            <Typography
                align="center"
                color="secondary"
                mb={1}
                fontSize={11}
            >
                {invitedByText}
            </Typography>
        </Root >
    );
};

export default HelperPopperHeader;