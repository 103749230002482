import { Theme } from '@mui/material/styles';
import withStyles, {StyleRulesCallback, WithStyles} from '@mui/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';
import { getIntercomTargetProp, getPhotoUrlWithoutTransformations } from '../../../../services';
import { ThemePhotoEnum, ThemeUX } from '../../../../shared/types';

const styles: StyleRulesCallback<Theme, Props, Classes> = (theme) => ({
    bottom_left_graphic: {
        width: 420,
        display: 'none',
        '@media (min-width: 1024px)': {
            display: 'initial'
        },
    },
    bottom_right_graphic: {
        width: 420,
        display: 'none',
        '@media (min-width: 1024px)': {
            display: 'initial'
        },
    },
    bottom_mobile_graphic: {
        display: 'block',
        margin: 'auto',
        paddingTop: 6,
        width: '100%',
        maxWidth: 320,
        '@media (min-width: 1024px)': {
            display: 'none'
        }
    },
    top_graphic: {
        width: '100%',
        maxWidth: 420,
        height: 'auto',
        '&:not($small)': {
            '@media (min-width: 768px)': {
                maxWidth: 730
            }
        }
    },
    top_mobile_graphic: {
        width: '100%',
        maxWidth: 420,
        height: 'auto',
        '&:not($small)': {
            '@media (min-width: 768px)': {
                maxWidth: 730
            }
        }
    },
    small: {},
    medium: {},
    top_accent_image_reversed: {},
    top_accent_image: {}
});

type Classes =  'small' | 'medium';
type StyledProps = WithStyles<Classes>;

interface Props {
    activeTheme: ThemeUX | null;
    type: ThemePhotoEnum;
    size?: 'small' | 'medium';
    className?: string;
    style?: React.CSSProperties;
}

const RememberThemeGraphics = ({
    activeTheme,
    type,
    className,
    classes,
    style,
    size
}: Props & StyledProps) => {
    if (!activeTheme) {
        return null;
    }

    const src = getPhotoUrlWithoutTransformations(activeTheme[type] ?? '');

    return (
        <>
            {!!src && <img
                src={src}
                alt={type}
                className={classNames(classes[type], size && classes[size], className)}
                style={style}
                height="auto"
                {...getIntercomTargetProp((type === ThemePhotoEnum.top_graphic
                    || type === ThemePhotoEnum.top_mobile_graphic)
                    ? `RememberPage-ThemeGraphic` : ``)}
            />}
        </>
    );
};

export default withStyles(styles)(RememberThemeGraphics);