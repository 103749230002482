import { paperClasses } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GPopper from "../../common/GPopper";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { LongAddress } from "../../../shared/types";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Theme, styled } from "@mui/material/styles";
import GButton from "../../common/GButton";
import Link from "@mui/material/Link";
import { getAddressFromLongAddress } from "../../../services";
import { GStyles } from "../../../styles/GStyles";

const PopperDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.main
}));

const styles = {
    locationIcon: {
        verticalAlign: 'middle',
        fontSize: 60,
        display: 'inline-block'
    },
    popper: (theme: Theme) => ({
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
        [`.${paperClasses.root}`]: {
            borderRadius: '4px',
            width: 310,
            boxShadow: theme.shadows[10],
            padding: '36px 8px 20px',
            '@media (min-width: 340px)': {
                width: 320,
            },
        }
    })
};

interface Props {
    zIndex: number;
    anchorEle: HTMLElement | null;
    isPickUp: boolean;
    address: LongAddress;
    onEdit: () => void;
    closePopper: () => void;
}
const AddressPopper = (props: Props) => {
    const { zIndex, anchorEle, isPickUp, address, closePopper, onEdit } = props;

    const parsedCoords: string[] | null = typeof address.coords === 'string'
        ? JSON.parse(address.coords)
        : address.coords;
    const caseGeoHref = parsedCoords
        ? `https://google.com/maps/search/?api=1&query=${encodeURIComponent(
            `${parsedCoords[0]},${parsedCoords[1]}`
        )}`
        : undefined;

    return (
        <GPopper
            zIndex={zIndex}
            anchorEle={anchorEle}
            closePopper={closePopper}
            sx={styles.popper}
        >
            <PopperDivider />

            <Grid
                container
                flexWrap="nowrap"
                p="8px 32px"
                alignItems="center"
                justifyContent="center"
            >
                <LocationOnIcon
                    color="primary"
                    sx={styles.locationIcon}
                />

                <Typography
                    color="secondary"
                    align="center"
                    fontSize={16}
                    display="inline-block"
                    lineHeight="18px"
                    sx={{ verticalAlign: 'middle' }}
                >
                    {getAddressFromLongAddress(address)}
                </Typography>
            </Grid>

            <PopperDivider />

            <Typography
                mt={2}
                color="primary"
                textAlign="center"
                className={GStyles.textClickable}
                onClick={onEdit}
            >
                Edit {isPickUp ? 'Pickup': 'Drop-Off'} Location
            </Typography>

            {caseGeoHref &&
                <Grid container justifyContent="center" mt={2}>
                    <Link target="_blank" href={caseGeoHref}>
                        <GButton
                            text="View on Map"
                            variant="outlined"
                            startIcon={<LocationOnIcon />}
                        />
                    </Link>
                </Grid>
            }
        </GPopper>
    );
};

export default AddressPopper;