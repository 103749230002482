import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';
import UndoIcon from '@mui/icons-material/Undo';

import { SnackBarVariant } from '../../../../../types';
import { GatherCaseUX, UserProfile, UserRoles } from '../../../../../shared/types';

import { NULL_STRING } from '../../../../../constants';

import {
    getFullNameFromCase,
    getFormattedPhoneNumber,
    emptyStringParser,
    getFullNameFromUser
} from '../../../../../services/app.service';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountType, OnlineAccountDeactivateStep } from '../utils';

interface Props {
    userData: UserProfile | null;
    activeCase: GatherCaseUX;
    accountType: OnlineAccountType;
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    onStepChange: (step: OnlineAccountDeactivateStep) => void;
}

const CanNotLogin = (props: Props) => {
    const { setAppSnackbar, activeCase, accountType, onStepChange, userData } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Email copied to your clipboard', 'success');
    };

    const renderEmailBody = (
        _caseAddress: string,
        _helperName: string,
        _helperEmail: string,
        _helperPhone: string
    ) => {
        return `Dear ${accountType} Customer Support,

${getFullNameFromCase(activeCase)}, ${'an Amazon customer, recently passed away and we need to cancel' +
            ` their ${accountType} account, effective immediately. The required information is listed below.`}

- Account holder's name: ${getFullNameFromCase(activeCase)}
- ${activeCase.fname}’s phone number: ${NULL_STRING}
- ${activeCase.fname}’s email: ${NULL_STRING}
- ${activeCase.fname}’s billing address: ${_caseAddress}
- My name: ${_helperName}
- My email: ${_helperEmail}
- My phone number: ${_helperPhone}

Per your request, I am also attaching the following: ${NULL_STRING}
${'(If you are the estate executor: A court-issued letter of administration for an estate subject to probate)'}
(If you are a spouse or family member: A scan or photo of ${activeCase.fname}'s death certificate)

It would be greatly appreciated if you can confirm when this action is completed.

Thanks for your help in this important matter,
${_helperName}`;
    };

    const isFhUser: boolean = UserRoles.isFHUser(userData);

    const sprintEmail: string = 'account-close-deceased@amazon.com';
    const emailSubject: string = `Cancel ${getFullNameFromCase(activeCase)}'s ${accountType} Account`;

    const caseAddress: string = activeCase.death_certificate
        && emptyStringParser(activeCase.death_certificate.life.residencePlace.description)
        ? activeCase.death_certificate.life.residencePlace.description : NULL_STRING;

    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;
    const helperEmail: string = userData && userData.email || NULL_STRING;

    let helperPhone: string = NULL_STRING;
    if (userData && userData.phone) {
        const phoneNumber = getFormattedPhoneNumber(userData.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    } else if (isFhUser && activeCase.funeral_home.phone) {
        const phoneNumber = getFormattedPhoneNumber(activeCase.funeral_home.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    }

    const emailBody: string = renderEmailBody(caseAddress, helperName, helperEmail, helperPhone);
    const tooltipText = `This will copy this email to your device clipboard so that you can paste it 
                            in another program.`;

    return (
        <Grid>
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.marginBottom10,
                    classes.marginTop20,
                    classes.fontSize16,
                    classes.fontWeight300
                )}
            >
                <span
                    className={classes.cursorPointer}
                    onClick={() => onStepChange(OnlineAccountDeactivateStep.AccessToEmailQueType)}
                >
                    <IconButton color="primary" size="large">
                        <UndoIcon />
                    </IconButton>
                    Return to previous question
                </span>
            </Typography>
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.fontSize28,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Contact {accountType} Directly
            </Typography>
            <Typography
                color="secondary"
                align="left"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                It looks like you won't be able to log in to {activeCase.fname}'s {accountType} account.
                No worries, we can still help. You will need to send an email to
                <span
                    className={classes.fontWeight500}

                >
                    {' account-close-deceased@amazon.com'}
                </span>
                . We wanted to make this as easy as possible for you so we went ahead and typed up the email with
                as much of the information as we could fill out.
            </Typography>
            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Dear {accountType} Customer Support,
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    {getFullNameFromCase(activeCase)}, an {accountType} customer, recently passed
                    away and we need to cancel their {accountType} account, effective immediately. The
                    required information is listed below.
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        - Account holder's name: <span className={classes.fontWeight500}>
                            {`${getFullNameFromCase(activeCase)}`}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s phone number: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s email: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s billing address: <span className={classes.fontWeight500}>
                            {caseAddress}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My name: <span className={classes.fontWeight500}>{helperName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My email: <span className={classes.fontWeight500}>{helperEmail}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My phone number: <span className={classes.fontWeight500}>{helperPhone}</span>
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    Per your request, I am also attaching the following: {NULL_STRING}
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (If you are the estate executor: <span className={classes.fontWeight500}>
                            A court-issued letter of administration for an estate subject to probate</span>)
                    </span>
                    <span className={classNames(classes.fontStyleItalic, classes.displayBlock)}>
                        (If you are a spouse or family member: <span className={classes.fontWeight500}>
                            A scan or photo of {activeCase.fname}'s death certificate</span>)
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        It would be greatly appreciated if you can confirm when this action is completed.
                    </span>

                    <span
                        className={classNames(
                            classes.displayBlock,
                            classes.marginTop20
                        )}
                    >
                        Thanks for your help in this important matter,
                        <span className={classes.displayBlock}>
                            {helperName}
                        </span>
                    </span>
                </Typography>

            </Grid>
            <Grid
                item
                xs={12}
                md={8}
                className={classes.marginAuto}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize16,
                        classes.fontWeight300,
                        classes.marginTop12
                    )}
                >
                    Clicking below will open your email provider and
                    allow you to edit and send this email.
                </Typography>
                <a
                    href={`mailto:${sprintEmail}?` +
                        `subject=${emailSubject}&body=` + encodeURIComponent(`${emailBody}`)}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                    >
                        Click To Edit &amp; Send
                    </Button>
                </a>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                    )}
                >
                    or...
                </Typography>
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.button}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this email
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Please consider the following...
                </Typography>
                <Grid
                    item

                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        After {activeCase.fname}'s account is closed, it is no longer accessible by
                        you or anyone else.
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            You won't have access to other websites
                            using {activeCase.fname}'s {accountType}.com log-in
                        </li>
                        <li className={classes.marginTop10}>
                            You won't have access to digital content related
                            to {accountType} Music, {accountType} Drive and/or Prime Photos,
                            or {activeCase.fname}'s {accountType} Appstore purchases
                        </li>
                        <li className={classes.marginTop10}>
                            You won't have access to {activeCase.fname}'s account history (including credit card
                            information, order history, etc.)
                        </li>
                        <li className={classes.marginTop10}>
                            Returns and refunds can't be processed for orders on closed accounts
                        </li>
                        <li className={classes.marginTop10}>
                            If {activeCase.fname} had a remaining {accountType}.com Gift Card or promotional credit
                            balance, you won't have access to use the funds
                        </li>
                        <li className={classes.marginTop10}>
                            Important: Once {activeCase.fname}'s account is closed, you'll no longer have access
                            to any Kindle, {accountType} Drive and/or Prime Photos content and subscriptions
                            via {activeCase.fname}'s account. Once an account is closed, {accountType} Drive
                            and/or Prime Photos content will be deleted and cannot be restored. Consequently,
                            we'd suggest that you download and save any content that you haven't already
                            downloaded or permanently saved before you have {activeCase.fname}'s account closed.
                        </li>
                    </ul>
                </Grid>
            </Grid>

        </Grid >
    );
};

export default CanNotLogin;
