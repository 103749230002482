import { getFromAPI, postToAPI, putToAPI } from ".";
import { log } from "../logger";
import {
    AssignKeepTrackRequest,
    AssignKeepTrackSearchParam,
    FuneralHomeUXPreview,
    GatherCaseSummary,
    GatherCaseUX,
    KeepTrackPageResponse,
    KeeptrackTagAssignableResponse,
    PaginatedResponse,
} from "../shared/types";
import { AppDispatch } from "../store";
import { parse, stringify } from 'query-string';
import { loadCaseSummaries } from "./GatherCase.action";
import { loadFuneralHomePreviewsFromAPI } from "./FuneralHome.action";

export const SELECTED_CASE_FOR_KEEPTRACK_ASSIGNMENT = 'SELECTED_KEEP_TRACK_CASE';

interface SelectedCaseForKeeptrackAssignment {
    type: typeof SELECTED_CASE_FOR_KEEPTRACK_ASSIGNMENT;
    caseSummary: GatherCaseSummary;
}

export function selectedCaseForKeeptrackAssignment(caseSummary: GatherCaseSummary): SelectedCaseForKeeptrackAssignment {
    return {
        type: SELECTED_CASE_FOR_KEEPTRACK_ASSIGNMENT,
        caseSummary,
    };
}

export function checkKeepTrackTag(params: {
    keepsakeId: string;
    trackerId: string | null;
}) {
    const { keepsakeId, trackerId } = params;
    return async (dispatch: AppDispatch): Promise<KeeptrackTagAssignableResponse | null> => {

        let route = `api/keeptrack/keepsake/${keepsakeId}`;
        if (trackerId) {
            route += `/tracker/${trackerId}`;
        }
        const response = await getFromAPI<KeeptrackTagAssignableResponse>(route, dispatch);
        return response;
    };
}

export function assignKeepTrack(params: {
    request: AssignKeepTrackRequest;
    caseUuid: string;
}) {
    const { request, caseUuid } = params;
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {

        try {
            AssignKeepTrackRequest.fromRequest(request);
        } catch (e) {
            log.warn('Failed to validate AssignKeepTrackRequest', { request, e });
            return null;
        }

        const gatherCase = await postToAPI<GatherCaseUX>(`api/case/${caseUuid}/keeptrack`, request, dispatch);
        return gatherCase;
    };
}

export function assignAdditionalKeepsake(params: {
    caseUuid: string;
    tagId: string;
}) {
    const { caseUuid, tagId } = params;
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {

        const resource = `api/case/${caseUuid}/keeptrack/additional/${tagId}`;
        const gatherCase = await putToAPI<GatherCaseUX>(resource, {}, dispatch);
        return gatherCase;
    };
}

export const LOADING_KEEP_TRACK_CASES = 'LOADING_KEEP_TRACK_CASES';

interface LoadingKeepTrackCases {
    type: typeof LOADING_KEEP_TRACK_CASES;
    clearCases: boolean;
}

function loadingKeepTrackCases(clearCases: boolean): LoadingKeepTrackCases {
    return {
        type: LOADING_KEEP_TRACK_CASES,
        clearCases,
    };
}

export const LOADED_KEEP_TRACK_CASES = 'LOADED_KEEP_TRACK_CASES';

interface LoadedKeepTrackCases {
    type: typeof LOADED_KEEP_TRACK_CASES;
    response: PaginatedResponse<GatherCaseSummary>;
}

function loadedKeepTrackCases(response: PaginatedResponse<GatherCaseSummary>): LoadedKeepTrackCases {
    return {
        type: LOADED_KEEP_TRACK_CASES,
        response,
    };
}

export const LOAD_KEEP_TRACK_CASES_FAILED = 'LOAD_KEEP_TRACK_CASES_FAILED';

interface LoadKeepTrackCasesFailed {
    type: typeof LOAD_KEEP_TRACK_CASES_FAILED;
}

function loadKeepTrackCasesFailed(): LoadKeepTrackCasesFailed {
    return {
        type: LOAD_KEEP_TRACK_CASES_FAILED,
    };
}

export function loadKeepTrackCases(params: {
    funeralHomeId: number;
    offset: number;
    searchText: string;
}) {
    return async (dispatch: AppDispatch): Promise<PaginatedResponse<GatherCaseSummary> | null> => {
        const { funeralHomeId, offset, searchText } = params;
        dispatch(loadingKeepTrackCases(offset === 0));

        const response = await dispatch(loadCaseSummaries({
            funeralHomeId,
            offset,
            searchText,
        }));
        if (!response) {
            dispatch(loadKeepTrackCasesFailed());
            return null;
        }
        dispatch(loadedKeepTrackCases(response));
        return response;
    };
}

export const LOADING_KEEP_TRACK_FUNERAL_HOMES = 'LOADING_KEEP_TRACK_FUNERAL_HOMES';

interface LoadingKeepTrackFuneralHomes {
    type: typeof LOADING_KEEP_TRACK_FUNERAL_HOMES;
    clearFuneralHomes: boolean;
}

function loadingKeepTrackFuneralHomes(clearFuneralHomes: boolean): LoadingKeepTrackFuneralHomes {
    return {
        type: LOADING_KEEP_TRACK_FUNERAL_HOMES,
        clearFuneralHomes,
    };
}

export const LOADED_KEEP_TRACK_FUNERAL_HOMES = 'LOADED_KEEP_TRACK_FUNERAL_HOMES';

interface LoadedKeepTrackFuneralHomes {
    type: typeof LOADED_KEEP_TRACK_FUNERAL_HOMES;
    response: PaginatedResponse<FuneralHomeUXPreview>;
}

function loadedKeepTrackFuneralHomes(response: PaginatedResponse<FuneralHomeUXPreview>): LoadedKeepTrackFuneralHomes {
    return {
        type: LOADED_KEEP_TRACK_FUNERAL_HOMES,
        response,
    };
}

export const LOAD_KEEP_TRACK_FUNERAL_HOMES_FAILED = 'LOAD_KEEP_TRACK_FUNERAL_HOMES_FAILED';

interface LoadKeepTrackFuneralHomesFailed {
    type: typeof LOAD_KEEP_TRACK_FUNERAL_HOMES_FAILED;
}

function loadKeepTrackFuneralHomesFailed(): LoadKeepTrackFuneralHomesFailed {
    return {
        type: LOAD_KEEP_TRACK_FUNERAL_HOMES_FAILED,
    };
}

export function loadKeepTrackFuneralHomes(params: {
    offset: number;
    searchText: string;
}) {
    return async (dispatch: AppDispatch): Promise<PaginatedResponse<FuneralHomeUXPreview> | null> => {
        const { offset, searchText } = params;
        dispatch(loadingKeepTrackFuneralHomes(offset === 0));

        const response = await dispatch(loadFuneralHomePreviewsFromAPI({
            offset,
            limit: 10,
            searchText,
            sortBy: 'name',
            sortDirection: 'asc',
        }));
        if (!response) {
            dispatch(loadKeepTrackFuneralHomesFailed());
            return null;
        }
        dispatch(loadedKeepTrackFuneralHomes(response));
        return response;
    };
}

export const LOADING_KEEP_TRACK_PAGE = 'LOADING_KEEP_TRACK_PAGE';

interface LoadingKeepTrackPage {
    type: typeof LOADING_KEEP_TRACK_PAGE;
}

function loadingKeepTrackPage(): LoadingKeepTrackPage {
    return {
        type: LOADING_KEEP_TRACK_PAGE,
    };
}

export const LOADED_KEEP_TRACK_PAGE = 'LOADED_KEEP_TRACK_PAGE';

interface LoadedKeepTrackPage extends KeepTrackPageResponse {
    type: typeof LOADED_KEEP_TRACK_PAGE;
}

function loadedKeepTrackPage(params: Omit<LoadedKeepTrackPage, 'type'>): LoadedKeepTrackPage {
    return {
        type: LOADED_KEEP_TRACK_PAGE,
        ...params,
    };
}

export const LOAD_KEEP_TRACK_PAGE_FAILED = 'LOAD_KEEP_TRACK_PAGE_FAILED';

interface LoadKeepTrackPageFailed {
    type: typeof LOAD_KEEP_TRACK_PAGE_FAILED;
    response?: KeepTrackPageResponse;
}

function loadKeepTrackPageFailed(response?: KeepTrackPageResponse): LoadKeepTrackPageFailed {
    return {
        type: LOAD_KEEP_TRACK_PAGE_FAILED,
        response,
    };
}

export function loadKeepTrackPage(params: {
    isAdditionalFlow: boolean;
}) {
    const { isAdditionalFlow } = params;
    return async (dispatch: AppDispatch): Promise<KeepTrackPageResponse | null> => {
        const searchParams = parse(window.location.search);

        const funeralhome = searchParams[AssignKeepTrackSearchParam.funeralHomeKey];
        const casename = searchParams[AssignKeepTrackSearchParam.caseName];

        // if there is no funeral home there is no sense in doing the API call
        if (funeralhome === undefined) {
            return null;
        }
        const query = stringify({
            casename,
            funeralhome,
            additional: isAdditionalFlow,
        });

        dispatch(loadingKeepTrackPage());
        const resource = `api/keeptrack${query ? '?' : ''}${query}`;
        const response = await getFromAPI<KeepTrackPageResponse>(resource, dispatch);
        dispatch(response ? loadedKeepTrackPage(response) : loadKeepTrackPageFailed());
        return response;
    };
}

export type KeepTrackAction =
    | SelectedCaseForKeeptrackAssignment
    | LoadingKeepTrackCases
    | LoadedKeepTrackCases
    | LoadKeepTrackCasesFailed
    | LoadingKeepTrackFuneralHomes
    | LoadedKeepTrackFuneralHomes
    | LoadKeepTrackFuneralHomesFailed
    | LoadingKeepTrackPage
    | LoadedKeepTrackPage
    | LoadKeepTrackPageFailed
    ;
