import { Component } from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PublicIcon from '@mui/icons-material/Public';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

import { StoreState } from '../../types';
import { UserRoles, getFuneralHomeId } from '../../shared/types';
import FuneralHomeLogo from './FuneralHomeLogo';
import withState from './utilHOC/WithState';
import {
    convertHexToRGBA,
    FuneralHomeRoutePage,
    getFormattedPhoneNumber,
    RouteBuilder
} from '../../services';
import GPopper from './GPopper';
import GLink from './GLink';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    popper: {
        maxWidth: 300,
        width: '100%',
        '@media (min-width: 360px)': {
            maxWidth: 320,
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: `15px solid ${convertHexToRGBA(theme.palette.primary.main, 0.18)}`,
            left: '-4px !important'
        },
        '&[data-popper-placement*="bottom"]': {
            left: '5px !important'
        },
    },
    popoverPaper: {
        boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 10px 14px 2px rgba(0, 0, 0, 0.12) !important`,
        top: '72px !important',
        left: 'calc(100% - 311px) !important',
        right: '23px !important',
        borderRadius: 8
    },
    arrow: {
        background: theme.palette.common.white,
        clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
    },
    popperContent: {
        textAlign: 'center',
        borderRadius: 6,
        WebkitBorderRadius: 6,
        MozBorderRadius: 6,
        minHeight: 200,
        minWidth: 200,
        maxWidth: 300,
        width: '100%',
        '@media (min-width: 360px)': {
            maxWidth: 320,
        }
    },
    headerContent: {
        padding: '8px 16px',
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.18),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '6px 6px 0px 0px',
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), ' +
            '0px 4px 5px 0px rgba(0, 0, 0, 0.14), ' +
            '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        '& p, svg': {
            color: theme.palette.primary.main
        }
    },
    favouriteIcon: {
        fontSize: 18,
        verticalAlign: 'sub'
    },
    heading: {
        fontSize: 16,
        color: theme.palette.common.white
    },
    subHeading: {
        color: theme.palette.common.white
    },
    mainContent: {
        padding: '12px 24px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '16px 0px',
        '& button': {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 72,
            maxWidth: 72,
            maxHeight: 72,
            minHeight: 72,
            borderRadius: 16,
            textTransform: 'capitalize',
            fontWeight: 400,
            padding: '6px 2px',
            '& svg': {
                fontSize: 32,
                marginBottom: 2
            },
        },
    },
    visitWebsiteButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            textTransform: 'none',
            fontWeight: 400,
            whiteSpace: 'nowrap',
            padding: '4px 12px',
            borderRadius: 14,
            fontSize: 13,
            '& svg': {
                fontSize: 20
            }
        }
    },
    logoContainer: {
        width: '100%',
        textAlign: 'center',
        paddingTop: 16
    },
    customHref: {
        textDecoration: 'none',
        color: 'inherit',
    },
    footer: {
        padding: '0 24px',
        '& hr': {
            background: theme.palette.primary.main
        },
        '& button': {
            textTransform: 'none',
            fontWeight: 400,
            marginBottom: 4
        }
    },
});

function mapStateToProps({
    casesState,
    funeralHomeState,
    userSession
}: StoreState) {
    const { selectedCase, publicCase } = casesState;
    const { activeFuneralHome, publicFuneralHome } = funeralHomeState;

    const funeralHomeId = getFuneralHomeId(activeFuneralHome, selectedCase, publicCase);
    return {
        selectedCase,
        publicCase,
        funeralHome: activeFuneralHome || publicFuneralHome,
        isFHorGOMUser: UserRoles.isFHorGOMUserOnFH(userSession.userData, funeralHomeId)
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    popperAnchorEle: HTMLElement | null;
    zIndex: number;
    closePopper: () => void;
    isGPLView?: boolean;
}

type StyledProps = WithStyles<'root' | 'popper' | 'popoverPaper' | 'arrow' | 'popperContent'
    | 'headerContent' | 'favouriteIcon' | 'heading' | 'subHeading' | 'mainContent' | 'buttonContainer'
    | 'visitWebsiteButton' | 'logoContainer' | 'customHref' | 'footer'>;

type CombinedProps = Props & StyledProps;
class FuneralHomePopper extends Component<CombinedProps> {

    renderHeader = () => {
        const { classes, selectedCase, publicCase, isGPLView } = this.props;

        const activeCase = selectedCase || publicCase;

        const { fhName } = this.getFhDetails();

        return (
            <Grid item xs={12} className={classes.headerContent}>
                <Typography align="center" className={classes.heading}>
                    {isGPLView
                        ? <><FavoriteIcon className={classes.favouriteIcon} />&nbsp;We are here to help.</>
                        : activeCase && <>
                            Made with <FavoriteIcon className={classes.favouriteIcon} /> for&nbsp;
                            {activeCase.display_full_name}
                        </>
                    }
                </Typography>

                <Typography align="center" className={classes.subHeading}>
                    {isGPLView
                        ? fhName
                        : <>by {fhName}</>
                    }
                </Typography>
            </Grid>
        );
    };

    renderFHLogo = () => {
        const { classes, isGPLView } = this.props;

        const { website } = this.getFhDetails();

        return (
            <Grid
                item
                xs={12}
                className={classNames(classes.logoContainer, (!website || isGPLView) && GStyles.padding0)}
            >
                <Tooltip
                    title={website || ''}
                    enterDelay={1000}
                    placement="top"
                >
                    <a
                        href={website || undefined}
                        className={GStyles.textDecorationNone}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FuneralHomeLogo logoSize="small" />
                    </a>
                </Tooltip>
            </Grid>
        );
    };

    getFhDetails = () => {
        const { selectedCase, funeralHome, publicCase } = this.props;

        const gatherCase = selectedCase || publicCase;

        const assigneePhoneNumber = selectedCase && selectedCase.assignee.phone
            || funeralHome && funeralHome.phone
            || publicCase && publicCase.funeral_home.phone;

        const assigneeEmail = selectedCase && selectedCase.assignee.email
            || funeralHome && funeralHome.email
            || publicCase && publicCase.funeral_home.email;

        const latLng = funeralHome && funeralHome.long_address.coords
            || gatherCase && gatherCase.funeral_home.long_address.coords
            || [null, null];

        const website = gatherCase && gatherCase.funeral_home.website_url
            || funeralHome && funeralHome.website_url;

        const address = gatherCase && `${gatherCase.funeral_home.address1} ${gatherCase.funeral_home.city} 
            ${gatherCase.funeral_home.state}`
            || funeralHome && `${funeralHome.long_address.address1} ${funeralHome.long_address.city} 
            ${funeralHome.long_address.state}`;

        const fhName = funeralHome && funeralHome.name
            || gatherCase && gatherCase.funeral_home.name;

        return {
            assigneePhoneNumber: getFormattedPhoneNumber(assigneePhoneNumber),
            assigneeEmail,
            latLng,
            website,
            address,
            fhName
        };
    };

    renderMainContent = () => {
        const { classes, selectedCase, publicCase, isGPLView } = this.props;
        const { assigneePhoneNumber, latLng, assigneeEmail, website, address } = this.getFhDetails();

        const gatherCase = selectedCase || publicCase;

        const [lat, lng] = latLng;
        const mapCoords = lat !== null && lng !== null
            && `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        const subject = isGPLView
            ? 'Question about products'
            : `Help needed with ${gatherCase && gatherCase.display_fname || ''} ` +
            `${gatherCase && gatherCase.lname || ''}'s Profile`;
        const mailToSubject: string = encodeURIComponent(subject);

        return (
            <Grid item xs={12} className={classes.mainContent}>
                {!isGPLView &&
                    <Typography align="center" color="primary">
                        If you are interested in providing a similar tribute for another loved one or pre-planning your
                        own services, please feel free to reach out to our team anytime.
                    </Typography>
                }
                <Grid item xs={12} className={classes.buttonContainer}>
                    {assigneePhoneNumber &&
                        <Tooltip title={assigneePhoneNumber} enterDelay={400}>
                            <a
                                href={`tel:${assigneePhoneNumber}`}
                                className={classes.customHref}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    <CallIcon color="primary" />
                                    Call
                                </Button>
                            </a>
                        </Tooltip>
                    }
                    {assigneeEmail &&
                        <Tooltip title={assigneeEmail || ''} enterDelay={400}>
                            <a
                                href={`mailto:${assigneeEmail}?subject=${mailToSubject}`}
                                className={classes.customHref}
                                target="_blank" rel="noreferrer"
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    <MailIcon color="primary" />
                                    Email
                                </Button>
                            </a>
                        </Tooltip>
                    }
                    <Tooltip title={address || ''} enterDelay={400}>
                        <a
                            href={mapCoords ? mapCoords : undefined}
                            className={GStyles.textDecorationNone}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                            >
                                <DirectionsIcon color="primary" />
                                Directions
                            </Button>
                        </a>
                    </Tooltip>
                </Grid>
                {
                    website && !isGPLView &&
                    < Grid item xs={12} className={classes.visitWebsiteButton}>
                        <a
                            href={website}
                            className={GStyles.textDecorationNone}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button variant="outlined" color="primary">
                                <PublicIcon />&nbsp;
                                Visit our Website for more information
                            </Button>
                        </a>
                    </Grid>
                }

                {this.renderFHLogo()}
            </Grid >
        );
    };

    renderFooter = () => {
        const { isFHorGOMUser, classes, selectedCase, isGPLView } = this.props;

        const dashboardLink = selectedCase
            ? RouteBuilder.FuneralHome(
                selectedCase.funeral_home.key,
                selectedCase.funeral_home.is_demo
                    ? FuneralHomeRoutePage.MYFUNERALHOME
                    : FuneralHomeRoutePage.MYCASES,
            )
            : RouteBuilder.Root;

        if (!isFHorGOMUser || isGPLView) {
            return null;
        }

        return (
            <Grid item xs={12} className={classes.footer}>
                <Divider />
                <GLink to={dashboardLink}>
                    <Tooltip
                        title="This button is NOT visible to the family"
                        enterDelay={600}
                        placement="top"
                    >
                        <Button color="secondary">
                            <ViewCarouselIcon />&nbsp;Return to my Dashboard
                        </Button>
                    </Tooltip>
                </GLink>
            </Grid>
        );
    };

    renderContent = () => {
        return (
            <>
                {this.renderHeader()}
                {this.renderMainContent()}
                {this.renderFooter()}
            </>
        );

    };

    render() {
        const { classes, popperAnchorEle, zIndex, closePopper } = this.props;

        return (
            <GPopper
                modifiers={[{
                    name: 'preventOverflow',
                    options: {
                        padding: 12,
                    },
                }]}
                anchorEle={popperAnchorEle}
                className={classes.popper}
                arrowClass={classes.arrow}
                popperContentClass={classes.popperContent}
                paperClass={classes.popoverPaper}
                zIndex={zIndex + 1}
                closePopper={e => closePopper()}
                placement='top'
                intercomTargetProp="RememberPage-Header-FuneralHomePopper"
            >
                {this.renderContent()}
            </GPopper>
        );
    }
}

export default withState(mapStateToProps)(withStyles(styles)(FuneralHomePopper));
