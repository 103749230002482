import { Theme, Tooltip } from '@mui/material';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import classNames from 'classnames';
import * as moment from 'moment';
import { isEventTypeArrangement } from './utils';
import { EventCreateRequest, FeatureKey, LocationType, LocationUX } from '../../../../shared/types';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import { WhenEnabled } from '../../../common/AppFeature';
import GSwitch from '../../../common/inputElements/GSwitch';
import PublicPrivateSwitch from '../../PublicPrivateSwitch';
import withGStyles from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dateTimeText: {
        fontWeight: 400,
        fontSize: 18,
    },
    tzAbbrText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    noEventNameText: {
        color: 'red',
    },
    tzDifferentText: {
        color: 'red',
    },
    locationText: {
        fontWeight: 400,
        fontSize: 14,
    },
    liveStream: {
        '& label': {
            height: 30,
        },
        '& $label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                marginRight: 8
            }
        }
    },
    label: {}
});
type Classes = 'root' | 'dateTimeText' | 'tzAbbrText' | 'noEventNameText' | 'tzDifferentText' | 'tzDifferentText'
    | 'locationText' | 'liveStream' | 'label';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    eventStartTime: moment.Moment;
    eventEndTime: moment.Moment;
    thisEvent: EventCreateRequest;
    isLiveStreamEnabled: boolean;
    selectedLocation: LocationUX | null;
    hasEventNameError: boolean;
    isSuccessScreen: boolean;
    openAddLocationDialog: () => void;
    handleChangeIsStreamable: (isStreamable: boolean) => void;
    handleChangeIsPrivate: (isPrivate: boolean) => void;
}
const ConferenceVenueDetails = ({
    classes,
    eventStartTime,
    eventEndTime,
    thisEvent,
    isLiveStreamEnabled,
    selectedLocation,
    hasEventNameError,
    isSuccessScreen,
    handleChangeIsStreamable,
    handleChangeIsPrivate,
    openAddLocationDialog
}: Props & StyledProps) => {
    const formattedDateTimeString = `${eventStartTime.format('dddd, D MMM YYYY')} from ` +
        `${eventStartTime.format('h:mm')}-${eventEndTime.format('h:mm a')}`;

    const tzString: string = eventStartTime.format('z');
    const isTZDifferent = tzString !== moment.tz(moment.tz.guess()).format('z');

    const isEventLocation = selectedLocation !== null && selectedLocation.location_type === LocationType.event;
    const switchTooltip = isLiveStreamEnabled
        && `You will still need to manually begin the live stream at the correct time.`
        || `Contact Gather to enable live streaming.`;

    const switchPrivateEventTooltip = `Invite Only events can ONLY be viewed by logged in Admins and Guests`;
    const isPrivate = thisEvent.is_private;

    return (
        <>
            {!isSuccessScreen &&
                <Typography
                    color="primary"
                    variant={'caption'}
                    component="p"
                    className={classes.dateTimeText}
                >
                    <span className={classNames(hasEventNameError && classes.noEventNameText)}>
                        {thisEvent.name || 'Please Enter Event Name Above'}
                    </span>
                </Typography>
            }
            <Typography
                color="primary"
                variant="caption"
                component="p"
                className={classNames(classes.dateTimeText, isSuccessScreen && classes.fontWeight300)}
            >
                <span>
                    <span>{formattedDateTimeString}</span>
                    &nbsp;
                    <span
                        className={classNames(
                            isTZDifferent && classes.tzDifferentText,
                            isEventLocation && classes.tzAbbrText
                        )}
                        onClick={event => isEventLocation ?
                            openAddLocationDialog()
                            : undefined
                        }
                    >
                        {tzString}
                    </span>
                </span>
            </Typography>

            {selectedLocation &&
                <Typography
                    color="primary"
                    variant={'caption'}
                    component="p"
                    className={classNames(
                        classes.locationText,
                        isSuccessScreen && classes.fontWeight300
                    )}
                >
                    <span
                        onClick={event => isEventLocation ?
                            openAddLocationDialog()
                            : undefined
                        }
                        className={isEventLocation ? classes.cursorPointer : undefined}
                    >
                        <span className={classes.textCapitalize}>
                            {selectedLocation.name || selectedLocation.address.address1}
                        </span>
                        &nbsp;in&nbsp;
                        <span className={classes.textCapitalize}>
                            {selectedLocation.address.city}
                        </span>
                    </span>
                </Typography>
            }

            {!isEventTypeArrangement(thisEvent) &&
                <WhenEnabled feature={FeatureKey.LIVE_STREAM}>
                    <Tooltip placement="top" title={switchTooltip}>
                        <Grid item xs={12} className={classes.liveStream}>
                            <GSwitch
                                label={
                                    <Typography color="primary" className={classes.label}>
                                        <LiveTvIcon color="primary" />Include Live Stream
                                    </Typography>
                                }
                                checked={thisEvent.is_streamable}
                                onChangeCallBack={(isChecked) => handleChangeIsStreamable(isChecked)}
                                disabled={!isLiveStreamEnabled}
                            />
                        </Grid>
                    </Tooltip>
                    <PublicPrivateSwitch
                        isPrivate={isPrivate}
                        tooltipTitle={switchPrivateEventTooltip}
                        handleViewChange={(isChecked: boolean) => handleChangeIsPrivate(isChecked)}
                    />
                </WhenEnabled>
            }
        </>
    );
};

export default withGStyles(styles)(ConferenceVenueDetails);
