import * as React from 'react';
import * as momentTz from 'moment-timezone';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRulesCallback } from '@mui/styles';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import withGStyles from '../../../../styles/WithGStyles';
import { DateTimeFormat } from '../../../../shared/utils';

export const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        justifyContent: 'center',
        maxWidth: 240,
        margin: '20px auto 40px'
    },
    marginBottom24: {
        marginBottom: 24
    },
    maxWidth110: {
        maxWidth: 110
    }
});

type Classes = 'root' | 'marginBottom24' | 'maxWidth110';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    startTime: momentTz.Moment;
    endTime: momentTz.Moment;
    hasStartTimeError: boolean;
    hasEndTimeError: boolean;
    onEndTimeChange: (timeStr: momentTz.Moment) => void;
    onStartTimeChange: (timeStr: momentTz.Moment) => void;
    onDateChange: (date: momentTz.Moment) => void;
}

const CollapsedDatesView = ({
    classes,
    startTime,
    endTime,
    hasStartTimeError,
    hasEndTimeError,
    onEndTimeChange,
    onStartTimeChange,
    onDateChange,
}: Props & StyledProps) => {

    const timezone = startTime.tz() || momentTz.tz.guess();

    const formattedEventDate = startTime.format(DateTimeFormat.ShortDate);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = momentTz.tz(event.target.value, timezone).startOf('day');
        onDateChange(newDate);
    };

    return (
        <Grid item xs={12} className={classes.root}>
            <Grid item xs={12} className={classes.marginBottom24}>
                <TextField
                    type="date"
                    label="Event Date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formattedEventDate}
                    onChange={handleDateChange}
                />
            </Grid>

            <Grid container justifyContent="space-between">
                <TextField
                    type="time"
                    label="Start Time"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={startTime.format('HH:mm')}
                    onChange={(e) => onStartTimeChange(momentTz.tz(
                        `${startTime.format('DD MM YYYY')} ${e.target.value}`,
                        'DD MM YYYY HH:mm',
                        timezone,
                    ))}
                    error={hasStartTimeError}
                    className={classes.maxWidth110}
                />
                <TextField
                    type="time"
                    label="End Time"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={endTime.format('HH:mm')}
                    onChange={(e) => onEndTimeChange(momentTz.tz(
                        `${endTime.format('DD MM YYYY')} ${e.target.value}`,
                        'DD MM YYYY HH:mm',
                        timezone,
                    ))}
                    error={hasEndTimeError}
                    className={classes.maxWidth110}
                />
            </Grid>
        </Grid>
    );
};

export default withGStyles(styles)(CollapsedDatesView);
