import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import GSwitch from '../common/inputElements/GSwitch';
import Tooltip from '@mui/material/Tooltip';

import { StoreState, useGDispatch } from '../../types';
import { getFuneralHomeId, UserPatchRequest } from '../../shared/types';
import { patchTeamMember } from '../../actions/Team.action';
import makeGStyles from '../../styles/makeGStyles';
import withState from '../common/utilHOC/WithState';
import { getIntercomTargetProp } from '../../services';

const useStyles = makeGStyles(
    theme => ({
        root: {},
        switchContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 24,
            maxHeight: 24,
            '& label': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
                fontSize: 14
            }
        },
        gSwitch: {
            width: 32,
            margin: '0 8px',
        },
    })
);

function mapStateToProps({
    casesState,
    userSession,
    funeralHomeState,
}: StoreState) {
    const { userData } = userSession;
    const { selectedCase, publicCase } = casesState;
    const { activeFuneralHome } = funeralHomeState;

    const funeralHomeId = getFuneralHomeId(activeFuneralHome, selectedCase, publicCase);

    return {
        userData,
        funeralHomeId,
        isFamilyView: Boolean(userData && userData.is_family_view_on),
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    customClass?: string;
    tooltipTitle?: string;
    tooltipEnterDelay?: number;
    intercomTargetProp?: string;
}

const ChangeViewGSwitch = (props: Props) => {
    const {
        isFamilyView,
        customClass,
        tooltipTitle,
        tooltipEnterDelay,
        userData,
        funeralHomeId,
        intercomTargetProp,
    } = props;

    const dispatch = useGDispatch();
    const classes = useStyles();

    const handleTaskViewChange = (_isFamilyView: boolean) => {
        if (!userData) {
            console.warn('No user data in mycases!');
            return;
        }

        const changes: UserPatchRequest = { is_family_view_on: _isFamilyView };
        dispatch(patchTeamMember(userData.user_id, userData.entity_id, changes, funeralHomeId));
    };

    return (
        <Tooltip
            title={tooltipTitle || ``}
            enterDelay={tooltipEnterDelay !== undefined ? tooltipEnterDelay : 1200}
        >
            <Grid
                item xs={12}
                className={classNames(classes.switchContainer, customClass && customClass)}
                {...getIntercomTargetProp(intercomTargetProp)}
            >
                <label htmlFor="task-view-switch">Director View</label>
                <div className={classes.gSwitch}>
                    <GSwitch
                        checked={isFamilyView}
                        onChangeCallBack={handleTaskViewChange}
                        id="task-view-switch"
                    />
                </div>
                <label htmlFor="task-view-switch">Family View</label>
            </Grid>
        </Tooltip>
    );
};

export default withState(mapStateToProps)(ChangeViewGSwitch);
