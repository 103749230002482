import { PropsWithChildren, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { SvgIconComponent } from "@mui/icons-material";

import BaseStepDialogTitle from "./BaseStep.dialogTitle";
import { useCompleteStepStyles } from "../completeStepDialog/dialogContent.styles";
import { SlideTransition } from "../../../../common/Transitions";
import useFullScreen from "../../../../common/hooks/useFullScreen";
import classNames from "classnames";

interface Props {
    zIndex: number;
    open: boolean;
    stepTitle: string;
    entityFullName: string;
    stepImagePublicId: string | null;
    stepImageFallbackURL: string | null;
    stepIcon: SvgIconComponent | null;
    isStepCompleted?: boolean;
    isStepSkipped?: boolean;
    dialogContentClass?: string;
    closeDialog: VoidFunction;
    intercomTargetProp?: string;
    intercomTargetPropDialogName?: string;
    intercomTargetPropTitle?: string;
    intercomTargetPropCancel?: string;
}
const BaseStepDialog = (props: PropsWithChildren<Props>) => {
    const {
        zIndex,
        open,
        stepImagePublicId,
        stepImageFallbackURL,
        stepIcon,
        stepTitle,
        children,
        isStepCompleted,
        isStepSkipped,
        entityFullName,
        dialogContentClass,
        closeDialog,
        intercomTargetProp,
        intercomTargetPropDialogName,
        intercomTargetPropTitle,
        intercomTargetPropCancel,
    } = props;

    const classes = useCompleteStepStyles();
    const fullScreen = useFullScreen();
    const [dialogContentRef, setDialogContentRef] = useState<HTMLDivElement | null>(null);

    let subtitle = `Logged in as ${entityFullName}`;
    if (isStepCompleted) {
        subtitle = `Marked complete by ${entityFullName}`;
    } else if (isStepSkipped) {
        subtitle = `Step skipped by ${entityFullName}`;
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            transitionDuration={300}
            TransitionComponent={SlideTransition}
            classes={{ paper: classes.paper }}
            sx={{ zIndex }}
            fullScreen={fullScreen}
        >
            <BaseStepDialogTitle
                isStepCompleted={!!isStepCompleted}
                dialogContentRef={dialogContentRef}
                closeDialog={closeDialog}
                stepImagePublicId={stepImagePublicId}
                stepImageFallbackURL={stepImageFallbackURL}
                stepIcon={stepIcon}
                stepTitle={stepTitle}
                subtitle={subtitle}
                intercomTargetProp={intercomTargetProp}
                intercomTargetPropDialogName={intercomTargetPropDialogName}
                intercomTargetPropTitle={intercomTargetPropTitle}
                intercomTargetPropCancel={intercomTargetPropCancel}
            />

            <DialogContent ref={setDialogContentRef} className={classNames(classes.dialogContent, dialogContentClass)}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default BaseStepDialog;