import { Component } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import ReactSignatureCanvas from 'react-signature-canvas';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WatchIcon from '@mui/icons-material/Watch';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import DiamondIcon from '@mui/icons-material/Diamond';

import CircularProgress from '@mui/material/CircularProgress';

import GatherCaseBelonging from './GatherCaseBelonging';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import CaseBelongingDetailDialog from './CaseBelongingDetail.dialog';
import AddCaseBelongingDialog from '../addCaseBelongingDialog/AddCaseBelonging.dialog';
import { convertHexToRGBA, getIntercomTargetProp } from '../../../../../services';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import { StoreState } from '../../../../../types';
import {
    CaseBelongingDecisionType,
    CaseBelongingPlaceHolder,
    CaseBelongingUpdateRequest,
    CaseBelongingUX,
    UserRoles,
    YesNoUnknownEnum
} from '../../../../../shared/types';
import { AppDispatch } from '../../../../../store';
import { deleteBelonging, loadBelongings, updateBelonging } from '../../../../../actions/Belongings.action';
import CaseBelongingPlaceholder from './CaseBelongingPlaceholder';
import withState from '../../../../common/utilHOC/WithState';
import { TrackingContentContext } from './CompleteStep.dialog';
import { uploadSignatureFromRef } from '../../utils';
import ScrollControls from '../../../../common/ScrollControls';

const styles: StyleRulesCallback<Theme, Props, Classes> = (theme) => ({
    root: {
        padding: 0,
        columnGap: '24px',
        rowGap: '24px',
        width: 'fit-content',
        listStyle: 'none',
        '&:not($list)': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(120px, 180px))',
            '&$isEmpty': {
                gridTemplateColumns: 'minmax(120px, 180px)',
            },
            '@media (max-width: 425px)': {
                columnGap: '12px',
                rowGap: '16px'
            },
            '@media (min-width: 600px)': {
                gridTemplateColumns: 'repeat(3, minmax(120px, 180px))',
            }
        },
        '&$list': {
            paddingBottom: '18px !important',
            '& $addBelongingBtn': {
                width: 180,
                height: 180,
                marginRight: '20px !important'
            },
            '& > li': {
                overflow: 'visible',
                width: 180,
                margin: 'unset'
            }
        },
    },
    addBelongingBtn: {
        borderRadius: 16,
        width: '100%',
        aspectRatio: '1',
        border: `2px dashed ${theme.palette.secondary.main}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 16,
        marginTop: 26,
        height: 180,
        transition: `background ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
        color: theme.palette.secondary.main,
        '&:hover': {
            background: convertHexToRGBA(theme.palette.secondary.main, 0.18)
        },
        '& p': {
            fontSize: 12,
            textTransform: 'uppercase',
            lineHeight: 1.125,
            '& span': {
                fontSize: 16
            }
        }
    },
    fontSize38: {
        fontSize: 38
    },
    padding_20_20_0: {
        padding: '28px 0 0 20px'
    },
    familyPageContext: {
        '& $list': {
            '& $addBelongingBtn': {
                width: 160,
                height: 160,
            },
            '& > li': {
                width: 160
            }
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    scrollBtn: {
        background: 'none',
        '& button': {
            margin: 0,
            background: `${theme.palette.primary.main} !important`,
            '& svg': {
                color: '#fff'
            }
        }
    },
    rightScrollBtn: {
        right: -12
    },
    leftScrollBtn: {
        left: -12
    },
    scrollingContent: {
        position: 'relative',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    isEmpty: {}
});
type Classes = 'root' | 'addBelongingBtn' | 'fontSize38' | 'isEmpty' | 'padding_20_20_0' | 'familyPageContext'
    | 'scrollBtn' | 'leftScrollBtn' | 'rightScrollBtn' | 'scrollingContent';
type StyledProps = WithGStyles<Classes>;

function mapStateToProps({ casesState, userSession }: StoreState) {
    return {
        selectedCase: casesState.selectedCase,
        caseBelongings: casesState.belongings,
        isFHorGOMUserOnFH: UserRoles.isFHorGOMUserOnFH(
            userSession.userData,
            casesState.selectedCase?.funeral_home_id || null
        )
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    userFullName: string;
    zIndex: number;
    caseUuid: string | null; // null case is for Back Office preview
    caseFname: string;
    context: TrackingContentContext;
    dispatch: AppDispatch;
    disableEdit?: boolean;
}
interface State {
    isConfirmationDialogOpen: boolean;
    caseItemToDelete: CaseBelongingUX | null;
    caseItemToEdit: CaseBelongingUX | null;
    isAddNewItemDialogOpen: boolean;
    isBelongingDetailsDialogOpen: boolean;
    caseItemPlaceholders: CaseBelongingPlaceHolder[];
    scrollElement: HTMLDivElement | null;
};

class CaseBelongingList extends Component<Props & StyledProps, State>  {
    state: Readonly<State> = {
        isConfirmationDialogOpen: false,
        caseItemToDelete: null,
        caseItemToEdit: null,
        isAddNewItemDialogOpen: false,
        isBelongingDetailsDialogOpen: false,
        caseItemPlaceholders: [],
        scrollElement: null
    };

    componentDidMount() {
        const { dispatch, caseUuid, context } = this.props;
        if (caseUuid && (context === TrackingContentContext.standalone
            || context === TrackingContentContext.familyPage
        )) {
            dispatch(loadBelongings(caseUuid));
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { caseBelongings } = this.props;
        const { caseItemToEdit, caseItemPlaceholders } = this.state;

        if (caseItemToEdit !== null) {
            const caseItem = caseBelongings?.find(b => b.id === caseItemToEdit.id);
            if (caseItem && !isEqual(caseItemToEdit, caseItem)) {
                this.setState({ caseItemToEdit: caseItem });
            }
        }

        const belongingsDiff = difference(caseBelongings, prevProps.caseBelongings || []);
        if (belongingsDiff.length) {
            this.setState({
                caseItemPlaceholders: caseItemPlaceholders.filter(p =>
                    !belongingsDiff.find(b => (
                        b.name === p.name &&
                        b.description === p.description &&
                        b.decision === p.decision
                    ))
                )
            });
        }
    }

    renderConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isConfirmationDialogOpen, caseItemToDelete } = this.state;
        const subheader = `Are you sure you want to remove this item from being recorded. This action can't be 
        undone and this will be recorded in an audit history as well as the original image and who deleted it.`;

        return (
            <ConfirmationDialog
                open={isConfirmationDialogOpen}
                onClose={this.closeDeleteConfirmationDialog}
                zIndex={zIndex + 2}
                onConfirm={() => caseItemToDelete && this.deleteCaseItem(caseItemToDelete.id)}
                cancelButtonText="Never Mind"
                confirmationButtonText="Remove Item"
                subHeader={subheader}
                header="Are you sure?"
                intercomTargetProp={`TrackingPage-RecordKeptBelonging-ConfirmDeleteDialog`}
                intercomTargetPropConfirmButton={`TrackingPage-RecordKeptBelonging-ConfirmDeleteButton`}
                intercomTargetPropCancelButton={`TrackingPage-RecordKeptBelonging-CancelDeleteButton`}
            />
        );
    };

    renderAddNewItemDialog = () => {
        const { zIndex, userFullName, caseFname, caseUuid } = this.props;
        const { isAddNewItemDialogOpen, caseItemToEdit } = this.state;

        return (
            <AddCaseBelongingDialog
                zIndex={zIndex + 2}
                existingItem={caseItemToEdit || undefined}
                isOpen={isAddNewItemDialogOpen}
                caseFirstName={caseFname}
                userFullName={userFullName}
                onClose={this.closeAddNewItemDialog}
                caseUuid={caseUuid}
            />
        );
    };

    renderCaseBelongingsDetailsDialog = () => {
        const { zIndex, caseFname, context, isFHorGOMUserOnFH, selectedCase } = this.props;
        const { isBelongingDetailsDialogOpen, caseItemToEdit } = this.state;

        if (!caseItemToEdit || !selectedCase) {
            return null;
        }

        return (
            <CaseBelongingDetailDialog
                zIndex={zIndex + 1}
                caseItem={caseItemToEdit}
                isOpen={isBelongingDetailsDialogOpen}
                caseFirstName={caseFname}
                isCompleted={!!caseItemToEdit.completed_by}
                context={context}
                isFHorGOMUserOnFH={isFHorGOMUserOnFH}
                selectedCase={selectedCase}
                onEditClick={this.openAddNewItemDialog}
                onClose={this.closeBelongingDetailsDialog}
                deleteItem={this.openDeleteConfirmationDialog}
                updateItem={this.updateItem}
            />
        );
    };

    render() {
        const { caseFname, classes, caseBelongings, context, disableEdit, caseUuid, isFHorGOMUserOnFH } = this.props;
        const { caseItemPlaceholders } = this.state;

        const isFamilyPageContext = context === TrackingContentContext.familyPage;
        const isScrollable = context === TrackingContentContext.dialog
            || isFamilyPageContext;

        if (caseBelongings === null && caseUuid !== null) {
            return (
                <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    className={classNames(classes.width100, classes.padding10, classes.flexCentred)}
                >
                    <CircularProgress
                        color="primary"
                        size={86}
                        thickness={3}
                    />
                </Grid>
            );
        }

        return (
            <>
                <Grid
                    item
                    ref={ref => !this.state.scrollElement &&
                        this.setState({ scrollElement: ref })
                    }
                    className={isScrollable
                        ? classNames(
                            classes.scrollingContent,
                            classes.overflowXAuto,
                            isFamilyPageContext
                                ? classNames(classes.paddingLeft20, classes.paddingTop10, classes.familyPageContext)
                                : classes.padding_20_20_0
                        )
                        : undefined}
                >
                    <Grid
                        className={classNames(
                            classes.root,
                            isScrollable ? classes.list : classes.width100,
                            (!caseBelongings?.length && !caseItemPlaceholders.length) && classes.isEmpty
                        )}
                        container
                        justifyContent={isScrollable ? undefined : 'center'}
                        component="ul"
                    >
                        {caseBelongings?.map(item =>
                            <GatherCaseBelonging
                                disableEdit={!!disableEdit}
                                key={item.id}
                                caseItem={item}
                                isCompleted={!!item.completed_by}
                                caseFirstName={caseFname}
                                context={context}
                                openBelongingDetailsDialog={this.openBelongingDetailsDialog}
                            />
                        )}

                        {caseItemPlaceholders.map(placeholderItem =>
                            <CaseBelongingPlaceholder
                                key={placeholderItem.name}
                                context={context}
                            />
                        )}

                        {(isFHorGOMUserOnFH || !isFamilyPageContext) && <Tooltip
                            placement="top"
                            enterDelay={400}
                            title={disableEdit ?
                                'Belongings cannot be edited from this view. You can edit them from the "Items" tab.'
                                : ''}
                        >
                            <span>
                                <ButtonBase
                                    className={classNames(
                                        classes.addBelongingBtn,
                                        isFamilyPageContext && classNames(classes.margin_0, classes.backgroundWhite)
                                    )}
                                    onClick={e => this.openAddNewItemDialog()}
                                    disabled={disableEdit}
                                >
                                    <Typography
                                        color="secondary"
                                        {...getIntercomTargetProp(`TrackingPage-ItemsTab-AddNewBelonging`)}
                                    >
                                        Record and track<br />
                                        <span>new belonging</span>
                                    </Typography>

                                    <span className={classes.flexCentred}>
                                        <WatchIcon color="secondary" className={classes.fontSize28} />

                                        <DiamondIcon
                                            color="secondary"
                                            className={classNames(classes.fontSize38, classes.marginTop2)}
                                        />

                                        <AccountBalanceWalletIcon color="secondary" className={classes.fontSize34} />

                                        <DryCleaningIcon color="secondary" className={classes.fontSize34} />
                                    </span>
                                </ButtonBase>
                            </span>
                        </Tooltip>}
                    </Grid>
                </Grid>
                {isScrollable &&
                    <ScrollControls
                        registerEvents
                        scrollElement={this.state.scrollElement}
                        containerClass={classes.scrollBtn}
                        leftButtonClass={classes.leftScrollBtn}
                        rightButtonClass={classes.rightScrollBtn}
                    />
                }
                {this.renderAddNewItemDialog()}
                {this.renderCaseBelongingsDetailsDialog()}
                {this.renderConfirmationDialog()}
            </>
        );
    }

    openBelongingDetailsDialog = (caseItem: CaseBelongingUX) => {
        this.setState({ isBelongingDetailsDialogOpen: true, caseItemToEdit: caseItem });
    };

    closeBelongingDetailsDialog = () => {
        this.setState(
            { isBelongingDetailsDialogOpen: false },
            () => setTimeout(() => this.setState({ caseItemToEdit: null }), 300)
        );
    };

    openDeleteConfirmationDialog = (caseItem: CaseBelongingUX) => {
        this.setState({ isConfirmationDialogOpen: true, caseItemToDelete: caseItem });
    };

    closeDeleteConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: false, caseItemToDelete: null });
    };

    deleteCaseItem = (itemId: number) => {
        const { dispatch, caseUuid } = this.props;

        if (caseUuid !== null) {
            dispatch(deleteBelonging(caseUuid, itemId));
        }

        this.closeBelongingDetailsDialog();
        this.closeDeleteConfirmationDialog();
    };

    openAddNewItemDialog = (caseItemToEdit?: CaseBelongingUX) => {
        if (caseItemToEdit?.completed_time) {
            this.openBelongingDetailsDialog(caseItemToEdit);
        } else {
            this.setState({
                isAddNewItemDialogOpen: true,
                caseItemToEdit: caseItemToEdit || null
            });
        }
    };

    closeAddNewItemDialog = (placeHolderItem?: CaseBelongingPlaceHolder) => {
        const { caseItemPlaceholders } = this.state;

        this.setState({
            isAddNewItemDialogOpen: false,
            caseItemPlaceholders: placeHolderItem
                ? [...caseItemPlaceholders, placeHolderItem]
                : caseItemPlaceholders
        });
    };

    updateItem = async (
        itemId: number,
        name: string,
        description: string | null,
        decision: string,
        signatureCanvas: ReactSignatureCanvas | null,
        decisionVerified: YesNoUnknownEnum
    ) => {
        const { dispatch, caseUuid } = this.props;

        if (caseUuid === null) {
            return;
        }

        const signatureData = await uploadSignatureFromRef({
            ref: signatureCanvas,
            dispatch,
            caseUuid,
        });
        if (signatureData) {
            const updateRequest: CaseBelongingUpdateRequest = {
                name,
                description,
                decision: CaseBelongingDecisionType[decision],
                signature: signatureData,
                isComplete: decisionVerified === YesNoUnknownEnum.Yes,
            };
            dispatch(updateBelonging({
                caseUuid,
                belongingId: itemId,
                updateRequest,
            }));
        }

        this.closeBelongingDetailsDialog();
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(CaseBelongingList));
