import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { alpha, Button, Grid, Typography } from '@mui/material';
import { formatDateString, getDecedentAge } from '../../../../services';
import { GatherCaseUX } from '../../../../shared/types';
import { GStyles } from '../../../../styles/GStyles';
import { buildQRCodeLink } from '../../utils';

interface Props {
    selectedCase: GatherCaseUX;
    onPrintClick: () => void;
    isLoading: boolean;
}

const QRLabel = ({ selectedCase, onPrintClick, isLoading }: Props) => {
    const qrcode = buildQRCodeLink({ caseName: selectedCase.name, organize: true });
    const { diff } = getDecedentAge(selectedCase);

    const formatedDOB_Date = formatDateString(selectedCase.dob_date || '', 'DD MMM YYYY');
    const formattedDOD_Date = formatDateString(selectedCase.dod_start_date || '', 'DD MMM YYYY');

    return (
        <Grid
            sx={{
                background: (theme) => alpha(theme.palette.primary.main, 0.2),
                borderRadius: '5px',
                padding: '15px 30px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Typography fontSize="18px" color="primary">
                {selectedCase.fname}'s QR Tracking Sticker:
            </Typography>
            <div
                id="print-label"
                style={{
                    width: '62mm',
                    height: '27mm',
                    margin: '4px',
                    flexDirection: 'column',
                    background: '#FFF',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '5px',
                }}
            >
                <div style={{ paddingInline: '8px' }}>
                    <p
                        style={{
                            textTransform: 'uppercase',
                            fontWeight: 500,
                            margin: '0px',
                            marginTop: '8px',
                            fontSize: '8px',
                            lineHeight: '8px',
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {selectedCase.funeral_home.name}
                    </p>
                    <p
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            margin: 0,
                            lineHeight: '20px',
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {selectedCase.full_name}
                    </p>
                </div>

                <div
                    style={{
                        display: 'flex',
                        borderTop: '0.5px solid #000',
                    }}
                >
                    <div
                        style={{
                            width: 'fit-content',
                            borderRight: '0.5px solid #000',
                        }}
                    >
                        <img
                            src={qrcode}
                            alt="Case QR Code"
                            style={{
                                width: '65px',
                                display: 'block',
                                borderBottomLeftRadius: '5px',
                            }}
                        />
                    </div>

                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    padding: '2px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <p
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 'clamp(5px, 8px, 10px)',
                                        textTransform: 'uppercase',
                                        margin: '0px',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    DOB&nbsp;{formatedDOB_Date ? formatedDOB_Date : 'missing'}
                                </p>
                                <p
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 'clamp(5px, 8px, 10px)',
                                        textTransform: 'uppercase',
                                        margin: '0px',
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    DOD&nbsp;{formattedDOD_Date ? formattedDOD_Date : 'missing'}
                                </p>
                            </div>
                            {(diff ?? 0) > 0 && (
                                <div
                                    style={{
                                        borderLeft: `0.5px solid #000`,
                                        padding: '2px',
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            margin: 0,
                                        }}
                                    >
                                        {diff}
                                    </p>
                                    <p
                                        style={{
                                            fontWeight: 500,
                                            fontSize: '8px',
                                            margin: 0,
                                        }}
                                    >
                                        YRS
                                    </p>
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderTop: '0.5px solid #000',
                                paddingBottom: '2px',
                                paddingInline: '3px',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    margin: 0,
                                    lineHeight: '20px',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {selectedCase.keeptrack_key}
                            </p>
                            <p
                                style={{
                                    fontWeight: 500,
                                    lineHeight: '10px',
                                    margin: 0,
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {selectedCase.case_number}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Typography textAlign="center" fontSize="16px" padding="4px">
                We are proud to be relasing our newest solution for QR Tracking Stickers, compatible with most printers
                and devices.
            </Typography>

            <Grid my={1}>
                <Button
                    disabled={isLoading}
                    variant="contained"
                    onClick={onPrintClick}
                    sx={{
                        padding: '10px 30px',
                        display: 'flex',
                        gap: '5px',
                    }}
                >
                    <span>
                        <LocalPrintshopIcon />
                    </span>
                    <span style={{ fontSize: '16px' }}>{
                        isLoading ? `Generating Sticker...` : `Print QR Tracking Sticker`
                    }</span>
                    <Typography
                        sx={{
                            background: '#fff',
                            fontSize: '10px',
                            color: (theme) => theme.palette.primary.main,
                            lineHeight: '15px',
                            fontWeight: 700,
                            paddingBlock: '2px',
                            borderRadius: '2px',
                        }}
                    >
                        NEW
                    </Typography>
                </Button>
            </Grid>
            <Typography
                sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: '2px',
                }}
            >
                <HelpOutlineIcon sx={{ fontSize: '18px' }} />
                <span className={GStyles.textUnderline}>How do I get started?</span>
            </Typography>
        </Grid>
    );
};

export default QRLabel;
