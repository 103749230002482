import * as t from 'io-ts';
import { TaskTemplateType, PhotoTransformationsType, getValidator, FuneralHomeCaseRecord } from '.';
import { UserRole } from './user';

export interface GatherCasePhoto {
    fname: string;
    lname: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
}

export interface NoteUpdatedByUser {
    id: number;
    time: Date;
    fname: string;
    role: UserRole;
    lname: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
}

export interface NoteUXRecord {
    notes: string[];
    note_id: number | null;
    note_updated_by: number;
    note_updated_time: Date;
    note_detail: string;

    task_title: string | null;
    task_template: TaskTemplateType | null;
    task_id: number | null;

    case_assignee_full_name: string;
    case_assignee_id: FuneralHomeCaseRecord['assignee_id'];
    gather_case_id: number;
    case_uuid: FuneralHomeCaseRecord['uuid'];
    case_key: string;
    case_full_name: string;
    gather_case_fname: string;
    gather_case_photo: GatherCasePhoto;
    note_updated_by_user: NoteUpdatedByUser;
    is_tracking_step: boolean;
}

export interface NoteUX extends NoteUXRecord {
}

export interface FuneralHomeNoteView extends NoteUXRecord {
}

export interface CaseNoteRecord {
    id: number;
    gather_case_id: number;
    note: string;
    created_by: number;
    created_time: Date | null;
    updated_by: number;
    updated_time: Date | null;
}

export interface CaseNote {
    id: number;
    note: string;
    gather_case_id: number;
    case_uuid: string;
    updated_by: number;
    updated_time: Date;
    updated_by_user: NoteUpdatedByUser;
}

export const noteUXToCaseNote = (note: NoteUX): CaseNote | null => {
    if (note.note_id === null) {
        return null;
    }
    return {
        id: note.note_id,
        note: note.notes[0],
        gather_case_id: note.gather_case_id,
        case_uuid: note.case_uuid,
        updated_by: note.note_updated_by,
        updated_time: note.note_updated_time,
        updated_by_user: note.note_updated_by_user,
    };
};

export interface TaskNote {
    notes: string[];
    task_title: string;
    task_template: TaskTemplateType | null;
    task_id: number;
    gather_case_fname: string;
    updated_by_user: NoteUpdatedByUser;
    is_tracking_step: boolean;
}

export const noteUXToTaskNote = (note: NoteUX): TaskNote | null => {
    if (note.task_id === null || note.task_title === null) {
        return null;
    }
    return {
        task_id: note.task_id,
        notes: note.notes,
        task_title: note.task_title,
        task_template: note.task_template,
        gather_case_fname: note.gather_case_fname,
        updated_by_user: note.note_updated_by_user,
        is_tracking_step: note.is_tracking_step,
    };
};

export interface NoteResponse {
    caseNotes: CaseNote[];
    taskNotes: TaskNote[];
}

// ---> NoteRequest <--- 
const noteRequestDefinition = {
    note: t.string,
};
const NoteRequestType = t.type(noteRequestDefinition);

export interface NoteRequest extends t.TypeOf<typeof NoteRequestType> {

}

export class NoteRequest {
    public static fromRequest = getValidator<NoteRequest>(NoteRequestType);
}
