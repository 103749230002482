import {GatherAction} from '../actions';
import {KeeptrackReportState} from '../types';
import {LOADED_KEEP_TRACK_REPORT, LOADING_KEEP_TRACK_REPORT,} from '../actions/KeepTrackReport.action';
import {CASE_NUMBER_UPDATED, UPDATING_CASE} from "../actions/GatherCase.action";
import {
    CASE_LABEL_CREATED,
    CASE_LABEL_DELETED,
    CASE_LABEL_UPDATED,
    LABEL_ADDED_TO_CASE,
    LABEL_REMOVED_FROM_CASE
} from "../actions/CaseLabel.action";

export const keeptrackReportInitData: KeeptrackReportState = {
    isLoading: false,
    reportData: [],
    staticListItems: null,
};

export const keeptrackReportState = (
    state: KeeptrackReportState = keeptrackReportInitData,
    action: GatherAction,
): KeeptrackReportState => {
    switch (action.type) {
        case LOADING_KEEP_TRACK_REPORT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LOADED_KEEP_TRACK_REPORT: {
            const newState = {
                ...state,
                isLoading: false,
                reportData: action.report.items
            };

            if (action.report.labels || action.report.locations || action.report.workflows) {
                return {
                    ...newState,
                    staticListItems: {
                        labels: action.report.labels ?? [],
                        locations: action.report.locations ?? [],
                        workflows: action.report.workflows ?? [],
                        teams: action.report.teams ?? [],
                        steps: action.report.steps ?? [],
                    },
                };
            }
            return newState;
        }
        case UPDATING_CASE: {
            const reportData = state.reportData.map((entry) => {
                if (entry.uuid === action.caseUuid) {
                    return {
                        ...entry,
                        case_type: action.changes.case_type ?? entry.case_type,
                        workflow_id: action.changes.workflow_id ?? entry.workflow_id,
                        workflow_name: action.changes.workflow_name ?? entry.workflow_name
                    };
                }
                return entry;
            });
            return {
                ...state,
                reportData,
            };
        }
        case CASE_NUMBER_UPDATED: {
            if (!action.gatherCase.case_number) {
                return state;
            }
            const reportData = state.reportData.map((entry) => {
                if (entry.uuid === action.gatherCase.uuid) {
                    return {
                        ...entry,
                        case_number: action.gatherCase.case_number
                    };
                }
                return entry;
            });
            return {
                ...state,
                reportData,
            };
        }

        case LABEL_ADDED_TO_CASE: {
            const reportData = state.reportData.map((entry) => {
                if (action.caseUuid === entry.uuid) {
                    const labels = [ ...entry.case_label_ids, action.caseLabel.id ];
                    return {
                        ...entry,
                        case_label_ids: labels,
                    };
                }
                return entry;
            });
            return {
                ...state,
                reportData,
            };
        }
        case LABEL_REMOVED_FROM_CASE: {
            const reportData = state.reportData.map((entry) => {
                if (action.caseUuid === entry.uuid) {
                    const labels = entry.case_label_ids.filter(id => id !== action.caseLabel.id);
                    return {
                        ...entry,
                        case_label_ids: labels,
                    };
                }
                return entry;
            });
            return {
                ...state,
                reportData,
            };
        }
        case CASE_LABEL_CREATED: {
            if (!state.staticListItems) {
                return state;
            }
            const labels = [{
                id: action.caseLabel.id,
                name: action.caseLabel.name ?? 'undefined',
                data: {
                    color: action.caseLabel.color ?? '#999', // neutral color as fallback
                }}, ...state.staticListItems.labels];

            return {
                ...state,
                staticListItems: {
                    ...state.staticListItems,
                    labels,
                }
            };
        }
        case CASE_LABEL_DELETED: {
            if (!state.staticListItems) {
                return state;
            }
            const labels = state.staticListItems.labels.filter(({ id }) => id !== action.caseLabel.id);
            const reportData = state.reportData.map((entry) => {
                if (entry.case_label_ids.length) {
                    const newLabels = entry.case_label_ids.filter(id => id !== action.caseLabel.id);
                    return {
                        ...entry,
                        case_label_ids: newLabels,
                    };
                }
                return entry;
            });
            return {
                ...state,
                reportData,
                staticListItems: {
                    ...state.staticListItems,
                    labels,
                }
            };
        }
        case CASE_LABEL_UPDATED: {
            if (!state.staticListItems) {
                return state;
            }
            const labels = state.staticListItems.labels.map(entry => {
                if (entry.id !== action.caseLabel.id) {
                    return entry;
                }
                return {
                    id: entry.id,
                    data: {
                        color: action.caseLabel.color ?? '#999',
                    },
                    name: action.caseLabel.name ?? 'undefined',
                };
            });
            return {
                ...state,
                staticListItems: {
                    ...state.staticListItems,
                    labels,
                }
            };
        }
        default: {
            return state;
        }
    }
};
