import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { APP_SECONDARY_COLOR, GREEN_COLOR, RED_COLOR } from '../../../../constants/colorVariables';
import { convertHexToRGBA, getPhotoUrl, RouteBuilder } from '../../../../services';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import {
    ModerationPhotoUX,
    ModerationCategory,
    CloudinaryTransformationsType,
    ModerationPhotoContributor,
} from '../../../../shared/types';
import { getModerationVars } from '../utils';
import UserAvatar from '../../../common/UserAvatar';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import GLink from '../../../common/GLink';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    photo: {
        borderRadius: '6px 6px 0 0',
        height: 'auto',
        width: '100%',
    },
    imageBox: {
        width: 'fit-content !important',
        height: '100%',
        position: 'relative',
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        borderRadius: 6,
        margin: 0,
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.secondary.main}`,
        '&$approve': {
            border: `1px solid ${GREEN_COLOR}`,
        },
        '&$block': {
            border: `1px solid ${RED_COLOR}`,
        },
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '0 8px 8px',
    },
    loaderContainer: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        margin: 0,
    },
    editPhotoButton: {
        padding: '0 4px',
        fontSize: 14,
        whiteSpace: 'nowrap',
        minHeight: 32,
        maxHeight: 32,
        textTransform: 'capitalize',
        border: 'none',
        '&:hover': {
            border: 'none',
        },
        '&$approve': {
            color: GREEN_COLOR
        },
        '&$block': {
            color: RED_COLOR
        },
        '& svg': {
            fontSize: 24
        },
    },
    photoWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '98%',
        zIndex: -1,
        borderRadius: 6,
    },
    bottomContainer: {
        padding: '0 4px',
        marginTop: '-4px',
    },
    pending: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        background: theme.palette.common.white,
        borderRadius: '0 0 6px 6px',
        minHeight: 32,
        boxSizing: 'border-box',
        marginTop: -3,
        '&$approve': {
            padding: 2,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& p': {
                lineHeight: 1.125,
                color: GREEN_COLOR,
            }
        },
        '&$block': {
            padding: 2,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& p': {
                lineHeight: 1.125,
                color: RED_COLOR,
            }
        }
    },
    moreVertButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: 4,
        right: 2,
        color: theme.palette.common.white,
        '& svg': {
            fontSize: 24,
            color: 'inherit',
            filter: 'drop-shadow(0 0px 2px gray)'
        }
    },
    visitorAvatar: {
        width: 40,
        height: 40,
        transform: 'translateY(-50%)',
        margin: '0 auto',
        top: '50%',
    },
    sharedByText: {
        margin: '-16px 4px 4px',
        paddingBottom: 4,
        borderBottom: `1px solid ${APP_SECONDARY_COLOR}`,
        '& p': {
            lineHeight: '20px',
            color: `${APP_SECONDARY_COLOR} !important`,
        },
    },
    block: {
        '& p': {
            color: RED_COLOR,
        },
    },
    approve: {
        '& p': {
            color: GREEN_COLOR,
        },
    },
    flagDiv: {
        backgroundColor: convertHexToRGBA(RED_COLOR, 0.18),
        borderRadius: '5px',
        border: '1px solid' + RED_COLOR,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '3px 0px',
        color: RED_COLOR,
        '& svg': {
            color: RED_COLOR
        },
        '& span': {
            color: RED_COLOR
        }
    }
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'photo' | 'imageBox' | 'editPhotoButton'
    | 'loadingText' | 'loaderContainer' | 'photoWrapper' | 'pending' | 'approve' | 'block'
    | 'moreVertButton' | 'visitorAvatar' | 'sharedByText' | 'bottomContainer' | 'flagDiv'>;

interface Props {
    imageId?: string;
    moderationPhoto: ModerationPhotoUX;
    isRememberPage: boolean;
    showHeader?: boolean;
    openMenu: (event: React.MouseEvent<HTMLElement>, photo: ModerationPhotoUX) => void;
    onApprove: () => void;
    onBlock: () => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, contributor: ModerationPhotoContributor) => void;
}

const ModerationPhoto = (props: StyledProps) => {
    const {
        classes,
        moderationPhoto,
        openMenu,
        imageId,
        isRememberPage,
        showHeader,
        onApprove,
        onBlock,
        openGuestPopper
    } = props;
    const { photo, case_fname, case_lname, case_name, fh_key } = moderationPhoto;
    const { contributor } = photo;

    const {
        isApproved,
        isBlocked,
        isPending,
        moderatorName,
        moderationTime,
    } = getModerationVars(moderationPhoto, ModerationCategory.photos);

    const transformations: CloudinaryTransformationsType[] = [{
        width: 320,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];

    const relationshipStr = contributor.relationship
        ? ` (${contributor.relationship})`
        : '';

    return (
        <div
            className={classNames(
                classes.imageBox,
                isApproved && classes.approve,
                isBlocked && classes.block,
            )}
            style={{ width: '100%' }}
        >
            {showHeader &&
                <GLink to={RouteBuilder.FamilyPage({
                    caseName: case_name,
                    funeralHomeKey: fh_key,
                })}>
                    <Typography
                        color="secondary"
                        fontSize="20px"
                        align="center"
                        fontWeight={500}
                    >
                        {case_fname} {case_lname}
                    </Typography>
                </GLink>
            }
            <img
                src={getPhotoUrl(photo.public_id, transformations)}
                className={classes.photo}
                id={imageId}
            />

            <div className={classes.photoWrapper} />

            <IconButton
                color="secondary"
                className={classes.moreVertButton}
                onClick={e => openMenu(e, moderationPhoto)}
                size="large">
                <MoreVertIcon />
            </IconButton>
            <div className={classes.bottomContainer} >
                <UserAvatar
                    className={classNames(classes.visitorAvatar, isRememberPage && classes.cursorPointer)}
                    user={contributor}
                    onClick={e => isRememberPage && openGuestPopper(e, contributor)}
                />
                <div className={classes.sharedByText}>
                    <Typography className={classes.fontSize16}>
                        <span className={classes.fontWeight500}>{contributor.fname}</span>{relationshipStr}
                    </Typography>
                    <Typography>
                        Uploaded {moment(photo.uploaded_time).fromNow()}
                    </Typography>
                </div>
                {isPending &&
                    <div className={classes.pending}>
                        <Button
                            className={classNames(classes.editPhotoButton, classes.approve)}
                            onClick={onApprove}
                        >
                            <DoneIcon />&nbsp;Approve
                        </Button>

                        <Button
                            className={classNames(classes.editPhotoButton, classes.block)}
                            onClick={onBlock}
                        >
                            <CloseIcon />&nbsp;Block
                        </Button>
                    </div>
                }
                {isApproved &&
                    <div className={classes.approve}>
                        <Typography align="center" component="p" className={classes.fontSize12}>
                            Approved {moderationTime}
                        </Typography>
                        <Typography align="center" component="p" className={classes.fontSize10}>
                            By {moderatorName}
                        </Typography>
                    </div>
                }
                {isBlocked &&
                    <div className={classes.block}>
                        <Typography align="center" component="p" className={classes.fontSize12}>
                            Blocked {moderationTime}
                        </Typography>
                        <Typography align="center" component="p" className={classes.fontSize10}>
                            By {moderatorName}
                        </Typography>
                    </div>
                }
            </div>
        </div >
    );
};

export default withGStyles(styles)(ModerationPhoto);
