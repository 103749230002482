import { DeathCertificateEducationType, DeathCertificateConfigUX, UserProfile } from '../../../shared/types';
import { isFieldOptional, ConfigurableEducationFieldKey } from './config';

export const educationValidators = {
    education: function (
        educationForm: DeathCertificateEducationType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const education = educationForm.educationListOfOptions;

        const isValid = Boolean(education);
        const isOptional = isFieldOptional(ConfigurableEducationFieldKey.educationListOfOptions, config, user);

        return isValid || isOptional;
    },
    history: function (
        { educationHistory }: DeathCertificateEducationType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = educationHistory && educationHistory.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableEducationFieldKey.educationHistory, config, user)) {
            return !touchCheck;
        }

        return false;
    },
};

export const educationMasterValidators = {
    valid: function (
        educationForm: DeathCertificateEducationType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
    ) {
        return (
            educationValidators.education(educationForm, config, user) &&
            educationValidators.history(educationForm, config, user)
        );
    },
    touched: function (
        educationForm: DeathCertificateEducationType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
    ) {
        return (
            educationValidators.education(educationForm, config, user, true) ||
            educationValidators.history(educationForm, config, user, true)
        );
    },
};
