import * as classNames from 'classnames';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import CaseLabelListItem from './CaseLabelListItem';
import { CaseLabelUX } from '../../../../shared/types';
import { useGStyles } from '../../../common/hooks/useGStyles';

interface Props {
    searchText: string;
    caseLabels: CaseLabelUX[];
    selectedLabels: CaseLabelUX[];
    caseFullName: string | null;
    showAddButton?: boolean;
    showEditButton: boolean;
    selectLabel: (label: CaseLabelUX) => void;
    removeLabel: (label: CaseLabelUX) => void;
    editLabel: (label: Partial<CaseLabelUX>) => void;
}

const CaseLabelList = (props: Props) => {
    const {
        searchText,
        caseLabels,
        selectedLabels,
        caseFullName,
        showAddButton,
        showEditButton,
        editLabel,
        selectLabel,
        removeLabel
    } = props;
    const classes = useGStyles();

    const filteredLabels = caseLabels.filter(({ name }) =>
        name.toLowerCase().includes(searchText.toLowerCase())
    );

    const renderNoResults = () => (
        <div className={classNames(classes.justifyContentCenter, classes.marginTop20)}>
            <Typography color="secondary" align="center">
                No results found. <br />
                {showEditButton &&
                    <span>
                        <span
                            className={classNames(classes.textUnderline, classes.cursorPointer)}
                            onClick={e => editLabel({ name: searchText })}
                        >
                            Click here
                        </span> to create a new label.
                    </span>
                }
            </Typography>
        </div>
    );

    if (!filteredLabels.length) {
        return renderNoResults();
    }

    return (
        <List component="nav">
            <Divider />

            {filteredLabels.map(label => {
                const isSelected = !!selectedLabels.find(sLabel => sLabel.id === label.id);
                return (
                    <CaseLabelListItem
                        key={label.id}
                        label={label}
                        isSelected={isSelected}
                        caseFullName={caseFullName}
                        selectLabel={selectLabel}
                        removeLabel={removeLabel}
                        showEditButton={showEditButton}
                        editLabel={editLabel}
                        showAddButton={showAddButton}
                    />
                );
            })}
        </List>
    );
};

export default CaseLabelList;
