import * as classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import WarningIcon from '@mui/icons-material/Warning';

import { LABEL_MAX_CHARS } from './NewLabel.dialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import makeGStyles from '../../../../styles/makeGStyles';
import { ChangeEventHandler } from 'react';

const useStyles = makeGStyles(theme => ({
    root: {},
    changesWarningText: {
        display: 'inline-block',
        margin: '8px 32px 0',
        lineHeight: 1.25,
        '& svg': {
            fontSize: 16,
            verticalAlign: 'text-bottom'
        }
    },
    padding_0_4: {
        padding: '0 4px'
    },
    maxWidth260: {
        maxWidth: 260
    },
    labelTextfield: {
        margin: '24px auto 40px',
    },
    selectColorContainer: {
        margin: '0 auto 40px'
    }
}), { name: 'NewLabelDialogContent' });

interface Props {
    isEditMode: boolean;
    label: string;
    labelColor: string;
    isInvalidLabel: boolean;
    handleLabelChange: ChangeEventHandler<HTMLInputElement>;
    handleColorChange: ChangeEventHandler<HTMLInputElement>;
    openDeleteConfirmationDialog: () => void;
    onSaveClick: () => void;
}
const NewLabelDialogContent = (props: Props) => {
    const {
        isEditMode,
        label,
        labelColor,
        isInvalidLabel,
        handleColorChange,
        handleLabelChange,
        openDeleteConfirmationDialog,
        onSaveClick
    } = props;

    const classes = useStyles();

    const remainingLabelChars = LABEL_MAX_CHARS - label.length;
    return (
        <>
            {isEditMode &&
                <Typography
                    align="center"
                    className={classNames(
                        classes.changesWarningText,
                        classes.colorWarning,
                    )}
                >
                    <WarningIcon />&nbsp;
                    Any changes made to the label name or label color will affect all cases that currently
                    use the label.
                </Typography>
            }
            <Grid
                container
                direction="column"
                className={classNames(classes.labelTextfield, classes.maxWidth260)}
            >
                <TextField
                    color="primary"
                    value={label}
                    onChange={handleLabelChange}
                    required
                    label="Label Name"
                    error={isInvalidLabel}
                    inputProps={{ maxLength: LABEL_MAX_CHARS }}
                />

                <Grid container justifyContent="space-between">
                    <Typography color="secondary">Example: COVID-19</Typography>

                    <Typography color="secondary">Max: {remainingLabelChars}</Typography>
                </Grid>
            </Grid>

            <Grid
                container
                direction="column"
                alignItems="center"
                className={classNames(classes.selectColorContainer, classes.maxWidth260)}
            >
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.fontSize18,
                        classes.marginBottom5,
                    )}
                >
                    Select Label Color *
                </Typography>

                <input
                    type="color"
                    value={labelColor}
                    onChange={handleColorChange}
                    className={classes.width100}
                    required
                />
            </Grid>

            <Grid container direction="column" alignItems="center">
                <Typography color="secondary" className={classes.fontSize18}>
                    Make sure you can read the label name
                </Typography>

                <Chip
                    label={label || 'Label'}
                    style={{ background: labelColor }}
                    className={classNames(classes.colorWhite, classes.fontSize16, classes.padding_0_4)}
                />
            </Grid>

            <Grid
                container
                justifyContent={isEditMode ? 'space-between' : 'center'}
                className={classes.marginTop50}
                alignItems="center"
                direction={!isEditMode ? 'column' : undefined}
            >
                {isEditMode &&
                    <Button
                        onClick={openDeleteConfirmationDialog}
                        className={classNames(
                            classes.colorRed,
                            classes.textTransformNone,
                            classes.fontWeight400,
                            classes.fontSize15
                        )}
                    >
                        <DeleteForever color="inherit" />&nbsp;Delete Label
                    </Button>
                }

                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={onSaveClick}
                    disabled={isInvalidLabel}
                >
                    {isEditMode ? 'Save' : 'Create'}
                </Button>
                {(!isEditMode && isInvalidLabel) &&
                    <Typography sx={{ color: 'error.main' }}>
                        A label with this name already exists
                    </Typography>
                }
            </Grid>
        </>
    );
};

export default NewLabelDialogContent;