
import * as React from 'react';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import { StoreState } from '../../../types';

import {
    GatherCaseUX,
    EntitySummary,
    VitalDates,
    DeathCertificateType,
    DeathCertificateUpdateRequest,
    TaskTemplateType,
    TaskUpdateRequestUX,
    CaseTaskUX,
} from '../../../shared/types';

import ApprovalForm from './forms/ApprovalForm';
import OverviewForm from './forms/overviewForm/OverviewForm';
import { openMySettingsDialog } from '../../../actions/Dialog.action';
import { HelperUserSettingsTab } from '../../dialogs/mySettings/HelperUserSettings';
import DeathCertificateQuestions from './DeathCertificateQuestions';
import LoadingSpinner from '../../common/LoadingSpinner';
import { updateTask } from '../../../actions/task/Task.action';
import { AppDispatch } from '../../../store';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { canManageDD214OnCase } from '../../../shared/authority/can';

const styles: StyleRulesCallback<Theme, OwnProps> = theme => ({
    root: {
    },
    snackbarContent: {
        minWidth: 'unset',
        '@media (max-width: 960px)': {
            flexGrow: 0
        },
    },
});

export enum TopTabKey {
    questions = 'questions',
    approval = 'approval',
    overview = 'overview',
}

export const isTopTabKey = (key: string): key is TopTabKey => {
    return Boolean(TopTabKey[key]);
};

function mapStateToProps(
    { userSession, deathCertificateConfigState, tasksState }: StoreState,
    { selectedCase }: OwnProps) {
    // WARNING: Pulling case from Redux will break DC configuration back office
    const dcTask = tasksState.checklistTasks.find((t) =>
        t.template_type === TaskTemplateType.death_certificate) || null;
    return {
        user: userSession.userData,
        activeConfig: deathCertificateConfigState.activeDeathCertificateConfig,
        dcTask,
        canUserManageDD214OnCase: canManageDD214OnCase(
            userSession.userData,
            selectedCase.funeral_home_id,
            selectedCase.id,
            tasksState.checklistTasks
        )
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}
interface OwnProps {
    selectedCase: GatherCaseUX;
    isEditMode: boolean;
    selectedTab: TopTabKey;
    deathCertificate: DeathCertificateType;
    helpers: EntitySummary[];
    topOffset?: number;
    setSelectedTab: (tab: TopTabKey) => void;
    updateVitalDates: (changes: VitalDates) => void;
    saveDeathCertificate: (updateRequest: DeathCertificateUpdateRequest) => void;
    openConfigPopper: (event: React.MouseEvent<HTMLElement>) => void;
}

interface State {
    isOpenSnackbar: boolean;
}

type CombinedProps = OwnProps & InjectedProps & WithGStyles<'root' | 'snackbarContent'>;

class DeathCertificateContent extends React.Component<CombinedProps, State> {
    state: State = {
        isOpenSnackbar: false,
    };

    updateTask = (
        taskId: number,
        taskChanges: TaskUpdateRequestUX,
        caseUuid: string,
        taskTemplate: TaskTemplateType | null
    ): Promise<CaseTaskUX | null> => {
        const { dispatch } = this.props;

        return dispatch(updateTask(taskId, taskChanges, caseUuid, taskTemplate));
    };

    updateDCLocked = (isLocked: boolean) => {
        const { saveDeathCertificate } = this.props;

        // bypass debounce when lock is being changed
        saveDeathCertificate({ death_certificate_locked: isLocked });
    };

    handleEditHelper = (helper: EntitySummary, tab?: HelperUserSettingsTab) => {
        const { dispatch } = this.props;
        dispatch(openMySettingsDialog(1322, helper.entity_id, tab));
    };

    handleCloseSnackbar = () => {
        this.setState({ isOpenSnackbar: false });
    };

    handleOpenSnackbar = () => {
        const { selectedCase } = this.props;

        if (selectedCase.death_certificate_locked) {
            this.setState({ isOpenSnackbar: true });
        }
    };

    render() {
        const {
            classes,
            selectedCase,
            selectedTab,
            user,
            deathCertificate,
            helpers,
            isEditMode,
            saveDeathCertificate,
            updateVitalDates,
            setSelectedTab,
            activeConfig,
            openConfigPopper,
            topOffset,
            dcTask,
            canUserManageDD214OnCase
        } = this.props;
        const {
            isOpenSnackbar,
        } = this.state;

        if (!user) {
            return null;
        }

        const isDeathCertificateLocked: boolean = selectedCase.death_certificate_locked;
        if (!activeConfig) {
            return (
                <LoadingSpinner containerClass={classes.marginTop10} />
            );
        }

        return (
            <>
                {selectedTab === TopTabKey.questions &&
                    <DeathCertificateQuestions
                        activeConfig={activeConfig}
                        selectedCase={selectedCase}
                        helpers={helpers}
                        user={user}
                        deathCertificate={deathCertificate}
                        isEditMode={isEditMode}
                        topOffset={topOffset}
                        dcTask={dcTask}
                        canUserManageDD214OnCase={canUserManageDD214OnCase}
                        updateVitalDates={updateVitalDates}
                        gotoApproval={() => setSelectedTab(TopTabKey.approval)}
                        openAboutFormPopper={openConfigPopper}
                        handleOpenSnackbar={this.handleOpenSnackbar}
                        saveDeathCertificate={saveDeathCertificate}
                        updateTask={this.updateTask}
                    />
                }
                {selectedTab === TopTabKey.approval &&
                    <ApprovalForm
                        active
                        activeCase={selectedCase}
                        user={user}
                        helpers={helpers}
                        data={{ dc_informant: selectedCase.dc_informant }}
                        onSaveData={(data) => saveDeathCertificate({ dc_informant: data.dc_informant })}
                        onLockedChange={this.updateDCLocked}
                        dc={deathCertificate}
                        dcConfig={activeConfig}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        editHelper={this.handleEditHelper}
                    />
                }
                {selectedTab === TopTabKey.overview &&
                    <OverviewForm
                        dc={deathCertificate}
                        dcConfig={activeConfig}
                        user={user}
                        activeCase={selectedCase}
                        helpers={helpers}
                        dcTask={dcTask}
                    />
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={isOpenSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        classes: {
                            root: classNames(
                                classes.backgroundPrimary,
                                classes.snackbarContent
                            )
                        }
                    }}
                    message={<span id="message-id">{selectedCase.fname}'s data is locked</span>}
                    action={
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleCloseSnackbar}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(DeathCertificateContent));
