import * as t from 'io-ts';
import { S3FileCreateRequest, S3FileCreateRequestType, S3FileRecord } from './doc';
import { getValidator } from './utils';

export enum PreviewPictureDecision {
    return_to_family = 'RETURN ITEM',
    keep = 'RECORD AS KEPT',
    donate = 'DONATE ITEM',
    dispose = 'THROW AWAY',
    unsure = 'CLICK TO EDIT'
}

export enum PreviewDecision {
    return_to_family = 'To be returned...',
    keep = 'To be kept with',
    donate = 'To be donated...',
    dispose = 'To be thrown away...',
    unsure = 'Unsure...'
}

export enum CaseBelongingDecisionType {
    return_to_family = 'return_to_family',
    keep = 'keep',
    donate = 'donate',
    dispose = 'dispose',
    unsure = 'unsure',
}

export enum FinalItemDecision {
    return_to_family = 'Returned to Family',
    keep = 'Kept with',
    donate = 'Donated',
    dispose = 'Thrown away',
    unsure = 'Unsure'
}

export const CASE_BELONGING_DECISION_TYPE = t.union([
    t.literal(CaseBelongingDecisionType.return_to_family),
    t.literal(CaseBelongingDecisionType.keep),
    t.literal(CaseBelongingDecisionType.donate),
    t.literal(CaseBelongingDecisionType.dispose),
    t.literal(CaseBelongingDecisionType.unsure),
]);

export interface CaseBelongingRecord {
    id: number;
    gather_case_id: number;
    photo_s3_file_id: number | null;
    name: string;
    description: string | null;
    decision: CaseBelongingDecisionType;
    decision_signature_s3_file_id: number;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    completed_time: Date | null;
    completed_by: number | null;
    completed_by_signature_s3_file_id: number | null;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface CaseBelongingUXRecord {
    id: number;
    name: string;
    description: string | null;
    decision: CaseBelongingDecisionType;
    created_time: Date;
    created_by: number;
    created_by_full_name: string;
    updated_time: Date;
    updated_by: number;
    updated_by_full_name: string;
    completed_time: Date | null;
    completed_by: number | null;
    completed_by_full_name: string | null;
    photo_s3_file: S3FileRecord | null;
    decision_sig_s3_file: S3FileRecord;
    completed_sig_s3_file: S3FileRecord | null;
}

export interface CaseBelongingUX extends
    Omit<CaseBelongingUXRecord, 'photo_s3_file' | 'decision_sig_s3_file' | 'completed_sig_s3_file'> {
    photo_url: string | null;
    decision_signature_url: string;
    completed_by_signature_url: string | null;
}

export interface CaseBelongingTableUXRecord extends
    Pick<CaseBelongingRecord, 'id' | 'name' | 'description' | 'decision' | 'updated_time' | 'completed_time'> {
    photo_s3_file: S3FileRecord | null;
    gather_case_id: number;
    gather_case_name: string;
    modified_time: Date;
    modified_by_full_name: string;
    gather_case_full_name: string;
    gather_case_fname: string;
}
export interface CaseBelongingTableUX extends
    Omit<CaseBelongingTableUXRecord, 'modified_time' | 'decision'> {
    modified_time: string;
    decision: string;
    photo_url: string | null;
}

export interface CaseBelongingCount {
    count: number;
}

export interface CaseBelongingPlaceHolder {
    name: string;
    description: string | null;
    decision: CaseBelongingDecisionType;
}

// ---> CaseBelongingCreateRequest <---
const caseBelongingCreateOnly = {
    photo: t.union([S3FileCreateRequestType, t.null]),
};

const caseBelongingCreateAndUpdate = {
    decision: CASE_BELONGING_DECISION_TYPE,
    isComplete: t.boolean,
    signature: S3FileCreateRequestType,
    name: t.string,
    description: t.union([t.string, t.null]),
};

const CaseBelongingCreateRequestType = t.intersection([
    t.type(caseBelongingCreateAndUpdate),
    t.type(caseBelongingCreateOnly),
]);

export interface CaseBelongingCreateRequest extends t.TypeOf<typeof CaseBelongingCreateRequestType> {
    decision: CaseBelongingDecisionType;
    photo: S3FileCreateRequest | null;
    signature: S3FileCreateRequest;
}

export class CaseBelongingCreateRequest {
    public static fromRequest = getValidator<CaseBelongingCreateRequest>(CaseBelongingCreateRequestType);
}

// ---> CaseBelongingUpdateRequest <---
const CaseBelongingUpdateRequestType = t.type(caseBelongingCreateAndUpdate);

export interface CaseBelongingUpdateRequest extends t.TypeOf<typeof CaseBelongingUpdateRequestType> {
    decision: CaseBelongingDecisionType;
    signature: S3FileCreateRequest;
}

export class CaseBelongingUpdateRequest {
    public static fromRequest = getValidator<CaseBelongingUpdateRequest>(CaseBelongingUpdateRequestType);
}

export const getDecisionLabel = (
    value: string,
    completed: boolean,
    firstName: string
) => {
    const decisionLabel = completed ? FinalItemDecision : PreviewDecision;
    switch (value) {
        case CaseBelongingDecisionType.return_to_family:
            return decisionLabel.return_to_family;
        case CaseBelongingDecisionType.keep:
            return `${decisionLabel.keep} ${firstName}`;
        case CaseBelongingDecisionType.donate:
            return decisionLabel.donate;
        case CaseBelongingDecisionType.dispose:
            return decisionLabel.dispose;
        case CaseBelongingDecisionType.unsure:
            return decisionLabel.unsure;
        default:
            return decisionLabel.return_to_family;
    }
};
