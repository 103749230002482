import { some, every, includes } from 'lodash';
import {
    DocPacketState,
    CaseDocState,
    FuneralHomeDocState,
} from '../types';
import {
    SET_FUNERALHOME_DOCS,
    DocAction,
    UPDATE_FUNERALHOME_DOC,
    SET_CASE_DOCS,
    UPDATE_CASE_DOC,
    SET_FUNERALHOME_DOCS_LOADING,
    ADD_FUNERALHOME_DOCS,
    ADD_CASE_DOCS,
    SET_FUNERALHOME_DOC,
    SET_CASE_DOC,
    REMOVE_FUNERALHOME_DOCS,
    convertUXtoUXDetail,
} from '../actions/Doc.action';
import * as constants from '../constants';
import {
    SET_DOC_PACKETS,
    SET_DOC_PACKET,
    ADD_DOC_PACKET,
    REMOVE_DOC_PACKET,
    SET_DOC_PACKETS_LOADING,
    SET_DOC_PACKET_SAVING,
    ADD_DOC_PACKET_SIGNED_DOC,
    REMOVE_DOC_PACKET_SIGNED_DOC,
    SET_DOC_PACKET_CONTRACT_SIGNERS,
    UPDATE_DOC_PACKET,
    SET_DOC_PACKET_DOC_SIGNER,
    UPDATE_DOC_PACKET_SIGNER,
} from '../actions/DocPacket.action';
import { DocPacketSignerUX, DocPacketDocUX, DocUXDetail } from '../shared/types';
import { GatherAction } from '../actions';
import { ORGANIZE_PAGE_LOADED, TRACKING_PAGE_LOADED } from '../actions/GatherCase.action';
import { INITIALIZE_STEP_LOADED, TASK_LOADED } from '../actions/task/Task.action';

export const docPacketInitData: DocPacketState = {
    docPackets: [],
    isLoading: false,
    isSaving: false,
};

export const caseDocInitData: CaseDocState = {
    docs: [],
    isLoading: false,
};

export const funeralHomeDocInitData: FuneralHomeDocState = {
    docs: [],
    isLoading: false,
};

export const funeralHomeDocState = (
    state: FuneralHomeDocState = funeralHomeDocInitData,
    action: DocAction | GatherAction,
): FuneralHomeDocState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return funeralHomeDocInitData;
        case SET_FUNERALHOME_DOCS: {
            return {
                ...state,
                docs: action.funeralHomeDocs,
            };
        }
        case SET_FUNERALHOME_DOCS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case ADD_FUNERALHOME_DOCS: {
            return {
                ...state,
                docs: [...state.docs, ...action.docs]
            };
        }
        case REMOVE_FUNERALHOME_DOCS: {
            const updatedDocList = state.docs.filter((existingDoc) => {
                return (!includes(action.docUids, existingDoc.uid));
            });
            return {
                ...state,
                docs: updatedDocList,
            };
        }
        case SET_FUNERALHOME_DOC: {
            return {
                ...state,
                docs: state.docs.map((detail) => {
                    if (detail.uid === action.uid) {
                        const updatedDetail: DocUXDetail = {
                            ...detail,
                            label: action.docUX.label,
                            doc: action.docUX,
                            status: 'uploaded',
                        };
                        return updatedDetail;
                    } else {
                        return detail;
                    }
                })
            };
        }
        case UPDATE_FUNERALHOME_DOC: {
            if (!state) {
                return state;
            }
            return {
                ...state,
                docs: state.docs.map((detail) => {
                    if (detail.doc && detail.doc.id === action.docId) {
                        return {
                            ...detail,
                            label: action.docChanges.label || detail.label,
                            doc: {
                                ...detail.doc,
                                ...action.docChanges,
                                label: action.docChanges.label || detail.doc.label,
                            }
                        };
                    } else {
                        return detail;
                    }
                })
            };
        }
        default:
            return state;
    }
};

export const caseDocState = (
    state: CaseDocState = caseDocInitData,
    action: DocAction | GatherAction,
): CaseDocState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return caseDocInitData;
        case ORGANIZE_PAGE_LOADED:
        case TRACKING_PAGE_LOADED:
        case SET_CASE_DOCS: {
            return {
                ...state,
                docs: action.caseDocs.map(convertUXtoUXDetail),
            };
        }
        case INITIALIZE_STEP_LOADED:
        case TASK_LOADED: {
            return {
                ...state,
                docs: action.docs?.map(convertUXtoUXDetail) ?? state.docs,
            };
        }
        case SET_CASE_DOC: {
            return {
                ...state,
                docs: state.docs.map((detail) => {
                    if (detail.uid === action.uid) {
                        const updatedDetail: DocUXDetail = {
                            ...detail,
                            label: action.docUX.label,
                            doc: action.docUX,
                            status: 'uploaded',
                            taskComponentId: action.docUX.case_file_task_component_id ?? undefined,
                        };
                        return updatedDetail;
                    } else {
                        return detail;
                    }
                })
            };
        }
        case ADD_CASE_DOCS: {
            return {
                ...state,
                docs: [...state.docs, ...action.docs],
            };
        }
        case UPDATE_CASE_DOC: {
            if (!state) {
                return state;
            }
            return {
                ...state,
                docs: state.docs.map((detail) => {
                    if (detail.doc && detail.doc.id === action.docChanges.id) {
                        return {
                            ...detail,
                            label: action.docChanges.label || detail.label,
                            doc: {
                                ...detail.doc,
                                ...action.docChanges,
                                label: action.docChanges.label || detail.label,
                            }
                        };
                    } else {
                        return detail;
                    }
                }
                )
            };
        }
        default:
            return state;
    }
};

export const docPacketState = (
    state: DocPacketState = docPacketInitData,
    action: GatherAction,
): DocPacketState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return docPacketInitData;
        case SET_DOC_PACKETS: {
            return {
                ...state,
                docPackets: action.docPackets,
            };
        }
        case SET_DOC_PACKETS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case SET_DOC_PACKET_SAVING: {
            return {
                ...state,
                isSaving: action.isSaving,
            };
        }
        case SET_DOC_PACKET: {
            let notFound = true;
            let updatedPackets = state.docPackets.map((existing) => {
                if (existing.id === action.id) {
                    notFound = false;
                    return action.docPacket;
                } else {
                    return existing;
                }
            });
            if (notFound) {
                updatedPackets = [...updatedPackets, action.docPacket];
            }
            return {
                ...state,
                docPackets: updatedPackets,
            };
        }
        case ADD_DOC_PACKET: {
            return {
                ...state,
                docPackets: [
                    ...state.docPackets,
                    action.docPacket,
                ],
            };
        }
        case UPDATE_DOC_PACKET: {
            return {
                ...state,
                docPackets: state.docPackets.map((docPacket) => {
                    if (docPacket.id === action.id) {
                        return {
                            ...docPacket,
                            ...action.changes,
                        };
                    } else {
                        return docPacket;
                    }
                })
            };
        }
        case REMOVE_DOC_PACKET: {
            return {
                ...state,
                docPackets: state.docPackets.filter((docPacket) => docPacket.id !== action.docPacketId),
            };
        }
        case SET_DOC_PACKET_CONTRACT_SIGNERS: {
            return {
                ...state,
                docPackets: state.docPackets.map((docPacket) => {
                    if (docPacket.id === action.docPacketId) {
                        const personsToAddAsPacketSigners = action.signers.filter((person) => {
                            return some(docPacket.signers, (signer) => signer.person.entity_id === person.entity_id);
                        });
                        const packetSignersToAdd: DocPacketSignerUX[] =
                            personsToAddAsPacketSigners.map((person, i) => ({
                                id: -i,
                                person,
                                person_id: person.entity_id,
                                doc_packet_id: docPacket.id,
                                signing_docs: [],
                                signing_contracts: { [action.packetContractId]: action.group },
                                hellosign_id: null,
                                hellosign_status: null,
                                hellosign_status_time: null,
                                last_reminder_sent_time: null,
                                history: null,
                            }));

                        const updatedPacketSigners = [...docPacket.signers, ...packetSignersToAdd];
                        return {
                            ...docPacket,
                            signers: updatedPacketSigners,
                            contracts: docPacket.contracts.map((packetContract) => {
                                if (packetContract.contract_id === action.packetContractId) {
                                    const otherSigners = packetContract.signers.filter((s) =>
                                        s.signer_group !== action.group);
                                    const newGroupSigners = action.signers.map((person) => {
                                        const packetSigner = updatedPacketSigners.find((s) => {
                                            return s.person.entity_id === person.entity_id;
                                        });
                                        return {
                                            doc_packet_contract_id: action.packetContractId,
                                            doc_packet_signer_id: packetSigner ? packetSigner.id : -1,
                                            signer_group: action.group,
                                        };
                                    });
                                    return {
                                        ...packetContract,
                                        signers: [...otherSigners, ...newGroupSigners],
                                    };
                                } else {
                                    return packetContract;
                                }
                            }),
                        };
                    } else {
                        return docPacket;
                    }
                })
            };
        }
        case SET_DOC_PACKET_DOC_SIGNER: {
            return {
                ...state,
                docPackets: state.docPackets.map((docPacket) => {
                    if (docPacket.id === action.docPacketId) {
                        const optimisticPacketSigners: DocPacketSignerUX[] = [...docPacket.signers];
                        if (every(optimisticPacketSigners, (ps) => ps.person_id !== action.signer.entity_id)) {
                            optimisticPacketSigners.push({
                                id: action.signer.entity_id,
                                person: action.signer,
                                signing_docs: { [action.docId]: [action.group] },
                                signing_contracts: [],
                                doc_packet_id: docPacket.id,
                                person_id: action.signer.entity_id,
                                hellosign_id: null,
                                hellosign_status: null,
                                hellosign_status_time: null,
                                last_reminder_sent_time: null,
                                history: null,
                            });
                        }
                        return {
                            ...docPacket,
                            signers: optimisticPacketSigners,
                            docs: docPacket.docs.map((packetDoc) => {
                                const optimisticPacketDoc: DocPacketDocUX = {
                                    ...packetDoc,
                                    signers: {
                                        ...packetDoc.signers,
                                        [action.group]: action.signer.entity_id,
                                    },
                                };

                                if (packetDoc.doc.id === optimisticPacketDoc.doc.id) {
                                    return optimisticPacketDoc;
                                } else {
                                    return packetDoc;
                                }
                            }),
                        };
                    } else {
                        return docPacket;
                    }
                })
            };
        }
        case UPDATE_DOC_PACKET_SIGNER: {
            return {
                ...state,
                docPackets: state.docPackets.map((docPacket) => {
                    if (docPacket.id === action.docPacketId) {
                        const updatedSigners: DocPacketSignerUX[] = docPacket.signers.map((signer) => {
                            if (signer.person_id === action.signerPersonId) {
                                return {
                                    ...signer,
                                    ...action.changes, // this only changes DocPacketSignerRecord items
                                };
                            } else {
                                return signer;
                            }
                        });
                        return {
                            ...docPacket,
                            signers: updatedSigners
                        };

                    } else {
                        return docPacket;
                    }
                })
            };
        }
        case ADD_DOC_PACKET_SIGNED_DOC: {
            return {
                ...state,
                docPackets: state.docPackets.map((docPacket) => {
                    if (docPacket.id === action.docPacketId) {
                        return {
                            ...docPacket,
                            signedDocs: [...docPacket.signedDocs, action.signedDoc],
                        };
                    } else {
                        return docPacket;
                    }
                }),
            };
        }
        case REMOVE_DOC_PACKET_SIGNED_DOC: {
            return {
                ...state,
                docPackets: state.docPackets.map((docPacket) => {
                    if (docPacket.id === action.docPacketId) {
                        return {
                            ...docPacket,
                            signedDocs: docPacket.signedDocs.filter((signedDoc) => signedDoc.id !== action.docId),
                        };
                    } else {
                        return docPacket;
                    }
                }),
            };
        }
        default:
            return state;
    }
};
