import { Component } from 'react';
import { find } from 'lodash';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import ListAltIcon from '@mui/icons-material/ListAlt';

import { ContractTaxationMethod, ProductTaxRateUX, ProductTaxRateRequest } from '../../../shared/types';
import AvailableTaxRatesDialog from '../../allProducts/AvailableTaxRates.dialog';
import { displayTaxRate } from '../../../shared/goods_and_services/pricing';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        minHeight: 290,
    },
    toggleContainer: {
        height: 56,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '@media (max-width: 500px)': {
            marginTop: 10,
        },
    },
    toggleButton: {
        height: 32,
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54) !important',
        border: 'none',
    },
    toggleButtonGroup: {
        boxShadow: `0px 1px 5px 0px rgb(0 0 0 / 20%), 
                    0px 2px 2px 0px rgb(0 0 0 / 14%), 
                    0px 3px 1px -2px rgb(0 0 0 / 12%)`,
    },
    content: {
        margin: '12px auto 32px',
        '& p': {
            fontWeight: 200,
        }
    },
    formControl: {
        margin: '8px auto 0',
        display: 'block',
        width: '100%',
        maxWidth: 280,
    },
    defaultProductTaxSection: {
        color: theme.palette.primary.main,
        borderTop: '1px solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 16
    },
});

type StyledProps = Props & WithGStyles<'root' | 'toggleContainer' | 'toggleButton' | 'toggleButtonGroup'
    | 'content' | 'formControl' | 'defaultProductTaxSection'>;

interface Props {
    taxRates: ProductTaxRateUX[];
    isGatherUser: boolean;
    isSaveClicked: boolean;
    itemTaxRateId?: number | null;
    contractTaxRateId: number | null;
    taxationMethod: ContractTaxationMethod | null;
    isDialog?: boolean;
    onTaxationMethodChange: (method: ContractTaxationMethod) => void;
    onItemTaxRateChange?: (taxRateId: number | null) => void;
    onContractTaxRateChange: (taxRateId: number | null) => void;
    createProductTaxRate?: (taxRate: ProductTaxRateRequest) => Promise<ProductTaxRateUX | null>;
    updateProductTaxRate?: (
        taxRateId: number,
        taxRate: ProductTaxRateRequest,
    ) => Promise<ProductTaxRateUX | null>;
}

interface State {
    isAvailableItemTaxRatesDialogOpen: boolean;
    isAvailableContractTaxRatesDialogOpen: boolean;
}

class TaxManagement extends Component<StyledProps, State> {
    state: State = {
        isAvailableItemTaxRatesDialogOpen: false,
        isAvailableContractTaxRatesDialogOpen: false,
    };

    renderAvailableTaxRatesDialog = () => {
        const { taxRates, isGatherUser, createProductTaxRate, updateProductTaxRate } = this.props;
        const { isAvailableItemTaxRatesDialogOpen, isAvailableContractTaxRatesDialogOpen } = this.state;

        return (
            <AvailableTaxRatesDialog
                zIndex={1322}
                isDialogOpen={isAvailableItemTaxRatesDialogOpen || isAvailableContractTaxRatesDialogOpen}
                closeDialog={this.closeAvailableTaxRatesDialog}
                taxRates={taxRates}
                isGatherUser={isGatherUser}
                onSelect={this.handleTaxRateSelect}
                onCreate={createProductTaxRate}
                onUpdate={updateProductTaxRate}
            />
        );
    };

    renderItemLevelContent = () => {
        const { classes, isDialog } = this.props;

        const content = isDialog && `With ITEM LEVEL tax selected, the total tax bill for this 
        statement will be calculated as the sum of each product’s individual taxability setting.
        You can adjust each product’s taxability setting from your “Goods & Services” page.`
            || `With ITEM LEVEL tax selected, the total tax bill for the statement will be
             calculated as the sum of each product’s individual taxability setting. 
            This is just a default that can be adjusted as needed for each case.`;

        return (
            <Grid item className={classes.content}>
                <Typography color="primary" align="center">
                    {content}
                </Typography>
            </Grid>
        );
    };

    renderContractLevelContent = () => {
        const { classes, contractTaxRateId, taxRates, isDialog, isSaveClicked } = this.props;

        const selectedContractTaxRate = contractTaxRateId &&
            find(taxRates, (tr) => tr.id === contractTaxRateId) || null;

        const content = isDialog && `With STATEMENT LEVEL tax selected, you can specify below the rate you
        want to be applied to this entire statement. The rate selected will
        apply to ALL products on this statement regardless of their individual taxability settings.`
            || ` With STATEMENT LEVEL tax selected, you can specify below the rate you want to be 
            applied to the entire given statement by default. 
            The rate selected will apply to ALL products on the given statement regardless of their individual 
            taxability settings. This is just a default that can be adjusted as needed for each case.`;

        return (
            <Grid item className={classes.content}>
                <Typography color="primary" align="center">
                    {content}
                </Typography>
                <FormControl className={classes.formControl}>
                    <TextField
                        onClick={this.openAvailableContractTaxRatesDialog}
                        value={displayTaxRate(selectedContractTaxRate)}
                        fullWidth
                        label="Statement Level Taxability"
                        disabled
                        error={isSaveClicked && !contractTaxRateId}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="primary"
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.openAvailableContractTaxRatesDialog();
                                        }}
                                        size="large">
                                        <ListAltIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
        );
    };

    renderNonTaxableContent = () => {
        const { classes, isDialog } = this.props;

        const content = isDialog && `With NON-TAXABLE selected,
        this entire statement will NOT be taxed regardless of individual product taxability settings.`
            || `With NON-TAXABLE selected, the entire given statement will NOT be taxed regardless of 
            individual product taxability settings. This is just a default that can be adjusted as 
            needed for each case.`;

        return (
            <Grid item className={classes.content}>
                <Typography color="primary" align="center">
                    {content}
                </Typography>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            taxationMethod,
            itemTaxRateId,
            taxRates,
            isDialog,
            onTaxationMethodChange,
        } = this.props;

        const selectedItemTaxRate = itemTaxRateId && find(taxRates, (tr) => tr.id === itemTaxRateId) || null;

        return (
            <div className={classes.root}>
                <div className={classes.toggleContainer}>
                    <Grid
                        container
                        alignItems="flex-end"
                        justifyContent="center"
                    >
                        <ToggleButtonGroup
                            value={taxationMethod || ''}
                            exclusive
                            className={classes.toggleButtonGroup}
                        >
                            <ToggleButton
                                value="per_item_basis"
                                className={classes.toggleButton}
                                onClick={() => onTaxationMethodChange('per_item_basis')}
                            >
                                ITEM LEVEL
                            </ToggleButton>
                            <ToggleButton
                                value="contract_basis"
                                className={classes.toggleButton}
                                onClick={() => onTaxationMethodChange('contract_basis')}
                            >
                                STATEMENT LEVEL
                            </ToggleButton>
                            <ToggleButton
                                value="exempt"
                                className={classes.toggleButton}
                                onClick={() => onTaxationMethodChange('exempt')}
                            >
                                NON-TAXABLE
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </div>

                {taxationMethod === 'per_item_basis' && this.renderItemLevelContent()}
                {taxationMethod === 'contract_basis' && this.renderContractLevelContent()}
                {taxationMethod === 'exempt' && this.renderNonTaxableContent()}

                {!isDialog && itemTaxRateId !== undefined &&
                    <Grid item className={classes.defaultProductTaxSection}>
                        <Typography color="secondary" className={classes.fontSize16} align="center">
                            DEFAULT PRODUCT TAXABILITY
                        </Typography>

                        <Typography color="secondary" align="center">
                            This is simply the default taxability option that will be
                            selected when creating new products.
                            This is just for convenience and can be adjusted on a per product basis, as needed.
                        </Typography>

                        <FormControl className={classes.formControl}>
                            <TextField
                                onClick={e => this.openAvailableItemTaxRatesDialog()}
                                value={displayTaxRate(selectedItemTaxRate)
                                }
                                fullWidth
                                label="Product Taxability"
                                disabled
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                color="primary"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    this.openAvailableItemTaxRatesDialog();
                                                }}
                                                size="large">
                                                <ListAltIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                }
                {this.renderAvailableTaxRatesDialog()}
            </div>
        );
    }

    openAvailableItemTaxRatesDialog = () => {
        this.setState({
            isAvailableItemTaxRatesDialogOpen: true,
        });
    };

    openAvailableContractTaxRatesDialog = () => {
        this.setState({
            isAvailableContractTaxRatesDialogOpen: true,
        });
    };

    closeAvailableTaxRatesDialog = () => {
        this.setState({
            isAvailableContractTaxRatesDialogOpen: false,
            isAvailableItemTaxRatesDialogOpen: false,
        });
    };

    handleTaxRateSelect = (taxRate: ProductTaxRateUX | null) => {
        const { onContractTaxRateChange, onItemTaxRateChange } = this.props;
        const { isAvailableContractTaxRatesDialogOpen, isAvailableItemTaxRatesDialogOpen } = this.state;
        if (isAvailableContractTaxRatesDialogOpen) {
            onContractTaxRateChange(taxRate ? taxRate.id : null);
        } else if (onItemTaxRateChange && isAvailableItemTaxRatesDialogOpen) {
            onItemTaxRateChange(taxRate ? taxRate.id : null);
        }
        this.closeAvailableTaxRatesDialog();
    };
}

export default withGStyles(styles)(TaxManagement);
