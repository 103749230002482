import { escapeRegExp, includes, isString } from 'lodash';
import GatherLocales from '../locale';
import momentTz from 'moment-timezone';
type FormatterType =
    | 'size'
    | 'state'
    | 'text'
    | 'date'
    | 'name'
    | 'if_true'
    | 'if_false'
    | 'if_blank'
    | 'if_notblank'
    | 'group'
    | 'option'
    | 'separator'
    | 'equals'
    | 'display_override'
    | 'remove';
enum FormatterTypeEnum {
    size = 'size',
    state = 'state',
    text = 'text',
    date = 'date',
    name = 'name',
    if_true = 'if_true',
    if_false = 'if_false',
    if_blank = 'if_blank',
    if_notblank = 'if_notblank',
    group = 'group',
    option = 'option',
    separator = 'separator',
    equals = 'equals',
    display_override = 'display_override',
    remove = 'remove',
}

const isFormatterType = (type: string): type is FormatterType => {
    return FormatterTypeEnum.hasOwnProperty(type);
};

export type FormatterIfValueTypes = 'x' | 'check' | 'checked' | 'on' | 'yes' | 'true'
| 'off' | 'no' | 'n/a' | 'na' | 'blank' | '_' | 'false';

export enum FormatterIfValueTypePositiveEnum {
    x = 'x',
    check = 'check',
    checked = 'checked',
    on = 'on',
    yes = 'yes',
    true = 'true',
}

export enum FormatterIfValueTypeEnum {
    x = 'x',
    check = 'check',
    checked = 'checked',
    on = 'on',
    yes = 'yes',
    true = 'true',
    off = 'off',
    no = 'no',
    'n/a' = 'n/a',
    na = 'na',
    blank = '',
    '_' = '',
    false = 'false',
}

export interface FormatDetail {
    formatType: FormatterType;
    formatDetails: string;
}

export type GatherSupportedLocale = 'us';

export interface GatherFormat {
    formatTypeMatch: FormatterType;
    formatDetails: string;
}

export type GatherFormats = FormatDetail[];

export default class Formatter {
    readonly formatDelimiter: string = '|';
    readonly formatDelimiterCount: number = 2;
    // type: FormatterType;
    formats: GatherFormats;
    separatorString: string;
    defaultFormats: GatherFormats;
    requiredFormats: GatherFormats;
    data: string[];
    locale: GatherSupportedLocale;
    finalFill: boolean;  // defaults true 
    constructor(
        finalFill: boolean = true,
        rawFormat?: string,
        data?: string | string[],
        settings?: Partial<Formatter>
    ) {
        if (settings && settings.formatDelimiter) {
            this.formatDelimiter = settings.formatDelimiter;
        }
        if (settings && settings.locale) {
            this.locale = settings.locale;
        }
        this.finalFill = finalFill;
        this.formats = [];
        this.format(rawFormat);
        this.separatorString = ' ';
        this.data = [];
        if (data) {
            this.setData(data);
        }
        return this;
    }
    public setFinalFill = (setVal?: boolean): Formatter => {
        if (setVal) {
            this.finalFill = setVal;
        }
        return this;
    };

    public toString = (): string => {
        if (this.data) {
            if (this.separatorString !== null && this.separatorString !== '') {
                let useSeparator = this.separatorString;
                if (this.separatorString === 'SPACE' || this.separatorString === '_') {
                    useSeparator = ' ';
                }
                return this.data.join(useSeparator);
            }
            return this.data.join(' ');
        }
        return '';
        // return `${this.data}`;
    };

    public format(rawFormat?: string): GatherFormats {
        if (rawFormat) {
            const formatRegex = new RegExp(
                `[${this.formatDelimiter}]{${this.formatDelimiterCount}}\\s*` +
                `(${Object.keys(FormatterTypeEnum).join('|')})_([^${this.formatDelimiter}]+)`,
                'gi'
            );
            let matchResults;
            let gatherFormats: GatherFormats = [];
            while ((matchResults = formatRegex.exec(rawFormat)) != null) {
                const formatTypeMatch = matchResults[1].toLowerCase();
                const formatDetails = matchResults[2];
                if (isFormatterType(formatTypeMatch)) {
                    gatherFormats.push({
                        formatType: formatTypeMatch,
                        formatDetails,
                    });
                } else {
                    console.warn('Invalid format type given', formatTypeMatch);
                }
            }
            if (gatherFormats && gatherFormats.length > 0) {
                this.formats = gatherFormats;
            }
        }
        return this.formats;
    }

    public process(input?: string | string[]): Formatter {
        if (input) {
            this.setData(input);
        }
        for (const tFormat of this.formats) {
            switch (tFormat.formatType) {
            case 'text':
                if (tFormat.formatDetails.toLowerCase() === 'lowercase') {
                    this.text_lowerCase();
                } else if (tFormat.formatDetails.toLowerCase() === 'uppercase') {
                    this.text_upperCase();
                }
                break;
            case 'date':
                this.date_moment();
                break;
            case 'state':
                if (tFormat.formatDetails.toLowerCase() === 'short') {
                    this.state_short();
                } else if (tFormat.formatDetails.toLowerCase() === 'long') {
                    this.state_long();
                }
                break;
            case 'name':
                this.name();
                break;
            case 'if_true':
                this.if_true();
                break;
            case 'if_false':
                this.if_false();
                break;
            case 'if_blank':
                this.if_blank();
                break;
            case 'if_notblank':
                this.if_notblank();
                break;
            case 'equals':
                this.equals();
                break;
            case 'separator':
                this.separator();
                break;
            case 'remove':
                this.remove(tFormat.formatDetails);
                break;

            default:
                // no op for formats like
                // size, value, group, display_override
            }

        }
        return this;
    }

    public separator(): Formatter;
    public separator(separator: string): Formatter;
    public separator(arg: { input: string[] }, separator?: string): Formatter;
    public separator(argOrSeparator?: { input: string[] } | string, separator?: string): Formatter {
        if (isString(argOrSeparator) && !separator) {
            separator = argOrSeparator;
        }
        if (argOrSeparator && !isString(argOrSeparator) && argOrSeparator.input) {
            this.setData(argOrSeparator.input);
        }
        let wantSeparator = ' ';
        if (separator !== null && separator !== undefined && separator !== '') {
            wantSeparator = separator;
        } else {
            const wantSeparatorFormat = ((this.formats || []).filter(ele => ele.formatType === 'separator'))[0];
            wantSeparator = wantSeparatorFormat.formatDetails;
        }
        if (wantSeparator !== null && wantSeparator !== undefined) {
            const spaceRegExp: RegExp = /(SPACE|_)/ig;
            this.separatorString = wantSeparator.replace(spaceRegExp, ' ');
        }
        return this;
    }

    public text_lowerCase(arg?: { input: string[] }): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) { // Do not run on final fill 
            return this;
        }
        this.setData(this.data.map(strEle => strEle.toLowerCase()));
        return this;
    }

    public text_upperCase(arg?: { input: string[] }): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) { // Do not run on final fill 
            return this;
        }
        this.setData(this.data.map(strEle => strEle.toUpperCase()));
        return this;
    }

    public state_short(arg?: { input: string[] }): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) { // Do not run on final fill 
            return this;
        }
        const gatherLocale = new GatherLocales();
        const input = this.data.toString();
        const thisState = (gatherLocale || []).find(input, 'abbr');
        if (thisState && typeof thisState === 'string') {
            this.setData(thisState);
        }
        return this;
    }

    public state_long(arg?: { input: string[] }): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) { // Do not run on final fill 
            return this;
        }
        const gatherLocale = new GatherLocales();
        const input = this.data.toString();
        const thisState = gatherLocale.find(input, 'name');
        if (thisState && typeof thisState === 'string') {
            this.setData(thisState);
        }
        return this;
    }

    public date_moment(arg?: { input?: string[] }, momentFormat?: string): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) { // Do not run on final fill 
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'date'))[0];
        let maybeDateFormat = (momentFormat || !gatherFormat) ? momentFormat : gatherFormat.formatDetails;
        if (!maybeDateFormat) {
            maybeDateFormat = 'MM/DD/YYYY';
        }
        const allowedDateFormats = [
            'M/D/YY',
            'M-D-YY',
            'M/D/YYYY',
            'YYYY-M-D',
            'YYYY/M/D',
            'YYYY-MM-DD HH:mm:ssZ',
            'YYYY-MM-DD HH:mm:ss.SSSZ',
        ];
        const maybeDateString = this.data[0] || '';
        const maybeTimeString = this.data[1] || '';
        const maybeTimezoneString = this.data[2] || '';
        const maybeEndDateString = this.data[3] || '';
        const maybeEndTimeString = this.data[4] || '';
        const maybeEndTimezoneString = this.data[5] || '';
        const dateArray = [];
        let dateRangeSeparator = ' - ';
        let date;
        let endDate;
        if (this.formats) {
            const wantSeparatorFormat = ((this.formats || []).filter(ele => ele.formatType === 'separator'))[0];
            if (wantSeparatorFormat) {
                const wantSeparator = wantSeparatorFormat.formatDetails;
                if (wantSeparator !== null && wantSeparator !== undefined) {
                    const spaceRegExp: RegExp = /(SPACE|_)/ig;
                    dateRangeSeparator = wantSeparator.replace(spaceRegExp, ' ');
                }
            }
        }
        if (maybeTimeString && maybeTimezoneString) {
            // date = momentTz(`${maybeDateString} ${maybeTimeString}`).tz(maybeTimezoneString);
            date = momentTz.tz(`${maybeDateString} ${maybeTimeString}`, maybeTimezoneString);
        } else {
            date = momentTz(maybeDateString, allowedDateFormats, true);
        }
        // Process the End Date if we have one
        if (maybeEndTimeString && maybeEndTimezoneString) {
            endDate = momentTz.tz(`${maybeEndDateString} ${maybeEndTimeString}`, maybeEndTimezoneString);
        } else {
            endDate = momentTz(maybeEndDateString, allowedDateFormats, true);
        }
        if (date.isValid()) {
            dateArray.push(date.format(maybeDateFormat));
            if (endDate.isValid()) {
                dateArray.push(endDate.format(maybeDateFormat));
            }
            this.setData(dateArray.join(dateRangeSeparator));
        }
        return this;
    }
    /*
     *  Name formatter is a prefill (non-FinalFill) formatter ONLY.
     *      As of right now, once we have the front end input field 
     *      to allow input:   NAME _______  _________ _________
     *      then we can move this to a pre and post formatter
     */
    public name(arg?: { input: string[] }, formatRaw?: string): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) { // Do not run on final fill 
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'name'))[0];
        if (!gatherFormat && !formatRaw) { // handle the exception
            console.warn('ERROR Formatter.name(): no valid format data found');
            return this;
        }
        let thisFormat = formatRaw ? formatRaw : gatherFormat.formatDetails;
        const emptyCheck = escapeRegExp(thisFormat.replace(/[_\s ]+/g, ' ')
            .replace(/first/g, '')
            .replace(/middle/g, '')
            .replace(/last/g, '')
            .replace(/suffix/g, '')
        ).replace(/[\s]+/, '\\s+')
        ;
        const emptyCheckRegex = new RegExp('^\\s*' + emptyCheck + '\\s*$' );
        const firstName = this.data[0] ? this.data[0].trim() : '';
        const middleName = this.data[1] ? this.data[1].trim() : '';
        const lastName = this.data[2] ? this.data[2].trim() : '';
        const suffix = this.data[3] ? this.data[3].trim() : '';
        const finalStr = thisFormat.replace(/_/g, ' ')
            .replace(/first/g, firstName)
            .replace(/middle/g, middleName)
            .replace(/last/g, lastName)
            .replace(/suffix/g, suffix)
            .replace(emptyCheckRegex, '').trim()
            ;
        this.setData(finalStr);
        return this;
    }

    public if_true(arg?: { input: string[] }, formatRaw?: FormatterIfValueTypes): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (!this.finalFill) {
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'if_true'))[0];
        if (!gatherFormat && !formatRaw) { // handle the exception
            console.warn('ERROR Formatter.if_true(): no valid format data found');
            return this;
        }
        let thisFormat = formatRaw ? formatRaw : gatherFormat.formatDetails;
        if (this.data.toString().toLowerCase() === 'true') { // This is a string true 
            this.setData(FormatterIfValueTypeEnum[thisFormat]);
        }
        return this;
    }

    public if_false(arg?: { input: string[] }, formatRaw?: FormatterIfValueTypes): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (!this.finalFill) {
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'if_false'))[0];
        if (!gatherFormat && !formatRaw) { // handle the exception
            console.warn('ERROR Formatter.if_false(): no valid format data found');
            return this;
        }
        let thisFormat = formatRaw ? formatRaw : gatherFormat.formatDetails;
        if (this.data.toString().toLowerCase() === 'false') { // This is a string false 
            this.setData(FormatterIfValueTypeEnum[thisFormat]);
        }
        return this;
    }

    public if_blank(arg?: { input: string[] }, formatRaw?: FormatterIfValueTypes): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (!this.finalFill) {
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'if_blank'))[0];
        if (!gatherFormat && !formatRaw) { // handle the exception
            console.warn('ERROR Formatter.if_blank(): no valid format data found');
            return this;
        }
        let thisFormat = formatRaw ? formatRaw : gatherFormat.formatDetails;
        if (this.data.toString() === '') {
            this.setData(FormatterIfValueTypeEnum[thisFormat]);
        }
        return this;
    }

    public if_notblank(arg?: { input: string[] }, formatRaw?: FormatterIfValueTypes): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'if_notblank'))[0];
        if (!gatherFormat && !formatRaw) { // handle the exception
            console.warn('ERROR Formatter.if_notblank(): no valid format data found');
            return this;
        }
        let thisFormat = formatRaw ? formatRaw : gatherFormat.formatDetails;
        if (this.data.toString() !== '') {
            this.setData(FormatterIfValueTypeEnum[thisFormat]);
        }
        return this;
    }

    /**
     * equals - this is an initial data formatter which will force the value to true or false 
     *
     * @param arg 
     * @param formatRaw 
     */
    public equals(arg?: { input: string[] }, formatRaw?: string): Formatter {
        if (arg && arg.input) {
            this.setData(arg.input);
        }
        if (this.finalFill) {
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'equals'))[0];
        if (!gatherFormat && !formatRaw) {
            console.warn('ERROR Formatter.equals(): no valid format search data found');
            return this;
        }
        const thisFormat = formatRaw ? formatRaw : gatherFormat.formatDetails;
        this.setData(includes(this.data.map(x => x.toLowerCase()), thisFormat.toLowerCase()) ? 'true' : 'false');
        return this;
    }

    /**
     * remove - this is an initial data formatter which will remove any data that matches
     *
     * @param arg 
     * @param formatRaw 
     */
    public remove(): Formatter;
    public remove(formatRawOnly: string): Formatter;
    public remove(arg: { input: string[] }, formatRaw?: string): Formatter;
    public remove(argCombined?: { input: string[] } | string, formatRaw?: string): Formatter {
        if (isString(argCombined) && !formatRaw) {
            formatRaw = argCombined;
        }
        if (argCombined && !isString(argCombined) && argCombined.input) {
            this.setData(argCombined.input);
        }
        if (this.finalFill) {
            return this;
        }
        const gatherFormat = ((this.formats || []).filter(ele => ele.formatType === 'remove'));
        if (gatherFormat.length === 0 && !formatRaw) {
            console.warn('ERROR Formatter.remove(): no valid format search data found');
            return this;
        }
        const formatRemovesToProcess: FormatDetail[] = formatRaw ? [{
            formatType: FormatterTypeEnum.remove, formatDetails: formatRaw,
        }]
            : gatherFormat;

        for (const tFormat of formatRemovesToProcess) {
            const thisFormat = tFormat.formatDetails;
            this.setData(
                this.data.filter(x => x.toLowerCase() !== thisFormat.toLowerCase())
            );
        }
        return this;
    }

    private setData(data: string | string[]) {
        if (Array.isArray(data)) {
            this.data = data;
        } else {
            this.data = [data];
        }
        return this;
    }

}