import * as t from 'io-ts';

/**
 * this mirrors the DB values in the app_permission table
 * 
 */

export enum Permission {
    manage_permission = 'MANAGE_PERMISSION',
    remove_other_team_members = 'REMOVE_OTHER_TEAM_MEMBERS',
    invite_other_team_members = 'INVITE_OTHER_TEAM_MEMBERS',
    be_assigned_to_case = 'BE_ASSIGNED_TO_CASE',
    modify_workflows = 'MODIFY_WORKFLOWS',
    view_case_financial_data = 'VIEW_CASE_FINANCIAL_DATA',
    view_case_autoforms = 'VIEW_CASE_AUTOFORMS',
    view_case_vitals = 'VIEW_CASE_VITALS',
    view_monthly_financial_rollup = 'VIEW_MONTHLY_FINANCIAL_ROLLUP',
    access_report_revenue = 'ACCESS_REPORT_REVENUE',
    access_report_payment = 'ACCESS_REPORT_PAYMENT',
    access_report_batch = 'ACCESS_REPORT_BATCH',
    access_report_cases = 'ACCESS_REPORT_CASES',
    access_report_helpers = 'ACCESS_REPORT_HELPERS',
    access_report_rolodex = 'ACCESS_REPORT_ROLODEX',
    access_report_keeptrack = 'ACCESS_REPORT_KEEPTRACK',
    complete_tracking_steps = 'COMPLETE_TRACKING_STEPS',
    skip_tracking_steps = 'SKIP_TRACKING_STEPS',
    edit_tracking_prerequisite = 'EDIT_TRACKING_PREREQUISITE',
    CONTRACT_ITEM_DISCOUNTS = 'CONTRACT_ITEM_DISCOUNTS',
    CONTRACT_MODIFY_ITEM_NAME = 'CONTRACT_MODIFY_ITEM_NAME',
    CONTRACT_DISCOUNTS = 'CONTRACT_DISCOUNTS',
    MODIFY_PRICES = 'MODIFY_PRICES',
    ADD_PAYMENTS = 'ADD_PAYMENTS',
    EDIT_PAYMENTS = 'EDIT_PAYMENTS',
    REMOVE_PAYMENTS = 'REMOVE_PAYMENTS',
    EDIT_GPL = 'EDIT_GPL',
    CONTRACT_UNFREEZE = 'CONTRACT_UNFREEZE',
    CONTRACT_ONE_OFF_ITEMS = 'CONTRACT_ONE_OFF_ITEMS',
    ADD_ITEMS = 'ADD_ITEMS',
    REMOVE_ITEMS = 'REMOVE_ITEMS',
    CASE_LABELS = 'CASE_LABELS',
    COLLECTIONS_REPORT = 'COLLECTIONS_REPORT',
    OVERPAID_CONTRACTS = 'OVERPAID_CONTRACTS',
    DISCOUNTS_REPORT = 'DISCOUNTS_REPORT',
    PRICE_ADJUSTMENTS_REPORT = 'PRICE_ADJUSTMENTS_REPORT',
    MERCH_SALES_REPORT = 'MERCH_SALES_REPORT',
    HELPER_REPORT = 'HELPER_REPORT',
    INFORMANT_REPORT = 'INFORMANT_REPORT',
    PRICE_LIST = 'PRICE_LIST',
    CASE_SUMMARY = 'CASE_SUMMARY',
    WORKFLOW_BY_CASE_REPORT = 'WORKFLOW_BY_CASE_REPORT',
    SALES_TAX_REPORT = 'SALES_TAX_REPORT',
    DISPATCH_REPORT = 'DISPATCH_REPORT',
    PREP_REPORT = 'PREP_REPORT',
    DATA_SOURCE_CASE_NON_FINANCIAL = 'DATA_SOURCE_CASE_NON_FINANCIAL',
    DATA_SOURCE_CASE_FINANCIAL = 'DATA_SOURCE_CASE_FINANCIAL',
    DATA_SOURCE_CASE_VITALS = 'DATA_SOURCE_CASE_VITALS',
    DATA_SOURCE_PAYMENTS = 'DATA_SOURCE_PAYMENTS',
    DATA_SOURCE_EVENTS = 'DATA_SOURCE_EVENTS',
    DATA_SOURCE_HELPERS = 'DATA_SOURCE_HELPERS',
    DATA_SOURCE_INVOICE_LINE_ITEMS = 'DATA_SOURCE_INVOICE_LINE_ITEMS',
    DATA_SOURCE_NOTES = 'DATA_SOURCE_NOTES',
    DATA_SOURCE_TASK_STEP_ACTIVITY = 'DATA_SOURCE_TASK_STEP_ACTIVITY',
    DATA_SOURCE_REMEMBER_PAGE = 'DATA_SOURCE_REMEMBER_PAGE',
    DATA_SOURCE_INSURANCE_POLICIES = 'DATA_SOURCE_INSURANCE_POLICIES',
}

export interface AppPermissionRecord {
    app_permission: Permission;
    recommended_value: boolean;
}

export type AppPermissions = Record<Permission, boolean>;

export const PermissionEnumType = t.keyof({
    [Permission.manage_permission]: t.boolean,
    [Permission.remove_other_team_members]: t.boolean,
    [Permission.invite_other_team_members]: t.boolean,
    [Permission.be_assigned_to_case]: t.boolean,
    [Permission.modify_workflows]: t.boolean,
    [Permission.view_case_financial_data]: t.boolean,
    [Permission.view_case_autoforms]: t.boolean,
    [Permission.view_case_vitals]: t.boolean,
    [Permission.view_monthly_financial_rollup]: t.boolean,
    [Permission.access_report_revenue]: t.boolean,
    [Permission.access_report_payment]: t.boolean,
    [Permission.access_report_batch]: t.boolean,
    [Permission.access_report_cases]: t.boolean,
    [Permission.access_report_helpers]: t.boolean,
    [Permission.access_report_rolodex]: t.boolean,
    [Permission.access_report_keeptrack]: t.boolean,
    [Permission.complete_tracking_steps]: t.boolean,
    [Permission.skip_tracking_steps]: t.boolean,
    [Permission.edit_tracking_prerequisite]: t.boolean,
    [Permission.CONTRACT_ITEM_DISCOUNTS]: t.boolean,
    [Permission.CONTRACT_MODIFY_ITEM_NAME]: t.boolean,
    [Permission.CONTRACT_DISCOUNTS]: t.boolean,
    [Permission.MODIFY_PRICES]: t.boolean,
    [Permission.ADD_PAYMENTS]: t.boolean,
    [Permission.EDIT_PAYMENTS]: t.boolean,
    [Permission.REMOVE_PAYMENTS]: t.boolean,
    [Permission.EDIT_GPL]: t.boolean,
    [Permission.CONTRACT_UNFREEZE]: t.boolean,
    [Permission.CONTRACT_ONE_OFF_ITEMS]: t.boolean,
    [Permission.ADD_ITEMS]: t.boolean,
    [Permission.REMOVE_ITEMS]: t.boolean,
    [Permission.CASE_LABELS]: t.boolean,
    [Permission.COLLECTIONS_REPORT]: t.boolean,
    [Permission.OVERPAID_CONTRACTS]: t.boolean,
    [Permission.DISCOUNTS_REPORT]: t.boolean,
    [Permission.PRICE_ADJUSTMENTS_REPORT]: t.boolean,
    [Permission.MERCH_SALES_REPORT]: t.boolean,
    [Permission.HELPER_REPORT]: t.boolean,
    [Permission.INFORMANT_REPORT]: t.boolean,
    [Permission.PRICE_LIST]: t.boolean,
    [Permission.CASE_SUMMARY]: t.boolean,
    [Permission.WORKFLOW_BY_CASE_REPORT]: t.boolean,
    [Permission.SALES_TAX_REPORT]: t.boolean,
    [Permission.DISPATCH_REPORT]: t.boolean,
    [Permission.PREP_REPORT]: t.boolean,
    [Permission.DATA_SOURCE_CASE_NON_FINANCIAL]: t.boolean,
    [Permission.DATA_SOURCE_CASE_FINANCIAL]: t.boolean,
    [Permission.DATA_SOURCE_CASE_VITALS]: t.boolean,
    [Permission.DATA_SOURCE_PAYMENTS]: t.boolean,
    [Permission.DATA_SOURCE_EVENTS]: t.boolean,
    [Permission.DATA_SOURCE_HELPERS]: t.boolean,
    [Permission.DATA_SOURCE_INVOICE_LINE_ITEMS]: t.boolean,
    [Permission.DATA_SOURCE_NOTES]: t.boolean,
    [Permission.DATA_SOURCE_TASK_STEP_ACTIVITY]: t.boolean,
    [Permission.DATA_SOURCE_REMEMBER_PAGE]: t.boolean,
    [Permission.DATA_SOURCE_INSURANCE_POLICIES]: t.boolean,
});

export const ALL_USER_PERMISSIONS_ON: Record<Permission, true> = {
    [Permission.manage_permission]: true,
    [Permission.remove_other_team_members]: true,
    [Permission.invite_other_team_members]: true,
    [Permission.be_assigned_to_case]: true,
    [Permission.view_case_financial_data]: true,
    [Permission.view_case_autoforms]: true,
    [Permission.view_case_vitals]: true,
    [Permission.view_monthly_financial_rollup]: true,
    [Permission.access_report_revenue]: true,
    [Permission.access_report_payment]: true,
    [Permission.access_report_batch]: true,
    [Permission.access_report_cases]: true,
    [Permission.access_report_helpers]: true,
    [Permission.access_report_rolodex]: true,
    [Permission.access_report_keeptrack]: true,
    [Permission.modify_workflows]: true,
    [Permission.complete_tracking_steps]: true,
    [Permission.skip_tracking_steps]: true,
    [Permission.edit_tracking_prerequisite]: true,
    [Permission.CONTRACT_ITEM_DISCOUNTS]: true,
    [Permission.CONTRACT_MODIFY_ITEM_NAME]: true,
    [Permission.CONTRACT_DISCOUNTS]: true,
    [Permission.MODIFY_PRICES]: true,
    [Permission.ADD_PAYMENTS]: true,
    [Permission.EDIT_PAYMENTS]: true,
    [Permission.REMOVE_PAYMENTS]: true,
    [Permission.EDIT_GPL]: true,
    [Permission.CONTRACT_UNFREEZE]: true,
    [Permission.CONTRACT_ONE_OFF_ITEMS]: true,
    [Permission.ADD_ITEMS]: true,
    [Permission.REMOVE_ITEMS]: true,
    [Permission.CASE_LABELS]: true,
    [Permission.COLLECTIONS_REPORT]: true,
    [Permission.OVERPAID_CONTRACTS]: true,
    [Permission.DISCOUNTS_REPORT]: true,
    [Permission.PRICE_ADJUSTMENTS_REPORT]: true,
    [Permission.MERCH_SALES_REPORT]: true,
    [Permission.HELPER_REPORT]: true,
    [Permission.INFORMANT_REPORT]: true,
    [Permission.PRICE_LIST]: true,
    [Permission.CASE_SUMMARY]: true,
    [Permission.WORKFLOW_BY_CASE_REPORT]: true,
    [Permission.SALES_TAX_REPORT]: true,
    [Permission.DISPATCH_REPORT]: true,
    [Permission.PREP_REPORT]: true,
    [Permission.DATA_SOURCE_CASE_NON_FINANCIAL]: true,
    [Permission.DATA_SOURCE_CASE_FINANCIAL]: true,
    [Permission.DATA_SOURCE_CASE_VITALS]: true,
    [Permission.DATA_SOURCE_PAYMENTS]: true,
    [Permission.DATA_SOURCE_EVENTS]: true,
    [Permission.DATA_SOURCE_HELPERS]: true,
    [Permission.DATA_SOURCE_INVOICE_LINE_ITEMS]: true,
    [Permission.DATA_SOURCE_NOTES]: true,
    [Permission.DATA_SOURCE_TASK_STEP_ACTIVITY]: true,
    [Permission.DATA_SOURCE_REMEMBER_PAGE]: true,
    [Permission.DATA_SOURCE_INSURANCE_POLICIES]: true,
};

export const ALL_USER_PERMISSIONS_OFF: Record<Permission, false> = {
    [Permission.manage_permission]: false,
    [Permission.remove_other_team_members]: false,
    [Permission.invite_other_team_members]: false,
    [Permission.be_assigned_to_case]: false,
    [Permission.view_case_financial_data]: false,
    [Permission.view_case_autoforms]: false,
    [Permission.view_case_vitals]: false,
    [Permission.view_monthly_financial_rollup]: false,
    [Permission.access_report_revenue]: false,
    [Permission.access_report_payment]: false,
    [Permission.access_report_batch]: false,
    [Permission.access_report_cases]: false,
    [Permission.access_report_helpers]: false,
    [Permission.access_report_rolodex]: false,
    [Permission.access_report_keeptrack]: false,
    [Permission.modify_workflows]: false,
    [Permission.complete_tracking_steps]: false,
    [Permission.skip_tracking_steps]: false,
    [Permission.edit_tracking_prerequisite]: false,
    [Permission.CONTRACT_ITEM_DISCOUNTS]: false,
    [Permission.CONTRACT_MODIFY_ITEM_NAME]: false,
    [Permission.CONTRACT_DISCOUNTS]: false,
    [Permission.MODIFY_PRICES]: false,
    [Permission.ADD_PAYMENTS]: false,
    [Permission.EDIT_PAYMENTS]: false,
    [Permission.REMOVE_PAYMENTS]: false,
    [Permission.EDIT_GPL]: false,
    [Permission.CONTRACT_UNFREEZE]: false,
    [Permission.CONTRACT_ONE_OFF_ITEMS]: false,
    [Permission.ADD_ITEMS]: false,
    [Permission.REMOVE_ITEMS]: false,
    [Permission.CASE_LABELS]: false,
    [Permission.COLLECTIONS_REPORT]: false,
    [Permission.OVERPAID_CONTRACTS]: false,
    [Permission.DISCOUNTS_REPORT]: false,
    [Permission.PRICE_ADJUSTMENTS_REPORT]: false,
    [Permission.MERCH_SALES_REPORT]: false,
    [Permission.HELPER_REPORT]: false,
    [Permission.INFORMANT_REPORT]: false,
    [Permission.PRICE_LIST]: false,
    [Permission.CASE_SUMMARY]: false,
    [Permission.WORKFLOW_BY_CASE_REPORT]: false,
    [Permission.SALES_TAX_REPORT]: false,
    [Permission.DISPATCH_REPORT]: false,
    [Permission.PREP_REPORT]: false,
    [Permission.DATA_SOURCE_CASE_NON_FINANCIAL]: false,
    [Permission.DATA_SOURCE_CASE_FINANCIAL]: false,
    [Permission.DATA_SOURCE_CASE_VITALS]: false,
    [Permission.DATA_SOURCE_PAYMENTS]: false,
    [Permission.DATA_SOURCE_EVENTS]: false,
    [Permission.DATA_SOURCE_HELPERS]: false,
    [Permission.DATA_SOURCE_INVOICE_LINE_ITEMS]: false,
    [Permission.DATA_SOURCE_NOTES]: false,
    [Permission.DATA_SOURCE_TASK_STEP_ACTIVITY]: false,
    [Permission.DATA_SOURCE_REMEMBER_PAGE]: false,
    [Permission.DATA_SOURCE_INSURANCE_POLICIES]: false,
};
