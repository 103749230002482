import { getFromAPI, postToAPI, patchAPI, deleteFromAPI } from ".";
import { log } from "../logger";
import {
    CaseBelongingCreateRequest,
    CaseBelongingUpdateRequest,
    CaseBelongingUX,
    S3FileCreateRequest,
    UploadFileRequest
} from "../shared/types";
import { AppDispatch } from "../store";
import { registerAppError } from "./errors";
import { uploadS3FileToS3 } from "./S3File.action";

export const CASE_BELONGINGS_LOADED = 'CASE_BELONGING_LOADED';
interface CaseBelongingsLoaded {
    type: typeof CASE_BELONGINGS_LOADED;
    belongings: CaseBelongingUX[];
}

function caseBelongingsLoaded(belongings: CaseBelongingUX[]): CaseBelongingsLoaded {
    return {
        type: CASE_BELONGINGS_LOADED,
        belongings
    };
}

export const CASE_BELONGING_CREATED = 'CASE_BELONGING_CREATED';
interface CaseBelongingCreated {
    type: typeof CASE_BELONGING_CREATED;
    newBelonging: CaseBelongingUX;
}

function caseBelongingCreated(newBelonging: CaseBelongingUX): CaseBelongingCreated {
    return {
        type: CASE_BELONGING_CREATED,
        newBelonging
    };
}

export const CASE_BELONGING_UPDATED = 'CASE_BELONGING_UPDATED';
interface CaseBelongingUpdated {
    type: typeof CASE_BELONGING_UPDATED;
    updatedBelonging: CaseBelongingUX;
}

function caseBelongingUpdated(updatedBelonging: CaseBelongingUX): CaseBelongingUpdated {
    return {
        type: CASE_BELONGING_UPDATED,
        updatedBelonging
    };
}

export const CASE_BELONGING_DELETED = 'CASE_BELONGING_DELETED';
interface CaseBelongingDeleted {
    type: typeof CASE_BELONGING_DELETED;
    deletedBelonging: CaseBelongingUX;
}

function caseBelongingDeleted(deletedBelonging: CaseBelongingUX): CaseBelongingDeleted {
    return {
        type: CASE_BELONGING_DELETED,
        deletedBelonging
    };
}

export function loadBelongings(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<CaseBelongingUX[]> => {
        const response = await getFromAPI<{ belongings: CaseBelongingUX[] }>(
            `api/case/${caseUuid}/belonging`,
            dispatch
        );

        if (response === null) {
            dispatch(caseBelongingsLoaded([]));
            return [];
        }

        const { belongings } = response;

        dispatch(caseBelongingsLoaded(belongings));
        return belongings;
    };
}

export function uploadBelongingPhoto(caseUuid: string, uploadRequest: UploadFileRequest) {
    return async (dispatch: AppDispatch): Promise<S3FileCreateRequest | null> => {
        const { hash, suffix } = uploadRequest;
        if (!suffix) {
            dispatch(registerAppError('Invalid file type'));
            return null;
        }
        const belongingPhotoUrl = `api/case/${caseUuid}/belonging/uploadurl/${hash}/${suffix}`;
        const s3FileData = await dispatch(
            uploadS3FileToS3({ uploadRequest, preSignURLRoute: belongingPhotoUrl })
        );

        if (!s3FileData) {
            dispatch(registerAppError('Problem uploading belonging photo'));
            return null;
        }

        return s3FileData;
    };
}

export function createBelonging(params: {
    caseUuid: string;
    createRequest: CaseBelongingCreateRequest;
}) {
    return async (dispatch: AppDispatch): Promise<CaseBelongingUX | null> => {
        const { caseUuid, createRequest } = params;

        try {
            CaseBelongingCreateRequest.fromRequest(createRequest);
        } catch (error) {
            log.warn('Failed to validate CaseBelongingCreateRequest', { createRequest, error });
            return null;
        }

        const newBelonging = await postToAPI<{ belonging: CaseBelongingUX }>(
            `api/case/${caseUuid}/belonging`,
            createRequest,
            dispatch
        );

        if (newBelonging === null) {
            dispatch(registerAppError('Failed to create belonging!'));
            return null;
        }

        const { belonging } = newBelonging;

        dispatch(caseBelongingCreated(belonging));
        return belonging;
    };
}

export function updateBelonging(params: {
    caseUuid: string;
    belongingId: number;
    updateRequest: CaseBelongingUpdateRequest;
}) {
    return async (dispatch: AppDispatch): Promise<CaseBelongingUX | null> => {
        const { updateRequest, caseUuid, belongingId } = params;

        try {
            CaseBelongingUpdateRequest.fromRequest(updateRequest);
        } catch (error) {
            log.warn('Failed to validate CaseBelongingUpdateRequest', { updateRequest, error });
            return null;
        }

        const updatedBelonging = await patchAPI<{ belonging: CaseBelongingUX }>(
            `api/case/${caseUuid}/belonging/${belongingId}`,
            updateRequest,
            dispatch
        );

        if (updatedBelonging === null) {
            dispatch(registerAppError('Failed to update case belonging!'));
            return null;
        }

        const { belonging } = updatedBelonging;

        dispatch(caseBelongingUpdated(belonging));
        return belonging;
    };
}

export function deleteBelonging(caseUuid: string, belongingId: number) {
    return async (dispatch: AppDispatch): Promise<CaseBelongingUX | null> => {
        const deletedBelonging = await deleteFromAPI<{ belonging: CaseBelongingUX }>(
            `api/case/${caseUuid}/belonging/${belongingId}`,
            dispatch
        );

        if (deletedBelonging === null) {
            dispatch(registerAppError('Failed to delete belonging!'));
            return null;
        }

        const { belonging } = deletedBelonging;

        dispatch(caseBelongingDeleted(belonging));
        return belonging;
    };
}

export type BelongingAction = CaseBelongingsLoaded
    | CaseBelongingCreated
    | CaseBelongingUpdated
    | CaseBelongingDeleted;
