import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import GSwitch from '../common/inputElements/GSwitch';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 12,
        maxHeight: 12,
        '& label': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            fontSize: 12
        }
    },
    gSwitch: {
        width: 32,
        margin: '0 8px',
    },
});

interface Props {
    isViewAsGOM: boolean;
    handleViewAsGOMChange: (isViewAsGOM: boolean) => void;
}

interface State {
}

type StyledProps = Props & WithStyles<'root' | 'switchContainer' | 'gSwitch'>;

class ChangeViewGSwitch extends Component<StyledProps, State> {
    state = {};

    render() {
        const { classes, isViewAsGOM, handleViewAsGOMChange } = this.props;

        return (
            <Grid item xs={12} className={classNames(classes.switchContainer)}>
                <label htmlFor="task-view-switch">Funeral Home View</label>
                <div className={classes.gSwitch}>
                    <GSwitch
                        checked={isViewAsGOM}
                        onChangeCallBack={(ischecked) => handleViewAsGOMChange(ischecked)}
                        id="task-view-switch"
                    />
                </div>
                <label htmlFor="task-view-switch">Gather Admin View</label>
            </Grid>
        );
    }

}

export default withGStyles(styles)(ChangeViewGSwitch);
