import { WithStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import { RolodexContactResult } from '../../../shared/types';
import { RowClickHandlerFunc } from './utils';
import Divider from '@mui/material/Divider';

type StyleProps = WithStyles<
    'firstItem'
    | 'heading'
    | 'subHeading'
    | 'profession'
    | 'location'
    | 'tabContainerDivider'
>;

type ContactRowProps = {
    contact: RolodexContactResult;
    rowClickHandler: RowClickHandlerFunc;
} & StyleProps;

const ContactRow = (props: ContactRowProps) => {
    const { contact, classes, rowClickHandler } = props;
    const contactHeading = `${contact.title !== null ? contact.title + ' ' : ''}${contact.name || ''}`;
    return (
        <>
            <Grid item xs={12} className={classes.firstItem} onClick={() => rowClickHandler(contact)}>
                <Typography color="primary" className={classes.heading}>
                    <AssignmentIndIcon color="primary" />&nbsp;{contactHeading}
                </Typography>
                <Grid item xs={12} className={classes.subHeading}>
                    {contact.role !== null &&
                        <Typography color="secondary" className={classes.profession}>
                            {contact.role}
                        </Typography>}
                    {(contact.orgName !== null && Boolean(contact.orgName.length)) &&
                        <Typography color="secondary" className={classes.location}>
                            <BusinessIcon color="secondary" />&nbsp;{contact.orgName}
                        </Typography>}
                </Grid>
            </Grid>
            <Divider color="secondary" className={classes.tabContainerDivider} />
        </>
    );
};

export default ContactRow;
