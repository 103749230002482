import * as React from 'react';
import classNames from 'classnames';

import InputAdornment from '@mui/material/InputAdornment';
import { InputLabelProps } from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';
import makeStyles from '@mui/styles/makeStyles';
import { InputBaseComponentProps } from '@mui/material';

export interface Props {
    id: string;
    label: string;
    value: string;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    rootClass?: string;
    labelClass?: string;
    textFieldClass?: string;
    pattern?: string;
    endAdornment?: JSX.Element;
    inputType?: string;
    placeholder?: string;
    multiline?: TextFieldProps['multiline'];
    minRows?: TextFieldProps['minRows'];
    helperText?: TextFieldProps['helperText'];
    variant?: TextFieldProps['variant'];
    inputLabelProps?: Partial<InputLabelProps>;
    inputComponent?: React.ElementType<InputBaseComponentProps>;
    onBlur?: TextFieldProps['onBlur'];
    onFocus?: TextFieldProps['onFocus'];
    onChange: (value: string) => void;
    handleOpenSnackbar?: () => void;
    isHiddenFromFamily?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'left',
        marginTop: 20,
    },
    width100: {
        width: '100%',
    },
}), { name: 'DCTextField' });

const DCTextField = (props: Props) => {

    const {
        id,
        error,
        disabled,
        required,
        value,
        label,
        rootClass,
        textFieldClass,
        labelClass,
        pattern,
        endAdornment,
        inputType,
        placeholder,
        multiline,
        minRows,
        helperText,
        inputLabelProps,
        variant,
        inputComponent,
        onBlur,
        onFocus,
        onChange,
        handleOpenSnackbar,
        isHiddenFromFamily
    } = props;

    const classes = useStyles();

    return (
        <FormControl
            className={classNames(classes.root, rootClass)}
            required={required}
            error={error}
        >
            <TextField
                id={id}
                label={label}
                required={required}
                error={error}
                value={value}
                type={inputType}
                onFocus={onFocus}
                className={classNames(classes.width100, textFieldClass)}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                autoComplete="new-password"
                disabled={disabled}
                onClick={handleOpenSnackbar}
                InputLabelProps={{
                    ...inputLabelProps,
                    classes: {
                        root: labelClass,
                    }
                }}
                inputProps={{ pattern }}
                InputProps={{
                    inputComponent,
                    endAdornment: (
                        <InputAdornment position="end">
                            {endAdornment}
                            <HiddenFromFamilyAdornment showMe={isHiddenFromFamily} />
                        </InputAdornment>
                    )
                }}
                placeholder={placeholder}
                multiline={multiline}
                minRows={minRows}
                helperText={helperText}
                variant={variant}
            />
        </FormControl>
    );
};

export default DCTextField;
