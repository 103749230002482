import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles/withStyles';
import { GLOBAL_STYLED_PROPS } from '.';
import { GLOBAL_STYLES } from './makeGStyles';

export type WithGStyles<T extends string> = GLOBAL_STYLED_PROPS & WithStyles<T>;

/**
 * @deprecated
 * use styled component for styling or `GStyles` for applying common styles  
 */
const withGStyles = <Props extends {}>(...customStyles: StyleRulesCallback<Theme, Props>[]) => {
    const combinedStylesCallback: StyleRulesCallback<Theme, Props> = (theme) => {
        return {
            ...customStyles.reduce((acc, customStyle) => ({ ...acc, ...customStyle(theme) }), {}),
            ...GLOBAL_STYLES<Props>(theme)
        };
    };

    return withStyles(combinedStylesCallback);
};

export default withGStyles;