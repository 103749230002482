import { Component } from 'react';
import classNames from 'classnames';
import * as appService from '../../services/app.service';

import YouTube from 'react-youtube';

import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Clear from '@mui/icons-material/Clear';

import { setElementStyleById } from '../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { ZoomTransition } from './Transitions';

interface YouTubeOptions {
    height?: string;
    width?: string;
    playerVars?: {
        autoplay?: 0 | 1;
        cc_load_policy?: 1;
        color?: 'red' | 'white';
        controls?: 0 | 1 | 2;
        disablekb?: 0 | 1;
        enablejsapi?: 0 | 1;
        end?: number;
        fs?: 0 | 1;
        hl?: string;
        iv_load_policy?: 1 | 3;
        list?: string;
        listType?: 'playlist' | 'search' | 'user_uploads';
        loop?: 0 | 1;
        modestbranding?: 1;
        origin?: string;
        playlist?: string;
        playsinline?: 0 | 1;
        rel?: 0 | 1;
        showinfo?: 0 | 1;
        start?: number;
    };
}

export interface YouTubeProps {
    pauseVideo: () => void;
    playVideo: () => void;
    stopVideo: () => void;
}

export interface Props {
    isDialogOpen: boolean;
    videoId: string;
    isVideoAvailable: boolean;
    title?: string;
    closeDialog: () => void;
    zIndex: number;
}

export interface State { }

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            margin: '3%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
            }
        },
    },
    dialogPaper: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    dialogHeader: {
        zIndex: 1,
        padding: 14,
    },
    youtubePlayerContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: 30,
        height: 0,
        margin: 0,
        overflow: 'hidden',
    },
    youtubeIframeContainer: {
        position: 'absolute !important' as 'absolute',
        top: '0px !important',
        left: '0px !important',
        width: '100% !important',
        height: '100% !important',
    },
    noVideoText: {
        fontWeight: 200,
        margin: 5,
    },
    dialogContent: {
        margin: 3,
        padding: 0,
    }
});

type StyledProps = Props & WithGStyles<'root' | 'clearIcon' | 'dialogHeader' | 'dialogPaper' | 'youtubePlayerContainer'
    | 'youtubeIframeContainer' | 'noVideoText' | 'dialogContent'>;

class YoutubePlayer extends Component<StyledProps, State> {
    protected youTubePlayer: YouTubeProps;

    componentDidUpdate(props: Props) {
        const { isDialogOpen } = this.props;
        if (isDialogOpen && !props.isDialogOpen) {
            this.appStyling(true);
        }
    }

    onReady = (event: { target: YouTubeProps }) => {
        this.youTubePlayer = event.target;
    };

    closeDialog = () => {
        if (this.youTubePlayer) {
            this.youTubePlayer.stopVideo();
        }
        this.props.closeDialog();
        this.appStyling(false);
    };

    /**
     * Forcfully styling app drawer and header appbar to avoid app component floating on full screen mode
     */
    appStyling = (isOpened: boolean) => {
        const appBarId = 'common-appbar';
        const drawerId = 'common-drawer';
        const swipeableDrawerId = 'common-drawer-swipeable';
        if (isOpened) {
            setElementStyleById(appBarId, 'z-index', '-1');
            setElementStyleById(drawerId, 'z-index', '-2');
            setElementStyleById(swipeableDrawerId, 'z-index', '-2');
            return;
        }
        setElementStyleById(appBarId, 'z-index', '1310');
        setElementStyleById(drawerId, 'z-index', '1200');
        setElementStyleById(swipeableDrawerId, 'z-index', '1200');
    };

    render() {
        const { classes, isDialogOpen, videoId, isVideoAvailable, title, zIndex } = this.props;

        const youtubeOptions: YouTubeOptions = {
            height: '100%',
            width: '100%',
            playerVars: {
                autoplay: 0,
                showinfo: 0,
                rel: 0,
            }
        };

        if (isDialogOpen && this.youTubePlayer) {
            this.youTubePlayer.playVideo();
        }

        return (
            <Dialog
                fullScreen={false}
                open={isDialogOpen}
                TransitionComponent={ZoomTransition}
                transitionDuration={300}
                onClose={this.closeDialog}
                aria-labelledby="youtube-player-title"
                aria-describedby="youtube-player-description"
                className={classes.root}
                keepMounted
                classes={{
                    paper: classes.dialogPaper,
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="youtube-player-title"
                    className={classes.dialogHeader}
                >
                    <Clear
                        color="secondary"
                        className={classes.clearIcon}
                        onClick={this.closeDialog}
                    />
                    <Typography
                        color="secondary"
                        component="p"
                        className={classes.fontSize20}
                        align="left"
                    >
                        <span className={classes.textCapitalize}>{title || 'How It Works...'}</span>
                    </Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <div className={classes.youtubePlayerContainer}>
                        <YouTube
                            className={classes.youtubeIframeContainer}
                            videoId={videoId}
                            opts={youtubeOptions}
                            onReady={this.onReady}
                        />
                    </div>
                    {!isVideoAvailable && <Typography
                        color="secondary"
                        component="p"
                        className={classes.noVideoText}
                        align="center"
                    >
                        Your custom explainer video has not been loaded. Please&nbsp;
                        <span
                            className={classNames(classes.fontWeight500, classes.cursorPointer)}
                            onClick={event => appService.showIntercom()}
                        >
                            click here&nbsp;
                        </span>
                        to contact Gather to learn more.
                    </Typography>}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withGStyles(styles)(YoutubePlayer);
