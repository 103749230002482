import { FocusEventHandler, MouseEventHandler, useState } from "react";

interface Events<T extends HTMLElement = HTMLElement> {
    onClick: MouseEventHandler<T>;
    onMouseEnter: MouseEventHandler<T>;
    onMouseLeave: MouseEventHandler<T>;
    onFocus: FocusEventHandler<T>;
    onBlur: FocusEventHandler<T>;
}

export const usePopperState = <T extends HTMLElement = HTMLElement>(): [T | null, Events<T>, VoidFunction] => {
    const [element, setElement] = useState<T | null>(null);

    const events: Events<T> = {
        onClick: (evt) => setElement(evt.currentTarget),
        onFocus: (evt) => setElement(evt.currentTarget),
        onBlur: (evt) => setElement(evt.currentTarget),
        onMouseEnter: (evt) => setElement(evt.currentTarget),
        onMouseLeave: (evt) => setElement(evt.currentTarget),
    };

    return [element, events, () => setElement(null)];
};