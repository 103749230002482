/* eslint-disable max-len */
import { Component } from 'react';

export default class TextSmsSVG extends Component<{ color: string }> {
    render() {
        const { color } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 225.000000 225.000000" preserveAspectRatio="xMidYMid meet" style={{ fill: color }}>
                <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M915 2094 c-240 -36 -460 -140 -614 -288 -122 -119 -186 -227 -215 -369 -20 -96 -20 -148 0 -245 33 -165 139 -324 291 -436 45 -34 82 -66 82 -72 1 -15 -81 -180 -122 -244 -43 -66 -39 -71 68 -78 113 -7 222 32 370 133 l68 47 74 -11 c107 -16 335 -14 439 4 496 87 848 435 820 813 -23 313 -269 579 -646 700 -184 59 -418 76 -615 46z" />
                </g>
            </svg>
        );
    }
}