// NOTE: This component should never be used directly! Use GNavigate or GNavigateWithString instead!
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { log } from '../../logger';
import { AppRoute, buildPathFromRoute, buildURLString, isAbsoluteURL, PathType } from "../../services";

export interface RealGNavigateProps {
    to: string | AppRoute; // Should always use AppRoute if we can
    replace: boolean;
}

function RealGNavigate(props: RealGNavigateProps) {
    const { to } = props;

    let path: string | null = null;
    if (typeof to === 'string') {
        path = to;
    } else {
        const _path = buildPathFromRoute(to);
        switch (_path.type) {
            case PathType.DISABLED:
                path = null;
                break;
            case PathType.ABSOLUTE:
            case PathType.RELATIVE:
                path = buildURLString(_path.urlParts);
                break;
            default:
                path = null;
                log.warn('Invalid PathType', { _path, to });
        }
    }

    useEffect(() => {
        if (path && isAbsoluteURL(path)) {
            window.location.href = path;
        }
    });

    // If this is an absolute path, then we'll just redirect
    if (!path || isAbsoluteURL(path)) {
        return null;
    } else {
        return <Navigate to={path} replace={props.replace} />;
    }
}

export default RealGNavigate;
