import { useState } from "react";
import { useGDispatch } from '../../../../../types';
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import {
    FormControl,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { styled } from "@mui/styles";
import { ProductBulkAction, ProductTaxRateUX, ProductUX } from '../../../../../shared/types';
import { useGSelector } from '../../../../../types';
import { values } from 'lodash';
import { useBulkEditStyles } from "./BulkEditStyles";
import BulkEditBaseContainer from './BulkEditBaseContainer';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';

const TaxRatesMenu = styled(MenuItem)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: '8px',
});

interface EditTaxRateProps {
    selectButton: BulkEditButtonType | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    zIndex: number;
    funeralHomeId: number | null;
}

const EditTaxRate = (props: EditTaxRateProps) => {
    const { selectButton, selectedProductIds, onSubmitClick, zIndex, funeralHomeId } = props;
    const availableTaxRates = useGSelector(({ productState }) => productState.taxRates);
    const classes = useBulkEditStyles();
    const dispatch = useGDispatch();

    const [selectedTaxRateId, setSelectedTaxRateId] = useState(-1);
    const [isSpinning, setIsSpinning] = useState(false);

    const handleTaxRateChange = (value: number) => {
        setSelectedTaxRateId(value);
    };

    const handleSubmitclick = async () => {
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.edit_tax_rate,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: { tax_rate_id: selectedTaxRateId },
        }));
        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <BulkEditBaseContainer
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            onSubmitClick={handleSubmitclick}
            disableSubmitButton={!selectedTaxRateId}
            isSpinning={isSpinning}
        >
            <FormControl className={classes.selectMenuForm}>
                <Select
                    value={selectedTaxRateId}
                    onChange={(e) => handleTaxRateChange(Number(e.target.value))}
                    MenuProps={{ style: { zIndex: zIndex + 1 } }}
                    inputProps={{
                        name: 'taxratesListOfOptions',
                        id: 'taxratesListOfOptions',
                    }}
                    classes={{ select: classes.selectMenu }}
                >
                    <TaxRatesMenu key="-1" value="-1">
                        <Typography>None</Typography>
                    </TaxRatesMenu>
                    {(values(availableTaxRates)).map((c: ProductTaxRateUX) => (
                        <TaxRatesMenu key={c.id} value={c.id}>
                            <Typography>{c.name}</Typography>
                            <Typography color="secondary">({c.description})</Typography>
                        </TaxRatesMenu>
                    ))}
                </Select>
            </FormControl>
        </BulkEditBaseContainer>
    );
};

export default EditTaxRate;