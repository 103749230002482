import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';
import { memoize } from 'lodash';

import { convertHexToRGBA, EventDateMap, groupEventsByDay } from '../../../services';
import { GLOBAL_STYLED_PROPS } from '../../../styles';
import {
    GatherCasePreview,
    FuneralHomeUX,
    GatherCaseUX,
} from '../../../shared/types';
import { StoreState } from '../../../types';
import ScrollControls from '../../common/ScrollControls';
import DateWiseEvent from './dateWiseEvent/DateWiseEvent';
import { DateTimeFormat } from '../../../shared/utils';
import PendingTaskList from './pendingTaskList/PendingTaskList';
import { AppDispatch } from '../../../store';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';

const TASK_CARD_WIDTH = 380;

function mapStateToProps(
    { eventState, locationState }: StoreState,
    ownProps: OwnProps,
) {
    const { funeralHome } = ownProps;

    return {
        googleEvents: eventState.googleEvents,
        gatherEvents: eventState.gatherEvents,
        isEventLoading: eventState.isLoading,
        locations: locationState.locations,
        isGCalConnected: Boolean(funeralHome && funeralHome.has_google_calendar_creds
            && funeralHome.google_calendar_id),
    };
}

interface OwnProps {
    scrollContents: boolean;
    isOpen: boolean;
    isFHorGOMOnAnyFH: boolean;
    funeralHome: FuneralHomeUX | null;
    activeCase: GatherCaseUX | null;
    gatherCases: GatherCasePreview[];
    zIndex: number;
    selectedTeamMemberUserId: number | null;
    openPickTeamMember: (e: React.MouseEvent<HTMLElement>) => void;
}
interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface State {
    eventDateMap: EventDateMap | null;
}

interface DateData {
    id: number;
    value: momentTz.Moment;
    description: string;
    dayString: string; // days away
    date: string; // YYYY-MM-DD
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        height: 'calc(100% - 100px)',
        width: '100%',
        position: 'relative',
    },
    listContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflowX: 'auto',
        display: 'flex',
        margin: 0,
        padding: '20px 0px',
        boxSizing: 'border-box',
        '-webkit-overflow-scrolling': 'touch',
        maxHeight: 'inherit'
    },
    backgroundSection: {
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.18)
    },
    displayFlex: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    navigationContainer: {
        bottom: 0,
        top: 0,
        position: 'absolute',
        background: 'none',
        '& button': {
            boxShadow: 'none'
        }
    },
    leftButton: {
        background: 'none',
        '& button': {
            marginLeft: 0,
            maxWidth: 32,
            height: 180,
            borderRadius: '0px 12px 12px 0px',
            background: `${convertHexToRGBA(theme.palette.primary.main, 0.18)} !important`,
            '& svg': {
                color: `${theme.palette.common.white} !important`,
                fontSize: 36
            },
            '&:hover': {
                background: `${convertHexToRGBA(theme.palette.primary.main, 0.60)} !important`
            }
        },

        '@media only screen and (max-device-width: 1023px) and (orientation : landscape)': {
            background: 'none',
            '& button': {
                display: 'none'
            }
        },
        '@media (min-width: 1300px)': {
            left: TASK_CARD_WIDTH
        },
    },
    rightButton: {
        background: 'none',
        '& button': {
            marginRight: 0,
            maxWidth: 32,
            height: 180,
            borderRadius: '12px 0px 0px 12px',
            color: theme.palette.common.white,
            background: `${convertHexToRGBA(theme.palette.primary.main, 0.18)} !important`,
            '& svg': {
                color: `${theme.palette.common.white} !important`,
                fontSize: 36
            },
            '&:hover': {
                background: `${convertHexToRGBA(theme.palette.primary.main, 0.60)} !important`
            }
        },
        '@media only screen and (max-device-width: 1023px) and (orientation : landscape)': {
            background: 'none',
            '& button': {
                display: 'none'
            }
        }
    },
    height100: {
        height: '100%'
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.secondary.main}`,
    },
    borderTop: {
        color: theme.palette.secondary.main,
        borderTop: '1px solid',
    },
    displayAtLeftPosition: {
        display: 'none',
        '@media (min-width: 1300px)': {
            display: 'block',
            height: '100%',
            boxShadow: `3px 0px 5px -1px rgb(0 0 0 / 20%),
            5px 0px 8px 0px rgb(0 0 0 / 14%),
           1px 0px 14px 0px rgb(0 0 0 / 12%)`
        },
    },
    taskSectionAtWidth: {
        maxWidth: TASK_CARD_WIDTH,
        height: '100%',
        position: 'relative',
        display: 'none',
        '@media (min-width:1300px)': {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1
        }
    },
    taskColorPrimary: {
        background: theme.palette.primary.main,
        '& p': {
            color: theme.palette.common.white
        }
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS
    & WithStyles<'root' | 'listContainer' | 'borderTop' | 'height100' | 'taskColorPrimary' | 'backgroundSection'
        | 'borderRight' | 'displayFlex' | 'displayAtLeftPosition' | 'navigationContainer' | 'rightButton'
        | 'leftButton' | 'taskSectionAtWidth'>;

const PREV_DATE_COUNT = 5;
const FWD_DATE_COUNT = 90;
const generateDates = memoize((dayOfYear: number): DateData[] => {
    let dateArray: DateData[] = [];
    let dateIter = momentTz().subtract(PREV_DATE_COUNT, 'days');
    for (let i = -PREV_DATE_COUNT; i < FWD_DATE_COUNT + 1; i++) {
        let dayString = '';
        if (i < 0) {
            dayString = i === -1 ? 'Yesterday' : `${-i} days ago`;
        } else {
            dayString = i === 0 ? 'Today' : i === 1 ? 'Tomorrow' : `in ${i} days`;
        }
        dateArray.push({
            value: dateIter.clone(),
            id: i,
            description: dateIter.format('ddd, DD MMM').toUpperCase(),
            dayString,
            date: dateIter.format(DateTimeFormat.ShortDate),
        });
        dateIter.add(1, 'days');
    }
    return dateArray;
});

class CaseEvents extends React.Component<StyledProps, State> {
    state: State = {
        eventDateMap: null,
    };

    private listRef = React.createRef<HTMLUListElement>();
    private todayRef = React.createRef<HTMLDivElement>();

    componentDidMount = () => {

        if (this.todayRef.current !== null) {
            this.readTodayWidth(this.todayRef.current);
        }
    };

    componentDidUpdate(prevProps: StyledProps) {
        const { gatherEvents, googleEvents } = this.props;
        const { eventDateMap } = this.state;
        const {
            gatherEvents: prevGatherEvents,
            googleEvents: prevGoogleEvents,
        } = prevProps;
        if (!eventDateMap || (gatherEvents !== prevGatherEvents && googleEvents !== prevGoogleEvents)) {
            const newMap = groupEventsByDay([...gatherEvents, ...googleEvents]);
            this.setState({ eventDateMap: newMap });
        }
    }

    readTodayWidth = (todayCardElement: HTMLDivElement) => {
        const cardLeft = todayCardElement.offsetLeft;
        const cardWidth = todayCardElement.offsetWidth;
        const ref = this.listRef.current;

        if (!ref) {
            return;
        }

        ref.scrollBy({ left: cardLeft - cardWidth, top: 0 });
        ref.scrollBy({ left: cardWidth - 32, top: 0, behavior: 'auto' });
    };

    renderEvents = () => {
        const {
            classes,
            gatherCases,
            isEventLoading,
            zIndex,
            isGCalConnected,
            selectedTeamMemberUserId,
            openPickTeamMember,
        } = this.props;
        const { eventDateMap } = this.state;

        const DATES = generateDates(momentTz().dayOfYear());

        return (
            <ul
                ref={this.listRef}
                className={classNames(
                    classes.listContainer,
                    classes.colorPrimary,
                    classes.backgroundSection
                )}

            >
                {DATES.map((dateData: DateData) =>
                    <DateWiseEvent
                        key={dateData.id}
                        eventDateMap={eventDateMap}
                        cases={gatherCases}
                        zIndex={zIndex}
                        dateData={dateData}
                        isLoading={isEventLoading}
                        isGCalConnected={isGCalConnected}
                        todayCardRef={this.todayRef}
                        selectedTeamMemberUserId={selectedTeamMemberUserId}
                        openPickTeamMember={openPickTeamMember}
                    />
                )}
            </ul >
        );
    };

    render() {
        const {
            classes,
            isFHorGOMOnAnyFH,
            openPickTeamMember,
            selectedTeamMemberUserId,
            zIndex,
        } = this.props;

        if (!isFHorGOMOnAnyFH) {
            return null;
        }

        return (
            <div className={classNames(
                classes.displayFlex,
                classes.height100,
                classes.overflowHidden,
                classes.root
            )}>
                <Grid
                    xs={12}
                    item
                    className={classNames(
                        classes.taskColorPrimary,
                        classes.taskSectionAtWidth,
                        classes.displayAtLeftPosition,
                    )}
                >
                    {/* This is only shown on large screens (1300px+). See DateWiseEvent for small screens */}
                    <PendingTaskList
                        selectedTeamMemberUserId={selectedTeamMemberUserId}
                        openPickTeamMember={openPickTeamMember}
                        zIndex={zIndex}
                    />
                </Grid>

                {this.renderEvents()}

                <ScrollControls
                    scrollElement={this.listRef.current}
                    registerEvents
                    containerClass={classes.navigationContainer}
                    leftButtonClass={classes.leftButton}
                    rightButtonClass={classes.rightButton}
                />
            </div>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(CaseEvents));