import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import EditPriceBaseContainer from "./EditPriceBaseContainer";
import { ProductBulkAction, ProductUX } from "../../../../../shared/types";
import Dinero from 'dinero.js';
import { useGDispatch } from '../../../../../types';
import { bulkUpdateProductPrice } from '../../../../../actions/product/FHProduct.action';
import { registerAppError } from '../../../../../actions/errors';
import { useState } from 'react';

interface EditBasePriceProps {
    selectButton: BulkEditButtonType | null;
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    selectedProductIds: number[];
    funeralHomeId: number | null;
}

const EditBasePrice = (props: EditBasePriceProps) => {

    const {
        selectButton,
        onSubmitClick,
        selectedProductIds,
        funeralHomeId,
    } = props;

    const dispatch = useGDispatch();
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitclick = async (productIdPriceMap: { productId: number; value: number }[]) => {
        if (funeralHomeId !== null) {
            setIsSpinning(true);
            const updatedProducts: ProductUX[] = await dispatch(bulkUpdateProductPrice({
                funeralHomeId: funeralHomeId,
                productIdPriceMap,
                bulkAction: ProductBulkAction.edit_base_price,
            }));
            setIsSpinning(false);
            onSubmitClick(updatedProducts);
        } else {
            dispatch(registerAppError('Funeral Home not set', {showSnackbar: true, sendToSentry: true}));
        }
    };

    const getBasePrice = (productDetails: ProductUX) => {
        const basePrice = Dinero({
            amount: productDetails?.base_price || 0,
            currency: productDetails?.asset_type,
        });
        return basePrice;
    };
    return (
        <EditPriceBaseContainer
            selectButton={selectButton}
            onSubmitClick={handleSubmitclick}
            selectedProductIds={selectedProductIds}
            getPrice={getBasePrice}
            isSpinning={isSpinning}   
        />
    );
};

export default EditBasePrice;