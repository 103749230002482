import DCTextField, { Props as DCTextFieldProps } from '../textField/DCTextField';
import DCCheckbox from '../checkbox/DCCheckbox';

export interface Props extends DCTextFieldProps {
    checkBoxData: {
        onChange: (checked: boolean) => void;
        isChecked: boolean;
        label: string;
        disabled: boolean;
    }[];
    formControlClass?: string;
    isTextFieldDisabled?: boolean;
    isHiddenFromFamily?: boolean;
}

const DCTextFieldWithMultipleCheckboxes = (props: Props) => {

    const {
        id,
        error,
        disabled: overallDisable,
        required,
        value,
        label: textFieldLabel,
        rootClass,
        textFieldClass,
        labelClass,
        onBlur,
        onChange: onTextfieldChange,
        handleOpenSnackbar,
        inputType,
        placeholder,
        formControlClass,
        endAdornment,
        pattern,
        inputComponent,
        checkBoxData,
        isHiddenFromFamily
    } = props;

    return (
        <>
            <DCTextField
                id={id}
                error={error}
                disabled={checkBoxData.some(({ isChecked }) => isChecked) || overallDisable}
                required={required}
                value={value}
                label={textFieldLabel}
                rootClass={rootClass}
                textFieldClass={textFieldClass}
                labelClass={labelClass}
                onBlur={onBlur}
                onChange={onTextfieldChange}
                handleOpenSnackbar={handleOpenSnackbar}
                inputType={inputType}
                placeholder={placeholder}
                endAdornment={endAdornment}
                pattern={pattern}
                inputComponent={inputComponent}
                isHiddenFromFamily={isHiddenFromFamily}
            />
            {checkBoxData.map(({ label, onChange, isChecked, disabled }) => (
                <DCCheckbox
                    key={label}
                    label={label}
                    onChange={onChange}
                    handleOpenSnackbar={handleOpenSnackbar}
                    isChecked={isChecked}
                    disabled={disabled || overallDisable}
                    rootClass={rootClass}
                    labelClass={labelClass}
                    formControlClass={formControlClass}
                />
            ))}

        </>
    );
};

export default DCTextFieldWithMultipleCheckboxes;
