import { groupBy, cloneDeep } from 'lodash';
import * as constants from '../constants';
import { GatherAction } from '../actions';
import { ModerationState } from '../types';
import {
    LOADED_MODERATION_PENDING_COUNTS,
    LOADED_MODERATION_COUNTS_FOR_CASE,
    MADE_MODERATION_DECISION,
    DELETED_MODERATION_ITEM,
    FLAGGED_CONTENT,
} from '../actions/Moderation.action';
import {
    ModerationStatus,
    ModerationCategoryCounts,
    ModerationCountsForCase,
    ModerationItem
} from '../shared/types';
import { LOADED_PUBLIC_CASE } from '../actions/GatherCase.action';

export const moderationInitData: ModerationState = {
    allCasesPendingCounts: null,
    caseCounts: [],
};

export const moderationState = (
    state: ModerationState = moderationInitData,
    action: GatherAction,
): ModerationState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT: {
            return moderationInitData;
        }
        case LOADED_PUBLIC_CASE:
        case LOADED_MODERATION_PENDING_COUNTS: {
            if (!action.moderationCounts) {
                return state;
            }
            return {
                ...state,
                caseCounts: action.moderationCounts.cases || [],
                allCasesPendingCounts: action.moderationCounts.all_cases,
            };
        }
        case LOADED_MODERATION_COUNTS_FOR_CASE: {
            return {
                ...state,
                caseCounts: state.caseCounts.map((c) => {
                    if (c.case_id === action.moderationCounts.case_id) {
                        return action.moderationCounts;
                    } else {
                        return c;
                    }
                }),
            };
        }
        case DELETED_MODERATION_ITEM: {

            const { item, category } = action;

            let updatedAllCasesPendingCounts = state.allCasesPendingCounts;
            let updatedCaseCounts: ModerationCountsForCase[] = state.caseCounts;

            if ((!item.moderation_decision
                || item.moderation_decision.approval_status === ModerationStatus.pending)
                && state.allCasesPendingCounts) {
                updatedAllCasesPendingCounts = {
                    ...state.allCasesPendingCounts,
                    all: state.allCasesPendingCounts.all - 1,
                    [category]: state.allCasesPendingCounts[category] - 1,
                };
            }

            updatedCaseCounts = state.caseCounts.map((cc) => {
                if (cc.case_id !== item.case_id) {
                    return cc;
                }

                const updatedCount = cloneDeep(cc);

                // decrement origin status
                if (!item.moderation_decision
                    || item.moderation_decision.approval_status === ModerationStatus.pending) {
                    updatedCount.pending.all -= 1;
                    updatedCount.pending[category] -= 1;
                } else {
                    if (item.moderation_decision.approval_status === ModerationStatus.approved) {
                        updatedCount.approved.all -= 1;
                        updatedCount.approved[category] -= 1;
                    } else {
                        updatedCount.blocked.all -= 1;
                        updatedCount.blocked[category] -= 1;
                    }
                }

                return updatedCount;
            });

            return {
                ...state,
                caseCounts: updatedCaseCounts,
                allCasesPendingCounts: updatedAllCasesPendingCounts,
            };
        }
        case MADE_MODERATION_DECISION: {

            const newDecisionsCount = action.decisions.filter((d) => {
                return !d.moderation_decision
                    || d.moderation_decision.approval_status === ModerationStatus.pending;
            }).length;
            const updatedAllCasesPendingCounts: ModerationCategoryCounts | null = !state.allCasesPendingCounts
                ? null
                : {
                    ...state.allCasesPendingCounts,
                    all: state.allCasesPendingCounts.all - newDecisionsCount,
                    [action.category]: state.allCasesPendingCounts[action.category] - newDecisionsCount,
                };

            const groupedDecisions = groupBy(action.decisions, (d: ModerationItem) => d.case_id);
            const updatedCaseCounts: ModerationCountsForCase[] = state.caseCounts.map((cc) => {
                const updatedCount = cloneDeep(cc);
                const decisions: ModerationItem[] | undefined = groupedDecisions[cc.case_id];
                if (!decisions) {
                    return cc;
                }

                for (const decision of decisions) {

                    if (!decision.moderation_decision
                        || decision.moderation_decision.approval_status === ModerationStatus.pending) {
                        // was: pending, now: blocked/approved
                        updatedCount.pending.all -= 1;
                        updatedCount.pending[action.category] -= 1;
                        if (action.isApproval) {
                            updatedCount.approved.all += 1;
                            updatedCount.approved[action.category] += 1;
                        } else {
                            updatedCount.blocked.all += 1;
                            updatedCount.blocked[action.category] += 1;
                        }
                    } else {
                        if (decision.moderation_decision.approval_status === ModerationStatus.approved
                            && !action.isApproval) {
                            // was: approved, now: blocked
                            updatedCount.approved.all -= 1;
                            updatedCount.approved[action.category] -= 1;
                            updatedCount.blocked.all += 1;
                            updatedCount.blocked[action.category] += 1;
                        } else if (decision.moderation_decision.approval_status === ModerationStatus.blocked
                            && action.isApproval) {
                            // was: blocked, now: approved
                            updatedCount.blocked.all -= 1;
                            updatedCount.blocked[action.category] -= 1;
                            updatedCount.approved.all += 1;
                            updatedCount.approved[action.category] += 1;
                        }
                        // will only fall through if it was previously approved/blocked and then flagged
                    }

                }
                return updatedCount;
            });

            return {
                ...state,
                caseCounts: updatedCaseCounts,
                allCasesPendingCounts: updatedAllCasesPendingCounts,
            };
        }
        case FLAGGED_CONTENT: {
            const updatedAllCasesPendingCounts: ModerationCategoryCounts | null = !state.allCasesPendingCounts
                ? null
                : {
                    ...state.allCasesPendingCounts,
                    all: state.allCasesPendingCounts.all + 1,
                    [action.category]: state.allCasesPendingCounts[action.category] + 1,
                };

            const updatedCaseCounts: ModerationCountsForCase[] = state.caseCounts.map((cc) => {
                if (cc.case_id !== action.caseId) {
                    return cc;
                }
                const updatedCount = cloneDeep(cc);
                updatedCount.pending.all += 1;
                updatedCount.pending[action.category] += 1;

                return updatedCount;
            });

            return {
                ...state,
                caseCounts: updatedCaseCounts,
                allCasesPendingCounts: updatedAllCasesPendingCounts,
            };
        }
        default: {
            return state;
        }
    }
};
