import { GatherAction } from '../actions';
import { AssignKeeptrackState } from '../types';
import {
    LOADED_KEEP_TRACK_CASES,
    LOADED_KEEP_TRACK_FUNERAL_HOMES,
    LOADED_KEEP_TRACK_PAGE,
    LOADING_KEEP_TRACK_CASES,
    LOADING_KEEP_TRACK_FUNERAL_HOMES,
    LOADING_KEEP_TRACK_PAGE,
    LOAD_KEEP_TRACK_CASES_FAILED,
    LOAD_KEEP_TRACK_FUNERAL_HOMES_FAILED,
    LOAD_KEEP_TRACK_PAGE_FAILED,
    SELECTED_CASE_FOR_KEEPTRACK_ASSIGNMENT,
} from '../actions/KeepTrack.action';
import { USER_LOGGED_OUT } from '../constants';
import { CASE_NUMBER_UPDATED, CASE_UPDATED } from '../actions/GatherCase.action';

export const keeptrackInitData: AssignKeeptrackState = {
    cases: {
        data: [],
        hasMoreData: false,
    },
    funeralHomes: {
        data: [],
        hasMoreData: false,
    },
    isLoading: false,
};

export const keeptrackState = (
    state: AssignKeeptrackState = keeptrackInitData,
    action: GatherAction,
): AssignKeeptrackState => {
    switch (action.type) {
        case USER_LOGGED_OUT: {
            return keeptrackInitData;
        }
        case LOADING_KEEP_TRACK_FUNERAL_HOMES: {
            const funeralHomes = action.clearFuneralHomes
                ? { ...state.funeralHomes, data: [] }
                : state.funeralHomes;
            return {
                ...state,
                isLoading: true,
                funeralHomes,
            };
        }
        case LOADED_KEEP_TRACK_FUNERAL_HOMES: {
            return {
                ...state,
                isLoading: false,
                funeralHomes: {
                    ...state.funeralHomes,
                    data: [...state.funeralHomes.data, ...action.response.data],
                    hasMoreData: action.response.hasMoreData,
                }
            };
        }
        case LOAD_KEEP_TRACK_FUNERAL_HOMES_FAILED: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LOADING_KEEP_TRACK_CASES: {
            const cases = action.clearCases
                ? { ...state.cases, data: [] }
                : state.cases;
            return {
                ...state,
                isLoading: true,
                cases,
            };
        }
        case LOADED_KEEP_TRACK_CASES: {
            return {
                ...state,
                isLoading: false,
                cases: {
                    ...state.cases,
                    data: [...state.cases.data, ...action.response.data],
                    hasMoreData: action.response.hasMoreData,
                }
            };
        }
        case LOAD_KEEP_TRACK_CASES_FAILED: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SELECTED_CASE_FOR_KEEPTRACK_ASSIGNMENT: {
            return {
                ...state,
                cases: {
                    ...state.cases,
                    data: [action.caseSummary],
                    hasMoreData: true,
                },
            };
        }
        case LOADING_KEEP_TRACK_PAGE: {
            return {
                ...keeptrackInitData,
                isLoading: true,
            };
        }
        case LOADED_KEEP_TRACK_PAGE: {
            return {
                ...state,
                isLoading: false,
                funeralHomes: {
                    ...state.funeralHomes,
                    data: action.selectedFuneralHome ? [action.selectedFuneralHome] : [],
                },
                cases: {
                    ...state.cases,
                    data: action.selectedCase ? [action.selectedCase] : [],
                },
            };
        }
        case CASE_UPDATED: {
            return {
                ...state,
                cases: {
                    ...state.cases,
                    data: state.cases.data.map(ktCase =>
                        ktCase.uuid === action.gatherCase.uuid
                            ? {
                                ...ktCase,
                                case_type: action.gatherCase.case_type,
                                workflow_id: action.gatherCase.workflow_id,
                                workflow_name: action.gatherCase.workflow_name
                            }
                            : ktCase
                    )
                }
            };
        }
        case CASE_NUMBER_UPDATED: {
            return {
                ...state,
                cases: {
                    ...state.cases,
                    data: state.cases.data.map(ktCase =>
                        ktCase.uuid === action.gatherCase.uuid
                            ? {
                                ...ktCase,
                                case_number: action.gatherCase.case_number,
                            }
                            : ktCase
                    )
                }
            };
        }
        case LOAD_KEEP_TRACK_PAGE_FAILED: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};
