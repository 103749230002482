import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { ORANGE_COLOR } from '../../../constants/colorVariables';

import { Theme } from '@mui/material';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles(
    (theme: Theme) => ({
        dataGridHeader: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.up(768)]: {
                alignItems: 'flex-end',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            '& .MuiBadge-badge': {
                backgroundColor: ORANGE_COLOR,
            },
        },
    }),
    { name: 'CustomToolBar' },
);

interface Props {
    exportFileName?: string;
}

const CustomToolBar = ({ exportFileName, children }: PropsWithChildren<Props>) => {
    const classes = useStyles();

    return (
        <Grid className={classes.dataGridHeader} alignItems="flex-end">
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                    excelOptions={{
                        fileName: exportFileName,
                        escapeFormulas: false,
                    }}
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                        fileName: exportFileName,
                        escapeFormulas: false,
                    }}
                />
            </GridToolbarContainer>
            {children}
        </Grid>
    );
};

export default CustomToolBar;
