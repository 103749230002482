import { CSSProperties, memo } from "react";

import { getDecedentAge, getFullNameFromCase } from "../../../services";
import { GatherCaseUX } from "../../../shared/types";
import UserAvatar from "../../common/UserAvatar";
import { useGSelector } from "../../../types";

type StylesKey = 'root' | 'keepTrackKey' | 'rightSection' | 'userAvatar'
    | 'zIndex1' | 'textEllipsis' | 'printColorAdjust' | 'lineHeight' | 'widthFitContent' | 'fontSize22'
    | 'width100' | 'paragraphLineHeight' | 'colorPrimary';
type Styles = Record<StylesKey, CSSProperties>;

const useStyles = (): Styles => {
    const muiTheme = useGSelector(({ whiteLabel }) => whiteLabel.muiTheme);

    return {
        root: {
            margin: '0 auto',
            borderRadius: 16,
            boxShadow: muiTheme.shadows[6],
        },
        keepTrackKey: {
            height: '100%',
            display: 'inline-block',
            textAlign: 'center',
            margin: 'auto'
        },
        rightSection: {
            padding: '4px 4px 4px 0'
        },
        userAvatar: {
            width: 95,
            height: 95,
            fontSize: 32,
            fontWeight: 300,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            background: '#bdbdbd',
            color: '#fff'
        },
        zIndex1: {
            position: 'relative',
            zIndex: 1,
        },
        textEllipsis: {
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        printColorAdjust: {
            printColorAdjust: 'exact',
            WebkitPrintColorAdjust: 'exact',
        },
        lineHeight: {
            lineHeight: 1.125
        },
        widthFitContent: {
            width: 'fit-content'
        },
        fontSize22: {
            fontSize: 22
        },
        width100: {
            width: '100%'
        },
        paragraphLineHeight: {
            lineHeight: 1.25
        },
        colorPrimary: {
            color: muiTheme.palette.primary.main
        }
    };
};

interface Props {
    selectedCase: GatherCaseUX;
    printMode: boolean;
    previewMode?: boolean;
    rootStyles?: CSSProperties;
}

/**
 * Note: Don't use MUI classes/Components for styling for this component,
 * use only Intrinsic Elements and inline styling 
 */
const CremainsSticker = ({ selectedCase, printMode, previewMode, rootStyles }: Props) => {
    const { bDate, dDate, age } = getDecedentAge(selectedCase);

    const styles = useStyles();
    const crematoryLocationName = selectedCase.death_certificate?.restingPlace.dispositionPlace.locationName;

    return (
        <div
            style={{
                ...styles.root,
                ...rootStyles,
                maxHeight: 200,
                boxSizing: 'border-box',
                boxShadow: printMode || previewMode ? 'none' : styles.root.boxShadow,
                width: '100%',
                overflow: 'hidden',
                maxWidth: !printMode ? 400 : undefined
            }}
        >
            <div
                style={{
                    ...styles.printColorAdjust,
                    minWidth: 42,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '4px 8px 0'
                }}
            >
                <p
                    style={{
                        ...styles.colorPrimary,
                        fontSize: 12,
                        margin: 0
                    }}
                >
                    {selectedCase.case_number}
                </p>

                <p
                    style={{
                        ...styles.keepTrackKey,
                        ...styles.colorPrimary,
                        fontSize: 12,
                        margin: 0
                    }}
                >
                    {selectedCase.keeptrack_key}
                </p>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 1,
                    flex: 2,
                    width: '100%',
                    padding: printMode ? '0 2px 3px' : '0 12px 6px',
                    boxSizing: 'border-box'
                }}
            >
                <div style={{ ...styles.zIndex1, ...styles.width100 }}>
                    <p style={{
                        margin: 0,
                        textAlign: 'center',
                        ...styles.colorPrimary,
                        ...styles.textEllipsis,
                        lineHeight: printMode ? '16px' : 1.125,
                        fontSize: 16
                    }}>
                        {getFullNameFromCase(selectedCase)}{age && `, ${age}`}
                    </p>

                    <p
                        style={{
                            textTransform: 'uppercase',
                            minHeight: 15,
                            lineHeight: 1.25,
                            margin: 0,
                            fontSize: 12,
                            textAlign: 'center',
                            ...styles.colorPrimary,
                            ...styles.textEllipsis
                        }}
                    >
                        {bDate && dDate &&
                            `${bDate.format('D MMM YYYY')} - ${dDate.format('D MMM YYYY')}`}
                    </p>
                </div>

                <div style={{ ...styles.width100, ...styles.zIndex1 }}>
                    <p
                        style={{
                            ...styles.textEllipsis,
                            ...styles.lineHeight,
                            ...styles.colorPrimary,
                            fontSize: 12,
                            margin: 0,
                            textAlign: 'center'
                        }}
                    >
                        {selectedCase.funeral_home.name} ({selectedCase.funeral_home.city})
                    </p>
                </div>

                <UserAvatar
                    user={selectedCase}
                    size={120}
                    imgProps={{
                        width: '100%',
                        height: '100%',
                        style: { objectFit: 'cover' }
                    }}
                    style={{
                        ...styles.userAvatar,
                        margin: '2px 0',
                    }}
                />

                <p
                    style={{
                        ...styles.textEllipsis,
                        ...styles.lineHeight,
                        ...styles.colorPrimary,
                        fontSize: 12,
                        margin: 0,
                        textAlign: 'center'
                    }}
                >
                    Cremated Human Remains
                </p>

                <p
                    style={{
                        ...styles.textEllipsis,
                        ...styles.lineHeight,
                        ...styles.colorPrimary,
                        fontSize: 12,
                        minHeight: 14,
                        margin: 0,
                        textAlign: 'center'
                    }}
                >
                    {crematoryLocationName}
                </p>
            </div>
        </div>
    );
};

export default memo(CremainsSticker);