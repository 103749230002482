import { getFromAPI, putToAPI, postToAPI, deleteFromAPI } from '.';

import {
    ServiceTemplateUX,
    ServiceTemplateRequest,
    ServiceDetailRequest,
    DefaultServiceDetail,
    ServiceTemplateFuneralHomesResponse,
    ServiceTemplateUpdateResponse,
} from '../shared/types';

import { setAppSnackbar } from './AppSnackbar.action';
import { funeralHomesLoaded } from './FuneralHome.action';
import { log } from '../logger';
import { AppDispatch } from '../store';

export function loadAllServiceTemplates() {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateUX[] | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = 'servicetemplate';
        const templates = await getFromAPI<ServiceTemplateUX[]>(resource, dispatch);
        if (templates) {
            dispatch(gotTemplates(templates));
            return templates;
        } else {
            dispatch(gotTemplatesError());
            return null;
        }
    };
}

export function loadServiceTemplatesForFuneralHome(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateUX[] | null> => {
        dispatch(serviceTemplatesLoading);
        dispatch(clearTemplates);
        const resource = `funeralhome/${funeralHomeId}/servicetemplate`;
        const templates = await getFromAPI<ServiceTemplateUX[]>(resource, dispatch);
        if (templates) {
            dispatch(gotTemplates(templates));
            return templates;
        } else {
            dispatch(gotTemplatesError());
            return null;
        }
    };
}

export function loadDefaultTemplateDetails() {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail[] | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = 'servicetemplate/DEFAULT';
        const template = await getFromAPI<ServiceTemplateUX>(resource, dispatch);
        if (template) {
            dispatch(gotDefaultTemplateDetails(template.service_details));
            return template.service_details;
        } else {
            dispatch(gotDefaultTemplateDetailsError());
            return null;
        }
    };
}

export function loadCasketBearerTemplateDetails() {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail[] | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = 'servicetemplate/CASKET_BEARERS';
        const template = await getFromAPI<ServiceTemplateUX>(resource, dispatch);
        if (template) {
            dispatch(gotCasketBearerTemplateDetails(template.service_details));
            return template.service_details;
        } else {
            dispatch(gotCasketBearerTemplateDetailsError());
            return null;
        }
    };
}

export function loadServiceTemplate(key: string) {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateUX | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `servicetemplate/${encodeURI(key)}`;
        const template = await getFromAPI<ServiceTemplateUX>(resource, dispatch);
        if (template) {
            dispatch(gotTemplate(template));
            return template;
        } else {
            dispatch(gotTemplatesError());
            return null;
        }
    };
}

export function loadServiceTemplateFuneralHomes(key: string) {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateFuneralHomesResponse | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `servicetemplate/${encodeURI(key)}/funeralhomes`;
        const response = await getFromAPI<ServiceTemplateFuneralHomesResponse>(resource, dispatch);

        if (response) {
            dispatch(gotTemplateFuneralHomes(response));
            dispatch(funeralHomesLoaded(response.all, false, 0));
        } else {
            dispatch(gotTemplateFuneralHomesError());
        }

        return response;
    };
}

export function createServiceTemplate(template: ServiceTemplateRequest) {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateUX | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = 'servicetemplate';
        const newTemplate = await putToAPI<ServiceTemplateUX>(resource, { serviceTemplate: template }, dispatch);
        if (newTemplate) {
            dispatch(createdTemplate(newTemplate));
            dispatch(setAppSnackbar(`${newTemplate.name} service template created`, 'success'));
            return newTemplate;
        } else {
            dispatch(createTemplateError());
            return null;
        }
    };
}

export function updateServiceTemplate(key: string, template: ServiceTemplateRequest) {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateUX | null> => {
        dispatch(serviceTemplatesLoading());
        try {
            ServiceTemplateRequest.fromRequest(template);
        } catch (ex) {
            log.warn('Failed to validate ServiceTemplateRequest', { template, ex });
            return null;
        }
        const resource = `servicetemplate/${encodeURI(key)}`;
        const response = await postToAPI<ServiceTemplateUpdateResponse>(
            resource,
            { serviceTemplate: template },
            dispatch,
        );
        if (response) {
            dispatch(updatedTemplate(response.serviceTemplate));
            dispatch(gotTemplateFuneralHomes(response));
            dispatch(funeralHomesLoaded(response.all, false, 0));
            return response.serviceTemplate;
        } else {
            dispatch(updateTemplateError());
            return null;
        }
    };
}

export function deleteServiceTemplate(templateKey: string) {
    return async (dispatch: AppDispatch): Promise<ServiceTemplateUX | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `servicetemplate/${templateKey}`;
        const newTemplate = await deleteFromAPI<ServiceTemplateUX>(resource, dispatch);
        if (newTemplate) {
            dispatch(deletedTemplate(newTemplate));
            return newTemplate;
        } else {
            dispatch(deleteTemplateError());
            return null;
        }
    };
}

export function linkServiceTemplateDetail(detailId: number, templateKey: string) {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `servicetemplate/${templateKey}/servicedetail/link/${detailId}`;
        const newDetail = await postToAPI<DefaultServiceDetail>(resource, {}, dispatch);
        if (newDetail) {
            dispatch(linkedTemplateDetail(templateKey, newDetail));
            return newDetail;
        } else {
            dispatch(linkTemplateDetailError());
            return null;
        }
    };
}

export function removeServiceTemplateDetail(serviceTemplateDetailId: number, templateKey: string) {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `servicetemplate/${templateKey}/servicedetail/unlink/${serviceTemplateDetailId}`;
        const removed = await postToAPI<DefaultServiceDetail>(resource, {}, dispatch);
        if (removed) {
            dispatch(unlinkTemplateDetail(templateKey, removed));
            return removed;
        } else {
            dispatch(unlinkTemplateDetailError());
            return null;
        }
    };
}

export function createServiceTemplateDetail(detail: ServiceDetailRequest, templateKey: string) {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `servicetemplate/${templateKey}/servicedetail`;
        const newDetail = await putToAPI<DefaultServiceDetail>(resource, { serviceDetail: detail }, dispatch);
        if (newDetail) {
            dispatch(createdTemplateDetail(newDetail));
            return newDetail;
        } else {
            dispatch(deleteTemplateError());
            return null;
        }
    };
}

export function createDefaultServiceDetail(detail: ServiceDetailRequest) {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = 'defaultservicedetail';
        const updated = await postToAPI<DefaultServiceDetail>(resource, { defaultServiceDetail: detail }, dispatch);

        if (updated) {
            dispatch(createdDefaultDetail(updated));
            return updated;
        } else {
            dispatch(createDefaultDetailError());
            return null;
        }
    };
}

export function updateDefaultServiceDetail(id: number, detail: ServiceDetailRequest) {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `defaultservicedetail/${id}`;
        const updated = await postToAPI<DefaultServiceDetail>(resource, { defaultServiceDetail: detail }, dispatch);

        if (updated) {
            dispatch(updatedDefaultDetail(updated));
            return updated;
        } else {
            dispatch(updateDefaultDetailError());
            return null;
        }
    };
}

export function removeDefaultServiceDetail(id: number) {
    return async (dispatch: AppDispatch): Promise<DefaultServiceDetail | null> => {
        dispatch(serviceTemplatesLoading);
        const resource = `defaultservicedetail/${id}`;
        const detail = await deleteFromAPI<DefaultServiceDetail>(resource, dispatch);

        if (detail) {
            dispatch(deletedDefaultDetail(detail));
            return detail;
        } else {
            dispatch(deleteDefaultDetailError());
            return null;
        }
    };
}

// loading

export const SERVICE_TEMPLATES_LOADING = 'SERVICE_TEMPLATES_LOADING';
export type SERVICE_TEMPLATES_LOADING = typeof SERVICE_TEMPLATES_LOADING;

interface ServiceTemplatesLoading {
    type: SERVICE_TEMPLATES_LOADING;
}

export function serviceTemplatesLoading(): ServiceTemplatesLoading {
    return {
        type: SERVICE_TEMPLATES_LOADING
    };
}

// clear
export const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES';
export type CLEAR_TEMPLATES = typeof CLEAR_TEMPLATES;

interface ClearTemplates {
    type: CLEAR_TEMPLATES;
}

export function clearTemplates(): ClearTemplates {
    return {
        type: CLEAR_TEMPLATES
    };
}

// list templates
export const GOT_TEMPLATES = 'GOT_TEMPLATES';
export type GOT_TEMPLATES = typeof GOT_TEMPLATES;

interface GotTemplates {
    type: GOT_TEMPLATES;
    templates: ServiceTemplateUX[];
}

export function gotTemplates(templates: ServiceTemplateUX[]): GotTemplates {
    return {
        type: GOT_TEMPLATES,
        templates
    };
}

export const GOT_TEMPLATES_ERROR = 'GOT_TEMPLATES_ERROR';
export type GOT_TEMPLATES_ERROR = typeof GOT_TEMPLATES_ERROR;

interface GotTemplatesError {
    type: GOT_TEMPLATES_ERROR;
}

export function gotTemplatesError(): GotTemplatesError {
    return {
        type: GOT_TEMPLATES_ERROR
    };
}

// default template details
export const GOT_DEFAULT_TEMPLATE_DETAILS = 'GOT_DEFAULT_TEMPLATE_DETAILS';
export type GOT_DEFAULT_TEMPLATE_DETAILS = typeof GOT_DEFAULT_TEMPLATE_DETAILS;

interface GotDefaultTemplateDetails {
    type: GOT_DEFAULT_TEMPLATE_DETAILS;
    defaultDetails: DefaultServiceDetail[];
}

export function gotDefaultTemplateDetails(defaultDetails: DefaultServiceDetail[]): GotDefaultTemplateDetails {
    return {
        type: GOT_DEFAULT_TEMPLATE_DETAILS,
        defaultDetails: defaultDetails
    };
}

export const GOT_DEFAULT_TEMPLATE_DETAILS_ERROR = 'GOT_DEFAULT_TEMPLATE_DETAILS_ERROR';
export type GOT_DEFAULT_TEMPLATE_DETAILS_ERROR = typeof GOT_DEFAULT_TEMPLATE_DETAILS_ERROR;

interface GotDefaultTemplateDetailsError {
    type: GOT_DEFAULT_TEMPLATE_DETAILS_ERROR;
}

export function gotDefaultTemplateDetailsError(): GotDefaultTemplateDetailsError {
    return {
        type: GOT_DEFAULT_TEMPLATE_DETAILS_ERROR
    };
}

// default template details
export const GOT_CASKET_BEARER_TEMPLATE_DETAILS = 'GOT_CASKET_BEARER_TEMPLATE_DETAILS';
export type GOT_CASKET_BEARER_TEMPLATE_DETAILS = typeof GOT_CASKET_BEARER_TEMPLATE_DETAILS;

interface GotCasketBearerTemplateDetails {
    type: GOT_CASKET_BEARER_TEMPLATE_DETAILS;
    defaultDetails: DefaultServiceDetail[];
}

export function gotCasketBearerTemplateDetails(defaultDetails: DefaultServiceDetail[]): GotCasketBearerTemplateDetails {
    return {
        type: GOT_CASKET_BEARER_TEMPLATE_DETAILS,
        defaultDetails: defaultDetails
    };
}

export const GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR = 'GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR';
export type GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR = typeof GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR;

interface GotCasketBearerTemplateDetailsError {
    type: GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR;
}

export function gotCasketBearerTemplateDetailsError(): GotCasketBearerTemplateDetailsError {
    return {
        type: GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR
    };
}

// single template
export const GOT_TEMPLATE = 'GOT_TEMPLATE';
export type GOT_TEMPLATE = typeof GOT_TEMPLATE;

interface GotTemplate {
    type: GOT_TEMPLATE;
    template: ServiceTemplateUX;
}

export function gotTemplate(template: ServiceTemplateUX): GotTemplate {
    return {
        type: GOT_TEMPLATE,
        template: template
    };
}

export const GOT_TEMPLATE_ERROR = 'GOT_TEMPLATE_ERROR';
export type GOT_TEMPLATE_ERROR = typeof GOT_TEMPLATE_ERROR;

interface GotTemplateError {
    type: GOT_TEMPLATE_ERROR;
}

export function gotTemplateError(): GotTemplateError {
    return {
        type: GOT_TEMPLATE_ERROR
    };
}
// template funeral homes

export const GOT_TEMPLATE_FUNERAL_HOMES = 'GOT_TEMPLATE_FUNERAL_HOMES';
export type GOT_TEMPLATE_FUNERAL_HOMES = typeof GOT_TEMPLATE_FUNERAL_HOMES;

interface GotTemplateFuneralHomes {
    type: GOT_TEMPLATE_FUNERAL_HOMES;
    response: ServiceTemplateFuneralHomesResponse;
}

function gotTemplateFuneralHomes(response: ServiceTemplateFuneralHomesResponse): GotTemplateFuneralHomes {
    return {
        type: GOT_TEMPLATE_FUNERAL_HOMES,
        response,
    };
}

export const GOT_TEMPLATE_FUNERAL_HOMES_ERROR = 'GOT_TEMPLATE_FUNERAL_HOMES_ERROR';
export type GOT_TEMPLATE_FUNERAL_HOMES_ERROR = typeof GOT_TEMPLATE_FUNERAL_HOMES_ERROR;

interface GotTemplateFuneralHomesError {
    type: GOT_TEMPLATE_FUNERAL_HOMES_ERROR;
}

function gotTemplateFuneralHomesError(): GotTemplateFuneralHomesError {
    return {
        type: GOT_TEMPLATE_FUNERAL_HOMES_ERROR
    };
}

// create template

export const CREATED_TEMPLATE = 'CREATED_TEMPLATE';
export type CREATED_TEMPLATE = typeof CREATED_TEMPLATE;

interface CreatedTemplate {
    type: CREATED_TEMPLATE;
    template: ServiceTemplateUX;
}

export function createdTemplate(template: ServiceTemplateUX): CreatedTemplate {
    return {
        type: CREATED_TEMPLATE,
        template: template
    };
}

export const CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';
export type CREATE_TEMPLATE_ERROR = typeof CREATE_TEMPLATE_ERROR;

interface CreateTemplateError {
    type: CREATE_TEMPLATE_ERROR;
}

export function createTemplateError(): CreateTemplateError {
    return {
        type: CREATE_TEMPLATE_ERROR
    };
}

// update template

export const UPDATED_TEMPLATE = 'UPDATED_TEMPLATE';
export type UPDATED_TEMPLATE = typeof UPDATED_TEMPLATE;

interface UpdatedTemplate {
    type: UPDATED_TEMPLATE;
    template: ServiceTemplateUX;
}

export function updatedTemplate(template: ServiceTemplateUX): UpdatedTemplate {
    return {
        type: UPDATED_TEMPLATE,
        template: template
    };
}

export const UPDATE_TEMPLATE_ERROR = 'UPDATE_TEMPLATE_ERROR';
export type UPDATE_TEMPLATE_ERROR = typeof UPDATE_TEMPLATE_ERROR;

interface UpdateTemplateError {
    type: UPDATE_TEMPLATE_ERROR;
}

export function updateTemplateError(): UpdateTemplateError {
    return {
        type: UPDATE_TEMPLATE_ERROR
    };
}

// delete template

export const DELETED_TEMPLATE = 'DELETED_TEMPLATE';
export type DELETED_TEMPLATE = typeof DELETED_TEMPLATE;

interface DeletedTemplate {
    type: DELETED_TEMPLATE;
    template: ServiceTemplateUX;
}

export function deletedTemplate(template: ServiceTemplateUX): DeletedTemplate {
    return {
        type: DELETED_TEMPLATE,
        template: template
    };
}

export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';
export type DELETE_TEMPLATE_ERROR = typeof DELETE_TEMPLATE_ERROR;

interface DeleteTemplateError {
    type: DELETE_TEMPLATE_ERROR;
}

export function deleteTemplateError(): DeleteTemplateError {
    return {
        type: DELETE_TEMPLATE_ERROR
    };
}

// create template detail

export const CREATED_TEMPLATE_DETAIL = 'CREATED_TEMPLATE_DETAIL';
export type CREATED_TEMPLATE_DETAIL = typeof CREATED_TEMPLATE_DETAIL;

interface CreatedTemplateDetail {
    type: CREATED_TEMPLATE_DETAIL;
    detail: DefaultServiceDetail;
}

export function createdTemplateDetail(detail: DefaultServiceDetail): CreatedTemplateDetail {
    return {
        type: CREATED_TEMPLATE_DETAIL,
        detail: detail
    };
}

export const CREATE_TEMPLATE_DETAIL_ERROR = 'CREATE_TEMPLATE_DETAIL_ERROR';
export type CREATE_TEMPLATE_DETAIL_ERROR = typeof CREATE_TEMPLATE_DETAIL_ERROR;

interface CreateTemplateDetailError {
    type: CREATE_TEMPLATE_DETAIL_ERROR;
}

export function createTemplateDetailError(): CreateTemplateDetailError {
    return {
        type: CREATE_TEMPLATE_DETAIL_ERROR
    };
}

// link template detail

export const LINKED_TEMPLATE_DETAIL = 'LINKED_TEMPLATE_DETAIL';
export type LINKED_TEMPLATE_DETAIL = typeof LINKED_TEMPLATE_DETAIL;

interface LinkedTemplateDetail {
    type: LINKED_TEMPLATE_DETAIL;
    templateKey: string;
    detail: DefaultServiceDetail;
}

export function linkedTemplateDetail(templateKey: string, detail: DefaultServiceDetail): LinkedTemplateDetail {
    return {
        type: LINKED_TEMPLATE_DETAIL,
        templateKey: templateKey,
        detail: detail
    };
}

export const LINK_TEMPLATE_DETAIL_ERROR = 'LINK_TEMPLATE_DETAIL_ERROR';
export type LINK_TEMPLATE_DETAIL_ERROR = typeof LINK_TEMPLATE_DETAIL_ERROR;

interface LinkTemplateDetailError {
    type: LINK_TEMPLATE_DETAIL_ERROR;
}

export function linkTemplateDetailError(): LinkTemplateDetailError {
    return {
        type: LINK_TEMPLATE_DETAIL_ERROR
    };
}

//  set active template

export const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE';
export type SET_ACTIVE_TEMPLATE = typeof SET_ACTIVE_TEMPLATE;

interface SetActiveTemplate {
    type: SET_ACTIVE_TEMPLATE;
    templateKey: string | null;
}

export function setActiveTemplate(key: string | null): SetActiveTemplate {
    return {
        type: SET_ACTIVE_TEMPLATE,
        templateKey: key
    };
}

//  set active tab index

export const SET_ACTIVE_TAB_INDEX = 'SET_ACTIVE_TAB_INDEX';
export type SET_ACTIVE_TAB_INDEX = typeof SET_ACTIVE_TAB_INDEX;

interface SetActiveTabIndex {
    type: SET_ACTIVE_TAB_INDEX;
    tabIndex: number | null;
}

export function setActiveTabIndex(tabIndex: number | null): SetActiveTabIndex {
    return {
        type: SET_ACTIVE_TAB_INDEX,
        tabIndex
    };
}

// create default detail

export const CREATED_DEFAULT_DETAIL = 'CREATED_DEFAULT_DETAIL';
export type CREATED_DEFAULT_DETAIL = typeof CREATED_DEFAULT_DETAIL;

interface CreatedDefaultDetail {
    type: CREATED_DEFAULT_DETAIL;
    detail: DefaultServiceDetail;
}

export function createdDefaultDetail(detail: DefaultServiceDetail): CreatedDefaultDetail {
    return {
        type: CREATED_DEFAULT_DETAIL,
        detail: detail
    };
}

export const CREATE_DEFAULT_DETAIL_ERROR = 'CREATE_DEFAULT_DETAIL_ERROR';
export type CREATE_DEFAULT_DETAIL_ERROR = typeof CREATE_DEFAULT_DETAIL_ERROR;

interface CreateDefaultDetailError {
    type: CREATE_DEFAULT_DETAIL_ERROR;
}

export function createDefaultDetailError(): CreateDefaultDetailError {
    return {
        type: CREATE_DEFAULT_DETAIL_ERROR
    };
}

// update default detail

export const UPDATED_DEFAULT_DETAIL = 'UPDATED_DEFAULT_DETAIL';
export type UPDATED_DEFAULT_DETAIL = typeof UPDATED_DEFAULT_DETAIL;

interface UpdatedDefaultDetail {
    type: UPDATED_DEFAULT_DETAIL;
    detail: DefaultServiceDetail;
}

export function updatedDefaultDetail(detail: DefaultServiceDetail): UpdatedDefaultDetail {
    return {
        type: UPDATED_DEFAULT_DETAIL,
        detail: detail
    };
}

export const UPDATE_DEFAULT_DETAIL_ERROR = 'UPDATE_DEFAULT_DETAIL_ERROR';
export type UPDATE_DEFAULT_DETAIL_ERROR = typeof UPDATE_DEFAULT_DETAIL_ERROR;

interface UpdateDefaultDetailError {
    type: UPDATE_DEFAULT_DETAIL_ERROR;
}

export function updateDefaultDetailError(): UpdateDefaultDetailError {
    return {
        type: UPDATE_DEFAULT_DETAIL_ERROR
    };
}

// delete default detail
export const DELETED_DEFAULT_DETAIL = 'DELETED_DEFAULT_DETAIL';
export type DELETED_DEFAULT_DETAIL = typeof DELETED_DEFAULT_DETAIL;

interface DeletedDefaultDetail {
    type: DELETED_DEFAULT_DETAIL;
    detail: DefaultServiceDetail;
}

export function deletedDefaultDetail(detail: DefaultServiceDetail): DeletedDefaultDetail {
    return {
        detail,
        type: DELETED_DEFAULT_DETAIL
    };
}

export const DELETE_DEFAULT_DETAIL_ERROR = 'DELETE_DEFAULT_DETAIL_ERROR';
export type DELETE_DEFAULT_DETAIL_ERROR = typeof DELETE_DEFAULT_DETAIL_ERROR;

interface DeleteDefaultDetailError {
    type: DELETE_DEFAULT_DETAIL_ERROR;
}

export function deleteDefaultDetailError(): DeleteDefaultDetailError {
    return {
        type: DELETE_DEFAULT_DETAIL_ERROR
    };
}

export const UNLINK_TEMPLATE_DETAIL = 'UNLINK_TEMPLATE_DETAIL';
export type UNLINK_TEMPLATE_DETAIL = typeof UNLINK_TEMPLATE_DETAIL;

interface UnlinkTemplateDetail {
    templateKey: string;
    detail: DefaultServiceDetail;
    type: UNLINK_TEMPLATE_DETAIL;
}

function unlinkTemplateDetail(
    templateKey: string, detail: DefaultServiceDetail
): UnlinkTemplateDetail {
    return {
        templateKey,
        detail,
        type: UNLINK_TEMPLATE_DETAIL
    };
}

export const UNLINK_TEMPLATE_DETAIL_ERROR = 'UNLINK_TEMPLATE_DETAIL_ERROR';
export type UNLINK_TEMPLATE_DETAIL_ERROR = typeof UNLINK_TEMPLATE_DETAIL_ERROR;

interface UnlinkTemplateDetailError {
    type: UNLINK_TEMPLATE_DETAIL_ERROR;
}

function unlinkTemplateDetailError(): UnlinkTemplateDetailError {
    return {
        type: UNLINK_TEMPLATE_DETAIL_ERROR
    };
}

export type ServiceDetailAction = ServiceTemplatesLoading
| GotTemplates
| ClearTemplates
| GotTemplatesError
| GotDefaultTemplateDetails
| GotDefaultTemplateDetailsError
| GotCasketBearerTemplateDetails
| GotCasketBearerTemplateDetailsError
| SetActiveTemplate
| GotTemplate
| GotTemplateError
| CreatedTemplate
| CreateTemplateError
| UpdatedTemplate
| UpdateTemplateError
| DeletedTemplate
| DeleteTemplateError
| SetActiveTabIndex
| CreatedTemplateDetail
| CreateTemplateDetailError
| LinkedTemplateDetail
| LinkTemplateDetailError
| UnlinkTemplateDetail
| UnlinkTemplateDetailError
| CreatedDefaultDetail
| CreateDefaultDetailError
| UpdatedDefaultDetail
| UpdateDefaultDetailError
| DeletedDefaultDetail
| DeleteDefaultDetailError
| GotTemplateFuneralHomes
| GotTemplateFuneralHomesError
    ;
