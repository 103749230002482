import * as React from 'react';

import Grid from '@mui/material/Grid';

import { StyledProps, styleWrapper } from '../styles';
import { ContractPackageView, ContractPackageGroup } from '../../../../../shared/types';
import ListView from './ListView';
import GroupedView from './GroupedView';
import PackageOnlyView from './PackageOnlyView';
import { PrintModeType } from '../..';
import withGStyles from '../../../../../styles/WithGStyles';

type Props = {
    packageGroups: ContractPackageGroup[];
    packageView: ContractPackageView;
    isPrintMode: boolean;
    isCondensedView: boolean;
    isItemizedStatement: boolean;
    printModeType?: PrintModeType;
    renderCategoryContent: (category: 'package', categoryItemIds: string[]) => JSX.Element | null;
};

const ContractPackages: React.FunctionComponent<Props & StyledProps> = (props) => {
    const {
        packageGroups,
        packageView,
        isPrintMode,
        printModeType,
        isCondensedView,
        isItemizedStatement,
        classes, renderCategoryContent
    } = props;
    if (!packageGroups.length) {
        return null;
    }

    let containerStyles: React.CSSProperties = {
        display: 'flex',
        flexDirection: isCondensedView ? 'column' : 'row',
        justifyContent: isCondensedView ? 'space-between' : 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    };

    const typographyStyles: Record<string, string | number> = {
        display: 'inline-flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        color: '#2e2e2e',
        marginTop: 0,
        marginBottom: 4,
        alignItems: 'center'
    };

    if (packageView === ContractPackageView.grouped) {
        containerStyles = {
            display: 'initial',
        };
    } else {
        typographyStyles.alignItems = 'center';
    }

    return (
        <Grid
            item
            xs={12}
            className={classes.packageSection}
            style={{
                ...containerStyles,
                borderBottom: isCondensedView ? 0 : '1px solid',
            }}
        >
            {packageGroups.map((pkgGrp) => {
                if (packageView === ContractPackageView.grouped) {
                    return (
                        <GroupedView
                            key={pkgGrp.title}
                            renderCategoryContent={() => renderCategoryContent(
                                'package',
                                pkgGrp.items.map((i) => i.id)
                            )}
                            isCondensedView={isCondensedView}
                            isPrintMode={isPrintMode}
                            packageGroup={pkgGrp}
                            typographyStyles={typographyStyles}
                            printModeType={printModeType}
                            isItemizedStatement={isItemizedStatement}
                        />
                    );
                } else if (packageView === ContractPackageView.package_only) {
                    return (
                        <PackageOnlyView
                            key={pkgGrp.title}
                            isCondensedView={isCondensedView}
                            packageGroup={pkgGrp}
                            typographyStyles={typographyStyles}
                            isPrintMode={isPrintMode}
                            printModeType={printModeType}
                        />
                    );
                } else {
                    return (
                        <ListView
                            key={pkgGrp.title}
                            isCondensedView={isCondensedView}
                            title={`${pkgGrp.title}${pkgGrp.pkgPrice ? ` (${pkgGrp.pkgPrice})` : ''}`}
                            subPackages={pkgGrp.subPackages}
                            typographyStyles={typographyStyles}
                            isPrintMode={isPrintMode}
                            printModeType={printModeType}
                        />
                    );
                }
            })}
        </Grid>
    );
};

export default withGStyles(styleWrapper<Props>())(ContractPackages);
