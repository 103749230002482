import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

import { ProductTaxRateRequest, ProductTaxRateUX } from '../../shared/types';
import {
    convertTaxRateBracketsToString,
    convertStringToTaxRateBrackets,
} from '../../shared/goods_and_services/pricing';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 16px 24px',
        margin: ' auto',
        textAlign: 'center',
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        '@media (min-width: 400px)': {
            width: 360
        },
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
});

interface Props {
    isDialogOpen: boolean;
    existingTaxRate?: ProductTaxRateUX;
    closeDialog: () => void;
    onTaxRateSave: (taxRate: ProductTaxRateRequest) => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    name: string;
    bracketsStr: string;
    description: string;
    state: string;
    county: string;
    saveClicked: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'clearIcon' | 'header' | 'dialogContent' | 'bottomContent'
    | 'dialogPaper' | 'headerText'>;

const INITIAL_STATE: State = {
    name: '',
    bracketsStr: '',
    description: '',
    state: '',
    county: '',
    saveClicked: false,
};

class TaxRateDetails extends React.Component<StyledProps & DialogProps, State> {
    state: State = INITIAL_STATE;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { existingTaxRate } = nextProps;
        if (existingTaxRate) {
            this.setState({
                name: existingTaxRate.name,
                bracketsStr: convertTaxRateBracketsToString(existingTaxRate),
                description: existingTaxRate.description || '',
                state: existingTaxRate.state,
                county: existingTaxRate.county,
            });
        }
    }

    saveTaxRate = () => {
        const { onTaxRateSave } = this.props;
        const { name, bracketsStr, description, state, county } = this.state;

        const brackets = convertStringToTaxRateBrackets(bracketsStr);
        if (name && brackets && state && county) {
            this.handleClose();
            onTaxRateSave({
                name,
                brackets,
                description,
                state,
                county,
            });
        } else {
            this.setState({
                saveClicked: true,
            });
        }
    };

    handleClose = () => {
        const { closeDialog } = this.props;
        closeDialog();
        this.setState(INITIAL_STATE);
    };

    render() {
        const {
            classes,
            isDialogOpen,
            zIndex
        } = this.props;

        const { name, bracketsStr, description, state, county, saveClicked } = this.state;

        return (
            <div className={classes.root}>
                <Dialog
                    open={isDialogOpen}
                    onClose={this.handleClose}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.header,
                            classes.backgroundPrimary
                        )}
                    >
                        <ClearIcon
                            className={classNames(classes.clearIcon, classes.colorWhite)}
                            onClick={this.handleClose}
                        />
                        <Typography
                            component="p"
                            noWrap
                            className={classNames(classes.colorWhite, classes.headerText)}
                        >
                            Tax Rate Details
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid
                            container
                            className={classes.marginTop20}
                        >
                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="Name"
                                        name="name"
                                        required
                                        type="text"
                                        value={name}
                                        error={saveClicked && !name}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="Brackets"
                                        name="brackets"
                                        required
                                        type="text"
                                        value={bracketsStr}
                                        onChange={(e) => this.setState({ bracketsStr: e.target.value })}
                                        helperText={'Example: "0+@5.00" OR "0-1500@5.00, 1500.01+@6.00" (drop quotes)'}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="State"
                                        name="state"
                                        required
                                        type="text"
                                        value={state}
                                        onChange={(e) => this.setState({ state: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="County"
                                        name="county"
                                        required
                                        type="text"
                                        value={county}
                                        onChange={(e) => this.setState({ county: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="Description (optional)"
                                        name="description"
                                        type="text"
                                        value={description}
                                        onChange={(e) => this.setState({ description: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid
                                className={classes.bottomContent}
                                item
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={() => this.saveTaxRate()}
                                >
                                    SAVE
                                </Button>

                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default compose(withGStyles(styles), withFullScreen('xs'))(TaxRateDetails) as React.ComponentType<Props>;
