import classNames from 'classnames';

import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import { convertHexToRGBA } from '../../services/app.service';
import withGStyles from '../../styles/WithGStyles';

interface Props {
    icon: JSX.Element;
    iconClass?: string;
    isWhiteBackground?: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    iconContainer: {
        position: 'relative',
        display: 'flex',
        '& svg:first-of-type,span:first-of-type': {
            margin: '0 auto',
            fontSize: 'inherit',
            color: convertHexToRGBA(theme.palette.common.white, 0.75),
            filter: 'url(#inset-shadow)',
            WebkitFilter: 'url(#inset-shadow)'
        },
        '& svg:nth-of-type(2), span:nth-of-type(2)': {
            fontSize: 'inherit',
            color: convertHexToRGBA(theme.palette.primary.main, 0.25),
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
        },
    },
    colorContainer: {
        '& svg:nth-of-type(2), span:nth-of-type(2)': {
            fontSize: 'inherit',
            color: convertHexToRGBA(theme.palette.common.white, 0.6),
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translateX(-50%)'
        },
    }
});

type StyledProps = Props & WithStyles<'iconContainer' | 'colorContainer'>;

const InsetShadowIcon = (props: StyledProps) => {
    const { classes, icon, iconClass, isWhiteBackground } = props;

    const insetShadowElement = () => {
        return (
            <svg height={0} width={0}>
                <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
                    <feComponentTransfer in="SourceAlpha">
                        <feFuncA type="table" tableValues="1 0" />
                    </feComponentTransfer>
                    <feGaussianBlur stdDeviation="2" />
                    <feOffset dx="0" dy="4" result="offsetblur" />
                    <feFlood floodColor="rgb(0, 0, 0)" floodOpacity="1" result="color" />
                    <feComposite in2="offsetblur" operator="in" />
                    <feComposite in2="SourceAlpha" operator="in" />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                        <feMergeNode />
                    </feMerge>
                </filter>
            </svg>
        );
    };

    return (
        <span
            className={classNames(
                classes.iconContainer,
                isWhiteBackground && classes.colorContainer,
                iconClass
            )}
        >
            {icon}
            {icon}
            {insetShadowElement()}
        </span>
    );
};

export default withGStyles(styles)(InsetShadowIcon);