import * as React from 'react';
import { WorkOnButtonLink, EntityCaseRole } from '../../../shared/types';
import { openHelperInvitationDialog } from '../../../actions/Dialog.action';
import { useGDispatch } from '../../../types';
import { FamilyRoutePage, RouteBuilder, TaskRouteType } from '../../../services';
import { useGNavigate } from "../../common/hooks/useGNavigate";

interface WorkOnButtonHooks {
    onOpen?: (params: { caseName: string; funeralHomeKey: string; taskId?: number }) => void;
    onClose?: () => void;
}

export const useTaskWorkOnButton = () => {
    const [taskDialogToBeMounted, setTaskDialogToBeMounted] = React.useState<WorkOnButtonLink | null>(null);

    const dispatch = useGDispatch();
    const navigate = useGNavigate();

    const workOnButtonLookup: Record<WorkOnButtonLink, WorkOnButtonHooks> = {
        [WorkOnButtonLink.inviteHelpers]: {
            onOpen: () => openInvitationDialog(EntityCaseRole.admin)
        },
        [WorkOnButtonLink.deathCertificate]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoPage({
                ...params,
                page: FamilyRoutePage.QUESTIONS
            })
        },
        [WorkOnButtonLink.completeObituary]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoPage({
                ...params,
                page: FamilyRoutePage.OBITUARY
            })
        },
        [WorkOnButtonLink.specifyServiceDetails]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoPage({
                ...params,
                page: FamilyRoutePage.SERVICEDETAILS
            })
        },
        [WorkOnButtonLink.specifyCasketBearers]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoPage({
                ...params,
                page: FamilyRoutePage.CASKETBEARERS
            })
        },
        [WorkOnButtonLink.goodsAndServices]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoPage({
                ...params,
                page: FamilyRoutePage.CONTRACT
            })
        },
        [WorkOnButtonLink.signaturePacket]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => navigate(RouteBuilder.DocPacketPage(params)),
        },
        [WorkOnButtonLink.photoSlideshow]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
                taskId?: number;
            }) => params.taskId !== undefined &&
                navigate(RouteBuilder.TaskPage({
                    ...params,
                    taskId: params.taskId,
                    taskType: TaskRouteType.PHOTO_SLIDESHOW,
                }))
        },
        [WorkOnButtonLink.liveStream]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoRememberPage(params.caseName)
        },
        [WorkOnButtonLink.profileAndCoverPhotos]: {
            onOpen: (params: {
                caseName: string;
                funeralHomeKey: string;
            }) => gotoRememberPage(params.caseName)
        },
        [WorkOnButtonLink.scheduleServices]: {},
        [WorkOnButtonLink.freezeCredit]: {},
        [WorkOnButtonLink.closeSocialMedia]: {},
        [WorkOnButtonLink.contactSocialSecurity]: {},
        [WorkOnButtonLink.cancelOnlineAccounts]: {},
        [WorkOnButtonLink.cancelPhoneService]: {},
        [WorkOnButtonLink.forwardMail]: {},
        [WorkOnButtonLink.unclaimedProperty]: {},
        [WorkOnButtonLink.lifeInsurance]: {},
        [WorkOnButtonLink.exportToMessenger]: {},
        [WorkOnButtonLink.funeralReimbursement]: {},
    };

    const handleTaskDialogClose = (link: WorkOnButtonLink) => {
        const workOnFunctions = workOnButtonLookup[link];
        if (workOnFunctions.onClose) {
            workOnFunctions.onClose();
        } else {
            setTaskDialogToBeMounted(null);
        }
    };

    const onWorkOnButtonClicked = (params: {
        link: WorkOnButtonLink;
        caseName: string;
        funeralHomeKey: string;
        taskId?: number;
    }) => {
        const { link, caseName, funeralHomeKey, taskId } = params;
        const workOnFunctions = workOnButtonLookup[link];
        if (workOnFunctions.onOpen) {
            workOnFunctions.onOpen({
                caseName,
                funeralHomeKey,
                taskId,
            });
        } else {
            setTaskDialogToBeMounted(link);
        }
    };

    const gotoRememberPage = (caseName: string) => {
        navigate(RouteBuilder.RememberPage(caseName));
    };

    const openInvitationDialog = (currentTab: EntityCaseRole = EntityCaseRole.admin) => {
        dispatch(openHelperInvitationDialog({
            zIndex: 1322,
            defaultTab: currentTab,
        }));
    };

    const gotoPage = (params: {
        caseName: string;
        funeralHomeKey: string;
        page: FamilyRoutePage;
    }) => {
        navigate(RouteBuilder.FamilyPage(params));
    };

    return {
        taskDialogToBeMounted,
        onWorkOnButtonClicked,
        handleTaskDialogClose,
    };
};

type WorkOnButtonClicked = (params: {
    link: WorkOnButtonLink;
    caseName: string;
    funeralHomeKey: string;
    taskId?: number;
}) => void;

export interface TaskWorkOnButtonProps {
    handleTaskDialogClose: (link: WorkOnButtonLink) => void;
    onWorkOnButtonClicked: WorkOnButtonClicked;
    taskDialogToBeMounted: WorkOnButtonLink | null;
}

export const withTaskWorkOnButton = <P extends object>(
    Component: React.ComponentType<P & TaskWorkOnButtonProps>
) => React.memo(function WithTaskWorkOnButton(props: P & TaskWorkOnButtonProps) {

    const { taskDialogToBeMounted, onWorkOnButtonClicked, handleTaskDialogClose } = useTaskWorkOnButton();

    return (
        <Component
            {...props}
            taskDialogToBeMounted={taskDialogToBeMounted}
            onWorkOnButtonClicked={onWorkOnButtonClicked}
            handleTaskDialogClose={handleTaskDialogClose}
        />
    );
});