import { postToAPI, putToAPI, advancedAPIRequest } from '.';
import {
    EntitySummary,
    PhotoTransformationsType,
    SessionResult,
    VisitorCaseRequest,
    VisitorCreateRequest,
    GuestListUser,
    getDisplayRelationshipForPerson,
    UserProfile,
    CaseEntityUX,
    getCaseEntityByCaseName,
} from '../shared/types';
import { setAppSnackbar } from './AppSnackbar.action';
import { registerAppError } from './errors';
import { uploadPhoto } from './Photo.action';
import { setLoginError, setLoginSuccess, initializeUser, updateLoginUser, userLoggedIn } from './UserSession.action';
import { log } from '../logger';
import { AppDispatch } from '../store';

export const VISITOR_ADDED_TO_CASE = 'VISITOR_ADDED_TO_CASE';
interface VisitorAddedToCase {
    type: typeof VISITOR_ADDED_TO_CASE;
    visitor: GuestListUser;
}

function visitorAddedToCase(
    user: UserProfile,
    newCaseEntityId: number,
    visitorRequest: VisitorCreateRequest | VisitorCaseRequest,
): VisitorAddedToCase {
    return {
        type: VISITOR_ADDED_TO_CASE,
        visitor: {
            fname: user.fname,
            entity_id: user.entity_id,
            case_entity_id: newCaseEntityId,
            user_id: user.user_id,
            photo: user.photo,
            photo_transformations: user.photo_transformations,
            relationship: getDisplayRelationshipForPerson(visitorRequest) || '',
            is_pending_decision: true,
            created_time: new Date(),
        },
    };
}

export const SET_VISITOR_PHOTO_SAVING = 'SET_VISITOR_PHOTO_SAVING';
interface SetVisitorPhotoSaving {
    type: typeof SET_VISITOR_PHOTO_SAVING;
    entityId: number;
    isPhotoSaving: boolean;
}

function setVisitorPhotoSaving(entityId: number, isPhotoSaving: boolean): SetVisitorPhotoSaving {
    return {
        type: SET_VISITOR_PHOTO_SAVING,
        entityId,
        isPhotoSaving,
    };
}

export const SET_VISITOR = 'SET_VISITOR';
interface SetVisitor {
    type: typeof SET_VISITOR;
    visitor: EntitySummary;
}

function setVisitor(visitor: EntitySummary): SetVisitor {
    return {
        type: SET_VISITOR,
        visitor,
    };
}

export function registerNewVisitor(caseName: string, visitorCreateRequest: VisitorCreateRequest) {
    return async (dispatch: AppDispatch): Promise<boolean | null> => {
        try {
            VisitorCreateRequest.fromRequest(visitorCreateRequest);
        } catch (ex) {
            log.warn('Failed to validate VisitorCreateRequest', { ex, visitorCreateRequest });
            return false;
        }
        try {
            const response = await advancedAPIRequest(
                `app/remember/${caseName}/visitor`,
                'POST',
                visitorCreateRequest,
                dispatch,
            );
            const result = response ? await response.json() : null;
            const sessionResult: SessionResult | null = result && response && response.ok ? result : null;
            // < SessionResult & LoginError >
            if (sessionResult === null || result.message || result.Message) {
                let errorMessage = 'Sorry, unable to login at this time';
                if (result && result !== null) {
                    errorMessage = result.message ? result.message : errorMessage;
                    errorMessage = result.Message ? result.Message : errorMessage;
                }
                // dispatch(setAppSnackbar(errorMessage, 'error'));
                dispatch(setLoginSuccess(false));
                dispatch(setLoginError(sessionResult === null ? errorMessage : result.message || result.Message));
                return false;
            } else {
                initializeUser(sessionResult);
                dispatch(userLoggedIn(sessionResult.data));
                const newCaseEntity = getCaseEntityByCaseName(sessionResult.data.userData, caseName);
                if (newCaseEntity) {
                    dispatch(
                        visitorAddedToCase(
                            sessionResult.data.userData,
                            newCaseEntity.case_entity_id,
                            visitorCreateRequest,
                        ),
                    );
                } else {
                    log.warn('Somehow the new case entity could not be found', { sessionResult, caseName });
                }
                dispatch(setLoginSuccess(true));
            }
        } catch (ex) {
            return false;
        }
        return true;
    };
}

export function registerVisitorToCase(caseUuid: string, user: UserProfile, visitorCaseRequest: VisitorCaseRequest) {
    return async (dispatch: AppDispatch): Promise<boolean | null> => {
        try {
            VisitorCaseRequest.fromRequest(visitorCaseRequest);
        } catch (ex) {
            log.warn('Failed to validate VisitorCreateRequest', { visitorCaseRequest, ex });
            return false;
        }
        const response = await putToAPI<{ allCases: CaseEntityUX[]; created: CaseEntityUX }>(
            `api/case/${caseUuid}/visitor/${user.entity_id}`,
            visitorCaseRequest,
            dispatch,
        );
        if (!response) {
            dispatch(setAppSnackbar('Sorry, unable record your relationship this time', 'error'));
            return false;
        } else {
            dispatch(visitorAddedToCase(user, response.created.case_entity_id, visitorCaseRequest));
            dispatch(updateLoginUser({ cases: response.allCases }));
        }
        return true;
    };
}

export function updateVisitorPhoto(entityId: number, photo: string, transformations: PhotoTransformationsType) {
    return async (dispatch: AppDispatch): Promise<EntitySummary | null> => {
        // need to upload photo to Cloudinary first
        dispatch(setVisitorPhotoSaving(entityId, true));

        const path = `api/entity/${entityId}/photo`;
        const gatherSignatureURL = `${path}/signature`;
        const cloudinaryResult = await uploadPhoto(photo, gatherSignatureURL, dispatch);
        if (cloudinaryResult) {
            const updatedVistor = await postToAPI<EntitySummary>(
                path,
                {
                    public_id: cloudinaryResult.public_id,
                    width: cloudinaryResult.width,
                    height: cloudinaryResult.height,
                    transformations,
                },
                dispatch,
            );
            if (updatedVistor) {
                dispatch(setVisitor(updatedVistor));

                // Create an event for pages to listen for
                const event = new Event('gather.user_profile.photo_update', { bubbles: true, cancelable: true });
                document.dispatchEvent(event);
                dispatch(setVisitorPhotoSaving(entityId, false));
                return updatedVistor;
            } else {
                dispatch(registerAppError('Unable to set photo.'));
            }
        } else {
            dispatch(registerAppError('Unable to upload photo.'));
        }
        dispatch(setVisitorPhotoSaving(entityId, false));
        return null;
    };
}

export function updateVistorSubscription(entityId: number, subscriptionUUID: string, value: boolean) {
    return async (dispatch: AppDispatch): Promise<boolean | null> => {
        const response = await putToAPI<EntitySummary>(
            `api/entity/${entityId}/subscription/${subscriptionUUID}`,
            { value },
            dispatch,
        );
        if (response) {
            dispatch(setVisitor(response));
            return true;
        }
        return false;
    };
}

export type VisitorAction = SetVisitor | SetVisitorPhotoSaving | VisitorAddedToCase;
