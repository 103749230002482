import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';

import { GatherCasePublic } from '../../../../shared/types';
import { convertHexToRGBA, getIntercomTargetProp, getRememberURLForSharing, isTouchDevice } from '../../../../services';

import FacebookMessengerIconSVG from '../../svg/FacebookMessengerIconSVG';
import ShareIconSVG from '../../svg/ShareIconSVG';
import TextSmsSVG from '../../svg/TextSmsSVG';
import QRCodeSVG from '../../svg/QRCodeSVG';
import SocialIconPopper from './SocialIconPopper';
import { TransitionGroup } from 'react-transition-group';
import {
    MESSANGER_COLOR,
    ShareOptionSettings,
    SHARE_COLOR,
    QRCODE_COLOR,
    SMS_COLOR,
    TWITTER_COLOR,
    WHATSAPP_COLOR,
    shareOptionRenderOrder,
    FACEBOOK_COLOR,
    EMAIL_COLOR,
    LINKEDIN_COLOR,
    LINK_COLOR
} from '../utils';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRules, StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { buildQRCodeLink } from '../../utils';

const styles: StyleRulesCallback<Theme, Props, Classes> = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 'auto',
        width: '100%',
        marginBottom: 24
    },
    textField: {
        margin: '0 8px 16px',
    },
    buttonSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    anchorTag: {
        cursor: 'pointer',
        height: 36,
        padding: '36px 0',
        textDecoration: 'none'
    },
    customHref: {
        textDecoration: 'none',
        display: 'inline-block',
        color: 'inherit',
    },
    messageTextField: {},
    main: {},
});

type Classes = 'root' | 'main' | 'messageTextField'
    | 'buttonSection' | 'textField' | 'anchorTag' | 'customHref';
type StyledProps = WithStyles<Classes>;

export const optionStyles = <T extends object>(): StyleRules<T, OptionClasses> => ({
    button: {
        border: `1px solid`,
        margin: '4px 16px',
        width: 40,
        height: 40,
        minWidth: 'fit-content',
        cursor: 'pointer',
        '@media (min-width: 440px)': {
            margin: '8px 16px',
            width: 48,
            height: 48
        },
        '@media (min-width: 960px)': {
            margin: '0 16px',
            width: 60,
            height: 60
        },
        '& svg': {
            width: 22,
            height: 22,
            '@media (min-width: 440px)': {
                width: 24,
                height: 24,
            },
            '@media (min-width: 960px)': {
                width: 32,
                height: 32,
            }
        },
        '&$MESSENGER': {
            '& svg': {
                width: 20,
                height: 20,
                fill: MESSANGER_COLOR,
                '@media (min-width: 440px)': {
                    width: 22,
                    height: 22,
                },
                '@media (min-width: 960px)': {
                    width: 28,
                    height: 28,
                }
            },
            '&$xs': {
                '& svg': {
                    width: `20px !important`,
                    height: `20px !important`,
                }
            }
        },
        '&$QRCODE': {
            border: 'none',
            '& svg': {
                width: 24,
                height: 24,
                '@media (min-width: 440px)': {
                    width: 30,
                    height: 30,
                },
                '@media (min-width: 960px)': {
                    width: 46,
                    height: 46,
                }
            },
            '&"hover svg': {
                color: 'black',
            }
        },
        '&$xs': {
            margin: '0 4px !important',
            width: `40px !important`,
            height: `40px !important`,
            '& svg': {
                width: `22px !important`,
                height: `22px !important`,
            }
        }
    },
    FACEBOOK: {
        color: FACEBOOK_COLOR,
        border: `1px solid`,
        '&:hover': {
            backgroundColor: convertHexToRGBA(FACEBOOK_COLOR, 0.08)
        },
    },
    MESSENGER: {
        color: MESSANGER_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(MESSANGER_COLOR, 0.08)
        },
    },
    EMAIL: {
        color: EMAIL_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(EMAIL_COLOR, 0.08)
        },
    },
    WHATSAPP: {
        color: WHATSAPP_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(WHATSAPP_COLOR, 0.08)
        },
    },
    TWITTER: {
        color: TWITTER_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(TWITTER_COLOR, 0.08)
        },
    },
    LINKEDIN: {
        color: LINKEDIN_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(LINKEDIN_COLOR, 0.08)
        },
    },
    LINK: {
        color: LINK_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(LINK_COLOR, 0.08)
        },
    },
    SHARE: {
        color: SHARE_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(SHARE_COLOR, 0.08)
        },
    },
    QRCODE: {
        color: QRCODE_COLOR,
    },
    SMS: {
        fill: SMS_COLOR,
        color: SMS_COLOR,
        '&:hover': {
            backgroundColor: convertHexToRGBA(`#9be473`, 0.08)
        },
    },
    xs: {}
});

type OptionClasses = 'button' | 'FACEBOOK' | 'MESSENGER' | 'TWITTER' | 'WHATSAPP'
    | 'LINKEDIN' | 'EMAIL' | 'SMS' | 'SHARE' | 'LINK' | 'QRCODE' | 'xs';
export type OptionStyledProps = WithStyles<OptionClasses>;

export interface PopperListType {
    id: string;
    listContent: string;
}

export interface PopperContentType {
    id: number;
    title: string;
    actions?: string;
    list: PopperListType[];
    icon: JSX.Element;
    label: string;
    footer?: string;
    link?: string;
    isLink?: boolean;
}

interface Props {
    shareOptionSettings: ShareOptionSettings;
    activeCase: GatherCasePublic;
    setAppSnackbarSuccess: (message: string) => void;
}

export type ShareOptionType = 'QRCODE'
    | 'FACEBOOK'
    | 'MESSENGER'
    | 'TWITTER'
    | 'WHATSAPP'
    | 'LINKEDIN'
    | 'EMAIL'
    | 'SMS'
    | 'SHARE'
    | 'LINK';

export const isShareOptionType = (value: string): value is ShareOptionType => {
    return (
        value === 'QRCODE' ||
        value === 'FACEBOOK' ||
        value === 'MESSENGER' ||
        value === 'TWITTER' ||
        value === 'WHATSAPP' ||
        value === 'LINKEDIN' ||
        value === 'EMAIL' ||
        value === 'SMS' ||
        value === 'SHARE' ||
        value === 'LINK'
    );
};
interface State {
    popperState: 'open' | 'close';
    popperAnchorEle: HTMLElement | null;
    shareOptionType: ShareOptionType | null;
}

export const getOptionContent = (
    option: ShareOptionType | null,
    activeCase: GatherCasePublic,
    shareOptionSettings: ShareOptionSettings
) => {
    const webLink = encodeURI(getRememberURLForSharing(activeCase.name));
    const qrLink = buildQRCodeLink({ caseName: activeCase.name, download: true });
    const webComp = encodeURIComponent(webLink);

    const { REACT_APP_FB_APP_ID } = process.env;

    const mailToEmail = '';
    const mailToSubject = `Remembering ${activeCase.full_name}`;

    const fbPoperContent: PopperContentType = {
        id: 1,
        title: 'Post to Facebook',
        actions: 'This option allows you to take the following actions on Facebook:',
        list: [
            {
                id: 'List_1',
                listContent: '- Share to News Feed or Story'
            },
            {
                id: 'List_2',
                listContent: '- Share on a Friend\'s Timeline'
            },
            {
                id: 'List_3',
                listContent: '- Share in a group or event'
            },
            {
                id: 'List_4',
                listContent: '- Share in a private message'
            }
        ],
        icon: <FacebookIcon />,
        label: 'Post to FaceBook',
        footer: 'You must have a Facebook Account to use this option',
        link: `http://www.facebook.com/sharer.php?u=${webComp}`
    };

    const messengerPoperContent: PopperContentType = {
        id: 1,
        title: 'Share via Messenger',
        list: [{
            id: 'List_1',
            listContent: 'Share directly with one or more facebook friends via Facebook Messenger'
        }],
        icon: <FacebookMessengerIconSVG color={MESSANGER_COLOR} />,
        label: 'Send to Messenger',
        footer: 'You must have a Messenger Account to use this option',
        // link: `fb-messenger://share/?link=${webComp}`  // for mobile app's
        // link: `https://m.me/?&amp;link=${webComp}`
        link: `http://facebook.com/dialog/send?link=${webComp}&app_id=${REACT_APP_FB_APP_ID || ''}`
    };

    const twitterPoperContent: PopperContentType = {
        id: 1,
        title: 'Post to Twitter',
        list: [
            {
                id: 'List_1',
                listContent: 'Tweet out the link so your followers can visit this page.'
            }
        ],
        icon: <TwitterIcon />,
        label: 'Send to Twitter',
        footer: 'You must have a Twitter Account to use this option',
        link: `https://twitter.com/share?url=${webComp}`
    };

    const whatsAppContent: PopperContentType = {
        id: 1,
        title: 'Send to WhatsApp',
        list: [
            {
                id: 'List_1',
                listContent: 'Share directly with one or more of your WhatsApp friends.'
            }
        ],
        icon: <WhatsAppIcon />,
        label: 'Send to WhatsApp',
        footer: 'You must have a WhatsApp Account to use this option',
        link: `https://wa.me/?text=${webComp}`
    };

    const linkedInContent: PopperContentType = {
        id: 1,
        title: 'Post to LinkedIn',
        list: [
            {
                id: 'List_1',
                listContent: `Share either in a post on LinkedIn or share directly with one or more of your 
                LinkedIn Connections.`
            }
        ],
        icon: <LinkedInIcon />,
        label: 'Send to LinkedIn',
        footer: 'You must have a LinkedIn Account to use this option',
        link: `http://www.linkedin.com/shareArticle?mini=true&url=${webComp}`
    };

    const emailContent: PopperContentType = {
        id: 1,
        title: 'Post to Email',
        list: [
            {
                id: 'List_1',
                listContent: 'Send this page\'s link to your existing email contacts.'
            }
        ],
        icon: <EmailOutlinedIcon />,
        label: 'Send to Email',
        footer: 'You must have an email account to use this option',
        link: `mailto:${mailToEmail}?subject=${mailToSubject}&body=${webComp}`,
    };

    const smsContent: PopperContentType = {
        id: 1,
        title: 'Send to SMS',
        list: [
            {
                id: 'List_1',
                listContent: 'Send this link directly to one or more contacts in your phone.'
            }
        ],
        icon: <TextSmsSVG color={SMS_COLOR} />,
        label: 'Send to SMS',
        footer: 'This option only works on your phone',
        link: `sms:?&body=${webComp}`,
    };

    const shareContent: PopperContentType = {
        id: 1,
        title: 'Share with other Apps',
        list: [
            {
                id: 'List_1',
                listContent: `This option will give you access to share this link via other common apps 
                installed on your device.`
            }
        ],
        icon: <ShareIconSVG color={SHARE_COLOR} />,
        label: 'Send to SHARE',
        footer: 'This option only works on your phone',
    };

    const linkContent: PopperContentType = {
        id: 1,
        title: 'Copy Link',
        list: [
            {
                id: 'List_1',
                listContent: 'Copy this page\'s link to share with others however you prefer.'
            }
        ],
        isLink: true,
        icon: <LinkIcon />,
        label: 'Copy Link',
    };

    const qrcodeContent: PopperContentType = {
        id: 1,
        title: 'Copy QR Code',
        list: [{
            id: `List_1`,
            listContent: `Download ${activeCase.display_fname}'s unique QR Code and place it on any physical product.`,
        }],
        icon: <QRCodeSVG color={QRCODE_COLOR} />,
        label: 'Download QR Code',
        footer: `Others can simply point their cameras at the ` +
            `QR code to link back to ${activeCase.display_fname}'s remember page`,
        link: qrLink,
    };

    switch (option) {
        case 'FACEBOOK':
            return {
                content: fbPoperContent,
                color: shareOptionSettings[option].color
            };
        case 'MESSENGER':
            return {
                content: messengerPoperContent,
                color: shareOptionSettings[option].color
            };
        case 'TWITTER':
            return {
                content: twitterPoperContent,
                color: shareOptionSettings[option].color
            };
        case 'WHATSAPP':
            return {
                content: whatsAppContent,
                color: shareOptionSettings[option].color
            };
        case 'LINKEDIN':
            return {
                content: linkedInContent,
                color: shareOptionSettings[option].color
            };
        case 'EMAIL':
            return {
                content: emailContent,
                color: shareOptionSettings[option].color
            };
        case 'SMS':
            return {
                content: smsContent,
                color: shareOptionSettings[option].color
            };
        case 'SHARE':
            return {
                content: shareContent,
                color: shareOptionSettings[option].color
            };
        case 'LINK':
            return {
                content: linkContent,
                color: shareOptionSettings[option].color
            };
        case 'QRCODE':
            return {
                content: qrcodeContent,
                color: shareOptionSettings[option].color
            };
        default:
            return null;
    }
};

type CombinedProps = Props & StyledProps & OptionStyledProps;
class ShareLinkSection extends React.Component<CombinedProps, State> {
    state: State = {
        popperAnchorEle: null,
        shareOptionType: null,
        popperState: 'close'
    };

    openLink = (link: string | undefined) => {
        window.open(
            link,
            '_blank',
            'location=yes,height=570,width=520,scrollbars=yes,status=yes'
        );
    };

    renderPopper = () => {
        const { activeCase, setAppSnackbarSuccess, shareOptionSettings } = this.props;
        const { popperAnchorEle, shareOptionType } = this.state;

        if (!shareOptionSettings) {
            return;
        }

        return (
            <SocialIconPopper
                clickAwayListener={e => this.closePopper()}
                popperAnchorEle={popperAnchorEle}
                popperContent={getOptionContent(shareOptionType, activeCase, shareOptionSettings)}
                activeCase={activeCase}
                setAppSnackbarSuccess={setAppSnackbarSuccess}
                type={shareOptionType}
                zIndex={1320}
            />
        );
    };

    openPopper = (event: React.MouseEvent<HTMLElement>, shareOptionType: ShareOptionType, timeout?: number) => {
        const target = event.currentTarget;

        this.setState({ popperState: 'open' });

        setTimeout(
            () => {
                const { popperState } = this.state;
                if (timeout && popperState === 'close') {
                    return;
                }

                this.setState({
                    shareOptionType,
                    popperAnchorEle: target,
                });
            },
            timeout
        );
    };

    closePopper = () => {
        this.setState({
            popperState: 'close',
            popperAnchorEle: null,
        });
    };

    render() {
        const { classes, activeCase, shareOptionSettings } = this.props;

        if (!shareOptionSettings) {
            return null;
        }

        const timeout = 400;
        const isDesktop = !isTouchDevice();
        const activeShareOptions = shareOptionRenderOrder.reduce(
            (acc, key) => {

                // messenger doesn't work in all browsers
                if (isDesktop && key === 'MESSENGER') {
                    return acc;
                }

                if (shareOptionSettings[key].enabled) {
                    acc[key] = shareOptionSettings[key];
                }
                return acc;
            },
            {}
        );
        return (
            <Grid item xs={12} className={classes.root}>
                <TransitionGroup className={classes.buttonSection}>
                    {Object.keys(activeShareOptions).map((o: string) => {
                        if (isShareOptionType(o)) {
                            const optionContent = getOptionContent(o, activeCase, shareOptionSettings);
                            const icon = optionContent && optionContent.content && optionContent.content.icon || null;

                            return (
                                <Zoom
                                    key={o}
                                    in
                                    appear
                                    enter
                                    exit
                                    mountOnEnter
                                    unmountOnExit
                                    timeout={400}
                                >
                                    <IconButton
                                        className={classNames(
                                            classes.button,
                                            classes[o]
                                        )}
                                        aria-label={o}
                                        aria-haspopup="true"
                                        onClick={e => this.openPopper(e, o)}
                                        onMouseEnter={e => this.openPopper(e, o, timeout)}
                                        onTouchStart={e => this.closePopper()}
                                        onMouseLeave={e => this.closePopper()}
                                        {...getIntercomTargetProp(
                                            `RememberPage-SocialShareButton-${optionContent?.content.title as string}`)}
                                    >
                                        {icon}
                                    </IconButton>
                                </Zoom>
                            );
                        }

                        return <></>;
                    })}
                </TransitionGroup>

                {this.renderPopper()}
            </Grid>
        );
    }

}

const componentStyles = optionStyles<Props>();
export default withStyles(theme => ({ ...styles(theme), ...componentStyles }))(ShareLinkSection);
