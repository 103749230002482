/* eslint-disable max-len */
import classNames from 'classnames';
/* tslint:disable */
export default function UploadPhotosSVG({ themeFillClass }: { themeFillClass: string }) {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.75 100.9">
            <defs>
                <style type="text/css" dangerouslySetInnerHTML={{
                    __html: `

                        .upload-photo-cls-3{fill: #fff;}
                        .upload-photo-cls-4{fill: #303030; opacity: 1 !important;}
                        .upload-photo-cls-5{opacity: 0.35;}
                        .upload-photo-cls-6{opacity: 0.55;}
                        .upload-photo-cls-7{opacity: 0.15;}
                        .upload-photo-cls-8{opacity: 0.7;}
                        .upload-photo-cls-9{fill: #5d94f2;}
                        .upload-photo-cls-10{opacity: 0.45;}
                    `}} />
            </defs>
            <polygon id="svgThemeColor" className={classNames(themeFillClass)} points="50.96 97.01 27.63 97.01 27.86 99.64 50.96 99.64 50.96 97.01" />
            <path id="svgThemeColor-2" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M122.85,84.71,124.21,100s0,0,0,0v0a.59.59,0,0,1,0,.1.62.62,0,0,1,0,.12.61.61,0,0,1-.06.11.63.63,0,0,1-.07.1l-.09.08-.1.07-.12,0-.09,0H58.38l.22,2.44a.63.63,0,0,1,0,.25.59.59,0,0,1,0,.07h69.06L126,84.71h-3.17Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-3" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M47.4,55.28v0a.6.6,0,0,1,0-.1.62.62,0,0,1,0-.12.6.6,0,0,1,.06-.11.62.62,0,0,1,.07-.1l.09-.08.1-.07.12,0,.09,0H69.59a.4.4,0,0,0,.31,0h6.71l-.3-3.21H43.62l.73,8.23h3.43l-.38-4.34S47.4,55.29,47.4,55.28Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-3" d="M117.94,84.71H79.39L76.91,57.8l-.18-1.92H69.9a.4.4,0,0,0-.31,0H48.72L49,59.65H69.28l3,33.7H57.73l.54,6.11h64.63l-1.31-14.75Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-4" d="M69.59,55.89a.4.4,0,0,1,.31,0h6.83l-.12-1.26H69.9a.4.4,0,0,1-.31,0H48l-.09,0-.12,0-.1.07-.09.08a.62.62,0,0,0-.07.1.6.6,0,0,0-.06.11.62.62,0,0,0,0,.12.6.6,0,0,0,0,.1v0s0,0,0,0l.38,4.34H49l-.33-3.76Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-4" d="M44.35,59.65l-.73-8.23h32.7l-.12-1.26H43.43a.62.62,0,0,0-.26.06.61.61,0,0,0-.87.63l.78,8.81Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-4" d="M147.31,103.47H129l-1.67-18.77H126l1.67,18.77H58.63a.59.59,0,0,0,0-.07.63.63,0,0,0,0-.25l-.22-2.44h65.26l.09,0,.12,0,.1-.07.09-.08a.63.63,0,0,0,.07-.1.61.61,0,0,0,.06-.11.62.62,0,0,0,0-.12.59.59,0,0,0,0-.1v0s0,0,0,0l-1.36-15.33h-1.26l1.31,14.75H58.27l-.54-6.11H56.46l.84,9.42H36.85l-2.7-29.51h7L41.08,72H34l-.26-2.81h7l-.12-1.26H33l-.09,0-.13,0-.09.06-.1.08-.06.09a.62.62,0,0,0-.05.08l-1.22,1.23,0,0a.61.61,0,0,0-.07.11.74.74,0,0,0-.09.34s0,0,0,0l3.45,38a.6.6,0,0,0,0,.1.62.62,0,0,0,0,.1.6.6,0,0,0,.08.11l.06.08a.63.63,0,0,0,.13.08l.07,0a.62.62,0,0,0,.24,0H147.31a.63.63,0,0,0,.63-.63v-3.9A.63.63,0,0,0,147.31,103.47Zm-89.9.56.3,3.34H37.27L37,104Zm-21.65,3.34L32.39,70.22l.2-.2L36,107.37Zm23.21,0-.23-2.64h87.94v2.64H59Z" transform="translate(-31.11 -7.73)" />
            <polygon className="upload-photo-cls-3" points="15.21 85.62 11.94 85.62 10.09 65.53 3.04 65.53 5.74 95.05 26.19 95.05 25.36 85.62 15.21 85.62" />
            <polygon id="svgThemeColor-4" data-name="svgThemeColor" className={classNames(themeFillClass)} points="1.48 62.3 1.28 62.5 4.65 99.64 4.9 99.64 1.48 62.3" />
            <circle className="upload-photo-cls-4" cx="16.92" cy="98.01" r="0.86" />
            <path id="svgThemeColor-5" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M140.21,42.94A7.83,7.83,0,0,1,136.34,48v3.22a19.61,19.61,0,0,1,7.2,4l-1.31-14.75A7.26,7.26,0,0,1,140.21,42.94Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-6" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M81.66,29.43a12.56,12.56,0,0,1,9.14.64A11.84,11.84,0,0,1,97,36.63c.59,1.55,1.51,10,1.25,11.67l-5.59.07a7,7,0,0,1-4.15,3.93v2.24a15,15,0,0,1,5.93,2.12l.44-.86,0,0c.08-.17.15-.35.24-.51a4.42,4.42,0,0,1,.33-.55C97,50,101.52,48,107,47.42a8.25,8.25,0,0,1-6.28-8V36.33c-2.12-1.82-2.82-7.56-.65-10,1.28-1.47,5-3,9-3,1.85,0,8.41.47,9.85,4.59,1.81,5.19-.19,11.5-1.66,12.31l0,0A8.26,8.26,0,0,1,113,46.6v.68c6.93.31,14.2,2,18.57,2.88V48.9a7.81,7.81,0,0,1-6.78-7.41h0V35.95a12.92,12.92,0,0,0-1.31-1.51,5.61,5.61,0,0,1,.78-7.79A10.85,10.85,0,0,1,129.4,25l3.44-.42a16.09,16.09,0,0,1,5.16.54,8.19,8.19,0,0,1,3,1.66l-1.69-19h-67l2.66,28.82a15.91,15.91,0,0,1,3.35-5.17A8,8,0,0,1,81.66,29.43Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-7" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M76.32,51.41l.3,3.21.12,1.26.18,1.92a15.06,15.06,0,0,1,7.26-3.21V52.4a7,7,0,0,1-4.34-3.86l-3.77,0,.14,1.56Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-8" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M136.34,51.69h0a2.37,2.37,0,0,1-4.69,0h0V50.16c-4.37-.9-11.64-2.56-18.57-2.88V49.7a15.79,15.79,0,0,1,12.34,15.4V84.71h20.82l-2.63-29.49a19.61,19.61,0,0,0-7.2-4Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-9" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M96,54.18A2,2,0,0,1,97.64,54a2.19,2.19,0,0,1,1,.7,1.11,1.11,0,0,1,.13.21l0,0a.39.39,0,0,1,.05.3c.45-.74,2.18-3.47,3.79-4.31a2.6,2.6,0,0,0,.32-.19,15.66,15.66,0,0,1,4.94-1.37V47.59a8.26,8.26,0,0,1-.93-.18C101.52,48,97,50,95.43,54.71A1.93,1.93,0,0,1,96,54.18Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-10" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M132.84,24.54,129.4,25a10.85,10.85,0,0,0-5.14,1.69,5.61,5.61,0,0,0-.78,7.79,12.92,12.92,0,0,1,1.31,1.51V32c1.72,1.22,3.64,1,5.75,1a52.47,52.47,0,0,0,7-.38c.5,1.66,1.15,4.1,2.69,5.14l.2.11v3.66h0a7.82,7.82,0,0,1-.2,1.45,7.26,7.26,0,0,0,2-2.48L141,26.74a8.19,8.19,0,0,0-3-1.66A16.09,16.09,0,0,0,132.84,24.54Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-11" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M131.58,51.69h0a2.37,2.37,0,0,0,4.69,0h0V48a7.8,7.8,0,0,0,4.06-6.53h0V37.83l-.2-.11c-1.54-1-2.18-3.47-2.69-5.14a52.47,52.47,0,0,1-7,.38c-2.11,0-4,.23-5.75-1v9.52h0a7.81,7.81,0,0,0,6.78,7.41v2.79Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-12" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M97.26,58.94l.19.2.13-.81C97.46,58.55,97.36,58.75,97.26,58.94Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-13" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M113,50.33a2.55,2.55,0,0,1-5.11,0V49.4A15.66,15.66,0,0,0,103,50.77a2.6,2.6,0,0,1-.32.19c-1.62.84-3.34,3.57-3.79,4.31,0,.1,0,.19,0,.25-.06.52-.12,1-.19,1.56a19.87,19.87,0,0,1-.57,2.74,15.07,15.07,0,0,1,3.55,9.73h-.26c0,3.77,1.15,7.68,3.08,7.68.84,0,2.82-1.33,3.08-13.7h0c0-.11,0-.23,0-.34a19,19,0,0,1,.13-3.12,1.89,1.89,0,0,1,.11-.48c0-.09.1-.18.19-.19a.27.27,0,0,1,.24.16,1.4,1.4,0,0,1,.13.7l0,1.53c.1-.34.22-.68.29-.92.26-.95,1-2.87,1.39-2.76s-.36,2.07-.8,3.45l.08,0,.1,0c.1-.34.23-.69.29-.93.26-.95,1-2.87,1.39-2.76s-.51,2.57-.95,3.91l.17.05c.09-.28.18-.55.23-.75.26-.95,1-2.87,1.39-2.76s-.45,2.35-.89,3.72l.14,0c.32-1,1-2.5,1.28-2.4s-.59,2.83-1,4.1l0,0c0,.18-.09.37-.15.55A4.22,4.22,0,0,1,111,65c-.27,8.34-1.39,15.72-6.59,15.72-4.68,0-6.47-5.52-6.62-10.53V84.71h27.54V65.1A15.79,15.79,0,0,0,113,49.7Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-14" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M107.9,49.4v.93a2.55,2.55,0,0,0,5.11,0V46.6a8.26,8.26,0,0,0,4.16-6.36c0-.27,0-.55,0-.83V35.07a26,26,0,0,1-6.37-5.72,28.07,28.07,0,0,1-10.15,2.82v7.24a8.27,8.27,0,0,0,7.2,8.18Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-15" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} d="M110.85,29.35a26,26,0,0,0,6.37,5.72v4.34c0,.28,0,.56,0,.83l0,0c1.47-.81,3.47-7.11,1.66-12.31-1.44-4.12-8-4.59-9.85-4.59-4,0-7.69,1.52-9,3-2.17,2.47-1.47,8.21.65,10V32.17A28.07,28.07,0,0,0,110.85,29.35Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-16" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M97.26,58.94c.1-.19.21-.39.31-.61l-.13.81c.21.22.42.44.61.67a19.87,19.87,0,0,0,.57-2.74c.07-.52.13-1,.19-1.56,0-.06,0-.16,0-.25a.39.39,0,0,0-.05-.3l0,0a1.11,1.11,0,0,0-.13-.21,2.19,2.19,0,0,0-1-.7,2,2,0,0,0-1.68.15,1.93,1.93,0,0,0-.53.52,4.42,4.42,0,0,0-.33.55c-.09.17-.16.34-.24.51l0,0-.44.86A15.19,15.19,0,0,1,97.26,58.94Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-17" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} d="M79.24,45.76V39H93.13v6.74a6.92,6.92,0,0,1-.52,2.62l5.59-.07c.27-1.67-.66-10.12-1.25-11.67a11.84,11.84,0,0,0-6.15-6.56,12.56,12.56,0,0,0-9.14-.64,8,8,0,0,0-3.37,1.93,15.91,15.91,0,0,0-3.35,5.17l1.11,12.05,3.77,0A6.91,6.91,0,0,1,79.24,45.76Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-18" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M79.39,84.71H97.81V70.23c0-.23,0-.46,0-.69h3.81a15.07,15.07,0,0,0-3.55-9.73c-.19-.23-.4-.45-.61-.67l-.19-.2a15.19,15.19,0,0,0-2.86-2.27,15,15,0,0,0-5.93-2.12v2.68a2.15,2.15,0,1,1-4.29,0V54.6a15.06,15.06,0,0,0-7.26,3.21Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-19" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M84.17,57.23a2.15,2.15,0,1,0,4.29,0V52.31a6.94,6.94,0,0,0,4.66-6.55V39H79.24v6.74a7,7,0,0,0,4.93,6.64v4.83Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-20" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M104.42,80.76c5.2,0,6.32-7.38,6.59-15.72a4.22,4.22,0,0,0,.26-.65c.06-.18.1-.36.15-.55l0,0c.42-1.27,1.28-4,1-4.1s-1,1.4-1.28,2.4l-.14,0c.44-1.37,1.13-3.64.89-3.72s-1.13,1.82-1.39,2.76c-.05.2-.15.47-.23.75l-.17-.05c.43-1.34,1.2-3.83.95-3.91s-1.13,1.82-1.39,2.76c-.07.25-.2.59-.29.93l-.1,0-.08,0c.44-1.38,1-3.38.8-3.45s-1.13,1.82-1.39,2.76c-.07.24-.19.58-.29.92l0-1.53a1.4,1.4,0,0,0-.13-.7.27.27,0,0,0-.24-.16c-.09,0-.16.1-.19.19a1.89,1.89,0,0,0-.11.48,19,19,0,0,0-.13,3.12c0,.11,0,.23,0,.34h0c-.27,12.36-2.25,13.7-3.08,13.7-1.93,0-3.08-3.9-3.08-7.68H97.79c0,.23,0,.46,0,.69C98,75.24,99.74,80.76,104.42,80.76Z" transform="translate(-31.11 -7.73)" />
            <polygon id="svgThemeColor-21" data-name="svgThemeColor" className={classNames(themeFillClass)} points="192.23 27.97 191.47 27.2 189.35 50.64 190.18 50.64 192.23 27.97" />
            <polygon id="svgThemeColor-22" data-name="svgThemeColor" className={classNames(themeFillClass)} points="184.91 99.64 185.74 99.64 186.58 90.36 185.75 90.36 184.91 99.64" />
            <path className="upload-photo-cls-4" d="M217.69,98.08l-.84,9.29H216l.84-9.29H215.6l-.84,9.29H163.47l2.75-29.95H165l-2.81,30.52s0,0,0,0,0,0,0,0a.59.59,0,0,0,0,.1.62.62,0,0,0,0,.12.61.61,0,0,0,.06.11.62.62,0,0,0,.07.1l.09.08.1.07.12,0,.09,0h54.71a.62.62,0,0,0,.24,0l.07,0a.62.62,0,0,0,.12-.08l.06-.08a.61.61,0,0,0,.07-.11.62.62,0,0,0,0-.1.6.6,0,0,0,0-.1l.9-10Z" transform="translate(-31.11 -7.73)" />
            <polygon className="upload-photo-cls-3" points="183.72 50.64 183.85 50.64 185.65 30.74 180.05 30.74 178.53 47.23 178.21 50.64 183.72 50.64" />
            <polygon className="upload-photo-cls-3" points="169.7 69.7 168.65 69.7 165.33 69.7 162.47 69.7 152.8 69.7 150.15 69.7 139.22 69.7 137.06 93.23 179.99 93.23 180.25 90.36 169.54 90.36 167.86 90.36 169.7 69.7" />
            <path className="upload-photo-cls-4" d="M211.56,34.12h9.83l-2.19,24.24h1.26l2.12-23.43.76.77-2.05,22.67h1.26l2.07-22.85s0,0,0,0a1.55,1.55,0,0,0,0-.24.64.64,0,0,0-.05-.1.61.61,0,0,0-.07-.11v0l-1.84-1.86-.06-.09-.09-.08-.1-.06-.12,0-.09,0H211.67Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-4" d="M215,58.36h1.26l1.85-20.47a.63.63,0,0,0-.57-.68h-6.23l-.12,1.26h5.6Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-4" d="M168.17,101l2.16-23.53h-1.26l-2.21,24.1a.63.63,0,0,0,.57.68h.06l.08,0,.08,0h44a.62.62,0,0,0,.24,0l.07,0a.62.62,0,0,0,.12-.08l.06-.08a.61.61,0,0,0,.07-.11.62.62,0,0,0,0-.1.6.6,0,0,0,0-.1l.32-3.56h-1.26L211.1,101Z" transform="translate(-31.11 -7.73)" />
            <circle className="upload-photo-cls-4" cx="156.98" cy="97.29" r="0.86" />
            <path id="svgThemeColor-23" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M40.82,69.19,41.08,72l.12,1.26,1.86,20.09h3.27v-.94A10,10,0,0,1,54.1,82.7v-2a5.17,5.17,0,0,1-2.6-4l-.06,0c-.93-.51-2.19-4.49-1-7.76a3.37,3.37,0,0,1,1.44-1.72V64.36H49.15v-.57H63.6v.57H61.4v2.9h0a3.62,3.62,0,0,1,.87.68c1.37,1.56.93,5.18-.41,6.33v1.94a5.19,5.19,0,0,1-4.54,5.14v1.16a10,10,0,0,1,8.91,9.9v.94h6.06l-3-33.7H39.94l.77,8.28Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-24" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M66.23,92.41a10,10,0,0,0-8.91-9.9v.59a1.61,1.61,0,1,1-3.22,0v-.4a10,10,0,0,0-7.78,9.71v.94H66.23Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-25" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} d="M51.45,73.47a16.77,16.77,0,0,0,4-3.61,17.82,17.82,0,0,0,6.4,1.76v2.64c1.34-1.15,1.78-4.77.41-6.33a3.62,3.62,0,0,0-.87-.68H51.84v0A3.37,3.37,0,0,0,50.4,69c-1.14,3.27.12,7.25,1,7.76l.06,0a5.1,5.1,0,0,1-.06-.55Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-26" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M55.71,84.71a1.61,1.61,0,0,0,1.61-1.61V81.35a5.19,5.19,0,0,0,4.54-5.14V71.63a17.82,17.82,0,0,1-6.4-1.76,16.77,16.77,0,0,1-4,3.61v2.74a5.1,5.1,0,0,0,.06.55,5.17,5.17,0,0,0,2.6,4V83.1A1.61,1.61,0,0,0,55.71,84.71Z" transform="translate(-31.11 -7.73)" />
            <polygon id="svgThemeColor-27" data-name="svgThemeColor" className={classNames(themeFillClass)} points="30.29 59.53 30.29 59.53 30.29 56.63 20.73 56.63 20.73 59.51 20.73 59.53 30.29 59.53" />
            <polygon id="svgThemeColor-28" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} points="32.5 56.63 32.5 56.06 18.04 56.06 18.04 56.63 20.73 56.63 30.29 56.63 32.5 56.63" />
            <path id="svgThemeColor-29" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M157.26,70.56H153.2a16.15,16.15,0,0,1,6-12.54c.27-.21.55-.42.83-.62s.8-.54,1.22-.79V47.91l-4.38-.06c-.29-1.79.7-10.81,1.32-12.48a12.51,12.51,0,0,1,3.37-4.89,9.77,9.77,0,0,1,1.35-1,11.41,11.41,0,0,1,1.85-1.06,13.4,13.4,0,0,1,9.78-.68,8.76,8.76,0,0,1,2.7,1.32,5.82,5.82,0,0,1,.9.74l.3.3c1.64,1.75,4.37,5.6,5,12.43A19.44,19.44,0,0,1,183,48.2l-5.08-.07v8.69l.51.32q.32.21.64.46a16.2,16.2,0,0,1,2.05,1.82,16.9,16.9,0,0,1,8.65-9A15.94,15.94,0,0,1,194,49.16a3.91,3.91,0,0,1,.44-.06L195,49V47.1a8.82,8.82,0,0,1-7.7-8.75V35.06c-2.27-1.94-3-8.08-.69-10.72,1.37-1.57,5.3-3.19,9.6-3.19,2,0,9,.51,10.53,4.91,1.93,5.55-.21,12.29-1.78,13.16l0,0a8.82,8.82,0,0,1-4.45,6.8v3.32l.43.09a16.27,16.27,0,0,1,5.09,2.23,16.94,16.94,0,0,1,3.7,3.27l1.52-16.49.12-1.26.28-3.08.12-1.26,2-22h-58l-5.93,66.59h4.95V70.56Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-30" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M183.91,77.42h9.67L191,65.81l-1.43-6.54,2.2-.59-2-8.23a16.9,16.9,0,0,0-8.65,9,16.09,16.09,0,0,1,4.44,11.13h-1.65Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-31" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M189.62,59.27,191,65.81l2.53,11.61h2.85l-.82-11.61-.52-7.36-.65-9.29H194a15.94,15.94,0,0,0-4.24,1.29l2,8.23Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-32" data-name="svgThemeColor" className="upload-photo-cls-9" d="M194.44,49.1a3.91,3.91,0,0,0-.44.06h.44Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-33" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M195.1,58.45l.52,7.36.82,11.61h3.32l.46-11.61.29-7.27.35-9.09-.43-.09V50A2.73,2.73,0,0,1,195,50V49l-.51.06v.06Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-34" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M200.21,65.81l-.46,11.61h1.05l.45-5.07,0-.35,1.21-13.64h1.7l1.73-6.68a16.27,16.27,0,0,0-5.09-2.23l-.35,9.09Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-35" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M209.32,58.36l.31-3.41a16.94,16.94,0,0,0-3.7-3.27l-1.73,6.68Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-36" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} d="M187.26,30.53a30.61,30.61,0,0,0,10.85-2.93,28.18,28.18,0,0,0,6.8,6v4.75c0,.3,0,.59,0,.89l0,0c1.57-.86,3.71-7.6,1.78-13.16-1.53-4.4-8.55-4.91-10.53-4.91-4.29,0-8.22,1.63-9.6,3.19-2.32,2.64-1.57,8.78.69,10.72Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-37" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M195,50a2.73,2.73,0,0,0,5.46,0V46a8.82,8.82,0,0,0,4.45-6.8c0-.29,0-.59,0-.89V33.6a28.18,28.18,0,0,1-6.8-6,30.61,30.61,0,0,1-10.85,2.93v7.82A8.82,8.82,0,0,0,195,47.1V50Z" transform="translate(-31.11 -7.73)" />
            <polygon id="svgThemeColor-38" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} points="152.8 69.7 152.8 62.83 150.15 62.83 150.15 68.51 150.15 69.7 152.8 69.7" />
            <path className="upload-photo-cls-3" d="M171.83,52.23v2.33a16.07,16.07,0,0,1,6.09,2.26V48.14l-1.45,0A7.44,7.44,0,0,1,171.83,52.23Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-3" d="M167.24,54.53v-2.4a7.43,7.43,0,0,1-4.43-4.2l-1.59,0V56.6A16,16,0,0,1,167.24,54.53Z" transform="translate(-31.11 -7.73)" />
            <rect id="svgThemeColor-39" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} x="123.63" y="62.83" width="2.52" height="6.87" />
            <path id="svgThemeColor-40" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M162.26,45.13v-7.2H177.1v7.2a7.39,7.39,0,0,1-.63,3l1.45,0,5.08.07a19.44,19.44,0,0,0,.41-5.71c-.62-6.83-3.35-10.69-5-12.43a17.46,17.46,0,0,1-8.76,2.25,18.74,18.74,0,0,1-8.12-1.82,12.51,12.51,0,0,0-3.37,4.89c-.62,1.67-1.61,10.69-1.32,12.48l4.38.06,1.59,0A7.39,7.39,0,0,1,162.26,45.13Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-41" data-name="svgThemeColor" className={classNames(themeFillClass)} d="M177.21,29a8.76,8.76,0,0,0-2.7-1.32,13.4,13.4,0,0,0-9.78.68,11.41,11.41,0,0,0-1.85,1.06A17.1,17.1,0,0,0,177.21,29Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-3" d="M178.41,30.06l-.3-.3a5.82,5.82,0,0,0-.9-.74,17.1,17.1,0,0,1-14.33.43,9.77,9.77,0,0,0-1.35,1,18.74,18.74,0,0,0,8.12,1.82A17.46,17.46,0,0,0,178.41,30.06Z" transform="translate(-31.11 -7.73)" />
            <path className="upload-photo-cls-3" d="M166.22,77.42h15V70.56h4.31a16.16,16.16,0,0,0-6.5-12.95l-9.69,8.46-10.22-8a16.15,16.15,0,0,0-6,12.54h4.06v6.87h9Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-42" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M177.92,56.83a16.07,16.07,0,0,0-6.09-2.26V52.23a7.43,7.43,0,0,0,5.27-7.1v-7.2H162.26v7.2a7.42,7.42,0,0,0,5,7v2.4a16,16,0,0,0-6,2.07c-.42.25-.83.5-1.22.79s-.56.41-.83.62l10.22,8,9.69-8.46q-.32-.25-.64-.46Z" transform="translate(-31.11 -7.73)" />
            <polygon className="upload-photo-cls-3" points="199.37 90.36 192.96 90.36 192.56 95.05 213.01 95.05 215.71 65.28 204.63 65.28 202.32 90.36 199.37 90.36" />
            <polygon id="svgThemeColor-43" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} points="115.57 99.64 115.57 97.01 97.28 97.01 97.27 97.01 97.27 97.01 50.96 97.01 50.96 99.64 115.57 99.64" />
            <polygon id="svgThemeColor-44" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} points="2.67 61.46 2.93 64.27 9.97 64.27 9.71 61.46 2.67 61.46" />
            <path id="svgThemeColor-45" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M37,104l.31,3.34H57.71l-.3-3.34ZM48,106.6a.86.86,0,1,1,.86-.86A.86.86,0,0,1,48,106.6Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-46" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M212.3,101.64a.6.6,0,0,1,0,.1.62.62,0,0,1,0,.1.61.61,0,0,1-.07.11l-.06.08a.62.62,0,0,1-.12.08l-.07,0a.62.62,0,0,1-.24,0h-44l-.08,0-.08,0h-.06a.63.63,0,0,1-.57-.68l2.21-24.1h-2.84l-2.75,29.95h51.29l.84-9.29h-3Zm-24.21,4.23A.86.86,0,1,1,189,105,.86.86,0,0,1,188.09,105.88Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-47" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M217.45,37.21h.06a.63.63,0,0,1,.57.68l-1.85,20.47h3l2.19-24.24h-9.83l-.28,3.08h6.18Z" transform="translate(-31.11 -7.73)" />
            <polygon id="svgThemeColor-48" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} points="204.75 64.02 215.83 64.02 216.08 61.21 205.01 61.21 204.9 62.37 204.75 64.02" />
            <path id="svgThemeColor-49" data-name="svgThemeColor" className={classNames('upload-photo-cls-6', themeFillClass)} d="M243.69,107.37,244,104H223.56l-.3,3.34Zm-10.76-.77a.86.86,0,1,1,.86-.86A.86.86,0,0,1,232.94,106.6Z" transform="translate(-31.11 -7.73)" />
            <polygon id="svgThemeColor-50" data-name="svgThemeColor" className={classNames(themeFillClass)} points="217.27 62.05 213.85 99.64 214.1 99.64 217.47 62.25 217.27 62.05" />
            <path className="upload-photo-cls-4" d="M249.84,69.63a.36.36,0,0,0-.08-.21.61.61,0,0,0-.07-.11l0,0-1.22-1.23a7,7,0,0,0-.12-.18l-.1-.08-.09-.06-.13,0-.09,0h-11.7l0,.47-.07.79h11.07l-.26,2.81H235.86L235.74,73h11.08l-2.7,29.76H223.67l.39-4.69H222.8l-.43,5.08a.63.63,0,0,0,0,.24.59.59,0,0,0,0,.09l-.39,4.45a.63.63,0,0,0,.57.68h.06l.11,0,.1,0h23a.62.62,0,0,0,.24,0l.07,0a.64.64,0,0,0,.13-.08l.06-.08a.62.62,0,0,0,.08-.11.63.63,0,0,0,0-.1.59.59,0,0,0,0-.1l3.45-38.26s0,0,0,0A.62.62,0,0,0,249.84,69.63ZM223.56,104H244l-.31,3.34H223.26Zm21.65,3.34H245l3.42-37.6.2.2Z" transform="translate(-31.11 -7.73)" />
            <circle className="upload-photo-cls-4" cx="201.83" cy="98.02" r="0.86" />
            <path id="svgThemeColor-51" data-name="svgThemeColor" className={classNames('upload-photo-cls-10', themeFillClass)} d="M222.55,58.36h-20L201.29,72l3.46-2.33,1.09.33a13.48,13.48,0,0,1,.69-4.59c1.39-4,7.75-4.45,9.55-4.45,3.89,0,7.46,1.48,8.7,2.89a5.66,5.66,0,0,1,1.2,3l5.08-4,5.12,5.33,0-.47.86-9.32Z" transform="translate(-31.11 -7.73)" />
            <path id="svgThemeColor-52" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M208.19,77.36l0,0c-.75-.41-1.64-2.32-2.06-4.77l-1-.1L203,71.86l-1.75.49-.45,5.07L199,98.08h1.68a15.31,15.31,0,0,1,11.57-11.55v-3A8,8,0,0,1,208.19,77.36Z" transform="translate(-31.11 -7.73)" /> <path id="svgThemeColor-53" data-name="svgThemeColor" className={classNames('upload-photo-cls-5', themeFillClass)} d="M235.62,70l-1.71.88L230,70.12l-1.93.61-2.12-.94a6.51,6.51,0,0,1-1.77,3.79v3a8,8,0,0,1-7,7.93v1.75a15.3,15.3,0,0,1,13.31,11.84h2.95L235.74,73l.12-1.26L236,70.1Z" transform="translate(-31.11 -7.73)" /> <path id="svgThemeColor-54" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} d="M224.06,98.08h6.42a15.3,15.3,0,0,0-13.31-11.84v.9a2.47,2.47,0,1,1-4.95,0v-.61a15.31,15.31,0,0,0-11.57,11.55h23.41Z" transform="translate(-31.11 -7.73)" /> <path className="upload-photo-cls-3" d="M201.26,72.35l1.75-.49,2.08.61,1,.1a16.52,16.52,0,0,1-.24-2.56l-1.09-.33L201.29,72Z" transform="translate(-31.11 -7.73)" /> <path id="svgThemeColor-55" data-name="svgThemeColor" className={classNames('upload-photo-cls-8', themeFillClass)} d="M206.08,72.57c.42,2.45,1.31,4.36,2.06,4.77l0,0c0-.26,0-.53,0-.8v-4.2a23.93,23.93,0,0,0,6-5.55,29,29,0,0,0,10,2.66v4.1a6.51,6.51,0,0,0,1.77-3.79,10.11,10.11,0,0,0,.06-2.94h0a5.66,5.66,0,0,0-1.2-3C223.54,62.43,220,61,216.08,61c-1.8,0-8.16.46-9.55,4.45a13.48,13.48,0,0,0-.69,4.59A16.52,16.52,0,0,0,206.08,72.57Z" transform="translate(-31.11 -7.73)" /> <path id="svgThemeColor-56" data-name="svgThemeColor" className={classNames('upload-photo-cls-7', themeFillClass)} d="M224.15,76.56V69.48a29,29,0,0,1-10-2.66,23.93,23.93,0,0,1-6,5.55v4.2c0,.27,0,.54,0,.8a8,8,0,0,0,4,6.17v3.62a2.47,2.47,0,1,0,4.95,0V84.49A8,8,0,0,0,224.15,76.56Z" transform="translate(-31.11 -7.73)" />
        </svg >
    );
}