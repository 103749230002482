import { Component } from 'react';

import {
    EntityCaseRole,
    EntitySummary,
    PhotoTransformationsType,
    ProductContractViewerUX,
    UserRoles,
} from '../../shared/types';

import { styleWrapper as AssignStyles, AssignStyleProps } from './Style';

import { categorizeViewers } from '../../shared/goods_and_services/utils';
import { isEqual } from 'lodash';
import AssignmentPopperForEntitySummary from './AssignmentPopperForEntitySummary';
import { openHelperInvitationDialog } from '../../actions/Dialog.action';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';
import { GStyles } from '../../styles/GStyles';
import withStyles from '@mui/styles/withStyles';

interface ProductContractViewerSummary {
    userId: number;
    fname: string;
    mname: string | null;
    lname: string | null;
    is_funeral_home_user: boolean;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
}
interface Props {
    dispatch: AppDispatch;
    invitedHelpers: EntitySummary[];
    viewers: ProductContractViewerUX[];
    zIndex: number;
    onUpdateViewers: (viewerUserIds: number[]) => void;
}

interface State {
    localViewers: ProductContractViewerSummary[];
}

type StyledProps = AssignStyleProps;

class AssignContractViewers extends Component<Props & StyledProps, State> {
    state: State = {
        localViewers: []
    };

    componentDidMount() {
        this.setLocalViewers();
    }

    componentDidUpdate(prevProps: Props) {
        const { viewers } = this.props;

        if (!isEqual(viewers, prevProps.viewers)) {
            this.setLocalViewers();
        }
    }

    contractViewerMapper = () => {
        const { invitedHelpers } = this.props;
        const { localViewers } = this.state;
        const mappedData = invitedHelpers.filter(i => localViewers.find(v => v.userId === i.user_id));
        return mappedData;
    };

    render() {
        const { invitedHelpers, zIndex, classes } = this.props;

        const heading = 'Statement Visibility Details';
        const invitationButtonLabel = 'Add Helper';

        const assignedTo = this.contractViewerMapper();
        const tooltip = `This will allow the user to view all statement details. 
        Family members can never edit a contract.`;

        return (
            <AssignmentPopperForEntitySummary
                zIndex={zIndex}
                headerText={heading}
                addButtonText={invitationButtonLabel}
                popoverTextClass={classes.assignButton}
                onAdd={(_zIndex) => this.handleInviteNewHelper(_zIndex + 1)}
                items={invitedHelpers}
                assignedTo={assignedTo}
                setAssignee={(viewer, viewers, canAllHelpersView) => this.handleUpdateViewers(viewers || [])}
                tooltip={tooltip}
                showToolipForAllUsers
                showTooltipOnCheckbox
                isMultiSelect
                buttonText={this.getButtonText()}
                showFamilyHelpers={true}
                showTeamMembers={true}
            />
        );
    }

    setLocalViewers = () => {
        const { viewers } = this.props;
        this.setState({
            localViewers: viewers.map((viewer) => ({
                userId: viewer.user.id,
                fname: viewer.user.fname,
                mname: viewer.user.mname,
                lname: viewer.user.lname,
                is_funeral_home_user: viewer.is_funeral_home_user,
                photo: viewer.user.photo,
                photo_transformations: viewer.user.photo_transformations
            })),
        });
    };

    handleUpdateViewers = (viewers: EntitySummary[]) => {
        const { onUpdateViewers } = this.props;

        const localViewers = viewers.reduce<ProductContractViewerSummary[]>(
            (contractViewers, current) => {
                if (current.user_id) {
                    return [
                        ...contractViewers,
                        {
                            userId: current.user_id,
                            fname: current.fname,
                            mname: current.mname,
                            lname: current.lname,
                            is_funeral_home_user: UserRoles.isFHUser(current),
                            photo: current.photo,
                            photo_transformations: current.photo_transformations
                        }];
                }
                return contractViewers;
            },
            []
        );

        this.setState({ localViewers });
        onUpdateViewers(localViewers.map(v => v.userId));
    };

    handleInviteNewHelper = (zIndex: number) => {
        const { dispatch } = this.props;

        dispatch(openHelperInvitationDialog({
            zIndex: zIndex + 1,
            defaultTab: EntityCaseRole.admin,
            onAddedCallback: this.onAddedNewEntityCallback
        }));
    };

    onAddedNewEntityCallback = (entity: EntitySummary) => {
        if (!entity.user_id) {
            return;
        }

        const viewer: ProductContractViewerSummary = {
            userId: entity.user_id,
            fname: entity.fname,
            mname: entity.mname,
            lname: entity.lname,
            is_funeral_home_user: UserRoles.isFHUser(entity),
            photo: entity.photo,
            photo_transformations: entity.photo_transformations
        };

        this.setState(prevState => ({
            localViewers: [...prevState.localViewers, viewer]
        }));
    };

    getButtonText = () => {

        let assignedTo: string | null = null;

        const { familyViewers, /* familyEditors */ } = categorizeViewers(this.state.localViewers);
        const localViewers = familyViewers;
        // const editors = inviteeType === 'team' ? fhSigner : familyEditors;
        // const targetRecipients = isViewersType ? viewers : editors;
        const targetViewers = localViewers;

        const list = targetViewers.map((viewer) => ({
            fname: viewer.fname,
            lname: viewer.fname || '',
            photo: viewer.photo,
            photo_transformations: viewer.photo_transformations
        }));

        if (list.length > 0) {
            assignedTo = (list.length === 1)
                ? `${list[0].fname} ${'can view'}`
                : `${list.length} ${'people can view'}`;
        }

        let label: string | JSX.Element = '';
        if (assignedTo) {
            label = (
                <span>
                    <span className={GStyles.textUnderline}>
                        {assignedTo}
                    </span>
                </span>
            );
        } else {
            label = 'Who can view statement?';
        }

        return {
            assignedTo: list,
            label
        };
    };
}

export default withState()(withStyles(AssignStyles<Props>())(AssignContractViewers));
