import { AppDispatch } from "../store";

let userHasInternet: boolean = true;

export const HAS_INTERNET = 'HAS_INTERNET';

interface HasInternet {
    type: typeof HAS_INTERNET;
}

function hasInternet(): HasInternet {
    return {
        type: HAS_INTERNET,
    };
}

export const HAS_NO_INTERNET = 'HAS_NO_INTERNET';

interface HasNoInternet {
    type: typeof HAS_NO_INTERNET;
}

function hasNoInternet(): HasNoInternet {
    return {
        type: HAS_NO_INTERNET,
    };
}

export function internetStatusChanged(params: {
    hasInternet: boolean;
}) {
    return async (dispatch: AppDispatch) => {
        if (params.hasInternet !== userHasInternet) {
            userHasInternet = params.hasInternet;
            dispatch(params.hasInternet ? hasInternet() : hasNoInternet());
        }
    };
}

export type AppAction =
    | HasInternet
    | HasNoInternet
    ;
