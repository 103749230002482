import { AppRoute } from "../../services";
import RealGNavigate, { RealGNavigateProps } from './RealGNavigate';

export interface GNavigateProps extends RealGNavigateProps {
    to: AppRoute;
}

function GNavigate(props: GNavigateProps) {
    return <RealGNavigate {...props} />;
}

export default GNavigate;
