import {
    CloudinarySignaturePayload,
    CloudinaryPhoto,
} from '../shared/types';

import { getFromAPI } from './';
import { cloudinaryContextToStr } from '../shared/utils';
import { AppDispatch } from '../store';
import { log } from '../logger';

export interface CloudinaryUploadResponse {
    public_id?: string | null;
    secure_url?: string | null;
    original_filename?: string | null;
    height?: number | null;
    width?: number | null;
    created_at?: Date | null;
    // tags: string[];
    // context: {
    //     custom: CloudinaryContext,
    // };
}

export async function fetchCloudinarySignature(
    signatureURL: string,
    dispatch: AppDispatch
): Promise<CloudinarySignaturePayload | null> {
    return await getFromAPI<CloudinarySignaturePayload>(signatureURL, dispatch);
}

export async function cloudinaryUpload(
    photo: File | string, // File or Base 64 Data URI string
    signaturePayload: CloudinarySignaturePayload,
): Promise<CloudinaryPhoto | null> {
    // For more info on this call see: https://cloudinary.com/documentation/image_upload_api_reference
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    if (!process.env.REACT_APP_CLOUDINARY_BASE_URL || !cloudName) {
        log.warn('REACT_APP_CLOUDINARY_BASE_URL/cloudName is not defined in cloudinaryUpload', {
            cloudName,
            REACT_APP_CLOUDINARY_BASE_URL: process.env.REACT_APP_CLOUDINARY_BASE_URL
        });
        return null;
    }
    const cloudinaryUrl = `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/${cloudName}/image/upload`;
    const fd = new FormData();
    const contextStr = cloudinaryContextToStr(signaturePayload.context);
    // fd.append('tags', signaturePayload.tags);
    if (contextStr) {
        fd.append('context', contextStr);
    }
    fd.append('public_id', signaturePayload.public_id);
    fd.append('file', photo);
    fd.append('signature', signaturePayload.signature);
    fd.append('api_key', signaturePayload.api_key);
    fd.append('timestamp', signaturePayload.timestamp);
    const response = await fetch(cloudinaryUrl, {
        method: 'POST',
        body: fd,
    });
    const respJSON: CloudinaryUploadResponse = await response.json();
    if (!respJSON || !respJSON.public_id) {
        return null;
    }
    return {
        public_id: respJSON.public_id,
        secure_url: respJSON.secure_url || undefined,
        // tags: respJSON.tags,
        // context: respJSON.context.custom,
        height: respJSON.height || undefined,
        width: respJSON.width || undefined,
        created_at: respJSON.created_at || new Date(),
    };
}