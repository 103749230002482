import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { GLOBAL_STYLED_PROPS } from '../../../../../../styles';
import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../../../styles/WithGStyles';

interface TimezoneProps {
    styles?: string;
    value: string;
    id: string;
    onClick: () => void;
}
export interface Props {
    id: string;
    label: string;
    value: string;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    rootClass?: string;
    labelClass?: string;
    textFieldClass?: string;
    timezoneData?: TimezoneProps;
    isHiddenFromFamily?: boolean;
    onBlur?: () => void;
    onChange: (value: string) => void;
    handleOpenSnackbar?: () => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        textAlign: 'left',
        marginTop: 10,
        width: 190,
        '@media (min-width: 421px)': {
            width: 280,
        },
        display: 'inline-block'
    },
    inputDateLabel: {
        width: 'max-content',
    },
    timeZoneContainer: {
        width: 124,
        margin: '4px auto',
        opacity: 0.50,
    },
    datePickerGrid: {
        width: 190,
        '@media (min-width: 421px)': {
            width: 280,
        },
    },
    flexDirectionRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0
    },
    styleTextField: {
        width: 68,
        display: 'inline-flex',
        '& input': {
            padding: 0,
            fontSize: 10,
            lineHeight: '12px',
        },
        '@media (max-width: 330px)': {
            width: 74,
        }
    },
    styleTimeZoneLabel: {
        width: 50,
        fontSize: 10,
    },
    timezoneText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    displayFlex: {
        display: 'flex',
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<
    'root'
    | 'inputDateLabel'
    | 'timeZoneContainer'
    | 'datePickerGrid'
    | 'flexDirectionRow'
    | 'styleTimeZoneLabel'
    | 'styleTextField'
>;

const DCTimeField = (props: StyledProps) => {

    const {
        classes,
        id,
        error,
        disabled,
        required,
        value,
        label,
        rootClass,
        textFieldClass,
        timezoneData,
        labelClass,
        onBlur,
        onChange,
        handleOpenSnackbar,
        isHiddenFromFamily
    } = props;

    return (
        <FormControl
            className={classNames(classes.root, rootClass)}
            required={required}
            error={error}
        >
            <TextField
                id={id}
                type="time"
                name="time"
                label={label}
                defaultValue={value}
                className={classNames(classes.width100, textFieldClass)}
                onChange={(e) => onChange(e.target.value)}
                autoComplete="new-password"
                disabled={disabled}
                onClick={handleOpenSnackbar}
                onBlur={onBlur}
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: labelClass
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <HiddenFromFamilyAdornment
                            showMe={isHiddenFromFamily}
                            useAdornmentComponent
                        />
                    )
                }}
                required={false}
            />
            {!!timezoneData &&
                <div className={classes.timeZoneContainer}>
                    <FormControl
                        className={classNames(
                            classes.datePickerGrid,
                            classes.textLeft,
                            classes.flexDirectionRow,
                        )}
                    >
                        <Typography className={classes.styleTimeZoneLabel}>Timezone:</Typography>
                        <TextField
                            id={timezoneData.id}
                            type="text"
                            name="timezone"
                            value={timezoneData.value}
                            className={classNames(classes.styleTextField, timezoneData.styles)}
                            autoComplete="new-password"
                            disabled={disabled}
                            onClick={timezoneData.onClick}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </div>}
        </FormControl>
    );
};

export default withGStyles(styles)(DCTimeField);
