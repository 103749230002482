import * as React from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import EventIcon from '@mui/icons-material/Event';

import { CalendarViewType, EventCreateRequest, LocationUX } from '../../../../shared/types';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import { isEventTypeArrangement } from './utils';
import withGStyles from '../../../../styles/WithGStyles';
import LocationList, { LocationListChangeParam } from "./LocationList";

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dateTimeTabs: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: 10,
    },
    switchViewButtonContainer: {
        display: 'flex',
        paddingLeft: 8
    },
    switchViewButton: {
        padding: 0,
        textTransform: 'none',
        minHeight: 24,
        fontWeight: 400,
        fontSize: 12,
        '& svg': {
            fontSize: 16
        }
    },
    familyMessage: {
        margin: '16px 0 10px',
        minWidth: 280,
        maxWidth: 460,
        width: '100%'
    },
});

type Classes = 'root' | 'dateTimeTabs' | 'switchViewButtonContainer' | 'switchViewButton' | 'familyMessage';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    filteredLocations: LocationUX[];
    selectedLocation: LocationUX | null;
    isExpandedView: boolean;
    eventNameValidator: boolean | null;
    caseFname: string;
    thisEvent: EventCreateRequest;
    setInputRef: (ref: HTMLInputElement) => void;
    handleViewChange: () => void;
    handleChangeNameEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEventNameKeyUpEvent: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleMessageForFamilyChange: (message: string) => void;
    handleChangeAddressesEvent: (event: React.ChangeEvent<{}>, location: LocationListChangeParam) => void;
    zIndex: number;
}

const EventNameAndAddress = ({
    classes,
    filteredLocations,
    selectedLocation,
    isExpandedView,
    thisEvent,
    eventNameValidator,
    caseFname,
    setInputRef,
    handleMessageForFamilyChange,
    handleViewChange,
    handleChangeNameEvent,
    handleChangeAddressesEvent,
    handleEventNameKeyUpEvent,
    zIndex,
}: Props & StyledProps) => {

    const switchViewTootlip = `Change your preference for entering the date and time for events. ` +
        `Your preference will be remembered for future use.`;
    const messageFieldTooltip = `This message will be displayed in any emails that are sent to the family as well
        as on ${caseFname}'s Remember page for this event`;

    return (
        <>
            <div className={classes.overflowXAuto}>
                <LocationList
                    locations={filteredLocations}
                    selectedLocation={selectedLocation || undefined}
                    onChange={handleChangeAddressesEvent}
                    showEmptyLocation={true}
                    zIndex={zIndex}
                />
            </div>

            {isEventTypeArrangement(thisEvent) &&
                <Grid container direction="column" alignItems="center">
                    <FormControl className={classes.familyMessage}>
                        <Tooltip title={messageFieldTooltip}>
                            <TextField
                                id="familyMessage"
                                name="familyMessage"
                                variant="outlined"
                                margin="dense"
                                label="Optional message to the Family..."
                                value={thisEvent.message || ''}
                                className={classes.width100}
                                onChange={(e) => handleMessageForFamilyChange(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: 'notranslate'
                                    }
                                }}
                                autoComplete="off"
                            />
                        </Tooltip>
                    </FormControl>
                </Grid>
            }

            <Grid container direction="column" alignItems="center">
                <FormControl
                    className={classNames(classes.marginBottom10, classes.marginTop20, classes.width280)}
                >
                    <TextField
                        required
                        error={eventNameValidator === false}
                        id="name"
                        name="name"
                        tabIndex={2}
                        value={thisEvent.name}
                        className={classes.width100}
                        onChange={handleChangeNameEvent}
                        onKeyUp={handleEventNameKeyUpEvent}
                        placeholder={`Type event name here *`}
                        autoComplete="off"
                        inputRef={setInputRef}
                        autoFocus={!thisEvent.name}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.switchViewButtonContainer}>
                <Tooltip
                    title={switchViewTootlip}
                    enterDelay={600}
                    placement="top"
                >
                    <Button
                        color="primary"
                        className={classes.switchViewButton}
                        onClick={handleViewChange}
                    >
                        <EventIcon />&nbsp;
                        <span>Switch to</span>&nbsp;
                        <span>
                            {isExpandedView ? CalendarViewType.collapsed : CalendarViewType.expanded}
                        </span>&nbsp;
                        <span>view</span>
                    </Button>
                </Tooltip>
            </Grid>
        </>
    );
};

export default withGStyles(styles)(EventNameAndAddress);
