import * as t from 'io-ts';
import { getValidator } from './utils';

export enum TaskComponentType {
    paragraph = 'paragraph',
    case_confirmation = 'case_confirmation',
    who_performed = 'who_performed',
    checklist_questions = 'checklist_questions',
    text_questions = 'text_questions',
    multiple_choice_question = 'multiple_choice_question',
    file_upload = 'file_upload',
    note = 'note',
    personal_belongings = 'personal_belongings',
    fine_print = 'fine_print',
    fingerprint = 'fingerprint',
    location = 'location',
    family_sharing = 'family_sharing',
    additional_signature = 'additional_signature',
    signature = 'signature',
}

const componentQuestionAnswer = t.partial({
    answer: t.string,
});

// Note Component
const noteComponentConfig = t.intersection([
    componentQuestionAnswer,
    t.type({
        note_instructions: t.string,
        is_required: t.boolean,
    }),
]);
export interface NoteComponentConfig extends t.TypeOf<typeof noteComponentConfig> { }

const NoteComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.note),
    configuration: noteComponentConfig,
});

export interface NoteTaskComponentSaveRequest extends t.TypeOf<typeof NoteComponentSaveRequest> {
    type: TaskComponentType.note;
}

export const isNoteConfig = (c: TaskComponentConfiguration | null): c is NoteComponentConfig => {
    return noteComponentConfig.is(c);
};

// Location Component
const locationConfig = componentQuestionAnswer;
export interface LocationConfig extends t.TypeOf<typeof locationConfig> { }

const LocationComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.location),
    configuration: locationConfig,
});

export interface LocationTaskComponentSaveRequest extends t.TypeOf<typeof LocationComponentSaveRequest> {
    type: TaskComponentType.location;
}

export const isLocationConfig = (c: TaskComponentConfiguration | null): c is LocationConfig => {
    return locationConfig.is(c);
};

// Text Questions Component
const textQuestionsQuestion = t.intersection([
    componentQuestionAnswer,
    t.type({
        uuid: t.string,
        question_text: t.string,
        is_required: t.boolean,
    }),
]);
export interface TextQuestionsQuestion extends t.TypeOf<typeof textQuestionsQuestion> { }

const textQuestionsConfig = t.intersection([
    t.type({
        questions: t.array(textQuestionsQuestion),
    }),
    t.partial({
        header_text: t.union([t.string, t.undefined])
    }),
]);
export interface TextQuestionsConfig extends t.TypeOf<typeof textQuestionsConfig> { }

const TextQuestionsComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.text_questions),
    configuration: textQuestionsConfig,
});

export interface TextQuestionsTaskComponentSaveRequest extends t.TypeOf<typeof TextQuestionsComponentSaveRequest> {
    type: TaskComponentType.text_questions;
}

export const isTextQuestionsConfig = (c: TaskComponentConfiguration | null): c is TextQuestionsConfig => {
    return textQuestionsConfig.is(c);
};

// Multiple Choice Component
const multipleChoiceOption = t.type({
    uuid: t.string,
    question_text: t.string,
});

export interface MultipleChoiceOption extends t.TypeOf<typeof multipleChoiceOption> { }

const multipleChoiceConfig = t.intersection([
    componentQuestionAnswer, // this will be the uuid of the selected option
    t.type({
        question_text: t.string,
        is_required: t.boolean,
        options: t.array(multipleChoiceOption),
    }),
]);
export interface MultipleChoiceConfig extends t.TypeOf<typeof multipleChoiceConfig> { }

const MultipleChoiceComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.multiple_choice_question),
    configuration: multipleChoiceConfig,
});

export interface MultipleChoiceTaskComponentSaveRequest extends t.TypeOf<typeof MultipleChoiceComponentSaveRequest> {
    type: TaskComponentType.multiple_choice_question;
}

export const isMultipleChoiceConfig = (c: TaskComponentConfiguration | null): c is MultipleChoiceConfig => {
    return multipleChoiceConfig.is(c);
};

// Checklist Questions Component
const checklistQuestionsQuestion = t.intersection([
    componentQuestionAnswer,
    t.type({
        uuid: t.string,
        question_text: t.string,
        is_required: t.boolean,
    }),
]);
export interface ChecklistQuestionsQuestion extends t.TypeOf<typeof checklistQuestionsQuestion> { }

const checklistQuestionsConfig = t.intersection([
    t.type({
        questions: t.array(checklistQuestionsQuestion),
    }),
    t.partial({
        header_text: t.union([t.string, t.undefined])
    }),
]);
export interface ChecklistQuestionsConfig extends t.TypeOf<typeof checklistQuestionsConfig> { }

const ChecklistQuestionsComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.checklist_questions),
    configuration: checklistQuestionsConfig,
});

export interface ChecklistQuestionsTaskComponentSaveRequest
    extends t.TypeOf<typeof ChecklistQuestionsComponentSaveRequest> {
    type: TaskComponentType.checklist_questions;
}

export const isChecklistQuestionsConfig = (c: TaskComponentConfiguration | null): c is ChecklistQuestionsConfig => {
    return checklistQuestionsConfig.is(c);
};

// Fingerprints Component
const fingerprintsConfig = t.type({
    instructions: t.string,
    count: t.number,
    is_required: t.boolean,
});
export interface FingerprintsConfig extends t.TypeOf<typeof fingerprintsConfig> { }

const FingerprintsComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.fingerprint),
    configuration: fingerprintsConfig,
});

export interface FingerprintsTaskComponentSaveRequest extends t.TypeOf<typeof FingerprintsComponentSaveRequest> {
    type: TaskComponentType.fingerprint;
}

export const isFingerprintsConfig = (c: TaskComponentConfiguration | null): c is FingerprintsConfig => {
    return fingerprintsConfig.is(c);
};

// Family Sharing Component
const familySharingConfig = t.type({
});
export interface FamilySharingConfig extends t.TypeOf<typeof familySharingConfig> { }

const FamilySharingComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.family_sharing),
    configuration: familySharingConfig,
});

export interface FamilySharingTaskComponentSaveRequest extends t.TypeOf<typeof FamilySharingComponentSaveRequest> {
    type: TaskComponentType.family_sharing;
}

export const isFamilySharingConfig = (c: TaskComponentConfiguration | null): c is FamilySharingConfig => {
    return familySharingConfig.is(c);
};

// Belongings Component
const belongingsConfig = t.type({
    is_required: t.boolean,
});
export interface BelongingsConfig extends t.TypeOf<typeof belongingsConfig> { }

const BelongingsComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.personal_belongings),
    configuration: belongingsConfig,
});

export interface BelongingsTaskComponentSaveRequest extends t.TypeOf<typeof BelongingsComponentSaveRequest> {
    type: TaskComponentType.personal_belongings;
}

export const isBelongingsConfig = (c: TaskComponentConfiguration | null): c is BelongingsConfig => {
    return belongingsConfig.is(c);
};

// File Upload Component
const fileUploadConfig = t.type({
    instructions: t.string,
    is_required: t.boolean,
});
export interface FileUploadConfig extends t.TypeOf<typeof fileUploadConfig> { }

const FileUploadComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.file_upload),
    configuration: fileUploadConfig,
});

export interface FileUploadTaskComponentSaveRequest extends t.TypeOf<typeof FileUploadComponentSaveRequest> {
    type: TaskComponentType.file_upload;
}

export const isFileUploadConfig = (c: TaskComponentConfiguration | null): c is FileUploadConfig => {
    return fileUploadConfig.is(c);
};

// Fine Print Component
const finePrintConfig = t.type({
    text: t.string,
});
interface FinePrintConfig extends t.TypeOf<typeof finePrintConfig> { }
const FinePrintComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.fine_print),
    configuration: finePrintConfig,
});

export interface FinePrintTaskComponentSaveRequest extends t.TypeOf<typeof FinePrintComponentSaveRequest> {
    type: TaskComponentType.fine_print;
}

export const isFinePrintConfig = (c: TaskComponentConfiguration | null): c is FinePrintConfig => {
    return finePrintConfig.is(c);
};

// Paragraph Component
const paragraphConfig = t.type({
    text: t.string,
});
interface ParagraphConfig extends t.TypeOf<typeof paragraphConfig> { }

const ParagraphComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.paragraph),
    configuration: paragraphConfig,
});

export interface ParagraphTaskComponentSaveRequest extends t.TypeOf<typeof ParagraphComponentSaveRequest> {
    type: TaskComponentType.paragraph;
}

export const isParagraphConfig = (c: TaskComponentConfiguration | null): c is ParagraphConfig => {
    return paragraphConfig.is(c);
};

// Case Confirmation Component
const caseConfirmationConfig = t.type({
    show_upload_option: t.boolean,
    require_death_photo_uploaded: t.boolean,
    require_case_confirmation: t.boolean,
});
export interface CaseConfirmationConfig extends t.TypeOf<typeof caseConfirmationConfig> { }

const CaseConfirmationComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.case_confirmation),
    configuration: caseConfirmationConfig,
});

export interface CaseConfirmationTaskComponentSaveRequest
    extends t.TypeOf<typeof CaseConfirmationComponentSaveRequest> {
    type: TaskComponentType.case_confirmation;
}

export const isCaseConfirmationConfig = (c: TaskComponentConfiguration | null): c is CaseConfirmationConfig => {
    return caseConfirmationConfig.is(c);
};

// Signature Component
const signatureConfig = t.partial({
    verification_text: t.string,
});
interface SignatureConfig extends t.TypeOf<typeof signatureConfig> { }

const SignatureComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.signature),
    configuration: signatureConfig,
});

export interface SignatureTaskComponentSaveRequest extends t.TypeOf<typeof SignatureComponentSaveRequest> {
    type: TaskComponentType.signature;
}

export const isSignatureConfig = (c: TaskComponentConfiguration | null): c is SignatureConfig => {
    return signatureConfig.is(c);
};

// Additional Signature Component
const additionalSignatureConfig = t.intersection([
    componentQuestionAnswer, // signer name
    t.type({
        verification_text: t.string,
        fine_print: t.string,
        is_required: t.boolean,
    }),
]);
export interface AdditionalSignatureConfig extends t.TypeOf<typeof additionalSignatureConfig> { }

const AdditionalSignatureComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.additional_signature),
    configuration: additionalSignatureConfig,
});

export interface AdditionalSignatureTaskComponentSaveRequest
    extends t.TypeOf<typeof AdditionalSignatureComponentSaveRequest> {
    type: TaskComponentType.additional_signature;
}

export const isAdditionalSignatureConfig = (c: TaskComponentConfiguration | null): c is AdditionalSignatureConfig => {
    return additionalSignatureConfig.is(c);
};

// Who Performed Component
const whoPerformedConfig = t.type({
    allow_completion_by_someone_else: t.boolean,
});

interface WhoPerformedConfig extends t.TypeOf<typeof whoPerformedConfig> { }

const WhoPerformedComponentSaveRequest = t.type({
    type: t.literal(TaskComponentType.who_performed),
    configuration: whoPerformedConfig,
});

export interface WhoPerformedTaskComponentSaveRequest extends t.TypeOf<typeof WhoPerformedComponentSaveRequest> {
    type: TaskComponentType.who_performed;
}

export const isWhoPerformedConfig = (c: TaskComponentConfiguration | null): c is WhoPerformedConfig => {
    return whoPerformedConfig.is(c);
};

export const getValidConfig = <T extends TaskComponentConfiguration>(
    typeGuard: (c: TaskComponentConfiguration | null) => c is T,
    component: TaskComponentUX | undefined,
): T | null => {
    if (component?.configuration && typeGuard(component.configuration)) {
        return component.configuration;
    } else {
        return null;
    }
};

const TaskComponentConfigurationType = t.union([
    noteComponentConfig,
    locationConfig,
    textQuestionsConfig,
    multipleChoiceConfig,
    checklistQuestionsConfig,
    fingerprintsConfig,
    familySharingConfig,
    belongingsConfig,
    fileUploadConfig,
    finePrintConfig,
    paragraphConfig,
    caseConfirmationConfig,
    signatureConfig,
    additionalSignatureConfig,
    whoPerformedConfig,
]);
export type TaskComponentConfiguration = t.TypeOf<typeof TaskComponentConfigurationType>;

export interface TaskComponentRecord {
    id: number;
    type: TaskComponentType;
    task_id: number;
    cloned_from: number | null;
    configuration: TaskComponentConfiguration;
    rank: number;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface TaskComponentUX {
    id: number;
    type: TaskComponentType;
    configuration: TaskComponentConfiguration;
    rank: number;
}

// ---> TaskComponentSaveRequest <---
const TaskComponentSaveRequestType = t.union([
    NoteComponentSaveRequest,
    LocationComponentSaveRequest,
    TextQuestionsComponentSaveRequest,
    MultipleChoiceComponentSaveRequest,
    ChecklistQuestionsComponentSaveRequest,
    FingerprintsComponentSaveRequest,
    FamilySharingComponentSaveRequest,
    BelongingsComponentSaveRequest,
    FileUploadComponentSaveRequest,
    FinePrintComponentSaveRequest,
    ParagraphComponentSaveRequest,
    CaseConfirmationComponentSaveRequest,
    SignatureComponentSaveRequest,
    AdditionalSignatureComponentSaveRequest,
    WhoPerformedComponentSaveRequest,
]);

export type TaskComponentSaveRequest = t.TypeOf<typeof TaskComponentSaveRequestType>;

const getIoTsType = (body: TaskComponentSaveRequest): t.HasProps => {
    let ioTSType: t.HasProps | null = null;
    switch (body.type) {
        case TaskComponentType.note:
            ioTSType = NoteComponentSaveRequest;
            break;
        case TaskComponentType.location:
            ioTSType = LocationComponentSaveRequest;
            break;
        case TaskComponentType.text_questions:
            ioTSType = TextQuestionsComponentSaveRequest;
            break;
        case TaskComponentType.multiple_choice_question:
            ioTSType = MultipleChoiceComponentSaveRequest;
            break;
        case TaskComponentType.checklist_questions:
            ioTSType = ChecklistQuestionsComponentSaveRequest;
            break;
        case TaskComponentType.fingerprint:
            ioTSType = FingerprintsComponentSaveRequest;
            break;
        case TaskComponentType.personal_belongings:
            ioTSType = BelongingsComponentSaveRequest;
            break;
        case TaskComponentType.file_upload:
            ioTSType = FileUploadComponentSaveRequest;
            break;
        case TaskComponentType.fine_print:
            ioTSType = FinePrintComponentSaveRequest;
            break;
        case TaskComponentType.paragraph:
            ioTSType = ParagraphComponentSaveRequest;
            break;
        case TaskComponentType.case_confirmation:
            ioTSType = CaseConfirmationComponentSaveRequest;
            break;
        case TaskComponentType.signature:
            ioTSType = SignatureComponentSaveRequest;
            break;
        case TaskComponentType.additional_signature:
            ioTSType = AdditionalSignatureComponentSaveRequest;
            break;
        case TaskComponentType.who_performed:
            ioTSType = WhoPerformedComponentSaveRequest;
            break;
        default:
            ioTSType = null;
    }
    if (!ioTSType) {
        throw `Invalid component type ${body.type}`;
    }
    return ioTSType;
};

export const taskComponentSaveRequestFromRequest = getValidator<TaskComponentSaveRequest>(getIoTsType);

// ---> TaskComponentUpdateRankRequest <---
const TaskComponentUpdateRankRequestType = t.type({
    task_component_ids: t.array(t.number),
});

export interface TaskComponentUpdateRankRequest extends t.TypeOf<typeof TaskComponentUpdateRankRequestType> {
}

export class TaskComponentUpdateRankRequest {
    public static fromRequest = getValidator<TaskComponentUpdateRankRequest>(TaskComponentUpdateRankRequestType);
}

// ---> TaskComponentQuestionAnswers <---
const answerWithUUID = t.type({
    uuid: t.string,
    answer: t.string,
});

export interface AnswerWithUUID extends t.TypeOf<typeof answerWithUUID> { };

const componentAnswer = t.union([t.string, t.array(answerWithUUID)]);
export type TaskComponentQuestionAnswer = t.TypeOf<typeof componentAnswer>;

export const TaskComponentCaseUpdateRequestType = t.type({
    task_component_id: t.number,
    answer: componentAnswer,
});
export interface TaskComponentCaseUpdateRequest extends t.TypeOf<typeof TaskComponentCaseUpdateRequestType> {
    answer: TaskComponentQuestionAnswer;
}

export const injectAnswerIntoConfig = (
    existingConfig: TaskComponentConfiguration,
    answer: TaskComponentQuestionAnswer,
): TaskComponentConfiguration | null => {
    if (typeof answer === 'string') {
        return {
            ...existingConfig,
            answer,
        };
    } else {
        const answers: AnswerWithUUID[] = answer;
        if ('questions' in existingConfig) {
            const questions = existingConfig.questions.map((q) => ({
                ...q,
                answer: answers.find((a) => a.uuid === q.uuid)?.answer,
            }));
            return {
                ...existingConfig,
                questions,
            };
        } else {
            return null;
        }
    }
};

// this throws so be sure to wrap in try/catch
export const validateRequiredQuestions = (component: TaskComponentUX): true => {
    const config = component.configuration;

    switch (component.type) {

        case TaskComponentType.additional_signature:
        case TaskComponentType.note:
            if ('is_required' in config && config.is_required) {
                if ('answer' in config && config.answer?.trim()) {
                    return true;
                } else {
                    throw `Missing required answer to ${component.type}`;
                }
            } else {
                return true;
            }
        case TaskComponentType.multiple_choice_question:
            if ('is_required' in config && config.is_required) {
                if ('answer' in config
                    && 'options' in config
                    && config.options.some((opt) => opt.uuid === config.answer)
                ) {
                    return true;
                } else {
                    throw `Missing required answer in multiple choice component`;
                }
            } else {
                return true;
            }
        case TaskComponentType.text_questions:
        case TaskComponentType.checklist_questions:
            if ('questions' in config) {
                for (const question of config.questions) {
                    if ('is_required' in question && question.is_required) {
                        if ('answer' in question && question.answer?.trim()) {
                            return true;
                        } else {
                            throw `Missing required answer to ${component.type}`;
                        }
                    }
                }
                return true;
            } else {
                throw `Missing questions in text questions component`;
            }
        default:
            return true;
    }
};
