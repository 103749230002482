import classNames from 'classnames';
import { values } from 'lodash';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DeathCertificateEducationType, EducationTypeEnum } from '../../../../shared/types';
import { FormParent, ParentProps } from './FormParent';
import {
    educationValidators,
    educationMasterValidators,
} from '../../../../shared/death_certificate/validators/education';
import ConfigurableSelectWrapper from '../common/fields/select/ConfigurableSelect';
import FormTitle from '../common/FormTitle';
import { ConfigurableEducationFieldKey } from '../../../../shared/death_certificate/validators/config';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import { GStyles } from '../../../../styles/GStyles';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';

const ConfigurableSelect = ConfigurableSelectWrapper<EducationTypeEnum>();

const educationTypeTitleLookup: Record<EducationTypeEnum, string> = {
    [EducationTypeEnum['1st grade']]: '1st grade',
    [EducationTypeEnum['2nd grade']]: '2nd grade',
    [EducationTypeEnum['3rd grade']]: '3rd grade',
    [EducationTypeEnum['4th grade']]: '4th grade',
    [EducationTypeEnum['5th grade']]: '5th grade',
    [EducationTypeEnum['6th grade']]: '6th grade',
    [EducationTypeEnum['7th grade']]: '7th grade',
    [EducationTypeEnum['8th grade or less']]: '8th grade or less',
    [EducationTypeEnum['8th grade']]: '8th grade',
    [EducationTypeEnum['9th grade']]: '9th grade',
    [EducationTypeEnum['10th grade']]: '10th grade',
    [EducationTypeEnum['11th grade']]: '11th grade',
    [EducationTypeEnum['12th grade']]: '12th grade',
    [EducationTypeEnum['9th-12th grade; no diploma']]: '9th-12th grade; no diploma',
    [EducationTypeEnum['High School graduate']]: 'High School graduate',
    [EducationTypeEnum['GED completed']]: 'GED completed',
    [EducationTypeEnum['High School graduate or GED completed']]: 'High School graduate or GED completed',
    [EducationTypeEnum['Some college credit, but no degree']]: 'Some college credit, but no degree',
    [EducationTypeEnum['Associate degree']]: 'Associate degree',
    [EducationTypeEnum["Bachelor's degree"]]: "Bachelor's degree",
    [EducationTypeEnum["Master's degree"]]: "Master's degree",
    [EducationTypeEnum['Doctorate (e.g. PhD, EdD)']]: 'Doctorate (e.g. PhD, EdD)',
    [EducationTypeEnum['Professional degree (e.g. MD, DDS, DVM, LLB, JD)']]:
        'Professional degree (e.g. MD, DDS, DVM, LLB, JD)',
    [EducationTypeEnum['Doctorate or Professional degree']]:
        'Doctorate (e.g., PhD, EdD) or Professional degree (e.g., MD, DDS, DVM, LLB, JD)',
    [EducationTypeEnum['Degree not listed here']]: 'Degree not listed here',
    [EducationTypeEnum['Not Available']]: 'Not Available',
    [EducationTypeEnum.Unknown]: 'Unknown',
    [EducationTypeEnum['Refuse to answer']]: 'Refuse to answer',
    [EducationTypeEnum.None]: 'None',
};

interface Props {
    caseFname: string;
    zIndex: number;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateEducationType;
    educationValidator: boolean | null;
    historyValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateEducationType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 510,
        },
    },
});

type StyledProps = Props & WithStyles<'root' | 'inviteHelperForm'>;

class EducationForm extends FormParent<DeathCertificateEducationType, StyledProps, State> {
    state: State = {
        localData: this.props.data,
        educationValidator: null,
        historyValidator: null,
    };

    handleSelectEducation = (option: EducationTypeEnum) => {
        this.updateData(
            {
                educationListOfOptions: option,
            },
            this.validateEducation,
        );
    };

    handleHistoryChange = (value: string) => {
        this.updateData(
            {
                educationHistory: value,
            },
            this.validateHistory,
        );
    };

    validateEducation = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            educationValidator: educationValidators.education(localData, dcConfig, user),
        });
    };

    validateHistory = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            historyValidator: educationValidators.history(localData, dcConfig, user),
        });
    };

    checkValid() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return educationMasterValidators.valid(localData, dcConfig, user);
    }

    checkIfTouched() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return educationMasterValidators.touched(localData, dcConfig, user);
    }

    validateAll() {
        this.validateEducation();
        this.validateHistory();
    }

    renderHelperElement = () => {
        const { caseFname } = this.props;
        return (
            <Typography color="secondary" className={classNames(GStyles.fontSize12, GStyles.marginTop10)}>
                If {caseFname} was currently enrolled, please select the previous grade or highest degree received.
            </Typography>
        );
    };

    render() {
        const { classes, isDeathCertificateLocked, handleOpenSnackbar, zIndex, isEditMode } = this.props;
        const { educationValidator, historyValidator, localData } = this.state;

        return (
            <div ref={this.props.formRef}>
                <Grid item xs={12}>
                    <FormTitle title="Education" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(GStyles.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container alignItems="flex-start" justifyContent="center">
                            <Grid item className={GStyles.width100}>
                                <ConfigurableSelect
                                    id={ConfigurableEducationFieldKey.educationListOfOptions}
                                    label="Select the highest level of education achieved"
                                    options={values(EducationTypeEnum)}
                                    selectedOption={localData.educationListOfOptions || null}
                                    displayLookup={educationTypeTitleLookup}
                                    zIndex={zIndex}
                                    onSelect={this.handleSelectEducation}
                                    onClick={handleOpenSnackbar}
                                    disabled={isDeathCertificateLocked}
                                    validator={this.validateEducation}
                                    error={educationValidator === false}
                                    helperElement={this.renderHelperElement()}
                                    isEditMode={isEditMode}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-end" justifyContent="center">
                            <Grid item className={GStyles.width100}>
                                <ConfigurableTextField
                                    id={ConfigurableEducationFieldKey.educationHistory}
                                    value={localData.educationHistory || ''}
                                    label="Full Education History"
                                    error={historyValidator === false}
                                    onChange={this.handleHistoryChange}
                                    onBlur={this.validateHistory}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    isEditMode={isEditMode}
                                    disabled={isDeathCertificateLocked}
                                    multiline={true}
                                    minRows={3}
                                    variant="outlined"
                                    helperText={`Please add as much information as possible ` +
                                        `including years, schools, and degrees if known`}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(EducationForm);
