import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import ATTService from './ATTService';
import { ATT_ICON, TMOBILE_ICON, VERIZON_ICON } from './PhoneServiceIcons';
import TMobileService from './TMobileService';
import VerizonService from './VerizonService';

const SocialSecurityDialog = (props: TaskDialogComponentProps) => {
    const { activeCase, userData, setAppSnackbar } = props;

    const description = (
        <>
            Like most people, {activeCase.fname} likely had a cell phone. Cancelling {activeCase.fname}'s service
            can be a long and tedious process, but luckily we can help. We've simplified
            the process by providing easy-to-follow instructions for each of the major
            cell phone carriers below. Simply select which one applies and we will
            walk you through each step and if you have any questions,
            simply <FhOrAssigneePhoneNumberLink activeCase={activeCase} /> to call me directly.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: `Cancel ${activeCase.fname}'s AT&T Service`,
        icon: ATT_ICON,
        content: (
            <ATTService
                heading={`Cancel ${activeCase.fname}'s AT&T Service`}
                icon={ATT_ICON}
                activeCase={activeCase}
                userData={userData}
                setAppSnackbar={setAppSnackbar}
            />
        ),
    }, {
        id: 2,
        label: `Cancel ${activeCase.fname}'s T-Mobile Serive`,
        icon: TMOBILE_ICON,
        content: (
            <TMobileService
                heading={`Cancel ${activeCase.fname}'s T-Mobile Serive`}
                icon={TMOBILE_ICON}
                activeCase={activeCase}
                userData={userData}
                setAppSnackbar={setAppSnackbar}
            />
        )
    }, {
        id: 3,
        label: `Cancel ${activeCase.fname}'s Verizon Service`,
        icon: VERIZON_ICON,
        content: (
            <VerizonService
                heading={`Cancel ${activeCase.fname}'s Verizon Service`}
                icon={VERIZON_ICON}
                activeCase={activeCase}
            />
        )
    }];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: `Close ${activeCase.fname}'s Online Accounts`,
                description,
                listItems
            }}
        />
    );
};

export default SocialSecurityDialog;
