import { useState } from 'react';
import { CaseLabelUX, GatherCaseUX } from '../../../shared/types';
import AddLabelDialog from './addLabelDialog/AddLabel.dialog';
import { AddLabelCase } from './addLabelDialog/AddLabelPure.dialog';
import CaseLabelChips, { CaseLabelChipsProps } from './CaseLabelChips';

interface Props extends Omit<CaseLabelChipsProps, 'onClick'> {
    zIndex: number;
    gatherCase: AddLabelCase & Pick<GatherCaseUX, 'funeral_home_id'>;
    addLabelToCase?: (caseUuid: string, label: CaseLabelUX) => void;
    removeLabelFromCase?: (caseUuid: string, label: CaseLabelUX) => void;
    onClick?: (label?: CaseLabelUX) => void;
    disableButton?: boolean;
}
const CaseLabels = (props: Props) => {
    const { zIndex, gatherCase, disableButton, onClick, ...rest } = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <CaseLabelChips {...rest} onClick={(label?: CaseLabelUX) => onClick?.(label) ?? setDialogOpen(true)} />
            {!disableButton && (
                <AddLabelDialog
                    isOpen={dialogOpen}
                    zIndex={zIndex + 1}
                    gatherCase={gatherCase}
                    funeralHomeId={gatherCase.funeral_home_id}
                    onClose={() => setDialogOpen(false)}
                    addLabelToCase={props.addLabelToCase}
                    removeLabelFromCase={props.removeLabelFromCase}
                />
            )}
        </>
    );
};

export default CaseLabels;
