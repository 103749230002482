import classNames from 'classnames';
import { values } from 'lodash';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import {
    AncestryEnum,
    DeathCertificateRaceType,
    HispanicOriginEnum,
    isAncestryList,
    RaceEnum,
    YesNoUnknownEnum
} from '../../../../shared/types';
import { FormParent, ParentProps } from './FormParent';
import { raceValidators, raceMasterValidators } from '../../../../shared/death_certificate/validators/race';
import FormTitle from '../common/FormTitle';
import ConfigurableMultiSelectWrapper from '../common/fields/multiSelect/ConfigurableMultiSelect';
import { ConfigurableRaceFieldKey } from '../../../../shared/death_certificate/validators/config';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { ConfigurableToggleButtonsForYesNoUnknown } from './AboutForm';

const ConfigurableMultiSelectForAncestry = ConfigurableMultiSelectWrapper<AncestryEnum>();
const ConfigurableMultiSelectForHispanicOrigin = ConfigurableMultiSelectWrapper<HispanicOriginEnum>();
const ConfigurableMultiSelectForRace = ConfigurableMultiSelectWrapper<RaceEnum>();

function isHispanicOriginList(arr: unknown): arr is HispanicOriginEnum[] {
    return Boolean(arr && Array.isArray(arr) && (arr.length === 0 || HispanicOriginEnum[arr[0]]));
}

function isRaceList(arr: unknown): arr is RaceEnum[] {
    return Boolean(arr && Array.isArray(arr) && (arr.length === 0 || RaceEnum[arr[0]]));
}

interface Props {
    caseFname: string;
    zIndex: number;
    gotoApproval: () => void;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateRaceType;
    hispanicOriginValidator: boolean | null;
    otherOriginValidator: boolean | null;
    raceValidator: boolean | null;
    enrolledOrPrincipleValidator: boolean | null;
    otherAsianValidator: boolean | null;
    otherPacificIslanderValidator: boolean | null;
    otherRaceValidator: boolean | null;
    isUsCitizenValidator: boolean | null;
    ancestryValidator: boolean | null;
    americanIndianTribeValidator: boolean | null;
    raceHispanicSpecifyValidator: boolean | null;
    raceOtherOtherAsianValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateRaceType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        margin: 'auto',
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 510,
        },
    },
    inputSubTextWidth: {
        maxWidth: 230,
        '@media (min-width: 421px)': {
            maxWidth: 330,
        },
    },
    inputTextWidth: {
        width: '100%',
    },
    buttonContainer: {
        margin: '50px 0px 24px 0px',
        textAlign: 'center',
    },
});

type StyledProps = Props & WithGStyles<'root' | 'inviteHelperForm' | 'inputSubTextWidth' | 'inputTextWidth'
    | 'buttonContainer'>;

class RaceForm extends FormParent<DeathCertificateRaceType, StyledProps, State> {

    state = {
        localData: this.props.data,
        hispanicOriginValidator: null,
        otherOriginValidator: null,
        raceValidator: null,
        enrolledOrPrincipleValidator: null,
        otherAsianValidator: null,
        otherPacificIslanderValidator: null,
        otherRaceValidator: null,
        isUsCitizenValidator: null,
        ancestryValidator: null,
        americanIndianTribeValidator: null,
        raceHispanicSpecifyValidator: null,
        raceOtherOtherAsianValidator: null
    };

    handleSpecifyOtherChange = (value: string) => {
        this.updateData({ race_specify_other: value }, this.validateOtherRace);
    };

    handleSpecifyIslanderChange = (value: string) => {
        this.updateData({ race_specify_islander: value }, this.validateOtherPacificIslander);
    };

    handleSpecifyAsian = (value: string) => {
        this.updateData({ race_specify_asian: value }, this.validateOtherAsian);
    };

    handleOtherAsian = (value: string) => {
        this.updateData({ race_specify_other_asian: value }, this.validateOtherOtherAsian);
    };

    handleSpecifyIndianTribeChange = (value: string) => {
        this.updateData({ race_specify_indian_tribe: value }, this.validateEnrolledOrPrinciple);
    };

    handleHispanicOriginSpecifyOtherChange = (value: string) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.updateData({ hispanicOrigin_specify_other: value }, this.validateOtherOrigin);
    };

    handleRaceHispanicChange = (value: string) => {
        this.updateData({ race_specify_other_hispanic: value }, this.validateRaceHispanicOther);
    };

    handleHispanicOriginChangeEvent = (valueList: string[]) => {
        this.updateData(
            (prevData) => {
                if (isHispanicOriginList(valueList)) {
                    const changes: Partial<DeathCertificateRaceType> = {
                        hispanicOrigin: valueList,
                    };
                    const prevList = prevData.hispanicOrigin;
                    // if before had "Yes, Other Spanish/Hispanic/Latino" and now doesn't,
                    // clear the hispanicOriginOther field
                    if (prevList && prevList.some((v) => (
                        v === HispanicOriginEnum['Yes, Other Spanish/Hispanic/Latino']
                    ))
                        && valueList
                        && !valueList.some((v) => v === HispanicOriginEnum['Yes, Other Spanish/Hispanic/Latino'])
                    ) {
                        changes.hispanicOrigin_specify_other = '';
                    }
                    return changes;
                }
                return {};
            },
            this.validateHispanicOrigin,
        );
    };

    handleRaceChangeEvent = (valueList: string[]) => {
        const newList = valueList;
        if (!isRaceList(newList)) {
            console.warn('Invalid race array', JSON.stringify(newList));
            return;
        }

        this.updateData(
            (prevData) => {
                const changes: Partial<DeathCertificateRaceType> = {
                    race: newList,
                };
                const prevList = prevData.race;
                // if before had "American Indian or Alaska Native" and
                //    now doesn't, clear the race_specify_indian_tribe field
                if (prevList && prevList.some((r) => r === RaceEnum['American Indian or Alaska Native'])
                    && newList && !newList.some((r) => r === RaceEnum['American Indian or Alaska Native'])) {
                    changes.race_specify_indian_tribe = '';
                }
                // if before had "Other Asian" and now doesn't, clear the race_specify_asian field
                if (prevList && prevList.some((r) => r === RaceEnum['Asian (Specify)'])
                    && newList && !newList.some((r) => r === RaceEnum['Asian (Specify)'])) {
                    changes.race_specify_asian = '';
                }
                // if before had "Other Pacific Islander" and now doesn't, clear the race_specify_islander field
                if (prevList && prevList.some((r) => r === RaceEnum['Other Pacific Islander'])
                    && newList && !newList.some((r) => r === RaceEnum['Other Pacific Islander'])) {
                    changes.race_specify_islander = '';
                }
                // if before had "Other" and now doesn't, clear the race_specify_other field
                if (prevList && prevList.some((r) => r === RaceEnum.Other)
                    && newList && !newList.some((r) => r === RaceEnum.Other)) {
                    changes.race_specify_other = '';
                }

                if ((prevList && prevList.some(r => r === RaceEnum['Hispanic/Latino (Specify)']))
                    && (newList && !newList.some(r => r === RaceEnum['Hispanic/Latino (Specify)']))) {
                    changes.race_specify_other_hispanic = '';
                }
                return changes;
            },
            this.validateRace,
        );
    };

    handleIsUsCitizenChange = (isUsCitizen: YesNoUnknownEnum) => {

        this.updateData({ isUsCitizen }, this.validateIsUsCitizen);
    };

    handleAncestryChange = (ancestryList: string[]) => {
        this.updateData(
            prevState => {
                if (isAncestryList(ancestryList)) {
                    const changes: Partial<DeathCertificateRaceType> = {
                        ancestry: ancestryList
                    };

                    if (prevState.americanIndian_specifiy_tribe && prevState.americanIndian_specifiy_tribe.length > 0) {
                        changes.americanIndian_specifiy_tribe = '';
                    }

                    return changes;
                }

                return {};
            },
            this.validateAncestry
        );
    };

    handleAmericanIndianTribeChange = (value: string) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.updateData({ americanIndian_specifiy_tribe: value }, this.validateAmericanIndianTribe);
    };

    validateEnrolledOrPrinciple = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            enrolledOrPrincipleValidator: raceValidators.enrolledOrPrinciple(localData, dcConfig, user)
        });
    };

    validateOtherOrigin = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            otherOriginValidator: raceValidators.hispanicOriginSpecifyOther(localData, dcConfig, user)
        });
    };

    validateOtherPacificIslander = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            otherPacificIslanderValidator: raceValidators.otherPacificIslander(localData, dcConfig, user)
        });
    };

    validateHispanicOrigin = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            hispanicOriginValidator: raceValidators.hispanicOrigin(localData, dcConfig, user)
        });
    };

    validateOtherAsian = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            otherAsianValidator: raceValidators.otherAsian(localData, dcConfig, user)
        });
    };

    validateRace = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            raceValidator: raceValidators.race(localData, dcConfig, user),
        });
    };

    validateOtherRace = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            otherRaceValidator: raceValidators.otherRace(localData, dcConfig, user),
        });
    };

    validateIsUsCitizen = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            isUsCitizenValidator: raceValidators.isUsCitizen(localData, dcConfig, user),
        });
    };

    validateAncestry = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            ancestryValidator: raceValidators.ancestry(localData, dcConfig, user),
        });
    };

    validateAmericanIndianTribe = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            americanIndianTribeValidator: raceValidators.americanIndianTribe(localData, dcConfig, user),
        });
    };

    validateRaceHispanicOther = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            raceHispanicSpecifyValidator: raceValidators.hispanicRaceSpecify(localData, dcConfig, user),
        });
    };

    validateOtherOtherAsian = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            raceOtherOtherAsianValidator: raceValidators.otherOtherAsian(localData, dcConfig, user),
        });
    };

    checkValid() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return raceMasterValidators.valid(localData, dcConfig, user);
    }

    validateAll() {
        this.validateEnrolledOrPrinciple();
        this.validateOtherOrigin();
        this.validateOtherPacificIslander();
        this.validateHispanicOrigin();
        this.validateOtherAsian();
        this.validateRace();
        this.validateOtherRace();
        this.validateIsUsCitizen();
        this.validateAncestry();
        this.validateAmericanIndianTribe();
        this.validateRaceHispanicOther();
    }

    checkIfTouched() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return raceMasterValidators.touched(localData, dcConfig, user);
    }

    getContentHispanicOriginOther = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            otherOriginValidator,
            localData,
        } = this.state;
        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid item className={classNames(classes.width100, classes.textRight)}>
                    <ConfigurableTextField
                        label={'Please specify "Other" here'}
                        error={otherOriginValidator === false}
                        id={ConfigurableRaceFieldKey.hispanicOriginOther}
                        value={localData.hispanicOrigin_specify_other || ''}
                        onChange={this.handleHispanicOriginSpecifyOtherChange}
                        onBlur={this.validateOtherOrigin}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );

    };

    getContentIndianTribe = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            enrolledOrPrincipleValidator,
            localData,
        } = this.state;
        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid
                    item
                    className={classNames(
                        classes.width100,
                        classes.textRight,
                    )}
                >
                    <ConfigurableTextField
                        error={enrolledOrPrincipleValidator === false}
                        label="Name of enrolled or principle tribe"
                        id={ConfigurableRaceFieldKey.indianTribe}
                        value={localData.race_specify_indian_tribe || ''}
                        onChange={this.handleSpecifyIndianTribeChange}
                        onBlur={this.validateEnrolledOrPrinciple}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    getContentOtherAsian = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            otherAsianValidator,
            localData,
        } = this.state;

        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid item className={classNames(classes.width100, classes.textRight)}>
                    <ConfigurableTextField
                        error={otherAsianValidator === false}
                        label={'Specify "Asian"'}
                        id={ConfigurableRaceFieldKey.asianOther}
                        value={localData.race_specify_asian || ''}
                        onChange={this.handleSpecifyAsian}
                        onBlur={this.validateOtherAsian}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    getOtherAsianField = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            otherAsianValidator,
            localData,
        } = this.state;

        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid item className={classNames(classes.width100, classes.textRight)}>
                    <ConfigurableTextField
                        error={otherAsianValidator === false}
                        label={'Specify "Other Asian"'}
                        id={ConfigurableRaceFieldKey.otherOtherAsian}
                        value={localData.race_specify_other_asian || ''}
                        onChange={this.handleOtherAsian}
                        onBlur={this.validateOtherOtherAsian}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    getContentOtherPacificIslander = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            otherPacificIslanderValidator,
            localData,
        } = this.state;

        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid item className={classNames(classes.width100, classes.textRight)}>
                    <ConfigurableTextField
                        error={otherPacificIslanderValidator === false}
                        label={'Specify "Other Pacific Islander"'}
                        id={ConfigurableRaceFieldKey.otherPacificIslander}
                        value={localData.race_specify_islander || ''}
                        onChange={this.handleSpecifyIslanderChange}
                        onBlur={this.validateOtherPacificIslander}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    getContentRaceOther = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            otherRaceValidator,
            localData,
        } = this.state;
        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid item className={classNames(classes.width100, classes.textRight)}>
                    <ConfigurableTextField
                        error={otherRaceValidator === false}
                        label={'Please Specify "Other"'}
                        id={ConfigurableRaceFieldKey.otherRace}
                        value={localData.race_specify_other || ''}
                        onChange={this.handleSpecifyOtherChange}
                        onBlur={this.validateOtherRace}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    getLatinoHispanicOther = () => {
        const {
            classes,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            raceHispanicSpecifyValidator,
            localData,
        } = this.state;
        return (
            <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                className={classNames(classes.inputTextWidth, classes.marginAuto)}
            >
                <Grid item className={classNames(classes.width100, classes.textRight)}>
                    <ConfigurableTextField
                        label={'Please specify here'}
                        error={raceHispanicSpecifyValidator === false}
                        id={ConfigurableRaceFieldKey.otherHispanicRace}
                        value={localData.race_specify_other_hispanic || ''}
                        onChange={this.handleRaceHispanicChange}
                        onBlur={this.validateRaceHispanicOther}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                        rootClass={classNames(
                            classes.inputSubTextWidth,
                            classes.marginTop10
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            caseFname,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            zIndex,
            gotoApproval,
            isEditMode
        } = this.props;
        const {
            hispanicOriginValidator,
            raceValidator,
            localData,
            isUsCitizenValidator,
            ancestryValidator,
            americanIndianTribeValidator
        } = this.state;
        const {
            race,
            hispanicOrigin,
            ancestry
        } = localData;

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Race" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(classes.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classes.width100}>
                                <ConfigurableMultiSelectForAncestry
                                    id={ConfigurableRaceFieldKey.ancestry}
                                    options={values(AncestryEnum)}
                                    selectedOptions={values(ancestry)}
                                    disabled={isDeathCertificateLocked}
                                    zIndex={zIndex}
                                    validator={this.validateAncestry}
                                    isEditMode={isEditMode}
                                    label={`${caseFname}'s Ancestry`}
                                    onSelect={this.handleAncestryChange}
                                    error={ancestryValidator === false}
                                    onClick={handleOpenSnackbar}
                                />
                            </Grid>
                        </Grid>

                        {(ancestry
                            && ancestry.some(a => AncestryEnum[a] === AncestryEnum['American Indian (Specify Tribe)']))
                            && <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="flex-end"
                                className={classNames(classes.inputTextWidth, classes.marginAuto)}
                            >
                                <Grid item className={classNames(classes.width100, classes.textRight)}>
                                    <ConfigurableTextField
                                        id={ConfigurableRaceFieldKey.americanIndianTribe}
                                        label={'Please specify American Indian Tribe'}
                                        error={americanIndianTribeValidator === false}
                                        value={localData.americanIndian_specifiy_tribe || ''}
                                        onChange={this.handleAmericanIndianTribeChange}
                                        onBlur={this.validateAmericanIndianTribe}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        isEditMode={isEditMode}
                                        rootClass={classNames(
                                            classes.inputSubTextWidth,
                                            classes.marginTop10
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        }

                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classes.width100}>
                                <ConfigurableMultiSelectForHispanicOrigin
                                    id={ConfigurableRaceFieldKey.ofHispanicOrigins}
                                    options={values(HispanicOriginEnum)}
                                    selectedOptions={values(hispanicOrigin)}
                                    disabled={isDeathCertificateLocked}
                                    zIndex={zIndex}
                                    validator={this.validateHispanicOrigin}
                                    isEditMode={isEditMode}
                                    label={`Is ${caseFname} of hispanic origin?`}
                                    onSelect={this.handleHispanicOriginChangeEvent}
                                    error={hispanicOriginValidator === false}
                                    onClick={handleOpenSnackbar}
                                />
                            </Grid>
                        </Grid>

                        {hispanicOrigin
                            && hispanicOrigin.some(item => 'Yes, Other Spanish/Hispanic/Latino' === item)
                            ? this.getContentHispanicOriginOther()
                            : null}

                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classNames(classes.width100, classes.textCenter)}>
                                <ConfigurableMultiSelectForRace
                                    id={ConfigurableRaceFieldKey.personRace}
                                    options={values(RaceEnum)}
                                    selectedOptions={race || null}
                                    disabled={isDeathCertificateLocked}
                                    zIndex={zIndex}
                                    validator={this.validateRace}
                                    isEditMode={isEditMode}
                                    label={`What is ${caseFname}'s Race?`}
                                    onSelect={this.handleRaceChangeEvent}
                                    onClick={handleOpenSnackbar}
                                    error={raceValidator === false}
                                />
                            </Grid>
                        </Grid>

                        {race
                            && race.some((item: string) => RaceEnum['American Indian or Alaska Native'] === item)
                            ? this.getContentIndianTribe() : null}

                        {race
                            && race.some((item: string) => RaceEnum['Asian (Specify)'] === item)
                            ? this.getContentOtherAsian() : null}

                        {race && race.some(r => r === RaceEnum['Other Asian']) ? this.getOtherAsianField() : null}

                        {race && race.some((item: string) => RaceEnum['Other Pacific Islander'] === item) ?
                            this.getContentOtherPacificIslander() : null}

                        {race && race.some((item: string) => RaceEnum.Other === item) ?
                            this.getContentRaceOther() : null}

                        {race
                            && race.some(item => RaceEnum['Hispanic/Latino (Specify)'] === item)
                            ? this.getLatinoHispanicOther()
                            : null}

                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classes.width100}>
                                <ConfigurableToggleButtonsForYesNoUnknown
                                    id={ConfigurableRaceFieldKey.usCitizen}
                                    error={isUsCitizenValidator === false}
                                    rootClass={classes.marginTop40}
                                    labelClass={'width-100'}
                                    label={`Is ${caseFname} a U.S. Citizen?`}
                                    onSelect={this.handleIsUsCitizenChange}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    options={values(YesNoUnknownEnum)}
                                    isEditMode={isEditMode}
                                    selected={localData.isUsCitizen || null}
                                    disabled={isDeathCertificateLocked}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid className={classes.buttonContainer}>
                        <Button color="primary" variant="contained" onClick={gotoApproval}>
                            Approve Answers
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withGStyles(styles)(RaceForm);
