import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import EmailIcon from '@mui/icons-material/Email';
import CloudUploadIcon from '@mui/icons-material/CloudDownload';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import { ESignUserListStyledProps, styleWrapper } from './styles';

import UserAvatar from '../../../components/common/UserAvatar';
import {
    DocPacketSignerUX, DocUX, DocDownloadResponse, DocPacketUX, GatherCaseUX
} from '../../../shared/types';
import { AvatarUser, getCaseEntity } from '../../../shared/types/user';
import { StoreState } from '../../../types';
import { downloadFromURL } from '../../../services/doc.service';
import SendEmailReminderPopover from './SendEmailReminderPopover';
import HelperPopper from '../../family/helperPopper/HelperPopper';
import { remindDocPacketSigner, updateDocPacketSignerEmail } from '../../../actions/DocPacket.action';
import ChangeOriginalValueDialog from '../../common/ChangeOriginalValueDialog';
import { isValidEmail } from '../../../shared/utils';
import { canEditPersonPhoneOrEmail } from '../../../shared/authority/can';
import { getRelationshipDisplayValue } from '../../common/AcquaintanceButtons';
import { AppDispatch } from '../../../store';
import withGStyles from '../../../styles/WithGStyles';
import withState from '../../common/utilHOC/WithState';
import GPopper from '../../common/GPopper';
import { AppRoute } from '../../../services';
import GLink from '../../common/GLink';

function mapStateToProps({ casesState, userSession }: StoreState) {
    return {
        entityCaseDetails: casesState,
        selectedCase: casesState.selectedCase,
        userSession
    };
}

type Props = ReturnType<typeof mapStateToProps> & {
    dispatch: AppDispatch;
    packetLink?: AppRoute;
    signedDoc: DocUX | null;
    packetSigners: DocPacketSignerUX[];
    zIndex: number;
    onDownloadSignedDoc: (doc: DocUX) => Promise<DocDownloadResponse | null>;
    isChangeEmailDialogOpen?: (isOpen: boolean) => void;
    isHelperPopperOpen?: (isOpen: boolean) => void;
    setReminderAnchorEl: (anchorEl: HTMLElement | null) => void;
    reminderAnchorEl: HTMLElement | null;
    menuAnchorEle: HTMLElement | null;
    setMenuAnchorEle: (anchorEl: HTMLElement | null) => void;
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
};

interface State {
    helperPopperAnchorEle: HTMLElement | null;
    targetSigner: DocPacketSignerUX | null;
    isChangeEmailDialogOpen: boolean;
}

type CombinedProps = Props & ESignUserListStyledProps;

class ESignSignatures extends React.Component<CombinedProps, State> {
    state: State = {
        helperPopperAnchorEle: null,
        targetSigner: null,
        isChangeEmailDialogOpen: false,
    };

    handleDocDownload = async (e: React.MouseEvent<HTMLElement>) => {
        const { signedDoc, onDownloadSignedDoc } = this.props;

        e.preventDefault();
        e.stopPropagation();

        if (!signedDoc) {
            return;
        }
        const downloadDocResult = await onDownloadSignedDoc(signedDoc);
        if (!downloadDocResult) {
            return;
        }
        downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
    };

    secondaryText = (signer: DocPacketSignerUX) => {
        const { classes, docPacket } = this.props;
        const isSigned = signer.hellosign_status === 'signed';
        const isViewed = signer.hellosign_status === 'viewed';
        const fromNow = signer.hellosign_status_time ? moment(signer.hellosign_status_time).fromNow() : '';

        const { history } = signer;
        const thisHistory = history && history.filter(obj => (
            obj.doc_packet_id === docPacket.id && obj.person_id === signer.person_id));
        const emailChangeDetected = thisHistory && thisHistory.length > 0 ?
            thisHistory[0].email !== signer.person.email : false;
        const lastSentDetails = thisHistory && thisHistory.length > 0 ? thisHistory[0] : null;
        const lastSentFromNow = lastSentDetails ? moment(lastSentDetails.sent_time).fromNow() : '';

        const signerEmail = signer.person.email;
        const displayMessage = docPacket.status === 'started' && docPacket.type === 'fill_esign'
            ? signerEmail && `Will send to ${signerEmail}`
            : emailChangeDetected && lastSentDetails
                ? `Sent to ${lastSentDetails.email}`
                : signerEmail && `Sent to ${signerEmail}`;
        const emailChangedMessage = emailChangeDetected && lastSentDetails ?
            `${signer.person.fname}'s email was updated to ${signerEmail || ''}. You may need to send a new reminder`
            : '';

        return (
            <span className={classes.secondaryTextContainer}>
                <Typography component="span" className={classes.sendToEmail}>
                    {displayMessage}
                    {lastSentFromNow &&
                        <span>
                            &nbsp;({lastSentFromNow})
                        </span>
                    }
                </Typography>
                {emailChangeDetected &&
                    <Typography component={'span'} className={classes.updatedEmail}>
                        {emailChangedMessage}
                    </Typography>
                }
                <span className={classes.chipButtons}>
                    <span
                        className={classNames(
                            classes.fabExtended,
                            isSigned ? classes.signed : classes.started,
                            isViewed && classes.viewed,
                        )}
                    >
                        {isSigned ? `Signed ${fromNow}` :
                            signer.hellosign_status === 'viewed' ? `Viewed ${fromNow}` :
                                docPacket.status === 'started' ? 'Not yet sent' :
                                    'Awaiting Signature'}
                    </span>
                    {!isSigned && docPacket.status !== 'started' && <span

                        className={classNames(
                            classes.fabExtended,
                            classes.remindFabButton,
                            classes.menu,
                        )}
                        onClick={e => this.openReminderPopup(e, signer)}
                    >
                        <AccessAlarmIcon /> Remind
                    </span>}
                </span>
            </span>
        );
    };

    openHelperPopper = (
        event: React.MouseEvent<HTMLDivElement>,
        person: DocPacketSignerUX,
    ) => {
        const { isHelperPopperOpen } = this.props;
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            targetSigner: person,
            helperPopperAnchorEle: event.currentTarget,
        });
        if (isHelperPopperOpen) {
            isHelperPopperOpen(true);
        }
    };

    openMenu = (e: React.MouseEvent<HTMLElement>, targetSigner: DocPacketSignerUX) => {
        const { setMenuAnchorEle } = this.props;
        e.preventDefault();
        e.stopPropagation();
        this.setState({ targetSigner });
        setMenuAnchorEle(e.currentTarget);
    };

    closeMenu = () => {
        const { setMenuAnchorEle, reminderAnchorEl } = this.props;
        const { isChangeEmailDialogOpen } = this.state;

        if (isChangeEmailDialogOpen || reminderAnchorEl) {
            return;
        }

        setMenuAnchorEle(null);
        this.setState({ targetSigner: null });
    };

    changeEmailAddress = () => {
        const { isChangeEmailDialogOpen } = this.props;
        this.setState({
            isChangeEmailDialogOpen: true
        });
        if (isChangeEmailDialogOpen) {
            isChangeEmailDialogOpen(true);
        }
    };

    closeChangeEmailAddressDialog = () => {
        const { isChangeEmailDialogOpen } = this.props;
        this.setState(
            {
                isChangeEmailDialogOpen: false,
                targetSigner: null
            },
            () => this.closeMenu()
        );
        if (isChangeEmailDialogOpen) {
            isChangeEmailDialogOpen(false);
        }
    };

    openReminderPopup = (e: React.MouseEvent<HTMLElement>, targetSigner: DocPacketSignerUX) => {
        const { setReminderAnchorEl } = this.props;
        e.preventDefault();
        e.stopPropagation();
        setReminderAnchorEl(e.currentTarget);
        this.setState({
            targetSigner,
        });
    };

    renderMenuOptions = () => {
        const { classes, zIndex, menuAnchorEle, userSession, activeCase, docPacket } = this.props;
        const { targetSigner } = this.state;
        if (!targetSigner || !userSession.userData) {
            return null;
        }
        const canSendReminder = targetSigner.hellosign_status !== 'signed' &&
            docPacket.status !== 'started' && docPacket.type !== 'fill_print';
        const reminderTooltip = canSendReminder ? '' :
            docPacket.type === 'fill_print' ? 'Printed documents do not have email reminders' :
                docPacket.status === 'started' ? 'Finalize packet to send notification email' :
                    'Signer has already signed, no reminder can be sent';

        const canChangeEmail = targetSigner.hellosign_status !== 'signed' &&
            canEditPersonPhoneOrEmail(
                userSession.userData,
                targetSigner.person,
                activeCase.funeral_home_id,
                activeCase.id,
            );
        const editSignerTooltip = targetSigner.hellosign_status === 'signed'
            ? 'Signer has already signed using current email'
            : canChangeEmail ? ''
                : 'Please contact the user to change their own email';

        return (
            <GPopper
                anchorEle={menuAnchorEle}
                className={classes.menu}
                paperClass={classes.paper}
                zIndex={zIndex + 2}
                closePopper={e => this.closeMenu()}
                hideArrow
            >
                <Tooltip
                    title={reminderTooltip}
                    placement="top"
                >
                    <span className={classes.menuItem}>
                        <MenuItem disabled={!canSendReminder} onClick={
                            e => this.openReminderPopup(e, targetSigner)
                        }>
                            <AccessAlarmIcon />&nbsp;Send a Reminder
                        </MenuItem>
                    </span>
                </Tooltip>
                <Tooltip
                    title={editSignerTooltip}
                    placement="top"
                >
                    <div>
                        <MenuItem disabled={!canChangeEmail} onClick={this.changeEmailAddress}>
                            <EmailIcon />&nbsp;Change Email Address
                        </MenuItem>
                    </div>
                </Tooltip>
            </GPopper>
        );
    };

    renderUserListItem = (signer: DocPacketSignerUX) => {
        const { classes, activeCase } = this.props;
        const { fname, lname, photo, photo_transformations } = signer.person;
        const user: AvatarUser = {
            fname,
            lname,
            photo,
            photo_transformations,
        };
        const caseEntity = getCaseEntity(signer.person, activeCase.id);
        // const caseDetail = entityCaseDetails.find((c) => c.entity_id === signer.person_id);
        const { relationship, relationship_alias, relationship_type } = caseEntity || {
            relationship: null, relationship_alias: '', relationship_type: null
        };
        const displayRelationship = getRelationshipDisplayValue(
            { relationship, relationship_alias, relationship_type }, true);

        return (
            <ListItem key={signer.id} className={classes.listItem}>
                <Avatar className={classes.avatarClass}>
                    <UserAvatar
                        user={user}
                        className={classes.signerAvatar}
                        onClick={(e) => this.openHelperPopper(e, signer)}
                        size={52}
                    />
                </Avatar>
                <div className={classes.marginLeft8}>
                    <ListItemText
                        className={classes.styleUserName}
                        primary={
                            <>
                                <span className={classes.signerName}>
                                    {fname} {lname || ''}
                                    <span>{displayRelationship && `(${displayRelationship})`}</span>
                                </span>
                                <IconButton
                                    className={classes.moreIconButton}
                                    onClick={
                                        (e) => this.openMenu(e, signer)
                                    }
                                    size="large">
                                    <MoreVertIcon />
                                </IconButton>
                            </>
                        }
                        secondary={this.secondaryText(signer)}
                        classes={{
                            primary: classes.primary,
                            secondary: classes.secondary
                        }}
                    />
                </div>
            </ListItem>
        );
    };

    rendercontent = () => {
        const { classes, packetSigners, signedDoc } = this.props;

        return (
            <>
                <div className={classes.upperSection}>
                    <div className={classes.counterSection}>
                        <Typography color="primary" className={classes.packetCount}>
                            {packetSigners.filter((signer) => signer.hellosign_status === 'signed').length}
                        </Typography>
                        <Typography color="primary" className={classes.middleText}>
                            of
                        </Typography>
                        <Typography color="primary" className={classes.packetCount}>
                            {packetSigners.length}
                        </Typography>
                    </div>
                    <div className={classes.signatureStatus}>
                        <Typography color="primary" className={classes.statusText}>
                            Signatures Completed
                        </Typography>
                    </div>
                </div>
                <List className={classes.listContainer}>
                    {packetSigners.map((signer) => this.renderUserListItem(signer))}
                </List>
                {signedDoc &&
                    <div className={classes.upperSection}>
                        <Button
                            variant="contained"
                            color="primary"
                            aria-label="download signed doc"
                            onClick={this.handleDocDownload}
                        >
                            <CloudUploadIcon />
                            &nbsp;Download Signed Packet
                        </Button>
                    </div>
                }
            </>
        );
    };

    renderHelperPopper = () => {
        const { activeCase, zIndex } = this.props;
        const { helperPopperAnchorEle, targetSigner } = this.state;
        if (!activeCase) {
            return null;
        }

        return (
            <HelperPopper
                key={targetSigner && targetSigner.person.entity_id}
                zIndex={zIndex + 1}
                clickAwayListener={this.clickAwayListener}
                selectedCase={activeCase}
                activeEntityId={targetSigner && targetSigner.person.entity_id}
                popperAnchorEle={helperPopperAnchorEle}
                closeHelperPopper={() => this.closeHelperPopper()}
            />
        );
    };

    renderEmailReminder = () => {
        const { zIndex, reminderAnchorEl, docPacket } = this.props;
        const { targetSigner } = this.state;
        if (!targetSigner || !reminderAnchorEl) {
            return null;
        }
        const lastSentTime = targetSigner.last_reminder_sent_time || docPacket.notification_sent_time;
        return (
            <SendEmailReminderPopover
                zIndex={zIndex + 2}
                targetSigner={targetSigner.person}
                reminderAnchorEl={reminderAnchorEl}
                clickAwayListener={this.closeReminderPopup}
                sendReminderClick={this.handleSendReminderClick}
                lastSentTime={lastSentTime}
                docPacketStatus={docPacket.status}
            />
        );
    };

    renderChangeEmailDialog = () => {
        const { zIndex } = this.props;
        const { targetSigner, isChangeEmailDialogOpen } = this.state;

        if (!targetSigner) {
            return;
        }
        const headerMessage = `Change ${targetSigner.person.fname}'s Email`;
        const subHeaderMessage = targetSigner.person.user
            ? `${targetSigner.person.fname} will be reinvited to Gather using new email`
            : '';
        const inputMessage = `Enter ${targetSigner.person.fname}'s new email`;
        const confirmationButtonText = targetSigner.person.user
            ? 'Update Email & Resend Invite'
            : 'Update Email';
        const cancelButtonText = '';
        const currentDetail = <>Current Email: <b>{targetSigner.person.email}</b></>;
        return (
            <ChangeOriginalValueDialog
                header={headerMessage}
                subHeader={subHeaderMessage}
                confirmationButtonText={confirmationButtonText}
                cancelButtonText={cancelButtonText}
                open={isChangeEmailDialogOpen}
                onClose={this.closeChangeEmailAddressDialog}
                onSave={this.handleChangeEmailDialogConfirm}
                zIndex={zIndex + 1}
                originalValue={targetSigner.person.email}
                validator={isValidEmail}
                newValueInputTextMessage={inputMessage}
                currentDetail={currentDetail}
            />
        );
    };

    render() {
        const { classes, packetLink } = this.props;
        const { targetSigner } = this.state;

        return (
            <Grid container justifyContent="center" alignItems="center" className={classes.root}>
                {packetLink ?
                    <GLink
                        to={packetLink}
                        linkClass={classNames(
                            classes.link,
                            targetSigner && classes.pointerEventsNone
                        )}
                    >
                        {this.rendercontent()}
                    </GLink>
                    : this.rendercontent()
                }
                {this.renderMenuOptions()}
                {this.renderHelperPopper()}
                {this.renderEmailReminder()}
                {this.renderChangeEmailDialog()}
            </Grid>
        );
    }

    closeReminderPopup = () => {
        const { setReminderAnchorEl } = this.props;

        setReminderAnchorEl(null);

        this.setState(
            { targetSigner: null },
            () => this.closeMenu()
        );
    };

    handleChangeEmailDialogConfirm = (newEmailAddress: string) => {
        const { dispatch, docPacket, activeCase, } = this.props;
        const { targetSigner } = this.state;
        // process the local state var 
        if (!newEmailAddress || !targetSigner) { // empty string
            return;
        }
        const isValid = isValidEmail(newEmailAddress);
        if (!isValid) {
            return;
        }
        // Here we can process the updated email
        dispatch(updateDocPacketSignerEmail(docPacket, activeCase, targetSigner, newEmailAddress));
        this.closeChangeEmailAddressDialog();
    };

    handleSendReminderClick = () => {
        const { dispatch, docPacket, activeCase } = this.props;
        const { targetSigner } = this.state;
        if (!targetSigner || !targetSigner.person.email) {
            return;
        }
        dispatch(remindDocPacketSigner(
            docPacket, activeCase, targetSigner
        ));
        this.closeReminderPopup();
    };

    clickAwayListener = () => {
        this.closeHelperPopper();
        this.closeReminderPopup();
    };

    closeHelperPopper = () => {
        const { isHelperPopperOpen } = this.props;
        this.setState({
            helperPopperAnchorEle: null,
            targetSigner: null
        });
        if (isHelperPopperOpen) {
            isHelperPopperOpen(false);
        }
    };

}

const componentStyles = styleWrapper<Props>();
export default withState(mapStateToProps)(withGStyles(componentStyles)(ESignSignatures));
