import DCCheckbox from '../checkbox/DCCheckbox';
import DCAddressSearch, { Props as DCAddressSearchProps } from '../addressSearch/DCAddressSearch';

export interface Props extends DCAddressSearchProps {
    checkboxLabel: string;
    isChecked: boolean;
    onCheckboxChange: (isChecked: boolean) => void;
}

const DCAddressSearchWithCheckbox = (props: Props) => {

    const {
        error,
        disabled,
        required,
        value,
        searchType,
        label,
        rootClass,
        controlClass,
        textFieldClass,
        labelClass,
        zIndex,
        isChecked,
        checkboxLabel,
        handleOpenSnackbar,
        onChange,
        onCheckboxChange,
        isHiddenFromFamily
    } = props;

    return (
        <>
            <DCAddressSearch
                label={label}
                searchType={searchType}
                rootClass={rootClass}
                controlClass={controlClass}
                textFieldClass={textFieldClass}
                labelClass={labelClass}
                value={value}
                onChange={onChange}
                error={error}
                required={required}
                disabled={isChecked || disabled}
                handleOpenSnackbar={handleOpenSnackbar}
                zIndex={zIndex}
                isHiddenFromFamily={isHiddenFromFamily}
            />
            <DCCheckbox
                label={checkboxLabel}
                onChange={onCheckboxChange}
                handleOpenSnackbar={handleOpenSnackbar}
                isChecked={isChecked}
                disabled={disabled}
                rootClass={rootClass}
                labelClass={labelClass}
            />
        </>
    );
};

export default DCAddressSearchWithCheckbox;
