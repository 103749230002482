export const loadingAnimation = () => {
    return {
        "v": "4.8.0",
        "meta": {
            "g": "LottieFiles AE ",
            "a": "",
            "k": "",
            "d": "",
            "tc": ""
        },
        "fr": 29.9700012207031,
        "ip": 0,
        "op": 241.000009816131,
        "w": 200,
        "h": 200,
        "nm": "Geer",
        "ddd": 0,
        "assets": [

        ],
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 4,
                "nm": "Geer - 1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 240.0000097754,
                                "s": [
                                    -1080
                                ]
                            }
                        ],
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            130.58,
                            119.395,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            33.08,
                            23.395,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -0.142,
                                                -0.014
                                            ],
                                            [
                                                13.024,
                                                -2.541
                                            ],
                                            [
                                                0.142,
                                                0.014
                                            ],
                                            [
                                                -13.017,
                                                2.543
                                            ]
                                        ],
                                        "o": [
                                            [
                                                13.21,
                                                1.254
                                            ],
                                            [
                                                -0.14,
                                                0.027
                                            ],
                                            [
                                                -13.204,
                                                -1.26
                                            ],
                                            [
                                                0.14,
                                                -0.028
                                            ]
                                        ],
                                        "v": [
                                            [
                                                32.565,
                                                13.085
                                            ],
                                            [
                                                33.58,
                                                33.821
                                            ],
                                            [
                                                33.149,
                                                33.842
                                            ],
                                            [
                                                32.134,
                                                13.106
                                            ]
                                        ],
                                        "c": true
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ix": 2,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0.508,
                                                0.336
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.444,
                                                -0.553
                                            ],
                                            [
                                                0.751,
                                                -2.071
                                            ],
                                            [
                                                -0.637,
                                                -0.305
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.102,
                                                -0.592
                                            ],
                                            [
                                                -0.026,
                                                -1.021
                                            ],
                                            [
                                                0.494,
                                                -0.218
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.141,
                                                -0.639
                                            ],
                                            [
                                                -1.177,
                                                -1.917
                                            ],
                                            [
                                                -0.626,
                                                0.273
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.386,
                                                -0.428
                                            ],
                                            [
                                                -0.389,
                                                -0.353
                                            ],
                                            [
                                                -0.387,
                                                -0.29
                                            ],
                                            [
                                                0.156,
                                                -0.536
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.6,
                                                -0.292
                                            ],
                                            [
                                                -2.235,
                                                -0.272
                                            ],
                                            [
                                                -0.183,
                                                0.65
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.57,
                                                0.037
                                            ],
                                            [
                                                -1.049,
                                                0.295
                                            ],
                                            [
                                                -0.311,
                                                -0.531
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.644,
                                                0.309
                                            ],
                                            [
                                                -1.607,
                                                1.594
                                            ],
                                            [
                                                0.336,
                                                0.547
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.291,
                                                0.432
                                            ],
                                            [
                                                -0.38,
                                                0.898
                                            ],
                                            [
                                                -0.6,
                                                -0.077
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.155,
                                                0.687
                                            ],
                                            [
                                                0.233,
                                                2.219
                                            ],
                                            [
                                                0.658,
                                                0.054
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.16,
                                                0.524
                                            ],
                                            [
                                                0.479,
                                                0.894
                                            ],
                                            [
                                                -0.408,
                                                0.418
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.43,
                                                0.539
                                            ],
                                            [
                                                0.815,
                                                0.739
                                            ],
                                            [
                                                0.938,
                                                0.604
                                            ],
                                            [
                                                0.441,
                                                -0.535
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.547,
                                                0.225
                                            ],
                                            [
                                                1.069,
                                                0.192
                                            ],
                                            [
                                                0.067,
                                                0.593
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.695,
                                                0
                                            ],
                                            [
                                                2.093,
                                                -0.707
                                            ],
                                            [
                                                -0.141,
                                                -0.677
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.517,
                                                -0.287
                                            ],
                                            [
                                                0.842,
                                                -0.741
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.592,
                                                -0.391
                                            ],
                                            [
                                                -1.384,
                                                1.727
                                            ],
                                            [
                                                -0.241,
                                                0.664
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.542,
                                                0.26
                                            ],
                                            [
                                                -0.175,
                                                1.01
                                            ],
                                            [
                                                0.014,
                                                0.54
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.599,
                                                0.264
                                            ],
                                            [
                                                0.486,
                                                2.202
                                            ],
                                            [
                                                0.358,
                                                0.582
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.528,
                                                -0.23
                                            ],
                                            [
                                                0.344,
                                                0.382
                                            ],
                                            [
                                                0.365,
                                                0.331
                                            ],
                                            [
                                                0.447,
                                                0.335
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.186,
                                                0.641
                                            ],
                                            [
                                                2.019,
                                                0.984
                                            ],
                                            [
                                                0.67,
                                                0.082
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.155,
                                                -0.549
                                            ],
                                            [
                                                1.082,
                                                -0.07
                                            ],
                                            [
                                                0.593,
                                                -0.167
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.361,
                                                0.617
                                            ],
                                            [
                                                2.018,
                                                -0.97
                                            ],
                                            [
                                                0.456,
                                                -0.452
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.273,
                                                -0.444
                                            ],
                                            [
                                                0.558,
                                                -0.83
                                            ],
                                            [
                                                0.236,
                                                -0.557
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.698,
                                                0.09
                                            ],
                                            [
                                                0.493,
                                                -2.172
                                            ],
                                            [
                                                -0.069,
                                                -0.656
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.546,
                                                -0.044
                                            ],
                                            [
                                                -0.292,
                                                -0.957
                                            ],
                                            [
                                                -0.276,
                                                -0.515
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.482,
                                                -0.493
                                            ],
                                            [
                                                -0.683,
                                                -0.856
                                            ],
                                            [
                                                -0.83,
                                                -0.752
                                            ],
                                            [
                                                -0.583,
                                                -0.376
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.376,
                                                0.456
                                            ],
                                            [
                                                -1.019,
                                                -0.419
                                            ],
                                            [
                                                -0.587,
                                                -0.106
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.079,
                                                -0.691
                                            ],
                                            [
                                                -2.214,
                                                0.001
                                            ],
                                            [
                                                -0.655,
                                                0.222
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.12,
                                                0.579
                                            ],
                                            [
                                                -0.951,
                                                0.528
                                            ],
                                            [
                                                -0.457,
                                                0.402
                                            ]
                                        ],
                                        "v": [
                                            [
                                                20.986,
                                                11.924
                                            ],
                                            [
                                                18.86,
                                                10.521
                                            ],
                                            [
                                                17.035,
                                                10.817
                                            ],
                                            [
                                                13.823,
                                                16.528
                                            ],
                                            [
                                                14.512,
                                                18.238
                                            ],
                                            [
                                                16.836,
                                                19.351
                                            ],
                                            [
                                                17.589,
                                                20.788
                                            ],
                                            [
                                                17.367,
                                                23.842
                                            ],
                                            [
                                                16.556,
                                                25.09
                                            ],
                                            [
                                                13.86,
                                                26.279
                                            ],
                                            [
                                                13.08,
                                                27.833
                                            ],
                                            [
                                                15.602,
                                                34.076
                                            ],
                                            [
                                                17.315,
                                                34.618
                                            ],
                                            [
                                                19.809,
                                                33.53
                                            ],
                                            [
                                                21.344,
                                                33.849
                                            ],
                                            [
                                                22.442,
                                                34.952
                                            ],
                                            [
                                                23.571,
                                                35.883
                                            ],
                                            [
                                                24.045,
                                                37.335
                                            ],
                                            [
                                                23.253,
                                                40.057
                                            ],
                                            [
                                                23.973,
                                                41.669
                                            ],
                                            [
                                                30.42,
                                                43.572
                                            ],
                                            [
                                                31.906,
                                                42.588
                                            ],
                                            [
                                                32.661,
                                                39.914
                                            ],
                                            [
                                                33.866,
                                                38.93
                                            ],
                                            [
                                                37.072,
                                                38.382
                                            ],
                                            [
                                                38.606,
                                                39.003
                                            ],
                                            [
                                                39.878,
                                                41.176
                                            ],
                                            [
                                                41.659,
                                                41.713
                                            ],
                                            [
                                                47.108,
                                                37.86
                                            ],
                                            [
                                                47.327,
                                                36.16
                                            ],
                                            [
                                                45.722,
                                                33.551
                                            ],
                                            [
                                                45.733,
                                                32.107
                                            ],
                                            [
                                                47.141,
                                                29.51
                                            ],
                                            [
                                                48.557,
                                                28.703
                                            ],
                                            [
                                                51.102,
                                                29.03
                                            ],
                                            [
                                                52.607,
                                                27.975
                                            ],
                                            [
                                                53.001,
                                                21.323
                                            ],
                                            [
                                                51.752,
                                                20.096
                                            ],
                                            [
                                                48.867,
                                                19.862
                                            ],
                                            [
                                                47.686,
                                                18.929
                                            ],
                                            [
                                                46.53,
                                                16.146
                                            ],
                                            [
                                                46.764,
                                                14.575
                                            ],
                                            [
                                                48.628,
                                                12.665
                                            ],
                                            [
                                                48.717,
                                                10.857
                                            ],
                                            [
                                                46.465,
                                                8.458
                                            ],
                                            [
                                                43.806,
                                                6.418
                                            ],
                                            [
                                                42.01,
                                                6.695
                                            ],
                                            [
                                                40.326,
                                                8.736
                                            ],
                                            [
                                                38.773,
                                                9.13
                                            ],
                                            [
                                                35.631,
                                                8.212
                                            ],
                                            [
                                                34.53,
                                                7.033
                                            ],
                                            [
                                                34.233,
                                                4.419
                                            ],
                                            [
                                                32.871,
                                                3.208
                                            ],
                                            [
                                                26.347,
                                                4.281
                                            ],
                                            [
                                                25.443,
                                                5.854
                                            ],
                                            [
                                                25.984,
                                                8.455
                                            ],
                                            [
                                                25.321,
                                                9.912
                                            ],
                                            [
                                                22.622,
                                                11.815
                                            ]
                                        ],
                                        "c": true
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 2",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.9411764705882353,
                                        0.8470588235294118,
                                        0.8431372549019608,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Group 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 360.000014663101,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 4,
                "nm": "Geer - 2",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 240.0000097754,
                                "s": [
                                    -720
                                ]
                            }
                        ],
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            77.253,
                            136.298,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            -22.747,
                            36.298,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -0.203,
                                                0.074
                                            ],
                                            [
                                                16.172,
                                                -11.958
                                            ],
                                            [
                                                0.203,
                                                -0.074
                                            ],
                                            [
                                                -16.161,
                                                11.957
                                            ]
                                        ],
                                        "o": [
                                            [
                                                18.897,
                                                -6.885
                                            ],
                                            [
                                                -0.174,
                                                0.128
                                            ],
                                            [
                                                -18.893,
                                                6.872
                                            ],
                                            [
                                                0.173,
                                                -0.128
                                            ]
                                        ],
                                        "v": [
                                            [
                                                -29.561,
                                                22.169
                                            ],
                                            [
                                                -14.669,
                                                49.89
                                            ],
                                            [
                                                -15.245,
                                                50.199
                                            ],
                                            [
                                                -30.137,
                                                22.479
                                            ]
                                        ],
                                        "c": true
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ix": 2,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0.914,
                                                0.128
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.247,
                                                -1.046
                                            ],
                                            [
                                                -0.32,
                                                -3.324
                                            ],
                                            [
                                                -1.071,
                                                -0.003
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.246,
                                                -0.878
                                            ],
                                            [
                                                -0.7,
                                                -1.38
                                            ],
                                            [
                                                0.535,
                                                -0.62
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.609,
                                                -0.782
                                            ],
                                            [
                                                -2.859,
                                                -1.857
                                            ],
                                            [
                                                -0.679,
                                                0.781
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.806,
                                                -0.335
                                            ],
                                            [
                                                -0.762,
                                                -0.229
                                            ],
                                            [
                                                -0.718,
                                                -0.144
                                            ],
                                            [
                                                -0.135,
                                                -0.835
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.011,
                                                -0.009
                                            ],
                                            [
                                                -3.236,
                                                1.083
                                            ],
                                            [
                                                0.172,
                                                1.009
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.755,
                                                0.422
                                            ],
                                            [
                                                -1.244,
                                                1.086
                                            ],
                                            [
                                                -0.771,
                                                -0.525
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.681,
                                                0.843
                                            ],
                                            [
                                                -1.161,
                                                3.228
                                            ],
                                            [
                                                0.817,
                                                0.53
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.117,
                                                0.781
                                            ],
                                            [
                                                0.065,
                                                1.476
                                            ],
                                            [
                                                -0.871,
                                                0.285
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.235,
                                                1.041
                                            ],
                                            [
                                                1.764,
                                                2.885
                                            ],
                                            [
                                                0.935,
                                                -0.355
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.559,
                                                0.613
                                            ],
                                            [
                                                1.237,
                                                0.912
                                            ],
                                            [
                                                -0.287,
                                                0.839
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.939,
                                                0.458
                                            ],
                                            [
                                                1.596,
                                                0.481
                                            ],
                                            [
                                                1.678,
                                                0.216
                                            ],
                                            [
                                                0.256,
                                                -1.019
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.895,
                                                -0.048
                                            ],
                                            [
                                                1.589,
                                                -0.433
                                            ],
                                            [
                                                0.478,
                                                0.768
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.952,
                                                -0.453
                                            ],
                                            [
                                                2.405,
                                                -2.331
                                            ],
                                            [
                                                -0.633,
                                                -0.835
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.521,
                                                -0.73
                                            ],
                                            [
                                                0.67,
                                                -1.562
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.065,
                                                -0.149
                                            ],
                                            [
                                                -0.771,
                                                3.265
                                            ],
                                            [
                                                0.102,
                                                1.066
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.911,
                                                0.003
                                            ],
                                            [
                                                0.418,
                                                1.497
                                            ],
                                            [
                                                0.37,
                                                0.73
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.648,
                                                0.751
                                            ],
                                            [
                                                2.1,
                                                2.698
                                            ],
                                            [
                                                0.868,
                                                0.563
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.573,
                                                -0.659
                                            ],
                                            [
                                                0.719,
                                                0.299
                                            ],
                                            [
                                                0.716,
                                                0.215
                                            ],
                                            [
                                                0.829,
                                                0.167
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.162,
                                                0.998
                                            ],
                                            [
                                                3.404,
                                                0.032
                                            ],
                                            [
                                                0.971,
                                                -0.325
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.146,
                                                -0.852
                                            ],
                                            [
                                                1.436,
                                                -0.801
                                            ],
                                            [
                                                0.703,
                                                -0.614
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.895,
                                                0.609
                                            ],
                                            [
                                                2.131,
                                                -2.642
                                            ],
                                            [
                                                0.33,
                                                -0.916
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.663,
                                                -0.429
                                            ],
                                            [
                                                0.224,
                                                -1.499
                                            ],
                                            [
                                                -0.04,
                                                -0.915
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.014,
                                                -0.331
                                            ],
                                            [
                                                -0.74,
                                                -3.293
                                            ],
                                            [
                                                -0.522,
                                                -0.853
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.776,
                                                0.295
                                            ],
                                            [
                                                -1.022,
                                                -1.119
                                            ],
                                            [
                                                -0.713,
                                                -0.526
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.338,
                                                -0.988
                                            ],
                                            [
                                                -1.492,
                                                -0.728
                                            ],
                                            [
                                                -1.625,
                                                -0.489
                                            ],
                                            [
                                                -1.042,
                                                -0.135
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.218,
                                                0.87
                                            ],
                                            [
                                                -1.668,
                                                0.09
                                            ],
                                            [
                                                -0.873,
                                                0.238
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.558,
                                                -0.895
                                            ],
                                            [
                                                -3.029,
                                                1.443
                                            ],
                                            [
                                                -0.753,
                                                0.73
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.542,
                                                0.714
                                            ],
                                            [
                                                -0.959,
                                                1.342
                                            ],
                                            [
                                                -0.364,
                                                0.848
                                            ]
                                        ],
                                        "v": [
                                            [
                                                -46.165,
                                                28.12
                                            ],
                                            [
                                                -49.988,
                                                27.584
                                            ],
                                            [
                                                -52.294,
                                                29.178
                                            ],
                                            [
                                                -52.972,
                                                39.086
                                            ],
                                            [
                                                -50.915,
                                                40.977
                                            ],
                                            [
                                                -47.01,
                                                40.987
                                            ],
                                            [
                                                -45.043,
                                                42.465
                                            ],
                                            [
                                                -43.359,
                                                46.79
                                            ],
                                            [
                                                -43.655,
                                                49.025
                                            ],
                                            [
                                                -46.572,
                                                52.407
                                            ],
                                            [
                                                -46.627,
                                                55.042
                                            ],
                                            [
                                                -39.11,
                                                61.945
                                            ],
                                            [
                                                -36.412,
                                                61.571
                                            ],
                                            [
                                                -33.708,
                                                58.459
                                            ],
                                            [
                                                -31.399,
                                                57.896
                                            ],
                                            [
                                                -29.177,
                                                58.691
                                            ],
                                            [
                                                -27.026,
                                                59.23
                                            ],
                                            [
                                                -25.433,
                                                60.909
                                            ],
                                            [
                                                -24.744,
                                                65.15
                                            ],
                                            [
                                                -22.708,
                                                66.887
                                            ],
                                            [
                                                -12.646,
                                                65.295
                                            ],
                                            [
                                                -11.252,
                                                62.981
                                            ],
                                            [
                                                -11.961,
                                                58.828
                                            ],
                                            [
                                                -10.951,
                                                56.697
                                            ],
                                            [
                                                -6.919,
                                                53.86
                                            ],
                                            [
                                                -4.417,
                                                53.711
                                            ],
                                            [
                                                -1.26,
                                                55.857
                                            ],
                                            [
                                                1.526,
                                                55.432
                                            ],
                                            [
                                                6.476,
                                                46.61
                                            ],
                                            [
                                                5.67,
                                                44.141
                                            ],
                                            [
                                                1.773,
                                                41.615
                                            ],
                                            [
                                                0.848,
                                                39.631
                                            ],
                                            [
                                                1.084,
                                                35.159
                                            ],
                                            [
                                                2.497,
                                                33.134
                                            ],
                                            [
                                                6.193,
                                                31.924
                                            ],
                                            [
                                                7.567,
                                                29.5
                                            ],
                                            [
                                                3.774,
                                                20.139
                                            ],
                                            [
                                                1.266,
                                                19.272
                                            ],
                                            [
                                                -2.836,
                                                20.831
                                            ],
                                            [
                                                -5.059,
                                                20.323
                                            ],
                                            [
                                                -8.454,
                                                17.266
                                            ],
                                            [
                                                -9.156,
                                                14.964
                                            ],
                                            [
                                                -7.848,
                                                11.135
                                            ],
                                            [
                                                -8.904,
                                                8.604
                                            ],
                                            [
                                                -13.548,
                                                6.785
                                            ],
                                            [
                                                -18.515,
                                                5.725
                                            ],
                                            [
                                                -20.794,
                                                7.273
                                            ],
                                            [
                                                -21.77,
                                                11.164
                                            ],
                                            [
                                                -23.639,
                                                12.714
                                            ],
                                            [
                                                -28.537,
                                                13.504
                                            ],
                                            [
                                                -30.811,
                                                12.607
                                            ],
                                            [
                                                -32.921,
                                                9.223
                                            ],
                                            [
                                                -35.572,
                                                8.452
                                            ],
                                            [
                                                -43.804,
                                                14.168
                                            ],
                                            [
                                                -44.017,
                                                16.91
                                            ],
                                            [
                                                -41.583,
                                                20.117
                                            ],
                                            [
                                                -41.541,
                                                22.543
                                            ],
                                            [
                                                -43.997,
                                                26.905
                                            ]
                                        ],
                                        "c": true
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 2",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.803921568627451,
                                        0.5450980392156862,
                                        0.5450980392156862,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Group 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 360.000014663101,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 4,
                "nm": "Geer - 3",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 240.0000097754,
                                "s": [
                                    720
                                ]
                            }
                        ],
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            96.933,
                            71.071,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            -3.067,
                            -28.929,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                -0.252,
                                                0.092
                                            ],
                                            [
                                                20.067,
                                                -14.839
                                            ],
                                            [
                                                0.251,
                                                -0.092
                                            ],
                                            [
                                                -20.054,
                                                14.838
                                            ]
                                        ],
                                        "o": [
                                            [
                                                23.448,
                                                -8.543
                                            ],
                                            [
                                                -0.216,
                                                0.159
                                            ],
                                            [
                                                -23.444,
                                                8.528
                                            ],
                                            [
                                                0.215,
                                                -0.16
                                            ]
                                        ],
                                        "v": [
                                            [
                                                -11.523,
                                                -46.462
                                            ],
                                            [
                                                6.957,
                                                -12.063
                                            ],
                                            [
                                                6.242,
                                                -11.679
                                            ],
                                            [
                                                -12.237,
                                                -46.078
                                            ]
                                        ],
                                        "c": true
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ind": 1,
                                "ty": "sh",
                                "ix": 2,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                1.135,
                                                0.159
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.307,
                                                -1.298
                                            ],
                                            [
                                                -0.397,
                                                -4.124
                                            ],
                                            [
                                                -1.329,
                                                -0.003
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.305,
                                                -1.089
                                            ],
                                            [
                                                -0.869,
                                                -1.713
                                            ],
                                            [
                                                0.663,
                                                -0.77
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.756,
                                                -0.971
                                            ],
                                            [
                                                -3.548,
                                                -2.304
                                            ],
                                            [
                                                -0.842,
                                                0.969
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1,
                                                -0.415
                                            ],
                                            [
                                                -0.945,
                                                -0.285
                                            ],
                                            [
                                                -0.891,
                                                -0.179
                                            ],
                                            [
                                                -0.168,
                                                -1.036
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.255,
                                                -0.011
                                            ],
                                            [
                                                -4.016,
                                                1.344
                                            ],
                                            [
                                                0.214,
                                                1.252
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.938,
                                                0.523
                                            ],
                                            [
                                                -1.544,
                                                1.348
                                            ],
                                            [
                                                -0.958,
                                                -0.651
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.844,
                                                1.046
                                            ],
                                            [
                                                -1.442,
                                                4.005
                                            ],
                                            [
                                                1.013,
                                                0.657
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.145,
                                                0.969
                                            ],
                                            [
                                                0.081,
                                                1.832
                                            ],
                                            [
                                                -1.081,
                                                0.354
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.291,
                                                1.292
                                            ],
                                            [
                                                2.189,
                                                3.579
                                            ],
                                            [
                                                1.16,
                                                -0.441
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.694,
                                                0.761
                                            ],
                                            [
                                                1.535,
                                                1.133
                                            ],
                                            [
                                                -0.355,
                                                1.041
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.165,
                                                0.569
                                            ],
                                            [
                                                1.981,
                                                0.597
                                            ],
                                            [
                                                2.082,
                                                0.268
                                            ],
                                            [
                                                0.317,
                                                -1.265
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.111,
                                                -0.06
                                            ],
                                            [
                                                1.972,
                                                -0.537
                                            ],
                                            [
                                                0.594,
                                                0.952
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.181,
                                                -0.562
                                            ],
                                            [
                                                2.984,
                                                -2.892
                                            ],
                                            [
                                                -0.786,
                                                -1.036
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.646,
                                                -0.905
                                            ],
                                            [
                                                0.831,
                                                -1.938
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -1.321,
                                                -0.185
                                            ],
                                            [
                                                -0.956,
                                                4.051
                                            ],
                                            [
                                                0.128,
                                                1.323
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.13,
                                                0.003
                                            ],
                                            [
                                                0.52,
                                                1.857
                                            ],
                                            [
                                                0.459,
                                                0.906
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.803,
                                                0.932
                                            ],
                                            [
                                                2.607,
                                                3.347
                                            ],
                                            [
                                                1.077,
                                                0.699
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.711,
                                                -0.818
                                            ],
                                            [
                                                0.893,
                                                0.371
                                            ],
                                            [
                                                0.888,
                                                0.267
                                            ],
                                            [
                                                1.029,
                                                0.207
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.201,
                                                1.239
                                            ],
                                            [
                                                4.224,
                                                0.04
                                            ],
                                            [
                                                1.204,
                                                -0.403
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.181,
                                                -1.058
                                            ],
                                            [
                                                1.781,
                                                -0.994
                                            ],
                                            [
                                                0.872,
                                                -0.762
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.112,
                                                0.756
                                            ],
                                            [
                                                2.645,
                                                -3.278
                                            ],
                                            [
                                                0.409,
                                                -1.137
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.822,
                                                -0.533
                                            ],
                                            [
                                                0.278,
                                                -1.86
                                            ],
                                            [
                                                -0.049,
                                                -1.136
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                1.258,
                                                -0.412
                                            ],
                                            [
                                                -0.918,
                                                -4.086
                                            ],
                                            [
                                                -0.648,
                                                -1.059
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.963,
                                                0.366
                                            ],
                                            [
                                                -1.268,
                                                -1.389
                                            ],
                                            [
                                                -0.885,
                                                -0.653
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.419,
                                                -1.227
                                            ],
                                            [
                                                -1.851,
                                                -0.903
                                            ],
                                            [
                                                -2.017,
                                                -0.607
                                            ],
                                            [
                                                -1.293,
                                                -0.166
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.271,
                                                1.079
                                            ],
                                            [
                                                -2.071,
                                                0.112
                                            ],
                                            [
                                                -1.083,
                                                0.295
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -0.692,
                                                -1.11
                                            ],
                                            [
                                                -3.759,
                                                1.79
                                            ],
                                            [
                                                -0.934,
                                                0.905
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0.672,
                                                0.886
                                            ],
                                            [
                                                -1.19,
                                                1.666
                                            ],
                                            [
                                                -0.452,
                                                1.052
                                            ]
                                        ],
                                        "v": [
                                            [
                                                -32.127,
                                                -39.077
                                            ],
                                            [
                                                -36.87,
                                                -39.742
                                            ],
                                            [
                                                -39.731,
                                                -37.765
                                            ],
                                            [
                                                -40.573,
                                                -25.47
                                            ],
                                            [
                                                -38.02,
                                                -23.123
                                            ],
                                            [
                                                -33.174,
                                                -23.111
                                            ],
                                            [
                                                -30.734,
                                                -21.277
                                            ],
                                            [
                                                -28.644,
                                                -15.91
                                            ],
                                            [
                                                -29.012,
                                                -13.136
                                            ],
                                            [
                                                -32.631,
                                                -8.939
                                            ],
                                            [
                                                -32.7,
                                                -5.669
                                            ],
                                            [
                                                -23.371,
                                                2.896
                                            ],
                                            [
                                                -20.025,
                                                2.433
                                            ],
                                            [
                                                -16.668,
                                                -1.43
                                            ],
                                            [
                                                -13.803,
                                                -2.128
                                            ],
                                            [
                                                -11.046,
                                                -1.142
                                            ],
                                            [
                                                -8.376,
                                                -0.473
                                            ],
                                            [
                                                -6.399,
                                                1.611
                                            ],
                                            [
                                                -5.545,
                                                6.873
                                            ],
                                            [
                                                -3.019,
                                                9.029
                                            ],
                                            [
                                                9.468,
                                                7.053
                                            ],
                                            [
                                                11.197,
                                                4.181
                                            ],
                                            [
                                                10.318,
                                                -0.971
                                            ],
                                            [
                                                11.57,
                                                -3.616
                                            ],
                                            [
                                                16.574,
                                                -7.137
                                            ],
                                            [
                                                19.679,
                                                -7.322
                                            ],
                                            [
                                                23.596,
                                                -4.658
                                            ],
                                            [
                                                27.053,
                                                -5.186
                                            ],
                                            [
                                                33.196,
                                                -16.133
                                            ],
                                            [
                                                32.195,
                                                -19.197
                                            ],
                                            [
                                                27.359,
                                                -22.332
                                            ],
                                            [
                                                26.213,
                                                -24.794
                                            ],
                                            [
                                                26.504,
                                                -30.342
                                            ],
                                            [
                                                28.258,
                                                -32.855
                                            ],
                                            [
                                                32.845,
                                                -34.357
                                            ],
                                            [
                                                34.55,
                                                -37.365
                                            ],
                                            [
                                                29.843,
                                                -48.98
                                            ],
                                            [
                                                26.731,
                                                -50.056
                                            ],
                                            [
                                                21.641,
                                                -48.122
                                            ],
                                            [
                                                18.883,
                                                -48.753
                                            ],
                                            [
                                                14.67,
                                                -52.546
                                            ],
                                            [
                                                13.798,
                                                -55.402
                                            ],
                                            [
                                                15.421,
                                                -60.153
                                            ],
                                            [
                                                14.111,
                                                -63.295
                                            ],
                                            [
                                                8.348,
                                                -65.551
                                            ],
                                            [
                                                2.184,
                                                -66.867
                                            ],
                                            [
                                                -0.644,
                                                -64.945
                                            ],
                                            [
                                                -1.854,
                                                -60.118
                                            ],
                                            [
                                                -4.174,
                                                -58.195
                                            ],
                                            [
                                                -10.252,
                                                -57.214
                                            ],
                                            [
                                                -13.074,
                                                -58.327
                                            ],
                                            [
                                                -15.691,
                                                -62.526
                                            ],
                                            [
                                                -18.982,
                                                -63.483
                                            ],
                                            [
                                                -29.196,
                                                -56.39
                                            ],
                                            [
                                                -29.461,
                                                -52.987
                                            ],
                                            [
                                                -26.441,
                                                -49.008
                                            ],
                                            [
                                                -26.389,
                                                -45.998
                                            ],
                                            [
                                                -29.436,
                                                -40.585
                                            ]
                                        ],
                                        "c": true
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 2",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.803921568627451,
                                        0.5450980392156862,
                                        0.5450980392156862,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Group 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 360.000014663101,
                "st": 0,
                "bm": 0
            }
        ],
        "markers": [

        ]
    };
};