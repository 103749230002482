import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Intercom: any;

export const useHideIntercom = () => {
    useEffect(() => {
        Intercom('update', { 'hide_default_launcher': true });
        return () => {
            Intercom('update', { 'hide_default_launcher': false });
        };
    }, []);
};