import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { StartupMessage, DocUXDetail, FuneralHomeUX } from '../../../../shared/types';
import CircularProgress from '@mui/material/CircularProgress';
import { useGSelector } from '../../../../types';
import { downloadFuneralHomeDoc, downloadStartupDocForFamilyUser } from '../../../../actions/Doc.action';
import { downloadFromURL } from '../../../../services/doc.service';
import { UserRoles } from '../../../../shared/types';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { AppDispatch } from '../../../../store';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';
import { SlideTransition } from '../../../common/Transitions';
import { useDispatch } from 'react-redux';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            borderRadius: 0,
            height: '100vh',
            minHeight: '100vh',
            maxHeight: '100vh',
            [theme.breakpoints.up('md')]: {
                height: 'auto',
                minHeight: 'auto',
                maxHeight: 'calc(100% - 96px)',
                borderRadius: 4,
                maxWidth: 720,
                width: 720,
            }
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: 12,
        '& $heading': {
            margin: 'auto',
            textAlign: 'center',
            fontSize: 32,
            fontWeight: 300,
            marginBottom: 20
        },
        '& $inner': {
            margin: 'auto'
        },
    },
    subHeading: {
        width: '100%',
        margin: '0 0 32px 0',
        fontSize: 18,
        fontWeight: 300
    },
    doc: {
        fontSize: 18,
        fontWeight: 400,
        textTransform: 'none',
        '& svg': {
            marginRight: 16
        }
    },
    docList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 12px',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        '& $heading': {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 400,
            marginTop: 4,
            marginBottom: 16
        },
        '& button:first-of-type': {
            marginTop: 16
        },
        '& button:last-child': {
            marginBottom: 16
        }
    },
    fhLogo: {
        display: 'flex',
        justifyContent: 'center',
        aligItems: 'center',
        marginBottom: 24
    },
    footer: {
        textAlign: 'center',
        marginTop: 32,
        '& p': {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 300,
            marginTop: 4
        },
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    inner: {},
    heading: {},
    dialogPaper: {},
});

type StyledProps = Props & WithStyles<'root' | 'dialogPaper' | 'dialogContent' | 'heading' | 'subHeading' | 'fhLogo'
    | 'footer' | 'doc' | 'docList' | 'inner' | 'buttonProgress'>;

interface Props {
    isSaving?: boolean;
    startupMessage: StartupMessage;
    isOpen: boolean;
    funeralHome: FuneralHomeUX;
    closeDialog: () => void;
    caseId: number | null;
    caseUuid: string | null;
    zIndex: number;
}

const StartupMessageDialog = (props: StyledProps) => {
    const { classes, isOpen, startupMessage, isSaving, funeralHome, closeDialog, zIndex, caseId, caseUuid } = props;

    const dispatch = useDispatch<AppDispatch>();
    const userSession = useGSelector(state => state.userSession);

    const downloadDoc = async (docUX: DocUXDetail) => {
        /**
         * this means that download is triggered by family user from family view having a unique gather-case-id
         */
        if (caseId && caseUuid && UserRoles.isFamilyOnCase(userSession.userData, caseId)) {
            const response = await dispatch(
                downloadStartupDocForFamilyUser(caseUuid, docUX.doc && docUX.doc.id || -1)
            );

            if (!response) {
                return;
            }

            downloadFromURL(response.presignedurl, response.downloadfilename);
            return;
        }

        const downloadDocResult = await dispatch(
            downloadFuneralHomeDoc(userSession, funeralHome, docUX.doc && docUX.doc.id || -1)
        );
        if (!downloadDocResult) {
            return;
        }
        downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
    };

    const {
        startup_button_helper_text,
        startup_button_text,
        startup_documents_header,
        startup_headline_title,
        startup_main_message
    } = startupMessage;

    const startupDocs = (funeralHome.startup_documents || [])
        .sort((a, b) => {
            const aRank = a.doc && a.doc.startup_rank || 0;
            const bRank = b.doc && b.doc.startup_rank || 0;
            return aRank - bRank;
        });

    return (
        <Dialog
            disableEscapeKeyDown
            open={isOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={(e, reason) => {
                if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            className={classes.root}
            classes={{ paper: classes.dialogPaper }}
            style={{ zIndex }}
        >
            <DialogContent className={classes.dialogContent}>
                <Grid item xs={12} sm={10} className={classes.inner}>

                    <Grid item xs={12} className={classes.fhLogo}>
                        <FuneralHomeLogo logoSize="large" />
                    </Grid>

                    {startup_headline_title && <Typography className={classes.heading} color="primary">
                        {startup_headline_title}
                    </Typography>}

                    {startup_main_message && <Typography
                        color="secondary"
                        align="left"
                        component="p"
                        className={classes.subHeading}
                    >
                        {startup_main_message}
                    </Typography>}

                    {startupDocs.length !== 0 && <Grid item xs={12} className={classes.docList}>

                        {startup_documents_header && <Typography
                            color="primary"
                            className={classes.heading}
                        >
                            {startup_documents_header}
                        </Typography>}

                        {startupDocs.map(d =>
                            <Button
                                key={d.uid}
                                color="secondary"
                                className={classes.doc}
                                onClick={e => downloadDoc(d)}
                            >
                                <InsertDriveFileIcon color="primary" />
                                {d.label}
                            </Button>
                        )}

                    </Grid>}

                    <Grid item xs={12} className={classes.footer}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={e => closeDialog()}
                            disabled={isSaving}
                        >
                            {startup_button_text || 'close'}
                            {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>

                        {startup_button_helper_text && <Typography color="secondary">
                            {startup_button_helper_text}
                        </Typography>}
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withStyles(styles)(StartupMessageDialog);
