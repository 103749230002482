import { Component, Fragment } from 'react';
import classNames from 'classnames';

import Button from '@mui/material/Button';

import GCircularProgress from '../../../common/GCircularProgress';
import {
    formatDinero,
} from '../../../../shared/goods_and_services/pricing';
import PriceWidget from '../widgets/PriceWidget';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import GPopper from '../../../common/GPopper';

export type AllowanceSummary = {
    id: string;
    name: string;
    remaining: Dinero.Dinero;
    percentRemaining: number;
};

export interface Props {
    popperAnchorEle: HTMLElement | null;
    popperLeftOffset: number;
    allowances: AllowanceSummary[];
    clickAwayListener: (event: MouseEvent | TouchEvent) => void;
    addItem: (allowanceItemId?: string) => void;
    zIndex: number;
}

export interface State { }

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    buttonLabel: {
        fontSize: 12,
        fontWeight: 200,
        width: '100%',
        display: 'inline-block',
        textTransform: 'none',
        lineHeight: 1,
        margin: '5px 0 0'
    },
    popperButtonLabel: {
        margin: 4,
        color: theme.palette.primary.main
    },
    popperButton: {
        width: 120,
        height: 94,
        textAlign: 'center',
        padding: 0,
    },
    popperIcon: {
        fontSize: 74,
        display: 'block',
    },
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
    },
    popperContent: {
        textAlign: 'center',
        display: 'flex',
        height: 94,
        padding: '8px 0',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    popperDivider: {
        display: 'inline-block',
        height: 94,
        borderRight: '1px solid',
        margin: 'auto',
        color: theme.palette.primary.main
    },
    arrow: {
        border: '20px solid transparent',
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-20px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '20px solid #fff',
            bottom: '-20px',
        },
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    progressWrapper: {
        position: 'relative',
        height: 50,
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        paddingTop: 12,
        '& $fabProgress': {
            zIndex: 1,
        },
        '& $progressText': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            lineHeight: '1em',
            fontWeight: 400,
            zIndex: 2,
            color: theme.palette.primary.main,
            '& span': {
                fontWeight: 400,
                '&:first-of-type': {
                    fontSize: 14,
                    lineHeight: '1em',
                },
                '&:last-child': {
                    fontSize: 8,
                    lineHeight: '1em',
                }
            }
        },
    },
    priceWidgetSign: {
        fontSize: '10px !important',
    },
    fabProgress: {},
    progressText: {},
});

type StyledProps = Props & WithGStyles<'root' | 'buttonLabel' | 'popperButtonLabel' | 'popperButton' | 'popperIcon'
    | 'popoverPaper' | 'popperContent' | 'popperDivider' | 'arrow' | 'popper' | 'floatRight' | 'floatLeft'
    | 'progressWrapper' | 'priceWidgetSign' | 'fabProgress' | 'progressText'>;

class AllowancePopper extends Component<StyledProps, State> {
    protected arrowRef: | HTMLElement | ((element: HTMLElement) => HTMLElement) | undefined;

    constructor(props: StyledProps) {
        super(props);
        this.state = {};
    }

    renderOption = () => {
        const { classes, addItem, allowances } = this.props;

        return (
            <div className={classes.popperContent}>
                {allowances.map((allowance) => {

                    const isDisabled = allowance.remaining.getAmount() === 0;
                    return (
                        <Fragment key={allowance.id}>
                            <Button
                                color="primary"
                                className={classNames(
                                    classes.popperButton,
                                    classes.floatLeft,
                                    isDisabled && classes.opacity50,
                                )}
                                classes={{
                                    text: classNames(
                                        classes.popperButtonLabel,
                                        classes.buttonLabel,
                                    )
                                }}
                                onClick={() => addItem(allowance.id)}
                                disabled={isDisabled}
                            >
                                &nbsp;&nbsp;&nbsp;&nbsp;Add using&nbsp;{allowance.name}
                                <div className={classes.progressWrapper}>
                                    <div className={classes.progressText}>
                                        <PriceWidget
                                            priceStr={formatDinero(allowance.remaining, '$0,0')}
                                            color={'primary'}
                                            size={'xsmall'}
                                            customClasses={{
                                                price: '',
                                                cents: classes.priceWidgetSign
                                            }}
                                        />
                                        <span>Remaining</span>
                                    </div>
                                    <GCircularProgress
                                        color="primary"
                                        variant="determinate"
                                        size={60}
                                        thickness={3}
                                        className={classes.fabProgress}
                                        value={allowance.percentRemaining < 2 ? 2 : allowance.percentRemaining}
                                    />
                                </div>

                            </Button>
                            <div
                                className={classes.popperDivider}
                            />
                        </Fragment>
                    );
                })}
                <Button
                    color="primary"
                    className={classNames(classes.popperButton, classes.floatRight)}
                    classes={{
                        text: classNames(
                            classes.popperButtonLabel,
                            classes.buttonLabel,
                        )
                    }}
                    onClick={() => addItem()}
                >
                    &nbsp;Add without using&nbsp; an Allowance
                    <span className={classes.popperIcon}>
                        +
                    </span>
                </Button>
            </div>
        );
    };

    render() {
        const {
            classes,
            popperAnchorEle,
            clickAwayListener,
            zIndex
        } = this.props;

        return (
            <GPopper
                anchorEle={popperAnchorEle}
                className={classes.popper}
                paperClass={classes.popoverPaper}
                arrowClass={classes.arrow}
                zIndex={zIndex}
                closePopper={clickAwayListener}
                placement='top'
            >
                {this.renderOption()}
            </GPopper>
        );
    }
}

export default withGStyles(styles)(AllowancePopper);
