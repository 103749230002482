import { postToAPI, getFromAPI, putToAPI, advancedAPIRequest, patchAPI } from '.';
import { registerAppError, handleException } from './errors';
import {
    StreamDeviceUX,
    StreamDeviceType,
    StreamDeviceRecord,
    DeletedStreamDeviceResponse,
    StreamableEventsAndDevicesResponse,
    StreamDevicePatchRequest,
} from '../shared/types/live_stream';
import {
    GatherEventWithPlayback,
    GatherCasePublic,
    GatherCaseLiveStreamResponse,
    ActiveStreamReportRecord,
    LiveStreamBillingRecord
} from '../shared/types';
import { UpdateEvent } from './GatherEvent.action';
import { setSnackbarSuccess } from './AppSnackbar.action';
import { AppDispatch } from '../store';

export const STREAMABLE_EVENTS_LOADING = 'STREAMABLE_EVENTS_LOADING';

interface StreamableEventsLoading {
    type: typeof STREAMABLE_EVENTS_LOADING;
}

function streamableEventsLoading(): StreamableEventsLoading {
    return {
        type: STREAMABLE_EVENTS_LOADING,
    };
}

export const STREAMABLE_EVENTS_LOADED = 'STREAMABLE_EVENTS_LOADED';

interface StreamableEventsLoaded {
    type: typeof STREAMABLE_EVENTS_LOADED;
    events: GatherEventWithPlayback[];
    devices?: StreamDeviceUX[];
}

export function streamableEventsLoaded(
    events: GatherEventWithPlayback[],
    devices?: StreamDeviceUX[],
): StreamableEventsLoaded {
    return {
        type: STREAMABLE_EVENTS_LOADED,
        events,
        devices,
    };
}

export const STREAMABLE_EVENTS_LOAD_FAILED = 'STREAMABLE_EVENTS_LOAD_FAILED';

interface StreamableEventsLoadFailed {
    type: typeof STREAMABLE_EVENTS_LOAD_FAILED;
}

function streamableEventsLoadFailed(): StreamableEventsLoadFailed {
    return {
        type: STREAMABLE_EVENTS_LOAD_FAILED,
    };
}

export function loadStreamableEvents(gatherCase: GatherCasePublic) {
    return async (dispatch: AppDispatch): Promise<GatherCaseLiveStreamResponse | null> => {
        dispatch(streamableEventsLoading());
        const route = `api/case/${gatherCase.uuid}/livestream`;
        const response = await getFromAPI<GatherCaseLiveStreamResponse>(route, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to load events.'));
            dispatch(streamableEventsLoadFailed());
            return null;
        } else {
            dispatch(streamableEventsLoaded(response.events, response.devices));
            return response;
        }
    };
}

export function loadPublicStreamableEvents(gatherCase: GatherCasePublic) {
    return async (dispatch: AppDispatch): Promise<GatherEventWithPlayback[] | null> => {
        dispatch(streamableEventsLoading());
        const route = `app/remember/${gatherCase.name}/event`;
        const events = await getFromAPI<GatherEventWithPlayback[]>(route, dispatch);
        if (!events) {
            dispatch(registerAppError('Unable to load events.'));
            dispatch(streamableEventsLoadFailed());
            return null;
        } else {
            // public-facing page does not see devices
            dispatch(streamableEventsLoaded(events));
            return events;
        }
    };
}

export function startBroadcast(eventId: number, deviceId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<StreamableEventsAndDevicesResponse | null> => {
        const route = `api/case/${caseUuid}/event/${eventId}/device/${deviceId}/broadcast/start`;
        const response = await putToAPI<StreamableEventsAndDevicesResponse>(route, {}, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to start broadcasting.'));

            return null;
        } else {
            dispatch(streamableEventsLoaded(response.events, response.devices));
            return response;
        }
    };
}

export function cancelBroadcast(eventId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<StreamableEventsAndDevicesResponse | null> => {
        const route = `api/case/${caseUuid}/event/${eventId}/broadcast/cancel`;
        const response = await putToAPI<StreamableEventsAndDevicesResponse>(route, {}, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to cancel broadcasting.'));
            return null;
        } else {
            dispatch(streamableEventsLoaded(response.events, response.devices));
            dispatch(setSnackbarSuccess('Broadcast Canceled'));
            return response;
        }
    };
}

export function stopBroadcast(eventId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<StreamableEventsAndDevicesResponse | null> => {
        const route = `api/case/${caseUuid}/event/${eventId}/broadcast/stop`;
        const response = await putToAPI<StreamableEventsAndDevicesResponse>(route, {}, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to stop broadcasting.'));
            return null;
        } else {
            dispatch(streamableEventsLoaded(response.events, response.devices));
            return response;
        }
    };
}

export const STREAM_DEVICES_LOADING = 'STREAM_DEVICES_LOADING';

interface StreamDevicesLoading {
    type: typeof STREAM_DEVICES_LOADING;
}

function streamDevicesLoading(): StreamDevicesLoading {
    return {
        type: STREAM_DEVICES_LOADING,
    };
}

export const AVAILABLE_STREAM_DEVICES_LOADED = 'AVAILABLE_STREAM_DEVICES_LOADED';

interface AvailableStreamDevicesLoaded {
    type: typeof AVAILABLE_STREAM_DEVICES_LOADED;
    availableStreamDevices: StreamDeviceUX[];
}

function availableStreamDevicesLoaded(availableStreamDevices: StreamDeviceUX[]): AvailableStreamDevicesLoaded {
    return {
        type: AVAILABLE_STREAM_DEVICES_LOADED,
        availableStreamDevices,
    };
}

export const STREAM_DEVICES_LOADED = 'STREAM_DEVICES_LOADED';

interface StreamDevicesLoaded {
    type: typeof STREAM_DEVICES_LOADED;
    streamDevices: StreamDeviceUX[];
}

function streamDevicesLoaded(streamDevices: StreamDeviceUX[]): StreamDevicesLoaded {
    return {
        type: STREAM_DEVICES_LOADED,
        streamDevices,
    };
}

export const STREAM_DEVICES_LOAD_FAILED = 'STREAM_DEVICES_LOAD_FAILED';

interface StreamDevicesLoadFailed {
    type: typeof STREAM_DEVICES_LOAD_FAILED;
}

function streamDevicesLoadFailed(): StreamDevicesLoadFailed {
    return {
        type: STREAM_DEVICES_LOAD_FAILED,
    };
}

export function loadStreamDevices(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceUX[] | null> => {
        dispatch(streamDevicesLoading());
        const route = `funeralhome/${funeralHomeId}/streamdevice`;
        const devices = await getFromAPI<StreamDeviceUX[]>(route, dispatch);
        if (!devices) {
            dispatch(registerAppError('Unable to load stream devices'));
            dispatch(streamDevicesLoadFailed());
            return null;
        }
        dispatch(streamDevicesLoaded(devices));

        return devices;
    };
}

export function loadAvailableStreamDevices(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceUX[] | null> => {
        dispatch(streamDevicesLoading());
        const route = `funeralhome/${funeralHomeId}/streamdevice/all`;
        const devices = await getFromAPI<StreamDeviceUX[]>(route, dispatch);
        if (!devices) {
            dispatch(registerAppError('Unable to load all stream devices'));
            dispatch(streamDevicesLoadFailed());
            return null;
        }
        dispatch(availableStreamDevicesLoaded(devices));

        return devices;
    };
}

export const ADD_STREAM_DEVICE = 'ADD_STREAM_DEVICE';

interface AddStreamDevice {
    type: typeof ADD_STREAM_DEVICE;
    streamDevice: StreamDeviceUX;
}

function addStreamDevice(streamDevice: StreamDeviceUX): AddStreamDevice {
    return {
        type: ADD_STREAM_DEVICE,
        streamDevice,
    };
}

export function createStreamDevice(
    funeralHomeId: number,
    nickname: string,
    deviceName: string,
    deviceType: StreamDeviceType,
    tabletNotes: string,
    cameraNotes: string,
) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceUX | null> => {
        const route = `funeralhome/${funeralHomeId}/streamdevice`;
        const createdDevice = await postToAPI<StreamDeviceUX>(
            route,
            { nickname, deviceType, deviceName, tabletNotes, cameraNotes },
            dispatch
        );
        if (!createdDevice) {
            dispatch(registerAppError('Unable to create new stream device'));
            return null;
        }

        dispatch(addStreamDevice(createdDevice));
        return createdDevice;
    };
}

export const SET_STREAM_DEVICE = 'SET_STREAM_DEVICE';

interface SetStreamDevice {
    type: typeof SET_STREAM_DEVICE;
    deviceId: number;
    streamDevice: StreamDeviceUX;
}

function setStreamDevice(deviceId: number, streamDevice: StreamDeviceUX): SetStreamDevice {
    return {
        type: SET_STREAM_DEVICE,
        deviceId,
        streamDevice,
    };
}

export function patchStreamDevice(funeralHomeId: number, patch: Partial<StreamDevicePatchRequest> & { id: number }) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceUX | null> => {
        const { id, ...patchRequest } = patch;

        try {
            StreamDevicePatchRequest.fromRequest(patchRequest);
        } catch (ex) {
            console.warn('Failed to validate StreamDevicePatchRequest:', patchRequest, ex);
            return null;
        }
        const route = `funeralhome/${funeralHomeId}/streamdevice/${id}`;
        const streamDevice = await patchAPI<StreamDeviceUX>(route, patchRequest, dispatch);
        if (!streamDevice) {
            dispatch(registerAppError('Unable to save stream device'));
            return null;
        }

        dispatch(setStreamDevice(patch.id, streamDevice));
        return streamDevice;
    };
}

export function cloneStreamDevice(funeralHomeId: number, device: StreamDeviceUX) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceUX | null> => {
        const route = `funeralhome/${funeralHomeId}/streamdevice/${device.id}/clone`;
        const createdDevice = await postToAPI<StreamDeviceUX>(route, {}, dispatch);
        if (!createdDevice) {
            dispatch(registerAppError('Unable to clone new stream device'));
            return null;
        }

        dispatch(addStreamDevice(createdDevice));
        return createdDevice;
    };
}

export function updateStreamDevice(funeralHomeId: number, deviceId: number, nickname: string) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceUX | null> => {
        const route = `funeralhome/${funeralHomeId}/streamdevice/${deviceId}`;
        const updatedDevice = await postToAPI<StreamDeviceUX>(route, { nickname }, dispatch);
        if (!updatedDevice) {
            dispatch(registerAppError('Unable to update stream device'));
            return null;
        }

        dispatch(setStreamDevice(deviceId, updatedDevice));
        return updatedDevice;
    };
}

export const DELETE_STREAM_DEVICE = 'DELETE_STREAM_DEVICE';

interface DeleteStreamDevice {
    type: typeof DELETE_STREAM_DEVICE;
    deviceId: number;
}

function deleteStreamDevice(deviceId: number): DeleteStreamDevice {
    return {
        type: DELETE_STREAM_DEVICE,
        deviceId,
    };
}

export function removeStreamDevice(funeralHomeId: number, deviceId: number) {
    return async (dispatch: AppDispatch): Promise<StreamDeviceRecord | null> => {
        const route = `funeralhome/${funeralHomeId}/streamdevice/${deviceId}`;
        const response = await advancedAPIRequest(route, 'DELETE', {}, dispatch);
        try {
            if (!response) {
                throw ({
                    message: 'Unable to remove stream device no response',
                });
            }
            const body: DeletedStreamDeviceResponse = await response.json();
            if (response.status === 200 && body) {
                const removedStreamDevice: StreamDeviceRecord | null = body.removedStreamDevice;
                if (!removedStreamDevice) {
                    throw ({
                        message: 'Unable to remove stream device',
                    });
                }
                dispatch(deleteStreamDevice(deviceId));
                return removedStreamDevice;
            } else if (response.status === 403) {
                throw ({
                    message: 'Unable to remove a Gather Camera device. Please contact Gather Support.'
                });
            } else {
                console.warn('Invalid state of removeStreamDevice Response', body);
                return null;
            }

        } catch (ex) {
            dispatch(handleException({ ex, userMessage: 'Unable to remove Gather Camera device' }));
            return null;
        }
    };
}

export const SET_ACTIVE_STREAM_REPORT = 'SET_ACTIVE_STREAM_REPORT';

interface SetActiveStreamReport {
    type: typeof SET_ACTIVE_STREAM_REPORT;
    report: ActiveStreamReportRecord[];
}

function setActiveStreamReport(report: ActiveStreamReportRecord[]): SetActiveStreamReport {
    return {
        type: SET_ACTIVE_STREAM_REPORT,
        report,
    };
}

export function loadActiveStreamReport() {
    return async (dispatch: AppDispatch) => {
        // dispatch(setActiveStreamReport([])); // Clear out the old report
        const response = await getFromAPI<ActiveStreamReportRecord[]>('event/livestreamreport', dispatch);
        if (response) {
            dispatch(setActiveStreamReport(response));
        } else {
            dispatch(registerAppError('Unable to load active stream report'));
        }
    };
}

export const SET_STREAM_BILLING_REPORT = 'SET_STREAM_BILLING_REPORT';
interface SetStreamBillingReport {
    type: typeof SET_STREAM_BILLING_REPORT;
    report: LiveStreamBillingRecord[];
}
function setStreamBillingReport(report: LiveStreamBillingRecord[]): SetStreamBillingReport {
    return {
        type: SET_STREAM_BILLING_REPORT,
        report,
    };
}
export function loadStreamBillingReport() {
    return async (dispatch: AppDispatch) => {
        const response = await getFromAPI<LiveStreamBillingRecord[]>('api/finance/livestreambillingreport', dispatch);
        if (response) {
            dispatch(setStreamBillingReport(response));
        } else {
            dispatch(registerAppError('Unable to load live stream billing report'));
        }
    };
}

export function updateStreamBillingRecord(params: {
    caseUuid: string;
    notes: string;
    amount: number;
    isBilled: boolean;
}) {
    return async (dispatch: AppDispatch) => {
        const { caseUuid, notes, amount, isBilled } = params;

        const response = await patchAPI<LiveStreamBillingRecord[]>(
            `api/finance/livestreambillingreport/${caseUuid}`,
            {
                notes,
                amount,
                isBilled,
            },
            dispatch
        );
        if (response) {
            dispatch(setStreamBillingReport(response));
        } else {
            dispatch(registerAppError('Unable to update live stream billing report'));
        }
    };
}

export type LiveStreamAction =
    | StreamDevicesLoading
    | StreamDevicesLoaded
    | StreamDevicesLoadFailed
    | AvailableStreamDevicesLoaded
    | AddStreamDevice
    | DeleteStreamDevice
    | SetStreamDevice
    | StreamableEventsLoading
    | StreamableEventsLoaded
    | StreamableEventsLoadFailed
    | SetActiveStreamReport
    | SetStreamBillingReport
    | UpdateEvent
    ;
