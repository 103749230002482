import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { StoreState } from '../../../types';
import { GatherCaseUX, UserRoles } from '../../../shared/types';
import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { setAppSnackbar } from '../../../actions/AppSnackbar.action';
import { compose } from 'redux';
import { DeathCertificateStatus } from '../../../shared/death_certificate/validators';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

const Transition = React.forwardRef(
    function SlideTransition(props: SlideProps, ref: React.Ref<unknown>) {
        return <Slide {...props} direction="up" mountOnEnter unmountOnExit />;
    }
);

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    button: {
        margin: theme.spacing(),
    },
    completionStatus: {
        textAlign: 'center',
        margin: '20px 20px 20px',
    },
    bottomMargin10: {
        marginBottom: '10px'
    },
    headerIconButton: {
        height: 30,
        width: 30,
        verticalAlign: 'unset'
    },
    lockUnlockIcon: {
        fontSize: 20,
        paddingTop: 3
    }

});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'button' | 'completionStatus' | 'bottomMargin10' | 'headerIconButton' |
        'lockUnlockIcon'>;

interface DialogProps {
    fullScreen: boolean;
}

function mapStateToProps({ userSession }: StoreState) {
    return {
        userSession,
    };
}
interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    activeCase: GatherCaseUX;
    dcStatus: DeathCertificateStatus;
    rendorOnlyIcon?: boolean;
    zIndex: number;
    isLocked: boolean;
    onLockedChange: (isLocked: boolean) => void;
    closeLockFormDialog?: () => void;
}

interface State {
    isOpenLockConfirmation: boolean;
}

type CombinedProps = DialogProps & StyledProps;

class LockContent extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            isOpenLockConfirmation: false
        };
    }

    lockDeathCertificateConfirm = () => {
        const { activeCase, closeLockFormDialog, onLockedChange, dispatch } = this.props;
        onLockedChange(true);
        this.setState({
            isOpenLockConfirmation: false,
        });
        if (closeLockFormDialog !== undefined) {
            closeLockFormDialog();
        }
        dispatch(setAppSnackbar(`${activeCase.fname}'s data is now securely locked`, 'success'));
    };

    unlockDeathCertificateConfirm = () => {
        const { activeCase, closeLockFormDialog, onLockedChange, dispatch } = this.props;
        onLockedChange(false);
        if (closeLockFormDialog !== undefined) {
            closeLockFormDialog();
        }
        dispatch(setAppSnackbar(`${activeCase.fname}'s data is now unlocked and can be edited`, 'warning'));
    };

    closeLockConfirmation = () => {
        this.setState({
            isOpenLockConfirmation: false,
        });
    };

    openLockConfirmation = () => {
        this.setState({
            isOpenLockConfirmation: true,
        });
    };

    renderUnlockView = () => {
        const { classes, activeCase, userSession, rendorOnlyIcon } = this.props;
        const isFamilyUser = UserRoles.isFamilyOnCase(userSession.userData, activeCase.id);

        if (rendorOnlyIcon) {
            return this.renderIcon();
        }

        return (
            <Grid
                item
                xs={12}
                className={classes.completionStatus}
            >
                <LockOpenIcon color="primary" className={classes.fontSize40} />

                <Typography
                    color="primary"
                    align="center"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight400,
                        classes.bottomMargin10,
                    )}
                >
                    {activeCase.fname}'s data is unlocked.
                </Typography>
                {
                    isFamilyUser ?
                        <Typography
                            color="primary"
                            align="center"
                            className={classNames(
                                classes.fontSize12,
                                classes.fontWeight400,
                                classes.bottomMargin10,
                            )}
                        >
                            Once complete, {activeCase.assignee.fname} will lock this data to ensure it remains secure.
                        </Typography>
                        :
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            className={classNames(
                                classes.button,

                            )}
                            onClick={this.openLockConfirmation}
                        >
                            Lock Data
                        </Button>
                }
            </Grid >
        );
    };

    renderLockView = () => {
        const { classes, activeCase, userSession, rendorOnlyIcon } = this.props;

        const isFamilyUser = UserRoles.isFamilyOnCase(userSession.userData, activeCase.id);

        if (rendorOnlyIcon) {
            return this.renderIcon();
        }

        return (
            <Grid
                item
                xs={12}
                className={classes.completionStatus}
            >
                <LockIcon color="primary" className={classes.fontSize40} />

                <Typography
                    color="primary"
                    align="center"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight400,
                        classes.bottomMargin10,
                    )}
                >
                    {activeCase.fname}'s data is locked.
                </Typography>

                {isFamilyUser ?
                    <Typography
                        color="primary"
                        align="center"
                        className={classNames(
                            classes.fontSize12,
                            classes.fontWeight400,
                            classes.bottomMargin10,
                        )}
                    >
                        We want to keep this data secure and accurate.
                        Please reach out to {activeCase.assignee.fname} if you would like to make any changes.
                    </Typography>
                    :
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                        onClick={this.unlockDeathCertificateConfirm}
                    >
                        Unlock Data
                    </Button>
                }
            </Grid >
        );
    };

    renderIcon = () => {
        const { classes, userSession, activeCase, isLocked } = this.props;

        const isFamilyUser = UserRoles.isFamilyOnCase(userSession.userData, activeCase.id);

        if (isFamilyUser) {
            return (
                isLocked ?
                    <LockIcon color="primary" className={classes.lockUnlockIcon} /> :
                    <LockOpenIcon color="primary" className={classes.lockUnlockIcon} />
            );
        }

        return (
            <Tooltip
                id="lock_unlock_data"
                title={isLocked ? 'Unlock Data' : 'Lock Data'}
                placement="top"
            >
                <IconButton
                    className={classNames(
                        classes.headerIconButton
                    )}
                    onClick={isLocked ?
                        this.unlockDeathCertificateConfirm : this.openLockConfirmation}
                    size="large">
                    {
                        isLocked ?
                            <LockIcon color="primary" className={classes.fontSize20} /> :
                            <LockOpenIcon color="primary" className={classes.fontSize20} />
                    }
                </IconButton>
            </Tooltip>
        );
    };

    renderLockConfirmation = () => {
        const { classes, dcStatus, zIndex } = this.props;
        const { isOpenLockConfirmation } = this.state;

        let completeText: string = '';

        if (dcStatus.invalidSections === 0) {
            completeText = 'All Sections Complete';
        } else {
            const total = dcStatus.validSections + dcStatus.invalidSections;
            completeText = `${dcStatus.validSections} of ${total} Sections Complete`;
        }

        return (
            <Dialog
                open={isOpenLockConfirmation}
                TransitionComponent={Transition}
                keepMounted
                transitionDuration={300}
                onClose={this.closeLockConfirmation}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
                style={{ zIndex }}
            >
                <DialogTitle>
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <Typography
                        color="primary"
                        variant="h5"
                        align="center"
                        className={classNames(
                            classes.fontSize30,
                            classes.fontWeight200,
                        )}
                    >
                        {completeText}
                    </Typography>
                    <DialogContentText id="alert-dialog-slide-description">
                        This action will prevent anyone else from editing this data. You, or anyone else from your
                        funeral home, can unlock this section later, as needed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeLockConfirmation}>
                        Cancel
                    </Button>
                    <Button
                        onClick={this.lockDeathCertificateConfirm}
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                    >
                        Lock Data
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    render() {
        const { classes, isLocked, rendorOnlyIcon } = this.props;
        return (
            <div className={rendorOnlyIcon ? classes.floatLeft : ''}>
                {isLocked ? this.renderLockView() : this.renderUnlockView()}
                {this.renderLockConfirmation()}
            </div>
        );
    }
}

export default withState(mapStateToProps)(
    compose(withGStyles(styles), withFullScreen())(LockContent) as React.ComponentType<Props>
);
