import momentTz from 'moment-timezone';

import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import LiveTvIcon from '@mui/icons-material/LiveTv';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { GatherEvent } from '../../../shared/types';
import { convertHexToRGBA } from '../../../services';
import { makeStyles } from '@mui/styles';

interface Props {
    event: GatherEvent;
    index: number;
    groupedEventsCount: number;
    onClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        width: 310,
        boxShadow: theme.shadows[10],
        '@media (min-width: 340px)': {
            width: 320,
        },
    },
    popperContent: {
        padding: '0 8px',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    eventTime: {},
    accessTimeIcon: {},
    eventDateTime: {},
    eventTimeContainer: {
        padding: '8px 4px',
        '& $eventTime': {
            fontSize: 20,
            lineHeight: 1.6,
        },
        '& $accessTimeIcon': {
            verticalAlign: 'text-top',
            marginRight: 5,
            fontSize: 24,
        },
        '& $eventDateTime': {
            fontSize: 16,
            lineHeight: '18px'
        }
    },
    eventAddress: {},
    locationIcon: {},
    addressContainer: {
        padding: '8px 4px',
        textAlign: 'center',
        display: 'table',
        width: 'calc(100% - 8px)',
        '& $eventAddress': {
            display: 'inline-block',
            fontSize: 16,
            fontWeight: 400,
            verticalAlign: 'middle',
            lineHeight: '18px',
        },
        '& $locationIcon': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontSize: 60,
        },
        '& $liveStream': {
            borderLeft: 'none',
            padding: '12px 0 8px',
            flexDirection: 'row',
            display: 'flex',
            fontWeight: 400,
            fontSize: 16,
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '1.1em',
            margin: 'auto',
            '& svg': {
                marginRight: 8,
                fontSize: 36
            },
        },
    },
    editDetailsContainer: {
        padding: '8px 4px',
        textAlign: 'center'
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
    },
    secondaryBigText: {},
    buttonSecondaryTypography: {},
    buttonGroupSecondary: {
        display: 'inline-flex',
        '& $buttonSecondaryTypography': {
            position: 'relative',
            fontSize: 18,
            lineHeight: 1.2,
            fontWeight: 200,
            display: 'inline-block',
            padding: '0 26px',
            margin: 'auto',
            '& $secondaryBigText': {
                fontSize: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: 1,
                marginTop: 6
            }
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    liveStream: {
        width: 'fit-content',
        padding: '0 12px',
        flexDirection: 'column',
        paddingLeft: 12,
        marginLeft: 12,
        display: 'flex',
        fontSize: 10,
        borderLeft: `1px solid ${convertHexToRGBA(theme.palette.secondary.main, 0.4)}`,
        fontWeight: 200,
        alignItems: 'center',
        lineHeight: '1em',
        justifyContent: 'center',
        marginTop: -8,
        '& $icon': {
            fontSize: 24
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    timePeriod: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        height: 26,
        fontSize: 12
    },
    familyMessage: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        padding: 16,
        '& p': {
            fontSize: 16,
            textAlign: 'center',
        }
    },
    addMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        padding: '16px 0'
    },
    switchContainer: {
        paddingTop: 24,
        margin: '0 auto 6px'
    },
    visibilityIcon: {
        fontSize: 18,
        marginRight: 8,
        marginTop: 2,
        verticalAlign: 'text-top'
    },
    icon: {}
}), { name: 'EventDetails' });

const EventDetails = (props: Props) => {
    const {
        event,
        index,
        groupedEventsCount,
        onClick,
    } = props;

    const classes = useStyles();

    const startMoment = momentTz(event.start_time);
    const time = startMoment.format('h:mm');
    const timePeriod = startMoment.format('a');
    const isPrivateEvent = event.is_private;

    return (
        <span
            key={event.id}
            aria-haspopup="true"
            className={classes.buttonGroupSecondary}
            onClick={onClick}
            style={{
                borderLeft: index === 0 ? 'none' : '1px solid',
                width: groupedEventsCount > 1 ? 'auto' : '100%'
            }}
            itemID={event.id.toString()}
        >
            <Typography
                itemID={event.id.toString()}
                component="span"
                color="inherit"
                className={classes.buttonSecondaryTypography}
            >
                {isPrivateEvent &&
                    <VisibilityOffIcon
                        color="primary"
                        className={classes.visibilityIcon}
                    />
                }
                <span>{event.name}</span>
                <span className={classes.secondaryBigText} itemID={event.id.toString()}>
                    {time}<span className={classes.timePeriod} itemID={event.id.toString()}>{timePeriod}</span>
                    {event.is_streamable &&
                        <Typography
                            className={classes.liveStream}
                            color="primary"
                            itemID={event.id.toString()}
                        >
                            <LiveTvIcon className={classes.icon} id={event.id.toString()} />
                            Live Stream
                        </Typography>
                    }
                </span>
            </Typography>
        </span>
    );
};

export default EventDetails;
