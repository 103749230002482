import { getFromAPI, postToAPI, deleteFromAPI, patchAPI, advancedAPIRequest } from '.';

import { registerAppError, handleException } from './errors';

import {
    GatherCaseUX,
    DocPacketRequest,
    DocPacketDocRequest,
    DocPacketDocUX,
    DocPacketUX,
    DocPacketSignerRequest,
    UserProfile,
    DocPacketContractUX,
    DocCreateRequest,
    UploadFileRequest,
    DocUX,
    ProductContractUX,
    DocPacketStatus,
    DocCategory,
    DocPacketSignerRecord,
    DocPacketSignerUX,
    EntitySummary,
    DocPacketSignerEmailResponse,
    DocPacketSignerReminderResponse,
    DocPacketFinalizeRequest,
} from '../shared/types';
import { DocPDFStatus, HelloSignSendReminderError } from '../shared/types';
import { setAppSnackbar } from './AppSnackbar.action';
import { uniqBy } from 'lodash';
import { AppDispatch } from '../store';
import { uploadFileToS3 } from './S3File.action';
import { log } from '../logger';

/// / ------>  Action Creators  <------ \\\\

// ADD_DOC_PACKET
export const ADD_DOC_PACKET = 'ADD_DOC_PACKET';
export type ADD_DOC_PACKET = typeof ADD_DOC_PACKET;

interface AddDocPacket {
    type: ADD_DOC_PACKET;
    docPacket: DocPacketUX;
}

function addDocPacket(docPacket: DocPacketUX): AddDocPacket {
    return {
        type: ADD_DOC_PACKET,
        docPacket,
    };
}

// SET_DOC_PACKETS
export const SET_DOC_PACKETS = 'SET_DOC_PACKETS';
export type SET_DOC_PACKETS = typeof SET_DOC_PACKETS;

interface SetDocPackets {
    type: SET_DOC_PACKETS;
    docPackets: DocPacketUX[];
}

function setDocPackets(docPackets: DocPacketUX[]): SetDocPackets {
    return {
        type: SET_DOC_PACKETS,
        docPackets,
    };
}

// SET_DOC_PACKET
export const SET_DOC_PACKET = 'SET_DOC_PACKET';
export type SET_DOC_PACKET = typeof SET_DOC_PACKET;

interface SetDocPacket {
    type: SET_DOC_PACKET;
    id: number;
    docPacket: DocPacketUX;
}

function setDocPacket(id: number, docPacket: DocPacketUX): SetDocPacket {
    return {
        type: SET_DOC_PACKET,
        id,
        docPacket,
    };
}

// SET_DOC_PACKET_CONTRACT_SIGNERS
export const SET_DOC_PACKET_CONTRACT_SIGNERS = 'SET_DOC_PACKET_CONTRACT_SIGNERS';
export type SET_DOC_PACKET_CONTRACT_SIGNERS = typeof SET_DOC_PACKET_CONTRACT_SIGNERS;

interface SetDocPacketContractSigners {
    type: SET_DOC_PACKET_CONTRACT_SIGNERS;
    docPacketId: number;
    packetContractId: number;
    group: 'helpers' | 'team';
    signers: EntitySummary[];
}

function setDocPacketContractSigners(
    docPacketId: number,
    packetContractId: number,
    group: 'helpers' | 'team',
    signers: EntitySummary[],
): SetDocPacketContractSigners {
    return {
        type: SET_DOC_PACKET_CONTRACT_SIGNERS,
        docPacketId,
        packetContractId,
        group,
        signers,
    };
}

// SET_DOC_PACKET_DOC_SIGNER
export const SET_DOC_PACKET_DOC_SIGNER = 'SET_DOC_PACKET_DOC_SIGNER';
export type SET_DOC_PACKET_DOC_SIGNER = typeof SET_DOC_PACKET_DOC_SIGNER;

interface SetDocPacketDocSigner {
    type: SET_DOC_PACKET_DOC_SIGNER;
    docPacketId: number;
    docId: number;
    group: string;
    signer: EntitySummary;
}

function setDocPacketDocSigner(
    docPacketId: number,
    docId: number,
    group: string,
    signer: EntitySummary,
): SetDocPacketDocSigner {
    return {
        type: SET_DOC_PACKET_DOC_SIGNER,
        docPacketId,
        docId,
        group,
        signer,
    };
}

// UPDATE_DOC_PACKET_SIGNER
export const UPDATE_DOC_PACKET_SIGNER = 'UPDATE_DOC_PACKET_SIGNER';
export type UPDATE_DOC_PACKET_SIGNER = typeof UPDATE_DOC_PACKET_SIGNER;

interface UpdateDocPacketSigner {
    type: UPDATE_DOC_PACKET_SIGNER;
    docPacketId: number;
    signerPersonId: number;
    changes: Partial<DocPacketSignerUX>;
}

function updateDocPacketSignerInStore(
    docPacketId: number,
    signerPersonId: number,
    changes: Partial<DocPacketSignerUX>
): UpdateDocPacketSigner {
    return {
        type: UPDATE_DOC_PACKET_SIGNER,
        docPacketId,
        signerPersonId,
        changes,
    };
}

// UPDATE_DOC_PACKET
export const UPDATE_DOC_PACKET = 'UPDATE_DOC_PACKET';
export type UPDATE_DOC_PACKET = typeof UPDATE_DOC_PACKET;

interface UpdateDocPacket {
    type: UPDATE_DOC_PACKET;
    id: number;
    changes: Partial<DocPacketUX>;
}

function updateDocPacketInStore(id: number, changes: Partial<DocPacketUX>): UpdateDocPacket {
    return {
        type: UPDATE_DOC_PACKET,
        id,
        changes,
    };
}

// REMOVE_DOC_PACKET
export const REMOVE_DOC_PACKET = 'REMOVE_DOC_PACKET';
export type REMOVE_DOC_PACKET = typeof REMOVE_DOC_PACKET;

interface RemoveDocPacket {
    type: REMOVE_DOC_PACKET;
    docPacketId: number;
}

function removeDocPacket(docPacketId: number): RemoveDocPacket {
    return {
        type: REMOVE_DOC_PACKET,
        docPacketId,
    };
}

// SET_DOC_PACKETS_LOADING
export const SET_DOC_PACKETS_LOADING = 'SET_DOC_PACKETS_LOADING';
export type SET_DOC_PACKETS_LOADING = typeof SET_DOC_PACKETS_LOADING;

interface SetDocPacketsLoading {
    type: SET_DOC_PACKETS_LOADING;
    isLoading: boolean;
}

function setDocPacketsLoading(isLoading: boolean): SetDocPacketsLoading {
    return {
        type: SET_DOC_PACKETS_LOADING,
        isLoading,
    };
}

// SET_DOC_PACKET_SAVING
export const SET_DOC_PACKET_SAVING = 'SET_DOC_PACKET_SAVING';
export type SET_DOC_PACKET_SAVING = typeof SET_DOC_PACKET_SAVING;

interface SetDocPacketSaving {
    type: SET_DOC_PACKET_SAVING;
    isSaving: boolean;
}

function setDocPacketSaving(isSaving: boolean): SetDocPacketSaving {
    return {
        type: SET_DOC_PACKET_SAVING,
        isSaving,
    };
}

// ADD_DOC_PACKET_SIGNED_DOC
export const ADD_DOC_PACKET_SIGNED_DOC = 'ADD_DOC_PACKET_SIGNED_DOC';
export type ADD_DOC_PACKET_SIGNED_DOC = typeof ADD_DOC_PACKET_SIGNED_DOC;

interface AddDocPacketSignedDoc {
    type: ADD_DOC_PACKET_SIGNED_DOC;
    docPacketId: number;
    signedDoc: DocUX;
}

function addDocPacketSignedDoc(docPacketId: number, signedDoc: DocUX): AddDocPacketSignedDoc {
    return {
        type: ADD_DOC_PACKET_SIGNED_DOC,
        docPacketId,
        signedDoc,
    };
}

// REMOVE_DOC_PACKET_SIGNED_DOC
export const REMOVE_DOC_PACKET_SIGNED_DOC = 'REMOVE_DOC_PACKET_SIGNED_DOC';
export type REMOVE_DOC_PACKET_SIGNED_DOC = typeof REMOVE_DOC_PACKET_SIGNED_DOC;

interface RemoveDocPacketSignedDoc {
    type: REMOVE_DOC_PACKET_SIGNED_DOC;
    docPacketId: number;
    docId: number;
}

function removeDocPacketSignedDoc(docPacketId: number, docId: number): RemoveDocPacketSignedDoc {
    return {
        type: REMOVE_DOC_PACKET_SIGNED_DOC,
        docPacketId,
        docId,
    };
}

/// / ------>  API Interface  <------ \\\\

export function createDocPacket(docPacket: DocPacketRequest, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        try {
            DocPacketRequest.fromRequest(docPacket);
        } catch (ex) {
            log.warn('Failed to validate DocPacketRequest:', { docPacket, ex });
            return null;
        }

        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docPacket`;
        const created = await postToAPI<DocPacketUX>(route, { docPacket }, dispatch);
        dispatch(setDocPacketSaving(false));
        if (created) {
            dispatch(addDocPacket(created));
            return created;
        }
        dispatch(registerAppError('Unable to add document packet.'));
        return null;
    };
}

export function loadDocPackets(gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX[] | null> => {
        dispatch(setDocPacketsLoading(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/`;
        const docPackets = await getFromAPI<DocPacketUX[]>(route, dispatch);
        dispatch(setDocPacketsLoading(false));

        if (docPackets) {
            dispatch(setDocPackets(docPackets));
            return docPackets;
        }

        dispatch(registerAppError('Unable to load document packets.'));
        return null;
    };
}

export function loadDocPacket(docPacketId: number, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        dispatch(setDocPacketsLoading(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacketId}`;
        const docPacket = await getFromAPI<DocPacketUX>(route, dispatch);
        dispatch(setDocPacketsLoading(false));
        if (docPacket) {
            dispatch(setDocPacket(docPacketId, docPacket));
            return docPacket;
        }
        dispatch(registerAppError('Unable to load document packet.'));
        return null;
    };
}

export function loadContractInDocPacket(docPacket: DocPacketUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/contract/`;
        const contract = await getFromAPI<ProductContractUX | null>(route, dispatch);
        return contract;
    };
}

export function updateDocPacket(existing: DocPacketUX, changes: Partial<DocPacketRequest>, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        try {
            DocPacketRequest.fromPatchRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate DocPacketRequest:', { changes, ex });
            return null;
        }

        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${existing.id}`;
        const updated = await patchAPI<DocPacketUX>(route, { docPacket: changes }, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(existing.id, updated));
            return updated;
        }
        dispatch(registerAppError('Unable to update document packet.'));
        return null;
    };
}

export function finalizeDocPacket(
    docPacket: DocPacketUX,
    gatherCase: GatherCaseUX,
    contractHtml?: string,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {

        if (docPacket.type === 'fill_esign' && docPacket.signers.length === 0) {
            dispatch(registerAppError('No signers specified for document packet.'));
            return null;
        }

        const body: DocPacketFinalizeRequest = {
            caseUuid: gatherCase.uuid,
            contract_html: contractHtml,
            packet_signers: contractHtml ? docPacket.signers.map((signer) => signer.id) : undefined,
        };

        try {
            DocPacketFinalizeRequest.fromRequest(body);
        } catch (ex) {
            log.warn('Failed to validate DocPacketDocRequest', { body, ex });
            return null;
        }
        dispatch(setDocPacketSaving(true));
        const route = `docpacket/${docPacket.id}/finalize`;
        const updated = await postToAPI<DocPacketUX>(route, body, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return updated;
        }
        dispatch(registerAppError('Could not generate document packet.'));
        return null;
    };
}

export function eSignDocPacket(docPacket: DocPacketUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/esign`;
        const updated = await postToAPI<DocPacketUX>(route, {}, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return updated;
        }
        dispatch(registerAppError('Unable to send document packet for eSignatures.'));
        return null;
    };
}

export function markDocPacketSigned(docPacket: DocPacketUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        dispatch(updateDocPacketInStore(docPacket.id, { status: 'signed' }));
        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/signed`;
        const updated = await postToAPI<DocPacketUX>(route, {}, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return updated;
        }
        dispatch(registerAppError('Unable to send document packet for eSignatures.'));
        return null;
    };
}

export function generateDocPacketDownloadUrl(docPacket: DocPacketUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/download`;
        const resp = await postToAPI<{ url: string; status: DocPacketStatus }>(route, {}, dispatch);
        if (resp) {
            dispatch(updateDocPacketInStore(docPacket.id, { status: resp.status }));
            return resp.url;
        }
        dispatch(registerAppError('Unable to download document packet.'));
        return null;
    };
}

export function deleteDocPacket(docPacket: DocPacketUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX[] | null> => {
        dispatch(removeDocPacket(docPacket.id));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}`;
        const docPackets = await deleteFromAPI<DocPacketUX[]>(route, dispatch);
        if (docPackets) {
            dispatch(setDocPackets(docPackets));
            return docPackets;
        }
        dispatch(registerAppError('Unable to delete document packet.'));
        return null;
    };
}

export function remindDocPacketSigner(
    docPacket: DocPacketUX,
    gatherCase: GatherCaseUX,
    docPacketSigner: DocPacketSignerUX,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketSignerReminderResponse | null> => {
        let result: DocPacketSignerReminderResponse | null;
        try {
            const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/signer/${docPacketSigner.id}/remind`;
            const response = await advancedAPIRequest(route, 'POST', {}, dispatch);
            result = response && response.ok ? await response.json() : null;
            const errorResponse = response && !response.ok
                ? await response.json()
                : null;
            if (HelloSignSendReminderError.isHelloSignSendReminderError(errorResponse)) {
                const helloSignError = errorResponse;
                if (helloSignError) {
                    if (helloSignError.error_type === 'forbidden'
                        || helloSignError.error_type === 'bad_request'
                    ) {
                        dispatch(registerAppError(
                            helloSignError.hellosign_error_message,
                            { showSnackbar: true, sendToSentry: true }
                        ));
                    } else {
                        // do we really want to display any error message? 
                        dispatch(registerAppError(
                            helloSignError.hellosign_error_message,
                            { showSnackbar: false, sendToSentry: true }
                        ));
                    }
                    return null;
                }
            } else if (errorResponse) {
                // Some other error we should handle
                dispatch(registerAppError(errorResponse.message, { showSnackbar: false, sendToSentry: true }));
                return null;
            }
            if (!result) {
                return null;
            }
            const updatedHistory = docPacketSigner.history && result.historyEvent
                ? uniqBy(
                    [
                        ...docPacketSigner.history.filter(obj => obj.doc_packet_id === docPacket.id),
                        result.historyEvent].sort((eleA, eleB) => (eleA.id > eleB.id ? -1 : 1)),
                    (obj) => obj.id)
                : result.historyEvent ? new Array(result.historyEvent) : null
                ;
            dispatch(updateDocPacketSignerInStore(docPacket.id, docPacketSigner.person_id, {
                last_reminder_sent_time: new Date(),
                history: updatedHistory,
            }));
            const successMessage = `Reminder successfully sent to ${docPacketSigner.person.fname}`;
            dispatch(setAppSnackbar(successMessage, 'success'));
            return result;
        } catch (ex) {
            dispatch(handleException({ ex, showSnackbar: true, sendToSentry: true }));
            return null;
        }
    };
}

export function updateDocPacketSignerEmail(
    docPacket: DocPacketUX,
    gatherCase: GatherCaseUX,
    docPacketSigner: DocPacketSignerUX,
    newEmailAddress: string,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketSignerRecord | null> => {
        //
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/signer/${docPacketSigner.id}/`;
        const result = await patchAPI<DocPacketSignerEmailResponse>(
            route,
            {
                email: newEmailAddress,
            },
            dispatch);
        if (result) {
            const updatedHistory = docPacketSigner.history && result.historyEvent
                ? uniqBy(
                    [
                        ...docPacketSigner.history.filter(obj => obj.doc_packet_id === docPacket.id),
                        result.historyEvent].sort((eleA, eleB) => (eleA.id > eleB.id ? -1 : 1)),
                    (obj) => obj.id)
                : result.historyEvent ? new Array(result.historyEvent) : null
                ;
            dispatch(updateDocPacketSignerInStore(docPacket.id, docPacketSigner.person_id, {
                last_reminder_sent_time: new Date(),
                person: {
                    ...docPacketSigner.person,
                    email: result.signer.email,
                },
                history: updatedHistory,
            }));
            return null;
        }
        return null;
    };

}

export function updateDocPacketDoc(
    docPacket: DocPacketUX,
    docPacketDoc: DocPacketDocUX,
    changes: DocPacketDocRequest,
    gatherCase: GatherCaseUX,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        try {
            DocPacketDocRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate DocPacketDocRequest', { changes, ex });
            return null;
        }

        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/doc/${docPacketDoc.doc.id}`;
        const updated = await patchAPI<DocPacketUX>(route, { docPacketDoc: changes }, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return updated;
        }
        dispatch(registerAppError('Unable to update document packet.'));
        return null;
    };
}

export function updateDocPacketDocSigner(
    docPacket: DocPacketUX,
    docPacketDoc: DocPacketDocUX,
    gatherCase: GatherCaseUX,
    signerGroup: string,
    person: EntitySummary,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const signer: DocPacketSignerRequest = {
            person_id: person.entity_id, //  > 0 ? person.id : undefined, // UI sets person.id negative for users
            signer_group: signerGroup,
        };

        try {
            DocPacketSignerRequest.fromRequest(signer);
        } catch (ex) {
            log.warn('Failed to validate DocPacketSignerRequest:', { signer, ex });
            return;
        }

        // optimistically update signer
        dispatch(setDocPacketDocSigner(docPacket.id, docPacketDoc.doc.id, signerGroup, person));
        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/doc/${docPacketDoc.doc.id}/signer`;
        const updated = await postToAPI<DocPacketUX>(route, { signer }, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return;
        } else {
            // undo optimistic signer update
            dispatch(setDocPacket(docPacket.id, docPacket));
        }
        dispatch(registerAppError('Unable to save document packet signer.'));
    };
}

export function updateDocPacketContractSigners(
    docPacket: DocPacketUX,
    docPacketContract: DocPacketContractUX,
    gatherCase: GatherCaseUX,
    signerGroup: 'team' | 'helpers',
    signers: EntitySummary[],
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const signerReqs: DocPacketSignerRequest[] = signers.map((person) => ({
            person_id: person.entity_id,
            signer_group: signerGroup,
        }));

        try {
            signerReqs.map(DocPacketSignerRequest.fromRequest);
        } catch (ex) {
            log.warn('Failed to validate DocPacketSignerRequests:', { signerReqs, ex });
            return;
        }

        // optimistically update signers
        dispatch(setDocPacketContractSigners(docPacket.id, docPacketContract.id, signerGroup, signers));
        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}` +
            `/contract/${docPacketContract.contract_id}/signers/${signerGroup}`;
        const updated = await postToAPI<DocPacketUX>(route, { signers: signerReqs }, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return;
        } else {
            // TODO: undo optimistic signer update
        }
        dispatch(registerAppError('Unable to save document packet contract signers.'));
    };
}

export function uploadDocPacketSignedDoc(
    docPacket: DocPacketUX,
    docRequest: UploadFileRequest,
    gatherCase: GatherCaseUX,
    user: UserProfile,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        if (!docRequest.suffix) {
            dispatch(registerAppError('Invalid file type'));
            return null;
        }
        const s3DocUploadRequest = await uploadFileToS3(
            docRequest,
            `api/case/${gatherCase.uuid}/doc/uploadurl/${docRequest.hash}/${docRequest.suffix}`,
            dispatch,
        );
        if (!s3DocUploadRequest) {
            dispatch(registerAppError('Failed to upload document'));
            return null;
        }
        const createRequest: DocCreateRequest = {
            uploaded_by: user.id,
            parent_doc_id: null,
            name: s3DocUploadRequest.name,
            label: null,
            hash: s3DocUploadRequest.hash,
            size: s3DocUploadRequest.size,
            is_private: true,
            path: s3DocUploadRequest.path,
            suffix: s3DocUploadRequest.suffix || '',
            mimetype: s3DocUploadRequest.type,
            icon: null,
            doc_category: DocCategory.general,
            pdf_status: DocPDFStatus.not_needed,
            task_component_id: null, // not used for doc packets
        };

        dispatch(setDocPacketSaving(true));
        const updatedPacket = await postToAPI<DocPacketUX>(
            `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/signeddoc`,
            {
                newDoc: createRequest,
            },
            dispatch,
        );
        dispatch(setDocPacketSaving(false));

        if (updatedPacket) {
            dispatch(setDocPacket(docPacket.id, updatedPacket));
            return updatedPacket;
        } else {
            dispatch(registerAppError('Unable to create signed document'));
        }
        return null;
    };
}

export function renameDocPacketSignedDoc(
    docPacket: DocPacketUX,
    signedDoc: DocUX,
    newLabel: string,
    gatherCase: GatherCaseUX,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {
        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/signeddoc/${signedDoc.id}`;
        const updated = await patchAPI<DocPacketUX>(route, { label: newLabel }, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return updated;
        }
        dispatch(registerAppError('Unable to update name of signed document.'));
        return null;
    };
}

export function deleteDocPacketSignedDoc(
    docPacket: DocPacketUX,
    signedDoc: DocUX,
    gatherCase: GatherCaseUX,
) {
    return async (dispatch: AppDispatch): Promise<DocPacketUX | null> => {

        // optimistically delete document from packet
        dispatch(removeDocPacketSignedDoc(docPacket.id, signedDoc.id));

        dispatch(setDocPacketSaving(true));
        const route = `api/case/${gatherCase.uuid}/docpacket/${docPacket.id}/signeddoc/${signedDoc.id}`;
        const updated = await deleteFromAPI<DocPacketUX>(route, dispatch);
        dispatch(setDocPacketSaving(false));
        if (updated) {
            dispatch(setDocPacket(docPacket.id, updated));
            return updated;
        } else {
            // rollback optimistic changes
            dispatch(addDocPacketSignedDoc(docPacket.id, signedDoc));
        }
        dispatch(registerAppError('Unable to remove packet signed document.'));
        return null;
    };
}

export type DocPacketAction =
    AddDocPacket
    | SetDocPacket
    | SetDocPackets
    | RemoveDocPacket
    | SetDocPacketsLoading
    | SetDocPacketSaving
    | AddDocPacketSignedDoc
    | RemoveDocPacketSignedDoc
    | SetDocPacketContractSigners
    | SetDocPacketDocSigner
    | UpdateDocPacketSigner
    | UpdateDocPacket
    ;