import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import BaseErrorPage from '../common/BaseErrorPage';

const CaseNotFound = () => {
    const heading = 'Sorry, our search came up empty.';
    const subheading = `It looks like you're not currently assigned as a helper for anyone 
or you don't have the correct access.`;

    return (
        <BaseErrorPage
            HeadingIcon={DeviceUnknownIcon}
            heading={heading}
            subHeading={subheading}
        />
    );
};

export default CaseNotFound;
