import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface Props extends TooltipProps {
    contentDisabled?: boolean;
    contentDisabledContainerClass?: string;
}
const GTooltip = ({ children, contentDisabled, contentDisabledContainerClass, ...tooltipProps }: Props) => {
    const content = contentDisabled
        ? <span className={contentDisabledContainerClass}>{children}</span>
        : children;

    return (
        <Tooltip placement="top" {...tooltipProps}>
            {content}
        </Tooltip>
    );
};

export default GTooltip;