import * as React from 'react';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import CaseFingerprintItem from './CaseFingerprintItem';
import EditFingerprintDialog from '../../../editFingerprintDialog/EditFingerprint.dialog';
import FileUploader, { AcceptFileType } from '../../../../../../common/FileUploader';
import withGStyles, { WithGStyles } from '../../../../../../../styles/WithGStyles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import {
    FingerprintType,
    FingerprintUX,
    UserRoles,
} from '../../../../../../../shared/types';
import { StoreState } from '../../../../../../../types';
import { connect } from 'react-redux';
import { AppDispatch } from '../../../../../../../store';
import {
    createFingerprint,
    deleteFingerprint,
    loadFingerprintsForCase, updateFingerprint
} from '../../../../../../../actions/Fingerprints.action';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';
import { TrackingContentContext } from '../../CompleteStep.dialog';
import ScrollControls from '../../../../../../common/ScrollControls';
import { getIntercomTargetProp } from '../../../../../../../services';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        width: 'fit-content',
        display: 'flex',
        flexWrap: 'nowrap',
        columnGap: '20px',
        padding: '0 20px 8px',
        overflow: 'hidden',
        margin: '20px 0',
        '&:not($grid) li': {
            minWidth: 160,
            maxWidth: 160
        }
    },
    addIcon: {
        border: `1px dashed ${theme.palette.secondary.main}`,
        alignSelf: 'center',
        position: 'relative',
        top: 20
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(120px, 160px))',
        width: 'fit-content',
        margin: '20px auto 0',
        rowGap: '20px',
        '& > div:last-child': {
            height: 200
        },
        '@media (min-width: 600px)': {
            gridTemplateColumns: 'repeat(3, minmax(120px, 160px))',
        }
    },
    addBtnText: {
        fontSize: 10,
        '@media (min-width: 360px)': {
            fontSize: 12,
        },
        '@media (min-width: 400px)': {
            fontSize: 14
        }
    },
    inkpadText: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '8px 16px',
        width: 'max-content',
        maxWidth: '80%',
        margin: '16px auto 0',
        borderRadius: 12,
        fontSize: 12,
        '@media (min-width: 360px)': {
            fontSize: 14
        }
    },
    listContainer: {
        overflow: 'auto hidden',
        position: 'relative',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '& $root': {
            margin: '20px auto'
        }
    },
    scrollBtn: {
        position: 'absolute',
        background: 'none',
        '& button': {
            margin: 0,
            background: `${theme.palette.primary.main} !important`,
            '& svg': {
                color: '#fff'
            }
        }
    },
    rightScrollBtn: {
        right: -12
    },
    leftScrollBtn: {
        left: -12
    },
});
type Classes = 'root' | 'addIcon' | 'grid' | 'addBtnText' | 'inkpadText' | 'listContainer' | 'scrollBtn'
    | 'rightScrollBtn' | 'leftScrollBtn';
type StyledProps = WithGStyles<Classes>;

function mapStateToProps({ casesState, userSession }: StoreState) {
    return {
        isFHorGOMUserOnFH: UserRoles.isFHorGOMUserOnFH(
            userSession.userData,
            casesState.selectedCase?.funeral_home_id || null
        ),
        loadingPrints: casesState.activeCaseFingerprints.loading,
        fingerprints: casesState.activeCaseFingerprints.prints,
    };
}

interface Props {
    zIndex: number;
    disableEdit?: boolean;
    fingerprintText?: string;
    numEmptyPrints?: number;
    caseUuid: string | null; // null case is for Back Office preview
    caseFname: string;
    context: TrackingContentContext;
    dispatch: AppDispatch;
    intercomTargetProp?: string;
    intercomTargetPropPrintItem?: string;
    intercomTargetPropAddPrint?: string;
}

interface State {
    numEmptyPrints: number;
    activeItem: FingerprintUX | null;
    scrollElement: HTMLElement | null;
}

type CombinedProps = Props & StyledProps & ReturnType<typeof mapStateToProps>;
class CaseFingerprints extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        const { numEmptyPrints, fingerprints } = this.props;

        this.state = {
            // we only display empty thumbnails if we don't have images already in their place
            numEmptyPrints: Math.max((numEmptyPrints || 1) - fingerprints.length, 0),
            activeItem: null,
            scrollElement: null
        };
    }

    async componentDidMount() {
        const { dispatch, caseUuid, context } = this.props;

        if (caseUuid !== null && context === TrackingContentContext.standalone) {
            dispatch(loadFingerprintsForCase(caseUuid));
        }
    }

    async componentDidUpdate(prevProps: Readonly<CombinedProps>) {
        const { caseUuid: prevUuid, fingerprints: prevPrints, numEmptyPrints: prevNumEmpty } = prevProps;
        const { caseUuid, dispatch, fingerprints, numEmptyPrints } = this.props;

        if (caseUuid !== prevUuid && caseUuid !== null) {
            await dispatch(loadFingerprintsForCase(caseUuid));
        }

        if (fingerprints.length !== prevPrints.length || prevNumEmpty !== numEmptyPrints) {
            this.setState({
                numEmptyPrints: Math.max((numEmptyPrints || 1) - fingerprints.length, 0)
            });
        }
    }

    render(): React.ReactNode {
        const {
            classes,
            context,
            fingerprints,
            fingerprintText,
            loadingPrints,
            disableEdit,
            isFHorGOMUserOnFH,
            intercomTargetProp,
            intercomTargetPropPrintItem,
            intercomTargetPropAddPrint
        } = this.props;
        const { numEmptyPrints } = this.state;
        const isStandaloneComp = context === TrackingContentContext.standalone;
        const isFamilyPageContext = context === TrackingContentContext.familyPage;
        const canAddFingerprints = isFHorGOMUserOnFH || !isFamilyPageContext;
        const emptyFingerprints = Array(numEmptyPrints).fill(null);

        return (
            <Grid item xs={12}>
                {fingerprintText &&
                    <Typography
                        className={classes.inkpadText}
                        {...getIntercomTargetProp(intercomTargetProp)}
                    >
                        {fingerprintText}
                    </Typography>
                }

                {loadingPrints &&
                    <LoadingSpinner size={72} />
                }

                {!loadingPrints &&
                    <Grid item xs={12} className={classes.positionRelative}>
                        <FileUploader
                            acceptTypes={[AcceptFileType.PNG, AcceptFileType.JPEG, AcceptFileType.GIF]}
                            handleSave={this.handlePrintSave}
                        >
                            {inputRef =>
                                <>
                                    <Grid
                                        ref={ref => !this.state.scrollElement &&
                                            this.setState({ scrollElement: ref })
                                        }
                                        item
                                        xs={12}
                                        className={!isStandaloneComp && classes.listContainer || undefined}
                                    >
                                        <List
                                            className={classNames(classes.root, isStandaloneComp && classes.grid)}
                                        >
                                            {fingerprints.map(fingerprint =>
                                                <CaseFingerprintItem
                                                    disabled={disableEdit}
                                                    item={fingerprint}
                                                    key={fingerprint.uuid}
                                                    btnTextClass={isStandaloneComp ? classes.addBtnText : undefined}
                                                    onClick={canAddFingerprints
                                                        ? this.openEditFingerprintDialog
                                                        : undefined
                                                    }
                                                    intercomTargetPropPrintItem={intercomTargetPropPrintItem}
                                                />
                                            )}
                                            {canAddFingerprints && emptyFingerprints.map((_, i) =>
                                                <CaseFingerprintItem
                                                    item={null}
                                                    key={i}
                                                    disabled={disableEdit}
                                                    onClick={canAddFingerprints
                                                        ? () => inputRef.click()
                                                        : undefined}
                                                />
                                            )}
                                            {(!disableEdit && canAddFingerprints) &&
                                                <div className={classes.flexCentred}>
                                                    <IconButton
                                                        aria-label="Add New Fingerprint"
                                                        className={classNames(
                                                            classes.addIcon,
                                                            isStandaloneComp && classes.marginAuto
                                                        )}
                                                        onClick={() => inputRef.click()}
                                                        {...getIntercomTargetProp(intercomTargetPropAddPrint)}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </div>
                                            }
                                        </List>
                                    </Grid>

                                    {!isStandaloneComp &&
                                        <ScrollControls
                                            registerEvents
                                            scrollElement={this.state.scrollElement}
                                            containerClass={classes.scrollBtn}
                                            leftButtonClass={classes.leftScrollBtn}
                                            rightButtonClass={classes.rightScrollBtn}
                                        />
                                    }
                                </>
                            }
                        </FileUploader>
                    </Grid>
                }

                {this.renderEditFingerprintDialog()}
            </Grid>
        );
    }

    handlePrintSave = async (files: FileList | null) => {
        const { caseUuid, dispatch } = this.props;
        const { numEmptyPrints } = this.state;
        if (!files || files.length === 0) {
            return;
        }

        if (caseUuid !== null) {
            await dispatch(createFingerprint(files[0], files[0].name, FingerprintType.fingerprint, caseUuid));
        }
        this.setState({
            numEmptyPrints: Math.max(0, numEmptyPrints - 1)
        });
    };

    openEditFingerprintDialog = (activeItem: FingerprintUX | null) => {
        this.setState({ activeItem });
    };

    closeEditFingerprintDialog = () => {
        this.setState({
            activeItem: null
        });
    };

    deleteFingerprint = (item: FingerprintUX) => {
        const { dispatch, caseUuid } = this.props;

        if (caseUuid !== null) {
            dispatch(deleteFingerprint(item, caseUuid));
        }
    };

    handleUpdatedType = async (type: FingerprintType) => {
        const { dispatch, caseUuid } = this.props;
        const { activeItem } = this.state;

        if (activeItem && caseUuid !== null) {
            await dispatch(updateFingerprint(activeItem.s3_file_id, type, caseUuid));
        }
        this.closeEditFingerprintDialog();
    };

    renderEditFingerprintDialog = () => {
        const { zIndex, caseFname, disableEdit } = this.props;
        const { activeItem } = this.state;

        return (
            <EditFingerprintDialog
                disableEdit={disableEdit}
                zIndex={zIndex + 1}
                fingerprint={activeItem}
                caseFname={caseFname ?? ''}
                isDialogOpen={Boolean(activeItem)}
                onSave={this.handleUpdatedType}
                onClose={this.closeEditFingerprintDialog}
                onDelete={() => activeItem && this.deleteFingerprint(activeItem)}
            />
        );
    };
}

export default connect(mapStateToProps)(withGStyles(styles)(CaseFingerprints));
