import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { RED_COLOR } from '../../../constants/colorVariables';

const styles = <Props extends object>(): StyleRulesCallback<Theme, Props> => theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            margin: '48px auto',
            justifyContent: 'space-around',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%',
            minHeight: '80vh',
            [theme.breakpoints.up('md')]: {
                maxWidth: 1060,
                width: 1060,
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        zIndex: 0,
        padding: '16px !important',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '1px solid',
        color: theme.palette.primary.main,
        margin: '0 12px',
        flex: '0 0 auto',
    },
    margin8: {
        margin: 8
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        '& svg': {
            color: theme.palette.primary.main
        }
    },
    headerText: {
        fontSize: 24,
        lineHeight: '1.1em',
        color: theme.palette.primary.main
    },
    mainContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (min-width: 1080px)': {
            flexDirection: 'row',
            alignItems: 'inherit'
        }
    },
    leftSection: {
        maxWidth: 500
    },
    width100: {
        width: '100%'
    },
    width45: {
        width: '45%'
    },
    marginTop8: {
        marginTop: 10
    },
    marginLeftRight8: {
        marginRight: 8,
        marginLeft: 8
    },
    textLeft: {
        textAlign: 'left',
    },
    selectFormControl: {
        width: '100%',
        // '@media (min-width: 480px)': {
        //     width: '50%'
        // }
    },
    organizationFooterSection: {
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 960px)': {
            flexDirection: 'row'
        }
    },
    switchLabel: {
        maxHeight: 24,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main
    },
    widthInherit: {
        width: 'inherit'
    },
    organizationSection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '0px 16px',
        borderRadius: 10,
        paddingBottom: 24,
        position: 'relative',
        '& $label': {
            top: '-11px',
            left: '50%',
            padding: '0px 8px',
            position: 'absolute',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.common.white
        }
    },
    switchContainer: {
        maxHeight: 24,
        display: 'flex',
        alignItems: 'center',
        marginTop: 16,
    },
    contactSection: {
        marginTop: 24,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '0px 16px',
        borderRadius: 10,
        position: 'relative',
        '& $label': {
            top: '-11px',
            left: '50%',
            padding: '0px 8px',
            position: 'absolute',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.common.white
        }
    },
    contactDetailSection: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    width65: {
        width: '65%'
    },
    width55: {
        width: '55%'
    },
    width40: {
        width: '40%'
    },
    contactFooterButton: {
        width: 'fit-content',
        display: 'flex',
        marginLeft: 'auto',
        marginTop: 16,
        '& button': {
            fontSize: 12,
            padding: 0,
            minHeight: 24,
            color: RED_COLOR,
            textTransform: 'capitalize',
            '& svg': {
                fontSize: 16,
                color: RED_COLOR
            }
        }
    },
    addNewContract: {
        marginTop: 24,
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20
    },
    rightSection: {
        maxWidth: 500,
        marginTop: 32,
        '@media (min-width: 1080px)': {
            marginTop: 0
        }
    },
    docSection: {
        margin: 'auto',
        marginTop: 40
    },
    docHeading: {
        fontSize: 14,
        marginBottom: 2
    },
    docText: {
        fontSize: 12,
        marginTop: 2
    },
    docContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 16,
        '& $docListContainer': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            maxWidth: 'calc(100% - 16px)',
            width: '100%',
            backgroundColor: theme.palette.common.white,
            borderRadius: 6,
            boxShadow: theme.shadows[4],
            padding: '1px 0px',
            minWidth: 260,
            marginBottom: 16,
            '& $docLabelContainer': {
                marginLeft: '-4px',
                '& $docName': {
                    fontSize: 16,
                    maxWidth: 185,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    '@media (min-width: 400px)': {
                        maxWidth: 210
                    }
                },
                '& $docUploadedBy': {
                    fontSize: 12,
                    lineHeight: '1.20em'
                },
                '& $docUploadedTime': {
                    fontSize: 12,
                    lineHeight: '1.20em'
                }
            },
        }
    },
    fileMoreIcon: {
        width: 20,
        height: 20,
        position: 'absolute',
        top: 6,
        right: 4,
        '& svg': {
            fontSize: '20px !important'
        }
    },
    uploadButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    displayTable: {
        display: 'table'
    },
    newPhotoOuter: {
        margin: 'auto',
        height: '100%',
    },
    addNewPhotoInner: {
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    addPhotoIcon: {
        fontSize: 36,
    },
    squareButtonText: {
        fontSize: 12,
        margin: 0,
    },
    grid: {
        height: 160,
        width: 160,
        borderRadius: 4,
    },
    addNewPhotoContainer: {
        color: theme.palette.secondary.main,
        position: 'relative',
        textAlign: 'center',
        height: 160,
        verticalAlign: 'top',
        display: 'inline-block',
        margin: 0,
        '@media (min-width: 420px)': {
            margin: 12,
        }
    },
    label: {},
    formControlStyles: {
        marginTop: 10,
        width: '100%'
    },
    gmapSearch: {
        width: '100% !important',
        maxWidth: '100%'
    },
    controlSuggestionClass: {
        position: 'absolute',
        textAlign: 'center'
    },
    nameLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        '&$select': {
            maxWidth: '85%'
        }
    },
    nameLabelFocused: {
        maxWidth: 'calc(100% + 100px)',
    },
    select: {},
    docListContainer: {},
    docLabelContainer: {},
    docName: {},
    docUploadedBy: {},
    docUploadedTime: {}
});

export default styles;
