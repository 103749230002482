import { alpha, Grid, Theme, Typography } from '@mui/material';
import { CaseTypeDisplayLookup, GatherCaseSummary, InsurancePolicyUX } from '../../../shared/types';
import PolicyContentItem from './PolicyContentItem';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimeFormat, formatDateString, getAddressFromLongAddress, getAgeString } from '../../../services';
import PolicyAggregateContent from './PolicyAggregateContent';


const useStyles = makeStyles(
    (theme: Theme) => ({
        title: {
            fontSize: 12,
            padding: '2px 7px',
        },
        borderBottom: {
            borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
        },
        bottomContent: {
            padding: '5px 7px',
        }
    }),
    { name: 'PolicyCaseSummary' },
);

interface Props {
    policies: InsurancePolicyUX[];
    associatedCase: GatherCaseSummary | null;
    insuranceColor: string;
}

const PolicyPotentialMatchSummary = (props: Props) => {
    const { policies, associatedCase, insuranceColor } = props;

    const classes = useStyles();

    if (!policies.length || !associatedCase) {
        return null;
    }

    const getAge = (gatherCase: GatherCaseSummary) => {
        const dodOrCurrent = gatherCase.dod_start_date ?? new Date().toISOString();
        if (!gatherCase.dob_date) {
            return null;
        } else {
            return getAgeString(gatherCase.dob_date, dodOrCurrent);
        }
    };
    const caseDisplayName = associatedCase.fname + ' ' + associatedCase.lname;
    const caseAddress = associatedCase.dc_residence !== undefined
        ? getAddressFromLongAddress(associatedCase.dc_residence) : 'Missing';
    const caseSummary = {
        caseName: caseDisplayName,
        caseStatus: CaseTypeDisplayLookup[associatedCase.case_type],
        caseDateOfBirth: associatedCase.dob_date
            ? formatDateString(associatedCase.dob_date, DateTimeFormat.MonthDayYear) : 'Missing',
        caseDateOfDeath: associatedCase.dod_start_date
            ? formatDateString(associatedCase.dod_start_date, DateTimeFormat.MonthDayYear) : 'Missing',
        caseAge: getAge(associatedCase) ?? 'Missing',
        caseGender: associatedCase.gender ?? 'Missing',
        caseAddress: caseAddress ?? 'Missing',
    };

    return (
        <Grid container width={1}>
            <Grid item width={policies.length > 1 ? 0.5 : 1}>
                <Grid item width={1}>
                    <Typography
                        className={classes.title}
                        sx={{
                            color: insuranceColor,
                            bgcolor: `${alpha(insuranceColor, 0.1)}`,
                            borderBottom: `1px solid ${alpha(insuranceColor, 0.1)} !important`,
                        }}
                    >
                        Case Information
                    </Typography>
                </Grid>
                <Grid item width={1}>
                    <PolicyContentItem
                        title="Case Name"
                        description={caseSummary.caseName}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title="Case Type"
                        description={caseSummary.caseStatus}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title="Case DOB"
                        description={caseSummary.caseDateOfBirth}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title="Case DOD"
                        description={caseSummary.caseDateOfDeath}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title="Case Age"
                        description={caseSummary.caseAge}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title="Case Gender"
                        description={caseSummary.caseGender}
                        borderClass={classes.borderBottom}
                        titleColor={insuranceColor}
                    />
                    <PolicyContentItem
                        title="Case Address"
                        description={caseSummary.caseAddress}
                        borderClass={classes.borderBottom}
                        additionalClass={classes.bottomContent}
                        titleColor={insuranceColor}
                    />
                    <Typography color="secondary" p={2}>
                        {'The Case Information includes the details of the case that was matched with this policy.'}
                    </Typography>
                </Grid>
            </Grid>
            {policies.length > 1 && (
                <Grid item width={0.5}>
                    <PolicyAggregateContent policies={policies} insuranceColor={insuranceColor} />
                </Grid>
            )}
        </Grid>
    );
};

export default PolicyPotentialMatchSummary;
