import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LottiePlayer from 'lottie-react';
import { loadingAnimation } from '../../family/familyObituary/loadingAnimation';

interface Props {
    loadingText: string | undefined;
}

const CustomLoadingOverlay = ({ loadingText }: Props) => {
    return (
        <Grid
            container
            alignItems="center"
            flexDirection="column"
            flexWrap="nowrap"
            sx={(theme) => ({
                '& svg': {
                    width: '160px !important',
                    '& > g > g': {
                        '& path': {
                            fill: theme.palette.primary.main,
                        },
                        '&:last-of-type': {
                            '& path': {
                                fill: alpha(theme.palette.primary.main, 0.5),
                            },
                        },
                    },
                },
            })}
        >
            <LottiePlayer animationData={loadingAnimation()} />

            <Typography fontSize={20} fontWeight={300} color="primary" textAlign="center" lineHeight={1.25}>
                Gathering your secure data...
            </Typography>

            <Typography color="secondary" textAlign="center">
                This may take a moment to make sure we get everything perfect
            </Typography>
        </Grid>
    );
};

export default CustomLoadingOverlay;
