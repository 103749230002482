import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ReportIcon from '@mui/icons-material/Report';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { APP_SECONDARY_COLOR, RED_COLOR_2, WARNING_COLOR } from '../../../constants/colorVariables';
import { downloadFromURL } from '../../../services/doc.service';
import FlipCard from '../../common/FlipCard';
import ShowImagePopper from './ShowImagePopper';
import { CaseIdPhotoUX, DocDownloadResponse } from '../../../shared/types';
import { getCaseIdPhotoDownloadurl } from '../../../actions/CaseIdPhotos.action';
import { useGDispatch } from '../../../types';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {
        perspective: 600,
        WebkitPerspective: 600,
        MozPerspective: 600
    },
    buttonBase: {
        width: 280,
        height: 280,
        boxShadow: theme.shadows[5],
        borderRadius: 16,
        '@media (min-width: 360px)': {
            width: 320,
            height: 320,
        },
    },
    displayImage: {
        width: 'inherit',
        height: 'inherit',
        '& div': {
            width: 'inherit',
            height: 'inherit',
            borderRadius: 16,
            '-webkit-border-radius': 16,
            '-moz-border-radius': 16,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },
    },
    deleteDownloadBtn: {
        position: 'absolute',
        filter: 'drop-shadow(0 0px 2px gray)',
        padding: 4,
        height: 'auto',
        width: 'auto',
        '& svg': {
            fontSize: 32,
        },
    },
    deleteIcon: {
        top: 0,
        right: 0,
        '& svg': {
            color: RED_COLOR_2,
        },
    },
    downloadIcon: {
        color: theme.palette.common.white,
        left: 4,
        bottom: 0,
    },
    countDown: {
        position: 'absolute',
        right: 8,
        bottom: 0,
        color: theme.palette.common.white,
        filter: 'drop-shadow(0 0px 2px gray)',
        fontSize: 32,
    },
    addPhoto: {
        border: `2px dashed ${APP_SECONDARY_COLOR}`,
        borderRadius: 16,
        color: APP_SECONDARY_COLOR,
        textTransform: 'none',
        fontWeight: 400,
        width: 280,
        '@media (min-width: 360px)': {
            width: 320,
        },
    },
    imageOvelay: {
        border: 'none',
        width: 'inherit',
        height: 'inherit',
        position: 'absolute',
        background: '#f5ededb3',
        '& $reportIcon': {
            fontSize: 70,
            color: WARNING_COLOR,
        },
        '& p': {
            padding: '0px 16px',
        },
    },
    smallText: {
        padding: '0 4px !important',
        lineHeight: 1.25,
        fontSize: 12,
        bottom: 4,
        margin: '16px 0 12px'
    },
    marginTopAuto: {
        marginTop: 'auto'
    },
    reportIcon: {}
}), { name: 'SecureBodyPhoto' });

interface Props {
    caseUuid: string | null; // null case is for Back Office preview
    photo: CaseIdPhotoUX;
    imageOverlayTopText?: string;
    imageOverlayBottomText: React.ReactNode;
    usePopper?: boolean;
    size?: 'small';
    zIndex?: number;
    caseFname?: string;
    rootClass?: string;
    onDeleteClick?: (caseUuid: string, s3FileId: number) => void;
    onPhotoClick?: () => void;
    intercomTargetPropFlipCard?: string;
}

const SecureBodyPhoto = (props: Props) => {
    const {
        imageOverlayBottomText,
        imageOverlayTopText,
        size,
        usePopper,
        caseUuid,
        photo,
        rootClass,
        zIndex,
        caseFname,
        onPhotoClick,
        onDeleteClick,
        intercomTargetPropFlipCard,
    } = props;

    const dispatch = useGDispatch();
    const classes = useStyles();

    const [isCardFlipped, setIsCardFlipped] = useState(false);
    const [countDown, setCountDown] = useState(4);
    const [showImageAnchorEle, setShowImageAnchorEle] = useState<HTMLElement | null>(null);
    const [imageDownloadData, setImageDownloadData] = useState<DocDownloadResponse | null>(null);

    const isSmall = size === 'small';

    useEffect(() => {
        if (countDown <= 0) {
            setIsCardFlipped(false);
        }
    }, [countDown]);

    useEffect(() => {
        let intervalTimer: NodeJS.Timer | null = null;
        if (isCardFlipped) {
            intervalTimer = setInterval(() => {
                setCountDown(prev => prev - 1);
            }, 1000);
            setCountDown(4);
        } else {
            if (intervalTimer) {
                clearInterval(intervalTimer);
            }
            setCountDown(0);
        }
        return function cleanupInterval() {
            if (intervalTimer) {
                clearInterval(intervalTimer);
            }
        };
    }, [isCardFlipped]);

    const handleClick = (event?: React.MouseEvent<HTMLElement>) => {

        if (onPhotoClick) {
            onPhotoClick();
        }

        if (usePopper) {
            setShowImageAnchorEle(event && event.currentTarget || null);
        } else {
            handleShowImage();
        }
    };

    const handleShowImage = async () => {

        if (imageDownloadData === null && caseUuid !== null) {
            const imageData = await dispatch(getCaseIdPhotoDownloadurl(caseUuid, photo.s3_file_id));
            setImageDownloadData(imageData);
        }

        setShowImageAnchorEle(null);
        setIsCardFlipped(true);
    };

    const renderImageOverlay = () => {

        return (
            <>
                {renderDeleteIcon()}
                {imageOverlayTopText &&
                    <Typography color="secondary">{imageOverlayTopText}</Typography>
                }
                <ReportIcon
                    className={classNames(
                        classes.reportIcon,
                        isSmall && classes.marginTopAuto
                    )}
                />
                <Typography
                    color="secondary"
                    className={isSmall ? classes.smallText : undefined}
                >
                    {imageOverlayBottomText}
                </Typography>
            </>
        );
    };

    const renderImage = () => {

        if (imageDownloadData) {
            return (
                <>
                    <div style={{ backgroundImage: `url(${imageDownloadData.presignedurl})` }} />
                    {imageDownloadData &&
                        <IconButton
                            component="span"
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                                e.stopPropagation();
                                e.preventDefault();
                                downloadFromURL(imageDownloadData.presignedurl, 'Case-Image');
                            }}
                            className={classNames(
                                classes.downloadIcon,
                                classes.deleteDownloadBtn,
                                isSmall && classes.fontSize28
                            )}
                        >
                            <CloudDownloadIcon />
                        </IconButton>
                    }
                    {renderDeleteIcon()}
                    <span className={classNames(classes.countDown, isSmall && classes.fontSize24)}>
                        {countDown}
                    </span>
                </>
            );
        }

        return <></>;
    };

    const renderDeleteIcon = () => {

        if (!onDeleteClick) {
            return null;
        }

        return (
            <IconButton
                component="span"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (caseUuid !== null) {
                        onDeleteClick(caseUuid, photo.s3_file_id);
                    }
                }}
                className={classNames(
                    classes.deleteIcon,
                    classes.deleteDownloadBtn,
                    isSmall && classes.fontSize28
                )}
            >
                <DeleteForeverIcon />
            </IconButton>
        );
    };

    return (
        <div className={classNames(classes.root, rootClass)}>
            <FlipCard
                id="photo-id-card"
                isCardFlipped={isCardFlipped}
                buttonClasses={classes.buttonBase}
                buttonStyles={isSmall ? { width: 172, height: 172 } : undefined}
                frontSideOnClick={handleClick}
                frontSideContent={renderImageOverlay()}
                frontSideClasses={classNames(
                    classes.addPhoto,
                    classes.imageOvelay,
                    classes.flexDirectionColumn,
                    classes.flexCentred
                )}
                backSideContent={renderImage()}
                backSideClasses={classes.displayImage}
                intercomTargetPropFlipCard={intercomTargetPropFlipCard}
            />

            {(zIndex && caseFname) &&
                <ShowImagePopper
                    popperAnchorEle={showImageAnchorEle}
                    closePopper={handleClick}
                    zIndex={zIndex}
                    caseName={caseFname}
                    handleShowImage={handleShowImage}
                />
            }
        </div>
    );
};

export default SecureBodyPhoto;
