import { useMemo } from "react";
import {
    getGridNumericOperators,
} from '@mui/x-data-grid-premium';

const numericOperators = getGridNumericOperators()
    .filter(({ value }) => [
        '=',
        '!=',
        '>',
        '>=',
        '<',
        '<='
    ].indexOf(value) !== -1);

export const getNumericFilterOperators = () =>
    numericOperators.map((operator) => {
        switch (operator.value) {
            case '=':
                return { ...operator, label: 'is' };
            case '!=':
                return { ...operator, label: 'is not' };
            case '>':
                return { ...operator, label: 'greater than' };
            case '<':
                return { ...operator, label: 'less than' };
            case '>=':
                return { ...operator, label: 'greater than or equals' };
            case '<=':
                return { ...operator, label: 'less than or equals' };
            default:
                return operator;
        }
    });

const useNumericFilterOperators = () => {
    return useMemo(() => getNumericFilterOperators(), []);
};

export default useNumericFilterOperators;
