import { find, forEach, includes, sortBy } from 'lodash';

import { removeHiddenProductItems } from '../../../../services/goodsandservices.service';
import { groupByProductCategory } from '../../../../shared/goods_and_services/utils';
import {
    ProductCategory,
    ProductCategoryEnum,
    ProductContractItemUX,
    ProductItem,
    ProductPackageUX,
    ProductSupplierUXWithCategory,
    ProductUX,
    GPLContext
} from '../../../../shared/types';
import { Filter } from '../products/FilterResults';
import ProfessionalServices from '../professionalServices';
import Products from '../products';

interface Props {
    selectedTab: ProductCategory;
    caseFirstName: string;
    isShowPrices: boolean;
    context: GPLContext;
    canUserEditContract: boolean;
    contractPackageIds: { [id: number]: number };
    availablePackages: ProductPackageUX[];
    visibleProductItems: ProductItem[];
    funeralHomeSuppliers: ProductSupplierUXWithCategory[];
    productItems: ProductItem[];
    openAddItemDialog: (category: ProductCategory) => void;
    handleRemovePackage: (packageId: number) => void;
    handleAddPackage: (packageId: number) => void;
    handleRemoveContractItem: (productItem: ProductContractItemUX) => void;
    onProductItemClick: (productId?: number, contractItemId?: string) => void;
    onPackageClick: (packageId: number) => void;
    onAddContractItem: (product: ProductUX, quantity: number) => void;
    onUpdateContractItemQuantity: (contractItem: ProductContractItemUX, quantity: number) => void;
}

const ShowroomContent = (props: Props) => {
    const {
        selectedTab,
        caseFirstName,
        isShowPrices,
        context,
        canUserEditContract,
        contractPackageIds,
        availablePackages,
        visibleProductItems,
        funeralHomeSuppliers,
        productItems,
        openAddItemDialog,
        handleRemovePackage,
        handleAddPackage,
        handleRemoveContractItem,
        onProductItemClick,
        onPackageClick,
        onAddContractItem,
        onUpdateContractItemQuantity
    } = props;

    if (selectedTab === ProductCategoryEnum.care_of_loved_one) {
        return (
            <ProfessionalServices
                context={context}
                caseFirstName={caseFirstName}
                productItemsByCategory={groupByProductCategory(visibleProductItems)}
                availablePackages={availablePackages}
                contractPackageIds={contractPackageIds}
                isShowPrices={isShowPrices}
                canUserEditContract={canUserEditContract}
                onAddAdditionalItem={openAddItemDialog}
                onProductItemClick={onProductItemClick}
                onPackageClick={onPackageClick}
                onAddContractItem={onAddContractItem}
                onUpdateContractItemQuantity={onUpdateContractItemQuantity}
                onRemoveContractItem={handleRemoveContractItem}
                onAddPackage={handleAddPackage}
                onRemovePackage={handleRemovePackage}
            />
        );
    }

    const productItemsByCategory = groupByProductCategory(productItems);
    const productItemsForCategory = productItemsByCategory[selectedTab] || [];

    const featuredProductItems = productItemsForCategory.filter((v) =>
        v.product && v.product.featured_rank !== null);
    const featuredProductItemsSorted = sortBy(featuredProductItems, (v) => v.product && v.product.featured_rank);

    let filters: Filter[] = [{
        name: 'Manufacturer',
        options: [],
    }];

    productItemsForCategory.forEach(({ product }) => {
        if (!product) {
            return;
        }
        // add all unique manufacturers as a filter
        if (product.manufacturer_name && !includes(filters[0].options, product.manufacturer_name)) {
            filters[0].options.push(product.manufacturer_name);
        }
        // add all unique tags as filters
        forEach(product.tags, (value, name) => {
            const existing = find(filters, { name });
            if (!existing) {
                filters.push({
                    name,
                    options: [value],
                });
            } else {
                if (!includes(existing.options, value)) {
                    existing.options.push(value);
                }
            }
        });
    });
    // remove any empty filters
    filters = filters.filter((filter) => filter.options.length > 0);

    const allPackagesByCategory = groupByProductCategory(availablePackages);

    const visibleProductItemsForCategory = removeHiddenProductItems(productItemsForCategory);

    return (
        <Products
            context={context}
            allProductItems={productItemsForCategory}
            visibleProductItems={visibleProductItemsForCategory}
            packagesForCategory={allPackagesByCategory[selectedTab] || []}
            contractPackageIds={contractPackageIds}
            filters={filters}
            featured={featuredProductItemsSorted}
            caseName={caseFirstName}
            isShowPrices={isShowPrices}
            onAddAdditionalItem={() => openAddItemDialog(selectedTab)}
            onProductItemClick={onProductItemClick}
            onPackageClick={onPackageClick}
            onAddContractItem={onAddContractItem}
            onUpdateContractItemQuantity={onUpdateContractItemQuantity}
            onRemoveContractItem={handleRemoveContractItem}
            canUserEditContract={canUserEditContract}
            onAddPackage={handleAddPackage}
            onRemovePackage={handleRemovePackage}
            category={selectedTab}
            funeralHomeSuppliers={funeralHomeSuppliers}
            productSummary={[]}
        />
    );
};

export default ShowroomContent;