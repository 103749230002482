
import makeGStyles from "../../styles/makeGStyles";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { APP_SECONDARY_COLOR } from "../../constants/colorVariables";

const useStyles = makeGStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '36px 0 44px'
    },
    textContainer: {
        color: APP_SECONDARY_COLOR,
        textAlign: 'center',
        '& p': {
            color: APP_SECONDARY_COLOR,
            padding: '0px 10px',
        },
    },
    heading: {
        fontSize: 24,
        lineHeight: '1.2em',
    },
    text: {
        fontSize: 14,
    },
}), { name: 'ReportsEmptyState' });

interface Props {
    icon: JSX.Element;
    heading: string;
    bottomText: string;
}

const ReportsEmptyState = (props: Props) => {
    const { icon, heading, bottomText } = props;
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.root}
        >
            <Grid item className={classes.textContainer}>
                <Typography className={classes.heading}>
                    {heading}
                </Typography>

                {icon}

                <Typography className={classes.text}>
                    {bottomText}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ReportsEmptyState;