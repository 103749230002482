import {
    CaseType,
    GatherCaseFHUpdateRequestUX,
    GatherCaseUpdateRequest,
    WorkflowSummary,
} from '../../../../shared/types';
import { renewCaseWorkflow, updateCase, updateCaseForFuneralHome } from '../../../../actions/GatherCase.action';
import { useGDispatch, useGSelector } from '../../../../types';
import { useLocation } from 'react-router-dom';
import { doesPathnameStartWithRoute, RouteBuilder } from '../../../../services';
import ChangeWorkflowAndCaseTypePureDialog, {
    ChangeWorkflowAndCaseTypePureProps,
} from './ChangeWorkflowAndCaseTypePure.dialog';
import { useEffect } from 'react';
import { loadWorkflows } from '../../../../actions/Workflow.action';

interface Props
    extends Omit<
        ChangeWorkflowAndCaseTypePureProps,
        'isSaving' | 'isLoading' | 'workflows' | 'onRenewCaseWorkflow' | 'onWorkflowChange' | 'onCaseTypeChange'
    > {
    onWorkflowChanged?: (workflow: WorkflowSummary) => void;
    onCaseTypeUpdated?: (caseUuid: string, newCaseType: CaseType) => void;
}

const ChangeWorkflowAndCaseTypeDialog = (props: Props) => {
    const { gatherCase, funeralHome, isOpen, onWorkflowChanged, onCaseTypeUpdated, ...rest } = props;

    const dispatch = useGDispatch();

    const location = useLocation();

    const isSaving = useGSelector((state) => state.casesState.isSaving);
    const workflows = useGSelector(({ workflowState }) => workflowState.workflows);
    const isLoading = useGSelector(({ workflowState }) => workflowState.isLoadingWorkflowSummaries);

    useEffect(() => {
        if (isOpen) {
            dispatch(loadWorkflows(funeralHome.id));
        }
    }, [dispatch, funeralHome.id, isOpen]);

    const handleCaseTypeChange = (caseType: CaseType) => {
        dispatch(updateCase(gatherCase.uuid, { case_type: caseType }));
        onCaseTypeUpdated?.(gatherCase.uuid, caseType);
    };

    const handleWorkflowChange = async (workflow: WorkflowSummary) => {
        const isFamilyRoute = doesPathnameStartWithRoute(
            RouteBuilder.FamilyPage({
                caseName: gatherCase.name,
                funeralHomeKey: funeralHome.key,
            }),
            location.pathname,
        );
        if (isFamilyRoute) {
            const changes: GatherCaseUpdateRequest = {
                workflow_id: workflow.id,
            };

            await dispatch(updateCase(gatherCase.uuid, changes));
        } else {
            const caseChanges: GatherCaseFHUpdateRequestUX = {
                workflow,
            };
            await dispatch(
                updateCaseForFuneralHome({
                    funeralHomeId: gatherCase.funeral_home_id,
                    caseUuid: gatherCase.uuid,
                    caseChanges,
                }),
            );
        }

        onWorkflowChanged?.(workflow);
    };

    const handleRenewWorkflow = async (caseUuid: string) => {
        await dispatch(
            renewCaseWorkflow({
                caseUuid,
            }),
        );
    };

    return (
        <ChangeWorkflowAndCaseTypePureDialog
            {...rest}
            isOpen={isOpen}
            gatherCase={gatherCase}
            funeralHome={funeralHome}
            isSaving={isSaving}
            isLoading={isLoading}
            workflows={workflows}
            onRenewCaseWorkflow={handleRenewWorkflow}
            onCaseTypeChange={handleCaseTypeChange}
            onWorkflowChange={handleWorkflowChange}
        />
    );
};

export default ChangeWorkflowAndCaseTypeDialog;
