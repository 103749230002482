import classNames from 'classnames';
import { HelperInvitationDialogContext, openHelperInvitationDialog } from '../../actions/Dialog.action';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import {
    EntityCaseRole,
    EntityRelationshipType,
    EntitySummary,
    getCaseEntity,
    GuestListUser,
    UserRoles,
} from '../../shared/types';
import { GStyles } from '../../styles/GStyles';
import { useGDispatch, useGSelector } from '../../types';
import AssignmentPopper, { ButtonText } from './AssignmentPopper';
import { BaseAssignEntity } from './baseAssign/BaseAssign';
import makeStyles from '@mui/styles/makeStyles';
import { log } from '../../logger';
import { getSecondaryTextForEntitySummary } from './baseAssign/BaseAssignForEntitySummary';

const useStyles = makeStyles(
    {
        selectHelperPopoverElement: {
            margin: '8px 0 4px',
        },
        assignButton: {
            textTransform: 'none',
            fontSize: 18,
            marginLeft: 10,
            fontWeight: 200,
            whiteSpace: 'nowrap',
        },
    },

    { name: 'SelectPartner' },
);

export enum DCEntityEnum {
    Father = 'Father',
    Mother = 'Mother',
    Spouse = 'Spouse',
    Informant = 'Informant',
}

interface Props {
    zIndex: number;
    selectedCaseEntityId: number | null;
    activeEntity: Pick<EntitySummary, 'fname' | 'user_id' | 'entity_id'>;
    isDisabled: boolean;
    disabledTooltip: string;
    addButtonClass?: string;
    popoverElement?: JSX.Element;
    anchorElement?: HTMLElement;
    invitationProps?: {
        context?: HelperInvitationDialogContext;
        relationshipType?: EntityRelationshipType;
        homeAddressOptional?: boolean;
    };
    setPartner: (caseEntityId: number | null) => void;
    onClose?: () => void;
}

const isEntitySummary = (user: EntitySummary | GuestListUser): user is EntitySummary => {
    return Boolean('cases' in user && user.cases);
};

const SelectPartner = (props: Props) => {
    const {
        selectedCaseEntityId,
        zIndex,
        setPartner,
        isDisabled,
        disabledTooltip,
        addButtonClass,
        activeEntity,
        popoverElement,
        anchorElement,
        invitationProps,
        onClose
    } = props;

    const classes = useStyles();

    const dispatch = useGDispatch();

    const activeCase = useGSelector(({ casesState }) => casesState.selectedCase);
    const user = useGSelector(({ userSession }) => userSession.userData);
    const helpers = useGSelector(({ casesState }) => casesState.helpers);
    const guestList = useGSelector(({ casesState }) =>
        casesState.guestList.filter((guest) => guest.user_id !== activeEntity.user_id),
    );

    const potentialPartners = useMemo(
        () =>
            uniqBy([...helpers, ...guestList], (person) => person.entity_id).filter(
                (helper) => helper.entity_id !== activeEntity.entity_id,
            ),
        [guestList, helpers, activeEntity.entity_id],
    );
    const mappedPotentials: BaseAssignEntity[] = useMemo(
        () =>
            potentialPartners.map((person): BaseAssignEntity => {

                const secondaryText = isEntitySummary(person)
                    ? activeCase && getSecondaryTextForEntitySummary({ entity: person, gatherCase: activeCase })
                    : person.relationship && activeCase && `${activeCase.fname}'s ${person.relationship}`;
                return {
                    ...person,
                    user: 'user' in person ? person.user : null,
                    isAdmin:
                        isEntitySummary(person) && activeCase
                            ? UserRoles.isFamilyAdmin(person, activeCase.id)
                            : false,
                    secondaryText,
                    email: isEntitySummary(person) ? person.email : null,
                };
            }),
        [potentialPartners, activeCase],
    );

    const selectedPartner = potentialPartners.find((potential) =>
        isEntitySummary(potential)
            ? potential.cases.some((c) => c.case_entity_id === selectedCaseEntityId)
            : potential.case_entity_id === selectedCaseEntityId,
    );

    const isMe = user?.entity_id === activeEntity.entity_id;

    let label: string;
    let addButtonText: string;
    let headerText: string;
    if (isMe) {
        addButtonText = `Add my Partner`;
        headerText = `Specify my Partner`;
        if (selectedPartner) {
            label = `${selectedPartner.fname} is my Partner`;
        } else {
            label = `Specify my Partner`;
        }
    } else {
        addButtonText = `Add ${activeEntity.fname}'s Partner`;
        headerText = `Specify ${activeEntity.fname}'s Partner`;
        if (selectedPartner) {
            label = `${selectedPartner.fname} is ${activeEntity.fname}'s Partner`;
        } else {
            label = `Specify ${activeEntity.fname}'s Partner`;
        }
    }
    const buttonText: ButtonText = {
        assignedTo: selectedPartner ? [selectedPartner] : [],
        label,
    };

    const handleInvited = (invited: EntitySummary) => {
        if (!activeCase) {
            return;
        }
        const caseEntity = getCaseEntity(invited, activeCase.id);
        if (caseEntity) {
            setPartner(caseEntity.case_entity_id);
        } else {
            log.warn('No case entity for newly invited user', { invited });
        }
    };

    const handleSelected = (selected: BaseAssignEntity | null) => {
        if (!activeCase) {
            log.warn('No case found when selecting partner');
            return;
        }
        if (selected) {
            const newPartner = potentialPartners.find((person) => person.entity_id === selected.entity_id);
            if (!newPartner) {
                log.warn('Could not find partner for selected', { selected, potentialPartners });
                return;
            }
            const caseEntityId = isEntitySummary(newPartner)
                ? newPartner.cases.find((c) => c.gather_case_id === activeCase.id)?.case_entity_id
                : newPartner.case_entity_id;
            if (!caseEntityId) {
                log.warn('Could not find newly selected partners case entity id', { newPartner, activeCase });
                return;
            }
            setPartner(caseEntityId);
        } else {
            setPartner(null);
        }
    };

    const openInvitationDialog = () => {
        dispatch(
            openHelperInvitationDialog({
                zIndex: zIndex + 2,
                defaultTab: EntityCaseRole.guest,
                onAddedCallback: handleInvited,
                ...invitationProps
            }),
        );
    };

    return (
        <AssignmentPopper
            zIndex={zIndex}
            headerText={headerText}
            addButtonText={addButtonText}
            items={mappedPotentials}
            onAdd={openInvitationDialog}
            setAssignee={handleSelected}
            shouldClosePopperOnInvite
            canUnassign
            buttonText={buttonText}
            popoverTextClass={classes.assignButton}
            popoverContainerClass={classNames(GStyles.textCenter, classes.selectHelperPopoverElement)}
            isDisabled={isDisabled}
            disabledTooltip={disabledTooltip}
            addButtonClass={addButtonClass}
            popoverElement={popoverElement}
            anchorElement={anchorElement}
            handleClose={onClose}
            showFamilyHelpers={true}
            showTeamMembers={false}
        />
    );
};

export default SelectPartner;
