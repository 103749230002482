import DevicesIcon from '@mui/icons-material/Devices';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import FuneralHomeLogo from '../../../../common/FuneralHomeLogo';
import { SelfLoginStyledProps } from '../SelfLogin.styles';
import { LoginDialogStep, ExistingUserStep } from '../SelfLoginDialog';
import SelfLoginPasswordResetSuccess from '../SelfLoginPasswordResetSuccess';

interface Props extends SelfLoginStyledProps {
    hasFHLogo: boolean;
    passwordResetMessage: string;
    passwordResetPending: boolean;
    hasLoginDetailsError: boolean;
    existingUserLoginEmailPhone: string;
    isActive: boolean;
    isPasswordResetInProgress: boolean;
    localPasswordResetMessage: string;
    goToStep: (step: LoginDialogStep) => void;
    goToSubStep: (subStep: ExistingUserStep) => void;
    handleResetPassword: () => void;
    setExistingUserEmailPhone: (data: string) => void;
}

const ResetPasswordStep = (props: Props) => {
    const {
        classes,
        hasFHLogo,
        passwordResetMessage,
        passwordResetPending,
        hasLoginDetailsError,
        existingUserLoginEmailPhone,
        isActive,
        isPasswordResetInProgress,
        localPasswordResetMessage,
        goToStep,
        goToSubStep,
        handleResetPassword,
        setExistingUserEmailPhone
    } = props;

    const emailPhone = existingUserLoginEmailPhone;

    const hasError = Boolean(localPasswordResetMessage && localPasswordResetMessage.length
        || (hasLoginDetailsError && !emailPhone)
        || (isPasswordResetInProgress && passwordResetMessage.length
            && passwordResetMessage !== 'NOTSENT')
    );

    return (
        <Fade
            timeout={600}
            in={isActive}
            appear
            mountOnEnter
            unmountOnExit
        >
            <Grid item xs={12} className={classes.loginDetailsContainer}>
                {isPasswordResetInProgress && passwordResetPending === false && passwordResetMessage === '' ?
                    <SelfLoginPasswordResetSuccess
                        emailOrPhone={emailPhone}
                        gotoDisplayMessage="Login Now"
                        gotoLink={() => goToStep(LoginDialogStep.existingUser)}
                    />
                    :
                    <>
                        <Grid item xs={12} className={classNames(classes.userDetails, classes.height74)}>
                            <Typography className={classes.fontSize16}>Reset Your Password</Typography>
                            <Typography
                                color="secondary"
                                className={classes.resetPasswordHeading}
                            >
                                Enter your email or mobile number below
                                and we will send you instructions on how to quickly
                                reset your password.
                            </Typography>

                            <FormControl
                                className={classNames(classes.loginFormControl, classes.loginForm)}
                                error={hasLoginDetailsError && !emailPhone}
                            >
                                <DevicesIcon color="secondary" className={classes.marginTop16} />
                                <TextField
                                    label="Your Email or Mobile number"
                                    color="primary"
                                    fullWidth
                                    autoComplete="new-email"
                                    value={emailPhone}
                                    error={hasError}
                                    onChange={evt => setExistingUserEmailPhone(evt.target.value)}
                                    className={classNames(classes.marginLeft8, classes.loginTextField)}
                                />
                                {localPasswordResetMessage ?
                                    <FormHelperText error={hasError} className={classes.loginErrorText}>
                                        {localPasswordResetMessage}
                                    </FormHelperText>

                                    : !emailPhone.length && hasLoginDetailsError &&
                                    <FormHelperText error={hasError} className={classes.loginErrorText}>
                                        Email or Phone is required.
                                    </FormHelperText>
                                }
                                {isPasswordResetInProgress && passwordResetMessage.length
                                    && passwordResetMessage !== 'NOTSENT'
                                    && !!emailPhone.length
                                    && <FormHelperText error={hasError} className={classes.loginErrorText}>
                                        {passwordResetMessage}
                                    </FormHelperText>
                                }
                            </FormControl>

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.resetButton}
                                disabled={passwordResetPending}
                                onClick={handleResetPassword}
                            >
                                {passwordResetPending && <CircularProgress
                                    color="primary"
                                    thickness={3}
                                    className={classNames(classes.buttonProgress, classes.resetProgress)}
                                />}
                                Send Reset instructions
                            </Button>

                            <Typography
                                color="secondary"
                                className={classes.forgetPwBottomText}
                            >
                                Never mind, I just remembered my password
                                <span
                                    className={classNames(
                                        classes.fontWeight500,
                                        classes.cursorPointer,
                                        classes.displayBlock
                                    )}
                                    onClick={e => goToSubStep(ExistingUserStep.login)}
                                >
                                    Return to Log In
                                </span>
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            className={classNames(classes.continueButtonContainer, classes.margin_0)}
                        >
                            <Typography color="secondary"
                                className={classNames(classes.haveAccount, classes.margin_0)}>
                                I don't have an account.&nbsp;
                                <span
                                    className={classes.loginText}
                                    onClick={e => goToStep(LoginDialogStep.name)}>
                                    Create one now
                                </span>
                            </Typography>
                        </Grid>

                        {hasFHLogo &&
                            <FuneralHomeLogo className={classNames(classes.fhLogo, classes.marginBottom24)} />}
                    </>
                }
            </Grid>
        </Fade>
    );
};

export default ResetPasswordStep;