import React from 'react';
import classNames from 'classnames';
import ReactSignatureCanvas from 'react-signature-canvas';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

import CaseBelongingDecision from './CaseBelongingDecision';
import UploadOptionalPhoto from './UploadOptionalPhoto';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import { SlideTransition } from '../../../../common/Transitions';
import withFullScreen from '../../../../common/utilHOC/WithFullScreen';
import {
    CaseBelongingCreateRequest,
    CaseBelongingDecisionType,
    CaseBelongingPlaceHolder,
    CaseBelongingUpdateRequest,
    CaseBelongingUX,
    S3FileCreateRequest,
} from '../../../../../shared/types';
import { RED_COLOR } from '../../../../../constants/colorVariables';
import { AppDispatch } from '../../../../../store';
import withState from '../../../../common/utilHOC/WithState';
import { createBelonging, updateBelonging, uploadBelongingPhoto } from '../../../../../actions/Belongings.action';
import { getDataFromBlob } from '../../../../../services/doc.service';
import { YesNoUnknownEnum } from '../../../../../shared/types';
import SignatureComponent from '../completeStepDialog/components/SignatureComponent';
import { getDataURIFromFile, getIntercomTargetProp } from '../../../../../services';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import { createRef } from 'react';
import { uploadSignatureFromRef } from '../../utils';
import { generateCenteredS3Crop } from '../../../../../shared/utils/s3Image';

const styles: StyleRulesCallback<Theme, Props, Classes> = theme => ({
    root: {},
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '16px 12px',
        '& p': {
            fontSize: 16,
            lineHeight: 1,
            '@media (min-width: 425px)': {
                fontSize: 20
            },
            '&:not($existingItem)': {
                '@media (min-width: 375px)': {
                    fontSize: 20
                },
            },

        }
    },
    dialogContent: {
        padding: '20px 12px !important',
    },
    dialogPaper: {
        [theme.breakpoints.up('sm')]: {
            width: 425
        }
    },
    closeIcon: {
        fontSize: 32,
        cursor: 'pointer',
        marginLeft: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        }
    },
    textfieldContainer: {
        padding: '12px 8px 32px 0'
    },
    signatureContainer: {
        margin: '64px auto 32px',
        maxWidth: 425
    },
    uploadPhotoFooterText: {
        padding: '4px 12px 0',
        color: RED_COLOR,
        textAlign: 'center'
    },
    footerButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    signatureHeading: {
        maxWidth: '94%',
        fontSize: 10,
        [theme.breakpoints.up(375)]: {
            fontSize: 12,
        },
        [theme.breakpoints.up(425)]: {
            fontSize: 14
        }
    },
    existingItem: {}
});

export const getDecisionText = (
    caseName: string,
    decision: CaseBelongingDecisionType
) => {
    const itemName = 'item';
    switch (decision) {
        case CaseBelongingDecisionType.return_to_family:
            return `Family wants ${itemName} returned to them`;
        case CaseBelongingDecisionType.keep:
            return `Family wants to keep ${itemName} with ${caseName}`;
        case CaseBelongingDecisionType.donate:
            return `Family wants the ${itemName} donated`;
        case CaseBelongingDecisionType.dispose:
            return `Family wants the ${itemName} thrown away`;
        case CaseBelongingDecisionType.unsure:
            return `Unsure of what family wants`;
        default:
            return <></>;
    }
};

export const getFinalDecisionText = (
    caseName: string,
    decision: CaseBelongingDecisionType
) => {
    const itemName = 'Item';
    switch (decision) {
        case CaseBelongingDecisionType.return_to_family:
            return `${itemName} has been returned to family`;
        case CaseBelongingDecisionType.keep:
            return `${itemName} has been permanently kept with ${caseName}`;
        case CaseBelongingDecisionType.donate:
            return `${itemName} has been donated`;
        case CaseBelongingDecisionType.dispose:
            return `${itemName} has been thrown away`;
        case CaseBelongingDecisionType.unsure:
            return `Unsure of what family wants`;
        default:
            return <></>;
    }
};

export const getDecisionIcon = (decision: CaseBelongingDecisionType) => {
    switch (decision) {
        case CaseBelongingDecisionType.return_to_family:
            return <KeyboardReturnIcon color="primary" fontSize="small" />;
        case CaseBelongingDecisionType.keep:
            return <SaveAltIcon color="primary" fontSize="small" />;
        case CaseBelongingDecisionType.donate:
            return <VolunteerActivismIcon color="primary" fontSize="small" />;
        case CaseBelongingDecisionType.dispose:
            return <DeleteOutlineIcon color="primary" fontSize="small" />;
        case CaseBelongingDecisionType.unsure:
            return <QuestionMarkIcon color="primary" fontSize="small" />;
        default:
            return <></>;
    }
};

type Classes = 'root' | 'dialogTitle' | 'dialogContent' | 'dialogPaper' | 'textfieldContainer' | 'closeIcon'
    | 'existingItem' | 'signatureContainer' | 'uploadPhotoFooterText' | 'footerButton' | 'signatureHeading';
type StyledProps = WithGStyles<Classes>;

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    zIndex: number;
    isOpen: boolean;
    caseFirstName: string;
    caseUuid: string | null; // null case is for Back Office preview
    userFullName: string;
    existingItem?: CaseBelongingUX;
    onClose: (placeHolderItem?: CaseBelongingPlaceHolder) => void;
    dispatch: AppDispatch;
}
interface State {
    optionalPhoto: string;
    saveAttempted: boolean;
    itemName: string;
    itemDescription: string;
    itemDecision: CaseBelongingDecisionType | null;
    didSigned: boolean;
    decisionToggle: YesNoUnknownEnum;
    belongingPhotoCreateRequest: S3FileCreateRequest | null;
    belongingPhotoUri: string | null;
    isSaving: boolean;
    isCloseConfirmationDialogOpen: boolean;
}

const getInitState = (existingItem?: CaseBelongingUX): State => ({
    itemDecision: existingItem && existingItem.decision || null,
    itemDescription: existingItem && existingItem.description || '',
    itemName: existingItem && existingItem.name || '',
    optionalPhoto: existingItem && existingItem.photo_url || '',
    saveAttempted: false,
    didSigned: false,
    decisionToggle: YesNoUnknownEnum.Unknown,
    belongingPhotoCreateRequest: null,
    belongingPhotoUri: null,
    isSaving: false,
    isCloseConfirmationDialogOpen: false
});

type CombinedProps = Props & DialogProps & StyledProps;
class AddCaseBelongingDialog extends React.Component<CombinedProps, State> {
    state: State = getInitState(this.props.existingItem);

    private signatureRef = createRef<ReactSignatureCanvas | null>();

    componentDidUpdate(prevProps: Props) {
        const { existingItem, isOpen } = this.props;

        if (prevProps.isOpen !== isOpen) {
            this.setState(getInitState(existingItem));

            if (isOpen && this.state.didSigned) {
                this.setState({ didSigned: false });
            }
        }
    }

    renderNameAndDescriptionTextfield = () => {
        const { classes } = this.props;
        const { saveAttempted, itemName, itemDescription } = this.state;

        const isItemNameInvalid = saveAttempted && !itemName.trim();

        return (
            <Grid item xs={12} className={classes.textfieldContainer}>
                <TextField
                    label="Item Name"
                    required
                    value={itemName}
                    onChange={this.handleChangeItemName}
                    error={isItemNameInvalid}
                    fullWidth
                    className={classes.marginBottom10}
                    {...getIntercomTargetProp(`TrackingPage-AddBelonging-ItemName`)}
                />

                <TextField
                    label="Item Description and Notes (optional)"
                    value={itemDescription}
                    onChange={this.handleChangeItemDescription}
                    multiline
                    minRows={2}
                    maxRows={4}
                    fullWidth
                    {...getIntercomTargetProp(`TrackingPage-AddBelonging-ItemDescriptionAndNotes`)}
                />
            </Grid>
        );
    };

    renderCloseConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isCloseConfirmationDialogOpen } = this.state;

        return (
            <ConfirmationDialog
                zIndex={zIndex + 1}
                open={isCloseConfirmationDialogOpen}
                onClose={this.closeConfirmationDialog}
                onConfirm={() => this.closeDialog(true)}
                header="Are you sure?"
                subHeader="Are you sure you want to close before recording this belonging?"
            />
        );
    };

    render(): React.ReactNode {
        const {
            isOpen,
            classes,
            zIndex,
            fullScreen,
            existingItem,
            caseFirstName,
            userFullName,
        } = this.props;
        const {
            saveAttempted,
            itemDecision,
            itemName,
            didSigned,
            decisionToggle,
            optionalPhoto,
            belongingPhotoUri,
            isSaving
        } = this.state;

        return (
            <Dialog
                open={isOpen}
                onClose={() => this.closeDialog()}
                style={{ zIndex }}
                transitionDuration={300}
                TransitionComponent={SlideTransition}
                fullScreen={fullScreen}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography
                        color="inherit"
                        className={classNames(classes.flexCentred, existingItem && classes.existingItem)}
                    >
                        {existingItem
                            ? 'ADJUST ANSWER'
                            : <><AddIcon fontSize="small" color="inherit" />&nbsp;ADD NEW BELONGING</>
                        }
                    </Typography>

                    <CloseIcon onClick={() => this.closeDialog()} color="inherit" className={classes.closeIcon} />
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {!existingItem &&
                        <>
                            <UploadOptionalPhoto
                                optionalPhoto={belongingPhotoUri || optionalPhoto}
                                savePhoto={this.savePhoto}
                                deletePhoto={this.deletePhoto}
                            />
                            <Typography className={classes.uploadPhotoFooterText}>
                                IMPORTANT: It is your responsibility to ensure this photo is appropriate as
                                it may be seen by the family.
                            </Typography>
                        </>
                    }

                    {this.renderNameAndDescriptionTextfield()}

                    <CaseBelongingDecision
                        decision={itemDecision}
                        caseFirstName={caseFirstName}
                        itemName={itemName}
                        toggleCheck={decisionToggle}
                        handleToggleButton={(value) =>
                            this.setState({ decisionToggle: YesNoUnknownEnum[value] })
                        }
                        isDecisionInvalid={!!(saveAttempted && !itemDecision)}
                        handleChangeItemDecision={this.handleChangeItemDecision}
                    />

                    <Grid item={true} xs={12} className={classes.signatureContainer}>
                        <SignatureComponent
                            signatureUrl={null} // not showing completed belongings in this dialog
                            signatureHeadingClass={classes.signatureHeading}
                            signerFullName={userFullName}
                            hasSignError={!didSigned && saveAttempted}
                            canvasRef={this.signatureRef}
                            hasSigned={didSigned}
                            clearSignature={() => {
                                if (this.signatureRef.current) {
                                    this.signatureRef.current.clear();
                                }
                                this.setState({ didSigned: false });
                            }}
                            onStopDrawing={() => this.setState({ didSigned: true })}
                            heading={<>
                                Sign below to verify and attest that this <br />
                                belonging was recorded accurately and completely
                            </>}
                            intercomTargetProp={`TrackingPage-AddBelonging-SignatureComponent`}
                            intercomTargetPropClearSignature={`TrackingPage-AddBelonging-Clear`}
                        />
                    </Grid>

                    <Grid
                        container={true}
                        justifyContent="center"
                        className={classNames(classes.marginTop20, existingItem && classes.footerButton)}>
                        {existingItem &&
                            <Button
                                size="large"
                                color="primary"
                                variant="text"
                                disabled={isSaving}
                                onClick={() => this.closeDialog()}
                            >
                                Cancel
                            </Button>
                        }
                        <LoadingButton
                            size="large"
                            color="primary"
                            variant="contained"
                            loading={isSaving}
                            onClick={this.handleSave}
                            disabled={isSaving || saveAttempted && !this.validateData()}
                            {...getIntercomTargetProp(`TrackingPage-AddBelonging-SaveButton`)}
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </DialogContent>

                {this.renderCloseConfirmationDialog()}
            </Dialog>
        );
    }

    closeConfirmationDialog = () => this.setState({ isCloseConfirmationDialogOpen: false });

    openConfirmationDialog = () => this.setState({ isCloseConfirmationDialogOpen: true });

    isSomethingChanged = () => {
        const { existingItem } = this.props;
        const { itemName, itemDescription, itemDecision, belongingPhotoUri } = this.state;

        const initState = getInitState(existingItem);
        return initState.itemName !== itemName
            || initState.itemDescription !== itemDescription
            || initState.itemDecision !== itemDecision
            || initState.belongingPhotoUri !== belongingPhotoUri;
    };

    closeDialog = (skipCheck: boolean = !!this.props.existingItem) => {
        if (!skipCheck && this.isSomethingChanged()) {
            this.openConfirmationDialog();
            return;
        }
        this.setState(getInitState(), this.props.onClose);
    };

    validateData = () => {
        const { itemDecision, itemName } = this.state;

        return !!itemName.trim()
            && (this.signatureRef.current && !this.signatureRef.current.isEmpty())
            && !!itemDecision;
    };

    handleSave = async () => {
        const { existingItem, dispatch, caseUuid } = this.props;
        const {
            itemName,
            itemDecision,
            itemDescription,
            decisionToggle,
            belongingPhotoCreateRequest
        } = this.state;

        if (!this.validateData() || !itemDecision || caseUuid === null) {
            this.setState({ saveAttempted: true });
            return;
        }

        // show placeholder only when creating a new belonging
        if (!existingItem) {
            this.props.onClose({
                name: itemName,
                description: itemDescription,
                decision: itemDecision
            });
        }

        if (existingItem) {
            this.setState({ isSaving: true });
        }

        const signatureData = await uploadSignatureFromRef({
            ref: this.signatureRef.current,
            dispatch,
            caseUuid,
        });
        if (signatureData) {
            if (existingItem) {
                const updateRequest: CaseBelongingUpdateRequest = {
                    name: itemName,
                    description: itemDescription,
                    decision: itemDecision,
                    signature: signatureData,
                    isComplete: decisionToggle === YesNoUnknownEnum.Yes,
                };
                await dispatch(updateBelonging({
                    caseUuid,
                    belongingId: existingItem.id,
                    updateRequest,
                }));
                this.setState({ isSaving: false });

            } else {
                const createRequest: CaseBelongingCreateRequest = {
                    name: itemName,
                    description: itemDescription,
                    photo: belongingPhotoCreateRequest,
                    decision: itemDecision,
                    signature: signatureData,
                    isComplete: decisionToggle === YesNoUnknownEnum.Yes,
                };
                dispatch(createBelonging({
                    caseUuid,
                    createRequest,
                }));
            }
        }

        this.closeDialog(true);
    };

    handleChangeItemName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ itemName: event.target.value });
    };

    handleChangeItemDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ itemDescription: event.target.value });
    };

    handleChangeItemDecision = (itemDecision: CaseBelongingDecisionType) => {
        this.setState({
            itemDecision,
            decisionToggle: YesNoUnknownEnum.Unknown
        });
    };

    deletePhoto = () => {
        this.setState({ belongingPhotoCreateRequest: null, belongingPhotoUri: null });
    };

    savePhoto = async (files: FileList | null) => {
        const { dispatch, caseUuid } = this.props;

        if (files && files.length) {
            this.setState({ isSaving: true });

            const thumbBlob = await generateCenteredS3Crop(files[0], 300 * 2);
            const [photo, imageUri] = await Promise.all([
                getDataFromBlob(thumbBlob, files[0].name),
                getDataURIFromFile(thumbBlob)
            ]);

            this.setState({ belongingPhotoUri: imageUri });

            if (photo && caseUuid !== null) {
                const fileCreateRequest = await dispatch(uploadBelongingPhoto(caseUuid, photo));
                if (fileCreateRequest !== null) {
                    this.setState({ belongingPhotoCreateRequest: fileCreateRequest });
                }
            }
            this.setState({ isSaving: false });
        }
    };


    handleToggleButton = (event: React.MouseEvent<HTMLElement>, value: string) => {
        this.setState({ decisionToggle: YesNoUnknownEnum[value] });
    };
}

export default withState()(withFullScreen()(withGStyles(styles)(AddCaseBelongingDialog)));
