import { useCallback, useState, createContext, useContext, MouseEvent } from 'react';
import { includes, once } from 'lodash';

import GDialog from '../GDialog';
import { HasIdProperty } from '../../../shared/types';
import AvailableDefaultItemDialogContent from './AvailableDefaultItemDialogContent';
import makeGStyles from '../../../styles/makeGStyles';
import classNames from 'classnames';
import { SortEndHandler } from 'react-sortable-hoc';

const useStyles = makeGStyles({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
                height: 'auto'
            }
        },
    },
    createNewContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            margin: 8
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    doneIcon: {
        paddingLeft: 8,
        marginRight: 8,
    },
    savingSpinner: {
        padding: '0px 10px',
        marginLeft: 5,
    },

    addTaskButton: {
        margin: '24px 12px 6px',
        '@media (min-width: 480px)': {
            margin: '24px 10px 24px 12px',
        },
    },
    addAnotherTaskButton: {
        margin: '6px 12px 24px',
        '@media (min-width: 480px)': {
            margin: '24px 12px 24px 10px',
        },
    },
    addButton: {
        margin: '24px 10px 24px 12px'
    },
    dialogContent: {
        padding: '0 12px',
        minHeight: 660,
        maxHeight: '100%'
    },
    dialogPaper: {},
    listContainer: {}
}, { name: 'AvailableDefaultItemsDialog' });

export interface DefaultItemType extends HasIdProperty {
    title: string;
    subtitle: string | null;
    icon?: string | null;
    iconImage?: string | null;
    sharedTitle?: string | null;
    isVisible?: boolean;
}
interface Props<T extends DefaultItemType> {
    itemType: string;
    titleIcon?: JSX.Element;
    isDialogOpen: boolean;
    selectedItemIds?: number[];
    availableItems: T[];
    zIndex: number;
    canAddMultiple?: boolean;
    titleClass?: string;
    createNewButtonText: string;
    createAnotherButtonText?: string;
    isSortable?: boolean;
    getHeader?: () => JSX.Element;
    closeDialog: () => void;
    onSortEnd?: SortEndHandler;
    onCreateNewItemClick?: () => void;
    onDefaultItemAdd?: (item: Readonly<T>) => Promise<void>;
    onCreateAnotherButtonClick?: () => void;
    getEndAdornment?: (item: Readonly<T>) => JSX.Element;
    getTitleIcon?: (item: Readonly<T>) => JSX.Element;
    getTitle?: (item: Readonly<T>) => JSX.Element;
    getSubHeader?: () => JSX.Element;
    getClassName?: (item: Readonly<T>) => string;
}

interface ContextProps<T extends DefaultItemType> extends Omit<Props<T>, 'zIndex'> {
    idsSaving: number[];
    searchText: string;
    setSearchText: (text: string) => void;
    hasItem: (item: T) => boolean;
    handleAddClick: (evt: MouseEvent<HTMLElement>, item: T) => Promise<void>;
}
const createAvailableItemsContext = once(<T extends DefaultItemType>() => createContext<ContextProps<T>>({
    itemType: '',
    isDialogOpen: false,
    availableItems: [],
    createNewButtonText: '',
    createAnotherButtonText: '',
    closeDialog: () => void 0,
    onCreateNewItemClick: () => void 0,
    searchText: '',
    setSearchText: () => void 0,
    idsSaving: [],
    hasItem: (_) => false,
    handleAddClick: async (_) => void 0
}));
export const useAvailableItemsContext = <T extends DefaultItemType>() =>
    useContext(createAvailableItemsContext<T>());

const AvailableDefaultItemsDialog = <T extends DefaultItemType>(props: Props<T>) => {
    const {
        zIndex,
        itemType,
        titleIcon,
        titleClass,
        isDialogOpen,
        selectedItemIds,
        canAddMultiple,
        closeDialog,
        onDefaultItemAdd,
    } = props;
    const { Provider } = createAvailableItemsContext<T>();

    const classes = useStyles();

    const [idsSaving, setIdsSaving] = useState<number[]>([]);
    const [searchText, setSearchText] = useState('');

    const hasItem = useCallback((item: T) => includes(selectedItemIds, item.id), [selectedItemIds]);

    const handleAddClick = useCallback(async (e: MouseEvent<HTMLElement>, item: T) => {
        if (!onDefaultItemAdd) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();

        if (hasItem(item) && !canAddMultiple) {
            return;
        }

        setIdsSaving((prevState) => ([
            ...prevState,
            item.id,
        ]));

        await onDefaultItemAdd(item);

        setIdsSaving((prevState) => prevState.filter((id) => id !== item.id));
    }, [canAddMultiple, hasItem, onDefaultItemAdd]);

    const handleClose = () => {
        setSearchText('');
        closeDialog();
    };

    return (
        <Provider
            value={{
                ...props,
                searchText,
                idsSaving,
                hasItem,
                setSearchText: (text) => setSearchText(text),
                handleAddClick
            }}
        >
            <GDialog
                title={itemType}
                isOpen={isDialogOpen}
                onClose={handleClose}
                rootClass={classes.root}
                zIndex={zIndex}
                contentClass={classes.dialogContent}
                titleClass={classNames(classes.padding10, titleClass)}
                titleIcon={titleIcon}
            >
                <AvailableDefaultItemDialogContent zIndex={zIndex + 1} />
            </GDialog>
        </Provider>
    );
};

export default AvailableDefaultItemsDialog;
