import moment from 'moment-timezone';
import * as classNames from 'classnames';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { DocWidgetProps, useDocWidgetStyles } from '.';
import { isTouchDevice } from '../../../services';
import { AcceptFileType } from '../FileUploader';
import UploadButton from '../UploadButton';
import Doc from './Doc';

const DocWidgetWithTabs = (props: DocWidgetProps) => {
    const { uploadButtonProps, isDocUploading, docs, docItemClass, handleUpload, menuHandler } = props;
    const classes = useDocWidgetStyles();

    const uploadNewFileButton = (
        <Tab
            component="div"
            label={handleUpload &&
                <UploadButton
                    {...uploadButtonProps}
                    handleUpload={handleUpload}
                    acceptFileTypes={[
                        AcceptFileType.JPEG,
                        AcceptFileType.GIF,
                        AcceptFileType.PNG,
                        AcceptFileType.DOCUMENT,
                        AcceptFileType.MSWORD,
                        AcceptFileType.PDF
                    ]}
                    buttonText="Upload new file"
                    isLoading={isDocUploading}
                    buttonIcon={<CloudUploadIcon />}
                />
            }
            disableTouchRipple
            disableRipple
            classes={{
                root: classNames(classes.docTabRoot, classes.docTabLabelContainer)
            }}
        />
    );

    return (
        <Tabs
            value={0}
            color="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
                scrollButtons: classes.docScrollButtons,
                indicator: classes.docTabsIndicator,
                root: classNames(classes.minHeight70, isTouchDevice() && classes.paddingBottom15)
            }}
        >
            {uploadNewFileButton}
            {docs.map(doc =>
                <Tab
                    key={doc.id}
                    disableTouchRipple
                    disableRipple
                    component="div"
                    classes={{
                        root: classNames(classes.docTabRoot, classes.docTabLabelContainer)
                    }}
                    label={
                        <Doc
                            key={doc.id}
                            name={doc.name}
                            uploadedBy={doc.uploadedBy}
                            uploadedTime={moment(doc.uploadedTime).format()}
                            classes={classes}
                            menuHandler={menuHandler
                                ? (e) => menuHandler(doc.id, e)
                                : undefined}
                            docItemClass={docItemClass}
                        />
                    }
                />
            )}
        </Tabs>
    );
};

export default DocWidgetWithTabs;
