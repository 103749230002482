import { useState } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import CloseIcon from '@mui/icons-material/Close';
import LabelIcon from '@mui/icons-material/Label';
import SearchIcon from '@mui/icons-material/Search';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import NewLabelDialog from '../newLabelDialog/NewLabel.dialog';
import CaseLabelList from './CaseLabelList';
import { SlideTransition } from '../../../common/Transitions';
import { CaseLabelUX, GatherCaseUX, UserProfile } from '../../../../shared/types';
import { getFullNameFromCase } from '../../../../services';
import useFullScreen from '../../../common/hooks/useFullScreen';
import LoadingSpinner from '../../../common/LoadingSpinner';
import Chip from '@mui/material/Chip';
import { canAdminCaseLabels } from '../../../../shared/authority/can';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { GStyles } from '../../../../styles/GStyles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        dialogTitle: {
            padding: '16px 12px',
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& p': {
                fontSize: 16,
                '@media (min-width: 375px)': {
                    fontSize: 20,
                },
            },
        },
        dialogPaper: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: 425,
            },
        },
        closeIcon: {
            fontSize: 32,
            cursor: 'pointer',
            marginLeft: 8,
            [theme.breakpoints.up('sm')]: {
                fontSize: 40,
            },
        },
        dialogContent: {
            padding: '12px 0 0 !important',
            '& $inner': {
                margin: '0 auto',
                maxWidth: 425,
            },
        },
        searchField: {
            display: 'flex',
            margin: '32px 24px 24px',
        },

        chip: {
            height: 28,
            fontSize: 14,
            padding: '0 4px',
        },
        labelList: {
            minHeight: '58vh',
        },
        contentHeading: {
            padding: '0 8px',
            lineHeight: 1.25,
        },
        chipDeleteIcon: {
            color: `${theme.palette.common.white} !important`,
            fontSize: '26px !important',
            marginRight: '2px !important',
        },
        selectedChipsContainer: {
            marginTop: 16,
            gap: '6px',
        },
        padding8: {
            padding: 8,
        },
        inner: {},
    }),
    { name: 'AddLabelPureDialog' },
);

export interface AddLabelCase extends Pick<GatherCaseUX, 'fname' | 'lname' | 'uuid' | 'labels'> {
    full_name?: GatherCaseUX['full_name'];
}

export interface AddLabelPureDialogProps {
    zIndex: number;
    isOpen: boolean;
    funeralHomeId: number;
    labels: CaseLabelUX[];
    gatherCase: AddLabelCase | null;
    user: UserProfile;
    isLoading: boolean;
    onClose: () => void;
    onAddLabelToCase: ((caseUuid: string, label: CaseLabelUX) => void) | null;
    onRemoveLabelFromCase: ((caseUuid: string, label: CaseLabelUX) => void) | null;
    onCreateLabelOnFH: (params: { funeralHomeId: number; label: string; color: string }) => void;
    onDeleteLabelFromFH: (label: CaseLabelUX) => void;
    onUpdateLabelOnFH: (params: { labelId: number; funeralHomeId: number; name: string; color: string }) => void;
}

function AddLabelPureDialog(props: AddLabelPureDialogProps) {
    const {
        zIndex,
        isOpen,
        labels,
        gatherCase,
        user,
        funeralHomeId,
        isLoading,
        onAddLabelToCase,
        onRemoveLabelFromCase,
        onCreateLabelOnFH,
        onDeleteLabelFromFH,
        onUpdateLabelOnFH,
    } = props;

    const fullScreen = useFullScreen();
    const classes = useStyles();

    const [searchText, setSearchText] = useState<string>('');
    const [isNewLabelDialogOpen, setIsNewLabelDialogOpen] = useState(false);
    const [labelToEdit, setLabelToEdit] = useState<Partial<CaseLabelUX> | null>(null);

    const canAddEditDeleteLabels = canAdminCaseLabels(user, funeralHomeId);

    const fullName = gatherCase ? gatherCase.full_name || getFullNameFromCase(gatherCase) : null;

    const deleteTooltip = fullName ? `Remove this label from ${fullName}'s case` : '';

    const doesLabelAlreadyExist = (labelName: string) => !!labels.find((l) => l.name === labelName);
    const headerTitle = fullName ? `Add Label to ${fullName}` : `Adjust Labels`;

    const closeDialog = () => {
        const { onClose } = props;

        setSearchText('');
        setIsNewLabelDialogOpen(false);
        setLabelToEdit(null);

        onClose();
    };

    const openNewLabelDialog = (incomingLabel?: Partial<CaseLabelUX>) => {
        setIsNewLabelDialogOpen(true);
        setLabelToEdit(incomingLabel || null);
    };

    const closeNewLabelDialog = () => {
        setIsNewLabelDialogOpen(false);
        setLabelToEdit(null);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={closeDialog}
                style={{ zIndex }}
                fullScreen={fullScreen}
                transitionDuration={300}
                TransitionComponent={SlideTransition}
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography color="inherit">{headerTitle}</Typography>

                    <CloseIcon color="inherit" className={classes.closeIcon} onClick={closeDialog} />
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Typography color="primary" align="center" className={classes.contentHeading}>
                        Labels are a helpful way to categorize cases across multiple dimensions. Names should be concise
                        and unique.
                    </Typography>

                    {gatherCase && gatherCase.labels.length !== 0 && (
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.selectedChipsContainer}
                        >
                            {gatherCase.labels.map((label) => (
                                <Chip
                                    key={label.id}
                                    label={label.name}
                                    sx={{ background: label.color }}
                                    onDelete={() => onRemoveLabelFromCase?.(gatherCase.uuid, label)}
                                    deleteIcon={
                                        <Tooltip title={deleteTooltip} placement="top">
                                            <RemoveCircleIcon className={classes.chipDeleteIcon} />
                                        </Tooltip>
                                    }
                                    className={classNames(GStyles.colorWhite, GStyles.fontSize14)}
                                />
                            ))}
                        </Grid>
                    )}

                    <TextField
                        autoComplete="off"
                        className={classes.searchField}
                        autoFocus
                        value={searchText}
                        placeholder="Start typing to filter list of available labels..."
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Grid item xs={12} className={classes.labelList}>
                        {isLoading && <LoadingSpinner containerClass={GStyles.marginTop50} />}
                        {!isLoading && (
                            <CaseLabelList
                                caseLabels={labels}
                                searchText={searchText}
                                showEditButton={canAddEditDeleteLabels}
                                selectLabel={(label) => {
                                    if (gatherCase) {
                                        onAddLabelToCase?.(gatherCase.uuid, label);
                                    }
                                }}
                                removeLabel={(label) => {
                                    if (gatherCase) {
                                        onRemoveLabelFromCase?.(gatherCase.uuid, label);
                                    }
                                }}
                                selectedLabels={gatherCase?.labels || []}
                                editLabel={openNewLabelDialog}
                                caseFullName={fullName}
                                showAddButton={!!gatherCase}
                            />
                        )}
                    </Grid>
                </DialogContent>

                {canAddEditDeleteLabels && (
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => openNewLabelDialog()}>
                            <LabelIcon className={GStyles.colorWhite} />
                            &nbsp;create new label
                        </Button>
                    </DialogActions>
                )}
            </Dialog>

            <NewLabelDialog
                zIndex={zIndex + 1}
                isOpen={isNewLabelDialogOpen}
                onClose={closeNewLabelDialog}
                existingLabel={labelToEdit}
                funeralHomeId={funeralHomeId}
                doesLabelAlreadyExist={doesLabelAlreadyExist}
                onCreateLabelOnFH={onCreateLabelOnFH}
                onDeleteLabelFromFH={onDeleteLabelFromFH}
                onUpdateLabelOnFH={onUpdateLabelOnFH}
            />
        </>
    );
}

export default AddLabelPureDialog;
