import { Theme, alpha } from "@mui/material/styles";
import { green, grey } from "@mui/material/colors";
import { WARNING_COLOR, ORANGE_COLOR, RED_COLOR, GREEN_COLOR } from "../constants/colorVariables";
import makeStyles from "@mui/styles/makeStyles";

export enum GStyles {
    colorSecondary = 'gatherColorSecondary',
    colorPrimary = 'gatherColorPrimary',
    colorRememberPrimary = 'gatherColorRememberPrimary',
    colorRememberSecondary = 'gatherColorRememberSecondary',
    colorWhite = 'gatherColorWhite',
    colorBlack = 'gatherColorBlack',
    colorBlack2 = 'gatherColorBlack2',
    colorTransparent = 'gatherColorTransparent',
    colorWarning = 'gatherColorWarning',
    textGreen500 = 'gatherTextGreen500',
    colorOrange = 'gatherColorOrange',
    colorRed = 'gatherColorRed',
    colorGreen = 'gatherColorGreen',

    fillPrimary = 'gatherFillPrimary',
    fillSecondary = 'gatherFillSecondary',
    fillWhite = 'gatherFillWhite',

    backgroundSecondary = 'gatherBackgroundSecondary',
    backgroundPrimary = 'gatherBackgroundPrimary',
    backgroundWhite = 'gatherBackgroundWhite',
    background_primary_0_2 = 'gatherBackground_primary_0_2',
    background_primary_0_0_4 = 'gatherBackground_primary_0_0_4',
    background_primary_0_0_8 = 'gatherBackground_primary_0_0_8',
    background_primary_0_0_12 = 'gatherBackground_primary_0_0_12',
    background_primary_0_0_10 = 'gatherBackground_primary_0_0_10',
    background_primary_0_1_8 = 'gatherBackground_primary_0_1_8',
    background_secondary_0_1 = 'gatherBackground_secondary_0_1',
    backgroundTransparent = 'gatherBackgroundTransparent',
    backgroundGrey200 = 'gatherBackgroundGrey200',
    backgroundGrey400 = 'gatherBackgroundGrey400',
    backgroundGrey700 = 'gatherBackgroundGrey700',
    backgroundGreen500 = 'gatherBackgroundGreen500',
    backgroundRed = 'gatherBackgroundRed',
    backgroundGreen = 'gatherBackgroundGreen',
    backgroundOrange = 'gatherBackgroundOrange',

    buttonInvertedPrimary = 'gatherButtonInvertedPrimary',
    buttonInvertedPrimaryDisabled = 'gatherButtonInvertedPrimaryDisabled',

    marginTheme = 'gatherMarginTheme',
    padding24 = 'gatherPadding24',
    list = 'gatherList',
    textFieldWithIcon = 'gatherTextFieldWithIcon',
    inputWithError = 'gatherInputWithError',
    transparentButtonDisabled = 'gatherTransparentButtonDisabled',
    themeSelectMenu = 'gatherThemeSelectMenu',
    verticalAlignMiddle = 'gatherVerticalAlignMiddle',

    heightAuto = 'gatherHeightAuto',
    height100 = 'gatherHeight100',

    minHeight20 = 'gatherMinHeight20',
    minHeight70 = 'gatherMinHeight70',
    minHeight90 = 'gatherMinHeight90',
    minMaxHeight550 = 'gatherMinMaxHeight550',

    width100 = 'gatherWidth100',
    widthAuto = 'gatherWidthAuto',
    widthFitContent = 'gatherWidthFitContent',
    width50 = 'gatherWidth50',
    width280 = 'gatherWidth280',

    maxWidth280 = 'gatherMaxWidth280',
    maxWidth320 = 'gatherMaxWidth320',

    scrollAuto = 'gatherScrollAuto',
    overflowScroll = 'gatherOverflowScroll',
    overflowXAuto = 'gatherOverflowXAuto',
    overflowHidden = 'gatherOverflowHidden',

    displayInlineFlex = 'gatherDisplayInlineFlex',
    displayInlineBlock = 'gatherDisplayInlineBlock',
    displayBlock = 'gatherDisplayBlock',
    displayFlex = 'gatherDisplayFlex',
    displayTable = 'gatherDisplayTable',
    displayNone = 'gatherDisplayNone',
    displayContents = 'gatherDisplayContents',

    flexCentred = 'gatherFlexCentred',
    flexEnd = 'gatherFlexEnd',
    justifyContentCenter = 'gatherJustifyContentCenter',
    justifyContentEnd = 'gatherJustifyContentEnd',
    justifyContentStart = 'gatherJustifyContentStart',
    spaceBetween = 'gatherSpaceBetween',
    flexColumnCentered = 'gatherFlexColumnCentered',
    flexDirectionColumn = 'gatherFlexDirectionColumn',

    floatLeft = 'gatherFloatLeft',
    pullRight = 'gatherPullRight',

    textLeft = 'gatherTextLeft',
    textCenter = 'gatherTextCenter',
    textRight = 'gatherTextRight',

    positionRelative = 'gatherPositionRelative',
    positionAbsolute = 'gatherPositionAbsolute',

    pointerEventsNone = 'gatherPointerEventsNone',
    cursorPointer = 'gatherCursorPointer',
    cursorGrab = 'gatherCursorGrab',
    cursorGrabbing = 'gatherCursorGrabbing',
    cursorDefault = 'gatherCursorDefault',

    buttonProgress = 'gatherButtonProgress',
    buttonDisabled = 'gatherButtonDisabled',

    opacity50 = 'gatherOpacity50',

    focusOutlineNone = 'gatherFocusOutlineNone',

    b_solid_1 = 'gatherB_solid_1',
    bt_solid_1 = 'gatherBt_solid_1',
    b_solid_orange_2 = 'gatherB_solid_orange_2',

    ellipses = 'gatherEllipses',

    marginAuto = 'gatherMarginAuto',
    margin0 = 'gatherMargin0',

    marginTop0 = 'gatherMarginTop0',
    marginTop2 = 'gatherMarginTop2',
    marginTop5 = 'gatherMarginTop5',
    marginTop8 = 'gatherMarginTop8',
    marginTop10 = 'gatherMarginTop10',
    marginTop12 = 'gatherMarginTop12',
    marginTop16 = 'gatherMarginTop16',
    marginTop20 = 'gatherMarginTop20',
    marginTop24 = 'gatherMarginTop24',
    marginTop30 = 'gatherMarginTop30',
    marginTop40 = 'gatherMarginTop40',
    marginTop50 = 'gatherMarginTop50',

    marginBottom5 = 'gatherMarginBottom5',
    marginBottom8 = 'gatherMarginBottom8',
    marginBottom10 = 'gatherMarginBottom10',
    marginBottom16 = 'gatherMarginBottom16',
    marginBottom20 = 'gatherMarginBottom20',
    marginBottom24 = 'gatherMarginBottom24',
    marginBottom40 = 'gatherMarginBottom40',
    marginBottom50 = 'gatherMarginBottom50',

    marginRight5 = 'gatherMarginRight5',
    marginRight10 = 'gatherMarginRight10',
    marginRight20 = 'gatherMarginRight20',

    marginLeft5 = 'gatherMarginLeft5',
    marginLeft10 = 'gatherMarginLeft10',
    marginLeft20 = 'gatherMarginLeft20',
    marginLeft28 = 'gatherMarginLeft28',

    margin_24_0 = 'gatherMargin_24_0',

    padding0 = 'gatherPadding0',
    padding10 = 'gatherPadding10',
    padding15 = 'gatherPadding15',
    padding20 = 'gatherPadding20',

    paddingTop10 = 'gatherPaddingTop10',
    paddingTop15 = 'gatherPaddingTop15',
    paddingTop48 = 'gatherPaddingTop48',

    padding_0_12 = 'gatherPadding_0_12',
    padding_0_20 = 'gatherPadding_0_20',

    paddingLeft20 = 'gatherPaddingLeft20',

    padding_4_0 = 'gatherPadding_4_0',

    paddingBottom12 = 'gatherPaddingBottom12',

    fontSize10 = 'gatherFontSize10',
    fontSize11 = 'gatherFontSize11',
    fontSize12 = 'gatherFontSize12',
    fontSize13 = 'gatherFontSize13',
    fontSize14 = 'gatherFontSize14',
    fontSize15 = 'gatherFontSize15',
    fontSize16 = 'gatherFontSize16',
    fontSize17 = 'gatherFontSize17',
    fontSize18 = 'gatherFontSize18',
    fontSize20 = 'gatherFontSize20',
    fontSize22 = 'gatherFontSize22',
    fontSize24 = 'gatherFontSize24',
    fontSize28 = 'gatherFontSize28',
    fontSize30 = 'gatherFontSize30',
    fontSize32 = 'gatherFontSize32',
    fontSize34 = 'gatherFontSize34',
    fontSize35 = 'gatherFontSize35',
    fontSize36 = 'gatherFontSize36',
    fontSize38 = 'gatherFontSize38',
    fontSize40 = 'gatherFontSize40',
    fontSize48 = 'gatherFontSize48',
    fontSize68 = 'gatherFontSize68',
    fontSize74 = 'gatherFontSize74',
    fontSize92 = 'gatherFontSize92',
    fontSize140 = 'gatherFontSize140',

    fontWeight100 = 'gatherFontWeight100',
    fontWeight200 = 'gatherFontWeight200',
    fontWeight300 = 'gatherFontWeight300',
    fontWeight400 = 'gatherFontWeight400',
    fontWeight500 = 'gatherFontWeight500',
    fontWeight600 = 'gatherFontWeight600',
    fontWeight700 = 'gatherFontWeight700',

    fontStyleItalic = 'gatherFontStyleItalic',
    fontBold = 'gatherFontBold',
    textCapitalize = 'gatherTextCapitalize',
    textUppercase = 'gatherTextUppercase',
    textLowercase = 'gatherTextLowercase',
    textTransformNone = 'gatherTextTransformNone',
    textUnderline = 'gatherTextUnderline',
    textClickable = 'gatherTextClickable',
    textDecorationNone = 'gatherTextDecorationNone',
    strikeThrough = 'gatherStrikeThrough',
    paragraphLineHeight = 'gatherParagraphLineHeight',
    clickable = 'gatherClickable',
};

/**
 * NOTE: only put styles are required to be recomputed when theme is changed in below `useThemedGStyles`, 
 * and for the styles that don't need re-computation put them in `gStyles` 
 */
const useThemedGStyles = makeStyles((theme: Theme) => ({
    '@global': {
        /**
         * COLOR
         */
        [`.${GStyles.colorSecondary}`]: {
            color: theme.palette.secondary.main
        },
        [`.${GStyles.colorRememberSecondary}`]: {
            color: theme.palette.rememberSecondary.main,
        },
        [`.${GStyles.colorPrimary}`]: {
            color: `${theme.palette.primary.main} !important`
        },
        [`.${GStyles.colorRememberPrimary}`]: {
            color: theme.palette.rememberPrimary.main,
        },
        [`.${GStyles.colorWhite}`]: {
            color: `${theme.palette.common.white} !important`,
        },
        [`.${GStyles.colorBlack}`]: {
            color: `${theme.palette.common.black} !important`,
        },
        [`.${GStyles.colorBlack2}`]: {
            color: alpha(theme.palette.common.black, 0.87),
        },

        /**
         * FILL
         */

        [`.${GStyles.fillPrimary}`]: {
            fill: `${theme.palette.primary.main} !important`
        },
        [`.${GStyles.fillSecondary}`]: {
            fill: `${theme.palette.secondary.main} !important`
        },
        [`.${GStyles.fillWhite}`]: {
            fill: `${theme.palette.common.white} !important`
        },

        /**
         * BACKGROUND
         */
        [`.${GStyles.backgroundSecondary}`]: {
            background: theme.palette.secondary.main
        },
        [`.${GStyles.backgroundPrimary}`]: {
            background: `${theme.palette.primary.main} !important`
        },
        [`.${GStyles.backgroundWhite}`]: {
            background: theme.palette.common.white,
        },
        /**
         * RGBA BACKGROUND
         */
        [`.${GStyles.background_primary_0_2}`]: {
            background: alpha(theme.palette.primary.main, 0.20),
        },
        [`.${GStyles.background_primary_0_0_4}`]: {
            background: alpha(theme.palette.primary.main, 0.04),
        },
        [`.${GStyles.background_primary_0_0_8}`]: {
            background: alpha(theme.palette.primary.main, 0.08),
        },
        [`.${GStyles.background_primary_0_0_12}`]: {
            background: alpha(theme.palette.primary.main, 0.12),
        },
        [`.${GStyles.background_primary_0_0_10}`]: {
            background: alpha(theme.palette.primary.main, 0.10),
        },
        [`.${GStyles.background_primary_0_1_8}`]: {
            background: alpha(theme.palette.primary.main, 0.18),
        },

        [`.${GStyles.background_secondary_0_1}`]: {
            background: alpha(theme.palette.secondary.main, 0.10),
        },

        /**
         * Custom buttons
         */
        [`.${GStyles.buttonInvertedPrimary}`]: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
            [`&.${GStyles.buttonInvertedPrimaryDisabled}`]: {
                backgroundColor: 'rgba(255,255,255,0.75)',
                color: theme.palette.primary.main,
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 255,255, 0.9)'
            }
        },
        [`.${GStyles.buttonInvertedPrimaryDisabled}`]: {},

        [`.${GStyles.marginTheme}`]: {
            margin: theme.spacing(),
        },

        [`.${GStyles.padding24}`]: {
            padding: theme.spacing(3),
        },
    }
}), { name: 'ThemeRelatedGStyles' });

const useGStyles = makeStyles({
    '@global': {
        [`.${GStyles.backgroundTransparent}`]: {
            background: 'transparent',
        },
        [`.${GStyles.colorTransparent}`]: {
            color: 'transparent',
        },
        [`.${GStyles.colorWarning}`]: {
            color: WARNING_COLOR,
        },
        [`.${GStyles.list}`]: {
            flexWrap: 'nowrap',
            transform: 'translateZ(0)',
            MmsTransform: 'translateZ(0)',
            WebkitTransform: 'translateZ(0)',
            WebkitOverflowScrolling: 'touch',
            margin: 'auto',
            display: 'flex',
            padding: '0 !important',
            overflowY: 'auto',
            listStyle: 'none',
            scrollBehavior: 'smooth',
            '& li': {
                margin: 'auto',
                boxSizing: 'border-box',
                flexShrink: 0,
                display: 'flex',
                padding: 0,
                flexWrap: 'wrap',
                overflowY: 'auto',
                listStyle: 'none',
                WebkitOverflowScrolling: 'touch',
            }
        },
        [`.${GStyles.textGreen500}`]: {
            color: green[500],
        },
        [`.${GStyles.colorOrange}`]: {
            color: `${ORANGE_COLOR} !important`
        },
        [`.${GStyles.backgroundGreen500}`]: {
            background: grey[200],
        },
        [`.${GStyles.backgroundGrey400}`]: {
            background: grey[400],
        },
        [`.${GStyles.backgroundGrey700}`]: {
            background: grey[700],
        },
        [`.${GStyles.backgroundGreen500}`]: {
            background: green[500],
        },

        [`.${GStyles.backgroundRed}`]: {
            background: RED_COLOR
        },
        [`.${GStyles.colorRed}`]: {
            color: `${RED_COLOR} !important`
        },
        [`.${GStyles.colorGreen}`]: {
            color: GREEN_COLOR
        },
        [`.${GStyles.backgroundGreen}`]: {
            background: GREEN_COLOR
        },
        [`.${GStyles.backgroundOrange}`]: {
            background: ORANGE_COLOR
        },
        [`.${GStyles.textFieldWithIcon}`]: {
            width: 'calc(100% - 32px)',
        },
        [`.${GStyles.inputWithError}`]: {
            minHeight: 68,
        },
        [`.${GStyles.transparentButtonDisabled}`]: {
            color: 'rgba(0, 0, 0, 0.26)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.85)'
            }
        },
        [`.${GStyles.themeSelectMenu}`]: {
            '&:focus': {
                background: 'transparent',
            },
        },
        [`.${GStyles.verticalAlignMiddle}`]: {
            verticalAlign: 'middle',
        },

        [`.${GStyles.heightAuto}`]: {
            height: 'auto !important',
        },
        [`.${GStyles.height100}`]: {
            height: '100%',
        },

        [`.${GStyles.minHeight20}`]: {
            minHeight: 20,
        },
        [`.${GStyles.minHeight70}`]: {
            minHeight: 70,
        },
        [`.${GStyles.minHeight90}`]: {
            minHeight: 90,
        },
        [`.${GStyles.minMaxHeight550}`]: {
            minHeight: '550px !important',
            maxHeight: '550px !important',
        },

        [`.${GStyles.width100}`]: {
            width: '100%',
        },
        [`.${GStyles.widthAuto}`]: {
            width: 'auto !important',
        },
        [`.${GStyles.widthFitContent}`]: {
            width: 'fit-content'
        },
        [`.${GStyles.width50}`]: {
            width: 50
        },
        [`.${GStyles.width280}`]: {
            width: 280,
        },
        [`.${GStyles.maxWidth280}`]: {
            maxWidth: 280,
        },
        [`.${GStyles.maxWidth320}`]: {
            maxWidth: 320,
        },

        [`.${GStyles.scrollAuto}`]: {
            overflow: 'auto',
        },
        [`.${GStyles.overflowHidden}`]: {
            overflow: 'hidden',
        },
        [`.${GStyles.overflowScroll}`]: {
            overflow: 'scroll',
        },
        [`.${GStyles.overflowXAuto}`]: {
            overflowX: 'auto !important' as 'auto',
        },

        [`.${GStyles.displayInlineFlex}`]: {
            display: 'inline-flex',
        },
        [`.${GStyles.displayInlineBlock}`]: {
            display: 'inline-block',
        },
        [`.${GStyles.displayBlock}`]: {
            display: 'block !important',
        },
        [`.${GStyles.displayFlex}`]: {
            display: 'flex',
        },
        [`.${GStyles.displayTable}`]: {
            display: 'table',
        },
        [`.${GStyles.displayNone}`]: {
            display: 'none !important',
        },
        [`.${GStyles.displayContents}`]: {
            display: 'contents',
        },

        [`.${GStyles.floatLeft}`]: {
            float: 'left'
        },
        [`.${GStyles.pullRight}`]: {
            float: 'right',
        },

        [`.${GStyles.textLeft}`]: {
            textAlign: 'left',
        },
        [`.${GStyles.textCenter}`]: {
            textAlign: 'center',
        },
        [`.${GStyles.textRight}`]: {
            textAlign: 'right',
        },

        [`.${GStyles.positionRelative}`]: {
            position: 'relative !important' as 'relative',
        },
        [`.${GStyles.positionAbsolute}`]: {
            position: 'absolute',
        },

        [`.${GStyles.pointerEventsNone}`]: {
            pointerEvents: 'none !important' as 'none',
        },

        [`.${GStyles.cursorPointer}`]: {
            cursor: 'pointer',
        },
        [`.${GStyles.cursorGrab}`]: {
            cursor: 'grab',
        },
        [`.${GStyles.cursorGrabbing}`]: {
            cursor: 'grabbing',
        },
        [`.${GStyles.cursorDefault}`]: {
            cursor: 'default',
        },

        [`.${GStyles.buttonProgress}`]: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        [`.${GStyles.buttonDisabled}`]: {
            color: 'rgba(0,0,0,0.12)',
            background: 'rgba(0,0,0,0.12) !important',
            pointerEvents: 'none',
        },

        [`.${GStyles.opacity50}`]: {
            opacity: 0.5,
        },

        [`.${GStyles.focusOutlineNone}`]: {
            '&:focus': {
                outline: 'none',
            },
        },

        /**
         * BORDERS
         */
        [`.${GStyles.b_solid_1}`]: { // TODO rename
            border: '1px solid'
        },
        [`.${GStyles.bt_solid_1}`]: {
            borderTop: '1px solid'
        },
        [`.${GStyles.ellipses}`]: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        [`.${GStyles.b_solid_orange_2}`]: {
            border: '2px orange solid',
        },

        /**
         * FLEX HELPERS
         */
        [`.${GStyles.flexCentred}`]: {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [`.${GStyles.flexEnd}`]: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        [`.${GStyles.justifyContentCenter}`]: {
            justifyContent: 'center',
        },
        [`.${GStyles.justifyContentEnd}`]: {
            justifyContent: 'flex-end !important',
        },
        [`.${GStyles.justifyContentStart}`]: {
            justifyContent: 'flex-start !important',
        },
        [`.${GStyles.spaceBetween}`]: {
            justifyContent: 'space-between !important',
        },
        [`.${GStyles.flexColumnCentered}`]: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        [`.${GStyles.flexDirectionColumn}`]: {
            flexDirection: 'column'
        },

        [`.${GStyles.marginAuto}`]: {
            margin: 'auto !important',
        },
        [`.${GStyles.margin0}`]: {
            margin: 0,
        },

        [`.${GStyles.marginTop0}`]: {
            marginTop: 0,
        },
        [`.${GStyles.marginTop2}`]: {
            marginTop: 2,
        },
        [`.${GStyles.marginTop5}`]: {
            marginTop: 5
        },
        [`.${GStyles.marginTop8}`]: {
            marginTop: '8px !important'
        },
        [`.${GStyles.marginTop10}`]: {
            marginTop: 10
        },
        [`.${GStyles.marginTop12}`]: {
            marginTop: '12px !important'
        },
        [`.${GStyles.marginTop16}`]: {
            marginTop: 16
        },
        [`.${GStyles.marginTop20}`]: {
            marginTop: '20px !important',
        },
        [`.${GStyles.marginTop24}`]: {
            marginTop: 24,
        },
        [`.${GStyles.marginTop30}`]: {
            marginTop: 30,
        },
        [`.${GStyles.marginTop40}`]: {
            marginTop: 40
        },
        [`.${GStyles.marginTop50}`]: {
            marginTop: 50
        },

        [`.${GStyles.marginBottom5}`]: {
            marginBottom: 5,
        },
        [`.${GStyles.marginBottom8}`]: {
            marginBottom: 8,
        },
        [`.${GStyles.marginBottom10}`]: {
            marginBottom: 10
        },
        [`.${GStyles.marginBottom16}`]: {
            marginBottom: 16,
        },
        [`.${GStyles.marginBottom20}`]: {
            marginBottom: 20,
        },
        [`.${GStyles.marginBottom24}`]: {
            marginBottom: 24,
        },
        [`.${GStyles.marginBottom40}`]: {
            marginBottom: 40,
        },
        [`.${GStyles.marginBottom50}`]: {
            marginBottom: 50,
        },

        [`.${GStyles.marginRight5}`]: {
            marginRight: 5
        },
        [`.${GStyles.marginRight10}`]: {
            marginRight: '10px !important'
        },
        [`.${GStyles.marginRight20}`]: {
            marginRight: 20
        },

        [`.${GStyles.marginLeft5}`]: {
            marginLeft: 5
        },
        [`.${GStyles.marginLeft10}`]: {
            marginLeft: 10
        },
        [`.${GStyles.marginLeft20}`]: {
            marginLeft: 20
        },
        [`.${GStyles.marginLeft28}`]: {
            marginLeft: 28,
        },
        [`.${GStyles.margin_24_0}`]: {
            margin: '24px 0'
        },

        [`.${GStyles.padding0}`]: {
            padding: '0 !important',
        },
        [`.${GStyles.padding10}`]: {
            padding: 10
        },
        [`.${GStyles.padding15}`]: {
            padding: 15
        },
        [`.${GStyles.padding20}`]: {
            padding: 20,
        },
        [`.${GStyles.paddingTop10}`]: {
            paddingTop: 10,
        },
        [`.${GStyles.paddingTop15}`]: {
            paddingTop: 15,
        },
        [`.${GStyles.paddingTop48}`]: {
            paddingTop: 48,
        },

        [`.${GStyles.padding_0_12}`]: {
            padding: '0 12px',
        },
        [`.${GStyles.padding_0_20}`]: {
            padding: '0 20px',
        },

        [`.${GStyles.paddingLeft20}`]: {
            paddingLeft: 20,
        },

        [`.${GStyles.padding_4_0}`]: {
            padding: '4px 0',
        },

        [`.${GStyles.paddingBottom12}`]: {
            paddingBottom: 12,
        },

        [`.${GStyles.fontSize10}`]: {
            fontSize: 10,
        },
        [`.${GStyles.fontSize11}`]: {
            fontSize: 11,
        },
        [`.${GStyles.fontSize12}`]: {
            fontSize: '12px !important',
        },
        [`.${GStyles.fontSize13}`]: {
            fontSize: 13,
        },
        [`.${GStyles.fontSize14}`]: {
            fontSize: '14px !important',
        },
        [`.${GStyles.fontSize15}`]: {
            fontSize: 15,
        },
        [`.${GStyles.fontSize16}`]: {
            fontSize: 16,
        },
        [`.${GStyles.fontSize17}`]: {
            fontSize: 17,
        },
        [`.${GStyles.fontSize18}`]: {
            fontSize: '18px !important'
        },
        [`.${GStyles.fontSize20}`]: {
            fontSize: 20,
        },
        [`.${GStyles.fontSize22}`]: {
            fontSize: 22,
        },
        [`.${GStyles.fontSize24}`]: {
            fontSize: 24,
        },
        [`.${GStyles.fontSize28}`]: {
            fontSize: '28px !important',
        },
        [`.${GStyles.fontSize30}`]: {
            fontSize: 30,
        },
        [`.${GStyles.fontSize32}`]: {
            fontSize: 32,
        },
        [`.${GStyles.fontSize34}`]: {
            fontSize: 34,
        },
        [`.${GStyles.fontSize35}`]: {
            fontSize: 35,
        },
        [`.${GStyles.fontSize36}`]: {
            fontSize: 36,
        },
        [`.${GStyles.fontSize38}`]: {
            fontSize: 38,
        },
        [`.${GStyles.fontSize40}`]: {
            fontSize: 40,
        },
        [`.${GStyles.fontSize48}`]: {
            fontSize: 48,
        },
        [`.${GStyles.fontSize68}`]: {
            fontSize: 68,
        },
        [`.${GStyles.fontSize74}`]: {
            fontSize: 74,
        },
        [`.${GStyles.fontSize92}`]: {
            fontSize: 92,
        },
        [`.${GStyles.fontSize140}`]: {
            fontSize: 140,
        },

        [`.${GStyles.fontWeight100}`]: {
            fontWeight: 100,
        },
        [`.${GStyles.fontWeight200}`]: {
            fontWeight: '200 !important',
        },
        [`.${GStyles.fontWeight300}`]: {
            fontWeight: '300 !important',
        },
        [`.${GStyles.fontWeight400}`]: {
            fontWeight: 400,
        },
        [`.${GStyles.fontWeight500}`]: {
            fontWeight: '500 !important',
        },
        [`.${GStyles.fontWeight600}`]: {
            fontWeight: 600
        },
        [`.${GStyles.fontWeight700}`]: {
            fontWeight: 700,
        },

        [`.${GStyles.fontStyleItalic}`]: {
            fontStyle: 'italic',
        },
        [`.${GStyles.fontBold}`]: {
            fontWeight: 'bold',
        },
        [`.${GStyles.textCapitalize}`]: {
            textTransform: 'capitalize',
        },
        [`.${GStyles.textUppercase}`]: {
            textTransform: 'uppercase',
        },
        [`.${GStyles.textLowercase}`]: {
            textTransform: 'lowercase',
        },
        [`.${GStyles.textTransformNone}`]: {
            textTransform: 'none !important' as 'none',
        },
        [`.${GStyles.textUnderline}`]: {
            textDecoration: 'underline',
        },

        [`.${GStyles.textClickable}`]: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        [`.${GStyles.textDecorationNone}`]: {
            textDecoration: 'none',
        },
        [`.${GStyles.strikeThrough}`]: {
            textDecoration: 'line-through'
        },
        [`.${GStyles.paragraphLineHeight}`]: {
            lineHeight: 1.25
        },
        [`.${GStyles.clickable}`]: {
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline !important',
                cursor: 'pointer',
            }
        }
    }
}, { name: 'GlobalStyles' });

export { useThemedGStyles as useThemeRelatedGStyles, useGStyles };