import { Component } from 'react';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { convertHexToRGBA, FamilyRoutePage, RouteBuilder } from '../../services';

import GSwitch from '../common/inputElements/GSwitch';
import { GatherCaseUX, TaskState, WebsiteVendor } from '../../shared/types';
import { debounce } from 'lodash';
import { updateSyncState, updateDeathNotice } from '../../actions/GatherCase.action';
import Tooltip from '@mui/material/Tooltip';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import withState from '../common/utilHOC/WithState';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { AppDispatch } from '../../store';
import GPopper from '../common/GPopper';
import GLink from '../common/GLink';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
        width: 316,
        height: 'auto',
        maxHeight: 540,
        '@media (min-width: 420px)': {
            width: 368,
        },
        '@media (min-width: 500px)': {
            width: 450,
        }
    },
    disabledPaper: {
        '@media (min-width: 380px)': {
            width: '360px !important',
        },
    },
    gSwitchSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        padding: '0 12px',
        height: 40
    },
    heading: {
        fontStyle: 'italic',
        marginTop: 2,
        fontSize: 12,
        '@media (min-width: 380px)': {
            fontSize: 14
        }
    },
    itemSection: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        margin: '0 16px',
        padding: '0 12px'
    },
    itemContainer: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 300,
        margin: '6px auto'
    },
    topItems: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            display: 'flex',
            fontSize: 12,
            '@media (min-width: 380px)': {
                fontSize: 16,
            },
            '& svg': {
                verticalAlign: 'middle',
                fontSize: 16,
                '@media (min-width: 380px)': {
                    fontSize: 24
                }
            }
        }
    },
    marginTop4: {
        marginTop: 4
    },
    mainSection: {
        minHeight: 130,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    obituaryText: {
        fontStyle: 'italic',
        margin: '0 4px',
        marginTop: 12,
        fontSize: 12,
        '@media (min-width: 360px)': {
            fontSize: 14
        }
    },
    textField: {
        marginTop: 17
    },
    buttonContainer: {
        marginTop: 16,
        padding: '0 12px'
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
        borderTop: `1px solid ${theme.palette.primary.main}`,
        background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        padding: '8px 10px',
        minHeight: 64
    },
    statusBadge: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 6,
        width: 'calc(100% - 16px)',
        padding: '4px 0',
        margin: '10px auto 0',
        background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        '& p': {
            textAlign: 'center',
            fontSize: 16
        }
    },
    textFieldContainer: {
        width: '100%',
        minHeight: 130
    },
    switchLabel: {
        fontSize: 14,
        '@media (min-width: 360px)': {
            fontSize: 16
        }
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            left: '5px !important'
        },
    },
    arrow: {
        left: '-4px !important'
    }
});

type StyledProps = Props & WithGStyles<'root' | 'popoverPaper' | 'disabledPaper' | 'popper' | 'arrow'
    | 'gSwitchSection' | 'heading' | 'itemSection' | 'itemContainer' | 'topItems' | 'marginTop4' | 'mainSection'
    | 'obituaryText' | 'textField' | 'buttonContainer' | 'footerContainer' | 'statusBadge' | 'textFieldContainer'
    | 'switchLabel'>;

type Props = {
    fname: string;
    obitStatus: TaskState;
    dispatch: AppDispatch;
    anchorEle: HTMLElement | null;
    onClose: () => void;
    onUpdate?: (isAuto: boolean, deathNotice: string) => void;
    activeCase?: GatherCaseUX;
    zIndex: number;
};

interface State {
    isAutoSync: boolean;
    deathNotice: string;
    isPublishing: boolean;
}

class WebsiteSyncPopper extends Component<StyledProps, State> {

    debounceUpdateSyncState = debounce(
        () => {
            const { activeCase, dispatch } = this.props;
            if (activeCase) {
                this.getVendors().forEach(i => {
                    dispatch(updateSyncState(
                        activeCase.uuid,
                        i.vendor,
                        this.state.isAutoSync,
                        false));
                });
            }
        },
        500,
    );

    debounceDeathNotice = debounce(
        () => {
            const { activeCase, dispatch } = this.props;
            if (activeCase) {
                dispatch(updateDeathNotice(activeCase.uuid, this.state.deathNotice));
            }
        },
        1000
    );

    constructor(props: StyledProps) {
        super(props);
        const { activeCase } = props;
        if (!activeCase) {
            this.state = {
                isAutoSync: false,
                deathNotice: '',
                isPublishing: false,
            };
        } else {
            this.state = {
                isAutoSync: activeCase.sync.length > 0,
                deathNotice: activeCase.obituary_published_content || '',
                isPublishing: false,
            };
        }
    }

    getVendors = (): { vendor: WebsiteVendor; enabled: boolean }[] => {
        const { activeCase } = this.props;
        if (activeCase) {
            const features = activeCase.funeral_home.features;
            return [
                {
                    vendor: WebsiteVendor.gather,
                    enabled: features.GATHER_SYNC.enabled,
                }, {
                    vendor: WebsiteVendor.cfs,
                    enabled: features.CFS_SYNC.enabled,
                }, {
                    vendor: WebsiteVendor.funeralone,
                    enabled: features.FUNERAL_ONE_SYNC.enabled,
                }, {
                    vendor: WebsiteVendor.funeralinnovations,
                    enabled: features.FUNERAL_INNOVATIONS_SYNC.enabled,
                }
            ].filter(i => i.enabled);
        } else {
            return [];
        }
    };

    toggleIsAutoSyncSwitch = (isChecked: boolean) => {
        this.setState({ isAutoSync: isChecked }, () => this.debounceUpdateSyncState());
        if (this.props.onUpdate) {
            this.props.onUpdate(isChecked, this.state.deathNotice);
        }
    };

    updateDeathNotice = (dn: string) => {
        this.setState({ deathNotice: dn }, () => this.debounceDeathNotice());
        if (this.props.onUpdate) {
            this.props.onUpdate(this.state.isAutoSync, dn);
        }
    };

    publishNow = async () => {
        const { activeCase, onClose } = this.props;
        const { isAutoSync } = this.state;
        if (activeCase) {
            this.setState({ isPublishing: true });
            await Promise.all(this.getVendors().map(i => {
                return this.props.dispatch(updateSyncState(activeCase.uuid, i.vendor, isAutoSync, true));
            }));
            this.setState({ isPublishing: false });
            onClose();
        }
    };

    switchLabel = () => {
        const { classes } = this.props;
        const { isAutoSync } = this.state;

        return (
            <Typography align="center" color="secondary" className={classes.switchLabel}>
                AutoSync for this case is {isAutoSync ? 'ENABLED' : 'DISABLED'}
            </Typography>
        );
    };

    renderAutoSyncSwitch = () => {
        const { classes } = this.props;
        const { isAutoSync } = this.state;

        return (
            <div className={classes.gSwitchSection}>
                <GSwitch
                    label={this.switchLabel()}
                    checked={isAutoSync}
                    labelClasses={classes.colorSecondary}
                    formControlLabelClasses={classes.margin_0}
                    onChangeCallBack={e => this.toggleIsAutoSyncSwitch(e)}
                />
            </div>
        );
    };

    renderItemsSection = () => {
        const { classes } = this.props;
        const { isAutoSync } = this.state;

        return (
            <Grid item xs={12} className={classes.itemSection}>
                <Typography align="center" color="secondary" className={classes.heading}>
                    {isAutoSync ?
                        'These items will flow automatically to your websites when each is added or edited.'
                        : 'These items will only flow to your website by clicking "Push to Website".'
                    }
                </Typography>
                <Grid item xs={12} className={classes.itemContainer}>

                    <Grid item xs={12} className={classes.topItems}>
                        <Typography color={isAutoSync ? 'primary' : 'secondary'}>
                            {isAutoSync ? <SyncIcon color="primary" /> : <SyncDisabledIcon color="secondary" />}&nbsp;
                            Vitals
                        </Typography>
                        <Typography color={isAutoSync ? 'primary' : 'secondary'} className={classes.marginTop4}>
                            {isAutoSync ? <SyncIcon color="primary" /> : <SyncDisabledIcon color="secondary" />}&nbsp;
                            Public Events
                        </Typography>
                        <Typography color={isAutoSync ? 'primary' : 'secondary'} className={classes.marginTop4}>
                            {isAutoSync ? <SyncIcon color="primary" /> : <SyncDisabledIcon color="secondary" />}&nbsp;
                            Live Stream
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.topItems}>
                        <Typography color={isAutoSync ? 'primary' : 'secondary'}>
                            {isAutoSync ? <SyncIcon color="primary" /> : <SyncDisabledIcon color="secondary" />}&nbsp;
                            Profile Photo
                        </Typography>
                        <Typography color={isAutoSync ? 'primary' : 'secondary'} className={classes.marginTop4}>
                            {isAutoSync ? <SyncIcon color="primary" /> : <SyncDisabledIcon color="secondary" />}&nbsp;
                            Memorial Video
                        </Typography>
                        <Typography color={isAutoSync ? 'primary' : 'secondary'} className={classes.marginTop4}>
                            {isAutoSync ? <SyncIcon color="primary" /> : <SyncDisabledIcon color="secondary" />}&nbsp;
                            Obituary *
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        );
    };

    buttonSection = () => {
        const { classes, fname, obitStatus, activeCase } = this.props;
        const { deathNotice } = this.state;
        const tooltipTitle = activeCase ? 'Click to navigate to the obituary' : '';

        return (
            <Grid item xs={12} className={classes.buttonContainer}>
                <Divider color="primary" className={classes.width100} />
                <Grid item xs={12} className={classes.mainSection}>

                    <Typography align="center" color="secondary" className={classes.obituaryText}>
                        *{fname ? `${fname}'s` : 'The'} Obituary task must be completed before the Obituary text
                        will flow to your website.
                    </Typography>

                    <GLink
                        linkClass={classes.width100}
                        to={activeCase?.name
                            ? RouteBuilder.FamilyPage({
                                caseName: activeCase.name,
                                funeralHomeKey: activeCase.funeral_home.key,
                                page: FamilyRoutePage.OBITUARY,
                            })
                            : RouteBuilder.Disabled
                        }
                    >
                        <Tooltip title={tooltipTitle} placement="top">
                            <Grid item xs={12} className={classes.statusBadge}>
                                <Typography color="secondary">
                                    Obituary Task Status:&nbsp;
                                    <span className={classes.fontWeight700}>{obitStatus}</span>
                                </Typography>
                            </Grid>
                        </Tooltip>
                    </GLink>

                    {obitStatus === TaskState.incomplete && <div className={classes.textFieldContainer}>
                        <TextField
                            id="death-notice-text-here"
                            label="Add Temporary or Alternative Death Notice..."
                            multiline
                            value={deathNotice}
                            onChange={(e) => this.updateDeathNotice(e.target.value)}
                            rows={4}
                            variant="outlined"
                            className={classes.textField}
                            InputProps={{ classes: { notchedOutline: 'notranslate' } }}
                            classes={{
                                root: classes.width100
                            }}
                        />
                    </div>}
                </Grid>
            </Grid>
        );
    };

    popperFooter = () => {
        const { classes, activeCase } = this.props;
        const { isAutoSync, isPublishing } = this.state;

        const showSyncMessage = isAutoSync;
        return (
            <Grid item xs={12} className={classes.footerContainer}>
                {showSyncMessage &&
                    <Typography align="center" color="primary" className={classes.fontSize16}>
                        Edits will automatically display on your website within 5 minutes
                    </Typography>}
                {!showSyncMessage && <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.publishNow()}
                    disabled={activeCase === undefined || isPublishing}
                >
                    {isPublishing ? 'Publishing...' : 'Push to Website'}
                </Button>}
            </Grid>
        );
    };

    renderContent = () => {
        return (
            <>
                {this.renderAutoSyncSwitch()}
                {this.renderItemsSection()}
                {this.buttonSection()}
                {this.popperFooter()}
            </>
        );
    };

    render() {
        const { classes, anchorEle, onClose, zIndex } = this.props;

        return (
            <GPopper
                anchorEle={anchorEle}
                paperClass={classNames(
                    classes.popoverPaper,
                    classes.disabledPaper
                )}
                zIndex={zIndex}
                className={classes.popper}
                arrowClass={classes.arrow}
                closePopper={() => onClose()}
            >
                {this.renderContent()}
            </GPopper >
        );
    }
}

export default withState()(withGStyles(styles)(WebsiteSyncPopper));
