import generateUuid from 'uuid';
import { getFromAPI, putToAPI, postToAPI, deleteFromAPI, patchAPI } from '..';

import { registerAppError } from '../errors';

import {
    ProductContractUX,
    ProductContractUXSummary,
    ProductContractRecord,
    ProductContractViewerUX,
    ProductCustomContractItemRequest,
    ProductContractItemUpdateRequest,
    ProductContractItemCreateRequest,
    ProductContractRequest,
    LedgerNonExtractRevision,
} from '../../shared/types';
import { setSnackbarSuccess } from '../AppSnackbar.action';
import { getExtractReport, getTransactionsForCase } from '../Finance.action';
import { AppDispatch } from '../../store';
import { log } from '../../logger';

const contractSnackbarSuccess = (msg: string) => setSnackbarSuccess(msg, undefined, 2000);

/// / ------>  Action Creators  <------ \\\\

// ADD_PACKAGE_TO_CONTRACT
export const ADD_PACKAGE_TO_CONTRACT = 'ADD_PACKAGE_TO_CONTRACT';
export type ADD_PACKAGE_TO_CONTRACT = typeof ADD_PACKAGE_TO_CONTRACT;

interface AddPackageToContract {
    type: ADD_PACKAGE_TO_CONTRACT;
    packageId: number;
}

function addPackageToContract(packageId: number): AddPackageToContract {
    return {
        type: ADD_PACKAGE_TO_CONTRACT,
        packageId,
    };
}

// REMOVE_PACKAGE_FROM_CONTRACT
export const REMOVE_PACKAGE_FROM_CONTRACT = 'REMOVE_PACKAGE_FROM_CONTRACT';
export type REMOVE_PACKAGE_FROM_CONTRACT = typeof REMOVE_PACKAGE_FROM_CONTRACT;

interface RemovePackageFromContract {
    type: REMOVE_PACKAGE_FROM_CONTRACT;
    packageId: number;
}

function removePackageFromContract(packageId: number): RemovePackageFromContract {
    return {
        type: REMOVE_PACKAGE_FROM_CONTRACT,
        packageId,
    };
}

// SET_PRODUCT_CONTRACTS
export const SET_PRODUCT_CONTRACTS = 'SET_PRODUCT_CONTRACTS';
export type SET_PRODUCT_CONTRACTS = typeof SET_PRODUCT_CONTRACTS;

interface SetProductContracts {
    type: SET_PRODUCT_CONTRACTS;
    contracts: ProductContractRecord[];
}

function setContracts(contracts: ProductContractRecord[]): SetProductContracts {
    return {
        type: SET_PRODUCT_CONTRACTS,
        contracts,
    };
}

// SET_PRODUCT_ACTIVE_CONTRACT
export const SET_PRODUCT_ACTIVE_CONTRACT = 'SET_PRODUCT_ACTIVE_CONTRACT';
export type SET_PRODUCT_ACTIVE_CONTRACT = typeof SET_PRODUCT_ACTIVE_CONTRACT;

interface SetProductActiveContract {
    type: SET_PRODUCT_ACTIVE_CONTRACT;
    contract: ProductContractUX | null;
}

export function setActiveContract(contract: ProductContractUX | null): SetProductActiveContract {
    return {
        type: SET_PRODUCT_ACTIVE_CONTRACT,
        contract,
    };
}

// SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY
export const SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY = 'SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY';

interface SetProductActiveContractSummary {
    type: typeof SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY;
    contractSummary: ProductContractUXSummary | null;
}

export function setActiveContractSummary(
    contractSummary: ProductContractUXSummary | null,
): SetProductActiveContractSummary {
    return {
        type: SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY,
        contractSummary,
    };
}

// UPDATE_PRODUCT_ACTIVE_CONTRACT
export const UPDATE_PRODUCT_ACTIVE_CONTRACT = 'UPDATE_PRODUCT_ACTIVE_CONTRACT';

interface UpdateProductActiveContract {
    type: typeof UPDATE_PRODUCT_ACTIVE_CONTRACT;
    contract: ProductContractRequest;
}

function updateActiveContract(contract: ProductContractRequest): UpdateProductActiveContract {
    return {
        type: UPDATE_PRODUCT_ACTIVE_CONTRACT,
        contract,
    };
}

// SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE
export const SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE = 'SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE';

interface SetProductActiveContractFrozenState {
    type: typeof SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE;
    isFrozen: boolean;
}

function setActiveContractFrozenState(
    isFrozen: boolean,
): SetProductActiveContractFrozenState {
    return {
        type: SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE,
        isFrozen,
    };
}

// SET_PRODUCT_CONTRACT_LOADING
export const SET_PRODUCT_CONTRACT_LOADING = 'SET_PRODUCT_CONTRACT_LOADING';
export type SET_PRODUCT_CONTRACT_LOADING = typeof SET_PRODUCT_CONTRACT_LOADING;

interface SetProductContractLoading {
    type: SET_PRODUCT_CONTRACT_LOADING;
    isLoading: boolean;
}

function setContractLoading(isLoading: boolean): SetProductContractLoading {
    return {
        type: SET_PRODUCT_CONTRACT_LOADING,
        isLoading,
    };
}

// ADD_PRODUCT_CONTRACT_ITEM
export const ADD_PRODUCT_CONTRACT_ITEM = 'ADD_PRODUCT_CONTRACT_ITEM';
export type ADD_PRODUCT_CONTRACT_ITEM = typeof ADD_PRODUCT_CONTRACT_ITEM;

interface AddProductContractItem {
    type: ADD_PRODUCT_CONTRACT_ITEM;
    contractId: number;
    itemId: string;
    item: ProductContractItemCreateRequest;
}

function addContractItem(
    contractId: number,
    itemId: string,
    item: ProductContractItemCreateRequest,
): AddProductContractItem {
    return {
        type: ADD_PRODUCT_CONTRACT_ITEM,
        contractId,
        itemId,
        item,
    };
}

// ADD_PRODUCT_CUSTOM_CONTRACT_ITEM
export const ADD_PRODUCT_CUSTOM_CONTRACT_ITEM = 'ADD_PRODUCT_CUSTOM_CONTRACT_ITEM';
export type ADD_PRODUCT_CUSTOM_CONTRACT_ITEM = typeof ADD_PRODUCT_CUSTOM_CONTRACT_ITEM;

interface AddProductCustomContractItem {
    type: ADD_PRODUCT_CUSTOM_CONTRACT_ITEM;
    contractId: number;
    itemId: string;
    customItem: ProductCustomContractItemRequest;
}

function addCustomContractItem(
    contractId: number,
    itemId: string,
    customItem: ProductCustomContractItemRequest,
): AddProductCustomContractItem {
    return {
        type: ADD_PRODUCT_CUSTOM_CONTRACT_ITEM,
        contractId,
        itemId,
        customItem,
    };
}

// ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM
export const ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM = 'ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM';
export type ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM = typeof ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM;

interface AddProductContractDiscountItem {
    type: ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM;
    contractId: number;
    itemId: string;
    amount: number;
    itemName: string;
}

function addContractDiscountItem(
    contractId: number,
    itemId: string,
    amount: number,
    itemName: string,
): AddProductContractDiscountItem {
    return {
        type: ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM,
        contractId,
        itemId,
        amount,
        itemName,
    };
}

// UPDATE_PRODUCT_CONTRACT_ITEM
export const UPDATE_PRODUCT_CONTRACT_ITEM = 'UPDATE_PRODUCT_CONTRACT_ITEM';
export type UPDATE_PRODUCT_CONTRACT_ITEM = typeof UPDATE_PRODUCT_CONTRACT_ITEM;

interface UpdateProductContractItem {
    type: UPDATE_PRODUCT_CONTRACT_ITEM;
    contractId: number;
    itemId: string;
    item: ProductContractItemUpdateRequest;
}

function updateContractItem(
    contractId: number,
    itemId: string,
    item: ProductContractItemUpdateRequest,
): UpdateProductContractItem {
    return {
        type: UPDATE_PRODUCT_CONTRACT_ITEM,
        contractId,
        itemId,
        item,
    };
}

// REMOVE_PRODUCT_CONTRACT_ITEM
export const REMOVE_PRODUCT_CONTRACT_ITEM = 'REMOVE_PRODUCT_CONTRACT_ITEM';
export type REMOVE_PRODUCT_CONTRACT_ITEM = typeof REMOVE_PRODUCT_CONTRACT_ITEM;

interface RemoveProductContractItem {
    type: REMOVE_PRODUCT_CONTRACT_ITEM;
    contractId: number;
    itemId: string;
}

function removeContractItem(contractId: number, itemId: string): RemoveProductContractItem {
    return {
        type: REMOVE_PRODUCT_CONTRACT_ITEM,
        contractId,
        itemId,
    };
}

// SET_PRODUCT_CONTRACT_VIEWERS
export const SET_PRODUCT_CONTRACT_VIEWERS = 'SET_PRODUCT_CONTRACT_VIEWERS';
export type SET_PRODUCT_CONTRACT_VIEWERS = typeof SET_PRODUCT_CONTRACT_VIEWERS;

interface SetProductContractViewers {
    type: SET_PRODUCT_CONTRACT_VIEWERS;
    viewers: ProductContractViewerUX[];
}

function setContractViewers(viewers: ProductContractViewerUX[]): SetProductContractViewers {
    return {
        type: SET_PRODUCT_CONTRACT_VIEWERS,
        viewers,
    };
}

// UPDATE_CONTRACT_DISCLAIMER_REASON
export const UPDATE_CONTRACT_DISCLAIMER_REASON = 'UPDATE_CONTRACT_DISCLAIMER_REASON';
export type UPDATE_CONTRACT_DISCLAIMER_REASON = typeof UPDATE_CONTRACT_DISCLAIMER_REASON;

interface UpdateContractDisclaimerReason {
    type: UPDATE_CONTRACT_DISCLAIMER_REASON;
    disclaimerId: number;
    reason: string | null;
}

/// / ------>  API Interface  <------ \\\\

function updateContractDisclaimerReason(disclaimerId: number, reason: string | null): UpdateContractDisclaimerReason {
    return {
        type: UPDATE_CONTRACT_DISCLAIMER_REASON,
        disclaimerId,
        reason,
    };
}

export function addProductPackageToContract(packageId: number, contractId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        dispatch(addPackageToContract(packageId));
        const contract = await postToAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/package/${packageId}`, {}, dispatch
        );
        if (contract) {
            dispatch(setActiveContract(contract));
            dispatch(contractSnackbarSuccess('Package added to statement'));
            return contract;
        }
        dispatch(registerAppError('Unable to add package.'));
        return null;
    };
}

export function removeProductPackageFromContract(packageId: number, contractId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        dispatch(removePackageFromContract(packageId));
        const contract = await deleteFromAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/package/${packageId}`, dispatch
        );
        if (contract) {
            dispatch(setActiveContract(contract));
            dispatch(contractSnackbarSuccess('Package removed from statement'));
            return contract;
        }
        dispatch(registerAppError('Unable to remove package.'));
        return null;
    };
}

// ------> Product Contract <------ \\

// ** Reserved for future use **
export function loadProductContractsForCase(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractRecord[] | null> => {
        const contracts = await getFromAPI<ProductContractRecord[]>(`api/case/${caseUuid}/product/contract/`, dispatch);
        if (contracts) {
            dispatch(setContracts(contracts));
            return contracts;
        }
        dispatch(registerAppError('Unable to load statements.'));
        return null;
    };
}

export function loadProductContractForCase(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        // Currently this fetches or creates a contract for the case if one does not exist
        dispatch(setContractLoading(true));
        const contract = await getFromAPI<ProductContractUX | null>(
            `api/case/${caseUuid}/product/contract/`, dispatch
        );
        dispatch(setContractLoading(false));
        dispatch(setActiveContract(contract));
        if (!contract) {
            dispatch(registerAppError('Unable to load statement.'));
        }
        return contract;
    };
}

export function loadProductContractSummaryForCase(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractUXSummary | null> => {
        // Currently this fetches or creates a contract for the case if one does not exist
        dispatch(setContractLoading(true));
        const contractSummary = await getFromAPI<ProductContractUXSummary | null>(
            `api/case/${caseUuid}/product/contract_summary/`, dispatch
        );
        dispatch(setContractLoading(false));
        dispatch(setActiveContractSummary(contractSummary));
        return contractSummary;
    };
}

export function loadProductContract(contractId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        const contract = await getFromAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}`, dispatch,
        );
        if (contract) {
            dispatch(setActiveContract(contract));
            return contract;
        }
        dispatch(registerAppError('Unable to load statement.'));
        return null;
    };
}

// For future use with multiple contracts per case
export function createProductContract(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        const resource = `api/case/${caseUuid}/product/contract/`;
        const createdContract = await postToAPI<ProductContractUX>(resource, {}, dispatch);
        if (createdContract) {
            dispatch(setActiveContract(createdContract));
            return createdContract;
        }
        dispatch(registerAppError('Unable to create statement.'));
        return null;
    };
}

export function updateProductContract(caseUuid: string, contractId: number, contract: ProductContractRequest) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        try {
            ProductContractRequest.fromRequest(contract);
        } catch (ex) {
            log.warn('Failed to validate ProductContractRequest:', { contract, ex });
            return null;
        }
        dispatch(updateActiveContract(contract));
        const updatedContract = await patchAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}`, { contract }, dispatch
        );
        if (updatedContract) {
            dispatch(setActiveContract(updatedContract));
            if (contract.tax_rate_id !== undefined || contract.taxation_method !== undefined) {
                dispatch(contractSnackbarSuccess('Tax information saved'));
            }
            return updatedContract;
        }
        dispatch(registerAppError('Unable to create statement.'));
        return null;
    };
}

export function freezeProductContractFromExtractReport(
    contractId: number,
    caseUuid: string,
    caseFName: string,
    funeralHomeId: number,
    isBatch: boolean = false,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        await dispatch(freezeProductContract(contractId, caseUuid, caseFName));
        dispatch(getExtractReport(funeralHomeId));
    };
}

export function freezeProductContract(
    contractId: number,
    caseUuid: string,
    caseFName: string,
) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        dispatch(setActiveContractFrozenState(true));

        const updatedContract = await postToAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/freeze/`, {}, dispatch
        );
        if (updatedContract) {
            dispatch(setActiveContract(updatedContract));
            dispatch(contractSnackbarSuccess(`${caseFName}'s statement is now frozen`));
            return updatedContract;
        }
        dispatch(setActiveContractFrozenState(false));
        dispatch(registerAppError('Unable to freeze statement.'));
        return null;

    };
};


export function freezeMultipleProductContracts(
    funeralHomeId: number,
    contracts: LedgerNonExtractRevision[],
    batchSize: number,
) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX[] | null> => {
        const resource = `funeralhome/${funeralHomeId}/contract/freeze/`;
        const updatedContracts = await postToAPI<ProductContractUX[]>(resource, { contracts, batchSize }, dispatch);
        if (updatedContracts) {
            return updatedContracts;
        }
        dispatch(registerAppError('Unable to freeze statements.'));
        return null;
    };
};

// ------> Product Contract Item <------ \\
function createContractItemRequest(
    caseUuid: string,
    contractId: number,
    itemId: string,
    body: {
        productItem?: ProductContractItemCreateRequest;
        customItem?: ProductCustomContractItemRequest;
        contractDiscount?: { itemName?: string; amount: number };
    },
) {
    return async (dispatch: AppDispatch) => {
        const contract = await putToAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/item/${itemId}/`, body, dispatch
        );
        dispatch(getTransactionsForCase(caseUuid));
        if (contract) {
            dispatch(setActiveContract(contract));
            dispatch(contractSnackbarSuccess('Added to statement'));
        } else {
            dispatch(registerAppError('Unable to add item to statement.'));
        }
    };
}

export function createProductContractItem(params: {
    caseUuid: string;
    contractId: number;
    item: ProductContractItemCreateRequest;
}) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        const { caseUuid, contractId, item } = params;
        try {
            ProductContractItemCreateRequest.fromRequest(item);
        } catch (ex) {
            log.warn('Failed to validate ProductContractItemRequest:', { item, ex });
            return null;
        }
        // ContractItem ID is generated in UI
        const itemId = generateUuid();
        dispatch(addContractItem(contractId, itemId, item));
        dispatch(createContractItemRequest(caseUuid, contractId, itemId, { productItem: item }));
        return itemId;
    };
}

export function createProductCustomContractItem(
    caseUuid: string,
    contractId: number,
    customItem: ProductCustomContractItemRequest,
) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        try {
            ProductCustomContractItemRequest.fromRequest(customItem);
        } catch (ex) {
            log.warn('Failed to validate ProductContractItemRequest:', { customItem, ex });
            return null;
        }
        // ContractItem ID is generated in UI
        const itemId = generateUuid();
        dispatch(addCustomContractItem(contractId, itemId, customItem));
        dispatch(createContractItemRequest(caseUuid, contractId, itemId, { customItem }));
        return itemId;
    };
}

export function createProductContractDiscountItem(
    caseUuid: string,
    contractId: number,
    amount: number,
    itemName?: string,
) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        // ContractItem ID is generated in UI
        const itemId = generateUuid();
        dispatch(addContractDiscountItem(contractId, itemId, amount, itemName || ''));
        dispatch(createContractItemRequest(caseUuid, contractId, itemId, {
            contractDiscount: { itemName, amount }
        }));
        return itemId;
    };
}

export function updateProductContractItem(
    contractId: number,
    itemId: string,
    item: ProductContractItemUpdateRequest,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        try {
            ProductContractItemUpdateRequest.fromRequest(item);
        } catch (ex) {
            log.warn('Failed to validate ProductContractItemUpdateRequest:', { item, ex });
            return null;
        }
        dispatch(updateContractItem(contractId, itemId, item));
        const contract = await patchAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/item/${itemId}`, { item }, dispatch
        );
        dispatch(getTransactionsForCase(caseUuid));
        if (contract) {
            dispatch(setActiveContract(contract));
            if (item.price_adjustment !== undefined) {
                const message = item.price_adjustment > 0 ? 'Premium was added' :
                    item.price_adjustment === 0 ? 'Adjustment was removed' : 'Discount was added';
                dispatch(contractSnackbarSuccess(message));
            } else if (item.display_name !== undefined) {
                if (item.display_name !== null) {
                    dispatch(contractSnackbarSuccess('Item renamed'));
                } else {
                    dispatch(contractSnackbarSuccess('Item name reset'));
                }
            } else if (item.note !== undefined) {
                if (item.note !== null) {
                    dispatch(contractSnackbarSuccess('Note saved'));
                } else {
                    dispatch(contractSnackbarSuccess('Note deleted'));
                }
            }
            return contract;
        }
        dispatch(registerAppError('Unable to update statement.'));
        return null;
    };
}

export function deleteProductContractItem(contractId: number, itemId: string, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        dispatch(removeContractItem(contractId, itemId));
        const contract = await deleteFromAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/item/${itemId}`, dispatch
        );
        dispatch(getTransactionsForCase(caseUuid));
        if (contract) {
            dispatch(setActiveContract(contract));
            dispatch(contractSnackbarSuccess('Removed from statement'));
            return true;
        }
        dispatch(registerAppError('Unable to remove item from statement.'));
        return false;
    };
}

export function updateProductContractViewers(viewerUserIds: number[], contractId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ProductContractViewerUX[] | null> => {
        const contract = await putToAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contractId}/viewers/`, { viewers: viewerUserIds }, dispatch
        );
        if (contract) {
            dispatch(setActiveContract(contract));
            dispatch(contractSnackbarSuccess('Updated viewers'));
            dispatch(setContractViewers(contract.viewers));
            return contract.viewers;
        }
        dispatch(registerAppError('Unable to update viewers.'));
        return null;
    };
}

// ------> Contract Disclaimer Reasons <------ \\

export function saveContractDisclaimerReason(
    contract: ProductContractUX,
    caseUuid: string,
    disclaimerId: number,
    reason: string,
) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {
        dispatch(updateContractDisclaimerReason(disclaimerId, reason));
        const resource = `api/case/${caseUuid}/product/contract/${contract.id}/disclaimer/${disclaimerId}/reason`;
        const updatedContract = await putToAPI<ProductContractUX>(resource, { reason }, dispatch);
        if (updatedContract) {
            dispatch(setActiveContract(updatedContract));
            return updatedContract;
        }
        dispatch(registerAppError('Unable to save statement disclaimer reason.'));
        return null;
    };
}

export function deleteContractDisclaimerReason(
    contract: ProductContractUX,
    caseUuid: string,
    disclaimerId: number,
) {
    return async (dispatch: AppDispatch): Promise<ProductContractUX | null> => {

        dispatch(updateContractDisclaimerReason(disclaimerId, null));
        const updatedContract = await deleteFromAPI<ProductContractUX>(
            `api/case/${caseUuid}/product/contract/${contract.id}/disclaimer/${disclaimerId}/reason`, dispatch
        );
        if (updatedContract) {
            dispatch(setActiveContract(updatedContract));
            return updatedContract;
        }
        dispatch(registerAppError('Unable to delete statement disclaimer reason.'));
        return null;
    };
}

export type ContractAction =
    | AddPackageToContract
    | RemovePackageFromContract
    | SetProductContracts
    | SetProductActiveContract
    | UpdateProductActiveContract
    | SetProductActiveContractSummary
    | SetProductActiveContractFrozenState
    | SetProductContractLoading
    | AddProductContractItem
    | AddProductCustomContractItem
    | AddProductContractDiscountItem
    | UpdateProductContractItem
    | RemoveProductContractItem
    | SetProductContractViewers
    | UpdateContractDisclaimerReason
    ;
