import * as React from 'react';
import classNames from 'classnames';
import { debounce, throttle } from 'lodash';

import { Theme } from '@mui/material/styles';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import LocalFlorist from '@mui/icons-material/LocalFlorist';
import TreeIcon from '@mui/icons-material/Park';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import {
    FeatureKey,
    GatherCasePublic,
    MemoryPrompt,
    MemoryUX,
    PromptTranslationFunctions,
    RememberStoreCTA,
    ThemeUX,
    UserProfile
} from '../../../../../shared/types';
import { ShareOptionType } from '../../widgets/ShareLinkSection';
import { WhenEnabled } from '../../../../common/AppFeature';
import MemoryContribution from './MemoryContribution';

import ScrollIndicator from '../../../flowersAndCards/ScrollIndicator';
import ScrollSnap, { ScrollSnapAlign, ScrollSnapType } from '../../../../common/ScrollSnap';
import ScrollControls from '../../../../common/ScrollControls';
import { PostMemoryParams } from '../Memories';
import { CANDLE_LIT, CANDLE_UNLIT } from '../../../../../constants';
import { ORANGE_COLOR } from '../../../../../constants/colorVariables';
import { isTouchDevice, RememberStoreType } from '../../../../../services';
import { RouteBuilder } from "../../../../../services";

const BREAK_POINT_768 = 768;
const ITEM_MARGIN = 36;

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        margin: '0 auto',
        overflowX: 'auto',
        '& $scrollIndicator': {
            zIndex: 4,
            position: 'absolute',
            top: 8,
            right: 20,
            margin: 0,
            transform: 'translateY(0)',
            pointerEvents: 'none',
            '@media (min-width: 768px)': {
                right: '5vw',
            },
            '& svg, p': {
                color: theme.palette.common.white,
            },
            '& p': {
                textTransform: 'uppercase',
                padding: '0 0 0 4px',
                fontWeight: 500,
            },
            '& svg': {
                fontSize: 24,
                marginBottom: 2,
            },
            '& $inner': {
                background: ORANGE_COLOR,
                padding: '0 12px 0 8px',
                borderRadius: 6,
                filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.4))',
                boxShadow: 'none',
                animation: '1.5s keyframe-scroll-horizontal infinite',
            },
            '& $arrow': {
                borderLeftColor: ORANGE_COLOR,
                filter: 'none',
            },
        }
    },
    greatVibesFont: {
        textTransform: 'capitalize',
        fontFamily: '\'Great Vibes\', cursive !important',
        fontSize: 40,
        margin: '0 6px',
    },
    innerContainer: {
        display: 'inline-flex',
        width: '100%',
        overflowX: 'auto',
        padding: '28px 0 6px',
        scrollBehavior: 'smooth',
        boxSizing: 'border-box',
        '&$minimalViewContainer': {
            maxWidth: 1220,
            flexWrap: 'wrap',
            justifyContent: 'center',
            width: `calc(100% + ${ITEM_MARGIN}px)`,
            marginLeft: -ITEM_MARGIN
        },
        [`@media (min-width: ${BREAK_POINT_768}px)`]: {
            scrollSnapType: 'unset',
        },
    },
    item: {
        marginLeft: ITEM_MARGIN,
        transform: 'translateZ(0)',
        WebkitTransform: 'translateZ(0)',
        '&$minimalViewItem': {
            marginBottom: 48
        },
        [`@media (min-width: ${BREAK_POINT_768}px)`]: {
            scrollSnapAlign: 'unset',
        },
        '&:last-of-type:not($minimalViewItem)': {
            marginRight: ITEM_MARGIN,
        },
    },
    navigationContainer: {
        position: 'absolute !important' as 'absolute',
    },
    disabledScrollSnap: {
        scrollSnapType: 'unset !important' as 'unset',
    },
    candle: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    candleLit: {
        backgroundImage: `url(${CANDLE_LIT})`,
    },
    candleUnlit: {
        backgroundImage: `url(${CANDLE_UNLIT})`,
    },
    marginBottom4: {
        marginBottom: 4,
    },
    scrollIndicator: {},
    minimalViewContainer: {},
    minimalViewItem: {},
    arrow: {},
    inner: {}
});

type StyledProps = WithStyles<
    'root' | 'greatVibesFont' | 'innerContainer' | 'item' | 'scrollIndicator' | 'arrow'
    | 'navigationContainer' | 'disabledScrollSnap' | 'marginBottom4' | 'inner' | 'candle'
    | 'candleLit' | 'candleUnlit' | 'minimalViewContainer' | 'minimalViewItem'
>;

type Props = {
    zIndex: number;
    publicCase: GatherCasePublic;
    isUserPhotoSaving: boolean;
    userData: UserProfile | null;
    memoryList: MemoryUX[];
    activeTheme: ThemeUX | null;
    minimalView?: boolean;
    gaClassName?: string;
    postMemory: (params: PostMemoryParams) => Promise<void>;
    openSocialIconPopper: (
        event: React.MouseEvent<HTMLElement>,
        shareOptionType: ShareOptionType,
        timeout?: number
    ) => void;
    closeSocialIconPopper: () => void;
    onShareOptionsDialogOpen: () => void;
    onCandleMemoryDialogOpen: () => void;
    onFlowerSelect?: () => void;
    onAnswerChange?: (prompt: MemoryPrompt, answer: string) => void;
};

interface State {
    isScrollIndicatorVisible: boolean;
}

class MemoryContributionList extends React.Component<Props & StyledProps, State> {
    state: State = {
        isScrollIndicatorVisible: true,
    };

    private debouncedResizeHandler = debounce(() => this._setMarginforLastElement(), 500);
    private throttledScrollListener = throttle(() => this._setScrollIndicatorVisibility(), 200);
    private containerRef: HTMLDivElement | null = null;

    componentDidMount() {
        window.addEventListener('resize', this.debouncedResizeHandler, { passive: true });

        this._setMarginforLastElement();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
        if (this.containerRef) {
            this.containerRef.removeEventListener('scroll', this.throttledScrollListener);
        }

        this.debouncedResizeHandler.cancel();
        this.throttledScrollListener.cancel();
    }

    render() {
        const {
            memoryList,
            userData,
            publicCase,
            isUserPhotoSaving,
            classes,
            activeTheme,
            minimalView,
            onCandleMemoryDialogOpen,
            openSocialIconPopper,
            closeSocialIconPopper,
            postMemory,
            onFlowerSelect,
            onAnswerChange,
            onShareOptionsDialogOpen
        } = this.props;
        const {
            isScrollIndicatorVisible,
        } = this.state;

        const hasCandleMemory = memoryList.some(m => {
            if (m.author !== null && userData !== null) {
                return m.author.user_id === userData.user_id && m.prompt === MemoryPrompt.Candle;
            }
            return false;
        });
        const candleIsLit = hasCandleMemory;

        const candleStyles = classNames(
            classes.candle,
            candleIsLit ? classes.candleLit : classes.candleUnlit
        );

        const thanksScreenMessage = (minimalView
            ? 'Continue to see additional questions. '
            : 'Swipe over to see additional questions. ') + 'Sometimes the best gift is a kind memory shared.';
        const memoryPromptCards: JSX.Element[] = [];

        let lastCardInserted: string | null = null;
        const memoryPromptKeys = Object.keys(MemoryPrompt);
        const flowerStoreLink = RouteBuilder.RememberStore({
            caseName: publicCase.name,
            storeType: RememberStoreType.flowers,
            cta: RememberStoreCTA.Memory,
        });
        const treeStoreLink = RouteBuilder.RememberStore({
            caseName: publicCase.name,
            storeType: RememberStoreType.trees,
            cta: RememberStoreCTA.Memory,
        });
        memoryPromptKeys.forEach((prompt, index) => {
            if (prompt !== MemoryPrompt.Candle) {
                const memory = memoryList.find(m =>
                    m.prompt === prompt && m.user_id === (userData && userData.user_id)
                );

                if (index === 5) { // place candle prompt 5th
                    memoryPromptCards.push(
                        <WhenEnabled feature={FeatureKey.MEMORY_CANDLE_LIGHTING} key="LightACandle">
                            <MemoryContribution
                                question={<></>}
                                prompt={MemoryPrompt[prompt]}
                                avatarPosition="top"
                                linkProps={RouteBuilder.Disabled}
                                type="candle"
                                userData={userData}
                                caseDisplayFname={publicCase.display_fname}
                                isUserPhotoLoading={false}
                                onClick={onCandleMemoryDialogOpen}
                                candleData={{
                                    styles: candleStyles,
                                    isLit: candleIsLit
                                }}
                                openShareOptionsDialog={onShareOptionsDialogOpen}
                                intercomTargetProp={`RememberPage-Memories-CandleMemoryPrompt`}
                            />
                        </WhenEnabled>
                    );
                }

                if (index % 8 === 0 && index !== 0
                    && index < memoryPromptKeys.length - 2
                    && !minimalView) {
                    if (lastCardInserted !== 'trees' && publicCase.options.plant_trees) {
                            memoryPromptCards.push(
                                <WhenEnabled
                                    feature={FeatureKey.PLANT_TREES}
                                    key={`tree-${index}`}
                                >
                                    <MemoryContribution
                                        question={
                                            <>
                                                Leave a
                                                <span
                                                    className={classes.greatVibesFont}>
                                                    Living, Lasting Tribute
                                                </span>
                                                with a memorial tree.
                                            </>
                                        }
                                        prompt="flowers"
                                        avatarPosition="top"
                                        type="button"
                                        buttonText={`plant a tree for ${publicCase.display_fname}`}
                                        buttonIcon={<TreeIcon />}
                                        linkProps={treeStoreLink}
                                        userData={userData}
                                        caseDisplayFname={publicCase.display_fname}
                                        isUserPhotoLoading={isUserPhotoSaving}
                                        onClick={onFlowerSelect}
                                        openShareOptionsDialog={onShareOptionsDialogOpen}
                                        intercomTargetProp={`RememberPage-Memories-FlowersMemoryPrompt`}
                                        gaClassName="trees-memory-card"
                                    />
                                </WhenEnabled>
                            );
                        lastCardInserted = 'trees';
                    } else if (lastCardInserted !== 'photos' && !minimalView) {
                        memoryPromptCards.push(
                            <MemoryContribution
                                key={`photos-${index}`}
                                question={
                                    <>
                                        A
                                        <span className={classes.greatVibesFont}>
                                            Photo
                                        </span>
                                        can be worth a thousand
                                        <span className={classes.greatVibesFont}>
                                            Words
                                        </span>
                                    </>
                                }
                                thanksScreenMessage={`Family & Friends will love seeing your 
                                photos of ${publicCase.display_fname}.`}
                                linkProps={RouteBuilder.Disabled}
                                prompt="photo"
                                avatarPosition="top"
                                type="button"
                                buttonText="upload your favorites"
                                buttonIcon={<AddAPhotoIcon className={classes.marginBottom4} />}
                                userData={userData}
                                isUserPhotoLoading={isUserPhotoSaving}
                                onSave={postMemory}
                                caseDisplayFname={publicCase.display_fname}
                                openShareOptionsDialog={onShareOptionsDialogOpen}
                                intercomTargetProp={`RememberPage-Memories-PhotosMemoryPrompt`}
                            />
                        );
                        lastCardInserted = 'photo';
                    }
                }

                if (prompt !== MemoryPrompt.Default) {
                    memoryPromptCards.push(
                        <MemoryContribution
                            index={index}
                            key={prompt}
                            question={PromptTranslationFunctions[prompt](publicCase.display_fname)}
                            prompt={MemoryPrompt[prompt]}
                            answer={memory && memory.memory_text || ''}
                            textfieldLabel="Share your memory here..."
                            avatarPosition="bottom"
                            type="textfield"
                            linkProps={RouteBuilder.Disabled}
                            userData={userData}
                            isUserPhotoLoading={isUserPhotoSaving}
                            caseDisplayFname={publicCase.display_fname}
                            onSave={postMemory}
                            openSocialIconPopper={openSocialIconPopper}
                            closeSocialIconPopper={closeSocialIconPopper}
                            openShareOptionsDialog={onShareOptionsDialogOpen}
                            onAnswerChange={onAnswerChange}
                            intercomTargetProp={`RememberPage-Memories-QuestionMemoryPrompt`}
                        />
                    );
                }
            }
        });

        if (!minimalView && publicCase.options.sell_flowers) {
            memoryPromptCards.push(
                <WhenEnabled feature={FeatureKey.REMEMBER_FLOWER_LINK} key="EndFlowerCard">
                    <MemoryContribution
                        question={
                            <>
                                <span className={classes.greatVibesFont}>Flowers</span>
                                can often say it
                                <span className={classes.greatVibesFont}>Best</span>
                            </>
                        }
                        prompt="flowers"
                        avatarPosition="top"
                        type="button"
                        buttonText="send flowers to family"
                        buttonIcon={<LocalFlorist />}
                        linkProps={flowerStoreLink}
                        userData={userData}
                        caseDisplayFname={publicCase.display_fname}
                        isUserPhotoLoading={isUserPhotoSaving}
                        openShareOptionsDialog={onShareOptionsDialogOpen}
                    />
                </WhenEnabled>
            );
        }

        const indicatorText = isTouchDevice() ? 'Swipe' : 'More';

        return (
            <>
                <div className={classes.root}>
                    {!minimalView &&
                        <ScrollIndicator
                            showMe={isScrollIndicatorVisible}
                            unmountOnExit
                            mountOnEnter
                            iconPosition="start"
                            text={indicatorText}
                            icon={<TouchAppIcon color="primary" />}
                            rootClass={classes.scrollIndicator}
                            innerClass={classes.inner}
                            arrowClass={classes.arrow}
                            backgroundColor={activeTheme && activeTheme.secondary_color || undefined}
                        />
                    }

                    <ScrollSnap
                        type={ScrollSnapType.xMandatory}
                        align={ScrollSnapAlign.center}
                        ref={minimalView ? undefined : this._setContainerRef}
                        containerClasses={{
                            root: classNames(classes.innerContainer, minimalView && classes.minimalViewContainer),
                            item: classNames(classes.item, minimalView && classes.minimalViewItem),
                        }}
                    >
                        <MemoryContribution
                            question="Share a message or condolence with others..."
                            textfieldLabel="Share your message here..."
                            thanksScreenMessage={thanksScreenMessage}
                            prompt={MemoryPrompt.Default}
                            avatarPosition="top"
                            linkProps={RouteBuilder.Disabled}
                            type="textfield"
                            userData={userData}
                            caseDisplayFname={publicCase.display_fname}
                            isUserPhotoLoading={isUserPhotoSaving}
                            onSave={postMemory}
                            openSocialIconPopper={openSocialIconPopper}
                            closeSocialIconPopper={closeSocialIconPopper}
                            openShareOptionsDialog={onShareOptionsDialogOpen}
                            onAnswerChange={onAnswerChange}
                            intercomTargetProp={`RememberPage-Memories-InitialMemoryPrompt`}
                        />
                        {memoryPromptCards}
                    </ScrollSnap>
                </div>

                {!minimalView &&
                    <ScrollControls
                        scrollElement={this.containerRef}
                        registerEvents
                        containerClass={classes.navigationContainer}
                    />
                }
            </>
        );
    }

    private _setContainerRef = (ref: HTMLDivElement | null) => {
        if (ref) {
            this.containerRef = ref;
            this.containerRef.addEventListener('scroll', this.throttledScrollListener, { passive: true });
        }
    };

    private _setMarginforLastElement = () => {
        const { classes } = this.props;

        this._setScrollIndicatorVisibility();

        const windowWidth = window.innerWidth;

        const element: HTMLElement | null = document.querySelector(`.${classes.item}`);
        const elementWidth = element && element.scrollWidth;

        if (this.containerRef && elementWidth !== null) {
            if (windowWidth >= BREAK_POINT_768) {
                this.containerRef.style.paddingRight = `${ITEM_MARGIN}px`;
                return;
            }

            const padding = Math.floor(((windowWidth - elementWidth) / 2));
            this.containerRef.style.paddingRight = `${padding}px`;
        }
    };

    private _setScrollIndicatorVisibility = () => {
        const container = this.containerRef;

        if (container) {
            const isScrollIndicatorVisible = container.scrollLeft <= ITEM_MARGIN;

            if (isScrollIndicatorVisible !== this.state.isScrollIndicatorVisible) {
                this.setState({ isScrollIndicatorVisible });
            }
        }
    };
}

export default withStyles(styles)(MemoryContributionList);
