import { TOOLTIPS } from "../../constants";
import { Permission } from "../../shared/types/permissions";
import GTooltip from "./GTooltip";
import { usePermissionEnabled } from "./hooks/usePermissionEnabled";

interface Props {
    permission: Permission;
    children: JSX.Element | ((disabled: boolean) => JSX.Element);
}
const DisabledPermissionTooltip = (props: Props) => {
    const { children, permission } = props;

    const enabled = usePermissionEnabled(permission);

    return (
        <GTooltip
            enterDelay={0}
            title={enabled ? '' : TOOLTIPS.ACCESS_DISABLED}
            contentDisabled={!enabled}
        >
            {typeof children === 'function'
                ? children(!enabled)
                : children}
        </GTooltip>
    );
};

export default DisabledPermissionTooltip;