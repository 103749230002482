import classNames from 'classnames';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import InsetShadowIcon from '../../../common/InsetShadowIcon';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import withGStyles from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    iconContainerSize: {
        fontSize: 114,
        margin: 4,
    },
    flexDayEvents: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& $subtitle': {
            maxWidth: 240,
            textAlign: 'center'
        }
    },
    setIconCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginTop: 40,
    },
    subtitle: {},
});

interface Props {
    isMe: boolean;
    teamMemberFname: string;
}

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'iconContainerSize'
    | 'flexDayEvents' | 'setIconCenter' | 'subtitle'>;

const NoAssignedTasks = (props: StyledProps) => {
    const { classes, isMe, teamMemberFname } = props;

    const caughtUpText = isMe
        ? `Congrats, you're all caught up.`
        : `${teamMemberFname} is all caught up.`;
    return (
        <div className={classes.setIconCenter}>
            <div
                className={classNames(
                    classes.colorPrimary,
                    classes.flexDayEvents,
                    classes.width100
                )}
            >
                <Typography
                    component="p"
                    color="primary"
                    className={classes.fontSize17}
                >
                    {caughtUpText}
                </Typography>
                <InsetShadowIcon
                    icon={<TaskAltIcon className={classes.colorWhite} />}
                    iconClass={classes.iconContainerSize}
                    isWhiteBackground
                />
                <Typography
                    component="p"
                    color="primary"
                    className={classNames(
                        classes.fontSize13,
                        classes.subtitle
                    )}
                >
                    All assigned tasks will display here.
                </Typography>
            </div>
        </div >
    );
};

export default withGStyles(styles)(NoAssignedTasks);
