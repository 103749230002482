import { ObituaryLinkUX, ObituaryLinkRequest } from '../shared/types/remember';
import { deleteFromAPI, postToAPI, putToAPI } from './index';
import { GatherCasePublic, GatherCaseUX } from '../shared/types';
import { registerAppError } from './errors';
import { AppDispatch } from '../store';

export const INSERT_OBITUARY_LINK = 'INSERT_OBITUARY_LINK';
export type INSERT_OBITUARY_LINK = typeof INSERT_OBITUARY_LINK;

interface InsertObituaryLink {
    type: INSERT_OBITUARY_LINK;
    caseId: number;
    link: ObituaryLinkUX;
}

function insertObituaryLink(caseId: number, link: ObituaryLinkUX): InsertObituaryLink {
    return {
        type: INSERT_OBITUARY_LINK,
        caseId,
        link,
    };
}

export const UPDATE_OBITUARY_LINK = 'UPDATE_OBITUARY_LINK';
export type UPDATE_OBITUARY_LINK = typeof UPDATE_OBITUARY_LINK;

interface UpdateObituaryLink {
    type: UPDATE_OBITUARY_LINK;
    caseId: number;
    link: ObituaryLinkUX;
}

function updateObituaryLink(caseId: number, link: ObituaryLinkUX): UpdateObituaryLink {
    return {
        type: UPDATE_OBITUARY_LINK,
        caseId,
        link,
    };
}

export const DELETE_OBITUARY_LINK = 'DELETE_OBITUARY_LINK';
export type DELETE_OBITUARY_LINK = typeof DELETE_OBITUARY_LINK;

interface DeleteObituaryLink {
    type: DELETE_OBITUARY_LINK;
    caseId: number;
    link: ObituaryLinkUX;
}

function deleteObituaryLink(caseId: number, link: ObituaryLinkUX): DeleteObituaryLink {
    return {
        type: DELETE_OBITUARY_LINK,
        caseId,
        link,
    };
}

export function createObituaryLink(link: ObituaryLinkRequest, gatherCase: GatherCaseUX | GatherCasePublic) {
    return async (dispatch: AppDispatch) => {
        try {
            ObituaryLinkRequest.fromRequest(link);
        } catch (ex) {
            console.warn('Failed to validate ObituaryLinkRequest', link, ex);
            return null;
        }
        const result = await postToAPI<ObituaryLinkUX>(
            `api/remember/${gatherCase.name}/obituary/link`,
            { obituaryLink: link },
            dispatch
        );
        if (!result) {
            dispatch(registerAppError('Could not insert obituary links'));
        } else {
            dispatch(insertObituaryLink(gatherCase.id, result));
        }
        return null;
    };
}

export function modifyObituaryLink(
    linkId: number,
    link: ObituaryLinkRequest,
    gatherCase: GatherCaseUX | GatherCasePublic
) {
    return async (dispatch: AppDispatch) => {
        try {
            ObituaryLinkRequest.fromRequest(link);
        } catch (ex) {
            console.warn('Failed to validate ObituaryLinkRequest', link, ex);
            return null;
        }
        dispatch(updateObituaryLink(gatherCase.id, {...link, gatherCaseId: gatherCase.id, id: linkId }));
        const result = await putToAPI<ObituaryLinkUX>(
            `api/remember/${gatherCase.name}/obituary/link/${linkId}`,
            { obituaryLink: link },
            dispatch
        );
        if (!result) {
            dispatch(registerAppError('Could not update obituary link'));
        }
        return null;
    };
}

export function removeObituaryLink(link: ObituaryLinkUX, gatherCase: GatherCaseUX | GatherCasePublic) {
    return async (dispatch: AppDispatch) => {
        dispatch(deleteObituaryLink(gatherCase.id, link));
        const result =
            await deleteFromAPI<ObituaryLinkUX>(`api/remember/${gatherCase.name}/obituary/link/${link.id}`, dispatch);
        if (!result) {
            dispatch(registerAppError('Could not delete obituary link'));
        }
        return null;
    };
}

export type ObituaryLinkAction =
    | InsertObituaryLink
    | UpdateObituaryLink
    | DeleteObituaryLink
    ;