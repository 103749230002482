import Dinero from 'dinero.js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementChangeEvent, TokenResult } from '@stripe/stripe-js';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import CreditCardIcon from '@mui/icons-material/CreditCard';

import { useMemo, useState } from 'react';
import { Payer, calculateProcessingFeesInCents, calculateAmountInCents, PaymentFieldToolTip } from '..';
import { STRIPE_CHARGE_STATE_TYPE, collectStripeOnlinePayment, STRIPE_CHARGE_STATE }
    from '../../../../../actions/Finance.action';
import { toPennyValue, formatDinero } from '../../../../../shared/goods_and_services/pricing';
import { PaymentMethod, PaymentRequest } from '../../../../../shared/types';
import makeGStyles from '../../../../../styles/makeGStyles';
import { useGDispatch, useGSelector } from '../../../../../types';
import { Tooltip } from '@mui/material';

const useStyles = makeGStyles((theme) => ({
    bottomContent: {
        textAlign: 'center',
        marginTop: 20,
    },
    cashDesc: {
        fontSize: 18,
        color: theme.palette.secondary.main
    },
    moneyIcon: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    textField: {
        width: 200,
        margin: '8px 0',
        color: theme.palette.secondary.main,
        '& label': {
            width: 'max-content',
        },
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    },
    multiLineTextField: {
        marginTop: 20,
        width: 280,
        '& textarea': {
            minHeight: 43, // calculated as per the padding in mui
            maxHeight: 300
        },
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    stripeNotConfigured: {
        padding: '0 4px'
    },
    textAreaRoot: {}
}), { name: 'OnlinePaymentScreen' });

interface StripeInputProps {
    onCardEntryComplete: (tokenResult?: TokenResult) => void;
    disabled: boolean;
    caseUuid: string | null;
}

const StripeInput = (props: StripeInputProps) => {
    const { disabled, caseUuid } = props;

    // Get a reference to Stripe or Elements using hooks.
    const stripe = useStripe();
    const elements = useElements();

    const onChange = async (event: StripeCardElementChangeEvent) => {

        // Use elements.getElement to get a reference to the mounted Element.
        const cardElement = elements?.getElement(CardElement);

        // Pass the Element directly to other Stripe.js methods:
        // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
        const stripeTokenResult = cardElement && await stripe?.createToken(cardElement, {
            name: `Online:${caseUuid || 'Onboarding'}`,
            currency: 'USD',
        });

        if (event.complete && stripeTokenResult) {
            props.onCardEntryComplete(stripeTokenResult);
        } else {
            props.onCardEntryComplete();
        }
    };

    if (stripe) {
        return <CardElement
            onChange={onChange}
            options={{
                disabled,
            }}
        />;
    } else {
        return <Typography>Initializing Stripe...</Typography>;
    }
};

interface Props {
    paymentInfo: PaymentRequest;
    isRestricted: boolean;
    payer: Payer;
    caseUuid: string | null;
    updatePayment?: (paymentInfo: PaymentRequest) => void;
    closeDialog: (activeReceipt: number | null) => void;
    updateSentNotification?: (sentNotification: boolean) => void;
    dialogFooter?: JSX.Element;
    sendNotification: boolean;
    onlineChargeState: STRIPE_CHARGE_STATE_TYPE;
}

const OnlinePaymentScreen = (props: Props) => {
    const {
        paymentInfo,
        isRestricted,
        updatePayment,
        updateSentNotification,
        dialogFooter,
        sendNotification,
        onlineChargeState,
        caseUuid,
    } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const feeSchedule = useGSelector(({ financeState }) => financeState.transactions.feeSchedule);

    const [stripeTokenResult, setStripeTokenResult] = useState<TokenResult | null>(null);
    const [isAmountValid, setIsAmountValid] = useState<boolean>(true);
    const [isMerchFeeValid, setIsMerchFeeValid] = useState<boolean>(true);
    const [isTotalValid, setIsTotalValid] = useState<boolean>(true);
    const [amount, setAmount] = useState<string>((paymentInfo.amount.getAmount() / 100.0).toFixed(2));
    const [merchFee, setMerchFee] = useState<string>((paymentInfo.merch_fee.getAmount() / 100.0).toFixed(2));
    const [total, setTotal] =
        useState<string>((paymentInfo.amount.add(paymentInfo.merch_fee).getAmount() / 100.0).toFixed(2));
    const [memo, setMemo] = useState<string>(paymentInfo.memo);

    const apiKey = process.env.REACT_APP_STRIPE_API_KEY;
    const stripePromise = useMemo(() => apiKey ? loadStripe(apiKey) : null, [apiKey]);

    const processPayment = () => {

        // validate amount
        const newAmount = toPennyValue(amount);
        if (newAmount.isZero() || newAmount.isNegative()) {
            setIsAmountValid(false);
            return;
        }
        const newMerchFee = toPennyValue(merchFee);
        if (newMerchFee.isNegative()) {
            setIsMerchFeeValid(false);
            return;
        }
        const newTotal = toPennyValue(total);
        if (newTotal.isZero() || newTotal.isNegative()) {
            setIsTotalValid(false);
            return;
        }

        if ((paymentInfo.payment_id || paymentInfo.funeralHomeId) && stripeTokenResult) {
            const newInfo: PaymentRequest = {
                ...paymentInfo,
                amount: newAmount,
                merch_fee: newMerchFee,
                memo: isRestricted ? '' : memo,
            };
            dispatch(collectStripeOnlinePayment(newInfo, stripeTokenResult, sendNotification));
            if (updateSentNotification) {
                updateSentNotification(sendNotification);
            }
            if (updatePayment) {
                updatePayment(newInfo);
            }
        }
    };

    const calculateMerchFee = (newAmount: Dinero.Dinero) => {
        return Dinero({
            amount: calculateProcessingFeesInCents(newAmount.getAmount(), feeSchedule, PaymentMethod.online),
            currency: 'USD'
        });
    };

    const onChangeAmount = (newAmount: string) => {
        const newAmountPennies = toPennyValue(newAmount);
        const newFee = calculateMerchFee(newAmountPennies);
        const newTotal = newAmountPennies.add(newFee);
        setAmount(newAmount);
        setMerchFee((newFee.getAmount() / 100.0).toFixed(2));
        setTotal((newTotal.getAmount() / 100.0).toFixed(2));
    };

    const calculateAmount = (newTotal: Dinero.Dinero) => {
        return Dinero({
            amount: calculateAmountInCents(newTotal.getAmount(), feeSchedule, PaymentMethod.online),
            currency: 'USD'
        });
    };

    const onChangeTotal = (newTotal: string) => {
        const newTotalPennies = toPennyValue(newTotal);
        const newAmount = calculateAmount(newTotalPennies);
        const newFee = calculateMerchFee(newAmount);
        setAmount((newAmount.getAmount() / 100.0).toFixed(2));
        setMerchFee((newFee.getAmount() / 100.0).toFixed(2));
        setTotal(newTotal);
    };

    const onChangeProcessingFee = (newFee: string) => {
        const newFeePennies = toPennyValue(newFee);
        const amountPennies = toPennyValue(amount);
        const newTotal = amountPennies.add(newFeePennies);
        setMerchFee(newFee);
        setTotal((newTotal.getAmount() / 100.0).toFixed(2));
    };

    const onCardEntryComplete = (tokenResult: TokenResult | null = null) => {
        setStripeTokenResult(tokenResult);
    };

    const renderStripeElements = (disabled: boolean) => {

        if (stripePromise) {
            return (
                <Elements stripe={stripePromise}>
                    <StripeInput
                        disabled={disabled}
                        caseUuid={caseUuid}
                        onCardEntryComplete={onCardEntryComplete}
                    />
                </Elements>
            );
        } else {
            return <h1 className={classes.stripeNotConfigured}>
                Stripe Online Payments Not Configured
            </h1>;
        }
    };

    const totalFormatted = formatDinero(toPennyValue(total));

    const isProcessing = onlineChargeState === STRIPE_CHARGE_STATE.FETCHING_TOKEN
        || onlineChargeState === STRIPE_CHARGE_STATE.CHARGING_CARD;

    return (<>
        <Typography className={classes.cashDesc} align="center" >
            Process Online Payment
        </Typography>

        <Grid item>
            <Grid item>
                <Tooltip
                    enterDelay={400}
                    placement="top"
                    title={!isRestricted && PaymentFieldToolTip.paymentSubtotal}
                >
                    <TextField
                        required
                        error={!isAmountValid}
                        id="paymentAmount"
                        label="Payment Subtotal"
                        value={amount}
                        onChange={e => onChangeAmount(e.target.value)}
                        type="number"
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        variant="outlined"
                        disabled={isProcessing || isRestricted}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">$</InputAdornment>
                            ),
                            classes: {
                                notchedOutline: 'notranslate'
                            }
                        }}
                        autoFocus
                    />
                </Tooltip>
            </Grid>

            {(!isRestricted || merchFee !== '0.00') && <>
                <Grid item>
                    <Tooltip
                        enterDelay={400}
                        placement="top"
                        title={!isRestricted && PaymentFieldToolTip.paymentFee}
                    >
                        <TextField
                            id="optionalFee"
                            label="Convenience Fee"
                            error={!isMerchFeeValid}
                            value={merchFee}
                            onChange={e => onChangeProcessingFee(e.target.value)}
                            type="number"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            variant="outlined"
                            disabled={isProcessing || isRestricted}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                                classes: {
                                    notchedOutline: 'notranslate'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip
                        enterDelay={400}
                        placement="top"
                        title={!isRestricted && PaymentFieldToolTip.paymentTotal}
                    >
                        <TextField
                            id="total"
                            label="Payment Total"
                            error={!isTotalValid}
                            value={total}
                            onChange={e => onChangeTotal(e.target.value)}
                            type="number"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            variant="outlined"
                            disabled={isProcessing || isRestricted}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                                classes: {
                                    notchedOutline: 'notranslate'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid>
            </>}

        </Grid>

        <Grid>
            {renderStripeElements(isProcessing)}
        </Grid>

        {!isRestricted && <Grid>
            <TextField
                id="memo"
                label="Memo (optional)"
                multiline
                value={memo}
                onChange={e => setMemo(e.target.value)}
                className={classes.multiLineTextField}
                InputProps={{
                    classes: {
                        root: classes.textAreaRoot,
                        notchedOutline: 'notranslate'
                    }
                }}
                margin="dense"
                variant="outlined"
                disabled={isProcessing}
                placeholder="This memo will be visible on the final receipt"
            />
        </Grid>}
        <Grid
            className={classes.bottomContent}
            item
            xs={12}
        >
            <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={processPayment}
                className={classes.marginBottom16}
                disabled={stripeTokenResult === undefined || isProcessing}
            >
                <CreditCardIcon className={classes.moneyIcon} />
                &nbsp;Process {totalFormatted} Payment
            </Button>

            {dialogFooter}

        </Grid>
    </>);

};

export default OnlinePaymentScreen;
