import { Grid, Typography } from '@mui/material';
import { BulkEditButtonType } from './BulkEditSelectedOptionContent';
import { ReactNode } from 'react';
import BulkEditDialogFooter from './BulkEditDialogFooter';

interface BulkEditBaseContainerProps {
    selectButton: BulkEditButtonType | null;
    children?: ReactNode | ReactNode[];
    selectedProductsCount: number;
    onSubmitClick: (val?: number | string) => Promise<void>;
    disableSubmitButton: boolean;
    isSpinning: boolean;
}

const BulkEditBaseContainer = (props: BulkEditBaseContainerProps) => {
    const {
        selectButton,
        children,
        disableSubmitButton,
        onSubmitClick,
        selectedProductsCount,
        isSpinning,
    } = props;

    return (
        <>
            <Grid container flexDirection="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography textAlign="center" fontSize={18} fontWeight={400} color="primary" pb={0.5}>
                        {selectButton}
                    </Typography>
                </Grid>

                {children}
            </Grid>

            <BulkEditDialogFooter
                selectedProductsCount={selectedProductsCount}
                onSubmitClick={onSubmitClick}
                disableSubmitButton={disableSubmitButton}
                isSpinning={isSpinning}
            />
        </>
    );
};

export default BulkEditBaseContainer;
