
export const buildQRCodeLink = (params: {
    caseName: string;
    download?: boolean;
    organize?: boolean;
}): string => {
    const { caseName, download, organize } = params;
    const apiUrl = process.env.REACT_APP_API_BASE_PATH;
    const base = `${apiUrl || ''}/app/keeptrack/${caseName}/qrcode${organize ? '/organize' : ''}`;
    return base + (download ? '?download=true' : '');
};

export const buildPDFLabelLink = (params: {
    caseName: string;
    download?: boolean;
}): string => {
    const { caseName, download } = params;
    const apiUrl = process.env.REACT_APP_API_BASE_PATH;
    const base = `${apiUrl || ''}/app/labelpdf/${caseName}`;
    return base + (download ? '?download=true' : '');
};
