import { AlbumPhoto as AlbumPhotoType } from '../../../../types';
import * as React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { StyledProps } from './styles';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import AlbumPhoto from '../AlbumPhoto';

type SortableProps = {
    style: React.CSSProperties;
    photo: AlbumPhotoType;
    elementIndex: number;
    isOpenPhotoPopper: boolean;
    isLocked: boolean;
    activePhotoId: string | null;
    setPhotoCircleRef: (ref: HTMLDivElement | null, index: number) => void;
    setEditButtonRef: (ref: HTMLDivElement | null, index: number) => void;
    setPhotoRef: (ref: HTMLDivElement | null, index: number) => void;
    setContainerRef: (ref: HTMLDivElement | null, index: number) => void;
    setPhotoNumberRef: (ref: HTMLDivElement | null, index: number) => void;
    openPopper: (event: React.MouseEvent<HTMLElement>, photoId: string, index: number) => void;
};

export const SortablePhotoElement = SortableElement<SortableProps & StyledProps>(({
    style,
    photo,
    elementIndex,
    classes,
    isOpenPhotoPopper,
    activePhotoId,
    setPhotoCircleRef,
    setEditButtonRef,
    setPhotoRef,
    isLocked,
    setContainerRef,
    openPopper,
    setPhotoNumberRef
}: SortableProps & StyledProps) => (

    <div
        className={classNames(classes.albumPhotoContainer, isLocked && classes.cursorAuto)}
        ref={ref => setContainerRef(ref, elementIndex)}
        style={style}
    >
        <Tooltip
            enterDelay={400}
            title="Click and hold to rearrange. Click the edit button to adjust."
            placement="top"
            key={photo.gatherId}
            disableInteractive
        >
            <div>
                <AlbumPhoto
                    photo={photo}
                    index={elementIndex + 1}
                    isLocked={isLocked}
                    isOpenPhotoPopper={isOpenPhotoPopper}
                    activePhotoId={activePhotoId}
                    setPhotoCircleRef={ref => setPhotoCircleRef(ref, elementIndex)}
                    setEditButtonRef={ref => setEditButtonRef(ref, elementIndex)}
                    setPhotoRef={ref => setPhotoRef(ref, elementIndex)}
                    openPopper={(e, photoId, idx) => openPopper(e, photoId, idx)}
                    setPhotoNumberRef={(ref) => setPhotoNumberRef(ref, elementIndex)}
                />
            </div>
        </Tooltip>
    </div>
));
