import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import GestureIcon from '@mui/icons-material/Gesture';
import ClearIcon from '@mui/icons-material/Clear';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import GSwitch from '../../../common/inputElements/GSwitch';
import { ORANGE_COLOR } from '../../../../constants/colorVariables';

import { PaymentMethod, PaymentRequest } from '../../../../shared/types';
import { getFormattedPhoneNumber } from '../../../../services';
import { Payer, PaymentFieldToolTip } from '.';
import { PatchPaymentParameters } from '../../../../actions/Finance.action';
import { formatDinero, toPennyFormat, toPennyValue } from '../../../../shared/goods_and_services/pricing';
import { DateTimeFormat, joinNameParts } from '../../../../shared/utils';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import momentTz from 'moment-timezone';
import { Tooltip } from '@mui/material';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    cashDialog: {},
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 8px',
        margin: 0,
        textAlign: 'center',
        overflowX: 'hidden',
        position: 'relative',
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 20,
    },
    dialogPaper: {
        margin: 'auto',
        width: '100%',
        maxWidth: 'unset',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        '@media (min-width: 600px)': {
            borderRadius: 4,
            width: 360,
            maxWidth: 360,
            height: 'auto',
            maxHeight: 'calc(100% - 96px)',
            display: 'flex'
        },
    },
    cashDesc: {
        fontSize: 18,
        color: theme.palette.secondary.main
    },
    moneyIcon: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    headerContent: {
        textAlign: 'center',
        paddingBottom: 8,
        '& p': {
            color: theme.palette.common.white,
            '& span': {
                '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            }
        },
        '& $moneyIcon': {
            fontSize: 80,
        }
    },
    headerTitle: {
        fontSize: 24,
        '&:hover': {
            cursor: 'default !important',
            textDecoration: 'none !important',
        }
    },
    cashFormControl: {
        width: 160,
        marginTop: 12,
    },
    paymentDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textField: {
        width: 200,
        margin: '8px 0',
        color: theme.palette.secondary.main,
        '&$check': {
            margin: '0 0 24px'
        },
        '& label': {
            width: 'max-content',
            whiteSpace: 'nowrap',
        },
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    },
    datePickerTextField: {
        width: 200,
        zIndex: 1,
        '& input': {
            fontSize: 14,
        },
        '& input[type="date"]::-webkit-clear-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            display: 'none'
        }
    },
    helperText: {
        fontSize: '12px !important',
        marginTop: 4,
        textAlign: 'center',
    },
    textArea: {
        marginTop: 20,
        width: 280,
        '& textarea': {
            minHeight: 43, // calculated as per the padding in mui
            maxHeight: 300
        },
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    payerName: {
        fontSize: 16,
        lineHeight: '18px',
    },
    payerEmail: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    smallTextCont: {
        width: 188,
        margin: 'auto',
    },
    totalAmountCont: {
        color: theme.palette.primary.main,
        width: 120,
        borderTop: '1px solid',
        margin: '8px auto',
        padding: '8px 20px',
        textAlign: 'center',
        fontSize: 18,
    },
    buttonContainer: {
        margin: '24px 0 8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        '& button': {
            margin: 8,
        }
    },
    sendReceiptSwitch: {
        maxHeight: '32px',
        display: 'flex',
        color: theme.palette.secondary.main,
        alignItems: 'center',
        width: 'fit-content',
        margin: '-4px auto 0px',
        fontSize: 16,
    },
    gSwitch: {
        width: '32px',
        paddingRight: '10px',
    },
    orangeText: {
        color: ORANGE_COLOR,
    },
    check: {},
    textAreaRoot: {}
});

interface Props {
    isEditMode: boolean;
    isDialogOpen: boolean;
    paymentInfo: PaymentRequest;
    payer: Payer;
    caseUuid: string;
    patchPayment: (...params: PatchPaymentParameters) => void;
    closeDialog: (activeReceipt: number | null) => void;
    updatePayment: (paymentInfo: PaymentRequest) => void;
    openMySettingsDialog: () => void;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    removePayment: (id: number | null) => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    amount: string;
    paymentDate: string;
    memo: string;
    merchFee: string;
    externalId: string;
    isPaymentAmountValid: boolean;
    sendNotification: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'cashDialog' | 'dRoot' | 'header' | 'dialogContent'
    | 'clearIcon' | 'bottomContent' | 'cashDesc' | 'headerContent' | 'moneyIcon' | 'headerTitle'
    | 'cashFormControl' | 'paymentDetails' | 'textField' | 'datePickerTextField' | 'helperText' | 'multiLineTextField'
    | 'checkFormControl' | 'payerName' | 'payerEmail' | 'smallTextCont' | 'totalAmountCont' | 'textAreaRoot'
    | 'textArea' | 'check' | 'buttonContainer' | 'sendReceiptSwitch' | 'gSwitch' | 'orangeText'>;

class CashPaymentDialog extends React.Component<StyledProps & DialogProps, State> {

    constructor(props: StyledProps & DialogProps) {
        super(props);
        const { paymentInfo } = props;
        const timezone = momentTz.tz.guess();
        const paymentDateString = momentTz(paymentInfo.payment_date || new Date())
            .tz(timezone).format(DateTimeFormat.ShortDate);

        if (paymentInfo.payment_id) {
            this.state = {
                amount: paymentInfo.amount.isZero()
                    ? '' : (paymentInfo.amount.getAmount() / 100.0).toFixed(2),
                paymentDate: paymentDateString,
                merchFee: paymentInfo.merch_fee.isZero()
                    ? '' : (paymentInfo.merch_fee.getAmount() / 100).toFixed(2),
                memo: paymentInfo.memo,
                externalId: paymentInfo.external_id || '',
                isPaymentAmountValid: true,
                sendNotification: true,
            };
        } else {
            this.state = {
                amount: '',
                paymentDate: paymentDateString,
                merchFee: '',
                memo: '',
                externalId: '',
                isPaymentAmountValid: true,
                sendNotification: true,
            };
        }

    }

    validatePaymentInfo() {
        const { amount } = this.state;
        const dollarAmount = toPennyValue(amount);
        const isPaymentAmountValid = !dollarAmount.isZero() && dollarAmount.isPositive();
        this.setState({ isPaymentAmountValid });
        return isPaymentAmountValid;
    }

    patchPayment() {
        const { paymentInfo, caseUuid, closeDialog } = this.props;
        const { method } = paymentInfo;
        const { amount, memo, merchFee, externalId, sendNotification, paymentDate } = this.state;

        // validate form
        if (!this.validatePaymentInfo()) {
            return;
        }

        let paymentDateMoment: momentTz.Moment | undefined;
        if (paymentDate) {
            paymentDateMoment = momentTz(paymentDate);
            if (!paymentDateMoment.isValid) {
                paymentDateMoment = undefined;
            }
        }

        // We should never get here without a proposed payment already having been created
        const updatedPayment: PaymentRequest = {
            ...paymentInfo,
            method,
            amount: toPennyValue(amount),
            payment_date: paymentDateMoment,
            memo,
            merch_fee: toPennyValue(merchFee),
            external_id: externalId
        };
        if (paymentInfo.payment_id) {
            this.props.patchPayment({ caseUuid, paymentInfo: updatedPayment, collect: true, sendNotification });
            this.props.updatePayment(updatedPayment);
            closeDialog(paymentInfo.payment_id);
        }
    }

    onChangeAmount(amount: string) {
        this.setState({ amount });
    }

    onChangeDate(paymentDate: string) {
        this.setState({ paymentDate });
    }

    onChangeMerchantFee(merchFee: string) {
        this.setState({ merchFee });
    }

    onChangeMemo(memo: string) {
        this.setState({ memo });
    }

    onChangeExternalId(externalId: string) {
        this.setState({ externalId });
    }

    getComponentOptions() {
        const { classes, paymentInfo } = this.props;
        const method = paymentInfo.method || PaymentMethod.unknown; // Should never get here with null method
        const methodLabel = method.charAt(0).toUpperCase() + method.slice(1);

        switch (method) {
            case PaymentMethod.other:
                return {
                    label: 'Enter payment details below',
                    icon: <AttachMoneyIcon className={classes.moneyIcon} />,
                    methodLabel,
                };
            case PaymentMethod.check:
                return {
                    label: 'Enter check details below',
                    icon: <GestureIcon className={classes.moneyIcon} />,
                    methodLabel,
                };
            case PaymentMethod.cash:
            default:
                return {
                    label: 'How much cash did you receive?',
                    icon: <LocalAtmIcon className={classes.moneyIcon} />,
                    methodLabel,
                };
        }
    }

    render() {
        const {
            classes,
            isDialogOpen,
            closeDialog,
            payer,
            paymentInfo,
            openMySettingsDialog,
            onAddNonUserContactInfoClick,
            isEditMode,
            zIndex
        } = this.props;
        const { isPaymentAmountValid, sendNotification } = this.state;
        const paymentOptions = this.getComponentOptions();

        const { amount, paymentDate, memo, merchFee, externalId } = this.state;
        const method = paymentInfo.method || 'unknown'; // Should never get here with null method
        const dollarAmount = toPennyFormat(amount);
        const dollarFee = toPennyFormat(merchFee);
        const totalAmount = toPennyValue(amount).add(toPennyValue(merchFee));

        const payerName = joinNameParts(payer.payerEntity);
        const payerEmail = payer.payerEntity.email;
        const payerPhone = payer.payerEntity.phone;
        const isNonUser = !payer.payerEntity.user_profile_id;

        const openEditUserDialog = () => {
            if (!payerEmail && !payerPhone) {
                onAddNonUserContactInfoClick(payer.payerEntity.id);
            } else {
                closeDialog(null);
                openMySettingsDialog();
            }
        };
        const hasEmailorPhone = payerEmail || getFormattedPhoneNumber(payerPhone);
        const sendReceiptText = isNonUser
            ? 'RECEIPT WILL NOT BE SENT UNLESS PAYER IS INVITED'
            : sendNotification && hasEmailorPhone
                ? 'Receipt will be sent to '
                : 'A receipt will NOT be sent. ';
        return (
            <div className={classes.root} >
                <Dialog
                    open={isDialogOpen}
                    onClose={() => closeDialog(null)}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.cashDialog}
                    classes={{
                        paper: classes.dialogPaper,
                        root: classes.dRoot,
                    }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.header,
                            classes.backgroundPrimary
                        )}
                    >
                        <ClearIcon
                            className={classNames(classes.clearIcon, classes.colorWhite)}
                            onClick={() => closeDialog(null)}
                        />
                        <div
                            className={classes.headerContent}
                        >
                            {paymentOptions.icon}
                            <Typography className={classes.headerTitle}>
                                {isEditMode && 'Adjust ' || 'Record a '}
                                {method !== PaymentMethod.other && paymentOptions.methodLabel} Payment
                            </Typography>
                            <Typography className={classes.payerName}>
                                <span onClick={e => openEditUserDialog()}>
                                    {payerName}
                                </span>
                            </Typography>
                            <Typography className={classes.fontSize12}>
                                <span onClick={e => openEditUserDialog()}>
                                    {payerEmail || 'No email listed'}
                                </span>
                            </Typography>
                            <Typography className={classes.fontSize12}>
                                <span onClick={e => openEditUserDialog()}>
                                    {payerPhone && getFormattedPhoneNumber(payerPhone) || 'No number listed'}
                                </span>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid
                            item
                            className={method === PaymentMethod.other ? classes.marginTop8 : classes.marginTop20}
                        >
                            <Typography
                                className={classes.cashDesc}
                                align="center"
                            >
                                {paymentOptions.label}
                            </Typography>

                            <Grid item className={classNames(classes.paymentDetails)}>

                                {method === PaymentMethod.check &&
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classNames(classes.textField, classes.check)}
                                            autoComplete="new-password"
                                            id="check"
                                            name="check"
                                            type="text"
                                            label="Check Number"
                                            value={externalId}
                                            onChange={e => this.onChangeExternalId(e.target.value)}
                                            autoFocus={method === PaymentMethod.check}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} >
                                    <Tooltip
                                        enterDelay={400}
                                        placement="top"
                                        title={method === PaymentMethod.other && PaymentFieldToolTip.paymentSubtotal}
                                    >
                                        <TextField
                                            id="paymentAmount"
                                            error={!isPaymentAmountValid}
                                            required
                                            label={'Payment Subtotal'}
                                            value={dollarAmount || ''}
                                            onChange={e => this.onChangeAmount(e.target.value)}
                                            type="number"
                                            className={classes.textField}
                                            InputLabelProps={{ shrink: true }}
                                            margin="dense"
                                            variant="outlined"
                                            autoFocus={method !== 'check'}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                                classes: {
                                                    notchedOutline: 'notranslate'
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="paymentDate"
                                        required
                                        label="Payment Date"
                                        value={paymentDate}
                                        onChange={e => this.onChangeDate(e.target.value)}
                                        type="date"
                                        className={classes.datePickerTextField}
                                        InputLabelProps={{ shrink: true }}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>

                                {method === PaymentMethod.other && <>
                                    <Grid item xs={12}>
                                        <Tooltip
                                            enterDelay={400}
                                            placement="top"
                                            title={PaymentFieldToolTip.paymentFee}
                                        >
                                            <TextField
                                                id="optionalFee"
                                                label="Convenience Fee"
                                                value={dollarFee || ''}
                                                onChange={e => this.onChangeMerchantFee(e.target.value)}
                                                type="number"
                                                className={classes.textField}
                                                InputLabelProps={{ shrink: true }}
                                                margin="dense"
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">$</InputAdornment>
                                                    ),
                                                    classes: {
                                                        notchedOutline: 'notranslate'
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Typography className={classes.totalAmountCont}>
                                        {formatDinero(totalAmount)}
                                    </Typography>
                                </>}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="memo"
                                    label="Memo (optional)"
                                    multiline
                                    value={memo}
                                    onChange={e => this.onChangeMemo(e.target.value)}
                                    className={classes.textArea}
                                    InputProps={{
                                        classes: {
                                            root: classes.textAreaRoot,
                                            notchedOutline: 'notranslate'
                                        }
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    placeholder="This memo will be visible on the final receipt"
                                />
                            </Grid>

                            <Grid
                                className={classes.bottomContent}
                                item
                                xs={12}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classNames(
                                        classes.buttonContainer,
                                        isEditMode && classes.spaceBetween
                                    )}
                                >
                                    {isEditMode &&
                                        <DisabledPermissionTooltip permission={Permission.REMOVE_PAYMENTS}>
                                            {disabled =>
                                                <Button
                                                    color="secondary"
                                                    disabled={disabled}
                                                    onClick={(e) => {
                                                        this.props.removePayment(paymentInfo.payment_id || null);
                                                        this.props.closeDialog(null);
                                                    }}
                                                >
                                                    remove payment
                                                </Button>
                                            }
                                        </DisabledPermissionTooltip>
                                    }
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={() => this.patchPayment()}
                                        className={classes.marginBottom5}
                                    >
                                        {paymentOptions.icon}&nbsp;{isEditMode ? 'update' : 'record'}&nbsp;
                                        {totalAmount.getAmount() !== 0 &&
                                            <span>{formatDinero(totalAmount)}&nbsp;</span>
                                        }
                                        <span>Payment</span>
                                    </Button>
                                </Grid>

                                <Typography color="secondary" className={classNames(
                                    classes.fontSize12,
                                    (hasEmailorPhone && !sendNotification) && classes.orangeText,
                                    classes.marginBottom8,
                                )}>
                                    {sendReceiptText}
                                    {sendNotification && !isNonUser &&
                                        <span
                                            className={classes.payerEmail}
                                            onClick={e => openEditUserDialog()}
                                        >
                                            {(hasEmailorPhone) || 'Click to add contact information.'}
                                        </span>
                                    }
                                </Typography>

                                {
                                    !isNonUser &&
                                    <div className={classes.sendReceiptSwitch}>
                                        <div className={classes.gSwitch}>
                                            <GSwitch
                                                checked={!sendNotification}
                                                onChangeCallBack={
                                                    (ischecked) => this.setState({ sendNotification: !ischecked })
                                                }
                                                id="send-receipt-switch"
                                            />
                                        </div>
                                        <label htmlFor="send-receipt-switch" className={classes.cursorPointer}>
                                            Don't send a receipt
                                        </label>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default compose(
    withGStyles(styles),
    withFullScreen('xs')
)(CashPaymentDialog) as React.ComponentType<Props>;
