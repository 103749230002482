import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { useState } from "react";
import { EntityRelationship, EntityRelationshipType, GatherCasePublic } from "../../../../../shared/types";
import AcquaintanceButtons from "../../../../common/AcquaintanceButtons";
import FuneralHomeLogo from "../../../../common/FuneralHomeLogo";
import { SelfLoginStyledProps } from "../SelfLogin.styles";
import { LoginDialogStep, SelfLoginSrcAction } from "../SelfLoginDialog";

interface Props extends SelfLoginStyledProps {
    publicCase: GatherCasePublic;
    isUserLoggedIn: boolean;
    srcAction: SelfLoginSrcAction;
    firstName: string;
    relationshipType: EntityRelationshipType | null;
    relationshipAlias: string;
    relationship: EntityRelationship | null;
    setRelationship: (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string
    ) => void;
    goToStep: (step: LoginDialogStep) => void;
    registerExistingVisitor: () => void;
    nextStep: LoginDialogStep;
}

const RelationshipStep = (props: Props) => {
    const {
        classes,
        publicCase,
        isUserLoggedIn,
        srcAction,
        firstName,
        relationship,
        relationshipAlias,
        relationshipType,
        registerExistingVisitor,
        goToStep,
        setRelationship,
        nextStep
    } = props;

    const [hasRelationError, setHasRelationError] = useState(false);

    const hasFHLogo = publicCase.funeral_home.photo;

    let greetingText;
    switch (srcAction) {
        case SelfLoginSrcAction.sharePhoto:
        case SelfLoginSrcAction.shareText: {
            greetingText = `Hello ${firstName}, thanks for sharing!`;
            break;
        }
        case SelfLoginSrcAction.signGuestbook: {
            greetingText =
                `Hello ${firstName}, thanks for being here. Signing ${publicCase.display_fname}'s Guestbook `
                + `is a great way to show others how much you care.`;
            break;
        }
        case SelfLoginSrcAction.treeSale:
        case SelfLoginSrcAction.flowerSale: {
            greetingText = greetingText = `Hello ${firstName}, Your order has been placed successfully. ` +
                `Connect with ${publicCase.display_fname}'s family and friends by answering a few questions`;
            break;
        }
        default: {
            greetingText = `Hi, ${firstName}!`;
            break;
        }
    }
    return (
        <>
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.userDetails,
                    classes.userRelationContainer,
                    classes.step2
                )}
            >
                <Typography className={classes.fontSize16}>{greetingText}</Typography>
                <Typography
                    color="secondary"
                    className={classes.heading}
                >
                    How do you know {publicCase.display_fname}?
                </Typography>

                <Grid item xs={12} className={classes.acquaintanceContainer}>
                    <AcquaintanceButtons
                        formType="INVITE_GUEST"
                        relationship={relationship || null}
                        relationshipAlias={relationshipAlias || null}
                        relationshipType={relationshipType || null}
                        setRelationship={setRelationship}
                        caseFName={publicCase.fname}
                        saveAttempted={hasRelationError}
                        hideLabel
                        userFName="I am"
                        isMe
                    />
                    {!relationshipType && hasRelationError && <Typography color="error" align="center">
                        Relation is required.
                    </Typography>}
                </Grid>

            </Grid>
            <Grid item className={classNames(classes.continueButtonContainer, classes.step2)}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={e => {
                        if (!relationshipType) {
                            setHasRelationError(true);
                        } else if (relationshipType === EntityRelationshipType.other && relationshipAlias === '') {
                            setHasRelationError(true);
                        } else {
                            setHasRelationError(false);
                            if (isUserLoggedIn) {
                                registerExistingVisitor();
                                goToStep(nextStep);
                            } else {
                                goToStep(nextStep);
                            }
                        }
                    }}
                >
                    {'Continue'}
                </Button>
                {!isUserLoggedIn &&
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.loginNowContainer}
                    >
                        <Typography color="secondary" variant="h6" className={classes.haveAccount}>
                            Already have an account?&nbsp;
                        </Typography>
                        <Button
                            className={classNames(
                                classes.loginText,
                                classes.loginButton,
                            )}
                            onClick={e => goToStep(LoginDialogStep.existingUser)}
                        >
                            Log in now
                        </Button>
                    </Grid>
                }

                {hasFHLogo && <FuneralHomeLogo className={classes.fhLogo} />}
            </Grid>
        </>
    );
};

export default RelationshipStep;