import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { MAX_HEIGHT } from "../styles";
import HangingPhotosUploadButton from "./HangingPhotos.uploadButton";
import ReviewPhotos from "./ReviewPhotos";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        minHeight: 410,
        transition: 'min-height 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&$onePhoto': {
            minHeight: 430,
            margin: '16px 16px 0',
            '& > div:first-of-type': {
                '@media (min-width: 768px)': {
                    transform: 'translateY(5%)'
                },
            },
            '& > div:last-child': {
                '@media (min-width: 768px)': {
                    transform: 'translateY(0%)'
                },
            },
        },
        '&$twoPhotos': {
            '@media (min-width: 768px)': {
                margin: '16px 20px 0 12px !important'
            }
        },
        '&$threeOrMorePhotos': {
            minHeight: MAX_HEIGHT
        },
        '& > div:first-of-type': {
            height: 'fit-content',
            zIndex: 4,
            '@media (min-width: 768px) and (max-width: 959px)': {
                transform: 'translateY(-28%)'
            },
            '@media (min-width: 1024px)': {
                marginLeft: 20
            }
        },
    },
    onePhoto: {},
    twoPhotos: {},
    threeOrMorePhotos: {}
}), { name: 'LeftSection' });

interface Props {
    casePhotosCount: number;
    pendingVisitorsCount: number;
    pendingPhotosCount: number;
    caseFName: string;
    showReviewButton: boolean;
    onReview: () => void;
    onPhotosUpload: (files: FileList | null, setUploading: (uploading: boolean) => void) => void;
}

const LeftSection = (props: Props) => {
    const {
        casePhotosCount,
        pendingPhotosCount,
        pendingVisitorsCount,
        showReviewButton,
        caseFName,
        onReview,
        onPhotosUpload
    } = props;

    const classes = useStyles();

    return (
        <Grid
            item
            className={classNames(classes.root,
                casePhotosCount >= 1 && classes.onePhoto,
                casePhotosCount >= 2 && classes.twoPhotos,
                casePhotosCount > 2 && classes.threeOrMorePhotos,
            )}
        >
            <HangingPhotosUploadButton
                caseFName={caseFName}
                casePhotosCount={casePhotosCount}
                onPhotosUpload={onPhotosUpload}
            />

            {showReviewButton &&
                <ReviewPhotos
                    visitorsCount={pendingVisitorsCount}
                    photosCount={pendingPhotosCount}
                    totalPhotosCount={casePhotosCount}
                    onReview={onReview}
                />
            }
        </Grid>
    );
};

export default LeftSection;