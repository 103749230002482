import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import FRQuestion from './FR.Question';

interface Props {
    onStepChange(step: number): void;
}

const FRQuestionThree = (props: Props) => {
    const { onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <FRQuestion
            question="Is the person applying for reimbursement a U.S. citizen, non-citizen national, or
            qualified alien?"
            onAccept={() => onStepChange(7)}
            onDeny={() => onStepChange(6)}
        >
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                A minor child cannot apply on behalf of an adult who is not a U.S. citizen, non-citizen
                national, or qualified alien.
            </Typography>
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                There are several categories of aliens lawfully present in the U.S. who are not eligible.
                These include, but are not limited to: Temporary tourist visa holders, Foreign students,
                Temporary work visa holders, Habitual residents such as citizens of the Federated States
                of Micronesia, Palau, and the Republic of the Marshall Islands.
            </Typography>
        </FRQuestion>
    );
};

export default FRQuestionThree;