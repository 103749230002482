import { Component } from 'react';
import classNames from 'classnames';
import { times } from 'lodash';
import Dinero from 'dinero.js';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';

import { APP_STYLED_PROPS } from '../../styles/App.styles';
import { formatDinero } from '../../shared/goods_and_services/pricing';
import withGStyles from '../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    ulGrid: {
        overflowX: 'auto',
        width: '100%',
        padding: 0,
        margin: 0,
        boxShadow: 'inset 0 16px 16px -10px #ccc, inset 0px -16px 16px -10px #ccc',
        '& $ul': {
            overflow: 'unset',
            margin: '24px 0',
            '& $li': {
                height: '60px !important',
                width: 90,
                color: theme.palette.primary.main,
                border: '1px solid',
                borderRadius: 4,
                margin: '0 8px',
                '&$active': {
                    background: theme.palette.primary.main,
                    boxShadow: theme.shadows[5],
                    '&:hover': {
                        opacity: 1,
                    },
                    '& p': {
                        color: theme.palette.common.white
                    }
                },
                '&:first-of-type': {
                    marginLeft: 16,
                },
                '& p': {
                    '&:first-of-type': {
                        fontSize: 24,
                        lineHeight: '1em',
                    },
                }
            },
            '& $lastLi': {
                width: 8,
                height: 1,
                border: 'none'
            }
        },
        '& $listTile': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%'
        }
    },
    ul: {},
    li: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.675,
        }
    },
    listTile: {},
    lastLi: {},
    active: {},
});

interface Props {
    price: Dinero.Dinero;
    minPercent: number;
    maxPercent: number;
    step: number;
    active: number;
    onPercentageSelect: (priceAdjustment: Dinero.Dinero) => void;
}

type StyledProps = APP_STYLED_PROPS &
    WithStyles<'root' | 'ulGrid' | 'ul' | 'li' | 'listTile' | 'lastLi' | 'active'>;

type CombinedProps = Props & StyledProps;

class PercentageSlider extends Component<CombinedProps> {

    componentDidMount() {
        const { active } = this.props;
        const activeCard = document.getElementById(`p_card_${active}`);

        if (activeCard) {
            activeCard.scrollIntoView({
                block: 'center',
                inline: 'center'
            });
        }
    }

    render() {
        const {
            classes,
            price,
            minPercent,
            maxPercent,
            step,
            onPercentageSelect,
            active,
        } = this.props;

        // minPercent to maxPercent (including 0)
        const totalSteps = (Math.abs(maxPercent - minPercent) / step) + 1;
        const percentages = times(totalSteps, (i) => minPercent + (i * step));

        return (
            <div className={classes.ulGrid}>
                <ul className={classNames(classes.ul, classes.list)}>
                    {percentages.map(p =>
                        <ImageListItem
                            key={p}
                            className={classNames(
                                classes.li,
                                active !== undefined &&
                                active === price.percentage(Math.abs(p)).multiply(p < 0 ? -1 : 1).getAmount()
                                && classes.active
                            )}
                            onClick={() => onPercentageSelect(
                                price.percentage(Math.abs(p)).multiply(p < 0 ? -1 : 1)
                            )}
                            id={`p_card_${price.percentage(Math.abs(p)).multiply(p < 0 ? -1 : 1).getAmount()}`}
                        >
                            <div className={classes.listTile}>
                                <Typography color="primary">
                                    {p > 0 && <>+</>}
                                    {p}%
                                </Typography>
                                <Typography color="primary">
                                    {p > 0 && <>+</>}
                                    {formatDinero(price.percentage(Math.abs(p)).multiply(p < 0 ? -1 : 1))}
                                </Typography>
                            </div>
                        </ImageListItem>
                    )}
                    <li className={classes.lastLi} />
                </ul>
            </div>
        );
    }
}

export default withGStyles(styles)(PercentageSlider);
