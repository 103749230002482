import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';
import { compose } from 'redux';
import { normalizeTz } from '../../../services';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';

import {
    LocationUX,
    LongAddress,
    NullLongAddress,
    LocationRequest,
    addressFromLongAddress
} from '../../../shared/types';

import AddLocationView, { AddressWithTimezone } from './AddLocationView';
import { addLocation, updateLocation, deleteLocation } from '../../../actions/Location.action';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { AppDispatch } from '../../../store';
import { SlideTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 600px)': {
            fontSize: 34,
        }
    },
    header: {
        zIndex: 1,
        padding: '8px 36px 8px 14px',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        '& p': {
            width: 'calc(100vw - 56px)',
            '@media (min-width: 600px)': {
                width: 'calc(560px - 62px)',
            }
        }
    },
    addLocationDialog: {
        '& $dialogPaper': {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            '@media (min-width: 960px)': {
                maxWidth: 720,
                margin: 32,
                maxHeight: '90vh',
            }
        },
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 24px',
        width: 280,
        margin: ' auto',
        '@media (min-width: 421px)': {
            width: '360px',
        },
        '@media (min-width: 600px)': {
            width: '468px',
            margin: 'auto',
        },
        textAlign: 'center',
    },
    dialogPaper: {
        '@media (min-width: 600px)': {
            minHeight: '510px'
        }
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    gmapSearch: {
        width: 280,
        maxWidth: 280,
        '@media (min-width: 421px)': {
            width: 360,
            maxWidth: 360,
        },
        '@media (min-width: 600px)': {
            width: 460,
            maxWidth: 460,
        },
    },
    gmapSearchSuggestionBox: {
        position: 'absolute',
        zIndex: 1,
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        '@media (min-width: 600px)': {
            fontSize: 28,
        }
    },
});

interface Props {
    isDialogOpen: boolean;
    funeralHomeId: number;
    zIndex: number;
    existingLocation?: LocationUX;
    isEventCreation?: boolean;
    closeDialog: (savedLocation?: LocationUX) => void;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    longAddress: LongAddress;
    tzSelected: string;
    isLocationSavedForFuture: boolean;
    isLocationSaving: boolean;
    isSaveClicked: boolean;
    locationName: string;
    useAddressDescription: boolean;
}

type StyledProps = Props & { dispatch: AppDispatch } & WithGStyles<'root' | 'dialogPaper' | 'addLocationDialog'
    | 'dRoot' | 'header' | 'dialogContent' | 'clearIcon' | 'bottomContent' | 'gmapSearch' | 'gmapSearchSuggestionBox'
    | 'headerText'>;

const INITIAL_STATE: State = {
    longAddress: {
        ...NullLongAddress
    },
    useAddressDescription: false,
    tzSelected: normalizeTz(momentTz.tz.guess() || 'America/Denver'),
    isLocationSavedForFuture: false,
    isLocationSaving: false,
    isSaveClicked: false,
    locationName: '',
};

class AddLocationDialog extends React.Component<StyledProps & DialogProps, State> {
    switchGridStyle = {
        marginLeft: '-15px',
        maxWidth: 'calc(100% + 15px)',
        flexBasis: 'calc(100% + 15px)',
    };

    constructor(props: StyledProps & DialogProps) {
        super(props);
        this.state = INITIAL_STATE;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: StyledProps) {
        const { existingLocation, isDialogOpen, isEventCreation } = nextProps;
        // on opening the dialog
        if (isDialogOpen && !this.props.isDialogOpen) {
            if (!existingLocation) {
                this.setState({
                    ...INITIAL_STATE,
                    isLocationSavedForFuture: INITIAL_STATE.isLocationSavedForFuture || !isEventCreation,
                });
            } else {
                const tzSelected = normalizeTz(
                    existingLocation.address.timezone || momentTz.tz.guess() || 'America/Denver'
                );
                this.setState({
                    longAddress: {
                        ...INITIAL_STATE.longAddress,
                        ...existingLocation.address.long_address,
                    },
                    tzSelected,
                    useAddressDescription: existingLocation.address.use_description,
                    isLocationSavedForFuture: existingLocation.is_saved || !isEventCreation,
                    locationName: existingLocation.name || ''
                });
            }
        }
    }

    clearDialog = () => {
        this.setState(INITIAL_STATE);
    };

    handleClose = (savedLocation?: LocationUX) => {
        const { existingLocation } = this.props;
        this.clearDialog();
        // return the existingLocation if the user closes the dialog without editing the location
        this.props.closeDialog(existingLocation && !savedLocation ? existingLocation : savedLocation);
    };

    saveForFutureChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isLocationSavedForFuture: event.target.checked,
        });
    };

    saveLocation = async () => {
        const { dispatch, funeralHomeId, existingLocation } = this.props;
        const { longAddress, tzSelected, isLocationSavedForFuture, locationName, useAddressDescription } = this.state;

        this.setState({ isLocationSaving: true });
        let savedLocation: LocationUX | null;

        if (existingLocation) {

            const changesToMake: LocationRequest = {
                name: locationName || null,
                timezone: tzSelected,
                long_address: longAddress,
                is_saved: isLocationSavedForFuture,
                use_address_description: useAddressDescription,
            };

            // immediately update the location and return
            dispatch(updateLocation(existingLocation, changesToMake, funeralHomeId));
            savedLocation = {
                ...existingLocation,
                name: changesToMake.name,
                is_saved: changesToMake.is_saved,
                address: {
                    ...existingLocation.address,
                    ...addressFromLongAddress(longAddress, tzSelected, useAddressDescription),
                },
            };
        } else {
            const locationToCreate: LocationRequest = {
                name: locationName || null,
                timezone: tzSelected,
                is_saved: isLocationSavedForFuture,
                long_address: longAddress,
                use_address_description: useAddressDescription,
            };
            savedLocation = await dispatch(addLocation(locationToCreate, funeralHomeId));
        }
        this.setState({ isLocationSaving: false });
        this.handleClose(savedLocation || undefined);
    };

    deleteLocation = async () => {
        const { dispatch, existingLocation, funeralHomeId } = this.props;

        if (!existingLocation) {
            return;
        }
        dispatch(deleteLocation(existingLocation, funeralHomeId));
        this.handleClose();
    };

    handleLocationChange = (addressWithTz: AddressWithTimezone) => {
        this.setState({
            longAddress: addressWithTz.longAddress,
            tzSelected: addressWithTz.timezone,
            useAddressDescription: addressWithTz.useAddressDescription,
            locationName: addressWithTz.locationName,
        });
    };

    handleTimezoneChange = (tz: string) => {
        this.setState({
            tzSelected: tz,
        });
    };

    render() {
        const {
            classes,
            isDialogOpen,
            fullScreen,
            isEventCreation,
            existingLocation,
            zIndex,
        } = this.props;

        const {
            isLocationSavedForFuture,
            isLocationSaving,
            longAddress,
            tzSelected,
            useAddressDescription,
            locationName,
        } = this.state;

        const hasLocation = (longAddress.city && longAddress.state)
            || (useAddressDescription && longAddress.description)
            ? true
            : false;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                onClose={() => this.handleClose()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.addLocationDialog}
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.dRoot,
                }}
                maxWidth="xs"
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.header,
                        classes.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon, classes.colorWhite)}
                        onClick={() => this.handleClose()}
                    />
                    <Typography
                        align="center"
                        component="p"
                        noWrap
                        className={classNames(classes.colorWhite, classes.headerText)}
                    >
                        Select an Event Location
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                        className={classes.marginTop10}
                    >
                        <AddLocationView
                            controlSuggestionClasses={classNames(classes.gmapSearchSuggestionBox)}
                            controlClasses={classNames(classes.gmapSearch)}
                            longAddress={longAddress}
                            isSaveClicked={this.state.isSaveClicked}
                            timezone={tzSelected}
                            onLocationChange={this.handleLocationChange}
                            isAutoFocus
                            zIndex={zIndex + 1}
                            useAddressDescription={useAddressDescription}
                            nameValue={locationName}
                        />
                        <Grid
                            className={classes.bottomContent}
                            item
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.margin_8}
                                onClick={hasLocation ? this.saveLocation :
                                    () => this.setState({ isSaveClicked: true })}
                                disabled={isLocationSaving}
                            >
                                {isEventCreation ? 'USE THIS LOCATION' : 'SAVE LOCATION'}
                                {isLocationSaving &&
                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                }
                            </Button>
                            {!isEventCreation && existingLocation &&
                                <Button
                                    size="large"
                                    color="secondary"
                                    className={classes.margin_8}
                                    onClick={this.deleteLocation}
                                    disabled={isLocationSaving}
                                >
                                    {'DELETE LOCATION'}
                                </Button>
                            }
                            {isEventCreation &&
                                <FormControlLabel
                                    className={classes.textLeft}
                                    control={
                                        <Checkbox
                                            checked={isLocationSavedForFuture}
                                            onChange={this.saveForFutureChanged}
                                            value="saveForFuture"
                                            color="primary"
                                        />
                                    }
                                    label="Save this location for future events"
                                />
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}


export default compose(
    withState(),
    withGStyles(styles),
    withFullScreen('sm')
)(AddLocationDialog) as React.ComponentType<Props>;
