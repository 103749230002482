import { GatherAction } from '../actions';
import { DOC_PACKET_TABLE } from '../actions/DocPacketTable.action';
import { CLEAR_ACTIVE_FUNERALHOME } from '../actions/FuneralHome.action';
import { DocPacketTableReduxState } from '../types';

export const docPacketTableReduxInitData: DocPacketTableReduxState = {
    data: [],
    hasMoreData: true,
    searchText: '',
    sortBy: 'created_time',
    sortDirection: 'desc',
    isLoading: false,
    totalDocPacketCount: 0,
};

export const docPacketTableState = (
    state: DocPacketTableReduxState = docPacketTableReduxInitData,
    action: GatherAction,
): DocPacketTableReduxState => {

    switch (action.type) {
    case CLEAR_ACTIVE_FUNERALHOME: {
        return docPacketTableReduxInitData;
    }
    case DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOADED: {
        const updatedData = action.offset === 0 ?
            [...action.data] : [...state.data, ...action.data];
        return {
            ...state,
            data: updatedData,
            isLoading: false,
            hasMoreData: action.hasMoreData,
        };
    }
    case DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOADING:
        return {
            ...state,
            data: action.offset === 0 ? [] : state.data,
            searchText: action.searchText,
            sortDirection: action.sortDirection,
            sortBy: action.sortBy,
            isLoading: true,
        };
    case DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOAD_FAILED:
        return {
            ...state,
            isLoading: false,
        };
    case DOC_PACKET_TABLE.SET_TOTAL_DOC_PACKET_COUNT:
        return {
            ...state,
            totalDocPacketCount: action.count
        };
    default:
        return state;
    }
};