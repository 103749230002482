import { Component } from 'react';
import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';

import {
    LongAddress,
    NullLongAddress,
    OrganizationDisplayTypes,
    RolodexOrganization,
    OrganizationTypes,
    GmapsSearchType,
    isLongAddress
} from '../../../shared/types';
import PhoneNumberMask from '../../common/PhoneNumberMask';
import GSwitch from '../../common/inputElements/GSwitch';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GmapsSearch, { GmapsSearchAddress } from '../../gmapsSearch/GmapsSearch';
import { isEqual, toPairs } from 'lodash';

type StyledProps = WithStyles<
    'organizationSection' | 'label' | 'switchContainer' | 'switchLabel' | 'width100' |
    'marginTop8' | 'textLeft' | 'width55' | 'selectFormControl' | 'organizationFooterSection' |
    'widthInherit' | 'formControlStyles' | 'controlSuggestionClass' | 'gmapSearch' | 'width40' |
    'contactDetailSection' | 'width45' | 'nameLabel' | 'nameLabelFocused' | 'select'
>;

interface Props {
    orgData: Partial<RolodexOrganization> | null;
    zIndex: number;
    orgDisabled: boolean;
    orgEnableDisableCallback: (isChecked: boolean) => void;
    fieldChangeEventHandler: (
        fieldName: keyof RolodexOrganization | string,
        value: string | LongAddress | boolean | null
    ) => void;
}

// all incoming contact data is optional
type OrganizationEntryProps = Props & StyledProps;

interface OrganizationEntryState {
    useOrg: boolean;
    name: string;
    type: OrganizationTypes | null;
    other_text: string;
    longAddress: LongAddress | null;
    useDescription: boolean;
    email: string;
    phone: string;
    fax_number: string;
}

const INIT_EMPTY_OR_NULL_ORG = {
    name: '',
    type: null,
    other_text: '',
    longAddress: null,
    useDescription: false,
    email: '',
    phone: '',
    fax_number: ''
};

class OrganizationEntry extends Component<OrganizationEntryProps, OrganizationEntryState> {
    constructor(props: OrganizationEntryProps) {
        super(props);

        const { orgData, orgDisabled } = props;

        if (orgData === null) {
            this.state = {
                useOrg: !orgDisabled,
                ...INIT_EMPTY_OR_NULL_ORG
            };
        } else {
            this.state = {
                useOrg: !orgDisabled,
                name: orgData.name || '',
                type: orgData.type || null,
                other_text: orgData.other_text || '',
                longAddress: orgData.longAddress || null,
                useDescription: orgData.useAddressDescription || false,
                email: orgData.email || '',
                phone: orgData.phone || '',
                fax_number: orgData.fax_number || ''
            };
        }
    }

    componentDidUpdate(prevProps: OrganizationEntryProps) {
        const { orgData, orgDisabled } = this.props;

        if (orgData !== null && !isEqual(orgData, prevProps.orgData)) {
            this.setState({
                useOrg: !orgDisabled,
                name: orgData.name || '',
                type: orgData.type || null,
                other_text: orgData.other_text || '',
                longAddress: orgData.longAddress || null,
                email: orgData.email || '',
                phone: orgData.phone || '',
                fax_number: orgData.fax_number || ''
            });
        }
    }

    resetInputFields = () => {
        for (const key of Object.keys(this.state)) {
            if (key === 'useOrg') {
                continue;
            }

            if (key === 'type') {
                this._updateStateField(key, null);
                continue;
            }

            if (key === 'longAddress') {
                this._updateStateField(key, null);
                continue;
            }

            this._updateStateField(key, '');
        }
    };

    handleUseOrgChange = (isChecked: boolean) => {
        const { orgEnableDisableCallback } = this.props;

        this.setState({ useOrg: isChecked });

        orgEnableDisableCallback(isChecked);
    };

    render() {
        const { classes, zIndex } = this.props;
        const {
            useOrg,
            name,
            type,
            longAddress: address,
            useDescription,
            email,
            phone,
            fax_number,
            other_text
        } = this.state;

        const menuItems = [['choose', 'choose'], ...toPairs(OrganizationDisplayTypes)].map(([key, value]) => {
            if (key === 'choose') {
                return <MenuItem
                    value=""
                    key={key}
                >Choose a type of Organization</MenuItem>;
            }

            return <MenuItem value={key} key={key}>{value}</MenuItem>;
        });

        return (
            <Grid item xs={12} className={classes.organizationSection}>
                <Grid item className={classes.label}>
                    <Typography color="primary" align="center">
                        Organization
                    </Typography>
                </Grid>

                <Grid color="secondary" className={classes.switchContainer}>
                    <GSwitch
                        label={'Rolodex Entry is associated with an Organization'}
                        checked={useOrg}
                        color="secondary"
                        labelClasses={classes.switchLabel}
                        onChangeCallBack={this.handleUseOrgChange}
                    />
                </Grid>
                {useOrg &&
                    <>
                        <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                            <FormControl
                                className={classNames(classes.width100, classes.textLeft)}
                            >
                                <TextField
                                    label="Organization Name"
                                    name="organization-name"
                                    required
                                    value={name}
                                    className={classes.width100}
                                    onChange={(e) => this._updateStateField('name', e.target.value)}
                                    autoComplete="organization-name"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className={classNames(classes.contactDetailSection)}>
                            <Grid item className={classNames(classes.marginTop8, classes.width45)}>
                                <FormControl className={classes.selectFormControl}>
                                    <InputLabel
                                        htmlFor="select-organization-type"
                                        classes={{ focused: classes.nameLabelFocused }}
                                        className={classNames(classes.nameLabel, classes.select)}
                                    >
                                        Organization Type
                                    </InputLabel>
                                    <Select
                                        onChange={(e) => {
                                            if (typeof e.target.value === 'string') {
                                                this._updateStateField(
                                                    'type',
                                                    OrganizationTypes[e.target.value]
                                                );
                                            }
                                        }}
                                        value={type || ''}
                                        MenuProps={{ style: { zIndex: zIndex + 3 } }}
                                        inputProps={{
                                            name: 'select-organization-type',
                                            id: 'select-organization-type',
                                            className: classNames(!type && 'notranslate')
                                        }}
                                    >
                                        {menuItems}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item className={classNames(classes.marginTop8, classes.width45)}>
                                <FormControl className={classes.selectFormControl}>
                                    <TextField
                                        label="Custom Organization Type"
                                        name="organization-other-type"
                                        value={other_text}
                                        className={classes.width100}
                                        onChange={(e) => this._updateStateField('other_text', e.target.value)}
                                        autoComplete="organization-other-type"
                                        disabled={this.state.type !== OrganizationTypes.other}
                                        required={this.state.type === OrganizationTypes.other}
                                        InputLabelProps={{
                                            className: classes.nameLabel,
                                            classes: { focused: classes.nameLabelFocused }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                            <FormControl
                                className={classNames(classes.width100, classes.textLeft)}
                            >
                                <GmapsSearch
                                    formControlStyleClasses={classes.formControlStyles}
                                    controlClasses={classNames(classes.gmapSearch)}
                                    controlSuggestionClasses={classNames(classes.controlSuggestionClass)}
                                    textLabel={'Organization Address'}
                                    type={GmapsSearchType.longAddress}
                                    value={address || NullLongAddress}
                                    onSetPlace={(addressResult: GmapsSearchAddress, useAddressDescription: boolean) => {
                                        if (isLongAddress(addressResult)) {
                                            this._updateStateField('longAddress', addressResult);
                                            this._updateStateField('useDescription', useAddressDescription);
                                        }
                                    }}
                                    error={false}
                                    required={false}
                                    disabled={false}
                                    zIndex={zIndex + 1}
                                    useDescription={useDescription}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                            <FormControl
                                className={classNames(classes.width100, classes.textLeft)}
                            >
                                <TextField
                                    label="Organization Email"
                                    name="organization-email"
                                    value={email}
                                    className={classes.width100}
                                    onChange={(e) => this._updateStateField('email', e.target.value)}
                                    autoComplete="organization-email"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className={classes.organizationFooterSection}>
                            <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                                <FormControl className={classNames(classes.textLeft)}>
                                    <TextField
                                        label="Organization Phone"
                                        name="organization-phone"
                                        value={phone}
                                        onChange={(e) => this._updateStateField('phone', e.target.value)}
                                        autoComplete="organization-phone"
                                        InputProps={{
                                            inputComponent: PhoneNumberMask
                                        }}
                                        InputLabelProps={{
                                            className: classes.nameLabel,
                                            classes: { focused: classes.nameLabelFocused }
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classNames(classes.width100, classes.marginTop8)}>
                                <FormControl className={classNames(classes.textLeft, classes.widthInherit)}>
                                    <TextField
                                        label="Organization Fax"
                                        name="organization-fax"
                                        value={fax_number}
                                        onChange={(e) => this._updateStateField('fax_number', e.target.value)}
                                        autoComplete="organization-fax"
                                        InputProps={{
                                            inputComponent: PhoneNumberMask
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
        );
    }

    private _updateStateField = (
        fieldName: keyof RolodexOrganization | string,
        data: string | LongAddress | boolean | null
    ) => {
        const { orgData, fieldChangeEventHandler } = this.props;
        const updateObj = {};
        updateObj[fieldName] = data;
        this.setState(updateObj);

        if (orgData !== null) {
            fieldChangeEventHandler(fieldName, data);
        }
    };
}

export default OrganizationEntry;
