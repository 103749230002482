import { useMemo, useState } from 'react';
import partition from 'lodash/partition';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { lighten } from '@mui/material/styles';

import PostAddIcon from '@mui/icons-material/PostAdd';
import AddIcon from '@mui/icons-material/Add';

import { TrackingItem, TrackingItemStatus, TrackingItemType } from '../BodyTrackingContent';
import { useQRStepperCommonStyles } from '.';
import SelectLocation from './selectLocation/SelectLocation';
import { GatherCaseUX, TaskLocationUX } from '../../../../shared/types';
import PendingStep from './PendingStep';
import { TrackingContentContext } from '../dialogs/completeStepDialog/CompleteStep.dialog';
import { useGSelector } from '../../../../types';
import MoveLocationDialog from '../dialogs/moveLocationDialog/MoveLocation.dialog';
import QRPendingStepsMenu from './QRPendingStepsMenu';
import { log } from '../../../../logger';
import makeGStyles from '../../../../styles/makeGStyles';
import { SUCCESS_COLOR } from '../../../../constants/colorVariables';
import InitializeKeepTrackButton from './InitializeKeepTrackButton';
import { getIntercomTargetProp } from '../../../../services';

export const useStyles = makeGStyles(
    (theme) => ({
        pendingStepsHeader: {
            width: 'fit-content',
            margin: '0 auto',
            '& button': {
                textAlign: 'center',
                marginTop: 40,
                textTransform: 'none',
                '& p': {
                    lineHeight: '18px',
                },
            },
        },
        completedStepsText: {
            textAlign: 'center',
            margin: '8px 0',
            '& p': {
                lineHeight: '18px',
                color: SUCCESS_COLOR,
            },
        },
        pendingStepsBtn: {
            padding: '8px 0 8px 8px',
            textTransform: 'none',
            textAlign: 'left',
            '@media (min-width: 600px)': {
                marginLeft: 4,
            },
            '& p': {
                width: 'fit-content',
                lineHeight: '20px',
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
            '& p.disabled': {
                cursor: 'default',
                opacity: '30%',
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        },
        location: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 14,
            },
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
            '@media (min-width: 420px)': {
                marginLeft: 8,
            },
        },

        finalizeWorkflow: {
            minWidth: 300,
            border: `2px dashed ${theme.palette.secondary.main} !important`,
            borderRadius: 16,
            padding: '8px 20px',
            textAlign: 'center',
            textTransform: 'none',
            flexDirection: 'column',
            fontWeight: 400,
            lineHeight: 1,
            background: '#fff',
            '&:hover': {
                background: lighten(theme.palette.secondary.main, 0.96),
            },
        },
        borderRadius8: {
            borderRadius: 8,
        },
        addButtons: {
            minWidth: '28px !important',
            minHeight: '28px !important',
            '& button': {
                width: 24,
                height: 24,
            },
            '& svg': {
                fontSize: '20px !important',
            },
        },
        addActionButton: {
            marginLeft: 4,
            padding: 4,
            '@media (min-width: 375px)': {
                marginLeft: 4,
            },
            '@media (min-width: 600px)': {
                marginLeft: -8,
            },
        },
        buttonTextFontsize: {
            fontSize: '14px !important',
            '@media (min-width: 400px)': {
                fontSize: '16px !important',
            },
        },
        subLocation: {
            display: 'flex',
            alignItems: 'unset',
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
        },
        stepIcon: {
            minWidth: 24,
            minHeight: 24,
            padding: 0,
        },
        addMedallion: {
            border: 'none !important',
            borderRadius: '50% !important',
            '& button, img': {
                minWidth: 'unset',
                minHeight: 'unset',
                width: 28,
                height: 28,
                borderRadius: 'inherit',
            },
        },
        note: {},
        isSkipped: {},
    }),
    { name: 'QRPendingSteps' },
);

interface Props {
    zIndex: number;
    steps: TrackingItem[];
    selectedCase: GatherCaseUX;
    toggleBulkEditMode: VoidFunction;
    openNotesDialog: () => void;
    openAllStepsDialog: () => void;
    openSkipStepDialog: (stepId: number) => void;
    onDueDateClick: (stepId: number) => void;
    openWorkflowSelectorDialog: () => void;
}
const QRPendingSteps = (props: Props) => {
    const {
        steps,
        openNotesDialog,
        openAllStepsDialog,
        selectedCase,
        zIndex,
        toggleBulkEditMode,
        openSkipStepDialog,
        openWorkflowSelectorDialog,
        onDueDateClick,
    } = props;

    const commonClasses = useQRStepperCommonStyles();
    const classes = useStyles();

    const userProfile = useGSelector(({ userSession }) => userSession.userData);

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const [activeStep, setActiveStep] = useState<TrackingItem | null>(null);

    const [targetLocation, setTargetLocation] = useState<TaskLocationUX | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const onTaskLocationSelect = (location: TaskLocationUX) => {
        setTargetLocation(location);
        setDialogOpen(true);
    };

    const addNoteItem: TrackingItem = {
        key: `addnote`,
        label: 'Add a Note',
        secondaryText: null,
        finishedBy: null,
        finishedTime: null,
        durationsInLocation: null,
        status: TrackingItemStatus.pending,
        itemType: TrackingItemType.note,
        icon: <PostAddIcon color="inherit" fontSize="small" style={{ fontSize: '20px !important' }} />,
        onClick: openNotesDialog,
        isVisibleToFamily: true,
    };

    const addStepItem: TrackingItem = {
        key: `addaction`,
        label: `Add an action to ${selectedCase.fname}'s WorkFlow`,
        secondaryText: null,
        finishedBy: null,
        finishedTime: null,
        durationsInLocation: null,
        status: TrackingItemStatus.pending,
        itemType: TrackingItemType.add_action,
        icon: <AddIcon />,
        onClick: openAllStepsDialog,
        isVisibleToFamily: true,
    };

    const [normalSteps, otherSteps] = useMemo(
        () =>
            partition(
                steps,
                (s) => s.itemType !== TrackingItemType.initialize_step && s.itemType !== TrackingItemType.finalize_step,
            ),
        [steps],
    );

    const initializeStep = otherSteps.find((s) => s.itemType === TrackingItemType.initialize_step);
    const finalizeStep = otherSteps.find((s) => s.itemType === TrackingItemType.finalize_step);

    const isTrackingInitialized = !!selectedCase.keeptrack_assigned_time;

    if (otherSteps.length > 2) {
        log.warn('Case has multiple intialize or finalize steps', { steps, otherSteps });
    }

    if (!userProfile) {
        return null;
    }

    return (
        <>
            <PendingStep
                key="addnote"
                onMenuClick={(anchor) => {
                    setActiveStep(addNoteItem);
                    setMenuAnchor(anchor);
                }}
                step={addNoteItem}
                isLast={false}
                zIndex={zIndex}
                selectedCase={selectedCase}
                intercomTargetProp={`TrackingPage-TrackTab-AddNote`}
            />

            {!isTrackingInitialized && (
                <InitializeKeepTrackButton
                    caseFname={selectedCase.fname}
                    caseName={selectedCase.name}
                    funeralHomeKey={selectedCase.funeral_home.key}
                    hasInitializeStep={!!initializeStep}
                    workFlowName={selectedCase.workflow_name}
                    onWorkFlowSelectClick={() => openWorkflowSelectorDialog()}
                />
            )}

            {isTrackingInitialized && (
                <>
                    {initializeStep && (
                        <Grid item xs={12} pl={{ xs: '8px', sm: 0 }}>
                            <Grid container flexDirection="column" alignItems="flex-start">
                                <Button
                                    color="secondary"
                                    className={classes.finalizeWorkflow}
                                    onClick={initializeStep.onClick}
                                >
                                    <span className={classes.fontSize20}>{initializeStep.label}</span>
                                    {selectedCase.workflow_name && (
                                        <span className={classes.fontSize12}>{selectedCase.workflow_name}</span>
                                    )}
                                </Button>

                                <Grid container maxWidth={52} justifyContent="center">
                                    <div className={commonClasses.stepConnector} style={{ height: 32 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <SelectLocation
                        caseFName={selectedCase.fname}
                        context={TrackingContentContext.standalone}
                        currentLocation={selectedCase.current_task_location}
                        selectedLocation={selectedCase.current_task_location}
                        funeralHomeId={selectedCase.funeral_home_id}
                        onTaskLocationSelect={onTaskLocationSelect}
                        caseUuid={selectedCase.uuid}
                        intercomTargetProp={`TrackingPage-TrackTab-Location`}
                        intercomTargetPropContainer={`TrackingPage-TrackTab-SelectLocationContainer`}
                    />

                    {normalSteps.map((step) => (
                        <PendingStep
                            key={step.key}
                            onMenuClick={(anchor) => {
                                setActiveStep(step);
                                setMenuAnchor(anchor);
                            }}
                            step={step}
                            isLast={false}
                            zIndex={zIndex}
                            selectedCase={selectedCase}
                            intercomTargetProp={`TrackingPage-TrackTab`}
                            onDueDateClick={onDueDateClick}
                        />
                    ))}

                    <PendingStep
                        key="addstep"
                        step={addStepItem}
                        isLast={true}
                        zIndex={zIndex}
                        selectedCase={selectedCase}
                        intercomTargetProp={`TrackingPage-TrackTab-AddStep`}
                    />

                    {finalizeStep && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.finalizeWorkflow}
                            onClick={finalizeStep.onClick}
                            {...getIntercomTargetProp(`TrackingPage-TrackTab-Finalize`)}
                        >
                            <span className={classes.fontSize20}>{finalizeStep.label}</span>
                            {selectedCase.workflow_name && (
                                <span className={classes.fontSize12}>{selectedCase.workflow_name}</span>
                            )}
                        </Button>
                    )}
                </>
            )}

            {targetLocation && (
                <MoveLocationDialog
                    fhName={selectedCase.funeral_home.name}
                    user={userProfile}
                    caseFName={selectedCase.fname}
                    caseUuid={selectedCase.uuid}
                    currentLocationSummary={selectedCase.current_task_location}
                    currentLocation={selectedCase.current_task_location || null}
                    targetLocation={targetLocation}
                    zIndex={zIndex + 1}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                />
            )}

            {activeStep && (
                <QRPendingStepsMenu
                    zIndex={zIndex}
                    selectedCase={selectedCase}
                    activeStep={{
                        label: activeStep.label,
                        stepId: activeStep.stepId,
                        completedByTime: activeStep.completedByTime,
                        onClick: activeStep.onClick,
                    }}
                    menuAnchor={menuAnchor}
                    closeMenu={() => setMenuAnchor(null)}
                    openSkipStepDialog={openSkipStepDialog}
                    toggleBulkEditMode={toggleBulkEditMode}
                    onDueDateClick={onDueDateClick}
                />
            )}
        </>
    );
};

export default QRPendingSteps;
