import { useState } from 'react';
import { Grid, Typography } from "@mui/material";
import BulkEditDialogFooter from "./BulkEditDialogFooter";
import { useGDispatch } from "../../../../../types";
import { setSnackbarSuccess } from "../../../../../actions/AppSnackbar.action";
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductUX } from '../../../../../shared/types';

interface ProductDeleteDialogContentProps {
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const ProductDeleteDialogContent = (props: ProductDeleteDialogContentProps) => {
    const { selectedProductIds, onSubmitClick, funeralHomeId } = props;

    const dispatch = useGDispatch();
    const selectedProductsCount = selectedProductIds.length;
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitclick = async () => {
        if (!selectedProductIds?.length || typeof funeralHomeId !== 'number') {
            return;
        }
        setIsSpinning(true);
        // const updatedProducts = await dispatch(bulkDeleteProducts({funeralHomeId, productIds: selectedProductIds}) );
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.delete_selected_products,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: {},
        }));
        setIsSpinning(false);
        dispatch(setSnackbarSuccess(`${selectedProductIds.length} have been deleted`));
        onSubmitClick(updatedProducts);
    };

    return (
        <>
            <Grid container textAlign='center' mt={1}>
                <Typography color='primary' fontSize={16}>
                    Are you sure you want to delete your {selectedProductsCount} selected
                    product{selectedProductsCount === 1 ? '' : 's'}?
                </Typography>
                <Typography fontSize={14} color='secondary' pt={0.5}>
                    This will permanently delete these products from your collection of goods and services.
                </Typography>
                <Typography fontSize={14} color='secondary' pt={2}>
                    Please Note: if instead of deleting you would like to make these items not visible on your public
                    GPL, but NOT delete them, you should instead use the Show/Hide on Public GPL edit option.
                </Typography>
            </Grid>

            <BulkEditDialogFooter
                selectedProductsCount={selectedProductsCount}
                onSubmitClick={handleSubmitclick}
                disableSubmitButton={false}
                isSpinning={isSpinning}
            />

        </>
    );
};

export default ProductDeleteDialogContent;