import {
    DeathCertificateWorkHistoryType,
    DeathCertificateConfigUX,
    UserProfile,
    OccupationListOfOptions,
} from '../../../shared/types';
import { isFieldOptional, ConfigurableWorkHistoryFieldKey } from './config';

export const workHistoryValidators = {
    occupation: function(
        { occupationListOfOptions }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ) {
        const isValid = occupationListOfOptions && typeof occupationListOfOptions === 'string';

        if (isValid) {
            return true;
        }
    
        if (isFieldOptional(ConfigurableWorkHistoryFieldKey.occupationListOfOptions, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    normalOccupation: function(
        { normalOccupation, normalOccupation_unknown, occupationListOfOptions }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ) {
        const isOptionalFieldSelected = occupationListOfOptions === OccupationListOfOptions.Employed
            || occupationListOfOptions === OccupationListOfOptions.Retired
            || occupationListOfOptions === OccupationListOfOptions['Not Employed'];
        const isValid = isOptionalFieldSelected
            && ((normalOccupation && normalOccupation.length > 0) || normalOccupation_unknown);

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableWorkHistoryFieldKey.normalOccupation, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    normalOccupationIndustry: function (
        {
            normalOccupationIndustry,
            normalOccupationIndustry_unknown,
            occupationListOfOptions
        }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isOptionalFieldSelected = occupationListOfOptions === OccupationListOfOptions.Employed
            || occupationListOfOptions === OccupationListOfOptions.Retired
            || occupationListOfOptions === OccupationListOfOptions['Not Employed'];
        const isValid = normalOccupationIndustry && normalOccupationIndustry.length > 0;
        if (isOptionalFieldSelected && (isValid || normalOccupationIndustry_unknown)) {
            return true;
        }

        if (isFieldOptional(ConfigurableWorkHistoryFieldKey.kindOfBusiness, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    currentEmployer: function (
        { currentEmployer, occupationListOfOptions }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isEmployed = occupationListOfOptions === OccupationListOfOptions.Employed;
        const isValid = currentEmployer && currentEmployer.length > 0;

        if (isValid || !isEmployed || isFieldOptional(ConfigurableWorkHistoryFieldKey.currentEmployer, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    yearsInOccupation: function(
        { yearsInOccupation, occupationListOfOptions }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isEmployed = occupationListOfOptions === OccupationListOfOptions.Employed;
        const isValid = yearsInOccupation && yearsInOccupation.length > 0;

        if (isValid
            || !isEmployed
            || isFieldOptional(ConfigurableWorkHistoryFieldKey.yearsInOccupation, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    retiredYear: function (
        { retiredInYear, occupationListOfOptions }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isRetired = occupationListOfOptions === OccupationListOfOptions.Retired;
        const isValid = retiredInYear && retiredInYear.length > 0;

        if (isValid || !isRetired || isFieldOptional(ConfigurableWorkHistoryFieldKey.retiredInYear, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    retiredLastEmployer: function (
        { retiredLastEmployer, occupationListOfOptions }: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isRetired = occupationListOfOptions === OccupationListOfOptions.Retired;
        const isValid = retiredLastEmployer && retiredLastEmployer.length > 0;

        if (isValid
            || !isRetired
            || isFieldOptional(ConfigurableWorkHistoryFieldKey.retiredLastEmployer, config, user)) {
            return !touchCheck;
        }

        return false;
    }
};

export const workHistoryMasterValidators = {
    valid: function(
        workHistoryForm: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
    ) {
        if (workHistoryForm.occupationListOfOptions === OccupationListOfOptions['Never Employed']) {
            return true;
        }

        return workHistoryValidators.currentEmployer(workHistoryForm, config, user)
            && workHistoryValidators.yearsInOccupation(workHistoryForm, config, user)
            && workHistoryValidators.retiredYear(workHistoryForm, config, user)
            && workHistoryValidators.retiredLastEmployer(workHistoryForm, config, user)
            && workHistoryValidators.normalOccupation(workHistoryForm, config, user)
            && workHistoryValidators.normalOccupationIndustry(workHistoryForm, config, user)
            && workHistoryValidators.occupation(workHistoryForm, config, user);
    },
    touched: function(
        workHistoryForm: DeathCertificateWorkHistoryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
    ) {
        return workHistoryValidators.occupation(workHistoryForm, config, user, true) ||
            workHistoryValidators.normalOccupation(workHistoryForm, config, user, true) ||
            workHistoryValidators.normalOccupationIndustry(workHistoryForm, config, user, true) ||
            workHistoryValidators.currentEmployer(workHistoryForm, config, user, true) ||
            workHistoryValidators.yearsInOccupation(workHistoryForm, config, user, true) ||
            workHistoryValidators.retiredYear(workHistoryForm, config, user, true) ||
            workHistoryValidators.retiredLastEmployer(workHistoryForm, config, user, true);
    },
};