import { Component } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Clear from '@mui/icons-material/Clear';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import TaxManagement from '../../../funeralHomes/goodsAndServices/TaxManagement';
import {
    GatherCaseUX,
    ProductTaxRateUX,
    ContractTaxationMethod,
    ProductContractUX,
    ProductContractRequest,
    ContractOptionsUX,
} from '../../../../shared/types';
import { FuneralHomeRoutePage, getFullNameFromCase, lifeSpan, RouteBuilder } from '../../../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';
import GLink from '../../../common/GLink';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        borderRadius: 12,
        '& $dialogPaper': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: 'none',
            height: '100vh',
            maxHeight: 'none',
            borderRadius: 0,
            margin: '48px auto',
            padding: 0,
            '@media (min-width: 600px)': {
                maxWidth: 520,
                width: 520,
                borderRadius: 12,
                height: 'auto'
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    headerContent: {
        textAlign: 'center',
        color: theme.palette.common.white,
        '& svg': {
            fontSize: 84,
            color: 'inherit',
        },
        '& $taxInformation': {
            fontSize: 28,
            color: 'inherit',
            lineHeight: '1em'
        },
        '& $personName': {
            color: 'inherit',
            fontSize: 20,
            fontWeight: 200,
            marginTop: 8,
        },
        '& $date': {
            color: 'inherit',
            fontWeight: 200,
            lineHeight: '1em',
            textTransform: 'uppercase',
            marginBottom: 8,
        }
    },
    footer: {
        textAlign: 'center',
        marginBottom: 12,
        '& button': {
            padding: '12px 24px',
            marginBottom: 8
        },
        '& a': {
            color: theme.palette.primary.main,
        }
    },
    dialogInnerContent: {
        padding: '24px 24px 0',
        '& p': {
            fontSize: 16,
        }
    },
    toggleButtonGroupLabel: {
        position: 'relative',
        transform: 'none',
        textAlign: 'center',
    },
    dialogPaper: {},
    taxInformation: {},
    personName: {},
    date: {}
});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon' | 'heading' | 'headerContent'
        | 'taxInformation' | 'personName' | 'date' | 'footer' | 'dialogInnerContent' | 'toggleButtonGroupLabel'>;

interface Props {
    activeCase: GatherCaseUX;
    activeContract: ProductContractUX;
    isDialogOpen: boolean;
    taxRates: ProductTaxRateUX[];
    contractOptions: ContractOptionsUX;
    isGOMUser: boolean;
    closeDialog: () => void;
    updateContract: (contractRequest: ProductContractRequest) => void;
    onSeeTaxBreakdown: () => void;
    zIndex: number;
}

interface State {
    selectedContractTaxRateId: number | null;
    selectedTaxationMethod: ContractTaxationMethod | null;
    isSaveClicked: boolean;
}

const DEFAULT_STATE = {
    selectedContractTaxRateId: null,
    selectedTaxationMethod: null,
    isSaveClicked: false,
};

export class TaxManagementDialog extends Component<StyledProps, State> {
    state: State = DEFAULT_STATE;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { activeContract, contractOptions } = nextProps;
        this.setState({
            selectedTaxationMethod: activeContract.taxation_method,
            selectedContractTaxRateId: activeContract.tax_rate_id || contractOptions.default_contract_tax_rate,
        });
    }

    render() {
        const {
            classes,
            isDialogOpen,
            activeCase,
            taxRates,
            zIndex,
            isGOMUser,
        } = this.props;
        const {
            isSaveClicked,
            selectedContractTaxRateId,
            selectedTaxationMethod,
        } = this.state;

        return (
            <Dialog
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={() => this.closeDialog()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                scroll={'body'}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.dialogHeader,
                        classes.backgroundPrimary
                    )}
                >
                    <div className={classes.headerContent}>
                        <AccountBalanceIcon />
                        <Typography className={classes.taxInformation}>Tax Information</Typography>

                        <Typography className={classes.personName}>
                            {getFullNameFromCase(activeCase)}
                        </Typography>

                        <Typography className={classes.date}>
                            {lifeSpan(activeCase)}
                        </Typography>
                    </div>
                    <Clear
                        className={classes.clearIcon}
                        onClick={e => this.closeDialog()}
                    />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid item xs={12} className={classes.textCenter}>
                        <div className={classes.dialogInnerContent}>
                            <Typography color="secondary" className={classes.fontWeight200}>
                                How should {activeCase.fname}'s statement be taxed?
                            </Typography>

                            <TaxManagement
                                contractTaxRateId={selectedContractTaxRateId}
                                taxationMethod={selectedTaxationMethod}
                                isDialog
                                taxRates={taxRates}
                                isGatherUser={false}
                                isSaveClicked={isSaveClicked}
                                onContractTaxRateChange={this.handleContractTaxRateChange}
                                onTaxationMethodChange={this.handleTaxationMethodChange}
                            />

                        </div>
                    </Grid>
                </DialogContent>
                <div className={classes.footer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={e => this.saveChanges()}
                    >
                        <AccountBalanceIcon />
                        &nbsp;SAVE CHANGES
                    </Button>
                    {isGOMUser &&
                        <Typography
                            color="primary"
                            className={classNames(
                                classes.fontWeight200,
                                classes.textUnderline,
                                classes.cursorPointer,
                            )}
                            onClick={this.onSeeTaxBreakdown}
                        >
                            See Tax breakdown (only visible to Gather)
                        </Typography>
                    }
                    <Typography color="primary" className={classes.fontWeight200}>
                        You can adjust your default tax settings from&nbsp;
                        <GLink
                            to={RouteBuilder.FuneralHome(
                                activeCase.funeral_home.key,
                                FuneralHomeRoutePage.GOODSANDSERVICES,
                            )}
                            linkClass={classes.textUnderline}
                        >
                            this page
                        </GLink>

                    </Typography>
                </div>
            </Dialog>
        );
    }

    saveChanges = () => {
        const { activeContract, updateContract } = this.props;
        const { selectedContractTaxRateId, selectedTaxationMethod } = this.state;

        this.setState({ isSaveClicked: true });

        if (!selectedTaxationMethod || !selectedContractTaxRateId && selectedTaxationMethod === 'contract_basis') {
            return;
        }

        if (activeContract.taxation_method !== selectedTaxationMethod
            || activeContract.tax_rate_id !== selectedContractTaxRateId) {
            // only update if something changed
            updateContract({
                taxation_method: selectedTaxationMethod,
                tax_rate_id: selectedTaxationMethod === 'contract_basis' ? selectedContractTaxRateId : null,
            });
        }

        this.closeDialog();
    };

    onSeeTaxBreakdown = () => {
        const { isGOMUser, onSeeTaxBreakdown } = this.props;
        if (isGOMUser) {
            onSeeTaxBreakdown();
            this.closeDialog();
        }
    };

    closeDialog = () => {
        this.setState(DEFAULT_STATE);
        this.props.closeDialog();
    };

    handleContractTaxRateChange = (contractTaxRateId: number | null) => {
        this.setState({
            selectedContractTaxRateId: contractTaxRateId,
        });
    };

    handleTaxationMethodChange = (selectedTaxationMethod: ContractTaxationMethod) => {
        this.setState({
            selectedTaxationMethod,
        });
    };
}

export default withGStyles(styles)(TaxManagementDialog);
