import { useEffect, useState } from "react";

interface Props {
    isDialogOpen: boolean;
}

interface ComponentProps { }

const DialogMounter = <P extends ComponentProps>(Component: React.ComponentType<P>) => {

    const WithDialogMounter: React.FC<P & Props> = (props: P & Props) => {
        const [isDialogMounted, setIsDialogMounted] = useState(props.isDialogOpen);
        useEffect(() => {
            let timer: NodeJS.Timer;
            if (props.isDialogOpen) {
                setIsDialogMounted(true);
            } else {
                timer = setTimeout(() => setIsDialogMounted(false), 300);
            }

            return function cleanupTimer() {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }, [props.isDialogOpen]);
        if (!isDialogMounted) {
            return null;
        }
        return <Component {...props} />;
    };
    WithDialogMounter.displayName = Component.displayName || Component.name || 'Component';
    return WithDialogMounter;
};

export const withDialogMounter = DialogMounter;