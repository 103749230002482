
import * as t from 'io-ts';
import {
    PhotoTransformationsType,
    LongAddress,
    TaskAssignee,
    GatherCasePublic,
    getValidator,
    GatherCaseRecord,
    FuneralHomeRecord,
} from '.';
import { longAddress, NullLongAddress } from './deathcertificate';
import { AppPermissions, PermissionEnumType } from './permissions';
import { intersectionWith } from 'lodash';
import { ModerationStatus } from './moderation';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import {
    EntitySubscription,
    EntitySubscriptionInsertRequestDefinition,
    EntitySubscriptionUpdateRequestDefinition,
} from './subscription';

export const isUserProfile = (person: EntitySummary | UserProfile): person is UserProfile => {
    return person.hasOwnProperty('funeral_home_ids');
};

export const isUserProfileOrEntitySummary = (
    person: Pick<EntitySummary | UserProfile, 'funeral_homes'> | TaskAssignee
): person is UserProfile | EntitySummary => {
    return ('funeral_homes' in person && person.funeral_homes !== undefined);
};

export const isHelperModifiable = (helper: EntitySummary | UserProfile): boolean => {
    return helper.can_be_managed;
};

export const hasLoggedIn = (target: EntitySummary | UserProfile): boolean => {
    const targetUser = isUserProfile(target) ? target : target.user;
    return Boolean(targetUser && targetUser.has_logged_in);
};

export const getCaseEntity = (person: { cases: CaseEntityUX[] | null } | null, caseId: number): CaseEntityUX | null => {
    const caseEntity = person && (person.cases || []).find((ce) => ce.gather_case_id === caseId);
    return caseEntity || null;
};

export const getCaseEntityByCaseName = (
    person: UserProfile | EntitySummary | null, caseName: string
): CaseEntityUX | null => {
    const caseEntity = person && (person.cases || []).find((ce) => ce.gather_case_name === caseName);
    return caseEntity || null;
};

export const getCaseEntityById = (
    person: UserProfile | EntitySummary | null,
    caseEntityId: number,
): CaseEntityUX | null => {
    const caseEntity = person && (person.cases || []).find((ce) => ce.case_entity_id === caseEntityId);
    return caseEntity || null;
};

export const getUserFuneralHome = (
    user: Pick<UserProfile | EntitySummary, 'funeral_homes'> | null,
    funeralHomeId: number
): UserFuneralHomeUX | null => {
    const funeralHomeDetails = (user?.funeral_homes || []).find(fh => fh.funeral_home_id === funeralHomeId);
    return funeralHomeDetails || null;
};

export const getUserFuneralHomeByKey = (
    user: UserProfile | EntitySummary | null,
    funeralHomeKey: string,
): UserFuneralHomeUX | null => {
    const funeralHomeDetails = (user?.funeral_homes || []).find(fh => fh.funeral_home_key === funeralHomeKey);
    return funeralHomeDetails || null;
};

export const getUserFuneralHomeSettings = (
    settings: UserFuneralHomeSettings[],
    funeralHomeId: number | null
): UserFuneralHomeSettings | null => {
    if (!funeralHomeId) {
        return null;
    }
    const userFuneralHomeSettings = settings.find(ufhs => ufhs.funeral_home_id === funeralHomeId);
    return userFuneralHomeSettings || null;
};

export const mapUserFHUXtoUserFHSettings = (funeralHomeUX: UserFuneralHomeUX[] | null): UserFuneralHomeSettings[] => {
    const userFuneralHomeSettings: UserFuneralHomeSettings[] = (funeralHomeUX || []).map((fhs) => {
        return {
            funeral_home_id: fhs.funeral_home_id,
            use_email_pref: fhs.use_email_pref,
            use_phone_pref: fhs.use_phone_pref,
            visible_title: fhs.visible_title,
            visible_email: fhs.visible_email,
            visible_phone: fhs.visible_phone,
            permissions: fhs.permissions,
        };
    });
    return userFuneralHomeSettings;
};

export const isNonUserCaseHelper = (person: UserProfile | EntitySummary | null, caseId: number): boolean => {
    return Boolean(person && !person.user_id && getCaseEntity(person, caseId));
};

export const getCaseRole = (person: UserProfile | EntitySummary | null, caseId: number): EntityCaseRole | null => {
    const caseEntity = getCaseEntity(person, caseId);
    return caseEntity ? caseEntity.case_role : null;
};

interface HasFuneralHomeIds {
    funeral_home_ids: number[];
}

type CaseId = number;
type FuneralHomeId = number;
type SharesFuneralHomeRecord = Record<FuneralHomeId, { user1: UserFuneralHomeUX; user2: UserFuneralHomeUX }>;
type SharesCasesRecord = Record<CaseId, { user1: CaseEntityUX; user2: CaseEntityUX }>;
type FuneralHomeUserToCaseUserRecord = Record<FuneralHomeId, { fhUser: UserFuneralHomeUX; caseUser: CaseEntityUX }>;
export interface UserIntersection {
    sharesFuneralHomes: SharesFuneralHomeRecord;
    sharesCases: SharesCasesRecord;
    user1FHtoUser2FamilyByFH: FuneralHomeUserToCaseUserRecord;
    user2FHtoUser1FamilyByFH: FuneralHomeUserToCaseUserRecord;
}

export class UserUtils {
    public static funeralHomeCount({ funeral_home_ids }: HasFuneralHomeIds): number {
        if (!funeral_home_ids) {
            return 0;
        }
        return funeral_home_ids.length;
    }

    public static hasFuneralHome(user: UserProfile | EntitySummary | null, funeralHomeId: number): boolean {
        if (!user) {
            return false;
        }
        const funeralHomeIds = isUserProfile(user) ? user.funeral_home_ids : user.user && user.user.funeral_home_ids;
        if (!funeralHomeIds || funeralHomeIds.length === 0) {
            return false;
        } else {
            return funeralHomeIds.some(x => x === funeralHomeId);
        }
    }

    public static sharesFuneralHomes(
        first: UserProfile | EntitySummary | null,
        second: UserProfile | EntitySummary | null,
    ): boolean {
        if (!first || !second) {
            return false;
        }

        const firstFHIds = isUserProfile(first)
            ? first.funeral_home_ids
            : first.user && first.user.funeral_home_ids;
        const secondFHIds = isUserProfile(second)
            ? second.funeral_home_ids
            : second.user && second.user.funeral_home_ids;
        if (!firstFHIds || !secondFHIds) {
            return false;
        }

        const set = new Set(firstFHIds);
        const fhintersection = secondFHIds.filter(x => set.has(x));
        return (fhintersection.length > 0) ? true : false;
    }

    /**
     * userIntersection 
     *  This function will review two users and find any intersections
     *  Possible intersections: 
     *      - first user is FH and second user is on same FH
     *      - first user belongs to a FH for a case that the second user is on 
     *      - first user is on a case the belongs to the FH of the second user
     *      - first user and second user shares cases 
     */
    public static userIntersection(
        first: UserProfile | EntitySummary | null,
        second: UserProfile | EntitySummary | null,
    ): UserIntersection {
        let intersectionResults: UserIntersection = {
            sharesFuneralHomes: {},
            sharesCases: {},
            user1FHtoUser2FamilyByFH: {},
            user2FHtoUser1FamilyByFH: {},
        };
        if (!first || !second) {
            return intersectionResults;
        }

        // find all the overlapping funeral home ids for the funeral home users
        if (first.funeral_homes && second.funeral_homes) {
            const sharedFHList = intersectionWith(
                (first.funeral_homes || []),
                (second.funeral_homes || []),
                (a, b) => a.funeral_home_id === b.funeral_home_id);

            intersectionResults.sharesFuneralHomes = sharedFHList.reduce(
                (map, fh) => {
                    const user1 = (first.funeral_homes || []).find(
                        user1FH => user1FH.funeral_home_id === fh.funeral_home_id);
                    const user2 = (second.funeral_homes || []).find(
                        user2FH => user2FH.funeral_home_id === fh.funeral_home_id);
                    if (user1 && user2) {
                        map[fh.funeral_home_id] = {
                            user1,
                            user2,
                        };
                    }
                    return map;
                },
                {});
        }

        // these are both caseUsers (helper or visitor)
        if (first.cases && second.cases) {
            const sharedIdList = intersectionWith(
                (first.cases || []),
                (second.cases || []),
                (a, b) => a.gather_case_id === b.gather_case_id);
            intersectionResults.sharesCases = sharedIdList.reduce(
                (map, gatherCase) => {
                    const user1 = (first.cases || []).find(
                        user1CaseEntity => user1CaseEntity.gather_case_id === gatherCase.gather_case_id);
                    const user2 = (second.cases || []).find(
                        user2CaseEntity => user2CaseEntity.gather_case_id === gatherCase.gather_case_id);
                    if (user1 && user2) {
                        map[gatherCase.gather_case_id] = {
                            user1,
                            user2,
                        };
                    }
                    return map;
                },
                {});
        }
        // find all the different funeral home ids for the cases. 
        const user1FHtoUser2FamilyByFH = this.getFHUserToCaseUserIntersection(first, second);
        if (user1FHtoUser2FamilyByFH) {
            intersectionResults.user1FHtoUser2FamilyByFH = user1FHtoUser2FamilyByFH;
        }
        const user2FHtoUser1FamilyByFH = this.getFHUserToCaseUserIntersection(second, first);
        if (user2FHtoUser1FamilyByFH) {
            intersectionResults.user2FHtoUser1FamilyByFH = user2FHtoUser1FamilyByFH;
        }
        return intersectionResults;
    }

    public static getFHUserToCaseUserIntersection(
        fhUser: UserProfile | EntitySummary,
        caseUser: UserProfile | EntitySummary
    ): FuneralHomeUserToCaseUserRecord | null {
        const casesIntersectsWithFHUser = intersectionWith(
            (caseUser.cases || []), (fhUser.funeral_homes || []), (a, b) => a.funeral_home_id === b.funeral_home_id);
        if (!casesIntersectsWithFHUser) {
            return null;
        }
        const retVal = casesIntersectsWithFHUser.reduce<FuneralHomeUserToCaseUserRecord>(
            (map, caseEntity) => {
                const fhUserDetails = (fhUser.funeral_homes || []).find(
                    user1FH => user1FH.funeral_home_id === caseEntity.funeral_home_id);
                if (fhUserDetails) {
                    map[caseEntity.funeral_home_id] = {
                        fhUser: fhUserDetails,
                        caseUser: caseEntity,
                    };
                }
                return map;
            },
            {}
        );
        return retVal;
    }
}

export type UserInviteStatus =
    'success'
    | 'pending'
    | 'different_name_found'
    | 'different_role_found'
    | 'multiple_users_found'
    ;
export enum UserInviteStatusEnum {
    success = 'success',
    pending = 'pending',
    different_name_found = 'different_name_found',
    different_role_found = 'different_role_found',
    multiple_users_found = 'multiple_users_found',
}

export enum EntityCaseRole {
    guest = 'guest',
    admin = 'admin',
    visitor = 'visitor',
}

interface HasUserRole {
    role: UserRole;
}

export enum UserRole {
    GOMUser = 'GOMUser',
    GOMSuperUser = 'GOMSuperUser',
    FHUser = 'FHUser',
    User = 'User',
}

export class UserRoles {

    public static display(role: UserRole): string {
        switch (role) {
            case UserRole.GOMSuperUser:
                return 'Gather Super Admin';
            case UserRole.GOMUser:
                return 'Gather User';
            case UserRole.FHUser:
                return 'Funeral Home Employee';
            case UserRole.User:
                return 'Family/Friend';
            default:
                return '';
        }
    }

    public static displayRoleGroup(role: UserRole): string {
        switch (role) {
            case UserRole.GOMSuperUser:
            case UserRole.GOMUser:
                return 'Gather Employee';
            case UserRole.FHUser:
                return 'Funeral Home Employee';
            case UserRole.User:
                return 'Family/Friend';
            default:
                return '';
        }
    }

    public static getUserRole(user: HasUserRole | EntitySummary | null): UserRole | null {
        if (!user) {
            return null;
        }

        const hasUserRole = (obj: {} | HasUserRole): obj is HasUserRole => {
            return Boolean((obj as HasUserRole).role);
        };

        return hasUserRole(user) ? user.role : user.user && user.user.role;
    }

    public static isGOMRole(role: UserRole, specificRole?: UserRole): boolean {
        switch (specificRole) {
            case UserRole.GOMSuperUser: return role === UserRole.GOMSuperUser;
            case UserRole.GOMUser: return role === UserRole.GOMUser;
            default: return role === UserRole.GOMSuperUser || role === UserRole.GOMUser;
        }
    }

    public static isFHRole(role: UserRole, specificRole?: UserRole): boolean {
        switch (specificRole) {
            case UserRole.FHUser: return role === UserRole.FHUser;
            default: return role === UserRole.FHUser;
        }
    }

    public static isFamilyRole(role: UserRole): boolean {
        return role === UserRole.User;
    }

    public static isFHorGOMUser(
        user: Pick<UserProfile, 'role'> | EntitySummary | null,
    ): boolean {
        if (!user) {
            return false;
        }

        if (this.isGOMUser(user)) {
            return true;
        }

        return this.isFHUser(user);
    }

    public static isFHorGOMUserOnFH(user: UserProfile | EntitySummary | null, funeralHomeId: number | null): boolean {
        return this.isGOMUser(user) || this.isFHUserOnFH(user, funeralHomeId);
    }

    public static isFHorGOMUserOnFHByKey(
        user: UserProfile | EntitySummary | null,
        funeralHomeKey: string | null,
    ): boolean {
        return this.isGOMUser(user) || this.isFHUserOnFHByKey(user, funeralHomeKey);
    }

    public static isGOMSuperUser(user: UserProfile | EntitySummary | TaskAssignee | null): boolean {
        const role = UserRoles.getUserRole(user);
        return Boolean(role && UserRoles.isGOMRole(role, UserRole.GOMSuperUser));
    }

    public static isGOMPeonUser(user: UserProfile | EntitySummary | TaskAssignee | null): boolean {
        const role = UserRoles.getUserRole(user);
        return Boolean(role && UserRoles.isGOMRole(role, UserRole.GOMUser));
    }

    public static isGOMUser(
        user: HasUserRole | EntitySummary | null, specificRole?: UserRole
    ): boolean {

        const role = UserRoles.getUserRole(user);
        return Boolean(role && UserRoles.isGOMRole(role, specificRole));
    }

    public static isFHUser(user: HasUserRole | EntitySummary | null): boolean {

        const role = UserRoles.getUserRole(user);
        return Boolean(role && UserRoles.isFHRole(role));
    }

    public static isFHUserOnFH(
        user: Pick<UserProfile, 'funeral_homes' | 'role'> | EntitySummary | null,
        funeralHomeId: number | null,
    ): boolean {
        if (!user || !funeralHomeId) {
            return false;
        }
        const funeralHomes = getUserFuneralHome(user, funeralHomeId);
        if (!funeralHomes) {
            return false;
        }
        // currently the Role is not specific to the funeral home 
        // but is on the user. So we really do not know if they 
        // are a specific role on this paritcular funeral home 
        // until we move the role on the user funeralhome assoc table
        return UserRoles.isFHUser(user);
    }

    public static isFHUserOnFHByKey(user: UserProfile | EntitySummary | null, funeralHomeKey: string | null): boolean {
        if (!user || !funeralHomeKey) {
            return false;
        }
        const funeralHomes = getUserFuneralHomeByKey(user, funeralHomeKey);
        if (!funeralHomes) {
            return false;
        }
        // currently the Role is not specific to the funeral home 
        // but is on the user. So we really do not know if they 
        // are a specific role on this paritcular funeral home 
        // until we move the role on the user funeralhome assoc table
        return UserRoles.isFHUser(user);
    }

    public static isFamilyVisitor(user: UserProfile | EntitySummary | null, caseId: number | null): boolean {
        if (!user || !caseId) {
            return false;
        }

        const caseEntity = getCaseEntity(user, caseId);
        return Boolean(UserRoles.isFamilyOnCase(user, caseId) && caseEntity
            && caseEntity.case_role === EntityCaseRole.visitor);
    }

    public static isFamilyVisitorByCaseName(user: UserProfile | EntitySummary | null, caseName: string): boolean {
        if (!user) {
            return false;
        }

        const caseEntity = getCaseEntityByCaseName(user, caseName);
        return Boolean(UserRoles.isFamilyOnCaseByCaseName(user, caseName) && caseEntity
            && caseEntity.case_role === EntityCaseRole.visitor);
    }

    public static isFamilyGuest(user: UserProfile | EntitySummary | null, caseId: number): boolean {
        if (!user) {
            return false;
        }

        const caseEntity = getCaseEntity(user, caseId);
        return Boolean(UserRoles.isFamilyOnCase(user, caseId) && caseEntity
            && caseEntity.case_role === EntityCaseRole.guest);
    }

    public static isFamilyGuestByCaseName(user: UserProfile | EntitySummary | null, caseName: string): boolean {
        if (!user) {
            return false;
        }
        const caseEntity = getCaseEntityByCaseName(user, caseName);
        return Boolean(UserRoles.isFamilyOnCaseByCaseName(user, caseName) && caseEntity
            && caseEntity.case_role === EntityCaseRole.guest);
    }

    public static isFamilyAdmin(user: UserProfile | EntitySummary | null, caseId: number): boolean {
        if (!user) {
            return false;
        }

        const caseEntity = getCaseEntity(user, caseId);
        return Boolean(UserRoles.isFamilyOnCase(user, caseId) && caseEntity
            && caseEntity.case_role === EntityCaseRole.admin);
    }

    public static isFamilyAdminByCaseName(user: UserProfile | EntitySummary | null, caseName: string): boolean {
        if (!user) {
            return false;
        }
        const caseEntity = getCaseEntityByCaseName(user, caseName);
        return Boolean(UserRoles.isFamilyOnCaseByCaseName(user, caseName) && caseEntity
            && caseEntity.case_role === EntityCaseRole.admin);
    }

    public static isFamilyOnCase(person: UserProfile | EntitySummary | null, caseId: number): boolean {
        const role = UserRoles.getUserRole(person);
        return Boolean(role && UserRoles.isFamilyRole(role) && getCaseEntity(person, caseId));
    }

    public static isFamilyOnCaseByCaseName(person: UserProfile | EntitySummary | null, caseName: string): boolean {
        const role = UserRoles.getUserRole(person);
        return Boolean(role && UserRoles.isFamilyRole(role) && getCaseEntityByCaseName(person, caseName));
    }

    public static isInvitedFamilyOnCase(person: UserProfile | EntitySummary | null, caseId: number | null): boolean {
        if (!caseId) {
            return false;
        }
        return Boolean(UserRoles.isFamilyAdmin(person, caseId) || UserRoles.isFamilyGuest(person, caseId));
    }

    public static isInvitedFamilyOnCaseByCaseName(
        person: UserProfile | EntitySummary | null,
        caseName: string
    ): boolean {
        const caseEntity = getCaseEntityByCaseName(person, caseName);
        return Boolean(caseEntity && this.isInvitedFamilyOnCase(person, caseEntity.gather_case_id));
    }

    public static isInvitedFamilyOnAnyCaseForFH(
        person: UserProfile | EntitySummary | null,
        funeralHomeId: number | null,
    ): boolean {
        return Boolean(
            person?.cases?.some(
                (c) => c.funeral_home_id === funeralHomeId && this.isInvitedFamilyOnCase(person, c.gather_case_id),
            ),
        );
    }
}

/** 
 * ********************** * ********************** * ********************** * ********************** *
 * Entity
 * ********************** * ********************** * ********************** * ********************** *
 * 
 */

export function isEntityCaseRole(r: string): r is EntityCaseRole {
    return Boolean(EntityCaseRole[r]);
}

const EntityCaseRoleDefinition = t.union([
    t.literal(EntityCaseRole.guest),
    t.literal(EntityCaseRole.admin),
    t.literal(EntityCaseRole.visitor),
]);

export enum EntityRelationshipType {
    family = 'family',
    church = 'church',
    work = 'work',
    school = 'school',
    friend = 'friend',
    other = 'other',
}

const EntityRelationshipTypeDefinition = t.union([
    t.literal(EntityRelationshipType.church),
    t.literal(EntityRelationshipType.family),
    t.literal(EntityRelationshipType.friend),
    t.literal(EntityRelationshipType.other),
    t.literal(EntityRelationshipType.school),
    t.literal(EntityRelationshipType.work),
]);

export const EntityRelationshipTypeDisplayLookup = {
    [EntityRelationshipType.family]: 'Family Member',
    [EntityRelationshipType.church]: 'Church Friend',
    [EntityRelationshipType.work]: 'Co-Worker',
    [EntityRelationshipType.school]: 'Classmate',
    [EntityRelationshipType.friend]: 'Friend',
    [EntityRelationshipType.other]: 'Not Specified',
};

// Changes here need to also be made in 
// AcquaintanceButtons.tsx
export enum EntityRelationship {
    other = 'other',
    husband = 'husband',
    exhusband = 'exhusband',
    wife = 'wife',
    exwife = 'exwife',
    spouse = 'spouse',
    partner = 'partner',
    domesticPartner = 'domesticPartner',
    significantOther = 'significantOther',
    fiance = 'fiance',
    father = 'father',
    stepFather = 'stepFather',
    fatherInLaw = 'fatherInLaw',
    mother = 'mother',
    stepMother = 'stepMother',
    motherInLaw = 'motherInLaw',
    // parent = 'parent',
    // guardian = 'guardian',
    // fosterParent = 'fosterParent',
    son = 'son',
    stepSon = 'stepSon',
    sonInLaw = 'sonInLaw',
    daughter = 'daughter',
    stepDaughter = 'stepDaughter',
    daughterInLaw = 'daughterInLaw',
    child = 'child',
    stepChild = 'stepChild',
    brother = 'brother',
    stepBrother = 'stepBrother',
    brotherInLaw = 'brotherInLaw',
    halfBrother = 'halfBrother',
    sister = 'sister',
    stepSister = 'stepSister',
    sisterInLaw = 'sisterInLaw',
    halfSister = 'halfSister',
    sibling = 'sibling',
    grandfather = 'grandfather',
    stepGrandfather = 'stepGrandfather',
    grandfatherInLaw = 'grandfatherInLaw',
    greatGrandfather = 'greatGrandfather',
    grandmother = 'grandmother',
    stepGrandmother = 'stepGrandmother',
    grandmotherInLaw = 'grandmotherInLaw',
    greatGrandmother = 'greatGrandmother',
    grandparent = 'grandparent',
    greatGrandparent = 'greatGrandparent',
    grandson = 'grandson',
    stepGrandson = 'stepGrandson',
    grandsonInLaw = 'grandsonInLaw',
    greatGrandson = 'greatGrandson',
    granddaughter = 'granddaughter',
    stepGranddaughter = 'stepGranddaughter',
    granddaughterInLaw = 'granddaughterInLaw',
    greatGranddaughter = 'greatGranddaughter',
    grandchild = 'grandchild',
    greatGrandchild = 'greatGrandchild',
    uncle = 'uncle',
    stepUncle = 'stepUncle',
    uncleInLaw = 'uncleInLaw',
    greatUncle = 'greatUncle',
    aunt = 'aunt',
    stepAunt = 'stepAunt',
    auntInLaw = 'auntInLaw',
    greatAunt = 'greatAunt',
    nephew = 'nephew',
    greatNephew = 'greatNephew',
    niece = 'niece',
    greatNiece = 'greatNiece',
    cousin = 'cousin',
    secondCousin = 'secondCousin',
}

export const EntityRelationshipDisplayLookup = {
    [EntityRelationship.other]: 'Other',
    [EntityRelationship.husband]: 'Husband',
    [EntityRelationship.exhusband]: 'Ex-Husband',
    [EntityRelationship.wife]: 'Wife',
    [EntityRelationship.exwife]: 'Ex-Wife',
    [EntityRelationship.spouse]: 'Spouse',
    [EntityRelationship.partner]: 'Partner',
    [EntityRelationship.domesticPartner]: 'Domestic Partner',
    [EntityRelationship.significantOther]: 'Significant Other',
    [EntityRelationship.fiance]: 'Fiance',
    [EntityRelationship.father]: 'Father',
    [EntityRelationship.stepFather]: 'Step-Father',
    [EntityRelationship.fatherInLaw]: 'Father-in-law',
    [EntityRelationship.mother]: 'Mother',
    [EntityRelationship.stepMother]: 'Step-Mother',
    [EntityRelationship.motherInLaw]: 'Mother-in-law',
    // [EntityRelationship.parent] : 'Parent',
    // [EntityRelationship.guardian] : 'Guardian',
    // [EntityRelationship.fosterParent] : 'Foster Parent',
    [EntityRelationship.son]: 'Son',
    [EntityRelationship.stepSon]: 'Step-Son',
    [EntityRelationship.sonInLaw]: 'Son-in-law',
    [EntityRelationship.daughter]: 'Daughter',
    [EntityRelationship.stepDaughter]: 'Step-Daughter',
    [EntityRelationship.daughterInLaw]: 'Daughter-in-law',
    [EntityRelationship.child]: 'Child',
    [EntityRelationship.stepChild]: 'Step-Child',
    [EntityRelationship.brother]: 'Brother',
    [EntityRelationship.stepBrother]: 'Step-Brother',
    [EntityRelationship.brotherInLaw]: 'Brother-in-law',
    [EntityRelationship.halfBrother]: 'Half-Brother',
    [EntityRelationship.sister]: 'Sister',
    [EntityRelationship.stepSister]: 'Step-Sister',
    [EntityRelationship.sisterInLaw]: 'Sister-in-law',
    [EntityRelationship.halfSister]: 'Half-Sister',
    [EntityRelationship.sibling]: 'Sibling',
    [EntityRelationship.grandfather]: 'Grandfather',
    [EntityRelationship.stepGrandfather]: 'Step-Grandfather',
    [EntityRelationship.grandfatherInLaw]: 'Grandfather-in-law',
    [EntityRelationship.greatGrandfather]: 'Great Grandfather',
    [EntityRelationship.grandmother]: 'Grandmother',
    [EntityRelationship.stepGrandmother]: 'Step-Grandmother',
    [EntityRelationship.grandmotherInLaw]: 'Grandmother-in-law',
    [EntityRelationship.greatGrandmother]: 'Great Grandmother',
    [EntityRelationship.grandparent]: 'Grandparent',
    [EntityRelationship.greatGrandparent]: 'Great Grandparent',
    [EntityRelationship.grandson]: 'Grandson',
    [EntityRelationship.stepGrandson]: 'Step-Grandson',
    [EntityRelationship.grandsonInLaw]: 'Grandson-in-law',
    [EntityRelationship.greatGrandson]: 'Great Grandson',
    [EntityRelationship.granddaughter]: 'Granddaughter',
    [EntityRelationship.stepGranddaughter]: 'Step-Granddaughter',
    [EntityRelationship.granddaughterInLaw]: 'Granddaughter-in-law',
    [EntityRelationship.greatGranddaughter]: 'Great Granddaughter',
    [EntityRelationship.grandchild]: 'Grandchild',
    [EntityRelationship.greatGrandchild]: 'Great Grandchild',
    [EntityRelationship.uncle]: 'Uncle',
    [EntityRelationship.stepUncle]: 'Step-Uncle',
    [EntityRelationship.uncleInLaw]: 'Uncle-in-law',
    [EntityRelationship.greatUncle]: 'Great Uncle',
    [EntityRelationship.aunt]: 'Aunt',
    [EntityRelationship.stepAunt]: 'Step-Aunt',
    [EntityRelationship.auntInLaw]: 'Aunt-in-law',
    [EntityRelationship.greatAunt]: 'Great Aunt',
    [EntityRelationship.nephew]: 'Nephew',
    [EntityRelationship.greatNephew]: 'Great Nephew',
    [EntityRelationship.niece]: 'Niece',
    [EntityRelationship.greatNiece]: 'Great Niece',
    [EntityRelationship.cousin]: 'Cousin',
    [EntityRelationship.secondCousin]: 'Second-Cousin',
};

const EntityRelationshipDefinition = t.union([
    t.literal(EntityRelationship.other),
    t.literal(EntityRelationship.husband),
    t.literal(EntityRelationship.exhusband),
    t.literal(EntityRelationship.wife),
    t.literal(EntityRelationship.exwife),
    t.literal(EntityRelationship.spouse),
    t.literal(EntityRelationship.partner),
    t.literal(EntityRelationship.domesticPartner),
    t.literal(EntityRelationship.significantOther),
    t.literal(EntityRelationship.fiance),
    t.literal(EntityRelationship.father),
    t.literal(EntityRelationship.stepFather),
    t.literal(EntityRelationship.fatherInLaw),
    t.literal(EntityRelationship.mother),
    t.literal(EntityRelationship.stepMother),
    t.literal(EntityRelationship.motherInLaw),
    // t.literal(EntityRelationship.parent),
    // t.literal(EntityRelationship.guardian),
    // t.literal(EntityRelationship.fosterParent),
    t.literal(EntityRelationship.son),
    t.literal(EntityRelationship.stepSon),
    t.literal(EntityRelationship.sonInLaw),
    t.literal(EntityRelationship.daughter),
    t.literal(EntityRelationship.stepDaughter),
    t.literal(EntityRelationship.daughterInLaw),
    t.literal(EntityRelationship.child),
    t.literal(EntityRelationship.stepChild),
    t.literal(EntityRelationship.brother),
    t.literal(EntityRelationship.stepBrother),
    t.literal(EntityRelationship.brotherInLaw),
    t.literal(EntityRelationship.halfBrother),
    t.literal(EntityRelationship.sister),
    t.literal(EntityRelationship.stepSister),
    t.literal(EntityRelationship.sisterInLaw),
    t.literal(EntityRelationship.halfSister),
    t.literal(EntityRelationship.sibling),
    t.literal(EntityRelationship.grandfather),
    t.literal(EntityRelationship.stepGrandfather),
    t.literal(EntityRelationship.grandfatherInLaw),
    t.literal(EntityRelationship.greatGrandfather),
    t.literal(EntityRelationship.grandmother),
    t.literal(EntityRelationship.stepGrandmother),
    t.literal(EntityRelationship.grandmotherInLaw),
    t.literal(EntityRelationship.greatGrandmother),
    t.literal(EntityRelationship.grandparent),
    t.literal(EntityRelationship.greatGrandparent),
    t.literal(EntityRelationship.grandson),
    t.literal(EntityRelationship.stepGrandson),
    t.literal(EntityRelationship.grandsonInLaw),
    t.literal(EntityRelationship.greatGrandson),
    t.literal(EntityRelationship.granddaughter),
    t.literal(EntityRelationship.stepGranddaughter),
    t.literal(EntityRelationship.granddaughterInLaw),
    t.literal(EntityRelationship.greatGranddaughter),
    t.literal(EntityRelationship.grandchild),
    t.literal(EntityRelationship.greatGrandchild),
    t.literal(EntityRelationship.uncle),
    t.literal(EntityRelationship.stepUncle),
    t.literal(EntityRelationship.uncleInLaw),
    t.literal(EntityRelationship.greatUncle),
    t.literal(EntityRelationship.aunt),
    t.literal(EntityRelationship.stepAunt),
    t.literal(EntityRelationship.auntInLaw),
    t.literal(EntityRelationship.greatAunt),
    t.literal(EntityRelationship.nephew),
    t.literal(EntityRelationship.greatNephew),
    t.literal(EntityRelationship.niece),
    t.literal(EntityRelationship.greatNiece),
    t.literal(EntityRelationship.cousin),
    t.literal(EntityRelationship.secondCousin),
]);

export function isEntityRelationship(r: string): r is EntityRelationship {
    return Boolean(EntityRelationship[r]);
}

export function isEntityRelationshipByValue(r: string): EntityRelationship | null {
    const keys = Object.keys(EntityRelationship).filter(x => EntityRelationship[x] === r);
    const thisKey = keys.length > 0 ? keys[0] : null;
    return thisKey && isEntityRelationship(thisKey)
        ? thisKey : null;
}

// ---> Entity <---
export enum EntityType {
    customer = 'customer',
    vendor = 'vendor',
    gather = 'gather',
    insurer = 'insurer',
    person = 'person',
}

export const EntityTypeDefinition = t.union([
    t.literal(EntityType.customer),
    t.literal(EntityType.gather),
    t.literal(EntityType.insurer),
    t.literal(EntityType.person),
    t.literal(EntityType.vendor)
]);

export interface EntityRecord {
    id: number;
    title: string | null;
    fname: string;
    mname: string | null;
    lname: string;
    suffix: string | null;
    email: string | null;
    email_verified_time: Date | null;
    email_uuid: string;
    phone: string | null;
    phone_verified_time: Date | null;
    phone_uuid: string;
    home_phone: string | null;
    work_phone: string | null;
    fax_number: string | null;
    type: EntityType;
    home_address_id: number | null;
    billing_address_id: number | null;
    is_deceased: boolean;
    state_license_number: string | null;
    photo_view_id: number | null;
    organization_id: number | null;
    org_role: string | null;
}

export interface EntityRecordWithCaseId extends EntityRecord {
    gather_case_id: number;
}

export interface EntityInsertData {
    title?: string | null;
    fname: string;
    mname?: string | null;
    lname: string;
    email?: string | null;
    email_verified_time?: Date | null;
    email_uuid?: string;
    phone?: string | null;
    phone_verified_time?: Date | null;
    phone_uuid?: string;
    home_phone?: string | null;
    work_phone?: string | null;
    fax_number?: string | null;
    type: EntityType;
    home_address_id?: number | null;
    billing_address_id?: number | null;
    is_deceased?: boolean;
    photo_view_id?: number | null;
    state_license_number?: string | null;
    organization_id?: number | null;
    org_role?: string | null;
}

const entityUpdateRequestTypeDefinition = {
    title: t.union([t.string, t.null]),
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    email: t.union([t.string, t.null]),
    email_verified_time: t.union([DateFromISOString, t.null]),
    email_uuid: t.string,
    phone: t.union([t.string, t.null]),
    phone_verified_time: t.union([DateFromISOString, t.null]),
    phone_uuid: t.string,
    work_phone: t.union([t.string, t.null]),
    home_phone: t.union([t.string, t.null]),
    home_address: t.union([longAddress, t.null]),
    billing_address: t.union([longAddress, t.null]),
    is_deceased: t.boolean,
    photo_view_id: t.union([t.number, t.null]),
    state_license_number: t.union([t.string, t.null]),
    organization_id: t.union([t.number, t.null]),
    org_role: t.union([t.string, t.null]),
    fax_number: t.union([t.string, t.null]),
};

export const EntityUpdateRequestType = t.partial(entityUpdateRequestTypeDefinition);

export interface EntityUpdateRequest extends t.TypeOf<typeof EntityUpdateRequestType> {
    home_address: LongAddress | null;
    billing_address: LongAddress | null;
}

export class EntityUpdateRequest {
    public static fromRequest = getValidator<EntityUpdateRequest>(EntityUpdateRequestType, {
        mapperFn: EntityUpdateRequest.normalizeEntity
    });

    private static normalizeEntity(entity: EntityUpdateRequest): EntityUpdateRequest {
        if (entity.email) {
            entity.email = entity.email && entity.email.trim().toLowerCase();
        }
        if (entity.phone) {
            entity.phone = entity.phone.replace(/[^0-9]/g, '');
        }
        if (entity.work_phone) {
            entity.work_phone = entity.work_phone.replace(/[^0-9]/g, '');
        }
        if (entity.home_phone) {
            entity.home_phone = entity.home_phone.replace(/[^0-9]/g, '');
        }
        if (entity.fname) {
            entity.fname = entity.fname.trim();
        }
        if (entity.lname) {
            entity.lname = entity.lname && entity.lname.trim();
        }
        return entity;
    }
}

/** 
 * ********************** * ********************** * ********************** * ********************** *
 * CaseEntity
 * ********************** * ********************** * ********************** * ********************** *
 */

export interface CaseEntityRecord {
    id: number;
    entity_id: number;
    gather_case_id: number;
    created_by: number;
    created_time: Date;
    accepted_time: Date | null;
    relationship_type: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationship_alias: string | null;
    case_role: EntityCaseRole;
    has_accepted_startup: boolean;
    moderation_status: ModerationStatus;
    moderation_reason: string | null;
    moderation_time: Date | null;
    moderated_by: number | null;
    moderation_required: boolean;
    can_view_tracking_steps: boolean;
    can_view_belongings: boolean;
    partner_case_entity_id: number | null;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface CaseEntityUpsertRecord
    extends Pick<
        CaseEntityRecord,
        | "entity_id"
        | "gather_case_id"
        | "created_by"
        | "relationship_type"
        | "relationship"
        | "relationship_alias"
        | "case_role"
        | "can_view_tracking_steps"
        | "can_view_belongings"
        | "moderation_status"
        | "moderation_time"
        | "moderation_reason"
        | "moderated_by"
        | "moderation_required"
    > { }

export interface CaseEntityUXRecord extends Pick<CaseEntityRecord,
    | 'entity_id'
    | 'gather_case_id'
    | 'created_by'
    | 'created_time'
    | 'accepted_time'
    | 'relationship_type'
    | 'relationship'
    | 'relationship_alias'
    | 'case_role'
    | 'has_accepted_startup'
    | 'moderation_status'
    | 'moderation_reason'
    | 'can_view_tracking_steps'
    | 'can_view_belongings'
    | 'partner_case_entity_id'
    | 'deleted_time'
    | 'deleted_by'
> {
    case_entity_id: CaseEntityRecord['id'];
    funeral_home_id: GatherCaseRecord['funeral_home_id'];
    funeral_home_key: FuneralHomeRecord["key"];
    gather_case_name: GatherCaseRecord['name'];
    case_fname: GatherCaseRecord['fname'];
    case_lname: GatherCaseRecord['lname'];
    case_photo: string | null;
    case_photo_transformations: PhotoTransformationsType | null;
    display_relationship: string | null;
    can_get_private_case_data: boolean;
    can_view_contract: boolean;
    created_by_fname: string | null;
    created_by_mname: string | null;
    created_by_lname: string | null;
    subscriptions: EntitySubscription[];
}

export interface CaseEntityUX extends CaseEntityUXRecord {
}

/**
 * ********************** * ********************** * ********************** * ********************** *
 *  EntityReport
 * *
 *  EntityReportRecord is used for the helper report 
 * ********************** * ********************** * ********************** * ********************** *
 */
export interface EntityReportRecord {
    id: number;
    email: string | null;
    phone: string | null;
    is_deceased: boolean;
    name: string;
    user_profile_id: number | null;
    user_role: UserRole | null;
    home_address: string | null;
    case_full_name: string | null;
    case_name: string | null;
    case_role: EntityCaseRole;
    funeral_home_id: number;
    gather_case_id: number;
    display_relationship: string | null;
}

export interface EntityReport extends EntityReportRecord {
}

/** 
 * ********************** * ********************** * ********************** * ********************** *
 * PayerEntity
 * ********************** * ********************** * ********************** * ********************** *
 */
export interface PayerEntity {
    id: number;
    fname: string;
    mname?: string;
    lname: string;
    email: string | null;
    phone: string | null;
    type: EntityType;
    user_profile_id: number | null;
}

/** 
 * ********************** * ********************** * ********************** * ********************** *
 * EntityUX
 * ********************** * ********************** * ********************** * ********************** *
 */
export interface EntityUXRecord extends EntityRecord {
    home_address: string | null;
    home_address_long: LongAddress | null;
    billing_address: string | null;
    user_profile_id: number | null;
}

export interface EntityUX extends EntityUXRecord {
    user_profile: UserProfile | null;
}

export interface UserProfileSummary {
    user_id: UserProfileRecord['id'];
    entity_id: EntityRecord['id'];
    role: UserProfileRecord['role'];
    invited_by: UserProfileRecord['invited_by'];
    invited_by_name: string;
    invited_time: UserProfileRecord['invited_time'];
    funeral_home_ids: number[];
    has_logged_in: boolean;
}

export interface EntitySummaryRecord extends Pick<EntityRecord,
    | 'title'
    | 'fname'
    | 'mname'
    | 'lname'
    | 'suffix'
    | 'email'
    | 'email_verified_time'
    | 'email_uuid'
    | 'phone'
    | 'phone_verified_time'
    | 'phone_uuid'
    | 'home_phone'
    | 'work_phone'
    | 'fax_number'
    | 'type'
    | 'home_address_id'
    | 'billing_address_id'
    | 'is_deceased'
    | 'state_license_number'
    | 'photo_view_id'
    | 'organization_id'
    | 'org_role'
> {
    entity_id: EntityRecord['id'];
    user_id: UserProfileRecord['id'] | null;
    home_address: LongAddress | null;
    use_address_description: boolean | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    user: UserProfileSummary | null;
}

export interface EntitySummary extends EntitySummaryRecord {
    cases: CaseEntityUX[];
    can_be_managed: boolean;
    funeral_homes: UserFuneralHomeUX[] | null;
    primary_email: string | null;
}

export interface EntitySummaryWithAuth {
    entity: EntitySummary | null;
    auth_email?: string;
    auth_phone?: string;
}

const entityUXRecordFields: EntityUXRecord = {
    id: 0,
    title: null,
    fname: '',
    mname: null,
    lname: '',
    suffix: null,
    email: '',
    email_verified_time: null,
    email_uuid: '',
    phone: null,
    phone_verified_time: null,
    phone_uuid: '',
    work_phone: null,
    home_phone: null,
    fax_number: null,
    type: EntityType.person,
    home_address_id: null,
    home_address: null,
    home_address_long: { ...NullLongAddress },
    billing_address_id: null,
    billing_address: null,
    user_profile_id: null,
    is_deceased: false,
    state_license_number: null,
    photo_view_id: null,
    organization_id: null,
    org_role: null
};

export const EntitySearchDetailsDef = {
    searchText: t.union([t.string, t.null]),
    /** (fname | mname | lname | email | phone)[] */
    searchFields: t.array((t.keyof({ ...entityUXRecordFields, name: '' }))),
};
const EntitySearchDetailsType = t.type(EntitySearchDetailsDef);
export interface EntitySearchDetails extends t.TypeOf<typeof EntitySearchDetailsType> {
}

/**
 * ********************** * ********************** * ********************** * ********************** *
 *  Visitor Requests
 * ********************** * ********************** * ********************** * ********************** *
 */

const VisitorCreateRequestDefinition = {
    emailOrPhone: t.string,
    password: t.string,
    fname: t.string,
    lname: t.string,
    relationship_type: t.union([EntityRelationshipTypeDefinition, t.null]),
    relationship: t.union([EntityRelationshipDefinition, t.null]),
    relationship_alias: t.union([t.string, t.null]),
};

const VisitorCreateRequestType = t.type(VisitorCreateRequestDefinition);
export interface VisitorCreateRequest extends t.TypeOf<typeof VisitorCreateRequestType> {
    relationship_type: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
}

export class VisitorCreateRequest {
    public static fromRequest = getValidator<VisitorCreateRequest>(VisitorCreateRequestType);

    // This function is not needed
    public static isVisitorCreateRequest(obj: object): obj is VisitorCreateRequest {
        const request = obj as VisitorCreateRequest;
        return Boolean(
            request.fname
            && request.lname
            && request.password
            && request.emailOrPhone
        );
    }
}

const VisitorCaseRequestDefinition = {
    relationship_type: t.union([EntityRelationshipTypeDefinition, t.null]),
    relationship: t.union([EntityRelationshipDefinition, t.null]),
    relationship_alias: t.union([t.string, t.null]),
};

const VisitorCaseRequestType = t.type(VisitorCaseRequestDefinition);
export interface VisitorCaseRequest extends t.TypeOf<typeof VisitorCaseRequestType> {
    relationship_type: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
}

export class VisitorCaseRequest {
    public static fromRequest = getValidator<VisitorCaseRequest>(VisitorCaseRequestType);
}

/**
 * ********************** * ********************** * ********************** * ********************** *
 * UserProfile
 * ********************** * ********************** * ********************** * ********************** *
 */

// ---> UserCreateRequest <---
const UserCreateRequestDefinition = {
    entity_id: t.union([t.number, t.null]),
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    role: t.string,
    email: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    home_address: t.union([longAddress, t.null]),
    use_address_description: t.boolean,
    password: t.union([t.string, t.null]),
};

const UserCreateRequestType = t.type(UserCreateRequestDefinition);

export interface UserCreateRequest extends t.TypeOf<typeof UserCreateRequestType> {
    role: UserRole;
    home_address: LongAddress | null;
}

export class UserCreateRequest {
    public static fromRequest = getValidator<UserCreateRequest>(UserCreateRequestType, {
        mapperFn: UserCreateRequest.normalizeUser,
    });

    private static normalizeUser(user: UserCreateRequest): UserCreateRequest {
        user.email = user.email && user.email.trim().toLowerCase();
        if (user.phone) {
            user.phone = user.phone.replace(/[^0-9]/g, '');
        }
        user.fname = user.fname.trim();
        user.lname = user.lname && user.lname.trim();
        return user;
    }
}

// ---> UserFuneralHomeSettings 
export enum UsePreference {
    user_primary = 'user_primary',
    funeral_home = 'funeral_home',
    override = 'override',
}

const UsePreferenceType = t.keyof({
    [UsePreference.user_primary]: t.null,
    [UsePreference.funeral_home]: t.null,
    [UsePreference.override]: t.null,
});

const userFuneralHomeSettingsDefinition = {
    funeral_home_id: t.number,
    use_email_pref: UsePreferenceType,
    use_phone_pref: UsePreferenceType,
    visible_title: t.union([t.string, t.null]),
    visible_email: t.union([t.string, t.null]),
    visible_phone: t.union([t.string, t.null]),
    permissions: t.record(PermissionEnumType, t.boolean),
};

const UserFuneralHomeSettingsType = t.type(userFuneralHomeSettingsDefinition);

export interface UserFuneralHomeSettings extends t.TypeOf<typeof UserFuneralHomeSettingsType> {
    permissions: AppPermissions;
}

// ---> UserUpdateRequest <---
const userUpdateRequestTypeDefinition = {
    email: t.union([t.string, t.null]),
    title: t.union([t.string, t.null]),
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    role: t.string,
    org_role: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    fax_number: t.union([t.string, t.null]),
    state_license_number: t.union([t.string, t.null]),
    home_address: t.union([longAddress, t.null]),
    organization_id: t.union([t.number, t.null]),
    use_address_description: t.boolean,
    funeral_home_settings: t.union([t.array(UserFuneralHomeSettingsType), t.null])
};

export const UserUpdateRequestType = t.type(userUpdateRequestTypeDefinition);

export interface UserUpdateRequest extends t.TypeOf<typeof UserUpdateRequestType> {
    role: UserRole;
    home_address: LongAddress | null;
}

export class UserUpdateRequest {
    public static fromRequest = getValidator<UserUpdateRequest>(UserUpdateRequestType, {
        mapperFn: UserUpdateRequest.normalizeUser,
    });

    private static normalizeUser(user: UserUpdateRequest): UserUpdateRequest {
        user.email = user.email && user.email.trim().toLowerCase();
        if (user.phone) {
            user.phone = user.phone.replace(/[^0-9]/g, '');
        }
        user.fname = user.fname.trim();
        user.lname = user.lname && user.lname.trim();
        return user;
    }
}

// ---> UserPatchRequest <---
export enum CalendarViewType {
    expanded = 'expanded',
    collapsed = 'collapsed'
}

export const calendarViewStateDefinition = t.union([
    t.literal(CalendarViewType.expanded),
    t.literal(CalendarViewType.collapsed),
]);

const userPatchRequestTypeDefinition = {
    is_family_view_on: t.boolean,
    selected_calendar_view: calendarViewStateDefinition,
    show_payments_on_statement: t.boolean,
    advert_hidden_time: DateFromISOString,
};

const UserPatchRequestType = t.intersection([
    t.partial(userUpdateRequestTypeDefinition),
    t.partial(userPatchRequestTypeDefinition)
]);

export interface UserPatchRequest extends t.TypeOf<typeof UserPatchRequestType> {
    role?: UserRole;
    home_address?: LongAddress | null;
    selected_calendar_view?: CalendarViewType;
}

export class UserPatchRequest {
    public static fromRequest = getValidator<UserPatchRequest>(UserPatchRequestType);
}

// ---> UserProfile <---
export interface UserProfileRecord {
    id: number;
    uniqueid: string;
    password: string;
    reset_code: string | null;
    reset_expires: Date | null;
    email: string | null;
    role: UserRole;
    phone: string | null;
    invited_by: number;
    invited_time: Date;
    deleted_time: Date | null;
    entity_id: number;
    is_family_view_on: boolean;
    selected_calendar_view: CalendarViewType;
    show_payments_on_statement: boolean;
    advert_hidden_time: Date | null;
}

export interface AvatarUser {
    fname: string;
    lname?: string | null;
    photo?: string | null;
    photo_transformations?: PhotoTransformationsType | null;
    photo_id?: number | null;
}

export interface UserProfileInsertData {
    uniqueid?: string;
    password?: string;
    reset_code?: string | null;
    reset_expires?: Date | null;
    email?: string | null;
    role: UserRole;
    phone?: string | null;
    invited_by: number;
    deleted_time?: Date | null;
    entity_id: number;
}

// UserProfileRecordCore & UserProfileUXRecordCore
export interface UserProfileUXRecord extends
    Pick<UserProfileRecord,
        'id' |
        'uniqueid' |
        'role' |
        'is_family_view_on' |
        'show_payments_on_statement' |
        'advert_hidden_time' |
        'selected_calendar_view' |
        'entity_id' |
        'invited_by' |
        'invited_time' |
        'deleted_time'
    >,
    Pick<EntityRecord,
        'email' |
        'fname' |
        'mname' |
        'lname' |
        'suffix' |
        'phone' |
        'state_license_number' |
        'home_address_id' |
        'photo_view_id'
    > {
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    postal_code: string | null;
    timezone: string | null;
    address_description: string | null;
    long_address: LongAddress | null;
    use_address_description: boolean | null;
    default_funeral_home_id: number | null;
    invited_by_name: string;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    funeral_home_ids: number[];
    has_logged_in: boolean;
}

export interface UserProfile extends UserProfileUXRecord {
    home_address: LongAddress | null;
    cases: CaseEntityUX[] | null;
    user_id: number;
    can_be_managed: boolean;
    funeral_homes: UserFuneralHomeUX[] | null;
    titles?: string[];
}

export interface UserProfileBasics {
    user_id: number;
    fname: string;
    mname: string | null;
    lname: string;
    suffix: string | null;
    entity_id: number;
    role: UserRole;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
}

/**
 * UserFuneralHomeRecord
 */
export interface UserFuneralHomeRecord {
    user_profile_id: number;
    funeral_home_id: number;
    deactivated_by: number | null;
    deactivated_time: Date | null;
    created_by: number | null;
    created_time: Date;
    updated_by: number | null;
    updated_time: Date;
    use_email_pref: UsePreference;
    use_phone_pref: UsePreference;
    visible_title: string | null;
    visible_email: string | null;
    visible_phone: string | null;
    permissions: AppPermissions;
}

export interface UserFuneralHomeUX {
    user_profile_id: number;
    funeral_home_id: number;
    funeral_home_name: string;
    funeral_home_key: string;
    funeral_home_icon: string | null;
    funeral_home_icon_transformations: PhotoTransformationsType | null;
    funeral_home_city: string;
    funeral_home_state: string;
    funeral_home_email: string;
    funeral_home_phone: string;
    use_email_pref: UsePreference;
    use_phone_pref: UsePreference;
    visible_title: string | null;
    visible_email: string | null;
    visible_phone: string | null;
    permissions: AppPermissions;
}


/**
 * 
 */
export interface UserFuneralHomeEmailRecipientDetails extends
    Pick<UserFuneralHomeRecord, 'user_profile_id' | 'funeral_home_id' | 'permissions'>,
    Pick<UserProfileRecord, 'uniqueid' | 'role' | 'entity_id'
    > {
    fname: string;
    lname: string;
    primary_email: string | null;
    primary_phone: string | null;
    display_name: string;
    display_email: string;
    display_phone: string;
}

/**
 * CaseHelperCreateRequest
 */
const CreateEntityCaseHelperRoleDefinition = t.union([
    t.literal(EntityCaseRole.guest),
    t.literal(EntityCaseRole.admin),
]);

const CaseHelperCreateAndUpdate = {
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    email: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    work_phone: t.union([t.string, t.null]),
    home_phone: t.union([t.string, t.null]),
    home_address: t.union([longAddress, t.null]),
    use_address_description: t.boolean,
    relationship_type: t.union([EntityRelationshipTypeDefinition, t.null]),
    relationship: t.union([EntityRelationshipDefinition, t.null]),
    relationship_alias: t.union([t.string, t.null]),
    state_license_number: t.union([t.string, t.null]),
    case_entity_subscription_updates: t.array(EntitySubscriptionUpdateRequestDefinition),
    case_entity_subscription_creates: t.array(EntitySubscriptionInsertRequestDefinition),
};

const CaseHelperCreateOnly = {
    case_role: CreateEntityCaseHelperRoleDefinition,
    entity_id: t.union([t.number, t.null]),
    is_deceased: t.boolean,
    send_invite: t.boolean,
};

const CaseHelperUpdateOnly = {
    case_role: EntityCaseRoleDefinition,
    can_view_tracking_steps: t.boolean,
    can_view_belongings: t.boolean,
    partner_case_entity_id: t.union([t.number, t.null]),
};

const CaseHelperCreateRequestType = t.intersection([
    t.type(CaseHelperCreateOnly),
    t.type(CaseHelperCreateAndUpdate),
]);

export interface CaseHelperCreateRequest extends t.TypeOf<typeof CaseHelperCreateRequestType> {
    home_address: LongAddress | null;
    relationship_type: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
}

export class CaseHelperCreateRequest {
    public static fromRequest = getValidator<CaseHelperCreateRequest>(CaseHelperCreateRequestType);
}

export interface CaseHelperCreateResponse {
    status: UserInviteStatus;
    similarUsers: UserProfile[];
    helpers?: EntitySummary[];
    addedEntityId?: number;
}

export interface TeamMemberCreateResponse {
    status: UserInviteStatus;
    similarUsers: UserProfile[];
    team?: EntitySummary[];
    addedEntityId?: number;
}

export interface LoadCaseHelpersResponse {
    helpers: EntitySummary[];
    guestList: GuestListUser[];
}

// ---> CaseHelperUpdateRequest <---
const CaseHelperUpdateRequestType = t.intersection([
    t.partial(CaseHelperUpdateOnly),
    t.partial(CaseHelperCreateAndUpdate),
]);

export interface CaseHelperUpdateRequest extends t.TypeOf<typeof CaseHelperUpdateRequestType> {
    case_role?: EntityCaseRole;
    home_address?: LongAddress | null;
    relationship_type?: EntityRelationshipType | null;
    relationship?: EntityRelationship | null;
}

export function isEntityUpdateValidateError(input: EntitySummary[] | EntitySummary | EntityUpdateValidateError):
    input is EntityUpdateValidateError {
    return (input as EntityUpdateValidateError).validateError !== undefined;
}

export enum EntityUpdateValidateErrorEnum {
    duplicateEmail = 'duplicateEmail',
    duplicatePhone = 'duplicatePhone',
    duplicatePhoneAndEmail = 'duplicatePhoneAndEmail',
}

export interface EntityUpdateValidateError {
    validateError: EntityUpdateValidateErrorEnum;
}

interface EntityException {
    message: string;
}

export function handleEntityValidateError(e: EntityException) {
    switch (e.message) {
        case EntityUpdateValidateErrorEnum.duplicateEmail:
            return { validateError: EntityUpdateValidateErrorEnum.duplicateEmail };
        case EntityUpdateValidateErrorEnum.duplicatePhone:
            return { validateError: EntityUpdateValidateErrorEnum.duplicatePhone };
        case EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail:
            return { validateError: EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail };
        default:
            throw e;
    }
}

export class CaseHelperUpdateRequest {
    public static fromRequest = getValidator<CaseHelperUpdateRequest>(CaseHelperUpdateRequestType, {
        mapperFn: CaseHelperUpdateRequest.normalizeUser,
    });

    private static normalizeUser(user: CaseHelperUpdateRequest): CaseHelperUpdateRequest {
        if (user.email?.trim()) {
            user.email = user.email.trim().toLowerCase();
        }
        if (user.phone) {
            user.phone = user.phone.replace(/[^0-9]/g, '');
        }
        if (user.home_phone) {
            user.home_phone = user.home_phone.replace(/[^0-9]/g, '');
        }
        if (user.work_phone) {
            user.work_phone = user.work_phone.replace(/[^0-9]/g, '');
        }
        if (user.fname) {
            user.fname = user.fname.trim();
        }
        if (user.lname) {
            user.lname = user.lname.trim();
        }
        return user;
    }
}

export const getDisplayRelationshipForPerson = ({ relationship, relationship_alias, relationship_type }: {
    relationship_type: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationship_alias: string | null;
}) => {
    const relationshipDisplay = relationship && EntityRelationshipDisplayLookup[relationship];
    const relationshipTypeDisplay = relationship_type && EntityRelationshipTypeDisplayLookup[relationship_type];
    return relationship_alias || relationshipDisplay || relationshipTypeDisplay || null;
};

export const getDisplayTitleForFHUser = (
    user: EntitySummary | UserProfile,
    funeralHomeId: number,
    useFHNameAsRelationship?: boolean
): string => {
    if (!user) {
        return '';
    }
    const fhUserDetails = getUserFuneralHome(user, funeralHomeId);
    const userRole = UserRoles.getUserRole(user);
    return (useFHNameAsRelationship
        ? fhUserDetails && fhUserDetails.funeral_home_name
        : fhUserDetails && fhUserDetails.visible_title)
        || (user && userRole && UserRoles.displayRoleGroup(userRole))
        || '';
};

export const entitySummaryToPartialUserProfile = (changes: Partial<EntitySummary>): Partial<UserProfile> => {
    return {
        email: changes.email,
        phone: changes.phone,
        entity_id: changes.entity_id,
        fname: changes.fname,
        mname: changes.mname,
        lname: changes.lname,
        photo: changes.photo,
        photo_transformations: changes.photo_transformations,
        address1: changes.home_address ? changes.home_address.address1 : undefined,
        address2: changes.home_address ? changes.home_address.address2 : undefined,
        city: changes.home_address ? changes.home_address.city : undefined,
        state: changes.home_address ? changes.home_address.state : undefined,
        country: changes.home_address ? changes.home_address.country : undefined,
        postal_code: changes.home_address ? changes.home_address.postalCode : undefined,
        address_description: changes.home_address ? changes.home_address.description : undefined,
        long_address: changes.home_address,
        home_address_id: changes.home_address_id,
        photo_view_id: changes.photo_view_id,
        state_license_number: changes.state_license_number,
    };
};

export const userToEntitySummary = (user: UserProfile): EntitySummary => {
    return {
        user_id: user.user_id,
        email: user.email,
        primary_email: user.email,
        phone: user.phone,
        entity_id: user.entity_id,
        fname: user.fname,
        mname: user.mname,
        lname: user.lname || '',
        suffix: user.suffix,
        photo: user.photo,
        work_phone: null,
        home_phone: null,
        photo_transformations: user.photo_transformations,
        photo_view_id: user.photo_view_id,
        home_address_id: user.home_address_id,
        billing_address_id: null,
        state_license_number: user.state_license_number,
        user: {
            user_id: user.user_id,
            entity_id: user.entity_id,
            role: user.role,
            invited_by: user.invited_by,
            invited_time: user.invited_time,
            invited_by_name: user.invited_by_name || '',
            has_logged_in: user.has_logged_in,
            funeral_home_ids: user.funeral_home_ids,
        },
        cases: user.cases || [],
        home_address: user.home_address,
        use_address_description: user.use_address_description,
        is_deceased: false,
        email_verified_time: null,
        email_uuid: '',
        phone_verified_time: null,
        phone_uuid: '',
        type: EntityType.person,
        can_be_managed: user.can_be_managed,
        funeral_homes: user.funeral_homes || [],
        title: null,
        fax_number: null,
        organization_id: null,
        org_role: null,
    };
};

export interface GuestListUser {
    fname: EntityRecord['fname'];
    entity_id: EntityRecord['id'];
    case_entity_id: CaseEntityRecord['id'];
    created_time: CaseEntityRecord['created_time'];
    user_id: UserProfileRecord['id'];
    relationship: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    is_pending_decision: boolean;
}

export type GuestListUserMap = Record<number, GuestListUser>;

export const caseAssigneeAsGuestListUser = (
    caseAssignee: EntitySummary,
    funeralHomeId: number,
    useFHNameAsRelationship?: boolean
): GuestListUser => {
    return {
        ...caseAssignee,
        relationship: getDisplayTitleForFHUser(caseAssignee, funeralHomeId, useFHNameAsRelationship),
        is_pending_decision: false,
        user_id: caseAssignee.user_id || -1, // case assignee will always have a user_id
        case_entity_id: -1, // case assignee will never have a case_entity_id
        created_time: caseAssignee.user ? caseAssignee.user.invited_time : new Date(),
    };
};

export const getCombinedGuestList = (
    params: {
        caseAssignee: EntitySummary | null;
        guests: GuestListUser[];
        publicCase: GatherCasePublic;
    },
    useFHNameAsRelationship: boolean): GuestListUser[] => {
    const { caseAssignee, guests, publicCase } = params;

    const guestsWithRelationship: GuestListUser[] = guests.map((user) => ({
        ...user,
        relationship: user.relationship
            ? `${publicCase.fname}'s ${user.relationship}`
            : null,
    }));

    const caseAssigneeAsGuest: GuestListUser | null = !caseAssignee
        ? null
        : caseAssigneeAsGuestListUser(caseAssignee, publicCase.funeral_home.id, useFHNameAsRelationship);

    return caseAssigneeAsGuest
        ? [caseAssigneeAsGuest, ...guestsWithRelationship]
        : guestsWithRelationship;
};

export enum EntitySearchType {
    case_helper = 'case_helper',
    fh_team = 'fh_team',
    all = 'all',
}

export const isEntitySearchType = (str: string): str is EntitySearchType => {
    return Boolean(EntitySearchType[str]);
};
