import * as React from 'react';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';


import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { PopperContentType, ShareOptionType } from './ShareLinkSection';
import { GatherCasePublic } from '../../../../shared/types';
import { handleShareOptionAction } from '../utils';
import { optionStyles } from './Option.styles';
import GPopper from '../../../common/GPopper';
import { getRememberURLForSharing } from '../../../../services';
import { Theme } from '@mui/material';
import { buildQRCodeLink } from '../../utils';

interface Props {
    type: ShareOptionType | null;
    popperAnchorEle: HTMLElement | null;
    popperContent: { content: PopperContentType | null; color: string } | null;
    activeCase: GatherCasePublic;
    setAppSnackbarSuccess: (message: string) => void;
    clickAwayListener: (event: MouseEvent | TouchEvent) => void;
    zIndex: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    popoverPaper: {
        position: 'relative',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        width: 280,
        boxShadow: theme.shadows[10]
    },
    popperHeader: {
        color: 'white',
        fontSize: 22,
        lineHeight: '1.8em',
        fontWeight: 300,
        borderRadius: '4px 4px 0 0'
    },
    popperContent: {
        textAlign: 'center',
        height: 'fit-content',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    actionsOnIcons: {
        padding: '10px 8px',
        fontWeight: 300,
        fontSize: 16
    },
    actionList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        padding: '0 14px',
        '& p': {
            fontWeight: 300,
            fontSize: 16,
        }
    },
    footerText: {
        fontSize: 11,
        fontWeight: 300,
        padding: '2px 8px 8px 8px'
    },
    optionButton: {
        fontSize: 15,
        marginTop: 20,
        border: '1px solid',
    },
    fontSize14: {
        fontSize: '14px !important'
    },
    workBreakAll: {
        wordBreak: 'break-all'
    },
    ...optionStyles()(theme)
}), { name: 'SocialIconPopper' });

export type SocialIconPopperOpenHandler = (
    event: React.MouseEvent<HTMLElement>,
    option: ShareOptionType,
    timeout?: number
) => void;

const SocialIconPopper = (props: Props) => {
    const {
        popperAnchorEle,
        clickAwayListener,
        popperContent,
        zIndex,
        activeCase,
        type
    } = props;

    const classes = useStyles();

    if (!popperContent || !popperContent.content || !popperContent.color) {
        return null;
    }

    const copyLink = (message: string) => {
        props.setAppSnackbarSuccess(message);
    };

    const openLink = (link: string | undefined) => {
        window.open(link);
    };

    const handleButtonClick = () => {
        if (type === 'SHARE') {
            handleShareOptionAction(activeCase);
            return;
        }

        if (type === 'LINK') {
            copyLink('Link copied to your clipboard');
            return;
        }

        openLink(content.link);
    };

    const linkToCopied = encodeURI(getRememberURLForSharing(activeCase.name));
    const qrCodeImage = buildQRCodeLink({ caseName: activeCase.name });


    const content = popperContent.content;
    const color = popperContent.color;

    return (
        <GPopper
            anchorEle={popperAnchorEle}
            popperContentClass={classes.popperContent}
            arrowStyles={{ borderBottomColor: color }}
            paperClass={classes.popoverPaper}
            zIndex={zIndex}
            closePopper={clickAwayListener}
            interactive
        >
            <Typography
                style={{ backgroundColor: color }}
                align="center"
                className={classes.popperHeader}
            >
                {content.title}
            </Typography>

            <Typography
                style={{ color }}
                align="center"
                className={classes.actionsOnIcons}
            >
                {content.actions}
            </Typography>

            <Grid item xs={12} className={classes.actionList}>
                {content.list.map((l) => (
                    <Typography
                        style={{ color }}
                        key={l.id}
                        align="center"
                    >
                        {l.listContent}
                    </Typography>
                ))}

                {content.isLink &&
                    <Typography
                        style={{ color }}
                        className={classNames(classes.fontSize14, classes.workBreakAll)}
                    >
                        {linkToCopied}
                    </Typography>
                }
            </Grid>

            {type === 'QRCODE' && <img src={qrCodeImage} />}

            <CopyToClipboard text={linkToCopied}>
                <Button
                    style={{ color }}
                    className={classNames(
                        classes.optionButton,
                        type && classes[type]
                    )}
                    variant="outlined"
                    size="small"
                    onClick={handleButtonClick}
                >
                    {content.icon}&nbsp;&nbsp;{content.label}
                </Button>
            </CopyToClipboard>

            <Typography
                style={{ color }}
                align="center"
                className={classes.footerText}
            >
                {content.footer}
            </Typography>
        </GPopper>
    );
};

export default SocialIconPopper;
