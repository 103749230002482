import * as React from 'react';
import { throttle } from 'lodash';

import SignatureCanvas from 'react-signature-canvas';
import withTheme, { WithTheme } from '@mui/styles/withTheme';
import { Theme } from '@mui/material/styles';

interface Props extends WithTheme<Theme> {
    canvasRatio: number;
    minStrokeWidth: number;
    maxStrokeWidth: number;
    useThemePenColor: boolean;
    canvasRef: React.MutableRefObject<SignatureCanvas | null>;
    onStopDrawing?: () => void;
}

interface State {
    lines: SignaturePad.Point[][] | null;
    width: number;
}
class GSignatureCanvas extends React.Component<Props, State> {
    state: State = {
        lines: null,
        width: 0,
    };

    private throttledResizeListener = throttle(() => this.handleWindowResize(), 200);
    private containerRef = React.createRef<HTMLDivElement>();
    private canvasRef: SignatureCanvas | null = null;

    componentDidMount() {
        window.addEventListener('resize', this.throttledResizeListener, { passive: true });
        this.throttledResizeListener();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledResizeListener);
        this.throttledResizeListener.cancel();
    }

    /**
     * please follow this link: https://stackoverflow.com/a/60057521
     * to understand the resize functionality
     */
    handleWindowResize = () => {
        const { width } = this.state;

        if (this.containerRef.current && this.canvasRef) {
            const currentWrapperWidth = this.containerRef.current.scrollWidth;
            const scale = width / currentWrapperWidth;

            this.setState(
                prevState => ({
                    width: currentWrapperWidth,
                    lines: this.canvasRef && this.canvasRef.isEmpty()
                        ? null
                        : prevState.lines
                }),
                () => this.callback(scale)
            );
        } else {
            this.resetCanvasSize();
        }
    };

    callback = (scale: number) => {
        const { lines } = this.state;

        if (!this.canvasRef) {
            return;
        }

        const currentLines = lines && [...lines];

        if (currentLines) {
            this.setRescaledSignature(currentLines, scale);
            this.resetCanvasSize();
            this.canvasRef.fromData(currentLines);
        } else {
            this.resetCanvasSize();
        }
    };

    resetCanvasSize = () => {
        if (!this.canvasRef) {
            return;
        }

        const canvas = this.canvasRef.getCanvas();
        const ctx = canvas.getContext('2d');

        canvas.style.height = `${canvas.offsetWidth / this.props.canvasRatio}px`;

        const dpr = window.devicePixelRatio || 1;
        canvas.width = canvas.offsetWidth * dpr;
        canvas.height = canvas.offsetHeight * dpr;

        if (ctx) {
            ctx.scale(dpr, dpr);
        }
    };

    setRescaledSignature = (lines: SignaturePad.Point[][], scale: number) => {
        lines.forEach(line => {
            line.forEach(point => {
                point.x /= scale;
                point.y /= scale;
            });
        });
    };

    onChangeSignaturePad = () => {
        const { onStopDrawing } = this.props;

        if (onStopDrawing) {
            onStopDrawing();
        }

        if (this.canvasRef) {
            const lines = this.canvasRef.toData();
            this.setState({ lines });
        }
    };

    render() {
        const { theme, useThemePenColor, minStrokeWidth, maxStrokeWidth, canvasRef } = this.props;

        return (
            <div ref={this.containerRef}>
                <SignatureCanvas
                    penColor={theme && useThemePenColor ? theme.palette.primary.main : undefined}
                    minWidth={minStrokeWidth}
                    maxWidth={maxStrokeWidth}
                    clearOnResize={false}
                    onEnd={this.onChangeSignaturePad}
                    canvasProps={{ style: { width: '100%', display: 'block' } }}
                    ref={(ref) => {
                        this.canvasRef = ref;
                        if (canvasRef) {
                            canvasRef.current = ref;
                        }
                    }}
                />
            </div>
        );
    }
}

export default withTheme<Theme, typeof GSignatureCanvas>(GSignatureCanvas);
