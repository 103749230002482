import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Gesture from '@mui/icons-material/Gesture';
import EditIcon from '@mui/icons-material/Edit';

import {
    GatherCaseUX,
} from '../../../../shared/types';

import {
    StyledProps,
    styles,
    ContractStatusContainerStyledProps,
    contractStatusContainerStyles,
} from './styles';
import { ContractStatusContent } from './widgets';
import GSwitch from '../../../common/inputElements/GSwitch';

import { toggleFullScreen, isFullScreen, RouteBuilder } from '../../../../services';

import ButtonLink from '../../../common/ButtonLink';
import Tooltip from '@mui/material/Tooltip';
import withGStyles from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import GPopper from '../../../common/GPopper';

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    popperAnchorEle: HTMLElement | null;
    canEditContract: boolean;
    activeCase: GatherCaseUX;
    isShowPrices: boolean;
    isFrozen: boolean;
    isShowRevisions: boolean;
    isAutoFormsEnabled: boolean;
    printContract: () => void;
    openFreezeContractDialog: () => void;
    toggleShowPrices: (isShowPrices: boolean) => void;
    toggleShowContractRevisions: (isShowRevisions: boolean) => void;
    closePopper: () => void;
    isShowPayments: boolean;
    toggleShowPayments: (isShowPayments: boolean) => void;
}

type CombinedProps = DialogProps & StyledProps & ContractStatusContainerStyledProps & Props;
const ContractPopover = (props: CombinedProps) => {

    const {
        classes,
        closePopper,
        isShowPrices,
        isShowRevisions,
        toggleShowPrices,
        toggleShowContractRevisions,
        canEditContract,
        isShowPayments,
        toggleShowPayments,
        popperAnchorEle,
        activeCase,
        isFrozen,
        printContract,
        isAutoFormsEnabled,
        openFreezeContractDialog,
    } = props;

    const renderHeader = () => {
        const showPricesTooltip = 'Your selection will be remembered so that the same setting is applied '
            + 'each time you log in on this device.';

        return (
            <Grid
                item
                xs={12}
                className={classes.header}
            >
                <Grid className={classes.headerRow}>
                    <Tooltip title={showPricesTooltip}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            wrap="nowrap"
                            className={classes.showPricesContainer}
                        >
                            <GSwitch
                                id={`show_prices`}
                                name={'show_prices'}
                                checked={isShowPrices}
                                labelClasses={classes.colorPrimary}
                                formControlLabelClasses={classes.formControlLabel}
                                onChangeCallBack={(isChecked) => toggleShowPrices(isChecked)}
                            />
                            <label
                                htmlFor={'show_prices'}
                                className={classNames(classes.colorPrimary, classes.fontWeight500)}
                            >
                                SHOW PRICES
                            </label>
                        </Grid>
                    </Tooltip>
                    <Button
                        color="primary"
                        className={classes.fullscreenButton}
                        onClick={e => {
                            toggleFullScreen(); closePopper();
                        }}
                    >
                        {isFullScreen() ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        &nbsp;
                        {isFullScreen() ? 'exit full screen' : 'full screen'}

                    </Button>
                </Grid>
                {canEditContract &&
                    <Grid className={classes.headerRow}>
                        <GSwitch
                            label={
                                <label
                                    htmlFor={'show_revisions'}
                                    className={classNames(classes.colorPrimary, classes.fontWeight500)}
                                >
                                    SHOW STATEMENT REVISIONS
                                </label>
                            }
                            id={'show_revisions'}
                            name={'show_revisions'}
                            checked={isShowRevisions}
                            labelClasses={classes.colorPrimary}
                            formControlLabelClasses={classes.formControlLabel}
                            onChangeCallBack={(isChecked) => toggleShowContractRevisions(isChecked)}
                        />
                    </Grid>
                }

                {canEditContract &&
                    <Grid className={classes.headerRow}>
                        <GSwitch
                            label={
                                <label
                                    htmlFor={`show_payments`}
                                    className={classNames(classes.colorPrimary, classes.fontWeight500)}
                                >
                                    SHOW PAYMENTS ON STATEMENT
                                </label>
                            }
                            id={`show_payments`}
                            name={'show_payments'}
                            checked={isShowPayments}
                            labelClasses={classes.colorPrimary}
                            formControlLabelClasses={classes.formControlLabel}
                            onChangeCallBack={(isChecked) => toggleShowPayments(isChecked)}
                        />
                    </Grid>
                }
            </Grid>
        );
    };

    const renderMiddleSection = () => {

        const icon = isFrozen ? <AcUnitIcon /> : <EditIcon />;
        const heading: string = `${activeCase.fname}'s statement is ${isFrozen ? 'frozen' : 'unfrozen'}.`;
        const description: string = !isFrozen ?
            `Once you sign ${activeCase.fname}'s statement, it will become frozen. `
            + 'Any additional changes you make to statement will then be tracked as statement revisions.' :
            `If ${activeCase.fname}'s statement has already been signed, unfreezing will allow you to make edits, `
            + 'which will be tracked as statement revisions.';
        let button = {
            label: <><AcUnitIcon />&nbsp;freeze statement</>,
            onClick: () => !isFrozen && openFreezeContractDialog(),
            disabled: isFrozen,
        };

        return (
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.middle,
                    isFrozen && classes.paddingBottom12,
                    isFrozen && classNames(classes.paddingTop12, classes.marginAuto)
                )}
            >
                <ContractStatusContent
                    {...props}
                    icon={icon}
                    heading={heading}
                    description={description}
                    button={button}
                    canEditContract={canEditContract}
                />

                <div className={classes.popperDefaultButton}>
                    <Button
                        color="primary"
                        size="small"
                        className={classNames(
                            classes.button,
                            !canEditContract && classes.restricted
                        )}
                        onClick={e => printContract()}
                    >
                        <LocalPrintshopIcon />&nbsp;print draft
                    </Button>

                    {canEditContract &&
                        <ButtonLink
                            link={RouteBuilder.DocPacketPage({
                                caseName: activeCase.name,
                                funeralHomeKey: activeCase.funeral_home.key,
                            })}
                            color="primary"
                            size="small"
                            className={classes.button}
                            disabled={!isAutoFormsEnabled}
                        >
                            <Gesture />&nbsp;sign statement
                        </ButtonLink>
                    }
                </div>
            </Grid>
        );
    };

    return (
        <GPopper
            anchorEle={popperAnchorEle}
            paperClass={classes.paper}
            className={classes.popper}
            arrowClass={classes.arrowLeft}
            closePopper={() => closePopper()}
            zIndex={1320}
        >
            {renderHeader()}
            {renderMiddleSection()}
        </GPopper>
    );
};

export default withFullScreen()(
    withGStyles(styles<Props>(), contractStatusContainerStyles<Props>())(ContractPopover)
);
