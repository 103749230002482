import { PaymentRequest, GatherCaseUX, CaseType, EntityCaseRole, EntitySummary } from '../../shared/types';

import { PatchPaymentParameters } from '../../actions/Finance.action';
import { HelperInvitationDialogContext, openHelperInvitationDialog } from '../../actions/Dialog.action';
import BaseAssignForEntitySummary from './baseAssign/BaseAssignForEntitySummary';
import { useGDispatch } from '../../types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    {
        assignSecondaryButtonStyle: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '15px',
            '& $primary': {
                fontWeight: 200,
                textTransform: 'none',
            },
            '& $secondary': {
                textTransform: 'none',
                fontStyle: 'italic',
                fontWeight: 200,
            },
        },
        primary: {},
        secondary: {},
    },

    { name: 'AssignPaymentPopper' },
);

interface Props {
    helpers: EntitySummary[];
    anchorEl: HTMLElement | null;
    paymentInfo: PaymentRequest;
    selectedCase: Pick<GatherCaseUX, 'id' | 'fname' | 'case_type' | 'funeral_home_id' | 'uuid'>;
    zIndex: number;
    patchPayment: (...params: PatchPaymentParameters) => void;
    closePopper: () => void;
}

const AssignPaymentPopper = (props: Props) => {
    const { helpers, anchorEl, selectedCase, zIndex, paymentInfo, patchPayment, closePopper } = props;

    const classes = useStyles();

    const dispatch = useGDispatch();

    const addPayer = (payer: EntitySummary) => {
        patchPayment({
            caseUuid: selectedCase.uuid,
            paymentInfo: { ...paymentInfo, payer_id: payer.entity_id },
            collect: false,
            sendNotification: true,
        });
        closePopper();
    };

    const addNewHelper = (_zIndex: number, defaultTab: EntityCaseRole, sendInvite: boolean) => {
        dispatch(
            openHelperInvitationDialog({
                zIndex: _zIndex + 1,
                defaultTab,
                sendInvite,
                onAddedCallback: addPayer,
                context: HelperInvitationDialogContext.payment,
            }),
        );
    };

    const dialogTitle = 'Select a payer';
    const addContactInfoTooltip =
        `Click to add contact information. ` + `Click {entityFname}'s name to add as a payer.`;

    const isOneOff = selectedCase.case_type === CaseType.one_off;

    return (
        <BaseAssignForEntitySummary
            key="payment_popper"
            anchorEl={anchorEl}
            zIndex={zIndex + 1}
            gatherCase={selectedCase}
            items={helpers}
            handleClose={() => closePopper()}
            onSelect={(_, assignee) => assignee && addPayer(assignee)}
            headerText={dialogTitle}
            addButtonText="Add Helper"
            onAdd={(_zIndex) =>
                addNewHelper(_zIndex, isOneOff ? EntityCaseRole.guest : EntityCaseRole.admin, !isOneOff)
            }
            tooltip={addContactInfoTooltip}
            onSecondaryAdd={(_zIndex) => addNewHelper(_zIndex, EntityCaseRole.guest, false)}
            secondaryAddButtonText={
                <span className={classes.assignSecondaryButtonStyle}>
                    <span className={classes.primary}>+ Add payer without email or mobile number</span>
                    <span className={classes.secondary}>(not recommended per FTC suggestions)</span>
                </span>
            }
            showFamilyHelpers={true}
            showTeamMembers={false}
        />
    );
};

export default AssignPaymentPopper;
