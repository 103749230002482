import { OverviewSectionCommonProps } from './OverviewForm';
import { NULL_NO_ANSWER } from '../../../../../constants';
import { emptyStringParser } from '../../../../../services';
import { HispanicOriginEnum, RaceEnum, AncestryEnum } from '../../../../../shared/types';
import OverviewLabel, { ArraySeparator } from './OverviewLabel';
import { OverviewDivider, SectionTypography } from './components';
import { ConfigurableRaceFieldKey } from '../../../../../shared/death_certificate/validators/config';

interface Props extends OverviewSectionCommonProps { }
const RaceOverview = (props: Props) => {
    const { deathCertificate, printMode, showHeading, divider, alwaysVisible } = props;

    const hispanicOriginArray: Array<HispanicOriginEnum> | null = deathCertificate.race.hispanicOrigin || null;
    let hispanic: Array<string> = [];
    if (hispanicOriginArray) {
        hispanicOriginArray.forEach((hispanicOrigin: HispanicOriginEnum) => {
            if (hispanicOrigin === 'Yes, Other Spanish/Hispanic/Latino' && hispanicOriginArray) {
                hispanic = [
                    ...hispanic,
                    'Yes, Other Spanish/Hispanic/Latino: ' +
                    `${emptyStringParser(deathCertificate.race.hispanicOrigin_specify_other) ?? NULL_NO_ANSWER}`
                ];
            } else {
                hispanic = [...hispanic, hispanicOrigin];
            }
        });
    }

    const raceTypeArray: Array<RaceEnum> | null = deathCertificate.race.race || null;
    let race: Array<string> = [];
    if (raceTypeArray) {
        raceTypeArray.forEach((raceType: RaceEnum) => {
            switch (raceType) {
                case 'American Indian':
                    race = [
                        ...race,
                        'American Indian' +
                        `${emptyStringParser(deathCertificate.race.race_specify_indian_tribe) ?? NULL_NO_ANSWER}`
                    ];
                    break;
                case 'Alaska Native':
                    race = [
                        ...race,
                        'Alaska Native' +
                        `${emptyStringParser(deathCertificate.race.race_specify_indian_tribe) ?? NULL_NO_ANSWER}`
                    ];
                    break;
                case 'Asian (Specify)':
                    race = [
                        ...race,
                        `Asian (Specify): ${emptyStringParser(deathCertificate.race.race_specify_asian)
                        ?? NULL_NO_ANSWER}`
                    ];
                    break;
                case 'Other Asian':
                    race = [
                        ...race,
                        `Other Asian: ${emptyStringParser(deathCertificate.race.race_specify_other_asian)
                        ?? NULL_NO_ANSWER}`
                    ];
                    break;
                case 'Other Pacific Islander':
                    race = [
                        ...race,
                        `Other Pacific Islander: ${emptyStringParser(deathCertificate.race.race_specify_islander)
                        ?? NULL_NO_ANSWER}`
                    ];
                    break;
                case 'Hispanic/Latino (Specify)':
                    race = [
                        ...race,
                        `Hispanic/Latino (Specify): ${emptyStringParser(
                            deathCertificate.race.race_specify_other_hispanic
                        ) ?? NULL_NO_ANSWER}`
                    ];
                    break;
                case 'Other':
                    race = [
                        ...race,
                        `Other: ${emptyStringParser(deathCertificate.race.race_specify_other) ?? NULL_NO_ANSWER}`
                    ];
                    break;
                default:
                    race = [...race, raceType];
                    break;
            }
        });
    }

    const isUsCitizen: string | null = deathCertificate.race.isUsCitizen || null;

    const ancestryList = deathCertificate.race.ancestry || null;
    const ancestry = [];
    for (const ancestryItem of ancestryList || []) {
        if (ancestryItem === AncestryEnum['American Indian (Specify Tribe)']) {
            ancestry.push(
                `American Indian (Specify Tribe): ${emptyStringParser(
                    deathCertificate.race.americanIndian_specifiy_tribe
                ) ?? NULL_NO_ANSWER}`
            );
        } else {
            ancestry.push(ancestryItem);
        }
    }

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    RACE
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Ancestry"
                    html={ancestry}
                    arraySeparator={ArraySeparator.colon}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRaceFieldKey.ancestry}
                />

                <OverviewLabel
                    label="Hispanic"
                    html={hispanic}
                    arraySeparator={ArraySeparator.colon}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRaceFieldKey.ofHispanicOrigins}
                />

                <OverviewLabel
                    label="Race"
                    html={race}
                    arraySeparator={ArraySeparator.colon}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRaceFieldKey.personRace}
                />

                <OverviewLabel
                    label="US Citizen"
                    html={isUsCitizen}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRaceFieldKey.usCitizen}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default RaceOverview;