import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';

import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import ConfirmationDialog from '../common/ConfirmationDialog';
import { ProductTagRecord, ProductCategoryDisplayLookup, ProductCategory } from '../../shared/types';
import CreateNewTagDialog from './CreateNewTag.dialog';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';
import { GStyles } from '../../styles/GStyles';

interface Props {
    isDialogOpen: boolean;
    tags: ProductTagRecord[];
    category?: ProductCategory;
    isGatherUser: boolean;
    closeDialog: () => void;
    onCreate?: (tag: ProductTagRecord) => void;
    onDelete?: (tag: ProductTagRecord) => void;
    onTagSelect?: (tag: ProductTagRecord) => void;
    zIndex: number;
}

interface State {
    searchText: string;
    selectedTag: ProductTagRecord | null;
    isDeleteConfirmationDialogOpen: boolean;
    isCreateNewTagDialogOpen: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    createNewContainer: {
        width: '100%',
        textAlign: 'right',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)'
    },
    listContainer: {
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    deleteIcon: {
        margin: 0,
        cursor: 'pointer'
    },
    textFieldContainer: {
        textAlign: 'center',
        flexBasis: 0,
    },
});

type StyledProps = Props & WithStyles<'root' | 'dialogPaper' | 'dialogHeader' | 'dialogContent'
    | 'createNewContainer' | 'clearIcon' | 'heading' | 'searchField' | 'listContainer'
    | 'noResultsFoundSection' | 'deleteIcon' | 'textFieldContainer'>;

type CombinedProps = DialogProps & StyledProps;
class AvailableTags extends React.Component<CombinedProps, State> {
    state: State = {
        searchText: '',
        selectedTag: null,
        isDeleteConfirmationDialogOpen: false,
        isCreateNewTagDialogOpen: false,
    };

    protected topscroll: HTMLDivElement;

    registerTopscroll = (topscroll: HTMLDivElement) => {
        this.topscroll = topscroll;
    };

    componentDidUpdate() {
        if (!this.topscroll) {
            return;
        }

        this.topscroll.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });
    }

    applyFilter = (tags: ProductTagRecord[]) => {
        const { category } = this.props;
        const { searchText } = this.state;

        const filteredTags = category ? tags.filter((tag) => tag.category === category) : tags;

        if (searchText) {
            return this.applySearchFilter(filteredTags, searchText);
        }
        return filteredTags;
    };

    applySearchFilter = (tags: ProductTagRecord[], searchText: string) => {
        const search = searchText.toLowerCase();
        return tags.filter((tag) => {
            const category = ProductCategoryDisplayLookup[tag.category].toLowerCase();
            const name = tag.name.toLowerCase();
            const value = tag.value.toLowerCase();
            return category.includes(search) || name.includes(search) || value.includes(search) ||
                search.includes(category) || search.includes(name) || search.includes(value);
        });
    };

    renderNoResultsFoundSection = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography
                    color="secondary"
                    component="p"
                    className={GStyles.marginTop20}
                >
                    Sorry, no results found.
                </Typography>
            </Grid >
        );
    };

    renderTagList = (tags: ProductTagRecord[]) => {
        const { classes, onTagSelect, isGatherUser, onDelete } = this.props;
        if (tags.length === 0) {
            return this.renderNoResultsFoundSection();
        }

        const isClickEnabled = onTagSelect !== undefined;

        return (
            <List component="nav" className={classes.listContainer}>
                <Divider />
                {tags.map((tag) => (
                    <ListItem
                        key={`${tag.category}_${tag.name}_${tag.value}`}
                        button={isClickEnabled ? undefined : false}
                        onClick={isClickEnabled ? () => this.handleTagClick(tag) : undefined}
                        divider
                    >
                        <ListItemText
                            primary={`${ProductCategoryDisplayLookup[tag.category]}: ${tag.name}: ${tag.value}`}
                        />

                        {isGatherUser && onDelete && 
                            <ListItemIcon
                                className={classes.deleteIcon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.toggleDeleteConfirmationDialog(tag);
                                }}
                            >
                                <DeleteIcon />
                            </ListItemIcon>
                        }
                    </ListItem>
                ))}
            </List>
        );
    };

    render() {
        const {
            classes,
            fullScreen,
            isDialogOpen,
            onCreate,
            onDelete,
            tags,
            category,
            isGatherUser,
            zIndex
        } = this.props;
        const { searchText, isDeleteConfirmationDialogOpen, isCreateNewTagDialogOpen } = this.state;

        const deleteDialogHeader = 'Are you sure?';

        const deleteDialogSubHeader = `This tag will remain on any products currently using it
            but will not be available to be added to existing or new products`;

        const deleteDialogConfirmationButton = 'Delete Forever';

        const filteredTags = this.applyFilter(tags);

        const categoryStr = category ? `for ${ProductCategoryDisplayLookup[category]}` : '';

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                    style={{ zIndex }}
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.dialogHeader,
                            GStyles.backgroundPrimary
                        )}
                    >
                        <Clear
                            className={classNames(classes.clearIcon)}
                            onClick={this.handleClose}
                        />
                        <Typography
                            component="p"
                            className={classNames(classes.heading)}
                            align="left"
                        >
                            <span>Available Tags {categoryStr}</span>
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <div ref={this.registerTopscroll} />
                        <Grid item xs={12} className={classes.textFieldContainer}>
                            <form
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    autoComplete="off"
                                    className={classes.searchField}
                                    fullWidth
                                    autoFocus
                                    value={searchText}
                                    id="input-with-icon-textfield"
                                    placeholder="Start typing to filter list..."
                                    name="searchText"
                                    onChange={this.handleSearchChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid className={GStyles.scrollAuto}>
                            {this.renderTagList(filteredTags)}
                        </Grid>
                    </DialogContent>

                    {isGatherUser && onCreate &&
                        <DialogActions>
                            <Grid item className={classes.createNewContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.toggleCreateNewTagDialog()}
                                >
                                    <LabelIcon /> &nbsp;Create New Tag
                                </Button>
                            </Grid>
                        </DialogActions>
                    }
                </Dialog>
                {isGatherUser  && 
                    <>
                        { onCreate && <CreateNewTagDialog
                            isDialogOpen={isCreateNewTagDialogOpen}
                            closeDialog={this.toggleCreateNewTagDialog}
                            onCreateNewTag={onCreate}
                            existingTags={filteredTags}
                            defaultCategory={category}
                            zIndex={zIndex + 1}
                        />}
                        { onDelete && <ConfirmationDialog
                            header={deleteDialogHeader}
                            subHeader={deleteDialogSubHeader}
                            confirmationButtonText={deleteDialogConfirmationButton}
                            onClose={this.toggleDeleteConfirmationDialog}
                            open={isDeleteConfirmationDialogOpen}
                            onConfirm={this.handleDeleteTag}
                            zIndex={zIndex + 1}
                        />}
                    </>
                }
            </>
        );
    }

    handleTagClick = (tag: ProductTagRecord) => {
        const { onTagSelect } = this.props;
        if (onTagSelect) {
            onTagSelect(tag);
            this.handleClose();
        }
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        this.setState({
            searchText: target.value
        });
    };

    handleClose = () => {
        this.setState({ searchText: '' });
        this.props.closeDialog();
    };

    toggleDeleteConfirmationDialog = (tag?: ProductTagRecord) => {
        this.setState((prevState) => ({
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            selectedTag: tag || null,
        }));
    };

    toggleCreateNewTagDialog = () => {
        this.setState((prevState) => ({
            isCreateNewTagDialogOpen: !prevState.isCreateNewTagDialogOpen,
        }));
    };

    handleDeleteTag = () => {
        const { onDelete } = this.props;
        const { selectedTag } = this.state;
        this.toggleDeleteConfirmationDialog();
        if (selectedTag && onDelete) {
            onDelete(selectedTag);
        }
    };
}

export default withFullScreen('md')(withStyles(styles)(AvailableTags));
