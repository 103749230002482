import { useEffect, lazy, Suspense, useMemo, useCallback } from 'react';

import { Route, Routes, useParams, useLocation, useSearchParams } from 'react-router-dom';

import AppSnackbar from './appBars/AppSnackbar';
import DeepLinkRouter from './DeepLinkRouter';
import AppLoader from './common/AppLoader';

import { useGDispatch, useGSelector } from '../types';
import { ProductCategoryEnum, UserRoles } from '../shared/types';

import GlobalDialogs from './GlobalDialogs';
import NoInternetSnackbar from './appBars/NoInternetSnackbar';
import { checkForExistingSession } from '../actions/UserSession.action';
import { internetStatusChanged } from '../actions/App';
import GPhotoSwipe from './profileImage/GPhotoSwipe';
import { extractCaseDataFromIndex } from '../services/indexfile.service';
import CaseLoadingPage from './family/CaseLoadingPage';

import PublicGPLPage from './funeralHomes/goodsAndServices/publicGPL/PublicGPLPage';
import { GPLCategoryLinkLookup } from './family/goodsAndServices';
import PageNotFound from './common/PageNotFound';
import { log } from '../logger';
import { canViewOrganizePage } from '../shared/authority/can';
import useWindowEvent from './common/hooks/useWindowEvent';
import { ErrorBoundary } from '@sentry/react';
import AppErrorPage from './common/AppErrorPage';
import { AdminRoutePage, DESTINATION_QUERY_PARAM, RouteBuilder, updateIntercom } from '../services';
import GNavigate from './navigation/GNavigate';
import LoginNavigate from './navigation/LoginNavigate';
import GNavigateWithString from './navigation/GNavigateWithString';
import { retryLazyLoad } from './routerUtils';
import makeStyles from '@mui/styles/makeStyles';
import { useThemeRelatedGStyles, useGStyles } from '../styles/GStyles';
import { useHideIntercom } from './common/hooks/useHideIntercom';
import Unauthorized from './common/Unauthorized';


const AdminRouter = lazy(() => retryLazyLoad(() => import('./admin/AdminRouter')));
const LoginRouter = lazy(() => retryLazyLoad(() => import('./login/LoginRouter')));
const FamilyRouter = lazy(() => retryLazyLoad(() => import('./family/FamilyRouter')));
const DashboardRouter = lazy(() => retryLazyLoad(() => import('./dashboard/DashboardRouter')));
const RememberRouter = lazy(() => retryLazyLoad(() => import('./remember/RememberRouter')));
const ZebraPrinterRouter = lazy(() => retryLazyLoad(() => import('./zebraPrinter/ZebraPrinterRouter')));
const KeepTrackRouter = lazy(() => retryLazyLoad(() => import('./keeptrack/KeepTrackRouter')));
const KeepTrackEComm = lazy(() => retryLazyLoad(() => import('./keepTrackECommerce/KeepTrackEComm')));
const ReportPage = lazy(() => retryLazyLoad(() => import('./reports/ReportPage')));

enum GatherRouterRouteParam {
    reportUuid = 'reportUuid',
}

const RootComponent = () => {
    const user = useGSelector((s) => s.userSession.userData);
    const defaultFuneralHomeKey = useGSelector((s) => s.userSession.defaultFuneralHomeKey);
    const defaultCase = useGSelector((s) => s.userSession.defaultCase);

    const activeFuneralHomeKey = useGSelector(({ funeralHomeState }) => funeralHomeState.activeFuneralHome?.key);

    if (!user) {
        return <LoginNavigate replace={true} />;
    }

    if (UserRoles.isGOMUser(user)) {
        return <GNavigate to={RouteBuilder.Admin(AdminRoutePage.ROOT)} replace={true} />;
    } else if (UserRoles.isFHUser(user)) {
        const fhKey = activeFuneralHomeKey ?? defaultFuneralHomeKey ?? '';
        if (!fhKey) {
            return <LoginRedirect />;
        }
        return <GNavigate to={RouteBuilder.FuneralHome(fhKey)} replace={true} />;
    } else if (UserRoles.isFamilyRole(user.role)) {
        if (!defaultCase) {
            return <LoginRedirect />;
        } else if (canViewOrganizePage({
            target: user,
            caseId: defaultCase.id,
            funeralHomeId: null,
        })) {
            return <GNavigate to={RouteBuilder.FamilyPage({
                caseName: defaultCase.name,
                funeralHomeKey: defaultCase.funeral_home_key,
            })} replace={true} />;
        } else {
            return <GNavigate to={RouteBuilder.RememberPage(defaultCase.name)} replace={true} />;
        }
    } else {
        log.warn('Unknown user state', { user });
    }

    return <PageNotFound />;
};

// DEPRECATED -- Delete with future release once this warning is no longer seen
const DeprecatedFamilyRouter = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const oldUrlParam = 'oldurl';
    const isOldUrl: string | null = searchParams.get(oldUrlParam);

    const caseName = params.caseName ?? '';
    const rest = params['*'] ?? '';
    log.warn('Family route is being accessed w/o FH context. Hopefully it is an invalid case name', {
        caseName,
        params,
        referrer: document.referrer,
        isOldUrl,
    });

    if (!isOldUrl) {
        // try a refresh to get the SSR API to redirect to the /fh/:fhKey/family/:caseName route
        // but only try ONCE to prevent a loop. If we see the query param then show Page Not Found
        // The API should strip off the query param on redirect
        // We are waiting for 3 seconds to allow the Sentry warning to finish so we can be alerted to this problem
        setTimeout(() => {
            location.href = `${window.location.origin}/family/${caseName}/${rest}?${oldUrlParam}=true`;
        }, 3000);
        return <AppLoader />;
    }
    return <PageNotFound />;
};

// DEPRECATED -- Delete with future release once this warning is no longer seen
const DeprecatedDashboardRouter = () => {
    const params = useParams();
    const funeralHomeKey = params.funeralHomeKey ?? '';
    const rest = params['*'] ?? '';
    log.warn('Dashboard route is still being accessed somehow', {
        funeralHomeKey,
        params,
        referrer: document.referrer,
    });
    return <GNavigateWithString to={`/fh/${funeralHomeKey}/${rest}`} replace={true} />;
};

const LoginRedirect = () => {
    return <LoginNavigate destination="currentPathname" replace={true} />;
};

const PublicGPL = () => {
    const { funeralHomeKey, productCategory } = useParams();

    /**
     * for `productCategory`, we can't be sure if it's of `ProductCategory` Type from URL,
     * so used the below method to detect the correct type
     */
    const category = productCategory
        && GPLCategoryLinkLookup[productCategory]
        || ProductCategoryEnum.care_of_loved_one;

    return (
        <PublicGPLPage
            funeralHomeKey={funeralHomeKey || ''}
            productCategory={category}
        />
    );
};

const GatherAnalyticsReport = (props: { isAuthenticated: boolean }) => {
    const { isAuthenticated } = props;
    const classes = useStyles();
    const { reportUuid } = useParams<GatherRouterRouteParam>();

    if (!isAuthenticated) {
        return <Unauthorized />;
    }
    
    if (!reportUuid) {
        return <PageNotFound />;
    }

    return (
        <main className={classes.root}>
            <ReportPage
                funeralHome={null}
                reportUuid={reportUuid}
            />
        </main>
    );
};

const LoginComponent = (props: { isAuthenticated: boolean }) => {
    const { isAuthenticated } = props;

    const isLoginSuccess = useGSelector((s) => s.userSession.isLoginSuccess);
    const [searchParams] = useSearchParams();

    useHideIntercom();

    if (isAuthenticated) {
        let nextPage: string;
        if (isLoginSuccess) {
            const destination = searchParams.get(DESTINATION_QUERY_PARAM);
            nextPage = destination ?? '/';
        } else {
            nextPage = '/error';
        }
        return <GNavigateWithString to={nextPage} replace={true} />;
    } else {
        return <LoginRouter />;
    }
};

const NotFound = (props: { isAuthenticated: boolean }) => {
    const { isAuthenticated } = props;
    if (isAuthenticated) {
        return <PageNotFound />;
    } else {
        return <LoginRedirect />;
    }
};

const useStyles = makeStyles(({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    mainWrapper: {
        width: '100%',
        display: 'block',
    },
}), { name: 'GatherRouter' });

const GatherRouter = () => {
    useThemeRelatedGStyles();
    useGStyles();

    const classes = useStyles();

    const dispatch = useGDispatch();
    const location = useLocation();

    const userData = useGSelector((s) => s.userSession.userData);
    const isLoginPending = useGSelector((s) => s.userSession.isLoginPending);
    const isLoginSuccess = useGSelector((s) => s.userSession.isLoginSuccess);
    const isAuthCheckPending = useGSelector((s) => s.userSession.isAuthCheckPending);
    const activeFuneralHome = useGSelector((s) => s.funeralHomeState.activeFuneralHome);

    const showCaseLoadingScreen = useMemo(() => extractCaseDataFromIndex().caseName, []);

    useEffect(() => {
        if (!isLoginSuccess && !isLoginPending) {
            dispatch(checkForExistingSession(location.pathname));
        }
    }, [dispatch, isLoginSuccess, isLoginPending, location.pathname]);

    const updateOnlineStatus = useCallback(() => {
        dispatch(internetStatusChanged({ hasInternet: window.navigator.onLine }));
    }, [dispatch]);

    // listen for 'offline' event so we know when user loses internet connection
    useWindowEvent('offline', updateOnlineStatus, true);
    useWindowEvent('online', updateOnlineStatus, false);

    const loadingComponent = showCaseLoadingScreen ? <CaseLoadingPage /> : <AppLoader />;

    const isAuthenticated = Boolean(isLoginSuccess && userData);

    if (!isAuthenticated && isAuthCheckPending) {
        return loadingComponent;
    }

    if (isAuthenticated && userData && activeFuneralHome) {
        updateIntercom(userData, activeFuneralHome.key, activeFuneralHome.id.toString());
    }

    return (
        <ErrorBoundary fallback={<AppErrorPage />}>
            <div className={classes.root}>
                <div className={classes.mainWrapper}>
                    <Suspense fallback={loadingComponent}>
                        <Routes>

                            <Route
                                path="/remember/:caseName/*"
                                element={<RememberRouter />}
                            />

                            <Route
                                path="/zebraprinter/*"
                                element={<ZebraPrinterRouter />}
                            />

                            <Route
                                path="/obituaries/:caseName/*"
                                element={<RememberRouter />}
                            />

                            {/* DEPRECATED -- Delete with future release once we're certain this is unused */}
                            <Route
                                path="/family/:caseName/*"
                                element={<DeprecatedFamilyRouter />}
                            />

                            <Route
                                path="/fh/:funeralHomeKey/family/:caseName/*"
                                element={<FamilyRouter />}
                            />

                            <Route
                                path="/link/:linkText/*"
                                element={<DeepLinkRouter authenticated={isAuthenticated} />}
                            />

                            <Route
                                path="/gpl/:funeralHomeKey/"
                                element={<PublicGPL />}
                            />

                            <Route
                                path="/gpl/:funeralHomeKey/:productCategory"
                                element={<PublicGPL />}
                            />

                            <Route
                                path="/keeptrack/*"
                                element={<KeepTrackRouter />}
                            />

                            <Route
                                path="/login/*"
                                element={<LoginComponent isAuthenticated={isAuthenticated} />}
                            />

                            <Route
                                path="/"
                                element={<RootComponent />}
                            />

                            <Route
                                path="/admin/*"
                                element={<AdminRouter />}
                            />

                            {/* DEPRECATED -- Delete with future release once we're certain this is unused */}
                            <Route
                                path="/dashboard/:funeralHomeKey/*"
                                element={<DeprecatedDashboardRouter />}
                            />

                            <Route
                                path="/fh/:funeralHomeKey/family"
                                element={<GNavigate to={RouteBuilder.Root} replace={true} />}
                            />

                            <Route
                                path="/fh/:funeralHomeKey/*"
                                element={<DashboardRouter />}
                            />

                            <Route
                                path="/remember"
                                element={<GNavigate to={RouteBuilder.Root} replace={true} />}
                            />

                            <Route
                                path="/buy/keeptrack/thanks"
                                element={<KeepTrackEComm isSuccessPage={true} />}
                            />

                            <Route
                                path="/buy/keeptrack/*"
                                element={<KeepTrackEComm isSuccessPage={false} />}
                            />

                            <Route
                                path="*"
                                element={<NotFound isAuthenticated={isAuthenticated} />}
                            />

                            <Route
                                path={`${AdminRoutePage.GATHERANALYTICS}/:${GatherRouterRouteParam.reportUuid}`}
                                element={<GatherAnalyticsReport isAuthenticated={isAuthenticated} />}
                            />

                        </Routes>
                    </Suspense>
                </div>

                <AppSnackbar />
                <NoInternetSnackbar />
                <GlobalDialogs user={userData} />
                <GPhotoSwipe />
            </div>
        </ErrorBoundary>
    );
};

export default GatherRouter;
