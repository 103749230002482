import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { listItemTextClasses } from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import classNames from "classnames";
import includes from "lodash/includes";
import { SortableContainer, SortableElement, SortableHandle, SortEndHandler } from "react-sortable-hoc";
import { ORANGE_COLOR } from "../../../constants/colorVariables";
import makeGStyles from "../../../styles/makeGStyles";
import { DefaultItemType, useAvailableItemsContext } from "./AvailableDefaultItems.dialog";

const useStyles = makeGStyles<Props, Classes>({
    btnContainer: {
        marginLeft: 'auto'
    },
    button: {
        width: 24,
        height: 24,
        '& svg': {
            fontSize: 20
        }
    },
    title: {
        maxWidth: '76%',
        fontSize: 16,
        marginLeft: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    item: {
        padding: '6px 0'
    },
    listStyle: {
        paddingRight: 0,
        paddingLeft: 0,
        minHeight: 68
    },
    opacity40: {
        opacity: 0.40
    },
    displayCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    helper: {
        zIndex: props => props.zIndex + 1
    },
    dragHandle: {
        color: ORANGE_COLOR,
        paddingLeft: 6,
        cursor: 'pointer'
    },
    doneIcon: {
        paddingLeft: 8,
        marginRight: 8,
    },
    savingSpinner: {
        padding: '0px 10px',
    },
    addMultipleItem: {
        '&:hover': {
            opacity: 1
        }
    },
    itemsList: {
        [`& .${listItemTextClasses.root}:first-of-type`]: {
            paddingLeft: '16px'
        }
    },
}, { name: 'AvailableDefaultDialogContent' });


const DragHandle = SortableHandle(DragHandleIcon);
const SortableItem = SortableElement(({ children }: SortableProps) => children);
const SortableList = SortableContainer(({ children }: SortableProps) => children);

const wrapWithSortableList = (content: JSX.Element, helperClass: string, onSortEnd?: SortEndHandler) =>
    <SortableList
        lockAxis="y"
        lockToContainerEdges
        useDragHandle
        helperClass={helperClass}
        onSortEnd={onSortEnd}
    >
        {content}
    </SortableList >;

const wrapWithSortableItem = (content: JSX.Element, itemId: number, index: number) =>
    <SortableItem index={index} key={itemId}>
        {content}
    </SortableItem>;

type Classes = 'btnContainer' | 'button' | 'item' | 'title' | 'listStyle' | 'opacity40' | 'displayCenter'
    | 'helper' | 'dragHandle' | 'doneIcon' | 'savingSpinner' | 'addMultipleItem' | 'itemsList';

interface SortableProps {
    children: JSX.Element;
}

interface ListItemProps {
    key: number;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    divider: boolean;
    className: string;
}
interface Props {
    zIndex: number;
}

const AvailableDefaultDialogContent = <T extends DefaultItemType>(props: Props) => {
    const {
        availableItems,
        isSortable,
        idsSaving,
        canAddMultiple,
        searchText,
        hasItem,
        getTitle,
        getTitleIcon,
        getEndAdornment,
        handleAddClick,
        onSortEnd,
        onCreateNewItemClick,
        getClassName,
    } = useAvailableItemsContext<T>();
    const classes = useStyles(props);

    const filteredItems = !searchText
        ? availableItems
        : availableItems.filter((t) => t.title.toLowerCase().includes(searchText.toLowerCase()));

    const renderListIcon = (item: T, hasTask: boolean) => {
        const isItemSaving = includes(idsSaving, item.id);

        if (hasTask && canAddMultiple) {
            return (
                <ListItemIcon className={classes.doneIcon}>
                    <DoneIcon />
                </ListItemIcon>
            );
        } else if (isItemSaving) {
            return (
                <CircularProgress
                    size={20}
                    className={classes.savingSpinner}
                />
            );
        } else {
            return (
                <ListItemIcon className={classes.doneIcon} onClick={(e) => handleAddClick(e, item)}>
                    <AddIcon />
                </ListItemIcon>
            );
        }
    };

    const renderListItem = (
        content: JSX.Element,
        liProps: ListItemProps,
        id: number,
        isAdded: boolean,
        itemVisible?: boolean
    ) => {
        return (
            !isAdded || canAddMultiple
                ? <ListItemButton
                    {...liProps}
                    key={id}
                    className={classNames(liProps.className, canAddMultiple && classes.addMultipleItem)}
                >
                    {isSortable && <DragHandle className={classes.dragHandle} />}
                    {content}
                </ListItemButton>
                :
                <ListItem {...liProps} key={id} disabled={!itemVisible}>
                    {isSortable && <DragHandle className={classes.dragHandle} />}
                    {content}
                </ListItem>
        );
    };

    const renderContent = () => {
        return (
            <List
                component="nav"
                className={classNames(
                    classes.itemsList,
                    !onCreateNewItemClick && classes.marginBottom20,
                )}
                disablePadding={true}
            >
                {filteredItems.map((item, index) => {
                    const hasItemExist = hasItem(item);
                    const content = (
                        <>
                            {!isSortable && renderListIcon(item, hasItemExist)}
                            {getTitleIcon?.(item)}
                            {getTitle?.(item)}
                            {getEndAdornment?.(item)}
                        </>
                    );

                    const liProps = {
                        key: item.id,
                        onClick: (e: React.MouseEvent<HTMLElement>) => handleAddClick(e, item),
                        divider: true,
                        className: classNames(classes.listStyle,
                            hasItemExist && classes.opacity40,
                            getClassName?.(item)
                        )
                    };

                    return (
                        isSortable ?
                            wrapWithSortableItem(renderListItem(content, liProps, item.id, true, item.isVisible),
                                item.id, index)
                            : renderListItem(content, liProps, item.id, hasItemExist)
                    );
                }
                )}
            </List>
        );
    };

    return (
        isSortable
            ? wrapWithSortableList(renderContent(), classes.helper, onSortEnd)
            : renderContent()
    );
};

export default AvailableDefaultDialogContent;