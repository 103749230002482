import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import { GLOBAL_STYLED_PROPS } from '../../../../../../styles';
import GmapsSearch, { GmapsSearchAddress } from '../../../../../gmapsSearch/GmapsSearch';
import { GmapsSearchType } from '../../../../../../shared/types';
import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../../../styles/WithGStyles';

export interface Props {
    label: string;
    value: GmapsSearchAddress;
    searchType: GmapsSearchType;
    zIndex: number;
    isHiddenFromFamily?: boolean;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    rootClass?: string;
    controlClass?: string;
    textFieldClass?: string;
    labelClass?: string;
    onChange: (value: GmapsSearchAddress) => void;
    handleOpenSnackbar?: () => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        textAlign: 'center',
        margin: 'auto',
        width: '100%',
        '@media (min-width: 421px)': {
            width: 360,
        },
    },
    gmapSearch: {
        width: '100% !important',
        maxWidth: '100% !important',
        '@media (min-width: 421px)': {
            width: '360px  !important',
            maxWidth: '360px !important',
        },
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'gmapSearch'>;

const DCAddressSearch = (props: StyledProps) => {

    const {
        classes,
        error,
        disabled,
        required,
        value,
        searchType,
        label,
        rootClass,
        controlClass,
        textFieldClass,
        labelClass,
        zIndex,
        handleOpenSnackbar,
        onChange,
        isHiddenFromFamily
    } = props;

    return (
        <Grid item className={classNames(classes.root, rootClass)}>
            <GmapsSearch
                controlClasses={classNames(classes.gmapSearch, controlClass)}
                textFieldClass={textFieldClass}
                textLabelClass={labelClass}
                textLabel={label}
                type={searchType}
                value={value}
                onSetPlace={(mp: GmapsSearchAddress) => onChange(mp)}
                error={error || false}
                required={required || false}
                disabled={disabled}
                handleOpenSnackbar={handleOpenSnackbar}
                zIndex={zIndex}
                inputAdornment={{
                    position: 'end',
                    content: <HiddenFromFamilyAdornment showMe={isHiddenFromFamily} />
                }}
            />
        </Grid>
    );
};

export default withGStyles(styles)(DCAddressSearch);
