import classNames from 'classnames';

import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { GatherCaseUX, CaseTaskUX } from '../../../../shared/types';
import AssignTask from '../../../assignmentPoppers/AssignTask';
import GLink from '../../../common/GLink';
import { RouteBuilder } from '../../../../services';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    headingContainer: {
        padding: '20px 0',
        textAlign: 'center',
        borderBottom: '1px solid rgba(0,0,0,0.21)',
    },
    linkstyle: {
        textDecoration: 'none',
        color: '#000000',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    successContentContainer: {
        padding: '40px 0',
        textAlign: 'center',
    },
    personalizedInviteText: {
        fontSize: 14,
        width: 300,
        fontWeight: 300,
        margin: '10px auto',
    },
    eventSuccessFooterContainer: {
        padding: 8,
        textAlign: 'right',
        '@media (max-width: 959px)': {
            position: 'absolute',
            bottom: 0,
            right: 0,
        },
    },
});
type Classes = 'root' | 'headingContainer' | 'linkstyle' | 'cursorPointer' | 'successContentContainer'
    | 'personalizedInviteText' | 'eventSuccessFooterContainer';
type StyledProps = WithStyles<Classes>;

interface Props {
    selectedCase: GatherCaseUX;
    zIndex: number;
    arrangementConferenceTask: CaseTaskUX | null;
    editEventDetails: () => void;
    setActiveEvent: () => void;
    renderVenueDetails: () => JSX.Element;
}

const SuccessScreen = ({
    classes,
    zIndex,
    selectedCase,
    arrangementConferenceTask,
    renderVenueDetails,
    editEventDetails,
    setActiveEvent
}: Props & StyledProps) => {

    return (
        <>
            <Tooltip
                id="tooltip-event-time-location"
                title={`Click to edit the time or location.`}
                placement="top"
            >
                <Grid
                    item
                    xs={12} className={classNames(classes.headingContainer, classes.cursorPointer)}
                    onClick={event => editEventDetails()}
                >
                    {renderVenueDetails()}
                </Grid>
            </Tooltip>

            <Grid item xs={12} className={classes.successContentContainer}>

                {arrangementConferenceTask &&
                    <AssignTask
                        zIndex={zIndex + 1}
                        task={arrangementConferenceTask}
                        activeCase={selectedCase}
                        onlyHelpers
                    />
                }

                <Typography
                    color="primary"
                    variant="subtitle1"
                    component="p"
                    className={classes.personalizedInviteText}
                >
                    Each helper will get a personalized invite from you to the arrangement conference.
                </Typography>

            </Grid>

            <Grid item xs={12} className={classes.eventSuccessFooterContainer}>
                <GLink
                    to={RouteBuilder.FamilyPage({
                        caseName: selectedCase.name,
                        funeralHomeKey: selectedCase.funeral_home.key,
                    })}
                    linkClass={classes.linkstyle}
                >
                    <Button
                        color="primary"
                        size="large"
                        onClick={setActiveEvent}
                    >
                        view {selectedCase.fname}'s case page
                    </Button>
                </GLink>
            </Grid>
        </>
    );
};

export default withStyles(styles)(SuccessScreen);
