import momentTz from 'moment-timezone';
import classNames from 'classnames';
import * as appService from '../../../services/app.service';

import { WithStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import DirectionsIcon from '@mui/icons-material/Directions';

import { RolodexCaseDetails } from '../../../shared/types';
import { deathOrAge, RouteBuilder } from '../../../services';
import UserAvatar from '../../common/UserAvatar';
import { longAddressToAddressString } from '../../../shared/utils';
import Tooltip from '@mui/material/Tooltip';
import GLinkWithButton from '../../common/GLinkWithButton';

type StyleProps = WithStyles<
    'caseDetailContainer' | 'avatar' | 'nameInitialsAvatar' | 'caseName' | 'caseCreatedBy' | 'caseDateofDeath' |
    'viewCaseSection' | 'mainDivider' | 'helperCardSection' | 'leftSection' | 'cardSection' | 'avatarContainer' |
    'caseHelperName' | 'caseRelation' | 'rightSection' | 'contactButtonSection' | 'contactButton' | 'lastChild' |
    'justifyContentCenter' | 'contactDetailsSection' | 'contactLabel' | 'contactText' | 'marginTop8' | 'avatarIcon' |
    'textDecorationNone'
>;

type Props = {
    selectedCase: RolodexCaseDetails;
    resetRolodexUI: () => void;
} & StyleProps;

const CaseDetails = (props: Props) => {
    const { classes, selectedCase, resetRolodexUI } = props;

    if (!selectedCase) {
        return null;
    }

    const dodDate = selectedCase.dodStartDate === null ? '' : selectedCase.dodStartDate;
    const deathFromNow = momentTz(dodDate).fromNow();
    const caseCreatedTime = momentTz(selectedCase.createdDate).fromNow();
    const age = deathOrAge({
        dob_date: selectedCase.dobDate,
        dod_start_date: selectedCase.dodStartDate,
        dod_start_time: selectedCase.dodStartTime
    });

    return <Grid item xs={12} className={classes.caseDetailContainer}>
        <UserAvatar
            user={selectedCase}
            photoClassName={classes.avatar}
            initialsClassName={classes.nameInitialsAvatar}
            size={50}
        />
        <Typography align="center" color="primary" className={classes.caseName}>
            {selectedCase.displayName} {selectedCase.case_number && `(${selectedCase.case_number})`}
        </Typography>
        <Typography align="center" color="secondary" className={classes.caseCreatedBy}>
            Entered {caseCreatedTime} by {selectedCase.createdByName}
        </Typography>
        <Typography align="center" color="secondary" className={classes.caseDateofDeath}>
            Died {deathFromNow}{Boolean(age.trim().length) && ` at Age ${age}`}
        </Typography>
        <Typography align="center" color="secondary" className={classes.caseDateofDeath}>
            {selectedCase.funeralHomeName}
        </Typography>
        <GLinkWithButton
            to={RouteBuilder.FamilyPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeralHomeKey,
            })}
            GButtonProps={{ color: 'primary', variant: 'outlined', onClick: resetRolodexUI }}
            buttonClass={classes.viewCaseSection}
        >
            <UserAvatar
                user={selectedCase}
                className={classes.avatarIcon}
            />&nbsp;View {selectedCase.fname}'s Case
        </GLinkWithButton>

        <Divider color="secondary" className={classes.mainDivider} />

        <Grid
            item
            xs={12}
            className={classNames(
                classes.helperCardSection,
                selectedCase.helpers.length < 2 && classes.justifyContentCenter
            )}
        >
            {selectedCase.helpers.map((caseData, index) => {
                const sectionSide = (index === 0 || index % 2 === 0) ? 'leftSection' : 'rightSection';
                const caseGeoHref = caseData.location !== null && caseData.location.coords
                    ? `https://google.com/maps/search/?api=1&query=${encodeURIComponent(
                        `${caseData.location.coords[0]},${caseData.location.coords[1]}`
                    )}`
                    : '';
                const isLastItem = selectedCase.helpers.length === (index + 1);

                return (
                    <Grid
                        key={caseData.fullName}
                        item
                        xs={12}
                        className={classNames(classes[sectionSide], isLastItem && classes.lastChild)}
                    >
                        <Grid item xs={12} className={classes.cardSection}>
                            <div className={classes.avatarContainer}>
                                <UserAvatar
                                    user={caseData}
                                    photoClassName={classNames(classes.avatar)}
                                    initialsClassName={classNames(classes.nameInitialsAvatar)}
                                    size={48}
                                />
                            </div>
                            <Typography align="center" color="primary" className={classes.caseHelperName}>
                                {caseData.fullName}
                            </Typography>
                            <Typography align="center" color="secondary" className={classes.caseRelation}>
                                {`${caseData.relationship && caseData.relationship || ''}
                                    ${caseData.deceased && ' (Deceased)' || ''}`}
                            </Typography>
                            <Grid item xs={12} className={classes.contactButtonSection}>
                                {caseData.phone &&
                                    <Tooltip title={`Click to call ${caseData.fname}`} placement="top">
                                        <IconButton
                                            color="primary"
                                            className={classes.contactButton}
                                            href={`tel:+1${caseData.phone}`}
                                            size="large">
                                            <CallIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>}
                                {caseData.phone &&
                                    <Tooltip title={`Click to text ${caseData.fname}`} placement="top">
                                        <IconButton
                                            color="primary"
                                            className={classes.contactButton}
                                            href={`sms:+1${caseData.phone}`}
                                            size="large">
                                            <SmsIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>}
                                {caseData.email &&
                                    <Tooltip title={`Click to email ${caseData.fname}`} placement="top">
                                        <IconButton
                                            color="primary"
                                            className={classes.contactButton}
                                            href={`mailto:${caseData.email}`}
                                            size="large">
                                            <EmailIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>}
                                {(caseData.location && caseData.location.coords) &&
                                    <Tooltip title={'Click for directions'} placement="top">
                                        <IconButton
                                            color="primary"
                                            className={classes.contactButton}
                                            href={caseGeoHref}
                                            size="large">
                                            <DirectionsIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>}
                            </Grid>

                            <Grid item xs={12} className={classes.contactDetailsSection}>
                                {caseData.email &&
                                    <>
                                        <Typography color="secondary" className={classes.contactLabel}>
                                            <EmailIcon />
                                            Contact's Email
                                        </Typography>
                                        <Typography color="primary" className={classes.contactText}>
                                            {caseData.email}
                                        </Typography>
                                    </>}

                                {caseData.location &&
                                    <>
                                        <Typography
                                            color="secondary"
                                            className={classNames(
                                                classes.contactLabel, classes.marginTop8
                                            )}
                                        >
                                            <LocationOnIcon />
                                            Contact's Location
                                        </Typography>
                                        <Typography color="primary" className={classes.contactText}>
                                            {longAddressToAddressString(caseData.location)}
                                        </Typography>
                                    </>}

                                {caseData.phone &&
                                    <>
                                        <Typography
                                            color="secondary"
                                            className={classNames(
                                                classes.contactLabel, classes.marginTop8
                                            )}
                                        >
                                            <CallIcon />Contact's Phone
                                        </Typography>
                                        <Typography color="primary" className={classes.contactText}>
                                            {appService.getFormattedPhoneNumber(caseData.phone)}
                                        </Typography>
                                    </>}
                            </Grid>

                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    </Grid>;
};

export default CaseDetails;
