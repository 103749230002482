import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountDeactivateStep, OnlineAccountType } from '../utils';

export interface LogInToAccountQueProps {
    caseFName: string;
    accountType: OnlineAccountType;
    onStepChange: (questionType: OnlineAccountDeactivateStep) => void;
}

const LogInToAccountQue = (props: LogInToAccountQueProps) => {
    const { caseFName, accountType, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <Fade in>
            <Grid>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Deleting {caseFName}'s {accountType} account can help remove any unwanted charges while
                    also helping to keep {caseFName}'s information secure. We’ve tried to make this process
                    as easy as possible, but please let us know if you have any questions.
                </Typography>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.fontSize28,
                        classes.fontWeight300,
                        classes.marginTop50
                    )}
                >
                    Are you able to log in to {caseFName}'s {accountType} account?
                </Typography>
                <Grid container className={classes.cancelAccConfirmationBtn}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => onStepChange(OnlineAccountDeactivateStep.CanLoginType)}
                            className={classes.margin_8}
                        >
                            YES
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => onStepChange(OnlineAccountDeactivateStep.AccessToEmailQueType)}
                            className={classes.margin_8}
                        >
                            NO
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default LogInToAccountQue;
