import { useCallback, useState } from 'react';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { LocalizationProvider, PickersActionBar, PickersActionBarProps } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Grid from '@mui/material/Grid';
import momentTz from 'moment-timezone';
import GDialog from './GDialog';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';


const useStyles = makeStyles((theme) => ({
    header: {
        zIndex: 1,
        padding: 14,
        '& .MuiPickersToolbar-root': {
            gridColumn: '1/4',
        },
        '& .MuiPickersLayout-contentWrapper': {
            gridColumn: '1/4',
        },
        '& .MuiDateTimePickerToolbar-ampmLabel': {
            opacity: .2,
        },
        '& .Mui-selected': {
            opacity: 1,
        },
        '& .MuiDialogContent-root': {
            padding: 0,
        }
    },
    dialogContent: {
        padding: '0',
    },
    deleteButton: {
        color: '#ff0000',
        gridColumn: '1/1',
        gridRow: '3/4',
    },
}));

const CustomActionBar = (props: PickersActionBarProps & {
    onCloseDialog: () => void;
    handleDateChange: (newDate: momentTz.Moment | null) => void;
    hasDefaultTimestamp?: boolean;
}) => {
    const classes = useStyles();
    const { onCloseDialog, onCancel, handleDateChange, hasDefaultTimestamp, ...otherProps } = props;
    const handleCancelClick = useCallback(() => {
        onCancel();
        onCloseDialog();
    }, [onCancel, onCloseDialog]);
    return (
        <>
            <PickersActionBar {...otherProps} onCancel={handleCancelClick} />
            {hasDefaultTimestamp && <Button
                className={classes.deleteButton}
                onClick={() => handleDateChange(null)}
            >
                <DeleteIcon />
                Delete Due Date
            </Button>}
        </>
    );
};

interface Props {
    onUpdate: (newDate: momentTz.Moment | null) => void;
    title?: string;
    zIndex: number;
    isDialogOpen: boolean;
    defaultTimestamp?: Date;
    timezone?: string;
    onClose: () => void;
    isReadOnly: boolean;
    enablePast?: boolean;
}

const GatherDateTimePicker = (props: Props) => {
    const classes = useStyles();
    const {
        defaultTimestamp,
        zIndex,
        isDialogOpen,
        onClose,
        title,
        onUpdate,
        timezone,
        isReadOnly,
        enablePast,
    } = props;
    const hasDefaultTimestamp = defaultTimestamp !== undefined;
    const initialValue = defaultTimestamp
        ? momentTz(defaultTimestamp)
        : timezone
            ? momentTz.tz(timezone)
            : momentTz.tz('America/Denver');

    const [selectedDate, handleDateChangeState] = useState<momentTz.Moment | null>(initialValue);

    const handleDateChange = (date: momentTz.Moment | null) => {
        handleDateChangeState(date);
        onUpdate(date);
        closeDialog();
    };

    const closeDialog = () => {
        onClose();
    };
    const thisTimezone = timezone || 'America/Denver';
    const thisShortTimezone = initialValue.tz(thisTimezone).zoneAbbr();
    return (
        <GDialog
            isOpen={isDialogOpen}
            contentClass={classes.dialogContent}
            zIndex={zIndex}
            onClose={closeDialog}
            title={title || `Select Date and Time in ${thisShortTimezone}`}
        >
            <Grid container>
                <Grid className={classes.header} item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StaticDateTimePicker
                            timezone={thisTimezone}
                            localeText={{
                                toolbarTitle: 'Select Date & Time (' + thisShortTimezone + ')',
                            }}
                            disablePast={!enablePast}
                            slots={{
                                actionBar: (actionBarProps) => (
                                    <CustomActionBar
                                        hasDefaultTimestamp={hasDefaultTimestamp}
                                        handleDateChange={handleDateChange}
                                        {...actionBarProps}
                                        onCloseDialog={onClose} />
                                ),
                            }}
                            value={selectedDate}
                            onAccept={handleDateChange}
                            readOnly={isReadOnly}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </GDialog>
    );
};

export default GatherDateTimePicker;
