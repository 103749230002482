import * as t from 'io-ts';
import { getValidator } from './utils';

// ---> DeathCertificateConfig <---
export interface DeathCertificateConfigRecord {
    id: number;
    name: string;
    funeral_home_id: number | null;
    cloned_from: number;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface DeathCertificateConfigSummary {
    id: number;
    name: string;
    funeral_home_id: number | null;
    funeral_home_name: string | null;
    funeral_home_state: string | null;
}

// ---> DeathCertificateConfigRevision <---
export interface DeathCertificateConfigRevisionRecord {
    id: number;
    config_id: number;
    replaces: number | null;
    cloned_from: number | null;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
}

export interface DeathCertificateConfigRevisionUXRecord extends DeathCertificateConfigRevisionRecord {
    rev_number: number;
    latest_rev_id: number;
}

export interface DeathCertificateConfigRevisionUX extends DeathCertificateConfigRevisionUXRecord {
    fields: DeathCertificateConfigFieldUX[];
}

// ---> DeathCertificateConfigField <---
export interface DeathCertificateConfigFieldRecord {
    ui_field_key: string;
    config_rev_id: number;
    rank: number;
    is_required: boolean;
    is_hidden_from_family: boolean;
    options: string[] | null;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
}

export interface DeathCertificateConfigFieldUX extends DeathCertificateConfigFieldRecord {
}

export interface DeathCertificateConfigUX extends DeathCertificateConfigRecord {
    revision: DeathCertificateConfigRevisionUX;
    is_latest_revision: boolean;
    funeral_home_id: number;
}

// ---> DeathCertificateConfigCreateRequest <---
const dcConfigCreateRequestDefinition = {
    name: t.string,
    cloned_from: t.number,
    make_default: t.boolean,
};
const DCConfigCreateRequestType = t.type(dcConfigCreateRequestDefinition);

export interface DeathCertificateConfigCreateRequest extends t.TypeOf<typeof DCConfigCreateRequestType> {
}

export class DeathCertificateConfigCreateRequest {
    public static fromRequest = getValidator<DeathCertificateConfigCreateRequest>(DCConfigCreateRequestType);
}

// ---> DeathCertificateConfigFieldSaveRequest <---
const dcConfigFieldSaveRequestRequired = {
    is_required: t.boolean,
    is_hidden_from_family: t.boolean,
    options: t.union([t.array(t.string), t.null]),
};
const DCConfigFieldSaveRequestType = t.type(dcConfigFieldSaveRequestRequired);

export interface DeathCertificateConfigFieldSaveRequest extends t.TypeOf<typeof DCConfigFieldSaveRequestType> {
}

export class DeathCertificateConfigFieldSaveRequest {
    public static fromRequest = getValidator<DeathCertificateConfigFieldSaveRequest>(DCConfigFieldSaveRequestType);
}

// ---> DeathCertificateConfigUpdateRequest <---
const dcConfigUpdateRequestDefinition = {
    name: t.string,
};
const DCConfigUpdateRequestType = t.partial(dcConfigUpdateRequestDefinition);

export interface DeathCertificateConfigUpdateRequest extends t.TypeOf<typeof DCConfigUpdateRequestType> {
}

export class DeathCertificateConfigUpdateRequest {
    public static fromRequest = getValidator<DeathCertificateConfigUpdateRequest>(DCConfigUpdateRequestType);
}

export interface DeathCertificateConfigFieldSaveResponse {
    savedFieldKey: string;
    updatedConfig: DeathCertificateConfigUX;
}

export const deathCertificateUXToSummary = (config: DeathCertificateConfigUX): DeathCertificateConfigSummary => {
    return {
        id: config.id,
        name: config.name,
        funeral_home_id: config.funeral_home_id,
        funeral_home_name: null,
        funeral_home_state: null,
    };
};
