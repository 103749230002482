import { useState, useEffect } from "react";
import classNames from "classnames";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import {
    CaseEntityUX,
    EntityCaseRole,
    EntityRelationship,
    EntityRelationshipType,
    GlobalDialog,
    UserProfile
} from "../../../shared/types";
import makeGStyles from "../../../styles/makeGStyles";
import AcquaintanceButtons from "../../common/AcquaintanceButtons";
import UserAvatar from "../../common/UserAvatar";
import useFullScreen from "../../common/hooks/useFullScreen";
import CommunicationPreference from "./CommunicationPreference";
import { getFullNameFromCase, isRememberPage, RouteBuilder } from "../../../services";
import GLink from "../../common/GLink";
import { HelperUserSettingsTab } from "./HelperUserSettings";
import { canRetrievePrivateCaseData } from "../../../shared/authority/can";
import {
    NotificationMethodType,
    NotificationMethod,
    SubscriptionType,
    SubscriptionTypeType,
} from '../../../shared/types/subscription';

const useStyles = makeGStyles(theme => ({
    relationshipContent: {
        width: '100%',
        maxWidth: 270,
        padding: '0 12px',
        textAlign: 'center'
    },
    content: {
        alignItems: 'center',
        flexDirection: 'column',
        width: 'max-content',
        margin: '40px auto 0',
        [theme.breakpoints.up(768)]: {
            alignItems: 'flex-start',
            flexDirection: 'row',
        }
    },
    paddingTop12: {
        paddingTop: 12
    },
    communicationPreferences: {
        padding: '0 20px',
        maxWidth: 600
    },
    horizDivider: {
        boxSizing: 'border-box',
        margin: '20px auto',
        width: 'calc(100% - 40px)',
        maxWidth: '560px'
    },
    avatar: {
        width: 100,
        height: 100,
        fontSize: 40,
        fontWeight: 300
    },
    caseItem: {
        transition: `border-bottom ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        paddingBottom: 2,
        borderBottom: `4px solid transparent`
    },
    active: {
        borderBottom: `4px solid ${theme.palette.primary.main}`
    },
    casesContainer: {
        '& $hasActive': {
            '& $caseItem:not($active)': {
                opacity: '0.2 !important',
                '&:hover': {
                    opacity: '0.8 !important'
                },
            },
        },
        '& $mainSection': {
            '&:hover $caseItem': {
                opacity: 0.5,
            },
            '& $active': {
                opacity: '1 !important',
                '&:hover': {
                    opacity: '1 !important'
                },
            },
            '& $caseItem': {
                '&:hover': {
                    opacity: '1 !important',
                    cursor: 'pointer'
                },
            }
        },
    },
    mainSection: {},
    hasActive: {},
}), { name: 'RelationshipAndCommunicationSettings' });

interface Props {
    caseFName: string;
    userFName: string;
    entityCaseRole: EntityCaseRole | null;
    relationship: EntityRelationship | null;
    relationshipType: EntityRelationshipType | null;
    relationshipAlias: string | null;
    saveAttempted: boolean;
    entityRelatedCases: CaseEntityUX[];
    userEmail: string | null;
    userPhone: string | null;
    fhName: string | null;
    fhCity: string | null;
    selectedCaseId: number | null;
    isMe: boolean;
    selectedEntityId: number;
    userData: UserProfile;

    subNotifyCaseServiceReminderViaEmail: boolean;
    subNotifyCaseServiceReminderViaSms: boolean;
    subNotifyCaseNewMemoriesViaEmail: boolean;
    subNotifyCaseNewMemoriesViaSms: boolean;

    onSubscriptionChange: (args: {
        notify: boolean; notifyMethod: NotificationMethodType; subscriptionType: SubscriptionTypeType;}) => void;
    onAddPhoneOrEmail: () => void;
    onRelationshipChange: (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string
    ) => void;
}

const RelationshipAndCommunicationSettings = (props: Props) => {
    const {
        caseFName,
        userFName,
        entityCaseRole,
        relationship,
        relationshipAlias,
        relationshipType,
        saveAttempted,
        entityRelatedCases,
        userEmail,
        userPhone,
        fhName,
        fhCity,
        selectedCaseId,
        isMe,
        selectedEntityId,
        userData,
        onAddPhoneOrEmail,
        onRelationshipChange,
        onSubscriptionChange,
        subNotifyCaseServiceReminderViaEmail,
        subNotifyCaseServiceReminderViaSms,
        subNotifyCaseNewMemoriesViaEmail,
        subNotifyCaseNewMemoriesViaSms,
    } = props;

    const classes = useStyles();
    const isMdUp = useFullScreen(768);

    const defaultNotifyWhenPostedNew_Email = !!(userEmail && subNotifyCaseNewMemoriesViaEmail);
    const defaultKeepMeUpdated_Email = !!(userEmail && subNotifyCaseServiceReminderViaEmail);
    const defaultNotifyWhenPostedNew_Phone = !!(userPhone && subNotifyCaseNewMemoriesViaSms);
    const defaultKeepMeUpdated_Phone = !!(userPhone && subNotifyCaseServiceReminderViaSms);


    const [
        notifyWhenPostedNew,
        setNotifyWhenPostedNew
    ] = useState({ email: !!defaultNotifyWhenPostedNew_Email, phone: !!defaultNotifyWhenPostedNew_Phone });

    const [
        keepMeUpdated,
        setKeepMeUpdated
    ] = useState({ email: !!defaultKeepMeUpdated_Email, phone: !!defaultKeepMeUpdated_Phone });

    useEffect(() => {
        if (selectedEntityId && subNotifyCaseNewMemoriesViaEmail !== notifyWhenPostedNew.email) {
            onSubscriptionChange({
                notify: notifyWhenPostedNew.email,
                notifyMethod: NotificationMethod.email,
                subscriptionType: SubscriptionType.case_new_memories});
        }
        if (selectedEntityId && subNotifyCaseNewMemoriesViaSms !== notifyWhenPostedNew.phone) {
            onSubscriptionChange ({
                notify: notifyWhenPostedNew.phone,
                notifyMethod: NotificationMethod.sms,
                subscriptionType: SubscriptionType.case_new_memories});
        }
    }, [onSubscriptionChange,
        notifyWhenPostedNew,
        selectedEntityId,
        subNotifyCaseNewMemoriesViaEmail,
        subNotifyCaseNewMemoriesViaSms
    ]);

    useEffect(() => {
        if (selectedEntityId && subNotifyCaseServiceReminderViaEmail !== keepMeUpdated.email) {
            onSubscriptionChange({
                notify: keepMeUpdated.email, 
                notifyMethod: NotificationMethod.email, 
                subscriptionType: SubscriptionType.case_service_summary});
            onSubscriptionChange({notify: keepMeUpdated.email,
                notifyMethod: NotificationMethod.email,
                subscriptionType: SubscriptionType.case_service_reminder});
        }
        if (selectedEntityId && subNotifyCaseServiceReminderViaSms !== keepMeUpdated.phone) {
            onSubscriptionChange({
                notify: keepMeUpdated.phone,
                notifyMethod: NotificationMethod.sms,
                subscriptionType: SubscriptionType.case_service_summary});
            onSubscriptionChange({
                notify: keepMeUpdated.phone,
                notifyMethod: NotificationMethod.sms,
                subscriptionType: SubscriptionType.case_service_reminder});
        }

    }, [onSubscriptionChange,
        keepMeUpdated,
        selectedEntityId,
        subNotifyCaseServiceReminderViaEmail,
        subNotifyCaseServiceReminderViaSms,
    ]);

    return (
        <Grid item xs={12}>
            <Grid
                container
                overflow="auto hidden"
                className={classes.casesContainer}
            >
                <Grid
                    container
                    columnSpacing={2}
                    m="0 auto"
                    width="fit-content"
                    wrap="nowrap"
                    className={classNames(classes.mainSection, !!selectedCaseId && classes.hasActive)}
                >
                    {entityRelatedCases.map(gCase => {
                        const data = encodeURIComponent(JSON.stringify({
                            selectedEntityId,
                            activeTab: HelperUserSettingsTab.relationship,
                        }));
                        const familyRoute = RouteBuilder.FamilyPage({
                            caseName: gCase.gather_case_name,
                            funeralHomeKey: gCase.funeral_home_key,
                            query: { data }
                        });
                        const rememberRoute = RouteBuilder.RememberPage(
                            gCase.gather_case_name,
                            undefined,
                            GlobalDialog.MySettings,
                            { data }
                        );

                        const canAccessFamilyPage = canRetrievePrivateCaseData({
                            caseId: gCase.gather_case_id,
                            funeralHomeId: gCase.funeral_home_id,
                            target: userData
                        });
                        const route = !isRememberPage() && canAccessFamilyPage
                            ? familyRoute
                            : rememberRoute;

                        return (
                            <Grid key={gCase.case_entity_id} item>
                                <GLink to={route}>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classNames(
                                            classes.caseItem,
                                            selectedCaseId === gCase.gather_case_id && classes.active
                                        )}
                                    >
                                        <UserAvatar
                                            capitalizeInitials
                                            size={100}
                                            user={{
                                                fname: gCase.case_fname,
                                                lname: gCase.case_lname,
                                                photo: gCase.case_photo,
                                                photo_transformations: gCase.case_photo_transformations
                                            }}
                                            className={classes.avatar}
                                        />

                                        <Typography align="center" textTransform="capitalize">
                                            {getFullNameFromCase({ fname: gCase.case_fname, lname: gCase.case_lname })}
                                        </Typography>
                                    </Grid>
                                </GLink>
                            </Grid>
                        );
                    }
                    )}
                </Grid>
            </Grid>

            <Grid
                container
                wrap="nowrap"
                className={classNames(classes.width100, classes.content)}
            >
                <div className={classes.relationshipContent}>
                    <AcquaintanceButtons
                        caseFName={caseFName}
                        userFName={userFName}
                        formType={entityCaseRole === EntityCaseRole.admin
                            && 'INVITE_ADMIN' || 'INVITE_GUEST'}
                        setRelationship={onRelationshipChange}
                        relationshipType={relationshipType}
                        relationship={relationship}
                        relationshipAlias={relationshipAlias}
                        saveAttempted={saveAttempted}
                    />
                </div>

                {!isMdUp
                    ? <Divider flexItem orientation="vertical" />
                    : <Divider flexItem className={classes.horizDivider} />
                }

                <Grid item xs={12} className={classes.communicationPreferences}>
                    <Typography
                        color="primary"
                        align="center"
                        className={classNames(classes.marginBottom8, classes.fontSize16)}
                    >
                        Your Communication Preferences
                    </Typography>

                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" className={classes.marginBottom5}>
                            <Typography color="secondary" minWidth="42px" align="center">
                                Email
                            </Typography>
                            <Typography color="secondary" minWidth="42px" align="center">
                                Text
                            </Typography>
                        </Grid>

                        <CommunicationPreference
                            label={`I want to receive important updates & reminders about ${caseFName}’s Services` +
                                ` such as LiveStream and event details.`}
                            email={userEmail}
                            phone={userPhone}
                            sendMail={keepMeUpdated.email}
                            sendText={keepMeUpdated.phone}
                            isMe={isMe}
                            onAddPhoneOrEmail={onAddPhoneOrEmail}
                            onMailToggle={checked => setKeepMeUpdated(prev => ({ ...prev, email: checked }))}
                            onPhoneToggle={checked => setKeepMeUpdated(prev => ({ ...prev, phone: checked }))}
                        />

                        <CommunicationPreference
                            label={` I want to receive a recap of any new memories and photos shared to` +
                                ` ${caseFName}'s Remember page.`}
                            email={userEmail}
                            phone={userPhone}
                            sendMail={notifyWhenPostedNew.email}
                            sendText={notifyWhenPostedNew.phone}
                            isMe={isMe}
                            onAddPhoneOrEmail={onAddPhoneOrEmail}
                            onMailToggle={checked => setNotifyWhenPostedNew(prev => ({ ...prev, email: checked }))}
                            onPhoneToggle={checked => setNotifyWhenPostedNew(prev => ({ ...prev, phone: checked }))}
                        />
                    </Grid>

                    <Divider className={classNames(classes.marginBottom5, classes.marginTop2)} />

                    <Typography
                        color="primary"
                        align="center"
                        className={classNames(classes.paragraphLineHeight, classes.fontSize16)}
                    >
                        {caseFName}'s care was under the direction of {fhName} ({fhCity}).
                    </Typography>

                </Grid>
            </Grid>
        </Grid >
    );
};

export default RelationshipAndCommunicationSettings;