import classNames from 'classnames';

import { generateNameInitials } from '../../services';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Avatar from '@mui/material/Avatar';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import ContactsIcon from '@mui/icons-material/Contacts';

import PersonAdd from '@mui/icons-material/PersonAdd';
import CheckIcon from '@mui/icons-material/Check';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { UserRoles, LongAddress, GmapsSearchType, isLongAddress } from '../../shared/types';
import { useGSelector } from '../../types';

import PhoneNumberMask from '../common/PhoneNumberMask';
import GGroupButtons from '../common/GGroupButtons';

import { splitFullName } from '../../shared/utils';
import { SKYBLUE_COLOR_2 } from '../../constants/colorVariables';
import GmapsSearch, { GmapsSearchAddress } from '../gmapsSearch/GmapsSearch';
import { DCEntityEnum } from '../assignmentPoppers/SelectHelper';

import { grey, green } from '@mui/material/colors';
import { AliveType, InviteMethod } from './InvitationForm';
import makeGStyles from '../../styles/makeGStyles';
import { InvitationFormStyles } from './InvitationFormStyles';
import { HelperInvitationDialogContext } from '../../actions/Dialog.action';

interface Props {
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    setInvitationFormDirty: (isFormDirty: boolean) => void;
    relationToDeceased: DCEntityEnum | null;
    zIndex: number;
    showStep2?: boolean;
    fullName: string;
    email: string;
    phone: string;
    isFocused: boolean;
    inviteAttempted: boolean;
    aliveType: AliveType;
    inviteMethod: InviteMethod;
    homeAddress: LongAddress;
    addressOptional: boolean;
    dontSendInvite: boolean;
    isPhoneValid: boolean;
    isEmailValid: boolean;
    isAddressValid: boolean;
    isInvitationSuccess: boolean;
    isDeceased: boolean;
    isPending: boolean;
    openRolodexSearchDialog: () => void;
    showPhoneTextField: boolean;
    showEMailTextField: boolean;
    context: HelperInvitationDialogContext | null;
    handleDeceasedToggleOnClick: (aliveType: AliveType, inviteAttempted: boolean) => void;
    handleOnChangePhone: (phone: string) => void;
    handleOnChangeHomeAddress: (addr: LongAddress) => void;
    handleOnChangeEmail: (email: string) => void;
    handleOnChangeName: (value: string) => void;
    renderInvitationGroupButtons: () => JSX.Element;
    funeralHomeID: number;
}

const useStyles = makeGStyles(theme => ({
    emptyAvatarSuccess: {
        width: 90,
        height: 90,
        margin: 'auto',
        border: 'none',
        color: theme.palette.background.paper,
        background: green[500],
        transition: 'all .8s ease-in-out !important',
    },
    emptyAvatar: {
        boxSizing: 'border-box',
        width: 90,
        height: 90,
        border: '2px dashed',
        color: grey[600],
        transition: 'all .8s ease-in-out  !important',
        background: theme.palette.background.paper,
    },
    nameInitialsAvatar: {
        width: 90,
        height: 90,
        margin: 'auto',
        background: grey[400],
        textTransform: 'uppercase',
        fontSize: 40,
        fontWeight: 200,
        '-webkit-font-smoothing': 'antialiased',
    },
    avatarProgress: {
        color: green[500],
        position: 'absolute',
        zIndex: 1,
        top: '-5px',
        left: 'calc(50% - 50px)',
    },
    overlapDot: {
        top: 4,
        left: 4,
        fontSize: 12,
        background: SKYBLUE_COLOR_2,
        height: '1em',
        width: '1em',
        borderRadius: '50%',
        zIndex: 1,
        position: 'absolute',
    },
    avatarContainer: {
        width: 90,
        height: 90,
        margin: 'auto',
        position: 'relative',
    },
    deceasedToggle: {
        margin: '20px 0',
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(2)': {
                marginTop: -1
            }
        },
    },
    error: {
        color: theme.palette.error.main,
        borderRadius: '0px 8px 8px 0px',
    },
    dcEntityForm: {
        height: 'auto !important',
        margin: 'auto 0',
        zIndex: 2
    },
    adronmentIconButton: {
        position: 'absolute',
        right: '-4px',
        top: '-8px',
        width: 40,
        height: 40,
        '& svg': {
            fontSize: 22
        }
    },
    paddingRight26: {
        paddingRight: 26
    },
    nameLabel: {
        maxWidth: 'calc(100% - 26px)',
    },
    nameLabelFocused: {
        maxWidth: '100%',
    },
    inner: {},
    ...InvitationFormStyles(theme),
}), { name: 'InvitationFormLeftSection' });

const InvitationFormLeftSection = (props: Props) => {

    const {
        formType,
        setInvitationFormDirty,
        relationToDeceased,
        zIndex,
        showStep2,
        fullName,
        email,
        phone,
        isFocused,
        inviteAttempted,
        aliveType,
        inviteMethod,
        homeAddress,
        addressOptional,
        dontSendInvite,
        isPhoneValid,
        isEmailValid,
        isAddressValid,
        isInvitationSuccess,
        isDeceased,
        isPending,
        openRolodexSearchDialog,
        showPhoneTextField,
        showEMailTextField,
        context,
        handleDeceasedToggleOnClick,
        handleOnChangePhone,
        handleOnChangeHomeAddress,
        handleOnChangeEmail,
        handleOnChangeName,
        renderInvitationGroupButtons,
        funeralHomeID,
    } = props;

    const classes = useStyles();

    const userData = useGSelector(({ userSession }) => userSession.userData);

    const { fname } = splitFullName(fullName);

    const isPhoneOrEmailOnly = inviteMethod === InviteMethod.phone ||
        inviteMethod === InviteMethod.email;
    const isPhoneAndEmailBoth = inviteMethod === InviteMethod.both;

    const firstName = fname
        || formType === 'INVITE_ADMIN' && 'Admin'
        || relationToDeceased
        || 'Guest';

    const isFHorGOMUserOnFH = UserRoles.isFHorGOMUserOnFH(userData, funeralHomeID);

    const showOverlapDot = formType === 'INVITE_ADMIN';

    const isObituaryAddPerson = context === HelperInvitationDialogContext.addPreceededInDeath
        || context === HelperInvitationDialogContext.addSurvivors;

    const renderNameInitialsAvatar = () => {
        return (
            <div className={classes.avatarContainer}>
                {showOverlapDot && <span className={classes.overlapDot} style={{ top: 12, left: 4 }} />}
                <Avatar className={classNames(classes.nameInitialsAvatar)}>
                    {generateNameInitials(fullName, '')}
                </Avatar>
                {isPending &&
                    <CircularProgress
                        thickness={2}
                        variant="indeterminate"
                        size={100}
                        className={classes.avatarProgress}
                    />
                }
            </div>
        );
    };

    const renderEmptyAvatar = () => {
        return (
            <div className={classes.avatarContainer}>
                {showOverlapDot && !isInvitationSuccess &&
                    <span className={classes.overlapDot} style={{ top: 8, left: 0, fontSize: 20, }} />}
                <Avatar
                    className={classNames(
                        classes.emptyAvatar,
                        classes.colorPrimary,
                        isInvitationSuccess && classes.emptyAvatarSuccess || undefined
                    )}
                >
                    <Zoom
                        in={isInvitationSuccess}
                        timeout={{
                            enter: 400,
                            exit: 800,
                        }}
                    >
                        <CheckIcon className={classes.fontSize40} />
                    </Zoom>
                </Avatar>
            </div>
        );
    };

    const getFullNameTextFieldLabel = () => {
        return relationToDeceased && `${relationToDeceased}'s Full Name`
            || dontSendInvite && 'Name of person to preserve'
            || isDeceased && 'Name of deceased person'
            || formType === 'INVITE_ADMIN' && 'Name of Admin to invite'
            || 'Name of person to invite';
    };

    return (
        <form
            className={classNames(
                classes.leftSection,
                classes.leftSectionHeightTransition,
                formType === 'INVITE_ADMIN' && classes.adminTabLeftSectionHeight ||
                classes.guestTabLeftSectionHeight,
                (relationToDeceased || isObituaryAddPerson) && showStep2 && classes.dcEntityForm,
                (relationToDeceased || isObituaryAddPerson) && classes.heightAuto,
                formType === 'INVITE_ADMIN' && isPhoneOrEmailOnly && classes.active,
                formType === 'INVITE_GUEST' && isPhoneAndEmailBoth && classes.active,
                formType === 'INVITE_GUEST' && isDeceased && classes.isDeceased
            )}
            noValidate
            autoComplete="off"
        >
            {relationToDeceased !== DCEntityEnum.Informant &&
                <Zoom
                    in={formType === 'INVITE_GUEST'}
                    timeout={600}
                >
                    <FormControl
                        className={classNames(classes.width100)}
                        required
                        error={inviteAttempted && aliveType === AliveType.null}
                    >
                        <div className={classes.deceasedToggle}>
                            <GGroupButtons
                                buttons={[
                                    {
                                        label: AliveType.living,
                                        value: AliveType.living,
                                        className: (inviteAttempted
                                            && aliveType === AliveType.null)
                                            && classNames(classes.error)
                                    }, {
                                        label: AliveType.deceased,
                                        value: AliveType.deceased,
                                        className: (inviteAttempted
                                            && aliveType === AliveType.null)
                                            && classNames(classes.error)
                                    }
                                ]}
                                activeButton={aliveType}
                                onClick={(value) => handleDeceasedToggleOnClick(value, false)}
                            />
                            {inviteAttempted && aliveType === AliveType.null &&
                                <FormHelperText>Selection is required.</FormHelperText>}
                        </div>
                    </FormControl>
                </Zoom>}

            <div
                className={classNames(
                    classes.inner,
                    classes.formInner,
                    (relationToDeceased || isObituaryAddPerson) && classes.marginTop20,
                    (formType === 'INVITE_ADMIN' && (!relationToDeceased && !isObituaryAddPerson)) && classes.active
                )}
            >
                {fullName.length !== 0 && !isInvitationSuccess
                    ? renderNameInitialsAvatar()
                    : renderEmptyAvatar()}

                {(!relationToDeceased && !isObituaryAddPerson) && renderInvitationGroupButtons()}

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    className={classNames(
                        classes.formControlContainer,
                        classes.moveMeUp,
                        isDeceased && (!relationToDeceased && !isObituaryAddPerson) && classes.active
                    )}
                >
                    <Grid item className={classes.height0} >
                        <PersonAdd color="primary" />
                    </Grid>
                    <Grid item className={classNames(classes.textFeildWithIcon, classes.marginTop10)}>
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                            required
                            error={inviteAttempted && fullName.length === 0}
                        >
                            <TextField
                                required
                                error={inviteAttempted && fullName.length === 0}
                                label={getFullNameTextFieldLabel()}
                                id="fullName"
                                name="fullName"
                                value={fullName}
                                autoFocus={isFocused}
                                className={classes.width100}
                                InputLabelProps={{
                                    className: classes.nameLabel,
                                    classes: { focused: classes.nameLabelFocused }
                                }}
                                inputProps={{
                                    className: classes.paddingRight26
                                }}
                                onChange={(e) => {
                                    handleOnChangeName(e.target.value);
                                    setInvitationFormDirty(true);
                                }}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment: (
                                        isFHorGOMUserOnFH &&
                                        <InputAdornment position="end">
                                            <Tooltip title={'Click here to search your rolodex'}>
                                                <IconButton
                                                    color="primary"
                                                    className={classes.adronmentIconButton}
                                                    onClick={(e) => openRolodexSearchDialog()}
                                                    size="large">
                                                    <ContactsIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment> ||
                                        <></>
                                    )
                                }}
                            />
                            {inviteAttempted && fullName.length === 0 && <FormHelperText>
                                Name is required.</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                {(!relationToDeceased && !isObituaryAddPerson)
                    && <Zoom
                        in={showPhoneTextField}
                        timeout={600}
                    >
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="flex-start"
                            className={classes.formControlContainer}
                        >
                            <Grid item className={classes.height0}>
                                <SmartphoneIcon color="primary" />
                            </Grid>
                            <Grid item className={classNames(classes.textFeildWithIcon)}>
                                <Tooltip
                                    id="phone"
                                    title={'Only US mobile numbers are currently supported'}
                                    placement="top"
                                >
                                    <FormControl
                                        className={classNames(classes.width100, classes.textLeft)}
                                        error={inviteAttempted && !isPhoneValid}
                                    >
                                        {/* <img src={FLAG_USA_SRC} className={classes.flagIcon} /> */}
                                        <TextField
                                            label={`${fname && `${fname}'s ` || ''}Mobile Number` +
                                                `${dontSendInvite && ' (optional)' || ''}`}
                                            type="tel"
                                            required={!dontSendInvite}
                                            id="formatted-phone"
                                            name="phone"
                                            value={phone}
                                            error={inviteAttempted && !isPhoneValid}
                                            className={classes.width100}
                                            onChange={(e) => {
                                                handleOnChangePhone(e.target.value);
                                                setInvitationFormDirty(true);
                                            }}
                                            InputProps={{ inputComponent: PhoneNumberMask }}
                                            autoComplete="new-password"
                                            inputProps={{
                                                tabIndex: inviteMethod === InviteMethod.email ? -1 : undefined
                                            }}
                                        />
                                        {inviteAttempted && !isPhoneValid &&
                                            <FormHelperText>Mobile number is invalid.</FormHelperText>}
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Zoom>}

                <Zoom
                    timeout={600}
                    in={Boolean((relationToDeceased === DCEntityEnum.Informant && !isDeceased
                        || isObituaryAddPerson && aliveType === AliveType.living))}
                >
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.gmapSearch,
                            classes.marginAuto,
                            classes.marginTop30,
                            (relationToDeceased
                                ? relationToDeceased !== DCEntityEnum.Informant
                                : !isObituaryAddPerson
                            ) && classes.scaleMeOut,
                            (!relationToDeceased && !isObituaryAddPerson) && classes.displayNone
                        )}
                    >
                        <div className={classNames(classes.gmapSearchContainer, classes.active)}>
                            <GmapsSearch
                                controlClasses={classes.gmapSearch}
                                textLabel={`${firstName}'s Home Address`}
                                controlSuggestionClasses={classes.controlSuggestionClasses}
                                type={GmapsSearchType.longAddress}
                                value={homeAddress}
                                onSetPlace={(addr: GmapsSearchAddress) => {
                                    if (isLongAddress(addr)) {
                                        handleOnChangeHomeAddress(addr);
                                        setInvitationFormDirty(true);
                                    }
                                }}
                                error={inviteAttempted && !isAddressValid}
                                required={!addressOptional}
                                variant="outlined"
                                size="small"
                                zIndex={zIndex + 2}
                            />
                        </div>
                    </Grid>
                </Zoom>

                {(!relationToDeceased && !isObituaryAddPerson) &&
                    <Grid
                        container
                        alignItems="flex-end"
                        justifyContent="center"
                        className={classNames(
                            classes.formControlContainer,
                            classes.scaleMeOut,
                            inviteMethod === InviteMethod.email && classes.moveMeUpAndScaleMeOut,
                            showEMailTextField && (!relationToDeceased && !isObituaryAddPerson) && classes.active
                        )}
                    >
                        <Grid
                            item
                            className={classes.height0}
                        >
                            <AlternateEmailIcon color="primary" />
                        </Grid>
                        <Grid item className={classes.textFeildWithIcon}>
                            <FormControl
                                className={classNames(classes.width100, classes.textLeft)}
                                error={inviteAttempted && !isEmailValid}
                            >
                                <TextField
                                    required={!dontSendInvite}
                                    error={inviteAttempted && !isEmailValid}
                                    label={`${fname && `${fname}'s ` || ''} Email Address` +
                                        `${dontSendInvite && ' (optional)' || ''}`}
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    className={classes.width100}
                                    onChange={(e) => {
                                        handleOnChangeEmail(e.target.value);
                                        setInvitationFormDirty(true);
                                    }}
                                    autoComplete="new-password"
                                    inputProps={{
                                        tabIndex: inviteMethod === InviteMethod.phone ? -1 : undefined
                                    }}
                                />
                                {inviteAttempted && !isEmailValid &&
                                    <FormHelperText>Email Address is invalid.</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </div>
        </form >
    );
};

export default InvitationFormLeftSection;