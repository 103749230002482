import makeStyles from '@mui/styles/makeStyles';
import Transition, { TransitionProps } from 'react-transition-group/Transition';

const useStyles = makeStyles({
    root: {
        transform: 'translateY(100%)',
        transition: 'all 900ms cubic-bezier(0.4, 0, 0.2, 1)',
        width: '100%',
    }
}, { name: 'SelfLoginSectionAnimation' });

type Props = TransitionProps & {
    children: React.ReactNode;
    enteringTransform: string;
    exitingTransform: string;
};

const SelfLoginSectionAnimation = (props: Props) => {
    const { children, enteringTransform, exitingTransform, ...others } = props;

    const classes = useStyles();

    const defaultTransition = 'transform 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
        'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 300ms';

    const ZoomAnimationStyle = {
        transition: defaultTransition,
        transform: enteringTransform,
        opacity: 0
    };

    const TransitionStyles = {
        enter: {
            transform: enteringTransform,
            zIndex: 1,
            visibility: 'hidden'
        },
        entering: {
            transform: 'translateY(0%)',
            opacity: 1,
            visibility: 'visible'
        },
        entered: {
            transform: 'translateY(0%)',
            opacity: 1,
            zIndex: 1,
            visibility: 'visible'
        },
        exiting: {
            transition: defaultTransition,
            transform: exitingTransform,
            opacity: 0,
            visibility: 'visible'
        },
        exited: {
            zIndex: 0,
            visibility: 'hidden'
        }
    };

    return (
        <Transition
            {...others}
        >
            {(state) => (
                <div
                    className={classes.root}
                    style={{
                        ...ZoomAnimationStyle,
                        ...TransitionStyles[state],
                        position: 'absolute',
                        height: '100%',
                        overflowY: 'auto'
                    }}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default SelfLoginSectionAnimation;
