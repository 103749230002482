import {
    DeathCertificateConfigCreateRequest,
    DeathCertificateConfigFieldSaveRequest,
    DeathCertificateConfigFieldSaveResponse,
    DeathCertificateConfigFieldUX,
    DeathCertificateConfigRecord,
    DeathCertificateConfigSummary,
    DeathCertificateConfigUpdateRequest,
    DeathCertificateConfigUX,
    UserProfile,
} from '../shared/types';
import { deleteFromAPI, getFromAPI, patchAPI, postToAPI, putToAPI } from '.';
import { registerAppError } from './errors';
import { setSnackbarSuccess } from './AppSnackbar.action';
import { log } from '../logger';
import { AppDispatch } from '../store';

// ----> Create Death Certificate Config <----
export const CREATING_DEATH_CERTIFICATE_CONFIG = 'CREATING_DEATH_CERTIFICATE_CONFIG';

interface CreatingDeathCertificateConfig {
    type: typeof CREATING_DEATH_CERTIFICATE_CONFIG;
}

function creatingDeathCertificateConfig(): CreatingDeathCertificateConfig {
    return {
        type: CREATING_DEATH_CERTIFICATE_CONFIG,
    };
}

export const CREATED_DEATH_CERTIFICATE_CONFIG = 'CREATED_DEATH_CERTIFICATE_CONFIG';

interface CreatedDeathCertificateConfig {
    type: typeof CREATED_DEATH_CERTIFICATE_CONFIG;
    config: DeathCertificateConfigUX;
    isDefault: boolean;
}

function createdDeathCertificateConfig(
    config: DeathCertificateConfigUX,
    isDefault: boolean,
): CreatedDeathCertificateConfig {
    return {
        type: CREATED_DEATH_CERTIFICATE_CONFIG,
        config,
        isDefault,
    };
}

export const CREATING_DEATH_CERTIFICATE_CONFIG_FAILED = 'CREATING_DEATH_CERTIFICATE_CONFIG_FAILED';

interface CreatingDeathCertificateConfigFailed {
    type: typeof CREATING_DEATH_CERTIFICATE_CONFIG_FAILED;
}

function creatingDeathCertificateConfigFailed(): CreatingDeathCertificateConfigFailed {
    return {
        type: CREATING_DEATH_CERTIFICATE_CONFIG_FAILED,
    };
}

export function createDeathCertificateConfig(
    deathCertificateConfig: DeathCertificateConfigCreateRequest,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigUX | null> => {
        try {
            DeathCertificateConfigCreateRequest.fromRequest(deathCertificateConfig);
        } catch (ex) {
            log.warn('Failed to validate DeathCertificateConfigCreateRequest', { deathCertificateConfig, ex });
            return null;
        }

        dispatch(creatingDeathCertificateConfig());
        const route = `funeralhome/${funeralHomeId}/deathcertificateconfig`;
        const createdConfig = await postToAPI<DeathCertificateConfigUX>(route, { deathCertificateConfig }, dispatch);
        if (createdConfig) {
            dispatch(createdDeathCertificateConfig(createdConfig, deathCertificateConfig.make_default));
            return createdConfig;
        }
        dispatch(creatingDeathCertificateConfigFailed());
        dispatch(registerAppError('Unable to create new death certificate configuration.'));
        return null;
    };
}

// ----> Update Death Certificate Config <----
export const UPDATING_DEATH_CERTIFICATE_CONFIG = 'UPDATING_DEATH_CERTIFICATE_CONFIG';

interface UpdatingDeathCertificateConfig {
    type: typeof UPDATING_DEATH_CERTIFICATE_CONFIG;
    configId: number;
    changes: DeathCertificateConfigUpdateRequest;
    updaterId: number;
}

function updatingDeathCertificateConfig(
    configId: number,
    changes: DeathCertificateConfigUpdateRequest,
    updaterId: number,
): UpdatingDeathCertificateConfig {
    return {
        type: UPDATING_DEATH_CERTIFICATE_CONFIG,
        configId,
        changes,
        updaterId,
    };
}

export const UPDATED_DEATH_CERTIFICATE_CONFIG = 'UPDATED_DEATH_CERTIFICATE_CONFIG';

interface UpdatedDeathCertificateConfig {
    type: typeof UPDATED_DEATH_CERTIFICATE_CONFIG;
    updatedConfig: DeathCertificateConfigUX;
}

function updatedDeathCertificateConfig(updatedConfig: DeathCertificateConfigUX): UpdatedDeathCertificateConfig {
    return {
        type: UPDATED_DEATH_CERTIFICATE_CONFIG,
        updatedConfig,
    };
}

export const UPDATING_DEATH_CERTIFICATE_CONFIG_FAILED = 'UPDATING_DEATH_CERTIFICATE_CONFIG_FAILED';

interface UpdatingDeathCertificateConfigFailed {
    type: typeof UPDATING_DEATH_CERTIFICATE_CONFIG_FAILED;
    configId: number;
    changesToRollback: Partial<DeathCertificateConfigUX>;
}

function updatingDeathCertificateConfigFailed(
    configId: number,
    changesToRollback: Partial<DeathCertificateConfigUX>,
): UpdatingDeathCertificateConfigFailed {
    return {
        type: UPDATING_DEATH_CERTIFICATE_CONFIG_FAILED,
        configId,
        changesToRollback,
    };
}

export function updateDeathCertificateConfig(
    existing: DeathCertificateConfigUX,
    changes: DeathCertificateConfigUpdateRequest,
    user: UserProfile,
) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigUX | null> => {
        try {
            DeathCertificateConfigUpdateRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate DeathCertificateConfigUpdateRequest', { changes, ex });
            return null;
        }

        dispatch(updatingDeathCertificateConfig(existing.id, changes, user.user_id));
        const route = `funeralhome/${existing.funeral_home_id}/deathcertificateconfig/${existing.id}`;
        const updatedConfig = await patchAPI<DeathCertificateConfigUX>(
            route,
            { deathCertificateConfig: changes },
            dispatch,
        );
        if (updatedConfig) {
            dispatch(updatedDeathCertificateConfig(updatedConfig));
            return updatedConfig;
        }

        dispatch(updatingDeathCertificateConfigFailed(existing.id, existing));
        dispatch(registerAppError('Unable to update configuration.'));
        return null;
    };
}

// ----> Save Death Certificate Config Field <----
export const SAVING_DEATH_CERTIFICATE_CONFIG_FIELD = 'SAVING_DEATH_CERTIFICATE_CONFIG_FIELD';

interface SavingDeathCertificateConfigField {
    type: typeof SAVING_DEATH_CERTIFICATE_CONFIG_FIELD;
    fieldKey: string;
    changes: DeathCertificateConfigFieldSaveRequest;
}

function savingDeathCertificateConfigField(
    fieldKey: string,
    changes: DeathCertificateConfigFieldSaveRequest,
): SavingDeathCertificateConfigField {
    return {
        type: SAVING_DEATH_CERTIFICATE_CONFIG_FIELD,
        fieldKey,
        changes,
    };
}

export const SAVED_DEATH_CERTIFICATE_CONFIG_FIELD = 'SAVED_DEATH_CERTIFICATE_CONFIG_FIELD';

interface SavedDeathCertificateConfigField {
    type: typeof SAVED_DEATH_CERTIFICATE_CONFIG_FIELD;
    updatedConfig: DeathCertificateConfigUX;
}

function savedDeathCertificateConfigField(updatedConfig: DeathCertificateConfigUX): SavedDeathCertificateConfigField {
    return {
        type: SAVED_DEATH_CERTIFICATE_CONFIG_FIELD,
        updatedConfig,
    };
}

export const SAVING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED = 'SAVING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED';

interface SavingDeathCertificateConfigFieldFailed {
    type: typeof SAVING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED;
}

function savingDeathCertificateConfigFieldFailed(): SavingDeathCertificateConfigFieldFailed {
    return {
        type: SAVING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED,
    };
}

export function saveDeathCertificateConfigField(
    config: DeathCertificateConfigUX,
    uiFieldKey: string,
    saveRequest: DeathCertificateConfigFieldSaveRequest,
) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigFieldUX | null> => {
        try {
            DeathCertificateConfigFieldSaveRequest.fromRequest(saveRequest);
        } catch (ex) {
            log.warn('Failed to validate DeathCertificateConfigFieldSaveRequest', { saveRequest, ex });
            return null;
        }

        dispatch(savingDeathCertificateConfigField(uiFieldKey, saveRequest));
        const route = `funeralhome/${config.funeral_home_id}/deathcertificateconfig/${config.id}/field/${uiFieldKey}`;
        const response = await putToAPI<DeathCertificateConfigFieldSaveResponse>(
            route,
            { configField: saveRequest },
            dispatch,
        );
        if (response) {
            const { updatedConfig, savedFieldKey } = response;
            dispatch(savedDeathCertificateConfigField(updatedConfig));
            const updatedField = updatedConfig.revision.fields.find((f) => f.ui_field_key === savedFieldKey) || null;
            return updatedField;
        }

        dispatch(savingDeathCertificateConfigFieldFailed());
        dispatch(registerAppError('Unable to update field.'));
        return null;
    };
}

// ----> Delete Death Certificate Config Field <----
export const DELETING_DEATH_CERTIFICATE_CONFIG_FIELD = 'DELETING_DEATH_CERTIFICATE_CONFIG_FIELD';

interface DeletingDeathCertificateConfigField {
    type: typeof DELETING_DEATH_CERTIFICATE_CONFIG_FIELD;
    fieldToDelete: DeathCertificateConfigFieldUX;
}

function deletingDeathCertificateConfigField(
    fieldToDelete: DeathCertificateConfigFieldUX,
): DeletingDeathCertificateConfigField {
    return {
        type: DELETING_DEATH_CERTIFICATE_CONFIG_FIELD,
        fieldToDelete,
    };
}

export const DELETED_DEATH_CERTIFICATE_CONFIG_FIELD = 'DELETED_DEATH_CERTIFICATE_CONFIG_FIELD';

interface DeletedDeathCertificateConfigField {
    type: typeof DELETED_DEATH_CERTIFICATE_CONFIG_FIELD;
    updatedConfig: DeathCertificateConfigUX;
}

function deletedDeathCertificateConfigField(
    updatedConfig: DeathCertificateConfigUX,
): DeletedDeathCertificateConfigField {
    return {
        type: DELETED_DEATH_CERTIFICATE_CONFIG_FIELD,
        updatedConfig,
    };
}

export const DELETING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED = 'DELETING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED';

interface DeletingDeathCertificateConfigFieldFailed {
    type: typeof DELETING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED;
    oldField: DeathCertificateConfigFieldUX;
}

function deletingDeathCertificateConfigFieldFailed(
    oldField: DeathCertificateConfigFieldUX,
): DeletingDeathCertificateConfigFieldFailed {
    return {
        type: DELETING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED,
        oldField,
    };
}

export function deleteDeathCertificateConfigField(
    config: DeathCertificateConfigUX,
    configField: DeathCertificateConfigFieldUX,
) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigUX | null> => {
        dispatch(deletingDeathCertificateConfigField(configField));
        const route = `funeralhome/${config.funeral_home_id}/deathcertificateconfig/${config.id}`
            + `/field/${configField.ui_field_key}`;
        const updatedConfig = await deleteFromAPI<DeathCertificateConfigUX>(
            route,
            dispatch,
        );
        if (updatedConfig) {
            dispatch(deletedDeathCertificateConfigField(updatedConfig));
            return updatedConfig;
        }

        dispatch(deletingDeathCertificateConfigFieldFailed(configField));
        dispatch(registerAppError('Unable to remove field.'));
        return null;
    };
}

// ----> Delete Death Certificate Config <----
export const DELETING_DEATH_CERTIFICATE_CONFIG = 'DELETING_DEATH_CERTIFICATE_CONFIG';

interface DeletingDeathCertificateConfig {
    type: typeof DELETING_DEATH_CERTIFICATE_CONFIG;
    idToRemove: number;
}

function deletingDeathCertificateConfig(idToRemove: number): DeletingDeathCertificateConfig {
    return {
        type: DELETING_DEATH_CERTIFICATE_CONFIG,
        idToRemove,
    };
}

export const DELETED_DEATH_CERTIFICATE_CONFIG = 'DELETED_DEATH_CERTIFICATE_CONFIG';

interface DeletedDeathCertificateConfig {
    type: typeof DELETED_DEATH_CERTIFICATE_CONFIG;
}

function deletedDeathCertificateConfig(): DeletedDeathCertificateConfig {
    return {
        type: DELETED_DEATH_CERTIFICATE_CONFIG,
    };
}

export const DELETING_DEATH_CERTIFICATE_CONFIG_FAILED = 'DELETING_DEATH_CERTIFICATE_CONFIG_FAILED';

interface DeletingDeathCertificateConfigFailed {
    type: typeof DELETING_DEATH_CERTIFICATE_CONFIG_FAILED;
    configToRestore: DeathCertificateConfigUX;
}

function deletingDeathCertificateConfigFailed(
    configToRestore: DeathCertificateConfigUX,
): DeletingDeathCertificateConfigFailed {
    return {
        type: DELETING_DEATH_CERTIFICATE_CONFIG_FAILED,
        configToRestore,
    };
}

export function deleteDeathCertificateConfig(config: DeathCertificateConfigUX) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigRecord | null> => {
        const { funeral_home_id } = config;

        dispatch(deletingDeathCertificateConfig(config.id));
        const route = `funeralhome/${funeral_home_id}/deathcertificateconfig/${config.id}`;
        const deletedConfig = await deleteFromAPI<DeathCertificateConfigRecord>(route, dispatch);
        if (deletedConfig) {
            dispatch(deletedDeathCertificateConfig());
            dispatch(setSnackbarSuccess('Successfully deleted configuration'));
            return deletedConfig;
        }
        dispatch(deletingDeathCertificateConfigFailed(config));
        dispatch(registerAppError('Unable to delete configuration.'));
        return null;
    };
}

// ----> Load Death Certificate Configs <----
export const LOADING_DEATH_CERTIFICATE_CONFIGS = 'LOADING_DEATH_CERTIFICATE_CONFIGS';

interface LoadingDeathCertificateConfigs {
    type: typeof LOADING_DEATH_CERTIFICATE_CONFIGS;
}

function loadingDeathCertificateConfigs(): LoadingDeathCertificateConfigs {
    return {
        type: LOADING_DEATH_CERTIFICATE_CONFIGS,
    };
}

export const LOADED_DEATH_CERTIFICATE_CONFIGS = 'LOADED_DEATH_CERTIFICATE_CONFIGS';

export interface LoadedDeathCertificateConfigs {
    type: typeof LOADED_DEATH_CERTIFICATE_CONFIGS;
    configs: DeathCertificateConfigSummary[];
}

function loadedDeathCertificateConfigs(
    configs: DeathCertificateConfigSummary[],
): LoadedDeathCertificateConfigs {
    return {
        type: LOADED_DEATH_CERTIFICATE_CONFIGS,
        configs,
    };
}

export const LOADING_DEATH_CERTIFICATE_CONFIGS_FAILED = 'LOADING_DEATH_CERTIFICATE_CONFIGS_FAILED';

interface LoadingDeathCertificateConfigsFailed {
    type: typeof LOADING_DEATH_CERTIFICATE_CONFIGS_FAILED;
}

function loadingDeathCertificateConfigsFailed(): LoadingDeathCertificateConfigsFailed {
    return {
        type: LOADING_DEATH_CERTIFICATE_CONFIGS_FAILED,
    };
}

// Load the dc configs for the funeral home ID provided.
// This will not store the resulting dc configs in the Redux store.
// It is used when we want to load dc configs but use local state rather than Redux for storing them
export function loadDCConfigsApi(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigSummary[] | null> => {
        const route = `funeralhome/${funeralHomeId}/deathcertificateconfig/`;
        return getFromAPI<DeathCertificateConfigSummary[]>(route, dispatch);
    };
}

// Load the dc configs for the funeral home ID provided and store them in Redux
// This differs from loadDCConfigsApi in that it will store the dc configs in the Redux store
// It should only be used when the active Funeral Home in Redux is the same as the funeralHomeId provided
export function loadDeathCertificateConfigs(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigSummary[] | null> => {
        dispatch(loadingDeathCertificateConfigs());
        const configs = await dispatch(loadDCConfigsApi(funeralHomeId));
        if (configs) {
            dispatch(loadedDeathCertificateConfigs(configs));
            return configs;
        } else {
            dispatch(loadingDeathCertificateConfigsFailed());
            dispatch(registerAppError('Unable to load death certificate configurations.'));
            return null;
        }
    };
}

export function loadAllDeathCertificateConfigs(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigSummary[] | null> => {
        const route = `funeralhome/${funeralHomeId}/deathcertificateconfig/all/`;
        const configs = await getFromAPI<DeathCertificateConfigSummary[]>(route, dispatch);
        if (configs) {
            return configs;
        } else {
            dispatch(registerAppError('Unable to load all death certificate configurations.'));
            return null;
        }
    };
}

// ----> Load Death Certificate Config <----
export const LOADING_DEATH_CERTIFICATE_CONFIG = 'LOADING_DEATH_CERTIFICATE_CONFIG';

interface LoadingDeathCertificateConfig {
    type: typeof LOADING_DEATH_CERTIFICATE_CONFIG;
}

function loadingDeathCertificateConfig(): LoadingDeathCertificateConfig {
    return {
        type: LOADING_DEATH_CERTIFICATE_CONFIG,
    };
}

export const LOADED_DEATH_CERTIFICATE_CONFIG = 'LOADED_DEATH_CERTIFICATE_CONFIG';

export interface LoadedDeathCertificateConfig {
    type: typeof LOADED_DEATH_CERTIFICATE_CONFIG;
    config: DeathCertificateConfigUX;
}

function loadedDeathCertificateConfig(
    config: DeathCertificateConfigUX,
): LoadedDeathCertificateConfig {
    return {
        type: LOADED_DEATH_CERTIFICATE_CONFIG,
        config,
    };
}

export const LOADING_DEATH_CERTIFICATE_CONFIG_FAILED = 'LOADING_DEATH_CERTIFICATE_CONFIG_FAILED';

interface LoadingDeathCertificateConfigFailed {
    type: typeof LOADING_DEATH_CERTIFICATE_CONFIG_FAILED;
}

function loadingDeathCertificateConfigFailed(): LoadingDeathCertificateConfigFailed {
    return {
        type: LOADING_DEATH_CERTIFICATE_CONFIG_FAILED,
    };
}

export function loadDeathCertificateConfig(funeralHomeId: number, configId: number) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigUX | null> => {
        dispatch(loadingDeathCertificateConfig());
        const route = `funeralhome/${funeralHomeId}/deathcertificateconfig/${configId}`;
        const config = await getFromAPI<DeathCertificateConfigUX>(route, dispatch);
        if (config) {
            dispatch(loadedDeathCertificateConfig(config));
            return config;
        } else {
            dispatch(loadingDeathCertificateConfigFailed());
            dispatch(registerAppError('Unable to load death certificate configuration.'));
            return null;
        }
    };
}

export function loadDeathCertificateConfigForCase(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigUX | null> => {
        dispatch(loadingDeathCertificateConfig());
        const route = `api/case/${caseUuid}/deathcertificateconfig`;
        const config = await getFromAPI<DeathCertificateConfigUX>(route, dispatch);
        if (config) {
            dispatch(loadedDeathCertificateConfig(config));
            return config;
        } else {
            dispatch(loadingDeathCertificateConfigFailed());
            dispatch(registerAppError('Unable to load death certificate configuration.'));
            return null;
        }
    };
}

export const UPDATING_DEATH_CERTIFICATE_CONFIG_FOR_CASE = 'UPDATING_DEATH_CERTIFICATE_CONFIG_FOR_CASE';

export interface UpdatingDeathCertificateConfigForCase {
    type: typeof UPDATING_DEATH_CERTIFICATE_CONFIG_FOR_CASE;
    caseUuid: string;
}

function updatingConfigForCase(
    caseUuid: string,
): UpdatingDeathCertificateConfigForCase {
    return {
        type: UPDATING_DEATH_CERTIFICATE_CONFIG_FOR_CASE,
        caseUuid,
    };
}

export const UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE = 'SET_DEATH_CERTIFICATE_CONFIG_FOR_CASE';

export interface UpdatedDeathCertificateConfigForCase {
    type: typeof UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE;
    config: DeathCertificateConfigUX;
    caseUuid: string;
}

function updateConfigForCase(
    config: DeathCertificateConfigUX,
    caseUuid: string,
): UpdatedDeathCertificateConfigForCase {
    return {
        type: UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE,
        config,
        caseUuid,
    };
}

export function setDeathCertificateConfigForCase(caseUuid: string, configId: number) {
    return async (dispatch: AppDispatch): Promise<DeathCertificateConfigUX | null> => {
        dispatch(updatingConfigForCase(caseUuid));
        const route = `api/case/${caseUuid}/deathcertificateconfig/${configId}`;
        const config = await postToAPI<DeathCertificateConfigUX>(route, {}, dispatch);
        if (config) {
            dispatch(updateConfigForCase(config, caseUuid));
            return config;
        } else {
            dispatch(registerAppError('Unable to change death certificate configuration.'));
            return null;
        }
    };
}

export type DeathCertificateConfigAction =
    | CreatingDeathCertificateConfig
    | CreatingDeathCertificateConfigFailed
    | CreatedDeathCertificateConfig
    | UpdatingDeathCertificateConfig
    | UpdatingDeathCertificateConfigFailed
    | UpdatedDeathCertificateConfig
    | SavingDeathCertificateConfigField
    | SavingDeathCertificateConfigFieldFailed
    | SavedDeathCertificateConfigField
    | DeletingDeathCertificateConfigField
    | DeletingDeathCertificateConfigFieldFailed
    | DeletedDeathCertificateConfigField
    | DeletingDeathCertificateConfig
    | DeletingDeathCertificateConfigFailed
    | DeletedDeathCertificateConfig
    | LoadingDeathCertificateConfig
    | LoadingDeathCertificateConfigFailed
    | LoadedDeathCertificateConfig
    | LoadingDeathCertificateConfigs
    | LoadingDeathCertificateConfigsFailed
    | LoadedDeathCertificateConfigs
    | UpdatedDeathCertificateConfigForCase
    | UpdatingDeathCertificateConfigForCase
    ;
