import * as React from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { GatherCaseUX, ContractDisclaimerUX } from '../../../../shared/types';
import { getFullNameFromCase } from '../../../../services';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            width: 300,
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            '@media (min-width: 400px)': {
                width: 360
            },
        },
    },
    clearIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        background: theme.palette.primary.main,
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '24px !important',
        margin: ' auto',
        textAlign: 'center',
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        color: theme.palette.common.white,
    },
    textField: {
        margin: '24px 0'
    },
    textAreaMinHeight: {
        minHeight: 80
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            margin: '8px 0'
        }
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    dialogPaper: {},
});

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    activeCase: GatherCaseUX;
    isDialogOpen: boolean;
    disclaimer: ContractDisclaimerUX | null;
    closeDialog: () => void;
    onSaveDisclaimerReason: (id: number, disclaimer: string | null) => void;
    onDeleteDisclaimerReason: (id: number) => void;
    zIndex: number;
}

interface State {
    reason: string | null;
    saveAttempted: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent' | 'textField'
    | 'clearIcon' | 'headerText' | 'textAreaMinHeight' | 'footer' | 'spaceBetween'>;

class DisclaimerDialog extends React.Component<StyledProps & DialogProps, State> {

    constructor(props: StyledProps & DialogProps) {
        super(props);
        this.state = {
            reason: this.props.disclaimer && this.props.disclaimer.reason || null,
            saveAttempted: false,
        };
    }

    render() {
        const {
            classes,
            isDialogOpen,
            activeCase,
            disclaimer,
            zIndex
        } = this.props;

        const { reason, saveAttempted } = this.state;

        if (!disclaimer) {
            return null;
        }

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.root,
                }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.header}
                >
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={() => this.closeDialog()}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classes.headerText}
                    >
                        Statement Disclaimer Reason
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize18}
                            >
                                Enter disclaimer reason for&nbsp;
                                {getFullNameFromCase(activeCase)}'s Statement
                            </Typography>
                            <TextField
                                name="reason"
                                className={classes.textField}
                                fullWidth
                                value={reason || ''}
                                multiline
                                label="Type your disclaimer reason here..."
                                InputProps={{
                                    classes: {
                                        root: classes.textAreaMinHeight,
                                        inputMultiline: classes.textAreaMinHeight,
                                    }
                                }}
                                required
                                error={saveAttempted && !reason}
                                onChange={this.handleReasonChange}
                            />

                            <div
                                className={classNames(
                                    classes.footer,
                                    disclaimer.reason && classes.spaceBetween
                                )}
                            >
                                {disclaimer.reason &&
                                    <Button
                                        color="primary"
                                        className={classes.margin_8}
                                        onClick={this.handleDelete}
                                    >
                                        delete reason
                                    </Button>
                                }

                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.margin_8}
                                    onClick={this.handleSave}
                                >
                                    Save reason
                                </Button>
                            </div>

                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize12}
                            >
                                This disclaimer reason WILL BE visible on {activeCase.fname}'s Statement
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    handleSave = async () => {
        const { disclaimer, onSaveDisclaimerReason } = this.props;
        const { reason } = this.state;

        if (!disclaimer) {
            return;
        }

        if (!reason) {
            this.setState({
                saveAttempted: true,
            });
            return;
        }
        onSaveDisclaimerReason(disclaimer.id, reason);
        this.closeDialog();
    };

    handleDelete = async () => {
        const { disclaimer, onDeleteDisclaimerReason } = this.props;

        if (!disclaimer) {
            return;
        }

        onDeleteDisclaimerReason(disclaimer.id);
        this.closeDialog();
    };

    handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        this.setState({ reason: target.value });
    };

    closeDialog = () => {
        const { closeDialog, disclaimer } = this.props;

        closeDialog();
        this.setState({
            reason: disclaimer && disclaimer.reason || null,
            saveAttempted: false,
        });
    };
}

export default withGStyles(styles)(withFullScreen('xs')(DisclaimerDialog));
