import { ComponentType } from "react";
import { log } from "../logger";

// got this idea from this article: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
export const retryLazyLoad = <T = unknown>(
    loadComponent: () => Promise<{ default: ComponentType<T> }>,
    attemptsLeft: number = 3,
) => {
    return new Promise<{ default: ComponentType<T> }>(async (resolve, reject) => {
        let loadedComponent: { default: ComponentType<T> };
        try {
            loadedComponent = await loadComponent();
            resolve(loadedComponent);
        } catch (error: unknown) {
            if (attemptsLeft <= 0) {
                reject(error);
                return;
            }
            // let us retry after 1500 ms
            setTimeout(async () => {
                try {
                    loadedComponent = await retryLazyLoad(loadComponent, attemptsLeft - 1);
                    // Informational: Can remove this once we see that it is working
                    log.warn(`Awesome! Chunk loaded w/ ${attemptsLeft} attempts left`, {
                        loadedComponent: loadedComponent.default.displayName,
                    });
                    resolve(loadedComponent);
                } catch (e: unknown) {
                    reject(e);
                }
            }, 1500);
        }
    });
};
