export const isBlacklisted = (error: Error | string, blackList: string[]): boolean => {
    return blackList.some((blackListedError) => {

        if (typeof error === 'string') {
            return error.includes(blackListedError);
        } else {
            return error.name.includes(blackListedError) || error.message.includes(blackListedError);
        }
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sentryReduceConsoleArgs(args: any[], blackList: string[], isWarning: boolean = false): Error | null {
    let error: Error | string = args[0];
    if (!(error instanceof Error)) {
        // wrap string errors in an Error object to create a stack trace
        // stringify all args as a new Error (which creates a stack trace)
        const errorStr = args.reduce<string>(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (accumulator: string, currentValue: any) =>
                `${accumulator} ${currentValue ? JSON.stringify(currentValue) : 'Unknown'}`,
            '',
        );
        error = new Error(`${isWarning ? 'WARNING:' : ''}${errorStr}`);

        // ignore errors generated by lambda-log (from API) because those are already captured by Sentry 
        if (errorStr.includes('_logLevel')) {
            return null;
        }
    }
    if (isBlacklisted(error, blackList)) {
        return null;
    }
    return error;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ConsoleFunction = (message?: any, ...optionalParams: any[]) => void;

interface OriginalConsoleFunctions {
    // originalException: ConsoleFunction;
    originalError: ConsoleFunction;
    originalWarn: ConsoleFunction;
}

export function monkeyPatchConsole(
    errorHandler: (error: Error) => void,
    blackList: string[],
): OriginalConsoleFunctions {
    // "monkey patching" console.exception, console.error, console.warn to send console errors/warnings to Sentry
    // eslint-disable-next-line no-console
    const originalConsoleError = console.error;
    // eslint-disable-next-line no-console
    console.error = function sentryReportConsoleError() {
        const args = Array.prototype.slice.call(arguments);
        const error = sentryReduceConsoleArgs(args, blackList);
        if (error) {
            errorHandler(error);
        }
        return originalConsoleError.apply(console, args);
    };

    const originalConsoleWarn = console.warn;
    console.warn = function sentryReportConsoleWarn() {
        var args = Array.prototype.slice.call(arguments);
        const error = sentryReduceConsoleArgs(args, blackList, true);
        if (error) {
            errorHandler(error);
        }
        return originalConsoleWarn.apply(console, args);
    };

    // eslint-disable-next-line no-console
    // const originalConsoleException = console.exception;
    // eslint-disable-next-line no-console
    // console.exception = function sentryReportConsoleException() {
    //     const args = Array.prototype.slice.call(arguments);
    //     const error = sentryReduceConsoleArgs(args, blackList);
    //     if (error) {
    //         errorHandler(error);
    //     }
    //     return originalConsoleException.apply(console, args);
    // };

    return {
        // originalException: originalConsoleException,
        originalError: originalConsoleError,
        originalWarn: originalConsoleWarn,
    };
}

let onlyOnceBlacklist: string[] = [];

export type SentryTags = Record<string, number | string | boolean | bigint | symbol | null | undefined>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SentryMetaData = Record<string, any>;

export function sendToSentry(params: {
    sentryCapture: (error: Error, metaData?: SentryMetaData, tags?: SentryTags) => void;
    msg: string | Error;
    meta?: SentryMetaData;
    tags?: SentryTags;
    prefix?: string;
    onlyOnce?: boolean;
    errorSuffix?: string;
}) {
    const { sentryCapture, msg, meta, tags, prefix, onlyOnce, errorSuffix } = params;
    let error: Error;
    if (msg instanceof Error) {
        error = msg;
    } else if (meta instanceof Error) {
        error = meta;
    } else {
        error = new Error(prefix ? `${prefix}: ${msg}` : msg);
    }
    // don't include meta as metaData if it is an Error
    const metaData = meta instanceof Error ? { msg } : meta;

    if (onlyOnce) {
        const onlyOnceKey = msg instanceof Error ? msg.message : msg;
        if (onlyOnceBlacklist.some((blacklisted) => blacklisted === onlyOnceKey)) {
            return;
        } else {
            onlyOnceBlacklist.push(onlyOnceKey);
        }
    }

    if (errorSuffix) {
        error.name += `: ${errorSuffix}`;
    }

    sentryCapture(error, metaData, tags);
}

export const genericizeUrl = (url: string) => {
    return url.split('/').map((urlPart) => /\d|-/.test(urlPart) ? 'x' : urlPart).join('/');
};
