import { WebsiteState } from '../types';
import { GatherAction } from '../actions';
import {
    WEBSITES_LOADED,
    WEBSITES_LOADING,
    WEBSITE_CREATED,
    WEBSITE_UPDATED,
    WEBSITE_DELETED, WEBSITE_FUNERAL_HOMES_LOADED,
} from '../actions/Website.action';


export const initWebsiteState = {
    isLoading: false,
    websites: [],
    funeralHomes: [],
};

export const websiteState = (
    state: WebsiteState = initWebsiteState,
    action: GatherAction
): WebsiteState => {
    switch (action.type) {
        case WEBSITES_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case WEBSITES_LOADED: {
            return {
                ...state,
                isLoading: false,
                websites: action.websites,
            };
        }
        case WEBSITE_FUNERAL_HOMES_LOADED: {
            return {
                ...state,
                funeralHomes: action.websiteFH,
            };
        }
        case WEBSITE_CREATED: {
            return {
                ...state,
                websites: [...state.websites, action.website]
            };
        }
        case WEBSITE_UPDATED: {
            const updated = state.websites.map(website => (
                (website.id === action.websiteId)
                    ? { ...website, ...action.website, id: action.websiteId }
                    : website
                )
            );
            return {
                ...state,
                websites: updated
            };
        }
        case WEBSITE_DELETED: {
            return {
                ...state,
                websites: state.websites.filter(({ id }) => action.websiteId !== id)
            };
        }
        default:
            return state;
    }
};