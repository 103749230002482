import {
    DeathCertificateMarriageType,
    EntitySummary,
    getCaseEntityById,
    GatherCaseUX,
    DeathCertificateConfigUX,
    UserProfile,
    MaritalStatusEnum,
} from '../../../shared/types';
import { isFieldOptional, ConfigurableMarriageFieldKey } from './config';

export const marriageValidators = {
    spouse: function (
        activeCase: GatherCaseUX,
        helpers: EntitySummary[],
    ): boolean {
        const { dc_spouse } = activeCase;
        const spouse = dc_spouse &&
            helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_spouse)));
        return Boolean(spouse && spouse.fname && spouse.lname);
    },
    spouseMaidenName: function (
        marriageForm: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const spouseMaidenName = marriageForm.spouseMaidenName;

        const isValid = Boolean(spouseMaidenName);
        if (isValid || marriageForm.maritalStatus === 'Unknown' || marriageForm.maritalStatus === 'Never Married') {
            return true;
        }

        if (isFieldOptional(ConfigurableMarriageFieldKey.spouseMaidenName, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    maritalStatus: function (
        { maritalStatus }: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = maritalStatus && maritalStatus.length > 0 && MaritalStatusEnum[maritalStatus];
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMarriageFieldKey.maritalStatus, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    marriagePlace: function (
        marriageForm: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const marriagePlace = marriageForm.marriagePlace;
        const isValid = Boolean(marriagePlace.description) || marriagePlace.unknown === true;
        if (isValid || marriageForm.maritalStatus === 'Unknown' || marriageForm.maritalStatus === 'Never Married') {
            return true;
        }

        if (isFieldOptional(ConfigurableMarriageFieldKey.marriagePlace, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    marriageDate: function (
        { marriageDate, marriageDate_unknown: unknown, maritalStatus }: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        if (maritalStatus === MaritalStatusEnum.Unknown || maritalStatus === MaritalStatusEnum['Never Married']) {
            return true;
        }

        const isValid = (marriageDate && marriageDate.length > 0) || unknown;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMarriageFieldKey.dateOfMarriage, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    spousesDateOfDeath: function (
        { spousesDateOfPassing }: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = spousesDateOfPassing && spousesDateOfPassing.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMarriageFieldKey.spousesDateOfDeath, config, user)) {
            return !touchCheck;
        }

        return false;
    }
};

export const marriageMasterValidators = {
    valid: function (
        marriageForm: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        activeCase: GatherCaseUX,
        user: UserProfile,
        helpers: EntitySummary[],
    ) {
        if (marriageValidators.maritalStatus(marriageForm, config, user) === false) {
            return false;
        }

        switch (marriageForm.maritalStatus) {
            case 'Never Married':
            case 'Divorced':
            case 'Unknown':
            case 'Not Obtainable':
                return true;
            default:
                return (
                    marriageValidators.spouse(activeCase, helpers) &&
                    marriageValidators.spouseMaidenName(marriageForm, config, user) &&
                    marriageValidators.marriagePlace(marriageForm, config, user) &&
                    marriageValidators.marriageDate(marriageForm, config, user) &&
                    marriageValidators.spousesDateOfDeath(marriageForm, config, user)
                );
        }
    },
    touched: function (
        marriageForm: DeathCertificateMarriageType,
        config: DeathCertificateConfigUX | null,
        activeCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return (
            activeCase.dc_spouse !== null ||
            marriageValidators.spouseMaidenName(marriageForm, config, user, true) ||
            marriageValidators.maritalStatus(marriageForm, config, user, true) ||
            marriageValidators.marriagePlace(marriageForm, config, user, true) ||
            marriageValidators.marriageDate(marriageForm, config, user, true) ||
            marriageValidators.spousesDateOfDeath(marriageForm, config, user, true)
        );
    },
};