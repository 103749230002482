import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import { PaymentRequest } from '../../../../../shared/types';
import { Payer } from '..';
import { STRIPE_CHARGE_STATE, STRIPE_CHARGE_STATE_TYPE } from '../../../../../actions/Finance.action';
import { getFormattedPhoneNumber } from '../../../../../services';
import { joinNameParts } from '../../../../../shared/utils';
import { SlideTransition } from '../../../../common/Transitions';
import makeGStyles from '../../../../../styles/makeGStyles';
import OnlinePaymentSuccessScreen from './OnlinePaymentSuccessScreen';
import OnlinePaymentScreen from './OnlinePaymentScreen';

const useStyles = makeGStyles((theme) => ({
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    onlineDialog: {},
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 8px',
        margin: ' auto',
        textAlign: 'center',
        overflowX: 'hidden',
        position: 'relative',
        marginTop: 20,
    },
    dialogPaper: {
        margin: 'auto',
        width: '100%',
        maxWidth: 'unset',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        '@media (min-width: 600px)': {
            borderRadius: 4,
            maxWidth: 360,
            width: 360,
            height: 'auto',
            maxHeight: 'calc(100% - 96px)',
            display: 'flex',
        },
    },
    moneyIcon: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    headerContent: {
        textAlign: 'center',
        paddingBottom: 8,
        '& p': {
            color: theme.palette.common.white,
            '& span': {
                '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            }
        },
        '& $moneyIcon': {
            fontSize: 80,
        }
    },
    headerTitle: {
        fontSize: 24,
        '&:hover': {
            cursor: 'default !important',
            textDecoration: 'none !important',
        }
    },
    payerName: {
        fontSize: 16,
        lineHeight: '18px',
    },
}), { name: 'BaseOnlinePaymentDialog' });

interface Props {
    isDialogOpen: boolean;
    paymentInfo: PaymentRequest;
    isRestricted: boolean;
    payer: Payer;
    caseUuid: string | null;
    updatePayment?: (paymentInfo: PaymentRequest) => void;
    closeDialog: (activeReceipt: number | null) => void;
    openMySettingsDialog?: () => void;
    updateSentNotification?: (sentNotification: boolean) => void;
    zIndex: number;
    dialogFooter?: JSX.Element;
    sendNotification: boolean;
    onlineChargeState: STRIPE_CHARGE_STATE_TYPE;
}

const BaseOnlinePaymentDialog = (props: Props) => {
    const {
        isDialogOpen,
        paymentInfo,
        isRestricted,
        payer,
        caseUuid,
        updatePayment,
        closeDialog,
        openMySettingsDialog,
        updateSentNotification,
        zIndex,
        dialogFooter,
        sendNotification,
        onlineChargeState,
    } = props;

    const classes = useStyles();

    const payerName = joinNameParts(payer.payerEntity);
    const payerEmail = payer.payerEntity.email;
    const payerPhone = payer.payerEntity.phone;

    const screen = onlineChargeState === STRIPE_CHARGE_STATE.CHARGED
        ? <OnlinePaymentSuccessScreen
            closeDialog={closeDialog}
            paymentId={paymentInfo.payment_id}
        />
        : <OnlinePaymentScreen
            paymentInfo={paymentInfo}
            isRestricted={isRestricted}
            payer={payer}
            caseUuid={caseUuid}
            updatePayment={updatePayment}
            closeDialog={closeDialog}
            updateSentNotification={updateSentNotification}
            dialogFooter={dialogFooter}
            sendNotification={sendNotification}
            onlineChargeState={onlineChargeState}
        />;

    return (
        <Dialog
            open={isDialogOpen}
            onClose={() => closeDialog(null)}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.onlineDialog}
            classes={{
                paper: classes.dialogPaper,
                root: classes.dRoot,
            }}
            style={{ zIndex }}
            maxWidth="xs"
        >
            <DialogTitle
                id="alert-dialog-slide-title"
                className={classNames(
                    classes.header,
                    classes.backgroundPrimary
                )}
            >
                <ClearIcon
                    className={classNames(classes.clearIcon, classes.colorWhite)}
                    onClick={() => closeDialog(null)}
                />
                <div className={classes.headerContent}>
                    <CreditCardIcon className={classes.moneyIcon} />
                    <Typography className={classes.headerTitle}>
                        Process Online Payment
                    </Typography>
                    <Typography className={classes.payerName}>
                        <span onClick={openMySettingsDialog}>
                            {payerName}
                        </span>
                    </Typography>
                    <Typography className={classes.fontSize12}>
                        <span onClick={openMySettingsDialog}>
                            {payerEmail || 'No email listed'}
                        </span>
                    </Typography>
                    <Typography className={classes.fontSize12}>
                        <span onClick={openMySettingsDialog}>
                            {payerPhone && getFormattedPhoneNumber(payerPhone) || 'No number listed'}
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {screen}
            </DialogContent>
        </Dialog>
    );
};

export default BaseOnlinePaymentDialog;
