import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { GREEN_COLOR, RED_COLOR } from '../../../constants/colorVariables';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';

import { WithStyles, StyleRulesCallback } from '@mui/styles';

import withGStyles from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        gridRowEnd: 4,
        gridRowStart: 3,
        gridColumnEnd: 3,
        gridColumnStart: 1,
        '@media (min-width: 700px)': {
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 2,
            gridColumnEnd: 2,
            marginRight: 8,
            justifyContent: 'flex-end'
        },
        '& button': {
            minHeight: 24,
            padding: 2,
            textTransform: 'capitalize'
        }
    },
    approveButton: {
        color: GREEN_COLOR,
    },
    blockButton: {
        color: RED_COLOR,
    },
});

interface Props {
    onApprove: () => void;
    onBlock: () => void;
}

export type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'approveButton' | 'blockButton'>;

const ModerateButtons = (props: StyledProps & Props) => {
    const { classes, onBlock, onApprove } = props;

    return (
        <Grid item className={classes.root}>
            <Button
                className={classes.approveButton}
                onClick={onApprove}
            >
                <DoneIcon />&nbsp;Approve
            </Button>
            <Button
                className={classes.blockButton}
                onClick={onBlock}
            >
                <CloseIcon />&nbsp;Block
            </Button>
        </Grid>
    );
};

export default withGStyles(styles)(ModerateButtons);
