import classNames from 'classnames';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import { ShareOptionType } from '../../../widgets/ShareLinkSection';
import { EMAIL_COLOR, FACEBOOK_COLOR, SHARE_COLOR, SMS_COLOR } from '../../../utils';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    link: {
        margin: '4px 8px',
        '@media (min-width: 600px)': {
            margin: 0,
            '&:not(:last-of-type)': {
                marginRight: 12,
            }
        }
    },
    button: {
        textTransform: 'none',
        minHeight: 120,
        maxHeight: 120,
        minWidth: 120,
        maxWidth: 120,
        '@media (min-width: 768px)': {
            minHeight: 152,
            maxHeight: 152,
            minWidth: 168,
            maxWidth: 168,
        },
        '& svg': {
            fontSize: 60,
            '@media (min-width: 768px)': {
                fontSize: 88,
            },
            '@media (min-width: 960px)': {
                fontSize: 112
            }
        },
    },
    FACEBOOK: {
        color: FACEBOOK_COLOR
    },
    EMAIL: {
        color: EMAIL_COLOR
    },
    SMS: {
        color: SMS_COLOR,
        '& svg': {
            width: 60,
            height: 60,
            '@media (min-width: 768px)': {
                width: 80,
                height: 80
            },
            '@media (min-width: 960px)': {
                width: 100,
                height: 100
            }
        }
    },
    SHARE: {
        color: SHARE_COLOR,
        '& svg': {
            width: 48,
            height: 48,
            marginBottom: 8,
            '@media (min-width: 768px)': {
                width: 64,
                height: 64,
                marginBottom: 12
            },
            '@media (min-width: 960px)': {
                width: 80,
                height: 80
            }
        }
    },
    buttonLabel: {
        flexDirection: 'column'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        minHeight: 60,
        maxHeight: 60,
        '@media (min-width: 768px)': {
            minHeight: 88,
            maxHeight: 88,
        },
        '@media (min-width: 960px)': {
            minHeight: 112,
            maxHeight: 112,
        }
    },
    shareText: {
        fontWeight: 300,
        fontSize: 14,
        whiteSpace: 'nowrap',
        '@media (min-width: 768px)': {
            fontSize: 18
        }
    }
});
type OptionClass = Extract<ShareOptionType, 'FACEBOOK' | 'SHARE' | 'SMS' | 'EMAIL'>;
type Classes = OptionClass | 'root' | 'buttonLabel' | 'button' | 'iconContainer' | 'shareText' | 'link';
type StyledProps = WithStyles<Classes>;

interface Props {
    option: ShareOptionType;
    title: string;
    icon: JSX.Element;
    link?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

const ShareButton = ({ icon, title, classes, option, link, onClick }: Props & StyledProps) => {
    return (
        <Button
            className={classNames(classes.button, classes[option])}
            classes={{ text: classes.buttonLabel }}
            onClick={e => !!link ? window.open(link) : onClick?.(e)}
        >
            <div className={classes.iconContainer}>{icon}</div>
            <Typography color="inherit" className={classes.shareText}>{title}</Typography>
        </Button>
    );
};

export default withStyles(styles)(ShareButton);
