import {
    PaginatedResponse,
    EntityReportRecord,
    EntityReport,
    EntitySummaryWithAuth,
    UserRoles,
    getGatherCase,
    EntitySummary,
    EntitySearchType,
} from '../shared/types';
import { StoreState } from '../types';
import { registerAppError } from './errors';
import { getFromAPI } from '.';
import { AppDispatch } from '../store';
import { canRetrievePrivateCaseData } from '../shared/authority/can';

/**
 * Going to try using an ENUM in this Redux action definition
 * this could help reduce the amount of "boiler plate" code 
 * by removing the "type" declaration.
 */
export enum PERSON_TABLE {
    'PERSON_TABLE_LOADING' = 'PERSON_TABLE_LOADING',
    'PERSON_TABLE_LOADED' = 'PERSON_TABLE_LOADED',
    'PERSON_TABLE_LOAD_FAILED' = 'PERSON_TABLE_LOAD_FAILED',
    'SET_TOTAL_PERSON_COUNT' = 'SET_TOTAL_PERSON_COUNT',
}

/**
 *   PersonTableLoading
 */
interface PersonTableLoading {
    type: PERSON_TABLE.PERSON_TABLE_LOADING;
    data: EntityReport[];
    searchText: string;
    sortBy: keyof EntityReport;
    sortDirection: 'desc' | 'asc';
    isLoading: boolean;
}
function personTableLoading(
    data: EntityReport[],
    searchText: string,
    sortBy: keyof EntityReport,
    sortDirection: 'asc' | 'desc'
): PersonTableLoading {
    return {
        type: PERSON_TABLE.PERSON_TABLE_LOADING,
        data,
        searchText,
        sortBy,
        sortDirection,
        isLoading: true,
    };
}

/**
 * PersonTableLoaded
 */
interface PersonTableLoaded {
    type: PERSON_TABLE.PERSON_TABLE_LOADED;
    data: EntityReport[];
    hasMoreData: boolean;
    isLoading: boolean;
}
function personTableLoaded(
    data: EntityReport[],
    hasMoreData: boolean,
): PersonTableLoaded {
    return {
        type: PERSON_TABLE.PERSON_TABLE_LOADED,
        data,
        hasMoreData,
        isLoading: false,
    };
}

/**
 * PersonTableLoadFailed
 */
interface PersonTableLoadFailed {
    type: PERSON_TABLE.PERSON_TABLE_LOAD_FAILED;
    errorDetail: object;
}
function personTableLoadFailed(
    errorDetail: object,
): PersonTableLoadFailed {
    return {
        type: PERSON_TABLE.PERSON_TABLE_LOAD_FAILED,
        errorDetail,
    };
}

/**
 * SetTotalPersonCount
 */
interface SetTotalPersonCount {
    type: PERSON_TABLE.SET_TOTAL_PERSON_COUNT;
    count: number;
}
function setTotalPersonCount(
    count: number,
): SetTotalPersonCount {
    return {
        type: PERSON_TABLE.SET_TOTAL_PERSON_COUNT,
        count,
    };
}

export type PersonTableAction =
    | PersonTableLoading
    | PersonTableLoaded
    | PersonTableLoadFailed
    | SetTotalPersonCount
    ;

/**
 * 
 * 
 */

/* **********************************************************************
 *  Define the THUNKS here
 * **********************************************************************
 */

export function getPersonTableRecords(
    newOffset: number = 0,
    newSearchText: string,
    newSortBy: keyof EntityReportRecord,
    newSortDirection: 'asc' | 'desc',
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<EntityReport[] | null> => {
        newSearchText = newSearchText ? newSearchText : '';
        newSortBy = newSortBy ? newSortBy : 'id';
        newSortDirection = newSortDirection ? newSortDirection : 'asc';
        let newData: EntityReport[] = [];
        const { funeralHomeState } = getState();
        const { activeFuneralHome } = funeralHomeState;
        if (!activeFuneralHome) {
            return [];
        }
        const { entityTableState } = getState();
        const { data, searchText, sortBy, sortDirection } = entityTableState;

        if (newOffset === 0 || newSearchText !== searchText
            || newSortBy !== sortBy || newSortDirection !== sortDirection) {
            newOffset = 0;
            newData = [];
        } else {
            newOffset = data.length;
            newData = data;
        }

        const queryParamOffset = `offset=${newOffset}`;
        const queryParamSearchText = 'filter=' + encodeURIComponent(newSearchText);
        const queryParamSortBy = 'sortBy=' + encodeURIComponent(newSortBy);
        const queryParamSortDirection = 'sortDirection=' + encodeURIComponent(newSortDirection);

        dispatch(personTableLoading(newData, newSearchText, newSortBy, newSortDirection));
        const resource = `funeralhome/${activeFuneralHome.id}/entity/`
            + `?${queryParamOffset}`
            + `&${queryParamSearchText}`
            + `&${queryParamSortBy}`
            + `&${queryParamSortDirection}`
            ;
        const response: PaginatedResponse<EntityReport> | null
            = await getFromAPI<PaginatedResponse<EntityReport>>(resource, dispatch);
        if (response !== null) {
            dispatch(personTableLoaded(response.data, response.hasMoreData));
            if (response.totalcount) {
                dispatch(setTotalPersonCount(response.totalcount));
            } else {
                dispatch(setTotalPersonCount(0));
            }
            return response.data;
        } else {
            dispatch(registerAppError('Unable to load person records.'));
            dispatch(personTableLoadFailed({ message: 'failed to load person records' }));
        }
        return [];
    };
}

export function loadEntity(entityId: number) {
    return async (
        dispatch: AppDispatch, getState: () => StoreState): Promise<EntitySummaryWithAuth | null> => {
        const { casesState, funeralHomeState, userSession } = getState();
        const { publicCase, selectedCase } = casesState;
        const { activeFuneralHome } = funeralHomeState;
        const { userData } = userSession;

        const gatherCase = getGatherCase({ selectedCase, publicCase });

        let resource: string;
        if (gatherCase) {
            const canGetPrivateData = canRetrievePrivateCaseData({
                target: userData,
                caseId: gatherCase.id,
                funeralHomeId: gatherCase.funeral_home.id,
            });
            const urlRoot = canGetPrivateData ? `api/case/${gatherCase.uuid}` : `api/remember/${gatherCase.name}`;
            resource = `${urlRoot}/entity/${entityId}`;
        } else if (activeFuneralHome) {
            resource = `funeralhome/${activeFuneralHome.id}/entity/${entityId}`;
        } else if (UserRoles.isGOMUser(userSession.userData)) {
            resource = `api/entity/${entityId}`;
        } else {
            dispatch(registerAppError('Failed to load person', { sendToSentry: true }));
            return null;
        }
        const response = await getFromAPI<EntitySummaryWithAuth>(resource, dispatch);
        return response;
    };
}

export function searchEntities(params: { searchText: string; searchType: EntitySearchType }) {
    return async (dispatch: AppDispatch): Promise<EntitySummary[] | null> => {
        const { searchText, searchType } = params;
        if (!searchText.trim()) {
            return [];
        }
        const resource = `api/entity/search/${searchType}/text/${encodeURIComponent(searchText)}`;
        const response = await getFromAPI<EntitySummary[]>(resource, dispatch);
        return response;
    };
}
