import classNames from 'classnames';
import { sortBy } from 'lodash';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import {
    ProductContractItemUX,
    ProductContractUX,
    ProductUX,
    ProductCategory,
    ProductCategoryDisplayLookup,
    GPLContext,
} from '../../../../shared/types';

import { StyledProps, styleWrapper } from './styles';

import { getLatestItem, isPackagePlaceholder, isPackageDiscount } from '../../../../shared/goods_and_services/utils';
import ContractItemProductOnly from './ContractItemProductOnly';
import ContractItemWithRevisions from './ContractItemWithRevisions';
import { SubtotalItemGrid } from './widgets';
import { formatPrice } from '../../../../shared/goods_and_services/pricing';
import withGStyles from '../../../../styles/WithGStyles';

type Props = {
    category: ProductCategory | 'package';
    visibleCategories: ProductCategory[];
    categoryItemIds: string[];
    activeContract: ProductContractUX;
    isPrintMode: boolean;
    canUserEditContract: boolean;
    isCondensedView: boolean;
    onAddAdditionalItem: (cat: ProductCategory) => void;
    onItemClick: (productId?: number, contractItemId?: string) => void;
    onMenuClick: (ele: HTMLElement, contractItemId: string) => void;
    onNoteClick: (contractItemId: string) => void;
    onPriceAdjustmentClick: (contractItemId: string) => void;
    openProductTaxDetailPopper?: (event: HTMLElement, latestItem: ProductContractItemUX) => void;
    isItemizedStatement: boolean;
};

type ContractLineItem = {
    productOnly?: ProductUX;
    itemRevisions?: ProductContractItemUX[];
    rank: number | null;
    isDiscountInPackageGroup?: boolean;
};

const getContractLineItems = (
    activeContract: ProductContractUX,
    category: ProductCategory | 'package',
    categoryItemIds: string[],
    isItemizedStatement: boolean
) => {
    const { persistent_products, items } = activeContract;

    const categoryPersistentProducts: ProductUX[] = persistent_products.filter((product) => {
        return product.category === category;
    });

    // add all contract items to line items list
    const contractLineItems = items.reduce<ContractLineItem[]>(
        (lineItems, itemRevisions) => {
            const latestItem = getLatestItem(itemRevisions);
            if (!latestItem) {
                return lineItems;
            }

            // don't render items not in this category
            if (!categoryItemIds.some((id) => id === latestItem.id)) {
                return lineItems;
            }

            const newLineItem: ContractLineItem = {
                itemRevisions: itemRevisions,
                rank: latestItem.category_rank,
                isDiscountInPackageGroup: category === 'package' && isPackageDiscount(latestItem),
            };
            return [...lineItems, newLineItem];
        },
        [],
    );

    // for each persistent product, add it to the line items if an item doesn't already exist for that product
    if (!isItemizedStatement) {
        for (const product of categoryPersistentProducts) {
            const itemWithProduct = items.find((itemRevisions) => {
                const latestItem = getLatestItem(itemRevisions);
                return latestItem && latestItem.product ? latestItem.product.id === product.id : false;
            });
            if (!itemWithProduct) {
                contractLineItems.push({
                    productOnly: product,
                    rank: product.category_rank,
                });
            }
        }
    }

    return contractLineItems;
};

const ContractCategoryContent = (props: Props & StyledProps) => {

    const {
        classes,
        category,
        visibleCategories,
        activeContract,
        categoryItemIds,
        isPrintMode,
        canUserEditContract,
        isCondensedView,
        onAddAdditionalItem,
        onItemClick,
        onMenuClick,
        onNoteClick,
        onPriceAdjustmentClick,
        openProductTaxDetailPopper,
        isItemizedStatement
    } = props;

    const { hide_revisions, revisions, taxation_method } = activeContract;

    const showAddItemButton = !isPrintMode && canUserEditContract && category !== 'package'
        && visibleCategories.some((cat) => cat === category);

    const contractLineItems = getContractLineItems(activeContract, category, categoryItemIds, isItemizedStatement);

    if (contractLineItems.length === 0 && !showAddItemButton) {
        return null;
    }

    // sort lineItems by rank
    const sortedLineItems = sortBy(contractLineItems, (lineItem) => lineItem.rank);

    return (
        <Grid
            item
            xs={12}
            className={classNames(
                classes.contentInner,
                !canUserEditContract && classes.restricted,
                !isCondensedView && classes.background_primary_06
            )}
            style={{
                padding: isCondensedView && isPrintMode && '0px 8px 0px' ||
                    isCondensedView && '0px 0px 0px 8px' ||
                    !isCondensedView && isPrintMode && '8px 8px 8px 16px'
                    || '8px 8px 0px 16px',
                borderBottom: isCondensedView || (isPrintMode && contractLineItems.length === 0)
                    ? undefined : '1px solid',
                background: isPrintMode && 'transparent' || undefined
            }}
        >
            {sortedLineItems.map((lineItem: ContractLineItem) => {
                const { productOnly, itemRevisions, isDiscountInPackageGroup } = lineItem;

                if (!itemRevisions && productOnly) {
                    return (
                        <ContractItemProductOnly
                            key={`${productOnly.id}_persistent`}
                            context={GPLContext.App}
                            classes={classes}
                            product={productOnly}
                            isPrintMode={isPrintMode}
                            onItemClick={() => onItemClick(productOnly.id)}
                            canUserEditContract={canUserEditContract}
                            isCondensedView={isCondensedView}
                            overridePrice={productOnly.use_na_when_always_displayed ? 'N/A' : '_____'}
                        />
                    );
                } else if (!itemRevisions) {
                    return null;
                }

                const latestItem = getLatestItem(itemRevisions);
                if (!latestItem || isPackagePlaceholder(latestItem)
                    || (hide_revisions && latestItem.delete_revision)) {
                    return null;
                }
                const { id, product } = latestItem;

                if (isDiscountInPackageGroup) {
                    return (
                        <div
                            style={{
                                marginRight: isPrintMode ? 0 : isCondensedView ? 16 : !canUserEditContract ? 16 : 36
                            }}
                            key={id}
                        >
                            <SubtotalItemGrid
                                key={id}
                                classes={classes}
                                item={{
                                    label: `Package ${latestItem.list_price <= 0 ? 'Discount' : 'Premium'}`,
                                    price: formatPrice(latestItem.list_price, latestItem.asset_type),
                                    isTaxable: latestItem.tax_rate_id !== null
                                }}
                                priceFontWeight={500}
                                isStriked={latestItem.delete_revision !== null}
                                fontSize={isCondensedView ? 10 : 14}
                                indent
                                isCondensedView={isCondensedView}
                                printMode={isPrintMode}
                                hasMenu
                                canUserEditContract={canUserEditContract}
                                openProductTaxDetailPopper={(e) => openProductTaxDetailPopper
                                    && openProductTaxDetailPopper(e, latestItem)
                                }
                                showTaxIcon={taxation_method === 'per_item_basis'}
                            />
                        </div>
                    );
                }

                const contractItemRevisions = hide_revisions ? [latestItem] : itemRevisions;
                const contractRevisionIds = hide_revisions ? [revisions[revisions.length - 1]] : revisions;

                return (
                    <ContractItemWithRevisions
                        key={id}
                        classes={classes}
                        contractItemRevisions={contractItemRevisions}
                        isCondensedView={isCondensedView}
                        hidePackageItemPrices={activeContract.contract_options.hide_package_item_prices}
                        canUserEditContract={canUserEditContract}
                        product={product}
                        printMode={isPrintMode}
                        contractRevisionIds={contractRevisionIds}
                        onItemClick={() => onItemClick(product ? product.id : undefined, id)}
                        onMenuClick={(ele) => onMenuClick(ele, id)}
                        onNoteClick={() => onNoteClick(id)}
                        onPriceAdjustmentClick={() => onPriceAdjustmentClick(id)}
                        openProductTaxDetailPopper={(e) => openProductTaxDetailPopper
                            && openProductTaxDetailPopper(e, latestItem)
                        }
                        showTaxIcon={taxation_method === 'per_item_basis'}
                    />
                );
            })}
            {showAddItemButton &&
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.displayInlineFlex,
                        classes.width100
                    )}
                >
                    <Button
                        color="primary"
                        className={isCondensedView ? classes.addItemCompactButton : classes.addItemButton}
                        onClick={() => onAddAdditionalItem(category)}
                    >
                        + Add {ProductCategoryDisplayLookup[category]} Item
                    </Button>
                </Grid>
            }
        </Grid>
    );
};

const componentStyles = styleWrapper<Props>();
export default withGStyles(componentStyles)(ContractCategoryContent);
