import * as React from 'react';
import { isTouchDevice } from '../../services/app.service';

interface Props {
    onHoverEnter: (target: HTMLElement) => void;
    onHoverLeave: () => void;
    enterDelayMs?: number;
    leaveDelayMs?: number;
    rootClass?: string;
}

interface State {
    hoverActive: boolean;
}

class HoverListener extends React.Component<React.PropsWithChildren<Props>, State> {

    state: State = {
        hoverActive: false
    };

    private leaveTimer: NodeJS.Timer | null = null;
    private enterTimer: NodeJS.Timer | null = null;

    componentWillUnmount() {
        if (this.leaveTimer) {
            clearTimeout(this.leaveTimer);
        }
        if (this.enterTimer) {
            clearTimeout(this.enterTimer);
        }
    }

    hoverEnter = (target: HTMLElement) => {
        const { onHoverEnter } = this.props;

        this.setState({
            hoverActive: true,
        });
        onHoverEnter(target);
    };

    hoverLeave = () => {
        const { onHoverLeave } = this.props;

        this.setState({
            hoverActive: false,
        });
        onHoverLeave();
    };

    handleMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
        const { enterDelayMs } = this.props;

        const { currentTarget } = e;

        if (enterDelayMs) {
            this.enterTimer = setTimeout(() => this.hoverEnter(currentTarget), enterDelayMs);
        } else {
            this.hoverEnter(currentTarget);
        }

        if (this.leaveTimer) {
            // cancel the leave
            clearTimeout(this.leaveTimer);
            this.leaveTimer = null;
        }
    };

    handleMouseLeave = () => {
        const { leaveDelayMs } = this.props;

        if (leaveDelayMs) {
            this.leaveTimer = setTimeout(this.hoverLeave, leaveDelayMs);
        } else {
            this.hoverLeave();
        }

        if (this.enterTimer) {
            // cancel the enter
            clearTimeout(this.enterTimer);
            this.enterTimer = null;
        }
    };

    handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        const { hoverActive } = this.state;
        if (isTouchDevice() && !hoverActive) {
            const { currentTarget } = event;
            this.hoverEnter(currentTarget);
        }
    };

    render() {
        const { children, rootClass } = this.props;

        return (
            <span
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
                className={rootClass}
            >
                {children}
            </span>
        );
    }
}

export default HoverListener;
