import Hidden from "@mui/material/Hidden";
import { OverviewDivider, SectionTypography } from "./components";
import OverviewLabel from "./OverviewLabel";
import { OverviewSectionCommonProps } from "./OverviewForm";
import { ConfigurableEducationFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { emptyStringParser } from "../../../../../services";

interface Props extends OverviewSectionCommonProps {
}
const EducationOverview = (props: Props) => {
    const { deathCertificate, printMode, divider, showHeading, alwaysVisible } = props;

    const highest: string | null = deathCertificate.education.educationListOfOptions || null;
    const history: string | null = emptyStringParser(deathCertificate.education.educationHistory) || null;

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    EDUCATION
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    fieldKey={ConfigurableEducationFieldKey.educationListOfOptions}
                    label="Highest"
                    html={highest}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableEducationFieldKey.educationHistory}
                    label="History"
                    html={history}
                    alwaysVisible={alwaysVisible}
                />
            </SectionTypography>

            {divider &&
                <Hidden mdUp>
                    <OverviewDivider
                        style={{
                            margin: '10px 0 16px',
                            backgroundColor: 'rgba(0, 0, 0, 0.21)',
                        }}
                    />
                </Hidden>
            }
        </div>
    );
};

export default EducationOverview;