import { Component } from 'react';
import classNames from 'classnames';

import Button from '@mui/material/Button';

import { ORANGE_COLOR } from '../../../../constants/colorVariables';
import { GatherCaseUX } from '../../../../shared/types';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import CaseNumberDialog from '../dialogs/CaseNumber.dialog';
import Tooltip from '@mui/material/Tooltip';
import { getIntercomTargetProp } from '../../../../services';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    caseNumberButton: {
        borderRadius: 20,
        color: theme.palette.secondary.main,
        background: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        padding: '2px 16px',
        minHeight: 20,
        fontWeight: 400,
        '&:hover': {
            background: theme.palette.common.white,
            textDecoration: 'underline'
        },
        '&$isTest': {
            background: ORANGE_COLOR,
            color: theme.palette.common.white,
            border: 'none !important',
            '&:hover': {
                background: ORANGE_COLOR,
            }
        }
    },
    isTest: {},
});

type StyledProps = WithStyles<'root' | 'caseNumberButton' | 'isTest'>;

interface Props {
    dispatch: AppDispatch;
    zIndex: number;
    activeCase: Pick<GatherCaseUX, 'uuid' | 'fname' | 'lname' | 'case_number' | 'is_test'>;
    buttonClass?: string;
    hideButton?: boolean;
    buttonColorClass?: string;
    showTooltip?: boolean;
    intercomTargetProp?: string;
}

interface State {
    isCaseNumberDialogOpen: boolean;
    isCaseNumberDialogMounted: boolean;
}

type CombinedProps = Props & StyledProps;

class CaseNumberButton extends Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            isCaseNumberDialogOpen: false,
            isCaseNumberDialogMounted: false
        };
    }

    openCaseNumberDialog = () => {
        this.setState({ isCaseNumberDialogOpen: true, isCaseNumberDialogMounted: true });
    };

    closeCaseNumberDialog = () => {
        this.setState({ isCaseNumberDialogOpen: false });
        // give dialog time to animate away
        setTimeout(() => this.setState({ isCaseNumberDialogMounted: false }), 300);
    };

    render() {
        const {
            activeCase,
            zIndex,
            hideButton,
            showTooltip,
            classes,
            buttonClass,
            buttonColorClass,
            intercomTargetProp
        } = this.props;

        const {
            isCaseNumberDialogOpen,
        } = this.state;

        if (hideButton) {
            return null;
        }

        return (
            <>
                <Tooltip
                    placement="top"
                    enterDelay={1200}
                    title={showTooltip && activeCase.case_number ? activeCase.case_number : ''}
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        className={classNames(
                            classes.caseNumberButton,
                            activeCase.is_test && classes.isTest,
                            buttonColorClass,
                            buttonClass
                        )}
                        component="span"
                        onClick={() => this.openCaseNumberDialog()}
                        {...getIntercomTargetProp(intercomTargetProp)}
                    >
                        {activeCase.is_test
                            ? 'TEST CASE'
                            : activeCase.case_number || '+ Case Number'
                        }
                    </Button>
                </Tooltip>

                <CaseNumberDialog
                    activeCase={activeCase}
                    isDialogOpen={isCaseNumberDialogOpen}
                    closeDialog={this.closeCaseNumberDialog}
                    zIndex={zIndex}
                    isMultiline={true}
                    numberOfRows={4}
                />
            </>
        );
    }
}

export default withState()(withStyles(styles)(CaseNumberButton));
