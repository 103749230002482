import * as classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {},
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '1px solid',
        color: theme.palette.primary.main,
        margin: '0 12px',
        flex: '0 0 auto',
        width: 'calc(100% - 24px)',
    },
    btnDisabled: {
        color: 'rgba(0,0,0,0.12)',
        background: 'rgba(0,0,0,0.12) !important',
        pointerEvents: 'none',
    },
    showText: {
        display: 'none',
        '@media (min-width: 375px)': {
            display: 'block'
        }
    },
}), { name: 'ConfigureDialogFooterButton' });

interface Props {
    isSaving?: boolean;
    deleteBtnText: string;
    handleClose: () => void;
    handleDelete?: () => void;
    handleSave?: () => void;
}

const ConfigureDialogFooterButton = (props: Props) => {
    const { handleClose, handleDelete, handleSave, isSaving, deleteBtnText } = props;
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.footer}>
            <Button
                color="primary"
                className={classes.margin_8}
                onClick={handleClose}
            >
                cancel
            </Button>
            {handleDelete &&
                <Button
                    color="primary"
                    className={classes.margin_8}
                    onClick={handleDelete}
                >
                    <span>delete&nbsp;</span><span className={classes.showText}>{deleteBtnText}</span>
                </Button>
            }
            <Button
                variant="outlined"
                color="primary"
                className={classNames(
                    classes.margin_8,
                    isSaving && classes.btnDisabled,
                )}
                onClick={handleSave}
            >
                <span>save&nbsp;</span><span className={classes.showText}>changes</span>
                {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        </Grid>
    );
};

export default ConfigureDialogFooterButton;