import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { Grid, GridCellProps, RenderedSection } from 'react-virtualized/dist/commonjs/Grid';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { Index, IndexRange } from 'react-virtualized';
import { InfiniteLoader } from 'react-virtualized/dist/commonjs/InfiniteLoader';

import makeStyles from '@mui/styles/makeStyles';

interface Props<T> {
    data: Array<T>;
    render: (item: T, measure?: () => void) => JSX.Element;
    itemWidth: number;
    defaultCellHeight: number;
    firstChild?: JSX.Element;
    lastChild?: JSX.Element;
    penultimateChild?: JSX.Element;
    rootClass?: string;
    getRef?: (ref: Grid | null) => void;
}

type DataChunkType<T> = {
    [index: number]: T;
};

const useStyles = makeStyles({
    root: {
        '&:focus': {
            outline: 'none !important'
        }
    },
}, { name: 'HorizontalReactVirtualizedList' });

const minimumBatchSize: number = 50;
const threshold: number = 60;

function HorizontalReactVirtualizedList<T>(props: Props<T>) {
    const {
        itemWidth,
        data,
        firstChild,
        getRef,
        rootClass,
        lastChild,
        penultimateChild,
        defaultCellHeight,
        render
    } = props;

    const classes = useStyles();

    const setInitialData = () => {
        let initialData: DataChunkType<T> = {};

        for (let i = 0; i <= minimumBatchSize; i++) {
            initialData[i] = data[i];
        }
        return initialData;
    };

    const [startIndex, setStartIndex] = useState(0);
    const [stopIndex, setStopIndex] = useState(0);
    const [dataChunks, setDataChunks] = useState(setInitialData());

    const cache: CellMeasurerCache = useMemo(() => new CellMeasurerCache({
        minHeight: defaultCellHeight,
        fixedWidth: true,
    }), [defaultCellHeight]);
    const infiniteLoader = useRef<InfiniteLoader>(null);
    const gridRef = useRef<Grid | null>(null);

    const setDataChunksInState = useCallback(
        (index: IndexRange, resolve: (value?: unknown) => void) => {
            let dataObject: DataChunkType<T> = {};
            for (let i = index.startIndex; i <= index.stopIndex; i++) {
                dataObject[i] = data[i];
            }

            setDataChunks(prevDataChunks => ({ ...prevDataChunks, ...dataObject }));
            resolve();
        }, [data]);

    const loadMoreRows = useCallback(
        (indexRange: IndexRange) =>
            new Promise(resolve => setDataChunksInState(indexRange, resolve)),
        [setDataChunksInState]
    );

    useEffect(() => {
        loadMoreRows({ startIndex, stopIndex });
    }, [loadMoreRows, startIndex, stopIndex]);

    const isRowLoaded = ({ index }: Index) => Boolean(dataChunks[index]);

    const onSectionRendered = (
        { columnStartIndex, columnStopIndex }: RenderedSection,
        onRowsRendered: (params: IndexRange) => void
    ) => {
        setStartIndex(columnStartIndex);
        setStopIndex(columnStopIndex);

        onRowsRendered({
            startIndex: columnStartIndex,
            stopIndex: columnStopIndex
        });
    };

    const recomputeGridSize = useCallback(() => {
        cache.clearAll();
        gridRef.current?.forceUpdate();
        gridRef.current?.recomputeGridSize();
    }, [cache]);

    useEffect(() => recomputeGridSize(), [data, recomputeGridSize]);

    /**
     * CellMeasurer is used to auto calculate the
     * dimensions of item and it stores the data in cache
     */
    const cellRenderer = ({ columnIndex, key, parent, style, isVisible }: GridCellProps) => {
        const index = firstChild ? columnIndex - 1 : columnIndex;
        const item = dataChunks[index < 0 ? 0 : index];

        if (!isVisible) {
            return;
        }

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={columnIndex}
                key={key}
                parent={parent}
                rowIndex={0}
            >
                {({ measure, registerChild }) => (
                    <div
                        ref={node => node && registerChild ? registerChild(node) : undefined}
                        style={{ ...style, width: itemWidth, listStyleType: 'none' }}
                        key={key}
                    >
                        {firstChild && columnIndex === 0 && firstChild}
                        {columnIndex === 0 && firstChild ? undefined : item && render(item, measure)}
                        {index === data.length && penultimateChild}
                        {index === data.length + 1 && lastChild}
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const columnCount = data.length + (firstChild ? 1 : 0) + (lastChild ? 1 : 0) + (penultimateChild ? 1 : 0);

    /**
     * Window Scroller will help to scroll the Grid items
     * using body scrollbar
     */
    return (
        <WindowScroller onResize={recomputeGridSize}>
            {({ height, width, isScrolling }) => (
                <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    rowCount={columnCount}
                    minimumBatchSize={minimumBatchSize}
                    threshold={threshold}
                    ref={infiniteLoader}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <Grid
                            className={classNames(classes.root, rootClass)}
                            columnCount={columnCount}
                            columnWidth={itemWidth}
                            deferredMeasurementCache={cache}
                            overscanColumnCount={0}
                            onSectionRendered={params => onSectionRendered(params, onRowsRendered)}
                            cellRenderer={cellRenderer}
                            rowCount={1}
                            rowHeight={cache.rowHeight}
                            height={height}
                            autoHeight
                            width={width}
                            isScrolling={isScrolling}
                            autoWidth={(width / (columnCount * itemWidth)) >= 1 ? true : undefined}
                            ref={node => {
                                gridRef.current = node;
                                registerChild(node);
                                if (getRef) {
                                    getRef(node);
                                }
                            }}
                        />
                    )}
                </InfiniteLoader>
            )}
        </WindowScroller>
    );
}

export default HorizontalReactVirtualizedList;