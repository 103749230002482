import { Component } from 'react';
import classNames from 'classnames';
/* eslint-disable */
export default class CardReaderWithCardSVG extends Component<{ themeFillClass: string }> {
    render() {
        const { themeFillClass } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 109.6600037 109.6600037">
                <defs>
                    <style type="text/css" dangerouslySetInnerHTML={{
                        __html: `
                        .st0{fill:none;}
                        .st1{opacity:0.15;fill:#005CEF;}
                        .st2{opacity:0.7;fill:#005CEF;}
                        .st3{fill:#005CEF;}
                        .st4{opacity:0.35;fill:#005CEF;}
                        .st5{fill:#FFFFFF;}
                        .st6{fill:#303030;}
                        .st7{fill:#D0D2D3;}
                        </style>
                    `}} />
                </defs>
                <g>
                    <rect className={'st0'} width="109.6600037" height="109.6600037" />
                    <g>
                        <path className={classNames('st1', themeFillClass)} d="M54.9483032,14.917572c-4.3375854,0-8.4450073,0.9828491-12.1148071,2.7346802
                            c-5.9285889,5.0314941-9.7011719,12.5300293-9.7011719,20.8969116c0,14.5391846,11.3814697,26.4649658,25.7017822,27.3497314
                            l8.8421631-7.4620361c0.0275269-0.0230713,0.0610962-0.0310059,0.0907593-0.0494995
                            c0.045166-0.0286865,0.0881958-0.057373,0.1392212-0.0759888c0.0487061-0.0176392,0.0977783-0.0227051,0.1483154-0.0299683
                            c0.0359497-0.0053101,0.0681152-0.0213013,0.1055908-0.0213013H78.65625
                            c2.812561-4.3822021,4.4503784-9.5903931,4.4503784-15.1842041C83.1066284,27.5245056,70.4996948,14.917572,54.9483032,14.917572z
                            M70.2474365,48.8626404c0,1.3347168-1.092041,2.4267578-2.4267578,2.4267578H53.2601929
                            c-1.3347168,0-2.4267578-1.092041-2.4267578-2.4267578V36.7288513c0-1.3347168,1.092041-2.4267578,2.4267578-2.4267578h1.2133789
                            v-2.4267578c0-3.348877,2.7179565-6.0668335,6.0668945-6.0668335c3.348877,0,6.0668335,2.7179565,6.0668335,6.0668335v2.4267578
                            h1.2133789c1.3347168,0,2.4267578,1.092041,2.4267578,2.4267578V48.8626404z"/>
                        <circle className={classNames('st1', themeFillClass)} cx="60.5404663" cy="42.7957458" r="2.4267578" />
                        <path className={classNames('st1', themeFillClass)} d="M60.5404663,28.1138611c-2.0748901,0-3.7614746,1.6866455-3.7614746,3.7614746
                            v2.4267578h7.5228882v-2.4267578C64.3018799,29.8005066,62.6152954,28.1138611,60.5404663,28.1138611z"/>
                        <path className={classNames('st1', themeFillClass)} d="M31.6323242,38.5491638c0-6.6998901,2.2973022-12.8695679,6.1369629-17.7770996
                            C31.0942383,25.921051,26.789978,33.9936829,26.789978,43.0758972c0,14.7711792,11.3760376,26.8773804,25.8434448,28.0558472
                            l4.586853-3.8709106C42.8391724,65.6078796,31.6323242,53.3657532,31.6323242,38.5491638z"/>
                        <path className={classNames('st2', themeFillClass)} d="M57.2202759,67.2608337l1.6138306-1.3619385
                            c-14.3203125-0.8847656-25.7017822-12.8105469-25.7017822-27.3497314c0-8.3668823,3.772583-15.8654175,9.7011719-20.8969116
                            c4.7803345-4.0570068,10.9608765-6.5117798,17.7070313-6.5117798c15.112793,0,27.4082031,12.2954102,27.4082031,27.4086914
                            c0,7.7329712-3.2242432,14.7230225-8.3928833,19.7109375h2.1033936
                            c4.8267822-5.1681519,7.7894897-12.0983276,7.7894897-19.7109375c0-15.9404297-12.9682617-28.9086914-28.9082031-28.9086914
                            c-9.2402344,0-17.4755249,4.3630371-22.7712402,11.1315918c-3.8396606,4.9075317-6.1369629,11.0772095-6.1369629,17.7770996
                            C31.6323242,53.3657532,42.8391724,65.6078796,57.2202759,67.2608337z"/>
                        <path className={classNames('st2', themeFillClass)} d="M67.8206787,34.3020935h-1.2133789v-2.4267578
                            c0-3.348877-2.7179565-6.0668335-6.0668335-6.0668335c-3.348938,0-6.0668945,2.7179565-6.0668945,6.0668335v2.4267578h-1.2133789
                            c-1.3347168,0-2.4267578,1.092041-2.4267578,2.4267578v12.1337891c0,1.3347168,1.092041,2.4267578,2.4267578,2.4267578h14.5604858
                            c1.3347168,0,2.4267578-1.092041,2.4267578-2.4267578V36.7288513
                            C70.2474365,35.3941345,69.1553955,34.3020935,67.8206787,34.3020935z M60.5404663,45.2225037
                            c-1.3347168,0-2.4267578-1.092041-2.4267578-2.4267578s1.092041-2.4267578,2.4267578-2.4267578
                            s2.4267578,1.092041,2.4267578,2.4267578S61.8751831,45.2225037,60.5404663,45.2225037z M64.3018799,34.3020935h-7.5228882
                            v-2.4267578c0-2.0748291,1.6865845-3.7614746,3.7614746-3.7614746c2.0748291,0,3.7614136,1.6866455,3.7614136,3.7614746
                            V34.3020935z"/>
                        <path className={classNames('st3', themeFillClass)} d="M101.8984375,70.8245544
                            c-1.722168-0.3662109-4.0136719,0.7700195-5.5214844,2.0561523c-0.0005493,0.0004883-0.0012207,0.0005493-0.00177,0.0009766
                            c-0.0004883,0.0004272-0.0006714,0.0010376-0.0011597,0.0014648l-34.0709839,28.7623291
                            c-1.9500122,1.7151489-2.2131958,3.4110718-2.2131958,4.8446045v1.6699219h26.6303711
                            c9.7817383,0,17.7397461-8.0029297,17.7397461-17.8398438V76.1600037
                            C104.4599609,73.9676208,104.015625,71.2620544,101.8984375,70.8245544z"/>
                        <path className={classNames('st3', themeFillClass)} d="M58.5898438,106.4900818c0-1.6660156,0.3095703-3.8505859,2.7250977-5.9736328
                            c0.0019531-0.001709,0.0044556-0.0020142,0.0064087-0.0036621c0.0020142-0.00177,0.0027466-0.0043335,0.0048218-0.0061035
                            l8.6088867-7.2675171V85.877655l-2.8613281,2.4156494c-0.0773315,0.0652466-0.1647949,0.1082153-0.2556763,0.1372681
                            c-0.0123901,0.0054321-0.0205688,0.0161133-0.0333862,0.0209351l-25.4125977,9.5773315
                            c-0.8045654,0.3408203-1.5170898,0.6110229-2.1918945,0.847168v9.2839966h19.409668V106.4900818z"/>
                        <path className={classNames('st3', themeFillClass)} d="M99.2766113,61.1066589l-2.7849121,2.3510742v7.4667358
                            c1.4074707-0.949646,3.1431274-1.7049561,4.8085938-1.6699219v-3.8245239
                            C101.300293,63.7462463,100.5545654,62.1756897,99.2766113,61.1066589z"/>
                        <path className={classNames('st4', themeFillClass)} d="M96.4916992,61.4942322l1.4520874-1.2258911
                            c-0.4615479-0.2104492-0.9481812-0.3609009-1.4520874-0.4398193V61.4942322z"/>
                        <path className={'st5'} d="M69.6700439,61.6799622L42.9300537,84.2499695h11.8400269h10.7399902l4.4249878-3.7363281v-3.1080322
                            c0-4.5449219,0.628418-6.0068359,2.3984375-7.8466797l0.1577148-0.1635742l9.1574707-7.7153931h-0.1386108H69.6700439z
                            M55.0688477,77.8128357h-2v-1h2V77.8128357z M58.2842407,78.7937927c-0.8178711,0-1.480896-0.6630249-1.480896-1.480896
                            s0.6630249-1.480896,1.480896-1.480896s1.480896,0.6630249,1.480896,1.480896S59.1021118,78.7937927,58.2842407,78.7937927z"/>
                        <path className={classNames('st4', themeFillClass)} d="M11.0898438,98.4197693h22.1201172
                            c0.0108032,0,0.0197754,0.0057373,0.0304565,0.0061646c0.0114746-0.0004272,0.0220337-0.0062256,0.0335083-0.0061646
                            c0.046875,0,0.0913086-0.0004883,0.1357422-0.0053711c1.7099609-0.0239258,3.1108398-0.2529297,4.7558594-0.7695313
                            c0.7822266-0.2548828,1.6484375-0.5844727,2.6416016-1.0053711c0.0050049-0.0021362,0.0103149-0.0010986,0.0153809-0.0031128
                            c0.0048828-0.0019531,0.0079956-0.0062256,0.0129395-0.0081177l25.361084-9.5579224l3.7385254-3.156189v-3.4005127
                            l-4.4249878,3.7363281H54.7700806H42.9300537l26.7399902-22.5700073h11.8400269h0.1386108l2.2787476-1.9198608H68.4345703
                            L48.2278442,76.8128357h1.8410034v1h-2V76.947052L34.6240234,88.2933044
                            c-0.0529785,0.0445557-0.1132202,0.0721436-0.1731567,0.0997925c-0.0167236,0.0077515-0.0280762,0.0220337-0.0455933,0.0286255
                            l-23.8048096,9.0039063c-1.0001221,0.4171753-1.7834473,0.8447876-2.289917,1.8578491
                            C9.1027222,98.7402039,10.0588379,98.4197693,11.0898438,98.4197693z M32.0236816,95.7600403h-5.762207l3.5204468-1.3261719
                            h5.762207L32.0236816,95.7600403z M40.9544067,92.3921814l-3.5203857,1.3261719h-5.7622681l3.5204468-1.3261719H40.9544067z
                            M37.057312,91.6767273l3.5204468-1.3262329h5.7622681l-3.5204468,1.3262329H37.057312z M39.9018555,95.7600403h-5.7622681
                            l3.5204468-1.3261719h5.7622681L39.9018555,95.7600403z M45.3121338,93.7183533h-5.762207l3.5203857-1.3261719h5.7622681
                            L45.3121338,93.7183533z M50.6976929,91.6767273h-5.762207l3.5204468-1.3262329h5.762207L50.6976929,91.6767273z
                            M32.699646,90.3504944h5.762207l-3.5204468,1.3262329h-5.762207L32.699646,90.3504944z M27.3140259,92.3921814h5.7622681
                            l-3.5204468,1.3261719h-5.762207L27.3140259,92.3921814z M21.9037476,94.4338684h5.7622681l-3.5204468,1.3261719h-5.7622681
                            L21.9037476,94.4338684z"/>
                        <path className={classNames('st2', themeFillClass)} d="M37.6801758,99.3363953
                            c-1.1608276,0.3049316-2.2752686,0.4776001-3.4399414,0.5385742v8.2850342h3.4399414V99.3363953z"/>
                        <path className={classNames('st2', themeFillClass)} d="M32.7402344,99.9197693H11.0898438
                            c-1.8911133,0-3.4296875,1.5385742-3.4296875,3.4301758v4.8100586h25.0800781V99.9197693z"/>
                        <path className={'st6'} d="M108.9100037,108.1600037h-14.76828c6.9343872-2.9257813,11.8182373-9.8190308,11.8182373-17.8398438
                            V76.1600037c0-4.4147949-1.604187-6.0678101-3.159668-6.633606v-4.0963745c0-2.4291992-1.2231445-4.671875-3.265625-5.9951172
                            c-0.9105835-0.6037598-1.949646-0.97229-3.0429688-1.107605v-5.1082153c0-0.421875,0-1.7060547-0.9550781-2.1118164
                            c-0.9506836-0.4052734-1.9555664,0.4985352-2.1098633,0.6494141l-7.7189941,6.503418h-4.0485229h-2.1033936H78.65625H68.1601563
                            c-0.0374756,0-0.0696411,0.0159912-0.1055908,0.0213013c-0.0505371,0.0072632-0.0996094,0.0123291-0.1483154,0.0299683
                            c-0.0510254,0.0186157-0.0940552,0.0473022-0.1392212,0.0759888c-0.0296631,0.0184937-0.0632324,0.0264282-0.0907593,0.0494995
                            l-8.8421631,7.4620361l-1.6138306,1.3619385l-4.586853,3.8709106l-7.5645752,6.3838501v0.2972412h-0.3522339
                            l-10.9622803,9.2512207l-23.699646,8.9641113c-0.0041504,0.0015869-0.0067139,0.005127-0.0108032,0.0067749
                            c-0.0041504,0.0016479-0.0084839,0.0007935-0.0126343,0.0025024c-2.5673828,1.0698242-3.8710938,2.4946289-3.8710938,7.3125
                            v4.8100586H0.75c-0.4140625,0-0.75,0.3359375-0.75,0.75s0.3359375,0.75,0.75,0.75h108.1600037
                            c0.4140625,0,0.75-0.3359375,0.75-0.75S109.3240662,108.1600037,108.9100037,108.1600037z M10.6004639,97.4256287
                            l23.8048096-9.0039063c0.0175171-0.0065918,0.0288696-0.020874,0.0455933-0.0286255
                            c0.0599365-0.0276489,0.1201782-0.0552368,0.1731567-0.0997925L48.0688477,76.947052v-0.1342163h0.1589966l20.2067261-17.0527344
                            h15.4928589l-2.2787476,1.9198608l-9.1574707,7.7153931l-0.1577148,0.1635742
                            c-1.7700195,1.8398438-2.3984375,3.3017578-2.3984375,7.8466797v3.1080322v3.4005127l-3.7385254,3.156189l-25.361084,9.5579224
                            c-0.0049438,0.0018921-0.0080566,0.0061646-0.0129395,0.0081177c-0.0050659,0.0020142-0.010376,0.0009766-0.0153809,0.0031128
                            c-0.9931641,0.4208984-1.859375,0.7504883-2.6416016,1.0053711c-1.6450195,0.5166016-3.0458984,0.7456055-4.7558594,0.7695313
                            c-0.0444336,0.0048828-0.0888672,0.0053711-0.1357422,0.0053711c-0.0114746-0.000061-0.0220337,0.0057373-0.0335083,0.0061646
                            c-0.0106812-0.0004272-0.0196533-0.0061646-0.0304565-0.0061646H11.0898438c-1.0310059,0-1.9871216,0.3204346-2.7792969,0.8637085
                            C8.8170166,98.2704163,9.6003418,97.842804,10.6004639,97.4256287z M94.9174805,52.5354919
                            c0.0332031,0.1074219,0.0742188,0.3144531,0.0742188,0.6835938V72.056488L71.4350586,91.9368591v-14.53125
                            c0-4.3198242,0.5727539-5.3447266,1.9692383-6.7963867c0.0209961-0.0205078,0.0620117-0.065918,0.0927734-0.0996094
                            l20.9316406-17.6376953C94.5727539,52.735199,94.7792969,52.5965271,94.9174805,52.5354919z M104.4599609,90.3201599
                            c0,9.8369141-7.9580078,17.8398438-17.7397461,17.8398438H60.0898438v-1.6699219
                            c0-1.4335327,0.2631836-3.1294556,2.2131958-4.8446045l34.0709839-28.7623291
                            c0.0004883-0.0004272,0.0006714-0.0010376,0.0011597-0.0014648c0.0005493-0.0004272,0.0012207-0.0004883,0.00177-0.0009766
                            c1.5078125-1.2861328,3.7993164-2.4223633,5.5214844-2.0561523c2.1171875,0.4375,2.5615234,3.1430664,2.5615234,5.3354492
                            V90.3201599z M34.2402344,99.8749695c1.1646729-0.0609741,2.2791138-0.2336426,3.4399414-0.5385742v8.8236084h-3.4399414
                            V99.8749695z M39.1801758,98.8760071c0.6748047-0.236145,1.3873291-0.5063477,2.1918945-0.847168l25.4125977-9.5773315
                            c0.0128174-0.0048218,0.0209961-0.0155029,0.0333862-0.0209351c0.0908813-0.0290527,0.1783447-0.0720215,0.2556763-0.1372681
                            l2.8613281-2.4156494v7.3615112l-8.6088867,7.2675171c-0.0020752,0.00177-0.0028076,0.0043335-0.0048218,0.0061035
                            c-0.0019531,0.0016479-0.0044556,0.0019531-0.0064087,0.0036621c-2.4155273,2.1230469-2.7250977,4.3076172-2.7250977,5.9736328
                            v1.6699219h-19.409668V98.8760071z M101.300293,69.2545471c-1.6654663-0.0350342-3.401123,0.7202759-4.8085938,1.6699219
                            v-7.4667358l2.7849121-2.3510742c1.2779541,1.0690308,2.0236816,2.6395874,2.0236816,4.3233643V69.2545471z
                            M96.4916992,61.4942322v-1.6657104c0.5039063,0.0789185,0.9905396,0.2293701,1.4520874,0.4398193L96.4916992,61.4942322z
                            M7.6601563,103.3499451c0-1.8916016,1.5385742-3.4301758,3.4296875-3.4301758h21.6503906v8.2402344H7.6601563V103.3499451z"/>
                        <polygon className={'st6'} points="38.461853,90.3504944 32.699646,90.3504944 29.1791992,91.6767273 34.9414063,91.6767273 		" />
                        <polygon className={'st6'} points="46.3400269,90.3504944 40.5777588,90.3504944 37.057312,91.6767273 42.8195801,91.6767273 		" />
                        <polygon className={'st6'} points="44.9354858,91.6767273 50.6976929,91.6767273 54.2181396,90.3504944 48.4559326,90.3504944 		" />
                        <polygon className={'st6'} points="33.0762939,92.3921814 27.3140259,92.3921814 23.7936401,93.7183533 29.5558472,93.7183533 		" />
                        <polygon className={'st6'} points="31.6717529,93.7183533 37.434021,93.7183533 40.9544067,92.3921814 35.1921997,92.3921814 		" />
                        <polygon className={'st6'} points="39.5499268,93.7183533 45.3121338,93.7183533 48.8325806,92.3921814 43.0703125,92.3921814 		" />
                        <polygon className={'st6'} points="27.6660156,94.4338684 21.9037476,94.4338684 18.3833008,95.7600403 24.1455688,95.7600403 		" />
                        <polygon className={'st6'} points="29.7819214,94.4338684 26.2614746,95.7600403 32.0236816,95.7600403 35.5441284,94.4338684 		" />
                        <polygon className={'st6'} points="34.1395874,95.7600403 39.9018555,95.7600403 43.4223022,94.4338684 37.6600342,94.4338684 		" />
                        <path className={'st7'} d="M13.1181641,56.578949c0,0.2763672,0.2236328,0.5,0.5,0.5h10.8535156c0.2763672,0,0.5-0.2236328,0.5-0.5
                            s-0.2236328-0.5-0.5-0.5H13.6181641C13.3417969,56.078949,13.1181641,56.3025818,13.1181641,56.578949z"/>
                        <path className={'st7'} d="M24.9716797,60.0774841c0-0.2763672-0.2236328-0.5-0.5-0.5H13.6181641c-0.2763672,0-0.5,0.2236328-0.5,0.5
                            s0.2236328,0.5,0.5,0.5h10.8535156C24.7480469,60.5774841,24.9716797,60.3538513,24.9716797,60.0774841z"/>
                        <path className={classNames('st2', themeFillClass)} d="M20.8027344,65.5110779c0-0.2763672-0.2236328-0.5-0.5-0.5h-6.6845703
                            c-0.2763672,0-0.5,0.2236328-0.5,0.5s0.2236328,0.5,0.5,0.5h6.6845703
                            C20.5791016,66.0110779,20.8027344,65.7874451,20.8027344,65.5110779z"/>
                        <rect x="9.0859375" y="60.7957458" className={'st7'} width="1" height="2" />
                        <polygon className={'st7'} points="9.0859375,77.8128357 10.0688477,77.8128357 10.0859375,75.7957458 9.0859375,75.7957458 		" />
                        <rect x="9.0859375" y="55.7957458" className={'st7'} width="1" height="2" />
                        <rect x="9.0859375" y="65.7957458" className={'st7'} width="1" height="2" />
                        <rect x="13.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <rect x="9.0859375" y="70.7957458" className={'st7'} width="1" height="2" />
                        <rect x="38.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <rect x="28.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <rect x="33.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <rect x="18.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <rect x="23.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <polygon className={'st7'} points="50.0688477,77.8128357 50.0688477,76.8128357 48.2278442,76.8128357 48.0688477,76.8128357 
                            48.0688477,76.947052 48.0688477,77.8128357 		"/>
                        <rect x="53.0688477" y="76.8128357" className={'st7'} width="2" height="1" />
                        <polygon className={'st7'} points="45.0688477,77.5155945 45.0688477,76.8128357 43.0688477,76.8128357 43.0688477,77.8128357 
                            44.7166138,77.8128357 45.0688477,77.8128357 		"/>
                        <path className={'st7'} d="M9.0859375,51.132843v1.6629028h1v-1.6846313c4.4942627-0.3657837,8.0415039-4.1328125,8.0415039-8.7196655
                            c0-4.828125-3.9282227-8.7563477-8.7563477-8.7563477S0.6152344,37.563324,0.6152344,42.391449
                            C0.6152344,47.1233215,4.3904419,50.9805603,9.0859375,51.132843z M9.3710938,34.6351013
                            c4.2768555,0,7.7563477,3.4794922,7.7563477,7.7563477c0,4.2763672-3.4794922,7.7558594-7.7563477,7.7558594
                            c-4.2763672,0-7.7558594-3.4794922-7.7558594-7.7558594C1.6152344,38.1145935,5.0947266,34.6351013,9.3710938,34.6351013z"/>
                        <circle className={classNames('st1', themeFillClass)} cx="58.2842407" cy="77.3128967" r="1.480896" />
                        <path className={classNames('st2', themeFillClass)} d="M9.418396,44.7853088c-1.0802002,0-1.5049438-0.4824219-1.5626221-1.1011963
                            H6.7021484c0.0629272,1.1483765,0.9229126,1.793335,1.9296875,2.0083618v1.1378784h1.5731201v-1.1273804
                            c1.022522-0.1940308,1.8352661-0.7865601,1.8352661-1.8615112c0-1.4892578-1.2741699-1.9978638-2.4645386-2.307251
                            c-1.1903076-0.3093872-1.5730591-0.6292114-1.5730591-1.1273804c0-0.5715942,0.5296021-0.9700928,1.4157715-0.9700928
                            c0.9333496,0,1.27948,0.4457397,1.3109131,1.1011963h1.1588745c-0.0367432-0.9019165-0.5872803-1.7304077-1.6832275-1.9978638
                            v-1.1483765H8.6318359v1.1326294c-1.0172729,0.2202759-1.8352661,0.8809814-1.8352661,1.8930054
                            c0,1.2113037,1.0015259,1.81427,2.4645386,2.1656494c1.3109131,0.3145752,1.5730591,0.776062,1.5730591,1.2636719
                            C10.8341675,44.2084656,10.5772705,44.7853088,9.418396,44.7853088z"/>
                        <path className={classNames('st1', themeFillClass)} d="M73.4970703,70.509613
                            c-0.0307617,0.0336914-0.0717773,0.0791016-0.0927734,0.0996094c-1.3964844,1.4516602-1.9692383,2.4765625-1.9692383,6.7963867
                            v14.53125L94.9916992,72.056488V53.2190857c0-0.3691406-0.0410156-0.5761719-0.0742188-0.6835938
                            c-0.1381836,0.0610352-0.3447266,0.199707-0.4887695,0.3364258L73.4970703,70.509613z M81.6259766,77.778656l-5.7514648,4.8549805
                            c-0.1411133,0.1186523-0.3125,0.1767578-0.4833984,0.1767578c-0.2133789,0-0.425293-0.090332-0.5737305-0.2661133
                            c-0.2670898-0.3164063-0.2270508-0.7900391,0.0893555-1.0571289l5.7514648-4.8549805
                            c0.3178711-0.2666016,0.7905273-0.2265625,1.0571289,0.0893555C81.9824219,77.0379333,81.9423828,77.5115662,81.6259766,77.778656
                            z M88.1064453,71.3274841c0.2670898,0.3168945,0.2270508,0.7900391-0.0893555,1.0571289l-4.237793,3.5766602
                            c-0.1411133,0.1186523-0.3125,0.1767578-0.4833984,0.1767578c-0.2133789,0-0.425293-0.090332-0.5737305-0.2661133
                            c-0.2670898-0.3168945-0.2270508-0.7900391,0.0893555-1.0571289l4.237793-3.5766602
                            C87.3671875,70.9715271,87.8398438,71.0115662,88.1064453,71.3274841z M89.8652344,65.969574L75.8745117,77.778656
                            c-0.1411133,0.1186523-0.3125,0.1767578-0.4833984,0.1767578c-0.2133789,0-0.425293-0.090332-0.5737305-0.2661133
                            c-0.2670898-0.3168945-0.2270508-0.7900391,0.0893555-1.0571289l13.9907227-11.809082
                            c0.3178711-0.2661133,0.7895508-0.2270508,1.0571289,0.0893555C90.2216797,65.2293396,90.1816406,65.7024841,89.8652344,65.969574
                            z"/>
                        <path className={'st6'} d="M88.8974609,64.8230896l-13.9907227,11.809082c-0.3164063,0.2670898-0.3564453,0.7402344-0.0893555,1.0571289
                            c0.1484375,0.1757813,0.3603516,0.2661133,0.5737305,0.2661133c0.1708984,0,0.3422852-0.0581055,0.4833984-0.1767578
                            l13.9907227-11.809082c0.3164063-0.2670898,0.3564453-0.7402344,0.0893555-1.0571289
                            C89.6870117,64.5960388,89.215332,64.5569763,88.8974609,64.8230896z"/>
                        <path className={'st6'} d="M80.6582031,76.6321716l-5.7514648,4.8549805c-0.3164063,0.2670898-0.3564453,0.7407227-0.0893555,1.0571289
                            c0.1484375,0.1757813,0.3603516,0.2661133,0.5737305,0.2661133c0.1708984,0,0.3422852-0.0581055,0.4833984-0.1767578
                            l5.7514648-4.8549805c0.3164063-0.2670898,0.3564453-0.7407227,0.0893555-1.0571289
                            C81.4487305,76.4056091,80.9760742,76.3655701,80.6582031,76.6321716z"/>
                        <path className={'st6'} d="M82.8115234,74.8147888c-0.3164063,0.2670898-0.3564453,0.7402344-0.0893555,1.0571289
                            c0.1484375,0.1757813,0.3603516,0.2661133,0.5737305,0.2661133c0.1708984,0,0.3422852-0.0581055,0.4833984-0.1767578
                            l4.237793-3.5766602c0.3164063-0.2670898,0.3564453-0.7402344,0.0893555-1.0571289
                            c-0.2666016-0.315918-0.7392578-0.355957-1.0571289-0.0893555L82.8115234,74.8147888z"/>
                    </g>
                </g>
            </svg>
        );
    }
}