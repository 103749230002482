import * as React from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import { SlideTransition } from '../../common/Transitions';
import makeStyles from '@mui/styles/makeStyles';
import useFullScreen from '../../common/hooks/useFullScreen';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    dialog: {
        minWidth: '290px',
    },
    dialogPaper: {
        '@media (min-width: 960px)': {
            width: '720px !important',
            maxWidth: '720px !important',
        },
        '@media (max-width: 695.95px)': {
            margin: '0 !important',
        }
    },
    agreementText: {
        whiteSpace: 'pre-wrap',
    },
    dialogHeader: {
        zIndex: 1,
        padding: '16px 4px',
        boxShadow: theme.shadows[1],
        '& h2': {
            textAlign: 'center',
        }
    },
    dialogContent: {
        margin: 'auto',
        background: '#fff',
        zIndex: 0,
        padding: '0 0 5px',
        marginTop: 10,
        overflowY: 'visible',
    },
    width90: {
        width: '90%',
    },
    button: {
        margin: theme.spacing(),
    },
    footer: {
        width: '100%',
        padding: 8,
        background: '#fff',
        zIndex: 9,
    },
    dialogContainer: {
        alignItems: 'flex-start'
    }
}), { name: 'TermsOfServiceDialog' });

interface Props {
    isDialogOpen: boolean;
    closeDialog: React.MouseEventHandler<HTMLElement>;
    agreement: string;
    zIndex: number;
}

const TermsOfServiceDialog = (props: Props) => {
    const classes = useStyles();
    const fullScreen = useFullScreen();

    const { isDialogOpen, agreement, zIndex, closeDialog } = props;

    return (
        <Dialog
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={closeDialog}
            fullScreen={fullScreen}
            className={classes.dialog}
            classes={{
                paper: classes.dialogPaper,
                container: classes.dialogContainer
            }}
            style={{ zIndex }}
        >
            <DialogTitle className={classes.dialogHeader}>
                <Typography align="center">Terms of Service</Typography>
            </DialogTitle>
            <DialogContent className={classNames(classes.dialogContent, classes.width90)}>
                <Grid item xs={12}>
                    <Typography align="center" className={classes.agreementText}>{agreement}</Typography>
                    <Grid item xs={12} className={classes.footer}>
                        <Grid container justifyContent="space-evenly">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    size="large"
                                    onClick={closeDialog}
                                >
                                    DONE
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
};

export default TermsOfServiceDialog;