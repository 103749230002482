import { useEffect } from 'react';

function useWindowEvent(
    event: keyof WindowEventMap,
    handler: (this: Window, ev: Event) => void,
    passive: boolean = false,
) {
    useEffect(() => {
        window.addEventListener(event, handler, passive);

        return function cleanp() {
            window.removeEventListener(event, handler, passive);
        };
    }, [event, handler, passive]);
}

export default useWindowEvent;
