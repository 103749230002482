import * as React from 'react';
import classNames from 'classnames';
import { isEqual, random } from 'lodash';

import { getFormattedPhoneNumber, arePhoneNumbersEqual, convertHexToRGBA } from '../../services';

import Grid from '@mui/material/Grid';

import {
    UserRole,
    UserRoles,
    UserProfile,
    UserInviteStatus,
    UserInviteStatusEnum,
    GatherCaseUX,
    CaseHelperCreateRequest,
    EntityRelationshipType,
    EntityCaseRole,
    LongAddress,
    NullLongAddress,
    EntityRelationship,
    CaseHelperCreateResponse,
    getCaseEntity,
    EntitySummary,
} from '../../shared/types';
import { StoreState } from '../../types';

import { isValidEmail, isValidPhoneNumber, joinNameParts, splitFullName } from '../../shared/utils';
import SimilarUsersDialog from './SimilarUsersDialog';

import { openAccessRestrictedDialog } from '../../actions/AccessRestricted.action';
import { DCEntityEnum } from '../assignmentPoppers/SelectHelper';
import { setRolodexListDialogOpen } from '../../actions/Rolodex.action';
import { AppDispatch } from '../../store';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import withState from '../common/utilHOC/WithState';

import { log } from '../../logger';
import SuccessScreen from './SuccessScreen';
import InvitationFormFooter from './InvitationFormFooter';
import InvitationFormLeftSection from './InvitationFormLeftSection';
import InvitationFormRightSection from './InvitationFormRightSection';
import InvitationGroupButtons from './InvitationGroupButtons';
import InvitationFormLeftSectionForDC from './InvitationFormLeftSectionForDC';
import InvitationFormRightSectionForDC from './InvitationFormRightSectionForDC';
import { HelperInvitationDialogContext } from '../../actions/Dialog.action';

// props from redux store
const mapStateToProps = ({ funeralHomeState, userSession, casesState }: StoreState) => {

    return {
        funeralHome: funeralHomeState.activeFuneralHome,
        userSession,
        helpers: casesState.helpers,
    };
};

interface Props extends ReturnType<typeof mapStateToProps> {
    showSuccessScreen?: boolean;
    zIndex: number;
    similarUsers: UserProfile[];
    status: UserInviteStatus | null;
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    selectedEntityId: number | null;
    activeCase: GatherCaseUX;
    entityRole: EntityCaseRole;
    initialSendInvite: boolean;
    canInviteFamilyAdmin: boolean;
    setInvitationFormDirty: (isFormDirty: boolean) => void;
    dispatch: AppDispatch;
    toggleSuccessScreen?: () => void;
    onInvite: (request: CaseHelperCreateRequest) => Promise<CaseHelperCreateResponse | null>;
    editHelper: () => void;
    resetInvitationStatus: () => void;
    handleMouseLeaveOnInfoPopper?: () => void;
    openInfoPopper?: (event: React.MouseEvent<HTMLElement>, userType: EntityCaseRole) => void;
    switchView: () => void;
    closeParentDialog: () => void;
    entityRelationship: EntityRelationship | null;
    entityRelationshipType: EntityRelationshipType | null;
    relationToDeceased: DCEntityEnum | null;
    context: HelperInvitationDialogContext | null;
    aliveType: AliveType | null;
    showStep2?: boolean;
    homeAddressOptional: boolean;
    toggleStep2: () => void;
    setFirstName: (value: string) => void;
}

export enum InviteMethod {
    phone = 'phone',
    email = 'email',
    both = 'both',
}

export enum AliveType {
    null = 'null',
    living = 'living',
    deceased = 'deceased'
}

interface State {
    homeAddress: LongAddress;
    fullName: string;
    email: string;
    phone: string;
    otherRelationShip: string;
    isSimilarUsersDialogOpen: boolean;
    isFocused: boolean;
    inviteAttempted: boolean;
    inviteMethod: InviteMethod;
    relationshipType: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationshipAlias: string;
    sendInvitation: boolean;
    aliveType: AliveType;
    newHelper: EntitySummary | null;
    isSendInvitation: boolean;
    entityIdKey: string | number;
    infoPopperContentAnchorEle: HTMLElement | null;
    infoPopperAnchorEle: HTMLElement | null;
    isNonUserSaving: boolean;
    shouldChangeRelation: boolean;
    useAddressDescription: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    invitationFormContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '8px 0',
        zIndex: 1,
        '@media (min-width: 760px)': {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-evenly',
        },
    },
    mainContainer: {
        position: 'relative',
    },
    innerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        pointerEvents: 'none',
        transform: 'scale(0)',
        transition: 'transform 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&$active': {
            transform: 'scale(1)',
            pointerEvents: 'auto',
        }
    },
    divider: {
        height: 1,
        width: 'calc(100% - 48px)',
        maxWidth: 420,
        padding: '24px 0',
        '@media (min-width: 760px)': {
            height: '100%',
            width: 1,
            maxWidth: 1,
            padding: 0,
        },
        '& $inner': {
            background: convertHexToRGBA(theme.palette.primary.main, 0.45),
            height: 1,
            width: '100%',
            maxWidth: 420,
            '@media (min-width: 760px)': {
                position: 'absolute',
                height: '100%',
                width: 1,
                maxWidth: '100%'
            },
        }
    },
    active: {},
    inner: {},
});

type StyledProps = WithGStyles<'root' | 'inner' | 'invitationFormContainer' | 'divider' | 'mainContainer'
    | 'innerContainer' | 'active'>;

// const FLAG_USA_SRC = `/images/flag_usa.png`;
const DEFAULT_STATE: State = {
    homeAddress: NullLongAddress,
    sendInvitation: true,
    fullName: '',
    email: '',
    phone: '',
    otherRelationShip: '',
    isSimilarUsersDialogOpen: false,
    isFocused: false,
    inviteAttempted: false,
    inviteMethod: InviteMethod.phone,
    aliveType: AliveType.living,
    newHelper: null,
    isSendInvitation: false,
    entityIdKey: -1,
    relationshipType: null,
    relationship: null,
    relationshipAlias: '',
    infoPopperContentAnchorEle: null,
    infoPopperAnchorEle: null,
    isNonUserSaving: false,
    shouldChangeRelation: true,
    useAddressDescription: false
};

type CombinedProps = Props & StyledProps;
class InvitationForm extends React.Component<CombinedProps, State> {

    state: State = {
        ...DEFAULT_STATE,
        sendInvitation: this.props.initialSendInvite,
    };

    genRandomValue() {
        return `tbd${random(1000, 9999, false) + Date.now()}`;
    }

    componentDidMount() {
        const { relationToDeceased, entityRelationship, entityRelationshipType, aliveType } = this.props;

        this.setFieldsForSelectedEntity();

        if (relationToDeceased) {
            if (relationToDeceased === DCEntityEnum.Father || relationToDeceased === DCEntityEnum.Mother) {
                this.setState({ aliveType: AliveType.null });
            } else if (relationToDeceased === DCEntityEnum.Spouse || relationToDeceased === DCEntityEnum.Informant) {
                this.setState({ aliveType: AliveType.living });
            }
        } else if (aliveType) {
            this.setState({ aliveType });
        }

        if (entityRelationship || entityRelationshipType) {
            this.setState({ relationship: entityRelationship, relationshipType: entityRelationshipType });
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            initialSendInvite,
            selectedEntityId,
            entityRelationshipType,
            entityRelationship,
            relationToDeceased,
            aliveType
        } = this.props;
        const { shouldChangeRelation } = this.state;
        if (initialSendInvite !== prevProps.initialSendInvite) {
            this.setState({
                sendInvitation: initialSendInvite,
            });
        }
        if (selectedEntityId && selectedEntityId !== prevProps.selectedEntityId) {
            this.setFieldsForSelectedEntity();
        }
        if ((entityRelationship !== prevProps.entityRelationship
            || entityRelationshipType !== prevProps.entityRelationshipType)
            && shouldChangeRelation
        ) {
            this.setState({
                relationship: entityRelationship,
                relationshipType: entityRelationshipType,
                shouldChangeRelation: false
            });
        }
        if (relationToDeceased && relationToDeceased !== prevProps.relationToDeceased) {
            if (relationToDeceased === DCEntityEnum.Father || relationToDeceased === DCEntityEnum.Mother) {
                this.setState({ aliveType: AliveType.null });
            } else if (relationToDeceased === DCEntityEnum.Spouse) {
                this.setState({ aliveType: AliveType.living });
            } else if (relationToDeceased === DCEntityEnum.Informant) {
                this.setState({ aliveType: AliveType.living });
            }
        }

        // we don't want the above feature to override by aliveType 
        if (aliveType && aliveType !== prevProps.aliveType && !relationToDeceased) {
            this.setState({ aliveType });
        }
    }

    setFieldsForSelectedEntity = () => {
        const {
            initialSendInvite,
            selectedEntityId,
            helpers,
            activeCase,
        } = this.props;

        const selectedHelper = selectedEntityId && helpers.find((h) => h.entity_id === selectedEntityId);
        const selectedInitialName = selectedHelper && joinNameParts(selectedHelper);
        const selectedInitialEmail = selectedHelper && selectedHelper.email || '';
        const selectedInitialPhone = selectedHelper && getFormattedPhoneNumber(selectedHelper.phone) || '';
        const selectedInitialAddress = selectedHelper && selectedHelper.home_address || NullLongAddress;
        const caseEntity = selectedHelper && getCaseEntity(selectedHelper, activeCase.id);
        const selectedRelationship = selectedHelper && caseEntity ? caseEntity.relationship : null;
        const selectedRelationshipType = selectedHelper && caseEntity ? caseEntity.relationship_type : null;
        const selectedRelationshipAlias = selectedHelper && caseEntity ? caseEntity.relationship_alias || '' : '';
        const selectedUseDescription = selectedHelper ? selectedHelper.use_address_description : '';

        this.setState({
            fullName: selectedInitialName || '',
            phone: selectedInitialPhone || '',
            email: selectedInitialEmail || '',
            sendInvitation: initialSendInvite,
            entityIdKey: selectedEntityId || this.genRandomValue(),
            relationship: selectedRelationship,
            relationshipType: selectedRelationshipType,
            relationshipAlias: selectedRelationshipAlias,
            homeAddress: selectedInitialAddress,
            useAddressDescription: selectedUseDescription || false
        });
    };

    showEMailTextField = () => {
        const { inviteMethod } = this.state;
        return (inviteMethod === InviteMethod.email || inviteMethod === InviteMethod.both)
            && !this.isDeceased();
    };

    showPhoneTextField = () => {
        const { inviteMethod } = this.state;
        return (inviteMethod === InviteMethod.phone || inviteMethod === InviteMethod.both)
            && !this.isDeceased();
    };

    isDeceased = (): boolean => {
        const { aliveType } = this.state;
        const { formType } = this.props;
        return aliveType === AliveType.deceased && formType === 'INVITE_GUEST';
    };

    isLiving = (): boolean => {
        const { aliveType } = this.state;
        const { formType } = this.props;
        return aliveType === AliveType.living && formType === 'INVITE_GUEST';
    };

    isDontSendInvite = (): boolean => {
        const { sendInvitation } = this.state;
        const { formType } = this.props;
        return !sendInvitation && formType === 'INVITE_GUEST';
    };

    isSuccess = (status = this.props.status) => {
        return status === UserInviteStatusEnum.success;
    };

    isPending = (status = this.props.status) => {
        return status === UserInviteStatusEnum.pending;
    };

    isEmailValid = (allowEmpty?: boolean) => {
        const { email } = this.state;
        if (allowEmpty) {
            return email.trim() === '' || isValidEmail(email);
        }
        return email.trim() !== '' && isValidEmail(email);
    };

    isPhoneValid = (allowEmpty?: boolean) => {
        const { phone } = this.state;
        if (allowEmpty) {
            return phone.trim() === '' || isValidPhoneNumber(phone);
        }
        return phone.trim() !== '' && isValidPhoneNumber(phone);
    };

    isAddressValid = (allowEmpty?: boolean) => {
        const { homeAddress } = this.state;
        if (allowEmpty && isEqual(homeAddress, NullLongAddress)) {
            return true;
        }
        return !isEqual(homeAddress, NullLongAddress);
    };

    openRolodexSearchDialog = () => {
        const { dispatch } = this.props;
        dispatch(setRolodexListDialogOpen(true));
    };

    handleDeceasedToggleOnClick = (aliveType: AliveType, inviteAttempted: boolean) => {
        this.setState({ aliveType, inviteAttempted });
    };

    handleOnChangePhone = (phone: string) => {
        this.setState({ phone });
    };

    handleOnChangeHomeAddress = (addr: LongAddress) => {
        this.setState({ homeAddress: addr });
    };

    handleOnChangeEmail = (email: string) => {
        this.setState({ email });
    };

    handleOnChangeName = (value: string) => {
        const { setFirstName } = this.props;
        this.setState(
            { fullName: value },
            () => {
                const name = splitFullName(this.state.fullName);
                setFirstName(name.fname);
            }
        );
    };

    handleSetRelationship = (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string
    ) => {
        if (relationshipType && relationshipType === EntityRelationshipType.family
            && relationshipAlias && relationshipAlias.trim().length > 0) {
            relationship = EntityRelationship.other;
        }
        this.setState({
            relationshipType,
            relationship,
            relationshipAlias,
        });
        this.props.setInvitationFormDirty(true);
    };

    renderInvitationGroupButtons = () => {
        const { inviteMethod, sendInvitation } = this.state;
        return (
            <InvitationGroupButtons
                sendInvitation={sendInvitation}
                inviteMethod={inviteMethod}
                isDeceased={this.isDeceased()}
                handleGGroupButtonsOnClick={this.handleGGroupButtonsOnClick}
            />
        );
    };

    handleGGroupButtonsOnClick = (value: InviteMethod) => {
        this.setState({
            inviteMethod: value,
            inviteAttempted: false,
        });
    };

    renderInvitationForm = () => {
        const {
            classes,
            relationToDeceased,
        } = this.props;

        return (
            <>
                <Grid item xs={12} className={classes.invitationFormContainer}>
                    {this.renderLeftSection()}
                    {(relationToDeceased === DCEntityEnum.Father
                        || relationToDeceased === DCEntityEnum.Mother)
                        && <></>
                        || <>
                            <div className={classes.divider}>
                                <div className={classes.inner} />
                            </div>
                            {this.renderRightSection()}
                        </>}
                </Grid>
                {this.renderFooter()}
            </>
        );
    };

    renderLeftSection = () => {
        const {
            formType,
            setInvitationFormDirty,
            relationToDeceased,
            zIndex,
            showStep2,
            context,
            funeralHome,
            homeAddressOptional,
        } = this.props;

        const {
            fullName,
            email,
            phone,
            isFocused,
            inviteAttempted,
            aliveType,
            inviteMethod,
            homeAddress,
        } = this.state;

        const dontSendInvite = this.isDontSendInvite();
        const isPhoneValid = this.isPhoneValid(dontSendInvite);
        const isEmailValid = this.isEmailValid(dontSendInvite);
        const isAddressValid = this.isAddressValid(relationToDeceased !== DCEntityEnum.Informant);
        const isInvitationSuccess = this.isSuccess();
        const isDeceased = this.isDeceased();
        const isPending = this.isPending();

        if (!funeralHome) {
            return;
        }

        return (
            <InvitationFormLeftSection
                formType={formType}
                setInvitationFormDirty={setInvitationFormDirty}
                relationToDeceased={relationToDeceased}
                zIndex={zIndex}
                showStep2={showStep2}
                fullName={fullName}
                email={email}
                phone={phone}
                isFocused={isFocused}
                inviteAttempted={inviteAttempted}
                aliveType={aliveType}
                inviteMethod={inviteMethod}
                homeAddress={homeAddress}
                addressOptional={homeAddressOptional}
                dontSendInvite={dontSendInvite}
                isPhoneValid={isPhoneValid}
                isEmailValid={isEmailValid}
                isAddressValid={isAddressValid}
                isInvitationSuccess={isInvitationSuccess}
                isDeceased={isDeceased}
                isPending={isPending}
                context={context}
                openRolodexSearchDialog={this.openRolodexSearchDialog}
                showPhoneTextField={this.showPhoneTextField()}
                showEMailTextField={this.showEMailTextField()}
                handleDeceasedToggleOnClick={this.handleDeceasedToggleOnClick}
                handleOnChangePhone={this.handleOnChangePhone}
                handleOnChangeHomeAddress={this.handleOnChangeHomeAddress}
                handleOnChangeEmail={this.handleOnChangeEmail}
                handleOnChangeName={this.handleOnChangeName}
                renderInvitationGroupButtons={this.renderInvitationGroupButtons}
                funeralHomeID={funeralHome.id}
            />
        );
    };

    renderRightSection = () => {
        const {
            activeCase,
            formType,
            setInvitationFormDirty,
            zIndex,
            relationToDeceased,
            context
        } = this.props;
        const {
            fullName,
            homeAddress,
            entityIdKey,
            inviteAttempted,
            isSendInvitation,
            relationshipType,
            relationship,
            relationshipAlias,
            useAddressDescription,
        } = this.state;

        const isDeceased = this.isDeceased();

        return (
            <InvitationFormRightSection
                activeCase={activeCase}
                formType={formType}
                setInvitationFormDirty={setInvitationFormDirty}
                zIndex={zIndex}
                relationToDeceased={relationToDeceased}
                fullName={fullName}
                homeAddress={homeAddress}
                entityIdKey={entityIdKey}
                inviteAttempted={inviteAttempted}
                isSendInvitation={isSendInvitation}
                relationshipType={relationshipType}
                relationship={relationship}
                relationshipAlias={relationshipAlias}
                useAddressDescription={useAddressDescription}
                isDeceased={isDeceased}
                context={context}
                handleSetRelationship={this.handleSetRelationship}
                setHomeAddressAndDescription={(address: LongAddress, useDescription: boolean) =>
                    this.setState({ homeAddress: address, useAddressDescription: useDescription })}
            />
        );
    };

    renderInvitationFormForDCEntity = () => {
        const { classes } = this.props;

        return (
            <>
                <Grid item xs={12} className={classes.invitationFormContainer}>
                    {this.renderLeftSectionForDC()}
                    <div className={classes.divider}>
                        <div className={classes.inner} />
                    </div>
                    {this.renderRightSectionForDC()}
                </Grid>
                {this.renderFooter()}
            </>
        );
    };

    renderLeftSectionForDC = () => {
        const { setInvitationFormDirty, showStep2, activeCase } = this.props;

        const {
            fullName,
            email,
            inviteAttempted,
            inviteMethod,
            phone
        } = this.state;

        const dontSendInvite = this.isDontSendInvite();
        const isEmailValid = this.isEmailValid(dontSendInvite);
        const isPhoneValid = this.isPhoneValid(dontSendInvite);

        return (
            <InvitationFormLeftSectionForDC
                activeCase={activeCase}
                setInvitationFormDirty={setInvitationFormDirty}
                showStep2={showStep2}
                fullName={fullName}
                email={email}
                inviteAttempted={inviteAttempted}
                inviteMethod={inviteMethod}
                phone={phone}
                dontSendInvite={dontSendInvite}
                isPhoneValid={isPhoneValid}
                isEmailValid={isEmailValid}
                renderInvitationGroupButtons={this.renderInvitationGroupButtons}
                showPhoneTextField={this.showPhoneTextField()}
                showEMailTextField={this.showEMailTextField()}
                handleOnChangePhone={this.handleOnChangePhone}
                handleOnChangeEmail={this.handleOnChangeEmail}
            />
        );
    };

    renderRightSectionForDC = () => {
        const { activeCase } = this.props;
        const { fullName } = this.state;

        return (
            <InvitationFormRightSectionForDC
                activeCase={activeCase}
                fullName={fullName}
            />
        );
    };

    renderFooter = () => {
        const {
            formType,
            switchView,
            handleMouseLeaveOnInfoPopper,
            openInfoPopper,
            canInviteFamilyAdmin,
            relationToDeceased,
            showStep2,
            activeCase,
            context,
        } = this.props;
        const { fullName, sendInvitation, isNonUserSaving, inviteMethod, phone, email } = this.state;

        const dontSendInvite = this.isDontSendInvite();
        const invitedUser = this.findInvitedHelperWithEmailOrPhone();
        const isPending = this.isPending();
        const isLiving = this.isLiving();
        const isDeceased = this.isDeceased();

        return (
            <InvitationFormFooter
                formType={formType}
                switchView={switchView}
                handleMouseLeaveOnInfoPopper={handleMouseLeaveOnInfoPopper}
                openInfoPopper={openInfoPopper}
                canInviteFamilyAdmin={canInviteFamilyAdmin}
                relationToDeceased={relationToDeceased}
                showStep2={showStep2}
                fullName={fullName}
                sendInvitation={sendInvitation}
                isNonUserSaving={isNonUserSaving}
                dontSendInvite={dontSendInvite}
                invitedUser={invitedUser}
                isPending={isPending}
                isLiving={isLiving}
                isDeceased={isDeceased}
                context={context}
                handleDCSubmit={this.handleDCSubmit}
                handleSubmit={this.handleSubmit}
                handleDontInviteButtonClick={this.handleDontInviteButtonClick}
                isSuccess={this.isSuccess()}
                handleSendInvitation={checked => this.setState({ sendInvitation: checked })}
                inviteMethod={inviteMethod}
                phone={phone}
                email={email}
                activeCase={activeCase}
            />
        );
    };

    renderSuccessScreen = (newHelper: EntitySummary) => {
        const {
            editHelper,
            entityRole,
            formType,
            zIndex,
            activeCase,
            closeParentDialog,
            relationToDeceased
        } = this.props;

        return (
            <SuccessScreen
                editHelper={editHelper}
                entityRole={entityRole}
                formType={formType}
                zIndex={zIndex}
                activeCase={activeCase}
                closeParentDialog={closeParentDialog}
                relationToDeceased={relationToDeceased}
                newHelper={newHelper}
                resetForm={this.resetForm}
            />
        );
    };

    openSimilarUsersDialog = () => {
        this.setState({ isSimilarUsersDialogOpen: true });
    };

    handleSelectSimilarUser = async (user: UserProfile) => {
        const {
            onInvite,
            entityRole,
            toggleSuccessScreen,
        } = this.props;
        const {
            relationshipType,
            relationship,
            relationshipAlias,
            useAddressDescription: useDescription
        } = this.state;

        this.setState({
            fullName: joinNameParts(user),
            email: user.email || '',
            phone: getFormattedPhoneNumber(user.phone) || '',
        });

        const dontSendInvite = this.isDontSendInvite();
        const isDeceased = this.isDeceased();

        if (entityRole === EntityCaseRole.visitor) {
            log.warn('Cannot invite helper with visitor role', { entityRole });
            return;
        }

        const request: CaseHelperCreateRequest = {
            fname: user.fname,
            mname: user.mname || null,
            lname: user.lname || '',
            email: user.email || null,
            phone: user.phone || null,
            work_phone: null,
            home_phone: null,
            entity_id: user.entity_id,
            relationship_type: relationshipType || null,
            relationship: relationship,
            relationship_alias: relationshipAlias || null,
            case_role: entityRole,
            is_deceased: false,
            home_address: null,
            send_invite: entityRole === EntityCaseRole.admin || (!dontSendInvite && !isDeceased),
            use_address_description: useDescription,
            state_license_number: null,
            case_entity_subscription_updates: [],
            case_entity_subscription_creates: [],
        };
        const response = await onInvite(request);
        if (!response) {
            return;
        }
        if (response.status === UserInviteStatusEnum.success && response.helpers) {
            const newlyAddedHelper = response.helpers.find((h) => h.entity_id === response.addedEntityId);
            this.setState(
                {
                    ...DEFAULT_STATE,
                    newHelper: newlyAddedHelper || null,
                },
                () => {
                    if (toggleSuccessScreen) {
                        toggleSuccessScreen();
                    }
                });
        }
    };

    findInvitedHelperWithEmailOrPhone = () => {
        const { helpers } = this.props;
        const { phone, email, inviteMethod } = this.state;

        const invitedHelper = helpers.find((helper) => {
            if (!helper.user) {
                return false;
            }
            if (inviteMethod === InviteMethod.phone) {
                return arePhoneNumbersEqual(helper.phone, phone);
            } else if (inviteMethod === InviteMethod.email) {
                return helper.email === email;
            } else {
                return helper.email === email || arePhoneNumbersEqual(helper.phone, phone);
            }
        });
        return invitedHelper;
    };

    handleSubmit = async () => {
        const {
            fullName,
            email,
            phone,
            inviteMethod,
            homeAddress,
            relationship,
            relationshipAlias,
            relationshipType,
            useAddressDescription: useDescription
        } = this.state;
        const {
            onInvite,
            userSession,
            funeralHome,
            dispatch,
            activeCase,
            resetInvitationStatus,
            toggleSuccessScreen,
            entityRole,
            selectedEntityId,
            relationToDeceased,
            zIndex,
            showStep2,
        } = this.props;
        const dontSendInvite = this.isDontSendInvite();
        const isDeceased = this.isDeceased();
        const isFHUser: boolean = UserRoles.isFHUser(userSession.userData);
        const isFamilyUser: boolean = UserRoles.isFamilyOnCase(userSession.userData, activeCase.id);
        const isEmailRequired = (inviteMethod === InviteMethod.email
            || inviteMethod === InviteMethod.both) && !dontSendInvite && !isDeceased;
        const isPhoneRequired = (inviteMethod === InviteMethod.phone
            || inviteMethod === InviteMethod.both) && !dontSendInvite && !isDeceased;

        if (fullName.length === 0
            || isPhoneRequired && !this.isPhoneValid(dontSendInvite)
            || isEmailRequired && !this.isEmailValid(dontSendInvite)
            || (phone && !this.isPhoneValid())
            || (email && !this.isEmailValid())
            || (!dontSendInvite && this.findInvitedHelperWithEmailOrPhone() !== undefined)
            || (relationshipType && relationshipType === EntityRelationshipType.other
                && relationshipAlias.trim().length === 0)
        ) {
            this.setState({ inviteAttempted: true });
            return;
        }

        // RESTRICT ACCESS
        if ((isFHUser && funeralHome
            && funeralHome.is_demo && !funeralHome.features.DEMO_ALLOW_FAMILY_INVITES.enabled) ||
            (isFamilyUser && activeCase.funeral_home.is_demo
                && !activeCase.funeral_home.features.DEMO_ALLOW_FAMILY_INVITES.enabled)) {
            dispatch(openAccessRestrictedDialog(zIndex + 1));
            return;
        }

        const { fname, mname, lname } = splitFullName(fullName);

        if (entityRole === EntityCaseRole.visitor) {
            log.warn('Cannot invite helper with visitor role', { entityRole });
            return;
        }

        const request: CaseHelperCreateRequest = {
            fname,
            mname,
            lname,
            email: email || null,
            phone: phone || null,
            home_phone: null,
            work_phone: null,
            relationship_type: relationshipType || null, // EntityRelationshipType
            relationship: relationship, // EntityRelationship
            relationship_alias: relationshipAlias,
            case_role: entityRole,
            entity_id: selectedEntityId || null,
            is_deceased: isDeceased,
            home_address: homeAddress,
            send_invite: entityRole === EntityCaseRole.admin || (!dontSendInvite && !isDeceased),
            use_address_description: useDescription,
            state_license_number: null,
            case_entity_subscription_updates: [],
            case_entity_subscription_creates: [],
        };
        const response = await onInvite(request);
        if (!response) {
            return;
        }
        if (response.status === UserInviteStatusEnum.success && response.helpers && response.addedEntityId) {
            const newlyAddedHelper = response.helpers.find((h) => h.entity_id === response.addedEntityId);

            this.setState(
                {
                    ...DEFAULT_STATE,
                    newHelper: newlyAddedHelper || null,
                    isSendInvitation: true
                },
                () => {
                    if (toggleSuccessScreen && (!relationToDeceased || showStep2)) {
                        toggleSuccessScreen();
                    }
                });
            resetInvitationStatus();
        } else if (response.similarUsers &&
            (response.status === UserInviteStatusEnum.multiple_users_found
                || UserInviteStatusEnum.different_name_found)) {
            this.openSimilarUsersDialog();
        } else {
            resetInvitationStatus();
        }
    };

    handleDCSubmit = async () => {
        const { closeParentDialog, toggleStep2, showStep2, relationToDeceased } = this.props;
        const { relationshipType, relationship, relationshipAlias } = this.state;
        const { fullName } = this.state;
        const isDeceased = this.isDeceased();

        if (fullName.trim().length === 0
            || (!this.isLiving() && !this.isDeceased())
            || !this.isAddressValid(relationToDeceased !== DCEntityEnum.Informant)
            || relationshipType === EntityRelationshipType.family && !relationship
            || (relationshipType && relationshipType === EntityRelationshipType.other
                && relationshipAlias.trim().length === 0)
        ) {
            this.setState({ inviteAttempted: true });
            return;
        }

        if (showStep2) {
            this.handleSubmit();
            return;
        }

        if (isDeceased) {
            await this.handleSubmit();
            closeParentDialog();
            return;
        }

        toggleStep2();
    };

    handleDontInviteButtonClick = () => {
        const { closeParentDialog } = this.props;
        this.setState(
            { sendInvitation: false },
            async () => {
                await this.handleSubmit();
                closeParentDialog();
            });
    };

    resetForm = ({ isFocused }: { isFocused: boolean }) => {
        const { showStep2, context, resetInvitationStatus, toggleSuccessScreen, toggleStep2 } = this.props;

        resetInvitationStatus();

        if (toggleSuccessScreen) {
            toggleSuccessScreen();
        }

        this.setState({
            ...DEFAULT_STATE,
            isFocused,
            entityIdKey: this.genRandomValue(),
        });

        if (showStep2 && (context === HelperInvitationDialogContext.addPreceededInDeath
            || context === HelperInvitationDialogContext.addSurvivors)) {
            toggleStep2();
        }
    };

    render() {
        const {
            similarUsers,
            showSuccessScreen,
            classes,
            zIndex,
            showStep2
        } = this.props;

        const { isSimilarUsersDialogOpen, newHelper } = this.state;

        return (
            <Grid item xs={12} className={classes.mainContainer}>
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.innerContainer,
                        showSuccessScreen && classes.active
                    )}
                >
                    {newHelper && this.renderSuccessScreen(newHelper)}
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.innerContainer,
                        !showSuccessScreen && showStep2 && classes.active
                    )}
                >
                    {this.renderInvitationFormForDCEntity()}
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.innerContainer,
                        !showSuccessScreen && !showStep2 && classes.active
                    )}
                >
                    {this.renderInvitationForm()}
                </Grid>

                <SimilarUsersDialog
                    invitedRole={UserRole.User}
                    users={similarUsers}
                    isDialogOpen={isSimilarUsersDialogOpen}
                    closeDialog={() => this.setState({ isSimilarUsersDialogOpen: false })}
                    onSelectUser={this.handleSelectSimilarUser}
                    zIndex={zIndex + 1}
                />
            </Grid>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(InvitationForm));