import { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

import ClearIcon from '@mui/icons-material/Clear';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import { formatPrice, toPennyValue, formatDinero } from '../../../../shared/goods_and_services/pricing';
import { PaymentMethod, StripeChargeDetails, Transaction } from '../../../../shared/types';
import { refundPayment, RefundReason } from '../../../../actions/Finance.action';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 0,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        margin: 0,
        textAlign: 'center',
        overflow: 'hidden',
        marginBottom: 16,
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& $inner': {
            margin: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            '& p': {
                fontWeight: 200,
                fontSize: 14,
                textTransform: 'capitalize',
            }
        }
    },
    dialogPaper: {
        margin: 'auto',
        width: '100%',
        maxWidth: 'unset',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        '@media (min-width: 600px)': {
            borderRadius: 4,
            width: 360,
            maxWidth: 360,
            height: 'auto',
            maxHeight: 'calc(100% - 96px)',
            display: 'flex'
        },
    },
    textField: {
        width: 200,
        margin: '8px 0',
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    },
    headerContent: {
        textAlign: 'center',
        margin: '8px 0',
        color: theme.palette.common.white,
        '& p': {
            color: 'inherit',
            fontSize: 24,
        },
        '& svg': {
            color: 'inherit',
            fontSize: 88,
        }
    },
    button: {
        margin: '24px 0 8px 0',
    },
    footer: {
        margin: '0 12px',
        fontSize: 12,
        fontWeight: 200,
        '& a': {
            color: 'inherit'
        }
    },
    textArea: {
        margin: '8px 0',
        width: 280,
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    total: {
        width: 120,
        borderTop: '1px solid',
        margin: '8px 0',
        padding: '8px 20px',
        textAlign: 'left',
    },
    selectBox: {
        display: 'flex',
        width: 280,
        margin: '8px auto',
        '& label': {},
    },
    selectMenu: {
        textAlign: 'left',
        padding: '12px 32px 12px 14px',
        '&:focus': {
            backgroundColor: 'transparent'
        }
    },
    selectLabel: {
        transform: 'translate(14px, -6px) scale(0.75)',
        background: 'white',
        padding: '0px 4px',
    },
    textAreaRoot: {},
    inner: {}
});

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper'
    | 'dRoot' | 'header' | 'dialogContent' | 'clearIcon' | 'textField' | 'inner' | 'footer'
    | 'headerContent' | 'AttachMoneyIcon' | 'button' | 'textArea' | 'textAreaRoot' | 'total' | 'selectBox'
    | 'selectMenu' | 'selectLabel'>;

interface Props {
    payment: Transaction | null;
    chargeDetails: StripeChargeDetails;
    processRefund: (...params: Parameters<typeof refundPayment>) => void;
    closeDialog: () => void;
    zIndex: number;
}

interface State {
    amount: string;
    memo: string;
    isAmountValid: boolean;
    reason: RefundReason;
}

const INITIAL_STATE = {
    amount: '',
    isAmountValid: true,
    reason: 'requested_by_customer' as RefundReason,
    memo: '',
};

class RefundScreenDialog extends Component<StyledProps, State> {

    constructor(props: StyledProps) {
        super(props);
        this.state = INITIAL_STATE;
    }

    render() {
        const { classes, payment, chargeDetails, zIndex } = this.props;
        const { amount, memo, isAmountValid, reason } = this.state;
        const isProcessing = false; // TODO - set this based on props
        const { charge } = chargeDetails;
        const refundAmount = toPennyValue(amount);
        const amountRemaining = charge ? charge.amount - charge.amount_refunded : 0;
        const isMaxLimitReached = refundAmount.getAmount() > amountRemaining;
        const isBankPayment = payment && payment.method === PaymentMethod.plaid;
        const accountOwnerLabel = isBankPayment ? 'Account Owner' : 'Card Holder';
        const accountNumberLabel = isBankPayment ? 'Account Number' : 'Card Number';
        const accountTypeLabel = isBankPayment ? 'Bank' : 'Card Type';

        return (
            <Dialog
                open={payment !== null}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.dRoot,
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.header,
                        classes.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon, classes.colorWhite)}
                        onClick={() => this.closeDialog()}
                    />
                    <div className={classes.headerContent}>
                        <MoneyOffIcon />
                        <Typography>Refund Transaction</Typography>
                    </div>
                </DialogTitle>
                {(chargeDetails.loading || !charge) &&
                    <DialogContent className={classes.dialogContent}>
                        <CircularProgress size={100} />
                    </DialogContent>
                }
                {!chargeDetails.loading && charge &&
                    <DialogContent className={classes.dialogContent}>
                        <Grid item xs={12} className={classes.inner}>
                            <Typography color="secondary">
                                {accountOwnerLabel}:&nbsp;
                                <span className={classes.fontWeight500}>
                                    {charge.name || (payment ? payment.payer_name : 'Unknown')}
                                </span>
                            </Typography>

                            <Typography color="secondary">
                                {accountNumberLabel}:&nbsp;
                                <span className={classes.fontWeight500}>
                                    ****{charge.last4}
                                </span>
                            </Typography>

                            <Typography color="secondary">
                                {accountTypeLabel}:&nbsp;
                                <span className={classes.fontWeight500}>
                                    {charge.brand}
                                </span>
                            </Typography>

                            <Typography color="secondary">
                                Transaction Amount:&nbsp;
                                <span className={classes.fontWeight500}>
                                    {formatPrice(amountRemaining, 'USD')}
                                </span>
                            </Typography>

                            <Typography color="secondary">
                                Transaction Date:&nbsp;
                                <span
                                    className={classNames(
                                        classes.fontWeight500,
                                        classes.textUppercase
                                    )}
                                >
                                    {moment(charge.created).format('dddd, D MMM YYYY')}
                                </span>
                            </Typography>
                        </Grid>

                        <TextField
                            required
                            label="Amount to refund"
                            variant="outlined"
                            type="number"
                            error={!isAmountValid}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                classes: {
                                    notchedOutline: 'notranslate',
                                }
                            }}
                            className={classes.textField}
                            value={amount}
                            onChange={(e) => this.onUpdateAmount(e.target.value)}
                        />

                        <FormControl variant="outlined" className={classes.selectBox} required>
                            <InputLabel htmlFor="reasonSelect" classes={{ root: classes.selectLabel }}>
                                Reason for refund
                            </InputLabel>
                            <Select
                                value={reason}
                                onChange={(e) => this.onUpdateReason(e.target.value as RefundReason)}
                                MenuProps={{ style: { zIndex: zIndex + 1 } }}
                                input={<OutlinedInput name="reasonSelect" id="reasonSelect" />}
                                classes={{ select: classes.selectMenu }}
                            >
                                <MenuItem value="duplicate">Duplicate Charge</MenuItem>
                                <MenuItem value="requested_by_customer">Requested by Customer</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Memo (optional)"
                            multiline
                            margin="dense"
                            variant="outlined"
                            value={memo || ''}
                            className={classes.textArea}
                            InputProps={{
                                classes: {
                                    root: classes.textAreaRoot,
                                    notchedOutline: 'notranslate'
                                }
                            }}
                            onChange={(e) => this.setState({
                                memo: e.target.value
                            })}
                        />

                        <Button
                            disabled={refundAmount.isZero() || !isAmountValid || isMaxLimitReached}
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={e => this.processRefund(isMaxLimitReached)}
                        >
                            <MoneyOffIcon />
                            process {refundAmount.getAmount() !== 0 && <>{formatDinero(refundAmount)}&nbsp;</>}
                            refund
                            {isProcessing && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>

                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.footer,
                                isMaxLimitReached && classes.colorRed
                            )}
                        >
                            {isMaxLimitReached && 'Refund cannot be greater than original charge.'
                                || 'Refund may take up to 10 days'}
                        </Typography>
                    </DialogContent>
                }
            </Dialog>
        );
    }

    closeDialog = () => {
        this.props.closeDialog();
        this.setState(INITIAL_STATE);
    };

    onUpdateAmount = (amount: string) => {
        const refundAmount = toPennyValue(amount);
        const isAmountValid = !refundAmount.isZero() && refundAmount.isPositive();
        this.setState({ amount, isAmountValid });
        return isAmountValid;
    };

    onUpdateReason = (reason: RefundReason) => {
        this.setState({ reason });
    };

    processRefund = (isMaxLimitReached: boolean) => {
        const { payment, processRefund } = this.props;
        const { amount, memo, reason, isAmountValid } = this.state;

        const refundAmount = toPennyValue(amount);

        if (!isAmountValid || isMaxLimitReached) {
            return;
        }

        if (payment && payment.payment_id) {
            processRefund(
                payment.payment_id,
                refundAmount.getAmount(),
                reason as RefundReason,
                memo || ''
            );
            this.closeDialog();
        }
    };
}

export default withGStyles(styles)(RefundScreenDialog);
