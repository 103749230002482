import Typography from '@mui/material/Typography';

import { AssignStyleProps, styleWrapper } from '../Style';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';

export enum ColorType {
    'primary' = 'primary',
    'secondary' = 'secondary',
    'default' = 'default',
}

export interface LabelType {
    title: string;
    color?: ColorType;
}

interface Props {
    uniqueKey: string | number;
    label: {
        primary: LabelType;
        secondary?: LabelType[];
    };
    disabled?: boolean;
    isChecked?: boolean;
    onCheckBoxChange?: (checked: boolean) => void;
    openSettingsDialog?: () => void;
    tooltip?: string;
    showTooltipOnCheckbox?: boolean;
    avatar: JSX.Element | null;
}

const CheckBoxItem = (props: Props & AssignStyleProps) => {
    const { classes, label, uniqueKey, isChecked, onCheckBoxChange, disabled, tooltip, showTooltipOnCheckbox, avatar } =
        props;

    return (
        <div className={classes.checkboxItem} >
            <InputLabel className={classes.itemLabel} htmlFor={`checkbox-item-${uniqueKey}`}>
                {avatar && (
                    <Tooltip title={(!showTooltipOnCheckbox && tooltip) || ''} placement="top">
                        <div>{avatar}</div>
                    </Tooltip>
                )}

                <div className={classes.labelContainer}>
                    <Typography color={ColorType[label.primary.color || ''] || 'default'} className={classes.primary}>
                        {label.primary.title}
                    </Typography>

                    {label.secondary &&
                        label.secondary.map((s, i) => (
                            <Typography
                                color={ColorType[s.color || ''] || 'default'}
                                className={classes.secondary}
                                key={s.title.toString()}
                            >
                                {s.title}
                            </Typography>
                        ))}
                </div>
            </InputLabel>
            <Tooltip title={(showTooltipOnCheckbox && tooltip) || ''} placement="top">
                <div>
                    <Checkbox
                        id={`checkbox-item-${uniqueKey}`}
                        color="primary"
                        checked={isChecked}
                        disabled={disabled}
                        onChange={(e, checked) => {
                            e.stopPropagation();
                            if (onCheckBoxChange) {
                                onCheckBoxChange(checked);
                            }
                        }}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default withStyles(styleWrapper<Props>())(CheckBoxItem);
