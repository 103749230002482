import { getDataURIFromFile } from '../../services';
import { uploadCasePhotos } from '../../actions/Photo.action';
import { GatherCasePublic, GatherCaseUX } from '../../shared/types';
import { GatherPhoto } from '../../types';
import { AppDispatch } from '../../store';

type UploadCasePhotosType =
    (dataURIs: string[], gatherCase: GatherCaseUX | GatherCasePublic) => Promise<GatherPhoto[] | null>;

export const createUploadFromDispatch = (dispatch: AppDispatch): UploadCasePhotosType => (
    (dataURIs: string[], gatherCase: GatherCaseUX | GatherCasePublic) => (
        dispatch(uploadCasePhotos(dataURIs, gatherCase))
    )
);

export const performCasePhotoUpload = async (
    files: FileList | null,
    selectedCase: GatherCaseUX | GatherCasePublic,
    uploadCasePhotosFunc: UploadCasePhotosType,
) => {
    if (files && files.length !== 0) {
        for (let i = 0; i < files.length; i++) {
            const dataURI = await getDataURIFromFile(files[i]);
            await uploadCasePhotosFunc([dataURI], selectedCase);
        }
    }
};
