import {
    joinNameParts,
    emptyStringParser,
    eliminateWhiteSpaces,
    getAddressFromLongAddress,
    formatDateString,
    getFormattedPhoneNumber,
    DateTimeFormat
} from "../../../../../services";
import { ConfigurableMarriageFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { EntitySummary, GatherCaseUX, getCaseEntityById } from "../../../../../shared/types";
import { OverviewSectionCommonProps, parseShortAddress } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
    activeCase: GatherCaseUX;
    helpers: EntitySummary[];
}
const MarriageOverview = (props: Props) => {
    const { deathCertificate, activeCase, helpers, divider, printMode, showHeading, alwaysVisible } = props;
    const { dc_spouse } = activeCase;

    const spouseEntity: EntitySummary | null = dc_spouse &&
        helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_spouse))) || null;

    const status: string | null = deathCertificate.marriage.maritalStatus || null;
    const spouse: string | null = spouseEntity && joinNameParts(spouseEntity) || null;
    const spouseMaidenName = emptyStringParser(
        eliminateWhiteSpaces(deathCertificate.marriage.spouseMaidenName || null)
    );
    const spousePhone = (spouseEntity && spouseEntity.phone) || null;
    const spouseAddress = (spouseEntity && getAddressFromLongAddress(spouseEntity.home_address)) || null;
    let marriagePlace: string | null = !deathCertificate.marriage.marriagePlace.unknown
        && parseShortAddress(deathCertificate.marriage.marriagePlace) || null;
    if (deathCertificate.marriage.marriagePlace.unknown) {
        marriagePlace = 'unknown';
    }

    const dom = formatDateString(deathCertificate.marriage.marriageDate || '', DateTimeFormat.LongDate);
    const spouseDateOfDeath = formatDateString(
        deathCertificate.marriage.spousesDateOfPassing || '',
        DateTimeFormat.LongDate
    );

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 12 : 0
                    }}
                >
                    MARRIAGE
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Status"
                    html={status}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMarriageFieldKey.maritalStatus}
                />

                <OverviewLabel
                    label="Spouse"
                    html={emptyStringParser(eliminateWhiteSpaces(spouse))}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Spouse Maiden Name"
                    html={spouseMaidenName}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMarriageFieldKey.spouseMaidenName}
                />

                <OverviewLabel
                    label="Spouse Phone Number"
                    html={getFormattedPhoneNumber(spousePhone)}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Spouse Address"
                    html={spouseAddress}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="City of Marriage"
                    html={marriagePlace}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMarriageFieldKey.marriagePlace}
                />

                <OverviewLabel
                    label="Date of Marriage"
                    html={dom}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMarriageFieldKey.dateOfMarriage}
                />

                <OverviewLabel
                    label="Spouse Date of Passing"
                    html={spouseDateOfDeath}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableMarriageFieldKey.spousesDateOfDeath}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default MarriageOverview;