import moment from 'moment-timezone';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import RoofingIcon from '@mui/icons-material/Roofing';
import { WithStyles } from '@mui/styles';

import { RolodexCaseResult } from '../../../shared/types';
import { RowClickHandlerFunc } from './utils';
import Divider from '@mui/material/Divider';

const DATE_FORMAT = 'DD MMM YYYY';

type StyledProps = WithStyles<
    'firstItem'
    | 'heading'
    | 'subHeading'
    | 'profession'
    | 'location'
    | 'tabContainerDivider'
    | 'avatar'
    | 'nameInitialsAvatar'
>;

type Props = {
    gatherCase: RolodexCaseResult;
    rowClickHandler: RowClickHandlerFunc;
} & StyledProps;

const CaseRow = (props: Props) => {
    const { gatherCase, rowClickHandler, classes } = props;

    return (
        <>
            <Grid item xs={12} className={classes.firstItem} onClick={() => rowClickHandler(gatherCase)}>
                <Typography color="primary" className={classes.heading}>
                    <ViewCarouselIcon color="primary" />&nbsp;{gatherCase.displayName}
                </Typography>
                <Grid item xs={12} className={classes.subHeading}>
                    {gatherCase.createdTime !== null &&
                        <Typography color="secondary" className={classes.profession}>
                            {`Case (Created ${moment(gatherCase.createdTime).format(DATE_FORMAT).toUpperCase()})`}
                        </Typography>
                    }
                    <Typography color="secondary" className={classes.location}>
                        <RoofingIcon color="secondary" />&nbsp;{gatherCase.funeralHomeName}
                    </Typography>
                    <Typography color="secondary" className={classes.location}>
                        <SupervisedUserCircleIcon color="secondary" />&nbsp;{`${gatherCase.helperCount || 0} Helpers`}
                    </Typography>
                </Grid>
            </Grid>
            <Divider color="secondary" className={classes.tabContainerDivider} />
        </>
    );
};

export default CaseRow;
