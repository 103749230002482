import {
    DeathCertificateMilitaryType,
    UserProfile,
    DeathCertificateConfigUX,
    GatherCaseUX,
    CaseType,
} from '../../../shared/types';
import { isDateRangeValid } from '../../utils';
import { isFieldOptional, ConfigurableMilitaryFieldKey } from './config';

import moment from 'moment-timezone';

export const militaryValidators = {
    military: function(militaryForm: DeathCertificateMilitaryType) {
        const {
            military,
        } = militaryForm;

        return military === 'Yes' || military === 'No' || military === 'Unknown';
    },
    militaryBranch: function(
        militaryForm: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ) {
        const { militaryBranch } = militaryForm;

        const isValid = militaryBranch && militaryBranch instanceof Array && militaryBranch.length > 0;

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.militaryBranch, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    militaryRank: function(
        { militaryRank }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = militaryRank && militaryRank.length > 0;

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.militaryRank, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    militaryYears: function (
        { militaryYears }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = militaryYears && !Number.isNaN(militaryYears);
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.yearsServed, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    militaryServiceNumber: function(
        { militaryServiceNumber }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = militaryServiceNumber && militaryServiceNumber.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.militaryServiceNumber, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    militaryHonors: function(
        { militaryHonors }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = militaryHonors && militaryHonors.length > 0;
        const isValidListOfStrings = militaryHonors &&
            militaryHonors.filter(h => typeof h !== 'string').length === 0;

        if (isValid && isValidListOfStrings) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.militaryHonors, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    disabilityContributed: function (
        { disabilityContributedToDeath }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = disabilityContributedToDeath !== undefined && typeof disabilityContributedToDeath === 'boolean';
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.serviceConnectedDisability, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },

    fromServiceDate: function (
        { serviceDateFrom, serviceDateTo }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isStartTimeValid = isDateRangeValid(moment(serviceDateFrom), moment(serviceDateTo));
        const isValid = serviceDateFrom && serviceDateFrom.length > 0;
        if (isValid && isStartTimeValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.fromServiceDate, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    toServiceDate: function (
        { serviceDateTo, serviceDateFrom }: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isTimeValid = isDateRangeValid(moment(serviceDateFrom), moment(serviceDateTo));
        const isValid = serviceDateTo && serviceDateTo.length > 0;

        if (isValid && isTimeValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableMilitaryFieldKey.toServiceDate, config, user)) {
            return !touchCheck;
        }

        return false;
    }
};

export const militaryMasterValidators = {
    valid: function(
        militaryForm: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ) {
        if (militaryForm.military && militaryForm.military !== 'Yes') {
            return true;
        }

        return militaryValidators.military(militaryForm) &&
            militaryValidators.militaryBranch(militaryForm, config, user) &&
            militaryValidators.militaryRank(militaryForm, config, user) &&
            militaryValidators.militaryYears(militaryForm, config, user) &&
            militaryValidators.militaryServiceNumber(militaryForm, config, user) &&
            militaryValidators.militaryHonors(militaryForm, config, user) &&
            militaryValidators.disabilityContributed(militaryForm, config, gatherCase, user) &&
            militaryValidators.fromServiceDate(militaryForm, config, user) &&
            militaryValidators.toServiceDate(militaryForm, config, user);
    },
    touched: function(
        militaryForm: DeathCertificateMilitaryType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return militaryValidators.military(militaryForm) ||
            militaryValidators.militaryBranch(militaryForm, config, user, true) ||
            militaryValidators.militaryHonors(militaryForm, config, user, true) ||
            militaryValidators.militaryServiceNumber(militaryForm, config, user, true) ||
            militaryValidators.militaryYears(militaryForm, config, user, true) ||
            militaryValidators.militaryRank(militaryForm, config, user, true) ||
            militaryValidators.disabilityContributed(militaryForm, config, gatherCase, user, true) ||
            militaryValidators.fromServiceDate(militaryForm, config, user, true) ||
            militaryValidators.toServiceDate(militaryForm, config, user, true);
    },
};
