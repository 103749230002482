import { CropperRef, Cropper } from 'react-mobile-cropper';
import 'react-mobile-cropper/dist/style.css';
import {
    getPhotoUrlWithoutTransformations,
    getTransformationString
} from '../../services';
import { GatherPhoto, useGDispatch } from '../../types';
import makeGStyles from '../../styles/makeGStyles';
import { Dialog, DialogTitle, Grid, lighten } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    CloudinaryTransformationsType,
    GatherCasePublic, GatherCaseUX,
    isAlbumEntry, PhotoTransformationsType
} from '../../shared/types';
import GButton from '../common/GButton';
import { Clear } from '@mui/icons-material';
import { modifyCasePhoto } from '../../actions/Photo.action';
import { updateAlbumEntry } from '../../actions/Album.action';
import classNames from 'classnames';

const useStyles = makeGStyles((theme) => ({
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        },
    },
    dialogFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 1,
        padding: 14,
        '@media (min-width: 350px)': {
            minWidth: 300,
        },
    },
    revertButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    saveButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    // hide the rotation slider for now...
    hideRotationSlider: {
        '& .rmc-navigation': {
            justifyContent: 'center',
        },
        '& .rmc-navigation__rotator': {
            display: 'none',
        },
    }
}));

interface Props {
    zIndex: number;
    activeCase: GatherCaseUX | GatherCasePublic;
    albumId?: number;
    isOpen: boolean;
    activePhoto: GatherPhoto;
    onClose: () => void;
}

const MobileCropper = (props: Props) => {
    const classes = useStyles();
    const dispatch = useGDispatch();
    const { zIndex, activeCase, albumId, activePhoto, isOpen, onClose } = props;
    const [crop, setCrop] = useState<CloudinaryTransformationsType | null>(null);

    const imageUrl = getPhotoUrlWithoutTransformations(activePhoto.photo ? activePhoto.photo.public_id : '');

    const cropperRef = useRef<CropperRef>(null);

    const handleRevertCrop = useCallback(() => {
        setCrop(null);
        if (cropperRef.current) {
            cropperRef.current.reset();
        }
    }, []);

    useEffect(() => {
        if (!isOpen) {
            handleRevertCrop();
        }
    }, [isOpen, handleRevertCrop]);

    const handleCropEnd = (cropper: CropperRef) => {
        const cropState = cropper.getState();
        if (!cropState || !activePhoto.photo) {
            return;
        }

        const cropX = cropState.coordinates ? Math.round(cropState.coordinates.left) : 0;
        const cropY = cropState.coordinates ? Math.round(cropState.coordinates.top) : 0;
        const cropHeight = cropState.coordinates ? Math.round(cropState.coordinates.height) : 0;
        const cropWidth = cropState.coordinates ? Math.round(cropState.coordinates.width) : 0;
        const rotation = cropState.transforms.rotate % 360;
        const finalRotation = getTransformationString({
            angle: rotation,
            vertical: cropState.transforms.flip.vertical,
            horizontal: cropState.transforms.flip.horizontal
        });

        const finalCrop: CloudinaryTransformationsType = {
            crop: 'crop',
            width: cropWidth,
            height: cropHeight,
            x: cropX,
            y: cropY,
            angle: finalRotation,
        };
        setCrop(finalCrop);
    };

    // store transformations
    const handleSetTransformations = (
        final: CloudinaryTransformationsType | null,
    ) => {
        let appliedTransformations: PhotoTransformationsType = {};
        if (!final || !activePhoto) {
            appliedTransformations = {};
        } else {
            appliedTransformations = { cloudinary: final };
        };
        if (isAlbumEntry(activePhoto.photo)) {
            if (albumId) {
                dispatch(
                    updateAlbumEntry(activeCase.uuid, albumId, activePhoto.photo.id, {
                        transformations: appliedTransformations,
                    }),
                );
            } else {
                dispatch(
                    modifyCasePhoto(activeCase.uuid, activePhoto, {
                        transformations: appliedTransformations,
                    }),
                );
            }
        }
        onClose();
    };

    const renderFooter = () => {
        return (
            <Grid className={classes.dialogFooter}>
                <GButton className={classes.revertButton} disabled={!crop} onClick={() => handleRevertCrop()}>
                    Revert
                </GButton>
                <GButton
                    disabled={!crop}
                    className={classes.saveButton}
                    onClick={() => {
                        // save transformed image
                        handleSetTransformations(crop);
                    }}
                >
                    Save
                </GButton>
            </Grid>
        );
    };

    return (
        <Dialog fullScreen={false} open={isOpen} onClose={onClose} transitionDuration={300} style={{ zIndex }}>
            <Clear color="secondary" className={classes.clearIcon} onClick={() => onClose()} />
            <DialogTitle color="secondary">Edit Photo</DialogTitle>
            <Cropper
                className={classNames('cropper', classes.hideRotationSlider)}
                ref={cropperRef}
                src={imageUrl}
                onInteractionEnd={handleCropEnd}
                onTransformImageEnd={handleCropEnd}
                stencilProps={{
                    grid: true,
                }}
            />
            {renderFooter()}
        </Dialog>
    );
};

export default MobileCropper;
