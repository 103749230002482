import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import UserAvatar from '../../../common/UserAvatar';
import { StyledProps } from '../ModerationContent.styles';
import {
    ModerationMemoryUX,
    ModerationCategory,
    PromptTranslationFunctions,
    MemoryAuthor,
    MemoryPrompt,
} from '../../../../shared/types';
import { joinNameParts } from '../../../../shared/utils';
import { getModerationVars } from '../utils';
import ModerateButtons from '../ModerateButtons';
import { GStyles } from '../../../../styles/GStyles';
import ModerationMemoryText from './ModerationMemoryText';
import ModerationCaseCard from '../../dashboard/ModerationCaseCard';

interface Props {
    moderationMemory: ModerationMemoryUX;
    isLastMemory: boolean;
    isPending: boolean;
    isRememberPage: boolean;
    showHeader?: boolean;
    openMenu: (event: React.MouseEvent<HTMLElement>, memory: ModerationMemoryUX) => void;
    onApprove: () => void;
    onBlock: () => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, author: MemoryAuthor) => void;
}

const ModerationMemory = (props: Props & StyledProps) => {
    const {
        classes,
        moderationMemory,
        isLastMemory,
        isPending,
        isRememberPage,
        showHeader,
        openMenu,
        onApprove,
        onBlock,
        openGuestPopper
    } = props;
    const {
        memory,
        case_fname,
        case_lname,
        case_name,
        fh_key,
        case_photo,
        case_photo_transformations,
        dod_start_date,
        dob_date,
        dod_start_time,
        funeral_home_name,
    } = moderationMemory;
    const { author, prompt } = memory;

    const { isApproved, isBlocked, moderationText } = getModerationVars(moderationMemory, ModerationCategory.memories);
    const sharedTime = moment(memory.created_time).fromNow();
    const areModerateButtonsVisible = isPending;

    return (
        <Grid
            item
            xs={12}
            key={memory.id}
            className={classNames(
                showHeader && classes.firstMemory,
                classes.memory,
                isApproved && classes.approved,
                isBlocked && classes.blocked,
                isLastMemory && classes.lastMemory,
            )}
        >
            {showHeader &&
                <ModerationCaseCard
                    gatherCase={{
                        name: case_name,
                        fname: case_fname,
                        lname: case_lname,
                        dod_start_date: dod_start_date,
                        dob_date: dob_date,
                        dod_start_time: dod_start_time,
                        photo: case_photo,
                        photo_transformations: case_photo_transformations,
                        funeral_home_name: funeral_home_name,
                        funeral_home_key: fh_key,
                    }}
                />
            }
            <Grid
                item
                xs={12}
                justifyContent={!areModerateButtonsVisible ? 'flex-end !important' : undefined}
                className={classNames(
                    classes.memoryOptions,
                    !areModerateButtonsVisible && GStyles.flexEnd
                )}
            >
                {areModerateButtonsVisible &&
                    <ModerateButtons
                        onApprove={onApprove}
                        onBlock={onBlock}
                    />
                }
                <Typography
                    color="secondary"
                    className={classNames(
                        GStyles.fontSize12,
                        GStyles.fontStyleItalic,
                        isBlocked && classes.blockColor,
                        isApproved && classes.approveColor,
                    )}
                >
                    {moderationText}
                    <IconButton
                        className={classes.menuIconButton}
                        onClick={e => openMenu(e, moderationMemory)}
                        size="large">
                        <MoreVertIcon />
                    </IconButton>
                </Typography>
            </Grid>

            {prompt !== MemoryPrompt.Candle && <Grid item xs={12}>
                <Typography
                    align="center"
                    color="secondary"
                    fontSize="16px"
                    className={classNames(
                        GStyles.fontWeight500,
                        GStyles.textUnderline
                    )}
                >
                    {PromptTranslationFunctions[prompt](case_fname, author ? author.fname : undefined)}
                </Typography>
            </Grid>}

            <Grid
                item
                xs={12}
            >
                <ModerationMemoryText text={memory.memory_text} decision={moderationMemory.moderation_decision} />
                {author &&
                    <Grid item xs={12} className={classes.memorySharedBy}>
                        <UserAvatar
                            user={author}
                            className={classNames(classes.userAvatar, isRememberPage && GStyles.cursorPointer)}
                            onClick={e => isRememberPage && openGuestPopper(e, author)}
                        />&nbsp;
                        <Grid item className={classes.sharedByContainer}>
                            <Typography color="secondary">
                                <span className={GStyles.fontWeight500}>
                                    {joinNameParts(author)}
                                </span>&nbsp;{author.relationship ? `(${author.relationship})` : ''}
                            </Typography>
                            <Typography color="secondary">
                                {prompt === MemoryPrompt.Candle
                                    ? `Candle lit ${sharedTime}`
                                    : `Memory shared ${sharedTime}`}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default ModerationMemory;