import * as React from 'react';
import classNames from 'classnames';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import ConfirmationDialog from '../../../common/ConfirmationDialog';
import { GatherCaseUX, DocCategory } from '../../../../shared/types';

import { StoreState } from '../../../../types';
import {
    downloadCaseDoc,
    createCaseDocs,
    removeCaseDoc,
} from '../../../../actions/Doc.action';
import { downloadFromURL } from '../../../../services/doc.service';
import { canManageDD214OnCase } from '../../../../shared/authority/can';
import { AppDispatch } from '../../../../store';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withState from '../../../common/utilHOC/WithState';
import { TOOLTIPS } from '../../../../constants';

function mapStateToProps({
    userSession,
    caseDocState,
    tasksState,
}: StoreState, ownProps: OwnProps) {

    const { activeCase } = ownProps;
    const formDD214Detail = caseDocState.docs.find((d) => d.doc !== null
        && d.doc.doc_category === DocCategory.form_dd214);
    const formDD214 = formDD214Detail ? formDD214Detail.doc : null;


    const manageDD214Allowed = canManageDD214OnCase(
        userSession.userData,
        activeCase.funeral_home_id,
        activeCase.id,
        tasksState.checklistTasks,
    );

    return {
        formDD214,
        userSession,
        manageDD214Allowed,
    };
}

interface OwnProps {
    activeCase: GatherCaseUX;
    disabled?: boolean;
}

interface Props extends ReturnType<typeof mapStateToProps>, OwnProps {
    dispatch: AppDispatch;
}

interface State {
    isSaving: boolean;
    isDeleteConfirmationDialogOpen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        position: 'relative',
        width: 280,
        boxShadow: 'none',
        borderRadius: 12,
        textAlign: 'center',
        '@media (min-width: 360px)': {
            width: 310,
        }
    },
    cardContent: {
        padding: '24px 16px'
    },
    buttonLeftIcon: {
        marginRight: 8
    },
    benifitsText: {
        textAlign: 'center',
        marginBottom: 16
    },
    insertDriveFileIcon: {
        fontSize: 80,
        margin: 'auto',
        display: 'block'
    },
    downloadText: {
        fontWeight: 200,
        textDecoration: 'underline'
    },
    buttonLabel: {
        display: 'block',
        textAlign: 'center',
        margin: '8px auto',
    },
    downloadFormHeading: {
        textAlign: 'center',
        fontSize: 24
    },
    closeIconButton: {
        top: 0,
        right: 0,
        position: 'absolute',
        margin: 5,
        // margin: '18px 5px',
        '& svg': {
            fontSize: 30
        }
    },
    butttonDownloadProgress: {
        marginTop: '-60px !important',
        marginLeft: '-60px !important'
    },
    disabledDownloadButton: {
        background: 'rgba(0, 0, 0, 0.26)'
    }
});

type StyledProps = Props & WithGStyles<'root' | 'cardContent' | 'buttonLeftIcon' | 'benifitsText'
    | 'insertDriveFileIcon' | 'downloadText' | 'buttonLabel' | 'downloadFormHeading' | 'closeIconButton'
    | 'butttonDownloadProgress' | 'disabledDownloadButton'>;

class FormDD214 extends React.Component<StyledProps, State> {

    state: State = {
        isSaving: false,
        isDeleteConfirmationDialogOpen: false,
    };

    protected fileUploadInput: HTMLInputElement | null;

    renderUploadFormContent = () => {
        const { classes, disabled, manageDD214Allowed } = this.props;
        const { isSaving } = this.state;

        const tooltipText = !manageDD214Allowed ?
            TOOLTIPS.DISABLED_FEATURE
            : '';
        return (
            <CardContent className={classes.cardContent}>
                <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png, application/pdf, application/msword, 
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    multiple={false}
                    className={classes.displayNone}
                    ref={ele => this.fileUploadInput = ele}
                    onChange={this.uploadFormDD214}
                    onClick={e => {
                        const element = e.target as HTMLInputElement;
                        // clear this value to so that same photo can be chosen each time
                        element.value = '';
                    }}
                />

                <Typography
                    color="primary"
                    className={classes.benifitsText}
                >
                    In order to receive military funeral benefits,
                    you will need to upload a Certificate of Release or
                    Discharge from Active Duty (Form DD‌-214).
                </Typography>
                <Tooltip
                    title={tooltipText}
                    enterDelay={400}
                >
                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classNames(
                                classes.margin_8,

                            )}
                            disabled={disabled || isSaving || !manageDD214Allowed}
                            onClick={manageDD214Allowed ? e => this.fileUploadInput && this.fileUploadInput.click()
                                : undefined}
                        >
                            <CloudUploadIcon className={classes.buttonLeftIcon} />
                            upload DD-214
                            {isSaving &&
                                <CircularProgress
                                    color="primary"
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            }
                        </Button>
                    </div>
                </Tooltip>
            </CardContent>
        );
    };

    renderDownloadFormContent = () => {
        const { classes, disabled, manageDD214Allowed } = this.props;
        const { isSaving } = this.state;

        const tooltipText = !manageDD214Allowed ?
            TOOLTIPS.DISABLED_FEATURE
            : '';

        return (
            <CardContent className={classes.cardContent}>
                <IconButton
                    color="primary"
                    aria-label="Close"
                    onClick={this.openDeleteConfirmationDialog}
                    className={classes.closeIconButton}
                    disabled={disabled || isSaving}
                    size="large">
                    <CloseIcon />
                </IconButton>
                <Typography
                    color="primary"
                    className={classes.downloadFormHeading}
                >
                    Form DD-214
                </Typography>
                <Tooltip
                    title={tooltipText}
                    enterDelay={400}
                >
                    <div>
                        <Button
                            className={classNames(
                                isSaving ? classes.disabledDownloadButton : '',
                                classes.buttonLabel,
                            )}
                            onClick={manageDD214Allowed ? this.downloadFormDD214 : undefined}
                            disabled={isSaving || !manageDD214Allowed}
                        >
                            <InsertDriveFileIcon
                                color="primary"
                                className={classes.insertDriveFileIcon}
                            />
                            <span
                                className={classNames(
                                    classes.downloadText,
                                    classes.colorPrimary
                                )}
                            >
                                {manageDD214Allowed ? 'Click to download' : 'Access Restricted'}
                            </span>
                            {isSaving && <CircularProgress
                                size={120}
                                color="primary"
                                thickness={2}
                                className={classNames(
                                    classes.buttonProgress,
                                    classes.butttonDownloadProgress
                                )}
                            />}
                        </Button>
                    </div>
                </Tooltip>
            </CardContent>
        );
    };

    renderDeleteDialog = () => {
        const { isDeleteConfirmationDialogOpen } = this.state;

        const deleteDialogHeader = 'Are you sure you want to delete this file?';
        const deleteDialogConfirmationButton = 'Delete File';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={this.closeDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={this.deleteFormDD214}
                zIndex={1350}
            />
        );
    };

    render() {
        const { classes, formDD214 } = this.props;

        return (
            <Card
                className={classNames(
                    classes.root,
                    classes.background_primary_0_2
                )}
            >
                {formDD214 ? this.renderDownloadFormContent() : this.renderUploadFormContent()}
                {this.renderDeleteDialog()}
            </Card>
        );
    }

    closeDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: false,
        });
    };

    openDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: true,
        });
    };

    downloadFormDD214 = async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const { activeCase, formDD214, dispatch } = this.props;

        if (formDD214) {
            const downloadDocResult = await dispatch(downloadCaseDoc(activeCase.uuid, formDD214.id));
            if (!downloadDocResult) {
                return;
            }
            downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
        }
    };

    uploadFormDD214 = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { dispatch, activeCase, userSession } = this.props;

        this.setState({ isSaving: true });

        const userId = userSession.userData?.user_id;
        if (userId === undefined) {
            return;
        }

        await dispatch(createCaseDocs({
            files: event.target.files,
            userId,
            caseUuid: activeCase.uuid,
            docCategory: DocCategory.form_dd214,
        }));
        this.setState({ isSaving: false });
    };

    deleteFormDD214 = async () => {
        const { activeCase, formDD214, dispatch } = this.props;

        this.closeDeleteConfirmationDialog();

        if (!formDD214) {
            console.warn('Somehow got to deleteFormDD214 without a formDD214');
            return;
        }

        this.setState({ isSaving: true });

        await dispatch(removeCaseDoc(activeCase.uuid, formDD214.id));

        this.setState({ isSaving: false });
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(FormDD214));