import classNames from 'classnames';
import ReactSignatureCanvas from 'react-signature-canvas';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import makeGStyles from '../../../../../../../styles/makeGStyles';

import SignatureComponent from '../SignatureComponent';
import StepComponentWrapper from '../../StepComponentWrapper';
import SignHereTag from './SignHereTag';
import { AdditionalSignatureConfig } from '../../../../../../../shared/types';
import { useEffect, useState } from 'react';
import { OnAnswerChangedFunction, OnValidityChangedFunction } from '../types';
import useDebounce from '../../../../../../common/hooks/useDebounce';

const useStyles = makeGStyles(theme => ({
    padding_16_12_0: {
        padding: '16px 12px 0px',
    },
    textField: {
        margin: '0 auto',
        width: '100%',
        display: 'block',
        maxWidth: 'calc(100% - 16px)',
        minWidth: '56%',
        '@media (min-width: 460px)': {
            width: 'fit-content',
            maxWidth: 'calc(100% - 60px)',
        },
    },
    signaturesContainer: {
        margin: '12px auto',
        width: '100%',
        alignItems: 'center',
        '& $scrollIndicator': {
            '@media (min-width: 460px)': {
                right: 12,
            },
        },
    },
    textfieldContainer: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        '& $scrollIndicator': {
            '@media (min-width: 460px)': {
                right: 30,
            },
        },
    },
    scrollIndicator: {},
}), { name: 'AdditionalSignatureComponent' });

interface Props {
    componentId: number;
    config: AdditionalSignatureConfig | null;
    saveAttempted: boolean;
    hasSigned: boolean;
    isStepCompleted: boolean;
    additionalSignatureUrl: string | null;
    canvasRef: React.MutableRefObject<ReactSignatureCanvas | null>;
    onStopDrawing: () => void;
    onValidityChanged: OnValidityChangedFunction;
    onAnswerChanged: OnAnswerChangedFunction;
    clearSignature: () => void;
}

const AdditionalSignatureComponent = (props: Props) => {
    const {
        componentId,
        config,
        hasSigned,
        canvasRef,
        saveAttempted,
        isStepCompleted,
        additionalSignatureUrl,
        onStopDrawing,
        onValidityChanged,
        onAnswerChanged,
        clearSignature,
    } = props;

    const classes = useStyles();

    const finePrint = config?.fine_print;
    const verificationText = config?.verification_text;
    const isRequired = config?.is_required;

    const [name, setName] = useState<string | null>(config?.answer ?? null);

    const hasName = Boolean(name?.trim());
    const hasNameError = Boolean(isRequired && !hasName);
    const hasSignError = Boolean(isRequired && !hasSigned);
    const isValid = !hasNameError && !hasSignError;

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    const debouncedAnswerChanged = useDebounce(onAnswerChanged);

    useEffect(() => {
        debouncedAnswerChanged(componentId, name ?? '');
    }, [debouncedAnswerChanged, componentId, name]);

    return (
        <StepComponentWrapper
            extendedWidth
            error={saveAttempted && !isValid}
            success={isValid && isRequired}
            heading={`Additional Signature${isRequired ? ' *' : ''}`}
        >
            {finePrint &&
                <Grid item={true} xs={12} className={classNames(classes.padding_16_12_0)}>
                    <Typography color="secondary" className={classes.fontSize12}>
                        {finePrint}
                    </Typography>
                </Grid>
            }

            <Grid item xs={12} className={classes.textfieldContainer}>
                <TextField
                    value={name}
                    label="Type Your Name Here"
                    variant="outlined"
                    className={classes.textField}
                    error={saveAttempted && hasNameError}
                    required={isRequired}
                    InputProps={{
                        classes: {
                            root: classes.width100,
                        },
                    }}
                    disabled={isStepCompleted}
                    onChange={evt => setName(evt.currentTarget.value)}
                />

                <SignHereTag
                    title='Name Here'
                    hideTag={hasName || isStepCompleted}
                    required={isRequired}
                />

            </Grid>

            <Grid
                container={true}
                justifyContent="center"
                className={classes.signaturesContainer}
            >
                <Grid item xs={12}>
                    <SignatureComponent
                        signatureUrl={additionalSignatureUrl}
                        heading={verificationText}
                        signerFullName={name}
                        canvasRef={canvasRef}
                        onStopDrawing={onStopDrawing}
                        clearSignature={clearSignature}
                        hasSignError={saveAttempted && hasSignError}
                        hasSigned={hasSigned}
                        signatureHeadingClass={classes.maxWidth280}
                    />
                </Grid>

                <SignHereTag
                    title='Sign Here'
                    hideTag={hasSigned || isStepCompleted}
                    required={isRequired}
                />

            </Grid>
        </StepComponentWrapper>
    );
};

export default AdditionalSignatureComponent;