import {
    CASE_TABLE,
    CaseTableAction,
    REMOVE_CASE,
    UPDATING_CASE,
    CASE_UPDATED,
    UPDATING_CASE_NUMBER,
    CASE_NUMBER_UPDATED,
} from '../actions/GatherCase.action';
import * as constants from '../constants';
import { CaseTableReduxState } from '../types';
import { CaseStatus, GatherCaseReportType } from '../shared/types';
import { GatherAction } from '../actions';
import moment from 'moment-timezone';

export const caseTableReduxInitData: CaseTableReduxState = {
    data: [],
    hasMoreData: true,
    searchText: '',
    sortBy: 'created_time',
    sortDirection: 'desc',
    isLoading: false,
    totalCaseCount: 0,
};

export const caseTableState = (
    state: CaseTableReduxState = caseTableReduxInitData,
    action: CaseTableAction | GatherAction,

): CaseTableReduxState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return caseTableReduxInitData;
        case CASE_TABLE.CASE_TABLE_LOADED:
            return {
                ...state,
                data: action.isOverwrite ? action.data : [...state.data, ...action.data],
                isLoading: false,
                hasMoreData: action.hasMoreData,
            };
        case CASE_TABLE.CASE_TABLE_LOADING:
            return {
                ...state,
                data: [...action.data],
                searchText: action.searchText,
                sortDirection: action.sortDirection,
                sortBy: action.sortBy,
                isLoading: true,
            };
        case CASE_TABLE.CASE_TABLE_LOAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case CASE_TABLE.SET_TOTAL_CASE_COUNT:
            return {
                ...state,
                totalCaseCount: action.count
            };
        case CASE_UPDATED:
            return {
                ...state,
                data: state.data.map((obj): GatherCaseReportType => {
                    if (obj.id === action.gatherCase.id) {
                        let caseStatus: CaseStatus;
                        if (action.gatherCase.deleted_time) {
                            caseStatus = CaseStatus.DELETED;
                        } else if (action.gatherCase.archived_time) {
                            caseStatus = CaseStatus.ARCHIVED;
                        } else if (action.gatherCase.is_test) {
                            caseStatus = CaseStatus.TEST;
                        } else {
                            caseStatus = CaseStatus.ACTIVE;
                        }

                        return {
                            ...obj,
                            ...action.gatherCase,
                            assignee_fname: action.gatherCase.assignee.fname,
                            assignee: {
                                user_id: action.gatherCase.assignee.user_id || 0,
                                entity_id: action.gatherCase.assignee.entity_id,
                                fname: action.gatherCase.assignee.fname,
                                lname: action.gatherCase.assignee.lname,
                                email: action.gatherCase.assignee.email || '',
                                phone: action.gatherCase.assignee.phone || '',
                            },
                            case_status: caseStatus,
                            created_time: moment(action.gatherCase.created_time).format('YYYY-MM-DD'),
                            updated_time: moment(action.gatherCase.updated_time).format('YYYY-MM-DD'),
                        };
                    } else {
                        return obj;
                    }
                }),
            };
        case UPDATING_CASE:
            return {
                ...state,
                data: state.data.map(obj => {
                    if (obj.uuid === action.caseUuid) {
                        return {
                            ...obj,
                            deleted_time: action.changes.deleted_time !== undefined
                                ? action.changes.deleted_time
                                : obj.deleted_time,
                            case_number: action.changes.case_number !== undefined
                                ? action.changes.case_number
                                : obj.case_number,
                            assignee_fname: action.changes.assignee !== undefined
                                ? action.changes.assignee.fname
                                : obj.assignee_fname,
                            assignee: action.changes.assignee !== undefined
                                ? {
                                    user_id: action.changes.assignee.user_id || 0,
                                    entity_id: action.changes.assignee.entity_id,
                                    fname: action.changes.assignee.fname || '',
                                    lname: action.changes.assignee.lname || '',
                                    email: action.changes.assignee.email || '',
                                    phone: action.changes.assignee.phone || '',
                                }
                                : obj.assignee,
                            case_status: action.changes.is_test !== undefined
                                ? action.changes.is_test
                                    ? CaseStatus.TEST
                                    : CaseStatus.ACTIVE
                                : action.changes.deleted_time !== undefined
                                    ? action.changes.deleted_time
                                        ? CaseStatus.DELETED
                                        : CaseStatus.ACTIVE
                                    : obj.case_status,
                        };
                    } else {
                        return obj;
                    }
                })
            };
        case UPDATING_CASE_NUMBER:
            return {
                ...state,
                data: state.data.map(obj => {
                    if (obj.uuid === action.caseUuid) {
                        return {
                            ...obj,
                            case_number: action.caseNumber.match(/^next$/i) ? 'refresh to view...' : action.caseNumber,
                        };
                    } else {
                        return obj;
                    }
                })
            };
        case CASE_NUMBER_UPDATED:
            return {
                ...state,
                data: state.data.map(obj => {
                    if (obj.uuid ===  action.gatherCase.uuid) {
                        return {
                            ...obj,
                            case_number: `HERE ${action.gatherCase.case_number || ''}`,
                        };
                    } else {
                        return obj;
                    }
                })
            };
        case REMOVE_CASE:
            const updatedCaseList = state.data.filter(obj => {
                if (obj.uuid !== action.caseUuid) {
                    return {
                        ...obj
                    };
                } else {
                    return undefined;
                }
            });
            const caseDelta = state.data.length - updatedCaseList.length;
            return {
                ...state,
                data: updatedCaseList,
                totalCaseCount: state.totalCaseCount - caseDelta > 0 ? state.totalCaseCount - caseDelta : 0,
            };
        default:
            return state;
    }
};