import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

export const PADDING_STYLES =
    <Props extends object>(): StyleRulesCallback<Theme, Props, PaddingStylesClassKey> => theme => ({
        padding0: {
            padding: '0 !important',
        },
        padding10: {
            padding: 10
        },
        padding15: {
            padding: 15
        },
        padding20: {
            padding: 20,
        },
        padding24: {
            padding: theme.spacing(3),
        },

        paddingTop10: {
            paddingTop: 10,
        },
        paddingTop15: {
            paddingTop: 15,
        },
        paddingTop48: {
            paddingTop: 48,
        },

        padding_0_12: {
            padding: '0 12px',
        },
        padding_0_20: {
            padding: '0 20px',
        },

        paddingLeft20: {
            paddingLeft: 20,
        },

        padding_4_0: {
            padding: '4px 0',
        },

        paddingBottom12: {
            paddingBottom: 12,
        },
    });

export type PaddingStylesClassKey =
    'padding0'
    | 'padding10'
    | 'padding15'
    | 'padding20'
    | 'padding24'

    | 'paddingTop10'
    | 'paddingTop15'
    | 'paddingTop48'

    | 'padding_0_12'
    | 'padding_0_20'

    | 'paddingLeft20'

    | 'padding_4_0'

    | 'paddingBottom12';

export type PADDING_STYLED_PROPS = WithStyles<PaddingStylesClassKey>;
