export const ATT_ICON: JSX.Element = (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 411.22 411.06"
    >
        <defs>
            <style
                type="text/css"
                dangerouslySetInnerHTML={{ __html: '.cls-att{fill: #00a8e0;}' }}
            />
        </defs>
        <title>AT&amp;amp;T_logo_2016</title>
        <g id="g3">
            <path
                id="path9"
                className="cls-att"
                d={`M83.53,372.87a205.57,205.57,0,0,0,261.54-7.75c0.44-.39.22-0.64-0.21-0.39-16.22,
                10.83-62.45,34.48-135.24,34.48-63.26,0-103.24-14.11-125.82-26.72-0.43-.21-0.59.11-0.27,
                0.38m140,10.51c50.6,0,106.2-13.79,139.45-41.1,9.1-7.44,17.77-17.34,25.53-30.65A197.18,
                197.18,0,0,0,401,285.94c0.16-.44-0.11-0.65-0.44-0.16C369.59,331.29,280,359.68,187.58,
                359.68c-65.36,0-135.68-20.9-163.21-60.81-0.27-.37-0.54-0.21-0.38.21,25.65,54.51,
                103.46,84.29,199.58,84.29m-55.28-90.44c-105.23,0-154.85-49-163.85-82.46C4.32,210,
                4,210.1,4,210.54,4,221.8,5.13,236.33,7.07,246,8,250.67,11.81,258,17.41,263.91c25.48,
                26.56,89,63.78,199,63.78,149.9,0,184.18-49.93,191.18-66.36,5-11.74,7.6-33,7.6-50.79,
                0-4.31-.11-7.76-0.27-11.14,0-.55-0.32-0.59-0.43-0.06C407,239.52,279,292.94,168.28,
                292.94M23.82,122.37c-6,12-12.71,32.16-14.7,42.61-0.87,4.48-.5,6.63,1.07,10C22.8,
                201.7,86.6,244.52,235.42,244.52c90.79,0,161.32-22.3,172.74-63,2.1-7.49,
                2.22-15.4-.49-26.06-3-11.91-8.68-25.8-13.46-35.56-0.16-.32-0.44-0.27-0.38.11,
                1.78,53.39-147.1,87.79-222.22,87.79-81.36,0-149.25-32.42-149.25-73.35a50,50,0,0,1,
                1.83-12c0.1-.37-0.22-0.44-0.38-0.11M345.4,56.82a8.31,8.31,0,0,1,1.3,4.74c0,
                22.84-69.89,63.23-181.15,63.23-81.75,0-97.05-30.33-97.05-49.61,0-6.89,2.64-13.95,
                8.47-21.11,0.32-.43,0-0.59-0.32-0.27A206,206,0,0,0,47.7,83.86c-4.09,5.17-6.63,
                9.75-6.63,12.49,0,40,100.22,68.94,193.92,68.94,99.84,0,144.4-32.59,144.4-61.24,
                0-10.24-4-16.21-14.18-27.8a242.33,242.33,0,0,0-19.5-19.72c-0.32-.26-0.54,
                0-0.32.27M314.79,34A205.41,205.41,0,0,0,103,34.85C93.7,40.55,88.48,45.13,
                88.48,51c0,17.34,40.51,36,112.39,36,71.13,0,126.3-20.42,126.3-40.07,
                0-4.69-4.1-8-12.4-12.93`}
                transform="translate(-4 -5.01)"
            />
        </g>
    </svg>
);

export const TMOBILE_ICON: JSX.Element = (
    <svg
        id="svg2008"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65.38 80.42"
    >
        <defs>
            <style
                type="text/css"
                dangerouslySetInnerHTML={{ __html: '.cls-tmobile1{fill: #999b9e;}.cls-tmobile2{fill:#ed008c;}' }}
            />
        </defs>
        <title>T-Mobile_logo</title>
        <rect id="rect3217" className="cls-tmobile1" x="49.27" y="36.7" width="16.11" height="16.44" />
        <rect id="rect3219" className="cls-tmobile1" y="36.7" width="16.11" height="16.44" />
        <path
            id="path3221"
            className="cls-tmobile2"
            d="M61,16.18h1.91c12.34,0,18.1,6.6,20.5,24.21l3.82-.17L86.72,12.29H23.91L23.29,40.23,
            27,40.39c0.64-6.6,1.4-10.16,3.06-13.71,2.93-6.6,9-10.5,16.57-10.5h2.68V77c0,6.43-.38,
            8.47-1.91,10-1.28,1.18-3.82,1.69-6.76,1.69H37.68v4.06H72.6V88.65H69.65c-2.91,
            0-5.48-.51-6.74-1.69C61.38,85.43,61,83.4,61,77V16.18"
            transform="translate(-22.32 -12.29)"
        />
    </svg>
);

export const VERIZON_ICON: JSX.Element = (
    <svg
        id="svg3601"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 59.48 72.23"
    >
        <defs>
            <style
                type="text/css"
                dangerouslySetInnerHTML={{ __html: '.cls-verizon{fill: #cd040b;}' }}
            />
        </defs>
        <title>Verizon_2015_logo_-vector</title>
        <g id="layer1">
            <g id="g3346">
                <path
                    id="path3348"
                    className="cls-verizon"
                    d="M51,4.77L23.86,63,13.6,40.88H2.52L19.36,77h9L62,4.78H51Z"
                    transform="translate(-2.52 -4.77)"
                />
            </g>
        </g>
    </svg>
);