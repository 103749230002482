import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

interface Props {
    overlappedEventsCount: number;
}

const useStyles = makeStyles({
    warningText: {
        fontSize: 14,
        color: '#ffa000',
        marginBottom: 4,
        fontWeight: 500
    }
}, { name: 'OverlappedEventMessage' });

const OverlappedEventMessage = (props: Props) => {
    const { overlappedEventsCount } = props;

    const classes = useStyles();

    return (
        <Grid>
            {overlappedEventsCount > 0 && <Typography
                variant={'caption'}
                component="p"
                className={classes.warningText}
            >
                This event overlaps with {overlappedEventsCount} existing event
                {overlappedEventsCount > 1 ? 's' : ''}
            </Typography>}
        </Grid>
    );
};

export default OverlappedEventMessage;
