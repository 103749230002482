import { groupBy, find, partition } from 'lodash';
import {
    ByProductCategory,
    ProductContractUX,
    ProductContractRecord,
    ProductContractItemUX,
    ProductCategoryEnum,
    PricingModelEnum,
    ProductCategoryGroup,
    ProductCategory,
    ProductUX,
} from '../types';

export const getContractCurrentRevision = (contract: ProductContractUX) => {
    return contract.revisions[contract.revisions.length - 1];
};

export const groupByProductCategory = <T>(objectList: T[]): ByProductCategory<T> => {
    return groupBy(objectList, 'category') as ByProductCategory<T>;
};

export const isContractStarted = (contract: ProductContractUX | ProductContractRecord) => {
    return contract.tax_total !== null && contract.sub_total !== null;
};

export const getOriginalItem = (itemRevisions: ProductContractItemUX[]) => {
    const firstItem = itemRevisions[0];
    if (firstItem.original_item === null) {
        return firstItem;
    } else {
        const originalItem = find(itemRevisions, (item) => item.original_item === null);
        return originalItem || firstItem;
    }
};

export const getLatestItem = (itemRevisions: ProductContractItemUX[]): ProductContractItemUX => {
    return itemRevisions[itemRevisions.length - 1];
};

export const getLatestItems = (itemRevisionsList: ProductContractItemUX[][]): ProductContractItemUX[] => {
    return itemRevisionsList.map(getLatestItem);
};

export const findContractItem = (
    contractItemId: string,
    itemRevisionsList: ProductContractItemUX[][],
): ProductContractItemUX | undefined => {

    for (const itemRevisions of itemRevisionsList) {
        const targetItem = itemRevisions.find((ir) => ir.id === contractItemId);
        if (targetItem) {
            return targetItem;
        }
    }
    return undefined;
};

export const categorizeViewers = <T extends { is_funeral_home_user: boolean; is_editor?: boolean }>(
    viewers: T[],
) => {

    const [fhViewers, familyViewers] = partition(viewers, (v) => v.is_funeral_home_user);
    // const familyEditors = familyUsers.filter((v) => v.is_editor);

    return {
        fhViewers,
        familyViewers,
        // familyEditors,
    };
};

export const getCategoryGroupForCategory = (category: ProductCategory | null): ProductCategoryGroup => {
    switch (category) {
    case ProductCategoryEnum.care_of_loved_one:
    case ProductCategoryEnum.transportation:
    case ProductCategoryEnum.equipment_facilities_staff:
        return ProductCategoryGroup.professional_services;
    case ProductCategoryEnum.casket:
    case ProductCategoryEnum.urn:
    case ProductCategoryEnum.vault:
    case ProductCategoryEnum.cemetery:
    case ProductCategoryEnum.memorial:
    case ProductCategoryEnum.flowers:
        return ProductCategoryGroup.merchandise;
    case ProductCategoryEnum.cash_advance:
        return ProductCategoryGroup.cash_advance;
    default:
        return ProductCategoryGroup.other;
    }
};

export const filterCareOfLovedOneItems = (contractItems: ProductContractItemUX[]) => {
    return contractItems.filter((item) => {
        return item.category && item.category === ProductCategoryEnum.care_of_loved_one;
    });
};

export const filterTransportationItems = (contractItems: ProductContractItemUX[]) => {
    return contractItems.filter((item) => {
        return item.category && item.category === ProductCategoryEnum.transportation;
    });
};

export const filterFacilitiesItems = (contractItems: ProductContractItemUX[]) => {
    return contractItems.filter((item) => {
        return item.category && item.category === ProductCategoryEnum.equipment_facilities_staff;
    });
};

export const filterServiceItems = (contractItems: ProductContractItemUX[]) => {
    return contractItems.filter((item) => {
        return getCategoryGroupForCategory(item.category) === ProductCategoryGroup.professional_services;
    });
};

export const filterMerchandiseItems = (contractItems: ProductContractItemUX[]) => {
    return contractItems.filter((item) => {
        return getCategoryGroupForCategory(item.category) === ProductCategoryGroup.merchandise;
    });
};

export const filterCashAdvanceItems = (contractItems: ProductContractItemUX[]) => {
    return contractItems.filter((item) => {
        return getCategoryGroupForCategory(item.category) === ProductCategoryGroup.cash_advance;
    });
};

export const isAllowanceItem = (contractItem: ProductContractItemUX) => {
    return contractItem.product !== null
        && contractItem.product.pricing_model === PricingModelEnum.allowance
        && contractItem.product.base_price !== null;
};

export const isAllowanceCredit = (item: ProductContractItemUX) => item.type === 'allowance_credit';
export const isPackageDiscount = (item: ProductContractItemUX) => item.type === 'package_discount';
export const isPackagePlaceholder = (item: ProductContractItemUX) => item.type === 'package_placeholder';
export const isContractDiscount = (item: ProductContractItemUX) => item.type === 'contract_discount';
export const isNormalItem = (item: ProductContractItemUX) => item.type === 'normal';

export const getContractItemName = (args: {
    product?: ProductUX;
    contractItem?: ProductContractItemUX;
}): string => {
    const { contractItem, product } = args;
    const name = contractItem && contractItem.display_name ? contractItem.display_name
        : product ? product.name
            : contractItem ? contractItem.name : '';
    return name;
};
