import classNames from 'classnames';
import * as appService from '../../../../services/app.service';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { GatherCaseUX } from '../../../../shared/types';

import Typography from '@mui/material/Typography';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
}

const MemorializeInstagram = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
    } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    Instagram can memorialize {activeCase.fname}'s account if a family member
                    or friend submits a request. Instagram will try to prevent references to
                    memorialized accounts from appearing on Instagram in ways that may be
                    upsetting to {activeCase.fname}'s friends and family, and they also take
                    measures to protect {activeCase.fname}'s privacy by securing the account.
                    Here are some of the key features of memorialized accounts:
                </Typography>
                <ul
                    className={classNames(
                        classes.fontSize18,
                        classes.colorSecondary,
                        classes.textLeft,
                        classes.fontWeight300
                    )}
                >
                    <li>
                        Instagram doesn’t allow anyone to log into a memorialized account.
                        Please keep in mind that Instagram can't provide login information
                        for {activeCase.fname}'s account.
                    </li>
                    <li className={classes.marginTop10}>
                        The profile of a memorialized account doesn't appear differently from
                        an account that hasn't been memorialized.
                    </li>
                    <li className={classes.marginTop10}>
                        Memorialized accounts can’t be changed in any way. This includes
                        changes to likes, followers, tags, posts and comments.
                    </li>
                    <li className={classes.marginTop10}>
                        Posts {activeCase.fname} shared, including photos and videos, stay on
                        Instagram and are visible to the audience they were shared with.
                    </li>
                    <li className={classes.marginTop10}>
                        Memorialized accounts don't appear in public spaces, like people's Explore section.
                    </li>
                </ul>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    You will need the following...
                </Typography>
                <Grid
                    item
                    className={
                        classNames(
                            classes.padding10,
                            classes.textLeft
                        )
                    }
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        Full Name:
                        <span
                            className={classes.fontWeight500}
                            id="caseName"
                        >
                            {` ${appService.getFullNameFromCase(activeCase)}`}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Instagram Username
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Passing:
                        <span className={classes.fontWeight500} id="deathDate">
                            {activeCase.dod_start_date
                                ? ` ${appService.formatDateString(activeCase.dod_start_date)} `
                                : ''
                            }
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        One of the following proofs of passing:
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            Link to or screenshot of an obituary
                        </li>
                        <li className={classes.marginTop10}>
                            Link to or screenshot of a news article
                        </li>
                        <li className={classes.marginTop10}>
                            etc.
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid>
                <a
                    href="https://help.instagram.com/contact/452224988254813?helpref=faq_content"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Memorialize {activeCase.fname}'s Instagram
                        &nbsp;<OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default MemorializeInstagram;
