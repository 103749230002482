import * as React from 'react';

import { StyledProps, styleWrapper } from '../styles';
import { formatDinero } from '../../../../../shared/goods_and_services/pricing';

import CategoryGroupContent from '../widgets/CategoryGroupContent';
import { ContractPackageGroup } from '../../../../../shared/types';
import PackageDisplay from './PackageDisplay';
import { PrintModeType } from '../..';
import withGStyles from '../../../../../styles/WithGStyles';

type Props = {
    isPrintMode: boolean;
    isCondensedView: boolean;
    packageGroup: ContractPackageGroup;
    typographyStyles: React.CSSProperties;
    isItemizedStatement: boolean;
    renderCategoryContent: () => JSX.Element | null;
    printModeType?: PrintModeType;
};

const GroupedView: React.FunctionComponent<Props & StyledProps> = (props) => {
    const {
        isPrintMode,
        isCondensedView,
        renderCategoryContent,
        packageGroup,
        typographyStyles,
        printModeType,
        classes,
        isItemizedStatement
    } = props;

    return (
        <CategoryGroupContent
            title={
                <PackageDisplay
                    isCondensedView={isCondensedView}
                    title={packageGroup.title}
                    subPackages={packageGroup.subPackages}
                    typographyStyles={typographyStyles}
                    isPrintMode={isPrintMode}
                    printModeType={printModeType}
                />
            }
            categoryContent={renderCategoryContent()}
            isItemizedStatement={isItemizedStatement}
            isCondensedView={isCondensedView}
            classes={classes}
            isPrintMode={isPrintMode}
            subtotalLabel={'Package Total'}
            subtotalAmount={formatDinero(packageGroup.subTotal)}
        />
    );
};

export default withGStyles(styleWrapper<Props>())(GroupedView);
