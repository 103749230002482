import * as React from 'react';
import classNames from 'classnames';

import { alpha, Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import FacebookIcon from '@mui/icons-material/Facebook';
import ReplayIcon from '@mui/icons-material/Replay';

import { FACEBOOK_COLOR } from '../../utils';
import ShareIconSVG from '../../../svg/ShareIconSVG';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 16,
        overflow: 'hidden',
        height: '100%'
    },
    answerAgainButton: {
        textTransform: 'none',
        width: 'fit-content',
        color: theme.palette.common.white,
        margin: '0 auto 4px',
        fontWeight: 400,
        '& svg': {
            fontSize: 20
        }
    },
    fbButton: {
        background: FACEBOOK_COLOR,
        color: theme.palette.common.white,
        width: 'fit-content',
        margin: 'auto',
        borderRadius: 50,
        boxShadow: 'none !important',
        '&:hover': {
            background: '#293e6a'
        }
    },
    textContainer: {
        padding: '12px 16px 0',
        flex: 0
    },
    divider: {
        margin: '4px 0',
        background: theme.palette.common.white,
    },
    thanksText: {
        fontSize: 16
    },
    familyText: {
        maxWidth: 220,
        margin: '0 auto',
        '&$hasMessage': {
            maxWidth: 240
        }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    lineHeight_1_25: {
        lineHeight: 1.25
    },
    otherShareOptions: {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        borderRadius: 50,
        boxShadow: 'none !important',
        '&:hover, &:focus': {
            background: alpha(theme.palette.common.white, 0.8)
        },
        '& span': {
            lineHeight: 1
        },
    },
    shareButtonsContainer: {
        margin: 'auto',
        '& > span': {
            margin: '2px 0'
        }
    },
    shareSVG: {
        fill: theme.palette.primary.main
    },
    hasMessage: {}
});

type Classes = 'root' | 'answerAgainButton' | 'fbButton' | 'textContainer' | 'divider' | 'thanksText'
    | 'lineHeight_1_25' | 'familyText' | 'otherShareOptions' | 'shareButtonsContainer' | 'shareSVG'
    | 'hasMessage';
type StyledProps = WithStyles<Classes>;

interface Props {
    userFname: string;
    caseDisplayFname: string;
    rootClass?: string;
    message?: string;
    goBack: () => void;
    openShareOptionsDialog: () => void;
    openSocialIconPopper?: (event: React.MouseEvent<HTMLElement>, timeout?: number) => void;
    closeSocialIconPopper?: () => void;
}
const MemoryContributionThanksScreen = ({
    classes,
    userFname,
    caseDisplayFname,
    rootClass,
    message,
    goBack,
    openSocialIconPopper,
    closeSocialIconPopper,
    openShareOptionsDialog
}: Props & StyledProps) => {

    const buttonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        buttonRef.current?.focus({ preventScroll: true });
    }, []);

    const getSocialIconButtonProps = () => {
        let timeout = 400;
        if (openSocialIconPopper && closeSocialIconPopper) {
            return {
                onClick: (e: React.MouseEvent<HTMLElement>) => openSocialIconPopper(e),
                onMouseEnter: (e: React.MouseEvent<HTMLElement>) => openSocialIconPopper(e, timeout),
                onTouchStart: (e: React.TouchEvent<HTMLElement>) => closeSocialIconPopper(),
                onMouseLeave: (e: React.MouseEvent<HTMLElement>) => closeSocialIconPopper()
            };
        }

        return null;
    };

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classNames(classes.root, rootClass)}
        >
            <Grid item xs={12} className={classes.textContainer}>
                <Typography
                    color="inherit"
                    align="center"
                    className={classNames(classes.thanksText, classes.lineHeight_1_25)}
                >
                    Thank You {userFname}!
                </Typography>

                <Divider className={classes.divider} />

                <Typography
                    color="inherit"
                    align="center"
                    className={classNames(
                        classes.familyText,
                        classes.lineHeight_1_25,
                        !!message && classes.hasMessage
                    )}
                >
                    {message
                        || <>
                            Family & Friends will love reading your kind words about&nbsp;
                            <span>{caseDisplayFname}</span>.
                        </>}
                </Typography>
            </Grid>

            <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                className={classes.shareButtonsContainer}
            >
                <Button
                    variant="contained"
                    className={classes.fbButton}
                    {...getSocialIconButtonProps()}
                >
                    <FacebookIcon />&nbsp;SHARE ON FACEBOOK
                </Button>

                <span>or</span>

                <Button
                    variant="contained"
                    className={classes.otherShareOptions}
                    onClick={e => openShareOptionsDialog()}
                >
                    <ShareIconSVG color="" className={classes.shareSVG} />&nbsp;OTHER SHARE OPTIONS
                </Button>
            </Grid>

            <Button
                color="primary"
                ref={buttonRef}
                className={classes.answerAgainButton}
                onClick={e => goBack()}
            >
                <ReplayIcon />&nbsp;Answer this question again
            </Button>
        </Grid>
    );
};

export default withStyles(styles)(MemoryContributionThanksScreen);
