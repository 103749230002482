import * as React from 'react';
import { compose } from 'redux';
import Dinero from 'dinero.js';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import IncreaseDecreaseTextField from '../widgets/IncreaseDecreaseTextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
    formatDinero,
} from '../../../../shared/goods_and_services/pricing';
import PercentageSlider from '../../../common/PercentageSlider';
import GGroupButtons from '../../../common/GGroupButtons';
import withGStyles from '../../../../styles/WithGStyles';
import withFullScreen, { WithFullScreenProps } from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import { GStyles } from '../../../../styles/GStyles';
import GLink from '../../../common/GLink';
import { FuneralHomeRoutePage, RouteBuilder } from '../../../../services';
import withState from '../../../common/utilHOC/WithState';
import { StoreState } from '../../../../types';
import classNames from 'classnames';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            margin: 0,
            padding: 0,
            height: '100vh',
            maxHeight: '100vh',
            width: '100%',
            maxWidth: '100%',
            borderRadius: 0,
            '@media (min-width: 700px)': {
                height: 'auto',
                maxHeight: '80vh',
                width: 'auto',
                maxWidth: 600,
                borderRadius: 4
            },
        },
    },
    clearIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        background: theme.palette.primary.main,
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '24px 0 !important',
        margin: 0,
        textAlign: 'center',
        '& $inner': {
            padding: '0 24px'
        }
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        color: theme.palette.common.white,
    },
    textField: {
        margin: '24px 0'
    },
    textAreaMinHeight: {
        minHeight: 80
    },
    incrementor: {
        margin: '0 auto 16px'
    },
    incrementorTextfield: {
        width: 100,
        margin: '12px 12px 0 !important',
        '& label': {
            width: 137, // is 0.75 scaled
        },
        '& input': {
            fontSize: '18px !important',
            textAlign: 'left !important',
            color: `${theme.palette.secondary.main} !important`,
            fontWeight: '400 !important'
        },
        '& p': {
            fontSize: 18
        }
    },
    incrementInputRoot: {
        paddingBottom: 8,
    },
    incrementorFab: {
        height: 36,
        width: 36,
        boxShadow: 'none',
        filter: 'none',
        border: '1px solid',
        '& p': {
            lineHeight: '1em',
            padding: 0,
            margin: 0,
            fontSize: 12,
            fontWeight: 500
        }
    },
    button: {
        margin: '24px 8px 8px',
        minWidth: 190,
    },
    gGroupButtons: {
        marginTop: 24,
        '& button': {
            height: 28,
            padding: 0,
            minHeight: 28,
            fontSize: 12,
            minWidth: 120
        },
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(2)': {
                marginTop: -1
            }
        }
    },
    editButton: {
        marginLeft: 8,
        height: 24,
        width: 24,
        '& svg': {
            fontSize: 14
        }
    },
    adjustmentTarget: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& input': {
            textAlign: 'center',
        },
        '& p': {
            fontSize: 20,
            lineHeight: '32px'
        }
    },
    inner: {},
    dialogPaper: {},
});

const mapStateToProps = ({ funeralHomeState: { activeFuneralHome } }: StoreState) => {
    return { fhKey: activeFuneralHome?.key };
};

interface InjectedProps extends ReturnType<typeof mapStateToProps>, WithFullScreenProps {
}

interface Props {
    isDialogOpen: boolean;
    listPrice: Dinero.Dinero;
    existingAdjustment: Dinero.Dinero;
    target: string;
    assetType: string;
    isContractAdjustment?: boolean;
    closeDialog: () => void;
    onPriceAdjustmentChange: (priceAdjustment: number, contractItemName?: string) => void;
    zIndex: number;
}

interface State {
    priceAdjustment: Dinero.Dinero;
    isFirstAttempt: boolean;
    isEditMode: boolean;
    itemName: string | null;
}

type StyledProps = GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent' | 'textField' | 'clearIcon' | 'headerText'
        | 'textAreaMinHeight' | 'incrementor' | 'incrementorFab' | 'incrementorTextfield' | 'incrementInputRoot'
        | 'inner' | 'button' | 'gGroupButtons' | 'editButton' | 'adjustmentTarget'>;

type CombinedProps = Props & StyledProps & InjectedProps;

class PriceAdjustmentDialog extends React.Component<CombinedProps, State> {
    state: State = {
        priceAdjustment: this.props.existingAdjustment || Dinero(),
        isFirstAttempt: true,
        isEditMode: false,
        itemName: this.props.target || null,
    };

    render() {
        const {
            classes,
            isDialogOpen,
            listPrice,
            existingAdjustment,
            target,
            isContractAdjustment,
            zIndex,
            fhKey
        } = this.props;

        const { priceAdjustment, itemName } = this.state;

        const nameChanged = itemName !== null && itemName !== target;

        const priceAdjustmentStr = formatDinero(priceAdjustment.isNegative() ?
            priceAdjustment.multiply(-1)
            : priceAdjustment
        );

        const adjustmentType = priceAdjustment.isNegative() ? 'discount' : 'premium';
        const newTotalPrice = listPrice.add(priceAdjustment);

        const sign = priceAdjustment.isNegative() ? '-' : '+';

        const percentDifference = Math.abs(Math.round(100 * (1 - newTotalPrice.getAmount() / listPrice.getAmount())));
        const maxDiscountError = !listPrice.isNegative() && listPrice.add(priceAdjustment).isNegative();
        const buttonText = nameChanged && target
            ? 'Save Changes'
            : priceAdjustment.isZero()
                ? 'No Adjustment'
                : `Apply ${priceAdjustmentStr} ${adjustmentType}`;

        const priceText = isContractAdjustment ? 'Contract total before adjustment(s)' : 'List Price';

        const isSaveDisabled = maxDiscountError ||
            ((!nameChanged || !target) && existingAdjustment.getAmount() === priceAdjustment.getAmount());

        return (
            <Dialog
                open={isDialogOpen}
                onClose={this.cancelDialog}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.root,
                }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.header}
                >
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={this.cancelDialog}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classes.headerText}
                    >
                        {isContractAdjustment ? 'Adjust Statement Total' : 'Product Price Adjustment'}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={12} className={classes.inner}>
                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize14}
                            >
                                Enter a price adjustment for
                            </Typography>

                            <Grid item xs={12} className={classes.adjustmentTarget}>
                                {isContractAdjustment ?
                                    <TextField
                                        value={itemName !== null ? itemName : `Statement ${adjustmentType}`}
                                        onChange={e => this.setState({ itemName: e.target.value })}
                                    />
                                    :
                                    <Typography
                                        align="center"
                                        color="primary"
                                        className={classes.fontSize20}
                                    >
                                        {target}
                                    </Typography>
                                }
                            </Grid>

                            <Typography color="primary" className={classes.fontSize12}>
                                {priceText}: {formatDinero(listPrice)}
                            </Typography>

                            <Grid item xs={12} className={classes.gGroupButtons}>
                                <GGroupButtons
                                    buttons={[
                                        { label: 'Discount (-)', value: '-1' },
                                        { label: 'Premium (+)', value: '1' },
                                    ]}
                                    activeButton={(priceAdjustment.isNegative() || priceAdjustment.isZero())
                                        && '-1' || '1'}
                                    onClick={this.handleGroupButtonClick}
                                />
                            </Grid>

                            <IncreaseDecreaseTextField
                                widgetClasses={{
                                    root: classes.incrementor,
                                    fab: classes.incrementorFab,
                                    textField: classes.incrementorTextfield,
                                    inputRoot: classes.incrementInputRoot,
                                    helperText: classes.colorSecondary
                                }}
                                fabLabel={{
                                    decrease: <Typography align="center">-$25</Typography>,
                                    increase: <Typography align="center">+$25</Typography>,
                                }}
                                min={listPrice.multiply(-1).getAmount() / 100}
                                helperText={`Price Adjustment`}
                                counter={(priceAdjustment.getAmount() / 100) || 0}
                                onCounterChange={this.handleCounterChange}
                                increment={25}
                                startAdornment={
                                    <InputAdornment position="start" className={classes.colorSecondary}>
                                        ${priceAdjustment.isPositive() && !priceAdjustment.isZero() &&
                                            <span>&nbsp;+</span>
                                        }
                                    </InputAdornment>}
                                hasPlusAdornment={priceAdjustment.isPositive() && !priceAdjustment.isZero()}
                                hideIncrementors={true}
                                useUncontrolled={true}
                            />
                        </Grid>

                        {!listPrice.isZero() && <PercentageSlider
                            price={listPrice.multiply(listPrice.isNegative() ? -1 : 1)}
                            minPercent={-100}
                            maxPercent={100}
                            step={5}
                            active={priceAdjustment.getAmount()}
                            onPercentageSelect={this.handlePriceAdjustmentChange}
                        />}

                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => this.onApplyPriceAdjustmentClick()}
                                disabled={isSaveDisabled}
                            >
                                {buttonText}
                            </Button>
                            <Typography color="secondary">
                                {formatDinero(listPrice)} {sign} {priceAdjustmentStr} =&nbsp;
                                <b className={maxDiscountError && classes.colorRed || undefined}>
                                    {formatDinero(newTotalPrice)}
                                </b>
                            </Typography>
                            <Typography color="secondary" className={maxDiscountError && classes.colorRed || undefined}>
                                <span>{percentDifference || null}</span>%&nbsp;
                                <span>{!priceAdjustment.isZero() && adjustmentType}</span>
                            </Typography>

                            <Typography fontWeight={300} color="secondary" maxWidth={410} m="0 auto">
                                <span className={GStyles.fontWeight400}>Important:</span> Admins can adjust your
                                team's statement permissions, including preventing discounts and more, in the my team
                                section.&nbsp;<GLink
                                    linkClass={GStyles.colorSecondary}
                                    to={fhKey
                                        ? RouteBuilder.FuneralHome(fhKey, FuneralHomeRoutePage.MYTEAM)
                                        : RouteBuilder.Disabled}
                                >
                                    <span className={classNames(GStyles.displayInlineBlock, GStyles.textClickable)}>
                                        Click here
                                    </span>
                                </GLink> to navigate to
                                the my team section.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    onApplyPriceAdjustmentClick = () => {
        const { onPriceAdjustmentChange, closeDialog } = this.props;
        const { priceAdjustment, itemName } = this.state;

        onPriceAdjustmentChange(priceAdjustment.getAmount(), itemName || undefined);
        closeDialog();
    };

    cancelDialog = () => {
        const { target, existingAdjustment, closeDialog } = this.props;
        this.setState({
            priceAdjustment: existingAdjustment || Dinero(),
            itemName: target,
        });
        closeDialog();
    };

    handleCounterChange = (value: number) => {
        const { assetType } = this.props;
        const valueInCents = value * 100;

        this.setState(({ isFirstAttempt }) => ({
            priceAdjustment: Dinero({
                amount: Math.round(isFirstAttempt && valueInCents > 0 ? valueInCents * -1 : valueInCents),
                currency: assetType,
            }),
            isFirstAttempt: false,
        }));
    };

    handlePriceAdjustmentChange = (priceAdjustment: Dinero.Dinero) => {
        this.setState({
            priceAdjustment,
        });
    };

    handleGroupButtonClick = (value: string) => {
        const { priceAdjustment } = this.state;

        if ((priceAdjustment.isNegative() && value === '-1')
            || (priceAdjustment.isPositive() && value === '1')) {
            return;
        }

        this.setState({
            priceAdjustment: priceAdjustment.multiply(-1)
        });
    };
}

export default compose(
    withGStyles(styles),
    withFullScreen('xs'),
    withState(mapStateToProps)
)(PriceAdjustmentDialog) as React.ComponentType<Props>;
