import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import CircularProgress from '@mui/material/CircularProgress';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArchiveIcon from '@mui/icons-material/Archive';

import ConfirmationDialog from '../common/ConfirmationDialog';

import { GatherPhoto, PhotoStatusEnum } from '../../types';
import { GatherCasePublic, GatherCaseUX } from '../../shared/types';

import Photo from './Photo';
import { PhotoOrientationType, PhotoSizeType } from '../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import withFullScreen from '../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    grid: {
        height: 160,
        width: 160,
        borderRadius: 4,
    },
    addNewPhotoContainer: {
        background: '#fff',
        color: theme.palette.secondary.main,
        position: 'relative',
        textAlign: 'center',
        height: 160,
        verticalAlign: 'top',
        display: 'inline-block',
        margin: 0,
        '@media (min-width: 420px)': {
            margin: 12,
        }
    },
    squareButtonText: {
        fontSize: 12,
        margin: 0,
    },
    addNewPhotoInner: {
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    addPhotoIcon: {
        fontSize: 36,
    },
    downloadIcon: {
        height: 40,
        width: 40,
        filter: 'drop-shadow(0 0px 2px gray)',
        color: '#fff',
        position: 'absolute',
        bottom: 0,
        left: 0,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)'
        },
    },
    deleteIcon: {
        height: 40,
        width: 40,
        color: '#fff',
        position: 'absolute',
        bottom: 0,
        right: 0,
        filter: 'drop-shadow(0 0px 2px gray)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)'
        },
    },
    newPhotoOuter: {
        margin: 'auto',
        height: '100%',
    },
    imageBox: {
        position: 'relative',
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        height: 160,
        width: 160,
        boxShadow: theme.shadows[5],
        borderRadius: 4,
        margin: 0,
        '@media (min-width: 368px)': {
            margin: 12,
        }
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '-6px 8px 8px',
    },
    loaderContainer: {
        textAlign: 'center',
        margin: 12,
    },
    cutsomButtonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -43,
        marginLeft: -43,
    },
    disabledDownloadButton: {
        opacity: 0.4,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'grid' | 'addNewPhotoContainer' | 'squareButtonText'
    | 'addNewPhotoInner' | 'addPhotoIcon' | 'deleteIcon' | 'newPhotoOuter' | 'imageBox' | 'listContainer'
    | 'loadingText' | 'loaderContainer' | 'cutsomButtonProgress' | 'downloadIcon' | 'disabledDownloadButton'>;

export interface Props {
    photoOptions?: {
        orientation?: PhotoOrientationType;
        size?: PhotoSizeType;
    };
    activeCase?: GatherCaseUX | GatherCasePublic;
    photoList: GatherPhoto[];
    isLoading: boolean;
    activePhotoId?: number;
    acceptMultiple?: boolean;
    hideActionButtons?: boolean;
    hideDownloadButton?: boolean;
    disablePhotoSwipeOnClick?: boolean;
    onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPhotoClick?: (chosenImage: GatherPhoto) => void;
    downloadCasePhotos?: (gatherCase: GatherCaseUX | GatherCasePublic) => Promise<string | null>;
    onPhotoDelete?: (gatherPhoto: GatherPhoto) => void;
    photoContainerClass?: string;
    disableZoomAnimation?: boolean;
}

interface State {
    isDeleteConfirmationDialogOpen: boolean;
    photoToDelete: GatherPhoto | null;
    downloadUrl: string | null;
}

class PhotoList extends React.Component<StyledProps, State> {
    protected fileUploadInput: HTMLInputElement;

    constructor(props: StyledProps) {
        super(props);
        this.state = {
            isDeleteConfirmationDialogOpen: false,
            photoToDelete: null,
            downloadUrl: null,
        };
    }

    async componentDidMount() {
        const { downloadCasePhotos, activeCase, hideDownloadButton } = this.props;
        if (!hideDownloadButton && downloadCasePhotos && activeCase) {
            this.setState({
                downloadUrl: await downloadCasePhotos(activeCase),
            });
        }
    }

    renderUploadNewPhoto = () => {
        const { classes, acceptMultiple, onFileUpload, photoContainerClass, disableZoomAnimation } = this.props;

        const content = <Button
            className={classNames(classes.grid, classes.addNewPhotoContainer, photoContainerClass)}
            onClick={event => this.fileUploadInput.click()}
        >
            <input
                type="file"
                accept="image/gif, image/jpeg, image/png"
                multiple={acceptMultiple || false}
                className={classes.displayNone}
                ref={ref => {
                    if (ref) {
                        this.fileUploadInput = ref;
                    }
                }}
                onClick={e => {
                    const element = e.target as HTMLInputElement;
                    // clear this value to so that same photo can be chosen each time
                    element.value = '';
                }}
                onChange={e => onFileUpload(e)}
            />
            <div className={classNames(classes.displayTable, classes.newPhotoOuter)}>
                <div className={classes.addNewPhotoInner}>
                    <AddAPhotoIcon color="primary" className={classes.addPhotoIcon} />
                    <Typography
                        color="primary"
                        paragraph
                        className={classes.squareButtonText}
                    >
                        Upload New
                    </Typography>
                </div>
            </div>
        </Button>;

        if (disableZoomAnimation) {
            return content;

        }

        return (
            <Zoom in timeout={1200}>
                {content}
            </Zoom >
        );
    };

    renderDownloadPhotosButton = () => {
        const { classes, photoList } = this.props;
        const { downloadUrl } = this.state;
        const disabled = !photoList.length || !downloadUrl;

        return (
            <Zoom in timeout={1200}>
                <a href={downloadUrl || undefined}>
                    <Button
                        className={classNames(
                            classes.grid,
                            classes.addNewPhotoContainer,
                            disabled ? classes.disabledDownloadButton : ''
                        )}
                        disabled={disabled}
                    >
                        <div className={classNames(classes.displayTable, classes.newPhotoOuter)}>
                            <div className={classes.addNewPhotoInner}>
                                <ArchiveIcon color="primary" className={classes.addPhotoIcon} />
                                <Typography
                                    color="primary"
                                    paragraph
                                    className={classes.squareButtonText}
                                >
                                    Download All Photos
                                </Typography>
                            </div>
                        </div>
                    </Button>
                </a>
            </Zoom>
        );
    };

    renderPhotoList = () => {
        const {
            photoList,
            classes,
            onPhotoClick,
            hideDownloadButton,
            activePhotoId,
            activeCase,
            hideActionButtons,
            photoOptions,
            disablePhotoSwipeOnClick,
        } = this.props;
        const filteredPhotos = photoList
            .filter((photo) => photo.status !== PhotoStatusEnum.failed)
            .reverse();
        return (
            <div className={classes.listContainer} >
                {this.renderUploadNewPhoto()}
                {filteredPhotos.map((photo: GatherPhoto, index: number) =>
                    <Zoom in timeout={1200} key={photo.gatherId}>
                        <div>
                            <Photo
                                photo={photo}
                                photos={filteredPhotos}
                                photoOptions={photoOptions}
                                onPhotoClick={onPhotoClick ? () => onPhotoClick(photo) : undefined}
                                downloadName={`${activeCase ? activeCase.name : 'photo'}-${index}`}
                                hideDownload={hideActionButtons}
                                hideDelete={hideActionButtons ||
                                    Boolean(photo.photo && activePhotoId && photo.photo.id === activePhotoId)
                                }
                                onPhotoDelete={() => this.openDeleteConfirmationDialog(photo)}
                                disablePhotoSwipeOnClick={disablePhotoSwipeOnClick}
                            />
                        </div>
                    </Zoom>
                )}
                {!hideDownloadButton && this.renderDownloadPhotosButton()}
            </div>
        );
    };

    openDeleteConfirmationDialog = (photo: GatherPhoto) => {
        this.setState({
            isDeleteConfirmationDialogOpen: true,
            photoToDelete: photo,
        });
    };

    closeDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: false,
            photoToDelete: null,
        });
    };

    handleDeletePhoto = () => {
        const { onPhotoDelete } = this.props;
        const { photoToDelete } = this.state;
        if (photoToDelete && onPhotoDelete) {
            onPhotoDelete(photoToDelete);
        }
        this.closeDeleteConfirmationDialog();
    };

    renderConfirmationDialog = () => {
        const { isDeleteConfirmationDialogOpen } = this.state;
        const deleteDialogHeader = 'Are you sure you want to delete this photo?';
        const deleteDialogConfirmationButton = 'Delete Photo';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={this.closeDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={this.handleDeletePhoto}
                zIndex={1350}
            />
        );
    };

    renderLoader = (text: string = 'Loading...') => {
        const { classes } = this.props;

        return (
            <div className={classes.loaderContainer}>
                <CircularProgress
                    color="primary"
                    size={86}
                    thickness={3}
                />
                <Typography
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    {text}
                </Typography>
            </div>
        );
    };

    render() {
        const { isLoading } = this.props;

        return (
            <>
                <Grid item xs={12}>
                    {isLoading ? this.renderLoader() : this.renderPhotoList()}
                </Grid>
                {this.renderConfirmationDialog()}
            </>
        );
    }
}

export default compose(
    withGStyles(styles),
    withFullScreen(),
)(PhotoList) as React.ComponentType<Props>;
