import * as React from 'react';
import classNames from 'classnames';
import { values, every, startCase } from 'lodash';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ClearIcon from '@mui/icons-material/Clear';

import {
    ProductCategory,
    ProductCategoryEnum,
    ProductCategoryDisplayLookup,
    isProductCategory,
    ProductTagRecord,
} from '../../shared/types';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    dialogPaper: {
        margin: 'auto',
        width: 300,
        '@media (min-width: 400px)': {
            width: 360
        },
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 16px 24px',
        margin: ' auto',
        textAlign: 'center',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
    paddingLeft10: {
        paddingLeft: 10
    },
    paddingRight10: {
        paddingRight: 10
    },
});

interface Props {
    isDialogOpen: boolean;
    existingTags: ProductTagRecord[];
    defaultCategory?: ProductCategory;
    closeDialog: () => void;
    onCreateNewTag: (tag: ProductTagRecord) => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    category: ProductCategory | null;
    name: string;
    value: string;
    saveClicked: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'dialogContent'
    | 'clearIcon' | 'header' | 'bottomContent' | 'headerText' | 'paddingLeft10' | 'paddingRight10'>;

const INITIAL_STATE: State = {
    category: null,
    name: '',
    value: '',
    saveClicked: false,
};

class CreateNewTag extends React.Component<StyledProps & DialogProps, State> {
    state: State = INITIAL_STATE;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { defaultCategory } = nextProps;
        if (defaultCategory) {
            this.setState({
                category: defaultCategory,
            });
        }
    }

    isTagUnique = () => {
        const { existingTags } = this.props;
        const { category, name, value } = this.state;
        const nameLC = name.toLowerCase();
        const valueLC = value.toLowerCase();

        return every(existingTags, (existing) => {
            return existing.category !== category ||
                existing.name.toLowerCase() !== nameLC ||
                existing.value.toLowerCase() !== valueLC;
        });
    };

    isTagValid = () => {
        const { category, name, value } = this.state;
        return category && name && name === startCase(name) && value && this.isTagUnique();
    };

    saveTag = () => {
        const { onCreateNewTag } = this.props;
        const { category, name, value } = this.state;

        if (category && this.isTagValid()) {
            this.handleClose();
            onCreateNewTag({
                category,
                name,
                value,
            });
        } else {
            this.setState({
                saveClicked: true,
            });
        }
    };

    handleClose = () => {
        const { closeDialog } = this.props;
        closeDialog();
        this.setState(INITIAL_STATE);
    };

    handleCategoryChange = (category: unknown) => {
        if (isProductCategory(category)) {
            this.setState({
                category,
            });
        }
    };

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        this.setState({
            name: target.value
        });
    };

    handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        this.setState({
            value: target.value
        });
    };

    render() {
        const {
            classes,
            isDialogOpen,
            zIndex
        } = this.props;

        const { category, name, value, saveClicked } = this.state;

        const isValueUniqueError = saveClicked && !this.isTagUnique();
        const isValueEmpty = saveClicked && !value;
        const isValueCaseInvalid = saveClicked && value !== startCase(value);

        const isNameEmpty = saveClicked && !name;
        const isNameCaseInvalid = saveClicked && name !== startCase(name);

        return (
            <div className={classes.root}>
                <Dialog
                    open={isDialogOpen}
                    onClose={this.handleClose}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.header,
                            classes.backgroundPrimary
                        )}
                    >
                        <ClearIcon
                            className={classNames(classes.clearIcon, classes.colorWhite)}
                            onClick={this.handleClose}
                        />
                        <Typography
                            component="p"
                            noWrap
                            className={classNames(classes.colorWhite, classes.headerText)}
                        >
                            Create New Tag
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid
                            container
                            className={classes.marginTop20}
                        >
                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(classes.width100, classes.textLeft, classes.marginTop20)}
                                    required
                                    error={saveClicked && !category}
                                >
                                    <InputLabel
                                        htmlFor="categoryListOfOptions"
                                        className="width-100"
                                    >
                                        Category
                                    </InputLabel>
                                    <Select
                                        value={category || ''}
                                        onChange={(e) => this.handleCategoryChange(e.target.value)}
                                        MenuProps={{ style: { zIndex: zIndex + 1 } }}
                                        inputProps={{
                                            name: 'categoryListOfOptions',
                                            id: 'categoryListOfOptions',
                                            className: classNames(!category && 'notranslate')
                                        }}
                                        classes={{ select: classes.themeSelectMenu }}
                                    >
                                        {(values(ProductCategoryEnum)).map((c: ProductCategory) => (
                                            <MenuItem
                                                key={c}
                                                value={c}
                                            >
                                                {ProductCategoryDisplayLookup[c]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} className={classes.paddingRight10}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="Tag"
                                        name="name"
                                        required
                                        type="text"
                                        value={name}
                                        error={isNameEmpty || isNameCaseInvalid}
                                        onChange={this.handleNameChange}
                                    />
                                    <FormHelperText
                                        color="secondary"
                                    >
                                        Example: Color
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} className={classes.paddingRight10}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="Tag Value"
                                        name="value"
                                        required
                                        type="text"
                                        value={value}
                                        error={isValueEmpty || isValueCaseInvalid || isValueUniqueError}
                                        onChange={this.handleValueChange}
                                    />
                                    <FormHelperText
                                        color="secondary"
                                        error={isValueUniqueError}
                                    >
                                        {isValueUniqueError ? 'This value already exists' : 'Example: Blue'}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid
                                className={classes.bottomContent}
                                item
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={this.saveTag}
                                >
                                    SAVE
                                </Button>

                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default withGStyles(styles)(withFullScreen('xs')(CreateNewTag));
