import * as React from 'react';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { DRAWER_WIDTH_DOWN_SM } from '../../../constants';

import CaseSwitcher from '../../cases/caseSwitcher/CaseSwitcher';
import UserAvatar from '../../../components/common/UserAvatar';
import { isViewAsGOM } from '../../../services';
import GLink from '../../common/GLink';
import { AppRoute } from '../../../services';
import { GStyles } from '../../../styles/GStyles';
import useUserSession from '../../common/hooks/useUserSession';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            position: 'fixed',
            marginLeft: DRAWER_WIDTH_DOWN_SM,
            color: '#fff',
        },
        relativeAppBar: {
            position: 'relative',
            margin: 0,
            color: '#fff',
        },
        headerAvatar: {
            float: 'right',
            border: '1px solid #fff',
            // position: 'absolute',
            zIndex: -1,
        },
        toolbar: {
            paddingLeft: 4,
            paddingRight: 8,
            position: 'static',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: 8,
                paddingRight: 16,
            },
            '& button': {
                width: '38px !important',
                height: '38px !important',
            },

            '@media (min-width: 416px)': {
                '& button': {
                    width: '48px !important',
                    height: '48px !important',
                },
            },
        },
        customLink: {
            color: '#fff',
            backgroundColor: 'transparent',
        },
    }),
    { name: 'BaseAppBar' },
);

interface Props {
    children?: React.ReactNode | React.ReactNode[];
    zIndex: number;
    goBackLink: AppRoute;
    position?: 'relative' | 'fixed';
    userAvatarTextSlot?: JSX.Element;
    hideGoBack?: boolean;
    appbarClass?: string;
    toolbarClass?: string;
    goBackCallBack?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const BaseAppBar = (props: Props) => {
    const {
        goBackCallBack,
        goBackLink,
        hideGoBack,
        userAvatarTextSlot,
        position,
        children,
        zIndex,
        appbarClass,
        toolbarClass,
    } = props;

    const classes = useStyles();
    const [caseSwitcherAnchorEle, setCaseSwitcherAnchorEle] = React.useState<HTMLElement | null>(null);

    const userSession = useUserSession();
    const { isUserPhotoSaving, userData } = userSession;

    const viewAsGOM = isViewAsGOM(userSession);

    const toggleCaseSwitcher = (event?: React.MouseEvent<HTMLElement>) => {
        setCaseSwitcherAnchorEle((event && event.currentTarget) || null);
    };

    return (
        <>
            <AppBar
                className={classNames(
                    position && position === 'relative' ? classes.relativeAppBar : classes.root,
                    GStyles.backgroundPrimary,
                    appbarClass,
                )}
                style={{ zIndex }}
            >
                <Toolbar className={classNames(classes.toolbar, toolbarClass)}>
                    {!hideGoBack && (
                        <GLink to={goBackLink} linkClass={classes.customLink} onClick={goBackCallBack}>
                            <IconButton color="inherit" aria-label="go back">
                                <ArrowBack className={GStyles.fontSize32} />
                            </IconButton>
                        </GLink>
                    )}

                    {children}

                    <div
                        className={classNames(
                            GStyles.width100,
                            userAvatarTextSlot !== undefined ? GStyles.textRight : '',
                        )}
                    >
                        {userAvatarTextSlot}
                        <IconButton className={GStyles.pullRight} onClick={toggleCaseSwitcher} size="large">
                            <UserAvatar
                                user={userData}
                                className={classNames(classes.headerAvatar, viewAsGOM && GStyles.b_solid_orange_2)}
                                isLoading={isUserPhotoSaving}
                            />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <CaseSwitcher
                popperAnchorEle={caseSwitcherAnchorEle}
                closePopper={toggleCaseSwitcher}
                zIndex={zIndex + 10}
            />
        </>
    );
};

export default BaseAppBar;
