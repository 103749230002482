import { green } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { WithGStyles } from '../../../../styles/WithGStyles';

export function selfLoginStyles<Props extends object>() {
    const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
        root: {},
        dContentRoot: {
            padding: '0 !important',
            height: '100%'
        },
        dialogPaper: {
            width: '100%',
            height: '100%',
            margin: '0 auto',
            maxWidth: '100%',
            display: 'flex',
            '@media (min-width: 960px)': {
                marginTop: 48,
                borderRadius: 8,
                maxWidth: 1080,
                height: 700,
            },
        },
        step4DialogPaper: {
            width: '100%',
            height: '100%',
            margin: '0 auto',
            maxWidth: '100%',
        },
        clearIcon: {
            filter: 'drop-shadow(0 0px 2px gray)',
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 2,
            fontSize: 36,
            cursor: 'pointer',
            color: theme.palette.common.white,
            '@media (min-width: 960px)': {
                right: 20,
                top: 20,
            },
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            '@media (min-width: 960px)': {
                flexDirection: 'row',
                height: '100%',
            },
        },
        avatarButton: {
            zIndex: 1,
            margin: 0,
            background: theme.palette.common.white,
            padding: 0,
            transition: 'inherit',
            cursor: 'initial',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))',
            '&:hover': {
                backgroundColor: theme.palette.common.white,
            },
            width: 80,
            height: 80,
            '@media (min-width: 768px)': {
                width: 120,
                height: 120,
            },
            '@media (min-width: 960px)': {
                width: 180,
                height: 180,
            },
        },
        avatar: {
            margin: 'auto',
            fontWeight: 200,
            fontSize: 36,
            textTransform: 'uppercase',
            width: 76,
            height: 76,
            '@media (min-width: 768px)': {
                width: 112,
                height: 112,
                fontSize: 52,
            },
            '@media (min-width: 960px)': {
                width: 172,
                height: 172,
                fontSize: 68,
            },
        },
        caseName: {
            fontFamily: "'Great Vibes', cursive !important",
            color: theme.palette.common.white,
            fontSize: 36,
            lineHeight: 1,
            marginTop: 8,
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))',
            '@media (min-width: 500px)': {
                fontSize: 56,
                marginTop: 12,
            },
            '@media (min-width: 768px)': {
                fontSize: 64,
                marginTop: 16,
            },
            '@media (min-width: 960px)': {
                fontSize: 96,
                marginTop: 24,
            },
        },
        lifeSpan: {
            color: theme.palette.common.white,
            lineHeight: 1,
            fontWeight: 400,
            display: 'none',
            fontSize: 28,
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))',
            '@media (min-width: 960px)': {
                display: 'block',
            },
        },
        rightSection: {
            width: '100%',
            height: 'fit-content',
            maxWidth: '100%',
            maxHeight: '100%',
            textAlign: 'center',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundSize: 'cover',
            opacity: 1,
            padding: '12px 0',
            transition:
                'flex-basis 1800ms cubic-bezier(0.4, 0, 0.2, 1), ' +
                'max-width 1800ms cubic-bezier(0.4, 0, 0.2, 1), ' +
                'opacity 1200ms cubic-bezier(0.4, 0, 0.2, 1), ' +
                'max-height 1800ms cubic-bezier(0.4, 0, 0.2, 1)',
            '@media (min-width: 768px)': {
                padding: '24px 0',
            },
            '@media (min-width: 960px)': {
                height: '100%',
                flexGrow: 0,
                maxWidth: '100%',
                flexBasis: '100%',
            },
            '&:not($hasActiveTheme)': {
                background: theme.palette.primary.main,
            },
        },
        textField: {
            '& label': {
                fontStyle: 'italic',
                paddingRight: 2
            },
        },
        leftSection: {
            width: '100%',
            height: '100%',
            padding: '16px 16px 0',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            textAlign: 'center',
            display: 'flex',
            overflowY: 'auto',
            '&$step2': {
                display: 'block',
                '@media (min-width: 360px)': {
                    display: 'flex',
                },
            },
            '@media (min-width: 960px)': {
                padding: '28px 28px 0',
            },
        },
        backArrow: {
            fontSize: 36,
            '@media (min-width: 960px)': {
                fontSize: 56,
            },
        },
        rightSectionbackArrow: {
            fontSize: 44,
            position: 'absolute',
            top: 6,
            left: 8,
            cursor: 'pointer',
            color: theme.palette.common.white,
            filter: 'drop-shadow(0 0px 2px gray)',
            '@media (min-width: 960px)': {
                display: 'none',
            },
        },
        header: {
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 0 0',
            zIndex: 2,
            minHeight: 68,
            background: theme.palette.common.white,
            position: 'relative',
            transition:
                'transform 900ms cubic-bezier(0.4, 0, 0.2, 1), ' +
                'max-width 900ms cubic-bezier(0.4, 0, 0.2, 1), ' +
                'min-width 900ms cubic-bezier(0.4, 0, 0.2, 1), ' +
                'opacity 900ms cubic-bezier(0.4, 0, 0.2, 1)',
            '@media (min-width: 960px)': {
                display: 'flex',
            },
            '& svg': {
                opacity: 0,
                maxWidth: 0,
                transform: 'translateX(-100%)',
                [theme.breakpoints.up(1024)]: {
                    transform: 'translateX(-120%)',
                }
            },
            '& svg, $fhLogoContainer': {
                transition: 'inherit',
            },
            '& $fhLogoContainer': {
                minWidth: 0,
            },
            '& img': {
                display: 'block',
                marginLeft: 'auto',
            },
            '&$hasBackArrow': {
                '& svg': {
                    opacity: 1,
                    maxWidth: '100%',
                },
                '& $fhLogoContainer': {
                    minWidth: 175,
                    transform: 'translateX(25%)',
                    '@media (min-width: 1024px)': {
                        transform: 'translateX(30%)',
                    },
                },
            },
        },
        transitionGroup: {
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 1,
            '@media (min-width: 960px)': {
                height: 'calc(100% - 68px)',
            },
        },
        userDetails: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            maxWidth: 360,
            '@media (min-width: 960px)': {
                justifyContent: 'center',
            },
            '&$step2': {
                maxWidth: 340,
                position: 'relative',
                '@media (min-width: 960px)': {
                    margin: '0 0 80px',
                },
            },
            '&$createAccount': {
                '@media (min-width: 960px)': {
                    justifyContent: 'flex-start !important',
                    overflow: 'hidden',
                },
                '@media (min-width: 1080px)': {
                    marginTop: 40,
                },
            },
        },
        loginText: {
            fontWeight: 500,
            color: theme.palette.primary.main,
            cursor: 'pointer',
            fontSize: 'inherit',
        },
        continueButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            width: '100%',
            paddingBottom: 28,
            marginTop: 40,
            maxWidth: 360,
            height: 'fit-content',
            flexBasis: 'auto',
            '&$step2': {
                '@media (min-width: 960px)': {
                    marginTop: 0,
                },
            },
            '&$step3': {
                '@media (min-width: 960px)': {
                    marginTop: 0,
                },
                '@media (min-width: 1080px)': {
                    marginTop: 40,
                },
                '& p': {
                    '@media (min-width: 500px)': {
                        fontSize: 13,
                    },
                },
            },
        },
        caseDetails: {
            zIndex: 1,
            '@media (min-width: 960px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        userRelationContainer: {
            '@media (min-width: 960px)': {
                marginTop: 96,
            },
        },
        haveAccount: {
            fontSize: 13,
            fontWeight: 300,
            '@media (min-width: 500px)': {
                fontSize: 14,
            },
        },
        leftSectionContainer: {
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
            zIndex: 1,
            flexGrow: 1,
            '@media (min-width: 960px)': {
                maxWidth: 'calc(100% - 625px)',
            },
            '@media (min-width: 1030px)': {
                maxWidth: 'calc(100% - 700px)',
            },
        },
        heading: {
            fontWeight: 300,
            fontSize: 24,
            marginTop: 4,
            marginBottom: 20,
            '@media (min-width: 960px)': {
                fontSize: 28,
                marginBottom: 24,
            },
        },
        acquaintanceContainer: {
            minHeight: 256,
            maxHeight: 256,
        },
        formControl: {
            minHeight: 60,
        },
        lineHeight1: {
            lineHeight: 1,
        },
        passwordInputIcon: {
            width: 32,
            height: 32,
            marginRight: 8,
            '& svg': {
                color: theme.palette.secondary.main,
            },
        },
        gridBtn: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidith: 300,
            margin: 'auto',
            paddingTop: 28,
        },
        btnLogin: {
            float: 'right',
            boxShadow: `0px 1px 5px 0px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 3px 1px -2px rgba(0, 0, 0, 0.12)`,
        },
        btnForgetPw: {
            color: '#b3b3b3',
            float: 'left',
            fontSize: '15px',
            textDecoration: 'none',
            lineHeight: '1.375em',
            '&:hover': {
                backgroundColor: 'white',
                textDecoration: 'underline',
            },
        },
        loginInputField: {
            display: ' grid',
            paddingLeft: 0,
            lineHeight: 1.25,
            textTransform: 'capitalize',
            fontSize: 13,
            '@media (min-width: 500px)': {
                fontSize: 15,
            },
        },
        loginFormControl: {
            minHeight: 68,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
        },
        marginLeft4: {
            marginLeft: 4,
        },
        resetButton: {
            margin: '20px 8px 36px',
        },
        resetPasswordHeading: {
            fontWeight: 300,
            fontSize: 16,
            '@media (min-width: 500px)': {
                marginBottom: 16,
            },
            '@media (min-width: 960px)': {
                fontSize: 18,
                marginBottom: 24,
            },
        },
        height74: {
            height: '74%',
        },
        fhLogo: {
            margin: '20px auto 0',
            '@media (min-width: 960px)': {
                display: 'none',
            },
        },
        loginDetailsContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '&$login': {
                '@media (min-width: 960px)': {
                    width: '100%',
                },
            },
        },
        emailPhoneToggle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16,
            cursor: 'pointer',
            fontSize: 13,
            '& svg': {
                fontSize: 15,
            },
        },
        forgetPwBottomText: {
            lineHeight: 1.25,
            fontWeight: 300,
            fontSize: 13,
            '@media (min-width: 500px)': {
                fontSize: 14,
            },
        },
        margin_32_0: {
            margin: '32px 0',
        },
        marginLeft8: {
            marginLeft: 8,
        },
        submitProgress: {
            color: green[500],
            width: '28px !important',
            height: '28px !important',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -14,
            marginLeft: -14,
        },
        step4: {
            width: '100%',
            height: '100%',
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
        },
        topText: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1,
        },
        helperTopText: {
            fontSize: 24,
            fontWeight: 300,
            '@media (min-width: 600px)': {
                fontSize: 32,
            },
        },

        doneButton: {
            width: '100%',
            maxWidth: 320,
            margin: '60px auto 36px',
            '@media (min-width: 768px)': {
                margin: '80px auto 32px',
            },
        },
        doneButtonContainer: {
            width: '100%',
            display: 'flex',
            marginTop: 'auto',
            flexDirection: 'column',
        },
        step4MiddleSection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
        },
        profileAvatarButton: {
            width: 120,
            height: 120,
            margin: '8px auto',
            overflow: 'hidden',
            '& svg': {
                fontSize: 140,
            },
            '& img': {
                height: 'auto',
                width: '100%',
            },
        },
        profileHeader: {
            margin: '40px 0 12px',
            flexBasis: 'auto',
            '@media (min-width: 500px)': {
                margin: '80px 0 12px',
            },
        },
        uploadProfilePicContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        needOfAccountButton: {
            textTransform: 'none',
            fontSize: 13,
            fontWeight: 400,
            margin: '0 auto 12px',
            padding: '4px 8px',
            minHeight: 32,
            width: 'max-content',
        },
        loginTextField: {
            maxWidth: 'calc(100% - 32px)',
            marginLeft: 8,
        },
        loginErrorText: {
            marginLeft: 32,
        },
        loginForm: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            maxWidth: 280,
            '@media (min-width: 380px)': {
                maxWidth: '100%',
                minWidth: 360,
            },
            '@media (min-width: 960px)': {
                minWidth: '100%',
            },
        },
        resetProgress: {
            width: '28px !important',
            height: '28px !important',
            marginTop: '-14px !important',
        },
        loginButton: {
            textTransform: 'none',
            padding: 0,
            minWidth: 'fit-content',
            minHeight: 'fit-content',
            background: 'none !important',
        },
        loginNowContainer: {
            marginTop: 16,
            '@media (min-width: 500px)': {
                marginTop: 20,
            },
            '& $loginButton': {
                fontSize: 13,
                '@media (min-width: 500px)': {
                    fontSize: 14,
                },
            },
        },
        profilePhotoHeading: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            textAlign: 'center',
            flexBasis: 'unset',
            width: '100%',
            fontWeight: 300,
            padding: '8px 0',
            '& $thanksContributing': {
                fontSize: 16,
                fontWeight: 'inherit',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '@media (min-width: 500px)': {
                    fontSize: 20,
                },
                '& svg': {
                    fontSize: 'inherit',
                },
            },
            '& $messagePreserved': {
                fontSize: 20,
                fontWeight: 'inherit',
                lineHeight: 1,
                '@media (min-width: 500px)': {
                    fontSize: 28,
                },
            },
        },
        greatVibesFont: {
            textTransform: 'capitalize',
            fontFamily: '\'Great Vibes\', cursive !important',
            fontSize: 40,
            margin: '0 6px',
        },
        marginBottom4: {
            marginBottom: 4
        },
        hangingPhotosContainer: {
            background: 'none !important',
            border: 'none !important',
            position: 'relative',
            '&$twoOrMorePhotos > div': {
                height: '640px !important'
            },
            '& > div': {
                height: '540px !important'
            },
            '& .ReactVirtualized__Collection__innerScrollContainer, & .ReactVirtualized__Collection': {
                height: '100% !important'
            }
        },
        checkbox: {
            padding: 4,
            marginRight: 4
        },
        paddingTop8: {
            paddingTop:8
        },
        twoOrMorePhotos: {},
        fhLogoContainer: {},
        hasActiveTheme: {},
        thanksContributing: {},
        messagePreserved: {},
        createAccount: {},
        login: {},
        hasBackArrow: {},
        step2: {},
        step3: {},
    });

    return styles;
}

export type SelfLoginStyledProps = WithGStyles<'root' | 'dContentRoot' | 'dialogPaper' | 'step4DialogPaper'
    | 'dialogContent' | 'clearIcon' | 'avatarButton' | 'avatar' | 'caseName' | 'lifeSpan' | 'rightSection'
    | 'textField' | 'leftSection' | 'backArrow' | 'rightSectionbackArrow' | 'header' | 'transitionGroup'
    | 'userDetails' | 'loginText' | 'continueButtonContainer' | 'caseDetails' | 'userRelationContainer'
    | 'haveAccount' | 'leftSectionContainer' | 'heading' | 'acquaintanceContainer' | 'formControl'
    | 'lineHeight1' | 'passwordInputIcon' | 'gridBtn' | 'btnLogin' | 'btnForgetPw' | 'loginInputField'
    | 'loginFormControl' | 'marginLeft4' | 'resetButton' | 'resetPasswordHeading' | 'height74' | 'fhLogo'
    | 'loginDetailsContainer' | 'emailPhoneToggle' | 'forgetPwBottomText' | 'margin_32_0' | 'marginLeft8'
    | 'submitProgress' | 'step4' | 'topText' | 'helperTopText' | 'doneButton' | 'doneButtonContainer'
    | 'step4MiddleSection' | 'profileAvatarButton' | 'profileHeader' | 'uploadProfilePicContainer'
    | 'needOfAccountButton' | 'loginTextField' | 'loginErrorText' | 'loginForm' | 'resetProgress' | 'loginButton'
    | 'loginNowContainer' | 'profilePhotoHeading' | 'fhLogoContainer' | 'hasActiveTheme' | 'thanksContributing'
    | 'messagePreserved' | 'createAccount' | 'login' | 'hasBackArrow' | 'step2' | 'step3' | 'paddingTop8'
    | 'greatVibesFont' | 'marginBottom4' | 'hangingPhotosContainer' | 'twoOrMorePhotos' | 'checkbox'>;
