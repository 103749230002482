import {
    TaskTemplateUX,
    TaskTemplateCreateRequest,
    TaskTemplateUpdateRequest,
    TaskTemplateSummary,
} from '../../shared/types';
import { postToAPI, deleteFromAPI, patchAPI } from '..';
import { registerAppError } from '../errors';
import { AppDispatch } from '../../store';
import { log } from '../../logger';

// ----> Update Task Template <----
export const UPDATING_TASK_TEMPLATE = 'UPDATING_TASK_TEMPLATE';

interface UpdatingTaskTemplate {
    type: typeof UPDATING_TASK_TEMPLATE;
    task: TaskTemplateSummary;
}

function updatingTaskTemplate(task: TaskTemplateSummary): UpdatingTaskTemplate {
    return {
        type: UPDATING_TASK_TEMPLATE,
        task,
    };
}

export const UPDATED_TASK_TEMPLATE = 'UPDATED_TASK_TEMPLATE';

interface UpdatedTaskTemplate {
    type: typeof UPDATED_TASK_TEMPLATE;
    task: TaskTemplateUX;
}

function updatedTaskTemplate(task: TaskTemplateUX): UpdatedTaskTemplate {
    return {
        type: UPDATED_TASK_TEMPLATE,
        task,
    };
}

export const UPDATING_TASK_TEMPLATE_FAILED = 'UPDATING_TASK_TEMPLATE_FAILED';

interface UpdatingTaskTemplateFailed {
    type: typeof UPDATING_TASK_TEMPLATE_FAILED;
    id: number;
    changesToRollback: Partial<TaskTemplateUX>;
}

function updatingTaskTemplateFailed(
    id: number,
    changesToRollback: Partial<TaskTemplateUX>,
): UpdatingTaskTemplateFailed {
    return {
        type: UPDATING_TASK_TEMPLATE_FAILED,
        id,
        changesToRollback,
    };
}

export function updateTaskTemplate(
    existing: TaskTemplateSummary,
    changes: TaskTemplateUpdateRequest,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<TaskTemplateSummary | null> => {

        try {
            TaskTemplateUpdateRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate TaskTemplateUpdateRequest', { changes, ex });
            return null;
        }

        // get the current values for changed fields to know which ones to rollback on failure
        const existingAttributes: Partial<TaskTemplateSummary> = {};
        for (const key of Object.keys(changes)) {
            existingAttributes[key] = existing[key];
        }

        const optimisticTask: TaskTemplateSummary = {
            ...existing,
            ...changes,
            visible_to_family: changes.visible_to_family !== undefined
                ? changes.visible_to_family || false
                : existing.visible_to_family,
            is_after_care: changes.is_after_care !== undefined
                ? changes.is_after_care || false
                : existing.is_after_care,
        };

        dispatch(updatingTaskTemplate(optimisticTask));
        const route = `funeralhome/${funeralHomeId}/tasktemplate/${existing.id}`;
        const updatedTask = await patchAPI<TaskTemplateUX>(route, { task: changes }, dispatch);
        if (updatedTask) {
            dispatch(updatedTaskTemplate(updatedTask));
            return updatedTask;
        }
        dispatch(updatingTaskTemplateFailed(existing.id, existingAttributes));
        dispatch(registerAppError('Unable to update task.'));
        return null;
    };
}

// ----> Create Task Template <----
export const CREATED_TASK_TEMPLATE = 'CREATED_TASK_TEMPLATE';

interface CreatedTaskTemplate {
    type: typeof CREATED_TASK_TEMPLATE;
    taskTemplate: TaskTemplateUX;
}

function createdTaskTemplate(taskTemplate: TaskTemplateUX): CreatedTaskTemplate {
    return {
        type: CREATED_TASK_TEMPLATE,
        taskTemplate,
    };
}

export function createTaskTemplate(
    task: TaskTemplateCreateRequest,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<TaskTemplateUX | null> => {

        try {
            TaskTemplateCreateRequest.fromRequest(task);
        } catch (ex) {
            log.warn('Failed to validate TaskTemplateCreateRequest:', { task, ex });
            return null;
        }

        const route = `funeralhome/${funeralHomeId}/tasktemplate`;
        const newTaskTemplate = await postToAPI<TaskTemplateUX>(
            route,
            { task },
            dispatch,
        );
        if (newTaskTemplate) {
            dispatch(createdTaskTemplate(newTaskTemplate));
            return newTaskTemplate;
        } else {
            dispatch(registerAppError('Unable to add task.'));
            return null;
        }
    };
}

// ----> Delete Task Template <----
export const DELETING_TASK_TEMPLATE = 'DELETING_TASK_TEMPLATE';

interface DeletingTaskTemplate {
    type: typeof DELETING_TASK_TEMPLATE;
    idToRemove: number;
}

function deletingTaskTemplate(idToRemove: number): DeletingTaskTemplate {
    return {
        type: DELETING_TASK_TEMPLATE,
        idToRemove,
    };
}

export const DELETING_TASK_TEMPLATE_FAILED = 'DELETING_TASK_TEMPLATE_FAILED';

interface DeletingTaskTemplateFailed {
    type: typeof DELETING_TASK_TEMPLATE_FAILED;
    taskToRestore: TaskTemplateSummary;
}

function deletingTaskTemplateFailed(taskToRestore: TaskTemplateSummary): DeletingTaskTemplateFailed {
    return {
        type: DELETING_TASK_TEMPLATE_FAILED,
        taskToRestore,
    };
}

export function deleteTaskTemplate(taskTemplate: TaskTemplateSummary, funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(deletingTaskTemplate(taskTemplate.id));
        const route = `funeralhome/${funeralHomeId}/tasktemplate/${taskTemplate.id}`;
        const deletedTask = await deleteFromAPI<TaskTemplateUX>(route, dispatch);
        if (deletedTask) {
            return deletedTask;
        }
        dispatch(deletingTaskTemplateFailed(taskTemplate));
        dispatch(registerAppError('Unable to delete task.'));
        return null;
    };
}

export type TaskTemplateAction =
    | CreatedTaskTemplate
    | UpdatingTaskTemplate
    | UpdatingTaskTemplateFailed
    | UpdatedTaskTemplate
    | DeletingTaskTemplate
    | DeletingTaskTemplateFailed
    ;
