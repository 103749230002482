import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import ClearIcon from '@mui/icons-material/Clear';

import GDialogCore from './GDialogCore';
import { noop } from 'lodash';
import { log } from '../../logger';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { Breakpoint } from '@mui/material/styles';
import { getIntercomTargetProp } from '../../services';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        '@media (min-width: 600px)': {
            width: '100%',
            maxWidth: '100% !important',
        },
        '& $dialogPaper': {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            '@media (min-width: 600px)': {
                minWidth: 560,
                maxWidth: 560,
                width: 560,
                margin: 32,
                maxHeight: '90vh',
                height: '90vh'
            },
        },
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    content: {
        overflowY: 'auto',
        textAlign: 'center',
    },
    dialogPaper: {},
}), { name: 'GDialogNoTitle' });

interface Props {
    isOpen: boolean;
    zIndex: number;
    paperClass?: string;
    contentClass?: string;
    hideCloseIcon?: boolean;
    closeIconClass?: string;
    backdropClass?: string;
    fullScreenBreakpoint?: number | Breakpoint;
    intercomTargetProp?: string;
    onClose?: () => void;
}

const GDialogNoTitle = (props: PropsWithChildren<Props>) => {
    const {
        onClose,
        isOpen,
        children,
        zIndex,
        paperClass,
        contentClass,
        hideCloseIcon,
        closeIconClass,
        backdropClass,
        fullScreenBreakpoint,
        intercomTargetProp
    } = props;

    const classes = useStyles();

    return (
        <GDialogCore
            isOpen={isOpen}
            onClose={onClose || noop}
            rootClass={classes.root}
            paperClass={classNames(classes.dialogPaper, paperClass)}
            contentClass={classNames(classes.content, contentClass)}
            zIndex={zIndex}
            backdropClass={backdropClass}
            fullScreenBreakpoint={fullScreenBreakpoint}
            onComponentDidMount={() => {
                if (hideCloseIcon && !onClose) {
                    log.warn('GDialogNoTitle: onClose was not supplied to this Component');
                }
            }}
        >
            {!hideCloseIcon &&
                <ClearIcon
                    color="inherit"
                    sx={theme => ({ color: theme.palette.secondary.main })}
                    className={classNames(classes.clearIcon, closeIconClass)}
                    onClick={onClose}
                    {...getIntercomTargetProp(intercomTargetProp)}
                />
            }
            {children}
        </GDialogCore>
    );
};

export default GDialogNoTitle;
