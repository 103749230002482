import * as React from 'react';
import classNames from 'classnames';

import { CloudinaryTransformationsType, DeepLinkUX, FH_LogoType } from '../../shared/types';

import { getIntercomTargetProp, getPhotoUrl } from '../../services';
import { GATHER_LOGO } from '../../constants';

import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import { WhiteLabel } from '../../types';

const useStyles = makeStyles(
    {
        smallLogo: {
            maxWidth: 175,
            maxHeight: 50,
        },
        largeLogo: {
            maxWidth: 320,
            maxHeight: 92,
        },
        white: {
            color: 'white',
        },
    },
    { name: 'FuneralHomeLogoPure' },
);

type LogoSizeType = 'large' | 'small' | 'custom';

interface PopperProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export type WhiteLabelForFHLogo = Pick<
    WhiteLabel,
    'logo' | 'theme_logo' | 'logo_transformations' | 'theme_logo_transformations'
>;

export interface FuneralHomeLogoPureProps extends PopperProps {
    className?: string;
    logoSize?: LogoSizeType;
    style?: {
        width?: string | number;
        height?: string | number;
        maxWidth: number;
        maxHeight: number;
        display?: 'flex' | 'none';
        objectFit?: 'cover' | 'contain';
        objectPosition?: string;
    };
    forcePNG?: boolean;
    logoType?: FH_LogoType;
    toolTipTitle?: string;
    toolTipDelay?: number;
    intercomTargetProp?: string;
    whiteLabel: WhiteLabelForFHLogo;
    deepLink: DeepLinkUX | null;
    isPhotoSaving?: boolean;
    funeralHomeName: string | null;
    ignoreTranformationsHeight?: boolean;
}

const FuneralHomeLogoPure = (props: FuneralHomeLogoPureProps) => {
    const {
        className,
        logoSize = 'small',
        style,
        forcePNG,
        onClick,
        toolTipTitle,
        toolTipDelay,
        intercomTargetProp,
        whiteLabel,
        deepLink,
        isPhotoSaving,
        funeralHomeName,
        ignoreTranformationsHeight,
    } = props;

    const classes = useStyles();

    const logoType = props.logoType ? props.logoType : FH_LogoType.defaultLogo;
    const { logo_transformations, theme_logo_transformations } = whiteLabel;

    let imageLogo = whiteLabel.logo;
    if (logoType === FH_LogoType.themeLogo && whiteLabel.theme_logo && whiteLabel.theme_logo !== GATHER_LOGO) {
        imageLogo = whiteLabel.theme_logo;
    }

    let logo = imageLogo || GATHER_LOGO;
    const imgWidth = (style && style.maxWidth) || (logoSize === 'small' ? 175 : 320);
    const imgHeight = (style && style.maxHeight) || (logoSize === 'small' ? 50 : 92);

    const extraTransformations: CloudinaryTransformationsType[] = [
        {
            crop: 'limit',
            width: imgWidth,
            height: ignoreTranformationsHeight ? undefined : imgHeight,
            quality: 'auto',
            fetch_format: 'auto',
        },
    ];

    if (forcePNG) {
        extraTransformations[0].fetch_format = 'png';
    }

    let transformations: CloudinaryTransformationsType[] = extraTransformations;

    // if there is a deepLink and the logo is set to default Gather Logo then check deeplink logo
    if (logo === GATHER_LOGO && deepLink) {
        if (deepLink.fhLogo) {
            logo = deepLink.fhLogo;
        }
        if (deepLink.fhLogoTransformations) {
            try {
                const logoTransformations = JSON.parse(deepLink.fhLogoTransformations);
                transformations = [logoTransformations, ...extraTransformations];
            } catch (e) {
                /* ignore SyntaxError on JSON.parse() */
            }
        }
    } else if (logo_transformations && logo_transformations.cloudinary && logoType === FH_LogoType.defaultLogo) {
        transformations = [logo_transformations.cloudinary, ...extraTransformations];
    } else if (
        theme_logo_transformations &&
        theme_logo_transformations.cloudinary &&
        logoType === FH_LogoType.themeLogo
    ) {
        transformations = [theme_logo_transformations.cloudinary, ...extraTransformations];
    }

    let sizeClassName = logoSize === 'small' ? classes.smallLogo : classes.largeLogo;

    if (isPhotoSaving) {
        const colorClasses = logoType === FH_LogoType.defaultLogo ? {} : { colorPrimary: classes.white };
        return <CircularProgress color="primary" classes={colorClasses} size={36} />;
    }

    return (
        <Tooltip title={toolTipTitle} enterDelay={toolTipDelay} placement="top">
            <img
                alt={`${funeralHomeName || 'funeral home'} logo`}
                onClick={onClick}
                src={getPhotoUrl(logo, transformations)}
                className={className ? classNames(sizeClassName, logoSize !== 'custom' && className) : sizeClassName}
                style={style}
                {...getIntercomTargetProp(intercomTargetProp)}
            />
        </Tooltip>
    );
};

export default FuneralHomeLogoPure;
