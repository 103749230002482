import { ReactNode, useState } from "react";
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import { useBulkEditStyles } from "./BulkEditStyles";
import BulkEditBaseContainer from "./BulkEditBaseContainer";
import { FormControl, TextField } from "@mui/material";
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductUX } from '../../../../../shared/types';
import { useGDispatch } from '../../../../../types';

interface EditUnitsProps {
    selectButton: BulkEditButtonType | null;
    children?: ReactNode | ReactNode[];
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const EditUnits = (props: EditUnitsProps) => {
    const {
        selectButton,
        onSubmitClick,
        selectedProductIds,
        funeralHomeId,
    } = props;
    const classes = useBulkEditStyles();
    const dispatch = useGDispatch();

    const [inputText, setInputText] = useState('');
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitClick = async () => {
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.edit_unit,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: { var_increment_units: inputText },
        }));

        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <BulkEditBaseContainer
            disableSubmitButton={!inputText.trim()}
            onSubmitClick={handleSubmitClick}
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            isSpinning={isSpinning}
        >
            <FormControl className={classes.selectMenuForm}>
                <TextField
                    fullWidth
                    onChange={(evt) => setInputText(evt.target.value.trim())}
                    autoComplete="off"
                    label="Units"
                    value={inputText}
                />
            </FormControl>
        </BulkEditBaseContainer>
    );
};

export default EditUnits;