import { Component } from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';

import classNames from 'classnames';
import { APP_SECONDARY_COLOR, ORANGE_COLOR, GREEN_COLOR, RED_COLOR } from '../../../constants/colorVariables';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { AppRoute } from '../../../services';
import GLink from '../../common/GLink';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    documentCounterWidget: {
        background: 'transparent',
        // boxShadow: 'inset 0px 0px 6px 0px rgba(0,0,0,0.2)',
        boxShadow: 'none',
        position: 'relative',
        '& $label': {
            fontSize: 22,
            fontWeight: 200,
            lineHeight: '1.25em'
        },
        '& $count': {
            fontSize: 64,
            fontWeight: 200,
            lineHeight: '1.1em'
        },
        '& $inner': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        '& button': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '6px 0',
        },
        '& a': {
            width: '100%',
            height: '100%',
            color: 'inherit',
            textDecoration: 'none'
        }
    },
    fabExtended: {
        width: 'max-content',
        margin: 'auto',
        textTransform: 'none',
        fontWeight: 400,
        fontSize: 10,
        padding: '2px 16px',
        borderRadius: 24,
        minHeight: 'auto',
        border: '1px solid',
        lineHeight: '1.15em'
    },
    colorGrayChip: {
        color: APP_SECONDARY_COLOR,
    },
    colorOrangeChip: {
        color: ORANGE_COLOR,
    },
    colorGreenChip: {
        color: GREEN_COLOR,
    },
    moreIcon: {
        position: 'absolute',
        right: 4,
        top: 4,
        width: 24,
        height: 24,
        zIndex: 1,
        '& svg': {
            fontSize: 16
        }
    },
    menu: {
        zIndex: 1330,
    },
    paper: {
        overflow: 'auto'
    },
    deleteMenuItem: {
        color: RED_COLOR,
        '& svg': {
            color: RED_COLOR,
        }
    },
    flexCentredCounter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px 0px !important'
    },
    statusText: {
        color: 'inherit',
        fontSize: 10,
        lineHeight: '1.15em'
    },
    count: {},
    label: {},
    inner: {},
});

type StyledProps = Props & WithGStyles<'root' | 'documentCounterWidget' | 'count' | 'inner'
    | 'label' | 'fabExtended' | 'colorGrayChip' | 'colorOrangeChip' | 'colorGreenChip' | 'moreIcon' | 'menu'
    | 'paper' | 'deleteMenuItem' | 'flexCentredCounter' | 'statusText'>;

interface Props {
    docCount: number;
    link?: AppRoute;
    statusDisplay: string;
    status: string;
    zIndex: number;
    isGOM: boolean;
    isDeleteConfirmationDialogOpen: boolean;
    setDeleteConfirmationDialogOpen: (setToggle?: boolean) => void;
    menuAnchorEle: HTMLElement | null;
    setMenuAnchorEle: (menuAnchorEle: HTMLElement | null) => void;
    disabled?: boolean;
}

class DocumentCounter extends Component<StyledProps> {

    renderDocCounter = () => {
        const { classes, status, docCount, statusDisplay } = this.props;
        return (
            <>
                <Typography color="primary" className={classes.count}>
                    {docCount}
                </Typography>

                <div className={classes.inner}>
                    <Typography color="secondary" className={classes.label}>
                        {docCount > 1 && 'Documents' || 'Document'}
                    </Typography>
                    <div
                        className={classNames(
                            classes.fabExtended,
                            status === 'started' && classes.colorGrayChip,
                            (status === 'sent' || status === 'printed') && classes.colorOrangeChip,
                            status === 'signed' && classes.colorGreenChip)
                        }
                    >
                        <Typography className={classes.statusText}>
                            {statusDisplay}
                        </Typography>
                    </div>
                </div>
            </>
        );
    };

    render() {
        const { classes, link, setMenuAnchorEle, disabled } = this.props;

        return (
            <Card className={classes.documentCounterWidget}>
                {!disabled
                    && <IconButton
                        className={classes.moreIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMenuAnchorEle(e.currentTarget);
                        }}
                        size="large">
                        <MoreVertIcon />
                    </IconButton>}
                {link ?
                    <GLink to={link}>
                        <CardActionArea>
                            {this.renderDocCounter()}
                        </CardActionArea>
                    </GLink>
                    :
                    <Grid xs={12} item className={classes.flexCentredCounter}>
                        {this.renderDocCounter()}
                    </Grid>
                }
            </Card>
        );
    }

}

export default withGStyles(styles)(DocumentCounter);
