import { useEffect, useMemo } from 'react';

import makeGStyles from '../../../../../../styles/makeGStyles';
import { alpha } from '@mui/material/styles';

import Typography from '@mui/material/Typography';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useGDispatch, useGSelector } from '../../../../../../types';
import { DocCategory, FileUploadConfig, UserRoles, DocRecord } from '../../../../../../shared/types';
import {
    createCaseDocs,
    downloadCaseDoc,
    removeCaseDoc,
    updateCaseDoc,
} from '../../../../../../actions/Doc.action';
import StepComponentWrapper from '../StepComponentWrapper';
import { OnValidityChangedFunction } from './types';
import DocItemList from '../../../../../documentLibrary/widgets/DocItemList';
import { DocType } from '../../../../../documentLibrary/DocPreview.dialog';
import { downloadFromURL } from '../../../../../../services/doc.service';
import LoadingSpinner from '../../../../../common/LoadingSpinner';
import { AcceptFileType } from '../../../../../common/FileUploader';
import UploadButton from '../../../../../common/UploadButton';
import { HiddenDocMenuItems } from '../../../../../documentLibrary/widgets/DocItemList/DocItemMenu';
import { useFuneralHomeId } from '../../../../../common/hooks/useFuneralHomeId';

const FILE_TYPES = [
    AcceptFileType.PNG,
    AcceptFileType.JPEG,
    AcceptFileType.GIF,
    AcceptFileType.DOCUMENT,
    AcceptFileType.PDF,
    AcceptFileType.MSWORD,
];

const useStyles = makeGStyles(theme => ({
    uploadContainer: {
        width: '100%',
        marginBottom: 52,
    },
    uploadCopyText: {
        fontSize: 12,
        fontWeight: 400,
        padding: '0 20px 8px',
    },
    uploadSection: {
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        borderRadius: '0 0 8px 8px',
        background: alpha(theme.palette.primary.main, 0.20),
        '& svg': {
            fontSize: 16,
            marginRight: 4
        },
    },
    uploadBtnContainer: {
        width: '100%',
        paddingBottom: 10,
        textAlign: 'center',
        '& button': {
            margin: 'auto',
        },
    },
    docItemListRoot: {
        '& button[aria-label="More"]': {
            right: 0,
            top: 0,
        }
    },
    uploadButton: {
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'center',
    }
}), { name: 'UploadDocsComponent' });
interface Props {
    config: FileUploadConfig | null;
    componentId: number;
    caseUuid: string | null; // null case is for Back Office preview
    userId: number;
    zIndex: number;
    saveAttempted: boolean;
    isStepCompleted: boolean;
    onValidityChanged: OnValidityChangedFunction;
}

const UploadDocsComponent = (props: Props) => {
    const {
        zIndex,
        saveAttempted,
        componentId,
        userId,
        caseUuid,
        config,
        isStepCompleted,
        onValidityChanged,
    } = props;

    const isRequired = config?.is_required;
    const instructions = config?.instructions;

    const classes = useStyles();

    const dispatch = useGDispatch();
    const funeralHomeId = useFuneralHomeId();
    const isGOMUser = useGSelector(({ userSession }) => UserRoles.isGOMUser(userSession.userData));
    const caseDocsForComponent = useGSelector(({ caseDocState }) => {
        return caseDocState.docs.filter((doc) => {
            return doc.doc?.case_file_task_component_id === componentId || doc.taskComponentId === componentId;
        });
    });
    const docsLoading = useGSelector(({ caseDocState }) => caseDocState.isLoading);

    const isValid = useMemo(() => {
        return !isRequired || caseDocsForComponent.length > 0;
    }, [isRequired, caseDocsForComponent]);

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    if (!funeralHomeId) {
        return null;
    }

    const handleFileUpload = async (files: FileList | null) => {

        if (caseUuid !== null) {
            dispatch(createCaseDocs({
                files,
                userId,
                caseUuid,
                docCategory: DocCategory.general,
                taskComponentId: componentId,
            }));
        }
    };

    const handleDownload = async (docId: number) => {

        if (caseUuid === null) {
            return;
        }

        const downloadDocResult = await dispatch(downloadCaseDoc(caseUuid, docId));
        if (!downloadDocResult) {
            return;
        }
        downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
    };

    const handleRemoveDoc = (docId: number) => {
        if (caseUuid !== null) {
            dispatch(removeCaseDoc(caseUuid, docId));
        }
    };

    const handleUpdateDoc = async (targetDocId: number, updateDocRecord: Partial<DocRecord>) => {
        if (caseUuid !== null) {
            await dispatch(updateCaseDoc(targetDocId, caseUuid, updateDocRecord));
        }
    };

    return (
        <div className={classes.uploadContainer}>
            <StepComponentWrapper
                error={saveAttempted && !isValid}
                success={isValid && isRequired}
                heading={`Upload (${caseDocsForComponent.length})${isRequired ? ' *' : ''}`}
                className={classes.paddingTop15}
            >
                {docsLoading && <LoadingSpinner />}
                {!docsLoading && instructions &&
                    <Typography
                        align="center"
                        color="secondary"
                        className={classes.uploadCopyText}
                    >
                        {instructions}
                    </Typography>
                }

                <DocItemList
                    docs={caseDocsForComponent}
                    zIndex={zIndex + 1}
                    isCompactView={true}
                    isGOMUser={isGOMUser}
                    activeFuneralHomeId={funeralHomeId}
                    docsType={DocType.Case}
                    getHiddenMenuItems={isStepCompleted
                        ? () => [HiddenDocMenuItems.delete, HiddenDocMenuItems.rename]
                        : undefined}
                    rootClass={classes.docItemListRoot}
                    downloadDoc={handleDownload}
                    deleteDoc={handleRemoveDoc}
                    renameDoc={(docId, label) => handleUpdateDoc(docId, { label })}
                />

                {!isStepCompleted &&
                    <div className={classes.uploadButton}>
                        <UploadButton
                            handleUpload={handleFileUpload}
                            acceptFileTypes={FILE_TYPES}
                            buttonText="Upload new file"
                        />
                    </div>
                }

                <Typography color="primary" className={classes.uploadSection}>
                    <VisibilityOffIcon />Never visible to family. Will show in case files.
                </Typography>
            </StepComponentWrapper >
        </div >
    );
};

export default UploadDocsComponent;
