import * as React from 'react';
import classNames from 'classnames';

import { compose } from 'redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Clear from '@mui/icons-material/Clear';

import { UsStateType } from '../../../types';

import { US_STATES } from '../../../shared/locale';
import { GRAY_COLOR_3 } from '../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { SlideTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

interface Props {
    zIndex: number;
    isDialogOpen: boolean;
    closeDialog: () => void;
    existingFilingState: string | null;
    saveFilingState: (filingState: string) => void;
}

interface State {
    filingState: string;
    usStateList: UsStateType[];
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    filingStateDialog: {
        minWidth: '290px',
        '& $dialogPaper': {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            '@media (min-width: 960px)': {
                maxWidth: 720,
                margin: 32,
                maxHeight: '90vh',
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 14,
        '& h2': {
            height: '29px',
            marginTop: '0px',
        },
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#5B6E74',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 24px',
        width: '255px',
        margin: ' auto',
        '@media (min-width: 400px)': {
            width: '335px',
        },
        '@media (min-width: 958px)': {
            width: '600px',
            margin: 'auto',
        },
        textAlign: 'center',
        borderTop: `1px solid ${GRAY_COLOR_3}`,
    },
    dialogFooter: {
        display: 'block',
        padding: 8,
        margin: 0,
        boxShadow: '0px -1px 10px 1px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
    },
    formOuterFilinig: {
        margin: 'auto',
        paddingLeft: 12,
        paddingRight: 12,

    },
});

type StyledProps = Props & WithGStyles<'root' | 'filingStateDialog' | 'dialogPaper' | 'dialogHeader'
    | 'clearIcon' | 'dialogContent' | 'dialogFooter' | 'formOuterFilinig'>;

class ChangeFilingStateDialog extends React.Component<StyledProps & Props, State> {
    constructor(props: StyledProps & Props) {
        super(props);
        this.state = {
            filingState: '',
            usStateList: US_STATES,
        };
    }

    handleClose = () => {
        this.setState({
            filingState: '',
            usStateList: US_STATES,
        });

        this.props.closeDialog();
    };

    saveChanges = () => {
        const { saveFilingState, closeDialog } = this.props;
        const { filingState } = this.state;

        saveFilingState(filingState);
        closeDialog();
    };

    render() {
        const { classes, isDialogOpen, existingFilingState, zIndex } = this.props;
        const { usStateList, filingState } = this.state;

        const headerTitle = 'Adjust Filing State';

        return (
            <Dialog
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.filingStateDialog}
                classes={{
                    paper: classes.dialogPaper
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.dialogHeader}
                >
                    <Clear
                        color="secondary"
                        className={classes.clearIcon}
                        onClick={this.handleClose}
                    />
                    <Typography
                        color="secondary"
                        component="p"
                        align="left"
                        className={classes.fontSize20}
                    >
                        <span className={classes.textCapitalize}> {headerTitle}</span>
                    </Typography>
                </DialogTitle>
                <DialogContent className={classNames(classes.dialogContent)}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className={classNames(classes.formOuterFilinig, classes.overflowHidden)}
                    >
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.textCenter,
                                classes.marginTop10,
                                classes.fontSize18
                            )}
                        >
                            FROM THE CDC
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.textLeft,
                                classes.marginTop10,
                                classes.fontSize16,
                            )}
                        >
                            If the place of death is unknown but the body is found in your State,
                            the certificate of death should be completed and filed in accordance with the
                            laws of your State.
                        </Typography>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.textLeft,
                                classes.marginTop10,
                                classes.fontSize16,
                            )}
                        >
                            If the death occurred on a moving conveyance in the United States and the body was
                            first removed from the conveyance in this State, complete a death certificate, and enter
                            as the place of death address where the body was first removed from the conveyance.

                        </Typography>

                        <form autoComplete="off">
                            <FormControl
                                className={classNames(
                                    classes.width100,
                                    classes.textLeft,
                                    classes.marginBottom40,
                                    classes.marginTop10,
                                )}
                            >
                                <InputLabel
                                    htmlFor="filingState"
                                    className="width-100"
                                    variant="standard"
                                >
                                    Filing State
                                </InputLabel>
                                <NativeSelect
                                    value={!filingState && existingFilingState ? existingFilingState : filingState}
                                    name="filingState"
                                    onChange={(e) => this.setState({ filingState: e.target.value })}
                                    classes={{ select: classes.backgroundTransparent }}
                                    autoComplete="new-password"
                                >
                                    <option value="" />
                                    {usStateList.map
                                        (
                                            name => {
                                                return (
                                                    <option key={name.abbreviation} value={name.abbreviation}>
                                                        {name.name}
                                                    </option>
                                                );
                                            },
                                            this
                                        )
                                    }
                                </NativeSelect>
                            </FormControl>
                        </form>
                    </Grid>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialogFooter
                    }}
                >
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.pullRight}
                        onClick={this.saveChanges}
                    >
                        SAVE
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

export default compose(
    withGStyles(styles),
    withFullScreen(),
)(ChangeFilingStateDialog) as React.ComponentType<Props>;
