import classNames from "classnames";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";

import IncreaseDecreaseTextField from "../goodsAndServices/widgets/IncreaseDecreaseTextField";
import makeGStyles from "../../../styles/makeGStyles";
import { GREEN_COLOR } from "../../../constants/colorVariables";
import QRStickersList, { QRStickersRenderItemParams } from "./QRStickers.list";
import { useCallback } from "react";
import { StickerType } from "./PrintQRStickers.dialog";

const useStyles = makeGStyles(theme => ({
    backgroundGreen60: {
        background: `${alpha(GREEN_COLOR, 0.6)} !important`
    },
    itemBtn: {
        padding: '8px 0',
        fontWeight: 400,
        minHeight: 52,
        minWidth: '100%',
        border: `1px solid ${alpha('#000', 0.6)} !important`,
        boxShadow: 'none !important',
        [theme.breakpoints.down(425)]: {
            fontSize: 12,
        },
        '&$backgroundGreen': {
            background: `${GREEN_COLOR} !important`
        }
    },
    paper: {
        marginTop: 12,
        padding: '16px 8px'
    },
    cremainsStickerBtn: {
        minHeight: 80,
        aspectRatio: '2.2',
        [theme.breakpoints.up(400)]: {
            minHeight: 120
        },
        '@supports (aspect-ratio: 2/1)': {
            minHeight: 'unset'
        }
    }
}), { name: 'Step2' });

interface Props {
    count: number;
    startingRow: number;
    startingColumn: number;
    stickerType: StickerType;
    onStartingPointChange: (row: number, column: number) => void;
    onCountChange: (count: number) => void;
}

const Step2 = (props: Props) => {
    const {
        count,
        startingColumn,
        startingRow,
        stickerType,
        onStartingPointChange,
        onCountChange
    } = props;

    const classes = useStyles();
    const is2160Selected = stickerType === StickerType.QR_2160;
    const is5160or2160Selected = stickerType === StickerType.QR_5160 || is2160Selected;

    const renderItem = useCallback(({
        currentColumn,
        currentRow,
        included,
        isNextColumn,
        isNextRow
    }: QRStickersRenderItemParams) => (
        <Button
            fullWidth
            size="large"
            color="secondary"
            variant={included ? 'contained' : 'outlined'}
            onClick={() => onStartingPointChange(currentRow, currentColumn)}
            sx={{ mt: is2160Selected && currentRow !== 0 && currentRow % 4 === 0 ? 4 : undefined }}
            className={classNames(
                classes.itemBtn,
                classes.flexCentred,
                !is5160or2160Selected && classes.cremainsStickerBtn,
                included
                    ? ((isNextRow || isNextColumn) ? classes.backgroundGreen60 : classes.backgroundGreen)
                    : undefined
            )}
        >
            {startingRow === currentRow
                && startingColumn === currentColumn
                && 'Start Here'}
        </Button>
    ), [
        classes.cremainsStickerBtn,
        classes.backgroundGreen,
        classes.backgroundGreen60,
        classes.flexCentred,
        classes.itemBtn,
        is2160Selected,
        is5160or2160Selected,
        onStartingPointChange,
        startingColumn,
        startingRow,
    ]);

    let maxCount: number | undefined = undefined;
    let columns: number = 1;
    if (is2160Selected) {
        maxCount = 17;
        columns = 1;
    } else if (stickerType === StickerType.QR_5160) {
        maxCount = 61;
        columns = 3;
    } else if (stickerType === StickerType.CREMAINS) {
        maxCount = 21;
        columns = 2;
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography color="secondary" align="center" className={classes.fontSize18}>
                    How many stickers would you like to print?
                </Typography>

                <IncreaseDecreaseTextField
                    min={1}
                    max={maxCount}
                    helperText="Stickers"
                    counter={count}
                    onCounterChange={onCountChange}
                />
            </Grid>

            <Grid item xs={12} mt={4}>
                <Typography
                    align="center"
                    color="secondary"
                    className={classNames(classes.fontSize20, classes.paragraphLineHeight)}
                >
                    Select starting point below
                </Typography>

                <Typography
                    align="center"
                    color="secondary"
                    className={classNames(classes.paragraphLineHeight, classes.fontSize12)}
                >
                    This allows you to print from a partial sheet of stickers
                </Typography>

                <Paper elevation={4} className={classes.paper}>
                    <Grid
                        container
                        justifyContent={stickerType === StickerType.QR_2160 ? 'center' : undefined}
                        columnSpacing={1}
                    >
                        <QRStickersList
                            stickerType={stickerType}
                            columns={columns}
                            startingColumn={startingColumn}
                            startingRow={startingRow}
                            count={count}
                            renderItem={renderItem}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};

export default Step2;