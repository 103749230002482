import * as classNames from 'classnames';

import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import FingerprintIcon from '@mui/icons-material/Fingerprint';

import makeGStyles from '../../../../../../../styles/makeGStyles';
import {
    CaseFingerprintUX,
    FingerPrintDisplay,
    FingerprintType,
    FingerprintUX
} from '../../../../../../../shared/types';
import FingerprintImage from '../../../editFingerprintDialog/FingerprintImage';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';
import Tooltip from '@mui/material/Tooltip';
import { getIntercomTargetProp } from '../../../../../../../services';

const useStyles = makeGStyles(theme => ({
    root: {
        width: '100%',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        padding: 0,
        alignItems: 'unset'
    },
    imageContainer: {
        width: '100%',
        minHeight: 180,
        maxHeight: 180,
        borderWidth: 1,
        borderStyle: 'dashed',
        color: theme.palette.secondary.main,
        borderRadius: 24,
        padding: '4px 0',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        '&$btnLabel': {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '100%'
        },
        '&$hasImage': {
            borderStyle: 'solid',
            padding: 0
        }
    },
    fingerprintIcon: {
        fontSize: 148,
        width: '100%'
    },
    btnText: {
        marginTop: -8,
        whiteSpace: 'nowrap'
    },
    printType: {
        margin: 'auto',
        maxWidth: '85%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    spinner: {
        marginTop: 20
    },
    btnLabel: {},
    hasImage: {},
}), { name: 'CaseFingerprintItem' });

interface Props {
    item: CaseFingerprintUX | null;
    disabled?: boolean;
    btnTextClass?: string;
    onClick?: (item: FingerprintUX | null) => void;
    intercomTargetPropPrintItem?: string;
}
const CaseFingerprintItem = (props: Props) => {
    const { item, btnTextClass, disabled, onClick, intercomTargetPropPrintItem } = props;
    const classes = useStyles();

    const type = item && item.fingerprint ? item.fingerprint.type : FingerprintType.fingerprint;

    const fingerprintItem = item && item.fingerprint;
    const displayLoader = item && !item.fingerprint;
    const displayEmpty = !item;

    return (
        <Tooltip
            placement="top"
            title={disabled
                ? `Fingerprints can't be edited from this view. You can edit them from the "Prints" tab.`
                : ''}
            enterDelay={400}
        >
            <ListItem className={classes.root} disableGutters>
                <Typography align="center" color="secondary" className={classes.printType}>
                    {FingerPrintDisplay[type]}
                </Typography>

                <Button
                    color="secondary"
                    className={classNames(
                        classes.imageContainer,
                        item && classes.hasImage,
                        displayLoader && classes.flexCentred
                    )}
                    disabled={disabled || !onClick}
                    onClick={e => !disabled && onClick?.(fingerprintItem)}
                    {...getIntercomTargetProp(intercomTargetPropPrintItem)}
                >
                    <span className={classes.btnLabel}>
                        {fingerprintItem && <FingerprintImage s3DownloadURL={fingerprintItem.photo_url} />}
                        {displayLoader && <LoadingSpinner containerClass={classes.spinner} />}
                        {displayEmpty &&
                            <>
                                <FingerprintIcon color="secondary" className={classes.fingerprintIcon} />
                                <Typography color="secondary" className={classNames(classes.btnText, btnTextClass)}>
                                    Tap to add photo
                                </Typography>
                            </>
                        }
                    </span>
                </Button>
            </ListItem>
        </Tooltip>
    );
};

export default CaseFingerprintItem;
