import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';

import CallSplitIcon from '@mui/icons-material/CallSplit';

import { convertHexToRGBA } from '../../../../../services';
import { formatDinero } from '../../../../../shared/goods_and_services/pricing';
import Dinero from 'dinero.js';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        transition: 'all 1s',
        transform: 'translateY(100%) scale(0)',
        opacity: 0,
        height: 0,
        margin: 0,
        '&$show': {
            height: 192,
            margin: '24px auto',
            opacity: 1,
            transform: 'translateY(0) scale(1)'
        },
        '& p': {
            textAlign: 'center',
            '&:first-of-type': {
                marginBottom: 16,
            }
        },
        '& $boxes': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& $box': {
                margin: '0 8px',
                borderRadius: 4,
                color: theme.palette.primary.main,
                border: '1px solid',
                '&:hover': {
                    boxShadow: theme.shadows[10],
                },
                '& button': {
                    height: 148,
                    width: 148,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                },
                '& $label': {
                    fontSize: 16,
                    lineHeight: '1.25em',
                    textTransform: 'capitalize',
                    margin: '8px 0 0'
                },
                '& $amount': {
                    fontSize: 12,
                    fontWeight: 200,
                    lineHeight: '1em',
                    margin: '0 4px 4px',
                },
                '& $pieContainer': {
                    marginTop: 4,
                    height: 74,
                    width: 74,
                    position: 'relative',
                    '& $overlay': {
                        position: 'absolute',
                        width: 74,
                        height: 74,
                        borderRadius: '100%',
                        clip: 'rect(0px, 74px, 74px, 37px)',
                        '& $pie': {
                            clip: 'rect(0px, 37px, 74px, 0px)',
                        },
                    },
                    '& $pie': {
                        position: 'absolute',
                        width: 74,
                        height: 74,
                        borderRadius: '100%',
                        background: `${convertHexToRGBA(theme.palette.primary.main, 0.3)}`,
                        clip: 'rect(0px, 74px, 74px, 0px)',
                        '&$split_1': {
                            background: theme.palette.primary.main,
                        },
                        '&$split_2': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(180deg)'
                        },
                        '&$split_3': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(120deg)'
                        },
                        '&$split_4': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(90deg)'
                        },
                        '&$split_5': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(72deg)'
                        },
                        '&$split_6': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(60deg)'
                        },
                        '&$split_7': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(52deg)'
                        },
                        '&$split_8': {
                            backgroundColor: theme.palette.primary.main,
                            transform: 'rotate(45deg)'
                        },
                    }
                },
                '&$splitBox': {
                    '& svg': {
                        fontSize: 48,
                    },
                    '& $label': {
                        margin: 0
                    },
                }
            }
        }
    },
    heading: {
        fontSize: 18,
        fontWeight: 200
    },
    boxes: {},
    splitBox: {},
    pieContainer: {},
    overlay: {},
    pie: {},
    box: {},
    split_1: {},
    split_2: {},
    split_3: {},
    split_4: {},
    split_5: {},
    split_6: {},
    split_7: {},
    split_8: {},
    label: {},
    amount: {},
    show: {}
});

type StyledProps = Props & WithGStyles<'root' | 'pieContainer' | 'pie' | 'overlay' | 'box' | 'split_1' | 'split_2'
    | 'split_3' | 'split_4' | 'split_5' | 'split_6' | 'split_7' | 'split_8'
    | 'label' | 'amount' | 'splitBox' | 'boxes' | 'heading' | 'show'>;

interface Props {
    payerCount: number;
    price: Dinero.Dinero;
    onClick: (payerCount: number, split: number) => void;
    isHidden: boolean;
}

interface State {
    disabled: boolean;
}

interface PaymentType {
    label: string;
    amount: Dinero.Dinero;
    className: string;
}

export class SplitBill extends Component<StyledProps, State> {
    state: State = { disabled: false };

    onClick = (payerCount: number, split: number) => {
        this.setState({ disabled: true });
        this.props.onClick(payerCount, split);
    };

    renderSplitBox = () => {
        const { disabled } = this.state;
        const { classes, payerCount } = this.props;

        return (
            <Card className={classNames(classes.box, classes.splitBox)}>
                <CardActionArea onClick={e => this.onClick(payerCount, 0)} disabled={disabled}>
                    <CallSplitIcon color="primary" />
                    <Typography color="primary" className={classes.label}>
                        Custom Split
                    </Typography>
                </CardActionArea>
            </Card>
        );
    };

    renderPieBox = (splitFraction: PaymentType) => {
        const { disabled } = this.state;
        const { classes, isHidden, payerCount } = this.props;

        return (
            <Card className={classes.box}>
                <CardActionArea onClick={e => this.onClick(payerCount, payerCount)} disabled={disabled}>
                    <div className={classes.pieContainer}>
                        <div
                            className={classNames(
                                classes.pie,
                                isHidden && classes.split_1
                            )}
                        />
                        {!isHidden &&
                            <div className={classes.overlay}>
                                <div
                                    className={classNames(
                                        classes.pie,
                                        splitFraction.className && classes[splitFraction.className]
                                    )}
                                />
                            </div>
                        }
                    </div>
                    <Typography color="primary" className={classes.label}>
                        {splitFraction.label}
                    </Typography>
                    <Typography color="primary" className={classes.amount}>
                        {splitFraction.amount && formatDinero(splitFraction.amount)}
                        &nbsp;per payer
                    </Typography>
                </CardActionArea>
            </Card>
        );
    };

    render() {
        const { classes, payerCount, price, isHidden } = this.props;
        const splitFraction: PaymentType = {
            label: ['none', 'all', 'half', 'one-third', 'quarter', 'one-fifth',
                'one-sixth', 'one-seventh', 'one-eighth'][payerCount],
            className: `split_${payerCount}`,
            amount: payerCount > 0
                ? price.percentage(100 / payerCount)
                : Dinero({ amount: 0, currency: price.getCurrency() }),
        };

        return (
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.root,
                    !isHidden && payerCount > 1 && classes.show
                )}
            >
                <Typography color="primary" className={classes.heading}>
                    How would you like to split the bill?
                </Typography>
                <div className={classes.boxes}>
                    {splitFraction && this.renderPieBox(splitFraction)}
                    {this.renderSplitBox()}
                </div>
            </Grid>
        );
    }
}

export default withGStyles(styles)(SplitBill);
