import { PropsWithChildren } from "react";
import classNames from "classnames";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { getFormattedPhoneNumber, openInterCom } from "../../../services";
import { UserRoles } from "../../../shared/types";
import AssignTask from "../../assignmentPoppers/AssignTask";
import FuneralHomeLogo from "../../common/FuneralHomeLogo";
import { TaskDialogComponentProps } from "./TaskDialog";
import { useTaskDialogStyles } from "./TaskDialogsStyles";

export interface InitialStepListItem {
    id: number;
    icon?: JSX.Element;
    label: string;
    content: JSX.Element;
}

interface Props extends Pick<TaskDialogComponentProps, 'task' | 'activeCase' | 'zIndex' | 'userData'> {
    listItems: InitialStepListItem[];
    onItemClick: (index: number) => void;
    active: boolean;
}

const TaskDialogInitialState = (props: PropsWithChildren<Props>) => {
    const {
        activeCase,
        task,
        userData,
        zIndex,
        listItems,
        children,
        active,
        onItemClick
    } = props;

    const classes = useTaskDialogStyles();

    const asigneeHasPhoneNumber: boolean = activeCase.assignee.phone !== null && activeCase.assignee.phone !== '';
    const fhHasPhoneNumber: boolean = activeCase.funeral_home.phone !== null
        && activeCase.funeral_home.phone !== '';

    const phoneNumbeToolTip = asigneeHasPhoneNumber ? activeCase.assignee.phone : fhHasPhoneNumber
        ? activeCase.funeral_home.phone : 'No number available';

    const hasOnlyOneItem = listItems.length === 1;

    return (
        <Grid
            container
            justifyContent="center"
            className={classNames(
                classes.marginAuto,
                classes.marginBottom20,
                classes.marginTop20,
            )}
            sx={{ height: active ? 'auto' : 0 }}
        >
            <Grid item xs={12} sm={10}>
                <Grid className={classes.textCenter}>
                    {task && <AssignTask
                        zIndex={zIndex + 1}
                        task={task}
                        activeCase={activeCase}
                    />}
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        color="secondary"
                        align="left"
                        component="p"
                        className={classNames(
                            classes.marginBottom20,
                            classes.fontSize18,
                            classes.marginTop10,
                            classes.fontWeight300
                        )}
                    >
                        {UserRoles.isFHorGOMUser(userData)
                            ? 'Hi There,'
                            : `Hi ${userData?.fname || ''},`
                        }
                    </Typography>
                    <Typography
                        color="secondary"
                        align="left"
                        component="p"
                        className={classNames(
                            classes.width100,
                            classes.marginTop20,
                            classes.fontSize18,
                            classes.fontWeight300
                        )}
                    >
                        {children}
                    </Typography>
                </Grid>

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="center"
                    className={classes.marginTop20}
                >
                    <Grid item xs={4}>
                        <Typography
                            align="left"
                            color="secondary"
                            component="p"
                            className={classNames(
                                classes.marginBottom10,
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.whiteSpaceNowrap
                            )}
                        >
                            Sincerely,
                            <span className={classes.displayBlock}>
                                {activeCase.assignee.fname}
                            </span>
                            <a
                                href={asigneeHasPhoneNumber ? `tel:${activeCase.assignee.phone || ''}`
                                    : fhHasPhoneNumber ? `tel:${activeCase.funeral_home.phone}`
                                        : undefined}
                                className={classNames(
                                    classes.colorPrimary,
                                    classes.textDecorationNone,
                                    classes.fontSize16,
                                )}
                            >
                                {getFormattedPhoneNumber(phoneNumbeToolTip)}
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.textRight}>
                        <FuneralHomeLogo className={classes.marginBottom20} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.textCenter,
                            classes.colorPrimary,
                            classes.bt_solid_1,
                            hasOnlyOneItem && classes.marginBottom20
                        )}
                    >
                        {!hasOnlyOneItem &&
                            <Typography
                                color="primary"
                                className={classNames(
                                    classes.fontSize20,
                                    classes.marginBottom10
                                )}
                            >
                                What would you like to do?
                            </Typography>
                        }
                    </Grid>
                    {listItems.map((listItem, index) =>
                        <Grid
                            key={listItem.id}
                            item
                            xs={12}
                            className={classNames(classes.iconButtonGrid, listItems.length && classes.margin_0)}
                        >
                            <Button
                                color={hasOnlyOneItem ? 'primary' : 'secondary'}
                                variant={hasOnlyOneItem ? 'contained' : undefined}
                                className={hasOnlyOneItem
                                    ? classNames(classes.displayFlex, classes.marginAuto)
                                    : classNames(
                                        classes.iconButton,
                                        classes.textTransformNone,
                                        classes.fontSize18,
                                        classes.fontWeight300,
                                    )}
                                onClick={() => onItemClick(index)}
                            >
                                {listItem.icon &&
                                    <span className={classNames(classes.initialStateIconsSize, classes.colorPrimary)}>
                                        {listItem.icon}
                                    </span>
                                }
                                <span
                                    className={classNames(listItem.icon && classes.marginLeft15, classes.textLeft)}
                                >
                                    {listItem.label}
                                </span>
                            </Button>
                        </Grid>)}
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.textCenter,
                            classes.colorPrimary,
                            classes.bt_solid_1,
                            classes.marginTop20
                        )}
                    >
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.marginBottom10,
                                classes.marginTop10,
                                classes.fontSize16,
                                classes.fontWeight300
                            )}
                        >
                            Not seeing what you need?
                            <span
                                className={
                                    classNames(
                                        classes.clickable,
                                        classes.fontWeight500
                                    )
                                }
                                onClick={openInterCom}
                            >
                                {' Chat with us now'}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TaskDialogInitialState;