import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import Grid from '@mui/material/Grid';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    message: {
        color: theme.palette.secondary.main,
        fontSize: 18,
        fontWeight: 300,
        marginTop: '8px',
        marginBottom: '30px',
        '@media (min-width: 768px)': {
            fontSize: 22,
        },

    },
    clickStyle: {
        color: ' #909090',
        fontSize: '15px',
        fontWeight: 500,
    },
    cursor: {
        cursor: 'pointer',
    },
    messageFontSize: {
        fontSize: 15,
        marginTop: '40px',
        fontWeight: 300,
    },
    linkTextDec: {
        '& a': {
            textDecoration: 'none',
        },
    },
});

interface Props {
    emailOrPhone: string;
    gotoLink: () => void;
    gotoDisplayMessage: string;
}

type StyledProps = WithStyles<'root' | 'message' | 'clickStyle' | 'cursor'
    | 'messageFontSize' | 'linkTextDec'>;
type CombinedProps = StyledProps & Props;

const SelfLoginPasswordResetSuccess = (props: CombinedProps) => {
    const {
        classes,
        emailOrPhone,
        gotoLink,
        gotoDisplayMessage,
    } = props;

    return (
        <Grid container className={classes.root} justifyContent="center" >
            <Grid item xs={12} m="auto">
                <Typography variant="h5" component="p" align="center" className={classes.message}>
                    Your reset password instructions were sent to <b>{emailOrPhone}</b> and
                    should arrive shortly
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.linkTextDec}>
                <Typography
                    variant="h5"
                    gutterBottom
                    noWrap
                    align="center"
                    component="p"
                    className={`${classes.clickStyle} ${classes.cursor}`}
                    onClick={() => gotoLink()}
                >
                    {gotoDisplayMessage || ' Return to Log In '}
                </Typography>
            </Grid>
        </Grid>

    );
};

export default withStyles(styles)(SelfLoginPasswordResetSuccess);