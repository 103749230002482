import { useState } from 'react';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductUX } from '../../../../../shared/types';
import { useGDispatch } from '../../../../../types';
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import ShowHideProductDetails from "./ShowHideProductDetails";

interface ShowHideOnPublicGPLProps {
    selectButton: BulkEditButtonType | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    funeralHomeId: number | null;
}

const ShowHideOnPublicGPL = (props: ShowHideOnPublicGPLProps) => {
    const { selectButton, selectedProductIds, onSubmitClick, funeralHomeId } = props;
    const dispatch = useGDispatch();
    const [isSpinning, setIsSpinning] = useState(false);
    const handleSubmitclick = async (isShowSelected: boolean) => {
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.show_hide_on_public_gpl,
            funeralHomeId, 
            productIds: selectedProductIds, 
            changes: {is_hidden: Boolean(!isShowSelected)},
        }) );
        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <ShowHideProductDetails
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            onSubmitClick={handleSubmitclick}
            isSpinning={isSpinning}
        />
    );
};

export default ShowHideOnPublicGPL;
