
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import makeGStyles from "../../../../../styles/makeGStyles";
import LocationItem, { LocationItemState } from "./LocationItem";
import ScrollControls from "../../../../common/ScrollControls";
import { useGDispatch, useGSelector } from "../../../../../types";
import { TrackingContentContext } from "../../dialogs/completeStepDialog/CompleteStep.dialog";
import { TaskLocationSummary, TaskLocationUX, LocationConfig } from "../../../../../shared/types";
import StepComponentWrapper from "../../dialogs/completeStepDialog/StepComponentWrapper";
import { OnAnswerChangedFunction, OnValidityChangedFunction } from "../../dialogs/completeStepDialog/components/types";
import { GREEN_COLOR, ORANGE_COLOR } from "../../../../../constants/colorVariables";
import { canCompleteTrackingSteps } from '../../../../../shared/authority/can';
import { TOOLTIPS } from "../../../../../constants";
import { renewCaseWorkflow } from "../../../../../actions/GatherCase.action";
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from "@mui/material/Tooltip";
import { getIntercomTargetProp } from "../../../../../services";

const useStyles = makeGStyles<{ isDialogContext: boolean }>(theme => ({
    root: {
        width: '100%',
        borderRadius: 16,
        border: `2px dashed ${theme.palette.secondary.main}`,
        background: '#fff',
        '& $listContainer': {
            padding: '20px 0 8px !important',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&$completed button': {
                border: `2px solid ${GREEN_COLOR}`,
            }
        }
    },
    listContainer: {},
    connector: {
        width: 0,
        height: 58,
        borderLeft: '2px dashed #757575',
        display: 'flex',
        // margin: '-8px auto',
        margin: '-38px auto 0'
    },
    mainRoot: {
        padding: '0 8px',
        [theme.breakpoints.up('sm')]: {
            padding: 0
        }
    },
    width52: {
        width: 52
    },
    naivgateContainer: {
        position: 'absolute',
        background: 'none',
        '& button': {
            margin: 0,
            background: `${theme.palette.primary.main} !important`,
            '& svg': {
                color: '#fff'
            }
        }
    },
    leftButton: {
        left: props => props.isDialogContext ? -12 : -24
    },
    rightButton: {
        right: props => props.isDialogContext ? -12 : -24
    },
    progress: {
        padding: 20
    },
    explainerText: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '8px 16px',
        width: 'fit-content',
        textAlign: 'center',
        borderRadius: 12,
        fontSize: 12,
        margin: '16px auto 4px',
        '@media (min-width: 360px)': {
            fontSize: 14
        }
    },
    borderNone: {
        border: 'none'
    },
    footer: {
        minHeight: 56,
        maxWidth: '75%',
        margin: '4px auto 12px'
    },
    completed: {},
    borderOrange: {
        border: `2px dashed ${ORANGE_COLOR}`,
    },
    colorOrange: {
        color: `${ORANGE_COLOR} !important`
    },
    textCorrectAccess: {
        display: 'flex',
        flexDirection: 'column',
        color: ORANGE_COLOR
    },
    updateText: {},
    updateTextSection: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0px 5px',
        width: 'max-content',
        margin: 'auto'
    }

}), { name: 'SelectLocation' });

interface Props {
    config?: LocationConfig | null;
    componentId?: number;
    saveAttempted?: boolean;
    caseFName: string;
    context: TrackingContentContext;
    selectedLocation: TaskLocationUX | null;
    funeralHomeId: number | null;
    currentLocation: TaskLocationSummary | null;
    isStepCompleted?: boolean;
    onTaskLocationSelect: (location: TaskLocationUX) => void;
    onValidityChanged?: OnValidityChangedFunction;
    onAnswerChanged?: OnAnswerChangedFunction;
    caseUuid: string | null;
    intercomTargetProp?: string;
    intercomTargetPropContainer?: string;
}

const SelectLocation = (props: Props) => {
    const {
        config,
        componentId,
        caseFName,
        context,
        selectedLocation,
        funeralHomeId,
        currentLocation,
        saveAttempted,
        isStepCompleted,
        onTaskLocationSelect,
        onValidityChanged,
        onAnswerChanged,
        caseUuid,
        intercomTargetProp,
        intercomTargetPropContainer,
    } = props;

    const isDialogContext = context === TrackingContentContext.dialog;
    const classes = useStyles({ isDialogContext });

    const userProfile = useGSelector(({ userSession }) => userSession.userData);
    const { taskLocations, isLoading, trackingSteps } = useGSelector(({ taskLocationState, tasksState }) => ({
        taskLocations: taskLocationState.taskLocations,
        isLoading: taskLocationState.isLoading,
        trackingSteps: tasksState.trackingSteps
    }));

    const [note, setNote] = useState<string>('');
    const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null);
    const [renewingWorkflow, setRenewingWorkflow] = useState<boolean>(false);

    const locationTask = useMemo(() => {
        if (selectedLocation?.id === undefined) {
            return null;
        }
        return trackingSteps.find(task => task.task_location_id === selectedLocation.id);
    }, [selectedLocation?.id, trackingSteps]);

    const isValid = useMemo(() => {
        return selectedLocation !== null;
    }, [selectedLocation]);

    const dispatch = useGDispatch();

    useEffect(() => {
        if ((locationTask?.note || config?.answer) && isStepCompleted) {
            setNote(config?.answer || locationTask?.note || '');
        }
    }, [config?.answer, locationTask, isStepCompleted]);

    useEffect(() => {
        if (onValidityChanged && componentId) {
            onValidityChanged(componentId, isValid);
        }
    }, [onValidityChanged, componentId, isValid]);

    useEffect(() => {
        if (onAnswerChanged && componentId) {
            onAnswerChanged(componentId, note);
        }
    }, [onAnswerChanged, componentId, note]);

    const renewWorkflow = async () => {
        setRenewingWorkflow(true);
        if (caseUuid != null) {
            await dispatch(renewCaseWorkflow({
                caseUuid: caseUuid,
            }));
        }
        setRenewingWorkflow(false);
    };

    const canCompleteTracking = funeralHomeId !== null && canCompleteTrackingSteps(userProfile, funeralHomeId);
    const currentLocationId = currentLocation?.id;

    const renderLocations = () => {
        if (isStepCompleted) {
            if (!selectedLocation) {
                return null;
            }
            return (
                <LocationItem
                    key={selectedLocation.id}
                    locationMovedTime={null}
                    state={LocationItemState.default}
                    location={selectedLocation}
                    caseFName={caseFName}
                    disabled={true}
                    hideTooltips={true}
                    intercomTargetProp={intercomTargetProp}
                />
            );
        }

        return taskLocations.map(location => {
            const isCurrent = location.id === currentLocation?.id;
            const isSelected = location.id === selectedLocation?.id;

            let state: LocationItemState;
            if (isCurrent) {
                if (!isDialogContext) {
                    state = LocationItemState.current;
                } else {
                    if (selectedLocation === null) {
                        state = LocationItemState.previous;
                    } else {
                        if (isSelected) {
                            state = LocationItemState.current;
                        } else {
                            state = LocationItemState.previousOtherSelected;
                        }
                    }
                }
            } else if (isSelected) {
                state = LocationItemState.moveTo;
            } else {
                state = LocationItemState.default;
            }

            return (
                <LocationItem
                    key={location.id}
                    locationMovedTime={isCurrent ? currentLocation?.moved_time : null}
                    state={state}
                    location={location}
                    onSelect={() => onTaskLocationSelect(location)}
                    caseFName={caseFName}
                    disabled={!canCompleteTracking}
                    intercomTargetProp={intercomTargetProp}
                />
            );
        });
    };

    const renderContent = () => (
        <Grid
            container
            flexWrap="nowrap"
            justifyContent={isLoading ? 'center' : undefined}
            className={classes.positionRelative}
        >
            {isLoading
                && <CircularProgress size={52} className={classes.progress} />
                || <Grid
                    container
                    justifyContent={isStepCompleted ? 'center' : undefined}
                    ref={setScrollElement}
                    className={classNames(classes.list, classes.listContainer, isStepCompleted && classes.completed)}
                >
                    {renderLocations()}
                </Grid>
            }

            {scrollElement &&
                <ScrollControls
                    registerEvents
                    scrollElement={scrollElement}
                    containerClass={classes.naivgateContainer}
                    leftButtonClass={classes.leftButton}
                    rightButtonClass={classes.rightButton}
                />
            }
        </Grid>
    );

    if (!isDialogContext) {
        return (
            <Grid className={classes.mainRoot}>
                <Grid
                    item
                    xs={12}
                    className={classNames(classes.root, classes.positionRelative,
                        !canCompleteTracking && classes.borderOrange)}
                    {...getIntercomTargetProp(intercomTargetPropContainer)}
                >
                    {canCompleteTracking && (
                        <Typography align="center" color="secondary">
                            Tap below to move {caseFName}
                        </Typography>
                    )}
                    {!canCompleteTracking && (
                        <Typography align="center" color="secondary" className={classes.colorOrange}>
                            {TOOLTIPS.DISABLED_FEATURE}
                        </Typography>

                    )}

                    {renderContent()}
                </Grid>

                <Tooltip
                    title="To give you more control and predictability, new adjustments to the details of a Workflow
                        are designed to only apply when first switching to that WorkFlow. So if you make changes to a
                        WorkFlow after it has been applied to a case, you won't see those changes reflected on that
                        case until you click this button."
                    placement="top"
                    enterDelay={600}
                >
                    <div className={classes.updateTextSection}>
                        <UpdateIcon className={classes.colorOrange} fontSize="small" />&nbsp;
                        <Typography
                            color="secondary"
                            variant="subtitle2"
                            className={classNames(classes.updateText, classes.fontWeight400)}
                            {...getIntercomTargetProp(`TrackingPage-TrackTab-ClickToSeeUpdatedWorkFlow`)}
                        >
                            Did you update this WorkFlow recently?&nbsp;
                            <span
                                className={!renewingWorkflow ? classes.textClickable : undefined}
                                onClick={!renewingWorkflow ? renewWorkflow : undefined}
                            >
                                Click to see the new one.
                            </span>
                        </Typography>
                    </div>
                </Tooltip>

                <Grid className={classes.width52} >
                    <Grid className={classes.connector} />
                </Grid>

            </Grid>
        );
    }

    const showNote = selectedLocation?.id !== currentLocationId || isStepCompleted;
    const classColorOrange = !canCompleteTracking ? classes.colorOrange : undefined;

    return (
        <StepComponentWrapper
            error={saveAttempted && !isValid}
            success={isValid}
            heading="Location Confirmation *"
            className={classColorOrange}
            extendedWidth
            intercomTargetProp={intercomTargetProp}
        >
            <Typography className={classes.explainerText}>
                Where was {caseFName} located during this action? *
            </Typography>

            <Grid item xs={12} className={classNames(classes.root, classes.positionRelative, classes.borderNone)}>
                {renderContent()}
            </Grid>

            <Grid item xs={12} className={classNames(classes.footer, classes.flexCentred)}>
                {selectedLocation === null && !isStepCompleted
                    ?
                    !canCompleteTracking ?
                        <Typography
                            className={classNames(classes.paragraphLineHeight, classes.textCorrectAccess)}
                            align="center"
                        >
                            <span>You do not have the correct access to complete a step.</span>
                            <span>Please check with your funeral home admin to enable.</span>
                        </Typography>
                        :
                        <Typography color="primary" className={classes.paragraphLineHeight} align="center">
                            Your selection will automatically move {caseFName},
                            helping to maintain a more precise history.
                        </Typography>
                    : showNote &&
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Record optional placement details..."
                        value={note}
                        disabled={isStepCompleted}
                        onChange={(e) => !isStepCompleted && setNote(e.currentTarget.value)}
                    />
                }
            </Grid>
        </StepComponentWrapper>
    );
};

export default SelectLocation;
