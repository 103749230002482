import * as React from 'react';
import classNames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {},
    xMandatory: {
        display: 'flex',
        flexDirection: 'row',
        scrollSnapType: 'x mandatory' as 'mandatory',
        overflowX: 'auto',
    },
    yMandatory: {
        display: 'flex',
        flexDirection: 'column',
        scrollSnapType: 'y mandatory' as 'mandatory',
        overflowY: 'auto',
    },
    xProximity: {
        display: 'flex',
        flexDirection: 'row',
        scrollSnapType: 'x proximity' as 'proximity',
        overflowX: 'auto',
    },
    yProximity: {
        display: 'flex',
        flexDirection: 'column',
        scrollSnapType: 'y proximity' as 'proximity',
        overflowY: 'auto',
    },
    start: {
        scrollSnapAlign: 'start',
    },
    center: {
        scrollSnapAlign: 'center',
    },
    end: {
        scrollSnapAlign: 'end',
    },
}), { name: 'ScrollSnap' });

export enum ScrollSnapType {
    xMandatory = 'xMandatory',
    yMandatory = 'yMandatory',
    xProximity = 'xProximity',
    yProximity = 'yProximity',
}

export enum ScrollSnapAlign {
    start = 'start',
    center = 'center',
    end = 'end'
}

interface Classes {
    root: string;
    item: string;
}
interface Props {
    children: React.ReactNode | React.ReactNode[];
    align: ScrollSnapAlign;
    type: ScrollSnapType;
    containerClasses?: Partial<Classes>;
}

const ScrollSnap = React.forwardRef<HTMLDivElement, Props>(
    function ScrollSnapInstance(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
        const { children, type, align, containerClasses } = props;

        const classes = useStyles();

        return (
            <div
                className={classNames(
                    classes[type],
                    containerClasses && containerClasses.root
                )}
                ref={ref}
            >
                {React.Children.map(children, (child) =>
                    <div
                        className={classNames(
                            classes[align],
                            containerClasses && containerClasses.item
                        )}
                    >
                        {child}
                    </div>
                )}
            </div>
        );
    }
);

export default React.memo(ScrollSnap);
