import * as t from 'io-ts';
import { getValidator } from './utils';

export interface ThemeRecord {
    id: number;
    rank: number;
    name: string;
    description: string | null;
    primary_color: string;
    secondary_color: string;
    thumbnail_view_id: number | null;
    primary_background_image_view_id: number | null;
    secondary_background_image_view_id: number | null;
    top_accent_image_view_id: number | null;
    top_accent_image_reversed_view_id: number | null;
    top_graphic_view_id: number | null;
    top_mobile_graphic_view_id: number | null;
    bottom_left_graphic_view_id: number | null;
    bottom_right_graphic_view_id: number | null;
    bottom_mobile_graphic_view_id: number | null;
    created_by: number;
    updated_by: number;
    created_time: Date;
    updated_time: Date;
    is_live: boolean;
}

export interface ThemeUXRecord extends Pick<ThemeRecord,
    'id' |
    'rank' |
    'name' |
    'description' |
    'primary_color' |
    'secondary_color' |
    'thumbnail_view_id' |
    'primary_background_image_view_id' |
    'secondary_background_image_view_id' |
    'top_accent_image_view_id' |
    'top_accent_image_reversed_view_id' |
    'top_graphic_view_id' |
    'top_mobile_graphic_view_id' |
    'bottom_left_graphic_view_id' |
    'bottom_right_graphic_view_id' |
    'bottom_mobile_graphic_view_id' |
    'created_by' |
    'updated_by' |
    'created_time' |
    'updated_time' |
    'is_live'
>
{
    thumbnail: string | null;
    primary_background_image: string | null;
    secondary_background_image: string | null;
    top_accent_image: string | null;
    top_accent_image_reversed: string | null;
    top_graphic: string | null;
    top_mobile_graphic: string | null;
    bottom_left_graphic: string |  null;
    bottom_right_graphic: string | null;
    bottom_mobile_graphic: string | null;
    tags: ThemeTagRecord[];
}

export interface ThemeUX extends ThemeUXRecord {
}

const ThemeCreateRequestDefinition = {
    name: t.string,
    rank: t.number,
    description: t.union([t.string, t.null]),
    primary_color: t.string,
    secondary_color:t.string,
    is_live: t.boolean,
};

const ThemeCreateRequestType = t.type(ThemeCreateRequestDefinition);

export interface ThemeCreateRequest extends t.TypeOf<typeof ThemeCreateRequestType> {}

export class ThemeCreateRequest {
    public static fromRequest = getValidator<ThemeCreateRequest>(ThemeCreateRequestType);
}

const ThemeUpdateRequestDefinition = {
    ...ThemeCreateRequestDefinition,
    thumbnail_view_id: t.union([t.number, t.null]),
    primary_background_image_view_id: t.union([t.number, t.null]),
    secondary_background_image_view_id: t.union([t.number, t.null]),
    top_accent_image_view_id: t.union([t.number, t.null]),
    top_accent_image_reversed_view_id: t.union([t.number, t.null]),
    top_graphic_view_id: t.union([t.number, t.null]),
    top_mobile_graphic_view_id: t.union([t.number, t.null]),
    bottom_left_graphic_view_id: t.union([t.number, t.null]),
    bottom_right_graphic_view_id: t.union([t.number, t.null]),
    bottom_mobile_graphic_view_id: t.union([t.number, t.null]),
};

const ThemeUpdateRequestType = t.partial(ThemeUpdateRequestDefinition);

export interface ThemeUpdateRequest extends t.TypeOf<typeof ThemeUpdateRequestType> {}

export class ThemeUpdateRequest {
    public static fromRequest = getValidator<ThemeUpdateRequest>(ThemeUpdateRequestType);
}

export enum ThemePhotoEnum {
    'thumbnail' = 'thumbnail',
    'primary_background_image' = 'primary_background_image',
    'secondary_background_image' = 'secondary_background_image',
    'top_accent_image' = 'top_accent_image',
    'top_accent_image_reversed' = 'top_accent_image_reversed',
    'top_graphic' = 'top_graphic',
    'top_mobile_graphic' = 'top_mobile_graphic',
    'bottom_left_graphic' = 'bottom_left_graphic',
    'bottom_right_graphic' = 'bottom_right_graphic',
    'bottom_mobile_graphic' = 'bottom_mobile_graphic',
}


export interface ThemeTagRecord {
    name: string;
    value: string;
    theme_id: number;
}

export interface ThemeTagUX extends ThemeTagRecord {}

const ThemeTagRequestDefinition = {
    name: t.string,
    value: t.string,
};

const ThemeTagRequestType = t.type(ThemeTagRequestDefinition);

export interface ThemeTagRequest extends t.TypeOf<typeof ThemeTagRequestType> {}

export class ThemeTagRequest {
    public static fromRequest = getValidator<ThemeTagRequest>(ThemeTagRequestType);
}