import { StyledProps } from './styles';
import { ItemGrid, SecondaryGridProps } from './widgets';
import { getPhotoUrl } from '../../../../services';
import { getVariablePriceDisplay, getDisplayPrice } from '../../../../shared/goods_and_services/pricing';
import { ProductCategoryEnum, ProductSummary, GPLContext } from '../../../../shared/types';
import {
    generateTagsLabel,
    generatePersistentContractTextLabel,
    generateQuantityLabel,
} from './ContractItemWithRevisions';

interface Props {
    isPrintMode: boolean;
    canUserEditContract: boolean;
    product: ProductSummary;
    isCondensedView: boolean;
    isDraggable?: boolean;
    context: GPLContext;
    overridePrice?: string;
    onItemClick: () => void;
}

type CombinedProps = StyledProps & Props;

const ContractItemProductOnly = (props: CombinedProps) => {
    const {
        classes,
        isPrintMode,
        product,
        onItemClick,
        canUserEditContract,
        isCondensedView,
        isDraggable,
        context,
        overridePrice,
    } = props;

    let secondaryGrid: SecondaryGridProps[] = [];

    if (product.display_tags && Object.keys(product.tags).length !== 0) {
        secondaryGrid.push(generateTagsLabel(product.tags, false));
    }

    if (product.persistent_contract_text) {
        secondaryGrid.push(generatePersistentContractTextLabel(product.persistent_contract_text, false));
    }

    if (product.var_increment_units && context === GPLContext.BackOffice) {
        const variablePricing = getVariablePriceDisplay(product);
        secondaryGrid.push(generateQuantityLabel(
            product.base_quantity || 0,
            variablePricing,
            product.var_increment_units,
            undefined,
            false,
            undefined,
        ));
    }

    const transformations = [{
        height: 56,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];
    const backgroundImage = product.photos[0] && product.category !== ProductCategoryEnum.care_of_loved_one ?
        getPhotoUrl(product.photos[0], transformations) : undefined;

    const displayLabel = product.name;

    return (
        <ItemGrid
            classes={classes}
            item={{
                label: displayLabel,
                price: overridePrice || getDisplayPrice(product),
                modelNumber: product.display_model_number && product.model_number || undefined,
                manufacturer: product.display_manufacturer && product.manufacturer_name || undefined,
                bold: product.bold_contract_text,
                underline: product.underline_contract_text,
                indent: product.indent_contract_text
            }}
            printMode={isPrintMode}
            photo={backgroundImage ? { url: backgroundImage, variant: 'border' } : undefined}
            secondary={secondaryGrid}
            onClick={onItemClick}
            canUserEditContract={canUserEditContract}
            isCondensedView={isCondensedView}
            isDraggable={isDraggable}
        />
    );
};

export default ContractItemProductOnly;
