import {
    ModerationPendingCountsForAllCases,
    LoadModerationItemsResponse,
    ModerationItem,
    ModerationCategory,
    isModerationCountsForCase,
    isModerationPendingCountsForAllCases,
    ModerationCountsForCase,
} from '../shared/types';
import {
    retrievePendingModerationCounts,
    retrieveModerationVisitors,
    retrieveModerationMemories,
    retrieveModerationPhotos,
    RetrieveModerationItemsParams,
    retrieveAllModerationCountsForCase,
    FlagContentParams,
    callFlagContent,
} from '../api/Moderation.api';
import { log } from '../logger';
import { setSnackbarSuccess, setAppSnackbar } from './AppSnackbar.action';
import { AppDispatch } from '../store';

// ----> Loaded Pending Moderation Counts <----
export const LOADED_MODERATION_PENDING_COUNTS = 'LOADED_MODERATION_PENDING_COUNTS';

interface LoadedModerationPendingCounts {
    type: typeof LOADED_MODERATION_PENDING_COUNTS;
    moderationCounts: ModerationPendingCountsForAllCases;
}

function loadedModerationPendingCounts(
    moderationCounts: ModerationPendingCountsForAllCases,
): LoadedModerationPendingCounts {
    return {
        type: LOADED_MODERATION_PENDING_COUNTS,
        moderationCounts,
    };
}

export function loadModerationPendingCounts(params: {
    funeralHomeId: number | null;
    caseUuid: string | null;
}) {
    return async (dispatch: AppDispatch): Promise<ModerationPendingCountsForAllCases | null> => {
        const counts = await dispatch(retrievePendingModerationCounts(params));
        if (counts) {
            dispatch(loadedModerationPendingCounts(counts));
        }
        return counts;
    };
}

// ----> Loaded Moderation Counts For Case <----
export const LOADED_MODERATION_COUNTS_FOR_CASE = 'LOADED_MODERATION_COUNTS_FOR_CASE';

interface LoadedModerationCountsForCase {
    type: typeof LOADED_MODERATION_COUNTS_FOR_CASE;
    moderationCounts: ModerationCountsForCase;
}

function loadedModerationCountsForCase(
    moderationCounts: ModerationCountsForCase,
): LoadedModerationCountsForCase {
    return {
        type: LOADED_MODERATION_COUNTS_FOR_CASE,
        moderationCounts,
    };
}

export function loadModerationCountsForCase(params: { caseUuid: string }) {
    return async (dispatch: AppDispatch): Promise<ModerationCountsForCase | null> => {
        const counts = await dispatch(retrieveAllModerationCountsForCase(params));
        if (counts) {
            dispatch(loadedModerationCountsForCase(counts));
        }
        return counts;
    };
}

export function loadModerationItems(params: RetrieveModerationItemsParams) {
    return async (dispatch: AppDispatch): Promise<LoadModerationItemsResponse<ModerationItem> | null> => {
        const { category } = params;
        let response: LoadModerationItemsResponse<ModerationItem> | null = null;
        switch (category) {
        case ModerationCategory.visitors:
            response = await dispatch(retrieveModerationVisitors(params));
            break;
        case ModerationCategory.memories:
            response = await dispatch(retrieveModerationMemories(params));
            break;
        case ModerationCategory.photos:
            response = await dispatch(retrieveModerationPhotos(params));
            break;
        default:
            log.warn('Invalid moderation category', { category });
            break;
        }
        if (response) {
            const { totals } = response;
            if (totals) {
                if (isModerationCountsForCase(totals)) {
                    dispatch(loadedModerationCountsForCase(totals));
                } else if (isModerationPendingCountsForAllCases(totals)) {
                    dispatch(loadedModerationPendingCounts(totals));
                }
            }
        }
        return response;
    };
}

// ----> Made Moderation Decision <----
export const MADE_MODERATION_DECISION = 'MADE_MODERATION_DECISION';

interface MadeModerationDecision {
    type: typeof MADE_MODERATION_DECISION;
    category: ModerationCategory;
    decisions: ModerationItem[];
    isApproval: boolean;
    caseUuid: string | null;
}

export function madeModerationDecision({
    category,
    decisions,
    isApproval,
    caseUuid,
}: Omit<MadeModerationDecision, 'type'>): MadeModerationDecision {
    return {
        type: MADE_MODERATION_DECISION,
        category,
        decisions,
        isApproval,
        caseUuid,
    };
}

// ----> Deleted Moderation Item <----
export const DELETED_MODERATION_ITEM = 'DELETED_MODERATION_ITEM';

interface DeletedModerationItem {
    type: typeof DELETED_MODERATION_ITEM;
    category: ModerationCategory;
    item: ModerationItem;
}

export function deletedModerationItem({ category, item }: Omit<DeletedModerationItem, 'type'>): DeletedModerationItem {
    return {
        type: DELETED_MODERATION_ITEM,
        category,
        item,
    };
}

// ----> Flagged Content <----
export const FLAGGED_CONTENT = 'FLAGGED_CONTENT';

interface FlaggedContent {
    type: typeof FLAGGED_CONTENT;
    category: ModerationCategory;
    itemId: number;
    caseId: number;
}

export function flaggedContent({
    category,
    itemId,
    caseId,
}: Omit<FlaggedContent, 'type'>): FlaggedContent {
    return {
        type: FLAGGED_CONTENT,
        category,
        itemId,
        caseId,
    };
}

export function flagContent(params: FlagContentParams & { caseId: number }) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        const result = await dispatch(callFlagContent(params));
        if (result) {
            dispatch(flaggedContent(params));
            dispatch(setSnackbarSuccess('Content has been flagged'));
            return true;
        } else {
            dispatch(setAppSnackbar('Failed to flag content', 'error'));
            return false;
        }
    };
}

export type ModerationAction =
    | LoadedModerationPendingCounts
    | LoadedModerationCountsForCase
    | MadeModerationDecision
    | DeletedModerationItem
    | FlaggedContent
    ;
