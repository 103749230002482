import { Component } from 'react';
import { log } from '../../../../logger';
import { DocCategory, DocUXDetail, UserRole, UserRoles } from '../../../../shared/types';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import DocPreviewDialog, { DocType } from '../../DocPreview.dialog';
import DocumentRenameDialog from '../../DocumentRename.dialog';
import DocItemMenu, { AdditionalDocMenuItems, HiddenDocMenuItems } from './DocItemMenu';
import DocItem from './DocItem';
import { PopoverReference } from '@mui/material/Popover';

interface Props {
    docsType: DocType;
    zIndex: number;
    activeFuneralHomeId: number;
    docs: DocUXDetail[];
    rootClass?: string;
    isCompactView: boolean;
    isGOMUser: boolean;
    menuAnchorReference?: PopoverReference;
    downloadDoc: (docId: number) => void;
    deleteDoc: (docId: number) => void;
    renameDoc?: (docId: number, label: string) => void;
    setMenuOpen?: (isOpen: boolean) => void;
    getAdditionalMenuItems?: (docDetail: DocUXDetail) => AdditionalDocMenuItems[];
    getHiddenMenuItems?: (docDetail: DocUXDetail) => Partial<HiddenDocMenuItems>[];
    intercomTargetPropDocMenu?: string;
    intercomTargetPropDocItem?: string;
}
interface State {
    menuAnchorEle: HTMLElement | null;
    isDocPreviewDialogOpen: boolean;
    activeDocDetail: DocUXDetail | null;
    isRenameDialogOpen: boolean;
    isDeleteConfirmationDialogOpen: boolean;
}

class DocItemList extends Component<Props, State> {
    state: State = {
        menuAnchorEle: null,
        isDocPreviewDialogOpen: false,
        activeDocDetail: null,
        isDeleteConfirmationDialogOpen: false,
        isRenameDialogOpen: false
    };

    renderMenu = () => {
        const { menuAnchorEle } = this.state;
        const {
            zIndex,
            menuAnchorReference,
            downloadDoc,
            getAdditionalMenuItems,
            getHiddenMenuItems,
            renameDoc,
            isGOMUser,
        } = this.props;
        const { activeDocDetail } = this.state;
        const isDD214Item = (activeDocDetail
            && activeDocDetail.doc
            && activeDocDetail.doc.doc_category) === DocCategory.form_dd214;

        const disableDeleteButton = !isGOMUser
            && UserRoles.isGOMRole(activeDocDetail?.doc?.uploaded_role || UserRole.GOMUser);

        return (
            <DocItemMenu
                menuAnchorEle={menuAnchorEle}
                closeMenu={this.closeMenu}
                isDD214={isDD214Item}
                zIndex={zIndex + 1}
                additionalMenuItems={activeDocDetail && getAdditionalMenuItems
                    ? getAdditionalMenuItems(activeDocDetail)
                    : undefined}
                onViewClick={this.closeMenuAndCallAction(this.openDocPreviewDialog)}
                onRenameClick={renameDoc && this.closeMenuAndCallAction(this.openRenameDocDialog)}
                onDeleteClick={this.closeMenuAndCallAction(this.openDeleteConfirmationDialog)}
                onDownloadClick={this.closeMenuAndCallAction(() =>
                    activeDocDetail && activeDocDetail.doc && downloadDoc(activeDocDetail.doc.id)
                )}
                closeMenuAndCallAction={this.closeMenuAndCallAction}
                hiddenMenuItems={activeDocDetail && getHiddenMenuItems
                    ? getHiddenMenuItems(activeDocDetail)
                    : undefined}
                anchorReference={menuAnchorReference}
                disableDeleteButton={disableDeleteButton}
            />
        );
    };

    renderDocPreviewDialog = () => {
        const { docsType, activeFuneralHomeId, zIndex } = this.props;
        const { isDocPreviewDialogOpen, activeDocDetail } = this.state;

        if (!activeDocDetail || !activeDocDetail.doc) {
            return null;
        }

        return (
            <DocPreviewDialog
                isDialogOpen={isDocPreviewDialogOpen}
                closeDialog={this.closeDocPreviewDialog}
                docId={activeDocDetail.doc.id}
                docType={docsType}
                zIndex={zIndex + 2}
                activeFuneralHomeId={activeFuneralHomeId}
            />
        );
    };

    renderDocumentRenameDialog = () => {
        const { zIndex, renameDoc } = this.props;
        const { isRenameDialogOpen, activeDocDetail } = this.state;

        if (!activeDocDetail
            || !activeDocDetail.doc
            || activeDocDetail.doc.id === null
            || !renameDoc
        ) {
            return null;
        }

        return (
            <DocumentRenameDialog
                key={activeDocDetail.label}
                isDialogOpen={isRenameDialogOpen}
                renameDocHandler={this.renameDocHandler}
                existingName={activeDocDetail.label}
                closeDialog={this.closeRenameDocDialog}
                zIndex={zIndex + 2}
            />
        );
    };

    renderDeleteConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isDeleteConfirmationDialogOpen, activeDocDetail } = this.state;

        if (!activeDocDetail || !activeDocDetail.doc) {
            return null;
        }

        const header: string = `Are you sure you want to delete '${activeDocDetail.label}'?`;
        const confirmationButtonText: string = 'Delete';

        return (
            <ConfirmationDialog
                open={isDeleteConfirmationDialogOpen}
                onClose={this.closeDeleteConfirmationDialog}
                zIndex={zIndex + 2}
                header={header}
                confirmationButtonText={confirmationButtonText}
                onConfirm={this.deleteDocHandler}
            />
        );
    };

    render() {
        const {
            docs,
            rootClass,
            isCompactView,
            isGOMUser,
            intercomTargetPropDocMenu,
            intercomTargetPropDocItem
        } = this.props;

        return (
            <>
                {docs.map(docDetail =>
                    <DocItem
                        key={docDetail.doc ? docDetail.doc.id : docDetail.uid}
                        docDetail={docDetail}
                        rootClass={rootClass}
                        isCompactView={isCompactView}
                        photo={null}
                        isGOMUser={isGOMUser}
                        openMenu={(target) => this.openMenu(target, docDetail)}
                        intercomTargetPropDocMenu={
                            intercomTargetPropDocMenu ? `${intercomTargetPropDocMenu}-DocMenu` : ``}
                        intercomTargetPropDocItem={
                            intercomTargetPropDocItem ? `${intercomTargetPropDocItem}-DocItem` : ``}
                    />
                )}

                {this.renderDeleteConfirmationDialog()}
                {this.renderMenu()}
                {this.renderDocPreviewDialog()}
                {this.renderDocumentRenameDialog()}
            </>
        );
    }

    deleteDocHandler = () => {
        const { deleteDoc } = this.props;
        const { activeDocDetail } = this.state;

        if (!activeDocDetail || !activeDocDetail.doc) {
            log.warn('`activeDocDetail` is missing in DocItemList');
            return;
        }

        deleteDoc(activeDocDetail.doc.id);
        this.closeDeleteConfirmationDialog();
    };

    renameDocHandler = (label: string) => {
        const { renameDoc } = this.props;
        const { activeDocDetail } = this.state;

        if (!renameDoc) {
            return;
        }

        if (!activeDocDetail || !activeDocDetail.doc) {
            log.warn('`activeDocDetail` is missing in DocItemList');
            return;
        }

        renameDoc(activeDocDetail.doc.id, label);
    };

    openRenameDocDialog = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { isRenameDialogOpen: true },
            () => setMenuOpen && setMenuOpen(true)
        );
    };

    closeRenameDocDialog = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { isRenameDialogOpen: false },
            () => setMenuOpen && setMenuOpen(false)
        );
    };

    openDeleteConfirmationDialog = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { isDeleteConfirmationDialogOpen: true },
            () => setMenuOpen && setMenuOpen(true)
        );
    };

    closeDeleteConfirmationDialog = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { isDeleteConfirmationDialogOpen: false },
            () => setMenuOpen && setMenuOpen(false)
        );
    };

    openDocPreviewDialog = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { isDocPreviewDialogOpen: true },
            () => setMenuOpen && setMenuOpen(true)
        );
    };

    closeDocPreviewDialog = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { isDocPreviewDialogOpen: false },
            () => setMenuOpen && setMenuOpen(false)
        );
    };

    openMenu = (target: HTMLElement | undefined, docDetail: DocUXDetail) => {
        const { setMenuOpen } = this.props;

        if (target) {
            this.setState(
                { menuAnchorEle: target, activeDocDetail: docDetail },
                () => setMenuOpen && setMenuOpen(true)
            );
        }
    };

    closeMenu = () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { menuAnchorEle: null },
            () => setMenuOpen && setMenuOpen(false)
        );
    };

    closeMenuAndCallAction = (callback: () => void) => () => {
        const { setMenuOpen } = this.props;

        this.setState(
            { menuAnchorEle: null },
            () => {
                callback();
                if (setMenuOpen) {
                    setMenuOpen(false);
                }
            }
        );
    };
}

export default DocItemList;