import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import CanNotLoginWrapper from '../widgets/CanNotLoginWrapper';
import { CanNotLoginProps } from '../utils';

const CanNotLogin = (props: CanNotLoginProps) => {
    const { activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanNotLoginWrapper onReturnClick={onStepChange}>
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.fontSize28,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Contact Hulu
            </Typography>

            <Typography
                align="left"
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                It looks like you won't be able to log in to {activeCase.fname}'s Hulu account. No worries,
                you can still reach out to Hulu by calling them directly at 888-755-7907. If you
                run into any problems, we are always here and happy to help.
            </Typography>

            <Grid className={classes.marginTop20}>
                <a
                    className={classes.textDecorationNone}
                    href="tel:888-755-7907"
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                    >
                        CALL HULU
                    </Button>
                </a>
            </Grid>
        </CanNotLoginWrapper>
    );
};

export default CanNotLogin;
