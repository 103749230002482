import { IndexFileCaseDetails } from '../shared/html/case/caseLoadingPage';

const isMetaElement = (obj: HTMLElement): obj is HTMLMetaElement => {
    const metaEl = obj as HTMLMetaElement;
    return metaEl.content !== undefined;
};

const isAnchorElement = (obj: HTMLElement): obj is HTMLAnchorElement => {
    const anchorEl = obj as HTMLAnchorElement;
    return anchorEl.href !== undefined;
};

export const extractCaseDataFromIndex = (): IndexFileCaseDetails => {
    const metaList = Array.from(
        document.head.querySelectorAll<HTMLMetaElement | HTMLAnchorElement>(`[id^='meta-']`)
    );

    const metaData = metaList.reduce<IndexFileCaseDetails>((caseData, el) => {
        if (isMetaElement(el)) {
            switch (el.id) {
                case 'meta-case-profile-photo':
                    caseData.casePhoto = el.content;
                    break;
                case 'meta-case-name':
                    caseData.caseName = el.content;
                    break;
                case 'meta-case-fname':
                    caseData.caseFname = el.content;
                    break;
                case 'meta-case-lname':
                    caseData.caseLname = el.content;
                    break;
                case 'meta-case-display-name':
                    caseData.displayName = el.content;
                    break;
                case 'meta-case-theme-color':
                    caseData.themeColor = el.content;
                    break;
                default:
                    break;
            }
        } else if (isAnchorElement(el)) {
            switch (el.id) {
                case 'meta-fh-logo':
                    caseData.fhLogo = el.href;
                    break;
                default:
                    break;
            }
        }

        return caseData;
    }, {} as IndexFileCaseDetails);

    return metaData;
};
