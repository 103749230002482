import { useState } from 'react';

import CompletedStep from './CompletedStep';
import { TrackingItem, TrackingItemType } from '../BodyTrackingContent';
import QRCompletedStepsMenu from './QRCompletedStepsMenu';
import noop from 'lodash/noop';

interface Props {
    items: TrackingItem[];
    caseFName: string;
    keepTrackKey: string;
    openNotesDialog: VoidFunction;
    caseUuid: string;
}

const QRCompletedSteps = (props: Props) => {
    const { items, caseFName, keepTrackKey, openNotesDialog, caseUuid } = props;

    const [activeItem, setActiveItem] = useState<TrackingItem | null>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const isNote = activeItem?.itemType === TrackingItemType.note;

    return (
        <>
            {items.map((step, index) =>
                <CompletedStep
                    key={index}
                    step={step}
                    caseFName={caseFName}
                    keepTrackKey={keepTrackKey}
                    isLastStep={index === (items.length - 1)}
                    openNotesDialog={openNotesDialog}
                    openMenu={e => {
                        setMenuAnchorEl(e.currentTarget);
                        setActiveItem(step);
                    }}
                    caseUuid={caseUuid}
                />
            )}

            {activeItem &&
                <QRCompletedStepsMenu
                    zIndex={1320}
                    activeStep={{
                        stepId: activeItem.stepId,
                        status: activeItem.status,
                        type: activeItem.itemType,
                        onClick: activeItem.onClick || noop
                    }}
                    menuAnchor={menuAnchorEl}
                    openNotesDialog={openNotesDialog}
                    isNote={isNote}
                    closeMenu={() => setMenuAnchorEl(null)}
                />
            }
        </>
    );
};

export default QRCompletedSteps;
