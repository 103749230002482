import Button from '@mui/material/Button';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { Typography } from '@mui/material';
import {
    PaymentTransaction,
    Transaction,
    PaymentRequest,
    PaymentMode,
    PaymentType,
    PaymentMethod,
} from '../../../../../shared/types';
import { Payer } from '..';
import Dinero from 'dinero.js';
import makeGStyles from '../../../../../styles/makeGStyles';
import DisabledPermissionTooltip from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';

const useStyles = makeGStyles((theme) => ({
    root: {
        position: 'relative',
        margin: '36px 0 18px'
    },
    inner: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '24px 0',
        '& button': {
            marginTop: 24,
        }
    },
    policy: {
        fontWeight: 200,
        width: 'calc(100% - 16px)',
        textAlign: 'center',
        borderBottom: '1px solid',
        marginBottom: 8,
        paddingBottom: 8,
    },
    amountFee: {
        fontWeight: 200,
        fontSize: '14px !important',
    }
}), { name: 'PolicyCard' });

interface Props {
    payer: Payer;
    payment: PaymentTransaction;
    funeralHomeCaseId: number;
    showPaymentDialog: (paymentInfo: PaymentRequest, payer: Payer, isRestricted: boolean) => void;
}

const PolicyCard = (props: Props) => {
    const {
        payer,
        payment,
        funeralHomeCaseId,
        showPaymentDialog,
    } = props;

    const classes = useStyles();

    const dollarAmount = Transaction.dollarFormatUnsigned(payment);
    const fee = Transaction.dollarFormatNumberAbs(payment.merch_fee || 0);

    const _showPaymentDialog = () => {
        const paymentInfo: PaymentRequest = {
            payment_id: payment.payment_id,
            payer_id: payment.payer_id,
            payer_name: '',
            method: PaymentMethod.insurance,
            mode: PaymentMode.IN_PERSON,
            type: PaymentType.CASE,
            amount: Dinero({ amount: payment.credit, currency: 'USD' }),
            merch_fee: Dinero({ amount: payment.merch_fee || 0, currency: 'USD' }),
            memo: payment.description || '',
            funeralHomeCaseId,
            is_anon: false,
            external_id: payment.external_id || ''
        };

        showPaymentDialog(paymentInfo, payer, false,);
    };

    return (
        <div className={classes.inner}>
            <Typography
                color="secondary"
                className={classes.policy}
            >
                Policy:&nbsp;
                <span className={classes.fontWeight500}>{payment.external_id}</span>
            </Typography>

            <Typography
                color="secondary"
                className={classes.amountFee}
            >
                Amount:&nbsp;
                <span className={classes.fontWeight500}>{dollarAmount}</span>
            </Typography>

            <Typography
                color="secondary"
                className={classes.amountFee}
            >
                Convenience Fee:&nbsp;
                <span className={classes.fontWeight500}>{fee}</span>
            </Typography>

            <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                {disabled =>
                    <Button
                        disabled={disabled}
                        color="primary"
                        variant="contained"
                        onClick={_showPaymentDialog}
                    >
                        <InsertDriveFileIcon />&nbsp;
                        Record payment on policy
                    </Button>
                }
            </DisabledPermissionTooltip>
        </div>
    );
};

export default PolicyCard;
