import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ClearIcon from '@mui/icons-material/Clear';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import { GatherCaseUX } from '../../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            width: 300,
            maxWidth: 300,
            minWidth: 300,
            height: 'auto',
            borderRadius: 12,
            boxShadow: theme.shadows[5],
            [theme.breakpoints.up('md')]: {
                maxWidth: 300,
                minWidth: 300,
                width: 300,
                height: 'auto',
                maxHeight: 'auto'
            }
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: 16,
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    clearIcon: {
        zIndex: 1,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        cursor: 'pointer',
        filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,.8))',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    lockIcon: {
        color: 'inherit',
        fontSize: 120
    },
    header: {
        fontSize: 24,
        color: 'inherit',
        borderBottom: '1px solid',
        textAlign: 'center',
        width: '100%'
    },
    subHeader: {
        color: 'inherit',
        fontSize: 13,
        fontWeight: 200,
        lineHeight: '2em',
        marginBottom: 16,
    },
    details: {
        color: 'inherit',
        fontSize: 14,
        fontWeight: 200
    },
    button: {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        margin: '24px auto 8px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            opacity: 0.8,
        },
        '&:disabled': {
            color: 'rgba(255,255,255,0.5)',
            backgroundColor: 'rgba(0, 0, 0, 0.24)'
        }
    },
    dialogPaper: {}
});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon' | 'lockIcon' | 'header'
        | 'subHeader' | 'button' | 'details'>;

interface Props {
    activeCase: GatherCaseUX;
    isDialogOpen: boolean;
    unfreezeReason: string | null;
    closeDialog: () => void;
    unfreezeProductContract: () => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

type CombinedProps = DialogProps & StyledProps;
const UnfreezeContractDialog = (props: CombinedProps) => {
    const {
        classes,
        fullScreen,
        isDialogOpen,
        closeDialog,
        activeCase,
        unfreezeReason,
        zIndex,
        unfreezeProductContract
    } = props;

    const onUnfreezeProductContract = () => {
        unfreezeProductContract();
        closeDialog();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={closeDialog}
            className={classes.root}
            classes={{ paper: classes.dialogPaper }}
            style={{ zIndex }}
        >
            <DialogContent className={classes.dialogContent}>
                <ClearIcon
                    className={classes.clearIcon}
                    onClick={closeDialog}
                />
                <Grid container justifyContent="center" alignItems="center">
                    <AcUnitIcon className={classes.lockIcon} />
                    <Typography className={classes.header}>
                        Statement is Frozen
                    </Typography>

                    <Typography className={classes.subHeader}>
                        {unfreezeReason}
                    </Typography>

                    <Typography className={classes.subHeader}>
                        If you unfreeze {activeCase.fname}'s statement:
                    </Typography>

                    <Typography className={classes.details}>
                        You will be able to make statement edits
                    </Typography>
                    <Typography className={classes.details}>
                        Edits will be tracked as revisions IF frozen
                    </Typography>

                    <DisabledPermissionTooltip permission={Permission.CONTRACT_UNFREEZE}>
                        {disabled =>
                            <Button
                                className={classes.button}
                                variant="contained"
                                disabled={disabled}
                                onClick={onUnfreezeProductContract}
                            >
                                <AcUnitIcon />&nbsp;Unfreeze Statement
                            </Button>
                        }
                    </DisabledPermissionTooltip>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withFullScreen()(withGStyles(styles)(UnfreezeContractDialog));
