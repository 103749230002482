
interface FsDocument extends HTMLDocument {
    fullscreenElement: Element | null;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    msExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;
    webkitExitFullscreen?: () => void;
}

export interface FsDocumentElement extends HTMLElement {
    msRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
    webkitRequestFullscreen?: () => void;
}

export const isFullScreen = (): boolean => {
    const fsDoc = <FsDocument> document;
    return !!(fsDoc.fullscreenElement
        || fsDoc.mozFullScreenElement
        || fsDoc.webkitFullscreenElement
        || fsDoc.msFullscreenElement);
};

export const toggleFullScreen = (exitMode?: boolean): void => {
    const fsDoc = <FsDocument> document;
    try {
        const fullscreen = isFullScreen();
        if (!fullscreen && !exitMode) {

            const fsDocElem = <FsDocumentElement> document.documentElement;

            if (fsDocElem.requestFullscreen) {
                fsDocElem.requestFullscreen();
                return;
            }
            if (fsDocElem.msRequestFullscreen) {
                fsDocElem.msRequestFullscreen();
                return;
            }
            if (fsDocElem.mozRequestFullScreen) {
                fsDocElem.mozRequestFullScreen();
                return;
            }
            if (fsDocElem.webkitRequestFullscreen) {
                fsDocElem.webkitRequestFullscreen();
                return;
            }
        }

        if (fsDoc.fullscreenElement && fullscreen) {
            fsDoc.exitFullscreen().catch(e => console.warn('fullscreen error:', e));
            return;
        }

        if (fsDoc.exitFullscreen && fullscreen) {
            fsDoc.exitFullscreen().catch(e => console.warn('fullscreen error:', e));
            return;
        }

        if (fsDoc.msExitFullscreen && fullscreen) {
            fsDoc.msExitFullscreen();
            return;
        }
        if (fsDoc.mozCancelFullScreen && fullscreen) {
            fsDoc.mozCancelFullScreen();
            return;
        }
        if (fsDoc.webkitExitFullscreen && fullscreen) {
            fsDoc.webkitExitFullscreen();
            return;
        }
    } catch (e) {
        console.warn('fullscreen error:', e);
    }
};