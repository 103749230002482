import generateUuid from 'uuid';
import { getFromAPI, putToAPI, postToAPI, deleteFromAPI } from '.';
import { fetchCloudinarySignature, cloudinaryUpload } from './Cloudinary.action';
import { registerAppError } from './errors';

import {
    GatherCaseUX,
    Photo,
    CaseGiftPhotoState,
    CaseGiftPhotoUX,
    CaseGiftPhotoType,
    CaseGiftPhotoTypeWithState,
    CaseGiftPhotoRequest,
    PhotoTypeEnum,
    AlbumEntryMappedType,
    DeleteReturnType,
    GatherCasePublic,
    UploadPhotoResponse,
    isAlbumEntry,
    UploadPhotoRequest,
    ModerationStatus,
} from '../shared/types';
import { StoreState, GatherPhoto, PhotoStatusEnum, GatherCaseForPhotoDelete } from '../types';
import { AppDispatch } from '../store';
import { log } from '../logger';
import { canRetrievePrivateCaseData, canUploadCasePhoto } from '../shared/authority/can';

export async function uploadPhoto(
    dataURI: string,
    signatureUrl: string,
    dispatch: AppDispatch
): Promise<UploadPhotoResponse | null> {

    const signaturePayload = await fetchCloudinarySignature(signatureUrl, dispatch);
    if (!signaturePayload) {
        return null;
    }

    const photo = await cloudinaryUpload(dataURI, signaturePayload);
    return photo && photo.public_id && photo.width && photo.height ? {
        public_id: photo.public_id,
        width: photo.width,
        height: photo.height
    } : null;
}

export const SET_CASE_PHOTOS = 'SET_CASE_PHOTOS';
export type SET_CASE_PHOTOS = typeof SET_CASE_PHOTOS;

interface SetCasePhotos {
    type: SET_CASE_PHOTOS;
    photos: GatherPhoto[];
    caseUuid: string;
}

function setCasePhotos(caseUuid: string, photos: GatherPhoto[]): SetCasePhotos {
    return {
        type: SET_CASE_PHOTOS,
        photos,
        caseUuid,
    };
}

export const SET_CASE_PHOTOS_LOADING = 'SET_CASE_PHOTOS_LOADING';
export type SET_CASE_PHOTOS_LOADING = typeof SET_CASE_PHOTOS_LOADING;

interface SetCasePhotosLoading {
    type: SET_CASE_PHOTOS_LOADING;
    isLoading: boolean;
}

function setCasePhotosLoading(isLoading: boolean): SetCasePhotosLoading {
    return {
        type: SET_CASE_PHOTOS_LOADING,
        isLoading,
    };
}

export const SET_CASE_PROFILE_PHOTO_SAVING = 'SET_CASE_PROFILE_PHOTO_SAVING';
export type SET_CASE_PROFILE_PHOTO_SAVING = typeof SET_CASE_PROFILE_PHOTO_SAVING;

interface SetCaseProfilePhotoSaving {
    type: SET_CASE_PROFILE_PHOTO_SAVING;
    isSaving: boolean;
}

export function setCaseProfilePhotoSaving(isSaving: boolean): SetCaseProfilePhotoSaving {
    return {
        type: SET_CASE_PROFILE_PHOTO_SAVING,
        isSaving,
    };
}

export function photoToGatherPhoto(photo: Photo | AlbumEntryMappedType): GatherPhoto {
    return {
        status: PhotoStatusEnum.uploaded,
        gatherId: generateUuid(),
        photo,
    };
}

export function dataURIToGatherPhoto(dataURI: string): GatherPhoto {
    return {
        status: PhotoStatusEnum.uploading,
        gatherId: generateUuid(),
        photo: null,
        dataURI,
    };
}

export const OPEN_GLOBAL_DELETE_DIALOG = 'OPEN_GLOBAL_DELETE_DIALOG';
export type OPEN_GLOBAL_DELETE_DIALOG = typeof OPEN_GLOBAL_DELETE_DIALOG;

interface OpenGlobalDeleteDialog {
    type: OPEN_GLOBAL_DELETE_DIALOG;
    gatherCase: GatherCaseForPhotoDelete;
    zIndex: number;
    albumEntryId: number;
    references: string[];
}

export function openGlobalDeleteDialog(
    gatherCase: GatherCaseForPhotoDelete,
    zIndex: number,
    albumEntryId: number,
    references: string[]
): OpenGlobalDeleteDialog {
    return {
        type: OPEN_GLOBAL_DELETE_DIALOG,
        zIndex,
        gatherCase,
        albumEntryId,
        references
    };
}

export const CLOSE_GLOBAL_DELETE_DIALOG = 'CLOSE_GLOBAL_DELETE_DIALOG';
export type CLOSE_GLOBAL_DELETE_DIALOG = typeof CLOSE_GLOBAL_DELETE_DIALOG;

interface CloseGlobalDeleteDialog {
    type: CLOSE_GLOBAL_DELETE_DIALOG;
}

export function closeGlobalDeleteDialog(): CloseGlobalDeleteDialog {
    return {
        type: CLOSE_GLOBAL_DELETE_DIALOG,
    };
}

export function loadCasePhotos(caseUuid: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setCasePhotosLoading(true));
        const casePhotos = await getFromAPI<AlbumEntryMappedType[]>(`api/case/${caseUuid}/photo/`, dispatch);
        if (casePhotos) {
            const gatherPhotos = casePhotos.map(photoToGatherPhoto);
            dispatch(setCasePhotos(caseUuid, gatherPhotos));
        } else {
            dispatch(registerAppError('Unable to load photos.'));
        }
        dispatch(setCasePhotosLoading(false));
    };
}

export const ADD_CASE_PHOTO = 'ADD_CASE_PHOTO';
export type ADD_CASE_PHOTO = typeof ADD_CASE_PHOTO;

interface AddCasePhoto {
    type: ADD_CASE_PHOTO;
    photo: GatherPhoto;
    caseId: number;
}

function addCasePhoto(caseId: number, photo: GatherPhoto): AddCasePhoto {
    return {
        type: ADD_CASE_PHOTO,
        photo,
        caseId,
    };
}

export const UPDATE_CASE_PHOTO = 'UPDATE_CASE_PHOTO';
export type UPDATE_CASE_PHOTO = typeof UPDATE_CASE_PHOTO;

interface UpdateCasePhoto {
    type: UPDATE_CASE_PHOTO;
    photo: GatherPhoto;
    caseUuid: string;
}

function updateCasePhoto(caseUuid: string, photo: GatherPhoto): UpdateCasePhoto {
    return {
        type: UPDATE_CASE_PHOTO,
        photo,
        caseUuid,
    };
}

export function uploadCasePhotos(dataURIs: string[], gatherCase: GatherCaseUX | GatherCasePublic) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherPhoto[] | null> => {
        const { userData } = getState().userSession;

        if (!canUploadCasePhoto({ user: userData, activeCase: gatherCase })) {
            log.warn('User not allowed to upload case photo(s)', { userData, gatherCase });
            return null;
        }

        const canGetPrivateData = canRetrievePrivateCaseData({
            target: userData,
            caseId: gatherCase.id,
            funeralHomeId: gatherCase.funeral_home.id,
        });

        const urlRoot = canGetPrivateData ? `api/case/${gatherCase.uuid}` : `api/remember/${gatherCase.name}`;

        const results: GatherPhoto[] = [];
        // upload photos serially
        for (let i = 0; i < dataURIs.length; i++) {
            const dataURI = dataURIs[i];
            const gatherPhoto = dataURIToGatherPhoto(dataURI);
            dispatch(addCasePhoto(gatherCase.id, gatherPhoto));

            // unique signature per photo to get names with timestamp
            const gatherSignatureURL = `${urlRoot}/photo/signature`;

            const cloudinaryPhoto = await uploadPhoto(dataURI, gatherSignatureURL, dispatch);
            if (cloudinaryPhoto) {
                const uploadRequest: UploadPhotoRequest = {
                    publicId: cloudinaryPhoto.public_id,
                    width: cloudinaryPhoto.width,
                    height: cloudinaryPhoto.height
                };

                try {
                    UploadPhotoRequest.fromRequest(uploadRequest);
                } catch (e) {
                    log.warn('Failed to validate partial UploadPhotoRequest', { e, uploadRequest });
                    return null;
                }
                const uploadedPhoto = await postToAPI<AlbumEntryMappedType>(
                    `${urlRoot}/photo`,
                    uploadRequest,
                    dispatch,
                );
                if (uploadedPhoto && uploadedPhoto.id) {
                    const updatedGatherPhoto: GatherPhoto = {
                        ...gatherPhoto,
                        photo: uploadedPhoto,
                        status: PhotoStatusEnum.uploaded,
                    };
                    dispatch(updateCasePhoto(gatherCase.uuid, updatedGatherPhoto));
                    results.push(updatedGatherPhoto);
                    continue;
                }
            }
            dispatch(registerAppError('Unable to upload photo.'));
            gatherPhoto.status = PhotoStatusEnum.failed;
            results.push(gatherPhoto);
            dispatch(updateCasePhoto(gatherCase.uuid, gatherPhoto));
            continue;
        }
        // let pages know we have uploaded something
        const event = new Event('gather.user_photos_uploaded', { bubbles: true, cancelable: true });
        document.dispatchEvent(event);
        return results;
    };
}

function uploadProductPhotos(gatherPhotos: GatherPhoto[], signatureRoute: string) {
    return async (dispatch: AppDispatch): Promise<GatherPhoto[]> => {
        const results: GatherPhoto[] = [];
        for (let i = 0; i < gatherPhotos.length; i++) {
            const gatherPhoto = gatherPhotos[i];
            if (!gatherPhoto.dataURI) {
                continue;
            }

            const cloudinaryPhoto = await uploadPhoto(gatherPhoto.dataURI, signatureRoute, dispatch);
            if (cloudinaryPhoto) {
                results.push({
                    ...gatherPhoto,
                    status: PhotoStatusEnum.uploaded,
                    photo: {
                        public_id: cloudinaryPhoto.public_id,
                        width: cloudinaryPhoto.width,
                        height: cloudinaryPhoto.height,
                        uploaded_by: -1,
                        photo_type: PhotoTypeEnum.product,
                        moderated_by: 0,
                        moderation_reason: 'product photo',
                        moderation_status: ModerationStatus.approved,
                        moderation_time: new Date(),
                        moderation_required: false,
                        transformations: {},
                    },
                });
                continue;
            }
            dispatch(registerAppError('Unable to upload photo.'));
            gatherPhoto.status = PhotoStatusEnum.failed;
            results.push(gatherPhoto);
        }
        return results;
    };
}

export function uploadGlobalProductPhotos(gatherPhotos: GatherPhoto[]) {
    return async (dispatch: AppDispatch): Promise<GatherPhoto[]> => {
        const gatherSignatureURL = 'api/product/photo/signature';
        return await dispatch(uploadProductPhotos(gatherPhotos, gatherSignatureURL));
    };
}

export function uploadFuneralHomeProductPhotos(gatherPhotos: GatherPhoto[], funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<GatherPhoto[]> => {
        const gatherSignatureURL = `funeralhome/${funeralHomeId}/product/photo/signature`;
        return await dispatch(uploadProductPhotos(gatherPhotos, gatherSignatureURL));
    };
}

export function uploadProductSupplierPhoto(dataURI: string) {
    return async (dispatch: AppDispatch): Promise<UploadPhotoResponse | null> => {
        // use the same signature for all uploads
        const gatherSignatureURL = 'api/product/photo/signature';
        const cloudinaryResult = await uploadPhoto(dataURI, gatherSignatureURL, dispatch);
        if (cloudinaryResult) {
            return cloudinaryResult;
        }
        dispatch(registerAppError('Unable to upload photo.'));
        return null;
    };
}

export const DELETE_CASE_PHOTO = 'DELETE_CASE_PHOTO';
export type DELETE_CASE_PHOTO = typeof DELETE_CASE_PHOTO;

interface DeleteCasePhoto {
    type: DELETE_CASE_PHOTO;
    albumEntryId: number;
    caseId: number;
}

function deleteCasePhotoInStore(caseId: number, albumEntryId: number): DeleteCasePhoto {
    return {
        type: DELETE_CASE_PHOTO,
        albumEntryId,
        caseId,
    };
}

export function deleteCasePhoto(params: {
    gatherCase: GatherCaseForPhotoDelete;
    albumEntryId: number;
    force?: boolean;
    zIndex?: number;
}) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const { gatherCase, albumEntryId, force = false, zIndex = 1500 } = params;
        const { userData } = getState().userSession;

        const canGetPrivateData = canRetrievePrivateCaseData({
            target: userData,
            caseId: gatherCase.id,
            funeralHomeId: gatherCase.funeral_home.id,
        });

        const urlRoot = canGetPrivateData ? `api/case/${gatherCase.uuid}` : `api/remember/${gatherCase.name}`;

        // if forcing we don't have to wait to remove locally
        if (force) {
            dispatch(deleteCasePhotoInStore(gatherCase.id, albumEntryId));
        }
        const deleteURL = `${urlRoot}/photo/${albumEntryId}${force ? '/force' : ''}`;
        const deletedPhoto = await deleteFromAPI<DeleteReturnType>(deleteURL, dispatch);
        if (!deletedPhoto) {
            dispatch(registerAppError('Unable to delete photo.'));
        } else if (deletedPhoto.references) {
            dispatch(openGlobalDeleteDialog(gatherCase, zIndex, albumEntryId, deletedPhoto.references));
            return;
        }
        // if not forcing we need to wait to see if the image is being used before removing
        if (!force) {
            dispatch(deleteCasePhotoInStore(gatherCase.id, albumEntryId));
        }
        // let pages know we have deleted something
        const event = new Event('gather.user_photo_deleted', { bubbles: true, cancelable: true });

        document.dispatchEvent(event);
    };
}

export const downloadCasePhotos = (caseUuid: string) => {
    return async (dispatch: AppDispatch): Promise<string | null> => {

        const response = await getFromAPI<{ downloadUrl: string }>(
            `api/case/${caseUuid}/photo/download_url`, dispatch,
        );
        if (!response) {
            dispatch(registerAppError('Download photos unavailable. Please try again later!'));
            return null;
        }
        return response.downloadUrl;
    };
};

// ***** Case Gift Photo Actions *****

export const SET_CASE_GIFT_PHOTOS = 'SET_CASE_GIFT_PHOTOS';
export type SET_CASE_GIFT_PHOTOS = typeof SET_CASE_GIFT_PHOTOS;

interface SetCaseGiftPhotos {
    type: SET_CASE_GIFT_PHOTOS;
    photos: CaseGiftPhotoState[];
    caseUuid: string;
}

function setCaseGiftPhotos(caseUuid: string, photos: CaseGiftPhotoState[]): SetCaseGiftPhotos {
    return {
        type: SET_CASE_GIFT_PHOTOS,
        photos,
        caseUuid,
    };
}

export const SET_CASE_GIFT_PHOTOS_LOADING = 'SET_CASE_GIFT_PHOTOS_LOADING';
export type SET_CASE_GIFT_PHOTOS_LOADING = typeof SET_CASE_GIFT_PHOTOS_LOADING;

interface SetCaseGiftPhotosLoading {
    type: SET_CASE_GIFT_PHOTOS_LOADING;
    isLoading: boolean;
}

function setCaseGiftPhotosLoading(isLoading: boolean): SetCaseGiftPhotosLoading {
    return {
        type: SET_CASE_GIFT_PHOTOS_LOADING,
        isLoading,
    };
}

export const ADD_CASE_GIFT_PHOTO = 'ADD_CASE_GIFT_PHOTO';
export type ADD_CASE_GIFT_PHOTO = typeof ADD_CASE_GIFT_PHOTO;

interface AddCaseGiftPhoto {
    type: ADD_CASE_GIFT_PHOTO;
    photo: CaseGiftPhotoState;
    caseId: number;
}

export function addCaseGiftPhoto(caseId: number, photo: CaseGiftPhotoState): AddCaseGiftPhoto {
    return {
        type: ADD_CASE_GIFT_PHOTO,
        photo,
        caseId,
    };
}

export const UPDATE_CASE_GIFT_PHOTO = 'UPDATE_CASE_GIFT_PHOTO';
export type UPDATE_CASE_GIFT_PHOTO = typeof UPDATE_CASE_GIFT_PHOTO;

interface UpdateCaseGiftPhoto {
    type: UPDATE_CASE_GIFT_PHOTO;
    photo: CaseGiftPhotoState;
    caseUuid: string;
}

export function updateCaseGiftPhoto(caseUuid: string, photo: CaseGiftPhotoState): UpdateCaseGiftPhoto {
    return {
        type: UPDATE_CASE_GIFT_PHOTO,
        photo,
        caseUuid,
    };
}

export const DELETE_CASE_GIFT_PHOTO = 'DELETE_CASE_GIFT_PHOTO';
export type DELETE_CASE_GIFT_PHOTO = typeof DELETE_CASE_GIFT_PHOTO;

interface DeleteCaseGiftPhotoInStore {
    type: DELETE_CASE_GIFT_PHOTO;
    uuid: string;
    caseUuid: string;
    avoidNewBlankPhotoCreation?: boolean;
}

function deleteCaseGiftPhotoInStore(
    caseUuid: string,
    uuid: string,
    avoidNewBlankPhotoCreation?: boolean
): DeleteCaseGiftPhotoInStore {
    return {
        type: DELETE_CASE_GIFT_PHOTO,
        uuid,
        caseUuid,
        avoidNewBlankPhotoCreation
    };
}

const defaultGiftPhotoType: CaseGiftPhotoTypeWithState = {
    isUploaded: false,
    isUploading: false,
    public_id: '',
    width: 0,
    height: 0,
    dataURI: null,
    giver_name: '',
    giver_display_name: '',    
    card_message: '',
    giver_photo: '',
    project_name: '',
    quantity: 1,
};

export function createBlankCaseGiftPhoto(caseUuid: string): CaseGiftPhotoState {
    return {
        uuid: generateUuid(),
        gather_case_id: -1,
        case_uuid: caseUuid,
        excluded: null,
        gift_photo: defaultGiftPhotoType,
        card_photo: defaultGiftPhotoType,
        created_time: new Date(),
        type: 'photo',
        giver_name: '',
        giver_display_name: '',
        card_message: '',
        giver_photo: '',
        quantity: 1,
        project_name: '',
    };
}

export function isCardComplete(giftPhoto: CaseGiftPhotoState, checkDataURI?: boolean): boolean {
    return giftPhoto.excluded === CaseGiftPhotoType.card
        || giftPhoto.card_photo.public_id !== ''
        || Boolean(checkDataURI) && giftPhoto.card_photo.dataURI !== null;
}

export function isGiftComplete(giftPhoto: CaseGiftPhotoState, checkDataURI?: boolean): boolean {
    return giftPhoto.excluded === CaseGiftPhotoType.gift
        || giftPhoto.gift_photo.public_id !== ''
        || Boolean(checkDataURI) && giftPhoto.gift_photo.dataURI !== null;
}

export function isCaseGiftPhotoComplete(giftPhoto: CaseGiftPhotoState, checkDataURI?: boolean): boolean {
    return isCardComplete(giftPhoto, checkDataURI) && isGiftComplete(giftPhoto, checkDataURI);
}

export function isCaseGiftPhotoInComplete(giftPhoto: CaseGiftPhotoState, checkDataURI?: boolean): boolean {
    if (giftPhoto.excluded) {
        return giftPhoto.excluded === CaseGiftPhotoType.gift ?
            !isCardComplete(giftPhoto, checkDataURI) : !isGiftComplete(giftPhoto, checkDataURI);
    }
    return !isCardComplete(giftPhoto, checkDataURI) && !isGiftComplete(giftPhoto, checkDataURI);
}

export function processCaseGiftPhotoList(
    caseUuid: string,
    giftPhotoUXList: CaseGiftPhotoUX[],
    existingList: CaseGiftPhotoState[],
): CaseGiftPhotoState[] {
    let giftPhotoList = giftPhotoUXList.map(CaseGiftPhotoState.fromUXType);

    const existingFirstPhoto = existingList[0];

    // check if a blank caseGiftPhoto is already in the list (happens when updating an already completed box)
    // also check to make sure the existingFirstPhoto is for this case
    if (existingFirstPhoto && existingFirstPhoto.case_uuid === caseUuid
        && isCaseGiftPhotoInComplete(existingFirstPhoto)
        && giftPhotoList.every((photo) => photo.uuid !== existingFirstPhoto.uuid)) {

        giftPhotoList = [existingFirstPhoto, ...giftPhotoList];

        // check if the most recently created giftPhoto is complete or if the list is empty
        // or if the existing blank photo is for the wrong case
    } else if (!giftPhotoList[0]
        || (existingFirstPhoto && existingFirstPhoto.case_uuid !== caseUuid)
        || (isCardComplete(giftPhotoList[0], true)
            && isGiftComplete(giftPhotoList[0], true))
    ) {
        const newBlank = createBlankCaseGiftPhoto(caseUuid);
        giftPhotoList = [newBlank, ...giftPhotoList];
    }
    return giftPhotoList;
}

function handleCaseGiftPhotoList(caseUuid: string, giftPhotoUXList: CaseGiftPhotoUX[]) {
    return (dispatch: AppDispatch, getState: () => StoreState): void => {
        const { caseGiftPhotos } = getState().casesState;
        const processedList = processCaseGiftPhotoList(caseUuid, giftPhotoUXList, caseGiftPhotos);
        dispatch(setCaseGiftPhotos(caseUuid, processedList));
    };
}

// if UUID is not defined generate one
export function uploadPhotoToCaseGiftPhoto(
    dataURI: string,
    uuid: string,
    gatherCase: GatherCaseUX | GatherCasePublic,
    photoType: CaseGiftPhotoType,
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<void> => {
        const { caseGiftPhotos } = getState().casesState;
        const caseGiftPhoto = caseGiftPhotos.find((photo) => photo.uuid === uuid);
        if (!caseGiftPhoto) {
            return;
        }

        const isCard = photoType === CaseGiftPhotoType.card;

        let stateChanges: CaseGiftPhotoTypeWithState = {
            ...defaultGiftPhotoType,
            isUploading: true,
            dataURI,
        };

        let changesToMake = isCard ? { card_photo: stateChanges } : { gift_photo: stateChanges };
        let updatedCaseGiftPhoto = { ...caseGiftPhoto, ...changesToMake, gather_case_id: gatherCase.id };
        dispatch(updateCaseGiftPhoto(gatherCase.uuid, updatedCaseGiftPhoto));

        // use the same signature for all uploads
        const gatherSignatureURL = `api/case/${gatherCase.uuid}/gift_photo/signature`;

        const cloudinaryPhoto = await uploadPhoto(dataURI, gatherSignatureURL, dispatch);
        if (cloudinaryPhoto) {
            // add public_id to photo
            stateChanges = {
                isUploaded: false,
                isUploading: false,
                public_id: cloudinaryPhoto.public_id,
                width: cloudinaryPhoto.width,
                height: cloudinaryPhoto.height,
                dataURI: null,
                giver_name: '',
                giver_display_name: '',
                card_message: '',
                giver_photo: '',
                project_name: '',
                quantity: 1,
            };
            changesToMake = isCard ? { card_photo: stateChanges } : { gift_photo: stateChanges };

            updatedCaseGiftPhoto = {
                ...updatedCaseGiftPhoto,
                ...changesToMake,
            };
            dispatch(updateCaseGiftPhoto(gatherCase.uuid, updatedCaseGiftPhoto));
            // if caseGiftPhoto complete -> save to API
            if (isCaseGiftPhotoComplete(updatedCaseGiftPhoto)) {
                dispatch(saveCaseGiftPhoto(gatherCase.uuid, updatedCaseGiftPhoto));
            }
            return;
        }

        // reset the photo on failure
        changesToMake = isCard ? { card_photo: defaultGiftPhotoType } : { gift_photo: defaultGiftPhotoType };
        updatedCaseGiftPhoto = {
            ...updatedCaseGiftPhoto,
            ...changesToMake,
        };
        dispatch(updateCaseGiftPhoto(gatherCase.uuid, updatedCaseGiftPhoto));
        dispatch(registerAppError(`Unable to upload ${photoType} photo.`));
        return;
    };
}

// save caseGiftPhoto to API
function saveCaseGiftPhoto(caseUuid: string, giftPhotoState: CaseGiftPhotoState) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const updatedGiftPhotoState = {
            ...giftPhotoState,
            card_photo: {
                ...giftPhotoState.card_photo,
                isUploaded: false,
                isUploading: true,
            },
            gift_photo: {
                ...giftPhotoState.gift_photo,
                isUploaded: false,
                isUploading: true,
            },
        };
        dispatch(updateCaseGiftPhoto(caseUuid, updatedGiftPhotoState));
        let giftPhotoRequest: CaseGiftPhotoRequest;
        // Validate the object before sending to the server, and strip off the UX-only fields
        try {
            giftPhotoRequest = CaseGiftPhotoRequest.fromRequest({
                card_public_id: giftPhotoState.card_photo.public_id,
                card_width: giftPhotoState.card_photo.width,
                card_height: giftPhotoState.card_photo.height,
                gift_public_id: giftPhotoState.gift_photo.public_id,
                gift_width: giftPhotoState.gift_photo.width,
                gift_height: giftPhotoState.gift_photo.height,
                excluded: giftPhotoState.excluded,
            });
        } catch (ex) {
            log.warn('Failed to validate CaseGiftPhotoRequest:', { giftPhotoState, ex });
            return;
        }

        let photoList = await putToAPI<CaseGiftPhotoUX[]>(
            `api/case/${caseUuid}/gift_photo/${giftPhotoState.uuid}`,
            { giftPhoto: giftPhotoRequest },
            dispatch,
        );
        if (!photoList) {
            // try once more
            photoList = await putToAPI<CaseGiftPhotoUX[]>(
                `api/case/${caseUuid}/gift_photo/${giftPhotoState.uuid}`,
                { giftPhoto: giftPhotoRequest },
                dispatch,
            );
        }

        if (photoList) {
            dispatch(handleCaseGiftPhotoList(caseUuid, photoList));
        } else {
            // if request fails for the second time, delete from store
            dispatch(deleteCaseGiftPhotoInStore(caseUuid, giftPhotoState.uuid));
            dispatch(registerAppError('Unable to save gift photo(s)'));
        }
    };
}

export function setCaseGiftPhotoExclusion(
    excluded: CaseGiftPhotoType | null,
    giftPhotoUuid: string,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<void> => {
        const { caseGiftPhotos } = getState().casesState;
        const caseGiftPhoto = caseGiftPhotos.find((photo) => photo.uuid === giftPhotoUuid);
        if (!caseGiftPhoto) {
            return;
        }

        const updatedCaseGiftPhoto = { ...caseGiftPhoto, excluded };
        dispatch(updateCaseGiftPhoto(caseUuid, updatedCaseGiftPhoto));
        // if caseGiftPhoto complete -> save to API
        if (isCaseGiftPhotoComplete(updatedCaseGiftPhoto)) {
            dispatch(saveCaseGiftPhoto(caseUuid, updatedCaseGiftPhoto));
        }
    };
}

export function deleteCaseGiftPhoto(caseGiftPhoto: CaseGiftPhotoState, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { uuid } = caseGiftPhoto;
        dispatch(deleteCaseGiftPhotoInStore(caseUuid, caseGiftPhoto.uuid));

        // only delete from API if it has been saved to API
        if (isCaseGiftPhotoComplete(caseGiftPhoto)) {
            const resource = `api/case/${caseUuid}/gift_photo/${uuid}`;
            const photoList = await deleteFromAPI<CaseGiftPhotoUX[]>(resource, dispatch);
            if (!photoList) {
                dispatch(registerAppError('Unable to delete gift photo(s)'));
            } else {
                dispatch(handleCaseGiftPhotoList(caseUuid, photoList));
            }
        }
    };
}

export function loadGiftCasePhotos(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(setCaseGiftPhotosLoading(true));
        const giftPhotos = await getFromAPI<CaseGiftPhotoUX[]>(`api/case/${caseUuid}/gift_photo/`, dispatch);
        if (giftPhotos) {
            dispatch(handleCaseGiftPhotoList(caseUuid, giftPhotos));
        } else {
            dispatch(registerAppError('Unable to load gift photos.'));
        }
        dispatch(setCaseGiftPhotosLoading(false));
    };
}

export function modifyCasePhoto(caseUuid: string, photo: GatherPhoto, updatedFields: Partial<AlbumEntryMappedType>) {
    return async (dispatch: AppDispatch) => {
        if (!isAlbumEntry(photo.photo)) {
            return;
        }
        // construct our locally updated photo
        const localUpdate = {
            ...photo,
            photo: {
                ...photo.photo,
                ...updatedFields
            }
        };
        dispatch(updateCasePhoto(caseUuid, localUpdate));
        const albumEntries = await putToAPI<AlbumEntryMappedType[]>(
            `api/case/${caseUuid}/photo/${photo.photo.id}/update`,
            { updatedFields },
            dispatch,
        );
        if (!albumEntries) {
            return dispatch(registerAppError('Unable to update case photo.'));
        }
    };
}

export type PhotoAction =
    | SetCasePhotos
    | SetCasePhotosLoading
    | SetCaseProfilePhotoSaving
    | OpenGlobalDeleteDialog
    | CloseGlobalDeleteDialog
    | AddCasePhoto
    | UpdateCasePhoto
    | DeleteCasePhoto
    | SetCaseGiftPhotos
    | SetCaseGiftPhotosLoading
    | AddCaseGiftPhoto
    | UpdateCaseGiftPhoto
    | DeleteCaseGiftPhotoInStore
    ;
