import { memo, useRef, useState } from 'react';
import classNames from 'classnames';

import { ClassNameMap } from '@mui/styles';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import FavoriteIcon from '@mui/icons-material/Favorite';

interface Props {
    isSaved: boolean;
    isSaving: boolean;
    classes: ClassNameMap<
        'fadeContainer' | 'active' | 'header' | 'atBottom' | 'question' | 'textFieldContainer' | 'formControl' |
        'textField' | 'height100' | 'textFieldLabel' | 'multiline' | 'shareButton' | 'disabledButton'
    >;
    isAvatarAtBottom: boolean;
    isAvatarAtTop: boolean;
    question: string | JSX.Element;
    textfieldLabel?: string;
    index?: number;
    onAnswerChange?: (answer: string) => void;
    handleClick: (answer: string) => Promise<boolean>;
}

const TextPrompt = (props: Props) => {
    const {
        isSaved,
        isSaving,
        classes,
        question,
        isAvatarAtBottom,
        textfieldLabel,
        isAvatarAtTop,
        index,
        handleClick,
        onAnswerChange
    } = props;

    const [saveAttempted, setSaveAttempted] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const value = inputRef.current?.value;
        if (!value || !value.trim()) {
            setSaveAttempted(true);
            return;
        }

        setSaveAttempted(false);
        await handleClick(value);
    };

    return (
        <Fade in={!isSaved} timeout={300} mountOnEnter unmountOnExit>
            <div className={classNames(classes.fadeContainer, !isSaved && classes.active)}>
                {!!question &&
                    <div
                        className={classNames(
                            classes.header,
                            isAvatarAtBottom && classes.atBottom
                        )}
                    >
                        <Typography
                            align="center"
                            color="primary"
                            className={classes.question}
                        >
                            <span>
                                {question}
                            </span>
                        </Typography>
                    </div>
                }

                <Grid
                    component="form"
                    onSubmit={handleSubmit}
                    container
                    direction="column"
                    className={classes.textFieldContainer}
                >
                    <FormControl error={saveAttempted} className={classes.formControl}>
                        <TextField
                            label={textfieldLabel || ''}
                            margin="dense"
                            variant="outlined"
                            multiline
                            name="answer"
                            tabIndex={index}
                            minRows={isAvatarAtTop ? 6 : undefined}
                            maxRows={isAvatarAtTop ? 6 : undefined}
                            onChange={({ target }) => onAnswerChange?.(target.value)}
                            className={classNames(classes.textField, classes.height100)}
                            sx={{ mt: !question ? '12px !important' : undefined }}
                            error={saveAttempted}
                            inputProps={{ ref: inputRef }}
                            onFocus={() => setSaveAttempted(false)}
                            InputLabelProps={{ className: classes.textFieldLabel }}
                            InputProps={{
                                classes: { multiline: classes.multiline }
                            }}
                        />
                    </FormControl>

                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.shareButton}
                        disabled={isSaving}
                        classes={{ disabled: classes.disabledButton }}
                    >
                        <FavoriteIcon />&nbsp;
                        <span>Click to Save</span>
                    </Button>
                </Grid>
            </div>
        </Fade >
    );
};

export default memo(TextPrompt);