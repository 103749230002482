import * as t from 'io-ts';

import { ModerationStatus } from './moderation';
import { GuestListUser } from './user';
import { FlowerSaleType, getValidator } from '.';

export enum MemoryPrompt {
    Default = 'Default',
    Candle = 'Candle',
    KnowPerson = 'KnowPerson',
    InterestingFact = 'InterestingFact',
    DescribePerson = 'DescribePerson',
    FunnyStory = 'FunnyStory',
    HundredYears = 'HundredYears',
    BetterPlace = 'BetterPlace',
    Legacy = 'Legacy',
    AlwaysRemember = 'AlwaysRemember',
    Admired = 'Admired',
    FavoriteStory = 'FavoriteStory',
    MadeSpecial = 'MadeSpecial',
    HelpOthers = 'HelpOthers',
    TeachYou = 'TeachYou',
    LoveAbout = 'LoveAbout',
    SayToday = 'SayToday',
    LovedToDo = 'LovedToDo',
    GoodAt = 'GoodAt',
    MakeYouLaugh = 'MakeYouLaugh',
    PossessionPlaceHoliday = 'PossessionPlaceHoliday',
    EarliestMemory = 'EarliestMemory',
    Quotes = 'Quotes',
    FunniestQuirks = 'FunniestQuirks',
    ValueMost = 'ValueMost',
    DescribeCharacter = 'DescribeCharacter'
}

const MemoryPromptType = t.union([
    t.literal(MemoryPrompt.Default),
    t.literal(MemoryPrompt.Candle),
    t.literal(MemoryPrompt.KnowPerson),
    t.literal(MemoryPrompt.InterestingFact),
    t.literal(MemoryPrompt.DescribePerson),
    t.literal(MemoryPrompt.FunnyStory),
    t.literal(MemoryPrompt.BetterPlace),
    t.literal(MemoryPrompt.Legacy),
    t.literal(MemoryPrompt.AlwaysRemember),
    t.literal(MemoryPrompt.Admired),
    t.literal(MemoryPrompt.FavoriteStory),
    t.literal(MemoryPrompt.MadeSpecial),
    t.literal(MemoryPrompt.HelpOthers),
    t.literal(MemoryPrompt.TeachYou),
    t.literal(MemoryPrompt.LoveAbout),
    t.literal(MemoryPrompt.SayToday),
    t.literal(MemoryPrompt.LovedToDo),
    t.literal(MemoryPrompt.GoodAt),
    t.literal(MemoryPrompt.MakeYouLaugh),
    t.literal(MemoryPrompt.PossessionPlaceHoliday),
    t.literal(MemoryPrompt.EarliestMemory),
    t.literal(MemoryPrompt.Quotes),
    t.literal(MemoryPrompt.FunniestQuirks),
    t.literal(MemoryPrompt.ValueMost),
    t.literal(MemoryPrompt.DescribeCharacter),
    t.literal(MemoryPrompt.HundredYears)
]);

export const PromptTranslationFunctions: Record<MemoryPrompt, (name: string, posterName?: string) => string> = {
    [MemoryPrompt.Default]: (_: string, __?: string) => 'Share a message or condolence with others...',
    [MemoryPrompt.Candle]: (_: string, __?: string) => '',
    [MemoryPrompt.KnowPerson]: (name: string, posterName?: string) => (
        `How did ${posterName || 'you'} know ${name}?`
    ),
    [MemoryPrompt.InterestingFact]: (name: string, _?: string) => (
        `What is something interesting about ${name} that others might not know?`
    ),
    [MemoryPrompt.DescribePerson]: (name: string, posterName?: string) => (
        `In just a few words, how would ${posterName || 'you'} describe ${name}?`
    ),
    [MemoryPrompt.FunnyStory]: (name: string, _?: string) => `What is a funny story about ${name}?`,
    [MemoryPrompt.BetterPlace]: (name: string, _?: string) => (
        `How did ${name} make the world a better place?`
    ),
    [MemoryPrompt.Legacy]: (name: string, _?: string) => `What is ${name}’s legacy?`,
    [MemoryPrompt.AlwaysRemember]: (name: string, posterName?: string) => (
        `What is something ${posterName || 'you'} will always remember about ${name}?`
    ),
    [MemoryPrompt.Admired]: (name: string, posterName?: string) => (
        `What is something ${posterName || 'you'} admired about ${name}?`
    ),
    [MemoryPrompt.FavoriteStory]: (name: string, posterName?: string) => (
        `What is ${(posterName && posterName + '\'s') || 'your'} favorite story of ${name}?`
    ),
    [MemoryPrompt.MadeSpecial]: (name: string, _?: string) => (
        `What is something that made ${name} special?`
    ),
    [MemoryPrompt.HelpOthers]: (name: string, _?: string) => `How did ${name} help other people?`,
    [MemoryPrompt.TeachYou]: (name: string, posterName?: string) => (
        `What did ${name} teach ${posterName || 'you'}?`
    ),
    [MemoryPrompt.LoveAbout]: (name: string, posterName?: string) => (
        `What did ${posterName || 'you'} love about ${name}?`
    ),
    [MemoryPrompt.SayToday]: (name: string, posterName?: string) => (
        `What would ${posterName || 'you'} say to ${name} today?`
    ),
    [MemoryPrompt.LovedToDo]: (name: string, _?: string) => `What are some things ${name} loved to do?`,
    [MemoryPrompt.GoodAt]: (name: string, _?: string) => `What was ${name} really good at?`,
    [MemoryPrompt.MakeYouLaugh]: (name: string, posterName?: string) => (
        `When did ${name} make ${posterName || 'you'} laugh?`
    ),
    [MemoryPrompt.PossessionPlaceHoliday]: (name: string, _?: string) =>
        `What was ${name}'s favorite possession, place, or holiday?`,
    [MemoryPrompt.EarliestMemory]: (name: string, posterName?: string) => (
        `What is ${(posterName && posterName + '\'s') || 'your'} earliest memory of ${name}?`
    ),
    [MemoryPrompt.ValueMost]: (name: string, _?: string) => `What did ${name} value most in life?`,
    [MemoryPrompt.DescribeCharacter]: (name: string, posterName?: string) =>
        `What words would ${posterName || 'you'} use to describe ${name}’s character?`,
    [MemoryPrompt.Quotes]: (name: string, posterName?: string) => (
        `What were some quotes from ${name} ${posterName || 'you'} liked?`
    ),
    [MemoryPrompt.FunniestQuirks]: (name: string, _?: string) => `What was ${name}'s funniest quirk?`,
    [MemoryPrompt.HundredYears]: (name: string, posterName?: string) => {
        const youOrName = posterName || 'you';
        const doOrDoes = posterName ? 'does' : 'do';

        return `What ${doOrDoes} ${youOrName} want someone reading this
            100 years from now to know about ${name}?`;
    }
};

export interface MemoryAuthor extends Omit<GuestListUser, 'case_entity_id'> {
    mname: string | null;
    lname: string | null;
    isFHorGOM: boolean;
    case_entity_id: number | null;
}

export interface MemoryRecord {
    id: number;
    user_id: number;
    sale_type: FlowerSaleType;
    gather_case_id: number;
    memory_text: string;
    prompt: MemoryPrompt;
    created_time: Date;
    updated_time: Date;
    updated_by: number;
    moderation_status: ModerationStatus;
    moderation_reason: string | null;
    moderation_time: Date | null;
    moderated_by: number | null;
    moderation_required: boolean;
    imported_time: Date | null;
}

export interface MemoryUXRecord extends Omit<MemoryRecord, 'imported_time'> {
    is_pending_decision: boolean;
    author: MemoryAuthor | null;
}

export interface MemoryUX extends MemoryUXRecord {
}

const MemoryUpdateRequestType = t.type({
    memory_text: t.string
});
export interface MemoryUpdateRequest extends t.TypeOf<typeof MemoryUpdateRequestType> {}

export class MemoryUpdateRequest {
    public static fromRequest = getValidator<MemoryUpdateRequest>(MemoryUpdateRequestType);
}

const MemoryCreateRequestType = t.intersection([
    t.type({
        prompt: MemoryPromptType
    }),
    MemoryUpdateRequestType
]);
export interface MemoryCreateRequest extends t.TypeOf<typeof MemoryCreateRequestType> {}

export class MemoryCreateRequest {
    public static fromRequest = getValidator<MemoryCreateRequest>(MemoryCreateRequestType);
}

export interface MemoryCreateResponse {
    memory: MemoryUX;
    total: number;
}
