/* eslint-disable max-len */
import { Component } from 'react';

export default class FacebookMessengerIconSVG extends Component<{ color: string }> {
    render() {
        const { color } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" version="1.0" viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet" style={{ fill: color }}>
                <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M220 631 c-188 -61 -274 -251 -189 -419 17 -34 41 -70 55 -81 20 -16 24 -27 24 -75 0 -32 4 -56 10 -56 6 0 30 10 53 22 35 19 59 23 137 23 80 1 104 5 151 27 114 52 179 151 179 273 -1 116 -57 207 -165 263 -41 21 -66 26 -140 29 -49 1 -101 -1 -115 -6z m212 -263 l61 33 -69 -75 -69 -74 -43 41 -42 41 -63 -34 -62 -34 70 76 70 75 43 -41 43 -41 61 33z" />
                </g>
            </svg>
        );
    }
}