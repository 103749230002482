/* eslint-disable max-len */
import classNames from 'classnames';
/* tslint:disable */
export default function InviteHelpersSVG({ themeFillClass }: { themeFillClass: string }) {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288.41 108.63">
            <defs>
                <style type="text/css" dangerouslySetInnerHTML={{
                    __html: `
                        .ih-cls-1{fill:#d0d2d3;}
                        .ih-cls-2,.ih-cls-4,.ih-cls-5,.ih-cls-6,.ih-cls-7,
                        .ih-cls-9{fill:#005cef;}
                        .ih-cls-2{opacity:0.55;}
                        .ih-cls-2,.ih-cls-4,.ih-cls-5,.ih-cls-7,
                        .ih-cls-9{isolation:isolate;}
                        .ih-cls-3{fill:#fff;}
                        .ih-cls-4{opacity:0.35;}
                        .ih-cls-5{opacity:0.7;}
                        .ih-cls-7{opacity:0.15;}
                        .ih-cls-8{fill:#303030;}
                        .ih-cls-9{opacity:0.45;}
                    `}} />
            </defs>
            <path className="ih-cls-1" d="M287.88,38.35a.51.51,0,0,1,.5.5.5.5,0,0,1-.5.5H277a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5Z" transform="translate(0.03 0)" />
            <path className="ih-cls-1" d="M287.88,41.85a.51.51,0,0,1,.5.5.5.5,0,0,1-.5.5H277a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5Z" transform="translate(0.03 0)" />
            <path className={classNames('ih-cls-2', themeFillClass)} d="M283.71,47.29a.5.5,0,0,1,.5.5.51.51,0,0,1-.5.5H277a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="272.38" y="57.51" width="1" height="2" />
            <rect className="ih-cls-1" x="272.38" y="52.51" width="1" height="2" />
            <rect className="ih-cls-1" x="272.38" y="47.51" width="1" height="2" />
            <rect className="ih-cls-1" x="272.38" y="42.51" width="1" height="2" />
            <rect className="ih-cls-1" x="272.38" y="37.51" width="1" height="2" />
            <path className="ih-cls-3" d="M274.54,20.68a2.29,2.29,0,0,0-1.69.78,2.27,2.27,0,0,0-1.68-.78,2,2,0,0,0-2.07,1.93s0,0,0,.07c0,1.42,1.28,2.57,3.21,4.32l.54.5.55-.49c1.93-1.76,3.2-2.91,3.2-4.33a2,2,0,0,0-2-2Zm-1.69-4.32a7.76,7.76,0,1,1-7.75,7.77h0a7.77,7.77,0,0,1,7.75-7.77Z" transform="translate(0.03 0)" />
            <polygon className="ih-cls-1" points="273.38 62.51 273.38 64.51 272.39 64.51 272.38 64.51 272.38 62.51 273.29 62.51 273.38 62.51" />
            <path className="ih-cls-3" d="M273.06,67.07a1.45,1.45,0,1,1-.22,0h.22Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-2', themeFillClass)} d="M272.85,21.45a2.29,2.29,0,0,1,1.69-.78,2,2,0,0,1,2.06,1.94s0,0,0,.07c0,1.42-1.27,2.57-3.2,4.33l-.55.49-.54-.5c-1.93-1.75-3.21-2.9-3.21-4.32a2,2,0,0,1,2-2h.07A2.27,2.27,0,0,1,272.85,21.45Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-4', themeFillClass)} d="M272.36,64.51h1v-2h-.09c.29-.51.56-.84.75-.78.35.11-.56,3.19-1.23,5.31h.06a1.52,1.52,0,1,0,.22,0c.15-.52.34-1,.44-1.4.39-1.46,1.6-4.42,2.13-4.25s-.77,3.92-1.44,6l.26.08c.13-.42.28-.82.36-1.12.39-1.45,1.6-4.42,2.13-4.25s-.68,3.61-1.36,5.72l.21.07c.5-1.55,1.5-3.85,2-3.7s-.84,4.12-1.49,6.14c-.1.36-.2.72-.32,1.08a4.59,4.59,0,0,1-.68,1.47c-.43,12.53-2.17,23.51-9.9,23.51-6.13,0-9-6.31-9.74-12.91v-4h4.77c0,5.75,1.74,11.86,5,11.86,1.61,0,4.32-2.54,4.91-18.92a3,3,0,0,1,0-.5c0-.8-.09-1.61-.12-2.41a29.62,29.62,0,0,1,.21-4.8,3.62,3.62,0,0,1,.17-.74c.06-.13.16-.27.3-.29a.42.42,0,0,1,.37.24,2,2,0,0,1,.2,1.07v2.35c.14-.53.34-1,.44-1.42S272.19,65,272.36,64.51Z" transform="translate(0.03 0)" />
            <path className="ih-cls-1" d="M281.61,24.11a8.76,8.76,0,0,1-8.26,8.73v1.67h-1V32.84a8.75,8.75,0,1,1,9.26-8.73Zm-8.76,7.76a7.76,7.76,0,1,0-7.75-7.77h0a7.77,7.77,0,0,0,7.75,7.76Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M254.41,32.25c.84,2.23,2.17,14.36,1.78,16.77l-8,.1a10,10,0,0,0,.74-3.76V35.68H229v9.69a9.92,9.92,0,0,0,.84,4l-8.84.11a26.31,26.31,0,0,1-.55-7.67c.89-9.76,5-15,7.12-17.13a11.67,11.67,0,0,1,4.84-2.78,18,18,0,0,1,13.14.93A17,17,0,0,1,254.41,32.25Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-4', themeFillClass)} d="M242.21,61.85a3.09,3.09,0,0,1-6.17,0v-7a10,10,0,0,1-7-9.49V35.68h19.95v9.69a10,10,0,0,1-6.7,9.41v7.08Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M236,61.85a3.09,3.09,0,0,0,6.17,0V58A21.75,21.75,0,0,1,261,79.54h-5.45v22.25H223.36V79.54h-5.78A21.76,21.76,0,0,1,236,58Z" transform="translate(0.03 0)" />
            <rect data-name="svgThemeColor" className={classNames('ih-cls-4', themeFillClass)} x="218.63" y="79.54" width="4.76" height="22.25" />
            <polygon data-name="svgThemeColor" className={classNames('ih-cls-6', themeFillClass)} points="208.04 98.39 207.74 101.79 167.87 101.79 167.87 98.39 208.04 98.39" />
            <path className="ih-cls-3" d="M207.6,40.16,203,92.1H119.18l.86-9.68h73.3l1-11.36a13.25,13.25,0,0,0,2-23.58l.62-7.32Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-6', themeFillClass)} d="M118,93.36h85.63a.87.87,0,0,0,.28,0H204a.46.46,0,0,0,.16-.11.24.24,0,0,0,.06-.08.49.49,0,0,0,.1-.15.35.35,0,0,0,0-.11.28.28,0,0,0,0-.13l4.72-53.28h0V39.4a.8.8,0,0,0,0-.15l-.07-.12-.08-.12-.11-.09-.12-.08H197l.32-4h16.3l-5.52,62.21-94.68-.15,1.29-14.47h3.77l-.84,10.35h0a.53.53,0,0,0,0,.12C117.72,93.17,118,93.36,118,93.36Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-7', themeFillClass)} d="M196.3,47.48a13.4,13.4,0,1,1-1.44-.77A14.13,14.13,0,0,1,196.3,47.48Zm2.33,6-1.87-1.87L186.69,61.68l-4.77-4.75-1.87,1.87,6.64,6.64Z" transform="translate(0.03 0)" />
            <polygon data-name="svgThemeColor" className={classNames('ih-cls-2', themeFillClass)} points="186.72 61.64 196.79 51.58 198.66 53.45 186.72 65.4 180.08 58.76 181.95 56.89 186.72 61.64" />
            <path className="ih-cls-8" d="M169,50.16H119.7a.75.75,0,0,1,0-1.5H169a.75.75,0,0,1,0,1.5Z" transform="translate(0.03 0)" />
            <path className="ih-cls-8" d="M168.46,54.58a.75.75,0,0,1,0,1.5h-49.3a.75.75,0,0,1,0-1.5Z" transform="translate(0.03 0)" />
            <polygon className="ih-cls-3" points="144.94 16.54 142.88 39.69 128.82 26.12 144.94 16.54" />
            <path className="ih-cls-3" d="M119.16,56.08h49.3a.75.75,0,0,0,0-1.5h-49.3a.75.75,0,0,0,0,1.5Zm56.91,2.68a13.22,13.22,0,0,0,16.68,12.81L192,80.92H108.15l3.44-38.85h31.87a.67.67,0,0,0,.28-.06h.07a.56.56,0,0,0,.15-.1h0a.25.25,0,0,0,0-.07.55.55,0,0,0,.09-.14h0a.22.22,0,0,0,0-.11.45.45,0,0,0,0-.11l1.11-12.4h51.05l-1.35,17.6a13.24,13.24,0,0,0-18.79,12Zm-56.38-8.6H169a.75.75,0,0,0,0-1.5H119.7a.75.75,0,0,0,0,1.5h0Zm18,11.09a.75.75,0,0,0-.75-.75H118.63a.75.75,0,0,0,0,1.5H137a.76.76,0,0,0,.71-.75Z" transform="translate(0.03 0)" />
            <polygon className="ih-cls-3" points="143.09 15.9 121.01 29.02 104.22 15.9 143.09 15.9" />
            <path className="ih-cls-8" d="M137,60.5a.75.75,0,0,1,0,1.5H118.63a.75.75,0,0,1,0-1.5Z" transform="translate(0.03 0)" />
            <path className="ih-cls-3" d="M120.46,30.52h.14a.78.78,0,0,0,.33.09.72.72,0,0,0,.28-.07h.06l6.14-3.65L141.6,40.57h-40L115.88,26.9Z" transform="translate(0.03 0)" />
            <polygon className="ih-cls-3" points="114.67 25.97 100.63 39.4 102.66 16.59 114.67 25.97" />
            <polygon data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} points="112.68 98.39 167.87 98.39 167.87 101.79 90.86 101.79 90.86 98.39 112.67 98.39 112.68 98.39" />
            <path className="ih-cls-8" d="M100.6,39.4,114.6,26l-12-9.38Zm-9.77,62.39H207.71l.3-3.4H90.83Zm38-75.67,14,13.56,2.06-23.15Zm144.44,77.17H33v-1.5H89.33V97.68a.75.75,0,0,1,.75-.75H112l1.29-14.47h-6.74l3.57-40.35H99.39L99.27,42l-.1-.07h0a.41.41,0,0,1-.07-.11l-.07-.1h0v-.12h0a.44.44,0,0,1,0-.1h0v-.06l2.33-26.17v-.06a.53.53,0,0,1,0-.12.41.41,0,0,1,0-.08.35.35,0,0,1,0-.08V14.9h0l.06-.07h.08l.09-.06h44.55a.72.72,0,0,1,.3.59.5.5,0,0,1,0,.12l-1,12H198l-.49,5.85h16.4a.73.73,0,0,1,.3.07.63.63,0,0,1,.36-.06.74.74,0,0,1,.68.79v0l-6,67.64h64a.75.75,0,0,1,0,1.5ZM120,82.42l-.86,9.68H203l4.6-51.94H196.92l-.62,7.32a14.13,14.13,0,0,0-1.44-.77L196.36,29H145.31l-1.11,12.4a.45.45,0,0,1,0,.11.22.22,0,0,1,0,.11h0a.55.55,0,0,1-.09.14.25.25,0,0,1,0,.07h0a.56.56,0,0,1-.15.1h-.07a.67.67,0,0,1-.28.06h-32l-3.44,38.85H192l.79-9.35a13.38,13.38,0,0,0,1.55-.51l-1,11.36ZM104.19,15.9,121,29,143.08,15.9Zm17,14.71a.72.72,0,0,1-.28.07.78.78,0,0,1-.33-.09h-.14L115.81,27l-14.26,13.6H141.6L127.45,26.92l-6.14,3.65h0ZM117.6,92.82h0l.91-10.37h-3.77l-1.29,14.47h94.68l5.52-62.06h-16.3l-.3,3.82H208.7l.12.08.11.09L209,39l.07.12a.8.8,0,0,1,0,.15v.11h0l-4.74,53.44a.28.28,0,0,1,0,.13.35.35,0,0,1,0,.11.49.49,0,0,1-.1.15.24.24,0,0,1-.06.08.46.46,0,0,1-.16.11h-.07a.87.87,0,0,1-.28,0H118l-.12-.08-.11-.09-.08-.12a.52.52,0,0,1-.06-.13.32.32,0,0,1,0-.14.53.53,0,0,1,0-.12s0,.14,0,.13Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-4', themeFillClass)} d="M73.8,21.25a77.27,77.27,0,0,0,10.31.56,17.21,17.21,0,0,0,8-1.54h.13V34.13a11.38,11.38,0,0,1-9.93,11.28v3.77a3.52,3.52,0,0,1-7,0V44a11.65,11.65,0,0,1-5.79-9.91V29a1.58,1.58,0,0,0,.42-.2C72.12,27.28,73.06,23.68,73.8,21.25Z" transform="translate(0.03 0)" />
            <path className="ih-cls-3" d="M69.07,22a1.48,1.48,0,1,1-1.48,1.48A1.48,1.48,0,0,1,69.07,22Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="68.6" y="18" width="1" height="2.06" />
            <rect className="ih-cls-1" x="68.6" y="12.85" width="1" height="2.06" />
            <polygon className="ih-cls-1" points="69.6 8.25 69.6 9.75 68.6 9.75 68.6 9.25 68.1 9.25 68.1 8.25 69.6 8.25" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M69.57,14.91V12.85h-1v2.06Zm0,5.15V18h-1v2.06Zm1,3.46A1.48,1.48,0,1,0,69.09,25a1.48,1.48,0,0,0,1.48-1.48Zm3.25-2.27c-.74,2.45-1.68,6-4,7.56a1.58,1.58,0,0,1-.42.2v5.12a11.4,11.4,0,0,0,.24,2.2,10.69,10.69,0,0,1-3-4c-1.09-2.49-1.19-5.29-1.26-8-.1-3.36-.14-6.94,1.66-9.78a10.87,10.87,0,0,1,6.09-4.36,20.45,20.45,0,0,1,7.58-.8c1.27.09,5.06.61,5.06.61,2.65.4,5.43.85,7.56,2.48A8.26,8.26,0,0,1,94.48,24c-.84,1-2,1.85-2.29,3V20.27h-.13c-2.37,1.27-5.23,1.5-8,1.54a77.27,77.27,0,0,1-10.26-.56Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="63.47" y="8.25" width="1.85" height="1" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-2', themeFillClass)} d="M65.16,54.68a2.93,2.93,0,0,1,.26-1.32,21.7,21.7,0,0,1,9.8-5.08v.88a3.52,3.52,0,0,0,7,0V47.89a21.76,21.76,0,0,1,19.47,21.64v9.35c.17,5.59.72,12.61,2.19,18H90.08a.75.75,0,0,0-.75.75v4.15H59v-7.2c2.46-3,3.24-7,3.48-10.84.12-1.77.11-3.47.11-5V78.1h1.72a18.91,18.91,0,0,0-3.14-10.42c2.91-1.17,5.69-3.58,5.67-6.69C66.88,58.81,65.49,56.86,65.16,54.68Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="58.84" y="8.25" width="1.85" height="1" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-6', themeFillClass)} d="M41.12,59.54a19.11,19.11,0,0,0-12.49,9.33,5.77,5.77,0,0,1-2-1.33c-1.7-1.91-.68-4.89-.9-7.45-.3-3.4-3-6.49-2.34-9.84s4.4-5.54,5.14-8.92S26.67,34.15,28.17,31c1.23-2.59,4.56-3.38,6.45-5.54.87-1,1.43-2.29,2.47-3.12,2.47-2,5.54.13,7.77-.89,2.44-1.13,3.61-2.68,6.71-1.9,2.73.69,5,2.58,7.11,4.4a9.76,9.76,0,0,1,2.47,2.74,19.42,19.42,0,0,1,1.67,3.91c.91,5.47-.42,3.55.49,9,.26,1.59,3.61,2.29,3.61,8.49a13.93,13.93,0,0,1-1.5,5.29,2.93,2.93,0,0,0-.26,1.31c.33,2.16,1.72,4.11,1.73,6.3,0,3.11-2.76,5.52-5.67,6.69a19,19,0,0,0-13.93-8.49V55.24A10,10,0,0,0,56,45.36V33.2h0c-.8.9-3.49,1-4.35,2a12.51,12.51,0,0,1-3.52,3.09c-2.13,1.16-5,.31-7,1.74-1.63,1.2-1.94,3.5-3.11,5.15a5.68,5.68,0,0,1-1.82,1.63,10,10,0,0,0,5,7.21Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="54.2" y="8.25" width="1.86" height="1" />
            <rect className="ih-cls-1" x="49.57" y="8.25" width="1.85" height="1" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-9', themeFillClass)} d="M33,97a10.08,10.08,0,0,0,3.18.47,20.26,20.26,0,0,0,4.48-.57,29.44,29.44,0,0,0,9.06,1.71,12.58,12.58,0,0,0,7-1.89A10.13,10.13,0,0,0,59,94.61v7.2H33Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="44.94" y="8.25" width="1.85" height="1" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-4', themeFillClass)} d="M44.2,64.93a3.08,3.08,0,0,1-3.08-3.08V54a10,10,0,0,1-5-7.21A5.68,5.68,0,0,0,38,45.2c1.17-1.65,1.48-4,3.11-5.15,2-1.43,4.85-.58,7-1.74a12.51,12.51,0,0,0,3.52-3.09c.86-1,3.55-1.12,4.35-2h0V45.36a10,10,0,0,1-8.7,9.88v6.61a3.08,3.08,0,0,1-3.08,3.08Z" transform="translate(0.03 0)" />
            <circle className="ih-cls-3" cx="38.19" cy="73.31" r="1.48" />
            <rect className="ih-cls-1" x="32.04" y="72.81" width="2" height="1" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M33.83,91.46a.71.71,0,0,1-.08.15v.07l-.19-.09a2.08,2.08,0,0,1-.25-.38Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-4', themeFillClass)} d="M59.13,78.14v1.44a6.8,6.8,0,0,1,1,3.52v.5a6.63,6.63,0,0,1-1,3.14,7.44,7.44,0,0,1-.81,1v.7A5.35,5.35,0,0,1,53,93.79H51a6.3,6.3,0,0,1-.76-.08l-.06-.06a.56.56,0,0,1-.09-.14L50,93.26h.15c1.65-.75,5.2-2.42,5-2.77s-2.28.34-3.58.93l-.09-.17c1.78-.82,4.69-2.22,4.54-2.54s-2.86.54-4,1.16c-.25.13-.6.28-1,.45l-.11-.22c1.74-.8,4.94-2.32,4.78-2.65s-2.86.54-4,1.15c-.31.16-.77.35-1.2.57a.76.76,0,0,1-.06-.13l-.06-.09c1.78-.83,4.33-2.08,4.19-2.38s-2.86.54-4,1.16c-.31.16-.77.34-1.19.56l1.5-1.43a1.9,1.9,0,0,0,.58-.77A.3.3,0,0,0,51,85.7a2.74,2.74,0,0,0-.58.32A25.64,25.64,0,0,0,47,88.68c-.4.39-.8.8-1.19,1.2a46.51,46.51,0,0,1-5,1.71c-.9-.33-1.84-.7-2.82-1.12-.13-.13-.25-.26-.39-.39a25.66,25.66,0,0,0-3.24-2.74,2.56,2.56,0,0,0-.58-.33.4.4,0,0,0-.37,0,.36.36,0,0,0-.07.39,2,2,0,0,0,.58.77l1.54,1.51c-.43-.21-.89-.4-1.19-.56a15.57,15.57,0,0,0-1.57-.68V87.27c0-.4,0-1,.08-1.55.06-.74.12-1.48.2-2.2v-.43c.17-1.65.34-3.36.34-4.92h-5A44,44,0,0,1,28,82.56c-.46,4.62-1,9.83,2.19,12.81l.32.27c.12.09.22.19.35.28a7.17,7.17,0,0,0,2.09,1,10.08,10.08,0,0,0,3.18.47,20.26,20.26,0,0,0,4.48-.57,29.44,29.44,0,0,0,9.06,1.71,12.58,12.58,0,0,0,7.09-1.85A10.13,10.13,0,0,0,59,94.61c2.46-3,3.24-7,3.48-10.84.12-1.77.11-3.47.11-5v-.64ZM33.75,91.61v.07l-.19-.09a2.08,2.08,0,0,1-.25-.38l.55.25A.71.71,0,0,1,33.75,91.61Zm.61-1.2v.12l-.66-.3L33.15,90l-.15,0-.14-.08v-.3l.14.14,1.43.68Z" transform="translate(0.03 0)" />
            <path className="ih-cls-3" d="M32.33,11.68l.54.49.55-.49c1.93-1.75,3.2-2.91,3.2-4.32A2,2,0,0,0,34.68,5.3h-.12a2.25,2.25,0,0,0-1.69.78,2.22,2.22,0,0,0-1.68-.78,2,2,0,0,0-2.06,1.94s0,.1,0,.14C29.13,8.79,30.4,9.95,32.33,11.68ZM32.87,1a7.76,7.76,0,1,1-7.75,7.77s0,0,0,0A7.77,7.77,0,0,1,32.87,1Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M33,89.68l1.43.68a.41.41,0,0,0,0,.09v.12l-.66-.3L33.22,90,33,89.92l-.14-.08a.63.63,0,0,0,0-.3Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M32.7,88.41V87.24c0-.4,0-1,.08-1.55.06-.74.12-1.48.2-2.2a7.27,7.27,0,0,1,4.55-1.59h9.11A6.87,6.87,0,0,1,47.91,79V76.53c0-.41.12-.75.54-.75s.92.64,1.45,1.45A6.8,6.8,0,0,1,57,77.4c.56-.89,1.15-1.62,1.56-1.62s.54.34.54.75v3.05a6.8,6.8,0,0,1,1,3.52v.5a6.63,6.63,0,0,1-1,3.14,7.43,7.43,0,0,1-.81,1v.7a5.35,5.35,0,0,1-5.35,5.35h-2a6.31,6.31,0,0,1-.76-.08l-.12,0a.56.56,0,0,1-.09-.14l-.11-.25H50c1.65-.75,5.2-2.42,5-2.77s-2.28.34-3.58.93l-.09-.17c1.78-.82,4.69-2.22,4.54-2.54s-2.86.54-4,1.16c-.25.13-.6.28-1,.45,0-.08-.07-.15-.11-.22,1.74-.8,4.94-2.32,4.78-2.65s-2.86.54-4,1.15c-.31.16-.77.35-1.2.57a.75.75,0,0,1-.06-.13L50.22,89c1.78-.83,4.33-2.08,4.19-2.38s-2.86.54-4,1.16c-.31.16-.77.34-1.19.56l1.5-1.43a1.9,1.9,0,0,0,.58-.77.3.3,0,0,0-.44-.39,2.75,2.75,0,0,0-.58.32A25.66,25.66,0,0,0,47,88.68c-.4.39-.8.8-1.19,1.2a46.52,46.52,0,0,1-5,1.71c-.9-.33-1.84-.7-2.82-1.12l-.39-.39a25.66,25.66,0,0,0-3.24-2.74,2.56,2.56,0,0,0-.58-.33.4.4,0,0,0-.37,0,.36.36,0,0,0-.07.39,2,2,0,0,0,.58.77l1.54,1.51c-.43-.21-.89-.4-1.19-.56A15.57,15.57,0,0,0,32.7,88.41Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-2', themeFillClass)} d="M32.87,6.1a2.25,2.25,0,0,1,1.69-.78,2,2,0,0,1,2.06,1.94s0,.08,0,.12c0,1.41-1.27,2.57-3.2,4.32l-.55.49-.54-.51c-1.93-1.75-3.2-2.91-3.2-4.32A2,2,0,0,1,31.07,5.3h.12A2.22,2.22,0,0,1,32.87,6.1Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-5', themeFillClass)} d="M30.22,101.79V95.37l.32.27c.12.09.22.19.35.28a7.18,7.18,0,0,0,2.09,1v10.33a1.38,1.38,0,0,1-2.76,0v-5.46Z" transform="translate(0.03 0)" />
            <path className="ih-cls-8" d="M30.22,103.29H14.92a.75.75,0,1,1,0-1.5h15.3Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="27.04" y="72.81" width="2" height="1" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-9', themeFillClass)} d="M38.16,74.79a1.48,1.48,0,1,0-1.48-1.48,1.48,1.48,0,0,0,1.48,1.48ZM27,72.81v1h2v-1Zm7,0H32v1h2Zm7.11-11a3.09,3.09,0,0,0,6.17,0V59.18a19.12,19.12,0,0,1,17.07,19H59.13V76.53c0-.41-.13-.75-.54-.75s-1,.73-1.56,1.62a6.8,6.8,0,0,0-7.14-.17c-.53-.81-1.07-1.45-1.45-1.45s-.54.34-.54.75V79a6.87,6.87,0,0,0-1.27,2.89H37.52A7.27,7.27,0,0,0,33,83.49v-.43c.17-1.65.34-3.36.34-4.92H26.21a18.88,18.88,0,0,1,2.42-9.27,19.11,19.11,0,0,1,12.49-9.33Z" transform="translate(0.03 0)" />
            <path className="ih-cls-1" d="M41.61,9.25a8.75,8.75,0,1,1,0-1h.52v1Zm-8.74,7.26a7.76,7.76,0,1,0-7.75-7.77h0A7.77,7.77,0,0,0,32.87,16.51Z" transform="translate(0.03 0)" />
            <rect className="ih-cls-1" x="22.04" y="72.81" width="2" height="1" />
            <path className="ih-cls-1" d="M15.15,88.78a.5.5,0,1,1,0,1H4.3a.5.5,0,1,1,0-1Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-2', themeFillClass)} d="M11,84.84a.5.5,0,0,1,0,1H4.3a.5.5,0,1,1,0-1Z" transform="translate(0.03 0)" />
            <path className="ih-cls-3" d="M8.21,76.25l.54.5.55-.49c1.93-1.76,3.2-2.91,3.2-4.33a2,2,0,0,0-1.94-2.06h-.12a2.25,2.25,0,0,0-1.69.81,2.22,2.22,0,0,0-1.68-.78A2,2,0,0,0,5,71.83s0,.07,0,.1C5,73.35,6.28,74.5,8.21,76.25Zm8.3-2.94a7.76,7.76,0,1,1-7.76-7.76A7.76,7.76,0,0,1,16.51,73.31Z" transform="translate(0.03 0)" />
            <path data-name="svgThemeColor" className={classNames('ih-cls-2', themeFillClass)} d="M5,71.93a2,2,0,0,1,1.94-2.06h.12a2.22,2.22,0,0,1,1.69.81,2.25,2.25,0,0,1,1.69-.78,2,2,0,0,1,2.06,1.94s0,.08,0,.12c0,1.42-1.27,2.57-3.2,4.33l-.55.49-.54-.5C6.28,74.5,5,73.35,5,71.93Z" transform="translate(0.03 0)" />
            <path className="ih-cls-1" d="M8.75,82.07a8.76,8.76,0,1,1,8.73-9.26H19v1H17.48A8.76,8.76,0,0,1,8.75,82.07Zm7.76-8.76a7.76,7.76,0,1,0-7.76,7.76A7.76,7.76,0,0,0,16.51,73.31Z" transform="translate(0.03 0)" />
        </svg>

    );
}