import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { GatherCaseUX } from '../../../../shared/types';

import { useTaskDialogStyles } from '../TaskDialogsStyles';

import { getFullNameFromCase, formatDateString } from '../../../../services/app.service';

import { NULL_STRING } from '../../../../constants';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
}

const VerizonService = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
    } = props;

    const classes = useTaskDialogStyles();

    const verizonContact: string = '1-800-922-0204';

    const deathDate: string = activeCase.dod_start_date && activeCase.dod_start_date !== ''
        ? formatDateString(activeCase.dod_start_date) : NULL_STRING;

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    The best option to close  {activeCase.fname}'s account with Verizon is to
                    call their customer service number at {verizonContact}. You will need the
                    information listed below.
                    <span className={classNames(classes.displayBlock, classes.marginTop20)}>
                        Note: You might be directed to a store to close {activeCase.fname}'s account; however,
                        we have found that customers have a poor experience and many stores don't
                        know how to help. We recommend calling the number below to cancel {activeCase.fname}'s
                        service.
                    </span>
                </Typography>
                <a
                    href={`tel: ${verizonContact}`}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop20
                        )}
                    >
                        CALL Verizon
                    </Button>
                </a>
            </Grid>

            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop30,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    You may need the following information...
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        <li>
                            Name: <span className={classes.fontWeight500}>
                                {getFullNameFromCase(activeCase)}</span>
                        </li>
                        <li>
                            Date of Passing: <span className={classes.fontWeight500}>{deathDate}</span>
                        </li>
                        <li>
                            {activeCase.fname}'s Verizon Phone Number: {NULL_STRING}
                        </li>
                        <li>
                            {activeCase.fname}'s Verizon Account Number (optional):  {NULL_STRING}
                        </li>
                    </Typography>
                </Grid>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default VerizonService;
