import { Component } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

import ClearIcon from '@mui/icons-material/Clear';
import ArchiveIcon from '@mui/icons-material/Archive';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

import { StoreState } from '../../../types';
import {
    GatherCasePublic,
    GatherCaseUX,
    isGatherCaseUX,
    TaskTemplateSummary,
    UserRoles,
    TaskTemplateType,
} from '../../../shared/types';
import AvailableTaskTemplatesDialog from
    '../../workflows/dialogs/AvailableTaskTemplates.dialog';
import { createTaskFromTaskTemplate } from '../../../actions/task/Task.action';
import SaveTaskDialog from '../tasks/SaveTask.dialog';
import DownloadSnackbar from './SortablePhotoList/DownloadSnackbar';
import GLink from '../../common/GLink';
import { RouteBuilder } from "../../../services";
import { canViewTask } from '../../../shared/authority/can';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import { SlideTransition } from '../../common/Transitions';
import { isRememberPage } from '../../../services';
import ListItemButton from '@mui/material/ListItemButton';
import { GStyles } from '../../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%',
            margin: 0,
            '@media (min-width: 768px)': {
                maxWidth: 720,
                width: 720,
                height: 'auto'
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 32,
    },
    dialogContent: {
        zIndex: 0,
        overflowX: 'hidden',
        padding: '0 8px',
        maxWidth: 540,
        margin: '0 auto 64px',
        '@media (min-width: 400px)': {
            padding: '0 16px'
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        cursor: 'pointer',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    downloadButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& $card': {
            width: 'min-content',
            minWidth: 300,
            borderRadius: 4,
            color: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: 'none',
            position: 'relative',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: theme.shadows[10],
            },
            '&$downloading': {
                '&:hover': {
                    cursor: 'auto',
                    boxShadow: 'none',
                },
            },
            '@media (min-width: 400px)': {
                minWidth: 356,
            },
            '& p': {
                color: theme.palette.secondary.main
            },
            '& $cardHeading': {
                fontSize: 18,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 16px',
                borderBottom: '1px solid',
                padding: '8px 0',
                color: theme.palette.primary.main,
                '& svg': {
                    fontSize: 28,
                },
            },
            '& $cardContent': {
                padding: '8px 6px 16px',
                '@media (min-width: 400px)': {
                    padding: '8px 8px 16px',
                },
                '& p': {
                    fontSize: 14,
                    fontWeight: 200,
                    textAlign: 'center',
                    '@media (min-width: 400px)': {
                        fontSize: 16
                    }
                },
            },
        },
    },
    heading: {
        fontSize: 20,
        fontWeight: 200,
        marginBottom: 20,
        '@media (min-width: 400px)': {
            fontSize: 24
        },
        '@media (min-width: 480px)': {
            fontSize: 28
        }
    },
    divider: {
        background: theme.palette.primary.main,
        margin: '24px 0 8px'
    },
    description: {
        fontSize: 16,
        fontWeight: 200,
        textAlign: 'center',
        lineHeight: 1.25,
        '@media (min-width: 400px)': {
            fontSize: 18
        }
    },
    listItemText: {
        padding: 0
    },
    primaryText: {
        lineHeight: 1,
        fontSize: 16,
        '@media (min-width: 600px)': {
            fontSize: 18
        }
    },
    listItem: {
        padding: '8px 14px 8px 0',
        width: 'fit-content',
        margin: 'auto'
    },
    listItemIcon: {
        margin: '0 8px',
        minWidth: 40,
        '& svg': {
            margin: 'auto'
        }
    },
    workflow: {
        margin: '12px 0'
    },
    footer: {
        width: 280,
        textAlign: 'center',
        lineHeight: 1.25,
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto'
    },
    circulurProgress: {
        color: green[500],
        position: 'absolute',
        top: '40%',
        left: '45%',
        marginTop: -12,
        marginLeft: -12,
    },
    downloading: {},
    cardHeading: {},
    cardContent: {},
    card: {},
    dialogPaper: {},
});

const mapStateToProps = ({ casesState, albumState, workflowState, tasksState, userSession }: StoreState) => {
    const { checklistTasks } = tasksState;
    const { userData } = userSession;
    const { selectedCase } = casesState;
    const isFamilyGuest = selectedCase && UserRoles.isFamilyGuest(userData, selectedCase.id);

    const slideshowTasks = checklistTasks.filter(task => {
        return task.template_type === TaskTemplateType.build_photo_slideshow && canViewTask(userData, task);
    });

    const skipDownloadDialog = isFamilyGuest && slideshowTasks.length === 0;

    return {
        albumTaskInfoMap: albumState.albumTaskInfoMap,
        userData,
        workflow: workflowState.activeWorkflow,
        skipDownloadDialog,
        slideshowTasks,
        selectedTaskIds: checklistTasks.reduce<number[]>(
            (list, t) =>
                t.from_task_id
                    ? [...list, t.from_task_id]
                    : list,
            []
        ),
    };
};

type StyledProps = WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'clearIcon'
    | 'dialogContent' | 'card' | 'cardContent' | 'cardHeading' | 'downloadButtonContainer' | 'heading'
    | 'divider' | 'description' | 'listItemText' | 'primaryText' | 'listItem' | 'circulurProgress'
    | 'listItemIcon' | 'workflow' | 'footer' | 'downloading'>;

export interface SharedProps {
    zIndex: number;
    activeCase: GatherCaseUX | GatherCasePublic;
    isDialogOpen: boolean;
    closeDialog: () => void;
    photosCount: number;
}

interface Props extends SharedProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    downloadPhotos: () => void;
    isDownloading: boolean;
}

interface State {
    isAvailableTasksDialogOpen: boolean;
    isSaveTaskDialogOpen: boolean;
}

type CombinedProps = StyledProps & Props;
class DownloadPhotosDialog extends Component<CombinedProps, State> {
    state: State = {
        isAvailableTasksDialogOpen: false,
        isSaveTaskDialogOpen: false
    };

    componentDidUpdate(prevProps: Props) {
        const { isDialogOpen, skipDownloadDialog, downloadPhotos, closeDialog } = this.props;

        if (isDialogOpen !== prevProps.isDialogOpen) {
            if (skipDownloadDialog && isDialogOpen) {
                closeDialog();
                downloadPhotos();
                return;
            }
        }
    }

    renderTaskList = () => {
        const { slideshowTasks, classes, albumTaskInfoMap, activeCase, closeDialog } = this.props;

        return (
            <List className={classes.workflow}>
                {slideshowTasks.map(task => {
                    const photosCount = albumTaskInfoMap[task.id] && albumTaskInfoMap[task.id].count;
                    const secondaryText = photosCount !== 1
                        ? `${photosCount || 'No'} photos in this album`
                        : '1 photo in this album';

                    return (
                        <GLink
                            to={RouteBuilder.PhotoSlideShow({
                                caseName: activeCase.name,
                                funeralHomeKey: activeCase.funeral_home.key,
                                taskId: task.id,
                            })}
                            linkClass={GStyles.colorSecondary}
                            key={task.id}
                        >
                            <ListItemButton
                                className={classes.listItem}
                                onClick={e => closeDialog()}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <ViewCarouselIcon sx={{ fontSize: 36 }} color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    color="primary"
                                    primaryTypographyProps={{ color: 'primary', className: classes.primaryText }}
                                    className={classes.listItemText}
                                    primary={task.title}
                                    secondary={secondaryText}
                                />
                            </ListItemButton>
                        </GLink>
                    );
                })}
            </List>
        );
    };

    renderAvailableTaskTemplatesDialog = () => {
        const { workflow, selectedTaskIds, zIndex } = this.props;
        const { isAvailableTasksDialogOpen } = this.state;

        return (
            <AvailableTaskTemplatesDialog
                zIndex={zIndex + 2}
                isDialogOpen={isAvailableTasksDialogOpen}
                selectedTaskIds={selectedTaskIds}
                availableTasks={workflow ? workflow.available_checklist_tasks : []}
                onTaskTemplateAdd={this.handleAddTaskFromTaskTemplate}
                onCreateNewTaskClick={this.handleCreateNewTaskClick}
                closeDialog={this.closeAvailableTasksDialog}
            />
        );
    };

    renderSaveTaskDialog = () => {
        const { activeCase, zIndex } = this.props;
        const { isSaveTaskDialogOpen } = this.state;

        if (isGatherCaseUX(activeCase)) {
            return (
                <SaveTaskDialog
                    key="saveTaskDialog"
                    activeCase={activeCase}
                    existing={null}
                    type="standard"
                    zIndex={zIndex + 2}
                    isOpen={isSaveTaskDialogOpen}
                    closeDialog={this.closeSaveTaskDialog}
                />
            );
        }

        return null;
    };

    render() {
        const {
            zIndex,
            isDialogOpen,
            closeDialog,
            classes,
            userData,
            photosCount,
            downloadPhotos,
            isDownloading,
            activeCase,
            skipDownloadDialog,
        } = this.props;

        const isFHorGOMUser = UserRoles.isFHorGOMUser(userData);
        const ifOnRememberPage = isRememberPage();

        return (
            <>
                <Dialog
                    open={isDialogOpen && !skipDownloadDialog}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    keepMounted
                    onClose={e => closeDialog()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                >
                    <DialogTitle className={classes.dialogHeader}>
                        <ClearIcon
                            color="primary"
                            className={classes.clearIcon}
                            onClick={e => closeDialog()}
                        />
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        <Grid item xs={12} className={classes.downloadButtonContainer}>
                            <Typography color="secondary" className={classes.heading}>
                                What would you like to download?
                            </Typography>
                            <Card className={classNames(classes.card, isDownloading && classes.downloading)}>
                                <CardActionArea disabled={isDownloading} onClick={e => downloadPhotos()}>
                                    {isDownloading &&
                                        <CircularProgress size={64} className={classes.circulurProgress} />
                                    }
                                    <Typography className={classes.cardHeading}>
                                        <ArchiveIcon />&nbsp;
                                        Download {photosCount === 1 && '1 Photo' || `All ${photosCount} Photos`}
                                    </Typography>

                                    <CardContent className={classes.cardContent}>
                                        <Typography>
                                            Photos will be downloaded into a zipped folder in the order
                                            in which they were uploaded
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Divider className={classes.divider} />

                        <Grid item xs={12}>
                            <Typography color="secondary" className={classes.description}>
                                You can also create multiple albums that will allow you to select,
                                arrange, and download your photos in the order you prefer.
                            </Typography>

                            <Grid item xs={12}>
                                {this.renderTaskList()}
                            </Grid>

                            {isFHorGOMUser &&
                                <Typography color="primary" className={classes.footer}>
                                    {ifOnRememberPage ?
                                        <span>
                                            You can create up to four different albums by adding the additional
                                            slideshow tasks
                                        </span>
                                        : <>
                                            <span>You can create up to 4 different albums.&nbsp;</span>
                                            <span>
                                                <span
                                                    className={GStyles.textClickable}
                                                    onClick={e => this.setState({ isAvailableTasksDialogOpen: true })}
                                                >
                                                    Click here
                                                </span>
                                                &nbsp;to add additional albums tasks
                                            </span>
                                        </>
                                    }
                                </Typography>
                            }
                        </Grid>
                    </DialogContent>
                </Dialog>
                {this.renderSaveTaskDialog()}
                {this.renderAvailableTaskTemplatesDialog()}
                {(isDialogOpen || skipDownloadDialog) &&
                    <DownloadSnackbar
                        caseUuid={activeCase.uuid}
                        albumId={'case'}
                        zIndex={zIndex + 1}
                    />
                }
            </>
        );
    }

    handleAddTaskFromTaskTemplate = async (templateTask: TaskTemplateSummary) => {
        const { dispatch, activeCase } = this.props;

        if (isGatherCaseUX(activeCase)) {
            await dispatch(createTaskFromTaskTemplate(templateTask.id, activeCase.uuid));
        }
    };

    handleCreateNewTaskClick = () => {
        this.openSaveTaskDialog();
        this.closeAvailableTasksDialog();
    };

    closeAvailableTasksDialog = () => {
        this.setState({
            isAvailableTasksDialogOpen: false,
        });
    };

    openSaveTaskDialog = () => {
        this.setState({ isSaveTaskDialogOpen: true });
    };

    closeSaveTaskDialog = () => {
        this.setState({ isSaveTaskDialogOpen: false });
    };
}

export default withState(mapStateToProps)(withStyles(styles)(DownloadPhotosDialog));
