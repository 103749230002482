import classNames from 'classnames';
import momentTz from 'moment-timezone';

import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Paper from '@mui/material/Paper';

import {
    GatherCasePreview,
    GatherEvent,
    GoogleEvent
} from '../../../shared/types';

import { convertHexToRGBA, getAddressFromGatherEvent } from '../../../services/app.service';
import { GOOGLE_LOGO } from '../../../constants';
import withGStyles from '../../../styles/WithGStyles';
import GLink from '../../common/GLink';
import { RouteBuilder } from "../../../services";

interface Props {
    event: GatherEvent | GoogleEvent;
    cases: GatherCasePreview[];
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    buttonSecondaryTypography: {
        display: 'block',
        position: 'relative',
        fontSize: 16,
        lineHeight: 1.2,
        fontWeight: 200,
    },
    eventNameContainer: {
        padding: '0px 0px 0px 2%',
    },
    spanEllipsis: {
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    fontColorPrimary: {
        color: theme.palette.primary.main
    },
    maxWidth160: {
        maxWidth: '160px',
    },
    width160: {
        width: '145px',
        '@media (min-width: 360px)': {
            width: '160px',
        },
    },
    caseNameSpan: {
        display: 'block',
        color: 'rgba(0, 0, 0, 0.54)',
        lineHeight: '14px',
        padding: '0px 0px 4px 0px'
    },
    caseLink: {
        textDecoration: 'none',
        color: 'inherit',
        width: 'calc(100% - 92px)',
        flexGrow: 2,
    },
    divEventName: {
        display: 'flex',
        height: 24,
        '& svg': {
            fontSize: 18,
            color: theme.palette.primary.main
        }
    },
    paddingLeft4: {
        paddingLeft: 4
    },
    caseEventPaper: {
        maxWidth: 300,
        borderRadius: 4,
        margin: '0 10px',
        color: theme.palette.secondary.main,
        border: '1px solid',
        boxShadow: 'none',
        '@media (min-width: 960px)': {
            maxWidth: 340,
        },
    },
    marginEventPaper: {
        margin: '12px 8px'
    },
    borderBottomSection: {
        color: theme.palette.secondary.main,
        borderBottom: '1px solid',
    },
    displayFlex: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    padding4: {
        padding: 4,
    },
    backgroundSection: {
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.18)
    },
    timeContainer: {
        padding: 0,
        height: '40px',
        paddingRight: '4px',
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.secondary.main}`,
    },
    lineHeightAssignee: {
        lineHeight: 1.25,
        fontWeight: 300
    },
    endTimeContainer: {
        display: 'block',
    },
    defaultCursor: {
        cursor: 'default'
    }
});

type StyledProps = Props & GLOBAL_STYLED_PROPS
    & WithStyles<'root' | 'buttonSecondaryTypography' | 'eventNameContainer' | 'spanEllipsis' | 'fontColorPrimary'
        | 'maxWidth160' | 'width160' | 'caseNameSpan' | 'caseLink'
        | 'divEventName' | 'paddingLeft4' | 'caseEventPaper' | 'marginEventPaper' | 'borderBottomSection'
        | 'displayFlex' | 'padding4' | 'backgroundSection' | 'timeContainer' | 'borderRight' | 'lineHeightAssignee'
        | 'endTimeContainer' | 'defaultCursor'>;

const EventTime = (props: StyledProps) => {
    const { classes, cases, event } = props;

    const { id, name, start_time, end_time } = event;

    if (!start_time || !end_time) {
        return null;
    }
    const startMoment = momentTz(start_time);
    const endMoment = momentTz(end_time);
    let isAllDay: boolean = false;
    let eventCase: GatherCasePreview | undefined = undefined;

    let caseFname = '',
        caseLname = '',
        eventLocation = '',
        caseAssignee = '',
        caseName = '',
        funeralHomeKey = '',
        eventFullLocation: string | null = null;
    let isStreamable: boolean = false;
    let isPrivateEvent: boolean = false;
    let isGoogleEvent: boolean = false;
    let gcalEventURL: string | null = null;
    if (GatherEvent.isGatherEvent(event)) {
        caseFname = event.case_fname;
        caseLname = event.case_lname;
        caseName = event.case_name;
        funeralHomeKey = event.funeral_home_key;
        eventFullLocation = getAddressFromGatherEvent(event);
        eventLocation = event.location_name || '';
        isStreamable = event.is_streamable;
        isPrivateEvent = event.is_private;
        caseAssignee = event.case_assignee_full_name;
        eventCase = cases.find((c) => c.id === event.gather_case_id);
    } else {
        isAllDay = event.is_all_day;
        isGoogleEvent = true;
        eventLocation = event.location || '';
        gcalEventURL = event.url_to_event_in_gcal;
    }

    const startTime = startMoment.format('h:mm');
    const startTimePeriod = startMoment.format('a');

    const key = `${isGoogleEvent ? 'google' : 'gather'}_${id}`;

    const showFooter: boolean = Boolean(caseAssignee || eventLocation);

    const eventElementText = (
        <Typography
            component="span"
            color="inherit"
            className={classNames(
                classes.buttonSecondaryTypography,
                classes.eventNameContainer,
            )}
        >
            <span
                className={classNames(
                    classes.spanEllipsis,
                    classes.fontWeight400,
                    classes.fontColorPrimary,
                )}
            >
                <Tooltip
                    title={name}
                    placement="top"
                    enterDelay={400}
                >
                    <span>{name}</span>
                </Tooltip>
            </span>
            <span
                className={classNames(
                    classes.spanEllipsis,
                    classes.fontWeight400,
                    classes.caseNameSpan,
                    classes.width100,
                )}
            >
                {caseFname} {caseLname}
            </span>
        </Typography>
    );

    const googleIconJSX = <img src={GOOGLE_LOGO} height="18px" width="18px" />;
    const rememberPageLink = RouteBuilder.RememberPage(caseName);

    const eventElement = (
        <>
            {!eventCase
                ? <div className={classes.caseLink}>{eventElementText}</div>
                : <GLink
                    to={RouteBuilder.FamilyPage({
                        caseName,
                        funeralHomeKey,
                    })}
                    linkClass={classes.caseLink}
                >
                    {eventElementText}
                </GLink>
            }

            <div className={classes.divEventName}>
                {isPrivateEvent &&
                    <Tooltip
                        title={'This event is marked as Invite Only'}
                        placement="top"
                        enterDelay={200}
                    >
                        <VisibilityOffIcon />
                    </Tooltip>
                }
                {isGoogleEvent &&
                    <Tooltip
                        title={`This event was imported from your team's Google Calendar.
                        ${gcalEventURL ? ' Click here to view it on your calendar.' : ''}`}
                        placement="top"
                        enterDelay={200}
                    >
                        {gcalEventURL
                            ? <a href={gcalEventURL} target="_blank" rel="noreferrer">{googleIconJSX}</a>
                            : googleIconJSX
                        }
                    </Tooltip>
                }
                {isStreamable &&
                    <GLink to={rememberPageLink}>
                        <Tooltip
                            title={'This event has a LiveStream'}
                            placement="top"
                            enterDelay={200}
                        >
                            <LiveTvIcon className={classes.paddingLeft4} />
                        </Tooltip>
                    </GLink>
                }
            </div>
        </>
    );

    return (
        <div
            className={classNames(
                classes.width100,
                classes.colorPrimary,
            )}
        >
            <Paper className={classNames(
                classes.caseEventPaper,
                classes.marginEventPaper,
            )}>
                <div
                    itemID={key}
                    className={classNames(
                        classes.width100,
                        classes.overflowHidden
                    )}
                >
                    <div className={classNames(
                        classes.displayFlex,
                        showFooter && classes.borderBottomSection,
                        classes.backgroundSection,
                        classes.padding4
                    )}
                    >
                        <Typography
                            component="span"
                            color="primary"
                            className={classNames(
                                classes.buttonSecondaryTypography,
                                classes.timeContainer,
                                classes.borderRight,
                            )}
                        >
                            {isAllDay ? <span>All day</span> :
                                <>
                                    <span>
                                        {startTime}<span className={classes.fontSize12}>{startTimePeriod}</span>
                                    </span>
                                    <span className={classes.endTimeContainer}>
                                        {endMoment.format('h:mm')}
                                        <span className={classes.fontSize12}>{endMoment.format('a')}</span>
                                    </span>
                                </>
                            }
                        </Typography>
                        {eventElement}
                    </div>
                    {showFooter &&
                        <div className={classNames(classes.textCenter, classes.padding4, classes.fontWeight300)}>
                            <Tooltip title={eventFullLocation || ''} placement="top" enterDelay={400}>
                                <Typography
                                    component="span"
                                    color="inherit"
                                    className={classNames(
                                        classes.lineHeightAssignee,
                                        classes.fontSize16,
                                        classes.defaultCursor
                                    )}
                                >
                                    {eventLocation}
                                </Typography>
                            </Tooltip>
                            {caseAssignee &&
                                <Typography
                                    component="span"
                                    color="inherit"
                                    className={classNames(
                                        classes.lineHeightAssignee,
                                        classes.fontSize16
                                    )}
                                >
                                    Case Assigned to {caseAssignee}
                                </Typography>
                            }
                        </div>
                    }
                </div>
            </Paper>
        </div>
    );
};

export default withGStyles(styles)(EventTime);
