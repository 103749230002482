import { getValidator } from './utils';
import * as t from 'io-ts';

export enum QRStickerTemplate {
    Avery5160 = 'Avery5160',
    Avery2160 = 'Avery2160',
}

const QRStickerTemplateType = t.union([t.literal(QRStickerTemplate.Avery5160), t.literal(QRStickerTemplate.Avery2160)]);

const QrStickerGenerateRequestDefinition = {
    html: t.string,
    template: QRStickerTemplateType,
};

const QrStickerGenerateRequestType = t.type(QrStickerGenerateRequestDefinition);

export interface QrStickerGenerateRequest extends t.TypeOf<typeof QrStickerGenerateRequestType> {
    template: QRStickerTemplate;
}

export class QrStickerGenerateRequest {
    public static fromRequest = getValidator<QrStickerGenerateRequest>(QrStickerGenerateRequestType);
}

export interface QrStickerGenerateResponse {
    stickerPdf: string;
}
