import * as React from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { ContractOptionsUX, LegalLocation } from '../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { SlideTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogBody': {
            width: '100%',
            maxWidth: '100%',
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
                height: 'auto',
                margin: '48px auto !important'
            },
            '@media (min-width: 600px)': {
                maxWidth: 570,
                width: 570
            },
            '@media (min-width: 960px)': {
                width: '720px !important',
                maxWidth: '720px !important',
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    dialogFooter: {
        display: 'block',
        padding: 8,
        margin: 0,
        boxShadow: `0px -1px 10px 1px rgba(0, 0, 0, 0.2)`,
        zIndex: 1,
    },
    textAreaMinHeight: {
        minHeight: 292,
        height: 'auto'
    },
    textField: {
        margin: '48px auto'
    },
    textCont: {
        padding: '0 24px',
    },
    dialogBody: {},
});

export interface ContentType {
    header: string;
    placeholder: string;
    label: string;
    legalText: string | null;
    type: LegalLocation;
}

interface Props {
    contractOptions: ContractOptionsUX;
    location: LegalLocation | null;
    isDialogOpen: boolean;
    onSave: (changes: Partial<ContractOptionsUX>) => void;
    closeDialog: () => void;
    zIndex: number;
}

interface State {
    legalText: string | null;
}

export const getLegalContractContent = (
    contractOptions: ContractOptionsUX,
    location: LegalLocation | null
): ContentType => {

    switch (location) {
        case 'invoice_language':
            return {
                header: `Invoice Language`,
                legalText: contractOptions.legal_invoice,
                label: 'Invoice Language',
                placeholder: `Add your invoice language shown at the top of the statement`,
                type: LegalLocation.invoice_language,
            };
        case 'top':
            return {
                header: `Top of Statement`,
                legalText: contractOptions.legal_top,
                label: 'Legal Language',
                placeholder: `Add your legal language shown at the top of the statement`,
                type: LegalLocation.top,
            };
        case 'bottom':
            return {
                header: `Bottom of Statement`,
                legalText: contractOptions.legal_bottom,
                label: 'Legal Language',
                placeholder: `Add your legal language shown at the bottom of the statement`,
                type: LegalLocation.bottom
            };
        case 'cash_advance':
            return {
                header: `Cash Advances Optional Language`,
                legalText: contractOptions.legal_cash_advances,
                label: 'Cash Advances',
                placeholder: `Add optional language to show at the top of the Cash Advances section`,
                type: LegalLocation.cash_advance,
            };
        case 'service':
            return {
                header: `Professional Services Optional Language`,
                legalText: contractOptions.legal_pro_services,
                label: 'Professional Services',
                placeholder: `Add optional language to show at the top of the Professional Services section`,
                type: LegalLocation.service,
            };
        case 'merchandise':
            return {
                header: `Merchandise Optional Language`,
                legalText: contractOptions.legal_merchandise,
                label: 'Merchandise',
                placeholder: `Add optional language to show at the top of the Merchandise section`,
                type: LegalLocation.merchandise,
            };
        default:
            return {
                header: '',
                legalText: null,
                placeholder: '',
                label: '',
                type: location || LegalLocation.top,
            };
    }
};

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogContent' | 'clearIcon' | 'dialogBody'
    | 'heading' | 'dialogFooter' | 'textAreaMinHeight' | 'textField' | 'textCont'>;

type CombinedProps = DialogProps & StyledProps;
class AddLegalContract extends React.Component<CombinedProps, State> {
    state: State = {
        legalText: null
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: StyledProps) {
        const content = getLegalContractContent(nextProps.contractOptions, nextProps.location);

        this.setState({
            legalText: content.legalText,
        });
    }

    render() {
        const { classes, fullScreen, isDialogOpen, closeDialog, contractOptions, location, zIndex } = this.props;
        const { legalText } = this.state;
        const content = getLegalContractContent(contractOptions, location);

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                scroll={'body'}
                className={classes.root}
                classes={{
                    paper: classes.dialogBody,
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.dialogHeader,
                        classes.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon)}
                        onClick={closeDialog}
                    />
                    <Typography
                        component="p"
                        className={classNames(classes.heading)}
                        align="left"
                    >
                        {content.header}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid item className={classes.textCont}>
                        <TextField
                            className={classes.textField}
                            label={content.label}
                            multiline
                            fullWidth
                            InputProps={{
                                classes: {
                                    root: classes.textAreaMinHeight,
                                    inputMultiline: classes.textAreaMinHeight,
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={content.placeholder}
                            value={legalText || ''}
                            onChange={this.handleLegalContractChange}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialogFooter
                    }}
                >
                    <Button onClick={closeDialog}>
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        color="primary"
                        variant="contained"
                        className={classes.pullRight}
                        onClick={this.onSaveLegalContract}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleLegalContractChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            legalText: event.target.value,
        });
    };

    onSaveLegalContract = () => {
        const { location, onSave, closeDialog } = this.props;
        const { legalText } = this.state;

        let field: keyof ContractOptionsUX | null = null;

        switch (location) {
            case 'invoice_language':
                field = 'legal_invoice';
                break;
            case 'top':
                field = 'legal_top';
                break;
            case 'bottom':
                field = 'legal_bottom';
                break;
            case 'cash_advance':
                field = 'legal_cash_advances';
                break;
            case 'service':
                field = 'legal_pro_services';
                break;
            case 'merchandise':
                field = 'legal_merchandise';
                break;
            default:
                field = null;
                break;
        }
        if (!field) {
            return;
        }
        const changes: Partial<ContractOptionsUX> = {
            [field]: legalText,
        };
        onSave(changes);
        closeDialog();
    };
}

export default withFullScreen()(withGStyles(styles)(AddLegalContract));