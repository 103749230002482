import { setAppSnackbar } from './AppSnackbar.action';
import { sentryCapture } from '../sentry';
import { AppDispatch } from '../store';

export interface AppError {
    message: string;
    name: string;
    secondaryMessage: string;
    statusCode: number;
    error?: Error | string;
    metaData?: Record<string, unknown>;
}

export class AppError extends Error {
    public name: string;
    public sendToSentry: boolean;
    public metaData?: Record<string, unknown>;
    public Message?: string;

    constructor(
        public message: string,
        public statusCode: number = 520,
        name?: string,
        metaData?: Record<string, unknown>,
        sendToSentry: boolean = true,
        secondaryMessage: string = '',
    ) {
        super(message);
        this.name = name ? name : `HTTP ${statusCode}`;
        this.metaData = metaData;
        this.sendToSentry = sendToSentry;
        this.secondaryMessage = secondaryMessage;

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AppError);
        }
    }
}

export class UnauthorizedError extends AppError {
    constructor(public message: string, metaData?: Record<string, unknown>) {
        super(message, 401, '401 Unauthorized', metaData, false);
    }
}

export class NotFoundError extends AppError {
    constructor(public message: string, metaData?: Record<string, unknown>) {
        super(message, 404, '404 Not Found', metaData, false);
    }
}

export class ConflictError extends AppError {
    constructor(public message: string, metaData?: Record<string, unknown>) {
        super(message, 409, '409 Conflict', metaData, false);
    }
}

export class AccessRestrictedError extends AppError {
    constructor(public message: string, metaData?: Record<string, unknown>) {
        super(message, 403, '403 Access Restricted', metaData, false);
    }
}

type ErrorType = AppError | UnauthorizedError | NotFoundError | ConflictError;
export const isErrorType = (obj: unknown): obj is ErrorType => {
    const errorObj = obj as ErrorType;
    return Boolean(
        errorObj.message !== undefined &&
        errorObj.statusCode !== undefined &&
        errorObj.name !== undefined &&
        errorObj.secondaryMessage !== undefined
    );
};

interface HasMessage { message: string }
const hasMessage = (obj: unknown): obj is HasMessage => {
    return (obj as HasMessage).message !== undefined
        && typeof (obj as HasMessage).message === 'string';
};

interface HasSecondaryMessage { secondaryMessage: string }
const hasSecondaryMessage = (obj: unknown): obj is HasSecondaryMessage => {
    return (obj as HasSecondaryMessage).secondaryMessage !== undefined
        && typeof (obj as HasSecondaryMessage).secondaryMessage === 'string';
};

interface HasSendToSentry { sendToSentry: boolean }
const hasSendToSentry = (obj: unknown): obj is HasSendToSentry => {
    return (obj as HasSendToSentry).sendToSentry !== undefined
        && typeof (obj as HasSendToSentry).sendToSentry === 'boolean';
};

interface HandleExceptionParams {
    ex: unknown;
    userMessage?: string;
    showSnackbar?: boolean;
    userSecondaryMessage?: string;
    sendToSentry?: boolean;
    prefix?: string;
    metadata?: Record<string, unknown>;
    statusCode?: number;
    name?: string;
}
// Wrapper for sending a generic exception to registerAppError
// By default, send to sentry but don't snow snackbar
// If userMessage is defined, show the user a different message than what we send to sentry
export const handleException = (params: HandleExceptionParams) => {
    return async (dispatch: AppDispatch) => {
        const { ex, prefix, userMessage, userSecondaryMessage, showSnackbar } = params;
        const message = hasMessage(ex)
            ? ex.message // If we have a message property, use it
            : typeof ex === 'string' ? ex // Sometimes people throw a string as an exception
                : userMessage ?? 'Unhandled generic exception'; // Fall back to the user message or generic message
        const secondaryMessage =
            hasSecondaryMessage(ex) ? ex.secondaryMessage : userSecondaryMessage;
        const sendToSentry = params.sendToSentry ?? true;

        // Send one version that goes to the snackbar if userMessage is set
        if (userMessage || showSnackbar) {
            const userAppError = new AppError(
                userMessage ?? message,
                params.statusCode,
                params.name ?? 'Error',
                params.metadata,
                false, // Don't send user error sentry
                secondaryMessage,
            );
            dispatch(registerAppError(userAppError, {
                showSnackbar: showSnackbar ?? true,
                prefix,
            }));
        }
        // If we didn't show the user a message or we explicitly want to log the error
        if (!userMessage || sendToSentry) {
            const sysAppError = new AppError(
                message,
                params.statusCode,
                params.name ?? 'Error',
                params.metadata,
                sendToSentry ?? true,
                secondaryMessage,
            );
            dispatch(registerAppError(sysAppError, {
                showSnackbar: false,
                prefix,
            }));
        }
    };
};

export function registerAppError(
    error: string | ErrorType,
    options?: {
        prefix?: string;
        showSnackbar?: boolean;
        sendToSentry?: boolean;
        secondaryMessage?: string;
    },
) {
    return async (dispatch: AppDispatch) => {
        // option defaults
        let prefix = 'APPERROR';
        let showSnackbar = true;
        let sendToSentry = hasSendToSentry(error) ? error.sendToSentry : false;
        let secondaryMessage = hasSecondaryMessage(error) ? error.secondaryMessage : undefined;

        if (options) {
            prefix = options.prefix !== undefined ? options.prefix : prefix;
            showSnackbar = options.showSnackbar !== undefined ? options.showSnackbar : showSnackbar;
            sendToSentry = options.sendToSentry !== undefined ? options.sendToSentry : sendToSentry;
            secondaryMessage = options.secondaryMessage !== undefined ? options.secondaryMessage : secondaryMessage;
        }

        // print warning to console
        if (sendToSentry) {
            if (typeof error === 'string') {
                sentryCapture(`${prefix}: ${error}`);
            } else {
                sentryCapture(error, error.metaData);
            }
        }

        // show snackbar error
        if (showSnackbar) {
            let snackbarMessage = '';
            if (typeof error === 'string') {
                snackbarMessage = error;
            } else if (error.message && typeof error.message === 'string') {
                snackbarMessage = error.message;
            } else {
                snackbarMessage = 'Unhandled application error';
            }
            dispatch(setAppSnackbar(
                snackbarMessage,
                secondaryMessage && 'warning' || 'error',
                undefined,
                undefined,
                secondaryMessage
            ));
        }
    };
}
