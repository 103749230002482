import { reduce } from 'lodash';
import * as crypto from 'crypto';
import {
    DocUX,
    PdfFieldData,
    DocPacketFieldGroup,
    PdfFieldType,
    DocPacketSignerRecord,
    DocPacketDocSignerRecord,
    DocPacketContractSignerRecord,
} from '../types';
import Formatter from './formatter';

export const isDocAutofillEnabled = (doc: DocUX) => {
    return doc.pdf_fields && doc.pdf_fields.length > 0;
};

export const replaceUnderscores = (field: string, subStr: string = ' ') => field.replace(/_/g, subStr);

export const getFieldDisplayName = (field: PdfFieldData) => {
    const { name, format } = field;
    const formatters = format ? new Formatter(false, format).formats : null;
    const displayOverride = (formatters || []).find((f) => f.formatType === 'display_override');
    return displayOverride ? displayOverride.formatDetails : replaceUnderscores(name);
};

export const ungroupFieldData = (fieldGroups: DocPacketFieldGroup[]): PdfFieldData[] => {
    const allFields = reduce(
        fieldGroups,
        (fields, fieldGroup) => {
            const updatedFields: PdfFieldData[] = [
                ...fields,
                ...fieldGroup.fields,
            ];
            return updatedFields;
        },
        [] as PdfFieldData[],
    );
    return allFields;
};

export const findSharedFormDataFieldForField = (target: PdfFieldData, allFields: PdfFieldData[]) => {
    const targetAsFormDataId =
        `${target.rank}-${target.type}-Form_Data-${target.name}-${target.required}${target.format || ''}`;
    const match = allFields.find((field) => targetAsFormDataId === field.id);
    return match;
};

export const findSharedSignerFieldForField = (target: PdfFieldData, allFields: PdfFieldData[]) => {
    const formatWithoutPipes = (target.format || '').replace(/\|/g, '\\|');
    const sharedFieldRegex = new RegExp(
        `${target.rank}-${target.type}-[^-]+-${target.name}-${target.required}${formatWithoutPipes}`
    );
    const matchingField = allFields.find((field) =>
        sharedFieldRegex.test(field.id) && !findSharedFormDataFieldForField(target, [field]));
    return matchingField;
};

export const generateHelloSignTextTag = (
    fieldType: PdfFieldType,
    packetSigners: DocPacketSignerRecord[],
    signer: DocPacketDocSignerRecord | DocPacketContractSignerRecord,
    required: boolean = true,
): string | null => {
    // HelloSign's arrays start at index 1.
    const helloSignSignerIndex = packetSigners.findIndex((packetSigner) => {
        return packetSigner.id === signer.doc_packet_signer_id;
    });
    if (helloSignSignerIndex === -1) {
        return null;
    }
    return `[${fieldType}|${required ? 'req' : 'noreq'}|Signer${helloSignSignerIndex + 1}]`;
};

export const generateHash = (data: Buffer | string, algorithm: string = 'sha256'): string => {
    const hashObj = crypto.createHash(algorithm);
    hashObj.update(data);
    const hash = hashObj.digest('hex');
    return hash;
};
