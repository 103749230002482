import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Subtract } from 'utility-types';
import { AppDispatch } from '../../../store';
import { StoreState, useGSelector } from '../../../types';
import { noop } from 'lodash';

type WithDispatch<T> = T & { dispatch?: AppDispatch };

function withState<State extends object>(mapStateToProps?: (...args: Parameters<typeof noop>) => State) {
    return <Props extends WithDispatch<State>>(WrappedComponent: React.ComponentType<Props>) => {
        const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

        const ComponentWithState = (props: Subtract<Props, WithDispatch<State>>) => {
            const dispatch = useDispatch<AppDispatch>();
            const state = useGSelector((store: StoreState) => mapStateToProps?.(store, props));
            return <WrappedComponent {...(props as Props)} dispatch={dispatch} {...(state ?? {})} />;
        };
        ComponentWithState.displayName = `withState(${displayName})`;

        return React.memo(ComponentWithState);
    };
}

export default withState;
