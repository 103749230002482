import * as t from 'io-ts';
import Dinero from 'dinero.js';
import {
    CaseType,
    EntityType,
    FuneralHomeCaseRecord,
    GatherCaseRecord,
    getValidator,
    PayerEntity,
} from '.';
import { splitFullName } from '../utils';
import { ProductCategoryEnum } from './product';
import { EntitySummary } from './user';
import { FuneralHomeUX, FuneralHomeDemoSettings } from './funeralHome';
import moment from 'moment-timezone';

export enum AssetType { USD = 'USD' }

const dineroTypeDefinition = {
    amount: t.number,
    currency: t.string,
    precision: t.number,
};

const DineroType = t.type(dineroTypeDefinition);
interface DineroType extends t.TypeOf<typeof DineroType> { }

export const dineroToRequest = (dinero: Dinero.Dinero): DineroType => {
    return {
        amount: dinero.getAmount(),
        currency: dinero.getCurrency(),
        precision: dinero.getPrecision(),
    };
};

export const dineroFromRequest = getValidator<DineroType, Dinero.Dinero>(DineroType, {
    mapperFn: (value) => Dinero({
        amount: value.amount,
        currency: value.currency,
        precision: value.precision,
    }),
});

const paymentRequestTypeDefinition = {
    method: t.string,
    mode: t.string,
    type: t.string,
    amount: DineroType,
    merch_fee: DineroType,
    memo: t.string,
    funeralHomeCaseId: t.union([t.number, t.null]),
    funeralHomeId: t.union([t.number, t.undefined]),
    payer_id: t.union([t.number, t.null]),
    payer_name: t.union([t.string, t.null]),
    is_anon: t.boolean,
    external_id: t.union([t.string, t.undefined]),
    payment_id: t.union([t.number, t.undefined]),
    payment_date: t.union([t.string, t.undefined]),
};

const PaymentRequestType = t.type(paymentRequestTypeDefinition);

export enum PaymentMode { IN_PERSON = 'in_person', REMOTE = 'remote', GUEST = 'guest' }
export enum PaymentType { CASE = 'case', ONBOARDING = 'onboarding' }
export enum PaymentMethod {
    unknown = 'unknown',
    check = 'check',
    cash = 'cash',
    card = 'card',
    online = 'online',
    insurance = 'insurance',
    plaid = 'plaid',
    other = 'other',
}
export interface PaymentRequest {
    payment_id?: number;
    method: PaymentMethod;
    mode: PaymentMode;
    type: PaymentType;
    amount: Dinero.Dinero;
    merch_fee: Dinero.Dinero;
    memo: string;
    funeralHomeCaseId: number | null;
    funeralHomeId?: number;
    is_anon: boolean;
    payer_id?: number | null; // Points directly to payer.id
    payer_name?: string; // If neither id is set, then create a person, using the name
    external_id?: string; // Keep track of the check number, etc
    stripe_txn?: string; // Track the stripe balance transaction for Gather
    stripe_payment?: string; // Track the stripe payment id in the FH's stripe account
    payment_date?: moment.Moment; // Some payment types can set this explicitly (e.g case/check/insurance)
}

export class PaymentRequest {
    public static fromRequest = getValidator<t.TypeOf<typeof PaymentRequestType>, PaymentRequest>(PaymentRequestType, {
        mapperFn: (value): PaymentRequest => ({
            method: value.method,
            mode: value.mode,
            type: value.type,
            amount: Dinero({
                amount: value.amount.amount,
                currency: value.amount.currency,
                precision: value.amount.precision,
            }),
            merch_fee: Dinero({
                amount: value.merch_fee.amount,
                currency: value.merch_fee.currency,
                precision: value.merch_fee.precision,
            }),
            memo: value.memo,
            funeralHomeCaseId: value.funeralHomeCaseId,
            funeralHomeId: value.funeralHomeId,
            payer_id: value.payer_id && value.payer_id > 0 ? value.payer_id : null,
            payer_name: value.payer_name,
            payment_id: value.payment_id,
            is_anon: value.is_anon,
            external_id: value.external_id,
            payment_date: value.payment_date ? moment(value.payment_date) : undefined, // Defaults to NOW() in DB
        } as PaymentRequest),
    });
}

/* Payment Status
    proposed: FH has entered a proposed payment but not has attempted to collect/process the payment
    requested: FH has requested that a user make a payment by manually entering payment information
    pending: FH has begun payment processing
*/
export enum PaymentStatus {
    'proposed' = 'proposed',
    'requested' = 'requested',
    'pending' = 'pending',
    'failed' = 'failed',
    'succeeded' = 'succeeded',
    'refunded' = 'refunded',
    'canceled' = 'canceled',
}

export interface Payment extends Pick<PaymentRecord,
    | 'id'
    | 'status'
    | 'method'
    | 'mode'
    | 'payer'
    | 'is_anon'
    | 'customer_id'
    | 'processor_id'
    | 'funeral_home_case_id'
    | 'error'
    | 'payment_date'
    | 'source_id'
    | 'memo'
    | 'external_id'
> {
    amount: Dinero.Dinero;
    app_fee: Dinero.Dinero;
    proc_fee: Dinero.Dinero;
    merch_fee: Dinero.Dinero;
}

export interface StripeChargeDetails {
    loading: boolean;
    charge?: {
        id: string;
        amount: number;
        amount_refunded: number;
        created: Date;
        currency: string;
        description: string;
        refunded: boolean;
        brand: string | null; // Discover, Visa, etc
        exp_month: number | null;
        exp_year: number | null;
        last4: string | null;
        name: string | null;
    };
}

export interface TransactionRecord {
    gather_case_id: number;
    id: number | null;
    type: 'INVOICE' | 'PAYMENT';
    description: string | null;
    status: PaymentStatus | null;
    asset_type: AssetType;
    debit: string | null;
    credit: string | null;
    invoice_id: number | null;
    payment_id: number | null;
    merch_fee: string | null;
    sales_tax: string | null;
    is_sub_item: boolean;
    date: Date;
    method: PaymentMethod | null;
    mode: PaymentMode | null;
    payment_date: Date;
    is_anon: boolean;
    payer_name: string | null;
    payer_user_id: number | null;
    payer_id: number | null;
    payer_email: string | null;
    payer_phone: string | null;
    creator_fname: string | null;
    creator_lname: string | null;
    creator_id: number | null;
    creator_email: string | null;
    key: string;
    is_proposed: boolean;
    external_id: string | null;
    contract_id: number | null;
    invoice_tax_rate_id: number | null;
}

export interface Transaction extends Omit<TransactionRecord, 'debit' | 'credit' | 'sales_tax' | 'merch_fee'> {
    debit: number;
    credit: number;
    sales_tax: number;
    merch_fee: number | null;
    status: PaymentStatus;
}

export class Transaction {
    static dollarFormat(txn: Transaction, signed: boolean = true, excludeMerchFee = false) {
        let amount = signed
            ? (txn.debit || -txn.credit) + txn.sales_tax
            : (txn.debit || txn.credit) + txn.sales_tax;

        if (excludeMerchFee && txn.merch_fee) {
            amount -= txn.merch_fee;
        }

        return Transaction.dollarFormatNumber(amount);
    }
    static dollarFormatNumber(amount: number) {
        return (amount / 100.0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
    static dollarFormatNumberAbs(amount: number) {
        return (Math.abs(amount) / 100.0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
    static dollarFormatUnsigned(txn: Transaction, excludeMerchFee = false) {
        return Transaction.dollarFormat(txn, false, excludeMerchFee);
    }
}

export interface PaymentTransaction extends Transaction {
    payment_id: number;
    creator_fname: string;
    creator_lname: string;
    creator_email: string;
    creator_id: number;
    method: PaymentMethod;
    mode: PaymentMode;
    status: PaymentStatus;
}

export class PaymentTransaction {
    static constructPayer(payment: PaymentTransaction, entity?: EntitySummary) {
        const { fname, mname, lname } = splitFullName(payment.payer_name);
        const payerEntity: PayerEntity = {
            id: entity ? entity.entity_id : payment.payer_id || 0,
            fname: entity ? entity.fname : fname,
            mname: entity ? entity.mname || undefined : mname,
            lname: entity ? entity.lname : lname,
            email: entity && entity.email ? entity.email : payment.payer_email || '',
            phone: entity && entity.phone ? entity.phone : payment.payer_phone || '',
            type: entity && entity.type || EntityType.person,
            user_profile_id: entity && entity.user_id ? entity.user_id : payment.payer_user_id || null
        };
        return {
            payerEntity,
            user: entity && entity.user || undefined,
            home_address: entity ? entity.home_address : null
        };
    }
}

export interface PaymentGrouping {
    payer_user_id?: number | null;
    payer_id: number;
    total: number;
    transactions: PaymentTransaction[];
}

export interface CaseTotalsRecord extends Pick<FuneralHomeCaseRecord,
    | 'uuid'
    | 'expense_total'
    | 'collected_total'
    | 'proposed_total'
> {
    funeral_home_case_id: FuneralHomeCaseRecord['id'];
}

export interface CaseTotals {
    case_uuid: CaseTotalsRecord['uuid'];
    funeral_home_case_id: CaseTotalsRecord['funeral_home_case_id'];
    expense_total: number;
    collected_total: number;
    proposed_total: number;
}

export interface PaymentFailure {
    message: string;
    secondaryMessage?: string;
}

export interface CaseTransactions {
    isLoading: boolean;
    funeralHomeCaseId: number;
    transactions: Transaction[];
    payments: PaymentGrouping[];
    totals: CaseTotals;
    feeSchedule: FeeSchedule | null;
}

export type FeeRecord = {
    base: number;
    rate: number;
    max: number; // only applies to the amount * rate, always add base
    // src indicates where the fee is defined
    // F = Funeral Home, G = Gather Default, N = Not Defined
    src: 'F' | 'G' | 'N';
};
export type FeeType = 'platform' | 'merchant' | 'processor';
export type BaseFeeSchedule = Record<FeeType, FeeRecord>;
export type PartialBaseFeeSchedule = Partial<BaseFeeSchedule>;
export type FeeSchedule = Record<PaymentMethod, BaseFeeSchedule>;
export type PartialFeeSchedule = Partial<Record<PaymentMethod, PartialBaseFeeSchedule>> | null;
export type OwnerType = 'GATHER' | 'FH';
export interface FeeConfiguration {
    loadTime: string;
    funeralHomeId: number;
    schedule: Record<OwnerType, PartialFeeSchedule>;
}
export interface FeeChange {
    method: PaymentMethod;
    feeType: FeeType;
    base: number | null;
    rate: number | null;
    max: number | null;
    baseError: string | null;
    rateError: string | null;
    maxError: string | null;
}

export interface LedgerExtract {
    id: number;
    funeral_home_id: number | null;
    owner: OwnerType;
    created_by: number;
    extract_time: Date;
    type: 'INVOICE' | 'PAYMENT';
    account: number;
}
export interface LedgerExtractSummaryRecord extends LedgerExtract {
    debits: string;
    credits: string;
    postings: number;
    journals: number;
    details: string;
    first_entry: Date;
    last_entry: Date;
    account_name: string;
    account_type: string;
    death_date: string | null;
    case_full_name: string | null;
    case_name: string | null;
    case_number: string | null;
    gather_case_id: number | null;
}

export interface LedgerExtractSummary extends Omit<LedgerExtractSummaryRecord, 'debits' | 'credits'> {
    debits: number;
    credits: number;
}

export interface LedgerNonExtractRevisionRecord {
    row_id: string;
    type: 'INVOICE' | 'PAYMENT';
    created_time: Date;
    death_date: GatherCaseRecord['dod_start_date'];
    fname: GatherCaseRecord['fname'];
    lname: GatherCaseRecord['lname'];
    case_number: string;
    gather_case_id: GatherCaseRecord['id'];
    case_name: GatherCaseRecord['name'];
    funeral_home_id: number;
    case_uuid: FuneralHomeCaseRecord['uuid'];
    details: string;
    amount: string;
    asset_type: AssetType;
    contract_id: number;
    frozen_time: Date;
}
export interface LedgerNonExtractRevision extends Omit<LedgerNonExtractRevisionRecord, 'amount'> {
    amount: number;
}

export interface LedgerExtractReport {
    closedBatches: LedgerExtractSummary[];
    openBatches: LedgerExtractSummary[];
    nonBatches: LedgerNonExtractRevision[];
}

export interface PaymentRecord {
    id: number;
    status: PaymentStatus;
    method: PaymentMethod;
    mode: PaymentMode;
    payer: number | null;
    is_anon: boolean;
    customer_id: number;
    processor_id: number | null;
    funeral_home_case_id: number;
    error: string | null;
    payment_date: Date;
    amount: string;
    proc_fee: string;
    app_fee: string;
    merch_fee: string;
    asset_type: AssetType;
    source_id: string | null;
    memo: string | null;
    external_id: string | null;
    stripe_payment: string | null;
    created_by: number;
    created_time: Date;
    reconciled_time: Date | null;
    canceled_time: Date | null;
    payout_id: string | null;
}

export interface PaymentReportRecord extends PaymentRecord {
    gather_case_name: string;
    gather_case_fullname: string;
    gather_case_type: CaseType;
    payer_name: string;
    payer_type: EntityType;
    payout_date: Date | null;
    payout_status: string;
}

export enum PayoutStatus {
    paid = 'paid',
    pending = 'pending',
    in_transit = 'in_transit',
    canceled = 'canceled',
    failed = 'failed',
}

export interface FeeDetail {
    amount: number;
    type: string;
}

export interface PayoutTransaction {
    amount: number;
    available_on: number;
    fee: number;
    fee_details: FeeDetail;
    id: string;
    net: number;
    source: string;
    status: PayoutStatus;
    type: string;
    currency: string;
    description: string;
    created: number;
}

export interface PayoutTransactions {
    data: PayoutTransaction[];
    has_more: boolean;
    url: string;
}

export interface Payout {
    id: string; // This is the id created by Stripe
    funeral_home_id: number;
    amount: number; // Expressed in pennies
    arrival_date: Date;
    created: Date;
    updated: Date;
    asset_type: AssetType;
    failure_code: string | null;
    failure_message: string | null;
    status: PayoutStatus;
    count: number;
    transactions: PayoutTransactions;
}

export interface PaymentReportParams {
    startDate: Date | null;
    endDate: Date | null;
    funeralHomeId: number;
}

export interface PaymentReport {
    params: PaymentReportParams;
    payouts: Payout[];
    payments: PaymentReportRecord[];
}

export enum DateType {
    TransactionDate = 'TransactionDate',
    DeathDate = 'DeathDate',
    CreatedDate = 'CreatedDate',
}

interface RevenueReportColumns<T extends string | number> {
    proserve_amount: T;
    proserve_discount: T;
    proserve_tax: T;
    merchandise_amount: T;
    merchandise_discount: T;
    merchandise_tax: T;
    cashadvance_amount: T;
    cashadvance_discount: T;
    cashadvance_tax: T;
    other_amount: T;
    other_discount: T;
    other_tax: T;
    package_discount: T;
    contract_tax_total: T;
}

export interface RevenueRecordFigures extends RevenueReportColumns<number> {
}

interface RevenueReportExtras {
    txn_date: Date;
    transaction_date: Date;
    case_created_time: Date;
    death_date: Date | null;
    case_id: number;
    case_name: string;
    case_fullname: string;
    case_number: string | null;
    case_type: CaseType;
    description: string;
    revision_rank: number;
    txn_type: 'CONTRACT' | 'CHARGE';
}

// string === BIGINT for the RevenueReportColumns
export interface RevenueReportRecord extends RevenueReportExtras, RevenueReportColumns<string> {
}
export interface RevenueReportUX extends RevenueReportExtras, RevenueReportColumns<number> {
}

// ---> InvoiceRequest <---
const InvoiceRequestDefinition = {
    amount: DineroType,
    taxAmount: DineroType,
    description: t.string,
    productId: t.union([t.number, t.null]),
    category: t.string,
    taxRateId: t.union([t.number, t.null]),
};
const InvoiceRequestType = t.type(InvoiceRequestDefinition);

export interface InvoiceRequest extends t.TypeOf<typeof InvoiceRequestType> {
    category: ProductCategoryEnum;
}

export class InvoiceRequest {
    public static fromRequest = getValidator<InvoiceRequest>(InvoiceRequestType);
}

export interface StripeOnboardingResponse {
    demoSettings: FuneralHomeDemoSettings;
    updatedFuneralHome: FuneralHomeUX;
}

export enum LedgerExtractType {
    'QB_ZEDAXIS' = 'QB_ZEDAXIS',
    'QB_ZEDAXIS_NOFEES' = 'QB_ZEDAXIS_NOFEES',
    'ROSEBERRY' = 'ROSEBERRY',
    'FULL' = 'FULL'
}

export type InvoiceStatus = 'draft' | 'submitted' | 'paid' | 'void';

export interface InvoiceRecord {
    id: number;
    status: InvoiceStatus;
    customer_id: number;
    funeral_home_case_id: number;
    payment_id: number | null;
    description: string;
    issue_date: Date;
    due_date: Date;
    amount_due: string;
    sales_tax: string;
    is_fee: boolean;
    asset_type: AssetType;
    reference: string | null;
    memo: string | null;
    product_id: number | null;
    product_category: ProductCategoryEnum | null;
    tax_rate_id: number | null;
}