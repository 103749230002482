import * as React from 'react';

import Dialog from '@mui/material/Dialog';

import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import EditFingerprintDialogContent from './EditFingerprint.dialogContent';
import { SlideTransition } from '../../../../common/Transitions';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import withFullScreen from '../../../../common/utilHOC/WithFullScreen';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { withDialogMounter } from '../../../../../DialogMounter';
import { FingerprintType, FingerprintUX, UserRoles } from '../../../../../shared/types';
import { StoreState } from '../../../../../types';
import withState from '../../../../common/utilHOC/WithState';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '20px',
        padding: '20px 24px 8px'
    },
    dialogTitle: {
        padding: '8px 4px 0 12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap'
    },
    clearIcon: {
        fontSize: 40,
        cursor: 'pointer'
    },

    dialogPaper: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: 425
        }
    },
    fingerprintContainer: {
        maxWidth: 160,
        maxHeight: 180,
        position: 'relative',
    },
});

const mapStateToProps = ({ userSession, casesState }: StoreState) => {
    return {
        isFHorGOMUserOnFH: UserRoles.isFHorGOMUserOnFH(
            userSession.userData,
            casesState.selectedCase?.funeral_home_id || null
        )
    };
};

type Classes = 'root' | 'dialogContent' | 'dialogTitle' | 'clearIcon'
    | 'dialogPaper' | 'fingerprintContainer';
export type FingerprintDialogStyledProps = WithGStyles<Classes>;

interface DialogProps {
    fullScreen: boolean;
}
interface Props extends ReturnType<typeof mapStateToProps> {
    disableEdit?: boolean;
    isDialogOpen: boolean;
    zIndex: number;
    fingerprint: FingerprintUX | null;
    caseFname: string;
    onSave: (type: FingerprintType) => void;
    onClose: () => void;
    onDelete: () => void;
}
interface State {
    isConfirmationDialogOpen: boolean;
    fingerprintType: FingerprintType;
}
type CombinedProps = Props & FingerprintDialogStyledProps & DialogProps;

class EditFingerprintDialog extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            isConfirmationDialogOpen: false,
            fingerprintType: props.fingerprint ? props.fingerprint.type : FingerprintType.fingerprint,
        };
    }

    componentDidUpdate(prevProps: Readonly<CombinedProps>) {
        const { fingerprint: prevPrint } = prevProps;
        const { fingerprint } = this.props;

        if (prevPrint !== fingerprint && fingerprint) {
            this.setState({ fingerprintType: fingerprint.type });
        }
    }

    render() {
        const {
            zIndex,
            isDialogOpen,
            fingerprint,
            classes,
            caseFname,
            fullScreen,
            disableEdit,
            isFHorGOMUserOnFH,
            onSave,
            onClose
        } = this.props;
        const { fingerprintType } = this.state;

        return (
            <>
                <Dialog
                    transitionDuration={300}
                    TransitionComponent={SlideTransition}
                    style={{ zIndex }}
                    open={isDialogOpen}
                    onClose={onClose}
                    fullScreen={fullScreen}
                    classes={{
                        paper: classes.dialogPaper
                    }}
                >

                    {fingerprint &&
                        <EditFingerprintDialogContent
                            disableEdit={disableEdit}
                            classes={classes}
                            zIndex={zIndex}
                            caseFname={caseFname}
                            fingerprint={fingerprint}
                            selectedType={fingerprintType}
                            isFHorGOMUserOnFH={isFHorGOMUserOnFH}
                            onTypeChange={type => this.setState({ fingerprintType: type })}
                            onClose={onClose}
                            onSave={() => onSave(fingerprintType)}
                            openConfirmationDialog={this.openConfirmationDialog}
                        />
                    }
                </Dialog>

                {this.renderConfirmationDialog()}
            </>
        );
    }

    openConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: true });
    };

    closeConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: false });
    };

    deletePrint = () => {
        this.closeConfirmationDialog();
        this.props.onClose();
        this.props.onDelete();
    };

    renderConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isConfirmationDialogOpen } = this.state;

        return (
            <ConfirmationDialog
                open={isConfirmationDialogOpen}
                zIndex={zIndex}
                header="Are you sure?"
                subHeader="Are you sure you want to delete this print? This can't be undone."
                confirmationButtonText="Delete Print"
                onClose={this.closeConfirmationDialog}
                onConfirm={this.deletePrint}
            />
        );
    };
}

export default withFullScreen()(withState(mapStateToProps)(
    withGStyles(styles)(withDialogMounter(EditFingerprintDialog))
));
