import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ORANGE_COLOR } from '../../../../constants/colorVariables';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import { ProductUX, ProductContractItemUX, ProductItem, GPLContext } from '../../../../shared/types';
import ProductCard from '../widgets/ProductItemCard';
import { AllowanceSummary } from '../widgets/AllowancePopper';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

interface Props {
    canUserEditContract: boolean;
    productItems: ProductItem[];
    allowances: AllowanceSummary[];
    caseFirstName: string;
    context: GPLContext;
    onProductItemClick: (productItem: ProductItem) => void;
    onAddContractItem?: (product: ProductUX, quantity: number) => void;
    onUpdateContractItemQuantity?: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    onEditClick?: (productId: number) => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    imageContainer: {
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.common.white,
        width: 380,
        height: 235,
        borderRadius: 8
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 20,
        fontSize: 20,
        fontWeight: 200
    },
    featuredProdCont: {
        color: theme.palette.primary.main,
        borderTop: '1px solid',
        borderBottom: '1px solid',
        marginBottom: 28
    },
    dashedBorder: {
        borderTop: `1px dashed ${ORANGE_COLOR}`,
        borderBottom: `1px dashed ${ORANGE_COLOR}`,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'imageContainer' | 'title' | 'featuredProdCont' | 'dashedBorder'>;

const FeaturedProducts = (props: StyledProps) => {
    const {
        classes,
        productItems,
        caseFirstName,
        onProductItemClick,
        onAddContractItem,
        onUpdateContractItemQuantity,
        onRemoveContractItem,
        canUserEditContract,
        context,
        onEditClick,
        allowances,
    } = props;

    return (
        <Grid container justifyContent="center" alignItems="center"
            className={classNames(
                classes.background_primary_0_2,
                classes.featuredProdCont,
                context === GPLContext.BackOffice && classes.dashedBorder,
            )}
        >
            <Typography
                color="primary"
                variant="subtitle2"
                className={classes.title}
            >
                <LoyaltyIcon className={classes.verticalAlignMiddle} /> Featured Products
            </Typography>

            <ul className={classNames(classes.list, classes.root)}>
                {productItems.map((productItem) =>
                    <ProductCard
                        canUserEditContract={canUserEditContract}
                        key={productItem.key}
                        productItem={productItem}
                        caseFirstName={caseFirstName}
                        onClick={() => onProductItemClick(productItem)}
                        onAddContractItem={onAddContractItem}
                        onUpdateQuantity={onUpdateContractItemQuantity}
                        onRemoveContractItem={onRemoveContractItem}
                        isFeatured
                        isShowPrices={false}
                        context={context}
                        onEditClick={onEditClick}
                        size={'auto'}
                        allowances={allowances}
                    />
                )}
            </ul>
        </Grid>
    );
};

export default withGStyles(styles)(FeaturedProducts);
