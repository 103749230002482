import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";

import ActionCheckbox from "./ActionCheckbox";
import { getFormattedPhoneNumber } from "../../../services";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'flex-start',
        '& > p': {
            fontSize: 16,
            lineHeight: 1.25,
            paddingTop: 10
        }
    },
}), { name: 'CommunicationPreference' });

interface Props {
    label: string;
    email: string | null;
    phone: string | null;
    sendMail: boolean;
    sendText: boolean;
    isMe: boolean;
    onAddPhoneOrEmail: () => void;
    onMailToggle: (checked: boolean) => void;
    onPhoneToggle: (checked: boolean) => void;
}
const CommunicationPreference = (props: Props) => {
    const { label, email, phone, sendMail, sendText, isMe, onMailToggle, onPhoneToggle, onAddPhoneOrEmail } = props;

    const classes = useStyles();

    return (
        <Grid container wrap="nowrap" className={classes.root}>
            <Typography mr="auto">
                {label}
            </Typography>

            <ActionCheckbox
                data={email
                    ? `This will send an email notification to ${email}`
                    : null}
                checked={sendMail}
                invited
                fname=""
                invitedTooltip={`Click to add ${isMe ? 'your' : 'an'} email`}
                checkboxColor="secondary"
                onChange={onMailToggle}
                onAdd={onAddPhoneOrEmail}
            />

            <ActionCheckbox
                data={phone
                    ? `This will send a text notification to ${getFormattedPhoneNumber(phone) || ''}. 
                Please ensure this number can receive text messages`
                    : null}
                checked={sendText}
                invited
                fname=""
                invitedTooltip={`Click to add ${isMe ? 'your' : 'a'} phone number`}
                checkboxColor="secondary"
                onChange={onPhoneToggle}
                onAdd={onAddPhoneOrEmail}
            />
        </Grid>
    );
};

export default CommunicationPreference;