import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanLoginProps } from '../utils';
import CanLoginWrapper from '../widgets/CanLoginWrapper';

const CanLogin = (props: CanLoginProps) => {
    const { accountType, activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <CanLoginWrapper
            caseFName={activeCase.fname}
            accountType={accountType}
            onStepChange={onStepChange}
        >
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                Since you have the ability to log in to {activeCase.fname}'s Netflix account, this process
                will only take a few seconds. Simply click the link below to get started.
            </Typography>
            <Grid>
                <a
                    href="https://www.netflix.com/cancelplan"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop20
                        )}
                    >
                        Delete {activeCase.fname}'s Streaming Plan <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                If {activeCase.fname} had subscribed to Netflix's mail-order DVD service,
                you can click below to quickly cancel this service as well.
            </Typography>
            <Grid>
                <a
                    href="https://www.dvd.netflix.com/subscriptioncancel"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop20
                        )}
                    >
                        Delete {activeCase.fname}'s DVD Plan <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </CanLoginWrapper>
    );
};

export default CanLogin;