import * as t from 'io-ts';
import { getValidator } from './utils';


export enum SendgridDeliveryEventType {
    'processed' = 'processed',
    'dropped' = 'dropped',
    'delivered' = 'delivered',
    'deferred' = 'deferred',
    'bounce' = 'bounce',
    'blocked' = 'blocked'
}
export enum SendgridEngagementEventType {
    'open' = 'open',
    'click' = 'click',
    'spamreport' = 'spamreport',
    'unsubscribe' = 'unsubscribe',
    'group_unsubscribe' = 'group_unsubscribe',
    'group_resubscribe' = 'group_resubscribe'
}
export const SendgridEventType = {
    ...SendgridDeliveryEventType,
    ...SendgridEngagementEventType,
};
export type SendgridEventType = SendgridDeliveryEventType | SendgridEngagementEventType;

export const isSendgridEventType = (s: unknown): s is SendgridEventType => {

    return Boolean(typeof s === 'string' && SendgridEventType[s]);
};
/**
 * Sengrid interfaces not implemented
 *  Delivery Processed
 *  Engagement Open
 *  Engagement Click
 * 
 */

/**
 * Sendgrid requires this passthrough data to be strings
 */
const GatherSendgridCustomArgsType = t.intersection([
    t.type({
        uuid: t.string,
    }),
    t.partial({
        unsubscribeGroupIdString: t.string,
        websiteIdStr: t.string,
        gatherTemplateId: t.string,

    })]);
export interface SendgridCustomArgs extends t.TypeOf<typeof GatherSendgridCustomArgsType> { };

const sendgridPoolDef = {
    name: t.string,
    id: t.number,
};
const SendgridPoolType = t.type(sendgridPoolDef);

const SendgridEventAllAttributesDefinition = {
    email: t.string,
    timestamp: t.number,
    "smtp-id": t.string,
    sg_machine_open: t.boolean,
    sg_event_id: t.string,
    sg_message_id: t.string,
    response: t.string,
    attempt: t.string,
    useragent: t.string,
    ip: t.string,
    url: t.string,
    // the following appear to be optional
    type: t.union([t.literal('bounce'), t.literal('blocked')]), // bounce | blocked
    category: t.union([t.string, t.undefined, t.array(t.string)]),
    reason: t.union([t.string, t.undefined]),
    status: t.union([t.string, t.undefined]),
    // bounce_classification  invalid Address, Technical, Content, 
    // Reputation, Frequency/Volume, Mailbox Unavailable, or Unclassified
    bounce_classification: t.union([t.string, t.undefined]),
    asm_group_id: t.union([t.number, t.undefined])

};

const SendgridDroppedEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridDeliveryEventType.dropped),
    }), t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        reason: SendgridEventAllAttributesDefinition.reason,
        status: SendgridEventAllAttributesDefinition.status,
        category: SendgridEventAllAttributesDefinition.category,

    })]);
export interface SendgridDroppedEvent extends t.TypeOf<typeof SendgridDroppedEventDefinition> { }
export class SendgridDroppedEvent {
    public static fromRequest = getValidator<SendgridDroppedEvent>(SendgridDroppedEventDefinition, { quiet: true });
}

const SendgridDeliveredEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        response: SendgridEventAllAttributesDefinition.response,
        event: t.literal(SendgridDeliveryEventType.delivered),
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,

    })]);
export interface SendgridDeliveredEvent extends t.TypeOf<typeof SendgridDeliveredEventDefinition> { }
export class SendgridDeliveredEvent {
    public static fromRequest = getValidator<SendgridDeliveredEvent>(SendgridDeliveredEventDefinition, { quiet: true });
}

const SendgridDeferredEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridDeliveryEventType.deferred),
        response: SendgridEventAllAttributesDefinition.response,
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,
        // attempt: SendgridEventAllAttributesDefinition.attempt,
        reason: SendgridEventAllAttributesDefinition.reason,
    })]);
export interface SendgridDeferredEvent extends t.TypeOf<typeof SendgridDeferredEventDefinition> { }
export class SendgridDeferredEvent {
    public static fromRequest = getValidator<SendgridDeferredEvent>(SendgridDeferredEventDefinition, { quiet: true });
}

const SendgridBouncedEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridDeliveryEventType.bounce),
        type: t.literal("bounce"),
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,
        bounce_classification: SendgridEventAllAttributesDefinition.bounce_classification,
        reason: SendgridEventAllAttributesDefinition.reason,
        status: SendgridEventAllAttributesDefinition.status,
    })]);
export interface SendgridBouncedEvent extends t.TypeOf<typeof SendgridBouncedEventDefinition> { }
export class SendgridBouncedEvent {
    public static fromRequest = getValidator<SendgridBouncedEvent>(SendgridBouncedEventDefinition, { quiet: true });
}

const SendgridBlockedEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridDeliveryEventType.bounce),
        type: t.literal("blocked"),
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,
        bounce_classification: SendgridEventAllAttributesDefinition.bounce_classification,
        reason: SendgridEventAllAttributesDefinition.reason,
        status: SendgridEventAllAttributesDefinition.status,

    })]);
export interface SendgridBlockedEvent extends t.TypeOf<typeof SendgridBlockedEventDefinition> { }
export class SendgridBlockedEvent {
    public static fromRequest = getValidator<SendgridBlockedEvent>(SendgridBlockedEventDefinition, { quiet: true });
}

// Engagement Events
const SendgridSpamReportEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridEngagementEventType.spamreport),
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
    })]);

export interface SendgridSpamReportEvent extends t.TypeOf<typeof SendgridSpamReportEventDefinition> { }
export class SendgridSpamReportEvent {
    public static fromRequest = getValidator<SendgridSpamReportEvent>(SendgridSpamReportEventDefinition,
        { quiet: true });
}

const SendgridUnsubscribeEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridEngagementEventType.unsubscribe),
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,
    })]);

export interface SendgridUnsubscribeEvent extends t.TypeOf<typeof SendgridUnsubscribeEventDefinition> { }
export class SendgridUnsubscribeEvent {
    public static fromRequest = getValidator<SendgridUnsubscribeEvent>(SendgridUnsubscribeEventDefinition,
        { quiet: true });
}

const SendgridGroupUnsubscribeEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridEngagementEventType.group_unsubscribe),
        ip: SendgridEventAllAttributesDefinition.ip,
        // url: SendgridEventAllAttributesDefinition.url,
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,
        // useragent: SendgridEventAllAttributesDefinition.useragent,
        asm_group_id: SendgridEventAllAttributesDefinition.asm_group_id,
    })]);
export interface SendgridGroupUnsubscribeEvent extends t.TypeOf<typeof SendgridGroupUnsubscribeEventDefinition> { }
export class SendgridGroupUnsubscribeEvent {
    public static fromRequest = getValidator<SendgridGroupUnsubscribeEvent>(SendgridGroupUnsubscribeEventDefinition,
        { quiet: true });
}

const SendgridGroupResubscribeEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        event: t.literal(SendgridEngagementEventType.group_resubscribe),
        // useragent: SendgridEventAllAttributesDefinition.useragent,
        ip: SendgridEventAllAttributesDefinition.ip,
        // url: SendgridEventAllAttributesDefinition.url,
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        // category: SendgridEventAllAttributesDefinition.category,
        asm_group_id: SendgridEventAllAttributesDefinition.asm_group_id,
    })]);

export interface SendgridGroupResubscribeEvent extends t.TypeOf<typeof SendgridGroupResubscribeEventDefinition> { }
export class SendgridGroupResubscribeEvent {
    public static fromRequest = getValidator<SendgridGroupResubscribeEvent>(SendgridGroupResubscribeEventDefinition,
        { quiet: true });
}

const SendgridOpenEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        event: t.literal(SendgridEngagementEventType.open),
        sg_machine_open: SendgridEventAllAttributesDefinition.sg_machine_open,
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        useragent: SendgridEventAllAttributesDefinition.useragent,
        ip: SendgridEventAllAttributesDefinition.ip,
    }),
    t.partial({
        category: SendgridEventAllAttributesDefinition.category,
        asm_group_id: SendgridEventAllAttributesDefinition.asm_group_id,
        pool: t.union([SendgridPoolType, t.string, t.undefined]),
    })]);

export interface SendgridOpenEvent extends t.TypeOf<typeof SendgridOpenEventDefinition> { }
export class SendgridOpenEvent {
    public static fromRequest = getValidator<SendgridOpenEvent>(SendgridOpenEventDefinition, { quiet: true });
}

const SendgridClickEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        event: t.literal(SendgridEngagementEventType.click),
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
        useragent: SendgridEventAllAttributesDefinition.useragent,
        ip: SendgridEventAllAttributesDefinition.ip,
        url: SendgridEventAllAttributesDefinition.url,
    }),
    t.partial({
        category: SendgridEventAllAttributesDefinition.category,
        asm_group_id: SendgridEventAllAttributesDefinition.asm_group_id,
    })]);

export interface SendgridClickEvent extends t.TypeOf<typeof SendgridClickEventDefinition> { }
export class SendgridClickEvent {
    public static fromRequest = getValidator<SendgridClickEvent>(SendgridClickEventDefinition, { quiet: true });
}

const SendgridProcessedEventDefinition = t.intersection([
    GatherSendgridCustomArgsType,
    t.type({
        email: SendgridEventAllAttributesDefinition.email,
        timestamp: SendgridEventAllAttributesDefinition.timestamp,
        event: t.literal(SendgridDeliveryEventType.processed),
        sg_event_id: SendgridEventAllAttributesDefinition.sg_event_id,
        sg_message_id: SendgridEventAllAttributesDefinition.sg_message_id,
    }),
    t.partial({
        "smtp-id": SendgridEventAllAttributesDefinition["smtp-id"],
        category: SendgridEventAllAttributesDefinition.category,
        asm_group_id: SendgridEventAllAttributesDefinition.asm_group_id,
        pool: t.union([SendgridPoolType, t.string, t.undefined]),
    })]);

export interface SendgridProcessedEvent extends t.TypeOf<typeof SendgridProcessedEventDefinition> { }
export class SendgridProcessedEvent {
    public static fromRequest = getValidator<SendgridProcessedEvent>(SendgridProcessedEventDefinition, { quiet: true });
}

const SendgridEventsDefinition = t.union([
    SendgridDroppedEventDefinition,
    SendgridDeliveredEventDefinition,
    SendgridDeferredEventDefinition,
    SendgridBouncedEventDefinition,
    SendgridBlockedEventDefinition,
    SendgridSpamReportEventDefinition,
    SendgridUnsubscribeEventDefinition,
    SendgridGroupUnsubscribeEventDefinition,
    SendgridGroupResubscribeEventDefinition,
    SendgridOpenEventDefinition,
    SendgridClickEventDefinition,
    SendgridProcessedEventDefinition,
]);

export type SendgridEvent = t.TypeOf<typeof SendgridEventsDefinition>;

const acceptedSendgridEventsDefinition = {
    sendgridEvents: t.array(SendgridEventsDefinition),
};
const AcceptedSendgridEventsType = t.type(acceptedSendgridEventsDefinition);
export interface AcceptedSendgridEvents extends t.TypeOf<typeof AcceptedSendgridEventsType> { }


/** ****************************************************
 * 
 * Is functions
 ** ****************************************************
 */
export const isSendgridDroppedEvent = (e: SendgridEvent): e is SendgridDroppedEvent => {
    return SendgridDroppedEventDefinition.is(e);
};

export const isSendgridDeliveredEvent = (e: SendgridEvent): e is SendgridDeliveredEvent => {
    return SendgridDeliveredEventDefinition.is(e);
};

export const isSendgridDeferredEvent = (e: SendgridEvent): e is SendgridDeferredEvent => {
    return SendgridDeferredEventDefinition.is(e);
};

export const isSendgridBouncedEvent = (e: SendgridEvent): e is SendgridBouncedEvent => {
    return SendgridBouncedEventDefinition.is(e);
};

export const isSendgridBlockedEvent = (e: SendgridEvent): e is SendgridBlockedEvent => {
    return SendgridBlockedEventDefinition.is(e);
};

export const isSendgridSpamReportEvent = (e: SendgridEvent): e is SendgridSpamReportEvent => {
    return SendgridSpamReportEventDefinition.is(e);
};

export const isSendgridUnsubscribeEvent = (e: SendgridEvent): e is SendgridUnsubscribeEvent => {
    return SendgridUnsubscribeEventDefinition.is(e);
};

export const isSendgridGroupUnsubscribeEvent = (e: SendgridEvent): e is SendgridGroupUnsubscribeEvent => {
    return SendgridGroupUnsubscribeEventDefinition.is(e);
};

export const isSendgridGroupResubscribeEvent = (e: SendgridEvent): e is SendgridGroupResubscribeEvent => {
    return SendgridGroupResubscribeEventDefinition.is(e);
};