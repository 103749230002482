import * as React from 'react';
import classNames from 'classnames';
import { memoize, each, throttle } from 'lodash';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ORANGE_COLOR, RED_COLOR } from '../../../constants/colorVariables';

import {
    GatherCaseUX,
    VitalDates,
    DeathCertificateType,
    DeathCertificateUpdateRequest,
    DeathCertificateConfigUX,
    UserProfile,
    FeatureKey,
    UserRoles,
    EntitySummary,
    CaseTaskUX,
    TaskUpdateRequestUX,
    TaskTemplateType,
} from '../../../shared/types';

import { restingPlaceMasterValidators } from '../../../shared/death_certificate/validators/restingPlace';
import { aboutMasterValidators } from '../../../shared/death_certificate/validators/about';
import { lifeMasterValidators } from '../../../shared/death_certificate/validators/life';
import { parentsMasterValidators } from '../../../shared/death_certificate/validators/parents';
import { educationMasterValidators } from '../../../shared/death_certificate/validators/education';
import { marriageMasterValidators } from '../../../shared/death_certificate/validators/marriage';
import { workHistoryMasterValidators } from '../../../shared/death_certificate/validators/workHistory';
import { militaryMasterValidators } from '../../../shared/death_certificate/validators/military';
import { raceMasterValidators } from '../../../shared/death_certificate/validators/race';

import AboutForm from './forms/AboutForm';
import MarriageForm from './forms/MarriageForm';
import ParentForm from './forms/ParentForm';
import RestingPlaceForm from './forms/RestingPlaceForm';
import EducationForm from './forms/EducationForm';
import LifeForm from './forms/LifeForm';
import MilitaryForm from './forms/MilitaryForm';
import WorkHistoryForm from './forms/WorkHistoryForm';
import RaceForm from './forms/RaceForm';
import { WhenEnabled } from '../../common/AppFeature';
import ChangeViewGSwitch from '../ChangeViewGSwitch';
import TaskNoteTextField from '../../common/TaskNoteTextField';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import green from '@mui/material/colors/green';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import CreateIntercomTicketButton from '../../common/CreateIntercomTicketButton';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    topbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '0 auto 8px',
        '@media (min-width: 600px)': {
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: '0 48px'
        },
        '@media (min-width: 920px)': {
            padding: 0,
            maxWidth: 820,
        },
        '& $flexCentred': {
            marginTop: 8,
            '@media (min-width: 600px)': {
                marginTop: 0
            },
            '& button': {
                width: 28,
                height: 28
            }
        }
    },
    tabAppbar: {
        background: theme.palette.background.paper,
        boxShadow: 'none',
    },
    tabsFlexContainer: {
        '@media (min-width: 1400px)': {
            justifyContent: 'center',
        }
    },
    scrollButtons: {
        flex: 'none',
    },
    tabBadgeValidIcon: {
        color: green[600],
    },
    tabBadgeInvalidIcon: {
        color: RED_COLOR,
    },
    tabBadge: {
        padding: '0 10px',
        '&svg': {
            fontSize: 24,
        },
    },
    tabSelected: {
        '&$colorOrange': {
            color: `${ORANGE_COLOR} !important`,
        },
    },
    tabRoot: {
        textTransform: 'uppercase',
        minWidth: 140,
        opacity: 1,
        margin: '0 !important',
        height: 30,
        fontWeight: 500,
        fontSize: 14,
        '&:hover': {
            fontWeight: 500,
        },
    },
    tabsPlaceholder: {
        height: 49,
    },
    tabsShadow: {
        boxShadow: '0px 0px 6px 0px rgb(0 0 0 / 20%)',
    },
    interComBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px'
    }
});

type FormRefLookup = Record<keyof DeathCertificateType, React.RefObject<HTMLDivElement>>;

interface TabType {
    key: keyof DeathCertificateType;
    title: string;
}

const getTabs = memoize((caseFirstName: string): TabType[] => ([
    {
        key: 'about', title: `About ${caseFirstName}`,
    }, {
        key: 'life', title: 'Life',
    }, {
        key: 'restingPlace', title: 'Resting Place',
    }, {
        key: 'parents', title: 'Parents',
    }, {
        key: 'education', title: 'Education',
    }, {
        key: 'marriage', title: 'Marriage',
    }, {
        key: 'workHistory', title: 'Work History',
    }, {
        key: 'military', title: 'Military',
    }, {
        key: 'race', title: 'Race',
    },
]));

type TabsValidation = Record<keyof DeathCertificateType, { valid: boolean; touched: boolean }>;

export const getTabsValidation = (
    deathCertificate: DeathCertificateType,
    dcConfig: DeathCertificateConfigUX | null,
    selectedCase: GatherCaseUX,
    user: UserProfile,
    helpers: EntitySummary[],
): TabsValidation => ({
    about: {
        valid: aboutMasterValidators.valid(deathCertificate.about, dcConfig, selectedCase, user),
        touched: aboutMasterValidators.touched(deathCertificate.about, dcConfig, selectedCase, user),
    },
    life: {
        valid: lifeMasterValidators.valid(deathCertificate.life, dcConfig, selectedCase, user),
        touched: lifeMasterValidators.touched(deathCertificate.life, dcConfig, selectedCase, user),
    },
    restingPlace: {
        valid: restingPlaceMasterValidators.valid(deathCertificate.restingPlace, dcConfig, selectedCase, user),
        touched: restingPlaceMasterValidators.touched(deathCertificate.restingPlace, dcConfig, selectedCase, user),
    },
    parents: {
        valid: parentsMasterValidators.valid(deathCertificate.parents, dcConfig, selectedCase, user, helpers),
        touched: parentsMasterValidators.touched(deathCertificate.parents, dcConfig, selectedCase, user),
    },
    education: {
        valid: educationMasterValidators.valid(deathCertificate.education, dcConfig, user),
        touched: educationMasterValidators.touched(deathCertificate.education, dcConfig, user),
    },
    marriage: {
        valid: marriageMasterValidators.valid(deathCertificate.marriage, dcConfig, selectedCase, user, helpers),
        touched: marriageMasterValidators.touched(deathCertificate.marriage, dcConfig, selectedCase, user),
    },
    workHistory: {
        valid: workHistoryMasterValidators.valid(deathCertificate.workHistory, dcConfig, user),
        touched: workHistoryMasterValidators.touched(deathCertificate.workHistory, dcConfig, user),
    },
    military: {
        valid: militaryMasterValidators.valid(deathCertificate.military, dcConfig, selectedCase, user),
        touched: militaryMasterValidators.touched(deathCertificate.military, dcConfig, selectedCase, user),
    },
    race: {
        valid: raceMasterValidators.valid(deathCertificate.race, dcConfig, user),
        touched: raceMasterValidators.touched(deathCertificate.race, dcConfig, user),
    },
});

interface Props {
    selectedCase: GatherCaseUX;
    helpers: EntitySummary[];
    isEditMode: boolean;
    deathCertificate: DeathCertificateType;
    user: UserProfile;
    topOffset?: number;
    activeConfig: DeathCertificateConfigUX;
    dcTask: CaseTaskUX | null;
    canUserManageDD214OnCase: boolean;
    gotoApproval: () => void;
    updateVitalDates: (changes: VitalDates) => void;
    saveDeathCertificate: (updateRequest: DeathCertificateUpdateRequest) => void;
    handleOpenSnackbar: () => void;
    openAboutFormPopper: (event: React.MouseEvent<HTMLElement>) => void;
    updateTask: (
        taskId: number,
        taskChanges: TaskUpdateRequestUX,
        caseUuid: string,
        taskTemplate: TaskTemplateType | null
    ) => Promise<CaseTaskUX | null>;
}

interface State {
    selectedTab: keyof DeathCertificateType;
    stickyTabs: boolean;
    scrollingToForm: boolean;
}

type CombinedProps = Props & WithGStyles<'root' | 'topbar' | 'tabAppbar' | 'tabsFlexContainer' | 'scrollButtons'
    | 'tabBadgeValidIcon' | 'tabBadgeInvalidIcon' | 'tabBadge' | 'tabSelected' | 'tabRoot' | 'tabsPlaceholder'
    | 'tabsShadow' | 'interComBox'>;

class DeathCertificateQuestions extends React.Component<CombinedProps, State> {
    state: State = {
        selectedTab: 'about',
        stickyTabs: false,
        scrollingToForm: false,
    };

    private tabsRef: React.RefObject<HTMLDivElement> = React.createRef();
    private formRefs: FormRefLookup = {
        about: React.createRef(),
        life: React.createRef(),
        restingPlace: React.createRef(),
        parents: React.createRef(),
        education: React.createRef(),
        marriage: React.createRef(),
        workHistory: React.createRef(),
        military: React.createRef(),
        race: React.createRef(),
    };

    private scrollingToFormTimer: NodeJS.Timer | null = null;

    private throttledScrollListener = throttle(
        () => {
            const { topOffset } = this.props;
            const { stickyTabs, selectedTab, scrollingToForm } = this.state;

            if (!this.tabsRef.current) {
                return;
            }

            const viewportTop = topOffset || 0;

            const tabs = this.tabsRef.current.getBoundingClientRect();

            if (tabs.top < viewportTop && !stickyTabs) {
                this.setState({
                    stickyTabs: true,
                });
            } else if (tabs.top > viewportTop && stickyTabs) {
                this.setState({
                    stickyTabs: false,
                });
            }

            if (scrollingToForm) {
                return;
            }

            each(this.formRefs, (formRef, formKey) => {
                if (!formRef.current || !DeathCertificateType.isDeathCertificateKey(formKey)) {
                    return;
                }
                const crossOverPt = viewportTop + 80;
                const { top, bottom } = formRef.current.getBoundingClientRect();
                if (top < crossOverPt && bottom > crossOverPt && selectedTab !== formKey) {
                    this.setSelectedTab(formKey);
                }
            });
        },
        200,
    );

    componentDidMount() {
        this.addScrollListener();
    }

    componentWillUnmount() {
        this.removeScrollListener();
        this.throttledScrollListener.cancel();
        if (this.scrollingToFormTimer) {
            clearTimeout(this.scrollingToFormTimer);
        }
    }

    addScrollListener = () => {
        window.addEventListener('scroll', this.throttledScrollListener, { passive: true });
    };

    removeScrollListener = () => {
        window.removeEventListener('scroll', this.throttledScrollListener);
    };

    scrollToForm = (form: keyof DeathCertificateType) => {
        const { topOffset } = this.props;

        const formRef = this.formRefs[form];
        if (formRef && formRef.current) {
            this.setState({
                scrollingToForm: true,
            });

            const { top } = formRef.current.getBoundingClientRect();
            const formTop = top + window.pageYOffset - (topOffset || 0);
            window.scrollTo({ top: formTop, behavior: 'smooth' });

            this.setSelectedTab(form);
            this.scrollingToFormTimer = setTimeout(() => this.setState({ scrollingToForm: false }), 600);
        }
    };

    handleTabClick = (tab: keyof DeathCertificateType) => {

        this.setSelectedTab(tab);
        this.scrollToForm(tab);
    };

    setSelectedTab = (tab: keyof DeathCertificateType) => {
        this.setState({ selectedTab: tab });
    };

    handleDeathCertificateChange = (changes: Partial<DeathCertificateType>) => {
        const { deathCertificate, saveDeathCertificate } = this.props;
        const { selectedTab } = this.state;

        const updatedSections = Object.keys(changes);
        const inactiveUpdatedSection = updatedSections.find((section) => section !== selectedTab);

        if (inactiveUpdatedSection && DeathCertificateType.isDeathCertificateKey(inactiveUpdatedSection)) {
            this.setSelectedTab(inactiveUpdatedSection);
        }
        const updateRequest: DeathCertificateUpdateRequest = {
            death_certificate: {
                ...deathCertificate,
                ...changes,
            },
        };
        saveDeathCertificate(updateRequest);
    };

    render() {
        const {
            classes,
            selectedCase,
            dcTask,
            user,
            deathCertificate,
            isEditMode,
            helpers,
            topOffset,
            canUserManageDD214OnCase,
            saveDeathCertificate,
            updateVitalDates,
            activeConfig,
            openAboutFormPopper,
            handleOpenSnackbar,
            gotoApproval,
            updateTask
        } = this.props;
        const {
            stickyTabs,
            selectedTab,
        } = this.state;

        if (!user) {
            return null;
        }

        const label = `Notes about ${selectedCase.fname}`;
        const message = `${selectedCase.fname}'s Death Certificate is locked`;
        const tabList: TabType[] = getTabs(selectedCase.fname);
        const tabsValidation = getTabsValidation(deathCertificate, activeConfig, selectedCase, user, helpers);

        const isDeathCertificateLocked: boolean = selectedCase.death_certificate_locked;
        const isFHorGOMUser = UserRoles.isFHorGOMUser(user);

        return (
            <>
                <div
                    ref={this.tabsRef}
                    className={stickyTabs ? classes.tabsPlaceholder : undefined}
                />
                <AppBar
                    position={stickyTabs ? 'fixed' : 'static'}
                    className={classes.tabAppbar}
                    classes={{
                        positionFixed: classes.tabsShadow,
                    }}
                    style={stickyTabs && topOffset ? { top: topOffset } : undefined}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={(e, tab) => this.handleTabClick(tab)}
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        classes={{
                            flexContainer: classes.tabsFlexContainer,
                            scrollButtons: classNames(
                                isEditMode ? classes.colorOrange : classes.colorPrimary,
                                classes.scrollButtons
                            ),
                            indicator: isEditMode
                                ? classes.backgroundOrange
                                : classes.backgroundPrimary,
                        }}
                    >
                        {tabList.map((ele) => {
                            const validationTab = tabsValidation[ele.key];
                            let icon = <span />;
                            if (validationTab && validationTab.touched) {
                                if (validationTab.valid === true) {
                                    icon = <DoneIcon className={classes.tabBadgeValidIcon} />;
                                } else {
                                    icon = <CloseIcon className={classes.tabBadgeInvalidIcon} />;
                                }
                            }

                            return (
                                <Tab
                                    label={
                                        <Badge
                                            color="default"
                                            badgeContent={icon}
                                            className={classes.tabBadge}
                                        >
                                            {ele.title}
                                        </Badge>
                                    }
                                    value={ele.key}
                                    className={isEditMode ? classes.colorOrange : undefined}
                                    classes={{
                                        root: classes.tabRoot,
                                        selected: classNames(
                                            classes.tabSelected,
                                            isEditMode && classes.colorOrange,
                                        ),
                                        textColorPrimary: isEditMode ? classes.colorOrange : undefined
                                    }}
                                    key={ele.key}
                                />
                            );
                        })}
                    </Tabs>
                </AppBar>
                <Grid className={classes.marginTop50}>
                    {isFHorGOMUser && !isEditMode &&
                        <Grid item className={classes.topbar}>
                            <Grid item>
                                <ChangeViewGSwitch
                                    tooltipEnterDelay={200}
                                    tooltipTitle="Some questions may be hidden while in Family Safe Mode"
                                />
                            </Grid>
                            <WhenEnabled feature={FeatureKey.CONFIGURE_DEATH_CERTIFICATE}>
                                <Grid item className={classes.flexCentred}>
                                    <Typography color="primary">
                                        {activeConfig.name}
                                    </Typography>&nbsp;
                                    <IconButton onClick={event => openAboutFormPopper(event)} size="large">
                                        <MoreVertIcon
                                            color="primary"
                                            className={classes.cursorPointer}
                                        />
                                    </IconButton>
                                </Grid>
                            </WhenEnabled>
                        </Grid>
                    }
                    <AboutForm
                        active={selectedTab === 'about'}
                        activeCase={selectedCase}
                        data={deathCertificate.about}
                        onSaveData={(data) => this.handleDeathCertificateChange({ about: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.about}
                        zIndex={1320}
                    />
                    <LifeForm
                        active={selectedTab === 'life'}
                        zIndex={1320}
                        activeCase={selectedCase}
                        data={deathCertificate.life}
                        onSaveData={(data) => this.handleDeathCertificateChange({ life: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        updateVitalDates={updateVitalDates}
                        formRef={this.formRefs.life}
                    />
                    <RestingPlaceForm
                        active={selectedTab === 'restingPlace'}
                        caseFname={selectedCase.fname}
                        activeCase={selectedCase}
                        data={deathCertificate.restingPlace}
                        onSaveData={(data) => this.handleDeathCertificateChange({ restingPlace: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.restingPlace}
                    />
                    <ParentForm
                        active={selectedTab === 'parents'}
                        activeCase={selectedCase}
                        data={deathCertificate.parents}
                        onSaveData={(data) => this.handleDeathCertificateChange({ parents: data })}
                        dcConfig={activeConfig}
                        user={user}
                        onFatherChange={(father) => saveDeathCertificate({ dc_father: father })}
                        onMotherChange={(mother) => saveDeathCertificate({ dc_mother: mother })}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.parents}
                    />
                    <EducationForm
                        active={selectedTab === 'education'}
                        zIndex={1320}
                        caseFname={selectedCase.fname}
                        data={deathCertificate.education}
                        onSaveData={(data) => this.handleDeathCertificateChange({ education: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.education}
                    />
                    <MarriageForm
                        active={selectedTab === 'marriage'}
                        zIndex={1320}
                        activeCase={selectedCase}
                        onSpouseChange={(spouse) => saveDeathCertificate({ dc_spouse: spouse })}
                        data={deathCertificate.marriage}
                        onSaveData={(data) => this.handleDeathCertificateChange({ marriage: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.marriage}
                    />
                    <WorkHistoryForm
                        active={selectedTab === 'workHistory'}
                        caseFname={selectedCase.fname}
                        data={deathCertificate.workHistory}
                        onSaveData={(data) => this.handleDeathCertificateChange({ workHistory: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.workHistory}
                    />
                    <MilitaryForm
                        active={selectedTab === 'military'}
                        activeCase={selectedCase}
                        data={deathCertificate.military}
                        onSaveData={(data) => this.handleDeathCertificateChange({ military: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        canUserManageDD214OnCase={canUserManageDD214OnCase}
                        formRef={this.formRefs.military}
                    />
                    <RaceForm
                        active={selectedTab === 'race'}
                        zIndex={1320}
                        caseFname={selectedCase.fname}
                        data={deathCertificate.race}
                        onSaveData={(data) => this.handleDeathCertificateChange({ race: data })}
                        dcConfig={activeConfig}
                        user={user}
                        isEditMode={isEditMode}
                        isDeathCertificateLocked={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        formRef={this.formRefs.race}
                        gotoApproval={gotoApproval}
                    />
                    <TaskNoteTextField
                        selectedCase={selectedCase}
                        taskType={dcTask}
                        updateTask={updateTask}
                        label={label}
                        locked={selectedCase.death_certificate_locked}
                        snackbarMessage={message}
                    />
                </Grid>
                <Grid className={classes.interComBox}>
                    <CreateIntercomTicketButton
                        title='Missing a state-required DC field?'
                        subTitle='Click here so we can map it.'
                        intercomTarget='DeathCertificateQuestions-MissingRequiredDCField'
                    />
                </Grid>
            </>
        );
    }
}

export default withGStyles(styles)(DeathCertificateQuestions);
