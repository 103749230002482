import * as t from 'io-ts';
import { FuneralHomeTaskRecord, TaskRecord, WorkflowRecord } from '.';
import { getValidator } from './utils';

export enum TaskType {
    checklist_task = 'checklist_task',
    tracking_step = 'tracking_step',
}
const TASK_TYPE = t.union([
    t.literal(TaskType.checklist_task),
    t.literal(TaskType.tracking_step),
]);

export enum TrackingStepType {
    normal = 'normal',
    move = 'move',
    initialize = 'initialize',
    finalize = 'finalize',
}

export enum WorkOnButtonLink {
    inviteHelpers = 'inviteHelpers',
    scheduleServices = 'scheduleServices',
    deathCertificate = 'deathCertificate',
    completeObituary = 'completeObituary',
    freezeCredit = 'freezeCredit',
    closeSocialMedia = 'closeSocialMedia',
    contactSocialSecurity = 'contactSocialSecurity',
    cancelOnlineAccounts = 'cancelOnlineAccounts',
    cancelPhoneService = 'cancelPhoneService',
    forwardMail = 'forwardMail',
    unclaimedProperty = 'unclaimedProperty',
    lifeInsurance = 'lifeInsurance',
    specifyServiceDetails = 'specifyServiceDetails',
    specifyCasketBearers = 'specifyCasketBearers',
    goodsAndServices = 'goodsAndServices',
    signaturePacket = 'signaturePacket',
    liveStream = 'liveStream',
    profileAndCoverPhotos = 'profileAndCoverPhotos',
    exportToMessenger = 'exportToMessenger',
    funeralReimbursement = 'funeralReimbursement',
    photoSlideshow = 'photoSlideshow',
}

export enum TaskTemplateType {
    invite_helpers = 'invite_helpers',
    death_certificate = 'death_certificate',
    schedule_services = 'schedule_services',
    upload_photos = 'upload_photos',
    complete_obituary = 'complete_obituary',
    freeze_credit = 'freeze_credit',
    close_social_media = 'close_social_media',
    contact_social_security = 'contact_social_security',
    cancel_online_accounts = 'cancel_online_accounts',
    cancel_phone_service = 'cancel_phone_service',
    forward_mail = 'forward_mail',
    arrangement_conference = 'arrangement_conference',
    unclaimed_property = 'unclaimed_property',
    life_insurance = 'life_insurance',
    service_details = 'service_details',
    casket_bearers = 'casket_bearers',
    form_dd_214 = 'form_dd_214',
    flowers_cards = 'flowers_cards',
    goods_and_services = 'goods_and_services',
    signature_packet = 'signature_packet',
    live_stream = 'live_stream',
    profile_and_cover_photos = 'profile_and_cover_photos',
    export_to_messenger = 'export_to_messenger',
    funeral_reimbursement = 'funeral_reimbursement',
    build_photo_slideshow = 'build_photo_slideshow',
}

export const templateButtonLookup = {
    [TaskTemplateType.goods_and_services]: {
        text: 'VIEW STATEMENT',
        link: WorkOnButtonLink.goodsAndServices,
    },
    [TaskTemplateType.build_photo_slideshow]: {
        text: null,
        link: null,
    },
    [TaskTemplateType.profile_and_cover_photos]: {
        text: null,
        link: WorkOnButtonLink.profileAndCoverPhotos,
    },
    [TaskTemplateType.funeral_reimbursement]: {
        text: 'DO I QUALIFY',
        link: WorkOnButtonLink.funeralReimbursement,
    },
    [TaskTemplateType.export_to_messenger]: {
        text: 'Export Case Data',
        link: WorkOnButtonLink.exportToMessenger,
    },
    [TaskTemplateType.live_stream]: {
        text: 'Setup Live Stream',
        link: WorkOnButtonLink.liveStream,
    },
    [TaskTemplateType.casket_bearers]: {
        text: null,
        link: null,
    },
    [TaskTemplateType.upload_photos]: {
        text: null,
        link: null,
    },
    [TaskTemplateType.signature_packet]: {
        text: 'Create New Packet',
        link: WorkOnButtonLink.signaturePacket,
    },
    [TaskTemplateType.life_insurance]: {
        text: 'GET STARTED',
        link: WorkOnButtonLink.lifeInsurance,
    },
    [TaskTemplateType.unclaimed_property]: {
        text: 'GET STARTED',
        link: WorkOnButtonLink.unclaimedProperty,
    },
    [TaskTemplateType.forward_mail]: {
        text: 'GET STARTED',
        link: WorkOnButtonLink.forwardMail,
    },
    [TaskTemplateType.cancel_phone_service]: {
        text: 'GET STARTED',
        link: WorkOnButtonLink.cancelPhoneService,
    },
    [TaskTemplateType.cancel_online_accounts]: {
        text: 'GET STARTED',
        link: WorkOnButtonLink.cancelOnlineAccounts,
    },
    [TaskTemplateType.contact_social_security]: {
        text: 'GET STARTED',
        link: WorkOnButtonLink.contactSocialSecurity,
    },
    [TaskTemplateType.close_social_media]: {
        text: 'LEARN HOW',
        link: WorkOnButtonLink.closeSocialMedia,
    },
    [TaskTemplateType.freeze_credit]: {
        text: 'FREEZE CREDIT',
        link: WorkOnButtonLink.freezeCredit,
    },
    [TaskTemplateType.flowers_cards]: {
        text: null,
        link: null,
    },
    [TaskTemplateType.form_dd_214]: {
        text: null,
        link: null,
    },
    [TaskTemplateType.complete_obituary]: {
        text: 'WORK ON OBITUARY',
        link: WorkOnButtonLink.completeObituary,
    },
    [TaskTemplateType.service_details]: {
        text: 'SPECIFY SERVICE DETAILS',
        link: WorkOnButtonLink.specifyServiceDetails,
    },
    [TaskTemplateType.arrangement_conference]: {
        text: null,
        link: null,
    },
    [TaskTemplateType.invite_helpers]: {
        text: 'INVITE HELPERS',
        link: WorkOnButtonLink.inviteHelpers,
    },
    [TaskTemplateType.death_certificate]: {
        text: 'ANSWER QUESTIONS',
        link: WorkOnButtonLink.deathCertificate,
    },
    [TaskTemplateType.schedule_services]: {
        text: 'SCHEDULE SERVICES',
        link: WorkOnButtonLink.scheduleServices,
    },
};

export enum TrackingStepIcon {
    AddTask = 'AddTask',
    Check = 'Check',
    HealthAndSafety = 'HealthAndSafety',
    GppGood = 'GppGood',
    Edit = 'Edit',
    AssignmentTurnedIn = 'AssignmentTurnedIn',
    Settings = 'Settings',
    Add = 'Add',
    AddAPhoto = 'AddAPhoto',
    AirplanemodeActive = 'AirplanemodeActive',
    AddAlarm = 'AddAlarm',
    AttachFile = 'AttachFile',
    Water = 'Water',
    Bloodtype = 'Bloodtype',
    TempleBuddhist = 'TempleBuddhist',
    ArrowDownward = 'ArrowDownward',
    Event = 'Event',
    SaveAlt = 'SaveAlt',
    Grass = 'Grass',
    Addchart = 'Addchart',
    Backup = 'Backup',
    AddToQueue = 'AddToQueue',
    AcUnit = 'AcUnit',
    Fireplace = 'Fireplace',
    LocalFireDepartment = 'LocalFireDepartment',
    AssignmentLate = 'AssignmentLate',
    AssignmentInd = 'AssignmentInd',
    Delete = 'Delete',
    Diamond = 'Diamond',
    Coronavirus = 'Coronavirus',
    Checkroom = 'Checkroom',
    Accessibility = 'Accessibility',
    FileCopy = 'FileCopy',
    Fingerprint = 'Fingerprint',
    AssistantPhoto = 'AssistantPhoto',
    ContentCut = 'ContentCut',
    Cottage = 'Cottage',
    AllInbox = 'AllInbox',
    ColorLens = 'ColorLens',
    MilitaryTech = 'MilitaryTech',
    CurrencyExchange = 'CurrencyExchange',
    Mosque = 'Mosque',
    Campaign = 'Campaign',
    Nature = 'Nature',
    Favorite = 'Favorite',
    AccountCircle = 'AccountCircle',
    Attribution = 'Attribution',
    Boy = 'Boy',
    SettingsAccessibility = 'SettingsAccessibility',
    Soap = 'Soap',
    AirlineSeatFlat = 'AirlineSeatFlat',
    AirlineSeatFlatAngled = 'AirlineSeatFlatAngled',
    Print = 'Print',
    AutoStories = 'AutoStories',
    AddRoad = 'AddRoad',
    CleanHands = 'CleanHands',
    Build = 'Build',
    Synagogue = 'Synagogue',
    Groups = 'Groups',
    DeviceThermostat = 'DeviceThermostat',
    AirportShuttle = 'AirportShuttle',
}

export const isTrackingStepIcon = (icon: string | number): icon is TrackingStepIcon =>
    !!TrackingStepIcon[icon];

interface WorkflowUsingTaskTemplate {
    id: WorkflowRecord['id'];
    name: WorkflowRecord['name'];
    is_mirrored: boolean;
}

// ---> TaskTemplate <---
export interface TaskTemplateUXRecord extends Pick<TaskRecord,
    | 'id'
    | 'type'
    | 'icon'
    | 'title'
    | 'past_tense_title'
    | 'subtitle'
    | 'description'
    | 'template_type'
    | 'tracking_step_type'
    | 'feature'
    | 'can_complete'
    | 'can_skip'
    | 'visible_to_family'
    | 'can_reassign_by_family'
    | 'can_assign_multiple'
    | 'is_after_care'
    | 'created_by'
    | 'created_time'
    | 'updated_by'
    | 'updated_time'
    | 'from_task_id'
    | 'from_workflow_id'
>, Pick<FuneralHomeTaskRecord,
    | 'rank'
    | 'funeral_home_id'
> {
    component_count: number;
    workflows: WorkflowUsingTaskTemplate[];
}

export interface TaskTemplateUX extends TaskTemplateUXRecord { }

export interface TaskTemplateSummary extends Pick<TaskTemplateUX,
    | 'id'
    | 'type'
    | 'tracking_step_type'
    | 'icon'
    | 'title'
    | 'past_tense_title'
    | 'subtitle'
    | 'description'
    | 'visible_to_family'
    | 'is_after_care'
    | 'rank'
    | 'component_count'
    | 'workflows'
> {
}

// ---> TaskTemplateCreateRequest <---
const taskTemplateCreateOnly = {
    type: TASK_TYPE,
};

const taskTemplateCreateAndUpdate = {
    icon: t.union([t.string, t.null]),
    title: t.string,
    past_tense_title: t.union([t.string, t.null]),
    subtitle: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
    visible_to_family: t.boolean,
    is_after_care: t.boolean,
};

const TaskTemplateCreateRequestType = t.intersection([
    t.type(taskTemplateCreateAndUpdate),
    t.type(taskTemplateCreateOnly),
]);

export interface TaskTemplateCreateRequest extends t.TypeOf<typeof TaskTemplateCreateRequestType> {
    type: TaskType;
    icon: TrackingStepIcon | null;
}

export class TaskTemplateCreateRequest {
    public static fromRequest = getValidator<TaskTemplateCreateRequest>(TaskTemplateCreateRequestType);
}

// ---> TaskTemplateUpdateRequest <---
const TaskTemplateUpdateRequestType = t.partial(taskTemplateCreateAndUpdate);

export interface TaskTemplateUpdateRequest extends t.TypeOf<typeof TaskTemplateUpdateRequestType> {
    icon?: TrackingStepIcon | null;
}

export class TaskTemplateUpdateRequest {
    public static fromRequest = getValidator<TaskTemplateUpdateRequest>(TaskTemplateUpdateRequestType);
}

export const taskTemplateToSummary = (tt: TaskTemplateUX): TaskTemplateSummary => ({
    id: tt.id,
    type: tt.type,
    tracking_step_type: tt.tracking_step_type,
    icon: tt.icon,
    title: tt.title,
    past_tense_title: tt.past_tense_title,
    subtitle: tt.subtitle,
    description: tt.description,
    visible_to_family: tt.visible_to_family,
    is_after_care: tt.is_after_care,
    rank: tt.rank,
    component_count: tt.component_count,
    workflows: tt.workflows,
});
