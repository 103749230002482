
import IconButton from "@mui/material/IconButton";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import styled from "@mui/system/styled";
import GTooltip from "../../common/GTooltip";

const StyledIconButton = styled(IconButton)({
    width: 42,
    height: 42,
    opacity: 0.4
});

interface Props {
    data: string | null;
    checked: boolean;
    invited: boolean;
    fname: string;
    invitedTooltip: string;
    checkboxColor?: CheckboxProps['color'];
    onChange: (checked: boolean) => void;
    onAdd: () => void;
}
const ActionCheckbox = (props: Props) => {
    const { data, checked, invited, fname, invitedTooltip, checkboxColor, onChange, onAdd } = props;

    if (data) {
        return (
            <GTooltip
                enterDelay={400}
                title={data}
                disableInteractive
            >
                <Checkbox
                    color={checkboxColor}
                    checked={checked}
                    onChange={(evt, isChecked) => onChange(isChecked)}
                />
            </GTooltip>
        );
    }

    return (
        <GTooltip
            title={invited
                ? invitedTooltip
                : `${fname} has not yet been invited. Click here to invite.`
            }
        >
            <StyledIconButton onClick={onAdd}>
                <AddCircleIcon />
            </StyledIconButton>
        </GTooltip>
    );
};

export default ActionCheckbox;