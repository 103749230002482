import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';
import { normalizeTz, getTzLabel, emptyStringParser } from '../../../services';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import LocationOn from '@mui/icons-material/LocationOn';

import { APP_STYLED_PROPS } from '../../../styles/App.styles';

import { HELPER_STYLED_PROPS } from '../../../styles/Helper.styles';
import { LongAddress, GmapsSearchType, isLongAddress } from '../../../shared/types';
import GmapsSearch, { GmapsSearchAddress, GmapsTimezone } from '../../gmapsSearch/GmapsSearch';

import ChooseTimeZoneDialog from '../ChooseTimeZone.dialog';
import TextField from '@mui/material/TextField';
import withGStyles from '../../../styles/WithGStyles';
import { green } from '@mui/material/colors';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    container: {
        textAlign: 'center',
    },
    gmapsContainer: {
        marginBottom: 30,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    marginTop10: {
        marginTop: 10,
    },
    locationIcon: {
        fontSize: 70,
    },
    timezoneText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    addressContainer: {
        marginTop: 5,
    },
    tzContainer: {
        height: 32,
        marginTop: 12,
    },
    locationText: {
        fontSize: 16,
    },
    locationNameForm: {
        width: '100%',
        marginTop: 10,
        height: 68,
    },
    nameFormOuter: {
        maxWidth: 280,
        margin: 'auto',
    },
    nameInputLabel: {
        width: '100%',
        textAlign: 'left',
    },
});

export interface AddressWithTimezone {
    longAddress: LongAddress;
    timezone: string;
    locationName: string;
    useAddressDescription: boolean;
}

interface Props {
    controlClasses?: Object;
    controlSuggestionClasses?: Object;
    useAddressDescription: boolean;
    longAddress: LongAddress;
    timezone: string;
    zIndex: number;
    nameLabel?: string;
    nameValue: string;
    isSaveClicked?: boolean;
    isAutoFocus?: boolean;
    onLocationChange: (addrWithTimezone: AddressWithTimezone) => void;
    onNameChange?: (name: string) => void;
}

interface State {
    isEditTimezoneDialogOpen: boolean;
}

type StyledProps = Props & HELPER_STYLED_PROPS & APP_STYLED_PROPS &
    WithStyles<'root' | 'gmapsContainer' | 'buttonProgress' | 'timezoneText'
        | 'locationIcon' | 'addressContainer' | 'tzContainer' | 'dialogContent' | 'locationText' | 'container'
        | 'locationNameForm' | 'nameFormOuter' | 'nameInputLabel'
    >;

const INITIAL_STATE = {
    isEditTimezoneDialogOpen: false,
};

class AddLocationView extends React.Component<StyledProps, State> {

    constructor(props: StyledProps) {
        super(props);
        this.state = INITIAL_STATE;
    }

    openTimezoneDialog = () => {
        this.setState({
            isEditTimezoneDialogOpen: true,
        });
    };

    closeTimezoneDialog = () => {
        this.setState({
            isEditTimezoneDialogOpen: false,
        });
    };

    handleTimezoneReceived = (gmapTz: GmapsTimezone | null) => {
        const { onLocationChange, longAddress, nameValue, useAddressDescription } = this.props;
        onLocationChange({
            longAddress,
            timezone: normalizeTz(gmapTz ? gmapTz.timeZoneId : momentTz.tz.guess()),
            locationName: nameValue || longAddress.locationName || '',
            useAddressDescription,
        });
    };

    handlePlaceReceived = (longAddress: GmapsSearchAddress, useDescription: boolean) => {
        if (isLongAddress(longAddress)) {
            const { onLocationChange, timezone, nameValue } = this.props;
            onLocationChange({
                longAddress,
                timezone,
                locationName: nameValue || longAddress.locationName || '',
                useAddressDescription: useDescription,
            });
        }
    };

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onLocationChange, onNameChange, longAddress, timezone, useAddressDescription } = this.props;
        const locationName = event.target.value;
        onLocationChange({
            longAddress: {
                ...longAddress,
                isEstablishment: Boolean(emptyStringParser(locationName))
            },
            timezone,
            locationName,
            useAddressDescription,
        });
        if (onNameChange) {
            onNameChange(locationName);
        }
    };

    handleTimezoneChange = (timezone: string) => {
        const { onLocationChange, longAddress, nameValue, useAddressDescription } = this.props;
        onLocationChange({
            longAddress,
            timezone,
            locationName: nameValue || longAddress.locationName || '',
            useAddressDescription,
        });
    };

    renderLocationText = (text: string): JSX.Element => {
        const { classes } = this.props;
        return (
            <Typography
                color="primary"
                variant="h5"
                className={classes.locationText}
            >
                {text}
            </Typography >
        );
    };

    renderLocationView = () => {
        const {
            classes,
            longAddress,
            timezone,
            nameLabel,
            nameValue,
            zIndex,
            isSaveClicked,
        } = this.props;
        const { isEditTimezoneDialogOpen } = this.state;

        const name = nameValue || '';
        const isFHNameValid = isSaveClicked && name.trim().length === 0 ? false : true;
        return (
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                >
                    <LocationOn
                        color="primary"
                        className={classes.locationIcon}
                    />
                    <Grid
                        item
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            className={classes.addressContainer}
                        >
                            {name && this.renderLocationText(name)}
                            {longAddress.address1 &&
                                this.renderLocationText(longAddress.address1)
                            }
                            {longAddress.address2 &&
                                this.renderLocationText(longAddress.address2)
                            }
                            {longAddress.city && longAddress.state &&
                                this.renderLocationText(`${longAddress.city}, ${longAddress.state}`)
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                >
                    <Grid
                        className={classes.tzContainer}
                        item
                        xs={12}
                    >
                        <Typography
                            variant="caption"
                        >
                            {'This location will use'}&nbsp;
                            <span
                                className={classes.timezoneText}
                                onClick={this.openTimezoneDialog}
                            >
                                {getTzLabel(timezone)}
                            </span>
                        </Typography>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={classes.nameFormOuter}
                    >
                        <form
                            className={classes.overflowHidden}
                            autoComplete="off"
                            noValidate
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <FormControl
                                className={classes.locationNameForm}
                            >
                                <TextField
                                    label={nameLabel || 'Location Name'}
                                    id="locationName"
                                    name="locationName"
                                    value={name}
                                    className={classes.width100}
                                    onChange={this.handleNameChange}
                                    required
                                    error={!isFHNameValid}
                                />
                            </FormControl>
                        </form>
                    </Grid>
                    <ChooseTimeZoneDialog
                        isDialogOpen={isEditTimezoneDialogOpen}
                        closeDialog={this.closeTimezoneDialog}
                        tzSelected={timezone}
                        title={'Location Time Zone'}
                        onChange={this.handleTimezoneChange}
                        zIndex={zIndex + 1}
                    />
                </Grid>
            </>
        );
    };

    render() {
        const {
            classes,
            longAddress,
            controlClasses,
            controlSuggestionClasses,
            isSaveClicked,
            isAutoFocus,
            zIndex,
            useAddressDescription,
        } = this.props;

        const hasLocation = longAddress.city && longAddress.state ? true : false;
        const isClicked = isSaveClicked ? true : false;

        return (
            <Grid
                container
                className={classes.container}
            >
                <Grid
                    item
                    xs={12}
                    className={classes.gmapsContainer}
                >
                    <GmapsSearch
                        controlClasses={classNames(
                            controlClasses ? controlClasses : classes.width100
                        )}
                        controlSuggestionClasses={classNames(controlSuggestionClasses)}
                        textLabel={'Type location here...'}
                        type={GmapsSearchType.longAddress}
                        value={longAddress}
                        onSetPlace={this.handlePlaceReceived}
                        onTimezoneReceived={this.handleTimezoneReceived}
                        useDescription={useAddressDescription}
                        error={!hasLocation && isClicked}
                        required
                        isAutoFocus={isAutoFocus}
                        zIndex={zIndex + 2}
                    />
                </Grid>
                {hasLocation && this.renderLocationView()}
            </Grid>
        );
    }
}

export default withGStyles(styles)(AddLocationView);
