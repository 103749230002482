import { useMemo } from "react";
import { updateTask } from "../../../../../actions/task/Task.action";
import { GatherCaseUX, TrackingStepType, TaskUpdateRequestUX } from "../../../../../shared/types";
import { useGDispatch, useGSelector } from "../../../../../types";
import CompleteStep, { CompleteStepCase, Props as CompleteStepProps } from "./CompleteStep";

interface Props extends Omit<CompleteStepProps,
    'selectedCase' | 'prereqs' | 'availablePrereqs' | 'onUpdatePrereqs'
> {
    selectedCase: GatherCaseUX;
    prereqTaskIds: number[];
}

const CompleteStepDialogContent = (props: Props) => {
    const {
        activeStep,
        prereqTaskIds,
        selectedCase,
    } = props;

    const taskId = activeStep.id;

    const dispatch = useGDispatch();

    const trackingSteps = useGSelector(({ tasksState }) => tasksState.trackingSteps);

    const prereqs = useMemo(() =>
        trackingSteps.filter((step) => prereqTaskIds.some((id) => id === step.id))
            .map((task) => ({
                ...task,
                key: task.id,
            })),
        [trackingSteps, prereqTaskIds],
    );

    const caseWithFHName: CompleteStepCase = useMemo(() => ({
        ...selectedCase,
        funeral_home_name: selectedCase.funeral_home.name,
        funeral_home_key: selectedCase.funeral_home.key,
        funeral_home_address1: selectedCase.funeral_home.address1,
    }), [selectedCase]);

    const availablePrereqs = useMemo(() =>
        trackingSteps.filter((step) =>
            step.id !== taskId
            && step.tracking_step_type === TrackingStepType.normal
        ).map((step) => ({
            key: step.id,
            label: step.title,
        })),
        [trackingSteps, taskId],
    );

    const handleUpdateStep = (taskIds: number[]) => {

        const updateReq: TaskUpdateRequestUX = {
            prereq_task_ids: taskIds,
        };

        dispatch(updateTask(taskId, updateReq, selectedCase.uuid, null));
    };

    return (
        <>
            <CompleteStep
                {...props}
                selectedCase={caseWithFHName}
                prereqs={prereqs}
                availablePrereqs={availablePrereqs}
                onUpdatePrereqs={handleUpdateStep}
            />
        </>
    );
};

export default CompleteStepDialogContent;
