import { ActiveCaseState, ActiveCaseStatus } from '../types';

import { GatherAction } from '../actions';
import {
    CHECKED_FOR_CASE,
    CHECKING_FOR_CASE,
    LOADED_PRIVATE_CASE,
    LOADED_PUBLIC_CASE,
    LOADING_PRIVATE_CASE,
    LOADING_PUBLIC_CASE,
    LOAD_PRIVATE_CASE_FAILED,
    LOAD_PUBLIC_CASE_FAILED,
} from '../actions/GatherCase.action';
import { CLEAR_ACTIVE_FUNERALHOME, FUNERAL_HOME_LOADING } from '../actions/FuneralHome.action';
import { USER_LOGGED_OUT } from '../constants';

export const activeCaseInitData = {
    caseName: null,
    fhKeyWhereUserCanAccessCase: null,
    status: null,
};

export const activeCaseState = (
    state: ActiveCaseState = activeCaseInitData,
    action: GatherAction,
): ActiveCaseState => {

    switch (action.type) {
        case USER_LOGGED_OUT:
        case FUNERAL_HOME_LOADING:
        case CLEAR_ACTIVE_FUNERALHOME: {
            return activeCaseInitData;
        }
        case CHECKING_FOR_CASE:
        case LOADING_PRIVATE_CASE:
        case LOADING_PUBLIC_CASE: {
            return {
                ...state,
                caseName: action.caseName,
                status: ActiveCaseStatus.loading,
            };
        }
        case LOADED_PRIVATE_CASE:
            return {
                ...state,
                caseName: action.gatherCase.name,
                status: ActiveCaseStatus.loaded,
            };
        case LOADED_PUBLIC_CASE:
            return {
                ...state,
                caseName: action.publicCase.name,
                status: ActiveCaseStatus.loaded,
            };
        case CHECKED_FOR_CASE:
        case LOAD_PRIVATE_CASE_FAILED:
            return {
                ...state,
                caseName: action.caseName,
                fhKeyWhereUserCanAccessCase: action.fhKeyWithAccess,
                status: action.caseExists
                    ? ActiveCaseStatus.unauthorized
                    : ActiveCaseStatus.notFound,
            };
        case LOAD_PUBLIC_CASE_FAILED:
            return {
                ...state,
                caseName: action.caseName,
                status: ActiveCaseStatus.notFound,
            };
        default:
            return state;
    }
};
