import { CaseType, TaskComponentQuestionAnswer, GatherCaseSummary } from "../../../../../../shared/types";

export type OnValidityChangedFunction = (componentId: number, isValid: boolean) => void;
export type OnAnswerChangedFunction = (componentId: number, answer: TaskComponentQuestionAnswer) => void;

export const DUMMY_CASE: GatherCaseSummary = {
    name: 'john-doe',
    fname: 'John',
    lname: 'Doe',
    gender: 'Male',
    id: -1,
    funeral_home_case_id: -1,
    uuid: '',
    dob_date: new Date().toISOString(),
    dod_start_date: new Date().toISOString(),
    dc_residence: null,
    labels: [],
    created_time: new Date(),
    assignee: { fname: 'Assignee', lname: 'Name' },
    funeral_home_id: -1,
    owner_funeral_home_id: -1,
    funeral_home_name: '',
    photo: null,
    photo_transformations: null,
    case_number: null,
    case_type: CaseType.at_need,
    keeptrack_key: 'T12345',
    workflow_name: null,
    current_task_location: null,
    funeral_home_key: '',
    workflow_id: null,
    funeral_home_address1: '',
};
