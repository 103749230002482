import momentTz from 'moment-timezone';
import {
    CaseTaskUX,
    TasksComplete,
    UserProfile,
    UserRoles,
    EntitySummary,
    TaskState,
    TaskTemplateSummary,
} from '../types';
import { SupportedStringTemplateClasses, replaceStringTemplates } from '.';

export const replaceStringTemplatesInTask = <T extends CaseTaskUX | TaskTemplateSummary>(
    task: T,
    supportedTemplateClasses: SupportedStringTemplateClasses,
): T => {
    const filledTask: T = {
        ...task,
        title: replaceStringTemplates(task.title, supportedTemplateClasses),
        past_tense_title: task.past_tense_title
            ? replaceStringTemplates(task.past_tense_title, supportedTemplateClasses)
            : task.past_tense_title,
        description: task.description
            ? replaceStringTemplates(task.description, supportedTemplateClasses)
            : task.description,
        subtitle: task.subtitle
            ? replaceStringTemplates(task.subtitle, supportedTemplateClasses)
            : task.subtitle,
    };
    return filledTask;
};

export function getTasksCompleteValue(tasks: CaseTaskUX[]): TasksComplete {
    let d = tasks.length;
    let n = 0;

    for (let index = 0; index < tasks.length; index++) {
        const task = tasks[index];
        if (CaseTaskUX.isComplete(task)) {
            n += 1;
        } else if (CaseTaskUX.isSkipped(task)) {
            d -= 1;
        }

        if (task.can_skip === false && task.can_complete === false) {
            d -= 1;
        }
    }
    return {
        complete: n,
        remaining: d - n,
        pct: (d === 0) ? 0 : Math.round(((n / d) * 100)),
    };
}

export function filterUnassignedTasks(
    tasks: CaseTaskUX[],
    assignee: UserProfile | null,
    caseId: number
): CaseTaskUX[] {
    if (!UserRoles.isFHorGOMUser(assignee) && !UserRoles.isFamilyAdmin(assignee, caseId)) {
        return tasks.filter(task => (
            task.assigned_to_all
            || (assignee !== null && task.assigned_to.some(a => a.entity_id === assignee.entity_id))
        ));
    }
    return tasks;
}

interface TaskData {
    allTasks: CaseTaskUX[];
    familyTasks: CaseTaskUX[];
}

export function getAssigneeTaskList(
    taskData: TaskData,
    assignee: UserProfile,
    caseId: number
): CaseTaskUX[] {
    const { allTasks, familyTasks } = taskData;

    if (UserRoles.isFHorGOMUser(assignee)) {
        return allTasks;
    }

    if (UserRoles.isFamilyAdmin(assignee, caseId)) {
        return familyTasks;
    }

    return filterUnassignedTasks(familyTasks, assignee, caseId);
}

export interface HelperTask extends CaseTaskUX {
    assignedTo: {
        fname: string | null;
        time: string | null;
    };
}

export const getHelpersTasks = (params: {
    tasks: CaseTaskUX[];
    helper: EntitySummary;
    onlyIncompleteTasks?: boolean;
}): HelperTask[] => {
    const { tasks, helper, onlyIncompleteTasks } = params;

    return tasks.reduce(
        (acc: HelperTask[], task: CaseTaskUX) => {

            if (onlyIncompleteTasks && task.state !== TaskState.incomplete) {
                return acc;
            }

            if (task.assigned_to_all) {
                if (!UserRoles.isFHUser(helper) && !task.visible_to_family) {
                    // hide team tasks from family tasks
                    return acc;
                }
                const fname = task.assigned_to_all_by_fname;
                const time = task.assigned_to_all_time && momentTz(task.assigned_to_all_time).fromNow() || null;
                return [...acc, { ...task, assignedTo: { fname, time } }];
            }

            const assignedTo = helper.user_id && task.assigned_to.find((ta) => ta.user_id === helper.user_id);

            if (assignedTo) {
                const fname = assignedTo.assigned_by_fname;
                const time = assignedTo.assigned_time && momentTz(assignedTo.assigned_time).fromNow() || null;
                return [...acc, { ...task, assignedTo: { fname, time } }];
            }
            return acc;
        },
        []
    );
};
