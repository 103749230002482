import { EntitySummary, UserProfile } from '../../../shared/types';
import BaseAssignForEntitySummary, {
    Props as BaseAssignForEntitySummaryProps,
    CaseForBaseAssign,
    EntitySummaryForBaseAssign,
} from '../baseAssign/BaseAssignForEntitySummary';
import { canInviteOtherTeamMembers } from '../../../shared/authority/can';
import { TOOLTIPS } from '../../../constants';
import { useMemo } from 'react';

export interface PickTeamMemberPureProps
    extends Pick<
        BaseAssignForEntitySummaryProps,
        | 'assignedTo'
        | 'isMultiSelect'
        | 'anchorEl'
        | 'zIndex'
        | 'gatherCase'
        | 'onSearch'
        | 'alwaysShowSearch'
        | 'isLoading'
        | 'headerText'
        | 'anchorReference'
    > {
    onAssign: (member: EntitySummary | null, members?: EntitySummary[]) => void;
    onInvite?: (zIndex: number) => void;
    handleClose: () => void;
    team: EntitySummaryForBaseAssign[];
    funeralHomeId: number | null;
    gatherCase: CaseForBaseAssign | null;
    userData: UserProfile | null;
    assignedEntityIds?: number[];
}

// this uses zero Redux. If you want a easy-mode version based on current global state use PickTeamMember
const PickTeamMemberPure = (props: PickTeamMemberPureProps) => {
    const { onAssign, onInvite, team, funeralHomeId, userData, assignedEntityIds, ...restProps } = props;

    const inviteTeamMemberAllowed = canInviteOtherTeamMembers(userData, funeralHomeId || null);

    const assignedTo = useMemo(
        () => (assignedEntityIds ? team.filter((e) => assignedEntityIds.includes(e.entity_id)) : undefined),
        [team, assignedEntityIds],
    );

    return (
        <BaseAssignForEntitySummary
            {...restProps}
            assignedTo={assignedTo}
            items={team}
            onSelect={(_, teamMember, teamMembers) => onAssign(teamMember, teamMembers)}
            onAdd={onInvite}
            addButtonText={!onInvite ? undefined : 'Invite Team Member'}
            addButtonDisabled={!inviteTeamMemberAllowed}
            addButtonDisabledToolTip={TOOLTIPS.DISABLED_FEATURE}
            showFamilyHelpers={false}
            showTeamMembers={true}
        />
    );
};

export default PickTeamMemberPure;
