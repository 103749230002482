import * as React from 'react';

import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import ReportIcon from '@mui/icons-material/Report';

import { WARNING_COLOR } from '../../../constants/colorVariables';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';

interface Props {
    popperAnchorEle: HTMLElement | null;
    closePopper: () => void;
    zIndex: number;
    caseName: string;
    handleShowImage: () => void;
}

interface State {
    arrowRef: HTMLElement | null;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    arrow: {
        width: 0,
        height: 0,
        position: 'absolute',
        border: '20px solid transparent',
        overflow: 'hidden',
        boxShadow: '0 16px 10px -14px rgba(0, 0, 0, 0.5)',
        left: -14
    },
    arrowOuter: {
        background: 'transparent',
        width: 0,
        height: 0,
        position: 'absolute',
    },
    popper: {
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: `20px solid ${WARNING_COLOR}`,
            borderTop: 0,
            top: '-20px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: `20px solid ${WARNING_COLOR}`,
            borderBottom: 0,
            bottom: '-20px',
        },
    },
    showImagePopperContainer: {
        width: 280,
        boxShadow: theme.shadows[5],
    },
    paperContent: {
        textAlign: 'center',
        borderRadius: 6,
        minHeight: 100,
        padding: '12px 8px',
        background: WARNING_COLOR,
        '& p': {
            color: theme.palette.common.white,
        },
        '& button': {
            marginTop: 8,
            backgroundColor: theme.palette.common.white,
            color: WARNING_COLOR,
            marginBottom: 4,
            padding: '2px 16px 2px 8px',
            fontSize: 18,
            '& svg': {
                fontSize: 32,
                marginRight: 8,
            },
            '&:hover': {
                backgroundColor: theme.palette.common.white,
            },
        },
    },
    dummyArrow: {}
});

type StyledProps = WithGStyles<'root' | 'popper' | 'showImagePopperContainer'
    | 'arrow' | 'arrowOuter' | 'dummyArrow' | 'paperContent'
>;

class ShowImagePopper extends React.Component<Props & StyledProps, State> {

    constructor(props: Props & StyledProps) {
        super(props);
        this.state = {
            arrowRef: null,
        };
    }

    render() {
        const {
            classes,
            popperAnchorEle,
            zIndex,
            closePopper,
            caseName,
            handleShowImage,
        } = this.props;

        const { arrowRef } = this.state;

        return (
            <Popper
                open={Boolean(popperAnchorEle)}
                anchorEl={popperAnchorEle || null}
                transition
                placement="top"
                className={classes.popper}
                modifiers={[
                    { name: 'flip', enabled: true },
                    {
                        name: 'arrow',
                        options: {
                            enabled: true,
                            element: arrowRef
                        }
                    },
                ]}
                style={{
                    display: popperAnchorEle ? 'block' : 'none',
                    zIndex
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={e => closePopper()}>
                        <Grow {...TransitionProps}>
                            <Paper className={classes.showImagePopperContainer}>
                                <span
                                    className={classes.arrow}
                                    ref={this.handleArrowRef}
                                />
                                <div className={classes.paperContent}>
                                    <Typography className={classes.fontSize20}>
                                        Are you sure?
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        onClick={() => handleShowImage()}>
                                        <ReportIcon /> View Image of {caseName}
                                    </Button>

                                    <Typography>
                                        Image will display for 4 seconds
                                    </Typography>
                                </div>
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        );
    }

    handleArrowRef = (node: HTMLSpanElement | null) => {
        this.setState({ arrowRef: node });
    };
}

export default withGStyles(styles)(ShowImagePopper);
