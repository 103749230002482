import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import makeGStyles from "../../../styles/makeGStyles";
import { SlideTransition } from "../../common/Transitions";
import Grid from "@mui/material/Grid";
import SwipeableViews from "react-swipeable-views";
import TaskDialogInitialState, { InitialStepListItem } from "./TaskDialogInitialState";
import { TaskDialogComponentProps } from "./TaskDialog";
import { isDemoMode, openAccessRestrictedDialogState } from "../../../actions/AccessRestricted.action";
import { useGDispatch } from "../../../types";
import useFullScreen from "../../common/hooks/useFullScreen";

const useStyles = makeGStyles(theme => ({
    clearIcon: {
        position: 'absolute',
        top: 18,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        },
    },
    dialogPaper: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 720,
            width: 720,
        },
    },
    dialogTitle: {
        padding: 14,
        zIndex: 1,
        paddingBottom: 0,
        height: 'auto',
        '& button': {
            width: 36,
            height: 36,
            '@media (min-width: 400px)': {
                width: 48,
                height: 48,
            },
            '& svg': {
                fontSize: 26,
                '@media (min-width: 400px)': {
                    fontSize: 32,
                },
            },
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: '0 14px',
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    },
    slideClassName: {
        overflow: 'hidden !important',
        overflowY: 'auto !important' as 'auto',
    }
}), { name: 'BaseTaskDialog' });

interface InitialStepData {
    heading: string;
    description: JSX.Element;
    listItems: InitialStepListItem[];
}

interface Props extends TaskDialogComponentProps {
    initialStepData: InitialStepData;
}
const BaseTaskDialog = (props: Props) => {
    const {
        isDialogOpen,
        zIndex,
        initialStepData,
        closeDialog,
        ...taskDialogInitialStateProps
    } = props;

    const classes = useStyles();
    const fullScreen = useFullScreen('md');
    const dispatch = useGDispatch();

    const containerRef = useRef<HTMLDivElement>(null);
    const [isInitialStep, setIsInitialStep] = useState(true);
    const [mainContentStep, setMainContentStep] = useState(-1);
    const [boxShadowVisible, setBoxShadowVisible] = useState(false);

    useEffect(() => {
        containerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });
    }, [isInitialStep]);

    const handleContentScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
        const scrollTop = event.currentTarget.scrollTop;

        if (scrollTop > 10 && !boxShadowVisible) {
            setBoxShadowVisible(true);
        } else if (scrollTop <= 10 && boxShadowVisible) {
            setBoxShadowVisible(false);
        }
    };

    const handleClose = () => {
        setIsInitialStep(true);
        setMainContentStep(-1);
        closeDialog();
    };

    const backToInitialState = () => {
        setIsInitialStep(true);
        setTimeout(() => setMainContentStep(-1), 300);
    };

    const handleItemClick = (index: number) => {
        if (isDemoMode(
            taskDialogInitialStateProps.activeFuneralHome,
            taskDialogInitialStateProps.activeCase
        )) {
            dispatch(openAccessRestrictedDialogState(zIndex + 2));
            return;
        }

        setIsInitialStep(false);
        setMainContentStep(index);
    };

    const renderHeader = () => {
        if (isInitialStep) {
            return (
                <Typography
                    color="secondary"
                    component="p"
                    className={classes.fontSize20}
                    align="left"
                >
                    <span className={classes.textCapitalize}>{initialStepData.heading}</span>
                </Typography>
            );
        }

        return (
            <IconButton color="primary" onClick={backToInitialState} size="large">
                <ArrowBackIcon color="primary" />
            </IconButton>
        );
    };

    const renderInitialState = () => {
        return (
            <TaskDialogInitialState
                {...taskDialogInitialStateProps}
                zIndex={zIndex + 1}
                listItems={initialStepData.listItems}
                active={isInitialStep}
                onItemClick={handleItemClick}
            >
                {initialStepData.description}
            </TaskDialogInitialState>
        );
    };

    const renderStepContent = () => {
        const content = initialStepData.listItems[mainContentStep]?.content;

        return (
            <Grid item xs={12} sx={{ height: isInitialStep ? 0 : 'auto' }}>
                {content}
            </Grid>
        );
    };

    return (
        <Dialog
            open={isDialogOpen}
            transitionDuration={300}
            TransitionComponent={SlideTransition}
            fullScreen={fullScreen}
            sx={{ zIndex }}
            classes={{ paper: classes.dialogPaper }}
            onClose={handleClose}
        >
            <DialogTitle
                className={classes.dialogTitle}
                sx={{ boxShadow: theme => boxShadowVisible ? theme.shadows[4] : undefined }}
            >
                <ClearIcon
                    className={classNames(classes.clearIcon, classes.colorSecondary)}
                    onClick={handleClose}
                />

                {renderHeader()}
            </DialogTitle>

            <DialogContent className={classes.dialogContent} onScroll={handleContentScroll}>
                <div ref={containerRef} />

                <Grid container>
                    <SwipeableViews
                        axis="x"
                        index={isInitialStep ? 0 : 1}
                        disabled
                        slideClassName={classes.slideClassName}
                    >
                        {[0, 1].map(step => (
                            <div key={step}>
                                {step === 0
                                    ? renderInitialState()
                                    : renderStepContent()
                                }
                            </div>
                        ))}
                    </SwipeableViews>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default BaseTaskDialog;