import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import ClearIcon from '@mui/icons-material/Clear';

import { toPennyFormat, toPennyValue } from '../../../../shared/goods_and_services/pricing';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            width: 300,
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            '@media (min-width: 400px)': {
                width: 360
            },
        },
    },
    clearIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        background: theme.palette.primary.main,
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '24px !important',
        margin: ' auto',
        textAlign: 'center',
        overflow: 'hidden'
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        color: theme.palette.common.white,
    },
    textAreaMinHeight: {
        minHeight: 80
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            margin: '8px 0'
        }
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    textField: {
        width: 120,
        marginBottom: 20,
        '& $inputUnderline': {
            '&:hover:before': {
                color: 'inherit',
                borderBottom: '2px solid !important',
            },
            '&:before': {
                color: 'inherit',
                borderBottom: '1px solid',
            },
            '&:after': {
                color: 'inherit',
                borderBottom: '2px solid',
            }
        },
        '& $inputRoot': {
            color: 'inherit',
        },
        '& $input': {
            fontSize: 26,
            fontWeight: 200,
            padding: 0,
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
        '& $helperText': {
            textAlign: 'center',
            fontSize: 10,
            marginTop: 4,
            fontWeight: 200,
            color: 'inherit'
        }
    },
    manualPriceContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '4px auto 0',
    },
    helperText: {},
    input: {},
    inputRoot: {},
    inputUnderline: {},
    dialogPaper: {},
});

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    isDialogOpen: boolean;
    productName: string;
    initialPrice?: number;
    closeDialog: () => void;
    onSpecifyPrice: (price: number) => void;
    zIndex: number;
}

interface State {
    price: string;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent' | 'textField'
    | 'clearIcon' | 'headerText' | 'textAreaMinHeight' | 'footer' | 'spaceBetween'
    | 'input' | 'inputRoot' | 'inputUnderline' | 'helperText' | 'textField' | 'manualPriceContainer'
>;

type CombinedProps = StyledProps & DialogProps;

class SpecifyPriceDialog extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            price: this.props.initialPrice !== undefined ? (this.props.initialPrice / 100).toString() : '',
        };
    }

    render() {
        const {
            classes,
            isDialogOpen,
            productName,
            zIndex
        } = this.props;

        const { price } = this.state;

        return (
            <Dialog
                open={isDialogOpen}
                onClose={this.handleCancel}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.root,
                }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.header}
                >
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={this.handleCancel}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classes.headerText}
                    >
                        Specify Price
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                    >
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize18}
                            >
                                Specify the price for&nbsp;
                                <span className={classes.fontWeight500}>{productName}</span>
                            </Typography>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.manualPriceContainer}
                                >
                                    <TextField
                                        type="number"
                                        value={price || ''}
                                        className={classes.textField}
                                        InputProps={{
                                            classes: {
                                                input: classes.input,
                                                root: classes.inputRoot,
                                                underline: classes.inputUnderline
                                            },
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    className={classes.colorSecondary}
                                                >
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: classes.helperText
                                            }
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handlePriceChange}
                                    />
                                </Grid>
                            </Grid>

                            <div
                                className={classNames(
                                    classes.footer,
                                    classes.spaceBetween,
                                )}
                            >
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.margin_8}
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.margin_8}
                                    onClick={this.handlePriceSave}
                                >
                                    Specify Price
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    handlePriceSave = async () => {
        const { price } = this.state;
        const { onSpecifyPrice, closeDialog } = this.props;
        if (price === null) {
            return;
        }
        onSpecifyPrice(toPennyValue(price).getAmount());
        closeDialog();
    };

    handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({
            price: toPennyFormat(event.target.value),
        });
    };

    handleCancel = () => {
        const { initialPrice, closeDialog } = this.props;

        closeDialog();

        this.setState({
            price: initialPrice !== undefined ? (initialPrice / 100).toString() : ''
        });
    };

}

export default compose(
    withGStyles(styles),
    withFullScreen('xs')
)(SpecifyPriceDialog) as React.ComponentType<Props>;
