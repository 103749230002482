import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { SKYBLUE_COLOR } from '../../constants/colorVariables';
import { EntityCaseRole } from '../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import GPopper from '../common/GPopper';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '14px !important'
        },
    },
    popoverPaper: {
        borderRadius: 6,
        WebkitBorderRadius: 6,
        MozBorderRadius: 6,
        boxShadow: theme.shadows[10],
        minWidth: 300,
        '@media (min-width: 360px)': {
            minWidth: 320,
            width: 'min-content'
        },
    },
    popperContent: {
        textAlign: 'center',
        padding: '0px 16px',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    tabAppbar: {
        boxShadow: 'none',
        background: '#fff',
    },
    tabsRoot: {
        overflowX: 'auto',
        marginTop: 20,
        '@media (min-width: 420px)': {
            marginTop: 0,
        }
    },
    tabsScrollableContainer: {
        overflow: 'auto',
        overflowX: 'auto',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 100,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 1,
        margin: '0 4px',
        height: 30,
        fontSize: 14,
    },
    labelContainer: {
        display: 'flex',
        padding: '6px 6px'
    },
    tabIndicator: {
        bottom: '6px !important'
    },
    mainContainer: {
        padding: '0 6px'
    },
    adminContentContiner: {
        margin: '8px 0'
    },
    guestContentContiner: {
        margin: '8px 0'
    },
    divider: {
        width: '100%',
        margin: '8px 0',
        background: theme.palette.primary.main
    },
    heading: {
        fontSize: 15,
        padding: '0 4px',
        lineHeight: '1.25em'
    },
    listContainer: {
        padding: '0 8px',
        paddingBottom: 12
    },
    itemManaged: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 4,
        whiteSpace: 'nowrap',
        '& svg': {
            fontSize: 18
        }
    },
    svgDot: {
        color: SKYBLUE_COLOR,
        fontSize: 30,
        position: 'absolute',
        top: '-12px',
        left: '-12px',
    },
    opacity50: {
        opacity: 0.5,
    },
});

type StyledProps = Props & WithStyles<'root' | 'popper' | 'popoverPaper' | 'popperContent'
    | 'tabAppbar' | 'tabsRoot' | 'tabsScrollableContainer' | 'tabRoot' | 'labelContainer' | 'tabIndicator'
    | 'mainContainer' | 'adminContentContiner' | 'guestContentContiner' | 'divider' | 'heading'
    | 'listContainer' | 'itemManaged' | 'svgDot' | 'opacity50'>;

interface Props {
    zIndex: number;
    popperAnchorEle: HTMLElement | SVGElement | null;
    caseFName: string;
    infoPopperType: EntityCaseRole | null;
    clickAwayListener: (event: MouseEvent | TouchEvent) => void;
}

type AdminManagedTasks = {
    id: number;
    hasAccess?: boolean;
    taskName: string;
    canViewAssignedTasks?: boolean;
};

type GuestManagedTasks = {
    id: number;
    hasAccess?: boolean;
    taskName: string;
    canViewAssignedTasks?: boolean;
};

interface State {
    activeTab: EntityCaseRole;
}

const ADMIN_MANAGED_TASK_LIST: AdminManagedTasks[] = [{
    id: 1,
    hasAccess: true,
    taskName: 'Can view ALL family tasks'
}, {
    id: 2,
    hasAccess: true,
    taskName: 'Can assign tasks to others'
}, {
    id: 3,
    hasAccess: true,
    taskName: 'Can view Helpers Report'
}, {
    id: 4,
    hasAccess: true,
    taskName: 'Can view "Remember" page'
}, {
    id: 5,
    hasAccess: true,
    taskName: 'Can configure "Remember" page'
}, {
    id: 6,
    hasAccess: true,
    taskName: 'Can view private events'
}, {
    id: 7,
    hasAccess: true,
    taskName: 'Can view and upload photos'
}, {
    id: 8,
    hasAccess: true,
    taskName: 'Can invite other GUESTS'
}, {
    id: 9,
    hasAccess: false,
    taskName: 'Cannot invite other ADMINS'
}];

const GUEST_MANAGED_TASK_LIST: GuestManagedTasks[] = [{
    id: 1,
    hasAccess: true,
    taskName: 'Can view ASSIGNED tasks only',
    canViewAssignedTasks: true
}, {
    id: 2,
    hasAccess: false,
    taskName: 'Cannot assign tasks to others',
}, {
    id: 3,
    hasAccess: false,
    taskName: 'Cannot view Helpers Report',
}, {
    id: 4,
    hasAccess: true,
    taskName: 'Can view "Remember" page',
}, {
    id: 5,
    hasAccess: false,
    taskName: 'Cannot configure "Remember" page',
}, {
    id: 6,
    hasAccess: true,
    taskName: 'Can view private events',
}, {
    id: 7,
    hasAccess: true,
    taskName: 'Can view and upload photos',
}, {
    id: 8,
    hasAccess: true,
    taskName: 'Can invite other GUESTS',
}, {
    id: 9,
    hasAccess: false,
    taskName: 'Cannot invite other ADMINS'
}];

class InfoPopper extends React.Component<StyledProps, State> {
    state: State = {
        activeTab: EntityCaseRole.admin,
    };

    componentDidUpdate(prevProps: Props) {
        const { infoPopperType } = this.props;
        if (infoPopperType !== prevProps.infoPopperType && infoPopperType) {
            this.setState({ activeTab: infoPopperType });
        }
    }

    handleTabChangeEvent = (event: React.FormEvent<{}>, activeTab: unknown) => {
        if (typeof activeTab === 'string' && EntityCaseRole[activeTab]) {
            this.setState({ activeTab: EntityCaseRole[activeTab] });
        }
    };

    renderContent = () => {
        const { classes, caseFName } = this.props;
        const { activeTab } = this.state;

        return (
            <Grid item xs={12} className={classes.mainContainer}>
                {activeTab === EntityCaseRole.admin &&
                    <Grid item xs={12} className={classes.adminContentContiner}>
                        <Typography align="center" color="secondary" className={classes.heading}>
                            An <span className={GStyles.fontWeight500}>ADMIN</span> is a trusted friend or family
                            &nbsp;who can help manage {caseFName}'s profile.
                        </Typography>

                        <Divider color="primary" className={classes.divider} />

                        <Grid item xs={12} className={classes.listContainer}>
                            {ADMIN_MANAGED_TASK_LIST.map((item) => (
                                <Typography
                                    key={item.id}
                                    align="left"
                                    color="secondary"
                                    className={classes.itemManaged}
                                >
                                    {item.hasAccess
                                        ? <CheckCircleIcon className={GStyles.colorGreen} />
                                        : <CancelIcon className={GStyles.colorRed} />
                                    }
                                    &nbsp;<span className={classNames(!item.hasAccess && classes.opacity50)}>
                                        {item.taskName}
                                    </span>
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                }

                {activeTab === EntityCaseRole.guest &&
                    <Grid item xs={12} className={classes.guestContentContiner}>
                        <Typography align="center" color="secondary" className={classes.heading}>
                            A <span className={GStyles.fontWeight500}>GUEST</span> is anyone who knew or wants to
                            &nbsp;remember {caseFName}.
                        </Typography>

                        <Divider color="primary" className={classes.divider} />

                        <Grid item xs={12} className={classes.listContainer}>
                            {GUEST_MANAGED_TASK_LIST.map((item) => (
                                <Typography
                                    key={item.id}
                                    align="left"
                                    color="secondary"
                                    className={classes.itemManaged}
                                >
                                    {item.canViewAssignedTasks
                                        ? <CheckCircleIcon className={GStyles.colorWarning} />
                                        : item.hasAccess
                                            ? <CheckCircleIcon className={GStyles.colorGreen} />
                                            : <CancelIcon className={GStyles.colorRed} />
                                    }
                                    &nbsp;<span className={classNames(!item.hasAccess && classes.opacity50)}>
                                        {item.taskName}
                                    </span>
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    };

    render() {
        const {
            classes,
            popperAnchorEle,
            clickAwayListener,
            zIndex,
        } = this.props;
        const { activeTab } = this.state;

        return (
            <GPopper
                anchorEle={popperAnchorEle}
                className={classes.popper}
                paperClass={classes.popoverPaper}
                zIndex={zIndex}
                closePopper={clickAwayListener}
                interactive
            >
                {activeTab === EntityCaseRole.admin &&
                    <FiberManualRecordIcon className={classes.svgDot} />}

                <div className={classes.popperContent}>
                    <Grid
                        item
                        xs={12}
                        className={GStyles.textCenter}
                    >
                        <AppBar position="static" className={classes.tabAppbar}>
                            <Tabs
                                value={activeTab}
                                onChange={this.handleTabChangeEvent}
                                classes={{
                                    root: classes.tabsRoot,
                                    scrollableX: classes.tabsScrollableContainer,
                                    indicator: classes.tabIndicator
                                }}
                                variant="scrollable"
                                scrollButtons={false}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab
                                    label={'What is an ADMIN?'}
                                    value={EntityCaseRole.admin}
                                    classes={{
                                        root: classNames(classes.tabRoot, classes.labelContainer),
                                    }}
                                />
                                <Tab
                                    label={'What is a GUEST?'}
                                    value={EntityCaseRole.guest}
                                    classes={{
                                        root: classNames(classes.tabRoot, classes.labelContainer),
                                    }}
                                />
                            </Tabs>
                        </AppBar>

                        {this.renderContent()}
                    </Grid>
                </div>
            </GPopper>
        );
    }
}

export default withStyles(styles)(InfoPopper);
