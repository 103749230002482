import classNames from "classnames";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import makeGStyles from "../../../../../styles/makeGStyles";
import { ORANGE_COLOR } from "../../../../../constants/colorVariables";
import { TrackingContentContext } from "./CompleteStep.dialog";

const useStyles = makeGStyles<{ isFixedWidth: boolean; isFamilyPageContext: boolean }>({
    placeholderContainer: {
        border: `2px dashed ${ORANGE_COLOR}`,
        borderRadius: 20,
        aspectRatio: '1',
        marginTop: props => props.isFamilyPageContext ? undefined : 26,
        width: props => props.isFixedWidth
            ? props.isFamilyPageContext
                ? 160
                : 180
            : undefined,
        height: props => props.isFixedWidth
            ? props.isFamilyPageContext
                ? 160
                : 180
            : undefined,
    }
});

interface Props {
    context: TrackingContentContext;
}

function CaseBelongingPlaceholder(props: Props) {
    const isFamilyPageContext = props.context === TrackingContentContext.familyPage;
    const isFixedWidth = props.context === TrackingContentContext.dialog
        || isFamilyPageContext;
    const classes = useStyles({ isFixedWidth, isFamilyPageContext });

    return (
        <Grid
            item
            xs={12}
            justifyContent="center"
            className={
                classNames(classes.placeholderContainer, classes.width100, classes.padding10, classes.flexCentred)
            }
        >
            <CircularProgress
                color="primary"
                size={86}
                thickness={3}
            />
        </Grid>
    );
}

export default CaseBelongingPlaceholder;
