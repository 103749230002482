import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { SvgIconComponent } from '@mui/icons-material';
import { getIntercomTargetProp } from '../../services';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { GStyles } from '../../styles/GStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderRadius: 12,
        border: `2px solid ${theme.palette.primary.main}`
    },
    heading: {
        background: theme.palette.common.white,
        padding: '0 8px',
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%, -50%)',
    },
    widthMaxContent: {
        width: 'max-content',
    },
}), { name: 'BorderedSection' });

interface Props {
    heading?: string | SvgIconComponent;
    classes?: { root?: string; heading?: string };
    intercomTargetProp?: string;
    className?: string;
}
const BorderedSection = (props: PropsWithChildren<Props>) => {
    const { heading, children, classes, intercomTargetProp, className } = props;

    const componentClasses = useStyles();

    let HeadingIcon: SvgIconComponent | undefined = undefined;
    let headingText: string | null = null;
    if (typeof heading === 'string') {
        headingText = heading;
    } else {
        HeadingIcon = heading;
    }

    return (
        <Grid
            item
            xs={12}
            className={classNames(
                GStyles.positionRelative,
                GStyles.width100,
                componentClasses.root,
                classes?.root,
                className
            )}
            {...getIntercomTargetProp(intercomTargetProp)}
        >
            {headingText &&
                <Typography
                    color="primary"
                    component="h6"
                    className={classNames(
                        componentClasses.heading,
                        componentClasses.widthMaxContent,
                        classes?.heading
                    )}
                >
                    {headingText}
                </Typography>
            }

            {HeadingIcon &&
                <HeadingIcon
                    color="primary"
                    viewBox='1.125 0.85 21.75 23'
                    className={classNames(componentClasses.heading, classes?.heading)}
                />
            }

            {children}
        </Grid>
    );
};

export default BorderedSection;