import classNames from 'classnames';
import moment from 'moment';

import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRulesCallback } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import MoreVert from '@mui/icons-material/MoreVert';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import { GatherPhoto } from '../../../../types';
import Photo from '../../../profileImage/Photo';
import { DocUXDetail } from '../../../../shared/types';
import withGStyles from '../../../../styles/WithGStyles';
import { DateTimeFormat, joinNameParts } from '../../../../shared/utils';
import { getIntercomTargetProp } from '../../../../services';

const styles = <StyleWrapperProps extends object>(): StyleRulesCallback<Theme, StyleWrapperProps> => (theme) => ({
    root: {},
    item: {
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        '&$isDD214Item': {
            '& img': {
                minHeight: 161,
                '@media (min-width: 420px)': {
                    minHeight: 213,
                },
            },
        },
        '&:nth-child(odd)': {
            padding: '0 4px 0 8px'
        },
        '&:nth-child(even)': {
            padding: '0 8px 0 4px'
        },
        '& img': {
            width: '100%',
            minWidth: '100%',
            margin: 0,
            height: 'auto',
            boxShadow: theme.shadows[3],
            cursor: 'pointer'
        },
        '& $label': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            width: 'calc(100% - 16px)',
            '& p': {
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '85%',
                lineHeight: '1.25em'
            },
            '& $primary': {
                fontWeight: 300,
                fontSize: 12,
                textAlign: 'center'
            },
            '& $secondary': {
                display: 'none'
            }
        },
        '& $iconButton': {
            position: 'absolute',
            top: 4,
            right: 12,
            marginRight: 0,
            '& svg': {
                filter: 'drop-shadow(0 0px 2px gray)',
            }
        },
        '&$isCompactView': {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 12,
            flexDirection: 'row',
            '&:nth-child(odd)': {
                padding: 0
            },
            '&:nth-child(even)': {
                padding: 0
            },
            '&$isDD214Item': {
                '& img': {
                    border: `1px dashed ${theme.palette.primary.main}`,
                    minHeight: 100,
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
                },
            },
            '& img': {
                width: 77,
                minWidth: 77,
                margin: '0 8px',
            },
            '& $fileIcon': {
                filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
                cursor: 'pointer',
                '& svg': {
                    fontSize: 54,
                }
            },
            '& $iconButton': {
                height: 24,
                width: 24,
                '& svg': {
                    fontSize: 16
                },
            },
            '& $label': {
                width: 'calc(100% - 121px)', // 77 + 16 + 24
                '& $primary': {
                    lineHeight: '1.25em',
                    fontWeight: 400,
                    fontSize: 16,
                    textAlign: 'left'
                },
                '& $secondary': {
                    lineHeight: '1.25em',
                    display: 'block',
                    fontWeight: 300,
                    fontSize: 13
                }
            },
        },
    },
    isClickable: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    spinnerIcon: {
        margin: '0 12px',
    },
    isDD214Item: {},
    fileIcon: {},
    label: {},
    primary: {},
    secondary: {},
    iconButton: {},
    isCompactView: {}
});

type Classes = 'root' | 'isDD214Item' | 'fileIcon' | 'label' | 'primary'
    | 'isClickable' | 'secondary' | 'iconButton' | 'item' | 'isCompactView' | 'spinnerIcon';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    docDetail: DocUXDetail;
    isCompactView: boolean;
    photo: GatherPhoto | null;
    isGOMUser: boolean;
    key?: string | number;
    isDD214Item?: boolean;
    rootClass?: string;
    openMenu: (target?: HTMLElement) => void;
    intercomTargetPropDocItem?: string;
    intercomTargetPropDocMenu?: string;
}

const DocItem = (props: StyledProps & Props) => {
    const {
        classes,
        photo,
        isCompactView,
        key,
        docDetail,
        isGOMUser,
        rootClass,
        isDD214Item,
        openMenu,
        intercomTargetPropDocItem,
        intercomTargetPropDocMenu,
    } = props;

    const renderLoading = () => (
        <div className={classes.spinnerIcon}>
            <CircularProgress size={30} />
        </div>
    );

    const renderItemImage = () => (
        <>
            {photo && <Photo
                photo={photo}
                photoOptions={{ orientation: 'portrait' }}
                hideDelete
                hideDownload
                onPhotoClick={openMenu}
                imgContainerStyle={{
                    margin: isCompactView && '0 8px' || 0,
                    width: isCompactView && 77 || '100%',
                    height: 'auto'
                }}
                imgStyle={{
                    margin: 0,
                    width: isCompactView && 77 || '100%',
                    height: 'auto'
                }}
                imageId={`print-image-${key || ''}`}
            />}

            {!photo && <IconButton
                className={classes.fileIcon}
                color="primary"
                onClick={e => openMenu(e.currentTarget)}
                id={`print-image-${key || ''}`}
                size="large">
                <InsertDriveFile />
            </IconButton>}
        </>
    );

    const renderItemContents = () => {
        let line1: string | null = null;
        let line2: string | null = null;
        let isLine2Red = false;

        if (docDetail.doc && docDetail.status !== 'uploading') {
            const uploader = joinNameParts({
                fname: docDetail.doc.uploaded_by_fname,
                lname: docDetail.doc.uploaded_by_lname
            });
            if (docDetail.doc.pdf_status.includes('error') && isGOMUser) {
                line1 = `${moment(docDetail.doc.uploaded_time).format('MM/DD/YY @ h:mma')} by ${uploader}`;
                line2 = docDetail.doc.pdf_status;
                isLine2Red = true;
            } else {
                line1 = `uploaded by ${uploader}`;
                line2 = moment(docDetail.doc.uploaded_time).format(DateTimeFormat.LongDateWithTime);
            }
        } else {
            line2 = 'Uploading Document...';
        }

        return <>
            {docDetail.status !== 'uploading' ? renderItemImage() : renderLoading()}
            <Grid item xs={12} className={classes.label}>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.primary,
                        classes.isClickable
                    )}
                    onClick={e => openMenu(e.currentTarget)}
                >
                    {docDetail.label}
                </Typography>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.secondary,
                    )}
                >
                    {line1}
                </Typography>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.secondary,
                        isLine2Red && classes.colorRed,
                    )}
                >
                    {line2}
                </Typography>
            </Grid>
            <IconButton
                className={classes.iconButton}
                aria-label="More"
                aria-haspopup="true"
                aria-controls="upload-documents-item-menu"
                aria-owns={'upload-documents-item-menu'}
                onClick={e => openMenu(e.currentTarget)}
                size="large"
                {...getIntercomTargetProp(intercomTargetPropDocMenu)}
            >
                <MoreVert color="primary" />
            </IconButton>
        </>;
    };

    return (
        <Grid
            item
            xs={isCompactView ? 12 : 6}
            className={classNames(
                rootClass,
                classes.item,
                isDD214Item && !photo && classes.isDD214Item,
                isCompactView && classes.isCompactView
            )}
            key={`grid_${key || ''}`}
            {...getIntercomTargetProp(intercomTargetPropDocItem)}
        >
            {renderItemContents()}
        </Grid>
    );
};

export default withGStyles(styles<Props>())(DocItem);
