import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';
import Clear from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

import {
    CaseType,
    WorkflowSummary
} from '../../../../shared/types';
import { convertHexToRGBA } from '../../../../services/app.service';
import { SlideTransition } from '../../../common/Transitions';
import makeGStyles from '../../../../styles/makeGStyles';
import useFullScreen from '../../../common/hooks/useFullScreen';
import {
    WorkFlowSelectorFuneralHome,
    WorkFlowSelectorGatherCase,
} from './ChangeWorkflowAndCaseTypePure.dialog';
import WorkflowSelectorPure from './WorkflowSelectorPure';

const useStyles = makeGStyles(theme => ({
    root: {},
    createNewCaseDialog: {
        '& $dialogPaper': {
            margin: 0,
            maxWidth: 'min-content',
            minWidth: 300,
            minHeight: 'calc(100% - 64px)',
            width: '100%',
            '@media (min-width: 960px)': {
                margin: 32,
            }
        },
    },
    header: {
        zIndex: 1,
        color: theme.palette.primary.main,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
        padding: 4,
        textAlign: 'center',
        minHeight: 54,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fontSize: 24,
            top: 20,
            '@media (min-width: 400px)': {
                fontSize: 34,
                top: 10,
            }
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    headingSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerHeading: {
        fontSize: 18,
        '@media (min-width: 360px)': {
            fontSize: 20,
        },
        '@media (min-width: 400px)': {
            fontSize: 22,
        },
    },
    dialogContent: {
        background: '#fafafa',
        zIndex: 0,
        padding: '0 0 0px',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    themeColor12: {
        background: convertHexToRGBA(theme.palette.primary.main, 0.12),
    },
    dialogPaper: {},
    paddingTop14: {
        paddingTop: 14,
    },
}), { name: 'WorkflowSelectorDialog' });

interface Props {
    isOpen: boolean;
    isSaving: boolean;
    gatherCase: WorkFlowSelectorGatherCase;
    funeralHome: WorkFlowSelectorFuneralHome;
    workflows: WorkflowSummary[];
    isLoading: boolean;
    onClose: () => void;
    onSelect: (workflow: WorkflowSummary) => void;
    onCaseTypeChange: (caseType: CaseType) => void;
    zIndex: number;
}

const WorkflowSelectorDialog = (props: Props) => {
    const {
        gatherCase,
        isOpen,
        isSaving,
        funeralHome,
        workflows,
        isLoading,
        onClose,
        onSelect,
        onCaseTypeChange,
        zIndex,
    } = props;

    const classes = useStyles();

    const fullScreen = useFullScreen('md');

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={onClose}
            className={classes.createNewCaseDialog}
            classes={{
                paper: classes.dialogPaper
            }}
            style={{ zIndex }}
        >
            <DialogTitle className={classes.header}>
                <Clear className={classes.clearIcon} onClick={onClose} />

                <div className={classes.headingSection}>
                    <Typography className={classes.headerHeading} color="primary">
                        Select {gatherCase.fname}'s WorkFlow
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent
                className={classNames(
                    classes.dialogContent,
                    classes.themeColor12,
                )}
            >
                <WorkflowSelectorPure
                    caseType={gatherCase.case_type}
                    isSaving={isSaving}
                    caseFname={gatherCase.fname}
                    funeralHome={funeralHome}
                    activeWorkflowId={gatherCase.workflow_id}
                    activeWorkflowName={gatherCase.workflow_name}
                    onSelect={onSelect}
                    onCaseTypeChange={onCaseTypeChange}
                    listContainerClass={classes.paddingTop14}
                    workflows={workflows}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    );
};

export default WorkflowSelectorDialog;
