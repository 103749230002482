import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';

import { GatherCaseUX } from '../../../../shared/types';

import { useTaskDialogStyles } from '../TaskDialogsStyles';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    activeCase: GatherCaseUX;
}

const BlockUnwantedMail = (props: Props) => {
    const { activeCase } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper
            icon={<UnsubscribeIcon color="primary" />}
            heading="Block Unwanted Mail"
        >
            <Grid item>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    We want to help reduce and eliminate unwanted marketing mail by automatically
                    removing {activeCase.fname}'s information from advertisers' mailing lists.
                    The Direct Marketing Association maintains a “Deceased Do Not Contact” list
                    and within three months of adding {activeCase.fname}’s name to this list, the amount of
                    advertising mail received should decrease.
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    Simply click the link below to complete the brief online form.
                </Typography>
            </Grid>
            <Grid>
                <a
                    href="https://www.ims-dm.com/cgi/ddnc.php"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,
                            classes.marginTop30
                        )}
                    >
                        Eliminate Unwanted Mail <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default BlockUnwantedMail;
