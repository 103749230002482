import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';

import makeGStyles from '../../../../../../styles/makeGStyles';

export interface Props<T extends string> {
    id: string;
    label: string;
    options: T[];
    selected: T | T[] | null;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    displayLookup?: Record<T, string>;
    rootClass?: string;
    labelClass?: string;
    buttonClass?: string;
    buttonContainerClass?: string;
    onSelect: (value: T) => void;
    isHiddenFromFamily?: boolean;
    handleOpenSnackbar?: () => void;
}

const useStyles = makeGStyles({
    root: {
        width: '100%',
        textAlign: 'left',
        marginTop: 20,
    },
    toggleContainer: {
        padding: '12px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(1)': {
                marginTop: 1
            }
        }
    },
    buttonGroupLabel: {
        display: 'unset',
        position: 'relative',
        transform: 'none',
        textAlign: 'center',
        whiteSpace: 'unset',
    },
    labelContainer: {
        textAlign: 'center',
        '& svg': {
            marginLeft: 4,
            fontSize: 20,
            verticalAlign: 'middle'
        }
    },
    toggleButton: {
        padding: '4px 10px',
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54)'
    },
}, { name: 'DCToggleButtons' });

const DCToggleButtons = <T extends string>(props: Props<T>) => {
    const {
        id,
        error,
        disabled,
        required,
        selected,
        options,
        displayLookup,
        label,
        rootClass,
        buttonClass,
        buttonContainerClass,
        labelClass,
        onSelect,
        handleOpenSnackbar,
        isHiddenFromFamily
    } = props;
    const classes = useStyles();

    return (
        <FormControl
            className={classNames(classes.root, rootClass)}
            required={required}
            error={error}
        >
            <div className={classes.labelContainer}>
                <InputLabel
                    htmlFor={id}
                    className={classNames(classes.buttonGroupLabel, labelClass)}
                >
                    {label}
                </InputLabel>
                <HiddenFromFamilyAdornment showMe={isHiddenFromFamily} />
            </div>

            <div className={classNames(classes.toggleContainer, buttonContainerClass)}>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <ToggleButtonGroup
                        id={id}
                        value={selected}
                        exclusive
                        onClick={handleOpenSnackbar}
                        className={classes.displayBlock}
                    >
                        {options.map((option) => (
                            <ToggleButton
                                key={option}
                                value={option}
                                className={classNames(classes.toggleButton, buttonClass)}
                                onClick={() => onSelect(option)}
                                disabled={disabled}
                            >
                                {displayLookup ? displayLookup[option] : option}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
            </div>
        </FormControl>
    );
};

export default DCToggleButtons;