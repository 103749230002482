import { Component } from 'react';

import { AlbumDownloadState, AlbumDownloadStatus, AlbumIdOrCase } from '../../../../shared/types';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import UILink from '@mui/material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import { getAlbumDownloadStatus } from '../../../../actions/Album.action';
import { StoreState } from '../../../../types';
import { styleWrapper, StyledProps } from './styles';
import { isEqual } from 'lodash';
import withGStyles from '../../../../styles/WithGStyles';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import { SlideTransition } from '../../../common/Transitions';
import classNames from 'classnames';
import Portal from '@mui/material/Portal';

function mapStateToProps({ albumState }: StoreState) {
    return {
        albums: albumState.albums
    };
}

export type OpenSnackBarFunction = () => void;

interface Props extends ReturnType<typeof mapStateToProps> {
    caseUuid: string;
    albumId: AlbumIdOrCase;
    dispatch: AppDispatch;
    zIndex: number;
}

interface State {
    isAllowedOpenDownloadSnackbar: boolean;
}

const INTERVAL_FREQUENCY = 10000; // ten seconds

class DownloadSnackbar extends Component<Props & StyledProps, State> {
    state: State = {
        isAllowedOpenDownloadSnackbar: false
    };

    private intervalTimer: NodeJS.Timer | null = null;

    componentDidMount() {
        this.shouldStartIntervalTimer();
    }

    componentDidUpdate(prevProps: Readonly<Props & StyledProps & State>) {
        const { albumId: prevAlbumId, albums: prevState } = prevProps;
        const { albumId, albums } = this.props;

        if (prevAlbumId !== albumId || !isEqual(albums, prevState)) {
            this.shouldStartIntervalTimer();
        }

        if (!albums[albumId]) {
            return;
        }
        const { status }: AlbumDownloadState = albums[albumId].downloadState;
        const isOpen = (status === AlbumDownloadStatus.started || status === AlbumDownloadStatus.pending);

        if (isOpen) {
            this.startInterval();
        } else {
            this.stopInterval();
        }
    }

    componentWillUnmount() {
        this.stopInterval();
    }

    shouldStartIntervalTimer = () => {
        const { albums, albumId } = this.props;

        if (albums[albumId]) {
            const { status } = albums[albumId].downloadState;

            if (status === AlbumDownloadStatus.started || status === AlbumDownloadStatus.pending) {
                this.startInterval();
            }
        }
    };

    startInterval = () => {
        if (!this.intervalTimer) {
            this.intervalTimer = setInterval(this.handleIntervalCallback, INTERVAL_FREQUENCY);
        }
        if (!this.state.isAllowedOpenDownloadSnackbar) {
            this.handleOpenPreparingPhotosSnackbar();
        }
    };

    stopInterval = () => {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
            this.intervalTimer = null;
        }
    };

    handleIntervalCallback = () => {
        const { caseUuid, albumId, dispatch } = this.props;
        dispatch(getAlbumDownloadStatus(caseUuid, albumId));
    };

    handleOpenPreparingPhotosSnackbar = () => {
        this.setState({
            isAllowedOpenDownloadSnackbar: true,
        });
    };

    closeDownloadSnackbar = () => {
        this.setState({ isAllowedOpenDownloadSnackbar: false });
    };

    renderPreparingPhotosSnackbar = () => {
        const { classes, albums, albumId, zIndex } = this.props;
        if (!albums[albumId]) {
            return;
        }
        const { status, count }: AlbumDownloadState = albums[albumId].downloadState;
        const isOpen = (status === AlbumDownloadStatus.started || status === AlbumDownloadStatus.pending);

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={isOpen}
                TransitionComponent={SlideTransition}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes.preparingSnackbarContent
                    }
                }}
                className={classes.snackbarContainer}
                style={{ zIndex }}
                message={
                    <span className={classes.messageContainer}>
                        <LinearProgress
                            variant="indeterminate"
                            className={classes.linearProgress}
                        />
                        <IconButton color="inherit" className={classes.headerIcon} size="large">
                            <SettingsIcon />
                        </IconButton>
                        <span className={classes.progressText}>
                            {count ? `Preparing your ${count} photos...` : 'Preparing your photos'}
                        </span>
                        <span className={classes.progressSubText}>
                            This could take up to a few minutes
                        </span>
                    </span>
                }
            />
        );
    };

    renderDownloadPhotosSnackbar = () => {
        const { isAllowedOpenDownloadSnackbar } = this.state;
        const { classes, albums, albumId, zIndex } = this.props;
        if (!albums[albumId]) {
            return;
        }
        const { status, url } = albums[albumId].downloadState;

        const isOpen = status === AlbumDownloadStatus.completed && isAllowedOpenDownloadSnackbar;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={isOpen}
                onClose={() => this.closeDownloadSnackbar()}
                TransitionComponent={SlideTransition}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes.snackbarContent
                    }
                }}
                className={classes.snackbarContainer}
                style={{ zIndex }}
                message={
                    <span className={classNames(classes.messageContainer)}>
                        <IconButton color="primary" className={classes.headerIcon} size="large">
                            <PermMediaIcon />
                        </IconButton>
                        <span className={classNames(classes.flexDirectionColumn, classes.classFlexDirectionColumn)}>
                            <span className={classes.fontSize20}>
                                Your Photos are Ready
                            </span>
                            <span className={classNames(classes.downloadText, classes.classMessageContainer)}>
                                {url && <UILink href={url}>Click here to download</UILink>}
                            </span>
                        </span>
                        <IconButton
                            aria-label="Close"
                            color="primary"
                            className={classes.clearIcon}
                            onClick={() => this.closeDownloadSnackbar()}
                            size="large">
                            <ClearIcon />
                        </IconButton>
                    </span>
                }
            />
        );
    };

    render() {
        return (
            <Portal>
                {this.renderPreparingPhotosSnackbar()}
                {this.renderDownloadPhotosSnackbar()}
            </Portal>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styleWrapper<Props>())(DownloadSnackbar));
