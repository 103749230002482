import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { DATA_PRIVATE } from "../../../../../styles/Helper.styles";
import FuneralHomeLogo from "../../../../common/FuneralHomeLogo";
import PhoneNumberMask from "../../../../common/PhoneNumberMask";
import { SelfLoginStyledProps } from "../SelfLogin.styles";
import { useState } from "react";
import { LoginType } from "../SelfLoginDialog";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import LockIcon from "@mui/icons-material/Lock";
import { GStyles } from "../../../../../styles/GStyles";

interface Props extends SelfLoginStyledProps {
    hasFHLogo: boolean;
    loginError: string;
    password: string;
    email: string;
    phone: string;
    loginType: LoginType;
    hasPasswordError: boolean;
    hasEmailPhoneError: boolean;
    hasLoginDetailsError: boolean;
    isLoading: boolean;
    passwordErrorMessage: string[];
    onUpdateEmailPhone: (emailPhone: string) => void;
    onChangePassword: (password: string) => void;
    goToExistingUserLogin: () => void;
    toggleLoginType: () => void;
    openConfirmCloseDialog: () => void;
    createNewVisitor: () => void;
}

const LoginCredentialsStep = (props: Props) => {
    const {
        classes,
        hasFHLogo,
        loginError,
        password,
        email,
        phone,
        loginType,
        hasPasswordError,
        hasEmailPhoneError,
        hasLoginDetailsError,
        isLoading,
        passwordErrorMessage,
        onUpdateEmailPhone,
        goToExistingUserLogin,
        toggleLoginType,
        openConfirmCloseDialog,
        createNewVisitor,
        onChangePassword
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const isEmail = loginType === LoginType.email ? true : false;
    const textFieldLabel = loginType === LoginType.email ? 'Your Email' : 'Your Mobile number';
    const emailMobileToggleText = loginType === LoginType.phone ?
        'Continue instead using my email' : 'Continue instead using my mobile number';
    const isCreateMyAccountButtonDisabled = !password;
    const loginUsername = loginType === LoginType.email ? email : phone;
    const displayPasswordResetLink =
        loginError && loginError === 'User already exists' && loginUsername ? true : false;
    const errorLoginDisplayText = loginError && loginUsername ?
        loginError === 'User already exists' ?
            `${loginUsername} was already used to create an account, `
            + 'you can login using the link below.'
            : loginError
        : null;

    const passwordComplexityMessage =
        passwordErrorMessage.map(errStr => {
            return (
                <Typography
                    color="error"
                    key={errStr}
                >
                    {errStr}
                </Typography>
            );
        });

    return (
        <>
            <Grid item xs={12} className={classNames(classes.userDetails, classes.createAccount)}>
                <Typography className={classes.fontSize16}>And finally,</Typography>
                <Typography
                    color="secondary"
                    className={classes.heading}
                >
                    Create your free account.
                </Typography>

                <FormControl
                    className={classes.loginFormControl}
                    error={hasEmailPhoneError}
                >
                    {isEmail
                        && <AlternateEmailIcon color="secondary" />
                        || <SmartphoneIcon color="secondary" />
                    }
                    <TextField
                        label={textFieldLabel}
                        color="primary"
                        type={isEmail ? 'email' : 'tel'}
                        fullWidth
                        autoComplete="new-email"
                        value={isEmail ? email : phone}
                        error={hasEmailPhoneError || hasLoginDetailsError}
                        onChange={evt => onUpdateEmailPhone(evt.target.value)}
                        className={classNames(classes.marginBottom10, classes.marginLeft4)}
                        InputProps={{
                            inputComponent: isEmail ? undefined : PhoneNumberMask
                        }}
                    />
                </FormControl>
                <FormControl
                    className={classes.loginFormControl}
                    error={hasPasswordError}
                >
                    <LockIcon color="secondary" className={classes.marginTop12} />
                    <TextField
                        label="Create a password"
                        color="primary"
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={password}
                        error={hasPasswordError || hasLoginDetailsError}
                        onChange={evt => onChangePassword(evt.target.value)}
                        className={classNames(classes.marginLeft4, DATA_PRIVATE)}
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label="Toggle password visibility"
                                        onClick={() => setShowPassword(prev => !prev)}
                                        className={classNames(classes.passwordInputIcon, classes.colorSecondary)}
                                        size="large">
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
                {errorLoginDisplayText &&
                    < Typography color="error" key={loginError} >
                        {errorLoginDisplayText}
                    </Typography >
                }
                {displayPasswordResetLink &&
                    <Button
                        color="secondary"
                        className={classNames(classes.btnForgetPw, classes.loginInputField)}
                        tabIndex={-1}
                        onClick={goToExistingUserLogin}
                    >
                        <p
                            className={classNames(
                                classes.colorSecondary,
                                classes.fontWeight400,
                                classes.margin_0
                            )}
                        >
                            Click Here to Login
                        </p>
                    </Button>
                }
                <div>
                    {passwordComplexityMessage}
                </div>

                <Typography
                    onClick={e => toggleLoginType()}
                    color="secondary"
                    className={classes.emailPhoneToggle}
                >
                    {isEmail
                        && <SmartphoneIcon color="secondary" />
                        || <AlternateEmailIcon color="secondary" />
                    }&nbsp;{emailMobileToggleText}
                </Typography>
            </Grid>

            <Grid
                item
                className={classNames(classes.continueButtonContainer, classes.step3)}
            >
                <Button
                    color="primary"
                    className={classes.needOfAccountButton}
                    tabIndex={-1}
                    onClick={openConfirmCloseDialog}
                >
                    Why do I need to create a free account?
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classNames(classes.textTransformNone, classes.marginBottom8)}
                    onClick={createNewVisitor}
                    disabled={isLoading || isCreateMyAccountButtonDisabled}
                >
                    <LockIcon />&nbsp;Create my secure account
                    {isLoading && <CircularProgress className={classes.submitProgress} />}
                </Button>
                <Typography color="secondary" className={classNames(classes.haveAccount, classes.lineHeight1)}>
                    By Continuing, you agree to our&nbsp;
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://gather.app/terms-conditions/"
                        className={classNames(
                            GStyles.colorSecondary,
                            GStyles.clickable,
                            classes.fontWeight500
                        )}
                    >
                        terms & conditions
                    </a>
                </Typography>
                <Typography color="secondary" className={classNames(classes.haveAccount, classes.margin_0)}>
                    and that you have read and agree to our&nbsp;
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://help.gather.app/en/articles/8851725-privacy-policy"
                        className={classNames(
                            GStyles.colorSecondary,
                            GStyles.clickable,
                            classes.fontWeight500
                        )}
                    >
                        privacy policy
                    </a>
                </Typography>

                {hasFHLogo && <FuneralHomeLogo className={classes.fhLogo} />}
            </Grid>
        </>
    );
};

export default LoginCredentialsStep;