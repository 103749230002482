import classNames from 'classnames';

import makeGStyles from "../../styles/makeGStyles";
import { alpha } from "@mui/material";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { APP_SECONDARY_COLOR } from '../../constants/colorVariables';

import GSwitch from "../common/inputElements/GSwitch";
import LearnMoreButton from '../common/LearnMoreButton';
import FamilySharingExplainerDialog from '../family/bodyTracking/dialogs/FamilySharingExplainer.dialog';
import { useState } from 'react';

const useStyles = makeGStyles(theme => ({
    permissionsContainer: {
        display: 'flex',
        marginTop: 20,
        columnGap: 24,
        rowGap: 20,
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    permissionsInnerContainer: {
        width: 'fit-content',
        paddingTop: 6,
        paddingBottom: 4,
        borderRadius: 6,
        minWidth: 300,
        background: theme.palette.common.white,
        border: `1px dashed ${APP_SECONDARY_COLOR}`,
        color: APP_SECONDARY_COLOR,
        '&$selected': {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            background: alpha(theme.palette.primary.main, 0.08),
        }
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    switchContainerInner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gSwitch: {
        '& label': {
            margin: 0,
            maxHeight: 20,
        },
    },
    switchBottomText: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& p': {
            padding: '8px 8px 0px 0px',
            fontSize: 10,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
            '& svg': {
                fontSize: 16,
            },
        },
    },
    selected: {},
}), { name: 'QRPermissionsForHelpers' });

interface PermissionSwitchProps {
    helperFname: string;
    question: string;
    isChecked: boolean;
    onToggleClick: (checked: boolean) => void;
    onLearnMore: () => void;
}
const PermissionSwitch = ({
    question,
    isChecked,
    helperFname,
    onLearnMore,
    onToggleClick
}: PermissionSwitchProps) => {
    const classes = useStyles();

    return (
        <Grid
            className={classNames(
                classes.permissionsInnerContainer,
                isChecked && classes.selected,
            )}
        >
            <Grid className={classes.switchContainer}>
                <Typography color="inherit">
                    Can {helperFname} view {question}?
                </Typography>

                <Grid item xs={12} className={classes.switchContainerInner}>
                    <label htmlFor="view-details" className={classes.colorSecondary}>No</label>
                    <div className={classes.gSwitch}>
                        <GSwitch
                            checked={isChecked}
                            onChangeCallBack={onToggleClick}
                            id="view-details"
                        />
                    </div>
                    <label
                        htmlFor="view-details"
                        className={classNames(
                            isChecked ? classes.colorPrimary : classes.colorSecondary
                        )}
                    >
                        Yes
                    </label>
                </Grid>
            </Grid>
            <LearnMoreButton
                label={`Click to learn more`}
                onClick={onLearnMore}
                intercomTargetProp={`QRPermissionsForHelpers-LearnMore`}
            />
        </Grid>
    );
};

interface Props {
    zIndex: number;
    fhName: string;
    helperFname: string;
    canViewQRTracking: boolean;
    canViewCaseBelongings: boolean;
    setCanViewQRTracking: (isChecked: boolean) => void;
    setCanViewCaseBelongings: (isChecked: boolean) => void;
}
const QRPermissionsForHelpers = (props: Props) => {
    const {
        zIndex,
        fhName,
        helperFname,
        canViewQRTracking,
        canViewCaseBelongings,
        setCanViewQRTracking,
        setCanViewCaseBelongings,
    } = props;

    const classes = useStyles();

    const [familySharingExplainerDialogOpen, setfamilySharingExplainerDialogOpen] = useState(false);

    return (
        <Grid className={classes.permissionsContainer}>
            <PermissionSwitch
                helperFname={helperFname}
                question="tracking step details"
                isChecked={canViewQRTracking}
                onToggleClick={setCanViewQRTracking}
                onLearnMore={() => setfamilySharingExplainerDialogOpen(true)}
            />

            <PermissionSwitch
                helperFname={helperFname}
                question="case belongings"
                isChecked={canViewCaseBelongings}
                onToggleClick={setCanViewCaseBelongings}
                onLearnMore={() => setfamilySharingExplainerDialogOpen(true)}
            />

            <FamilySharingExplainerDialog
                fhName={fhName}
                zIndex={zIndex + 1}
                open={familySharingExplainerDialogOpen}
                onClose={() => setfamilySharingExplainerDialogOpen(false)}
            />
        </Grid>
    );
};

export default QRPermissionsForHelpers;