import { Box, Typography } from "@mui/material";
import InsetShadowIcon from "../../common/InsetShadowIcon";

interface Props {
    icon: JSX.Element;
    text: string;
    iconClass?: string;
    intercomTarget: string;
    learnMore?: boolean;
}

const InvitingTeam = ({ icon, text, iconClass, intercomTarget, learnMore }: Props) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems='center'
            p={1}
        >
            <InsetShadowIcon
                icon={icon}
                iconClass={iconClass}
            />
            <Typography
                color="primary"
                textAlign="center"
                fontSize="12px"
            >
                {text}
            </Typography>
        </Box>
    );
};

export default InvitingTeam;