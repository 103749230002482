import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { UserSession } from '../../../types';
import { GatherCaseUX, CaseType, UserRoles, FH_LogoType } from '../../../shared/types';

import BaseAppBar from './Base.appBar';
import FuneralHomeLogo from '../../common/FuneralHomeLogo';
import { FuneralHomeRoutePage, RouteBuilder } from "../../../services";
import GLink from '../../common/GLink';
import makeGStyles from '../../../styles/makeGStyles';
import Hidden from '@mui/material/Hidden';

const useStyles = makeGStyles((theme) => ({
    lockIcon: {
        '& svg': {
            fontSize: 28
        }
    },
    fhLogo: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: '72px !important',
        }
    },
    appbar: {
        minHeight: 140,
        [theme.breakpoints.up('md')]: {
            minHeight: 120
        }
    },
    fhLogoContainer: {
        width: '100%',
        height: 'calc(100% - 48px)',
        position: 'absolute',
        flex: 1,
        left: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        '& a': {
            pointerEvents: 'visible',
        },
        [theme.breakpoints.up('md')]: {
            height: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
    headerUserAvatarTextSlot: {
        fontSize: 14,
        lineHeight: '48px',
        opacity: 0.6,
        paddingRight: 8,
        '@media (max-width: 360px)': {
            width: '56px',
            display: 'inline-table',
            textAlign: 'center',
            marginTop: 8,
            lineHeight: '16px',
        },
    },
}), { name: 'GoodsAndServicesAppBar' });

interface Props {
    userSession: UserSession;
    activeCase: GatherCaseUX;
    icon: JSX.Element;
    priceWidget: JSX.Element;
    goBackCallBack?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    openDocsPopper: (event: React.MouseEvent<HTMLElement>) => void;
    openGSPopper: (event: React.MouseEvent<HTMLElement>) => void;
}

const GoodsAndServicesAppBar = (props: Props) => {
    const {
        openGSPopper,
        icon,
        openDocsPopper,
        userSession,
        activeCase,
        goBackCallBack,
        priceWidget,
    } = props;

    const { userData } = userSession;

    const classes = useStyles();

    const isFHorGOMUserOnFH = UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home_id);

    const dashboardLink = isFHorGOMUserOnFH
        ? RouteBuilder.FuneralHome(
            activeCase.funeral_home.key,
            activeCase.funeral_home.is_demo
                ? FuneralHomeRoutePage.MYFUNERALHOME
                : FuneralHomeRoutePage.MYCASES,
        )
        : RouteBuilder.Disabled;

    const goBackLink = activeCase.case_type === CaseType.one_off
        ? RouteBuilder.FuneralHome(activeCase.funeral_home.key, FuneralHomeRoutePage.MYCASES)
        : RouteBuilder.FamilyPage({
            caseName: activeCase.name,
            funeralHomeKey: activeCase.funeral_home.key,
        });

    return (
        <BaseAppBar
            zIndex={1310}
            userAvatarTextSlot={(
                <span className={classes.headerUserAvatarTextSlot}>
                    <Hidden only={['xs', 'sm']}>
                        <span>All changes saved automatically</span>
                    </Hidden>
                    <Hidden mdUp>
                        <span>Changes saved</span>
                    </Hidden>
                </span>
            )}
            position="relative"
            hideGoBack={activeCase.case_type === CaseType.one_off && !isFHorGOMUserOnFH}
            goBackLink={goBackLink}
            goBackCallBack={goBackCallBack}
            appbarClass={classes.appbar}
        >
            <IconButton
                color="inherit"
                onClick={openDocsPopper}
                size="large"
            >
                <FileCopyIcon className={classes.fontSize28} />
            </IconButton>

            <IconButton
                color="inherit"
                aria-label="lock open"
                onClick={openGSPopper}
                className={classes.lockIcon}
                size="large"
            >
                {icon}
            </IconButton>

            {priceWidget || null}

            <div className={classes.fhLogoContainer}>
                <GLink to={dashboardLink}>
                    <FuneralHomeLogo
                        className={classes.fhLogo}
                        logoType={FH_LogoType.themeLogo}
                        style={{ maxWidth: 320, maxHeight: 100 }}
                        toolTipTitle={isFHorGOMUserOnFH ?
                            "Click here to return to your dashboard" : undefined
                        }
                        toolTipDelay={0}
                    />
                </GLink>
            </div>

        </BaseAppBar>
    );
};

export default GoodsAndServicesAppBar;
