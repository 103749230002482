import * as React from 'react';
import classNames from 'classnames';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverPosition, PopoverReference } from '@mui/material/Popover';
import GTooltip from './GTooltip';
import GLink from './GLink';
import { RouteBuilder } from "../../services";
import { AppRoute } from "../../services";
import { useGStyles } from './hooks/useGStyles';

export interface GMenuItem {
    id: React.Key;
    content: React.ReactNode;
    className?: string;
    useFragment?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    disableRipple?: boolean;
    link?: AppRoute;
    disabled?: boolean;
    tooltip?: string;
}
interface Props {
    zIndex: number;
    items: GMenuItem[];
    anchorEl: HTMLElement | null;
    onClose: () => void;
    anchorReference?: PopoverReference;
    anchorPosition?: PopoverPosition;
    menuListCass?: string;
    paperClass?: string;
}
const GMenu = (props: Props) => {
    const { anchorEl, items, zIndex, anchorPosition, anchorReference, menuListCass, paperClass, onClose } = props;

    const classes = useGStyles();

    return (
        <Menu
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            style={{ zIndex }}
            anchorPosition={anchorPosition}
            anchorReference={anchorReference}
            classes={{ list: menuListCass, paper: paperClass }}
        >
            {items.map(item => {

                const content = item.useFragment
                    ? <span key={item.id}>
                        {item.content}
                    </span>
                    : <MenuItem
                        key={item.id}
                        onClick={item.onClick}
                        className={item.className}
                        disableRipple={item.disableRipple}
                        disableTouchRipple={item.disableRipple}
                        disableGutters={item.disableRipple}
                        disabled={item.disabled}
                    >
                        {item.content}
                    </MenuItem>;

                if (item.link) {
                    return (
                        <GLink
                            key={item.id}
                            to={item.disabled ? RouteBuilder.Disabled : item.link}
                            linkClass={classNames(classes.colorBlack, item.className)}
                        >
                            {content}
                        </GLink>
                    );
                }

                if (item.tooltip) {
                    return (
                        <GTooltip key={item.id} contentDisabled={item.disabled} title={item.tooltip}>
                            {content}
                        </GTooltip>
                    );
                }

                return content;
            })}
        </Menu>
    );
};

export default GMenu;