import * as React from 'react';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, withStyles, WithStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
    EntityCaseRole,
    EntitySummary,
    getCaseEntity,
    getDisplayRelationshipForPerson,
    UserRoles
} from '../../../../../shared/types';
import { StoreState } from '../../../../../types';

import BaseFamilyAndFriendsDialogWrapper from '../../../../common/BaseFamilyAndFriends.dialog';
import UserAvatar from '../../../../common/UserAvatar';
import HelperPopper from '../../../helperPopper/HelperPopper';
import withState from '../../../../common/utilHOC/WithState';

const BaseFamilyAndFriendsDialog = BaseFamilyAndFriendsDialogWrapper<EntitySummary>();

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    listStyle: {
        paddingRight: 0,
        paddingLeft: 0,
        minHeight: 68,
    },
    userAvatar: {
        width: 48,
        height: 48,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    overlapDot: {
        top: 2,
        left: 2,
    },
});

type StyledProps = WithStyles<'root' | 'listStyle' | 'userAvatar' | 'overlapDot'>;

function mapStateToProps({ casesState }: StoreState) {
    return { selectedCase: casesState.selectedCase };
}
interface Props extends ReturnType<typeof mapStateToProps> {
    isDialogOpen: boolean;
    zIndex: number;
    invitedHelpers: EntitySummary[];
    closeDialog: () => void;
    openInvitationDialog: (currentTab: EntityCaseRole) => void;
}

interface State {
    popperAnchorEle: HTMLElement | null;
    activeEntityId: number | null;
}

class FamilyAndFriendDetailDialog extends React.Component<Props & StyledProps, State> {
    state: State = {
        popperAnchorEle: null,
        activeEntityId: null
    };

    private popperDelayTimer: NodeJS.Timer;

    renderHelperPopper = () => {
        const { selectedCase, zIndex } = this.props;
        const { popperAnchorEle, activeEntityId } = this.state;

        if (!selectedCase) {
            return null;
        }

        return (
            <HelperPopper
                key={activeEntityId}
                zIndex={zIndex + 1}
                clickAwayListener={this.handleClosePopper}
                selectedCase={selectedCase}
                activeEntityId={activeEntityId}
                popperAnchorEle={popperAnchorEle}
                closeHelperPopper={this.closeHelperPopper}
            />
        );
    };

    render() {
        const {
            openInvitationDialog,
            invitedHelpers,
            selectedCase,
            classes,
            closeDialog,
            ...others
        } = this.props;

        if (!selectedCase) {
            return null;
        }

        return (
            <>
                <BaseFamilyAndFriendsDialog
                    onInviteButtonClick={() => openInvitationDialog(EntityCaseRole.guest)}
                    users={invitedHelpers}
                    title={'Family & Friends'}
                    closeDialog={this.handleClose}
                    {...others}
                >
                    {(helper: EntitySummary) => {
                        const caseEntity = getCaseEntity(helper, selectedCase.id);
                        const relationship = caseEntity && getDisplayRelationshipForPerson(caseEntity);
                        return (
                            <ListItem
                                key={helper.entity_id}
                                divider
                                className={classes.listStyle}
                            >
                                <UserAvatar
                                    user={helper}
                                    className={classes.userAvatar}
                                    overlapDotClass={classes.overlapDot}
                                    showOverlapDot={UserRoles.isFamilyAdmin(helper, selectedCase.id)
                                        || UserRoles.isFHorGOMUser(helper)}
                                    onClick={(e) => this.openHelperPopper(e, helper.entity_id)}
                                    size={48}
                                />
                                <ListItemText
                                    primary={helper.fname}
                                    secondary={relationship
                                        ? `${selectedCase.display_fname}'s ${relationship}`
                                        : ''
                                    }
                                />
                            </ListItem>
                        );
                    }}
                </BaseFamilyAndFriendsDialog>
                {this.renderHelperPopper()}
            </>
        );
    }

    handleClose = () => {
        this.closeHelperPopper();
        this.props.closeDialog();
    };

    handleClosePopper = () => {
        this.setState({
            popperAnchorEle: null,
        });
        this.handleCancelOpenPopper();
    };

    handleCancelOpenPopper = () => {
        if (this.popperDelayTimer) {
            clearTimeout(this.popperDelayTimer);
        }
    };

    closeHelperPopper = () => {
        this.setState({
            popperAnchorEle: null,
            activeEntityId: null,
        });
    };

    openHelperPopper = (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        activeEntityId: number | null,
    ) => {

        this.setState({
            activeEntityId,
            popperAnchorEle: event.currentTarget
        });
    };
}

export default withState(mapStateToProps)(withStyles(styles)(FamilyAndFriendDetailDialog));
