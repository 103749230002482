import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Clear from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import {
    CaseTaskUX,
    FeatureKey,
    GatherCaseUX,
    TaskAssignee,
    TaskState,
    TaskTemplateType,
    TaskType,
    TaskUpdateRequestUX,
} from '../../../shared/types';
import GSwitch from '../../common/inputElements/GSwitch';
import { SlideTransition } from '../../common/Transitions';
import makeGStyles from '../../../styles/makeGStyles';
import useFullScreen from '../../common/hooks/useFullScreen';
import AssignTask, { AssigneeRelatedTaskFields } from '../../assignmentPoppers/AssignTask';
import { useGSelector } from '../../../types';
import { ChecklistTaskType } from '../editWorkFlow/editTasks/EditTasks';
import useFeatureEnabled from '../../common/hooks/useFeatureEnabled';
import GatherDateTimePicker from '../../common/GDateTimePicker';
import momentTz from 'moment-timezone';
import { dueDateTooltipString, getStandardDateText } from '../../../services';
import GButton from '../../common/GButton';
import GTooltip from '../../common/GTooltip';
import { canToggleVisibleToFamily } from '../../../shared/authority/can';

const useStyles = makeGStyles((theme) => ({
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        cursor: 'pointer',
        '@media (min-width: 400px)': {
            fontSize: 34,
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
    },
    dialogFormGrid: {
        marginBottom: '15px',
    },
    dialogContentGrid: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    familySwitch: {
        fontSize: 14,
        width: 'fit-content',
        display: 'flex',
        margin: 0,
    },
    gSwitchRoot: {
        display: 'flex',
        width: 'fit-content',
    },
    dueDateLink: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

interface SaveTaskDialogTask
    extends Pick<CaseTaskUX, 'id' | 'title' | 'description' | 'is_after_care' | 'visible_to_family'> {
    subtitle: string | null;
    assigned_to?: TaskAssignee[];
    assigned_to_all?: boolean;
    event_id?: number | null;
    state?: TaskState;
    template_type?: TaskTemplateType | null;
    can_assign_multiple?: boolean;
    complete_by_time?: Date | null;
    marked_complete_time?: CaseTaskUX['marked_complete_time'];
    skipped_time?: CaseTaskUX['skipped_time'];
}

export interface CreateUpdateSaveTask
    extends Omit<SaveTaskDialogTask, 'id' | 'can_assign_multiple' | 'template_type' | 'event_id' | 'state'> {
    assigned_to: TaskAssignee[];
    assigned_to_all: boolean;
}

interface Props {
    isDialogOpen: boolean;
    activeCase?: GatherCaseUX;
    existing: SaveTaskDialogTask | null;
    zIndex: number;
    type?: ChecklistTaskType;
    showAssignTask?: boolean;
    closeDialog: () => void;
    onCreate: (taskTemplate: CreateUpdateSaveTask) => Promise<SaveTaskDialogTask | null>;
    onUpdate: (existing: SaveTaskDialogTask, changes: CreateUpdateSaveTask) => Promise<SaveTaskDialogTask | null>;
    showDueDate: boolean;
}

const GSaveTaskDialog = (props: Props) => {
    const {
        isDialogOpen, zIndex, existing, type, activeCase, showAssignTask, onCreate, onUpdate, closeDialog, showDueDate
    } = props;

    const fullScreen = useFullScreen();
    const classes = useStyles();

    const isAfterCareTasksDisabled = !useFeatureEnabled(FeatureKey.AFTER_CARE);
    const team = useGSelector(({ teamState }) => teamState.team);

    const canToggleFamilyView = useGSelector(({ userSession }) =>
        canToggleVisibleToFamily(userSession.userData, activeCase?.funeral_home.id)
    );

    const defaultIsVisibleToFamily = !canToggleFamilyView;
    const [taskName, setTaskName] = useState(existing?.title || '');
    const [taskDetails, settaskDetails] = useState(existing?.description || '');
    const [taskSubheader, setTaskSubheader] = useState(existing?.subtitle || '');
    const [isAfterCare, setIsAfterCare] = useState(existing?.is_after_care || type === 'after-care');
    const [isVisibleToFamily, setIsVisibleToFamily] = useState(existing?.visible_to_family || defaultIsVisibleToFamily);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [assignedToAll, setAssignedToAll] = useState(existing?.assigned_to_all || false);
    const [assignedTo, setAssignedTo] = useState(existing?.assigned_to || []);
    const [isSaving, setIsSaving] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [completeByTimestamp, setCompleteByTimestamp] = useState(existing?.complete_by_time || null);

    useEffect(() => {
        if (existing && isDialogOpen) {
            setTaskName(existing.title);
            settaskDetails(existing.description || '');
            setTaskSubheader(existing.subtitle || '');
            setIsAfterCare(existing.is_after_care);
            setIsVisibleToFamily(existing.visible_to_family);
            setAssignedTo(existing.assigned_to || []);
            setAssignedToAll(existing.assigned_to_all || false);
            setCompleteByTimestamp(existing.complete_by_time || null);
        }
    }, [existing, isDialogOpen]);

    const closeAndReset = () => {
        setTaskName('');
        settaskDetails('');
        setTaskSubheader('');
        setIsAfterCare(false);
        setIsVisibleToFamily(false);
        setSaveAttempted(false);
        setAssignedTo([]);
        setAssignedToAll(false);
        setCompleteByTimestamp(null);
        closeDialog();
    };

    const hasChanges = () => {
        return Boolean(
            !existing ||
            taskName !== existing.title ||
            taskDetails !== existing.description ||
            taskSubheader !== existing.subtitle ||
            isVisibleToFamily !== existing.visible_to_family ||
            isAfterCare !== existing.is_after_care ||
            completeByTimestamp !== existing.complete_by_time ||
            (existing.assigned_to !== undefined && existing.assigned_to.length !== assignedTo.length) ||
            (existing.assigned_to_all !== undefined && existing.assigned_to_all !== assignedToAll),
        );
    };

    const isValid = () => {
        return Boolean(taskName.trim());
    };

    const onSave = async () => {
        if (!isValid()) {
            setSaveAttempted(true);
            return;
        } else if (!hasChanges()) {
            closeAndReset();
            return;
        }

        setIsSaving(true);

        if (existing) {
            const changes: CreateUpdateSaveTask = {
                title: taskName,
                subtitle: taskSubheader,
                description: taskDetails,
                visible_to_family: isVisibleToFamily,
                is_after_care: isAfterCare,
                assigned_to: assignedTo,
                assigned_to_all: assignedToAll,
                complete_by_time: completeByTimestamp,
            };
            const updated = await onUpdate(existing, changes);

            if (updated) {
                closeAndReset();
            }
        } else {
            const taskToCreate: CreateUpdateSaveTask = {
                title: taskName,
                subtitle: taskSubheader,
                description: taskDetails,
                visible_to_family: isVisibleToFamily,
                is_after_care: isAfterCare,
                assigned_to: assignedTo,
                assigned_to_all: assignedToAll,
                complete_by_time: completeByTimestamp,
            };
            const created = await onCreate(taskToCreate);

            if (created) {
                closeAndReset();
            }
        }

        setIsSaving(false);
    };

    const toggleFamilyisiblity = (isChecked: boolean) => {
        setAssignedTo((prevAssigned) =>
            !isVisibleToFamily
                ? prevAssigned.filter((assignee) => team.some((t) => assignee.user_id === t.user_id))
                : prevAssigned,
        );

        setIsVisibleToFamily(isChecked);
    };

    const handleChangeTask = (id: number, { assigned_to, assigned_to_all }: TaskUpdateRequestUX) => {
        setAssignedTo((prevAssigned) => (assigned_to !== undefined ? assigned_to : prevAssigned));
        setAssignedToAll((prevAssigned) => (assigned_to_all !== undefined ? assigned_to_all : prevAssigned));
    };

    const handleDateTimeChange = (newDate: momentTz.Moment | null) => {
        const newDateTz = newDate ? newDate.toDate() : null;
        setCompleteByTimestamp(newDateTz);
    };
    const closeDatePickerDialog = () => {
        setShowDatePicker(false);
    };

    const populateTaskForAssignTask = (): AssigneeRelatedTaskFields => {
        return {
            id: existing?.id || -1,
            type: TaskType.checklist_task,
            state: existing?.state || TaskState.incomplete,
            event_id: existing?.event_id || null,
            can_assign_multiple: existing?.can_assign_multiple || true,
            visible_to_family: isVisibleToFamily,
            assigned_to: assignedTo,
            assigned_to_all: assignedToAll,
            template_type: existing?.template_type || null,
        };
    };

    const dueDateString =
        completeByTimestamp &&
        getStandardDateText({
            timezone: momentTz.tz.guess(),
            targetDate: completeByTimestamp,
            prefix: 'Due',
            pastPrefix: ':',
        });

    const renderDueDate = () => {
        if (!dueDateString) {
            return 'Add Due Date';
        }

        if (dueDateString.startsWith(':')) {
            return (
                <>
                    <Typography color="error" component="span">
                        Overdue
                    </Typography>
                    {dueDateString}
                </>
            );
        }
        return dueDateString;
    };

    const renderContent = () => {
        const isInvalidName = saveAttempted && !taskName.trim();

        return (
            <Grid container>
                <Grid className={classes.dialogFormGrid} item xs={12} md={12} lg={12}>
                    <FormControl fullWidth error={isInvalidName} required>
                        <TextField
                            required
                            label="Task Name"
                            margin="dense"
                            name="title"
                            type="text"
                            fullWidth
                            onChange={(e) => setTaskName(e.target.value)}
                            value={taskName}
                            error={isInvalidName}
                        />
                        <FormHelperText className={classes.margin_0}>
                            {isInvalidName ? 'Please enter the task name' : ' '}
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            label="Task Subheader (optional)"
                            margin="dense"
                            type="text"
                            fullWidth
                            onChange={(e) => setTaskSubheader(e.target.value)}
                            value={taskSubheader}
                            multiline
                        />
                        <FormHelperText className={classes.margin_0}>
                            Shows in the task accordion under the Task Name field
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            label="Task Details (optional)"
                            margin="dense"
                            name="explainer_text"
                            type="text"
                            fullWidth
                            onChange={(e) => settaskDetails(e.target.value)}
                            value={taskDetails}
                            multiline
                        />
                    </FormControl>
                </Grid>
                {showDueDate && <Grid className={classes.margin_0} item xs={12}>
                    <GTooltip
                        contentDisabled={!!(existing?.marked_complete_time || existing?.skipped_time)}
                        title={dueDateTooltipString(completeByTimestamp)}
                    >
                        <GButton
                            disabled={!!(existing?.marked_complete_time || existing?.skipped_time)}
                            variant="text"
                            color="secondary"
                            onClick={() => setShowDatePicker(true)}
                            startIcon={
                                <EventAvailableIcon color={dueDateString?.startsWith(':') ? 'error' : 'primary'} />
                            }
                            sx={{
                                padding: '8px 2px',
                                fontWeight: 400,
                                textTransform: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {renderDueDate()}
                        </GButton>
                    </GTooltip>
                </Grid>}

                <Grid
                    className={classes.dialogContentGrid}
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                        marginLeft: '-15px',
                        maxWidth: 'calc(100% + 15px)',
                        flexBasis: 'calc(100% + 15px)',
                    }}
                >
                    {canToggleFamilyView
                        && <Typography variant="caption" align="left" noWrap={false} className={classes.familySwitch}>
                            <GSwitch
                                label={'Make this task visible to the family'}
                                checked={isVisibleToFamily}
                                labelClasses={classNames(classes.cursorPointer, classes.colorSecondary)}
                                formControlLabelClasses={classes.margin_0}
                                onChangeCallBack={toggleFamilyisiblity}
                            />
                        </Typography>}
                    <Tooltip
                        placement="top"
                        title={
                            isAfterCareTasksDisabled
                                ? `After-care tasks have been disabled. To take advantage of this feature, 
                                please contact Gather.`
                                : ''
                        }
                    >
                        <Typography
                            variant="caption"
                            gutterBottom
                            align="left"
                            noWrap={false}
                            className={classes.familySwitch}
                        >
                            <GSwitch
                                label={'This is an after-care task'}
                                checked={isAfterCare}
                                disabled={isAfterCareTasksDisabled}
                                labelClasses={classNames(
                                    !isAfterCareTasksDisabled && classes.cursorPointer,
                                    classes.colorSecondary,
                                )}
                                rootClass={classes.gSwitchRoot}
                                formControlLabelClasses={classes.margin_0}
                                onChangeCallBack={(isChecked) => !isAfterCareTasksDisabled && setIsAfterCare(isChecked)}
                            />
                        </Typography>
                    </Tooltip>
                </Grid>

                {showAssignTask && activeCase && (
                    <Grid className={classes.dialogContentGrid} item xs={12}>
                        <AssignTask
                            zIndex={zIndex + 1}
                            onChangeTask={handleChangeTask}
                            task={populateTaskForAssignTask()}
                            activeCase={activeCase}
                        />
                    </Grid>
                )}

                <Grid className={classes.dialogContentGrid} item xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classNames(classes.margin_8, isSaving && classes.buttonDisabled)}
                        onClick={onSave}
                        disabled={isSaving || !hasChanges()}
                    >
                        {existing ? 'Update task' : 'Create new task'}
                        {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </Grid>

                {canToggleFamilyView && <Grid className={classes.dialogContentGrid} item xs={12} md={12} lg={12}>
                    <Typography variant="caption" gutterBottom align="center">
                        This task will{isVisibleToFamily ? '' : ' NOT'} be visible to the family
                    </Typography>
                </Grid>}
            </Grid>
        );
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            onClose={closeAndReset}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            maxWidth="xs"
            style={{ zIndex }}
        >
            <DialogTitle className={classes.header}>
                <Clear color="secondary" className={classes.clearIcon} onClick={closeAndReset} />
            </DialogTitle>
            <DialogContent>{renderContent()}</DialogContent>
            {showDueDate && <GatherDateTimePicker
                onUpdate={handleDateTimeChange}
                isDialogOpen={showDatePicker}
                zIndex={zIndex}
                onClose={closeDatePickerDialog}
                timezone={activeCase?.funeral_home.timezone || undefined}
                isReadOnly={false}
            />}
        </Dialog>
    );
};

export default GSaveTaskDialog;
