import * as classNames from 'classnames';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import makeGStyles from '../../../../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {
        objectFit: 'cover',
        maxHeight: 'inherit',
        pointerEvents: 'none',
        userSelect: 'none',
        width: 160,
        height: 180,
    },
    centerWhiteCircle: {
        background: theme.palette.common.white,
        position: 'absolute',
        content: '""',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        width: 56,
        height: 56
    },
    checkIcon: {
        fontSize: 72,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}), { name: 'FingerprintImage' });

interface Props {
    s3DownloadURL: string;
}
const FingerprintImage = ({ s3DownloadURL }: Props) => {
    const classes = useStyles();

    return (
        <>
            <img
                src={s3DownloadURL}
                alt="Fingerprint Image"
                className={classes.root}
                tabIndex={-1}
                onContextMenu={(e) => e.preventDefault()}
            />
            <div aria-hidden className={classes.centerWhiteCircle} />
            <CheckCircleIcon className={classNames(classes.colorGreen, classes.checkIcon)} />
        </>
    );
};

export default FingerprintImage;
