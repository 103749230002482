import classNames from 'classnames';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { GPLTabType, SHOWROOM_TAB_LIST } from '..';
import {
    ProductCategory,
    ProductContractItemUX,
    ProductItem,
    ProductPackageUX,
    ProductSupplierUXWithCategory,
    ProductUX,
    GPLContext,
    ProductCategoryEnum
} from '../../../../shared/types';
import ShowroomContent from './ShowroomContent';
import { useGSelector } from '../../../../types';
import makeGStyles from '../../../../styles/makeGStyles';
import WireProps from '../../../common/WireProps';
import { AppRoute, RouteBuilder } from '../../../../services';
import GLink from '../../../common/GLink';

const useShowroomStyles = makeGStyles(theme => ({
    root: {
        paddingBottom: 80
    },
    slide: {
        minHeight: 'calc(100vh - 122px)',
        marginTop: 40,
        '@media (min-width: 440px)': {
            marginTop: 0
        },
    },
    tabAppbar: {
        background: theme.palette.background.paper,
        boxShadow: 'none',
        marginBottom: 5,
        justifyContent: 'center',
        flexDirection: 'row'
    },
    scrollButtons: {
        flex: 'none',
        color: theme.palette.primary.main,
        marginTop: 12
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '1.2em',
    },
    tabButtonRoot: {
        minWidth: 130,
        minHeight: 40
    },
    tabLabelContainer: {
        padding: '6px 32px 0'
    },
    loadingContainer: {
        textAlign: 'center',
        width: '100%',
    },
    loadingText: {
        margin: '0px 8px 8px',
    },
}), { name: 'Showroom' });

interface Props {
    isLoading: boolean;
    selectedTabKey: ProductCategory;
    caseFirstName: string;
    isShowPrices: boolean;
    context: GPLContext;
    canUserEditContract: boolean;
    contractPackageIds: { [id: number]: number };
    availablePackages: ProductPackageUX[];
    visibleProductItems: ProductItem[];
    funeralHomeSuppliers: ProductSupplierUXWithCategory[];
    productItems: ProductItem[];
    generateLink: (category: ProductCategoryEnum) => AppRoute;
    handleChangeSelectedTabKey: (tab: ProductCategory) => void;
    openAddItemDialog: (category: ProductCategory) => void;
    handleRemovePackage: (packageId: number) => void;
    handleAddPackage: (packageId: number) => void;
    handleRemoveContractItem: (productItem: ProductContractItemUX) => void;
    onProductItemClick: (productId?: number | undefined, contractItemId?: string | undefined) => void;
    onPackageClick: (packageId: number) => void;
    onAddContractItem: (product: ProductUX, quantity: number) => void;
    onUpdateContractItemQuantity: (contractItem: ProductContractItemUX, quantity: number) => void;
}

const Showroom = (props: Props) => {
    const {
        selectedTabKey,
        caseFirstName,
        isShowPrices,
        context,
        canUserEditContract,
        contractPackageIds,
        availablePackages,
        visibleProductItems,
        funeralHomeSuppliers,
        productItems,
        isLoading,
        generateLink,
        handleChangeSelectedTabKey,
        openAddItemDialog,
        handleRemovePackage,
        handleAddPackage,
        handleRemoveContractItem,
        onProductItemClick,
        onPackageClick,
        onAddContractItem,
        onUpdateContractItemQuantity
    } = props;

    const classes = useShowroomStyles();
    const visibleCategories = useGSelector(({ productState }) => productState.funeralHomeCategories);
    const isTabVisible = (tab: GPLTabType) =>
        // always show Professional Services tab
        tab.key === ProductCategoryEnum.care_of_loved_one ||
        visibleCategories.some((visibleCat) => tab.categories.some((tabCat) => tabCat === visibleCat));

    const visibleTabs = SHOWROOM_TAB_LIST.filter(isTabVisible);
    const selectedTabIsVisible = visibleTabs.some((vt) => vt.key === selectedTabKey);

    const renderLoader = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress color="primary" />

                <Typography
                    color="secondary"
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    Loading...
                </Typography>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <ShowroomContent
                context={context}
                selectedTab={selectedTabKey}
                caseFirstName={caseFirstName}
                isShowPrices={isShowPrices}
                canUserEditContract={canUserEditContract}
                contractPackageIds={contractPackageIds}
                availablePackages={availablePackages}
                visibleProductItems={visibleProductItems}
                funeralHomeSuppliers={funeralHomeSuppliers}
                productItems={productItems}
                openAddItemDialog={openAddItemDialog}
                handleRemovePackage={handleRemovePackage}
                handleAddPackage={handleAddPackage}
                handleRemoveContractItem={handleRemoveContractItem}
                onProductItemClick={onProductItemClick}
                onPackageClick={onPackageClick}
                onAddContractItem={onAddContractItem}
                onUpdateContractItemQuantity={onUpdateContractItemQuantity}
            />
        );
    };

    return (
        <Grid item className={classes.root}>
            <AppBar position="static" className={classes.tabAppbar}>
                <Tabs
                    value={selectedTabIsVisible ? selectedTabKey : false}
                    onChange={(e, i) => handleChangeSelectedTabKey(i)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    classes={{ scrollButtons: classes.scrollButtons }}
                >
                    {visibleTabs.map(tab =>
                        <WireProps value={tab.key} key={tab.key}>
                            {(wiredProps) =>
                                <GLink
                                    to={ProductCategoryEnum[tab.key]
                                        ? generateLink(ProductCategoryEnum[tab.key])
                                        : RouteBuilder.Disabled}
                                    tabIndex={-1}
                                >
                                    <Tab
                                        label={
                                            <div className={classes.labelContainer}>
                                                {tab.label.primary}
                                            </div>
                                        }
                                        classes={{
                                            root: classNames(classes.tabButtonRoot, classes.tabLabelContainer)
                                        }}
                                        {...wiredProps}
                                    />
                                </GLink>
                            }
                        </WireProps>
                    )}
                </Tabs>
            </AppBar>
            <div className={classes.slide}>
                {isLoading
                    ? renderLoader()
                    : renderContent()
                }
            </div>
        </Grid>
    );
};

export default Showroom;
