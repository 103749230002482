import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Fade from '@mui/material/Fade';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountType, OnlineAccountDeactivateStep } from '../utils';

export interface AccessToEmailQueProps {
    caseFName: string;
    accountType: OnlineAccountType;
    onStepChange: (questionType: OnlineAccountDeactivateStep) => void;
}

const AccessToEmailQue = (props: AccessToEmailQueProps) => {
    const { caseFName, accountType, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <Fade in>
            <Grid>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    <span
                        className={classes.cursorPointer}
                        onClick={() => onStepChange(OnlineAccountDeactivateStep.LogInToAccountQueType)}
                    >
                        <IconButton color="primary" size="large">
                            <UndoIcon />
                        </IconButton>
                        Return to previous question
                    </span>
                </Typography>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.fontSize28,
                        classes.fontWeight300,
                        classes.marginTop40
                    )}
                >
                    Do you have access to {caseFName}'s email account?
                </Typography>
                <Grid container className={classes.cancelAccConfirmationBtn}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => onStepChange(OnlineAccountDeactivateStep.ResetPasswordStepsType)}
                            className={classes.margin_8}
                        >
                            YES
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => onStepChange(OnlineAccountDeactivateStep.CanNotLoginType)}
                            className={classes.margin_8}
                        >
                            NO
                        </Button>
                    </Grid>
                </Grid >
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    If you have access to the email that {caseFName} used for {accountType},
                    we can help you reset their password so you can gain access.
                </Typography>
            </Grid>
        </Fade>
    );
};

export default AccessToEmailQue;
