import { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";

import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { GatherCaseUX, QrStickerGenerateRequest, QRStickerTemplate } from "../../../shared/types";
import GDialog from "../../common/GDialog";
import QRSticker from "./QRSticker";
import Step1 from "./Step1";
import Step2 from "./Step2";
import QRStickersList, { QRStickersRenderItemParams } from "./QRStickers.list";
import { useGDispatch } from '../../../types';
import { generateQrStickerPdf } from '../../../actions/QrSticker.action';
import GGroupButtons from "../../common/GGroupButtons";
import CremainsSticker from "./CremainsSticker";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { GStyles } from "../../../styles/GStyles";

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        background: 'none',
        boxShadow: 'none',
        minHeight: 58,
        boxSizing: 'border-box',
        '& $arrowBack': {
            position: 'absolute',
            top: 12,
            left: 10,
            fontSize: 34
        },
        '& p, svg': {
            color: '#fff',
        },
        '& p': {
            fontWeight: 400,
            fontSize: '18px',
            textAlign: 'left',
            padding: 0,
            display: 'block',
            lineHeight: '30px'
        }
    },
    dialogContent: {
        padding: '20px 8px 80px !important',
        [theme.breakpoints.up(425)]: {
            padding: '20px 24px 80px !important'
        },
        '& hr': {
            borderColor: theme.palette.primary.main,
            background: 'none'
        }
    },
    printBtn: {
        textTransform: 'none',
        fontWeight: 400,
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        borderRadius: 12,
        paddingTop: 8,
        boxShadow: theme.shadows[4],
        position: 'absolute',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'max-content',
        zIndex: 2
    },
    nextBtn: {
        fontSize: 16,
        padding: '0 52px'
    },
    groupBtn: {
        minHeight: 48,
        height: 48,
        width: '33.33%',
        minWidth: 'unset',
        maxWidth: 140,

    },
    arrowBack: {}
}), { name: 'PrintQRStickersDialog' });

export enum StickerType {
    QR_5160 = 'QR_5160',
    QR_2160 = 'QR_2160',
    CREMAINS = 'CREMAINS'
}
interface Props {
    zIndex: number;
    open: boolean;
    selectedCase: GatherCaseUX;
    onClose: () => void;
}
const PrintQRStickersDialog = (props: Props) => {
    const { zIndex, open, selectedCase, onClose } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();
    const [stepNumber, setStepNumber] = useState(1);
    const [count, setCount] = useState(4);
    const [startingColumn, setStartingColumn] = useState(0);
    const [startingRow, setStartingRow] = useState(0);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const [selectedStickerType, setSelectedStickerType] = useState(StickerType.QR_5160);
    const contentRef = useRef<HTMLDivElement>(null);

    const is2160Selected = selectedStickerType === StickerType.QR_2160;
    const is5160or2160Selected = selectedStickerType === StickerType.QR_5160 || is2160Selected;

    const handlePrint = async () => {
        setIsPrinting(true);
        const content = contentRef.current?.innerHTML;
        const printContainer = document.getElementById('print-container');

        if (!content || !printContainer) {
            return;
        }

        const printStyles = `<style>
            @page {
                margin: 0.4in 0.1in 0.4in ${is2160Selected ? '0.1in' : '0.08in'};
                ${is2160Selected
                ? `height: 10in;
                   width: 4.25in;`
                : `size: letter;`}
            }

            * {
                font-family: 'system-ui', sans-serif;
            }
        </style>`;
        const req: QrStickerGenerateRequest = {
            html: printStyles + content,
            template: is2160Selected ? QRStickerTemplate.Avery2160 : QRStickerTemplate.Avery5160,
        };
        const result = await dispatch(generateQrStickerPdf(req, selectedCase.uuid));
        if (result) {
            window.open(result.stickerPdf, '_blank');
        }
        setIsPrinting(false);
    };

    const handleCountChange = (value: number) => {
        const maxCount = is2160Selected ? 17 : is5160or2160Selected ? 61 : 21;
        if ((value !== 0 && value < 1) || value > maxCount) {
            return;
        }

        setCount(parseInt(`${value}`, 10));
    };

    const closeDialog = () => {
        // reset state before closing the dialog
        setStartingColumn(0);
        setStartingRow(0);
        setCount(4);
        setStepNumber(1);
        setSelectedStickerType(StickerType.QR_5160);

        onClose();
    };

    const renderQRSticker = useCallback(({
        included,
        currentColumn,
        currentRow
    }: QRStickersRenderItemParams) => {
        if (currentColumn > startingColumn && !included) {
            return null;
        }

        let marginTop: number | undefined;
        if (currentRow !== 0 && currentRow % 4 === 0 && is2160Selected) {
            marginTop = 96;
        } else if (currentRow === 0 && (is2160Selected ? currentColumn > 0 : currentColumn > 2)) {
            marginTop = 20;
        }

        if (!included) {
            return (
                <div
                    style={{
                        visibility: 'hidden',
                        opacity: 0,
                        width: '100%',
                        marginTop
                    }}
                >
                    {is5160or2160Selected
                        ? <QRSticker selectedCase={selectedCase} printMode={true} />
                        : <CremainsSticker selectedCase={selectedCase} printMode={true} />
                    }
                </div>
            );
        }

        return (
            is5160or2160Selected
                ? <QRSticker
                    selectedCase={selectedCase}
                    printMode={true}
                    rootStyles={{ marginTop: marginTop }}
                />
                : <CremainsSticker
                    selectedCase={selectedCase}
                    printMode={true}
                    rootStyles={{ marginTop: currentRow === 0 && currentColumn > 1 ? 20 : undefined }}
                />
        );
    }, [selectedCase, is5160or2160Selected, startingColumn, is2160Selected]);

    const onStickerTypeChange = useCallback((type: StickerType) => {
        setSelectedStickerType(type);
        // resetting the settings for Step-2
        setCount(4);
        setStartingRow(0);
        setStartingColumn(0);
    }, []);

    const handleStartingPointChange = useCallback((row: number, column: number) => {
        setStartingRow(row);
        setStartingColumn(column);
    }, []);

    const groupButtons = useMemo(() => ([{
        value: StickerType.QR_5160,
        label: <div style={{ lineHeight: 1.125 }} className={GStyles.flexColumnCentered}>
            <span>QR Sticker</span>
            <span
                className={classNames(
                    GStyles.fontSize10,
                    GStyles.fontWeight400,
                    GStyles.textTransformNone
                )}
            >
                Avery 5160 Label
            </span>
        </div>
    }, {
        value: StickerType.QR_2160,
        label: <div style={{ lineHeight: 1.125 }} className={GStyles.flexColumnCentered}>
            <span>Sticker</span>
            <span
                className={classNames(
                    GStyles.fontSize10,
                    GStyles.fontWeight400,
                    GStyles.textTransformNone
                )}
            >
                Avery 2160 Label
            </span>
        </div>
    }, {
        value: StickerType.CREMAINS,
        label: <div style={{ lineHeight: 1.125 }} className={GStyles.flexColumnCentered}>
            <span>Cremains Sticker</span>
            <span
                className={classNames(
                    GStyles.fontSize10,
                    GStyles.fontWeight400,
                    GStyles.textTransformNone
                )}
            >
                Avery 5163 Label
            </span>
        </div>
    }]), []);

    const columnSpacing = selectedStickerType === StickerType.QR_5160
        ? 8
        : selectedStickerType === StickerType.QR_2160
            ? 0
            : 18;

    return (
        <GDialog
            zIndex={zIndex}
            title={stepNumber === 1
                ? 'Print QR Stickers'
                : <ArrowBackIcon
                    className={classNames(classes.arrowBack, GStyles.cursorPointer, GStyles.colorSecondary)}
                    onClick={() => setStepNumber(1)}
                />
            }
            isOpen={open}
            titleClass={classes.dialogTitle}
            paperClass={GStyles.positionRelative}
            contentClass={classes.dialogContent}
            onClose={closeDialog}
        >
            {stepNumber === 1 &&
                <>
                    <div className={GStyles.marginBottom24}>
                        <GGroupButtons
                            buttons={groupButtons}
                            controlClasses={classes.groupBtn}
                            activeButton={selectedStickerType}
                            onClick={onStickerTypeChange}
                        />
                    </div>

                    {is5160or2160Selected
                        ? <QRSticker selectedCase={selectedCase} printMode={false} />
                        : <CremainsSticker selectedCase={selectedCase} printMode={false} />
                    }
                    <Step1 stickerType={selectedStickerType} />
                </>
            }

            {stepNumber === 2 &&
                <Step2
                    count={count}
                    startingRow={startingRow}
                    startingColumn={startingColumn}
                    stickerType={selectedStickerType}
                    onStartingPointChange={handleStartingPointChange}
                    onCountChange={handleCountChange}
                />
            }

            <div
                aria-hidden={true}
                ref={contentRef}
                style={{ display: 'none', visibility: 'hidden' }}
            >
                <div
                    style={{
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: is2160Selected ? 'column' : 'row',
                        flexWrap: 'wrap',
                        alignItems: is2160Selected ? 'center' : undefined,
                        justifyContent: is2160Selected ? 'center' : undefined,
                        width: `calc(100% + ${columnSpacing}px)`,
                        maxWidth: `calc(100% + ${columnSpacing}px)`,
                        flexBasis: '100%',
                        marginLeft: -columnSpacing
                    }}
                >
                    <QRStickersList
                        printMode={true}
                        stickerType={selectedStickerType}
                        columns={selectedStickerType === StickerType.QR_2160 ? 3 : is5160or2160Selected ? 9 : 6}
                        startingColumn={startingColumn}
                        startingRow={startingRow}
                        count={count}
                        columnSpacing={columnSpacing}
                        renderItem={renderQRSticker}
                    />
                </div>
            </div>

            <LoadingButton
                loading={isPrinting}
                variant="contained"
                className={classNames(
                    GStyles.flexColumnCentered,
                    classes.printBtn,
                    stepNumber === 1 && classes.nextBtn
                )}
                onClick={() => stepNumber === 1
                    ? setStepNumber(2)
                    : handlePrint()
                }
            >
                {stepNumber === 1 && 'Next Step'}

                {stepNumber === 2 && <>
                    <span className={GStyles.fontSize16}>Generate PDF</span>
                    <span className={GStyles.fontSize10}>PDF will download to your device</span>
                </>}
            </LoadingButton>
        </GDialog>
    );
};

export default PrintQRStickersDialog;