import * as React from 'react';
import classNames from 'classnames';

import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { TableColumnType } from '../../../types';
import makeGStyles from '../../../styles/makeGStyles';
import { HasIdProperty } from '../../../shared/types';

const useStyles = makeGStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableCell: {
        padding: '8px !important',
        '& span': {
            fontSize: 14,
        },
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        '& th': {
            color: `${theme.palette.common.white} !important`
        },
        '& $label, & $icon': {
            color: `${theme.palette.common.white} !important`,
            '&:hover': {
                color: `${theme.palette.common.white} !important`
            }
        }
    },
    label: {},
    icon: {}
}), { name: 'TableHeader' });

interface Props<T extends HasIdProperty> {
    order: 'asc' | 'desc';
    orderBy: string;
    headerColumns: TableColumnType<T>[];
    hideActionsMenu?: boolean;
    sortHandler: (
        event: React.MouseEvent<HTMLElement>,
        property: keyof T,
        isPropertyTypeString: boolean
    ) => void;
    rowClass?: string;
    color?: 'primary';
}

const TableHeader = <T extends HasIdProperty>(props: Props<T>) => {
    const { order, orderBy, headerColumns, hideActionsMenu, rowClass, color, sortHandler } = props;

    const classes = useStyles();

    const renderCell = React.useCallback((column: TableColumnType<T>) => {
        const label = (
            <Typography component="span" color={column.color || 'secondary'} className={classes.label}>
                {column.label}
            </Typography>
        );
        const sortColumn = column.dbcolumn ? column.dbcolumn : column.id;
        const sortable = (
            <TableSortLabel
                active={orderBy === sortColumn}
                direction={order}
                onClick={event => sortHandler(event, sortColumn, column.isString)}
                classes={{
                    active: classes.label,
                    icon: classes.icon
                }}
            >
                {label}
            </TableSortLabel>
        );

        return (
            <TableCell
                component="th"
                key={JSON.stringify(column.id)}
                align={column.isImage ? 'center' : column.isString ? 'left' : 'right'}
                className={classNames(classes.tableCell, column.cellClass)}
                sortDirection={orderBy === sortColumn ? order : false}
            >
                <Tooltip
                    title={column.headerTooltip || ''}
                    placement="top"
                    enterDelay={600}
                >
                    {column.disableSorting ? label : sortable}
                </Tooltip>
            </TableCell>
        );
    }, [classes.icon, classes.label, classes.tableCell, order, orderBy, sortHandler]);

    return (
        <TableHead>
            <TableRow className={classNames(rowClass, classes[color || ''])}>
                {headerColumns.map(renderCell)}
                {!hideActionsMenu &&
                    <TableCell
                        component="th"
                        key="actions_menu"
                        className={classNames(
                            classes.textCenter,
                            classes.tableCell,
                        )}
                        sortDirection={false}
                    />
                }
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
