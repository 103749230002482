import { Component } from 'react';
import Dinero from 'dinero.js';
import classNames from 'classnames';

import { debounce } from 'lodash';

import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import GestureIcon from '@mui/icons-material/Gesture';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import BankIcon from '@mui/icons-material/AccountBalance';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';

import TerminalSVG from '../svg/TerminalSVG';

import { GLOBAL_STYLED_PROPS } from '../../../../../styles';

import {
    PaymentTransaction,
    PaymentRequest,
    PaymentMethod,
    FeatureKey,
    PaymentMode,
    FeeSchedule,
    PaymentType,
} from '../../../../../shared/types';

import GGroupButtons from '../../../../common/GGroupButtons';
import { WhenEnabled, WhenDisabled, WhenAllDisabled } from '../../../../common/AppFeature';
import { Payer, calculateProcessingFeesInCents } from '..';
import { RestrictedPaymentFeature } from './RestrictedPaymentFeaturePopper';
import { convertHexToRGBA, getFormattedPhoneNumber } from '../../../../../services';
import PaymentRemotelySVG from '../../../../taskTemplates/svg/PaymentRemotelySVG';
import { toPennyValue } from '../../../../../shared/goods_and_services/pricing';
import { PatchPaymentParameters } from '../../../../../actions/Finance.action';
import { joinNameParts } from '../../../../../shared/utils';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../../styles/WithGStyles';
import DisabledPermissionTooltip from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';
import SchoolIcon from '@mui/icons-material/School';
import { SKYBLUE_COLOR } from '../../../../../constants/colorVariables';
import { getIntercomTargetProp } from '../../../../../services';

export const styles: StyleRulesCallback<Theme, PaymentCardProps> = (theme) => ({
    root: {
        position: 'relative',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        background: `${convertHexToRGBA(theme.palette.primary.main, 0.12)}`,
        boxShadow: 'inset 0px 0px 6px 0px rgba(0,0,0,0.2)',
    },
    noBorder: {
        border: 'none !important',
    },
    card: {
        borderRadius: 8,
        width: 300,
        boxShadow: 'none',
        height: 'auto',
        background: 'transparent',
        color: theme.palette.primary.main,
        border: '1px solid',
        '& $cardContent': {
            padding: 0,
        },
    },
    hidden: {
        display: 'none !important',
    },
    avatarIconButton: {
        cursor: 'auto',
        position: 'absolute',
        top: -36,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 72,
        width: 72,
        border: `2px solid ${theme.palette.background.paper}`,
        zIndex: 1,
        padding: 0,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    avatar: {
        width: '100%',
        height: '100%',
    },
    nameInitialsAvatar: {
        height: 72,
        width: 72,
        fontSize: 40,
        fontWeight: 200,
        textTransform: 'uppercase',
        '-webkit-font-smoothing': 'antialiased',
    },
    header: {
        padding: '36px 8px 12px',
        background: theme.palette.primary.main,
        '& p': {
            fontSize: 20,
            fontWeight: 200,
            lineHeight: '1.25em',
            textAlign: 'center',
            color: theme.palette.common.white,
        },
    },
    incrementorContainer: {
        margin: 0,
        padding: '32px 0 0',
    },
    label: {
        fontSize: 16,
        fontWeight: 200,
    },
    paymentModeContainer: {
        margin: '24px 0 12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& button': {
            minHeight: 28,
            height: 28,
            padding: 0,
            minWidth: 120,
        },
    },
    centerContents: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonContainer: {
        justifyContent: 'space-between',
        '& div': {
            '&:nth-child(odd)': {
                padding: '4px 4px 4px 8px',
            },
            '&:nth-child(even)': {
                padding: '4px 8px 4px 4px',
            },
        },
        '& button': {
            width: '100%',
        },
    },
    fullButton: {
        width: 'auto !important',
        margin: '4px auto 12px !important',
    },
    remotePaymentOptions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 'auto',
        paddingBottom: 6,
        '& >svg': {
            width: 280,
            marginBottom: 8,
            marginTop: -12,
        },
        '& p': {
            fontSize: 12,
        },
    },
    textField: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 160,
        '& $helperText': {
            fontSize: 10,
            lineHeight: '1.25em',
            marginTop: 2,
        },
        '& input': {
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
        },
    },
    removeButton: {
        position: 'absolute',
        top: 4,
        right: 4,
        width: 32,
        height: 32,
        '& svg': {
            fontSize: 20,
        },
    },
    svgIconCont: {
        '& svg': {
            width: 24,
        },
    },
    noInfo: {
        fontSize: 14,
        fontWeight: 200,
        textAlign: 'center',
        padding: '8px 0',
    },
    learnButton: {
        lineHeight: '2.2',
        textAlign: 'center',
        margin: '0 14px',
        backgroundColor: SKYBLUE_COLOR,
        color: theme.palette.common.white,
        borderRadius: '0px 0px 10px 10px',
        padding: '0px 12px 0px 12px',
        cursor: 'pointer',
        pointerEvents: 'auto',
        textTransform: 'none',
        fontSize: '10px',
        fontWeight: '400',
        '& svg': {
            top: 3,
            fontSize: 16,
            position: 'relative',
            margin: '0px 2px -2px -2px',
        },
    },
    removedPayer: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    helperText: {},
    primary: {},
    inverted: {},
    cardContent: {},
});

export type StyledProps = GLOBAL_STYLED_PROPS &
    WithStyles<
        | 'root'
        | 'card'
        | 'cardContent'
        | 'hidden'
        | 'avatarIconButton'
        | 'avatar'
        | 'nameInitialsAvatar'
        | 'header'
        | 'incrementorContainer'
        | 'paymentModeContainer'
        | 'buttonContainer'
        | 'label'
        | 'fullButton'
        | 'remotePaymentOptions'
        | 'textField'
        | 'footer'
        | 'removeButton'
        | 'noBorder'
        | 'helperText'
        | 'noInfo'
        | 'svgIconCont'
        | 'centerContents'
        | 'learnButton'
        | 'removedPayer'
    >;

interface RemotePaymentProps {
    payer: Payer;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    sendPaymentInstructions: () => void;
    paymentRequestSent: boolean;
    zIndex: number;
}

const RemotePaymentOptions = (props: StyledProps & RemotePaymentProps) => {
    const { classes, payer, paymentRequestSent, zIndex } = props;

    const payerEmail = payer.user ? payer.payerEntity.email : null;
    const payerPhone = payer.user ? payer.payerEntity.phone : null;
    const payerFName = payer.payerEntity.fname;
    const buttonTitle = paymentRequestSent ? 'Resend Payment Instructions' : 'Send Payment Instructions';

    return (
        <Grid item xs={12} className={classes.remotePaymentOptions}>
            <PaymentRemotelySVG themeFillClass={classes.fillPrimary} />
            {(payerEmail || payerPhone) && (
                <>
                    <Button color="primary" variant="outlined" onClick={props.sendPaymentInstructions}>
                        <SendIcon />
                        &nbsp;{buttonTitle}
                    </Button>
                    <Typography
                        variant="button"
                        component="div"
                        color="secondary"
                        className={classNames(classes.learnButton)}
                        zIndex={zIndex - 1}
                        style={{ marginBottom: '6px' }}
                        {...getIntercomTargetProp(`Payments-PaymentMethods-RemotePayment`)}
                    >
                        <SchoolIcon />
                        <span>&nbsp;Learn More</span>
                    </Typography>
                </>
            )}

            {payerEmail && <Typography color="secondary">{payerEmail}</Typography>}

            {payerPhone && <Typography color="secondary">{getFormattedPhoneNumber(payerPhone)}</Typography>}

            {!(payerEmail || payerPhone) && (
                <>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={(e) => props.onAddNonUserContactInfoClick(payer.payerEntity.id)}
                        className={classes.maxWidth280}
                    >
                        Add {payerFName}'s contact information
                    </Button>
                    <Typography
                        variant="button"
                        component="div"
                        color="secondary"
                        className={classNames(classes.learnButton)}
                        zIndex={zIndex - 1}
                        {...getIntercomTargetProp(`Payments-PaymentMethods-RemotePayment-AddContactInfo`)}
                    >
                        <SchoolIcon />
                        <span>&nbsp;Learn More</span>
                    </Typography>
                </>
            )}
        </Grid>
    );
};

const renderTooltips = (paymentMethod: PaymentMethod, payer: Payer): string => {
    switch (paymentMethod) {
        case PaymentMethod.card:
            return 'Use the Gather card reader to take payment';
        case PaymentMethod.cash:
            return `Record a cash payment from ${
                payer.user ? payer.payerEntity.fname : joinNameParts(payer.payerEntity)
            }`;
        case PaymentMethod.check:
            return `Record a check received from ${
                payer.user ? payer.payerEntity.fname : joinNameParts(payer.payerEntity)
            }`;
        case PaymentMethod.online:
            return 'Manually type in a card number';
        case PaymentMethod.plaid:
            return 'Transfer money from your bank account';
        case PaymentMethod.other:
            return 'Record a payment using a method not listed above';
        case PaymentMethod.insurance:
        case PaymentMethod.unknown:
        default:
            return '';
    }
};

const PaymentOptionButton = (props: {
    children: JSX.Element;
    tooltip: string;
    className?: string;
    onClick: (target: HTMLElement) => void;
    disabled?: boolean;
}) => {
    const { disabled, children, onClick, tooltip, className } = props;

    return (
        <Tooltip placement="top" title={disabled ? '' : tooltip} enterDelay={500} leaveDelay={200}>
            <span>
                <Button
                    disabled={disabled}
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={(e) => onClick(e.currentTarget)}
                    className={className}
                >
                    {children}
                </Button>
            </span>
        </Tooltip>
    );
};

const InPersonOptions = (
    props: StyledProps & {
        collect: (method: PaymentMethod) => void;
        payer: Payer;
        zIndex: number;
        isPayerRemovedFromCase: boolean;
        openRestrictedPaymentFeaturePopper: (restrictedFeature: RestrictedPaymentFeature) => void;
    },
) => {
    const { classes, collect, payer, isPayerRemovedFromCase, openRestrictedPaymentFeaturePopper, zIndex } = props;

    return (
        <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
            {(disabled) => (
                <Grid container
                    className={classNames(classes.buttonContainer, isPayerRemovedFromCase && classes.removedPayer)}>
                    <Grid item xs={6}>
                        <PaymentOptionButton
                            disabled={disabled}
                            onClick={() => collect(PaymentMethod.cash)}
                            tooltip={renderTooltips(PaymentMethod.cash, payer)}
                        >
                            <>
                                <LocalAtmIcon />
                                &nbsp;cash
                            </>
                        </PaymentOptionButton>
                    </Grid>

                    <Grid item xs={6}>
                        <PaymentOptionButton
                            disabled={disabled}
                            onClick={() => collect(PaymentMethod.check)}
                            tooltip={renderTooltips(PaymentMethod.check, payer)}
                        >
                            <>
                                <GestureIcon />
                                &nbsp;check
                            </>
                        </PaymentOptionButton>
                    </Grid>

                    <Grid item xs={6}>
                        <WhenEnabled feature={FeatureKey.PAYMENTS_CARD_PRESENT}>
                            <PaymentOptionButton
                                disabled={disabled}
                                onClick={() => collect(PaymentMethod.card)}
                                tooltip={renderTooltips(PaymentMethod.card, payer)}
                            >
                                <>
                                    <TerminalSVG
                                        themeFillClass={disabled ? classes.fillSecondary : classes.fillPrimary}
                                        iconStyle={{
                                            height: '1em',
                                            width: '1em',
                                            fontSize: 24,
                                        }}
                                    />
                                    &nbsp;card reader
                                </>
                            </PaymentOptionButton>
                            {!isPayerRemovedFromCase && (
                                <>
                                    <Typography
                                        variant="button"
                                        component="div"
                                        color="secondary"
                                        className={classNames(classes.learnButton)}
                                        zIndex={zIndex - 1}
                                        style={{ padding: '0 12px' }}
                                        {...getIntercomTargetProp(`Payments-PaymentMethods-CardReader-Enabled`)}
                                    >
                                        <SchoolIcon />
                                        <span>&nbsp;Learn More</span>
                                    </Typography>
                                </>)}
                        </WhenEnabled>
                        <WhenDisabled feature={FeatureKey.PAYMENTS_CARD_PRESENT}>
                            <PaymentOptionButton
                                disabled={disabled}
                                onClick={(target) => {
                                    openRestrictedPaymentFeaturePopper({
                                        anchorEle: target,
                                        feature: FeatureKey.PAYMENTS_CARD_PRESENT,
                                    });
                                }}
                                tooltip={''}
                            >
                                <>
                                    <TerminalSVG
                                        themeFillClass={classes.fillPrimary}
                                        iconStyle={{
                                            height: '1em',
                                            width: '1em',
                                            fontSize: 24,
                                        }}
                                    />
                                    &nbsp;card reader
                                </>
                            </PaymentOptionButton>
                            {!isPayerRemovedFromCase && (
                                <>
                                    <Typography
                                        variant="button"
                                        component="div"
                                        color="secondary"
                                        className={classNames(classes.learnButton)}
                                        zIndex={zIndex - 1}
                                        style={{ padding: '0 12px' }}
                                        {...getIntercomTargetProp(`Payments-PaymentMethods-CardReader-Disabled`)}
                                    >
                                        <SchoolIcon />
                                        <span>&nbsp;Learn More</span>
                                    </Typography>
                                </>)}
                        </WhenDisabled>
                    </Grid>

                    <Grid item xs={6}>
                        <WhenEnabled feature={FeatureKey.PAYMENTS_ONLINE}>
                            <PaymentOptionButton
                                disabled={disabled}
                                onClick={() => collect(PaymentMethod.online)}
                                tooltip={renderTooltips(PaymentMethod.online, payer)}
                            >
                                <>
                                    <KeyboardIcon />
                                    &nbsp;enter card
                                </>
                            </PaymentOptionButton>
                            {!isPayerRemovedFromCase && (
                                <>
                                    <Typography
                                        variant="button"
                                        component="div"
                                        color="secondary"
                                        className={classNames(classes.learnButton)}
                                        zIndex={zIndex - 1}
                                        style={{ padding: '0 12px' }}
                                        {...getIntercomTargetProp(`Payments-PaymentMethods-EnterCard-Enabled`)}
                                    >
                                        <SchoolIcon />
                                        <span>&nbsp;Learn More</span>
                                    </Typography>
                                </>)}
                        </WhenEnabled>
                        <WhenDisabled feature={FeatureKey.PAYMENTS_ONLINE}>
                            <PaymentOptionButton
                                disabled={disabled}
                                onClick={(target) => {
                                    openRestrictedPaymentFeaturePopper({
                                        anchorEle: target,
                                        feature: FeatureKey.PAYMENTS_ONLINE,
                                    });
                                }}
                                tooltip={''}
                            >
                                <>
                                    <KeyboardIcon />
                                    &nbsp;enter card
                                </>
                            </PaymentOptionButton>
                            {!isPayerRemovedFromCase && (
                                <>
                                    <Typography
                                        variant="button"
                                        component="div"
                                        color="secondary"
                                        className={classNames(classes.learnButton)}
                                        zIndex={zIndex - 1}
                                        style={{ padding: '0 12px' }}
                                        {...getIntercomTargetProp(`Payments-PaymentMethods-EnterCard-Disabled`)}
                                    >
                                        <SchoolIcon />
                                        <span>&nbsp;Learn More</span>
                                    </Typography>
                                </>)}
                        </WhenDisabled>
                    </Grid>

                    <Grid item xs={12} className={classNames(classes.flexCentred, classes.padding0)}>
                        <PaymentOptionButton
                            disabled={disabled}
                            tooltip={renderTooltips(PaymentMethod.other, payer)}
                            className={classes.fullButton}
                            onClick={() => collect(PaymentMethod.other)}
                        >
                            <>
                                <AttachMoneyIcon />
                                &nbsp;other method
                            </>
                        </PaymentOptionButton>
                    </Grid>
                </Grid>
            )}
        </DisabledPermissionTooltip>
    );
};

const RemotePayerCard = (
    props: StyledProps & {
        amount: number;
        feeSchedule: FeeSchedule | null;
        collect: (method: PaymentMethod, locked: boolean) => void;
        payer: Payer;
        assigneeName: string;
    },
) => {
    const { classes, collect, payer, amount, feeSchedule, assigneeName } = props;
    // Calculate the fees for each payment type
    const cardFee = calculateProcessingFeesInCents(amount, feeSchedule, PaymentMethod.online) / 100.0;
    const bankFee = calculateProcessingFeesInCents(amount, feeSchedule, PaymentMethod.plaid) / 100.0;
    return (
        <Grid container className={classes.buttonContainer}>
            <Grid item xs={6} className={classes.centerContents}>
                <WhenEnabled feature={FeatureKey.PAYMENTS_ONLINE}>
                    <PaymentOptionButton
                        onClick={() => collect(PaymentMethod.online, true)}
                        tooltip={renderTooltips(PaymentMethod.online, payer)}
                    >
                        <>
                            <KeyboardIcon />
                            &nbsp;credit card
                            <br />
                            +&nbsp;${cardFee.toFixed(2)}
                        </>
                    </PaymentOptionButton>
                </WhenEnabled>
            </Grid>
            <Grid item xs={6} className={classes.centerContents}>
                <WhenEnabled feature={FeatureKey.PAYMENTS_ACH}>
                    <PaymentOptionButton
                        onClick={() => collect(PaymentMethod.plaid, true)}
                        tooltip={renderTooltips(PaymentMethod.plaid, payer)}
                    >
                        <>
                            <BankIcon />
                            &nbsp;transfer
                            <br />
                            +&nbsp;${bankFee.toFixed(2)}
                        </>
                    </PaymentOptionButton>
                </WhenEnabled>
            </Grid>
            <WhenAllDisabled features={[FeatureKey.PAYMENTS_ONLINE, FeatureKey.PAYMENTS_ACH]}>
                <Typography color="secondary" align="center" className={classes.label}>
                    Please contact {assigneeName} to make your payment.
                </Typography>
            </WhenAllDisabled>
        </Grid>
    );
};

interface PaymentCardProps {
    isFamilyUser: boolean | null;
    feeSchedule: FeeSchedule | null;
    totalDebits: number;
    isFirstEle: boolean;
    isInPersonMode: boolean;
    payer: Payer;
    payment: PaymentTransaction;
    isHelperText?: boolean;
    caseUuid: string;
    funeralHomeCaseId: number;
    zIndex: number;
    isPayerRemovedFromCase: boolean;
    showPaymentDialog: (paymentInfo: PaymentRequest, payer: Payer, locked: boolean) => void;
    patchPayment: (...params: PatchPaymentParameters) => void;
    deletePayment: (paymentId: number) => void;
    openRestrictedPaymentFeaturePopper: (restrictedFeature: RestrictedPaymentFeature) => void;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    patchPaymentMode: (paymentId: number, mode: PaymentMode) => void;
}

interface State {
    amount: string;
    paymentMode: PaymentMode;
    paymentRequestSent: boolean;
    validationError: string | null;
}

class PaymentCardComponent extends Component<PaymentCardProps & StyledProps, State> {
    debounceUpdatePayment: (paymentInfo: PaymentRequest) => void = debounce(this.updatePayment.bind(this), 1000);

    constructor(props: PaymentCardProps & StyledProps) {
        super(props);
        this.state = {
            amount: (props.payment ? props.payment.credit / 100 : 0).toFixed(2),
            paymentMode: props.payment ? props.payment.mode : PaymentMode.IN_PERSON,
            paymentRequestSent: !!(props.payment && props.payment.mode === PaymentMode.REMOTE),
            validationError: null,
        };
    }

    getPaymentInfo = (method: PaymentMethod, amount: string = this.state.amount): PaymentRequest => {
        const { payment, funeralHomeCaseId } = this.props;

        const value = toPennyValue(amount);

        return {
            payment_id: payment.payment_id,
            payer_id: payment.payer_id,
            payer_name: '',
            method,
            mode: payment.mode,
            type: PaymentType.CASE,
            amount: value,
            merch_fee: Dinero({ amount: 0, currency: 'USD' }),
            memo: payment.description || '',
            funeralHomeCaseId,
            is_anon: false,
            external_id: payment.external_id || '',
        };
    };

    changeAmount(amount: string) {
        const { payment } = this.props;
        const caseId = payment.gather_case_id;
        this.setState({ amount });
        if (!caseId) {
            // Should never get here without a case in scope, but...
            return;
        }
        const paymentInfo: PaymentRequest = this.getPaymentInfo(payment.method, amount);
        if (payment) {
            // Existing payment, needs to be updated
            this.debounceUpdatePayment(paymentInfo);
        }
    }

    updatePayment(paymentInfo: PaymentRequest) {
        const { payment, caseUuid } = this.props;
        if (payment) {
            this.props.patchPayment({ caseUuid, paymentInfo, collect: false, sendNotification: true });
        }
    }

    showPaymentDialog(method: PaymentMethod, locked: boolean = false) {
        const { payer } = this.props;

        const paymentInfo: PaymentRequest = this.getPaymentInfo(method);
        this.props.showPaymentDialog(paymentInfo, payer, locked);
    }

    togglePaymentMode = (paymentMode: PaymentMode) => {
        this.setState({ paymentMode });
        if (paymentMode === PaymentMode.IN_PERSON) {
            this.props.patchPaymentMode(this.props.payment.payment_id, paymentMode);
        }
    };

    sendPaymentInstructions = () => {
        // Validate that at least $5.00 has been requested
        const minimum = Dinero({ amount: 500, currency: 'USD' });
        const amount = toPennyValue(this.state.amount);
        if (amount.lessThan(minimum)) {
            this.setState({ validationError: 'Amount must be at least $5.00' });
        } else {
            this.setState({ validationError: null });
            this.setState({ paymentRequestSent: true });
            this.props.patchPaymentMode(this.props.payment.payment_id, PaymentMode.REMOTE);
        }
    };

    render() {
        const {
            classes,
            payer,
            payment,
            isFirstEle,
            totalDebits,
            isFamilyUser,
            feeSchedule,
            openRestrictedPaymentFeaturePopper,
            isHelperText,
            isPayerRemovedFromCase,
        } = this.props;
        const { amount, paymentMode, paymentRequestSent, validationError } = this.state;
        const pennyAmount = toPennyValue(amount).getAmount();
        const paymentPercentile = ((pennyAmount / totalDebits) * 100).toFixed(0);

        if (isFamilyUser) {
            return (
                <div className={classNames(classes.root,
                    isFirstEle && classes.noBorder,
                    isPayerRemovedFromCase && classes.removedPayer)}>
                    <Grid item xs={12} className={classes.incrementorContainer}>
                        <Typography color="secondary" align="center" className={classes.label}>
                            Requested payment Amount
                        </Typography>
                        <TextField
                            type="number"
                            disabled
                            helperText={`${paymentPercentile}% of Total Contract Value`}
                            className={classes.textField}
                            defaultValue={((payment.credit + payment.debit) / 100).toFixed(2)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.colorSecondary}>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                classes: { root: classes.helperText },
                            }}
                        />
                    </Grid>
                    <RemotePayerCard
                        {...this.props}
                        amount={pennyAmount}
                        feeSchedule={feeSchedule}
                        assigneeName={payment.creator_fname}
                        collect={(method: PaymentMethod, isRestricted: boolean) => {
                            return this.showPaymentDialog(method, isRestricted);
                        }}
                    />
                </div>
            );
        }

        const customHelperText =
            validationError !== null
                ? validationError
                : isHelperText
                ? `${paymentPercentile}% of Transaction`
                : `${paymentPercentile}% of Total Contract Value`;
        return (
            <div className={classNames(classes.root, isFirstEle && classes.noBorder)}>
                <Grid item xs={12} className={classes.incrementorContainer}>
                    <DisabledPermissionTooltip permission={Permission.REMOVE_PAYMENTS}>
                        {(disabled) => (
                            <IconButton
                                color="primary"
                                onClick={() => this.props.deletePayment(payment.payment_id)}
                                aria-label="Delete Payment"
                                className={classes.removeButton}
                                size="large"
                                disabled={disabled || isPayerRemovedFromCase}
                            >
                                <ClearIcon />
                            </IconButton>
                        )}
                    </DisabledPermissionTooltip>

                    <Typography color="secondary" align="center" className={classes.label}>
                        How much will&nbsp;
                        <span className={classes.textCapitalize}>{payer.payerEntity.fname}</span>
                        &nbsp;pay?
                    </Typography>

                    <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                        {(disabled) => (
                            <TextField
                                type="number"
                                helperText={customHelperText}
                                error={validationError !== null}
                                className={classes.textField}
                                disabled={disabled || isPayerRemovedFromCase}
                                defaultValue={((payment.credit + payment.debit) / 100).toFixed(2)}
                                onChange={(e) => this.changeAmount(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.colorSecondary}>
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                FormHelperTextProps={{
                                    classes: { root: classes.helperText },
                                }}
                            />
                        )}
                    </DisabledPermissionTooltip>
                </Grid>

                <Grid item xs={12} className={classes.paymentModeContainer}>
                    <Typography color="secondary" align="center" className={classes.label}>
                        How will&nbsp;
                        <span className={classes.textCapitalize}>{payer.payerEntity.fname}</span>
                        &nbsp;pay?
                    </Typography>

                    <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                        {(disabled) => (
                            <div>
                                <WhenEnabled feature={FeatureKey.PAYMENTS_PAY_REMOTELY}>
                                    <GGroupButtons
                                        buttons={[
                                            {
                                                label: 'in person',
                                                value: PaymentMode.IN_PERSON,
                                            },
                                            {
                                                label: 'remotely',
                                                value: PaymentMode.REMOTE,
                                            },
                                        ]}
                                        disabled={disabled || isPayerRemovedFromCase}
                                        activeButton={paymentMode}
                                        onClick={this.togglePaymentMode}
                                        size={'small'}
                                    />
                                </WhenEnabled>
                                <WhenDisabled feature={FeatureKey.PAYMENTS_PAY_REMOTELY}>
                                    <GGroupButtons
                                        buttons={[
                                            {
                                                label: 'in person',
                                                value: PaymentMode.IN_PERSON,
                                            },
                                            {
                                                label: 'remotely',
                                                value: PaymentMode.REMOTE,
                                            },
                                        ]}
                                        disabled={disabled || isPayerRemovedFromCase}
                                        activeButton={PaymentMode.IN_PERSON}
                                        onClick={(v, target) => {
                                            if (v === PaymentMode.REMOTE) {
                                                openRestrictedPaymentFeaturePopper({
                                                    anchorEle: target,
                                                    feature: FeatureKey.PAYMENTS_PAY_REMOTELY,
                                                });
                                            }
                                        }}
                                        size={'small'}
                                    />
                                </WhenDisabled>
                            </div>
                        )}
                    </DisabledPermissionTooltip>
                </Grid>
                {paymentMode === PaymentMode.IN_PERSON ? (
                    <InPersonOptions
                        {...this.props}
                        collect={(method: PaymentMethod) => this.showPaymentDialog(method)}
                        openRestrictedPaymentFeaturePopper={openRestrictedPaymentFeaturePopper}
                    />
                ) : (
                    <RemotePaymentOptions
                        {...this.props}
                        sendPaymentInstructions={this.sendPaymentInstructions}
                        paymentRequestSent={paymentRequestSent}
                    />
                )}
            </div>
        );
    }
}

export const PaymentCard = withGStyles(styles)(PaymentCardComponent);
