import WaterIcon from "@mui/icons-material/Water";
import TempleBuddhistIcon from "@mui/icons-material/TempleBuddhist";
import SynagogueIcon from "@mui/icons-material/Synagogue";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import NatureIcon from "@mui/icons-material/Nature";
import MosqueIcon from "@mui/icons-material/Mosque";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import GroupsIcon from "@mui/icons-material/Groups";
import GrassIcon from "@mui/icons-material/Grass";
import GppGoodIcon from "@mui/icons-material/GppGood";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import DiamondIcon from "@mui/icons-material/Diamond";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CottageIcon from "@mui/icons-material/Cottage";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CleanHandsIcon from "@mui/icons-material/CleanHands";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import CheckIcon from "@mui/icons-material/Check";
import CampaignIcon from "@mui/icons-material/Campaign";
import BuildIcon from "@mui/icons-material/Build";
import BoyIcon from "@mui/icons-material/Boy";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import BackupIcon from "@mui/icons-material/Backup";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AttributionIcon from "@mui/icons-material/Attribution";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirlineSeatFlatAngledIcon from "@mui/icons-material/AirlineSeatFlatAngled";
import AirlineSeatFlatIcon from "@mui/icons-material/AirlineSeatFlat";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AddchartIcon from "@mui/icons-material/Addchart";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PrintIcon from "@mui/icons-material/Print";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import SoapIcon from "@mui/icons-material/Soap";
import { SvgIconComponent } from "@mui/icons-material";
import { TrackingStepIcon } from "../../../../../shared/types";

export const TrackingStepIconDisplayLookup: Record<TrackingStepIcon, string> = {
    [TrackingStepIcon.AddTask]: 'Task',
    [TrackingStepIcon.Check]: 'Check',
    [TrackingStepIcon.HealthAndSafety]: 'Health',
    [TrackingStepIcon.GppGood]: 'Approved',
    [TrackingStepIcon.Edit]: 'Edit',
    [TrackingStepIcon.AssignmentTurnedIn]: 'Checkmark',
    [TrackingStepIcon.Settings]: 'Settings',
    [TrackingStepIcon.Add]: 'Add',
    [TrackingStepIcon.AddAPhoto]: 'Add Photo',
    [TrackingStepIcon.AirplanemodeActive]: 'Airplane',
    [TrackingStepIcon.AddAlarm]: 'Alarm',
    [TrackingStepIcon.AttachFile]: 'Attachment',
    [TrackingStepIcon.Water]: 'Aquamation',
    [TrackingStepIcon.Bloodtype]: 'Blood',
    [TrackingStepIcon.TempleBuddhist]: 'Buddhist temple',
    [TrackingStepIcon.ArrowDownward]: 'Burial',
    [TrackingStepIcon.Event]: 'Calender',
    [TrackingStepIcon.SaveAlt]: 'Casketing',
    [TrackingStepIcon.Grass]: 'Cemetery',
    [TrackingStepIcon.Addchart]: 'Chart',
    [TrackingStepIcon.Backup]: 'Cloud',
    [TrackingStepIcon.AddToQueue]: 'Computer',
    [TrackingStepIcon.AcUnit]: 'Cooler',
    [TrackingStepIcon.Fireplace]: 'Cremate 1',
    [TrackingStepIcon.LocalFireDepartment]: 'Cremate 2',
    [TrackingStepIcon.AssignmentLate]: 'Critical',
    [TrackingStepIcon.AssignmentInd]: 'Death Photo',
    [TrackingStepIcon.Delete]: 'Delete',
    [TrackingStepIcon.Diamond]: 'Diamond',
    [TrackingStepIcon.Coronavirus]: 'Disease',
    [TrackingStepIcon.Checkroom]: 'Dressing',
    [TrackingStepIcon.Accessibility]: 'Embalming',
    [TrackingStepIcon.FileCopy]: 'File',
    [TrackingStepIcon.Fingerprint]: 'Fingerprint',
    [TrackingStepIcon.AssistantPhoto]: 'Flag',
    [TrackingStepIcon.ContentCut]: 'Hair Sample',
    [TrackingStepIcon.Cottage]: 'Home',
    [TrackingStepIcon.AllInbox]: 'Inbox',
    [TrackingStepIcon.ColorLens]: 'Makeup',
    [TrackingStepIcon.MilitaryTech]: 'Military',
    [TrackingStepIcon.CurrencyExchange]: 'Money',
    [TrackingStepIcon.Mosque]: 'Mosque',
    [TrackingStepIcon.Campaign]: 'Notify',
    [TrackingStepIcon.Nature]: 'Outside',
    [TrackingStepIcon.Favorite]: 'Pacemaker',
    [TrackingStepIcon.AccountCircle]: 'Person 1',
    [TrackingStepIcon.Attribution]: 'Person 2',
    [TrackingStepIcon.Boy]: 'Person 3',
    [TrackingStepIcon.SettingsAccessibility]: 'Person 4',
    [TrackingStepIcon.Soap]: 'Prep',
    [TrackingStepIcon.AirlineSeatFlat]: 'Prep Room 1',
    [TrackingStepIcon.AirlineSeatFlatAngled]: 'Prep Room 2',
    [TrackingStepIcon.Print]: 'Printer',
    [TrackingStepIcon.AutoStories]: 'Record',
    [TrackingStepIcon.AddRoad]: 'Road',
    [TrackingStepIcon.CleanHands]: 'Sanitize',
    [TrackingStepIcon.Build]: 'Settings',
    [TrackingStepIcon.Synagogue]: 'Synagogue',
    [TrackingStepIcon.Groups]: 'Groups',
    [TrackingStepIcon.DeviceThermostat]: 'Temperature',
    [TrackingStepIcon.AirportShuttle]: 'Van'
};

export const TrackingStepIconLookup: Record<TrackingStepIcon, SvgIconComponent> = {
    [TrackingStepIcon.AddTask]: AddTaskIcon,
    [TrackingStepIcon.Check]: CheckIcon,
    [TrackingStepIcon.HealthAndSafety]: HealthAndSafetyIcon,
    [TrackingStepIcon.GppGood]: GppGoodIcon,
    [TrackingStepIcon.Edit]: EditIcon,
    [TrackingStepIcon.AssignmentTurnedIn]: AssignmentTurnedInIcon,
    [TrackingStepIcon.Settings]: SettingsIcon,
    [TrackingStepIcon.Add]: AddIcon,
    [TrackingStepIcon.AddAPhoto]: AddAPhotoIcon,
    [TrackingStepIcon.AirplanemodeActive]: AirplanemodeActiveIcon,
    [TrackingStepIcon.AddAlarm]: AddAlarmIcon,
    [TrackingStepIcon.AttachFile]: AttachFileIcon,
    [TrackingStepIcon.Water]: WaterIcon,
    [TrackingStepIcon.Bloodtype]: BloodtypeIcon,
    [TrackingStepIcon.TempleBuddhist]: TempleBuddhistIcon,
    [TrackingStepIcon.ArrowDownward]: ArrowDownwardIcon,
    [TrackingStepIcon.Event]: EventIcon,
    [TrackingStepIcon.SaveAlt]: SaveAltIcon,
    [TrackingStepIcon.Grass]: GrassIcon,
    [TrackingStepIcon.Addchart]: AddchartIcon,
    [TrackingStepIcon.Backup]: BackupIcon,
    [TrackingStepIcon.AddToQueue]: AddToQueueIcon,
    [TrackingStepIcon.AcUnit]: AcUnitIcon,
    [TrackingStepIcon.Fireplace]: FireplaceIcon,
    [TrackingStepIcon.LocalFireDepartment]: LocalFireDepartmentIcon,
    [TrackingStepIcon.AssignmentLate]: AssignmentLateIcon,
    [TrackingStepIcon.AssignmentInd]: AssignmentIndIcon,
    [TrackingStepIcon.Delete]: DeleteIcon,
    [TrackingStepIcon.Diamond]: DiamondIcon,
    [TrackingStepIcon.Coronavirus]: CoronavirusIcon,
    [TrackingStepIcon.Checkroom]: CheckroomIcon,
    [TrackingStepIcon.Accessibility]: AccessibilityIcon,
    [TrackingStepIcon.FileCopy]: FileCopyIcon,
    [TrackingStepIcon.Fingerprint]: FingerprintIcon,
    [TrackingStepIcon.AssistantPhoto]: AssistantPhotoIcon,
    [TrackingStepIcon.ContentCut]: ContentCutIcon,
    [TrackingStepIcon.Cottage]: CottageIcon,
    [TrackingStepIcon.AllInbox]: AllInboxIcon,
    [TrackingStepIcon.ColorLens]: ColorLensIcon,
    [TrackingStepIcon.MilitaryTech]: MilitaryTechIcon,
    [TrackingStepIcon.CurrencyExchange]: CurrencyExchangeIcon,
    [TrackingStepIcon.Mosque]: MosqueIcon,
    [TrackingStepIcon.Campaign]: CampaignIcon,
    [TrackingStepIcon.Nature]: NatureIcon,
    [TrackingStepIcon.Favorite]: FavoriteIcon,
    [TrackingStepIcon.AccountCircle]: AccountCircleIcon,
    [TrackingStepIcon.Attribution]: AttributionIcon,
    [TrackingStepIcon.Boy]: BoyIcon,
    [TrackingStepIcon.SettingsAccessibility]: SettingsAccessibilityIcon,
    [TrackingStepIcon.Soap]: SoapIcon,
    [TrackingStepIcon.AirlineSeatFlat]: AirlineSeatFlatIcon,
    [TrackingStepIcon.AirlineSeatFlatAngled]: AirlineSeatFlatAngledIcon,
    [TrackingStepIcon.Print]: PrintIcon,
    [TrackingStepIcon.AutoStories]: AutoStoriesIcon,
    [TrackingStepIcon.AddRoad]: AddRoadIcon,
    [TrackingStepIcon.CleanHands]: CleanHandsIcon,
    [TrackingStepIcon.Build]: BuildIcon,
    [TrackingStepIcon.Synagogue]: SynagogueIcon,
    [TrackingStepIcon.Groups]: GroupsIcon,
    [TrackingStepIcon.DeviceThermostat]: DeviceThermostatIcon,
    [TrackingStepIcon.AirportShuttle]: AirportShuttleIcon,
};