import * as React from 'react';
import classNames from 'classnames';

import { compose } from 'redux';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import Clear from '@mui/icons-material/Clear';
import Send from '@mui/icons-material/Send';

import { StoreState } from '../../../types';

import { UserCreateRequest, UserRole, UserRoles } from '../../../shared/types';
import { isValidEmail, splitFullName } from '../../../shared/utils';

import { setTeamInvitationSuccess, inviteTeamMember } from '../../../actions/Team.action';
import { openAccessRestrictedDialog } from '../../../actions/AccessRestricted.action';
import { closeTeamInvitationDialog, openMySettingsDialog } from '../../../actions/Dialog.action';
import { TeamUserSettingsTab } from '../mySettings/TeamUserSettings';
import { canManageFuneralHomeUserPermission } from '../../../shared/authority/can';
import FuneralHomeLogo from '../../common/FuneralHomeLogo';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { AppDispatch } from '../../../store';
import { SlideTransition } from '../../common/Transitions';
import { green } from '@mui/material/colors';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            width: '100%',
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            '@media (min-width: 960px)': {
                maxWidth: 720,
                minWidth: 720,
                margin: 32,
                maxHeight: '90vh',
            },
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    dialogPaper: {},
    button: {
        margin: theme.spacing(),
    },
    header: {
        zIndex: 1,
        boxShadow: `0 1px 10px 1px rgb(0 0 0 / 20%)`,
        padding: 4,
        display: 'flex',
        justifyContent: 'center',
        minHeight: 56,
    },
    clearIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        fontSize: 28,
        color: '#5B6E74',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    width100: {
        width: '100%',
    },
    textLeft: {
        textAlign: 'left',
    },
    themeButton: {
        color: '#fff',
        width: '90%',
    },
    overflowHidden: {
        overflow: 'hidden'
    },
    marginTop10: {
        marginTop: 10
    },
    group: {
        margin: `${theme.spacing()} 0`,
    },
    dialogContent: {
        background: '#fafafa',
        zIndex: 0,
        padding: '0 0 24px',
    },
    formOuter: {
        maxWidth: 280,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 220,
        },
    },
    textCapitalize: {
        textTransform: 'capitalize',
    },
    caseAsignee: {
        color: theme.palette.secondary.main,
        fontWeight: 'normal',
        fontSize: 14,
        padding: '0 40px',
    },
    pullRight: {
        float: 'right',
    },
    inputWithError: {
        minHeight: 68,
    },
    displayContents: {
        display: 'contents',
    },
    marginLeft10: {
        marginLeft: 10,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'button' | 'header' | 'clearIcon' | 'width100' | 'textLeft'
    | 'themeButton' | 'overflowHidden' | 'marginTop10' | 'group' | 'dialogContent' | 'dialogPaper'
    | 'formOuter' | 'textCapitalize' | 'caseAsignee' | 'pullRight' | 'buttonProgress'
    | 'inputWithError' | 'displayContents' | 'marginLeft10'>;

function mapStateToProps({ userSession, teamState, funeralHomeState, dialogState }: StoreState) {
    const { isOpen, zIndex, onAddedCallback } = dialogState.teamInvitation;

    return {
        userSession,
        teamState,
        funeralHome: funeralHomeState.activeFuneralHome,
        isOpen,
        zIndex,
        onAddedCallback
    };
}

type Props = ReturnType<typeof mapStateToProps> & {
    dispatch: AppDispatch;
};

interface State {
    fullName: string;
    email: string;
    inviteAttempted: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

const emptyState: State = {
    fullName: '',
    email: '',
    inviteAttempted: false,
};

class InviteTeamMemberDialog extends React.Component<StyledProps & DialogProps, State> {
    state: State = emptyState;

    componentDidUpdate(prevProps: Props) {
        const { teamState, isOpen, dispatch } = this.props;
        const { isInvitationSuccess } = teamState;

        if (isOpen && isOpen !== prevProps.isOpen && isInvitationSuccess) {
            dispatch(setTeamInvitationSuccess(false));
        }
    }

    render() {
        const { classes, fullScreen, teamState, isOpen, zIndex } = this.props;
        const { isInvitationSuccess } = teamState;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
                classes={{
                    paper: classes.dialogPaper
                }}
                style={{ zIndex }}
            >
                {isOpen && <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.header}
                >
                    <Clear className={classes.clearIcon} onClick={this.handleClose} />
                    <FuneralHomeLogo />
                </DialogTitle>}

                {(!isInvitationSuccess && isOpen) && this.renderInviteNewTeamMemberForm()}
            </Dialog>
        );
    }

    private clearForm = () => {
        const { dispatch } = this.props;

        dispatch(setTeamInvitationSuccess(false));
        this.setState(emptyState);
    };

    private handleClose = () => {
        const { dispatch } = this.props;

        this.clearForm();
        dispatch(closeTeamInvitationDialog());
    };

    private isEmailValid = () => {
        const { email } = this.state;

        const isEmailValid = email.length > 0 && isValidEmail(email);
        return isEmailValid;
    };

    private isFullNameValid = () => {
        const { fullName } = this.state;
        const { fname, lname } = splitFullName(fullName);
        return fname.trim().length !== 0 && lname.trim().length !== 0;
    };

    private inviteNewTeamMember = async () => {
        const { funeralHome, dispatch, zIndex, userSession, onAddedCallback } = this.props;
        const { fullName, email } = this.state;

        if (!this.isFullNameValid() || !this.isEmailValid()) {
            this.setState({ inviteAttempted: true });
            return;
        }

        // RESTRICT ACCESS
        if (funeralHome && funeralHome.is_demo && !funeralHome.features.DEMO_ALLOW_TEAM_INVITES.enabled) {
            dispatch(openAccessRestrictedDialog(zIndex + 1));
            return;
        }
        const { fname, mname, lname } = splitFullName(fullName);
        const funeralHomeId = funeralHome ? funeralHome.id : undefined;
        const teamMember: UserCreateRequest = {
            fname: fname,
            mname: mname,
            lname: lname,
            role: funeralHomeId ? UserRole.FHUser : UserRole.GOMUser,
            email,
            phone: null,
            home_address: null,
            entity_id: null,
            use_address_description: false,
            password: null,
        };
        const currentPerson = await dispatch(inviteTeamMember(teamMember, funeralHomeId));
        if (currentPerson) {
            const isFHUser = UserRoles.isFHUser(currentPerson);
            const canUpdatePermissions = canManageFuneralHomeUserPermission(
                userSession.userData, funeralHomeId || null
            );

            if (onAddedCallback) {
                onAddedCallback(currentPerson);
            }
            dispatch(openMySettingsDialog(
                zIndex + 1,
                currentPerson.entity_id,
                isFHUser ? canUpdatePermissions ? TeamUserSettingsTab.permissions
                    : TeamUserSettingsTab.communications
                    : undefined
            ));
            this.handleClose();
        }
    };

    private renderSecureLinkText = () => {
        const { fullName } = this.state;
        const { classes } = this.props;
        const { fname } = splitFullName(fullName);
        const paraInitials = fname.length === 0 ? <span>Team Member</span> :
            <span className={classes.textCapitalize}>{fname}</span>;

        return (
            <>
                {paraInitials}
                <span>
                    {' will receive a secure link with easy-to-follow instructions.'}
                </span>
            </>
        );
    };

    private renderInviteNewTeamMemberForm = () => {
        const { classes, teamState } = this.props;
        const {
            inviteAttempted,
            fullName,
            email,
        } = this.state;

        const isEmailValid = this.isEmailValid();
        const isFNameValid = this.isFullNameValid();
        const { fname } = splitFullName(fullName);
        return (
            <DialogContent className={classes.dialogContent}>
                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={4}
                    className={classes.formOuter}
                >
                    <form className={classes.overflowHidden} autoComplete="off">
                        <FormControl
                            className={classNames(
                                classes.width100,
                                classes.textLeft,
                                classes.marginTop10,
                                classes.inputWithError
                            )}
                        >
                            <TextField
                                required
                                error={inviteAttempted && !isFNameValid}
                                label="Team Member's Full Name"
                                id="invite_fullName"
                                name="fullName"
                                value={fullName}
                                className={classes.width100}
                                onChange={(e) => this.setState({ fullName: e.target.value })}
                                autoComplete="new-password"
                            />
                            {inviteAttempted && !isFNameValid &&
                                <FormHelperText>
                                    This field is required
                                </FormHelperText>
                            }
                        </FormControl>

                        <FormControl
                            className={classNames(
                                classes.width100,
                                classes.textLeft,
                                classes.inputWithError
                            )}
                        >
                            <TextField
                                required
                                error={inviteAttempted && (email.length === 0 || !isEmailValid)}
                                label={<span className={classes.textCapitalize}>
                                    {fname.length === 0 ?
                                        'Team Member\'s Email' : `${fname}'s Email`}
                                </span>}
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                className={classes.width100}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                autoComplete="new-password"
                            />
                            {inviteAttempted && email.length === 0 ?
                                <FormHelperText>
                                    This field is required
                                </FormHelperText> : null
                            }
                            {inviteAttempted && email.length > 0 && !isEmailValid ?
                                <FormHelperText>
                                    Oops, looks like this email is invalid
                                </FormHelperText> : null
                            }
                        </FormControl>
                    </form>
                </Grid>

                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.marginBottom20}
                >
                    <Grid item xs={12} className={classes.displayContents}>
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            disabled={teamState.isInvitationPending}
                            className={classes.pullRight}
                            onClick={this.inviteNewTeamMember}
                        >
                            {fname.length === 0 ?
                                'Invite New Team Member' : `Invite ${fname}`}
                            {teamState.isInvitationPending
                                && <CircularProgress size={24} className={classes.buttonProgress} />}
                            <Send className={classes.marginLeft10} />
                        </Button>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography
                            component="p"
                            className={classes.caseAsignee}
                            align="center"
                            noWrap={false}
                        >
                            {this.renderSecureLinkText()}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        );
    };
}

export default withState(mapStateToProps)(
    compose(withGStyles(styles), withFullScreen())(InviteTeamMemberDialog) as React.ComponentType<Props>
);
