import { useLocation } from "react-router-dom";
import { AppRoute, LoginRoutePage, RouteBuilder } from "../../services";
import GNavigate, { GNavigateProps } from "./GNavigate";

interface LoginNavigateProps extends Omit<GNavigateProps, 'to'> {
    page?: LoginRoutePage;
    destination?: AppRoute | 'currentPathname';
}

const LoginNavigate = (props: LoginNavigateProps) => {
    const { destination, page } = props;

    const { pathname, search, hash } = useLocation();

    return <GNavigate
        to={RouteBuilder.Login({
            destination: destination === 'currentPathname'
                ? `${pathname}${search}${hash}`
                : destination,
            page,
        })}
        {...props}
    />;
};

export default LoginNavigate;
