import classNames from 'classnames';
import { debounce, values } from 'lodash';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ContactsIcon from '@mui/icons-material/Contacts';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { setRolodexListDialogOpen } from '../../../../actions/Rolodex.action';

import {
    GatherCaseUX,
    DeathCertificateLifeType,
    YesNoUnknownEnum,
    VitalDates,
    DeathLocationEnum,
    GmapsSearchType,
    DeathCertificateConfigUX,
    CaseType,
    UserRoles,
    NullLongAddress,
    isLongAddress,
    ShortAddress,
    isShortAddress,
    NullShortAddress,
} from '../../../../shared/types';
import { UsStateType } from '../../../../types';

import ChangeFilingStateDialog from '../ChangeFilingState.Dialog';
import { FormParent, ParentProps } from './FormParent';
import { lifeValidators, lifeMasterValidators } from '../../../../shared/death_certificate/validators/life';
import { formatGatherDate } from '../../../../shared/utils';
import PhoneNumberMask from '../../../common/PhoneNumberMask';
import ChooseTimeZoneDialog from '../../../funeralHomes/ChooseTimeZone.dialog';
import { getTzLabel, deathOrAge, convertHexToRGBA } from '../../../../services';
import { US_STATES } from '../../../../shared/locale';
import FormTitle from '../common/FormTitle';
import ConfigurableAddressSearch from '../common/fields/addressSearch/ConfigurableAddressSearch';
import { ConfigurableLifeFieldKey } from '../../../../shared/death_certificate/validators/config';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';
import
ConfigurableAddressSearchWithCheckbox
    from '../common/fields/addressSearchWithCheckbox/ConfigurableAddressSearchWithCheckbox';
import ConfigurableSelectWrapper from '../common/fields/select/ConfigurableSelect';
import ConfigurableTimeField from '../common/fields/timeField/ConfigurableTimeField';
import ConfigurableDateField from '../common/fields/dateField/ConfigurableDateField';
import DCDateField from '../common/fields/dateField/DCDateField';
import DCToggleButtons from '../common/fields/toggleButtons/DCToggleButtons';
import DCTimeField from '../common/fields/timeField/DCTimeField';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withState from '../../../common/utilHOC/WithState';
import { AppDispatch } from '../../../../store';
import { GmapsSearchAddress } from '../../../gmapsSearch/GmapsSearch';
import { ConfigurableToggleButtonsForYesNoUnknown } from './AboutForm';
import { log } from '../../../../logger';

const ConfigurableSelect = ConfigurableSelectWrapper<DeathLocationEnum>();

enum LifeDateTimeFieldEnum {
    'dob_date' = 'dob_date',
    'dod_start_date' = 'dod_start_date',
    'dod_end_date' = 'dod_end_date',
    'dop_date' = 'dop_date',
    'dod_start_time' = 'dod_start_time',
    'dod_end_time' = 'dod_end_time',
    'dop_time' = 'dop_time',
}

interface Props {
    dispatch: AppDispatch;
    activeCase: GatherCaseUX;
    dcConfig: DeathCertificateConfigUX | null;
    updateVitalDates: (vitalDates: VitalDates) => void;
    handleOpenSnackbar: () => void;
    zIndex: number;
}

interface State {
    localData: DeathCertificateLifeType;
    isChangeFilingStateDialogOpen: boolean;
    usStateList: UsStateType[];
    birthDateValidator: boolean | null;
    deathDateValidator: boolean | null;
    deathDateRangeEndValidator: boolean | null;
    pronouncementDateValidator: boolean | null;
    pronouncementTimeValidator: boolean | null;
    birthLocationValidator: boolean | null;
    deathLocationValidator: boolean | null;
    residenceLocationValidator: boolean | null;
    cityLimitsValidator: boolean | null;
    deathLocationOptionsValidator: boolean | null;
    physicianNameValidator: boolean | null;
    physicianPhoneNumberValidator: boolean | null;
    physicianFaxNumberValidator: boolean | null;
    physicianAddressValidator: boolean | null;
    causeOfDeathValidator: boolean | null;
    yearsInCurrentCountyValidator: boolean | null;
    updatedCase: Partial<GatherCaseUX>;
    deathDateUnknown: boolean;
    whichTz: 'dod_start_tz' | 'dod_end_tz' | 'dop_tz' | null;
    tzSelected: string | null;
    hebrewDateOfBirthValidator: boolean | null;
    hebrewDateOfDeathValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateLifeType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        margin: 'auto',
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        '@media (min-width: 800px)': {
            width: 800,
        },
        padding: '0 15px'
    },
    cityLimitToggleButton: {
        padding: '4px 20px',
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    toggleButtonGroupLabel: {
        position: 'relative',
        transform: 'none',
        textAlign: 'center',
    },
    marginTop0: {
        marginTop: 0,
    },
    datePickerGrid: {
        width: 190,
        '@media (min-width: 421px)': {
            width: 280,
        },
    },
    gmapSearch: {
        width: '100%',
        maxWidth: '100%',
        '@media (min-width: 421px)': {
            width: 360,
            maxWidth: 360,
        },
    },
    calculateAge: {
        padding: 16,
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        fontSize: 18,
        fontWeight: 500,
        margin: '0 auto',
        background: `${convertHexToRGBA(theme.palette.primary.main, 0.18)}`,
        '@media (min-width: 599px)': {
            width: 450,
        }
    },
    textBoxGridWidth: {
        width: '100%',
        maxWidth: '100%',
        '@media (min-width: 420px)': {
            width: 360,
            maxWidth: 360,
        }
    },
    filingState: {
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'block',
    },
    dateTimeGrid: {
        width: '100%',
        display: 'inline-block',
        '& $datePickerGrid': {
            width: '100%',
            maxWidth: 170,
        },
        '&$dateTimeGrid:nth-child(even)': {
            paddingLeft: 10,
            textAlign: 'left',
        },
        '&$dateTimeGrid:nth-child(odd)': {
            paddingRight: 10,
            textAlign: 'right',
        },
        '@media (max-width: 350px)': {
            '& input': {
                marginRight: '-10px',
            }
        },
    },
    deathDateContent: {
        padding: '20px 4px',
        margin: '0px -4px',
        borderRadius: '12px',
    },
    adronmentIconButton: {
        width: 40,
        height: 40,
        '& svg': {
            fontSize: 22
        }
    },
    displayFlex: {
        display: 'flex',
    },
});

type StyledProps = Props & WithGStyles<'root' | 'inviteHelperForm' | 'cityLimitToggleButton'
    | 'toggleButtonGroupLabel' | 'marginTop0' | 'datePickerGrid' | 'gmapSearch' | 'calculateAge'
    | 'textBoxGridWidth' | 'filingState' | 'dateTimeGrid' | 'deathDateContent' | 'adronmentIconButton'
    | 'displayFlex'>;

class LifeForm extends FormParent<DeathCertificateLifeType, StyledProps, State> {

    state: State = {
        localData: this.props.data,
        birthDateValidator: null,
        deathDateValidator: null,
        deathDateRangeEndValidator: null,
        pronouncementDateValidator: null,
        pronouncementTimeValidator: null,
        birthLocationValidator: null,
        deathLocationValidator: null,
        residenceLocationValidator: null,
        cityLimitsValidator: null,
        isChangeFilingStateDialogOpen: false,
        deathLocationOptionsValidator: null,
        usStateList: US_STATES,
        physicianNameValidator: null,
        physicianPhoneNumberValidator: null,
        physicianFaxNumberValidator: true,
        physicianAddressValidator: null,
        causeOfDeathValidator: null,
        yearsInCurrentCountyValidator: null,
        updatedCase: {
            [LifeDateTimeFieldEnum.dob_date]: this.props.activeCase.dob_date,
            [LifeDateTimeFieldEnum.dod_start_date]: this.props.activeCase.dod_start_date,
            [LifeDateTimeFieldEnum.dod_start_time]: this.props.activeCase.dod_start_time,
            [LifeDateTimeFieldEnum.dod_end_date]: this.props.activeCase.dod_end_date,
            [LifeDateTimeFieldEnum.dod_end_time]: this.props.activeCase.dod_end_time,
            [LifeDateTimeFieldEnum.dop_date]: this.props.activeCase.dop_date,
            [LifeDateTimeFieldEnum.dop_time]: this.props.activeCase.dop_time,
            dod_start_tz: this.props.activeCase.dod_start_tz,
            dod_end_tz: this.props.activeCase.dod_end_tz,
            dop_tz: this.props.activeCase.dop_tz,
        },
        deathDateUnknown: this.props.activeCase.death_date_unknown,
        tzSelected: null,
        whichTz: null,
        hebrewDateOfBirthValidator: null,
        hebrewDateOfDeathValidator: null
    };

    debounceCaseUpdate = debounce(() => this.updateVitalDates(), 500);

    componentWillUnmount() {
        this.debounceCaseUpdate.flush();
    }

    updateVitalDates = () => {
        const { updatedCase } = this.state;
        this.props.updateVitalDates({
            death_date_unknown: updatedCase.death_date_unknown === undefined ? false : updatedCase.death_date_unknown,
            dob_date: updatedCase.dob_date && formatGatherDate(updatedCase.dob_date) || '',
            dod_start_date: updatedCase.dod_start_date && formatGatherDate(updatedCase.dod_start_date) || '',
            dod_start_time: updatedCase.dod_start_time || '',
            dod_start_tz: updatedCase.dod_start_tz || '',
            dod_end_date: updatedCase.dod_end_date && formatGatherDate(updatedCase.dod_end_date) || '',
            dod_end_time: updatedCase.dod_end_time || '',
            dod_end_tz: updatedCase.dod_end_tz || '',
            dop_date: updatedCase.dop_date && formatGatherDate(updatedCase.dop_date) || '',
            dop_time: updatedCase.dop_time || '',
            dop_tz: updatedCase.dop_tz || '',
        });
    };

    openFilingChangeDialog = () => {
        const { isDeathCertificateLocked } = this.props;

        if (isDeathCertificateLocked) {
            return;
        }
        this.setState({
            isChangeFilingStateDialogOpen: true,
        });
    };

    closeFilingChangeDialog = () => {
        this.setState({
            isChangeFilingStateDialogOpen: false,
        });
    };

    handleTimezoneChange = (newValue: string) => {
        const { activeCase } = this.props;
        const { whichTz } = this.state;
        if (whichTz !== null) {
            const oldValue = this.state.updatedCase[whichTz] ? this.state.updatedCase[whichTz] : activeCase[whichTz];
            if (newValue !== oldValue) {
                this.setState((prevState) => ({
                    updatedCase: { ...prevState.updatedCase, [whichTz]: newValue },
                }));
                this.debounceCaseUpdate();
            }
            // this.onChange(this.props.lifeForm);
        }
    };

    handleDateChange = (
        name: LifeDateTimeFieldEnum,
        newValue: string,
        validator?: () => void
    ) => {
        const { activeCase } = this.props;
        // If just a time value then we need to prepend a date (any date) before testing
        let addDate = '';
        if (name.endsWith('_time')) {
            addDate = '2011-01-01T';
            if (/^\d:/.test(newValue)) {
                // If the time isn't preceded with a 0 then add a zero
                // This is a Safari issue because Safari's time input is 24 hour format,
                //  and it returns whatever is typed in (doesn't convert to H24:MM format)
                newValue = `0${newValue}`;
            }
        }
        const oldValue = this.state.updatedCase[name] ? this.state.updatedCase[name] : activeCase[name];
        let notValid: boolean | null = null;
        if (newValue !== oldValue) {
            if (newValue === '' || !isNaN(new Date(`${addDate}${newValue}`).getTime())) {
                this.setState((prevState) => ({
                    updatedCase: { ...prevState.updatedCase, [name]: newValue },
                }));
                this.debounceCaseUpdate();
                notValid = newValue === '';
            } else {
                notValid = true;
            }
        }
        switch (name) {
            case LifeDateTimeFieldEnum.dob_date:
                this.setState({ birthDateValidator: notValid }, validator);
                break;
            case LifeDateTimeFieldEnum.dod_start_date:
            case LifeDateTimeFieldEnum.dod_start_time:
                this.setState({ deathDateValidator: notValid }, validator);
                break;
            case LifeDateTimeFieldEnum.dod_end_date:
            case LifeDateTimeFieldEnum.dod_end_time:
                this.setState({ deathDateRangeEndValidator: notValid }, validator);
                break;
            case LifeDateTimeFieldEnum.dop_date:
                this.setState({ pronouncementDateValidator: notValid }, validator);
                break;
            case LifeDateTimeFieldEnum.dop_time:
                this.setState({ pronouncementTimeValidator: notValid }, validator);
                break;
            default:
                break;
        }
    };

    handlePhysicianNameChange = (value: string) => {
        this.updateData({ physicianName: value }, this.validatePhysicianName);
    };

    handlePhysicianPhoneNumberChange = (value: string) => {
        this.updateData(
            {
                physicianPhoneNumber: value
            },
            this.validatePhysicianPhoneNumber,
        );
    };

    handlePhysicianFaxNumberChange = (value: string) => {
        this.updateData(
            {
                physicianFaxNumber: value
            },
            this.validatePhysicianFaxNumber,
        );
    };

    handlePhysicianAddressChange = (physicianAddress: GmapsSearchAddress) => {
        if (isLongAddress(physicianAddress)) {
            this.updateData(
                {
                    physicianAddress,
                },
                this.validatePhysicianAddress,
            );
        }
    };

    handleCauseOfDeathChange = (causeOfDeath: string) => {
        this.updateData({ causeOfDeath }, this.validateCauseOfDeath);
    };

    handleDeathDateKnownChange = (option: string) => {
        const isUnknown = option === 'UNKNOWN';
        this.setState((prevState) => ({
            deathDateUnknown: isUnknown,
            updatedCase: { ...prevState.updatedCase, death_date_unknown: isUnknown }
        }));
        if (option === 'KNOWN') {
            // Clear out the end date and time...
            this.handleDateChange(LifeDateTimeFieldEnum.dod_end_date, '');
            this.handleDateChange(LifeDateTimeFieldEnum.dod_end_time, '');
        }
        this.validateDeathDateEnd();
        this.debounceCaseUpdate();
    };

    handleChangeYearsInCountyEvent = (value: string) => {
        const yearsInCounty = Number(value);
        this.updateData(
            (prevData) => ({
                residencePlace: {
                    ...prevData.residencePlace,
                    yearsInCounty,
                },
            }));
    };

    handleBirthPlaceUnknown = () => {
        this.updateData(
            (prevData) => ({
                birthPlace: {
                    ...NullShortAddress,
                    unknown: !prevData.birthPlace.unknown,
                },
            }),
            this.validateBirthLocation,
        );
    };

    handleResidencePlaceUnknown = () => {
        this.updateData(
            (prevData) => ({
                residencePlace: {
                    ...NullLongAddress,
                    unknown: !prevData.residencePlace?.unknown,
                    filingState: prevData.residencePlace?.filingState,
                    insideCityLimits: prevData.residencePlace?.insideCityLimits,
                    yearsInCounty: prevData.residencePlace?.yearsInCounty,
                },
            }),
            this.validateResidenceLocation,
        );
    };

    handleCityLimitsChange = (insideCityLimits: YesNoUnknownEnum) => {
        this.updateData(
            (prevData) => ({
                residencePlace: {
                    ...prevData.residencePlace,
                    insideCityLimits,
                },
            }),
            this.validateCityLimits,
        );
    };

    handleBornPlace = (place: GmapsSearchAddress) => {
        const birthPlace: ShortAddress = {
            ...this.state.localData.birthPlace,
            ...place
        };

        if (isShortAddress(birthPlace)) {
            this.updateData(
                {
                    birthPlace,
                },
                this.validateBirthLocation,
            );
        }
    };

    handleDeathPlace = (deathPlace: GmapsSearchAddress) => {
        if (!isLongAddress(deathPlace)) {
            log.warn('Invalid death place entered', { deathPlace });
            return;
        }
        this.updateData(
            {
                deathPlace,
            },
            this.validateDeathLocation,
        );
    };

    handleResidencePlace = (residencePlace: GmapsSearchAddress) => {
        if (!isLongAddress(residencePlace)) {
            log.warn('Invalid residence place entered', { residencePlace });
            return;
        }

        this.updateData(
            (prevData) => ({
                residencePlace: {
                    ...prevData.residencePlace,
                    ...residencePlace,
                },
            }),
            this.validateResidenceLocation,
        );
    };

    handleFilingState = (filingState: string) => {
        this.updateData(
            (prevData) => ({
                residencePlace: {
                    ...prevData.residencePlace,
                    filingState,
                },
            }),
            this.validateResidenceLocation,
        );
    };

    handleLocationOptionsChangeEvent = (option: DeathLocationEnum) => {

        this.updateData(
            {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                deathLocation_listOfOptions: option
            },
            this.validateDeathLocationOptions,
        );
    };

    handleHebrewDateOfBirthChange = (value: string) => {
        this.updateData({ hebrewDateOfBirth: value }, this.validateHebrewDateOfBirth);
    };

    handleHebrewDateOfDeathChange = (value: string) => {
        this.updateData({ hebrewDateOfDeath: value }, this.validateHebrewDateOfDeath);
    };

    validateBirthLocation = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            birthLocationValidator: lifeValidators.birthLocation(localData, dcConfig, user),
        });
    };

    validateDeathLocation = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        this.setState({
            deathLocationValidator: lifeValidators.deathLocation(localData, dcConfig, activeCase, user),
        });
    };

    validateResidenceLocation = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            residenceLocationValidator: lifeValidators.residenceLocation(localData, dcConfig, user),
        });
    };

    validateDeathLocationOptions = () => {
        const { activeCase, dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            deathLocationOptionsValidator: lifeValidators.deathLocationOptions(localData, dcConfig, activeCase, user),
        });
    };

    validateCityLimits = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            cityLimitsValidator: lifeValidators.cityLimits(localData, dcConfig, user),
        });
    };

    validateYearsInCurrentCounty = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            yearsInCurrentCountyValidator: lifeValidators.yearsCurrentCounty(localData, dcConfig, user)
        });
    };

    getUpdatedCase = (): GatherCaseUX => {
        return {
            ...this.props.activeCase,
            ...this.state.updatedCase,
        };
    };

    validateBirthDate = () => {
        this.setState({
            birthDateValidator: lifeValidators.birthDate(this.getUpdatedCase())
        });
    };

    validateDeathDate = () => {
        this.setState({
            deathDateValidator: lifeValidators.deathDate(this.getUpdatedCase())
        });
    };

    validateDeathDateEnd = () => {
        this.setState({
            deathDateRangeEndValidator:
                lifeValidators.deathDateRangeEnd(this.getUpdatedCase())
        });
    };

    validatePronouncementDate = () => {
        const { dcConfig, user } = this.props;

        this.setState({
            pronouncementDateValidator: lifeValidators.pronouncementDate(
                this.getUpdatedCase(),
                dcConfig,
                user
            )
        });
    };

    validatePronouncementTime = () => {
        const { dcConfig, user } = this.props;
        this.setState({
            pronouncementTimeValidator:
                lifeValidators.pronouncementTime(this.getUpdatedCase(), dcConfig, user)
        });
    };

    validatePhysicianName = () => {
        const { activeCase, dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            physicianNameValidator: lifeValidators.physicianName(localData, dcConfig, activeCase, user)
        });
    };

    validatePhysicianPhoneNumber = () => {
        const { activeCase, dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            physicianPhoneNumberValidator: lifeValidators.physicianPhoneNumber(localData, dcConfig, activeCase, user)
        });
    };

    validatePhysicianFaxNumber = () => {
        const { activeCase, dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            physicianFaxNumberValidator: lifeValidators.physicianFaxNumber(localData, dcConfig, activeCase, user)
        });
    };

    validatePhysicianAddress = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;

        this.setState({
            physicianAddressValidator: lifeValidators.physicianAddress(localData, dcConfig, activeCase, user),
        });
    };

    validateCauseOfDeath = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        this.setState({
            causeOfDeathValidator: lifeValidators.causeOfDeath(localData, dcConfig, activeCase, user),
        });
    };

    validateHebrewDateOfBirth = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            hebrewDateOfBirthValidator: lifeValidators.hebrewDateOfBirth(localData, dcConfig, user),
        });
    };

    validateHebrewDateOfDeath = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;

        this.setState({
            hebrewDateOfDeathValidator: lifeValidators.hebrewDateOfDeath(localData, dcConfig, activeCase, user)
        });
    };

    checkValid() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return lifeMasterValidators.valid(localData, dcConfig, this.getUpdatedCase(), user);
    }

    validateAll() {
        this.validateBirthDate();
        this.validateDeathDate();
        this.validateDeathDateEnd();
        this.validatePronouncementDate();
        this.validatePronouncementTime();
        this.validateBirthLocation();
        this.validateDeathLocation();
        this.validateResidenceLocation();
        this.validateDeathLocationOptions();
        this.validateCityLimits();
        this.validatePhysicianName();
        this.validatePhysicianPhoneNumber();
        this.validatePhysicianFaxNumber();
        this.validateCauseOfDeath();
        this.validateYearsInCurrentCounty();
        this.validatePhysicianAddress();
        this.validateHebrewDateOfBirth();
        this.validateHebrewDateOfDeath();
    }

    checkIfTouched() {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        return lifeMasterValidators.touched(localData, dcConfig, activeCase, user);
    }

    openRolodexSearchDialog = () => {
        const { dispatch } = this.props;
        dispatch(setRolodexListDialogOpen(true));
    };

    render() {
        const {
            classes,
            activeCase,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            zIndex,
            isEditMode,
            user
        } = this.props;
        const {
            localData,
        } = this.state;

        const residencePlace = JSON.parse(JSON.stringify(localData.residencePlace));
        const age = deathOrAge(activeCase);

        const {
            birthDateValidator,
            deathDateValidator,
            deathDateRangeEndValidator,
            physicianNameValidator,
            physicianPhoneNumberValidator,
            physicianFaxNumberValidator,
            physicianAddressValidator,
            birthLocationValidator,
            deathLocationValidator,
            residenceLocationValidator,
            cityLimitsValidator,
            isChangeFilingStateDialogOpen,
            deathLocationOptionsValidator,
            deathDateUnknown,
            causeOfDeathValidator,
            yearsInCurrentCountyValidator,
            updatedCase,
            pronouncementDateValidator,
            pronouncementTimeValidator,
            hebrewDateOfBirthValidator,
            hebrewDateOfDeathValidator
        } = this.state;

        // compute the filing state name
        let filingStateName = '';
        let filingStateObj = this.state.usStateList.find(state => state.abbreviation === residencePlace.filingState);
        if (filingStateObj) {
            filingStateName = filingStateObj.name;
        }

        const isFHOrGOMUser = UserRoles.isFHorGOMUser(user);

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Life" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(classes.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid
                            container
                            alignItems="flex-start"
                            justifyContent="center"
                        >
                            <Grid
                                xs={12}
                                item
                                className={
                                    classNames(classes.width100, classes.textCenter)
                                }
                            >
                                <Grid
                                    item
                                    className={classNames(classes.width100, classes.textCenter, classes.marginTop20)}
                                    xs={12}
                                >
                                    <DCDateField
                                        id="dob_date"
                                        required
                                        error={birthDateValidator === false}
                                        label={`${activeCase.fname}'s Date of Birth`}
                                        defaultValue={activeCase.dob_date || ''}
                                        onChange={value => this.handleDateChange(
                                            LifeDateTimeFieldEnum.dob_date, value
                                        )}
                                        onBlur={this.validateBirthDate}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        rootClass={classes.marginTop20}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.marginBottom10}
                                    xs={12}
                                >
                                    <ConfigurableTextField
                                        id={ConfigurableLifeFieldKey.hebrewDateOfBirth}
                                        value={localData.hebrewDateOfBirth || ''}
                                        label="Hebrew Date of Birth"
                                        error={hebrewDateOfBirthValidator === false}
                                        onChange={this.handleHebrewDateOfBirthChange}
                                        onBlur={this.validateHebrewDateOfBirth}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        isEditMode={isEditMode}
                                        disabled={isDeathCertificateLocked}
                                        rootClass={classes.textBoxGridWidth}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    justifyContent="center"
                                    className={classNames(classes.marginBottom10, classes.marginTop0)}
                                >
                                    <ConfigurableAddressSearchWithCheckbox
                                        id={ConfigurableLifeFieldKey.birthPlace}
                                        label={`In which city was ${activeCase.fname} born?`}
                                        searchType={GmapsSearchType.shortAddress}
                                        value={localData.birthPlace}
                                        onChange={this.handleBornPlace}
                                        error={birthLocationValidator === false}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        zIndex={zIndex + 2}
                                        isEditMode={isEditMode}
                                        checkboxLabel="City of birth unknown"
                                        onCheckboxChange={this.handleBirthPlaceUnknown}
                                        isChecked={!!localData.birthPlace.unknown}
                                    />
                                </Grid>
                            </Grid>
                            {/* Death-related questions start */}
                            {activeCase.case_type !== CaseType.pre_need &&
                                <Grid
                                    sm={6}
                                    xs={12}
                                    item
                                    className={
                                        classNames(classes.width100, classes.textCenter, classes.marginBottom40)
                                    }
                                >
                                    {/* Death Date & Time starts */}
                                    <div
                                        className={classNames(
                                            classes.background_primary_0_2,
                                            classes.deathDateContent
                                        )}
                                    >
                                        <Grid item className={classes.width100}>
                                            <DCToggleButtons
                                                label={`${activeCase.fname}'s Date of Passing`}
                                                labelClass={classNames(classes.toggleButtonGroupLabel, 'width-100')}
                                                id="dop_known"
                                                handleOpenSnackbar={handleOpenSnackbar}
                                                options={['KNOWN', 'UNKNOWN']}
                                                onSelect={this.handleDeathDateKnownChange}
                                                disabled={isDeathCertificateLocked}
                                                selected={deathDateUnknown ? 'UNKNOWN' : 'KNOWN'}
                                            />
                                        </Grid>
                                        <div className={classes.displayFlex}>
                                            <Grid
                                                item
                                                className={classNames(
                                                    classes.textCenter,
                                                    classes.dateTimeGrid
                                                )}
                                                xs={6}
                                            >
                                                <DCDateField
                                                    id="dod_start_date"
                                                    label={deathDateUnknown ? 'Date (from)' : 'Date of Passing'}
                                                    defaultValue={activeCase.dod_start_date || ''}
                                                    error={deathDateValidator === false}
                                                    onChange={value => this.handleDateChange(
                                                        LifeDateTimeFieldEnum.dod_start_date,
                                                        value
                                                    )}
                                                    onBlur={this.validateDeathDate}
                                                    disabled={isDeathCertificateLocked}
                                                    handleOpenSnackbar={handleOpenSnackbar}
                                                    required
                                                    rootClass={classes.datePickerGrid}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={classNames(
                                                    classes.textCenter,
                                                    classes.dateTimeGrid
                                                )}
                                                xs={6}
                                            >
                                                <DCTimeField
                                                    id="dod_start_time"
                                                    label="Time of Passing"
                                                    value={activeCase.dod_start_time}
                                                    onChange={value => this.handleDateChange(
                                                        LifeDateTimeFieldEnum.dod_start_time, value
                                                    )}
                                                    disabled={isDeathCertificateLocked}
                                                    handleOpenSnackbar={handleOpenSnackbar}
                                                    timezoneData={{
                                                        value: getTzLabel(updatedCase.dod_start_tz || ''),
                                                        id: 'dod_start_tz',
                                                        onClick: () => this.setState({
                                                            whichTz: 'dod_start_tz',
                                                            tzSelected: updatedCase.dod_start_tz || ''
                                                        })
                                                    }}
                                                    rootClass={classes.datePickerGrid}
                                                />
                                            </Grid>
                                        </div>

                                        {deathDateUnknown &&
                                            <div className={classes.displayFlex}>
                                                <Grid
                                                    item
                                                    className={classNames(
                                                        classes.textCenter,
                                                        classes.dateTimeGrid
                                                    )}
                                                    xs={6}
                                                >
                                                    <DCDateField
                                                        id="dod_end_date"
                                                        label="Date (to)"
                                                        defaultValue={activeCase.dod_end_date || ''}
                                                        onChange={value => this.handleDateChange(
                                                            LifeDateTimeFieldEnum.dod_end_date,
                                                            value,
                                                        )}
                                                        onBlur={this.validateDeathDateEnd}
                                                        disabled={isDeathCertificateLocked}
                                                        handleOpenSnackbar={handleOpenSnackbar}
                                                        required
                                                        error={deathDateRangeEndValidator === false}
                                                        rootClass={classes.datePickerGrid}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={classNames(
                                                        classes.textCenter,
                                                        classes.dateTimeGrid
                                                    )}
                                                    xs={6}
                                                >
                                                    <DCTimeField
                                                        id="dod_end_time"
                                                        label="Time"
                                                        value={activeCase.dod_end_time || ''}
                                                        onChange={value => this.handleDateChange(
                                                            LifeDateTimeFieldEnum.dod_end_time,
                                                            value
                                                        )}
                                                        disabled={isDeathCertificateLocked}
                                                        handleOpenSnackbar={handleOpenSnackbar}
                                                        rootClass={classes.datePickerGrid}
                                                        timezoneData={{
                                                            id: 'dod_end_tz',
                                                            value: getTzLabel(updatedCase.dod_end_tz || ''),
                                                            onClick: () => this.setState({
                                                                whichTz: 'dod_end_tz',
                                                                tzSelected: updatedCase.dod_end_tz || ''
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </div>
                                        }

                                    </div>
                                    {/* Death Date & Time ends */}

                                    <Grid
                                        item
                                        className={classNames(classes.width100, classes.marginBottom10)}
                                        xs={12}
                                    >
                                        <ConfigurableTextField
                                            id={ConfigurableLifeFieldKey.hebrewDateOfDeath}
                                            value={localData.hebrewDateOfDeath || ''}
                                            label="Hebrew Date of Death"
                                            error={hebrewDateOfDeathValidator === false}
                                            onChange={this.handleHebrewDateOfDeathChange}
                                            onBlur={this.validateHebrewDateOfDeath}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            isEditMode={isEditMode}
                                            disabled={isDeathCertificateLocked}
                                            rootClass={classes.textBoxGridWidth}
                                        />
                                    </Grid>

                                    {/* Pronouncement Date & Time starts */}
                                    <div className={classes.displayFlex}>
                                        <Grid
                                            item
                                            className={classNames(
                                                classes.textCenter,
                                                classes.marginTop20,
                                                classes.dateTimeGrid
                                            )}
                                            xs={6}
                                        >
                                            <ConfigurableDateField
                                                id={ConfigurableLifeFieldKey.dateOfPronouncement}
                                                label="Date of Pronouncement"
                                                defaultValue={activeCase.dop_date || ''}
                                                onChange={(value) => this.handleDateChange(
                                                    LifeDateTimeFieldEnum.dop_date,
                                                    value,
                                                )}
                                                disabled={isDeathCertificateLocked}
                                                handleOpenSnackbar={handleOpenSnackbar}
                                                isEditMode={isEditMode}
                                                onBlur={this.validatePronouncementDate}
                                                rootClass={classes.datePickerGrid}
                                                error={pronouncementDateValidator === false}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={classNames(
                                                classes.textCenter,
                                                classes.marginTop20,
                                                classes.dateTimeGrid
                                            )}
                                            xs={6}
                                        >
                                            <ConfigurableTimeField
                                                id={ConfigurableLifeFieldKey.timeOfPronouncement}
                                                label="Time of Pronouncement"
                                                value={activeCase.dop_time}
                                                onChange={value => this.handleDateChange(
                                                    LifeDateTimeFieldEnum.dop_time,
                                                    value
                                                )}
                                                disabled={isDeathCertificateLocked}
                                                handleOpenSnackbar={handleOpenSnackbar}
                                                timezoneData={{
                                                    id: 'dop_tz',
                                                    value: getTzLabel(this.state.updatedCase.dop_tz || ''),
                                                    onClick: () => this.setState({
                                                        whichTz: 'dop_tz',
                                                        tzSelected: this.state.updatedCase.dop_tz || ''
                                                    })
                                                }}
                                                isEditMode={isEditMode}
                                                rootClass={classes.datePickerGrid}
                                                error={pronouncementTimeValidator === false}
                                            />
                                        </Grid>
                                    </div>
                                    {/* Pronouncement Date & Time ends */}

                                    <Grid item className={classes.width100}>
                                        <ConfigurableTextField
                                            id={ConfigurableLifeFieldKey.causeOfDeath}
                                            error={causeOfDeathValidator === false}
                                            label={'Cause of Death'}
                                            value={localData.causeOfDeath || ''}
                                            onChange={this.handleCauseOfDeathChange}
                                            onBlur={this.validateCauseOfDeath}
                                            disabled={isDeathCertificateLocked}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            isEditMode={isEditMode}
                                            rootClass={classes.textBoxGridWidth}
                                        />
                                        <ConfigurableSelect
                                            id={ConfigurableLifeFieldKey.deathLocationOptions}
                                            rootClass={classes.gmapSearch}
                                            error={deathLocationOptionsValidator === false}
                                            label={`Where did ${activeCase.fname} die?`}
                                            selectedOption={
                                                (localData.deathLocation_listOfOptions
                                                    && DeathLocationEnum[localData.deathLocation_listOfOptions])
                                                || null
                                            }
                                            onSelect={option => (
                                                this.handleLocationOptionsChangeEvent(DeathLocationEnum[option])
                                            )}
                                            zIndex={zIndex}
                                            validator={this.validateDeathLocationOptions}
                                            options={values(DeathLocationEnum)}
                                            isEditMode={isEditMode}
                                            disabled={isDeathCertificateLocked}
                                        />
                                    </Grid>
                                    <ConfigurableAddressSearch
                                        id={ConfigurableLifeFieldKey.deathPlace}
                                        label="Specific location of passing"
                                        searchType={GmapsSearchType.longAddress}
                                        value={localData.deathPlace}
                                        onChange={this.handleDeathPlace}
                                        error={deathLocationValidator === false}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        zIndex={zIndex + 1}
                                        isEditMode={isEditMode}
                                    />
                                    <Grid item className={classes.width100}>
                                        <ConfigurableTextField
                                            id={ConfigurableLifeFieldKey.physicianName}
                                            label="Physician's Name"
                                            value={localData.physicianName}
                                            inputType="text"
                                            onChange={this.handlePhysicianNameChange}
                                            onBlur={this.validatePhysicianName}
                                            disabled={isDeathCertificateLocked}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            error={physicianNameValidator === false}
                                            endAdornment={(
                                                isFHOrGOMUser
                                                && <Tooltip title={'Click here to search your rolodex'}>
                                                    <IconButton
                                                        color="primary"
                                                        className={classes.adronmentIconButton}
                                                        onClick={(e) => this.openRolodexSearchDialog()}
                                                        size="large">
                                                        <ContactsIcon color="secondary" />
                                                    </IconButton>
                                                </Tooltip>
                                                || <></>
                                            )}
                                            isEditMode={isEditMode}
                                            rootClass={
                                                classNames(classes.gmapSearch, classes.textLeft, classes.marginTop20)
                                            }
                                        />
                                    </Grid>
                                    <Grid item className={classes.width100}>
                                        <ConfigurableTextField
                                            id={ConfigurableLifeFieldKey.physicianPhoneNumber}
                                            label="Physician's Phone Number"
                                            value={localData.physicianPhoneNumber}
                                            inputType="tel"
                                            onChange={this.handlePhysicianPhoneNumberChange}
                                            onBlur={this.validatePhysicianPhoneNumber}
                                            disabled={isDeathCertificateLocked}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            inputComponent={PhoneNumberMask}
                                            isEditMode={isEditMode}
                                            rootClass={classes.gmapSearch}
                                            error={physicianPhoneNumberValidator === false}
                                        />
                                    </Grid>
                                    <Grid item className={classes.width100}>
                                        <ConfigurableTextField
                                            id={ConfigurableLifeFieldKey.physicianFaxNumber}
                                            label="Physician's Fax Number"
                                            value={localData.physicianFaxNumber}
                                            inputType="tel"
                                            onBlur={this.validatePhysicianFaxNumber}
                                            onChange={this.handlePhysicianFaxNumberChange}
                                            disabled={isDeathCertificateLocked}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            inputComponent={PhoneNumberMask}
                                            isEditMode={isEditMode}
                                            rootClass={classes.gmapSearch}
                                            error={physicianFaxNumberValidator === false}
                                        />
                                    </Grid>

                                    <ConfigurableAddressSearch
                                        id={ConfigurableLifeFieldKey.physicianAddress}
                                        label="Physician's Physical Address"
                                        searchType={GmapsSearchType.longAddress}
                                        value={localData.physicianAddress || { ...NullLongAddress }}
                                        onChange={this.handlePhysicianAddressChange}
                                        error={physicianAddressValidator === false}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        zIndex={zIndex + 1}
                                        isEditMode={isEditMode}
                                    />
                                </Grid>
                            }
                            {/* Death-related questions end */}
                        </Grid>
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={
                                        classNames(
                                            classes.width100,
                                            classes.textLeft,
                                        )
                                    }
                                >
                                    <Typography
                                        color="secondary"
                                        align="center"
                                        className={classes.calculateAge}
                                    >
                                        {`${activeCase.fname}'s Age: ${age}`}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <ConfigurableAddressSearchWithCheckbox
                                id={ConfigurableLifeFieldKey.primaryResidence}
                                checkboxLabel="Location of residence unknown"
                                label={`Address of ${activeCase.fname}'s Primary Residence?`}
                                searchType={GmapsSearchType.longAddress}
                                value={localData.residencePlace}
                                error={residenceLocationValidator === false}
                                onChange={this.handleResidencePlace}
                                handleOpenSnackbar={handleOpenSnackbar}
                                disabled={isDeathCertificateLocked}
                                zIndex={zIndex + 2}
                                isEditMode={isEditMode}
                                onCheckboxChange={this.handleResidencePlaceUnknown}
                                isChecked={!!localData.residencePlace.unknown}
                            />
                        </Grid>
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classes.width100}>
                                <ConfigurableToggleButtonsForYesNoUnknown
                                    id={ConfigurableLifeFieldKey.primaryResidenceInCityLimits}
                                    error={cityLimitsValidator === false}
                                    rootClass={classes.marginTop40}
                                    label={`Is ${activeCase.fname}'s primary residence inside city limits?`}
                                    onSelect={this.handleCityLimitsChange}
                                    options={values(YesNoUnknownEnum)}
                                    buttonClass={classes.cityLimitToggleButton}
                                    isEditMode={isEditMode}
                                    selected={residencePlace.insideCityLimits}
                                    disabled={isDeathCertificateLocked}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classNames(classes.width100, classes.textCenter)}>
                                <ConfigurableTextField
                                    id={ConfigurableLifeFieldKey.yearsLivedInCounty}
                                    label="Years lived in current county"
                                    inputType="number"
                                    rootClass={classes.textBoxGridWidth}
                                    error={yearsInCurrentCountyValidator === false}
                                    onBlur={this.validateYearsInCurrentCounty}
                                    onChange={this.handleChangeYearsInCountyEvent}
                                    value={residencePlace.yearsInCounty?.toString() ?? ''}
                                    disabled={isDeathCertificateLocked}
                                    isEditMode={isEditMode}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={classNames(classes.width100, classes.marginBottom5)}>
                                <FormControl
                                    className={
                                        classNames(
                                            classes.width100,
                                            classes.textLeft,
                                            classes.marginTop40,
                                        )
                                    }
                                >
                                    <Typography
                                        color="secondary"
                                        align="center"
                                    >
                                        {activeCase.fname}'s death certificate will be filed in
                                        <span
                                            className={classes.fontWeight500}
                                        >
                                            {` ${filingStateName}.`}

                                        </span>  {activeCase.assignee.fname} can adjust this if needed.
                                        <span
                                            className={classes.filingState}
                                            onClick={this.openFilingChangeDialog}
                                        >
                                            Click to change filing state
                                        </span>
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <ChangeFilingStateDialog
                            isDialogOpen={isChangeFilingStateDialogOpen}
                            closeDialog={this.closeFilingChangeDialog}
                            existingFilingState={localData.residencePlace.filingState || null}
                            saveFilingState={this.handleFilingState}
                            zIndex={zIndex + 1}
                        />
                        <ChooseTimeZoneDialog
                            isDialogOpen={this.state.whichTz !== null}
                            closeDialog={() => this.setState({ whichTz: null })}
                            tzSelected={this.state.tzSelected || 'America/Boise'}
                            title={'Location Time Zone'}
                            onChange={(tz) => this.handleTimezoneChange(tz)}
                            zIndex={zIndex + 1}
                        />
                    </form>
                </Grid>
            </div >
        );
    }
}

export default withState()(withGStyles(styles)(LifeForm));
