import { TransitionGroup } from 'react-transition-group';
import SlideZoomListTransition from '../SlideZoomListTransition';
import { TrackingItem } from './BodyTrackingContent';
import { GatherCaseUX } from '../../../shared/types';
import QRStepper from './QRStepper';

interface Props {
    trackingItems: TrackingItem[];
    selectedCase: GatherCaseUX;
    isFamilyView: boolean;
    openWorkflowSelectorDialog: () => void;
    toggleBulkEditMode: () => void;
    openAllStepsDialog: VoidFunction;
    openSkipStepDialog: (stepId: number) => void;
    onDueDateClick: (stepId: number) => void;
}

const Tracking = (props: Props) => {
    const {
        trackingItems,
        selectedCase,
        isFamilyView,
        openWorkflowSelectorDialog,
        toggleBulkEditMode,
        openAllStepsDialog,
        openSkipStepDialog,
        onDueDateClick,
    } = props;

    return (
        <TransitionGroup className="steps-list">
            <SlideZoomListTransition key="stepper">
                <QRStepper
                    trackingItems={trackingItems}
                    selectedCase={selectedCase}
                    isFamilyView={isFamilyView}
                    openWorkflowSelectorDialog={openWorkflowSelectorDialog}
                    toggleBulkEditMode={toggleBulkEditMode}
                    openAllStepsDialog={openAllStepsDialog}
                    openSkipStepDialog={openSkipStepDialog}
                    onDueDateClick={onDueDateClick}
                />
            </SlideZoomListTransition>
        </TransitionGroup>
    );
};

export default Tracking;
