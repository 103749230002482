import { deleteFromAPI, getFromAPI, postToAPI } from ".";
import { CaseIdPhotoUX, DocDownloadResponse, UploadFileRequest } from "../shared/types";
import { AppDispatch } from "../store";
import { registerAppError } from "./errors";
import { uploadS3FileToS3 } from "./S3File.action";

export const LOADING_CASE_ID_PHOTOS = 'LOADING_CASE_ID_PHOTOS';
interface LoadingCaseIdPhotos {
    type: typeof LOADING_CASE_ID_PHOTOS;
}

function loadingCaseIdPhotos(): LoadingCaseIdPhotos {
    return {
        type: LOADING_CASE_ID_PHOTOS,
    };
}

export const CASE_ID_PHOTOS_LOADED = 'CASE_ID_PHOTOS_LOADED';
interface CaseIdPhotosLoaded {
    type: typeof CASE_ID_PHOTOS_LOADED;
    idPhotos: CaseIdPhotoUX[];
}

function caseIdPhotosLoaded(idPhotos: CaseIdPhotoUX[]): CaseIdPhotosLoaded {
    return {
        type: CASE_ID_PHOTOS_LOADED,
        idPhotos,
    };
}

export function uploadCaseIdPhoto(
    caseUuid: string,
    uploadRequest: UploadFileRequest,
) {
    return async (dispatch: AppDispatch): Promise<CaseIdPhotoUX[] | null> => {
        const suffix = uploadRequest.suffix || '';
        if (!suffix) {
            dispatch(registerAppError('Invalid file type'));
            return null;
        }
        const preSignURLRoute = `api/case/${caseUuid}/idphoto/uploadurl/${uploadRequest.hash}/${suffix}`;
        const s3File = await dispatch(uploadS3FileToS3({
            uploadRequest,
            preSignURLRoute,
        }));
        const caseIdPhotos = await postToAPI<CaseIdPhotoUX[]>(
            `api/case/${caseUuid}/idphoto`,
            {
                s3File,
            },
            dispatch,
        );
        if (caseIdPhotos) {
            dispatch(caseIdPhotosLoaded(caseIdPhotos));
            return caseIdPhotos;
        } else {
            dispatch(registerAppError('Unable to upload identification photo'));
        }
        return null;
    };
}

export function loadCaseIdPhotos(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<CaseIdPhotoUX[] | null> => {

        dispatch(loadingCaseIdPhotos());

        const idPhotos = await getFromAPI<CaseIdPhotoUX[]>(`api/case/${caseUuid}/idphoto/`, dispatch);
        if (!idPhotos) {
            dispatch(registerAppError('Unable to load identification photos'));
            return null;
        }

        dispatch(caseIdPhotosLoaded(idPhotos));
        return idPhotos;
    };
}

export function getCaseIdPhotoDownloadurl(caseUuid: string, docId: number) {
    return async (dispatch: AppDispatch): Promise<DocDownloadResponse | null> => {
        const downloadUrl = await getFromAPI<DocDownloadResponse>(
            `api/case/${caseUuid}/idphoto/${docId}/downloadurl`,
            dispatch
        );
        if (!downloadUrl) {
            dispatch(registerAppError('Unable to get identification photo'));
            return null;
        }

        return downloadUrl;
    };
}

export const REMOVE_CASE_ID_PHOTO = 'REMOVE_CASE_ID_PHOTO';

interface RemoveCaseIdPhoto {
    type: typeof REMOVE_CASE_ID_PHOTO;
    s3FileId: number;
}

function removeCaseIdPhoto(s3FileId: number): RemoveCaseIdPhoto {
    return {
        type: REMOVE_CASE_ID_PHOTO,
        s3FileId
    };
}

export function deleteCaseIdPhoto(caseUuid: string, s3FileId: number) {
    return async (dispatch: AppDispatch) => {

        dispatch(removeCaseIdPhoto(s3FileId));
        const deleteURL = `api/case/${caseUuid}/idphoto/${s3FileId}`;
        const updatedIdPhotos = await deleteFromAPI<CaseIdPhotoUX[]>(deleteURL, dispatch);
        if (!updatedIdPhotos) {
            dispatch(registerAppError('Unable to delete identification photo'));
            return null;
        }
        dispatch(caseIdPhotosLoaded(updatedIdPhotos));
        return updatedIdPhotos;
    };
}

export type CaseS3Action =
    | CaseIdPhotosLoaded
    | LoadingCaseIdPhotos
    | RemoveCaseIdPhoto
    ;
