import DCTextField, { Props as DCTextFieldProps } from '../textField/DCTextField';
import DCCheckbox from '../checkbox/DCCheckbox';

export interface Props extends DCTextFieldProps {
    checkboxLabel: string;
    isChecked: boolean;
    onCheckboxChange: (isChecked: boolean) => void;
    formControlClass?: string;
}

const DCTextFieldWithCheckbox = (props: Props) => {

    const {
        id,
        error,
        disabled,
        required,
        value,
        label,
        rootClass,
        textFieldClass,
        labelClass,
        onBlur,
        onChange,
        handleOpenSnackbar,
        checkboxLabel,
        isChecked,
        onCheckboxChange,
        inputType,
        placeholder,
        formControlClass,
        endAdornment,
        pattern,
        inputComponent,
        isHiddenFromFamily
    } = props;

    return (
        <>
            <DCTextField
                id={id}
                error={error}
                disabled={isChecked || disabled}
                required={required}
                value={value}
                label={label}
                rootClass={rootClass}
                textFieldClass={textFieldClass}
                labelClass={labelClass}
                onBlur={onBlur}
                onChange={onChange}
                handleOpenSnackbar={handleOpenSnackbar}
                inputType={inputType}
                placeholder={placeholder}
                endAdornment={endAdornment}
                pattern={pattern}
                inputComponent={inputComponent}
                isHiddenFromFamily={isHiddenFromFamily}
            />
            <DCCheckbox
                label={checkboxLabel}
                onChange={onCheckboxChange}
                handleOpenSnackbar={handleOpenSnackbar}
                isChecked={isChecked}
                disabled={disabled}
                rootClass={rootClass}
                labelClass={labelClass}
                formControlClass={formControlClass}
            />
        </>
    );
};

export default DCTextFieldWithCheckbox;
