import * as React from 'react';
import { GatherCaseUX, ProductContractItemUX } from '../../../../shared/types';
import ChangeOriginalValueDialog from '../../../common/ChangeOriginalValueDialog';

interface Props {
    activeCase: GatherCaseUX;
    isDialogOpen: boolean;
    contractItem: ProductContractItemUX;
    closeDialog: () => void;
    handleRenameContractItem: (contractItemId: string, newName: string | null) => void;
    zIndex: number;
}

interface State {
    displayName: string | null;
    saveAttempted: boolean;
}
class RenameContractItemDialog extends React.Component<Props, State> {

    constructor(props: Props ) {
        super(props);
        this.state = {
            displayName: this.props.contractItem && this.props.contractItem.display_name
                ? this.props.contractItem.display_name : null,
            saveAttempted: false,
        };
    }

    renameContractItem = (newName: string) => {
        const { contractItem, closeDialog, handleRenameContractItem } = this.props;
        this.setState({displayName: newName});
        handleRenameContractItem(contractItem.id, newName);
        closeDialog();
    };

    isValidName = (newName: string): boolean => {
        return true;
    };

    render() {
        const {
            isDialogOpen, closeDialog, zIndex, contractItem
        } = this.props;
        const { displayName } = this.state;
        const originalName = contractItem && contractItem.name ? contractItem.name : '';
        const prefillValue = Boolean(displayName && displayName !== originalName);
        const subHeaderMessage = 'This is a visual name change only and will not effect reporting. This item will '
            + 'be under the original category and use the same name as "Original Name" '
            + 'below. '
        ;
        const currentDetail = <>Original Name: <b>{originalName}</b></>;
        return (
            <>
                <ChangeOriginalValueDialog
                    header={'Rename Item'}
                    subHeader={subHeaderMessage}
                    confirmationButtonText={'Rename Item'}
                    open={isDialogOpen}
                    onClose={closeDialog}
                    onSave={this.renameContractItem}
                    zIndex={zIndex + 1}
                    originalValue={displayName}
                    newValueInputTextMessage={'Enter new name'}
                    currentDetail={currentDetail}
                    prefillOriginalValue={Boolean(prefillValue)}
                    validator={this.isValidName}
                />
            </>
        );
    }

}

export default RenameContractItemDialog as React.ComponentType<Props>;