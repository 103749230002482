import classNames from 'classnames';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import makeGStyles from '../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        background: '#fafafa',
        zIndex: 0,
        padding: '0',
    },
    tabSelected: {},
    tabRoot: {
        minWidth: '72px !important',
    },
    scrollButtons: {
        color: '#000',
        flex: 0,
        minHeight: 48,
    },
    largeTab: {
        minHeight: 60,
    },
    flexContainer: {
        width: '100%',
    },
    flexContainerCenter: {
        display: 'inline-flex',
    },
    textTransformNone: {
        textTransform: 'none !important' as 'none',
    },
    colorThemeColor: {
        color: theme.palette.primary.main
    },
    shrinkButtonSize: {
        width: 20,
        color: theme.palette.primary.main
    },
}), { name: 'GTabs' });

interface GTabContent {
    label: string;
    description?: string[];
}

export interface GTabData {
    content: GTabContent;
    id: number;
    value: Date | number;
    disabled?: boolean;
    autoFocus?: boolean;
    tabIndex?: number;
}

interface Props {
    handleChangeEvent: (event: React.ChangeEvent<{}>, tabIndex: number) => void;
    tabIndex: number | boolean;
    data: GTabData[];
    size?: string;
    alignCenter?: boolean;
    isLabelThemeColor?: boolean;
    shrinkScrollButtonSize?: boolean;
}

const GTabs = (props: Props) => {
    const {
        tabIndex,
        handleChangeEvent,
        data,
        alignCenter,
        size = 'default',
        isLabelThemeColor,
        shrinkScrollButtonSize
    } = props;

    const classes = useStyles();

    const generateTabHtml = ({ label, description = [] }: GTabContent): JSX.Element => {
        return (
            <span style={{ fontSize: 14 }}>
                {label}
                {description.map((line) => (
                    <span key={line} style={{ fontSize: 12 }}>
                        <br />
                        {line}
                    </span>
                ))}
            </span>
        );
    };

    return (
        <Tabs
            value={tabIndex}
            onChange={handleChangeEvent}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
                root: size === 'large' ? classes.largeTab : '',
                scrollButtons: (size === 'large' ? classNames(classes.scrollButtons, classes.largeTab)
                    : classes.scrollButtons) && (isLabelThemeColor && classes.colorThemeColor)
                    && (shrinkScrollButtonSize && classes.shrinkButtonSize),
                flexContainer: alignCenter ?
                    classes.flexContainerCenter
                    : classes.flexContainer,
                scrollableX: alignCenter ? classNames(classes.scrollHidden, classes.margin_0) : '',
            }}
        >
            {data.map((tab: GTabData) =>
                <Tab
                    key={tab.id}
                    label={generateTabHtml(tab.content)}
                    disabled={tab.disabled}
                    tabIndex={tab.tabIndex}
                    // autoFocus={tab.autoFocus}
                    classes={{
                        root: classNames(
                            classes.tabRoot,
                            classes.textTransformNone,
                            isLabelThemeColor && classes.colorThemeColor
                        ),
                        selected: classes.tabSelected,
                        labelIcon: classNames(isLabelThemeColor && classes.colorThemeColor)
                    }}
                />
            )}
        </Tabs>
    );
};

export default GTabs;
