import { Component } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import Clear from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import {
    ProductContractItemUX,
} from '../../../../shared/types';
import { isNormalItem } from '../../../../shared/goods_and_services/utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        borderRadius: 12,
        '& $dialogPaper': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: 'none',
            height: '100vh',
            maxHeight: 'none',
            borderRadius: 0,
            margin: '48px auto',
            padding: 0,
            '@media (min-width: 600px)': {
                maxWidth: 520,
                width: 520,
                borderRadius: 12,
                height: 'auto'
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)',
        color: 'white',
    },
    dialogContent: {
        zIndex: 0,
        padding: '40px !important',
        textAlign: 'center',
        overflowX: 'hidden',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    textWithIcon: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    dialogPaper: {},
});

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent'
    | 'clearIcon' | 'textWithIcon'>;

interface Props {
    items: ProductContractItemUX[];
    isDialogOpen: boolean;
    closeDialog: () => void;
    zIndex: number;
}

class TaxBreakdownDialog extends Component<StyledProps> {

    render() {
        const {
            classes,
            isDialogOpen,
            closeDialog,
            items,
            zIndex,
        } = this.props;

        const validItems = items.filter((item) => !item.delete_revision && isNormalItem(item));

        return (
            <Dialog
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                scroll={'body'}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.dialogHeader,
                        classes.backgroundPrimary
                    )}
                >
                    Tax Breakdown
                    <Clear
                        className={classes.clearIcon}
                        onClick={closeDialog}
                    />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {validItems.map((item) => (
                        <Grid key={item.id} container justifyContent="space-between">
                            <Grid className={classes.textWithIcon}>
                                <Typography>
                                    {item.display_name || item.name}&nbsp;
                                </Typography>
                                <CopyToClipboard text={item.id}>
                                    <Tooltip title="Copy contract item ID">
                                        <FileCopyIcon
                                            className={classNames(classes.cursorPointer, classes.fontSize12)}
                                        />
                                    </Tooltip>
                                </CopyToClipboard>
                            </Grid>
                            {item.tax_rate_id &&
                                <Grid className={classes.textWithIcon}>
                                    <Typography>
                                        {item.tax_rate_brackets}&nbsp;
                                    </Typography>
                                    <CopyToClipboard text={item.tax_rate_id.toString()}>
                                        <Tooltip title="Copy tax rate ID">
                                            <FileCopyIcon
                                                className={classNames(classes.cursorPointer, classes.fontSize12)}
                                            />
                                        </Tooltip>
                                    </CopyToClipboard>
                                </Grid>
                            }
                            <Typography>
                                {item.tax_total > 0
                                    ? `$${item.tax_total / 100}`
                                    : 'Non-Taxable'
                                }
                            </Typography>
                        </Grid>
                    ))}
                </DialogContent>
            </Dialog>
        );
    }

}

export default withGStyles(styles)(TaxBreakdownDialog);
