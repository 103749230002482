import { Component } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ClearIcon from '@mui/icons-material/Clear';

import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import { StoreState } from '../../../types';
import { UserRoles, FuneralHomeUX } from '../../../shared/types';

import { GCard } from '../../funeralHomes/funeralHomeSettings/widgets';
import GetStartedDialog from '../../funeralHomes/funeralHomeSettings/GetStarted.dialog';

import { closeAccessRestrictedDialog, isDemoMode } from '../../../actions/AccessRestricted.action';

import { showIntercom, RouteBuilder, FuneralHomeRoutePage } from '../../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, withStyles, WithStyles } from '@mui/styles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import { SlideTransition } from '../../common/Transitions';
import GLink from '../../common/GLink';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            width: '100%',
            minWidth: 300,
            maxWidth: 360,
            height: 'auto',
            borderRadius: 12,
            boxShadow: theme.shadows[5],
            margin: 'auto 8px',
            [theme.breakpoints.up('md')]: {
                maxWidth: 360,
                minWidth: 360,
                width: 360,
                height: 'auto',
                maxHeight: 'auto'
            }
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: 16,
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    clearIcon: {
        zIndex: 1,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        cursor: 'pointer',
        filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,.8))',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    mainIcon: {
        color: 'inherit',
        fontSize: 120
    },
    header: {
        fontSize: 24,
        color: 'inherit',
        borderBottom: '1px solid',
        textAlign: 'center',
        width: '100%'
    },
    details: {
        color: 'inherit',
        fontSize: 14,
        fontWeight: 300,
        lineHeight: '24px'
    },
    footer: {
        color: 'inherit',
        fontSize: 14,
        fontWeight: 200,
        '& span': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    button: {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        margin: '24px auto',
        fontWeight: 400,
        '&$hasGCard': {
            margin: '8px auto',
        },
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            opacity: 0.8,
        }
    },
    gCardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    customLink: {
        color: '#fff',
        backgroundColor: 'transparent',
    },
    hasGCard: {},
    dialogPaper: {}
});

type StyledProps = Props & WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon'
    | 'mainIcon' | 'header' | 'button' | 'details' | 'footer' | 'gCardContainer' | 'customLink' | 'hasGCard'>;

// props from redux store
const mapStateToProps = ({ accessRestricted, funeralHomeState, userSession, casesState }: StoreState) => {
    return {
        activeCase: casesState.selectedCase,
        isDialogOpen: accessRestricted.isDialogOpen,
        funeralHome: funeralHomeState.activeFuneralHome,
        demoSettings: funeralHomeState.demoSettings,
        userSession,
        zIndex: accessRestricted.zIndex
    };
};

type Props = ReturnType<typeof mapStateToProps> & {
    dispatch: AppDispatch;
};

interface State {
    isGetStartedDialogOpen: boolean;
}

class AccessRestrictedDialog extends Component<StyledProps, State> {
    state: State = {
        isGetStartedDialogOpen: false
    };

    renderGetStartedDialog = (funeralHome: FuneralHomeUX) => {
        const { demoSettings, userSession, zIndex } = this.props;
        const { isGetStartedDialogOpen } = this.state;

        return (
            <GetStartedDialog
                key={demoSettings ? demoSettings.id : 'n/a'}
                isDialogOpen={isGetStartedDialogOpen}
                closeDialog={this.toggleGetStartedDialog}
                funeralHomeIcon={funeralHome.icon}
                demoSettings={demoSettings}
                userSession={userSession}
                zIndex={zIndex + 1}
            />
        );
    };

    render() {
        const {
            classes,
            isDialogOpen,
            funeralHome,
            demoSettings,
            userSession,
            activeCase,
            zIndex
        } = this.props;

        if (!activeCase && !funeralHome) {
            return null;
        }
        const demoMode = isDemoMode(funeralHome, activeCase);
        const funeralHomeName = funeralHome ? funeralHome.name : activeCase ? activeCase.funeral_home.name : '';
        const funeralHomeKey = funeralHome ? funeralHome.key : activeCase ? activeCase.funeral_home.key : '';
        const isGatherUser: boolean = UserRoles.isGOMUser(userSession.userData);
        const isFHUser: boolean = UserRoles.isFHUser(userSession.userData);

        const showGettingStarted = demoSettings
            && demoSettings.show_get_started
            && !demoSettings.ob_fee_waived
            && demoSettings.ob_fee_status === 'proposed';

        return (
            <Dialog
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={() => this.closeDialog()}
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                style={{ zIndex }}
            >
                {isDialogOpen && <DialogContent className={classes.dialogContent}>
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={e => this.closeDialog()}
                    />
                    <Grid container justifyContent="center" alignItems="center">
                        <LocalActivityIcon className={classes.mainIcon} />
                        <Typography className={classes.header}>
                            Access Restricted
                        </Typography>

                        <Typography className={classes.details}>
                            {funeralHomeName} is in {demoMode ? 'DEMO' : 'LIVE'} mode
                        </Typography>

                        {showGettingStarted && (isGatherUser || isFHUser) &&
                            <Grid item xs={12} className={classes.gCardContainer}>
                                <GCard
                                    isInverted
                                    icon="important_devices"
                                    label="Get Started Risk Free"
                                    subLabel="Money-back Guarantee Cancel Anytime"
                                    onClick={this.toggleGetStartedDialog}
                                />
                            </Grid>
                        }

                        {(isGatherUser || isFHUser) &&
                            <GLink
                                to={RouteBuilder.FuneralHome(funeralHomeKey, FuneralHomeRoutePage.MYFUNERALHOME)}
                                linkClass={classes.customLink}
                                onClick={e => this.closeDialog()}
                            >
                                <Button
                                    className={classNames(
                                        classes.button,
                                        showGettingStarted && classes.hasGCard
                                    )}
                                    variant="contained"
                                    onClick={e => this.closeDialog()}
                                >
                                    Onboarding dashboard
                                </Button>
                            </GLink>
                        }

                        <Typography className={classes.footer}>
                            Have questions? <span onClick={e => showIntercom()}>Click here to contact us</span>
                        </Typography>

                    </Grid>
                </DialogContent>}
                {funeralHome && this.renderGetStartedDialog(funeralHome)}
            </Dialog>
        );
    }

    closeDialog = () => {
        const { dispatch } = this.props;
        dispatch(closeAccessRestrictedDialog());
    };

    toggleGetStartedDialog = () => {
        this.setState((prevState) => ({
            isGetStartedDialogOpen: !prevState.isGetStartedDialogOpen,
        }));
    };
}

export default withState(mapStateToProps)(withStyles(styles)(AccessRestrictedDialog));
