import TaskComponentDialog, { Props as TaskComponentDialogProps } from '../activeStep/common/TaskComponent.dialog';
import SavePrerequisitesDialogContent, { Props as SavePrereqsProps } from './SavePrerequisitesDialogContent';

interface Props extends SavePrereqsProps, Omit<TaskComponentDialogProps, 'title'> {
    hideWarning?: boolean;
}

const SavePrerequisitesDialog = (props: Props) => {
    const {
        workflowName,
        availablePrereqs,
        selectedKeys,
        zIndex,
        onSave,
        onClose,
        hideWarning,
        ...dialogProps
    } = props;

    return (
        <TaskComponentDialog
            {...dialogProps}
            zIndex={zIndex}
            onClose={onClose}
            title={`Edit Prerequisites`}
        >
            <SavePrerequisitesDialogContent
                workflowName={workflowName}
                availablePrereqs={availablePrereqs}
                selectedKeys={selectedKeys}
                onSave={onSave}
                onClose={onClose}
                zIndex={zIndex}
                hideWarning={hideWarning}
            />
        </TaskComponentDialog>
    );
};

export default SavePrerequisitesDialog;
