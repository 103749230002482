import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { convertHexToRGBA } from '../../../services';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Zoom from '@mui/material/Zoom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { GLOBAL_STYLED_PROPS } from '../../../styles';

import {
    GatherEvent,
    UserRoles,
    GatherCaseUX,
    EventType,
    TaskTemplateType,
} from '../../../shared/types';
import { StoreState } from '../../../types';

import AddEventButton from './widgets/AddEventButton';

import ScheduleServicesDialog from '../taskDialogs/scheduleServicesDialog';
import { openArrangementConferenceDialog } from '../../../actions/GatherCase.action';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';
import { AppDispatch } from '../../../store';
import withFullScreen from '../../common/utilHOC/WithFullScreen';
import EventList from './EventList';;

export type ParentComponentType = 'FAMILY_CASE' | 'DETAIL_TASKCARD' | TaskTemplateType.schedule_services;
export enum ParentComponentTypeEnum {
    FAMILY_CASE = 'FAMILY_CASE',
    DETAIL_TASKCARD = 'DETAIL_TASKCARD'
}

function mapStateToProps({
    eventState,
    casesState,
    userSession
}: StoreState) {
    const { selectedCase } = casesState;

    return {
        activeCase: selectedCase,
        caseEvents: eventState.gatherEvents.filter(event => event.gather_case_id === selectedCase?.id),
        isLoading: eventState.isLoading,
        userData: userSession.userData
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    renderOnlyAddEventButton?: boolean;
    parentComponent?: ParentComponentType;
    dispatch: AppDispatch;
    zIndex: number;
}

interface State {
    activeEvent: GatherEvent | null;
    isScheduleServicesDialogOpen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    lastGridList: {
        minWidth: 16,
        width: 16,
        color: 'transparent'
    },
    addIcon: {
        height: 34,
        width: 34,
        color: '#fff',
        display: 'block',
        margin: '5px auto 0'
    },
    buttonLabel: {
        fontSize: 12,
        fontWeight: 200,
        width: '100%',
        display: 'inline-block',
        textTransform: 'capitalize',
        lineHeight: 1,
        margin: '5px 0 0'
    },
    button: {
        width: 70,
        height: 70,
        padding: 0,
        borderRadius: 8
    },
    eventsContainer: {
        display: 'inline-flex',
        height: '72px !important',
        width: 'auto',
        margin: '0 auto'
    },
    buttonBoxShadow: {
        boxShadow: `${theme.shadows[5]} !important`,
        borderRadius: 8,
    },
    buttonPrependTypography: {},
    prependBigText: {},
    scheduleArrangementConference: {},
    buttonGroup: {
        minWidth: 270,
        height: 72,
        padding: 0,
        borderRadius: 8,
        position: 'relative',
        color: theme.palette.primary.main,
        background: '#fff',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.08),
        },
        '&$scheduleArrangementConference': {
            minWidth: 320,
            width: 320,
            '& $buttonGroupPrepend': {
                verticalAlign: 'middle',
                display: 'table',
                '& $buttonPrependTypography': {
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    '& svg': {
                        fontSize: 40
                    }
                }
            },
            '& $buttonGroupSecondary': {
                '& $buttonSecondaryTypography': {
                    fontSize: 14,
                    '& $secondaryBigText': {
                        fontSize: 17,
                        lineHeight: 1,
                        marginTop: 2,
                        fontWeight: 400
                    }
                }
            }
        },
        '&:hover, &$buttonGroupFocus': {
            '& $buttonGroupPrepend': {
                opacity: 0.8,
            },
        }
    },
    buttonGroupFocus: {},
    buttonGroupDisabled: {
        opacity: 0.60,
        pointerEvents: 'auto !important' as 'auto'
    },
    buttonGroupPrepend: {
        width: 84,
        minWidth: 84,
        height: 58,
        padding: '6px 0px',
        display: 'inline-block',
        borderRadius: '6px 0px 0px 6px',
        '& $buttonPrependTypography': {
            color: '#fff',
            fontSize: 14,
            fontWeight: 200,
            lineHeight: 1,
        },
        '& $prependBigText': {
            color: '#fff',
            display: 'block',
            fontSize: 40,
            marginTop: 2,
            lineHeight: '40px'
        }
    },
    taskCardGridList: {
        margin: '12px auto 0 !important',
        width: 'calc(100% - 32px) !important',
        maxWidth: 'calc(100vw - 600px) !important',
        minWidth: 'calc(100% - 32px) !important'
    },
    gridList: {
        minHeight: 90,
        minWidth: 200,
        flexWrap: 'nowrap',
        margin: '12px 0 0',
        display: 'flex',
        padding: '0 16px !important',
        overflowY: 'auto',
        listStyle: 'none',
        scrollBehavior: 'smooth',
        '-webkit-overflow-scrolling': 'touch',
        '& li': {
            height: 'auto !important',
            width: 'auto !important',
        }
    },
    gridListTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
        margin: '0 13px'
    },
    gridListTile: {
        margin: '0 auto',
    },
    secondaryBigText: {},
    buttonSecondaryTypography: {},
    buttonGroupSecondary: {
        display: 'inline-block',
        '& $buttonSecondaryTypography': {
            position: 'relative',
            fontSize: 18,
            lineHeight: 1.2,
            fontWeight: 200,
            display: 'inline-block',
            padding: '0 26px',
            '& $secondaryBigText': {
                fontSize: 36,
                display: 'block',
                lineHeight: 1,
                marginTop: 6
            }
        }
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'addIcon' | 'buttonGroup' | 'buttonLabel'
    | 'button' | 'eventsContainer' | 'buttonGroupPrepend' | 'prependBigText' | 'buttonPrependTypography'
    | 'buttonGroupDisabled' | 'buttonGroupFocus' | 'gridList' | 'gridListTitle' | 'gridListTile'
    | 'buttonGroupSecondary' | 'buttonSecondaryTypography' | 'secondaryBigText'
    | 'scheduleArrangementConference' | 'buttonBoxShadow' | 'taskCardGridList' | 'lastGridList'>;

/**
 * Returns true if the existing list of events contains arrangement_event for the active case
 *
 * @param events
 * @param activeCase
 */
export const hasArrangementEvent = (events: GatherEvent[], activeCase: GatherCaseUX | null): boolean => {
    if (events.length === 0 || !activeCase) {
        return false;
    }

    const arrangementEvent: GatherEvent | undefined = events.filter((e) =>
        e.event_type === EventType.arrangement && e.gather_case_id === activeCase.id
    )[0];

    return !!arrangementEvent;
};

class EventSlider extends React.Component<StyledProps, State> {
    private isFirstLoad: boolean;
    constructor(props: StyledProps) {
        super(props);
        this.state = {
            activeEvent: null,
            isScheduleServicesDialogOpen: false
        };
        this.isFirstLoad = true;
    }

    openScheduleServicesDialog = (event: GatherEvent | null) => {
        this.setState({
            isScheduleServicesDialogOpen: true,
            activeEvent: event
        });
    };

    closeScheduleServicesDialog = () => {
        this.setState({
            isScheduleServicesDialogOpen: false,
            activeEvent: null
        });
    };

    openScheduleArrangementConferenceDialog = (event: GatherEvent | null) => {
        const { userData, zIndex, dispatch } = this.props;
        if (!UserRoles.isFHorGOMUser(userData)) {
            return;
        }
        this.setState({
            activeEvent: event
        });
        dispatch(openArrangementConferenceDialog(zIndex + 1));
    };

    renderAddArrangementEventButton = () => {
        const { classes } = this.props;

        return (
            <ListItem
                className={classNames(
                    classes.gridListTitle,
                    classes.buttonBoxShadow
                )}
            >
                <ButtonBase
                    focusRipple
                    className={classNames(
                        classes.buttonGroup,
                        classes.scheduleArrangementConference
                    )}
                    focusVisibleClassName={classes.buttonGroupFocus}
                    onClick={e => this.openScheduleArrangementConferenceDialog(null)}
                >
                    <div
                        className={classNames(
                            classes.buttonGroupPrepend,
                            classes.backgroundPrimary
                        )}
                    >
                        <Typography
                            align="center"
                            className={classes.buttonPrependTypography}
                            component="p"
                        >
                            <AccessTimeIcon />
                        </Typography>
                    </div>
                    <span
                        className={classes.buttonGroupSecondary}
                        style={{
                            borderLeft: 'none',
                            width: '100%'
                        }}
                    >
                        <Typography
                            component="span"
                            color="inherit"
                            className={classes.buttonSecondaryTypography}
                        >
                            Click to Schedule
                            <span className={classes.secondaryBigText}>
                                Arrangement Conference
                            </span>
                        </Typography>
                    </span>
                </ButtonBase>
            </ListItem>
        );
    };

    renderScheduleServicesDialog = () => {
        const { activeCase, zIndex } = this.props;
        const { isScheduleServicesDialogOpen, activeEvent } = this.state;

        if (!activeCase) {
            return null;
        }

        return (
            <ScheduleServicesDialog
                isDialogOpen={isScheduleServicesDialogOpen}
                closeDialog={this.closeScheduleServicesDialog}
                existingEvent={activeEvent ? activeEvent : undefined}
                selectedCase={activeCase}
                zIndex={zIndex + 2}
            />
        );
    };

    renderEvents = (events: GatherEvent[]) => {
        const {
            classes,
            userData,
            activeCase,
            zIndex
        } = this.props;

        const isFHorGOMUser = UserRoles.isFHorGOMUser(userData);

        return (
            <div className={classNames(classes.eventsContainer)}>
                {isFHorGOMUser && !hasArrangementEvent(events, activeCase) && this.renderAddArrangementEventButton()}

                <EventList
                    activeCase={activeCase}
                    events={events}
                    zIndex={zIndex + 1}
                    direction="horizontal"
                    openArrangementConferenceDialog={this.openScheduleArrangementConferenceDialog}
                    openScheduleServicesDialog={this.openScheduleServicesDialog}
                />

                {/* {isFamilyUser && <li className={classes.lastGridList} />} */}
                {isFHorGOMUser &&
                    <AddEventButton
                        hasArrangementEvent={hasArrangementEvent(events, activeCase)}
                        openScheduleServicesDialog={this.openScheduleServicesDialog}
                    />
                }
                {this.renderScheduleServicesDialog()}
            </div>
        );
    };

    render() {
        const {
            classes,
            isLoading,
            caseEvents,
            userData,
            parentComponent,
            renderOnlyAddEventButton,
            activeCase,
        } = this.props;

        if (!activeCase) {
            return null;
        }
        const isFamilyUser: boolean = UserRoles.isFamilyOnCase(userData, activeCase.id);

        if (isFamilyUser && caseEvents.length === 0) {
            return null;
        }

        if (renderOnlyAddEventButton) {
            return (
                <>
                    <AddEventButton
                        isFamilyUser={isFamilyUser}
                        hasEvents={caseEvents.length !== 0}
                        parentComponent={parentComponent}
                        hasArrangementEvent={hasArrangementEvent(caseEvents, activeCase)}
                        openScheduleServicesDialog={this.openScheduleServicesDialog}
                    />
                    {this.renderScheduleServicesDialog()}
                </>
            );
        }
        // once first event load is done we won't show zoom animation
        this.isFirstLoad = this.isFirstLoad ? isLoading : false;
        return (
            <Zoom in={!this.isFirstLoad || !isLoading} timeout={1200}>
                <List
                    className={classNames(
                        classes.gridList,
                        classes.root,
                        parentComponent && parentComponent === TaskTemplateType.schedule_services
                            ? classes.taskCardGridList : ''
                    )}
                    style={{
                        minWidth: caseEvents.length === 0 ? 0 : 200,
                    }}
                >
                    {this.renderEvents(caseEvents)}
                </List>
            </Zoom>
        );
    }
}

export default withState(mapStateToProps)(compose(
    withGStyles(styles),
    withFullScreen(),
)(EventSlider) as React.ComponentType<Props>);
