import { Component } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import {
    EntitySummary,
    FeatureKey,
    getUserFuneralHomeSettings,
    UserFuneralHomeSettings,
    UserProfile,
    UserRole
} from '../../../shared/types';
import {
    canEditUserRole,
} from '../../../shared/authority/can';

import { ALL_USER_PERMISSIONS_ON, AppPermissions, Permission } from '../../../shared/types/permissions';
import AppPermissionGridSwitch from './AppPermissionGridSwitch';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import FuneralHomeSelector, { mapUserFuneralHomeUXToElement } from './FuneralHomeSelector';
import { convertHexToRGBA } from '../../../services';
import { RED_COLOR } from '../../../constants/colorVariables';
import { isEnabled, WhenEnabled } from '../../common/AppFeature';
import { StoreState } from '../../../types';
import withState from '../../common/utilHOC/WithState';
import { TOOLTIPS } from '../../../constants';
import { CAN_ACCESS_PERMISSION_LOOKUP } from '../../common/hooks/usePermissionEnabled';
import values from 'lodash/values';

const styles: StyleRulesCallback<Theme, Props, Classes> = (theme) => ({
    root: {
        minHeight: 505,
        padding: '0 10px',
        '@media (min-width: 768px)': {
            padding: 0
        }
    },
    themeSelectMenu: {
        '&:focus': {
            background: 'transparent',
        },
    },
    accessContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        maxWidth: 760,
        margin: 'auto',
        '@media (min-width: 768px)': {
            flexDirection: 'row',
            alignItems: 'flex-start',
        }
    },
    gSwitchClass: {
        height: 32
    },
    gSwitchContainer: {
        marginBottom: 8,
        '@media (min-width: 768px)': {
            maxWidth: 352
        }
    },
    gSwitchHelperText: {
        marginLeft: 48,
        marginTop: -4,
        fontSize: 13,
        lineHeight: 1.25,
        '@media (min-width: 420px)': {
            fontSize: 14
        }
    },
    labelClass: {
        fontSize: 13,
        color: theme.palette.primary.main,
        '@media (min-width: 420px)': {
            fontSize: 14
        }
    },
    fhAdminAtLocGrid: {
        maxWidth: 348,
        borderRadius: 8,
        border: `1px solid ${RED_COLOR}`,
        background: convertHexToRGBA(RED_COLOR, 0.08),
        padding: '0 0 8px 8px',
        '& p': {
            fontSize: 13,
            lineHeight: 1.25
        }
    },
    userTitleGrid: {
        maxWidth: 332,
        width: '100%',
        marginBottom: 16
    },
    userRoleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 16,
    },
    marginRight0: {
        marginRight: 0
    },
    inner: {
        marginTop: 8,
        '@media (min-width: 768px)': {
            marginTop: 0,
        }
    },
    reportsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    redAccessContainer: {
        margin: '16px auto',
        alignItems: 'center',
        '@media (min-width: 768px)': {
            alignItems: 'flex-start',
        }
    }
});

type Classes = 'root' | 'themeSelectMenu' | 'accessContainer' | 'gSwitchClass'
    | 'gSwitchContainer' | 'gSwitchHelperText' | 'labelClass' | 'fhAdminAtLocGrid' | 'userTitleGrid'
    | 'userRoleContainer' | 'marginRight0' | 'reportsContainer' | 'inner' | 'redAccessContainer';
type StyledProps = WithGStyles<Classes>;

function mapStateToProps({ userSession, funeralHomeState, casesState, }: StoreState) {
    return {
        userSession,
        funeralHomeState,
        casesState
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    funeralHomeIdInContext: number | null;
    selectedFuneralHomeId: number | null;
    selectFuneralHome: (id: number) => void;
    isSaving: boolean;
    isMe: boolean;
    selectedPerson: EntitySummary;
    zIndex: number;
    user: UserProfile;
    isFHAdminAtLoc: boolean;
    funeralHomeSettings: UserFuneralHomeSettings[];
    onTitleChange: (title: string) => void;
    onPermissionChange: (permissions: AppPermissions) => void;
}

type CombinedProps = Props & StyledProps;

interface State {
    userTitle: string;
}

const initializeState = (props: CombinedProps): State => {
    const { funeralHomeSettings, selectedFuneralHomeId } = props;
    const thisFuneralHomeSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
    return {
        userTitle: thisFuneralHomeSettings && thisFuneralHomeSettings.visible_title || '',
    };
};

class SecurityTab extends Component<CombinedProps, State> {
    state: State = initializeState(this.props);

    debounceUserTitleValue = debounce(
        (userTitle: string) => {
            const { onTitleChange } = this.props;
            onTitleChange(userTitle);
        },
        300,
    );

    componentWillUnmount() {
        // save the last changes before unmounting
        this.debounceUserTitleValue.flush();
    }

    selectAllPermissions() {
        const { onPermissionChange } = this.props;
        onPermissionChange(ALL_USER_PERMISSIONS_ON);
    }

    initializeAllPermissions(): AppPermissions | null {
        const { selectedPerson, funeralHomeIdInContext } = this.props;

        if (!selectedPerson || funeralHomeIdInContext === null) {
            return null;
        }

        const permissions: Partial<Record<Permission, boolean>> = {};
        for (const permission of values(Permission)) {
            permissions[permission] = CAN_ACCESS_PERMISSION_LOOKUP[permission](selectedPerson, funeralHomeIdInContext);
        }
        // because we are using values(Permission) we know every Permission is included in the permissions object
        // unfortunately, typescript can't figure that out, so we have to cast it
        return permissions as Record<Permission, boolean>;
    }

    render() {
        const {
            classes,
            selectedFuneralHomeId,
            selectFuneralHome,
            selectedPerson,
            isMe,
            isFHAdminAtLoc,
            funeralHomeSettings,
            userSession,
            funeralHomeState,
            casesState
        } = this.props;
        const { userTitle } = this.state;
        const thisUserFuneralHomeSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
        const currentPermissions = thisUserFuneralHomeSettings ? thisUserFuneralHomeSettings.permissions
            : this.initializeAllPermissions();
        if (!currentPermissions) {
            return;
        }

        const viewMonthlyFinancialRollup = currentPermissions[Permission.view_monthly_financial_rollup];
        const viewCaseVitals = currentPermissions[Permission.view_case_vitals];
        const viewCaseAutoforms = currentPermissions[Permission.view_case_autoforms];
        const viewCaseFinancialData = currentPermissions[Permission.view_case_financial_data];
        const accessPaymentReport = currentPermissions[Permission.access_report_payment];
        const accessRevenueReport = currentPermissions[Permission.access_report_revenue];
        const accessBatchReport = currentPermissions[Permission.access_report_batch];
        const accessCasesReport = currentPermissions[Permission.access_report_cases];
        const accessHelpersReport = currentPermissions[Permission.access_report_helpers];
        const accessRolodexReport = currentPermissions[Permission.access_report_rolodex];
        const accessKeeptrackReport = currentPermissions[Permission.access_report_keeptrack];
        const inviteTeam = currentPermissions[Permission.invite_other_team_members];
        const removeTeam = currentPermissions[Permission.remove_other_team_members];
        const managePermission = currentPermissions[Permission.manage_permission];
        const beAssignedToCase = currentPermissions[Permission.be_assigned_to_case];
        const modifyWorkflow = currentPermissions[Permission.modify_workflows];
        const skipTrackingSteps = currentPermissions[Permission.skip_tracking_steps];
        const completeTrackingSteps = currentPermissions[Permission.complete_tracking_steps];
        const editTrackingPrerequisite = currentPermissions[Permission.edit_tracking_prerequisite];
        const contractItemDiscounts = currentPermissions[Permission.CONTRACT_ITEM_DISCOUNTS];
        const contractModifyItemName = currentPermissions[Permission.CONTRACT_MODIFY_ITEM_NAME];
        const contractDiscounts = currentPermissions[Permission.CONTRACT_DISCOUNTS];
        const modifyPrices = currentPermissions[Permission.MODIFY_PRICES];
        const addPayments = currentPermissions[Permission.ADD_PAYMENTS];
        const editPayments = currentPermissions[Permission.EDIT_PAYMENTS];
        const removePayments = currentPermissions[Permission.REMOVE_PAYMENTS];
        const editGPL = currentPermissions[Permission.EDIT_GPL];
        const contractUnfreeze = currentPermissions[Permission.CONTRACT_UNFREEZE];
        const contractOneOffItems = currentPermissions[Permission.CONTRACT_ONE_OFF_ITEMS];
        const addItems = currentPermissions[Permission.ADD_ITEMS];
        const removeItems = currentPermissions[Permission.REMOVE_ITEMS];
        const adminCaseLabels = currentPermissions[Permission.CASE_LABELS];
        const collectionsReport = currentPermissions[Permission.COLLECTIONS_REPORT];
        const overpaidContractsReport = currentPermissions[Permission.OVERPAID_CONTRACTS];
        const discountsReport = currentPermissions[Permission.DISCOUNTS_REPORT];
        const priceAdjustmentReport = currentPermissions[Permission.PRICE_ADJUSTMENTS_REPORT];
        const merchSalesReport = currentPermissions[Permission.MERCH_SALES_REPORT];
        const helpers = currentPermissions[Permission.HELPER_REPORT];
        const informants = currentPermissions[Permission.INFORMANT_REPORT];
        const priceList = currentPermissions[Permission.PRICE_LIST];
        const caseSummary = currentPermissions[Permission.CASE_SUMMARY];
        const workflowByCase = currentPermissions[Permission.WORKFLOW_BY_CASE_REPORT];
        const salesTaxReport = currentPermissions[Permission.SALES_TAX_REPORT];
        const dispatchReport = currentPermissions[Permission.DISPATCH_REPORT];
        const prepReport = currentPermissions[Permission.PREP_REPORT];

        const caseNonFinancialDataSource = currentPermissions[Permission.DATA_SOURCE_CASE_NON_FINANCIAL];
        const caseFinancialDataSource = currentPermissions[Permission.DATA_SOURCE_CASE_FINANCIAL];
        const caseVitalsDataSource = currentPermissions[Permission.DATA_SOURCE_CASE_VITALS];
        const helpersDataSource = currentPermissions[Permission.DATA_SOURCE_HELPERS];
        const paymentsDataSource = currentPermissions[Permission.DATA_SOURCE_PAYMENTS];
        const invoiceLineItemsDataSource = currentPermissions[Permission.DATA_SOURCE_INVOICE_LINE_ITEMS];
        const eventsDataSource = currentPermissions[Permission.DATA_SOURCE_EVENTS];
        const notesDataSource = currentPermissions[Permission.DATA_SOURCE_NOTES];
        const taskStepActivityDataSource = currentPermissions[Permission.DATA_SOURCE_TASK_STEP_ACTIVITY];
        const rememberPageDataSource = currentPermissions[Permission.DATA_SOURCE_REMEMBER_PAGE];

        const funeralHomes = selectedPerson.funeral_homes;
        const thisFuneralHome = selectedPerson.funeral_homes?.find(fh => fh.funeral_home_id === selectedFuneralHomeId);

        const keepTrackEnabled = isEnabled(FeatureKey.KEEP_TRACK, funeralHomeState, casesState, userSession);
        const configWorkflows = isEnabled(FeatureKey.CONFIGURE_WORKFLOWS, funeralHomeState, casesState, userSession);

        /**
         *  NOTE: isDisabled -- disable the fields if managePermission is set 
         *  except if the webpage user is editing themself then we need to check to 
         *  see if they have managePermission (because the user on the page may be viewing a FH that they 
         *  do not have access to manage), if it is set, then DO NOT disable the other 
         *  permissions, so that the user can turn off any other permission to test.  However, 
         *  the managePermission will not be able to be turned off (so that the FH does not lock
         *  out themselves by accidentally removing all users that can managePermission)
         *  However note that if the user is on a funeral home that they have managePermission on, they will
         *  see any other funeral home they have access to, so we must check to see if they also 
         *  have manage permission on the funeral home.
         * 
         */
        const isDisabled = isMe ?
            isFHAdminAtLoc ? false : true
            : managePermission;

        return (
            <Grid container className={classes.root}>
                <Grid item xs={12} >
                    <FuneralHomeSelector
                        funeralHomes={funeralHomes?.map(mapUserFuneralHomeUXToElement) || null}
                        selectedFuneralHomeIds={selectedFuneralHomeId ? [selectedFuneralHomeId] : []}
                        selectFuneralHome={selectFuneralHome}
                    />

                    <Typography
                        color="secondary"
                        align="center"
                        className={classNames(classes.fontWeight400, classes.marginTop30)}
                    >
                        The settings below apply for {selectedPerson.fname} {selectedPerson.lname} at&nbsp;
                        <span className={classNames(classes.colorPrimary, classes.fontWeight500)}>
                            {thisFuneralHome?.funeral_home_name}
                            &nbsp;({thisFuneralHome?.funeral_home_city})
                        </span>
                    </Typography>
                    <Grid item className={classes.userRoleContainer}>
                        <Grid item xs={12} className={classNames(classes.userTitleGrid)}>
                            <TextField
                                label={`${selectedPerson.fname}'s Title`}
                                fullWidth
                                value={userTitle}
                                onChange={e => this.handleUserTitleChange(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.accessContainer,
                            classes.redAccessContainer
                        )}
                    >
                        <Grid item>
                            <AppPermissionGridSwitch
                                key={Permission.manage_permission}
                                label={'Is a FUNERAL HOME ADMIN at this location'}
                                helperTextWarning={'BE CAREFUL'}
                                helperText={
                                    `A Funeral Home Admin can change other people’s access levels and settings, ` +
                                    `including their own. This setting is typically reserved for owners.`
                                }
                                labelClasses={classes.colorRed}
                                formControlLabelClasses={classes.marginRight0}
                                isChecked={isMe && isFHAdminAtLoc ? true : managePermission}
                                isMe={isMe}
                                onChange={
                                    (checked: boolean) => {
                                        if (checked) {
                                            this.selectAllPermissions();
                                        } else {
                                            this.initializeAllPermissions();
                                            this.handleUserPermissionChange(Permission.manage_permission, checked);
                                        }
                                    }
                                }
                                isDisabled={isMe} // disabled if editing themself as we dont want to lock out the user. 
                            />
                        </Grid>
                        <Grid item className={classes.inner}>
                            <AppPermissionGridSwitch
                                key={Permission.remove_other_team_members}
                                label={'Can REMOVE OTHER TEAM MEMBERS '}
                                helperTextWarning={'BE CAREFUL'}
                                helperText={
                                    'This will allow the ability to REMOVE ANY team member from the Application.'
                                }
                                labelClasses={classes.colorRed}
                                formControlLabelClasses={classes.marginRight0}
                                isChecked={removeTeam}
                                isMe={isMe}
                                onChange={
                                    (checked: boolean) => {
                                        this.handleUserPermissionChange(Permission.remove_other_team_members, checked);
                                    }
                                }
                                isDisabled={isDisabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.accessContainer}>
                    <Grid item>
                        <AppPermissionGridSwitch
                            key={Permission.view_case_financial_data}
                            label={'Can view CASE FINANCIAL DATA'}
                            toolTipText={
                                'Controls the visibility of the financial data on \
                                    each case card and within each case.'
                            }
                            isChecked={viewCaseFinancialData}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.view_case_financial_data, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.view_monthly_financial_rollup}
                            label={'Can view MONTHLY FINANCIAL ROLLUP'}
                            toolTipText={'Controls the visibility of the monthly financial rollup ' +
                                'on the top of the dashboard page.'}
                            isChecked={viewMonthlyFinancialRollup}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.view_monthly_financial_rollup, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.CONTRACT_ITEM_DISCOUNTS}
                            label={'Can adjust STATEMENT ITEMS'}
                            toolTipText={'Controls the ability to add a Discount or Premium on an ITEM.'}
                            isChecked={contractItemDiscounts}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.CONTRACT_ITEM_DISCOUNTS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.CONTRACT_DISCOUNTS}
                            label={'Can adjust STATEMENT TOTAL'}
                            toolTipText={'Controls the ability to add a Discount or Premium on the STATEMENT.'}
                            isChecked={contractDiscounts}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.CONTRACT_DISCOUNTS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.MODIFY_PRICES}
                            label={'Can Modify price'}
                            toolTipText={'Controls the ability to modify price of items both inside ' +
                                'and outside of statement.'}
                            isChecked={modifyPrices}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.MODIFY_PRICES, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.ADD_PAYMENTS}
                            label={'Can Add Payments'}
                            toolTipText={'Controls the ability to add payments to a case.'}
                            isChecked={addPayments}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.ADD_PAYMENTS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.EDIT_PAYMENTS}
                            label={'Can Edit Payments'}
                            toolTipText={'Controls the ability to revise payments on a case.'}
                            isChecked={editPayments}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.EDIT_PAYMENTS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.REMOVE_PAYMENTS}
                            label={'Can Remove Payments'}
                            toolTipText={'Controls the ability to delete payments on a case.'}
                            isChecked={removePayments}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.REMOVE_PAYMENTS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.EDIT_GPL}
                            label={'Can access GOODS & SERVICES configuration'}
                            toolTipText={'Controls the ability to access the GOODS & SERVICES ' +
                                'configuration module to make system-wide edits.'}
                            isChecked={editGPL}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.EDIT_GPL, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.CONTRACT_UNFREEZE}
                            label={'Can UNFREEZE Statements'}
                            toolTipText={'Controls the ability to be able to UNFREEZE the statement ' +
                                'or add items to statement when it is frozen then unfreeze it.'}
                            isChecked={contractUnfreeze}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.CONTRACT_UNFREEZE, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.CONTRACT_MODIFY_ITEM_NAME}
                            label={'Can Modify Item Name'}
                            toolTipText={'Controls the ability to modify item names on statement.'}
                            isChecked={contractModifyItemName}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.CONTRACT_MODIFY_ITEM_NAME, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.CONTRACT_ONE_OFF_ITEMS}
                            label={'Can Add one-time Items'}
                            toolTipText={'Controls the ability to add an item that is not on the ' +
                                'GPL both inside and outside of the statement.'}
                            isChecked={contractOneOffItems}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.CONTRACT_ONE_OFF_ITEMS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.ADD_ITEMS}
                            label={'Can Add Items'}
                            toolTipText={'Controls the ability to add an item that is on the GPL both ' +
                                'inside and outside of the statement.'}
                            isChecked={addItems}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.ADD_ITEMS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.REMOVE_ITEMS}
                            label={'Can Remove Items'}
                            toolTipText={'Controls the ability to remove an item both inside and outside ' +
                                'of the statement.'}
                            isChecked={removeItems}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.REMOVE_ITEMS, checked)
                            }
                            isDisabled={isDisabled}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.CASE_LABELS}
                            label={'Can Edit, Delete and Create LABELS'}
                            toolTipText={'Controls the ability to create, edit or delete case labels.'}
                            isChecked={adminCaseLabels}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.CASE_LABELS, checked)
                            }
                            isDisabled={isDisabled}
                        />
                    </Grid>

                    <Grid item>
                        <Grid item>
                            <AppPermissionGridSwitch
                                key={Permission.invite_other_team_members}
                                label={'Can INVITE OTHER TEAM MEMBERS'}
                                toolTipText={
                                    'Each newly invited team member can view Case Financial Data, Autoforms, ' +
                                    'and Vitals unless these permissions are restricted by a FH ADMIN'
                                }
                                isChecked={inviteTeam}
                                isMe={isMe}
                                onChange={(checked: boolean) => this.handleUserPermissionChange(
                                    Permission.invite_other_team_members, checked)
                                }
                                isDisabled={isDisabled}
                            />

                            <AppPermissionGridSwitch
                                key={Permission.view_case_autoforms}
                                label={'Can view CASE AUTOFORMS'}
                                toolTipText={'Controls access to AutoForms AND Case Files'
                                    + ' on each case card and within each case.'}
                                isChecked={viewCaseAutoforms}
                                isMe={isMe}
                                onChange={(checked: boolean) => this.handleUserPermissionChange(
                                    Permission.view_case_autoforms, checked)
                                }
                                isDisabled={isDisabled}
                            />

                            <AppPermissionGridSwitch
                                key={Permission.view_case_vitals}
                                label={'Can view CASE VITALS'}
                                toolTipText={'Controls access to the Vitals information with each case.'}
                                isChecked={viewCaseVitals}
                                isMe={isMe}
                                onChange={(checked: boolean) => this.handleUserPermissionChange(
                                    Permission.view_case_vitals, checked)
                                }
                                isDisabled={isDisabled}
                            />

                            <AppPermissionGridSwitch
                                key={Permission.be_assigned_to_case}
                                label={'Can be ASSIGNED TO A CASE'}
                                toolTipText={`Controls the ability to specify ${selectedPerson.fname} as the assigned `
                                    + 'funeral director on a case.'}
                                isChecked={beAssignedToCase}
                                isMe={isMe}
                                onChange={(checked: boolean) => this.handleUserPermissionChange(
                                    Permission.be_assigned_to_case, checked)
                                }
                                isDisabled={isDisabled}
                            />

                            <AppPermissionGridSwitch
                                key={Permission.modify_workflows}
                                label={'Can modify FUNERAL HOME WORKFLOWS'}
                                toolTipText={'Controls the ability to modify WorkFlows at the FH level, including ' +
                                    'Tracking Steps, Standard Tasks, and After-care Tasks. WorkFlow Edits CAN still ' +
                                    'be made at the case level.'}
                                isChecked={modifyWorkflow}
                                isMe={isMe}
                                onChange={(checked: boolean) => this.handleUserPermissionChange(
                                    Permission.modify_workflows, checked)
                                }
                                isDisabled={isDisabled || !configWorkflows}
                                disabledTooltip={TOOLTIPS.DISABLED_FEATURE_CONTACT_GATHER}
                            />
                        </Grid>

                        <Typography color="primary" className={classNames(classes.marginBottom10, classes.marginTop20)}>
                            {selectedPerson.fname} can access the following REPORTS:
                        </Typography>

                        <Grid className={classes.reportsContainer}>
                            <Grid>
                                <AppPermissionGridSwitch
                                    key={Permission.access_report_cases}
                                    label={'CASES'}
                                    isChecked={accessCasesReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_cases, checked)
                                    }
                                    isDisabled={isDisabled}
                                />

                                <AppPermissionGridSwitch
                                    key={Permission.access_report_helpers}
                                    label={'HELPERS'}
                                    isChecked={accessHelpersReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_helpers, checked)
                                    }
                                    isDisabled={isDisabled}
                                />

                                <AppPermissionGridSwitch
                                    key={Permission.access_report_rolodex}
                                    label={'ROLODEX'}
                                    isChecked={accessRolodexReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_rolodex, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.access_report_keeptrack}
                                    label={'KEEPTRACK'}
                                    isChecked={accessKeeptrackReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_keeptrack, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                            </Grid>

                            <Grid className={classes.marginLeft20}>
                                <AppPermissionGridSwitch
                                    key={Permission.access_report_batch}
                                    label={'BATCH'}
                                    isChecked={accessBatchReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_batch, checked)
                                    }
                                    isDisabled={isDisabled}
                                />

                                <AppPermissionGridSwitch
                                    key={Permission.access_report_revenue}
                                    label={'REVENUE'}
                                    isChecked={accessRevenueReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_revenue, checked)
                                    }
                                    isDisabled={isDisabled}
                                />

                                <AppPermissionGridSwitch
                                    key={Permission.access_report_payment}
                                    label={'PAYMENTS'}
                                    isChecked={accessPaymentReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.access_report_payment, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        <WhenEnabled feature={FeatureKey.GATHER_ANALYTICS}>
                        <Typography color="primary" className={classNames(classes.marginBottom10, classes.marginTop20)}>
                            {selectedPerson.fname} can access the following DATA SOURCES:
                        </Typography>
                            <Grid className={classes.reportsContainer}>
                                <Grid>
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_CASE_NON_FINANCIAL}
                                        label={'CASE (NON-FINANCIAL)'}
                                        isChecked={caseNonFinancialDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_CASE_NON_FINANCIAL, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />

                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_CASE_FINANCIAL}
                                        label={'CASE (FINANCIAL)'}
                                        isChecked={caseFinancialDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_CASE_FINANCIAL, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />

                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_CASE_VITALS}
                                        label={'CASE VITALS'}
                                        isChecked={caseVitalsDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_CASE_VITALS, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_REMEMBER_PAGE}
                                        label={'REMEMBER PAGE'}
                                        isChecked={rememberPageDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_REMEMBER_PAGE, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_PAYMENTS}
                                        label={'PAYMENTS'}
                                        isChecked={paymentsDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_PAYMENTS, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                </Grid>

                                <Grid className={classes.marginLeft20}>
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_HELPERS}
                                        label={'HELPERS'}
                                        isChecked={helpersDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_HELPERS, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_EVENTS}
                                        label={'EVENTS'}
                                        isChecked={eventsDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_EVENTS, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_INVOICE_LINE_ITEMS}
                                        label={'INVOICE LINE ITEMS'}
                                        isChecked={invoiceLineItemsDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_INVOICE_LINE_ITEMS, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_NOTES}
                                        label={'NOTES'}
                                        isChecked={notesDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_NOTES, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                    <AppPermissionGridSwitch
                                        key={Permission.DATA_SOURCE_TASK_STEP_ACTIVITY}
                                        label={'TASK/STEP ACTIVITY'}
                                        isChecked={taskStepActivityDataSource}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DATA_SOURCE_TASK_STEP_ACTIVITY, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>
                        </WhenEnabled>

                        <Typography color="primary" className={classNames(classes.marginBottom10, classes.marginTop20)}>
                            {selectedPerson.fname} can <u>download</u> the following REPORTS:
                        </Typography>


                        <Grid className={classes.reportsContainer}>
                            <Grid>
                                <AppPermissionGridSwitch
                                    key={Permission.COLLECTIONS_REPORT}
                                    label={'COLLECTIONS'}
                                    isChecked={collectionsReport}
                                    toolTipText={`This permission controls access to the` +
                                        ` Collections report.`}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.COLLECTIONS_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.OVERPAID_CONTRACTS}
                                    label={'OVERPAID'}
                                    isChecked={overpaidContractsReport}
                                    toolTipText={`This permission controls access to the` +
                                        ` Overpaid Contracts reports.`}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.OVERPAID_CONTRACTS, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.MERCH_SALES_REPORT}
                                    label={'MERCH SALES REPORT'}
                                    isChecked={merchSalesReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.MERCH_SALES_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.PRICE_LIST}
                                    label={'PRICE LIST'}
                                    isChecked={priceList}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.PRICE_LIST, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.DISCOUNTS_REPORT}
                                    label={'DISCOUNTS REPORT'}
                                    isChecked={discountsReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.DISCOUNTS_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.PRICE_ADJUSTMENTS_REPORT}
                                    label={'PRICE ADJUSTMENT REPORT'}
                                    isChecked={priceAdjustmentReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.PRICE_ADJUSTMENTS_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                            </Grid>

                            <Grid className={classes.marginLeft20}>
                                <AppPermissionGridSwitch
                                    key={Permission.CASE_SUMMARY}
                                    label={'CASE SUMMARY'}
                                    isChecked={caseSummary}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.CASE_SUMMARY, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.HELPER_REPORT}
                                    label={'HELPER REPORT'}
                                    isChecked={helpers}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.HELPER_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.INFORMANT_REPORT}
                                    label={'INFORMANT REPORT'}
                                    isChecked={informants}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.INFORMANT_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.WORKFLOW_BY_CASE_REPORT}
                                    label={'WORKFLOW BY CASE REPORT'}
                                    isChecked={workflowByCase}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.WORKFLOW_BY_CASE_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <AppPermissionGridSwitch
                                    key={Permission.SALES_TAX_REPORT}
                                    label={'SALES TAX REPORT'}
                                    isChecked={salesTaxReport}
                                    isMe={isMe}
                                    onChange={(checked: boolean) => this.handleUserPermissionChange(
                                        Permission.SALES_TAX_REPORT, checked)
                                    }
                                    isDisabled={isDisabled}
                                />
                                <WhenEnabled feature={FeatureKey.DISPATCH_REPORT}>
                                    <AppPermissionGridSwitch
                                        key={Permission.DISPATCH_REPORT}
                                        label={'DISPATCH REPORT'}
                                        isChecked={dispatchReport}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.DISPATCH_REPORT, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                </WhenEnabled>
                                <WhenEnabled feature={FeatureKey.PREP_REPORT}>
                                    <AppPermissionGridSwitch
                                        key={Permission.PREP_REPORT}
                                        label={'PREP REPORT'}
                                        isChecked={prepReport}
                                        isMe={isMe}
                                        onChange={(checked: boolean) => this.handleUserPermissionChange(
                                            Permission.PREP_REPORT, checked)
                                        }
                                        isDisabled={isDisabled}
                                    />
                                </WhenEnabled>
                            </Grid>
                        </Grid>

                        <Typography
                            color="primary"
                            className={classNames(classes.marginBottom10, classes.marginTop20)}
                        >
                            {selectedPerson.fname} has the following KEEPTRACK permissions:
                        </Typography>

                        <AppPermissionGridSwitch
                            key={Permission.complete_tracking_steps}
                            label={'Can COMPLETE TRACKING STEPS'}
                            toolTipText={'Controls the ability to complete tracking steps and ' +
                                'movements at the case level.'}
                            isChecked={completeTrackingSteps}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.complete_tracking_steps, checked)
                            }
                            isDisabled={isDisabled || !keepTrackEnabled}
                            disabledTooltip={TOOLTIPS.DISABLED_FEATURE_CONTACT_GATHER}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.skip_tracking_steps}
                            label={'Can SKIP TRACKING STEPS'}
                            toolTipText={'Controls the ability to skip tracking steps at the case ' +
                                'level. Skipping is always recorded.'}
                            isChecked={skipTrackingSteps}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.skip_tracking_steps, checked)
                            }
                            isDisabled={isDisabled || !keepTrackEnabled}
                            disabledTooltip={TOOLTIPS.DISABLED_FEATURE_CONTACT_GATHER}
                        />

                        <AppPermissionGridSwitch
                            key={Permission.edit_tracking_prerequisite}
                            label={'Can EDIT TRACKING STEP PREREQUISITES'}
                            toolTipText={'Controls the ability to add or remove prerequisite steps ' +
                                'on any given tracking step at the case level.'}
                            isChecked={editTrackingPrerequisite}
                            isMe={isMe}
                            onChange={(checked: boolean) => this.handleUserPermissionChange(
                                Permission.edit_tracking_prerequisite, checked)
                            }
                            isDisabled={isDisabled || !keepTrackEnabled}
                            disabledTooltip={TOOLTIPS.DISABLED_FEATURE_CONTACT_GATHER}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    isUserRoleDisabled = (desiredRole: UserRole): boolean => {
        const { user, selectedPerson } = this.props;
        return !canEditUserRole(user, selectedPerson, desiredRole);
    };

    handleUserTitleChange = (userTitle: string) => {
        this.setState({ userTitle });
        this.debounceUserTitleValue(userTitle);
    };

    handleUserPermissionChange = (permission: Permission, value: boolean) => {
        const { onPermissionChange, funeralHomeSettings, selectedFuneralHomeId } = this.props;
        const thisUserFuneralHomeSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
        if (!thisUserFuneralHomeSettings) {
            return;
        }
        const newPermissions = {
            ...thisUserFuneralHomeSettings.permissions,
            [permission]: value,
        };
        onPermissionChange(newPermissions);
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(SecurityTab));
