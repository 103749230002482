import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { HOMESTEADER_LOGO } from '../../../../constants';
import { APP_SECONDARY_COLOR, INSURANCE_CARRIER_COLORS } from '../../../../constants/colorVariables';
import { FuneralHomeUX, GatherCaseUX } from '../../../../shared/types';
import PolicyDialog from '../../../cases/policy/Policy.dialog';
import GButton from '../../../common/GButton';

interface Props {
    activeCase: Pick<GatherCaseUX, 'insurance_policies'>;
    title: string;
    subtitle: string;
    zIndex: number;
    funeralHome: FuneralHomeUX | null;
    btntext: string;
}

const PreArrangedGoodAndServices = (props: Props) => {
    const { title, subtitle, zIndex, funeralHome, btntext, activeCase } = props;
    const [policyDialogOpen, setPolicyDialogOpen] = useState<boolean>(false);

    if (!funeralHome) {
        return null;
    }

    return (
        <>
            <Grid
                sx={{
                    maxWidth: '450px',
                    width: 'calc(100% - 36px)',
                    textAlign: 'center',
                    margin: '20px auto',
                    boxShadow:
                        // eslint-disable-next-line max-len
                        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
                    borderRadius: '8px',
                }}
            >
                <Grid item p="12px">
                    <img src={HOMESTEADER_LOGO} style={{ width: '62px' }} />
                    <Typography color={APP_SECONDARY_COLOR} fontSize={18} fontWeight={300}>
                        {title}
                    </Typography>
                    <Typography color={APP_SECONDARY_COLOR} fontSize={12} fontWeight={300} lineHeight={'1.25em'}>
                        {subtitle}
                    </Typography>
                </Grid>
                <GButton
                    fullWidth
                    text={btntext}
                    sx={{
                        borderRadius: '0 0 8px 8px !important',
                    }}
                    onClick={() => setPolicyDialogOpen(true)}
                />
            </Grid>

            {policyDialogOpen && activeCase.insurance_policies.length > 0 && (
                <PolicyDialog
                    isOpen={policyDialogOpen}
                    closeDialog={() => setPolicyDialogOpen(false)}
                    zIndex={zIndex}
                    funeralHome={funeralHome}
                    initialPolicyId={activeCase.insurance_policies[0]}
                    insuranceColor={INSURANCE_CARRIER_COLORS.HOMESTEADERS}
                />
            )}
        </>
    );
};

export default PreArrangedGoodAndServices;
