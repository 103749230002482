import { useEffect, useState } from 'react';
import {
    DeathCertificatePatchRequest,
    GmapsSearchType,
    LongAddress,
    NullLongAddress,
    isLongAddress,
} from '../../../shared/types';
import GDialogWithTextField from '../../common/GDialogWithTextField';
import GmapsSearch, { GmapsSearchAddress } from '../../gmapsSearch/GmapsSearch';
import { GStyles } from '../../../styles/GStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGDispatch } from '../../../types';
import { patchDeathCertificate } from '../../../actions/GatherCase.action';

const useStyles = makeStyles((theme) => ({
    gmapsSearch: {
        maxWidth: 'unset',
        width: '100%',
    },
    margin0: {
        margin: 0,
    },
    controlSuggestionClasses: {
        position: 'relative',
    },
}));

interface Props {
    zIndex: number;
    open: boolean;
    dropOffAddress: LongAddress | null;
    caseFullName: string;
    caseUuid: string;
    onClose: () => void;
    onSaveCallback?: () => void;
}
const DropOffAddressDialog = (props: Props) => {
    const { open, dropOffAddress, caseFullName, caseUuid, zIndex, onSaveCallback, onClose } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const [address, setAddress] = useState<LongAddress | null>(null);

    useEffect(() => {
        setAddress(dropOffAddress);
    }, [dropOffAddress]);

    const handleAddressChange = (_dropOffAddress: GmapsSearchAddress) => {
        if (isLongAddress(_dropOffAddress)) {
            setAddress(_dropOffAddress);
        }
    };

    const handleSave = async () => {
        if (!address) {
            return;
        }

        const changes: DeathCertificatePatchRequest = {
            about: { dropOffAddress: address },
        };

        onClose();

        await dispatch(
            patchDeathCertificate({
                changes,
                caseUuid: caseUuid,
            }),
        );

        onSaveCallback?.();
    };

    return (
        <GDialogWithTextField
            zIndex={zIndex}
            title="Decedent Drop-Off Address"
            initialValue={null}
            isDialogOpen={open}
            textFieldLabel={''}
            closeDialog={onClose}
            onSave={handleSave}
            skipValidation
            description={
                <>
                    <span className={GStyles.fontWeight600}>{caseFullName}'s</span> Drop-Off Address
                </>
            }
            renderCustomTextfield={() => (
                <GmapsSearch
                    type={GmapsSearchType.longAddress}
                    controlClasses={classes.gmapsSearch}
                    textLabel={'Drop-Off Address'}
                    value={address ?? (dropOffAddress ?? NullLongAddress)}
                    error={false}
                    onSetPlace={handleAddressChange}
                    required={false}
                    zIndex={1320}
                    formControlStyleClasses={GStyles.margin0}
                    controlSuggestionClasses={classes.controlSuggestionClasses}
                />
            )}
        />
    );
};

export default DropOffAddressDialog;