import generateUuid from 'uuid';
import { first, orderBy, has, some, every, find, findIndex, includes, keyBy, partition, each, slice } from 'lodash';
import {
    ProductContractItemUX,
    ProductContractUX,
    ProductPackageUX,
    ProductUX,
    ProductPackageItemUX,
    ProductSubPackageItem,
    ProductPackageItemOptionUX,
    ProductSubPackageItemOption,
    ProductTaxRateUX,
    ProductContractItemUpdateRequest,
    ProductCustomContractItemRequest,
    ProductContractItemCreateRequest,
    PricingModelEnum,
    AssetType,
    ContractItemType,
} from '../types';
import {
    getProductQuantity,
    convertTaxRateBracketsToString,
    calculateProductListPrice,
    calcTaxForContractItem,
    applyTaxRate,
    calculatePackageAdjustment,
    calculateContractItemListPrice,
    calculateAllowanceCredit,
    calculateTaxes,
    getDinero,
    getContractItemListPrice,
} from './pricing';
import {
    getLatestItem,
    getLatestItems,
    isAllowanceItem,
    isAllowanceCredit,
    isPackageDiscount,
    isPackagePlaceholder,
} from './utils';
import {
    getPackagesInContract,
    shouldRemovingContractItemBreakPackage,
    findContractPackageForSubPackageId,
    findPackageItemsWithProductAsMultiOption,
    findMultiOptionPackageItems,
    getSubPackages,
    findContractItemsThatArePackageItemOptions,
    findValidPackageItemsToAddProduct,
    findMultiOptionPackageItem,
    getContractItemsForPackage,
} from './packages';

type ContractItemUpdateMap = {
    [id: string]: Partial<ProductContractItemUX>;
};

export type ContractItemActions = {
    itemsToAdd: ProductContractItemUX[];
    itemUpdates: ContractItemUpdateMap;
    itemIdsToRemove: string[];
};

// this mutates existing actions with newActions
const updateExistingActions = (
    itemsToAdd: ProductContractItemUX[],
    itemUpdates: ContractItemUpdateMap,
    itemIdsToRemove: string[],
    newActions: ContractItemActions,
): void => {
    itemsToAdd.push(...newActions.itemsToAdd);
    // update itemUpdates
    each(newActions.itemUpdates, (updates, id) => {
        // check for update ID in itemsToAdd
        const itemToBeAddedIdx = findIndex(itemsToAdd, (item) => item.id === id);
        if (itemToBeAddedIdx !== -1) {
            // if update is to an item that hasn't been added then update the item to be added instead
            itemsToAdd[itemToBeAddedIdx] = {
                ...itemsToAdd[itemToBeAddedIdx],
                ...updates,
            };
        } else if (has(itemUpdates, id)) {
            // if existing updates then combine
            itemUpdates[id] = {
                ...itemUpdates[id],
                ...updates,
            };
        } else {
            itemUpdates[id] = updates;
        }
    });
    itemIdsToRemove.push(...newActions.itemIdsToRemove);
};

export const isContractRevisionCurrent = (contract: ProductContractUX) => !contract.is_frozen;

const updateItemRevisions = (
    itemRevisions: ProductContractItemUX[],
    itemId: string,
    updatesToMake: Partial<ProductContractItemUX>,
) => itemRevisions.map((item) => {
    if (item.id === itemId) {
        return {
            ...item,
            ...updatesToMake,
        };
    } else {
        return item;
    }
});

export const updateItemRevisionsList = (
    contractItemRevsList: ProductContractItemUX[][],
    itemsToAdd: ProductContractItemUX[],
    itemUpdates: ContractItemUpdateMap,
    itemIdsToRemove: string[],
): ProductContractItemUX[][] => {

    // update/delete existing items
    let updatedItemRevsList = contractItemRevsList.map((itemRevs) => {
        const latestItem = getLatestItem(itemRevs);

        // should current item should be deleted
        if (itemIdsToRemove && includes(itemIdsToRemove, latestItem.id)) {

            return itemRevs.filter((item) => item.id !== latestItem.id);

            // should current item be updated
        } else if (itemUpdates && has(itemUpdates, latestItem.id)) {

            return updateItemRevisions(itemRevs, latestItem.id, itemUpdates[latestItem.id]);

        } else {
            return itemRevs;
        }
    });

    // remove any items with no revisions (caused by deleting items)
    updatedItemRevsList = updatedItemRevsList.filter((itemRevisions) => itemRevisions.length > 0);

    // handle new items
    const [newItems, replacementItems] = partition(itemsToAdd, (item) => item.replaces_item === null);
    const replacementItemsByItemToReplace =
        keyBy(replacementItems, (item) => item.replaces_item) as { [id: string]: ProductContractItemUX };

    // add replacement items
    updatedItemRevsList = updatedItemRevsList.map((itemRevs) => {
        const latestItem = getLatestItem(itemRevs);

        if (has(replacementItemsByItemToReplace, latestItem.id)) {

            return [...itemRevs, replacementItemsByItemToReplace[latestItem.id]];

        } else {
            return itemRevs;
        }
    });

    // add brand new items
    if (newItems.length > 0) {
        const newItemsToAdd = newItems.map((newItem) => [newItem]);
        updatedItemRevsList.push(...newItemsToAdd);
    }

    return updatedItemRevsList;
};

const addContractItem = (
    itemId: string,
    itemToCreate: Partial<ProductContractItemUX>,
    contractId: number,
    contractItems: ProductContractItemUX[],
    currentRevision: number,
): ProductContractItemUX => {

    const itemToCreateProduct = itemToCreate.product;

    // if there is an existing item for this product that was deleted in the current revision
    // -> if so, create update item based off of that item
    let existingItem: ProductContractItemUX | undefined = undefined;
    if (itemToCreateProduct) {
        const deletedContractItems = contractItems.filter((item) =>
            item.product && item.product.id === itemToCreateProduct.id && item.delete_revision === currentRevision);
        existingItem = first(orderBy(deletedContractItems, (item) => item.created_time, 'desc'));
    }
    return {
        id: itemId,
        contract_id: contractId,
        product: itemToCreateProduct || null,
        package_item_id: itemToCreate.package_item_id || null,
        category: itemToCreate.category || null,
        category_rank: itemToCreateProduct ? itemToCreateProduct.category_rank : null,
        name: itemToCreate.name || '',
        description: itemToCreate.description || null,
        sku: itemToCreate.sku || null,
        note: itemToCreate.note || null,
        quantity: itemToCreate.quantity || 0,
        list_price: itemToCreate.list_price || 0,
        price_adjustment: itemToCreate.price_adjustment || 0,
        tax_total: itemToCreate.tax_total || 0,
        tax_rate_id: itemToCreate.tax_rate_id || null,
        tax_rate_brackets: itemToCreate.tax_rate_brackets || null,
        asset_type: itemToCreate.asset_type || AssetType.USD,
        package_id: itemToCreate.package_id || null,
        sub_package_id: itemToCreate.sub_package_id || null,
        type: itemToCreate.type || ContractItemType.normal,
        quantity_in_package: itemToCreate.quantity_in_package || null,
        allowance_item: itemToCreate.allowance_item || null,
        insert_revision: currentRevision,
        delete_revision: null,
        replaces_item: existingItem ? existingItem.id : null,
        original_item: existingItem ? existingItem.original_item || existingItem.id : null,
        created_time: new Date(),
        updated_time: new Date(),
        display_name: null,
    };
};

const updateContractItem = (
    changes: Partial<ProductContractItemUX>,
    existingItem: ProductContractItemUX,
    contractItems: ProductContractItemUX[],
    currentRevision: number,
): {
    itemUpdates: ContractItemUpdateMap;
    itemsToAdd: ProductContractItemUX[];
} => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};

    if (existingItem.insert_revision === currentRevision) {
        // update existing record
        itemUpdates[existingItem.id] = changes;
    } else {
        // lock existing item -> mark old one deleted & replaced and create new record
        const itemId = generateUuid();
        const replacementItem = {
            ...existingItem,
            ...changes,
            id: itemId,
            insert_revision: currentRevision,
            delete_revision: null,
            replaces_item: existingItem.id,
            original_item: existingItem.original_item || existingItem.id,
            created_time: new Date(),
            updated_time: new Date(),
        };
        itemsToAdd.push(replacementItem);

        itemUpdates[existingItem.id] = {
            delete_revision: currentRevision,
        };
        if (isAllowanceItem(replacementItem)) {
            // if this is an allowance item then update items pointing to the old allowance item to point to new item
            const itemsUsingAllowance = contractItems.filter((item) =>
                !item.delete_revision && item.allowance_item === existingItem.id && !isAllowanceCredit(item));
            const itemChanges = { allowance_item: replacementItem.id };
            itemsUsingAllowance.forEach((item) => {
                const updateActions = updateContractItem(itemChanges, item, contractItems, currentRevision);
                itemsToAdd.push(...updateActions.itemsToAdd);
                itemUpdates = { ...itemUpdates, ...updateActions.itemUpdates };
            });
        }
    }

    return {
        itemUpdates,
        itemsToAdd,
    };
};

const removeContractItem = (contractItem: ProductContractItemUX, currentRevision: number): {
    itemUpdates: ContractItemUpdateMap;
    itemIdsToRemove: string[];
} => {

    const itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    if (contractItem.insert_revision === currentRevision) {
        itemIdsToRemove.push(contractItem.id);
    } else {
        itemUpdates[contractItem.id] = {
            delete_revision: currentRevision,
        };
    }

    return {
        itemUpdates,
        itemIdsToRemove,
    };
};

const breakPackages = (
    packageIds: number[],
    contractId: number,
    contractItems: ProductContractItemUX[],
    taxRates: ProductTaxRateUX[],
    fhPackages: ProductPackageUX[],
    currentRevision: number,
): ContractItemActions => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    contractItems.forEach((item) => {

        if (item.delete_revision || !includes(packageIds, item.package_id) || item.contract_id !== contractId ||
            isPackageDiscount(item) || isAllowanceCredit(item)
        ) {
            return; // skip
        }

        const promoteSubPackage = item.sub_package_id !== null && !includes(packageIds, item.sub_package_id);
        if (isPackagePlaceholder(item) && !promoteSubPackage) {
            // remove placeholders for packages that are being broken
            // delete placeholders forever, we don't need to worry about revisions for placeholders
            itemIdsToRemove.push(item.id);
        } else if (isAllowanceItem(item) && !promoteSubPackage) {
            // allowance items can only be part of a package
            const removeActions = removeContractItem(item, currentRevision);
            itemUpdates = { ...itemUpdates, ...removeActions.itemUpdates };
            itemIdsToRemove.push(...removeActions.itemIdsToRemove);
        } else {
            // re-calculate taxes assuming no package discount
            const optimisticItem = {
                ...item,
                package_id: null,
                sub_package_id: null,
                quantity_in_package: null,
                package_item_id: null,
            };
            const { taxes, taxRate } = calcTaxForContractItem(
                optimisticItem,
                contractItems,
                fhPackages,
                taxRates,
            );

            const updatesToMake: Partial<ProductContractItemUX> = {
                package_id: promoteSubPackage ? item.sub_package_id : null,
                sub_package_id: null,
                quantity_in_package: promoteSubPackage ? item.quantity_in_package : null,
                package_item_id: promoteSubPackage ? item.package_item_id : null,
                tax_total: taxes || 0.0,
                tax_rate_id: taxRate ? taxRate.id : null,
                tax_rate_brackets: taxRate ? convertTaxRateBracketsToString(taxRate) : null,
            };

            const actions = updateContractItem(updatesToMake, item, contractItems, currentRevision);
            itemsToAdd.push(...actions.itemsToAdd);
            itemUpdates = { ...itemUpdates, ...actions.itemUpdates };
        }
    });

    return {
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
    };
};

type PackagePlaceholders = {
    packageItem: ProductPackageItemUX | ProductSubPackageItem;
    subPackageId?: number;
};

// these are the placeholders that should exist if the given package is in the contract
const getPackagePlaceholdersForPackage = (pkg: ProductPackageUX): PackagePlaceholders[] => {
    const [singleOptionItems, multiOptionItems] = partition(pkg.items, (item) => item.options.length === 1);
    const placeholdersInPackage: PackagePlaceholders[] = multiOptionItems.map((item) => ({ packageItem: item }));

    singleOptionItems.forEach((item) => {
        const { sub_package } = item.options[0];
        if (sub_package) {
            sub_package.items.forEach((subPackageItem) => {
                if (subPackageItem.options.length > 1) {
                    placeholdersInPackage.push({
                        packageItem: subPackageItem,
                        subPackageId: sub_package.id,
                    });
                }
            });
        }
    });

    return placeholdersInPackage;
};

const validatePackageContractItems = (
    contractId: number,
    contractItems: ProductContractItemUX[],
    allPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const existingSpecialItems = contractItems.filter((item) => {
        return !item.delete_revision && (isPackageDiscount(item) || isAllowanceCredit(item));
    });

    // use contractItems to find contractPackages because a new package could have been added
    const { contractPackages } = getPackagesInContract(contractItems, allPackages);

    // remove package special items not associated with contractPackages
    const [validSpecialItems, invalidSpecialItems] = partition(existingSpecialItems, (item) =>
        some(contractPackages, (pkg) => pkg.id === item.package_id));

    invalidSpecialItems.forEach((item) => {
        const removeActions = removeContractItem(item, currentRevision);
        itemUpdates = { ...itemUpdates, ...removeActions.itemUpdates };
        itemIdsToRemove.push(...removeActions.itemIdsToRemove);
    });

    const allowanceCreditItems = validSpecialItems.filter((item) => isAllowanceCredit(item));
    const adjustmentItems = validSpecialItems.filter((item) => isPackageDiscount(item));

    // make sure no contract items are referring to deleted allowance items
    const allowanceItems = contractItems.filter((item) => !item.delete_revision && isAllowanceItem(item));
    const itemsSelectedForDeletedAllowances = contractItems.filter((item) => {
        return !item.delete_revision
            && item.allowance_item !== null
            && every(allowanceItems, (allowanceItem) => allowanceItem.id !== item.allowance_item);
    });

    const changes = { allowance_item: null };
    itemsSelectedForDeletedAllowances.forEach((item) => {
        if (isAllowanceCredit(item)) {
            // delete allowance credits that haven't already been deleted
            if (every(invalidSpecialItems, (invalidItem) => invalidItem.id !== item.id)) {
                const removeActions = removeContractItem(item, currentRevision);
                itemUpdates = { ...itemUpdates, ...removeActions.itemUpdates };
                itemIdsToRemove.push(...removeActions.itemIdsToRemove);
            }
        } else {
            const updateActions = updateContractItem(changes, item, contractItems, currentRevision);
            itemsToAdd.push(...updateActions.itemsToAdd);
            itemUpdates = { ...itemUpdates, ...updateActions.itemUpdates };
        }
    });

    // make sure package special items exist and are correct based on contract items
    contractPackages.forEach((pkg) => {
        const contractItemsForPackage = getContractItemsForPackage(contractItems, pkg.id);

        // discount/adjustment special item
        // calculate what package adjustment (discount/premium) should be
        const { adjustment } = calculatePackageAdjustment(pkg, contractItemsForPackage);

        const adjustmentItem = adjustmentItems.find((item) =>
            isPackageDiscount(item) && item.package_id === pkg.id);

        const adjustmentType = adjustment.isNegative() ? 'Discount' : adjustment.isZero() ? '' : 'Premium';
        let adjustmentChanged = true;

        if (adjustmentItem && adjustmentItem.list_price !== adjustment.getAmount()) {
            const itemRequest: Partial<ProductContractItemUX> = {
                name: `${pkg.name} ${adjustmentType}`,
                list_price: adjustment.getAmount(),
            };

            const updateActions = updateContractItem(itemRequest, adjustmentItem, contractItems, currentRevision);
            itemsToAdd.push(...updateActions.itemsToAdd);
            itemUpdates = { ...itemUpdates, ...updateActions.itemUpdates };
        } else if (!adjustmentItem) {
            const itemRequest: Partial<ProductContractItemUX> = {
                package_id: pkg.id,
                type: ContractItemType.package_discount,
                category: null,
                name: `${pkg.name} ${adjustmentType}`,
                list_price: adjustment.getAmount(),
            };

            const itemToAdd = addContractItem(
                generateUuid(),
                itemRequest,
                contractId,
                contractItems,
                currentRevision,
            );
            itemsToAdd.push(itemToAdd);
        } else {
            // if discount exists and the amount hasn't changed -> don't do anything
            adjustmentChanged = false;
        }

        if (adjustmentChanged) {
            // because discount was added/changed we need to re-calculate tax for all items in this package
            // breakPackage() handles the case when the discount item is removed but not all items are removed with it
            contractItemsForPackage.forEach((item) => {

                if (isPackageDiscount(item) || isPackagePlaceholder(item) || isAllowanceCredit(item)) {
                    return; // skip
                }

                // calculate taxes w/ package discount/premium applied
                // TODO: could be optimized - adjustment is re-calculated in each iteration
                const { taxes, taxRate } = calcTaxForContractItem(
                    item,
                    contractItemsForPackage,
                    [pkg],
                    taxRates,
                );

                const itemRequest: Partial<ProductContractItemUX> = {
                    tax_total: taxes || 0,
                    tax_rate_id: taxRate ? taxRate.id : null,
                    tax_rate_brackets: taxRate ? convertTaxRateBracketsToString(taxRate) : null,
                };

                const updateActions = updateContractItem(itemRequest, item, contractItems, currentRevision);
                itemsToAdd.push(...updateActions.itemsToAdd);
                itemUpdates = { ...itemUpdates, ...updateActions.itemUpdates };
            });
        }

        // allowance credit special item(s)
        const pkgAllowanceItems = contractItemsForPackage.filter((item) => isAllowanceItem(item));
        const pkgAllowanceCreditItems = allowanceCreditItems.filter((item) => item.package_id === pkg.id);

        pkgAllowanceItems.forEach((allowanceItem) => {

            const creditAmount = calculateAllowanceCredit(allowanceItem, contractItems);
            if (!creditAmount) {
                return; // skip
            }

            const creditItem = find(pkgAllowanceCreditItems, (item) => item.allowance_item === allowanceItem.id);

            if (creditItem && creditItem.list_price !== creditAmount.getAmount()) {
                const itemRequest: Partial<ProductContractItemUX> = {
                    list_price: creditAmount.getAmount(),
                };

                const updateActions = updateContractItem(itemRequest, creditItem, contractItems, currentRevision);
                itemsToAdd.push(...updateActions.itemsToAdd);
                itemUpdates = { ...itemUpdates, ...updateActions.itemUpdates };

            } else if (!creditItem) {
                const itemRequest: Partial<ProductContractItemUX> = {
                    name: `Credit: ${allowanceItem.name}`,
                    package_id: pkg.id,
                    sub_package_id: allowanceItem.sub_package_id || null,
                    type: ContractItemType.allowance_credit,
                    category: null,
                    product: null,
                    list_price: creditAmount.getAmount(),
                    allowance_item: allowanceItem.id,
                };

                const itemToAdd = addContractItem(
                    generateUuid(),
                    itemRequest,
                    contractId,
                    contractItems,
                    currentRevision,
                );
                itemsToAdd.push(itemToAdd);
            }
        });
    });

    return {
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
    };
};

const validateActions = (
    itemsToAdd: ProductContractItemUX[],
    itemUpdates: ContractItemUpdateMap,
    itemIdsToRemove: string[],
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
) => {

    const updatedItemRevsList = updateItemRevisionsList(
        contract.items,
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
    );
    const updatedItems = getLatestItems(updatedItemRevsList);

    const pkgContractItemActions = validatePackageContractItems(
        contract.id,
        updatedItems,
        fhPackages,
        taxRates,
        currentRevision,
    );

    const retItemsToAdd = [...itemsToAdd];
    let retItemUpdates = { ...itemUpdates };
    const retItemIdsToRemove = [...itemIdsToRemove];

    // mutates itemsToAdd, itemUpdates, & itemIdsToRemove
    updateExistingActions(retItemsToAdd, retItemUpdates, retItemIdsToRemove, pkgContractItemActions);

    return {
        itemsToAdd: retItemsToAdd,
        itemUpdates: retItemUpdates,
        itemIdsToRemove: retItemIdsToRemove,
    };
};

// ------> ContractItemCreate <------
export const getActionsForContractItemCreate = (
    itemId: string,
    itemRequest: ProductContractItemCreateRequest,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    fhProducts: ProductUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions | null => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const { product_id, package_item_id, allowance_item, quantity, list_price } = itemRequest;

    const product = fhProducts.find((p) => p.id === product_id);
    if (!product) {
        return null;
    }

    let listPrice: Dinero.Dinero | null = null;
    if (list_price !== null) {
        // only manual pricing products can specify the list_price
        if (product.pricing_model !== PricingModelEnum.manual) {
            return null;
        }
        listPrice = getDinero(list_price, product.asset_type);
    } else {
        listPrice = calculateProductListPrice(product, quantity);
    }

    if (!listPrice) {
        return null;
    }

    let taxes: number = 0;
    let taxRate: ProductTaxRateUX | null = null;
    if (product.tax_rate_id) {
        const taxObj = calculateTaxes(listPrice.getAmount(), product.tax_rate_id, taxRates);
        taxes = taxObj.taxes;
        taxRate = taxObj.taxRate;
    }

    const itemToCreate: Partial<ProductContractItemUX> = {
        product,
        package_item_id,
        allowance_item,
        category: product.category,
        name: product.name,
        description: product.description,
        sku: product.sku,
        quantity,
        list_price: listPrice.getAmount(),
        tax_total: taxes,
        tax_rate_id: taxRate ? taxRate.id : null,
        tax_rate_brackets: taxRate ? convertTaxRateBracketsToString(taxRate) : null,
    };

    // purposefully allow deleted contractItems because addContractItem will use them
    const contractItems = getLatestItems(contract.items);

    // check that allowance_item is a valid allowance item
    if (allowance_item) {
        const allowanceItem = contractItems.find((item) => !item.delete_revision && item.id === allowance_item);
        if (!allowanceItem) {
            return null;
        }
    } else {
        // if there is a contract package that has this product as an option of a multi-option package item
        //    then add this item to that package
        const { contractPackages, contractSubPackages } = getPackagesInContract(contractItems, fhPackages);
        const contractPackagesAndSubPackages = [...contractPackages, ...contractSubPackages];
        const packageItems = findPackageItemsWithProductAsMultiOption(contractPackagesAndSubPackages, product.id);
        // if the request does not define a desired packageItem then pick the first valid packageItem found
        const requestedPackageItem = itemRequest.package_item_id ?
            packageItems.find((i) => i.id === itemRequest.package_item_id) : undefined;
        const validPackageItems = findValidPackageItemsToAddProduct(
            product.id,
            requestedPackageItem ? [requestedPackageItem] : packageItems,
            contractItems,
        );
        const packageItem = validPackageItems[0];
        if (packageItem) {
            // check if the packageItem's package ID is a subPackage
            const contractPackage = findContractPackageForSubPackageId(packageItem.package_id, contractPackages);
            itemToCreate.package_id = contractPackage ? contractPackage.id : packageItem.package_id;
            itemToCreate.sub_package_id = contractPackage ? packageItem.package_id : null;
            itemToCreate.quantity_in_package = getProductQuantity(product);
            itemToCreate.package_item_id = packageItem.id;
        }
    }
    const itemToAdd = addContractItem(itemId, itemToCreate, contract.id, contractItems, currentRevision);
    itemsToAdd.push(itemToAdd);

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};

// ------> CustomContractItemCreate <------
export const getActionsForCustomContractItemCreate = (
    itemId: string,
    customItemRequest: ProductCustomContractItemRequest,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const { tax_rate_id, name, list_price, category } = customItemRequest;
    const taxRate = tax_rate_id && find(taxRates, { id: tax_rate_id }) || null;
    const taxes = taxRate && applyTaxRate(list_price, taxRate);

    const itemToCreate: Partial<ProductContractItemUX> = {
        category,
        name,
        quantity: 1,
        list_price,
        tax_total: taxes || 0,
        tax_rate_id: taxRate ? taxRate.id : null,
        tax_rate_brackets: taxRate ? convertTaxRateBracketsToString(taxRate) : null,
    };
    // purposefully allow deleted contractItems because addContractItem will use them
    const contractItems = getLatestItems(contract.items);

    const itemToAdd = addContractItem(itemId, itemToCreate, contract.id, contractItems, currentRevision);
    itemsToAdd.push(itemToAdd);

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};

// ------> ContractDiscountItemCreate <------
export const getActionsForContractDiscountItemCreate = (
    itemId: string,
    amount: number,
    itemName: string,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const itemToCreate: Partial<ProductContractItemUX> = {
        name: itemName,
        list_price: amount,
        type: ContractItemType.contract_discount,
    };
    // purposefully allow deleted contractItems because addContractItem will use them
    const contractItems = getLatestItems(contract.items);

    const itemToAdd = addContractItem(itemId, itemToCreate, contract.id, contractItems, currentRevision);
    itemsToAdd.push(itemToAdd);

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};

// ------> ContractItemUpdate <------
export const getActionsForContractItemUpdate = (
    itemRequest: ProductContractItemUpdateRequest,
    existingItem: ProductContractItemUX,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions | null => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const contractItems = getLatestItems(contract.items);

    const changes: Partial<ProductContractItemUX> = { ...itemRequest };

    let listPrice: number | null = null;
    if (itemRequest.list_price !== undefined) {
        // only manual priced items & contract discounts can update list_price
        listPrice = itemRequest.list_price;
    } else if (itemRequest.quantity === undefined && itemRequest.price_adjustment !== undefined) {
        // need the price to make an adjustment
        listPrice = getContractItemListPrice(existingItem).getAmount();
    } else if (itemRequest.quantity !== undefined) {
        // manual pricing items cannot update quantity
        if (existingItem.product && existingItem.product.pricing_model === PricingModelEnum.manual) {
            console.warn('Manual priced items cannot update quantity');
            return null;
        }
        // re-calculate tax if price is affected
        const listPriceDinero = calculateContractItemListPrice(existingItem, itemRequest.quantity);
        if (!listPriceDinero) {
            return null;
        }
        listPrice = listPriceDinero.getAmount();
    }

    // re-calculate taxes as they should be after update to list_price
    if (listPrice !== null) {
        const optimisticItem: ProductContractItemUX = {
            ...existingItem,
            ...itemRequest,
            list_price: listPrice,
        };
        const { taxes, taxRate } = calcTaxForContractItem(
            optimisticItem,
            contractItems,
            fhPackages,
            taxRates,
        );

        changes.list_price = listPrice;
        changes.tax_total = taxes;
        changes.tax_rate_id = taxRate ? taxRate.id : null;
        changes.tax_rate_brackets = taxRate ? convertTaxRateBracketsToString(taxRate) : null;
    }

    const updateActions = updateContractItem(changes, existingItem, contractItems, currentRevision);
    itemsToAdd.push(...updateActions.itemsToAdd);
    itemUpdates = { ...itemUpdates, ...updateActions.itemUpdates };

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};

// ------> ContractItemDelete <------
export const getActionsForContractItemDelete = (
    itemToRemove: ProductContractItemUX,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions => {

    const removeActions = removeContractItem(itemToRemove, currentRevision);

    let itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = removeActions.itemUpdates;
    const itemIdsToRemove: string[] = removeActions.itemIdsToRemove;

    const contractItems = getLatestItems(contract.items);
    // remove deleted contractItem from contractItems
    const contractItemsWithoutRemovedItem = contractItems.filter((item) =>
        item.id !== itemToRemove.id && !item.delete_revision);

    // check if we need to break package because of contractItem removal
    if (itemToRemove.package_id) {
        // only break if contractItem is part of this package, it has a product, and the product is not a multi-option
        if (shouldRemovingContractItemBreakPackage(itemToRemove)) {
            const packagesToBreak = [itemToRemove.package_id];
            if (itemToRemove.sub_package_id) {
                // break subPackage if item has one
                packagesToBreak.push(itemToRemove.sub_package_id);
            }
            const breakPackageActions = breakPackages(
                packagesToBreak,
                contract.id,
                contractItemsWithoutRemovedItem,
                taxRates,
                fhPackages,
                currentRevision,
            );

            itemsToAdd = breakPackageActions.itemsToAdd;
            // will not overwrite item removal because item was removed before items were passed to breakPackages
            itemUpdates = { ...itemUpdates, ...breakPackageActions.itemUpdates };
            itemIdsToRemove.push(...breakPackageActions.itemIdsToRemove);

        } else if (itemToRemove.package_id && itemToRemove.product && itemToRemove.package_item_id) {
            // this item is part of a contract but won't break the package because it is a package multi-option item
            // check if we need to swap in a new item

            const packageItem = findMultiOptionPackageItem(
                fhPackages,
                itemToRemove.package_item_id,
                itemToRemove.sub_package_id || itemToRemove.package_id,
            );
            if (packageItem) {
                const { selectedOptions, unselectedOptions } =
                    findContractItemsThatArePackageItemOptions(packageItem, contractItemsWithoutRemovedItem);
                const numberOfItemsToAdd = packageItem.max_selections - selectedOptions.length;
                // if we are already at the maximum allowed selections then no items will be added
                if (numberOfItemsToAdd > 0) {
                    // sort unselected items - most expensive first
                    const sortedUnselectedOptions = orderBy(unselectedOptions, (item) => item.list_price, 'desc');
                    const contractItemsToAddToPackage = slice(sortedUnselectedOptions, 0, numberOfItemsToAdd);

                    contractItemsToAddToPackage.forEach((contractItem) => {
                        if (contractItem.product) {
                            const quantity = getProductQuantity(contractItem.product);

                            itemUpdates[contractItem.id] = {
                                package_id: itemToRemove.package_id,
                                sub_package_id: itemToRemove.sub_package_id,
                                quantity_in_package: quantity,
                                package_item_id: packageItem.id,
                            };
                        }
                    });
                }
            }
        }
    }

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};

// -----> Package Actions <-----

const getRequiredProductsForPackage = (pkg: ProductPackageUX): { product: ProductUX; subPackageId?: number }[] => {
    const singleOptionItems = pkg.items.filter((item) => item.options.length === 1);
    const productsInPackage: { product: ProductUX; subPackageId?: number }[] = [];

    singleOptionItems.forEach((item) => {
        const { product, sub_package } = item.options[0];
        if (product) {
            productsInPackage.push({ product });
        } else if (sub_package) {
            const subPkgSingleOptionItems = sub_package.items.filter((i) => i.options.length === 1);
            const subPkgProducts = subPkgSingleOptionItems.map((i) => ({
                product: i.options[0].product,
                subPackageId: sub_package.id,
            }));
            productsInPackage.push(...subPkgProducts);
        }
    });

    return productsInPackage;
};

// ------> AddingPackage <------
export const getActionsForAddingPackage = (
    pkg: ProductPackageUX,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions => {

    const itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const contractItems = getLatestItems(contract.items);

    const subPackages = getSubPackages(pkg);
    const multiOptionPackageItems = findMultiOptionPackageItems([pkg, ...subPackages]);

    // if existing contractItems exist for one or more of these options than absorb them into this package
    // absorb the most expensive first, up to the max_selections allowed for the packageItem
    multiOptionPackageItems.forEach((packageItem) => {
        // check if this packageItem is part of a subPackage of this package
        const subPackage = find(subPackages, (subPkg) => subPkg.id === packageItem.package_id);
        const subPackageId = subPackage ? subPackage.id : undefined;

        // hash of options by product ID
        const optionsByProductId: Record<number, ProductPackageItemOptionUX | ProductSubPackageItemOption> = {};
        for (const option of packageItem.options) {
            const key = option.product ? option.product.id : 0;
            optionsByProductId[key] = option;
        }
        // find items that have a product that is an option for this item and isn't part of another package or
        //   is part of a package that is a subPackage of the package being added
        const contractItemsForOptions = contractItems.filter((contractItem) =>
            !contractItem.delete_revision &&
            contractItem.product !== null &&
            optionsByProductId[contractItem.product.id] &&
            (!contractItem.package_id || contractItem.package_id === subPackageId)
        );

        // sort items - most expensive first
        const sortedItems = orderBy(contractItemsForOptions, (item) => item.list_price, 'desc');
        const contractItemsToAddToPackage = slice(sortedItems, 0, packageItem.max_selections);

        contractItemsToAddToPackage.forEach((contractItem) => {
            const option = contractItem && contractItem.product && optionsByProductId[contractItem.product.id];
            if (option && option.product) {
                const quantity = getProductQuantity(option.product);

                itemUpdates[contractItem.id] = {
                    package_id: pkg.id,
                    sub_package_id: subPackageId || null,
                    quantity_in_package: quantity,
                    package_item_id: packageItem.id,
                };
            }
        });
    });

    // Add contractItems for each packageItem that isn't existing
    // If there is an existing packageItem that is not part of another package
    // -> Then update the item to be part of this package
    // When a contract has multiple packages, duplicate items may exist

    // add special placeholder contractItem

    const placeholdersForPackage = getPackagePlaceholdersForPackage(pkg);

    placeholdersForPackage.forEach((packagePlaceholder) => {
        const { packageItem, subPackageId } = packagePlaceholder;
        const itemRequest: Partial<ProductContractItemUX> = {
            package_id: pkg.id,
            sub_package_id: subPackageId || null,
            package_item_id: packageItem.id,
            type: ContractItemType.package_placeholder,
            category: packageItem.category,
            name: packageItem.name,
        };
        const itemToAdd = addContractItem(
            generateUuid(),
            itemRequest,
            contract.id,
            contractItems,
            currentRevision,
        );
        itemsToAdd.push(itemToAdd);
    });

    const requiredProducts = getRequiredProductsForPackage(pkg);

    requiredProducts.forEach(({ product, subPackageId }) => {
        const quantity = getProductQuantity(product);

        const existingContractItem = find(contractItems, (i) =>
            !i.delete_revision && i.product !== null && i.product.id === product.id);
        if (existingContractItem && (!existingContractItem.package_id ||
            existingContractItem.package_id === subPackageId)) {
            // absorb the existing item if doesn't have a package or is a subPackage item of this package
            itemUpdates[existingContractItem.id] = {
                package_id: pkg.id,
                sub_package_id: subPackageId,
                quantity_in_package: quantity,
            };
        } else {
            // taxes will be handled in validatePackageContractItems()
            const listPriceDinero = calculateProductListPrice(product, quantity);
            if (!listPriceDinero) {
                return;
            }

            const itemRequest: Partial<ProductContractItemUX> = {
                product,
                package_id: pkg.id,
                sub_package_id: subPackageId || null,
                category: product.category,
                name: product.name,
                description: product.description,
                quantity,
                quantity_in_package: quantity,
                list_price: listPriceDinero.getAmount(),
                tax_total: 0, // taxes will be calculated in validatePackageContractItems()
            };
            const itemToAdd = addContractItem(
                generateUuid(),
                itemRequest,
                contract.id,
                contractItems,
                currentRevision,
            );
            itemsToAdd.push(itemToAdd);
        }
    });

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};

// ------> RemovingPackage <------
export const getActionsForRemovingPackage = (
    packageId: number,
    contract: ProductContractUX,
    fhPackages: ProductPackageUX[],
    taxRates: ProductTaxRateUX[],
    currentRevision: number,
): ContractItemActions => {

    let itemsToAdd: ProductContractItemUX[] = [];
    let itemUpdates: ContractItemUpdateMap = {};
    const itemIdsToRemove: string[] = [];

    const contractItems = getLatestItems(contract.items).filter((item) => !item.delete_revision);

    // this will remove all contractItems related to this package (including discounts and placeholders)
    const contractItemsWithoutRemovedItems: ProductContractItemUX[] = [];
    contractItems.forEach((contractItem) => {

        if (contractItem.package_id !== packageId && contractItem.sub_package_id !== packageId) {
            contractItemsWithoutRemovedItems.push(contractItem);
            return;
        }

        const removeActions = removeContractItem(contractItem, currentRevision);
        itemUpdates = { ...itemUpdates, ...removeActions.itemUpdates };
        itemIdsToRemove.push(...removeActions.itemIdsToRemove);
    });

    // check if we need to break a package because of package removal
    const { contractPackages } = getPackagesInContract(contractItems, fhPackages);

    // if package is a subPackage then break the parent Package
    const parentPackage = findContractPackageForSubPackageId(packageId, contractPackages);
    if (parentPackage) {
        // break parent package
        const breakParentPackageActions = breakPackages(
            [parentPackage.id],
            contract.id,
            contractItemsWithoutRemovedItems,
            taxRates,
            fhPackages,
            currentRevision,
        );

        // mutates itemsToAdd, itemUpdates, & itemIdsToRemove
        updateExistingActions(itemsToAdd, itemUpdates, itemIdsToRemove, breakParentPackageActions);
    }

    // validate actions
    const actions = validateActions(
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
        contract,
        fhPackages,
        taxRates,
        currentRevision,
    );

    return actions;
};
