import * as React from 'react';

import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import GLink from '../../../../common/GLink';
import { AppRoute, AppRouteType } from "../../../../../services";
import GButton from '../../../../common/GButton';
import { GStyles } from '../../../../../styles/GStyles';
import styled from '@emotion/styled';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
});

const Question = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
    fontSize: 20,
    lineHeight: 1.125,
    margin: '32px 32px 0'
});

const UploadButton = styled(GButton)({
    fontWeight: 400,
    minHeight: 40
});
interface Props {
    isSaved: boolean;
    question: string | JSX.Element;
    isSaving: boolean;
    buttonText?: string;
    linkProps: AppRoute;
    buttonIcon?: JSX.Element;
    savePhotos: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    onClick?: () => void;
}

function PhotoPrompt({
    isSaved,
    question,
    isSaving,
    buttonText,
    linkProps,
    buttonIcon,
    savePhotos,
    onClick
}: Props) {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <>
            <input
                type="file"
                accept="image/gif, image/jpeg, image/png"
                multiple
                className={GStyles.displayNone}
                ref={inputRef}
                onChange={savePhotos}
                onClick={e => {
                    const element = e.target as HTMLInputElement;
                    // clear this value to so that same photo can be chosen each time
                    element.value = '';
                }}
            />
            <Fade in={!isSaved} timeout={300} mountOnEnter unmountOnExit>
                <Container>
                    <Question color="primary">
                        <span>{question}</span>
                    </Question>

                    <span className={GStyles.marginBottom20}>
                        <GLink
                            to={linkProps}
                            onClick={e => {
                                e.stopPropagation();
                                onClick?.();
                            }}
                        >
                            <UploadButton
                                text={buttonText || ''}
                                icon={buttonIcon}
                                onClick={() => {
                                    if (linkProps.type === AppRouteType.DISABLED && inputRef.current) {
                                        inputRef.current.click();
                                    }
                                }}
                                isSpinning={isSaving}
                            />
                        </GLink>
                    </span>
                </Container>
            </Fade>
        </>
    );
}

export default PhotoPrompt;
