import * as React from 'react';
import { find, debounce, some } from 'lodash';

import {
    ProductUX,
    ProductContractUX,
    ProductCategory,
    ProductPackageUX,
    ProductContractItemUX,
    UserRoles,
    ProductSubPackage,
    ProductContractItemCreateRequest,
    ProductContractItemUpdateRequest,
    ProductCustomContractItemRequest,
    PricingModelEnum,
    ProductSupplierUXWithCategory,
    CaseType,
    ProductSummary,
    EntityCaseRole,
    ProductItem,
    GatherCaseUX,
    GPLContext,
    InvoiceRequest,
    dineroToRequest,
    isProductCategoryEnum,
} from '../../../shared/types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loyalty from '@mui/icons-material/Loyalty';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';
import ModeComment from '@mui/icons-material/ModeComment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';

import Overview from './overview';
import PaymentTab from './payment';
import ProductDetailDialog from './dialogs/ProductDetail.dialog';
import {
    calculateContractItemListPrice,
    getProductQuantity,
    calculateAllowanceCredit,
    getContractItemListPrice,
    getContractItemPriceAdjustment,
    getDinero,
    applyTaxRate,
    toPennyValue,
    calculateProductListPrice,
    calculateTaxes,
} from '../../../shared/goods_and_services/pricing';
import {
    isAllowanceItem,
    isAllowanceCredit,
    findContractItem,
} from '../../../shared/goods_and_services/utils';
import AddItemDialog, { AddItemOrigin } from './dialogs/AddItem.dialog';
import { StoreState } from '../../../types';
import { cleanMap } from '../../../shared/utils';
import HelperPopper from '../helperPopper/HelperPopper';
import {
    shouldRemovingContractItemBreakPackage,
    findContractPackageForContractItem,
    findContractPackageForSubPackageId,
} from '../../../shared/goods_and_services/packages';
import PackageDialog from './dialogs/Package.dialog';
import { getProductCategoriesForTabType, removeHiddenProductItems } from '../../../services/goodsandservices.service';
import BreakPackageDialog from './dialogs/BreakPackage.dialog';
import NotesDialog from './dialogs/Notes.dialog';
import PriceAdjustmentDialog from './dialogs/PriceAdjustment.dialog';
import SpecifyPriceDialog from './dialogs/SpecifyPrice.dialog';
import {
    updateProductContractItem,
    createProductContractItem,
    createProductCustomContractItem,
    deleteProductContractItem,
    addProductPackageToContract,
    removeProductPackageFromContract,
    loadProductContractForCase,
    createProductContractDiscountItem,
} from '../../../actions/product/Contract.action';
import { openAccessRestrictedDialog } from '../../../actions/AccessRestricted.action';
import { loadProductsForFuneralHome } from '../../../actions/product/FHProduct.action';
import ChooseItemDestination from './dialogs/ChooseItemDestination.dialog';
import { addInvoice } from '../../../actions/Finance.action';
import UnfreezeContractDialog from './dialogs/UnfreezeContract.dialog';
import { openHelperInvitationDialog } from '../../../actions/Dialog.action';
import { openPhotoSwipeDialog } from '../../../actions/PhotoSwipe.action';
import RenameContractItemDialog from './dialogs/RenameContractItemDialog';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import { PayerDetails } from '.';
import Showroom from './showroom/Showroom';
import { FamilyRoutePage, FuneralHomeRoutePage, RouteBuilder } from '../../../services';
import GLink from '../../common/GLink';
import DisabledPermissionTooltip from '../../common/DisabledPermissionTooltip';
import { Permission } from '../../../shared/types/permissions';

function mapStateToProps({
    userSession,
    productState,
    productSupplierState,
}: StoreState,
    ownProps: OwnProps) {
    return {
        products: productState.funeralHomeProducts,
        productSummaries: productState.funeralHomeProductSummaries,
        isLoading: productState.isFHProductsLoading || productState.isContractLoading,
        taxRates: productState.taxRates,
        funeralHomeSuppliers: productSupplierState.funeralHomeSuppliers,
        isGOMOrFHUser: UserRoles.isFHorGOMUser(userSession.userData)
    };
}

interface InjectedProps {
    dispatch: AppDispatch;
}

interface OwnProps {
    isContractFrozen: boolean;
    tabType: ProductCategory | 'contract' | 'payment';
    contractItems: ProductContractItemUX[];
    productItems: ProductItem[];
    activeContract: ProductContractUX;
    availablePackages: ProductPackageUX[];
    contractPackages: ProductPackageUX[];
    contractSubPackages: ProductSubPackage[];
    contractPackageIds: { [id: number]: number };
    canUserEditContract: boolean;
    isShowPrices: boolean;
    isCondensedView: boolean;
    funeralHomeSuppliers: ProductSupplierUXWithCategory[];
    toggleCondensedView: (isChecked: boolean) => void;
    openFreezeContractDialog: () => void;
    printContract: () => void;
    zIndex: number;
    isItemizedStatement: boolean;
    setItemizedStatement: (isItemized: boolean, callback?: () => void) => void;
    isDownloadingInvoice: boolean;
    setDownloadInvoice: (downloadInvoice: boolean, payerId: number, callback?: () => void) => void;
    payerDetails?: PayerDetails;
    selectedCase: GatherCaseUX;
    handleChangeSelectedTabKey: (tab: ProductCategory) => void;
}
type Props = ReturnType<typeof mapStateToProps> & OwnProps & InjectedProps;

interface State {
    localProductItems: ProductItem[];
    selectedProductId: number | null;
    selectedContractItemId: string | null;
    productAddedOrigin: AddItemOrigin;
    selectedPackageId: number | null;
    selectedPackageItemId: number | null;
    breakPackageName: string;
    breakPackageCallback: () => void;
    isPackageDialogOpen: boolean;
    isAddItemDialogOpen: boolean;
    isProductDetailDialogOpen: boolean;
    isBreakConfirmationDialogOpen: boolean;
    popperAnchorEle: HTMLElement | null;
    menuAnchorEl: HTMLElement | null;
    isPriceAdjustmentDialogOpen: boolean;
    isNotesDialogOpen: boolean;
    isSpecifyPriceDialogOpen: boolean;
    isAddItemOptionDialogOpen: boolean;
    isUnfreezeContractDialogOpen: boolean;
    contractUnfreezeReason: string | null;
    unfreezeCallback: (() => void) | null;
    addItemToContractCallback: (() => void) | null;
    addItemToPaymentsCallback: (() => void) | null;
    specifyPriceCallback: ((listPrice: number) => void) | null;
    addToAllowanceItem: string | null;
    activeEntityId: number | null;
    isRenameContractItemDialogOpen: boolean;
}

class GoodsAndServicesTab extends React.Component<Props, State> {
    state: State = {
        localProductItems: [],
        productAddedOrigin: 'invoice',
        selectedProductId: null,
        selectedContractItemId: null,
        selectedPackageId: null,
        selectedPackageItemId: null,
        breakPackageName: '',
        breakPackageCallback: () => null,
        isPackageDialogOpen: false,
        isAddItemDialogOpen: false,
        isProductDetailDialogOpen: false,
        isBreakConfirmationDialogOpen: false,
        popperAnchorEle: null,
        menuAnchorEl: null,
        isPriceAdjustmentDialogOpen: false,
        isNotesDialogOpen: false,
        isSpecifyPriceDialogOpen: false,
        isAddItemOptionDialogOpen: false,
        isUnfreezeContractDialogOpen: false,
        contractUnfreezeReason: null,
        unfreezeCallback: null,
        addItemToContractCallback: null,
        addItemToPaymentsCallback: null,
        specifyPriceCallback: null,
        addToAllowanceItem: null,
        activeEntityId: null,
        isRenameContractItemDialogOpen: false,
    };

    debounceUpdateContractItem = debounce(
        (...args: Parameters<typeof updateProductContractItem>) => {
            const { dispatch } = this.props;
            dispatch(updateProductContractItem(...args));
        },
        1000,
    );

    componentDidMount() {
        const {
            dispatch,
            selectedCase,
            tabType,
        } = this.props;
        const categories = getProductCategoriesForTabType(tabType);
        if (categories) {
            dispatch(loadProductsForFuneralHome(selectedCase.funeral_home.id, categories, false));
            dispatch(loadProductContractForCase(selectedCase.uuid));
        }
    }

    componentWillUnmount() {
        this.debounceUpdateContractItem.flush();
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { productItems } = nextProps;
        // update localProductItem but retain quantity & list_price state
        this.setState((prevState) => ({
            localProductItems: productItems.map((item) => {
                const existingLocal = find(prevState.localProductItems, (localItem) => localItem.key === item.key);
                if (existingLocal && existingLocal.contractItem &&
                    item.contractItem && !isAllowanceCredit(item.contractItem)) {
                    return {
                        ...item,
                        contractItem: {
                            ...item.contractItem,
                            quantity: existingLocal.contractItem.quantity,
                            list_price: existingLocal.contractItem.list_price,
                        },
                    };
                } else {
                    return item;
                }
            }),
        }));
    }

    renderContent = () => {
        const {
            tabType,
            selectedCase,
            canUserEditContract,
            isCondensedView,
            toggleCondensedView,
            isContractFrozen,
            openFreezeContractDialog,
            printContract,
            activeContract,
            taxRates,
            zIndex,
            setItemizedStatement,
            isItemizedStatement,
            isDownloadingInvoice,
            setDownloadInvoice,
            payerDetails,
            isShowPrices,
            funeralHomeSuppliers,
            availablePackages,
            contractPackageIds,
            handleChangeSelectedTabKey,
            isLoading
        } = this.props;

        if (tabType === 'contract') {
            return <Overview
                isPrintMode={false}
                activeContract={activeContract}
                activeCase={selectedCase}
                canUserEditContract={canUserEditContract}
                onAddAdditionalItem={this.openAddItemDialog}
                onProductItemClick={this.toggleProductDetailDialogOpen}
                onUpdateItemPriceAdjustment={this.handleUpdateContractItemPriceAdjustment}
                onUpdateItemPrice={this.handleUpdateContractItemPrice}
                onAddContractDiscountItem={this.handleAddContractDiscountItem}
                onUpdateContractDiscountItem={this.handleUpdateContractDiscountItem}
                onUpdateItemNote={this.handleUpdateContractItemNote}
                onRemoveContractItem={this.handleRemoveContractItem}
                isCondensedView={isCondensedView}
                toggleCondensedView={toggleCondensedView}
                isContractFrozen={isContractFrozen}
                openFreezeContractDialog={openFreezeContractDialog}
                printContract={printContract}
                onRenameContractItem={this.handleRenameContractItem}
                zIndex={zIndex + 1}
                isItemizedStatement={isItemizedStatement}
                isDownloadingInvoice={isDownloadingInvoice}
                payerDetails={payerDetails}
            />;
        } else if (tabType === 'payment') {
            return (
                <PaymentTab
                    selectedCase={selectedCase}
                    taxRates={taxRates}
                    isContractFrozen={isContractFrozen}
                    onAddNonUserContactInfoClick={this.openInviteHelperDialog}
                    onAddItem={() => this.openAddItemDialog('invoice')}
                    handleClickEventOnAvatar={this.handleClickEventOnAvatar}
                    zIndex={zIndex + 1}
                    setItemizedStatement={setItemizedStatement}
                    setDownloadInvoice={setDownloadInvoice}
                />
            );
        }

        return (
            <Showroom
                context={GPLContext.App}
                isLoading={isLoading}
                handleChangeSelectedTabKey={handleChangeSelectedTabKey}
                generateLink={category => RouteBuilder.Showroom({
                    caseName: selectedCase.name,
                    funeralHomeKey: selectedCase.funeral_home.key,
                    category,
                })}
                isShowPrices={isShowPrices}
                handleAddPackage={this.handleAddPackage}
                canUserEditContract={canUserEditContract}
                caseFirstName={selectedCase.fname}
                selectedTabKey={tabType}
                funeralHomeSuppliers={funeralHomeSuppliers}
                productItems={this.state.localProductItems}
                visibleProductItems={removeHiddenProductItems(this.state.localProductItems)}
                availablePackages={availablePackages}
                handleRemoveContractItem={this.handleRemoveContractItem}
                handleRemovePackage={this.handleRemovePackage}
                openAddItemDialog={this.openAddItemDialog}
                onPackageClick={this.openPackageDialog}
                onUpdateContractItemQuantity={this.handleUpdateContractItemQuantity}
                onAddContractItem={this.handleAddContractItem}
                onProductItemClick={this.toggleProductDetailDialogOpen}
                contractPackageIds={contractPackageIds}
            />
        );
    };

    renderBreakPackageDialog = () => {
        const { breakPackageCallback, isBreakConfirmationDialogOpen } = this.state;
        const { zIndex } = this.props;

        return (
            <BreakPackageDialog
                isDialogOpen={isBreakConfirmationDialogOpen}
                closeDialog={() => this.toggleBreakPackageDialog()}
                onConfirm={() => {
                    breakPackageCallback();
                    this.toggleBreakPackageDialog();
                }}
                zIndex={zIndex + 1}
            />
        );
    };

    renderMenu = () => {
        const { selectedCase, products, dispatch, zIndex, canUserEditContract } = this.props;
        const {
            menuAnchorEl,
            selectedProductId,
            selectedContractItemId,
            selectedPackageItemId,
        } = this.state;

        const selectedProduct = selectedProductId && find(products, { id: selectedProductId }) || undefined;
        const selectedContractItem = selectedContractItemId ? this.getContractItem(selectedContractItemId) : undefined;
        const noteText = selectedContractItem && selectedContractItem.note ? 'Edit note' : 'Add a note';
        const isAllowance = selectedContractItem && isAllowanceItem(selectedContractItem);
        const isUsingAllowance = selectedContractItem && selectedContractItem.allowance_item;
        const hideAllowanceMenuItem = !this.isAllowanceAvailable() && !isUsingAllowance
            || isAllowance || selectedPackageItemId || selectedContractItem && selectedContractItem.package_item_id;
        const hideAdjustPriceMenuItem = selectedProduct && selectedProduct.pricing_model === PricingModelEnum.manual;

        return (
            <Menu
                id="overview--item-menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={e => this.handleMenuClose()}
                style={{ zIndex: zIndex + 1 }}
            >
                <DisabledPermissionTooltip
                    permission={selectedContractItem ? Permission.REMOVE_ITEMS : Permission.ADD_ITEMS}
                >
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={() => {
                                this.handleMenuClose();
                                this.toggleProductDetailDialogOpen();
                                if (selectedContractItem) {
                                    this.handleRemoveContractItem(selectedContractItem);
                                } else if (selectedProduct) {
                                    const quantity = getProductQuantity(selectedProduct);
                                    this.handleAddContractItem(
                                        selectedProduct,
                                        quantity,
                                        selectedPackageItemId || undefined
                                    );
                                }
                            }}
                        >
                            {selectedContractItem ? <AddIcon color="primary" /> : <RemoveIcon color="primary" />}&nbsp;
                            {selectedContractItem ? 'Remove From Statement' : 'Add to Statement'}
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>

                {selectedContractItem &&
                    <MenuItem onClick={this.toggleNotesDialog}>
                        <ModeComment color="primary" />&nbsp;{noteText}
                    </MenuItem>
                }
                {!hideAdjustPriceMenuItem &&
                    <DisabledPermissionTooltip permission={Permission.CONTRACT_ITEM_DISCOUNTS}>
                        {disabled =>
                            <MenuItem onClick={this.openPriceAdjustmentDialog} disabled={disabled}>
                                <AttachMoneyIcon color="primary" />&nbsp;Discount/Premium
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                }
                {!hideAllowanceMenuItem &&
                    <MenuItem
                        onClick={() => {
                            this.handleMenuClose();
                            this.handleAllowanceToggle(selectedProduct, selectedContractItem);
                        }}
                    >
                        <MonetizationOnOutlined color="primary" />&nbsp;
                        {isUsingAllowance ? 'Exclude' : 'Include'} as allowance
                    </MenuItem>
                }
                {selectedProduct &&
                    <GLink
                        to={RouteBuilder.FuneralHome(
                            selectedCase.funeral_home.key,
                            FuneralHomeRoutePage.GOODSANDSERVICES,
                        )}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        onClick={e => {
                            // RESTRICT ACCESS
                            if (selectedCase.funeral_home.is_demo) {
                                e.stopPropagation();
                                e.preventDefault();
                                dispatch(openAccessRestrictedDialog(zIndex + 5));
                            }
                        }}
                    >
                        <MenuItem>
                            <Loyalty color="primary" />&nbsp;Configure this Product
                        </MenuItem>
                    </GLink>
                }
                {canUserEditContract && selectedContractItem &&
                    <DisabledPermissionTooltip permission={Permission.CONTRACT_MODIFY_ITEM_NAME}>
                        {disabled =>
                            <MenuItem disabled={disabled} onClick={this.openRenameContractItemDialog}>
                                <EditIcon color="primary" />&nbsp;
                                Rename Statement Item
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                }
            </Menu>
        );
    };

    renderLoadingSpinner = () => {
        return (
            <Grid
                container
                justifyContent="center"
            >
                <CircularProgress color="primary" />
            </Grid>
        );
    };

    getContractItem = (contractItemId: string) => {
        const { selectedProductId, localProductItems } = this.state;

        let selectedLocalProductItem = localProductItems.find((i) => i.contractItem !== undefined
            && i.contractItem.id === contractItemId) || undefined;

        if (!selectedLocalProductItem) {
            selectedLocalProductItem = selectedProductId !== null &&
                localProductItems.find((item) => item.product !== undefined && item.product.id === selectedProductId)
                || undefined;
        }
        const selectedContractItem = selectedLocalProductItem ? selectedLocalProductItem.contractItem : undefined;
        return selectedContractItem;
    };

    render() {
        const {
            products,
            productSummaries,
            selectedCase,
            activeContract,
            availablePackages,
            contractPackages,
            contractSubPackages,
            taxRates,
            canUserEditContract,
            isContractFrozen,
            isLoading,
            zIndex,
            isGOMOrFHUser,
            tabType
        } = this.props;
        const {
            localProductItems,
            selectedProductId,
            selectedContractItemId,
            productAddedOrigin,
            selectedPackageId,
            selectedPackageItemId,
            isPackageDialogOpen,
            isAddItemDialogOpen,
            isProductDetailDialogOpen,
            popperAnchorEle,
            isPriceAdjustmentDialogOpen,
            isNotesDialogOpen,
            isSpecifyPriceDialogOpen,
            isAddItemOptionDialogOpen,
            isUnfreezeContractDialogOpen,
            contractUnfreezeReason,
            activeEntityId,
            isRenameContractItemDialogOpen,
        } = this.state;

        if (isLoading && (tabType === 'contract' || tabType === 'payment')) {
            return this.renderLoadingSpinner();
        }

        const selectedContractItem = selectedContractItemId ? this.getContractItem(selectedContractItemId) : undefined;

        const selectedProduct: ProductUX | undefined = selectedProductId
            ? selectedContractItem && selectedContractItem.product
                ? selectedContractItem.product
                : products.find((p) => p.id === selectedProductId)
            : undefined
            ;
        const selectedProductSummary: ProductSummary | undefined = selectedProduct ||
            productSummaries.find((p) => p.id === selectedProductId);

        const productSummariesForOrigin = productAddedOrigin === 'invoice' || productAddedOrigin === 'contract_all'
            ? productSummaries
            : productSummaries.filter((sum) => sum.category === productAddedOrigin)
            ;

        const selectedPackage = selectedPackageId &&
            find(availablePackages, (pkg) => pkg.id === selectedPackageId) || null;
        const isSelectedPackageInContract = selectedPackage !== null &&
            some([...contractPackages, ...contractSubPackages], (pkg) => pkg.id === selectedPackage.id);

        const allowanceUsedProductItem = selectedContractItem &&
            find(localProductItems, (i) => i.contractItem !== undefined && isAllowanceItem(i.contractItem)
                && i.contractItem.id === selectedContractItem.allowance_item) || undefined;

        return (
            <div>
                {this.renderContent()}

                <ProductDetailDialog
                    product={selectedProduct}
                    contractItem={selectedContractItem}
                    packageItemId={selectedPackageItemId || undefined}
                    closeDialog={this.toggleProductDetailDialogOpen}
                    isDialogOpen={isProductDetailDialogOpen}
                    onAddContractItem={this.handleAddContractItem}
                    onUpdateQuantity={this.handleUpdateContractItemQuantity}
                    onRemoveContractItem={this.handleRemoveContractItem}
                    canUserEditContract={canUserEditContract}
                    caseAssignee={selectedCase.assignee.fname}
                    caseName={selectedCase.fname}
                    handleClickEventOnAvatar={e =>
                        this.handleClickEventOnAvatar(e, selectedCase.assignee_entity_id)
                    }
                    onMenuClick={this.handleMenuClick}
                    isGOMOrFHUser={isGOMOrFHUser}
                    openPriceAdjustmentDialog={this.openPriceAdjustmentDialog}
                    toggleNotesDialog={this.toggleNotesDialog}
                    openSpecifyPriceDialog={this.openSpecifyPriceDialog}
                    isAllowanceAvailable={this.isAllowanceAvailable()}
                    allowanceUsed={allowanceUsedProductItem ? allowanceUsedProductItem.contractItem : undefined}
                    onToggleAllowance={this.handleAllowanceToggle}
                    openPhotoSwipeDialog={this.openPhotoSwipeDialog}
                    openRenameContractItemDialog={this.openRenameContractItemDialog}
                    zIndex={zIndex}
                    context={GPLContext.App}
                />

                <AddItemDialog
                    taxRates={taxRates}
                    isDialogOpen={isAddItemDialogOpen}
                    closeDialog={this.closeAddItemDialog}
                    productSummaries={productSummariesForOrigin}
                    contractOptions={activeContract.contract_options}
                    addOrigin={productAddedOrigin}
                    onSelectProduct={this.handleAddContractItem}
                    onCreateOneTimeItem={this.handleAddOneTimeContractItem}
                    zIndex={zIndex + 1}
                />

                <PackageDialog
                    context={GPLContext.App}
                    productPackage={selectedPackage}
                    isSelected={isSelectedPackageInContract}
                    isDialogOpen={isPackageDialogOpen}
                    closeDialog={this.closePackageDialog}
                    onAddPackage={this.handleAddPackage}
                    onRemovePackage={this.handleRemovePackage}
                    canUserEditContract={canUserEditContract}
                    caseAssignee={selectedCase.assignee.fname}
                    handleClickEventOnAvatar={e =>
                        this.handleClickEventOnAvatar(e, selectedCase.assignee_entity_id)
                    }
                    openPhotoSwipeDialog={this.openPhotoSwipeDialog}
                    zIndex={zIndex + 1}
                />

                <HelperPopper
                    key={activeEntityId}
                    zIndex={zIndex + 1}
                    clickAwayListener={this.clickAwayListener}
                    selectedCase={selectedCase}
                    activeEntityId={activeEntityId}
                    popperAnchorEle={popperAnchorEle}
                    closeHelperPopper={this.closeHelperPopper}
                    isParentDialogOpen={isPackageDialogOpen || isProductDetailDialogOpen}
                />

                {this.renderBreakPackageDialog()}
                {this.renderMenu()}

                <PriceAdjustmentDialog
                    key={`goods_and_services_${selectedContractItem ? selectedContractItem.id : -1}`}
                    closeDialog={this.closePriceAdjustmentDialog}
                    isDialogOpen={isPriceAdjustmentDialogOpen}
                    onPriceAdjustmentChange={this.handlePriceAdjustment}
                    listPrice={selectedContractItem
                        ? getContractItemListPrice(selectedContractItem)
                        : getDinero(0, 'USD')
                    }
                    existingAdjustment={selectedContractItem
                        ? getContractItemPriceAdjustment(selectedContractItem)
                        : getDinero(0, 'USD')
                    }
                    target={selectedContractItem ? selectedContractItem.name : ''}
                    assetType={selectedContractItem ? selectedContractItem.asset_type : 'USD'}
                    zIndex={zIndex + 1}
                />
                <NotesDialog
                    key={selectedContractItem && selectedContractItem.note || 1}
                    closeDialog={this.toggleNotesDialog}
                    isDialogOpen={isNotesDialogOpen}
                    activeCase={selectedCase}
                    contractItem={selectedContractItem}
                    handleAddNote={this.handleAddNote}
                    zIndex={zIndex + 1}
                />
                {selectedContractItem && <RenameContractItemDialog
                    key={selectedContractItem.id + '-renameContractItem'}
                    activeCase={selectedCase}
                    isDialogOpen={isRenameContractItemDialogOpen}
                    contractItem={selectedContractItem}
                    closeDialog={this.closeRenameContractItemDialog}
                    handleRenameContractItem={this.handleRenameContractItem}
                    zIndex={zIndex + 1}
                />}
                <SpecifyPriceDialog
                    key={selectedContractItemId || selectedProductId || 0}
                    closeDialog={this.closeSpecifyPriceDialog}
                    isDialogOpen={isSpecifyPriceDialogOpen}
                    productName={selectedProductSummary ? selectedProductSummary.name : ''}
                    initialPrice={selectedContractItem ? selectedContractItem.list_price : undefined}
                    onSpecifyPrice={this.handleSpecifyPrice}
                    zIndex={zIndex + 1}
                />
                <ChooseItemDestination
                    isDialogOpen={isAddItemOptionDialogOpen}
                    isContractFrozen={isContractFrozen}
                    closeDialog={this.closeAddItemOptionDialog}
                    paymentLink={RouteBuilder.FamilyPage({
                        caseName: selectedCase.name,
                        funeralHomeKey: selectedCase.funeral_home.key,
                        page: FamilyRoutePage.PAYMENTS,
                    })}
                    onAddToContract={this.handleConfirmAddItemToContract}
                    onAddToPayments={this.handleConfirmAddItemToPayments}
                    zIndex={zIndex + 1}
                />
                <UnfreezeContractDialog
                    isDialogOpen={isUnfreezeContractDialogOpen}
                    closeDialog={this.closeUnfreezeContractDialog}
                    activeCase={selectedCase}
                    unfreezeReason={contractUnfreezeReason}
                    unfreezeProductContract={this.handleConfirmUnfreeze}
                    zIndex={zIndex + 2}
                />
            </div>
        );
    }

    openAddItemDialog = (origin: AddItemOrigin) => {
        this.setState({
            isAddItemDialogOpen: true,
            productAddedOrigin: origin,
        });
    };

    closeAddItemDialog = () => {
        this.setState({
            isAddItemDialogOpen: false,
        });
    };

    openPackageDialog = (packageId: number) => {
        this.setState({
            isPackageDialogOpen: true,
            selectedPackageId: packageId,
        });
    };

    closePackageDialog = () => {
        this.setState({
            isPackageDialogOpen: false,
            selectedPackageId: null,
        });
    };

    openRenameContractItemDialog = () => {
        const { canUserEditContract } = this.props;
        if (canUserEditContract) {
            this.setState({
                isRenameContractItemDialogOpen: true,
            });
        }
    };

    closeRenameContractItemDialog = () => {
        this.setState({
            isRenameContractItemDialogOpen: false,
        });
        this.handleMenuClose();
    };

    handleRenameContractItem = (contractItemId: string, newName: string | null) => {
        const { activeContract } = this.props;
        if (activeContract && contractItemId) {
            this.handleUpdateContractItem(contractItemId, { display_name: newName || '' });
        }
    };

    handleAddContractItem = async (
        product: ProductSummary,
        quantity: number,
        packageItemId?: number,
        allowanceItemId?: string,
        listPrice?: number,
        isAddedAsInvoice?: boolean,
    ) => {
        const {
            dispatch,
            selectedCase,
            activeContract,
            taxRates,
            isContractFrozen,
        } = this.props;
        const { isProductDetailDialogOpen, addToAllowanceItem } = this.state;

        // listPrice can only be set for manual priced items
        if (product.pricing_model !== PricingModelEnum.manual && listPrice !== undefined) {
            console.warn('listPrice can only be set for manual priced items');
            return;
        } else if (product.pricing_model === PricingModelEnum.manual && listPrice === undefined) {
            // if manual pricing model and listPrice has't been set, force the price to be set
            const specifyPriceCallback = (price: number) => this.handleAddContractItem(
                product,
                quantity,
                packageItemId,
                allowanceItemId,
                price,
                isAddedAsInvoice,
            );
            this.setState({
                isSpecifyPriceDialogOpen: true,
                selectedProductId: product.id,
                selectedContractItemId: null,
                addToAllowanceItem: allowanceItemId || null,
                specifyPriceCallback,
            });
            return;
        }

        const addToContract = async () => {

            const itemToCreate: ProductContractItemCreateRequest = {
                product_id: product.id,
                allowance_item: allowanceItemId || addToAllowanceItem || null,
                package_item_id: packageItemId || null,
                quantity,
                list_price: listPrice !== undefined ? listPrice : null,
            };

            if (isProductDetailDialogOpen) {
                this.toggleProductDetailDialogOpen();
            }

            const createdItemId = await dispatch(createProductContractItem({
                caseUuid: selectedCase.uuid,
                contractId: activeContract.id,
                item: itemToCreate,
            }));
            if (isProductDetailDialogOpen) {
                this.setState({
                    selectedContractItemId: createdItemId,
                });
            }
        };

        if (isContractFrozen || isAddedAsInvoice || selectedCase.case_type === CaseType.one_off) {

            // define callback function
            const addToPayments = () => {
                let priceDinero: Dinero.Dinero | null = null;
                if (listPrice) {
                    // only manual pricing products can specify the list price
                    if (product.pricing_model !== PricingModelEnum.manual) {
                        console.warn('handleAddContractItem: only manual-priced products can specify list price');
                        return;
                    }
                    priceDinero = getDinero(listPrice, product.asset_type);
                } else {
                    priceDinero = calculateProductListPrice(product, quantity);
                }

                if (!priceDinero) {
                    console.warn('handleAddContractItem: failed to determine price');
                    return;
                }
                if (!isProductCategoryEnum(product.category)) {
                    console.warn('Invalid product category', { product });
                    return;
                }

                let taxes: number = 0.0;
                let taxRateId: number | null = null;
                if (product.tax_rate_id) {
                    const taxObj = calculateTaxes(priceDinero.getAmount(), product.tax_rate_id, taxRates);
                    taxes = taxObj.taxes;
                    taxRateId = taxObj.taxRate ? taxObj.taxRate.id : null;
                }
                const invoiceRequest: InvoiceRequest = {
                    amount: dineroToRequest(priceDinero),
                    taxAmount: dineroToRequest(getDinero(taxes, product.asset_type)),
                    description: product.name,
                    productId: product.id,
                    category: product.category,
                    taxRateId,
                };

                dispatch(addInvoice(selectedCase.uuid, invoiceRequest));
            };

            this.openAddItemOptionDialog(addToContract, addToPayments);
        } else {
            addToContract();
        }
    };

    handleAddOneTimeContractItem = (
        name: string,
        price: string,
        taxRateId: number | null,
        category: ProductCategory,
        isAddedAsInvoice: boolean,
    ) => {
        const { isContractFrozen, selectedCase, activeContract, dispatch, taxRates } = this.props;

        const addToContractCallback = () => {
            const priceInCents = toPennyValue(price).getAmount();

            const itemToCreate: ProductCustomContractItemRequest = {
                category,
                name,
                list_price: priceInCents,
                tax_rate_id: taxRateId,
            };

            dispatch(createProductCustomContractItem(selectedCase.uuid, activeContract.id, itemToCreate));
        };

        if (isContractFrozen || isAddedAsInvoice || selectedCase.case_type === CaseType.one_off) {

            if (!isProductCategoryEnum(category)) {
                console.warn('Invalid product category', { category });
                return;
            }

            // define callback function
            const addToPaymentsCallback = () => {
                const priceDinero = toPennyValue(price);
                const taxRate = taxRates.find((tr) => tr.id === taxRateId);
                const taxesDinero = getDinero(taxRate ? applyTaxRate(priceDinero.getAmount(), taxRate) : 0, 'USD');
                const invoiceRequest: InvoiceRequest = {
                    amount: dineroToRequest(priceDinero),
                    taxAmount: dineroToRequest(taxesDinero),
                    description: name,
                    productId: null,
                    category: category,
                    taxRateId: taxRateId,
                };
                dispatch(addInvoice(selectedCase.uuid, invoiceRequest));
            };

            this.openAddItemOptionDialog(addToContractCallback, addToPaymentsCallback);
        } else {
            addToContractCallback();
        }

        this.closeAddItemDialog();
    };

    handleAddContractDiscountItem = (amount: number, itemName?: string) => {
        const { dispatch, selectedCase, activeContract, isContractFrozen } = this.props;

        const callback = () => {
            dispatch(createProductContractDiscountItem(selectedCase.uuid, activeContract.id, amount, itemName));
        };

        if (isContractFrozen) {
            const reason = 'Adding statement discount';
            this.openUnfreezeContractDialog(callback, reason);
        } else {
            dispatch(createProductContractDiscountItem(selectedCase.uuid, activeContract.id, amount, itemName));
        }
    };

    handleConfirmAddItemToContract = () => {
        const { addItemToContractCallback } = this.state;

        if (addItemToContractCallback) {
            addItemToContractCallback();
        } else {
            console.warn('handleConfirmAddItemToContract: no callback set');
        }
    };

    handleConfirmAddItemToPayments = () => {
        const { addItemToPaymentsCallback } = this.state;

        if (addItemToPaymentsCallback) {
            addItemToPaymentsCallback();
        } else {
            console.warn('handleConfirmAddItemToPayments: no callback set');
        }
    };

    handleConfirmUnfreeze = () => {
        const { unfreezeCallback } = this.state;

        if (unfreezeCallback) {
            unfreezeCallback();
        } else {
            console.warn('unfreezeCallback not set!');
        }
    };

    updateQuantityAndOrPrice = (contractItemId: string, quantity?: number, listPrice?: number) => {
        this.setState((prevState) => ({
            localProductItems: prevState.localProductItems.map((local) => {
                if (local.contractItem && local.contractItem.id === contractItemId) {
                    return {
                        ...local,
                        contractItem: {
                            ...local.contractItem,
                            quantity: quantity !== undefined ? quantity : local.contractItem.quantity,
                            list_price: listPrice !== undefined ? listPrice : local.contractItem.list_price,
                        },
                    };
                } else {
                    return local;
                }
            })
        }));
    };

    handleUpdateContractItem = (contractItemId: string, changes: ProductContractItemUpdateRequest) => {
        const { dispatch, selectedCase, activeContract, isContractFrozen } = this.props;

        const callback = () => {
            dispatch(updateProductContractItem(activeContract.id, contractItemId, changes, selectedCase.uuid));
        };

        if (isContractFrozen) {
            const contractItem = findContractItem(contractItemId, activeContract.items);
            if (!contractItem) {
                console.warn('handleUpdateContractItem: Failed to find statement item by id');
            }

            const reason = `Updating ${contractItem ? contractItem.name : 'item'}.`;
            this.openUnfreezeContractDialog(callback, reason);
        } else {
            callback();
        }
    };

    handleUpdateContractItemQuantity = (contractItem: ProductContractItemUX, quantity: number) => {
        const { selectedCase, activeContract, isContractFrozen } = this.props;

        if (!contractItem.product) {
            return;
        }

        const callback = () => {
            const listPrice = calculateContractItemListPrice(contractItem, quantity);
            if (!listPrice) {
                return;
            }

            this.debounceUpdateContractItem(activeContract.id, contractItem.id, { quantity }, selectedCase.uuid);
            // update local productItem's quantity & price
            this.updateQuantityAndOrPrice(contractItem.id, quantity, listPrice.getAmount());
        };

        if (isContractFrozen) {
            const reason = `Updating ${contractItem.name}'s quantity`;
            this.openUnfreezeContractDialog(callback, reason);
        } else {
            callback();
        }
    };

    handleUpdateContractItemPriceAdjustment = (contractItemId: string, priceAdjustment: number) => {
        this.handleUpdateContractItem(contractItemId, { price_adjustment: priceAdjustment });
    };

    handleUpdateContractItemPrice = (contractItemId: string, price: number) => {
        // This is used for non-manual priced items
        this.handleUpdateContractItem(contractItemId, { list_price: price });
        this.updateQuantityAndOrPrice(contractItemId, undefined, price);
    };

    handleUpdateContractDiscountItem = (contractItemId: string, amount: number, itemName?: string) => {
        const changes: ProductContractItemUpdateRequest = {
            list_price: amount,
        };
        if (itemName) {
            changes.name = itemName;
        }
        this.handleUpdateContractItem(contractItemId, changes);
    };

    handleUpdateContractItemNote = (contractItemId: string, note: string | null) => {
        this.handleUpdateContractItem(contractItemId, { note });
    };

    handleRemoveContractItem = (contractItem: ProductContractItemUX) => {
        const {
            dispatch,
            selectedCase,
            activeContract,
            contractPackages,
            isContractFrozen,
        } = this.props;

        const callback = () => {
            const selectedCaseUuid = selectedCase.uuid;

            const contractPackage = findContractPackageForContractItem(contractItem, contractPackages);

            if (contractPackage && shouldRemovingContractItemBreakPackage(contractItem)) {
                // if part of a package => warn that this will break the package
                const breakPackageName = contractPackage.name;
                const onPackageBreak = () =>
                    dispatch(deleteProductContractItem(activeContract.id, contractItem.id, selectedCaseUuid));
                this.toggleBreakPackageDialog(onPackageBreak, breakPackageName);
            } else {
                // if contractItem not part of a package => then remove normally
                dispatch(deleteProductContractItem(activeContract.id, contractItem.id, selectedCaseUuid));
            }
        };

        if (isContractFrozen) {
            const reason = `Removing ${contractItem.name}`;
            this.openUnfreezeContractDialog(callback, reason);
        } else {
            callback();
        }
    };

    handleAddPackage = (packageId: number) => {
        const { activeContract, selectedCase, dispatch, isContractFrozen, availablePackages } = this.props;

        const callback = () => {
            dispatch(addProductPackageToContract(packageId, activeContract.id, selectedCase.uuid));
        };

        if (isContractFrozen) {
            const pkg = availablePackages.find((p) => p.id === packageId);
            const reason = `Adding ${pkg ? pkg.name : 'package'}`;
            this.openUnfreezeContractDialog(callback, reason);
        } else {
            callback();
        }
    };

    handleRemovePackage = (packageId: number) => {
        const {
            activeContract,
            selectedCase,
            contractPackages,
            dispatch,
            isContractFrozen,
            availablePackages,
        } = this.props;

        const callback = () => {
            const selectedCaseUuid = selectedCase.uuid;

            // check if packageId is subPackage of a contract package
            const contractPackage = findContractPackageForSubPackageId(packageId, contractPackages);

            if (contractPackage) {
                // if a subPackage of a contract package => warn that this will break the package
                const onConfirmPackageBreak = () =>
                    dispatch(removeProductPackageFromContract(packageId, activeContract.id, selectedCaseUuid));
                this.toggleBreakPackageDialog(onConfirmPackageBreak, contractPackage.name);
            } else {
                // if not a subPackage => then remove normally
                dispatch(removeProductPackageFromContract(packageId, activeContract.id, selectedCaseUuid));
            }
        };

        if (isContractFrozen) {
            const pkg = availablePackages.find((p) => p.id === packageId);
            const reason = `Adding ${pkg ? pkg.name : 'package'}`;
            this.openUnfreezeContractDialog(callback, reason);
        } else {
            callback();
        }
    };

    toggleProductDetailDialogOpen = (productId?: number, contractItemId?: string, packageItemId?: number) => {
        this.setState((prevState) => ({
            isProductDetailDialogOpen: !prevState.isProductDetailDialogOpen,
            selectedProductId: productId || null,
            selectedContractItemId: contractItemId || null,
            selectedPackageItemId: packageItemId || null,
        }));
    };

    toggleBreakPackageDialog = (callbackOnConfirm?: () => void, breakPackageName?: string) => {
        this.setState((prevState) => ({
            isBreakConfirmationDialogOpen: !prevState.isBreakConfirmationDialogOpen,
            breakPackageName: breakPackageName || '',
            breakPackageCallback: callbackOnConfirm !== undefined ? callbackOnConfirm : () => null,
        }));
    };

    /**
     * Popper operations
     */
    clickAwayListener = (event: MouseEvent | TouchEvent) => {
        this.setState({ popperAnchorEle: null });
    };

    handleClickEventOnAvatar = (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        activeEntityId: number
    ) => {
        this.setState({
            popperAnchorEle: event.currentTarget,
            activeEntityId
        });
    };

    closeHelperPopper = () => {
        this.setState({
            popperAnchorEle: null
        });
    };

    closeAddItemOptionDialog = () => {
        this.setState({
            isAddItemOptionDialogOpen: false,
        });
    };

    openAddItemOptionDialog = (addItemToContractCallback: () => void, addItemToPaymentsCallback: () => void) => {
        this.setState({
            isAddItemOptionDialogOpen: true,
            addItemToContractCallback,
            addItemToPaymentsCallback,
        });
    };

    handleMenuClick = (menuAnchorEl: HTMLElement) => {
        this.setState({ menuAnchorEl });
    };

    handleMenuClose = () => {
        this.setState({ menuAnchorEl: null });
    };

    handleAllowanceToggle = (product?: ProductUX, contractItem?: ProductContractItemUX) => {
        const { contractItems } = this.props;

        if (contractItem && contractItem.allowance_item) {
            // item already using allowance -> remove allowance
            this.handleUpdateContractItem(contractItem.id, { allowance_item: null });
            return;
        }

        if (contractItem) {
            // item does not have allowance -> update item's allowance_item
            const allowanceItems = contractItems.filter((item) =>
                item.delete_revision === null &&
                isAllowanceItem(item) &&
                item.category === contractItem.category
            );

            if (!allowanceItems.length) {
                console.warn('No Allowance items but user was able to add item to allowance');
                return;
            }

            this.handleUpdateContractItem(contractItem.id, {
                allowance_item: allowanceItems[0].id,
            });
        } else if (product) {
            // product has not been added to contract -> add item to contract with allowance_item set
            const allowanceItems = contractItems.filter((item) =>
                item.delete_revision === null &&
                isAllowanceItem(item) &&
                item.category === product.category
            );

            if (!allowanceItems.length) {
                console.warn('No Allowance items but user was able to add product to allowance');
                return;
            }
            const quantity = getProductQuantity(product);
            this.handleAddContractItem(product, quantity, undefined, allowanceItems[0].id);
        }
    };

    toggleNotesDialog = () => {
        this.setState((prevState) => ({
            isNotesDialogOpen: !prevState.isNotesDialogOpen,
        }));
        this.handleMenuClose();
    };

    openSpecifyPriceDialog = () => {
        this.setState({
            isSpecifyPriceDialogOpen: true,
        });
    };

    closeSpecifyPriceDialog = () => {
        this.setState({
            isSpecifyPriceDialogOpen: false,
            addToAllowanceItem: null,
        });
    };

    openInviteHelperDialog = (personId: number) => {
        const { dispatch } = this.props;
        dispatch(openHelperInvitationDialog({
            zIndex: 1325,
            defaultTab: EntityCaseRole.guest,
            sendInvite: true,
            selectedEntityId: personId,
        }));
    };

    handleAddNote = async (note: string | null) => {
        const { selectedContractItemId } = this.state;

        if (selectedContractItemId) {
            this.handleUpdateContractItemNote(selectedContractItemId, note);
        }
    };

    handleSpecifyPrice = async (price: number) => {
        const { selectedContractItemId, specifyPriceCallback } = this.state;

        if (specifyPriceCallback) {
            specifyPriceCallback(price);
            this.setState({
                specifyPriceCallback: null,
            });
        } else if (selectedContractItemId) {
            this.handleUpdateContractItem(selectedContractItemId, { list_price: price });
            this.updateQuantityAndOrPrice(selectedContractItemId, 1, price);
        } else {
            this.addSelectedProductToContract(price);
        }
    };

    openPriceAdjustmentDialog = async () => {
        const { selectedContractItemId } = this.state;

        if (selectedContractItemId) {
            this.setState({
                isPriceAdjustmentDialogOpen: true,
            });
        } else {
            // don't await the promise
            this.addSelectedProductToContract();
            this.setState({
                isPriceAdjustmentDialogOpen: true,
            });
        }
        this.handleMenuClose();
    };

    closePriceAdjustmentDialog = async () => {
        this.setState({
            isPriceAdjustmentDialogOpen: false,
        });
    };

    openUnfreezeContractDialog = (unfreezeCallback: () => void, contractUnfreezeReason: string) => {
        this.setState({
            isUnfreezeContractDialogOpen: true,
            unfreezeCallback,
            contractUnfreezeReason,
        });
    };

    closeUnfreezeContractDialog = () => {
        this.setState({
            isUnfreezeContractDialogOpen: false,
            unfreezeCallback: null,
            contractUnfreezeReason: null,
        });
    };

    handlePriceAdjustment = (priceAdjustment: number) => {
        const { selectedContractItemId } = this.state;

        if (selectedContractItemId) {
            this.handleUpdateContractItemPriceAdjustment(selectedContractItemId, priceAdjustment);
        }
    };

    addSelectedProductToContract = async (listPrice?: number) => {
        const { products, productSummaries } = this.props;
        const { selectedProductId, selectedPackageItemId } = this.state;

        const product: ProductSummary | ProductUX | undefined = selectedProductId ?
            products.find((p) => p.id === selectedProductId) ||
            productSummaries.find((p) => p.id === selectedProductId)
            : undefined;
        if (!product) {
            return;
        }

        const createdItemId = await this.handleAddContractItem(
            product,
            getProductQuantity(product),
            selectedPackageItemId || undefined,
            undefined,
            listPrice,
        );
        return createdItemId;
    };

    isAllowanceAvailable = (): boolean => {
        const { productItems } = this.props;

        const contractItems = cleanMap(productItems, (pi) => pi.contractItem);
        const allowanceItems = contractItems.filter((item) => isAllowanceItem(item));

        // if there is an allowanceItem that hasn't been used up yet
        for (const allowanceItem of allowanceItems) {
            const creditAmount = calculateAllowanceCredit(allowanceItem, contractItems);
            if (creditAmount && -1 * creditAmount.getAmount() < allowanceItem.list_price) {
                return true;
            }
        }
        return false;
    };

    openPhotoSwipeDialog = (activeImage: string | null, photos: string[]) => {
        this.props.dispatch(openPhotoSwipeDialog('PRODUCT_OR_PACKAGE', activeImage, photos));
    };
}

export default withState(mapStateToProps)(GoodsAndServicesTab);
