import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import withStyles, { WithStyles, StyleRulesCallback } from '@mui/styles/withStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import MoreVertIcon from '@mui/icons-material/MoreVert';

const styles: StyleRulesCallback<Theme, BlankDD214ItemProps> = (theme) => ({
    root: {},
    item: {
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        '&$isDD214Item': {
            '& img': {
                minHeight: 161,
                '@media (min-width: 420px)': {
                    minHeight: 213,
                },
            },
        },
        '&:nth-child(odd)': {
            padding: '0 4px 0 8px',
        },
        '&:nth-child(even)': {
            padding: '0 8px 0 4px',
        },
        '& img': {
            width: '100%',
            minWidth: '100%',
            margin: 0,
            height: 'auto',
            boxShadow: theme.shadows[3],
            cursor: 'pointer',
        },
        '& $label': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            width: 'calc(100% - 16px)',
            '& p': {
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '85%',
                lineHeight: '1.25em',
            },
            '& $primary': {
                fontWeight: 300,
                fontSize: 12,
                textAlign: 'center',
            },
            '& $secondary': {
                display: 'none',
            },
        },
        '& $iconButton': {
            position: 'absolute',
            top: 4,
            right: 12,
            marginRight: 0,
            '& svg': {
                filter: 'drop-shadow(0 0px 2px gray)',
            },
        },
        '&$isCompactView': {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 12,
            flexDirection: 'row',
            '&:nth-child(odd)': {
                padding: 0,
            },
            '&:nth-child(even)': {
                padding: 0,
            },
            '&$isDD214Item': {
                '& img': {
                    border: `1px dashed ${theme.palette.primary.main}`,
                    minHeight: 100,
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
                },
            },
            '& img': {
                width: 77,
                minWidth: 77,
                margin: '0 8px',
            },
            '& $fileIcon': {
                filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
                cursor: 'pointer',
                '& svg': {
                    fontSize: 54,
                },
            },
            '& $iconButton': {
                height: 24,
                width: 24,
                '& svg': {
                    fontSize: 16,
                },
            },
            '& $label': {
                width: 'calc(100% - 121px)', // 77 + 16 + 24
                '& $primary': {
                    lineHeight: '1.25em',
                    fontWeight: 400,
                    fontSize: 16,
                    textAlign: 'left',
                },
                '& $secondary': {
                    lineHeight: '1.25em',
                    display: 'block',
                    fontWeight: 300,
                    fontSize: 13,
                },
            },
        },
    },
    isClickable: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    isDD214Item: {},
    fileIcon: {},
    label: {},
    primary: {},
    secondary: {},
    iconButton: {},
    isCompactView: {},
});

type StyledProps = WithStyles<
    | 'root'
    | 'isDD214Item'
    | 'fileIcon'
    | 'label'
    | 'primary'
    | 'isClickable'
    | 'secondary'
    | 'iconButton'
    | 'item'
    | 'isCompactView'
>;

interface BlankDD214ItemProps {
    isCompactView: boolean;
    onClick: (target: HTMLElement) => void;
}

const BlankDD214Item = (props: StyledProps & BlankDD214ItemProps) => {
    const { isCompactView, classes, onClick } = props;
    return (
        <Grid
            item
            xs={(isCompactView && 12) || 6}
            className={classNames(classes.item, isCompactView && classes.isCompactView)}
        >
            <IconButton
                color="primary"
                className={classes.fileIcon}
                onClick={(e) => onClick(e.currentTarget)}
                size="large"
            >
                <InsertDriveFileIcon />
            </IconButton>
            <Grid item xs={12} className={classes.label}>
                <Typography
                    color="secondary"
                    className={classNames(classes.primary, classes.isClickable)}
                    onClick={(e) => onClick(e.currentTarget)}
                >
                    Click to Upload DD-214
                </Typography>
                <Typography color="secondary" className={classes.secondary}>
                    Click to Assign Task
                </Typography>
            </Grid>
            <IconButton
                className={classes.iconButton}
                aria-label="More"
                aria-haspopup="true"
                aria-controls="upload-documents-item-menu"
                aria-owns={'upload-documents-item-menu'}
                onClick={(e) => onClick(e.currentTarget)}
                size="large"
            >
                <MoreVertIcon color="primary" />
            </IconButton>
        </Grid>
    );
};

export default withStyles(styles)(BlankDD214Item);
