import * as React from 'react';
import classNames from 'classnames';
import Dinero from 'dinero.js';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import BankIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { PaymentMethod, PaymentRequest } from '../../../../shared/types';
import { Payer, calculateProcessingFeesInCents, calculateAmountInCents, PaymentFieldToolTip } from '.';
import { StoreState } from '../../../../types';
import { toPennyValue, formatDinero } from '../../../../shared/goods_and_services/pricing';
import { getFormattedPhoneNumber } from '../../../../services';
import {
    collectPlaidPayment,
    PLAID_CHARGE_STATE,
    setPlaidChargeState,
    createPlaidLinkToken,
    setPlaidLinkToken,
} from '../../../../actions/Finance.action';
import { CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { joinNameParts } from '../../../../shared/utils';
import { AppDispatch } from '../../../../store';
import { SlideTransition } from '../../../common/Transitions';
import withState from '../../../common/utilHOC/WithState';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';

import { PlaidLink, PlaidLinkOnExit, PlaidLinkOnSuccess } from "react-plaid-link";
import { GStyles } from '../../../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    onlineDialog: {},
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 24px',
        margin: ' auto',
        textAlign: 'center',
        overflowX: 'hidden',
        position: 'relative',
        marginTop: 20,
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 16,
    },
    dialogPaper: {
        margin: 'auto',
        width: '100%',
        maxWidth: 'unset',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        '@media (min-width: 600px)': {
            borderRadius: 4,
            maxWidth: 360,
            width: 360,
            height: 'auto',
            maxHeight: 'calc(100% - 96px)',
            display: 'flex',
        },
    },
    cashDesc: {
        fontSize: 18,
        color: theme.palette.secondary.main
    },
    moneyIcon: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    headerContent: {
        textAlign: 'center',
        paddingBottom: 8,
        '& p': {
            color: theme.palette.common.white,
            '& span': {
                '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            }
        },
        '& $moneyIcon': {
            fontSize: 80,
        }
    },
    headerTitle: {
        fontSize: 24,
        '&:hover': {
            cursor: 'default !important',
            textDecoration: 'none !important',
        }
    },
    cashFormControl: {
        width: 160,
        marginTop: 12,
    },
    textField: {
        width: 200,
        margin: '8px 0',
        color: theme.palette.secondary.main,
        '& label': {
            width: 'max-content',
        },
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    },
    helperText: {
        fontSize: '12px !important',
        marginTop: 4,
        textAlign: 'center',
    },
    multiLineTextField: {
        marginTop: 20,
        width: 280,
        '& textarea': {
            minHeight: 43, // calculated as per the padding in mui
            maxHeight: 300
        },
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    checkFormControl: {
        width: 116,
        marginTop: 20,
        '@media (min-width: 400px)': {
            width: 124,
        }
    },
    checkNo: {
        marginLeft: 20,
        marginTop: 5,
        '@media (min-width: 400px)': {
            marginLeft: 40,
        }
    },
    payerName: {
        fontSize: 16,
        lineHeight: '18px',
    },
    payerEmail: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    checkMark: {
        color: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '50%',
        width: 88,
        height: 88,
        margin: '88px auto 16px',
        '& span': {
            fontSize: 68,
            paddingTop: 12,
            color: theme.palette.primary.main,
        }
    },
    totalAmountCont: {
        color: theme.palette.primary.main,
        borderTop: '1px solid',
        width: 120,
        margin: '8px auto',
        padding: '8px 20px',
        textAlign: 'center',
        fontSize: 18,
    },
    stripeNotConfigured: {
        padding: '0 4px'
    },
    textAreaRoot: {}
});

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    isDialogOpen: boolean;
    paymentInfo: PaymentRequest;
    isRestricted: boolean;
    payer: Payer;
    updatePayment?: (paymentInfo: PaymentRequest) => void;
    closeDialog: (activeReceipt: number | null) => void;
    openMySettingsDialog?: () => void;
    onAddNonUserContactInfoClick?: (nonUserId: number) => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    isAmountValid: boolean;
    isMerchFeeValid: boolean;
    isTotalValid: boolean;
    amount: string;
    merchFee: string;
    total: string;
    memo: string;
}

type StyledProps = Props & WithStyles<'root' | 'dialogPaper' | 'checkMark' | 'cashDesc' | 'moneyIcon'
    | 'onlineDialog' | 'dRoot' | 'header' | 'clearIcon' | 'headerContent' | 'headerTitle' | 'payerName'
    | 'dialogContent' | 'multiLineTextField' | 'bottomContent' | 'payerEmail' | 'textField' | 'totalAmountCont'
    | 'stripeNotConfigured' | 'textAreaRoot'>;

function mapStateToProps({ casesState, financeState }: StoreState) {
    return {
        casesState,
        financeState,
    };
}

type CombinedProps = StyledProps & DialogProps & InjectedProps & ReturnType<typeof mapStateToProps>;

class PlaidPaymentDialog extends React.Component<CombinedProps, State> {


    constructor(props: CombinedProps) {
        super(props);
        const { paymentInfo } = this.props;
        const { amount, merch_fee } = paymentInfo;
        const total = amount.add(merch_fee);
        this.state = {
            isAmountValid: true,
            isMerchFeeValid: true,
            isTotalValid: true,
            amount: (amount.getAmount() / 100.0).toFixed(2),
            merchFee: (merch_fee.getAmount() / 100.0).toFixed(2),
            total: (total.getAmount() / 100.0).toFixed(2),
            memo: paymentInfo.memo,
        };
    }

    componentDidMount() {
        if (this.props.isDialogOpen) {
            this.props.dispatch(createPlaidLinkToken(this.props.paymentInfo.funeralHomeCaseId));
        }
    }

    componentDidUpdate(prevProps: CombinedProps) {
        const props = this.props;
        // We need to kick off the Plaid create link API call before authorization can happen
        // Do this when the dialog is opened or when the payment request is switched
        if ((props.isDialogOpen && !prevProps.isDialogOpen
                || props.paymentInfo.payment_id !== prevProps.paymentInfo.payment_id)
            && this.props.financeState.plaidChargeState === PLAID_CHARGE_STATE.NOT_STARTED) {
            this.props.dispatch(createPlaidLinkToken(this.props.paymentInfo.funeralHomeCaseId));
        } else if ( // Dialog is closing, reset the plaid state
            !this.props.isDialogOpen
            && prevProps.isDialogOpen
            && this.props.financeState.plaidChargeState !== PLAID_CHARGE_STATE.NOT_STARTED) {
            this.props.dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.NOT_STARTED));
            this.props.dispatch(setPlaidLinkToken(null));
        }
    }

    exitPlaidTransaction: PlaidLinkOnExit = (err) => {
        const { dispatch } = this.props;
        if (err != null) {
            dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.NOT_STARTED));
        } else {
            dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.NOT_STARTED));
        }
    };

    authorizePlaidTransaction() {
        const { dispatch } = this.props;
        dispatch(setPlaidChargeState(PLAID_CHARGE_STATE.AUTHORIZING));
    }

    processPayment: PlaidLinkOnSuccess = (publicToken, metadata) => {
        const { paymentInfo, updatePayment, isRestricted, dispatch } = this.props;
        const { amount, merchFee, total } = this.state;

        const memo = isRestricted ? '' : this.state.memo;

        // validate amount
        const newAmount = toPennyValue(amount);
        if (newAmount.isZero() || newAmount.isNegative()) {
            this.setState({ isAmountValid: false });
            return;
        }
        const newMerchFee = toPennyValue(merchFee);
        if (newMerchFee.isNegative()) {
            this.setState({ isMerchFeeValid: false });
            return;
        }
        const newTotal = toPennyValue(total);
        if (newTotal.isZero() || newTotal.isNegative()) {
            this.setState({ isTotalValid: false });
            return;
        }

        if (paymentInfo.payment_id !== undefined) {
            const newInfo: PaymentRequest = {
                ...paymentInfo,
                amount: newAmount,
                merch_fee: newMerchFee,
                memo
            };
            dispatch(collectPlaidPayment(newInfo, publicToken, metadata.accounts[0].id));
            if (updatePayment) {
                updatePayment(newInfo);
            }
        }
    };

    calculateMerchFee(newAmount: Dinero.Dinero) {
        const { financeState } = this.props;
        const { feeSchedule } = financeState.transactions;
        return Dinero({
            amount: calculateProcessingFeesInCents(newAmount.getAmount(), feeSchedule, PaymentMethod.plaid),
            currency: 'USD'
        });
    }

    onChangeAmount(amount: string) {
        const newAmount = toPennyValue(amount);
        const newFee = this.calculateMerchFee(newAmount);
        const newTotal = newAmount.add(newFee);
        this.setState({
            amount,
            merchFee: (newFee.getAmount() / 100.0).toFixed(2),
            total: (newTotal.getAmount() / 100.0).toFixed(2),
        });
    }

    calculateAmount(newTotal: Dinero.Dinero) {
        const { financeState } = this.props;
        const { feeSchedule } = financeState.transactions;
        return Dinero({
            amount: calculateAmountInCents(newTotal.getAmount(), feeSchedule, PaymentMethod.plaid),
            currency: 'USD'
        });
    }

    onChangeTotal(total: string) {
        const newTotal = toPennyValue(total);
        const newAmount = this.calculateAmount(newTotal);
        const newFee = this.calculateMerchFee(newAmount);
        this.setState({
            amount: (newAmount.getAmount() / 100.0).toFixed(2),
            merchFee: (newFee.getAmount() / 100.0).toFixed(2),
            total,
        });
    }

    onChangeProcessingFee(merchFee: string) {
        const newFee = toPennyValue(merchFee);
        const amount = toPennyValue(this.state.amount);
        const newTotal = amount.add(newFee);
        this.setState({
            merchFee,
            total: (newTotal.getAmount() / 100.0).toFixed(2),
        });
    }

    onChangeMemo(memo: string) {
        this.setState({ memo });
    }

    renderSuccessScreen = () => {
        const { classes, paymentInfo } = this.props;

        return (<>
            <Grid>
                <div className={classes.checkMark}>
                    <AccountBalanceIcon />
                </div>
                <Typography
                    className={classNames(classes.cashDesc, GStyles.marginBottom50)}
                    align="center"
                >
                    Payment Successful
                </Typography>
            </Grid>
            {!!paymentInfo.payment_id && <Grid
                className={classes.bottomContent}
                item
                xs={12}
                md={12}
                lg={12}
            >
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => this.props.closeDialog(paymentInfo.payment_id || null)}
                    className={GStyles.marginBottom5}
                >
                    <ReceiptIcon className={classes.moneyIcon} />
                    View Receipt
                </Button>
            </Grid>}
        </>);
    };

    renderFailureScreen = (message: string) => {
        const { classes } = this.props;
        return (
            <Grid>
                <div className={classes.checkMark}>
                    <ReportProblemIcon />
                </div>
                <Typography
                    className={classNames(classes.cashDesc, GStyles.marginBottom50)}
                    align="center"
                >
                    {message}
                </Typography>
            </Grid>
        );
    };

    renderChargingScreen = () => {
        const { classes, financeState } = this.props;
        const { plaidChargeState } = financeState;
        const message = plaidChargeState === PLAID_CHARGE_STATE.CREATING_LINK
            || plaidChargeState === PLAID_CHARGE_STATE.NOT_STARTED
            ? 'Initializing'
            : plaidChargeState === PLAID_CHARGE_STATE.AUTHORIZING
                ? 'Authorizing' : 'Transferring Funds';

        return (<>
            <Grid>
                <div className={classes.checkMark}>
                    <AccountBalanceIcon />
                </div>
                <Typography
                    className={classNames(classes.cashDesc, GStyles.marginBottom50)}
                    align="center"
                >
                    {message}...
                </Typography>
            </Grid>
            <Grid
                className={classes.bottomContent}
                item
                xs={12}
                md={12}
                lg={12}
            >
                <CircularProgress size={70} />
            </Grid>
        </>);
    };

    renderPaymentScreen() {
        const {
            classes,
            payer,
            openMySettingsDialog,
            onAddNonUserContactInfoClick,
            isRestricted,
            financeState
        } = this.props;
        const { isAmountValid, isMerchFeeValid, isTotalValid, amount, merchFee, total, memo } = this.state;
        const totalFormatted = formatDinero(toPennyValue(total));

        const isProcessing = financeState.plaidChargeState === PLAID_CHARGE_STATE.AUTHORIZING
            || financeState.plaidChargeState === PLAID_CHARGE_STATE.CHARGING
            || financeState.plaidChargeState === PLAID_CHARGE_STATE.CREATING_LINK;
        const payerEmail = payer.payerEntity.email;
        const payerPhone = payer.payerEntity.phone;

        return (
            <>
                <Typography className={classes.cashDesc} align="center">
                    One-Time Direct Bank Transfer
                </Typography>

                <Grid item>
                    <Grid item>
                        <Tooltip
                            enterDelay={400}
                            placement="top"
                            title={!isRestricted && PaymentFieldToolTip.paymentSubtotal}
                        >
                            <TextField
                                required
                                error={!isAmountValid}
                                id="paymentAmount"
                                label="Payment Subtotal"
                                value={amount}
                                onChange={(e) => this.onChangeAmount(e.target.value)}
                                type="number"
                                className={classes.textField}
                                InputLabelProps={{ shrink: true }}
                                margin="dense"
                                variant="outlined"
                                disabled={isProcessing || isRestricted}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    classes: {
                                        notchedOutline: 'notranslate',
                                    },
                                }}
                                autoFocus
                            />
                        </Tooltip>
                    </Grid>

                    {(!isRestricted || merchFee !== '0.00') && (
                        <>
                            <Grid item>
                                <Tooltip
                                    enterDelay={400}
                                    placement="top"
                                    title={!isRestricted && PaymentFieldToolTip.paymentFee}
                                >
                                    <TextField
                                        id="optionalFee"
                                        label="Convenience Fee"
                                        error={!isMerchFeeValid}
                                        value={merchFee}
                                        onChange={(e) => this.onChangeProcessingFee(e.target.value)}
                                        type="number"
                                        className={classes.textField}
                                        InputLabelProps={{ shrink: true }}
                                        margin="dense"
                                        variant="outlined"
                                        disabled={isProcessing || isRestricted}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            classes: {
                                                notchedOutline: 'notranslate',
                                            },
                                        }}
                                    />
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip
                                    enterDelay={400}
                                    placement="top"
                                    title={!isRestricted && PaymentFieldToolTip.paymentTotal}
                                >
                                    <TextField
                                        id="total"
                                        label="Payment Total"
                                        error={!isTotalValid}
                                        value={total}
                                        onChange={(e) => this.onChangeTotal(e.target.value)}
                                        type="number"
                                        className={classes.textField}
                                        InputLabelProps={{ shrink: true }}
                                        margin="dense"
                                        variant="outlined"
                                        disabled={isProcessing || isRestricted}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            classes: {
                                                notchedOutline: 'notranslate',
                                            },
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                        </>
                    )}
                </Grid>

                {!isRestricted && (
                    <Grid>
                        <TextField
                            id="memo"
                            label="Memo (optional)"
                            multiline
                            value={memo}
                            onChange={(e) => this.onChangeMemo(e.target.value)}
                            className={classes.multiLineTextField}
                            InputProps={{
                                classes: {
                                    root: classes.textAreaRoot,
                                    notchedOutline: 'notranslate',
                                },
                            }}
                            margin="dense"
                            variant="outlined"
                            disabled={isProcessing}
                            placeholder="This memo will be visible on the final receipt"
                        />
                    </Grid>
                )}
                <Grid className={classes.bottomContent} item xs={12}>
                    <PlaidLink
                        className={`${GStyles.marginBottom5} ${GStyles.backgroundPrimary}`}
                        token={financeState.plaidLinkToken}
                        onSuccess={this.processPayment}
                        onExit={this.exitPlaidTransaction}
                        style={{
                            padding: '12px 22px',
                            borderRadius: '6px',
                            textTransform: 'uppercase',
                            color: 'white',
                            fontWeight: '600',
                        }}
                    >
                        ACH Transfer {totalFormatted}
                    </PlaidLink>
                    {openMySettingsDialog && onAddNonUserContactInfoClick && (
                        <Typography color="secondary" className={GStyles.fontSize12}>
                            {payerEmail || payerPhone ? 'Receipt will be sent to' : 'Receipt will NOT be sent.'}&nbsp;
                            <span
                                className={classes.payerEmail}
                                onClick={(e) => {
                                    this.props.closeDialog(null);
                                    if (payerEmail || payerPhone) {
                                        openMySettingsDialog();
                                    } else {
                                        onAddNonUserContactInfoClick(payer.payerEntity.id);
                                    }
                                }}
                            >
                                {payerEmail || payerPhone || 'Click to add contact information.'}
                            </span>
                        </Typography>
                    )}
                </Grid>
            </>
        );
    }

    renderAppropriateScreen() {
        const { financeState } = this.props;

        switch (financeState.plaidChargeState) {
            case PLAID_CHARGE_STATE.CHARGED:
                return this.renderSuccessScreen();
            case PLAID_CHARGE_STATE.AUTHORIZING:
            case PLAID_CHARGE_STATE.CHARGING:
            case PLAID_CHARGE_STATE.CREATING_LINK:
                return this.renderChargingScreen();
            case PLAID_CHARGE_STATE.NOT_STARTED:
                if (financeState.plaidLinkToken) {
                    return this.renderFailureScreen('Charge Cancelled');
                } else {
                    return this.renderChargingScreen();
                }
            case PLAID_CHARGE_STATE.LINK_CREATED:
                return this.renderPaymentScreen();
            case PLAID_CHARGE_STATE.AUTH_FAILED:
                return this.renderFailureScreen(
                    financeState.plaidLinkToken ? 'Authorization Failed' : 'Initialization Failed'
                );
            case PLAID_CHARGE_STATE.CHARGE_FAILED:
                return this.renderFailureScreen('Charge Failed');
            default:
                return this.renderFailureScreen('Integration Failure');
        }
    }

    render() {
        const {
            classes,
            isDialogOpen,
            closeDialog,
            payer,
            openMySettingsDialog,
            onAddNonUserContactInfoClick,
            zIndex
        } = this.props;

        const screen = this.renderAppropriateScreen();

        const payerName = joinNameParts(payer.payerEntity);
        const payerEmail = payer.payerEntity.email;
        const payerPhone = payer.payerEntity.phone;

        const openEditUserDialog = () => {
            if (!payerEmail && !payerPhone) {
                if (onAddNonUserContactInfoClick) {
                    onAddNonUserContactInfoClick(payer.payerEntity.id);
                }
            } else {
                closeDialog(null);
                if (openMySettingsDialog) {
                    openMySettingsDialog();
                }
            }
        };

        return (
            <div className={classes.root}>
                <Dialog
                    open={isDialogOpen}
                    onClose={() => closeDialog(null)}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.onlineDialog}
                    classes={{
                        paper: classes.dialogPaper,
                        root: classes.dRoot,
                    }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.header,
                            GStyles.backgroundPrimary
                        )}
                    >
                        <ClearIcon
                            className={classNames(classes.clearIcon, GStyles.colorWhite)}
                            onClick={() => closeDialog(null)}
                        />
                        <div
                            className={classes.headerContent}
                        >
                            <BankIcon className={classes.moneyIcon} />
                            <Typography className={classes.headerTitle}>
                                Process Payment
                            </Typography>
                            <Typography className={classes.payerName}>
                                <span onClick={e => openEditUserDialog()}>
                                    {payerName}
                                </span>
                            </Typography>
                            <Typography className={GStyles.fontSize12}>
                                <span onClick={e => openEditUserDialog()}>
                                    {payerEmail || 'No email listed'}
                                </span>
                            </Typography>
                            <Typography className={GStyles.fontSize12}>
                                <span onClick={e => openEditUserDialog()}>
                                    {payerPhone && getFormattedPhoneNumber(payerPhone) || 'No number listed'}
                                </span>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {screen}
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default compose(
    withState(mapStateToProps),
    withStyles(styles),
    withFullScreen('xs'),
)(PlaidPaymentDialog) as React.ComponentType<Props>;
