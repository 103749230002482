import { DialogTitle, Grid, IconButton, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GButton from '../../common/GButton';
import { FuneralHomeUX, GatherCaseSummary, InsurancePolicyUX, PolicyCaseStatus } from '../../../shared/types';
import { useState } from 'react';
import { useGDispatch } from '../../../types';
import { attachPolicyToCase, unAttachPolicyToCase, removePolicyFromDashboard } from '../../../actions/Insurance.action';
import GMenu, { GMenuItem } from '../../common/GMenu';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import PolicyCircles, { ActivePolicyCircleIdentifier } from './PolicyCircles';
import makeStyles from '@mui/styles/makeStyles';
import {
    FamilyRoutePage,
    joinNameParts,
    LegacyReportPage,
    RouteBuilder,
    getIntercomTargetProp
} from '../../../services';
import { log } from '../../../logger';
import { FILE_CLAIM_URL_HOMESTEADERS, SMALL_LOGO_HOMESTEADERS } from '../../../constants';

const useStyles = makeStyles(
    (theme: Theme) => ({
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px 5px 0 0',
            padding: 0,
            flexDirection: 'column',
        },
        headerText: {
            marginTop: '-40px',
            color: theme.palette.common.white,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '5px',
            paddingBottom: '10px',
            '& > p': {
                color: theme.palette.common.white,
                textAlign: 'center',
            },
            [theme.breakpoints.down(500)]: {
                marginTop: '15px',
            },
        },
        headerIconsBox: {
            color: '#fff',
            position: 'absolute',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            top: 0,
            cursor: 'pointer',
        },
        fileClaimButton: {
            display: 'flex',
            alignItems: 'baseline',
            color: `#fff !important`,
            border: '1px solid #fff',
            pt: '8px',
        }
    }),
    { name: 'PolicyHeader' },
);

interface Props {
    activePolicy: InsurancePolicyUX | null;
    activePolicyCircle: ActivePolicyCircleIdentifier;
    funeralHome: Pick<FuneralHomeUX, 'id' | 'key'>;
    insuranceColor: string;
    zIndex: number;
    policies: InsurancePolicyUX[];
    associatedCase: GatherCaseSummary | null;
    onAttachExistingCaseClick: () => void;
    onCreateCaseClick: () => void;
    closeDialog: () => void;
    onChangeActivePolicyCircle: (policyId: ActivePolicyCircleIdentifier) => void;
    onPolicyCaseStatusChanged?: () => void;
}

const PolicyHeader = (props: Props) => {
    const {
        activePolicy,
        activePolicyCircle,
        insuranceColor,
        funeralHome,
        zIndex,
        policies,
        associatedCase,
        onAttachExistingCaseClick,
        onCreateCaseClick,
        closeDialog,
        onChangeActivePolicyCircle,
        onPolicyCaseStatusChanged,
    } = props;

    const funeralHomeId = funeralHome.id;
    const funeralHomeKey = funeralHome.key;

    const dispatch = useGDispatch();
    const classes = useStyles();

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const [isUpdatingCase, setIsUpdatingCase] = useState<boolean>(false);

    const confirmPossibleMatch = async () => {
        setMenuAnchor(null);
        if (associatedCase && activePolicy && activePolicy.policy_case_status !== PolicyCaseStatus.attached) {
            setIsUpdatingCase(true);
            await dispatch(
                attachPolicyToCase({
                    policyId: activePolicy.policy_id,
                    caseUuid: associatedCase.uuid,
                    funeralHomeId,
                }),
            );
            setIsUpdatingCase(false);
        }
        onPolicyCaseStatusChanged?.();
        closeDialog();
    };

    const dismissPossibleMatch = async () => {
        if (!activePolicy || !associatedCase) {
            return;
        }
        setMenuAnchor(null);
        setIsUpdatingCase(true);
        await dispatch(unAttachPolicyToCase({ policyId: activePolicy.policy_id, funeralHomeId }));
        setIsUpdatingCase(false);
        onPolicyCaseStatusChanged?.();
        closeDialog();
    };

    const handleCreateNewCaseClick = () => {
        setMenuAnchor(null);
        onCreateCaseClick();
    };

    const handleAttachExistingCaseClick = () => {
        setMenuAnchor(null);
        onAttachExistingCaseClick();
    };

    const savePolicyForLater = () => {
        if (!activePolicy) {
            return;
        }
        dispatch(removePolicyFromDashboard({ policyId: activePolicy.policy_id, funeralHomeId }));
        closeDialog();
    };

    // menu item defintions
    const convertPolicyToPreNeedMenuItem = {
        id: 'convert-policy',
        content: 'Convert policy to new pre-need',
        onClick: handleCreateNewCaseClick,
    };
    const saveForLaterMenuItem = {
        id: 'save-for-later',
        content: 'Save for later',
        onClick: savePolicyForLater,
    };

    let headerText: string = '';
    let menuItems: GMenuItem[];
    let submitButton: { text: string; onClick: () => void } | null;
    let cancelButton: { text: string; onClick: () => void } | null;
    let attachedPolicyText: string | null = null;
    let unattachedPolicyText: string | null = null;
    let claimLink = '';
    if (activePolicyCircle !== 'AGGREGATE'
        && activePolicyCircle !== 'CASE_SUMMARY'
        && !activePolicy) {
        // likely loading or between states
        return null;
    } else if (!activePolicy) {
        // no policy in context i.e. on aggregate or case summary
        const attachedPolicies = policies.filter((p) => p.policy_case_status === PolicyCaseStatus.attached);
        const unattachedPolicies = policies.filter((p) => p.policy_case_status !== PolicyCaseStatus.attached);
        attachedPolicyText = !attachedPolicies.length
            ? ''
            : attachedPolicies.length > 1
                ? `${attachedPolicies.length} Policies are attached`
                : '1 Policy is attached';
        unattachedPolicyText = !unattachedPolicies.length
            ? ''
            : unattachedPolicies.length > 1
                ? `${unattachedPolicies.length} Policies are unattached`
                : '1 Policy is unattached';

        submitButton = null;
        cancelButton = null;
        menuItems = [];

        if (activePolicyCircle === 'AGGREGATE') {
            headerText = `${policies.length} total policies with ${policies[0].insurance_carrier.name}.`;
        } else {
            headerText =
                `Case summary${policies.length > 1 ? ` and aggregate policy information` : ``} 
                    for ${joinNameParts(associatedCase ?? {})}.`;
        }

    } else if (activePolicy.policy_case_status === PolicyCaseStatus.unattached) {
        // policy in context and is unattached
        headerText = `New policy sold for your business by
                        ${activePolicy.writing_agent.displayName} with ${activePolicy.insurance_carrier.name}.`;
        submitButton = {
            text: 'Convert Policy to pre-need',
            onClick: handleCreateNewCaseClick,
        };
        cancelButton = {
            text: 'Save for later',
            onClick: savePolicyForLater,
        };
        menuItems = [
            {
                id: 'pair-case',
                content: 'Pair to an existing case',
                onClick: handleAttachExistingCaseClick,
            },
            convertPolicyToPreNeedMenuItem,
        ];
    } else if (activePolicy.policy_case_status === PolicyCaseStatus.pending) {
        // policy in context and is pending
        if (!associatedCase) {
            log.warn('Policy case status is "saved" or "pending" but somehow has no associated case');
        }
        headerText = `New policy sold by
                        ${activePolicy.writing_agent.displayName} with ${activePolicy.insurance_carrier.name
            } that we think belongs to your existing case, ${joinNameParts(associatedCase ?? {})}.
            Click on the case profile photo to compare case data to policy data.`;
        submitButton = {
            text: 'Confirm Match',
            onClick: confirmPossibleMatch,
        };
        cancelButton = {
            text: 'Do not match',
            onClick: dismissPossibleMatch,
        };
        menuItems = [
            {
                id: 'pair-to-different-case',
                content: 'Pair to a different case',
                onClick: handleAttachExistingCaseClick,
            },
            convertPolicyToPreNeedMenuItem,
            saveForLaterMenuItem,
            {
                id: 'confirm',
                content: 'Confirm Match',
                onClick: confirmPossibleMatch,
            },
            {
                id: 'do-not-match',
                content: 'Do not match',
                onClick: dismissPossibleMatch,
            },
        ];
    } else if (activePolicy.policy_case_status === PolicyCaseStatus.attached) {
        // policy in context and is attached
        headerText =
            `Sold by ${activePolicy.writing_agent.displayName} with ${activePolicy.insurance_carrier.name}.`;
        submitButton = null;
        cancelButton = null;
        attachedPolicyText = 'Policy is attached';
        claimLink = activePolicy ? claimLink = FILE_CLAIM_URL_HOMESTEADERS + activePolicy.policy_number : '';
        menuItems = [
            {
                id: 'detach-from-case',
                content: 'Detach from case',
                onClick: dismissPossibleMatch,
            },
        ];
    } else {
        // we are in an unknown state somehow
        log.warn('Unknown policy case status', { activePolicy });
        headerText = '';
        submitButton = null;
        cancelButton = null;
        menuItems = [];
    }

    if (associatedCase) {
        // any menu that has an associated case should have the option to go to the statements page
        menuItems.push({
            id: 'statement-page',
            content: 'Go to Statements Page',
            link: RouteBuilder.FamilyPage({
                funeralHomeKey: associatedCase.funeral_home_key,
                caseName: associatedCase.name,
                page: FamilyRoutePage.CONTRACT,
            }),
        });
    }

    // all menus have the review all insurance policies option
    menuItems.push({
        id: 'review',
        content: 'Review all insurance policies',
        link: RouteBuilder.LegacyReportPage({
            funeralHomeKey,
            legacyReport: LegacyReportPage.INSURANCEPOLICIES,
        }),
    });

    return (
        <>
            <DialogTitle className={classes.header} sx={{ bgcolor: insuranceColor && `${insuranceColor} !important` }}>
                <div className={classes.headerIconsBox}>
                    <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
                        <MoreVertIcon sx={{ color: '#fff' }} />
                    </IconButton>
                    <IconButton onClick={closeDialog}>
                        <CloseIcon sx={{ color: '#fff' }} />
                    </IconButton>
                </div>
                <PolicyCircles
                    activePolicyCircle={activePolicyCircle}
                    insuranceColor={insuranceColor}
                    associatedCase={associatedCase}
                    policies={policies}
                    setActivePolicyCircle={onChangeActivePolicyCircle}
                />
                <Grid className={classes.headerText}>
                    <Typography fontSize={11} px={2}>
                        {headerText}
                    </Typography>
                    {attachedPolicyText && (
                        <div style={{ 'textAlign': 'center' }}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    color: '#FFFFFF80 !important',
                                    pt: 1,
                                    pb: 1,
                                }}
                            >
                                <CheckCircleOutlineIcon sx={{ fontSize: '25px !important' }} />
                                {attachedPolicyText}
                            </Typography>
                            {claimLink && activePolicy &&
                                <GButton
                                    variant="contained"
                                    text="File A Claim"
                                    startIcon={<img src={SMALL_LOGO_HOMESTEADERS} height="16px" />}
                                    className={classes.fileClaimButton}
                                    sx={{
                                        bgcolor: `${insuranceColor} !important`,
                                    }}
                                    onClick={() => window.open(claimLink , '_blank')}
                                >
                                    File A Claim
                                </GButton>
                            }
                        </div>
                    )}
                    {unattachedPolicyText && (
                        <Grid item>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    color: '#FFF !important',
                                    pt: 1,
                                }}
                            >
                                <ErrorIcon sx={{ fontSize: '25px !important' }} />
                                {unattachedPolicyText}
                            </Typography>
                        </Grid>
                    )}
                    {submitButton && (
                        <GButton
                            variant="contained"
                            text={submitButton.text}
                            onClick={submitButton.onClick}
                            isSpinning={isUpdatingCase}
                            {...getIntercomTargetProp(`PolicyDialog-SubmitButton`)}
                            sx={{
                                bgcolor: `#fff !important`,
                                color: `${insuranceColor} !important`,
                            }}
                        />
                    )}
                    {cancelButton && (
                        <GButton
                            variant="text"
                            text={cancelButton.text}
                            onClick={cancelButton.onClick}
                            sx={{
                                color: `#fff !important`,
                                boxShadow: 'none',
                            }}
                            {...getIntercomTargetProp(`PolicyDialog-SaveForLater`)}
                        />
                    )}
                </Grid>
            </DialogTitle>
            <GMenu zIndex={zIndex + 1} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)} items={menuItems} />
        </>
    );
};

export default PolicyHeader;
