import React, { useState, useEffect, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Popper, { PopperProps } from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Placement, ReferenceObject } from 'popper.js';
import makeStyles from '@mui/styles/makeStyles';
import { SxProps, Theme } from '@mui/material/styles';
import { getIntercomTargetProp } from '../../services';

const useStyles = makeStyles({
    root: {},
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '4px !important',
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '4px !important',
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderTop: 0,
            borderBottom: '16px solid #fff',
            top: '-14px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '16px solid #fff',
            borderBottom: 0,
            bottom: '-14px',
        },
    },
    arrow: {
        width: 0,
        height: 0,
        position: 'absolute',
        border: '14px solid transparent',
        overflow: 'hidden',
        boxShadow: '0 16px 10px -14px rgba(0, 0, 0, 0.5)',
    },
    height100: {
        height: '100%',
    }
}, { name: 'GPopper' });

export interface GPopperProps {
    zIndex: number;
    anchorEle: HTMLElement | ReferenceObject | null;
    closePopper: (event: MouseEvent | TouchEvent) => void;
}
interface Props extends GPopperProps {
    placement?: Placement;
    className?: string;
    paperClass?: string;
    paperStyles?: React.CSSProperties;
    hideArrow?: boolean;
    arrowClass?: string;
    arrowStyles?: React.CSSProperties;
    popperContentClass?: string;
    interactive?: boolean;
    id?: string;
    modifiers?: PopperProps['modifiers'];
    sx?: SxProps<Theme>;
    intercomTargetProp?: string;
    arrowContent?: ReactNode;
    arrowContainerClass?: string;
}

const GPopper = (props: PropsWithChildren<Props>) => {
    const {
        className,
        arrowClass,
        anchorEle,
        children,
        closePopper,
        zIndex,
        paperClass,
        popperContentClass,
        arrowStyles,
        interactive,
        placement,
        hideArrow,
        paperStyles,
        id,
        modifiers,
        sx,
        intercomTargetProp,
        arrowContent,
        arrowContainerClass
    } = props;

    const classes = useStyles();

    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(!!anchorEle);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | ReferenceObject | null>(anchorEle);
    const [contentElement, setContentElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setOpen(!!(anchorEle || contentElement));
        if (anchorEle) {
            setAnchorElement(anchorEle);
        }
    }, [anchorEle, contentElement]);

    return (
        <Popper
            id={id}
            open={open}
            anchorEl={anchorElement}
            transition
            placement={placement}
            sx={sx}
            className={classNames(classes.popper, className)}
            modifiers={[
                { name: 'flip', enabled: true },
                {
                    name: 'arrow',
                    options: {
                        enabled: true,
                        element: arrowRef,
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    }
                },
                ...(modifiers ?? [])
            ]}
            style={{ zIndex }}
            onMouseEnter={event => interactive && setContentElement(event.currentTarget)}
            onMouseLeave={event => interactive && setContentElement(null)}
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={closePopper}>
                    <Grow {...TransitionProps}>
                        <Paper
                            className={paperClass}
                            style={paperStyles}
                            {...getIntercomTargetProp(intercomTargetProp)}
                        >
                            {!hideArrow &&
                                <>
                                    {!arrowContent &&
                                        <span
                                            className={classNames(classes.arrow, arrowClass)}
                                            ref={setArrowRef}
                                            style={arrowStyles}
                                        />
                                    }

                                    {arrowContent &&
                                        <span ref={setArrowRef} className={arrowContainerClass}>
                                            {arrowContent}

                                            <span
                                                className={classNames(classes.arrow, arrowClass)}
                                                style={arrowStyles}
                                            />
                                        </span>
                                    }
                                </>
                            }


                            <Grid item xs={12} className={classNames(classes.height100, popperContentClass)}>
                                {children}
                            </Grid>
                        </Paper>
                    </Grow>
                </ClickAwayListener>
            )}
        </Popper>
    );
};

export default GPopper;
