import * as React from 'react';
import { PrintModeType } from '../..';
import PackageDisplay from './PackageDisplay';

interface Props {
    isCondensedView: boolean;
    title: string;
    subPackages: string[];
    typographyStyles: React.CSSProperties;
    isPrintMode: boolean;
    printModeType?: PrintModeType;
}

const ContractPackages: React.StatelessComponent<Props> = (props) => {
    const {
        isCondensedView,
        title,
        subPackages,
        typographyStyles,
        isPrintMode,
        printModeType
    } = props;

    return (
        <PackageDisplay
            isCondensedView={isCondensedView}
            title={title}
            subPackages={subPackages}
            typographyStyles={typographyStyles}
            isPrintMode={isPrintMode}
            printModeType={printModeType}
        />
    );
};

export default ContractPackages;
