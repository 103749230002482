import * as React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import { AppRoute } from '../../services';
import GLink from './GLink';

interface Props extends ButtonProps {
    noLink?: boolean;
    linkClasses?: string;
    buttonClasses?: string;
    noLinkAction?: (event?: React.MouseEvent<HTMLElement>) => void; // onClick
    link: AppRoute; // url to navigate to
}

const ButtonLink: React.FC<Props> = (props) => {
    const {
        disabled,
        noLink,
        link,
        noLinkAction,
        linkClasses,
        buttonClasses,
        children,
        onClick,
        ...buttonProps
    } = props;

    const button = (
        <Button
            {...buttonProps}
            className={buttonClasses}
            disabled={disabled}
            onClick={noLink ? noLinkAction : undefined}
        >
            {children}
        </Button>
    );

    if (onClick) {
        console.warn('ButtonLink: onClick has no action. Use noLinkAction instead');
    }
    if (disabled || noLink) {
        return button;
    } else {
        return (
            <GLink
                to={link}
                linkClass={linkClasses}
            >
                {button}
            </GLink>
        );
    }
};

export default ButtonLink;
