import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BulkEditBaseContainer from "./BulkEditBaseContainer";
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import { 
    isProductCategory, 
    ProductBulkAction, 
    ProductCategory, 
    ProductCategoryDisplayLookup, 
    ProductCategoryEnum, 
    ProductUX
} from "../../../../../shared/types";
import { useState } from "react";
import { values } from "lodash";
import { useBulkEditStyles } from "./BulkEditStyles";
import { useGDispatch } from '../../../../../types';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';

interface ChangeCategoryProps {
    selectButton: BulkEditButtonType | null;
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    zIndex: number;
}

const ChangeCategory = (props: ChangeCategoryProps) => {
    const { selectButton, onSubmitClick, selectedProductIds, zIndex, funeralHomeId } = props;
    const classes = useBulkEditStyles();
    const [selectedCategory, setSelectedCategory] = useState<ProductCategory|null>(null);
    const [isSpinning, setIsSpinning] = useState(false);

    const handleCategoryChange = (category: string) => {
        if (isProductCategory(category)) {
            setSelectedCategory(category);
        } else {
            setSelectedCategory(null);
        }
    };
    const dispatch = useGDispatch();
    const renderCategoryFilter = () => {
        return (
            <FormControl className={classes.selectMenuForm}>
                <InputLabel
                    htmlFor="categoryListOfOptions"
                    className="width-100"
                    variant="standard"
                >
                    Category
                </InputLabel>
                <Select
                    value={selectedCategory}
                    onChange={(e) => handleCategoryChange(e.target.value || '')}
                    MenuProps={{ style: { zIndex: zIndex + 1 } }}
                    inputProps={{
                        name: 'categoryListOfOptions',
                        id: 'categoryListOfOptions',
                    }}
                    classes={{ select: classes.selectMenu }}
                >
                    {(values(ProductCategoryEnum)).map((c: ProductCategory) => (
                        <MenuItem key={c} value={c}>
                            {ProductCategoryDisplayLookup[c]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const handleSubmitclick = async () => {
        // call api for change category
        if (!selectedCategory || !selectedProductIds?.length || typeof funeralHomeId !== 'number') {
            return;
        }
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.change_category,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: { category: selectedCategory },
        }));
        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <BulkEditBaseContainer
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            onSubmitClick={handleSubmitclick}
            disableSubmitButton={!selectedCategory?.trim()}
            isSpinning={isSpinning}
        >
            {renderCategoryFilter()}
        </BulkEditBaseContainer>
    );
};

export default ChangeCategory;