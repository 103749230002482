import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Sync from '@mui/icons-material/Sync';
import SyncDisabled from '@mui/icons-material/SyncDisabled';
import { isEventTypeArrangement } from './utils';
import { emptyStringParser } from '../../../../services';
import { EventCreateRequest, GatherEvent } from '../../../../shared/types';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import { WebsiteSyncFeatures, WhenAnyEnabled } from '../../../common/AppFeature';
import withGStyles from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    footerButtons: {
        '& button': {
            padding: 8,
        },
        '@media (min-width: 350px)': {
            '& button': {
                padding: '8px 24px',
            }
        },
    },
    footerTextInfo: {
        marginBottom: 8,
        fontSize: 15,
        '& svg': {
            verticalAlign: 'middle'
        }
    },
});
type Classes = 'root' | 'footerButtons' | 'footerTextInfo';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    existingEvent: GatherEvent | null;
    isEventSaved: boolean;
    isEventSaving: boolean;
    isFormChanged: boolean;
    thisEvent: EventCreateRequest;
    isFormValid: boolean;
    isPrivate: boolean;
    isCaseSync: boolean;
    saveServiceSchedule: () => void;
    openDeleteConfirmationDialog: () => void;
}
const Footer = ({
    classes,
    thisEvent,
    isFormChanged,
    isEventSaved,
    isEventSaving,
    existingEvent,
    isFormValid,
    isCaseSync,
    isPrivate,
    saveServiceSchedule,
    openDeleteConfirmationDialog
}: Props & StyledProps) => {
    const isArrangementConference = isEventTypeArrangement(thisEvent);

    const renderFooterInfo = () => (
        !isArrangementConference &&
        <WhenAnyEnabled features={WebsiteSyncFeatures}>
            {isPrivate ?
                <Typography color="secondary" align="center" className={classes.footerTextInfo}>
                    <SyncDisabled />&nbsp;
                    <span>INVITE ONLY events will</span> <span className={classes.fontWeight700}>NOT</span>
                    &nbsp;<span>be synced to your website</span>
                </Typography> :
                <Typography color="secondary" align="center" className={classes.footerTextInfo}>
                    <Sync />
                    &nbsp;<span>This event</span>&nbsp;
                    <span className={classes.fontWeight700}>
                        WILL
                    </span>
                    {isCaseSync && <span> NOT</span>}
                    &nbsp;<span>AutoSync with website</span>
                </Typography>
            }
        </WhenAnyEnabled>
    );

    if (isEventSaved || existingEvent) {
        return (
            <>
                <Grid container justifyContent="space-between" className={classes.footerButtons}>
                    <Grid item>
                        <Button
                            color="secondary"
                            size="large"
                            className={classes.margin_8}
                            disabled={isEventSaving}
                            onClick={openDeleteConfirmationDialog}
                            tabIndex={2}
                        >
                            <span>Delete Event</span>
                            {isEventSaving
                                && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="large"
                            disabled={isEventSaving || !isFormValid || !isFormChanged}
                            className={classes.margin_8}
                            onClick={saveServiceSchedule}
                            tabIndex={2}
                        >
                            <span>save changes</span>
                            {isEventSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </Grid>
                </Grid>

                {renderFooterInfo()}
            </>
        );
    }

    return (
        <>
            <Grid container justifyContent="center" className={classes.footerButtons}>
                <Grid item>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={isEventSaving}
                        className={classes.margin_8}
                        onClick={saveServiceSchedule}
                        tabIndex={2}
                    >
                        <span>schedule</span>&nbsp;
                        <span>{emptyStringParser(thisEvent.name) ? thisEvent.name : 'event'}</span>
                        {isEventSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </Grid>
            </Grid>

            {renderFooterInfo()}
        </>
    );
};

export default withGStyles(styles)(Footer);
