import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { DeathCertificateStatus } from '../../../shared/death_certificate/validators';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        width: '100%',
        maxWidth: 800,
    },
    statusContainer: {
        marginBottom: '0.5em',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    svgContainer: {
        height: 135,
        marginTop: '1.2em',
        '& svg': {
            maxWidth: 292,
        },
        '@media (min-width: 600px)': {
            height: 175,
            '& svg': {
                maxWidth: 400,
            },
        },
    },
    explainerText: {
        paddingLeft: '1em',
        paddingRight: '1em',
        fontSize: 16,
        fontWeight: 300,
        '@media (min-width: 600px)': {
            fontSize: 18,
        }
    },
    fontSize26: {
        fontSize: '26px'
    }
});

interface Props {
    dcStatus: DeathCertificateStatus;
    isPopupMenu?: boolean;
}

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'explainerText' | 'svgContainer' | 'statusContainer' | 'fontSize26'>;

class FormsCompletionStatus extends Component<StyledProps> {

    render() {
        const { classes, dcStatus, isPopupMenu } = this.props;

        let completeText: string = '';
        let remainingText: string = '';

        if (dcStatus.invalidSections === 0) {
            completeText = 'All Sections Complete';
            remainingText = 'Good work.';
        } else {
            const remaingFormsCount = dcStatus.invalidSections;

            completeText = `${dcStatus.validSections} Section${dcStatus.validSections === 1 ? '' : 's'} Complete`;
            remainingText = `(${remaingFormsCount} Remaining)`;
        }

        return (
            <Grid
                item
                className={classNames(classes.textCenter, classes.statusContainer)}
            >
                <Typography
                    color="secondary"
                    variant="h5"
                    align="center"
                    className={classNames(
                        isPopupMenu ? classes.fontSize26 : classes.fontSize30,
                        classes.fontWeight200
                    )}
                >
                    {completeText}
                </Typography>
                <Typography
                    color="secondary"
                    variant="subtitle1"
                    align="center"
                    className={classNames(classes.fontSize18, classes.fontWeight200)}
                >
                    {remainingText}
                </Typography>
            </Grid>
        );
    }
}

export default withGStyles(styles)(FormsCompletionStatus);
