import { useGSelector } from '../../types';
import FuneralHomeLogoPure, { FuneralHomeLogoPureProps } from './FuneralHomeLogoPure';

const FuneralHomeLogo = (
    props: Omit<FuneralHomeLogoPureProps, 'whiteLabel' | 'deepLink' | 'isPhotoSaving' | 'funeralHomeName'>,
) => {
    const whiteLabel = useGSelector((store) => store.whiteLabel);
    const deepLink = useGSelector(({ deepLinkState }) => deepLinkState.deepLink);
    const isPhotoSaving = useGSelector(({ funeralHomeState }) => funeralHomeState.isPhotoSaving);
    const funeralHomeName = useGSelector(({ funeralHomeState }) =>
        funeralHomeState.activeFuneralHome
            ? funeralHomeState.activeFuneralHome.name
            : funeralHomeState.publicFuneralHome
            ? funeralHomeState.publicFuneralHome.name
            : null,
    );

    return (
        <FuneralHomeLogoPure
            whiteLabel={whiteLabel}
            deepLink={deepLink}
            isPhotoSaving={isPhotoSaving}
            funeralHomeName={funeralHomeName}
            {...props}
        />
    );
};

export default FuneralHomeLogo;
