import { LocationUX, LocationRequest, addressFromLongAddress, LocationRecord } from '../shared/types';
import { postToAPI, getFromAPI, patchAPI, deleteFromAPI } from '.';
import { registerAppError } from './errors';
import { AppDispatch } from '../store';

export const SET_LOCATION_SAVING = 'SET_LOCATION_SAVING';

interface SetLocationSaving {
    type: typeof SET_LOCATION_SAVING;
    isSaving: boolean;
}

function setLocationSaving(isSaving: boolean): SetLocationSaving {
    return {
        type: SET_LOCATION_SAVING,
        isSaving,
    };
}

export const SET_LOCATIONS = 'SET_LOCATIONS';

interface SetLocations {
    type: typeof SET_LOCATIONS;
    locations: LocationUX[];
}

export function setLocations(locations: LocationUX[]): SetLocations {
    return {
        type: SET_LOCATIONS,
        locations,
    };
}

export const ADD_LOCATION = 'ADD_LOCATION';

interface AddLocation {
    type: typeof ADD_LOCATION;
    location: LocationUX;
}

function addLocationInStore(location: LocationUX): AddLocation {
    return {
        type: ADD_LOCATION,
        location,
    };
}

export function addLocation(location: LocationRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<LocationUX | null> => {
        try {
            LocationRequest.fromRequest(location);
        } catch (ex) {
            console.warn('Failed to validate LocationRequest:', location, ex);
            return null;
        }

        dispatch(setLocationSaving(true));
        const route = `funeralhome/${funeralHomeId}/location`;
        const savedLocation = await postToAPI<LocationUX>(route, { location }, dispatch);
        dispatch(setLocationSaving(false));
        if (!savedLocation) {
            dispatch(registerAppError('Unable to add location.'));
            return null;
        } else {
            dispatch(addLocationInStore(savedLocation));
            return savedLocation;
        }
    };
}

export const UPDATE_LOCATION = 'UPDATE_LOCATION';

interface UpdateLocation {
    type: typeof UPDATE_LOCATION;
    changes: Partial<LocationUX>;
    locationId: number;
}

function updateLocationInStore(locationId: number, changes: Partial<LocationUX>): UpdateLocation {
    return {
        type: UPDATE_LOCATION,
        locationId,
        changes,
    };
}

export function updateLocation(
    existingLocation: LocationUX,
    changes: Partial<LocationRequest>,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<LocationUX | null> => {

        try {
            LocationRequest.fromPatchRequest(changes);
        } catch (ex) {
            console.warn('Failed to validate LocationRequest:', changes, ex);
            return null;
        }

        // use existing changes when necessary
        const updatesToMake: Partial<LocationUX> = {
            name: changes.name || existingLocation.name,
            is_saved: changes.is_saved || existingLocation.is_saved,
            address: {
                ...addressFromLongAddress(
                    changes.long_address || existingLocation.address.long_address,
                    changes.timezone || existingLocation.address.timezone,
                    changes.use_address_description !== undefined
                        ? changes.use_address_description
                        : existingLocation.address.use_description
                ),
                id: existingLocation.address_id,
            },
        };
        dispatch(updateLocationInStore(existingLocation.id, updatesToMake));
        dispatch(setLocationSaving(true));
        const route = `funeralhome/${funeralHomeId}/location/${existingLocation.id}`;
        const updatedLocation = await patchAPI<LocationUX>(route, { location: changes }, dispatch);
        dispatch(setLocationSaving(false));
        if (!updatedLocation) {
            dispatch(registerAppError('Unable to update location.'));
            return null;
        } else {
            return updatedLocation;
        }
    };
}

export const DELETE_LOCATION = 'DELETE_LOCATION';

interface DeleteLocation {
    type: typeof DELETE_LOCATION;
    locationId: number;
}

function deleteLocationInStore(locationId: number): DeleteLocation {
    return {
        type: DELETE_LOCATION,
        locationId,
    };
}

export function deleteLocation(existingLocation: LocationUX, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<LocationRecord | null> => {
        dispatch(deleteLocationInStore(existingLocation.id));
        const route = `funeralhome/${funeralHomeId}/location/${existingLocation.id}`;
        const deletedLocation = await deleteFromAPI<LocationRecord>(route, dispatch);
        if (!deletedLocation) {
            dispatch(registerAppError('Unable to delete location.'));
            return null;
        } else {
            return deletedLocation;
        }
    };
}

export const SET_LOCATIONS_LOADING = 'SET_LOCATIONS_LOADING';

interface SetLocationsLoading {
    type: typeof SET_LOCATIONS_LOADING;
    isLoading: boolean;
}

function setLocationsLoading(isLoading: boolean): SetLocationsLoading {
    return {
        type: SET_LOCATIONS_LOADING,
        isLoading,
    };
}

export function loadLocations(funeralHomeId?: number) {
    return async (dispatch: AppDispatch): Promise<LocationUX[] | null> => {
        dispatch(setLocationsLoading(true));
        let resource: string = 'location/';
        if (funeralHomeId) {
            resource = `funeralhome/${funeralHomeId}/location/`;
        }
        let loadedLocations = await getFromAPI<LocationUX[]>(resource, dispatch);
        dispatch(setLocationsLoading(false));
        if (!loadedLocations) {
            dispatch(registerAppError('Unable to load locations.'));
            return null;
        } else {
            dispatch(setLocations(loadedLocations));
            return loadedLocations;
        }
    };
}

export function loadCaseLocations(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<LocationUX[] | null> => {
        dispatch(setLocationsLoading(true));
        let loadedLocations = await getFromAPI<LocationUX[]>(`api/case/${caseUuid}/location/`, dispatch);
        dispatch(setLocationsLoading(false));

        if (!loadedLocations) {
            dispatch(registerAppError('Unable to load locations.'));
            return null;
        } else {
            dispatch(setLocations(loadedLocations));
            return loadedLocations;
        }
    };
}

export type LocationAction = SetLocationSaving
| SetLocations
| AddLocation
| UpdateLocation
| DeleteLocation
| SetLocationsLoading
    ;
