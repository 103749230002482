import { MouseEventHandler, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import makeGStyles from '../../styles/makeGStyles';
import { getIntercomTargetProp } from '../../services';


const useStyles = makeGStyles(theme => ({
    root: {
        minWidth: 425,
        maxWidth: 425
    },
    barHeading: {
        maxWidth: '72%',
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        lineHeight: 1.125,
        borderRadius: '12px 12px 0 0',
        padding: '8px 20px 4px',
        fontSize: 18,
        zIndex: 1
    },
    leftBar: {
        borderRadius: 12,
        border: `4px solid ${theme.palette.primary.main}`,
        boxShadow: theme.shadows[4],
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'hidden',
        marginBottom: -4
    },
    moreVert: {
        width: 36,
        height: 36
    },
    badge: {
        position: 'relative',
        top: -4,
        left: -4,
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomRightRadius: 12,
        borderTopLeftRadius: 12,
        height: 36,
        width: 32,
    },
    explainerText: {
        marginTop: 4,
        lineHeight: 1.25,
        fontSize: 12
    },
    helperItem: {
        '&:before, &:after': {
            width: '0 !important',
            height: '0 !important',
            border: 'none !important'
        }
    }
}), { name: 'WorkFlowSummaryWrapper' });

export interface Step {
    id: number;
    label: string;
    prerequisites?: number;
    components?: number;
    icon?: JSX.Element;
    image?: string;
}

export interface WorkFlowSummaryWrapperClasses {
    rootClass?: string;
    containerClass?: string;
    innerClass?: string;
    barHeadingClass?: string;
}

interface Props {
    heading: string;
    badgeCount: number;
    description: JSX.Element;
    wrapperClasses?: Partial<WorkFlowSummaryWrapperClasses>;
    onMenuIconClick?: MouseEventHandler<HTMLElement>;
    intercomTargetProp?: string;
    workflowName: string;
}

const WorkFlowSummaryWrapper = (props: PropsWithChildren<Props>) => {
    const {
        heading,
        badgeCount,
        description,
        children,
        wrapperClasses,
        onMenuIconClick,
        intercomTargetProp
    } = props;

    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            className={classNames(classes.root, wrapperClasses?.rootClass)}
            {...getIntercomTargetProp(intercomTargetProp)}
        >
            <Typography
                align="center"
                className={classNames(classes.barHeading,
                wrapperClasses?.barHeadingClass)}
                {...getIntercomTargetProp(`WorkFlowSelection-${heading}Header`)}
            >
                {!onMenuIconClick && badgeCount} {heading}
            </Typography>

            <Grid container direction="column" wrap="nowrap"
                className={classNames(classes.leftBar, wrapperClasses?.containerClass)}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                    className={classNames(classes.marginBottom8, wrapperClasses?.innerClass)}
                >
                    {onMenuIconClick && <div className={classes.badge}>{badgeCount}</div>}

                    <Typography color="secondary" align="center" className={classes.explainerText}>
                        {description}
                    </Typography>

                    {onMenuIconClick &&
                        <IconButton
                            color="primary"
                            className={classes.moreVert}
                            onClick={onMenuIconClick}
                        >
                            <MoreVertIcon className={classes.fontSize28} />
                        </IconButton>
                    }
                </Grid>

                {children}
            </Grid>
        </Grid>
    );
};

export default WorkFlowSummaryWrapper;
