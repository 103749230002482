import * as constants from '../constants';

import {
    CREATED_TASK_TEMPLATE,
    DELETING_TASK_TEMPLATE,
    DELETING_TASK_TEMPLATE_FAILED,
    UPDATED_TASK_TEMPLATE,
    UPDATING_TASK_TEMPLATE,
    UPDATING_TASK_TEMPLATE_FAILED,
} from '../actions/task/TaskTemplate.action';

import {
    CREATED_WORKFLOW,
    CREATING_WORKFLOW,
    CREATING_WORKFLOW_FAILED,
    DELETED_WORKFLOW,
    DELETING_WORKFLOW,
    DELETING_WORKFLOW_FAILED,
    LOADED_WORKFLOW,
    LOADED_WORKFLOWS,
    LOADING_WORKFLOW,
    LOADING_WORKFLOW_FAILED,
    LOADING_WORKFLOWS,
    LOADING_WORKFLOWS_FAILED,
    UPDATED_WORKFLOW,
    UPDATED_WORKFLOWS_RANKS,
    UPDATING_WORKFLOW,
    UPDATING_WORKFLOW_FAILED,
    UPDATING_WORKFLOWS_RANKS,
    UPDATING_WORKFLOWS_RANKS_FAILED,
    ADDING_TASK_TO_WORKFLOW,
    UPDATING_WORKFLOW_TASK,
    REMOVING_TASK_FROM_WORKFLOW,
    WORKFLOW_STEP_RANKS_UPDATED,
    WORKFLOW_TASK_RANKS_UPDATED,
} from '../actions/Workflow.action';

import { WorkflowState } from '../types';
import {
    ORGANIZE_PAGE_LOADED,
    TRACKING_PAGE_LOADED,
    LOADED_PRIVATE_CASE,
    CASE_UPDATED,
} from '../actions/GatherCase.action';
import { GatherAction } from '../actions';
import {
    taskTemplateToWorkflowTaskForWorkflowUX,
} from '../shared/types';
import { WHITEBOARD_LOADED, WHITEBOARD_LOADING } from '../actions/Whiteboard.action';
import {
    TaskTemplateSummary,
    WorkflowTaskForWorkflowUX,
} from '../shared/types';
import orderBy from 'lodash/orderBy';

export const workflowInitData: WorkflowState = {
    workflows: [],
    activeWorkflow: null,
    isLoadingWorkflow: false,
    isLoadingWorkflowSummaries: false,
};

export const workflowState = (
    state: WorkflowState = workflowInitData,
    action: GatherAction,
): WorkflowState => {

    switch (action.type) {
        case LOADED_PRIVATE_CASE:
        case constants.USER_LOGGED_OUT: {
            return workflowInitData;
        }
        case CREATED_TASK_TEMPLATE: {
            if (!state.activeWorkflow) {
                return state;
            }
            return {
                ...state,
                activeWorkflow: {
                    ...state.activeWorkflow,
                    available_checklist_tasks: [
                        ...state.activeWorkflow.available_checklist_tasks,
                        action.taskTemplate,
                    ],
                }
            };
        }
        case UPDATED_TASK_TEMPLATE:
        case UPDATING_TASK_TEMPLATE: {
            if (!state.activeWorkflow) {
                return state;
            }
            return {
                ...state,
                activeWorkflow: {
                    ...state.activeWorkflow,
                    available_checklist_tasks: state.activeWorkflow.available_checklist_tasks.map(
                        (tt): TaskTemplateSummary => tt.id === action.task.id
                            ? action.task
                            : tt
                    ),
                    selected_checklist_tasks: state.activeWorkflow.selected_checklist_tasks.map(
                        (wt): WorkflowTaskForWorkflowUX => wt.task.id === action.task.id
                            ? {
                                ...wt,
                                task: action.task,
                            }
                            : wt
                    ),
                    available_tracking_steps: state.activeWorkflow.available_tracking_steps.map(
                        (tt): TaskTemplateSummary => tt.id === action.task.id
                            ? action.task
                            : tt
                    ),
                    selected_tracking_steps: state.activeWorkflow.selected_tracking_steps.map(
                        (wt): WorkflowTaskForWorkflowUX => wt.task.id === action.task.id
                            ? {
                                ...wt,
                                task: action.task,
                            }
                            : wt
                    ),
                },
            };
        }
        case UPDATING_TASK_TEMPLATE_FAILED: {
            if (!state.activeWorkflow) {
                return state;
            }
            return {
                ...state,
                activeWorkflow: {
                    ...state.activeWorkflow,
                    available_checklist_tasks: state.activeWorkflow.available_checklist_tasks.map((tt) => {
                        if (tt.id === action.id) {
                            return {
                                ...tt,
                                ...action.changesToRollback,
                            };
                        } else {
                            return tt;
                        }
                    }),
                    selected_checklist_tasks: state.activeWorkflow.selected_checklist_tasks.map((wt) => {
                        if (wt.task.id === action.id) {
                            return {
                                ...wt,
                                ...action.changesToRollback,
                            };
                        } else {
                            return wt;
                        }
                    }),
                    available_tracking_steps: state.activeWorkflow.available_tracking_steps.map((tt) => {
                        if (tt.id === action.id) {
                            return {
                                ...tt,
                                ...action.changesToRollback,
                            };
                        } else {
                            return tt;
                        }
                    }),
                    selected_tracking_steps: state.activeWorkflow.selected_tracking_steps.map((wt) => {
                        if (wt.task.id === action.id) {
                            return {
                                ...wt,
                                ...action.changesToRollback,
                            };
                        } else {
                            return wt;
                        }
                    }),
                },
            };
        }
        case DELETING_TASK_TEMPLATE: {
            if (!state.activeWorkflow) {
                return state;
            }
            return {
                ...state,
                activeWorkflow: {
                    ...state.activeWorkflow,
                    available_checklist_tasks: state.activeWorkflow.available_checklist_tasks.filter(
                        (tt) => tt.id !== action.idToRemove
                    ),
                    selected_checklist_tasks: state.activeWorkflow.selected_checklist_tasks.filter(
                        (wt) => wt.task.id !== action.idToRemove
                    ),
                    available_tracking_steps: state.activeWorkflow.available_tracking_steps.filter(
                        (tt) => tt.id !== action.idToRemove
                    ),
                    selected_tracking_steps: state.activeWorkflow.selected_tracking_steps.filter(
                        (wt) => wt.task.id !== action.idToRemove
                    ),
                },
            };
        }
        case DELETING_TASK_TEMPLATE_FAILED: {
            if (!state.activeWorkflow) {
                return state;
            }
            return {
                ...state,
                activeWorkflow: {
                    ...state.activeWorkflow,
                    available_checklist_tasks: [
                        ...state.activeWorkflow.available_checklist_tasks,
                        action.taskToRestore,
                    ],
                },
            };
        }
        case CREATING_WORKFLOW: {
            return {
                ...state,
                workflows: [...state.workflows, action.optimisticWorkflow],
            };
        }
        case CREATING_WORKFLOW_FAILED: {
            return {
                ...state,
                workflows: state.workflows.filter((dtl) => dtl.id !== action.idToRemove),
            };
        }
        case CREATED_WORKFLOW: {
            return {
                ...state,
                workflows: state.workflows.map((dtl) => {
                    if (dtl.id === action.idToReplace) {
                        return action.workflow;
                    } else {
                        return dtl;
                    }
                }),
            };
        }
        case ADDING_TASK_TO_WORKFLOW: {
            const taskAsWorkflowTask = taskTemplateToWorkflowTaskForWorkflowUX(action.task);
            return {
                ...state,
                activeWorkflow: state.activeWorkflow?.id !== action.workflowId
                    ? state.activeWorkflow
                    : {
                        ...state.activeWorkflow,
                        selected_checklist_tasks: action.task.tracking_step_type
                            ? state.activeWorkflow.selected_checklist_tasks
                            : [
                                ...state.activeWorkflow.selected_checklist_tasks,
                                taskAsWorkflowTask,
                            ],
                        selected_tracking_steps: action.task.tracking_step_type
                            ? [
                                taskAsWorkflowTask,
                                ...state.activeWorkflow.selected_tracking_steps,
                            ]
                            : state.activeWorkflow.selected_tracking_steps,
                    },
            };
        }
        case REMOVING_TASK_FROM_WORKFLOW: {
            return {
                ...state,
                activeWorkflow: state.activeWorkflow?.id !== action.workflowId
                    ? state.activeWorkflow
                    : {
                        ...state.activeWorkflow,
                        selected_checklist_tasks: state.activeWorkflow.selected_checklist_tasks
                            .filter((t) => t.workflow_task_id !== action.workflowTaskId),
                        selected_tracking_steps: state.activeWorkflow.selected_tracking_steps
                            .filter((t) => t.workflow_task_id !== action.workflowTaskId),
                    },
            };
        }
        case UPDATING_WORKFLOW_TASK: {
            return {
                ...state,
                activeWorkflow: state.activeWorkflow?.id !== action.workflowId
                    ? state.activeWorkflow
                    : {
                        ...state.activeWorkflow,
                    selected_checklist_tasks: state.activeWorkflow.selected_checklist_tasks
                        .map((t) => t.workflow_task_id === action.workflowTaskId ? { ...t, ...action.changes } : t),
                    selected_tracking_steps: state.activeWorkflow.selected_tracking_steps
                        .map((t) => t.workflow_task_id === action.workflowTaskId ? { ...t, ...action.changes } : t),
                    },
            };
        }
        case WORKFLOW_STEP_RANKS_UPDATED: {
            return {
                ...state,
                activeWorkflow: !state.activeWorkflow ? null : {
                    ...state.activeWorkflow,
                    selected_tracking_steps: orderBy(
                        state.activeWorkflow.selected_tracking_steps,
                        (s) => action.workflowTaskIds.indexOf(s.workflow_task_id)
                    ),
                },
            };
        }
        case WORKFLOW_TASK_RANKS_UPDATED: {
            return {
                ...state,
                activeWorkflow: !state.activeWorkflow ? null : {
                    ...state.activeWorkflow,
                    selected_checklist_tasks: orderBy(
                        state.activeWorkflow.selected_checklist_tasks,
                        (s) => action.workflowTaskIds.indexOf(s.workflow_task_id)
                    ),
                },
            };
        }
        case UPDATING_WORKFLOW: {
            return {
                ...state,
                activeWorkflow: state.activeWorkflow?.id !== action.workflowId
                    ? state.activeWorkflow
                    : {
                        ...state.activeWorkflow,
                        ...action.changes,
                    },
                workflows: state.workflows.map((wf) => {
                    if (wf.id === action.workflowId) {
                        return {
                            ...wf,
                            ...action.changes,
                        };
                    } else {
                        return wf;
                    }
                }),
            };
        }
        case UPDATING_WORKFLOW_FAILED: {
            return {
                ...state,
            };
        }
        case UPDATED_WORKFLOW: {
            return {
                ...state,
                activeWorkflow: state.activeWorkflow?.id !== action.updatedWorkflow.id
                    ? state.activeWorkflow
                    : action.updatedWorkflow,
            };
        }
        case DELETING_WORKFLOW: {
            return {
                ...state,
                workflows: state.workflows.filter((dtl) => dtl.id !== action.idToRemove),
            };
        }
        case DELETING_WORKFLOW_FAILED: {
            return {
                ...state,
                workflows: [...state.workflows, action.workflowToRestore],
            };
        }
        case DELETED_WORKFLOW: {
            return {
                ...state,
            };
        }
        case LOADING_WORKFLOWS: {
            return {
                ...state,
                isLoadingWorkflowSummaries: true,
            };
        }
        case WHITEBOARD_LOADING: {
            return {
                ...state,
                isLoadingWorkflowSummaries: true,
            };
        }
        case LOADING_WORKFLOWS_FAILED: {
            return {
                ...state,
                isLoadingWorkflowSummaries: false,
            };
        }
        case LOADED_WORKFLOWS: {
            return {
                ...state,
                isLoadingWorkflowSummaries: false,
                workflows: action.workflows,
            };
        }
        case WHITEBOARD_LOADED: {
            return {
                ...state,
                isLoadingWorkflowSummaries: false,
                workflows: action.whiteboard.workflows,
            };
        }
        case LOADING_WORKFLOW: {
            return {
                ...state,
                isLoadingWorkflow: true,
            };
        }
        case LOADING_WORKFLOW_FAILED: {
            return {
                ...state,
                isLoadingWorkflow: false,
            };
        }
        case CASE_UPDATED:
        case ORGANIZE_PAGE_LOADED:
        case TRACKING_PAGE_LOADED:
        case LOADED_WORKFLOW: {
            return {
                ...state,
                isLoadingWorkflow: false,
                activeWorkflow: action.workflow !== undefined ? action.workflow : state.activeWorkflow,
            };
        }
        case UPDATING_WORKFLOWS_RANKS: {
            return {
                ...state,
                workflows: action.optimisticWorkflows,
            };
        }
        case UPDATING_WORKFLOWS_RANKS_FAILED: {
            return {
                ...state,
                workflows: action.rollbackWorkflows,
            };
        }
        case UPDATED_WORKFLOWS_RANKS: {
            return {
                ...state,
                workflows: action.updatedWorkflows,
            };
        }
        default: {
            return state;
        }
    }
};
