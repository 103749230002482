import {
    FingerprintUX,
    FingerPrintUpdateRequest,
    FingerPrintCreateRequest,
    FingerprintType,
    CaseFingerprintUX,
    S3FileCreateRequest
} from '../shared/types';
import { registerAppError } from './errors';
import { deleteFromAPI, getFromAPI, patchAPI, postToAPI } from './index';
import { log } from '../logger';
import { AppDispatch } from '../store';
import generateUuid from 'uuid';
import { uploadS3FileToS3 } from './S3File.action';
import { getDataFromBlob } from '../services/doc.service';
import { generateS3Thumbnail } from '../shared/utils/s3Image';

export const FINGERPRINT_CREATED = 'FINGERPRINT_CREATED';
export type FINGERPRINT_CREATED = typeof FINGERPRINT_CREATED;

interface FingerprintCreated {
    type: FINGERPRINT_CREATED;
    caseFingerprint: CaseFingerprintUX;
}

function fingerprintCreated(caseFingerprint: CaseFingerprintUX): FingerprintCreated {
    return {
        type: FINGERPRINT_CREATED,
        caseFingerprint
    };
}

export const FINGERPRINT_UPLOADED = 'FINGERPRINT_UPLOADED';
export type FINGERPRINT_UPLOADED = typeof FINGERPRINT_UPLOADED;

interface FingerprintUploaded {
    type: FINGERPRINT_UPLOADED;
    caseFingerprint: CaseFingerprintUX;
}

function fingerprintUploaded(caseFingerprint: CaseFingerprintUX): FingerprintUploaded {
    return {
        type: FINGERPRINT_UPLOADED,
        caseFingerprint,
    };
}

export const FINGERPRINT_UPDATED = 'FINGERPRINT_UPDATED';
export type FINGERPRINT_UPDATED = typeof FINGERPRINT_UPDATED;

interface FingerprintUpdated {
    type: FINGERPRINT_UPDATED;
    s3FileId: number;
    fingerprint: Partial<FingerprintUX>;
}

function fingerprintUpdated(s3FileId: number, fingerprint: Partial<FingerprintUX>): FingerprintUpdated {
    return {
        type: FINGERPRINT_UPDATED,
        s3FileId,
        fingerprint,
    };
}

export const FINGERPRINT_DELETED = 'FINGERPRINT_DELETED';
export type FINGERPRINT_DELETED = typeof FINGERPRINT_DELETED;

interface FingerprintDeleted {
    type: FINGERPRINT_DELETED;
    fingerprint: FingerprintUX;
}

function fingerprintDeleted(fingerprint: FingerprintUX): FingerprintDeleted {
    return {
        type: FINGERPRINT_DELETED,
        fingerprint
    };
}


export const FINGERPRINTS_LOADING = 'FINGERPRINTS_LOADING';
export type FINGERPRINTS_LOADING = typeof FINGERPRINTS_LOADING;

interface FingerprintsLoading {
    type: FINGERPRINTS_LOADING;
}

function fingerprintsLoading(): FingerprintsLoading {
    return {
        type: FINGERPRINTS_LOADING,
    };
}

export const FINGERPRINTS_LOADED = 'FINGERPRINTS_LOADED';
export type FINGERPRINTS_LOADED = typeof FINGERPRINTS_LOADED;

interface FingerprintsLoaded {
    type: FINGERPRINTS_LOADED;
    fingerprints: FingerprintUX[];
}

function fingerprintsLoaded(fingerprints: FingerprintUX[]): FingerprintsLoaded {
    return {
        type: FINGERPRINTS_LOADED,
        fingerprints
    };
}

const uploadToS3 = async (
    caseUuid: string,
    blob: Blob,
    name: string,
    dispatch: AppDispatch
): Promise<S3FileCreateRequest | null> => {
    const uploadRequest = await getDataFromBlob(blob, name);
    if (!uploadRequest) {
        return null;
    }
    if (!uploadRequest.suffix) {
        dispatch(registerAppError('Invalid file type'));
        return null;
    }
    const preSignURLRoute = `api/case/${caseUuid}/fingerprint/uploadurl/` +
        `${uploadRequest.hash}/${uploadRequest.suffix}`;
    return await dispatch(uploadS3FileToS3({
        uploadRequest: uploadRequest,
        preSignURLRoute: preSignURLRoute,
    }));
};

export function createFingerprint(
    blob: Blob,
    name: string,
    type: FingerprintType,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch) => {

        const uuid = generateUuid();
        dispatch(fingerprintCreated({
            uuid,
            uploading: true,
            fingerprint: null,
        }));

        // upload full res image
        const [s3_file, s3_orig_file] = await Promise.all([
            (async () => {
                const thumbBlob = await generateS3Thumbnail(blob, 160 * 2, 180 * 2);
                return uploadToS3(caseUuid, thumbBlob, name, dispatch);
            })(),
            uploadToS3(caseUuid, blob, name, dispatch)
        ]);

        if (!s3_file) {
            return dispatch(registerAppError(`Problem uploading thumbnail fingerprint`));
        }
        if (!s3_orig_file) {
            return dispatch(registerAppError(`Problem uploading original fingerprint`));
        }

        const createRequest: FingerPrintCreateRequest = {
            type,
            s3_file: s3_file,
            s3_orig_file: s3_orig_file,
        };

        try {
            FingerPrintCreateRequest.fromRequest(createRequest);
        } catch (ex) {
            log.warn('Failed to validate FingerPrintCreateRequest:', { createRequest, ex });
            return null;
        }

        const result = await postToAPI<FingerprintUX>(`api/case/${caseUuid}/fingerprint`, createRequest, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem creating fingerprint`));
        }
        const caseFingerprint: CaseFingerprintUX = {
            uuid,
            fingerprint: result,
            uploading: false,
        };
        dispatch(fingerprintUploaded(caseFingerprint));
        return result;
    };
}

export function updateFingerprint(
    s3FileId: number,
    type: FingerprintType,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch) => {
        // update optimistically
        dispatch(fingerprintUpdated(s3FileId, {
            s3_file_id: s3FileId,
            type,
        }));
        const updateRequest: FingerPrintUpdateRequest = {
            type
        };
        try {
            FingerPrintUpdateRequest.fromRequest(updateRequest);
        } catch (ex) {
            log.warn('Failed to validate FingerPrintUpdateRequest:', { id: s3FileId, type, caseUuid, ex });
            return null;
        }
        const url = `api/case/${caseUuid}/fingerprint/${s3FileId}`;
        const result = await patchAPI<FingerprintUX>(url, updateRequest, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem updating fingerprint`));
        }
        return result;
    };
}

export function deleteFingerprint(fingerprint: FingerprintUX, caseUuid: string) {
    return async (dispatch: AppDispatch) => {
        // delete optimistically
        dispatch(fingerprintDeleted(fingerprint));
        const url = `api/case/${caseUuid}/fingerprint/${fingerprint.s3_file_id}`;
        const result = await deleteFromAPI<FingerprintUX>(url, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem deleting fingerprint`));
        }
        return result;

    };
}

export function loadFingerprintsForCase(caseUuid: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(fingerprintsLoading());
        const result = await getFromAPI<FingerprintUX[]>(`api/case/${caseUuid}/fingerprint`, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem loading fingerprints`));
        }
        dispatch(fingerprintsLoaded(result));
        return result;
    };
}

export type FingerprintAction =
    | FingerprintCreated
    | FingerprintUploaded
    | FingerprintUpdated
    | FingerprintDeleted
    | FingerprintsLoading
    | FingerprintsLoaded
    ;
