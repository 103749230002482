import * as React from 'react';
import classNames from 'classnames';

import ImageListItem from '@mui/material/ImageListItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Zoom from '@mui/material/Zoom';

import UserAvatarWithZoom from '../common/UserAvatarWithZoom';
import { StoreState } from '../../types';
import {
    UserInviteStatusEnum,
    EntityCaseRole,
    GatherCaseUX,
    getDisplayRelationshipForPerson,
    getCaseRole,
    EntitySummary,
    getCaseEntity,
    UserRoles,
    getDisplayTitleForFHUser,
} from '../../shared/types';
import { GRAY_COLOR_3, SKYBLUE_COLOR_2 } from '../../constants/colorVariables';
import HelperPopper from '../family/helperPopper/HelperPopper';
import { canViewCaseAllHelpersReport } from '../../shared/authority/can';
import CaseHelperReport from '../family/caseHelperReport';
import { closeHelperInvitationDialog } from '../../actions/Dialog.action';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { getHelpersTasks } from '../../shared/utils/task';

const mapStateToProps = ({ casesState, invitationState, tasksState, userSession }: StoreState) => {
    return {
        helpers: casesState.helpers,
        isHelpersLoading: casesState.isHelpersLoading,
        invitationStatus: invitationState.status,
        tasks: tasksState.checklistTasks,
        userData: userSession.userData,
        isUserPhotoSaving: userSession.isUserPhotoSaving,
    };
};

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    activeTab: EntityCaseRole;
    emptyAvatarList?: number[];
    selectedCase: GatherCaseUX;
    closeParentDialog: () => void;
    zIndex: number;
}

interface State {
    helperPopperAnchorEle: HTMLElement | null;
    activeEntityId: number | null;
    isDialogOpen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    avatarContainer: {
        display: 'inline-flex',
        width: 'auto !important',
        height: 'auto !important',
    },
    avatar: {
        height: 60,
        width: 60,
        '&$avatarButton': {
            margin: '8px auto 7px',
            padding: 0
        },
        '&$profilePictureAvatar': {
            boxShadow: theme.shadows[5],
        },
        '&$emptyAvatar': {
            color: theme.palette.primary.main,
            boxSizing: 'border-box',
            margin: '8px auto 5px',
            border: '2px dashed',
            background: 'transparent'
        },
        '&$nameInitialsAvatar': {
            fontSize: 30,
            textTransform: 'uppercase',
            boxShadow: theme.shadows[5],
            fontWeight: 200,
            '-webkit-font-smoothing': 'antialiased',
        },
    },
    gridList: {
        maxWidth: '100%',
        width: 'fit-content',
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        margin: '18px auto !important',
        display: 'flex',
        padding: 0,
        overflowY: 'auto',
        listStyle: 'none',
        scrollBehavior: 'smooth',
        '-webkit-overflow-scrolling': 'touch',
        '& li': {
            height: 'auto !important',
            width: '98px !important',
        }
    },
    avatarCaption: {
        fontSize: 12,
        fontWeight: 500,
        width: 98,
        lineHeight: '1.275em',
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
    },
    gridListTTitle: {
        textAlign: 'center',
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
    },
    gridTile: {
        height: '100%',
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        margin: '0 auto',
        '& $overlapDot': {
            top: 4,
            left: 4,
            fontSize: 12,
            background: SKYBLUE_COLOR_2,
            height: '1em',
            width: '1em',
            borderRadius: '50%',
            zIndex: 1,
            position: 'absolute',
        },
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '-6px 8px 11px',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 90,
    },
    caseHelperReportGrid: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& button': {
            margin: '8px auto 7px',
            minWidth: 60,
            maxWidth: 60,
            minHeight: 60,
            maxHeight: 60
        },
        '& svg': {
            fontSize: 44,
            color: GRAY_COLOR_3
        }
    },
    progress: {
        marginLeft: 9,
    },
    avatarButton: {},
    profilePictureAvatar: {},
    emptyAvatar: {},
    nameInitialsAvatar: {},
    overlapDot: {},
});

type StyledProps = Props & WithGStyles<'root' | 'avatarContainer' | 'avatar' | 'gridList'
    | 'avatarCaption' | 'gridListTTitle' | 'gridTile' | 'loadingText' | 'caseHelperReportGrid' | 'progress'
    | 'avatarButton' | 'profilePictureAvatar' | 'emptyAvatar' | 'nameInitialsAvatar' | 'overlapDot'>;

class InviteeList extends React.Component<StyledProps, State> {
    state: State = {
        helperPopperAnchorEle: null,
        activeEntityId: null,
        isDialogOpen: false
    };

    protected gridList: HTMLUListElement;

    componentDidUpdate() {
        const { invitationStatus } = this.props;
        if (invitationStatus === UserInviteStatusEnum.success) {
            setTimeout(
                () => {
                    this.scrollToNewInvitee();
                },
                1600
            );
        }
    }

    gridListDidMount = (gridList: HTMLUListElement) => {
        this.gridList = gridList;
    };

    canViewCaseAllHelpersReport = () => {
        const { userData, selectedCase } = this.props;

        return userData && canViewCaseAllHelpersReport(userData, selectedCase.id);
    };

    openCaseHelperDialog = () => {
        const { dispatch } = this.props;

        dispatch(closeHelperInvitationDialog());
        if (this.canViewCaseAllHelpersReport()) {
            this.setState({ isDialogOpen: true });
        }
    };

    closeDialog = () => {
        this.setState({ isDialogOpen: false });
    };

    scrollToNewInvitee = () => {
        const inviteeListInner1 = document.getElementById('invitee-list-inner-1');
        const inviteeListInner2 = document.getElementById('invitee-list-inner-2');

        if (!this.gridList || !inviteeListInner1 || !inviteeListInner2) {
            return;
        }

        this.gridList.scroll({ behavior: 'smooth' });
        const scrollLeft = inviteeListInner1.scrollWidth + inviteeListInner2.scrollWidth;
        this.gridList.scrollLeft = scrollLeft;
    };

    renderCaseHelperReportTab = () => {
        const { classes } = this.props;

        return (
            // TODO(Jonathan): consider refactoring this to use a List/ListItem component
            <ImageListItem
                className={classNames(
                    classes.gridListTTitle,
                )}
            >
                <div className={classNames(classes.gridTile, classes.caseHelperReportGrid)}>
                    <Zoom
                        in
                        timeout={1200}
                    >
                        <IconButton onClick={e => this.openCaseHelperDialog()} size="large">
                            <FormatListBulletedIcon color="primary" />
                        </IconButton>
                    </Zoom>

                    <Zoom
                        in
                        timeout={1200}
                    >
                        <Typography
                            variant="caption"
                            gutterBottom
                            align="center"
                            color="secondary"
                            className={classNames(classes.avatarCaption, classes.textUppercase)}
                        >
                            {'view all'}
                        </Typography>
                    </Zoom>
                </div>
            </ImageListItem >
        );
    };

    renderEmptyAvatars = () => {
        const { classes, emptyAvatarList, activeTab } = this.props;
        const showOverlapDot = activeTab === EntityCaseRole.admin;

        return (
            <div className={classes.avatarContainer} id="invitee-list-inner-2">
                {(emptyAvatarList || []).map((emptyAvatar: number) => (
                    <ImageListItem
                        key={emptyAvatar}
                        className={classes.gridListTTitle}
                    >
                        <div className={classes.gridTile}>
                            <Zoom in timeout={1200} key={activeTab}>
                                <div className={classes.positionRelative}>
                                    {showOverlapDot && <span className={classes.overlapDot} />}
                                    <Avatar className={classNames(classes.avatar, classes.emptyAvatar)} />
                                </div>
                            </Zoom>
                        </div>
                    </ImageListItem>
                ))}
            </div >
        );
    };

    clickAwayListener = (event: MouseEvent | TouchEvent) => {
        this.closeHelperPopper();
    };

    closeHelperPopper = () => {
        this.setState({
            helperPopperAnchorEle: null,
            activeEntityId: null,
        });
    };

    openHelperPopper = (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        activeEntityId: number,
    ) => {
        this.setState({
            activeEntityId,
            helperPopperAnchorEle: event.currentTarget
        });
    };

    renderHelperPopper = () => {
        const { selectedCase, closeParentDialog, zIndex } = this.props;
        const { helperPopperAnchorEle, activeEntityId } = this.state;

        return (
            <HelperPopper
                key={activeEntityId}
                zIndex={zIndex}
                clickAwayListener={this.clickAwayListener}
                selectedCase={selectedCase}
                activeEntityId={activeEntityId}
                popperAnchorEle={helperPopperAnchorEle}
                closeHelperPopper={() => this.closeHelperPopper()}
                closeParentDialog={closeParentDialog}
            />
        );
    };

    renderProfilePictureAvatars = () => {
        const {
            classes,
            userData,
            isUserPhotoSaving,
            activeTab,
            selectedCase,
            helpers,
            tasks,
        } = this.props;
        const { assignee } = selectedCase;
        const displayHelpers = helpers.filter((helper) => getCaseRole(helper, selectedCase.id) === activeTab);
        const displayEntities = activeTab === EntityCaseRole.admin
            ? [assignee, ...displayHelpers]
            : displayHelpers;

        return (
            <div className={classes.avatarContainer} id="invitee-list-inner-1">
                {displayEntities.map((helper: EntitySummary) => {
                    const caseEntity = getCaseEntity(helper, selectedCase.id);
                    const isAssignee = assignee.entity_id === helper.entity_id;
                    if (!caseEntity && !isAssignee) {
                        return null;
                    }
                    const helpersTasks = getHelpersTasks({ tasks, helper, onlyIncompleteTasks: true });
                    const helperTaskCount = helpersTasks.length;
                    return (
                        <ImageListItem
                            key={helper.entity_id}
                            className={classes.gridListTTitle}
                        >
                            <div className={classes.gridTile}>
                                <IconButton
                                    className={classNames(classes.avatarButton, classes.avatar)}
                                    onClick={(e) => this.openHelperPopper(e, helper.entity_id)}
                                    size="large">
                                    <UserAvatarWithZoom
                                        user={helper}
                                        photoClassName={classNames(classes.avatar, classes.profilePictureAvatar)}
                                        initialsClassName={classNames(classes.avatar, classes.nameInitialsAvatar)}
                                        zoomDuration={1200}
                                        size={70}
                                        isLoading={isUserPhotoSaving && userData && helper.user_id
                                            && userData.id === helper.user_id || undefined}
                                        showOverlapDot={UserRoles.isFamilyAdmin(helper, selectedCase.id)
                                            || UserRoles.isFHorGOMUser(helper) || isAssignee}
                                        overlapDotClass={classes.overlapDot}
                                        isInviteeList
                                        badgeCount={helperTaskCount}
                                        isMe={Boolean(userData && helper.user_id && userData.id === helper.user_id)}
                                    />
                                </IconButton>
                                <Zoom
                                    in
                                    timeout={1200}
                                >
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        color="secondary"
                                        className={classNames(classes.avatarCaption, classes.textUppercase)}
                                    >
                                        {helper.fname}
                                    </Typography>
                                </Zoom>
                                <Zoom
                                    in
                                    timeout={1200}
                                >
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        align="center"
                                        color="secondary"
                                        className={classNames(classes.avatarCaption, classes.fontWeight400)}
                                    >
                                        {isAssignee
                                            ? getDisplayTitleForFHUser(assignee, selectedCase.funeral_home_id)
                                            : caseEntity
                                                ? getDisplayRelationshipForPerson(caseEntity) : ''
                                        }
                                    </Typography>
                                </Zoom>
                            </div>
                        </ImageListItem>
                    );
                })}
            </div>
        );
    };

    renderLoadingHelpers = () => {
        const { classes } = this.props;

        return (
            <ImageListItem>
                <CircularProgress
                    color="primary"
                    className={classes.progress}
                    size={76}
                    thickness={3}
                />
                <Typography
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    Loading...
                </Typography>
            </ImageListItem>
        );
    };

    render() {
        const { classes, isHelpersLoading, zIndex, selectedCase } = this.props;
        const { isDialogOpen } = this.state;

        const shouldRenderHelperReportFab = this.canViewCaseAllHelpersReport();

        return (
            <ul className={classes.gridList} ref={this.gridListDidMount}>
                {isHelpersLoading ? this.renderLoadingHelpers() :
                    <>
                        {this.renderProfilePictureAvatars()}
                        {this.renderEmptyAvatars()}
                        {this.renderHelperPopper()}
                        {shouldRenderHelperReportFab && this.renderCaseHelperReportTab()}
                        {shouldRenderHelperReportFab &&
                            <CaseHelperReport
                                zIndex={zIndex}
                                selectedCase={selectedCase}
                                isOpen={isDialogOpen}
                                closeDialog={this.closeDialog}
                            />
                        }
                    </>
                }
            </ul>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(InviteeList));
