import { SvgIconComponent } from '@mui/icons-material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { ORANGE_COLOR } from '../../../../../../constants/colorVariables';
import makeGStyles from '../../../../../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        background: ORANGE_COLOR,
        borderRadius: 8,
        padding: 8,
        lineHeight: 1.25,
        '& svg': {
            float: 'left',
            fontSize: 34,
            marginRight: 4
        }
    },
}), { name: 'StepComponentWarning' });

interface Props {
    text: string;
    Icon?: SvgIconComponent;
    className?: string;
}

const StepComponentWarning = (props: Props) => {
    const {
        text,
        Icon,
        className
    } = props;

    const classes = useStyles();

    const SVGIcon = Icon || ReportProblemOutlinedIcon;

    return (
        <Typography className={classNames(classes.root, className)}>
            <SVGIcon color="inherit" />
            <span>
                {text}
            </span>
        </Typography>
    );
};

export default StepComponentWarning;
