import classNames from "classnames";
import Grid from "@mui/material/Grid";
import { useTaskDialogStyles } from "./TaskDialogsStyles";
import { openInterCom } from "../../../services";
import Typography from "@mui/material/Typography";
import { PropsWithChildren } from "react";

interface Props {
    icon: JSX.Element;
    heading?: string;
}
const TaskDialogStepWrapper = ({ icon, heading, children }: PropsWithChildren<Props>) => {
    const classes = useTaskDialogStyles();

    return (
        <Grid
            item
            xs={12}
            sm={10}
            className={classNames(
                classes.marginAuto,
                classes.textCenter,
                classes.marginBottom20
            )}
        >
            <Grid className={classNames(classes.marginBottom10, classes.borderBottom,)}>
                <div className={classNames(classes.secondStateIcon, classes.marginAuto)}>
                    {icon}
                </div>

                {heading &&
                    <Typography
                        color="secondary"
                        className={classNames(classes.fontSize28, classes.fontWeight300)}
                    >
                        {heading}
                    </Typography>
                }
            </Grid>

            {children}

            <Grid>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    Still have questions?
                    <span className={classNames(classes.clickable, classes.fontWeight500)}
                        onClick={() => openInterCom()}
                    >
                        {' Chat with us now'}
                    </span>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default TaskDialogStepWrapper;