import { PopoverReference } from '@mui/material/Popover';
import { AppRoute } from '../../../services';
import { DocDownloadResponse, DocPacketUX, DocUX, GatherCaseUX } from '../../../shared/types';
import ESignSignatures from './ESignSignatures';
import PrintSignSignatures from './PrintSignSignatures';

interface Props {
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
    packetLink?: AppRoute;
    onDownloadSignedDoc: (doc: DocUX) => Promise<DocDownloadResponse | null>;
    zIndex: number;
    setReminderAnchorEl: (anchorEl: HTMLElement | null) => void;
    reminderAnchorEl: HTMLElement | null;
    setMenuAnchorEle: (anchorEl: HTMLElement | null) => void;
    menuAnchorEle: HTMLElement | null;
    isChangeEmailDialogOpen?: (isOpen: boolean) => void;
    isHelperPopperOpen?: (isOpen: boolean) => void;
    toggleDocMenuOpen?: (isOpen: boolean) => void;
    menuAnchorReference?: PopoverReference;
};

export const DocPacketSignatures = (props: Props) => {
    const {
        docPacket,
        onDownloadSignedDoc,
        packetLink,
        activeCase,
        zIndex,
        setReminderAnchorEl,
        reminderAnchorEl,
        menuAnchorEle,
        setMenuAnchorEle,
        isChangeEmailDialogOpen,
        isHelperPopperOpen,
        toggleDocMenuOpen,
        menuAnchorReference
    } = props;

    const eSignSignatures = (
        <ESignSignatures
            packetLink={packetLink}
            packetSigners={docPacket.signers}
            signedDoc={docPacket.signedDocs[0] || null}
            onDownloadSignedDoc={onDownloadSignedDoc}
            zIndex={zIndex + 1}
            setReminderAnchorEl={setReminderAnchorEl}
            reminderAnchorEl={reminderAnchorEl}
            setMenuAnchorEle={setMenuAnchorEle}
            menuAnchorEle={menuAnchorEle}
            docPacket={docPacket}
            activeCase={activeCase}
            isChangeEmailDialogOpen={isChangeEmailDialogOpen}
            isHelperPopperOpen={isHelperPopperOpen}
        />
    );

    const printAndSignSignatures = (
        <PrintSignSignatures
            key={docPacket.signedDocs.map((signedDoc) => signedDoc.id).join('')}
            docPacket={docPacket}
            activeCase={activeCase}
            onDownloadSignedDoc={onDownloadSignedDoc}
            zIndex={zIndex + 1}
            toggleDocMenuOpen={toggleDocMenuOpen}
            menuAnchorReference={menuAnchorReference}
        />
    );
    return docPacket.type === 'fill_esign' ? eSignSignatures : printAndSignSignatures;
};