import * as React from 'react';
import moment from 'moment-timezone';

import { ButtonProps } from '@mui/material/Button';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Doc from './Doc';
import { AcceptFileType } from '../FileUploader';
import UploadButton from '../UploadButton';
import makeGStyles from '../../../styles/makeGStyles';

export const useDocWidgetStyles = makeGStyles(theme => ({
    root: {},
    docListContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        maxWidth: 260,
        backgroundColor: theme.palette.common.white,
        borderRadius: 6,
        marginLeft: 6,
        boxShadow: theme.shadows[4],
        padding: '1px 0px',
        minWidth: 260,
        textTransform: 'none',
        '& svg': {
            fontSize: 56
        },
        '& $docLabelContainer': {
            marginLeft: '-4px',
            '& $docName': {
                fontSize: 16,
                maxWidth: 185,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            '& $docUploadedBy': {
                fontSize: 12,
                lineHeight: '1.20em'
            },
            '& $docUploadedTime': {
                fontSize: 12,
                lineHeight: '1.20em'
            }
        },
        '& $fileMoreIcon': {
            width: 20,
            height: 20,
            position: 'absolute',
            top: 6,
            right: 4,
            '& svg': {
                fontSize: 20
            }
        }
    },
    docTabLabelContainer: {
        padding: 6
    },
    docTabRoot: {
        maxWidth: '100%',
        opacity: 1,
        cursor: 'auto',
        '&:first-of-type $docTabLabelContainer': {
            paddingLeft: 0
        },
        '&:hover, &:active': {
            opacity: 1
        }
    },
    docTabsIndicator: {
        display: 'none'
    },
    docScrollButtons: {
        width: 24,
        display: 'block !important',
        '& svg': {
            color: theme.palette.primary.main
        }
    },
    paddingBottom15: {
        paddingBottom: 15
    },
    fileMoreIcon: {},
    docUploadedTime: {},
    docUploadedBy: {},
    docLabelContainer: {},
    docName: {}
}));

export interface DocWidgetDoc {
    id: number | string;
    name: string;
    uploadedBy: string;
    uploadedTime: string;
}

export interface DocWidgetProps {
    docs: DocWidgetDoc[];
    docItemClass?: string;
    uploadButtonProps?: Partial<ButtonProps>;
    isDocUploading?: boolean;
    handleUpload?: (files: FileList | null) => void;
    menuHandler?: (docId: number | string, evt: React.MouseEvent<HTMLElement>) => void;
}

const DocWidget = (props: DocWidgetProps) => {
    const {
        docs,
        uploadButtonProps,
        isDocUploading,
        docItemClass,
        handleUpload,
        menuHandler
    } = props;

    const classes = useDocWidgetStyles();

    const uploadNewFileButton = (
        handleUpload &&
        <UploadButton
            {...uploadButtonProps}
            handleUpload={handleUpload}
            acceptFileTypes={[
                AcceptFileType.JPEG,
                AcceptFileType.GIF,
                AcceptFileType.PNG,
                AcceptFileType.DOCUMENT,
                AcceptFileType.MSWORD,
                AcceptFileType.PDF
            ]}
            buttonText="Upload new file"
            isLoading={isDocUploading}
            buttonIcon={<CloudUploadIcon />}
        />
    );

    const content: React.ReactNode = (
        <>
            {docs.map(doc =>
                <Doc
                    key={doc.id}
                    name={doc.name}
                    uploadedBy={doc.uploadedBy}
                    uploadedTime={moment(doc.uploadedTime).format()}
                    classes={classes}
                    menuHandler={menuHandler
                        ? (e) => menuHandler(doc.id, e)
                        : undefined}
                    docItemClass={docItemClass}
                />
            )}
        </>
    );

    return (
        <>
            {content}
            {uploadNewFileButton}
        </>
    );
};

export default DocWidget;
