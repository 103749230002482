import { PropsWithChildren } from 'react';
import GButton, { GButtonProps } from './GButton';
import GLink from './GLink';
import { RouteBuilder } from "../../services";
import { AppRoute } from "../../services";

interface Props {
    to: AppRoute;
    disabled?: boolean;
    linkClass?: string;
    buttonClass?: string;
    GButtonProps?: Omit<GButtonProps, 'text' | 'children' | 'className'>;
    intercomTargetProp?: string;

}
const GLinkWithButton = (props: PropsWithChildren<Props>) => {

    return (
        <GLink
            to={props.disabled ? RouteBuilder.Disabled : props.to}
            linkClass={props.linkClass}
            tabIndex={-1}
        >
            <GButton
                {...props.GButtonProps}
                disabled={props.disabled}
                className={props.buttonClass}
                intercomTargetProp={props.intercomTargetProp}
            >
                {props.children}
            </GButton>
        </GLink>
    );
};

export default GLinkWithButton;