import { restingPlaceMasterValidators } from './restingPlace';
import { aboutMasterValidators } from './about';
import { lifeMasterValidators } from './life';
import { parentsMasterValidators } from './parents';
import { educationMasterValidators } from './education';
import { marriageMasterValidators } from './marriage';
import { workHistoryMasterValidators } from './workHistory';
import { militaryMasterValidators } from './military';
import { raceMasterValidators } from './race';
import { approvalMasterValidators } from './approval';
import {
    DeathCertificateType,
    EntitySummary,
    GatherCaseUX,
    generateDeathCertificate,
    DeathCertificateConfigUX,
    UserProfile,
} from '../../../shared/types';

export interface DeathCertificateStatus {
    validSections: number;
    invalidSections: number;
    percentComplete: number;
}

export const deathCertificateValidator = (
    deathCertificate: DeathCertificateType | null,
    dcConfig: DeathCertificateConfigUX | null,
    gatherCase: GatherCaseUX,
    helpers: EntitySummary[],
    user: UserProfile,
    ignoreApprovalForm?: boolean,
): DeathCertificateStatus => {

    const dc = deathCertificate || generateDeathCertificate();
    const results: Boolean[] = [
        restingPlaceMasterValidators.valid(dc.restingPlace, dcConfig, gatherCase, user),
        aboutMasterValidators.valid(dc.about, dcConfig, gatherCase, user),
        lifeMasterValidators.valid(dc.life, dcConfig, gatherCase, user),
        parentsMasterValidators.valid(dc.parents, dcConfig, gatherCase, user, helpers),
        educationMasterValidators.valid(dc.education, dcConfig, user),
        marriageMasterValidators.valid(dc.marriage, dcConfig, gatherCase, user, helpers),
        workHistoryMasterValidators.valid(dc.workHistory, dcConfig, user),
        militaryMasterValidators.valid(dc.military, dcConfig, gatherCase, user),
        raceMasterValidators.valid(dc.race, dcConfig, user),
    ];

    if (!ignoreApprovalForm) {
        results.push(approvalMasterValidators.valid(gatherCase, helpers));
    }

    const validSections = results.filter((isValid) => isValid).length;
    const invalidSections = results.filter((isValid) => !isValid).length;
    const percentComplete = Math.ceil(
        100 * validSections / (validSections + invalidSections)
    );
    return {
        validSections,
        invalidSections,
        percentComplete,
    };
};