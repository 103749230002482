
import { StyleRules, WithStyles } from '@mui/styles';

export const TYPOGRAPHY_STYLES =
    <Props extends object>(): StyleRules<Props, TypographyStylesClassKey> => ({
        fontSize10: {
            fontSize: 10,
        },
        fontSize11: {
            fontSize: 11,
        },
        fontSize12: {
            fontSize: 12,
        },
        fontSize13: {
            fontSize: 13,
        },
        fontSize14: {
            fontSize: 14,
        },
        fontSize15: {
            fontSize: 15,
        },
        fontSize16: {
            fontSize: '16px !important',
        },
        fontSize17: {
            fontSize: 17,
        },
        fontSize18: {
            fontSize: 18
        },
        fontSize20: {
            fontSize: 20,
        },
        fontSize22: {
            fontSize: 22,
        },
        fontSize24: {
            fontSize: 24,
        },
        fontSize28: {
            fontSize: 28,
        },
        fontSize30: {
            fontSize: 30,
        },
        fontSize32: {
            fontSize: 32,
        },
        fontSize34: {
            fontSize: 34,
        },
        fontSize35: {
            fontSize: 35,
        },
        fontSize36: {
            fontSize: 36,
        },
        fontSize38: {
            fontSize: 38,
        },
        fontSize40: {
            fontSize: 40,
        },
        fontSize48: {
            fontSize: 48,
        },
        fontSize68: {
            fontSize: 68,
        },
        fontSize74: {
            fontSize: 74,
        },
        fontSize92: {
            fontSize: 92,
        },
        fontSize140: {
            fontSize: 140,
        },


        fontWeight100: {
            fontWeight: 100,
        },
        fontWeight200: {
            fontWeight: 200,
        },
        fontWeight300: {
            fontWeight: 300,
        },
        fontWeight350: {
            fontWeight: 350,
        },
        fontWeight400: {
            fontWeight: '400 !important',
        },
        fontWeight500: {
            fontWeight: '500 !important',
        },
        fontWeight600: {
            fontWeight: 600
        },
        fontWeight700: {
            fontWeight: 700,
        },


        fontStyleItalic: {
            fontStyle: 'italic',
        },
        fontBold: {
            fontWeight: 'bold',
        },
        fontSmoothing: {
            '-webkit-font-smoothing': 'antialiased',
        },
        textCapitalize: {
            textTransform: 'capitalize',
        },
        textUppercase: {
            textTransform: 'uppercase',
        },
        textLowercase: {
            textTransform: 'lowercase',
        },
        textTransformNone: {
            textTransform: 'none',
        },
        textUnderline: {
            textDecoration: 'underline',
        },


        textClickable: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        textDecorationNone: {
            textDecoration: 'none',
        },
        fontSmallCaps: {
            fontVariant: 'small-caps'
        },
        strikeThrough: {
            textDecoration: 'line-through'
        },
        paragraphLineHeight: {
            lineHeight: 1.25
        },
        clickable: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    });

export type TypographyStylesClassKey =
    'fontSize10'
    | 'fontSize11'
    | 'fontSize12'
    | 'fontSize13'
    | 'fontSize14'
    | 'fontSize15'
    | 'fontSize16'
    | 'fontSize17'
    | 'fontSize18'
    | 'fontSize20'
    | 'fontSize22'
    | 'fontSize24'
    | 'fontSize28'
    | 'fontSize30'
    | 'fontSize32'
    | 'fontSize34'
    | 'fontSize35'
    | 'fontSize36'
    | 'fontSize38'
    | 'fontSize40'
    | 'fontSize48'
    | 'fontSize68'
    | 'fontSize74'
    | 'fontSize92'
    | 'fontSize140'

    | 'fontWeight100'
    | 'fontWeight200'
    | 'fontWeight300'
    | 'fontWeight350'
    | 'fontWeight400'
    | 'fontWeight500'
    | 'fontWeight600'
    | 'fontWeight700'

    | 'fontBold'
    | 'fontStyleItalic'
    | 'fontSmoothing'
    | 'textCapitalize'
    | 'textLowercase'
    | 'textUppercase'
    | 'textDecorationNone'
    | 'textTransformNone'
    | 'textUnderline'
    | 'textClickable'
    | 'fontSmallCaps'
    | 'strikeThrough'
    | 'paragraphLineHeight'
    | 'clickable';
export type TYPOGRAPHY_STYLED_PROPS = WithStyles<TypographyStylesClassKey>;
