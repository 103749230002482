import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { GatherCaseUX } from '../../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

interface Props {
    activeCase: GatherCaseUX;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    paraText: {
        fontSize: 18,
        fontWeight: 300,
        color: theme.palette.secondary.main
    }
});

const socialSecurityContact: string = '1-800-772-1213';
const socialSecurityContactForDeaf: string = '1-800-325-0778';
const findSocialSecurityOffice: string = 'https://secure.ssa.gov/ICON/main.jsp';
const socialSecurityWebsite: string = 'https://www.ssa.gov/planners/survivors/';

type StyledProps = Props & WithGStyles<'root' | 'paraText'>;

const DoWeQualify = (props: StyledProps) => {
    const { classes, activeCase } = props;

    return (
        <>
            <Grid item>
                <Typography className={classes.paraText} align="left">
                    {activeCase.fname}'s family members may be eligible to receive monthly benefits, including:
                </Typography>
            </Grid>
            <Grid
                item
                className={classNames(
                    classes.padding10,
                    classes.textLeft
                )}
            >
                <ul
                    className={classes.paraText}
                >
                    <li>
                        <Typography className={classes.paraText}>
                            A widow or widower age 60 or older (age 50 or older if disabled).
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.paraText}>
                            A widow or widower any age caring for the deceased’s child
                            who is under age 16 or disabled.
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.paraText}>
                            An unmarried child of the {activeCase.fname} who is:
                        </Typography>
                        <ul
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                                classes.colorSecondary,
                            )}
                        >
                            <li>
                                <Typography className={classes.paraText}>
                                    Younger than age 18 (or up to age 19 if they’re a full-time
                                    student in an elementary or secondary school).
                                </Typography>
                            </li>
                            <li>
                                <Typography className={classes.paraText}>
                                    Age 18 or older with a disability that began before age 22.
                                </Typography>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Typography className={classes.paraText}>
                            A stepchild, grandchild, stepgrandchild, or adopted child under certain circumstances.
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.paraText}>
                            Parents, age 62 or older, who were dependent on the deceased for at
                            least half of their support.
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.paraText}>
                            A surviving divorced spouse, under certain circumstances.
                        </Typography>
                    </li>
                </ul>
            </Grid>

            <Grid item>
                <Typography className={classes.paraText} align="left">
                    We recommend that you call the Social Security Administration toll-free at&nbsp;
                    <a
                        href={`tel: ${socialSecurityContact}`}
                        className={classNames(
                            classes.cursorPointer,
                            classes.colorSecondary
                        )}
                    >
                        {socialSecurityContact}
                    </a>. Staff can answer your specific questions from
                    7 a.m. to 7 p.m., Monday through Friday. You can also click the button
                    below to find the Social Security office closest to you.
                </Typography>
                <a
                    href={`tel: ${socialSecurityContact}`}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classNames(
                            classes.margin_8,

                            classes.marginTop20
                        )}
                    >
                        Call Social Security
                    </Button>
                </a>
            </Grid>
            <Grid item>
                <a
                    href={findSocialSecurityOffice}
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classNames(
                            classes.margin_8,

                            classes.marginTop20
                        )}
                    >
                        Find Social Security Office
                    </Button>
                </a>
            </Grid>
            <Grid item>
                <Typography
                    className={classNames(
                        classes.paraText,
                        classes.marginTop20
                    )}
                    align="left"
                >
                    <a
                        href={socialSecurityWebsite}
                        target="blank"
                        className={classNames(
                            classes.cursorPointer,
                            classes.colorSecondary
                        )}
                    >
                        Click here
                    </a>
                    &nbsp;to visit the Social Security website for more information about all of
                    Social Security's programs.
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    className={classNames(
                        classes.paraText,
                        classes.marginTop20,
                        classes.marginBottom20
                    )}
                    align="left"
                >
                    If you are deaf or hard of hearing, you may call the TTY number at&nbsp;
                    <a
                        href={`tel: ${socialSecurityContactForDeaf}`}
                        className={classNames(
                            classes.cursorPointer,
                            classes.colorSecondary
                        )}
                    >
                        1-800-325-0778
                    </a>
                </Typography>
            </Grid>
        </>
    );
};

export default withGStyles(styles)(DoWeQualify);
