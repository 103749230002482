import * as React from 'react';
import classNames from 'classnames';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import TasksForCase, { CaseDetails } from './TasksForCase';
import TaskListHeader from './TaskListHeader';
import NoAssignedTasks from './NoAssignedTasks';
import { TaskState, TaskPreview, UserRoles, userToEntitySummary } from '../../../../shared/types';
import { openFinalizeTaskDialog } from '../../../../actions/Dialog.action';
import { TaskForFinalizeDialog } from '../../../../types/DialogState';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import { StoreState } from '../../../../types';
import { AppDispatch } from '../../../../store';
import withGStyles from '../../../../styles/WithGStyles';
import withState from '../../../common/utilHOC/WithState';
import List from '@mui/material/List';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    overflowTasks: {
        height: '100%',
        overflow: 'auto',
    },
    listContainer: {
        width: '100%',
        position: 'relative',
        overflowY: 'auto',
        maxHeight: '100%',
        paddingBottom: 0,
        borderBottomLeftRadius: 12,
        '-webkit-overflow-scrolling': 'touch'
    },
    borderTopLeftRadius: {
        borderTopLeftRadius: 12,
        '@media (min-width:1300px)': {
            borderTopLeftRadius: 'unset'
        }
    },
});

function mapStateToProps({ tasksState, userSession, teamState }: StoreState, ownProps: OwnProps) {
    const { userData } = userSession;
    const { selectedTeamMemberUserId } = ownProps;

    const isLoading = tasksState.taskPreviewsLoading;

    const cases = tasksState.taskPreviews.reduce<CaseDetails[]>(
        (caseList, task) => {
            const thisCase = caseList.find((c) => c.id === task.gather_case_id);
            if (thisCase) {
                thisCase.tasks.push(task);
            } else {
                caseList.push({
                    id: task.gather_case_id,
                    fname: task.case_fname,
                    lname: task.case_lname,
                    photo: task.case_photo,
                    photo_transformations: task.case_photo_transformations,
                    fullName: task.case_full_name,
                    name: task.case_name,
                    funeralHomeKey: task.funeral_home_key,
                    tasks: [task],
                });
            }
            return caseList;
        },
        [],
    );

    let selectedTeamMember = selectedTeamMemberUserId && teamState.team.find((tm) =>
        tm.user_id === selectedTeamMemberUserId
    ) || null;
    if (!selectedTeamMember && UserRoles.isGOMUser(userData)
        && userData && userData.user_id === selectedTeamMemberUserId) {
        // Gather users won't show up in the FH team so select themselves
        selectedTeamMember = userToEntitySummary(userData);
    }
    return {
        cases,
        userData,
        isLoading,
        pendingCount: tasksState.taskPreviews.length,
        selectedTeamMember,
    };
}

interface OwnProps {
    selectedTeamMemberUserId: number | null;
    zIndex: number;
    // TODO(MUI v5): try to move pick TM functionality into this component
    // Couldn't do it in v3 because the CaseSwitcher would close on click due to ClickListener firing
    openPickTeamMember: (e: React.MouseEvent<HTMLElement>) => void;
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'overflowTasks' | 'listContainer'
    | 'borderTopLeftRadius'>;

class PendingTaskList extends React.Component<StyledProps> {

    handleFinalizeTask = (taskPreview: TaskPreview, state: TaskState) => {
        const { dispatch, zIndex } = this.props;

        const task: TaskForFinalizeDialog = {
            id: taskPreview.task_id,
            title: taskPreview.task_title,
            case_uuid: taskPreview.case_uuid,
            note: taskPreview.task_note,
            template_type: taskPreview.task_template,
        };
        dispatch(openFinalizeTaskDialog({
            zIndex: zIndex + 1,
            task,
            state,
        }));
    };

    renderList = () => {
        const { cases, userData, isLoading, selectedTeamMember } = this.props;

        if (!userData || !selectedTeamMember) {
            return null;
        }

        const isMe = selectedTeamMember.user_id === userData.user_id;

        if (cases.length === 0 && !isLoading) {
            return <NoAssignedTasks
                isMe={isMe}
                teamMemberFname={selectedTeamMember.fname}
            />;
        }

        return cases.map((c) => (
            <TasksForCase
                key={c.id}
                caseDetails={c}
                isMe={isMe}
                teamMember={selectedTeamMember}
                onFinalizeTask={this.handleFinalizeTask}
            />
        ));
    };

    render() {
        const { classes, userData, isLoading, pendingCount, selectedTeamMember, openPickTeamMember } = this.props;

        if (!userData || !selectedTeamMember) {
            return null;
        }

        return (
            <div className={classNames(classes.overflowTasks, classes.borderTopLeftRadius)}>
                <List
                    className={classNames(
                        classes.listContainer,
                        classes.colorPrimary
                    )}
                    subheader={<li />}
                >
                    <TaskListHeader
                        isLoading={isLoading}
                        teamMember={selectedTeamMember}
                        isMe={selectedTeamMember.user_id === userData.user_id}
                        pendingCount={pendingCount}
                        openPickTeamMember={openPickTeamMember}
                    />
                    {this.renderList()}
                </List>
            </div>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(PendingTaskList));
