import * as React from 'react';
import classNames from 'classnames';
import * as appService from '../../services/app.service';

import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

import Gavatar, { GavatarProps, GAVATAR_DEFAULT_SIZE } from './Gavatar';
import { AvatarUser } from '../../shared/types/user';
import { SKYBLUE_COLOR_2, RED_COLOR } from '../../constants/colorVariables';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { Box, SxProps } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
    },
    avatar: {
        backgroundColor: '#fff',
    },
    overlapDot: {
        background: SKYBLUE_COLOR_2,
        height: '1em',
        width: '1em',
        fontSize: 8,
        borderRadius: '50%',
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0
    },
    badge: {
        backgroundColor: theme.palette.common.white,
        right: '-28px',
        top: '-26px',
        color: RED_COLOR,
        border: '1px solid',
        height: 24,
        width: 24,
        borderRadius: 12,
        '&$isMe': {
            backgroundColor: RED_COLOR,
            color: theme.palette.common.white,
            border: 'none'
        }
    },
    smalllBadge: {
        backgroundColor: theme.palette.common.white,
        top: '-22px',
        right: '-26px',
        color: RED_COLOR,
        height: 20,
        width: 20,
        borderRadius: 12,
        border: '1px solid',
        '&$isMe': {
            backgroundColor: RED_COLOR,
            color: theme.palette.common.white,
            border: 'none'
        }
    },
    positionAbsolute: {
        position: 'absolute'
    },
    isMe: {}
}), { name: 'UserAvatar' });

export interface UserAvatarProps extends GavatarProps {
    user: AvatarUser | null;
    className?: string;
    photoClassName?: string;
    initialsClassName?: string;
    id?: string;
    isLoading?: boolean;
    overlapDotClass?: string;
    showOverlapDot?: boolean;
    spinnerSize?: number;
    isInviteeList?: boolean;
    badgeCount?: number;
    badgeTooltipText?: string;
    isMe?: boolean;
    capitalizeInitials?: boolean;
    altText?: string;
    badgeClass?: string;
    badgeContainerClass?: string;
    badgeMaxCount?: number;
    overlapDotSx?: SxProps<Theme>;
    intercomTargetProp?: string;
}

interface PropsFromContainer { }

type StyledProps = UserAvatarProps & PropsFromContainer;

const UserAvatar = React.forwardRef(
    function UserAvatar(props: StyledProps, ref: React.Ref<HTMLDivElement>) {
        const {
            user,
            className,
            photoClassName,
            initialsClassName,
            isLoading,
            size = GAVATAR_DEFAULT_SIZE,
            showOverlapDot,
            overlapDotClass,
            style,
            spinnerSize,
            isInviteeList,
            badgeCount,
            badgeTooltipText,
            isMe,
            onClick,
            capitalizeInitials,
            altText,
            badgeClass,
            badgeContainerClass,
            badgeMaxCount,
            imgProps,
            sx,
            overlapDotSx,
            intercomTargetProp,
            ...otherProps
        } = props;

        const classes = useStyles();

        const hasPhoto: boolean = Boolean(user && user.photo);
        // If there is no photo then use the users initials
        const userInitials = user ? appService.generateNameInitials(user.fname, user.lname) : undefined;
        // Component may pass in different classNames for photo and/or initials, default to className
        const chosenClassName = hasPhoto && photoClassName ? photoClassName :
            !hasPhoto && initialsClassName ? initialsClassName : className;

        const renderBadge = () => (
            !!badgeCount &&
            <Tooltip
                enterDelay={1200}
                title={badgeTooltipText || ''}
                placement="top"
            >
                <Badge
                    className={classNames(classes.positionAbsolute, badgeContainerClass)}
                    badgeContent={badgeCount}
                    max={badgeMaxCount ?? 99}
                    classes={{
                        badge: classNames(
                            isInviteeList ? classes.smalllBadge : classes.badge,
                            isMe && classes.isMe,
                            badgeClass
                        )
                    }}
                />
            </Tooltip>
        );

        if (isLoading) {
            return (
                <div
                    {...otherProps}
                    className={chosenClassName}
                    style={{
                        ...style,
                        position: 'relative',
                        border: 'none',
                        boxShadow: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'none'
                    }}
                    ref={ref}
                >
                    {showOverlapDot &&
                        <Box
                            component="span"
                            className={classNames(classes.overlapDot, overlapDotClass)}
                            sx={overlapDotSx}
                        />
                    }

                    {user && <Gavatar
                        public_id={user.photo ? user.photo : undefined}
                        transformations={user.photo ? user.photo_transformations : undefined}
                        className={chosenClassName}
                        initials={capitalizeInitials && userInitials ? userInitials.toUpperCase() : userInitials}
                        alt={appService.generateNameInitials(user.fname, user.lname)}
                        size={size}
                        style={style}
                        onClick={onClick}
                        imgProps={imgProps}
                        sx={sx}
                        intercomTargetProp={intercomTargetProp}
                        {...otherProps}
                    />}

                    {renderBadge()}
                </div>
            );
        }

        return (
            <div
                {...otherProps}
                className={chosenClassName}
                style={{
                    ...style,
                    position: 'relative',
                    border: 'none',
                    boxShadow: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'none'
                }}
                ref={ref}
            >
                {showOverlapDot &&
                    <Box
                        component="span"
                        className={classNames(classes.overlapDot, overlapDotClass)}
                        sx={overlapDotSx}
                    />
                }

                <Gavatar
                    public_id={user && user.photo ? user.photo : undefined}
                    transformations={user && user.photo ? user.photo_transformations : undefined}
                    className={chosenClassName}
                    initials={capitalizeInitials && userInitials ? userInitials.toUpperCase() : userInitials}
                    alt={altText || userInitials}
                    size={size}
                    style={style}
                    onClick={onClick}
                    imgProps={imgProps}
                    sx={sx}
                    intercomTargetProp={intercomTargetProp}
                    {...otherProps}
                />

                {renderBadge()}
            </div>
        );
    }
);

export default UserAvatar;
