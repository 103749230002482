import * as React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { WithStyles } from '@mui/styles';
import moment from 'moment-timezone';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { StoreState } from '../../../types';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ContactEntry from './ContactEntry';
import {
    EntityType,
    LongAddress,
    RolodexOrganization,
    Contact,
    RolodexEntryRequest,
    RolodexOrganizationRequiredType,
    ExistingContact,
    rolodexRecordToUpdateRecord,
    isExistingOrganization,
    contactToCreateContactObj,
    UploadFileRequest,
    DocUXDetail,
    DocCategory,
    DocPDFStatus,
    RolodexDocInsert,
    RolodexContact,
    RolodexDoc,
    isExistingContact,
    isRolodexDocInsert,
    isRolodexDoc,
    getDocFromDocList
} from '../../../shared/types';
import OrganizationEntry from './OrganizationEntry';
import {
    createRolodexEntry,
    deleteEntry,
    downloadRolodexDoc,
    setRolodexCreateEditDialogOpen,
    updateRolodexEntry
} from '../../../actions/Rolodex.action';
import { setAppSnackbar } from '../../../actions/AppSnackbar.action';
import styles from './EditCreateRolodex.dialog.styles';
import { downloadFromURL, getDataFromLocalComputer } from '../../../services/doc.service';
import { registerAppError, handleException } from '../../../actions/errors';
import { convertDocUploadToUXDetail } from '../../../actions/Doc.action';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import PopperMenu from '../listing/PopperMenu';
import DocumentRenameDialog from '../../documentLibrary/DocumentRename.dialog';
import DocPreviewDialog, { DocType } from '../../documentLibrary/DocPreview.dialog';
import GButton from '../../common/GButton';
import DocWidget from '../../common/DocWidget';
import FileUploader, { AcceptFileType } from '../../common/FileUploader';
import { AppDispatch } from '../../../store';
import { compose } from 'redux';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';
import { SlideTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';
import { uploadFileToS3 } from '../../../actions/S3File.action';
import { joinNameParts } from '../../../services';

const emptyContactObj = (): Contact => ({
    fname: '',
    mname: null,
    lname: '',
    email: null,
    phone: null,
    longAddress: null,
    timezone: null,
    fax_number: null,
    org_role: null,
    title: null,
    type: EntityType.person
});

function mapStateToProps({ userSession, casesState, funeralHomeState, rolodexState }: StoreState) {
    return {
        userSession,
        casesState,
        activeCase: casesState.selectedCase,
        funeralHomeState,
        rolodexState,
        activeFuneralHomeId: funeralHomeState.activeFuneralHome === null ? null : funeralHomeState.activeFuneralHome.id,
    };
}

type Props = ReturnType<typeof mapStateToProps> & {
    dispatch: AppDispatch;
};

interface State {
    isDeleteConfirmationDialogOpen: boolean;
    orgDisabled: boolean;
    organization: Partial<RolodexOrganization>;
    contactList: Array<ExistingContact | Contact>;
    isSaving: boolean;
    isUploading: boolean;
    targetDocHash: string | null;
    filesToUpload: RolodexDocInsert[];
    docPopperAnchorEle: HTMLElement | null;
    activeDoc: number | string | null;
    dialogueRenameDocOpen: boolean;
    dialogueDocPreviewOpen: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

type StyledProps = WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'dialogFooter'
    | 'margin8' | 'headerContent' | 'headerText' | 'mainContainer' | 'rightSection' | 'leftSection' | 'label'
    | 'width100' | 'marginTop8' | 'textLeft' | 'selectFormControl' | 'organizationFooterSection' | 'switchLabel'
    | 'widthInherit' | 'organizationSection' | 'switchContainer' | 'contactSection' | 'contactDetailSection'
    | 'width55' | 'width40' | 'contactFooterButton' | 'addNewContract' | 'docSection' | 'docHeading' | 'docText'
    | 'docListContainer' | 'displayTable' | 'newPhotoOuter' | 'addNewPhotoInner' | 'addPhotoIcon' | 'grid'
    | 'squareButtonText' | 'addNewPhotoContainer' | 'marginLeftRight8' | 'formControlStyles'
    | 'controlSuggestionClass' | 'gmapSearch' | 'width45' | 'docLabelContainer' | 'docName' | 'docUploadedBy'
    | 'docUploadedTime' | 'fileMoreIcon' | 'docSectionContainer' | 'uploadButtonContainer' | 'docContainer'
    | 'menuPopper' | 'menuPaper' | 'arrow' | 'menuItem' | 'nameLabel' | 'nameLabelFocused' | 'select'>;

type CombinedProps = Props & DialogProps & StyledProps;

const defaultFormState = (funeralHomeId?: number): State => ({
    isDeleteConfirmationDialogOpen: false,
    isUploading: false,
    isSaving: false,
    orgDisabled: true,
    targetDocHash: null,
    organization: {
        name: '',
        type: null,
        other_text: null,
        longAddress: null,
        timezone: null,
        email: null,
        phone: null,
        fax_number: null,
        notes: null,
        funeral_home_id: funeralHomeId,
        docs: [],
        useAddressDescription: false
    },
    contactList: [],
    filesToUpload: [],
    docPopperAnchorEle: null,
    activeDoc: null,
    dialogueRenameDocOpen: false,
    dialogueDocPreviewOpen: false,
});

class EditCreateRolodexDialog extends React.Component<CombinedProps, State> {
    protected _firstContactEntryRef: React.RefObject<ContactEntry> = React.createRef<ContactEntry>();
    protected _organzationEntryRef: React.RefObject<OrganizationEntry> = React.createRef<OrganizationEntry>();

    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            ...defaultFormState()
        };
    }

    componentDidUpdate(prevProps: CombinedProps, prevState: State) {
        const { rolodexState, activeFuneralHomeId } = this.props;
        const { activeEntry } = rolodexState;
        const { organization } = this.state;

        if (activeFuneralHomeId !== null && (organization && !organization.funeral_home_id)) {
            this.setState({
                organization: { ...organization, funeral_home_id: activeFuneralHomeId }
            });
        }

        if (activeEntry !== null && !rolodexState.createEditDialogOpen) {
            if (!isEqual(prevState.organization, activeEntry.organization)) {
                let orgDisabled: boolean = false;

                const { organization: activeOrg, contacts: activeContacts } = activeEntry;
                if (activeOrg.name !== null && activeOrg.name.length === 0) {
                    orgDisabled = true;
                }

                if (!activeContacts.length || activeContacts.some(isExistingContact)) {
                    this.setState({
                        orgDisabled,
                        organization: activeOrg,
                        contactList: activeContacts
                    });
                }
            }
        } else if (activeEntry === null && prevProps.rolodexState.activeEntry !== null) {
            this.setState({ ...defaultFormState(activeFuneralHomeId || undefined) });
        }
    }

    closeCreateEditDialog = () => {
        const { dispatch, activeFuneralHomeId } = this.props;

        this._resetInputFields();

        this.setState({ ...defaultFormState(activeFuneralHomeId || undefined) });

        dispatch(setRolodexCreateEditDialogOpen(false));
    };

    updateOrgNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;

        this.setState(prevState => {
            const updatedOrg = { ...prevState.organization, notes: value };
            return {
                organization: updatedOrg
            };
        });
    };

    addNewContactEntry = () => {
        this.setState(prevState => ({
            contactList: [...prevState.contactList, emptyContactObj()]
        }));
    };

    renderContent = () => {
        const { classes, rolodexState, userSession } = this.props;
        const { zIndex } = rolodexState;
        const {
            orgDisabled,
            contactList,
            organization,
            filesToUpload
        } = this.state;

        return (
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12} className={classes.leftSection}>
                    <OrganizationEntry
                        ref={this._organzationEntryRef}
                        classes={classes}
                        zIndex={zIndex}
                        orgData={organization}
                        orgDisabled={orgDisabled}
                        orgEnableDisableCallback={(isChecked) => this.setState({ orgDisabled: !isChecked })}
                        fieldChangeEventHandler={this._handleOrgDataChangeEvent}
                    />
                    {/* Organization Section ENDS */}

                    {orgDisabled && !contactList.length
                        ? <ContactEntry
                            key={0}
                            ref={this._firstContactEntryRef}
                            entryKey={0}
                            classes={classes}
                            zIndex={zIndex}
                            deleteHandler={this._deleteContactHandler}
                            fieldChangeEventHandler={this._handleContactChangeEvent}
                        />
                        : contactList.map((contact, index) => (
                            <ContactEntry
                                key={index}
                                ref={index === 0 ? this._firstContactEntryRef : undefined}
                                entryKey={index}
                                classes={classes}
                                zIndex={zIndex}
                                deleteHandler={this._deleteContactHandler}
                                id={contact.id}
                                title={contact.title}
                                fname={contact.fname}
                                mname={contact.mname}
                                lname={contact.lname}
                                email={contact.email}
                                phone={contact.phone}
                                fax={contact.fax_number}
                                org_role={contact.org_role}
                                longAddress={contact.longAddress}
                                useDescription={contact.useAddressDescription}
                                fieldChangeEventHandler={this._handleContactChangeEvent}
                            />
                        ))
                    }

                    {/* Contact Section ENDS */}

                    {!orgDisabled &&
                        <Grid item xs={12} className={classes.addNewContract}>
                            <Button color="primary" onClick={this.addNewContactEntry} disabled={orgDisabled}>
                                <AddIcon />&nbsp;
                                Add New Contact{
                                    (organization && organization.name && organization.name.length)
                                        ? ` Under ${organization.name}`
                                        : ''
                                }
                            </Button>
                        </Grid>
                    }

                </Grid>
                {/* left Section ENDS */}

                <Grid item xs={12} className={classes.rightSection}>
                    <Grid item className={classNames(classes.width100)}>
                        <FormControl
                            className={classNames(classes.width100, classes.textLeft)}
                        >
                            <TextField
                                label="Notes"
                                name="notes"
                                value={organization && organization.notes || ''}
                                className={classes.width100}
                                onChange={this.updateOrgNotes}
                                autoComplete="notes"
                                multiline
                                color="secondary"
                                rows={9}
                                variant="outlined"
                                placeholder="Type internal notes here..."
                                InputProps={{ classes: { notchedOutline: 'notranslate' } }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.docSection}>
                        <Typography color="secondary" className={classes.docHeading}>
                            Documents ({(organization.docs && organization.docs.length) || 0 + filesToUpload.length})
                        </Typography>
                        <Divider color="secondary" />
                        <Typography color="secondary" className={classes.docText}>
                            Upload any documents that you'd like to have quick access to
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.docContainer}>
                        <DocWidget
                            docs={(organization.docs || []).map(doc => ({
                                id: doc.id,
                                name: doc.name,
                                uploadedBy: joinNameParts({
                                    fname: doc.uploaded_by_fname,
                                    lname: doc.uploaded_by_lname
                                }),
                                uploadedTime: moment(doc.uploaded_time).format(),
                            }))}
                            menuHandler={this._openDocMenuPopper}
                            docItemClass={classes.docListContainer}
                        />

                        <DocWidget
                            docs={filesToUpload.map(doc => ({
                                id: doc.name,
                                name: doc.name,
                                uploadedBy: userSession.userData
                                    && `${userSession.userData.fname} ${userSession.userData.lname}`
                                    || '',
                                uploadedTime: moment(doc.uploaded_time).format(),
                            }))}
                            menuHandler={this._openDocMenuPopper}
                            docItemClass={classes.docListContainer}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.uploadButtonContainer}>
                        <FileUploader
                            acceptTypes={[
                                AcceptFileType.JPEG,
                                AcceptFileType.GIF,
                                AcceptFileType.PNG,
                                AcceptFileType.DOCUMENT,
                                AcceptFileType.MSWORD,
                                AcceptFileType.PDF
                            ]}
                            handleSave={this._onFileUpload}
                        >
                            {(inputRef) =>
                                <Button
                                    color="primary"
                                    className={classNames(classes.grid, classes.addNewPhotoContainer)}
                                    onClick={e => inputRef && inputRef.click()}
                                >
                                    <CloudUploadIcon color="primary" className={classes.addPhotoIcon} />
                                    <Typography
                                        color="primary"
                                        paragraph
                                        className={classes.squareButtonText}
                                    >
                                        Upload New
                                    </Typography>
                                </Button>
                            }
                        </FileUploader>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    render() {
        const { classes, fullScreen, rolodexState } = this.props;
        const { createEditDialogOpen, zIndex } = rolodexState;
        const { isSaving } = this.state;

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={createEditDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={this.closeCreateEditDialog}
                    className={classes.root}
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                    style={{ zIndex: zIndex + 2 }}
                >
                    {createEditDialogOpen && <>
                        <DialogTitle
                            className={classes.dialogHeader}
                        >
                            <Grid item xs={12} className={classes.headerContent}>
                                <ContactsIcon />&nbsp;
                                <Typography className={classes.headerText}>
                                    {rolodexState.activeEntry !== null ? 'Edit ' : 'Create '}Rolodex Entry
                                </Typography>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            {this.renderContent()}
                        </DialogContent>

                        <Grid item className={classes.dialogFooter}>
                            <Button
                                color="primary"
                                className={classes.margin8}
                                onClick={this.closeCreateEditDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className={classes.margin8}
                                onClick={() => this.setState({
                                    isDeleteConfirmationDialogOpen: true,
                                })}
                            >
                                Delete Rolodex Entry
                            </Button>
                            <GButton
                                variant="outlined"
                                className={classNames(
                                    classes.margin8,
                                )}
                                disabled={!this._formValid()}
                                isSpinning={isSaving}
                                onClick={this._submitRolodexEntry}
                                text="Save Changes"
                            />
                        </Grid>
                    </>}
                    {this._deleteEntryConfirmDialogue()}
                    {this._renderDocMenuPopper()}
                    {this._renderDocumentRenameDialog()}
                    {this._renderDocPreviewDialog()}
                </Dialog>
            </>
        );
    }

    private _formValid = (): boolean => {
        const { contactList, orgDisabled, organization } = this.state;

        const orgName = organization && organization.name;
        if (!orgDisabled && (!orgName || !orgName.length)) {
            return false;
        }

        if (!contactList.length) {
            return false;
        }

        const invalidFirstNames = contactList.some(contact => !contact.fname?.trim().length);
        if (invalidFirstNames) {
            return false;
        }

        const invalidLastNames = contactList.some(contact => !contact.lname?.trim().length);
        if (invalidLastNames) {
            return false;
        }

        if (organization.type && organization.type === 'other'
            && (!organization.other_text || !organization.other_text.length)) {
            return false;
        }
        return true;
    };

    private _handleOrgDataChangeEvent = (
        fieldName: string,
        value: string | LongAddress | boolean | null
    ) => {
        const newData = { [fieldName]: value };

        this.setState(prevState => ({
            organization: { ...prevState.organization, ...newData }
        }));
    };

    private _handleContactChangeEvent = (
        entryKey: number,
        fieldName: keyof RolodexContact,
        value: string | LongAddress | boolean | null
    ) => {
        this.setState(prevState => {
            const updateObj = {};

            const { contactList } = prevState;
            const contact = contactList[entryKey];

            updateObj[fieldName] = value;

            const updatedContact = { ...contact, ...updateObj };
            contactList[entryKey] = updatedContact;

            return { contactList };
        });
    };

    private _deleteContactHandler = (entryKey: number) => {
        this.setState(prevState => {
            const currentContacts = prevState.contactList;
            const newContactList = currentContacts.filter((_, index) => index !== entryKey);
            return { contactList: newContactList };
        });
    };

    private _submitRolodexEntry = async () => {
        const {
            dispatch,
            rolodexState,
            activeFuneralHomeId,
            userSession
        } = this.props;
        const { organization, contactList, filesToUpload } = this.state;
        const userId = userSession.userData && userSession.userData.id;

        this.setState({ isSaving: true });
        try {
            if (rolodexState.activeEntry !== null && isExistingOrganization(organization) && userId !== null) {
                if (activeFuneralHomeId !== null) {
                    const updateObj = rolodexRecordToUpdateRecord(
                        organization,
                        contactList,
                        filesToUpload,
                        activeFuneralHomeId
                    );
                    await dispatch(updateRolodexEntry(updateObj));
                }
            } else {
                const updateObj = this._rolodexRecordToCreateRecord(organization, contactList, filesToUpload);
                if (updateObj !== null) {
                    const newEntry = await dispatch(createRolodexEntry(updateObj));

                    if (newEntry === null) {
                        dispatch(registerAppError('Failed to create Rolodex Entry.'));
                    } else {
                        dispatch(setAppSnackbar(
                            'Rolodex Entry created successfully!',
                            'success'
                        ));
                    }
                }
            }
            this.closeCreateEditDialog();
        } catch (ex) {
            dispatch(handleException({ ex, showSnackbar: true }));
        } finally {
            this.setState({ isSaving: false });
        }
    };

    private _rolodexRecordToCreateRecord = (
        organization: Partial<RolodexOrganization>,
        contactList: Contact[],
        docList: RolodexDocInsert[]
    ): RolodexEntryRequest | null => {
        const { activeFuneralHomeId } = this.props;

        if (activeFuneralHomeId !== null) {
            const processedContacts = contactList.map(contactToCreateContactObj);

            let orgObj: RolodexOrganizationRequiredType | null = {
                name: organization.name || '',
                type: organization.type || null,
                other_text: organization.other_text || null,
                longAddress: organization.longAddress || null,
                timezone: null,
                email: organization.email || null,
                phone: organization.phone || null,
                fax_number: organization.fax_number || null,
                notes: organization.notes || null,
                funeral_home_id: activeFuneralHomeId,
                docs: docList,
                useAddressDescription: organization.useAddressDescription || false
            };

            return {
                organization: orgObj,
                contacts: processedContacts,
                funeralHomeId: activeFuneralHomeId
            };
        }

        return null;
    };

    private _resetInputFields = () => {
        if (this._organzationEntryRef.current) {
            this._organzationEntryRef.current.resetInputFields();
        }

        if (this._firstContactEntryRef.current) {
            this._firstContactEntryRef.current.resetInputFields(false);
        }

        this.setState({ organization: { notes: '' } });
    };

    // TODO(Jonathan): Make this generic code so we an reuse rather than copy paste.
    private _onFileUpload = async (files: FileList | null) => {
        const { dispatch } = this.props;

        if (files && files.length !== 0) {
            this.setState({ isUploading: true });
            const results: UploadFileRequest[] = [];
            for (let i = 0; i < files.length; i++) {
                const result = await getDataFromLocalComputer(files[i]);
                if (!result) {
                    continue;
                }
                results.push(result);
                if (result && result.hash) {
                    this.setState({ targetDocHash: result.hash });
                }
            }

            this.setState({ isUploading: false });
            if (results.length !== files.length) {
                dispatch(registerAppError('Document failed to upload. Please verify size is less than 50MB'));
            }
            const processedDocs = await this._handleUploadDocs(results);
            if (processedDocs !== null) {
                this.setState(prevState => ({ filesToUpload: [...prevState.filesToUpload, ...processedDocs] }));
            }
        }
    };

    private _handleUploadDocs = async (newDocs: UploadFileRequest[]): Promise<RolodexDocInsert[] | null> => {
        const { dispatch, userSession } = this.props;
        const { organization } = this.state;
        if (newDocs && newDocs.length > 0) {
            const placeHolderRecords: DocUXDetail[] = newDocs.map(convertDocUploadToUXDetail);
            const processedDocs: RolodexDocInsert[] = [];
            for (let i = 0; i < newDocs.length; i++) {
                const targetDocData = placeHolderRecords.find(t => t.label === newDocs[i].name);
                if (targetDocData && userSession.userData) {
                    const funeralHomeId = organization.funeral_home_id;
                    const { hash, suffix } = newDocs[i];

                    if (suffix) {
                        const s3DocUploadRequest = await uploadFileToS3(
                            newDocs[i],
                            `funeralhome/${funeralHomeId ?? ''}/rolodex/uploadurl/${hash}/${suffix}`,
                            dispatch
                        );
    
                        if (s3DocUploadRequest !== null) {
                            processedDocs.push({
                                uploaded_by: userSession.userData.id,
                                uploaded_time: (new Date()).toISOString(),
                                parent_doc_id: null,
                                name: s3DocUploadRequest.name,
                                label: null,
                                hash: s3DocUploadRequest.hash,
                                size: s3DocUploadRequest.size,
                                is_private: true,
                                path: s3DocUploadRequest.path,
                                suffix: s3DocUploadRequest.suffix || '',
                                mimetype: s3DocUploadRequest.type,
                                icon: null,
                                doc_category: DocCategory.general,
                                pdf_status: DocPDFStatus.not_needed
                            });
                        }
                    }
                }
            }
            return processedDocs;
        }

        return null;
    };

    private _deleteEntryConfirmDialogue = () => {
        const { rolodexState } = this.props;
        const { isDeleteConfirmationDialogOpen } = this.state;
        const { activeEntry, zIndex } = rolodexState;

        if (activeEntry !== null) {
            const { organization } = activeEntry;

            const deleteDialogHeader = `Are you sure you want to delete ${organization.name || ''}?`;
            const deleteDialogSubHeader = 'This action cannot be undone.';
            const deleteDialogConfirmationButton = `Delete ${organization.name || ''}`;
            const cancelButtonText = 'Nevermind';

            return (
                <ConfirmationDialog
                    header={deleteDialogHeader}
                    subHeader={deleteDialogSubHeader}
                    confirmationButtonText={deleteDialogConfirmationButton}
                    cancelButtonText={cancelButtonText}
                    onClose={() => this.setState({ isDeleteConfirmationDialogOpen: false })}
                    open={isDeleteConfirmationDialogOpen}
                    onConfirm={this._handleDeleteEntry}
                    zIndex={zIndex + 2}
                />
            );
        }

        return <></>;
    };

    private _handleDeleteEntry = async () => {
        const { rolodexState, activeFuneralHomeId, dispatch } = this.props;

        if (activeFuneralHomeId && rolodexState.activeEntry) {
            await dispatch(deleteEntry(rolodexState.activeEntry));

            dispatch(setRolodexCreateEditDialogOpen(false));
        }
    };

    private _openDocMenuPopper = (docName: number | string, e: React.MouseEvent<HTMLElement>) => {
        this.setState({ docPopperAnchorEle: e.currentTarget, activeDoc: docName });
    };

    private _closeDocMenuPopper = (closeDoc: boolean = true) => {
        const { dialogueDocPreviewOpen } = this.state;

        if (dialogueDocPreviewOpen) {
            return;
        }

        const stateChange = {
            docPopperAnchorEle: null
        };

        if (closeDoc) {
            const activeDocKey = 'activeDoc';
            stateChange[activeDocKey] = null;
        }

        this.setState(stateChange);
    };

    private _handleFileDownload = async () => {
        const { activeDoc, organization } = this.state;
        const { dispatch, activeFuneralHomeId } = this.props;

        this._closeDocMenuPopper();

        if ((activeDoc !== null && typeof activeDoc === 'number') && activeFuneralHomeId !== null && organization.id) {
            const downloadData = await dispatch(downloadRolodexDoc(activeDoc, organization.id, activeFuneralHomeId));

            if (downloadData === null) {
                return;
            }

            downloadFromURL(downloadData.presignedurl, downloadData.downloadfilename);
        }
    };

    private _handleFileDelete = () => {
        const { activeDoc, organization, filesToUpload } = this.state;

        this._closeDocMenuPopper();

        if (activeDoc !== null && organization.docs) {
            const foundDoc = getDocFromDocList(activeDoc, organization.docs, filesToUpload);

            if (foundDoc !== null) {
                const [doc, docList] = foundDoc;
                const index = docList.indexOf(doc);
                docList.splice(index, 1);

                if (typeof activeDoc === 'number') {
                    const existingDocList = docList.filter((d): d is RolodexDoc => d.hasOwnProperty('id'));
                    this.setState(prevState => ({
                        organization: { ...prevState.organization, docs: existingDocList }
                    }));
                } else if (typeof activeDoc === 'string') {
                    this.setState({ filesToUpload: docList });
                }
            }
        }
    };

    private _openRenameDialog = () => {
        this._closeDocMenuPopper(false);
        this.setState({ dialogueRenameDocOpen: true });
    };

    private _closeRenameDialog = () => {
        this.setState({ dialogueRenameDocOpen: false });
    };

    private _handleFileRename = (doc: RolodexDoc | RolodexDocInsert, newName: string) => {
        if (isRolodexDocInsert(doc)) {
            this.setState(prevState => {
                const { filesToUpload } = prevState;
                const docToRenameIndex = filesToUpload.indexOf(doc);

                if (docToRenameIndex !== -1) {
                    filesToUpload[docToRenameIndex].name = newName;
                    filesToUpload[docToRenameIndex].label = newName;
                }

                return { filesToUpload };
            });
        } else if (isRolodexDoc(doc)) {
            this.setState(prevState => {
                const { organization } = prevState;
                if (organization.docs) {
                    const docList = [...organization.docs];
                    const docToRenameIndex = docList.indexOf(doc);

                    if (docToRenameIndex !== -1) {
                        docList[docToRenameIndex].name = newName;
                        docList[docToRenameIndex].label = newName;
                    }

                    const updatedOrg = { ...organization, docs: docList };
                    return { organization: updatedOrg };
                }

                return { organization };
            });
        }
    };

    private _renderDocumentRenameDialog = () => {
        const { rolodexState } = this.props;
        const { zIndex } = rolodexState;
        const { activeDoc, organization, filesToUpload, dialogueRenameDocOpen } = this.state;

        if (activeDoc !== null && organization.docs) {
            const foundDoc = getDocFromDocList(activeDoc, organization.docs, filesToUpload);

            if (foundDoc !== null) {
                const [doc] = foundDoc;
                return (
                    <DocumentRenameDialog
                        key={doc.name}
                        isDialogOpen={dialogueRenameDocOpen}
                        renameDocHandler={(newName) => this._handleFileRename(doc, newName)}
                        existingName={doc.name}
                        closeDialog={this._closeRenameDialog}
                        zIndex={zIndex + 5}
                    />
                );
            }
        }

        return <></>;
    };

    private _openDocPreviewDialog = () => {
        this.setState({ dialogueDocPreviewOpen: true });
    };

    private _closeDocPreviewDialog = () => {
        this.setState({ dialogueDocPreviewOpen: false });
    };

    private _renderDocPreviewDialog = () => {
        const { dialogueDocPreviewOpen, activeDoc, organization, filesToUpload } = this.state;
        const { rolodexState, activeFuneralHomeId } = this.props;
        const { zIndex } = rolodexState;

        const foundDoc = getDocFromDocList(activeDoc || '', organization.docs || [], filesToUpload);
        const doc = foundDoc && foundDoc[0];

        if (!activeDoc || typeof activeDoc !== 'number' || !activeFuneralHomeId) {
            return null;
        }

        return (
            <DocPreviewDialog
                isDialogOpen={dialogueDocPreviewOpen}
                closeDialog={this._closeDocPreviewDialog}
                zIndex={zIndex + 3}
                docId={activeDoc}
                docType={DocType.Rolodex}
                activeFuneralHomeId={activeFuneralHomeId}
                organization={organization || undefined}
                updateRolodexDocName={async (newName) => {
                    if (doc) {
                        this._handleFileRename(doc, newName);
                    }
                }}
            />
        );
    };

    private _renderDocMenuPopper = () => {
        const { rolodexState } = this.props;
        const { zIndex } = rolodexState;
        const { docPopperAnchorEle, activeDoc } = this.state;

        const menuItems = [];

        if (activeDoc !== null && typeof activeDoc === 'number') {
            menuItems.push(
                {
                    text: 'View File',
                    clickHandler: async () => this._openDocPreviewDialog()
                },
                {
                    text: 'Download File',
                    clickHandler: this._handleFileDownload
                }
            );
        }

        menuItems.push(
            {
                text: 'Rename File',
                clickHandler: async () => this._openRenameDialog()
            },
            {
                text: 'Delete File',
                clickHandler: async () => this._handleFileDelete()
            }
        );

        return (
            <PopperMenu
                zIndex={zIndex}
                closePopperHandler={() => this._closeDocMenuPopper()}
                anchorEl={docPopperAnchorEle}
                arrowEnabled={false}
                menuItems={menuItems}
            />
        );
    };
}

export default compose(
    withState(mapStateToProps),
    withGStyles(styles<CombinedProps>()),
    withFullScreen('md')
)(EditCreateRolodexDialog) as React.ComponentType<{}>;
