import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import makeGStyles from '../../../styles/makeGStyles';
import { useState } from 'react';
import useDebounce from '../hooks/useDebounce';
import classNames from 'classnames';
import { getIntercomTargetProp } from '../../../services';

const useStyles = makeGStyles((theme) => ({
    root: {},
    textFieldContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
        '& $svgContainer': {
            maxWidth: 'fit-content',
            '& svg': {
                verticalAlign: 'bottom',
            },
        },
        '& $searchField': {
            width: '100%',
            minWidth: 240,
        },
    },
    svgContainer: {},
    searchField: {},
}), { name: 'SearchInput' });

interface Props {
    searchHandler: (value: string) => void;
    inputLabel: string;
    placeholder?: string;
    debounceTime?: number;
    containerClass?: string;
    textFieldClass?: string;
    intercomTargetProp?: string;
    autoFocus?: boolean;
    hideIcon?: boolean;
    submitHandler?: (event: React.FormEvent<HTMLFormElement>) => void;
    searchTextChange?: (newSearchText: string) => void;
}


const SearchInput = (props: Props) => {
    const {
        searchHandler,
        inputLabel,
        placeholder,
        debounceTime = 500,
        containerClass,
        textFieldClass,
        intercomTargetProp,
        submitHandler,
        searchTextChange,
        hideIcon,
        autoFocus
    } = props;

    const classes = useStyles();

    const [searchInput, setSearchInput] = useState<string>('');

    const debounceSearchHandler = useDebounce(
        searchHandler,
        debounceTime,
        { cancel: true },
    );

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const newSearchValue = e.currentTarget.value;

        setSearchInput(newSearchValue);
        searchTextChange?.(newSearchValue);
        debounceSearchHandler(newSearchValue);
    };

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        submitHandler?.(e);
    };

    return (
        <Grid item className={containerClass} {...getIntercomTargetProp(intercomTargetProp)}>
            <form
                noValidate
                autoComplete="off"
                onSubmit={onFormSubmit}
            >
                <Grid item xs={12} className={classes.textFieldContainer}>
                    {!hideIcon &&
                        <Grid item xs={12} className={classes.svgContainer}>
                            <SearchIcon color="primary" />
                        </Grid>
                    }

                    <TextField
                        id="input-with-icon-grid"
                        label={inputLabel}
                        className={classNames(classes.searchField, textFieldClass)}
                        fullWidth
                        value={searchInput}
                        placeholder={placeholder}
                        name="searchText"
                        onChange={onSearchChange}
                        autoFocus={autoFocus}
                    />
                </Grid>
            </form>
        </Grid>
    );
};

export default SearchInput;
