import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import CommonSocialSecurity from './CommonSocialSecurity';

const SocialSecurityDialog = (props: TaskDialogComponentProps) => {
    const { activeCase } = props;

    const description = (
        <>
            We know that navigating the complex rules around Social Security benefits can be a
            challenge. We want to help you know exactly what you need to do to help ensure you
            receive the benefits to which you are entitled. We've provided easy to follow
            questionnaires that will help you know exactly what to do. Click below to learn more
            about the details and <FhOrAssigneePhoneNumberLink activeCase={activeCase} /> to call us
            if you have any questions. You'll need to have the Death Certificate complete prior to
            reaching out to the Social Security office.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: 'Do we qualify for Social Security benefits?',
        icon: <AccountBalanceIcon />,
        content: (
            <CommonSocialSecurity
                label={'Do we qualify for Social Security benefits?'}
                Icon={AccountBalanceIcon}
                activeCase={activeCase}
                componentId={1}
            />
        ),
    }, {
        id: 2,
        icon: <AttachMoneyIcon />,
        label: 'Can we receive the $255 Lump Sum Death Benefit?',
        content: (
            <CommonSocialSecurity
                label={'Can we receive the $255 Lump Sum Death Benefit?'}
                Icon={AttachMoneyIcon}
                activeCase={activeCase}
                componentId={2}
            />
        )
    }];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: 'Contact Social Security',
                description,
                listItems
            }}
        />
    );
};

export default SocialSecurityDialog;
