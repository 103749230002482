import * as React from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';

import { AvatarUser, MemoryPrompt, UserProfile } from '../../../../../shared/types';
import UserAvatar from '../../../../common/UserAvatar';
import MemoryContributionThanksScreen from './MemoryContribution.ThanksScreen';
import { ShareOptionType } from '../../widgets/ShareLinkSection';
import { GRAY_COLOR_3 } from '../../../../../constants/colorVariables';
import { PostMemoryParams } from '../Memories';
import { SelfLoginSrcAction } from '../../selfLogin/SelfLoginDialog';
import CandlePrompt from './CandlePrompt';
import PhotoPrompt from './PhotoPrompt';
import TextPrompt from './TextPrompt';
import { AppRoute, getIntercomTargetProp } from "../../../../../services";

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        minWidth: 300,
        maxWidth: 300,
        '@media (min-width: 768px)': {
            minWidth: 360,
            maxWidth: 360
        }
    },
    avatar: {
        width: 40,
        height: 40,
        margin: '0 auto',
        fontSize: 18
    },
    topAvatar: {
        position: 'absolute !important' as 'absolute',
        zIndex: 2,
        left: '50%',
        top: 0,
        transform: 'translate(-50%, -25%)',
    },
    shareButton: {
        borderRadius: 50,
        fontSize: 14,
        width: 'fit-content',
        minHeight: 'unset',
        margin: '0 auto',
        transform: 'translateY(-30%)',
        lineHeight: 1,
        '& svg': {
            fontSize: 16
        }
    },
    arrow: {
        width: 0,
        height: 0,
        left: '50%',
        top: '100%',
        transform: 'translateX(-50%)',
        position: 'absolute',
        border: '12px solid transparent',
        overflow: 'hidden',
        borderTop: '12px solid #fff',
        zIndex: 2,
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        '&$primaryArrow': {
            borderTop: `12px solid ${theme.palette.primary.main}`,
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        position: 'relative',
        zIndex: 1,
        marginBottom: 2,
        boxShadow: 'none',
        filter: 'drop-shadow(0 0 6px rgba(0, 0, 0, 0.25))',
        WebkitFilter: 'drop-shadow(0 0 6px rgba(0, 0, 0, 0.25))',
        height: 290,
        maxHeight: 290,
        '&$atBottom': {
            height: 260,
            maxHeight: 260,
        }
    },
    header: {
        borderRadius: '16px 16px 0 0',
        padding: '0 6px',
        '&$atBottom': {
            padding: '8px 6px',
            background: theme.palette.primary.main,
            '& $question': {
                color: theme.palette.common.white,
                padding: 0,
                fontWeight: 300,
            }
        }
    },
    question: {
        lineHeight: 1.25,
        paddingTop: 24,
        fontSize: 18
    },
    formControl: {
        height: 'calc(100% - 30px)',
    },
    textField: {
        margin: '8px 12px 0',
    },
    textFieldLabel: {
        fontSize: 14,
        fontWeight: 500,
        '&[data-shrink=false]': {
            transform: 'translate(14px, 14px) scale(1) !important'
        }
    },
    multiline: {
        height: '100%',
        padding: '12px 14px',
        '& div, textarea': {
            maxHeight: '100% !important',
            height: '100% !important',
            overflowY: 'auto !important'
        }
    },
    textFieldContainer: {
        flex: 1,
        flexWrap: 'nowrap'
    },
    height100: {
        height: '100%'
    },
    fadeContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        '&$active': {
            pointerEvents: 'auto'
        }
    },
    paddingTop12: {
        paddingTop: 12
    },
    disabledButton: {
        background: `${GRAY_COLOR_3} !important`,
        color: 'rgba(0, 0, 0, 0.2) !important'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
    },
    questionText: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flex: 1,
        fontSize: 20,
        lineHeight: 1.125,
        margin: '32px 32px 0'
    },
    button: {
        fontWeight: 400,
        minHeight: 40
    },
    candleHeader: {
        textTransform: 'uppercase',
        color: 'white',
        fontSize: '24px',
        fontWeight: 300
    },
    candleContainer: {
        height: '100%',
        with: '100%',
        textAlign: 'center',
        paddingTop: '35px'
    },
    pointer: {
        cursor: 'pointer'
    },
    primaryArrow: {},
    atBottom: {},
    active: {}
});

type StyledProps = WithStyles<'root' | 'topAvatar' | 'shareButton' | 'arrow' | 'avatar'
    | 'paper' | 'header' | 'question' | 'atBottom' | 'textField' | 'textFieldLabel' | 'buttonContainer'
    | 'multiline' | 'primaryArrow' | 'textFieldContainer' | 'height100' | 'formControl' | 'paddingTop12'
    | 'fadeContainer' | 'active' | 'disabledButton' | 'questionText' | 'button' | 'candleHeader' | 'candleContainer'
    | 'pointer'
>;

interface Props {
    question: string | JSX.Element;
    prompt: MemoryPrompt | 'flowers' | 'photo';
    userData: UserProfile | null;
    avatarPosition: 'top' | 'bottom';
    type: 'textfield' | 'button' | 'candle';
    caseDisplayFname: string;
    isUserPhotoLoading: boolean;
    candleData?: {
        styles: string;
        isLit: boolean;
    };
    textfieldLabel?: string;
    answer?: string | null;
    buttonText?: string;
    buttonIcon?: JSX.Element;
    linkProps: AppRoute;
    thanksScreenMessage?: string;
    index?: number;
    className?: string;
    paperClass?: string;
    intercomTargetProp?: string;
    gaClassName?: string;
    onSave?: (params: PostMemoryParams) => Promise<void>;
    closeSocialIconPopper?: () => void;
    openSocialIconPopper?: (
        event: React.MouseEvent<HTMLElement>,
        option: ShareOptionType,
        timeout?: number
    ) => void;
    onClick?: () => void;
    onAnswerChange?: (prompt: MemoryPrompt, answer: string) => void;
    openShareOptionsDialog: () => void;
}

interface State {
    isSaved: boolean;
    isSaving: boolean;
}

export const createSaveMemoryPromise = (
    memoryPrompt: MemoryPrompt,
    srcAction: SelfLoginSrcAction,
    memoryText: string | null,
    files: FileList | null,
    onSave: (params: PostMemoryParams) => Promise<void>,
) => {
    return new Promise<{ isSaving: boolean; hasError?: boolean }>((resolve, reject) => {
        onSave({
            memoryPrompt,
            srcAction,
            memoryText,
            files,
            callback: (isSaving: boolean, hasError?: boolean) => {
                if (!isSaving && !hasError) {
                    resolve({ isSaving, hasError });
                }

                if (hasError) {
                    reject({ isSaving, hasError });
                }
            }
        });
    });
};

class MemoryContribution extends React.PureComponent<Props & StyledProps, State> {
    constructor(props: Props & StyledProps) {
        super(props);

        this.state = {
            isSaved: !!this.props.answer,
            isSaving: false
        };
    }

    componentDidUpdate(prevProps: Props & StyledProps) {
        const { answer } = this.props;

        if (answer !== prevProps.answer) {
            this.setState({
                isSaved: !!answer
            });
        }
    }

    renderContent = () => {
        const {
            textfieldLabel,
            classes,
            type,
            question,
            buttonText,
            buttonIcon,
            linkProps,
            candleData,
            prompt,
            index,
            onClick,
            onAnswerChange,
        } = this.props;
        const { isSaved, isSaving } = this.state;

        const isAvatarAtTop = this.isAvatarAtTop();

        if (type === 'button') {
            return <PhotoPrompt
                isSaved={isSaved}
                question={question}
                isSaving={isSaving}
                buttonText={buttonText}
                linkProps={linkProps}
                buttonIcon={buttonIcon}
                savePhotos={this.savePhotos}
                onClick={onClick}
            />;
        }

        if (type === 'candle' && candleData) {
            return <CandlePrompt
                isSaved={isSaved}
                isLit={candleData.isLit}
                candleOnClick={onClick}
            />;
        }

        return <TextPrompt
            isSaved={isSaved}
            isSaving={isSaving}
            classes={classes}
            isAvatarAtBottom={this.isAvatarAtBottom()}
            isAvatarAtTop={isAvatarAtTop}
            question={question}
            textfieldLabel={textfieldLabel}
            index={index}
            handleClick={this.handleClick}
            onAnswerChange={(answer) => prompt !== 'flowers' && prompt !== 'photo' && onAnswerChange?.(prompt, answer)}
        />;
    };

    renderThanksScreen = () => {
        const {
            userData,
            caseDisplayFname,
            classes,
            thanksScreenMessage,
            openSocialIconPopper,
            closeSocialIconPopper,
            openShareOptionsDialog
        } = this.props;
        const { isSaved } = this.state;

        const isAvatarAtTop = this.isAvatarAtTop();

        return (
            <Fade in={isSaved} timeout={300} mountOnEnter unmountOnExit>
                <div className={classNames(classes.fadeContainer, isSaved && classes.active)}>
                    <MemoryContributionThanksScreen
                        caseDisplayFname={caseDisplayFname}
                        userFname={userData && userData.fname || ''}
                        goBack={() => this.setState({ isSaved: false })}
                        openSocialIconPopper={(e, timeout) =>
                            openSocialIconPopper && openSocialIconPopper(e, 'FACEBOOK', timeout)
                        }
                        closeSocialIconPopper={closeSocialIconPopper}
                        rootClass={isAvatarAtTop ? classes.paddingTop12 : undefined}
                        openShareOptionsDialog={openShareOptionsDialog}
                        message={thanksScreenMessage}
                    />
                </div>
            </Fade>
        );
    };

    render() {
        const {
            classes,
            userData,
            isUserPhotoLoading,
            type,
            candleData,
            className,
            paperClass,
            intercomTargetProp,
            gaClassName,
        } = this.props;
        const { isSaved } = this.state;

        const isAvatarAtBottom = this.isAvatarAtBottom();
        const isAvatarAtTop = this.isAvatarAtTop();

        const user: AvatarUser = userData || { fname: 'M', lname: 'e' };
        const content = isSaved ? this.renderThanksScreen() : this.renderContent();

        return (
            <Grid item className={classNames(classes.root, className)}>
                <Paper
                    className={classNames(
                        gaClassName,
                        classes.paper,
                        isAvatarAtBottom && classes.atBottom,
                        paperClass,
                        (type === 'candle' && candleData) && candleData.styles
                    )}
                    {...getIntercomTargetProp(intercomTargetProp)}
                >
                    {isAvatarAtTop &&
                        <UserAvatar
                            isLoading={isUserPhotoLoading}
                            user={user}
                            capitalizeInitials={!!userData}
                            className={classNames(classes.avatar, classes.topAvatar)}
                        />
                    }

                    {content}

                    {isAvatarAtBottom &&
                        <div className={classNames(classes.arrow, isSaved && classes.primaryArrow)} />
                    }
                </Paper>

                {isAvatarAtBottom &&
                    <Grid item>
                        <UserAvatar
                            isLoading={isUserPhotoLoading}
                            user={user}
                            capitalizeInitials={!!userData}
                            className={classes.avatar}
                        />
                    </Grid>
                }
            </Grid>
        );
    }

    isAvatarAtTop = () => this.props.avatarPosition === 'top';

    isAvatarAtBottom = () => this.props.avatarPosition === 'bottom';

    handleClick = async (answer: string): Promise<boolean> => {
        const { onSave, prompt } = this.props;

        this.setState({ isSaving: true });

        if (onSave) {
            const memoryPrompt: MemoryPrompt = prompt === 'flowers' || prompt === 'photo'
                ? MemoryPrompt.Default
                : prompt;

            try {
                const { isSaving, hasError } = await createSaveMemoryPromise(
                    memoryPrompt,
                    SelfLoginSrcAction.shareText,
                    answer,
                    null,
                    onSave
                );
                this.setState({ isSaving, isSaved: !isSaving && !hasError });

                return true;
            } catch (error) {
                this.setState({ isSaving: false, isSaved: false });
                return false;
            }
        }

        return true;
    };

    savePhotos = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onSave } = this.props;

        this.setState({ isSaving: true });

        const files = event.target.files;
        if (onSave) {
            try {
                const { isSaving, hasError } = await createSaveMemoryPromise(
                    MemoryPrompt.Default,
                    SelfLoginSrcAction.sharePhoto,
                    null,
                    files,
                    onSave
                );
                this.setState({ isSaving, isSaved: !isSaving && !hasError });
            } catch (error) {
                this.setState({ isSaving: false, isSaved: false });
            }
        }
    };
}

export default withStyles(styles)(MemoryContribution);