import { useState } from 'react';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductUX } from '../../../../../shared/types';
import { useGDispatch } from '../../../../../types';
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import ShowHideProductDetails from "./ShowHideProductDetails";

interface ShowHideOnWebsiteProps {
    selectButton: BulkEditButtonType | null;
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const ShowHideOnWebsite = (props: ShowHideOnWebsiteProps) => {

    const { selectButton, selectedProductIds, onSubmitClick, funeralHomeId } = props;
    const dispatch = useGDispatch();
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitclick = async (isShowSelected: boolean) => {
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.show_hide_on_website,
            funeralHomeId, 
            productIds: selectedProductIds, 
            changes: {show_on_website: isShowSelected},
        }) );
        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <ShowHideProductDetails
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            onSubmitClick={handleSubmitclick}
            isSpinning={isSpinning}
        />
    );
};

export default ShowHideOnWebsite;
