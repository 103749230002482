import { Component, Fragment } from 'react';
import classNames from 'classnames';
import * as appService from '../../../../services/app.service';

import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';

import RemoveIcon from '@mui/icons-material/Remove';

import { getPhotoUrl } from '../../../../services';
import YoutubePlayer from '../../../common/YoutubePlayer';
import Badge from './Badge';
import {
    ProductSupplierUXWithCategory,
    CloudinaryTransformationsType
} from '../../../../shared/types';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { GStyles } from '../../../../styles/GStyles';

interface Props {
    suppliers: ProductSupplierUXWithCategory[];
    categoryName: string;
    isGatherUser?: boolean;
    removeSupplier?: (supplier: ProductSupplierUXWithCategory) => void;
}

interface State {
    youtubeVideoCode: string;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    imageContainer: {
        margin: 18,
        '& img': {
            height: 108,
            borderRadius: 8,
            verticalAlign: 'middle',
        }
    },
    badge: {
        cursor: 'pointer',
        position: 'absolute',
        top: -18,
        right: -12
    },
    onHoverShadow: {
        '&:hover': {
            boxShadow: [
                [0, 1, 10, 1, 'rgba(0, 0, 0, 0.2)'],
            ],
            borderRadius: 8
        },
    }
});

type StyledProps = Props & WithStyles<'root' | 'imageContainer' | 'badge' | 'onHoverShadow'>;

type CombinedProps = Props & StyledProps;
class SupplierImageList extends Component<CombinedProps, State> {

    state: State = {
        youtubeVideoCode: '',
    };

    renderYoutubePlayer = () => {
        const { categoryName } = this.props;
        const { youtubeVideoCode } = this.state;

        return (
            <YoutubePlayer
                isDialogOpen={youtubeVideoCode ? true : false}
                closeDialog={this.toggleYoutubeDialog}
                videoId={youtubeVideoCode || ''}
                isVideoAvailable={!appService.emptyValueValidator(youtubeVideoCode)}
                title={categoryName}
                zIndex={1330}
            />
        );
    };

    render() {
        const { classes, suppliers, isGatherUser, removeSupplier } = this.props;

        const removeTooltip = 'Remove this supplier from funeral home';
        const transformations: CloudinaryTransformationsType[] = [{
            height: 108,
            crop: 'limit'
        }];

        return (
            <Grid className={classes.root}>
                {suppliers.map((supplier) =>
                    <Fragment key={supplier.id}>
                        {supplier.photos.map((item) =>
                            <Zoom in timeout={1200} key={`sup_${item.photo_public_id}`}>
                                <div
                                    className={classNames(
                                        classes.imageContainer,
                                        GStyles.positionRelative,
                                        item.youtube_data && GStyles.cursorPointer,
                                        item.youtube_data && classes.onHoverShadow
                                    )}
                                    onClick={() => this.toggleYoutubeDialog(item.youtube_data)}
                                >
                                    <img src={getPhotoUrl(item.photo_public_id, transformations)} height={108} />
                                    {isGatherUser && removeSupplier &&
                                        <Tooltip title={removeTooltip} placement="top">
                                            <div
                                                className={classes.badge}
                                                onClick={!removeSupplier ? undefined : (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    removeSupplier(supplier);
                                                }}
                                            >
                                                <Badge
                                                    color="orange"
                                                    variant="round"
                                                    icon={{ root: <RemoveIcon /> }}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            </Zoom>
                        )}
                    </Fragment>
                )}
                {this.renderYoutubePlayer()}
            </Grid>
        );
    }

    toggleYoutubeDialog = (youtubeVideoCode?: string) => {
        this.setState({ youtubeVideoCode: youtubeVideoCode || '' });
    };
}

export default withStyles(styles)(SupplierImageList);
