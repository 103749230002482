import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useGDispatch, useGSelector } from '../../../types';
import { ConsumableSearchParam, EntitySummary, UserRoles } from '../../../shared/types';
import { loadEntity } from '../../../actions/Person.action';
import TeamUserSettings, { TeamUserSettingsTab } from './TeamUserSettings';
import HelperUserSettings, { HelperUserSettingsTab } from './HelperUserSettings';
import useDocumentEvent, { CUSTOM_EVENT } from '../../common/hooks/useDocumentEvent';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Slide, { SlideProps } from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import useFullScreen from '../../common/hooks/useFullScreen';
import { closeMySettingsDialog, openMySettingsDialog } from '../../../actions/Dialog.action';
import useConsumeSearchParam from '../../common/hooks/useConsumeSearchParam';
import { makeStyles } from '@mui/styles';

export type MySettingsTab = HelperUserSettingsTab | TeamUserSettingsTab;

const Transition = forwardRef(function SlideTransitionWithExtraProps(props: SlideProps, ref: React.Ref<unknown>) {
    return <Slide {...props} direction="up" mountOnEnter unmountOnExit />;
});

const useStyles = makeStyles(
    (theme) => ({
        dialog: {
            '& $dialogPaper': {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                width: '100%',
                maxWidth: '100%',
                '@media (min-width: 960px)': {
                    maxWidth: 760,
                    width: 760,
                    height: 'auto',
                    margin: '48px auto',
                },
            },
        },
        dialogPaper: {},
        dialogContent: {
            zIndex: 0,
            padding: 0,
            overflowX: 'hidden',
            '&:first-of-type': {
                padding: 0,
            },
        },
        loadingContainer: {
            height: '100%',
            textAlign: 'center',
            '@media (min-width: 768px)': {
                height: 635,
                maxHeight: 635,
            },
        },
    }),
    { name: 'MySettingsDialog' },
);

const MySettingsDialog = () => {
    const classes = useStyles();

    const dispatch = useGDispatch();
    const fullScreen = useFullScreen('md');

    const [selectedEntity, setSelectedEntity] = useState<EntitySummary | null>(null);
    const [isCloseConfirmationDialogOpen, setIsCloseConfirmationDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isOpen = useGSelector(({ dialogState }) => dialogState.mySettings.isOpen);
    const selectedEntityId = useGSelector(({ dialogState }) => dialogState.mySettings.entityId);
    const zIndex = useGSelector(({ dialogState }) => dialogState.mySettings.zIndex);
    const hasGatherCase = useGSelector(({ casesState }) => !!(casesState.publicCase || casesState.selectedCase));
    const isFHorGOMLoggedInUser = useGSelector(({ userSession }) => UserRoles.isFHorGOMUser(userSession.userData));

    const dataParamValue = useConsumeSearchParam(ConsumableSearchParam.data);
    const dataObject = dataParamValue ? JSON.parse(decodeURIComponent(dataParamValue)) : null;

    const selectedEntityIdFromParam = dataObject?.selectedEntityId;
    const activeTab = dataObject?.activeTab;

    const getEntitySummary = useCallback(async (): Promise<void> => {
        if (!selectedEntityId) {
            return;
        }

        setIsLoading(true);
        const results = await dispatch(loadEntity(selectedEntityId));
        setIsLoading(false);
        if (results) {
            const { entity, auth_email, auth_phone } = results;
            let _selectedEntity = entity;
            if (_selectedEntity) {
                if (auth_email) {
                    _selectedEntity = {
                        ..._selectedEntity,
                        email: auth_email,
                    };
                }
                if (auth_phone) {
                    _selectedEntity = {
                        ..._selectedEntity,
                        phone: auth_phone,
                    };
                }
            }

            setSelectedEntity(entity);
        }
    }, [dispatch, selectedEntityId]);

    useEffect(() => {
        if (!selectedEntityIdFromParam || !activeTab) {
            return;
        }

        dispatch(openMySettingsDialog(1320, selectedEntityIdFromParam, activeTab));
    }, [selectedEntityIdFromParam, activeTab, isLoading, dispatch]);


    useEffect(() => {

        if (isOpen && selectedEntityId !== null && (isFHorGOMLoggedInUser || hasGatherCase)) {
            getEntitySummary();
        }
    }, [isOpen, selectedEntityId, isFHorGOMLoggedInUser, hasGatherCase, getEntitySummary]);

    useDocumentEvent(CUSTOM_EVENT.user_profile_photo_update, getEntitySummary);

    const handleClose = ({ confirm }: { confirm: boolean }) => {
        if (confirm) {
            setIsCloseConfirmationDialogOpen(true);
        } else {
            closeDialog();
        }
    };

    const closeDialog = () => {
        setSelectedEntity(null);
        setIsCloseConfirmationDialogOpen(false);
        dispatch(closeMySettingsDialog());
    };

    const isSelectedEntityTeamMember = UserRoles.isFHorGOMUser(selectedEntity);

    const loadingSpinner = (
        <Grid container alignItems="center" justifyContent="center" className={classes.loadingContainer}>
            <CircularProgress />
        </Grid>
    );

    const mySettingsContent =
        !selectedEntity || !isOpen ? null : (
            <>
                {isSelectedEntityTeamMember && (
                    <TeamUserSettings
                        selectedEntity={selectedEntity}
                        key={selectedEntity.entity_id}
                        onClose={handleClose}
                    />
                )}
                {!isSelectedEntityTeamMember && (
                    <HelperUserSettings
                        selectedEntity={selectedEntity}
                        key={selectedEntity.entity_id}
                        onClose={handleClose}
                    />
                )}
            </>
        );

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isOpen}
            TransitionComponent={Transition}
            transitionDuration={300}
            keepMounted={false}
            onClose={() => handleClose({ confirm: true })}
            className={classes.dialog}
            classes={{ paper: classes.dialogPaper }}
            style={{ zIndex }}
            scroll="body"
        >
            <DialogContent className={classes.dialogContent}>
                {isLoading || !selectedEntity ? loadingSpinner : mySettingsContent}
            </DialogContent>
            <ConfirmationDialog
                header="Are you sure you want to close the window?"
                subHeader="You will lose any unsaved data you may have already entered here."
                confirmationButtonText="Close Window"
                open={isCloseConfirmationDialogOpen}
                onClose={() => setIsCloseConfirmationDialogOpen(false)}
                onConfirm={() => closeDialog()}
                zIndex={zIndex + 1}
            />
        </Dialog>
    );
};

export default MySettingsDialog;
