import { ButtonProps } from '@mui/material/Button';

import FileUploader, { AcceptFileType } from './FileUploader';
import GButton from './GButton';

interface Props extends Omit<ButtonProps, 'onClick' | 'onFocus'> {
    acceptFileTypes: AcceptFileType[];
    handleUpload: (files: FileList | null) => void;
    buttonText: string;
    buttonIcon?: JSX.Element;
    isLoading?: boolean;
}
const UploadButton = (props: Props) => {
    const { acceptFileTypes, buttonText, buttonIcon, isLoading, handleUpload, children, ...others } = props;

    return (
        <FileUploader
            handleSave={handleUpload}
            acceptTypes={acceptFileTypes}
        >
            {(inputRef) =>
                <GButton
                    {...others}
                    color="primary"
                    text={buttonText}
                    icon={buttonIcon}
                    isSpinning={isLoading}
                    onClick={e => inputRef.click()}
                />
            }
        </FileUploader>
    );
};

export default UploadButton;