import FRQuestion from './FR.Question';

interface Props {
    caseFName: string;
    onStepChange(step: number): void;
}

const FRQuestionTwo = (props: Props) => {
    const { caseFName, onStepChange } = props;

    return (
        <FRQuestion
            question={`Did ${caseFName} die AFTER January 20, 2020 in the United States?`}
            onAccept={() => onStepChange(5)}
            onDeny={() => onStepChange(4)}
        />
    );
};

export default FRQuestionTwo;