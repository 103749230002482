import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PrintIcon from '@mui/icons-material/Print';
import GestureIcon from '@mui/icons-material/Gesture';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

import { GatherCaseUX } from '../../../../shared/types';

import GGroupButtons from '../../../common/GGroupButtons';

import { PrintModeType } from '..';
import PrintDialog, { COMPACT_LAYOUT, VISUAL_LAYOUT, PrintOptionsType, } from '../dialogs/Print.dialog';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { RouteBuilder } from '../../../../services';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            margin: '8px 0',
            fontSize: 18,
            fontWeight: 300
        }
    },
    draft: {
        border: '1px dashed !important'
    },
    lockIcon: {
        position: 'absolute',
        top: 4,
        right: 4,
        fontSize: 16,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'header' | 'lockIcon' | 'draft'>;

interface Props {
    selectedCase: GatherCaseUX;
    isDialogOpen: boolean;
    isCondensedView: boolean;
    isAutoFormsEnabled: boolean;
    closeDialog: () => void;
    toggleCondensedView: (isChecked: boolean) => void;
    printContract: (printConclusively: boolean) => void;
    zIndex: number;
}

interface State {
    printModeType: PrintModeType | null;
    isPrintButtonClicked: boolean;
}

const INITIAL_STATE: State = {
    printModeType: null,
    isPrintButtonClicked: false,
};

class PrintContractsDialog extends Component<StyledProps, State> {
    state: State = INITIAL_STATE;

    getPrintLayoutButtons = () => {
        const { classes, isCondensedView } = this.props;
        return (
            <Grid item xs={12} className={classes.header}>
                <Typography color="secondary">
                    Select Print Layout
                </Typography>

                <div>
                    <GGroupButtons
                        buttons={[
                            {
                                label: 'Visual Layout',
                                value: VISUAL_LAYOUT,
                                icon: <ViewAgendaIcon />
                            },
                            {
                                label: 'Compact Layout',
                                value: COMPACT_LAYOUT,
                                icon: <ViewHeadlineIcon />
                            }
                        ]}
                        activeButton={isCondensedView ? COMPACT_LAYOUT : VISUAL_LAYOUT}
                        onClick={this.toggleLayout}
                        size={'small'}
                        controlClasses={classNames(classes.fontWeight200, classes.textCapitalize)}
                    />
                </div>
            </Grid>
        );
    };

    getOptionsList = () => {
        const { classes } = this.props;
        const { printModeType } = this.state;

        const options = [
            {
                id: PrintOptionsType.OfficialStatement,
                primaryText: PrintOptionsType.OfficialStatement,
                secondaryText: <>
                    <Typography>Statement will be LOCKED</Typography>
                    <Typography>Any future edits will be tracked as revisions</Typography>
                    <Typography>PRINT & SIGN or SIGN DIGITALLY</Typography>
                </>,
                icon: <GestureIcon />,
                isActive: (printModeType === 'official') ? true : false,
                onClick: () => this.setState({ printModeType: 'official' }),
                lockIcon: <LockIcon className={classes.lockIcon} />
            },
            {
                id: PrintOptionsType.DraftStatement,
                primaryText: PrintOptionsType.DraftStatement,
                secondaryText: <>
                    <Typography>Statement will remain in DRAFT</Typography>
                    <Typography>You can continue to edit the statement</Typography>
                    <Typography>Signature lines WON’T BE included</Typography>
                </>,
                icon: <PrintIcon />,
                isActive: (printModeType === 'draft') ? true : false,
                onClick: () => this.setState({ printModeType: 'draft' }),
                additionalClass: classes.draft,
                lockIcon: <LockOpenIcon className={classes.lockIcon} />
            }
        ];

        return options;
    };

    getOfficialFooter = () => {
        const { selectedCase, isAutoFormsEnabled } = this.props;

        const helperText = isAutoFormsEnabled
            ? `Statement will be LOCKED`
            : `Signatures Not Enabled`;

        return {
            buttonText: <><GestureIcon />&nbsp;Sign Statement</>,
            helperText: helperText,
            isButtonLink: true,
            buttonLink: RouteBuilder.DocPacketPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
            }),
            disabled: !isAutoFormsEnabled
        };
    };

    getFooter = () => {
        const { printModeType } = this.state;
        const isDraft = printModeType === 'draft';

        const buttonText = isDraft
            ? `Print Draft Statement`
            : `Print Statement`;

        const helperText = isDraft
            ? `Statement will remain in DRAFT`
            : `Please select statement type above`;

        return {
            buttonText: <><PrintIcon />&nbsp;{buttonText}</>,
            helperText: helperText,
            onClick: () => this.printContract(),
        };
    };

    render() {
        const {
            isDialogOpen,
            selectedCase,
            zIndex,
        } = this.props;
        const { printModeType } = this.state;

        const isOfficial = printModeType === 'official';

        const heading = <><PrintIcon />&nbsp;Print {selectedCase.fname}'s Statement</>;

        return (
            <PrintDialog
                onExited={this.performActionOnExit}
                heading={heading}
                isDialogOpen={isDialogOpen}
                optionsList={this.getOptionsList()}
                closeDialog={this.closeDialog}
                zIndex={zIndex}
                footerContent={isOfficial ? this.getOfficialFooter() : this.getFooter()}
                getPrintLayoutButtons={this.getPrintLayoutButtons}
            />
        );
    }

    toggleLayout = (layoutType: string) => {
        const { toggleCondensedView } = this.props;
        toggleCondensedView(layoutType === COMPACT_LAYOUT ? true : false);
    };

    printContract = () => {
        this.setState({ isPrintButtonClicked: true });
        this.props.closeDialog();
    };

    performActionOnExit = () => {
        if (!this.state.isPrintButtonClicked) {
            return;
        }
        this.props.printContract(true);
        this.setState(INITIAL_STATE);
    };

    closeDialog = () => {
        this.props.closeDialog();
        this.setState(INITIAL_STATE);
    };
}

export default withGStyles(styles)(PrintContractsDialog);
