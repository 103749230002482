import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import { Theme } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import BorderedSection from './BorderedSection';
import { SvgIconComponent } from '@mui/icons-material';
import { getIntercomTargetProp } from '../../services';

const useStyles = makeStyles<Theme, Props>(theme => ({
    root: {
        margin: '6px auto',
    },
    heading: {
        padding: 0,
    },
    footer: {
        minHeight: 36,
        padding: 4,
        background: theme.palette.primary.main,
        width: '100%',
        borderRadius: '0px 0px 8px 8px',
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
    },
}), { name: 'BorderedSectionWithFooter' });

interface Props {
    heading: SvgIconComponent | string;
    footer: JSX.Element;
    componentClasses?: {
        root?: string;
        heading?: string;
        footer?: string;
    };
    intercomTargetProp?: string;
    intercomTargetPropFooter?: string;
}
const BorderedSectionWithFooter = (props: PropsWithChildren<Props>) => {
    const {
        children,
        heading,
        componentClasses,
        footer,
        intercomTargetProp,
        intercomTargetPropFooter
    } = props;

    const classes = useStyles(props);

    return (
        <BorderedSection
            heading={heading}
            classes={{
                root: classNames(classes.root, componentClasses?.root),
                heading: classNames(classes.heading, componentClasses?.heading),
            }}
            intercomTargetProp={intercomTargetProp}
            >
            {children}
            <Grid
                className={classNames(classes.footer, componentClasses?.footer)}
                {...getIntercomTargetProp(intercomTargetPropFooter)}
            >
                {footer}
            </Grid>
        </BorderedSection>
    );
};

export default BorderedSectionWithFooter;