import * as React from 'react';
import { TASK_DIALOGS } from './index';
import {
    WorkOnButtonLink,
    GatherCaseUX,
    CaseTaskUX,
} from '../../../shared/types';
import { useGSelector } from '../../../types';
import { setAppSnackbar } from '../../../actions/AppSnackbar.action';
import { openAccessRestrictedDialog } from '../../../actions/AccessRestricted.action';
import { useDispatch } from 'react-redux';

function useReduxState() {
    const tasks = useGSelector(({ tasksState }) => tasksState.checklistTasks);
    const team = useGSelector(({ teamState }) => teamState.team);
    const activeFuneralHome = useGSelector(state => state.funeralHomeState.activeFuneralHome);
    const userData = useGSelector(state => state.userSession.userData);

    return { tasks, team, activeFuneralHome, userData };
}

export interface TaskDialogComponentProps extends ReturnType<typeof useReduxState> {
    isDialogOpen: boolean;
    tabIndex: number | null;
    activeCase: GatherCaseUX;
    task?: CaseTaskUX;
    closeDialog: () => void;
    setAppSnackbar: (...params: Parameters<typeof setAppSnackbar>) => void;
    openAccessRestrictedDialog: (...params: Parameters<typeof openAccessRestrictedDialog>) => void;
    zIndex: number;
}

interface Props {
    activeTaskId?: number;
    taskDialogToBeMounted: WorkOnButtonLink | null;
    activeCase: GatherCaseUX;
    tabIndex?: number | null;
    closeDialog: (thisDialog: WorkOnButtonLink) => void;
    zIndex: number;
}

const TaskDialog = (props: Props) => {
    const {
        taskDialogToBeMounted,
        tabIndex,
        closeDialog,
        activeTaskId,
        activeCase,
        zIndex,
    } = props;

    const dispatch = useDispatch();
    const { activeFuneralHome, userData, tasks, team } = useReduxState();

    const [mountedDialog, setMountedDialog] = React.useState<WorkOnButtonLink | null>(null);

    React.useEffect(() => {
        if (taskDialogToBeMounted) {
            setMountedDialog(taskDialogToBeMounted);
        } else {
            setTimeout(() => unmountDialog, 300);
        }
    }, [taskDialogToBeMounted]);

    const unmountDialog = () => {
        setMountedDialog(null);
    };

    const setSnackbar = (...params: Parameters<typeof setAppSnackbar>) => {
        dispatch(setAppSnackbar(...params));
    };

    const openRestrictedDialog = (...params: Parameters<typeof openAccessRestrictedDialog>) => {
        dispatch(openAccessRestrictedDialog(...params));
    };

    if (!mountedDialog) {
        return null;
    }

    const activeTask = tasks.find((t) => t.id === activeTaskId);

    const DialogComponent: React.FC<TaskDialogComponentProps> = TASK_DIALOGS[mountedDialog];

    return (
        <DialogComponent
            isDialogOpen={taskDialogToBeMounted !== null}
            closeDialog={() => closeDialog(mountedDialog)}
            tabIndex={tabIndex || null}
            setAppSnackbar={setSnackbar}
            openAccessRestrictedDialog={openRestrictedDialog}
            team={team}
            task={activeTask}
            tasks={tasks}
            activeCase={activeCase}
            userData={userData}
            activeFuneralHome={activeFuneralHome}
            zIndex={zIndex}
        />
    );
};

export default React.memo(TaskDialog);
