import { GatherEventWithPlayback, getValidator } from '.';
import * as t from 'io-ts';

export enum StreamDeviceType {
    mevoPlus = 'mevoPlus',
    mevoStart = 'mevoStart',
    cellPhone = 'cellPhone',
    tablet = 'tablet',
    otherCamera = 'otherCamera',
}

export function isStreamDeviceType(c: string): c is StreamDeviceType {
    return Boolean(StreamDeviceType[c]);
}

export interface DeletedStreamDeviceResponse {
    removedStreamDevice: StreamDeviceRecord;
}
export interface StreamDeviceRecord {
    id: number;
    nickname: string;
    devicename: string;
    stream_key: string;
    stream_id: string;
    playback_id: string;
    is_recording: boolean; //  This happens  (status is idle) 
    recording_update_time: Date;
    is_streaming: boolean; //  status changes to active happens after recording
    streaming_update_time: Date;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    thumbnail: string;
    stream_device_type: StreamDeviceType;
    sent_camera_left_streaming: Date | null;
    tablet_notes: string | null;
    camera_notes: string | null;
}

const StreamDeviceTypeDef = t.keyof({
    [StreamDeviceType.mevoPlus]: t.null,
    [StreamDeviceType.mevoStart]: t.null,
    [StreamDeviceType.cellPhone]: t.null,
    [StreamDeviceType.tablet]: t.null,
    [StreamDeviceType.otherCamera]: t.null,
});

const StreamDevicePatchRequestDefinition = {
    nickname: t.string,
    devicename: t.string,
    stream_device_type: StreamDeviceTypeDef,
    tablet_notes: t.union([t.string, t.null]),
    camera_notes: t.union([t.string, t.null]),
};

const StreamDevicePatchRequestType = t.partial(StreamDevicePatchRequestDefinition);

export interface StreamDevicePatchRequest extends t.TypeOf<typeof StreamDevicePatchRequestType> {
}

export class StreamDevicePatchRequest {
    public static fromRequest = getValidator<StreamDevicePatchRequest>(StreamDevicePatchRequestType);
}

export interface StreamDeviceUX extends StreamDeviceRecord {
    funeralHomeNames: string;
    playback_url: string;
    thumbnail_url: string;
    animatedgif_url: string;
}

export interface FuneralHomeStreamDeviceRecord {
    funeral_home_id: number;
    stream_device_id: number;
}

export interface StreamAssetUpsert {
    asset_id: string;
    live_stream_id: string | null;
    playback_id: string | null;
    meta_data: Object;
    asset_status: string;
    created_time: Date | null;
    updated_time: Date | null;
    deleted_time: Date | null;
    event_id: number | null;
    duration: number | null;
    passthrough: string | null;
    is_live: boolean;
    download_filename: string | null;
}

export interface StreamAssetRecord extends StreamAssetUpsert {
    id: number;
    created_time: Date;
    deleted_by: number | null;
    delete_attempt_time: Date | null;
    asset_rank: number | null;
}

export interface StreamAssetSummary {
    asset_id: number;
    playback_id: string;
    download_filename: string | null;
}

export interface StreamableEventsAndDevicesResponse {
    events: GatherEventWithPlayback[];
    devices: StreamDeviceUX[];
}

export interface PlaybackStreamAsset {
    asset_id: number;
    video_url: string;
    download_url: string | null;
    thumbnail_url: string;
}

export interface PlaybackURLs {
    live: string[];
    replay: PlaybackStreamAsset[];
}
