import * as React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';

import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeComment from '@mui/icons-material/ModeComment';
import ClearIcon from '@mui/icons-material/Clear';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import MoreIcon from '@mui/icons-material/More';
import Pages from '@mui/icons-material/Pages';
import Check from '@mui/icons-material/Check';
import DeleteForever from '@mui/icons-material/DeleteForever';

import { ORANGE_COLOR } from '../../../../constants/colorVariables';

import Badge from '../widgets/Badge';
import {
    ProductUX,
    ProductContractItemUX,
    PricingModelEnum,
    GPLContext,
} from '../../../../shared/types';
import { getPhotoUrl } from '../../../../services';
import {
    getVariablePriceDisplay,
    getProductQuantity,
    getDisplayPrice,
    formatPrice,
    getContractItemPriceAdjustment,
    formatDinero,
} from '../../../../shared/goods_and_services/pricing';
import IncreaseDecreaseTextField from '../widgets/IncreaseDecreaseTextField';
import PriceWidget from '../widgets/PriceWidget';
import { getContractItemName } from '../../../../shared/goods_and_services/utils';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import { usePermissionEnabled } from '../../../common/hooks/usePermissionEnabled';

interface Props {
    caseName: string;
    caseAssignee: string;
    canUserEditContract: boolean;
    product?: ProductUX;
    contractItem?: ProductContractItemUX;
    packageItemId?: number;
    isDialogOpen: boolean;
    isGOMOrFHUser: boolean;
    isAllowanceAvailable?: boolean;
    allowanceUsed?: ProductContractItemUX;
    context: GPLContext;
    closeDialog: () => void;
    onAddContractItem?: (product: ProductUX, quantity: number, packageItemId?: number) => void;
    onUpdateQuantity?: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    handleClickEventOnAvatar?: React.MouseEventHandler<HTMLElement>;
    onMenuClick?: (e: HTMLElement) => void;
    openPriceAdjustmentDialog?: () => void;
    toggleNotesDialog?: () => void;
    onToggleAllowance?: (product?: ProductUX, contractItem?: ProductContractItemUX) => void;
    onEditClick?: (productId: number) => void;
    openSpecifyPriceDialog?: () => void;
    openPhotoSwipeDialog: (activeImage: string | null, photos: string[]) => void;
    openRenameContractItemDialog?: () => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
                maxHeight: '90vh'
            }
        },
    },
    dialogPaper: {},
    dialogContent: {
        zIndex: 0,
        padding: 0,
        paddingTop: '0 !important',
    },
    clearIcon: {
        zIndex: 1,
        position: 'absolute',
        top: 6,
        right: 10,
        fontSize: 36,
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    imageContainer: {
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.common.white,
        width: 'auto',
        height: 235,
        margin: 0,
    },
    ul: {},
    li: {},
    heading: {
        textAlign: 'center',
        fontSize: 32,
        textTransform: 'capitalize',
        lineHeight: '1.25em'
    },
    details: {
        marginTop: 8,
        fontSize: 18,
        whiteSpace: 'pre-line'
    },
    detailsGrid: {
        padding: '8px 16px 54px',
        [theme.breakpoints.up('md')]: {
            padding: '8px 16px 0',
        }
    },
    fab: {
        margin: '8px 16px 8px auto',
        color: '#000 !important',
        fontWeight: 400,
        textTransform: 'capitalize',
        padding: '0 12px',
        lineHeight: '1em',
        letterSpacing: 'normal',
    },
    button: {
        margin: '8px 0px',
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: 400,
        textTransform: 'capitalize',
        padding: '0 8px 0 8px',
        lineHeight: '1em',
        letterSpacing: 'normal',
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center'
    },
    footer: {
        backgroundColor: theme.palette.common.white,
        width: 'calc(100% - 32px)',
        color: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid',
        margin: '0 16px',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        }
    },
    ulGrid: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 48,
        '& $ul': {
            margin: 'auto',
            backgroundColor: theme.palette.common.white,
            alignItems: 'center',
            '& $li': {
                margin: 0,
                overflowY: 'unset !important',
            }
        },
    },
    priceDivider: {
        color: theme.palette.secondary.main,
        width: '100%',
        margin: '8px auto',
        borderBottom: '1px solid'
    },
    rate: {
        textAlign: 'center',
        fontWeight: 200,
        fontSize: 12,
        lineHeight: '1.25em'
    },
    restrictedFooter: {
        margin: '16px auto',
        '& span': {
            cursor: 'pointer'
        }
    },
    menuIcon: {
        position: 'absolute',
        zIndex: 1,
        width: 40,
        height: 40,
        top: 4,
        left: 4,
        '& svg': {
            fontSize: 32,
        }
    },
    priceContainer: {
        width: 'fit-content',
        margin: 'auto',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            display: 'inline-flex',
            margin: '0 4px',
        }
    },
    noteContainer: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        borderBottom: '1px solid',
        borderTop: '1px solid',
        marginTop: 8,
        '& p': {
            color: theme.palette.primary.main,
            fontSize: 16,
            width: 'fit-content',
            margin: '4px auto',
            lineHeight: '1.25em',
            '& svg': {
                fontSize: 16,
                verticalAlign: 'middle',
            }
        }
    },
    clickAble: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    adjustmentText: {
        textAlign: 'center',
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        }
    },
    lineThrough: {
        backgroundColor: theme.palette.secondary.main,
        height: 2,
        position: 'absolute',
        top: '50%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            top: 'calc(50% - 3px)',
        },
    },
    priceMainContainer: {
        width: 'fit-content',
        margin: 'auto',
    },
    allowanceIcon: {
        left: 40,
    },
    opacity_0_1_8: {
        opacity: 0.18,
    },
    dashedBorder: {
        border: `1px dashed ${ORANGE_COLOR}`,
        borderRadius: 4,
    },
    editButton: {
        background: `${ORANGE_COLOR} !important`,
        color: theme.palette.common.white,
        margin: 8,
    },
    editIcon: {
        margin: 0,
        cursor: 'pointer',
    },
    transformMe: {
        cursor: 'pointer',
        transform: 'scale(1)',
        transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
});

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'dialogContent' | 'clearIcon' | 'imageContainer'
    | 'ul' | 'li' | 'heading' | 'details' | 'detailsGrid' | 'fab' | 'button' | 'footer' | 'ulGrid' | 'priceDivider'
    | 'rate' | 'restrictedFooter' | 'menuIcon' | 'priceContainer' | 'noteContainer' | 'clickAble' | 'adjustmentText'
    | 'lineThrough' | 'priceMainContainer' | 'allowanceIcon' | 'opacity_0_1_8' | 'dashedBorder' | 'editButton'
    | 'editIcon' | 'transformMe'>;

type CombinedProps = DialogProps & StyledProps;

const transformations = [{
    width: 380,
    height: 235,
    quality: 'auto',
    fetch_format: 'auto',
    crop: 'limit',
}];

const ProductDetailDialog = (props: CombinedProps) => {
    let topscroll: HTMLDivElement | null = null;

    const {
        classes,
        product,
        contractItem,
        packageItemId,
        fullScreen,
        isDialogOpen,
        onAddContractItem,
        onUpdateQuantity,
        onRemoveContractItem,
        closeDialog,
        canUserEditContract,
        caseAssignee,
        caseName,
        context,
        onEditClick,
        onMenuClick,
        isGOMOrFHUser,
        openPriceAdjustmentDialog,
        openSpecifyPriceDialog,
        toggleNotesDialog,
        allowanceUsed,
        onToggleAllowance,
        isAllowanceAvailable,
        handleClickEventOnAvatar,
        openPhotoSwipeDialog,
        zIndex,
    } = props;

    const isBackOffice = context === GPLContext.BackOffice;
    const isPublicGPL = context === GPLContext.Public;

    const quantity = getProductQuantity(product, contractItem);
    const isManualPricing = product && product.pricing_model === PricingModelEnum.manual;

    const isAddOrRemovePermissionEnabled = usePermissionEnabled(contractItem
        ? Permission.REMOVE_ITEMS
        : Permission.ADD_ITEMS);
    const canDiscountOrModifyPrice = usePermissionEnabled(isManualPricing
        ? Permission.MODIFY_PRICES
        : Permission.CONTRACT_ITEM_DISCOUNTS);

    const canChangePrice = isManualPricing
        ? contractItem
            ? canDiscountOrModifyPrice
            : true
        : canDiscountOrModifyPrice;

    const handleClose = () => {
        if (topscroll) {
            topscroll.scrollIntoView({
                block: 'start',
                inline: 'start',
            });
        }

        closeDialog();
    };

    const handleQuantityChange = (newQuantity: number) => {
        if (!product) {
            return;
        }

        if (!contractItem && onAddContractItem) {
            // if quantity is changed and product hasn't been added to the contract -> add it
            onAddContractItem(product, newQuantity, packageItemId);
        } else if (contractItem && onUpdateQuantity) {
            onUpdateQuantity(contractItem, newQuantity);
        }

    };

    const handlePriceClick = () => {
        if (!canChangePrice) {
            return;
        }

        if (isGOMOrFHUser) {
            if (isManualPricing) {
                if (openSpecifyPriceDialog) {
                    openSpecifyPriceDialog();
                }
            } else {
                if (openPriceAdjustmentDialog) {
                    openPriceAdjustmentDialog();
                }
            }
        }
    };

    const handleAddRemoveClick = () => {
        if (contractItem) {
            if (onRemoveContractItem) {
                onRemoveContractItem(contractItem);
                handleClose();
            }
        } else {
            if (product && onAddContractItem) {
                onAddContractItem(product, quantity, packageItemId);
            }
        }
    };

    const renderTaxableText = (label: string, icon: JSX.Element) => {
        return (
            <Typography
                color="secondary"
                className={classes.button}
            >
                {icon}
                &nbsp;{label}
            </Typography>
        );
    };

    const renderManufacturerButton = () => {
        if (!product || !product.manufacturer_name) {
            return null;
        }

        const link = product.manufacturer_link;

        const button = (
            <Button
                color="secondary"
                disabled={!link}
                className={classes.button}
            >
                <BusinessIcon />
                &nbsp;{product.manufacturer_name}
            </Button>
        );

        if (link) {
            return (
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.textDecorationNone}
                >
                    {button}
                </a>
            );
        } else {
            return button;
        }
    };

    const renderFabButton = (val: string, key: string) => {
        const label = `${key}: ${val}`;
        return (
            <Fab
                key={label}
                size="small"
                variant="extended"
                color="secondary"
                disabled
                className={classes.fab}
            >
                {label}
            </Fab>
        );
    };

    const renderImages = (photos: string[]) => {
        return (
            <Grid item xs={12} className={classes.ulGrid}>
                <ul className={classNames(classes.ul, classes.list)}>
                    {photos.map((photo) =>
                        <Zoom in timeout={1200} key={photo}>
                            <ImageListItem className={classes.li}>
                                <div className={classes.overflowHidden}>
                                    <Tooltip title={'Click to view full-screen'} placement="top">
                                        <img
                                            className={classNames(classes.imageContainer, classes.transformMe)}
                                            src={getPhotoUrl(photo, transformations)}
                                            onClick={e => openPhotoSwipeDialog(photo, photos)}
                                        />
                                    </Tooltip>
                                </div>
                            </ImageListItem>
                        </Zoom>
                    )}
                </ul>
            </Grid>
        );
    };

    const renderPrice = () => {

        if (isPublicGPL && product && !product.show_price_on_website) {
            return null;
        }

        const price = getDisplayPrice(product, contractItem);
        let originalPriceStr = '';
        let priceAdjustmentText = '';
        let isPriceChanged = false;
        if (contractItem) {
            originalPriceStr = formatPrice(contractItem.list_price, contractItem.asset_type);
            const priceAdjustment = getContractItemPriceAdjustment(contractItem);
            isPriceChanged = contractItem.price_adjustment === 0 ? false : true;
            const priceDiffStr = formatDinero(priceAdjustment);
            const adjustmentType = priceAdjustment.isNegative() ? 'discount' : 'premium';
            priceAdjustmentText = priceDiffStr + ' ' + adjustmentType + ' applied';
        }

        const tooltipText = isGOMOrFHUser && !isBackOffice
            ? `Click to ${isManualPricing ? 'specify' : 'adjust'} price`
            : '';

        return (
            <div className={classes.priceMainContainer}>
                {isPriceChanged &&
                    <Tooltip
                        title={canChangePrice ? tooltipText : ''}
                        placement="top"
                    >
                        <div
                            className={classNames(
                                classes.priceContainer,
                                (isGOMOrFHUser && canChangePrice) && classes.cursorPointer,
                            )}
                            onClick={handlePriceClick}
                        >
                            <PriceWidget
                                priceStr={originalPriceStr}
                                color={'secondary'}
                                size={'large'}
                            />
                            <span className={classes.lineThrough} />
                        </div>
                    </Tooltip>
                }
                <Tooltip
                    title={canChangePrice ? tooltipText : ''}
                    placement="top"
                >
                    <div
                        className={classNames(
                            classes.priceContainer,
                            (isGOMOrFHUser && canChangePrice) && classes.cursorPointer,
                            isPriceChanged && classes.colorOrange,
                        )}
                        onClick={handlePriceClick}
                    >
                        <PriceWidget
                            priceStr={price}
                            color={!isPriceChanged ? 'secondary' : undefined}
                            size={'large'}
                        />
                    </div>
                </Tooltip>
                {isPriceChanged &&
                    <Typography className={classNames(classes.adjustmentText, classes.colorOrange)}>
                        {priceAdjustmentText}
                    </Typography>
                }
            </div>
        );
    };

    const renderDetails = () => {
        const { openRenameContractItemDialog } = props;
        const isIncludedInPackage = Boolean(contractItem && contractItem.package_id);
        const taxRateName: string = product && product.tax_rate_name ? product.tax_rate_name : '';
        const taxRateDescription: string = product && product.tax_rate_description
            ? `(${product.tax_rate_description})`
            : '';
        const isTaxFree = contractItem
            ? !contractItem.tax_rate_id
            : product
                ? !product.tax_rate_id
                : false;
        const variableUnitsStr = product && `Total ${product.var_increment_units || ''}${product.var_increment_units &&
            !product.var_increment_units.endsWith('s') ? 's' : ''}`;
        const variablePricing = product ? getVariablePriceDisplay(product) : undefined;

        const isVariablePricing = product && (product.pricing_model === PricingModelEnum.base_plus_variable
            || product.pricing_model === PricingModelEnum.variable);

        const showNote = (isGOMOrFHUser || (!isGOMOrFHUser && contractItem && contractItem.note))
            && contractItem && !isBackOffice;

        const allowRename = isGOMOrFHUser && contractItem && canUserEditContract && openRenameContractItemDialog;

        const displayName = getContractItemName({ product, contractItem });
        return (
            <Grid item xs={12} className={classes.detailsGrid}>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.heading,
                        product && product.photos.length === 0 && classes.marginTop30
                    )}
                >
                    {displayName}
                    {allowRename &&
                        <>
                            &nbsp;
                            <DisabledPermissionTooltip permission={Permission.CONTRACT_MODIFY_ITEM_NAME}>
                                {disabled =>
                                    <IconButton
                                        className={classes.editIcon}
                                        onClick={openRenameContractItemDialog}
                                        size="large"
                                        disabled={disabled}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                }
                            </DisabledPermissionTooltip>
                        </>
                    }
                </Typography>
                {renderPrice()}
                {isIncludedInPackage &&
                    <Badge
                        color="inverted"
                        icon={{ position: 'precedent', root: <Pages /> }}
                        label={'Included in Package'}
                        size="small"
                        variant="extended"
                    />
                }

                {showNote &&
                    <Grid item className={classNames(classes.noteContainer, classes.background_primary_0_0_8)}>
                        <Tooltip
                            title={isGOMOrFHUser ? `Note will be added to ${caseName}'s statement` : ''}
                            placement="top"
                        >
                            <Typography
                                className={isGOMOrFHUser ? classes.clickAble : ''}
                                onClick={() => isGOMOrFHUser && toggleNotesDialog && toggleNotesDialog()}
                            >
                                <ModeComment /> {contractItem && contractItem.note ? contractItem.note : 'Add a Note'}
                            </Typography>
                        </Tooltip>
                    </Grid>
                }

                <Typography color="secondary" className={classes.details}>
                    {product ? product.description : contractItem ? contractItem.description : ''}
                </Typography>

                {isVariablePricing && product && product.description &&
                    <div className={classes.priceDivider} />
                }

                {isVariablePricing &&
                    <Typography color="inherit" className={classes.rate}>
                        {variablePricing}
                    </Typography>
                }

                {isVariablePricing && product &&
                    <IncreaseDecreaseTextField
                        canUserEditContract={canUserEditContract}
                        rootClass={classes.marginBottom20}
                        helperText={variableUnitsStr}
                        counter={quantity}
                        onCounterChange={handleQuantityChange}
                        min={product.base_quantity || undefined}
                        max={product.var_max_quantity || undefined}
                        disabled={isBackOffice || !isAddOrRemovePermissionEnabled}
                    />
                }

                {product &&
                    <Grid item xs={12}>
                        {map(product.tags, renderFabButton)}
                    </Grid>
                }

                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Tooltip title={taxRateName} placement="top">
                        {renderTaxableText(
                            isTaxFree
                                ? 'Non-Taxable'
                                : `Taxable ${taxRateDescription}`,
                            isTaxFree ? <MoneyOffIcon /> : <AttachMoneyIcon />,
                        )}
                    </Tooltip>
                    {renderManufacturerButton()}
                    {product && product.model_number && renderTaxableText(product.model_number, <MoreIcon />)}
                </Grid>
            </Grid>
        );
    };

    const renderFooter = () => {
        if (!canUserEditContract) {
            if (!handleClickEventOnAvatar) {
                return null;
            }

            return (
                <Typography color="secondary" className={classes.restrictedFooter}>
                    <span
                        className={classes.textUnderline}
                        onClick={handleClickEventOnAvatar}
                    >
                        Contact {caseAssignee}
                    </span>
                    {contractItem ? ' to remove from statement' : ' to add to statement'}
                </Typography>
            );
        }

        if (isBackOffice) {
            return (
                <Button
                    variant="contained"
                    className={classes.editButton}
                    onClick={() => product && onEditClick && onEditClick(product.id)}
                >
                    Edit Product
                </Button>
            );
        }

        return (
            <Grid
                container
                justifyContent={contractItem ? 'space-between' : 'center'}
                className={classNames(classes.marginTop8, classes.marginBottom8)}
            >
                <DisabledPermissionTooltip permission={contractItem ? Permission.REMOVE_ITEMS : Permission.ADD_ITEMS}>
                    {disabled =>
                        <Button
                            disabled={disabled}
                            color="primary"
                            variant={contractItem ? 'outlined' : 'contained'}
                            onClick={handleAddRemoveClick}
                        >
                            {contractItem
                                ? <><DeleteForever />&nbsp;Remove</>
                                : 'Add to statement'}
                        </Button>
                    }
                </DisabledPermissionTooltip>

                {contractItem &&
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={closeDialog}
                    >
                        <Check />&nbsp;Done
                    </Button>
                }
            </Grid>
        );
    };

    const allowanceToolTip = allowanceUsed ?
        `This product is included as part of ${allowanceUsed.name}. Click to exclude`
        : 'This product is NOT included as an allowance. Click to include.';
    const isAllowancePricing = product && product.pricing_model === PricingModelEnum.allowance;
    const hideAllowanceIcon = !isAllowanceAvailable && !allowanceUsed || !canUserEditContract || packageItemId ||
        isAllowancePricing || contractItem && contractItem.package_item_id;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.root}
            classes={{
                paper: classes.dialogPaper,
            }}
            style={{ zIndex }}
        >
            <DialogContent
                className={classNames(
                    classes.dialogContent,
                    isBackOffice && classes.dashedBorder
                )}
            >
                <div ref={ref => topscroll = ref} />
                {isGOMOrFHUser && !isBackOffice &&
                    <IconButton
                        className={classes.menuIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (onMenuClick) {
                                onMenuClick(e.currentTarget);
                            } else {
                                e.isDefaultPrevented();
                            }
                        }}
                        size="large">
                        <MoreVertIcon fontVariant="outlined" color="primary" />
                    </IconButton>
                }

                {!hideAllowanceIcon &&
                    <Tooltip
                        title={allowanceToolTip}
                        placement="top"
                        enterDelay={600}
                    >
                        <IconButton
                            className={classNames(classes.menuIcon, classes.allowanceIcon)}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (onToggleAllowance) {
                                    onToggleAllowance(product, contractItem);
                                } else {
                                    e.isDefaultPrevented();
                                }
                            }}
                            size="large">
                            <MonetizationOnOutlined
                                className={allowanceUsed ? '' : classes.opacity_0_1_8}
                                color="primary"
                            />
                        </IconButton>
                    </Tooltip>
                }
                <ClearIcon
                    className={classes.clearIcon}
                    onClick={handleClose}
                />
                <Grid container>
                    {product && product.photos.length > 0 && renderImages(product.photos)}
                    {renderDetails()}

                    {renderFooter() !== null &&
                        <Grid item xs={12} className={classes.footer}>
                            {renderFooter()}
                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withFullScreen('md')(withGStyles(styles)(ProductDetailDialog));
