import {
    MEMORY_UPDATED,
    NEW_MEMORY,
    MEMORY_REMOVED,
    MEMORIES_LOADED,
} from '../actions/Memory.action';
import {
    MemoryUX,
    ModerationCategory,
    isModerationMemoryList,
    isModerationVisitorList,
    MemoryAuthor,
} from '../shared/types';
import { MemoryState } from '../types';
import { MADE_MODERATION_DECISION } from '../actions/Moderation.action';
import { GatherAction } from '../actions';
import { LOADED_PUBLIC_CASE } from '../actions/GatherCase.action';

export const initMemoryState: MemoryState = {
    memoryList: [],
};

export const memoryState = (state: MemoryState = initMemoryState, action: GatherAction): MemoryState => {
    switch (action.type) {
    case LOADED_PUBLIC_CASE:
    case MEMORIES_LOADED: {
        return {
            ...state,
            memoryList: action.memories,
        };
    }
    case NEW_MEMORY: {
        const newMemorylist = [action.newMemory, ...state.memoryList];
        return {
            ...state,
            memoryList: newMemorylist,
        };
    }
    case MEMORY_UPDATED: {
        const memoryListCopy = state.memoryList.map(
            m => m.id === action.updatedMemory.id ? action.updatedMemory : m
        );
        return {
            ...state,
            memoryList: memoryListCopy
        };
    }
    case MEMORY_REMOVED: {
        const updatedMemoryList = state.memoryList.filter(m => m.id !== action.memory.id);

        return {
            ...state,
            memoryList: updatedMemoryList,
        };
    }
    case MADE_MODERATION_DECISION: {
        const { decisions, caseUuid } = action;
        if (!caseUuid) {
            // when moderating at FH or GOM level we don't need to update the case memories
            return state;
        }

        let updatedMemoryList: MemoryUX[] = state.memoryList;

        if (action.category === ModerationCategory.memories && isModerationMemoryList(decisions)) {
            if (action.isApproval) {
                const newMemories = decisions.map(({ memory }): MemoryUX => ({
                    ...memory,
                    author: memory.author && !memory.author.is_pending_decision ? memory.author : null,
                    is_pending_decision: false,
                }));
                updatedMemoryList = [...state.memoryList];
                for (const newMemory of newMemories) {
                    // in the case of approved flagged content, make sure the memory isn't already in the list
                    if (updatedMemoryList.every((existing) => existing.id !== newMemory.id)) {
                        updatedMemoryList.unshift(newMemory);
                    }
                }
            } else {
                updatedMemoryList = state.memoryList.filter((mem) =>
                    decisions.every((d) => d.memory.id !== mem.id)
                );
            }
        } else if (action.category === ModerationCategory.visitors && isModerationVisitorList(decisions)) {
            updatedMemoryList = state.memoryList.map((memory): MemoryUX => {
                const authorDecision = decisions.find((d) => d.visitor.user_id === memory.user_id);
                if (!authorDecision) {
                    return memory;
                }

                // clear out the author if author was blocked, otherwise add the author
                const author: MemoryAuthor | null = !action.isApproval ? null : {
                    ...authorDecision.visitor,
                    mname: null,
                    lname: null,
                    isFHorGOM: false,
                    is_pending_decision: false,
                };
                return {
                    ...memory,
                    author,
                };
            });    
        }
        return {
            ...state,
            memoryList: updatedMemoryList,
        };
    }
    default:
        return state;
    }
};
