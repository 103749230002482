import {
    canManageFuneralHomeUserPermission,
    canRemoveOtherTeamMembers,
    canInviteOtherTeamMembers,
    canBeAssignedToCase,
    canModifyWorkflows,
    canViewCaseFinancialData,
    canViewCaseAutoforms,
    canViewCaseVitalsForAllCases,
    canViewMonthlyFinancialRollup,
    canAccessRevenueReport,
    canAccessPaymentReport,
    canAccessBatchReport,
    canAccessCasesReport,
    canAccessHelpersReport,
    canAccessRolodexReport,
    canAccessKeeptrackReport,
    canCompleteTrackingSteps,
    canSkipTrackingSteps,
    canEditTrackingPrerequisite,
    canDiscountContractItems,
    canModifyContractItemName,
    canDiscountContract,
    canModifyPrices,
    canAddPayments,
    canEditPayments,
    canRemovePayments,
    canEditGPL,
    canUnfreezeContract,
    canAddOneOffContractItems,
    canAddContractItems,
    canRemoveContractItems,
    canAccessCollectionsReport,
    canAccessOverpaidContractsReport,
    canAccessInformantReport,
    canAccessPriceListReport,
    canAccessCaseSummaryReport,
    canAccessHelperReport,
    canAccessMerchSalesReport,
    canAccessDiscountsReport,
    canAccessPriceAdjustmentsReport,
    canAccessWorkflowByCaseReport,
    canAccessSalesTaxReport,
    canAccessDispatchReport,
    canAdminCaseLabels,
    canAccessPrepReport,
    canAccessReportDataSource,
} from '../../../shared/authority/can';
import { getFuneralHomeId, ReportDataSource, EntitySummary, UserProfile } from '../../../shared/types';
import { Permission } from '../../../shared/types/permissions';
import { useGSelector } from '../../../types';

type PermissionCallback = (userData: UserProfile | EntitySummary | null, funeralHomeId: number | null) => boolean;
export const CAN_ACCESS_PERMISSION_LOOKUP: Record<Permission, PermissionCallback> = {
    [Permission.manage_permission]: canManageFuneralHomeUserPermission,
    [Permission.remove_other_team_members]: canRemoveOtherTeamMembers,
    [Permission.invite_other_team_members]: canInviteOtherTeamMembers,
    [Permission.be_assigned_to_case]: canBeAssignedToCase,
    [Permission.modify_workflows]: canModifyWorkflows,
    [Permission.view_case_financial_data]: canViewCaseFinancialData,
    [Permission.view_case_autoforms]: canViewCaseAutoforms,
    [Permission.view_case_vitals]: canViewCaseVitalsForAllCases,
    [Permission.view_monthly_financial_rollup]: canViewMonthlyFinancialRollup,
    [Permission.access_report_revenue]: canAccessRevenueReport,
    [Permission.access_report_payment]: canAccessPaymentReport,
    [Permission.access_report_batch]: canAccessBatchReport,
    [Permission.access_report_cases]: canAccessCasesReport,
    [Permission.access_report_helpers]: canAccessHelpersReport,
    [Permission.access_report_rolodex]: canAccessRolodexReport,
    [Permission.access_report_keeptrack]: canAccessKeeptrackReport,
    [Permission.complete_tracking_steps]: canCompleteTrackingSteps,
    [Permission.skip_tracking_steps]: canSkipTrackingSteps,
    [Permission.edit_tracking_prerequisite]: canEditTrackingPrerequisite,
    [Permission.CONTRACT_ITEM_DISCOUNTS]: canDiscountContractItems,
    [Permission.CONTRACT_MODIFY_ITEM_NAME]: canModifyContractItemName,
    [Permission.CONTRACT_DISCOUNTS]: canDiscountContract,
    [Permission.MODIFY_PRICES]: canModifyPrices,
    [Permission.ADD_PAYMENTS]: canAddPayments,
    [Permission.EDIT_PAYMENTS]: canEditPayments,
    [Permission.REMOVE_PAYMENTS]: canRemovePayments,
    [Permission.EDIT_GPL]: canEditGPL,
    [Permission.CONTRACT_UNFREEZE]: canUnfreezeContract,
    [Permission.CONTRACT_ONE_OFF_ITEMS]: canAddOneOffContractItems,
    [Permission.ADD_ITEMS]: canAddContractItems,
    [Permission.REMOVE_ITEMS]: canRemoveContractItems,
    [Permission.CASE_LABELS]: canAdminCaseLabels,
    [Permission.COLLECTIONS_REPORT]: canAccessCollectionsReport,
    [Permission.OVERPAID_CONTRACTS]: canAccessOverpaidContractsReport,
    [Permission.DISCOUNTS_REPORT]: canAccessDiscountsReport,
    [Permission.PRICE_ADJUSTMENTS_REPORT]: canAccessPriceAdjustmentsReport,
    [Permission.MERCH_SALES_REPORT]: canAccessMerchSalesReport,
    [Permission.HELPER_REPORT]: canAccessHelperReport,
    [Permission.INFORMANT_REPORT]: canAccessInformantReport,
    [Permission.PRICE_LIST]: canAccessPriceListReport,
    [Permission.CASE_SUMMARY]: canAccessCaseSummaryReport,
    [Permission.WORKFLOW_BY_CASE_REPORT]: canAccessWorkflowByCaseReport,
    [Permission.SALES_TAX_REPORT]: canAccessSalesTaxReport,
    [Permission.DISPATCH_REPORT]: canAccessDispatchReport,
    [Permission.PREP_REPORT]: canAccessPrepReport,
    [Permission.DATA_SOURCE_CASE_NON_FINANCIAL]: (
        user: UserProfile | EntitySummary | null,
        funeralHomeId: number | null,
    ) => canAccessReportDataSource({ datasource: ReportDataSource.case_non_financial, user, funeralHomeId }),
    [Permission.DATA_SOURCE_CASE_FINANCIAL]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.case_financial, user, funeralHomeId }),
    [Permission.DATA_SOURCE_CASE_VITALS]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.case_vitals, user, funeralHomeId }),
    [Permission.DATA_SOURCE_PAYMENTS]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.payments, user, funeralHomeId }),
    [Permission.DATA_SOURCE_EVENTS]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.events, user, funeralHomeId }),
    [Permission.DATA_SOURCE_HELPERS]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.helpers, user, funeralHomeId }),
    [Permission.DATA_SOURCE_INVOICE_LINE_ITEMS]: (
        user: UserProfile | EntitySummary | null,
        funeralHomeId: number | null,
    ) => canAccessReportDataSource({ datasource: ReportDataSource.invoice_line_items, user, funeralHomeId }),
    [Permission.DATA_SOURCE_NOTES]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.notes, user, funeralHomeId }),
    [Permission.DATA_SOURCE_TASK_STEP_ACTIVITY]: (
        user: UserProfile | EntitySummary | null,
        funeralHomeId: number | null,
    ) => canAccessReportDataSource({ datasource: ReportDataSource.task_and_step_activity, user, funeralHomeId }),
    [Permission.DATA_SOURCE_REMEMBER_PAGE]: (user: UserProfile | EntitySummary | null, funeralHomeId: number | null) =>
        canAccessReportDataSource({ datasource: ReportDataSource.remember_page, user, funeralHomeId }),
    [Permission.DATA_SOURCE_INSURANCE_POLICIES]: (
        user: UserProfile | EntitySummary | null,
        funeralHomeId: number | null,
    ) => canAccessReportDataSource({ datasource: ReportDataSource.insurance_policies, user, funeralHomeId }),
};

export const usePermissionEnabled = (permission: Permission) => {
    const permissionCallback = CAN_ACCESS_PERMISSION_LOOKUP[permission];
    const enabled = useGSelector(({ userSession, casesState, funeralHomeState }) => {
        const funeralHomeId = getFuneralHomeId(
            funeralHomeState.activeFuneralHome,
            casesState.selectedCase,
            casesState.publicCase,
        );

        return permissionCallback?.(userSession.userData, funeralHomeId) ?? false;
    });

    return enabled;
};
