import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import FindLifeInsuranceMoney from './FindLifeInsuranceMoney';

const LifeInsuranceDialog = (props: TaskDialogComponentProps) => {
    const { activeCase } = props;

    const description = (
        <>
            We believe you should get everything to which you are entitled, especially during
            this difficult time. Unfortunately, many life insurance policies go unpaid simply
            because family members don't know about them. We want to fix this for you and your
            family. We've discovered a resource that will automatically search hundreds of
            different life insurance providers to find any policies that belong
            to {activeCase.fname}. Simply click below to perform a quick
            search and please let me know if there is anything we can do to help.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: 'Search for Life Insurance Money',
        content: <FindLifeInsuranceMoney activeCase={activeCase} />
    }];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: `Find Life Insurance Money`,
                description,
                listItems
            }}
        />
    );
};

export default LifeInsuranceDialog;
