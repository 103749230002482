import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import LockIcon from '@mui/icons-material/Lock';

import { AlbumPhoto as AlbumPhotoType, albumPhotoToGatherPhoto, StoreState } from '../../../../types';
import {
    AlbumDownloadStatus,
    EntityCaseRole,
    GatherCaseUX,
    isAlbumEntry,
    UserRoles
} from '../../../../shared/types';
import {
    PhotoOrientationType,
    PhotoSizeType
} from '../../../../services';
import { SortableContainer, SortEnd, SortEvent, SortEventWithTag } from 'react-sortable-hoc';
import Button from '@mui/material/Button';

import PhotoPopper from '../PhotoPopper';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import classNames from 'classnames';
import PrivateEventUserWidget from '../../remember/events/PrivateEventUserWidget';
import { openHelperInvitationDialog } from '../../../../actions/Dialog.action';
import LockContent from '../LockContent';
import { styleWrapper, StyledProps } from './styles';
import { VirtualListProps, VirtualPhotoList } from './VirtualList';
import { Grid as VirtualGrid } from 'react-virtualized';
import { isEqual, throttle } from 'lodash';
import DownloadSnackbar from './DownloadSnackbar';
import withGStyles from '../../../../styles/WithGStyles';
import withState from '../../../common/utilHOC/WithState';
import { AppDispatch } from '../../../../store';
import GPopper from '../../../common/GPopper';

const mapStateToProps = ({ casesState, userSession, albumState }: StoreState) => {
    const { selectedCase, helpers, casePhotos } = casesState;
    const selectedCaseId = selectedCase?.id;

    return {
        helpers: helpers.filter(h => selectedCaseId && UserRoles.isFamilyOnCase(h, selectedCaseId)),
        userSession,
        albums: albumState.albums,
        casePhotosCount: casePhotos.length,
    };
};

export const VirtualSortableList = SortableContainer<VirtualListProps & StyledProps>(VirtualPhotoList);

export interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    photoOptions?: {
        orientation?: PhotoOrientationType;
        size?: PhotoSizeType;
    };
    albumId: number;
    activeCase?: GatherCaseUX;
    photoList: AlbumPhotoType[];
    isLoading: boolean;
    isLocked: boolean;
    lockUnlockAlbum: (lockStatus: boolean) => void;
    onPhotosReordered?: (sort: SortEnd) => void;
    onAddButtonClicked?: () => void;
    onDownloadClicked?: () => void;
}

interface State {
    popperEl: HTMLElement | null;
    isAllowedOpenDownloadSnackbar: boolean;
    isDownloadConfirmationDialogOpen: boolean;
    isOpenPhotoPopper: boolean;
    activePhotoId: string | null;
    indicatorPopperEl: HTMLDivElement | null;
    photoContainerRef: HTMLDivElement | null;
}

interface CircleRefType {
    [key: number]: HTMLDivElement;
}

class SortablePhotoList extends React.Component<Props & StyledProps, State> {
    circleRef: CircleRefType = {};
    photoRef: CircleRefType = {};
    containerRef: CircleRefType = {};
    editButtonRef: CircleRefType = {};
    photoNumberRef: CircleRefType = {};
    virtualGridRef: VirtualGrid | null = null;

    state: State = {
        isAllowedOpenDownloadSnackbar: false,
        isDownloadConfirmationDialogOpen: false,
        popperEl: null,
        isOpenPhotoPopper: false,
        activePhotoId: '',
        indicatorPopperEl: null,
        photoContainerRef: null
    };

    private throttledResize = throttle(
        () => this.virtualGridRef && this.virtualGridRef.recomputeGridSize(),
        200
    );

    componentDidUpdate(prevProps: Props & StyledProps) {
        const { isLocked, photoList } = this.props;

        if ((prevProps.isLocked !== isLocked
            || !isEqual(prevProps.photoList, photoList))
            && this.virtualGridRef) {
            this.virtualGridRef.recomputeGridSize();
        }
    }

    componentWillUnmount() {
        this.throttledResize.cancel();
    }

    openDownloadPhotosConfirmationDialog = () => {
        this.setState({ isDownloadConfirmationDialogOpen: true });
    };

    closeDownloadPhotosConfirmationDialog = () => {
        this.setState({ isDownloadConfirmationDialogOpen: false });
    };

    closePopper = () => {
        this.setState({
            popperEl: null,
            isOpenPhotoPopper: false
        });
    };

    handleOpenPreparingPhotosSnackbar = () => {
        const { onDownloadClicked } = this.props;

        if (onDownloadClicked) {
            onDownloadClicked();
        }
        this.setState({
            isDownloadConfirmationDialogOpen: false
        });
    };

    renderAddButton() {
        const { classes, onAddButtonClicked, isLocked } = this.props;

        const text = isLocked
            && 'Photos can\'t be added to a locked album'
            || 'Select photos for this album';

        return (
            <div
                className={classes.addButton}
                onClick={e => !isLocked && onAddButtonClicked && onAddButtonClicked()}
            >
                {!isLocked
                    && <AddIcon fontSize="large" color="primary" className={classes.addIcon} />
                    || <LockIcon fontSize="large" color="primary" className={classes.lockIcon} />
                }
                <Typography color="primary">{text}</Typography>
            </div>
        );
    }

    renderFooter = () => {
        const {
            dispatch,
            helpers,
            classes,
            userSession,
            activeCase,
            casePhotosCount,
            isLocked,
            lockUnlockAlbum
        } = this.props;

        const photoText = casePhotosCount > 1
            ? `${casePhotosCount} Photos shared so far`
            : casePhotosCount === 1
                ? '1 Photo shared so far'
                : 'Be the first to share';

        return (
            <Grid item xs={12} className={classes.footer}>
                <Grid item xs={12} className={classes.inviterOtherContainer}>
                    <Typography align="center" color="secondary">
                        Invite others to help share photos:
                    </Typography>
                    <PrivateEventUserWidget
                        openInvitationDialog={() =>
                            dispatch(openHelperInvitationDialog({
                                zIndex: 1330,
                                defaultTab: EntityCaseRole.guest,
                            }))
                        }
                        invitedHelpers={helpers}
                        hasCasePrivileges
                        zIndex={1320}
                        hideAbstarctInfo
                        activeCaseId={activeCase?.id || null}
                        caseDisplayFname={activeCase?.fname || ''}
                    />
                    <Typography align="center" color="secondary">
                        {photoText}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.downloadContainer}>
                    <Typography color="secondary">
                        Click below to download these photos in the order they are arranged above.
                    </Typography>
                    {this.renderDownloadButton()}
                </Grid>

                {activeCase &&
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.background_primary_0_2,
                            classes.lockContentBox
                        )}
                    >
                        <LockContent
                            zIndex={1320}
                            userSession={userSession}
                            activeCase={activeCase}
                            locked={isLocked}
                            lockUnlockAlbum={lockUnlockAlbum}
                        />
                    </Grid>
                }
            </Grid>
        );
    };

    // this will stop sorting if the user clicks on non-blurred photo or task is locked
    shouldCancelStart = (e: SortEvent | SortEventWithTag) => {
        const { isLocked } = this.props;

        if (isLocked) {
            return true;
        }

        const element = 'tagName' in e.target && e.target.tagName === 'IMG'
            && e.target as HTMLImageElement
            || null;
        const elementId = element && element.id;

        return !!(elementId && elementId.includes('mainPhoto'));
    };

    renderVirtualSortableList = (containerRef?: HTMLDivElement) => {
        const {
            photoList,
            onPhotosReordered,
            classes,
            isLocked
        } = this.props;
        const { isOpenPhotoPopper, activePhotoId } = this.state;

        return (
            <VirtualSortableList
                axis="xy"
                key={`sortableContainer-${containerRef?.nodeName ?? ''}`}
                isLocked={isLocked}
                items={photoList}
                getContainer={containerRef ? el => containerRef : undefined}
                useWindowAsScrollContainer
                renderAddButton={this.renderAddButton()}
                setPhotoCircleRef={this.setPhotoCircleRef}
                classes={classes}
                isOpenPhotoPopper={isOpenPhotoPopper}
                activePhotoId={activePhotoId}
                setPhotoRef={this.setPhotoRef}
                shouldCancelStart={(e) => this.shouldCancelStart(e)}
                setEditButtonRef={this.setEditButtonRef}
                setPhotoNumberRef={this.setPhotoNumberRef}
                setVirtualGridRef={ref => this.virtualGridRef = ref}
                resizeHandler={this.throttledResize}
                setContainerRef={(ref, index) => {
                    if (ref) {
                        this.containerRef[index] = ref;
                    }
                }}
                updateBeforeSortStart={(sort, event) => {
                    this.setState({
                        isOpenPhotoPopper: false,
                        popperEl: null,
                        indicatorPopperEl: null
                    });

                    if (this.editButtonRef && this.editButtonRef[sort.index]) {
                        this.editButtonRef[sort.index].classList.add(classes.opacity0);
                    }
                    if (this.photoNumberRef && this.photoNumberRef[sort.index]) {
                        this.photoNumberRef[sort.index].classList.add(classes.opacity0);
                    }
                    if (this.photoRef && this.photoRef[sort.index]) {
                        this.photoRef[sort.index].style.transform = 'scale(1.04)';
                    }
                    if (this.containerRef && this.containerRef[sort.index] && !isLocked) {
                        this.containerRef[sort.index].style.cursor = 'grabbing';
                    }
                }}
                onSortEnd={(sort, event) => {
                    if (onPhotosReordered) {
                        onPhotosReordered(sort);
                    }
                    if (this.virtualGridRef) {
                        this.virtualGridRef.recomputeGridSize();
                    }
                    if (this.editButtonRef && this.editButtonRef[sort.newIndex]) {
                        this.editButtonRef[sort.newIndex].classList.remove(classes.opacity0);
                    }
                    if (this.photoNumberRef && this.photoNumberRef[sort.newIndex]) {
                        this.photoNumberRef[sort.newIndex].classList.remove(classes.opacity0);
                    }
                    if (this.photoRef && this.photoRef[sort.newIndex]) {
                        this.photoRef[sort.newIndex].style.transform = 'scale(1)';
                    }
                    if (this.containerRef && this.containerRef[sort.newIndex] && !isLocked) {
                        this.containerRef[sort.newIndex].style.cursor = 'grab';
                    }
                }}
                openPopper={(e, photoId, index) => this.togglePhotoPopper(e, photoId, index)}
            />
        );
    };

    renderPhotoList = () => {
        const {
            classes,
            onAddButtonClicked,
            isLocked
        } = this.props;
        const { photoContainerRef } = this.state;

        return (
            <div className={classes.mainContainer} ref={ref => {
                if (ref && !this.state.photoContainerRef) {
                    this.setState({ photoContainerRef: ref });
                }
            }}>
                {!isLocked &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onAddButtonClicked && onAddButtonClicked()}
                        className={classes.selectPhotoButton}
                    >
                        <AddIcon />&nbsp;Select Photos
                    </Button>
                }
                <Grid item xs={12} className={classes.listContainer}>
                    {/* using 'useWindowAsScrollContainer' prop will use window's dimensions while sorting,
                        this causes the sortable items not wrapping to the next row, because window width is
                        greater than container width, so we need to give container explicitly */}
                    {photoContainerRef
                        ? this.renderVirtualSortableList(photoContainerRef)
                        : this.renderVirtualSortableList()
                    }
                </Grid>

                {this.renderFooter()}
            </div>
        );
    };

    renderDownloadPhotosConfirmationDialog = () => {
        const { photoList } = this.props;
        const { isDownloadConfirmationDialogOpen } = this.state;
        const count = photoList ? photoList.length : 0;
        const downloadDialogHeader = `Download the ${count} photos in this album`;
        const dialogSubHeader = `This will download these ${count} photos into a zipped folder onto your
device so that you can use them as needed. This action will maintain the order of the photos listed on this
page.This may take a few moments to complete. Feel free to navigate away from this page while your photos
are being prepared and they will be waiting for you when you come back!`;
        const downloadDialogConfirmationButton = `Download ${count} Photos`;
        const cancelButtonText = 'Nevermind';

        return (
            <ConfirmationDialog
                header={downloadDialogHeader}
                subHeader={dialogSubHeader}
                confirmationButtonText={downloadDialogConfirmationButton}
                cancelButtonText={cancelButtonText}
                onClose={this.closeDownloadPhotosConfirmationDialog}
                open={isDownloadConfirmationDialogOpen}
                onConfirm={this.handleOpenPreparingPhotosSnackbar}
                zIndex={1350}
            />
        );
    };

    renderDownloadButton = () => {
        const { photoList, classes, albumId, albums } = this.props;
        // so we enable download if not present
        const { status } = (albums[albumId] && albums[albumId].downloadState) || AlbumDownloadStatus.completed;
        const downloadDisabled =
            status === AlbumDownloadStatus.pending || status === AlbumDownloadStatus.started ||
            photoList.length === 0;

        return (
            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={e => this.openDownloadPhotosConfirmationDialog()}
                    className={classNames(
                        classes.downloadButton,
                        downloadDisabled ? classes.disabledDownloadButton : ''
                    )}
                    disabled={downloadDisabled}
                >
                    Download {photoList.length ? photoList.length : ''} Photos&nbsp;
                    <ArchiveIcon color="primary" />
                </Button>
            </Grid>
        );
    };

    renderLoader = (text: string = 'Loading...') => {
        const { classes } = this.props;

        return (
            <div className={classes.loaderContainer}>
                <CircularProgress
                    color="primary"
                    size={86}
                    thickness={3}
                />
                <Typography
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    {text}
                </Typography>
            </div>
        );
    };

    renderPopper = () => {
        const { indicatorPopperEl } = this.state;
        const { classes, isLocked } = this.props;

        const content = isLocked
            && 'This album is currently locked'
            || 'Click and hold here to reorder';

        return (
            <GPopper
                anchorEle={indicatorPopperEl}
                popperContentClass={classes.popperContent}
                paperClass={classes.popoverPaper}
                zIndex={1320}
                closePopper={this.indicatorPopperClickAway}
                placement='top'
            >
                <Typography color="primary" align="center">
                    {content}
                </Typography>
            </GPopper>
        );
    };

    render() {
        const { isLoading, classes, photoList, activeCase, albumId } = this.props;
        const { popperEl, isOpenPhotoPopper } = this.state;

        if (!activeCase) {
            return;
        }
        const activePhoto = this.findActivePhoto();
        const gatherPhotoList = photoList.map(albumPhotoToGatherPhoto);
        const timeSinceUploaded = activePhoto && isAlbumEntry(activePhoto.photo) &&
            activePhoto.photo.timeSinceUploaded || undefined;
        return (
            <>
                <Grid item xs={12} className={classes.sizeAdjust}>
                    {isLoading ? this.renderLoader() : this.renderPhotoList()}
                    <DownloadSnackbar
                        caseUuid={activeCase.uuid}
                        albumId={albumId}
                        zIndex={1330 + 1}
                    />
                    {this.renderDownloadPhotosConfirmationDialog()}
                </Grid>
                <PhotoPopper
                    key={activePhoto?.photo?.id}
                    activeCase={activeCase}
                    activePhoto={activePhoto}
                    albumId={albumId}
                    photoList={gatherPhotoList}
                    zIndex={1350}
                    popperEl={popperEl}
                    isOpenPhotoPopper={isOpenPhotoPopper}
                    timeSinceUploaded={timeSinceUploaded}
                    closePopper={this.closePopper}
                />
                {this.renderPopper()}
            </>
        );
    }

    indicatorPopperClickAway = () => {
        this.setState({
            indicatorPopperEl: null,
        });
    };

    setPhotoCircleRef = (ref: HTMLDivElement | null, index: number) => {
        if (ref) {
            this.circleRef[index] = ref;
        }
    };

    setEditButtonRef = (ref: HTMLDivElement | null, index: number) => {
        if (ref) {
            this.editButtonRef[index] = ref;
        }
    };

    setPhotoNumberRef = (ref: HTMLDivElement | null, index: number) => {
        if (ref) {
            this.photoNumberRef[index] = ref;
        }
    };

    setPhotoRef = (ref: HTMLDivElement | null, index: number) => {
        if (ref) {
            this.photoRef[index] = ref;
        }
    };

    findActivePhoto = () => {
        const { photoList } = this.props;
        const { activePhotoId } = this.state;

        const photo = photoList.find(e => e.gatherId === activePhotoId);
        return photo ? albumPhotoToGatherPhoto(photo) : null;
    };

    togglePhotoPopper = (e: React.MouseEvent<HTMLElement>, photoId: string | null, index: number) => {
        const { isLocked } = this.props;
        const { activePhotoId } = this.state;
        e.persist();

        let timeout = 0;

        if (isLocked) {
            if (activePhotoId) {
                timeout = 300;
                this.setState({ indicatorPopperEl: null, activePhotoId: null });
            }
            setTimeout(
                () => {
                    this.setState({
                        indicatorPopperEl: this.circleRef[index - 1] ? this.circleRef[index - 1] : null,
                        activePhotoId: photoId
                    });
                },
                timeout
            );

            return;
        }

        timeout = 0;
        if (activePhotoId) {
            timeout = 300;

            if (activePhotoId === photoId) {
                this.setState({
                    popperEl: e.currentTarget,
                    activePhotoId: photoId,
                    indicatorPopperEl: this.circleRef[index - 1] ? this.circleRef[index - 1] : null,
                    isOpenPhotoPopper: true
                });
                return;
            } else {
                this.setState({
                    popperEl: null,
                    isOpenPhotoPopper: false,
                    indicatorPopperEl: null
                });
            }
        }

        setTimeout(
            (event, pid, idx) => {
                this.setState({
                    popperEl: event.target,
                    activePhotoId: pid,
                    indicatorPopperEl: this.circleRef[idx - 1] ? this.circleRef[idx - 1] : null
                });

                this.setState((prevState) => ({
                    isOpenPhotoPopper: !prevState.isOpenPhotoPopper
                }));
            },
            timeout,
            e,
            photoId,
            index
        );

    };
}

export default withState(mapStateToProps)(withGStyles(styleWrapper<Props>())(SortablePhotoList));
