import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConsumableSearchParam } from '../../../shared/types';

function useConsumeSearchParam(paramKey: ConsumableSearchParam, deleteParam: boolean = true): string | null {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramValue = searchParams.get(paramKey) ?? '';

    // if the param is found then remove it from the URL and return the param value
    useEffect(() => {
        if (paramValue && deleteParam) {
            searchParams.delete(paramKey);
            setSearchParams(searchParams, { replace: true });
        }
    }, [paramValue, searchParams, paramKey, deleteParam, setSearchParams]);

    return paramValue || null;
}

export default useConsumeSearchParam;
