import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import {
    EntitySummary,
    FuneralHomeUXPreview,
    getTypeGuard,
    getValidator,
    ReportDateRange,
    ReportRecord,
    REPORT_DATE_RANGE,
} from '.';

// TODO - remove this with full reporting solution
export const CURSED_REPORT_UUID = '959dd517-923a-415f-8fb6-6e47f0760b79';

export enum Occurrence {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
}

export enum Weekday {
    Mon = 'Mon',
    Tue = 'Tue',
    Wed = 'Wed',
    Thu = 'Thu',
    Fri = 'Fri',
    Sat = 'Sat',
    Sun = 'Sun',
}

export enum Month {
    Jan = 'Jan',
    Feb = 'Feb',
    Mar = 'Mar',
    Apr = 'Apr',
    May = 'May',
    Jun = 'Jun',
    Jul = 'Jul',
    Aug = 'Aug',
    Sep = 'Sep',
    Oct = 'Oct',
    Nov = 'Nov',
    Dec = 'Dec',
}

export enum Cadence {
    EveryMonth = 'EveryMonth',
    EveryQuarter = 'EveryQuarter',
    SpecificMonths = 'SpecificMonths',
}

export const CadenceDisplayLookup: Record<Cadence, string> = {
    [Cadence.EveryMonth]: 'Every Month',
    [Cadence.EveryQuarter]: 'At the start of every quarter',
    [Cadence.SpecificMonths]: 'On specific months',
};

const CadenceType = t.keyof({
    [Cadence.EveryMonth]: null,
    [Cadence.EveryQuarter]: null,
    [Cadence.SpecificMonths]: null,
});

const MonthType = t.keyof({
    [Month.Jan]: null,
    [Month.Feb]: null,
    [Month.Mar]: null,
    [Month.Apr]: null,
    [Month.May]: null,
    [Month.Jun]: null,
    [Month.Jul]: null,
    [Month.Aug]: null,
    [Month.Sep]: null,
    [Month.Oct]: null,
    [Month.Nov]: null,
    [Month.Dec]: null,
});

const OccurrenceType = t.keyof({
    [Occurrence.Daily]: null,
    [Occurrence.Weekly]: null,
    [Occurrence.Monthly]: null,
});

const WeekdayType = t.keyof({
    [Weekday.Mon]: null,
    [Weekday.Tue]: null,
    [Weekday.Wed]: null,
    [Weekday.Thu]: null,
    [Weekday.Fri]: null,
    [Weekday.Sat]: null,
    [Weekday.Sun]: null,
});

export const TIME_FORMAT_FOR_UID_SCHEDULE_FOR_CRON = 'HH:mm';

const UIScheduleForCronType = t.type({
    time: t.string, // HH:mm (24hr), i.e. 20:15
    timezone: t.string,
    day: t.union([t.number, t.null]),
    cadence: t.union([CadenceType, t.null]),
    weekdays: t.array(WeekdayType),
    months: t.array(MonthType),
    occurrence: OccurrenceType,
});
export interface UIScheduleForCron extends t.TypeOf<typeof UIScheduleForCronType> {}
export class UIScheduleForCron {
    public static fromRequest = getValidator<UIScheduleForCron>(UIScheduleForCronType);
}

export interface ReportScheduleRecord {
    id: number;
    uuid: string;
    description: string;
    report_id: number;
    cron: string;
    ui_schedule_for_cron: UIScheduleForCron | null;
    static_query: string | null;
    additional_emails: string;
    timezone: string;
    date_type: string;
    date_range: ReportDateRange;
    custom_from_date: Date | null;
    custom_to_date: Date | null;
    arn: string | null;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    owned_by: number;
    is_enabled: boolean;
}

export interface ReportScheduleRecipientRecord {
    report_schedule_id: number;
    user_profile_id: number;
}

export interface ReportScheduleFuneralHomeRecord {
    report_schedule_id: number;
    funeral_home_id: number;
}

export interface ReportScheduleInsertRecord
    extends Pick<
        ReportScheduleRecord,
        | 'description'
        | 'report_id'
        | 'cron'
        | 'ui_schedule_for_cron'
        | 'static_query'
        | 'additional_emails'
        | 'date_type'
        | 'date_range'
        | 'custom_from_date'
        | 'custom_to_date'
        | 'created_by'
        | 'updated_by'
        | 'owned_by'
        | 'timezone'
    > {}

export interface ReportScheduleUpdateRecord
    extends Pick<ReportScheduleRecord, 'updated_by' | 'updated_time'>,
        Partial<
            Pick<
                ReportScheduleRecord,
                | 'description'
                | 'cron'
                | 'static_query'
                | 'additional_emails'
                | 'arn'
                | 'timezone'
                | 'date_type'
                | 'date_range'
                | 'custom_from_date'
                | 'custom_to_date'
                | 'owned_by'
                | 'is_enabled'
                | 'deleted_by'
                | 'deleted_time'
            >
        > {}

const ReportScheduleCURSEDRequestType = t.type({
    description: t.string,
    cron: t.string,
    static_query: t.string,
    additional_emails: t.string,
    timezone: t.string,
    is_enabled: t.boolean,
    recipient_user_ids: t.array(t.number),
});
export interface ReportScheduleCURSEDRequest extends t.TypeOf<typeof ReportScheduleCURSEDRequestType> {}
export class ReportScheduleCURSEDRequest {
    public static fromRequest = getValidator<ReportScheduleCURSEDRequest>(ReportScheduleCURSEDRequestType);
    public static is = getTypeGuard<ReportScheduleCURSEDRequest>(ReportScheduleCURSEDRequestType);
}

const ReportScheduleRequestRequired = t.type({
    description: t.string,
    cron: t.string,
    ui_schedule_for_cron: UIScheduleForCronType,
    additional_emails: t.string,
    timezone: t.string,
    is_enabled: t.boolean,
    recipient_user_ids: t.array(t.number),
    funeral_home_ids_in_report: t.array(t.number),
    custom_from_date: t.union([DateFromISOString, t.null]),
    custom_to_date: t.union([DateFromISOString, t.null]),
    date_type: t.string,
    date_range: REPORT_DATE_RANGE,
});
const ReportScheduleRequestOptional = t.partial({
    owned_by_user_id: t.number,
});
const ReportScheduleRequestType = t.intersection([ReportScheduleRequestRequired, ReportScheduleRequestOptional]);

export interface ReportScheduleRequest extends t.TypeOf<typeof ReportScheduleRequestType> {}
export class ReportScheduleRequest {
    public static fromRequest = getValidator<ReportScheduleRequest>(ReportScheduleRequestType);
    public static is = getTypeGuard<ReportScheduleRequest>(ReportScheduleRequestType);
}

/* ReportSendNowRequest */
const ReportSendNowRequestRequired = t.type({
    additional_emails: t.array(t.string),
    funeral_home_ids_in_report: t.array(t.number),
    recipient_user_ids: t.array(t.number),
    custom_from_date: t.union([DateFromISOString, t.null]),
    custom_to_date: t.union([DateFromISOString, t.null]),
    date_type: t.string,
    date_range: REPORT_DATE_RANGE,
    timezone: t.string,
});
const ReportSendNowRequestOptional = t.partial({
    run_as_user_id: t.number,
});
const ReportSendNowRequestType = t.intersection([ReportSendNowRequestRequired, ReportSendNowRequestOptional]);
export interface ReportSendNowRequest extends t.TypeOf<typeof ReportSendNowRequestType> {}
export class ReportSendNowRequest {
    public static fromRequest = getValidator<ReportSendNowRequest>(ReportSendNowRequestType);
}

/* HeadlessReportEventData */
const HeadlessReportEventDataType = t.type({
    detail: t.type({
        rowCount: t.number,
        data: t.string,
    }),
});
export interface HeadlessReportEventData extends t.TypeOf<typeof HeadlessReportEventDataType> {}
export class HeadlessReportEventData {
    public static fromRequest = getValidator<HeadlessReportEventData>(HeadlessReportEventDataType);
}

export enum ReportStatus {
    pending = 'pending',
    running = 'running',
    success = 'success',
    failed = 'failed',
    stale = 'stale',
}

export enum ReportLaunchType {
    manual = 'manual',
    scheduled = 'scheduled',
    scheduled_now = 'scheduled_now',
}

export interface ReportExecutionRecord {
    id: number;
    report_id: number;
    launch_type: ReportLaunchType;
    status: ReportStatus;
    report_schedule_id: number | null;
    cron: string | null;
    created_by: number;
    created_time: Date;
    error: string | null;
    file_size: number | null;
    run_start_time: Date | null;
    run_end_time: Date | null;
    run_duration_msec: number | null;
}

export interface ReportExecutionUX
    extends Pick<
        ReportExecutionRecord,
        'run_start_time' | 'run_end_time' | 'run_duration_msec' | 'status' | 'error' | 'file_size' | 'launch_type'
    > {}

export interface ReportScheduleUXRecord
    extends Pick<
        ReportScheduleRecord,
        | 'id'
        | 'uuid'
        | 'description'
        | 'report_id'
        | 'cron'
        | 'ui_schedule_for_cron'
        | 'static_query'
        | 'additional_emails'
        | 'arn'
        | 'created_by'
        | 'created_time'
        | 'updated_by'
        | 'updated_time'
        | 'deleted_by'
        | 'deleted_time'
        | 'owned_by'
        | 'date_type'
        | 'date_range'
        | 'custom_from_date'
        | 'custom_to_date'
        | 'is_enabled'
        | 'timezone'
    > {
    report_uuid: ReportRecord['uuid'];
    last_execution: ReportExecutionUX | null;
    funeral_home_ids_in_report: number[];
    recipient_entity_ids: number[];
}

export interface ReportScheduleUX extends ReportScheduleUXRecord {
    recipients: EntitySummary[];
    funeral_homes_in_report: FuneralHomeUXPreview[];
}

export interface ReportExecutionInsertRecord
    extends Pick<
        ReportExecutionRecord,
        'report_id' | 'launch_type' | 'report_schedule_id' | 'cron' | 'created_by' | 'status' | 'run_start_time'
    > {
    error?: string;
    run_end_time?: Date;
    run_duration_msec?: number;
}

export interface ReportExecutionUpdateRecord
    extends Partial<
        Pick<
            ReportExecutionRecord,
            'status' | 'error' | 'file_size' | 'run_start_time' | 'run_end_time' | 'run_duration_msec'
        >
    > {}
