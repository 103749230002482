import { useMemo, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { GREEN_COLOR_4 } from '../../../../../constants/colorVariables';
import makeGStyles from '../../../../../styles/makeGStyles';
import SaveStepDialog from '../saveStepDialog/SaveStep.dialog';
import {
    TaskTemplateCreateRequest,
    TaskTemplateSummary,
    WorkflowTaskForWorkflowUX,
} from '../../../../../shared/types';
import { TrackingStepIconLookup } from '../saveStepDialog/trackingStepIcon.list';
import AvailableDefaultItemsDialog from '../../../../common/AvailableDefaultItemsDialog/AvailableDefaultItems.dialog';
import classNames from 'classnames';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import { deleteTaskTemplate } from '../../../../../actions/task/TaskTemplate.action';
import { useGDispatch } from '../../../../../types';
import GSwitch from '../../../../common/inputElements/GSwitch';

const useStyles = makeGStyles(theme => ({
    root: {},
    stepIconContainer: {
        minWidth: 52,
        maxWidth: 52,
        minHeight: 52,
        maxHeight: 52,
        borderRadius: '50%',
        marginLeft: 12,
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
    },
    stepIcon: {
        color: theme.palette.common.white,
        fontSize: 44,
    },
    stepImageBorder: {
        borderRadius: 6,
        position: 'relative',
        '& img': {
            width: 48,
            borderRadius: 4
        }
    },
    addlocationIcon: {
        top: -11,
        left: -17,
        zIndex: 1,
        fontSize: 32,
        position: 'absolute',
        color: GREEN_COLOR_4
    },
    addSharedIcon: {
        top: -4,
        left: -8,
        width: 14,
        height: 14,
        borderRadius: 50,
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
    },
    borderGreen: {
        border: `2px solid ${GREEN_COLOR_4}`
    },
    sharedLocation: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            color: GREEN_COLOR_4,
            fontSize: 18,
            marginBottom: 3
        },
        '& span': {
            color: GREEN_COLOR_4
        },
        '@media (min-width: 375px)': {
            marginLeft: 6,
            marginRight: -14
        },
        '@media (min-width: 480px)': {
            marginLeft: 16
        }
    },
    FiberManualRecordIcon: {
        color: `${theme.palette.secondary.main} !important`,
        fontSize: `10px !important`,
        marginBottom: 'unset !important',
        '@media (min-width: 375px)': {
            marginRight: 6
        },
        '@media (min-width: 480px)': {
            marginRight: 16
        }

    },
    displayFlex: {
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 375px)': {
            flexDirection: 'unset'
        }
    },
    opacity40: {
        '& svg': {
            opacity: 0.4,
        },
    },
    height34: {
        minHeight: 34,
        maxHeight: 34,
    },
    gSwitchContainer: {
        width: 'fit-content',
        margin: '0 auto',
    },
    endAdornmentClass: {
        '&:hover $deleteIconBtn': {
            opacity: 0.4,
        },
    },
    deleteIconBtn: {},
}), { name: 'SelectActionStepDialog' });

interface Props {
    zIndex: number;
    isOpen: boolean;
    availableSteps: TaskTemplateSummary[];
    selectedSteps: WorkflowTaskForWorkflowUX[];
    funeralHomeId: number;
    hideDelete?: boolean;
    sortSelectedStepsOnEnd?: boolean;
    closeDialog: () => void;
    onAddExisting: (task: TaskTemplateSummary) => Promise<void>;
    onCreate?: (createRequest: TaskTemplateCreateRequest) => void;
}

const SelectActionStepDialog = (props: Props) => {
    const {
        isOpen,
        zIndex,
        availableSteps,
        selectedSteps,
        hideDelete,
        closeDialog,
        onAddExisting,
        onCreate,
        funeralHomeId,
        sortSelectedStepsOnEnd
    } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const [addActionDialogOpen, setAddActionDialogOpen] = useState(false);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
    const [activeAction, setActiveAction] = useState<TaskTemplateSummary>();
    const [hideOrphanedActions, setHideOrphanedActions] = useState(true);
    const selectedTaskIds = useMemo(() => selectedSteps.map((s) => s.task.id), [selectedSteps]);

    const filteredItems = useMemo(() => {
        const items = hideOrphanedActions
            ? availableSteps.filter(step => step.workflows.length > 0)
            : availableSteps;

        if (sortSelectedStepsOnEnd) {
            return items.sort(item => {
                if (selectedTaskIds.find(id => id === item.id)) {
                    return 1;
                }
                return -1;
            });
        }

        return items;
    }, [hideOrphanedActions, selectedTaskIds, sortSelectedStepsOnEnd, availableSteps]);

    const doesTaskIdExist = (task: TaskTemplateSummary) => !selectedTaskIds.find(taskId => taskId === task.id);

    const toolTip = `For convenience, this will hide any actions that are not currently being used in a WorkFlow`;
    const deleteToolTip = 'To delete this action, it must first be removed from all WorkFlows.';
    const getTitleIcon = (actionStep: TaskTemplateSummary) => {
        const Icon = actionStep.icon
            && TrackingStepIconLookup[actionStep.icon];

        return (
            <Grid container alignItems="center" justifyContent="center" className={classes.stepIconContainer}>
                {Icon && <Icon className={classes.stepIcon} />}
            </Grid>
        );
    };

    const getSubTitle = (action: TaskTemplateSummary) => {
        let usedOnStr: string = '';
        if (action.workflows.length > 0) {
            const mappedWorkflows = action.workflows.map((w) =>
                `${w.name}${w.is_mirrored ? ' (Mirrored)' : ''}`
            );
            usedOnStr = `Used on: ${mappedWorkflows.join(', ')}`;
        }
        return (
            <ListItemText
                primary={action.title}
                secondary={
                    <span className={classes.displayFlex}>
                        <span>
                            {usedOnStr}
                        </span>
                    </span>
                }
            />
        );
    };

    const handleNewItemClick = () => {
        setAddActionDialogOpen(true);
        closeDialog();
    };

    const openDeleteConfirmationDialog = (action: TaskTemplateSummary) => {
        setIsDeleteConfirmationDialogOpen(true);
        setActiveAction(action);
    };

    const deleteTask = () => {
        if (activeAction) {
            dispatch(deleteTaskTemplate(activeAction, funeralHomeId));
        }
        setIsDeleteConfirmationDialogOpen(false);
    };

    const getGSwitch = () => {

        return (
            <Tooltip
                title={toolTip}
                enterDelay={400}
                enterNextDelay={400}
                placement="top"
            >
                <div className={classes.gSwitchContainer}>
                    <GSwitch
                        formControlLabelClasses={classes.height34}
                        checked={hideOrphanedActions}
                        label={`Hide Orphaned Actions`}
                        labelClasses={classes.colorPrimary}
                        onChangeCallBack={(isChecked) => setHideOrphanedActions(isChecked)}
                        disableToggleOnTextClick
                    />
                </div>
            </Tooltip>
        );
    };

    return (
        <>
            <AvailableDefaultItemsDialog
                itemType="Available Action Steps"
                isDialogOpen={isOpen}
                selectedItemIds={selectedTaskIds}
                availableItems={filteredItems}
                zIndex={zIndex}
                closeDialog={closeDialog}
                createNewButtonText="CREATE NEW ACTION STEP"
                onDefaultItemAdd={onAddExisting}
                onCreateNewItemClick={onCreate ? handleNewItemClick : undefined}
                getTitleIcon={getTitleIcon}
                getTitle={getSubTitle}
                canAddMultiple
                getSubHeader={getGSwitch}
                getClassName={(action) => {
                    const hasWorkflow = action.workflows.length > 0;
                    return (
                        (hasWorkflow && !doesTaskIdExist(action)) && classes.endAdornmentClass || ''
                    );
                }}
                getEndAdornment={hideDelete ? undefined : (action) => {
                    const hasWorkflow = action.workflows.length > 0;
                    return (
                        <Tooltip
                            title={hasWorkflow ? deleteToolTip : ''}
                            placement='top'
                        >
                            <span>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!hasWorkflow) {
                                            openDeleteConfirmationDialog(action);
                                        }
                                    }}
                                    disabled={hasWorkflow}
                                    className={classNames(
                                        classes.deleteIconBtn,
                                        (hasWorkflow && doesTaskIdExist(action)) && classes.opacity40,
                                    )}
                                >
                                    <DeleteForeverIcon className={classes.colorRed} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    );
                }}
            />

            {onCreate &&
                <SaveStepDialog
                    isOpen={addActionDialogOpen}
                    zIndex={zIndex + 1}
                    onCreate={onCreate}
                    closeDialog={() => setAddActionDialogOpen(false)}
                />
            }

            {!hideDelete &&
                <ConfirmationDialog
                    zIndex={zIndex + 2}
                    header="Are you sure?"
                    subHeader={`Are you sure you want to permanently delete the "${activeAction?.title || ''}" action?`}
                    open={isDeleteConfirmationDialogOpen}
                    onClose={() => setIsDeleteConfirmationDialogOpen(false)}
                    onConfirm={() => deleteTask()}
                    cancelButtonText="Cancel"
                    confirmationButtonText="Delete Forever"
                />
            }
        </>
    );
};

export default SelectActionStepDialog;
