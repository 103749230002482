import { Divider, Typography, styled } from "@mui/material";

export const SectionTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: 16,
    textTransform: 'capitalize'
}));

export const OverviewDivider = styled(Divider)({
    margin: '10px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
});