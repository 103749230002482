/* eslint-disable max-len */
import { Component } from 'react';
import classNames from 'classnames';
/* tslint:disable */
export default class SignDigitallySVG extends Component<{ themeFillClass: string }> {
    render() {
        const { themeFillClass } = this.props;
        return (

            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 243.5307617 75.8134766">
                <defs>
                    <style type="text/css" dangerouslySetInnerHTML={{
                        __html: `
                        .st0{opacity:0.15;fill:#005CEF;}
                        .st1{fill:#FFFFFF;}
                        .st2{opacity:0.55;fill:#005CEF;}
                        .st3{fill:#0264CA;}
                        .st4{fill:#303030;}
                        .st5{fill:#005CEF;}
                        .st6{opacity:0.7;fill:#005CEF;}
                        .st7{opacity:0.7;}
                        .st8{opacity:0.35;fill:#005CEF;}
                        .st9{fill:#D0D2D3;}
                        .st10{opacity:0.7;fill:#FFFFFF;}
                    `}} />
                </defs>
                <title>SignDigitally</title>
                <g>
                    <g>
                        <path className={classNames('st0', themeFillClass)} d="M226.3676758,37.590332c0,1.0109863,0.4034424,1.404541,1.439209,1.404541
                            c0.7719727,0,1.3164062-0.7460938,1.6375732-1.371582c0.3967285-0.7739258,0.6568604-1.765625,0.7423096-2.8005371
                            C227.762207,35.3198242,226.3676758,36.2897949,226.3676758,37.590332z"/>
                        <path className="st1" d="M184.793457,52.3369141c0-4.6425781,2.9494629-8.598877,7.0686035-10.1242676l0.8076172-8.8220215
                            h-24.4709473l-0.317749,3.578125h0.946167v1h-1.0349121l-2.717041,30.5996094h24.3742676l0.6367188-6.9553223
                            C186.9233398,59.7263184,184.793457,56.2800293,184.793457,52.3369141z M173.8271484,37.96875h-2v-1h2V37.96875z
                            M178.8271484,37.96875h-2v-1h2V37.96875z M181.6739502,38.9499512c-0.8178711,0-1.480835-0.6630859-1.480835-1.480957
                            s0.6629639-1.480957,1.480835-1.480957s1.480835,0.6630859,1.480835,1.480957S182.4918213,38.9499512,181.6739502,38.9499512z"/>
                        <polygon className={classNames('st2', themeFillClass)} points="193.1136475,28.5410156 168.6293945,28.5410156 168.3319092,31.890625 
                            192.8070068,31.890625"/>
                        <path className={classNames('st2', themeFillClass)} d="M164.588623,74.0488281h24.3591309l0.3642578-3.9804688h-24.369873
                            L164.588623,74.0488281z M176.1245117,71.0776367c0.5681152,0,1.0285645,0.4606934,1.0285645,1.0285645
                            c0,0.5681152-0.4604492,1.0285645-1.0285645,1.0285645c-0.5679932,0-1.0285645-0.4604492-1.0285645-1.0285645
                            C175.0959473,71.5383301,175.5565186,71.0776367,176.1245117,71.0776367z"/>
                        <path className={classNames('st3', themeFillClass)} d="M190.4543457,74.0488281h0.295166l1.0541992-11.6081543
                            c-0.092041-0.034668-0.1865234-0.0646973-0.2773438-0.1018066L190.4543457,74.0488281z"/>
                        <path className={classNames('st3', themeFillClass)} d="M194.7702637,29.7741699l-0.2407227-0.2421875l-1.1190186,12.2243652
                            c0.0904541-0.0187988,0.1842041-0.0270996,0.2755127-0.043457L194.7702637,29.7741699z"/>
                        <circle className="st4" cx="176.1245117" cy="72.1062012" r="1.0285645" />
                        <polygon className={classNames('st5', themeFillClass)} points="22.7790527,70.90625 23.0588379,74.0488281 62.9492188,74.0488281 
                            62.9492188,70.90625"/>
                        <rect x="62.9492188" y="70.90625" className={classNames('st2', themeFillClass)} width="77.0151367" height="3.1425781" />
                        <path className={classNames('st5', themeFillClass)} d="M116.0368652,54.9404297h-3.7770996l0.918457,10.3662109
                            c0.0010986,0.0114746-0.0041504,0.0217285-0.0036621,0.0334473c0.0004883,0.0114746,0.0065918,0.0212402,0.0065918,0.032959
                            c0,0.0400391-0.0167236,0.074707-0.0228271,0.1132812c-0.0079346,0.050293-0.012085,0.1000977-0.0296631,0.1467285
                            c-0.0167236,0.0449219-0.0428467,0.0820312-0.0673828,0.1223145c-0.0257568,0.0422363-0.0483398,0.0847168-0.0817871,0.1210938
                            c-0.0313721,0.0341797-0.0693359,0.0585938-0.1066895,0.0866699c-0.0395508,0.0297852-0.076416,0.0605469-0.1220703,0.0822754
                            c-0.0439453,0.0209961-0.0922852,0.0292969-0.140625,0.0415039c-0.0383301,0.0095215-0.0717773,0.029541-0.1125488,0.0332031
                            c-0.0219727,0.001709-0.0440674,0.0026855-0.0661621,0.0026855c0,0,0,0.0002441-0.0002441,0.0002441h-0.0009766H27.109375
                            c0,0,0-0.0002441-0.0002441-0.0002441c-0.0003662,0-0.0007324,0.0002441-0.0012207,0.0002441
                            c-0.0996094,0-0.194458-0.0212402-0.2822266-0.057373c-0.0279541-0.0114746-0.0490723-0.0322266-0.0751953-0.046875
                            c-0.0562744-0.03125-0.1120605-0.0622559-0.1582031-0.1066895c-0.0253906-0.0241699-0.0413818-0.0554199-0.0631104-0.0830078
                            c-0.0362549-0.0458984-0.0726318-0.0905762-0.0975342-0.1447754c-0.0163574-0.0351562-0.0212402-0.0739746-0.0319824-0.1118164
                            c-0.0125732-0.0446777-0.0334473-0.0854492-0.0378418-0.1330566l-4.7353516-53.4404297
                            c-0.0009766-0.0117188,0.0042725-0.0219727,0.0037842-0.0334473c-0.0006104-0.0117188-0.0067139-0.0214844-0.0067139-0.032959
                            c0-0.0402832,0.0168457-0.0749512,0.0229492-0.1132812c0.0078125-0.050293,0.012207-0.1003418,0.0296631-0.1469727
                            c0.0167236-0.0446777,0.0428467-0.0820312,0.0673828-0.1220703c0.0257568-0.0424805,0.0482178-0.0847168,0.081665-0.1213379
                            c0.0314941-0.0341797,0.0693359-0.0583496,0.1064453-0.0861816c0.0397949-0.0300293,0.0769043-0.0610352,0.1225586-0.0827637
                            c0.0439453-0.0209961,0.092041-0.0290527,0.1401367-0.0412598c0.0384521-0.0097656,0.0720215-0.0297852,0.112793-0.0332031
                            c0.0117188-0.0012207,0.0222168,0.0041504,0.0339355,0.0036621c0.0113525-0.0004883,0.0209961-0.0065918,0.0324707-0.0065918
                            h13.375l-0.324707-3.8310547H17.1196289L22.6453857,69.40625h94.6794434L116.0368652,54.9404297z"/>
                        <path className="st1" d="M124.6484375,53.4404297L123.2775879,37.96875h-1.4504395v-1h1.3618164L120.0458984,1.5H36.4335938
                            l4.4013672,51.9404297H124.6484375z M116.8271484,36.96875h2v1h-2V36.96875z M116.4581299,45.2475586H84.4594727
                            l-1.1428223-15.5571289h31.9986572L116.4581299,45.2475586z M83.3374023,24.6835938c0-0.4140625,0.3359375-0.75,0.75-0.75
                            h14.4638672c0.4140625,0,0.75,0.3359375,0.75,0.75s-0.3359375,0.75-0.75,0.75H84.0874023
                            C83.6733398,25.4335938,83.3374023,25.0976562,83.3374023,24.6835938z M114.5449219,20.0126953
                            c0,0.4140625-0.3359375,0.75-0.75,0.75H83.3168945c-0.4140625,0-0.75-0.3359375-0.75-0.75s0.3359375-0.75,0.75-0.75h30.4780273
                            C114.2089844,19.2626953,114.5449219,19.5986328,114.5449219,20.0126953z M82.8984375,14.5927734h30.4780273
                            c0.4140625,0,0.75,0.3359375,0.75,0.75s-0.3359375,0.75-0.75,0.75H82.8984375c-0.4140625,0-0.75-0.3359375-0.75-0.75
                            S82.484375,14.5927734,82.8984375,14.5927734z M47.9761963,17.0559082
                            c2.8939209-4.5146484,7.9442139-7.520752,13.6932373-7.520752c8.9697266,0,16.2675781,7.2978516,16.2675781,16.2675781
                            c0,6.8823242-4.3034668,12.7663574-10.3551025,15.1386719c-2.4854736,1.6918945-5.4862061,2.6826172-8.7193604,2.6826172
                            c-8.5699463,0-15.517334-6.9472656-15.517334-15.517334C43.3452148,23.7797852,45.1196289,19.8703613,47.9761963,17.0559082z"/>
                        <path className="st4" d="M82.8984375,16.0927734h30.4780273c0.4140625,0,0.75-0.3359375,0.75-0.75s-0.3359375-0.75-0.75-0.75
                            H82.8984375c-0.4140625,0-0.75,0.3359375-0.75,0.75S82.484375,16.0927734,82.8984375,16.0927734z"/>
                        <path className="st4" d="M83.3168945,19.2626953c-0.4140625,0-0.75,0.3359375-0.75,0.75s0.3359375,0.75,0.75,0.75h30.4780273
                            c0.4140625,0,0.75-0.3359375,0.75-0.75s-0.3359375-0.75-0.75-0.75H83.3168945z"/>
                        <path className="st4" d="M98.5512695,25.4335938c0.4140625,0,0.75-0.3359375,0.75-0.75s-0.3359375-0.75-0.75-0.75H84.0874023
                            c-0.4140625,0-0.75,0.3359375-0.75,0.75s0.3359375,0.75,0.75,0.75H98.5512695z"/>
                        <path className="st4" d="M221.4755859,74.3134766h-29.2436523l1.0380859-11.4313965
                            c0.7506104,0.1657715,1.5279541,0.2595215,2.3276367,0.2595215c5.9575195,0,10.8041992-4.8466797,10.8041992-10.8046875
                            c0-5.9570312-4.8466797-10.8037109-10.8041992-10.8037109c-0.1318359,0-0.2600098,0.0151367-0.3907471,0.0197754
                            l1.0889893-11.9924316c0.0010986-0.0114746-0.0040283-0.0212402-0.00354-0.0327148
                            c0.0025635-0.0529785-0.0084229-0.1037598-0.0172119-0.15625c-0.0073242-0.0441895-0.0098877-0.0888672-0.0251465-0.1313477
                            c-0.0151367-0.0422363-0.0421143-0.0788574-0.0651855-0.1184082c-0.0258789-0.0446777-0.0496826-0.090332-0.0855713-0.1298828
                            c-0.0078125-0.0085449-0.010376-0.0197754-0.0186768-0.0280762l-1.453125-1.4611816
                            c-0.0148926-0.0356445-0.0424805-0.0625-0.0622559-0.0947266c-0.0251465-0.0410156-0.0478516-0.0834961-0.0799561-0.1186523
                            c-0.0323486-0.0354004-0.0712891-0.0610352-0.1097412-0.0895996c-0.0385742-0.0285645-0.0751953-0.059082-0.1188965-0.0800781
                            c-0.0441895-0.0212402-0.0925293-0.0300293-0.1411133-0.0422363c-0.0380859-0.0097656-0.0712891-0.0297852-0.1115723-0.0334473
                            c-0.0118408-0.0009766-0.0222168,0.0041504-0.0340576,0.0039062c-0.012085-0.0004883-0.0220947-0.0068359-0.0343018-0.0068359
                            h-25.9926758c-0.043457,0-0.0812988,0.0175781-0.1228027,0.0246582c-0.0441895,0.0078125-0.0893555,0.0114746-0.1306152,0.0263672
                            c-0.0543213,0.0200195-0.1003418,0.0507812-0.1479492,0.0817871c-0.0323486,0.0212402-0.0668945,0.0388184-0.0955811,0.0644531
                            c-0.0407715,0.0366211-0.0706787,0.0805664-0.1026611,0.1252441c-0.0246582,0.034668-0.0522461,0.0666504-0.0709229,0.1052246
                            c-0.0223389,0.0461426-0.0316162,0.0966797-0.0440674,0.1477051c-0.0092773,0.0368652-0.0289307,0.0688477-0.0324707,0.1081543
                            l-0.4104004,4.6220703c-0.0385742,0.090332-0.0603027,0.1894531-0.0603027,0.2939453
                            c0,0.041748,0.0172119,0.0776367,0.0238037,0.1174316l-3.2203369,36.269043
                            c-0.0377197,0.0895996-0.0588379,0.1879883-0.0588379,0.2912598c0,0.0402832,0.0168457,0.074707,0.0229492,0.1132812
                            l-0.4334717,4.8818359h-21.5948486V70.15625c0-0.4140625-0.3359375-0.75-0.75-0.75h-21.8835449l-1.2880859-14.4658203h8.7443848
                            L121.4189453,0H34.8007812l0.4959717,5.8515625H16.8979492c-0.1118164,0-0.2155762,0.027832-0.3109131,0.0717773
                            c-0.1102295-0.0473633-0.2279053-0.0783691-0.3536377-0.0688477c-0.4125977,0.0371094-0.7172852,0.4013672-0.6806641,0.8134766
                            l6.0236816,67.6455078H0.75c-0.4140625,0-0.75,0.3359375-0.75,0.75s0.3359375,0.75,0.75,0.75h220.7255859
                            c0.4140625,0,0.75-0.3359375,0.75-0.75S221.8896484,74.3134766,221.4755859,74.3134766z M120.0458984,1.5l3.1430664,35.46875
                            h0.6381836v1h-0.5495605l1.3708496,15.4716797H40.8349609L36.4335938,1.5H120.0458984z M39.4570312,54.9404297h71.296875
                            l0.8579102,9.6826172H27.7954102l-4.602417-51.9404297h12.6827393L39.4570312,54.9404297z M17.1196289,7.3515625h18.3041992
                            l0.324707,3.8310547h-13.375c-0.0114746,0-0.0211182,0.0061035-0.0324707,0.0065918
                            c-0.0117188,0.0004883-0.0222168-0.0048828-0.0339355-0.0036621c-0.0407715,0.003418-0.0743408,0.0234375-0.112793,0.0332031
                            c-0.0480957,0.012207-0.0961914,0.0202637-0.1401367,0.0412598c-0.0456543,0.0217285-0.0827637,0.0527344-0.1225586,0.0827637
                            c-0.0371094,0.027832-0.0749512,0.052002-0.1064453,0.0861816c-0.0334473,0.0366211-0.0559082,0.0788574-0.081665,0.1213379
                            c-0.0245361,0.0400391-0.0506592,0.0773926-0.0673828,0.1220703c-0.0174561,0.0466309-0.0218506,0.0966797-0.0296631,0.1469727
                            c-0.0061035,0.0383301-0.0229492,0.072998-0.0229492,0.1132812c0,0.0114746,0.0061035,0.0212402,0.0067139,0.032959
                            c0.0004883,0.0114746-0.0047607,0.0217285-0.0037842,0.0334473l4.7353516,53.4404297
                            c0.0043945,0.0476074,0.0252686,0.0883789,0.0378418,0.1330566c0.0107422,0.0378418,0.015625,0.0766602,0.0319824,0.1118164
                            c0.0249023,0.0541992,0.0612793,0.098877,0.0975342,0.1447754c0.0217285,0.0275879,0.0377197,0.0588379,0.0631104,0.0830078
                            c0.0461426,0.0444336,0.1019287,0.0754395,0.1582031,0.1066895c0.026123,0.0146484,0.0472412,0.0354004,0.0751953,0.046875
                            c0.0877686,0.0361328,0.1826172,0.057373,0.2822266,0.057373c0.0004883,0,0.0008545-0.0002441,0.0012207-0.0002441
                            c0.0002441,0,0.0002441,0.0002441,0.0002441,0.0002441h85.3208008h0.0009766c0.0002441,0,0.0002441-0.0002441,0.0002441-0.0002441
                            c0.0220947,0,0.0441895-0.0009766,0.0661621-0.0026855c0.0407715-0.0036621,0.0742188-0.0236816,0.1125488-0.0332031
                            c0.0483398-0.012207,0.0966797-0.0205078,0.140625-0.0415039c0.0456543-0.0217285,0.0825195-0.0524902,0.1220703-0.0822754
                            c0.0373535-0.0280762,0.0753174-0.0524902,0.1066895-0.0866699c0.0334473-0.036377,0.0560303-0.0788574,0.0817871-0.1210938
                            c0.0245361-0.0402832,0.0506592-0.0773926,0.0673828-0.1223145c0.0175781-0.0466309,0.0217285-0.0964355,0.0296631-0.1467285
                            c0.0061035-0.0385742,0.0228271-0.0732422,0.0228271-0.1132812c0-0.0117188-0.0061035-0.0214844-0.0065918-0.032959
                            c-0.0004883-0.0117188,0.0047607-0.0219727,0.0036621-0.0334473l-0.918457-10.3662109h3.7770996l1.2879639,14.4658203H22.6453857
                            L17.1196289,7.3515625z M139.9643555,74.0488281H62.9492188H23.0588379L22.7790527,70.90625h40.170166h77.0151367V74.0488281z
                            M195.5976562,43.0332031c5.1303711,0,9.3041992,4.1738281,9.3041992,9.3037109
                            c0,2.8371582-1.2797852,5.3774414-3.2883301,7.0854492c-1.6236572,1.3811035-3.72229,2.2192383-6.0158691,2.2192383
                            c-2.2490234,0-4.3137207-0.8024902-5.9240723-2.1357422c-2.0631104-1.7080078-3.380127-4.2873535-3.380127-7.1689453
                            C186.293457,47.2070312,190.4672852,43.0332031,195.5976562,43.0332031z M194.529541,29.5319824l0.2407227,0.2421875
                            l-1.0842285,11.9387207c-0.0913086,0.0163574-0.1850586,0.0246582-0.2755127,0.043457L194.529541,29.5319824z
                            M168.6293945,28.5410156h24.4842529l-0.3066406,3.3496094h-24.4750977L168.6293945,28.5410156z M188.9477539,74.0488281
                            H164.588623l0.3535156-3.9804688h24.369873L188.9477539,74.0488281z M189.4494629,68.5683594h-24.3742676l2.717041-30.5996094
                            h-0.9650879v-1h1.053833l0.317749-3.578125h24.4709473l-0.8076172,8.8220215
                            c-4.1191406,1.5253906-7.0686035,5.4816895-7.0686035,10.1242676c0,3.9431152,2.1298828,7.3894043,5.2927246,9.276123
                            L189.4494629,68.5683594z M190.7495117,74.0488281h-0.295166l1.0720215-11.7099609
                            c0.0908203,0.0371094,0.1853027,0.0671387,0.2773438,0.1018066L190.7495117,74.0488281z"/>
                        <path className={classNames('st0', themeFillClass)} d="M58.8625488,43.6240234c3.2331543,0,6.2338867-0.9907227,8.7193604-2.6826172
                            c-1.8353271,0.7194824-3.8250732,1.1289062-5.9124756,1.1289062c-8.9697266,0-16.2670898-7.2978516-16.2670898-16.2675781
                            c0-3.2207031,0.9526367-6.2175293,2.5738525-8.7468262c-2.8565674,2.8144531-4.6309814,6.723877-4.6309814,11.0507812
                            C43.3452148,36.6767578,50.2926025,43.6240234,58.8625488,43.6240234z"/>
                        <polygon className={classNames('st0', themeFillClass)} points="64.5720215,31.7231445 66.0231934,31.7231445 66.0231934,24.4475098 
                            61.7424316,24.4475098 61.7424316,20.1135254 61.6696777,20.1135254 57.315918,20.1135254 57.315918,31.7231445 
                            61.6696777,31.7231445 		"/>
                        <path className={classNames('st0', themeFillClass)} d="M61.6694336,40.5703125c4.0202637,0,7.6660156-1.6191406,10.331543-4.2341309
                            c1.4986572-2.387207,2.3789062-5.2026367,2.3789062-8.2294922c0-8.5700684-6.9472656-15.517334-15.517334-15.517334
                            c-1.9477539,0-3.8040771,0.3745117-5.5217285,1.029541c-3.8834229,2.6635742-6.4384766,7.1293945-6.4384766,12.1838379
                            C46.9023438,33.9453125,53.5268555,40.5703125,61.6694336,40.5703125z M55.8647461,20.1135254
                            c0-0.8054199,0.645752-1.4511719,1.4511719-1.4511719l5.9030762,0.0048828l4.2553711,4.2553711v8.8005371
                            c0,0.8054199-0.645752,1.4511719-1.4511719,1.4511719H57.315918c-0.8054199,0-1.4511719-0.645752-1.4511719-1.4511719V20.1135254z
                            "/>
                        <polygon className={classNames('st0', themeFillClass)} points="62.9460449,20.3112793 62.9460449,23.2438965 65.8708496,23.2438965 		" />
                        <path className={classNames('st6', themeFillClass)} d="M61.6694336,42.0703125c2.0874023,0,4.0771484-0.4094238,5.9124756-1.1289062
                            c6.0516357-2.3723145,10.3551025-8.2563477,10.3551025-15.1386719c0-8.9697266-7.2978516-16.2675781-16.2675781-16.2675781
                            c-5.7490234,0-10.7993164,3.0061035-13.6932373,7.520752c-1.6212158,2.5292969-2.5738525,5.526123-2.5738525,8.7468262
                            C45.4023438,34.7724609,52.699707,42.0703125,61.6694336,42.0703125z M53.3408203,13.6188965
                            c2.3730469-1.6274414,5.2403564-2.5837402,8.3286133-2.5837402c8.1430664,0,14.7675781,6.625,14.7675781,14.7675781
                            c0,4.1225586-1.7023926,7.8518066-4.4360352,10.5334473c-2.6655273,2.6149902-6.3112793,4.2341309-10.331543,4.2341309
                            c-8.1425781,0-14.7670898-6.625-14.7670898-14.7675781C46.9023438,20.748291,49.4573975,16.2824707,53.3408203,13.6188965z"/>
                        <path className={classNames('st6', themeFillClass)} d="M57.315918,33.1743164h8.7072754c0.8054199,0,1.4511719-0.645752,1.4511719-1.4511719
                            v-8.8005371l-4.2553711-4.2553711l-5.9030762-0.0048828c-0.8054199,0-1.4511719,0.645752-1.4511719,1.4511719v11.6096191
                            C55.8647461,32.5285645,56.510498,33.1743164,57.315918,33.1743164z M62.9460449,20.3112793l2.9248047,2.9326172h-2.9248047
                            V20.3112793z M57.315918,20.1135254h4.3537598h0.0727539v4.3339844h4.2807617v7.2756348h-1.4511719h-2.9023438H57.315918
                            V20.1135254z"/>
                        <path className={classNames('st0', themeFillClass)} d="M241.6020508,23.4680176
                            c-3.0788574-6.310791-9.5396729-10.6723633-17.0297852-10.6723633c-0.0935059,0-0.184082,0.0126953-0.2773438,0.013916v5.107666
                            h-12.6496582c-3.7023926,3.4609375-6.03125,8.3728027-6.03125,13.8364258c0,10.465332,8.4934082,18.9587402,18.958252,18.9587402
                            c2.4890137,0,4.8601074-0.4936523,7.038208-1.3666992c6.9801025-2.7978516,11.9202881-9.6157227,11.9202881-17.592041
                            C243.5307617,28.7788086,242.8249512,25.9743652,241.6020508,23.4680176z M234.4494629,34.4487305
                            c-0.880127,0-1.7019043,0.0375977-2.4576416,0.1101074c-0.0319824,0.7041016-0.1334229,1.4042969-0.2958984,2.0629883
                            s-0.3863525,1.2763672-0.6629639,1.815918c-0.2905273,0.5664062-0.6333008,1.0373535-1.0169678,1.4047852
                            c-0.6395264,0.612793-1.3931885,0.9375-2.2091064,0.9375c-2.019043,0-3.2243652-1.1923828-3.2243652-3.1896973
                            c0-0.0935059,0.0039062-0.1948242,0.0131836-0.3024902c0.0166016-0.1887207,0.0500488-0.3969727,0.1102295-0.6181641
                            c0.1352539-0.4970703,0.4047852-1.057373,0.9156494-1.5981445c0.1704102-0.1801758,0.3674316-0.3581543,0.5953369-0.5310059
                            c0.208374-0.157959,0.4425049-0.3115234,0.7054443-0.458252c0.7885742-0.4404297,1.8361816-0.8203125,3.2244873-1.0756836
                            c-0.1658936-1.2260742-0.6397705-2.3652344-1.5720215-2.6306152c-0.1331787-0.0380859-0.2757568-0.0581055-0.4281006-0.0581055
                            c-0.1855469,0-0.3693848,0.0258789-0.552002,0.0744629c-0.3652344,0.097168-0.7248535,0.2844238-1.0795898,0.5354004
                            c-0.887207,0.6274414-1.7443848,1.6520996-2.5837402,2.6550293c-1.0317383,1.2336426-2.0205078,2.4155273-3.1433105,2.9294434
                            c-0.3742676,0.1711426-0.7631836,0.2683105-1.1738281,0.2683105c-0.4191895,0-0.8125-0.0791016-1.166748-0.2241211
                            c-0.177124-0.0727539-0.3444824-0.1616211-0.5004883-0.2653809c-0.4677734-0.3112793-0.8326416-0.7558594-1.0498047-1.2885742
                            c-0.1446533-0.3549805-0.2236328-0.7492676-0.2236328-1.1694336c0-0.7109375,0.2214355-1.4284668,0.5588379-2.1359863
                            c0.1123047-0.2358398,0.2375488-0.470459,0.3718262-0.7033691c0.1923828-0.3334961,0.3981934-0.6643066,0.6174316-0.9885254
                            c0.2773438-0.4099121,0.567627-0.810791,0.8483887-1.1987305c0.1828613-0.2524414,0.3671875-0.5073242,0.5426025-0.7575684
                            c0.2629395-0.3752441,0.5054932-0.7404785,0.6920166-1.0725098c0.0621338-0.1108398,0.1179199-0.2180176,0.1662598-0.3205566
                            c0.0966797-0.2053223,0.1630859-0.3928223,0.1884766-0.5554199c0.0297852-0.1906738,0.0280762-0.3669434-0.0026855-0.5217285
                            c-0.0310059-0.1564941-0.0917969-0.2915039-0.1815186-0.3964844c-0.1385498-0.1618652-0.3406982-0.2475586-0.5841064-0.2475586
                            c-0.404541,0-0.6586914,0.072998-0.8945312,0.2849121c-0.1179199,0.1062012-0.2313232,0.2470703-0.3566895,0.4309082
                            c-0.1254883,0.1838379-0.2628174,0.4108887-0.428833,0.6894531l-1.5335693-0.9140625
                            c0.6977539-1.1706543,1.3569336-2.2763672,3.213623-2.2763672c0.1925049,0,0.3798828,0.0192871,0.5603027,0.0571289
                            s0.3538818,0.0942383,0.5183105,0.1679688c0.3286133,0.1477051,0.6212158,0.3657227,0.8618164,0.6469727
                            c0.2617188,0.3056641,0.4450684,0.6677246,0.545166,1.0666504c0.0500488,0.1994629,0.0795898,0.407959,0.0874023,0.6235352
                            c0.0004883,0.0141602-0.0030518,0.0292969-0.0026855,0.0437012c0.0047607,0.2021484-0.005127,0.4089355-0.0383301,0.6208496
                            c-0.0531006,0.3400879-0.1796875,0.6904297-0.3485107,1.0480957c-0.0979004,0.2075195-0.2064209,0.416748-0.3326416,0.6298828
                            c-0.171875,0.2902832-0.3666992,0.5854492-0.5742188,0.885498c-0.1037598,0.1499023-0.2108154,0.3010254-0.3198242,0.453125
                            c-0.1090088,0.1523438-0.2199707,0.3056641-0.3317871,0.4602051c-0.1364746,0.1887207-0.2751465,0.3808594-0.4128418,0.5749512
                            c-0.4128418,0.5820312-0.815918,1.1818848-1.1210938,1.7583008c-0.1318359,0.2492676-0.2512207,0.4956055-0.3376465,0.7321777
                            c-0.0587158,0.161377-0.1052246,0.3188477-0.1369629,0.4714355c-0.0317383,0.152832-0.0488281,0.3007812-0.0488281,0.4431152
                            c0,0.529541,0.255127,0.9187012,0.678833,1.0800781c0.1412354,0.0537109,0.3011475,0.0822754,0.4766846,0.0822754
                            c0.1010742,0,0.2071533-0.0212402,0.317627-0.060791c0.1564941-0.0559082,0.3239746-0.1589355,0.4951172-0.2802734
                            c0.1905518-0.1350098,0.3863525-0.2946777,0.5881348-0.4882812c0.255249-0.244873,0.5167236-0.5270996,0.7769775-0.822998
                            c0.2602539-0.2961426,0.5194092-0.605957,0.7701416-0.9055176c0.4799805-0.5739746,0.9840088-1.1660156,1.5158691-1.7165527
                            c0.5284424-0.5466309,1.0844727-1.0522461,1.6867676-1.4353027c0.3015137-0.1916504,0.6181641-0.3400879,0.942627-0.4645996
                            c0.4558105-0.1748047,0.9313965-0.2883301,1.439209-0.2883301c1.0115967,0,1.8684082,0.4057617,2.5157471,1.1384277
                            c0.1617432,0.1831055,0.3105469,0.3867188,0.4451904,0.609375c0.1789551,0.2956543,0.3231201,0.6367188,0.4506836,0.9970703
                            c0.1425781,0.4018555,0.2542725,0.8383789,0.3276367,1.3134766c0.0093994,0.0612793,0.0258789,0.1157227,0.0340576,0.1782227
                            c0.7625732-0.0686035,1.6026611-0.1057129,2.5294189-0.1057129V34.4487305z"/>
                        <g className="st7">
                            <g />
                        </g>
                        <g className={classNames('st7', themeFillClass)}>
                            <circle className={classNames('st0', themeFillClass)} cx="113.4656982" cy="37.4689941" r="1.480896" />
                            <path className={classNames('st5', themeFillClass)} d="M115.3153076,29.6904297H83.3166504l1.1428223,15.5571289h31.9986572
                                L115.3153076,29.6904297z M95.1674805,40.8886719c-0.3132324,0.3242188-0.737793,0.4873047-1.2749023,0.4873047
                                c-0.6367188,0-1.1364746-0.1972656-1.498291-0.5898438c-0.362915-0.3935547-0.5891113-0.9335938-0.6766357-1.6201172
                                l0.9178467-0.2529297c0.0671387,0.4873047,0.1906738,0.8798828,0.3718262,1.1796875
                                c0.1812744,0.2998047,0.4559326,0.4492188,0.8240967,0.4492188c0.2744141,0,0.4768066-0.0761719,0.6060791-0.2294922
                                c0.1295166-0.1523438,0.1837158-0.3720703,0.1625977-0.6591797c-0.0229492-0.3125-0.1083984-0.5712891-0.2543945-0.7773438
                                s-0.371582-0.4335938-0.677124-0.6835938l-1.355957-1.1230469c-0.2723389-0.2246094-0.475708-0.4667969-0.6099854-0.7255859
                                c-0.1352539-0.2597656-0.2160645-0.5761719-0.2436523-0.9501953c-0.0407715-0.5556641,0.086792-0.9863281,0.3826904-1.2919922
                                s0.7182617-0.4589844,1.2680664-0.4589844c0.5986328,0,1.0633545,0.1591797,1.3924561,0.4775391
                                s0.5498047,0.8115234,0.661377,1.4785156l-0.8814697,0.2441406c-0.0638428-0.4433594-0.1749268-0.7851562-0.3321533-1.0253906
                                c-0.1582031-0.2402344-0.4190674-0.3603516-0.7803955-0.3603516c-0.2685547,0-0.4693604,0.0703125-0.6026611,0.2109375
                                c-0.1333008,0.1396484-0.1893311,0.3476562-0.1691895,0.6220703c0.0166016,0.2246094,0.067627,0.4140625,0.1539307,0.5664062
                                c0.0855713,0.1533203,0.2247314,0.3076172,0.4178467,0.4638672l1.3647461,1.1230469
                                c0.3526611,0.2939453,0.6396484,0.6123047,0.8612061,0.9550781c0.2214355,0.3427734,0.3487549,0.7333984,0.3808594,1.1699219
                                C95.6171875,40.1240234,95.4814453,40.5644531,95.1674805,40.8886719z M96.5827637,41.2910156l-0.5571289-7.5830078h1.0390625
                                l0.5571289,7.5830078H96.5827637z M102.1062012,41.2910156l-0.1665039-0.8701172
                                c-0.1901855,0.6425781-0.6157227,0.9638672-1.277832,0.9638672c-0.6865234,0-1.1984863-0.2167969-1.5361328-0.6503906
                                c-0.3374023-0.4335938-0.5380859-1.078125-0.6009521-1.9335938l-0.1864014-2.5371094
                                c-0.0430908-0.5869141-0.0168457-1.0683594,0.076416-1.4462891c0.0942383-0.3779297,0.2819824-0.6679688,0.5639648-0.8710938
                                c0.2810059-0.2021484,0.6807861-0.3037109,1.1993408-0.3037109c0.6982422,0,1.2161865,0.1777344,1.5509033,0.5332031
                                c0.3347168,0.3564453,0.5310059,0.9150391,0.5869141,1.6757812l0.020752,0.28125h-0.9931641l-0.017334-0.234375
                                c-0.0264893-0.3613281-0.0684814-0.640625-0.1269531-0.8378906c-0.0583496-0.1962891-0.1610107-0.3447266-0.30896-0.4443359
                                c-0.1469727-0.0996094-0.3587646-0.1503906-0.6331787-0.1503906c-0.2939453,0-0.5067139,0.0673828-0.6403809,0.2021484
                                c-0.1337891,0.1337891-0.2124023,0.3134766-0.2368164,0.5380859c-0.0245361,0.2246094-0.0219727,0.5244141,0.0053711,0.8984375
                                l0.2062988,2.8085938c0.0427246,0.5810547,0.1435547,0.9970703,0.3018799,1.25
                                c0.1591797,0.2529297,0.4389648,0.3789062,0.8383789,0.3789062c0.3925781,0,0.6529541-0.1367188,0.7792969-0.4121094
                                c0.1263428-0.2744141,0.1672363-0.7138672,0.1228027-1.3193359l-0.0384521-0.5244141h-0.9638672l-0.0551758-0.7490234h1.9189453
                                l0.2758789,3.7539062H102.1062012z M106.9372559,41.2910156l-2.5620117-5.1767578l0.3803711,5.1767578h-0.8984375
                                l-0.5571289-7.5830078h0.7207031l2.5234375,5.0371094l-0.3701172-5.0371094h0.8798828l0.5571289,7.5830078H106.9372559z
                                M113.4656982,38.9499512c-0.8178711,0-1.480835-0.6630859-1.480835-1.480957s0.6629639-1.480957,1.480835-1.480957
                                s1.480835,0.6630859,1.480835,1.480957S114.2835693,38.9499512,113.4656982,38.9499512z"/>
                        </g>
                        <path className="st1" d="M92.3126221,37.0693359l1.355957,1.1230469c0.305542,0.25,0.5311279,0.4775391,0.677124,0.6835938
                            s0.2314453,0.4648438,0.2543945,0.7773438c0.0211182,0.2871094-0.0330811,0.5068359-0.1625977,0.6591797
                            c-0.1292725,0.1533203-0.331665,0.2294922-0.6060791,0.2294922c-0.3681641,0-0.6428223-0.1494141-0.8240967-0.4492188
                            c-0.1811523-0.2998047-0.3046875-0.6923828-0.3718262-1.1796875l-0.9178467,0.2529297
                            c0.0875244,0.6865234,0.3137207,1.2265625,0.6766357,1.6201172c0.3618164,0.3925781,0.8615723,0.5898438,1.498291,0.5898438
                            c0.5371094,0,0.9616699-0.1630859,1.2749023-0.4873047c0.3139648-0.3242188,0.449707-0.7646484,0.4088135-1.3203125
                            c-0.0321045-0.4365234-0.1594238-0.8271484-0.3808594-1.1699219c-0.2215576-0.3427734-0.5085449-0.6611328-0.8612061-0.9550781
                            l-1.3647461-1.1230469c-0.1931152-0.15625-0.3322754-0.3105469-0.4178467-0.4638672
                            c-0.0863037-0.1523438-0.1373291-0.3417969-0.1539307-0.5664062c-0.0201416-0.2744141,0.0358887-0.4824219,0.1691895-0.6220703
                            c0.1333008-0.140625,0.3341064-0.2109375,0.6026611-0.2109375c0.3613281,0,0.6221924,0.1201172,0.7803955,0.3603516
                            c0.1572266,0.2402344,0.2683105,0.5820312,0.3321533,1.0253906l0.8814697-0.2441406
                            c-0.1115723-0.6669922-0.3322754-1.1601562-0.661377-1.4785156s-0.7938232-0.4775391-1.3924561-0.4775391
                            c-0.5498047,0-0.972168,0.1533203-1.2680664,0.4589844s-0.4234619,0.7363281-0.3826904,1.2919922
                            c0.0275879,0.3740234,0.1083984,0.6904297,0.2436523,0.9501953
                            C91.8369141,36.6025391,92.0402832,36.8447266,92.3126221,37.0693359z"/>
                        <polygon className="st1" points="97.6218262,41.2910156 97.0646973,33.7080078 96.0256348,33.7080078 96.5827637,41.2910156 		" />
                        <path className="st1" d="M98.5247803,38.8007812c0.0628662,0.8554688,0.2635498,1.5,0.6009521,1.9335938
                            c0.3376465,0.4335938,0.8496094,0.6503906,1.5361328,0.6503906c0.6621094,0,1.0876465-0.3212891,1.277832-0.9638672
                            l0.1665039,0.8701172h0.6650391l-0.2758789-3.7539062h-1.9189453l0.0551758,0.7490234h0.9638672l0.0384521,0.5244141
                            c0.0444336,0.6054688,0.00354,1.0449219-0.1228027,1.3193359c-0.1263428,0.2753906-0.3867188,0.4121094-0.7792969,0.4121094
                            c-0.3994141,0-0.6791992-0.1259766-0.8383789-0.3789062c-0.1583252-0.2529297-0.2591553-0.6689453-0.3018799-1.25
                            l-0.2062988-2.8085938c-0.0273438-0.3740234-0.0299072-0.6738281-0.0053711-0.8984375
                            c0.0244141-0.2246094,0.1030273-0.4042969,0.2368164-0.5380859c0.133667-0.1347656,0.3464355-0.2021484,0.6403809-0.2021484
                            c0.2744141,0,0.4862061,0.0507812,0.6331787,0.1503906c0.1479492,0.0996094,0.2506104,0.2480469,0.30896,0.4443359
                            c0.0584717,0.1972656,0.1004639,0.4765625,0.1269531,0.8378906l0.017334,0.234375h0.9931641l-0.020752-0.28125
                            c-0.0559082-0.7607422-0.2521973-1.3193359-0.5869141-1.6757812c-0.3347168-0.3554688-0.8526611-0.5332031-1.5509033-0.5332031
                            c-0.5185547,0-0.918335,0.1015625-1.1993408,0.3037109c-0.2819824,0.203125-0.4697266,0.4931641-0.5639648,0.8710938
                            c-0.0932617,0.3779297-0.1195068,0.859375-0.076416,1.4462891L98.5247803,38.8007812z"/>
                        <polygon className="st1" points="104.7556152,41.2910156 104.3752441,36.1142578 106.9372559,41.2910156 107.611084,41.2910156 
                            107.0539551,33.7080078 106.1740723,33.7080078 106.5441895,38.7451172 104.020752,33.7080078 103.3000488,33.7080078 
                            103.8571777,41.2910156 		"/>
                        <path className={classNames('st0', themeFillClass)} d="M186.293457,52.3369141c0,2.8815918,1.3170166,5.4609375,3.380127,7.1689453
                            c0.7979736-2.1364746,2.701416-3.7268066,5.0174561-4.0795898v-0.9416504
                            c-0.8201904-0.2502441-1.4904785-0.8388672-1.831665-1.6149902l-2.5825195,0.0336914
                            c-0.1506348-0.7314453-0.2331543-1.4997559-0.1636963-2.2536621c0.2640381-2.8696289,1.4659424-4.4084473,2.0943604-5.0354004
                            c0.4193115-0.418457,0.9191895-0.6584473,1.4238281-0.8166504c1.2730713-0.3994141,2.6257324-0.3041992,3.8625488,0.2719727
                            c1.0622559,0.494873,2.0765381,1.3989258,2.5959473,2.769043c0.2485352,0.6560059,0.6398926,4.222168,0.526001,4.9296875
                            l-2.3613281,0.0307617c-0.3162842,0.7746582-0.9564209,1.3735352-1.7501221,1.6508789v0.9577637
                            c2.3410645,0.3100586,4.2785645,1.8828125,5.1091309,4.0146484c2.0085449-1.7080078,3.2883301-4.248291,3.2883301-7.0854492
                            c0-5.1298828-4.1738281-9.3037109-9.3041992-9.3037109S186.293457,47.2070312,186.293457,52.3369141z"/>
                        <path className={classNames('st6', themeFillClass)} d="M196.5043945,55.4077148v1.1320801
                            c0,0.5009766-0.4058838,0.9067383-0.9067383,0.9067383c-0.5007324,0-0.9066162-0.4057617-0.9066162-0.9067383v-1.1135254
                            c-2.31604,0.3527832-4.2194824,1.9431152-5.0174561,4.0795898c1.6103516,1.333252,3.6750488,2.1357422,5.9240723,2.1357422
                            c2.2935791,0,4.3922119-0.8381348,6.0158691-2.2192383C200.782959,57.2905273,198.845459,55.7177734,196.5043945,55.4077148z"/>
                        <path className={classNames('st6', themeFillClass)} d="M192.6096191,48.8464355h5.864624v2.8459473
                            c0,0.392334-0.0800781,0.7648926-0.2197266,1.1066895l2.3613281-0.0307617
                            c0.1138916-0.7075195-0.2774658-4.2736816-0.526001-4.9296875c-0.5194092-1.3701172-1.5336914-2.2741699-2.5959473-2.769043
                            c-1.2368164-0.5761719-2.5894775-0.6713867-3.8625488-0.2719727c-0.5046387,0.1582031-1.0045166,0.3981934-1.4238281,0.8166504
                            c-0.628418,0.6269531-1.8303223,2.1657715-2.0943604,5.0354004c-0.069458,0.7539062,0.0130615,1.5222168,0.1636963,2.2536621
                            l2.5825195-0.0336914c-0.1585693-0.3608398-0.2497559-0.7578125-0.2497559-1.1772461V48.8464355z"/>
                        <path className={classNames('st8', themeFillClass)} d="M195.5976562,57.4465332c0.5008545,0,0.9067383-0.4057617,0.9067383-0.9067383
                            v-1.1320801v-0.9577637c0.7937012-0.2773438,1.4338379-0.8762207,1.7501221-1.6508789
                            c0.1396484-0.3417969,0.2197266-0.7143555,0.2197266-1.1066895v-2.8459473h-5.864624v2.8459473
                            c0,0.4194336,0.0911865,0.8164062,0.2497559,1.1772461c0.3411865,0.776123,1.0114746,1.3647461,1.831665,1.6149902v0.9416504
                            v1.1135254C194.69104,57.0407715,195.0969238,57.4465332,195.5976562,57.4465332z"/>
                        <rect x="156.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="136.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="151.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="141.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="126.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="131.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="161.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <rect x="176.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <polygon className="st9" points="168.8271484,36.96875 167.8809814,36.96875 166.8271484,36.96875 166.8271484,37.96875 
                            167.7922363,37.96875 168.8271484,37.96875 		"/>
                        <rect x="171.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <polygon className="st9" points="121.8271484,37.96875 123.2775879,37.96875 123.8271484,37.96875 123.8271484,36.96875 
                            123.1889648,36.96875 121.8271484,36.96875 		"/>
                        <rect x="116.8271484" y="36.96875" className="st9" width="2" height="1" />
                        <circle className={classNames('st0', themeFillClass)} cx="181.6739502" cy="37.4689941" r="1.480896" />
                        <polygon className={classNames('st0', themeFillClass)} points="149.2400208,37.8547363 149.625885,37.4689941 149.2400208,37.083252 
                            149.1256409,36.96875 145.6373596,33.4804688 145.6373596,41.4575195 149.1261292,37.96875 		"/>
                        <path className={classNames('st6', themeFillClass)} d="M231.8859863,32.5910645
                            c-0.0733643-0.4750977-0.1850586-0.9116211-0.3276367-1.3134766c-0.1275635-0.3603516-0.2717285-0.701416-0.4506836-0.9970703
                            c-0.1346436-0.2226562-0.2834473-0.4262695-0.4451904-0.609375c-0.6473389-0.7324219-1.5042725-1.1384277-2.5157471-1.1384277
                            c-0.5078125,0-0.9833984,0.1135254-1.439209,0.2883301c-0.3244629,0.1245117-0.6411133,0.2729492-0.942627,0.4645996
                            c-0.6022949,0.3830566-1.1582031,0.8886719-1.6867676,1.4353027c-0.5318604,0.5505371-1.0357666,1.1425781-1.5158691,1.7165527
                            c-0.2507324,0.2995605-0.5098877,0.609375-0.7701416,0.9055176c-0.2602539,0.2958984-0.5217285,0.578125-0.7769775,0.822998
                            c-0.2017822,0.1936035-0.397583,0.3532715-0.5881348,0.4882812c-0.1711426,0.1213379-0.338501,0.2243652-0.4951172,0.2802734
                            c-0.1104736,0.0395508-0.2165527,0.060791-0.317627,0.060791c-0.1755371,0-0.3354492-0.0285645-0.4766846-0.0822754
                            c-0.4237061-0.161377-0.678833-0.5505371-0.678833-1.0800781c0-0.142334,0.0170898-0.2902832,0.0488281-0.4431152
                            c0.0317383-0.1525879,0.0782471-0.3100586,0.1369629-0.4714355c0.0864258-0.2365723,0.2058105-0.4829102,0.3376465-0.7321777
                            c0.3051758-0.5766602,0.708252-1.1762695,1.1210938-1.7583008c0.1376953-0.1940918,0.2763672-0.3862305,0.4128418-0.5749512
                            c0.1118164-0.154541,0.2227783-0.3078613,0.3317871-0.4602051c0.1090088-0.1520996,0.2160645-0.3032227,0.3198242-0.453125
                            c0.2075195-0.3000488,0.4023438-0.5952148,0.5742188-0.885498c0.1262207-0.2131348,0.2347412-0.4223633,0.3326416-0.6298828
                            c0.1688232-0.357666,0.2954102-0.708252,0.3485107-1.0480957c0.0332031-0.2119141,0.0430908-0.4187012,0.0383301-0.6208496
                            c-0.0003662-0.0144043,0.0031738-0.029541,0.0026855-0.0437012c-0.0078125-0.2155762-0.0373535-0.4240723-0.0874023-0.6235352
                            c-0.1000977-0.3989258-0.2834473-0.7607422-0.545166-1.0666504c-0.2406006-0.2810059-0.5332031-0.4992676-0.8618164-0.6469727
                            c-0.1644287-0.0737305-0.3378906-0.130127-0.5183105-0.1679688s-0.3677979-0.0571289-0.5603027-0.0571289
                            c-1.8566895,0-2.5158691,1.1057129-3.213623,2.2763672l1.5335693,0.9140625
                            c0.1660156-0.2785645,0.3033447-0.5056152,0.428833-0.6894531s0.2387695-0.3249512,0.3566895-0.4309082
                            c0.2358398-0.2119141,0.4899902-0.2849121,0.8945312-0.2849121c0.2434082,0,0.4455566,0.0856934,0.5841064,0.2475586
                            c0.0897217,0.1049805,0.1505127,0.2399902,0.1815186,0.3964844c0.0307617,0.1547852,0.0324707,0.3310547,0.0026855,0.5217285
                            c-0.0253906,0.1628418-0.0917969,0.3500977-0.1884766,0.5554199c-0.0483398,0.1025391-0.104126,0.2097168-0.1662598,0.3205566
                            c-0.1864014,0.3320312-0.4289551,0.6972656-0.6920166,1.0725098c-0.175415,0.2502441-0.3597412,0.505127-0.5426025,0.7575684
                            c-0.2807617,0.3879395-0.5710449,0.7888184-0.8483887,1.1987305c-0.2192383,0.3242188-0.4250488,0.6550293-0.6174316,0.9885254
                            c-0.1342773,0.2329102-0.2595215,0.4675293-0.3718262,0.7033691c-0.3374023,0.7075195-0.5588379,1.425293-0.5588379,2.1359863
                            c0,0.420166,0.0789795,0.8144531,0.2236328,1.1694336c0.2171631,0.5327148,0.5820312,0.9772949,1.0498047,1.2885742
                            c0.1560059,0.1037598,0.3233643,0.192627,0.5004883,0.2653809c0.354248,0.1450195,0.7475586,0.2241211,1.166748,0.2241211
                            c0.4105225,0,0.7995605-0.097168,1.1738281-0.2683105c1.1228027-0.513916,2.1115723-1.6958008,3.1433105-2.9294434
                            c0.8393555-1.0031738,1.6965332-2.0275879,2.5837402-2.6550293c0.3547363-0.2509766,0.7143555-0.4382324,1.0795898-0.5354004
                            c0.1826172-0.048584,0.3664551-0.0744629,0.552002-0.0744629c0.1523438,0,0.2949219,0.0200195,0.4281006,0.0581055
                            c0.932251,0.2653809,1.4061279,1.404541,1.5720215,2.6306152c-1.3883057,0.2556152-2.4359131,0.6352539-3.2244873,1.0756836
                            c-0.2629395,0.1467285-0.4970703,0.300293-0.7054443,0.458252c-0.2279053,0.1728516-0.4249268,0.3508301-0.5953369,0.5310059
                            c-0.5108643,0.5407715-0.7803955,1.1010742-0.9156494,1.5981445c-0.0601807,0.2211914-0.0936279,0.4294434-0.1102295,0.6181641
                            c-0.0092773,0.107666-0.0131836,0.2089844-0.0131836,0.3024902c0,1.9973145,1.2053223,3.1896973,3.2243652,3.1896973
                            c0.81604,0,1.5695801-0.3249512,2.2091064-0.9375c0.383667-0.3674316,0.7264404-0.8383789,1.0169678-1.4047852
                            c0.2766113-0.5397949,0.5004883-1.1572266,0.6629639-1.815918c0.1624756-0.6589355,0.263916-1.3588867,0.2958984-2.0629883
                            c0.7557373-0.0725098,1.5775146-0.1101074,2.4576416-0.1101074v-1.7851562c-0.9267578,0-1.7668457,0.0371094-2.5294189,0.1057129
                            C231.9118652,32.7067871,231.8953857,32.6523438,231.8859863,32.5910645z M229.444458,37.623291
                            c-0.321167,0.6254883-0.8656006,1.371582-1.6375732,1.371582c-1.0357666,0-1.439209-0.3935547-1.439209-1.404541
                            c0-1.3005371,1.3945312-2.2705078,3.8190918-2.7675781C230.1013184,35.857666,229.8411865,36.8493652,229.444458,37.623291z"/>
                    </g>
                    <g>
                        <path className={classNames('st6', themeFillClass)} d="M175.6352539,1.7653809V17.925293h4.5v4.5l5-4.5h26.5h12.6500244v-5.1101074V1.7653809
                            H175.6352539z M185.3552246,13.3352051c-0.3498535,0.3400879-0.7999268,0.5102539-1.3598633,0.5102539
                            c-0.6600342,0-1.1700439-0.2102051-1.5100098-0.6201172c-0.3500977-0.3999023-0.5400391-0.9599609-0.5800781-1.6799316
                            l0.9699707-0.2600098c0.0300293,0.5100098,0.130127,0.9099121,0.3000488,1.2299805
                            c0.1600342,0.3100586,0.4399414,0.4599609,0.8200684,0.4599609c0.289917,0,0.5-0.0800781,0.6499023-0.2399902
                            c0.1400146-0.1501465,0.2199707-0.380127,0.2199707-0.6799316c0-0.3300781-0.0699463-0.6000977-0.2099609-0.8100586
                            c-0.1298828-0.2099609-0.3499756-0.4499512-0.6499023-0.7099609l-1.3200684-1.1699219
                            c-0.2700195-0.2302246-0.4599609-0.4802246-0.5800781-0.75c-0.119873-0.2700195-0.1799316-0.6000977-0.1799316-0.9902344
                            c0-0.579834,0.1600342-1.0200195,0.4899902-1.3398438c0.3400879-0.3200684,0.7900391-0.4799805,1.3601074-0.4799805
                            c0.619873,0,1.0898438,0.1699219,1.4099121,0.5c0.3100586,0.329834,0.5100098,0.8398438,0.5700684,1.5400391l-0.9300537,0.25
                            c-0.0400391-0.4602051-0.1199951-0.8200684-0.2700195-1.0700684s-0.4100342-0.3701172-0.7799072-0.3701172
                            c-0.2800293,0-0.5,0.0700684-0.6501465,0.2199707c-0.1499023,0.1401367-0.2199707,0.3601074-0.2199707,0.6401367
                            c0,0.2399902,0.0400391,0.4299316,0.1201172,0.5898438c0.0698242,0.1601562,0.2099609,0.3200684,0.3999023,0.4799805
                            l1.3300781,1.170166c0.3398438,0.3098145,0.619873,0.6398926,0.8199463,0.9899902
                            c0.2099609,0.3598633,0.3099365,0.7700195,0.3099365,1.2199707
                            C185.8852539,12.5454102,185.7053223,13.0053711,185.3552246,13.3352051z M187.8752441,13.7553711h-1.0799561v-7.880127h1.0799561
                            V13.7553711z M193.2253418,13.7553711h-0.6900635l-0.1099854-0.9001465c-0.2399902,0.6601562-0.7099609,1-1.3999023,1
                            c-0.710083,0-1.2301025-0.2299805-1.5400391-0.6799316c-0.3200684-0.4499512-0.4799805-1.119873-0.4799805-2.0100098V8.5354004
                            c0-0.6101074,0.0599365-1.1101074,0.1899414-1.5100098c0.1300049-0.3901367,0.3399658-0.6901855,0.6499023-0.9001465
                            c0.3100586-0.2099609,0.7301025-0.3198242,1.2700195-0.3198242c0.7299805,0,1.25,0.1899414,1.5700684,0.5600586
                            c0.3200684,0.369873,0.4799805,0.949707,0.4799805,1.7397461v0.2900391h-1.0300293V8.1552734
                            c0-0.3798828-0.0200195-0.6699219-0.0699463-0.869873c-0.039917-0.2099609-0.1400146-0.3601074-0.2800293-0.4702148
                            c-0.1500244-0.0998535-0.3599854-0.1499023-0.6500244-0.1499023c-0.2999268,0-0.5300293,0.0700684-0.6799316,0.2099609
                            c-0.1500244,0.1401367-0.2399902,0.3200684-0.2900391,0.5600586c-0.0400391,0.2299805-0.0600586,0.5400391-0.0600586,0.9301758
                            v2.9199219c0,0.5998535,0.0700684,1.0400391,0.2200928,1.2998047c0.1400146,0.2602539,0.4200439,0.3901367,0.8399658,0.3901367
                            c0.4100342,0,0.6899414-0.1401367,0.8400879-0.4299316c0.1499023-0.2800293,0.2299805-0.7399902,0.2299805-1.3701172v-0.5400391
                            h-1V9.8552246h1.9899902V13.7553711z M198.2553711,13.7553711h-0.7000732l-2.2600098-5.380127v5.380127h-0.9400635v-7.880127h0.75
                            l2.2399902,5.2299805V5.8752441h0.9101562V13.7553711z M205.6252441,13.7553711h-0.6999512l-2.2700195-5.380127v5.380127
                            h-0.9299316v-7.880127h0.7399902l2.2399902,5.2299805V5.8752441h0.9199219V13.7553711z M211.0253906,11.2954102
                            c0,0.8200684-0.170166,1.4499512-0.5100098,1.8898926c-0.3400879,0.4399414-0.8901367,0.6601562-1.6401367,0.6601562
                            c-0.7800293,0-1.3299561-0.2202148-1.6599121-0.6601562c-0.3400879-0.4399414-0.5-1.0698242-0.5-1.8999023V8.2954102
                            c0-0.8200684,0.1599121-1.4299316,0.5-1.8601074c0.3399658-0.4199219,0.8898926-0.6298828,1.6599121-0.6298828
                            c0.7600098,0,1.3100586,0.2099609,1.6501465,0.6398926c0.329834,0.420166,0.5,1.0400391,0.5,1.8500977V11.2954102z
                            M216.965332,13.7553711h-0.8000488l-1.0999756-5.8400879l-1.1099854,5.8400879h-0.8300781l-1.289917-7.880127h0.9200439
                            l0.8398438,5.6601562l1.0700684-5.630127h0.7700195l1.0700684,5.6599121l0.8299561-5.6899414h0.9000244L216.965332,13.7553711z"/>
                        <path className={classNames('st6', themeFillClass)} d="M208.8752441,6.6652832c-0.4199219,0-0.6999512,0.1101074-0.8399658,0.3400879
                            s-0.2099609,0.5900879-0.2099609,1.0700684v3.4499512c0,0.4899902,0.0699463,0.8498535,0.2099609,1.0900879
                            c0.1400146,0.2397461,0.4200439,0.3598633,0.8399658,0.3598633c0.4100342,0,0.6800537-0.1201172,0.8200684-0.3598633
                            c0.1499023-0.2402344,0.2199707-0.6000977,0.2199707-1.0900879V8.0754395c0-0.4799805-0.0700684-0.8400879-0.2199707-1.0700684
                            C209.5552979,6.7753906,209.2852783,6.6652832,208.8752441,6.6652832z"/>
                        <polygon className="st10" points="216.5053711,11.5651855 215.4353027,5.9052734 214.6652832,5.9052734 213.5952148,11.5354004 
                            212.7553711,5.8752441 211.8353271,5.8752441 213.1252441,13.7553711 213.9553223,13.7553711 215.0653076,7.9152832 
                            216.1652832,13.7553711 216.965332,13.7553711 218.2353516,5.8752441 217.3353271,5.8752441 		"/>
                        <path className="st10" d="M208.8752441,5.8054199c-0.7700195,0-1.3199463,0.2099609-1.6599121,0.6298828
                            c-0.3400879,0.4301758-0.5,1.0400391-0.5,1.8601074v2.9899902c0,0.8300781,0.1599121,1.4599609,0.5,1.8999023
                            c0.3299561,0.4399414,0.8798828,0.6601562,1.6599121,0.6601562c0.75,0,1.3000488-0.2202148,1.6401367-0.6601562
                            c0.3398438-0.4399414,0.5100098-1.0698242,0.5100098-1.8898926v-3c0-0.8100586-0.170166-1.4299316-0.5-1.8500977
                            C210.1853027,6.0153809,209.6352539,5.8054199,208.8752441,5.8054199z M209.9152832,11.5253906
                            c0,0.4899902-0.0700684,0.8498535-0.2199707,1.0900879c-0.1400146,0.2397461-0.4100342,0.3598633-0.8200684,0.3598633
                            c-0.4199219,0-0.6999512-0.1201172-0.8399658-0.3598633c-0.1400146-0.2402344-0.2099609-0.6000977-0.2099609-1.0900879V8.0754395
                            c0-0.4799805,0.0699463-0.8400879,0.2099609-1.0700684s0.4200439-0.3400879,0.8399658-0.3400879
                            c0.4100342,0,0.6800537,0.1101074,0.8200684,0.3400879c0.1499023,0.2299805,0.2199707,0.5900879,0.2199707,1.0700684V11.5253906z"
                        />
                        <polygon className="st10" points="204.7053223,11.1052246 202.465332,5.8752441 201.7253418,5.8752441 201.7253418,13.7553711 
                            202.6552734,13.7553711 202.6552734,8.3752441 204.925293,13.7553711 205.6252441,13.7553711 205.6252441,5.8752441 
                            204.7053223,5.8752441 		"/>
                        <polygon className="st10" points="197.3452148,11.1052246 195.1052246,5.8752441 194.3552246,5.8752441 194.3552246,13.7553711 
                            195.2952881,13.7553711 195.2952881,8.3752441 197.5552979,13.7553711 198.2553711,13.7553711 198.2553711,5.8752441 
                            197.3452148,5.8752441 		"/>
                        <path className="st10" d="M191.2353516,10.6352539h1v0.5400391c0,0.630127-0.0800781,1.0900879-0.2299805,1.3701172
                            c-0.1501465,0.2897949-0.4300537,0.4299316-0.8400879,0.4299316c-0.4199219,0-0.6999512-0.1298828-0.8399658-0.3901367
                            c-0.1500244-0.2597656-0.2200928-0.6999512-0.2200928-1.2998047V8.3654785c0-0.3901367,0.0200195-0.7001953,0.0600586-0.9301758
                            c0.0500488-0.2399902,0.1400146-0.4199219,0.2900391-0.5600586c0.1499023-0.1398926,0.3800049-0.2099609,0.6799316-0.2099609
                            c0.2900391,0,0.5,0.0500488,0.6500244,0.1499023c0.1400146,0.1101074,0.2401123,0.2602539,0.2800293,0.4702148
                            c0.0499268,0.1999512,0.0699463,0.4899902,0.0699463,0.869873v0.2399902h1.0300293V8.1052246
                            c0-0.7900391-0.1599121-1.369873-0.4799805-1.7397461c-0.3200684-0.3701172-0.8400879-0.5600586-1.5700684-0.5600586
                            c-0.539917,0-0.9599609,0.1098633-1.2700195,0.3198242c-0.3099365,0.2099609-0.5198975,0.5100098-0.6499023,0.9001465
                            c-0.1300049,0.3999023-0.1899414,0.8999023-0.1899414,1.5100098v2.6298828c0,0.8901367,0.1599121,1.5600586,0.4799805,2.0100098
                            c0.3099365,0.4499512,0.8299561,0.6799316,1.5400391,0.6799316c0.6899414,0,1.1599121-0.3398438,1.3999023-1l0.1099854,0.9001465
                            h0.6900635V9.8552246h-1.9899902V10.6352539z"/>
                        <rect x="186.7952881" y="5.8752441" className="st10" width="1.0799561" height="7.880127" />
                        <path className="st10" d="M184.7553711,9.7553711l-1.3300781-1.170166c-0.1899414-0.1599121-0.3300781-0.3198242-0.3999023-0.4799805
                            c-0.0800781-0.1599121-0.1201172-0.3498535-0.1201172-0.5898438c0-0.2800293,0.0700684-0.5,0.2199707-0.6401367
                            c0.1501465-0.1499023,0.3701172-0.2199707,0.6501465-0.2199707c0.369873,0,0.6298828,0.1201172,0.7799072,0.3701172
                            s0.2299805,0.6098633,0.2700195,1.0700684l0.9300537-0.25c-0.0600586-0.7001953-0.2600098-1.2102051-0.5700684-1.5400391
                            c-0.3200684-0.3300781-0.7900391-0.5-1.4099121-0.5c-0.5700684,0-1.0200195,0.1599121-1.3601074,0.4799805
                            c-0.3299561,0.3198242-0.4899902,0.7600098-0.4899902,1.3398438c0,0.3901367,0.0600586,0.7202148,0.1799316,0.9902344
                            c0.1201172,0.2697754,0.3100586,0.5197754,0.5800781,0.75l1.3200684,1.1699219
                            c0.2999268,0.2600098,0.5200195,0.5,0.6499023,0.7099609c0.1400146,0.2099609,0.2099609,0.4799805,0.2099609,0.8100586
                            c0,0.2998047-0.0799561,0.5297852-0.2199707,0.6799316c-0.1499023,0.1599121-0.3599854,0.2399902-0.6499023,0.2399902
                            c-0.380127,0-0.6600342-0.1499023-0.8200684-0.4599609c-0.1699219-0.3200684-0.2700195-0.7199707-0.3000488-1.2299805
                            l-0.9699707,0.2600098c0.0400391,0.7199707,0.2299805,1.2800293,0.5800781,1.6799316
                            c0.3399658,0.4099121,0.8499756,0.6201172,1.5100098,0.6201172c0.5599365,0,1.0100098-0.170166,1.3598633-0.5102539
                            c0.3500977-0.329834,0.5300293-0.7897949,0.5300293-1.369873c0-0.4499512-0.0999756-0.8601074-0.3099365-1.2199707
                            C185.3752441,10.3952637,185.0952148,10.0651855,184.7553711,9.7553711z"/>
                    </g>
                </g>
            </svg>
        );
    }
}