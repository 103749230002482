import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { KEEP_TRACK_TRADEMARK } from '../../../../constants';
import makeGStyles from '../../../../styles/makeGStyles';
import GDialog from '../../../common/GDialog';

const useStyles = makeGStyles(theme => ({
    dialogContent: {
        padding: '20px 16px',
        textAlign: 'unset',
        '& p': {
            lineHeight: 1.25
        }
    },
    dialogPaper: {
        [theme.breakpoints.up(600)]: {
            maxWidth: '500px !important',
            minWidth: '500px !important'
        }
    },
    dialogTitle: {
        '& p': {
            padding: 0,
            display: 'block',
            textAlign: 'left',
            fontSize: 20,
            fontWeight: 400,
            width: '100%'
        }
    }
}), { name: 'FamilySharingExplainerDialog' });

interface Props {
    open: boolean;
    zIndex: number;
    fhName: string;
    onClose: () => void;
}

const FamilySharingExplainerDialog = ({ open, zIndex, fhName, onClose }: Props) => {
    const classes = useStyles();

    return (
        <GDialog
            isOpen={open}
            zIndex={zIndex}
            title="How Does Family Sharing Work?"
            onClose={onClose}
            titleClass={classes.dialogTitle}
            paperClass={classes.dialogPaper}
            contentClass={classes.dialogContent}
        >
            <Typography
                color="secondary"
                align="center"
                className={classNames(classes.fontSize18, classes.marginTop20)}
            >
                Sharing a {KEEP_TRACK_TRADEMARK} Medallion with family members will build trust while also helping
                to build a community around {fhName}.
            </Typography>
            <br /><br />
            <Typography color="secondary">
                Here is an overview of how family sharing works:
            </Typography>
            <br />
            <Typography color="secondary">
                <span className={classes.fontWeight500}>STEP 1:</span> When completing each tracking step on a case,
                you can decide whether or not to make that step visible to trusted family members.
            </Typography>
            <br />
            <Typography color="secondary">
                <span className={classes.fontWeight500}>STEP 2:</span> When inviting each family helper into Gather,
                you can decide whether or not to give them access to view Tracking Steps. Granting this access will only
                allow them to view the tracking steps you made visible in Step 1.
            </Typography>
            <br />
            <Typography color="secondary">
                <span className={classes.fontWeight500}>STEP 3:</span> One of these trusted helpers can then decide for
                themsleves to view the tracking steps. They will only see the name of the tracking step and when it was
                completed (e.g., “Fingerprints Obtained 4 minutes ago”). They will NOT see the details or notes of
                any step.
            </Typography>
            <br />
            <Typography
                align="center"
                color="secondary"
                className={classNames(classes.fontSize16, classes.fontWeight500)}
            >
                A QUICK EXAMPLE
            </Typography>

            <Typography color="secondary" className={classes.fontStyleItalic}>
                Let’s say you’ve completed four tracking steps on a case and while completing each step,
                you decided that you would make these four steps visible to the family. You then invite Amy and
                Bob into Gather. Amy was given access to view tracking steps and Bob was NOT given access.
                <br /><br />
                Amy will be able to see these four steps if she decides she wants to view them. Bob will NOT
                see any steps.
                <br /><br />
                Let’s say you then complete two more tracking steps but this time you decided NOT to share
                with the family. Amy will continue to only see four steps and Bob will continue to NOT see any steps.
            </Typography>
            <br /><br />
            <Typography color="secondary" className={classes.fontWeight500}>
                HOW DOES THE QR FAMILY MEDALLION WORK?
            </Typography>
            <br />

            <Typography color="secondary">
                Family members can hold onto their keepsake medallion forever to help them remember their loved one
                (and the service you provided). The most impactful benefit for most family members is the ability
                to access their loved one’s permanent Remember page to view photos and videos, read and share memories,
                watch the livestream recording, and much more.
                <br /><br />
                When the family keepsake medallion is scanned, it will always direct to the Remember page, and a special
                popup will automatically be displayed, directing family members to further engage with the aspects of
                {KEEP_TRACK_TRADEMARK} to which they have been granted access. If a family member hasn't been granted
                access, they will ONLY be able to view the Remember page.
                <br /><br />
                Once the care process is finalized, the family keepsake medallion will continue to link to the
                Remember page.
            </Typography>

            <Button
                variant="contained"
                className={classNames(classes.displayBlock, classes.marginAuto, classes.marginTop30)}
                onClick={onClose}
            >
                Close this window
            </Button>
        </GDialog>
    );
};

export default FamilySharingExplainerDialog;