import { useEffect, useState } from "react";
import classNames from "classnames";

import Grid from '@mui/material/Grid';

import { useTaskDialogStyles } from "../TaskDialogsStyles";
import { GatherCaseUX, UserProfile } from "../../../../shared/types";
import { SnackBarVariant } from "../../../../types";
import { IconLookup, OnlineAccountDeactivateStep, OnlineAccountType, StepsLookup } from "./utils";

interface Props {
    accountType: OnlineAccountType;
    activeCase: GatherCaseUX;
    userData: UserProfile | null;
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
}

const CloseAccountComponent = ({ accountType, activeCase, userData, setAppSnackbar }: Props) => {
    const classes = useTaskDialogStyles();

    const [step, setStep] = useState(OnlineAccountDeactivateStep.LogInToAccountQueType);

    const Steps = StepsLookup[accountType];

    useEffect(() => {
        return function cleanup() {
            setStep(OnlineAccountDeactivateStep.LogInToAccountQueType);
        };
    }, []);

    if (!Steps) {
        return null;
    }

    const {
        LogInToAccountQue: LoginComponent,
        AccessToEmailQue: AccessComponent,
        ResetPasswordSteps: ResetComponent,
        CanLogin: CanLoginComponent,
        CanNotLogin: CannotLoginComponent
    } = Steps;
    const icon = IconLookup[accountType];

    return (
        <Grid
            item
            xs={12}
            sm={10}
            className={classNames(
                classes.marginAuto,
                classes.textCenter,
                classes.marginBottom20,
                classes.secondStateMainGrid
            )}
        >
            {icon &&
                <Grid className={classNames(classes.marginBottom10, classes.borderBottom)}>
                    <div className={classNames(classes.secondStateIcon, classes.marginAuto)}>
                        {icon}
                    </div>
                </Grid>
            }

            {step === OnlineAccountDeactivateStep.LogInToAccountQueType &&
                <LoginComponent
                    caseFName={activeCase.fname}
                    accountType={accountType}
                    onStepChange={setStep}
                />
            }

            {step === OnlineAccountDeactivateStep.AccessToEmailQueType &&
                <AccessComponent
                    caseFName={activeCase.fname}
                    accountType={accountType}
                    onStepChange={setStep}
                />
            }

            {step === OnlineAccountDeactivateStep.ResetPasswordStepsType &&
                <ResetComponent
                    activeCase={activeCase}
                    onStepChange={setStep}
                />
            }

            {step === OnlineAccountDeactivateStep.CanLoginType &&
                <CanLoginComponent
                    activeCase={activeCase}
                    accountType={accountType}
                    setAppSnackbar={setAppSnackbar}
                    onStepChange={setStep}
                />
            }

            {step === OnlineAccountDeactivateStep.CanNotLoginType &&
                <CannotLoginComponent
                    userData={userData}
                    activeCase={activeCase}
                    accountType={accountType}
                    setAppSnackbar={setAppSnackbar}
                    onStepChange={setStep}
                />
            }
        </Grid>
    );
};

export default CloseAccountComponent;