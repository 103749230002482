import classNames from 'classnames';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import AddIcon from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import makeGStyles from '../../../../styles/makeGStyles';
import { CaseLabelUX } from '../../../../shared/types';

const useStyles = makeGStyles<Props>({
    root: {
        padding: props => props.showAddButton ? '4px 0' : '4px 4px 4px 16px',
        height: props => props.showAddButton ? undefined : 57
    },
    chip: {
        height: 28,
        fontSize: 14,
        padding: '0 4px'
    },
    checkIconContainer: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}, { name: 'CaseLabelListItem' });


interface Props {
    label: CaseLabelUX;
    isSelected: boolean;
    showAddButton?: boolean;
    caseFullName: string | null;
    showEditButton: boolean;
    editLabel: (label: Partial<CaseLabelUX>) => void;
    selectLabel: (label: CaseLabelUX) => void;
    removeLabel: (label: CaseLabelUX) => void;
}
const CaseLabelListItem = (props: Props) => {
    const {
        label,
        isSelected,
        caseFullName,
        showEditButton,
        showAddButton,
        editLabel,
        selectLabel,
        removeLabel
    } = props;
    const classes = useStyles(props);

    const tootlipTitle = isSelected && caseFullName
        ? `Remove this label from ${caseFullName}'s case`
        : '';

    return (
        <ListItem divider={true} className={classes.root}>
            {showAddButton && <Tooltip title={tootlipTitle} placement="top">
                <ListItemIcon className={classNames(classes.marginRight5, classes.fontSize28)}>
                    <IconButton onClick={e => isSelected ? removeLabel(label) : selectLabel(label)}>
                        {isSelected
                            ? <RemoveCircleIcon />
                            : <AddIcon />
                        }
                    </IconButton>
                </ListItemIcon>
            </Tooltip>
            }

            <ListItemText disableTypography className={classes.padding0}>
                <Chip
                    clickable={showEditButton}
                    label={label.name}
                    style={{ background: label.color }}
                    className={classNames(classes.colorWhite, classes.chip)}
                    onClick={e => showEditButton ? editLabel(label) : undefined}
                />
            </ListItemText>

            {showEditButton && <ListItemSecondaryAction>
                <IconButton onClick={e => editLabel(label)}>
                    <Edit />
                </IconButton>
            </ListItemSecondaryAction>}
        </ListItem>
    );
};

export default CaseLabelListItem; 
