export const APP_PRIMARY_COLOR: string = '#345F7F';
export const APP_SECONDARY_COLOR: string = '#757575';

export const ORANGE_COLOR: string = '#ffa000';
export const DARK_ORANGE_COLOR: string = '#d48500';
export const RED_COLOR: string = '#f44336';
export const RED_COLOR_2: string = '#c13e38';

export const GREEN_COLOR: string = '#43a047';
export const GREEN_COLOR_2: string = '#458833';
export const GREEN_COLOR_3: string = '#66aa5b';
export const GREEN_COLOR_4: string = '#2e7d32';
export const GREEN_COLOR_5: string = '#077914';

export const SKYBLUE_COLOR: string = '#66baff';
export const SKYBLUE_COLOR_2: string = '#64bbff';
export const SKYBLUE_COLOR_3: string = '#dcf0ff';

export const GRAY_COLOR: string = '#9e9e9e';
export const GRAY_COLOR_2: string = '#969696';
export const GRAY_COLOR_3: string = '#bdbdbd';
export const GRAY_BLUE_COLOR: string = '#5B6E74';
export const DARK_GRAY_COLOR: string = '#9c9c9c';
export const DARK_GRAY_COLOR_2: string = '#4A4A4A';

export const SUCCESS_COLOR: string = '#228b22';
export const WARNING_COLOR: string = '#ffa000';

export const GO_FUND_ME_COLOR = '#00b964';
export const YOU_TUBE_COLOR = '#eb3323';
export const TUKIOS_COLOR = '#9dda44';

export const GATHER_COLOR_MAIN = '#4798DA';
export const GATHER_COLOR_LIGHT = '#E5F3FF';
export const GATHER_COLOR_DARK = '#325E80';

export const INSURANCE_CARRIER_COLORS = {
    'HOMESTEADERS': "rgb(0,73,144)"
};