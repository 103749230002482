import * as React from 'react';
import classNames from 'classnames';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import UserAvatar from '../../../common/UserAvatar';
import { EntitySummary } from '../../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import withGStyles from '../../../../styles/WithGStyles';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    headerRightSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4px 0px',
        width: '100%',
        cursor: 'pointer',
        borderRadius: '12px 0px 0px 0px',
        '@media (min-width:1300px)': {
            borderRadius: 'unset'
        },
        '& svg': {
            fontSize: 22
        }
    },
    borderBottomSection: {
        color: theme.palette.common.white,
        borderBottom: '1px solid',
        padding: 8
    },
    headerAvatarSize: {
        height: 60,
        width: 60,
        padding: '4px'
    },
    gridListTTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        textAlign: 'center',
        '-webkit-overflow-scrolling': 'touch',
    },
    createNewCaseTile: {
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        verticalAlign: 'middle'
    },
    loadingText: {
        color: theme.palette.common.white,
    },
    loadingSubtitle: {
        visibility: 'hidden'
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lineHeightHeaderTask: {
        lineHeight: 1.25
    },
    whiteSpace: {
        whiteSpace: 'nowrap'
    },
    pendingTaskSubtitle: {
        fontSize: 13,
        '@media (min-width:960px)': {
            fontSize: 14
        }
    },
    borderTopLeftRadius: {
        borderTopLeftRadius: 12,
        '@media (min-width:1300px)': {
            borderTopLeftRadius: 'unset'
        }
    },
    subtitle: {},
});

interface Props {
    isLoading: boolean;
    pendingCount: number;
    teamMember: EntitySummary;
    isMe: boolean;
    openPickTeamMember: (e: React.MouseEvent<HTMLElement>) => void;
}

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'loadingSubtitle'
    | 'headerRightSection' | 'borderBottomSection' | 'whiteSpace' | 'lineHeightHeaderTask'
    | 'headerAvatarSize' | 'subtitle' | 'pendingTaskSubtitle' | 'borderTopLeftRadius'
    | 'gridListTTitle' | 'createNewCaseTile' | 'loadingText' | 'displayFlex'>;

const TaskListHeader = (props: StyledProps) => {
    const { classes, isLoading, teamMember, pendingCount, isMe, openPickTeamMember } = props;

    const taskText = `${isMe ? 'You' : teamMember.fname} ${isMe ? 'Have' : 'Has'} ` +
        `${pendingCount} Pending Task${pendingCount === 1 ? '' : 's'}`;

    return (
        <ListItem
            button
            className={classNames(
                classes.headerRightSection,
                classes.borderBottomSection
            )}
            onClick={openPickTeamMember}
        >
            <Tooltip
                title={`Click to view another team member's tasks`}
                placement="top"
            >
                <Typography component="p" className={classNames(classes.fontSize18, classes.displayFlex)}>
                    <PersonSearchIcon className={classes.fontSize22} />
                    &nbsp;Pending Tasks <ArrowDropDownIcon />
                </Typography>
            </Tooltip >
            <UserAvatar
                size={60}
                user={teamMember}
                className={classes.headerAvatarSize}
            />
            {isLoading
                ?
                <ImageListItem
                    className={classNames(classes.gridListTTitle, classes.loadingText)}
                    classes={{ img: classes.createNewCaseTile }}
                >
                    <CircularProgress
                        color="inherit"
                        size={18}
                        thickness={3}
                    />
                    <Typography
                        align="center"
                        className={classNames(
                            classes.loadingText,
                            classes.fontSize18,
                            classes.whiteSpace
                        )}
                    >
                        &nbsp;Loading Tasks
                    </Typography>
                </ImageListItem>
                :
                <Typography
                    component="p"
                    className={classNames(
                        classes.fontSize18,
                    )}
                    color="primary"
                >
                    {taskText}
                </Typography>
            }
            <Typography
                component="p"
                color="primary"
                className={classNames(
                    classes.pendingTaskSubtitle,
                    classes.lineHeightHeaderTask,
                    isLoading && classes.loadingSubtitle
                )}
            >
                Click above to view other team members' tasks.
            </Typography>
        </ListItem>
    );
};

export default withGStyles(styles)(TaskListHeader);
