import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import {
    CaseTaskUX,
    S3FileCreateRequest,
    TaskLocationSummary,
    TaskLocationUX,
    TaskUserType,
    TrackingStepMoveRequest,
    UserProfile
} from "../../../../../shared/types";
import LocationItem, { LocationItemState } from "../../QRStepper/selectLocation/LocationItem";
import makeGStyles from "../../../../../styles/makeGStyles";
import StepComponentWrapper from "../completeStepDialog/StepComponentWrapper";
import BaseStepDialog from "../baseStepDialog/BaseStepDialog";
import ReactSignatureCanvas from "react-signature-canvas";
import CompleteStepDialogFooter from "../completeStepDialog/CompleteStep.dialogFooter";
import { getIntercomTargetProp, joinNameParts } from "../../../../../services";
import { useGDispatch } from "../../../../../types";
import { log } from "../../../../../logger";
import SignatureComponent from "../completeStepDialog/components/SignatureComponent";
import PerformerComponent from "../completeStepDialog/components/PerformerComponent";
import ParagraphComponent from "../completeStepDialog/components/ParagraphComponent";
import { GREEN_COLOR } from "../../../../../constants/colorVariables";
import { createMoveTrackingStep } from "../../../../../actions/task/Task.action";
import { uploadSignatureFromRef } from "../../utils";
import FamilySharing, { FamilySharingSwitch } from "../../FamilySharing";

const useStyles = makeGStyles(theme => ({
    arrowIcon: {
        position: 'relative',
        fontSize: 72,
        marginTop: 16,
        [theme.breakpoints.up(375)]: {
            top: -8,
            marginTop: 0,
            fontSize: 120
        }
    },
    padding_0_12: {
        padding: '0 12px'
    },
    paddingBottom8: {
        paddingBottom: 8
    },
    stepContainer: {
        '& button': {
            border: `2px solid ${GREEN_COLOR}`,
            '& .location-casesInLocation': {
                borderLeft: `2px solid ${GREEN_COLOR}`,
                borderTop: `2px solid ${GREEN_COLOR}`,
            }
        }
    },
    dialogContent: {
        paddingBottom: '28px !important'
    }
}), { name: 'MoveLocationDialog' });

interface Props {
    fhName: string;
    caseFName: string;
    caseUuid: string;
    user: UserProfile;
    zIndex: number;
    open: boolean;
    currentLocationSummary: TaskLocationSummary | null;
    currentLocation: TaskLocationUX | null;
    targetLocation: TaskLocationUX;
    existingStep?: CaseTaskUX;
    onClose: VoidFunction;
    onTaskCompleted?: (location: TaskLocationUX | null, signature: S3FileCreateRequest, note: string | null) => void;
}

const MoveLocationDialog = (props: Props) => {
    const {
        open,
        zIndex,
        targetLocation,
        currentLocation,
        currentLocationSummary,
        caseFName,
        caseUuid,
        user,
        existingStep,
        fhName,
        onClose,
        onTaskCompleted,
    } = props;

    const {
        note,
        marked_complete_by,
        performed_by,
        marked_complete_time,
        visible_to_family,
        signature_url,
    } = existingStep || {};

    const classes = useStyles();

    const dispatch = useGDispatch();

    const [performedBy, setPerformedBy] = useState<TaskUserType | null>(null);
    const [error, setError] = useState(false);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [hasSigned, setHasSigned] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [saving, setSaving] = useState(false);
    const [visibleToFamily, setVisibleToFamily] = useState<boolean>(false);
    const signatureRef = useRef<ReactSignatureCanvas | null>(null);

    const isStepCompleted = !!marked_complete_time;
    const targetLocationName = targetLocation.name;
    const dialogTitle = `${isStepCompleted ? 'Moved' : 'Move'} to ${targetLocationName}`;

    useEffect(() => {
        if (performed_by) {
            setPerformedBy(performed_by);
        } else if (!marked_complete_time) {
            setPerformedBy(user);
        } else {
            // if this is a completed step and we don't have a performer than assume no performer
            setPerformedBy(null);
        }
    }, [performed_by, user, marked_complete_time]);

    useEffect(() => {
        setMessage(note || '');
    }, [note]);

    useEffect(() => {
        setVisibleToFamily(visible_to_family ?? false);
    }, [visible_to_family]);

    const familySharingSwitch: FamilySharingSwitch = useMemo(() => ({
        name: `Moved to ${targetLocationName}`,
        isVisible: visibleToFamily,
        setVisibility: setVisibleToFamily,
    }), [targetLocationName, visibleToFamily, setVisibleToFamily]);

    const hasError = () => {
        return (!!signatureRef.current?.isEmpty());
    };

    const resetDialog = () => {
        setHasSigned(false);
        setError(false);
        setMessage('');
        setPerformedBy(null);
        setSaveAttempted(false);
        setSaving(false);
        setVisibleToFamily(false);
        signatureRef.current?.clear();
        signatureRef.current?.on();
    };

    const closeDialog = () => {
        resetDialog();
        onClose();
    };

    const handleTaskComplete = async () => {
        if (isStepCompleted) {
            closeDialog();
        }

        if (hasError()) {
            setHasSigned(false);
            setError(true);
            setSaveAttempted(true);
            return;
        }

        if (!signatureRef.current) {
            return;
        }

        setSaving(true);
        setError(false);
        try {

            const signature = await uploadSignatureFromRef({
                ref: signatureRef.current,
                dispatch,
                caseUuid,
            });
            if (signature) {

                const moveRequest: TrackingStepMoveRequest = {
                    task_location_id: targetLocation?.id ?? null,
                    signature,
                    note: message.trim() ?? null,
                    visible_to_family: visibleToFamily,
                };
                await dispatch(createMoveTrackingStep({
                    moveRequest,
                    taskLocation: targetLocation,
                    caseUuid,
                }));
                onTaskCompleted?.(targetLocation, signature, message.trim() || null);
            }
        } catch (err) {
            log.error('Error occured while generating signature', { error: err });
        }
        setSaving(false);
        onClose();
        resetDialog();
    };

    const saveSignature = () => {
        setHasSigned(true);
    };
    const clearSignature = () => {
        signatureRef.current?.clear();
        signatureRef.current?.on();
        setHasSigned(false);
    };

    const userFullName = joinNameParts(user);

    return (
        <BaseStepDialog
            zIndex={zIndex}
            entityFullName={isStepCompleted ? joinNameParts(marked_complete_by ?? {}) : userFullName}
            open={open}
            closeDialog={closeDialog}
            stepIcon={null}
            stepImagePublicId={targetLocation.cover_photo}
            stepImageFallbackURL={targetLocation.cover_fallback_url}
            stepTitle={dialogTitle}
            isStepCompleted={isStepCompleted}
            dialogContentClass={classes.dialogContent}
            intercomTargetProp={`CompleteKeepTrackStep-TargetLocationIcon`}
            intercomTargetPropDialogName={`MoveLocationDialog`}
            intercomTargetPropTitle={`CompleteKeepTrackStep-ActionTitle`}
            intercomTargetPropCancel={`CompleteKeepTrackStep-CancelMoveButton`}
        >
            {isStepCompleted &&
                <ParagraphComponent
                    className={classNames(classes.marginTop24, classes.paddingBottom8)}
                    align="center"
                    text={` This step has been marked completed. For your protection, once a step is marked completed,
                it can't be undone. The following shows what was specified at the time of completion.`}
                />
            }

            <PerformerComponent
                completedBy={marked_complete_by}
                completedTime={marked_complete_time}
                user={user}
                stepTitle={dialogTitle}
                performedBy={performedBy}
                zIndex={0}
                isDisabled
                onAssignPerformer={setPerformedBy}
                intercomTargetPropDialogName={`MoveLocationConfirmation`}
            />

            <StepComponentWrapper hideBorder className={classes.padding_0_12}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="flex-start"
                    className={isStepCompleted ? classes.stepContainer : undefined}
                >
                    {currentLocation &&
                        <>
                            <LocationItem
                                disableGutters
                                state={LocationItemState.current}
                                locationMovedTime={currentLocationSummary?.moved_time}
                                location={currentLocation}
                                caseFName={caseFName}
                                hideTooltips
                                intercomTargetProp={`CompleteKeepTrackStep-CurrentLocation-MoveLocationConfirmation`}
                            />

                            <ArrowRightAltIcon className={classes.arrowIcon} color="primary" />
                        </>
                    }
                    <LocationItem
                        disableGutters
                        state={LocationItemState.default}
                        locationMovedTime={null}
                        caseFName={caseFName}
                        location={targetLocation}
                        hideTooltips
                        intercomTargetProp={`CompleteKeepTrackStep-TargetLocation-MoveLocationConfirmation`}
                    />
                </Grid>

                <TextField
                    fullWidth
                    value={message}
                    className={classes.marginTop20}
                    variant="outlined"
                    label="Record optional placement details..."
                    disabled={isStepCompleted}
                    onChange={({ currentTarget }) => !isStepCompleted && setMessage(currentTarget.value)}
                    {...getIntercomTargetProp(
                        `CompleteKeepTrackStep-OptionalPlacementDetails-MoveLocationConfirmation`)}
                />
            </StepComponentWrapper>

            <FamilySharing
                zIndex={zIndex + 1}
                fhName={fhName}
                switchOne={familySharingSwitch}
                switchTwo={undefined}
                isDisabled={isStepCompleted}
                intercomTargetPropComponent={`CompleteKeepTrackStep-FamilySharing-MoveLocationConfirmation`}
                intercomTargetPropToggle={`CompleteKeepTrackStep-FamilySharingToggle-MoveLocationConfirmation`}
                intercomTargetPropFooter={`CompleteKeepTrackStep-FamilySharingFooter-MoveLocationConfirmation`}
            />

            <SignatureComponent
                signatureUrl={signature_url ?? null}
                heading="I confirm this move was completed properly"
                signerFullName={isStepCompleted ? joinNameParts(marked_complete_by ?? {}) : userFullName}
                hasSignError={error && saveAttempted}
                hasSigned={hasSigned}
                canvasRef={signatureRef}
                onStopDrawing={saveSignature}
                clearSignature={clearSignature}
                intercomTargetProp={`CompleteKeepTrackStep-SignatureComponent-MoveLocationConfirmation`}
                intercomTargetPropClearSignature={`CompleteKeepTrackStep-ClearSignature-MoveLocationConfirmation`}
            />

            <CompleteStepDialogFooter
                hasError={saveAttempted && hasError()}
                user={user}
                performedBy={performedBy}
                stepTitle={dialogTitle}
                isSaving={saving}
                completedBy={marked_complete_by}
                onCompleteTaskClick={handleTaskComplete}
                intercomTargetProp={`CompleteKeepTrackStep-CompleteStepButton-MoveLocationConfirmation`}
                intercomTargetPropMarkedComplete={`CompleteKeepTrackStep-MarkedCompleteBy-MoveLocationConfirmation`}
                intercomTargetPropPerformedBy={`CompleteKeepTrackStep-PerformedBy-MoveLocationConfirmation`}
                intercomTargetPropAllStepsCustomizable={
                    `CompleteKeepTrackStep-AllStepsCustomizable-MoveLocationConfirmation`}
            />

        </BaseStepDialog>
    );
};

export default MoveLocationDialog; 
