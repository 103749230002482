import * as appService from '../../../services/app.service';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import MailOutline from '@mui/icons-material/MailOutline';
import Person from '@mui/icons-material/Person';
import Phone from '@mui/icons-material/Phone';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { GatherCaseUX, UserProfile } from '../../../shared/types';
import { GLOBAL_STYLED_PROPS } from '../../../styles';
import withGStyles from '../../../styles/WithGStyles';
import { SpeedDialActionType } from '../../common/SpeedDialMenu';

interface Props {
    activeCase: GatherCaseUX | null;
    isInvitedFamilyOnCase: boolean;
    isFamilyOnCase: boolean;
    userData: UserProfile;
    zIndex: number;
    closePopper: () => void;
    logoutUserSession: () => void;
    openMySettingsDialog: () => void;
    renderStartupMessageItem: () => JSX.Element;
    speedDialActions: SpeedDialActionType[];
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    customHref: {
        textDecoration: 'none',
        color: 'inherit',
    },
    intercomIcon: {
        height: 24,
    }
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'customHref' | 'intercomIcon'>;

const GuestSection = (props: StyledProps) => {
    const {
        classes,
        activeCase,
        closePopper,
        isInvitedFamilyOnCase,
        isFamilyOnCase,
        renderStartupMessageItem,
        openMySettingsDialog,
        speedDialActions
    } = props;

    const renderEmail = () => {
        let mailToEmail: string = '';
        let mailToSubject: string = '';
        let assigneeHasEmail: boolean = false;

        if (activeCase && activeCase.assignee) {
            mailToEmail = activeCase.assignee.email || '';
            mailToSubject = encodeURIComponent(`Help needed with ${activeCase.fname} ${activeCase.lname}'s Profile`);
            assigneeHasEmail = activeCase.assignee.email !== null && activeCase.assignee.email !== '';
        }
        return (
            <Tooltip
                id="tooltip-email"
                title={<>{activeCase ? activeCase.assignee.email : ''}</>}
                placement="top"
            >
                <a
                    href={`mailto:${mailToEmail}?subject=${mailToSubject}`}
                    className={classes.customHref}
                    target="_blank"
                    rel="noreferrer"
                >
                    <ListItem
                        disabled={!assigneeHasEmail}
                        onClick={closePopper}
                        button
                    >
                        <ListItemIcon>
                            <MailOutline color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Email Funeral Home"
                            classes={
                                { primary: classes.colorPrimary }
                            }
                        />
                    </ListItem>
                </a>
            </Tooltip>
        );
    };

    const renderIsInvitedFamilyOnCase = () => {
        let phoneNumber: string | undefined = undefined;

        let assigneeHasPhoneNumber: boolean = false;
        let fhHasPhoneNumber: boolean = false;
        let phoneNumbeToolTip: string = '';

        if (activeCase && activeCase.assignee) {
            assigneeHasPhoneNumber = activeCase.assignee.phone !== null && activeCase.assignee.phone !== '';
            fhHasPhoneNumber = activeCase.funeral_home.phone !== null && activeCase.funeral_home.phone !== '';

            phoneNumbeToolTip = activeCase.assignee.phone
                ? activeCase.assignee.phone
                : fhHasPhoneNumber && activeCase.funeral_home.phone
                    ? activeCase.funeral_home.phone
                    : `No number available`;

            phoneNumber = activeCase.assignee.phone ? `tel:${activeCase.assignee.phone}` : fhHasPhoneNumber
                ? `tel:${activeCase.funeral_home.phone}` : undefined;
        }
        return (
            <>
                <Tooltip
                    id="tooltip-phone"
                    title={<>{appService.getFormattedPhoneNumber(phoneNumbeToolTip)}</>}
                    placement="top"
                >
                    <a
                        href={phoneNumber}
                        className={classes.customHref}
                    >
                        <ListItem
                            disabled={!assigneeHasPhoneNumber
                                || !fhHasPhoneNumber}
                            onClick={closePopper}
                            button
                        >
                            <ListItemIcon>
                                <Phone color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Call Funeral Home"
                                classes={{
                                    primary: classes.colorPrimary
                                }}
                            />
                        </ListItem>
                    </a>
                </Tooltip>
                {renderEmail()}
            </>
        );
    };

    const renderIsFamilyOnCase = () => {
        return (
            <ListItem
                button
                onClick={openMySettingsDialog}
            >
                <ListItemIcon>
                    <Person color="primary" />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.colorPrimary }}
                    primary="My Settings"
                />
            </ListItem>);
    };

    const renderSpeedDialAction = (action: SpeedDialActionType) => {
        return (
            <ListItem
                className={action.gaClassName}
                button
                key={action.id}
                onClick={event => {
                    action.callBack();
                    closePopper();
                }}
            >
                <ListItemIcon>
                    {action.icon}
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.colorPrimary }}
                    primary={action.name}
                />
            </ListItem>
        );
    };

    return (
        <Grid item xs={12} className={classes.marginTop8} >
            <Divider />
            <List component="nav">
                {isInvitedFamilyOnCase && renderIsInvitedFamilyOnCase()}
                {isFamilyOnCase && renderIsFamilyOnCase()}
                {speedDialActions.reverse().map(renderSpeedDialAction)}
                {renderStartupMessageItem()}
            </List>
            <Divider />
        </Grid>
    );
};

export default withGStyles(styles)(GuestSection);
