import { useEffect, useState } from 'react';
import { CaseLabelUX } from '../../../../shared/types';
import { useGDispatch, useGSelector } from '../../../../types';
import {
    addLabelToCase,
    createCaseLabelOnFH,
    deleteCaseLabelFromFH,
    loadCaseLabelsForFuneralHome,
    removeLabelFromCase,
    updateCaseLabelOnFH,
} from '../../../../actions/CaseLabel.action';
import AddLabelPureDialog, { AddLabelPureDialogProps } from './AddLabelPure.dialog';

interface Props
    extends Pick<AddLabelPureDialogProps, 'zIndex' | 'isOpen' | 'funeralHomeId' | 'gatherCase' | 'onClose'> {
    addLabelToCase?: (caseUuid: string, label: CaseLabelUX) => void;
    removeLabelFromCase?: (caseUuid: string, label: CaseLabelUX) => void;
}

function AddLabelDialog(props: Props) {
    const { isOpen, gatherCase, funeralHomeId, ...rest } = props;

    const dispatch = useGDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const labels = useGSelector(({ funeralHomeState }) => funeralHomeState.activeCaseLabels);
    const user = useGSelector(({ userSession }) => userSession.userData);

    const handleDeleteLabel = (caseUuid: string, label: CaseLabelUX) => {
        dispatch(removeLabelFromCase(caseUuid, label));
        if (props.removeLabelFromCase) {
            props.removeLabelFromCase(caseUuid, label);
        }
    };

    const handleAddLabel = (caseUuid: string, label: CaseLabelUX) => {
        dispatch(addLabelToCase(caseUuid, label));
        if (props.addLabelToCase) {
            props.addLabelToCase(caseUuid, label);
        }
    };

    useEffect(() => {
        const loadLabels = async () => {
            setIsLoading(true);
            await dispatch(loadCaseLabelsForFuneralHome(funeralHomeId));
            setIsLoading(false);
        };
        if (isOpen) {
            loadLabels();
        }
    }, [dispatch, funeralHomeId, isOpen]);

    if (!user) {
        return null;
    }

    const handleDeleteLabelFromFH = (label: CaseLabelUX) => {
        dispatch(deleteCaseLabelFromFH(label));
    };

    const handleCreateLabelOnFH = (params: { label: string; color: string }) => {
        const { label, color } = params;
        dispatch(createCaseLabelOnFH(funeralHomeId, label, color));
    };

    const handleUpdateLabelOnFH = (params: { labelId: number; name: string; color: string }) => {
        const { labelId, name, color } = params;
        dispatch(updateCaseLabelOnFH(labelId, funeralHomeId, { color, name }));
    };

    return (
        <AddLabelPureDialog
            {...rest}
            onRemoveLabelFromCase={handleDeleteLabel}
            onAddLabelToCase={handleAddLabel}
            onCreateLabelOnFH={handleCreateLabelOnFH}
            onUpdateLabelOnFH={handleUpdateLabelOnFH}
            onDeleteLabelFromFH={handleDeleteLabelFromFH}
            isLoading={isLoading}
            labels={labels}
            user={user}
            isOpen={isOpen}
            gatherCase={gatherCase}
            funeralHomeId={funeralHomeId}
        />
    );
}

export default AddLabelDialog;
