import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { getIntercomTargetProp } from '../../services';
import { useGStyles } from './hooks/useGStyles';

export interface GButtonProps extends ButtonProps {
    icon?: JSX.Element;
    text?: string | JSX.Element;
    isSpinning?: boolean;
    component?: keyof JSX.IntrinsicElements;
    intercomTargetProp?: string;
}

const GButton = (props: PropsWithChildren<GButtonProps>, ref: ForwardedRef<HTMLButtonElement>) => {
    const { text, children, icon, isSpinning, disabled, intercomTargetProp, ...others } = props;

    const classes = useGStyles();

    let content: JSX.Element;
    if (children) {
        content = <>{children}</>;
    } else if (icon) {
        content = <>{icon}&nbsp;{text}</>;
    } else {
        content = <>{text}</>;
    }

    return (
        <Button
            color="primary"
            variant="contained"
            disabled={disabled || isSpinning}
            {...others}
            ref={ref}
            {...getIntercomTargetProp(intercomTargetProp)}
        >
            {content}
            {isSpinning && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
    );
};

export default forwardRef(GButton);
