import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';
import { AvatarUser, EntitySummary } from '../../shared/types';
import { joinNameParts } from '../../shared/utils';
import { GStyles } from '../../styles/GStyles';
import AssignmentPopperForEntitySummary from './AssignmentPopperForEntitySummary';
import { AssignStyleProps, styleWrapper } from './Style';

export enum DCEntityEnum {
    Father = 'Father',
    Mother = 'Mother',
    Spouse = 'Spouse',
    Informant = 'Informant'
}

interface Props {
    zIndex: number;
    relationToDeceased: DCEntityEnum;
    setHelper: (e: React.MouseEvent<HTMLElement> | null, helper: EntitySummary | null) => void;
    selectedHelper: EntitySummary | null;
    caseFname: string;
    helpers: EntitySummary[];
    openInvitationDialog: (zIndex: number) => void;
    isDisabled: boolean;
    disabledTooltip: string;
    addButtonClass?: string;
}
type StyledProps = AssignStyleProps;

const SelectHelper = (props: Props & StyledProps) => {
    const {
        classes,
        helpers,
        caseFname,
        relationToDeceased,
        selectedHelper,
        zIndex,
        setHelper,
        openInvitationDialog,
        isDisabled,
        disabledTooltip,
        addButtonClass
    } = props;

    const getButtonText = () => {
        let label: string | JSX.Element = '';
        let assignedTo: AvatarUser | null = null;

        const selectedEntityId = selectedHelper && selectedHelper.entity_id;
        const helper = helpers.find((h) => h.entity_id === selectedEntityId);

        if (helper) {
            assignedTo = {
                fname: helper.fname,
                lname: helper.lname,
                photo: helper.photo,
                photo_transformations: helper.photo_transformations,
            };

            label = (
                <span>
                    <span className={GStyles.textUnderline}>
                        {joinNameParts(helper)}
                    </span>
                </span>
            );
        } else {
            label = `Who is ${relationToDeceased === DCEntityEnum.Informant
                && 'the' || `${caseFname}'s`} ${relationToDeceased}?`;
        }

        return {
            label,
            assignedTo: assignedTo && [assignedTo] || []
        };
    };

    const addButtonText = relationToDeceased === DCEntityEnum.Informant
        && `Add Informant's Name`
        || `Add ${caseFname}'s ${relationToDeceased}`;
    const headerText = relationToDeceased === DCEntityEnum.Informant
        && `Select Informant`
        || `Select ${caseFname}'s ${relationToDeceased}`;

    return (
        <AssignmentPopperForEntitySummary
            zIndex={zIndex}
            headerText={headerText}
            addButtonText={addButtonText}
            items={helpers}
            onAdd={(_zIndex) => openInvitationDialog(_zIndex)}
            setAssignee={(entity) => setHelper(null, entity)}
            shouldClosePopperOnInvite
            canUnassign
            buttonText={getButtonText()}
            popoverTextClass={classes.assignButton}
            popoverContainerClass={classNames(GStyles.textCenter, classes.selectHelperPopoverElement)}
            isDisabled={isDisabled}
            disabledTooltip={disabledTooltip}
            addButtonClass={addButtonClass}
            showFamilyHelpers={true}
            showTeamMembers={false}
        />
    );
};

export default withStyles(styleWrapper<Props>())(SelectHelper);
