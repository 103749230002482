import { useState } from 'react';

import ClearAllIcon from '@mui/icons-material/ClearAll';

import GMenu, { GMenuItem } from '../../../common/GMenu';
import AssignTask from '../../../assignmentPoppers/AssignTask';
import { GatherCaseUX } from '../../../../shared/types';
import ReminderPopper from '../../tasks/ReminderPopper';
import { useGSelector } from '../../../../types';
import { canCompleteTrackingSteps, canSkipTrackingSteps } from '../../../../shared/authority/can';
import { TOOLTIPS } from '../../../../constants';
import Grid from '@mui/material/Grid';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { getStandardDateText } from '../../../../services';
import moment from 'moment-timezone';
import makeStyles from '@mui/styles/makeStyles';
import { GStyles } from '../../../../styles/GStyles';

const useStyles = makeStyles(
    (theme) => ({
        assignTask: {
            padding: 0,
            display: 'block',
            '& $assignTaskText': {
                height: 46,
                boxSizing: 'border-box',
                width: 'unset',
                padding: '11px 16px',
            },
        },
        assignTaskText: {},
    }),
    { name: 'QRPendingStepsMenu' },
);

interface ActiveStep {
    label: string | JSX.Element;
    stepId: number | undefined;
    completedByTime?: Date | null;
    onClick?: () => void;
}

interface Props {
    zIndex: number;
    selectedCase: GatherCaseUX;
    toggleBulkEditMode?: VoidFunction;
    activeStep: ActiveStep;
    menuAnchor: HTMLElement | null;
    closeMenu: VoidFunction;
    openSkipStepDialog: (stepId: number) => void;
    onDueDateClick: (stepId: number) => void;
}
const QRPendingStepsMenu = (props: Props) => {
    const classes = useStyles();

    const {
        selectedCase,
        zIndex,
        toggleBulkEditMode,
        activeStep,
        menuAnchor,
        closeMenu,
        openSkipStepDialog,
        onDueDateClick,
    } = props;

    const [reminderPopperAnchor, setReminderPopperAnchor] = useState<HTMLElement | null>(null);
    const userProfile = useGSelector(({ userSession }) => userSession.userData);
    const funeralHome = useGSelector(({ funeralHomeState }) => funeralHomeState.activeFuneralHome);
    const canCompleteTracking = funeralHome && canCompleteTrackingSteps(userProfile, funeralHome.id);
    const canSkip = funeralHome && canSkipTrackingSteps(userProfile, funeralHome.id);
    const activeTrackingStep = useGSelector(({ tasksState }) =>
        tasksState.trackingSteps.find((step) => step.id === activeStep.stepId),
    );

    const closeMenuAndPerformAction = (action?: VoidFunction) => {
        if (action) {
            action();
        }
        closeMenu();
    };

    const menuItems: GMenuItem[] = [
        {
            id: 2,
            className: classes.assignTask,
            content: activeTrackingStep && (
                <AssignTask
                    showAssignTaskLink
                    activeCase={selectedCase}
                    zIndex={zIndex + 1}
                    taskLinkTextClass={classes.assignTaskText}
                    closeMenu={closeMenu}
                    task={activeTrackingStep}
                />
            ),
        },
        {
            id: 3,
            content: 'Send a Reminder',
            onClick: (event) => setReminderPopperAnchor(event.currentTarget),
        },
        {
            id: 4,
            content: 'Skip Step',
            disabled: !canSkip,
            tooltip: !canSkip ? TOOLTIPS.DISABLED_FEATURE : undefined,
            onClick: () =>
                activeStep &&
                closeMenuAndPerformAction(
                    () => activeStep.stepId !== undefined && openSkipStepDialog(activeStep.stepId),
                ),
        },
    ];

    if (activeStep) {
        menuItems.unshift({
            id: 1,
            content: activeStep.label,
            disabled: !canCompleteTracking,
            tooltip: !canCompleteTracking ? TOOLTIPS.DISABLED_FEATURE : undefined,
            onClick: () => closeMenuAndPerformAction(activeStep.onClick),
        });
    }

    if (activeStep.completedByTime !== undefined) {
        const dueDateString =
            activeStep.completedByTime &&
            getStandardDateText({
                timezone: moment.tz.guess(),
                targetDate: activeStep.completedByTime,
                prefix: 'Due',
                pastPrefix: ':',
            });

        menuItems.push({
            id: 'update-due-date',
            onClick: () =>
                closeMenuAndPerformAction(() => activeStep.stepId !== undefined && onDueDateClick(activeStep.stepId)),
            content: (
                <Grid container component="span" alignItems="center">
                    <EventAvailableIcon />
                    &nbsp; {dueDateString ? 'Edit' : 'Add'}
                    {' Due Date'}
                </Grid>
            ),
        });
    }

    if (toggleBulkEditMode) {
        menuItems.push({
            id: 5,
            className: GStyles.colorOrange,
            content: (
                <>
                    <ClearAllIcon />
                    &nbsp;Bulk Edit Steps
                </>
            ),
            onClick: () => closeMenuAndPerformAction(toggleBulkEditMode),
        });
    }

    return (
        <>
            <GMenu items={menuItems} anchorEl={menuAnchor} onClose={closeMenu} zIndex={zIndex} />

            {activeTrackingStep && (
                <ReminderPopper
                    isActive
                    zIndex={zIndex + 1}
                    selectedCase={selectedCase}
                    task={activeTrackingStep}
                    anchorEle={reminderPopperAnchor}
                    closePopover={() => setReminderPopperAnchor(null)}
                />
            )}
        </>
    );
};

export default QRPendingStepsMenu;
