import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import {
    ModerationStatus,
    ModerationCategory,
    ModerationCaseDetails,
    ModerationItem,
    ModerationPhotoUX,
    ModerationMemoryUX,
    ModerationVisitorUX,
    isModerationVisitorList,
    isModerationMemoryList,
    isModerationPhotoList,
    ModerationDecisionRequest,
    ModerationDecisionRequestItem,
    ModerationCategoryCounts,
    isModerationPhoto,
    isModerationMemory,
    isModerationVisitor,
} from '../../../shared/types/moderation';
import ModerationCaseCard from '../dashboard/ModerationCaseCard';
import ModerationContent from './ModerationContent';
import { StoreState } from '../../../types';
import { makeModerationDecision, RetrieveModerationItemsParams } from '../../../api/Moderation.api';
import { log } from '../../../logger';
import LoadingSpinner from '../../common/LoadingSpinner';
import { loadModerationItems, madeModerationDecision, deletedModerationItem } from '../../../actions/Moderation.action';
import { loadCasePhotos, deleteCasePhoto } from '../../../actions/Photo.action';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { deleteCaseMemory } from '../../../actions/Memory.action';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: '40px 0 0',
        '&:not($adminPage)': {
            '@media (min-width: 960px)': {
                maxWidth: 872,
                margin: '0 auto'
            }
        },
        '&$adminPage': {
            '@media (min-width: 1200px)': {
                maxWidth: 872,
                margin: '0 auto'
            },
        },
        '& div:first-of-type': {
            maxWidth: '100%'
        }
    },
    memoryCaseCard: {
        display: 'flex',
        justifyContent: 'center',
    },
    adminPage: {}
});

type StyledProps = WithStyles<'root' | 'memoryCaseCard' | 'adminPage'>;

function mapStateToProps({ funeralHomeState, userSession, moderationState }: StoreState) {
    const { activeFuneralHome } = funeralHomeState;
    const { allCasesPendingCounts, caseCounts } = moderationState;

    return {
        funeralHomeId: activeFuneralHome?.id ?? null,
        userData: userSession.userData,
        caseCounts,
        allCasesPendingCounts,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    activeCaseUuid: string | 'All';
    status: ModerationStatus;
    initialCategory?: ModerationCategory;
    hideCaseCard?: boolean;
    showIndividualCards: boolean;
    zIndex: number;
    dispatch: AppDispatch;
    isAdminPage?: boolean;
}

interface State {
    category: ModerationCategory;
    isCaseLoading: boolean;
    isItemsLoading: boolean;
    isLoadingMoreItems: boolean;
    caseDetails: ModerationCaseDetails | null;
    photos: ModerationPhotoUX[];
    memories: ModerationMemoryUX[];
    visitors: ModerationVisitorUX[];
    hasMoreData: boolean;
    onConfirmDelete: (() => void) | null;
}

class ModerationContentController extends Component<Props & StyledProps, State> {
    state: State = {
        category: this.props.initialCategory || ModerationCategory.visitors,
        isCaseLoading: false,
        isItemsLoading: false,
        isLoadingMoreItems: false,
        caseDetails: null,
        photos: [],
        memories: [],
        visitors: [],
        hasMoreData: false,
        onConfirmDelete: null,
    };

    componentDidMount() {
        const { category } = this.state;

        this.setState({
            isCaseLoading: true,
        });

        this.loadItemsForCategory(category);
    }

    handleCategoryChange = (cat: ModerationCategory) => {
        const { category } = this.state;

        if (category === cat) {
            return;
        }

        this.setState({
            category: cat,
        });

        this.loadItemsForCategory(cat);
    };

    loadItemsForCategory = async (category: ModerationCategory, offset?: number) => {
        const { dispatch, funeralHomeId, activeCaseUuid, status } = this.props;

        const includeTotals = true;

        const params: RetrieveModerationItemsParams = {
            category,
            status,
            funeralHomeId,
            caseUuid: activeCaseUuid === 'All' ? null : activeCaseUuid,
            includeTotals,
            offset,
        };

        this.setState({
            isItemsLoading: true,
            isLoadingMoreItems: Boolean(offset),
        });

        const response = await dispatch(loadModerationItems(params));

        this.setState((prevState) => ({
            caseDetails: response && response.caseDetails ? response.caseDetails : prevState.caseDetails,
            visitors: response && isModerationVisitorList(response.data) && category === ModerationCategory.visitors
                ? [...prevState.visitors, ...response.data]
                : [],
            memories: response && isModerationMemoryList(response.data) && category === ModerationCategory.memories
                ? [...prevState.memories, ...response.data]
                : [],
            photos: response && isModerationPhotoList(response.data) && category === ModerationCategory.photos
                ? [...prevState.photos, ...response.data]
                : [],
            hasMoreData: response && response.hasMoreData || false,
            isCaseLoading: false,
            isItemsLoading: false,
            isLoadingMoreItems: false,
        }));
    };

    loadMoreItems = () => {
        const { category, visitors, memories, photos } = this.state;
        let offset = 0;
        if (category === ModerationCategory.visitors) {
            offset = visitors.length;
        } else if (category === ModerationCategory.memories) {
            offset = memories.length;
        } else if (category === ModerationCategory.photos) {
            offset = photos.length;
        }
        this.loadItemsForCategory(category, offset);
    };

    handleDeleteItem = (item: ModerationItem) => {
        const { dispatch, activeCaseUuid, zIndex } = this.props;
        const { category } = this.state;

        const caseUuid = activeCaseUuid === 'All' ? null : activeCaseUuid;

        if (isModerationVisitor(item)) {
            log.warn('Deleting moderation visitors is not supported', { caseUuid, item });
            return;
        }

        this.openDeleteConfirmDialog(() => {
            if (isModerationPhoto(item)) {
                dispatch(deleteCasePhoto({
                    gatherCase: {
                        id: item.case_id,
                        uuid: item.case_uuid,
                        name: item.case_name,
                        funeral_home: { id: item.funeral_home_id },
                    },
                    albumEntryId: item.photo.album_entry_id,
                    zIndex: zIndex + 1,
                }));

                this.setState((prevState) => ({
                    photos: prevState.photos.filter((p) => p.photo.photo_id !== item.photo.photo_id),
                }));
            } else if (isModerationMemory(item)) {
                dispatch(deleteCaseMemory(item.case_name, item.memory.id));

                this.setState((prevState) => ({
                    memories: prevState.memories.filter((m) => m.memory.id !== item.memory.id),
                }));
            }
            this.closeDeleteConfirmDialog();
            dispatch(deletedModerationItem({ category, item }));
        });
    };

    openDeleteConfirmDialog = (callback: () => void) => {
        this.setState({ onConfirmDelete: callback });
    };

    closeDeleteConfirmDialog = () => {
        this.setState({ onConfirmDelete: null });
    };

    handleMakeDecision = async (decisions: ModerationItem[], isApproval: boolean) => {
        const { dispatch, activeCaseUuid, funeralHomeId, status } = this.props;
        const { hasMoreData } = this.state;

        const caseUuid = activeCaseUuid === 'All' ? null : activeCaseUuid;

        let reqItems: ModerationDecisionRequestItem[];
        let category: ModerationCategory;
        if (isModerationMemoryList(decisions)) {
            category = ModerationCategory.memories;
            reqItems = decisions.map((m: ModerationMemoryUX): ModerationDecisionRequestItem => ({
                id: m.memory.id,
                case_id: m.case_id,
            }));
            this.setState((prevState) => ({
                memories: prevState.memories.filter((m) => reqItems.every((i) => i.id !== m.memory.id))
            }));
        } else if (isModerationVisitorList(decisions)) {
            category = ModerationCategory.visitors;
            reqItems = decisions.map((v: ModerationVisitorUX): ModerationDecisionRequestItem => ({
                id: v.visitor.case_entity_id,
                case_id: v.case_id,
            }));
            this.setState((prevState) => ({
                visitors: prevState.visitors.filter((v) => reqItems.every((i) => i.id !== v.visitor.case_entity_id))
            }));
        } else if (isModerationPhotoList(decisions)) {
            category = ModerationCategory.photos;
            reqItems = decisions.map((p: ModerationPhotoUX): ModerationDecisionRequestItem => ({
                id: p.photo.photo_id,
                case_id: p.case_id,
            }));
            this.setState((prevState) => ({
                photos: prevState.photos.filter((p) => reqItems.every((i) => i.id !== p.photo.photo_id))
            }));
        } else {
            log.warn('Moderation item list does not match a type', {
                decisions,
                isApproval,
                activeCaseUuid,
                funeralHomeId,
            });
            return;
        }

        const decisionRequest: ModerationDecisionRequest = {
            category,
            items: reqItems,
            reason: '',
            approval_status: isApproval ? ModerationStatus.approved : ModerationStatus.blocked,
        };

        const loadMoreAfterDecision = decisions.length > 1 && hasMoreData && status === ModerationStatus.pending;
        if (loadMoreAfterDecision) {
            this.setState({
                isItemsLoading: true,
            });
        }

        dispatch(madeModerationDecision({ category, decisions, isApproval, caseUuid }));
        await dispatch(makeModerationDecision({
            decisionRequest,
            funeralHomeId,
            caseUuid,
        }));

        if (isApproval && caseUuid && category === ModerationCategory.photos) {
            // TODO: JJT (AKT) look into doing this on the API instead of a round trip API request
            dispatch(loadCasePhotos(caseUuid));
        }

        if (loadMoreAfterDecision) {
            this.loadItemsForCategory(category);
        }
    };

    render() {
        const {
            classes,
            hideCaseCard,
            status,
            zIndex,
            userData,
            caseCounts,
            allCasesPendingCounts,
            activeCaseUuid,
            isAdminPage,
            showIndividualCards
        } = this.props;
        const {
            isItemsLoading,
            isCaseLoading,
            isLoadingMoreItems,
            category,
            caseDetails,
            photos,
            visitors,
            memories,
            hasMoreData,
            onConfirmDelete,
        } = this.state;

        if (!userData) {
            return null;
        }

        let categoryCounts: ModerationCategoryCounts | null = null;
        if (activeCaseUuid === 'All') {
            categoryCounts = allCasesPendingCounts;
        } else {
            const counts = caseCounts.find((c) => c.case_uuid === activeCaseUuid);
            if (counts) {
                categoryCounts = counts[status];
            }
        }

        return (
            <Grid item className={classNames(classes.root, isAdminPage && classes.adminPage)}>
                {isCaseLoading && <LoadingSpinner />}
                {!isCaseLoading &&
                    <Grid item xs={12}>
                        {caseDetails && !hideCaseCard &&
                            <Grid item className={classes.memoryCaseCard}>
                                <ModerationCaseCard
                                    gatherCase={caseDetails}
                                />
                            </Grid>
                        }
                        <ModerationContent
                            zIndex={zIndex}
                            isLoadingItems={isItemsLoading}
                            isLoadingMoreItems={isLoadingMoreItems}
                            counts={categoryCounts}
                            photos={photos}
                            visitors={visitors}
                            memories={memories}
                            moderationStatus={status}
                            category={category}
                            userData={userData}
                            hasMoreData={hasMoreData}
                            onLoadMore={this.loadMoreItems}
                            onCategoryChange={this.handleCategoryChange}
                            onMakeDecision={this.handleMakeDecision}
                            onDeleteItem={this.handleDeleteItem}
                            showIndividualCards={showIndividualCards}
                        />
                    </Grid>
                }
                <ConfirmationDialog
                    header={'Are you sure?'}
                    subHeader={'Deleting this will remove it permanently from the system.'}
                    confirmationButtonText={'Delete'}
                    cancelButtonText={'Cancel'}
                    onClose={this.closeDeleteConfirmDialog}
                    open={!!onConfirmDelete}
                    onConfirm={onConfirmDelete || undefined}
                    zIndex={zIndex + 1}
                />
            </Grid>
        );
    }
}

export default withState(mapStateToProps)(withStyles(styles)(ModerationContentController));
