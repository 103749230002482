import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import { useTaskDialogStyles } from '../TaskDialogsStyles';

const FuneralReimbursementLogo = () => {
    const classes = useTaskDialogStyles();

    const logo = 'https://res.cloudinary.com/gather-app-prod/' +
        'image/upload/v1618933683/system_assets/images/FEMA_logo.svg';

    return (
        <Grid
            className={classNames(
                classes.marginBottom10,
                classes.borderBottom,
            )}
        >
            <div className={classNames(classes.secondStateIcon, classes.marginAuto)}>
                <img src={logo} alt="Funeral Reimbursement Logo" />
            </div>
        </Grid>
    );
};

export default FuneralReimbursementLogo;