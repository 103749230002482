import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

import { StyledProps, ContractStatusContainerStyledProps } from './styles';
import { SvgIcon } from '@mui/material';

interface StatusStepProps {
    status: 'complete' | 'incomplete';
    label: string;
    hr: {
        after?: { disabled?: boolean };
        before?: { disabled?: boolean };
    };
    key: string | number;
    disabled?: boolean;
}

export const StatusStep = (
    props: StatusStepProps & ContractStatusContainerStyledProps
) => {
    const { status, disabled, label, classes, hr } = props;
    const completeIcon = <CheckCircleIcon color="primary" className={classes.icon} />;
    const inCompleteIcon = <TripOriginIcon color="primary" className={classes.icon} />;
    const disableIcon = <PanoramaFishEyeIcon color="primary" className={classes.icon} />;

    return (
        <div
            className={classNames(
                disabled && classes.disabled || undefined,
                classes.width100
            )}
        >
            <div
                className={classNames(
                    classes.iconGrid,
                    hr.after && hr.before && classes.justifyContentCenter,
                    hr.after && classes.justifyContentEnd,
                    hr.before && classes.justifyContentStart,
                )}
            >
                {hr.before &&
                    <div
                        className={classNames(
                            classes.hr,
                            hr.before.disabled && classes.disabled
                        )}
                    />
                }

                {disabled ? disableIcon : status === 'complete' ? completeIcon : inCompleteIcon}

                {hr.after &&
                    <div
                        className={classNames(
                            classes.hr,
                            hr.after.disabled && classes.disabled
                        )}
                    />
                }
            </div>
            <Typography color="primary">{label}</Typography>
        </div>
    );
};

interface ContractStatusContentProps {
    canEditContract: boolean;
    icon: JSX.Element;
    heading: string;
    description: string;
    button?: {
        isLoading?: boolean;
        label: JSX.Element;
        disabled?: boolean;
        onClick: () => void;
    };
}

export const ContractStatusContent = (props: ContractStatusContentProps & StyledProps) => {
    const { classes, icon, heading, description, button, canEditContract } = props;

    return (
        <Grid item xs={12} className={classes.contractStatusContent}>

            <SvgIcon className={classes.lockIcon} color="primary">
                {icon}
            </SvgIcon>

            <Typography color="primary" className={classes.heading}>
                {heading}
            </Typography>

            {canEditContract &&
                <Typography color="primary" className={classes.description}>
                    {description}
                </Typography>
            }

            {canEditContract && button &&
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={e => button.onClick()}
                    disabled={button.disabled}
                >
                    {button.label}
                    {button.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
            }
        </Grid>
    );
};
