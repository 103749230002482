import { Grid, IconButton } from '@mui/material';
import { HOMESTEADER_LOGO } from '../../../constants';
import { GATHER_COLOR_LIGHT } from '../../../constants/colorVariables';
import { AvatarUser, InsurancePolicyUX } from '../../../shared/types';
import UserAvatar from '../../common/UserAvatar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { log } from '../../../logger';
import { useMemo } from 'react';
import styled from '@emotion/styled';

const CircleButton = styled(IconButton)({
    margin: '4px auto 10px',
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'capitalize',
});

export type ActivePolicyCircleIdentifier = number | 'AGGREGATE' | 'CASE_SUMMARY';

interface PolicyProps {
    insuranceColor: string;
    associatedCase: AvatarUser | null;
    activePolicyCircle: ActivePolicyCircleIdentifier;
    policies: InsurancePolicyUX[];
    setActivePolicyCircle: (policyId: ActivePolicyCircleIdentifier) => void;
}

const PolicyCircles = ({ associatedCase, activePolicyCircle, policies, setActivePolicyCircle }: PolicyProps) => {
    const circles: ('AGGREGATE' | 'CASE_SUMMARY' | InsurancePolicyUX)[] = useMemo(() => {
        let _circles: ('AGGREGATE' | 'CASE_SUMMARY' | InsurancePolicyUX)[] = [];
        if (associatedCase && policies.length > 1) {
            _circles.push('AGGREGATE');
        };
        if (associatedCase && policies.length === 1) {
            _circles.push('CASE_SUMMARY');
        };
        _circles.push(...policies);
        return _circles;
    }, [associatedCase, policies]);

    const activeIndex =
        (activePolicyCircle === 'AGGREGATE' || activePolicyCircle === 'CASE_SUMMARY')
            ? 0
            : circles.findIndex((p) => typeof p !== 'string' && p.policy_id === activePolicyCircle);

    const handleNextCircle = () => {
        if (activeIndex >= circles.length - 1) {
            log.warn('User somehow was able to attempt to go above max index in PolicySlider');
            return;
        }

        const next = circles[activeIndex + 1];
        setActivePolicyCircle(typeof next === 'string' ? next : next.policy_id);
    };

    const handlePrevCircle = () => {
        if (activeIndex < 0) {
            log.warn('User somehow was able to attempt to go below 0 index in PolicySlider');
            return;
        }

        const next = circles[activeIndex - 1];
        setActivePolicyCircle(typeof next === 'string' ? next : next.policy_id);
    };

    const activeShadow = `0px 5px 26px 4px rgba(0, 0, 0, 0.12),
0px 14px 21px 2px rgba(0, 0, 0, 0.14),
0px 7px 9px -4px rgba(0, 0, 0, 0.2)`;

    const inactiveShadow = `0px 1px 5px 0px rgba(0, 0, 0, 0.12),
0px 2px 2px 0px rgba(0, 0, 0, 0.14),
0px 3px 1px -2px rgba(0, 0, 0, 0.2)`;

    const scale = circles.length > 5 ? 0.5 : circles.length > 3 ? 0.75 : 1;

    const caseAvatar = (
        <CircleButton
            size="large"
            key={activePolicyCircle === 'AGGREGATE' ? 'AGGREGATE' : 'CASE_SUMMARY'}
            disabled={policies.length < 1}
            sx={{
                padding: 0,
                height: 100 * scale,
                width: 100 * scale,
                fontSize: 45 * scale,
                zIndex: typeof activePolicyCircle === 'string' ? 0 : -1,
                boxShadow: typeof activePolicyCircle === 'string' ? activeShadow : inactiveShadow,
            }}
            onClick={() => activePolicyCircle === 'AGGREGATE'
                ? setActivePolicyCircle('AGGREGATE')
                : setActivePolicyCircle('CASE_SUMMARY')}
        >
            <UserAvatar
                transformations={associatedCase?.photo_transformations}
                user={associatedCase}
                sx={{
                    height: 100 * scale,
                    width: 100 * scale,
                    fontSize: 45 * scale,
                    fontWeight: 200,
                    borderRadius: '50%',
                }}
                size={200}
            />
        </CircleButton>
    );

    return (
        <Grid
            sx={(theme) => ({
                display: 'flex',
                transform: scale === 1 ? 'translateY(-40%)' : 'translateY(-50%)',
                [theme.breakpoints.down(500)]: {
                    transform: 'translateY(10%)',
                },
            })}
        >
            <Grid display="flex" maxWidth={380} zIndex={2}>
                {circles.map((circle) => (
                    (circle === 'AGGREGATE' || circle === 'CASE_SUMMARY')
                        ? caseAvatar :
                        <CircleButton
                            key={circle.policy_id}
                            disabled={policies.length < 1}
                            sx={{
                                height: 100 * scale,
                                width: 100 * scale,
                                fontSize: 45 * scale,
                                zIndex: circle.policy_id === activePolicyCircle ? 0 : -1,
                                background: `${GATHER_COLOR_LIGHT} !important`,
                                borderRadius: '50%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: circle.policy_id === activePolicyCircle ? activeShadow : inactiveShadow,
                            }}
                            style={{
                                // for some reason if this is in sx it flips the order of the circles
                                marginLeft: !associatedCase ? undefined : -20 * scale,
                            }}
                            onClick={() => policies.length >= 1 && setActivePolicyCircle(circle.policy_id)}
                        >
                            <img
                                src={HOMESTEADER_LOGO}
                                style={{
                                    objectFit: 'cover',
                                    width: 80 * scale,
                                }}
                            />
                        </CircleButton>
                ))}
            </Grid>
            {circles.length >= 2 && (
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        top: '60%',
                        width: '100%',
                        zIndex: 0,
                    }}
                >
                    <Grid>
                        <IconButton
                            sx={{ color: '#fff', marginLeft: '-36px' }}
                            disabled={activeIndex <= 0}
                            onClick={handlePrevCircle}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>

                    <Grid>
                        <IconButton
                            sx={{ color: '#fff', marginRight: '-36px' }}
                            disabled={activeIndex >= circles.length - 1}
                            onClick={handleNextCircle}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default PolicyCircles;
