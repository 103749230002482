import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NativeSelect from '@mui/material/NativeSelect';

import ClearIcon from '@mui/icons-material/Clear';

import { ManualAddress, GmapsSearchType } from '../../shared/types';

import TextField from '@mui/material/TextField';
import { US_STATES } from '../../shared/locale';
import PostalCodeMask from '../common/PostalCodeMask';
import GGroupButtons from '../common/GGroupButtons';
import { getStateLongName } from '../../shared/utils';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 7,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: '8px 14px',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 24px',
        margin: ' auto',
        textAlign: 'center',
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(2)': {
                marginTop: -2
            }
        }
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        maxWidth: 420,
        '@media (min-width: 400px)': {
            width: 360
        },
        '@media (min-width: 520px)': {
            width: 420
        },
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
    paddingLeft10: {
        paddingLeft: 10
    },
    paddingRight10: {
        paddingRight: 10
    },
    gGroupButtonClass: {
        textTransform: 'capitalize',
        height: 40,
        fontSize: 16,
        '@media (min-width: 520px)': {
            padding: '0 36px'
        },
    },
    internationalLoc: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 358,
        '& $bottomContent': {
            alignSelf: 'flex-end',
            margin: 0
        }
    },
    minHeight222: {
        minHeight: 222
    },
    content: {
        width: 280,
        '@media (min-width: 400px)': {
            width: 308
        },
        '@media (min-width: 520px)': {
            width: 380
        }
    },
    marginLeftAuto: {
        marginLeft: 'auto',
    }
});

enum AddressTypeEnum {
    US = 'US',
    International = 'International'
}

interface Props {
    isDialogOpen: boolean;
    type: GmapsSearchType;
    manualAddress: ManualAddress;
    useThisLocationClicked: boolean;
    requireLongAddress?: boolean;
    usAddressOnly?: boolean;
    closeDialog: () => void;
    updateManualAddress: (manualAddress: ManualAddress) => void;
    useThisLocation: () => void;
    zIndex: number;
    useDescription: boolean;
    toggleUseDescription: (value: boolean) => void;
}

interface State {
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'manualAddressDialog' | 'dRoot' | 'header'
    | 'dialogContent' | 'minHeight222' | 'gGroupButtonClass' | 'clearIcon' | 'bottomContent' | 'headerText'
    | 'paddingLeft10' | 'paddingRight10' | 'internationalLoc' | 'content' | 'marginLeftAuto'>;

class ManualAddressDialog extends React.Component<StyledProps, State> {

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { manualAddress, updateManualAddress } = this.props;
        const target = event.target;
        const name = target.name;
        updateManualAddress({
            ...manualAddress,
            [name]: target.value
        });
    };

    handleUsStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { manualAddress, updateManualAddress } = this.props;
        const target = event.target;
        const name = target.name;
        updateManualAddress({
            ...manualAddress,
            [name]: target.value
        });
    };

    onChangeAddressType = (value: string, target: HTMLElement) => {
        const { toggleUseDescription } = this.props;
        if (value === AddressTypeEnum.International) {
            toggleUseDescription(true);
        } else {
            toggleUseDescription(false);
        }
    };

    getAddressType = () => {
        const { useDescription, usAddressOnly } = this.props;

        return useDescription && !usAddressOnly ? AddressTypeEnum.International : AddressTypeEnum.US;
    };

    render() {
        const {
            classes,
            isDialogOpen,
            type,
            manualAddress,
            useThisLocationClicked,
            requireLongAddress,
            usAddressOnly,
            useThisLocation,
            closeDialog,
            zIndex,
        } = this.props;

        const addressType = this.getAddressType();

        const addrState = manualAddress.state && getStateLongName(manualAddress.state) || '';

        return (
            <div className={classes.root}>
                <Dialog
                    open={isDialogOpen}
                    onClose={() => closeDialog()}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{
                        paper: classes.dialogPaper,
                        root: classes.dRoot,
                    }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.header,
                            classes.backgroundPrimary
                        )}
                    >
                        <ClearIcon
                            className={classNames(classes.clearIcon, classes.colorWhite)}
                            onClick={() => closeDialog()}
                        />
                        <Typography
                            component="p"
                            noWrap
                            className={classNames(classes.colorWhite, classes.headerText)}
                        >
                            Enter Address Manually
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid item xs={12} className={classes.marginTop30}>
                            <GGroupButtons
                                buttons={[
                                    { label: 'US Address', value: AddressTypeEnum.US },
                                    { label: 'International', value: AddressTypeEnum.International },
                                ]}
                                disabled={!!usAddressOnly}
                                controlClasses={classNames(classes.gGroupButtonClass)}
                                activeButton={addressType}
                                onClick={this.onChangeAddressType}
                            />
                        </Grid>
                        {addressType === AddressTypeEnum.US &&
                            <Grid container className={classes.content}>
                                {type === GmapsSearchType.longAddress &&
                                    <Grid item className={classes.width100}>
                                        <FormControl
                                            className={classNames(
                                                classes.width100,
                                                classes.textLeft,
                                                classes.marginTop20
                                            )}
                                        >
                                            <TextField
                                                label="Location Name"
                                                id="locationName"
                                                name="locationName"
                                                type="text"
                                                value={manualAddress.locationName}
                                                onChange={this.handleInputChange}
                                                className={classes.width100}
                                            />
                                        </FormControl>

                                        <FormControl
                                            className={classNames(
                                                classes.width100,
                                                classes.textLeft,
                                                classes.marginTop20
                                            )}
                                        >
                                            <TextField
                                                error={useThisLocationClicked
                                                    && requireLongAddress
                                                    && !manualAddress.address}
                                                required={requireLongAddress}
                                                label="Address"
                                                id="address"
                                                name="address"
                                                type="text"
                                                value={manualAddress.address}
                                                onChange={this.handleInputChange}
                                                className={classes.width100}
                                            />
                                        </FormControl>

                                        <FormControl
                                            className={classNames(
                                                classes.width100,
                                                classes.textLeft,
                                                classes.marginTop20
                                            )}
                                        >
                                            <TextField
                                                label="Address 2"
                                                id="address2"
                                                name="address2"
                                                type="text"
                                                value={manualAddress.address2}
                                                onChange={this.handleInputChange}
                                                className={classes.width100}
                                            />
                                        </FormControl>
                                    </Grid>}

                                <Grid item xs={7} className={classes.paddingRight10}>
                                    <FormControl
                                        className={classNames(classes.width100, classes.textLeft, classes.marginTop20)}
                                        required
                                        error={useThisLocationClicked && !manualAddress.city}
                                    >
                                        <TextField
                                            error={useThisLocationClicked && !manualAddress.city}
                                            required
                                            label="City"
                                            id="city"
                                            name="city"
                                            type="text"
                                            value={manualAddress.city}
                                            onChange={this.handleInputChange}
                                            className={classes.width100}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5}>
                                    <FormControl
                                        className={classNames(classes.width100, classes.textLeft, classes.marginTop20)}
                                        required
                                        error={useThisLocationClicked && !manualAddress.state}
                                    >
                                        <InputLabel
                                            htmlFor="deathLocation_listOfOptions"
                                            className="width-100"
                                        >
                                            State
                                        </InputLabel>
                                        <NativeSelect
                                            value={addrState}
                                            name="state"
                                            onChange={this.handleUsStateChange}
                                            classes={{ select: classes.backgroundTransparent }}
                                        >
                                            <option value="" />
                                            {US_STATES.map
                                                (
                                                    name => {
                                                        return (
                                                            <option key={name.abbreviation} value={name.name}>
                                                                {name.name}
                                                            </option>
                                                        );
                                                    },
                                                    this
                                                )
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5}>
                                    <FormControl
                                        className={classNames(classes.width100, classes.textLeft, classes.marginTop20)}
                                        required={requireLongAddress}
                                        error={useThisLocationClicked
                                            && requireLongAddress
                                            && !manualAddress.postalCode
                                        }
                                    >
                                        <TextField
                                            error={useThisLocationClicked && requireLongAddress &&
                                                !manualAddress.postalCode}
                                            required={requireLongAddress}
                                            label="Postal Code"
                                            id="postalCode"
                                            name="postalCode"
                                            value={manualAddress.postalCode}
                                            onChange={this.handleInputChange}
                                            className={classes.width100}
                                            InputProps={{
                                                inputComponent: PostalCodeMask
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5} className={classes.marginLeftAuto}>
                                    <FormControl
                                        className={classNames(classes.width100, classes.textLeft, classes.marginTop20)}
                                    >
                                        <TextField
                                            label="County"
                                            id="county"
                                            name="county"
                                            value={manualAddress.county}
                                            onChange={this.handleInputChange}
                                            className={classes.width100}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid
                                    className={classes.bottomContent}
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        className={classes.margin_8}
                                        onClick={() => useThisLocation()}
                                    >
                                        USE THIS LOCATION
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {addressType === AddressTypeEnum.International
                            && <Grid
                                container
                                className={classNames(
                                    classes.content,
                                    classes.internationalLoc,
                                    type === GmapsSearchType.shortAddress && classes.minHeight222,
                                )}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        label="Type International Address here..."
                                        fullWidth
                                        name="locationName"
                                        value={manualAddress.locationName}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.bottomContent}
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        className={classes.margin_8}
                                        onClick={() => useThisLocation()}
                                    >
                                        USE THIS LOCATION
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default compose(
    withGStyles(styles), withFullScreen('xs'),
)(ManualAddressDialog) as React.ComponentType<Props>;
