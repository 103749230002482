import * as React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { StyledProps, styleWrapper } from '../styles';
import { formatDinero } from '../../../../../shared/goods_and_services/pricing';

import { ContractPackageGroup } from '../../../../../shared/types';
import PackageDisplay from './PackageDisplay';
import { PrintModeType } from '../..';
import withGStyles from '../../../../../styles/WithGStyles';

type Props = {
    isCondensedView: boolean;
    packageGroup: ContractPackageGroup;
    typographyStyles: React.CSSProperties;
    isPrintMode: boolean;
    printModeType?: PrintModeType;
};

const PackageOnlyView: React.FunctionComponent<Props & StyledProps> = (props) => {
    const { isCondensedView, packageGroup, classes, typographyStyles, isPrintMode, printModeType } = props;

    return (
        <Grid
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'nowrap',
                width: '100%',
                minHeight: '22px',
            }}
        >
            <PackageDisplay
                title={packageGroup.title}
                subPackages={packageGroup.subPackages}
                isCondensedView={isCondensedView}
                typographyStyles={typographyStyles}
                isPrintMode={isPrintMode}
                printModeType={printModeType}
            />

            <Typography
                color={isCondensedView ? 'secondary' : 'primary'}
                className={classes.package}
                style={{
                    ...typographyStyles,
                    marginRight: 5,
                }}
            >
                {formatDinero(packageGroup.subTotal)}
            </Typography>
        </Grid>
    );
};

export default withGStyles(styleWrapper<Props>())(PackageOnlyView);
