import classNames from 'classnames';
import * as appService from '../../../../services/app.service';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { GatherCaseUX } from '../../../../shared/types';

import Typography from '@mui/material/Typography';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
}

const CloseLinkedin = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
    } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    LinkedIn is a popular social network for professionals.
                    Many users chose to close their loved one's LinkedIn account.
                    Luckily, it is a quick process to close {activeCase.fname}'s account and remove
                    their profile on your behalf.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={
                    classNames(
                        classes.colorPrimary,
                        classes.b_solid_1,
                        classes.detailBox,
                        classes.marginTop20,
                        classes.marginAuto
                    )
                }
            >
                <Typography
                    className={
                        classNames(
                            classes.detailBoxHeader,
                            classes.fontSize18,
                            classes.backgroundPrimary,
                            classes.padding10
                        )
                    }
                >
                    You will need the following...
                </Typography>
                <Grid
                    item

                    className={
                        classNames(
                            classes.padding10,
                            classes.textLeft
                        )
                    }
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        Full Name:
                        <span
                            className={classes.fontWeight500}
                            id="caseName"
                        >
                            {` ${appService.getFullNameFromCase(activeCase)}`}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        {activeCase.fname}'s email (optional)
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Passing:
                        <span className={classes.fontWeight500} id="deathDate">
                            {` ${appService.formatDateString(activeCase.dod_start_date || '')} `}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Link to {activeCase.fname}'s obituary or relevant news article
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <a
                    href="https://www.linkedin.com/help/linkedin/ask/ts-rdmlp"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Delete {activeCase.fname}'s LinkedIn Account
                        &nbsp;<OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default CloseLinkedin;
