import * as React from 'react';
import classNames from 'classnames';
import { SortableHandle } from 'react-sortable-hoc';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import {
    StyledProps,
    itemLabelStyle,
    captionLabelStyle,
    typographyStyle,
    signatureNameStyle,
    signatureDataStyle,
    signatureTextTagStyle,
    iconStyle,
} from '../styles';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { ORANGE_COLOR } from '../../../../../constants/colorVariables';
import { ContractDisclaimerUX } from '../../../../../shared/types';

export interface SecondaryGridProps {
    canUserEditContract?: boolean;
    iconJSX?: JSX.Element;
    label?: string;
    value?: string;
    price?: string;
    isCurrentRev?: boolean;
    isPriceStriked?: boolean;
    isNote?: boolean;
    isCondensedView?: boolean;
    printMode?: boolean;
    onClick?: () => void;
}

export const SecondaryGrid = (props: StyledProps & SecondaryGridProps) => {
    const {
        classes,
        iconJSX,
        label = '',
        value,
        price,
        isCurrentRev,
        isPriceStriked,
        isNote,
        onClick,
        canUserEditContract,
        isCondensedView,
        printMode,
    } = props;

    const renderValue = () => {
        if (value) {
            return <>
                <span style={{ fontWeight: 700 }}>{value}</span>
            </>;
        }
        return null;
    };

    return (
        <Grid
            item
            xs={12}
            className={classes.captionGrid}
            style={{
                margin: 'auto',
                padding: '0 8px',
                paddingRight: price && 0 || undefined,
            }}
        >
            <Typography
                color="secondary"
                className={classes.label}
                style={{ ...captionLabelStyle, color: '#2e2e2e' }}
            >
                <span
                    className={classNames(
                        isCurrentRev && classes.colorWarning,
                        price && classes.warningGrid,
                        (!canUserEditContract) && classes.restricted,
                    )}
                    style={
                        price ?
                            {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }
                            : undefined
                    }
                >
                    <span
                        className={classNames(
                            classes.label,
                            isNote && classes.note,
                            onClick && classes.clickable,
                            (!canUserEditContract) && classes.restricted,
                        )}
                        onClick={onClick}
                        style={{ display: 'flex' }}
                    >
                        {iconJSX}
                        <span
                            className={classes.inner}
                            style={{
                                fontSize: isCondensedView ? 10 : 12,
                                lineHeight: '12px',
                            }}
                        >
                            {label}{renderValue()}
                        </span>
                    </span>

                    {price &&
                        <span
                            className={classes.value}
                            style={{
                                textDecoration: isPriceStriked && 'line-through' || undefined,
                                fontSize: isCondensedView ? 9 : 12,
                                marginRight: printMode && (isCondensedView ? 2 : 20)
                                    || !printMode && (isCondensedView ? 16 : 36)
                                    || 0,
                            }}
                        >
                            {price}
                        </span>
                    }
                </span>
            </Typography>
        </Grid>
    );
};

const DragHandle = SortableHandle<{ isDraggable?: boolean }>(({ isDraggable }: { isDraggable?: boolean }) =>
    <DragHandleIcon
        style={{
            fontSize: 14,
            width: 14,
            height: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            opacity: !isDraggable ? 0 : undefined,
            margin: !isDraggable ? 0 : undefined,
            color: !isDraggable ? 'transparent' : ORANGE_COLOR,
            pointerEvents: !isDraggable ? 'none' : undefined
        }}
    />
);

interface ItemGridProps {
    isDraggable?: boolean;
    canUserEditContract: boolean;
    item: {
        label: string;
        price: JSX.Element | string;
        modelNumber?: string;
        manufacturer?: string;
        underline: boolean;
        bold: boolean;
        indent: boolean;
        isTaxable?: boolean;
    };
    printMode?: boolean;
    photo?: {
        url: string;
        variant?: 'border';
    };
    warning?: boolean;
    strikeMe?: {
        label?: boolean;
        price?: boolean;
    };
    secondary?: SecondaryGridProps[];
    isCondensedView?: boolean;
    onMenuClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onClick?: () => void;
    openProductTaxDetailPopper?: (element: HTMLElement) => void;
    showTaxIcon?: boolean;
}

export const ItemGrid = (props: StyledProps & ItemGridProps) => {
    const {
        classes,
        onMenuClick,
        item,
        printMode,
        warning,
        secondary,
        strikeMe,
        photo,
        onClick,
        canUserEditContract,
        isCondensedView,
        isDraggable,
        openProductTaxDetailPopper,
        showTaxIcon
    } = props;

    const showMenu = !printMode && !isDraggable && onMenuClick && canUserEditContract;

    return <>
        <div
            className={photo && !isCondensedView ? classes.photoGrid : ''}
            style={{
                pageBreakInside: 'avoid',
                justifyContent: printMode && photo && 'flex-start' || undefined,
                alignItems: printMode && photo && 'flex-start' || undefined,
                display: photo && !isCondensedView && 'flex' || undefined,
                marginBottom: photo && !isCondensedView && 8 || undefined,
                paddingLeft: item.indent ? 20 : undefined,
            }}
        >
            {photo && !isCondensedView &&
                <img
                    className={classNames(
                        classes.thumbnail,
                        photo.variant && classes[photo.variant]
                    )}
                    style={{
                        backgroundColor: 'transparent',
                        width: 'auto',
                        minWidth: 'auto',
                        height: 56,
                        borderRadius: 8,
                        margin: '8px 16px 0 0',
                        verticalAlign: 'top',
                        border: photo.variant && photo.variant === 'border' ? '1px solid' : undefined
                    }}
                    onClick={onClick ? () => onClick() : undefined}
                    src={photo.url}
                />
            }
            <div
                className={photo && classes.inner}
                style={{
                    display: photo && 'inline-block' || undefined,
                    width: photo && '100%' || undefined,
                    padding: photo && '0 !important' || undefined,
                    border: photo && 'none !important' || undefined,
                }}
            >
                <Grid
                    item
                    xs={12}
                    className={classes.itemGrid}
                    style={{
                        pageBreakInside: 'avoid',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                        margin: '0 auto',
                    }}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.label,
                            warning && classes.colorWarning,
                            strikeMe && strikeMe.label
                            && classNames(classes.strikeThrough, classes.pointerEventsNone),
                            item.bold && classes.fontBold,
                        )}
                        style={{
                            ...itemLabelStyle,
                            marginRight: 8,
                            fontSize: isCondensedView
                                ? 12
                                : 18,
                            display: 'block',
                            color: '#2e2e2e',
                            fontWeight: item.bold ? 'bold' : undefined,
                        }}
                    >
                        {!printMode && item.isTaxable && showTaxIcon && strikeMe && !strikeMe.label &&
                            <>
                                <IconButton
                                    color="inherit"
                                    onClick={
                                        e => openProductTaxDetailPopper
                                            && openProductTaxDetailPopper(e.currentTarget)
                                    }
                                    style={{
                                        height: isCondensedView ? '14px' : '20px',
                                        width: isCondensedView ? '14px' : '20px'
                                    }}
                                    size="large">
                                    <AccountBalanceIcon
                                        style={{
                                            fontSize: isCondensedView ? 14 : 20,
                                            color: '#2e2e2e',
                                            marginBottom: isCondensedView ? 2 : 3
                                        }}
                                    />
                                </IconButton>
                                &nbsp;
                            </>
                        }
                        <span
                            onClick={onClick ?
                                () => onClick()
                                : undefined
                            }
                            className={classNames(
                                classes.labelText,
                                item.underline && classes.textUnderline
                            )}
                            style={{
                                textDecoration: strikeMe && strikeMe.label
                                    ? 'line-through'
                                    : item.underline
                                        ? 'underline'
                                        : undefined,
                            }}
                        >
                            {item.label}
                        </span>

                        {item.manufacturer &&
                            <span
                                style={{
                                    fontWeight: 200,
                                    fontStyle: 'italic',
                                    paddingLeft: 4,
                                    fontSize: isCondensedView
                                        ? 11
                                        : 16
                                }}
                            >
                                ({item.manufacturer})
                            </span>
                        }

                        {item.modelNumber &&
                            <span
                                style={{
                                    fontWeight: 200,
                                    fontStyle: 'italic',
                                    paddingLeft: 4,
                                    fontSize: isCondensedView
                                        ? 11
                                        : 16
                                }}
                            >
                                ({item.modelNumber})
                            </span>
                        }
                    </Typography>

                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.label,
                            warning && classes.colorWarning,
                            strikeMe && strikeMe.price && classes.strikeThrough
                        )}
                        style={{
                            ...itemLabelStyle,
                            marginLeft: 8,
                            marginRight: isCondensedView && (!showMenu ? 2 : 0)
                                || !isCondensedView && (!showMenu ? 20 : 0)
                                || undefined,
                            fontSize: isCondensedView
                                ? 12
                                : 18,
                            textDecoration: strikeMe && strikeMe.price && 'line-through' || undefined,
                            color: '#2e2e2e'
                        }}
                    >
                        {item.price}
                        {showMenu &&
                            <IconButton
                                className={classNames(
                                    classes.iconButton,
                                    'icon-button',
                                    !canUserEditContract && classes.restricted,
                                    isCondensedView && classes.smallIconButton,
                                )}
                                aria-label="More"
                                aria-owns={'overview--item-menu'}
                                aria-haspopup="true"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (onMenuClick) {
                                        onMenuClick(e);
                                    }
                                }}
                                disabled={!canUserEditContract}
                                size="large">
                                <MoreVertIcon />
                            </IconButton>
                        }

                        {!printMode && !onMenuClick && <DragHandle isDraggable={isDraggable} />}
                    </Typography>
                </Grid>
                {secondary && secondary.map((s, i) =>
                    <SecondaryGrid
                        {...s}
                        key={`${item.label}_${i}`}
                        classes={classes}
                        canUserEditContract={canUserEditContract}
                        isCondensedView={isCondensedView}
                        printMode={printMode}
                    />
                )}
            </div>
        </div>
    </>;
};

interface SubtotalItemGrid {
    item: {
        label: string;
        price: string;
        isTaxable?: boolean;
    };
    isTopBorder?: boolean;
    fontSize?: number;
    labelFontWeight?: number;
    priceFontWeight?: number;
    isStriked?: boolean;
    isCondensedView?: boolean;
    hasMenu?: boolean;
    canUserEditContract?: boolean;
    printMode?: boolean;
    indent?: boolean;
    onClick?: () => void;
    openProductTaxDetailPopper?: (element: HTMLElement) => void;
    showTaxIcon?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubtotalItemGrid = (props: StyledProps & SubtotalItemGrid) => {
    const {
        classes,
        item,
        isTopBorder,
        fontSize,
        priceFontWeight,
        labelFontWeight,
        isStriked,
        isCondensedView,
        onClick,
        hasMenu,
        canUserEditContract,
        printMode,
        indent,
        openProductTaxDetailPopper,
        showTaxIcon
    } = props;

    return (
        <div style={{ pageBreakInside: 'avoid' }}>
            <Grid
                item
                xs={12}
                className={classes.itemGrid}
                style={{
                    pageBreakInside: 'avoid',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0 auto',
                    borderTop: isTopBorder && '1px solid' || undefined,
                    marginLeft: indent ? 20 : undefined
                }}
            >
                <Typography
                    color={isCondensedView ? 'secondary' : 'primary'}
                    style={{
                        ...itemLabelStyle,
                        paddingLeft: 4,
                        fontSize: fontSize || 16,
                        fontWeight: labelFontWeight || undefined,
                        cursor: 'inherit',
                        textDecoration: isStriked ? 'line-through' : 'none',
                        color: '#2e2e2e'
                    }}
                    className={onClick && canUserEditContract && classes.clickable || undefined}
                    onClick={e => {
                        if (!canUserEditContract || !onClick) {
                            return;
                        }
                        onClick();
                    }}
                >
                    {!printMode && item.isTaxable && showTaxIcon && !isStriked &&
                        <>
                            <IconButton
                                color="inherit"
                                onClick={(e) => openProductTaxDetailPopper
                                    && openProductTaxDetailPopper(e.currentTarget)
                                }
                                style={{
                                    height: isCondensedView ? '14px' : '20px',
                                    width: isCondensedView ? '14px' : '20px'
                                }}
                                size="large">
                                <AccountBalanceIcon
                                    style={{
                                        fontSize: isCondensedView ? 14 : 20,
                                        color: '#2e2e2e',
                                        marginBottom: isCondensedView ? 2 : 3
                                    }}
                                />
                            </IconButton>
                            &nbsp;
                        </>
                    }
                    <span
                        onClick={onClick ?
                            () => onClick()
                            : undefined
                        }
                        className={classes.labelText}
                    >
                        {item.label}
                    </span>
                </Typography>

                <Typography
                    color={isCondensedView ? 'secondary' : 'primary'}
                    className="notranslate"
                    style={{
                        ...itemLabelStyle,
                        fontSize: fontSize || 16,
                        fontWeight: priceFontWeight || undefined,
                        cursor: 'inherit',
                        textDecoration: isStriked ? 'line-through' : 'none',
                        color: '#2e2e2e'
                    }}
                >
                    {item.price}
                    {onClick && hasMenu && canUserEditContract && !printMode && <IconButton
                        className={classNames(
                            classes.iconButton,
                            classes.small,
                            isCondensedView && classes.smallWidth,
                        )}
                        aria-label="More"
                        aria-owns={'overview--item-menu'}
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick();
                        }}
                        size="large">
                        <EditIcon />
                    </IconButton>}
                </Typography>
            </Grid>
        </div>
    );
};

interface TotalPriceProps {
    label: string;
    price: string;
    noBorder?: boolean;
    isCondensedView?: boolean;
    printMode?: boolean;
    showAmountTopBorder?: boolean;
}

export const TotalPrice = (props: StyledProps & TotalPriceProps) => {
    const { classes, noBorder, label, price, isCondensedView, printMode, showAmountTopBorder } = props;

    return (
        <Grid
            item
            xs={12}
            className={classNames(
                classes.totalSection,
                noBorder && classes.noBorder
            )}
            style={{
                padding: isCondensedView ? printMode ? '0 8px 0px' : '0 16px 0px' : '0 0px 32px',
                borderBottom: noBorder ? undefined : '1px solid',
                marginBottom: isCondensedView ? 12 : undefined,
            }}
        >
            <Typography
                color={isCondensedView ? 'secondary' : 'primary'}
                style={{
                    ...typographyStyle,
                    textTransform: isCondensedView ? 'uppercase' : 'capitalize',
                    fontSize: isCondensedView ? 14 : 20,
                    textAlign: isCondensedView ? 'right' : 'center',
                    color: isCondensedView ? '#2e2e2e' : undefined
                }}
            >
                {label}:
                <span
                    style={
                        isCondensedView ? {
                            borderTop: showAmountTopBorder ? '1px solid' : undefined,
                            padding: '0px 0px 0px 8px',
                        }
                            : undefined
                    }
                > {price}</span>
            </Typography>
        </Grid>
    );
};

interface SignatureGridProps {
    name: string;
    userDataLine1?: string;
    userDataLine2?: string;
    userDataLine3?: string;
    extraFieldName?: string;
    helloSignSignatureTextTag: string;
    helloSignDateTextTag: string;
    helloSignExtraTextTag?: string;
    isCondensedView: boolean;
    isLast: boolean;
    isPrintMode?: boolean;
}

export const SignatureGrid = (props: StyledProps & SignatureGridProps) => {
    const {
        classes,
        name,
        userDataLine1,
        userDataLine2,
        userDataLine3,
        extraFieldName,
        helloSignSignatureTextTag,
        helloSignDateTextTag,
        helloSignExtraTextTag,
        isCondensedView,
        isLast,
        isPrintMode
    } = props;

    return (
        <div
            className={classNames(
                classes.signatureGrid,
                isCondensedView && classes.isCondensedView
            )}
            style={{
                width: '100%',
                marginTop: 6,
                marginBottom: isCondensedView && isLast && undefined
                    || isCondensedView && !isLast && 0
                    || !isCondensedView && 36
                    || undefined,
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'table'
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 'calc(65% - 8px)',
                        paddingLeft: 8
                    }}
                >
                    <Typography style={signatureTextTagStyle}>
                        {// add extra space at the end of the tag to make HelloSign sign box bigger
                            helloSignSignatureTextTag.replace(/]$/, '')
                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
                    </Typography>
                    <Typography
                        color="secondary"
                        style={{
                            ...signatureNameStyle,
                            textTransform: 'capitalize',
                            margin: 0
                        }}
                    >
                        {name}
                    </Typography>
                    {userDataLine1 &&
                        <Typography
                            color="secondary"
                            style={{ ...signatureDataStyle, margin: 0 }}
                        >
                            {userDataLine1}
                        </Typography>
                    }
                    {userDataLine2 &&
                        <Typography
                            color="secondary"
                            style={{ ...signatureDataStyle, margin: 0 }}
                        >
                            {userDataLine2}
                        </Typography>
                    }
                    {userDataLine3 &&
                        <Typography
                            color="secondary"
                            style={{ ...signatureDataStyle, margin: 0 }}
                        >
                            {userDataLine3}
                        </Typography>
                    }
                </div>
                <div
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 'calc(35% - 16px)',
                        paddingLeft: 16
                    }}
                >
                    <Typography
                        style={{
                            ...signatureTextTagStyle,
                            margin: 0,
                            fontSize: 10,
                        }}
                    >
                        {helloSignDateTextTag}
                    </Typography>
                    <Typography
                        color="secondary"
                        style={{
                            ...signatureNameStyle,
                            textTransform: 'capitalize',
                            margin: 0
                        }}
                    >
                        Date
                    </Typography>
                </div>
            </div>
            {helloSignExtraTextTag &&
                <div
                    style={{
                        width: isPrintMode ? 'calc(100% - 8px)' : '100%',
                        display: 'block',
                        paddingTop: 8
                    }}
                >
                    <Typography
                        style={signatureTextTagStyle}
                    >
                        {// add extra space at the end of the tag to make HelloSign text box bigger
                            helloSignExtraTextTag.replace(/]$/, '')
                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
                    </Typography>
                    <Typography
                        color="secondary"
                        style={{
                            ...signatureNameStyle,
                            minWidth: '100%',
                            textTransform: 'capitalize',
                            overflow: 'hidden'
                        }}
                    >
                        {extraFieldName || ''}
                    </Typography>
                </div>
            }
        </div>
    );
};

interface PaymentCardProps {
    children: JSX.Element;
}

export const PaymentCard = (props: StyledProps & PaymentCardProps) => {
    const { classes, children } = props;

    return (
        <Card className={classes.paymentCard}>
            <CardContent className={classes.paymentCardContent}>
                <Grid xs={12} item>
                    {children}
                </Grid>
            </CardContent>
        </Card>
    );
};

interface DisclaimersSectionProps {
    disclaimers: ContractDisclaimerUX[];
    legalText: string | null;
    openConfigureDisclaimerDialog?: () => void;
    openDisclaimerReasonDialog?: (disclaimerId: number) => void;
    canUserEditContract: boolean;
}

export const DisclaimersSection = (props: StyledProps & DisclaimersSectionProps) => {
    const {
        classes,
        disclaimers,
        legalText,
        openConfigureDisclaimerDialog,
        openDisclaimerReasonDialog,
        canUserEditContract,
    } = props;

    return (
        <Grid
            xs={12}
            item
            className={classes.disclaimerSection}
            style={{
                paddingLeft: 8,
                marginTop: 8,
            }}
        >
            {openConfigureDisclaimerDialog && <div className={classes.textCenter}>
                <Button
                    size="small"
                    onClick={e => openConfigureDisclaimerDialog()}
                    className={classNames(
                        classes.addItemCompactButton,
                        classes.colorOrange
                    )}
                >
                    <ErrorOutlineIcon />&nbsp;Configure statement disclaimers
                </Button>
            </div>}

            {legalText &&
                <Typography
                    color="secondary"
                    className={classes.label}
                    style={{
                        ...typographyStyle,
                        fontSize: 10,
                        margin: '0px',
                        whiteSpace: 'pre-line',
                        pageBreakInside: 'avoid',
                        color: '#2e2e2e'
                    }}
                >
                    {legalText}
                </Typography>}

            {disclaimers.map((disclaimer) =>
                <div
                    key={disclaimer.id}
                    className={classes.inner}
                    style={{
                        margin: '0',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        pageBreakInside: 'avoid',
                        paddingLeft: 12,
                    }}
                >
                    <Typography
                        color="secondary"
                        className={classes.label}
                        style={{
                            ...typographyStyle,
                            fontSize: 10,
                            color: '#2e2e2e'
                        }}
                    >
                        {disclaimer.disclaimer}:
                    </Typography>
                    {disclaimer.reason &&
                        <Typography
                            color="secondary"
                            className={classes.familyRequested}
                            style={{
                                ...typographyStyle,
                                fontSize: 10,
                                alignContent: 'center',
                                textAlign: 'left',
                                color: '#2e2e2e',
                                pointerEvents: openDisclaimerReasonDialog === undefined
                                    || !canUserEditContract ? 'none' : undefined
                            }}
                            onClick={e => {
                                if (openDisclaimerReasonDialog && canUserEditContract) {
                                    openDisclaimerReasonDialog(disclaimer.id);
                                }
                            }}
                        >
                            <ErrorOutlineIcon
                                style={{
                                    ...iconStyle,
                                    fontSize: 13,
                                    verticalAlign: 'middle',
                                    color: '#2e2e2e',
                                }}
                            />
                            &nbsp;{disclaimer.reason}
                        </Typography>
                    }
                    {!disclaimer.reason && canUserEditContract &&
                        <Button
                            color="primary"
                            className={classes.addItemCompactButton}
                            disabled={openDisclaimerReasonDialog === undefined}
                            onClick={e => {
                                if (openDisclaimerReasonDialog) {
                                    openDisclaimerReasonDialog(disclaimer.id);
                                }
                            }}
                        >
                            + ADD DISCLAIMER REASON
                        </Button>
                    }
                </div>
            )}
        </Grid>
    );
};

interface SettingsCardProps {
    heading: string;
    children: JSX.Element;
    rootClass?: string;
}

export const SettingsCard = (props: StyledProps & SettingsCardProps) => {
    const { classes, heading, children, rootClass } = props;

    return (
        <Card className={classNames(classes.settingsCard, rootClass)}>
            <CardHeader className={classes.header} title={heading} />
            <CardContent className={classes.content}>
                {children}
            </CardContent>
        </Card>
    );
};