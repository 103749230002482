import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import {
    CaseType,
    EntityRelationship,
    ProductCategory,
    PricingModelEnum,
    EntityRelationshipType,
    CaseLabelUX,
    GatherCaseRecord,
    FuneralHomeCaseRecord,
    CaseStatus,
    GatherCaseReportAssignee,
    Nullable,
    WorkflowRecord,
    EntityRecord,
    FuneralHomeRecord,
    CaseWatcher,
    KeeptrackCardRecord,
    TaskRecord,
    LongAddress,
    UserProfileRecord,
    CaseTaskRecord,
    CaseTypeDefinition,
} from "../../shared/types";
import { getValidator } from '.';

export enum ReportKey {
    CASE_SUMMARY = 'case_summary',
    PRICE_LIST = 'price_list',
    HELPER_REPORT = 'helper',
    INFORMANT_REPORT = 'informant',
    WORKFLOW_BY_CASE = 'workflow_by_case',
    COLLECTIONS_REPORT = 'collections',
    OVERPAID_CONTRACTS = 'overpaid',
    MERCH_SALES_REPORT = 'merch_sales',
    DISCOUNTS_REPORT = 'discounts',
    PRICE_ADJUSTMENTS = 'adjustments',
    SALES_TAX = 'sales_tax',
}

export const getReportKey = (key: string): ReportKey | null => {
    return Object.values(ReportKey).includes(key as ReportKey)
        ? key as ReportKey : null;
};

export enum DateRangeEnum {
    LAST_30_DAYS = 'last_30_days',
    LAST_60_DAYS = 'last_60_days',
    LAST_90_DAYS = 'last_90_days',
    LAST_180_DAYS = "last_180_days",
    THIS_MONTH = 'this_month',
    LAST_MONTH = 'last_month',
    CUSTOM_RANGE = 'custom_range',
}

export enum DateRangeDisplayLookup {
    'last_30_days' = 'Last 30 Days',
    'last_60_days' = 'Last 60 Days',
    'last_90_days' = 'Last 90 Days',
    'last_180_days' = 'Last 180 Days',
    'this_month' = 'This Month',
    'last_month' = 'Last Month',
    'custom_range' = 'Custom Date Range',
}


type Text = string | null;
type BigInt = string | null;

interface ReportBaseDetail {
    funeral_home_name: string;
    funeral_home_key: string;
    case_full_display_name: string;
    case_name: string;
    case_number: Text;
    case_type: CaseType;
    case_assignee: string;
    created_time: string;
    case_dob: Text;
    case_dod: Text;
    converted_to_at_need_date: Text;
}

export interface AgingReportDetailRecord extends ReportBaseDetail {
    last_payer_name: Text;
    last_payment_date: Text;
    aging_days: string;
    last_payment_amount: BigInt;
    case_expense_total: BigInt;
    case_collected_total: BigInt;
    case_remaining_balance: BigInt;
}

export interface AgingReportDetail extends Omit<
    AgingReportDetailRecord,
    'last_payment_amount' | 'case_expense_total' | 'case_collected_total' | 'case_remaining_balance'
> {
    last_payment_amount: number;
    case_expense_total: number;
    case_collected_total: number;
    case_remaining_balance: number;
}

export interface MerchSalesDetailRecord extends ReportBaseDetail {
    contract_created_time: string;
    contract_item_created_time: string;
    contract_item_category: ProductCategory;
    contract_item_name: string;
    category_total: BigInt;
    category_discounts: BigInt;
    category_net_total: BigInt;
}

export interface MerchSalesDetail extends Omit<
    MerchSalesDetailRecord,
    'category_total' | 'category_discounts' | 'category_net_total'
> {
    category_total: number;
    category_discounts: number;
    category_net_total: number;
}

export interface DiscountsByDirectorDetailRecord extends ReportBaseDetail {
    assignee_email: string;
    discount_name: string;
    discount_added_date: string;
    discount_amount: BigInt;
}

export interface DiscountsByDirectorDetail extends Omit<
    DiscountsByDirectorDetailRecord, 'discount_amount'
> {
    discount_amount: number;
}

export interface PriceAdjustmentsDetailRecord extends ReportBaseDetail {
    assignee_email: string;
    contract_item_name: string;
    contract_item_added_date: string;
    contract_item_base_price: BigInt;
    contract_item_listed_price: BigInt;
    adjustment_amount: BigInt;
}

export interface PriceAdjustmentsDetail extends Omit<
    PriceAdjustmentsDetailRecord,
    'contract_item_base_price' | 'contract_item_listed_price' | 'adjustment_amount'
> {
    contract_item_base_price: number;
    contract_item_listed_price: number;
    adjustment_amount: number;
}

export interface CaseHelperDetailRecord extends ReportBaseDetail {
    case_fname: string;
    case_mname: Text;
    case_lname: string;
    helper_fname: string;
    helper_mname: Text;
    helper_lname: string;
    helper_relationship: EntityRelationship;
    helper_relationship_type: EntityRelationshipType;
    helper_relationship_alias: Text;
    helper_address_address1: Text;
    helper_address_address2: Text;
    helper_address_city: Text;
    helper_address_state: Text;
    helper_address_postal_code: Text;
    helper_email: Text;
    helper_phone: Text;
    is_informant: boolean;
}

export interface PriceListRecord {
    product_id: number;
    fh_key: string;
    funeral_home_name: string;
    funeral_home_group_name: string;
    product_deleted_time: Text;
    product_category: ProductCategory;
    product_name: string;
    product_description: string;
    product_cost: BigInt;
    base_price: BigInt;
    pricing_model: PricingModelEnum;
    var_default_quantity: Text;
    var_increment: Text;
    var_increment_units: Text;
    var_max_quantity: Text;
    manufacturer: string;
    external_link: Text;
    model_number: Text;
    sku: Text;
    product_tags: Text;
    is_taxable: 'Yes' | 'No';
    tax_rate_description: Text;
    is_hidden: 'Yes' | 'No';
}

export interface PriceListDetail extends Omit<
    PriceListRecord,
    'product_cost' | 'base_price'> {
    product_cost: number;
    base_price: number;
}


export interface CaseReportDetailRecord extends Pick<GatherCaseRecord,
    'id'
    | 'fname'
    | 'lname'
    | 'name'
    | 'dob_date'
    | 'dod_start_date'
    | 'created_time'
    | 'deleted_time'
    | 'is_test'
>, Pick<FuneralHomeCaseRecord,
    | 'uuid'
    | 'funeral_home_id'
    | 'case_number'
    | 'case_type'
    | 'archived_time'
    | 'updated_time'
    | 'workflow_id'
> {
    full_name: string;
    converted_to_at_need_date: FuneralHomeCaseRecord['case_type_change_time'];
    age: number | null;
    case_status: CaseStatus;
    funeral_home_case_id: FuneralHomeCaseRecord['id'];
    workflow_name: Nullable<WorkflowRecord>['name'];
    funeral_home_name: FuneralHomeRecord['name'];
    funeral_home_key: FuneralHomeRecord['key'];
    owner_funeral_home_id: GatherCaseRecord['funeral_home_id'];
    case_creator: string;
    assignee_fname: EntityRecord['fname'];
    case_assignee: string;
    assignee: GatherCaseReportAssignee;
    filter_date: Date;
    informant_name: string;
    informant_display_relationship: Text;
    informant_phone: Text;
    informant_email: Text;
    location_name: string;
    labels: CaseLabelUX[];
    watchers: (CaseWatcher & Pick<EntityRecord, 'fname' | 'lname'>)[];
}

/* ActivityReport */
interface ActivityReportTaskAssignee {
    user_id: UserProfileRecord['id'];
    entity_id: UserProfileRecord['entity_id'];
    fname: EntityRecord['fname'];
    lname: EntityRecord['lname'];
}

export enum ActivityReportTaskStatus {
    Completed = 'Completed',
    Skipped = 'Skipped',
    Deleted = 'Deleted',
    Uncompleted = 'Uncompleted',
}

export interface ActivityReportRecord {
    id: TaskRecord['id'];
    task_id: TaskRecord['id'];
    funeral_home_name: FuneralHomeRecord['name'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_full_name: string;
    case_labels: CaseLabelUX[];
    case_name: GatherCaseRecord['name'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_id: FuneralHomeCaseRecord['funeral_home_id'];
    keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    task_name: TaskRecord['title'];
    workflow_name: WorkflowRecord['name'] | null;
    tasks_complete_perc: number;
    steps_complete_perc: number;
    prereqs_complete_perc: number | null;
    case_assignee: GatherCaseReportAssignee;
    case_watchers: (CaseWatcher & Pick<EntityRecord, 'fname' | 'lname'>)[];
    case_created_time: Date;
    case_death_date: Date;
    dc_pickup_address: LongAddress | null;
    dc_dropoff_address: LongAddress | null;
    task_status: ActivityReportTaskStatus;
    task_assigned_to_all: boolean;
    task_assignees: ActivityReportTaskAssignee[];
    task_note: CaseTaskRecord['note'];
    current_location_name: string;
    current_location_time: Date | null;
    task_resolved_on: Date | null;
    task_due_time: CaseTaskRecord['complete_by_time'];
    timezone: string;
}


export interface WorkflowByCaseDetailRecord extends ReportBaseDetail {
    workflow_name: string;
    case_expense_total: BigInt;
    case_collected_total: BigInt;
    case_remaining_balance: BigInt;
}

export interface WorkflowByCaseDetail extends Omit<
    WorkflowByCaseDetailRecord, 'case_expense_total' | 'case_collected_total' | 'case_remaining_balance'
> {
    case_collected_total: number;
    case_expense_total: number;
    case_remaining_balance: number;
}

export interface SalesTaxReportRecord extends ReportBaseDetail {
    taxable_sales: BigInt;
    non_taxable_sales: BigInt;
    tax_total: BigInt;
    contract_total: BigInt;
}

export interface SalesTaxReportDetail extends Omit<
    SalesTaxReportRecord, 'taxable_sales' | 'non_taxable_sales' | 'tax_total' | 'contract_total'> {
    taxable_sales: number;
    non_taxable_sales: number;
    tax_total: number;
    contract_total: number;
}

// Standard report request
const StandardReportRequestDefinition = {
    funeralHomeIds: t.array(t.number),
    fromDate: DateFromISOString,
    toDate: DateFromISOString,
    reportKey: t.string,
    productCategories: t.array(t.string),
    caseTypes: t.array(t.string),
    dateType: t.string,
    returnType: t.union([t.literal('csv'), t.literal('json')]),
    showDeleted: t.boolean,
};

const StandardReportRequestType = t.type(StandardReportRequestDefinition);

export interface StandardReportRequest extends t.TypeOf<typeof StandardReportRequestType> {
}

export class StandardReportRequest {
    public static fromRequest = getValidator<StandardReportRequest>(StandardReportRequestType);
}


// Activity Report Request
export enum ActivityReportDateType {
    CaseCreatedOn = 'CaseCreatedOn',
    TaskResolvedOn = 'TaskResolvedOn',
}

const ActivityReportDateTypeDefinition = t.union([
    t.literal(ActivityReportDateType.CaseCreatedOn),
    t.literal(ActivityReportDateType.TaskResolvedOn),
]);

const ActivityReportRequestType = t.type({
    funeralHomeIds: t.array(t.number),
    fromDate: DateFromISOString,
    toDate: DateFromISOString,
    caseTypes: t.array(CaseTypeDefinition),
    dateType: ActivityReportDateTypeDefinition,
    taskNames: t.union([t.array(t.string), t.null]),
});

export interface ActivityReportRequest extends t.TypeOf<typeof ActivityReportRequestType> {
}
export class ActivityReportRequest {
    public static fromRequest = getValidator<ActivityReportRequest>(ActivityReportRequestType);
}
