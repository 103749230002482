import Tooltip from '@mui/material/Tooltip';

import { GatherPhoto } from '../../../../types';
import { getPhotoUrl } from '../../../../services';
import { CloudinaryTransformationsType, isAlbumEntry } from '../../../../shared/types';

interface Classes {
    photo: string;
    container: string;
    pendingTag: string;
}
interface Props {
    photo: GatherPhoto;
    onPhotoClick?: (target: HTMLElement) => void;
    classes: Partial<Classes>;
    shouldRenderPendingTag: boolean;
    caseDisplayFname: string;
    caseDisplayName: string;
    width: number;
}
const HangingPhoto = (props: Props) => {
    const {
        photo,
        onPhotoClick,
        shouldRenderPendingTag,
        classes,
        caseDisplayFname,
        caseDisplayName,
        width,
    } = props;

    if (!isAlbumEntry(photo.photo)) {
        return null;
    }

    const transformations: CloudinaryTransformationsType[] = [{
        width: width,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];

    if (photo.photo.transformations && photo.photo.transformations.cloudinary) {
        transformations.unshift(photo.photo.transformations.cloudinary);
    }

    return (
        <div className={classes.container}>
            <img
                alt={`Photo of ${caseDisplayName}`}
                src={getPhotoUrl(photo.photo.public_id, transformations)}
                className={classes.photo}
                onClick={e => {
                    if (onPhotoClick) {
                        onPhotoClick(e.currentTarget);
                    }
                }}
            />
            {shouldRenderPendingTag &&
                <Tooltip
                    title={`To keep ${caseDisplayFname}'s page secure, this memory will be visible once approved`}
                    enterDelay={200}
                    placement="bottom"
                >
                    <div className={classes.pendingTag}> Pending </div>
                </Tooltip>
            }
        </div>
    );
};

export default HangingPhoto;