import * as React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import {
    Index,
    Collection,
    CollectionCellRendererParams,
    CollectionCellSizeAndPosition
} from 'react-virtualized';

import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';

import { GatherPhoto } from '../../../../types';
import PhotoHanger from './PhotoHanger';
import HangingPhoto from './HangingPhoto';
import { HangingPhotosAnimation } from './HangingPhotosAnimation';
import { styleWrapper, StyledProps, MAX_HEIGHT } from './styles';
import { GatherCasePublic, GuestListUser, GuestListUserMap, isAlbumEntry } from '../../../../shared/types';
import HangingAvatar from './HangingAvatar';
import { getPhotoUploader } from '../utils';
import withStyles from '@mui/styles/withStyles';

const mod = require('react-swipeable-views-core').mod;

interface PhotoClasses {
    id: number;
    class: string;
    height: number;
}

const CYCLE_WIDTH = 1232;

const empty = {
    height: 0,
    width: 0,
    x: 0,
    y: 0
};

interface Props {
    activeCase: GatherCasePublic;
    casePhotos: GatherPhoto[];
    casePhotosLength: number;
    fileUploadRef: HTMLInputElement | undefined;
    width: number;
    height: number;
    guestListMap: GuestListUserMap;
    showReviewPhotosButton: boolean;
    onPhotoClick: (target: HTMLElement, photo: GatherPhoto) => void;
    renderLeftSide: () => JSX.Element;
    renderMiddleSection: (() => JSX.Element) | undefined;
    renderRightSide: () => JSX.Element;
    setCollectionRef: (ref: Collection) => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, activeGuest: GuestListUser) => void;
}

class VirtualizedHangingPhotos extends React.Component<StyledProps & Props> {
    protected positionArray = [
        { center: 100, width: 200, zIndex: 4 },
        { center: 268, width: 193, zIndex: 3 },
        { center: 405, width: 200, zIndex: 1 },
        { center: 474, width: 254, zIndex: 2 },
        { center: 698, width: 241, zIndex: 1 },
        { center: 907, width: 217, zIndex: 3 },
        { center: 987, width: 195, zIndex: 2 },
        { center: 1140, width: 205, zIndex: 1 }
    ];
    protected collectionRef: Collection;
    protected totalWidth = 0;

    constructor(props: Props & StyledProps) {
        super(props);
        this.calcConstants();
    }

    componentDidMount() {
        // I know Josh won't like this but it works
        document.addEventListener('gather.user_profile.photo_update', this.forceCollectionUpdate);
    }

    componentWillUnmount() {
        // I know Josh won't like this but it works
        document.removeEventListener('gather.user_profile.photo_update', this.forceCollectionUpdate);
    }

    componentDidUpdate(prevProps: Readonly<StyledProps & Props>) {
        const {
            casePhotosLength: prevCaseLength,
            casePhotos: prevCasePhotos,
            guestListMap: prevGuestListMap,
            classes: prevClasses,
            showReviewPhotosButton: prevShowReviewPhotosButton
        } = prevProps;
        const { casePhotosLength, casePhotos, guestListMap, classes, showReviewPhotosButton } = this.props;

        // need to check the classes also, 
        // when RememberTheme is changed classNames also changes 
        const classesChanged = !isEqual(prevClasses, classes);
        const guestListChanged = !isEqual(guestListMap, prevGuestListMap);
        const reviewPhotosButtonStatus = prevShowReviewPhotosButton !== showReviewPhotosButton;
        const casePhotosChanged = prevCaseLength !== casePhotosLength || !isEqual(prevCasePhotos, casePhotos);

        if (casePhotosChanged || guestListChanged || classesChanged || reviewPhotosButtonStatus) {
            this.collectionRef.calculateSizeAndPositionData();
            this.forceCollectionUpdate();
        }
    }

    forceCollectionUpdate = () => {
        this.collectionRef?.forceUpdate();
    };

    photoClassArray = (): PhotoClasses[] => {
        const { classes } = this.props;

        return [
            { id: 1, height: 154, class: classes.photo1 },
            { id: 2, height: 32, class: classes.photo2 },
            { id: 3, height: 290, class: classes.photo3 },
            { id: 4, height: 63, class: classes.photo4 },
            { id: 5, height: 83, class: classes.photo5 },
            { id: 6, height: 56, class: classes.photo6 },
            { id: 7, height: 310, class: classes.photo7 },
            { id: 8, height: 135, class: classes.photo8 }
        ];
    };

    setCollectionRef = (ref: Collection) => {
        this.collectionRef = ref;
        this.props.setCollectionRef(ref);
    };

    calcConstants = () => {
        const { casePhotosLength } = this.props;

        let numCycles = 0;
        let endNumber = 0;

        if (casePhotosLength) {
            numCycles = Math.floor((casePhotosLength - 1) / 8);
            endNumber = (casePhotosLength - 1) % 8;
        }
        const endWidth = ((this.positionArray[endNumber]).width) / 2;

        this.totalWidth = endWidth + (CYCLE_WIDTH * numCycles) + this.positionArray[endNumber].center;
    };

    cellRenderer = ({ index, style, key }: CollectionCellRendererParams) => {
        const { casePhotos, casePhotosLength, renderLeftSide, renderRightSide, renderMiddleSection } = this.props;
        let element = null;
        let zIndex;
        let disablePointerEvents = true;

        const actualIndex = index - 1;
        const photo = actualIndex >= 0 ? casePhotos[actualIndex] : undefined;

        // handle the fake photo is we have no photos yet
        const adjustedLength = (casePhotosLength) ? casePhotosLength : casePhotosLength + 1;

        if (index === 0) {
            element = renderLeftSide();
            zIndex = 5;
            disablePointerEvents = false;
        } else if (index === adjustedLength + 1) {
            if (casePhotosLength < 2) {
                element = renderMiddleSection?.();
                zIndex = 1;
                disablePointerEvents = false;
            }
        } else if (index === adjustedLength + 2) {
            element = renderRightSide();
            zIndex = 2;
            disablePointerEvents = false;
        } else if (actualIndex === 0) {
            element = this.renderFirstOrNoPhoto();
            zIndex = 4;
            disablePointerEvents = false;
        } else if (!photo || !isAlbumEntry(photo.photo)) {
            element = null;
        } else {
            element = this.renderPhoto(photo, actualIndex);
            zIndex = (this.positionArray[actualIndex % 8]).zIndex;
        }
        return (
            <div
                key={key}
                style={{
                    ...style,
                    zIndex,
                    pointerEvents: disablePointerEvents ? 'none' : undefined
                }}
            >
                {element}
            </div>
        );
    };

    cellSizeAndPositionGetter = ({ index }: Index): CollectionCellSizeAndPosition => {
        const { casePhotosLength, showReviewPhotosButton } = this.props;

        const firstSectionWidth = showReviewPhotosButton ? 310 : 150;
        const middleSectionWidth = (casePhotosLength < 2) ? 400 : 0;
        const offset = 20;

        // handle the fake photo is we have no photos yet
        const adjustedLength = (casePhotosLength) ? casePhotosLength : casePhotosLength + 1;

        // render end section
        if (index === 0) {
            return {
                height: MAX_HEIGHT - 20,
                width: firstSectionWidth,
                x: 0,
                y: 16,
            };
        } else if (index === adjustedLength + 1) {
            if (casePhotosLength < 2) {
                return {
                    height: MAX_HEIGHT - 20,
                    width: 400,
                    x: (firstSectionWidth - offset) + this.totalWidth,
                    y: 0
                };
            }
            return empty;
        } else if (index === adjustedLength + 2) {
            return {
                height: MAX_HEIGHT - 20,
                width: 270,
                x: (firstSectionWidth - offset) + this.totalWidth + middleSectionWidth,
                y: 32,
            };
        }
        const actualIndex = index - 1;
        const cycleNum = Math.floor(actualIndex / 8);
        const entry = this.positionArray[actualIndex % 8];
        return {
            height: MAX_HEIGHT - 20,
            width: entry.width,
            x: (firstSectionWidth - offset) + this.totalWidth -
                (cycleNum * CYCLE_WIDTH + entry.center + (entry.width / 2)),
            y: 0,
        };
    };

    renderFirstOrNoPhoto = () => {
        const {
            classes,
            guestListMap,
            casePhotos,
            casePhotosLength,
            onPhotoClick,
            fileUploadRef,
            activeCase,
            openGuestPopper
        } = this.props;
        const photo = casePhotosLength ? casePhotos[0] : undefined;

        // load in user details if needed
        const uploader = getPhotoUploader(photo, guestListMap);
        const isPending = photo && isAlbumEntry(photo.photo) ? photo.photo.is_pending_decision : false;
        const tooltipTitle = photo && photo.photo && isAlbumEntry(photo.photo) && uploader
            && `Photo uploaded by ${uploader.fname} ${photo.photo.timeSinceUploaded}`;

        return (
            <div
                className={classNames(
                    classes.flexColumnCentered,
                    classes.frameContainer,
                    casePhotosLength >= 1 && classes.onePhoto,
                    casePhotosLength > 1 && classes.twoPhotos
                )}>
                {!!casePhotosLength &&
                    <Tooltip title={tooltipTitle || ''} placement="top">
                        <span>
                            <HangingAvatar
                                user={uploader}
                                onClick={e => uploader && openGuestPopper(e, uploader)}
                            />
                        </span>
                    </Tooltip>
                }
                <PhotoHanger stringHeight={154} stringClass={classes.photoString} />
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.photoFrame,
                        casePhotosLength && classes.hasImage,
                        casePhotosLength && classes.backgroundNone,
                        isPending && classes.isPendingPhoto,
                    )}
                    onClick={() => !photo && fileUploadRef?.click()}
                >
                    {photo ?
                        <HangingPhoto
                            photo={photo}
                            classes={{
                                photo: classNames(
                                    classes.photo,
                                    isPending && classes.borderSolidOrange
                                ),
                                pendingTag: classes.bottomChipButton,
                                container: classes.imgContainer
                            }}
                            width={200}
                            onPhotoClick={(target) => onPhotoClick(target, photo)}
                            caseDisplayFname={activeCase.display_fname}
                            caseDisplayName={activeCase.display_full_name}
                            shouldRenderPendingTag={isPending}
                        />
                        :
                        <PersonIcon />
                    }
                </Grid>
            </div>
        );
    };

    renderPhoto = (photo: GatherPhoto, num: number) => {
        const { classes, guestListMap, onPhotoClick, activeCase, openGuestPopper } = this.props;
        const photoClass = this.photoClassArray()[mod(num, 8)];
        const isPending = isAlbumEntry(photo.photo) ? photo.photo.is_pending_decision : false;
        const uploader = getPhotoUploader(photo, guestListMap);
        const tooltipTitle = isAlbumEntry(photo.photo) && uploader
            && `Photo uploaded by ${uploader.fname} ${photo.photo.timeSinceUploaded}`;

        const photoPosition = this.positionArray[mod(num, 8)];

        return (
            <HangingPhotosAnimation
                timeout={900}
                key={`${num}_hanging_photo`}
                rootClass={classNames(photoClass.class, classes.fitHeight, classes.transitionClass)}
                onEntered={(node, isAppearing) => this.forceUpdate()}
            >
                <div
                    className={classNames(
                        classes.flexColumnCentered,
                        classes.fitHeight,
                        classes.pointerEventsVisible,
                    )}
                >
                    <Tooltip title={tooltipTitle || ''} placement="top">
                        <span>
                            <HangingAvatar
                                user={uploader}
                                onClick={e => uploader && openGuestPopper(e, uploader)}
                            />
                        </span>
                    </Tooltip>

                    <PhotoHanger stringHeight={photoClass.height} />

                    <HangingPhoto
                        photo={photo}
                        onPhotoClick={(target) => onPhotoClick(target, photo)}
                        classes={{
                            photo: classNames(
                                classes.photo,
                                isPending && classes.borderSolidOrange
                            ),
                            container: classes.imgContainer,
                            pendingTag: classes.bottomChipButton
                        }}
                        width={photoPosition ? photoPosition.width : 200}
                        caseDisplayFname={activeCase.display_fname}
                        caseDisplayName={activeCase.display_full_name}
                        shouldRenderPendingTag={isPending}
                    />
                </div>
            </HangingPhotosAnimation>
        );
    };

    render() {
        const { casePhotosLength, height, width } = this.props;
        // index 0 is the left section
        // index casePhotos+1 is the middle section if we have zero or one photos
        // index casePhotos+2 is the right section
        // If we have zero photos we add an extra fake padding to render the photo
        const cellCount = casePhotosLength ? casePhotosLength + 3 : 4;

        this.calcConstants();
        return <Collection
            cellCount={cellCount}
            cellRenderer={this.cellRenderer}
            cellSizeAndPositionGetter={this.cellSizeAndPositionGetter}
            height={height}
            width={width}
            autoHeight
            className="virtualized-hanging-photos"
            ref={this.setCollectionRef}
        />;
    }
}

export default withStyles(styleWrapper<Props>())(VirtualizedHangingPhotos);
