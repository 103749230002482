import { DialogActions, DialogContent, Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import classNames from 'classnames';
import { SlideTransition } from '../../common/Transitions';
import GButton from '../../common/GButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
    CaseType,
    FuneralHomeUX,
    GatherCaseSummary,
    GatherCaseUX,
    GetPolicyAndAssociationsResponse,
    InsurancePolicyUX,
} from '../../../shared/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGDispatch } from '../../../types';
import {
    attachPolicyToCase,
    getPolicyAndAssociations,
    downloadPolicyDocuments
} from '../../../actions/Insurance.action';
import LoadingSpinner from '../../common/LoadingSpinner';
import PolicyContents from './PolicyContents';
import AttachExistingCaseDialog from '../AttachExistingCaseDialog';
import useSnackbar from '../../common/hooks/useSnackbar';
import makeStyles from '@mui/styles/makeStyles';
import { GStyles } from '../../../styles/GStyles';
import { FuneralHomeRoutePage, RouteBuilder, getIntercomTargetProp, removeNonAscii } from '../../../services';
import PolicyHeader from './PolicyHeader';
import PolicyAggregateContent from './PolicyAggregateContent';
import { ActivePolicyCircleIdentifier } from './PolicyCircles';
import { log } from '../../../logger';
import { titleCase } from 'title-case';
import { useGNavigate } from '../../common/hooks/useGNavigate';
import { useLocation } from 'react-router-dom';
import PolicyPotentialMatchSummary from './PolicyCaseSummary';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            overflow: 'initial',
            width: '500px',
            borderRadius: '5px',
            boxShadow: theme.shadows[10],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.down(600)]: {
                width: '100%',
                margin: 0,
                top: '30px',
                height: '100%',
            },
        },
        dialogFooterActions: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            borderRadius: '0 0 5px 5px',
        },
        dialogContents: {
            overflowY: 'auto',
            padding: 0,
        },
        loadingSpinner: {
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            margin: '50px 0',
        },
    }),
    { name: 'PolicyDialog' },
);

interface RouterStateProps {
    selectedCase: GatherCaseUX;
    summary: GatherCaseSummary;
    policId: number | null;
}

interface Props {
    initialPolicyId: number;
    funeralHome: Pick<FuneralHomeUX, 'id' | 'key'>;
    isOpen: boolean;
    insuranceColor: string;
    zIndex: number;
    closeDialog: () => void;
    onPolicyCaseStatusChanged?: () => void;
}

const PolicyDialog = (props: Props) => {
    const { initialPolicyId, zIndex, insuranceColor, funeralHome, isOpen, closeDialog, onPolicyCaseStatusChanged } =
        props;

    const funeralHomeId = funeralHome.id;

    const dispatch = useGDispatch();
    const classes = useStyles();
    const navigate = useGNavigate();

    const location = useLocation();
    const state = location.state as RouterStateProps;

    const [existingCaseDialogOpen, setExistingCaseDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [associatedCase, setAssociatedCase] = useState<GatherCaseSummary | null>(null);
    const [activePolicyCircle, setActivePolicyCircle] = useState<ActivePolicyCircleIdentifier>(initialPolicyId);
    const [policies, setPolicies] = useState<InsurancePolicyUX[]>([]);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    // update the active policy ID when the dialog is opened
    useEffect(() => {
        setActivePolicyCircle(initialPolicyId);
    }, [initialPolicyId]);

    const showSnackbar = useSnackbar();

    const loadPolicy = useCallback(async () => {
        if (!isOpen) {
            return;
        }
        setIsLoading(true);
        const resp: GetPolicyAndAssociationsResponse | null = await dispatch(
            getPolicyAndAssociations({ policyId: initialPolicyId, funeralHomeId }),
        );
        setIsLoading(false);
        if (!resp) {
            showSnackbar('Failed to load policy', 'error');
            return;
        }
        setAssociatedCase(resp.caseSummary);
        setPolicies(resp.policies);
    }, [initialPolicyId, funeralHomeId, isOpen, dispatch, showSnackbar]);

    useEffect(() => {
        loadPolicy();
    }, [loadPolicy]);

    const handleCaseStatusChanged = useCallback(() => {
        onPolicyCaseStatusChanged?.();
        loadPolicy();
    }, [onPolicyCaseStatusChanged, loadPolicy]);

    const activePolicy = useMemo(() => {
        if (activePolicyCircle === 'AGGREGATE' || activePolicyCircle === 'CASE_SUMMARY') {
            return null;
        }
        return policies.find((p) => p.policy_id === activePolicyCircle) ?? null;
    }, [activePolicyCircle, policies]);

    const handleDownloadPolicyPDF = async () => {
        if (!activePolicy) {
            return;
        }
        setIsDownloading(true);
        await dispatch(downloadPolicyDocuments({
            policyId: activePolicy.policy_id,
            funeralHomeId,
            fileName: removeNonAscii(`${activePolicy.insured.lastName}_Policy_${activePolicy.policy_number}.pdf`),
        }));
        setIsDownloading(false);
    };

    const handleExistingCaseSelected = useCallback(async (c: GatherCaseSummary, policyId: number | null) => {
        if (!policyId) {
            log.warn('No active policy to attach to case');
            return;
        }
        const result = await dispatch(
            attachPolicyToCase({
                policyId,
                funeralHomeId: funeralHome.id,
                caseUuid: c.uuid,
            }),
        );
        if (result) {
            handleCaseStatusChanged();
        }
    }, [dispatch, funeralHome.id, handleCaseStatusChanged]);

    const dialogContent = () => {
        if (isLoading) {
            return <LoadingSpinner containerClass={classes.loadingSpinner} />;
        } else if (activePolicyCircle === 'AGGREGATE') {
            return <PolicyAggregateContent policies={policies} insuranceColor={insuranceColor} />;
        } else if (activePolicyCircle === 'CASE_SUMMARY') {
            return <PolicyPotentialMatchSummary
                associatedCase={associatedCase}
                policies={policies}
                insuranceColor={insuranceColor}
            />;
        } else if (!activePolicy) {
            // likely loading or between states
            return null;
        }

        return <PolicyContents policy={activePolicy} insuranceColor={insuranceColor} />;
    };

    const initialState = activePolicy ? {
        policy_id: activePolicy.policy_id,
        firstName: titleCase(activePolicy.insured.firstName.toLowerCase()),
        lastName: titleCase(activePolicy.insured.lastName.toLowerCase()),
        caseType: CaseType.pre_need,
    } : undefined;

    const handleCreateCaseClick = () => {
        navigate(RouteBuilder.FuneralHome(funeralHome.key, FuneralHomeRoutePage.CREATECASE),
            { state: { initialState: initialState, createCaseWithoutRedux: true } }
        );
    };

    useEffect(() => {
        if (state !== null) {
            if (state.selectedCase) {
                handleCaseStatusChanged();
            }
            if (state.summary) {
                handleExistingCaseSelected(state.summary, state.policId);
            }
        }
    }, [location, handleCaseStatusChanged, handleExistingCaseSelected, state]);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={closeDialog}
                TransitionComponent={SlideTransition}
                classes={{
                    paper: classes.root,
                }}
                style={{ zIndex }}
            >
                {!isLoading && (
                    <PolicyHeader
                        activePolicy={activePolicy}
                        activePolicyCircle={activePolicyCircle}
                        insuranceColor={insuranceColor}
                        associatedCase={associatedCase}
                        policies={policies}
                        funeralHome={funeralHome}
                        zIndex={zIndex}
                        closeDialog={closeDialog}
                        onChangeActivePolicyCircle={setActivePolicyCircle}
                        onCreateCaseClick={() => handleCreateCaseClick()}
                        onAttachExistingCaseClick={() => setExistingCaseDialogOpen(true)}
                        onPolicyCaseStatusChanged={handleCaseStatusChanged}
                    />
                )}
                <DialogContent className={classes.dialogContents}>{dialogContent()}</DialogContent>
                {activePolicyCircle !== 'AGGREGATE' && activePolicyCircle !== 'CASE_SUMMARY' && !isLoading && (
                    <DialogActions className={classNames(GStyles.flexDirectionColumn, GStyles.padding0)}>
                        <div
                            className={classes.dialogFooterActions}
                            style={{
                                background: insuranceColor,
                            }}
                        >
                            <GButton
                                variant="text"
                                text="download policy pdf"
                                disabled={isDownloading}
                                startIcon={isDownloading ? undefined : <InsertDriveFileIcon />}
                                sx={{
                                    fontSize: '12px',
                                    color: '#fff',
                                    'span:first-of-type': {
                                        marginRight: '2px',
                                        '& svg': {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                                onClick={handleDownloadPolicyPDF}
                                {...getIntercomTargetProp(`PolicyDialog-DownloadPDF`)}
                            />
                        </div>
                    </DialogActions>
                )}
            </Dialog>

            {(activePolicy || (activePolicy && activePolicyCircle === 'CASE_SUMMARY')) && (
                <AttachExistingCaseDialog
                    funeralHome={funeralHome}
                    policyToAttach={activePolicy}
                    isExistingCaseDialogOpen={existingCaseDialogOpen}
                    closeExisitingCaseDialog={() => setExistingCaseDialogOpen(false)}
                    zIndex={zIndex}
                />
            )}
        </>
    );
};

export default PolicyDialog;
