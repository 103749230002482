import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import CardHeader from './CardHeader';
import {
    Transaction,
    PaymentGrouping,
    PaymentRequest,
    PaymentTransaction,
    PaymentMethod,
    PaymentMode,
    PaymentType
} from '../../../../../shared/types';
import Dinero from 'dinero.js';
import { Payer } from '..';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import DisabledPermissionTooltip from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        position: 'relative',
        margin: '36px 0 18px'
    },
    card: {
        borderRadius: 8,
        width: 300,
        boxShadow: 'none',
        height: 'auto',
        border: `1px solid ${theme.palette.primary.main}`,
        '& $cardContent': {
            padding: 0,
            '& p': {
                fontSize: 16
            }
        },
    },
    avatar: {
        cursor: 'auto',
        position: 'absolute',
        top: -36,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 72,
        width: 72,
        zIndex: 1,
        padding: 0,
        textTransform: 'capitalize',
        boxSizing: 'border-box',
        border: `2px solid ${theme.palette.background.paper}`,
        '& svg': {
            fontSize: 36
        }
    },
    header: {
        padding: '36px 8px 12px',
        background: theme.palette.primary.main,
        '& p': {
            fontSize: 20,
            fontWeight: 200,
            lineHeight: '1.25em',
            textAlign: 'center',
            color: theme.palette.common.white
        }
    },
    or: {
        height: 32,
        width: 32,
        borderRadius: '50%',
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 200,
        fontSize: 15,
    },
    button: {
        textTransform: 'none',
        fontWeight: 400,
        fontSize: 17
    },
    inner: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 152,
        margin: '36px 0'
    },
    footer: {
        borderTop: '1px solid',
        padding: 8,
        textAlign: 'center',
        '& button': {
            textTransform: 'none',
            minHeight: 32,
            fontWeight: 300,
            padding: '4px 16px',
        }
    },
    cardContent: {},
});

type StyledProps = Props & WithGStyles<'root' | 'card' | 'primary' | 'inverted' | 'cardContent' | 'avatar' | 'header'
    | 'or' | 'button' | 'inner' | 'footer'>;

interface Props {
    showAssignPayerDialog: (event: React.MouseEvent<HTMLElement>, paymentInfo: PaymentRequest) => void;
    showRecordInsurancePolicyDialog: (activePaymentInfo: PaymentRequest, payer: Payer) => void;
    deletePayment: (paymentId: number) => void;
    group: PaymentGrouping;
    funeralHomeCaseId: number;
}

class EmptyPaymentCard extends React.Component<StyledProps> {

    getPaymentInfo(method?: PaymentMethod): PaymentRequest {
        const { group, funeralHomeCaseId } = this.props;
        const payment = group.transactions[0];
        return {
            payment_id: payment.payment_id,
            method: method ? method : payment.method,
            mode: PaymentMode.IN_PERSON,
            type: PaymentType.CASE,
            amount: Dinero({ amount: payment.debit + payment.credit, currency: 'USD' }),
            merch_fee: Dinero({ amount: 0, currency: 'USD' }),
            memo: payment.description || '',
            funeralHomeCaseId,
            is_anon: payment.is_anon,
            payer_id: payment.payer_id || NaN,
            payer_name: payment.payer_name || '',
            external_id: payment.external_id || ''
        };
    }

    render() {
        const {
            classes,
            showRecordInsurancePolicyDialog,
            group,
            showAssignPayerDialog,
        } = this.props;

        const payment = group.transactions[0];
        const payer = PaymentTransaction.constructPayer(payment);
        const amount = Transaction.dollarFormatUnsigned(payment);

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Avatar className={classes.avatar}>
                            <AttachMoneyIcon />
                        </Avatar>

                        <CardHeader
                            heading={`payer ${Math.abs(group.payer_id)}`}
                            received="0"
                            expected={amount}
                            collected={0}
                            paymentCount={1}
                        />

                        <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                            {disabled =>
                                <div className={classes.inner}>

                                    <Button
                                        disabled={disabled}
                                        color="primary"
                                        onClick={(e) => showAssignPayerDialog(e, this.getPaymentInfo())}
                                        className={classes.button}
                                    >
                                        Click to select who will pay...
                                    </Button>

                                    <Avatar className={classes.or}>
                                        or
                                    </Avatar>

                                    <Button
                                        disabled={disabled}
                                        color="primary"
                                        variant="outlined"
                                        onClick={
                                            () => showRecordInsurancePolicyDialog(
                                                this.getPaymentInfo(PaymentMethod.insurance),
                                                payer,
                                            )
                                        }
                                    >
                                        <InsertDriveFileIcon />&nbsp;Enter insurance policy
                                    </Button>
                                </div>
                            }
                        </DisabledPermissionTooltip>

                        <div className={classes.footer}>
                            <DisabledPermissionTooltip permission={Permission.REMOVE_PAYMENTS}>
                                {disabled =>
                                    <Button
                                        disabled={disabled}
                                        onClick={(e) => this.props.deletePayment(payment.payment_id)}
                                        className={classes.colorRed}
                                    >
                                        <RemoveCircleOutlineIcon />&nbsp;
                                        Remove this payer
                                    </Button>
                                }
                            </DisabledPermissionTooltip>
                        </div>

                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withGStyles(styles)(EmptyPaymentCard);
