import { Component } from 'react';
import momentTz from 'moment-timezone';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { GatherEvent, GatherCaseUX, GatherCasePublic, EventUpdateRequest } from '../../../shared/types';
import { StoreState } from '../../../types';
import { updateEvent } from '../../../actions/GatherEvent.action';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { DateTimeFormat } from '../../../shared/utils';
import { SlideTransition } from '../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            margin: 8,
            width: 360,
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
        },
    },
    clearIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        background: theme.palette.primary.main,
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '24px !important',
        margin: 'auto',
        overflowX: 'hidden',
        width: '100%',
        boxSizing: 'border-box'
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        color: theme.palette.common.white,
    },
    textField: {
        margin: '24px 0',
    },
    textAreaMinHeight: {
        minHeight: 80
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            margin: '8px 0'
        }
    },
    margin_8: {
        margin: 8
    },
    eventDateTime: {
        fontWeight: 200,
        fontSize: 18,
        '&:last-child': {
            lineHeight: 1
        }
    },
    eventTimeContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        cursor: 'pointer',
        margin: 'auto',
        textAlign: 'center'
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    dialogPaper: {},
});

function mapStateToProps({ locationState }: StoreState) {
    return {
        gatherLocations: locationState.locations
    };
}

type Props = ReturnType<typeof mapStateToProps> & {
    activeCase: GatherCaseUX | GatherCasePublic;
    activeEvent: GatherEvent;
    isDialogOpen: boolean;
    dispatch: AppDispatch;
    editEvent: () => void;
    closeDialog: () => void;
    zIndex: number;
};

interface State {
    isSaving: boolean;
    isDeleting: boolean;
    note: string | null;
    saveAttempted: boolean;
}

type StyledProps = WithGStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent' | 'textField'
    | 'clearIcon' | 'headerText' | 'textAreaMinHeight' | 'footer' | 'margin_8' | 'spaceBetween'
    | 'eventTimeContainer' | 'eventDateTime'>;

type CombinedProps = Props & StyledProps;
class EventNoteDialog extends Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            note: this.props.activeEvent.message || null,
            saveAttempted: false,
            isSaving: false,
            isDeleting: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.activeEvent.message !== prevProps.activeEvent.message) {
            this.setState({ note: this.props.activeEvent.message });
        }
    }

    renderTimeContainer = () => {
        const { classes, activeEvent, activeCase, gatherLocations } = this.props;

        const location = gatherLocations.find(loc => loc.id === activeEvent.location_id);
        const timezone = momentTz.tz.guess() || location && location.address.timezone
            || activeCase.funeral_home.timezone || 'America/Denver';
        const startMoment = momentTz(activeEvent.start_time).tz(timezone);

        const eventTimezone = startMoment.format('z');
        let eventTime = startMoment.format('h:mm');
        let eventTimePeriod = startMoment.format('a');
        const eventDate = startMoment.format(DateTimeFormat.LongDate);

        if (activeEvent.end_time && eventTime && eventTimePeriod) {
            const endMoment = momentTz(activeEvent.end_time).tz(timezone);
            eventTime = `${eventTime}-${endMoment.format('h:mm')}`;
            eventTimePeriod = endMoment.format('a');
        }

        return (
            <Typography
                component="span"
                className={classes.eventTimeContainer}
                onClick={() => this.props.editEvent()}
            >
                <Typography
                    component="span"
                    color="primary"
                    align="center"
                    className={classes.eventDateTime}
                >
                    {eventDate}
                </Typography>
                <Typography
                    component="span"
                    color="primary"
                    align="center"
                    className={classes.eventDateTime}
                >
                    {eventTime}{eventTimePeriod} {eventTimezone}
                </Typography>
            </Typography>
        );
    };

    render() {
        const {
            classes,
            isDialogOpen,
            activeEvent,
            zIndex
        } = this.props;

        const { note, saveAttempted, isSaving, isDeleting } = this.state;

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.root,
                }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.header}
                >
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={() => this.closeDialog()}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classes.headerText}
                    >
                        Event Note
                    </Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                color="secondary"
                                className={classNames(classes.fontSize18, classes.eventTimeContainer)}
                            >
                                <span className={classes.fontWeight500}>{activeEvent.name}</span>
                                {this.renderTimeContainer()}
                            </Typography>

                            <TextField
                                name="note"
                                className={classes.textField}
                                fullWidth
                                value={note || ''}
                                multiline
                                label="Type your event note here..."
                                InputProps={{
                                    classes: {
                                        root: classes.textAreaMinHeight,
                                        inputMultiline: classes.textAreaMinHeight,
                                    }
                                }}
                                required
                                error={saveAttempted && !note}
                                onChange={e => this.setState({ note: e.target.value })}
                            />

                            <div
                                className={classNames(
                                    classes.footer,
                                    activeEvent.message && classes.spaceBetween
                                )}
                            >
                                {activeEvent.message && <Button
                                    color="primary"
                                    className={classes.margin_8}
                                    onClick={e => {
                                        this.setState({ isDeleting: true });
                                        this.handleNoteSave(null);
                                    }}
                                    disabled={isDeleting}
                                >
                                    Delete note
                                </Button>}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.margin_8}
                                    onClick={e => {
                                        if (!note) {
                                            this.setState({ saveAttempted: true });
                                            return;
                                        }
                                        this.setState({ saveAttempted: false, isSaving: true });
                                        this.handleNoteSave(note);
                                    }}
                                    disabled={isSaving}
                                >
                                    Save note
                                    {isSaving && <CircularProgress
                                        size={24}
                                        color="primary"
                                        style={{ position: 'absolute' }}
                                    />}
                                </Button>
                            </div>

                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize12}
                            >
                                This note will be visible to family and friends
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    handleNoteSave = async (note: string | null) => {
        const { dispatch, activeCase, activeEvent } = this.props;

        const changesToMake: EventUpdateRequest = {
            message: note,
        };

        await dispatch(updateEvent(activeEvent.id, changesToMake, activeCase.uuid));
        this.setState({ isSaving: false, isDeleting: false, saveAttempted: false });
        this.closeDialog();
    };

    closeDialog = () => {
        this.setState({ saveAttempted: false, isSaving: false });
        this.props.closeDialog();
    };

}

export default withState(mapStateToProps)(withGStyles(styles)(EventNoteDialog));
