import { Grid } from '@mui/material';
import { ProductCategory, ProductSummary, ProductUX } from '../../shared/types';
import { Theme } from '@mui/material/styles';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import GButton from '../common/GButton';
import { GDataGridTableColumn } from '../common/GDataGrid/types';
import makeStyles from '@mui/styles/makeStyles';
import useFullScreen from '../common/hooks/useFullScreen';
import BaseProductSummaryGrid from './BaseProductSummaryGrid';
import { useFuneralHomeId } from '../common/hooks/useFuneralHomeId';

const useStyles = makeStyles(
    (theme: Theme) => ({
        createNewContainer: {
            width: '100%',
            textAlign: 'right',
            '& button': {
                margin: '12px',
            },
        },
    }),
    { name: 'AllProductContents' },
);

interface Props {
    category: ProductCategory;
    availableProducts: ProductSummary[];
    selectedProducts: ProductUX[];
    onCreateNewProductClick: (productName?: string) => void;
    onLoadProducts: (offset: number, searchText: string) => Promise<void>;
    onProductsAdd: (idList: number[]) => Promise<void>;
    zIndex: number;
}

const GlobalCatalogProductContents = (props: Props) => {
    const {
        onLoadProducts,
        category,
        availableProducts,
        onProductsAdd,
    } = props;
    const fullScreen = useFullScreen();

    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const classes = useStyles();

    const funeralHomeId = useFuneralHomeId();

    useEffect(() => {
        const loadProducts = async () => {
            setIsLoading(true);
            await onLoadProducts(0, '');
            setIsLoading(false);
        };
        loadProducts();

    }, [category, onLoadProducts]);

    const columns: GDataGridTableColumn<ProductSummary & { row_id: number }>[] = [];

    const handleSelectedRow = (rows: GridRowSelectionModel) => {
        setSelectionModel(rows);
    };

    const handleImportSelectedRow = async (
        e: React.MouseEvent<HTMLElement | SVGSVGElement> | undefined,
    ) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (funeralHomeId === undefined || funeralHomeId === null) {
            return;
        }
        const selectedProductsToClone = availableProducts.filter((p) => selectionModel.includes(p.id));
        const selectedProductIdsToClone = selectedProductsToClone.map((product) => product.id);
        setIsSaving(true);
        await onProductsAdd(selectedProductIdsToClone);
    };

    return (
        <>
            <Grid item className={classes.createNewContainer}>
                <GButton
                    color="primary"
                    disabled={!selectionModel.length}
                    variant="contained"
                    isSpinning={isSaving}
                    onClick={handleImportSelectedRow}
                >
                    Import Selected Products
                </GButton>
            </Grid>
            <BaseProductSummaryGrid
                actionColumn={columns}
                availableProducts={availableProducts}
                isLoading={isLoading}
                pinnedColumns={{ left: fullScreen ? undefined : ['Actions'] }}
                hasChanges={Boolean(selectionModel?.length)}
                onRowSelectionModelChange={handleSelectedRow}
            />
        </>
    );
    
};

export default GlobalCatalogProductContents;
