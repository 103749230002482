import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';
import { compose } from 'redux';

import { getSortedEvents } from '../../../../services';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { UserRoles } from '../../../../shared/types';
import { StoreState } from '../../../../types';
import { loadCaseEvents, loadFuneralHomeEvents } from '../../../../actions/GatherEvent.action';
import { AppDispatch } from '../../../../store';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import withState from '../../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import EventListByDateItem from './EventListByDateItem';
import OverlappedEventMessage from '../../../common/OverlappedEventMessage';
import Grid from '@mui/material/Grid';
import { includes, isEqual } from 'lodash';

function mapStateToProps({
    eventState,
    userSession,
    locationState,
    casesState,
}: StoreState,
    { selectedDate }: OwnProps) {
    return {
        activeCase: casesState.selectedCase,
        userSession,
        locations: locationState.locations,
        isLoading: eventState.isLoading,
        allEvents: getSortedEvents([...eventState.gatherEvents, ...eventState.googleEvents], selectedDate),
        gatherEventsCount: eventState.gatherEvents.length
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface OwnProps {
    selectedDate: momentTz.Moment;
    overlappedEventIds: number[];
}
interface State {
    scrollIntoView: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, OwnProps> = theme => ({
    root: {},
    buttonLabel: {
        fontSize: 12,
        fontWeight: 200,
        width: '100%',
        display: 'inline-block',
        textTransform: 'capitalize',
        lineHeight: 1,
        margin: '5px 0 0'
    },
    button: {
        width: 70,
        height: 70,
        padding: 0,
        borderRadius: 8
    },
    eventsContainer: {
        display: 'inline-flex',
        height: '72px !important',
        width: 'auto',
        margin: 0
    },
    buttonBoxShadow: {
        borderRadius: 8,
    },
    buttonPrependTypography: {},
    prependBigText: {},
    scheduleArrangementConference: {},
    buttonGroup: {
        minWidth: 270,
        height: 72,
        padding: 0,
        borderRadius: 8,
        position: 'relative',
        '&$scheduleArrangementConference': {
            minWidth: 320,
            width: 320,
            '& $buttonGroupPrepend': {
                verticalAlign: 'middle',
                display: 'table',
                '& $buttonPrependTypography': {
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    '& svg': {
                        fontSize: 40
                    }
                }
            },
            '& $buttonGroupSecondary': {
                '& $buttonSecondaryTypography': {
                    fontSize: 14,
                    '& $secondaryBigText': {
                        fontSize: 17,
                        lineHeight: 1,
                        marginTop: 2,
                        fontWeight: 400
                    },
                }
            }
        },
    },
    buttonGroupDisabled: {
        opacity: 0.60,
        pointerEvents: 'auto !important' as 'auto'
    },
    buttonGroupPrepend: {
        width: 84,
        minWidth: 84,
        height: 58,
        padding: '6px 0px',
        display: 'inline-block',
        borderRadius: '6px 0px 0px 6px',
        '& $buttonPrependTypography': {
            color: '#fff',
            fontSize: 14,
            fontWeight: 200,
            lineHeight: 1,
        },
        '& $prependBigText': {
            color: '#fff',
            display: 'block',
            fontSize: 40,
            marginTop: 2,
            lineHeight: '40px'
        }
    },
    taskCardGridList: {
        margin: '12px auto 0 !important',
        width: 'calc(100% - 32px) !important',
        maxWidth: 'calc(100vw - 600px) !important',
        minWidth: 'calc(100% - 32px) !important',
    },
    gridList: {
        minHeight: 90,
        minWidth: 200,
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        margin: '36px 0px -10px',
        display: 'flex',
        padding: '0 16px !important',
        overflowY: 'auto',
        listStyle: 'none',
        scrollBehavior: 'smooth',
        '-webkit-overflow-scrolling': 'touch',
        '& li': {
            height: 'auto !important',
            width: 'auto !important',
        }
    },
    gridListTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
        margin: '0 13px',
        '&:last-of-type': {
            margin: '0 0 0 13px',
            paddingRight: 13
        }
    },
    gridListTile: {
        margin: '0 auto',
    },
    secondaryBigText: {},
    secondarySmallText: {},
    buttonSecondaryTypography: {},
    buttonGroupSecondary: {
        width: '100%',
        display: 'inline-block',
        '& $buttonSecondaryTypography': {
            position: 'relative',
            fontSize: 16,
            lineHeight: 1.2,
            fontWeight: 200,
            display: 'inline-block',
            padding: '0 26px',
            '& $secondaryBigText': {
                fontSize: 22,
                display: 'block',
                lineHeight: 1,
                marginTop: 4
            },
            '& $secondarySmallText': {
                fontSize: 12,
            }

        }
    },
    subHeading: {
        color: theme.palette.secondary.main,
        fontSize: 20,
        margin: '15px 0',
    },
    eventMainCont: {
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#fafafa',
        paddingTop: '5px'
    },
    bgWhite: {
        backgroundColor: '#fff !important'
    },
    liveTvIcon: {
        fontSize: 18,
        marginRight: 8
    },
    heading: {
        display: 'flex',
    },
    listTile: {
        overflow: 'unset'
    }
});

const numberInWords = ['No events', 'One', 'Two', 'Three', 'Four', 'Five',
    'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

type StyledProps = OwnProps & WithGStyles<'root' | 'buttonGroup' | 'buttonLabel'
    | 'button' | 'eventsContainer' | 'buttonGroupPrepend' | 'prependBigText' | 'buttonPrependTypography'
    | 'buttonGroupDisabled' | 'gridList' | 'gridListTitle' | 'gridListTile' | 'listTile'
    | 'buttonGroupSecondary' | 'buttonSecondaryTypography' | 'secondaryBigText' | 'secondarySmallText'
    | 'scheduleArrangementConference' | 'eventMainCont' | 'bgWhite' | 'heading'
    | 'buttonBoxShadow' | 'taskCardGridList' | 'subHeading' | 'liveTvIcon'>;

type CombinedProps = StyledProps & DialogProps & InjectedProps;

class EventListByDate extends React.Component<CombinedProps, State> {
    state: State = {
        scrollIntoView: true
    };
    listRef = React.createRef<HTMLUListElement>();

    componentDidMount() {
        const { dispatch, userSession, activeCase, isLoading } = this.props;
        const isFHorGOMUser: boolean = UserRoles.isFHorGOMUser(userSession.userData);
        if (!isLoading && activeCase) {
            if (!isFHorGOMUser) {
                dispatch(loadCaseEvents(activeCase.uuid));
            } else {
                dispatch(loadFuneralHomeEvents(activeCase.funeral_home.id));
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<CombinedProps>, prevState: Readonly<State>): void {
        const { overlappedEventIds, allEvents } = this.props;

        if (!isEqual(overlappedEventIds, prevProps.overlappedEventIds)) {
            const firstOverlapEventIndex = allEvents.findIndex(event => includes(overlappedEventIds, event.id));
            if (firstOverlapEventIndex !== -1) {
                this.listRef.current?.scrollTo({
                    // 270px is element width, 13px is margin around the element
                    left: firstOverlapEventIndex * (270 + 13 * 2),
                    behavior: 'smooth'
                });
            }
        }
    };

    render() {
        const {
            classes,
            locations,
            allEvents,
            isLoading,
            userSession,
            selectedDate,
            gatherEventsCount,
            overlappedEventIds
        } = this.props;

        const isFHorGOMUser = UserRoles.isFHorGOMUser(userSession.userData);

        if (!isFHorGOMUser && !gatherEventsCount) {
            return null;
        }

        const dateDesc = selectedDate.format('MMMM Do');
        const eventCount = allEvents.length;

        return (
            <div className={classes.eventMainCont}>
                <Grid container flexDirection="column" justifyContent="center" minHeight={48}>
                    <Typography
                        color="primary"
                        component="div"
                        className={classes.buttonSecondaryTypography}
                        mb={overlappedEventIds.length ? '4px' : undefined}
                    >
                        {isLoading
                            ? <>
                                <CircularProgress
                                    size={14}
                                    color="secondary"
                                />
                                &nbsp; <span>Checking for new events...</span>
                            </>
                            : <>
                                {eventCount < 11
                                    ? <span>{numberInWords[eventCount]}</span>
                                    : <span>{eventCount}</span>
                                }
                                <span className={classes.fontWeight200}>
                                    {!eventCount
                                        ? ' on '
                                        : ` event${eventCount > 1 ? 's' : ''} on `
                                    }
                                </span>
                                <span>{dateDesc}</span>
                            </>
                        }
                    </Typography>

                    <OverlappedEventMessage
                        overlappedEventsCount={overlappedEventIds.length}
                    />
                </Grid>

                <ul
                    className={classNames(
                        classes.gridList,
                        classes.root,
                        classes.taskCardGridList
                    )}
                    ref={this.listRef}
                    style={{ minWidth: !eventCount ? 0 : 200 }}
                >
                    <div className={classes.eventsContainer}>
                        {allEvents.map(event => {
                            const isEventOverlapping = !!overlappedEventIds.find(id => id === event.id);

                            return (
                                <EventListByDateItem
                                    key={event.id}
                                    event={event}
                                    locations={locations}
                                    isEventOverlapping={isEventOverlapping}
                                />
                            );
                        }
                        )}
                    </div>
                </ul>
            </div>
        );
    }

    handleScrollIntoView = () => {
        this.setState({ scrollIntoView: false });
    };
}

export default compose(
    withState(mapStateToProps),
    withGStyles(styles),
    withFullScreen()
)(EventListByDate) as React.ComponentType<OwnProps>;
