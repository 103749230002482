import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Send from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Zoom from '@mui/material/Zoom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
    UserRoles,
    EntityCaseRole,
    EntitySummary,
    GatherCaseUX,
} from '../../shared/types';
import { useGSelector } from '../../types';

import { splitFullName } from '../../shared/utils';
import { SKYBLUE_COLOR_2 } from '../../constants/colorVariables';
import GSwitch from '../common/inputElements/GSwitch';
import { DCEntityEnum } from '../assignmentPoppers/SelectHelper';
import { green } from '@mui/material/colors';
import makeGStyles from '../../styles/makeGStyles';
import { InviteMethod } from './InvitationForm';
import { HelperInvitationDialogContext } from '../../actions/Dialog.action';

interface Props {
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    switchView: () => void;
    handleMouseLeaveOnInfoPopper?: () => void;
    openInfoPopper?: (event: React.MouseEvent<HTMLElement>, userType: EntityCaseRole) => void;
    canInviteFamilyAdmin: boolean;
    relationToDeceased: DCEntityEnum | null;
    showStep2?: boolean;
    fullName: string;
    sendInvitation: boolean;
    isNonUserSaving: boolean;
    dontSendInvite: boolean;
    invitedUser: EntitySummary | undefined;
    isPending: boolean;
    isLiving: boolean;
    isDeceased: boolean;
    handleDCSubmit: () => Promise<void>;
    handleSubmit: () => Promise<void>;
    handleDontInviteButtonClick: () => void;
    isSuccess: boolean;
    handleSendInvitation: (sendInvitation: boolean) => void;
    inviteMethod: InviteMethod;
    phone: string;
    email: string;
    activeCase: GatherCaseUX;
    context: HelperInvitationDialogContext | null;
}

const useStyles = makeGStyles(theme => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    button: {
        padding: '8px 20px',
        marginBottom: 8
    },
    btnLeftIcon: {
        marginRight: theme.spacing(),
    },
    buttonProgress: {
        color: green[500],
    },
    btnDisabled: {
        color: 'rgba(0,0,0,0.12)',
        background: 'rgba(0,0,0,0.12) !important',
        pointerEvents: 'none',
    },
    buttonSuccess: {
        background: green[500],
        pointerEvents: 'none',
    },
    footer: {
        position: 'relative',
        padding: '12px 0 8px',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 40,
        '@media (min-width: 760px)': {
            marginBottom: 0
        },
        '&$active': {
            minHeight: 102,
            justifyContent: 'unset'
        }
    },
    helperText: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        lineHeight: '1em',
    },
    GSwitchLabel: {
        fontSize: 16,
        color: theme.palette.secondary.main,
    },
    blueDotIcon: {
        position: 'absolute',
        left: '-10px',
        top: '-10px',
        color: SKYBLUE_COLOR_2
    },
    viewLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    switchText: {
        textDecoration: 'underline',
        fontSize: 12,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    textUnderHelperText: {
        fontSize: 12
    },
    dontInviteButton: {
        textTransform: 'capitalize',
        fontWeight: 400,
        marginTop: 8,
        '@media (min-width: 760px)': {
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
    active: {},
}), { name: 'InvitationFormFooter' });

const InvitationFormFooter = (props: Props) => {

    const {
        formType,
        switchView,
        handleMouseLeaveOnInfoPopper,
        openInfoPopper,
        canInviteFamilyAdmin,
        relationToDeceased,
        showStep2,
        fullName,
        sendInvitation,
        isNonUserSaving,
        dontSendInvite,
        invitedUser,
        isPending,
        isLiving,
        isDeceased,
        handleDCSubmit,
        handleSubmit,
        handleDontInviteButtonClick,
        isSuccess,
        handleSendInvitation,
        inviteMethod,
        phone,
        email,
        activeCase,
        context
    } = props;

    const classes = useStyles();

    const userData = useGSelector(({ userSession }) => userSession.userData);

    if (!userData) {
        return null;
    }

    const isFHorGOMUserOnFH = UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home_id);

    const { fname } = splitFullName(fullName);

    const isDisabled = dontSendInvite ? false
        : !invitedUser ? false : true;
    const userType = formType === 'INVITE_GUEST' ? EntityCaseRole.guest : EntityCaseRole.admin;
    const isObituaryAddPerson = context === HelperInvitationDialogContext.addPreceededInDeath
        || context === HelperInvitationDialogContext.addSurvivors;

    const getSendInviteButtonIcon = () => {
        return showStep2 &&
            <Send className={classes.btnLeftIcon} />
            || (relationToDeceased || isObituaryAddPerson)
            && <PersonAddIcon className={classes.btnLeftIcon} />
            || (dontSendInvite || isDeceased)
            && <FavoriteIcon className={classes.btnLeftIcon} />
            || <Send className={classes.btnLeftIcon} />;
    };

    const getSendInviteButtonText = () => {
        const buttonTextForDCEntity = context === HelperInvitationDialogContext.addPreceededInDeath
            ? 'Add'
            : context === HelperInvitationDialogContext.addSurvivors
                ? 'Add Survivor'
                : relationToDeceased
                    ? `Add ${relationToDeceased === DCEntityEnum.Informant
                    && `${relationToDeceased}`
                    || `${activeCase.fname}'s ${relationToDeceased}`}`
                    : null;
        return showStep2 && `SEND INVITE TO ${fname}`
            || buttonTextForDCEntity
            || dontSendInvite && fullName.length === 0 && 'Preserve guest\'s information'
            || isDeceased && fullName.length === 0 && 'Preserve deceased\'s information'
            || (dontSendInvite || isDeceased) && `Preserve ${fname}'s Information`
            || formType === 'INVITE_GUEST' && fullName.length === 0 && 'SEND INVITE TO GUEST'
            || fullName.length === 0 && 'SEND INVITE TO ADMIN'
            || `SEND INVITE TO ${fname}`;
    };

    const getHelperText = () => {
        if (isDeceased) {
            return null;
        }

        if (dontSendInvite) {
            return `An invitation will NOT be sent ${fullName.length === 0 ? '' : `to ${fname}`}`;
        }
        if (invitedUser) {
            return `${invitedUser.fname} has already been invited using this ` +
                `${inviteMethod === InviteMethod.both && 'email or mobile number' || inviteMethod}`;
        }

        let message = 'A mobile number OR email address is required';
        let toMessage = fname && fname !== '' ? ' to ' + fname : '';
        if (phone && email && inviteMethod === InviteMethod.both) {
            message = 'An email and a text invitation will be sent' + toMessage;
        } else if (phone && inviteMethod === InviteMethod.phone) {
            message = 'A text invitation will be sent' + toMessage;
        } else if (email && inviteMethod === InviteMethod.email) {
            message = 'An email invitation will be sent' + toMessage;
        } else if ((!phone || !email) && inviteMethod === InviteMethod.both) {
            message = 'A mobile number AND email address is required';
        }

        return message;
    };

    return (
        <Grid
            item
            xs={12}
            className={classNames(
                classes.footer,
                (relationToDeceased || isObituaryAddPerson) && classes.active
            )}
        >
            <Zoom
                in={isLiving && !relationToDeceased && !isObituaryAddPerson}
                timeout={600}
                unmountOnExit
                mountOnEnter
            >
                <Grid item xs={12}>
                    <GSwitch
                        label={fullName.length === 0 && 'Don\'t send invitation'
                            || `Don't send invitation to ${fname}`}
                        checked={!sendInvitation}
                        labelClasses={classes.GSwitchLabel}
                        onChangeCallBack={checked => handleSendInvitation(!checked)}
                    />
                </Grid>
            </Zoom>

            <Button
                type="submit"
                className={classNames(
                    classes.button,
                    (isPending || isNonUserSaving) ?
                        classes.btnDisabled : isSuccess ?
                            classes.buttonSuccess : ''
                )}
                onClick={() => ((relationToDeceased || isObituaryAddPerson) ? handleDCSubmit() : handleSubmit())}
                variant="contained"
                color="primary"
                size="large"
                disabled={isDisabled}
            >
                {formType === 'INVITE_ADMIN' && <FiberManualRecordIcon className={classes.blueDotIcon} />}
                <span>{getSendInviteButtonIcon()}</span>
                <span>{getSendInviteButtonText()}</span>
                {(isPending || isNonUserSaving) &&
                    <CircularProgress
                        variant="indeterminate"
                        size={24}
                        className={classes.buttonProgress}
                    />
                }
            </Button>

            {((!relationToDeceased && !isObituaryAddPerson) || showStep2) && <>
                <Zoom in={!isDeceased && !showStep2} timeout={600} appear={showStep2} mountOnEnter={showStep2}>
                    <Typography
                        className={classNames(
                            classes.helperText,
                            invitedUser !== undefined ? classes.colorRed : '',
                        )}
                    >
                        {getHelperText()}
                    </Typography>
                </Zoom>
                {isFHorGOMUserOnFH && !dontSendInvite && !isDeceased &&
                    <>
                        {formType === 'INVITE_ADMIN' &&
                            <Zoom in={formType === 'INVITE_ADMIN'} timeout={600}>
                                <Typography
                                    align="center"
                                    color="secondary"
                                    className={classes.textUnderHelperText}
                                >
                                    As an
                                    <span
                                        className={classes.viewLabel}
                                        onMouseLeave={e => handleMouseLeaveOnInfoPopper?.()}
                                        onMouseEnter={e => openInfoPopper?.(e, userType)}
                                        onMouseDown={e => openInfoPopper?.(e, userType)}
                                    >
                                        &nbsp;ADMIN,
                                    </span>
                                    &nbsp;{fname ? fname : 'Invitee'} can view ALL tasks
                                </Typography>
                            </Zoom>
                        }
                        {formType === 'INVITE_GUEST' &&
                            <Zoom in={formType === 'INVITE_GUEST'} timeout={600}>
                                <Typography
                                    align="center"
                                    color="secondary"
                                    className={classes.textUnderHelperText}
                                >
                                    As a
                                    <span
                                        className={classes.viewLabel}
                                        onMouseLeave={e => handleMouseLeaveOnInfoPopper?.()}
                                        onMouseEnter={e => openInfoPopper?.(e, userType)}
                                        onMouseDown={e => openInfoPopper?.(e, userType)}
                                    >
                                        &nbsp;GUEST,
                                    </span>
                                    &nbsp;{fname ? fname : 'Invitee'} can view ASSIGNED tasks
                                </Typography>
                            </Zoom>
                        }
                        {canInviteFamilyAdmin && <Zoom in timeout={600}>
                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.switchText}
                                onClick={switchView}
                            >
                                Switch to {formType === 'INVITE_GUEST' ? 'ADMIN' : 'GUEST'}
                            </Typography>
                        </Zoom>}
                    </>
                }
                {formType === 'INVITE_GUEST' && showStep2 && <Zoom in timeout={600}>
                    <Button
                        color="secondary"
                        className={classes.dontInviteButton}
                        onClick={e => handleDontInviteButtonClick()}
                    >
                        <HighlightOffIcon color="secondary" />&nbsp;
                        <span>Don't Invite</span>&nbsp;
                        <span>{fname}</span>
                    </Button>
                </Zoom>}
                {!canInviteFamilyAdmin && !showStep2 &&
                    <Zoom in={formType === 'INVITE_GUEST'} timeout={600}>
                        <Typography
                            align="center"
                            color="secondary"
                            className={classes.textUnderHelperText}
                        >
                            As a
                            <span
                                className={classes.viewLabel}
                                onMouseLeave={e => handleMouseLeaveOnInfoPopper?.()}
                                onMouseEnter={e => openInfoPopper?.(e, userType)}
                                onMouseDown={e => openInfoPopper?.(e, userType)}
                            >
                                &nbsp;GUEST,
                            </span>
                            &nbsp;{fname ? fname : 'Invitee'} can view ASSIGNED tasks
                        </Typography>
                    </Zoom>
                }
            </>}
        </Grid>
    );
};


export default InvitationFormFooter;