import { ButtonBase, Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UserAvatar from './UserAvatar';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import GTooltip from './GTooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';
import { getIntercomTargetProp } from '../../services';
import { useGSelector } from '../../types';
import { UserRoles } from '../../shared/types';
import { TOOLTIPS } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 320,
        maxWidth: 320,
        margin: '0px auto',
        width: '100%',
        borderTop: `3px solid ${theme.palette.primary.main}`,
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
    },
    profileIconsContainer: {
        position: 'absolute',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    avatarSize: {
        height: 48,
        width: 48,
    },
    localActivityIconContainer: {
        height: 48,
        width: 48,
        borderRadius: '50%',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: 'grid',
        placeItems: 'center',
        marginLeft: -8,
        position: 'relative',
        zIndex: 1
    },
    localActivityIconSize: {
        fontSize: '30px'
    },
    iconsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.primary.main,
    },
    textContainer: {
        paddingBlock: '10px',
        textAlign: 'center',
    }
}), { name: 'CreateIntercomTicketButton' });

interface Props {
    title: string;
    subTitle: string;
    intercomTarget: string;
}

const CreateIntercomTicketButton = ({ title, subTitle, intercomTarget }: Props) => {
    const classes = useStyles();
    const userData = useGSelector((s) => s.userSession.userData);
    const isFamilyView = userData?.is_family_view_on;
    const isFHorGOMUser = UserRoles.isFHorGOMUser(userData);

    if (isFamilyView || !isFHorGOMUser) {
        return null;
    }

    return (
        <ButtonBase
            className={classes.root}
            {...getIntercomTargetProp(intercomTarget)}
        >
            <Grid className={classes.profileIconsContainer}>
                <UserAvatar
                    size={60}
                    user={userData}
                    className={classes.avatarSize}
                />
                <Grid className={classes.localActivityIconContainer}>
                    <LocalActivityIcon className={classes.localActivityIconSize} />
                </Grid>
            </Grid>

            <Grid width={1} padding='5px'>
                <Grid className={classes.iconsContainer}>
                    <GTooltip
                        title={`This question is NOT visible to family members who log in. 
                        You can also hide it from your view by switching to Family View, which can be
                        done from anywhere in Gather by accessing the top-right mega menu.`}
                        enterDelay={200}
                    >
                        <VisibilityOffIcon />
                    </GTooltip>
                    <GTooltip
                        title={TOOLTIPS.CREATE_TICKET}
                        enterDelay={200}
                    >
                        <InfoIcon />
                    </GTooltip>
                </Grid>
                <Grid className={classes.textContainer}>
                    <Typography
                        fontSize={16}
                        color='primary'
                    >
                        {title}
                    </Typography>
                    <Typography
                        color='primary'
                        fontSize={16}
                        fontWeight={500}
                    >
                        {subTitle}
                    </Typography>
                </Grid>
            </Grid>
        </ButtonBase>
    );
};

export default CreateIntercomTicketButton;