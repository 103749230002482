import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

import ClearIcon from '@mui/icons-material/Clear';
import RouteIcon from '@mui/icons-material/Route';

import { CaseType, WorkflowSummary } from '../../../../shared/types';
import { SlideTransition } from '../../../common/Transitions';
import useFullScreen from '../../../common/hooks/useFullScreen';
import { CaseTypeButton, CaseTypeGroupButtons } from '../../../cases/type/widgets';
import WorkflowSelectorDialog from './WorkflowSelector.dialog';
import Grid from '@mui/material/Grid';
import UserAvatar from '../../../common/UserAvatar';
import GButton from '../../../common/GButton';
import Divider from '@mui/material/Divider';
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { GStyles } from '../../../../styles/GStyles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        popper: {
            '&[data-popper-placement*="bottom"] $arrow': {
                borderBottom: '16px solid #fff',
                borderTop: 0,
                top: '-16px',
            },
            '&[data-popper-placement*="top"] $arrow': {
                borderTop: '16px solid #fff',
                borderBottom: 0,
                bottom: '-16px',
            },
        },
        popoverPaper: {
            borderRadius: 4,
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            boxShadow: theme.shadows[10],
        },
        arrow: {
            width: 0,
            height: 0,
            position: 'absolute',
            border: '16px solid transparent',
            overflow: 'hidden',
        },
        groupButton: {
            minWidth: 90,
            '@media(min-width: 400px) ': {
                minWidth: 108,
            },
        },
        clearIcon: {
            position: 'absolute',
            top: 10,
            right: 10,
            fontSize: 34,
            zIndex: 2,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        description: {
            fontSize: '12px !important',
            lineHeight: 1.25,
        },
        liIcon: {
            margin: '0 8px',
        },
        dialogContent: {
            padding: '20px 12px !important',
            textAlign: 'center',
            borderRadius: 4,
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            '& p': {
                color: theme.palette.secondary.main,
            },
            '& $subHeading': {
                fontSize: 12,
                lineHeight: 1.25,
            },
        },
        dialogPaper: {
            maxWidth: 500,
        },
        marginTop24: {
            marginTop: 24,
        },
        avatarContainer: {
            '& $caseAvatar': {
                height: 90,
                width: 90,
                fontSize: 36,
                fontWeight: 300,
            },
            '& $caseTypeBtnContainer': {
                marginTop: -12,
                backgroundColor: '#fff',
                position: 'relative',
                zIndex: 1,
                '& button': {
                    minWidth: 88,
                    height: 22,
                    minHeight: 22,
                    maxHeight: 22,
                    borderRadius: 24,
                    '& span': { lineHeight: 1 },
                },
            },
        },
        adjustBtn: {
            boxShadow: 'none !important',
            borderRadius: 6,
        },
        caseTypeBtnContainer: {},
        caseAvatar: {},
        subHeading: {},
        updateLineContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: 'max-content',
            margin: 'auto',
        },
    }),
    { name: 'ChangeWorkflowAndCaseTypePureDialog' },
);

export interface WorkFlowSelectorFuneralHome {
    id: number;
    key: string;
}
export interface WorkFlowSelectorGatherCase {
    case_type: CaseType;
    fname: string;
    name: string;
    uuid: string;
    funeral_home_id: number;
    workflow_name: string | null;
    workflow_id: number | null;
}

export interface ChangeWorkflowAndCaseTypePureProps {
    isOpen: boolean;
    gatherCase: WorkFlowSelectorGatherCase;
    funeralHome: WorkFlowSelectorFuneralHome;
    isSaving: boolean;
    isLoading: boolean;
    workflows: WorkflowSummary[];
    onClose: () => void;
    onRenewCaseWorkflow: (caseUuid: string) => Promise<void>;
    onCaseTypeChange: (case_type: CaseType) => void;
    onWorkflowChange: (workflow: WorkflowSummary) => Promise<void>;
    zIndex: number;
}

const ChangeWorkflowAndCaseTypePureDialog = (props: ChangeWorkflowAndCaseTypePureProps) => {
    const {
        gatherCase,
        isOpen,
        funeralHome,
        zIndex,
        isSaving,
        isLoading,
        workflows,
        onClose,
        onRenewCaseWorkflow,
        onCaseTypeChange,
        onWorkflowChange,
    } = props;

    const classes = useStyles();
    const fullScreen = useFullScreen();

    const [caseType, setCaseType] = useState<CaseType>(gatherCase.case_type);
    const [workflowId, setWorkflowId] = useState<number | null>(gatherCase.workflow_id);
    const [workflowSelectorDialogOpen, setWorkflowSelectorDialogOpen] = useState<boolean>(false);
    const [renewingWorkflow, setRenewingWorkflow] = useState<boolean>(false);

    useEffect(() => {
        setCaseType(gatherCase.case_type);
    }, [gatherCase.case_type]);

    useEffect(() => {
        setWorkflowId(gatherCase.workflow_id);
    }, [gatherCase.workflow_id]);

    const saveCaseType = (activeCaseType: CaseType) => {
        if (activeCaseType !== caseType) {
            onCaseTypeChange(activeCaseType);
            setCaseType(activeCaseType);
            setWorkflowSelectorDialogOpen(true);
        }
    };

    const handleWorkflowSelect = async (workflow: WorkflowSummary) => {
        if (workflow.id !== workflowId) {
            setWorkflowId(workflow.id);
            await onWorkflowChange(workflow);
        }

        setWorkflowSelectorDialogOpen(false);
        onClose();
    };

    const renewWorkflow = async () => {
        setRenewingWorkflow(true);
        await onRenewCaseWorkflow(gatherCase.uuid);
        setRenewingWorkflow(false);
    };

    const type =
        (caseType === CaseType.pre_need && 'translucent') ||
        (caseType === CaseType.trade && 'dark') ||
        (caseType === CaseType.one_off && 'white') ||
        'default';

    const activeWorkflow = useMemo(() => workflows.find((wf) => wf.id === workflowId), [workflowId, workflows]);
    const isDifferentCaseType = Boolean(activeWorkflow && activeWorkflow.case_type !== caseType);

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={onClose}
                classes={{ paper: classes.dialogPaper }}
                style={{ zIndex }}
            >
                <DialogTitle className={GStyles.padding0}>
                    <ClearIcon color="secondary" className={classes.clearIcon} onClick={onClose} />
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                        wrap="nowrap"
                        direction="column"
                        alignItems="center"
                        className={classes.avatarContainer}
                    >
                        <UserAvatar user={gatherCase} size={90} className={classes.caseAvatar} />

                        <div className={classes.caseTypeBtnContainer}>
                            <CaseTypeButton
                                buttonStyle={type}
                                border={type === 'white'}
                                itemID={caseType}
                                active={false}
                                buttonSize="xSmall"
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                tabIndex={-1}
                            >
                                {caseType}
                            </CaseTypeButton>
                        </div>
                    </Grid>

                    <Typography className={classNames(GStyles.marginTop12, GStyles.fontSize16)}>
                        {gatherCase.fname}'s case is categorized as&nbsp;
                        <span className={GStyles.textUppercase}>{caseType}</span>
                    </Typography>

                    <Typography className={classes.subHeading}>Easily adjust between case types.</Typography>

                    <Typography className={classNames(classes.subHeading, GStyles.marginBottom8)}>
                        All data will be preserved.
                    </Typography>

                    <div>
                        <CaseTypeGroupButtons
                            rootClass={classes.groupButton}
                            active={caseType}
                            showOneOff={gatherCase.case_type === CaseType.one_off}
                            onClick={saveCaseType}
                        />
                    </div>

                    <Typography
                        component="div"
                        color="secondary"
                        className={classNames(
                            GStyles.fontSize16,
                            classes.marginTop24,
                            isDifferentCaseType && GStyles.colorRed,
                        )}
                    >
                        <RouteIcon fontSize="small" className={GStyles.verticalAlignMiddle} /> Current WorkFlow:&nbsp;
                        <span className={GStyles.textUppercase}>{gatherCase.workflow_name}</span>
                    </Typography>

                    <GButton
                        text={`Switch to a different workflow`}
                        size="large"
                        className={classNames(classes.adjustBtn, GStyles.marginTop5, GStyles.marginBottom8)}
                        onClick={() => setWorkflowSelectorDialogOpen(true)}
                    />
                    <Tooltip
                        title="To give you more control and predictability, new adjustments to the details of a Workflow
                         are designed to only apply when first switching to that WorkFlow. So if you make changes to a
                         WorkFlow after it has been applied to a case, you won't see those changes reflected on that
                         case until you click this button."
                        placement="top"
                        enterDelay={600}
                    >
                        <div className={classes.updateLineContainer}>
                            <UpdateIcon className={GStyles.colorOrange} fontSize="small" /> &nbsp;
                            <Typography
                                color="secondary"
                                variant="subtitle2"
                                className={classNames(GStyles.marginBottom24, GStyles.fontWeight400)}
                            >
                                Did you update this WorkFlow recently?&nbsp;
                                <span
                                    className={!renewingWorkflow ? GStyles.textClickable : undefined}
                                    onClick={!renewingWorkflow ? renewWorkflow : undefined}
                                >
                                    Click to see the new one.
                                </span>
                            </Typography>
                        </div>
                    </Tooltip>

                    <Divider className={GStyles.backgroundPrimary} />

                    <Typography className={classNames(classes.description, GStyles.marginTop8, GStyles.marginBottom10)}>
                        Each WorkFlow can be fully Customized to meet your needs
                    </Typography>

                    <Typography className={classes.description}>
                        When switching between WorkFlows, a smart merge will be done between the current and the new
                        WorkFlows. Any Tasks or Steps that are common between the two will be kept. Any Tasks or Steps
                        in the new that isn't in the old will be added. Any Tasks or Steps that are in the old but not
                        in the new will be removed UNLESS an action has been completed on that Task or Step, in which
                        case it will be kept in the new list. All Task and Step notes will be maintained as well as all
                        completion details.
                    </Typography>
                </DialogContent>
            </Dialog>

            <WorkflowSelectorDialog
                zIndex={zIndex + 1}
                isOpen={workflowSelectorDialogOpen}
                onClose={() => setWorkflowSelectorDialogOpen(false)}
                gatherCase={{
                    ...gatherCase,
                    case_type: caseType,
                    workflow_id: workflowId,
                }}
                isSaving={isSaving}
                funeralHome={funeralHome}
                workflows={workflows}
                isLoading={isLoading}
                onCaseTypeChange={saveCaseType}
                onSelect={handleWorkflowSelect}
            />
        </>
    );
};

export default ChangeWorkflowAndCaseTypePureDialog;
