import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';
import UndoIcon from '@mui/icons-material/Undo';

import { SnackBarVariant } from '../../../../../types';
import { GatherCaseUX } from '../../../../../shared/types';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountDeactivateStep } from '../utils';

interface Props {
    activeCase: GatherCaseUX;
    accountType: string;
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    onStepChange: (step: OnlineAccountDeactivateStep) => void;
}

const CanLogin = (props: Props) => {
    const { activeCase, accountType, onStepChange, setAppSnackbar } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Text copied to your clipboard', 'success');
    };

    const emailBody: string = 'I would like to permanently delete this ' +
        `${accountType} Account (${activeCase.fname} ${activeCase.lname}) and remove any pending charges.`;
    const tooltipText = `This will copy this text to your device clipboard so that you can paste 
            it in another program.`;

    return (
        <Grid>
            <Typography
                align="center"
                color="secondary"
                className={classNames(
                    classes.marginBottom10,
                    classes.marginTop20,
                    classes.fontSize16,
                    classes.fontWeight300
                )}
            >
                <span
                    className={classes.cursorPointer}
                    onClick={() => onStepChange(OnlineAccountDeactivateStep.LogInToAccountQueType)}
                >
                    <IconButton color="primary" size="large">
                        <UndoIcon />
                    </IconButton>
                    Return to previous question
                </span>
            </Typography>

            <Typography
                align="center"
                color="secondary"
                className={classNames(
                    classes.fontSize28,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Delete {activeCase.fname}'s {accountType} Account
            </Typography>

            <Typography
                color="secondary"
                align="left"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                Since you have the ability to log in to {activeCase.fname}'s {accountType} account,
                this process will be much faster. Simply follow the steps below to
                close {activeCase.fname}'s account.
            </Typography>

            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    <span className={classes.fontWeight500}>'Step 1:&nbsp;</span>
                    <a
                        href={'https://www.amazon.com/gp/help/customer/contact-us/?' +
                            'ie=UTF8&initialIssue=account-close_'}
                        target="blank"
                        className={classes.colorSecondary}
                    >
                        Click here
                    </a>
                    &nbsp;to contact customer support, making sure that you are logged in as {activeCase.fname}.
                </li>
                <li className={classes.marginTop10}>
                    <span className={classes.fontWeight500}>Step 2:&nbsp;</span>
                    Select the appropriate category
                </li>
                <ul
                    className={classNames(
                        classes.fontSize18,
                        classes.colorSecondary,
                        classes.textLeft,
                        classes.fontWeight300
                    )}
                >
                    <li className={classes.marginTop10}>
                        If you are on a mobile phone, select "Something Else" then "Other issues"
                    </li>
                    <li className={classes.marginTop10}>
                        If you are on a desktop, select "Prime or Something Else" then in the first
                        drop-down select "More non-order questions" then in the second drop-down
                        select "Other non-order questions"
                    </li>
                </ul>
                <li className={classes.marginTop10}>
                    <span className={classes.fontWeight500}>Step 3:&nbsp;</span>
                    You will then be able to start a chat or email
                </li>
                <li className={classes.marginTop10}>
                    <span className={classes.fontWeight500}>Step 4:&nbsp;</span>
                    Copy the following text into the chat or email field and an {accountType} Customer
                    Support Representative will walk you through the rest.
                </li>
            </ul>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography color="secondary" className={classNames(classes.fontSize18, classes.fontWeight300)}>
                    I would like to permanently delete this {accountType}
                    Account ({activeCase.fname} {activeCase.lname}) and remove any pending charges.
                </Typography>
            </Grid>

            <Grid>
                <CopyToClipboard text={emailBody}>
                    <Tooltip
                        title={tooltipText}
                        enterDelay={1200}
                        placement="top"
                        classes={{
                            tooltip: classes.fontSize12
                        }}
                        className={classes.button}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={copyEmailBody}
                        >
                            <FileCopy />&nbsp;Copy this text
                        </Button>
                    </Tooltip>
                </CopyToClipboard>
            </Grid>

            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Please consider the following...
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        After {activeCase.fname}'s account is closed, it is no longer accessible by
                        you or anyone else.
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            You won't have access to other websites
                            using {activeCase.fname}'s {accountType}.com log-in
                        </li>
                        <li className={classes.marginTop10}>
                            You won't have access to digital content related
                            to {accountType} Music, {accountType} Drive and/or Prime Photos,
                            or {activeCase.fname}'s {accountType} Appstore purchases
                        </li>
                        <li className={classes.marginTop10}>
                            You won't have access to {activeCase.fname}'s account history (including credit card
                            information, order history, etc.)
                        </li>
                        <li className={classes.marginTop10}>
                            Returns and refunds can't be processed for orders on closed accounts
                        </li>
                        <li className={classes.marginTop10}>
                            If {activeCase.fname} had a remaining {accountType}.com Gift Card or promotional credit
                            balance, you won't have access to use the funds
                        </li>
                        <li className={classes.marginTop10}>
                            Important: Once {activeCase.fname}'s account is closed, you'll no longer have access
                            to any Kindle, {accountType} Drive and/or Prime Photos content and subscriptions
                            via {activeCase.fname}'s account. Once an account is closed, {accountType} Drive
                            and/or Prime Photos content will be deleted and cannot be restored. Consequently,
                            we'd suggest that you download and save any content that you haven't already
                            downloaded or permanently saved before you have {activeCase.fname}'s account closed.
                        </li>
                    </ul>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default CanLogin;
