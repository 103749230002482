import * as classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import UndoIcon from '@mui/icons-material/Undo';

import SlideZoomListTransition from '../../../SlideZoomListTransition';
import { TaskBarListType } from './TaskOverviewBar';
import makeGStyles from '../../../../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px 0px 12px 12px',
        padding: '2px 16px',
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
        minHeight: 27,
        '@media (min-width: 768px)': {
            width: 'calc(100% - 40px)',
        },
        '& p': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.common.white,
            fontSize: 14,
            '@media (min-width: 400px)': {
                fontSize: 16
            }
        },
        '& $bulkEditText': {
            textTransform: 'uppercase',
            cursor: 'pointer',
            lineHeight: 1
        },
    },
    bulkEditText: {}
}));

interface Props {
    text?: JSX.Element;
    disableClick?: boolean;
    isBulkEditMode: boolean;
    toggleBulkEdit: () => void;
    listType: TaskBarListType;
    footerWidthClass?: string;
}

const TaskFooterBar = (props: Props) => {
    const { isBulkEditMode, toggleBulkEdit, listType, text, disableClick, footerWidthClass } = props;

    const classes = useStyles();

    return (
        <TransitionGroup>
            <SlideZoomListTransition>
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.root,
                        footerWidthClass,
                        isBulkEditMode ? classes.backgroundOrange : classes.backgroundPrimary
                    )}
                >
                    <Typography
                        className={isBulkEditMode ? classes.bulkEditText : undefined}
                        align="center"
                        onClick={!disableClick ? toggleBulkEdit : undefined}
                    >
                        {!isBulkEditMode
                            ? text
                            : <>
                                <UndoIcon />&nbsp;
                                CLICK TO FINISH EDITING {listType}
                            </>
                        }
                    </Typography>
                </Grid>
            </SlideZoomListTransition>
        </TransitionGroup>
    );
};

export default TaskFooterBar;
