import { createStore, applyMiddleware, Middleware } from 'redux';
import logger from 'redux-logger';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { GatherAction } from './actions';
import { getRootReducer } from './reducers/index';
import { isSentryEnabled, sentryReduxEnhancer } from './sentry';
import { StoreState } from './types';
import { composeWithDevTools } from 'redux-devtools-extension';

function configureStore() {
    const rootReducer = getRootReducer();
    const middleware: Middleware[] = [thunk];
    if ((process.env.NODE_ENV !== 'production' || process.env.REACT_APP_LOGLEVEL === 'DEBUG') &&
        !process.env.REACT_APP_HIDE_LOGGER) {
        middleware.push(logger);
    }

    const enhancers = [applyMiddleware(...middleware)];
    if (isSentryEnabled()) {
        enhancers.push(sentryReduxEnhancer);
    }
    const composedEnhancers = composeWithDevTools(...enhancers);
    // TODO: JJT stop using createStore
    return createStore(rootReducer, composedEnhancers);
}

export const store = configureStore();

export type AppDispatch = ThunkDispatch<StoreState, unknown, GatherAction>;
