import classNames from 'classnames';

import ImageListItem from '@mui/material/ImageListItem';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';

import PagesIcon from '@mui/icons-material/Pages';
import EditIcon from '@mui/icons-material/Edit';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PublicIcon from '@mui/icons-material/Public';

import HorizontalDivider from '../widgets/HorizontalDivider';
import ProductItemCardBase from '../widgets/ProductItemCardBase';
import Badge from '../widgets/Badge';
import PriceWidget from '../widgets/PriceWidget';
import {
    ProductPackageUX,
    GPLContext,
    FeatureKey,
} from '../../../../shared/types';
import { getPhotoUrl } from '../../../../services';
import {
    getPackageDisplayPrice,
    calculateListPriceRangeOfPackage,
    calculatePackagePercentDiscount,
    getPackagePrice,
} from '../../../../shared/goods_and_services/pricing';
import { getPackageItemName } from '../../../../shared/goods_and_services/packages';
import { WhenEnabled } from '../../../common/AppFeature';
import makeGStyles from '../../../../styles/makeGStyles';
import { Permission } from '../../../../shared/types/permissions';
import { usePermissionEnabled } from '../../../common/hooks/usePermissionEnabled';

interface Props {
    canUserEditContract: boolean;
    productPackage: ProductPackageUX;
    isShowPrices: boolean;
    isSelected: boolean;
    context: GPLContext;
    openPackageDialog: () => void;
    onAddPackage?: () => void;
    onRemovePackage?: () => void;
    onEditClick?: () => void;
}

const useStyles = makeGStyles(theme => ({
    root: {
    },
    heading: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 20,
        fontWeight: 200,
        lineHeight: '1.25em',
        paddingTop: 4,
        paddingBottom: 4
    },
    content: {
        fontWeight: 200,
        fontSize: 16,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        lineHeight: '1.25em',
        maxHeight: '3.75em',
    },
    productDescription: {
        padding: '16px 0 0'
    },
    itemUlContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        height: 180,
        boxShadow: 'inset 0px 0px 6px 0px rgba(0,0,0,0.2)',
        '& $itemUl': {
            margin: 0,
            padding: 0,
            width: '100%',
            maxHeight: 180,
            listStyleType: 'none',
            overflowY: 'auto',
            '& $li': {
                margin: 0,
                justifyContent: 'center',
                '& p': {
                    fontWeight: 200,
                    fontSize: 15,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    padding: '0 8px',
                },
                '& $special': {
                    fontWeight: 400,
                    fontStyle: 'italic'
                },
                '&:first-of-type': {
                    marginTop: 8,
                },
                '&:last-child': {
                    marginBottom: 8,
                },
            }
        },
    },
    pagesIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 4,
        filter: ` drop-shadow(0 0px 2px ${theme.palette.secondary.main}) `,
    },
    image: {
        height: 185,
        backgroundSize: 'cover !important',
        backgroundPosition: 'center center !important',
        backgroundRepeat: 'no-repeat !important',
    },
    saveBadge: {
        color: 'white',
        width: 'fit-content',
        margin: '-14px auto auto',
        border: '2px solid',
        borderRadius: 24,
        height: 28,
    },
    gridListTileLi: {
        margin: '0 auto !important',
        overflowY: 'unset !important' as 'unset',
    },
    itemUl: {},
    li: {},
    special: {},
    hidden: {
        border: 0
    },
    selectedText: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 2,
        left: 28,
        margin: 4,
        fontWeight: 500,
        filter: ` drop-shadow(0 0px 2px ${theme.palette.secondary.main}) `,
    }
}), { name: 'PackageCard' });

const transformations = [{
    crop: 'crop',
    aspect_ratio: (300 / 185).toFixed(2),
}, {
    width: 300,
    height: 185,
    quality: 'auto',
    fetch_format: 'auto',
    crop: 'limit',
}];

const PackageCard = (props: Props) => {
    const {
        productPackage,
        isSelected,
        isShowPrices,
        openPackageDialog,
        onAddPackage,
        onRemovePackage,
        canUserEditContract,
        context,
        onEditClick,
    } = props;

    const classes = useStyles();

    const isBackOffice = context === GPLContext.BackOffice;

    const showPrices = isShowPrices || Boolean(context === GPLContext.Public
        && productPackage.show_price_on_website
    );

    const hasPhoto = productPackage.photos.length > 0;
    const packagePrice = getPackagePrice(productPackage);
    const [, maxListPrice] = calculateListPriceRangeOfPackage(productPackage.items);
    // only show discount if is_discount_display === true and price is a discount from list price
    const showDiscount = productPackage.is_discount_displayed && packagePrice !== null
        && maxListPrice.greaterThan(packagePrice);
    const discountPercent = showDiscount && packagePrice ?
        calculatePackagePercentDiscount(packagePrice, maxListPrice)
        : null;

    const itemsCountStr = `${productPackage.items.length} Item${productPackage.items.length > 1 ? 's' : ''
        } Included in Package`;

    const isAddRemoveItemEnabled = usePermissionEnabled(isSelected ? Permission.REMOVE_ITEMS : Permission.ADD_ITEMS);

    const handleSelectBadgeClick = isBackOffice
        ? onEditClick
        : !isAddRemoveItemEnabled
            ? undefined
            : () => {
                if (isSelected && onRemovePackage) {
                    onRemovePackage();
                } else if (!isSelected && onAddPackage) {
                    onAddPackage();
                }
            };

    const renderSaveBadge = () => (
        <Badge
            icon={{
                root: <LoyaltyIcon />,
                position: 'precedent',
                size: 18
            }}
            color={isSelected ? 'inverted' : 'primary'}
            variant="extended"
            label={discountPercent ? `Save ${discountPercent}%` : undefined}
            size="small"
        />
    );

    const renderPublicBadge = () => {
        if (!canUserEditContract) {
            return <></>;
        }

        if (isBackOffice && productPackage && productPackage.show_on_website) {
            return (
                <WhenEnabled feature={FeatureKey.PUBLIC_GPL}>
                    <Badge
                        icon={{
                            root: <PublicIcon />,
                            position: 'precedent',
                            size: 36,
                        }}
                        color={productPackage.show_price_on_website ? 'green' : 'orangeInverted'}
                        variant="round"
                    />
                </WhenEnabled>
            );
        }

        return <></>;
    };

    const renderSelectBadge = () => {
        if (!canUserEditContract) {
            return <></>;
        }

        if (isBackOffice) {
            return (
                <Badge
                    color="orange"
                    variant="extended"
                    label="Edit"
                    icon={{ root: <EditIcon />, size: 12 }}
                />
            );
        }

        return (
            <Badge
                color={isSelected ? 'inverted' : 'primary'}
                variant={isSelected ? 'round' : 'extended'}
                label={isSelected ? undefined : 'Select'}
                icon={{ root: isSelected ? <RemoveIcon /> : <AddIcon /> }}
            />
        );
    };

    const renderItems = () => {
        return (
            <div className={classNames(classes.itemUlContainer, classes.background_primary_0_0_12)}>
                <ul className={classes.itemUl}>
                    {productPackage.items.map((item) =>
                        <li className={classes.li} key={item.id}>
                            <Typography
                                color={'secondary'}
                                className={item.options.length > 1 && classes.special || undefined}
                            >
                                {getPackageItemName(item)}
                            </Typography>
                        </li>
                    )}
                </ul>
            </div>
        );
    };

    return (
        <Zoom in timeout={1200}>
            <ImageListItem classes={{ root: classes.gridListTileLi }}>
                <ProductItemCardBase
                    color={isSelected ? 'primary' : isBackOffice ? 'dashed' : 'inverted'}
                    size="auto"
                    badges={[{
                        position: 'topEnd',
                        badge: renderSelectBadge(),
                        onClick: handleSelectBadgeClick
                    }, {
                        position: 'top',
                        size: 'small',
                        hidden: !showDiscount || hasPhoto,
                        badge: renderSaveBadge()
                    }, {
                        position: 'bottomStart',
                        badge: renderPublicBadge(),
                        tooltipTitle: `An orange icon indicates that this package is synced to your website. 
                            Green indicates that it is synced AND that the price is visible on your website.`,
                        onClick: onEditClick
                    }]}
                    onClick={() => openPackageDialog()}
                    productItems={renderItems()}
                >
                    <PagesIcon className={classes.pagesIcon} />
                    {isSelected &&
                        <Typography className={classes.selectedText}>
                            Selected
                        </Typography>
                    }

                    {hasPhoto &&
                        <>
                            <img
                                className={classes.image}
                                src={getPhotoUrl(productPackage.photos[0], transformations)}
                            />
                            <div
                                className={classNames(
                                    classes.saveBadge,
                                    isSelected ? classes.colorPrimary : '',
                                    !showDiscount && classes.hidden
                                )}
                            >
                                {showDiscount && renderSaveBadge()}
                            </div>
                        </>
                    }
                    <div
                        className={classNames(
                            classes.productDescription,
                            hasPhoto && classes.padding0
                        )}
                    >
                        <Typography
                            color="inherit"
                            className={classNames(
                                classes.heading,
                                showPrices ? '' : classes.marginBottom10
                            )}
                        >
                            {productPackage.name}
                        </Typography>

                        {showPrices &&
                            <PriceWidget
                                priceStr={getPackageDisplayPrice(productPackage) || ''}
                                size={'medium'}
                            />
                        }

                        <div className={classes.marginBottom16}>
                            <Badge
                                icon={{
                                    root: <PagesIcon />,
                                    position: 'precedent',
                                }}
                                color={isSelected ? 'primary' : 'inverted'}
                                variant="extended"
                                label={itemsCountStr}
                                size="small"
                                fontSize={12}
                                fontWeight={200}
                            />
                        </div>
                        <HorizontalDivider color="primary" inset="none" />
                    </div>
                </ProductItemCardBase>
            </ImageListItem>
        </Zoom>
    );
};

export default PackageCard;
