import AccessToEmailQue from '../widgets/AccessToEmailQue';
import CanLogin from './CanLogin';
import CanNotLogin from './CanNotLogin';
import LogInToAccountQue from '../widgets/LogInToAccountQue';
import { OnlineAccountStepLookup } from '../utils';
import ResetPasswordSteps from './ResetPasswordSteps';

export const APPLE_STEPS_LOOKUP: OnlineAccountStepLookup = {
    LogInToAccountQue,
    AccessToEmailQue,
    ResetPasswordSteps,
    CanLogin,
    CanNotLogin
};