import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { BelongingsConfig } from '../../../../../../shared/types';
import { OnValidityChangedFunction } from './types';
import { useCompleteStepSwitchStyles } from '../dialogContent.styles';
import { useGSelector } from '../../../../../../types';
import StepComponentWrapper from '../StepComponentWrapper';
import CaseBelongingList from '../CaseBelongingList';
import GSwitch from '../../../../../common/inputElements/GSwitch';
import { TrackingContentContext } from '../CompleteStep.dialog';

interface Props {
    isStepCompleted: boolean;
    config: BelongingsConfig | null;
    componentId: number;
    zIndex: number;
    caseFname: string;
    caseUuid: string | null; // null case is for Back Office preview
    userFullName: string;
    saveAttempted: boolean;
    onValidityChanged: OnValidityChangedFunction;
}

const CaseBelongingsComponent = (props: Props) => {
    const {
        componentId,
        caseFname,
        userFullName,
        zIndex,
        saveAttempted,
        config,
        caseUuid,
        isStepCompleted,
        onValidityChanged,
    } = props;

    const isRequired = Boolean(config?.is_required);

    const classes = useCompleteStepSwitchStyles();

    // if step completed assume required things were completed
    const [isChecked, setIsChecked] = useState<boolean>(Boolean(isRequired && isStepCompleted));
    const belongingsCount = useGSelector(({ casesState }) => casesState.belongings?.length || 0);

    const isValid = !isRequired || isChecked;

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [isValid, componentId, onValidityChanged]);

    const hasError = saveAttempted && !isValid;

    return (
        <StepComponentWrapper
            error={hasError}
            success={isChecked}
            extendedWidth
            heading={`Belongings (${belongingsCount})${isRequired ? ' *' : ''}`}
        >
            <CaseBelongingList
                disableEdit={isStepCompleted}
                caseFname={caseFname}
                caseUuid={caseUuid}
                zIndex={zIndex + 1}
                userFullName={userFullName}
                context={TrackingContentContext.dialog}
            />

            {isRequired &&
                <div className={classNames(
                    isChecked && classes.backgroundGreenColor,
                    hasError && classes.backgroundRed,
                    (!isChecked && !hasError) && classes.backgroundPrimary,
                    classes.switchRadius
                )}
                >
                    <GSwitch
                        required={true}
                        disabled={isStepCompleted}
                        label={`I captured all known belongings${isRequired ? ' *' : ''}`}
                        checked={isChecked}
                        onChangeCallBack={setIsChecked}
                        formControlLabelClasses={classes.formControlLabel}
                        labelClasses={classNames(
                            classes.marginLeft_4,
                            classes.whiteColor
                        )}
                        switchBaseClasses={classes.whiteColor}
                        switchCheckedClass={classes.whiteColor}
                        barClass={classes.secondaryBackground}
                    />
                </div>
            }
        </StepComponentWrapper>
    );
};

export default CaseBelongingsComponent;
