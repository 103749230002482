import * as React from 'react';
import isEqual from 'lodash/isEqual';

import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';

import withTheme, { WithTheme } from '@mui/styles/withTheme';
import { StyleRulesCallback } from '@mui/styles/withStyles';

import ConfirmationDialog from '../../../common/ConfirmationDialog';
import { APP_PRIMARY_COLOR } from '../../../../constants/colorVariables';
import NewLabelDialogContent from './NewLabel.dialogContent';

import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';
import {
    CaseLabelUX,
    isCaseLabelUX,
} from '../../../../shared/types';
import { compose } from 'redux';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, NewLabelProps> = theme => ({
    root: {},
    dialogPaper: {
        [theme.breakpoints.up('sm')]: {
            width: 425
        }
    },
    dialogTitle: {
        padding: '16px 12px',
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: 18,
            [theme.breakpoints.up('sm')]: {
                fontSize: 24
            }
        }
    },
    closeIcon: {
        fontSize: 32,
        cursor: 'pointer',
        marginLeft: 8,
    },
    dialogContent: {
        paddingBottom: 40
    }
});
type Classes = 'root' | 'dialogTitle' | 'closeIcon' | 'dialogPaper' | 'dialogContent';
type StyledProps = WithGStyles<Classes>;

interface DialogProps {
    fullScreen: boolean;
}

export interface NewLabelProps {
    zIndex: number;
    isOpen: boolean;
    existingLabel: Partial<CaseLabelUX> | null;
    funeralHomeId: number;
    onClose: () => void;
    doesLabelAlreadyExist: (labelName: string) => boolean;
    onCreateLabelOnFH: (params: { funeralHomeId: number; label: string; color: string }) => void;
    onDeleteLabelFromFH: (label: CaseLabelUX) => void;
    onUpdateLabelOnFH: (params: { labelId: number; funeralHomeId: number; name: string; color: string }) => void;
}
type CombinedProps = NewLabelProps & StyledProps & DialogProps & WithTheme<Theme>;

interface State {
    label: string;
    color: string;
    saveAttempted: boolean;
    isConfirmationDialogOpen: boolean;
    islabelInvalid: boolean;
}

const INIT_STATE: State = {
    label: '',
    color: APP_PRIMARY_COLOR,
    saveAttempted: false,
    isConfirmationDialogOpen: false,
    islabelInvalid: false
};

export const LABEL_MAX_CHARS = 26;

class NewLabelDialog extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            ...INIT_STATE,
            color: props.theme ? props.theme.palette.primary.main : INIT_STATE.color
        };
    }

    componentDidUpdate(prevProps: CombinedProps) {
        const { existingLabel, theme } = this.props;

        if (existingLabel && !isEqual(existingLabel, prevProps.existingLabel)) {
            const label = existingLabel.name
                ? existingLabel.name.slice(0, LABEL_MAX_CHARS)
                : '';

            this.setState({
                color: existingLabel.color || (theme && theme.palette.primary.main) || INIT_STATE.color,
                label
            });
        }
    }

    renderConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isConfirmationDialogOpen } = this.state;

        return (
            <ConfirmationDialog
                zIndex={zIndex + 1}
                open={isConfirmationDialogOpen}
                onClose={this.closeConfirmationDialog}
                onConfirm={this.deleteLabel}
                header="Are you sure?"
                subHeader={
                    'Once deleted, this label will no longer be available to apply to new cases. ' +
                    'Any existing cases currently using this label will continue to have the label applied so ' +
                    'that an accurate historical record is maintained. If you want to remove it from all previous ' +
                    'cases, you will need to remove it individually from each case.'
                }
                confirmationButtonText="DELETE FOREVER"
            />
        );
    };

    render() {
        const { isOpen, fullScreen, zIndex, classes, existingLabel } = this.props;
        const { label, color, saveAttempted, islabelInvalid } = this.state;

        const isInvalidLabel = (saveAttempted && !label.trim()) || islabelInvalid;
        const isEditMode = !!(existingLabel && existingLabel.id);

        return (
            <>
                <Dialog
                    open={isOpen}
                    onClose={this.closeDialog}
                    fullScreen={fullScreen}
                    style={{ zIndex }}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    classes={{ paper: classes.dialogPaper }}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        <Typography color="inherit">
                            {isEditMode ? 'Edit' : 'Create New'} Label
                        </Typography>

                        <CloseIcon color="inherit" className={classes.closeIcon} onClick={this.closeDialog} />
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        <NewLabelDialogContent
                            label={label}
                            labelColor={color}
                            isEditMode={isEditMode}
                            isInvalidLabel={isInvalidLabel}
                            handleColorChange={this.handleColorChange}
                            handleLabelChange={this.handleLabelChange}
                            openDeleteConfirmationDialog={this.openConfirmationDialog}
                            onSaveClick={this.handleSave}
                        />
                    </DialogContent>
                </Dialog>

                {isEditMode && this.renderConfirmationDialog()}
            </>
        );
    }

    deleteLabel = () => {
        const { existingLabel, onDeleteLabelFromFH } = this.props;

        if (!existingLabel?.id || !isCaseLabelUX(existingLabel)) {
            return;
        }

        onDeleteLabelFromFH(existingLabel);

        this.closeDialog();
    };

    openConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: true });
    };

    closeConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: false });
    };

    closeDialog = () => {
        const { theme } = this.props;

        this.setState({
            ...INIT_STATE,
            color: theme ? theme.palette.primary.main : INIT_STATE.color
        });
        this.props.onClose();
    };

    validateForm = () => {
        const { label, color } = this.state;

        return !!label.trim() && !!color.trim();
    };

    handleSave = () => {
        const {
            existingLabel,
            funeralHomeId,
            doesLabelAlreadyExist,
            onUpdateLabelOnFH,
            onCreateLabelOnFH,
        } = this.props;
        const { color, label } = this.state;

        if (!this.validateForm()) {
            this.setState({ saveAttempted: true });
            return;
        }

        const isEditMode = !!(existingLabel && existingLabel.id);
        if (isEditMode && isCaseLabelUX(existingLabel)) {
            onUpdateLabelOnFH({
                labelId: existingLabel.id,
                funeralHomeId: existingLabel.funeralHomeId,
                color,
                name: label,
            });
        } else if (!doesLabelAlreadyExist(label)) {
            onCreateLabelOnFH({ funeralHomeId, label, color });
        }

        this.closeDialog();
    };

    handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { doesLabelAlreadyExist } = this.props;
        const labelValue = event.target.value;

        const inputInErrorState = labelValue.length > LABEL_MAX_CHARS || doesLabelAlreadyExist(labelValue);

        if (inputInErrorState) {
            this.setState({ islabelInvalid: true });
        } else if (this.state.islabelInvalid && !inputInErrorState) {
            this.setState({ islabelInvalid: false });
        }

        this.setState({ label: event.target.value });
    };

    handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ color: event.target.value });
    };
}

export default compose(
    withFullScreen(),
    withTheme,
    withGStyles(styles),
)(NewLabelDialog) as React.ComponentType<NewLabelProps>;
