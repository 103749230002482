import * as React from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { bind, unbind } from 'mousetrap';

import { WithStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Clear from '@mui/icons-material/Clear';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

import { StoreState } from '../../../types';
import {
    RolodexSearchResultDataTypes,
    isRolodexCaseDetails,
    isRolodexEntry,
    OrganizationTypes,
    OrganizationDisplayTypes,
    RolodexCaseDetails,
    RolodexEntry,
    UserRoles,
} from '../../../shared/types';
import styles from './Rolodex.dialog.styles';
import {
    getDisplayModel,
    searchRolodex,
    setActiveRolodexEntry,
    setRolodexCreateEditDialogOpen,
    setRolodexListDialogOpen,
    loadedRolodexSearchResults,
    rolodexSearchIsLoading
} from '../../../actions/Rolodex.action';
import RolodexEntryList from './RolodexEntryList';
import CaseDetails from './CaseDetails';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { AppDispatch } from '../../../store';
import withGStyles from '../../../styles/WithGStyles';
import withState from '../../common/utilHOC/WithState';
import { compose } from 'redux';
import { FadeTransition } from '../../common/Transitions';
import withFullScreen from '../../common/utilHOC/WithFullScreen';
import EntryDetails from './EntryDetails';

function mapStateToProps({ userSession, funeralHomeState, rolodexState }: StoreState) {
    return {
        userSession,
        activeFuneralHomeId: funeralHomeState.activeFuneralHome === null ? null : funeralHomeState.activeFuneralHome.id,
        rolodexState,
    };
}

type StyledProps = WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon'
    | 'headerContent' | 'headerText' | 'inner' | 'textCenter' | 'searchField' | 'tabAppbar' | 'tabsFlexContainer'
    | 'tabsScrollableContainer' | 'colorPrimary' | 'scrollButtons' | 'tabRoot' | 'labelContainer' | 'divider'
    | 'scrollButtonsAuto' | 'tabContentContainer' | 'tabContainer' | 'textFieldContainer' | 'svgContainer'
    | 'tabIndicator' | 'footerList' | 'noResultContainer' | 'clickHereText' | 'noResultsDivider' | 'textFieldRoot'
    | 'filterTabAppbar' | 'filterTabsFlexContainer' | 'filterTabsScrollableContainer' | 'filterTabIndicator'
    | 'filterTabsRoot' | 'height60' | 'filterScrollButtons' | 'firstItem' | 'heading' | 'subHeading' | 'profession'
    | 'location' | 'assignments' | 'tabContainerDivider' | 'addNewEntryButton' | 'buttonText' | 'headerIconButton'
    | 'pointerEventsNone' | 'stepTwoContainer' | 'topSection' | 'officeName' | 'oganizationName' | 'optionsButton'
    | 'contactAndNotesSection' | 'leftSection' | 'rightSection' | 'contactButtonSection' | 'contactButton'
    | 'buttonLabel' | 'contactText' | 'organizationContactDetail' | 'contactLabel' | 'orgnizationAddress'
    | 'marginTop8' | 'organizationContactDetailFooter' | 'internalNotesField' | 'uploadButton' | 'docListContainer'
    | 'docLabelContainer' | 'docName' | 'docUploadedBy' | 'docUploadedTime' | 'fileMoreIcon' | 'mainDivider'
    | 'officeStaffContainer' | 'cardSection' | 'staffName' | 'staffProfession' | 'moreVertIcon' | 'assignmentIcon'
    | 'contactDetailsSection' | 'contactDetailFooter' | 'dummyDivAssignmentIcon' | 'marginBottom10' | 'marginTop28'
    | 'menuPopper' | 'arrow' | 'menuPaper' | 'menuItem' | 'deleteIcon' | 'docMenuPopper' | 'caseDetailContainer'
    | 'avatar' | 'nameInitialsAvatar' | 'caseName' | 'caseCreatedBy' | 'caseDateofDeath' | 'viewCaseSection'
    | 'helperCardSection' | 'caseRelation' | 'avatarContainer' | 'caseHelperName' | 'tabContainerDivider'
    | 'contactType' | 'doctorName' | 'phoneNumber' | 'emailText' | 'emailTitle' | 'rootButton' | 'addressText'
    | 'header' | 'footerContent' | 'footerContainer' | 'shortcutButton' | 'headerContactButtons' | 'docButton'
    | 'uploadButtonLabel' | 'docSectionContainer' | 'marginBottom8' | 'avatarIcon' | 'createRolodexEntryButton'
    | 'createRolodexEntryButton' | 'buttonProgress' | 'buttonDisabled' | 'headerDivider' | 'headerTextContainer'
    | 'textDecorationNone' | 'justifyContentCenter' | 'lastChild' | 'backdrop'>;

interface DialogProps {
    fullScreen: boolean;
}

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface State {
    searchText: string | null;
    activeDetails: RolodexCaseDetails | RolodexEntry | null;
    searchResultFilterKey: OrganizationTypes | null;
    keyBindingsSet: boolean;
}

type CombinedProps = Props & DialogProps & StyledProps;

class RolodexDialog extends React.Component<CombinedProps, State> {
    private _debounceSearchHandler = debounce(
        (funeralHomeId: number, newSearchValue: string) => {
            const { dispatch } = this.props;

            if (newSearchValue.length) {
                dispatch(searchRolodex(funeralHomeId, newSearchValue));
            } else {
                dispatch(loadedRolodexSearchResults(null, []));
            }
        },
        500
    );

    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            searchText: null,
            activeDetails: null,
            searchResultFilterKey: null,
            keyBindingsSet: false
        };
    }

    componentDidUpdate(prevProps: CombinedProps, _: State) {
        const { activeEntry: prevActiveEntry, inputValue: prevInputValue } = prevProps.rolodexState;
        const { activeEntry, inputValue } = this.props.rolodexState;
        const { activeDetails, keyBindingsSet } = this.state;
        const { dispatch } = this.props;

        if (prevInputValue !== inputValue && inputValue) {
            this._searchHandler(inputValue);
        }

        if (prevActiveEntry !== null && activeEntry === null
            && (activeDetails && isRolodexEntry(activeDetails))) {
            this.setState({ activeDetails: null });
            const { searchText } = this.state;
            if (searchText !== null) {
                this._searchHandler(searchText);
            }
        }

        if (prevActiveEntry === null && activeEntry !== null) {
            this.setState({ activeDetails: activeEntry });
        }

        if (!keyBindingsSet) {
            bind(
                ['command+shift+o', 'ctrl+shift+o'],
                () => dispatch(setRolodexListDialogOpen(true))
            );

            this.setState({ keyBindingsSet: true });
        }
    }

    componentWillUnmount() {
        const { keyBindingsSet } = this.state;

        if (keyBindingsSet) {
            unbind(['command+shift+o', 'ctrl+shift+o']);
        }

        this._debounceSearchHandler.flush();
    }

    render() {
        const { classes, fullScreen, rolodexState, userSession } = this.props;
        const {
            listDialogOpen,
            zIndex,
            searchResults,
            searchResultOrgCategories,
            activeEntry
        } = rolodexState;
        const { searchText, searchResultFilterKey, activeDetails } = this.state;

        const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;

        const isNotFamilyUser = userSession.userData !== null && UserRoles.isFHorGOMUser(userSession.userData);
        if (!isNotFamilyUser) {
            return null;
        }

        return (
            <Dialog
                fullScreen={fullScreen}
                open={listDialogOpen}
                TransitionComponent={FadeTransition}
                transitionDuration={400}
                onClose={this._closeDialog}
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                BackdropProps={{ className: classes.backdrop }}
                style={{ zIndex: zIndex + 1 }}
                scroll="body"
            >
                {activeDetails === null &&
                    <DialogTitle className={classes.header}>
                        <Clear
                            color="primary"
                            className={classes.clearIcon}
                            onClick={() => this._closeDialog()}
                        />
                    </DialogTitle>}
                {listDialogOpen && <DialogContent className={classes.dialogContent}>
                    <Grid container flexDirection="column" flexWrap="nowrap" className={classes.inner}>
                        {(activeDetails === null &&
                            <>
                                <div>
                                    <Grid container className={classes.textCenter}>
                                        <Grid item xs={12} className={classes.textFieldContainer}>
                                            <Grid item xs={12} className={classes.svgContainer}>
                                                <SavedSearchIcon />
                                            </Grid>
                                            <Grid item xs={12} className={classes.height60}>
                                                <TextField
                                                    id="input-with-icon-grid"
                                                    label={`Search all cases, informants, 
                                                        helpers, and rolodex entries...`}
                                                    className={classes.searchField}
                                                    fullWidth
                                                    value={searchText || ''}
                                                    name="searchText"
                                                    autoComplete="off"
                                                    autoFocus
                                                    onChange={(e) => this._searchHandler(e.target.value)}
                                                    classes={{
                                                        root: classes.textFieldRoot
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {(searchText !== null && !!searchText.length) &&
                                        <>
                                            {searchResultOrgCategories.length !== 0 &&
                                                <AppBar position="static" className={classes.tabAppbar}>
                                                    <Tabs
                                                        value={searchResultFilterKey === null ?
                                                            false : searchResultFilterKey}
                                                        onChange={this._handleFilterChange}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        classes={{
                                                            flexContainer: classes.tabsFlexContainer,
                                                            scrollableX: classes.filterTabsScrollableContainer,
                                                            scrollButtons: classNames(
                                                                classes.colorPrimary,
                                                                classes.scrollButtons,
                                                                // TODO(Jonathan): check that this styles the element
                                                                // correctly
                                                                classes.scrollButtonsAuto
                                                            ),
                                                            // scrollButtonsAuto: classes.scrollButtonsAuto,
                                                            indicator: classes.tabIndicator
                                                        }}
                                                    >
                                                        {!rolodexState.searchIsLoading &&
                                                            searchResultOrgCategories.map(({ key, count }) => {
                                                                const label = `${OrganizationDisplayTypes[key]} ` +
                                                                    `(${count})`;
                                                                return (
                                                                    <Tab
                                                                        label={label}
                                                                        classes={{
                                                                            root: classNames(
                                                                                classes.tabRoot, classes.labelContainer
                                                                            )
                                                                        }}
                                                                        value={key}
                                                                        key={key}
                                                                    />
                                                                );
                                                            })}
                                                    </Tabs>
                                                </AppBar>}
                                            <RolodexEntryList
                                                classes={classes}
                                                listContents={searchResults}
                                                rowClickHandler={this._handleRowSelect}
                                                openCreateDialog={this._openCreateEditDialog}
                                                categoryFilter={searchResultFilterKey}
                                                isLoading={rolodexState.searchIsLoading}
                                            />
                                        </>}
                                    {(!searchText ||
                                        (searchText !== null && searchResults && searchResults.length !== 0)) &&
                                        <Button
                                            color="primary"
                                            className={classes.createRolodexEntryButton}
                                            onClick={this._openCreateEditDialog}
                                        >
                                            <AddCircleIcon color="primary" />&nbsp;Create New Rolodex Entry
                                        </Button>}
                                </div>
                                <Grid item xs={12} className={classes.footerContent}>
                                    {!searchText && <Typography align="center">
                                        Open this screen from anywhere by clicking
                                        {isMacLike ? ' COMMAND' : ' CONTROL'} + SHIFT + O
                                    </Typography>}
                                </Grid>
                            </>) || (activeDetails !== null &&
                                <>
                                    <Clear
                                        className={classes.clearIcon}
                                        onClick={this._closeDialog}
                                        color="primary"
                                    />
                                    <Grid item xs={12} className={classes.headerContent}>
                                        <div className={classes.headerTextContainer}>
                                            <IconButton
                                                onClick={this._backToSearchHandler}
                                                className={classes.headerIconButton}
                                                size="large">
                                                <ArrowBackIcon />
                                            </IconButton>&nbsp;
                                            <Typography className={classes.headerText}>Back to Search</Typography>
                                        </div>
                                        <Divider className={classes.headerDivider} />
                                    </Grid>
                                    {isRolodexEntry(activeDetails) && activeEntry !== null
                                        ? this._createDetailsEl(activeEntry)
                                        : this._createDetailsEl(activeDetails)}
                                </>)}
                    </Grid>
                </DialogContent>}
            </Dialog>
        );
    }

    private _backToSearchHandler = () => {
        const { dispatch } = this.props;

        this.setState({ activeDetails: null });
        dispatch(setActiveRolodexEntry(null));
    };

    private _closeDialog = () => {
        const { dispatch } = this.props;

        this.setState({ searchText: null, activeDetails: null });

        dispatch(loadedRolodexSearchResults(null, []));
        dispatch(setActiveRolodexEntry(null));
        dispatch(setRolodexListDialogOpen(false));
    };

    private _openCreateEditDialog = () => {
        const { dispatch } = this.props;
        dispatch(setRolodexCreateEditDialogOpen(true));
    };

    private _handleRowSelect = async (item: RolodexSearchResultDataTypes) => {
        const { activeFuneralHomeId, dispatch } = this.props;

        if (activeFuneralHomeId === null) {
            return;
        }

        const rowData = await getDisplayModel(dispatch, item, activeFuneralHomeId);
        if (rowData === null) {
            return;
        }

        this.setState({ activeDetails: rowData });
        if (isRolodexEntry(rowData)) {
            dispatch(setActiveRolodexEntry(rowData));
        }
    };

    private _createDetailsEl = (data: RolodexCaseDetails | RolodexEntry) => {
        const { classes, dispatch, rolodexState, userSession } = this.props;
        const { zIndex } = rolodexState;

        if (isRolodexCaseDetails(data)) {
            return <CaseDetails
                classes={classes}
                selectedCase={data}
                resetRolodexUI={this._resetRolodexUI}
            />;
        }

        if (isRolodexEntry(data) && userSession.userData !== null) {
            return <EntryDetails
                dispatch={dispatch}
                zIndex={zIndex}
                classes={classes}
                entry={data}
                userData={userSession.userData} />;
        }

        return <></>;
    };

    private _searchHandler = async (text: string) => {
        const { activeFuneralHomeId, rolodexState, dispatch } = this.props;

        if (activeFuneralHomeId) {
            this.setState({ searchText: text });
            if (text.trim().length) {
                if (!rolodexState.searchIsLoading) {
                    dispatch(rolodexSearchIsLoading(true));
                }
                this._debounceSearchHandler(activeFuneralHomeId, text.trim());
            }
        }
    };

    private _handleFilterChange = (_: React.ChangeEvent<{}>, category: OrganizationTypes) => {
        const { searchResultFilterKey } = this.state;

        if (searchResultFilterKey === category) {
            this.setState({ searchResultFilterKey: null });
        } else {
            this.setState({ searchResultFilterKey: category });
        }
    };

    private _resetRolodexUI = async () => {
        const { dispatch } = this.props;

        await dispatch(setRolodexListDialogOpen(false));
        this.setState({ activeDetails: null, searchText: '' });
    };
}

export default compose(
    withFullScreen(),
    withState(mapStateToProps),
    withGStyles(styles<StyledProps>())
)(RolodexDialog) as React.ComponentType<{}>;
