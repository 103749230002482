
import { PaymentRequest } from '../../../shared/types';
import { useGSelector } from '../../../types';
import { Payer } from '../../family/goodsAndServices/payment';
import BaseOnlinePaymentDialog from
    '../../family/goodsAndServices/payment/baseOnlinePaymentDialog/BaseOnlinePayment.dialog';

interface Props {
    isDialogOpen: boolean;
    paymentInfo: PaymentRequest;
    payer: Payer;
    closeDialog: (activeReceipt: number | null) => void;
    zIndex: number;
}

const OnboardingPaymentDialog = (props: Props) => {
    const {
        isDialogOpen,
        paymentInfo,
        payer,
        closeDialog,
        zIndex,
    } = props;

    const onlineChargeState = useGSelector(({ financeState }) => financeState.onlineChargeState);

    return (
        <BaseOnlinePaymentDialog
            isDialogOpen={isDialogOpen}
            paymentInfo={paymentInfo}
            isRestricted
            payer={payer}
            caseUuid={null}
            closeDialog={closeDialog}
            zIndex={zIndex}
            sendNotification
            onlineChargeState={onlineChargeState}
        />
    );
};

export default OnboardingPaymentDialog;
