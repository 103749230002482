import momentTz from 'moment-timezone';
import { sortBy, mapValues } from 'lodash';
import { GatherEvent, GoogleEvent } from '../shared/types';
import { DateTimeFormat } from '../shared/utils';

const getTimes = (event: GatherEvent | GoogleEvent): {
    startMoment: momentTz.Moment | null;
    endMoment: momentTz.Moment | null;
 } => {
    const { start_time, end_time } = event;
    let startMoment: momentTz.Moment | null = null;
    let endMoment: momentTz.Moment | null = null;
    if (!GatherEvent.isGatherEvent(event) && event.is_all_day) {
        // This is an ALL DAY event
        // start_time: comes back as 12am UTC on the day of the event
        // end_time: comes back as 12am UTC on the day AFTER the last day of the event
        // This converts the times into the correct days for their timezone
        const timezone = momentTz.tz.guess() || 'America/Denver';
        startMoment = start_time ? momentTz(start_time).utc().tz(timezone, true) : null;
        endMoment = end_time ? momentTz(end_time).utc().tz(timezone, true) : null;
    } else {
        startMoment = start_time ? momentTz(start_time) : null;
        endMoment = end_time ? momentTz(end_time) : null;
    }
    return {
        startMoment,
        endMoment,
    };
};

export const getSortedEvents = (
    events: (GatherEvent | GoogleEvent)[],
    selectedMoment: momentTz.Moment,
): (GatherEvent | GoogleEvent)[] => {
    events = events.filter((event: GatherEvent | GoogleEvent) => {
        const { startMoment, endMoment } = getTimes(event);
        if (startMoment && endMoment) {
            return startMoment.isSame(selectedMoment, 'day')
                || endMoment.isSame(selectedMoment)
                || selectedMoment.isBetween(startMoment, endMoment);
        }
        return false;
    });
    // events dates and time are sorted in ascending order
    return sortBy(events, 'start_time');
};
export interface EventDateMap {
    [date: string]: (GatherEvent | GoogleEvent)[];
}
export const groupEventsByDay = (
    events: (GatherEvent | GoogleEvent)[],
): EventDateMap => {
    const eventDateMap: EventDateMap = {};
    events.forEach((event) => {
        const { startMoment, endMoment } = getTimes(event);
        if (startMoment && endMoment) {
            // this loop is necessary because events can be longer than a single day
            while (startMoment < endMoment) {
                const date = startMoment.format(DateTimeFormat.ShortDate);
                if (!eventDateMap.hasOwnProperty(date)) {
                    eventDateMap[date] = [];
                }
                eventDateMap[date].push(event);
                startMoment.add(1, 'days');
            }
        }
    });
    
    return mapValues(eventDateMap, (eventList) => sortBy(eventList, (e) => e.start_time));
};

export const getOverlappedEvents = (
    events: (GatherEvent | GoogleEvent)[],
    eventStartMoment: momentTz.Moment,
    eventEndMoment: momentTz.Moment
): (GatherEvent | GoogleEvent)[] => {
    return events.filter((event: GatherEvent | GoogleEvent) => {
        const { startMoment, endMoment } = getTimes(event);
        if (startMoment && endMoment) {
            return eventStartMoment.isBefore(endMoment) && eventEndMoment.isAfter(startMoment);
        }
        return false;
    });
};
