import {
    CaseLabelUX,
    CaseLabelUXWithDeleted,
    GatherCaseLabelCreateRequest,
    GatherCaseLabelUpdateRequest,
    MAX_CASE_LABEL_LENGTH,
    PartialWithRequiredField
} from '../shared/types';
import { registerAppError } from './errors';
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from './index';
import { log } from '../logger';
import { AppDispatch } from '../store';
import { canAccessCaseLabel } from '../shared/authority/can';
import { StoreState } from '../types';

export const CASE_LABEL_CREATED = 'CASE_LABEL_CREATED';
export type CASE_LABEL_CREATED = typeof CASE_LABEL_CREATED;

interface CaseLabelCreated {
    type: CASE_LABEL_CREATED;
    caseLabel: CaseLabelUX;
}

function caseLabelCreated(caseLabel: CaseLabelUX): CaseLabelCreated {
    return {
        type: CASE_LABEL_CREATED,
        caseLabel
    };
}

export const CASE_LABEL_UPDATED = 'CASE_LABEL_UPDATED';
export type CASE_LABEL_UPDATED = typeof CASE_LABEL_UPDATED;

interface CaseLabelUpdated {
    type: CASE_LABEL_UPDATED;
    caseLabel: PartialWithRequiredField<CaseLabelUX, 'id'>;
}

function caseLabelUpdated(caseLabel: PartialWithRequiredField<CaseLabelUX, 'id'>): CaseLabelUpdated {
    return {
        type: CASE_LABEL_UPDATED,
        caseLabel,
    };
}

export const CASE_LABEL_DELETED = 'CASE_LABEL_DELETED';
export type CASE_LABEL_DELETED = typeof CASE_LABEL_DELETED;

interface CaseLabelDeleted {
    type: CASE_LABEL_DELETED;
    caseLabel: CaseLabelUX;
}

function caseLabelDeleted(caseLabel: CaseLabelUX): CaseLabelDeleted {
    return {
        type: CASE_LABEL_DELETED,
        caseLabel
    };
}

export const CASE_LABELS_LOADED = 'CASE_LABELS_LOADED';
export type CASE_LABELS_LOADED = typeof CASE_LABELS_LOADED;

interface CaseLabelsLoaded {
    type: CASE_LABELS_LOADED;
    caseLabels: CaseLabelUX[];
}

function caseLabelsLoaded(caseLabels: CaseLabelUX[]): CaseLabelsLoaded {
    return {
        type: CASE_LABELS_LOADED,
        caseLabels
    };
}

export const LABEL_ADDED_TO_CASE = 'LABEL_ADDED_TO_CASE';
export type LABEL_ADDED_TO_CASE = typeof LABEL_ADDED_TO_CASE;

interface LabelAddedToCase {
    type: LABEL_ADDED_TO_CASE;
    caseUuid: string;
    caseLabel: CaseLabelUX;
}

function labelAddedToCase(caseUuid: string, caseLabel: CaseLabelUX): LabelAddedToCase {
    return {
        type: LABEL_ADDED_TO_CASE,
        caseUuid,
        caseLabel
    };
}

export const LABEL_REMOVED_FROM_CASE = 'LABEL_REMOVED_FROM_CASE';
export type LABEL_REMOVED_FROM_CASE = typeof LABEL_REMOVED_FROM_CASE;

interface LabelRemovedFromCase {
    type: LABEL_REMOVED_FROM_CASE;
    caseUuid: string;
    caseLabel: CaseLabelUX;
}

function labelRemovedFromCase(caseUuid: string, caseLabel: CaseLabelUX): LabelRemovedFromCase {
    return {
        type: LABEL_REMOVED_FROM_CASE,
        caseUuid,
        caseLabel
    };
}

export function createCaseLabelOnFHApi(funeralHomeId: number, name: string, color: string) {
    return async (dispatch: AppDispatch) => {
        if (name.length > MAX_CASE_LABEL_LENGTH) {
            return dispatch(registerAppError(`The case label name exceeds ${MAX_CASE_LABEL_LENGTH} characters`));
        }
        const createRequest: GatherCaseLabelCreateRequest = {
            name,
            color
        };
        try {
            GatherCaseLabelCreateRequest.fromRequest(createRequest);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseLabelCreateRequest:', { name, color, funeralHomeId, ex });
            return null;
        }
        const result = await postToAPI<CaseLabelUX>(`funeralhome/${funeralHomeId}/case/label`, createRequest, dispatch);
        return result;
    };
}

export function createCaseLabelOnFH(funeralHomeId: number, name: string, color: string) {
    return async (dispatch: AppDispatch) => {

        const result = await dispatch(createCaseLabelOnFHApi(funeralHomeId, name, color));
        if (!result) {
            return dispatch(registerAppError(`Problem creating case label`));
        }
        dispatch(caseLabelCreated(result));
        return result;
    };
}

export function updateCaseLabelonFHApi(
    labelId: number,
    funeralHomeId: number,
    caseLabel: Partial<CaseLabelUX>
) {
    return async (dispatch: AppDispatch) => {
        const updateRequest: GatherCaseLabelUpdateRequest = {
            name: caseLabel.name,
            color: caseLabel.color
        };
        try {
            GatherCaseLabelUpdateRequest.fromRequest(updateRequest);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseLabelUpdateRequest:', { id: labelId, funeralHomeId, ex });
            return null;
        }
        const url = `funeralhome/${funeralHomeId}/case/label/${labelId}`;
        const result = await putToAPI<CaseLabelUX>(url, updateRequest, dispatch);
        return result;
    };
}

export function updateCaseLabelOnFH(
    labelId: number,
    funeralHomeId: number,
    caseLabel: Partial<CaseLabelUX>
) {
    return async (dispatch: AppDispatch) => {
        const updatedLabel = {
            id: labelId,
            funeralHomeId,
            ...caseLabel
        };
        dispatch(caseLabelUpdated(updatedLabel));

        const result = await dispatch(updateCaseLabelonFHApi(labelId, funeralHomeId, caseLabel));
        if (!result) {
            return dispatch(registerAppError(`Problem updating case label`));
        }

        return result;
    };
}

export function deleteCaseLabelFromFHApi(caseLabel: CaseLabelUX) {
    return async (dispatch: AppDispatch) => {
        const url = `funeralhome/${caseLabel.funeralHomeId}/case/label/${caseLabel.id}`;
        return deleteFromAPI<CaseLabelUX>(url, dispatch);
    };
}

export function deleteCaseLabelFromFH(caseLabel: CaseLabelUX) {
    return async (dispatch: AppDispatch) => {
        // delete optimistically
        dispatch(caseLabelDeleted(caseLabel));
        const result = await dispatch(deleteCaseLabelFromFHApi(caseLabel));
        if (!result) {
            return dispatch(registerAppError(`Problem deleting case label`));
        }
        return result;
    };
}

export function loadCaseLabelsForFuneralHomeList(funeralHomeIdList: number[]) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const user = getState().userSession.userData;
        if (!user) {
            return [];
        }

        // filter out any FH IDs the User doesn't have access to case labels
        const fhIds = funeralHomeIdList.filter((fhId) => canAccessCaseLabel(user, fhId));
        if (fhIds.length === 0) {
            return [];
        }
        const searchParam = new URLSearchParams({
            fhIds: fhIds.join(','),
        });
        const team = await getFromAPI<CaseLabelUXWithDeleted[]>(
            `funeralhome/caselabel?${searchParam.toString()}`,
            dispatch,
        );
        return team;
    };
}

export function loadCaseLabelsForFuneralHomeApi(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        return getFromAPI<CaseLabelUX[]>(`funeralhome/${funeralHomeId}/case/label/`, dispatch);
    };
}

export function loadCaseLabelsForFuneralHome(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        const result = await dispatch(loadCaseLabelsForFuneralHomeApi(funeralHomeId));
        if (!result) {
            return dispatch(registerAppError(`Problem loading case labels for funeral homes`));
        }
        dispatch(caseLabelsLoaded(result));
        return result;
    };
}

export function addLabelToCase(caseUuid: string, caseLabel: CaseLabelUX) {
    return async (dispatch: AppDispatch) => {
        // update optimistically
        dispatch(labelAddedToCase(caseUuid, caseLabel));
        const result = await putToAPI<CaseLabelUX>(`api/case/${caseUuid}/label/${caseLabel.id}`, {}, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem adding label to case`));
        }
        return result;
    };
}

export function removeLabelFromCase(caseUuid: string, caseLabel: CaseLabelUX) {
    return async (dispatch: AppDispatch) => {
        // update optimistically
        dispatch(labelRemovedFromCase(caseUuid, caseLabel));
        const result = await deleteFromAPI<CaseLabelUX>(`api/case/${caseUuid}/label/${caseLabel.id}`, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem removing label from case`));
        }
        return result;
    };
}

export type CaseLabelAction =
    | CaseLabelCreated
    | CaseLabelUpdated
    | CaseLabelDeleted
    | CaseLabelsLoaded
    | LabelAddedToCase
    | LabelRemovedFromCase
    ;
