import {
    AppRoute,
    AppRouteType,
    buildRelativePathURLParts,
    buildRelativePathURLString,
    buildURLString,
    isAbsoluteURL,
    RouteBuilder,
    URLParts,
} from "../shared/navigationUtils";

export const urlIsFuneralHomeSite = (origin: string = window.location.origin): boolean => {
    return origin !== process.env.REACT_APP_APP_URL;
};

// Check to see if we are currently navigated to the remember page (or a subpage of it like /store)
export const isRememberPage = (pathname: string = window.location.pathname): boolean => {
    return pathname.startsWith('/remember/')
        || pathname.startsWith('/obituaries/');
};

export const redirectPage = (route: AppRoute): void => {
    const path = buildPathFromRoute(route);
    switch (path.type) {
        case PathType.DISABLED:
            break;
        case PathType.ABSOLUTE:
            window.location.href = buildURLString(path.urlParts);
            break;
        case PathType.RELATIVE:
            // force relative URLs to be absolute but use current domain
            window.location.href = buildURLString({
                ...path.urlParts,
                prefix: window.location.origin,
            });
            break;
        default:
            console.warn('Invalid PathType', JSON.stringify({ path, route }));
    }
};


// Get the full path to the remember page (use in external apps like FB, Twitter, etc)
// The Remember page is served up at either /remember/ or /obituaries/ (for embedded in FH site)
// Don't switch between the two - stick with whichever is currently being used
export const getRememberURLForSharing = (caseName: string): string => {
    const relativeUrlParts = buildRelativePathURLParts(RouteBuilder.RememberPage(caseName));
    // If the page is already served up with /obitiaries/
    // Or if the site is served on a site other than my.gather(qa).app
    const path = urlIsFuneralHomeSite()
        ? relativeUrlParts.path.replace(/^\/remember\//, '/obituaries/')
        : relativeUrlParts.path;
    return buildURLString({
        ...relativeUrlParts,
        prefix: window.location.origin,
        path,
    });
};

// Try to pull the case name out of the current path
const currentCaseName = (): string | null => {
    const res = /(?:obituaries|remember)\/([^\/]+)/.exec(window.location.pathname);
    return res ? res[1] : null;
};

// Return the caseName if this route includes one
const nextCaseName = (to: AppRoute): string | null => {
    switch (to.type) {
        case AppRouteType.FUNERALHOME:
        case AppRouteType.KEEPTRACK:
        case AppRouteType.KEEPTRACKECOMM:
        case AppRouteType.DISABLED:
        case AppRouteType.ROOT:
        case AppRouteType.LOGIN:
        case AppRouteType.ADMIN:
        case AppRouteType.SERVICETEMPLATES:
        case AppRouteType.WORKFLOW:
        case AppRouteType.REPORT:
        case AppRouteType.PUBLICGPL:
            return null;
        default:
            return to.caseName;
    }
};

const buildPathURLParts = (to: AppRoute): URLParts => {
    // Get the relative path of the next page
    const relativePath = buildRelativePathURLParts(to);

    // a = Currently at funeral home website
    const currentlyAtFuneralHomeSite = urlIsFuneralHomeSite();

    // b = Currently on a remember page
    const currentIsRememberPage = isRememberPage();

    // c = Next page is a remember page
    const nextIsRememberPage = isRememberPage(relativePath.path);

    // d = The case name changing
    const caseNameChanging = currentCaseName() !== nextCaseName(to);

    // Logic is embedded here: https://tinyurl.com/4w977ry6
    // Logic is (!b & c) or (a & !c) or (c & d)
    // In plain english, if one of the following is true, reload the page at the gather.app site
    //  1) Not on a remember page and going to a remember page
    //  2) On the funeral home website and going to a non-remember page
    //  3) Going to a remember page and the case name is changing
    const sendToGather = (!currentIsRememberPage && nextIsRememberPage)
        || (currentlyAtFuneralHomeSite && !nextIsRememberPage)
        || (nextIsRememberPage && caseNameChanging);

    // If we're at the funeral home site and going back to Gather
    //  then we need the full url at the gather.app site
    // OR If we are at gather.app page and navigating to a remember page from a non-remember page
    //  then we need to do a server hit (full path) in case the remember page needs to redirect to FH.com
    if (sendToGather) {
        // On the Gather side we need to rewrite /obituaries/ to /remember/
        const destination = {
            ...relativePath,
            path: relativePath.path.replace(/^\/obituaries\//, '/remember/'),
            prefix: process.env.REACT_APP_APP_URL,
        };
        if (currentlyAtFuneralHomeSite) {
            // Going back to Gather from funeral home page, route through auth handoff page
            return {
                prefix: window.location.origin,
                path: '/obituaries/_redirect',
                query: { destination: buildURLString(destination) },
            };
        } else if (nextIsRememberPage && !currentlyAtFuneralHomeSite) {
            // This user is probably authenticated and will want to be authenticated on the new site!
            return {
                ...destination,
                query: { ...destination.query, _fwd: 'remember' },
            };
        } else {
            return destination;
        }
    } else if (currentlyAtFuneralHomeSite) {
        // if we are on a funeral home site we should always show /obituaries instead of /remember
        return {
            ...relativePath,
            path: relativePath.path.replace(/^\/remember\//, '/obituaries/'),
        };
    } else {
        return relativePath;
    }
};

export enum PathType {
    DISABLED = 'DISABLED',
    RELATIVE = 'RELATIVE',
    ABSOLUTE = 'ABSOLUTE',
}

type BuildPathFromRoute = { type: PathType.DISABLED }
    | { type: Exclude<PathType, PathType.DISABLED>; urlParts: URLParts };

export const buildPathFromRoute = (to: AppRoute): BuildPathFromRoute => {
    if (to.type === AppRouteType.DISABLED) {
        return {
            type: PathType.DISABLED,
        };
    }

    const urlParts = buildPathURLParts(to);
    return {
        type: isAbsoluteURL(urlParts.prefix) ? PathType.ABSOLUTE : PathType.RELATIVE,
        urlParts,
    };
};

export const doesPathnameStartWithRoute = (route: AppRoute, pathname: string = window.location.pathname) => {
    const routePath = buildRelativePathURLString(route);
    const decodedPathname = decodeURI(pathname);
    return decodedPathname.startsWith(routePath);
};
