import {
    PhotoTransformationsType,
    LocationUX,
    GatherEventWithPlayback,
    ServiceDetail,
    PublicTask,
    TaskState,
    AlbumEntryMappedType,
    EntitySummary,
    CombinedDocPacketStatus,
    MemoryUX,
    FlowerSalesUX,
    AlbumWithEntries,
    EventForAppConfig,
    CaseLabelUX,
    TaskLocationUX,
    WorkflowSummary,
    TaskLocationSummary,
    getValidator,
    TrackingStepType,
    WorkflowUX,
    WorkflowChangeSummary,
    CaseTaskUX,
    DocUX,
    CaseBelongingUX,
    FingerprintUX,
    ModerationPendingCountsForAllCases,
    WebsiteVendor,
    ConsumableSearchParam,
    enumTypeGuard,
    WorkflowRecord,
    KeeptrackCardRecord,
    Nullable,
    DeathCertificateConfigSummary,
    CaseTaskRecord,
    DeathCertificateConfigRecord,
    TaskRecord,
    TaskLocationRecord,
    AddressRecord,
    InsurancePolicySummaryUX,
} from '.';
import * as t from 'io-ts';
import { DeathCertificateType, LongAddress } from './deathcertificate';
import { CaseGiftPhotoUX } from './photo';
import { StreamableEventsAndDevicesResponse } from './live_stream';
import { FuneralHomeUX, FuneralHomePublic, FuneralHomeRecord } from './funeralHome';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { ObituaryLinkUX } from './remember';
import { EntityRecord, GuestListUser, UserProfileRecord } from './user';
import { EventForAppConfigRecord, GatherEvent } from './event';
import { ProductCategory } from './product';
import { isRight } from 'fp-ts/lib/Either';
import { ThemeUX } from './themes';
import { NoteResponse } from './note';

/**
 * case utility functions
 */

export const getGatherCase = (params: {
    selectedCase: GatherCaseUX | null;
    publicCase: GatherCasePublic | null;
}): GatherCaseUX | GatherCasePublic | null => {
    const { selectedCase, publicCase } = params;
    return selectedCase ? selectedCase : publicCase ? publicCase : null;
};

export const getGatherCaseId = (
    gatherCase: GatherCaseUX | null,
    publicCase: GatherCasePublic | null,
): number | null => {
    const c = getGatherCase({ selectedCase: gatherCase, publicCase });
    return c ? c.id : null;
};

export const getGatherCaseUuid = (
    gatherCase: GatherCaseUX | null,
    publicCase: GatherCasePublic | null,
): string | null => {
    const c = getGatherCase({ selectedCase: gatherCase, publicCase });
    return c ? c.uuid : null;
};

export const getGatherCaseName = (params: {
    selectedCase: GatherCaseUX | null;
    publicCase: GatherCasePublic | null;
}): string | null => {
    const c = getGatherCase(params);
    return c ? c.name : null;
};

/**
 * case types
 */
export interface CaseAssignee {
    user_id: number;
    entity_id: number;
    fname: string;
    lname: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    email: string | null;
    phone: string | null;
}

export enum CaseType {
    'pre_need' = 'pre-need',
    'at_need' = 'at-need',
    'trade' = 'trade',
    'one_off' = 'one-off',
}

export const CaseTypeDisplayLookup = {
    [CaseType.pre_need]: 'Pre-Need',
    [CaseType.at_need]: 'At-Need',
    [CaseType.trade]: 'Trade',
    [CaseType.one_off]: 'One-Off',
};

export const CaseTypeAbbrMap = {
    [CaseType.pre_need]: 'PRE',
    [CaseType.at_need]: 'AT',
    [CaseType.trade]: 'TRD',
    [CaseType.one_off]: 'X',
};

export const CaseTypeDefinition = t.union([
    t.literal(CaseType.pre_need),
    t.literal(CaseType.at_need),
    t.literal(CaseType.trade),
    t.literal(CaseType.one_off),
]);

export interface CaseTypeSummary {
    [CaseType.pre_need]: number;
    [CaseType.at_need]: number;
    [CaseType.trade]: number;
    [CaseType.one_off]: number;
}

export const isCaseType = (str: string): str is CaseType => {
    // This double parsing is necessary to force Dates to strings
    const validationResult = CaseTypeDefinition.decode(str);
    return isRight(validationResult);
};

export interface GatherCaseEssentials {
    id: number;
    uuid: string;
    name: string;
    funeral_home_key: string;
}

// ---> FuneralHomeCaseRecord <---
export interface FuneralHomeCaseRecord {
    id: number; // only used for SQL joins
    uuid: string; // used for all external references to case
    funeral_home_id: number;
    gather_case_id: number;
    case_number: string | null;
    assignee_id: number;
    case_type: CaseType;
    case_type_change_time: Date;
    case_type_change_by: number;
    workflow_id: number | null;
    expense_total: string;
    collected_total: string;
    proposed_total: string;
    tax_total: string;
    merch_pretax_sales_total: string;
    proserve_pretax_sales_total: string;
    cashadv_pretax_sales_total: string;
    care_of_loved_one_pretax_sales_total: string;
    transportation_pretax_sales_total: string;
    use_of_facilities_pretax_sales_total: string;
    casket_pretax_sales_total: string;
    urn_pretax_sales_total: string;
    vault_pretax_sales_total: string;
    cemetery_pretax_sales_total: string;
    memorial_pretax_sales_total: string;
    flowers_pretax_sales_total: string;
    archived_time: Date | null;
    whiteboard_rank: number | null;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
    task_total_count: number;
    task_completed_count: number;
    step_total_count: number;
    step_completed_count: number;
    latest_successful_payment_id: number | null;
}

export interface FuneralHomeCaseRecordInsert
    extends Pick<
        FuneralHomeCaseRecord,
        | 'funeral_home_id'
        | 'gather_case_id'
        | 'case_number'
        | 'assignee_id'
        | 'workflow_id'
        | 'case_type'
        | 'case_type_change_by'
        | 'created_by'
        | 'updated_by'
    > {}

export interface FuneralHomeCaseRecordUpdate
    extends Pick<
        Partial<FuneralHomeCaseRecord>,
        | 'assignee_id'
        | 'workflow_id'
        | 'case_type'
        | 'case_type_change_by'
        | 'case_type_change_time'
        | 'updated_by'
        | 'updated_time'
        | 'archived_time'
        | 'deleted_time'
        | 'deleted_by'
    > {}

export interface FuneralHomeCaseNumberUpdate extends Pick<Partial<FuneralHomeCaseRecord>, 'case_number'> {}

// ---> GatherCaseRecord <---
export interface GatherCaseRecord {
    id: number;
    name: string;
    fname: string;
    mname: string | null;
    lname: string;
    suffix: string | null;
    display_full_name: string;
    display_fname: string;
    gender: string | null;

    dob_date: string | null;
    dod_start_date: string | null;
    dod_start_time: string;
    dod_start_tz: string;
    dod_end_date: string | null;
    dod_end_time: string;
    dod_end_tz: string;
    dod_datetime: Date | null;
    dop_date: string | null;
    dop_time: string;
    dop_tz: string;
    death_date_unknown: boolean;
    dc_informant: number | null;
    dc_spouse: number | null;
    dc_father: number | null;
    dc_mother: number | null;
    dc_residence: LongAddress | null;
    death_certificate: DeathCertificateType | null;
    death_certificate_locked: boolean;
    death_certificate_percentage: number | null;
    dc_updated_by: number | null;
    dc_updated_time: Date | null;
    dc_config_rev_id: number;

    created_time: Date;
    funeral_home_id: number;
    photo_view_id: number | null;
    is_test: boolean;
    deleted_time: Date | null;

    casket_bearers: string | null;
    honorary_casket_bearers: string | null;
    casket_bearers_locked: boolean;

    service_details: string | null;
    service_details_locked: boolean;
    service_template_id: number | null;

    has_old_photo_dir: boolean | null;
    options: CaseOptions;
    link_gofundme: string | null;
    link_memorialvideo_youtube: string | null;
    link_memorialvideo_tukios: string | null;

    imported_time: Date | null;
    updated_time: Date;
    updated_by: number;

    created_by: number;

    theme_id: number | null;
    desktop_cover_view_id: number | null;
    mobile_cover_view_id: number | null;
    last_move_task_id: number | null;

    new_memories_sms_last_sent_time: Date | null;
    new_memories_email_last_sent_time: Date | null;
    case_label_ids: number[];
}

export enum MemorialVideoType {
    youtube = 'youtube',
    tukios = 'tukios',
}
export class GatherCaseRecord {
    public static trimNames(gatherCase: Partial<GatherCaseRecord>): Partial<GatherCaseRecord> {
        ['fname', 'mname', 'lname', 'suffix', 'display_full_name', 'display_fname', 'name'].forEach((n) => {
            if (gatherCase[n] && gatherCase[n].trim) {
                gatherCase[n] = gatherCase[n].trim();
            }
        });
        return gatherCase;
    }
}

// ---> GatherCaseLogRecord <---
export interface TableLogRecord {
    id: number;
    column_name: string;
    old_value: string;
    new_value: string;
    note: string | null;
    action_type: string;
    action_time: Date;
    action_by: number;
}

export enum GatherCaseLogAction {
    column_changed = 'column_changed',
}

// ---> GatherCaseLogRecord <---
export interface GatherCaseLogRecord extends TableLogRecord {
    gather_case_id: number;
    action_type: GatherCaseLogAction;
    column_name: keyof GatherCaseRecord;
}

export enum FuneralHomeCaseLogAction {
    column_changed = 'column_changed',
}

// ---> FuneralHomeCaseLogRecord <---
export interface FuneralHomeCaseLogRecord extends TableLogRecord {
    funeral_home_case_id: number;
    action_type: FuneralHomeCaseLogAction;
    column_name: keyof FuneralHomeCaseRecord;
}

// ---> GatherCaseNameRecord <---
export interface GatherCaseNameRecord {
    name: string;
    gather_case_id: number | null;
    created_by: number;
    created_time: Date;
}

export enum CaseOption {
    remember_page = 'remember_page',
    cover_photo = 'cover_photo',
    life_span = 'life_span',
    inscription = 'inscription',
    hanging_photos = 'hanging_photos',
    obituary = 'obituary',
    go_fund_me = 'go_fund_me',
    memories_section = 'memories_section',
    guest_book = 'guest_book',
    casket_bearers = 'casket_bearers',
    service_details = 'service_details',
    events = 'events',
    embed_memorial_video = 'embed_memorial_video',
    flowers_and_cards = 'flowers_and_cards',
    show_photos = 'show_photos',
    guest_payment = 'guest_payment',
    share_FACEBOOK = 'share_FACEBOOK',
    share_MESSENGER = 'share_MESSENGER',
    share_TWITTER = 'share_TWITTER',
    share_WHATSAPP = 'share_WHATSAPP',
    share_LINKEDIN = 'share_LINKEDIN',
    share_EMAIL = 'share_EMAIL',
    share_SMS = 'share_SMS',
    share_SHARE = 'share_SHARE',
    share_LINK = 'share_LINK',
    share_QRCODE = 'share_QRCODE',
    sell_flowers = 'sell_flowers',
    plant_trees = 'plant_trees',
}
export type CaseOptions = Record<CaseOption, boolean>;

export const getDefaultCaseOptions = (): CaseOptions => ({
    remember_page: true,
    cover_photo: true,
    life_span: true,
    inscription: true,
    hanging_photos: true,
    obituary: true,
    go_fund_me: false,
    memories_section: true,
    guest_book: true,
    casket_bearers: true,
    service_details: true,
    events: true,
    embed_memorial_video: true,
    flowers_and_cards: true,
    show_photos: true,
    guest_payment: true,
    share_FACEBOOK: true,
    share_MESSENGER: true,
    share_TWITTER: true,
    share_WHATSAPP: true,
    share_LINKEDIN: true,
    share_EMAIL: true,
    share_SMS: true,
    share_SHARE: true,
    share_LINK: true,
    share_QRCODE: true,
    sell_flowers: true,
    plant_trees: true,
});

export const generateDummyCase = (required?: Partial<GatherCaseRecord>): Omit<GatherCaseRecord, 'id'> => ({
    name: '',
    fname: 'John',
    mname: null,
    lname: 'Doe',
    suffix: null,
    gender: 'Male',
    dob_date: '1950-01-17',
    dod_start_date: '2020-01-25',
    dod_start_time: '',
    dod_start_tz: '',
    death_date_unknown: false,
    dod_end_date: null,
    dod_end_time: '',
    dod_end_tz: '',
    dod_datetime: null,
    dop_date: null,
    dop_time: '',
    dop_tz: '',
    created_time: new Date(),
    created_by: 0,
    updated_time: new Date(),
    updated_by: 0,
    funeral_home_id: 0,
    is_test: true,
    photo_view_id: null,
    deleted_time: null,
    death_certificate: null,
    dc_informant: null,
    dc_spouse: null,
    dc_father: null,
    dc_mother: null,
    dc_residence: null,
    death_certificate_locked: false,
    death_certificate_percentage: 0,
    dc_updated_by: null,
    dc_updated_time: null,
    dc_config_rev_id: 0,
    service_template_id: null,
    service_details_locked: false,
    service_details: null,
    casket_bearers: null,
    honorary_casket_bearers: null,
    casket_bearers_locked: false,
    has_old_photo_dir: false,
    options: getDefaultCaseOptions(),
    imported_time: null,
    display_full_name: '',
    display_fname: '',
    link_gofundme: null,
    link_memorialvideo_youtube: null,
    link_memorialvideo_tukios: null,
    theme_id: null,
    desktop_cover_view_id: null,
    mobile_cover_view_id: null,
    last_move_task_id: null,
    new_memories_email_last_sent_time: null,
    new_memories_sms_last_sent_time: null,
    case_label_ids: [],
    ...required,
});

export interface CaseWatcher {
    user_id: UserProfileRecord['id'];
    entity_id: UserProfileRecord['entity_id'];
}

export interface GatherCaseUXRecord
    extends Pick<
            GatherCaseRecord,
            | 'id'
            | 'name'
            | 'fname'
            | 'mname'
            | 'lname'
            | 'suffix'
            | 'gender'
            | 'display_full_name'
            | 'display_fname'
            | 'dob_date'
            | 'dod_start_date'
            | 'dod_start_time'
            | 'dod_start_tz'
            | 'dod_end_date'
            | 'dod_end_time'
            | 'dod_end_tz'
            | 'dop_date'
            | 'dop_time'
            | 'dop_tz'
            | 'death_date_unknown'
            | 'created_time'
            | 'created_by'
            | 'imported_time'
            | 'updated_time'
            | 'updated_by'
            | 'photo_view_id'
            | 'is_test'
            | 'deleted_time'
            | 'death_certificate'
            | 'death_certificate_locked'
            | 'death_certificate_percentage'
            | 'dc_updated_by'
            | 'dc_updated_time'
            | 'dc_config_rev_id'
            | 'dc_informant'
            | 'dc_spouse'
            | 'dc_father'
            | 'dc_mother'
            | 'dc_residence'
            | 'service_details'
            | 'service_details_locked'
            | 'service_template_id'
            | 'casket_bearers'
            | 'honorary_casket_bearers'
            | 'casket_bearers_locked'
            | 'has_old_photo_dir'
            | 'options'
            | 'link_gofundme'
            | 'link_memorialvideo_youtube'
            | 'link_memorialvideo_tukios'
            | 'desktop_cover_view_id'
            | 'mobile_cover_view_id'
            | 'last_move_task_id'
            | 'theme_id'
            | 'case_label_ids'
        >,
        Pick<
            FuneralHomeCaseRecord,
            | 'uuid'
            | 'funeral_home_id'
            | 'case_number'
            | 'assignee_id'
            | 'case_type'
            | 'case_type_change_time'
            | 'case_type_change_by'
            | 'workflow_id'
            | 'expense_total'
            | 'collected_total'
            | 'proposed_total'
            | 'archived_time'
            | 'whiteboard_rank'
        > {
    funeral_home_case_id: FuneralHomeCaseRecord['id'];
    owner_funeral_home_id: GatherCaseRecord['funeral_home_id'];
    full_name: string;
    photo: string | null;
    photo_id: number | null;
    photo_transformations: PhotoTransformationsType | null;
    helper_count: number;
    helpers_accepted: number;
    assignee_entity_id: number;
    obituary_task_status: TaskState;
    service_details_count: number | null;
    order1: 1;
    order2: number;
    service_template_name: string | null;
    sync: WebsiteVendor[];
    dc_informant_entity_id: number | null;
    dc_spouse_entity_id: number | null;
    dc_father_entity_id: number | null;
    dc_mother_entity_id: number | null;
    is_published: boolean;

    desktop_cover: string | null;
    mobile_cover: string | null;
    desktop_cover_id: number | null;
    mobile_cover_id: number | null;
    desktop_transformations: PhotoTransformationsType | null;
    mobile_transformations: PhotoTransformationsType | null;
    last_move_task_location_id: Nullable<CaseTaskRecord>['task_location_id'];
    last_move_completed_time: Nullable<CaseTaskRecord>['marked_complete_time'];
    workflow_name: string | null;
    memories_count: number;
    photos_count: number;
    flowers_count: number;
    fingerprints_count: number;
    belongings_count: number;
    keeptrack_key: string | null;
    keeptrack_assigned_time: Date | null;
    keeptrack_finalized_time: Date | null;
    dc_config_name: DeathCertificateConfigRecord['name'];
    watchers: (CaseWatcher & Pick<EntityRecord, 'fname' | 'lname' | 'email'> & Pick<UserProfileRecord, 'role'>)[];
    insurance_policies: number[];
    zero_expense: boolean;
    zero_balance: boolean;
}

// ---> GatherCaseCreateRequest <---
const gatherCaseCreateRequestDefinition = {
    fname: t.string,
    lname: t.string,
    dod_start_date: t.union([t.string, t.null]),
    case_type: CaseTypeDefinition,
    assignee_id: t.number,
    workflow_id: t.union([t.number, t.null]),
    death_certificate_config_id: t.number,
    deathNotice: t.string,
    autoSync: t.boolean,
    theme_id: t.union([t.number, t.null]),
    case_number: t.union([t.string, t.null]),
    policy_id: t.union([t.number, t.null]),
};
const GatherCaseCreateRequestType = t.type(gatherCaseCreateRequestDefinition);

export interface GatherCaseCreateRequest extends t.TypeOf<typeof GatherCaseCreateRequestType> {
    case_type: CaseType;
}

export class GatherCaseCreateRequest {
    public static fromRequest = getValidator<GatherCaseCreateRequest>(GatherCaseCreateRequestType);
}

// ---> GatherCaseDuplicateRequest <---
const gatherCaseDuplicateRequestDefinition = {
    fname: t.string,
    lname: t.string,
    dod_start_date: t.union([t.string, t.null]),
    case_type: CaseTypeDefinition,
};
const GatherCaseDuplicateRequestType = t.type(gatherCaseDuplicateRequestDefinition);

export interface GatherCaseDuplicateRequest extends t.TypeOf<typeof GatherCaseDuplicateRequestType> {
    case_type: CaseType;
}

export class GatherCaseDuplicateRequest {
    public static fromRequest = getValidator<GatherCaseDuplicateRequest>(GatherCaseDuplicateRequestType);
}

// ---> GatherCaseUpdateRequest <---
const gatherCaseUpdateRequestDefinition = {
    case_type: CaseTypeDefinition,
    service_details_locked: t.boolean,
    casket_bearers_locked: t.boolean,
    workflow_id: t.number,
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    suffix: t.union([t.string, t.null]),
    display_full_name: t.string,
    display_fname: t.string,
    name: t.string,
};
const GatherCaseUpdateRequestType = t.partial(gatherCaseUpdateRequestDefinition);

export interface GatherCaseUpdateRequest extends t.TypeOf<typeof GatherCaseUpdateRequestType> {
    case_type?: CaseType;
}

export class GatherCaseUpdateRequest {
    public static fromRequest = getValidator<GatherCaseUpdateRequest>(GatherCaseUpdateRequestType);
}

// ---> GatherCaseNumberUpdateRequest <---
const gatherCaseNumberUpdateRequestDefinition = {
    caseNumber: t.string,
};
const GatherCaseNumberUpdateRequestType = t.type(gatherCaseNumberUpdateRequestDefinition);
export interface GatherCaseNumberUpdateRequest extends t.TypeOf<typeof GatherCaseNumberUpdateRequestType> {}
export class GatherCaseNumberUpdateRequest {
    public static fromRequest = getValidator<GatherCaseNumberUpdateRequest>(GatherCaseNumberUpdateRequestType);
}

// ---> GatherCaseFHUpdateRequest <---
const gatherCaseFHUpdateRequestDefinition = {
    is_test: t.boolean,
    assignee_id: t.number,
    case_watcher_user_ids: t.array(t.number),
    workflow_id: t.number,
    archived_time: t.union([DateFromISOString, t.null]),
};
const GatherCaseFHUpdateRequestType = t.partial(gatherCaseFHUpdateRequestDefinition);

export interface GatherCaseFHUpdateRequest extends t.TypeOf<typeof GatherCaseFHUpdateRequestType> {}

export class GatherCaseFHUpdateRequest {
    public static fromRequest = getValidator<GatherCaseFHUpdateRequest>(GatherCaseFHUpdateRequestType);
}

export interface GatherCaseFHUpdateRequestUX extends Omit<GatherCaseFHUpdateRequest, 'assignee_id' | 'workflow_id'> {
    assignee?: EntitySummary;
    workflow?: {
        id: number;
        name: string;
    };
}

// ---> GatherCaseMoveRequest <---
const gatherCaseMoveRequestDefinition = {
    dest_funeral_home_id: t.number,
    assignee_id: t.number,
    workflow_id: t.number,
    dc_config_id: t.number,
    case_number: t.union([t.string, t.null]),
};
const GatherCaseMoveRequestType = t.type(gatherCaseMoveRequestDefinition);

export interface GatherCaseMoveRequest extends t.TypeOf<typeof GatherCaseMoveRequestType> {}

export class GatherCaseMoveRequest {
    public static fromRequest = getValidator<GatherCaseMoveRequest>(GatherCaseMoveRequestType);
}

export interface LoadMoveCaseOptionsResponse {
    team: EntitySummary[];
    workflows: WorkflowSummary[];
    dcConfigs: DeathCertificateConfigSummary[];
    gatherCase: GatherCaseUX;
}

export interface GatherCaseSummaryRecord
    extends Pick<
            GatherCaseRecord,
            | 'id'
            | 'created_time'
            | 'name'
            | 'fname'
            | 'lname'
            | 'gender'
            | 'dob_date'
            | 'dod_start_date'
            | 'dc_residence'
        >,
        Pick<FuneralHomeCaseRecord, 'uuid' | 'funeral_home_id' | 'case_number' | 'case_type' | 'workflow_id'> {
    owner_funeral_home_id: number;
    funeral_home_name: string;
    funeral_home_key: string;
    funeral_home_address1: AddressRecord['address1'];
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    labels: CaseLabelUX[];
    keeptrack_key: string | null;
    assignee: {
        fname: string;
        mname?: string | null;
        lname: string | null;
        suffix?: string | null;
    };
    workflow_name: string | null;
    current_task_location: TaskLocationSummary | null;
    funeral_home_case_id: FuneralHomeCaseRecord['id'];
}

export interface GatherCaseSummary extends GatherCaseSummaryRecord {}

// ---> CaseOptionsUpdateRequest <---
const caseOptionsUpdateRequestDefinition = {
    options: t.dictionary(t.string, t.boolean),
    link_gofundme: t.string,
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    suffix: t.union([t.string, t.null]),
    display_full_name: t.string,
    display_fname: t.string,
    link_memorialvideo_youtube: t.string,
    link_memorialvideo_tukios: t.string,
    theme_id: t.union([t.number, t.null]),
};
const CaseOptionsUpdateRequestType = t.partial(caseOptionsUpdateRequestDefinition);

export interface CaseOptionsUpdateRequest extends t.TypeOf<typeof CaseOptionsUpdateRequestType> {
    options?: CaseOptions;
}

export class CaseOptionsUpdateRequest {
    public static fromRequest = getValidator<CaseOptionsUpdateRequest>(CaseOptionsUpdateRequestType);
}

export interface CaseCurrentTaskLocation extends TaskLocationUX {
    moved_time: Date;
}

export interface GatherCaseUX extends Omit<GatherCaseUXRecord, 'expense_total' | 'collected_total' | 'proposed_total'> {
    funeral_home: FuneralHomeUX;
    assignee: EntitySummary;
    obituary_content_length: number | null;
    obituary_content: string | null;
    obituary_published_content: string | null;
    obituary_locked: boolean | null;
    obituary_updated_by_fname: string | null;
    obituary_updated_by_lname: string | null;
    obituary_updated_time: Date | null;
    expense_total: number;
    collected_total: number;
    proposed_total: number;
    labels: CaseLabelUX[];
    theme: ThemeUX | null;
    current_task_location: CaseCurrentTaskLocation | null;
    insurance_policies: number[];
    zero_expense: boolean;
    zero_balance: boolean;
}

export interface LoadPrivateCaseResponse {
    requestedCase: GatherCaseWithRedirect | null;
    fhKeyWithAccess?: string;
}
export interface GatherCaseDashboardUXRecord
    extends Pick<
            GatherCaseRecord,
            | 'id'
            | 'name'
            | 'fname'
            | 'mname'
            | 'lname'
            | 'suffix'
            | 'display_full_name'
            | 'display_fname'
            | 'dob_date'
            | 'dod_start_date'
            | 'dod_start_time'
            | 'created_time'
            | 'updated_time'
            | 'deleted_time'
            | 'death_certificate_percentage'
            | 'death_certificate_locked'
            | 'is_test'
        >,
        Pick<
            FuneralHomeCaseRecord,
            | 'uuid'
            | 'funeral_home_id'
            | 'case_number'
            | 'case_type'
            | 'case_type_change_time'
            | 'workflow_id'
            | 'expense_total'
            | 'collected_total'
            | 'proposed_total'
            | 'archived_time'
            | 'task_total_count'
            | 'task_completed_count'
            | 'step_total_count'
            | 'step_completed_count'
        > {
    owner_funeral_home_id: GatherCaseRecord['funeral_home_id'];
    order_date: Date;
    is_contract_frozen: boolean;
    photo: string | null;
    photo_id: number | null;
    photo_transformations: PhotoTransformationsType | null;
    assignee: CaseAssignee;
    helper_count: number;
    helpers_accepted: number;
    events_scheduled: number;
    events_future: number;
    has_memories_pending_approval: boolean;
    memories_count: number;
    photos_count: number;
    flowers_count: number;
    has_livestream_event: boolean;
    doc_packet_count: number;
    doc_packet_combined_status: CombinedDocPacketStatus;
    doc_packet_combined_signer_total_count: number;
    doc_packet_combined_signer_signed_count: number;
    note_count: number;
    is_published: boolean;
    created_by_name: string;
    created_by_fname: string;
    labels: CaseLabelUX[];
    workflow_name: string | null;
    current_task_location: TaskLocationSummary | null;
    keeptrack_key: string | null;
    keeptrack_assigned_time: Date | null;
    keeptrack_finalized_time: Date | null;
    funeral_home_key: string;
    watchers: CaseWatcher[];
    insurance_policies: number[];
}

export interface GatherCaseDashboardUx
    extends Omit<GatherCaseDashboardUXRecord, 'expense_total' | 'collected_total' | 'proposed_total'> {
    payment_methods: string[];
    payer_names: string[];
    products: { name: string; photo: string | null }[];
    obituary_locked: boolean | null;
    obituary_content_length: number | null;
    expense_total: number;
    collected_total: number;
    proposed_total: number;
    funeral_home: { id: number; key: string };
}

export class GatherCaseDashboardUx {
    public static assigneePartialFromEntity(entity: EntitySummary): Partial<GatherCaseDashboardUx> {
        const { user_id } = entity;

        if (!user_id) {
            console.warn('Assignee does not have a login');
            return { assignee: undefined };
        }

        return {
            assignee: {
                user_id,
                entity_id: entity.entity_id,
                fname: entity.fname,
                lname: entity.lname,
                email: entity.email,
                phone: entity.phone,
                photo: entity.photo,
                photo_transformations: entity.photo_transformations,
            },
        };
    }
}

// ---> GatherCaseDashboardRollup <---
export interface GatherCaseDashboardMonthlyRollupRecord {
    cases_count: number;
    year: number;
    month: number;
    month_abbv: string;
    expense_total: string;
    collected_total: string;
}

export interface GatherCaseDashboardMonthlyRollup
    extends Omit<GatherCaseDashboardMonthlyRollupRecord, 'expense_total' | 'collected_total'> {
    expense_total: number;
    collected_total: number;
}

export interface GatherCaseDashboardRollup {
    case_type_summary: CaseTypeSummary;
    monthly_rollup: GatherCaseDashboardMonthlyRollup[];
}

// ---> GatherCaseDashboardAssignee <---
export interface GatherCaseDashboardAssignee {
    user_id: number;
    entity_id: number;
    fname: string;
    lname: string;
    email: string;
    phone: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    initials: string;
    assigned_cases_count: number | null;
    total_cases_count: number | null;
}

export class GatherCaseDashboardAssignee {
    public static initialsFromPartial(
        assignee: Partial<{
            fname?: string;
            lname?: string;
            assignee_fname?: string;
            assignee_lname: string;
        }>,
    ): string {
        const { fname, lname, assignee_fname, assignee_lname } = assignee;

        let firstInitial: string;
        let lastInitial: string;

        if (assignee_fname !== undefined) {
            firstInitial = assignee_fname && assignee_fname.length > 0 ? assignee_fname.charAt(0).toUpperCase() : '';
            lastInitial = assignee_lname && assignee_lname.length > 0 ? assignee_lname.charAt(0).toUpperCase() : '';
        } else {
            firstInitial = fname && fname.length > 0 ? fname.charAt(0).toUpperCase() : '';
            lastInitial = lname && lname.length > 0 ? lname.charAt(0).toUpperCase() : '';
        }

        return firstInitial + lastInitial;
    }
}

export enum CaseStatus {
    TEST = 'TEST',
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
    ARCHIVED = 'ARCHIVED',
}

// ---> GatherCaseReportAssignee <---
export interface GatherCaseReportAssignee extends Pick<EntityRecord, 'fname' | 'lname' | 'email' | 'phone'> {
    user_id: number;
    entity_id: number;
}

// ---> GatherCaseReportType <---
export interface GatherCaseReportType
    extends Pick<
            GatherCaseRecord,
            'id' | 'name' | 'fname' | 'lname' | 'dob_date' | 'dod_start_date' | 'deleted_time' | 'is_test'
        >,
        Pick<
            FuneralHomeCaseRecord,
            'uuid' | 'funeral_home_id' | 'case_number' | 'case_type' | 'archived_time' | 'workflow_id'
        > {
    owner_funeral_home_id: number;
    age: number | null;
    created_time: string;
    updated_time: string;
    full_name: string;
    case_status: CaseStatus;
    funeral_home_name: string;
    funeral_home_key: string;
    funeral_home_is_demo: boolean;
    assignee_fname: string;
    assignee: GatherCaseReportAssignee;
    helper_count: number;
    helpers_accepted: number;
    workflow_name: Nullable<WorkflowRecord>['name'];
}

export interface GatherCaseUXTable extends GatherCaseReportType {
    created_time_formatted: string;
    updated_time_formatted: string;
    death_date_formatted: string;
}

export interface WhiteboardStep {
    step_name: string;
    step_type: TrackingStepType;
    completed_by_name: string;
    completed_by_photo: string | null;
    completed_by_photo_transformations: PhotoTransformationsType | null;
    completed_by_time: Date; // ISO date string until it is parsed in the UI
}
export interface WhiteboardEvent {
    name: string;
    start_time: Date; // ISO date string until it is parsed in the UI
    location_name: string | null;
    is_streamable: boolean;
}

// ---> WhiteboardCaseUX <---
export interface WhiteboardCaseUXRecord
    extends Pick<GatherCaseRecord, 'id' | 'name' | 'fname' | 'lname' | 'is_test' | 'dob_date' | 'dod_start_date'>,
        Pick<
            FuneralHomeCaseRecord,
            'uuid' | 'funeral_home_id' | 'case_number' | 'assignee_id' | 'case_type' | 'workflow_id'
        > {
    fh_case_id: number;
    owner_funeral_home_id: GatherCaseRecord['funeral_home_id'];
    funeral_home_key: FuneralHomeRecord['key'];
    funeral_home_name: FuneralHomeRecord['name'];
    fh_color: FuneralHomeRecord['custom_assets']['themeColor'];
    fh_logo: string | null;
    fh_logo_transformations: PhotoTransformationsType | null;
    full_name: string;
    keeptrack_key: KeeptrackCardRecord['tracker_id'];
    workflow_name: WorkflowRecord['name'];
    current_task_location: TaskLocationSummary | null;
    case_photo: string | null;
    case_photo_transformations: PhotoTransformationsType | null;
    dc_weight: string | null;
    dc_pickup_address: LongAddress | null;
    dc_dropoff_address: LongAddress | null;

    labels: CaseLabelUX[];
    events_scheduled: WhiteboardEvent[];
    merch_selected: {
        name: string;
        category: ProductCategory;
    }[];
    doc_packet_count: number;
    doc_packet_combined_status: CombinedDocPacketStatus;
    doc_packet_combined_signer_total_count: number;
    doc_packet_combined_signer_signed_count: number;
    case_id_photo_count: number;
    case_belongings_count: number;
    fingerprints_count: number;
    rank: FuneralHomeCaseRecord['whiteboard_rank'];
    watchers: CaseWatcher[];
    notes_count: number;
}

export interface WhiteboardCaseUX extends WhiteboardCaseUXRecord {
    steps_completed: WhiteboardStep[];
}

export const KEEPTRACK_REPORT_PAGESIZE = 20;
export const KEEPTRACK_REPORT_STARTSORT_FIELD = 'marked_complete_time';
export const KEEPTRACK_REPORT_STARTSORT_DIR = 'desc';

const KeepTrackFilterItemDefn = t.intersection([
    t.type({
        field: t.string,
    }),
    t.partial({
        value: t.any,
        operator: t.string,
    }),
]);

const KeepTrackFilterDefn = t.intersection([
    t.type({
        items: t.array(KeepTrackFilterItemDefn),
    }),
    t.partial({
        linkOperator: t.union([t.literal('and'), t.literal('or')]),
    }),
]);

export interface KeepTrackFilterType extends t.TypeOf<typeof KeepTrackFilterDefn> {}

export class KeepTrackFilterType {
    public static fromRequest = getValidator<KeepTrackFilterType>(KeepTrackFilterDefn);
}

export interface KeepTrackReportTaskItemRecord
    extends Pick<TaskRecord, 'id' | 'visible_to_family'>,
        Pick<GatherCaseRecord, 'fname' | 'lname' | 'name'>,
        Pick<FuneralHomeCaseRecord, 'funeral_home_id' | 'uuid' | 'case_type' | 'case_number'> {
    key: FuneralHomeRecord['key'];
    keeptrack_key: KeeptrackCardRecord['tracker_id'];
    full_name: string;
    case_name: string;
    timezone: string;
    marked_complete_time: Date; // we don't use the underlying type here as it can be null, and not for us
    step_name: TaskRecord['title'];
    location_name: TaskLocationRecord['name'];
    performed_by_name: string;
    marked_complete_by_name: string;
    workflow_name: WorkflowRecord['name'];
    workflow_id: WorkflowRecord['id'];
    case_label_ids: number[];
    total: number;
}

export interface KeepTrackReportTaskItemUX extends KeepTrackReportTaskItemRecord {}

export interface KeepTrackStaticListItem {
    name: string;
    id: number | string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any; // catch all for sending extra stuff, used currently for the labels.
}

export interface KeepTrackStaticListItems {
    locations: KeepTrackStaticListItem[];
    workflows: KeepTrackStaticListItem[];
    labels: KeepTrackStaticListItem[];
    teams: KeepTrackStaticListItem[];
    steps: KeepTrackStaticListItem[];
}

export interface KeepTrackReportResponse extends Partial<KeepTrackStaticListItems> {
    items: KeepTrackReportTaskItemUX[];
}

export interface KeepTrackReportCSVItem {
    'Case Number': string;
    'Keeptrack #': string;
    Case: string;
    'Step Completion': string;
    'Step Name': string;
    'Location of Step': string;
    'Performed By': string;
    'Marked Complete By': string;
    'Visible to Family?': string;
    'Case Type': CaseType;
    Workflow: string;
    Labels: string;
}

export function isWhiteboardCaseUX(
    value: WhiteboardCaseUX | GatherCaseUX | GatherCaseDashboardUx,
): value is WhiteboardCaseUX {
    return (value as WhiteboardCaseUX).case_id_photo_count !== undefined;
}

export interface WhiteboardRequest {
    whiteboardCases: WhiteboardCaseUX[];
    taskLocations: TaskLocationUX[];
    caseLabels: CaseLabelUX[];
    workflows: WorkflowSummary[];
    teamMembers: EntitySummary[];
}

export enum WBFilterType {
    Labels = 'Labels',
    Workflows = 'WorkFlows',
    Locations = 'Locations',
    Assignee = 'Assignee',
    CaseFilterType = 'CaseType',
}

export const WBFilterTypeDisplayName = {
    [WBFilterType.Labels]: 'Labels',
    [WBFilterType.Workflows]: 'WorkFlows',
    [WBFilterType.Locations]: 'Locations',
    [WBFilterType.Assignee]: 'Assignee',
    [WBFilterType.CaseFilterType]: 'Case Type',
};

const WhiteBoardReorderCasesRequestDefinition = {
    caseUuidList: t.array(t.string),
};

const WhiteBoardReorderCasesRequestType = t.type(WhiteBoardReorderCasesRequestDefinition);
export interface WhiteBoardReorderCasesRequest extends t.TypeOf<typeof WhiteBoardReorderCasesRequestType> {}

export class WhiteBoardReorderCasesRequest {
    public static fromRequest = getValidator<WhiteBoardReorderCasesRequest>(WhiteBoardReorderCasesRequestType);
}

export interface GatherCasePreviewRecord
    extends Pick<GatherCaseRecord, 'id' | 'name' | 'fname' | 'lname' | 'photo_view_id'>,
        Pick<FuneralHomeCaseRecord, 'case_type'> {
    funeral_home_key: string;
    initials: string;
    full_name: string;
    photo_id: number | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
}

export interface GatherCasePreview extends GatherCasePreviewRecord {}

export interface GatherCaseName {
    id: number;
    name: string;
    fname: string;
    full_name: string;
}

export interface GatherCasePublic extends Pick<GatherCaseUX,
    'id' |
    'uuid' |
    'name' |
    'fname' |
    'lname' |
    'display_full_name' |
    'display_fname' |
    'dob_date' |
    'dod_start_date' |
    'full_name' |
    'photo' |
    'photo_transformations' |
    'obituary_content' |
    'options' |
    'link_gofundme' |
    'link_memorialvideo_youtube' |
    'link_memorialvideo_tukios' |
    'sync' |
    'desktop_cover' |
    'mobile_cover' |
    'desktop_transformations' |
    'mobile_transformations' |
    'desktop_cover_id' |
    'mobile_cover_id' |
    'theme' |
    'fingerprints_count' |
    'belongings_count' |
    'keeptrack_finalized_time' |
    'funeral_home_id' |
    'zero_expense' |
    'zero_balance'
> {
    funeral_home: FuneralHomePublic;
    assignee: PublicCaseAssignee;
}

export interface PublicCaseAssignee {
    fname: string;
    lname: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    email: string | null;
    phone: string | null;
}

export interface RememberPageResponse {
    publicCase: GatherCasePublic;
    photos: AlbumEntryMappedType[];
    giftPhotos: CaseGiftPhotoUX[];
    rememberPreviewAlbums: AlbumWithEntries;
    events: GatherEventWithPlayback[];
    locations: LocationUX[];
    caseServiceDetails: ServiceDetail[];
    publicTasks: PublicTask[];
    obituaryLinks: ObituaryLinkUX[];
    guestList: GuestListUser[];
    memories: MemoryUX[];
    flowerSales: FlowerSalesUX[];
    moderationCounts: ModerationPendingCountsForAllCases | null;
    notes: NoteResponse | null;
}

export interface UnauthenticatedRememberResponse {
    rememberPage: RememberPageResponse;
    redirect: boolean;
}

export interface AuthenticatedRememberResponse extends UnauthenticatedRememberResponse {
    gatherCase: GatherCaseUX | null;
}

export interface GatherCaseLiveStreamResponse extends StreamableEventsAndDevicesResponse {}

export interface GatherCaseWithRedirect {
    gatherCase: GatherCaseUX;
    redirect: boolean;
}

interface PublicIdAndTransformations {
    public_id: string;
    transformations: PhotoTransformationsType | null;
}

export interface CaseForAppConfigRecord
    extends Pick<
        GatherCaseRecord,
        'id' | 'fname' | 'lname' | 'name' | 'display_full_name' | 'display_fname' | 'dob_date' | 'dod_start_date'
    > {
    display_city: string | null;
    display_state: string | null;
    photo: PublicIdAndTransformations | null;
    desktop_cover: PublicIdAndTransformations | null;
    mobile_cover: PublicIdAndTransformations | null;
    theme_primary_color: string | null;
    obituary_text: string | null;
    funeral_home_id: number;
    funeral_home: Pick<FuneralHomeRecord, 'id' | 'key' | 'name' | 'website_url'>;
    fh_city: string;
    fh_state: string;
    fh_photo: PublicIdAndTransformations | null;
    fh_icon: PublicIdAndTransformations | null;
    fh_theme_photo: PublicIdAndTransformations | null;
    fh_full_remember_page: boolean;
    events: EventForAppConfigRecord[];
}

export interface CaseForAppConfig extends Omit<CaseForAppConfigRecord, 'events'> {
    events: EventForAppConfig[];
}

export interface RecentGatherCasesResponse {
    recentCases: GatherCaseDashboardUx[];
    unattachedPolicies: InsurancePolicySummaryUX[];
    totalCaseCount: number | null;
}

export enum CaseExportVendor {
    messenger = 'messenger',
}

export const isCaseExportVender = (s: string | null): s is CaseExportVendor => s && CaseExportVendor[s];

export const isGatherCaseUX = (gatherCaseObj: object): gatherCaseObj is GatherCaseUX => {
    const gatherCase = gatherCaseObj as GatherCaseUX;
    return 'dc_informant' in gatherCase && gatherCase.dc_informant !== undefined;
};

export interface CaseIdPhotoUX {
    s3_file_id: number;
}

export interface TrackingPageResponse {
    workflow: WorkflowUX | null;
    tasks: CaseTaskUX[];
    workflowChangeHistory: WorkflowChangeSummary[];
    fingerprints: FingerprintUX[];
    belongings: CaseBelongingUX[];
    idPhotos: CaseIdPhotoUX[];
    caseDocs: DocUX[];
}

export interface OrganizePageResponse {
    workflow: WorkflowUX | null;
    tasks: CaseTaskUX[];
    caseDocs: DocUX[];
    helpers: EntitySummary[];
    guestList: GuestListUser[];
    fingerprints: FingerprintUX[];
    caseEvents: GatherEvent[];
}

export interface UpdateCaseResponse {
    gatherCase: GatherCaseUX;
    tasks?: CaseTaskUX[];
    workflow?: WorkflowUX | null;
    workflowChangeHistory?: WorkflowChangeSummary[];
}

export enum GlobalDialog {
    MySettings = 'my-settings',
}

export enum RememberDialog {
    Keepsake = 'keepsake',
}

export enum CaseDialog {
    HelpersReport = 'helpers-report',
    Moderation = 'moderation',
    QrInstructions = 'qr-instructions',
}

export const isGlobalDialog = enumTypeGuard(GlobalDialog);
export const isCaseDialog = enumTypeGuard(CaseDialog);
export const isRememberDialog = enumTypeGuard(RememberDialog);

// TODO: JJT (AKT) convert these to hooks that use ReactRouter useSearchParams()
export const buildQueryParamForGlobalDialog = (dialog: GlobalDialog) => {
    return { [ConsumableSearchParam.open]: dialog };
};

export const buildQueryParamForCaseDialog = (dialog: CaseDialog) => {
    return { [ConsumableSearchParam.open]: dialog };
};

export const buildQueryParamForRememberDialog = (dialog: RememberDialog) => {
    return { [ConsumableSearchParam.open]: dialog };
};

export enum RememberStoreCTA {
    ObitToFuneral = 'obit-fh',
    ObitToFamily = 'obit-fam',
    ObitTrees = 'obit-trees',
    SpeedDial = 'speed-dial',
    LoginFlow = 'login-flow',
    Event = 'event',
    Memory = 'memory',
    FlowerMemory = 'flower-memory',
    FlowersAndCards = 'flowers-and-cards',
    RememberAppBar = 'remember-app-bar',
    FlowerStore = 'flower-store',
    FHWebsite = 'fh-website',
    TreeStore = 'tree-store',
    TreeAd0 = 'trees-0',
    TreeAd1 = 'trees-1',
    TreeAd2 = 'trees-2',
    TreeAd3 = 'trees-3',
    TreeAd4 = 'trees-4',
}

export const buildQueryParamForRememberStoreCTA = (cta: RememberStoreCTA) => {
    return { [ConsumableSearchParam.cta]: cta };
};

export interface CaseCheckResponse {
    redirectTo?: {
        caseName: string;
        funeralHomeKey: string;
    };
}

// ---> FuneralHomeCaseWatcherRecord <---
export interface FuneralHomeCaseWatcherRecord {
    id: number;
    funeral_home_case_id: number;
    user_profile_id: number;
    created_time: Date;
    created_by: number;
}

export interface FuneralHomeCaseWatcherRecordInsert
    extends Pick<FuneralHomeCaseWatcherRecord, 'funeral_home_case_id' | 'user_profile_id' | 'created_by'> {}

// ---> Case Test Types <---
export enum TestTypeEnum {
    TREE_SEED = 'tree_seed',
}

export interface TestType {
    testVariant: TestTypeEnum;
}

export enum TestGroup {

    A = 'A',
    B = 'B',
}

export type CaseTestRecord = {
    id: number;
    gather_case_id: number;
    test_type: TestTypeEnum;
    test_group: string;
    created_time: Date;
};

export interface CaseTestRecordInsert extends Pick<CaseTestRecord,
    'gather_case_id' |
    'test_type' |
    'test_group'
> { }


