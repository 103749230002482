import { ConfettiAction, TOGGLE_CONFETTI } from '../actions/confetti.action';

export interface ConfettiState {
    isRunning: boolean;
    timeout: number;
}

export const confettiInitState: ConfettiState = {
    isRunning: false,
    timeout: 4000 // default timeout
};

export const confettiState = (
    state: ConfettiState = confettiInitState,
    action: ConfettiAction
): ConfettiState => {
    switch (action.type) {
        case TOGGLE_CONFETTI: {
            return {
                ...state,
                isRunning: action.isRunning,
                timeout: action.timeout || confettiInitState.timeout
            };
        }
        default:
            return state;
    }
};