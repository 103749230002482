import classNames from 'classnames';
import * as appService from '../../../../../services/app.service';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Fade from '@mui/material/Fade';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountType, OnlineAccountDeactivateStep } from '../utils';

interface Props {
    caseFName: string;
    accountType: OnlineAccountType;
    steps: (JSX.Element | string)[];
    onReturnClick: (step: OnlineAccountDeactivateStep) => void;
    onAbleToLogin: (step: OnlineAccountDeactivateStep) => void;
    onUnableToLogin?: (step: OnlineAccountDeactivateStep) => void;
}

const ResetPasswordStepsContent = (props: Props) => {
    const { caseFName, accountType, steps, onReturnClick, onAbleToLogin, onUnableToLogin } = props;

    const classes = useTaskDialogStyles();

    return (
        <Fade in>
            <Grid>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    <span
                        className={classes.cursorPointer}
                        onClick={() => onReturnClick(OnlineAccountDeactivateStep.AccessToEmailQueType)}
                    >
                        <IconButton color="primary" size="large">
                            <UndoIcon />
                        </IconButton>
                        Return to previous question
                    </span>
                </Typography>
                <Typography
                    align="center"
                    color="secondary"
                    className={classNames(
                        classes.fontSize28,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    Reset {caseFName}'s {accountType} Password
                </Typography>
                <Typography
                    align="left"
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    It looks like you should be able to quickly get access
                    to {caseFName}’s {accountType} account by resetting their password.
                    These instructions will walk you through this process.
                </Typography>
                <ul
                    className={classNames(
                        classes.fontSize18,
                        classes.colorSecondary,
                        classes.textLeft,
                        classes.fontWeight300
                    )}
                >
                    {steps.map((step, i) =>
                        <li key={i} className={classes.marginTop10}>
                            <span className={classes.fontWeight500}>
                                {`Step ${i + 1}: `}
                            </span>
                            {step}
                        </li>
                    )}
                </ul>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    {`If you were able to reset ${caseFName}’s ${accountType} password, you can `}
                    <span
                        className={classNames(classes.textClickable, classes.textCapitalize)}
                        onClick={() => onAbleToLogin(OnlineAccountDeactivateStep.CanLoginType)}
                    >
                        {'click here'}
                    </span>
                    {' to continue.'}
                </Typography>
                {onUnableToLogin &&
                    <Typography
                        color="secondary"
                        align="left"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        {`If you were NOT able to reset ${caseFName}'s ${accountType} password, you can `}
                        <span
                            className={classes.textClickable}
                            onClick={() => onUnableToLogin(OnlineAccountDeactivateStep.CanNotLoginType)}
                        >
                            {'click here'}
                        </span>
                        {' to continue.'}
                    </Typography>
                }

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    Did this not work?
                    <span
                        className={
                            classNames(
                                classes.cursorPointer,
                                classes.fontWeight500
                            )
                        }
                        onClick={appService.openInterCom}
                    >
                        {' Click here for help'}
                    </span>
                </Typography>
            </Grid >
        </Fade>
    );
};

export default ResetPasswordStepsContent;