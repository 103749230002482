import {
    TaskComponentUX,
    TaskComponentSaveRequest,
    taskComponentSaveRequestFromRequest,
    TaskComponentUpdateRankRequest,
} from '../../shared/types';
import { getFromAPI, postToAPI, deleteFromAPI, patchAPI } from '..';
import { registerAppError } from '../errors';
import { AppDispatch } from '../../store';
import { log } from '../../logger';

// ----> Updating Task Component <----
export const UPDATING_TASK_COMPONENT = 'UPDATING_TASK_COMPONENT';

interface UpdatingTaskComponent {
    type: typeof UPDATING_TASK_COMPONENT;
    taskComponentId: number;
    changes: Partial<TaskComponentUX>;
}

function updatingTaskComponent(taskComponentId: number, changes: Partial<TaskComponentUX>): UpdatingTaskComponent {
    return {
        type: UPDATING_TASK_COMPONENT,
        taskComponentId,
        changes,
    };
}

export function updateTaskComponent(params: {
    taskComponentId: number;
    taskTemplateId: number;
    changes: TaskComponentSaveRequest;
    funeralHomeId: number;
}) {
    return async (dispatch: AppDispatch): Promise<TaskComponentUX[] | null> => {
        const { taskComponentId, taskTemplateId, changes, funeralHomeId } = params;
        try {
            taskComponentSaveRequestFromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate TaskComponentSaveRequest', { changes, ex });
            return null;
        }

        dispatch(updatingTaskComponent(taskTemplateId, changes));
        const route = `funeralhome/${funeralHomeId}/tasktemplate/${taskTemplateId}/component/${taskComponentId}`;
        const taskComponents = await patchAPI<TaskComponentUX[]>(route, { taskComponent: changes }, dispatch);
        if (taskComponents) {
            dispatch(loadedTaskComponents({
                taskTemplateId,
                taskComponents,
            }));
            return taskComponents;
        }
        dispatch(registerAppError('Unable to update component.'));
        return null;
    };
}

// ----> Create Task Component <----
export const CREATING_TASK_COMPONENT = 'CREATING_TASK_COMPONENT';

interface CreatingTaskComponent {
    type: typeof CREATING_TASK_COMPONENT;
    taskComponentRequest: TaskComponentSaveRequest;
}

function creatingTaskComponent(taskComponentRequest: TaskComponentSaveRequest): CreatingTaskComponent {
    return {
        type: CREATING_TASK_COMPONENT,
        taskComponentRequest,
    };
}

export function createTaskComponent(params: {
    taskComponent: TaskComponentSaveRequest;
    taskTemplateId: number;
    funeralHomeId: number;
}) {
    return async (dispatch: AppDispatch): Promise<TaskComponentUX[] | null> => {
        const { taskComponent, taskTemplateId, funeralHomeId } = params;
        try {
            taskComponentSaveRequestFromRequest(taskComponent);
        } catch (ex) {
            log.warn('Failed to validate TaskComponentSaveRequest:', { taskComponent, ex });
            return null;
        }

        dispatch(creatingTaskComponent(taskComponent));
        const route = `funeralhome/${funeralHomeId}/tasktemplate/${taskTemplateId}/component`;
        const taskComponents = await postToAPI<TaskComponentUX[]>(route, { taskComponent }, dispatch);
        if (taskComponents) {
            dispatch(loadedTaskComponents({
                taskTemplateId,
                taskComponents,
            }));
            return taskComponents;
        } else {
            dispatch(registerAppError('Unable to add new component.'));
            return null;
        }
    };
}

// ----> Load Task Components <----
export const LOADING_TASK_COMPONENTS = 'LOADING_TASK_COMPONENTS';

interface LoadingTaskComponents {
    type: typeof LOADING_TASK_COMPONENTS;
}

function loadingTaskComponents(): LoadingTaskComponents {
    return {
        type: LOADING_TASK_COMPONENTS,
    };
}

export const LOADED_TASK_COMPONENTS = 'LOADED_TASK_COMPONENTS';

export interface LoadedTaskComponents {
    type: typeof LOADED_TASK_COMPONENTS;
    taskTemplateId: number;
    taskComponents: TaskComponentUX[];
}

function loadedTaskComponents(params: {
    taskTemplateId: number;
    taskComponents: TaskComponentUX[];
}): LoadedTaskComponents {
    return {
        ...params,
        type: LOADED_TASK_COMPONENTS,
    };
}

export const LOADING_TASK_COMPONENTS_FAILED = 'LOADING_TASK_COMPONENTS_FAILED';

interface LoadingTaskComponentsFailed {
    type: typeof LOADING_TASK_COMPONENTS_FAILED;
}

function loadingTaskComponentsFailed(): LoadingTaskComponentsFailed {
    return {
        type: LOADING_TASK_COMPONENTS_FAILED,
    };
}

export function loadTaskComponents(params: {
    funeralHomeId: number;
    taskTemplateId: number;
}) {
    return async (dispatch: AppDispatch) => {
        const { funeralHomeId, taskTemplateId } = params;
        dispatch(loadingTaskComponents());
        const route = `funeralhome/${funeralHomeId}/tasktemplate/${taskTemplateId}/component/`;
        const taskComponents = await getFromAPI<TaskComponentUX[]>(route, dispatch);
        if (taskComponents) {
            dispatch(loadedTaskComponents({
                taskTemplateId,
                taskComponents,
            }));
        } else {
            dispatch(loadingTaskComponentsFailed());
            dispatch(registerAppError('Unable to load components.'));
        }
    };
}

// ----> Delete Task Component <----
export const DELETING_TASK_COMPONENT = 'DELETING_TASK_COMPONENT';

interface DeletingTaskComponent {
    type: typeof DELETING_TASK_COMPONENT;
    idToRemove: number;
}

function deletingTaskComponent(idToRemove: number): DeletingTaskComponent {
    return {
        type: DELETING_TASK_COMPONENT,
        idToRemove,
    };
}

export function deleteTaskComponent(params: {
    taskComponentId: number;
    taskTemplateId: number;
    funeralHomeId: number;
}) {
    return async (dispatch: AppDispatch) => {
        const { taskComponentId, taskTemplateId, funeralHomeId } = params;
        dispatch(deletingTaskComponent(taskComponentId));
        const route = `funeralhome/${funeralHomeId}/tasktemplate/${taskTemplateId}/component/${taskComponentId}`;
        const taskComponents = await deleteFromAPI<TaskComponentUX[]>(route, dispatch);
        if (taskComponents) {
            dispatch(loadedTaskComponents({
                taskTemplateId,
                taskComponents,
            }));
            return taskComponents;
        }
        dispatch(registerAppError('Unable to remove component.'));
        return null;
    };
}

export function updateTaskComponentRanks(params: {
    taskComponentIds: number[];
    taskTemplateId: number;
    funeralHomeId: number;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { taskTemplateId, funeralHomeId, taskComponentIds } = params;

        const updateRequest: TaskComponentUpdateRankRequest = {
            task_component_ids: taskComponentIds,
        };

        try {
            TaskComponentUpdateRankRequest.fromRequest(updateRequest);
        } catch (ex) {
            log.warn('Failed to validate TaskComponentUpdateRankRequest:', { updateRequest, ex });
            return;
        }

        const route = `funeralhome/${funeralHomeId}/tasktemplate/${taskTemplateId}/component/rank`;
        const response = await postToAPI<void>(route, updateRequest, dispatch);
        if (response === null) {
            dispatch(registerAppError('Unable to update step.'));
        }
    };
}

export type TaskComponentAction =
    | CreatingTaskComponent
    | UpdatingTaskComponent
    | DeletingTaskComponent
    | LoadingTaskComponents
    | LoadingTaskComponentsFailed
    | LoadedTaskComponents
    ;
