import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { deburr } from 'lodash';
import classNames from 'classnames';
import { useCallback } from 'react';
import  makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    textFieldPadding: {
        padding: '12px 14px',
    },
    option: {
        padding: '11px 16px !important'
    }
}), { name: 'GAutocomplete' });

export type SuggestionType<T> = {
    value: string;
    key: T;
};

interface GAutocompleteProps<T extends string | number> extends OutlinedTextFieldProps {
    selectedKey: T | null;
    inputValue: string;
    suggestions: SuggestionType<T>[];
    loading?: boolean;
    size?: 'small';
    popperClassName?: string;
    listboxClassName?: string;
    onSelectItem: (suggestion: SuggestionType<T>) => void;
}

function InputField<T extends string | number>(
    props: Omit<GAutocompleteProps<T>, 'onSelectItem' | 'suggestions'>
) {
    const { InputProps, inputValue, selectedKey, size, ...other } = props;

    const classes = useStyles();

    return (
        <TextField
            InputProps={{
                classes: {
                    notchedOutline: 'notranslate',
                    root: classes.inputRoot,
                    input: classNames(classes.inputInput, size && size === 'small' && classes.textFieldPadding)
                },
                ...InputProps
            }}
            {...other}
            value={inputValue}
        />
    );
}

const GAutocomplete = <T extends string | number>({
    suggestions,
    popperClassName,
    listboxClassName,
    loading,
    onChange,
    onSelectItem,
    ...others
}: GAutocompleteProps<T>) => {
    const classes = useStyles();

    const filterSuggestions = useCallback((value: string | null, options: SuggestionType<T>[]): SuggestionType<T>[] => {
        const deburredInputValue = deburr((value || '').trim()).toLowerCase();
        const inputLength = deburredInputValue.length;

        return inputLength === 0
            ? options
            : options.filter(option => option.value.toLowerCase().includes(deburredInputValue));
    }, []);

    return (
        <Autocomplete
            freeSolo
            fullWidth
            blurOnSelect
            disableClearable
            disablePortal
            renderInput={(params) => <InputField
                onChange={onChange} // changing the input value on typing in input-box
                {...others}
                {...params}
            />}
            componentsProps={{
                paper: { square: true },
                popper: {
                    modifiers: [{
                        name: 'offset',
                        options: { offset: [0, 8] }
                    }, {
                        name: 'flip',
                        options: {
                            flipVariations: false,
                            allowedAutoPlacements: []
                        }
                    }],
                    placement: 'bottom',
                    style: { zIndex: 1 },
                }
            }}
            loading={loading}
            value={others.inputValue}
            inputValue={others.inputValue}
            classes={{ listbox: listboxClassName, popper: popperClassName, option: classes.option }}
            options={suggestions}
            filterOptions={(options) => filterSuggestions(others.inputValue, options)}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.value}
            // changing the input when an option is selected
            onChange={(evt, option) => typeof option !== 'string' && option && onSelectItem(option)}
        />
    );
};

export default GAutocomplete;
