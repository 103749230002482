import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import withGStyles from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    cursorDefault: {
        cursor: 'Default'
    },
    marginLeft4: {
        marginLeft: 4
    }
});

type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'cursorDefault' | 'marginLeft4'>;

interface Props {
    showMe?: boolean;
    useAdornmentComponent?: boolean;
}

const HiddenFromFamilyAdornment = ({ showMe, useAdornmentComponent, classes }: Props & StyledProps) => {
    const tooltipTitle = 'This field is not visible to the family. Hide this from your ' +
        'screen by enabling Family View mode. Your Account Manager can adjust your DC as needed.';

    const content = (
        <Tooltip
            title={tooltipTitle}
            placement="top"
            enterDelay={600}
        >
            <VisibilityOffIcon color="primary" className={classes.cursorDefault} />
        </Tooltip>
    );

    if (!showMe) {
        return null;
    }

    if (useAdornmentComponent) {
        return (
            <InputAdornment position="end" className={classes.marginLeft4}>
                {content}
            </InputAdornment>
        );
    }

    return content;
};

export default withGStyles(styles)(HiddenFromFamilyAdornment);