import * as React from 'react';
import { useLocation } from 'react-router';
import { Subtract } from 'utility-types';
import { Location } from 'history';

type LocationObj = { location: Location };

function withLocation<Props extends LocationObj>(
    WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Subtract<Props, LocationObj>> {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithLocation = (props: Subtract<Props, LocationObj>): JSX.Element => {
        const location = useLocation();
        return <WrappedComponent {...(props as Props)} location={location} />;
    };
    ComponentWithLocation.displayName = `withLocation(${displayName})`;

    return ComponentWithLocation;
}

export default withLocation;
