import * as React from 'react';
import * as classNames from 'classnames';

import { FH_LogoType } from '../../../../shared/types';
import FuneralHomePopper from '../../../common/FuneralHomePopper';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import withGStyles from '../../../../styles/WithGStyles';
import { AppBar, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '@media (max-width: 600px)': {
            minHeight: '52px !important',
            height: '100%'
        }
    },
    backIcon: {
        position: 'absolute',
        left: 2,
        top: '50%',
        transform: 'translateY(-50%)',
        '& svg': {
            fontSize: 28
        },
    },
    contactButton: {
        position: 'absolute',
        top: '50%',
        right: 8,
        transform: 'translateY(-50%)',
        borderColor: theme.palette.common.white,
        '@media (max-width: 450px)': {
            fontSize: '10px',
            padding: '0 10px',
        },
        '@media (max-width: 350px)': {
            fontSize: '8px',
            padding: 0
        }
    },
    logo: {
        height: '100%',
        '@media (maxwidth: 767.95px)': {
            maxHeight: '72px !important',
            paddingBottom: 8
        },
        '@media (max-width: 600px)': {
            maxWidth: '150px !important',
        }
    },
    fhLink: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 'fitcontent',
        margin: 'auto',
        '@media (maxwidth: 767.95px)': {
            marginTop: 60
        }
    }
});

type Classes = 'root' | 'backIcon' | 'contactButton' | 'logo' | 'fhLink';
type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<Classes>;

interface Props {
    fhWebsiteURL: string | null;
    zIndex: number;
}
interface State {
    fhPopperAnchorEl: HTMLElement | null;
}
class PublicGPLAppBar extends React.Component<Props & StyledProps, State> {
    state: State = {
        fhPopperAnchorEl: null
    };

    renderFHPopper = () => {
        const { zIndex } = this.props;
        const { fhPopperAnchorEl } = this.state;

        return (
            <FuneralHomePopper
                zIndex={zIndex + 1}
                popperAnchorEle={fhPopperAnchorEl}
                isGPLView={true}
                closePopper={this.closeFhPopper}
            />
        );
    };

    render() {
        const { classes, fhWebsiteURL, zIndex } = this.props;

        return (
            <AppBar position="relative" className={classes.root} style={{ zIndex }}>
                <IconButton className={classes.backIcon} onClick={e => history.back()}>
                    <ArrowBackIcon className={classes.colorWhite} />
                </IconButton>

                <a
                    href={fhWebsiteURL || ''}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                        !fhWebsiteURL && classes.pointerEventsNone,
                        classes.fhLink
                    )}
                >
                    <FuneralHomeLogo
                        logoSize="large"
                        logoType={FH_LogoType.themeLogo}
                        className={classes.logo}
                        style={{ maxHeight: 100, maxWidth: 320 }}
                    />
                </a>

                <Button
                    variant="outlined"
                    className={classNames(classes.contactButton, classes.colorWhite)}
                    onClick={this.openFhPopper}
                >
                    Contact Us
                </Button>

                {this.renderFHPopper()}
            </AppBar>
        );
    }

    closeFhPopper = () => this.setState({ fhPopperAnchorEl: null });

    openFhPopper: React.MouseEventHandler<HTMLElement> = (event) =>
        this.setState({ fhPopperAnchorEl: event.currentTarget });
}

export default withGStyles(styles)(PublicGPLAppBar);