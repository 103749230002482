import classNames from 'classnames';

import Typography, { TypographyProps } from '@mui/material/Typography';
import makeGStyles from '../../../../styles/makeGStyles';
import { getIntercomTargetProp } from '../../../../services';

const useStyles = makeGStyles((theme) => ({
    root: {},
    price: {
        textAlign: 'center',
        textTransform: 'capitalize',
        lineHeight: '30px',
        letterSpacing: '0em',
        marginBottom: 8,
        marginRight: 4,
        '&$xsmall': {
            display: 'inline',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '16px',
            marginBottom: 0,
            marginRight: 0,
            '& $cents': {
                fontSize: 10,
                fontWeight: '400'
            }
        },
        '&$small': {
            fontSize: 24,
            fontWeight: '200',
            lineHeight: '16px',
            '& $cents': {
                fontSize: 12,
            }
        },
        '&$medium': {
            fontSize: 30,
            fontWeight: '200',
            '& $cents': {
                fontSize: 14,
            }
        },
        '&$large': {
            fontSize: 42,
            fontWeight: '400',
            '& $cents': {
                fontSize: 18,
            }
        },
        '& $cents': {
            lineHeight: '1em',
            textDecoration: 'underline',
            marginLeft: 4,
            verticalAlign: 'super',
            '& font': {
                verticalAlign: 'unset !important'
            }
        },
        '& $sign': {
            textDecoration: 'unset',
            marginRight: 2
        }
    },
    cents: {},
    sign: {},
    xsmall: {},
    small: {},
    medium: {},
    large: {},
}), { name: 'PriceWidget' });

interface Props {
    priceStr: string;
    size: 'xsmall' | 'small' | 'medium' | 'large';
    color?: TypographyProps['color'];
    isPrintMode?: boolean;
    customClasses?: {
        customSign?: string;
        price?: string;
        cents?: string;
    };
    isShowDecimal?: boolean;
    isPostive?: boolean;
    intercomTargetPropGandS?: string;
}

const PriceWidget = (props: Props) => {

    const {
        priceStr,
        color,
        size,
        customClasses,
        isShowDecimal,
        isPrintMode,
        isPostive,
        intercomTargetPropGandS
    } = props;

    const classes = useStyles();

    if (!priceStr) {
        return null;
    }
    const [dollarStr, centsStr] = isShowDecimal ? priceStr.split(' ') : priceStr.split('.');

    const firstChar = dollarStr.substring(0, 1);
    const isNegative = firstChar === '-';
    const dollarStrSign = isNegative ? dollarStr.substring(1, 2) : firstChar;
    const dollarStrValue = dollarStr.substring(isNegative ? 2 : 1);

    return (
        <Typography
            color={color ? color : 'inherit'}
            className={classNames(
                classes.price,
                classes[size],
                customClasses && customClasses.price,
                'notranslate'
            )}
            style={{
                margin: isPrintMode ? 0 : undefined
            }}
        >
            {priceStr.includes('Price') ?
                <span>{priceStr}</span> :
                <>
                    {isNegative && '-' || isPostive && '+'}
                    <span
                        className={classNames(
                            classes.cents,
                            classes.sign,
                            customClasses && customClasses.cents,
                            customClasses && customClasses.customSign
                        )}
                        style={{
                            fontSize: isPrintMode ? 14 : undefined,
                            verticalAlign: isPrintMode ? 'super' : undefined,
                            fontWeight: isPrintMode ? 300 : undefined
                        }}
                        {...getIntercomTargetProp(intercomTargetPropGandS)}
                    >
                        {dollarStrSign}
                    </span>
                    <span>{dollarStrValue}</span>
                    <span
                        className={classNames(
                            classes.cents,
                            customClasses && customClasses.cents
                        )}
                    >
                        {centsStr}
                    </span>
                </>
            }
        </Typography>
    );
};

export default PriceWidget;
