import {
    ProductCategory,
    ProductRequest,
    PaginatedResponse,
    ProductSummary,
    ProductUX,
    ProductRecord,
} from '../../shared/types';
import { registerAppError } from '../errors';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '..';
import { setSnackbarSuccess } from '../AppSnackbar.action';
import { AppDispatch } from '../../store';

const productSnackbarSuccess = (msg: string) => setSnackbarSuccess(msg, undefined, 1500);

// GLOBAL_PRODUCTS_LOADING
export const GLOBAL_PRODUCTS_LOADING = 'GLOBAL_PRODUCTS_LOADING';
interface GlobalProductsLoading {
    type: typeof GLOBAL_PRODUCTS_LOADING;
    category: ProductCategory;
    offset: number;
    searchText: string;
    sortBy: keyof ProductSummary;
    sortDirection: 'asc' | 'desc';
}
function globalProductsLoading(
    category: ProductCategory,
    offset: number,
    searchText: string,
    sortBy: keyof ProductSummary,
    sortDirection: 'asc' | 'desc',
): GlobalProductsLoading {
    return {
        type: GLOBAL_PRODUCTS_LOADING,
        category,
        offset,
        searchText,
        sortBy,
        sortDirection,
    };
}

// GLOBAL_PRODUCTS_LOADED
export const GLOBAL_PRODUCTS_LOADED = 'GLOBAL_PRODUCTS_LOADED';
interface GlobalProductsLoaded {
    type: typeof GLOBAL_PRODUCTS_LOADED;
    products: ProductSummary[];
    hasMoreData: boolean;
    offset: number;
}
export function globalProductsLoaded(
    products: ProductSummary[],
    hasMoreData: boolean,
    offset: number
): GlobalProductsLoaded {
    return {
        type: GLOBAL_PRODUCTS_LOADED,
        products,
        hasMoreData,
        offset,
    };
}

// GLOBAL_PRODUCTS_FAILED_TO_LOAD
export const GLOBAL_PRODUCTS_FAILED_TO_LOAD = 'GLOBAL_PRODUCTS_FAILED_TO_LOAD';
interface GlobalProductsFailed {
    type: typeof GLOBAL_PRODUCTS_FAILED_TO_LOAD;
}
function globalProductsFailed(): GlobalProductsFailed {
    return {
        type: GLOBAL_PRODUCTS_FAILED_TO_LOAD,
    };
}

// ADD_GLOBAL_PRODUCT
export const ADD_GLOBAL_PRODUCT = 'ADD_GLOBAL_PRODUCT';
interface AddGlobalProduct {
    type: typeof ADD_GLOBAL_PRODUCT;
    product: ProductSummary;
}
function addGlobalProduct(product: ProductSummary): AddGlobalProduct {
    return {
        type: ADD_GLOBAL_PRODUCT,
        product,
    };
}

// UPDATE_GLOBAL_PRODUCT
export const UPDATE_GLOBAL_PRODUCT = 'UPDATE_GLOBAL_PRODUCT';
interface UpdateGlobalProduct {
    type: typeof UPDATE_GLOBAL_PRODUCT;
    product: ProductSummary;
}
function updateGlobalProductInStore(product: ProductSummary): UpdateGlobalProduct {
    return {
        type: UPDATE_GLOBAL_PRODUCT,
        product,
    };
}

// REMOVE_GLOBAL_PRODUCT
export const REMOVE_GLOBAL_PRODUCT = 'REMOVE_GLOBAL_PRODUCT';
interface RemoveGlobalProduct {
    type: typeof REMOVE_GLOBAL_PRODUCT;
    productId: number;
}
function removeGlobalProduct(productId: number): RemoveGlobalProduct {
    return {
        type: REMOVE_GLOBAL_PRODUCT,
        productId,
    };
}

export function loadGlobalProducts(
    category: ProductCategory,
    offset: number,
    searchText: string,
    sortBy: keyof ProductSummary,
    sortDirection: 'asc' | 'desc',
    limit?: number,
) {
    return async (dispatch: AppDispatch): Promise<ProductSummary[] | null> => {

        let route = `api/product/category/${category}?offset=${offset}` +
            `&sortBy=${encodeURI(sortBy)}&sortDir=${sortDirection}`;

        if (searchText) {
            route += `&search=${encodeURI(searchText)}`;
        }
        if (limit) {
            route += `&limit=${limit}`;
        }

        dispatch(globalProductsLoading(category, offset, searchText, sortBy, sortDirection));

        const response = await getFromAPI<PaginatedResponse<ProductSummary>>(route, dispatch);
        if (response) {
            dispatch(globalProductsLoaded(response.data, response.hasMoreData, offset));
            return response.data;
        }
        dispatch(globalProductsFailed());
        dispatch(registerAppError('Unable to load available products.'));
        return null;
    };
}

export function getGlobalProduct(productId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        const product = await getFromAPI<ProductUX>(`api/product/${productId}`, dispatch);
        if (product) {
            return product;
        }
        dispatch(registerAppError('Unable to load product.'));
        return null;
    };
}

export function createGlobalProduct(product: ProductRequest) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        try {
            ProductRequest.fromRequest(product);
        } catch (ex) {
            console.warn('Failed to validate ProductRequest:', product, ex);
            return null;
        }
        const createdProduct = await postToAPI<ProductUX>('api/product/', { product }, dispatch);
        if (createdProduct) {
            dispatch(addGlobalProduct(createdProduct));
            dispatch(productSnackbarSuccess('Product has been created'));
            return createdProduct;
        }
        dispatch(registerAppError('Unable to add product.'));
        return null;
    };
}

export function updateGlobalProduct(productId: number, product: ProductRequest) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        try {
            ProductRequest.fromRequest(product);
        } catch (ex) {
            console.warn('Failed to validate ProductRequest:', product, ex);
            return null;
        }
        const updatedProduct = await putToAPI<ProductUX>(
            `api/product/${productId}`, { product }, dispatch
        );
        if (updatedProduct) {
            dispatch(updateGlobalProductInStore(updatedProduct));
            dispatch(productSnackbarSuccess('Product has been updated'));
            return updatedProduct;
        }
        dispatch(registerAppError('Unable to update product.'));
        return null;
    };
}

export function deleteGlobalProduct(productId: number) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        dispatch(removeGlobalProduct(productId));
        const deletedProduct = await deleteFromAPI<ProductRecord>(`api/product/${productId}`, dispatch);
        if (deletedProduct) {
            dispatch(productSnackbarSuccess('Product has been deleted'));
            return true;
        }
        dispatch(registerAppError('Unable to remove product.'));
        return false;
    };
}

export type GlobalProductAction =
    | GlobalProductsLoading
    | GlobalProductsLoaded
    | GlobalProductsFailed
    | AddGlobalProduct
    | UpdateGlobalProduct
    | RemoveGlobalProduct
    ;
