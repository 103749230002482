import { FeatureKey } from "../../../shared/types";
import { useGSelector } from "../../../types";
import { isEnabled } from "../AppFeature";

const useFeatureEnabled = (feature: FeatureKey): boolean => {
    const casesState = useGSelector((storeState) => storeState.casesState);
    const funeralHomeState = useGSelector((storeState) => storeState.funeralHomeState);
    const userSession = useGSelector((storeState) => storeState.userSession);

    const isFeatureEnabled = isEnabled(feature, funeralHomeState, casesState, userSession);
    return isFeatureEnabled;
};

export default useFeatureEnabled;
