import makeGStyles from "../../../../../../../styles/makeGStyles";
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RED_COLOR } from "../../../../../../../constants/colorVariables";
import classNames from "classnames";

const useStyles = makeGStyles({
    scrollIndicator: {
        zIndex: 4,
        position: 'absolute',
        right: -8,
        margin: 0,
        pointerEvents: 'none',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: RED_COLOR,
            textTransform: 'uppercase',
            padding: '4px 0 0 4px',
            paddingBottom: 4,
            fontWeight: 500,
        },
    },
    inner: {
        background: '#ffff25',
        padding: '0 12px 0 8px',
        borderRadius: 6,
        filter: 'drop-shadow(0px 2px 0px rgba(0,0,0,0.2))',
        display: 'flex',
        alignItems: 'center',
        height: 32,
    },
    arrow: {
        width: 0,
        height: 0,
        borderTop: '8px solid transparent !important',
        borderRight: '8px solid #fff',
        borderBottom: '8px solid transparent !important',
        position: 'absolute',
        left: -7,
        background: 'transparent',
        filter: 'drop-shadow(0px 1px 0px rgba(0,0,0,0.2))',
        borderRightColor: '#ffff25',
    },
}, { name: 'SignHereTag' });

interface Props {
    title: string;
    hideTag: boolean;
    required?: boolean;
}

const SignHereTag = (props: Props) => {
    const { hideTag, title, required } = props;
    const classes = useStyles();

    return (
        <Fade in={!hideTag}>
            <Grid
                item
                className={classes.scrollIndicator}
            >
                <Grid item className={classNames(classes.inner)}>
                    <div className={classes.arrow} />
                    <Typography color="primary" component="p">
                        {title}{required && ' *'}
                    </Typography>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default SignHereTag;
