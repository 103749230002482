import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        pointerEvents: 'none',
        opacity: 0,
        transform: 'scale(0)',
        transition: 'transform 300ms ease-in, height 300ms ease-in, opacity 300ms ease-in, width 300ms ease-in'
    }
});

export interface Props {
    children: React.ReactNode;
}

export interface State {
    height: number;
}

type StyledProps = Props & WithStyles<'root'>;

class SlideZoomListTransition extends React.Component<StyledProps, State> {

    contentElement: HTMLElement | null = null;
    height: number = 0;
    width: number = 0;

    constructor(props: StyledProps) {
        super(props);
        this.state = {
            height: 0
        };
    }

    render() {
        const {
            classes,
            ...props
        } = this.props;

        return (
            <CSSTransition
                {...props}
                appear
                className={classes.root}
                classNames="slide-zoom-list-transition"
                timeout={300}
                // unmountOnExit
                onEnter={(node, appearing) => {
                    node.style.opacity = '0';
                    node.style.pointerEvents = 'none';
                    node.style.height = 'auto';
                    node.style.width = 'auto';
                    this.height = node.offsetHeight;
                    this.width = node.offsetWidth;
                    node.style.height = '0px';
                    node.style.transform = 'scale(0)';
                }}
                onEntering={(node, appearing) => {
                    node.style.height = `${this.height}px`;
                    node.style.width = `${this.width}px`;
                    // on enter, we slide first then zoom
                    setTimeout(
                        () => {
                            node.style.transform = 'scale(1)';
                            node.style.pointerEvents = 'auto';
                            node.style.opacity = '1';
                        },
                        300
                    );

                }}
                onEntered={(node, appearing) => {
                    // should set height back to auto here
                    node.style.height = 'auto';
                    node.style.width = 'auto';
                    node.style.pointerEvents = 'auto';
                    node.style.opacity = '1';
                }}
                onExit={(node) => {
                    node.style.pointerEvents = 'auto';
                    node.style.height = `${node.offsetHeight}px`;
                    node.style.width = `${node.offsetWidth}px`;
                    node.style.transform = 'scale(1)';
                    node.style.opacity = '1';
                }}
                onExiting={(node) => {
                    node.style.opacity = '0';
                    node.style.pointerEvents = 'none';
                    node.style.transform = 'scale(0)';
                    // on exit we zoom first then slide
                    setTimeout(
                        () => {
                            node.style.height = '0px';
                            node.style.width = '0px';
                        },
                        300
                    );
                }}
                onExited={node => {
                    node.style.pointerEvents = 'none';
                    node.style.transform = 'scale(0)';
                    node.style.height = '0px';
                    node.style.opacity = '0';
                    node.style.width = '0px';
                }}
            >
                <div>{props.children}</div>
            </CSSTransition>

        );
    }
}

export default withGStyles(styles)(SlideZoomListTransition);
