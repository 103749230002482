import { useState } from 'react';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductUX } from '../../../../../shared/types';
import { useGDispatch } from '../../../../../types';
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import ShowHideProductDetails from "./ShowHideProductDetails";

interface ShowHidePriceOnWebsiteProps {
    selectButton: BulkEditButtonType | null;
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const ShowHidePriceOnWebsite = (props: ShowHidePriceOnWebsiteProps) => {

    const { selectButton, selectedProductIds, onSubmitClick, funeralHomeId } = props;
    const dispatch = useGDispatch();
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitclick = async (isShowSelected: boolean) => {
        // call api for show hide price on website
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.show_hide_price_on_website,
            funeralHomeId, 
            productIds: selectedProductIds, 
            changes: {show_price_on_website: isShowSelected},
        }) );
        setIsSpinning(false);
        onSubmitClick(updatedProducts);

    };

    return (
        <ShowHideProductDetails
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            onSubmitClick={handleSubmitclick}
            isSpinning={isSpinning}
        />
    );
};

export default ShowHidePriceOnWebsite;
