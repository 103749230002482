import * as constants from '../constants';
import * as appService from '../services/app.service';
import { StoreState } from '../types';
import {
    putToAPI,
    getFromAPI,
    postToAPI,
    setDeepLinkInviationStatus,
    evaluateDeepLink,
} from '.';

import { setAppSnackbar } from './AppSnackbar.action';

import {
    GatherData,
    SessionResult,
    UserProfile,
    ResetCodeReason,
} from '../shared/types';
import { AppDispatch } from '../store';
import { isRememberPage } from '../services';

let authToken: string = '';

export function getAuthToken() {
    return authToken;
}

export interface SetPasswordResetPending {
    type: constants.SET_PASSWORD_RESET_PENDING;
    isPending: boolean;
}

function setPasswordResetPending(isPending: boolean): SetPasswordResetPending {
    return {
        type: constants.SET_PASSWORD_RESET_PENDING,
        isPending,
    };
}

export interface SetPasswordResetMessage {
    type: constants.SET_PASSWORD_RESET_MESSAGE;
    message: string;
}

function setPasswordResetMessage(message: string): SetPasswordResetMessage {
    return {
        type: constants.SET_PASSWORD_RESET_MESSAGE,
        message,
    };
}

export const clearPasswordResetForm = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setPasswordResetPending(false));
        dispatch(setPasswordResetMessage('NOTSENT'));
    };
};

export const sendPasswordResetRequest = (email: string | null, phone: string | null) => {
    return async (dispatch: AppDispatch) => {
        // If the API returns {message: ''} then password reset was a success
        // Otherwise, the message will indicate some sort of error or issue
        dispatch(setPasswordResetPending(true));
        dispatch(setPasswordResetMessage('NOTSENT'));
        const result = await postToAPI<{ message: string }>('pwreset', { email, phone }, dispatch);
        if (result === null) {
            dispatch(setPasswordResetMessage('Sorry, looks like we don\'t have you in our system.'));
        } else {
            if (result.hasOwnProperty('message') && typeof result.message === 'string' && result.message !== '') {
                dispatch(setAppSnackbar(result.message, 'error'));
            }
            dispatch(setPasswordResetMessage(result.message));
        }
        dispatch(setPasswordResetPending(false));
    };
};

export interface LoginError {
    message?: string;
    Message?: string;
}

export const setLoginErrorBasedOnResetCodeReason = (resetCodeReason?: ResetCodeReason) => {
    return (dispatch: AppDispatch) => {
        let errorMessage: string = '';

        switch (resetCodeReason) {
            case ResetCodeReason.Expired:
                errorMessage = `For security reasons, the reset password link you clicked has expired.
                Click below to send another reset password link.`;
                dispatch(setLoginError(errorMessage));
                break;
            case ResetCodeReason.Used:
                errorMessage = `Sorry, the password reset link you clicked has already been used.
                For security reasons, reset links only work once.
                You can click below to send another reset password link.`;
                dispatch(setLoginError(errorMessage));
                break;
            default:
                dispatch(setLoginError(undefined));
                break;
        }
    };
};

export const attemptUserLogin = (
    email: string | null,
    phone: string | null,
    password: string,
    resetcode?: string,
    resetCodeReason?: ResetCodeReason,
    isInvitationAcceptanceRequest?: boolean
) => {
    return async (dispatch: AppDispatch): Promise<SessionResult | null> => {
        if ((!email && !phone) || !password) {
            return null;
        }

        if (resetCodeReason === ResetCodeReason.Used || resetCodeReason === ResetCodeReason.Expired) {
            dispatch(setLoginErrorBasedOnResetCodeReason(resetCodeReason));
            return null;
        }

        dispatch(attemptingLogin());

        const result = await putToAPI<SessionResult & LoginError>
            ('login', { email, phone, password, resetcode }, dispatch);
        if (result === null || result.message || result.Message) {

            const errorMessage = result?.message || result?.Message || 'Sorry, these credentials are invalid';

            dispatch(loginFailed(errorMessage));
            dispatch(setAppSnackbar(errorMessage, 'error'));
            return null;
        } else {
            if (isInvitationAcceptanceRequest) {
                dispatch(setDeepLinkInviationStatus(true));
            }

            initializeUser(result);
            dispatch(userLoggedIn(result.data));
            return result;
        }

    };
};

export const checkForExistingSession = (pathname: string) => {
    return async (dispatch: AppDispatch): Promise<void> => {

        dispatch(checkingUserSession());

        // Check browser for auth token and try it out...
        const token = localStorage.getItem('authToken');
        let result: SessionResult | null = null;
        if (token) {
            authToken = token;
            result = await getFromAPI<SessionResult>('api/session', dispatch);
            if (!result) {
                // The token is no good, so clear it out
                localStorage.removeItem('authToken');
            }
        }

        if (result) {
            initializeUser(result);
            dispatch(userLoggedIn(result.data));
        } else {
            // We aren't going to initialize the user, but we need to boot intercom
            appService.bootIntercom();
            dispatch(noUserSessionFound());
        }

        if (pathname.startsWith('/link/')) {
            const link = pathname.replace('/link/', '');
            // don't evaluate deep link until after user session is verified
            if (link) {
                dispatch(evaluateDeepLink(link));
            }
        }
    };
};

const logoutUser = async (dispatch: AppDispatch, globalLogout: boolean = true) => {

    /**
     * Re-Boot intercom & clear session of logged in user
     */
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
        appService.shutdownIntercom();
        appService.reBootIntercom();
    }

    localStorage.removeItem('authToken');

    if (authToken && globalLogout) {
        // If we are having network issues, this could cause a loop if the
        // 401 detector calls this with globalLogout === true.
        // So don't call it with globalLogout!
        await getFromAPI('api/logout', dispatch);
    }

    // clear out the auth token that is stored globally now that we are logged out
    authToken = '';
};

export const switchUserSession = () => {
    return async (dispatch: AppDispatch) => {

        await logoutUser(dispatch, false);

        // this will NOT refresh the app. Need to make sure we clear out everything from previous user
        dispatch(userLoggedOut());
    };
};

export const logoutUserSession = (globalLogout: boolean = true) => {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const { userSession } = getState();
        if (!userSession.userData) {
            // assuming this is a user who typed their password wrong
            return;
        }

        await logoutUser(dispatch, globalLogout);

        if (isRememberPage()) {
            // For a Remember page on my.gather.app OR a FH hosted site (/obituaries/)
            // log the user out
            window.location.reload();
        } else {
            // For a non-Remember page we will redirect to /
            // If the user is on my.gather.app it will redirect to the login page
            // If the user is on a FH site it will take them to the FH webpage
            // A user should never be on a non-Remember page on a FH site
            // but if for some reason they are, this will prevent that being an issue
            window.location.pathname = '/';
        }
    };
};

export const ATTEMPTING_LOGIN = 'ATTEMPTING_LOGIN';

interface AttemptingLogin {
    type: typeof ATTEMPTING_LOGIN;
}

function attemptingLogin(): AttemptingLogin {
    return {
        type: ATTEMPTING_LOGIN,
    };
}

export const LOGIN_FAILED = 'LOGIN_FAILED';

interface LoginFailed {
    type: typeof LOGIN_FAILED;
    message: string;
}

function loginFailed(message: string): LoginFailed {
    return {
        type: LOGIN_FAILED,
        message,
    };
}

export const CHECKING_USER_SESSION = 'CHECKING_USER_SESSION';

interface CheckingUserSession {
    type: typeof CHECKING_USER_SESSION;
}

function checkingUserSession(): CheckingUserSession {
    return {
        type: CHECKING_USER_SESSION,
    };
}

export const NO_USER_SESSION_FOUND = 'NO_USER_SESSION_FOUND';

interface NoUserSessionFound {
    type: typeof NO_USER_SESSION_FOUND;
}

function noUserSessionFound(): NoUserSessionFound {
    return {
        type: NO_USER_SESSION_FOUND,
    };
}

interface SetLoginSuccess {
    type: constants.SET_LOGIN_SUCCESS;
    isLoginSuccess: boolean;
}

export function setLoginSuccess(isLoginSuccess: boolean): SetLoginSuccess {
    return {
        type: constants.SET_LOGIN_SUCCESS,
        isLoginSuccess
    };
}

interface SetLoginError {
    type: constants.SET_LOGIN_ERROR;
    loginError?: string;
}

export function setLoginError(loginError: string | undefined): SetLoginError {
    return {
        type: constants.SET_LOGIN_ERROR,
        loginError
    };
}

interface UserLoggedIn {
    type: constants.USER_LOGGED_IN;
    data: GatherData;
}

export function userLoggedIn(data: GatherData): UserLoggedIn {
    return {
        type: constants.USER_LOGGED_IN,
        data,
    };
}

interface UserLoggedOut {
    type: constants.USER_LOGGED_OUT;
}

function userLoggedOut(): UserLoggedOut {
    return {
        type: constants.USER_LOGGED_OUT,
    };
}

interface UpdateLoginUser {
    type: constants.UPDATE_LOGIN_USER;
    changes: Partial<UserProfile>;
}

export function updateLoginUser(changes: Partial<UserProfile>): UpdateLoginUser {
    return {
        type: constants.UPDATE_LOGIN_USER,
        changes,
    };
}

export function initializeUser(sessionResult: SessionResult) {
    // -- returned from the / resource mean "keep using your cached key!"
    if (sessionResult.token !== '--') {
        authToken = sessionResult.token;
        localStorage.setItem('authToken', authToken);
    }
    const { userData, defaultFuneralHome } = sessionResult.data;

    // grab default funeral home for now and use it with intercom
    appService.bootIntercom(
        userData,
        defaultFuneralHome ? defaultFuneralHome.key : undefined,
        defaultFuneralHome ? defaultFuneralHome.id.toString() : undefined,
    );
};

export const SET_FEATURES_OVERRIDE = 'SET_FEATURES_OVERRIDE';

interface SetFeaturesOverride {
    type: typeof SET_FEATURES_OVERRIDE;
    hasFeaturesOverride: boolean;
}

export function setFeaturesOverride(hasFeaturesOverride: boolean): SetFeaturesOverride {
    localStorage.setItem('gatherOverride', hasFeaturesOverride ? 'True' : '');
    return {
        type: SET_FEATURES_OVERRIDE,
        hasFeaturesOverride,
    };
}

export type GatherUserAction =
    | AttemptingLogin
    | LoginFailed
    | CheckingUserSession
    | NoUserSessionFound
    | SetLoginSuccess
    | SetLoginError
    | UserLoggedIn
    | UserLoggedOut
    | UpdateLoginUser
    | SetPasswordResetMessage
    | SetPasswordResetPending
    | SetFeaturesOverride
    ;
