import { PaginatedResponse, DocPacketReportRecord } from '../shared/types';
import { PaginatedTableData, StoreState } from '../types';
import { registerAppError } from './errors';
import { getFromAPI } from '.';
import { AppDispatch } from '../store';

/**
 * Going to try using an ENUM in this Redux action definition
 * this could help reduce the amount of "boiler plate" code 
 * by removing the "type" declaration.
 */
export enum DOC_PACKET_TABLE {
    'DOC_PACKET_TABLE_LOADING' = 'DOC_PACKET_TABLE_LOADING',
    'DOC_PACKET_TABLE_LOADED' = 'DOC_PACKET_TABLE_LOADED',
    'DOC_PACKET_TABLE_LOAD_FAILED' = 'DOC_PACKET_TABLE_LOAD_FAILED',
    'SET_TOTAL_DOC_PACKET_COUNT' = 'SET_TOTAL_DOC_PACKET_COUNT',
    'REMOVE_DOC_PACKET_TABLE_DOC_PACKET' = 'REMOVE_DOC_PACKET_TABLE_DOC_PACKET',
    'ADD_DOC_PACKET_TABLE_DOC_PACKET' = 'ADD_DOC_PACKET_TABLE_DOC_PACKET',
}

/**
 *   DocPacketTableLoading
 */
interface DocPacketTableLoading extends Omit<PaginatedTableData<DocPacketReportRecord>, 'hasMoreData'> {
    type: DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOADING;
    offset: number;
}

function docPacketTableLoading(
    data: DocPacketReportRecord[],
    searchText: string,
    sortBy: keyof DocPacketReportRecord,
    sortDirection: 'asc' | 'desc',
    offset: number,
): DocPacketTableLoading {
    return {
        type: DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOADING,
        data,
        searchText,
        sortBy,
        sortDirection,
        isLoading: true,
        offset,
    };
}

/**
 * DocPacketTableLoaded
 */
interface DocPacketTableLoaded {
    type: DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOADED;
    data: DocPacketReportRecord[];
    hasMoreData: boolean;
    isLoading: boolean;
    offset: number;
}
function docPacketTableLoaded(
    data: DocPacketReportRecord[],
    hasMoreData: boolean,
    offset: number,
): DocPacketTableLoaded {
    return {
        type: DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOADED,
        data,
        hasMoreData,
        isLoading: false,
        offset,
    };
}

/**
 * DocPacketTableLoadFailed
 */
interface DocPacketTableLoadFailed {
    type: DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOAD_FAILED;
    errorDetail: object;
}
export function docPacketTableLoadFailed(
    errorDetail: object,
): DocPacketTableLoadFailed {
    return {
        type: DOC_PACKET_TABLE.DOC_PACKET_TABLE_LOAD_FAILED,
        errorDetail,
    };
}

/**
 * SetTotalDocPacketCount
 */
interface SetTotalDocPacketCount {
    type: DOC_PACKET_TABLE.SET_TOTAL_DOC_PACKET_COUNT;
    count: number;
}
function setTotalDocPacketCount(
    count: number,
): SetTotalDocPacketCount {
    return {
        type: DOC_PACKET_TABLE.SET_TOTAL_DOC_PACKET_COUNT,
        count,
    };
}

export type DocPacketTableAction =
    | DocPacketTableLoading
    | DocPacketTableLoaded
    | DocPacketTableLoadFailed
    | SetTotalDocPacketCount
    ;

/**
 * 
 * 
 */

/* **********************************************************************
 *  Define the THUNKS here
 * **********************************************************************
 */

export function loadAllDocPacketTableRecordsForFuneralHome(
    funeralHomeId: number,
    newOffset: number = 0,
    newSearchText: string = '',
    passedSortBy: keyof DocPacketReportRecord = 'created_time',
    newSortDirection: 'asc' | 'desc' = 'desc',
    hideCompletedPackets: boolean = false,
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<DocPacketReportRecord[] | null> => {
        let newData: DocPacketReportRecord[] = [];
        if (!funeralHomeId) {
            return [];
        }
        const { docPacketTableState } = getState();
        const { data, searchText, sortBy, sortDirection } = docPacketTableState;
        newSearchText = newSearchText ? newSearchText : '';
        if (newOffset === 0 || newSearchText !== searchText
            || passedSortBy !== sortBy || newSortDirection !== sortDirection) {
            newOffset = 0;
            newData = [];
        } else {
            newOffset = data.length;
            newData = data;
        }

        const queryParamOffset = `offset=${newOffset}`;
        const queryParamSearchText = 'filter=' + encodeURIComponent(newSearchText);
        const queryParamSortBy = 'sortBy=' + encodeURIComponent(passedSortBy);
        const queryParamSortDirection = 'sortDirection=' + encodeURIComponent(newSortDirection);
        const queryParamHideCompletedPackets = 'hideCompletedPackets=' + encodeURIComponent(hideCompletedPackets);

        dispatch(docPacketTableLoading(newData, newSearchText, passedSortBy, newSortDirection, newOffset));
        const resource = `funeralhome/${funeralHomeId}/docpacket/`
            + `?${queryParamOffset}`
            + `&${queryParamSearchText}`
            + `&${queryParamSortBy}`
            + `&${queryParamSortDirection}`
            + `&${queryParamHideCompletedPackets}`
            ;
        const response: PaginatedResponse<DocPacketReportRecord> | null
            = await getFromAPI<PaginatedResponse<DocPacketReportRecord>>(resource, dispatch);
        if (response !== null) {
            dispatch(docPacketTableLoaded(response.data, response.hasMoreData, newOffset));
            if (response.totalcount) {
                dispatch(setTotalDocPacketCount(response.totalcount));
            } else {
                dispatch(setTotalDocPacketCount(0));
            }
            return response.data;
        } else {
            dispatch(registerAppError('Unable to load DocPacket records.'));
            dispatch(docPacketTableLoadFailed({ message: 'failed to load DocPacket records' }));
        }
        return [];
    };
}

export function loadDocPacketSummaryForFuneralHome(funeralHomeId: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<number | null> => {
        const resource = `funeralhome/${funeralHomeId}/docpacket/summary`;
        const response: { totalcount: number } | null
            = await getFromAPI<{ totalcount: number } | null>(resource, dispatch);
        if (response !== null) {
            if (response.totalcount) {
                dispatch(setTotalDocPacketCount(response.totalcount));
            } else {
                dispatch(setTotalDocPacketCount(0));
            }
            return response.totalcount;
        } else {
            dispatch(registerAppError('Unable to load DocPacket summary.'));
        }
        return null;
    };
}
