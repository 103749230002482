import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import StepComponentWrapper from '../StepComponentWrapper';
import { useGStyles } from '../../../../../common/hooks/useGStyles';
import { getIntercomTargetProp } from '../../../../../../services';

interface Props {
    text: string | undefined | null;
    className?: string;
    align?: 'center';
    intercomTargetProp?: string;
}

const ParagraphComponent = (props: Props) => {
    const {
        text,
        className,
        align,
        intercomTargetProp,
    } = props;

    const classes = useGStyles();

    if (!text || !text.trim()) {
        return null;
    }

    return (
        <StepComponentWrapper
            hideBorder
            className={classNames(
                classes.padding_0_12,
                className
            )}
            >
            <Typography
                color="secondary"
                align={align}
                className={classes.fontSize14}
                {...getIntercomTargetProp(intercomTargetProp)}
            >
                {text}
            </Typography>
        </StepComponentWrapper>
    );
};

export default ParagraphComponent;
