import { log } from "../logger";

export const REAR_CAMERA_KEYWORDS: string[] = [
    "rear",
    "back",
    "rück",
    "arrière",
    "trasera",
    "trás",
    "traseira",
    "posteriore",
    "后面",
    "後面",
    "背面",
    "后置", // alternative
    "後置", // alternative
    "背置", // alternative
    "задней",
    "الخلفية",
    "후",
    "arka",
    "achterzijde",
    "หลัง",
    "baksidan",
    "bagside",
    "sau",
    "bak",
    "tylny",
    "takakamera",
    "belakang",
    "אחורית",
    "πίσω",
    "spate",
    "hátsó",
    "zadní",
    "darrere",
    "zadná",
    "задня",
    "stražnja",
    "belakang",
    "बैक"
  ];

export const getAuthorizedCameraDevices = async (): Promise<MediaDeviceInfo[]> => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter((device) => device.kind === 'videoinput' && device.label);
};

export enum CameraPermissionStatus {
    notChecked = 'notChecked',
    notRequested = 'notRequested',
    granted = 'granted',
    dismissed = 'dismissed',
    denied = 'denied',
}

export const requestCameraPermissions = async (): Promise<CameraPermissionStatus> => {
    try {
        await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: {
                    ideal: 'environment',
                },
            },
        });
        return CameraPermissionStatus.granted;
    } catch (e) {
        if (e instanceof DOMException) {
            if (e.message === 'Permission dismissed') {
                return CameraPermissionStatus.dismissed;
            } else if (e.message === 'Permission denied') {
                return CameraPermissionStatus.denied;
            } else {
                log.warn('Unknown DOMException', { e });
            }
        } else {
            log.warn('Unknown exception requesting camera permissions', { e });
        }
        return CameraPermissionStatus.dismissed;
    }
};