import { Component } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { getPhotoUrl } from '../../services/photo.service';
import { PhotoTransformationsType, CloudinaryTransformationsType } from '../../shared/types';
import { getIntercomTargetProp } from '../../services';

export interface GavatarProps extends Omit<AvatarProps, 'ref'> {
    _itemid?: string;
    public_id?: string;
    transformations?: PhotoTransformationsType | null;
    className?: string;
    id?: string;
    initials?: string;
    size?: number;
    name?: 'photo-avatar' | 'name-initials-avatar';
    intercomTargetProp?: string;
}

export const GAVATAR_DEFAULT_SIZE = 40;

export default class Gavatar extends Component<GavatarProps> {
    render() {
        const {
            public_id,
            transformations,
            className,
            initials,
            size = GAVATAR_DEFAULT_SIZE,
            _itemid,
            intercomTargetProp,
            ...otherProps
        } = this.props;
        const htmlElementId = this.props.id;
        let src = undefined;
        if (public_id) {
            const extraTransformations: CloudinaryTransformationsType = {
                width: size,
                crop: 'limit',
                quality: 'auto',
                fetch_format: 'auto',
            };
            const allTransformations = transformations && transformations.cloudinary ?
                [transformations.cloudinary, extraTransformations] : [extraTransformations];
            src = getPhotoUrl(public_id, allTransformations);
        }
        return (
            <Avatar
                src={src}
                className={className}
                imgProps={{
                    id: htmlElementId,
                    itemID: _itemid
                }}
                itemID={_itemid}
                {...getIntercomTargetProp(intercomTargetProp)}
                {...otherProps}
            >
                {initials}
            </Avatar>
        );
    }
}
