import { useState } from "react";
import { BulkEditButtonType, PrimaryButtonsEnum, SecondaryButtonsEnum } from "./BulkEditSelectedOptionContent";
import BulkEditContainer from "./BulkEditContainer";
import { FormControl, Grid, InputAdornment, TextField, alpha } from '@mui/material';
import GGroupButtons from "../../../../common/GGroupButtons";
import { APP_SECONDARY_COLOR } from "../../../../../constants/colorVariables";
import { useFuneralHomeId } from "../../../../common/hooks/useFuneralHomeId";
import { useGDispatch, useGSelector } from "../../../../../types";
import Dinero from 'dinero.js';
import { toPennyValue } from "../../../../../shared/goods_and_services/pricing";
import { ProductUX } from "../../../../../shared/types";
import { registerAppError } from '../../../../../actions/errors';

interface EditPriceBaseContainerProps {
    selectButton: BulkEditButtonType | null;
    onSubmitClick: (productIdPriceMap: { productId: number; value: number }[]) => Promise<void>;
    selectedProductIds: number[] | undefined;
    getPrice: (productDetails: ProductUX) => Dinero.Dinero;
    isSpinning: boolean;
}

const EditPriceBaseContainer = (props: EditPriceBaseContainerProps) => {

    const {
        selectButton,
        selectedProductIds,
        getPrice,
        onSubmitClick,
        isSpinning,
    } = props;

    const funeralHomeId = useFuneralHomeId();
    const productStateData = useGSelector(({ productState }) => productState);
    const dispatch = useGDispatch();

    const [activePrimaryButton, setActivePrimaryButton] = useState<PrimaryButtonsEnum | null>(null);
    const [activeSecondaryButton, setActiveSecondaryButton] = useState<SecondaryButtonsEnum | null>(null);
    const [inputText, setInputText] = useState('');

    const buttons = [
        { label: PrimaryButtonsEnum.increase, value: PrimaryButtonsEnum.increase },
        { label: PrimaryButtonsEnum.decrease, value: PrimaryButtonsEnum.decrease },
        { label: PrimaryButtonsEnum.replace, value: PrimaryButtonsEnum.replace },
    ];

    const secondaryButtons = [
        { label: SecondaryButtonsEnum.percent, value: SecondaryButtonsEnum.percent },
        { label: SecondaryButtonsEnum.dollars, value: SecondaryButtonsEnum.dollars },
    ];

    const handleSecondaryButtonClick = (type: PrimaryButtonsEnum, price: Dinero.Dinero, amount: Dinero.Dinero) => {
        switch (type) {
            case PrimaryButtonsEnum.increase: {
                const newPrice = price.add(amount);
                return newPrice;
            }
            case PrimaryButtonsEnum.decrease: {
                const newPrice = price.subtract(amount);
                return newPrice;
            }
            case PrimaryButtonsEnum.replace: {
                return amount;
            }
            default: {
                return;
            }
        }
    };

    const handleSubmitclick = async () => {
        if (!activePrimaryButton || !selectedProductIds?.length || typeof funeralHomeId !== 'number') {
            return;
        };
        if (activePrimaryButton !== PrimaryButtonsEnum.replace 
            && activeSecondaryButton === SecondaryButtonsEnum.percent ) {

            if (!inputText || Number(inputText) > 100 || Number(inputText) < 0) {
                dispatch(registerAppError('Please enter a value between 1 and 100', 
                    { showSnackbar: true, sendToSentry: false }));
                return;
            }
        }

        const productValueMap = selectedProductIds.map((id) => {
            const productDetails = productStateData.funeralHomeProducts.find(product => product.id === id);
            if (!productDetails) {
                return;
            }
            const price = getPrice(productDetails);

            if (activePrimaryButton === PrimaryButtonsEnum.replace) {
                return { productId: id, value: toPennyValue(inputText).getAmount() };
            }

            const amount = activeSecondaryButton === SecondaryButtonsEnum.dollars
                ? toPennyValue(inputText)
                : price.percentage(Number(inputText));

            const finalAmount = handleSecondaryButtonClick(activePrimaryButton, price, amount)?.getAmount();
            return { productId: id, value: finalAmount };

        }).filter(Boolean) as { productId: number; value: number }[];
        if (productValueMap) {
            onSubmitClick(productValueMap);
        }
    };

    const disableSubmitButton = !inputText.trim()
        || !activePrimaryButton
        || (activePrimaryButton === PrimaryButtonsEnum.replace ? false : !activeSecondaryButton);


    return (
        <BulkEditContainer
            activePrimaryButton={activePrimaryButton}
            buttons={buttons}
            selectButton={selectButton}
            setActivePrimaryButton={setActivePrimaryButton}
            selectedProductsCount={selectedProductIds?.length || 0}
            onSubmitClick={handleSubmitclick}
            disableSubmitButton={disableSubmitButton}
            isSpinning={isSpinning}
        >
            <Grid
                item
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <FormControl sx={{
                    margin: activePrimaryButton !== PrimaryButtonsEnum.replace ? '15px 0px' : '15px 0px 0px',
                    width: '130px'
                }}>
                    <TextField
                        fullWidth
                        onChange={(evt) => setInputText(evt.target.value)}
                        autoComplete="off"
                        label={`${activePrimaryButton || 'Increase'} 
                            ${activePrimaryButton === PrimaryButtonsEnum.replace ? 'with: ' : 'by: '}`}
                        name="name"
                        type="number"
                        value={inputText}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {activePrimaryButton === PrimaryButtonsEnum.replace ? '$' : ''}
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
            {activePrimaryButton !== PrimaryButtonsEnum.replace && (
                <Grid item>
                    <GGroupButtons
                        buttons={secondaryButtons}
                        activeButton={activeSecondaryButton || null}
                        onClick={(option) => setActiveSecondaryButton(option)}
                        sx={{
                            ['&.selected']: {
                                bgcolor: alpha(APP_SECONDARY_COLOR, 0.2),
                                color: '#000',
                            },
                            color: `${APP_SECONDARY_COLOR}`,
                            borderColor: `${APP_SECONDARY_COLOR} !important`,
                            minWidth: '120px !important',
                        }}
                    />
                </Grid>
            )}
        </BulkEditContainer>
    );
};

export default EditPriceBaseContainer;