import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { APP_SECONDARY_COLOR } from '../../../../../constants/colorVariables';
import ProductDeleteDialogContent from './ProductDeleteDialogContent';
import BulkEditStyling from './BulkEditStyling';
import EditTaxRate from './EditTaxRate';
import EditBasePrice from './EditBasePrice';
import ShowHideOnPublicGPL from './ShowHideOnPublicGPL';
import EditVariablePrice from './EditVariablePrice';
import ShowHideOnWebsite from './ShowHideOnWebsite';
import ShowHidePriceOnWebsite from './ShowHidePriceOnWebsite';
import ChangeCategory from './ChangeCategory';
import EditUnits from './EditUnits';
import EditDefaultUnitAmount from './EditDefaultUnitAmount';
import { useFuneralHomeId } from '../../../../common/hooks/useFuneralHomeId';
import { ProductUX } from '../../../../../shared/types';
import EditPricingModel from './BulkEditPricingModel';
import BulkRemoveProductTag from './BulkRemoveProductTag';
import BulkAddProductTag from './BulkAddProductTag';


// order of this enum is important per APP-3904
export enum BulkEditButtonType {
    edit_base_price = 'Edit Base Price',
    edit_variable_price = 'Edit Variable Price',
    change_pricing_model = 'Change Pricing Model',
    edit_tax_rate = 'Edit Tax Rate',
    add_tag = 'Add Tag',
    delete_tag = 'Delete Tag',
    change_category = 'Change Category',
    edit_unit = 'Edit Unit',
    edit_default_unit_amount = 'Edit Default Unit Amount',
    edit_styling_on_statement = 'Edit Styling on Statement',
    show_hide_on_website = 'Show/Hide On Website',
    show_hide_price_on_website = 'Show/Hide Price on Website',
    show_hide_on_public_gpl = 'Show/Hide on Public GPL',
    delete_selected_products = 'Delete Selected Products',
}

export enum PrimaryButtonsEnum {
    taxable = 'taxable',
    not_taxable = 'Not taxable',
    increase = 'Increase',
    decrease = 'Decrease',
    replace = 'Replace',
    show = 'Show',
    hide = 'Hide',
}

export enum SecondaryButtonsEnum {
    percent = 'Percent',
    dollars = 'Dollars',
}

export interface ButtonType<T> {
    label: T;
    value: T;
}

interface BulkEditSelectedOptionContentProps {
    onBackClick: () => void;
    selectButton: BulkEditButtonType | null;
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    selectedProductIds: number[] | undefined;
    zIndex: number;
    isGatherUser: boolean;
}

const BulkEditSelectedOptionContent = (props: BulkEditSelectedOptionContentProps) => {
    const {
        onBackClick,
        selectButton,
        onSubmitClick,
        selectedProductIds,
        zIndex,
        isGatherUser,
    } = props;
    const funeralHomeId = useFuneralHomeId();

    const selectedButtonContents = () => {
        switch (selectButton) {
            case BulkEditButtonType.edit_tax_rate: {
                return <EditTaxRate
                    selectButton={selectButton}
                    selectedProductIds={selectedProductIds ?? []}
                    funeralHomeId={funeralHomeId}
                    onSubmitClick={onSubmitClick}
                    zIndex={zIndex}
                />;
            }
            case BulkEditButtonType.edit_base_price: {
                return <EditBasePrice
                    selectButton={selectButton}
                    onSubmitClick={onSubmitClick}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                />;
            }
            case BulkEditButtonType.show_hide_on_public_gpl: {
                return <ShowHideOnPublicGPL
                    selectButton={selectButton}
                    onSubmitClick={onSubmitClick}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                />;
            }
            case BulkEditButtonType.change_category: {
                return <ChangeCategory
                    selectButton={selectButton}
                    onSubmitClick={onSubmitClick}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    zIndex={zIndex}
                />;
            }
            case BulkEditButtonType.edit_variable_price: {
                return <EditVariablePrice
                    selectButton={selectButton}
                    onSubmitClick={onSubmitClick}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                />;
            }
            case BulkEditButtonType.edit_unit: {
                return <EditUnits
                    onSubmitClick={onSubmitClick}
                    selectButton={selectButton}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                />;
            }
            case BulkEditButtonType.edit_default_unit_amount: {
                return <EditDefaultUnitAmount
                    onSubmitClick={onSubmitClick}
                    selectButton={selectButton}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                />;
            }
            case BulkEditButtonType.show_hide_on_website: {
                return <ShowHideOnWebsite
                    selectButton={selectButton}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    onSubmitClick={onSubmitClick}
                />;
            }
            case BulkEditButtonType.show_hide_price_on_website: {
                return <ShowHidePriceOnWebsite
                    selectButton={selectButton}
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    onSubmitClick={onSubmitClick}
                />;
            }
            case BulkEditButtonType.edit_styling_on_statement: {
                return <BulkEditStyling
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    selectButton={selectButton}
                    onSubmitClick={onSubmitClick}
                />;
            }
            case BulkEditButtonType.delete_selected_products: {
                return <ProductDeleteDialogContent
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    onSubmitClick={onSubmitClick}
                />;
            }
            case BulkEditButtonType.change_pricing_model: {
                return <EditPricingModel
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    onSubmitClick={onSubmitClick}
                    selectButton={selectButton}
                    zIndex={zIndex}
                />;
            }
            case BulkEditButtonType.delete_tag: {
                return <BulkRemoveProductTag
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    onSubmitClick={onSubmitClick}
                    zIndex={zIndex}
                    isGatherUser={isGatherUser}
                />;
            }
            case BulkEditButtonType.add_tag: {
                return <BulkAddProductTag
                    funeralHomeId={funeralHomeId}
                    selectedProductIds={selectedProductIds ?? []}
                    onSubmitClick={onSubmitClick}
                    zIndex={zIndex}
                    isGatherUser={isGatherUser}
                />;
            }
            default: {
                return;
            }
        }
    };

    return (
        <Grid pt={1.5}>
            <ArrowBackIcon
                fontSize="large"
                sx={{
                    color: `${APP_SECONDARY_COLOR}`,
                    cursor: 'pointer',
                }}
                onClick={onBackClick}
            />
            <Grid>{selectedButtonContents()}</Grid>
        </Grid>
    );
};

export default BulkEditSelectedOptionContent;
