import * as t from 'io-ts';
import {
    UserProfile,
    PhotoTransformationsType,
    TaskTemplateType,
    TaskType,
    TrackingStepType,
    FingerprintUX,
    CaseBelongingUX,
    CaseIdPhotoUX,
    FuneralHomeCaseRecord,
    GatherCaseRecord,
} from '.';
import { NoteUpdatedByUser } from './note';
import { EntitySummary, isUserProfile, UserRole } from './user';
import { FeatureKey, FuneralHomeRecord } from './funeralHome';
import {
    TaskComponentUX,
    TaskComponentCaseUpdateRequestType,
    TaskComponentCaseUpdateRequest,
} from './taskComponent';
import { getValidator } from './utils';
import { DocUX, S3FileCreateRequest, S3FileCreateRequestType, S3FileRecord } from './doc';
import uuid from 'uuid';
import { TaskLocationUX } from './taskLocation';
import { DateFromISOString } from 'io-ts-types';

export enum TaskState {
    incomplete = 'incomplete',
    skipped = 'skipped',
    complete = 'complete',
}

export interface TaskRecord {
    id: number;
    type: TaskType;
    icon: string | null;
    title: string;
    past_tense_title: string | null;
    subtitle: string | null;
    description: string | null;
    template_type: TaskTemplateType | null;
    tracking_step_type: TrackingStepType | null;
    feature: FeatureKey | null;
    can_complete: boolean;
    can_skip: boolean;
    visible_to_family: boolean;
    can_reassign_by_family: boolean;
    can_assign_multiple: boolean;
    is_after_care: boolean;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    from_task_id: number | null;
    from_workflow_id: number | null;
}

export interface CaseTaskRecord {
    task_id: number;
    funeral_home_case_id: number;
    task_location_id: number | null;
    rank: number;
    signature_s3_file_id: number | null;
    additional_sig_s3_file_id: number | null;
    note: string | null;
    note_updated_by: number | null;
    note_updated_time: Date | null;
    is_locked: boolean | null;
    assigned_to_all_time: Date | null;
    assigned_to_all_by: number | null;
    event_id: number | null;
    exited_location_time: Date | null; // special use case for "move" tracking_steps
    marked_complete_time: Date | null;
    marked_complete_by: number | null;
    performed_by: number | null;
    skipped_time: Date | null;
    skipped_by: number | null;
    complete_by_time: Date | null;
    resolved_time: Date | null; // marked_complete_time or skipped_time
    assigned_user_profile_ids: number[]; // denormalized list of assigned users
}

export interface FuneralHomeTaskRecord {
    task_id: number;
    funeral_home_id: number;
    rank: number;
    created_by: number;
    created_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
}

export interface TaskUserType {
    user_id: number;
    fname: string;
    lname: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
}

export const entitySummaryToTaskUser = (e: EntitySummary): TaskUserType | null => {
    if (e.user_id === null) {
        return null;
    }

    return {
        user_id: e.user_id,
        fname: e.fname,
        lname: e.lname,
        photo: e.photo,
        photo_transformations: e.photo_transformations,
    };
};

export interface TaskUXRecord extends
    Pick<TaskRecord,
        | 'id'
        | 'type'
        | 'icon'
        | 'title'
        | 'past_tense_title'
        | 'subtitle'
        | 'description'
        | 'template_type'
        | 'tracking_step_type'
        | 'feature'
        | 'can_complete'
        | 'can_skip'
        | 'visible_to_family'
        | 'can_reassign_by_family'
        | 'can_assign_multiple'
        | 'is_after_care'
        | 'created_by'
        | 'created_time'
        | 'updated_by'
        | 'updated_time'
        | 'from_task_id'
        | 'from_workflow_id'
    >,
    Pick<CaseTaskRecord,
        | 'task_location_id'
        | 'rank'
        | 'signature_s3_file_id'
        | 'additional_sig_s3_file_id'
        | 'note'
        | 'note_updated_by'
        | 'note_updated_time'
        | 'is_locked'
        | 'assigned_to_all_time'
        | 'assigned_to_all_by'
        | 'event_id'
        | 'marked_complete_time'
        | 'skipped_time'
        | 'complete_by_time'
        | 'exited_location_time'
    >,
    Pick<FuneralHomeCaseRecord,
        | 'gather_case_id'
    > {
    state: TaskState;
    skipped_by: TaskUserType | null;
    marked_complete_by: TaskUserType | null;
    performed_by: TaskUserType | null;
    note_updated_by_user: NoteUpdatedByUser;
    gather_case_fname: string;
    case_uuid: FuneralHomeCaseRecord['uuid'];
    assigned_to_all: boolean;
    assigned_to_all_by_fname: string | null;
    components: TaskComponentUX[];
    prereq_task_ids: number[];
    signature_s3_file: S3FileRecord | null;
    additional_signature_s3_file: S3FileRecord | null;
}

export interface CaseTaskUX extends Omit<TaskUXRecord, 'signature_s3_file' | 'additional_signature_s3_file'> {
    assigned_to: TaskAssignee[];
    task_location: TaskLocationUX | null;
    signature_url: string | null;
    additional_signature_url: string | null;
}

export interface PublicTask {
    template_type: TaskTemplateType | null;
    marked_complete_time: Date | null;
    skipped_time: Date | null;
}

export class CaseTaskUX {
    public static isComplete(task: CaseTaskUX): boolean {
        return task.state === TaskState.complete;
    }

    public static isSkipped(task: CaseTaskUX): boolean {
        return task.state === TaskState.skipped;
    }

    public static isIncomplete(task: CaseTaskUX): boolean {
        return task.state === TaskState.incomplete;
    }
}

// ---> CaseTaskCreateRequest <---
const caseTaskCreateRequestDefinition = {
    title: t.string,
    subtitle: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
    visible_to_family: t.boolean,
    is_after_care: t.boolean,
    assigned_to_all: t.boolean,
    assigned_to: t.array(t.number),
    event_id: t.union([t.number, t.null]),
    complete_by_time: t.union([DateFromISOString, t.null]),
};
const CaseTaskCreateRequestType = t.type(caseTaskCreateRequestDefinition);

export interface CaseTaskCreateRequest extends t.TypeOf<typeof CaseTaskCreateRequestType> {
}

export class CaseTaskCreateRequest {
    public static fromRequest = getValidator<CaseTaskCreateRequest>(CaseTaskCreateRequestType);
}

export interface CaseTaskCreateRequestUX extends Omit<CaseTaskCreateRequest, 'assigned_to'> {
    assigned_to: TaskAssignee[];
}

// ---> CaseTaskUpdateRequest <---
const CaseTaskUpdateOnlyType = t.partial({
    is_locked: t.union([t.boolean, t.null]),
    note: t.union([t.string, t.null]),
    prereq_task_ids: t.array(t.number),
    complete_by_time: t.union([DateFromISOString, t.null]),
});
const CaseTaskUpdateRequestType = t.intersection([
    CaseTaskUpdateOnlyType,
    t.partial(caseTaskCreateRequestDefinition),
]);

export interface CaseTaskUpdateRequest extends t.TypeOf<typeof CaseTaskUpdateRequestType> {
}

export class CaseTaskUpdateRequest {
    public static fromRequest = getValidator<CaseTaskUpdateRequest>(CaseTaskUpdateRequestType);
}

export interface TaskUpdateRequestUX extends Omit<CaseTaskUpdateRequest, 'assigned_to'> {
    assigned_to?: TaskAssignee[];
    assigned_to_all_by?: number | null;
    assigned_to_all_time?: Date | null;
    assigned_to_all_by_fname?: string | null;
    state?: TaskState;
    is_locked?: boolean | null;
    note_updated_by?: number | null;
    note_updated_time?: Date | null;
}

// ---> CaseTaskVisibilityRequest <---
const CaseTaskVisibilityType = t.type({
    visible_to_family: t.boolean,
});

export interface CaseTaskVisibilityRequest extends t.TypeOf<typeof CaseTaskVisibilityType> {
}

export class CaseTaskVisibilityRequest {
    public static fromRequest = getValidator<CaseTaskVisibilityRequest>(CaseTaskVisibilityType);
}

// ---> CaseTaskSkipRequest <---
const CaseTaskSkipRequestType = t.type({
    signature: t.union([S3FileCreateRequestType, t.null]),
    note: t.union([t.string, t.null]),
});

export interface CaseTaskSkipRequest extends t.TypeOf<typeof CaseTaskSkipRequestType> {
    signature: S3FileCreateRequest | null;
}

export class CaseTaskSkipRequest {
    public static fromRequest = getValidator<CaseTaskSkipRequest>(CaseTaskSkipRequestType);
}

// ---> ChecklistTaskCompleteRequest <---
const ChecklistTaskCompleteRequestType = t.type({
    note: t.union([t.string, t.null]),
});

export interface ChecklistTaskCompleteRequest extends t.TypeOf<typeof ChecklistTaskCompleteRequestType> {
}

export class ChecklistTaskCompleteRequest {
    public static fromRequest = getValidator<ChecklistTaskCompleteRequest>(ChecklistTaskCompleteRequestType);
}

// ---> TrackingStepCompleteRequest <---
export const TrackingStepCompleteRequestType = t.type({
    signature: t.union([S3FileCreateRequestType, t.null]),
    additional_signature: t.union([S3FileCreateRequestType, t.null]),
    task_location_id: t.union([t.number, t.null]),
    performed_by: t.union([t.number, t.null]),
    component_updates: t.union([t.array(TaskComponentCaseUpdateRequestType), t.null]),
    visible_to_family: t.boolean,
    move_step_visible_to_family: t.union([t.boolean, t.null]),
});

export interface TrackingStepCompleteRequest extends t.TypeOf<typeof TrackingStepCompleteRequestType> {
    signature: S3FileCreateRequest | null;
    additional_signature: S3FileCreateRequest | null;
    component_updates: TaskComponentCaseUpdateRequest[] | null;
}

export class TrackingStepCompleteRequest {
    public static fromRequest = getValidator<TrackingStepCompleteRequest>(TrackingStepCompleteRequestType);
}

export interface TasksComplete {
    complete: number;
    remaining: number;
    pct: number;
}

// ---> TaskAssignee <---
export interface TaskAssigneeRecord {
    user_id: number;
    entity_id: number;
    fname: string;
    lname: string | null;
    email: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    role: UserRole;
    assigned_by: number | null;
    assigned_time: Date;
    task_id: number;
    assigned_by_fname: string | null;
}

export interface TaskAssignee extends Omit<TaskAssigneeRecord, 'task_id'> {
}

export function userToTaskAssignee(
    person: EntitySummary | UserProfile,
    assignee?: UserProfile | null,
): TaskAssignee | null {

    const user = isUserProfile(person) ? person : person.user;

    if (!user) {
        console.warn('Person without user trying to be assigned to task');
        return null;
    }

    return {
        user_id: user.user_id,
        entity_id: person.entity_id,
        fname: person.fname,
        lname: person.lname,
        email: person.email,
        photo: person.photo,
        photo_transformations: person.photo_transformations,
        role: user.role,
        assigned_by: assignee && assignee.id || null,
        assigned_time: new Date(),
        assigned_by_fname: assignee && assignee.fname || null,
    };
}

export interface TaskPreviewRecord {
    task_id: TaskRecord['id'];
    task_title: TaskRecord['title'];
    task_note: CaseTaskRecord['note'];
    task_template: TaskRecord['template_type'];
    gather_case_id: GatherCaseRecord['id'];
    funeral_home_id: FuneralHomeCaseRecord['funeral_home_id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    case_name: GatherCaseRecord['name'];
    case_fname: GatherCaseRecord['fname'];
    case_lname: GatherCaseRecord['lname'];
    case_full_name: string;
    case_photo: string | null;
    case_photo_transformations: PhotoTransformationsType | null;
    assigned_time: Date;
    assigned_by_user_id: CaseTaskRecord['assigned_to_all_by'];
    assigned_by_full_name: string;
    assigned_to_user_id: number;
    complete_by_time: Date | null ;
}

export interface TaskPreview extends TaskPreviewRecord {
}

// ---> TrackingStepMoveRequest <---
const trackingStepMoveRequestDefinition = {
    task_location_id: t.number,
    note: t.union([t.string, t.null]),
    signature: S3FileCreateRequestType,
    visible_to_family: t.boolean,
};
const TrackingStepMoveRequestType = t.type(trackingStepMoveRequestDefinition);

export interface TrackingStepMoveRequest extends t.TypeOf<typeof TrackingStepMoveRequestType> {
    signature: S3FileCreateRequest;
}
export class TrackingStepMoveRequest {
    public static fromRequest = getValidator<TrackingStepMoveRequest>(TrackingStepMoveRequestType);
}

export const generateDummyTask = (): Omit<TaskRecord, 'id' | 'updated_time' | 'created_time'> => {
    const ident = uuid();
    const dummyRecord: Omit<TaskRecord, 'id' | 'updated_time' | 'created_time'> = {
        type: TaskType.checklist_task,
        tracking_step_type: null,
        title: `Test Custom Task ${ident}`,
        past_tense_title: `Tested Custom Task ${ident}`,
        description: `custom task ${ident} explainer`,
        visible_to_family: true,
        is_after_care: true,
        icon: null,
        subtitle: null,
        template_type: null,
        can_complete: true,
        can_skip: false,
        can_reassign_by_family: true,
        can_assign_multiple: false,
        created_by: 1,
        updated_by: 1,
        from_workflow_id: null,
        deleted_by: null,
        deleted_time: null,
        feature: null,
        from_task_id: null,
    };
    return dummyRecord;
};

export const generateDummyCaseTaskRecord = (): CaseTaskRecord => {
    return {
        task_id: -1,
        funeral_home_case_id: -1,
        task_location_id: null,
        rank: 1,
        signature_s3_file_id: null,
        additional_sig_s3_file_id: null,
        note: null,
        note_updated_by: null,
        note_updated_time: null,
        is_locked: null,
        assigned_to_all_time: null,
        assigned_to_all_by: null,
        event_id: null,
        exited_location_time: null,
        marked_complete_time: null,
        marked_complete_by: null,
        performed_by: null,
        skipped_time: null,
        skipped_by: null,
        complete_by_time: null,
        resolved_time: null,
        assigned_user_profile_ids: [],
    };
};

export const generateDummyCaseTask = (caseId: number, caseUuid: string): CaseTaskUX => {
    return {
        ...generateDummyTask(),
        ...generateDummyCaseTaskRecord(),
        id: -1,
        gather_case_id: caseId,
        created_time: new Date(),
        updated_time: new Date(),
        type: TaskType.tracking_step,
        tracking_step_type: TrackingStepType.normal,
        state: TaskState.incomplete,
        skipped_by: null,
        marked_complete_by: null,
        performed_by: null,
        note_updated_by_user: {
            id: -1,
            time: new Date(),
            fname: 'Jim',
            lname: null,
            photo: null,
            photo_transformations: null,
            role: UserRole.FHUser,
        },
        gather_case_fname: 'John',
        case_uuid: uuid(),
        assigned_to_all: false,
        assigned_to_all_by_fname: null,
        components: [],
        prereq_task_ids: [],
        assigned_to: [],
        signature_url: null,
        additional_signature_url: null,
        task_location: null,
    };
};

export interface LoadCaseTaskResponse {
    task: CaseTaskUX;
    fingerprints?: FingerprintUX[];
    belongings?: CaseBelongingUX[];
    idPhotos?: CaseIdPhotoUX[];
    docs?: DocUX[];
}

export interface LoadInitializeStepResponse extends LoadCaseTaskResponse {
    taskLocations: TaskLocationUX[];
}
