import { FuneralHomeUX, FuneralHomePublic } from '../types/funeralHome';

export const getPublicFHfromUX = (funeralHome: FuneralHomeUX): FuneralHomePublic => {
    return {
        id: funeralHome.id,
        custom_assets: funeralHome.custom_assets,
        name: funeralHome.name,
        key: funeralHome.key,
        photo: funeralHome.photo,
        photo_transformations: funeralHome.photo_transformations,
        theme_photo: funeralHome.theme_photo,
        theme_photo_transformations: funeralHome.theme_photo_transformations,
        icon: funeralHome.icon,
        icon_transformations: funeralHome.icon_transformations,
        address1: funeralHome.address1,
        city: funeralHome.city,
        state: funeralHome.state,
        postal_code: funeralHome.postal_code,
        timezone: funeralHome.timezone,
        phone: funeralHome.phone,
        email: funeralHome.email,
        features: funeralHome.features,
        website_url: funeralHome.website_url,
        long_address: funeralHome.long_address,
        ext_flower_store_url: funeralHome.ext_flower_store_url,
        florist_1day_lead_time: funeralHome.florist_1day_lead_time,
        hidden_flower_categories: funeralHome.hidden_flower_categories,
        guest_payment_display_option: funeralHome.guest_payment_display_option,
        is_overpayment_enabled: funeralHome.is_overpayment_enabled,
        is_prepayment_enabled: funeralHome.is_prepayment_enabled,
    };
};
