import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import LoadingButton from '@mui/lab/LoadingButton';
import SchoolIcon from '@mui/icons-material/School';

import Alert from '@mui/material/Alert';

import { FeatureKey, GatherCaseUX } from '../../../shared/types';
import makeGStyles from '../../../styles/makeGStyles';
import GDialog from '../../common/GDialog';
import { useGDispatch, useGSelector } from '../../../types';
import { generateQrZebraLabel } from '../../../actions/QrSticker.action';
import { FormControl, Typography } from '@mui/material';
import {
    getDecedentAge,
    isIOSDevice,
    getIntercomTargetProp,
    showIntercomNewMessage,
} from '../../../services';
import moment from 'moment-timezone';
import { isEnabled } from '../../common/AppFeature';
import IncreaseDecreaseTextField from '../goodsAndServices/widgets/IncreaseDecreaseTextField';
import { setAppSnackbar, setSnackbarSuccess } from '../../../actions/AppSnackbar.action';

const useStyles = makeGStyles(
    (theme) => ({
        dialogTitle: {
            background: 'none',
            boxShadow: 'none',
            minHeight: 58,
            boxSizing: 'border-box',
            '& p, svg': {
                color: '#fff',
            },
            '& p': {
                fontWeight: 400,
                fontSize: '18px',
                textAlign: 'left',
                padding: 0,
                display: 'block',
                color: '#fff',
                lineHeight: '30px',
            },
        },
        dialogContent: {
            padding: '20px 8px 80px !important',
            [theme.breakpoints.up(425)]: {
                padding: '20px 24px 80px !important',
            },
            '& hr': {
                borderColor: theme.palette.primary.main,
                background: 'none',
            },
            overflowY: 'visible',
        },
        printBtn: {
            textTransform: 'none',
            fontWeight: 400,
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            borderRadius: 12,
            paddingTop: 8,
            boxShadow: theme.shadows[4],
            position: 'absolute',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'max-content',
            zIndex: 2,
        },
        nextBtn: {
            fontSize: 16,
            padding: '0 52px',
        },
        groupBtn: {
            minHeight: 48,
            height: 48,
            minWidth: 148,
            [theme.breakpoints.up(380)]: {
                minWidth: 168,
            },
        },
        underlineText: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        stickerCountLabel: {},
        learnMoreIcon: {
            fontSize: 'small',
        },
        learnMoreLnk: {
            padding: '20px 0 10px 0',
            color: '#66baff',
        },
    }),
    { name: 'PrintQRStickersDialog' },
);

export enum StickerType {
    QR = 'QR',
}
interface Props {
    zIndex: number;
    selectedCase: GatherCaseUX;
    onClose: () => void;
}
const PrintQRStickersDialogZebra = (props: Props) => {
    const { zIndex, selectedCase, onClose } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();
    const [labelCount, setLabelCount] = useState(4);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);


    // handleSelectPrinter
    // if no printer detected - message setup GatherLabelPrinter
    const isIOS = useMemo(isIOSDevice, []);
    const isQRStickerZebraEnabled = useGSelector(({ funeralHomeState, casesState, userSession }) =>
        isEnabled(FeatureKey.QR_STICKER_ZEBRA, funeralHomeState, casesState, userSession),
    );

    useEffect(() => {
        if (isQRStickerZebraEnabled && isIOS) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [isIOS, isQRStickerZebraEnabled]);

    const handlePrint = async () => {
        setIsPrinting(true);
        const content = contentRef.current?.innerHTML;
        const printContainer = document.getElementById('print-container');

        if (!content || !printContainer) {
            return;
        }
        const newDateFormat = 'DD MMM YYYY';
        const displayName = selectedCase.lname.toUpperCase() + ', ' + selectedCase.fname;
        const failureMessage = await dispatch(
            generateQrZebraLabel(
                {
                    funeralHomeName: selectedCase.funeral_home.name,
                    fullNameDisplayStr: displayName,
                    keeptrackTagId: selectedCase.keeptrack_key,
                    funeralHomeCaseAssocId: selectedCase.funeral_home_case_id,
                    dobDisplayStr: selectedCase.dob_date //  11 Chars  DAYofMonth MON YEAR eg 30 JAN 1922
                        ? moment(selectedCase.dob_date, 'YYYY-MM-DD').format(newDateFormat).toString().toUpperCase()
                        : null,
                    dodDisplayStr: selectedCase.dod_start_date //  11 Chars  DAYofMonth MON YEAR eg 30 JAN 1922
                        ? moment(selectedCase.dod_start_date, 'YYYY-MM-DD')
                            .format(newDateFormat)
                            .toString()
                            .toUpperCase()
                        : null,
                    ageInYears: getDecedentAge(selectedCase).age,
                    fhCaseNumber: selectedCase.case_number, //  approx 19 chars
                },
                labelCount,
            ),
        );
        if (failureMessage) {
            dispatch(setAppSnackbar(`${failureMessage}`, 'error', undefined, 10000));
        }  else {
            dispatch(setSnackbarSuccess(`${labelCount} QR Sticker(s) printed successfully.`, undefined, 2000));
        }
        setIsPrinting(false);
        onClose();
    };

    return (
        <GDialog
            zIndex={zIndex}
            title={'Print QR Stickers on Zebra Printer'}
            isOpen={true}
            titleClass={classes.dialogTitle}
            paperClass={classes.positionRelative}
            contentClass={classes.dialogContent}
            onClose={onClose}
        >
            <div className={classes.marginBottom24}>
                <div aria-hidden={true} ref={contentRef} style={{ display: 'none', visibility: 'hidden' }}>
                    <div
                        style={{
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            width: `calc(100% + 8px)`,
                            maxWidth: `calc(100% + 8px)`,
                            flexBasis: '100%',
                            marginLeft: -8,
                        }}
                    />
                </div>
            </div>
            <FormControl fullWidth>
                <Typography className={classes.stickerCountLabel}>Number of Stickers</Typography>
                <IncreaseDecreaseTextField
                    min={1}
                    max={10}
                    helperText="Stickers"
                    counter={labelCount}
                    onCounterChange={setLabelCount}
                    disabled={isDisabled}
                />
            </FormControl>
            {!isIOS && (
                <>
                    <Typography
                        className={classes.underlineText}
                        onClick={() => showIntercomNewMessage(`Additional questions on using the Zebra printer`)}
                    >
                        We're here to answer any additional questions.
                    </Typography>
                </>
            )}
            {isIOS && (
                <>
                    <Alert variant="filled" severity="error">
                        This feature is not enabled on IOS devices. Please print from a desktop or Android device.
                    </Alert>
                    <Typography
                        className={classes.underlineText}
                        onClick={() =>
                            showIntercomNewMessage(`Additional questions on using the Zebra printer with iPhone`)
                        }
                    >
                        We're here to answer any additional questions.
                    </Typography>
                </>
            )}
            <Typography className={classes.learnMoreLnk} {...getIntercomTargetProp('MobilePrinter_LearnMore')}>
                <SchoolIcon className={classes.learnMoreIcon} /> Click here for instructions
            </Typography>
            <LoadingButton
                loading={isPrinting}
                variant="contained"
                disabled={isDisabled}
                className={classNames(classes.flexColumnCentered, classes.printBtn)}
                onClick={() => handlePrint()}
            >
                <span className={classes.fontSize16}>Print Sticker(s)</span>
            </LoadingButton>
        </GDialog>
    );
};

export default PrintQRStickersDialogZebra;
