import { getValidator, longAddress, LongAddress } from '.';
import * as t from 'io-ts';

export interface TaskLocationRecord {
    id: number;
    name: string;
    capacity: number | null;
    cover_photo_id: number | null;
    cover_fallback_url: string | null;
    address_id: number | null;
    is_3rd_party: boolean;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
}

export interface TaskLocationUXRecord extends Pick<TaskLocationRecord,
    | 'id'
    | 'name'
    | 'capacity'
    | 'is_3rd_party'
    | 'cover_photo_id'
    | 'cover_fallback_url'
> {
    rank: number;
    cover_photo: string | null;
    address: LongAddress | null;
    fhs_shared_with: number[];
    cases_in_location: number;
}

export interface TaskLocationUX extends TaskLocationUXRecord { }

export interface FuneralHomeTaskLocationRecord {
    task_location_id: number;
    funeral_home_id: number;
    rank: number;
    created_by: number;
    created_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
}

const uploadPhoto = t.type({
    public_id: t.string,
    width: t.number,
    height: t.number,
});

export interface TaskLocationUploadPhoto extends t.TypeOf<typeof uploadPhoto> { }

const TaskLocationRequestDefinition = {
    name: t.string,
    capacity: t.union([t.number, t.null]),
    cover_photo: t.union([uploadPhoto, t.null]),
    address: t.union([longAddress, t.null]),
    is_3rd_party: t.boolean,
    fhs_shared_with: t.union([t.array(t.number), t.null]),
};

const TaskLocationCreateRequestType = t.type(TaskLocationRequestDefinition);
export interface TaskLocationCreateRequest extends t.TypeOf<typeof TaskLocationCreateRequestType> {
    address: LongAddress | null;
}

export class TaskLocationCreateRequest {
    public static fromRequest = getValidator<TaskLocationCreateRequest>(TaskLocationCreateRequestType);
}

const TaskLocationUpdateRequestType = t.partial(TaskLocationRequestDefinition);
export interface TaskLocationUpdateRequest extends t.TypeOf<typeof TaskLocationUpdateRequestType> {
    address?: LongAddress;
}

export class TaskLocationUpdateRequest {
    public static fromRequest = getValidator<TaskLocationUpdateRequest>(TaskLocationUpdateRequestType);
}

const TaskLocationRankUpdateRequestDefinition = {
    taskLocationIds: t.array(t.number),
};

const TaskLocationRankUpdateRequestType = t.type(TaskLocationRankUpdateRequestDefinition);
export interface TaskLocationRankUpdateRequest extends t.TypeOf<typeof TaskLocationRankUpdateRequestType> { }

export class TaskLocationRankUpdateRequest {
    public static fromRequest = getValidator<TaskLocationRankUpdateRequest>(TaskLocationRankUpdateRequestType);
}

export interface TaskLocationSummary {
    id: number;
    name: string;
    moved_time: Date;
}
