import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import { DocPacketStatus, EntitySummary } from '../../../shared/types';
import moment from 'moment-timezone';
import GPopper from '../../common/GPopper';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    mainPaper: {
        padding: 16
    },
    paperHeader: {
        fontSize: 12,
        textAlign: 'center',
        color: '#000',
        fontWeight: 200
    },
    reminderPopover: {
        padding: 18,
        textAlign: 'center',
        maxWidth: '270px',
    },
    reminderButton: {
        margin: 0,
        width: 42,
        maxWidth: 42,
        minWidth: 42,
        marginRight: 4,
        padding: '7px 0px 7px 0px',
        '@media (min-width: 340px)': {
            margin: '8px 4px',
        }
    },
    additionalReminderButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    reminderButtonContent: {
        fontSize: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '8px',
    },
    alarmIcon: {
        fontSize: '2.5em',
    },
    textDecorationNone: {
        textDecoration: 'none'
    },
    transparentButton: {
        margin: '4px 2px',
        '@media (min-width: 340px)': {
            margin: '8px 2px',
        }
    },
    fabExtended: {
        width: 'max-content',
        lineHeight: '12px',
        textTransform: 'none',
        backgroundColor: 'white',
        fontWeight: 400,
        fontSize: 10,
        padding: '2px 8px',
        borderRadius: 24,
        border: '1px solid',
    },
    sendReminderButton: {
        '& svg': {
            verticalAlign: 'middle'
        }
    }
});

interface Props {
    zIndex: number;
    targetSigner: EntitySummary;
    reminderAnchorEl: HTMLElement | null;
    clickAwayListener: () => void;
    sendReminderClick: () => void;
    lastSentTime: Date | null;
    docPacketStatus: DocPacketStatus;

}

type StyledProps = Props & WithStyles<'root' | 'mainPaper' | 'paperHeader' | 'reminderPopover' | 'reminderButton'
    | 'transparentButton' | 'reminderButtonContent' | 'alarmIcon' | 'additionalReminderButton' | 'fabExtended'
    | 'sendReminderButton'>;

const SendEmailReminderPopover = (props: StyledProps) => {
    const {
        classes, zIndex, targetSigner, reminderAnchorEl, clickAwayListener, sendReminderClick,
        lastSentTime, docPacketStatus
    } = props;

    const reminderTooltip = `Click to send a signature request reminder email to ${targetSigner.fname}`;
    const lastSentTimeFormatted = moment(lastSentTime || '').format('M/D/YYYY @ h:mma');
    const displayMessage = docPacketStatus === 'started'
        ? targetSigner.email ? `We will send to ${targetSigner.email}` : ''
        : `Latest signature request sent to ${targetSigner.fname} at ${lastSentTimeFormatted}`;

    return (
        <GPopper
            anchorEle={reminderAnchorEl}
            popperContentClass={classes.mainPaper}
            zIndex={zIndex}
            closePopper={clickAwayListener}
            placement='bottom'
            hideArrow
        >
            <Typography
                className={classes.paperHeader}
            >
                {displayMessage}
            </Typography>
            <div className={classes.reminderPopover}>
                <Typography
                    align="center"
                    component="p"
                >
                    Email reminders are automatically sent 3 and 7 days after
                    the initial signature request
                </Typography>
            </div>
            <div className={classes.additionalReminderButton}>
                <Tooltip
                    title={reminderTooltip}
                    placement="top"
                >
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={sendReminderClick}
                    >
                        SEND ADDITIONAL REMINDER
                    </Button>
                </Tooltip>
            </div>
        </GPopper>
    );
};

export default withStyles(styles)(SendEmailReminderPopover);
