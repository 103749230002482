import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import CloseLinkedin from './CloseLinkedin';
import ClosePinterest from './ClosePinterest';
import CloseTwitter from './CloseTwitter';
import DeleteFacebook from './DeleteFacebook';
import DeleteInstagram from './DeleteInstagram';
import MemorializeFacebook from './MemorializeFacebook';
import MemorializeInstagram from './MemorializeInstagram';
import { FACEBOOK_ICON, INSTAGRAM_ICON, TWITTER_ICON, LINKEDIN_ICON, PINTEREST_ICON } from './SocialIcons';

const SocialMediaDialog = (props: TaskDialogComponentProps) => {
    const { activeCase, userData, setAppSnackbar } = props;

    const description = (
        <>
            Most families don't even know where to begin  when it comes to their loved
            one's social media. As a service to you and your family, we've provided a
            simple, easy-to-follow guide for each of the social media accounts
            that {activeCase.fname} may have had. <FhOrAssigneePhoneNumberLink activeCase={activeCase} capitalize /> to
            call me directly if you have questions.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: `Memorialize ${activeCase.fname}'s Facebook`,
        icon: FACEBOOK_ICON,
        content: (
            <MemorializeFacebook
                heading={`Memorialize ${activeCase.fname}'s Facebook`}
                icon={FACEBOOK_ICON}
                activeCase={activeCase}
            />
        ),
    }, {
        id: 2,
        icon: FACEBOOK_ICON,
        label: `Delete ${activeCase.fname}'s Facebook`,
        content: (
            <DeleteFacebook
                heading={`Delete ${activeCase.fname}'s Facebook`}
                icon={FACEBOOK_ICON}
                activeCase={activeCase}
            />
        )
    }, {
        id: 3,
        label: `Memorialize ${activeCase.fname}'s Instagram`,
        icon: INSTAGRAM_ICON,
        content: (
            <MemorializeInstagram
                heading={`Memorialize ${activeCase.fname}'s Instagram`}
                icon={INSTAGRAM_ICON}
                activeCase={activeCase}
            />
        )
    }, {
        id: 4,
        label: `Delete ${activeCase.fname}'s Instagram`,
        icon: INSTAGRAM_ICON,
        content: (
            <DeleteInstagram
                heading={`Delete ${activeCase.fname}'s Instagram`}
                icon={INSTAGRAM_ICON}
                activeCase={activeCase}
            />
        )
    }, {
        id: 5,
        label: `Close ${activeCase.fname}'s Twitter`,
        icon: TWITTER_ICON,
        content: (
            <CloseTwitter
                heading={`Close ${activeCase.fname}'s Twitter`}
                icon={TWITTER_ICON}
                activeCase={activeCase}
            />
        )
    }, {
        id: 6,
        label: `Close ${activeCase.fname}'s Linkedin`,
        icon: LINKEDIN_ICON,
        content: (
            <CloseLinkedin
                heading={`Close ${activeCase.fname}'s Linkedin`}
                icon={LINKEDIN_ICON}
                activeCase={activeCase}
            />
        )
    }, {
        id: 7,
        label: `Close ${activeCase.fname}'s Pinterest`,
        icon: PINTEREST_ICON,
        content: (
            <ClosePinterest
                heading={`Close ${activeCase.fname}'s Pinterest`}
                icon={PINTEREST_ICON}
                activeCase={activeCase}
                setAppSnackbar={setAppSnackbar}
                userData={userData}
            />
        ),
    },
    ];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: `Close ${activeCase.fname}'s Social Media`,
                description,
                listItems
            }}
        />
    );
};

export default SocialMediaDialog;
