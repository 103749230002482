import * as React from 'react';
import * as classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { SlideTransition } from './Transitions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RED_COLOR_2 } from '../../constants/colorVariables';
import { useState } from 'react';
import { withDialogMounter } from '../../DialogMounter';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { GStyles } from '../../styles/GStyles';

const useStyles = makeStyles((theme: Theme) => ({
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: [[0, 1, 10, 1, 'rgba(0, 0, 0, 0.2)']].join(),
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: (props: Props) => props.onDelete ? '0 0 12px' : '0 0 24px',
        width: 268,
        margin: ' auto',
        textAlign: 'center',
        '@media (min-width: 400px)': {
            width: 308
        },
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        '@media (min-width: 400px)': {
            width: 360
        },
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
    description: {
        fontSize: 18,
        color: theme.palette.secondary.main,
        margin: '0 auto',
    },
    flexButton: {
        marginTop: 40,
        display: 'flex',
        justifyContent: 'space-between'
    },
    ButtonSize: {
        color: RED_COLOR_2,
        display: 'flex',
        alignItems: 'stretch'
    },
    deleteButton: {
        color: RED_COLOR_2,
        fontSize: 16,
        fontWeight: 500
    },
}), { name: 'GDialogWithTextField' });

interface Props {
    zIndex: number;
    title: string;
    initialValue: string | null;
    isDialogOpen: boolean;
    description?: string | JSX.Element;
    textFieldLabel: string;
    children?: React.ReactNode;
    titleClass?: string;
    dialogTitleClass?: string;
    textfieldClass?: string;
    footerClass?: string;
    required?: boolean;
    isMultiline?: boolean;
    numberOfRows?: number;
    skipValidation?: boolean;
    renderCustomTextfield?: () => JSX.Element;
    closeDialog: () => void;
    onSave: (value: string) => void;
    onDelete?: () => void;
}

const GDialogWithTextField = (props: Props) => {
    const {
        title,
        zIndex,
        children,
        required,
        titleClass,
        footerClass,
        isMultiline,
        description,
        numberOfRows,
        initialValue,
        isDialogOpen,
        textfieldClass,
        textFieldLabel,
        dialogTitleClass,
        skipValidation,
        renderCustomTextfield,
        onSave,
        onDelete,
        closeDialog,
    } = props;

    const classes = useStyles(props);

    const [value, setValue] = useState<string>(initialValue || '');
    const [saveClicked, setSaveClicked] = useState(false);

    const handleSaveClick = () => {
        if (skipValidation || value && value.trim()) {
            onSave(value || '');
            closeDialog();
        } else {
            setSaveClicked(true);
        }
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={() => closeDialog()}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            classes={{ paper: classes.dialogPaper }}
            style={{ zIndex }}
            maxWidth="xs"
        >
            <DialogTitle
                className={classNames(
                    classes.header,
                    GStyles.backgroundPrimary,
                    dialogTitleClass
                )}
            >
                <ClearIcon
                    className={classNames(classes.clearIcon, GStyles.colorWhite)}
                    onClick={() => closeDialog()}
                />
                <Typography
                    component="p"
                    noWrap
                    className={classNames(GStyles.colorWhite, classes.headerText, titleClass)}
                >
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid
                    container
                    className={GStyles.marginTop20}
                >
                    {description &&
                        <Typography
                            className={classes.description}
                            align="center"
                        >
                            {description}
                        </Typography>
                    }

                    <Grid className={GStyles.width100} mt="20px">
                        <FormControl
                            required={required}
                            className={classNames(
                                GStyles.width100,
                                GStyles.textLeft,
                                textfieldClass,
                            )}
                        >
                            {renderCustomTextfield?.()
                                ?? <TextField
                                    fullWidth
                                    multiline={isMultiline}
                                    maxRows={numberOfRows}
                                    autoComplete="new-password"
                                    label={textFieldLabel}
                                    type="text"
                                    autoFocus
                                    required={required}
                                    value={value}
                                    error={saveClicked && !value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                            }
                        </FormControl>
                    </Grid>

                    {children}

                    <Grid
                        className={classNames(
                            classes.bottomContent,
                            footerClass,
                            onDelete && classes.flexButton
                        )}
                        item
                        xs={12}
                    >
                        {onDelete &&
                            <Button
                                variant="text"
                                startIcon={<DeleteForeverIcon className={classes.ButtonSize} />}
                                className={GStyles.colorRed}
                                onClick={onDelete}
                            >
                                <Typography className={classes.deleteButton}>
                                    DELETE
                                </Typography>
                            </Button>
                        }

                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleSaveClick}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withDialogMounter(GDialogWithTextField);