import * as React from 'react';
import classNames from 'classnames';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar/AppBar';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import {
    ModerationStatus,
    ModerationCategory,
    ModerationItem,
    ModerationPhotoUX,
    ModerationVisitorUX,
    ModerationMemoryUX,
    ModerationCategoryCounts,
    ModerationCategorySingleDisplayLookup,
    UserProfile,
    VisitorForModeration,
    MemoryAuthor,
    ModerationPhotoContributor,
} from '../../../shared/types';
import { StyledProps, styles } from './ModerationContent.styles';
import MemoriesSection from './memories/MemoriesSection';
import VisitorsSection from './visitors/VisitorsSection';
import PhotosSection from './photos/PhotosSection';
import LoadingSpinner from '../../common/LoadingSpinner';
import { canDeleteModerationItem } from '../../../shared/authority/can';
import GButton from '../../common/GButton';
import ModerateText from './ModerateText';
import { GuestPopperUserType } from '../../family/remember/guests/GuestPopper';
import { openGuestPopper } from '../../../actions/Dialog.action';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import { compose } from 'redux';
import { isRememberPage } from '../../../services';
import withStyles from '@mui/styles/withStyles';
import { GStyles } from '../../../styles/GStyles';

interface ModerationTab {
    title: string;
    counts: number | null;
    value: ModerationCategory;
    tabText: string;
}

interface InjectedProps {
    dispatch: AppDispatch;
}

interface Props {
    photos: ModerationPhotoUX[];
    visitors: ModerationVisitorUX[];
    memories: ModerationMemoryUX[];
    moderationStatus: ModerationStatus;
    zIndex: number;
    category: ModerationCategory;
    userData: UserProfile;
    counts: ModerationCategoryCounts | null;
    isLoadingItems: boolean;
    isLoadingMoreItems: boolean;
    hasMoreData: boolean;
    showIndividualCards: boolean;
    onLoadMore: () => void;
    onCategoryChange: (cat: ModerationCategory) => void;
    onMakeDecision: (items: ModerationItem[], isApproval: boolean) => void;
    onDeleteItem: (item: ModerationItem) => void;
}

interface State {
    activeItem: ModerationItem | null;
    menuAnchor: HTMLElement | null;

}

type CombinedProps = Props & StyledProps & InjectedProps;

class ModerationContent extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            activeItem: null,
            menuAnchor: null,
        };
    }

    renderMenu = () => {
        const {
            zIndex,
            classes,
            category,
            userData,
            moderationStatus,
            onMakeDecision,
            onDeleteItem,
        } = this.props;
        const { menuAnchor, activeItem } = this.state;

        if (!activeItem) {
            return;
        }

        const type = ModerationCategorySingleDisplayLookup[category];

        return (
            <Menu
                open={Boolean(menuAnchor)}
                anchorEl={menuAnchor}
                onClose={e => this.closeMenu()}
                style={{ zIndex: zIndex + 1 }}
            >
                {moderationStatus !== ModerationStatus.approved &&
                    <MenuItem
                        className={classes.approveColor}
                        onClick={() => {
                            onMakeDecision([activeItem], true);
                            this.closeMenu();
                        }}
                    >
                        <DoneIcon />&nbsp;Approve {type}
                    </MenuItem>
                }
                {moderationStatus !== ModerationStatus.blocked &&
                    <MenuItem
                        className={classes.blockColor}
                        onClick={() => {
                            onMakeDecision([activeItem], false);
                            this.closeMenu();
                        }}
                    >
                        <CloseIcon />&nbsp;Block {type}
                    </MenuItem>
                }
                {category !== ModerationCategory.visitors && canDeleteModerationItem(userData, activeItem) &&
                    <MenuItem
                        className={classes.blockColor}
                        onClick={() => {
                            onDeleteItem(activeItem);
                            this.closeMenu();
                        }}
                    >
                        <DeleteIcon />&nbsp;Delete {type}
                    </MenuItem>
                }
            </Menu >
        );
    };

    renderTabContent = () => {
        const {
            classes,
            visitors,
            memories,
            photos,
            category,
            moderationStatus,
            showIndividualCards,
            onMakeDecision,
        } = this.props;

        const isStatusPending = moderationStatus === ModerationStatus.pending;
        const visitorsCategory = category === ModerationCategory.visitors;
        const memoriesCategory = category === ModerationCategory.memories;
        const photosCategory = category === ModerationCategory.photos;

        const noVisitorPending = visitorsCategory && !visitors.length && isStatusPending;
        const noMemoriesPending = memoriesCategory && !memories.length && isStatusPending;
        const noPhotosPending = photosCategory && !photos.length && isStatusPending;

        const ifOnRememberPage = isRememberPage();

        if (noVisitorPending || noMemoriesPending || noPhotosPending) {
            return <ModerateText category={category} />;
        }

        if (visitorsCategory && visitors.length) {
            return (
                <VisitorsSection
                    openMenu={this.openMenu}
                    visitors={visitors}
                    moderationStatus={moderationStatus}
                    classes={classes}
                    onDecision={onMakeDecision}
                    openGuestPopper={this.openGuestPopper}
                    isRememberPage={ifOnRememberPage}
                    showIndividualCards={showIndividualCards}
                />
            );
        }

        if (memoriesCategory && memories.length) {
            return (
                <MemoriesSection
                    openMenu={this.openMenu}
                    memories={memories}
                    moderationStatus={moderationStatus}
                    onDecision={onMakeDecision}
                    openGuestPopper={this.openGuestPopper}
                    isRememberPage={ifOnRememberPage}
                    showIndividualCards={showIndividualCards}
                />
            );
        }

        if (photosCategory && photos.length) {
            return (
                <PhotosSection
                    photos={photos}
                    moderationStatus={moderationStatus}
                    openMenu={this.openMenu}
                    onDecision={onMakeDecision}
                    openGuestPopper={this.openGuestPopper}
                    isRememberPage={ifOnRememberPage}
                    showIndividualCards={showIndividualCards}
                />
            );
        }

        return null;
    };

    renderTab = ({ title, counts, value, tabText }: ModerationTab) => {
        const { classes, moderationStatus } = this.props;

        return (
            <Tab
                key={value}
                label={
                    <div className={classNames(
                        classes.tabLabel,
                        counts
                        && counts > 0
                        && moderationStatus === ModerationStatus.pending
                        && classes.hasPending
                    )}>
                        <Typography
                            color="secondary"
                            className={classNames(classes.fontStyleInherit, classes.tabTopText)}
                        >
                            {title}
                        </Typography>
                        <Typography color="secondary" className={classes.fontStyleInherit}>
                            {counts} {tabText} <span className={GStyles.textCapitalize}>{value}</span>
                        </Typography>
                    </div>
                }
                color="secondary"
                value={value}
                classes={{
                    root: classNames(
                        classes.tabRoot,
                        classes.displayFlex,
                        moderationStatus === ModerationStatus.approved && classes.approved,
                        moderationStatus === ModerationStatus.blocked && classes.blocked
                    ),
                    selected: GStyles.fontWeight500
                }}
            />
        );
    };

    render() {
        const {
            classes,
            moderationStatus,
            category,
            counts,
            isLoadingItems,
            isLoadingMoreItems,
            hasMoreData,
            onCategoryChange,
            onLoadMore,
        } = this.props;

        const tabText = moderationStatus === ModerationStatus.approved && 'Approved'
            || moderationStatus === ModerationStatus.blocked && 'blocked'
            || moderationStatus === ModerationStatus.pending && 'Pending'
            || '';

        const hasPendingVisitors = category === ModerationCategory.visitors && counts && counts.visitors > 0;
        const hasPendingMemories = category === ModerationCategory.memories && counts && counts.memories > 0;
        const hasPendingPhotos = category === ModerationCategory.photos && counts && counts.photos > 0;

        const indicatorClass = moderationStatus === ModerationStatus.pending
            && (hasPendingVisitors || hasPendingMemories || hasPendingPhotos)
            && GStyles.backgroundOrange;

        const tabs: ModerationTab[] = [
            {
                value: ModerationCategory.visitors,
                title: 'Step 1',
                counts: counts && counts.visitors,
                tabText,
            },
            {
                value: ModerationCategory.memories,
                title: 'Step 2',
                counts: counts && counts.memories,
                tabText,
            },
            {
                value: ModerationCategory.photos,
                title: 'Step 3',
                counts: counts && counts.photos,
                tabText,
            }
        ];

        return <>
            <Grid item xs={12} className={classes.dialogHeaderContainer}>
                <AppBar position="static" className={classes.tabAppbar}>
                    <Tabs
                        value={category}
                        onChange={(_, c: ModerationCategory) => onCategoryChange(c)}
                        classes={{
                            root: classes.tabsRoot,
                            flexContainer: classes.tabsFlexContainer,
                            scrollableX: classes.tabsScrollableContainer,
                            indicator: classNames(
                                classes.indicatorClass,
                                moderationStatus === ModerationStatus.approved && classes.approved,
                                moderationStatus === ModerationStatus.blocked && classes.blocked,
                                indicatorClass
                            )
                        }}
                        color="secondary"
                        variant="scrollable"
                        scrollButtons={false}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        {tabs.map(this.renderTab)}
                    </Tabs>
                </AppBar>
            </Grid>

            {isLoadingItems && !isLoadingMoreItems ? <LoadingSpinner /> : this.renderTabContent()}

            {hasMoreData &&
                <Grid
                    container
                    justifyContent="center"
                    className={GStyles.marginTop12}
                >
                    <GButton
                        onClick={onLoadMore}
                        text="Load More"
                        isSpinning={isLoadingMoreItems}
                    />
                </Grid>
            }

            {this.renderMenu()}
        </>;
    }

    openMenu = (e: React.MouseEvent<HTMLElement>, activeItem: ModerationItem) => {
        this.setState({ menuAnchor: e.currentTarget, activeItem });
    };

    closeMenu = () => {
        this.setState({ menuAnchor: null, activeItem: null });
    };

    openGuestPopper = (
        event: React.MouseEvent<HTMLElement>,
        user: VisitorForModeration | MemoryAuthor | ModerationPhotoContributor
    ) => {
        event.persist();
        const target = event.currentTarget;

        const { dispatch, zIndex } = this.props;

        const activeGuest: GuestPopperUserType = {
            user_id: user.user_id,
            photo: user.photo,
            photo_transformations: user.photo_transformations,
            fname: user.fname,
            relationship: user.relationship,
        };

        dispatch(openGuestPopper({
            zIndex: zIndex + 1,
            activeGuest,
            getTarget: () => target
        }));
    };
}

export default compose(
    withState(),
    withStyles(styles<Props & StyledProps>())
)(ModerationContent) as React.ComponentType<Props>;
