import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';
import ButtonBase from '@mui/material/ButtonBase';
import makeGStyles from '../../../../../styles/makeGStyles';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { CaseBelongingUX, PreviewPictureDecision, getDecisionLabel } from '../../../../../shared/types';
import { GREEN_COLOR, ORANGE_COLOR } from '../../../../../constants/colorVariables';
import { getDecisionIcon } from '../addCaseBelongingDialog/AddCaseBelonging.dialog';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { TrackingContentContext } from './CompleteStep.dialog';
import { useGDispatch } from '../../../../../types';
import { openPhotoSwipeDialog } from '../../../../../actions/PhotoSwipe.action';
import { getIntercomTargetProp } from '../../../../../services';

const useStyles = makeGStyles<Props>(theme => ({
    root: {
        color: theme.palette.secondary.main,
        borderRadius: 4,
        position: 'relative',
        display: 'initial !important',
        paddingTop: 0,
    },
    itemName: {
        fontSize: 16,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '80%',
        margin: '0px auto 2px'
    },
    decision: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 50%)',
        display: 'flex',
        width: 'max-content',
        maxWidth: '85%',
        height: 'unset',
        color: theme.palette.common.white,
        backgroundColor: props => props.caseItem.completed_time ? GREEN_COLOR : ORANGE_COLOR,
        '& $label': {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '4px 12px'
        },
        '&:hover': {
            backgroundColor: props => props.caseItem.completed_time ? GREEN_COLOR : ORANGE_COLOR,
        }
    },
    photo: {
        objectFit: 'cover',
        display: 'block',
        borderRadius: 20,
        boxSizing: 'border-box',
        border: props => props.caseItem.completed_time ? `2px solid ${GREEN_COLOR}` : `2px dashed ${ORANGE_COLOR}`,
    },
    noPhotoPlaceholder: {
        border: props => props.caseItem.completed_time ? `2px solid ${GREEN_COLOR}` : `2px dashed ${ORANGE_COLOR}`,
        borderRadius: 20,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        '& p': {
            fontSize: 14
        },
        '& svg': {
            fontSize: 80
        }
    },
    imageContainer: {
        borderRadius: 20,
        overflow: 'hidden',
        width: '100%',
        aspectRatio: '1',
    },
    imageBottomLabel: {
        paddingTop: 12,
        fontSize: 13,
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        color: theme.palette.primary.main,
        margin: '0 auto',
        '&$disabled': {
            cursor: 'initial',
            textDecoration: 'none !important'
        },
        '&:hover': {
            textDecoration: 'underline'
        },
        [theme.breakpoints.up(400)]: {
            fontSize: 15,
        }
    },
    positionRelative: {
        position: 'relative'
    },
    disabled: {},
    label: {}
}), { name: 'GatherCaseBelonging' });

interface Props {
    disableEdit?: boolean;
    caseItem: CaseBelongingUX;
    isCompleted: boolean;
    caseFirstName: string;
    context: TrackingContentContext;
    openBelongingDetailsDialog(caseItem: CaseBelongingUX): void;
}

const GatherCaseBelonging = (props: Props) => {
    const { caseItem, caseFirstName, openBelongingDetailsDialog, disableEdit, context } = props;

    const classes = useStyles(props);
    const dispatch = useGDispatch();

    const { name, decision, photo_url } = caseItem;
    const label = decision && getDecisionLabel(decision, !!caseItem.completed_time, caseFirstName);
    const decisionIcon = getDecisionIcon(decision);
    const isFamilyPage = context === TrackingContentContext.familyPage;

    const handleClick = () => {
        dispatch(openPhotoSwipeDialog('CASE_BELONGINGS', photo_url));
    };

    return (
        <Grid item className={classes.root} component="li">
            {!isFamilyPage &&
                <Typography align="center" color="secondary" className={classes.itemName}>
                    {name}
                </Typography>
            }

            <Tooltip
                placement="top"
                enterDelay={400}
                title={disableEdit
                    ? 'Belongings cannot be edited from this view. You can edit them from the "Items" tab.'
                    : ''
                }
            >
                <div className={classes.positionRelative}>
                    <ButtonBase
                        className={classNames(classes.imageContainer, isFamilyPage && classes.backgroundWhite)}
                        disabled={!!(!photo_url && disableEdit)}
                        onClick={e => disableEdit
                            ? photo_url && handleClick()
                            : openBelongingDetailsDialog(caseItem)}
                        {...getIntercomTargetProp(`TrackingPage-ItemsTab-BelongingItem`)}
                    >
                        {photo_url
                            ? <img
                                src={photo_url}
                                alt="Case Belonging Image"
                                height="100%"
                                width="100%"
                                className={classes.photo}
                            />
                            : <div className={classes.noPhotoPlaceholder}>
                                <ImageNotSupportedIcon color="secondary" />
                                <Typography color="secondary" aria-hidden={true}>No Image</Typography>
                            </div>
                        }
                    </ButtonBase>

                    {label &&
                        <Chip
                            className={classes.decision}
                            classes={{ label: classNames(classes.label, isFamilyPage && classes.fontSize12) }}
                            onClick={disableEdit ? undefined : e => openBelongingDetailsDialog(caseItem)}
                            label={label}
                        />
                    }
                </div>
            </Tooltip>

            {!caseItem.completed_time && !isFamilyPage &&
                <span
                    className={classNames(classes.imageBottomLabel, disableEdit && classes.disabled)}
                    onClick={e => !disableEdit && openBelongingDetailsDialog(caseItem)}
                >
                    {decisionIcon}&nbsp;
                    {PreviewPictureDecision[caseItem.decision]}
                </span>}
        </Grid>
    );
};

export default GatherCaseBelonging;
