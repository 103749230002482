import { GREEN_COLOR_4 } from '../../../../../constants/colorVariables';
import makeGStyles from '../../../../../styles/makeGStyles';

export const useCompleteStepStyles = makeGStyles((theme) => ({
    root: {},
    paper: {
        width: '100%',
    },
    caseName: {
        fontSize: 24,
        fontWeight: 300,
    },
    dialogContent: {
        padding: '168px 0 0 !important',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    photo: {
        fontSize: 56,
    },
    footerContainer: {
        marginTop: 32,
        '& button': {
            fontSize: 12,
            '@media (min-width: 375px)': {
                fontSize: 14,
                padding: 8
            }
        }
    },
    completeSectionsText: {
        minHeight: 16,
        maxHeight: 16,
        marginBottom: 4
    },
    marginTop60: {
        marginTop: 60
    },
    marginBottom12: {
        marginBottom: 12
    }
}), { name: 'CompleteStepStyles' });

export const useCompleteStepSwitchStyles = makeGStyles(theme => ({
    backgroundGreenColor: {
        backgroundColor: GREEN_COLOR_4
    },
    switchRadius: {
        borderRadius: '0 0 8px 8px',
        minHeight: 36,
        '& p': {
            lineHeight: '36px',
            fontSize: 11,
            '@media (min-width: 400px)': {
                fontSize: 14
            }
        }
    },
    whiteColor: {
        color: '#fff !important'
    },
    marginLeft_4: {
        marginLeft: -4
    },
    formControlLabel: {
        marginLeft: 0,
        maxHeight: 28,
        padding: '4px 0',
    },
    secondaryBackground: {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        opacity: '1 !important'
    },
}), { name: 'CompleteStepSwitchStyles' });
