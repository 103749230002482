import { noop } from 'lodash';
import SignatureCanvas from 'react-signature-canvas';

import Grid from '@mui/material/Grid';

import {
    CaseTaskSkipRequest,
    CaseTaskUX,
    GatherCaseUX,
    NoteComponentConfig,
    S3FileCreateRequest,
    UserProfile
} from "../../../../shared/types";
import BaseStepDialog from "../dialogs/baseStepDialog/BaseStepDialog";
import SignatureComponent from "../dialogs/completeStepDialog/components/SignatureComponent";
import { useRef, useState } from 'react';
import makeGStyles from '../../../../styles/makeGStyles';
import { joinNameParts } from '../../../../services';
import { TrackingStepIconLookup } from './saveStepDialog/trackingStepIcon.list';
import CompleteStepDialogFooter from '../dialogs/completeStepDialog/CompleteStep.dialogFooter';
import NoteComponent from '../dialogs/completeStepDialog/components/NoteComponent';
import { OnAnswerChangedFunction } from './completeStepDialog/components/types';
import { skipTask } from '../../../../actions/task/Task.action';
import { useGDispatch } from '../../../../types';
import { uploadSignatureFromRef } from '../utils';

const useStyles = makeGStyles(theme => ({
    signatureContainer: {
        marginTop: 40,
        '@media (min-width: 600px)': {
            marginTop: 20,
        }
    }
}), { name: 'SkipStepDialog' });

interface Props {
    isOpen: boolean;
    user: UserProfile;
    selectedCase: GatherCaseUX;
    zIndex: number;
    activeStep: CaseTaskUX;
    closeDialog: () => void;
}

const SkipStepDialog = (props: Props) => {
    const { isOpen, zIndex, user, activeStep, closeDialog, selectedCase } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const signatureRef = useRef<SignatureCanvas | null>(null);

    const isSkipped = !!activeStep.skipped_time;
    const signer = isSkipped ? activeStep.skipped_by : user;

    const [saveAttempted, setSaveAttempted] = useState(false);
    const [notes, setNotes] = useState<string>(isSkipped && activeStep.note || '');
    const [didSigned, setDidSigned] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const stepIcon = activeStep.icon && TrackingStepIconLookup[activeStep.icon];

    const signerFullName = signer ? joinNameParts(signer) : null;

    const handleSkipClick = async () => {
        if (isSkipped) {
            closeDialog();
            return;
        }

        setSaveAttempted(true);

        setIsSaving(true);
        const signature: S3FileCreateRequest | null = await uploadSignatureFromRef({
            ref: signatureRef.current,
            dispatch,
            caseUuid: selectedCase.uuid,
        });
        if (!signature) {
            setIsSaving(false);
            return;
        }
        const skipRequest: CaseTaskSkipRequest = {
            signature: signature,
            note: notes,
        };

        await dispatch(skipTask({
            taskId: activeStep.id,
            caseUuid: selectedCase.uuid,
            taskTemplateType: null,
            skipRequest
        }));

        setIsSaving(false);
        closeDialog();
    };

    const handleAnswerChange: OnAnswerChangedFunction = (_, answer) => {
        setNotes(typeof answer === 'object' ? answer[0].answer : answer);
    };

    const clearSignature = () => {
        signatureRef.current?.clear();
        signatureRef.current?.on();
        setDidSigned(false);
    };

    const config: NoteComponentConfig = {
        note_instructions: 'Notes about skipping...',
        is_required: false,
        answer: notes,
    };

    return (
        <BaseStepDialog
            zIndex={zIndex}
            entityFullName={activeStep.skipped_by ? joinNameParts(activeStep.skipped_by) : joinNameParts(user)}
            open={isOpen}
            stepIcon={stepIcon}
            stepImagePublicId={null}
            stepImageFallbackURL={null}
            stepTitle={`SKIP: ${activeStep.title}`}
            closeDialog={closeDialog}
            isStepSkipped={!!activeStep.skipped_by}
        >
            <NoteComponent
                componentId={-1}
                config={config}
                saveAttempted={saveAttempted}
                isDisabled={isSkipped}
                onValidityChanged={noop}
                onAnswerChanged={handleAnswerChange}
            />

            <Grid className={classes.signatureContainer}>
                <SignatureComponent
                    signatureUrl={activeStep.signature_url}
                    heading="I take responsibility that this step is okay to skip"
                    signerFullName={signerFullName}
                    canvasRef={signatureRef}
                    onStopDrawing={() => setDidSigned(true)}
                    clearSignature={clearSignature}
                    hasSignError={saveAttempted && !didSigned}
                    hasSigned={didSigned}
                    signerStateLicenceNumber={undefined}
                />
            </Grid>

            <CompleteStepDialogFooter
                user={user}
                stepTitle={activeStep.title}
                isSaving={isSaving}
                hasError={Boolean(saveAttempted && signatureRef.current?.isEmpty())}
                onCompleteTaskClick={handleSkipClick}
                skippedBy={activeStep.skipped_by}
                isSkipStep
            />
        </BaseStepDialog>
    );
};

export default SkipStepDialog;
