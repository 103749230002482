import { Component } from 'react';

import Typography from '@mui/material/Typography';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Gesture from '@mui/icons-material/Gesture';

import PrintDialog, { PrintOptionsType } from '../../dialogs/Print.dialog';
import { GatherCaseUX } from '../../../../../shared/types';
import { GLOBAL_STYLED_PROPS } from '../../../../../styles';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    bottomText: {
        padding: '16px 24px 0px',
        textAlign: 'center',
        fontSize: 15,
        fontWeight: 300,
    },
    cardHeight: {
        height: 170,
    },
    paddingBottom16: {
        paddingBottom: 16,
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'bottomText' | 'cardHeight'
    | 'paddingBottom16'>;

interface Props {
    selectedCase: GatherCaseUX;
    isDialogOpen: boolean;
    closeDialog: () => void;
    zIndex: number;
    printItemizedStatement: () => void;
    printReceipt: () => void;
}

interface State {
    receiptType: PrintOptionsType | null;
    isPrintButtonClicked: boolean;
}

const INITIAL_STATE: State = {
    receiptType: null,
    isPrintButtonClicked: false,
};

class PrintReceiptDialog extends Component<StyledProps, State> {
    state: State = INITIAL_STATE;

    getFooter = () => {
        const { classes } = this.props;
        const { receiptType } = this.state;
        const itemizedStatement = receiptType === PrintOptionsType.ItemizedStatement;
        const quickStatement = receiptType === PrintOptionsType.QuickStatement;

        const buttonText = itemizedStatement
            ? `Itemized Statement`
            : quickStatement
                ? `Quick Statement`
                : `Receipt`;

        const helperText = itemizedStatement
            ? `Includes all contract items with no legal language`
            : quickStatement
                ? `This does not include individual items`
                : `Please select receipt type above`;

        const bottomText = <Typography color="secondary" className={classes.bottomText}>
            In addition to the two options listed above, you can also print an individual
            payer's invoice by clicking the "View Payer Invoice" button under each payer card.
        </Typography>;

        return {
            buttonText: <><LocalPrintshopIcon />&nbsp;Print {buttonText}</>,
            helperText: helperText,
            bottomText: bottomText,
            onClick: () => this.print(),
        };
    };

    getOptionsList = () => {
        const { receiptType } = this.state;

        const options = [
            {
                id: PrintOptionsType.QuickStatement,
                primaryText: PrintOptionsType.QuickStatement,
                secondaryText: <Typography>This is a quick receipt showing all payments made against the
                    contract total and the remaining balance in a small form. This does not show the
                    individual contract items just the total.</Typography>,
                icon: <LocalPrintshopIcon />,
                isActive: (receiptType === PrintOptionsType.QuickStatement) ? true : false,
                onClick: () => this.setState({ receiptType: PrintOptionsType.QuickStatement }),
            },
            {
                id: PrintOptionsType.ItemizedStatement,
                primaryText: PrintOptionsType.ItemizedStatement,
                secondaryText: <Typography>The Itemized Statement includes an itemized list of every item
                    on the contract, their prices and their totals. The payments made against those totals
                    will show at the bottom with an outstanding balance shown below.</Typography>,
                icon: <Gesture />,
                isActive: (receiptType === PrintOptionsType.ItemizedStatement) ? true : false,
                onClick: () => this.setState({ receiptType: PrintOptionsType.ItemizedStatement })
            }
        ];

        return options;
    };

    render() {
        const {
            isDialogOpen,
            selectedCase,
            zIndex
        } = this.props;

        const heading = <><LocalPrintshopIcon />&nbsp;{selectedCase.fname}'s Case Statements</>;

        return (
            <PrintDialog
                onExited={this.performActionOnExit}
                heading={heading}
                isDialogOpen={isDialogOpen}
                optionsList={this.getOptionsList()}
                closeDialog={this.closeDialog}
                zIndex={zIndex}
                footerContent={this.getFooter()}
            />
        );
    }

    performActionOnExit = () => {
        const { printReceipt, printItemizedStatement } = this.props;
        const { receiptType } = this.state;
        const itemizedStatement = receiptType === PrintOptionsType.ItemizedStatement;
        const quickStatement = receiptType === PrintOptionsType.QuickStatement;

        if (!this.state.isPrintButtonClicked) {
            return;
        }
        if (itemizedStatement) {
            printItemizedStatement();
        }
        if (quickStatement) {
            printReceipt();
        }
        this.setState(INITIAL_STATE);
    };

    print = () => {
        this.setState({ isPrintButtonClicked: true });
        this.props.closeDialog();
    };

    closeDialog = () => {
        this.setState(INITIAL_STATE);
        this.props.closeDialog();
    };
}

export default withGStyles(styles)(PrintReceiptDialog);
