import { convertHexToRGBA } from '../../../../services';
import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';
import { WithGStyles } from '../../../../styles/WithGStyles';

export const styles = <Props extends object>(): StyleRulesCallback<Theme, Props> => theme => ({
    root: {},
    header: {
        margin: 'auto 8px',
        textAlign: 'center',
        padding: '0',
        color: theme.palette.secondary.main,
        borderBottom: '1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& label': {
            width: 'fit-content',
            cursor: 'pointer',
        },
        '& $headerRow': {
            display: 'flex',
            width: '100%',
        },
    },
    top: {
        margin: 'auto 8px',
        textAlign: 'center',
        paddingTop: 20
    },
    middle: {
        margin: 'auto 8px',
        textAlign: 'center',
        padding: '24px 0',
        '& $button': {
            margin: 'auto 4px',
            '&$restricted': {
                margin: '8px auto 0'
            }
        },
    },
    last: {
        margin: 'auto 8px',
        padding: '12px 0',
        textAlign: 'center',
        color: theme.palette.secondary.main,
        borderTop: '1px solid',
    },
    paddingBottom12: {
        paddingBottom: 12,
    },
    paddingTop12: {
        paddingTop: 12,
    },
    paper: {
        maxWidth: 288,
        width: 288,
        '@media (min-width: 360px)': {
            maxWidth: 330,
            width: 330,
        },
        boxShadow: theme.shadows[5],
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            left: '4px !important'
        },
    },
    arrowLeft: {
        left: '-4px !important'
    },
    popoverPaper: {
        top: '64px !important',
        borderRadius: 4,
        overflow: 'initial',
        '@media (min-width: 600px)': {
            top: '72px !important',
        }
    },
    popoverContainer: {
        maxWidth: 288,
        width: 288,
        '@media (min-width: 360px)': {
            maxWidth: 330,
            width: 330,
        },
        boxShadow: theme.shadows[5],
    },
    contractStatusContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 0,
        '& $button': {
            margin: 8
        },
    },
    lockIcon: {
        fontSize: 36,
    },
    heading: {
        fontSize: 18,
        lineHeight: '2em'
    },
    description: {
        fontSize: 14,
        fontWeight: 200
    },
    uploadSignedContract: {
        '& $li': {
            height: 207,
            width: 160,
            border: '1px dashed',
            margin: 8,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            color: theme.palette.secondary.main,
            cursor: 'pointer',
            position: 'relative',
            '&$thumbnail': {
                border: 'none',
                cursor: 'initial',
                backgroundSize: 'cover !important',
                backgroundRepeat: 'no-repeat !important',
                backgroundPosition: 'center center !important',
            },
            '&$fab': {
                border: 'none',
                flexDirection: 'column',
                width: 70,
            },
            '& p': {
                lineHeight: '2.5em'
            }
        },
    },
    formControlLabel: {
        marginRight: 0,
        width: '100%'
    },
    fullscreenButton: {
        display: 'none',
        minWidth: 160,
        padding: '8px 4px',
        '@media (min-width: 960px)': {
            display: 'flex',
        }
    },
    downloadIcon: {
        height: 32,
        width: 32,
        filter: 'drop-shadow(0 0px 2px gray)',
        color: '#fff',
        position: 'absolute',
        bottom: 0,
        left: 0,
        margin: 4,
        '& svg': {
            fontSize: 18
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)'
        },
    },
    deleteIcon: {
        height: 32,
        width: 32,
        color: '#fff',
        position: 'absolute',
        bottom: 0,
        right: 0,
        filter: 'drop-shadow(0 0px 2px gray)',
        margin: 4,
        '& svg': {
            fontSize: 18
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)'
        },
    },
    circularProgress: {
        zIndex: 1,
    },
    arrow: {
        content: '""',
        width: 20,
        height: 20,
        transform: 'rotate(-45deg)',
        background: theme.palette.common.white,
        position: 'absolute',
        top: -10,
    },
    show: {
        opacity: 1,
    },
    hide: {
        opacity: 0,
    },
    popperDefaultButton: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (min-width: 360px)': {
            display: 'flex',
        }
    },
    showPricesContainer: {
        width: 'fit-content',
        '& label': {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily
        }
    },
    li: {},
    fab: {},
    restricted: {},
    button: {},
    thumbnail: {},
    headerRow: {},
});

export type StyledProps = WithGStyles<'root' | 'header' | 'top' | 'middle' | 'last' | 'paddingBottom12' | 'popper'
    | 'paddingTop12' | 'paper' | 'popoverPaper' | 'popoverContainer' | 'contractStatusContent' | 'lockIcon' | 'heading'
    | 'description' | 'uploadSignedContract' | 'formControlLabel' | 'fullscreenButton' | 'downloadIcon'
    | 'deleteIcon' | 'circularProgress' | 'arrow' | 'show' | 'hide' | 'popperDefaultButton' | 'showPricesContainer'
    | 'li' | 'fab' | 'restricted' | 'button' | 'thumbnail' | 'headerRow' | 'arrowLeft'>;

export const contractStatusContainerStyles = <Props extends object>(): StyleRulesCallback<Theme, Props> =>
    theme => ({
        contractStatusContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& p': {
                textTransform: 'capitalize'
            },
            '& $iconGrid': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& $icon': {
                    display: 'inline-block',
                    margin: 'auto 2px',
                },
                '& $hr': {
                    opacity: 1,
                    height: 2,
                    background: theme.palette.primary.main,
                    display: 'inline-block',
                    width: 'calc(50% - 12px)', // subtract the icon's half width
                    '&$disabled': {
                        background: convertHexToRGBA(theme.palette.primary.main, 0.35),
                        height: 1
                    },
                },
            },
            '& $disabled': {
                '& $icon': {
                    opacity: 0.35
                },
                '& p': {
                    opacity: 0.35
                }
            },
        },
        iconGrid: {},
        disabled: {},
        icon: {},
        hr: {},
    });

export type ContractStatusContainerStyledProps = WithGStyles<'contractStatusContainer' | 'disabled' | 'icon'
    | 'iconGrid' | 'hr'>;