import * as React from 'react';

import { SvgIconProps } from '@mui/material/SvgIcon';

import ModeComment from '@mui/icons-material/ModeComment';
import PagesIcon from '@mui/icons-material/Pages';
import Filter9Plus from '@mui/icons-material/Filter9Plus';
import Filter9 from '@mui/icons-material/Filter9';
import Filter8 from '@mui/icons-material/Filter8';
import Filter7 from '@mui/icons-material/Filter7';
import Filter6 from '@mui/icons-material/Filter6';
import Filter5 from '@mui/icons-material/Filter5';
import Filter4 from '@mui/icons-material/Filter4';
import Filter3 from '@mui/icons-material/Filter3';
import Filter2 from '@mui/icons-material/Filter2';
import Filter1 from '@mui/icons-material/Filter1';

import { StyledProps, iconStyle } from './styles';
import { ItemGrid, SecondaryGridProps } from './widgets';
import { getPhotoUrl } from '../../../../services';
import {
    getVariablePriceDisplay,
    getContractItemDisplayPrice,
    formatPrice,
    formatDinero,
    getContractItemPriceAdjustment,
} from '../../../../shared/goods_and_services/pricing';
import {
    getLatestItem,
    getOriginalItem,
} from '../../../../shared/goods_and_services/utils';
import {
    ProductContractItemUX,
    ProductCategoryEnum,
    ProductUX,
} from '../../../../shared/types';

interface Props {
    contractItemRevisions: ProductContractItemUX[];
    contractRevisionIds: number[];
    printMode?: boolean;
    canUserEditContract: boolean;
    product: ProductUX | null;
    isCondensedView: boolean;
    hidePackageItemPrices?: boolean;
    onMenuClick: (element: HTMLElement) => void;
    onItemClick: () => void;
    onNoteClick: () => void;
    onPriceAdjustmentClick: () => void;
    openProductTaxDetailPopper?: (
        element: HTMLElement,
        latestItem: ProductContractItemUX | null) => void;
    showTaxIcon?: boolean;
}

type CombinedProps = StyledProps & Props;

const filterIconsLookup: Record<string | number, React.ComponentType<SvgIconProps>> = {
    1: Filter1,
    2: Filter2,
    3: Filter3,
    4: Filter4,
    5: Filter5,
    6: Filter6,
    7: Filter7,
    8: Filter8,
    9: Filter9,
    '9Plus': Filter9Plus,
};
// use this element for print mode
const getRevisionIcon = (revNumber: number) => {
    const Icon = revNumber > 9 ? filterIconsLookup['9Plus'] : filterIconsLookup[revNumber];

    return Icon
        ? <Icon style={{ ...iconStyle, marginRight: 6, fontSize: 12 }} />
        : <></>;
};

const modeCommentJSX = <ModeComment style={{ ...iconStyle, marginRight: 6, fontSize: 12 }} />;

export const generateQuantityLabel = (
    quantity: number,
    labelText: string | null,
    units?: string,
    price?: string,
    isCurrentRev?: boolean,
    revNumber?: number,
): SecondaryGridProps => ({
    label: labelText ? `${labelText} ` : '',
    value: `(Total ${units ? `${units}${!units.endsWith('s') ? 's' : ''}` : 'quantity'}: ${quantity})`,
    price,
    isCurrentRev,
    iconJSX: revNumber && revNumber > 1 ? getRevisionIcon(revNumber) : undefined,
});

export const generateTagsLabel = (
    tags: Record<string, string>,
    isCurrentRev: boolean,
): SecondaryGridProps => ({
    label: Object.keys(tags).map(t => `${t}: ${tags[t]}`).join(' | '),
    isCurrentRev,
});

export const generatePersistentContractTextLabel = (
    persistentContractText: string,
    isCurrentRev: boolean,
): SecondaryGridProps => ({
    label: persistentContractText,
    isCurrentRev,
});

const strikePrices = (secondaryGrid: SecondaryGridProps[]) => secondaryGrid.map((s) => ({
    ...s,
    isPriceStriked: true,
}));

const generatePriceAdjustment = (
    contractItem: ProductContractItemUX,
    priceAdjustment: Dinero.Dinero,
    isCurrentRev: boolean,
    revNumber?: number,
    onPriceAdjustmentClick?: () => void,
): SecondaryGridProps => {
    const priceTotalStr = getContractItemDisplayPrice(contractItem);
    const priceDiffStr = formatDinero(priceAdjustment);
    const adjustmentType = priceAdjustment.isNegative() ? 'discount' : 'premium';

    return {
        label: `${priceDiffStr} ${adjustmentType} applied`,
        price: priceTotalStr,
        isCurrentRev,
        iconJSX: revNumber && revNumber > 1 ? getRevisionIcon(revNumber) : undefined,
        onClick: onPriceAdjustmentClick
    };
};

const ContractItemWithRevisions = (props: CombinedProps) => {
    const {
        classes,
        contractItemRevisions,
        printMode,
        contractRevisionIds,
        product,
        onMenuClick,
        onItemClick,
        onNoteClick,
        canUserEditContract,
        isCondensedView,
        onPriceAdjustmentClick,
        hidePackageItemPrices,
        openProductTaxDetailPopper,
        showTaxIcon
    } = props;

    const originalItem = getOriginalItem(contractItemRevisions);
    const latestItem = getLatestItem(contractItemRevisions);
    const itemRevisions = contractItemRevisions.filter((item) => item.id !== originalItem.id);
    const currentContractRevisionId = contractRevisionIds[contractRevisionIds.length - 1];

    if (!latestItem) {
        return null;
    }

    const isRemoved = latestItem.delete_revision !== null;
    let secondaryGrid: SecondaryGridProps[] = [];
    let isOriginalPriceChanged = false;

    const markNew = isRemoved && latestItem.delete_revision === currentContractRevisionId ||
        originalItem.insert_revision === currentContractRevisionId && contractRevisionIds.length > 1;

    if (product && product.display_tags && Object.keys(product.tags).length !== 0) {
        secondaryGrid.push(generateTagsLabel(
            product.tags,
            markNew,
        ));
    }

    if (product && product.persistent_contract_text) {
        secondaryGrid.push(generatePersistentContractTextLabel(
            product.persistent_contract_text,
            markNew,
        ));
    }

    if (product && product.var_increment_units) {
        const variablePricing = getVariablePriceDisplay(product);
        secondaryGrid.push(generateQuantityLabel(
            originalItem.quantity,
            variablePricing,
            product.var_increment_units,
            undefined,
            isRemoved && latestItem.delete_revision === currentContractRevisionId,
            undefined,
        ));
    }

    // price check for original item - this is different from other items because we want to show
    // price changes that happen on the initial revision (original_price !== price)
    if (originalItem.price_adjustment) {
        isOriginalPriceChanged = true;
        const priceAdjustment = getContractItemPriceAdjustment(originalItem);

        secondaryGrid.push(generatePriceAdjustment(
            originalItem,
            priceAdjustment,
            false,
            undefined,
            onPriceAdjustmentClick
        ));
    }

    itemRevisions.forEach((itemRevision, i) => {
        // itemRevisions[i+1] === itemRevisions[i] because itemRevisions has the initial revision removed
        const prevItemRevision = contractItemRevisions[i];
        const itemInsertRevNumber = contractRevisionIds.indexOf(itemRevision.insert_revision) + 1;
        const isCurrentRev = itemRevision.insert_revision === currentContractRevisionId;

        if (itemRevision.price_adjustment !== prevItemRevision.price_adjustment) {
            isOriginalPriceChanged = true;
            secondaryGrid = strikePrices(secondaryGrid); // strike all past prices, because this is the new price
            const priceAdjustment = getContractItemPriceAdjustment(itemRevision)
                .subtract(getContractItemPriceAdjustment(prevItemRevision));
            secondaryGrid.push(generatePriceAdjustment(
                itemRevision,
                priceAdjustment,
                isCurrentRev,
                itemInsertRevNumber,
            ));
        }

        if (itemRevision.quantity !== prevItemRevision.quantity) {
            isOriginalPriceChanged = true;
            secondaryGrid = strikePrices(secondaryGrid); // strike all past prices, because this is the new price
            const price = getContractItemDisplayPrice(itemRevision);

            secondaryGrid.push(generateQuantityLabel(
                itemRevision.quantity,
                'Adjusted',
                product && product.var_increment_units || undefined,
                price,
                isCurrentRev,
                itemInsertRevNumber,
            ));
        } else if (itemRevision.list_price !== prevItemRevision.list_price) {
            isOriginalPriceChanged = true;
            secondaryGrid = strikePrices(secondaryGrid); // strike all past prices, because this is the new price
            const iconJSX = itemInsertRevNumber && itemInsertRevNumber > 1 ?
                getRevisionIcon(itemInsertRevNumber) : undefined;
            secondaryGrid.push({
                label: 'Price changed',
                price: getContractItemDisplayPrice(itemRevision),
                isCurrentRev,
                iconJSX,
            });
        }
    });

    if (isRemoved && latestItem.delete_revision !== null) {
        const isRemovedInCurrentRev = latestItem.delete_revision === currentContractRevisionId;
        const itemDeleteRevNumber = contractRevisionIds.indexOf(latestItem.delete_revision) + 1;
        secondaryGrid = strikePrices(secondaryGrid);
        secondaryGrid.push({
            label: 'Removed',
            iconJSX: getRevisionIcon(itemDeleteRevNumber),
            isCurrentRev: isRemovedInCurrentRev,
        });
    }

    if (originalItem.insert_revision === currentContractRevisionId && contractRevisionIds.length > 1) {
        const itemInsertRevNumber = contractRevisionIds.indexOf(originalItem.insert_revision) + 1;
        secondaryGrid.push({
            label: 'Added',
            iconJSX: getRevisionIcon(itemInsertRevNumber),
            isCurrentRev: true,
        });
    }

    if (latestItem.note) {
        secondaryGrid.push({
            label: latestItem.note,
            isNote: true,
            iconJSX: modeCommentJSX,
            onClick: onNoteClick,
            isCurrentRev: isRemoved && latestItem.delete_revision === currentContractRevisionId,
        });
    }

    const originalItemListPrice = formatPrice(originalItem.list_price, originalItem.asset_type);
    const displayedPrice = hidePackageItemPrices && latestItem.package_id
        ? <><PagesIcon style={{ ...iconStyle, marginRight: 3, fontSize: 12 }} /><span>Included</span></>
        : originalItemListPrice;
    const transformations = [{
        height: 56,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];
    const backgroundImage = product && product.photos[0] && product.category !== ProductCategoryEnum.care_of_loved_one ?
        getPhotoUrl(product.photos[0], transformations) : undefined;

    const displayLabel = latestItem.display_name || latestItem.name;

    return (
        <ItemGrid
            classes={classes}
            item={{
                label: displayLabel,
                price: displayedPrice,
                modelNumber: product && product.display_model_number && product.model_number || undefined,
                manufacturer: product && product.display_manufacturer && product.manufacturer_name || undefined,
                bold: product ? product.bold_contract_text : false,
                underline: product ? product.underline_contract_text : false,
                indent: product ? product.indent_contract_text : false,
                isTaxable: latestItem.tax_rate_id !== null
            }}
            printMode={printMode}
            photo={backgroundImage ? { url: backgroundImage, variant: 'border' } : undefined}
            secondary={secondaryGrid}
            strikeMe={{
                label: isRemoved,
                price: isRemoved || isOriginalPriceChanged,
            }}
            warning={markNew}
            onClick={onItemClick}
            onMenuClick={isRemoved ? undefined : (e) => onMenuClick(e.currentTarget)}
            canUserEditContract={canUserEditContract}
            isCondensedView={isCondensedView}
            openProductTaxDetailPopper={(e) => openProductTaxDetailPopper && openProductTaxDetailPopper(e, latestItem)}
            showTaxIcon={showTaxIcon}
        />
    );
};

export default ContractItemWithRevisions;
