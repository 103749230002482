import { Fragment, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { throttle } from "lodash";
import classNames from "classnames";

import Collapse from '@mui/material/Collapse';
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import useWindowEvent from "../../../common/hooks/useWindowEvent";

const useStyles = makeStyles({
    displayNone: {
        display: 'none'
    },
    referenceText: {
        position: 'absolute',
        top: 0,
        left: 0,
        visibility: 'hidden', pointerEvents: 'none',
        width: 'fit-content'
    },
    positionRelative: {
        position: 'relative'
    },
    textDecorationNone: {
        textDecoration: 'none !important'
    },
    prewrap: {
        whiteSpace: 'pre-wrap'
    }
}, { name: 'SeeMoreText' });

interface Props {
    minLines: number;
    text: string | null;
    textClass?: string;
    seeMoreTextClass?: string;
    containerClass?: string;
    onTextClick?: MouseEventHandler<HTMLParagraphElement>;
}

const SeeMoreText = (props: Props) => {
    const { text, seeMoreTextClass, textClass, minLines, containerClass, onTextClick } = props;

    const classes = useStyles();

    const secondaryTextRef = useRef<HTMLParagraphElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [showAll, setShowAll] = useState(false);
    const [elements, setElements] = useState<JSX.Element[]>([]);
    const [hasMoreElements, setHasMoreElements] = useState(false);
    const [hover, setHover] = useState(false);

    const setElementsInState = useCallback(() => {
        const splitted = text?.split(/\s\n/g);
        const filteredText = splitted?.filter(split => split.trim());
        const localElements: JSX.Element[] = [];
        let textChunk: string[] = [];

        if (secondaryTextRef.current && filteredText && canvasRef.current) {
            const parentWidth = secondaryTextRef.current.parentElement?.offsetWidth;
            const context = canvasRef.current.getContext('2d');
            let width = 0;

            if (context && parentWidth) {
                const contextStyle = {
                    fontFamily: '"system-ui", sans-serif',
                    fontSize: '14px',
                    fontStyle: 'italic'
                };
                context.font = `${contextStyle.fontStyle} ${contextStyle.fontSize} ${contextStyle.fontFamily}`;

                const measureWidth = (word: string) => Math.ceil(context.measureText(word).width);

                const seeMoreTextWidth = measureWidth('...see more');
                const whitespaceWidth = measureWidth(' ');
                for (let i = 0; i < filteredText.length; i++) {
                    const isLastIter = i === filteredText.length - 1;
                    const isLastLine = localElements.length === minLines - 1;
                    if (localElements.length === minLines && !showAll) {
                        setHasMoreElements(true);
                        break;
                    }
                    const chunk = filteredText[i];
                    const chunkWidth = measureWidth(chunk);

                    if ((chunkWidth + width + whitespaceWidth) < (
                        parentWidth - (isLastLine && !showAll ? seeMoreTextWidth : 0) - 6)
                        && !isLastIter) {
                        textChunk.push(chunk);
                        width = width + chunkWidth + whitespaceWidth;
                    } else {
                        localElements.push(
                            <>
                                <span>
                                    {[...textChunk, (isLastIter ? chunk : '')].join(' ')}
                                    {isLastLine && !showAll &&
                                        <>&nbsp;
                                            <span
                                                className={seeMoreTextClass}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setShowAll(true);
                                                }}
                                                onMouseEnter={e => setHover(true)}
                                                onMouseLeave={e => setHover(false)}
                                                onFocus={e => setHover(true)}
                                                onBlur={e => setHover(false)}
                                            >
                                                ...see more
                                            </span>
                                        </>
                                    }
                                </span>
                                {!isLastIter && <br />}
                            </>
                        );
                        width = measureWidth(chunk) + whitespaceWidth;
                        textChunk = [chunk];
                    }
                }
            }
        }

        setElements(localElements);
    }, [text, showAll, seeMoreTextClass, minLines]);

    const throttledListener = useMemo(() => throttle(setElementsInState, 200), [setElementsInState]);
    useWindowEvent('resize', throttledListener);

    useEffect(() => {
        setElementsInState();

        return () => throttledListener.cancel();
    }, [setElementsInState, throttledListener]);

    const minHeight = secondaryTextRef.current?.offsetHeight;

    if (!text) {
        return null;
    }

    const renderElements = (maxElements?: number) => (
        !elements.length
            ? text
            : elements.map((element, idx) => maxElements !== undefined && idx >= maxElements
                ? null
                : <Fragment key={idx}>{element}</Fragment>)
    );

    return (
        <div className={classNames(classes.positionRelative, containerClass)}>
            <Typography
                ref={secondaryTextRef}
                className={classNames(textClass, classes.referenceText)}
                aria-hidden
            >
                {renderElements(minLines)}
            </Typography>

            <canvas ref={canvasRef} className={classes.displayNone} />

            <Collapse in={showAll} collapsedSize={minHeight} timeout={200}>
                <Typography
                    color="secondary"
                    className={classNames(
                        textClass,
                        showAll && classes.prewrap,
                        hover && !showAll && classes.textDecorationNone
                    )}
                    onClick={onTextClick}
                >
                    "{hasMoreElements ? renderElements() : text}"
                </Typography>
            </Collapse>
        </div>
    );
};

export default SeeMoreText;