import { UserSession } from '../types';
import {
    FuneralHomeUX,
    PhotoTransformationsType,
    PhotoType,
    PhotoTypeEnum,
    FeatureKey,
    FuneralHomeDemoSettings,
    FuneralHomeCreateRequest,
    FuneralHomeUpdateRequest,
    FuneralHomeForGatherView,
    PaginatedResponse,
    FuneralHomeUXPreview,
    FH_LogoType,
    LoadCaseSwitcherResponse,
    LoadPublicGPLResponse,
    UploadPhotoResponse,
    CaseBelongingTableUX,
    CaseBelongingCount,
} from '../shared/types';
import {
    getFromAPI,
    putToAPI,
    postToAPI,
    deleteFromAPI,
    patchAPI
} from '.';
import { registerAppError } from './errors';
import { loadTeam } from './Team.action';
import { setAppSnackbar } from './AppSnackbar.action';
import { loadLocations } from './Location.action';
import { uploadPhoto } from './Photo.action';
import { SetFuneralHomeStartupDocuments } from './Doc.action';
import { AppDispatch } from '../store';
import { StoreState } from '../types';
import { loadCaseLabelsForFuneralHome } from './CaseLabel.action';
import { log } from '../logger';

export const CLEAR_ACTIVE_FUNERALHOME = 'CLEAR_ACTIVE_FUNERALHOME';

interface ClearActiveFuneralHome {
    type: typeof CLEAR_ACTIVE_FUNERALHOME;
}

export function clearActiveFuneralHome(): ClearActiveFuneralHome {
    return {
        type: CLEAR_ACTIVE_FUNERALHOME,
    };
}

export const FUNERAL_HOMES_LOADING = 'FUNERAL_HOMES_LOADING';
interface FuneralHomesLoading {
    type: typeof FUNERAL_HOMES_LOADING;
    offset: number;
    searchText: string;
    sortBy: keyof FuneralHomeForGatherView;
    sortDirection: 'asc' | 'desc';
}

function funeralHomesLoading(
    offset: number,
    searchText: string,
    sortBy: keyof FuneralHomeForGatherView,
    sortDirection: 'asc' | 'desc',
): FuneralHomesLoading {
    return {
        type: FUNERAL_HOMES_LOADING,
        offset,
        searchText,
        sortBy,
        sortDirection,
    };
}

export const FUNERAL_HOMES_LOADED = 'FUNERAL_HOMES_LOADED';
interface FuneralHomesLoaded {
    type: typeof FUNERAL_HOMES_LOADED;
    funeralHomes: FuneralHomeForGatherView[];
    hasMoreData: boolean;
    offset: number;
}

export function funeralHomesLoaded(
    funeralHomes: FuneralHomeForGatherView[],
    hasMoreData: boolean,
    offset: number,
): FuneralHomesLoaded {
    return {
        type: FUNERAL_HOMES_LOADED,
        funeralHomes,
        hasMoreData,
        offset,
    };
}

export const FUNERAL_HOMES_LOAD_FAILED = 'FUNERAL_HOMES_LOAD_FAILED';
interface FuneralHomesLoadFailed {
    type: typeof FUNERAL_HOMES_LOAD_FAILED;
}
function funeralHomesLoadFailed(): FuneralHomesLoadFailed {
    return {
        type: FUNERAL_HOMES_LOAD_FAILED,
    };
}

export function loadFuneralHomes(
    offset: number,
    limit: number,
    searchText: string,
    sortBy: keyof FuneralHomeForGatherView,
    sortDirection: 'asc' | 'desc',
) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeForGatherView[] | null> => {

        let route = 'funeralhome/?' +
            `offset=${offset}` +
            `&limit=${limit}` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}`;

        if (searchText) {
            route += `&search=${encodeURIComponent(searchText)}`;
        }

        dispatch(funeralHomesLoading(offset, searchText, sortBy, sortDirection));

        const response = await getFromAPI<PaginatedResponse<FuneralHomeForGatherView>>(route, dispatch);
        if (response) {
            dispatch(funeralHomesLoaded(response.data, response.hasMoreData, offset));
            return response.data;
        }
        dispatch(funeralHomesLoadFailed());
        dispatch(registerAppError('Unable to load funeral homes.'));
        return null;
    };
}

export const FUNERAL_HOME_PREVIEWS_LOADING = 'FUNERAL_HOME_PREVIEWS_LOADING';
interface FuneralHomePreviewsLoading {
    type: typeof FUNERAL_HOME_PREVIEWS_LOADING;
    offset: number;
    searchText: string;
    sortBy: keyof FuneralHomeUXPreview;
    sortDirection: 'asc' | 'desc';
}

function funeralHomePreviewsLoading(
    offset: number,
    searchText: string,
    sortBy: keyof FuneralHomeUXPreview,
    sortDirection: 'asc' | 'desc',
): FuneralHomePreviewsLoading {
    return {
        type: FUNERAL_HOME_PREVIEWS_LOADING,
        offset,
        searchText,
        sortBy,
        sortDirection,
    };
}

export const FUNERAL_HOME_PREVIEWS_LOADED = 'FUNERAL_HOME_PREVIEWS_LOADED';
interface FuneralHomePreviewsLoaded {
    type: typeof FUNERAL_HOME_PREVIEWS_LOADED;
    funeralHomes: FuneralHomeUXPreview[];
    hasMoreData: boolean;
    offset: number;
}

function funeralHomePreviewsLoaded(
    funeralHomes: FuneralHomeUXPreview[],
    hasMoreData: boolean,
    offset: number,
): FuneralHomePreviewsLoaded {
    return {
        type: FUNERAL_HOME_PREVIEWS_LOADED,
        funeralHomes,
        hasMoreData,
        offset,
    };
}

export const FUNERAL_HOME_PREVIEWS_LOAD_FAILED = 'FUNERAL_HOME_PREVIEWS_LOAD_FAILED';
interface FuneralHomePreviewsLoadFailed {
    type: typeof FUNERAL_HOME_PREVIEWS_LOAD_FAILED;
}
function funeralHomePreviewsLoadFailed(): FuneralHomePreviewsLoadFailed {
    return {
        type: FUNERAL_HOME_PREVIEWS_LOAD_FAILED,
    };
}

export function loadFuneralHomePreviewsForIdList(params: {
    idList: number[];
}) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUXPreview[] | null> => {
        if (!params.idList.length) {
            return [];
        }
        const route = `funeralhome/previewlist/?ids=${params.idList.join(',')}`;
        return getFromAPI<FuneralHomeUXPreview[]>(route, dispatch);
    };
}


export function loadFuneralHomePreviewsFromAPI(params: {
    offset: number;
    limit: number;
    searchText: string;
    sortBy: 'id' | 'name';
    sortDirection: 'asc' | 'desc';
}) {
    return async (dispatch: AppDispatch): Promise<PaginatedResponse<FuneralHomeUXPreview> | null> => {
        const { offset, limit, searchText, sortBy, sortDirection } = params;
        let route = 'funeralhome/preview/?' +
            `offset=${offset}` +
            `&limit=${limit}` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}`;

        if (searchText) {
            route += `&search=${encodeURIComponent(searchText)}`;
        }

        return getFromAPI<PaginatedResponse<FuneralHomeUXPreview>>(route, dispatch);
    };
}

export function loadFuneralHomePreviews(params: {
    offset: number;
    limit: number;
    searchText: string;
    sortBy: 'id' | 'name';
    sortDirection: 'asc' | 'desc';
}) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUXPreview[] | null> => {
        const { offset, searchText, sortBy, sortDirection } = params;

        dispatch(funeralHomePreviewsLoading(offset, searchText, sortBy, sortDirection));
        const response = await dispatch(loadFuneralHomePreviewsFromAPI(params));
        if (response) {
            dispatch(funeralHomePreviewsLoaded(response.data, response.hasMoreData, offset));
            return response.data;
        }
        dispatch(funeralHomePreviewsLoadFailed());
        dispatch(registerAppError('Unable to load funeral homes.'));
        return null;
    };
}

export const SET_FUNERAL_HOME_SAVING = 'SET_FUNERAL_HOME_SAVING';
export type SET_FUNERAL_HOME_SAVING = typeof SET_FUNERAL_HOME_SAVING;

interface SetFuneralHomeSaving {
    type: SET_FUNERAL_HOME_SAVING;
    isSaving: boolean;
}

function setFuneralHomeSaving(isSaving: boolean): SetFuneralHomeSaving {
    return {
        type: SET_FUNERAL_HOME_SAVING,
        isSaving,
    };
}

export const NEW_FUNERAL_HOME_SAVED = 'NEW_FUNERAL_HOME_SAVED';

interface NewFuneralHomeSaved {
    type: typeof NEW_FUNERAL_HOME_SAVED;
    funeralHome: FuneralHomeUX;
}

function newFuneralHomeSaved(funeralHome: FuneralHomeUX): NewFuneralHomeSaved {
    return {
        type: NEW_FUNERAL_HOME_SAVED,
        funeralHome,
    };
}

export const FUNERAL_HOME_BELONGINGS_LOADING = 'FUNERAL_HOME_BELONGINGS_LOADING';
interface FuneralHomeBelongingsLoading {
    type: typeof FUNERAL_HOME_BELONGINGS_LOADING;
    offset: number;
    searchText: string;
    sortBy: keyof CaseBelongingTableUX;
    sortDirection: 'asc' | 'desc';
    showOnlyPending: boolean;
}
function funeralHomeBelongingsLoading(
    offset: number,
    searchText: string,
    sortBy: keyof CaseBelongingTableUX,
    sortDirection: 'asc' | 'desc',
    showOnlyPending: boolean,
): FuneralHomeBelongingsLoading {
    return {
        type: FUNERAL_HOME_BELONGINGS_LOADING,
        offset,
        searchText,
        sortBy,
        sortDirection,
        showOnlyPending
    };
}

export const FUNERAL_HOME_BELONGINGS_LOADED = 'FUNERAL_HOME_BELONGINGS_LOADED';
interface FuneralHomeBelongingsLoaded {
    type: typeof FUNERAL_HOME_BELONGINGS_LOADED;
    belongings: CaseBelongingTableUX[];
    hasMoreData: boolean;
    offset: number;
}
function funeralHomeBelongingsLoaded(
    belongings: CaseBelongingTableUX[],
    hasMoreData: boolean,
    offset: number): FuneralHomeBelongingsLoaded {
    return {
        type: FUNERAL_HOME_BELONGINGS_LOADED,
        belongings,
        hasMoreData,
        offset,
    };
}

export const FUNERAL_HOME_BELONGINGS_FAILED_TO_LOAD = 'FUNERAL_HOME_BELONGINGS_FAILED_TO_LOAD';
interface FuneralHomeBelongingsFailed {
    type: typeof FUNERAL_HOME_BELONGINGS_FAILED_TO_LOAD;
}
function funeralHomeBelongingsFailed(): FuneralHomeBelongingsFailed {
    return {
        type: FUNERAL_HOME_BELONGINGS_FAILED_TO_LOAD,
    };
}

export function createFuneralHome(funeralHome: FuneralHomeCreateRequest) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUX | null> => {
        try {
            FuneralHomeCreateRequest.fromRequest(funeralHome);
        } catch (ex) {
            log.warn('Failed to validate FuneralHomeCreateRequest', { funeralHome, ex });
            return null;
        }
        dispatch(setFuneralHomeSaving(true));
        const newFuneralHome = await postToAPI<FuneralHomeUX>('funeralhome', { funeralHome }, dispatch);
        dispatch(setFuneralHomeSaving(false));
        if (newFuneralHome !== null) {
            dispatch(newFuneralHomeSaved(newFuneralHome));
            return newFuneralHome;
        } else {
            dispatch(registerAppError('Unable to create new funeral home.'));
            return null;
        }
    };
}

export const UPDATING_FUNERAL_HOME = 'UPDATING_FUNERAL_HOME';

interface UpdatingFuneralHome {
    type: typeof UPDATING_FUNERAL_HOME;
    id: number;
    changes: Partial<FuneralHomeUX>;
}

function updatingFuneralHome(id: number, changes: Partial<FuneralHomeUX>): UpdatingFuneralHome {
    return {
        type: UPDATING_FUNERAL_HOME,
        id,
        changes,
    };
}

export const FUNERAL_HOME_UPDATED = 'FUNERAL_HOME_UPDATED';

interface FuneralHomeUpdated {
    type: typeof FUNERAL_HOME_UPDATED;
    funeralHome: FuneralHomeUX;
}

export function funeralHomeUpdated(funeralHome: FuneralHomeUX): FuneralHomeUpdated {
    return {
        type: FUNERAL_HOME_UPDATED,
        funeralHome,
    };
}

export function updateFuneralHome(
    funeralHomeId: number,
    funeralHome: FuneralHomeUpdateRequest,
) {
    return async (dispatch: AppDispatch) => {
        try {
            FuneralHomeUpdateRequest.fromRequest(funeralHome);
        } catch (ex) {
            log.warn('Failed to validate FuneralHomeUpdateRequest', { funeralHome, ex });
            return;
        }
        dispatch(updatingFuneralHome(funeralHomeId, funeralHome));
        const updatedFuneralHome =
            await patchAPI<FuneralHomeUX>(`funeralhome/${funeralHomeId}`, { funeralHome }, dispatch);
        if (updatedFuneralHome !== null) {
            dispatch(funeralHomeUpdated(updatedFuneralHome));
            if (funeralHome.video_embed_code !== undefined) {
                dispatch(setAppSnackbar('Your video was successfully saved', 'success'));
            }
        } else {
            dispatch(setFuneralHomeSaving(false));
            dispatch(registerAppError('Unable to update funeral home.'));
        }
    };
}

export function deleteFuneralHome(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(setFuneralHomeSaving(true));
        const updatedFuneralHome =
            await deleteFromAPI<FuneralHomeUX>(`funeralhome/${funeralHomeId}`, dispatch);
        if (updatedFuneralHome !== null) {
            dispatch(funeralHomeUpdated(updatedFuneralHome));
        } else {
            dispatch(registerAppError('Unable to delete funeral home.'));
        }
        dispatch(setFuneralHomeSaving(false));
    };
}

export const FUNERAL_HOME_LOADING = 'FUNERAL_HOME_LOADING';

interface FuneralHomeLoading {
    type: typeof FUNERAL_HOME_LOADING;
}

function funeralHomeLoading(): FuneralHomeLoading {
    return {
        type: FUNERAL_HOME_LOADING,
    };
}

export const FUNERAL_HOME_LOADED = 'FUNERAL_HOME_LOADED';

interface FuneralHomeLoaded {
    type: typeof FUNERAL_HOME_LOADED;
    funeralHome: FuneralHomeUX;
}

function funeralHomeLoaded(funeralHome: FuneralHomeUX): FuneralHomeLoaded {
    return {
        type: FUNERAL_HOME_LOADED,
        funeralHome,
    };
}

export const FUNERAL_HOME_LOAD_FAILED = 'FUNERAL_HOME_LOAD_FAILED';

interface FuneralHomeLoadFailed {
    type: typeof FUNERAL_HOME_LOAD_FAILED;
    failedRouteKey: string;
}

function funeralHomeLoadFailed(failedRouteKey: string): FuneralHomeLoadFailed {
    return {
        type: FUNERAL_HOME_LOAD_FAILED,
        failedRouteKey,
    };
}

export function loadFuneralHomeFromRoute(funeralHomeKey: string) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUX | null> => {
        dispatch(funeralHomeLoading());
        const funeralHome = await dispatch(loadFuneralHome(funeralHomeKey));
        if (funeralHome) {
            dispatch(loadTeam(funeralHome.id));
            dispatch(loadLocations(funeralHome.id));
            dispatch(loadFuneralHomeDemoSettings(funeralHome.id));
            dispatch(loadCaseLabelsForFuneralHome(funeralHome.id));
        } else {
            dispatch(funeralHomeLoadFailed(funeralHomeKey));
        }
        return funeralHome;
    };
}

// this doesn't set the FH in Redux, just loads it from the API and returns it
export function loadFuneralHomeApi(key: string) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUX | null> => {
        const funeralHome = await getFromAPI<FuneralHomeUX>(`funeralhome/${key}`, dispatch);
        return funeralHome;
    };
}

// this gets the FH from the API and sets it as active in Redux
export function loadFuneralHome(key: string) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUX | null> => {
        const funeralHome = await dispatch(loadFuneralHomeApi(key));
        if (funeralHome) {
            dispatch(funeralHomeLoaded(funeralHome));
        }
        return funeralHome;
    };
}

export const SET_FUNERAL_HOME_DEMO_SETTINGS = 'SET_FUNERAL_HOME_DEMO_SETTINGS';
export type SET_FUNERAL_HOME_DEMO_SETTINGS = typeof SET_FUNERAL_HOME_DEMO_SETTINGS;

interface SetFuneralHomeDemoSettings {
    type: SET_FUNERAL_HOME_DEMO_SETTINGS;
    demoSettings: FuneralHomeDemoSettings | null;
}

export function setFuneralHomeDemoSettings(demoSettings: FuneralHomeDemoSettings | null): SetFuneralHomeDemoSettings {
    return {
        type: SET_FUNERAL_HOME_DEMO_SETTINGS,
        demoSettings,
    };
}

export function loadFuneralHomeDemoSettings(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(setFuneralHomeDemoSettings(null));
        const demoSettings = await getFromAPI<FuneralHomeDemoSettings>(
            `funeralhome/${funeralHomeId}/demosettings`,
            dispatch
        );
        if (demoSettings) {
            dispatch(setFuneralHomeDemoSettings(demoSettings));
        } else {
            dispatch(registerAppError('Unable to retrieve funeral home demo settings'));
        }
    };
}

export function patchDemoSettings(patch: Partial<FuneralHomeDemoSettings> & { id: number }) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const state = getState();
        const oldSettings = state.funeralHomeState.demoSettings;
        if (oldSettings) {
            dispatch(setFuneralHomeDemoSettings({
                ...oldSettings,
                ...patch,
            }));
        }
        const demoSettings = await patchAPI<FuneralHomeDemoSettings>(
            `funeralhome/${patch.id}/demosettings`,
            patch,
            dispatch
        );
        if (demoSettings) {
            dispatch(setFuneralHomeDemoSettings(demoSettings));
        } else {
            dispatch(setFuneralHomeDemoSettings(oldSettings));
            dispatch(registerAppError('Unable to update funeral home demo settings'));
        }
    };
}

export const SET_FUNERAL_HOME_PHOTO_SAVING = 'SET_FUNERAL_HOME_PHOTO_SAVING';
export type SET_FUNERAL_HOME_PHOTO_SAVING = typeof SET_FUNERAL_HOME_PHOTO_SAVING;

interface SetFuneralHomePhotoSaving {
    type: SET_FUNERAL_HOME_PHOTO_SAVING;
    isPhotoSaving: boolean;
}

function setFuneralHomePhotoSaving(isPhotoSaving: boolean): SetFuneralHomePhotoSaving {
    return {
        type: SET_FUNERAL_HOME_PHOTO_SAVING,
        isPhotoSaving,
    };
}

export const SET_FUNERAL_HOME_ICON_SAVING = 'SET_FUNERAL_HOME_ICON_SAVING';
export type SET_FUNERAL_HOME_ICON_SAVING = typeof SET_FUNERAL_HOME_ICON_SAVING;

interface SetFuneralHomeIconSaving {
    type: SET_FUNERAL_HOME_ICON_SAVING;
    isSaving: boolean;
}

function setFuneralHomeIconSaving(isSaving: boolean): SetFuneralHomeIconSaving {
    return {
        type: SET_FUNERAL_HOME_ICON_SAVING,
        isSaving,
    };
}

export function uploadFuneralHomePhotoToCloudinary(
    dataURI: string,
    funeralHomeId: number,
    dispatch: AppDispatch
): Promise<UploadPhotoResponse | null> {
    const url = `funeralhome/${funeralHomeId}/photo/signature`;
    return uploadPhoto(dataURI, url, dispatch);
}

export function updateFuneralHomePhoto(
    funeralHomeId: number | null,
    photo: string,
    transformations: PhotoTransformationsType,
    iconOrPhoto: PhotoType,
    type: FH_LogoType // whether default or themed logo
) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeUX | null> => {
        if (!funeralHomeId) {
            dispatch(registerAppError('No funeral home selected.'));
            return null;
        }
        if (iconOrPhoto !== PhotoTypeEnum.funeral_home_logo && iconOrPhoto !== PhotoTypeEnum.funeral_home_icon) {
            return null;
        }

        if (iconOrPhoto === PhotoTypeEnum.funeral_home_icon) {
            dispatch(setFuneralHomeIconSaving(true));
        } else {
            dispatch(setFuneralHomePhotoSaving(true));
        }
        const cloudinaryResponse = await uploadFuneralHomePhotoToCloudinary(photo, funeralHomeId, dispatch);
        if (cloudinaryResponse) {
            const updatedFuneralHome = await postToAPI<FuneralHomeUX>(
                `funeralhome/${funeralHomeId}/photo`,
                {
                    public_id: cloudinaryResponse.public_id,
                    width: cloudinaryResponse.width,
                    height: cloudinaryResponse.height,
                    transformations,
                    iconOrPhoto,
                    type
                },
                dispatch,
            );
            if (updatedFuneralHome) {
                dispatch(funeralHomeUpdated(updatedFuneralHome));
                if (iconOrPhoto === PhotoTypeEnum.funeral_home_icon) {
                    dispatch(setFuneralHomeIconSaving(false));
                } else {
                    dispatch(setFuneralHomePhotoSaving(false));
                }
                return updatedFuneralHome;
            }
        }
        dispatch(registerAppError('Unable to upload photo.'));
        if (iconOrPhoto === PhotoTypeEnum.funeral_home_icon) {
            dispatch(setFuneralHomeIconSaving(false));
        } else {
            dispatch(setFuneralHomePhotoSaving(false));
        }
        return null;
    };
}

export const SET_FUNERAL_HOME_FEATURE = 'SET_FUNERAL_HOME_FEATURE';
export type SET_FUNERAL_HOME_FEATURE = typeof SET_FUNERAL_HOME_FEATURE;

interface SetFuneralHomeFeature {
    type: SET_FUNERAL_HOME_FEATURE;
    funeralHomeId: number;
    feature: FeatureKey;
    enabled: boolean;
}

function setFeature(funeralHomeId: number, feature: FeatureKey, enabled: boolean): SetFuneralHomeFeature {
    return {
        type: SET_FUNERAL_HOME_FEATURE,
        funeralHomeId,
        feature,
        enabled
    };
}

export function updateFeature(
    funeralHomeId: number,
    feature: FeatureKey,
    enabled: boolean) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        // Not waiting for API call and optimistically updating the UI
        dispatch(setFeature(funeralHomeId, feature, enabled));
        putToAPI<boolean>(
            `funeralhome/${funeralHomeId}/feature/${feature}`,
            { enabled },
            dispatch,
        );
        return enabled;
    };
}

export const SET_STRIPE_DATA = 'SET_STRIPE_DATA';
export type SET_STRIPE_DATA = typeof SET_STRIPE_DATA;

export interface SetStripeData {
    type: SET_STRIPE_DATA;
    funeralHomeId: number;
    locationId: string | null;
    accountId: string | null;
    customerId: string | null;
}

function setStripeData(
    funeralHomeId: number,
    locationId: string | null,
    accountId: string | null,
    customerId: string | null,
): SetStripeData {
    return {
        type: SET_STRIPE_DATA,
        funeralHomeId,
        locationId,
        accountId,
        customerId,
    };
}

export function getStripeBillingPortalSessionUrl(
    funeralHome: FuneralHomeUX,
) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        if (!funeralHome.stripe_customer) { 
            dispatch(registerAppError('No Stripe Customer ID configured!'));
            return null;
        }
        const billingPortalUrl: string | null =
            await getFromAPI<string>(
                `funeralhome/${funeralHome.id}/stripebilling`, dispatch);

        if (billingPortalUrl) {
            return billingPortalUrl;
        }
        dispatch(registerAppError('Unable to load stripe data.'));
        return null;
    };
}

export function updateStripeData(
    funeralHome: FuneralHomeUX,
    locationId: string | null,
    accountId: string | null,
    customerId: string | null,
) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        // Optimistically update the location id on the funeral home object
        dispatch(setStripeData(funeralHome.id, locationId, accountId, customerId));
        const message = await putToAPI<string>(
            `funeralhome/${funeralHome.id}/stripeData`,
            { locationId, accountId, customerId },
            dispatch
        );
        if (message) {
            // If a message is returned, show it to the user
            dispatch(setAppSnackbar(message, 'success'));
            return message;
        } else if (message === '') {
            // If no message is returned, then just exit
            return message;
        } else { // message is null if an error ocurred, un-optimistically reset the values
            dispatch(setStripeData(
                funeralHome.id,
                funeralHome.stripe_location,
                funeralHome.stripe_account,
                funeralHome.stripe_customer,
            ));
            return '';
        }
    };
}

export const PATCH_EXTRACT_CONFIG = 'PATCH_EXTRACT_CONFIG';
export type PATCH_EXTRACT_CONFIG = typeof PATCH_EXTRACT_CONFIG;

export interface PatchExtractConfig {
    type: PATCH_EXTRACT_CONFIG;
    funeralHomeId: number;
    ledgerExtractType: string;
    qbTaxRate: number;
}

function patchExtractConfig(
    funeralHomeId: number,
    ledgerExtractType: string,
    qbTaxRate: number,
): PatchExtractConfig {
    return {
        type: PATCH_EXTRACT_CONFIG,
        funeralHomeId,
        ledgerExtractType,
        qbTaxRate,
    };
}

export function saveExtractConfig(
    funeralHomeId: number,
    ledgerExtractType: string,
    qbTaxRate: number,
) {
    return async (dispatch: AppDispatch) => {
        // Optimistically update the location id on the funeral home object
        dispatch(patchExtractConfig(funeralHomeId, ledgerExtractType, qbTaxRate));
        const result = await patchAPI<string>(
            `funeralhome/${funeralHomeId}/extractConfig`,
            { ledgerExtractType, qbTaxRate },
            dispatch
        );
        if (result) {
            dispatch(setAppSnackbar('Extract configuration updated', 'success'));
        }
    };
}

export const LOADING_CASE_SWITCHER_DATA = 'LOADING_CASE_SWITCHER_DATA';

interface LoadingCaseSwitcherData {
    type: typeof LOADING_CASE_SWITCHER_DATA;
}

function loadingCaseSwitcherData(): LoadingCaseSwitcherData {
    return {
        type: LOADING_CASE_SWITCHER_DATA,
    };
}

export const LOADED_CASE_SWITCHER_DATA = 'LOADED_CASE_SWITCHER_DATA';

interface LoadedCaseSwitcherData extends LoadCaseSwitcherResponse {
    type: typeof LOADED_CASE_SWITCHER_DATA;
}

function loadedCaseSwitcherData(data: LoadCaseSwitcherResponse): LoadedCaseSwitcherData {
    return {
        type: LOADED_CASE_SWITCHER_DATA,
        ...data,
    };
}

export const FAILED_LOAD_CASE_SWITCHER_DATA = 'FAILED_LOAD_CASE_SWITCHER_DATA';

interface FailedLoadCaseSwitcherData {
    type: typeof FAILED_LOAD_CASE_SWITCHER_DATA;
}

function failedLoadCaseSwitcherData(): FailedLoadCaseSwitcherData {
    return {
        type: FAILED_LOAD_CASE_SWITCHER_DATA,
    };
}

export function loadCaseSwitcherData(user: UserSession, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<LoadCaseSwitcherResponse | null> => {

        if (!user.userData) {
            return null;
        }

        dispatch(loadingCaseSwitcherData());

        const resource = `funeralhome/${funeralHomeId}/caseswitcher`;
        const response = await getFromAPI<LoadCaseSwitcherResponse>(resource, dispatch);
        if (response !== null) {
            dispatch(loadedCaseSwitcherData(response));
            return response;
        } else {
            dispatch(failedLoadCaseSwitcherData());
        }
        return null;
    };
}

export const LOADING_PUBLIC_GPL = 'LOADING_PUBLIC_GPL';

interface LoadingPublicGPL {
    type: typeof LOADING_PUBLIC_GPL;
}

function loadingPublicGPL(): LoadingPublicGPL {
    return {
        type: LOADING_PUBLIC_GPL,
    };
}

export const LOADED_PUBLIC_GPL = 'LOADED_PUBLIC_GPL';

interface LoadedPublicGPL extends LoadPublicGPLResponse {
    type: typeof LOADED_PUBLIC_GPL;
}

function loadedPublicGPL(data: LoadPublicGPLResponse): LoadedPublicGPL {
    return {
        type: LOADED_PUBLIC_GPL,
        ...data,
    };
}

export const FAILED_LOAD_PUBLIC_GPL = 'FAILED_LOAD_PUBLIC_GPL';

interface FailedLoadPublicGPL {
    type: typeof FAILED_LOAD_PUBLIC_GPL;
}

function failedLoadPublicGPL(): FailedLoadPublicGPL {
    return {
        type: FAILED_LOAD_PUBLIC_GPL,
    };
}

export function loadPublicGPL(funeralHomeKey: string) {
    return async (dispatch: AppDispatch): Promise<LoadPublicGPLResponse | null> => {

        dispatch(loadingPublicGPL());

        const resource = `app/funeralhome/${funeralHomeKey}/gpl`;
        const response = await getFromAPI<LoadPublicGPLResponse>(resource, dispatch);
        if (response !== null) {
            dispatch(loadedPublicGPL(response));
            return response;
        } else {
            dispatch(failedLoadPublicGPL());
        }
        return null;
    };
}

export function loadBelongingsCount(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<number | null> => {
        const resource = `funeralhome/${funeralHomeId}/belonging/count`;
        const response = await getFromAPI<CaseBelongingCount>(resource, dispatch);
        if (response !== null) {
            return response.count;
        }
        dispatch(registerAppError('Unable to load total count of belongings'));
        return null;
    };
}

export function loadAllBelongingsForFuneralHome(
    funeralHomeId: number,
    offset: number = 0,
    searchText: string = '',
    sortBy: keyof CaseBelongingTableUX = 'modified_time',
    sortDirection: 'asc' | 'desc' = 'desc',
    showOnlyPending: boolean = true,
) {
    return async (dispatch: AppDispatch): Promise<CaseBelongingTableUX[] | null> => {
        let route = `funeralhome/${funeralHomeId}/belonging?` +
            `offset=${offset}` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}`;
        if (showOnlyPending) {
            route += '&showOnlyPending=true';
        }

        if (searchText) {
            route += `&search=${encodeURIComponent(searchText)}`;
        }

        dispatch(funeralHomeBelongingsLoading(offset, searchText, sortBy, sortDirection, showOnlyPending));

        const response = await getFromAPI<PaginatedResponse<CaseBelongingTableUX>>(route, dispatch);
        if (!response) {
            dispatch(funeralHomeBelongingsFailed());
            dispatch(registerAppError('Unable to load belongings for funeral Home.'));
            return null;
        }

        dispatch(funeralHomeBelongingsLoaded(response.data, response.hasMoreData, offset));
        return response.data;
    };
}

export type FuneralHomeAction =
    | ClearActiveFuneralHome
    | SetFuneralHomeSaving
    | NewFuneralHomeSaved
    | FuneralHomeUpdated
    | UpdatingFuneralHome
    | SetFuneralHomePhotoSaving
    | SetFuneralHomeIconSaving
    | SetFuneralHomeFeature
    | SetStripeData
    | SetFuneralHomeDemoSettings
    | PatchExtractConfig
    | FuneralHomeLoading
    | FuneralHomeLoaded
    | FuneralHomeLoadFailed
    | FuneralHomesLoading
    | FuneralHomesLoaded
    | FuneralHomesLoadFailed
    | FuneralHomePreviewsLoading
    | FuneralHomePreviewsLoaded
    | FuneralHomePreviewsLoadFailed
    | SetFuneralHomeStartupDocuments
    | LoadingCaseSwitcherData
    | LoadedCaseSwitcherData
    | FailedLoadCaseSwitcherData
    | LoadingPublicGPL
    | LoadedPublicGPL
    | FailedLoadPublicGPL
    | FuneralHomeBelongingsLoading
    | FuneralHomeBelongingsLoaded
    | FuneralHomeBelongingsFailed
    ;
