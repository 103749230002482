import { MouseEventHandler } from "react";

import { getCompactKeepTrackDuration, getLocationDurationTooltip } from "../CompletedStep";
import { TaskLocationUX } from "../../../../../shared/types";
import { getDatesDiffDurations, getPhotoUrl } from "../../../../../services";
import moment from "moment-timezone";
import { log } from "../../../../../logger";
import LocationItemDisplay from "./LocationItemDisplay";
import { TOOLTIPS } from "../../../../../constants";

export enum LocationItemState {
    previousOtherSelected = 'previousOtherSelected',
    previous = 'previous',
    current = 'current',
    moveTo = 'moveTo',
    default = 'default',
    compact = 'compact',
}

interface Props {
    location: TaskLocationUX;
    locationMovedTime?: Date | null;
    state: LocationItemState;
    caseFName: string;
    disableGutters?: boolean;
    onSelect?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    hideTooltips?: boolean;
    intercomTargetProp?: string;
}

const LocationItem = (props: Props) => {
    const {
        locationMovedTime,
        location,
        state,
        caseFName,
        disabled,
        hideTooltips,
        intercomTargetProp
    } = props;

    const previousOtherSelected = state === LocationItemState.previousOtherSelected;
    const previous = state === LocationItemState.previous;
    const current = state === LocationItemState.current;
    const moveTo = state === LocationItemState.moveTo;
    const isDefault = state === LocationItemState.default;
    const compact = state === LocationItemState.compact;

    let tooltipTitle;
    if (hideTooltips) {
        tooltipTitle = '';
    } else if (disabled) {
        tooltipTitle = TOOLTIPS.DISABLED_FEATURE;
    } else if (state === LocationItemState.current) {
        tooltipTitle = `${caseFName}'s current location is ${location.name}. Tap to move within this location.`;
    } else if (state === LocationItemState.compact) {
        tooltipTitle = `${caseFName}'s current location is ${location.name}. Click to view details.`;
    } else if (state === LocationItemState.previous || state === LocationItemState.previousOtherSelected) {
        tooltipTitle = `Tap to keep ${caseFName} in ${location.name}`;
    } else if (state === LocationItemState.moveTo) {
        tooltipTitle = `Moving ${caseFName} to ${location.name}`;
    } else if (state === LocationItemState.default) {
        tooltipTitle = `Tap to move ${caseFName} to ${location.name}`;
    } else {
        log.warn(`Not a known state`, { state });
        tooltipTitle = '';
    }

    const badgeTooltip = `There are currently ${location.cases_in_location} decedents at this location.` +
        (location.capacity ? `Capacity is ${location.capacity}.` : '');

    const isBodyHere = current || previous || previousOtherSelected;

    const durations = locationMovedTime && getDatesDiffDurations({
        older: locationMovedTime,
        newer: moment(),
    });

    const durationText = getCompactKeepTrackDuration({
        durations: durations ?? null,
        withEllipses: isBodyHere,
    });

    const durationTooltip = !durations ? '' : getLocationDurationTooltip({
        durations,
        caseFName,
        isCurrent: isBodyHere,
        movedTime: moment(locationMovedTime) || undefined,
    });

    let headerText;
    if (previousOtherSelected || previous || current) {
        headerText = 'Current';
    } else if (moveTo) {
        headerText = 'Move To';
    }

    const locationImgPublicId = location.cover_photo;
    const locationImageFallbackUrl = location.cover_fallback_url;

    const locationImageUrl = locationImgPublicId
        ? getPhotoUrl(locationImgPublicId, [{ width: 96, height: 96, crop: 'fill' }])
        : locationImageFallbackUrl;

    return (
        <LocationItemDisplay
            name={location.name}
            imageUrl={locationImageUrl}
            casesInLocation={location.cases_in_location}
            mainTooltip={tooltipTitle}
            badgeTooltip={badgeTooltip}
            durationTooltip={durationTooltip}
            durationText={durationText || ''}
            headerText={headerText}
            isGreen={current || moveTo || compact}
            showPin={previous || current || moveTo}
            dashedBorder={isDefault}
            isCompact={compact}
            tooltipPlacement={compact ? 'bottom' : undefined}
            disableGutters={props.disableGutters}
            onSelect={props.onSelect}
            disabled={disabled}
            intercomTargetProp={intercomTargetProp}
        />
    );
};

export default LocationItem;
