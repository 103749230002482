import { orderBy } from 'lodash';
import {
    CaseNote,
    TaskNote,
    NoteResponse,
    NoteRequest,
    FuneralHomeNoteView,
    CaseNoteRecord,
} from '../shared/types/note';
import { getFromAPI, postToAPI, patchAPI, deleteFromAPI } from '.';
import { registerAppError } from './errors';
import { setAppSnackbar } from './AppSnackbar.action';
import { CaseTaskUX, PaginatedResponse, TaskType } from '../shared/types';
import { replaceStringTemplates, joinNameParts } from '../shared/utils';
import { updateTaskInStore } from './task/Task.action';
import { StoreState } from '../types';
import { AppDispatch } from '../store';
import { log } from '../logger';
import { updateNoteCount } from './Whiteboard.action';

export const INCREASE_CASE_NOTE_COUNT_BY_1 = 'INCREASE_CASE_NOTE_COUNT_BY_1';
export interface IncreaseCaseNoteCountBy1 {
    type: typeof INCREASE_CASE_NOTE_COUNT_BY_1;
    caseUuid: string;
}

function increaseCaseNoteCountBy1(caseUuid: string): IncreaseCaseNoteCountBy1 {
    return {
        type: INCREASE_CASE_NOTE_COUNT_BY_1,
        caseUuid
    };
}

export const DECREASE_CASE_NOTE_COUNT_BY_1 = 'DECREASE_CASE_NOTE_COUNT_BY_1';
export interface DecreaseCaseNoteCountBy1 {
    type: typeof DECREASE_CASE_NOTE_COUNT_BY_1;
    caseUuid: string;
}

function decreaseCaseNoteCountBy1(caseUuid: string): DecreaseCaseNoteCountBy1 {
    return {
        type: DECREASE_CASE_NOTE_COUNT_BY_1,
        caseUuid
    };
}

export const SET_CASE_NOTES = 'SET_CASE_NOTES';
interface SetCaseNotes {
    type: typeof SET_CASE_NOTES;
    caseNotes: CaseNote[];
}

function setCaseNotes(caseNotes: CaseNote[]): SetCaseNotes {
    return {
        type: SET_CASE_NOTES,
        caseNotes
    };
}

export const SET_TASK_NOTES = 'SET_TASK_NOTES';
interface SetTaskNotes {
    type: typeof SET_TASK_NOTES;
    taskNotes: TaskNote[];
}

function setTaskNotes(taskNotes: TaskNote[]): SetTaskNotes {
    return {
        type: SET_TASK_NOTES,
        taskNotes
    };
}

export const DELETE_TASK_NOTE = 'DELETE_TASK_NOTE';
interface DeleteTaskNote {
    type: typeof DELETE_TASK_NOTE;
    taskId: number;
}

export function deleteTaskNote(taskId: number): DeleteTaskNote {
    return {
        type: DELETE_TASK_NOTE,
        taskId
    };
}

export const SET_NOTE_STATE_LOADING = 'SET_NOTE_STATE_LOADING';
interface SetNoteStateLoading {
    type: typeof SET_NOTE_STATE_LOADING;
    isLoading: boolean;
}

export function setNoteStateLoading(isLoading: boolean): SetNoteStateLoading {
    return {
        type: SET_NOTE_STATE_LOADING,
        isLoading
    };
}

// FUNERAL_HOME_NOTES_LOADING
export const FUNERAL_HOME_NOTES_LOADING = 'FUNERAL_HOME_NOTES_LOADING';
interface FuneralHomeNotesLoading {
    type: typeof FUNERAL_HOME_NOTES_LOADING;
    offset: number;
    searchText: string;
    sortBy: keyof FuneralHomeNoteView;
    sortDirection: 'asc' | 'desc';
}
function funeralHomeNotesLoading(
    offset: number,
    searchText: string,
    sortBy: keyof FuneralHomeNoteView,
    sortDirection: 'asc' | 'desc',
): FuneralHomeNotesLoading {
    return {
        type: FUNERAL_HOME_NOTES_LOADING,
        offset,
        searchText,
        sortBy,
        sortDirection,
    };
}

// FUNERAL_HOME_NOTES_LOADED
export const FUNERAL_HOME_NOTES_LOADED = 'FUNERAL_HOME_NOTES_LOADED';
interface FuneralHomeNotesLoaded {
    type: typeof FUNERAL_HOME_NOTES_LOADED;
    notes: FuneralHomeNoteView[];
    hasMoreData: boolean;
    offset: number;
}
function funeralHomeNotesLoaded(
    notes: FuneralHomeNoteView[],
    hasMoreData: boolean,
    offset: number): FuneralHomeNotesLoaded {
    return {
        type: FUNERAL_HOME_NOTES_LOADED,
        notes,
        hasMoreData,
        offset,
    };
}

// FUNERAL_HOME_NOTES_FAILED_TO_LOAD
export const FUNERAL_HOME_NOTES_FAILED_TO_LOAD = 'FUNERAL_HOME_NOTES_FAILED_TO_LOAD';
interface FuneralHomeNotesFailed {
    type: typeof FUNERAL_HOME_NOTES_FAILED_TO_LOAD;
}
function funeralHomeNotesFailed(): FuneralHomeNotesFailed {
    return {
        type: FUNERAL_HOME_NOTES_FAILED_TO_LOAD,
    };
}

// SET_NOTES_ACTIVE_CASE_ID
export const SET_NOTES_ACTIVE_CASE_UUID = 'SET_NOTES_ACTIVE_CASE_UUID';
interface SetNotesActiveCaseUuid {
    type: typeof SET_NOTES_ACTIVE_CASE_UUID;
    caseUuid: string;
}
export function setNotesActiveCaseUuid(caseUuid: string): SetNotesActiveCaseUuid {
    return {
        type: SET_NOTES_ACTIVE_CASE_UUID,
        caseUuid,
    };
}

// SET_SHOW_HELPER_NOTES_ONLY_TOGGLE
export const SET_SHOW_HELPER_NOTES_ONLY_TOGGLE = 'SET_SHOW_HELPER_NOTES_ONLY_TOGGLE';
interface SetShowHelperNotesOnlyToggle {
    type: typeof SET_SHOW_HELPER_NOTES_ONLY_TOGGLE;
    showHelperNotesOnly: boolean;
}
export function setShowHelperNotesOnlyToggle(showHelperNotesOnly: boolean): SetShowHelperNotesOnlyToggle {
    return {
        type: SET_SHOW_HELPER_NOTES_ONLY_TOGGLE,
        showHelperNotesOnly
    };
}

function sanitizeFuneralHomeNotes(note: FuneralHomeNoteView, fname: string): FuneralHomeNoteView {
    return {
        ...note,
        note_detail: replaceStringTemplates(note.note_detail, { case: { fname } }),
        task_title: replaceStringTemplates(note.task_title || '', { case: { fname } })
    };
}

export function loadAllNotes(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<NoteResponse | null> => {
        dispatch(setNoteStateLoading(true));

        const notes = await getFromAPI<NoteResponse>(
            `api/case/${caseUuid}/note`,
            dispatch
        );

        if (!notes) {
            dispatch(setNoteStateLoading(false));
            dispatch(setCaseNotes([]));
            dispatch(setTaskNotes([]));
            dispatch(registerAppError('Unable to load Notes.'));
            return null;
        }

        dispatch(setCaseNotes(notes.caseNotes));
        dispatch(setTaskNotes(notes.taskNotes));
        dispatch(setNotesActiveCaseUuid(caseUuid));
        dispatch(setNoteStateLoading(false));
        return notes;
    };
}

export function addCaseNote(note: string, caseUuid: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseNote | null> => {
        const { userSession, noteState } = getState();
        const { showHelperNotesOnly } = noteState.funeralHomeView;
        if (!userSession.userData) {
            return null;
        }

        const insertRequest: NoteRequest = {
            note
        };

        try {
            NoteRequest.fromRequest(insertRequest);
        } catch (ex) {
            log.warn('Failed to validate NoteRequest', { insertRequest, ex });
            return null;
        }

        const caseNote = await postToAPI<CaseNote | null>(
            `api/case/${caseUuid}/note`,
            { caseNote: insertRequest },
            dispatch
        );

        if (!caseNote) {
            dispatch(registerAppError('Unable to add case Note'));
            return null;
        }

        const { caseNotes } = noteState;
        dispatch(setCaseNotes([caseNote, ...caseNotes]));
        dispatch(increaseCaseNoteCountBy1(caseUuid));
        dispatch(updateNoteCount(caseUuid, 1));
        if (!showHelperNotesOnly) {
            dispatch(addCaseNoteForFuneralHome(caseNote));
        }
        dispatch(setAppSnackbar('Case Note added successfully', 'success'));
        return caseNote;
    };
}

export function updateCaseNote(note: string, noteId: number, caseUuid: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseNote | null> => {
        const { userSession, noteState } = getState();
        if (!userSession.userData) {
            return null;
        }

        const patchRequest: NoteRequest = {
            note
        };

        try {
            NoteRequest.fromRequest(patchRequest);
        } catch (ex) {
            log.warn('Failed to validate NoteRequest', { patchRequest, ex });
            return null;
        }
        const caseNote = await patchAPI<CaseNote | null>(
            `api/case/${caseUuid}/note/${noteId}`,
            { caseNote: patchRequest },
            dispatch
        );

        if (!caseNote) {
            dispatch(registerAppError('Unable to update case Note.'));
            return null;
        }

        const { caseNotes } = noteState;
        const updatedNotes = caseNotes.map(n => {
            if (n.id === caseNote.id) {
                return caseNote;
            }
            return n;
        });

        const sortedNotes = orderBy(updatedNotes, (n) => n.updated_time, 'desc');

        dispatch(setCaseNotes(sortedNotes));
        dispatch(updateCaseNoteForFuneralHome(caseNote));
        return caseNote;
    };
}

export function deleteCaseNote(caseUuid: string, noteId: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseNoteRecord | null> => {
        const resource = `api/case/${caseUuid}/note/${noteId}`;
        const deletedCaseNote = await deleteFromAPI<CaseNoteRecord | null>(resource, dispatch);

        if (!deletedCaseNote) {
            dispatch(registerAppError('Unable to delete case Note.'));
            return null;
        }
        const { noteState } = getState();
        const { caseNotes } = noteState;
        const updatedNotes = caseNotes.filter(n => noteId !== n.id);
        dispatch(setCaseNotes(updatedNotes));
        dispatch(decreaseCaseNoteCountBy1(caseUuid));
        dispatch(deleteCaseNoteForFuneralHome(noteId));
        dispatch(updateNoteCount(caseUuid, -1));
        return deletedCaseNote;
    };
}

export function updateTaskNoteInStore(task: CaseTaskUX, caseUuid: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const { noteState, tasksState } = getState();
        const { taskNotes, funeralHomeView } = noteState;
        const { checklistTasks } = tasksState;
        const updatedTaskInStore = checklistTasks.find(t => t.id === task.id);

        // we need to update task in store agin so that we can use the sanitized 
        // title and update note_updated_by in store
        if (updatedTaskInStore) {
            dispatch(updateTaskInStore({
                taskChanges: {
                    ...updatedTaskInStore,
                    title: updatedTaskInStore.title,
                    note_updated_by_user: task.note_updated_by_user
                },
                taskId: task.id,
                caseUuid,
                taskTemplateType: task.template_type,
                trackingStepType: task.tracking_step_type,
            }));
        }

        if (!task.note || !task.note.trim() || !task.note_updated_time || !task.note_updated_by) {
            dispatch(deleteTaskNote(task.id));
            dispatch(decreaseCaseNoteCountBy1(caseUuid));
            return;
        }

        const noteToBeUpdated = taskNotes.find(n => n.task_id === task.id);
        const noteToBeUpdatedForFuneralHome = funeralHomeView.data.find(n => n.task_id === task.id);
        if (noteToBeUpdated || noteToBeUpdatedForFuneralHome) {
            dispatch(updateTaskNote(task));
            dispatch(updateTaskNoteForFuneralHome(task));
            return;
        }

        dispatch(addTaskNote(task));
        dispatch(increaseCaseNoteCountBy1(caseUuid));
    };
}

function addTaskNote(task: CaseTaskUX) {
    return (dispatch: AppDispatch, getState: () => StoreState): void => {
        const { noteState } = getState();
        const { taskNotes } = noteState;

        const newNote: TaskNote = {
            task_id: task.id,
            notes: task.note ? [task.note] : [],
            task_template: task.template_type,
            task_title: task.title,
            updated_by_user: { ...task.note_updated_by_user },
            gather_case_fname: task.gather_case_fname,
            is_tracking_step: task.type === TaskType.tracking_step,
        };
        dispatch(setTaskNotes([newNote, ...taskNotes]));
    };
}

export function updateTaskNote(task: CaseTaskUX) {
    return (dispatch: AppDispatch, getState: () => StoreState): void => {
        const { noteState } = getState();
        const { taskNotes } = noteState;

        const updatedNotes = taskNotes.map(n => {
            if (n.task_id === task.id) {
                return {
                    ...n,
                    note: task.note || '',
                    updated_by_user: task.note_updated_by_user
                };
            }
            return n;
        });
        const sortedNotes = orderBy(updatedNotes, note => note.updated_by_user.time, 'desc');
        dispatch(setTaskNotes(sortedNotes));
    };
}

export function loadAllNotesForFuneralhome(
    funeralHomeId: number,
    offset: number = 0,
    searchText: string = '',
    sortBy: keyof FuneralHomeNoteView = 'note_updated_time',
    sortDirection: 'asc' | 'desc' = 'desc',
    showHelperNotesOnly: boolean = false
) {
    return async (dispatch: AppDispatch): Promise<FuneralHomeNoteView[] | null> => {
        let route = `funeralhome/${funeralHomeId}/notes?` +
            `offset=${offset}` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}` +
            `&showHelperNotesOnly=${encodeURIComponent(showHelperNotesOnly.toString())}`;

        if (searchText) {
            route += `&search=${encodeURIComponent(searchText)}`;
        }

        dispatch(funeralHomeNotesLoading(offset, searchText, sortBy, sortDirection));

        const response = await getFromAPI<PaginatedResponse<FuneralHomeNoteView>>(route, dispatch);
        if (!response) {
            dispatch(funeralHomeNotesFailed());
            dispatch(registerAppError('Unable to load notes for funeral Home.'));
            return null;
        }

        const notes = response.data.map(note => {
            if (note.task_id === null) {
                return note;
            }
            return sanitizeFuneralHomeNotes(note, note.gather_case_fname);
        });

        dispatch(funeralHomeNotesLoaded(notes, response.hasMoreData, offset));
        return response.data;
    };
}

function addCaseNoteForFuneralHome(caseNote: CaseNote) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<null> => {
        const { userSession, noteState, casesState } = getState();
        const { dashboardCases } = casesState;
        const gatherCase = (dashboardCases || []).find(c => c.id === caseNote.gather_case_id);

        if (!userSession.userData || !gatherCase) {
            return null;
        }

        const caseNoteForFuneralHome: FuneralHomeNoteView = {
            notes: [caseNote.note],
            gather_case_id: caseNote.gather_case_id,
            case_uuid: caseNote.case_uuid,
            note_id: caseNote.id,
            note_updated_time: caseNote.updated_time,
            note_updated_by: caseNote.updated_by,
            note_detail: 'Case Note',
            case_key: gatherCase.name,
            case_assignee_id: gatherCase.assignee.user_id,
            case_assignee_full_name: joinNameParts(gatherCase.assignee),
            case_full_name: joinNameParts(gatherCase),
            gather_case_fname: gatherCase.fname,
            task_id: null,
            task_template: null,
            task_title: null,
            note_updated_by_user: {
                ...userSession.userData,
                time: caseNote.updated_time,
            },
            gather_case_photo: {
                fname: gatherCase.fname,
                lname: gatherCase.lname,
                photo: gatherCase.photo,
                photo_transformations: gatherCase.photo_transformations
            },
            is_tracking_step: false,
        };

        dispatch(funeralHomeNotesLoaded(
            [caseNoteForFuneralHome, ...noteState.funeralHomeView.data],
            noteState.funeralHomeView.hasMoreData,
            0
        ));

        return null;
    };
}

export function updateCaseNoteForFuneralHome(caseNote: CaseNote) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<FuneralHomeNoteView[] | null> => {
        const { noteState, userSession } = getState();
        const { data, hasMoreData } = noteState.funeralHomeView;

        if (!userSession.userData) {
            return null;
        }

        const changes: Partial<FuneralHomeNoteView> = {
            notes: [caseNote.note],
            note_updated_time: caseNote.updated_time,
            note_updated_by: caseNote.updated_by,
        };

        const updatedFuneralHomeNotes = data.map(n => {
            if (n.note_id === caseNote.id) {
                return {
                    ...n,
                    ...changes
                };
            }
            return n;
        });

        const orderedNotes = orderBy(
            updatedFuneralHomeNotes,
            (n) => new Date(n.note_updated_time),
            'desc'
        );
        dispatch(funeralHomeNotesLoaded(orderedNotes, hasMoreData, 0));
        return updatedFuneralHomeNotes;
    };
}

export function deleteCaseNoteForFuneralHome(noteId: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<FuneralHomeNoteView[] | null> => {
        const { noteState } = getState();
        const { data, hasMoreData } = noteState.funeralHomeView;

        const updatedFuneralHomeNotes = data.filter(n => noteId !== n.note_id);
        dispatch(funeralHomeNotesLoaded(updatedFuneralHomeNotes, hasMoreData, 0));

        return updatedFuneralHomeNotes;
    };
}

export function updateTaskNoteForFuneralHome(task: CaseTaskUX) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<FuneralHomeNoteView[] | null> => {
        const { noteState, userSession } = getState();
        const { data, hasMoreData } = noteState.funeralHomeView;

        if (!userSession.userData) {
            return null;
        }

        const changes: Partial<FuneralHomeNoteView> = {
            note_updated_time: task.note_updated_by_user.time || new Date(),
            notes: [task.note || ''],
            note_updated_by: task.note_updated_by || -1,
        };

        const updatedFuneralHomeNotes = data.map(n => {
            if (n.task_id === task.id) {
                return sanitizeFuneralHomeNotes({ ...n, ...changes }, n.gather_case_fname);
            }
            return n;
        });

        const orderedNotes = orderBy(updatedFuneralHomeNotes, (note) => new Date(note.note_updated_time), 'desc');
        dispatch(funeralHomeNotesLoaded(orderedNotes, hasMoreData, 0));
        return updatedFuneralHomeNotes;
    };
}

export type NoteAction = SetCaseNotes
    | SetTaskNotes
    | SetNoteStateLoading
    | DeleteTaskNote
    | FuneralHomeNotesLoading
    | FuneralHomeNotesLoaded
    | FuneralHomeNotesFailed
    | SetNotesActiveCaseUuid
    | SetShowHelperNotesOnlyToggle;