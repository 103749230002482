import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { SlideTransition } from '../../../../common/Transitions';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { GridRowId } from '@mui/x-data-grid-pro';
import BulkEditOptions from './BulkEditOptions';
import BulkEditSelectedOptionContent, { BulkEditButtonType } from './BulkEditSelectedOptionContent';
import { styled } from '@mui/material/styles';
import { ProductUX } from '../../../../../shared/types';

const BulkEditDialogTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 12px',
    '& .MuiDialogTitle-root': {},
    '& svg': {
        color: '#fff',
        cursor: 'pointer',
    },
}));

interface Props {
    isDialogOpen: boolean;
    closeDialog: () => void;
    updateProducts: (updatedProducts: ProductUX[]) => void;
    zIndex: number;
    selectedProducts: GridRowId[] | undefined;
    isGatherUser: boolean;
}

const BulkEditProductsDialog = ({
    isDialogOpen, closeDialog, zIndex, selectedProducts, updateProducts, isGatherUser
}: Props) => {
    const [selectButton, setSelectedButton] = useState<BulkEditButtonType | null>(null);

    const handleBackClick = () => {
        setSelectedButton(null);
    };

    const onSubmitClick = (updatedProducts: ProductUX[]) => {
        updateProducts(updatedProducts);
        setSelectedButton(null);
        closeDialog();
    };

    const selectedProductIds = selectedProducts?.map((gridRowId) => Number(gridRowId)) || [];

    return (
        <Dialog
            open={isDialogOpen}
            onClose={onSubmitClick}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            style={{ zIndex }}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: 400,
                },
            }}
        >
            <BulkEditDialogTitle>
                <Typography color="#FFF" fontSize={18}>
                    Edit {selectedProductIds?.length} Selected Product{selectedProductIds?.length === 1 ? '' : 's'}
                </Typography>
                <ClearIcon
                    onClick={() => {
                        setSelectedButton(null);
                        closeDialog();
                    }}
                />
            </BulkEditDialogTitle>

            <DialogContent sx={{ padding: '10px' }}>
                {selectButton === null ? (
                    <BulkEditOptions onButtonClick={setSelectedButton} />
                ) : (
                    <BulkEditSelectedOptionContent
                        isGatherUser={isGatherUser}
                        onBackClick={handleBackClick}
                        selectButton={selectButton}
                        onSubmitClick={onSubmitClick}
                        selectedProductIds={selectedProductIds}
                        zIndex={zIndex}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default BulkEditProductsDialog;
