import { StyleRules, WithStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

export const HELPER_STYLES =
    <Props extends object>(): StyleRules<Props, HelperStylesClassKey> => ({
        verticalAlignMiddle: {
            verticalAlign: 'middle',
        },


        heightAuto: {
            height: 'auto !important',
        },
        height100: {
            height: '100%',
        },

        minHeight20: {
            minHeight: 20,
        },
        minHeight70: {
            minHeight: 70,
        },
        minHeight90: {
            minHeight: 90,
        },
        minMaxHeight550: {
            minHeight: '550px !important',
            maxHeight: '550px !important',
        },


        width100: {
            width: '100%',
        },
        widthAuto: {
            width: 'auto !important',
        },
        widthFitContent: {
            width: 'fit-content'
        },
        width50: {
            width: 50
        },
        width280: {
            width: 280,
        },


        maxWidth280: {
            maxWidth: 280,
        },
        maxWidth320: {
            maxWidth: 320,
        },
        scrollAuto: {
            overflow: 'auto',
        },
        scrollHidden: {
            overflow: 'hidden',
        },
        overflowScroll: {
            overflow: 'scroll',
        },
        overflowXAuto: {
            overflowX: 'auto',
        },
        overflowHidden: {
            overflow: 'hidden'
        },


        displayInlineFlex: {
            display: 'inline-flex',
        },
        displayInlineBlock: {
            display: 'inline-block',
        },
        displayBlock: {
            display: 'block',
        },
        displayFlex: {
            display: 'flex',
        },
        displayTable: {
            display: 'table',
        },
        displayNone: {
            display: 'none',
        },
        displayContents: {
            display: 'contents',
        },


        floatLeft: {
            float: 'left'
        },
        pullRight: {
            float: 'right',
        },


        textLeft: {
            textAlign: 'left',
        },
        textCenter: {
            textAlign: 'center',
        },
        textRight: {
            textAlign: 'right',
        },


        positionRelative: {
            position: 'relative',
        },
        positionAbsolute: {
            position: 'absolute',
        },


        pointerEventsNone: {
            pointerEvents: 'none',
        },

        cursorPointer: {
            cursor: 'pointer',
        },
        cursorGrab: {
            cursor: 'grab',
        },
        cursorGrabbing: {
            cursor: 'grabbing',
        },
        cursorDefault: {
            cursor: 'default',
        },


        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        buttonDisabled: {
            color: 'rgba(0,0,0,0.12)',
            background: 'rgba(0,0,0,0.12) !important',
            pointerEvents: 'none',
        },

        opacity50: {
            opacity: 0.5,
        },

        focusOutlineNone: {
            '&:focus': {
                outline: 'none',
            },
        },

        /**
         * BORDERS
         */
        b_solid_1: { // TODO rename
            border: '1px solid'
        },
        bt_solid_1: {
            borderTop: '1px solid'
        },
        elipses: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        b_solid_orange_2: {
            border: '2px orange solid',
        },

        /**
         * FLEX HELPERS
         */
        flexCentred: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        justifyContentCenter: {
            justifyContent: 'center',
        },
        justifyContentEnd: {
            justifyContent: 'flex-end !important',
        },
        justifyContentStart: {
            justifyContent: 'flex-start !important',
        },
        spaceBetween: {
            justifyContent: 'space-between !important',
        },
        flexColumnCentered: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        flexDirectionColumn: {
            flexDirection: 'column'
        },
    });

export const DATA_PRIVATE = '_lr-hide';

export type HelperStylesClassKey = 'verticalAlignMiddle'

    | 'heightAuto'
    | 'height100'

    | 'minHeight20'
    | 'minHeight70'
    | 'minHeight90'
    | 'minMaxHeight550'

    | 'width100'
    | 'widthAuto'
    | 'widthFitContent'
    | 'width50'
    | 'width280'

    | 'maxWidth280'
    | 'maxWidth320'
    | 'scrollAuto'
    | 'scrollHidden'
    | 'overflowScroll'
    | 'overflowXAuto'
    | 'overflowHidden'

    | 'displayInlineFlex'
    | 'displayInlineBlock'
    | 'displayBlock'
    | 'displayFlex'
    | 'displayTable'
    | 'displayNone'
    | 'displayContents'

    | 'floatLeft'
    | 'pullRight'

    | 'textLeft'
    | 'textCenter'
    | 'textRight'

    | 'positionRelative'
    | 'positionAbsolute'

    | 'pointerEventsNone'

    | 'cursorPointer'
    | 'cursorGrab'
    | 'cursorGrabbing'
    | 'cursorDefault'

    | 'buttonProgress'
    | 'buttonDisabled'

    | 'opacity50'
    | 'focusOutlineNone'

    | 'b_solid_1'
    | 'bt_solid_1'
    | 'elipses'
    | 'b_solid_orange_2'

    | 'flexCentred'
    | 'flexEnd'
    | 'justifyContentCenter'
    | 'justifyContentEnd'
    | 'justifyContentStart'
    | 'spaceBetween'
    | 'flexColumnCentered'
    | 'flexDirectionColumn'
    ;

export type HELPER_STYLED_PROPS = WithStyles<HelperStylesClassKey>;
