import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Clear from '@mui/icons-material/Clear';

import { convertHexToRGBA } from '../../services/app.service';
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from '../../constants/colorVariables';

import { ModerationStatus, ModerationCategory, getGatherCase } from '../../shared/types';
import ModerationContentController from './listing/ModerationContentController';
import { StoreState } from '../../types';
import { loadModerationCountsForCase } from '../../actions/Moderation.action';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';
import { SlideTransition } from '../common/Transitions';
import { GStyles } from '../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
                maxHeight: 'calc(100% - 52px)',
                minHeight: 'calc(100% - 52px)'
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: '0',
        minHeight: 58,
        display: 'flex',
        alignItems: 'center',
        margin: '0 40px 0 20px',
        '& h6': {
            fontSize: 18,
            color: theme.palette.primary.main
        }
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflow: 'auto',
        position: 'relative',
        width: '100%',
    },
    header: {
        flex: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: convertHexToRGBA(ORANGE_COLOR, 0.18),
        color: ORANGE_COLOR,
        marginBottom: '0.5em',
        '& p': {
            color: 'inherit',
        },
        '&$approved': {
            backgroundColor: convertHexToRGBA(GREEN_COLOR, 0.18),
            '& p': {
                color: GREEN_COLOR
            }
        },
        '&$blocked': {
            backgroundColor: convertHexToRGBA(RED_COLOR, 0.18),
            '& p': {
                color: RED_COLOR
            }
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        zIndex: 1,
        cursor: 'pointer',
    },
    radioGroup: {
        flexDirection: 'row',
        '& > label': {
            margin: 0
        },
        '& $approved': {
            color: GREEN_COLOR,
        },
        '& $blocked': {
            color: RED_COLOR,
            margin: 0,
        }
    },
    radioGroupContainer: {
        display: 'flex',
        '@media (min-width: 536px)': {
            marginRight: 32
        }
    },
    radio: {
        width: 32,
        height: 32,
        marginRight: 2,
        '& svg': {
            fontSize: 20
        },
    },
    radioLabel: {
        marginRight: 2,
        fontSize: 12
    },
    approvedRadio: {},
    blockedRadio: {},
    approved: {},
    blocked: {},
    dialogPaper: {}
});

function mapStateToProps({ moderationState, casesState }: StoreState, ownProps: OwnProps) {
    const thisCase = getGatherCase(casesState);
    return {
        counts: thisCase && moderationState.caseCounts.find((c) => c.case_id === thisCase.id),
        caseUuid: thisCase?.uuid,
        caseFname: thisCase?.fname,
    };
}

interface OwnProps {
    isOpen: boolean;
    zIndex: number;
    handleClose: () => void;
    initialCategory: ModerationCategory;
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface State {
    activeStatus: ModerationStatus;
}

type StyledProps = WithStyles<'root' | 'header' | 'approved' | 'radioGroupContainer' | 'radio'
    | 'dialogHeader' | 'dialogContent' | 'dialogPaper' | 'clearIcon' | 'radioLabel' | 'blocked' | 'approvedRadio'
    | 'blockedRadio' | 'radioGroup'>;

class ModerationDialog extends Component<StyledProps & Props, State> {
    constructor(props: StyledProps & Props) {
        super(props);
        this.state = {
            activeStatus: ModerationStatus.pending,
        };
    }

    async componentDidUpdate(prevProps: Props) {
        const { isOpen: prevIsOpen } = prevProps;
        const { isOpen, caseUuid, dispatch } = this.props;

        if (!prevIsOpen && isOpen && caseUuid) {
            dispatch(loadModerationCountsForCase({ caseUuid }));
        }
    }

    getInitialCategory = () => {
        const { initialCategory, counts } = this.props;
        const { activeStatus } = this.state;

        const countsForStatus = counts && counts[activeStatus];

        if (!countsForStatus || countsForStatus.visitors > 0) {
            return ModerationCategory.visitors;
        }

        if (initialCategory === ModerationCategory.visitors) {
            if (countsForStatus.memories > 0) {
                return ModerationCategory.memories;
            } else if (countsForStatus.photos > 0) {
                return ModerationCategory.photos;
            } else {
                return ModerationCategory.visitors;
            }
        }

        if (initialCategory === ModerationCategory.memories) {
            return ModerationCategory.memories;
        }

        if (initialCategory === ModerationCategory.photos) {
            return ModerationCategory.photos;
        }

        return initialCategory;
    };

    render() {
        const {
            classes,
            isOpen,
            zIndex,
            handleClose,
            caseFname,
            caseUuid,
            counts,
        } = this.props;
        const { activeStatus } = this.state;

        let bannerText = 'Memories will NOT be visible to the family until they are approved.';

        if (activeStatus === ModerationStatus.approved) {
            bannerText = 'The following memories have been approved and are visible to the family.';
        } else if (activeStatus === ModerationStatus.blocked) {
            bannerText = 'The following memories have been blocked and are not visible to the family.';
        }

        return (
            <>
                <Dialog
                    open={isOpen}
                    fullScreen
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={handleClose}
                    className={classes.root}
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                >
                    <DialogTitle
                        className={classes.dialogHeader}
                        color="primary"
                    >
                        Moderate Memories of {caseFname}
                        <Clear
                            color="secondary"
                            onClick={handleClose}
                            style={{ zIndex }}
                            className={classes.clearIcon}
                        />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid
                            item
                            xs={12}
                            className={classNames(
                                classes.header,
                                activeStatus === ModerationStatus.approved && classes.approved,
                                activeStatus === ModerationStatus.blocked && classes.blocked
                            )}
                        >
                            <Grid item xs={12} className={classes.radioGroupContainer}>
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    className={classes.radioGroup}
                                    value={activeStatus}
                                    onChange={(e, value: string) => {
                                        if (ModerationStatus[value]) {
                                            this.setState({ activeStatus: ModerationStatus[value] });
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        value={ModerationStatus.pending}
                                        control={<Radio color="secondary" className={classes.radio} />}
                                        label={`Pending ${counts ? `(${counts.pending.all})` : ''}`}
                                        classes={{ label: classNames(GStyles.colorOrange, classes.radioLabel) }}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={ModerationStatus.approved}
                                        control={<Radio
                                            color="secondary"
                                            className={classNames(classes.approvedRadio, classes.radio)}
                                        />}
                                        label={`Approved ${counts ? `(${counts.approved.all})` : ''}`}
                                        labelPlacement="end"
                                        classes={{ label: classNames(classes.approved, classes.radioLabel) }}
                                    />
                                    <FormControlLabel
                                        value={ModerationStatus.blocked}
                                        control={<Radio
                                            color="secondary"
                                            className={classNames(classes.blockedRadio, classes.radio)}
                                        />}
                                        label={`Blocked ${counts ? `(${counts.blocked.all})` : ''}`}
                                        labelPlacement="end"
                                        classes={{ label: classNames(classes.blocked, classes.radioLabel) }}
                                    />
                                </RadioGroup>
                            </Grid>

                            <Typography align="center">
                                {bannerText}
                            </Typography>
                        </Grid>

                        {isOpen && caseUuid &&
                            <ModerationContentController
                                key={activeStatus}
                                activeCaseUuid={caseUuid}
                                hideCaseCard
                                status={activeStatus}
                                initialCategory={this.getInitialCategory()}
                                zIndex={zIndex + 1}
                                showIndividualCards={false}
                            />
                        }
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withState(mapStateToProps)(withStyles(styles)(ModerationDialog));
