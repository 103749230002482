import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Chip, Grid } from "@mui/material";
import { useState } from 'react';
import BulkEditDialogFooter from "./BulkEditDialogFooter";
import { useGDispatch, useGSelector } from '../../../../../types';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductTagRecord, ProductUX } from '../../../../../shared/types';
import AvailableTagsDialog from '../../../../allProducts/AvailableTags.dialog';
import { map } from 'lodash';

interface BulkRemoveProductTagProps {
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    zIndex: number;
    isGatherUser: boolean;
}

const BulkRemoveProductTag = (props: BulkRemoveProductTagProps) => {
    const {
        onSubmitClick,
        selectedProductIds,
        funeralHomeId,
        zIndex,
        isGatherUser,
    } = props;

    const dispatch = useGDispatch();
    const productStateData = useGSelector(({ productState }) => productState);
    const tagList = productStateData.tags;
    const originalProductList = productStateData.funeralHomeProducts.filter((product) =>
        selectedProductIds.includes(product.id));
    let originalProductTagList: ProductTagRecord[] = [];
    originalProductList.map((product) => {
        const theseTags = Object.entries(product.tags).map(([name, value]) => ({ name, value }));
        theseTags.forEach((tag) => {
            originalProductTagList.push(...tagList.filter((t) => t.name === tag.name && t.value === tag.value));
        });
        return theseTags;
    });
    const uniqueIds = new Set();
    const availableTags = originalProductTagList.filter(item => {
        if (uniqueIds.has(item.name + item.value)) {
            return false;
        } else {
            uniqueIds.add(item.name + item.value);
            return true;
        }
    });
    const [isSpinning, setIsSpinning] = useState(false);
    const [isAvailableTagsDialogOpen, setIsAvailableTagsDialogOpen] = useState(false);
    const [removeTags, setRemoveTags] = useState<ProductTagRecord[]>([]);

    const toggleAvailableTagsDialog = () => {
        setIsAvailableTagsDialogOpen(!isAvailableTagsDialogOpen);
    };
    const handleSelectTag = (tag: ProductTagRecord) => {
        setRemoveTags([...removeTags, tag]);
    };
    const handleRemoveTag = (tag: Record<string, string>) => {
        const { name, value } = tag;
        setRemoveTags(
            removeTags.filter((t) => t.name !== name && t.value !== value));
    };

    const handleSubmitclick = async () => {
        if (!selectedProductIds?.length || typeof funeralHomeId !== 'number') {
            return;
        }
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.remove_tag,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: {},
            tagChanges:  removeTags,
        }));
        setIsSpinning(false);
        onSubmitClick(updatedProducts);
    };
    
    return (
        <>
            <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                px={1.5}
            >
                <Chip
                    key="removeTags"
                    label="Add Tag to Remove"
                    clickable
                    color="primary"
                    onDelete={setIsAvailableTagsDialogOpen}
                    deleteIcon={<AddCircleIcon />}
                    onClick={toggleAvailableTagsDialog}

                />
                {map(removeTags, ( t )  => (
                    <Chip
                        key={`${t.name}: ${t.value}`}
                        label={`${t.name}: ${t.value}`}
                        onDelete={() => handleRemoveTag({name:t.name, value: t.value})}
                    />
                ))}

                <AvailableTagsDialog
                    isDialogOpen={isAvailableTagsDialogOpen}
                    closeDialog={toggleAvailableTagsDialog}
                    isGatherUser={isGatherUser}
                    tags={availableTags}
                    category={undefined}
                    onTagSelect={handleSelectTag}
                    zIndex={zIndex + 1}
                />
            </Grid>

            <BulkEditDialogFooter
                selectedProductsCount={selectedProductIds.length}
                onSubmitClick={handleSubmitclick}
                disableSubmitButton={removeTags.length === 0}
                isSpinning={isSpinning}
            />
        </>
    );
};

export default BulkRemoveProductTag;