import { Component } from 'react';

import Typography from '@mui/material/Typography';

import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        padding: '32px 8px 8px',
        background: theme.palette.primary.main,
        '& p': {
            fontSize: '10px !important',
            fontWeight: 200,
            lineHeight: '1.25em',
            color: theme.palette.common.white
        },
        '& $heading': {
            marginTop: 8,
            fontSize: '20px !important',
            textTransform: 'capitalize',
            textAlign: 'center',
        },
        '& $subHeading': {
            fontSize: '16px !important',
            textAlign: 'center',
            marginBottom: 4
        }
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    left: {
        width: 'calc(50% - 36px)',
    },
    right: {
        width: 'calc(50% - 36px)',
        textAlign: 'center',
    },
    subHeading: {},
    heading: {}
});

type StyledProps = Props & WithGStyles<'root' | 'heading' | 'left' | 'right' | 'topSection' | 'subHeading'>;

interface Props {
    heading: string;
    subheading?: string;
    received: string;
    expected: string;
    collected: number;
    paymentCount: number;
    rootClass?: string;
}

class CardHeader extends Component<StyledProps> {
    render() {
        const {
            classes,
            heading,
            subheading,
            rootClass
        } = this.props;

        return (
            <div className={classNames(classes.root, rootClass)}>

                {/* hide the below section as mention in APP-817 we will uncomment them later */}
                {/* <div className={classes.topSection}>
                    <div className={classes.left}>
                        <Typography color="primary">
                            Received:&nbsp;
                            <span className={classes.fontWeight500}>{received}</span>
                        </Typography>
                        <Typography color="primary">
                            Expected:&nbsp;
                            <span className={classes.fontWeight500}>{expected}</span>
                        </Typography>
                    </div>

                    <div className={classes.right}>
                        <Typography color="primary" className={classes.fontWeight500}>
                            {collected}% Collected
                        </Typography>

                        <Typography color="primary">
                            ({paymentCount}&nbsp;{paymentCount === 1 && 'Payment' || 'Payments'})
                        </Typography>
                    </div>
                </div> */}

                <Typography color="primary" className={classes.heading}>
                    {heading}
                </Typography>
                {subheading && <Typography color="primary" className={classes.subHeading}>
                    {subheading}
                </Typography>}
            </div>
        );
    }
}

export default withGStyles(styles)(CardHeader);
