import classNames from 'classnames';

import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Button, { ButtonProps } from '@mui/material/Button';

import { CaseCategoryStyledProps, caseCategoryStyles } from './style';

import { CaseType } from '../../../shared/types';

import GGroupButtons from '../../common/GGroupButtons';
import withStyles from '@mui/styles/withStyles';
import { GStyles } from '../../../styles/GStyles';

interface CaseTypeGroupProps {
    rootClass?: string;
    active: CaseType;
    showOneOff?: boolean;
    onClick: (type: CaseType) => void;
    intercomTargetProp?: string;
    disabled?: boolean;
}

const CaseTypeGroupButtonsComponent = (props: CaseTypeGroupProps & CaseCategoryStyledProps) => {
    const { classes, active, onClick, rootClass, showOneOff, intercomTargetProp, disabled } = props;

    const buttons = [
        { label: 'PRE-NEED', value: CaseType.pre_need, className: classes.translucent, disabled },
        { label: 'AT-NEED', value: CaseType.at_need, className: classes.default, disabled },
        { label: 'TRADE', value: CaseType.trade, className: classes.dark, disabled },
    ];

    if (showOneOff) {
        buttons.push({ label: 'ONE-OFF', value: CaseType.one_off, className: classes.white, disabled });
    }

    return (
        <GGroupButtons
            controlClasses={classNames(classes.groupButton, classes.outlinedButton, rootClass)}
            buttons={buttons}
            activeClass={classes.active}
            labelClass={classes.label}
            activeButton={active}
            onClick={type => onClick(type)}
            intercomTargetProp={intercomTargetProp}
        />
    );
};

export const CaseTypeGroupButtons = withStyles(caseCategoryStyles<CaseTypeGroupProps>())(
    CaseTypeGroupButtonsComponent
);

export type ButtonType = 'translucent' | 'dark' | 'default' | 'white' | 'translucentTheme';
export type ButtonVariantType = 'text' | /* 'flat' | */ 'outlined' | 'contained'
/* | 'raised' | 'fab' | 'extendedFab' */;
export type ButtonSizeType = 'xSmall' | 'small' | 'medium' | 'large';

interface CaseTypeButtonProps {
    buttonStyle: ButtonType;
    buttonSize?: ButtonSizeType;
    active: boolean;
    icon?: JSX.Element;
    arrow?: boolean;
    tooltip?: string;
    border?: boolean;
    isDashboardAppBar?: boolean;
}

type StyledProps = CaseCategoryStyledProps & CaseTypeButtonProps & ButtonProps;

const CaseTypeButtonComponent = (props: StyledProps) => {
    const {
        classes,
        children,
        buttonStyle: type,
        active,
        variant,
        buttonSize,
        arrow,
        icon,
        tooltip,
        border,
        isDashboardAppBar,
        ...others
    } = props;
    const _variant = variant || 'contained';

    return (
        <Tooltip title={tooltip || ''} enterDelay={1000}>
            <Button
                className={classNames(
                    classes.caseButton,
                    arrow && classes.arrow,
                    active && classes.active,
                    classes[buttonSize || 'small'],
                    classes[type],
                    classes[variant || 'contained'],
                    border && classes.border1px,
                    isDashboardAppBar && classes.marginLeft10,
                    !others.onClick && GStyles.pointerEventsNone
                )}
                color="primary"
                variant={_variant}
                TouchRippleProps={{
                    classes: { root: arrow && active && classes.arrowRipple || undefined }
                }}
                {...others}
            >
                <span className={classes.label}>
                    {icon && <Icon className={classes.icon}>{icon}</Icon>}
                    <span>{children}</span>
                </span>
            </Button>
        </Tooltip>
    );
};

export const CaseTypeButton = withStyles(caseCategoryStyles<CaseTypeButtonProps>())(CaseTypeButtonComponent);