import { useGSelector } from '../types';
import AppLoader from './common/AppLoader';
import { DeepLinkTypeEnum } from '../shared/types';
import GNavigate from './navigation/GNavigate';
import { LoginRoutePage } from '../services';
import InvalidDeepLinkPage from './common/InvalidDeepLinkPage';
import LoginNavigate from './navigation/LoginNavigate';

interface Props {
    authenticated: boolean;
}

const DeepLinkRouter = (props: Props) => {
    const { authenticated } = props;
    const { isLoading, deepLink } = useGSelector((state) => state.deepLinkState);

    if (isLoading === null || isLoading) {
        return <AppLoader />;
    } else if (!deepLink) {
        return <InvalidDeepLinkPage />;

    } else if (authenticated || deepLink.publicLink) {
        return <GNavigate
            to={deepLink.targetPage}
            replace={true}
        />;

    } else if (deepLink.configured && deepLink.type === DeepLinkTypeEnum.reset_password) {
        return <LoginNavigate
            page={LoginRoutePage.RESETPASSWORD}
            replace={true}
        />;

    } else if (deepLink.configured) {
        return <LoginNavigate
            page={LoginRoutePage.USER}
            destination={deepLink.targetPage}
            replace={true}
        />;

    } else if (deepLink.resetCodeReason !== 'Valid') {
        return <LoginNavigate
            page={LoginRoutePage.FORGOTPASSWORD}
            replace={true}
        />;

    } else {
        return <LoginNavigate
            page={LoginRoutePage.CREATEPASSWORD}
            destination={deepLink.targetPage}
            replace={true}
        />;
    }
};

export default DeepLinkRouter;
