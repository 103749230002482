import { Component } from 'react';
import classNames from 'classnames';
/* eslint-disable */
export default class CardReaderSVG extends Component<{ themeFillClass: string }> {
    render() {
        const { themeFillClass } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 109.6600037 109.6600037">
                <defs>
                    <style type="text/css" dangerouslySetInnerHTML={{
                        __html: `
                        .st0{opacity:0.15;fill:#005CEF;}
                        .st1{opacity:0.7;fill:#005CEF;}
                        .st2{fill:#005CEF;}
                        .st3{fill:#FFFFFF;}
                        .st4{opacity:0.35;fill:#005CEF;}
                        .st5{fill:#303030;}
                        .st6{fill:none;}
                        </style>
                    `}} />
                </defs>
                <g>
                    <g>
                        <path className={classNames('st0', themeFillClass)} d="M46.2117882,38.0027771c0-6.3477783,2.1586914-12.1975098,5.7730103-16.866333
                            c-6.3007813,4.9154663-10.3572388,12.5738525-10.3572388,21.1844482c0,10.9776611,6.5888062,20.4110718,16.0245972,24.5773926
                            l4.5645142-3.8520508C52.775753,58.653717,46.2117882,49.0829773,46.2117882,38.0027771z"/>
                        <path className={classNames('st0', themeFillClass)} d="M73.8220215,28.0486755c-1.979187,0-3.5880737,1.6088257-3.5880737,3.5880737
                            v2.3148804h7.1761475v-2.3148804C77.4100952,29.6575012,75.8012085,28.0486755,73.8220215,28.0486755z"/>
                        <path className={classNames('st0', themeFillClass)} d="M68.4876709,15.4607239c-4.0947876,0-7.9734535,0.920166-11.4464149,2.5588989
                            c-5.6989136,4.793396-9.3294678,11.9708862-9.3294678,19.9831543c0,10.7267456,6.5041504,19.9598389,15.774292,23.972168
                            l4.1925087-3.5380859c0.0275879-0.0230713,0.0612183-0.0310669,0.0908813-0.0495605
                            c0.045105-0.0286255,0.0880127-0.057312,0.1389771-0.0758667c0.0488281-0.0177002,0.0979614-0.0228271,0.1485596-0.0300293
                            c0.0359497-0.0053101,0.0680542-0.0213013,0.1054688-0.0213013h21.9428101
                            c3.2931519-4.4588623,5.2425537-9.9706421,5.2425537-15.939209
                            C95.3478394,27.4864197,83.3221436,15.4607239,68.4876709,15.4607239z M83.0814819,47.8408508
                            c0,1.2731323-1.041687,2.3148193-2.3148193,2.3148193H66.8773804c-1.2731323,0-2.3148193-1.041687-2.3148193-2.3148193V36.266449
                            c0-1.2731323,1.041687-2.3148193,2.3148193-2.3148193h1.1574707v-2.3148804c0-3.194519,2.5926514-5.7871704,5.7871704-5.7871704
                            s5.7871704,2.5926514,5.7871704,5.7871704v2.3148804h1.1574707c1.2731323,0,2.3148193,1.041687,2.3148193,2.3148193V47.8408508z"
                        />
                        <path className={classNames('st0', themeFillClass)} d="M73.8220215,39.7388c-1.2731934,0-2.3148804,1.041687-2.3148804,2.3148193
                            c0,1.2731934,1.041687,2.3148804,2.3148804,2.3148804s2.3148804-1.041687,2.3148804-2.3148804
                            C76.1369019,40.7804871,75.0952148,39.7388,73.8220215,39.7388z"/>
                        <path className={classNames('st1', themeFillClass)} d="M62.216671,63.0462341l1.2694092-1.0712891
                            c-9.2701416-4.0123291-15.774292-13.2454224-15.774292-23.972168c0-8.0122681,3.6305542-15.1897583,9.3294678-19.9831543
                            c4.541626-3.8200073,10.3956947-6.1271973,16.7808876-6.1271973c14.3969727,0,26.1098633,11.7128906,26.1098633,26.1103516
                            c0,8.1661987-3.7709351,15.4659424-9.6601563,20.2573242h2.284668c5.4532471-5.0469971,8.8754883-12.258667,8.8754883-20.2573242
                            c0-15.2246094-12.3857422-27.6103516-27.6098633-27.6103516c-8.8768311,0-16.7830238,4.2151489-21.8373451,10.7440186
                            c-3.6143188,4.6688232-5.7730103,10.5185547-5.7730103,16.866333C46.2117882,49.0829773,52.775753,58.653717,62.216671,63.0462341
                            z"/>
                        <path className={classNames('st1', themeFillClass)} d="M80.7666626,33.9516296h-1.1574707v-2.3148804
                            c0-3.194519-2.5926514-5.7871704-5.7871704-5.7871704s-5.7871704,2.5926514-5.7871704,5.7871704v2.3148804h-1.1574707
                            c-1.2731323,0-2.3148193,1.041687-2.3148193,2.3148193v11.5744019c0,1.2731323,1.041687,2.3148193,2.3148193,2.3148193h13.8892822
                            c1.2731323,0,2.3148193-1.041687,2.3148193-2.3148193V36.266449
                            C83.0814819,34.9933167,82.0397949,33.9516296,80.7666626,33.9516296z M73.8220215,44.3684998
                            c-1.2731934,0-2.3148804-1.041687-2.3148804-2.3148804c0-1.2731323,1.041687-2.3148193,2.3148804-2.3148193
                            s2.3148804,1.041687,2.3148804,2.3148193C76.1369019,43.3268127,75.0952148,44.3684998,73.8220215,44.3684998z
                            M77.4100952,33.9516296h-7.1761475v-2.3148804c0-1.979248,1.6088867-3.5880737,3.5880737-3.5880737
                            s3.5880737,1.6088257,3.5880737,3.5880737V33.9516296z"/>
                        <path className={classNames('st0', themeFillClass)} d="M4.3442993,59.5896912c0,9.565918,7.7822266,17.3486328,17.3481445,17.3486328
                            c4.3670044,0,8.3510132-1.6343384,11.4039307-4.3074951c1.9567871-2.8910522,3.1002808-6.3775024,3.1002808-10.1314087
                            c0-9.9954224-8.1029053-18.0982666-18.0983276-18.0982666c-2.4077148,0-4.7007446,0.4799194-6.802002,1.3334351
                            C7.0848999,48.9027405,4.3442993,53.9257507,4.3442993,59.5896912z M14.3491211,51.4303284l1.223877,1.223938l1.223938-1.223938
                            l1.223938,1.223938l1.223877-1.223938l1.223938,1.223938l1.223938-1.223938l1.223877,1.223938l1.223938-1.223938
                            l1.223938,1.223938l1.223877-1.223938l1.223938,1.223938l1.223938-1.223938v16.3189087l-1.223938-1.223877l-1.223938,1.223877
                            l-1.223877-1.223877l-1.223938,1.223877l-1.223938-1.223877l-1.223877,1.223877l-1.223938-1.223877l-1.223938,1.223877
                            l-1.223877-1.223877l-1.223938,1.223877l-1.223938-1.223877l-1.223877,1.223877V51.4303284z"/>
                        <path className={classNames('st0', themeFillClass)} d="M18.0983276,80.5977478c4.0506592,0,7.7776489-1.3470459,10.793335-3.5951538
                            c-2.2199707,0.9212646-4.6499023,1.43573-7.1992188,1.43573c-10.3930664,0-18.8481445-8.4555664-18.8481445-18.8486328
                            c0-3.9724121,1.2422485-7.65625,3.3486938-10.6984863C2.4043579,52.2085266,0,57.0680847,0,62.4994202
                            C0,72.4949036,8.1029053,80.5977478,18.0983276,80.5977478z"/>
                        <rect x="16.796936" y="62.0376282" className={classNames('st0', themeFillClass)} width="9.7913208" height="1.631897" />
                        <rect x="16.796936" y="55.5101013" className={classNames('st0', themeFillClass)} width="9.7913208" height="1.631897" />
                        <rect x="16.796936" y="58.7738342" className={classNames('st0', themeFillClass)} width="9.7913208" height="1.631897" />
                        <path className={classNames('st1', themeFillClass)} d="M21.6924438,78.438324c2.5493164,0,4.979248-0.5144653,7.1992188-1.43573
                            c6.8303833-2.8346558,11.6494141-9.5691528,11.6494141-17.4129028c0-10.3930664-8.4555664-18.8481445-18.8486328-18.8481445
                            c-6.4206543,0-12.0946655,3.2324219-15.4994507,8.1496582c-2.1064453,3.0422363-3.3486938,6.7260742-3.3486938,10.6984863
                            C2.8442993,69.9827576,11.2993774,78.438324,21.6924438,78.438324z M21.6924438,42.2415466
                            c9.565918,0,17.3486328,7.7822266,17.3486328,17.3481445c0,5.1989136-2.3101807,9.8587646-5.9447021,13.0411377
                            c-3.0529175,2.6731567-7.0369263,4.3074951-11.4039307,4.3074951c-9.565918,0-17.3481445-7.7827148-17.3481445-17.3486328
                            c0-5.6639404,2.7406006-10.6869507,6.9520264-13.8551025C14.1976318,43.5520325,17.7904663,42.2415466,21.6924438,42.2415466z"/>
                        <path className={classNames('st1', themeFillClass)} d="M16.796936,67.7492371l1.223938-1.223877l1.223877,1.223877l1.223938-1.223877
                            l1.223938,1.223877l1.223877-1.223877l1.223938,1.223877l1.223938-1.223877l1.223877,1.223877l1.223938-1.223877
                            l1.223938,1.223877V51.4303284l-1.223938,1.223938l-1.223938-1.223938l-1.223877,1.223938l-1.223938-1.223938l-1.223938,1.223938
                            l-1.223877-1.223938l-1.223938,1.223938l-1.223938-1.223938l-1.223877,1.223938l-1.223938-1.223938l-1.223938,1.223938
                            l-1.223877-1.223938v16.3189087l1.223877-1.223877L16.796936,67.7492371z M16.796936,55.5101013h9.7913208v1.631897H16.796936
                            V55.5101013z M16.796936,58.7738342h9.7913208v1.631897H16.796936V58.7738342z M16.796936,62.0376282h9.7913208v1.631897
                            H16.796936V62.0376282z"/>
                        <path className={classNames('st2', themeFillClass)} d="M101.9007568,70.8245544
                            c-1.7197266-0.3662109-4.0126953,0.7700195-5.5214844,2.0561523c-0.0005493,0.0004883-0.0012207,0.0005493-0.00177,0.0009766
                            c-0.0004883,0.0004272-0.0006714,0.0010376-0.0011597,0.0014648l-34.0709877,28.7623291
                            c-1.9495239,1.7146606-2.2127075,3.4110718-2.2127075,4.8446045v1.6699219h26.6298866
                            c9.7822266,0,17.7402344-8.0029297,17.7402344-17.8398438V76.1600037
                            C104.4627686,73.9671326,104.0184326,71.2620544,101.9007568,70.8245544z"/>
                        <path className={classNames('st2', themeFillClass)} d="M99.2791748,61.106842L67.0765381,88.2933044
                            c-0.0775146,0.0654297-0.1651611,0.1083984-0.2563477,0.1373901c-0.0123291,0.0054321-0.0204468,0.0159912-0.0332031,0.020813
                            l-25.4125404,9.5772705c-0.8029175,0.34021-1.5159302,0.6107178-2.1919556,0.847229v9.2839966h19.4101563v-1.6699219
                            c0-1.6660156,0.3095703-3.8510742,2.7246094-5.9736328c0.0019531-0.001709,0.0044556-0.0020142,0.0064087-0.0036621
                            c0.0020142-0.00177,0.0027466-0.0043335,0.0048218-0.0061035l34.0770912-28.7674561c0.000061,0,0-0.0001221,0.000061-0.0001221
                            c0.0001831-0.0001831,0.0004272-0.0003052,0.0006104-0.0004883l0.0023193-0.0019531
                            c0.0001831-0.0001221,0.0003662-0.0001831,0.0005493-0.0003052c1.5187378-1.2936401,3.7718506-2.526001,5.8934937-2.4817505
                            v-3.824585C101.3026123,63.7459412,100.5568848,62.1757507,99.2791748,61.106842z"/>
                        <polygon className={'st3'} points="65.5125732,84.2499695 92.2426147,61.6799622 81.5125732,61.6799622 69.6726074,61.6799622 
                            42.9325523,84.2499695 54.7725792,84.2499695"/>
                        <path className={classNames('st4', themeFillClass)} d="M11.0926485,98.4197693h22.1201172
                            c0.0108643,0,0.0198975,0.0057373,0.0306396,0.0061646c0.0112915-0.0004272,0.0220337-0.0062256,0.0333252-0.0061646
                            c0.0463867,0,0.0908203-0.0004883,0.1347656-0.0053711c1.7104492-0.0239258,3.1113281-0.2529297,4.7563477-0.7695313
                            c0.7841797-0.2553711,1.6503906-0.5849609,2.6416016-1.0053711c0.0050049-0.0021362,0.0103149-0.0010986,0.0153809-0.0031128
                            c0.0048828-0.0019531,0.0079956-0.0062256,0.0129395-0.0081177l25.3619423-9.5582275l31.7467041-26.8015747
                            c-0.7006836-0.319519-1.4615479-0.4959717-2.2458496-0.5083618H95.602417H68.4368896L34.626339,88.2933044
                            c-0.0529785,0.0445557-0.1132202,0.0721436-0.1731567,0.0997925c-0.0167236,0.0077515-0.0280762,0.0220337-0.0455933,0.0286255
                            l-23.8043213,9.0039063C9.6027193,97.842865,8.819211,98.2705383,8.3126802,99.283844
                            C9.1049776,98.740387,10.0614595,98.4197693,11.0926485,98.4197693z M69.6726074,61.6799622h11.8399658h10.7300415
                            L65.5125732,84.2499695h-10.739994H42.9325523L69.6726074,61.6799622z M32.0262413,95.7600403h-5.7622662l3.5204468-1.3261719
                            h5.7622662L32.0262413,95.7600403z M40.9569664,92.3921814l-3.5204468,1.3261719h-5.7622051l3.5203838-1.3261719H40.9569664z
                            M37.0598717,91.6767273l3.5204468-1.3262329h5.762207l-3.5204468,1.3262329H37.0598717z M39.9043541,95.7600403h-5.762207
                            l3.5204468-1.3261719h5.762207L39.9043541,95.7600403z M45.3146324,93.7183533h-5.762207l3.5204468-1.3261719h5.762207
                            L45.3146324,93.7183533z M50.7002525,91.6767273h-5.7622681l3.5204468-1.3262329h5.762207L50.7002525,91.6767273z
                            M32.7021446,90.3504944h5.7622681l-3.5204468,1.3262329h-5.7622662L32.7021446,90.3504944z M27.3165874,92.3921814h5.7622051
                            l-3.5204449,1.3261719h-5.762207L27.3165874,92.3921814z M21.9063091,94.4338684h5.762207l-3.5204468,1.3261719h-5.762207
                            L21.9063091,94.4338684z"/>
                        <path className={classNames('st1', themeFillClass)} d="M37.6824913,99.3363953c-1.1608276,0.3049316-2.2752686,0.4776001-3.4399414,0.5385742
                            v8.2850342h3.4399414V99.3363953z"/>
                        <path className={classNames('st1', themeFillClass)} d="M32.7425499,99.9197693H11.0926485
                            c-1.8916016,0-3.4301758,1.5385742-3.4301758,3.4301758v4.8100586h25.0800781V99.9197693z"/>
                        <path className={'st5'} d="M108.9100037,108.1600037H94.144165c6.9344482-2.9257813,11.8186035-9.8190308,11.8186035-17.8398438
                            V76.1600037c0-4.4147949-1.6046143-6.0678101-3.1601563-6.633606v-4.0963745c0-2.4296875-1.2231445-4.6723633-3.265625-5.9951172
                            c-1.1259766-0.746582-2.4438477-1.152832-3.8242188-1.1748047H95.602417h-3.0458984h-2.284668h-0.1665649H68.1624756
                            c-0.0374146,0-0.069519,0.0159912-0.1054688,0.0213013c-0.0505981,0.0072021-0.0997314,0.0123291-0.1485596,0.0300293
                            c-0.0509644,0.0185547-0.0938721,0.0472412-0.1389771,0.0758667c-0.0296631,0.0184937-0.0632935,0.0264893-0.0908813,0.0495605
                            l-4.1925087,3.5380859l-1.2694092,1.0712891l-4.5645142,3.8520508L33.756649,87.0640564l-23.6991577,8.9641113
                            c-0.0041504,0.0015869-0.0067139,0.005127-0.0108032,0.0067749c-0.0041504,0.0016479-0.0084839,0.0007935-0.0126343,0.0025024
                            c-2.5678711,1.0698242-3.871582,2.4946289-3.871582,7.3125v4.8100586H0.752805c-0.4140625,0-0.75,0.3359375-0.75,0.75
                            s0.3359375,0.75,0.75,0.75h108.157196c0.4140625,0,0.75-0.3359375,0.75-0.75S109.3240662,108.1600037,108.9100037,108.1600037z
                            M10.6032686,97.4256287l23.8043213-9.0039063c0.0175171-0.0065918,0.0288696-0.020874,0.0455933-0.0286255
                            c0.0599365-0.0276489,0.1201782-0.0552368,0.1731567-0.0997925l33.8105507-28.5332031H95.602417h0.0981445
                            c0.7843018,0.0123901,1.545166,0.1888428,2.2458496,0.5083618L66.199707,87.0700378l-25.3619423,9.5582275
                            c-0.0049438,0.0018921-0.0080566,0.0061646-0.0129395,0.0081177c-0.0050659,0.0020142-0.010376,0.0009766-0.0153809,0.0031128
                            c-0.9912109,0.4204102-1.8574219,0.75-2.6416016,1.0053711c-1.6450195,0.5166016-3.0458984,0.7456055-4.7563477,0.7695313
                            c-0.0439453,0.0048828-0.0883789,0.0053711-0.1347656,0.0053711c-0.0112915-0.000061-0.0220337,0.0057373-0.0333252,0.0061646
                            c-0.0107422-0.0004272-0.0197754-0.0061646-0.0306396-0.0061646H11.0926485c-1.031189,0-1.9876709,0.3206177-2.7799683,0.8640747
                            C8.819211,98.2705383,9.6027193,97.842865,10.6032686,97.4256287z M104.4627686,90.3201599
                            c0,9.8369141-7.9580078,17.8398438-17.7402344,17.8398438H60.0926476v-1.6699219
                            c0-1.4335327,0.2631836-3.1299438,2.2127075-4.8446045l34.0709877-28.7623291
                            c0.0004883-0.0004272,0.0006714-0.0010376,0.0011597-0.0014648c0.0005493-0.0004272,0.0012207-0.0004883,0.00177-0.0009766
                            c1.5087891-1.2861328,3.8017578-2.4223633,5.5214844-2.0561523c2.1176758,0.4375,2.5620117,3.1425781,2.5620117,5.3354492
                            V90.3201599z M101.3026123,69.2546082c-2.1216431-0.0442505-4.3747559,1.1881104-5.8934937,2.4817505
                            c-0.0001831,0.0001221-0.0003662,0.0001831-0.0005493,0.0003052L95.40625,71.7386169
                            c-0.0001831,0.0001831-0.0004272,0.0003052-0.0006104,0.0004883c-0.000061,0,0,0.0001221-0.000061,0.0001221
                            l-34.0770912,28.7674561c-0.0020752,0.00177-0.0028076,0.0043335-0.0048218,0.0061035
                            c-0.0019531,0.0016479-0.0044556,0.0019531-0.0064087,0.0036621c-2.4150391,2.1225586-2.7246094,4.3076172-2.7246094,5.9736328
                            v1.6699219H39.1824913v-9.2839966c0.6760254-0.2365112,1.3890381-0.507019,2.1919556-0.847229l25.4125404-9.5772705
                            c0.0127563-0.0048218,0.020874-0.0153809,0.0332031-0.020813c0.0911865-0.0289917,0.178833-0.0719604,0.2563477-0.1373901
                            L99.2791748,61.106842c1.27771,1.0689087,2.0234375,2.6390991,2.0234375,4.3231812V69.2546082z M34.2425499,99.8749695
                            c1.1646729-0.0609741,2.2791138-0.2336426,3.4399414-0.5385742v8.8236084h-3.4399414V99.8749695z M7.6624727,103.3499451
                            c0-1.8916016,1.5385742-3.4301758,3.4301758-3.4301758h21.6499023v8.2402344H7.6624727V103.3499451z"/>
                        <polygon className={'st5'} points="38.4644127,90.3504944 32.7021446,90.3504944 29.1816998,91.6767273 34.9439659,91.6767273 		" />
                        <polygon className={'st5'} points="46.3425255,90.3504944 40.5803185,90.3504944 37.0598717,91.6767273 42.8220787,91.6767273 		" />
                        <polygon className={'st5'} points="44.9379845,91.6767273 50.7002525,91.6767273 54.2206383,90.3504944 48.4584312,90.3504944 		" />
                        <polygon className={'st5'} points="33.0787926,92.3921814 27.3165874,92.3921814 23.7961407,93.7183533 29.5583477,93.7183533 		" />
                        <polygon className={'st5'} points="31.6743145,93.7183533 37.4365196,93.7183533 40.9569664,92.3921814 35.1946983,92.3921814 		" />
                        <polygon className={'st5'} points="39.5524254,93.7183533 45.3146324,93.7183533 48.8350792,92.3921814 43.0728722,92.3921814 		" />
                        <polygon className={'st5'} points="27.6685162,94.4338684 21.9063091,94.4338684 18.3858624,95.7600403 24.1480694,95.7600403 		" />
                        <polygon className={'st5'} points="29.7844219,94.4338684 26.2639751,95.7600403 32.0262413,95.7600403 35.5466881,94.4338684 		" />
                        <polygon className={'st5'} points="34.1421471,95.7600403 39.9043541,95.7600403 43.4248009,94.4338684 37.6625938,94.4338684 		" />
                    </g>
                    <rect className={'st6'} width="109.6600037" height="109.6600037" />
                </g>
            </svg>
        );
    }
}