
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import makeGStyles from "../../../../../styles/makeGStyles";
import { TrackingStepIconDisplayLookup, TrackingStepIconLookup } from "./trackingStepIcon.list";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { values } from "lodash";
import
CustomizedDialogWithFooterButtons
    from "../../../../funeralHomes/funeralHomeSettings/CustomizedDialogWithFooterButtons";
import {
    isTrackingStepIcon,
    TaskTemplateCreateRequest,
    TaskTemplateSummary,
    TaskTemplateUpdateRequest,
    TaskType,
    TrackingStepIcon,
} from "../../../../../shared/types";
import StepComponentWarning from "../../../../workflows/editWorkFlow/editSteps/activeStep/common/StepComponentWarning";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import VisibilityIcon from "@mui/icons-material/Visibility";
import classNames from "classnames";

const useStyles = makeGStyles(theme => ({
    dialogContent: {
        padding: '20px 24px 0'
    },
    selectBoxContainer: {
        width: '90%',
        maxWidth: 320,
        margin: '28px auto 0',
        display: 'flex'
    },
    select: {
        maxHeight: 360
    },
    iconContainer: {
        padding: 12,
        color: theme.palette.secondary.main,
        borderRadius: '50%',
        border: '2px dashed',
        width: 'auto',
        '& svg': {
            fontSize: 80,
        }
    },
}), { name: 'SaveStepDialog' });

interface SaveStepType extends Pick<
    TaskTemplateSummary,
    'id' | 'title' | 'past_tense_title' | 'visible_to_family' | 'icon'> {
}

interface Props {
    zIndex: number;
    isOpen: boolean;
    existing?: SaveStepType;
    onCreate?: (createRequest: TaskTemplateCreateRequest) => void;
    onUpdate?: (UpdateRequest: TaskTemplateUpdateRequest) => void;
    closeDialog: () => void;
}

const SaveStepDialog = (props: Props) => {
    const {
        zIndex,
        isOpen,
        existing,
        onCreate,
        onUpdate,
        closeDialog,
    } = props;

    const classes = useStyles();

    const [selectedIcon, setSelectedIcon] =
        useState<TrackingStepIcon | null>(existing?.icon ? TrackingStepIcon[existing.icon] : null);
    const [actionName, setActionName] = useState(existing?.title || '');
    const [pastTenseName, setPastTenseName] = useState(existing?.past_tense_title || '');

    useEffect(() => {
        if (!isOpen) {
            setSelectedIcon(existing?.icon ? TrackingStepIcon[existing.icon] : null);
            setActionName(existing?.title || '');
            setPastTenseName(existing?.past_tense_title || '');
        }
    }, [isOpen, existing]);

    const handleChange = ({ target }: SelectChangeEvent<string | number>) =>
        isTrackingStepIcon(target.value) && setSelectedIcon(target.value);

    const handleSave = () => {
        if (existing) {
            const updateRequest: TaskTemplateUpdateRequest = {
                icon: selectedIcon,
                title: actionName,
                past_tense_title: pastTenseName,
                subtitle: null,
                description: null,
                is_after_care: false,
            };
            onUpdate?.(updateRequest);
        } else {
            const createRequest: TaskTemplateCreateRequest = {
                type: TaskType.tracking_step,
                icon: selectedIcon,
                title: actionName,
                past_tense_title: pastTenseName,
                subtitle: null,
                description: null,
                visible_to_family: false,
                is_after_care: false,
            };
            onCreate?.(createRequest);
        }
        closeDialog();
    };

    let icon = <AddAPhotoIcon />;
    const ActiveIcon = selectedIcon && TrackingStepIconLookup[selectedIcon];
    if (ActiveIcon) {
        icon = <ActiveIcon />;
    }

    return (
        <CustomizedDialogWithFooterButtons
            fullScreen
            headerTitle="ACTION Details"
            deleteBtnText="action"
            zIndex={zIndex}
            isDialogOpen={isOpen}
            handleSave={handleSave}
            handleClose={closeDialog}
        >
            <Grid container direction="column" alignItems="center" className={classes.dialogContent}>
                <StepComponentWarning
                    text="Please note that actions are different from locations. A WorkFlow should NOT include 
                    any movement steps but should only include the actions needed to complete the disposition. 
                    You can create locations separately and move each decedent to any of the 
                    locations at any point in the flow."
                    Icon={AirlineStopsIcon}
                />
                <StepComponentWarning
                    text={`Within a case, you can decide to show this action step to designated family helpers. 
                    If you decide to show this step, the "Past Tense of Action" is what will be displayed.`}
                    Icon={VisibilityIcon}
                    className={classNames(classes.marginTop12, classes.marginBottom20)}
                />

                <Grid container alignItems="center" justifyContent="center" className={classes.iconContainer}>
                    {icon}
                </Grid>

                <FormControl className={classes.width100}>
                    <TextField
                        required
                        fullWidth
                        label="Action Name"
                        value={actionName}
                        onChange={({ currentTarget }) => setActionName(currentTarget.value)}
                    />
                    <FormHelperText className={classes.margin_0}>
                        For example: Cremate, Obtain Fingerprints, etc. DO NOT ADD LOCATIONS HERE.
                    </FormHelperText>
                </FormControl>

                <FormControl className={classes.width100}>
                    <TextField
                        required
                        fullWidth
                        label="Past tense of Action"
                        value={pastTenseName}
                        onChange={({ currentTarget }) => setPastTenseName(currentTarget.value)}
                    />
                    <FormHelperText className={classes.margin_0}>
                        For example: Cremated, Obtained Fingerprints, etc.
                    </FormHelperText>
                </FormControl>

                <FormControl className={classes.selectBoxContainer}>
                    <InputLabel id="select-action-icon">Select Action Icon</InputLabel>
                    <Select
                        labelId="select-action-icon"
                        value={selectedIcon || ''}
                        variant="outlined"
                        fullWidth
                        MenuProps={{ sx: { zIndex }, className: classes.select }}
                        input={<OutlinedInput label="Select Action Icon" />}
                        onChange={handleChange}
                    >
                        {values(TrackingStepIcon).map(iconName => {
                            const Icon = TrackingStepIconLookup[iconName];

                            return (
                                <MenuItem key={iconName} value={iconName}>
                                    <Grid container alignItems="center">
                                        <Icon />&nbsp;
                                        <Typography component="span">
                                            {TrackingStepIconDisplayLookup[iconName]}
                                        </Typography>
                                    </Grid>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </CustomizedDialogWithFooterButtons>
    );
};

export default SaveStepDialog;
