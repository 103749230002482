import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import LockIcon from '@mui/icons-material/Lock';

import { GRAY_COLOR_3 } from '../../constants/colorVariables';
import FuneralHomeLogo from './FuneralHomeLogo';
import makeGStyles from '../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {
        display: 'block',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100vw',
        height: '100vh',
        position: 'relative',
    },
    topLoader: {
        display: 'table',
        margin: 18,
        float: 'right',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    fabProgress: {
        color: GRAY_COLOR_3,
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    lockIcon: {
        color: theme.palette.secondary.main,
        height: 18,
        width: 18,
        margin: 4,
    },
    loadingText: {
        '-webkit-font-smoothing': 'antialiased',
        fontWeight: 200,
        color: theme.palette.secondary.main,
        fontSize: 16,
        display: 'inline-block',
        marginRight: 12,
        whiteSpace: 'nowrap'
    },
    logoOuter: {
        display: 'table-cell',
        verticalAlign: 'middle',
        height: '100vh',
        textAlign: 'center',
        width: '100vw',
    },
}), { name: 'AppLoader' });

const AppLoader = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.topLoader}>
                <Typography component="p" className={classes.loadingText}>
                    Loading your secure data...
                </Typography>
                <div className={classes.wrapper}>
                    <LockIcon className={classes.lockIcon} />
                    <CircularProgress
                        size={40}
                        className={classes.fabProgress}
                    />
                </div>
            </div>

            <div className={classes.logoOuter}>
                <FuneralHomeLogo
                    logoSize="large"
                />
            </div>
        </div>
    );
};

export default AppLoader;
