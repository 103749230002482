import * as t from 'io-ts';

import {
    DocCategoryDefinition,
    DocUX,
    EntityRecord,
    EntityRelationship,
    EntityType,
    EntityTypeDefinition,
    PhotoTransformationsType,
    getValidator,
    GatherCaseRecord,
    CaseEntityRecord,
    FuneralHomeCaseRecord,
    FuneralHomeRecord,
} from '.';
import { longAddress, LongAddress } from './deathcertificate';
import { DocPDFStatus, DocPDFStatusDefinition } from './doc';

export enum OrganizationTypes {
    cemetery = 'cemetery',
    church = 'church',
    competitor = 'competitor',
    crematory = 'crematory',
    florist = 'florist',
    government_agency = 'government_agency',
    hospital = 'hospital',
    nursing_home = 'nursing_home',
    supplier = 'supplier',
    veterinary_clinic = 'veterinary_clinic',
    other = 'other'
}

export enum OrganizationDisplayTypes {
    cemetery = 'Cemetery',
    church = 'Church',
    competitor = 'Competitor',
    crematory = 'Crematory',
    florist = 'Florist',
    government_agency = 'Government Agency',
    hospital = 'Hospital',
    nursing_home = 'Nursing Home',
    supplier = 'Supplier',
    veterinary_clinic = 'Veterinary Clinic',
    other = 'Other'
}

export const OrganizationTypeDefinition = t.union([
    t.literal(OrganizationTypes.cemetery),
    t.literal(OrganizationTypes.church),
    t.literal(OrganizationTypes.competitor),
    t.literal(OrganizationTypes.crematory),
    t.literal(OrganizationTypes.florist),
    t.literal(OrganizationTypes.government_agency),
    t.literal(OrganizationTypes.hospital),
    t.literal(OrganizationTypes.nursing_home),
    t.literal(OrganizationTypes.supplier),
    t.literal(OrganizationTypes.veterinary_clinic),
    t.literal(OrganizationTypes.other)
]);

export interface OrganizationRecord {
    id: number;
    name: string | null;
    type: OrganizationTypes | null;
    other_text: string | null;
    address_id: number | null;
    email: string | null;
    phone: string | null;
    fax_number: string | null;
    notes: string | null;
    created_by: number;
    created_time: Date;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
    funeral_home_id: number;
}

export interface RolodexOrganization extends OrganizationRecord {
    longAddress: LongAddress | null;
    timezone: string | null;
    useAddressDescription: boolean;
    contactCount: number;
    docs: RolodexDoc[];
}

export interface RolodexContact extends EntityRecord {
    longAddress: LongAddress | null;
    timezone: string | null;
    useAddressDescription: boolean;
}

export type Contact = Partial<Omit<RolodexContact, 'fname' | 'lname'>> & {
    fname: string;
    lname: string;
};

export interface RolodexBaseEntry {
    contacts: RolodexContact[];
    funeralHomeId: number;
}

export interface RolodexEntry extends RolodexBaseEntry {
    organization: RolodexOrganization;
}

export function isRolodexEntry(obj: object): obj is RolodexEntry {
    const entry = obj as RolodexEntry;
    return (
        Array.isArray(entry.contacts) && entry.organization !== undefined
    );
}

const RolodexContactRequiredType = t.type({
    fname: t.string,
    mname: t.union([t.string, t.null]),
    lname: t.string,
    email: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    longAddress: t.union([longAddress, t.null]),
    fax_number: t.union([t.string, t.null]),
    org_role: t.union([t.string, t.null]),
    timezone: t.union([t.string, t.null]),
    title: t.union([t.string, t.null]),
    type: EntityTypeDefinition,
    useAddressDescription: t.boolean
});

export interface RolodexContactRequiredType extends t.TypeOf<typeof RolodexContactRequiredType> { }

const RolodexDocInsertType = t.type({
    uploaded_by: t.number,
    uploaded_time: t.string,
    parent_doc_id: t.union([t.number, t.null]),
    name: t.string,
    label: t.union([t.string, t.null]),
    hash: t.string,
    size: t.union([t.number, t.null]),
    is_private: t.boolean,
    path: t.string,
    suffix: t.string,
    mimetype: t.string,
    icon: t.union([t.string, t.undefined, t.null]),
    doc_category: DocCategoryDefinition,
    pdf_status: DocPDFStatusDefinition,
});
export interface RolodexDocInsert extends t.TypeOf<typeof RolodexDocInsertType> {
}

export const isRolodexDocInsert = (doc: object): doc is RolodexDocInsert => {
    const docInsert = doc as RolodexDocInsert;
    return !docInsert.hasOwnProperty('id');
};

const RolodexOrganizationRequiredType = t.type({
    name: t.string,
    type: t.union([OrganizationTypeDefinition, t.null]),
    other_text: t.union([t.string, t.null]),
    longAddress: t.union([longAddress, t.null]),
    timezone: t.union([t.string, t.null]),
    email: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    fax_number: t.union([t.string, t.null]),
    notes: t.union([t.string, t.null]),
    funeral_home_id: t.number,
    docs: t.array(RolodexDocInsertType),
    useAddressDescription: t.boolean
});

export interface RolodexOrganizationRequiredType extends t.TypeOf<typeof RolodexOrganizationRequiredType> {
    type: OrganizationTypes | null;
    longAddress: LongAddress | null;
    docs: RolodexDocInsert[];
}

const RolodexEntryRequestType = t.type({
    contacts: t.array(RolodexContactRequiredType),
    organization: t.union([RolodexOrganizationRequiredType, t.null]),
    funeralHomeId: t.number
});

export interface RolodexEntryRequest extends t.TypeOf<typeof RolodexEntryRequestType> {
    contacts: RolodexContactRequiredType[];
    organization: RolodexOrganizationRequiredType | null;
}

export class RolodexEntryRequest {
    public static fromRequest = getValidator<RolodexEntryRequest>(RolodexEntryRequestType, {
        mapperFn: RolodexEntryRequest.normalizeRolodexEntry,
    });

    private static normalizeRolodexEntry(entry: RolodexEntryRequest): RolodexEntryRequest {
        if (entry.organization !== null) {
            const { organization } = entry;
            organization.name = organization.name.trim();
            organization.email = organization.email && organization.email.trim().toLowerCase();
            organization.phone = organization.phone && organization.phone.replace(/[^0-9]/g, '');
            organization.fax_number = organization.fax_number && organization.fax_number.replace(/[^0-9]/g, '');
            organization.notes = organization.notes && organization.notes.trim();
        }

        for (const contact of entry.contacts) {
            contact.fname = contact.fname.trim();
            contact.mname = contact.mname && contact.mname.trim();
            contact.lname = contact.lname.trim();
            contact.email = contact.email && contact.email.trim().toLowerCase();
            contact.phone = contact.phone && contact.phone.replace(/[^0-9]/g, '');
            contact.fax_number = contact.fax_number && contact.fax_number.replace(/[^0-9]/g, '');
        }

        return entry;
    }
}

const RolodexDocType = t.type({
    id: t.number,
    uploaded_time: t.string,
    deleted_by: t.union([t.number, t.null]),
    deleted_time: t.union([t.string, t.null]),
    default_icon: t.union([t.string, t.null]),
    uploaded_by: t.number,
    parent_doc_id: t.union([t.number, t.null]),
    name: t.string,
    label: t.union([t.string, t.null]),
    hash: t.string,
    size: t.union([t.number, t.null]),
    is_private: t.boolean,
    path: t.string,
    suffix: t.string,
    mimetype: t.string,
    icon: t.union([t.string, t.undefined, t.null]),
    doc_category: DocCategoryDefinition,
    pdf_status: DocPDFStatusDefinition,
    uploaded_by_fname: t.union([t.string, t.null]),
    uploaded_by_lname: t.union([t.string, t.null])
});
export interface RolodexDoc extends t.TypeOf<typeof RolodexDocType> { }

export const isRolodexDoc = (doc: object): doc is RolodexDoc => {
    const rolodexDoc = doc as RolodexDoc;
    return typeof rolodexDoc.id === 'number';
};

export const docUXToRolodexDoc = (doc: DocUX): RolodexDoc => ({
    id: doc.id,
    uploaded_time: doc.uploaded_time.toISOString(),
    uploaded_by: doc.uploaded_by,
    parent_doc_id: doc.parent_doc_id,
    name: doc.name,
    label: doc.label,
    hash: doc.hash,
    size: doc.size,
    is_private: doc.is_private,
    path: doc.path,
    suffix: doc.suffix,
    mimetype: doc.mimetype,
    icon: doc.icon,
    doc_category: doc.doc_category,
    pdf_status: doc.pdf_status || DocPDFStatus.none,
    deleted_time: (doc.deleted_time && doc.deleted_time.toISOString()) || null,
    deleted_by: doc.deleted_by,
    default_icon: doc.default_icon,
    uploaded_by_fname: doc.uploaded_by_fname,
    uploaded_by_lname: doc.uploaded_by_lname
});

type DocGetResult = [(RolodexDocInsert | RolodexDoc), (RolodexDocInsert | RolodexDoc)[]];
export const getDocFromDocList = (
    docIdentifier: string | number,
    existingDocs: RolodexDoc[],
    newDocs: RolodexDocInsert[]
): DocGetResult | null => {
    if (typeof docIdentifier === 'string') {
        const newDoc = newDocs.find(d => d.name === docIdentifier);
        if (newDoc) {
            return [newDoc, newDocs];
        }
    }

    // can assume that docIdentifier is a number
    const existingDoc = existingDocs.find(d => d.id === docIdentifier);
    if (existingDoc) {
        return [existingDoc, existingDocs];
    }

    return null;
};

const RolodexOrganizationUpdateType = t.intersection([
    RolodexOrganizationRequiredType,
    t.type({
        id: t.number,
        address_id: t.union([t.number, t.null]),
        created_by: t.number,
        newDocs: t.array(RolodexDocInsertType),
        docs: t.array(RolodexDocType)
    })
]);

export interface RolodexOrganizationUpdate extends t.TypeOf<typeof RolodexOrganizationUpdateType> {
    newDocs: RolodexDocInsert[];
    docs: RolodexDoc[];
}

const RolodexContactUpdateType = t.intersection([
    RolodexContactRequiredType,
    t.type({
        id: t.number,
        home_address_id: t.union([t.number, t.null]),
        organization_id: t.number
    })
]);

export interface RolodexContactUpdateType extends t.TypeOf<typeof RolodexContactUpdateType> {
    id: number;
}

const RolodexEntryUpdateType = t.type({
    contacts: t.union([
        t.array(RolodexContactUpdateType),
        t.array(RolodexContactRequiredType)
    ]),
    organization: RolodexOrganizationUpdateType,
    funeral_home_id: t.number
});

export interface RolodexEntryUpdateRequest extends t.TypeOf<typeof RolodexEntryUpdateType> {
    contacts: RolodexContactUpdateType[] | RolodexContactRequiredType[];
    organization: RolodexOrganizationUpdate;
}

export class RolodexEntryUpdateRequest {
    public static fromRequest = getValidator<RolodexEntryUpdateRequest>(RolodexEntryUpdateType, {
        mapperFn: RolodexEntryUpdateRequest.normalizeRolodexEntry
    });

    private static normalizeRolodexEntry(entry: RolodexEntryUpdateRequest): RolodexEntryUpdateRequest {
        if (entry.organization !== null) {
            const { organization } = entry;
            organization.name = organization.name.trim();
            organization.email = organization.email && organization.email.trim().toLowerCase();
            organization.phone = organization.phone && organization.phone.replace(/[^0-9]/g, '');
            organization.fax_number = organization.fax_number && organization.fax_number.replace(/[^0-9]/g, '');
            organization.notes = organization.notes && organization.notes.trim();
        }

        for (const contact of entry.contacts) {
            contact.fname = contact.fname.trim();
            contact.mname = contact.mname && contact.mname.trim();
            contact.lname = contact.lname.trim();
            contact.email = contact.email && contact.email.trim().toLowerCase();
            contact.phone = contact.phone && contact.phone.replace(/[^0-9]/g, '');
            contact.fax_number = contact.fax_number && contact.fax_number.replace(/[^0-9]/g, '');
        }

        return entry;
    }
}

type UpdateOrCreateContact = RolodexContactUpdateType | RolodexContactRequiredType;
export const isRolodexContactUpdate = (obj: UpdateOrCreateContact): obj is RolodexContactUpdateType => (
    (obj as RolodexContactUpdateType).id !== undefined
);

export const isRolodexContactRequiredType = (obj: UpdateOrCreateContact): obj is RolodexContactRequiredType => (
    !(obj as RolodexContactRequiredType).hasOwnProperty('id')
);

export enum RolodexSearchResultType {
    'helper' = 'helper',
    'case' = 'case',
    'org' = 'org',
    'contact' = 'contact'
}

export interface RolodexHelperResult {
    type: RolodexSearchResultType.helper;
    id: number;
    caseName: string;
    displayName: string;
    relationship: string | null;
    role: string | null;
    caseId: number;
    caseUuid: string;
}

export interface RolodexCaseResult {
    type: RolodexSearchResultType.case;
    id: number;
    caseUuid: string;
    createdTime: GatherCaseRecord['created_time'];
    displayName: string | null;
    helperCount: number | null;
    fname: string | null;
    lname: string | null;
    funeralHomeName: string;
}

export interface RolodexOrgResult {
    type: RolodexSearchResultType.org;
    id: number;
    name: string | null;
    contactCount: number | null;
    address: string | null;
    orgType: OrganizationTypes | null;
    otherText: string | null;
}

export interface RolodexContactResult {
    type: RolodexSearchResultType.contact;
    id: number;
    orgName: string | null;
    name: string | null;
    role: string | null;
    title: string | null;
    orgId: number | null;
}

export type RolodexSearchResultDataTypes =
    RolodexHelperResult
    | RolodexCaseResult
    | RolodexOrgResult
    | RolodexContactResult;

interface RolodexSearchResultHelperRecord {
    type: RolodexSearchResultType.helper;
    id: EntityRecord['id'];
    case_name: GatherCaseRecord['display_full_name'];
    display_name: string;
    relationship: string;
    case_role: CaseEntityRecord['case_role'];
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
}

interface RolodexSearchResultCaseRecord {
    type: RolodexSearchResultType.case;
    id: GatherCaseRecord['id'];
    fname: GatherCaseRecord['fname'];
    lname: GatherCaseRecord['lname'];
    created_time: GatherCaseRecord['created_time'];
    display_name: string;
    entity_count: number;
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
}

interface RolodexSearchResultOrgRecord {
    type: RolodexSearchResultType.org;
    id: OrganizationRecord['id'];
    org_name: OrganizationRecord['name'];
    entity_count: number;
    address: string | null;
    org_type: OrganizationRecord['type'];
    org_other_text: OrganizationRecord['other_text'];
}

interface RolodexSearchResultContactRecord {
    type: RolodexSearchResultType.contact;
    id: EntityRecord['id'];
    org_name: OrganizationRecord['name'];
    display_name: string;
    org_role: EntityRecord['org_role'];
    title: EntityRecord['title'];
    org_id: OrganizationRecord['id'];
}

export type RolodexSearchResultRecord = RolodexSearchResultHelperRecord
    | RolodexSearchResultCaseRecord
    | RolodexSearchResultOrgRecord
    | RolodexSearchResultContactRecord
    ;

export interface RolodexHelperDetails {
    fname: string;
    lname: string;
    fullName: string;
    relationship: EntityRelationship | null;
    deceased: boolean;
    email: string | null;
    phone: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    photo_id: number | null;
    location: LongAddress | null;
}

export interface RolodexCaseDetails {
    id: number;
    name: string;
    case_number: string | null;
    createdDate: Date;
    createdByName: string;
    dobDate: string | null;
    dodStartDate: string | null;
    dodEndDate: string | null;
    dodStartTime: string;
    displayName: string;
    fname: string;
    lname: string;
    photoId: number | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    helpers: RolodexHelperDetails[];
    funeralHomeKey: string;
    funeralHomeName: string;
}

export function isRolodexCaseDetails(obj: object): obj is RolodexCaseDetails {
    const caseDetails = obj as RolodexCaseDetails;
    return (
        Array.isArray(caseDetails.helpers) && caseDetails.dobDate !== undefined
    );
}

export function isSearchResultObj<T extends RolodexSearchResultDataTypes>(
    resultObj: object, searchResultTypeKey: keyof typeof RolodexSearchResultType
): resultObj is T {
    return (resultObj as T).type === RolodexSearchResultType[searchResultTypeKey];
}

export type RolodexSearchResultCategoryFilter = {
    key: OrganizationTypes;
    count: number;
};

export const contactToCreateContactObj = (contact: Contact): RolodexContactRequiredType => ({
    fname: contact.fname,
    mname: contact.mname || null,
    lname: contact.lname,
    email: contact.email || null,
    phone: contact.phone || null,
    longAddress: contact.longAddress || null,
    fax_number: contact.fax_number || null,
    org_role: contact.org_role || null,
    timezone: contact.timezone || null,
    title: contact.title || null,
    type: EntityType.person,
    useAddressDescription: contact.useAddressDescription || false
});

export type ExistingContact = Contact & {
    id: number;
    organization_id: number;
    home_address_id: number | null;
};
export const isExistingContact = (obj: object): obj is ExistingContact => {
    const existingContact = obj as ExistingContact;
    return (
        Boolean(existingContact.id && existingContact.organization_id && existingContact.home_address_id !== undefined)
    );
};

export type Organization = Partial<Omit<RolodexOrganization, 'name'>> & { name: string };
export type ExistingOrganization = Organization & {
    id: number;
    funeral_home_id: number;
    created_by: number;
};
export const isExistingOrganization = (obj: object): obj is ExistingOrganization => {
    const existingOrg = obj as ExistingOrganization;
    const createdByExists = existingOrg.created_by !== null && typeof existingOrg.created_by === 'number';
    return (
        Boolean(existingOrg.id && existingOrg.funeral_home_id && createdByExists)
    );
};

export function rolodexRecordToUpdateRecord(
    organization: ExistingOrganization,
    contactList: Array<ExistingContact | Contact>,
    docList: RolodexDocInsert[],
    funeralHomeId: number
): RolodexEntryUpdateRequest {
    const newContactsCreateObjs = contactList
        .filter(contact => !isExistingContact(contact))
        .map(contactToCreateContactObj);

    const updatedContactObjs: RolodexContactUpdateType[] = contactList.filter(isExistingContact).map(
        contact => ({
            id: contact.id,
            fname: contact.fname,
            mname: contact.mname || null,
            lname: contact.lname,
            email: contact.email || null,
            phone: contact.phone || null,
            longAddress: contact.longAddress || null,
            fax_number: contact.fax_number || null,
            org_role: contact.org_role || null,
            timezone: contact.timezone || null,
            title: contact.title || null,
            type: EntityType.person,
            state_license_number: null,
            home_address: contact.longAddress || null,
            home_address_id: contact.home_address_id || null,
            organization_id: contact.organization_id,
            use_address_description: false,
            funeral_home_settings: null,
            useAddressDescription: contact.useAddressDescription || false,
            role: ''
        })
    );

    const updatedOrg: RolodexOrganizationUpdate = {
        id: organization.id,
        address_id: organization.address_id || null,
        created_by: organization.created_by,
        name: organization.name,
        type: organization.type || null,
        other_text: organization.other_text || null,
        longAddress: organization.longAddress || null,
        timezone: organization.timezone || null,
        email: organization.email || null,
        phone: organization.phone || null,
        fax_number: organization.fax_number || null,
        notes: organization.notes || null,
        funeral_home_id: funeralHomeId,
        docs: organization.docs || [],
        newDocs: docList,
        useAddressDescription: organization.useAddressDescription || false
    };

    return {
        organization: updatedOrg,
        contacts: [...newContactsCreateObjs, ...updatedContactObjs],
        funeral_home_id: updatedOrg.funeral_home_id
    };
}

export interface RolodexTableQueryResults {
    entrytype: 'contact' | 'organization';
    id: number;
    name: string;
    address: string | null;
    type: OrganizationTypes | string | null;
    email: string | null;
    phone: string | null;
    fax: string | null;
}

export const isKeyOfRolodexTableQueryResults = (key: string):
    key is keyof RolodexTableQueryResults =>
(
    key === 'entrytype' || key === 'id' || key === 'name' || key === 'address'
    || key === 'type' || key === 'email' || key === 'phone' || key === 'fax'
);

export interface RolodexTableType {
    id: number;
    entryType: 'Organization' | 'Contact';
    name: string | null;
    phone: string | null;
    fax: string | null;
    address: string | null;
    type: OrganizationTypes | string | null;
    email: string | null;
}

export const isRolodexTableType = (obj: object): obj is RolodexTableType => {
    const row = obj as RolodexTableType;
    return Boolean(row.entryType) &&
        (row.entryType === 'Organization' || row.entryType === 'Contact') && Boolean(row.id);
};
