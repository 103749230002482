import { Grid, Typography } from '@mui/material';
import GButton from '../../../../common/GButton';

interface BulkEditDialogFooterProps {
    selectedProductsCount: number;
    onSubmitClick: () => Promise<void>;
    disableSubmitButton: boolean;
    isSpinning: boolean;
}

const BulkEditDialogFooter = (props: BulkEditDialogFooterProps) => {
    const {
        selectedProductsCount,
        onSubmitClick,
        disableSubmitButton,
        isSpinning,
    } = props;


    const onClick = async () => {
        await onSubmitClick();
    };

    return (
        <Grid textAlign="center" mt={2.5}>
            <GButton
                disabled={disableSubmitButton}
                text="Submit bulk edit"
                onClick={onClick}
                isSpinning={isSpinning}
            />
            <Typography color="secondary" fontSize={10} pt={0.5}>
                This will affect {selectedProductsCount} product{selectedProductsCount === 1 ? '' : 's'}{' '}
                immediately.
            </Typography>
        </Grid>
    );
};

export default BulkEditDialogFooter;