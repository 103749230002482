import {
    WhiteBoardReorderCasesRequest,
    WhiteboardRequest
} from '../shared/types';
import { AppDispatch } from '../store';
import { getFromAPI, postToAPI } from './index';
import { registerAppError } from './errors';
import { log } from '../logger';

export const WHITEBOARD_LOADING = 'WHITEBOARD_LOADING';
export type WHITEBOARD_LOADING = typeof WHITEBOARD_LOADING;

interface WhiteboardLoading {
    type: WHITEBOARD_LOADING;
}

function whiteboardLoading(): WhiteboardLoading {
    return {
        type: WHITEBOARD_LOADING,
    };
}

export const WHITEBOARD_LOADED = 'WHITEBOARD_LOADED';
export type WHITEBOARD_LOADED = typeof WHITEBOARD_LOADED;

interface WhiteboardLoaded {
    type: WHITEBOARD_LOADED;
    whiteboard: WhiteboardRequest;
}

function whiteboardLoaded(whiteboard: WhiteboardRequest): WhiteboardLoaded {
    return {
        type: WHITEBOARD_LOADED,
        whiteboard
    };
}

export const WHITEBOARD_CASES_REORDERED = 'WHITEBOARD_CASES_REORDERED';
export type WHITEBOARD_CASES_REORDERED = typeof WHITEBOARD_CASES_REORDERED;

interface WhiteboardCasesReordered {
    type: WHITEBOARD_CASES_REORDERED;
    funeralHomeId: number;
    caseUuidList: string[];
}

export const UPDATED_NOTE_COUNT = 'UPDATED_NOTE_COUNT';

export interface UpdateNoteCount {
    type: typeof UPDATED_NOTE_COUNT;
    caseUuid: string;
    notes_count_delta: number;
}

export function updateNoteCount(caseUuid: string, notes_count_delta: number): UpdateNoteCount {
    return {
        type: UPDATED_NOTE_COUNT,
        caseUuid,
        notes_count_delta,
    };
}

function whiteboardCasesReordered(funeralHomeId: number, caseUuidList: string[]): WhiteboardCasesReordered {
    return {
        type: WHITEBOARD_CASES_REORDERED,
        funeralHomeId,
        caseUuidList,
    };
}

export function loadWhiteboardForFuneralHome(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(whiteboardLoading());
        const result = await getFromAPI<WhiteboardRequest>(`funeralhome/${funeralHomeId}/whiteboard`, dispatch);
        if (!result) {
            dispatch(registerAppError(`Problem loading whiteboard for funeral home`));
            return null;
        }

        dispatch(whiteboardLoaded(result));

        return result;
    };
}

export function reorderWhiteboardCases(funeralHomeId: number, request: WhiteBoardReorderCasesRequest) {
    return async (dispatch: AppDispatch) => {
        try {
            WhiteBoardReorderCasesRequest.fromRequest(request);
        } catch (ex) {
            log.warn('Failed to validate update WhiteBoardReorderCasesRequest', { request, ex });
            return;
        }
        dispatch(whiteboardCasesReordered(funeralHomeId, request.caseUuidList));
        await postToAPI<void>(`funeralhome/${funeralHomeId}/whiteboard/rank`, request, dispatch);
    };
}

export type WhiteboardAction =
    | WhiteboardLoading
    | WhiteboardLoaded
    | WhiteboardCasesReordered
    | UpdateNoteCount;