import * as React from 'react';

import Grid from '@mui/material/Grid';

import { MemoryAuthor, ModerationMemoryUX, ModerationStatus } from '../../../../shared/types';
import { styles, StyledProps } from '../ModerationContent.styles';
import ModerationMemory from './ModerationMemory';
import withGStyles from '../../../../styles/WithGStyles';

interface Props {
    memories: ModerationMemoryUX[];
    moderationStatus: ModerationStatus;
    isRememberPage: boolean;
    showIndividualCards: boolean;
    openMenu: (event: React.MouseEvent<HTMLElement>, memory: ModerationMemoryUX) => void;
    onDecision: (memories: ModerationMemoryUX[], isApproval: boolean) => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, author: MemoryAuthor) => void;
}

const MemoriesSection = (props: Props & StyledProps) => {
    const {
        memories,
        classes,
        moderationStatus,
        isRememberPage,
        showIndividualCards,
        openMenu,
        onDecision,
        openGuestPopper
    } = props;
    // Sort memories by case_id and memory.id
    const sortedMemories = memories.sort((a, b) => a.case_id === b.case_id
        ? a.memory.id - b.memory.id
        : a.case_id - b.case_id
    );

    let lastCase: number | null = null;
    let showHeader: boolean = false;
    return (
        <Grid item xs={12} className={classes.memoryContainer}>

            {sortedMemories.map((modMemory, i) => {
                if (lastCase !== modMemory.case_id) {
                    lastCase = modMemory.case_id;
                    showHeader = showIndividualCards;
                } else {
                    showHeader = false;
                }

                return <ModerationMemory
                    key={modMemory.memory.id}
                    moderationMemory={modMemory}
                    isLastMemory={i === (memories.length - 1)}
                    isPending={moderationStatus === ModerationStatus.pending}
                    openMenu={openMenu}
                    classes={classes}
                    onApprove={() => onDecision([modMemory], true)}
                    onBlock={() => onDecision([modMemory], false)}
                    openGuestPopper={openGuestPopper}
                    isRememberPage={isRememberPage}
                    showHeader={showHeader}
                />;
            })}

        </Grid>
    );
};

export default withGStyles(styles<Props>())(MemoriesSection);
