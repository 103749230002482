import { styled } from "@mui/material/styles";
import {
    emptyStringParser,
    joinNameParts,
    getAddressFromLongAddress,
    getFormattedPhoneNumber
} from "../../../../../services";
import {
    CaseTaskUX,
    EntitySummary,
    GatherCaseUX,
    getCaseEntityById,
    getDisplayRelationshipForPerson
} from "../../../../../shared/types";
import { OverviewSectionCommonProps } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

const EmailContainer = styled('span')({
    '& span': {
        textTransform: 'lowercase'
    },
});

interface Props extends Omit<OverviewSectionCommonProps, 'deathCertificate'> {
    activeCase: GatherCaseUX;
    helpers: EntitySummary[];
    dcTask: CaseTaskUX | null;
}
const ApprovalOverview = (props: Props) => {
    const { activeCase, helpers, dcTask, divider, showHeading, printMode } = props;
    const { dc_informant } = activeCase;

    const selectedInformant: EntitySummary | null = dc_informant &&
        helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_informant))) || null;
    const informantCaseEntity = selectedInformant && dc_informant &&
        getCaseEntityById(selectedInformant, dc_informant);

    const informant: string | null = !selectedInformant ? null :
        emptyStringParser(joinNameParts(selectedInformant));
    const relation: string | null = !informantCaseEntity ? null :
        emptyStringParser(getDisplayRelationshipForPerson(informantCaseEntity));
    const address: string | null = selectedInformant && selectedInformant.home_address &&
        getAddressFromLongAddress(selectedInformant.home_address);
    const phoneNumber: string | null = selectedInformant ? emptyStringParser(selectedInformant.phone) : null;
    const email: string | null = selectedInformant ? emptyStringParser(selectedInformant.email) : null;

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    APPROVAL
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Informant's Name"
                    html={informant}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Relationship"
                    html={relation}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Address"
                    html={address}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Informant's Phone Number"
                    html={getFormattedPhoneNumber(phoneNumber)}
                    fieldKey={undefined}
                />

                <EmailContainer>
                    <OverviewLabel
                        label="Email"
                        html={email}
                        fieldKey={undefined}
                    />
                </EmailContainer>
            </SectionTypography>

            {dcTask && divider && <OverviewDivider />}
        </div>
    );
};

export default ApprovalOverview;