
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { noop } from 'lodash';
import styled from '@mui/system/styled';

const Container = styled('div')({
    height: '100%',
    with: '100%',
    textAlign: 'center',
    paddingTop: '35px'
});

const Text = styled(Typography)({
    textTransform: 'uppercase',
    color: 'white',
    fontSize: '24px',
    fontWeight: 300
});
interface Props {
    isLit: boolean;
    isSaved: boolean;
    candleOnClick?: () => void;
}

function CandlePrompt({ isSaved, isLit, candleOnClick }: Props) {
    const onClick = isLit ? noop : candleOnClick;

    return (
        <Fade in={!isSaved} timeout={300} mountOnEnter unmountOnExit>
            <Container sx={{ cursor: !isLit ? 'pointer' : undefined }} onClick={onClick}>
                {!isLit && <Text>Light a Candle</Text>}
            </Container>
        </Fade>
    );
}

export default CandlePrompt;
