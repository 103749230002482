import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import WifiOff from '@mui/icons-material/WifiOff';
import { RED_COLOR } from '../../constants/colorVariables';
import { useGDispatch, useGSelector } from '../../types';
import { internetStatusChanged } from '../../actions/App';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        backgroundColor: RED_COLOR,
        cursor: 'pointer',
    },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing(),
    },
}), { name: 'NoInternetSnackbar' });

const NoInternetSnackbar = () => {

    const classes = useStyles();

    const userHasInternet = useGSelector(({ appState }) => appState.hasInternet);

    const dispatch = useGDispatch();

    const handleClick = () => dispatch(internetStatusChanged({ hasInternet: true }));

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={!userHasInternet}
            onClick={handleClick}
        >
            <SnackbarContent
                className={classes.content}
                aria-describedby="no-internet-snackbar"
                message={(
                    <span id="no-internet-snackbar" className={classes.root}>
                        <WifiOff className={classes.icon} />
                        <span><b>No Internet connection</b></span>
                        <span>&nbsp;Make sure Wi-Fi or cellular data is turned on, then try again.</span>
                    </span>
                )}
            />
        </Snackbar>
    );
};

export default NoInternetSnackbar;
