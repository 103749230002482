import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import ClearAllIcon from "@mui/icons-material/ClearAll";

import PhotoHanger from "../PhotoHanger";
import { ORANGE_COLOR } from "../../../../../constants/colorVariables";
import { useMemo } from "react";

type StylesKey = 'reviewPhotosContainer' | 'reviewPhotosDiv' | 'pendingText' | 'pendingNumbers'
    | 'reviewPhotosInnerDiv' | 'reviewPhotosContent' | 'reviewAllButton';
const styles = (props: Props): Record<StylesKey, SxProps<Theme>> => ({
    reviewPhotosContainer: theme => ({
        margin: '0 40px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center',
        [theme.breakpoints.down(768)]: {
            transform: 'translateY(16%)',
        },
        [theme.breakpoints.down(960)]: {
            transform: props.totalPhotosCount ? 'translateY(6%) !important' : undefined,

        }
    }),
    reviewPhotosDiv: {
        boxSizing: 'initial',
        position: 'absolute',
        top: 214,
        backgroundColor: ORANGE_COLOR,
        height: 150,
        width: 160,
        padding: '10px',
        borderRadius: '8px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0 2px 8px 2px rgb(0 0 0 / 35%), -60px 44px 64px rgb(0 0 0 / 15%), 0 4px 44px rgb(0 0 0 / 25%)',
    },
    pendingText: theme => ({
        color: theme.palette.common.white,
        fontSize: 18,
        lineHeight: '24px',
    }),
    pendingNumbers: {
        fontWeight: 300,
        fontSize: 48,
        lineHeight: '36px',
        marginBottom: '4px',
    },
    reviewPhotosInnerDiv: theme => ({
        textAlign: 'center',
        padding: '0px 16px',
        '& p': {
            padding: '0px',
            color: theme.palette.common.white,
        }
    }),
    reviewPhotosContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '8px 0px',
    },
    reviewAllButton: theme => ({
        color: ORANGE_COLOR,
        background: theme.palette.common.white,
        width: 'fit-content',
        margin: '4px auto 0px',
        boxShadow: 'none !important',
        ':hover': {
            backgroundColor: theme.palette.common.white,
        },
    }),
});

interface Props {
    visitorsCount: number;
    photosCount: number;
    totalPhotosCount: number;
    onReview: () => void;
}

const ReviewPhotos = (props: Props) => {
    const { visitorsCount, photosCount, onReview } = props;

    const sxProps = useMemo(() => styles(props), [props]);

    return (
        <Grid
            item
            sx={sxProps.reviewPhotosContainer}
        >
            <PhotoHanger stringHeight={196} />

            <Grid item sx={sxProps.reviewPhotosDiv}>
                <Typography sx={sxProps.pendingText}>Pending</Typography>

                <Grid item sx={sxProps.reviewPhotosContent}>
                    <Grid
                        item
                        sx={sxProps.reviewPhotosInnerDiv}
                        borderRight={theme => `1px solid ${theme.palette.common.white}`}
                    >
                        <Typography sx={sxProps.pendingNumbers}>
                            {visitorsCount}
                        </Typography>

                        <Typography sx={sxProps.pendingText}>Visitors</Typography>
                    </Grid>

                    <Grid item sx={sxProps.reviewPhotosInnerDiv}>
                        <Typography sx={sxProps.pendingNumbers}>
                            {photosCount}
                        </Typography>

                        <Typography sx={sxProps.pendingText}>Photos</Typography>
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    sx={sxProps.reviewAllButton}
                    onClick={onReview}
                >
                    <ClearAllIcon /> &nbsp;Review All
                </Button>
            </Grid>
        </Grid>
    );
};

export default ReviewPhotos;