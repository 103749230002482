import { alpha, Grid, Theme, Typography } from '@mui/material';
import { Homesteaders, InsurancePolicyUX, mapPolicyStatusFromString } from '../../../shared/types';
import PolicyContentItem from './PolicyContentItem';
import { formatPrice } from '../../../shared/goods_and_services/pricing';
import makeStyles from '@mui/styles/makeStyles';
import groupBy from 'lodash/groupBy';

const useStyles = makeStyles(
    (theme: Theme) => ({
        title: {
            fontSize: 12,
            padding: '2px 7px',
        },
        borderBottom: {
            borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
        },
    }),
    { name: 'PolicyAggregateContent' },
);

interface Props {
    policies: InsurancePolicyUX[];
    insuranceColor: string;
}

const PolicyAggregateContent = (props: Props) => {
    const { policies, insuranceColor } = props;

    const classes = useStyles();

    if (!policies.length) {
        return null;
    }

    const aggregateFaceValue = policies.reduce(
        (accumulator: number, currentValue: InsurancePolicyUX) =>
            accumulator + parseFloat(currentValue.face_value) * 100.0,
        0,
    );
    const aggregateArrangementValue = policies.reduce(
        (accumulator: number, currentValue: InsurancePolicyUX) =>
            accumulator + parseFloat(currentValue.arrangement_total) * 100.0,
        0,
    );
    const policyNumbers = policies.map((p) => p.policy_number).join('; ');

    let status: string;
    const policiesGroupedByStatus = groupBy(policies, (p) => p.status);
    if (Object.keys(policiesGroupedByStatus).length === 1) {
        status = `All policies ${Homesteaders.PolicyStatusLookup[policies[0].status]}`;
    } else {
        status = Object.entries(policiesGroupedByStatus)
            .map(
                ([key, val]) =>
                    `${val.length} ${Homesteaders.PolicyStatusLookup[mapPolicyStatusFromString(key)]}`,
            )
            .join(', ');
    }

    return (
        <Grid container>
            <Grid item width={1}>
                <Typography
                    className={classes.title}
                    sx={{
                        color: insuranceColor,
                        bgcolor: `${alpha(insuranceColor, 0.1)}`,
                        borderBottom: `1px solid ${alpha(insuranceColor, 0.1)} !important`,
                    }}
                >
                    Aggregate Coverage Information
                </Typography>
            </Grid>
            <Grid item width={1}>
                <PolicyContentItem
                    title="Aggregate Face Value"
                    description={formatPrice(aggregateFaceValue, 'USD')}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title="Aggregate Arrangement Total"
                    description={formatPrice(aggregateArrangementValue, 'USD')}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem title="Policies" description={policyNumbers} titleColor={insuranceColor} />
                <PolicyContentItem title="Status" description={status} titleColor={insuranceColor} />
                <PolicyContentItem
                    title="Funeral Home"
                    description={policies[0].funeral_home_name}
                    titleColor={insuranceColor}
                />
                <PolicyContentItem
                    title="Funeral Home Address"
                    description={`${policies[0].funeral_home.addressLine1}
                        ${policies[0].funeral_home.addressLine2}
                        ${policies[0].funeral_home.city}, ${policies[0].funeral_home.state}
                        ${policies[0].funeral_home.zip}`}
                    borderClass={classes.borderBottom}
                    titleColor={insuranceColor}
                />
                <Typography color="secondary" p={2}>
                    The Aggregate Coverage Information includes the aggregate data of all of the policies that were
                    matched with this case, regardless of if they are attached or unattached.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PolicyAggregateContent;
