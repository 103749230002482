import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { useTaskDialogStyles } from '../TaskDialogsStyles';
import { DATA_PRIVATE } from '../../../../styles/Helper.styles';

import { GatherCaseUX } from '../../../../shared/types';
import {
    getFullNameFromCase,
    emptyStringParser,
    formatDateString
} from '../../../../services/app.service';
import { NULL_STRING } from '../../../../constants';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    activeCase: GatherCaseUX;
}

const FindLifeInsuranceMoney = (props: Props) => {
    const { activeCase } = props;

    const classes = useTaskDialogStyles();

    const birthDate = activeCase.dob_date
        && activeCase.dob_date !== '' ? formatDateString(activeCase.dob_date) : '___';
    const deathDate = activeCase.dod_start_date
        && activeCase.dod_start_date !== '' ? formatDateString(activeCase.dod_start_date) : '___';
    const ssn: string = activeCase.death_certificate
        && activeCase.death_certificate.about.ssn
        && emptyStringParser(activeCase.death_certificate.about.ssn)
        ? activeCase.death_certificate.about.ssn : NULL_STRING;
    const caseAddress: string = activeCase.death_certificate
        && emptyStringParser(activeCase.death_certificate.life.residencePlace.description)
        ? activeCase.death_certificate.life.residencePlace.description : NULL_STRING;

    return (
        <TaskDialogStepWrapper
            heading="Find Life Insurance Money"
            icon={<AttachMoneyIcon color="primary" />}
        >
            <Grid item>
                <Typography
                    align="left"
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    The NAIC is a U.S. standard-setting and regulatory support organization that has provided
                    a free tool to help families locate any policies that belong to their loved one. By
                    filling out this simple form in just a minute or two, the NAIC will:
                </Typography>
                <ul
                    className={classNames(
                        classes.fontSize18,
                        classes.colorSecondary,
                        classes.textLeft,
                        classes.fontWeight300
                    )}
                >
                    <li>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                            )}
                        >
                            Ask participating companies to search their records to determine whether they have
                            a life insurance policy or annuity contract in {activeCase.fname}'s name.
                        </Typography>
                    </li>
                    <li className={classes.marginTop10}>
                        <Typography
                            color="secondary"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300,
                            )}
                        >
                            Ask participating companies that have policy information to respond directly to
                            you.
                        </Typography>
                    </li>
                </ul>
            </Grid>
            <Grid>
                <a
                    href="https://eapps.naic.org/life-policy-locator/#/welcome"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Click to Instantly Search Hundreds of Insurance Companies
                        <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={
                        classNames(
                            classes.detailBoxHeader,
                            classes.fontSize18,
                            classes.backgroundPrimary,
                            classes.padding10
                        )
                    }
                >
                    You will need the following...
                </Typography>
                <Grid
                    item

                    className={
                        classNames(
                            classes.padding10,
                            classes.textLeft
                        )
                    }
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        Full Name:
                        <span
                            className={classes.fontWeight500}
                        >
                            {getFullNameFromCase(activeCase)}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Last Address:
                        <span
                            className={classes.fontWeight500}
                        >
                            {caseAddress}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Birth:
                        <span
                            className={classes.fontWeight500}
                        >
                            {birthDate}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Passing:
                        <span
                            className={classes.fontWeight500}
                        >
                            {deathDate}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Social Security Number:
                        <span
                            className={classNames(classes.fontWeight500, DATA_PRIVATE)}
                        >
                            {ssn}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Any other legal names used (optional)
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Any other known addresses (optional)
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    <a
                        href="https://eapps.naic.org/common_web/life_insurance_policy_locator_faq.pdf"
                        target="blank"
                        className={classNames(
                            classes.clickable,
                            classes.textDecorationNone,
                            classes.colorSecondary,
                            classes.fontWeight500
                        )}
                    >
                        {'Click here'}
                    </a>
                    {' to learn more about the NAIC process'}
                </Typography>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default FindLifeInsuranceMoney;
