import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        margin: '36px 0 18px'
    },
    card: {
        borderRadius: 8,
        width: 300,
        boxShadow: 'none',
        height: 'auto',
        border: `1px dashed ${theme.palette.secondary.main}`,
        '& $cardContent': {
            padding: 0
        },
    },
    avatar: {
        position: 'absolute',
        top: -36,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 72,
        width: 72,
        zIndex: 1,
        padding: 0,
        textTransform: 'capitalize',
        boxSizing: 'border-box',
        border: '2px solid',
        '& svg': {
            fontSize: 36
        }
    },
    cardContent: {
        height: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: 16
        }
    },
}), { name: 'AddPayerCard' });

interface Props {
    label: string;
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
}

const AddPayerCard = (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
        label,
        onClick,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.root} ref={ref}>
            <Card className={classes.card} sx={{ cursor: onClick ? 'pointer' : undefined }} onClick={onClick}>
                <CardContent className={classes.cardContent}>
                    <Avatar className={classes.avatar}>
                        <AttachMoneyIcon />
                    </Avatar>
                    <Typography color="secondary">
                        {label}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default React.forwardRef(AddPayerCard);
