import { paperClasses } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GPopper from "../common/GPopper";
import Favorite from "@mui/icons-material/Favorite";
import { SKYBLUE_COLOR_2 } from "../../constants/colorVariables";
import GButton from "../common/GButton";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import Divider from "@mui/material/Divider";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { GATHER_ACADEMY_SMALL_LOGO } from "../../constants";
import SchoolIcon from '@mui/icons-material/School';
import Box from "@mui/material/Box";
import Chip, { chipClasses } from "@mui/material/Chip";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { IntercomTrackEvent, getIntercomTargetProp, showIntercomNews, submitIntercomEvent } from "../../services";
import useFeatureEnabled from "../common/hooks/useFeatureEnabled";
import { FeatureKey } from "../../shared/types";
import { styled } from "@mui/material/styles";

export const GatherAcademyButton = styled(GButton)(({ theme }) => ({
    textTransform: 'none',
    display: 'flex',
    borderRadius: '50px',
    fontSize: 16,
    backgroundColor: `${SKYBLUE_COLOR_2} !important`,
    color: '#fff',
    fontWeight: 400,
    paddingTop: '6px',
    paddingBottom: '6px',
    '& svg': { fontSize: '28px !important' }
}));

interface Props {
    zIndex: number;
    anchorEle: HTMLElement | null;
    onClose: () => void;
}
const GatherAcademyPopper = ({ zIndex, anchorEle, onClose }: Props) => {
    const isGatherAcademyEnabled = useFeatureEnabled(FeatureKey.GATHER_ACADEMY);

    return (
        <GPopper
            modifiers={[{
                name: 'preventOverflow',
                options: {
                    padding: 8,
                },
            }]}
            sx={() => ({
                width: 'calc(100% - 16px)',
                maxWidth: 360,
                [`.${paperClasses.root}`]: {
                    boxShadow: 'none',
                    borderRadius: '8px',
                    filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.4))'
                }
            })}
            zIndex={zIndex}
            anchorEle={anchorEle}
            closePopper={onClose}
        >
            <Typography
                pt={1}
                color="#325e80"
                fontWeight={300}
                fontSize={24}
                textAlign="center"
            >
                We <Favorite
                    htmlColor={SKYBLUE_COLOR_2}
                    sx={{ verticalAlign: 'text-top', fontSize: 28 }}
                /> Funeral Directors
            </Typography>

            <Typography textAlign="center" color="secondary" mt={1}>
                We are obsessed with making Gather better for you.
            </Typography>

            <GatherAcademyButton
                disableElevation
                size="large"
                variant="contained"
                startIcon={<FiberNewIcon />}
                text="View the Latest Updates"
                onClick={() => (showIntercomNews(), onClose())}
                sx={{
                    m: '8px auto 0',
                }}
            />

            <Divider
                sx={{
                    height: '1px',
                    m: '16px 8px 8px',
                    bgcolor: SKYBLUE_COLOR_2,
                    border: 'none'
                }}
            />

            <Typography color="secondary" textAlign="center">
                Have an idea to improve Gather? Let us know.
            </Typography>

            <GButton
                variant="text"
                size="large"
                startIcon={<ConfirmationNumberIcon />}
                text="Submit a Feature Ticket"
                {...getIntercomTargetProp('GatherAcademyPopper-submitTicket')}
                sx={{
                    fontSize: 16,
                    textTransform: 'none',
                    display: 'flex',
                    m: '0 auto',
                    borderRadius: '50px',
                    color: SKYBLUE_COLOR_2,
                    fontWeight: 400,
                    py: '6px',
                }}
            />

            {isGatherAcademyEnabled &&
                <>
                    <Divider
                        sx={{
                            height: '1px',
                            m: '4px 8px 8px',
                            bgcolor: SKYBLUE_COLOR_2,
                            border: 'none'
                        }}
                    />

                    <Typography textAlign="center" color="secondary" mb={1}>
                        Get the most of Gather's amazing features!
                    </Typography>

                    <img
                        src={GATHER_ACADEMY_SMALL_LOGO}
                        width="132"
                        style={{ display: 'block', margin: '0 auto' }}
                    />

                    <GatherAcademyButton
                        disableElevation
                        size="large"
                        startIcon={<SchoolIcon />}
                        text="Access Gather Academy™"
                        sx={{
                            m: '0 auto',
                        }}
                        onClick={() => (submitIntercomEvent(IntercomTrackEvent.GatherAcademy), onClose())}
                    />
                </>
            }

            <Box
                mt={isGatherAcademyEnabled ? 2 : 0.5}
                bgcolor={SKYBLUE_COLOR_2}
                p="8px 0 16px"
                borderRadius="0 0 8px 8px"
            >
                <Typography
                    color="#fff"
                    fontSize={20}
                    fontWeight={500}
                    textAlign="center"
                    lineHeight={1.25}
                >
                    Loving Gather?
                </Typography>

                <Typography color="#fff" textAlign="center" fontSize={12} lineHeight={1.25}>
                    Your referral could be worth $525!
                </Typography>

                <a
                    target="_blank"
                    href="https://gather.app/referral-program/"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    <Chip
                        variant="filled"
                        icon={<MonetizationOnIcon htmlColor={SKYBLUE_COLOR_2} />}
                        label="Refer a Friend"
                        onClick={() => void 0}
                        sx={{
                            fontSize: 16,
                            width: 'fit-content',
                            display: 'flex',
                            m: '8px auto 0',
                            bgcolor: '#fff !important',
                            color: SKYBLUE_COLOR_2,
                            boxShadow: 'none !important',
                            [`.${chipClasses.icon}`]: {
                                color: SKYBLUE_COLOR_2
                            }
                        }}
                    />
                </a>
            </Box>
        </GPopper>
    );
};

export default GatherAcademyPopper;