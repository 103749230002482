import { convertHexToRGBA } from '../../../../services';
import { SUCCESS_COLOR } from '../../../../constants/colorVariables';
import makeGStyles from '../../../../styles/makeGStyles';

export const useStyles = makeGStyles(theme => ({
    root: {},
    photo: {
        borderRadius: 6,
    },
    selectIcon: {
        height: 20,
        width: 20,
        color: '#fff',
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 6,
    },
    deleteIcon: {
        height: 40,
        width: 40,
        color: '#fff',
        position: 'absolute',
        zIndex: 6,
        bottom: 8,
        right: 4,
        filter: 'drop-shadow(0 0px 2px gray)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.27)',
        },
    },
    imageBox: {
        width: '100%',
        height: '100%',
        position: 'relative',
        verticalAlign: 'top',
        textAlign: 'center',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        overflow: 'hidden',
        backgroundColor: theme.palette.common.black,
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
        '& img': {
            verticalAlign: 'middle',
            position: 'relative',
            zIndex: 5,
        },
    },
    loadingText: {
        margin: '0 8px 8px',
    },
    loaderContainer: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        margin: 0,
    },
    transformMe: {
        zIndex: 1,
        transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        transform: 'scale(1)',
        '@media (min-width: 480px)': {
            '&:hover': {
                zIndex: 2,
                transform: 'scale(1.04)',
            },
        },
        '&:hover $editPhotoButton': {
            pointerEvents: 'auto',
            opacity: 1,
            zIndex: 10,
            bottom: 0,
            '&$selected': {
                bottom: 6,
            },
        },
    },
    selectedGreenIcon: {
        backgroundColor: theme.palette.common.white,
        top: 12,
        left: 12,
    },
    deselectedPhoto: {
        transform: 'scale(1) !important',
    },
    editPhotoButton: {
        minWidth: '100.1%',
        padding: 0,
        position: 'absolute',
        bottom: -32,
        pointerEvents: 'none',
        opacity: 0,
        left: '50%',
        transform: 'translateX(-50.2%)',
        fontSize: 14,
        whiteSpace: 'nowrap',
        minHeight: 32,
        borderRadius: '0px 0px 6px 6px',
        maxHeight: 32,
        backgroundColor: convertHexToRGBA(theme.palette.common.white, 0.75),
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
        border: 'none',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 300ms !important',
        zIndex: 1,
        filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
        '&:hover': {
            backgroundColor: convertHexToRGBA(theme.palette.common.white, 0.75),
            border: 'none',
        },
        '& svg': {
            fontSize: 18,
        },
        '&$selected': {
            maxWidth: 'calc(100% - 11.5px)',
            minWidth: 'calc(100% - 11.5px)',
            borderRadius: 0,
        },
    },
    buttonPosition: {
        pointerEvents: 'auto',
        opacity: 1,
        zIndex: 10,
        bottom: 0,
        '&$selected': {
            bottom: 6,
        },
    },
    photoWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '99%',
        height: '99%',
        background: '#000',
        zIndex: 4,
        borderRadius: 6,
    },
    photoContainer: {
        borderRadius: 6,
        width: '100%',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        '&$selected': {
            boxShadow: `inset 0px 0px 0px 6px ${SUCCESS_COLOR}`,
        },
        '& img': {
            zIndex: -1,
        },
        '& $photoWrapper': {
            zIndex: -2,
        },
    },
    hideButton: {
        opacity: 0,
        bottom: '-32px !important',
        pointerEvents: 'none !important' as 'none',
    },
    selected: {},
}), { name: 'MasonryPhoto' });
