import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AppRoute, convertHexToRGBA } from '../../../services';

import {
    DocPacketUX, GatherCaseUX, DocDownloadResponse, DocUX, DocPacketStatus, DocPacketType
} from '../../../shared/types';

import DocumentCounter from './DocumentCounter';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DocPacketSignatures } from './DocPacketSignatures';
import { DocumentList } from './DocumentList';
import { useState } from 'react';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {},
    packetsView: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), ' +
            '0px 4px 5px 0px rgba(0,0,0,0.14), ' +
            '0px 1px 10px 0px rgba(0,0,0,0.12)',
        margin: 12,
        border: `1px solid ${theme.palette.primary.main}`,
        '@media (min-width: 500px)': {
            margin: '12px 32px'
        },
        '& $tabsContainer': {
            '& header': {
                boxShadow: 'none',
                backgroundColor: 'transparent'
            },
        }
    },
    popperContent: {
        textAlign: 'center',
        padding: 0,
        borderRadius: 8,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    tabsContainer: {
        filter: 'drop-shadow(0px 0px 0px transparent)',
        '& button': {
            margin: '0 4px',
        },
        '& header': {
            position: 'relative',
            backgroundColor: 'transparent'
        },
        '& $badge': {
            color: theme.palette.common.white,
            left: 'calc(100% - 8px)',
        }
    },
    items: {
        overflowY: 'auto',
        height: 492, // 540 - header height
        flexWrap: 'wrap',
        display: 'flex',
        '&$isCompactView': {
            flexWrap: 'no-wrap',
            display: 'block',
        },
    },
    tabLabelContainer: {
        padding: '16px 24px'
    },
    createNewPacketContainer: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), ' +
            '0px 4px 5px 0px rgba(0,0,0,0.14), ' +
            '0px 1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: `1px dashed ${theme.palette.primary.main}`,
        margin: 12,
        textdocumentCounterWidgetAlign: 'center',
        borderRadius: 8,
        '@media (min-width: 500px)': {
            margin: '12px 32px'
        },
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        '& p': {
            lineHeight: '1em',
            fontSize: 26,
            fontWeight: 200,
            marginBottom: 8
        },
        '& a': {
            width: '100%',
            height: '100%',
            color: 'inherit',
            textDecoration: 'none'
        },
        '& button': {
            padding: '16px 0'
        },
        '&:hover': {
            boxShadow: theme.shadows[10]
        }
    },
    fabExtended: {
        color: theme.palette.secondary.main,
        width: 'max-content',
        margin: 'auto',
        textTransform: 'none',
        fontWeight: 400,
        fontSize: 10,
        padding: '2px 16px',
        borderRadius: 24,
        minHeight: 'auto',
        border: `1px solid ${theme.palette.secondary.main}`
    },
    docs: {
        height: 240,
        minHeight: 240,
        maxHeight: 240,
        background: convertHexToRGBA(theme.palette.primary.main, 0.18),
        boxShadow: 'inset 0px 0px 6px 0px rgba(0,0,0,0.2)'
    },
    appBar: {
        boxShadow: 'none !important',
        '& button': {
            height: 36,
            minHeight: 36,
            fontSize: '0.8125rem',
            display: 'inline-flex',
            justifyContent: 'flex-start',
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    tabScroller: {
        position: 'inherit'
    },
    tabRoot: {
        minHeight: 36,
        height: 36
    },
    expansionPanel: {
        overflow: 'hidden',
        overflowY: 'auto',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    accordian: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), ' +
            '0px 4px 5px 0px rgba(0,0,0,0.14), ' +
            '0px 1px 10px 0px rgba(0,0,0,0.12)',
        margin: 12,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '8px !important',
        marginBottom: '8px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '@media (min-width: 500px)': {
            margin: '12px 32px !important',
        },
        '&:before': {
            display: 'none',
        },
        '& .MuiCollapse-root': {
            width: '100%',
        },
    },
    expansionPanelSummary: {
        padding: 0,
        width: '100%'
    },
    expansionPanelSummaryExpanded: {
        borderBottomColor: '#eee',
        margin: '0px !important'
    },
    expansionPanelSummaryContent: {
        transition: 'none !important',
        margin: 0
    },
    expandIcon: {
        top: 4,
        right: 24,
        width: 24,
        height: 24,
        position: 'absolute',
    },
    docCounter: {
        padding: '0 !important',
        width: '100%'
    },
    disabledShadow: {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    isCompactView: {},
    badge: {},
}), { name: 'DocPacketSummaryCard' });

interface Props {
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
    packetLink?: AppRoute;
    docNameClass?: string;
    docPacketSummaryClass?: string;
    zIndex: number;
    onDownloadUnsignedPacket: (docPacket: DocPacketUX) => void;
    onDownloadSignedDoc: (doc: DocUX) => Promise<DocDownloadResponse | null>;
    onDeletePacket: (docPacket: DocPacketUX, activeCase: GatherCaseUX) => void;
    isChangeEmailDialogOpen?: (isOpen: boolean) => void;
    isHelperPopperOpen: (isOpen: boolean) => void;
    isDeleteConfirmationDialogOpen: boolean;
    setDeleteConfirmationDialogOpen: (isOpen?: boolean) => void;
    isGOM: boolean;
    menuAnchorEle: HTMLElement | null;
    setMenuAnchorEle: (menuAnchorEle: HTMLElement | null) => void;
    reminderAnchorEl: HTMLElement | null;
    setReminderAnchorEl: (anchorEl: HTMLElement | null) => void;
    signerMenuAnchorEle: HTMLElement | null;
    setSignerMenuAnchorEle: (menuAnchorEle: HTMLElement | null) => void;
    disabled?: boolean;
    toggleDocMenuOpen: (isOpen: boolean) => void;
}

const enum DocTab {
    documents = 'documents',
    signatures = 'signatures',
}

export const getStatusDisplay = (docPacket: DocPacketUX | { status: DocPacketStatus; type: DocPacketType }): string => {
    switch (docPacket.status) {
        case 'printed':
            return 'Finalized & Printed';
        case 'sent':
            return 'Sent for eSignature';
        case 'signed':
            if (docPacket.type === 'fill_esign') {
                return 'eSigned';
            } else {
                return 'Printed & Signed';
            }
        case 'started':
            return 'Started';
        default:
            return '';
    }
};

const DocPacketSummaryCard = (props: Props) => {
    const {
        docPacket,
        packetLink,
        zIndex,
        isGOM,
        isDeleteConfirmationDialogOpen,
        setDeleteConfirmationDialogOpen,
        menuAnchorEle,
        setMenuAnchorEle,
        signerMenuAnchorEle,
        setSignerMenuAnchorEle,
        disabled,
    } = props;

    const classes = useStyles();

    const [selectedDocTab, setSelectedDocTab] = useState<DocTab>(DocTab.documents);
    const [isAccordianExpanded, setIsAccordianExpanded] = useState<boolean>(false);

    const selectLabel = (): string => {
        if (docPacket.docs.length + docPacket.contracts.length === 1) {
            return 'Document';
        } else {
            return 'Documents';
        }
    };

    const onAccordianExpandToggle = () => {
        setIsAccordianExpanded((prev) => !prev);
    };

    const handleDocTabChange = (_: React.ChangeEvent<{}>, tab: DocTab) => {
        setSelectedDocTab(tab);
    };

    const signerProps = {
        ...props,
        menuAnchorEle: signerMenuAnchorEle,
        setMenuAnchorEle: setSignerMenuAnchorEle,
    };
    return (
        <Accordion
            classes={{
                root: classes.accordian,
            }}
            expanded={isAccordianExpanded}
            onChange={onAccordianExpandToggle}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.expansionPanelSummary}
                classes={{
                    expanded: classes.expansionPanelSummaryExpanded,
                    content: classes.expansionPanelSummaryContent,
                    expandIconWrapper: classes.expandIcon,
                }}
            >
                <Grid item xs={12} className={classNames(
                    classes.docCounter,
                    disabled && classes.disabledShadow
                )}>
                    <DocumentCounter
                        docCount={docPacket.docs.length + docPacket.contracts.length}
                        link={isAccordianExpanded && packetLink || undefined}
                        status={docPacket.status}
                        statusDisplay={getStatusDisplay(docPacket)}
                        zIndex={zIndex + 1}
                        isGOM={isGOM}
                        isDeleteConfirmationDialogOpen={isDeleteConfirmationDialogOpen}
                        setDeleteConfirmationDialogOpen={setDeleteConfirmationDialogOpen}
                        menuAnchorEle={menuAnchorEle}
                        setMenuAnchorEle={setMenuAnchorEle}
                        disabled={disabled}
                    />
                </Grid>
            </AccordionSummary>

            <AccordionDetails className={classes.expansionPanel}>
                <Grid xs={12} item className={classNames(classes.tabsContainer, classes.borderBottom)}>
                    <AppBar position="static" className={classes.appBar}>
                        <Tabs
                            value={selectedDocTab}
                            onChange={handleDocTabChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            classes={{
                                root: classes.tabRoot,
                                scroller: classes.tabScroller
                            }}
                        >
                            <Tab
                                key={DocTab.documents}
                                value={DocTab.documents}
                                label={selectLabel()}
                                classes={{ root: classes.tabLabelContainer }}
                            />
                            <Tab
                                key={DocTab.signatures}
                                value={DocTab.signatures}
                                label="Signatures"
                                classes={{ root: classes.tabLabelContainer }}
                            />
                        </Tabs>
                    </AppBar>
                </Grid>

                <Grid
                    item
                    xs={12}
                    className={classNames(classes.items, classes.docs)}
                >
                    {selectedDocTab === DocTab.documents ?
                        <DocumentList {...props} /> :
                        <DocPacketSignatures {...signerProps} menuAnchorReference="anchorPosition" />
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default DocPacketSummaryCard;
