import {
    emptyStringParser,
    getAddressFromLongAddress,
    formatDateString,
    DateTimeFormat
} from "../../../../../services";
import { ConfigurableAboutFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { GatherCaseUX } from "../../../../../shared/types";
import { OverviewSectionCommonProps } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
    activeCase: GatherCaseUX;
}
const AboutOverview = (props: Props) => {
    const { printMode, divider, deathCertificate, activeCase, alwaysVisible } = props;

    const suffix = emptyStringParser(activeCase.suffix) || null;
    const lastNameAtBith = emptyStringParser(deathCertificate.about.birthLastName) || null;
    const aka: string | null = emptyStringParser(deathCertificate.about.aka) || null;
    const gender: string | null = emptyStringParser(deathCertificate.about.gender) || null;
    const pronouns: string | null = emptyStringParser(deathCertificate.about.pronouns) || null;

    let ssn: string | null = !deathCertificate.about.ssn_unknown
        && emptyStringParser(deathCertificate.about.ssn) || null;
    if (deathCertificate.about.ssn_unknown) {
        ssn = 'unknown';
    } else if (deathCertificate.about.ssn_none) {
        ssn = 'none';
    }

    const hasMemorialService: string | null = deathCertificate.about.hasMemorialService || 'No';
    const ministerOfficiating = emptyStringParser(deathCertificate.about.ministerOfficiating) || null;
    const pickUpAddress = getAddressFromLongAddress(deathCertificate.about.pickUpAddress || null);
    const dropOffAddress = getAddressFromLongAddress(deathCertificate.about.dropOffAddress || null);
    const facilityHasStairs = emptyStringParser(deathCertificate.about.hasStairs) || null;
    const numberOfDCs = (deathCertificate.about.numberOfDCs && deathCertificate.about.numberOfDCs.toString()) || null;
    const casket = emptyStringParser(deathCertificate.about.casket) || null;
    const insurance = emptyStringParser(deathCertificate.about.insuranceDetails) || null;
    const hair = emptyStringParser(deathCertificate.about.hair) || null;
    const facialHair = emptyStringParser(deathCertificate.about.facialHair) || null;
    const nails = emptyStringParser(deathCertificate.about.nails) || null;
    const lipstickColor = emptyStringParser(deathCertificate.about.lipstickColor) || null;
    const medicalExaminerReleaseNumber = emptyStringParser(deathCertificate.about.medicalExaminerReleaseNumber) || null;
    const clothingDueBy = formatDateString(deathCertificate.about.clothingDueBy || '', DateTimeFormat.LongDate) || null;
    const referredToUsBy = emptyStringParser(deathCertificate.about.referredToUsBy) || null;
    const hobbies = emptyStringParser(deathCertificate.about.hobbies) || null;
    const lodges = emptyStringParser(deathCertificate.about.lodges) || null;
    const clubs = emptyStringParser(deathCertificate.about.clubs) || null;
    const offices = emptyStringParser(deathCertificate.about.offices) || null;
    const previousPlaceLived = emptyStringParser(deathCertificate.about.previousPlacesLived) || null;
    const casketPanel = emptyStringParser(deathCertificate.about.casketPanel) || null;
    const chapel = emptyStringParser(deathCertificate.about.chapel) || null;
    const insurancePolicyNumber = emptyStringParser(deathCertificate.about.insurancePolicyNumber) || null;
    const weight = emptyStringParser(deathCertificate.about.weight) || null;
    const other = emptyStringParser(deathCertificate.about.other) || null;
    const hasChildrenUnder18 = emptyStringParser(deathCertificate.about.hasChildrenUnder18) || null;

    return (
        <div>
            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                    marginTop: printMode ? 8 : 0,
                }}
            >
                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.suffix}
                    label="Suffix"
                    html={suffix}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.birthLastName}
                    label="Last Name At Birth"
                    html={lastNameAtBith}
                    skipTextTransformation
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.aka}
                    label="Also known as (AKA)"
                    html={aka}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.gender}
                    label="Gender"
                    html={gender}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.pronouns}
                    label="Pronouns"
                    html={pronouns}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.ssn}
                    label="Social Security Number"
                    html={ssn}
                    privateData
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.hasMemorialService}
                    label="Will There Be A Funeral Service"
                    html={hasMemorialService}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.ministerOfficiating}
                    label="Clergy or Officiant"
                    html={ministerOfficiating}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.pickUpAddress}
                    label="Pick Up Address"
                    html={pickUpAddress}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.dropOffAddress}
                    label="Drop Off Address"
                    html={dropOffAddress}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.facilityHasStairs}
                    label="Facility Has Stair"
                    html={facilityHasStairs}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.numberOfCertifiedDCs}
                    label="Number of Certified Death Certificates"
                    html={numberOfDCs}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.casket}
                    label="Casket"
                    html={casket}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.insuranceDetails}
                    label="Insurance Company Name"
                    html={insurance}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.insurancePolicyNumber}
                    label="Insurance Policy Number"
                    html={insurancePolicyNumber}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.hair}
                    label="Hair"
                    html={hair}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.facialHair}
                    label="Facial Hair"
                    html={facialHair}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.nails}
                    label="Nails"
                    html={nails}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.lipstickColor}
                    label="Lipstick Color"
                    html={lipstickColor}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.medicalExaminerReleaseNumber}
                    label="Medical Examiner Release Number"
                    html={medicalExaminerReleaseNumber}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.clothingDueBy}
                    label="Clothing Due By"
                    html={clothingDueBy}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.referredToUsBy}
                    label="Referred To Us By"
                    html={referredToUsBy}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.hobbies}
                    label="Hobbies"
                    html={hobbies}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.lodges}
                    label="Lodges"
                    html={lodges}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.clubs}
                    label="Clubs"
                    html={clubs}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.offices}
                    label="Offices"
                    html={offices}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.previousPlacesLived}
                    label="Previous Place Lived"
                    html={previousPlaceLived}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.casketPanel}
                    label="Casket Panel"
                    html={casketPanel}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.chapel}
                    label="Chapel"
                    html={chapel}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.weight}
                    label="Weight"
                    html={weight}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.other}
                    label="Other"
                    html={other}
                    alwaysVisible={alwaysVisible}
                />

                <OverviewLabel
                    fieldKey={ConfigurableAboutFieldKey.hasChildrenUnder18}
                    label="Decedent Has Children under 18"
                    html={hasChildrenUnder18}
                    alwaysVisible={alwaysVisible}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default AboutOverview;