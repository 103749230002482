
import { useState } from 'react';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';

import GDialogCore from '../../../../../common/GDialogCore';
import classNames from 'classnames';
import makeGStyles from '../../../../../../styles/makeGStyles';
import { PropsWithChildren } from 'react';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog';

const useStyles = makeGStyles(theme => ({
    root: {},
    dialogTitle: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: 20
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    title: {
        fontSize: 18,
        '@media (min-width: 375px)': {
            fontSize: 20
        }
    },
    dialogPaper: {
        '@media (min-width: 600px)': {
            maxWidth: 570,
            maxHeight: 'calc(100% - 96px)',
            height: 'unset'
        }
    }
}), { name: 'TaskComponentDialog' });


export interface Props {
    isDialogOpen: boolean;
    zIndex: number;
    titleClass?: string;
    title: string;
    onClose: () => void;
}

const TaskComponentDialog = (props: PropsWithChildren<Props>) => {
    const {
        isDialogOpen,
        zIndex,
        title,
        titleClass,
        children,
        onClose,
    } = props;

    const classes = useStyles();
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const forceCloseDialog = () => {
        setConfirmationDialogOpen(false);
        onClose();
    };

    return (
        <>
            <GDialogCore
                isOpen={isDialogOpen}
                zIndex={zIndex}
                title={
                    <>
                        <Typography color="inherit" className={classNames(classes.title, titleClass)}>
                            {title}
                        </Typography>
                        <ClearIcon
                            onClick={() => setConfirmationDialogOpen(true)}
                            className={classes.clearIcon}
                            color="inherit"
                        />
                    </>
                }
                onClose={() => setConfirmationDialogOpen(true)}
                titleClass={classes.dialogTitle}
                contentClass={classes.dialogContent}
                paperClass={classes.dialogPaper}
                disableTypography
            >
                {children}
            </GDialogCore>

            <ConfirmationDialog
                zIndex={zIndex + 1}
                header="Are you sure?"
                subHeader="Are you sure you want to close before saving this component?"
                confirmationButtonText="Close component screen"
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
                onConfirm={forceCloseDialog}
            />
        </>
    );
};

export default TaskComponentDialog;
