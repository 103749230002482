import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { GatherCasePreview, GatherEvent } from '../../../../shared/types';
import { GOOGLE_LOGO } from '../../../../constants';
import PendingTaskList from '../pendingTaskList/PendingTaskList';
import EventTime from '../EventTime';
import { convertHexToRGBA, EventDateMap } from '../../../../services';
import NoEventCard from './NoEventCard';
import { DateTimeFormat } from '../../../../shared/utils';
import makeGStyles from '../../../../styles/makeGStyles';

const CARD_WIDTH = 340;

interface DateData {
    id: number;
    value: momentTz.Moment;
    description: string;
    dayString: string; // days away
    date: string; // YYYY-MM-DD
}
interface Props {
    key: number;
    eventDateMap: EventDateMap | null;
    dateData: DateData;
    cases: GatherCasePreview[];
    isLoading: boolean;
    isGCalConnected: boolean;
    selectedTeamMemberUserId: number | null;
    zIndex: number;
    openPickTeamMember: (e: React.MouseEvent<HTMLElement>) => void;
    todayCardRef: React.RefObject<HTMLDivElement>;
}

const useStyles = makeGStyles(theme => ({
    paperBorder: {
        width: '100%',
        maxWidth: 300,
        minWidth: 300,
        boxShadow: theme.shadows[5],
        borderRadius: 12,
        margin: '0 10px',
        '@media (min-width: 960px)': {
            maxWidth: CARD_WIDTH,
            minWidth: CARD_WIDTH
        },
    },
    todayPaperBorder: {
        width: '100%',
        maxWidth: 600,
        minWidth: 600,
        boxShadow: theme.shadows[5],
        borderRadius: 12,
        margin: '0 10px',
        color: theme.palette.primary.main,
        '@media (min-width: 960px)': {
            maxWidth: 680,
            minWidth: 680
        },
        '@media (min-width: 1300px)': {
            maxWidth: CARD_WIDTH,
            minWidth: CARD_WIDTH
        },
    },
    marginCard: {
        margin: '0px 0px 0px 32px',
        '&:last-child': {
            marginRight: 32,
        }
    },
    topDatePill: {
        borderRadius: 20,
        position: 'absolute',
        top: 0,
        padding: '0px 16px',
        fontWeight: 400,
        fontSize: 14,
        zIndex: 2,
        left: '50%',
        width: 'max-content',
        transform: 'translate(-50%, -50%)'
    },
    displayFlex: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    flexCenterData: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 6px',
        minHeight: 29,
        boxSizing: 'border-box'
    },
    EventDayColorPrimary: {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        '& p': {
            color: theme.palette.common.white
        }
    },
    EventDayColorSecondary: {
        color: theme.palette.common.white,
        background: theme.palette.secondary.main,
    },
    height100: {
        height: '100%'
    },
    refDivFlex: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    displayWithTodayEvent: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px 0px 0px 12px',
        '@media (min-width: 1300px)': {
            display: 'none'
        },
    },
    taskColorPrimary: {
        background: theme.palette.primary.main,
        '& p': {
            color: theme.palette.common.white
        }
    },
    footerCardEvent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
        borderTop: '1px solid',
        padding: '4px 0px',
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.08),
        '& p': {
            lineHeight: 1
        }
    },
    eventCardSection: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    todayEventSection: {
        flexBasis: '50%',
        border: '3px solid',
        borderLeft: 'none',
        boxSizing: 'border-box',
        borderRadius: '0px 12px 12px 0px',
        '@media (min-width: 1300px)': {
            flexBasis: '100%',
            borderRadius: 12,
            borderLeft: '3px solid'
        },
    },
    borderEventSection: {
        border: '1px solid',
        color: theme.palette.secondary.main,
        borderRadius: 12
    },
    justifyFlexEnd: {
        justifyContent: 'flex-end'
    },
    cursorDefault: {
        cursor: 'default'
    },
    justifyContentUnset: {
        justifyContent: 'unset'
    },
    todayCardWidth: {
        maxWidth: 297,
        '@media (min-width: 960px)': {
            maxWidth: 337
        }
    },
}), { name: 'DateWiseEvent' });

const getEventDate = (date: string) => {
    const now = momentTz().format(DateTimeFormat.ShortDate);

    return momentTz(now).isSame(date, 'day')
        ? 'Today'
        : (momentTz(date).format('dddd, D MMMM YYYY'));
};

const DateWiseEvent = (props: Props) => {
    const {
        eventDateMap,
        dateData,
        isLoading,
        cases,
        zIndex,
        todayCardRef,
        isGCalConnected,
        selectedTeamMemberUserId,
        openPickTeamMember,
    } = props;

    const classes = useStyles();

    const sortedEvents = eventDateMap ? eventDateMap[dateData.date] || [] : [];
    const isToday = dateData.dayString === 'Today';
    const todayDate = momentTz();
    const dates = momentTz(dateData.value);
    const diff = dates.diff(todayDate, 'days', true);
    const dayDiff = Math.round(diff);

    const daysPlural = dayDiff === 1 ? '' : dayDiff === -1 ? '' : 's';
    let previousDates = Math.abs(dayDiff);

    const DaysTooltipMessage = dayDiff >= 1
        ? `In ${dayDiff} day${daysPlural} from today`
        : `${previousDates} day${daysPlural} ago`;
    const daysShown = dayDiff >= 1
        ? `+${dayDiff}d`
        : `-${previousDates}d`;

    const tooltipMessage = `Any event scheduled in Gather will automatically sync up to your team's Google Calendar 
        and any event scheduled in your team's Google Calendar will automatically sync up to Gather. 
        Any changes made will be reflected everywhere for everyone instantly.`;

    if (sortedEvents.length === 0 && (dayDiff < -5 || dayDiff > 30)) {
        return null;
    }
    return (
        <Paper
            className={classNames(
                classes.marginCard,
                isToday ? classes.todayPaperBorder
                    : classes.paperBorder,
                isToday && classes.displayFlex
            )}
        >
            <div
                ref={isToday ? todayCardRef : undefined}
                className={classNames(classes.refDivFlex, classes.height100)}
            >
                {isToday &&
                    <Grid
                        xs={6}
                        item
                        className={classNames(
                            classes.displayWithTodayEvent,
                            classes.taskColorPrimary,
                            classes.height100
                        )}
                    >
                        <PendingTaskList
                            selectedTeamMemberUserId={selectedTeamMemberUserId}
                            openPickTeamMember={openPickTeamMember}
                            zIndex={zIndex}
                        />
                    </Grid>
                }
                <Grid
                    xs={12}
                    item
                    className={classNames(
                        classes.positionRelative,
                        classes.height100,
                        isToday ? classes.todayEventSection
                            : classes.borderEventSection
                    )}
                >
                    <Typography
                        component="p"
                        className={classNames(
                            classes.topDatePill,
                            isToday ? classes.EventDayColorPrimary
                                : classes.EventDayColorSecondary
                        )}
                    >
                        {getEventDate(dateData.date)}
                    </Typography>
                    <div className={classNames(
                        classes.scrollAuto,
                        classes.eventCardSection,
                        !isToday && classes.justifyContentUnset,
                        classes.todayCardWidth,
                        classes.height100
                    )}
                    >
                        <div
                            className={classNames(
                                classes.flexCenterData,
                                isToday && classes.justifyFlexEnd
                            )}
                        >
                            {!isToday &&
                                <Tooltip
                                    title={DaysTooltipMessage}
                                    placement="top"
                                    enterDelay={400}
                                >

                                    {isLoading ? <CircularProgress color="primary" size={20} thickness={3} />
                                        : <Typography color="secondary" className={classes.cursorDefault}>
                                            {daysShown}
                                        </Typography>
                                    }
                                </Tooltip>
                            }
                            {isGCalConnected &&
                                <Tooltip
                                    title={tooltipMessage}
                                    placement="top"
                                    enterDelay={200}
                                >
                                    <img src={GOOGLE_LOGO} height="20px" width="20px" />
                                </Tooltip>
                            }
                        </div>

                        {sortedEvents.length === 0
                            ? <NoEventCard
                                isLoading={isLoading}
                            />
                            : <div className={classNames(classes.height100, classes.scrollAuto)}>
                                {sortedEvents.map((event) => (
                                    <EventTime
                                        key={`${GatherEvent.isGatherEvent(event)
                                            ? 'gather'
                                            : 'google'
                                            }_${event.id}`}
                                        event={event}
                                        cases={cases}
                                    />
                                ))}
                            </div>
                        }

                        {isToday && isGCalConnected &&
                            <div>
                                <Tooltip
                                    title={tooltipMessage}
                                    placement="top"
                                    enterDelay={200}
                                >
                                    <div className={classes.footerCardEvent}>
                                        <img src={GOOGLE_LOGO} height="16px" width="16px" />
                                        <Typography
                                            component="p"
                                            className={classNames(
                                                classes.fontSize14,
                                                classes.cursorDefault
                                            )}
                                        >
                                            &nbsp;Your Google Calendar Sync is ENABLED
                                        </Typography>
                                    </div>
                                </Tooltip>
                            </div>
                        }
                    </div>
                </Grid>
            </div>
        </Paper>
    );
};

export default DateWiseEvent;