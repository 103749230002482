import React, { forwardRef } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { InputBaseComponentProps } from '@mui/material';

const YearNumberMask = forwardRef(
    function YearNumberMaskComponent(
        props: InputBaseComponentProps,
        forwardedRef: React.ForwardedRef<HTMLElement>
    ) {
        const { inputRef, ...other } = props;
        const maskedOtherProps = other as MaskedInputProps;

        return (
            <MaskedInput
                {...maskedOtherProps}
                ref={ref => ref?.inputElement && typeof forwardedRef === 'function' && forwardedRef(ref.inputElement)}
                mask={[/[1-9]/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
                showMask
                guide={false}
            />
        );
    });

export default YearNumberMask;