import makeGStyles from "../../../styles/makeGStyles";

/**
 * we should always make the `makeGStyles` function call outside of the `customHook/FC`,
 * else it will result in RE-GENERATING styles on each Component RE-RENDER  
 */
const useStyles = makeGStyles({}, { name: 'GStyles' });
/** @deprecated Use GStyles instead. i.e. className={GStyles.width100} */
export function useGStyles() {
    return useStyles();
};
