import { Component, CSSProperties } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { SvgIconComponent } from "@mui/icons-material";

import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import SimpleImage from '../../../../common/SimpleImage';
import { GREEN_COLOR } from '../../../../../constants/colorVariables';
import classNames from 'classnames';
import { getIntercomTargetProp } from '../../../../../services';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dialogTitle: {
        padding: '12px 12px 60px',
        background: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        left: 0,
        boxShadow: theme.shadows[4],
        boxSizing: 'border-box',
        width: '100%',
        zIndex: 2,
    },
    stepIcon: {
        fontSize: 80,
        padding: 12,
        border: '2px dashed',
        color: theme.palette.common.white,
        borderRadius: '50%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        alignSelf: 'flex-start',
        zIndex: 2,
        background: theme.palette.primary.main,
        '&$completed': {
            border: 'none',
            background: GREEN_COLOR
        }
    },
    stepImage: {
        boxSizing: 'border-box',
        padding: '0 !important',
        border: '2px dashed',
        color: theme.palette.common.white,
        borderRadius: 8,
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        alignSelf: 'flex-start',
        zIndex: 2,
        '&$completed': {
            border: `2px solid ${GREEN_COLOR}`
        }
    },
    stepTitle: {
        fontSize: 24,
        lineHeight: 1,
        color: theme.palette.common.white,
    },
    shrinkedHeaderText: {
        color: theme.palette.common.white,
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
        left: 0,
        top: 18,
        '& p:first-of-type': {
            lineHeight: 1.25,
            fontSize: 16,
            '@media (min-width: 768px)': {
                fontSize: 18,
                lineHeight: 1
            }
        },
        '& p:last-of-type': {
            fontSize: 12,
            '@media (min-width: 768px)': {
                fontSize: 14
            }
        }
    },
    closeIcon: {
        fontSize: 32,
        position: 'absolute',
        top: 4,
        right: 8,
        cursor: 'pointer',
        color: theme.palette.common.white
    },
    completed: {}
});
type Classes = 'root' | 'dialogTitle' | 'stepIcon' | 'shrinkedHeaderText' | 'stepTitle'
    | 'closeIcon' | 'stepImage' | 'completed';
type StyledProps = WithGStyles<Classes>;

interface Props {
    isStepCompleted: boolean;
    subtitle: string;
    dialogContentRef: HTMLDivElement | null;
    stepTitle: string;
    stepImagePublicId: string | null;
    stepImageFallbackURL: string | null;
    stepIcon: SvgIconComponent | null;
    closeDialog: () => void;
    intercomTargetProp?: string;
    intercomTargetPropDialogName?: string;
    intercomTargetPropTitle?: string;
    intercomTargetPropCancel?: string;
}
interface State {
    scrollTop: number;
}
class BaseStepDialogTitle extends Component<Props & StyledProps, State> {
    state: State = {
        scrollTop: this.props.dialogContentRef
            ? this.props.dialogContentRef.scrollTop
            : 0
    };

    private scrollTopOffset: number | null = null;

    componentDidUpdate(prevProps: Props & StyledProps) {
        const { dialogContentRef } = this.props;

        if (dialogContentRef && !prevProps.dialogContentRef) {
            dialogContentRef.addEventListener('scroll', this.handleScroll, { passive: true });
            this.setState({ scrollTop: dialogContentRef.scrollTop });
        }
    }

    componentWillUnmount() {
        const { dialogContentRef } = this.props;

        if (dialogContentRef) {
            dialogContentRef.removeEventListener('scroll', this.handleScroll);
        }
    }

    resizeWithThreshold = (
        maxSize: number,
        minSize: number,
        scrollSpeed: number = 0.8,
        topScroll?: number,
        offset?: number
    ): number => {
        const { scrollTop } = this.state;
        const threshold = maxSize - minSize;
        const size = maxSize - (threshold * (topScroll !== undefined
            ? (scrollTop - topScroll)
            : scrollTop) * scrollSpeed) / 100;

        const currentSize = size < minSize ? minSize : size;

        return offset !== undefined ? offset - currentSize : currentSize;
    };

    getIconStyles = (): CSSProperties => {
        const size = this.resizeWithThreshold(50, 0);
        const fontSize = this.resizeWithThreshold(80, 34);
        const padding = this.resizeWithThreshold(12, 4);

        return {
            marginLeft: `${size}%`,
            transform: `translateX(-${size}%)`,
            fontSize,
            padding
        };
    };

    getImageStyles = (): CSSProperties => {
        const size = this.resizeWithThreshold(50, 0);
        const widthNHeight = this.resizeWithThreshold(108, 46);

        return {
            marginLeft: `${size}%`,
            transform: `translateX(-${size}%)`,
            width: widthNHeight,
            height: widthNHeight
        };
    };

    getDialogTitleStyles = (): CSSProperties => {
        const paddingBottom = this.resizeWithThreshold(48, 12);

        return { paddingBottom };
    };

    getHeaderTextStyles = (): CSSProperties => {
        const bottom = this.resizeWithThreshold(50, 20, 2, undefined, 62);
        const transform = this.resizeWithThreshold(70, 0, 2, undefined, 70);
        const opacity = this.resizeWithThreshold(1, 0, 2);

        return {
            position: 'absolute',
            bottom,
            transform: `translateY(${transform}%)`,
            opacity,
            pointerEvents: opacity === 0 ? 'none' : undefined
        };
    };

    getShrinkedTextStyles = (startAnimation: boolean): CSSProperties => {
        if (startAnimation) {
            if (!this.scrollTopOffset) {
                this.scrollTopOffset = this.state.scrollTop - 48;
            }

            const opacity = this.resizeWithThreshold(1, 0, 2, this.scrollTopOffset || undefined, 1);
            const transform = this.resizeWithThreshold(100, 68, 2, this.scrollTopOffset || undefined, 136);

            return {
                opacity,
                transform: `translateX(${transform}px)`
            };
        }

        return {};
    };

    render() {
        const {
            classes,
            subtitle,
            stepTitle,
            stepImagePublicId,
            stepIcon,
            stepImageFallbackURL,
            isStepCompleted,
            closeDialog,
            intercomTargetProp,
            intercomTargetPropDialogName,
            intercomTargetPropTitle,
            intercomTargetPropCancel,
        } = this.props;

        const iconStyles = this.getIconStyles();
        const imageStyles = this.getImageStyles();
        const shrinkedTextStyles = this.getShrinkedTextStyles(!!iconStyles.fontSize && iconStyles.fontSize <= 38);
        const headerTextStyles = this.getHeaderTextStyles();
        const dialogTitleStyles = this.getDialogTitleStyles();

        const StepIcon: SvgIconComponent = stepIcon ?? AssignmentIcon;

        return (
            <DialogTitle className={classes.dialogTitle} style={dialogTitleStyles}>
                <Grid container={true} direction="column" alignItems="center" >
                    {stepImagePublicId &&
                        <SimpleImage
                            publicId={stepImagePublicId}
                            width={120}
                            height={120}
                            cropMode="fill"
                            className={classNames(classes.stepImage, isStepCompleted && classes.completed)}
                            style={imageStyles}
                            intercomTargetProp={intercomTargetProp}
                            intercomTargetPropDialogName={intercomTargetPropDialogName}
                        />
                    }
                    {stepImageFallbackURL &&
                        <img
                            src={stepImageFallbackURL}
                            width={120}
                            height={120}
                            className={classNames(classes.stepImage, isStepCompleted && classes.completed)}
                            style={{ ...imageStyles, objectFit: 'cover' }}
                            {...getIntercomTargetProp((intercomTargetProp && intercomTargetPropDialogName)
                                ? `${intercomTargetProp}-${intercomTargetPropDialogName}`
                                : (intercomTargetProp && !intercomTargetPropDialogName)
                                    ? intercomTargetProp : ``)}
                        />
                    }
                    {!stepImagePublicId && !stepImageFallbackURL &&
                        <StepIcon
                            style={iconStyles}
                            className={classNames(classes.stepIcon, isStepCompleted && classes.completed)}
                            {...getIntercomTargetProp((intercomTargetProp && intercomTargetPropDialogName)
                                ? `${intercomTargetProp}-${intercomTargetPropDialogName}`
                                : (intercomTargetProp && !intercomTargetPropDialogName)
                                    ? intercomTargetProp : ``)}
                        />
                    }

                    <Grid
                        item
                        style={shrinkedTextStyles}
                        className={classes.shrinkedHeaderText}
                    >
                        <Typography color="inherit">{stepTitle}</Typography>
                        <Typography color="inherit">
                            {subtitle}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        style={headerTextStyles}
                    >
                        <Typography
                            align="center"
                            className={classes.stepTitle}
                            {...getIntercomTargetProp((intercomTargetPropTitle && intercomTargetPropDialogName)
                                ? `${intercomTargetPropTitle}-${intercomTargetPropDialogName}`
                                : (intercomTargetPropTitle && !intercomTargetPropDialogName)
                                    ? intercomTargetPropTitle : ``)}
                        >
                            {stepTitle}
                        </Typography>
                    </Grid>
                </Grid>

                <CloseIcon
                    className={classes.closeIcon}
                    onClick={closeDialog}
                    {...getIntercomTargetProp((intercomTargetPropCancel && intercomTargetPropDialogName)
                        ? `${intercomTargetPropCancel}-${intercomTargetPropDialogName}`
                        : (intercomTargetPropCancel && !intercomTargetPropDialogName)
                            ? intercomTargetPropCancel : ``)}
                />
            </DialogTitle>
        );
    }

    handleScroll = () => {
        const { dialogContentRef } = this.props;
        if (dialogContentRef) {
            this.setState({ scrollTop: dialogContentRef.scrollTop });
        }
    };

}

export default withGStyles(styles)(BaseStepDialogTitle);
