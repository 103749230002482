import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';
import { getValidator } from './utils';

export enum WebsiteVendor {
    gather = 'gather',
    funeralone = 'funeralone',
    cfs = 'cfs',
    funeralinnovations = 'funeralinnovations'
}

export function isWebsiteVendor(vendor: string): vendor is WebsiteVendor {
    return Boolean(WebsiteVendor[vendor]);
}

export interface WebsiteRecord {
    id: number;
    url: string;
    token: string;
    ga4_measurement_id: string |  null;
    created_time: Date;
    launched_time: Date | null;
    deleted_time: Date | null;
    vendor: WebsiteVendor;
    block_subscriptions: boolean;
}

export interface WebsiteCaseAliasRecord {
    website_id: number;
    alias: string;
    search_alias: string;
    gather_case_id: number;
    canonical: boolean;
}

export type WebsiteFuneralHomes = {
    id: number;
    name: string;
};

export interface WebsiteUX extends WebsiteRecord {
    funeral_homes: WebsiteFuneralHomes[];
}

export const VENDOR_TYPE = t.union([
    t.literal(WebsiteVendor.cfs),
    t.literal(WebsiteVendor.funeralinnovations),
    t.literal(WebsiteVendor.gather),
    t.literal(WebsiteVendor.funeralone),
]);

// ---> WebsiteCreateRequest <---
const WebsiteCreateRequestType = t.type({
    token: t.string,
    vendor: VENDOR_TYPE,
    url: t.string,
    ga4_measurement_id: t.union([t.string, t.null]),
    launched_time: t.union([DateFromISOString, t.null]),
    funeralHomeIds: t.array(t.number),
    block_subscriptions: t.boolean,
});
export interface WebsiteCreateRequest extends t.TypeOf<typeof WebsiteCreateRequestType> {
    vendor: WebsiteVendor;
}
export class WebsiteCreateRequest {
    public static fromRequest = getValidator<WebsiteCreateRequest>(WebsiteCreateRequestType);
}

const WebsiteUpdateRequestType = t.partial({
    token: t.string,
    vendor: VENDOR_TYPE,
    ga4_measurement_id: t.string,
    launched_time: t.union([DateFromISOString, t.null]),
    url: t.string,
    funeralHomeIds: t.array(t.number),
    block_subscriptions: t.boolean,
});
export interface WebsiteUpdateRequest extends t.TypeOf<typeof WebsiteUpdateRequestType> {
    vendor?: WebsiteVendor;
}
export class WebsiteUpdateRequest {
    public static fromRequest = getValidator<WebsiteUpdateRequest>(WebsiteUpdateRequestType);
}

/*  ***********************
 *  Website Subscriptions
 *  ***********************
 */ 

const SubscriptionRecordDefinition = {
    uuid: t.string,
    name: t.string,
    email: t.string,
};
const SubscriptionRecordType = t.type(SubscriptionRecordDefinition);

export interface SubscriptionRecord extends t.TypeOf<typeof SubscriptionRecordType> {

}
export class SubscriptionRecord {
    public static fromRequest = getValidator<SubscriptionRecord>(SubscriptionRecordType);
}

/**
 * ObitSubscriptions
 */

export interface ObitSubscriptionRecord extends t.TypeOf<typeof SubscriptionRecordType>{
    website_id: number;
    created_time: Date;
    opt_in_time: Date | null;
    opt_in_ip: string | null;
    opt_out_time: Date | null;
    opt_out_ip: string | null;
    last_failed_time: Date | null;
    last_failed_msg: string | null;
    consecutive_failed_msg_count: number;
    total_success_msg_count: number;
}

/**
 * ObitSubscription Create Request
 */

const ObitSubscriptionCreateRequestDefinition = {
    name: t.union([t.string,t.null]),
    email: t.string,
    websiteToken: t.string,
};
const ObitSubscriptionCreateRequestType = t.type(ObitSubscriptionCreateRequestDefinition);

export interface ObitSubscriptionCreateRequest extends t.TypeOf<typeof ObitSubscriptionCreateRequestType> {

}
export class ObitSubscriptionCreateRequest {
    public static fromRequest = getValidator<ObitSubscriptionCreateRequest>(ObitSubscriptionCreateRequestType);
}

/**
 * FuneralHomeObitSubscription
 */
export interface FuneralHomeObitSubscription extends ObitSubscriptionRecord {
    funeral_home_id: number;
}