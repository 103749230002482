import { convertHexToRGBA } from '../../../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { ORANGE_COLOR } from '../../../../constants/colorVariables';

export const MAX_HEIGHT = 610;

export function styleWrapper<Props extends object>() {
    const styles: StyleRulesCallback<Theme, Props, Classes> = (theme) => ({
        parentRoot: {
            padding: '40px 0 12px',
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 40%,` +
                `${convertHexToRGBA(theme.palette.primary.main, 0.22)} 100%)`,
        },
        root: {
            display: 'flex',
            transition: 'all 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            width: '100%',
            height: MAX_HEIGHT,
            '&$noPhotos, &$onePhoto': {
                height: 480
            },
            '&$twoPhotos': {
                height: 500
            },
            '& .ReactVirtualized__Collection__innerScrollContainer': {
                margin: '0 auto',
                position: 'relative',
                overflow: 'visible !important',
            },
            '&$noPhotos .ReactVirtualized__Collection__innerScrollContainer': {
                height: '500px !important',
            },
            '&$onePhoto .ReactVirtualized__Collection__innerScrollContainer': {
                height: '500px !important',
            },
            '&$twoPhotos .ReactVirtualized__Collection__innerScrollContainer': {
                height: '500px !important',
            },
            '& .ReactVirtualized__Collection': {
                outline: 'none',
                overflow: 'auto hidden !important',
                '&::-webkit-scrollbar': { // for chrome
                    display: 'none'
                },
                msOverflowStyle: 'none',  // for IE
                scrollbarWidth: 'none'    // for firefox
            }

        },
        initStateContainer: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 410,
            '@media (min-width: 960px)': {
                minHeight: 430,
            },
        },
        flexColumnCentered: {
            display: 'flex',
            flexDirection: 'column',
            placeItems: 'center'
        },
        photoFrame: {
            borderRadius: 4,
            background: theme.palette.primary.main,
            minWidth: 200,
            minHeight: 200,
            maxHeight: 200,
            position: 'absolute',
            top: 104,
            cursor: 'pointer',
            boxShadow: '0 2px 8px 2px rgb(0 0 0 / 35%), -60px 44px 64px rgb(0 0 0 / 15%), 0 4px 44px rgb(0 0 0 / 25%)',
            border: '2px solid #fff',
            '&:hover': {
                background: theme.palette.primary.dark
            },
            '@media (min-width: 960px)': {
                top: 156
            },
            '&$isPendingPhoto, &$hasImage': {
                boxShadow: 'none',
                border: 'none'
            },
            '& img': {
                height: 196,
                width: 196,
                objectFit: 'cover',
            },
            '& svg': {
                color: convertHexToRGBA(theme.palette.common.white, 0.6),
                fontSize: 196
            }
        },
        photo: {
            border: `2px solid ${theme.palette.common.white}`,
            backgroundColor: theme.palette.common.white,
            display: 'block',
            objectFit: 'cover',
            boxShadow: `0px 2px 8px 2px rgba(0, 0, 0, 0.35), -60px 44px 64px rgba(0, 0, 0, 0.25), ` +
                `0px 4px 44px rgba(0, 0, 0, 0.25)`,
            cursor: 'pointer',
        },
        photo1: {
            position: 'relative',
            '& $photo': {
                maxWidth: 200,
                maxHeight: 200,
            },
            '& $imgContainer': {
                top: 224,
            }
        },
        photo2: {
            '& $photo': {
                maxWidth: 193,
                maxHeight: 274,
            },
            '& $imgContainer': {
                top: 102,
            }
        },
        photo3: {
            '& $photo': {
                maxWidth: 200,
                maxHeight: 170,
            },
            '& $imgContainer': {
                top: 360,
            }
        },
        photo4: {
            '& $photo': {
                maxWidth: 254,
                maxHeight: 172,
            },
            '& $imgContainer': {
                top: 133,
            }
        },
        photo5: {
            '& $photo': {
                maxWidth: 241,
                maxHeight: 314,
            },
            '& $imgContainer': {
                top: 153,
            }
        },
        photo6: {
            '& $photo': {
                maxWidth: 217,
                maxHeight: 211,
            },
            '& $imgContainer': {
                top: 126,
            }
        },
        photo7: {
            '& $photo': {
                maxWidth: 195,
                maxHeight: 149,
            },
            '& $imgContainer': {
                top: 380,
            }
        },
        photo8: {
            '& $photo': {
                maxWidth: 205,
                maxHeight: 267,
            },
            '& $imgContainer': {
                top: 205,
            }
        },
        fitHeight: {
            height: 'fit-content'
        },
        transitionGroup: {
            display: 'flex',
            zIndex: 1,
        },
        backgroundNone: {
            background: 'none !important'
        },
        frameContainer: {
            paddingTop: 16,
            transform: 'translateX(-20%)',
            zIndex: 2,
            position: 'relative',
            height: 'fit-content',
            minWidth: 52,
            '&$onePhoto': {
                padding: 0,
                '& $photoString': {
                    height: 124,
                },
                '& $photoFrame': {
                    top: 194,
                },
                '@media (min-width: 960px)': {
                    padding: 0,
                    transform: 'translateY(0)',
                }
            },
            '&$twoPhotos': {
                transform: 'translateX(0)'
            },
            '@media (min-width: 425px)': {
            },
            '@media (min-width: 960px)': {
                transform: 'translateY(12%)',
            },
            '& $photoString': {
                height: 70,
                '@media (min-width: 960px)': {
                    height: 122
                }
            },
        },
        userAvatar: {
            transform: 'translateY(2px)',
            zIndex: 2,
            width: 52,
            height: 52,
            overflow: 'visible'
        },
        pointerEventsVisible: {
            pointerEvents: 'visible'
        },
        borderSolidOrange: {
            border: `2px solid ${ORANGE_COLOR}`
        },
        imgContainer: {
            position: 'absolute',
        },
        bottomChipButton: {
            top: '100%',
            borderRadius: 20,
            position: 'absolute',
            padding: '2px 16px',
            minHeight: 26,
            fontWeight: 400,
            zIndex: 20,
            transform: 'translate(50%, -50%)',
            right: '50%',
            background: ORANGE_COLOR,
            width: 'max-content',
            color: theme.palette.common.white,
            boxSizing: 'border-box',
            textTransform: 'uppercase',
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'auto'
        },
        hasImage: {},
        isPendingPhoto: {},
        rememberText: {},
        noPhotos: {},
        onePhoto: {},
        twoPhotos: {},
        threeOrMorePhotos: {},
        photoString: {},
        largerHeight: {},
        transitionClass: {},
    });

    return styles;
}

type Classes = 'root' | 'initStateContainer'
    | 'flexColumnCentered' | 'photoFrame' | 'photo1' | 'photo2' | 'photo3' | 'photo4' | 'photo5' | 'photo6'
    | 'photo7' | 'photo8' | 'photo' | 'userAvatar' | 'fitHeight' | 'noPhotos' | 'imgContainer' | 'bottomChipButton'
    | 'transitionClass' | 'transitionGroup' | 'largerHeight' | 'photoString' | 'imgContainer' | 'isPendingPhoto'
    | 'backgroundNone' | 'frameContainer' | 'parentRoot' | 'onePhoto' | 'twoPhotos' | 'hasImage'
    | 'threeOrMorePhotos' | 'pointerEventsVisible' | 'rememberText' | 'borderSolidOrange';
export type StyledProps = WithStyles<Classes>;
