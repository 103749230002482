import * as constants from '../constants';

import {
    ADD_TASK,
    DELETE_TASK,
    SENDING_TASK_REMINDER,
    SET_TASK_SAVING,
    TASKS_LOADED,
    SET_TASKS_LOADING,
    UPDATE_TASK,
    BULK_UPDATE_CHECKLIST_TASKS,
    UPDATED_TASK_RANKS,
    TASK_LOADED,
    CREATING_MOVE_STEP,
} from '../actions/task/Task.action';

import { TasksState } from '../types';

import {
    CASE_UPDATED,
    LOADED_PUBLIC_CASE,
    ORGANIZE_PAGE_LOADED,
    TRACKING_PAGE_LOADED,
} from '../actions/GatherCase.action';
import { GatherAction } from '../actions';
import {
    FAILED_LOAD_CASE_SWITCHER_DATA,
    LOADED_CASE_SWITCHER_DATA,
    LOADING_CASE_SWITCHER_DATA,
} from '../actions/FuneralHome.action';
import { FAILED_LOAD_TASK_PREVIEWS, LOADED_TASK_PREVIEWS, LOADING_TASK_PREVIEWS } from '../actions/Team.action';
import { replaceStringTemplates } from '../shared/utils';
import {
    TaskType,
} from '../shared/types';
import partition from 'lodash/partition';

export const taskInitData: TasksState = {
    publicTasks: [],
    checklistTasks: [],
    trackingSteps: [],
    taskPreviews: [],
    taskPreviewsLoading: false,
    isSaving: false,
    saveTaskOpen: false,
    isLoading: false,
    sendingTaskReminder: false,
};

export const tasksState = (
    state: TasksState = taskInitData,
    action: GatherAction,
): TasksState => {

    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return taskInitData;
        case SET_TASK_SAVING: {
            return {
                ...state,
                isSaving: action.isSaving,
            };
        }
        case CREATING_MOVE_STEP: {
            return {
                ...state,
                isSaving: true,
            };
        }
        case ADD_TASK: {
            if (action.task.type === TaskType.checklist_task) {
                return {
                    ...state,
                    checklistTasks: [
                        ...state.checklistTasks,
                        action.task,
                    ],
                    isSaving: false,
                };
            } else {
                return {
                    ...state,
                    trackingSteps: [
                        ...state.trackingSteps,
                        action.task,
                    ],
                    isSaving: false,
                };
            }
        }
        case CASE_UPDATED: 
        case ORGANIZE_PAGE_LOADED:
        case TRACKING_PAGE_LOADED:
        case TASKS_LOADED: {
            if (!action.tasks) {
                return state;
            }
            const [checklistTasks, trackingSteps] = partition(action.tasks, (t) => t.type === TaskType.checklist_task);
            return {
                ...state,
                checklistTasks,
                trackingSteps,
            };
        }
        case TASK_LOADED: {
            return {
                ...state,
                trackingSteps: state.trackingSteps.map((t) => t.id === action.task.id ? action.task : t),
                checklistTasks: state.checklistTasks.map((t) => t.id === action.task.id ? action.task : t),
            };
        }
        case UPDATE_TASK: {

            let updatedTaskPreviews = state.taskPreviews;
            if (action.taskChanges.marked_complete_time || action.taskChanges.skipped_time) {
                // if skipped/completed remove this task from the taskPreviews
                updatedTaskPreviews = state.taskPreviews.filter((t) => t.task_id !== action.taskId);
            }
            return {
                ...state,
                trackingSteps: state.trackingSteps.map((task) => {
                    if (task.id === action.taskId) {
                        return {
                            ...task,
                            ...action.taskChanges,
                        };
                    }
                    return task;
                }),
                checklistTasks: state.checklistTasks.map((task) => {
                    if (task.id === action.taskId) {
                        return {
                            ...task,
                            ...action.taskChanges,
                        };
                    }
                    return task;
                }),
                taskPreviews: updatedTaskPreviews,
            };
        }
        case BULK_UPDATE_CHECKLIST_TASKS: {
            return {
                ...state,
                checklistTasks: state.checklistTasks.map((task) => {
                    if (action.tasks.some(t => t.id === task.id)) {
                        return {
                            ...task,
                            ...action.taskChanges,
                        };
                    }
                    return task;
                }),
            };
        }
        case DELETE_TASK: {
            return {
                ...state,
                checklistTasks: state.checklistTasks.filter((task) => task.id !== action.taskId),
                trackingSteps: state.trackingSteps.filter((task) => task.id !== action.taskId),
            };
        }
        case SET_TASKS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case SENDING_TASK_REMINDER: {
            return {
                ...state,
                sendingTaskReminder: action.sendingTaskReminder,
            };
        }
        case UPDATED_TASK_RANKS: {
            if (action.taskType === TaskType.checklist_task) {
                return {
                    ...state,
                    checklistTasks: action.updatedTasks,
                };
            } else {
                return {
                    ...state,
                    trackingSteps: action.updatedTasks,
                };
            }
        }
        case LOADED_PUBLIC_CASE: {
            return {
                ...state,
                publicTasks: action.publicTasks,
            };
        }
        case LOADED_TASK_PREVIEWS:
        case LOADED_CASE_SWITCHER_DATA: {
            return {
                ...state,
                taskPreviewsLoading: false,
                taskPreviews: !action.taskPreviews
                    ? state.taskPreviews
                    : action.taskPreviews.map((t) => ({
                        ...t,
                        task_title: replaceStringTemplates(t.task_title, {
                            case: { fname: t.case_fname }
                        }),
                    })),
            };
        }
        case LOADING_TASK_PREVIEWS:
        case LOADING_CASE_SWITCHER_DATA: {
            return {
                ...state,
                taskPreviewsLoading: true,
            };
        }
        case FAILED_LOAD_TASK_PREVIEWS:
        case FAILED_LOAD_CASE_SWITCHER_DATA: {
            return {
                ...state,
                taskPreviewsLoading: false,
            };
        }
        default:
            return state;
    }
};
