import { Component } from 'react';
import { Theme } from '@mui/material/styles';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import Hidden from '@mui/material/Hidden';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { GRAY_COLOR_3 } from '../../../../../constants/colorVariables';
import { FH_LogoType } from '../../../../../shared/types';
import { isIOSDevice } from '../../../../../services';
import FuneralHomeLogo from '../../../../common/FuneralHomeLogo';
import { FadeTransition } from '../../../../common/Transitions';
import withFullScreen from '../../../../common/utilHOC/WithFullScreen';

const CANDLE_LIGHTING_BLACK = '#020200';
const MUTED_WHITE = 'rgba(255, 255, 255, 0.85)';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            backgroundColor: CANDLE_LIGHTING_BLACK,
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            backdropFilter: 'blur(60px)',
            borderRadius: 0,
            height: '100%'
        },
        color: MUTED_WHITE
    },
    dialogHeader: {
        zIndex: 1,
        padding: 0,
        minHeight: 64,
        '& h6': {
            margin: '8px 4px'
        }
    },
    dialogContent: {
        zIndex: 0,
        display: 'flex',
        padding: 0,
        overflowX: 'hidden',
        minHeight: 120,
        paddingTop: '0px !important',
    },
    header: {
        zIndex: 1,
        padding: 0,
        textAlign: 'center',
        minHeight: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerContent: {
        margin: '4px 8px'
    },
    headerIconButton: {
        width: 48,
        height: 48,
        position: 'fixed',
        top: 0,
        left: 0,
        '& svg': {
            fontSize: 32
        },
        color: MUTED_WHITE
    },
    formControl: {
        height: 'calc(100% - 48px)',
        width: '100%'
    },
    memorySubmitButton: {
        color: MUTED_WHITE,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        fontSize: 14,
        width: 'fit-content',
        minHeight: 'unset',
        margin: '0 auto',
        transform: 'translateY(-30%)',
        '& svg': {
            fontSize: 16
        },
    },
    disabledButton: {
        background: `${GRAY_COLOR_3} !important`,
        color: 'rgba(255, 255, 255, 0.2) !important'
    },
    dialogTitle: {
        color: MUTED_WHITE,
        fontWeight: 200,
        fontSize: 28,
        lineHeight: '40px',
        maxWidth: 720,
        margin: '0 auto',
        '@media (min-width: 600px)': {
            fontSize: 34
        }
    },
    memoryInput: {
        color: MUTED_WHITE,
        width: '90%',
        maxWidth: 500,
        margin: '0 auto',
    },
    memoryTextArea: {
        width: '400px'
    },
    multiline: {
        height: '100%',
        padding: '12px 14px',
        '& div, textarea': {
            color: MUTED_WHITE,
            height: 'inherit !important'
        }
    },
    video: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 48,
        '@media (min-width: 414px)': {
            top: 0
        }
    },
    lightCandleButton: {
        backgroundColor: CANDLE_LIGHTING_BLACK,
        border: `0.75px solid ${MUTED_WHITE} !important`,
        padding: '10px 40px',
        color: MUTED_WHITE,
        fontWeight: 150,
        fontSize: 16,
        borderRadius: 16,
        margin: '75px auto 0 auto',
        maxHeight: '48px',
        maxWidth: '365px',
        '@media (min-width: 600px)': {
            fontSize: 22
        }
    },
    textFieldLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: `${MUTED_WHITE} !important`,
        '&[data-shrink=false]': {
            transform: 'translate(14px, 14px) scale(1) !important',
        },
    },
    candleButtonContainer: {
        width: '100%',
        textAlign: 'center',
        paddingTop: 48
    },
    memorySection: {
        width: '100%',
        textAlign: 'center',
        position: 'relative'
    },
    fhLogo: {
        position: 'fixed',
        bottom: 12,
        left: 12,
    },
    notchedOutline: {
        borderColor: `${MUTED_WHITE} !important`,
        borderRadius: 16
    },
    dialogPaper: {}
});

type StyledProps = WithStyles<
    'root' | 'header' | 'headerIconButton' | 'dialogContent' | 'memorySubmitButton' | 'dialogPaper' | 'dialogTitle' |
    'memoryInput' | 'memorySection' | 'headerContent' | 'video' | 'disabledButton' | 'multiline' | 'formControl' |
    'lightCandleButton' | 'textFieldLabel' | 'candleButtonContainer' | 'fhLogo' | 'notchedOutline'
>;

interface DialogProps {
    fullScreen: boolean;
}

type Props = {
    zIndex: number;
    caseDisplayFname: string;
    showDialog: boolean;
    isSaving: boolean;
    submitMemory: (memory: string) => Promise<void>;
    closeDialog: () => void;
};

interface State {
    lightCandleButtonClicked: boolean;
    showMemoryTextField: boolean;
    memoryText: string;
    saveAttempted: boolean;
    isSaving: boolean;
}

const DEFAULT_STATE: State = {
    lightCandleButtonClicked: false,
    showMemoryTextField: false,
    memoryText: '',
    saveAttempted: false,
    isSaving: false
};

type CombinedProps = Props & DialogProps & StyledProps;

class CandleMessageDialog extends Component<CombinedProps, State> {
    state: State = {
        ...DEFAULT_STATE
    };

    private _showInputTimeout: ReturnType<typeof setTimeout>;

    componentDidUpdate(prevProps: Props) {
        const { showDialog } = this.props;
        if (prevProps.showDialog === true && showDialog === false) {
            clearTimeout(this._showInputTimeout);
            this.setState(DEFAULT_STATE);
        }
    }

    componentWillUnmount() {
        clearTimeout(this._showInputTimeout);
    }

    render() {
        const {
            showDialog,
            zIndex,
            classes,
            caseDisplayFname,
            closeDialog,
            submitMemory
        } = this.props;
        const {
            lightCandleButtonClicked,
            showMemoryTextField,
            memoryText,
            saveAttempted,
            isSaving
        } = this.state;

        return (
            <Dialog
                fullScreen
                open={showDialog}
                TransitionComponent={FadeTransition}
                transitionDuration={400}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                BackdropProps={{ invisible: true }}
                style={{ zIndex: zIndex + 1 }}
                scroll="body"
            >
                <DialogContent className={classes.dialogContent}>
                    <IconButton
                        onClick={() => {
                            if (lightCandleButtonClicked) {
                                submitMemory('');
                            }
                            closeDialog();
                        }}
                        className={classes.headerIconButton}
                        style={{ zIndex: zIndex + 1 }}
                        size="large">
                        <ArrowBackIcon />
                    </IconButton>
                    {!lightCandleButtonClicked &&
                        <Grid
                            item
                            xs={12}
                            container
                            direction="column"
                            style={{ zIndex }}
                            className={classes.candleButtonContainer}
                        >
                            <Typography className={classes.dialogTitle}>
                                {`Take a moment to think about the light ${caseDisplayFname} `}
                                brought into the world.
                            </Typography>
                            <Button
                                className={classes.lightCandleButton}
                                onClick={() => this._handleShowButtonClick()}
                                variant="outlined"
                            >
                                Click to Light a Candle
                            </Button>
                        </Grid>}
                    {lightCandleButtonClicked &&
                        <video
                            controls={false}
                            autoPlay
                            loop={false}
                            muted
                            playsInline
                            className={classes.video}
                        >
                            {/* checking with `only` prop is faster and 
                                it is relevant here as we want to hide till 600px */}
                            <Hidden only={['xs']}>
                                <source src="/static/images/candle_lighting.m4v" type="video/mp4" />
                            </Hidden>
                            <Hidden smUp>
                                {/* #t=0.1 is enforcing the start_time of video to 0.1s, 
                                    we can't set t=0 because it has not effect on iOS */}
                                <source
                                    src={`/static/images/candle_lighting_mobile.m4v${isIOSDevice() ? '#t=0.1' : ''}`}
                                    type="video/mp4"
                                />
                            </Hidden>
                        </video>}
                    <Fade
                        in={showMemoryTextField}
                        timeout={300}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            className={classes.memorySection}
                            style={{ zIndex }}
                        >
                            <FormControl className={classes.formControl}>
                                <TextField
                                    type="text"
                                    className={classes.memoryInput}
                                    value={memoryText}
                                    onChange={memory => this.setState({ memoryText: memory.target.value })}
                                    label="Share a message with your candle here..."
                                    multiline
                                    margin="dense"
                                    variant="outlined"
                                    minRows={5}
                                    maxRows={10}
                                    error={saveAttempted && !memoryText.trim()}
                                    onFocus={() => this.setState({ saveAttempted: false })}
                                    InputLabelProps={{ className: classes.textFieldLabel }}
                                    InputProps={{
                                        classes: {
                                            multiline: classes.multiline,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                />
                                <Button
                                    color="primary"
                                    className={classes.memorySubmitButton}
                                    onClick={() => {
                                        this.setState({ saveAttempted: true });
                                        submitMemory(memoryText.trim());
                                        closeDialog();
                                    }}
                                    variant="contained"
                                    classes={{
                                        disabled: classes.disabledButton
                                    }}
                                    disabled={isSaving}
                                >
                                    <FavoriteIcon />
                                    Click To Share
                                </Button>
                            </FormControl>
                        </Grid>
                    </Fade>

                    <FuneralHomeLogo
                        logoType={FH_LogoType.themeLogo}
                        className={classes.fhLogo}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    private _handleShowButtonClick = () => {
        this.setState({ lightCandleButtonClicked: true });

        this._showInputTimeout = setTimeout(
            () => this.setState({ showMemoryTextField: true }), 10000
        );
    };
}

export default withFullScreen()(withStyles(styles)(CandleMessageDialog));
