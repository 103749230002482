import Typography from '@mui/material/Typography';
import makeGStyles from '../../../../../styles/makeGStyles';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import StepComponentWarning from '../activeStep/common/StepComponentWarning';
import StepComponentFooter from '../activeStep/common/StepComponentFooter';
import { useEffect, useState } from 'react';

const useStyles = makeGStyles<Props>(theme => ({
    content: {
        marginBottom: 40,
        padding: '24px 20px 0'
    },
    description: {
        fontSize: 16,
        lineHeight: 1.25,
        margin: '28px 0',
    },
    setSize: {
        paddingTop: 4,
        marginBottom: 12,
        '& p': {
            fontSize: 13,
            lineHeight: 1.25,
            padding: '0 16px',
            '@media (min-width: 480px)': {
                padding: '0 38px',
            },
        }
    },
    downShiftMargin: {
        marginTop: -16
    },
    popper: {
        zIndex: props => props.zIndex + 1
    },
    endAndornment: {
        top: 'unset',
    },
    paddingRight100: {
        paddingRight: '100px !important'
    }
}), { name: 'SavePrerequisitesDialogContent' });

export interface PrereqSummary {
    key: number;
    label: string;
}

export interface Props {
    workflowName: string;
    availablePrereqs: PrereqSummary[];
    selectedKeys: number[];
    zIndex: number;
    hideWarning?: boolean;
    onSave: (selectedKeys: number[]) => void;
    onClose: () => void;
}

const SavePrerequisitesDialogContent = (props: Props) => {
    const {
        workflowName,
        availablePrereqs,
        selectedKeys,
        hideWarning,
        onSave,
        onClose,
    } = props;

    const classes = useStyles(props);

    const [selectedPrereqs, setSelectedPrereqs] = useState<PrereqSummary[]>([]);

    useEffect(() => {
        setSelectedPrereqs(availablePrereqs.filter((available) => selectedKeys.some((key) => key === available.key)));
    }, [selectedKeys, availablePrereqs]);

    const handleSaveClick = () => {
        onSave(selectedPrereqs.map((ps) => ps.key));
    };

    return (
        <>
            <Grid item xs={12} className={classes.content}>

                <Typography align="center" color="primary" className={classes.description}>
                    Ensure things are done in the proper order by requiring that other steps
                    are completed prior to this step. Optional.
                </Typography>

                <div className={classes.downShiftMargin}>
                    <Autocomplete
                        multiple
                        options={availablePrereqs}
                        onChange={(_, newSelected) => setSelectedPrereqs(newSelected)}
                        value={selectedPrereqs}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        renderOption={(_props, option, { selected }) => (
                            <li {..._props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        )}
                        classes={{
                            popper: classes.popper,
                            endAdornment: classes.endAndornment,
                            inputRoot: classes.paddingRight100,
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Prerequisites"
                                placeholder="Search existing steps..."
                            />
                        )}
                    />
                </div>

                <div className={classes.setSize}>
                    <Typography align="center" color="primary" className={hideWarning ? classes.fontSize14 : undefined}>
                        Only steps that are added to the "{workflowName}" WorkFlow will be visible here.
                        If you want an additional prerequisite step, first add it to the WorkFlow.
                    </Typography>
                </div>


                {!hideWarning &&
                    <StepComponentWarning
                        text="Changes made will affect ONLY this WorkFlow even if this step is linked with other 
                        WorkFlows. Changes will NOT affect previously completed steps on existing
                        cases, but you will see the changes going forward."
                    />
                }
            </Grid>
            <StepComponentFooter
                onSaveClick={handleSaveClick}
                onClose={onClose}
            />
        </>
    );
};

export default SavePrerequisitesDialogContent;
