import * as React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import * as appService from '../../../services/app.service';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';

import { StoreState } from '../../../types';

import MyDetailsForm from './MyDetailsForm';
import SettingsForm from './SettingsForm';
import {
    UserRole,
    PhotoTransformationsType,
    LongAddress,
    NullLongAddress,
    UserRoles,
    EntityRelationship,
    EntityRelationshipType,
    EntityCaseRole,
    CaseHelperUpdateRequest,
    getCaseEntity,
    EntitySummary,
    getFuneralHomeId, isEntityUpdateValidateError, EntityUpdateValidateErrorEnum, FeatureKey, getGatherCase,
} from '../../../shared/types';
import { isValidPhoneNumber, isValidEmail } from '../../../shared/utils';
import {
    logoutUserSession,
    setFeaturesOverride,
} from '../../../actions/UserSession.action';
import { updateCaseHelper, updateCaseHelperPhoto } from '../../../actions/CaseHelper.action';
import { canEditCasePermissions, canEditPersonPhoneOrEmail } from '../../../shared/authority/can';
import { updateTeamMemberPhoto } from '../../../actions/Team.action';
import { MySettingsTab } from '.';
import { compose } from 'redux';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { AppDispatch } from '../../../store';
import { log } from '../../../logger';
import QRPermissionsForHelpers from '../../invitation/QRPermissionsForHelpers';
import { isEnabled, WhenEnabled } from '../../common/AppFeature';
import RelationshipAndCommunicationSettings from './RelationshipAndCommunicationSettings';
import SelectPartner from '../../assignmentPoppers/SelectPartner';
import {
    EntitySubscriptionInsertRequest, EntitySubscriptionUpdateRequest, NotificationMethod, SubscriptionType
} from '../../../shared/types/subscription';
import generateUuid from 'uuid';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    tabRoot: {
        textTransform: 'initial',
        minWidth: 140,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 1,
        margin: '8px 8px 4px',
        minHeight: 30,
        fontSize: 18,
        display: 'block',
        padding: '12px 16px 4px 16px',
    },
    tabAppbar: {
        background: theme.palette.background.paper,
        boxShadow: 'none',
        margin: '5px 0 0',
    },
    tabsFlexContainer: {
        justifyContent: 'center',
    },
    tabsScrollableContainer: {
        overflow: 'auto',
        overflowX: 'auto',
    },
    footer: {
        left: 0,
        bottom: 0,
        width: '100%',
        padding: 8,
        maxWidth: 760,
        margin: '96px auto 0'
    },
    button: {
        margin: theme.spacing(),
    },
    tabsRoot: {
        minHeight: 68
    },
    tabContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '@media (min-width: 768px)': {
            flexDirection: 'row'
        }
    },
    relationshipTab: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 332,
        flexDirection: 'column',
        alignItems: 'center',
    },
    relationshipContent: {
        width: '100%',
        padding: '0 16px',
        maxWidth: 300,
        textAlign: 'center',
        position: 'relative'
    },
    tabsFlexContainerFhUser: {
        '@media (min-width: 520px)': {
            justifyContent: 'center'
        }
    },
    userDetails: {
        maxWidth: 760,
        margin: 'auto'
    },
    margin0: {
        margin: 0
    },
    bottomUnset: {
        bottom: 'unset',
    },
    permissionsContent: {
        maxWidth: 400,
        padding: '0 16px',
        textAlign: 'center',
    },
    permissionsTab: {
        display: 'flex',
        justifyContent: 'center',
        '& $header': {
            fontSize: 18,
            color: theme.palette.primary.main,
        },
        '& $subheading': {
            fontSize: 12,
        },
    },
    header: {},
    subheading: {},
});

type StyledProps = Props & WithGStyles<'root' | 'tabRoot' | 'tabAppbar'
    | 'tabsFlexContainer' | 'tabsScrollableContainer' | 'footer' | 'button' | 'tabsRoot' | 'tabContainer'
    | 'relationshipTab' | 'relationshipContent' | 'tabsFlexContainerFhUser'
    | 'userDetails' | 'margin0' | 'bottomUnset' | 'permissionsContent' | 'permissionsTab' | 'header'
    | 'subheading'>;

function mapStateToProps({ userSession, dialogState, casesState, funeralHomeState }: StoreState) {
    const { mySettings } = dialogState;
    const { userData } = userSession;
    const { selectedCase, publicCase } = casesState;

    const gatherCase = getGatherCase({
        selectedCase,
        publicCase,
    });
    return {
        loggedInUser: userData,
        isUserPhotoSaving: userSession.isUserPhotoSaving,
        isViewAsGOM: appService.isViewAsGOM(userSession),
        isGOMUser: UserRoles.isGOMUser(userData),
        fhName: gatherCase?.funeral_home.name,
        fhCity: gatherCase?.funeral_home.city,
        caseFName: gatherCase?.fname || '',
        selectedCase,
        zIndex: mySettings.zIndex,
        tab: mySettings.tab,
        funeralHomeId: getFuneralHomeId(funeralHomeState.activeFuneralHome, selectedCase, publicCase),
        gatherCaseId: gatherCase?.id ?? null,
        gatherCaseName: gatherCase?.name ?? null,
        caseUuid: gatherCase?.uuid,
        callback: mySettings.callback,
        isKeepTrackEnabled: isEnabled(FeatureKey.KEEP_TRACK, funeralHomeState, casesState, userSession),
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    selectedEntity: EntitySummary;
    onClose: (params: { confirm: boolean }) => void;
}

export const EntityTypeLookup = {
    [EntityCaseRole.admin]: 'ADMIN',
    [EntityCaseRole.guest]: 'GUEST',
};

export enum HelperUserSettingsTab {
    details = 'details',
    relationship = 'relationship',
    permissions = 'permissions',
}

const isHelperSettingTab = (tab: MySettingsTab | HelperUserSettingsTab | null): tab is HelperUserSettingsTab => {
    if (!tab) {
        return false;
    }

    return Boolean(HelperUserSettingsTab[tab]);
};

interface State {
    selectedTab: HelperUserSettingsTab;
    isSaving: boolean;
    targetEntity: EntitySummary | null;
    fname: string;
    mname: string | null;
    lname: string;
    email: string;
    phone: string;
    homePhone: string;
    workPhone: string;
    role: UserRole | null;
    saveAttempted: boolean;
    stateLicenseNumber: string;
    homeAddress: LongAddress;
    useAddressDescription: boolean;
    entityCaseRole: EntityCaseRole | null;
    partnerCaseEntityId: number | null;
    relationshipType: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationshipAlias: string | null;
    entityValidateError: EntityUpdateValidateErrorEnum | null;
    canViewQRTracking: boolean;
    canViewCaseBelongings: boolean;
    subNotifyCaseServiceReminderViaEmail: boolean;
    subNotifyCaseServiceReminderViaSms: boolean;
    subNotifyCaseNewMemoriesViaEmail: boolean;
    subNotifyCaseNewMemoriesViaSms: boolean;
}

type CombinedProps = StyledProps & InjectedProps;

class HelperUserSettings extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        const { tab, selectedEntity, gatherCaseId } = this.props;
        const caseEntity = gatherCaseId && selectedEntity
            ? getCaseEntity(selectedEntity, gatherCaseId) : null;

        const currentSubscriptions = caseEntity?.subscriptions || [];
        const subscriptionCaseNewMemories =
            currentSubscriptions.filter(s => s.subscription_type === SubscriptionType.case_new_memories);
        const subNotifyCaseNewMemoriesViaEmail =
            !!(subscriptionCaseNewMemories.find(s =>
                s.notification_method === NotificationMethod.email)?.deleted_time === null);
        const subNotifyCaseNewMemoriesViaSms =
            !!(subscriptionCaseNewMemories.find(s =>
                s.notification_method === NotificationMethod.sms)?.deleted_time === null);

        const subscriptionCaseServiceReminders =
            currentSubscriptions.filter(s => s.subscription_type === SubscriptionType.case_service_reminder);
        const subNotifyCaseServiceReminderViaEmail =
            !!(subscriptionCaseServiceReminders.find(s =>
                s.notification_method === NotificationMethod.email)?.deleted_time === null);
        const subNotifyCaseServiceReminderViaSms =
            !!(subscriptionCaseServiceReminders.find(s =>
                s.notification_method === NotificationMethod.sms)?.deleted_time === null);


        const defaultQRPermissions = caseEntity?.case_role === EntityCaseRole.admin;

        this.state = {
            selectedTab: isHelperSettingTab(tab) ? tab : HelperUserSettingsTab.details,
            isSaving: false,
            targetEntity: selectedEntity || null,
            fname: selectedEntity && selectedEntity.fname || '',
            mname: selectedEntity && selectedEntity.mname || '',
            lname: selectedEntity && selectedEntity.lname || '',
            email: selectedEntity && selectedEntity.email || '',
            phone: selectedEntity && selectedEntity.phone || '',
            homePhone: selectedEntity && selectedEntity.home_phone || '',
            workPhone: selectedEntity && selectedEntity.work_phone || '',
            stateLicenseNumber: selectedEntity && selectedEntity.state_license_number || '',
            entityCaseRole: caseEntity && caseEntity.case_role,
            relationship: caseEntity && caseEntity.relationship,
            relationshipType: caseEntity && caseEntity.relationship_type,
            relationshipAlias: caseEntity && caseEntity.relationship_alias,
            role: selectedEntity && selectedEntity.user ? selectedEntity.user.role : null,
            homeAddress: selectedEntity && selectedEntity.home_address || { ...NullLongAddress },
            useAddressDescription: selectedEntity && selectedEntity.use_address_description || false,
            saveAttempted: false,
            entityValidateError: null,
            canViewQRTracking: caseEntity?.can_view_tracking_steps ?? defaultQRPermissions,
            canViewCaseBelongings: caseEntity?.can_view_belongings ?? defaultQRPermissions,
            partnerCaseEntityId: caseEntity?.partner_case_entity_id ?? null,
            subNotifyCaseNewMemoriesViaEmail,
            subNotifyCaseNewMemoriesViaSms,
            subNotifyCaseServiceReminderViaEmail,
            subNotifyCaseServiceReminderViaSms,
        };
    }

    componentDidUpdate(prevProps: Readonly<CombinedProps>) {
        if (this.props.tab && this.props.tab !== prevProps.tab) {

            this.setState(prevState => ({
                selectedTab: isHelperSettingTab(this.props.tab)
                    ? this.props.tab
                    : prevState.selectedTab
            }));
        }
    }

    logoutUserSession = () => this.props.dispatch(logoutUserSession());

    updateUserPhoto = async (
        entity: EntitySummary,
        photo: string,
        transformations: PhotoTransformationsType,
    ): Promise<void> => {
        const { dispatch, gatherCaseId, funeralHomeId, caseUuid } = this.props;
        const caseEntity = gatherCaseId && getCaseEntity(entity, gatherCaseId);
        let updatedEntity: EntitySummary | null = null;
        if (caseEntity && gatherCaseId && caseUuid) {
            updatedEntity =
                await dispatch(updateCaseHelperPhoto(entity, photo, transformations, caseUuid));
        } else if (UserRoles.isFHorGOMUser(entity)) {
            updatedEntity =
                await dispatch(updateTeamMemberPhoto(entity, photo, transformations, funeralHomeId));
        } else {
            console.warn('User is not a team member or a case helper');
            return;
        }

        const { targetEntity } = this.state;
        if (targetEntity && updatedEntity) {
            this.setState({
                targetEntity: {
                    ...targetEntity,
                    photo: updatedEntity.photo,
                    photo_transformations: updatedEntity.photo_transformations,
                    photo_view_id: updatedEntity.photo_view_id
                },
            });
        }
    };

    changeTab = (selectedTab: MySettingsTab) => {
        if (isHelperSettingTab(selectedTab)) {
            this.setState({
                selectedTab,
            });
        }
    };

    handleTabChange = (selectedTab: HelperUserSettingsTab) => {
        this.setState({ selectedTab });
    };

    handlePhoneFieldKeyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { phone } = this.state;

        if ((event.keyCode === 8 || event.which === 8) && phone && phone.length === 10 || phone.length === 5) {
            this.setState({
                phone: phone.slice(0, -1),
            });
        }
    };

    handleViewAsGOMChange = (isViewAsGOM: boolean) => {
        const { dispatch, isGOMUser } = this.props;

        if (!isGOMUser) {
            console.warn('Non-Gather user should not have access to features override');
            return;
        }

        dispatch(setFeaturesOverride(isViewAsGOM));
    };

    handleSetRelationship = (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string
    ) => {
        if (relationshipType && relationshipType === EntityRelationshipType.family
            && relationshipAlias && relationshipAlias.trim().length > 0) {
            relationship = EntityRelationship.other;
        }
        this.setState({
            relationshipType,
            relationship,
            relationshipAlias,
        });
    };

    // onSubscriptionChange
    handleSubscriptionChange = (args: {
        notify: boolean;
        notifyMethod: NotificationMethod;
        subscriptionType: SubscriptionType;
    }) => {
        const { notify, notifyMethod, subscriptionType } = args;
        if (subscriptionType === SubscriptionType.case_service_reminder
            && notifyMethod === NotificationMethod.email) {
            this.setState({ subNotifyCaseServiceReminderViaEmail: notify });
        } else if (subscriptionType === SubscriptionType.case_service_reminder
            && notifyMethod === NotificationMethod.sms) {
            this.setState({ subNotifyCaseServiceReminderViaSms: notify });
        } else if (subscriptionType === SubscriptionType.case_new_memories
            && notifyMethod === NotificationMethod.email) {
            this.setState({ subNotifyCaseNewMemoriesViaEmail: notify });
        } else if (subscriptionType === SubscriptionType.case_new_memories
            && notifyMethod === NotificationMethod.sms) {
            this.setState({ subNotifyCaseNewMemoriesViaSms: notify });
        }
    }

    closeDialog = () => {
        this.props.onClose({
            confirm: this.hasChanges(),
        });
    };

    saveSettings = async () => {
        const {
            selectedEntity,
            dispatch,
            loggedInUser,
            gatherCaseId,
            gatherCaseName,
            caseUuid,
            funeralHomeId,
            callback
        } = this.props;
        const {
            targetEntity,
            fname,
            mname,
            lname,
            email,
            phone,
            workPhone,
            homePhone,
            stateLicenseNumber,
            homeAddress,
            relationship,
            relationshipAlias,
            relationshipType,
            entityCaseRole,
            useAddressDescription,
            canViewQRTracking,
            canViewCaseBelongings,
            partnerCaseEntityId,
            subNotifyCaseNewMemoriesViaEmail,
            subNotifyCaseNewMemoriesViaSms,
            subNotifyCaseServiceReminderViaEmail,
            subNotifyCaseServiceReminderViaSms,
        } = this.state;

        if (!this.isEmailValid()
            || !this.isPhoneValid(phone)
            || !this.isPhoneValid(workPhone)
            || !this.isPhoneValid(homePhone)
            || !loggedInUser
            || (relationshipType && relationshipType === EntityRelationshipType.other
                && (!relationshipAlias || relationshipAlias.trim().length === 0))
        ) {
            this.setState({ saveAttempted: true });
            return;
        }

        this.setState({ isSaving: true, entityValidateError: null });
        if (targetEntity) {
            const cannotEditPhoneOrEmail = !canEditPersonPhoneOrEmail(
                loggedInUser,
                targetEntity,
                funeralHomeId,
                gatherCaseId,
            );

            const caseEntity = gatherCaseId !== null && getCaseEntity(targetEntity, gatherCaseId);

            if (caseEntity && caseUuid) {
                if (!gatherCaseName || funeralHomeId === null) {
                    log.warn('Somehow have caseEntity but no funeralHomeId or gatherCaseName', { caseEntity });
                    return;
                }
                const currentSubscriptions = caseEntity.subscriptions || [];
                const existingCaseEntitySubscriptions: EntitySubscriptionUpdateRequest[] = [];
                const newCaseEntitySubscriptions: EntitySubscriptionInsertRequest[] = [];

                const currentSubscriptionsForGatherCase = currentSubscriptions.filter(s => 
                    s.gather_case_id === gatherCaseId);

                const subNotificationCaseNewMemories = currentSubscriptionsForGatherCase.filter(s =>
                    s.subscription_type === SubscriptionType.case_new_memories);
                const subNotificationCaseNewMemoriesViaEmail = subNotificationCaseNewMemories.find(s =>
                    s.notification_method === NotificationMethod.email);
                const subNotificationCaseNewMemoriesViaSms = subNotificationCaseNewMemories.find(s =>
                    s.notification_method === NotificationMethod.sms);

                const subNotificationCaseServiceReminders = currentSubscriptionsForGatherCase.filter(s =>
                    s.subscription_type === SubscriptionType.case_service_reminder);
                const subNotificationCaseServiceRemindersViaEmail = subNotificationCaseServiceReminders.find(s =>
                    s.notification_method === NotificationMethod.email);
                const subNotificationCaseServiceRemindersViaSms = subNotificationCaseServiceReminders.find(s =>
                    s.notification_method === NotificationMethod.sms);

                if (subNotificationCaseNewMemoriesViaEmail) {
                    existingCaseEntitySubscriptions.push({
                        uuid: subNotificationCaseNewMemoriesViaEmail.uuid,
                        deleted_time: subNotifyCaseNewMemoriesViaEmail ? null : new Date(),
                    });
                } else {
                    newCaseEntitySubscriptions.push({
                        uuid: generateUuid(),
                        entity_id: targetEntity.entity_id,
                        notification_method: NotificationMethod.email,
                        deleted_time: subNotifyCaseNewMemoriesViaEmail ? null : new Date(),
                        subscription_details: {
                            subscription_type: SubscriptionType.case_new_memories,
                            gather_case_id: gatherCaseId,
                        }
                    });
                }

                if (subNotificationCaseNewMemoriesViaSms) {
                    existingCaseEntitySubscriptions.push({
                        uuid: subNotificationCaseNewMemoriesViaSms.uuid,
                        deleted_time: subNotifyCaseNewMemoriesViaSms ? null : new Date(),
                    });
                } else {
                    newCaseEntitySubscriptions.push({
                        uuid: generateUuid(),
                        entity_id: targetEntity.entity_id,
                        notification_method: NotificationMethod.sms,
                        deleted_time: subNotifyCaseNewMemoriesViaSms ? null : new Date(),
                        subscription_details: {
                            subscription_type: SubscriptionType.case_new_memories,
                            gather_case_id: gatherCaseId,
                        }
                    });
                }
                if (subNotificationCaseServiceRemindersViaEmail) {
                    existingCaseEntitySubscriptions.push({
                        uuid: subNotificationCaseServiceRemindersViaEmail.uuid,
                        deleted_time: subNotifyCaseServiceReminderViaEmail ? null : new Date(),
                    });
                } else {
                    newCaseEntitySubscriptions.push({
                        uuid: generateUuid(),
                        entity_id: targetEntity.entity_id,
                        notification_method: NotificationMethod.email,
                        deleted_time: subNotifyCaseServiceReminderViaEmail ? null : new Date(),
                        subscription_details: {
                            subscription_type: SubscriptionType.case_service_reminder,
                            gather_case_id: gatherCaseId,
                        }
                    });
                }

                if (subNotificationCaseServiceRemindersViaSms) {
                    existingCaseEntitySubscriptions.push({
                        uuid: subNotificationCaseServiceRemindersViaSms.uuid,
                        deleted_time: subNotifyCaseServiceReminderViaSms ? null : new Date(),
                    });

                } else {
                    newCaseEntitySubscriptions.push({
                        uuid: generateUuid(),
                        entity_id: targetEntity.entity_id,
                        notification_method: NotificationMethod.sms,
                        deleted_time: subNotifyCaseServiceReminderViaSms ? null : new Date(),
                        subscription_details: {
                            subscription_type: SubscriptionType.case_service_reminder,
                            gather_case_id: gatherCaseId,
                        }
                    });
                }
                // Currently the UI only has Service Reminders so this also controls 
                // the Case Summary subscription
                // to be consistent just setting these to the same value
                const subNotifyCaseServiceSummaryViaEmail = subNotifyCaseServiceReminderViaEmail;
                const subNotifyCaseServiceSummaryViaSms = subNotifyCaseServiceReminderViaSms;

                const subServiceSummaryViaEmail = currentSubscriptions.find((s) => {
                    return (
                        s.subscription_type === SubscriptionType.case_service_summary
                        && s.notification_method === NotificationMethod.email
                        && s.gather_case_id === gatherCaseId
                    );
                });

                const subServiceSummaryViaSms = currentSubscriptions.find((s) => {
                    return (
                        s.subscription_type === SubscriptionType.case_service_summary
                        && s.notification_method === NotificationMethod.sms
                        && s.gather_case_id === gatherCaseId
                    );
                });

                if (subServiceSummaryViaEmail) {
                    existingCaseEntitySubscriptions.push({
                        uuid: subServiceSummaryViaEmail.uuid,
                        deleted_time: subNotifyCaseServiceSummaryViaEmail ? null : new Date(),
                    });
                } else {
                    newCaseEntitySubscriptions.push({
                        uuid: generateUuid(),
                        entity_id: targetEntity.entity_id,
                        notification_method: NotificationMethod.email,
                        deleted_time: subNotifyCaseServiceSummaryViaEmail ? null : new Date(),
                        subscription_details: {
                            subscription_type: SubscriptionType.case_service_summary,
                            gather_case_id: gatherCaseId,
                        }
                    });
                }

                if (subServiceSummaryViaSms) {
                    existingCaseEntitySubscriptions.push({
                        uuid: subServiceSummaryViaSms.uuid,
                        deleted_time: subNotifyCaseServiceSummaryViaSms ? null : new Date(),
                    });
                } else {
                    newCaseEntitySubscriptions.push({
                        uuid: generateUuid(),
                        entity_id: targetEntity.entity_id,
                        notification_method: NotificationMethod.sms,
                        deleted_time: subNotifyCaseServiceSummaryViaSms ? null : new Date(),
                        subscription_details: {
                            subscription_type: SubscriptionType.case_service_summary,
                            gather_case_id: gatherCaseId,
                        }
                    }
                    );
                }


                const entityToBeUpdated: CaseHelperUpdateRequest = {
                    fname,
                    mname,
                    lname,
                    email: cannotEditPhoneOrEmail ? targetEntity.email : email || null,
                    phone: cannotEditPhoneOrEmail ? targetEntity.phone : phone || null,
                    relationship_type: relationshipType,
                    relationship,
                    relationship_alias: relationshipAlias,
                    home_phone: homePhone,
                    work_phone: workPhone,
                    home_address: homeAddress,
                    case_role: entityCaseRole ?? caseEntity.case_role,
                    state_license_number: stateLicenseNumber,
                    use_address_description: useAddressDescription,
                    can_view_belongings: canViewCaseBelongings,
                    can_view_tracking_steps: canViewQRTracking,
                    partner_case_entity_id: partnerCaseEntityId,
                    case_entity_subscription_updates: existingCaseEntitySubscriptions,
                    case_entity_subscription_creates: newCaseEntitySubscriptions,
                };
                const result = await dispatch(updateCaseHelper({
                    gatherCase: {
                        id: gatherCaseId,
                        name: gatherCaseName,
                        uuid: caseUuid,
                        funeral_home_id: funeralHomeId,
                    },
                    existingEntity: targetEntity,
                    changes: entityToBeUpdated,
                }));
                if (result && isEntityUpdateValidateError(result)) {
                    this.setState({ isSaving: false, entityValidateError: result.validateError });
                    return;
                }
                if (result && callback) {
                    callback(result);
                }
            } else {
                log.warn('Unable to make request to update user in HelperUserSettings', {
                    caseEntity,
                    gatherCaseId,
                    selectedEntity,
                });
            }
        }
        this.setState({ isSaving: false });
        this.props.onClose({
            confirm: false,
        });
    };

    isEmailValid = () => {
        const { email } = this.state;
        return email.trim().length === 0 || isValidEmail(email);
    };

    isPhoneValid = (phone: string) => {
        return phone.trim().length === 0 || isValidPhoneNumber(phone);
    };

    hasChanges = (): boolean => {
        const { selectedEntity, gatherCaseId } = this.props;
        const { targetEntity, fname, mname, lname, homeAddress, stateLicenseNumber,
            phone, email, homePhone, workPhone,

            subNotifyCaseNewMemoriesViaEmail,
            subNotifyCaseNewMemoriesViaSms,
            subNotifyCaseServiceReminderViaEmail,
            subNotifyCaseServiceReminderViaSms,
        } = this.state;
        const existing = selectedEntity;
        if (!targetEntity) {
            return false;
        }
        const existingCaseEntity = gatherCaseId && selectedEntity ? getCaseEntity(selectedEntity, gatherCaseId) : null;
        const existingSubNotifyCaseNewMemoriesEmail = !!(existingCaseEntity?.subscriptions.find((s) => {
            return (
                s.subscription_type === SubscriptionType.case_new_memories
                && s.notification_method === NotificationMethod.email
                && s.deleted_time === null
            );
        }));
        const existingSubNotifyCaseNewMemoriesSms = !!(existingCaseEntity?.subscriptions.find((s) => {
            return (
                s.subscription_type === SubscriptionType.case_new_memories
                && s.notification_method === NotificationMethod.sms
                && s.deleted_time === null
            );
        }));

        const existingSubNotifyCaseServiceReminderEmail = !!(existingCaseEntity?.subscriptions.find((s) => {
            return (
                s.subscription_type === SubscriptionType.case_service_reminder
                && s.notification_method === NotificationMethod.email
                && s.deleted_time === null
            );
        }
        ));
        const existingSubNotifyCaseServiceReminderSms = !!(existingCaseEntity?.subscriptions.find((s) => {
            return (
                s.subscription_type === SubscriptionType.case_service_reminder
                && s.notification_method === NotificationMethod.sms
                && s.deleted_time === null
            );
        }
        ));

        const existingHomeAddress = existing.home_address || { ...NullLongAddress };

        const val = Boolean(!existing
            || fname !== existing.fname
            || mname !== (existing.mname || '')
            || lname !== existing.lname
            || !isEqual(homeAddress, existingHomeAddress)
            || phone !== (existing.phone || '')
            || workPhone !== (existing.work_phone || '')
            || homePhone !== (existing.home_phone || '')
            || email !== (existing.email || '')
            || stateLicenseNumber !== (existing.state_license_number || '')
            || subNotifyCaseNewMemoriesViaEmail !== existingSubNotifyCaseNewMemoriesEmail
            || subNotifyCaseNewMemoriesViaSms !== existingSubNotifyCaseNewMemoriesSms
            || subNotifyCaseServiceReminderViaEmail !== existingSubNotifyCaseServiceReminderEmail
            || subNotifyCaseServiceReminderViaSms !== existingSubNotifyCaseServiceReminderSms
        );

        return val;
    };

    updatePartner = (partnerCaseEntityId: number | null) => {
        this.setState({
            partnerCaseEntityId,
        });
    };

    render() {
        const {
            classes,
            loggedInUser,
            isUserPhotoSaving,
            isViewAsGOM,
            zIndex,
            selectedEntity,
            gatherCaseId,
            funeralHomeId,
            caseFName,
            selectedCase,
            fhName,
            fhCity,
            isKeepTrackEnabled,
        } = this.props;

        const {
            selectedTab,
            fname,
            mname,
            lname,
            stateLicenseNumber,
            role,
            email,
            phone,
            homePhone,
            workPhone,
            isSaving,
            saveAttempted,
            homeAddress,
            entityCaseRole,
            targetEntity,
            useAddressDescription,
            relationshipType,
            relationship,
            relationshipAlias,
            entityValidateError,
            canViewQRTracking,
            canViewCaseBelongings,
            partnerCaseEntityId,
            subNotifyCaseServiceReminderViaEmail,
            subNotifyCaseServiceReminderViaSms,
            subNotifyCaseNewMemoriesViaEmail,
            subNotifyCaseNewMemoriesViaSms,
        } = this.state;

        if (!loggedInUser) {
            return null;
        }

        const isMe = loggedInUser.entity_id === selectedEntity.entity_id;

        const currentPerson: EntitySummary = {
            ...(targetEntity || selectedEntity),
            fname,
            mname,
            lname,
            home_address: homeAddress,
            state_license_number: stateLicenseNumber,
        };
        const disableLoginCredentials = !canEditPersonPhoneOrEmail(
            loggedInUser,
            selectedEntity,
            funeralHomeId,
            gatherCaseId,
        );

        const isFHUser = UserRoles.isFHUser(selectedEntity);

        const showPermissions = gatherCaseId !== null &&
            canEditCasePermissions(loggedInUser, selectedEntity, gatherCaseId) && isKeepTrackEnabled;

        const showRelationship = gatherCaseId !== null &&
            (UserRoles.isFamilyOnCase(selectedEntity, gatherCaseId) || !selectedEntity.user);

        return (
            <>
                <AppBar position="static" className={classes.tabAppbar}>
                    <Tabs
                        value={!showRelationship && selectedTab === HelperUserSettingsTab.permissions
                            || !showPermissions && selectedTab === HelperUserSettingsTab.permissions
                            ? HelperUserSettingsTab.details
                            : selectedTab}
                        onChange={(e, tab) => this.handleTabChange(tab)}
                        classes={{
                            flexContainer: classNames(
                                isFHUser && classes.tabsFlexContainerFhUser || classes.tabsFlexContainer
                            ),
                            scrollableX: classes.tabsScrollableContainer,
                            root: classNames(!isFHUser && classes.tabsRoot),
                            indicator: classes.bottomUnset
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            key={HelperUserSettingsTab.details}
                            value={HelperUserSettingsTab.details}
                            label={isMe
                                ? 'My Details'
                                : <>
                                    <span className={classes.textCapitalize}>
                                        {fname}
                                    </span>'s Details
                                </>
                            }
                            classes={{ root: classes.tabRoot }}
                        />
                        {showRelationship &&
                            <Tab
                                key={HelperUserSettingsTab.relationship}
                                value={HelperUserSettingsTab.relationship}
                                label="My Loved Ones"
                                classes={{ root: classes.tabRoot }}
                            />
                        }

                        {showPermissions &&
                            <Tab
                                key={HelperUserSettingsTab.permissions}
                                value={HelperUserSettingsTab.permissions}
                                label="Permissions"
                                classes={{ root: classes.tabRoot }}
                            />
                        }
                    </Tabs>
                </AppBar>
                <div
                    className={classNames(
                        classes.userDetails,
                        !isFHUser && classes.marginTop24,
                        selectedTab === HelperUserSettingsTab.details
                            ? classes.tabContainer : classes.displayNone
                    )}
                >
                    <MyDetailsForm
                        selectedPerson={currentPerson}
                        isDialogOpen={true}
                        onFirstNameChange={(fn: string) => this.setState({ fname: fn })}
                        onMiddleNameChange={(fn: string) => this.setState({ mname: fn })}
                        onLastNameChange={(fn: string) => this.setState({ lname: fn })}
                        onStateLicenseChange={(val: string) =>
                            this.setState({ stateLicenseNumber: val })
                        }
                        updateUserPhoto={this.updateUserPhoto}
                        isUserPhotoSaving={isUserPhotoSaving}
                        isMe={isMe}
                        isSaving={isSaving}
                        isViewAsGOM={isViewAsGOM}
                        onViewAsGOMChange={this.handleViewAsGOMChange}
                        setSettingsUserButton={r => this.setState({ entityCaseRole: r })}
                        entityCaseRole={entityCaseRole}
                        loggedInUser={loggedInUser}
                        gatherCaseId={gatherCaseId}
                        zIndex={zIndex + 1}
                        homeAddressLong={homeAddress}
                        onHomeAddressChange={(val, useDesc) => this.setState({
                            homeAddress: val,
                            useAddressDescription: useDesc,
                        })}
                        entityValidation={entityValidateError}
                        useAddressDescription={useAddressDescription}
                        isFHUser={isFHUser}
                        email={email}
                        phone={phone}
                        onEmailChange={(val) => this.setState({ email: val })}
                        onPhoneChange={(val) => this.setState({ phone: val })}
                        isEmailValid={!saveAttempted || this.isEmailValid()}
                        isPhoneValid={!saveAttempted || this.isPhoneValid(phone)}
                        disableLoginCredentials={disableLoginCredentials}
                        changeTab={(tab) => this.changeTab(tab)}
                        logoutUserSession={this.logoutUserSession}
                    />
                    {!isFHUser && <SettingsForm
                        selectedPerson={currentPerson}
                        role={role}
                        email={email}
                        phone={phone}
                        homePhone={homePhone}
                        workPhone={workPhone}
                        homeAddressLong={homeAddress}
                        logoutUserSession={this.logoutUserSession}
                        onRoleChange={(val) => this.setState({ role: val })}
                        onEmailChange={(val) => this.setState({ email: val })}
                        onPhoneChange={(val) => this.setState({ phone: val })}
                        onWorkPhoneChange={(val) => this.setState({ workPhone: val })}
                        onHomePhoneChange={(val) => this.setState({ homePhone: val })}
                        onHomeAddressChange={(val, useDesc) => this.setState({
                            homeAddress: val,
                            useAddressDescription: useDesc,
                        })}
                        useAddressDescription={useAddressDescription}
                        handlePhoneFieldKeyUpEvent={this.handlePhoneFieldKeyUpEvent}
                        isSaving={isSaving}
                        isEmailValid={!saveAttempted || this.isEmailValid()}
                        isPhoneValid={!saveAttempted || this.isPhoneValid(phone)}
                        isWorkPhoneValid={!saveAttempted || this.isPhoneValid(workPhone)}
                        isHomePhoneValid={!saveAttempted || this.isPhoneValid(homePhone)}
                        user={loggedInUser}
                        isMe={isMe}
                        entityValidation={entityValidateError}
                        disableLoginCredentials={disableLoginCredentials}
                        zIndex={zIndex + 1}
                    />}
                </div>
                <div
                    className={classNames(
                        classes.marginTop8,
                        selectedTab === HelperUserSettingsTab.relationship && showRelationship
                            ? classes.displayBlock
                            : classes.displayNone
                    )}
                >
                    {caseFName &&
                        <RelationshipAndCommunicationSettings
                            caseFName={caseFName}
                            userFName={fname}
                            entityCaseRole={entityCaseRole}
                            relationship={relationship}
                            relationshipType={relationshipType}
                            relationshipAlias={relationshipAlias}
                            saveAttempted={saveAttempted}
                            entityRelatedCases={selectedEntity.cases}
                            userEmail={email}
                            userPhone={appService.getFormattedPhoneNumber(phone)}
                            fhName={fhName || null}
                            fhCity={fhCity || null}
                            selectedCaseId={gatherCaseId}
                            isMe={isMe}
                            selectedEntityId={selectedEntity.entity_id}
                            userData={loggedInUser}
                            onAddPhoneOrEmail={() => this.handleTabChange(HelperUserSettingsTab.details)}
                            onRelationshipChange={this.handleSetRelationship}
                            onSubscriptionChange={this.handleSubscriptionChange}
                            subNotifyCaseServiceReminderViaEmail={subNotifyCaseServiceReminderViaEmail}
                            subNotifyCaseServiceReminderViaSms={subNotifyCaseServiceReminderViaSms}
                            subNotifyCaseNewMemoriesViaEmail={subNotifyCaseNewMemoriesViaEmail}
                            subNotifyCaseNewMemoriesViaSms={subNotifyCaseNewMemoriesViaSms}
                        />
                    }
                    {selectedCase &&
                        <SelectPartner
                            zIndex={zIndex + 1}
                            activeEntity={selectedEntity}
                            isDisabled={false}
                            disabledTooltip=""
                            selectedCaseEntityId={partnerCaseEntityId}
                            setPartner={this.updatePartner}
                        />
                    }
                </div>

                <WhenEnabled feature={FeatureKey.KEEP_TRACK}>
                    <div
                        className={classNames(
                            classes.marginTop24,
                            selectedTab === HelperUserSettingsTab.permissions
                                ? classes.permissionsTab
                                : classes.displayNone
                        )}
                    >
                        {<div className={classes.permissionsContent}>
                            <Typography className={classes.header}>
                                What can {fname} view on {caseFName ?? ''}'s case<br />
                            </Typography>

                            <Typography className={classes.subheading} color="secondary">
                                These options are not accessible to {fname}
                            </Typography>

                            <QRPermissionsForHelpers
                                zIndex={zIndex + 1}
                                fhName={fhName ?? ''}
                                helperFname={fname}
                                canViewQRTracking={canViewQRTracking}
                                setCanViewQRTracking={c => this.setState({ canViewQRTracking: c })}
                                canViewCaseBelongings={canViewCaseBelongings}
                                setCanViewCaseBelongings={c =>
                                    this.setState({ canViewCaseBelongings: c })
                                }
                            />
                        </div>}
                    </div>
                </WhenEnabled>

                <Grid
                    item
                    xs={12}
                    className={classes.footer}
                >
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button
                                size="large"
                                className={classNames(classes.button, classes.colorBlack2)}
                                onClick={this.closeDialog}
                            >
                                CANCEL
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.saveSettings}
                                disabled={isSaving}
                            >
                                SAVE
                                {isSaving && <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default compose(
    withState(mapStateToProps),
    withGStyles(styles),
)(HelperUserSettings) as React.ComponentType<Props>;
