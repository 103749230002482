import { alpha, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import makeGStyles from "../../../styles/makeGStyles";
interface StyleProps {
    color: string;
}

const useStyles = makeGStyles<StyleProps>((theme) => ({
    contentBox: {
        wordBreak: 'break-word',
        padding: '5px 7px',
        borderBottom: props => `1px solid ${alpha(props.color, 0.1)}`,
        '&:last-child': {
            borderBottom: 0
        }
    }
}));

interface Props {
    title: string;
    description: string | undefined | number | null;
    borderClass?: string;
    additionalClass?: string;
    titleColor: string | undefined;
}

const PolicyContentItem = ({ title, description, titleColor, borderClass, additionalClass }: Props) => {
    const classes = useStyles({ color: (titleColor || '') });
    return (
        <Grid className={classNames(classes.contentBox, borderClass, additionalClass)}>
            <Typography sx={{
                color: `${(titleColor || '')}`,
                fontSize: 12
            }}>
                {title}
            </Typography>
            <Typography
                fontSize={14}
            >
                {description}
            </Typography>
        </Grid>
    );
};

export default PolicyContentItem;