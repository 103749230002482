import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ProductItem } from '../../../../shared/types';
import { RED_COLOR, SUCCESS_COLOR } from '../../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../../services';
import makeGStyles from '../../../../styles/makeGStyles';

interface Props {
    productItems: ProductItem[];
}

const useStyles = makeGStyles({
    root: {},
    addPhotosText: {
        width: 'fit-content',
        padding: '6px 12px',
        borderRadius: 8,
        textAlign: 'center',
        border: `2px solid ${RED_COLOR}`,
        background: convertHexToRGBA(RED_COLOR, 0.18),
        '& p': {
            color: RED_COLOR,
        },
    },
    greenBox: {
        border: `2px solid ${SUCCESS_COLOR}`,
        background: convertHexToRGBA(SUCCESS_COLOR, 0.18),
        '& p': {
            color: SUCCESS_COLOR,
        },
    },
}, { name: 'AddPhotosToProductsText' });

const AddPhotosToProductsText = (props: Props) => {
    const { productItems } = props;

    const classes = useStyles();

    let withPhotos = 0;
    let totalProducts = 0;
    productItems.forEach((productItem) => {
        if (productItem.product) {
            totalProducts += 1;
            if (productItem.product.photos.length) {
                withPhotos += 1;
            }
        }
    });

    return (
        <Grid
            item
            className={classNames(
                classes.addPhotosText,
                withPhotos === totalProducts && classes.greenBox
            )}
        >
            <Typography>
                Adding photos to each product creates a better experience.
            </Typography>
            <Typography className={classes.fontSize16}>
                Products with Photos:&nbsp;
                <span className={classes.fontWeight500}>
                    {withPhotos}/{totalProducts}
                </span>
            </Typography>
        </Grid>
    );
};

export default AddPhotosToProductsText;
