import * as t from 'io-ts';
import { AddressRecord } from '.';
import { FuneralHomeCaseRecord, GatherCaseSummary } from './case';
import { FuneralHomeRecord } from './funeralHome';
import { getValidator, Nullable } from './utils';

export interface InsuranceCarrierClientRecord {
    fh_id: number;
    fh_name: string;
    carrier_id: number;
    carrier_name: string;
}

export enum InsuranceCarrier {
    HOMESTEADERS = 'HOMESTEADERS',
}

export interface InsuranceCarrierRecord {
    id: number;
    key: string;
    name: string;
    sync_enabled: boolean;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
}

export interface InsuranceCarrierUX extends Pick<InsuranceCarrierRecord,
    'id' | 'key' | 'name' | 'sync_enabled'> { }

export interface InsuranceCarrierUpdateRecord
    extends Pick<InsuranceCarrierRecord, 'updated_by' | 'updated_time'>,
    Partial<
        Pick<
            InsuranceCarrierRecord,
            | 'sync_enabled'
        >
    > { }

const InsuranceCarrierPatchRequestType = t.type({
    sync_enabled: t.boolean,
});

export interface InsuranceCarrierPatchRequest extends t.TypeOf<typeof InsuranceCarrierPatchRequestType> { }
export class InsuranceCarrierPatchRequest {
    public static fromRequest = getValidator<InsuranceCarrierPatchRequest>(InsuranceCarrierPatchRequestType);
}

export enum InsuranceSyncStatus {
    running = 'running',
    success = 'success',
    failed = 'failed',
    stale = 'stale',
}

export interface InsuranceSyncLogRecord {
    id: number;
    insurance_carrier_id: number;
    sync_status: InsuranceSyncStatus;
    sync_start_time: Date;
    sync_end_time: Date;
    sync_error: string | null;
    created_time: Date;
}


export enum PolicyCaseStatus {
    attached = 'attached',
    unattached = 'unattached',
    pending = 'pending',
}

export interface InsurancePolicyRecord {
    id: number;
    policy_number: string;
    issue_date: Date;
    prod_type_desc: string;
    policy_data: Homesteaders.Policy;
    insurance_carrier_id: number;
    funeral_home_id: number | null;
    funeral_home_case_id: number | null;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    removed_from_dashboard_time: Date | null;
    attached_time: Date | null;
    attached_by: number | null;
    error_message: string | null;
}

export interface InsurancePolicyInsertRecord extends
    Pick<InsurancePolicyRecord,
        | 'policy_number'
        | 'issue_date'
        | 'prod_type_desc'
        | 'policy_data'
        | 'insurance_carrier_id'
        | 'funeral_home_id'
        | 'funeral_home_case_id'
        | 'updated_by'
        | 'updated_time'
        | 'error_message'
        | 'removed_from_dashboard_time'
    > { }

export interface InsurancePolicyUpdateRecord extends
    Partial<Pick<InsurancePolicyRecord,
        | 'issue_date'
        | 'prod_type_desc'
        | 'policy_data'
        | 'attached_time'
        | 'attached_by'
        | 'funeral_home_id'
        | 'funeral_home_case_id'
        | 'updated_by'
        | 'updated_time'
        | 'deleted_by'
        | 'deleted_time'
        | 'removed_from_dashboard_time'
        | 'error_message'
    >> { }

// Use iots to define the csv record type so we can do some runtime validation of the CSV file
const PolicyCSVRecordType = t.type({
    policyNumber: t.string,
    status: t.string,
    prodType: t.string,
    prodTypeDesc: t.string,
    face: t.string,
    hlcLocationId: t.string,
    hlcLocationName: t.string,
    hlcLocationAddressLine1: t.string,
    hlcLocationAddressLine2: t.string,
    hlcLocationCity: t.string,
    hlcLocationState: t.string,
    hlcLocationZip: t.string,
    locationId: t.string,
    issueDate: t.string,
    payFrequency: t.string,
    total: t.string,
    insuredFirstName: t.string,
    insuredMiddleInitial: t.string,
    insuredSurName: t.string,
    insuredAge: t.string,
    insuredDateOfBirth: t.string,
    insuredGender: t.string,
    insuredEmail: t.string,
    insuredPhone: t.string,
    insuredAddressLineOne: t.string,
    insuredAddressLineTwo: t.string,
    insuredAddressLineThree: t.string,
    insuredCity: t.string,
    insuredState: t.string,
    insuredZip: t.string,
    insuredBadAddressIndicator: t.string,
    ownerFirstName: t.string,
    ownerMiddleInitial: t.string,
    ownerSurName: t.string,
    ownerAge: t.string,
    ownerDateOfBirth: t.string,
    ownerGender: t.string,
    ownerEmail: t.string,
    ownerPhone: t.string,
    ownerAddressLineOne: t.string,
    ownerAddressLineTwo: t.string,
    ownerCity: t.string,
    ownerState: t.string,
    ownerZip: t.string,
    ownerBadAddressIndicator: t.string,
    cycleDate: t.string,
    agentFirstName: t.string,
    agentLastName: t.string,
    agentNumber: t.string,
    StatusLongDescription: t.string,
});
export interface PolicyCSVRecord extends t.TypeOf<typeof PolicyCSVRecordType> { }
export class PolicyCSVRecord {
    public static fromRequest = getValidator<PolicyCSVRecord>(PolicyCSVRecordType);
}

const mapPayFrequencyFromString = (payFrequency: string): Homesteaders.PayFrequency => {
    switch (payFrequency) {
        case 'SP':
            return Homesteaders.PayFrequency.SinglePay;
        case '03':
            return Homesteaders.PayFrequency.ThreeYear;
        case '05':
            return Homesteaders.PayFrequency.FiveYear;
        case '07':
            return Homesteaders.PayFrequency.SevenYear;
        case '10':
            return Homesteaders.PayFrequency.TenYear;
        default:
            return Homesteaders.PayFrequency.SinglePay;
    }
};

export const mapPolicyStatusFromString = (status: string): Homesteaders.PolicyStatus => {
    switch (status) {
        case Homesteaders.PolicyStatus.Active:
            return Homesteaders.PolicyStatus.Active;
        case Homesteaders.PolicyStatus.Suspended:
            return Homesteaders.PolicyStatus.Suspended;
        case Homesteaders.PolicyStatus.Terminated:
            return Homesteaders.PolicyStatus.Terminated;
        default:
            return Homesteaders.PolicyStatus.Active;
    }
};

const blankIfNULL = (value: string): string => {
    return value.trim() === 'NULL' ? '' : value;
};

export function mapCSVRecordToInsurancePolicyUpsertRecord(
    record: PolicyCSVRecord,
    carrierId: number,
    userId: number
): InsurancePolicyInsertRecord {
    // Validate the policy record using iots
    PolicyCSVRecord.fromRequest(record);
    return {
        policy_number: record.policyNumber,
        issue_date: new Date(record.issueDate),
        prod_type_desc: record.prodTypeDesc,
        insurance_carrier_id: carrierId,
        funeral_home_id: parseInt(record.locationId, 10),
        funeral_home_case_id: null,
        updated_by: userId,
        updated_time: new Date(),
        removed_from_dashboard_time: new Date(),
        error_message: null,
        policy_data: {
            statusLongDescription: blankIfNULL(record.StatusLongDescription),
            policyNumber: record.policyNumber,
            status: mapPolicyStatusFromString(record.status),
            prodType: record.prodType,
            face: parseFloat(record.face),
            issueDate: record.issueDate,
            payFrequency: mapPayFrequencyFromString(record.payFrequency),
            arrangmentTotal: parseFloat(record.total),
            funeralHome: {
                hlcFuneralHomeNumber: record.hlcLocationId,
                locationId: record.locationId,
                name: record.hlcLocationName,
                addressLine1: record.hlcLocationAddressLine1,
                addressLine2: blankIfNULL(record.hlcLocationAddressLine2),
                city: record.hlcLocationCity,
                state: record.hlcLocationState,
                zip: record.hlcLocationZip,
            },
            insured: {
                firstName: record.insuredFirstName,
                middleName: blankIfNULL(record.insuredMiddleInitial),
                lastName: record.insuredSurName,
                age: parseInt(record.insuredAge, 10),
                dateOfBirth: record.insuredDateOfBirth,
                gender: record.insuredGender,
                email: blankIfNULL(record.insuredEmail),
                phone: blankIfNULL(record.insuredPhone),
                addressLineOne: record.insuredAddressLineOne,
                addressLineTwo: blankIfNULL(record.insuredAddressLineTwo),
                city: record.insuredCity,
                state: record.insuredState,
                zip: record.insuredZip,
                badAddressIndicator: record.insuredBadAddressIndicator === 'Y' ? 'Y' : 'N',
            },
            owner: {
                firstName: record.ownerFirstName,
                middleName: blankIfNULL(record.ownerMiddleInitial),
                lastName: record.ownerSurName,
                age: parseInt(record.ownerAge, 10),
                dateOfBirth: record.ownerDateOfBirth,
                gender: record.ownerGender,
                email: blankIfNULL(record.ownerEmail),
                phone: blankIfNULL(record.ownerPhone),
                addressLineOne: record.ownerAddressLineOne,
                addressLineTwo: blankIfNULL(record.ownerAddressLineTwo),
                city: record.ownerCity,
                state: record.ownerState,
                zip: record.ownerZip,
                badAddressIndicator: record.ownerBadAddressIndicator === 'Y' ? 'Y' : 'N',
            },
            writingAgent: {
                firstName: record.agentFirstName,
                lastName: record.agentLastName,
                agentNumber: record.agentNumber,
            }
        }
    };
}

export interface InsuranceEntity {
    displayName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    gender: string;
    email: string;
    phone: string;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: string;
    zip: string;
    badAddressIndicator: string;
}

export interface InsuranceColor {
    insuranceCoName: string;
    insuranceCoColor: string;
    insuranceCoLogo: string;
    insuranceCoWhiteLogo: string;
}

export interface InsurancePolicySummaryUX {
    policy_id: number;
    policy_number: string;
    issue_date: Date;
    policy_case_status: PolicyCaseStatus;
    insurance_carrier_id: number;
    funeral_home_case_id: number | null;
    policy_data: Homesteaders.Policy;
    insurance_carrier: InsuranceCarrierUX;
    removed_from_dashboard_time: Date | null;
    insurance_color: InsuranceColor;
}

export interface InsurancePolicyUX extends Pick<InsurancePolicyRecord,
    | 'policy_number'
    | 'issue_date'
    | 'funeral_home_id'
    | 'funeral_home_case_id'
    | 'insurance_carrier_id'
    | 'removed_from_dashboard_time'
> {
    policy_id: InsurancePolicyRecord['id'];
    policy_case_status: PolicyCaseStatus;
    carrier_key: InsuranceCarrierRecord['key'];
    insurance_carrier: {
        id: InsuranceCarrierRecord['id'];
        name: InsuranceCarrierRecord['name'];
        key: InsuranceCarrierRecord['key'];
        sync_enabled: InsuranceCarrierRecord['sync_enabled'];
    };
    pay_frequency: Homesteaders.PayFrequency;
    funeral_home_id: number;
    funeral_home_primary_theme_color: string;
    product_type: string;
    status: Homesteaders.PolicyStatus;
    face_value: string;
    arrangement_total: string;
    funeral_home: Homesteaders.FuneralHome;
    insured: InsuranceEntity;
    owner: InsuranceEntity;
    writing_agent: {
        displayName: string;
        firstName: string;
        lastName: string;
        agentNumber: string;
    };
    case_uuid: Nullable<FuneralHomeCaseRecord>['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    funeral_home_key: FuneralHomeRecord['key'];
    funeral_home_address1: AddressRecord['address1'];
}

export interface FuneralHomeInsurancePolicyUXList {
    funeral_home_id: number;
    funeral_home_name: string;
    funeral_home_key: string;
    funeral_home_address1: string;
    funeral_home_primary_theme_color: string;
    policyDetailList: Pick<InsurancePolicyUX,
        | 'writing_agent'
        | 'issue_date'
    >[];
}

const InsurancePolicyUpdateRequestType = t.type({
    policyCaseStatus: t.string,
    policyData: t.string,
    funeralHomeCaseId: t.number,
});

export interface InsurancePolicyUpdateRequest extends t.TypeOf<typeof InsurancePolicyUpdateRequestType> { }

export class InsurancePolicyUpdateRequest {
    public static fromRequest = getValidator<InsurancePolicyUpdateRequest>(InsurancePolicyUpdateRequestType);
}

const InsurancePolicyInsertRequestType = t.type({
    policyNumber: t.string,
    prodTypeDesc: t.string,
    policyData: t.string,
    insuranceCarrierId: t.number,
    funeralHomeId: t.number,
});

export interface InsurancePolicyRequest extends t.TypeOf<typeof InsurancePolicyInsertRequestType> { }
export class InsurancePolicyRequest {
    public static fromRequest = getValidator<InsuranceCarrierPatchRequest>(InsurancePolicyInsertRequestType);
}

export interface InsurancePolicyDialogCaseUX {
    fname: string;
    lname: string;
    uuid: string;
    insurance_policies: number[];
}

export namespace Homesteaders {
    /**
     * Homesteaders Life Company API
     */

    export interface PolicyRequest {
        funeralHomeId: number;
        startDate?: string;
        endDate?: string;
    }

    export interface TokenResponse {
        prorductId: string;
        accessToken: string;
    }
    /**
     * Life Policy Status
     */
    export enum PolicyStatus {
        Active = "A",
        Suspended = "S",
        Terminated = "T",
    }

    export const PolicyStatusLookup: Record<PolicyStatus, string> = {
        [PolicyStatus.Active]: 'Active',
        [PolicyStatus.Suspended]: 'Suspended',
        [PolicyStatus.Terminated]: 'Terminated',
    };

    /**
     * Pay Frequency
     */
    export enum PayFrequency {
        SinglePay = "SP",
        ThreeYear = "03",
        FiveYear = "05",
        SevenYear = "07",
        TenYear = "10",
    }

    /**
     * Insurance Agent
     */
    export interface InsuranceAgent {
        firstName: string;
        lastName: string;
        agentNumber: string; // Homesteaders Life Unique Agent Number 6 numeric digits
    }

    /**
     * Funeral Home
     */
    export interface FuneralHome {
        hlcFuneralHomeNumber: string; // Homesteaders Life unique Funeral Home ID 5 numeric digits
        locationId: string; // Client’s unique FH ID text (i.e. funeral_home.id)
        name: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        zip: string;
    }

    /**
     * Person - used for both Insured and Owner though the two may be different
     */
    export interface Person {
        firstName: string;
        middleName: string;
        lastName: string;
        age: number;
        dateOfBirth: string; // String representation of a date in YYYY-MM-DDTHH:mm:dd format
        gender: string;
        email: string;
        phone: string;
        addressLineOne: string;
        addressLineTwo: string;
        city: string;
        state: string;
        zip: string;
        badAddressIndicator: 'Y' | 'N';
    }

    /**
     * HomeSteaders Life Policy
     */
    export interface Policy {
        policyNumber: string; // Homesteaders Life Unique Policy Number 10 numeric digits
        status: PolicyStatus;
        prodType: string;
        // prodTypeDesc: string; // change this once their API updates
        face: number; // Face value of the policy decimal
        issueDate: string; // String representation of a date in YYYY-MM-DDTHH:mm:dd format
        payFrequency: PayFrequency;
        arrangmentTotal: number; // Arrangement Total from Goods and Services contract decimal
        statusLongDescription: string; // A longer description of the policy status
        funeralHome: FuneralHome;
        insured: Person;
        owner: Person;
        writingAgent: InsuranceAgent; // The agent who wrote the policy
    }

    /**
     * API Response for Policy queries
     */
    export interface PolicyResponse {
        policyCount: number;
        policies: Policy[];
    }

    /**
     * API Response for the Goods and Services query
     */
    export interface GoodsAndServicesResponse {
        message: string;
        document: {
            policyNumber: string;
            documentType: string;
            pageCount: number;
            base64: string;
        };
    }

}

export interface GetPolicyAndAssociationsResponse {
    caseSummary: GatherCaseSummary | null;
    policies: InsurancePolicyUX[];
}
