import * as React from 'react';

import { WithStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import ContactRow from './ContactRow';
import CaseRow from './CaseRow';
import OrganizationRow from './OrganizationRow';
import HelperRow from './HelperRow';

import { OrganizationTypes, RolodexSearchResultDataTypes, RolodexSearchResultType } from '../../../shared/types';
import { RowClickHandlerFunc } from './utils';

type StyledProps = WithStyles<
'tabContainer' | 'firstItem' | 'heading' | 'subHeading' | 'profession' | 'location' | 'assignments' |
'tabContainerDivider' | 'contactType' | 'doctorName' | 'phoneNumber' | 'emailText' | 'emailTitle' |
'rootButton' | 'addressText' | 'noResultContainer' | 'noResultsDivider' | 'clickHereText' | 'avatar' |
'nameInitialsAvatar'
>;

type Props = {
    listContents: RolodexSearchResultDataTypes[] | null;
    rowClickHandler: RowClickHandlerFunc;
    openCreateDialog: () => void;
    categoryFilter: OrganizationTypes | null;
    isLoading: boolean;
} & StyledProps;

const RolodexEntryList: React.StatelessComponent<Props> = (props) => {
    const {
        listContents,
        rowClickHandler,
        classes,
        openCreateDialog,
        categoryFilter,
        isLoading
    } = props;

    if (listContents !== null && !isLoading) {
        const noResultsFragment = (
            <Grid item xs={12} className={classes.noResultContainer}>
                <Typography color="secondary" align="center">
                    Sorry, no results found.
                </Typography>
                <Divider color="secondary" className={classes.noResultsDivider} />
                <Typography color="secondary" align="center">
                    <span
                        className={classes.clickHereText}
                        onClick={() => openCreateDialog()}
                    >Click here</span>&nbsp;
                    to create a new rolodex entry or contact Gather to import your contacts for you.
                </Typography>
            </Grid>
        );

        let list: RolodexSearchResultDataTypes[] = [];
        if (categoryFilter === null) {
            list = listContents;
        } else {
            list = listContents.filter(item => {
                if (item.type === RolodexSearchResultType.org && item.orgType !== null) {
                    return item.orgType === categoryFilter;
                }
                return false;
            });
        }

        const listRows = list.map((item, index) => {
            const propArgs = { classes, rowClickHandler, key: index };
    
            switch (item.type) {
            case RolodexSearchResultType.case:
                return <CaseRow gatherCase={item} {...propArgs} />;
            case RolodexSearchResultType.contact:
                return <ContactRow contact={item} {...propArgs} />;
            case RolodexSearchResultType.helper:
                return <HelperRow helper={item} {...propArgs} />;
            case RolodexSearchResultType.org:
                return <OrganizationRow org={item} {...propArgs} />;
            default:
                return <></>;
            }
        });

        return (
            <Grid item xs={12} className={classes.tabContainer}>
                {listRows.length ? listRows : noResultsFragment}
            </Grid>
        );
    }

    return (
        <Grid item xs={12} className={classes.tabContainer} />
    );

};

export default RolodexEntryList;
