import { SnackBarVariant } from '../types';

import { SnackbarOrigin } from '@mui/material/Snackbar';
import { AppDispatch } from '../store';

export const SET_APP_SNACKBAR_OPEN = 'SET_APP_SNACKBAR_OPEN';
export type SET_APP_SNACKBAR_OPEN = typeof SET_APP_SNACKBAR_OPEN;

interface SetAppSnackbarOpen {
    type: SET_APP_SNACKBAR_OPEN;
    isOpen: boolean;
}

function setAppSnackbarOpen(isOpen: boolean): SetAppSnackbarOpen {
    return {
        type: SET_APP_SNACKBAR_OPEN,
        isOpen: isOpen,
    };
}

export const closeSnackbar = (reason: string) => {
    return async (dispatch: AppDispatch) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setAppSnackbarOpen(false));
    };
};

export const SET_APP_SNACKBAR = 'SET_APP_SNACKBAR';
export type SET_APP_SNACKBAR = typeof SET_APP_SNACKBAR;

interface SetAppSnackbar {
    type: SET_APP_SNACKBAR;
    isOpen: boolean;
    message: string | JSX.Element;
    variant: SnackBarVariant;
    secondaryMessage?: string | JSX.Element;
    snackbarOrigin?: SnackbarOrigin;
    autoHideDuration?: number;
}

export function setAppSnackbar(
    message: string | JSX.Element,
    variant: SnackBarVariant,
    snackbarOrigin?: SnackbarOrigin,
    autoHideDuration?: number,
    secondaryMessage?: string | JSX.Element,
): SetAppSnackbar {
    return {
        type: SET_APP_SNACKBAR,
        isOpen: true,
        message,
        variant,
        secondaryMessage,
        snackbarOrigin,
        autoHideDuration
    };
}

export function setSnackbarSuccess(
    message: string | JSX.Element,
    snackbarOrigin?: SnackbarOrigin,
    autoHideDuration?: number
) {
    return setAppSnackbar(message, 'success', snackbarOrigin, autoHideDuration);
}

export type AppSnackbarAction = SetAppSnackbarOpen | SetAppSnackbar;
