import DCCheckbox from '../checkbox/DCCheckbox';
import DCDateField, { Props as DCDateFieldProps } from '../dateField/DCDateField';

export interface Props extends DCDateFieldProps {
    checkboxLabel: string;
    isChecked: boolean;
    onCheckboxChange: (isChecked: boolean) => void;
}

const DCDateFieldWithCheckbox = (props: Props) => {

    const {
        id,
        onBlur,
        error,
        disabled,
        required,
        value,
        defaultValue,
        label,
        rootClass,
        textFieldClass,
        labelClass,
        isChecked,
        checkboxLabel,
        isHiddenFromFamily,
        handleOpenSnackbar,
        onChange,
        onCheckboxChange,
    } = props;

    return (
        <>
            <DCDateField
                id={id}
                onBlur={onBlur}
                label={label}
                rootClass={rootClass}
                textFieldClass={textFieldClass}
                labelClass={labelClass}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                error={error}
                required={required}
                disabled={isChecked || disabled}
                handleOpenSnackbar={handleOpenSnackbar}
                isHiddenFromFamily={isHiddenFromFamily}
            />
            <DCCheckbox
                label={checkboxLabel}
                onChange={onCheckboxChange}
                handleOpenSnackbar={handleOpenSnackbar}
                isChecked={isChecked}
                disabled={disabled}
                rootClass={rootClass}
                labelClass={labelClass}
            />
        </>
    );
};

export default DCDateFieldWithCheckbox;
