import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { SlideTransition } from './Transitions';
import { useGStyles } from './hooks/useGStyles';
import { getIntercomTargetProp } from '../../services';

interface Props {
    isLoading?: boolean;
    header?: string;
    subHeader?: string;
    subHeaderStyles?: string;
    list?: string[];
    text?: string;
    confirmationButtonText?: string | JSX.Element;
    cancelButtonText?: string;
    dialogActionClass?: string;
    open: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    zIndex: number;
    intercomTargetProp?: string;
    intercomTargetPropConfirmButton?: string;
    intercomTargetPropCancelButton?: string;
}

const ConfirmationDialog = (props: Props) => {
    const {
        header,
        subHeader,
        open,
        onConfirm,
        text,
        cancelButtonText,
        dialogActionClass,
        zIndex,
        subHeaderStyles,
        list,
        isLoading,
        confirmationButtonText,
        onClose,
        intercomTargetProp,
        intercomTargetPropConfirmButton,
        intercomTargetPropCancelButton,
    } = props;

    const classes = useGStyles();

    const renderList = () => {
        if (!list) {
            return null;
        }
        return (
            <ul>
                {list.map((val, index) => <li key={index}>{val}</li>)}
            </ul>
        );
    };

    const renderConfirmButton = () => {
        return (
            <Button
                onClick={onConfirm}
                autoFocus
                color="primary"
                variant="contained"
                disabled={isLoading}
                {...getIntercomTargetProp(intercomTargetPropConfirmButton)}
            >
                {confirmationButtonText || 'CONFIRM'}
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        );
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex }}
        >
            <div {...getIntercomTargetProp(intercomTargetProp)}>
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        <span className={subHeaderStyles || ''}>{subHeader}</span>
                        {renderList()}
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={dialogActionClass || undefined}>
                    <Button
                        onClick={onClose}
                        className={classes.colorBlack2}
                        {...getIntercomTargetProp(intercomTargetPropCancelButton)}
                    >
                        {cancelButtonText || 'Cancel'}
                    </Button>

                    {onConfirm &&
                        renderConfirmButton()
                    }
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default ConfirmationDialog;
