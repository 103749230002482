import React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';

import { GatherCaseUX } from '../../../../shared/types';

import { styleWrapper as TASKDIALOGSSTYLES } from '../TaskDialogsStyles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

interface Props {
    activeCase: GatherCaseUX;
}

interface State {
    maritalStatusTypeList: MaritalStatusType1[];
    maritalStatus?: string;
    dependentChildren: string;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    paraText: {
        fontSize: 18,
        fontWeight: 300,
        color: theme.palette.secondary.main
    }
});

const socialSecurityContact: string = '1-800-772-1213';
const socialSecurityContactForDeaf: string = '1-800-325-0778';
const findSocialSecurityOffice: string = 'https://secure.ssa.gov/ICON/main.jsp';
const socialSecurityWebsite: string = 'https://www.ssa.gov/planners/survivors/';

export interface MaritalStatusType1 {
    key: number;
    title: string;
}

type StyledProps = Props & WithGStyles<'root' | 'secondStateMainGrid' | 'secondStateIcon' | 'borderBottom'
    | 'socialSecurityForm' | 'themeSelectMenu' | 'toggleButton' | 'toggleButtonGroupLabel' | 'toggleContainer'
    | 'paraText'>;

class SocialSecurityDollars extends React.Component<StyledProps, State> {
    constructor(props: StyledProps) {
        super(props);
        const { activeCase } = props;
        this.state = {
            maritalStatusTypeList: [
                {
                    key: 1, title: 'Married'
                },
                {
                    key: 2, title: 'Married, but separated'
                },
                {
                    key: 3, title: 'Married, died at the same time'
                },
                {
                    key: 4, title: 'Widowed'
                },
                {
                    key: 5, title: 'Divorced'
                },
                {
                    key: 6, title: 'Never Married'
                },
            ],
            maritalStatus: !activeCase.death_certificate ? '' :
                (activeCase.death_certificate.marriage.maritalStatus !== null &&
                    activeCase.death_certificate.marriage.maritalStatus !== 'Unknown') ?
                    activeCase.death_certificate.marriage.maritalStatus : '',
            dependentChildren: ''
        };
    }

    handleMaritalStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const target = event.target;
        this.setState({
            maritalStatus: target.value
        });
    };

    handleDependChildren = (dependentChildren: string) => {
        this.setState({
            dependentChildren
        });
    };

    renderOutputText = () => {
        const { classes, activeCase } = this.props;
        const { maritalStatus, dependentChildren } = this.state;
        const marriedOrSeparatedText: string = maritalStatus === 'Married' ?
            `Since ${activeCase.fname} was married at the time of his passing, ...` : maritalStatus ===
                'Married, but separated' ? `If... Also, if ${activeCase.fname} had dependent children...` : '';
        const dependentChildrenYesText: string = `${activeCase.fname}'s child or children may be entitled to 
        receive the $255 lump sum death benefit. You will want to check with your local Social Security 
        Administration office first... form... more details`;
        const dependentChildrenNoText: string = `It looks like you won't qualify for the $255 lump sum death 
        benefit. We recommend calling the Social Security Administration Office at ___ in order to verify 
        that there aren't any special circumstances that might qualify you to receive this benefit. 
        You can also click below to find the location nearest you.`;
        const marriedotherStatusText: string = dependentChildren === 'Yes' ? dependentChildrenYesText :
            dependentChildren === 'No' ? dependentChildrenNoText : '';
        return (
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                    align="left"
                >
                    {maritalStatus !== 'Married' && maritalStatus !== 'Married, but separated' ?
                        marriedotherStatusText : marriedOrSeparatedText}
                </Typography>
            </Grid>
        );
    };

    renderChildrenQuestion = () => {
        const { classes, activeCase } = this.props;
        const { dependentChildren } = this.state;
        return (
            <Grid container alignItems="flex-end" justifyContent="center" >
                <Grid item className={classes.width100}>
                    <FormControl
                        className={classNames(classes.width100, classes.textLeft, classes.marginTop40)}
                    >
                        <InputLabel
                            className={classNames(classes.toggleButtonGroupLabel, 'width-100')}
                        >
                            Did {activeCase.fname} have dependent children?
                        </InputLabel>
                        <div className={classes.toggleContainer}>
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="center"
                            >
                                <ToggleButtonGroup
                                    value={dependentChildren}
                                    exclusive

                                >
                                    <ToggleButton
                                        value="Yes"
                                        onClick={() => this.handleDependChildren('Yes')}
                                        className={classes.toggleButton}
                                    >
                                        Yes
                                    </ToggleButton>
                                    <ToggleButton
                                        value="No"
                                        onClick={() => this.handleDependChildren('No')}
                                        className={classes.toggleButton}
                                    >
                                        No
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </div>
                    </FormControl>

                </Grid>
            </Grid>
        );
    };

    render() {
        const { classes, activeCase } = this.props;
        // const { maritalStatusTypeList, maritalStatus } =  this.state;
        return (
            <>
                <Grid item>
                    <Typography className={classes.paraText} align="left">
                        {`You and your family may be entitled to receive a $255 lump sum death benefit payment from 
                        the federal government. This one-time payment of $255 can be paid to ${activeCase.fname}'s 
                        surviving spouse if he or she was living with the deceased; or, if living apart, was receiving 
                        certain Social Security benefits on ${activeCase.fname}'s record. If there is no surviving 
                        spouse, the payment is made to a child who is eligible for benefits on ${activeCase.fname}'s 
                        record in the month of death.`}
                    </Typography>
                    <Typography
                        className={classNames(
                            classes.paraText,
                            classes.marginTop10
                        )}
                        align="left"
                    >
                        We recommend that you call the Social Security Administration toll-free at&nbsp;
                        <a
                            href={`tel: ${socialSecurityContact}`}
                            className={classNames(
                                classes.cursorPointer,
                                classes.colorSecondary
                            )}
                        >
                            {socialSecurityContact}
                        </a>. Staff can answer your specific questions from 7 a.m. to 7 p.m., Monday through Friday.
                        You can also click the button below to find the Social Security office closest to you.
                    </Typography>
                </Grid>
                <Grid item>
                    <a
                        href={`tel: ${socialSecurityContact}`}
                        className={classNames(
                            classes.cursorPointer,
                            classes.textDecorationNone,
                        )}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classNames(
                                classes.margin_8,

                                classes.marginTop20
                            )}
                        >
                            Call Social Security
                        </Button>
                    </a>
                </Grid>
                <Grid item>
                    <a
                        href={findSocialSecurityOffice}
                        target="blank"
                        className={classes.textDecorationNone}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classNames(
                                classes.margin_8,

                                classes.marginTop20
                            )}
                        >
                            Find Social Security Office
                        </Button>
                    </a>
                </Grid>
                <Grid item>
                    <Typography
                        className={classNames(
                            classes.paraText,
                            classes.marginTop20
                        )}
                        align="left"
                    >
                        <a
                            href={socialSecurityWebsite}
                            target="blank"
                            className={classNames(
                                classes.cursorPointer,
                                classes.colorSecondary
                            )}
                        >
                            Click here
                        </a>
                        &nbsp;to visit the Social Security website for more information about all of
                        Social Security's programs.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        className={classNames(
                            classes.paraText,
                            classes.marginTop20,
                            classes.marginBottom20
                        )}
                        align="left"
                    >
                        If you are deaf or hard of hearing, you may call the TTY number at&nbsp;
                        <a
                            href={`tel: ${socialSecurityContactForDeaf}`}
                            className={classNames(
                                classes.cursorPointer,
                                classes.colorSecondary
                            )}
                        >
                            {socialSecurityContactForDeaf}
                        </a>
                    </Typography>
                </Grid>
                {/* <Grid>
                    <Grid item>
                        <Typography 
                            color="secondary"
                            className={classNames(
                                classes.fontSize18, 
                                classes.fontWeight300
                            )}
                            align="left"
                        >
                           You and your family may be entitled to receive a $255 lump sum death benefit payment 
                           from the federal government. Please answer the following brief questions so we can 
                           help determine your eligibility and point you in the right direction.
                        </Typography>
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        md={9}
                        className={
                            classNames(
                                classes.marginTop20,
                                classes.marginAuto
                            )
                        }
                    >
                       <form
                            className={classNames(classes.overflowHidden, classes.socialSecurityForm)}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="center"
                            >
                                <Grid item className={classes.width100}>
                                    <FormControl
                                        className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                                    >
                                        <InputLabel
                                            htmlFor="maritalStatus"
                                            className="width-100"
                                        >
                                            {activeCase.fname}'s Marital Status
                                        </InputLabel>
                                        <Select
                                            value={maritalStatus || ''}
                                            onChange={this.handleMaritalStatusChange}
                                            inputProps={{
                                                name: 'maritalStatus',
                                                id: 'maritalStatus',
                                            }}
                                            MenuProps={{
                                                style: {
                                                    zIndex: 1322,
                                                }
                                            }}
                                            classes={{
                                                selectMenu: classes.themeSelectMenu,
                                            }}
                                            className={classes.width100}
                                        >
                                            {maritalStatusTypeList.map((ele) =>
                                                <MenuItem value={ele.title} key={ele.key}>{ele.title}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            { (maritalStatus === 'Married, died at the same time' || 
                                maritalStatus === 'Widowed' || maritalStatus === 'Divorced' || 
                                maritalStatus === 'Never Married') 
                                ? this.renderChildrenQuestion() : ''
                            }
                        </form>
                    </Grid>
                    {(maritalStatus && maritalStatus !== 'Unknown') ? this.renderOutputText() : ''}
                </Grid> */}
            </>

        );
    }
}

export default withGStyles(styles, TASKDIALOGSSTYLES<Props>())(SocialSecurityDollars);
