/*
* Injected props can be different but value prop is always present and it is required 
* (when values are explicitly mentioned, be default it uses indexes)
* for the direct child of the Components like Tabs/RadioGroup etc, because it reads the 
* value prop of the direct child to fetch value for individual tab/radio.
* Read More https://github.com/mui-org/material-ui/issues/2225#issuecomment-460041878
*/

interface WireProps<T> {
   value: T;
}

function WireProps<T>({
   children,
   ...props
}: WireProps<T> & { children: (props: WireProps<T>) => JSX.Element }
) {
   return children(props);
}

export default WireProps;
