import {
    PhotoSwipeAction,
    CLOSE_PHOTO_SWIPE,
    OPEN_PHOTO_SWIPE,
    SET_PHOTO_SWIPE_OPTIONS,
} from '../actions/PhotoSwipe.action';
import { PhotoSwipeState } from '../types';

export const photoSwipeStateInitData: PhotoSwipeState = {
    isOpen: false,
    options: undefined,
    urls: []
};

export function photoSwipeState(
    state: PhotoSwipeState = photoSwipeStateInitData,
    action: PhotoSwipeAction
): PhotoSwipeState {
    switch (action.type) {
    case CLOSE_PHOTO_SWIPE: {
        return photoSwipeStateInitData;
    }
    case OPEN_PHOTO_SWIPE: {
        return {
            ...state,
            isOpen: true,
            urls: action.urls,
            options: action.options
        };
    }
    case SET_PHOTO_SWIPE_OPTIONS: {
        return { ...state, options: action.options };
    }
    default:
        return state;
    }
}
