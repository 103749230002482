import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';

import { DATA_PRIVATE } from '../../../../styles/Helper.styles';

import { GatherCaseUX, UserProfile } from '../../../../shared/types';

import { SnackBarVariant } from '../../../../types';

import { NULL_STRING } from '../../../../constants';
import { useTaskDialogStyles } from '../TaskDialogsStyles';

import {
    emptyStringParser,
    getFullNameFromUser,
    getFullNameFromCase,
    formatDateString
} from '../../../../services/app.service';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    setAppSnackbar: (message: string | JSX.Element, variant: SnackBarVariant) => void;
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
    userData: UserProfile | null;
}

const ATTService = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
        userData,
        setAppSnackbar
    } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Text copied to your clipboard', 'success');
    };

    const renderEmailBody = (
        _helperName: string,
        _ssn: string
    ) => {
        return `Hi, my name is ${_helperName}.

${getFullNameFromCase(activeCase)} recently passed away on ${formatDateString(activeCase.dod_start_date || '')} ` +
            `and I would like to cancel their AT & T service, effective immediately.${activeCase.fname} 's ` +
            `social security number was ${_ssn} and ${activeCase.fname}'s phone number was ${NULL_STRING}.`;
    };

    const aTTServiceContact: string = '800-331-0500';
    const aTTChatServiceLink: string = 'https://www.att.com/contactus/index/wireless.html?tab=1';
    const attStoreLoaction: string = 'https://www.att.com/store-locator/index.html';

    const ssn: string = activeCase.death_certificate
        && activeCase.death_certificate.about.ssn
        && emptyStringParser(activeCase.death_certificate.about.ssn)
        ? activeCase.death_certificate.about.ssn : `${NULL_STRING}${NULL_STRING}`;

    const birthDate = activeCase.dob_date !== '' ? activeCase.dob_date : '___';
    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;

    const emailBody: string = renderEmailBody(helperName, ssn);
    const tooltipText = `This will copy this text to your device clipboard so that you can paste it 
                            in another program.`;

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    There are a number of options to cancel {activeCase.fname}’s  AT&T service. The first
                    option is to call AT&amp;T and let them know that {activeCase.fname} passed away.
                    Their number is 800-331-0500 (or just dial 611 from an AT&T phone).
                </Typography>
                <a
                    href={`tel: ${aTTServiceContact}`}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop20
                        )}
                    >
                        CALL AT&amp;T
                    </Button>
                </a>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                    align="left"
                >
                    Another option is to send a chat to AT&amp;T's customer support. Simply click
                    the link below and then click on the "Chat Available" button. To make things as
                    easy as possible for you, we've already typed up the chat message that you can
                    send them to get started.
                </Typography>
            </Grid>

            <a
                href={aTTChatServiceLink}
                className={classNames(
                    classes.cursorPointer,
                    classes.textDecorationNone,
                )}
                target="blank"
            >
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classNames(
                        classes.button,

                        classes.marginTop20
                    )}
                >
                    Chat with AT&amp;T
                </Button>
            </a>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Hi, my name is {helperName} .
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20,
                        DATA_PRIVATE
                    )}
                >
                    {getFullNameFromCase(activeCase)} recently passed away
                    on {birthDate} and I would like to cancel their AT&amp;T
                    service, effective immediately. {activeCase.fname}'s social security number was {ssn}&nbsp;
                    and {activeCase.fname}'s phone number was {NULL_STRING}.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(classes.marginAuto, DATA_PRIVATE)}
            >
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.marginTop12}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this text
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>

            <Grid
                item
                className={classes.marginTop20}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.textLeft
                    )}
                >
                    The last option is to visit an authorized AT&amp;T store.
                    You will need to bring your photo ID and {activeCase.fname}'s death certificate.
                </Typography>

                <a
                    href={attStoreLoaction}
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop20
                        )}
                    >
                        Click to find an AT&amp;T location <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop30,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    Some things to remember...
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        <li>
                            Any balance on {activeCase.fname}'s account is the responsibility of the estate.
                        </li>
                        <li>
                            Early termination and/or Transfer of Billing Responsibility fees won't be charged
                            on {activeCase.fname}'s line of service.
                        </li>
                        <li>
                            The account can't remain active under {activeCase.fname}'s name and Social
                            Security number, with the exception of customers living in Oklahoma.
                            A Transfer of Billing Responsibility is required to keep the
                            wireless number with AT&amp;T service.
                        </li>
                        <li>
                            Installment plans may be adjusted for all lines of service.
                        </li>
                        <li>
                            Installment plans may be adjusted for an individual wireless line
                            if {activeCase.fname} was a  wireless user on the account.
                        </li>
                    </Typography>
                </Grid>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default ATTService;
