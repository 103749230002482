import { getFromAPI } from ".";
import {
    S3FileCreateRequest,
    S3FileUploadPresignUrlResponse,
    S3FileUploadRequest,
    UploadFileRequest,
} from "../shared/types";
import { AppDispatch } from "../store";
import { registerAppError } from "./errors";

export async function uploadFileToS3(
    fileRequest: UploadFileRequest,
    preSignURLRoute: string,
    dispatch: AppDispatch,
) {

    const presignUrlResponse = await getFromAPI<S3FileUploadPresignUrlResponse>(preSignURLRoute, dispatch);
    if (!presignUrlResponse) {
        return null;
    }
    const preSignedURL = presignUrlResponse.presignedurl;
    const fileUploadRequest: S3FileUploadRequest = {
        ...fileRequest,
        path: presignUrlResponse.path,
        presignedurl: presignUrlResponse.presignedurl,
        encryptionType: presignUrlResponse.encryptionType,
        encryptionKeyId: presignUrlResponse.encryptionKeyId,
    };
    if (preSignedURL === null || fileUploadRequest.type === null) {
        return null;
    }
    try {
        const response = await fetch(
            preSignedURL, {
                headers: {
                    'Content-Type': fileUploadRequest.type,
                    'x-amz-server-side-encryption': presignUrlResponse.encryptionType,
                    'x-amz-server-side-encryption-aws-kms-key-id': presignUrlResponse.encryptionKeyId || '',
                },
                method: 'PUT',
                body: fileUploadRequest.filecontents,
            });
        if (response.status === 200) {
            return fileUploadRequest;
        } else {
            console.warn(response);
            return null;
        }
    } catch (e) {
        return null;
    }
}

export function uploadS3FileToS3(params: {
    uploadRequest: UploadFileRequest;
    preSignURLRoute: string;
}) {
    return async (dispatch: AppDispatch): Promise<S3FileCreateRequest | null> => {
        const { uploadRequest, preSignURLRoute } = params;

        const s3DocUploadRequest = await uploadFileToS3(
            uploadRequest,
            preSignURLRoute,
            dispatch,
        );
        if (!s3DocUploadRequest) {
            dispatch(registerAppError('Failed to upload file'));
            return null;
        }
        const s3CreateRequest: S3FileCreateRequest = {
            name: s3DocUploadRequest.name,
            hash: s3DocUploadRequest.hash,
            size: s3DocUploadRequest.size,
            suffix: s3DocUploadRequest.suffix || '',
            mimetype: s3DocUploadRequest.type,
            path: s3DocUploadRequest.path,
        };
        return s3CreateRequest;
    };
}
