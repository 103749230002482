import { useCallback } from 'react';
import { setAppSnackbar } from '../../../actions/AppSnackbar.action';
import { useGDispatch } from '../../../types';

const useSnackbar = () => {
    const dispatch = useGDispatch();

    const showSnackbar = useCallback(
        (...params: Parameters<typeof setAppSnackbar>) => dispatch(setAppSnackbar(...params)),
        [dispatch],
    );
    return showSnackbar;
};

export default useSnackbar;
