import {
    RolodexAction,
    SET_ROLODEX_ACTIVE_ENTRY,
    SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE,
    SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE,
    ROLODEX_REPORT_IS_LOADING,
    LOADED_ROLODEX_SEARCH_RESULTS,
    LOADED_ROLODEX_REPORT_DATA,
    ROLODEX_SEARCH_IS_LOADING,
    REMOVE_ROLODEX_ENTRY,
    REMOVE_ROLODEX_CONTACT,
} from '../actions/Rolodex.action';
import { RolodexState, RolodexTableReduxState } from '../types';

export const rolodexTableDefaultState: RolodexTableReduxState = {
    data: [],
    hasMoreData: true,
    searchText: '',
    sortBy: 'id',
    sortDirection: 'asc',
    isLoading: false,
    totalEntryCount: 0,
};

export const initRolodexState: RolodexState = {
    createEditDialogOpen: false,
    listDialogOpen: false,
    zIndex: 1350,
    activeEntry: null,
    searchResults: null,
    searchResultOrgCategories: [],
    reportData: rolodexTableDefaultState,
    searchIsLoading: false,
    inputValue: null
};

export const rolodexState = (state: RolodexState = initRolodexState, action: RolodexAction): RolodexState => {
    switch (action.type) {
        case SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE:
            return {
                ...state,
                createEditDialogOpen: action.isOpen,
            };
        case SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE:
            return {
                ...state,
                listDialogOpen: action.isOpen,
                inputValue: action.inputValue || null
            };
        case SET_ROLODEX_ACTIVE_ENTRY:
            return {
                ...state,
                activeEntry: action.entry,
            };
        case LOADED_ROLODEX_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.searchResults,
                searchResultOrgCategories: action.searchResultCategories,
            };
        case LOADED_ROLODEX_REPORT_DATA: {
            const data = action.update ? [...state.reportData.data, ...action.reportData] : action.reportData;
            const reportData: RolodexTableReduxState = {
                ...state.reportData,
                data,
                hasMoreData: action.hasMoreData,
                searchText: action.searchText,
                sortBy: action.sortBy,
                sortDirection: action.sortDirection,
                totalEntryCount: action.totalCount,
            };

            return {
                ...state,
                reportData,
            };
        }
        case ROLODEX_REPORT_IS_LOADING: {
            const reportData: RolodexTableReduxState = {
                ...state.reportData,
                isLoading: action.isLoading,
            };
            return {
                ...state,
                reportData,
            };
        }
        case ROLODEX_SEARCH_IS_LOADING:
            return {
                ...state,
                searchIsLoading: action.isLoading,
            };
        case REMOVE_ROLODEX_ENTRY:
            return {
                ...state,
                activeEntry: null,
                reportData: {
                    ...state.reportData,
                    data: state.reportData.data.filter((r) => {
                        if (r.entryType === 'Organization') {
                            return r.id !== action.entry.organization.id;
                        } else {
                            return action.entry.contacts.every((contact) => contact.id !== r.id);
                        }
                    }),
                    totalEntryCount: state.reportData.totalEntryCount - 1 - action.entry.contacts.length,
                },
            };
        case REMOVE_ROLODEX_CONTACT:
            return {
                ...state,
                reportData: {
                    ...state.reportData,
                    data: state.reportData.data.filter((r) => r.id !== action.contactId || r.entryType !== 'Contact'),
                    totalEntryCount: state.reportData.totalEntryCount - 1,
                },
            };
        default:
            return state;
    }
};
