import { Fragment, MouseEvent } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import { AppRoute, convertHexToRGBA, getIntercomTargetProp } from '../../services';
import makeGStyles from '../../styles/makeGStyles';
import GLink from './GLink';

const useStyles = makeGStyles(theme => ({
    root: {
        width: '100%',
        background: convertHexToRGBA(theme.palette.primary.main, 0.18),
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'inset 0px 0px 6px 0px rgb(0 0 0 / 20%)',
        padding: '16px 0',
        '& hr': {
            width: 48,
            marginTop: 17,
            alignSelf: 'center'
        },
    },
    tab: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        maxWidth: 36,
        '& p': {
            whiteSpace: 'nowrap',
            fontSize: 10,
            margin: '2px 0'
        },
    },
    selected: {
        '& p, svg, span': {
            color: theme.palette.primary.main,
        },
        '& p': {
            fontWeight: 500
        },
        '& svg, span': {
            filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))'
        },
    },
    disabled: {
        opacity: 0.6,
        cursor: 'default',
    },
}), { name: 'TabsSelector' });

export interface TabType {
    key: React.Key;
    element: JSX.Element;
    className?: string;
    disabled?: boolean;
    disabledTooltip?: string;
    isNew?: boolean;
    link?: AppRoute;
}

interface Props {
    tabs: TabType[];
    selectedKey: React.Key;
    tabContainerClass?: string;
    onClick?: (key: number | string, event: MouseEvent<HTMLElement>) => void;
    rootClass?: string;
    selectedTabClass?: string;
    intercomTargetProp?: string;
    intercomTargetPropTabName?: string;
}

function TabsSelector(props: Props) {
    const {
        selectedKey,
        tabs,
        tabContainerClass,
        rootClass,
        selectedTabClass,
        onClick,
        intercomTargetProp,
        intercomTargetPropTabName
    } = props;

    const classes = useStyles();

    const wrapWithLink = (content: JSX.Element, link: AppRoute) =>
        <GLink useNavLink={true} to={link}>
            {content}
        </GLink>;

    const renderTab = (tab: TabType) =>
        <Tooltip title={tab.disabledTooltip || ''} enterDelay={300}>
            <Grid
                item
                className={classNames(
                    classes.tab,
                    selectedKey === tab.key && classNames(classes.selected, selectedTabClass),
                    tabContainerClass,
                    tab.disabled && classes.disabled,
                    tab.className
                )}
                onClick={tab.disabled || !onClick ? undefined : (e) => onClick(tab.key, e)}
                {...getIntercomTargetProp(intercomTargetPropTabName ? `${intercomTargetPropTabName}-${tab.key}` : ``)}
            >
                {tab.element}
            </Grid>
        </Tooltip>;

    return (
        <Grid
            item xs={12}
            className={classNames(classes.root, rootClass)}
            {...getIntercomTargetProp(intercomTargetProp ? `${intercomTargetProp}-TabSelector` : ``)}
        >
            {tabs.map((tab, i) =>
                <Fragment key={tab.key}>
                    {i !== 0 && <Divider />}

                    {tab.link && !tab.disabled
                        ? wrapWithLink(renderTab(tab), tab.link)
                        : renderTab(tab)}
                </Fragment>
            )}
        </Grid>
    );
}

export default TabsSelector;
