import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { SlideTransition } from './Transitions';
import useFullScreen from './hooks/useFullScreen';
import { Breakpoint, SxProps, Theme } from '@mui/material/styles';

export interface Props {
    isOpen: boolean;
    zIndex: number;
    title?: string | JSX.Element;
    rootClass?: string;
    paperClass?: string;
    contentClass?: string;
    titleClass?: string;
    scroll?: 'body' | 'paper';
    disableTypography?: boolean;
    contentId?: string;
    hideBackdrop?: boolean;
    backdropClass?: string;
    fullScreenBreakpoint?: number | Breakpoint;
    sx?: SxProps<Theme>;
    dialogContentRef?: React.Ref<HTMLDivElement>;
    onClose: () => void;
    onComponentDidMount?: () => void;
}

const GDialogCore = (props: React.PropsWithChildren<Props>) => {
    const {
        isOpen,
        title,
        children,
        zIndex,
        rootClass,
        contentClass,
        titleClass,
        paperClass,
        onClose,
        scroll,
        contentId,
        hideBackdrop,
        backdropClass,
        fullScreenBreakpoint,
        sx,
        dialogContentRef,
        // disableTypography,
        onComponentDidMount
    } = props;

    React.useEffect(() => {
        if (onComponentDidMount) {
            onComponentDidMount();
        }
    }, [onComponentDidMount]);

    const fullScreen = useFullScreen(fullScreenBreakpoint);

    return (
        <Dialog
            scroll={scroll}
            open={isOpen}
            fullScreen={fullScreen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={onClose}
            className={rootClass}
            classes={{
                paper: paperClass,
            }}
            BackdropProps={{ className: backdropClass }}
            hideBackdrop={hideBackdrop}
            style={{ zIndex }}
            sx={sx}
        >
            {title &&
                <DialogTitle
                    className={titleClass}
                // disableTypography={disableTypography}
                >
                    {title}
                </DialogTitle>
            }
            <DialogContent className={contentClass} id={contentId} ref={dialogContentRef}>
                {isOpen && children}
            </DialogContent>
        </Dialog>
    );
};

export default GDialogCore;
