import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { DateTimeFormat, getIntercomTargetProp, RouteBuilder } from "../../../services";
import { UserRoles, EventType, GatherEvent, GatherCaseUX, FeatureKey } from "../../../shared/types";
import GLink from "../../common/GLink";
import GPopper from "../../common/GPopper";
import PublicPrivateSwitch from "../PublicPrivateSwitch";
import { useGDispatch, useGSelector } from "../../../types";
import makeGStyles from "../../../styles/makeGStyles";
import moment from "moment-timezone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { updateEvent } from "../../../actions/GatherEvent.action";
import GButton from "../../common/GButton";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { WhenEnabled } from "../../common/AppFeature";

const useStyles = makeGStyles(theme => ({
    familyMessage: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        padding: 16,
        '& p': {
            fontSize: 16,
            textAlign: 'center',
        }
    },
    addMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        padding: '16px 0'
    },
    switchContainer: {
        paddingTop: 24,
        margin: '0 auto 6px'
    },
    eventTimeContainer: {
        padding: '8px 4px',
        '& $eventTime': {
            fontSize: 20,
            lineHeight: 1.6,
        },
        '& $accessTimeIcon': {
            verticalAlign: 'text-top',
            marginRight: 5,
            fontSize: 24,
        },
        '& $eventDateTime': {
            fontSize: 16,
            lineHeight: '18px'
        }
    },
    addressContainer: {
        padding: '8px 4px',
        textAlign: 'center',
        display: 'table',
        width: 'calc(100% - 8px)',
        '& $eventAddress': {
            display: 'inline-block',
            fontSize: 16,
            fontWeight: 400,
            verticalAlign: 'middle',
            lineHeight: '18px',
        },
        '& $locationIcon': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontSize: 60,
        },
        '& $liveStream': {
            borderLeft: 'none',
            padding: '12px 0 8px',
            flexDirection: 'row',
            display: 'flex',
            fontWeight: 400,
            fontSize: 16,
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '1.1em',
            margin: 'auto',
            '& svg': {
                marginRight: 8,
                fontSize: 36
            },
        },
    },
    editDetailsContainer: {
        padding: '8px 4px',
        textAlign: 'center'
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
    },
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        width: 310,
        boxShadow: theme.shadows[10],
        '@media (min-width: 340px)': {
            width: 320,
        },
    },
    popperContent: {
        padding: '0 8px',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    liveStream: {},
    locationIcon: {},
    eventAddress: {},
    eventTime: {},
    accessTimeIcon: {},
    eventDateTime: {}
}), { name: 'EventDetailsPopper' });

interface Props {
    popperAnchor: HTMLElement | null;
    zIndex: number;
    event: GatherEvent;
    activeCase: GatherCaseUX;
    onClose: () => void;
    onMessageClick: () => void;
    onEditClick: () => void;
    onPageRedirect: () => void;
}

const EventDetailsPopper = (props: Props) => {
    const { zIndex, event, activeCase, popperAnchor, onClose, onMessageClick, onEditClick, onPageRedirect } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const userData = useGSelector(({ userSession }) => userSession.userData);
    const eventLocation = useGSelector(({ locationState }) =>
        locationState.locations.find(location => location.id === event.location_id)
    );

    const handleViewChange = (isPrivate: boolean) => {
        dispatch(updateEvent(event.id, { is_private: isPrivate }, activeCase.uuid));
    };

    const isFHorGOMUser = UserRoles.isFHorGOMUser(userData);

    const tooltipTitle = 'Invite Only events can ONLY be viewed by logged in Admins and Guests';

    const timezone = moment.tz.guess() || eventLocation && eventLocation.address.timezone
        || activeCase.funeral_home.timezone || 'America/Denver';
    const startMoment = moment(event.start_time).tz(timezone);
    const isPastEvent = startMoment.toDate() < new Date();
    const date = startMoment.format(DateTimeFormat.LongDate);

    const eventTimeZone = startMoment.format('z');
    let eventTime = startMoment.format('h:mm');
    let eventTimePeriod = startMoment.format('a');

    if (event.end_time && eventTime && eventTimePeriod) {
        const endMoment = moment(event.end_time).tz(timezone);
        eventTime = `${eventTime}-${endMoment.format('h:mm')}`;
        eventTimePeriod = endMoment.format('a');
    }

    return (
        <GPopper
            anchorEle={popperAnchor}
            className={classes.popper}
            popperContentClass={classes.popperContent}
            paperClass={classes.popoverPaper}
            zIndex={zIndex}
            closePopper={onClose}
            placement='bottom'
        >
            {event.event_type !== EventType.arrangement && (
                <PublicPrivateSwitch
                    isPrivate={event.is_private}
                    tooltipTitle={tooltipTitle}
                    customClass={classes.switchContainer}
                    handleViewChange={handleViewChange}
                />
            )}

            {!!event.message &&
                <Grid item xs={12} className={classes.familyMessage}>
                    <Typography
                        color="primary"
                        className={isFHorGOMUser ? classes.cursorPointer : undefined}
                        onClick={isFHorGOMUser ? onMessageClick : undefined}
                    >
                        <i>
                            "{event.message}"
                        </i>
                    </Typography>
                </Grid>
            }

            {!event.message && isFHorGOMUser && event.event_type === EventType.arrangement &&
                <Grid item xs={12} className={classes.addMessage}>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={onMessageClick}
                    >
                        add a message to the family
                    </Button>
                </Grid>
            }

            <div className={classes.eventTimeContainer}>
                <Typography
                    color="primary"
                    align="center"
                    className={classNames(classes.eventTime, isFHorGOMUser && classes.cursorPointer)}
                    onClick={isFHorGOMUser ? onEditClick : undefined}
                >
                    {isPastEvent
                        ? <EventAvailableIcon className={classes.accessTimeIcon} />
                        : <AccessTimeIcon className={classes.accessTimeIcon} />
                    }
                    {isPastEvent && startMoment.fromNow()}
                    {!isPastEvent && `Begins in ${startMoment.toNow(true)}`}
                </Typography>
                <Typography
                    color="primary"
                    align="center"
                    className={classes.eventDateTime}
                >
                    {date}
                </Typography>
                <Typography
                    color="primary"
                    align="center"
                    className={classes.eventDateTime}
                >
                    {eventTime}{eventTimePeriod} {eventTimeZone}
                </Typography>
            </div>

            {(eventLocation || event.is_streamable) &&
                <Divider className={classNames(classes.backgroundPrimary)} />
            }

            <div
                className={classNames(
                    classes.addressContainer,
                    (!eventLocation && !event.is_streamable) && classes.padding0
                )}
            >
                {eventLocation &&
                    <div
                        className={classNames(isFHorGOMUser && classes.cursorPointer)}
                        onClick={isFHorGOMUser ? onEditClick : undefined}
                    >
                        <LocationOnIcon color="primary" className={classes.locationIcon} />
                        <Typography
                            color="secondary"
                            align="center"
                            className={classes.eventAddress}
                        >
                            {eventLocation.name &&
                                <span className={classes.displayBlock}>
                                    {eventLocation.name}
                                </span>
                            }
                            {eventLocation.address.address1 &&
                                <span className={classes.displayBlock}>
                                    {eventLocation.address.address1}
                                </span>
                            }
                            {eventLocation.address.address2 &&
                                <span className={classes.displayBlock}>
                                    {eventLocation.address.address2}
                                </span>
                            }
                            {eventLocation.address.city && eventLocation.address.state &&
                                <span className={classes.displayBlock}>
                                    {`${eventLocation.address.city}, ${eventLocation.address.state}`}
                                </span>
                            }
                        </Typography>
                    </div>
                }

                {event.is_streamable &&
                    <GLink to={RouteBuilder.RememberPage(activeCase.name)}>
                        <Typography
                            className={classes.liveStream}
                            color="primary"
                            onClick={onPageRedirect}
                        >
                            <LiveTvIcon color="primary" />&nbsp;

                            <span>Live Stream Available</span>
                        </Typography>
                    </GLink>
                }

                {(event.is_streamable && isFHorGOMUser) &&
                    <WhenEnabled feature={FeatureKey.LIVE_STREAM}>
                        <GButton
                            size="small"
                            variant="outlined"
                            text='Order LiveStream DVD or USB'
                            startIcon={<OndemandVideoIcon />}
                            {...getIntercomTargetProp('EventDetails-LiveStreamDVD')}
                        />
                    </WhenEnabled>
                }

            </div>

            {isFHorGOMUser && (
                <>
                    <Divider className={classes.backgroundPrimary} />

                    <div className={classes.editDetailsContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            className={classes.margin_8}
                            onClick={onEditClick}
                        >
                            Edit event details
                        </Button>
                    </div>
                </>
            )}
        </GPopper>
    );
};

export default EventDetailsPopper;