import React from 'react';
import classNames from 'classnames';

import ButtonBase from '@mui/material/ButtonBase';
import { Grid, SxProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getIntercomTargetProp } from '../../services';

const useStyles = makeStyles(theme => ({
    isFlipped: {
        '&$cardBody': {
            transform: 'translateX(-100%) rotateY(-180deg)',
            '-ms-transform': 'translateX(-100%) rotateY(-180deg)',
            '-webkit-transform': 'translateX(-100%) rotateY(-180deg)',
        },
    },
    cardBody: {
        transformStyle: 'preserve-3d',
        transformOrigin: 'center right',
        transition: 'transform 1s',
        '-webkit-transition': 'transform 1s',
        '& $cardContent': {
            position: 'absolute',
            zIndex: 1,
            height: 'inherit',
            width: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
            MozBackfaceVisibility: 'hidden',
            MozTransform: 'rotateX(0)',
        },
    },
    backside: {
        transform: 'rotateY(180deg) !important',
    },
    cardContent: {},
}), { name: 'FlipCard' });

interface Props {
    id: string;
    isCardFlipped: boolean; // TODO: Make this clearer which side is visible (front or back)
    buttonStyles?: React.CSSProperties;
    buttonClasses?: string;
    frontSideOnClick?: () => void;
    frontSideContent: JSX.Element;
    frontSideClasses?: string;
    backSideContent: JSX.Element;
    backSideClasses?: string;
    disableRipple?: boolean;
    buttonSX?: SxProps<Theme>;
    backSideSX?: SxProps<Theme>;
    intercomTargetPropFlipCard?: string;
}

const FlipCard = (props: Props) => {
    const {
        frontSideContent,
        backSideContent,
        backSideClasses,
        frontSideClasses,
        buttonClasses,
        buttonStyles,
        id,
        isCardFlipped,
        buttonSX,
        frontSideOnClick,
        disableRipple,
        intercomTargetPropFlipCard,
        backSideSX: backsideSX
    } = props;

    const classes = useStyles();

    const renderFrontSide = () => (
        <div
            className={classNames(classes.cardContent, frontSideClasses)}
            onClick={frontSideOnClick}
        >
            {frontSideContent}
        </div>
    );

    const renderBackSide = () => (
        <Grid
            sx={backsideSX}
            className={classNames(classes.cardContent, classes.backside, backSideClasses)}
        >
            {backSideContent}
        </Grid>
    );

    return (
        <ButtonBase
            className={classNames(
                classes.cardBody,
                buttonClasses,
                isCardFlipped && classes.isFlipped
            )}
            sx={buttonSX}
            id={id}
            style={buttonStyles}
            disableRipple={disableRipple}
            {...getIntercomTargetProp(intercomTargetPropFlipCard)}
        >
            {renderFrontSide()}
            {renderBackSide()}
        </ButtonBase>
    );
};

export default FlipCard;