import { Component } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import { DATA_PRIVATE } from '../../../../../styles/Helper.styles';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        margin: '12px 12px 24px 10px',
        '&:first-of-type': {
            margin: '12px 12px 24px 24px',
            '@media (min-width: 960px)': {
                margin: '12px 12px 24px 10px',
            }
        },
        '&:last-child': {
            margin: '12px 24px 24px 10px',
            '@media (min-width: 960px)': {
                margin: '12px 12px 24px 10px',
            }
        },
    },
    paddingLeft8: {
        paddingLeft: 8,
    },
    card: {
        width: 400,
        height: 518,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0,
        boxShadow: theme.shadows[9],
        [DATA_PRIVATE]: true,  // hide from Fullstory
        '@media (min-width: 500px)': {
            width: 550,
            height: 710,
        },
        '@media (min-width: 960px)': {
            maxWidth: 1200,
            width: '100%',
            height: 'auto',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& p': {
            fontSize: 12,
        }
    },
    thumbnailView: {
        margin: '0 6px !important',
        '& $card': {
            width: 77,
            height: 100,
            boxShadow: theme.shadows[4],
        },
        '& $header': {
            width: 77,
            '& p': {
                fontSize: 8,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        }
    },
    isStackedView: {
        height: '100%',
        width: '100%',
        margin: '0 !important',
        position: 'absolute',
        '& $card': {
            width: 154,
            height: 200,
            boxShadow: theme.shadows[4],
            position: 'absolute',
            backgroundColor: 'transparent',
            '&$omitCanvasRendering': {
                backgroundColor: theme.palette.common.white,
            }
        },
        '& $header': {
            display: 'none'
        }
    },
    omitCanvasRendering: {}
});

type Props = {
    totalPages: number;
    pageNumber: number;
    pdf: PDFDocument;
    omitCanvasRendering?: boolean;
    thumbnailView?: boolean;
    isStackedView?: boolean;
    hidePageNumber?: boolean;
    fullWidth?: boolean;
};

// PDF types are a subset of @types/pdfjs-dist
export interface PDFDocument {
    numPages: number;
    getPage(page: number): Promise<PDFPage>;
}

interface PDFPromise<T> {
    promise: Promise<T>;
}

interface PDFPage {
    pageNumber: number; // 1-based indexing
    getViewport(params: { scale: number }): PDFPageViewport;
    render(params: PDFRenderParams): PDFPromise<void>;
}

interface PDFPageViewport {
    width: number;
    height: number;
    scale: number;
    transforms: number[];
}

interface PDFRenderParams {
    canvasContext: CanvasRenderingContext2D;
    viewport: PDFPageViewport;
}

type StyledProps = Props & WithStyles<'root' | 'card' | 'header' | 'thumbnailView' | 'isStackedView'
    | 'omitCanvasRendering' | 'paddingLeft8'>;

const getStackedStyles = (
    pageNumber: number,
    isStackedView?: boolean
) => {
    const PAGE_OFFSET = 6;
    const MAX_PAGE_COUNT = 5;

    // pageNumber--;

    if (isStackedView) {
        if (pageNumber <= MAX_PAGE_COUNT) {
            return {
                top: pageNumber * PAGE_OFFSET || 0,
                right: pageNumber * PAGE_OFFSET || 0,
                zIndex: -pageNumber
            };
        }
        if (pageNumber >= MAX_PAGE_COUNT) {
            return { display: 'none' };
        }
    }

    return undefined;
};

class DocPreviewCard extends Component<StyledProps> {

    private canvas: HTMLCanvasElement | null;

    async componentDidMount() {
        const { pdf, pageNumber, omitCanvasRendering, fullWidth } = this.props;

        if (omitCanvasRendering) {
            return;
        }

        const page = await pdf.getPage(pageNumber);

        const MAX_WIDTH = fullWidth ? 1400 : 1200;
        const viewportWidth = window.innerWidth;
        const margin = 24 * 2;

        const pdfViewport = page.getViewport({ scale: 1 });

        const viewportPercent = fullWidth ? 1 : (60 / 100); // 60% of viewport width if it is /doc page
        let canvasWidth = (viewportWidth * viewportPercent - margin);
        canvasWidth = canvasWidth < MAX_WIDTH ? canvasWidth : MAX_WIDTH;

        const pdfRatio = pdfViewport.width / pdfViewport.height;
        const canvasHeight = canvasWidth / pdfRatio; // Maintain the PDF ratio (i.e. 8.5x11)

        const newScale = canvasWidth / pdfViewport.width;
        const scaledViewport = page.getViewport({ scale: newScale });

        if (!this.canvas) {
            console.warn('no canvas!');
            return;
        }
        const context = this.canvas.getContext('2d');
        if (!context) {
            console.warn('no context!');
            return;
        }
        this.canvas.height = canvasHeight;
        this.canvas.width = canvasWidth;
        //
        // Render PDF page into canvas context
        //
        await page.render({
            canvasContext: context,
            viewport: scaledViewport,
        }).promise;
    }

    render() {
        const {
            classes,
            pageNumber,
            totalPages,
            thumbnailView,
            isStackedView,
            omitCanvasRendering,
            hidePageNumber
        } = this.props;

        const _canvas = (<canvas
            ref={(canvas) => this.canvas = canvas}
            className={classNames(
                classes.card,
                omitCanvasRendering && classes.omitCanvasRendering
            )}
            style={getStackedStyles(pageNumber, isStackedView)}
        />);

        return (
            <div
                className={classNames(
                    classes.root,
                    thumbnailView && classes.thumbnailView,
                    isStackedView && classes.isStackedView,
                    !isStackedView && classes.paddingLeft8
                )}
            >
                {!hidePageNumber &&
                    <div className={classes.header}>
                        <Typography color="secondary">
                            Page {pageNumber} of {totalPages}
                        </Typography>
                    </div>
                }

                <Zoom in timeout={800}>{_canvas}</Zoom>
            </div>
        );
    }
}

export default withStyles(styles)(DocPreviewCard);
