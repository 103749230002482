import classNames from "classnames";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { MouseEventHandler, useState } from 'react';

import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import DragHandleIcon from "@mui/icons-material/DragHandle";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import makeGStyles from "../../../../styles/makeGStyles";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WorkFlowSummaryWrapper, { WorkFlowSummaryWrapperClasses } from "../../WorkFlowSummaryWrapper";
import { HasIdProperty } from "../../../../shared/types";
import { TaskListTypeEnum } from "../../../family/tasks/DetailedLayout/WorkflowListSummary";
import AddIcon from '@mui/icons-material/Add';
import Collapse from "@mui/material/Collapse";
import { getIntercomTargetProp } from "../../../../services";

const useStyles = makeGStyles(theme => ({
    btnContainer: {
        width: 'fit-content',
        marginLeft: 'auto'
    },
    button: {
        width: 24,
        height: 24,
        '& svg': {
            fontSize: 20
        }
    },
    taskList: {
        padding: '0 4px 0 6px',
    },
    title: {
        flex: 1,
        fontSize: 16,
        marginLeft: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    item: {
        padding: '6px 0'
    },
    visibilityIcon: {
        margin: '0 4px'
    },
    emptyState: {
        width: 240,
        margin: '52px auto',
        textAlign: 'center',
    },
    addButton: {
        fontSize: 16,
        marginTop: 1
    },
    paddingBottom0: {
        paddingBottom: 0
    },
    loadButton: {
        fontSize: 16,
        padding: '8px 0',
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        transition: 'min-height 0.3s',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    flexButton: {
        display: 'flex',
        justifyContent: 'center'
    },
    hideButton: {
        minHeight: 0,
        visibility: 'hidden',
    },
    overflowYAuto: {
        overflowY: 'auto'
    }
}), { name: 'TaskList' });

interface SortableProps {
    children: JSX.Element;
}

const DragHandle = SortableHandle(DragHandleIcon);
const SortableItem = SortableElement(({ children }: SortableProps) => children);
const SortableList = SortableContainer(({ children }: SortableProps) => children);

interface TaskList extends HasIdProperty {
    title: string;
    visible_to_family: boolean;
}

interface Props<T extends TaskList> {
    tasks: T[];
    workflowName: string;
    onEditClick?: (task: T) => void;
    onDeleteClick?: (task: T) => void;
    onSortEnd?: (oldIndex: number, newIndex: number) => void;
    taskListClass?: string;
    heading: TaskListTypeEnum;
    description: JSX.Element;
    onMenuIconClick?: MouseEventHandler<HTMLElement>;
    workflowSummaryWrapperClasses?: Partial<WorkFlowSummaryWrapperClasses>;
    footerContent?: JSX.Element;
    showLoadMoreButton?: boolean;
    featureDisabled?: boolean;
}

const TaskList = <T extends TaskList>(props: Props<T>) => {
    const {
        tasks,
        workflowName,
        onSortEnd,
        onEditClick,
        onDeleteClick,
        taskListClass,
        heading,
        description,
        onMenuIconClick,
        workflowSummaryWrapperClasses,
        footerContent,
        showLoadMoreButton,
        featureDisabled,
    } = props;

    const classes = useStyles();

    const [showAllTasks, setShowAllTasks] = useState(tasks.length <= 10 || !showLoadMoreButton);

    const removeBtnTooltip = `Remove this task from the ${heading} List on the ${workflowName} WorkFlow.`;
    const editBtnTooltip = 'Edit the details of this task. Changes will apply to all uses of this '
        + ' task across all WorkFlows.';

    const isSortable = onSortEnd !== undefined;
    const taskCount = tasks.length;
    const visibleTasks = !showAllTasks ? tasks.slice(0, 10) : tasks;
    const visibleStep = heading === TaskListTypeEnum.tracking ? 'Step' : 'Task';

    const renderEmptyState = () => {
        let text = featureDisabled
            ? `Your ${heading} are disabled. Contact Gather to enable.`
            : `This WorkFlow doesn't have any default ${heading}`;

        return (
            <Typography color="secondary" className={classes.emptyState}>
                {text}
            </Typography>
        );
    };

    const wrapWithSortableList = (content: JSX.Element) =>
        <SortableList
            lockAxis="y"
            lockToContainerEdges
            useDragHandle
            onSortEnd={(sort, evt) => onSortEnd?.(sort.oldIndex, sort.newIndex)}
        >
            {content}
        </SortableList >;

    const renderListItem = (task: T) => {
        const tooltipTitle = heading === TaskListTypeEnum.tracking
            ? 'The default for this tracking step is set to not visible to the family but can be adjusted per case.'
            : `Task not visible to family`;
        return (
            <ListItem
                disableGutters
                className={classNames(classes.item, taskListClass)}
                key={task.id}
                {...getIntercomTargetProp(`WorkFlowSelection-TaskDetail`)}
            >
                {onSortEnd && <DragHandle color="primary" />}

                <Typography color="secondary" className={classes.title}>
                    {task.title}
                </Typography>

                <Grid container alignItems="center" className={classes.btnContainer}>
                    {!task.visible_to_family &&
                        <Tooltip
                            title={tooltipTitle}
                            placement="top"
                            enterDelay={600}
                        >
                            <VisibilityOffIcon
                                className={classes.visibilityIcon}
                                fontSize="small"
                                color="secondary"
                                {...getIntercomTargetProp(`WorkFlowSelection-ItemVisibility`)}
                            />
                        </Tooltip>
                    }

                    {onDeleteClick &&
                        <Tooltip title={removeBtnTooltip} placement="top" enterDelay={600}>
                            <IconButton
                                className={classNames(classes.button, classes.colorRed)}
                                onClick={e => onDeleteClick(task)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    {onEditClick &&
                        <Tooltip enterDelay={600} placement="top" title={editBtnTooltip}>
                            <IconButton className={classes.button} onClick={e => onEditClick(task)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
            </ListItem>
        );
    };

    const wrapWithSortableItem = (content: JSX.Element, taskId: number, index: number) =>
        <SortableItem index={index} key={taskId}>
            {content}
        </SortableItem>;

    const loadMoreData = () => {
        setShowAllTasks(true);
    };

    const renderTaskList = () => {
        const showButton = !showAllTasks && showLoadMoreButton;
        return (
            <>
                <List className={classes.taskList}>
                    <Collapse
                        in={showAllTasks || !showLoadMoreButton}
                        collapsedSize={taskCount > 10 ? 275 : 0}
                        orientation="vertical"
                        timeout={300}
                    >
                        {visibleTasks.map((task, index) =>
                            isSortable
                                ? wrapWithSortableItem(renderListItem(task), task.id, index)
                                : renderListItem(task)
                        )}
                    </Collapse>
                </List>

                <div className={classes.flexButton}>
                    <span
                        className={classNames(classes.loadButton, !showButton && classes.hideButton)}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            loadMoreData();
                        }}
                    >
                        <AddIcon className={classes.addButton} />
                        {`Load ${taskCount - 10} More ${visibleStep}s`}
                    </span>
                </div>
            </>
        );
    };

    return (
        <WorkFlowSummaryWrapper
            workflowName={workflowName}
            heading={heading}
            badgeCount={taskCount}
            description={description}
            onMenuIconClick={onMenuIconClick}
            wrapperClasses={workflowSummaryWrapperClasses}
            intercomTargetProp={`WorkFlowSelection-${heading}Summary`}
        >
            <div className={classNames(isSortable && classes.overflowYAuto)}>
                {(tasks.length === 0 || featureDisabled)
                    ? renderEmptyState()
                    : isSortable
                        ? wrapWithSortableList(renderTaskList())
                        : renderTaskList()
                }
                {footerContent}
            </div>
        </WorkFlowSummaryWrapper>
    );
};

export default TaskList;