import classNames from 'classnames';

import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';
import ImageListItem from '@mui/material/ImageListItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import { FuneralHomeUX, GatherCasePreview } from '../../../shared/types';
import UserAvatar from '../../common/UserAvatar';
import { FuneralHomeRoutePage, joinNameParts, urlIsFuneralHomeSite } from '../../../services';
import GLink from '../../common/GLink';
import { RouteBuilder } from "../../../services";
import { GStyles } from '../../../styles/GStyles';

interface Props {
    gatherCases: GatherCasePreview[];
    isFHorGOMOnAnyFH: boolean;
    isFHorGOMOnFH: boolean;
    isOnRememberPage: boolean;
    isLoading: Boolean;
    funeralHome: FuneralHomeUX;
    openRolodexDialog: () => void;
    isGuestUser: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        maxHeight: 68,
        '-webkit-overflow-scrolling': 'touch',
        '&$isGuest': {
            maxHeight: 'unset'
        }
    },
    gridMain: {
        minHeight: 182,
        overflowX: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
        '&$isGuest': {
            minHeight: 'unset'
        }
    },
    gridListContainer: {
        padding: '0px 16px 0px 0px',
        '-webkit-overflow-scrolling': 'touch',
    },
    familyGridListContainer: {
        padding: '8px 16px',
        '-webkit-overflow-scrolling': 'touch',
    },
    gridListTTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        textAlign: 'center',
        '-webkit-overflow-scrolling': 'touch',
    },
    lastListItem: {
        marginRight: 16,
    },
    avatarButton: {
        height: 50,
        width: 50,
        padding: 0
    },
    familyAvatarButton: {
        height: 90,
        width: 90,
        margin: '14px auto 8px'
    },
    avatar: {
        height: 50,
        width: 50,
    },
    familyAvatar: {
        height: 90,
        width: 90,
    },
    nameInitialsAvatar: {
        height: 50,
        width: 50,
        fontWeight: 400,
        textTransform: 'uppercase',
        '-webkit-font-smoothing': 'antialiased',
    },
    familyNameInitialsAvatar: {
        height: 90,
        width: 90,
        fontSize: 40,
        '-webkit-font-smoothing': 'antialiased',
    },
    createNewCaseButton: {
        width: 50,
        zIndex: 1,
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        '&:active, &:focus': {
            boxShadow: 'none',
        },
        '& p': {
            lineHeight: '14px'
        },
        '& icon': {
            color: theme.palette.primary.main
        }
    },
    paddingGlobalSearch: {
        padding: '0 4px'
    },
    boxShadow: {
        boxShadow: '0px -5px 5px -1px rgb(0 0 0 / 20%)'
    },
    centerSection: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'inherit'
    },
    createNewCaseTile: {
        display: 'table-cell',
        verticalAlign: 'middle',
        overflow: 'hidden',
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '0px 8px 8px',
    },
    avatarCaption: {
        marginBottom: 2,
        fontSize: 13,
        fontWeight: 500,
        width: 110,
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    alignCenter: {
        alignItems: 'center'
    },
    isGuest: {}
});

type StyledProps = Props & WithStyles<'root' | 'gridMain' | 'gridListContainer' | 'familyGridListContainer'
    | 'gridListTTitle' | 'lastListItem' | 'avatarButton' | 'familyAvatarButton' | 'avatar' | 'familyAvatar'
    | 'nameInitialsAvatar' | 'familyNameInitialsAvatar' | 'createNewCaseButton' | 'isGuest' | 'paddingGlobalSearch'
    | 'boxShadow' | 'centerSection' | 'createNewCaseTile' | 'loadingText' | 'avatarCaption' | 'alignCenter'>;

const CaseSwitcherFooter = (props: StyledProps) => {
    const {
        classes,
        gatherCases,
        isFHorGOMOnAnyFH,
        isOnRememberPage,
        isFHorGOMOnFH,
        isLoading,
        funeralHome,
        openRolodexDialog,
        isGuestUser
    } = props;

    const allowCreateCase = !isFHorGOMOnAnyFH && urlIsFuneralHomeSite();

    const renderCasesList = (showCaseDetails: boolean = false) => (
        <>
            {(gatherCases || []).map((thisCase: GatherCasePreview) => {
                const link = isOnRememberPage
                    ? RouteBuilder.RememberPage(thisCase.name)
                    : RouteBuilder.FamilyPage({
                        caseName: thisCase.name,
                        funeralHomeKey: thisCase.funeral_home_key,
                    });

                return (<ImageListItem
                    key={thisCase.name}
                    className={classes.gridListTTitle}
                    classes={{
                        root: gatherCases.length > 1 || isFHorGOMOnAnyFH
                            ? classes.lastListItem : '',

                    }}
                >
                    <GLink
                        to={link}
                        linkClass={GStyles.marginAuto}
                    >
                        <Tooltip
                            title={isFHorGOMOnAnyFH ? thisCase.full_name : ''}
                            placement="top"
                        >
                            <IconButton
                                className={classNames(
                                    classes.avatarButton,
                                    !isFHorGOMOnAnyFH && classes.familyAvatarButton
                                )}
                            >
                                <UserAvatar
                                    user={thisCase}
                                    photoClassName={!isFHorGOMOnAnyFH ? classes.familyAvatar : classes.avatar}
                                    initialsClassName={classNames(
                                        classes.nameInitialsAvatar,
                                        !isFHorGOMOnAnyFH && classes.familyNameInitialsAvatar,
                                        !isFHorGOMOnAnyFH && GStyles.fontWeight200
                                    )}
                                    size={!isFHorGOMOnAnyFH ? 90 : 50}
                                />
                            </IconButton>
                        </Tooltip>
                    </GLink>

                    {allowCreateCase &&
                        <Typography
                            color="secondary"
                            variant="caption"
                            align="center"
                            noWrap={false}
                            className={classes.avatarCaption}
                        >
                            {joinNameParts(thisCase)}
                        </Typography>
                    }
                    {showCaseDetails &&
                        <Typography
                            color="secondary"
                            align="center"
                            variant="subtitle2"
                            noWrap={false}
                            className={classNames(GStyles.fontWeight500, GStyles.textUppercase)}
                        >
                            {thisCase.full_name}
                        </Typography>
                    }

                </ImageListItem>
                );

            }
            )}
        </>
    );

    const renderCreateNewCaseButton = () => (
        isFHorGOMOnAnyFH &&
        <ImageListItem className={classes.gridListTTitle}>
            <GLink to={RouteBuilder.FuneralHome(funeralHome.key, FuneralHomeRoutePage.CREATECASE)}>
                <Button
                    className={classNames(
                        classes.createNewCaseButton,
                        GStyles.marginRight10,
                        GStyles.fontWeight300,
                        GStyles.textTransformNone
                    )}
                >
                    <Add color="primary" />
                    <Typography color="primary">Create Case</Typography>
                </Button>
            </GLink>
        </ImageListItem>
    );

    const renderGlobalSearch = () => {
        return urlIsFuneralHomeSite() ? null : (
            <ImageListItem className={classes.gridListTTitle}>
                <Button
                    className={classNames(
                        classes.createNewCaseButton,
                        GStyles.marginRight10,
                        GStyles.fontWeight300,
                        GStyles.textTransformNone
                    )}
                    onClick={openRolodexDialog}
                >
                    <SavedSearchIcon color="primary" />
                    <Typography color="primary">
                        Global Search
                    </Typography>
                </Button>
            </ImageListItem>
        );
    };

    const renderLoadingCases = () => (
        <ImageListItem className={classNames(classes.gridListTTitle, classes.createNewCaseTile)}>
            <CircularProgress
                color="primary"
                size={86}
                thickness={3}
            />
            <Typography
                align="center"
                noWrap
                className={classes.loadingText}
            >
                Loading...
            </Typography>
        </ImageListItem>
    );

    const renderFamilyCaseSection = () => {
        return (
            <Grid
                item
                xs={12}
                className={classNames(
                    GStyles.overflowXAuto,
                    isLoading ||
                        (!isFHorGOMOnAnyFH && gatherCases.length === 1)
                        ? GStyles.textCenter : '',
                    classes.gridMain,
                    isGuestUser && classes.isGuest
                )}
            >
                <div
                    className={classNames(
                        GStyles.widthAuto,
                        GStyles.heightAuto,
                        GStyles.displayInlineFlex,
                        !isFHorGOMOnAnyFH
                            ? classes.familyGridListContainer
                            : classes.gridListContainer
                    )}
                >
                    {isLoading && renderLoadingCases()}
                    {!isLoading && renderCasesList(true)}
                </div>
            </Grid>
        );
    };

    const renderCaseSection = () => {
        return (
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.root,
                    isGuestUser && classes.isGuest,
                    classes.boxShadow,
                    GStyles.overflowHidden
                )}
            >
                <div className={classes.centerSection}>
                    <div className={classes.paddingGlobalSearch}>
                        {renderGlobalSearch()}
                    </div>
                    <div
                        className={classNames(
                            GStyles.displayInlineFlex,
                            classes.gridListContainer,
                            GStyles.overflowXAuto,
                            classes.alignCenter
                        )}
                    >
                        {!isLoading && renderCasesList(false)}
                        {isFHorGOMOnFH && renderCreateNewCaseButton()}
                    </div>
                </div>
            </Grid>
        );
    };

    return (
        isFHorGOMOnAnyFH
            ? renderCaseSection()
            : renderFamilyCaseSection()
    );
};

export default withStyles(styles)(CaseSwitcherFooter);
