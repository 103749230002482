import { GLOBAL_STYLED_PROPS } from '../../../styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { ModerationCategory } from '../../../shared/types';
import { APP_SECONDARY_COLOR } from '../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import InsetShadowIcon from '../../common/InsetShadowIcon';

import { WithStyles, StyleRulesCallback } from '@mui/styles';

import withGStyles from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '36px 0 44px'
    },
    textContainer: {
        color: APP_SECONDARY_COLOR,
        textAlign: 'center',
        '& p': {
            color: APP_SECONDARY_COLOR,
            padding: '0px 10px',
        }
    },
    heading: {
        fontSize: 24,
        lineHeight: '1.2em',
    },
    text: {
        fontSize: 14,
    },
    iconContainerSize: {
        fontSize: 140,
    }
});

interface Props {
    category: ModerationCategory;
}

export type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'textContainer' | 'heading' | 'text'
    | 'iconContainerSize'>;

const ModerateText = (props: StyledProps & Props) => {
    const { classes, category } = props;

    let icon = null;

    switch (category) {
        case ModerationCategory.memories:
            icon = <FavoriteIcon />;
            break;

        case ModerationCategory.photos:
            icon = <AddAPhotoIcon />;
            break;

        case ModerationCategory.visitors:
        default:
            icon = <SupervisedUserCircleIcon />;
    }

    return (
        <Grid
            container
            className={classes.root}
        >
            <Grid item className={classes.textContainer}>
                <Typography className={classes.heading}>
                    All <span className={classes.textCapitalize}>{category}</span> have been approved.
                </Typography>

                <InsetShadowIcon
                    icon={icon}
                    iconClass={classes.iconContainerSize}
                />

                <Typography className={classes.text}>
                    <span className={classes.textCapitalize}>{category}</span>
                    &nbsp;will only show once approved.
                    <br />
                    <span className={classes.textCapitalize}>Visitors</span>&nbsp;
                    {category === ModerationCategory.visitors
                        ? 'will see a message that they are not yet approved'
                        : `will see a "Pending" message on their unapproved ${category}.`
                    }
                    <br />
                    Approving a visitor does NOT automatically approve their&nbsp;
                    {category === ModerationCategory.visitors ? 'contributions' : category}.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default withGStyles(styles)(ModerateText);