import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { PropsWithChildren } from 'react';
import Transition from 'react-transition-group/Transition';

const defaultTransition = 'transform 1200ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, ' +
    'max-height 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

const exitingTransition = 'transform 1200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, ' +
    'max-height 900ms cubic-bezier(0.4, 0, 0.2, 1) 300ms';

// COPY THE STYLES FROM gridListTile
const ZoomAnimationStyle = {
    transition: defaultTransition,
    transform: 'scale(0)',
    maxHeight: 0
};

const TransitionStyles = {
    entering: {
        transform: 'scale(1)',
    },
    entered: {
        transform: 'scale(1)',
        maxHeight: 'unset',
    },
    exiting: {
        transition: exitingTransition,
        transform: 'scale(0)',
        maxHeight: 0
    }
};

const Container = styled(Grid)(({ theme }) => ({
    padding: '0 8px',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    '@media (min-width: 536px)': {
        padding: 0,
    },
    '&:last-child': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        marginBottom: '4px'
    },
}));

const NoteAnimation = (props: PropsWithChildren<{}>) => {
    const { children, ...others } = props;

    return (
        <Transition
            in
            timeout={{ enter: 1500, exit: 1200 }}
            onEntering={(node, _) => node.style.maxHeight = `${node.scrollHeight}px`}
            onExit={node => node.style.maxHeight = `100%`}
            {...others}
        >
            {(state) => (
                <Container
                    style={{
                        ...ZoomAnimationStyle,
                        ...TransitionStyles[state]
                    }}
                >
                    {children}
                </Container>
            )}
        </Transition>
    );
};

export default NoteAnimation;