import { StyleRulesCallback, WithStyles } from '@mui/styles';
import { APP_STYLED_PROPS } from '../../../styles/App.styles';
import { ORANGE_COLOR, GREEN_COLOR, RED_COLOR } from '../../../constants/colorVariables';
import { Theme } from '@mui/material';
import makeGStyles from '../../../styles/makeGStyles';

function eSignUserListStyles<T extends object>(): StyleRulesCallback<Theme, T> {
    return (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            '& a': {
                width: '100%',
                height: '100%',
                color: 'inherit',
                textDecoration: 'none',
            },
        },
        upperSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        counterSection: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 8,
        },
        packetCount: {
            fontSize: 32,
            lineHeight: 'normal',
        },
        middleText: {
            lineHeight: 'normal',
            fontSize: 16,
            fontWeight: 300,
            padding: '5px 0',
            margin: '0 2px',
        },
        signatureStatus: {
            textAlign: 'center',
            //     borderTop: `1px solid ${theme.palette.primary.main}`,
        },
        statusText: {
            fontWeight: 200,
            fontSize: 10,
            letterSpacing: '0.1px',
            borderTop: `1px solid ${theme.palette.primary.main}`,
            margin: '0 auto',
            width: 'fit-content',
        },
        styleUserName: {
            textTransform: 'capitalize',
            padding: '0 8px',
        },
        textUppercase: {
            textTransform: 'uppercase',
        },
        listContainer: {
            height: 'auto',
            width: '100%',
            maxHeight: 480,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative',
        },
        fabExtended: {
            width: 'max-content',
            lineHeight: '12px',
            textTransform: 'none',
            backgroundColor: 'white',
            fontWeight: 400,
            fontSize: 10,
            padding: '2px 8px',
            borderRadius: 24,
            border: '1px solid',
            '&$started': {
                color: theme.palette.secondary.main,
            },
            '&$signed': {
                color: GREEN_COLOR,
            },
            '&$viewed': {
                color: ORANGE_COLOR,
            },
        },
        primary: {
            lineHeight: '1.1em',
            fontWeight: 300,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        secondary: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        link: {
            width: '100%',
            textDecoration: 'none',
            display: 'block',
        },
        signerAvatar: {
            textTransform: 'uppercase',
            width: 52,
            height: 52,
        },
        listItem: {
            padding: 8,
            display: 'flex',
            alignItems: 'end',
        },
        sendToEmail: {
            // overflow: 'hidden',
            // lineHeight: '1.4em',
            lineHeight: '1.2em',
            fontSize: 13,
            fontWeight: 300,
            // whiteSpace: 'nowrap',
            // textOverflow: 'ellipsis',
            maxWidth: 'calc(100% - 16px)',
            textTransform: 'none',
        },
        signerName: {
            fontWeight: 400,
            lineHeight: '1.1em',
            fontSize: 14,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 240,
            overflow: 'hidden',
            '& span': {
                fontWeight: 300,
            },
        },
        moreIconButton: {
            width: 24,
            height: 24,
            position: 'absolute',
            right: 4,
            '& svg': {
                fontSize: 18,
            },
        },
        packetSignerList: {
            padding: '8px 0 8px 8px',
            display: 'flex',
            flexDirection: 'row',
        },
        fontSize18: {
            fontSize: 18,
        },
        reminderPopoverText: {
            marginBottom: '20px',
        },
        menu: {},
        removeSigner: {
            color: RED_COLOR,
            '& svg': {
                color: RED_COLOR,
            },
        },
        remindFabButton: {
            marginLeft: 4,
            cursor: 'pointer',
            minHeight: 'auto',
            '& svg': {
                fontSize: 11,
                verticalAlign: 'text-bottom',
            },
        },
        chipButtons: {
            marginLeft: '-2px',
            display: 'flex',
        },
        pointerEventsNone: {
            pointerEvents: 'none',
        },
        fabButtonLabel: {
            lineHeight: '1.1em',
        },
        avatarClass: {
            width: 52,
            height: 52,
        },
        marginLeft8: {
            marginLeft: 8,
        },
        menuItem: {
            '&:focus': {
                outline: 'transparent',
            },
        },
        secondaryTextContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        updatedEmail: {
            fontSize: 11,
            textTransform: 'none',
            lineHeight: '1.3em',
            color: RED_COLOR,
            margin: '1px auto',
        },
        textTransnformCaptalization: {
            textTransform: 'capitalize',
        },
        fontWeight500: {
            fontWeight: 500,
        },
        paper: {
            overflow: 'auto',
            padding: '8px 0',
        },
        started: {},
        signed: {},
        viewed: {},
    });
}

export function styleWrapper<Props extends object>() {
    return eSignUserListStyles<Props>();
}

export const useESignUserListStyles = makeGStyles(eSignUserListStyles(), { name: 'ESignUserListStyles' });

export type ESignUserListStyledProps = APP_STYLED_PROPS & WithStyles<'root' | 'upperSection' | 'counterSection'
    | 'packetCount' | 'middleText' | 'signatureStatus' | 'statusText' | 'styleUserName' | 'textUppercase'
    | 'listContainer' | 'fabExtended' | 'started' | 'signed' | 'viewed' | 'primary' | 'secondary' | 'link'
    | 'signerAvatar' | 'listItem' | 'sendToEmail' | 'signerName' | 'moreIconButton' | 'remindFabButton' | 'menuItem'
    | 'packetSignerList' | 'fontSize18' | 'reminderPopoverText' | 'menu' | 'removeSigner' | 'chipButtons'
    | 'secondaryTextContainer' | 'updatedEmail' | 'textTransnformCaptalization' | 'fontWeight500' | 'pointerEventsNone'
    | 'fabButtonLabel' | 'avatarClass' | 'marginLeft8' | 'paper'>;
