import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { alpha, styled, SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {
    DataGridPremium,
    GridColumnsPanel,
    GridColumnsPanelProps,
    GridFooter,
    GridFooterContainer,
    GridRowSelectionModel,
    GridCallbackDetails,
    GridControlledStateReasonLookup,
    GridApi,
    GridCellParams,
    GridPinnedColumns,
    GridLocaleText,
    GridFilterModel,
    GridColumnVisibilityModel,
    GridHeaderFilterMenu,
    UncapitalizedGridPremiumSlotsComponent,
    GridSlotsComponentsProps,
} from '@mui/x-data-grid-premium';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { SKYBLUE_COLOR, WARNING_COLOR } from '../../../constants/colorVariables';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { paperClasses } from '@mui/material/Paper';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { POWERED_BY_GATHER } from '../../../constants';
import { GStyles } from '../../../styles/GStyles';
import GTooltip from '../GTooltip';
import InsetShadowIcon from '../InsetShadowIcon';
import CustomLoadingOverlay from '../../reports/DataSourceReportTable/CustomLoadingOverlay';
import { GDataGridTableColumn } from './types';
import CustomToolBar from './CustomToolBar';
import { getIntercomTargetProp } from '../../../services';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export interface RowData {
    row_id: number | string;
}

export interface GDataGridProps<T extends RowData> {
    data: T[];
    hasChanges?: boolean;
    loading: boolean;
    columns: GDataGridTableColumn<T>[];
    onRowSelectionModelChange?: (
        rowSelectionModel: GridRowSelectionModel,
        details: GridCallbackDetails<keyof GridControlledStateReasonLookup>,
    ) => void;
    muiApiRef: React.MutableRefObject<GridApi>;
    initialGroupByColumns?: (Extract<keyof T, string>)[];
    handleChange?: () => void;
    onFilterModelChange?: (model: GridFilterModel) => void;
    handleCellClick?: (row: GridCellParams<T>) => void;
    onCellValueChange?: (row: GridCellParams<T>) => void;
    visibleRows?: number;
    toolbarSlot?: React.ComponentType;
    noRowText?: string;
    loadingText?: string;
    headerElement?: JSX.Element;
    sx?: SxProps<Theme>;
    isCellEditable?: (params: GridCellParams<T>) => boolean;
    onProcessRowUpdate?: (newValue: T, oldValue: T) => Promise<T>;
    zIndex: number;
    footerText?: string;
    pinnedColumns?: GridPinnedColumns;
    localeText?: Partial<GridLocaleText>;
    columnVisibility?: GridColumnVisibilityModel;
    rowsPerPage?: number;
    enableVirtualization?: boolean;
    initialSortModel?: { field: string; sort: 'asc' | 'desc' }[];
    isSaving?: boolean;
    disableRowGrouping?: boolean;
    comingSoonColumnsUI?: JSX.Element;
}

const GatherLogo = styled('img')(({ theme }) => ({
    marginLeft: 14,
    [theme.breakpoints.down(638)]: {
        display: 'none',
    },
}));

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            border: 'none !important',
            '& .MuiDataGrid-main': {
                border: `2px solid ${theme.palette.primary.main}`,
                minHeight: 560,
                width: 'calc(100% - 1px)',
                boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.2), 
                    0px 5px 8px 0px rgba(0,0,0,0.14), 
                    0px 1px 14px 0px rgba(0,0,0,0.12)`,
                borderRadius: 22,
                '& .MuiDataGrid-virtualScroller': {
                    '& .MuiDataGrid-withBorderColor:not(.MuiDataGrid-cell--editable)': {
                        color: theme.palette.secondary.main,
                    },
                },
                '& .MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapper': {
                    height: '100%',
                    width: 'auto',
                    display: 'flex',
                    '& .MuiDataGrid-overlayWrapperInner': {
                        margin: 'auto 0',
                        height: 'auto !important',
                    },
                },
                '& .MuiDataGrid-pinnedColumns--right': {
                    '& .MuiDataGrid-cell': {
                        padding: 0,
                    },
                },
                '& .MuiDataGrid-pinnedColumns': {
                    minHeight: '100% !important',
                },
                '& .MuiDataGrid-pinnedRows': {
                    '& .MuiDataGrid-cell': {
                        color: `${theme.palette.primary.main} !important`,
                        fontWeight: 500,
                        '& .MuiDataGrid-footerCell': {
                            color: `${theme.palette.primary.main} !important`,
                            fontWeight: 500,
                        },
                    },
                },
            },
            '& .MuiTablePagination-actions': {
                '& button': {
                    color: theme.palette.primary.main,
                    '&:disabled': {
                        color: theme.palette.secondary.main,
                    }
                }
            },
            '& .MuiDataGrid-toolbarContainer': {
                justifyContent: 'center',
                [theme.breakpoints.down(430)]: {
                    width: '100%',
                    '& button': {
                        paddingLeft: 5,
                        paddingRight: 5,
                        fontSize: 10,
                        '& svg': {
                            fontSize: 17,
                        },
                    },
                },
            },
        },
        columnHeaders: {
            zIndex: 1,
            borderRadius: 0,
            color: theme.palette.common.white,
            boxShadow: theme.shadows[2],
            borderBottom: 'none',
            '& .MuiDataGrid-aggregationColumnHeaderLabel': {
                color: theme.palette.common.white,
            },
            '& .MuiDataGrid-columnHeader': {
                borderRight: `1px solid ${alpha('#fff', 0.1)}`,
                '& .MuiIconButton-root': {
                    alignSelf: 'center',
                    margin: 0,
                },
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
            '& .MuiDataGrid-columnHeadersInner > div:first-of-type': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.common.white,
                },
            },
            '& .MuiDataGrid-headerFilterRow': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                overflowY: 'hidden',
            },
            '& .MuiDataGrid-pinnedColumnHeaders': {
                color: theme.palette.common.white,
                top: 'unset',
                '& > div:first-of-type': {
                    backgroundColor: theme.palette.primary.main,
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.common.white,
                    },
                },
            },
            '& .MuiDataGrid-menuIcon, & .MuiDataGrid-iconButtonContainer': {
                width: 'auto',
                visibility: 'visible',
            },
        },
        mainContainer: {
            padding: '0 8px 8px',
            '@media (min-width: 960px)': {
                padding: '0 12px 28px',
            },
            '& .MuiDataGrid-root--densityCompact': {
                '& $columnHeaders': {
                    maxHeight: 'unset !important',
                    '& .MuiDataGrid-columnHeadersInner, .MuiDataGrid-pinnedColumnHeaders': {
                        minHeight: 'fit-content',
                        '& .MuiDataGrid-headerFilterRow': {
                            height: 'fit-content',
                            padding: '2px 0px 4px',
                            '& .MuiDataGrid-columnHeader': {
                                height: '40px !important',
                                '& .MuiFormControl-root': {
                                    height: '43px',
                                },
                                '& .MuiInputBase-root': {
                                    // marginTop: 10,
                                    '& > input': {
                                        padding: '2px 0px',
                                    },
                                    '& > select': {
                                        padding: '2px 0px',
                                    }
                                }
                            }
                        }
                    },
                    '& .MuiDataGrid-columnHeadersInner > div:first-of-type': {
                        height: 'fit-content',
                    },
                    '& .MuiDataGrid-columnHeadersInner > div:first-of-type > div': {
                        height: '48px !important',
                    },
                    '& .MuiDataGrid-pinnedColumnHeaders > div:first-of-type > div': {
                        height: '48px !important',
                    },
                }
            }
        },
        searchContainer: {
            paddingBottom: 'inherit',
            '@media (max-width: 960px)': {
                justifyContent: 'left',
            },
        },
        quickFilter: {},
        footerContainer: {
            display: 'flex',
            width: '100%',
        },
        reportContainer: {
            '&$hasChanges .MuiDataGrid-main': {
                borderColor: WARNING_COLOR,
            },
            '&$isSaving .MuiDataGrid-main': {
                borderColor: `rgba(0, 0, 0, 0.12)`,
            },
        },
        hasChanges: {},
        isSaving: {},
    }),
    { name: 'GDataGrid' },
);

function GDataGrid<T extends RowData>(props: GDataGridProps<T>) {
    const {
        zIndex,
        data,
        loading,
        loadingText,
        noRowText,
        columns,
        muiApiRef, // use const muiApiRef = useGridApiRef(); in the parent component
        toolbarSlot,
        hasChanges,
        onRowSelectionModelChange,
        handleCellClick,
        handleChange,
        onFilterModelChange,
        columnVisibility,
        initialGroupByColumns,
        isSaving,
        sx,
        onCellValueChange,
        onProcessRowUpdate,
        footerText,
        isCellEditable,
        pinnedColumns,
        localeText,
        rowsPerPage,
        enableVirtualization,
        initialSortModel,
        disableRowGrouping,
        comingSoonColumnsUI,
    } = props;
    const classes = useStyles();

    const CustomFooter = useCallback(() => {
        return (
            <Grid container flexWrap="nowrap" alignItems="center">
                <GatherLogo src={POWERED_BY_GATHER} width="160" />

                <GridFooterContainer sx={{ borderTop: 'none' }}>
                    <GridFooter sx={{ borderTop: 'none' }} />
                </GridFooterContainer>
            </Grid>
        );
    }, []);

    const CustomColumnsPanel = useCallback((_props: GridColumnsPanelProps) => {
        return (
            <Grid item xs={12}>
                <GridColumnsPanel {..._props} />

                <Box bgcolor={alpha(SKYBLUE_COLOR, 0.18)} borderTop={`1px solid ${SKYBLUE_COLOR}`} p={0.5}>
                    <Typography textAlign="center" color={SKYBLUE_COLOR}>
                        Why is my field not showing?&nbsp;
                        <Box component="span" className={GStyles.textClickable}
                            {...getIntercomTargetProp(`GDataGrid-WhyFieldNotShowing`)}
                        >
                            Learn More
                        </Box>
                    </Typography>
                </Box>

                {comingSoonColumnsUI}
            </Grid>
        );
    }, [comingSoonColumnsUI]);

    const CustomNoRowsOverlay = useCallback(() => {
        return (
            <Grid container flexDirection="column" sx={{ fontSize: 140 }}>
                <InsetShadowIcon icon={<QueryStatsIcon />} />

                <Typography
                    textAlign="center"
                    color="primary"
                    lineHeight={1.25}
                    fontWeight={300}
                    fontSize={20}
                    mt={1.5}
                >
                    No Data to Display
                </Typography>

                {noRowText && (
                    <Typography textAlign="center" color="secondary">
                        {noRowText}
                    </Typography>
                )}
            </Grid>
        );
    }, [noRowText]);

    const dataGridInitialState: GridInitialStatePremium = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: rowsPerPage || 100,
                page: 0,
            },
        },
        columns: {
            columnVisibilityModel: columnVisibility
        },
        pinnedColumns,
        rowGrouping: !initialGroupByColumns ? undefined : {
            model: initialGroupByColumns,
        },
        sorting: {
            sortModel: initialSortModel,
        },
    }), [columnVisibility, initialGroupByColumns, pinnedColumns, rowsPerPage, initialSortModel]);

    const handleFilterModelChange = useCallback((model) => {
        handleChange?.();
        onFilterModelChange?.(model);
    }, [handleChange, onFilterModelChange]);

    const dataGridSlots: Partial<UncapitalizedGridPremiumSlotsComponent> = useMemo(() => ({
        headerFilterMenu: (_props) => (
            <GridHeaderFilterMenu
                {..._props}
                labelledBy='headerFilter'
                id='headerFilter'
            />
        ),
        columnSortedAscendingIcon: (_props) => (
            <GTooltip title="Click to sort this column">
                <ArrowUpwardIcon {..._props} />
            </GTooltip>
        ),
        columnSortedDescendingIcon: (_props) => (
            <GTooltip title="Click to sort this column">
                <ArrowDownwardIcon {..._props} />
            </GTooltip>
        ),
        toolbar: toolbarSlot !== undefined ? toolbarSlot : CustomToolBar,
        footer: CustomFooter,
        columnsPanel: CustomColumnsPanel,
        noRowsOverlay: CustomNoRowsOverlay,
        noResultsOverlay: CustomNoRowsOverlay,
        loadingOverlay: () => <CustomLoadingOverlay loadingText={loadingText} />,
    }), [toolbarSlot, loadingText, CustomColumnsPanel, CustomFooter, CustomNoRowsOverlay]);

    const dataGridSlotsProps: GridSlotsComponentsProps = useMemo(() => ({
        baseTooltip: { placement: 'top' },
        filterPanel: {
            sx: {
                minWidth: '520px',
                '.MuiDataGrid-filterFormValueInput': {
                    width: 'fit-content',
                    maxWidth: '320px',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    '.MuiTextField-root': {
                        justifyContent: 'flex-end',
                    },
                    '.MuiInput-underline': {
                        minWidth: 160,
                    },
                },
            },
        },
        basePopper: {
            sx:
            {
                zIndex: zIndex + 2,
                [`.${paperClasses.root}`]: {
                    maxHeight: 'unset',
                    minWidth: 'unset',
                },
                [`.${autocompleteClasses.popupIndicator}`]: {
                    height: '24px',
                    width: '24px',
                },
                [`.${autocompleteClasses.clearIndicator}`]: {
                    height: '24px',
                    width: '24px',
                },
                '.MuiMenuItem-root': {
                    padding: '4px 12px',
                    minHeight: 'auto !important',
                    '.MuiListItemIcon-root': {
                        marginRight: '10px',
                        minWidth: '20px',
                    },
                },
                '& .MuiDataGrid-columnsPanelRow': {
                    '& .MuiFormControlLabel-root.Mui-disabled': {
                        display: 'none',
                    },
                },
            },
        },
        baseIconButton: { sx: { width: 30, height: 30 } },
    }), [zIndex]);

    const dataGridLocaleText: Partial<GridLocaleText> = useMemo(() => ({
        ...localeText,
        columnHeaderSortIconLabel: 'Click to sort this column',
        toolbarQuickFilterPlaceholder: 'Filter list of cases',
        MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} of ${count} ${footerText ?? 'Cases'}`,
        },
    }), [localeText, footerText]);

    return (
        <div className={classNames(GStyles.positionRelative, GStyles.width100)}>
            <div className={classes.mainContainer}>
                <Box
                    display="flex"
                    width={1}
                    mt={{ md: 2 }}
                    className={classNames(
                        classes.reportContainer,
                        hasChanges && classes.hasChanges,
                        isSaving && classes.isSaving,
                    )}
                >
                    <DataGridPremium
                        sx={sx}
                        apiRef={muiApiRef}
                        unstable_headerFilters={!!data.length}
                        pinnedColumns={pinnedColumns}
                        initialState={dataGridInitialState}
                        autoHeight
                        editMode="cell"
                        classes={classes}
                        checkboxSelection={!!onRowSelectionModelChange}
                        rows={data}
                        getRowId={useCallback((row) => row.row_id, [])}
                        columns={columns}
                        onCellClick={handleCellClick}
                        onCellEditStop={onCellValueChange}
                        onFilterModelChange={handleFilterModelChange}
                        onSortModelChange={handleChange}
                        onColumnVisibilityModelChange={handleChange}
                        onColumnWidthChange={handleChange}
                        onPinnedColumnsChange={handleChange}
                        onColumnOrderChange={handleChange}
                        onAggregationModelChange={handleChange}
                        onRowGroupingModelChange={handleChange}
                        processRowUpdate={onProcessRowUpdate}
                        disableVirtualization={!enableVirtualization && false}
                        disableRowSelectionOnClick
                        pagination
                        isCellEditable={isCellEditable}
                        pageSizeOptions={useMemo(() => [100, 75, 50, 25, 10], [])}
                        loading={loading}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        slots={dataGridSlots}
                        slotProps={dataGridSlotsProps}
                        localeText={dataGridLocaleText}
                        disableRowGrouping={disableRowGrouping}
                        groupingColDef={{ width: 280 }}
                    />
                </Box>
            </div >
        </div >
    );
}

export default GDataGrid;
