import * as React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';

import { ProductManufacturerRecord, ProductManufacturerRequest } from '../../shared/types';
import ManufacturerDetailsDialog from './ManufacturerDetails.dialog';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

interface Props {
    isDialogOpen: boolean;
    manufacturers: ProductManufacturerRecord[];
    isGatherUser: boolean;
    closeDialog: () => void;
    onCreate: (manufacturer: ProductManufacturerRequest) => void;
    onUpdate: (id: number, manufacturer: ProductManufacturerRequest) => void;
    onManufacturerSelect?: (manufacturer: ProductManufacturerRecord) => void;
    zIndex: number;
}

export interface State {
    searchText: string;
    selectedManufacturerId: number | null;
    isDetailsDialogOpen: boolean;
}

interface DialogProps {
    fullScreen: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    createNewContainer: {
        width: '100%',
        textAlign: 'right',
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)'
    },
    listContainer: {
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    editIcon: {
        margin: 0,
        cursor: 'pointer',
    },
    textFieldContainer: {
        textAlign: 'center',
        flexBasis: 0,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon'
    | 'createNewContainer' | 'searchField' | 'heading' | 'listContainer'
    | 'noResultsFoundSection' | 'editIcon' | 'textFieldContainer'>;

type CombinedProps = DialogProps & StyledProps;
class AvailableManufacturers extends React.Component<CombinedProps, State> {
    state: State = {
        searchText: '',
        selectedManufacturerId: null,
        isDetailsDialogOpen: false,
    };

    protected topscroll: HTMLDivElement;

    registerTopscroll = (topscroll: HTMLDivElement) => {
        this.topscroll = topscroll;
    };

    componentDidUpdate() {
        if (!this.topscroll) {
            return;
        }

        this.topscroll.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });
    }

    applySearchFilter = (manufacturers: ProductManufacturerRecord[]) => {
        const { searchText } = this.state;
        const search = searchText.toLowerCase();
        return manufacturers.filter((mfg) => {
            const name = mfg.name.toLowerCase();
            return name.includes(search) || search.includes(name);
        });
    };

    renderNoResultsFoundSection = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography
                    color="secondary"
                    component="p"
                    className={classes.marginTop20}
                >
                    Sorry, no results found.
                </Typography>
            </Grid >
        );
    };

    renderManufacturerList = () => {
        const { classes, manufacturers, onManufacturerSelect, isGatherUser } = this.props;
        const { searchText } = this.state;
        const isClickEnabled = onManufacturerSelect !== undefined;

        const filteredManufacturers = searchText ? this.applySearchFilter(manufacturers) : manufacturers;

        if (manufacturers.length === 0 || filteredManufacturers.length === 0) {
            return this.renderNoResultsFoundSection();
        }

        return (
            <List component="nav" className={classes.listContainer}>
                <Divider />
                {filteredManufacturers.map((manufacturer) => (
                    <ListItem
                        key={manufacturer.id}
                        // see type definition. only allows undefined or false
                        button={isClickEnabled ? undefined : false}
                        onClick={isClickEnabled ? () => this.handleManufacturerClick(manufacturer) : undefined}
                        divider
                    >
                        <ListItemText primary={manufacturer.name} />
                        {isGatherUser &&
                            <ListItemIcon
                                className={classes.editIcon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.toggleDetailsDialog(manufacturer.id);
                                }}
                            >
                                <EditIcon />
                            </ListItemIcon>
                        }
                    </ListItem>
                ))}
            </List>
        );
    };

    render() {
        const {
            classes,
            fullScreen,
            isDialogOpen,
            manufacturers,
            isGatherUser,
            zIndex
        } = this.props;
        const { searchText, isDetailsDialogOpen, selectedManufacturerId } = this.state;

        const selectedManufacturer = selectedManufacturerId ?
            find(manufacturers, { id: selectedManufacturerId }) : undefined;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                    style={{ zIndex }}
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.dialogHeader,
                            classes.backgroundPrimary
                        )}
                    >
                        <Clear
                            className={classNames(classes.clearIcon)}
                            onClick={this.handleClose}
                        />
                        <Typography
                            component="p"
                            className={classNames(classes.heading)}
                            align="left"
                        >
                            <span>Available Manufacturers</span>
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <div ref={this.registerTopscroll} />
                        <Grid item xs={12} className={classes.textFieldContainer}>
                            <form noValidate autoComplete="off" >
                                <TextField
                                    autoComplete="off"
                                    className={classes.searchField}
                                    fullWidth
                                    autoFocus
                                    value={searchText}
                                    id="input-with-icon-textfield"
                                    placeholder="Start typing to filter list..."
                                    name="searchText"
                                    onChange={this.handleSearchChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="primary" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid className={classes.scrollAuto}>
                            {this.renderManufacturerList()}
                        </Grid>
                    </DialogContent>

                    {isGatherUser &&
                        <DialogActions>
                            <Grid item className={classes.createNewContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.toggleDetailsDialog()}
                                >
                                    <BusinessIcon /> &nbsp;Create New Manufacturer
                                </Button>
                            </Grid>
                        </DialogActions>
                    }
                </Dialog >
                {
                    isGatherUser &&
                    <ManufacturerDetailsDialog
                        isDialogOpen={isDetailsDialogOpen}
                        closeDialog={this.toggleDetailsDialog}
                        onManufacturerSave={this.handleManufacturerSave}
                        existingManufacturer={selectedManufacturer}
                        zIndex={zIndex + 1}
                    />
                }
            </div >
        );
    }

    handleManufacturerClick = (manufacturer: ProductManufacturerRecord) => {
        const { onManufacturerSelect } = this.props;
        if (onManufacturerSelect) {
            onManufacturerSelect(manufacturer);
            this.handleClose();
        }
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        this.setState({
            searchText: target.value
        });
    };

    handleClose = () => {
        this.setState({ searchText: '' });
        this.props.closeDialog();
    };

    handleManufacturerSave = (manufacturer: ProductManufacturerRequest) => {
        const { onCreate, onUpdate } = this.props;
        const { selectedManufacturerId } = this.state;
        if (selectedManufacturerId) {
            onUpdate(selectedManufacturerId, manufacturer);
        } else {
            onCreate(manufacturer);
        }
    };

    toggleDetailsDialog = (manufacturerId?: number) => {
        this.setState((prevState) => ({
            isDetailsDialogOpen: !prevState.isDetailsDialogOpen,
            selectedManufacturerId: manufacturerId || null,
        }));
    };
}

export default withFullScreen('sm')(withGStyles(styles)(AvailableManufacturers));
