import * as React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import ClearIcon from '@mui/icons-material/Clear';
import ListAltIcon from '@mui/icons-material/ListAlt';

import {
    ProductTaxRateUX,
    ContractOptionsUX,
    ProductCategory,
    ProductCategoryEnum,
    ProductCategoryDisplayLookup,
    isProductCategory,
} from '../../../../shared/types';
import GSwitch from '../../../common/inputElements/GSwitch';
import AvailableTaxRatesDialog from '../../../allProducts/AvailableTaxRates.dialog';
import { displayTaxRate } from '../../../../shared/goods_and_services/pricing';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import { openInterCom } from '../../../../services';
import { GStyles } from '../../../../styles/GStyles';
import { Grid } from '@mui/material';
import FeatureDiscoverabilityPopper from '../../../common/FeatureDiscoverabilityPopper';

interface Props {
    zIndex: number;
    taxRates: ProductTaxRateUX[];
    contractOptions: ContractOptionsUX;
    isDialogOpen: boolean;
    productName: string;
    productCategory: ProductCategory | null;
    closeDialog: () => void;
    onCreate: (name: string, price: string, taxRateId: number | null, category: ProductCategory) => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '24px 0 0 !important',
        margin: ' auto',
        textAlign: 'center',
        width: '100%',
        '& $content': {
            width: 268,
            '@media (min-width: 400px)': {
                width: 308
            },
        }
    },
    button: {
        margin: '24px auto',
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        '@media (min-width: 400px)': {
            width: 360
        },
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        maxWidth: 300
    },
    textUnderLine: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.secondary.main
    },
    textField: {
        margin: '24px 0',
        width: 140,
        display: 'block',
        '& $input': {
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    },
    importantNote: {
        fontSize: 13,
        fontWeight: 200,
        marginTop: 24,
    },
    selectMenuForm: {
        textAlign: 'left',
        width: '100%',
        marginTop: 16,
    },
    selectMenu: {
        '&:focus': {
            background: 'transparent',
        },
    },
    content: {},
    input: {},
});

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    name: string;
    price: string;
    selectedTaxRateId: number | null;
    category: ProductCategory | null;
    saveAttempted: boolean;
    isTaxable: boolean;
    isAvailableTaxRatesDialogOpen: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent'
    | 'clearIcon' | 'button' | 'headerText' | 'textUnderLine' | 'textField' | 'input'
    | 'importantNote' | 'selectMenuForm' | 'selectMenu' | 'content'>;

const INITIAL_STATE: State = {
    name: '',
    price: '',
    selectedTaxRateId: null,
    category: null,
    saveAttempted: false,
    isTaxable: false,
    isAvailableTaxRatesDialogOpen: false,
};

class CreateOneTimeItemDialog extends React.Component<StyledProps & DialogProps, State> {
    state: State = INITIAL_STATE;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({
            name: nextProps.productName,
            category: nextProps.productCategory,
        });
    }

    renderCategorySelect = () => {
        const { classes, zIndex } = this.props;
        const { category, saveAttempted } = this.state;

        return (
            <FormControl
                className={classes.selectMenuForm}
                required
                error={saveAttempted && !category}
            >
                <InputLabel
                    htmlFor="categoryListOfOptions"
                    className="width-100"
                    variant="standard"
                >
                    Category
                </InputLabel>
                <Select
                    value={category || ''}
                    onChange={(e) => {
                        if (typeof e.target.value === 'string') {
                            this.handleCategoryChange(e.target.value);
                        }
                    }}
                    MenuProps={{ style: { zIndex: zIndex + 1 } }}
                    inputProps={{
                        name: 'categoryListOfOptions',
                        id: 'categoryListOfOptions',
                        className: classNames(!category && 'notranslate')
                    }}
                    classes={{ select: classes.selectMenu }}

                >
                    {(values(ProductCategoryEnum)).map((c: ProductCategory) => (
                        <MenuItem
                            key={c}
                            value={c}
                        >
                            {ProductCategoryDisplayLookup[c]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    render() {
        const {
            classes,
            taxRates,
            isDialogOpen,
            zIndex,
        } = this.props;

        const {
            name,
            price,
            isTaxable,
            selectedTaxRateId,
            saveAttempted,
            isAvailableTaxRatesDialogOpen,
        } = this.state;

        const selectedTaxRate = selectedTaxRateId && taxRates.find((tr) => tr.id === selectedTaxRateId) || null;

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.header,
                        classes.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon, classes.colorWhite)}
                        onClick={this.closeDialog}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classNames(classes.colorWhite, classes.headerText)}
                    >
                        Add a One-time Product
                    </Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid item xs={12} m=" auto" className={classes.content}>
                        <TextField
                            fullWidth
                            label="Product Name"
                            type="text"
                            value={name}
                            onChange={this.handleNameChange}
                            required
                            error={saveAttempted && !name}
                        />

                        {this.renderCategorySelect()}

                        <TextField
                            className={classes.textField}
                            fullWidth
                            label="Product Price"
                            type="number"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                                classes: { input: classes.input }
                            }}
                            value={price}
                            onChange={this.handlePriceChange}
                            required
                            error={saveAttempted && !price}
                        />

                        <GSwitch
                            checked={isTaxable}
                            label={'This Product is taxable'}
                            labelClasses={classes.colorSecondary}
                            onChangeCallBack={this.handleIsTaxableChange}
                        />

                        {isTaxable &&
                            <Grow in>
                                <TextField
                                    onClick={e => {
                                        if (!isTaxable) {
                                            return;
                                        }
                                        this.toggleAvailableTaxRatesDialog();
                                    }}
                                    value={displayTaxRate(selectedTaxRate)}
                                    fullWidth
                                    label="Product Taxability"
                                    disabled
                                    required
                                    error={saveAttempted && isTaxable && !selectedTaxRateId}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    color="primary"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        if (!isTaxable) {
                                                            return;
                                                        }
                                                        this.toggleAvailableTaxRatesDialog();
                                                    }}
                                                    size="large">
                                                    <ListAltIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grow>
                        }

                        <Typography color="secondary" className={classes.importantNote}>
                            <span className={classes.fontWeight400}>Important</span>
                            : The ability to add one-time products to the statement can be limited by your location
                            admin in the permissions section of the my team page.&nbsp;
                            <span className={GStyles.textClickable} onClick={openInterCom}>
                                Click here
                            </span> to get help setting this up for your team.
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        width="fit-content"
                        maxWidth="calc(100% - 36px * 2)"
                        m="0 auto"
                        position="relative"
                    >
                        <Button
                            className={classes.button}
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => this.handleAddClick()}
                        >
                            Add product to statement
                        </Button>

                        <Grid
                            item
                            position="absolute"
                            right={-36}
                            top="50%"
                            sx={{ transform: 'translateY(-50%)' }}
                        >
                            <FeatureDiscoverabilityPopper
                                zIndex={zIndex + 1}
                                infoText={<>
                                    One-Time Items are only added to this statement and can not be added to
                                    future statements. If you want to have this item all future statements and
                                    keep accounting consistent we recommend adding it to your
                                    GPL. <span className={GStyles.textClickable} onClick={openInterCom}>
                                        Click here
                                    </span> to chat with us to have us do that for you.
                                </>}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <AvailableTaxRatesDialog
                    zIndex={zIndex + 1}
                    isDialogOpen={isAvailableTaxRatesDialogOpen}
                    closeDialog={this.toggleAvailableTaxRatesDialog}
                    taxRates={taxRates}
                    isGatherUser={false} // don't allow tax Rates to be created
                    onSelect={this.handleTaxRateSelect}
                />
            </Dialog>
        );
    }

    toggleAvailableTaxRatesDialog = () => {
        this.setState((prevState) => ({
            isAvailableTaxRatesDialogOpen: !prevState.isAvailableTaxRatesDialogOpen,
        }));
    };

    handleAddClick = () => {
        const { onCreate } = this.props;
        const { name, price, selectedTaxRateId, isTaxable, category } = this.state;

        if (!category || !name || !price || (isTaxable && !selectedTaxRateId)) {
            this.setState({
                saveAttempted: true,
            });
            return;
        }

        onCreate(name, price, isTaxable && selectedTaxRateId || null, category);
        this.closeDialog();
    };

    handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: e.target.value,
        });
    };

    handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            price: e.target.value,
        });
    };

    handleTaxRateSelect = (taxRate: ProductTaxRateUX | null) => {
        this.setState({
            selectedTaxRateId: taxRate ? taxRate.id : null,
        });
    };

    handleIsTaxableChange = (isTaxable: boolean) => {
        const { contractOptions } = this.props;

        this.setState((prevState) => {

            // check if FH has a default item tax rate and use that
            const selectedTaxRateId = !prevState.selectedTaxRateId && isTaxable ?
                contractOptions.default_item_tax_rate : prevState.selectedTaxRateId;

            return {
                isTaxable,
                selectedTaxRateId,
            };
        });
    };

    handleCategoryChange = (category: string) => {
        if (isProductCategory(category)) {
            this.setState({
                category,
            });
        }
    };

    closeDialog = () => {
        this.setState(INITIAL_STATE);
        this.props.closeDialog();
    };
}

export default withFullScreen()(withGStyles(styles)(CreateOneTimeItemDialog));
