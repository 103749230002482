import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import { CaseTaskUX, GatherCaseUX } from '../../../shared/types';
import { useGSelector, useGDispatch } from '../../../types';
import { sendTaskReminder } from '../../../actions/task/Task.action';
import GPopper from '../../common/GPopper';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles({
    reminderPopover: {
        padding: '25px',
        textAlign: 'center',
        maxWidth: '270px',
        filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
        '-webkit-filter': 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
    },
    reminderPopoverText: {
        marginBottom: '20px',
    },
}, { name: 'ReminderPopper' });

interface Props {
    anchorEle: HTMLElement | null;
    task: Pick<CaseTaskUX, 'assigned_to' | 'assigned_to_all' | 'id'>;
    isActive: boolean;
    selectedCase: GatherCaseUX;
    closePopover: () => void;
    zIndex: number;
}

const ReminderPopper = (props: Props) => {
    const {
        task,
        selectedCase,
        anchorEle,
        closePopover,
        // isActive,
        zIndex
    } = props;

    const classes = useStyles();

    const sendingTaskReminder = useGSelector(({ tasksState }) =>
        tasksState.sendingTaskReminder);
    const dispatch = useGDispatch();

    const hasAssignees = () => (
        task.assigned_to_all ||
        (task.assigned_to && task.assigned_to.length > 0)
    );

    const onSendReminder = () => {

        dispatch(sendTaskReminder(task.id, selectedCase.uuid));

        closePopover();
    };

    const isButtonDisabled = !hasAssignees() || sendingTaskReminder;
    // const isOpen = isActive && (Boolean(anchorEle) || sendingTaskReminder);

    return (
        <GPopper
            anchorEle={anchorEle}
            paperClass={classes.reminderPopover}
            zIndex={zIndex}
            closePopper={e => closePopover()}
            hideArrow
        >
            <Typography
                align="center"
                component="p"
                className={
                    classNames(
                        classes.fontSize18,
                        classes.reminderPopoverText,
                    )
                }
            >
                A friendly reminder will be sent to everyone
                assigned to this task
            </Typography>
            <Grid item>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isButtonDisabled}
                    onClick={e => onSendReminder()}
                >
                    {hasAssignees()
                        ? 'SEND REMINDER'
                        : 'THIS TASK IS UNASSIGNED'
                    }
                    {sendingTaskReminder &&
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    }
                </Button>
            </Grid>
        </GPopper>
    );
};

export default ReminderPopper;
