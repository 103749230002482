import { postToAPI, getFromAPI } from '../actions';
import {
    ModerationDecisionRequest,
    ModerationCategory,
    ModerationStatus,
    LoadModerationItemsResponse,
    ModerationPhotoUX,
    ModerationMemoryUX,
    ModerationItem,
    ModerationVisitorUX,
    ModerationPendingCountsForAllCases,
    ModerationCountsForCase,
    ModerationFlagRequest,
} from '../shared/types';
import { log } from '../logger';
import { stringify } from 'query-string';
import { AppDispatch } from '../store';

const getBaseRoute = (params: { caseUuid: string | null; funeralHomeId: number | null }): string => {
    const { funeralHomeId, caseUuid } = params;
    let route: string;
    if (caseUuid) {
        route = `api/case/${caseUuid}`;
    } else if (funeralHomeId) {
        route = `funeralhome/${funeralHomeId}`;
    } else {
        route = 'api';
    }
    return route;
};

export function makeModerationDecision(params: {
    decisionRequest: ModerationDecisionRequest;
    funeralHomeId: number | null;
    caseUuid: string | null;
}) {
    return async (dispatch: AppDispatch): Promise<true | null> => {
        const { decisionRequest, funeralHomeId, caseUuid } = params;
        try {
            ModerationDecisionRequest.fromRequest(decisionRequest);
        } catch (ex) {
            log.warn('Failed to validate ModerationDecisionRequest:', { decisionRequest, ex });
            return null;
        }

        const baseRoute = getBaseRoute({ funeralHomeId, caseUuid });
        const route: string = `${baseRoute}/moderation`;
        const createdConfig = await postToAPI<{ success: true }>(route, { decision: decisionRequest }, dispatch);

        return createdConfig ? createdConfig.success : null;
    };
}

export function retrievePendingModerationCounts(params: {
    funeralHomeId: number | null;
    caseUuid: string | null;
}) {
    return async (dispatch: AppDispatch): Promise<ModerationPendingCountsForAllCases | null> => {
        const { funeralHomeId, caseUuid } = params;

        const baseRoute = getBaseRoute({ funeralHomeId, caseUuid });
        const route: string = `${baseRoute}/moderation/pending/count`;
        const counts = await getFromAPI<ModerationPendingCountsForAllCases>(route, dispatch);
        return counts;
    };
}

export function retrieveAllModerationCountsForCase(params: {
    caseUuid: string;
}) {
    return async (dispatch: AppDispatch): Promise<ModerationCountsForCase | null> => {
        const { caseUuid } = params;

        const route: string = `api/case/${caseUuid}/moderation/count`;
        const counts = await getFromAPI<ModerationCountsForCase>(route, dispatch);
        return counts;
    };
}

export interface RetrieveModerationItemsParams {
    category: ModerationCategory;
    status: ModerationStatus;
    funeralHomeId: number | null;
    caseUuid: string | null;
    includeTotals?: boolean;
    offset?: number;
}

function retrieveModerationItems<T extends ModerationItem>(params: RetrieveModerationItemsParams) {
    return async (dispatch: AppDispatch): Promise<LoadModerationItemsResponse<T> | null> => {
        const { status, category, funeralHomeId, caseUuid, includeTotals, offset } = params;

        const baseRoute = getBaseRoute({ funeralHomeId, caseUuid });
        const queryString = stringify({
            includetotals: includeTotals ? true : undefined,
            limit: 50,
            offset: offset || 0,
        });
        const route: string = `${baseRoute}/moderation/category/${category}/status/${status}/?${queryString}`;
        const photos = await getFromAPI<LoadModerationItemsResponse<T>>(route, dispatch);
        return photos;
    };
}

export function retrieveModerationPhotos(params: Omit<RetrieveModerationItemsParams, 'category'>) {
    return retrieveModerationItems<ModerationPhotoUX>({
        ...params,
        category: ModerationCategory.photos,
    });
}

export function retrieveModerationMemories(params: Omit<RetrieveModerationItemsParams, 'category'>) {
    return retrieveModerationItems<ModerationMemoryUX>({
        ...params,
        category: ModerationCategory.memories,
    });
}

export function retrieveModerationVisitors(params: Omit<RetrieveModerationItemsParams, 'category'>) {
    return retrieveModerationItems<ModerationVisitorUX>({
        ...params,
        category: ModerationCategory.visitors,
    });
}

export interface FlagContentParams {
    category: ModerationCategory;
    itemId: number;
    caseName: string;
}

export function callFlagContent(params: FlagContentParams) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        const { itemId, caseName, category } = params;

        const flag: ModerationFlagRequest = {
            category,
            itemId,
        };

        try {
            ModerationFlagRequest.fromRequest(flag);
        } catch (ex) {
            log.warn('Failed to validate ModerationFlagRequest:', { flag, ex });
            return false;
        }

        const route = `api/remember/${caseName}/flag`;
        const result = await postToAPI<{ success: boolean }>(route, { flag }, dispatch);
        return result ? true : false;
    };
}
