import * as React from 'react';
import moment from 'moment-timezone';
import * as classNames from 'classnames';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { WithStyles } from '@mui/styles';


type StyledProps = WithStyles<
    'docListContainer' | 'docLabelContainer' | 'docName' | 'docUploadedBy' | 'docUploadedTime' |
    'fileMoreIcon'
>;

interface Props extends StyledProps {
    name: string;
    uploadedBy: string;
    uploadedTime: string;
    docItemClass?: string;
    menuHandler?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Doc = (props: Props) => {
    const { classes, name, uploadedBy, uploadedTime, docItemClass, menuHandler } = props;
    const timeString = moment(uploadedTime).format('LLL');

    return (
        <Grid item xs={12} className={classNames(classes.docListContainer, docItemClass)}>
            <InsertDriveFileIcon color="primary" />

            <div className={classes.docLabelContainer}>
                <Typography color="secondary" className={classes.docName}>
                    {name}
                </Typography>
                <Typography color="secondary" className={classes.docUploadedBy}>
                    Uploaded by {uploadedBy}
                </Typography>
                <Typography color="secondary" className={classes.docUploadedTime}>
                    {timeString}
                </Typography>
            </div>

            {menuHandler &&
                <IconButton
                    color="primary"
                    className={classes.fileMoreIcon}
                    onClick={menuHandler}
                >
                    <MoreVertIcon color="primary" />
                </IconButton>
            }
        </Grid>
    );
};

export default Doc;
