import { GatherCaseUX } from '../../../../../shared/types';
import CompleteStepDialogContent from './CompleteStepDialogContent';
import { TrackingStepIconLookup } from '../saveStepDialog/trackingStepIcon.list';
import BaseStepDialog from '../baseStepDialog/BaseStepDialog';
import { useEffect, useState } from 'react';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import { getFullNameFromUser, joinNameParts } from '../../../../../services';
import { useGDispatch, useGSelector } from '../../../../../types';
import { loadTrackingStep } from '../../../../../actions/task/Task.action';
import LoadingSpinner from '../../../../common/LoadingSpinner';
import { GStyles } from '../../../../../styles/GStyles';

// TODO: need a generic name as it's being used in various places except Tracking Flow
export enum TrackingContentContext {
    standalone = 'standalone',
    dialog = 'dialog',
    familyPage = 'familyPage'
}

interface Props {
    isOpen: boolean;
    selectedCase: GatherCaseUX;
    activeStepId: number | null;
    zIndex: number;
    closeDialog: () => void;
    onChangeActiveStep: (stepId: number) => void;
}

const CompleteStepDialog = (props: Props) => {
    const {
        isOpen,
        zIndex,
        activeStepId,
        selectedCase,
        onChangeActiveStep,
    } = props;

    const dispatch = useGDispatch();

    const activeStep = useGSelector(({ tasksState }) => tasksState.trackingSteps.find((t) => t.id === activeStepId));
    const user = useGSelector(({ userSession }) => userSession.userData);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [isStepLoading, setIsStepLoading] = useState<boolean>(false);

    // load the step to make sure we have fresh data
    useEffect(() => {
        async function getStep() {
            if (isOpen && activeStepId) {
                setIsStepLoading(true);
                await dispatch(loadTrackingStep({
                    taskId: activeStepId,
                    caseUuid: selectedCase.uuid,
                }));
                setIsStepLoading(false);
            }
        }

        getStep();
    }, [dispatch, activeStepId, isOpen, selectedCase.uuid]);

    const closeDialog = () => {
        if (activeStep && activeStep.marked_complete_time) {
            props.closeDialog();
        } else {
            setConfirmationDialogOpen(true);
        }
    };

    const forceCloseDialog = () => {
        setConfirmationDialogOpen(false);
        props.closeDialog();
    };

    const stepTitle = activeStep?.marked_complete_time
        ? activeStep.past_tense_title || activeStep.title
        : activeStep?.title;
    const stepIcon = activeStep?.icon && TrackingStepIconLookup[activeStep.icon];

    return (
        <>
            <BaseStepDialog
                zIndex={zIndex}
                entityFullName={activeStep?.marked_complete_by
                    ? joinNameParts(activeStep.marked_complete_by)
                    : user ? getFullNameFromUser(user) : ''}
                open={isOpen}
                stepIcon={stepIcon}
                stepImagePublicId={null}
                stepImageFallbackURL={null}
                stepTitle={stepTitle || ''}
                closeDialog={closeDialog}
                isStepCompleted={!!activeStep?.marked_complete_by}
            >
                {isStepLoading && <LoadingSpinner containerClass={GStyles.marginTop40} />}
                {activeStep && !isStepLoading && user &&
                    <CompleteStepDialogContent
                        fhName={selectedCase.funeral_home.name}
                        activeStep={activeStep}
                        user={user}
                        selectedCase={selectedCase}
                        zIndex={zIndex}
                        workflowName={selectedCase?.workflow_name ?? ''}
                        prereqTaskIds={activeStep.prereq_task_ids}
                        onChangeActiveStep={onChangeActiveStep}
                        closeDialog={forceCloseDialog}
                    />}
            </BaseStepDialog>

            <ConfirmationDialog
                zIndex={zIndex + 1}
                header="Are you sure?"
                subHeader="Are you sure you want to close before saving this step?"
                confirmationButtonText="Close Step Screen"
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
                onConfirm={forceCloseDialog}
            />
        </>
    );
};

export default CompleteStepDialog;
