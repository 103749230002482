import {
    TaskLocationUX,
    TaskLocationUpdateRequest,
    TaskLocationRankUpdateRequest,
    TaskLocationCreateRequest,
} from '../shared/types';
import { registerAppError } from './errors';
import { deleteFromAPI, getFromAPI, patchAPI, postToAPI } from './index';
import { AppDispatch } from '../store';
import { log } from '../logger';

export const TASK_LOCATION_CREATED = 'TASK_LOCATION_CREATED';
export type TASK_LOCATION_CREATED = typeof TASK_LOCATION_CREATED;

interface TaskLocationCreated {
    type: TASK_LOCATION_CREATED;
    taskLocation: TaskLocationUX;
}

function taskLocationCreated(taskLocation: TaskLocationUX): TaskLocationCreated {
    return {
        type: TASK_LOCATION_CREATED,
        taskLocation
    };
}

export const TASK_LOCATION_UPDATED = 'TASK_LOCATION_UPDATED';
export type TASK_LOCATION_UPDATED = typeof TASK_LOCATION_UPDATED;

interface TaskLocationUpdated {
    type: TASK_LOCATION_UPDATED;
    taskLocationId: number;
    taskLocation: Partial<TaskLocationUX>;
}

function taskLocationUpdated(taskLocationId: number, taskLocation: Partial<TaskLocationUX>): TaskLocationUpdated {
    return {
        type: TASK_LOCATION_UPDATED,
        taskLocationId,
        taskLocation,
    };
}

export const TASK_LOCATION_DELETED = 'TASK_LOCATION_DELETED';
export type TASK_LOCATION_DELETED = typeof TASK_LOCATION_DELETED;

interface TaskLocationDeleted {
    type: TASK_LOCATION_DELETED;
    taskLocation: TaskLocationUX;
}

function taskLocationDeleted(taskLocation: TaskLocationUX): TaskLocationDeleted {
    return {
        type: TASK_LOCATION_DELETED,
        taskLocation
    };
}


export const TASK_LOCATIONS_LOADING = 'TASK_LOCATIONS_LOADING';
export type TASK_LOCATIONS_LOADING = typeof TASK_LOCATIONS_LOADING;

interface TaskLocationsLoading {
    type: TASK_LOCATIONS_LOADING;
}

function taskLocationsLoading(): TaskLocationsLoading {
    return {
        type: TASK_LOCATIONS_LOADING,
    };
}

export const TASK_LOCATIONS_LOADED = 'TASK_LOCATIONS_LOADED';
export type TASK_LOCATIONS_LOADED = typeof TASK_LOCATIONS_LOADED;

interface TaskLocationsLoaded {
    type: TASK_LOCATIONS_LOADED;
    taskLocations: TaskLocationUX[];
}

function taskLocationsLoaded(taskLocations: TaskLocationUX[]): TaskLocationsLoaded {
    return {
        type: TASK_LOCATIONS_LOADED,
        taskLocations
    };
}

export const TASK_LOCATIONS_REORDERED = 'TASK_LOCATIONS_REORDERED';
export type TASK_LOCATIONS_REORDERED = typeof TASK_LOCATIONS_REORDERED;

interface TaskLocationsReordered {
    type: TASK_LOCATIONS_REORDERED;
    taskLocationIds: number[];
}

function taskLocationsReordered(taskLocationIds: number[]): TaskLocationsReordered {
    return {
        type: TASK_LOCATIONS_REORDERED,
        taskLocationIds
    };
}

export function createTaskLocation(funeralHomeId: number, createRequest: TaskLocationCreateRequest) {
    return async (dispatch: AppDispatch) => {
        try {
            TaskLocationCreateRequest.fromRequest(createRequest);
        } catch (ex) {
            log.warn('Failed to validate update TaskLocationCreateRequest', { createRequest, ex });
            return;
        }
        const url = `funeralhome/${funeralHomeId}/tasklocation/`;
        const result = await postToAPI<TaskLocationUX>(url, createRequest, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem creating location`));
        }
        dispatch(taskLocationCreated(result));
        return result;
    };
}

export function updateTaskLocation(
    taskLocationId: number,
    funeralHomeId: number,
    updateRequest: TaskLocationUpdateRequest
) {
    return async (dispatch: AppDispatch) => {
        try {
            TaskLocationUpdateRequest.fromRequest(updateRequest);
        } catch (ex) {
            log.warn('Failed to validate update TaskLocationUpdateRequest', { updateRequest, ex });
            return;
        }
        const url = `funeralhome/${funeralHomeId}/tasklocation/${taskLocationId}`;
        const result = await patchAPI<TaskLocationUX>(url, updateRequest, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem updating location`));
        }
        dispatch(taskLocationUpdated(taskLocationId, result));
        return result;
    };
}

export function deleteTaskLocation(taskLocation: TaskLocationUX, funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        // delete optimistically
        dispatch(taskLocationDeleted(taskLocation));
        const url = `funeralhome/${funeralHomeId}/tasklocation/${taskLocation.id}`;
        const result = await deleteFromAPI<TaskLocationUX>(url, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem deleting location`));
        }
        return result;

    };
}

export function reorderTaskLocation(taskLocationIds: number[], funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        const body: TaskLocationRankUpdateRequest = { taskLocationIds };
        try {
            TaskLocationRankUpdateRequest.fromRequest(body);
        } catch (ex) {
            log.warn('Failed to validate TaskLocationRankUpdateRequest', { body, ex });
            return;
        }
        // update optimistically
        dispatch(taskLocationsReordered(taskLocationIds));
        const url = `funeralhome/${funeralHomeId}/tasklocation/rank`;
        await postToAPI<void>(url, body, dispatch);
        // 204 response
    };
}

export function loadTaskLocations(funeralHomeId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(taskLocationsLoading());
        const url = `funeralhome/${funeralHomeId}/tasklocation`;
        const result = await getFromAPI<TaskLocationUX[]>(url, dispatch);
        if (!result) {
            return dispatch(registerAppError(`Problem loading locations for funeral home`));
        }
        dispatch(taskLocationsLoaded(result));
        return result;
    };
}

export type TaskLocationAction =
    | TaskLocationCreated
    | TaskLocationUpdated
    | TaskLocationDeleted
    | TaskLocationsLoading
    | TaskLocationsLoaded
    | TaskLocationsReordered
;
