import {
    GridCellParams,
    GridFilterItem,
    getGridSingleSelectOperators
} from '@mui/x-data-grid-premium';
import { RowData } from '../../common/GDataGrid/GDataGrid';
import { useMemo } from 'react';
import { map } from 'lodash';

export const getProductTagsFilters = <T extends RowData>() => {
    const createFilterFn = (operatorValue: string, isNegation: boolean) => (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
            return null;
        }

        return (params: GridCellParams<T, string | null>) => {
            if (!params.value) {
                return false;
            }

            const valueList = map(params.value, (tagValue, tagName) => ({
                value: `${tagName}: ${tagValue}`,
                label: `${tagName}: ${tagValue}`,
            }));
            const exists = valueList.some(item => item.value === filterItem.value);
            return isNegation ? !exists : exists;
        };
    };

    return getGridSingleSelectOperators().map((operator) => {
        switch (operator.value) {
            case 'is':
                return {
                    ...operator,
                    label: 'Is',
                    getApplyFilterFn: createFilterFn(operator.value, false),
                };
            case 'not':
                return {
                    ...operator,
                    label: 'Is not',
                    getApplyFilterFn: createFilterFn(operator.value, true),
                };
            default:
                return operator;
        }
    });
};

const useProductTagsFilterOperators = () => {
    return useMemo(() => getProductTagsFilters(), []);
};

export default useProductTagsFilterOperators;
