import { setElementStyleById, setElementStyleByName, setGTMFuneralHomeContext } from '.';
import { EntitySummary, UserProfile, UserRoles } from '../shared/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Intercom: any;

export const getIntercomTargetProp = (target: string | undefined) => {
    if (target === undefined || target === ``) {
        return {};
    }
    return {
        'data-intercom-target': target.trim().split(' ').join(''),
    };
};

export const showIntercom = () => {
    Intercom('show');
};

export const showIntercomNewMessage = (message: string) => {
    Intercom('showNewMessage', `${message}: `);
};

export const showIntercomNews = () => {
    Intercom('showSpace', 'news');
};

export enum IntercomTrackEvent {
    GatherAcademy = 'viewed-gather-academy'
}
export const submitIntercomEvent = (eventName: IntercomTrackEvent) => {
    Intercom('trackEvent', eventName);
};

interface LastFuneralHomeLSData {
    userId: number;
    funeralHomeKey: string;
}

export const getLastFuneralHomeLS = (): LastFuneralHomeLSData | null => {
    const data = localStorage.getItem('lastFuneralHomeKey');
    if (data) {
        const lastData: LastFuneralHomeLSData = JSON.parse(data);
        return lastData;
    }
    return null;
};

export const setLastFuneralHomeLS = (data: LastFuneralHomeLSData) => {
    const { funeralHomeKey } = data;
    if (funeralHomeKey) {
        localStorage.setItem('lastFuneralHomeKey', JSON.stringify(data));
    }
};

export const bootIntercom = (
    user?: UserProfile | EntitySummary,
    funeralHomeKey?: string,
    companyId?: string,
) => {
    if (!process.env.REACT_APP_INTERCOM_APP_ID) {
        console.warn('Intercom App ID not set!', process.env.REACT_APP_INTERCOM_APP_ID);
        return;
    }
    try {
        let pingData: Record<string, string | null | undefined | { id: string }> = {};
        // Only send the more detailed user info if the user is a FH user
        if (user && UserRoles.isFHorGOMUser(user) && user.user_id) {
            pingData = {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                email: user.email,
                name: `${user.fname} ${user.lname}`,
                user_id: user.user_id.toString(),
                funeralHomeKey: undefined,
                company: undefined,
            };
            if (user && UserRoles.isFHUser(user) && user.user_id) {
                pingData = {
                    ...pingData,
                    funeralHomeKey: funeralHomeKey ? funeralHomeKey : undefined,
                    company: companyId ? { id: companyId } : undefined,
                };
            }
            if (user?.user_id && funeralHomeKey && UserRoles.isFHUser(user)) {
                setLastFuneralHomeLS({ userId: user.user_id, funeralHomeKey });
            }
        } else {
            pingData = {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            };
        }

        // Send a GTM event to record the funeral home key
        setGTMFuneralHomeContext(funeralHomeKey);

        Intercom('boot', pingData);

        // tslint:disable-next-line:no-use-before-declare
        const timeout = setTimeout(() => clearInterval(interval), 30000);
        const interval = setInterval(() => {
            if (Intercom.booted) {
                clearInterval(interval);
                clearTimeout(timeout);

                // wait for intercom iframe to bind
                const bootedTimeout = setTimeout(() => {
                    clearTimeout(bootedTimeout);

                    // set intercom widget z-index to 1200 so it appears beneath all the app dialogs
                    setElementStyleById('intercom-container', 'z-index', '1200');
                    setElementStyleByName('intercom-launcher-frame', 'z-index', '1200');
                    setElementStyleByName('intercom-launcher-discovery-frame', 'z-index', '1199');

                    // set intercom icon z-index to  1200 so it appears beneath all the app dialogs
                    const intercomLightweightApp = document.getElementsByClassName('intercom-lightweight-app')[0];
                    const intercomLightweightAppLauncher = document.getElementsByClassName(
                        'intercom-lightweight-app-launcher',
                    )[0];

                    if (intercomLightweightApp) {
                        intercomLightweightApp.setAttribute('style', 'z-index: 1200 !important');
                    }
                    if (intercomLightweightAppLauncher) {
                        intercomLightweightAppLauncher.setAttribute('style', 'z-index: 1200 !important');
                    }
                }, 500);
            }
        }, 100);
    } catch (e) {
        console.warn(`Failed to boot intercom for user email: ${user?.email || ''}`, e);
    }
};

export const updateIntercom = (user: UserProfile | EntitySummary, funeralHomeKey?: string, companyId?: string) => {
    if (!process.env.REACT_APP_INTERCOM_APP_ID) {
        console.warn('Intercom App ID not set!', process.env.REACT_APP_INTERCOM_APP_ID);
        return;
    }
    if (!funeralHomeKey) {
        // right now we only care if a funeralHomeKey was passed in
        return;
    }
    try {
        let updatedData: Record<string, string | null | undefined | { id: string }> = {};
        // we only want to update a FH User - when the companyId changes.
        if (UserRoles.isFHorGOMUser(user) && user.user_id) {
            updatedData = {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                email: user.email,
                name: `${user.fname} ${user.lname}`,
                user_id: user.user_id.toString(),
                funeralHomeKey: undefined,
                company: undefined,
            };
            if (UserRoles.isFHUser(user) && user.user_id) {
                updatedData = {
                    ...updatedData,
                    funeralHomeKey: funeralHomeKey ? funeralHomeKey : undefined,
                    company: companyId ? { id: companyId } : undefined,
                };
            }
            const lastFuneralHomeData = getLastFuneralHomeLS();
            if (user?.user_id && funeralHomeKey) {
                if (lastFuneralHomeData?.funeralHomeKey !== funeralHomeKey) {
                    Intercom('update', updatedData);
                    setLastFuneralHomeLS({ userId: user.user_id, funeralHomeKey });
                }
            }
        }
    } catch (e) {
        console.warn(`Failed to update intercom for user: ${user.email || ''}`, e);
    }
};

export const reBootIntercom = () => {
    if (!process.env.REACT_APP_INTERCOM_APP_ID) {
        return;
    }
    try {
        Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        });
    } catch (e) {
        console.warn('Failed to reboot intercom', e);
    }
};

export const shutdownIntercom = () => {
    if (!process.env.REACT_APP_INTERCOM_APP_ID) {
        return;
    }
    try {
        Intercom('shutdown');
    } catch (e) {
        console.warn('Failed to shutdown intercom', e);
    }
};
