import classNames from 'classnames';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CheckIcon from '@mui/icons-material/Check';
import makeGStyles from '../../../../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    checkMark: {
        color: theme.palette.primary.main,
        border: '2px solid',
        borderRadius: '50%',
        width: 88,
        height: 88,
        margin: '88px auto 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fontSize: 68,
            color: theme.palette.primary.main,
        }
    },
    cashDesc: {
        fontSize: 18,
        color: theme.palette.secondary.main
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 20,
    },
    moneyIcon: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
}), { name: 'OnlinePaymentSuccessScreen' });

interface Props {
    paymentId?: number;
    closeDialog: (activeReceipt: number | null) => void;
}

const OnlinePaymentSuccessScreen = (props: Props) => {
    const { paymentId, closeDialog } = props;

    const classes = useStyles();

    return (<>
        <Grid>
            <div className={classes.checkMark}>
                <CheckIcon />
            </div>
            <Typography
                className={classNames(classes.cashDesc, classes.marginBottom50)}
                align="center"
            >
                Payment Successful
            </Typography>
        </Grid>
        {!!paymentId &&
            <Grid
                className={classes.bottomContent}
                item
                xs={12}
                md={12}
                lg={12}
            >
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => closeDialog(paymentId || null)}
                    className={classes.marginBottom5}
                >
                    <ReceiptIcon className={classes.moneyIcon} />
                    View Receipt
                </Button>
            </Grid>
        }
    </>);
};

export default OnlinePaymentSuccessScreen;