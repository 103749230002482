import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import makeGStyles from '../../../../../../styles/makeGStyles';
import StepComponentWrapper from '../StepComponentWrapper';
import { OnAnswerChangedFunction, OnValidityChangedFunction } from './types';
import { MultipleChoiceConfig } from '../../../../../../shared/types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const useStyles = makeGStyles(theme => ({
    root: {},
    margin40auto: {
        margin: '40px auto',
    },
    RadioBtn: {
        display: 'flex',
        flexDirection: 'column',
    },
    marginLeft4: {
        marginLeft: 4,
    },
    radioLabel: {
        margin: 0,
        '& > span': {
            margin: 5,
            padding: 0,
        },
    },
}), { name: 'MultipleChoiceComponent' });

interface Props {
    isStepCompleted: boolean;
    config: MultipleChoiceConfig | null;
    componentId: number;
    saveAttempted: boolean;
    onValidityChanged: OnValidityChangedFunction;
    onAnswerChanged: OnAnswerChangedFunction;
}

const MultipleChoiceComponent = (props: Props) => {
    const {
        isStepCompleted,
        config,
        componentId,
        saveAttempted,
        onValidityChanged,
        onAnswerChanged,
    } = props;

    const classes = useStyles();
    const isRequired = config?.is_required;

    const [answer, setAnswer] = useState<string | null>(config?.answer ?? null);

    const isValid = useMemo(() => {
        if (isRequired) {
            return Boolean(answer?.trim());
        } else {
            return true;
        }
    }, [isRequired, answer]);

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    useEffect(() => {
        if (answer !== null) {
            onAnswerChanged(componentId, answer);
        }
    }, [onAnswerChanged, componentId, answer]);

    const showError = !isValid && saveAttempted;

    return (
        <StepComponentWrapper
            hideBorder
            error={showError}
            success={isRequired && isValid}
            className={classes.padding_0_12}
        >
            <Typography
                color={showError ? "error" : "secondary"}
                className={classNames(classes.fontWeight500, classes.marginLeft4)}
            >
                {config?.question_text}{isRequired && ' *'}
            </Typography>

            <Grid container={true} direction="column">
                <RadioGroup
                    className={classes.RadioBtn}
                    row={true}
                    value={answer || ''}
                    onChange={({ currentTarget }) => {
                        if (isStepCompleted) {
                            return;
                        }
                        setAnswer(currentTarget.value);
                    }}
                >
                    {(config?.options || []).map(option =>
                        <FormControlLabel
                            key={option.uuid}
                            value={option.uuid}
                            control={<Radio color="primary" />}
                            label={option.question_text}
                            labelPlacement="end"
                            className={classes.radioLabel}
                            disabled={isStepCompleted}
                            classes={{ label: classes.colorSecondary }}
                        />
                    )}
                </RadioGroup>
            </Grid>
        </StepComponentWrapper>
    );
};

export default MultipleChoiceComponent;
