import * as constants from '../constants';
import { GatherAction } from '../actions';
import { DeathCertificateConfigState } from '../types';
import {
    CREATED_DEATH_CERTIFICATE_CONFIG,
    CREATING_DEATH_CERTIFICATE_CONFIG,
    CREATING_DEATH_CERTIFICATE_CONFIG_FAILED,
    DELETED_DEATH_CERTIFICATE_CONFIG,
    DELETING_DEATH_CERTIFICATE_CONFIG,
    DELETING_DEATH_CERTIFICATE_CONFIG_FAILED,
    LOADED_DEATH_CERTIFICATE_CONFIG,
    LOADED_DEATH_CERTIFICATE_CONFIGS,
    LOADING_DEATH_CERTIFICATE_CONFIG,
    LOADING_DEATH_CERTIFICATE_CONFIGS,
    LOADING_DEATH_CERTIFICATE_CONFIGS_FAILED,
    LOADING_DEATH_CERTIFICATE_CONFIG_FAILED,
    UPDATED_DEATH_CERTIFICATE_CONFIG,
    UPDATING_DEATH_CERTIFICATE_CONFIG,
    UPDATING_DEATH_CERTIFICATE_CONFIG_FAILED,
    SAVED_DEATH_CERTIFICATE_CONFIG_FIELD,
    SAVING_DEATH_CERTIFICATE_CONFIG_FIELD,
    SAVING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED,
    DELETED_DEATH_CERTIFICATE_CONFIG_FIELD,
    DELETING_DEATH_CERTIFICATE_CONFIG_FIELD,
    DELETING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED,
    UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE,
    UPDATING_DEATH_CERTIFICATE_CONFIG_FOR_CASE,
} from '../actions/DeathCertificateConfig.action';
import {
    DeathCertificateConfigUX,
    deathCertificateUXToSummary,
} from '../shared/types/deathcertificateconfig';

export const deathCertificateConfigInitData: DeathCertificateConfigState = {
    deathCertificateConfigs: [],
    activeDeathCertificateConfig: null,
    isSavingConfig: false,
    isDeletingConfig: false,
    isLoadingConfigs: false,
    isLoadingConfig: false,
};

export const deathCertificateConfigState = (
    state: DeathCertificateConfigState = deathCertificateConfigInitData,
    action: GatherAction,
): DeathCertificateConfigState => {
    switch (action.type) {
    case constants.USER_LOGGED_OUT: {
        return deathCertificateConfigInitData;
    }
    case LOADING_DEATH_CERTIFICATE_CONFIG: {
        return {
            ...state,
            isLoadingConfig: true,
        };
    }
    case LOADING_DEATH_CERTIFICATE_CONFIG_FAILED: {
        return {
            ...state,
            isLoadingConfig: false,
        };
    }
    case UPDATING_DEATH_CERTIFICATE_CONFIG_FOR_CASE:
        return {
            ...state,
            isLoadingConfig: true,
            activeDeathCertificateConfig: null,
        };
    case UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE:
    case LOADED_DEATH_CERTIFICATE_CONFIG: {
        return {
            ...state,
            isLoadingConfig: false,
            activeDeathCertificateConfig: action.config,
        };
    }
    case LOADING_DEATH_CERTIFICATE_CONFIGS: {
        return {
            ...state,
            isLoadingConfigs: true,
        };
    }
    case LOADING_DEATH_CERTIFICATE_CONFIGS_FAILED: {
        return {
            ...state,
            isLoadingConfigs: false,
        };
    }
    case LOADED_DEATH_CERTIFICATE_CONFIGS: {
        return {
            ...state,
            isLoadingConfigs: false,
            deathCertificateConfigs: action.configs,
        };
    }
    case CREATING_DEATH_CERTIFICATE_CONFIG: {
        return {
            ...state,
            isSavingConfig: true,
        };
    }
    case CREATING_DEATH_CERTIFICATE_CONFIG_FAILED: {
        return {
            ...state,
            isSavingConfig: false,
        };
    }
    case CREATED_DEATH_CERTIFICATE_CONFIG: {
        return {
            ...state,
            isSavingConfig: false,
            activeDeathCertificateConfig: action.config,
            deathCertificateConfigs: [
                ...state.deathCertificateConfigs,
                deathCertificateUXToSummary(action.config),
            ],
        };
    }
    case UPDATING_DEATH_CERTIFICATE_CONFIG: {
        const { activeDeathCertificateConfig } = state;

        let updatedConfigUX: DeathCertificateConfigUX | null = activeDeathCertificateConfig;
        if (activeDeathCertificateConfig && activeDeathCertificateConfig.id === action.configId) {
            const optimisticConfigUX: DeathCertificateConfigUX = {
                ...activeDeathCertificateConfig,
                ...action.changes,
                updated_by: action.updaterId,
                updated_time: new Date(),
            };
            updatedConfigUX = optimisticConfigUX;
        }

        return {
            ...state,
            isSavingConfig: true,
            activeDeathCertificateConfig: updatedConfigUX,
            deathCertificateConfigs: state.deathCertificateConfigs.map((config) => {
                if (config.id === action.configId) {
                    return {
                        ...config,
                        ...action.changes,
                    };
                } else {
                    return config;
                }
            }),
        };
    }
    case UPDATING_DEATH_CERTIFICATE_CONFIG_FAILED: {
        const { activeDeathCertificateConfig } = state;
        return {
            ...state,
            isSavingConfig: false,
            activeDeathCertificateConfig:
                    activeDeathCertificateConfig && activeDeathCertificateConfig.id === action.configId
                        ? {
                            ...activeDeathCertificateConfig,
                            ...action.changesToRollback,
                        } : activeDeathCertificateConfig,
            deathCertificateConfigs: state.deathCertificateConfigs.map((config) => {
                if (config.id === action.configId) {
                    return {
                        ...config,
                        ...action.changesToRollback,
                    };
                } else {
                    return config;
                }
            }),
        };
    }
    case UPDATED_DEATH_CERTIFICATE_CONFIG: {
        const { activeDeathCertificateConfig } = state;
        return {
            ...state,
            isSavingConfig: false,
            activeDeathCertificateConfig:
                    activeDeathCertificateConfig && activeDeathCertificateConfig.id === action.updatedConfig.id
                        ? action.updatedConfig
                        : activeDeathCertificateConfig,
            deathCertificateConfigs: state.deathCertificateConfigs.map((config) => {
                if (config.id === action.updatedConfig.id) {
                    return deathCertificateUXToSummary(action.updatedConfig);
                } else {
                    return config;
                }
            }),
        };
    }
    case DELETING_DEATH_CERTIFICATE_CONFIG: {
        const { activeDeathCertificateConfig } = state;
        return {
            ...state,
            isDeletingConfig: true,
            activeDeathCertificateConfig: activeDeathCertificateConfig &&
                    activeDeathCertificateConfig.id === action.idToRemove
                ? null
                : activeDeathCertificateConfig,
            deathCertificateConfigs: state.deathCertificateConfigs.filter((config) =>
                config.id !== action.idToRemove),
        };
    }
    case DELETING_DEATH_CERTIFICATE_CONFIG_FAILED: {
        return {
            ...state,
            isDeletingConfig: false,
            deathCertificateConfigs: [
                ...state.deathCertificateConfigs,
                deathCertificateUXToSummary(action.configToRestore),
            ],
        };
    }
    case DELETED_DEATH_CERTIFICATE_CONFIG: {
        return {
            ...state,
            isDeletingConfig: false,
        };
    }
    case SAVING_DEATH_CERTIFICATE_CONFIG_FIELD: {
        const { activeDeathCertificateConfig } = state;

        return {
            ...state,
            isSavingConfig: true,
            activeDeathCertificateConfig: !activeDeathCertificateConfig ? null : {
                ...activeDeathCertificateConfig,
                revision: {
                    ...activeDeathCertificateConfig.revision,
                    fields: activeDeathCertificateConfig.revision.fields.map((field) => {
                        if (field.ui_field_key === action.fieldKey) {
                            return {
                                ...field,
                                ...action.changes,
                            };
                        } else {
                            return field;
                        }
                    }),
                },
            },
        };
    }
    case SAVING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED: {
        return {
            ...state,
            isSavingConfig: false,
        };
    }
    case SAVED_DEATH_CERTIFICATE_CONFIG_FIELD: {
        return {
            ...state,
            isSavingConfig: false,
            activeDeathCertificateConfig: action.updatedConfig,
        };
    }
    case DELETING_DEATH_CERTIFICATE_CONFIG_FIELD: {
        const { activeDeathCertificateConfig } = state;

        return {
            ...state,
            isSavingConfig: true,
            activeDeathCertificateConfig: !activeDeathCertificateConfig ? null : {
                ...activeDeathCertificateConfig,
                revision: {
                    ...activeDeathCertificateConfig.revision,
                    fields: activeDeathCertificateConfig.revision.fields.filter((f) =>
                        f.ui_field_key !== action.fieldToDelete.ui_field_key
                    ),
                },
            },
        };
    }
    case DELETED_DEATH_CERTIFICATE_CONFIG_FIELD: {
        return {
            ...state,
            isSavingConfig: false,
            activeDeathCertificateConfig: action.updatedConfig,
        };
    }
    case DELETING_DEATH_CERTIFICATE_CONFIG_FIELD_FAILED: {
        const { activeDeathCertificateConfig } = state;
        return {
            ...state,
            isSavingConfig: false,
            activeDeathCertificateConfig: !activeDeathCertificateConfig ? null : {
                ...activeDeathCertificateConfig,
                revision: {
                    ...activeDeathCertificateConfig.revision,
                    fields: [...activeDeathCertificateConfig.revision.fields, action.oldField],
                },
            },
        };
    }
    default: {
        return state;
    }
    }
};
