import classNames from 'classnames';

import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';

import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import { useGDispatch, useGSelector } from '../../types';
import IconButton from '@mui/material/IconButton';
import { closeSnackbar } from '../../actions/AppSnackbar.action';
import { Divider, Theme } from '@mui/material';
import { RED_COLOR } from '../../constants/colorVariables';

import { green, amber } from '@mui/material/colors';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles: StyleRulesCallback<Theme, {}> = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: RED_COLOR,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 24,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    IconButton: {
        position: 'absolute',
        top: 4,
        right: 8,
        width: 24,
        height: 24
    },
    fontWeight400: {
        fontWeight: 400
    },
    secondaryMessageContainer: {
        width: '100%',
        '@media (min-width: 960px)': {
            maxWidth: 412
        },
        '& span': {
            padding: '0 12px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
        },
        '& hr': {
            height: 1,
            background: theme.palette.common.white,
            margin: '4px 0'
        }
    },
    messageClass: {
        width: '100%',
        padding: 6,
        '@media (min-width: 960px)': {
            width: 'auto',
            minWidth: 412,
            maxWidth: 412
        }
    },
    actionClass: {
        padding: 0,
        margin: 0
    },
    snackbarRoot: {
        padding: 0,
        flexDirection: 'column',
        fontWeight: 500,
        textAlign: 'center'
    },
    padding0: {
        padding: 0
    },
    primaryMessage: {
        padding: '0 20px'
    }
});

type StyledProps = WithStyles<'root' | 'success' | 'error' | 'info' | 'warning' | 'icon' | 'iconVariant'
    | 'IconButton' | 'fontWeight400' | 'secondaryMessageContainer' | 'messageClass'
    | 'actionClass' | 'snackbarRoot' | 'padding0' | 'primaryMessage'>;

const AppSnackbar = (props: StyledProps) => {
    const {
        classes,
    } = props;

    const {
        variant,
        snackbarOrigin,
        secondaryMessage,
        isOpen,
        autoHideDuration,
        message
    } = useGSelector(({ appSnackbarState }) => appSnackbarState);
    const dispatch = useGDispatch();

    const anchorOrigin: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right'
    };
    const Icon = variant !== null ? variantIcon[variant] : undefined;

    return (
        <Snackbar
            anchorOrigin={snackbarOrigin || anchorOrigin}
            open={isOpen}
            autoHideDuration={autoHideDuration || 6000}
            onClose={(event, reason) => dispatch(closeSnackbar(reason))}
        >
            <SnackbarContent
                className={classNames(variant !== null ? classes[variant] : '')}
                aria-describedby="app-snackbar"
                classes={{
                    message: secondaryMessage && classes.messageClass,
                    action: secondaryMessage && classes.actionClass,
                    root: secondaryMessage && classes.padding0
                }}
                message={(
                    <span
                        id="app-snackbar"
                        className={classNames(classes.root, secondaryMessage && classes.snackbarRoot)}
                    >
                        {(Icon && !secondaryMessage)
                            && <Icon className={classNames(classes.icon, classes.iconVariant)} />}
                        <span className={classNames(secondaryMessage && classes.primaryMessage)}>{message}</span>
                        {secondaryMessage
                            && <div className={classes.secondaryMessageContainer}>
                                <Divider />
                                <span className={classes.fontWeight400}>{secondaryMessage}</span>
                            </div>}
                    </span>
                )}
                action={
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classNames(secondaryMessage && classes.IconButton)}
                        onClick={(event) => dispatch(closeSnackbar('close'))}
                        size="large">
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                }
            />
        </Snackbar>
    );
};

export default withStyles(styles)(AppSnackbar);
