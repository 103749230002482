import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import BaseErrorPage from './BaseErrorPage';

const InvalidDeepLinkPage = () => {

    return (
        <BaseErrorPage
            HeadingIcon={NoAccountsIcon}
            heading="Oops, this invitation code is invalid"
            subHeading="Please double check the code and try again"
        />
    );
};

export default InvalidDeepLinkPage;
