import classNames from 'classnames';

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

interface GCircularProgressProps extends CircularProgressProps { }

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'absolute',
        background: theme.palette.common.white,
        borderRadius: '50%',
        '&$translucent': {
            opacity: 0.12
        }
    },
    translucent: {}
}), { name: 'GCircularProgress' });

const GCircularProgress = (props: GCircularProgressProps) => {
    const { value, className, color, ...otherProps } = props;
    const translucentValue = 100 - (value || 0);

    const classes = useStyles();

    return (
        <>
            <CircularProgress
                {...otherProps}
                value={value}
                className={classNames(
                    className,
                    classes.root
                )}
            />

            {/* translucent progress styling starts */}
            <CircularProgress
                {...otherProps}
                value={translucentValue}
                className={classNames(
                    className,
                    classes.root,
                    classes.translucent
                )}
                style={{
                    transform: `rotate(${-(360 * translucentValue) / 100 + -90}deg)`
                }}
            />
            {/* translucent progress styling ends */}
        </>
    );
};

export default GCircularProgress;
