import { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import PushPinIcon from '@mui/icons-material/PushPin';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import RouteIcon from '@mui/icons-material/Route';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WatchIcon from '@mui/icons-material/Watch';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddIcon from '@mui/icons-material/Add';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import PreviewIcon from '@mui/icons-material/Preview';

import { FeatureKey, GatherCaseUX, KeeptrackCardType, LongAddress, TrackingTabsEnum } from '../../../../shared/types';
import UserAvatar from '../../../common/UserAvatar';
import GMenu, { GMenuItem } from '../../../common/GMenu';
import {
    getDatesDiffDurations,
    getFullNameFromCase,
    RouteBuilder,
    tzWithFallback,
    getIntercomTargetProp,
    submitIntercomEvent,
    IntercomTrackEvent
} from '../../../../services';
import { getCompactKeepTrackDuration, getLocationDurationTooltip } from './CompletedStep';
import { useGSelector } from '../../../../types';
import CaseLabels from '../../labels/CaseLabels';
import makeGStyles from '../../../../styles/makeGStyles';
import { useQRStepperCommonStyles } from '.';
import { QR_MEDALLION_GOLD } from '../../../../constants';
import { alpha } from '@mui/material';
import { SKYBLUE_COLOR_2, SUCCESS_COLOR } from '../../../../constants/colorVariables';
import { canCompleteTrackingSteps } from '../../../../shared/authority/can';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';
import useFeatureEnabled from "../../../common/hooks/useFeatureEnabled";
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GStyles } from '../../../../styles/GStyles';
import PickupAddressDialog from '../../../funeralHomes/qrBodyTracking/PickupAddressDialog';
import DropOffAddressDialog from '../../../funeralHomes/qrBodyTracking/DropOffAddressDialog';
import DecedentWeightDialog from '../../../funeralHomes/qrBodyTracking/DecedentWeightDialog';
import AssignCaseWatchers from '../../../assignmentPoppers/AssignCaseWatchers';
import AddressPopper from '../../../funeralHomes/qrBodyTracking/AddressPopper';
import School from '@mui/icons-material/School';

const useStyles = makeGStyles<{ completedItemsCount: number }>(theme => ({
    absolutelyRight: {
        position: 'absolute',
        right: 0
    },
    buttonSection: {
        textAlign: 'unset',
        width: 'calc(100% + 52px)',
        maxWidth: 'calc(100% + 52px)',
        transform: 'translateX(-52px)',
        padding: props => props.completedItemsCount > 0 ? '6px 0px 0px' : '6px 0px 60px',
    },
    minHeight52: {
        minHeight: 52,
        marginLeft: 5
    },
    paddingRight5: {
        paddingRight: '5px',
    },
    caseAvatar: {
        width: 52,
        height: 52,
        fontSize: 26,
        textTransform: 'uppercase'
    },
    badge: {
        color: '#fff',
        background: SUCCESS_COLOR,
        border: 'none',
        right: 'unset',
        left: -40,
        top: -18
    },
    heading: {
        fontWeight: 500,
        fontSize: 16,
        marginBottom: 2
    },
    addFamilyMedallionItem: {
        position: 'relative',
        background: alpha(theme.palette.primary.main, 0.08),
        '@media (min-height: 600px)': {
            paddingTop: 28,
            '& img': {
                zIndex: 1,
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }
        }
    },
    overflowUnset: {
        background: 'transparent',
        '@media (min-height: 600px)': {
            overflow: 'unset'
        }
    },
    menuList: {
        boxShadow: theme.shadows[0],
        background: theme.palette.common.white,
        padding: 0
    },
    greenColor: {
        color: SUCCESS_COLOR
    },
    caseWatchersText: {
        fontSize: '0.875rem',
        fontWeight: 400,
        textTransform: 'none',
    },
    gatherAcademy: {
        color: '#fff',
        backgroundColor: `${SKYBLUE_COLOR_2} !important`
    }
}), { name: 'QRStepperHeader' });

interface Props {
    completedItemsCount: number;
    selectedCase: GatherCaseUX;
    openPrintQRStickersDialog: () => void;
    openPrintZebraQRStickersDialog: () => void;
    openNotesDialog: VoidFunction;
    openWorkflowSelectorDialog: VoidFunction;
    toggleBulkEditMode: VoidFunction;
    openAllStepsDialog: VoidFunction;
}
const QRStepperHeader = (props: Props) => {
    const {
        completedItemsCount,
        selectedCase,
        openNotesDialog,
        openWorkflowSelectorDialog,
        toggleBulkEditMode,
        openAllStepsDialog,
        openPrintQRStickersDialog
    } = props;

    const isTrackingInitialized = !!selectedCase.keeptrack_assigned_time;

    const userData = useGSelector(({ userSession }) => userSession.userData);
    const isGatherAcademyEnabled = useFeatureEnabled(FeatureKey.GATHER_ACADEMY);

    const commonClasses = useQRStepperCommonStyles();
    const classes = useStyles({ completedItemsCount });

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [pickupAddressDialogOpen, setPickupAddressDialogOpen] = useState(false);
    const [pickupAddressPopperAnchor, setPickupAddressPopperAnchor] = useState<HTMLElement | null>(null);
    const [dropOffAddressDialogOpen, setDropOffAddressDialogOpen] = useState(false);
    const [dropOffAddressPopperAnchor, setDropOffAddressPopperAnchor] = useState<HTMLElement | null>(null);
    const [caseWeightDialogOpen, setCaseWeightDialogOpen] = useState(false);

    const { current_task_location, fname } = selectedCase;

    const durations = current_task_location && getDatesDiffDurations({
        older: current_task_location.moved_time,
        newer: moment(),
    });
    const qrStickersEnabled = useFeatureEnabled(FeatureKey.QR_STICKER);

    const timezone = tzWithFallback(selectedCase.funeral_home.timezone);

    const locationDurationTooltip = !durations ? '' : getLocationDurationTooltip({
        durations,
        caseFName: fname,
        isCurrent: true,
        movedTime: moment(current_task_location.moved_time).tz(timezone) || undefined
    });

    const compactDuration = getCompactKeepTrackDuration({
        durations,
        withEllipses: true,
    });

    const isFinalized = !!selectedCase.keeptrack_finalized_time;

    const closeMenuAndPerformAction = (action: () => void) => {
        action();
        setMenuAnchorEl(null);
    };

    const canCompleteStep = canCompleteTrackingSteps(userData, selectedCase.funeral_home_id);

    const renderMenu = () => {
        let items: GMenuItem[] = [{
            id: 'assign-family-medallion',
            link: RouteBuilder.KeepTrack({
                funeralHomeKey: selectedCase.funeral_home.key,
                caseName: selectedCase.name,
                cardType: KeeptrackCardType.additional,
            }),
            content: (
                <Grid container alignItems="center" justifyContent="center" flexDirection="column">
                    <img src={QR_MEDALLION_GOLD} width={44} />
                    <Typography component="span" color="primary" className={classes.fontSize16}>
                        Add a Family Medallion
                    </Typography>
                </Grid>
            ),
            className: classes.addFamilyMedallionItem,
            disabled: !canCompleteStep
        }, {
            id: 'print-qr-stickers',
            content: <><QrCodeScanner />&nbsp;Print QR Stickers</>,
            onClick: () => closeMenuAndPerformAction(openPrintQRStickersDialog)
        }, {
            id: 1,
            content: <><RouteIcon />&nbsp;Change WorkFlow</>,
            onClick: () => closeMenuAndPerformAction(openWorkflowSelectorDialog),
        }, {
            id: 2,
            content: <><AddIcon />&nbsp;Add an Action</>,
            onClick: () => closeMenuAndPerformAction(openAllStepsDialog),
            disabled: !canCompleteStep
        }, {
            id: 3,
            content: <><PostAddIcon />&nbsp;Add a Note</>,
            onClick: () => closeMenuAndPerformAction(openNotesDialog)
        }, {
            id: 4,
            content: <><FileCopyIcon />&nbsp;Upload a file</>,
            onClick: () => setMenuAnchorEl(null)
        }, {
            id: 5,
            content: <><FingerprintIcon />&nbsp;Add a fingerprint</>,
            link: RouteBuilder.TrackingPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
                tab: TrackingTabsEnum.Prints,
            }),
        }, {
            id: 6,
            content: <><WatchIcon />&nbsp;Record a belonging</>,
            link: RouteBuilder.TrackingPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
                tab: TrackingTabsEnum.Items,
            }),
        }, {
            id: 7,
            content: <><AssignmentIndIcon />&nbsp;Take a death photo</>,
            link: RouteBuilder.TrackingPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
                tab: TrackingTabsEnum.ID,
            }),
        }, {
            id: 8,
            content: <><FormatListNumberedIcon />&nbsp;View Vitals</>,
            link: RouteBuilder.TrackingPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
                tab: TrackingTabsEnum.Vitals,
            }),
        }, {
            id: 10,
            content: <><ThreeSixtyIcon />&nbsp;View Tasks</>,
            link: RouteBuilder.FamilyPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
            }),
        }, {
            id: 11,
            content: <><ClearAllIcon />&nbsp;Bulk Edit Steps</>,
            className: classes.colorOrange,
            onClick: () => closeMenuAndPerformAction(toggleBulkEditMode),
        }];

        if (isGatherAcademyEnabled) {
            items.push({
                id: 'gather-academy',
                content: <><School />&nbsp;&nbsp;Access Gather Academy™</>,
                className: classes.gatherAcademy,
                onClick: () => closeMenuAndPerformAction(
                    () => submitIntercomEvent(IntercomTrackEvent.GatherAcademy)
                )
            });
        }

        // remove if qr stickers not enabled
        if (!qrStickersEnabled) {
            items = items.filter(({ id }) => id !== 'print-qr-stickers');
        }

        return (
            <GMenu
                zIndex={1320}
                items={items}
                anchorEl={menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
                menuListCass={classes.menuList}
                paperClass={classes.overflowUnset}
            />
        );
    };

    const pickUpAddress = selectedCase.death_certificate?.about.pickUpAddress;
    const dropOffAddress = selectedCase.death_certificate?.about.dropOffAddress;
    const decedentWeight = selectedCase.death_certificate?.about.weight;

    const openPickupAddressDialog = (event: React.MouseEvent<HTMLElement>, address: LongAddress | undefined) => {
        if (address?.city || address?.state) {
            setPickupAddressPopperAnchor(event.currentTarget);
        } else {
            setPickupAddressDialogOpen(true);
        }
    };

    const openDropOffAddressDialog = (event: React.MouseEvent<HTMLElement>, address: LongAddress | undefined) => {
        if (address?.city || address?.state) {
            setDropOffAddressPopperAnchor(event.currentTarget);
        } else {
            setDropOffAddressDialogOpen(true);
        }
    };

    return (
        <Grid container flexWrap="nowrap" className={commonClasses.stepWrapper}>
            {isTrackingInitialized &&
                <IconButton
                    color="primary"
                    className={classNames(commonClasses.moreVertIcon, classes.absolutelyRight)}
                    onClick={evt => setMenuAnchorEl(evt.currentTarget)}
                >
                    <MoreVertIcon fontSize="small" />
                </IconButton>
            }
            <Grid className={commonClasses.stepLeftContainer}>
                <UserAvatar
                    user={selectedCase}
                    className={classes.caseAvatar}
                    size={48}
                    badgeCount={completedItemsCount}
                    badgeClass={classes.badge}
                    badgeTooltipText={`${completedItemsCount} steps have been completed so far.`}
                    intercomTargetProp={`TrackingPage-TrackTab-DecedentAvatar`}
                />
                <div className={classNames(
                    commonClasses.stepConnector,
                    (isTrackingInitialized || completedItemsCount) && commonClasses.completedStepBorder
                )} />
            </Grid>
            <Grid className={classNames(commonClasses.completedStepContainer, classes.marginAuto)}>
                <Grid item xs={12} className={classes.minHeight52}>
                    <Typography color="secondary" className={classNames(classes.heading, commonClasses.lineHeight1)}>
                        {getFullNameFromCase(selectedCase)}
                    </Typography>

                    <Tooltip
                        placement="top"
                        enterDelay={600}
                        title={`Click to adjust ${selectedCase.fname}'s WorkFlow`}
                        disableInteractive
                    >
                        <Grid
                            container
                            component="p"
                            alignItems="center"
                            className={classNames(
                                classes.margin_0,
                                classes.colorSecondary,
                                classes.widthFitContent,
                                classes.clickable
                            )}
                            onClick={openWorkflowSelectorDialog}
                            {...getIntercomTargetProp(`TrackingPage-TrackTab-WorkFlowEditLink`)}
                        >
                            <RouteIcon color="inherit" className={classes.fontSize14} />

                            <Typography
                                component="span"
                                color="inherit"
                                className={classes.paragraphLineHeight}
                            >
                                {selectedCase.workflow_name}
                            </Typography>
                        </Grid>
                    </Tooltip>

                    {current_task_location &&
                        <Grid
                            container
                            alignItems="center"
                            className={classes.fontSize12}
                        >
                            <Tooltip
                                placement="top"
                                enterDelay={600}
                                title={`${selectedCase.fname} is currently located in ${current_task_location.name}`}
                                disableInteractive
                            >
                                <Typography
                                    color="secondary"
                                    className={classNames(
                                        classes.flexCentred,
                                        commonClasses.lineHeight1,
                                        isFinalized && classes.greenColor
                                    )}
                                    {...getIntercomTargetProp(`TrackingPage-TrackTab-CaseCurrentLocation`)}
                                >
                                    <PushPinIcon className={classes.fontSize14} />
                                    <span>
                                        {isFinalized && 'Finalized ('}{current_task_location.name}{isFinalized && ')'}
                                    </span>
                                </Typography>
                            </Tooltip>

                            <Tooltip
                                placement="top"
                                enterDelay={600}
                                title={locationDurationTooltip || ''}
                                disableInteractive
                            >
                                <Typography
                                    color="secondary"
                                    mr="8px"
                                    ml="8px"
                                    className={classNames(
                                        classes.flexCentred,
                                        commonClasses.lineHeight1,
                                    )}
                                    {...getIntercomTargetProp(`TrackingPage-TrackTab-TimeSinceLastCompletedStep`)}
                                >
                                    <AccessTimeIcon
                                        className={classNames(classes.fontSize16, commonClasses.marginRight2)}
                                    />
                                    <span className={classes.marginTop2}>{compactDuration}</span>
                                </Typography>
                            </Tooltip>

                            <Typography
                                color="secondary"
                                mr="8px"
                                className={classNames(
                                    classes.flexCentred,
                                    commonClasses.lineHeight1,
                                    GStyles.clickable
                                )}
                                onClick={() => setCaseWeightDialogOpen(true)}
                                {...getIntercomTargetProp(`TrackingPage-TrackTab-DecedentWeight`)}
                            >
                                <MonitorWeightIcon
                                    className={classNames(
                                        classes.fontSize20,
                                        commonClasses.marginRight2
                                    )}
                                />

                                {!!decedentWeight &&
                                    <span className={classes.marginTop2}>
                                        Weight: {decedentWeight}
                                    </span>
                                }

                                {!decedentWeight &&
                                    <span>
                                        + Add Weight
                                    </span>
                                }
                            </Typography>

                            <div
                                color="secondary"
                                className={classNames(
                                    classes.flexCentred,
                                    classes.paddingRight5,
                                    commonClasses.lineHeight1
                                )}
                            >
                                <PreviewIcon color="secondary" />
                                <AssignCaseWatchers
                                    activeCase={selectedCase}
                                    textClass={classes.caseWatchersText}
                                    addLabel="+ Add Case Watcher"
                                    zIndex={1320}
                                />
                            </div>

                            <Typography
                                color="secondary"
                                className={classNames(
                                    classes.flexCentred,
                                    classes.paddingRight5,
                                    commonClasses.lineHeight1,
                                    GStyles.clickable
                                )}
                                onClick={(e) => openPickupAddressDialog(e, pickUpAddress)}
                                {...getIntercomTargetProp(`TrackingPage-TrackTab-PickupLocation`)}
                            >
                                <LocationOnIcon
                                    className={classNames(classes.fontSize16, commonClasses.marginRight2)}
                                />
                                {(pickUpAddress?.city || pickUpAddress?.state) &&
                                    <span className={classes.marginTop2}>
                                        Pickup address in {pickUpAddress.city}
                                        {pickUpAddress.city && pickUpAddress.state && ','} {pickUpAddress.state}
                                    </span>
                                }

                                {!pickUpAddress?.city && !pickUpAddress?.state &&
                                    <span>+ Add Pickup Location</span>
                                }
                            </Typography>

                            <Typography
                                color="secondary"
                                className={classNames(
                                    classes.flexCentred,
                                    commonClasses.lineHeight1,
                                    GStyles.clickable
                                )}
                                onClick={(e) => openDropOffAddressDialog(e, dropOffAddress)}
                                {...getIntercomTargetProp(`TrackingPage-TrackTab-DropOffLocation`)}
                            >
                                <LocationOnIcon
                                    className={classNames(classes.fontSize16, commonClasses.marginRight2)}
                                />
                                {(dropOffAddress?.city || dropOffAddress?.state) &&
                                    <span className={classes.marginTop2}>
                                        Drop-Off address in {dropOffAddress.city}
                                        {dropOffAddress.city && dropOffAddress.state && ','} {dropOffAddress.state}
                                    </span>
                                }

                                {!dropOffAddress?.city && !dropOffAddress?.state &&
                                    <span>+ Add Drop-Off Location</span>
                                }
                            </Typography>
                        </Grid>
                    }
                </Grid>

                <CaseLabels
                    zIndex={1320}
                    gatherCase={selectedCase}
                    labels={selectedCase.labels}
                    className={classes.buttonSection}
                    intercomTargetProp={`TrackingPage-TrackTab-CaseLabels`}
                />
            </Grid>

            {isTrackingInitialized && renderMenu()}

            <DecedentWeightDialog
                zIndex={1320}
                open={caseWeightDialogOpen}
                caseUuid={selectedCase.uuid}
                decedentWeight={selectedCase.death_certificate?.about.weight || null}
                caseFullName={getFullNameFromCase(selectedCase)}
                onClose={() => setCaseWeightDialogOpen(false)}
            />

            <PickupAddressDialog
                zIndex={1320}
                open={pickupAddressDialogOpen}
                caseUuid={selectedCase.uuid}
                pickupAddress={selectedCase.death_certificate?.about.pickUpAddress || null}
                caseFullName={getFullNameFromCase(selectedCase)}
                onClose={() => setPickupAddressDialogOpen(false)}
            />

            {pickUpAddress &&
                <AddressPopper
                    zIndex={1320}
                    anchorEle={pickupAddressPopperAnchor}
                    isPickUp={true}
                    address={pickUpAddress}
                    onEdit={() => {
                        setPickupAddressDialogOpen(true);
                        setPickupAddressPopperAnchor(null);
                    }}
                    closePopper={() => setPickupAddressPopperAnchor(null)}
                />
            }


            <DropOffAddressDialog
                zIndex={1320}
                open={dropOffAddressDialogOpen}
                caseUuid={selectedCase.uuid}
                dropOffAddress={selectedCase.death_certificate?.about.dropOffAddress || null}
                caseFullName={getFullNameFromCase(selectedCase)}
                onClose={() => setDropOffAddressDialogOpen(false)}
            />

            {dropOffAddress &&
                <AddressPopper
                    zIndex={1320}
                    anchorEle={dropOffAddressPopperAnchor}
                    isPickUp={false}
                    address={dropOffAddress}
                    onEdit={() => {
                        setDropOffAddressDialogOpen(true);
                        setDropOffAddressPopperAnchor(null);
                    }}
                    closePopper={() => setDropOffAddressPopperAnchor(null)}
                />
            }
        </Grid>
    );
};

export default QRStepperHeader;
