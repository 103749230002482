import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PaymentCardSVG from '../../../../taskTemplates/svg/PaymentCardSVG';
import PaymentOnlineSVG from '../../../../taskTemplates/svg/PaymentOnlineSVG';
import PaymentRemotelySVG from '../../../../taskTemplates/svg/PaymentRemotelySVG';

import { showIntercom } from '../../../../../services';
import { FeatureKey } from '../../../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import GPopper from '../../../../common/GPopper';

export type RestrictedPaymentFeatureType = FeatureKey.PAYMENTS_CARD_PRESENT
    | FeatureKey.PAYMENTS_ONLINE
    | FeatureKey.PAYMENTS_PAY_REMOTELY;

export interface RestrictedPaymentFeature {
    anchorEle: HTMLElement;
    feature: RestrictedPaymentFeatureType;
}

export interface Props {
    restrictedPaymentFeature: RestrictedPaymentFeature | null;
    closePopper: () => void;
    zIndex: number;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    popper: {
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
    },
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
        width: 288,
        '@media (min-width: 360px)': {
            width: '320px',
        },
    },
    popperContent: {
        textAlign: 'center',
        padding: 8,
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    svgContainer: {
        margin: '16px 0 8px',
        '& svg': {
            maxWidth: 288,
            maxHeight: 120
        },
        '@media (min-width: 360px)': {
            '& svg': {
                maxWidth: 320,
            },
        },
    },
    disabledTopText: {
        fontSize: 21,
        fontWeight: 200,
        '@media (min-width: 360px)': {
            fontSize: 23
        }
    },
    linkText: {
        fontWeight: 200,
        fontSize: 15,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    label: {
        fontWeight: 200,
        lineHeight: '1.25em'
    },
});

type StyledProps = Props & WithGStyles<'root' | 'popper' | 'popoverPaper' | 'popperContent'
    | 'svgContainer' | 'disabledTopText' | 'linkText' | 'label'>;

class RestrictedPaymentFeaturePopper extends Component<StyledProps> {
    renderHeader = () => {
        const { restrictedPaymentFeature } = this.props;
        if (!restrictedPaymentFeature) {
            return '';
        }

        switch (restrictedPaymentFeature.feature) {
            case FeatureKey.PAYMENTS_CARD_PRESENT:
                return 'Card Reader Not Enabled';
            case FeatureKey.PAYMENTS_ONLINE:
                return 'Take Payment Instantly';
            case FeatureKey.PAYMENTS_PAY_REMOTELY:
                return 'Send Payment Instructions';
            default:
                return '';
        }
    };

    renderSvg = () => {
        const { restrictedPaymentFeature, classes } = this.props;
        if (!restrictedPaymentFeature) {
            return '';
        }

        switch (restrictedPaymentFeature.feature) {
            case FeatureKey.PAYMENTS_CARD_PRESENT:
                return <PaymentCardSVG themeFillClass={classes.fillPrimary} />;
            case FeatureKey.PAYMENTS_ONLINE:
                return <PaymentOnlineSVG themeFillClass={classes.fillPrimary} />;
            case FeatureKey.PAYMENTS_PAY_REMOTELY:
                return <PaymentRemotelySVG themeFillClass={classes.fillPrimary} />;
            default:
                return '';
        }
    };

    renderContent = () => {
        const { classes } = this.props;

        return (
            <>
                <Grid
                    item
                    xs={12}
                    className={classNames(classes.textCenter)}
                >
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="secondary"
                        className={classes.disabledTopText}
                    >
                        {this.renderHeader()}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="secondary"
                        className={classes.linkText}
                        noWrap={false}
                        onClick={event => showIntercom()}
                    >
                        Click to contact Gather to enable
                    </Typography>
                </Grid>

                <Grid
                    item
                    className={classNames(classes.textCenter, classes.svgContainer)}
                >
                    {this.renderSvg()}
                </Grid>

                <Grid item xs={12} className={classes.textCenter}>
                    <Typography
                        variant="subtitle1"
                        color="secondary"
                        className={classes.label}
                    >
                        Save money on high processing fees
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="secondary"
                        className={classes.label}
                    >
                        Track every dollar effortlessly
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="secondary"
                        className={classes.label}
                    >
                        Offer immediate refunds
                    </Typography>
                </Grid>
            </>
        );
    };

    render() {
        const { classes, restrictedPaymentFeature, closePopper, zIndex } = this.props;
        return (
            <GPopper
                anchorEle={restrictedPaymentFeature && restrictedPaymentFeature.anchorEle || null}
                className={classes.popper}
                popperContentClass={classes.popperContent}
                paperClass={classes.popoverPaper}
                zIndex={zIndex}
                closePopper={e => closePopper()}
                placement='top'
            >
                {this.renderContent()}
            </GPopper>
        );
    }
}

export default withGStyles(styles)(RestrictedPaymentFeaturePopper);
