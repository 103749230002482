import * as React from 'react';
import classNames from 'classnames';
import YouTube from 'react-youtube';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import Fab from '@mui/material/Fab';
import CardHeader from '@mui/material/CardHeader';

import AddIcon from '@mui/icons-material/Add';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { AvatarUser } from '../../../shared/types/user';

import UserAvatar from '../../common/UserAvatar';
import { convertHexToRGBA, FuneralHomeRoutePage, getFullNameFromCase, RouteBuilder } from '../../../services';

import * as appService from '../../../services/app.service';
import { FuneralHomeUX, FeatureKey, GatherCasePreview } from '../../../shared/types';
import { SvgIconComponent } from '@mui/icons-material';
import makeGStyles from '../../../styles/makeGStyles';
import { WhenEnabled } from '../../common/AppFeature';
import GLink from '../../common/GLink';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { GStyles } from '../../../styles/GStyles';

export const useStyles = makeGStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 800,
        margin: 12,
        '@media (min-width: 600px)': {
            margin: '32px auto 12px auto'
        },
        '& $name': {
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '1em',
            marginTop: 8
        },
        '& $phone': {
            fontWeight: 300,
            fontSize: 16,
            lineHeight: '1em'
        },
    },
    quote: {
        position: 'relative',
        fontSize: 20,
        fontWeight: 300,
        textAlign: 'left',
        '& $quotes': {
            fontSize: 32,
            margin: '0 4px',
            fontWeight: 700,
            color: theme.palette.primary.main,
            '&:first-of-type': {
                height: 96,
                minHeight: 96,
                position: 'absolute',
                margin: 'auto 4px',
                top: 0,
                left: 100 // width of avatar
            },
            '&:last-child': {
                height: 16,
                position: 'absolute',
                bottom: 6,
            }
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column'
    },
    avatar: {
        width: 100,
        height: 100,
        float: 'left',
        marginRight: 24,
        fontSize: 32,
        textTransform: 'uppercase',
    },
    casesListContainer: {
        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        background: `${convertHexToRGBA(theme.palette.primary.main, 0.20)}`
    },
    parentHeading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& $heading': {
            fontSize: 20,
            fontWeight: 200
        },
        '& $subHeading': {
            fontSize: 14,
            fontWeight: 200
        }
    },
    parentAvatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '24px auto',
        width: 'max-content',
        '& $iconStyle': {
            margin: '10px 22px',
            fontSize: 60,
            color: theme.palette.primary.main
        },
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    marginLeft24: {
        marginLeft: 24
    },
    addIconMargin: {
        marginRight: 24,
        marginLeft: 12,
    },
    styleName: {
        width: '100%',
        maxWidth: 94,
        fontSize: 12,
        fontWeight: 300,
        textAlign: 'center'
    },
    card: {
        margin: '16px 0',
        width: 220,
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '8px',
        '&$inverted': {
            background: theme.palette.common.white,
            color: theme.palette.primary.main,
        },
        '& $cardActionArea': {
            height: '100%'
        },
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
        '& $cardContent': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '8px !important',
            '& $iconStyle': {
                color: 'inherit',
                fontSize: 48
            },
            '& $labelStyle': {
                color: 'inherit',
                fontSize: 20,
                textAlign: 'center',
                fontWeight: 200
            },
            '& $subLabelStyle': {
                color: 'inherit',
                fontSize: 12,
                width: 140,
                textAlign: 'center',
                fontWeight: 200
            },
        },
    },
    gbutton: {
        width: 'calc(100% - 48px)',
        maxWidth: 320,
        margin: 'auto',
        cursor: 'pointer',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '8px',
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
        '@media (min-width: 400px)': {
            width: 'calc(100% - 68px)',
        },
    },
    styleCard: {
        display: 'table',
        justifyContent: 'center',
        margin: 'auto',
        padding: 8
    },
    styleAvatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& $itemAvatar': {
            width: 80,
            height: 80,
            margin: '0 12px',
            textTransform: 'uppercase',
            fontSize: 32,
            '&:hover': {
                boxShadow: theme.shadows[10],
            },
        },
    },
    documentListContainer: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        background: `${convertHexToRGBA(theme.palette.primary.main, 0.20)}`,
        boxShadow: 'inset 0px 0px 6px 0px rgba(0,0,0,0.2)',
        margin: '8px 0',
        borderRadius: 6,
        height: 240,
        overflowY: 'auto'
    },
    documnetParent: {
        margin: '24px auto !important',
        width: 'max-content',
        '& li': {
            margin: 0
        }
    },
    documentCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 12px',
        border: '1px dashed rgba(0, 0, 0, 0.2)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& $documentCardActionArea': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 207,
            width: 160,
            '& $documentStyleContent': {
                fontSize: 12
            }
        }
    },
    gChip: {
        color: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px auto',
        width: 'max-content',
        padding: '4px 8px',
        '& p': {
            fontSize: 13,
            fontWeight: 300
        },
    },
    documentHeading: {
        margin: '0 auto 4px',
        fontWeight: 200,
        maxWidth: 140
    },
    overflowAuto: {
        overflow: 'auto'
    },
    quoteBody: {
        paddingRight: '20px',
        paddingLeft: '20px',
        '@media (max-width: 600px)': {
            marginLeft: 0
        },
    },
    marginRight0: {
        marginRight: 0,
    },
    fontSize48: {
        fontSize: 48
    },
    addIcon: {
        height: 80,
        width: 80,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
    },
    rootVideoSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '36px 0',
        maxWidth: 600,
        margin: 12,
        width: 'calc(100% - 24px)',
        '@media (min-width: 624px)': {
            width: '100%',
            margin: '12px auto'
        },
    },
    videoHeader: {},
    videoHeading: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        lineHeight: '1em',
        fontWeight: 500,
        fontSize: 24,
    },
    videoSubHeading: {
        fontSize: 16,
        lineHeight: '1.25em',
        fontWeight: 300,
        textAlign: 'center',
    },
    videoSection: {
        width: '100% !important',
        height: '300px !important',
        margin: '12px',
        boxShadow: '0px 2px 22px 6px rgba(0,0,0,0.2)'
    },
    youtubeIframeContainer: {
        height: 'inherit',
    },
    rootDocSection: {
        width: '100%',
        maxWidth: 820,
        margin: '20px auto',
        padding: '0 16px',
        '@media (min-width: 768px)': {
            margin: '40px auto',
        },
        '@media (min-width: 852px)': {
            padding: 0,
        }
    },
    documentHead: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        '@media (min-width: 768px)': {
            flexDirection: 'row',
        },
        '& $heading': {
            fontSize: 12,
            width: '100%',
            marginTop: 12,
            '@media (min-width: 768px)': {
                width: 'calc(100% - 180px)',
                marginRight: 8,
                marginTop: 0,
            },
        }
    },
    uploadDocButton: {
        width: 180,
    },
    textBold: {
        fontWeight: 'bold'
    },
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '16px 0',
        '@media (min-width: 888px)': { // 420 * 2 + padding:48
            justifyContent: 'flex-start',
        },
        '@media (min-width: 960px)': {
            justifyContent: 'center',
        },
        '@media (min-width: 1208px)': {
            justifyContent: 'flex-start',
        },
        '& $item': {
            width: '100%',
            maxWidth: 380
        }
    },
    menu: {},
    onHoverEffect: {
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
    },
    dashboardIcon: {
        margin: '0 16px',
        fontSize: 60,
        color: theme.palette.primary.main,
        padding: 10
    },
    hoverDashboardIcon: {
        '&:hover': {
            boxShadow: theme.shadows[10],
            borderRadius: '50%'
        },
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    quoteAvatarContainer: {
        position: 'relative',
        float: 'left',
        width: 'fit-content',
    },
    quoteAvatar: {
        margin: '0 20px 0 8px',
    },
    keepTrackBadge: {},
    inverted: {},
    item: {},
    documentStyleContent: {},
    documentCardActionArea: {},
    quotes: {},
    name: {},
    phone: {},
    iconStyle: {},
    labelStyle: {},
    cardActionArea: {},
    heading: {},
    subHeading: {},
    itemAvatar: {},
    inner: {},
    subLabelStyle: {},
    cardContent: {}
}), { name: 'CasesList' });

export interface YouTubeOptions {
    height?: string;
    width?: string;
    playerVars?: {
        autoplay?: 0 | 1;
        cc_load_policy?: 1;
        color?: 'red' | 'white';
        controls?: 0 | 1 | 2;
        disablekb?: 0 | 1;
        enablejsapi?: 0 | 1;
        end?: number;
        fs?: 0 | 1;
        hl?: string;
        iv_load_policy?: 1 | 3;
        list?: string;
        listType?: 'playlist' | 'search' | 'user_uploads';
        loop?: 0 | 1;
        modestbranding?: 1;
        origin?: string;
        playlist?: string;
        playsinline?: 0 | 1;
        rel?: 0 | 1;
        showinfo?: 0 | 1;
        start?: number;
    };
}

interface QuoteProps {
    quote: string;
    user: AvatarUser | null;
    name: string;
    phone: string;
}

export const Quote = (props: QuoteProps) => {
    const { quote, user, name, phone } = props;

    const classes = useStyles();

    return (
        <Grid item xs={12} className={classNames(classes.root, classes.quoteBody)}>
            <Grid container flexDirection="column" className={classes.quoteAvatarContainer}>
                <UserAvatar
                    size={100}
                    user={user}
                    className={classes.avatar}
                />
            </Grid>

            <Typography color="secondary" className={classes.quote}>
                <span className={classes.quotes}>"</span>
                {quote}
                <span className={classes.quotes}>"</span>
            </Typography>

            <div className={classes.footer}>
                <Typography color="primary" className={classes.name}>
                    {name}
                </Typography>
                <Typography color="primary" className={classes.phone}>
                    {appService.getFormattedPhoneNumber(phone)}
                </Typography>
            </div>

        </Grid>
    );
};

interface GCardProps {
    isInverted?: boolean;
    label: React.ReactNode;
    icon: string;
    subLabel: React.ReactNode;
    onClick: () => void;
}

export const GCard = (props: GCardProps) => {
    const { label, icon, subLabel, onClick, isInverted } = props;

    const classes = useStyles();

    return (
        <Card
            className={classNames(
                classes.card,
                isInverted && classes.inverted
            )}
            onClick={() => onClick()}
        >
            <CardActionArea className={classes.cardActionArea}>
                <CardContent color="primary" className={classes.cardContent}>
                    <Icon className={classes.iconStyle}>{icon}</Icon>
                    <Typography className={classes.labelStyle}>{label}</Typography>
                    <Typography className={classes.subLabelStyle}>{subLabel}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export const CardButton = (props: GCardProps) => {
    const { label, icon, subLabel, onClick } = props;

    const classes = useStyles();

    return (
        <Card className={classes.gbutton} onClick={() => onClick()}>
            <CardHeader
                className={classes.styleCard}
                avatar={<Icon className={classes.fontSize48}>{icon}</Icon>}
                title={label}
                subheader={subLabel}
                classes={{ avatar: classNames(classes.marginRight0, classes.justifyCenter) }}
            />
        </Card>
    );
};

export interface VideoSectionProps {
    videoEmbedCode: string;
    heading?: React.ReactNode | null;
    subHeading?: React.ReactNode | null;
    rootClass?: string;
}

export interface YouTubeProps {
    pauseVideo: () => void;
    playVideo: () => void;
    stopVideo: () => void;
}

// onReady = (event: { target: YouTubeProps }) => {
//     this.youTubePlayer = event.target;
// }

export const VideoSection = (props: VideoSectionProps) => {
    const { heading, subHeading, videoEmbedCode, rootClass } = props;

    const classes = useStyles();

    const youtubeOptions: YouTubeOptions = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            showinfo: 0,
            rel: 0,
        }
    };
    return (
        <Grid item className={classNames(classes.rootVideoSection, rootClass)}>
            <div className={classes.videoHeader}>
                {heading && <Typography className={classes.videoHeading} color="primary">
                    {heading}
                </Typography>}
                {subHeading && <Typography className={classes.videoSubHeading} color="secondary">
                    {subHeading}
                </Typography>}
            </div>

            <div className={classes.videoSection}>
                <YouTube
                    className={classes.youtubeIframeContainer}
                    videoId={videoEmbedCode}
                    opts={youtubeOptions}
                // onReady={}
                />
            </div>
        </Grid>
    );
};

interface CaseAvatarProps {
    activeCase: GatherCasePreview;
}

export const CaseAvatar = (props: CaseAvatarProps) => {
    const { activeCase } = props;

    const classes = useStyles();

    return (
        <div className={classes.styleAvatar}>
            <UserAvatar
                className={classes.itemAvatar}
                user={{
                    fname: activeCase.fname,
                    lname: activeCase.lname,
                    photo: activeCase.photo,
                    photo_transformations: activeCase.photo_transformations,
                    photo_id: activeCase.photo_id
                }}
                size={80}
            />
            <Typography color="secondary" className={classes.styleName} noWrap>
                {getFullNameFromCase(activeCase)}
            </Typography>
        </div>
    );
};

interface CasesListProps {
    cases: GatherCasePreview[];
    funeralHome: FuneralHomeUX;
    loadCases: () => void;
    onAnalyticsMenuOpen: () => void;
}

type FeatureValue = {
    key: FeatureKey;
    label: string;
    enabled: boolean;
};

export const CasesList = (props: CasesListProps) => {
    const { cases, funeralHome, loadCases, onAnalyticsMenuOpen } = props;

    const classes = useStyles();

    React.useEffect(() => {
        loadCases();
    }, [loadCases]);

    const { features } = funeralHome;
    const fkeys = Object.keys(features) as FeatureKey[];
    const demoFlags = fkeys.
        filter(k => features[k].type === 'demo').
        map(k => ({ key: k, ...features[k] } as FeatureValue)).
        sort((a, b) => a.label > b.label ? 1 : -1);

    const filteredDemoFlag = demoFlags.find(df => df.key === FeatureKey.DEMO_ALLOW_CASE_CREATION);

    return (
        <Grid item className={classes.casesListContainer}>
            <div className={classes.parentHeading}>
                <Typography className={classes.heading} color="secondary">
                    Want to see an example?
                </Typography>
                <Typography className={classes.subHeading} color="secondary">
                    Check out how user-friendly Gather is for you, your team, and your families
                </Typography>
            </div>

            <div className={classes.overflowAuto}>
                <div className={classes.parentAvatar}>
                    <GLink
                        to={RouteBuilder.FuneralHome(funeralHome.key)}
                    >
                        <div className={classNames(classes.icon, classes.marginLeft24)}>
                            <ViewCarouselIcon
                                className={classNames(
                                    classes.dashboardIcon,
                                    classes.hoverDashboardIcon
                                )}
                            />

                            <Typography color="secondary" className={classes.styleName}>
                                View Dashboard
                            </Typography>
                        </div>
                    </GLink>

                    <WhenEnabled feature={FeatureKey.KEEP_TRACK}>
                        <GLink
                            to={RouteBuilder.FuneralHome(funeralHome.key, FuneralHomeRoutePage.WHITEBOARD)}
                        >
                            <div className={classes.icon}>
                                <QrCode2Icon
                                    className={classNames(
                                        classes.dashboardIcon,
                                        classes.hoverDashboardIcon
                                    )}
                                />

                                <Typography color="secondary" className={classes.styleName}>
                                    View WhiteBoard
                                </Typography>
                            </div>
                        </GLink>
                    </WhenEnabled>

                    <WhenEnabled feature={FeatureKey.GATHER_ANALYTICS}>
                        <GLink to={RouteBuilder.FuneralHome(funeralHome.key)}>
                            <div
                                className={classNames(GStyles.cursorPointer, classes.icon)}
                                onClick={onAnalyticsMenuOpen}
                            >
                                <TrendingUpIcon
                                    className={classNames(
                                        classes.dashboardIcon,
                                        classes.hoverDashboardIcon
                                    )}
                                />

                                <Typography color="secondary" className={classes.styleName}>
                                    View Analytics
                                </Typography>
                            </div>
                        </GLink>
                    </WhenEnabled>

                    {cases.map((c: GatherCasePreview) =>
                        <GLink
                            key={c.name}
                            to={RouteBuilder.FamilyPage({
                                caseName: c.name,
                                funeralHomeKey: funeralHome.key,
                            })}
                        >
                            <CaseAvatar activeCase={c} />
                        </GLink>
                    )}

                    {(filteredDemoFlag && filteredDemoFlag.enabled) &&
                        <div className={classNames(classes.icon, classes.addIconMargin)}>
                            <GLink to={RouteBuilder.FuneralHome(funeralHome.key, FuneralHomeRoutePage.CREATECASE)}>
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    className={classes.addIcon}
                                >
                                    <AddIcon fontSize="large" />
                                </Fab>

                                <Typography color="secondary" className={classes.styleName}>
                                    Create Case
                                </Typography>
                            </GLink>
                        </div>}

                </div>
            </div>

        </Grid>
    );
};

interface DocumentCardProps {
    label: string;
}

export const DocumentCard = (props: DocumentCardProps) => {
    const { label } = props;

    const classes = useStyles();

    return (
        <li>
            <div className={classes.inner}>
                <Typography className={classes.documentHeading} color="secondary" align="center" noWrap>
                    {label}
                </Typography>

                <Card className={classes.documentCard}>
                    <CardActionArea className={classes.documentCardActionArea}>
                        <Typography color="secondary" className={classes.documentStyleContent}>
                            Click to upload
                        </Typography>
                    </CardActionArea>
                </Card>
            </div>
        </li>
    );
};

interface GChipProps {
    Icon?: SvgIconComponent;
    label: string;
    className?: string;
}

export const GChip = (props: GChipProps) => {
    const { Icon: ComponentIcon, label, className } = props;

    const classes = useStyles();

    return (
        <div className={classNames(className, classes.gChip)}>
            {ComponentIcon && <ComponentIcon />}
            <Typography color="primary">{label}</Typography>
        </div>
    );
};