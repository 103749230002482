import { QrStickerGenerateRequest, QrStickerGenerateResponse } from '../shared/types';
import { registerAppError } from './errors';
import { postToAPI } from './index';
import { AppDispatch } from '../store';
import { log } from "../logger";
import { 
    LabelDetails, 
    PrintAlignmentLabel, 
    PrintZebraLabel, 
    isZebraConfigAvailable, 
    sendZebraCommandstoPrinter 
} from '../services';

export function generateQrStickerPdf(qrStickerRequest: QrStickerGenerateRequest, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<QrStickerGenerateResponse | null> => {
        try {
            QrStickerGenerateRequest.fromRequest(qrStickerRequest);
        } catch (ex) {
            log.warn('Failed to validate QrStickerGenerateRequest', { qrStickerRequest, ex });
            return null;
        }

        const route = `api/case/${caseUuid}/qrsticker`;
        const request = await postToAPI<QrStickerGenerateResponse>(route, qrStickerRequest, dispatch);
        if (!request) {
            dispatch(registerAppError(`Could not generate sticker pdf.`));
            return null;
        }
        return request;
    };
}

export function generateQrZebraLabel(labelData: LabelDetails, labelCount: number) {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        const result = await PrintZebraLabel(labelData, labelCount);
        if (result.printStatus === false) {
            dispatch(registerAppError(`Error: ${result.message}`, { showSnackbar: true, prefix: 'ZEBRA_PRINTERROR' }));
            let displayMessage = labelCount === 1 ? `Failed to send ${labelCount} label to printer.`
                : `Failed to send ${labelCount} labels to printer.`;

            displayMessage += `Please check the printer and that BrowserPrint is running and try again.`;
            if (result.subMessage) {
                displayMessage += `(${result.subMessage})`;
            }
            return displayMessage;
        }
        return null;
    };
}

export function generateAlignmentLabel() {
    return async (dispatch: AppDispatch): Promise<string | null> => {
        const result = await PrintAlignmentLabel();
        log.warn(`GenerateTestTemplate result: ${result||''}`);
        if (result) {
            const message = result || '';
            dispatch(registerAppError(`Error: ${message}`, { showSnackbar: true}));
            const msg = `Failed to send to printer. Please check the printer and BrowserPrint and try again.`;
            return msg;
        }
        return `unknown error`;
    };
}

export function generateSampleLabel() {
    const details: LabelDetails = {
        funeralHomeName: 'Sample Funeral Home', // 30 chars
        keeptrackTagId: 'X12345', // 6 Chars
        funeralHomeCaseAssocId: 1 , // will be encoded to QR Code
        fullNameDisplayStr: 'Jane Doe' , //
        dobDisplayStr: '12 JUN 1950', //  11 Chars  DAYofMonth MON YEAR eg 30 JAN 1922
        dodDisplayStr: '29 OCT 2023', //  11 Chars  DAYofMonth MON YEAR eg 30 JAN 1922
        ageInYears: 73,
        fhCaseNumber: 'SampleCase-2023-004', //  approx 19 chars
    };
    return generateQrZebraLabel(details, 1);
}

export function sendZebraCommands (cmds: string) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        const result = await sendZebraCommandstoPrinter(cmds);
        return result;
    };
}

export function isZebraPrinterConfigurationAvailable(ipaddress: string) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        return await isZebraConfigAvailable({ipaddress});
    };
}