import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';

import { GLOBAL_STYLED_PROPS } from '../../../styles';
import GSwitch from '../../common/inputElements/GSwitch';
import { Permission } from '../../../shared/types/permissions';
import { RED_COLOR } from '../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../services';
import withGStyles from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        minHeight: 505,
        padding: '0 10px',
        '@media (min-width: 768px)': {
            padding: 0
        }
    },
    gSwitchClass: {
        height: 32
    },
    gSwitchContainer: {
        marginBottom: 8,
        '@media (min-width: 768px)': {
            maxWidth: 352
        }
    },
    gSwitchHelperText: {
        marginLeft: 48,
        marginTop: -4,
        fontSize: 13,
        lineHeight: 1.25,
        '@media (min-width: 420px)': {
            fontSize: 14
        }
    },
    labelClass: {
        fontSize: 13,
        color: theme.palette.primary.main,
        '@media (min-width: 420px)': {
            fontSize: 14
        }
    },
    warningClass: {
        maxWidth: 348,
        borderRadius: 8,
        border: `1px solid ${RED_COLOR}`,
        background: convertHexToRGBA(RED_COLOR, 0.08),
        padding: '0 0 8px 8px',
        '& p': {
            fontSize: 13,
            lineHeight: 1.25
        }
    },

});

type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root'
    | 'gSwitchContainer' | 'gSwitchClass' | 'gSwitchHelperText' | 'labelClass'
    | 'warningClass'
>;

interface Props {
    key: Permission;
    label: string;
    helperText?: string | null;
    helperTextWarning?: string | null;
    isChecked: boolean;
    isMe: boolean;
    onChange: (checked: boolean) => void;
    isDisabled: boolean;
    labelClasses?: string;
    formControlLabelClasses?: string;
    disabledTooltip?: string;
    toolTipText?: string;
}

type CombinedProps = Props & StyledProps;

const AppPermissionGridSwitch = (props: CombinedProps) => {
    const {
        key,
        label,
        helperText,
        helperTextWarning,
        isChecked,
        isDisabled,
        classes,
        labelClasses,
        formControlLabelClasses,
        disabledTooltip,
        toolTipText,
        onChange
    } = props;

    const displayHelperText = helperText
        ? helperTextWarning
            ? <Typography className={classes.colorRed}>
                <span className={classes.fontWeight500}>{helperTextWarning}</span>:
                {helperText}
            </Typography>
            : <Typography color="secondary" className={classes.gSwitchHelperText}>
                {helperText}
            </Typography>
        : '';

    return (
        <Grid
            item
            key={key}
            className={helperTextWarning ? classes.warningClass : classes.gSwitchContainer}
        >
            <Tooltip placement="top" title={isDisabled && disabledTooltip || toolTipText || ''}>
                <FormControl>
                    <GSwitch
                        checked={isChecked}
                        label={label}
                        onChangeCallBack={onChange}
                        labelClasses={labelClasses ? labelClasses : classes.labelClass}
                        formControlLabelClasses={formControlLabelClasses
                            ? formControlLabelClasses : classes.gSwitchClass}
                        disabled={isDisabled}
                    />
                </FormControl>
            </Tooltip>

            {displayHelperText}
        </Grid>
    );
};

export default withGStyles(styles)(AppPermissionGridSwitch);
