import { GridFilterOperator, GridTypeFilterInputValueProps } from "@mui/x-data-grid-premium";
import { TextField } from "@mui/material";

export const isEmpty = (value: string | null | undefined) => {
    return value === null || value === undefined || value === '';
};

export const isNotEmpty = (value: string | null | undefined) => {
    return !isEmpty(value);
};

export const isTrue = (value: boolean) => value === true;
export const isFalse = (value: boolean) => value === false;

export const CustomFilterInputField = (props: GridTypeFilterInputValueProps) => {
    const { label } = props;
    return (
        <TextField label={label} disabled />
    );
};

const customFilterOperators: GridFilterOperator[] = [
    {
        label: 'Any',
        value: 'any',
        getApplyFilterFn: () => (row) => true,
        InputComponent: CustomFilterInputField,
    },
    {
        label: 'Is Empty',
        value: 'isEmpty',
        getApplyFilterFn: () => (row) => isEmpty(row.value),
    },
    {
        label: 'Is Not Empty',
        value: 'isNotEmpty',
        getApplyFilterFn: () => (row) => isNotEmpty(row.value),
    },
    {
        label: 'Is True',
        value: 'true',
        getApplyFilterFn: () => (row) => isTrue(row.value),
    },
    {
        label: 'Is False',
        value: 'false',
        getApplyFilterFn: () => (row) => isFalse(row.value),
    },
];

export const localeText = {
    headerFilterOperatorAny: 'Any',
    headerFilterOperatorIsEmpty: 'Is Empty',
    headerFilterOperatorIsNotEmpty: 'Is Not Empty',
    headerFilterOperatorTrue: 'Is True',
    headerFilterOperatorFalse: 'Is False',
};

export const photosOperators = customFilterOperators.filter(({ value }) => value === 'any'
    || value === 'isEmpty'
    || value === 'isNotEmpty');

export const booleanOperators = customFilterOperators.filter(({ value }) => value === 'any'
    || value === 'true'
    || value === 'false');
