import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';

import { TransitionGroup } from 'react-transition-group';
import SlideZoomListTransition from '../../../SlideZoomListTransition';
import makeGStyles from '../../../../../styles/makeGStyles';
import { getIntercomTargetProp } from '../../../../../services';

const useStyles = makeGStyles((theme) => ({
    root: {},
    taskOverview: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '12px 12px 0 0',
        padding: '2px 16px',
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
        '@media (min-width: 768px)': {
            width: 'calc(100% - 40px)',
        },
        '& p': {
            color: theme.palette.common.white,
            fontSize: 14,
            '@media (min-width: 400px)': {
                fontSize: 16
            }
        },
    },
    textName: {
        display: 'inline',
    },
    completeText: {
        fontWeight: 400,
        color: theme.palette.common.white,
        margin: 'auto'
    },
    fontSize16: {
        fontSize: 16
    },
    textUpperCase: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: theme.palette.common.white,
        '& svg': {
            fontSize: 16,
            margin: '0 0 0 8px',
        }
    },
    showCompleteSteps: {
        display: 'none',
        '@media (min-width: 600px)': {
            display: 'block'
        },
    }
}), { name: 'TaskOverviewBar' });

export enum TaskBarListType {
    tasks = 'tasks',
    steps = 'steps'
}

interface Props {
    caseFirstName: string;
    taskName: string;
    taskCompletionValue: number;
    tasksLength: number;
    taskCompletedPct: number;
    isBulkEditMode: boolean;
    listType: TaskBarListType;
    onBulkEditClick: () => void;
    customText?: string;
    taskOverviewClass?: string;
    disableClick?: boolean;
    intercomTargetProp?: string;
}

const TaskOverViewBar = (props: Props) => {

    const {
        taskName,
        taskCompletionValue,
        tasksLength,
        taskCompletedPct,
        caseFirstName,
        isBulkEditMode,
        onBulkEditClick,
        taskOverviewClass,
        listType,
        customText,
        disableClick,
        intercomTargetProp,
    } = props;

    const classes = useStyles();

    const tooltip = isBulkEditMode
        ? `Click to finish editing`
        : `Click to edit ${caseFirstName}'s ${listType} in bulk`;

    let status: JSX.Element | string;
    if (taskCompletedPct === 100) {
        status = <>100%&nbsp;<span className={classes.fontSize16}>Complete</span></>;
    } else if (customText) {
        status = customText;
    } else {
        status = <>
            <span>
                {taskCompletionValue}
            </span>
            &nbsp;of&nbsp;
            <span>
                {tasksLength}
            </span>&nbsp;
            <span
                className={classes.showCompleteSteps}
                {...getIntercomTargetProp(`TrackingPage-HeaderStepsCompleteCount`)}
            >
                {listType === TaskBarListType.steps && 'Steps '}
                Complete
            </span>
        </>;
    }

    return (
        <TransitionGroup>
            <SlideZoomListTransition>
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.taskOverview,
                        taskOverviewClass,
                        isBulkEditMode && classes.backgroundOrange
                    )}
                    {...getIntercomTargetProp(intercomTargetProp)}
                >
                    <Tooltip title={disableClick ? '' : tooltip} placement="top" enterDelay={600}>
                        <Typography
                            className={classNames(
                                classes.textUpperCase,
                                !disableClick && classes.clickable,
                            )}
                            onClick={!disableClick ? onBulkEditClick : undefined}
                            {...getIntercomTargetProp(`TrackingPage-HeaderClickToEditStepsInBulk`)}
                        >
                            {taskName}
                            {!disableClick &&
                                <>{isBulkEditMode ? <UndoIcon /> : <EditIcon />}</>
                            }
                        </Typography>
                    </Tooltip>

                    <Typography className={classes.displayInlineFlex}>
                        {status}
                    </Typography>
                </Grid>
            </SlideZoomListTransition>
        </TransitionGroup>
    );
};

export default TaskOverViewBar;
