import ReportIcon from '@mui/icons-material/Report';
import BaseErrorPage from './BaseErrorPage';

const Unauthorized = () => {
    const heading = 'Sorry, access has been denied.';
    const subheading = `It looks like you don't have the correct access.
    Please contact a Funeral Home Administrator for assistance.`;

    return (
        <BaseErrorPage
            HeadingIcon={ReportIcon}
            heading={heading}
            subHeading={subheading}
        />
    );
};

export default Unauthorized;
