import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import DocItemList from './widgets/DocItemList';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { UserRoles } from '../../shared/types/user';
import { DocType } from './DocPreview.dialog';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { GatherCaseUX } from '../../shared/types/case';
import { useGDispatch, useGSelector } from '../../types';
import { DocCategory, DocRecord, DocUXDetail } from '../../shared/types/doc';
import BlankDD214Item from './widgets/BlankDD214Item';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LockIcon from '@mui/icons-material/Lock';
import { downloadCaseDoc, removeCaseDoc, updateCaseDoc, createCaseDocs, loadCaseDocs } from '../../actions/Doc.action';
import { downloadFromURL } from '../../services/doc.service';
import { GRAY_COLOR } from '../../constants/colorVariables';
import AssignTask from '../assignmentPoppers/AssignTask';
import MenuItem from '@mui/material/MenuItem';
import { CaseTaskUX, TaskTemplateType, YesNoUnknownEnum } from '../../shared/types';
import { HiddenDocMenuItems } from './widgets/DocItemList/DocItemMenu';
import FileUploader, { AcceptFileType } from '../common/FileUploader';
import { WhenDisabled, WhenEnabled } from '../common/AppFeature';
import { FeatureKey } from '../../shared/types/funeralHome';
import classNames from 'classnames';
import SignDigitallySVG from './svg/SignDigitallySVG';
import { showIntercom, getIntercomTargetProp } from '../../services';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { GStyles } from '../../styles/GStyles';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 68,
        '& button': {
            '&:first-of-type': {
                padding: '6px 12px',
            },
        },
        '& p': {
            fontSize: 12,
            fontWeight: 200,
        },
        '& $inner': {
            marginTop: -16,
        },
    },
    initialState: {
        margin: '36px auto 0',
        padding: '0 12px',
        maxWidth: 600,
        color: GRAY_COLOR,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& > svg': {
            fontSize: 64,
            margin: 8,
        },
        '& p': {
            lineHeight: '1.75em',
            fontWeight: 300,
            '& svg': {
                fontSize: 16,
            },
        },
    },
    inner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    signNotEnabled: {
        padding: '8px 8px',
    },
    disabledTopText: {
        fontSize: 21,
        fontWeight: 200,
        '@media (min-width: 360px)': {
            fontSize: 23,
        },
    },
    svgContainer: {
        margin: '16px 0 8px',
        '& svg': {
            maxWidth: 260,
            maxHeight: 120,
        },
    },
    label: {
        fontWeight: 200,
        lineHeight: '1.25em',
    },
    linkText: {
        fontWeight: 200,
        fontSize: 15,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    paper: {
        overflow: 'auto',
    },
}));

type Props = {
    zIndex: number;
    compactView: boolean;
    tabIndex?: number;
    activeCase: GatherCaseUX;
    setIsDocMenuOpen?: (value: boolean) => void;
    intercomTargetProp?: string;
    intercomTargetPropDocItem?: string;
    intercomTargetPropDocMenu?: string;
};

const CaseFiles = (props: Props) => {
    const {
        tabIndex,
        activeCase,
        zIndex,
        compactView,
        setIsDocMenuOpen,
        intercomTargetProp,
        intercomTargetPropDocItem,
        intercomTargetPropDocMenu
    } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    useEffect(() => {
        dispatch(loadCaseDocs(activeCase.uuid));
    }, [dispatch, activeCase.uuid]);

    const caseDocs = useGSelector(({ caseDocState }) => caseDocState.docs);
    const isLoading = useGSelector(({ caseDocState }) => caseDocState.isLoading);
    const userData = useGSelector(({ userSession }) => userSession.userData);
    const checklistTasks = useGSelector(({ tasksState }) => tasksState.checklistTasks);

    const [docCategory, setDocCategory] = useState<DocCategory>(DocCategory.general);
    const [isUploading, setIsUploading] = useState(false);
    const [isAssignTaskPopperOpen, setIsAssignTaskPopperOpen] = useState(false);
    const [dd214MenuAnchorEle, setDd214MenuAnchorEle] = useState<HTMLElement | null>(null);

    const formDD214: DocUXDetail | null =
        caseDocs.find((d) => d.doc !== null && d.doc.doc_category === DocCategory.form_dd214) || null;
    const isFHorGOM = UserRoles.isFHorGOMUser(userData);
    const dd214Task = checklistTasks.find((t) => t.template_type === TaskTemplateType.form_dd_214);

    const orderedFilteredDocList = orderBy(caseDocs, (doc) => doc.label.toLowerCase(), ['asc']);

    const isCompactView = tabIndex === 1 || compactView;
    const isMilitaryCase =
        activeCase.death_certificate &&
        activeCase.death_certificate.military &&
        activeCase.death_certificate.military.military === YesNoUnknownEnum.Yes;
    const isGOMUser = UserRoles.isGOMUser(userData);

    const renderInitialState = () => {
        return (
            <Grid xs={12} item className={classes.initialState}>
                <FileCopyIcon color="inherit" />
                <Typography color="secondary" align="center">
                    Files related to {activeCase.fname}'s services can be stored here.
                </Typography>
                <Typography color="secondary" align="center" className={classNames(classes.inner, GStyles.marginTop5)}>
                    <LockIcon className={GStyles.marginRight5} />
                    &nbsp;All files are fully-encrypted and secure.
                </Typography>
            </Grid>
        );
    };

    const onDownloadDoc = (activeDocId: number) => {
        handleDownloadDoc(activeDocId);
    };

    const handleDownloadDoc = async (docId: number) => {
        const activeDoc = caseDocs.find((ele) => Boolean(ele.doc && ele.doc.id === docId));
        if (activeDoc && activeCase && activeCase.id) {
            const downloadDocResult = await dispatch(downloadCaseDoc(activeCase.uuid, docId));
            if (!downloadDocResult) {
                return;
            }
            downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
        }
    };

    const handleUpdateDoc = async (targetDocId: number, updateDocRecord: Partial<DocRecord>) => {
        if (targetDocId === null) {
            return;
        }

        await dispatch(updateCaseDoc(targetDocId, activeCase.uuid, updateDocRecord));
    };

    const handleRemoveDoc = (docId: number) => {
        dispatch(removeCaseDoc(activeCase.uuid, docId));
    };

    const canAssignTask = () => {
        const isAssignTask = isFHorGOM || (dd214Task && dd214Task.can_reassign_by_family);
        return (
            dd214Task &&
            isAssignTask &&
            (CaseTaskUX.isIncomplete(dd214Task) || (!CaseTaskUX.isIncomplete(dd214Task) && hasAssignees(dd214Task)))
        );
    };

    const hasAssignees = (task: CaseTaskUX) =>
        task.assigned_to_all || (task.assigned_to && task.assigned_to.length > 0);

    const renderAssignTaskPopover = () => {
        if (!canAssignTask() || !dd214Task) {
            return <></>;
        }
        return (
            <AssignTask
                zIndex={zIndex + 2}
                task={dd214Task}
                activeCase={activeCase}
                showAssignTaskLink
                openPopoverElement={<MenuItem>Assign Task</MenuItem>}
                closeMenu={() => {
                    setIsAssignTaskPopperOpen(false);
                    setDd214MenuAnchorEle(null);
                }}
                setChildPopperState={(isOpen) => setIsAssignTaskPopperOpen(isOpen)}
            />
        );
    };

    const onFileUpload = async (files: FileList | null) => {
        setDd214MenuAnchorEle(null);

        if (!userData?.user_id) {
            return;
        }

        if (files && files.length !== 0) {
            setIsUploading(true);
            await dispatch(
                createCaseDocs({
                    files,
                    userId: userData.user_id,
                    caseUuid: activeCase.uuid,
                    docCategory,
                }),
            );
            setIsUploading(false);
        }
    };

    const renderContent = () => {
        return (
            <>
                <Grid item xs={12} className={classes.header}>
                    <FileUploader
                        acceptTypes={[
                            AcceptFileType.JPEG,
                            AcceptFileType.GIF,
                            AcceptFileType.PNG,
                            AcceptFileType.DOCUMENT,
                            AcceptFileType.MSWORD,
                            AcceptFileType.PDF,
                        ]}
                        multiple
                        handleSave={onFileUpload}
                    >
                        {(inputRef) => (
                            <Button
                                color="primary"
                                size="medium"
                                variant="contained"
                                disabled={isUploading}
                                onClick={(e) => (inputRef && inputRef.click(), setDocCategory(DocCategory.general))}
                                {...getIntercomTargetProp(intercomTargetProp)}
                            >
                                <CloudUploadIcon />
                                &nbsp;upload new file
                                {isUploading && <CircularProgress size={24} className={GStyles.buttonProgress} />}
                            </Button>
                        )}
                    </FileUploader>
                </Grid>

                <DocItemList
                    docs={orderedFilteredDocList}
                    zIndex={zIndex + 1}
                    isCompactView={isCompactView}
                    isGOMUser={isGOMUser}
                    activeFuneralHomeId={activeCase.funeral_home_id}
                    menuAnchorReference="anchorPosition"
                    docsType={DocType.Case}
                    downloadDoc={onDownloadDoc}
                    renameDoc={(docId, label) => handleUpdateDoc(docId, { label })}
                    deleteDoc={handleRemoveDoc}
                    setMenuOpen={setIsDocMenuOpen}
                    getAdditionalMenuItems={(docDetail) => {
                        const isDD214 = (docDetail.doc && docDetail.doc.doc_category) === DocCategory.form_dd214;

                        return isDD214 ? [{ content: renderAssignTaskPopover() }] : [];
                    }}
                    getHiddenMenuItems={(docDetail) => {
                        const isMyFile =
                            isFHorGOM || (docDetail.doc && docDetail.doc.uploaded_by) === (userData && userData.id);

                        return !isMyFile ? [HiddenDocMenuItems.delete, HiddenDocMenuItems.rename] : [];
                    }}
                    intercomTargetPropDocMenu={intercomTargetPropDocMenu}
                    intercomTargetPropDocItem={intercomTargetPropDocItem}
                />

                {!formDD214 &&
                    isMilitaryCase &&
                    (!isUploading || docCategory !== DocCategory.form_dd214) &&
                    !isLoading && <BlankDD214Item isCompactView={isCompactView} onClick={setDd214MenuAnchorEle} />}

                {caseDocs.length === 0 && renderInitialState()}
            </>
        );
    };

    const autoFormsDisabled = () => {
        return (
            <div className={classes.signNotEnabled}>
                <Grid item xs={12} className={GStyles.textCenter}>
                    <Typography variant="subtitle1" align="center" color="primary" className={classes.disabledTopText}>
                        Signatures Not Enabled
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="primary"
                        className={classes.linkText}
                        noWrap={false}
                        onClick={(e) => showIntercom()}
                    >
                        Click to contact Gather to enable
                    </Typography>
                </Grid>

                <Grid item className={classNames(GStyles.textCenter, classes.svgContainer)}>
                    <SignDigitallySVG themeFillClass={GStyles.fillPrimary} />
                </Grid>

                <Grid item xs={12} className={GStyles.textCenter}>
                    <Typography variant="subtitle1" color="secondary" className={classes.label}>
                        Autofill and eSign your forms in seconds
                    </Typography>
                </Grid>
            </div>
        );
    };

    const renderDD214ItemMenu = () => {
        return (
            <Popper
                anchorEl={dd214MenuAnchorEle}
                open={Boolean(dd214MenuAnchorEle)}
                placement={'bottom'}
                style={{ zIndex: zIndex + 1 }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        if (dd214MenuAnchorEle && isAssignTaskPopperOpen) {
                            return;
                        }
                        setDd214MenuAnchorEle(null);
                    }}
                >
                    <Paper className={classes.paper}>
                        <FileUploader
                            acceptTypes={[
                                AcceptFileType.JPEG,
                                AcceptFileType.GIF,
                                AcceptFileType.PNG,
                                AcceptFileType.DOCUMENT,
                                AcceptFileType.MSWORD,
                                AcceptFileType.PDF,
                            ]}
                            handleSave={(evt) => onFileUpload(evt)}
                        >
                            {(inputRef) => (
                                <MenuItem
                                    onClick={(e) => {
                                        inputRef?.click();
                                        setDocCategory(DocCategory.form_dd214);
                                    }}
                                >
                                    Upload DD-214
                                </MenuItem>
                            )}
                        </FileUploader>
                        {renderAssignTaskPopover()}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        );
    };

    return (
        <>
            <WhenEnabled feature={FeatureKey.DOC_LIBRARY_AND_AUTOFORMS}>{renderContent()}</WhenEnabled>
            <WhenDisabled feature={FeatureKey.DOC_LIBRARY_AND_AUTOFORMS}>{autoFormsDisabled()}</WhenDisabled>
            {renderDD214ItemMenu()}
        </>
    );
};

export default CaseFiles;
