import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import FRQuestion from './FR.Question';

interface Props {
    caseFName: string;
    onStepChange: (step: number) => void;
}
const FRQuestionOne = (props: Props) => {
    const { caseFName, onStepChange } = props;

    const classes = useTaskDialogStyles();

    return (
        <FRQuestion
            heading="Applying is a relatively simple process done via a phone call to FEMA that should take
            around 20 minutes. It is important to verify that you qualify for the program and that
            you have the needed documentation."
            question={`Did ${caseFName} die of COVID-19 or COVID-19-like symptoms?`}
            onAccept={() => onStepChange(3)}
            onDeny={() => onStepChange(2)}
        >
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
            >
                The death certificate must indicate the death was caused by, “may have been
                caused by” or “was likely a result of” COVID-19 or COVID-19-like symptoms.
                Similar phrases that indicate a high likelihood of COVID-19 are considered
                sufficient attribution.
            </Typography>
        </FRQuestion>
    );
};

export default FRQuestionOne;                           