import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ClearIcon from '@mui/icons-material/Clear';
import PagesIcon from '@mui/icons-material/Pages';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';
import useFullScreen from '../../../common/hooks/useFullScreen';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            width: 300,
            maxWidth: 300,
            minWidth: 300,
            height: 'auto',
            borderRadius: 12,
            boxShadow: theme.shadows[5],
            [theme.breakpoints.up('md')]: {
                maxWidth: 300,
                minWidth: 300,
                width: 300,
                height: 'auto',
                maxHeight: 'auto'
            }
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: 16,
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    clearIcon: {
        zIndex: 1,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        cursor: 'pointer',
        filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,.8))',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    pagesIcon: {
        color: 'inherit',
        fontSize: 120
    },
    header: {
        fontSize: 24,
        color: 'inherit',
        borderBottom: '1px solid',
        textAlign: 'center',
        width: '100%'
    },
    subHeader: {
        color: 'inherit',
        fontSize: 13,
        fontWeight: 200,
        lineHeight: '2em',
        marginBottom: 16,
    },
    details: {
        color: 'inherit',
        fontSize: 14,
        fontWeight: 200
    },
    button: {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        margin: '24px auto 8px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            opacity: 0.8,
        }
    },
    dialogPaper: {}
});

type StyledProps = Props & WithGStyles<'root' | 'dialogContent' | 'clearIcon' | 'pagesIcon' | 'header'
    | 'subHeader' | 'details' | 'button' | 'dialogPaper'>;

interface Props {
    isDialogOpen: boolean;
    closeDialog: () => void;
    onConfirm: () => void;
    zIndex: number;
}

const BreakPackageDialog = (props: StyledProps) => {
    const {
        classes,
        isDialogOpen,
        closeDialog,
        onConfirm,
        zIndex
    } = props;

    const fullScreen = useFullScreen();

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            keepMounted
            onClose={() => closeDialog()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.root}
            classes={{ paper: classes.dialogPaper }}
            style={{ zIndex }}
        >
            <DialogContent className={classes.dialogContent}>
                <ClearIcon
                    className={classes.clearIcon}
                    onClick={e => closeDialog()}
                />
                <Grid container justifyContent="center" alignItems="center">
                    <PagesIcon className={classes.pagesIcon} />
                    <Typography className={classes.header}>
                        Break Package?
                    </Typography>

                    <Typography className={classes.subHeader}>
                        If you remove this item:
                    </Typography>

                    <Typography className={classes.details}>
                        Any package discount will be removed.
                    </Typography>

                    <Typography className={classes.details}>
                        Other items in the package will remain.
                    </Typography>

                    <Button
                        className={classes.button}
                        variant="contained"
                        onClick={e => onConfirm()}
                    >
                        break package
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withGStyles(styles)(BreakPackageDialog);
