import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { useState } from "react";
import FuneralHomeLogo from "../../../../common/FuneralHomeLogo";
import { SelfLoginStyledProps } from "../SelfLogin.styles";
import { LoginDialogStep, SelfLoginSrcAction } from "../SelfLoginDialog";

interface Props extends SelfLoginStyledProps {
    firstName: string;
    lastName: string;
    srcAction: SelfLoginSrcAction;
    hasFHLogo: boolean;
    caseDisplayFname: string;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    goToStep: (step: LoginDialogStep) => void;
    nextStep: LoginDialogStep;
}
const NameStep = (props: Props) => {
    const {
        classes,
        hasFHLogo,
        caseDisplayFname,
        srcAction,
        firstName,
        lastName,
        nextStep,
        setFirstName,
        setLastName,
        goToStep,
    } = props;

    const [hasNameError, setHasNameError] = useState(false);

    let greetingText;
    switch (srcAction) {
        case SelfLoginSrcAction.sharePhoto: {
            greetingText =
                `Hello, thanks for sharing! To keep ${caseDisplayFname}'s profile secure, ` +
                'each photo needs to come from a real person, not an anonymous post.';
            break;
        }
        case SelfLoginSrcAction.shareText:
            greetingText = `Hello, thanks for sharing! To keep ${caseDisplayFname}'s profile secure, `
                + `each memory needs to come from a real person, not an anonymous post.`;
            break;
        case SelfLoginSrcAction.signGuestbook:
            greetingText = `Hello, thanks for being here.Signing ${caseDisplayFname} 's Guestbook is a `
                + `simple way to show others how much you care.`;
            break;
        case SelfLoginSrcAction.flowerSale:
        case SelfLoginSrcAction.treeSale: {
            greetingText = 'Thanks for showing your support. Your order has been placed ' +
                `successfully. Connect with ${caseDisplayFname}'s profile by answering a few questions`;
            break;
        }
        default: {
            greetingText = 'Hello';
        }
    }

    const isValidName = !!(firstName.trim() && lastName.trim());

    return (
        <>
            <Grid item xs={12} className={classes.userDetails}>
                <Typography className={classes.fontSize16}>{greetingText}</Typography>
                <Typography
                    color="secondary"
                    className={classes.heading}
                >
                    What's your name?
                </Typography>

                <FormControl
                    error={hasNameError && !firstName.length}
                    className={classes.formControl}
                >
                    <TextField
                        label="My First Name"
                        color="primary"
                        fullWidth
                        value={firstName}
                        error={hasNameError && !firstName.length}
                        onChange={evt => setFirstName(evt.target.value)}
                        className={classes.textField}
                        inputProps={{ tabIndex: 1 }}
                    />
                </FormControl>
                <FormControl error={hasNameError && !lastName.length} className={classes.formControl}>
                    <TextField
                        label="My Last Name"
                        color="primary"
                        fullWidth
                        value={lastName}
                        error={hasNameError && !lastName.length}
                        onChange={evt => setLastName(evt.target.value)}
                        className={classes.textField}
                        inputProps={{ tabIndex: 1 }}
                    />
                </FormControl>
            </Grid>

            <Grid item className={classes.continueButtonContainer}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    tabIndex={1}
                    onClick={e => {
                        if (isValidName) {
                            setHasNameError(false);
                            goToStep(nextStep);
                        } else {
                            setHasNameError(true);
                        }
                    }}
                >
                    Continue
                </Button>

                <Grid container justifyContent="center" alignItems="center" className={classes.loginNowContainer}>
                    <Typography color="secondary" variant="h6" className={classes.haveAccount}>
                        Already have an account?&nbsp;
                    </Typography>
                    <Button
                        className={classNames(
                            classes.loginText,
                            classes.loginButton,
                        )}
                        onClick={e => goToStep(LoginDialogStep.existingUser)}
                        tabIndex={4}
                    >
                        Log in now
                    </Button>
                </Grid>

                {hasFHLogo && <FuneralHomeLogo className={classes.fhLogo} />}
            </Grid>
        </>
    );
};

export default NameStep;