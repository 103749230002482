import * as t from 'io-ts';
import { getValidator } from './utils';

/**
 * *************************************** * *************************************** * ***************************
 * Video
 * 
 * These are the assets that will be turned into videos 
 * *************************************** * *************************************** * ***************************
 */
// The following Doc Types utlize the io-ts 
const videoUpdateRequestDefinition = {
    event_id: t.union([t.number, t.null]),
    label: t.string,
    url: t.string,
    updated_by: t.union([t.number, t.null]),
    hidden_by: t.union([t.number, t.null]),
};

const VideoUpdateRequestType = t.partial(videoUpdateRequestDefinition);

export interface VideoUpdateRequest extends t.TypeOf<typeof VideoUpdateRequestType> {
}

export class VideoUpdateRequest {
    public static fromRequest = getValidator<VideoUpdateRequest>(VideoUpdateRequestType);
}

// The following Doc Types utlize the io-ts 
const videoInsertRequestDefinition = {
    event_id: t.union([t.number, t.null]),
    unique_id: t.string,
    path: t.string,
    url: t.string,
    uploaded_by: t.union([t.number, t.null]),
    location: t.string,

};

const VideoInsertRequestType = t.partial(videoInsertRequestDefinition);

export interface VideoInsertRequest extends t.TypeOf<typeof VideoInsertRequestType> {
}

export class VideoInsertRequest {
    public static fromRequest = getValidator<VideoInsertRequest>(VideoInsertRequestType);
}

/**
 * *************************************** * *************************************** * ***********************
 * UploadedAsset
 * 
 * These are the assets that will be turned into videos 
 * *************************************** * *************************************** * ***********************
 */

export enum UploadedAssetStatusType  {
    initialized = 'initialized',
    uploading = 'uploading',
    uploaded = 'uploaded',
    ready = 'ready',
}

const UploadedAssetStatusTypeDefinition = t.union([
    t.literal(UploadedAssetStatusType.initialized),
    t.literal(UploadedAssetStatusType.ready),
    t.literal(UploadedAssetStatusType.uploaded),
    t.literal(UploadedAssetStatusType.uploading),
]);

export interface UploadedAssetRecord { // this should be the complete representation of the uploaded_asset table
    id: number;
    event_id: number | null; 
    unique_id: string;
    path: string;
    url: string;
    location: string;
    asset_status: UploadedAssetStatusType; 
    uploaded_time: Date;
    uploaded_by: number;
}

export interface UploadedAssetRecordWithSignedUrl extends UploadedAssetRecord {
    signedUrl?: string;
}

export interface VideoMetadata { // This (except the id) is the data that the user interface can manipulate/change
    id: number;
    event_id: number | null;
    label: string;
    url: string;
}

export interface VideoRecord extends VideoMetadata { // this should be the complete representation of the video table 
    updated_time: Date | null;
    updated_by: number | null;
    hidden_time: Date | null;
    hidden_by: number | null;
}

const uploadedAssetUpdateRequestDefinition = {
    url: t.string,
    asset_status: UploadedAssetStatusTypeDefinition,
};

const UploadedAssetUpdateRequestType = t.partial(uploadedAssetUpdateRequestDefinition);

export interface UploadedAssetUpdateRequest extends t.TypeOf<typeof UploadedAssetUpdateRequestType> {
}

export class UploadedAssetUpdateRequest {
    public static fromRequest = getValidator<UploadedAssetUpdateRequest>(UploadedAssetUpdateRequestType);
}

const uploadedAssetInsertRequestDefinition = {
    event_id: t.number,
    unique_id: t.string,
    path: t.string,
    url: t.string,
    location: t.string,
    uploaded_by: t.number,
};

const UploadedAssetInsertRequestType = t.type(uploadedAssetInsertRequestDefinition);

export interface UploadedAssetInsertRequest extends t.TypeOf<typeof UploadedAssetInsertRequestType> {
}

export class UploadedAssetInsertRequest {
    public static fromRequest = getValidator<UploadedAssetInsertRequest>(UploadedAssetInsertRequestType);
}

export interface FileAsset {
    name: string; 
    type: string; 
    uniqueIdHash: string; 
    suffix: string; 
}
