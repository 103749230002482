import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import PageviewIcon from '@mui/icons-material/Pageview';

import { GatherCaseUX } from '../../../../shared/types';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    activeCase: GatherCaseUX;
}

const FindUnclaimedProperty = (props: Props) => {
    const { activeCase } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper
            heading="Find Unclaimed Property"
            icon={<PageviewIcon color="primary" />}
        >
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    Each state maintains a separate database of unclaimed property. Simply click the button
                    below and select each state where {activeCase.fname} may have unclaimed property. Each
                    state website will walk you through a few simple steps to complete your search.
                </Typography>
            </Grid>
            <Grid>
                <a
                    href="https://www.unclaimed.org/"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,
                            classes.marginTop30
                        )}
                    >
                        Click to Search By State <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default FindUnclaimedProperty;
