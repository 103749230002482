import { getFuneralHomeId } from "../../../shared/types";
import { useGSelector } from "../../../types";

export function useFuneralHomeId(): number | null {
    const funeralHomeId: number | null = useGSelector(({ funeralHomeState, casesState }) => getFuneralHomeId(
        funeralHomeState.activeFuneralHome,
        casesState.selectedCase,
        casesState.publicCase,
    ));
    return funeralHomeId;
};
