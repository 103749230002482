import { Component } from 'react';

import Showroom from '../../../family/goodsAndServices/showroom/Showroom';
import { loadProductsForFuneralHome } from '../../../../actions/product/FHProduct.action';
import { filterProductsByTabType, getProductCategoriesForTabType } from '../../../../services/goodsandservices.service';
import { generateProductItems } from '../../../family/goodsAndServices';
import { loadPublicGPL } from '../../../../actions/FuneralHome.action';
import { ProductCategory, GPLContext, UserRoles } from '../../../../shared/types';
import { StoreState } from '../../../../types';
import Unauthorized from '../../../common/Unauthorized';
import PublicGPLAppBar from './PublicGPLAppBar';
import ProductDetailDialog from '../../../family/goodsAndServices/dialogs/ProductDetail.dialog';
import { openPhotoSwipeDialog } from '../../../../actions/PhotoSwipe.action';
import PackageDialog from '../../../family/goodsAndServices/dialogs/Package.dialog';
import withState from '../../../common/utilHOC/WithState';
import { AppDispatch } from '../../../../store';
import { RouteBuilder } from '../../../../services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var Intercom: any;

function mapStateToProps({
    productState,
    productSupplierState,
    funeralHomeState,
    userSession
}: StoreState) {
    const { publicFuneralHome } = funeralHomeState;

    const products = productState.funeralHomeProducts.filter((p) => p.show_on_website);
    const allPackages = productState.packages.filter((pkg) => pkg.show_on_website);
    return {
        products,
        allPackages,
        funeralHomeSuppliers: productSupplierState.funeralHomeSuppliers,
        isLoading: productState.isFHProductsLoading,
        funeralHomeId: publicFuneralHome && publicFuneralHome.id,
        fhWebsiteUrl: publicFuneralHome && publicFuneralHome.website_url,
        isFHUser: UserRoles.isFHUser(userSession.userData)
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    funeralHomeKey: string;
    productCategory: ProductCategory;
    dispatch: AppDispatch;
}

interface State {
    fhPopperAnchorEl: HTMLElement | null;
    isEnabled: boolean | null;
    isProductDetailDialogOpen: boolean;
    isPackageDialogOpen: boolean;
    selectedProductId: number | null;
    selectedPackageId: number | null;
}

function noop() {
    // no-op 
}

class PublicGPLPage extends Component<Props, State> {

    state: State = {
        fhPopperAnchorEl: null,
        isEnabled: null,
        isProductDetailDialogOpen: false,
        isPackageDialogOpen: false,
        selectedProductId: null,
        selectedPackageId: null
    };

    async componentDidMount() {
        const { dispatch, funeralHomeKey, isFHUser } = this.props;

        // This is just temp for testing
        const response = await dispatch(loadPublicGPL(funeralHomeKey));
        this.loadProductsForSelectedCategory();
        this.setState({ isEnabled: !!response });

        if (!isFHUser) {
            Intercom('update', { 'hide_default_launcher': true });
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { productCategory: prevCategory } = prevProps;
        const { productCategory } = this.props;

        const { isEnabled } = this.state;

        if (productCategory !== prevCategory) {
            if (!isEnabled) {
                return;
            }
            this.loadProductsForSelectedCategory();
        }
    }

    componentWillUnmount(): void {
        if (!this.props.isFHUser) {
            Intercom('update', { 'hide_default_launcher': false });
        }
    }

    loadProductsForSelectedCategory = () => {
        const { funeralHomeId, productCategory, dispatch } = this.props;

        const categories = getProductCategoriesForTabType(productCategory);
        if (categories && funeralHomeId) {
            dispatch(loadProductsForFuneralHome(funeralHomeId, categories, true));
        }
    };

    renderProductDetailsDialog = () => {
        const { products } = this.props;
        const { isProductDetailDialogOpen, selectedProductId } = this.state;

        const selectedProduct = selectedProductId
            && products.find((p) => p.id === selectedProductId)
            || undefined;

        return (
            <ProductDetailDialog
                context={GPLContext.Public}
                product={selectedProduct}
                closeDialog={this.toggleProductDetailDialogOpen}
                isDialogOpen={isProductDetailDialogOpen}
                onAddContractItem={noop}
                onUpdateQuantity={noop}
                onRemoveContractItem={noop}
                canUserEditContract={false}
                caseAssignee=""
                caseName="Jimmy"
                isGOMOrFHUser={false}
                openPhotoSwipeDialog={this.openPhotoSwipeDialog}
                zIndex={1320}
            />
        );
    };

    renderPackageDialog = () => {
        const { allPackages } = this.props;
        const { selectedPackageId, isPackageDialogOpen } = this.state;

        const selectedPackage = selectedPackageId &&
            allPackages.find((pkg) => pkg.id === selectedPackageId) || null;

        return (
            <PackageDialog
                context={GPLContext.Public}
                productPackage={selectedPackage}
                isSelected={false}
                isDialogOpen={isPackageDialogOpen}
                closeDialog={this.togglePackageDialogOpen}
                canUserEditContract={false}
                caseAssignee=""
                openPhotoSwipeDialog={this.openPhotoSwipeDialog}
                zIndex={1320}
            />
        );
    };

    render() {
        const {
            funeralHomeKey,
            funeralHomeSuppliers,
            products,
            allPackages,
            isLoading,
            fhWebsiteUrl,
            productCategory
        } = this.props;
        const { isEnabled } = this.state;

        if (isEnabled === false) {
            return <Unauthorized />;
        }

        const filteredProducts = filterProductsByTabType(products, productCategory);
        const productItems = generateProductItems(filteredProducts, [], [], []);

        return (
            <div>
                <PublicGPLAppBar
                    zIndex={1310}
                    fhWebsiteURL={fhWebsiteUrl}
                />

                <script src="/static/js/iframe-resizer.js" />

                <Showroom
                    context={GPLContext.Public}
                    selectedTabKey={productCategory}
                    isLoading={isLoading}
                    generateLink={category => RouteBuilder.PublicGPL({ funeralHomeKey, category })}
                    isShowPrices={false}
                    canUserEditContract={false}
                    caseFirstName="Jimmy"
                    funeralHomeSuppliers={funeralHomeSuppliers}
                    productItems={productItems}
                    visibleProductItems={productItems}
                    availablePackages={allPackages}
                    contractPackageIds={{}}
                    handleChangeSelectedTabKey={noop}
                    handleAddPackage={noop}
                    handleRemoveContractItem={noop}
                    handleRemovePackage={noop}
                    openAddItemDialog={noop}
                    onPackageClick={this.togglePackageDialogOpen}
                    onUpdateContractItemQuantity={noop}
                    onAddContractItem={noop}
                    onProductItemClick={this.toggleProductDetailDialogOpen}
                />

                {this.renderProductDetailsDialog()}
                {this.renderPackageDialog()}
            </div>
        );
    }

    openPhotoSwipeDialog = (activeImage: string | null, photos: string[]) => {
        this.props.dispatch(openPhotoSwipeDialog('PRODUCT_OR_PACKAGE', activeImage, photos));
    };

    toggleProductDetailDialogOpen = (productId?: number) => {
        this.setState(prevState => ({
            isProductDetailDialogOpen: !prevState.isProductDetailDialogOpen,
            selectedProductId: productId || null,
        }));
    };

    togglePackageDialogOpen = (packageId?: number) => {
        this.setState(prevState => ({
            isPackageDialogOpen: !prevState.isPackageDialogOpen,
            selectedPackageId: packageId || null
        }));
    };
}

export default withState(mapStateToProps)(PublicGPLPage);
