import * as React from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { AvatarUser } from '../../shared/types';
import UserAvatar from './UserAvatar';
import { DARK_GRAY_COLOR } from '../../constants/colorVariables';
import { GStyles } from '../../styles/GStyles';
import { getIntercomTargetProp } from '../../services';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    avatarContainer: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'max-content',
    },
    avatarWrapper: {
        position: 'relative',
        '&:not(:first-of-type)': {
            marginLeft: -8,
            mask: 'radial-gradient(circle 26px at -16px 50%,transparent 99%,#fff 100%)',
            '-webkit-mask': 'radial-gradient(circle 26px at -16px 50%,transparent 99%,#fff 100%)',
        }
    },
    userAvatar: {
        width: 48,
        height: 48,
        fontSize: 20,
        display: 'flex',
    },
    inviteButton: {
        width: 48,
        height: 48,
        margin: '8px 8px 5px 6px',
        boxShadow: 'none',
        '@media (min-width: 360px)': {
            margin: '8px auto 5px 6px',
        },
        '&:active': {
            boxShadow: theme.shadows[8],
        },
        '& svg': {
            color: '#fff',
            fontSize: 30
        }
    },
    tripleDotAvatar: {
        width: 46,
        height: 46,
        marginLeft: '-12px',
        display: 'flex',
        alignItems: 'inherit',
        backgroundColor: DARK_GRAY_COLOR,
        '&:hover': {
            cursor: 'pointer',
        },
        '& svg': {
            fontSize: 32
        }
    },
});

type Classes = 'root' | 'avatarContainer' | 'userAvatar' | 'inviteButton'
    | 'tripleDotAvatar' | 'avatarWrapper';
type StyledProps = WithStyles<Classes>;

interface AvatarClasses {
    lastAvatar: string;
    avatar: string;
    inviteButton: string;
    avatarWrapper: string;
}

interface InviteButtonProps {
    variant: 'outlined' | 'contained' | 'fab';
    buttonPosition: 'start' | 'end';
    content?: JSX.Element;
    tooltip?: string;
    tooltipEnterDelay?: number;
    hideButton?: boolean;
    disabled?: boolean;
}

interface ViewAllUsersButtonProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    tooltipText?: string;
}

export interface OverlappedAvatar extends AvatarUser {
    entity_id: number;
    user_id: number | null;
    is_pending_decision?: boolean;
}

interface Props {
    users: OverlappedAvatar[];
    visibleUsersCount?: number;
    onInviteButtonClick: () => void;
    inviteButtonProps?: InviteButtonProps;
    rootClass?: string;
    avatarContainerClass?: string;
    viewAllUsersButtonProps?: ViewAllUsersButtonProps;
    onAvatarClick?: (event: React.MouseEvent<HTMLElement>, user: OverlappedAvatar) => void;
    showAllUsers?: boolean;
    avatarClasses?: Partial<AvatarClasses>;
    showUsersCount?: boolean;
    pendingTooltip?: string;
    pendingClass?: string;
}

const getAvatarClasses = (props?: Partial<AvatarClasses>): AvatarClasses => {
    const classes: AvatarClasses = {
        lastAvatar: '',
        avatar: '',
        inviteButton: '',
        avatarWrapper: ''
    };

    if (props) {
        return { ...classes, ...props };
    }

    return classes;
};

const getViewAllUsersButtonProps = (
    props?: Partial<ViewAllUsersButtonProps>
): ViewAllUsersButtonProps => {
    const initProps: ViewAllUsersButtonProps = {
        onClick: undefined,
        tooltipText: undefined
    };

    if (props) {
        return { ...initProps, ...props };
    }

    return initProps;
};

const OverlappedAvatars = ({
    classes,
    avatarClasses,
    showUsersCount,
    users,
    inviteButtonProps,
    showAllUsers,
    rootClass,
    avatarContainerClass,
    viewAllUsersButtonProps,
    visibleUsersCount,
    pendingClass,
    pendingTooltip,
    onAvatarClick,
    onInviteButtonClick,
}: Props & StyledProps) => {
    // users
    const usersCount = users.length;
    const firstSixUsers = users.slice(0, visibleUsersCount || 6);
    const remainingUsersCount = usersCount - firstSixUsers.length;

    // destructed props
    const {
        lastAvatar: lastAvatarClass,
        avatar: avatarClass,
        inviteButton: inviteButtonClass,
        avatarWrapper: avatarWrapperClass
    } = getAvatarClasses(avatarClasses);

    const {
        onClick: onViewAllUsersButtonClick,
        tooltipText: viewAllUsersTooltipText
    } = getViewAllUsersButtonProps(viewAllUsersButtonProps);

    const renderInviteButton = () => {
        const {
            variant,
            tooltip,
            tooltipEnterDelay,
            content,
            hideButton,
            disabled
        } = inviteButtonProps || {};

        if (hideButton) {
            return null;
        }

        const buttonProps = {
            'aria-label': 'add-invitee',
            className: classNames(classes.inviteButton, inviteButtonClass),
            onClick: (e: React.MouseEvent<HTMLElement>) => onInviteButtonClick(),
            disabled
        };
        const children = content || <PersonAddIcon />;
        let button = (
            <Fab
                {...buttonProps}
                color="primary"
            >
                {children}
            </Fab>

        );

        if (variant !== 'fab') {
            button = (
                <Button
                    {...buttonProps}
                    color="primary"
                    variant={variant}
                >
                    {children}
                </Button>
            );
        }

        return (
            <Tooltip
                title={tooltip !== undefined ? tooltip : 'Click to invite'}
                enterDelay={tooltipEnterDelay || undefined}
                placement="top"
            >
                <div {...getIntercomTargetProp(`RememberPage-GuestList-AddNameToGuestListButton`)}>{button}</div>
            </Tooltip>
        );
    };

    return (
        <div className={classNames(classes.root, rootClass)}>
            <div className={classNames(classes.avatarContainer, avatarContainerClass)}>
                {inviteButtonProps?.buttonPosition === 'start'
                    && renderInviteButton()
                }
                {(showAllUsers ? users : firstSixUsers).map((user) => {
                    const { is_pending_decision } = user;

                    return (
                        <Tooltip
                            placement="top"
                            title={is_pending_decision && pendingTooltip || ''}
                            key={user.entity_id}
                        >
                            <div
                                key={user.entity_id}
                                className={classNames(
                                    classes.userAvatar,
                                    classes.avatarWrapper,
                                    GStyles.pointerEventsNone,
                                    avatarClass,
                                    avatarWrapperClass,
                                    is_pending_decision && pendingClass
                                )}
                                onClick={e => onAvatarClick && onAvatarClick(e, user)}
                                itemID={user.entity_id.toString()}
                            >
                                <UserAvatar
                                    _itemid={user.entity_id.toString()}
                                    key={user.entity_id}
                                    user={user}
                                    size={64}
                                    capitalizeInitials
                                    className={classNames(
                                        classes.userAvatar,
                                        avatarClass,
                                        is_pending_decision && pendingClass
                                    )}
                                />
                            </div>
                        </Tooltip>
                    );
                })}

                {(showUsersCount
                    ? remainingUsersCount > 0
                    : usersCount !== 0
                ) && !showAllUsers &&
                    <Tooltip
                        title={viewAllUsersTooltipText !== undefined
                            ? viewAllUsersTooltipText
                            : 'Click to view all helpers'}
                        placement="top"
                    >
                        <Avatar
                            className={classNames(
                                classes.tripleDotAvatar,
                                classes.userAvatar,
                                classes.avatarWrapper,
                                lastAvatarClass
                            )}
                            onClick={onViewAllUsersButtonClick}
                        >
                            {showUsersCount
                                && <>&nbsp;+{remainingUsersCount}</>
                                || <MoreHorizIcon />
                            }
                        </Avatar>
                    </Tooltip>
                }
            </div>

            {inviteButtonProps?.buttonPosition === 'end'
                && renderInviteButton()
            }
        </div>
    );
};

export default withStyles(styles)(OverlappedAvatars);