import classNames from 'classnames';
import { every, includes, isEmpty, map, omit, orderBy, partition, some, uniqBy } from 'lodash';
import * as React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import FilterListIcon from '@mui/icons-material/FilterList';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { ORANGE_COLOR, RED_COLOR, SUCCESS_COLOR } from '../../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../../services';
import {
    calculateAllowanceCredit, calculateAndDisplayTotalForItems,
    calculateProductListPrice,
    formatDinero
} from '../../../../shared/goods_and_services/pricing';
import { isAllowanceCredit, isAllowanceItem } from '../../../../shared/goods_and_services/utils';
import {
    GPLContext, ProductCategory,
    ProductCategoryDisplayLookup,
    ProductContractItemUX,
    ProductItem,
    ProductPackageItemUX,
    ProductPackageUX,
    ProductSubPackageItem,
    ProductSummary,
    ProductSupplierUXWithCategory,
    ProductUX,
    ProductView
} from '../../../../shared/types';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import AddProductButtons from '../../../funeralHomes/goodsAndServices/AddProductButtons';
import Packages from '../professionalServices/Packages';
import { AllowanceSummary } from '../widgets/AllowancePopper';
import PriceWidget from '../widgets/PriceWidget';
import ProductItemCard from '../widgets/ProductItemCard';
import ProductItemList, { ReactVirtualizedLayout } from '../widgets/ProductItemList';
import ProductItemsDataGrid from '../widgets/ProductItemsDataGrid';
import SupplierImageList from '../widgets/SupplierImageList';
import AddPhotosToProductsText from './AddPhotosToProductsText';
import FeaturedProducts from './FeaturedProducts';
import FilterResults, { ActiveFilters, Filter, FilterCounts, SortType, SortTypeEnum } from './FilterResults';
import FilterResultsPopper from './FilterResultsPopper';
import PackageOptionProducts from './PackageOptionProducts';
import ScrollToTop from './ScrollToTop';
import ProductViewButtons from './ProductViewButtons';

interface Props {
    canUserEditContract: boolean;
    caseName: string;
    allProductItems: ProductItem[];
    visibleProductItems: ProductItem[];
    packagesForCategory: ProductPackageUX[];
    contractPackageIds?: { [id: number]: number };
    featured: ProductItem[];
    filters: Filter[];
    isShowPrices: boolean;
    context: GPLContext;
    productView?: ProductView;
    setProductView?: React.Dispatch<React.SetStateAction<ProductView>>;
    category: ProductCategory;
    funeralHomeSuppliers: ProductSupplierUXWithCategory[];
    productSummary: ProductSummary[];
    onAddAdditionalItem?: () => void;
    onProductItemClick: (productId?: number, contractItemId?: string, packageItemId?: number) => void;
    onPackageClick?: (packageId: number) => void;
    onAddContractItem?: (product: ProductUX, quantity: number, packageItemId?: number, allowanceItemId?: string)
        => void;
    onUpdateContractItemQuantity?: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    onAddPackage?: (packageId: number) => void;
    onRemovePackage?: (packageId: number) => void;
    onEditClick?: (productId: number, category?: ProductCategory) => void;
    handleCreateNewProductClick?: () => void;
}

interface State {
    popperAnchorEle: HTMLElement | null;
    eventTargetEle: EventTarget | null;
    sortType: SortType;
    activeFilters: ActiveFilters;
    searchText: string;
    youtubeVideoCode: string;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    ulHeader: {
        height: '100%',
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden !important' as 'hidden',
        margin: '24px auto !important',
        '& li': {
            margin: 'auto 0px !important',
            overflowY: 'unset !important',
        }
    },
    groupButton: {
        boxShadow: 'none',
        minWidth: 140,
        padding: '6px 0',
        borderRadius: 0,
        minHeight: 33,
        height: 33
    },
    selectedProduct: {
        color: theme.palette.primary.main,
        border: '1px dashed',
        width: 142,
        height: 88,
        borderRadius: '8px',
        margin: '28px 18px 18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedProductContent: {
        width: 108,
        display: 'flex',
        fontWeight: 200,
        fontSize: 14,
        margin: 'auto',
        textAlign: 'center',
    },
    filterButton: {
        margin: '8px auto',
        display: 'flex',
        '@media (min-width: 1356px)': {
            display: 'none'
        }
    },
    filterResultCont: {
        display: 'none',
        float: 'left',
        position: 'relative',
        margin: 18,
        borderRadius: 8,
        border: `1px solid ${theme.palette.primary.main}`,
        width: 300,
        '@media (min-width: 1356px)': {
            display: 'block'
        }
    },
    filterHeader: {
        width: '100%',
        borderRadius: '5px 5px 0px 0px',
        background: theme.palette.primary.main,
        padding: 8
    },
    fiterHeaderTitle: {
        textAlign: 'center',
        color: theme.palette.common.white
    },
    productContainer: {
        width: '100%',
        '@media (min-width: 1030px)': {
            maxWidth: '1030px',
        },
        '@media (min-width: 1356px)': {
            maxWidth: '1358px',
        }
    },
    productSummary: {
        color: theme.palette.primary.main,
        width: 142,
        height: 88,
        margin: '28px 18px 18px'
    },
    productCount: {
        borderBottom: '1px solid',
        margin: 'auto',
        paddingBottom: 8,
        paddingTop: 2,
        width: 108,
        fontSize: 30,
        fontWeight: 200,
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontSize: 16,
            fontWeight: 200
        }
    },
    paddingTop8: {
        paddingTop: 8
    },
    hidePrice: {
        paddingTop: 22,
        border: 0
    },
    backOfficeFilterResultCont: {
        border: `1px solid ${ORANGE_COLOR}`,
    },
    backOfficeFilterHeader: {
        background: `${ORANGE_COLOR}`,
    },
    allowanceContainer: {
        height: 108,
        margin: '18px 12px 12px 0px',
        display: 'flex',
        borderRadius: '0 8px 8px 0',
        '& li': {
            marginTop: '-8px !important',
        }
    },
    allowanceInitial: {
        color: theme.palette.primary.main,
        width: 168,
        height: 108,
        fontSize: 14,
        fontWeight: 200,
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        margin: '0 32px 0 22px',
    },
    allowanceProgressContainer: {
        display: 'flex',
        width: 100,
        height: 108,
        borderRadius: '50% 0 0 50%',
        margin: '18px auto',
    },
    progressWrapper: {
        position: 'relative',
        height: 88,
        width: 88,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        margin: 10,
        '& $fabProgress': {
            zIndex: 1,
        },
        '& $progressText': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            lineHeight: '1em',
            zIndex: 2,
            width: 76,
            height: 76,
            background: theme.palette.common.white,
            borderRadius: '50%',
            position: 'absolute',
            color: theme.palette.primary.main,
            '& span': {
                '&:first-of-type': {
                    fontSize: 24,
                    lineHeight: '1em',
                    marginTop: 4,
                },
                '&:last-child': {
                    fontSize: 11,
                    fontWeight: 200,
                }
            }
        },
    },
    priceWidgetSign: {
        fontSize: '10px !important',
    },
    verticalDivider: {
        height: 140,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        margin: '0px 18px',
    },
    footer: {
        justifyContent: 'center',
        margin: '0 12px',
        marginTop: 20,
        flex: '0 0 auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    },
    addPhotosText: {
        width: 'fit-content',
        padding: '6px 12px',
        borderRadius: 8,
        textAlign: 'center',
        border: `2px solid ${RED_COLOR}`,
        background: convertHexToRGBA(RED_COLOR, 0.18),
        '& p': {
            color: RED_COLOR,
        },
    },
    greenBox: {
        border: `2px solid ${SUCCESS_COLOR}`,
        background: convertHexToRGBA(SUCCESS_COLOR, 0.18),
        '& p': {
            color: SUCCESS_COLOR,
        },
    },
    activebtnView: {
        background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        color: theme.palette.primary.main,
        '&:hover': {
            background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        }
    },
    summary: {
        maxWidth: '100%',
        width: 'fit-content',
        margin: 'auto'
    },
    addProductButtonsContainer: {
        width: '100%',
        marginTop: 30,
        display: 'flex',
        rowGap: 30,
        margin: 'auto',
        padding: '0px 12px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '@media (min-width: 960px)': {
            justifyContent: 'space-between',
        }
    },
    progressText: {},
    fabProgress: {},
});

type StyledProps = Props & WithGStyles<'root' | 'groupButton' | 'selectedProduct' | 'selectedProductContent'
    | 'filterButton' | 'filterResultCont' | 'filterHeader' | 'fiterHeaderTitle' | 'productContainer'
    | 'productSummary' | 'productCount' | 'paddingTop8' | 'hidePrice' | 'ulHeader' | 'progressWrapper'
    | 'progressText' | 'fabProgress' | 'allowanceContainer' | 'allowanceInitial' | 'priceWidgetSign' | 'addPhotosText'
    | 'allowanceProgressContainer' | 'greenBox' | 'backOfficeFilterResultCont' | 'backOfficeFilterHeader'
    | 'verticalDivider' | 'footer' | 'summary' | 'activebtnView' | 'addProductButtonsContainer'>;

class Products extends React.Component<StyledProps, State> {
    state: State = {
        popperAnchorEle: null,
        eventTargetEle: null,
        sortType: SortTypeEnum.desc,
        activeFilters: {},
        searchText: '',
        youtubeVideoCode: '',
    };

    openFilterResultPopper = (event: React.MouseEvent<HTMLElement>) => {
        const { popperAnchorEle } = this.state;
        if (popperAnchorEle) {
            this.closeFilterResultPopper();
            return;
        }

        this.setState({
            popperAnchorEle: event.currentTarget,
            eventTargetEle: event.target
        });
    };

    clickAwayListener = (event: MouseEvent | TouchEvent) => {
        const { eventTargetEle } = this.state;
        const currentTarget = event.target;

        if (currentTarget !== eventTargetEle) {
            this.closeFilterResultPopper();
        }
    };

    closeFilterResultPopper = () => {
        this.setState({
            popperAnchorEle: null,
            eventTargetEle: null
        });
    };

    filterBySearch = (productItems: ProductItem[]) => {
        const { searchText } = this.state;

        if (!searchText) {
            return productItems;
        }
        const search = searchText.toLowerCase();
        return productItems.filter(({ product, contractItem }) => {
            const productName = product && product.name.toLowerCase() || '';
            const itemName = contractItem && contractItem.name.toLowerCase() || '';
            return productName && (productName.includes(search) || search.includes(productName)) ||
                itemName && (itemName.includes(search) || search.includes(itemName));
        });
    };

    applyActiveFilters = (productItems: ProductItem[]) => {
        const { activeFilters } = this.state;

        if (isEmpty(activeFilters)) {
            return productItems;
        }

        // each product must have one of the options of each active tag filter (skip Manufacturer filter)
        let filtered = productItems.filter(({ product }) => product && every(activeFilters, (options, name) =>
            includes(options, product.tags[name]) || name === 'Manufacturer'
        ));
        // filter out manufacturer
        if (activeFilters.Manufacturer) {
            filtered = filtered.filter(({ product }) => product &&
                includes(activeFilters.Manufacturer, product.manufacturer_name));
        }

        return filtered;
    };

    applyFilters = (productItems: ProductItem[]) => {
        const { sortType } = this.state;

        const searchFiltered = this.filterBySearch(productItems);
        const filtered = this.applyActiveFilters(searchFiltered);
        const orderFunction = ({ product, contractItem }: ProductItem): number => {
            if (product && product.base_price !== null) {
                return product.base_price;
            } else if (product && product.var_price !== null) {
                return product.var_price;
            } else if (contractItem && contractItem.list_price !== null) {
                return contractItem.list_price;
            } else {
                return -1;
            }
        };

        const sortedAndFiltered = orderBy(filtered, orderFunction, sortType);
        return sortedAndFiltered;
    };

    calculateFilterCounts = (productItems: ProductItem[], filters: Filter[]) => {
        const counts: FilterCounts = {};

        const filterIncludes = (filter: Filter, option: string) => {
            if (includes(filter.options, option)) {
                if (counts[filter.name][option] === undefined) {
                    counts[filter.name][option] = 0;
                }
                counts[filter.name][option] += 1;
            }
        };

        productItems.forEach(({ product }) => {
            filters.forEach((filter) => {
                if (counts[filter.name] === undefined) {
                    counts[filter.name] = {};
                }
                if (filter.name === 'Manufacturer' && product && product.manufacturer_name) {
                    filterIncludes(filter, product.manufacturer_name);
                } else if (product) {
                    filterIncludes(filter, product.tags[filter.name]);
                }
            });
        });

        return counts;
    };

    renderAllowances = (allowances: AllowanceSummary[], itemsUsingAnAllowance: ProductItem[]) => {
        const {
            classes,
            caseName,
            onUpdateContractItemQuantity,
            onRemoveContractItem,
            isShowPrices,
            canUserEditContract,
            context,
        } = this.props;

        return allowances.map((allowance) => {

            const itemsUsingAllowance = itemsUsingAnAllowance.filter((item) =>
                item.contractItem && item.contractItem.allowance_item === allowance.id &&
                !isAllowanceCredit(item.contractItem));

            return (
                <React.Fragment key={allowance.id}>
                    <div className={classNames(classes.allowanceProgressContainer, classes.background_primary_0_1_8)}>
                        <Zoom in timeout={1200}>
                            <Tooltip
                                title={allowance.name}
                                enterDelay={1200}
                                placement="top"
                                classes={{
                                    tooltip: classes.fontSize12
                                }}
                            >
                                <div className={classes.progressWrapper}>
                                    <div className={classes.progressText}>
                                        <PriceWidget
                                            priceStr={formatDinero(allowance.remaining, '$0,0')}
                                            color={'primary'}
                                            size={'xsmall'}
                                            customClasses={{
                                                price: '',
                                                cents: classes.priceWidgetSign
                                            }}
                                        />
                                        <span>Remaining</span>
                                    </div>
                                    <CircularProgress
                                        color="primary"
                                        variant="determinate"
                                        size={86}
                                        thickness={3}
                                        className={classes.fabProgress}
                                        value={allowance.percentRemaining < 2 ? 2 : allowance.percentRemaining}
                                    />
                                </div>
                            </Tooltip>
                        </Zoom>
                    </div>
                    <div className={classNames(classes.allowanceContainer, classes.background_primary_0_1_8)}>
                        {itemsUsingAllowance.length > 0 ?
                            itemsUsingAllowance.map((productItem) =>
                                <ProductItemCard
                                    canUserEditContract={canUserEditContract}
                                    key={productItem.key}
                                    productItem={productItem}
                                    caseFirstName={caseName}
                                    onClick={() => this.handleProductItemClick(productItem)}
                                    onAddContractItem={this.handleAddContractItemWithAllowance}
                                    onUpdateQuantity={onUpdateContractItemQuantity}
                                    onRemoveContractItem={onRemoveContractItem}
                                    size={'xsmall'}
                                    isSelectedProduct
                                    isShowPrices={isShowPrices}
                                    context={context}
                                />
                            ) :
                            <Zoom in timeout={1200}>
                                <span>
                                    <div className={classes.allowanceInitial}>
                                        Your {allowance.name} selections will display here
                                    </div>
                                </span>
                            </Zoom>
                        }
                    </div>
                </React.Fragment>
            );
        });
    };

    renderFilterContent = (filterCounts: FilterCounts) => {
        const { classes, filters, context } = this.props;
        const { sortType, activeFilters, searchText } = this.state;

        const isBackOffice = context === GPLContext.BackOffice;

        return (
            <>
                <Grid
                    container
                    className={classNames(
                        classes.filterResultCont,
                        isBackOffice && classes.backOfficeFilterResultCont
                    )}
                >
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.filterHeader,
                            isBackOffice && classes.backOfficeFilterHeader
                        )}
                    >
                        <Typography className={classes.fiterHeaderTitle} noWrap>
                            <FilterListIcon className={classes.verticalAlignMiddle} /> Filter Results
                        </Typography>
                    </Grid>
                    <FilterResults
                        sortType={sortType}
                        filters={filters}
                        activeFilters={activeFilters}
                        filterCounts={filterCounts}
                        searchText={searchText}
                        onSortTypeChange={this.handleSortTypeChange}
                        onFilterClick={this.handleTagCheckboxToggle}
                        onSearchChange={this.handleSearchTextChange}
                        haveActiveFilters={this.checkActiveFilters()}
                        resetFilters={this.resetFilters}
                    />
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.filterButton}
                    onClick={this.openFilterResultPopper}
                >
                    <FilterListIcon /> Filter Results
                </Button>
            </>
        );
    };

    renderPackageOptions = (productItemsWithPackageItem: ProductItem[]) => {
        const {
            caseName,
            onAddContractItem,
            onUpdateContractItemQuantity,
            onRemoveContractItem,
            canUserEditContract,
            context,
        } = this.props;

        // each productItem that is a package option needs to be grouped with other packageItems

        const packageItems = productItemsWithPackageItem.map((item) => item.packageItem)
            .filter((pi): pi is ProductPackageItemUX | ProductSubPackageItem => pi !== undefined);
        const uniquePackageItems = uniqBy(packageItems, (item) => item.id);
        return map(uniquePackageItems, (packageItem) => {
            const productItems = productItemsWithPackageItem.filter((pi) =>
                pi.packageItem && pi.packageItem.id === packageItem.id);
            return (
                <PackageOptionProducts
                    canUserEditContract={canUserEditContract}
                    key={packageItem.id}
                    packageItem={packageItem}
                    productItems={productItems}
                    caseFirstName={caseName}
                    context={context}
                    onProductItemClick={this.handleProductItemClick}
                    onAddContractItem={onAddContractItem}
                    onUpdateContractItemQuantity={onUpdateContractItemQuantity}
                    onRemoveContractItem={onRemoveContractItem}
                />
            );
        });
    };

    render() {
        const {
            classes,
            caseName,
            allProductItems,
            visibleProductItems,
            featured,
            packagesForCategory,
            contractPackageIds,
            filters,
            isShowPrices,
            onUpdateContractItemQuantity,
            onRemoveContractItem,
            canUserEditContract,
            onPackageClick,
            onAddPackage,
            onRemovePackage,
            onAddAdditionalItem,
            context,
            onEditClick,
            category,
            funeralHomeSuppliers,
            handleCreateNewProductClick,
            productView,
            setProductView,
        } = this.props;
        const { popperAnchorEle, sortType, activeFilters, searchText } = this.state;

        const filteredProductItems = productView === ProductView.table_view
            ? visibleProductItems : this.applyFilters(visibleProductItems);
        const filterCounts = this.calculateFilterCounts(filteredProductItems, filters);

        const productItemsWithPackageItem: ProductItem[] = [];
        const selectedProductItems: ProductItem[] = [];
        const selectedNonAllowanceProductItems: ProductItem[] = [];
        const selectedContractItems: ProductContractItemUX[] = [];
        const allowanceItems: ProductContractItemUX[] = [];

        allProductItems.forEach((productItem) => {
            if (productItem.packageItem) {
                productItemsWithPackageItem.push(productItem);
            } else if (productItem.contractItem) {
                selectedProductItems.push(productItem);
                selectedContractItems.push(productItem.contractItem);

                if (isAllowanceItem(productItem.contractItem)) {
                    allowanceItems.push(productItem.contractItem);
                } else {
                    selectedNonAllowanceProductItems.push(productItem);
                }
            }
        });

        const hasContractPackageOptions = productItemsWithPackageItem.length > 0;
        const hasPackages = packagesForCategory.length > 0;

        const [itemsUsingAnAllowance, itemsNotUsingAllowance] = partition(selectedNonAllowanceProductItems, (item) =>
            item.contractItem && item.contractItem.allowance_item &&
            some(allowanceItems, (ai) => item.contractItem && ai.id === item.contractItem.allowance_item));
        const allowances: AllowanceSummary[] = allowanceItems.reduce(
            (summaries, allowanceItem) => {

                if (!allowanceItem.product) {
                    return summaries;
                }

                const allowanceMax = calculateProductListPrice(allowanceItem.product, 1);
                if (!allowanceMax) {
                    return summaries;
                }

                const creditAmount = calculateAllowanceCredit(allowanceItem, selectedContractItems);
                if (!creditAmount) {
                    return summaries;
                }

                const allowanceUnused = creditAmount.add(allowanceMax);
                const percentRemaining = 100 * (1 - allowanceUnused.getAmount() / allowanceMax.getAmount());
                const newAllowance: AllowanceSummary = {
                    id: allowanceItem.id,
                    name: allowanceItem.name,
                    remaining: allowanceUnused,
                    percentRemaining,
                };
                return [...summaries, newAllowance];
            },
            [] as AllowanceSummary[],
        );

        const categoryName = category ? ProductCategoryDisplayLookup[category] : '';
        const filteredSuppliers = funeralHomeSuppliers.filter(obj => obj.category === category);

        const isPublicGPL = context === GPLContext.Public;
        const isBackOffice = context === GPLContext.BackOffice;

        return (
            <Grid
                container justifyContent="center" alignItems="center" direction="column"
                className={classes.root}
            >
                {/* Render Selected Items */}
                {!isBackOffice &&
                    <div className={classes.summary}>
                        <ul className={classNames(classes.list, classes.ulHeader)}>
                            {filteredSuppliers.length > 0 &&
                                <>
                                    <SupplierImageList
                                        suppliers={filteredSuppliers}
                                        categoryName={categoryName}
                                    />
                                    {!isPublicGPL && <div className={classes.verticalDivider} />}
                                </>
                            }
                            {!isPublicGPL &&
                                <div className={classes.productSummary}>
                                    <div
                                        className={classNames(
                                            classes.productCount,
                                            isShowPrices ? '' : classes.hidePrice
                                        )}
                                    >
                                        <span>{selectedNonAllowanceProductItems.length}</span>&nbsp;
                                        {selectedNonAllowanceProductItems.length > 0 ?
                                            <>
                                                <span>Product</span>
                                                {selectedNonAllowanceProductItems.length > 1 && <span>s</span>}
                                            </>
                                            :
                                            <span>Selected</span>
                                        }
                                    </div>
                                    {isShowPrices &&
                                        <div className={classes.paddingTop8}>
                                            <PriceWidget
                                                priceStr={calculateAndDisplayTotalForItems(selectedContractItems)}
                                                color={'primary'}
                                                size={'medium'}
                                            />
                                        </div>
                                    }
                                </div>
                            }

                            {/* show selected products */}
                            {itemsNotUsingAllowance.length > 0 &&
                                itemsNotUsingAllowance.map((productItem) =>
                                    <ProductItemCard
                                        hasTooltip
                                        canUserEditContract={canUserEditContract}
                                        key={productItem.key}
                                        productItem={productItem}
                                        caseFirstName={caseName}
                                        onClick={() => this.handleProductItemClick(productItem)}
                                        onAddContractItem={this.handleAddContractItemWithAllowance}
                                        onUpdateQuantity={onUpdateContractItemQuantity}
                                        onRemoveContractItem={onRemoveContractItem}
                                        size={'xsmall'}
                                        isSelectedProduct
                                        isShowPrices={isShowPrices}
                                        context={context}
                                    />
                                )
                            }
                            {itemsNotUsingAllowance.length === 0 && canUserEditContract &&
                                <Zoom in timeout={1200}>
                                    <div className={classes.selectedProduct}>
                                        <span className={classes.selectedProductContent}>
                                            Your selection will display here
                                        </span>
                                    </div>
                                </Zoom>
                            }

                            {/* show allowance products */}
                            {this.renderAllowances(allowances, itemsUsingAnAllowance)}
                        </ul>
                    </div>
                }
                {hasPackages &&
                    <Packages
                        packages={packagesForCategory}
                        contractPackageIds={contractPackageIds}
                        openPackageDialog={onPackageClick}
                        isShowPrices={isShowPrices}
                        onAddPackage={onAddPackage}
                        onRemovePackage={onRemovePackage}
                        canUserEditContract={canUserEditContract}
                        context={context}
                    />
                }
                {hasContractPackageOptions && this.renderPackageOptions(productItemsWithPackageItem)}
                {!hasContractPackageOptions && !hasPackages && featured.length > 0
                    && category &&
                    <FeaturedProducts
                        canUserEditContract={canUserEditContract}
                        productItems={featured}
                        caseFirstName={caseName}
                        onProductItemClick={this.handleProductItemClick}
                        onAddContractItem={this.handleAddContractItemWithAllowance}
                        onUpdateContractItemQuantity={onUpdateContractItemQuantity}
                        onRemoveContractItem={onRemoveContractItem}
                        context={context}
                        onEditClick={onEditClick}
                        allowances={allowances}
                    />
                }

                {productView && setProductView && category &&
                    <ProductViewButtons
                        productView={productView}
                        setProductView={setProductView}
                        category={category}
                    />
                }

                {handleCreateNewProductClick &&
                    <Grid item xs={12} className={classes.addProductButtonsContainer}>
                        <AddPhotosToProductsText
                            productItems={visibleProductItems}
                        />

                        <AddProductButtons
                            category={category}
                            handleCreateNewProductClick={handleCreateNewProductClick}
                            zIndex={1320}
                        />
                    </Grid>
                }

                <FilterResultsPopper
                    popperAnchorEle={popperAnchorEle}
                    clickAwayListener={this.clickAwayListener}
                    sortType={sortType}
                    filters={filters}
                    activeFilters={activeFilters}
                    filterCounts={filterCounts}
                    searchText={searchText}
                    onSortTypeChange={this.handleSortTypeChange}
                    onFilterClick={this.handleTagCheckboxToggle}
                    onSearchChange={this.handleSearchTextChange}
                    haveActiveFilters={this.checkActiveFilters()}
                    resetFilters={this.resetFilters}
                    zIndex={1320}
                />

                {productView === ProductView.table_view ?
                    <Grid width={1}>
                        <ProductItemsDataGrid
                            productItems={filteredProductItems.map(p => p.product)
                                .filter((p): p is ProductUX => !!p)}
                            onEditClick={onEditClick}
                            zIndex={1320}
                            category={category}
                        />
                    </Grid> :
                    <div className={classes.productContainer}>
                        {this.renderFilterContent(filterCounts)}

                        <ProductItemList
                            productItems={filteredProductItems}
                            caseFirstName={caseName}
                            onProductItemClick={this.handleProductItemClick}
                            onAddContractItem={this.handleAddContractItemWithAllowance}
                            onUpdateContractItemQuantity={onUpdateContractItemQuantity}
                            onRemoveContractItem={onRemoveContractItem}
                            onAddAdditionalItemClick={onAddAdditionalItem}
                            isShowPrices={isShowPrices}
                            canUserEditContract={canUserEditContract}
                            context={context}
                            onEditClick={onEditClick}
                            allowances={allowances}
                            layout={ReactVirtualizedLayout.grid}
                        />

                        <ScrollToTop />

                    </div>
                }
            </Grid>
        );
    }

    handleSortTypeChange = (type: SortType) => this.setState({ sortType: type });

    handleSearchTextChange = (search: string) => this.setState({ searchText: search });

    handleProductItemClick = ({ product, contractItem, packageItem }: ProductItem) => {
        const { onProductItemClick } = this.props;

        onProductItemClick(product && product.id, contractItem && contractItem.id, packageItem && packageItem.id);
    };

    handleTagCheckboxToggle = (name: string, option: string) => {
        this.setState((prevState) => {
            const existingOptions = prevState.activeFilters[name] || [];
            // if current state is checked then uncheck
            const updatedOptions = includes(existingOptions, option) ?
                existingOptions.filter((opt) => opt !== option)
                : [...existingOptions, option];
            // if options are now empty -> drop the filter
            if (updatedOptions.length === 0) {
                return {
                    activeFilters: omit(prevState.activeFilters, [name]),
                };
            }
            return {
                activeFilters: {
                    ...prevState.activeFilters,
                    [name]: updatedOptions,
                },
            };
        });
    };

    handleAddContractItemWithAllowance = (
        product: ProductUX,
        quantity: number,
        allowanceItemId?: string,
    ) => {
        const { onAddContractItem } = this.props;
        if (onAddContractItem) {
            onAddContractItem(product, quantity, undefined, allowanceItemId);
        }
    };

    resetFilters = () => {
        this.setState({
            sortType: SortTypeEnum.desc,
            activeFilters: {},
            searchText: '',
        });
    };

    checkActiveFilters = (): boolean => {
        const { activeFilters, searchText, sortType } = this.state;
        return !isEmpty(activeFilters) || Boolean(searchText) || sortType === SortTypeEnum.asc;
    };
}

export default withGStyles(styles)(Products);
