import { useMemo } from 'react';

import { EntitySummary } from '../../shared/types';
import {
    BaseAssignEntitySummary,
    EntitySummaryForBaseAssign,
    injectBaseAssignEntityFields,
} from './baseAssign/BaseAssignForEntitySummary';
import AssignmentPopper, { AssignmentPopperProps } from './AssignmentPopper';
import { useGSelector } from '../../types';

interface Props extends Omit<AssignmentPopperProps<BaseAssignEntitySummary>, 'items' | 'assignedTo'> {
    items: EntitySummaryForBaseAssign[];
    assignedTo?: EntitySummary[];
}

const AssignmentPopperForEntitySummary = (props: Props) => {
    const { items, assignedTo, ...rest } = props;

    const gatherCase = useGSelector(({ casesState }) => casesState.selectedCase);

    const mappedItems: BaseAssignEntitySummary[] = useMemo(
        () => injectBaseAssignEntityFields({ entityList: items, gatherCase }),
        [items, gatherCase],
    );
    const mappedAssignedTo: BaseAssignEntitySummary[] | undefined = useMemo(
        () => (assignedTo ? injectBaseAssignEntityFields({ entityList: assignedTo, gatherCase }) : undefined),
        [assignedTo, gatherCase],
    );

    return <AssignmentPopper items={mappedItems} assignedTo={mappedAssignedTo} {...rest} />;
};

export default AssignmentPopperForEntitySummary;
