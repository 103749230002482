import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintIcon from '@mui/icons-material/Print';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Gesture from '@mui/icons-material/Gesture';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ReceiptIcon from '@mui/icons-material/Receipt';

import {
    GatherCaseUX,
    ProductCategory,
    UserRoles,
    ProductContractItemUX,
    ProductContractRequest,
    ProductContractUX,
    DocPacketContractSignerRecord,
    DocPacketSignerUX,
    FeatureKey,
    getDisplayTitleForFHUser,
} from '../../../../shared/types';

import {
    getFullNameFromCase,
    lifeSpan,
    isTouchDevice,
    getDecedentAge,
    RouteBuilder,
    FuneralHomeRoutePage,
    FamilyRoutePage,
    submitIntercomEvent,
    IntercomTrackEvent,
} from '../../../../services';

import { StyledProps, styleWrapper } from './styles';
import { ContractStatusContainerStyledProps, contractStatusContainerStyles } from '../contractPopover/styles';
import { PaymentCard } from './widgets';
import {
    calculateTotalForItems,
    displayTaxRate,
    calculateContractTotal,
    getContractItemListPrice,
    getContractItemPriceAdjustment,
    getDinero,
    getContractSubTotal,
    getContractTaxTotal,
    formatPrice
} from '../../../../shared/goods_and_services/pricing';
import {
    isContractDiscount,
    getLatestItems,
} from '../../../../shared/goods_and_services/utils';

import PriceAdjustmentDialog from '../dialogs/PriceAdjustment.dialog';
import DisclaimerReasonDialog from '../dialogs/DisclaimerReason.dialog';

import NotesDialog from '../dialogs/Notes.dialog';
import GGroupButtons from '../../../common/GGroupButtons';

import TaxManagementDialog from '../dialogs/TaxManagement.dialog';
import { VISUAL_LAYOUT, COMPACT_LAYOUT } from '../dialogs/Print.dialog';
import { PayerDetails, PrintModeType } from '..';
import { StoreState } from '../../../../types';
import {
    updateProductContract,
    saveContractDisclaimerReason,
    deleteContractDisclaimerReason,
    updateProductContractViewers,
} from '../../../../actions/product/Contract.action';
import { loadDocPackets } from '../../../../actions/DocPacket.action';
import { WhenEnabled, isEnabled } from '../../../common/AppFeature';
import ContractContent from './ContractContent';
import AssignContractViewers from '../../../assignmentPoppers/AssignContractViewers';
import { AddItemOrigin } from '../dialogs/AddItem.dialog';
import RenameContractItemDialog from '../dialogs/RenameContractItemDialog';
import { canUpdateCase } from '../../../../shared/authority/can';
import ContractCategoryContent from './ContractCategoryContent';
import TaxBreakdownDialog from '../dialogs/TaxBreakdown.dialog';
import withGStyles from '../../../../styles/WithGStyles';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import { compose } from 'redux';
import ProductTaxDetailPopper from './widgets/ProductTaxDetail.popper';
import { getTransactionsForCase } from '../../../../actions/Finance.action';
import SpecifyPriceDialog from '../dialogs/SpecifyPrice.dialog';
import CaseNumberDialog from '../../../cases/caseCard/dialogs/CaseNumber.dialog';
import GLink from '../../../common/GLink';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import School from '@mui/icons-material/School';
import { SKYBLUE_COLOR } from '../../../../constants/colorVariables';
import CreateIntercomTicketButton from '../../../common/CreateIntercomTicketButton';
import PreArrangedGoodAndServices from './PreArrangedGoodAndServices';

function mapStateToProps(
    {
        financeState,
        productState,
        funeralHomeState,
        casesState,
        userSession,
        caseInsuranceState
    }: StoreState,
    ownProps: OwnProps
) {
    const userData = userSession.userData;
    return {
        userData,
        isShowPayments: Boolean(userData && userData.show_payments_on_statement),
        isFHorGOMUser: UserRoles.isFHorGOMUser(userSession.userData),
        isGOMUser: UserRoles.isGOMUser(userSession.userData),
        transactions: financeState.transactions,
        visibleCategories: productState.funeralHomeCategories,
        taxRates: productState.taxRates,
        helpers: casesState.helpers,
        isAutoFormsEnabled: isEnabled(FeatureKey.DOC_LIBRARY_AND_AUTOFORMS, funeralHomeState, casesState, userSession),
        canUserEditCase: canUpdateCase(userSession.userData, ownProps.activeCase),
        loggedInUserId: userSession.userData && userSession.userData.id,
        funeralHome: funeralHomeState.activeFuneralHome,
        selectedCase: casesState.selectedCase,
    };
}

interface OwnProps {
    activeContract: ProductContractUX | null;
    printModeType?: PrintModeType;
    isContractFrozen: boolean;
    printElementId?: string;
    isHidden?: boolean;
    isPrintMode: boolean;
    activeCase: GatherCaseUX;
    teamContractSigners?: DocPacketContractSignerRecord[];
    helperContractSigners?: DocPacketContractSignerRecord[];
    docPacketSigners?: DocPacketSignerUX[];
    canUserEditContract: boolean;
    isCondensedView: boolean;
    onAddAdditionalItem: (category: AddItemOrigin) => void;
    onProductItemClick: (productId?: number, contractItemId?: string) => void;
    onUpdateItemPriceAdjustment: (contractItemId: string, priceAdjustment: number) => void;
    onUpdateItemPrice: (contractItemId: string, price: number) => void;
    onUpdateItemNote: (contractItemId: string, note: string | null) => void;
    onAddContractDiscountItem: (adjustment: number, customName?: string) => void;
    onUpdateContractDiscountItem: (contractItemId: string, amount: number, customName?: string) => void;
    onRemoveContractItem: (contractItem: ProductContractItemUX) => void;
    toggleCondensedView: (isChecked: boolean) => void;
    openFreezeContractDialog: () => void;
    printContract: () => void;
    onRenameContractItem?: (contractItemId: string, newNamqe: string) => void;
    zIndex: number;
    isItemizedStatement: boolean;
    isDownloadingInvoice: boolean;
    payerDetails: PayerDetails | undefined;
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface State {
    anchorEl: HTMLElement | null;
    headerMenuAnchorEl: HTMLElement | null;
    activeContractItemId: string | null;
    activeDisclaimerId: number | null;
    isPriceAdjustmentDialogOpen: boolean;
    isContractPriceAdjustmentDialogOpen: boolean;
    isNotesDialogOpen: boolean;
    isCaseNumberDialogOpen: boolean;
    isTaxManagementDialogOpen: boolean;
    autoFormPopperAnchorEle: HTMLElement | null;
    autoFormContentAnchorEle: HTMLElement | null;
    isMouseStayOnContractStepper: boolean;
    isDisclaimerReasonDialogOpen: boolean;
    isRenameContractItemDialogOpen: boolean;
    isTaxBreakdownDialogOpen: boolean;
    anchorEle: HTMLElement | null;
    activeItem: ProductContractItemUX | null;
    isSpecifyPriceDialogOpen: boolean;
}

type CombinedProps = ContractStatusContainerStyledProps & Props & StyledProps;
class Overview extends React.Component<CombinedProps, State> {
    state: State = {
        anchorEl: null,
        headerMenuAnchorEl: null,
        activeContractItemId: null,
        activeDisclaimerId: null,
        isPriceAdjustmentDialogOpen: false,
        isContractPriceAdjustmentDialogOpen: false,
        isNotesDialogOpen: false,
        isCaseNumberDialogOpen: false,
        isTaxManagementDialogOpen: false,
        autoFormPopperAnchorEle: null,
        autoFormContentAnchorEle: null,
        isMouseStayOnContractStepper: false,
        isDisclaimerReasonDialogOpen: false,
        isRenameContractItemDialogOpen: false,
        isTaxBreakdownDialogOpen: false,
        anchorEle: null,
        activeItem: null,
        isSpecifyPriceDialogOpen: false,
    };

    componentDidMount() {
        const { activeCase, dispatch } = this.props;

        dispatch(loadDocPackets(activeCase));
        dispatch(getTransactionsForCase(activeCase.uuid));
    }

    getActiveContractItems = (): ProductContractItemUX[] => {
        const { activeContract } = this.props;

        if (!activeContract) {
            return [];
        }

        return getLatestItems(activeContract.items);
    };

    getActiveContractItem = () => {
        const { activeContractItemId } = this.state;
        if (activeContractItemId) {
            const contractItems = this.getActiveContractItems();
            return contractItems.find((item) => item.id === activeContractItemId);
        }
        return undefined;
    };

    renderMenu = () => {
        const { anchorEl } = this.state;
        const { zIndex } = this.props;

        const activeContractItem = this.getActiveContractItem();
        const isDeleted = !activeContractItem || activeContractItem.delete_revision !== null;
        const noteText = activeContractItem && activeContractItem.note ? 'Edit note' : 'Add a note';

        return (
            <Menu
                id="overview--item-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={e => this.handleMenuClose()}
                style={{ zIndex }}
            >
                <MenuItem onClick={() => this.handleViewItemDetails()}>View item details</MenuItem>
                <MenuItem onClick={() => this.toggleNotesDialog()}>{noteText}</MenuItem>
                {!isDeleted && activeContractItem &&
                    <DisabledPermissionTooltip permission={Permission.MODIFY_PRICES}>
                        {disabled =>
                            <MenuItem
                                disabled={disabled}
                                onClick={() => this.openSpecifyPriceDialog(activeContractItem.id)}
                            >
                                Specify Price
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                }
                {!isDeleted && activeContractItem &&
                    <DisabledPermissionTooltip permission={Permission.CONTRACT_ITEM_DISCOUNTS}>
                        {disabled =>
                            <MenuItem
                                onClick={() => this.openPriceAdjustmentDialog(activeContractItem.id)}
                                disabled={disabled}
                            >
                                Discount/Premium
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                }
                <DisabledPermissionTooltip permission={Permission.CONTRACT_MODIFY_ITEM_NAME}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={this.openRenameContractItemDialog}
                        >
                            Rename Item
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>

                {!isDeleted &&
                    <DisabledPermissionTooltip permission={Permission.REMOVE_ITEMS}>
                        {disabled =>
                            <MenuItem disabled={disabled} onClick={this.handleRemoveItem}>
                                Remove this item
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                }
            </Menu>
        );
    };

    openProductTaxDetailPopper = (element: HTMLElement, latestItem: ProductContractItemUX) => {
        this.setState({
            anchorEle: element,
            activeItem: latestItem
        });
    };

    closeProductTaxDetailPopper = () => {
        this.setState({
            anchorEle: null
        });
    };

    renderProductTaxDetailPopper = () => {
        const { anchorEle, activeItem } = this.state;
        const { zIndex, taxRates } = this.props;

        const taxSpecificData = activeItem && taxRates && taxRates.find(
            (tax) => tax.id === activeItem.tax_rate_id) || null;
        const taxRate = taxSpecificData && taxSpecificData.description;
        const taxAmount = activeItem && formatPrice(activeItem.tax_total, activeItem.asset_type);

        const taxCounty = taxSpecificData && taxSpecificData.county;
        const isPackage = !!activeItem && activeItem.package_id != null;

        return (
            <ProductTaxDetailPopper
                anchorEle={anchorEle}
                zIndex={zIndex + 1}
                onClose={this.closeProductTaxDetailPopper}
                taxRate={taxRate}
                taxAmount={taxAmount}
                taxCounty={taxCounty}
                isPackage={isPackage}
            />
        );
    };

    renderSignContractMenuItem = () => {
        const {
            activeCase,
            isAutoFormsEnabled,
        } = this.props;

        const menuItem = (
            <MenuItem disabled={!isAutoFormsEnabled}>
                <Gesture color="primary" />&nbsp;Sign Statement
            </MenuItem>
        );
        if (isAutoFormsEnabled) {
            return (
                <GLink
                    to={RouteBuilder.DocPacketPage({
                        caseName: activeCase.name,
                        funeralHomeKey: activeCase.funeral_home.key,
                    })}
                >
                    {menuItem}
                </GLink>
            );
        } else {
            return menuItem;
        }
    };

    renderHeaderMenu = () => {
        const { headerMenuAnchorEl } = this.state;
        const {
            classes,
            printContract,
            isContractFrozen,
            canUserEditContract,
            openFreezeContractDialog,
            activeCase,
            isFHorGOMUser,
            zIndex,
        } = this.props;

        return (
            <Menu
                id="overview--item-menu"
                anchorEl={headerMenuAnchorEl}
                open={Boolean(headerMenuAnchorEl)}
                onClose={e => this.toggleHeaderMenu(null)}
                style={{ zIndex }}
                className={classes.menu}
            >
                <WhenEnabled feature={FeatureKey.GATHER_ACADEMY}>
                    <MenuItem
                        sx={{ color: `${SKYBLUE_COLOR} !important` }}
                        onClick={() => submitIntercomEvent(IntercomTrackEvent.GatherAcademy)}
                    >
                        <School color="inherit" />&nbsp;&nbsp;Access Gather Academy™
                    </MenuItem>
                </WhenEnabled>

                {canUserEditContract &&
                    <MenuItem
                        onClick={() => {
                            this.toggleHeaderMenu(null);
                            openFreezeContractDialog();
                        }}
                        disabled={isContractFrozen}
                    >
                        <AcUnitIcon color="primary" />
                        &nbsp;Freeze Statement
                    </MenuItem>
                }
                {isFHorGOMUser && this.renderSignContractMenuItem()}
                <MenuItem
                    onClick={e => {
                        this.toggleHeaderMenu(null);
                        printContract();
                    }}
                >
                    <PrintIcon color="primary" />&nbsp;Print Draft
                </MenuItem>
                {canUserEditContract && !isContractFrozen &&
                    <MenuItem
                        onClick={e => {
                            this.toggleHeaderMenu(null);
                            this.handleTaxManagementClick();
                        }}
                    >
                        <AccountBalanceIcon color="primary" />&nbsp;View Tax Info
                    </MenuItem>
                }

                {canUserEditContract &&
                    <DisabledPermissionTooltip permission={Permission.CONTRACT_DISCOUNTS}>
                        {disabled =>
                            <MenuItem
                                disabled={disabled}
                                onClick={() => {
                                    this.toggleHeaderMenu(null);
                                    this.openContractPriceAdjustmentDialog();
                                }}
                            >
                                <SwapVertIcon color="primary" />&nbsp;Adjust Statement Total
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                }
                <GLink
                    to={RouteBuilder.FamilyPage({
                        caseName: activeCase.name,
                        funeralHomeKey: activeCase.funeral_home.key,
                        page: FamilyRoutePage.PAYMENTS,
                    })}
                >
                    <MenuItem>
                        <AttachMoneyIcon color="primary" />&nbsp;Enter Payment
                    </MenuItem>
                </GLink>

                <GLink
                    to={RouteBuilder.FamilyPage({
                        caseName: activeCase.name,
                        funeralHomeKey: activeCase.funeral_home.key,
                        page: FamilyRoutePage.PAYMENTS,
                    })}
                >
                    <MenuItem>
                        <ReceiptIcon color="primary" />&nbsp;View Receipts
                    </MenuItem>
                </GLink>

                {isFHorGOMUser &&
                    <GLink
                        to={RouteBuilder.FuneralHome(
                            activeCase.funeral_home.key,
                            FuneralHomeRoutePage.GOODSANDSERVICES,
                        )}
                    >
                        <MenuItem>
                            <LoyaltyIcon color="primary" />&nbsp;Configure Statement
                        </MenuItem>
                    </GLink>}
            </Menu>
        );
    };

    renderCategoryContent = (category: ProductCategory | 'package', categoryItemIds: string[]) => {
        const {
            activeContract,
            visibleCategories,
            onAddAdditionalItem,
            onProductItemClick,
            isPrintMode,
            canUserEditContract,
            isCondensedView,
            isItemizedStatement
        } = this.props;

        if (!activeContract) {
            return null;
        }

        return (
            <ContractCategoryContent
                key={category}
                category={category}
                visibleCategories={visibleCategories}
                categoryItemIds={categoryItemIds}
                activeContract={activeContract}
                isPrintMode={isPrintMode}
                canUserEditContract={canUserEditContract}
                isCondensedView={isCondensedView}
                onAddAdditionalItem={onAddAdditionalItem}
                onItemClick={onProductItemClick}
                onMenuClick={this.handleMenuClick}
                onNoteClick={this.toggleNotesDialog}
                onPriceAdjustmentClick={this.openPriceAdjustmentDialog}
                openProductTaxDetailPopper={this.openProductTaxDetailPopper}
                isItemizedStatement={isItemizedStatement}
            />
        );
    };

    renderPriceAdjustmentDialog = (contractItems: ProductContractItemUX[]) => {
        const { activeContract, zIndex } = this.props;
        const { isPriceAdjustmentDialogOpen, isContractPriceAdjustmentDialogOpen } = this.state;

        if (!activeContract) {
            return null;
        }

        const activeContractItem = this.getActiveContractItem();
        const contractGrandTotal = calculateContractTotal(activeContract);
        const contractDiscounts = contractItems.filter((item) => isContractDiscount(item) && item.list_price !== 0);
        const discount = calculateTotalForItems(contractDiscounts);
        const contractTotalLessDiscounts = contractGrandTotal.subtract(discount);
        let isContractAdjustment = false;

        let existingAdjustment: Dinero.Dinero;
        let listPrice: Dinero.Dinero;
        if (activeContractItem) {
            if (isContractDiscount(activeContractItem)) {
                existingAdjustment = getContractItemListPrice(activeContractItem);
                listPrice = contractTotalLessDiscounts;
                isContractAdjustment = true;
            } else {
                existingAdjustment = getContractItemPriceAdjustment(activeContractItem);
                listPrice = getContractItemListPrice(activeContractItem);
            }
        } else {
            existingAdjustment = getDinero(0, activeContract.asset_type);
            listPrice = contractTotalLessDiscounts;
            isContractAdjustment = true;
        }

        return (
            <PriceAdjustmentDialog
                key={`overview_${activeContractItem ? activeContractItem.id : -1}`}
                closeDialog={this.closePriceAdjustmentDialog}
                isDialogOpen={isPriceAdjustmentDialogOpen || isContractPriceAdjustmentDialogOpen}
                isContractAdjustment={isContractAdjustment}
                onPriceAdjustmentChange={(adjustment: number, customName?: string) => {
                    if (isContractAdjustment) {
                        this.handleContractPriceAdjustment(adjustment, activeContractItem, customName);
                    } else {
                        this.handlePriceAdjustment(adjustment);
                    }
                }}
                listPrice={listPrice}
                existingAdjustment={existingAdjustment}
                target={activeContractItem ? activeContractItem.name : ''}
                assetType={activeContract.asset_type}
                zIndex={zIndex + 1}
            />
        );
    };

    renderSpecifyPriceDialog = () => {
        const { zIndex, onUpdateItemPrice } = this.props;
        const { isSpecifyPriceDialogOpen } = this.state;

        const activeContractItem = this.getActiveContractItem();
        if (!activeContractItem) {
            return null;
        }

        return (
            <SpecifyPriceDialog
                key={activeContractItem.id}
                closeDialog={this.closeSpecifyPriceDialog}
                isDialogOpen={isSpecifyPriceDialogOpen}
                productName={activeContractItem.name}
                initialPrice={activeContractItem.list_price}
                onSpecifyPrice={(price) => onUpdateItemPrice(activeContractItem.id, price)}
                zIndex={zIndex + 1}
            />
        );
    };

    renderContractStatusContent = () => {
        const { classes, activeCase, isContractFrozen, openFreezeContractDialog } = this.props;
        return (
            <Grid item xs={12}>
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.contractStatusIcon,
                        !isContractFrozen && classes.ulockedIcon
                    )}
                >
                    {isContractFrozen ? <AcUnitIcon /> : <EditIcon />}
                </Grid>
                <Typography className={classes.contractStatus}>
                    {activeCase.fname}'s Statement is {isContractFrozen ? 'Frozen' : 'Unfrozen'}
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openFreezeContractDialog()}
                    disabled={isContractFrozen}
                >
                    <AcUnitIcon />
                    &nbsp;Freeze Statement
                </Button>
            </Grid>
        );
    };

    renderContractTaxMethodButton = () => {
        const {
            classes,
            activeContract,
            taxRates,
            canUserEditContract,
        } = this.props;

        if (!activeContract) {
            return null;
        }

        const taxationMethodStr = activeContract.taxation_method === 'contract_basis' ? 'Statement-Level Tax' :
            activeContract.taxation_method === 'per_item_basis' ? 'Item-Level Tax' :
                activeContract.taxation_method === 'exempt' ? 'Tax Exempt' : '';
        const taxRate = activeContract.tax_rate_id &&
            taxRates.find((tr) => tr.id === activeContract.tax_rate_id) || null;

        const button = (
            <div
                className={classNames(
                    classes.footerBadge,
                    !canUserEditContract && classes.restricted
                )}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleTaxManagementClick}
                    disabled={activeContract.is_frozen}
                >
                    <span className={classes.inner}>
                        <AccountBalanceIcon />
                        &nbsp;{taxationMethodStr}
                    </span>
                    <span className={classNames(classes.inner, classes.taxRate)}>
                        {displayTaxRate(taxRate)}
                    </span>
                </Button>
            </div>
        );

        const buttonWithTooltip = (
            <Tooltip
                title={`Cannot change taxation method after statement is started. 
                Contact Gather Support for assistance`}
                placement="top"
            >
                {button}
            </Tooltip>
        );

        return activeContract.is_frozen && canUserEditContract ? buttonWithTooltip : button;
    };

    render() {
        const {
            classes,
            activeContract,
            activeCase,
            helpers,
            taxRates,
            canUserEditContract,
            canUserEditCase,
            isHidden,
            printElementId,
            isCondensedView,
            isPrintMode,
            isContractFrozen,
            printModeType,
            printContract,
            teamContractSigners,
            helperContractSigners,
            docPacketSigners,
            openFreezeContractDialog,
            isFHorGOMUser,
            isGOMUser,
            zIndex,
            onAddAdditionalItem,
            isItemizedStatement,
            userData,
            transactions,
            loggedInUserId,
            isDownloadingInvoice,
            payerDetails,
            isShowPayments,
            funeralHome,
            selectedCase
        } = this.props;
        const {
            isDisclaimerReasonDialogOpen,
            isNotesDialogOpen,
            isCaseNumberDialogOpen,
            isTaxManagementDialogOpen,
            activeDisclaimerId,
            isRenameContractItemDialogOpen,
            isTaxBreakdownDialogOpen,
        } = this.state;

        if (!activeContract || !selectedCase) {
            return null;
        }

        const { contract_disclaimers, contract_options, contract_packages, hide_revisions } = activeContract;

        const contractItems: ProductContractItemUX[] = hide_revisions
            ? getLatestItems(activeContract.items).filter((latestItem) => !latestItem.delete_revision)
            : getLatestItems(activeContract.items);

        const contractSubTotal = getContractSubTotal(activeContract);
        const contractTaxTotal = getContractTaxTotal(activeContract);
        const contractGrandTotal = calculateContractTotal(activeContract);

        const hideHeaderMenu = !canUserEditContract;

        const activeContractItem = this.getActiveContractItem();
        const activeDisclaimer = !activeDisclaimerId ? null :
            contract_disclaimers.find((d) => d.id === activeDisclaimerId) || null;

        const { bDate, dDate, age } = getDecedentAge(activeCase);

        const deathCertificate = activeCase.death_certificate;
        const gender = deathCertificate && deathCertificate.about.gender || '';
        const residencePlace = deathCertificate && deathCertificate.life.residencePlace;

        const isFamilyUser = UserRoles.isFamilyOnCase(userData, activeCase.id);

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classNames(
                    classes.root,
                    classes.contractContainer,
                    classes.maxWidth895,
                    isHidden && classes.hidden
                )}
            >

                <Grid item xs={12} sm={11} className={classes.settingSection}>
                    {canUserEditContract &&
                        <AssignContractViewers
                            zIndex={zIndex + 1}
                            invitedHelpers={helpers.filter((helper) => UserRoles.isFamilyOnCase(helper, activeCase.id))}
                            viewers={activeContract.viewers}
                            onUpdateViewers={this.handleViewersUpdated}
                        />
                    }
                    {!!selectedCase.insurance_policies.length &&
                        <WhenEnabled feature={FeatureKey.INSURANCE_INTEGRATION}>
                            <PreArrangedGoodAndServices
                                activeCase={selectedCase}
                                title='Pre-arranged plans detected for this case!'
                                subtitle={`Refer to ${(selectedCase?.fname || '')}'s Goods and Services 
                        PDF from HomeSteader's Life Company.`}
                                zIndex={zIndex + 1}
                                funeralHome={funeralHome}
                                btntext={'View Pre-Arranged Goods and services'}
                            />
                        </WhenEnabled>}

                    <div
                        className={classNames(
                            classes.contractStatus,
                            !isFHorGOMUser && classes.pointerEventsNone,
                        )}
                        onClick={() => isFHorGOMUser && !isContractFrozen && openFreezeContractDialog()}>
                        <Typography color="primary">
                            {isContractFrozen ? <AcUnitIcon /> : <EditIcon />}
                            {` ${activeCase.fname}'s statement is ${isContractFrozen ? 'Frozen' : 'Unfrozen'}`}
                        </Typography>
                    </div>
                    <div className={classes.layoutGButton}>
                        <div>
                            <GGroupButtons
                                buttons={[
                                    { label: 'Visual Layout', value: VISUAL_LAYOUT, icon: <ViewAgendaIcon /> },
                                    { label: 'Compact Layout', value: COMPACT_LAYOUT, icon: <ViewHeadlineIcon /> }
                                ]}
                                activeButton={isCondensedView ? COMPACT_LAYOUT : VISUAL_LAYOUT}
                                onClick={this.toggleLayout}
                                size={'small'}
                                controlClasses={classNames(classes.fontWeight200, classes.textCapitalize)}
                            />
                        </div>
                        {!hideHeaderMenu && <IconButton
                            className={classes.iconButton}
                            aria-label="More"
                            aria-owns={'overview-header-menu'}
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.toggleHeaderMenu(e.currentTarget);
                            }}
                            size="large">
                            <MoreVertIcon color="primary" />
                        </IconButton>}
                    </div>
                </Grid>
                <ContractContent
                    isCondensedView={isCondensedView}
                    isContractFrozen={isContractFrozen}
                    canUserEditContract={canUserEditContract}
                    canUserEditCase={canUserEditCase}
                    isPrintMode={isPrintMode}
                    printModeType={printModeType}
                    printElementId={printElementId}
                    teamContractSigners={teamContractSigners || []}
                    helperContractSigners={helperContractSigners || []}
                    docPacketSigners={docPacketSigners || []}
                    contractPackages={contract_packages}
                    contractDisclaimers={contract_disclaimers}
                    contractOptions={contract_options}
                    contractItems={contractItems}
                    funeralHomeDetails={{
                        fhName: activeCase.funeral_home.name,
                        fhAddress: activeCase.funeral_home.address1,
                        fhCity: activeCase.funeral_home.city,
                        fhState: activeCase.funeral_home.state,
                        fhPostalCode: activeCase.funeral_home.postal_code,
                        fhPhone: activeCase.funeral_home.phone,
                    }}
                    caseDetails={{
                        assigneeName: `${activeCase.assignee.fname} ${activeCase.assignee.lname}`,
                        assigneeTitle: getDisplayTitleForFHUser(activeCase.assignee, activeCase.funeral_home_id),
                        caseName: getFullNameFromCase(activeCase),
                        caseFirstName: activeCase.fname,
                        caseNumber: activeCase.case_number || undefined,
                        caseLifeSpan: lifeSpan(activeCase),
                        age,
                        gender,
                        caseType: activeCase.case_type,
                        deathPlace: deathCertificate && deathCertificate.life.deathPlace,
                        deathDate: dDate,
                        birthDate: bDate,
                        residencePlace
                    }}
                    contractSubTotal={contractSubTotal}
                    contractTaxTotal={contractTaxTotal}
                    contractGrandTotal={contractGrandTotal}
                    legalUnavailableText={''}
                    legalTextStyle={{ color: '#2e2e2e' }}
                    onDisclaimerReasonClick={this.openDisclaimerReasonDialog}
                    onCaseNumberClick={this.toggleCaseNumberDialog}
                    onAdjustContractTotalClick={this.openContractPriceAdjustmentDialog}
                    onTaxManagementClick={this.handleTaxManagementClick}
                    renderCategoryContent={this.renderCategoryContent}
                    onAddAdditionalItemClick={() => onAddAdditionalItem('contract_all')}
                    isFHorGOMUser={isFHorGOMUser}
                    isItemizedStatement={isItemizedStatement}
                    transactions={transactions}
                    isFamilyUser={isFamilyUser}
                    loggedInUserId={loggedInUserId}
                    isDownloadingInvoice={isDownloadingInvoice}
                    payerDetails={payerDetails}
                    isShowPayments={isShowPayments}
                    contractCreatedTime={activeContract.created_time}
                />

                <Grid sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {this.renderContractTaxMethodButton()}

                    <Grid mt={5}>
                        <CreateIntercomTicketButton
                            title='Having issues with your statement?'
                            subTitle='Click here so we can fix it.'
                            intercomTarget='Overview-HavingIssuesStatement'
                        />
                    </Grid>
                </Grid>

                {this.renderMenu()}
                {!hideHeaderMenu && this.renderHeaderMenu()}
                {this.renderPriceAdjustmentDialog(contractItems)}
                {this.renderSpecifyPriceDialog()}
                <NotesDialog
                    key={activeContractItem && activeContractItem.note || -1}
                    closeDialog={() => this.toggleNotesDialog()}
                    isDialogOpen={isNotesDialogOpen}
                    activeCase={activeCase}
                    contractItem={activeContractItem}
                    handleAddNote={this.handleAddNote}
                    zIndex={zIndex + 1}
                />
                <CaseNumberDialog
                    activeCase={activeCase}
                    isDialogOpen={isCaseNumberDialogOpen}
                    closeDialog={this.toggleCaseNumberDialog}
                    zIndex={zIndex + 1}
                />
                {activeContractItem && <RenameContractItemDialog
                    key={
                        activeContractItem.id ? activeContractItem.id + '-renameItem' : -21
                    }
                    activeCase={activeCase}
                    isDialogOpen={isRenameContractItemDialogOpen}
                    contractItem={activeContractItem}
                    closeDialog={this.closeRenameContractItemDialog}
                    handleRenameContractItem={this.handleRenameContractItem}
                    zIndex={zIndex + 1}
                />}
                <TaxManagementDialog
                    isDialogOpen={isTaxManagementDialogOpen}
                    closeDialog={this.toggleTaxManagementDialog}
                    activeCase={activeCase}
                    activeContract={activeContract}
                    contractOptions={contract_options}
                    taxRates={taxRates}
                    updateContract={this.handleUpdateContract}
                    onSeeTaxBreakdown={this.openTaxBreakdownDialog}
                    zIndex={zIndex + 1}
                    isGOMUser={isGOMUser}
                />
                <TaxBreakdownDialog
                    zIndex={zIndex + 1}
                    isDialogOpen={isTaxBreakdownDialogOpen}
                    items={contractItems}
                    closeDialog={this.closeTaxBreakdownDialog}
                />

                <DisclaimerReasonDialog
                    key={activeDisclaimerId || -2}
                    isDialogOpen={isDisclaimerReasonDialogOpen}
                    closeDialog={this.closeDisclaimerReasonDialog}
                    activeCase={activeCase}
                    disclaimer={activeDisclaimer}
                    onSaveDisclaimerReason={this.handleDisclaimerReasonSave}
                    onDeleteDisclaimerReason={this.handleDisclaimerReasonDelete}
                    zIndex={zIndex + 1}
                />
                <Grid item xs={11} className={classes.paymentCardContainer}>
                    {isFHorGOMUser &&
                        <PaymentCard
                            classes={classes}
                        >
                            {this.renderContractStatusContent()}
                        </PaymentCard>
                    }
                </Grid>
                <Grid item xs={11} className={classes.footerButton}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={printContract}
                    >
                        <PrintIcon />&nbsp;Print Draft
                    </Button>

                    {isFHorGOMUser && this.renderSignContractButton()}

                </Grid>
                {this.renderProductTaxDetailPopper()}
            </Grid>
        );
    }

    renderSignContractButton = () => {
        const { activeCase, isAutoFormsEnabled } = this.props;

        const button = (
            <Button
                color="primary"
                variant="contained"
                disabled={!isAutoFormsEnabled}
            >
                <Gesture />&nbsp;Sign Statement
            </Button>
        );
        if (isAutoFormsEnabled) {
            return (
                <GLink
                    to={RouteBuilder.DocPacketPage({
                        caseName: activeCase.name,
                        funeralHomeKey: activeCase.funeral_home.key,
                    })}
                >
                    {button}
                </GLink>
            );
        } else {
            return button;
        }
    };

    toggleTaxManagementDialog = () => {
        this.setState((prevState) => ({
            isTaxManagementDialogOpen: !prevState.isTaxManagementDialogOpen,
        }));
    };

    toggleCaseNumberDialog = () => {
        this.setState((prevState) => ({
            isCaseNumberDialogOpen: !prevState.isCaseNumberDialogOpen,
        }));
    };

    toggleHeaderMenu = (headerMenuAnchorEl: HTMLElement | null) => {
        this.setState({ headerMenuAnchorEl });
    };

    handleMenuClick = (anchorEl: HTMLElement, contractItemId: string) => {

        this.setState({
            anchorEl,
            activeContractItemId: contractItemId,
        });
    };

    handleViewItemDetails = () => {
        const { onProductItemClick } = this.props;

        const activeContractItem = this.getActiveContractItem();

        if (activeContractItem && (activeContractItem.product || activeContractItem.id)) {
            onProductItemClick(
                activeContractItem.product ? activeContractItem.product.id : undefined,
                activeContractItem.id,
            );
        }
        this.handleMenuClose();
    };

    handleAddNote = async (note: string | null) => {
        const { onUpdateItemNote } = this.props;
        const { activeContractItemId } = this.state;

        if (activeContractItemId) {
            onUpdateItemNote(activeContractItemId, note);
        }
        this.handleMenuClose();
    };

    handlePriceAdjustment = (priceAdjustment: number) => {
        const {
            onUpdateItemPriceAdjustment,
        } = this.props;
        const { activeContractItemId } = this.state;

        if (activeContractItemId) {
            onUpdateItemPriceAdjustment(activeContractItemId, priceAdjustment);
        }
    };

    handleContractPriceAdjustment = (
        priceAdjustment: number,
        existingDiscount?: ProductContractItemUX,
        customName?: string,
    ) => {
        const {
            onUpdateContractDiscountItem,
            onAddContractDiscountItem,
        } = this.props;
        const { activeContractItemId } = this.state;

        if (existingDiscount && activeContractItemId) {
            onUpdateContractDiscountItem(existingDiscount.id, priceAdjustment, customName);
        } else {
            onAddContractDiscountItem(priceAdjustment, customName);
        }
    };

    handleRemoveItem = () => {
        const { onRemoveContractItem } = this.props;

        const activeContractItem = this.getActiveContractItem();
        if (!activeContractItem) {
            return;
        }

        onRemoveContractItem(activeContractItem);
        this.handleMenuClose();
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            activeContractItemId: null,
        });
    };

    handleUpdateContract = async (contractReq: ProductContractRequest) => {
        const { dispatch, activeContract, activeCase, isContractFrozen } = this.props;

        if (activeContract && !isContractFrozen) {
            dispatch(updateProductContract(activeCase.uuid, activeContract.id, contractReq));
        }
    };

    handleTaxManagementClick = () => {
        const { isContractFrozen, canUserEditContract } = this.props;

        if (!canUserEditContract || isContractFrozen) {
            return;
        }

        this.toggleTaxManagementDialog();
    };

    handleDisclaimerReasonSave = (disclaimerId: number, reason: string | null) => {
        const { dispatch, activeContract, activeCase } = this.props;

        if (activeContract && reason) {
            dispatch(saveContractDisclaimerReason(activeContract, activeCase.uuid, disclaimerId, reason));
        }
    };

    handleDisclaimerReasonDelete = (disclaimerId: number) => {
        const { dispatch, activeContract, activeCase } = this.props;

        if (activeContract) {
            dispatch(deleteContractDisclaimerReason(activeContract, activeCase.uuid, disclaimerId));
        }
    };

    openContractPriceAdjustmentDialog = (discountItemId?: string) => {

        this.setState({
            isContractPriceAdjustmentDialogOpen: true,
            activeContractItemId: discountItemId || null,
        });
    };

    openPriceAdjustmentDialog = (contractItemId: string) => {
        const { canUserEditContract } = this.props;

        if (!canUserEditContract) {
            this.setState({
                isPriceAdjustmentDialogOpen: false,
                anchorEl: null,
            });
            return;
        }

        this.setState((prevState) => ({
            isPriceAdjustmentDialogOpen: !prevState.isPriceAdjustmentDialogOpen,
            anchorEl: null,
            activeContractItemId: contractItemId,
        }));
    };

    closePriceAdjustmentDialog = () => {
        this.setState({
            isPriceAdjustmentDialogOpen: false,
            isContractPriceAdjustmentDialogOpen: false,
        });
    };

    openSpecifyPriceDialog = (contractItemId: string) => {

        this.setState({
            isSpecifyPriceDialogOpen: true,
            anchorEl: null,
            activeContractItemId: contractItemId,
        });
    };

    closeSpecifyPriceDialog = () => {
        this.setState({
            isSpecifyPriceDialogOpen: false,
        });
    };

    openRenameContractItemDialog = () => {
        const { canUserEditContract } = this.props;
        if (canUserEditContract) {
            this.setState({
                isRenameContractItemDialogOpen: true,
            });
        }
    };

    closeRenameContractItemDialog = () => {
        this.setState({
            isRenameContractItemDialogOpen: false,
        });
        this.handleMenuClose();
    };

    handleRenameContractItem = (contractItemId: string, newName: string | null) => {
        const { onRenameContractItem } = this.props;
        if (onRenameContractItem) {
            onRenameContractItem(contractItemId, newName || '');
        }
    };

    toggleNotesDialog = (contractItemId?: string) => {
        const { activeContractItemId } = this.state;
        const { canUserEditContract } = this.props;

        if (!canUserEditContract) {
            this.setState({
                isNotesDialogOpen: false,
                anchorEl: null,
            });
            return;
        }

        this.setState((prevState) => ({
            isNotesDialogOpen: !prevState.isNotesDialogOpen,
            anchorEl: null,
            activeContractItemId: contractItemId || activeContractItemId
        }));
    };

    toggleLayout = (layoutType: string) => {
        const { toggleCondensedView } = this.props;
        toggleCondensedView(layoutType === COMPACT_LAYOUT ? true : false);
    };

    handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        if (isTouchDevice()) {
            return;
        }
        this.openPopper(event);
    };

    openPopper = (event: React.MouseEvent<HTMLElement>) => {
        const currentTarget = event.currentTarget;
        this.setState({ isMouseStayOnContractStepper: true });
        setTimeout(
            () => {
                if (this.state.isMouseStayOnContractStepper) {
                    this.setState({
                        autoFormPopperAnchorEle: currentTarget,
                        isMouseStayOnContractStepper: false,
                    });
                }
            },
            200
        );
    };

    handleMouseLeave = () => {
        this.setState({
            autoFormPopperAnchorEle: null,
            autoFormContentAnchorEle: null,
            isMouseStayOnContractStepper: false,
        });
    };

    openDisclaimerReasonDialog = (disclaimerId: number) => {
        this.setState({
            isDisclaimerReasonDialogOpen: true,
            activeDisclaimerId: disclaimerId,
        });
    };

    closeDisclaimerReasonDialog = () => {
        this.setState({
            isDisclaimerReasonDialogOpen: false,
            activeDisclaimerId: null,
        });
    };

    handleViewersUpdated = async (viewerUserIds: number[]) => {
        const { activeContract, activeCase, dispatch } = this.props;
        if (activeContract) {
            dispatch(updateProductContractViewers(viewerUserIds, activeContract.id, activeCase.uuid));
        }
    };

    openTaxBreakdownDialog = () => {
        this.setState({
            isTaxBreakdownDialogOpen: true,
        });
    };
    closeTaxBreakdownDialog = () => {
        this.setState({
            isTaxBreakdownDialogOpen: false,
        });
    };
}

export default compose(
    withState(mapStateToProps),
    withGStyles(
        styleWrapper<CombinedProps>(),
        contractStatusContainerStyles<CombinedProps>()
    )
)(Overview) as React.ComponentType<OwnProps>;
