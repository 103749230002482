import { useCallback, useMemo, useState } from 'react';
import { throttle } from 'lodash';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import makeGStyles from '../../../../styles/makeGStyles';
import useWindowEvent from '../../../common/hooks/useWindowEvent';

const useStyles = makeGStyles((theme) => ({
    root: {},
    scrollToTopBtn: {
        cursor: "pointer",
        position: "fixed",
        bottom: 10,
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: 16,
        fontWeight: 400,
        zIndex: 1,
        '& svg': {
            fontSize: 24,
        },
    },
}), { name: 'ScrollToTop' });

const ScrollToTop = () => {

    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
    const classes = useStyles();

    const handleScroll = useCallback(() => {
        if (window.scrollY > (window.innerHeight * (3 / 4))) {
            setShowScrollToTopButton(true);
        } else {
            setShowScrollToTopButton(false);
        }
    }, []);

    const throttledListener = useMemo(() => throttle(handleScroll, 200), [handleScroll]);
    useWindowEvent('resize', throttledListener);
    useWindowEvent('scroll', throttledListener);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Fade in={showScrollToTopButton} mountOnEnter unmountOnExit>
            <Button
                color="primary"
                variant="contained"
                className={classes.scrollToTopBtn}
                onClick={scrollToTop}
            >
                Back to Top <KeyboardArrowUpIcon />
            </Button>
        </Fade>
    );
};

export default ScrollToTop;