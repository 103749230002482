import { useMemo } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import AddIcon from '@mui/icons-material/Add';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { WorkflowSummary, CaseType, FeatureKey } from '../../../../shared/types';

import { Button } from '@mui/material';
import { convertHexToRGBA } from '../../../../services/app.service';
import { GRAY_COLOR_2, GREEN_COLOR } from '../../../../constants/colorVariables';
import { WhenEnabled } from '../../../common/AppFeature';
import WorkflowListSummary from './WorkflowListSummary';
import { replaceStringTemplates } from '../../../../shared/utils';
import { LoadingButton } from '@mui/lab';
import GLink from '../../../common/GLink';
import { RouteBuilder, getIntercomTargetProp } from '../../../../services';
import { CaseTypeGroupButtons } from '../../../cases/type/widgets';
import RouteIcon from '@mui/icons-material/Route';
import { WorkFlowSelectorFuneralHome } from './ChangeWorkflowAndCaseTypePure.dialog';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { GStyles } from '../../../../styles/GStyles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        listItem: {
            marginTop: 24,
            margin: 'auto',
            maxWidth: '1160px',
            borderRadius: 8,
            cursor: 'pointer',
            padding: '0px',
            border: `4px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.common.white,
            '&:first-of-type': {
                marginTop: 0,
            },
            '&:hover': {
                boxShadow: theme.shadows[10],
                backgroundColor: theme.palette.common.white,
            },
        },
        loader: {
            margin: 24,
        },
        listItemHeading: {
            fontSize: 18,
            padding: '12px 8px',
            fontWeight: 300,
            textTransform: 'capitalize',
            display: 'inline-block',
            width: 'calc(100% - 40px)',
            verticalAlign: 'super',
        },
        listItemFooter: {
            borderTop: '1px solid rgba(0, 0, 0, 0.21)',
            textAlign: 'center',
            '& p': {
                fontSize: 16,
                padding: 8,
                fontWeight: 300,
            },
        },
        listContainer: {
            padding: '20px 8px 8px',
        },
        primaryContainer: {
            margin: '16px 0',
            display: 'flex',
            overflowX: 'auto',
            justifyContent: 'space-between',
            flexDirection: 'row',
            columnGap: 16,
            '@media (min-width: 768px)': {
                alignItems: 'flex-start',
            },
        },
        sorryText: {
            marginTop: 20,
        },
        noResultsDivider: {
            margin: '8px 32px',
        },
        noWorriesText: {
            padding: '0 24px',
        },
        noResultsFoundSection: {
            textAlign: 'center',
        },
        gridItem: {
            background: theme.palette.primary.main,
            borderBottom: '1px solid rgba(0, 0, 0, 0.21)',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 8px',
            borderRadius: '4px 4px 0px 0px',
            '& p, svg': {
                color: theme.palette.common.white,
            },
        },
        rightHeaderText: {
            whiteSpace: 'nowrap',
            paddingRight: '8px',
            fontWeight: 400,
            fontSize: 16,
        },
        afterCareWorkflow: {
            marginTop: 24,
            '@media (min-width: 768px)': {
                marginTop: 0,
            },
        },
        default: {
            color: theme.palette.primary.main,
        },
        dark: {
            color: GRAY_COLOR_2,
        },
        translucent: {
            color: convertHexToRGBA(theme.palette.primary.main, 0.8),
        },
        white: {
            color: theme.palette.primary.main,
        },
        loaderContainer: {
            width: 1168,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        spinner: {
            position: 'absolute',
        },
        marginLeft30: {
            marginLeft: 30,
        },
        taskButtonContainer: {
            margin: '16px 0 32px 0',
        },
        addTaskButton: {
            width: '100%',
            border: `1px dashed ${theme.palette.primary.main}`,
            padding: '10px 8px',
            borderRadius: 10,
        },
        buttonContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                fontSize: 40,
            },
        },
        buttonText: {
            textTransform: 'none',
            fontWeight: 400,
        },
        selectTasksButton: {
            background: GREEN_COLOR,
            color: theme.palette.common.white,
            boxShadow: 'none !important',
            marginTop: '8px',
            padding: '8px 14px',
            '&:hover': {
                background: convertHexToRGBA(GREEN_COLOR, 0.8),
            },
            '& svg': {
                fontSize: 18,
            },
        },
        workflowType: {
            backgroundColor: '#fff',
            borderRadius: 8,
            '& ~ p svg': {
                verticalAlign: 'bottom',
            },
        },
        standardWorkflow: {},
    }),
    { name: 'WorkflowSelectorPure' },
);

export interface WorkflowSelectorPureProps {
    caseType: CaseType;
    isSaving: boolean;
    caseFname: string;
    activeWorkflowId: number | null;
    activeWorkflowName: string | null;
    funeralHome: WorkFlowSelectorFuneralHome;
    listContainerClass?: string;
    workflows: WorkflowSummary[];
    isLoading: boolean;
    onSelect: (workflow: WorkflowSummary) => void;
    onCaseTypeChange: (caseType: CaseType) => void;
}

const WorkflowSelectorPure = (props: WorkflowSelectorPureProps) => {
    const {
        caseType,
        isSaving,
        caseFname,
        activeWorkflowId,
        activeWorkflowName,
        funeralHome,
        listContainerClass,
        workflows,
        isLoading,
        onSelect,
        onCaseTypeChange,
    } = props;

    const classes = useStyles();

    const mappedWorkflows = useMemo(
        () =>
            workflows.map((workflow) => ({
                ...workflow,
                selected_checklist_tasks: workflow.selected_checklist_tasks.map((task) => ({
                    ...task,
                    title: replaceStringTemplates(task.title, { case: { fname: caseFname } }),
                })),
                selected_tracking_steps: workflow.selected_tracking_steps.map((task) => ({
                    ...task,
                    title: replaceStringTemplates(task.title, { case: { fname: caseFname } }),
                })),
            })),
        [caseFname, workflows],
    );

    const activeWorkFlow = mappedWorkflows.find((wf) => wf.id === activeWorkflowId);

    if (mappedWorkflows.length === 0 && !isLoading) {
        return (
            <Grid item xs={12} className={classes.noResultsFoundSection}>
                <Typography component="p" color="secondary" className={classes.sorryText}>
                    Sorry, no results found.
                </Typography>
                <Divider className={classes.noResultsDivider} />
                <Typography component="p" color="secondary" className={classes.noWorriesText}>
                    Contact us to configure your first WorkFlow
                </Typography>
            </Grid>
        );
    }
    if (isLoading) {
        return (
            <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={12}>
                    <Grid item xs={12} className={classes.loaderContainer}>
                        <CircularProgress color="primary" className={classes.loader} size={90} thickness={3} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent="center" alignContent="center">
            <Grid item xs={12}>
                <List className={classNames(classes.listContainer, listContainerClass)}>
                    <Grid container direction="column" alignItems="center">
                        <Grid
                            item
                            xs={12}
                            className={classes.workflowType}
                            {...getIntercomTargetProp(`WorkFlowSelection-CaseTypeContainer`)}
                        >
                            <CaseTypeGroupButtons
                                active={caseType}
                                onClick={onCaseTypeChange}
                                intercomTargetProp={`WorkFlowSelection-CaseType`}
                            />
                        </Grid>

                        <Typography
                            lineHeight="18px"
                            className={classNames(
                                GStyles.fontSize16,
                                GStyles.marginTop12,
                                activeWorkFlow && activeWorkFlow?.case_type !== caseType && GStyles.colorRed,
                            )}
                            color="secondary"
                            align="center"
                        >
                            <RouteIcon className={GStyles.fontSize18} />
                            &nbsp;
                            <span>
                                {activeWorkflowName
                                    ? `Current WorkFlow: ${activeWorkflowName}`
                                    : 'No WorkFlow Selected'}
                            </span>
                        </Typography>

                        <Typography
                            color="secondary"
                            align="center"
                            className={classNames(GStyles.marginTop5, GStyles.fontSize12)}
                        >
                            Click below to select {caseFname}'s WorkFlow.
                        </Typography>
                    </Grid>

                    {mappedWorkflows.map((workflow: WorkflowSummary) => {
                        const tasksCounts = workflow.selected_checklist_tasks.length;
                        const trackingStepsCount = workflow.selected_tracking_steps.length;
                        const totalCount = tasksCounts + trackingStepsCount;
                        const isSavingCurrentWorkflow = isSaving && activeWorkflowId === workflow.id;

                        if (workflow.case_type !== caseType) {
                            return null;
                        }

                        return (
                            <ListItem
                                key={workflow.id}
                                dense
                                button
                                className={classes.listItem}
                                onClick={() => onSelect(workflow)}
                            >
                                <Grid container {...getIntercomTargetProp(`WorkFlowSelection-WorkFlow`)}>
                                    <Grid item xs={12} className={classes.gridItem}>
                                        {isSavingCurrentWorkflow ? (
                                            <CircularProgress
                                                color="primary"
                                                size={24}
                                                className={classes.spinner}
                                            />
                                        ) : (
                                            <DoneOutlineIcon color="inherit" />
                                        )}
                                        <Typography
                                            component="p"
                                            className={classNames(
                                                classes.listItemHeading,
                                                isSavingCurrentWorkflow &&
                                                classNames(classes.marginLeft30, GStyles.textTransformNone),
                                            )}
                                            {...getIntercomTargetProp(`WorkFlowSelection-WorkflowLabel-Count`)}
                                        >
                                            {isSavingCurrentWorkflow
                                                ? `Creating ${caseFname}'s Tasks...`
                                                : `${workflow.name} (${totalCount})`}
                                        </Typography>
                                        <Typography
                                            className={classNames(
                                                classes.rightHeaderText,
                                                GStyles.textUppercase,
                                                (workflow.case_type === 'at-need' && classes.default) ||
                                                (workflow.case_type === 'trade' && classes.dark) ||
                                                (workflow.case_type === 'pre-need' && classes.translucent) ||
                                                (workflow.case_type === 'one-off' && classes.white),
                                            )}
                                            {...getIntercomTargetProp(`WorkFlowSelection-CaseType`)}
                                        >
                                            {workflow.case_type}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} className={classes.primaryContainer}>
                                        <WorkflowListSummary workflowSummary={workflow} />
                                    </Grid>

                                    <Grid item xs={12} className={classes.listItemFooter}>
                                        <LoadingButton
                                            size="large"
                                            variant="contained"
                                            loadingPosition="start"
                                            startIcon={<AddIcon />}
                                            className={classes.selectTasksButton}
                                            loading={isSavingCurrentWorkflow}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                onSelect(workflow);
                                            }}
                                            {...getIntercomTargetProp(`WorkFlowSelection-SelectWorkFlowButton`)}
                                        >
                                            {isSavingCurrentWorkflow
                                                ? `Creating ${caseFname}'s Tasks`
                                                : 'Select this WorkFlow'}
                                        </LoadingButton>
                                        <Typography component="p" color="secondary">
                                            This WorkFlow is just a starting point. You can add or remove Steps and
                                            Tasks as needed.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        );
                    })}
                    <WhenEnabled feature={FeatureKey.CONFIGURE_WORKFLOWS}>
                        <Grid item xs={12} className={classes.taskButtonContainer}>
                            <GLink to={RouteBuilder.WorkflowPage(funeralHome.key)} target="_blank">
                                <Button size="small" color="primary" className={classes.addTaskButton}>
                                    <span className={classes.buttonContent}>
                                        <PlaylistAddIcon color="primary" />
                                        <span className={classNames(GStyles.fontSize16, classes.buttonText)}>
                                            Click here to create or edit your default WorkFlows
                                        </span>
                                        <span className={classNames(GStyles.fontSize12, classes.buttonText)}>
                                            (This link will open in a new tab)
                                        </span>
                                    </span>
                                </Button>
                            </GLink>
                        </Grid>
                    </WhenEnabled>
                </List>
            </Grid>
        </Grid>
    );
};

export default WorkflowSelectorPure;
