import classNames from 'classnames';

import SchoolIcon from '@mui/icons-material/School';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircleIcon from "@mui/icons-material/Circle";

import makeGStyles from '../../../styles/makeGStyles';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import BorderedSectionWithFooter from '../../common/BorderedSectionWithFooter';
import GSwitch from '../../common/inputElements/GSwitch';
import { useState } from 'react';
import FamilySharingExplainerDialog from './dialogs/FamilySharingExplainer.dialog';
import { getIntercomTargetProp } from '../../../services';

const useStyles = makeGStyles(theme => ({
    borderedSection: {
        maxWidth: 425,
        width: `calc(100% - 24px) !important`,
    },
    fontSize72: {
        fontSize: 72,
    },
    questionsContainer: {
        marginTop: 34,
        width: '100%',
        padding: '0px 8px',
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        paddingBottom: 20,
        width: 'fit-content',
    },
    switchContainerInner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gSwitch: {
        '& label': {
            margin: 0,
            maxHeight: 20,
        },
    },
    stepperContainer: {
        flexGrow: 1,
        width: 'fit-content',
        margin: 'auto',
        minHeight: 94,
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 16,
        '&$oneVisible': {
            '& $divider': {
                width: '50%',
            },
            '& $text, $circleIcon': {
                left: '50%',
            },
        },
        '&$bothVisible': {
            '& $divider': {
                width: '75%',
            },
            '& $text, $circleIcon': {
                left: '75%',
            },
        },
    },
    divider: {
        height: 2,
        backgroundColor: theme.palette.primary.main,
    },
    text: {
        color: theme.palette.primary.main,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
        paddingBottom: 14,
        width: 'fit-content',
        maxWidth: 100,
        textAlign: 'center',
    },
    circleIcon: {
        fontSize: 18,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -60%)',
    },
    left25: {
        left: '25% !important',
    },
    oneVisible: {},
    bothVisible: {},
    footer: {
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    paddingBottom24: {
        paddingBottom: 24,
    },
    relativeContent: {
        position: 'relative',
        top: 12
    }
}), { name: 'FamilySharing' });

export interface FamilySharingSwitch {
    name: string;
    isVisible: boolean;
    disabled?: boolean;
    setVisibility: (isVisible: boolean) => void;
}

interface Props {
    fhName: string;
    switchOne: FamilySharingSwitch | undefined;
    switchTwo: FamilySharingSwitch | undefined;
    isDisabled: boolean;
    zIndex: number;
    intercomTargetPropComponent?: string;
    intercomTargetPropToggle?: string;
    intercomTargetPropFooter?: string;
}

const FamilySharing = (props: Props) => {
    const {
        fhName,
        switchOne,
        switchTwo,
        isDisabled,
        zIndex,
        intercomTargetPropComponent,
        intercomTargetPropToggle,
        intercomTargetPropFooter
    } = props;

    const classes = useStyles();

    const [familySharingExplainerDialogOpen, setFamilySharingExplainerDialogOpen] = useState(false);

    const headerIcon = switchOne?.isVisible || switchTwo?.isVisible
        ? VisibilityIcon
        : VisibilityOffIcon;

    const getFooter = () => {
        return (
            <Typography
                color="white"
                className={classes.footer}
                onClick={() => setFamilySharingExplainerDialogOpen(true)}
            >
                <SchoolIcon />&nbsp;Click here to learn about family sharing
            </Typography>
        );
    };

    const renderToggleButton = (sharingSwitch: FamilySharingSwitch) => {
        const { name, isVisible, disabled, setVisibility } = sharingSwitch;
        return (
            <Grid
                className={classes.switchContainer}
            >
                <Typography color="secondary">Is "{name}" visible to helpers?</Typography>
                <Grid
                    item
                    xs={12}
                    className={classes.switchContainerInner}
                    {...getIntercomTargetProp(intercomTargetPropToggle)}
                >
                    <label
                        className={classNames(classes.colorSecondary, classes.cursorPointer)}
                        htmlFor="family-sharing-switch"
                    >
                        No
                    </label>
                    <div className={classes.gSwitch}>
                        <GSwitch
                            checked={isVisible}
                            disabled={isDisabled || disabled}
                            onChangeCallBack={setVisibility}
                            id="family-sharing-switch"
                        />
                    </div>
                    <label
                        htmlFor="family-sharing-switch"
                        className={classNames(
                            classes.cursorPointer,
                            isVisible ? classes.colorPrimary : classes.colorSecondary
                        )}
                    >
                        Yes
                    </label>
                </Grid>
            </Grid>
        );
    };

    const switchOneChecked = switchOne?.isVisible;
    const switchTwoChecked = switchTwo?.isVisible;

    const noneChecked = !switchOneChecked && !switchTwoChecked;
    const bothChecked = switchOneChecked && switchTwoChecked;
    const onlyOneChecked = !bothChecked && !noneChecked;

    const getText = () => {
        if (bothChecked || (switchOneChecked && !switchTwoChecked)) {
            return switchOne.name;
        }
        return switchTwo?.name;
    };

    return (
        <>
            <BorderedSectionWithFooter
                heading={headerIcon}
                footer={getFooter()}
                componentClasses={{
                    root: classNames(classes.borderedSection, classes.marginTop50, classes.flexColumnCentered),
                    heading: classes.fontSize72,
                }}
                intercomTargetProp={intercomTargetPropComponent}
                intercomTargetPropFooter={intercomTargetPropFooter}
            >
                <Grid className={classes.questionsContainer}>
                    {switchOne && renderToggleButton(switchOne)}
                    {switchTwo && renderToggleButton(switchTwo)}
                </Grid>

                {!noneChecked &&
                    <Grid className={classNames(
                        classes.stepperContainer,
                        classes.width100,
                        classes.flexCentred,
                        onlyOneChecked && classes.oneVisible,
                        bothChecked && classes.bothVisible,
                    )}>
                        <Grid className={classNames(classes.width100, classes.relativeContent)}>
                            <Divider className={classes.divider} />

                            <span className={classes.text}>{getText()}</span>

                            <CircleIcon className={classes.circleIcon} />

                            {bothChecked &&
                                <>
                                    <span className={classNames(classes.text, classes.left25)}>
                                        {switchTwo?.name}
                                    </span>
                                    <CircleIcon className={classNames(classes.circleIcon, classes.left25)} />
                                </>
                            }
                        </Grid>
                    </Grid>
                }

                {noneChecked &&
                    <Grid className={classes.stepperContainer}>
                        <Typography color="secondary">
                            This Step will NOT be visible to family
                        </Typography>
                    </Grid>
                }
            </BorderedSectionWithFooter>

            <FamilySharingExplainerDialog
                open={familySharingExplainerDialogOpen}
                zIndex={zIndex}
                fhName={fhName}
                onClose={() => setFamilySharingExplainerDialogOpen(false)}
            />
        </>
    );
};

export default FamilySharing;
