import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

import { ProductManufacturerRequest, ProductManufacturerRecord } from '../../shared/types';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0 24px',
        width: 268,
        margin: ' auto',
        textAlign: 'center',
        '@media (min-width: 400px)': {
            width: 308
        },
    },
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        '@media (min-width: 400px)': {
            width: 360
        },
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
    },
});

interface Props {
    isDialogOpen: boolean;
    existingManufacturer?: ProductManufacturerRecord;
    closeDialog: () => void;
    onManufacturerSave: (manufacturer: ProductManufacturerRequest) => void;
    zIndex: number;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    name: string;
    externalLink: string;
    saveClicked: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent'
    | 'clearIcon' | 'bottomContent' | 'headerText'>;

const INITIAL_STATE: State = {
    name: '',
    externalLink: '',
    saveClicked: false,
};

class ManufacturerDetails extends React.Component<StyledProps & DialogProps, State> {
    state: State = INITIAL_STATE;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { existingManufacturer } = nextProps;
        if (existingManufacturer) {
            this.setState({
                name: existingManufacturer.name,
                externalLink: existingManufacturer.external_link || '',
            });
        }
    }

    saveManufacturer = () => {
        const { onManufacturerSave } = this.props;
        const { name, externalLink } = this.state;

        if (name) {
            this.handleClose();
            onManufacturerSave({
                name,
                external_link: externalLink,
            });
        } else {
            this.setState({
                saveClicked: true,
            });
        }
    };

    handleClose = () => {
        const { closeDialog } = this.props;
        closeDialog();
        this.setState(INITIAL_STATE);
    };

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            externalLink: event.target.value,
        });
    };

    render() {
        const {
            classes,
            isDialogOpen,
            zIndex
        } = this.props;

        const { name, externalLink, saveClicked } = this.state;

        return (
            <div className={classes.root}>
                <Dialog
                    open={isDialogOpen}
                    onClose={this.handleClose}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                    maxWidth="xs"
                >
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classNames(
                            classes.header,
                            classes.backgroundPrimary
                        )}
                    >
                        <ClearIcon
                            className={classNames(classes.clearIcon, classes.colorWhite)}
                            onClick={this.handleClose}
                        />
                        <Typography
                            component="p"
                            noWrap
                            className={classNames(classes.colorWhite, classes.headerText)}
                        >
                            Manufacturer Details
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid
                            container
                            className={classes.marginTop20}
                        >
                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="Manufacturer Name"
                                        name="name"
                                        required
                                        type="text"
                                        value={name}
                                        error={saveClicked && !name}
                                        onChange={this.handleNameChange}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.width100}>
                                <FormControl
                                    className={classNames(
                                        classes.width100,
                                        classes.textLeft,
                                        classes.marginTop20
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        autoComplete="new-password"
                                        label="External Link (optional)"
                                        name="externalLink"
                                        type="text"
                                        value={externalLink}
                                        onChange={this.handleLinkChange}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid
                                className={classes.bottomContent}
                                item
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={() => this.saveManufacturer()}
                                >
                                    SAVE
                                </Button>

                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default compose(withGStyles(styles), withFullScreen('xs'))(ManufacturerDetails) as React.ComponentType<Props>;
