import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import GSwitch from '../common/inputElements/GSwitch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 12,
        maxHeight: 12,
        margin: '10px auto 6px auto',
        width: 'fit-content',
        '& $label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    label: {}
}), { name: 'PublicPrivateSwitch' });

interface Props {
    isPrivate: boolean;
    customClass?: string;
    tooltipTitle?: string;
    handleViewChange: (isPublicView: boolean) => void;
}

const PublicPrivateSwitch = (props: Props) => {
    const { handleViewChange, isPrivate, tooltipTitle, customClass } = props;

    const classes = useStyles();

    return (
        <Tooltip placement="top" title={tooltipTitle}>
            <Grid item xs={12} className={classNames(classes.switchContainer, customClass)}>
                <GSwitch
                    disableRipple
                    checked={isPrivate}
                    label={
                        <Typography color="primary" className={classes.label}>
                            Make event INVITE ONLY
                        </Typography>
                    }
                    onChangeCallBack={handleViewChange}
                />
            </Grid>
        </Tooltip>
    );
};

export default PublicPrivateSwitch;