import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import { UserProfile, UserRole, UserRoles } from '../../shared/types';
import UserAvatar from '../../components/common/UserAvatar';
import { getFormattedPhoneNumber, getFullNameFromUser } from '../../services';
import { SlideTransition } from '../common/Transitions';
import makeGStyles from '../../styles/makeGStyles';
import useFullScreen from '../common/hooks/useFullScreen';

interface Props {
    zIndex: number;
    users: UserProfile[];
    invitedRole: UserRole;
    isDialogOpen: boolean;
    closeDialog: () => void;
    onSelectUser: (user: UserProfile) => void;
}

const useStyles = makeGStyles(theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 480px)': {
                maxWidth: 400,
                width: 400,
                borderRadius: 4,
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        textAlign: 'center',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
    },
    footer: {
        textAlign: 'center',
        width: '100%',
        '& button': {
            margin: 12,
            marginTop: 25,
        },
    },
    avatar: {
        height: 90,
        width: 90,
        margin: '14px auto 8px',
        padding: 0,
    },
    nameInitialsAvatar: {
        height: 90,
        width: 90,
        fontSize: 40,
        fontWeight: 200,
        textTransform: 'uppercase',
        '-webkit-font-smoothing': 'antialiased',
    },
    gridListContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    gridListTileContainer: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        textAlign: 'center',
        width: '50%',
        '-webkit-overflow-scrolling': 'touch',
    },
    avatarCaption: {
        marginBottom: 2,
        fontSize: 13,
        fontWeight: 500,
        width: 110,
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    avatarCaptionSecondary: {
        fontSize: 11,
        fontWeight: 400,
        width: 110,
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        marginTop: 6,
        width: 90,
    },
    invalidRoleText: {
        fontWeight: 400,
        marginTop: 6,
        padding: '0 10px',
    },
}), { name: 'SimilarUsersDialog' });

const SimilarUsersDialog = (props: Props) => {
    const {
        users,
        isDialogOpen,
        zIndex,
        invitedRole,
        closeDialog,
        onSelectUser
    } = props;

    const fullScreen = useFullScreen();
    const classes = useStyles();

    const renderUser = (user: UserProfile) => {
        const hasInvalidRole = invitedRole !== user.role;
        const maybeDisabled = hasInvalidRole ? classes.opacity50 : undefined;

        return (
            // TODO(Jonathan): Consider refactoring this to use the List/ListItem component
            <ImageListItem
                key={user.id}
                className={classes.gridListTileContainer}
            >
                <UserAvatar
                    user={user}
                    photoClassName={classNames(classes.avatar, maybeDisabled)}
                    initialsClassName={classNames(classes.nameInitialsAvatar, maybeDisabled)}
                    size={90}
                />
                <Typography
                    color="secondary"
                    variant="caption"
                    align="center"
                    noWrap={false}
                    className={classNames(
                        classes.avatarCaption,
                        classes.textCapitalize,
                        maybeDisabled,
                    )}
                >
                    {getFullNameFromUser(user)}
                </Typography>
                {user.email &&
                    <Typography
                        color="secondary"
                        variant="caption"
                        align="center"
                        noWrap={false}
                        className={classNames(classes.avatarCaptionSecondary, maybeDisabled)}
                    >
                        {user.email}
                    </Typography>
                }
                {user.phone &&
                    <Typography
                        color="secondary"
                        variant="caption"
                        align="center"
                        noWrap={false}
                        className={classNames(classes.avatarCaptionSecondary, maybeDisabled)}
                    >
                        {getFormattedPhoneNumber(user.phone)}
                    </Typography>
                }
                <Typography
                    color="secondary"
                    variant="caption"
                    align="center"
                    noWrap={false}
                    className={classNames(
                        classes.avatarCaptionSecondary,
                        hasInvalidRole ? classes.colorRed : maybeDisabled,
                    )}
                >
                    {UserRoles.displayRoleGroup(user.role)}
                </Typography>
                {hasInvalidRole &&
                    <Typography
                        color="secondary"
                        variant="caption"
                        align="center"
                        noWrap={false}
                        className={classNames(classes.invalidRoleText, classes.colorRed)}
                    >
                        Cannot invite someone with a different role
                    </Typography>
                }
                {!hasInvalidRole &&
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            onSelectUser(user);
                            closeDialog();
                        }}
                        className={classes.button}
                    >
                        Invite
                    </Button>
                }
            </ImageListItem>
        );
    };


    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.root}
            classes={{
                paper: classes.dialogPaper,
            }}
            style={{ zIndex }}
        >
            <DialogTitle
                id="alert-dialog-slide-title"
                className={classes.dialogHeader}
            >
                It looks like the information you entered matches someone else. What would you like to do?
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container>
                    <Grid item xs={12} className={classes.gridListContainer}>
                        {users.map(renderUser)}
                    </Grid>

                    <Grid item className={classes.footer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.button}
                            onClick={closeDialog}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SimilarUsersDialog;
