import { CSSProperties, memo } from "react";

import { getDecedentAge, getFullNameFromCase, joinNameParts } from "../../../services";
import { GatherCaseUX } from "../../../shared/types";
import UserAvatar from "../../common/UserAvatar";
import { useGSelector } from "../../../types";
import { alpha } from "@mui/material/styles";
import { buildQRCodeLink } from '../utils';

type StylesKey = 'root' | 'writingVerticalRL' | 'keepTrackKey' | 'rightSection' | 'imageSize' | 'userAvatar'
    | 'zIndex1' | 'textEllipsis' | 'printColorAdjust' | 'lineHeight' | 'widthFitContent' | 'fontSize22'
    | 'flexCentred' | 'background_primary_0_0_8' | 'width100' | 'paragraphLineHeight' | 'colorPrimary';
type Styles = Record<StylesKey, CSSProperties>;

const useStyles = (): Styles => {
    const muiTheme = useGSelector(({ whiteLabel }) => whiteLabel.muiTheme);

    return {
        root: {
            margin: '0 auto',
            height: 124,
            boxShadow: muiTheme.shadows[6],
        },
        writingVerticalRL: {
            writingMode: 'vertical-rl'
        },
        keepTrackKey: {
            transform: 'rotate(180deg)',
            lineHeight: 1.75,
            height: '100%',
            display: 'inline-block',
            textAlign: 'center',
            margin: 'auto'
        },
        rightSection: {
            padding: '4px 4px 4px 0'
        },
        imageSize: {
            width: 92,
            height: 92,
            margin: '-6px 0'
        },
        userAvatar: {
            width: 80,
            height: 80,
            fontSize: 28,
            fontWeight: 300,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            background: '#bdbdbd',
            color: '#fff'
        },
        zIndex1: {
            position: 'relative',
            zIndex: 1,
        },
        textEllipsis: {
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        printColorAdjust: {
            printColorAdjust: 'exact',
            WebkitPrintColorAdjust: 'exact',
        },
        lineHeight: {
            lineHeight: 1.125
        },
        widthFitContent: {
            width: 'fit-content'
        },
        fontSize22: {
            fontSize: 22
        },
        flexCentred: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        background_primary_0_0_8: {
            background: alpha(muiTheme.palette.primary.main, 0.08)
        },
        width100: {
            width: '100%'
        },
        paragraphLineHeight: {
            lineHeight: 1.25
        },
        colorPrimary: {
            color: muiTheme.palette.primary.main
        }
    };
};

interface Props {
    selectedCase: GatherCaseUX;
    printMode: boolean;
    rootStyles?: CSSProperties;
}

/**
 * Note: Don't use MUI classes/Components for styling for this component,
 * use only Intrinsic Elements and inline styling 
 */
const QRSticker = ({ selectedCase, printMode, rootStyles }: Props) => {
    const { bDate, dDate, age } = getDecedentAge(selectedCase);
    const qrcode = buildQRCodeLink({ caseName: selectedCase.name, organize: true });

    const styles = useStyles();

    return (
        <div
            style={{
                ...styles.root,
                ...rootStyles,
                boxShadow: printMode ? 'none' : styles.root.boxShadow,
                height: printMode ? '1in' : styles.root.height,
                width: '100%',
                flexWrap: 'nowrap',
                display: 'flex',
                maxWidth: !printMode ? 324 : undefined
            }}
        >
            <div
                style={{
                    ...styles.flexCentred,
                    ...styles.printColorAdjust,
                    ...styles.widthFitContent,
                    ...styles.background_primary_0_0_8,
                    minWidth: 42,
                }}
            >
                <p
                    style={{
                        ...styles.keepTrackKey,
                        ...styles.writingVerticalRL,
                        ...styles.fontSize22,
                        ...styles.colorPrimary,
                        fontSize: printMode ? 14 : 20,
                        width: 'fit-content',
                        margin: 0
                    }}
                >
                    {selectedCase.keeptrack_key}
                </p>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 1,
                    flex: 2,
                    maxWidth: printMode ? 'calc(100% - 84px)' : undefined,
                    padding: printMode ? '0 2px' : '0 12px',
                    boxSizing: 'border-box'
                }}
            >
                <div style={{ ...styles.zIndex1, ...styles.width100 }}>
                    <p style={{
                        margin: 0,
                        textAlign: 'center',
                        ...styles.colorPrimary,
                        ...styles.textEllipsis,
                        lineHeight: printMode ? '16px' : 1.125,
                        fontSize: printMode ? 14 : 16
                    }}>
                        {getFullNameFromCase(selectedCase)}{age && `, ${age}`}
                    </p>

                    <p
                        style={{
                            textTransform: 'uppercase',
                            minHeight: 12,
                            lineHeight: '12px',
                            margin: 0,
                            fontSize: printMode ? 10 : 12,
                            textAlign: 'center',
                            ...styles.colorPrimary,
                            ...styles.textEllipsis
                        }}
                    >
                        {bDate && dDate &&
                            `${bDate.format('D MMM YYYY')} - ${dDate.format('D MMM YYYY')}`}
                    </p>
                </div>

                <div
                    style={{
                        ...styles.widthFitContent,
                        ...styles.flexCentred,
                        flexWrap: 'nowrap'
                    }}
                >
                    <img
                        src={qrcode}
                        alt="Case QR Code"
                        style={{
                            ...styles.imageSize,
                            width: printMode ? 66 : styles.imageSize.width,
                            height: printMode ? 66 : styles.imageSize.height
                        }}
                    />

                    <UserAvatar
                        user={selectedCase}
                        size={80}
                        imgProps={{
                            width: '100%',
                            height: '100%',
                            style: { objectFit: 'cover' }
                        }}
                        style={{
                            ...styles.userAvatar,
                            width: printMode ? 52 : styles.userAvatar.width,
                            height: printMode ? 52 : styles.userAvatar.height,
                            fontSize: printMode ? 20 : styles.userAvatar.fontSize,
                        }}
                    />
                </div>

                <div style={{ padding: "1px 4px 0", ...styles.width100, ...styles.zIndex1 }}>
                    <p
                        style={{
                            ...styles.textEllipsis,
                            ...styles.lineHeight,
                            ...styles.colorPrimary,
                            fontSize: printMode ? 10 : 12,
                            margin: 0,
                            textAlign: 'center'
                        }}
                    >
                        {selectedCase.funeral_home.name} ({selectedCase.funeral_home.city})
                    </p>
                </div>
            </div>

            <div
                style={{
                    ...styles.rightSection,
                    ...styles.background_primary_0_0_8,
                    ...styles.width100,
                    ...styles.printColorAdjust,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    flex: 0,
                    minWidth: 42,
                    maxWidth: 42,
                    boxSizing: 'border-box'
                }}
            >
                <p
                    style={{
                        height: '100%',
                        textAlign: 'center',
                        margin: 0,
                        lineHeight: 1.5,
                        fontSize: printMode ? 10 : 12,
                        ...styles.width100,
                        ...styles.textEllipsis,
                        ...styles.writingVerticalRL,
                        ...styles.colorPrimary,
                    }}
                >
                    {joinNameParts(selectedCase.assignee)}
                </p>

                <p
                    style={{
                        textAlign: 'center',
                        minHeight: 18,
                        lineHeight: 1,
                        fontSize: printMode ? 10 : 12,
                        ...styles.textEllipsis,
                        ...styles.writingVerticalRL,
                        ...styles.colorPrimary,
                        margin: 0
                    }}
                >
                    {selectedCase.case_number || <>&nbsp;</>}
                </p>
            </div>
        </div>
    );
};

export default memo(QRSticker);