import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import CloseAccountComponent from './CloseAccountComponent';
import { AMAZON_ICON, APPLE_ICON, HULU_ICON, NETFLIX_ICON, PANDORA_ICON, SPOTIFY_ICON } from './OnlineAccountIcons';
import { OnlineAccountType } from './utils';

const SocialSecurityDialog = (props: TaskDialogComponentProps) => {
    const { activeCase, userData, setAppSnackbar } = props;

    const description = (
        <>
            Saving you and your family money is important to us. Like most people,
            {activeCase.fname} probably had a number of active online accounts and we want to help you and
            your family close them as quickly as possible. Simple instructions are listed below.
            As always, <FhOrAssigneePhoneNumberLink activeCase={activeCase} /> to call
            me directly if you have questions.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: `Cancel ${activeCase.fname}'s Amazon Prime`,
        icon: AMAZON_ICON,
        content: (
            <CloseAccountComponent
                userData={userData}
                activeCase={activeCase}
                accountType={OnlineAccountType.Amazon}
                setAppSnackbar={setAppSnackbar}
            />
        ),
    }, {
        id: 2,
        icon: APPLE_ICON,
        label: `Close ${activeCase.fname}'s Apple Account`,
        content: (
            <CloseAccountComponent
                userData={userData}
                activeCase={activeCase}
                accountType={OnlineAccountType.Apple}
                setAppSnackbar={setAppSnackbar}
            />
        )
    }, {
        id: 3,
        label: `Close ${activeCase.fname}'s Hulu Account`,
        icon: HULU_ICON,
        content: (
            <CloseAccountComponent
                userData={userData}
                activeCase={activeCase}
                accountType={OnlineAccountType.Hulu}
                setAppSnackbar={setAppSnackbar}
            />
        )
    }, {
        id: 4,
        label: `Close ${activeCase.fname}'s Netflix Account`,
        icon: NETFLIX_ICON,
        content: (
            <CloseAccountComponent
                userData={userData}
                activeCase={activeCase}
                accountType={OnlineAccountType.Netflix}
                setAppSnackbar={setAppSnackbar}
            />
        )
    }, {
        id: 5,
        label: `Close ${activeCase.fname}'s Pandora Account`,
        icon: PANDORA_ICON,
        content: (
            <CloseAccountComponent
                userData={userData}
                activeCase={activeCase}
                accountType={OnlineAccountType.Pandora}
                setAppSnackbar={setAppSnackbar}
            />
        )
    }, {
        id: 6,
        label: `Close ${activeCase.fname}'s Spotify Account`,
        icon: SPOTIFY_ICON,
        content: (
            <CloseAccountComponent
                userData={userData}
                activeCase={activeCase}
                accountType={OnlineAccountType.Spotify}
                setAppSnackbar={setAppSnackbar}
            />
        ),
    }];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: `Close ${activeCase.fname}'s Online Accounts`,
                description,
                listItems
            }}
        />
    );
};

export default SocialSecurityDialog;
