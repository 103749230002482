import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';

import { UserRoles } from '../../../../../shared/types';

import { NULL_STRING } from '../../../../../constants';

import {
    getFullNameFromCase,
    emptyStringParser,
    getFullNameFromUser,
    getFormattedPhoneNumber
} from '../../../../../services';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { CanNotLoginProps } from '../utils';
import CanNotLoginWrapper from '../widgets/CanNotLoginWrapper';

const CanNotLogin = (props: CanNotLoginProps) => {
    const { activeCase, onStepChange, setAppSnackbar, userData } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Text copied to your clipboard', 'success');
    };

    const renderEmailBody = (
        _birthDate: string,
        _caseAddress: string,
        _caseZipCode: string,
        _helperName: string,
        _helperEmail: string,
        _helperPhone: string
    ): string => {
        return (
            `${getFullNameFromCase(activeCase)}, a Spotify customer, recently passed away ` +
            'and we need to delete their Spotify account and cancel any pending subscriptions,' +
            'effective immediately. The ' +
            `required information is listed below.

- Account holder's name: ${getFullNameFromCase(activeCase)}
- ${activeCase.fname}’s Birth Date: ${_birthDate}
- ${activeCase.fname}’s Zip Code: ${_caseZipCode}
- ${activeCase.fname}’s email: ${NULL_STRING}
- ${activeCase.fname}’s most recent address: ${_caseAddress}
- My name: ${_helperName}
- My email: ${_helperEmail}
- My phone number: ${_helperPhone}

Please let me know via email or phone regarding any additional information you may need.

Thanks for your help in this important matter,
${_helperName}`
        );
    };

    const isFhUser: boolean = UserRoles.isFHUser(userData);

    const birthDate = activeCase.dob_date !== '' ? activeCase.dob_date : NULL_STRING;

    const caseAddress: string = activeCase.death_certificate
        && emptyStringParser(activeCase.death_certificate.life.residencePlace.description)
        ? activeCase.death_certificate.life.residencePlace.description : NULL_STRING;

    const caseZipCode: string = activeCase.death_certificate
        && activeCase.death_certificate.life.residencePlace.postalCode
        && emptyStringParser(activeCase.death_certificate.life.residencePlace.postalCode)
        ? activeCase.death_certificate.life.residencePlace.postalCode : NULL_STRING;

    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;
    const helperEmail: string = userData && userData.email || NULL_STRING;

    let helperPhone: string = NULL_STRING;
    if (userData && userData.phone) {
        const phoneNumber = getFormattedPhoneNumber(userData.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    } else if (isFhUser && activeCase.funeral_home.phone) {
        const phoneNumber = getFormattedPhoneNumber(activeCase.funeral_home.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    }

    const emailBody: string = renderEmailBody(
        birthDate || '',
        caseAddress,
        caseZipCode,
        helperName,
        helperEmail,
        helperPhone
    );
    const tooltipText = `This will copy this text to your device clipboard so that you can paste it 
                            in another program.`;

    return (
        <CanNotLoginWrapper onReturnClick={onStepChange}>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize28,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="center"
            >
                Contact Spotify
            </Typography>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                It looks like you won't be able to log in to {activeCase.fname}'s Spotify account. No worries, we
                can still help. You will need to contact Spotify directly by following the instructions below.
            </Typography>
            <ul
                className={classNames(
                    classes.fontSize18,
                    classes.colorSecondary,
                    classes.textLeft,
                    classes.fontWeight300
                )}
            >
                <li>
                    <a
                        href="https://support.spotify.com/us/contact-spotify-anonymous/"
                        target="blank"
                        className={classes.colorSecondary}
                    >
                        Click here
                    </a>
                    {' to initiate a chat with Spotify'}
                </li>
                <li className={classes.marginTop10}>
                    Select "Account"
                </li>
                <li className={classes.marginTop10}>
                    Select "Other"
                </li>
                <li className={classes.marginTop10}>
                    Fill out the form using {activeCase.fname}'s information
                </li>
                <li className={classes.marginTop10}>
                    To try to help as much as possible, here is the message you can include in the last box:
                </li>
            </ul>
            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    {activeCase.fname} {activeCase.lname}, a Spotify customer, recently passed away and we need
                    to delete their Spotify account and cancel any pending subscriptions, effective immediately.
                    The required information is listed below.
                </Typography>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        - Account holder's name: <span className={classes.fontWeight500}>
                            {getFullNameFromCase(activeCase)}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s Birth Date: <span className={classes.fontWeight500}>
                            {birthDate}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s Zip Code: <span className={classes.fontWeight500}>
                            {caseZipCode}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s email: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}’s most recent address: <span className={classes.fontWeight500}>
                            {caseAddress}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My name: <span className={classes.fontWeight500}>{helperName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My email: <span className={classes.fontWeight500}>{helperEmail}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My phone number: <span className={classes.fontWeight500}>{helperPhone}</span>
                    </span>
                </Typography>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        Please let me know via email or phone regarding any additional information you may need.
                    </span>

                    <span
                        className={classNames(
                            classes.displayBlock,
                            classes.marginTop20
                        )}
                    >
                        Thanks for your help in this important matter,
                        <span className={classes.displayBlock}>
                            {`${userData ? userData.fname : ''} 
                                ${userData ? userData.lname : ''}`}
                        </span>
                    </span>
                </Typography>
            </Grid>
            <Grid>
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.button}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this text
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>
        </CanNotLoginWrapper>
    );
};

export default CanNotLogin;