import { Grid, Theme, Typography } from "@mui/material";
import GGroupButtons from "../../../common/GGroupButtons";
import { ProductCategory, ProductCategoryDisplayLookup, ProductView } from "../../../../shared/types";
import { makeStyles } from "@mui/styles";
import { convertHexToRGBA } from "../../../../services";

interface Props {
    category: ProductCategory;
    productView: ProductView;
    setProductView: React.Dispatch<React.SetStateAction<ProductView>>;
}

const useStyles = makeStyles((theme: Theme) => ({
    activebtnView: {
        background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        color: theme.palette.primary.main,
        '&:hover': {
            background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        }
    },
}), { name: 'AllProductTab' });

const ProductViewButtons = (props: Props) => {
    const { productView, setProductView, category } = props;
    const classes = useStyles();

    const tableViewTooltip = `This view allows you to see your products in a convenient table format. 
You can sort, filter, and group the table to isolate the products and properties you need to view.`;

    const gridViwTooltip = `This view emulates what is shown in your actual digital Showroom, 
giving you a convenient way to see the effects of any edits you make.`;
    const categoryName = category ? ProductCategoryDisplayLookup[category] : '';

    return (
        <Grid
            container
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
        >
            <Grid item mb={0.5}>
                <Typography
                    fontSize={18}
                    color='primary'
                    fontWeight={300}
                >{categoryName}</Typography>
            </Grid>
            <Grid item mb={2} sx={{
                '& button:first-of-type': {
                    borderRadius: '5px 0 0 5px',
                },
                '& button:last-of-type': {
                    borderRadius: '0 5px 5px 0',
                }
            }}>
                <GGroupButtons
                    buttons={[
                        { label: 'Table View', value: ProductView.table_view, tooltip: tableViewTooltip },
                        { label: 'Grid View', value: ProductView.grid_view, tooltip: gridViwTooltip }
                    ]}
                    activeClass={classes.activebtnView}
                    activeButton={productView}
                    onClick={(v: ProductView) => setProductView(v)}
                />
            </Grid>
        </Grid>
    );
};

export default ProductViewButtons;
