import classNames from "classnames";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import FuneralReimbursementLogo from "./FuneralReimbursementLogo";
import { useTaskDialogStyles } from "../TaskDialogsStyles";
import { PropsWithChildren } from "react";

interface Props {
    heading?: string;
    question: string;
    onAccept: () => void;
    onDeny: () => void;
}

const FRQuestion = (props: PropsWithChildren<Props>) => {
    const { children, heading, question, onAccept, onDeny } = props;

    const classes = useTaskDialogStyles();

    return (
        <Grid item xs={12}>
            <Grid
                item
                xs={12}
                sm={10}
                className={classNames(
                    classes.marginAuto,
                    classes.textCenter,
                    classes.marginBottom20,
                    classes.secondStateMainGrid
                )}
            >
                <FuneralReimbursementLogo />

                <Grid>
                    {heading &&
                        <Typography
                            color="secondary"
                            align="left"
                            className={classNames(
                                classes.fontSize18,
                                classes.fontWeight300
                            )}
                        >
                            {heading}
                        </Typography>
                    }

                    <Typography
                        color="secondary"
                        align="center"
                        className={classNames(
                            classes.fontSize28,
                            classes.fontWeight300,
                            classes.marginTop50
                        )}
                    >
                        {question}
                    </Typography>

                    <Grid container className={classes.cancelAccConfirmationBtn}>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={onAccept}
                                className={classes.margin_8}
                            >
                                YES
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={onDeny}
                                className={classes.margin_8}
                            >
                                NO
                            </Button>
                        </Grid>
                    </Grid>

                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FRQuestion;