import classNames from "classnames";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { GStyles } from "../../../../../styles/GStyles";
import GAnchorWithTooltip from "../../../../common/GAnchorWithTooltip";
import UploadPhotosSVG from "../../../../taskTemplates/svg/UploadPhotosSVG";
import styled from "@emotion/styled";
import { SxProps, Theme } from "@mui/material";
import { getIntercomTargetProp } from "../../../../../services";

export const PhotoPreservedText = ({
    fhName,
    fhWebsiteUrl,
    disabledFbButton
}: { fhWebsiteUrl: string | null; disabledFbButton: boolean; fhName: string }) => {
    const tooltipTitle = fhWebsiteUrl
        && 'Click here to learn more about the services we offer.'
        || '';

    return (
        <GAnchorWithTooltip
            target="_blank"
            href={!disabledFbButton ? fhWebsiteUrl || '' : ''}
            disabled={!fhWebsiteUrl || disabledFbButton}
            linkClass={classNames((fhWebsiteUrl && !disabledFbButton) && GStyles.clickable)}
            tooltipProps={{
                title: tooltipTitle,
                enterDelay: 600,
            }}
            sx={{
                color: theme => theme.palette.primary.main,
                display: 'block',
                margin: '0 auto',
                width: 'fit-content',
            }}
        >
            <Typography
                color="primary"
                align="center"
                {...getIntercomTargetProp(`RememberPage-HangingPhotos-HangingPhotosPreservedText`)}
            >
                Photos preserved by<br />
                <span className={GStyles.fontWeight400}>{fhName}</span>
            </Typography>
        </GAnchorWithTooltip>
    );
};

const FlexColumn = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center'
});

const styles: Record<'root' | 'textContainer' | 'rememberingText', SxProps<Theme>> = {
    root: {
        maxWidth: 300,
        margin: '44px auto 0',
        display: 'flex',
        '& svg': {
            height: 116,
        },
    },
    textContainer: {
        '& p:nth-of-type(2)': {
            fontSize: 16,
            lineHeight: 1.25,
            fontWeight: 200,
            maxWidth: 300,
            marginBottom: '32px'
        },
        '& p:last-child': {
            lineHeight: '22px',
            fontWeight: 300,
            display: 'none',
            '@media (min-width: 960px)': {
                display: 'block'
            }
        }
    },
    rememberingText: {
        margin: '8px 0 8px',
        fontWeight: 300,
        fontSize: 22,
    }
};

interface Props {
    fhWebsiteUrl: string | null;
    caseFname: string;
    fhName: string;
    disabledFbButton: boolean;
}

const MiddleSection = ({ fhWebsiteUrl, caseFname, fhName, disabledFbButton }: Props) => {
    return (
        <FlexColumn
            item
            sx={styles.root}
        >
            <div>
                <UploadPhotosSVG themeFillClass={GStyles.fillPrimary} />
            </div>
            <FlexColumn
                item
                xs={12}
                sx={styles.textContainer}
            >
                <Typography
                    color="secondary"
                    align="center"
                    sx={styles.rememberingText}
                >
                    Remembering {caseFname}
                </Typography>

                <Typography color="secondary" align="center">
                    {disabledFbButton
                        ? `Everyone will love and appreciate seeing your unique photos of ${caseFname}`
                        : `Everyone's favorite photos of ${caseFname} will be 
                        displayed here for all to enjoy.`
                    }
                </Typography>

                <PhotoPreservedText
                    fhName={fhName}
                    fhWebsiteUrl={fhWebsiteUrl}
                    disabledFbButton={disabledFbButton}
                />
            </FlexColumn>
        </FlexColumn>
    );
};

export default MiddleSection;