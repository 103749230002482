export interface IndexFileCaseDetails {
    casePhoto: string | null;
    caseName: string | null;
    caseFname: string | null;
    caseLname: string | null;
    displayName: string | null;
    themeColor: string | null;
    fhLogo: string | null;
}

export const getCaseLoadingPage = ({
    caseFname,
    caseLname,
    displayName,
    themeColor,
    casePhoto,
    fhLogo,
}: IndexFileCaseDetails) => {
    const initials = (caseFname && caseFname.substring(0, 1) || '') + (caseLname && caseLname.substring(0, 1) || '');

    return `
        <div class="CaseLoadingPage-root" ${themeColor ? `style="background: ${themeColor};` : ''}">
            <div class="CaseLoadingPage-topLoader">
                <div class="CaseLoadingPage-wrapper">
                    <svg class="CaseLoadingPage-lockIcon" focusable="false" viewBox="0 0 24 24"
                        aria-hidden="true" role="presentation">
                        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 
                            2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 
                            2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z">
                        </path>
                    </svg>
                    <div class="MuiCircularProgress-indeterminate CaseLoadingPage-fabProgress"
                        role="progressbar" style="width: 40px; height: 40px;">
                        <svg viewBox="22 22 44 44">
                            <circle class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
                                cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="CaseLoadingPage-logoOuter">
                <img src="${fhLogo || ''}" alt="fh-logo">
            </div>
            <div class="CaseLoadingPage-userDetails">
                <p
                    class="CaseLoadingPage-loadingMemorialPage">
                    Loading ${caseFname ? `${caseFname}'s ` : ''}Memorial Page...
                </p>
                <div class="CaseLoadingPage-avatarContainer">
                    <div class="CaseLoadingPage-avatar"
                        style="color: ${themeColor || 'inherit'}; position: relative; background: none;">
                        <div class="CaseLoadingPage-avatar" style="color: ${themeColor || 'inherit'}; border: none;">
                            ${casePhoto
            ? `<img alt="${initials}" src="${casePhoto}">`
            : initials
        }
                        </div>
                    </div>
                </div>
                <p class="CaseLoadingPage-displayName">
                    ${displayName || ''}
                </p>
            </div>
        </div>
    `;
};