import * as React from 'react';
import classNames from 'classnames';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import TaskAssignAvatar from './TaskAssignAvatar';
import {
    CaseTaskUX,
    UserRoles,
    userToTaskAssignee,
    GatherCaseUX,
    TaskUpdateRequestUX,
    TaskState,
    EntityCaseRole,
    EntitySummary,
    TaskTemplateType,
    getGatherCaseId,
    TaskAssignee,
    TaskType
} from '../../shared/types';
import { StoreState } from '../../types';

import { styleWrapper as AssignStyles, AssignStyleProps } from './Style';

import { openHelperInvitationDialog, openTeamInvitationDialog } from '../../actions/Dialog.action';
import { updateTask } from '../../actions/task/Task.action';
import {
    canInviteOtherTeamMembers,
    canViewCaseAutoforms,
    canViewCaseFinancialData,
    canViewCaseVitalsForAllCases,
} from '../../shared/authority/can';
import { AppDispatch } from '../../store';
import withState from '../common/utilHOC/WithState';
import AssignmentPopperForEntitySummary from './AssignmentPopperForEntitySummary';
import { TOOLTIPS } from '../../constants';
import withStyles from '@mui/styles/withStyles';
import { GStyles } from '../../styles/GStyles';
import { getIntercomTargetProp, joinNameParts } from '../../services';

function mapStateToProps({ userSession, casesState, teamState }: StoreState) {
    const { publicCase, selectedCase, helpers } = casesState;
    const gatherCaseId = getGatherCaseId(selectedCase, publicCase);
    return {
        userData: userSession.userData,
        invitedHelpers: helpers.filter(
            (helper) => UserRoles.isInvitedFamilyOnCase(helper, gatherCaseId)),
        team: teamState.team,
    };
}

export type AssigneeRelatedTaskFields = Pick<CaseTaskUX,
    'id' |
    'type' |
    'assigned_to' |
    'assigned_to_all' |
    'can_assign_multiple' |
    'event_id' |
    'visible_to_family' |
    'state' |
    'template_type'
>;

interface Props extends ReturnType<typeof mapStateToProps> {
    onChangeTask?: (...params: Parameters<typeof updateTask>) => void;
    onlyHelpers?: boolean;
    task: AssigneeRelatedTaskFields;
    activeCase: GatherCaseUX;
    showAssignTaskLink?: boolean;
    openPopoverElement?: JSX.Element;
    zIndex: number;
    assignmentWidgetAnchorEle?: HTMLElement | null;
    taskLinkTextClass?: string;
    dispatch: AppDispatch;
    setChildPopperState?: (isChildPopperOpen: boolean) => void;
    closeMenu?: () => void;
    closeAssignmentWidget?: () => void;
    resetInvitationForm?: () => void;
    intercomTargetProp?: string;
}

interface State {
    menuOpen: boolean;
    anchorEl: HTMLElement | null;
    assignees: EntitySummary[];
}

type CombinedProps = Props & AssignStyleProps;

class AssignTask extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            menuOpen: false,
            anchorEl: null,
            assignees: []
        };
    }

    componentDidUpdate(prevProps: CombinedProps, prevState: State) {
        const { assignmentWidgetAnchorEle, invitedHelpers, team } = this.props;

        if (assignmentWidgetAnchorEle && !prevProps.assignmentWidgetAnchorEle
            || this.state.anchorEl && !prevState.anchorEl
            || team.length !== prevProps.team.length
            || invitedHelpers.length !== prevProps.invitedHelpers.length
        ) {
            this.setState({
                assignees: this.taskAssigneeMapper()
            });
        }
    }

    setOpenMenu(menuOpen: boolean, event: React.MouseEvent<HTMLElement> | null = null) {
        const { setChildPopperState } = this.props;

        this.setState({
            menuOpen,
            anchorEl: (event === null) ? null : event.currentTarget as HTMLElement,
        });

        if (setChildPopperState) {
            setChildPopperState(menuOpen);
        }
    }

    renderOpenPopoverElement = () => {
        const {
            showAssignTaskLink,
            closeMenu,
            classes,
            task,
            openPopoverElement,
            taskLinkTextClass,
            intercomTargetProp
        } = this.props;

        const isTrackingStep = task.type === TaskType.tracking_step;

        const isIncompleTask = task.state === TaskState.incomplete;
        const isTaskComplete = task.state === TaskState.complete;
        const isTaskSkipped = task.state === TaskState.skipped;
        const taskCompleteTooltip = isTaskComplete
            ? `Uncomplete this ${isTrackingStep ? 'step' : 'task'} to change the assignment`
            : '';
        const taskSkippedTooltip = isTaskSkipped
            ? `Unskip this ${isTrackingStep ? 'step' : 'task'} to change the assignment`
            : '';

        if (openPopoverElement) {
            return (
                <div onClick={(e) => {
                    e.persist();
                    if (isIncompleTask) {
                        this.setOpenMenu(true, e);
                    }
                }}>
                    {openPopoverElement}
                </div>
            );
        }

        let assignUserText: string | JSX.Element;
        let assignedToStr: string | null = null;

        if (task.assigned_to_all === true) {
            assignedToStr = 'Everyone';
        } else if (task.assigned_to.length > 0) {
            assignedToStr = (task.assigned_to.length === 1)
                ? joinNameParts(task.assigned_to[0])
                : `${task.assigned_to.length} people`;
        }

        if (task.event_id) {
            assignUserText = !assignedToStr ? 'Invite family to conference' : (
                <span>
                    <span className={GStyles.textUnderline}>
                        {assignedToStr}
                    </span>
                    &nbsp;{`invited`}
                </span>
            );
        } else {
            assignUserText = !assignedToStr ? task.visible_to_family && !isTrackingStep ?
                'Click to assign this task' :
                'Assign to team member' : (
                <span>
                    {`Assigned to`}&nbsp;
                    <span
                        className={classNames(
                            (isTaskComplete || isTaskSkipped) && GStyles.textDecorationNone,
                            GStyles.textUnderline
                        )}>
                        {assignedToStr}
                    </span>
                </span>
            );
        }

        return (
            <>
                {showAssignTaskLink ?
                    <Tooltip
                        title={taskCompleteTooltip || taskSkippedTooltip}
                        placement="top"
                        enterDelay={1200}
                    >
                        <Typography
                            component="span"
                            className={classNames(
                                classes.widthFitContent,
                                showAssignTaskLink && !closeMenu ?
                                    classes.assignLink : classes.assignTaskMenu,
                                isIncompleTask ? classes.assignLinkHover : '',
                                isTaskComplete ? classes.fadedTextWithOpacity : '',
                                isTaskSkipped ? classes.fadedTextWithOpacity : '',
                                taskLinkTextClass
                            )}
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                                e.persist();
                                if (isIncompleTask) {
                                    this.setOpenMenu(true, e);
                                }
                            }}
                            {...getIntercomTargetProp(intercomTargetProp)}
                        >
                            {closeMenu ? `Assign ${isTrackingStep ? 'Step' : 'Task'}` : assignUserText}
                        </Typography>
                    </Tooltip>
                    :
                    <Button
                        onClick={(e) => {
                            e.persist();
                            this.setOpenMenu(true, e);
                        }}
                    >
                        <TaskAssignAvatar
                            assigned_to={task.assigned_to}
                            assigned_to_all={task.assigned_to_all}
                        />
                        <Typography
                            className={classes.assignButton}
                            variant="caption"
                            color="secondary"
                        >
                            {assignUserText}
                        </Typography>
                    </Button>
                }
            </>
        );
    };

    taskAssigneeMapper = () => {
        const { invitedHelpers, team, task } = this.props;

        const assignables = [...invitedHelpers, ...team];
        const mappedData = assignables.filter(a => Boolean(task.assigned_to.find(at => at.entity_id === a.entity_id)));

        return mappedData;
    };

    filterTeam = (): EntitySummary[] => {
        const {
            team,
            activeCase,
            task,
        } = this.props;
        return team.filter(teamMember => {
            switch (task.template_type) {
                case TaskTemplateType.death_certificate:
                    return canViewCaseVitalsForAllCases(teamMember, activeCase.funeral_home_id);
                case TaskTemplateType.signature_packet:
                case TaskTemplateType.form_dd_214:
                    return canViewCaseAutoforms(teamMember, activeCase.funeral_home_id);
                case TaskTemplateType.goods_and_services:
                    return canViewCaseFinancialData(teamMember, activeCase.funeral_home_id);
                default:
                    return true;
            }
        });
    };

    render() {
        const {
            onlyHelpers,
            invitedHelpers,
            userData,
            activeCase,
            zIndex,
            task,
            assignmentWidgetAnchorEle,
            closeAssignmentWidget,
        } = this.props;

        const { anchorEl, assignees } = this.state;
        // filter out FH team based on Team Members that have permission for that task
        const filteredTeam = this.filterTeam();
        const isTrackingStep = task.type === TaskType.tracking_step;
        const isFamilySelect: boolean = !isTrackingStep && task.visible_to_family;

        // helpers see other helpers
        // team sees team + helpers if the task is not marked as onlyHelpers === true
        // ... otherwise team sees other team members
        let assignables: EntitySummary[];

        if (!onlyHelpers && UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home_id)) {
            if (isFamilySelect) {
                assignables = [...filteredTeam, ...invitedHelpers];
            } else {
                assignables = filteredTeam;
            }
        } else {
            assignables = invitedHelpers;
        }

        const userCanInviteOtherTeamMembers = canInviteOtherTeamMembers(
            userData,
            activeCase.funeral_home_id
        );

        return (
            <AssignmentPopperForEntitySummary
                key={`assignTaskPopper_${task.id}`}
                anchorElement={anchorEl || assignmentWidgetAnchorEle || undefined}
                zIndex={zIndex}
                items={assignables}
                handleClose={() => {
                    if (closeAssignmentWidget) {
                        closeAssignmentWidget();
                    }
                    this.closePopper();
                }}
                setAssignee={(assignee, assignedTo, assignedToAll) =>
                    this.updateTask(assignedTo || [], assignedToAll || false)
                }
                headerText={isFamilySelect
                    ? `Assign this ${isTrackingStep ? 'step' : 'task'} to...`
                    : 'Assign to team member...'}
                addButtonText={isFamilySelect ? 'Add Helper' : 'Invite Team Member'}
                addButtonDisabled={isFamilySelect ? false : !userCanInviteOtherTeamMembers}
                addButtonDisabledToolTip={isFamilySelect ? ''
                    : TOOLTIPS.DISABLED_FEATURE
                }
                onAdd={this.handleInviteNewHelperClick}
                popoverElement={!assignmentWidgetAnchorEle && this.renderOpenPopoverElement() || undefined}
                isMultiSelect
                assignedTo={assignees}
                assignedToAll={task.assigned_to_all}
                canAssignToAll
                onAddTeamMember={this.handleNewTeamInvitationClick}
                showFamilyHelpers={isFamilySelect}
                showTeamMembers={!onlyHelpers && UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home_id)}
            />
        );
    }

    closePopper = () => {
        const { closeMenu, setChildPopperState } = this.props;

        this.setState({ anchorEl: null });
        if (closeMenu) {
            closeMenu();
        }
        if (setChildPopperState) {
            setChildPopperState(false);
        }
    };

    handleInviteNewHelperClick = (zIndex: number) => {
        const { dispatch, closeAssignmentWidget, resetInvitationForm, task } = this.props;
        if (closeAssignmentWidget && resetInvitationForm) {
            closeAssignmentWidget();
            resetInvitationForm();
        } else {
            if (task.visible_to_family && task.type !== TaskType.tracking_step) {
                dispatch(openHelperInvitationDialog({
                    zIndex: zIndex + 1,
                    defaultTab: EntityCaseRole.admin,
                    onAddedCallback: this.handleUserAddedCallback
                }));
            } else {
                dispatch(openTeamInvitationDialog({
                    zIndex: zIndex + 1,
                    onAddedCallback: this.handleUserAddedCallback
                }));
            }
        }
    };

    handleNewTeamInvitationClick = (zIndex: number) => {
        const { dispatch} = this.props;
        dispatch(openTeamInvitationDialog({
            zIndex: zIndex + 1,
            onAddedCallback: this.handleUserAddedCallback
        }));
    };

    handleUserAddedCallback = (entity: EntitySummary) => {
        if (!entity.user_id) {
            return;
        }

        this.setState(prevState => ({
            assignees: [...prevState.assignees, entity]
        }));
    };

    updateTask = (assignedTo: EntitySummary[], assignedToAll: boolean) => {
        const { dispatch, activeCase, task, userData, onChangeTask } = this.props;

        this.setState({ assignees: assignedToAll ? [] : assignedTo });

        let updatedTask: TaskUpdateRequestUX | null = null;
        const taskAssignedTo = assignedTo.reduce(
            (taskAssignees, curr) => {
                const at = userToTaskAssignee(curr, userData);
                if (!at) {
                    return taskAssignees;
                }
                return [...taskAssignees, at];
            },
            [] as TaskAssignee[]
        );

        if (assignedToAll) {
            updatedTask = {
                assigned_to_all: assignedToAll,
                assigned_to: [],
            };
        } else {
            updatedTask = {
                assigned_to: taskAssignedTo,
                assigned_to_all: assignedToAll
            };
        }

        if (!updatedTask) {
            return;
        }

        if (onChangeTask) {
            onChangeTask(task.id, updatedTask, activeCase.uuid, null);
        }

        if (task.id > 0) {
            dispatch(updateTask(task.id, updatedTask, activeCase.uuid, null));
        }
    };
}

const componentStyles = AssignStyles<Props>();
export default withState(mapStateToProps)(withStyles(componentStyles)(AssignTask));
