import { createRef, Component } from 'react';
import classNames from 'classnames';

import Grid, { GridSize } from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Breakpoint, Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';

import PrintIcon from '@mui/icons-material/Print';

import AboutOverview from './AboutOverview';
import {
    FamilyRoutePage,
    RouteBuilder,
    deathOrAge,
    eliminateWhiteSpaces,
    emptyStringParser,
    getDOBandDOD,
    joinNameParts,
    setElementStyleByRef,
    getIntercomTargetProp
} from '../../../../../services';
import {
    CaseTaskUX,
    DeathCertificateConfigUX,
    DeathCertificateType,
    EntitySummary,
    GatherCaseUX,
    ShortAddress,
    UserProfile,
} from '../../../../../shared/types';
import FormsCompletionStatus from '../../FormsCompletionStatus';
import { deathCertificateValidator } from '../../../../../shared/death_certificate/validators';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import CaseNumberButton from '../../../../cases/caseCard/widgets/CaseNumberButton';
import GLink from '../../../../common/GLink';
import LifeOverview from './LifeOverview';
import RestingPlaceOverview from './RestingPlaceOverview';
import ParentsOverview from './ParentsOverview';
import EducationOverview from './EducationOverview';
import MarriageOverview from './MarriageOverview';
import WorkHistoryOverview from './WorkHistoryOverview';
import MilitaryOverview from './MilitaryOverview';
import RaceOverview from './RaceOverview';
import ApprovalOverview from './ApprovalOverview';
import NotesOverview from './NotesOverview';

export interface OverviewSectionCommonProps {
    deathCertificate: DeathCertificateType;
    alwaysVisible?: boolean;
    showHeading?: boolean;
    divider?: boolean;
    printMode?: boolean;
}
interface Props {
    dc: DeathCertificateType;
    dcConfig?: DeathCertificateConfigUX;
    user: UserProfile;
    helpers: EntitySummary[];
    activeCase: GatherCaseUX;
    dcTask: CaseTaskUX | null;
    direction?: 'column';
    hideCaseNumber?: boolean;
    showEditButton?: boolean;
    intercomTargetProp?: string;
    intercomTargetPropEditDc?: string;
}

interface State {
    isPrintMode: boolean;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    buttonLeftIcon: {
        marginRight: theme.spacing(),
    },
    caseName: {
        textAlign: 'center',
        margin: '50px 0 16px',
        borderBottom: '1px solid rgba(117, 117, 117, 1)',
    },
    subSectionTypography: {
        color: theme.palette.secondary.main,
        fontWeight: 200,
        fontSize: 16,
        textTransform: 'capitalize'
    },
    subSectionHeader: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
        fontSize: 16,
        textTransform: 'uppercase',
        marginBottom: 10
    },
    leftSection: {
        [theme.breakpoints.up('md')]: {
            paddingRight: 20,
        }
    },
    rightSection: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
        }
    },
    divider: {
        margin: '10px 0',
        backgroundColor: 'rgba(0, 0, 0, 0.21)',
    },
    editButton: {
        borderRadius: '0 0 8px 8px',
        width: 'fit-content',
        margin: '0 auto',
        boxShadow: 'none !important',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderTop: 'none',
        textTransform: 'none',
        fontWeight: 400,
        minHeight: 'unset',
        padding: '4px 12px',
    },
    emailLowercase: {
        '& span': {
            textTransform: 'lowercase'
        },
    },
    paddingRight20: {
        paddingRight: 20
    },
    padding12: {
        padding: '0px 12px'
    },
});

type StyledProps = Props & WithGStyles<'root' | 'buttonLeftIcon' | 'caseName' | 'subSectionTypography'
    | 'leftSection' | 'rightSection' | 'divider' | 'subSectionHeader' | 'emailLowercase'
    | 'editButton' | 'paddingRight20' | 'padding12'>;

export const parseShortAddress = (address: ShortAddress): null | string => {
    let addressArray: Array<string> = [];

    if (!address.googlePlaceID) {
        return emptyStringParser(address.description) ? address.description : null;
    }

    let cityWithStateAndPostalCode: string | null = '';

    if (address.city) {
        cityWithStateAndPostalCode = `${cityWithStateAndPostalCode} ${address.city},`.trim();
    }

    if (address.state) {
        cityWithStateAndPostalCode = `${cityWithStateAndPostalCode} ${address.state}`.trim();
    }

    if (emptyStringParser(cityWithStateAndPostalCode)) {
        addressArray = [...addressArray, cityWithStateAndPostalCode];
    }

    /**
     * this check eliminates the country from the address if the country === United States
     * as of, currently the app is LIVE in US.
     * 
     * In near future we can check the user's Location and validate wether to show the country.
     */
    const currentCountry: string = 'United States';
    if (address.country && currentCountry !== address.country) {
        addressArray = [...addressArray, address.country];
    }

    return addressArray.length === 0 ? null : addressArray.join(', ');
};

class OverviewForm extends Component<StyledProps, State> {
    leftSectionRef = createRef<HTMLDivElement>();
    rightSectionRef = createRef<HTMLDivElement>();
    caseNameContainerRef = createRef<HTMLDivElement>();
    caseNameRef = createRef<HTMLDivElement>();
    overviewContentRef = createRef<HTMLDivElement>();

    constructor(props: StyledProps) {
        super(props);
        this.state = {
            isPrintMode: false,
        };
    }

    handlePrint = () => {
        const { isPrintMode } = this.state;
        /**
         * Formatting HTML for Printing
         */
        if (this.leftSectionRef.current) {
            const leftSection = this.leftSectionRef.current;
            leftSection.style.width = 'calc(50% - 20px)';
            leftSection.style.paddingRight = '20px';
            leftSection.style.display = 'inline-block';
            leftSection.style.verticalAlign = 'top';
        }

        if (this.rightSectionRef.current) {
            const rightSection = this.rightSectionRef.current;
            rightSection.style.width = 'calc(50% - 20px)';
            rightSection.style.paddingLeft = '20px';
            rightSection.style.display = 'inline-block';
            rightSection.style.verticalAlign = 'top';
        }

        if (this.caseNameContainerRef.current) {
            const caseNameContainer = this.caseNameContainerRef.current;
            caseNameContainer.style.margin = '0';
            caseNameContainer.style.textAlign = 'center';
            caseNameContainer.style.borderBottom = '1px solid rgba(117, 117, 117, 1)';
        }

        if (this.caseNameRef.current) {
            const caseName = this.caseNameRef.current;
            caseName.style.margin = isPrintMode ? '0' : '0 0 10px';
            caseName.style.fontSize = isPrintMode ? '32px' : '36px';
            caseName.style.fontWeight = '100';
            caseName.style.fontFamily = 'system-ui, sans-serif';
        }

        const printContents = this.overviewContentRef.current;
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            this.resetHTMLAfterPrinting();
            return;
        }

        const printStyles = `
            <style>
                @page { margin: 24px 48px; }
            </style>
            `;
        printContainer.innerHTML = printStyles + printContents.outerHTML;
        window.print();

        this.setState({ isPrintMode: false });
        this.resetHTMLAfterPrinting();
    };

    printForm = () => {
        this.setState({ isPrintMode: true }, this.handlePrint);
    };

    /**
     * Reset HTML after Printing
     */
    resetHTMLAfterPrinting = () => {
        setElementStyleByRef(this.leftSectionRef, 'width', '100%');
        setElementStyleByRef(this.rightSectionRef, 'width', '100%');
        setElementStyleByRef(this.caseNameContainerRef, 'margin', '50px 0 16px');
        setElementStyleByRef(this.caseNameRef, 'margin', '0');
    };

    render() {
        const {
            classes,
            activeCase,
            helpers,
            dc,
            dcConfig,
            user,
            direction,
            hideCaseNumber,
            showEditButton,
            dcTask,
            intercomTargetProp,
            intercomTargetPropEditDc
        } = this.props;

        const { isPrintMode } = this.state;

        const caseFullName = joinNameParts(activeCase);

        const age = deathOrAge(activeCase, undefined, undefined, true);
        const { dob, dod } = getDOBandDOD(activeCase);

        const isDirectionColumn = direction === 'column';

        const containerSizeProps: Partial<Record<Breakpoint, GridSize>> = isDirectionColumn
            ? { xs: 12 }
            : { xs: 12, sm: 10, md: 9, lg: 8 };

        return (
            <Grid
                container
                justifyContent="center"
                alignContent="center"
                className={isDirectionColumn ? undefined : classes.marginTop40}
            >
                <Grid
                    item
                    {...containerSizeProps}
                    {...getIntercomTargetProp(intercomTargetProp)}
                >
                    {dcConfig &&
                        <>
                            <Grid
                                item
                                xs={12}
                                className={classes.textCenter}
                            >
                                <FormsCompletionStatus
                                    dcStatus={deathCertificateValidator(dc, dcConfig, activeCase, helpers, user)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.textCenter}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classNames(
                                        classes.margin_8,
                                    )}
                                    onClick={this.printForm}
                                >
                                    <PrintIcon className={classes.buttonLeftIcon} />
                                    PRINT this page
                                </Button>
                            </Grid>
                        </>
                    }

                    <Grid
                        container
                        justifyContent="center"
                        alignContent={isDirectionColumn ? undefined : 'center'}
                        className={classNames(
                            isDirectionColumn
                                ? undefined
                                : classes.marginBottom20,
                            classes.padding12
                        )}
                        id="overview-content"
                        ref={this.overviewContentRef}
                        direction={direction}
                    >
                        <Grid
                            item
                            xs={12}
                            className={classes.caseName}
                            ref={this.caseNameContainerRef}
                            style={{
                                textAlign: 'center',
                                margin: isPrintMode ? '20px 0' : isDirectionColumn ? 0 : `50px 0 16px`,
                                borderBottom: '1px solid rgba(117, 117, 117, 1)',
                            }}
                        >
                            <Typography
                                color="secondary"
                                variant="body1"
                                align="center"
                                ref={this.caseNameRef}
                                className={classNames(
                                    classes.fontSize36,
                                    classes.fontWeight200,
                                    classes.paragraphLineHeight
                                )}
                                style={{
                                    fontSize: isPrintMode ? 32 : 36,
                                    fontWeight: 200,
                                    fontFamily: 'system-ui, sans-serif',
                                    lineHeight: '1.25em'
                                }}
                            >
                                {eliminateWhiteSpaces(caseFullName)}
                            </Typography>

                            {!hideCaseNumber &&
                                <CaseNumberButton
                                    zIndex={1320}
                                    activeCase={activeCase}
                                    buttonClass={classNames(
                                        classes.positionRelative,
                                        isPrintMode && classes.colorBlack2
                                    )}
                                    hideButton={isPrintMode && !(activeCase.is_test || activeCase.case_number)}
                                />
                            }

                            {dob && dod && <Typography
                                color="secondary"
                                variant="body1"
                                align="center"
                                className={classes.paragraphLineHeight}
                                style={{
                                    fontSize: isPrintMode ? 14 : 17,
                                    fontWeight: 400,
                                    lineHeight: '1.25em',
                                    marginBottom: 6,
                                    fontFamily: 'system-ui, sans-serif'
                                }}
                            >
                                {age}
                            </Typography>
                            }
                        </Grid>

                        {showEditButton &&
                            <GLink
                                to={RouteBuilder.FamilyPage({
                                    caseName: activeCase.name,
                                    funeralHomeKey: activeCase.funeral_home.key,
                                    page: FamilyRoutePage.QUESTIONS,
                                })}
                                linkClass={classNames(
                                    classes.marginBottom20,
                                    classes.displayInlineFlex,
                                )}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.editButton}
                                    {...getIntercomTargetProp(intercomTargetPropEditDc)}
                                >
                                    Edit {activeCase.fname}'s Vitals
                                </Button>
                            </GLink>
                        }

                        <Grid
                            item
                            xs={isPrintMode ? 6 : 12}
                            md={isDirectionColumn ? undefined : 6}
                            className={classNames(
                                classes.leftSection,
                                isDirectionColumn && classes.padding0,
                                isPrintMode && classes.paddingRight20
                            )}
                            id="left-section"

                        >
                            <AboutOverview
                                deathCertificate={dc}
                                activeCase={activeCase}
                                printMode={isPrintMode}
                                divider
                            />

                            <LifeOverview
                                deathCertificate={dc}
                                activeCase={activeCase}
                                printMode={isPrintMode}
                                divider
                                showHeading
                            />

                            <RestingPlaceOverview
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                divider
                                showHeading
                            />

                            <ParentsOverview
                                helpers={helpers}
                                activeCase={activeCase}
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                divider
                                showHeading
                            />

                            <EducationOverview
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                divider
                                showHeading
                            />
                        </Grid>
                        <Grid
                            item
                            xs={isPrintMode ? 6 : 12}
                            md={isDirectionColumn ? undefined : 6}
                            className={classNames(classes.rightSection,
                                isDirectionColumn && classes.padding0
                            )}
                            id="right-section"
                        >
                            <MarriageOverview
                                helpers={helpers}
                                activeCase={activeCase}
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                showHeading
                                divider
                            />

                            <WorkHistoryOverview
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                showHeading
                                divider
                            />

                            <MilitaryOverview
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                showHeading
                                divider
                            />

                            <RaceOverview
                                deathCertificate={dc}
                                printMode={isPrintMode}
                                showHeading
                                divider
                            />

                            <ApprovalOverview
                                activeCase={activeCase}
                                helpers={helpers}
                                dcTask={dcTask}
                                showHeading
                                divider
                            />

                            <NotesOverview
                                dcTask={dcTask}
                                printMode={isPrintMode}
                                showHeading
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withGStyles(styles)(OverviewForm);
