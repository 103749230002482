import * as React from 'react';
import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';

import {
    EntitySummary,
    EntityUpdateValidateErrorEnum,
    GmapsSearchType,
    isLongAddress,
    LongAddress,
    UserProfile,
    UserRole,
    UserRoles
} from '../../../shared/types';

import PhoneNumberMask from '../../common/PhoneNumberMask';
import { convertHexToRGBA, getFormattedPhoneNumber, showIntercom } from '../../../services';
import GmapsSearch, { GmapsSearchAddress } from '../../gmapsSearch/GmapsSearch';
import { canEditUserRole } from '../../../shared/authority/can';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        display: 'block',
        width: '100%',
    },
    button: {
        margin: theme.spacing(),
    },
    securityExplanation: {
        fontSize: 14,
        width: 'auto',
        marginTop: 30,
        wordWrap: 'break-word',
        fontWeight: 400
    },
    form: {
        padding: '0 16px',
        margin: 'auto',
        maxWidth: 280,
        '@media (min-width: 360px)': {
            maxWidth: 320,
        },
        '@media (min-width: 768px)': {
            padding: '0 16px 0 8px',
            maxWidth: '100%'
        }
    },
    logoutButtonOuter: {
        display: 'block',
        paddingTop: 20,
        textAlign: 'center',
    },
    paddingRight: {
        color: '#676767',
        paddingRight: '10px',
    },
    gmapSearch: {
        width: '100% !important',
        maxWidth: 'initial !important'
    },
    emailPhoneContainer: {
        borderRadius: 12,
        border: `1px solid ${theme.palette.primary.main}`,
        background: convertHexToRGBA(theme.palette.primary.main, 0.08),
        marginTop: 20,
        padding: '0 8px 16px !important',
        '& $containerHeader': {
            marginTop: 16
        },
        '& form': {
            padding: 0
        }
    },
    otherLabel: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            marginRight: 8
        }
    },
    radioClass: {
        width: 36,
        height: 32,
        marginLeft: 8
    },
    labelClass: {
        display: 'flex',
        alignItems: 'center',
        '& p:nth-child(2)': {
            fontSize: 12
        }
    },
    marginRight0: {
        marginRight: 0
    },
    containerHeader: {},
    controlSuggestionClass: {
        position: 'absolute',
        textAlign: 'center'
    },
    sectionHeader: {
        textDecoration: 'underline',
        fontWeight: 600,
        textAlign: "center",
        marginBottom: 15,
    }
});

type StyledProps = Props & WithGStyles<'root' | 'button' | 'securityExplanation' | 'form' | 'logoutButtonOuter'
    | 'paddingRight' | 'gmapSearch' | 'emailPhoneContainer' | 'otherLabel' | 'radioClass' | 'labelClass'
    | 'marginRight0' | 'containerHeader' | 'controlSuggestionClass' | 'sectionHeader'>;

interface Props {
    isSaving: boolean;
    user: UserProfile;
    selectedPerson: EntitySummary;
    role: UserRole | null;
    email: string;
    phone: string;
    workPhone: string;
    homePhone: string;
    homeAddressLong: LongAddress;
    isEmailValid: boolean;
    isPhoneValid: boolean;
    isHomePhoneValid: boolean;
    isWorkPhoneValid: boolean;
    isMe: boolean;
    disableLoginCredentials: boolean;
    logoutUserSession: () => void;
    onRoleChange: (role: UserRole) => void;
    onEmailChange: (email: string) => void;
    onPhoneChange: (phone: string) => void;
    onHomePhoneChange: (phone: string) => void;
    onWorkPhoneChange: (phone: string) => void;
    onHomeAddressChange: (homeAddress: LongAddress, useAddressDescription: boolean) => void;
    handlePhoneFieldKeyUpEvent: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    zIndex: number;
    useAddressDescription: boolean;
    entityValidation: EntityUpdateValidateErrorEnum | null;
}

interface State {
    otherEmail: string;
    otherPhone: string;
    emailRadio: string;
    phoneRadio: string;
}

class SettingsForm extends React.Component<StyledProps, State> {
    state: State = {
        otherEmail: '',
        otherPhone: '',
        emailRadio: '',
        phoneRadio: ''
    };

    isUserRoleDisabled = (desiredRole: UserRole): boolean => {
        const { user, selectedPerson } = this.props;
        return !canEditUserRole(user, selectedPerson, desiredRole);
    };

    renderRoleMenuItem = (role: UserRole) => {
        return (
            <MenuItem
                value={role}
                disabled={this.isUserRoleDisabled(role)}
            >
                {UserRoles.display(role)}
            </MenuItem>
        );
    };

    renderUserRoles = () => {
        const { classes, isMe, isSaving, user, role, selectedPerson, onRoleChange, zIndex } = this.props;
        if (UserRoles.isFamilyRole(user.role) || !selectedPerson.user) {
            return null;
        }

        const isSelectedUserFamilyRole = UserRoles.isFamilyRole(selectedPerson.user.role);

        const renderGatherRoles = UserRoles.isGOMUser(user) && UserRoles.isGOMUser(selectedPerson);
        let isDisabled: boolean;
        if (renderGatherRoles) {
            isDisabled = this.isUserRoleDisabled(UserRole.GOMSuperUser) &&
                this.isUserRoleDisabled(UserRole.GOMUser);
        } else if (isSelectedUserFamilyRole) {
            isDisabled = true;
        } else {
            isDisabled = this.isUserRoleDisabled(UserRole.FHUser);
        }

        return (
            <FormControl
                className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
            >
                <InputLabel htmlFor="my-role" variant="standard">
                    {isMe ? 'My Role' :
                        <>
                            <span className={classes.textCapitalize}>{selectedPerson.fname}</span>
                            's Role
                        </>
                    }
                </InputLabel>
                <Select
                    value={role || ''}
                    onChange={(e) => onRoleChange(e.target.value as UserRole)}
                    MenuProps={{ style: { zIndex } }}
                    inputProps={{
                        name: 'role',
                        id: 'my-role',
                    }}
                    classes={{ select: classes.themeSelectMenu }}
                    disabled={isSaving || isDisabled}
                >
                    {renderGatherRoles && this.renderRoleMenuItem(UserRole.GOMSuperUser)}
                    {renderGatherRoles && this.renderRoleMenuItem(UserRole.GOMUser)}
                    {this.renderRoleMenuItem(UserRole.FHUser)}
                    {isSelectedUserFamilyRole && this.renderRoleMenuItem(UserRole.User)}
                </Select>
            </FormControl>
        );
    };

    renderFHContent = () => {
        const {
            classes,
            isEmailValid,
            onEmailChange,
            isSaving,
            disableLoginCredentials,
            email,
            selectedPerson,
            onPhoneChange,
            isPhoneValid,
            entityValidation,
            phone
        } = this.props;
        const { otherEmail, phoneRadio, otherPhone, emailRadio } = this.state;

        const fhEmail = '';
        const fhPhone = '';
        const emailInUse = entityValidation === EntityUpdateValidateErrorEnum.duplicateEmail ||
            entityValidation === EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail;
        const phoneInUse = entityValidation === EntityUpdateValidateErrorEnum.duplicatePhone ||
            entityValidation === EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail;
        return (
            <form
                className={classNames(classes.overflowHidden, classes.form)}
                autoComplete="off"
            >

                <Grid item xs={12} className={classes.emailPhoneContainer}>
                    <FormControl
                        className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                    >
                        <TextField
                            label={`${selectedPerson.fname}'s Primary Email`}
                            type="email"
                            id="_email"
                            name="email"
                            value={email}
                            className={classes.width100}
                            onChange={(e) => onEmailChange(e.target.value)}
                            autoComplete="new-password"
                            disabled={isSaving || disableLoginCredentials}
                            error={!isEmailValid || emailInUse}
                            color="secondary" />
                        {!isEmailValid ?
                            <FormHelperText id="email-format-helper">
                                Email is invalid.
                            </FormHelperText> : null}
                        {emailInUse &&
                            <FormHelperText id="email-format-helper">
                                This email is already in use.
                            </FormHelperText>
                        }
                    </FormControl>

                    <Typography color="primary" className={classes.containerHeader}>
                        Which would you like to display to families?
                    </Typography>

                    <RadioGroup value={emailRadio} onChange={this.handleEmailRadioChange}>
                        <FormControlLabel
                            disabled={!email}
                            value="primaryEmail"
                            control={<Radio color="primary" className={classes.radioClass} />}
                            label={<span className={classes.labelClass}>
                                <Typography color="primary">Primary</Typography>&nbsp;
                                <Typography color="secondary">({email})</Typography>
                            </span>}
                            labelPlacement="end"
                            name="primaryEmail" />
                        <FormControlLabel
                            disabled={!fhEmail}
                            value="fhEmail"
                            control={<Radio color="primary" className={classes.radioClass} />}
                            label={<span className={classes.labelClass}>
                                <Typography color="primary">Funeral Home</Typography>&nbsp;
                                <Typography color="secondary">
                                    ({fhEmail && fhEmail || 'No Email Provided'})
                                </Typography>
                            </span>}
                            classes={{ label: classes.colorPrimary }}
                            labelPlacement="end"
                            name="fhEmail" />
                        <FormControlLabel
                            value="otherEmail"
                            name="otherEmail"
                            labelPlacement="end"
                            control={<Radio color="primary" className={classes.radioClass} />}
                            label={<div className={classes.otherLabel}>
                                <span>Other:</span>
                                <TextField
                                    placeholder="Type Email Here"
                                    type="email"
                                    id="otherEmail"
                                    name="otherEmail"
                                    value={otherEmail}
                                    className={classes.width100}
                                    onChange={e => {
                                        this.setState({ otherEmail: e.target.value });
                                    }}
                                    autoComplete="new-password"
                                    disabled={isSaving || disableLoginCredentials || emailRadio !== 'otherEmail'}
                                    error={!isEmailValid || emailInUse}
                                    color="secondary"
                                    fullWidth />
                            </div>}
                            classes={{
                                label: classNames(classes.colorPrimary, classes.width100),
                                root: classes.marginRight0
                            }}
                        />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12} className={classes.emailPhoneContainer}>
                    <FormControl
                        className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                    >
                        <TextField
                            label={`${selectedPerson.fname}'s Primary Phone Number`}
                            type="tel"
                            id="phone-number"
                            name="phoneNumber"
                            value={phone}
                            InputProps={{
                                inputComponent: PhoneNumberMask
                            }}
                            className={classes.width100}
                            onChange={(e) => onPhoneChange(e.target.value)}
                            autoComplete="new-password"
                            disabled={isSaving || disableLoginCredentials}
                            error={!isPhoneValid || phoneInUse}
                            color="secondary" />
                        {!isPhoneValid ?
                            <FormHelperText id="email-format-helper">
                                Phone Number is invalid.
                            </FormHelperText> : null}
                        {phoneInUse &&
                            <FormHelperText id="email-format-helper">
                                This phone number is already in use.
                            </FormHelperText>
                        }
                    </FormControl>

                    <Typography color="primary" className={classes.containerHeader}>
                        Which would you like to display to families?
                    </Typography>

                    <RadioGroup value={phoneRadio} onChange={this.handlePhoneRadioChange}>
                        <FormControlLabel
                            disabled={!phone}
                            value="primaryPhone"
                            control={<Radio color="primary" className={classes.radioClass} />}
                            label={<span className={classes.labelClass}>
                                <Typography color="primary">Primary</Typography>&nbsp;
                                <Typography color="secondary">{getFormattedPhoneNumber(phone)}</Typography>
                            </span>}
                            labelPlacement="end"
                            name="primaryPhone" />
                        <FormControlLabel
                            disabled={!fhPhone}
                            value="fhPhone"
                            control={<Radio color="primary" className={classes.radioClass} />}
                            label={<span className={classes.labelClass}>
                                <Typography color="primary">Funeral Home</Typography>&nbsp;
                                <Typography color="secondary">
                                    {fhPhone && getFormattedPhoneNumber(fhPhone) || '(No Phone Provided)'}
                                </Typography>
                            </span>}
                            labelPlacement="end"
                            name="fhPhone"
                            classes={{ label: classes.colorPrimary }} />
                        <FormControlLabel
                            value="otherPhone"
                            control={<Radio color="primary" className={classes.radioClass} />}
                            label={<div className={classes.otherLabel}>
                                <span>Other:</span>
                                <TextField
                                    placeholder="Type Phone Here"
                                    type="tel"
                                    id="phone_number"
                                    name="otherPhone"
                                    value={otherPhone}
                                    className={classes.width100}
                                    InputProps={{
                                        inputComponent: PhoneNumberMask
                                    }}
                                    onChange={e => {
                                        this.setState({ otherPhone: e.target.value });
                                    }}
                                    autoComplete="new-password"
                                    disabled={isSaving || disableLoginCredentials || phoneRadio !== 'otherPhone'}
                                    error={!isPhoneValid || phoneInUse}
                                    color="secondary" />
                            </div>}
                            labelPlacement="end"
                            name="otherPhone"
                            classes={{
                                label: classNames(classes.colorPrimary, classes.width100),
                                root: classes.marginRight0
                            }} />
                    </RadioGroup>
                </Grid>
            </form>
        );
    };

    render() {
        const {
            classes,
            logoutUserSession,
            email,
            homeAddressLong,
            phone,
            workPhone,
            homePhone,
            isEmailValid,
            isPhoneValid,
            isHomePhoneValid,
            isWorkPhoneValid,
            isSaving,
            isMe,
            disableLoginCredentials,
            handlePhoneFieldKeyUpEvent,
            onEmailChange,
            onPhoneChange,
            onHomePhoneChange,
            onWorkPhoneChange,
            zIndex,
            selectedPerson,
            useAddressDescription,
            entityValidation,
        } = this.props;
        const emailInUse = entityValidation === EntityUpdateValidateErrorEnum.duplicateEmail ||
            entityValidation === EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail;
        const phoneInUse = entityValidation === EntityUpdateValidateErrorEnum.duplicatePhone ||
            entityValidation === EntityUpdateValidateErrorEnum.duplicatePhoneAndEmail;

        return (
            <div className={classes.root}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <form
                            className={classes.form}
                            autoComplete="off"
                        >
                            <Typography className={classes.sectionHeader}>
                                Login Information
                            </Typography>

                            <FormControl
                                className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                            >
                                <TextField
                                    label="Email"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    className={classes.width100}
                                    onChange={(e) => onEmailChange(e.target.value)}
                                    autoComplete="new-password"
                                    disabled={isSaving || disableLoginCredentials}
                                    error={!isEmailValid || emailInUse}
                                />
                                {!isEmailValid ?
                                    <FormHelperText id="email-format-helper">
                                        Email is invalid.
                                    </FormHelperText> : null
                                }
                                {emailInUse &&
                                    <FormHelperText id="email-format-helper">
                                        This email address is already in use.
                                    </FormHelperText>
                                }
                            </FormControl>

                            <FormControl
                                className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                                error={!isPhoneValid || phoneInUse}
                            >
                                <TextField
                                    label="Mobile Number"
                                    type="tel"
                                    id="formatted-phoneNumber"
                                    name="phone"
                                    value={phone}
                                    className={classes.width100}
                                    onChange={(e) => onPhoneChange(e.target.value)}
                                    onKeyUp={handlePhoneFieldKeyUpEvent}
                                    autoComplete="new-password"
                                    disabled={isSaving || disableLoginCredentials}
                                    InputProps={{
                                        inputComponent: PhoneNumberMask
                                    }}
                                    error={!isPhoneValid || phoneInUse}
                                />
                                {!isPhoneValid ?
                                    <FormHelperText id="phone-format-helper">
                                        Mobile number is invalid.
                                    </FormHelperText> : null
                                }
                                {phoneInUse &&
                                    <FormHelperText id="email-format-helper">
                                        This phone number is already in use.
                                    </FormHelperText>
                                }
                            </FormControl>

                            <Typography className={classes.sectionHeader} sx={{mt: 4}}>
                                Additional Information
                            </Typography>

                            {/* render role menu on the basis of the user role from user session */}
                            {this.renderUserRoles()}

                            <FormControl
                                className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                            >
                                <TextField
                                    label="Home Number"
                                    type="tel"
                                    id="home-phone-number"
                                    name="homePhoneNumber"
                                    value={homePhone}
                                    InputProps={{
                                        inputComponent: PhoneNumberMask
                                    }}
                                    className={classes.width100}
                                    onChange={(e) => onHomePhoneChange(e.target.value)}
                                    autoComplete="new-password"
                                    disabled={isSaving || disableLoginCredentials}
                                    error={!isHomePhoneValid}
                                    color="secondary" />
                                {!isHomePhoneValid ?
                                    <FormHelperText id="home-format-helper">
                                        Home Phone Number is invalid.
                                    </FormHelperText> : null}
                            </FormControl>

                            <FormControl
                                className={classNames(classes.width100, classes.textLeft, classes.marginTop10)}
                            >
                                <TextField
                                    label="Work Number"
                                    type="tel"
                                    id="work-phone-number"
                                    name="workPhoneNumber"
                                    value={workPhone}
                                    InputProps={{
                                        inputComponent: PhoneNumberMask
                                    }}
                                    className={classes.width100}
                                    onChange={(e) => onWorkPhoneChange(e.target.value)}
                                    autoComplete="new-password"
                                    disabled={isSaving || disableLoginCredentials}
                                    error={!isWorkPhoneValid}
                                    color="secondary" />
                                {!isWorkPhoneValid ?
                                    <FormHelperText id="work-format-helper">
                                        Work Phone Number is invalid.
                                    </FormHelperText> : null}
                            </FormControl>

                            <Grid item xs={12} className={classes.positionRelative}>
                                <GmapsSearch
                                    formControlStyleClasses={classes.marginTop10}
                                    controlClasses={classNames(classes.gmapSearch)}
                                    controlSuggestionClasses={classNames(classes.controlSuggestionClass)}
                                    textLabel={`${selectedPerson.fname}'s Physical Address`}
                                    type={GmapsSearchType.longAddress}
                                    value={homeAddressLong}
                                    onSetPlace={(homeAddressPlace: GmapsSearchAddress, useDesc?: boolean) => {
                                        if (isLongAddress(homeAddressPlace)) {
                                            this.handleHomeAddress(homeAddressPlace, useDesc || false);
                                        }
                                    }
                                    }
                                    useDescription={useAddressDescription}
                                    error={false}
                                    required={false}
                                    disabled={false}
                                    zIndex={zIndex + 1}
                                />

                            </Grid>
                            {disableLoginCredentials &&
                                <Typography
                                    color="secondary"
                                    variant="caption"
                                    gutterBottom
                                    align="center"
                                    noWrap={false}
                                    className={classes.securityExplanation}
                                >
                                    For security reasons only Gather can update a user's email or mobile number.
                                    <br />
                                    <span
                                        className={classNames(classes.fontWeight500, classes.cursorPointer)}
                                        onClick={showIntercom}
                                    >
                                        &nbsp;Click here&nbsp;
                                    </span>
                                    to contact Gather Support.
                                </Typography>
                            }
                        </form>
                        {isMe && <Grid
                            item
                            className={classes.logoutButtonOuter}
                        >
                            <Button
                                color="primary"
                                className={classes.button}
                                size="large"
                                onClick={logoutUserSession}
                            >
                                <PowerSettingsNew className={classNames(classes.paddingRight)} />
                                LOG OUT ON THIS DEVICE
                            </Button>
                        </Grid>}
                    </Grid>
                </Grid>
            </div>
        );
    }

    handleEmailRadioChange = (e: React.ChangeEvent, value: string) => {
        this.setState({ emailRadio: value });
    };

    handlePhoneRadioChange = (e: React.ChangeEvent, value: string) => {
        this.setState({ phoneRadio: value });
    };

    handleHomeAddress = (homeAddressLong: LongAddress, useAddressDescription: boolean) => {
        const { onHomeAddressChange } = this.props;
        onHomeAddressChange(homeAddressLong, useAddressDescription);
    };
}

export default withGStyles(styles)(SettingsForm);
