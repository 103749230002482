import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import EditPriceBaseContainer from "./EditPriceBaseContainer";
import Dinero from 'dinero.js';
import { ProductBulkAction, ProductUX } from "../../../../../shared/types";
import { useGDispatch } from '../../../../../types';
import { bulkUpdateProductPrice } from '../../../../../actions/product/FHProduct.action';
import { registerAppError } from '../../../../../actions/errors';
import { useState } from 'react';

interface EditVariablePriceProps {
    selectButton: BulkEditButtonType | null;
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const EditVariablePrice = (props: EditVariablePriceProps) => {

    const {
        funeralHomeId,
        selectButton,
        selectedProductIds,
        onSubmitClick,
    } = props;
    const dispatch = useGDispatch();
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitclick = async (productIdPriceMap: { productId: number; value: number }[]) => {
        setIsSpinning(true);
        if (funeralHomeId !== null) {
            const updatedProducts: ProductUX[] = await dispatch(bulkUpdateProductPrice({
                funeralHomeId: funeralHomeId,
                productIdPriceMap,
                bulkAction: ProductBulkAction.edit_variable_price,
            }));
            onSubmitClick(updatedProducts);
        } else {
            dispatch(registerAppError('Funeral Home not set', {showSnackbar: true, sendToSentry: true}));
        }
        setIsSpinning(false);
    };

    const getVariablePrice = (productDetails: ProductUX) => {
        const basePrice = Dinero({
            amount: productDetails?.var_price || 0,
            currency: productDetails?.asset_type,
        });
        return basePrice;
    };

    return (
        <EditPriceBaseContainer
            selectButton={selectButton}
            onSubmitClick={handleSubmitclick}
            selectedProductIds={selectedProductIds}
            getPrice={getVariablePrice}
            isSpinning={isSpinning}
        />
    );
};

export default EditVariablePrice;
