import { GatherCaseUX, EntityCaseRole, EntitySummary } from '../../shared/types';

import InvitationSuccess from './InvitationSuccess';
import AddEntitySuccess from './addEntitySuccess';

import { DCEntityEnum } from '../assignmentPoppers/SelectHelper';

interface Props {
    newHelper: EntitySummary;
    zIndex: number;
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    activeCase: GatherCaseUX;
    entityRole: EntityCaseRole;
    editHelper: () => void;
    closeParentDialog: () => void;
    relationToDeceased: DCEntityEnum | null;
    resetForm: ({ isFocused }: { isFocused: boolean }) => void;
}

const SuccessScreen = (props: Props) => {

    const {
        editHelper,
        entityRole,
        formType,
        zIndex,
        activeCase,
        closeParentDialog,
        relationToDeceased,
        newHelper,
        resetForm,
    } = props;

    if (newHelper.user) {
        return (
            <InvitationSuccess
                editHelper={editHelper}
                activeCase={activeCase}
                resetInvitationForm={() => resetForm({ isFocused: true })}
                entityRole={entityRole}
                newHelper={newHelper}
                formType={formType}
                zIndex={zIndex}
                closeParentDialog={closeParentDialog}
                isDCEntity={Boolean(relationToDeceased)}
            />
        );
    } else {
        return (
            <AddEntitySuccess
                resetInvitationForm={() => resetForm({ isFocused: true })}
                newHelper={newHelper}
            />
        );
    }

};

export default SuccessScreen;
