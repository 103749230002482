import classNames from 'classnames';
import { values } from 'lodash';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import Lock from '@mui/icons-material/Lock';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';

import { DATA_PRIVATE } from '../../../../styles/Helper.styles';

import {
    DeathCertificateAboutType,
    GenderEnum,
    GmapsSearchType,
    NullLongAddress,
    YesNoEnum,
    YesNoUnknownEnum,
    isLongAddress,
    GatherCaseUX,
    PronounsEnum
} from '../../../../shared/types';

import SecurityNumberMask from '../../../common/SecurityNumberMask';
import { FormParent, ParentProps } from './FormParent';
import { aboutValidators, aboutMasterValidators } from '../../../../shared/death_certificate/validators/about';
import ConfigurableToggleButtonsWrapper from '../common/fields/toggleButtons/ConfigurableToggleButtons';
import { ConfigurableAboutFieldKey, isFieldEnabled } from '../../../../shared/death_certificate/validators/config';
import FormTitle from '../common/FormTitle';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';
import ConfigurableAddressSearch from '../common/fields/addressSearch/ConfigurableAddressSearch';
import ConfigurableDateField from '../common/fields/dateField/ConfigurableDateField';
import ConfigurableFieldWithMultipleCheckboxes
    from '../common/fields/textFieldWithMultipleCheckboxes/ConfigurableFieldWithMultipleCheckboxes';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import { GmapsSearchAddress } from '../../../gmapsSearch/GmapsSearch';
import grey from '@mui/material/colors/grey';
import ChangeDecedentNameDialog from '../../ChangeDecedentName.dialog';
import GButton from '../../../common/GButton';
import GTooltip from '../../../common/GTooltip';
import DCEntityDetails from '../common/DCEntityDetails';
import { GStyles } from '../../../../styles/GStyles';

export const ConfigurableToggleButtonsForYesNo = ConfigurableToggleButtonsWrapper<YesNoEnum>();
export const ConfigurableToggleButtonsForYesNoUnknown = ConfigurableToggleButtonsWrapper<YesNoUnknownEnum>();
const ConfigurableToggleButtonsForGender = ConfigurableToggleButtonsWrapper<GenderEnum>();
const ConfigurableToggleButtonsForPronouns = ConfigurableToggleButtonsWrapper<PronounsEnum>();

interface Props {
    activeCase: GatherCaseUX;
    zIndex: number;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateAboutType;
    genderValidator: boolean | null;
    pronounsValidator: boolean | null;
    securityNumberValidator: boolean | null;
    showSecurityNumber: boolean | null;
    hasMemorialServiceValidator: boolean | null;
    akaValidator: boolean | null;
    ministerOfficiatingValidator: boolean | null;
    pickUpAddressValidator: boolean | null;
    dropOffAddressValidator: boolean | null;
    hasStairsValidator: boolean | null;
    numberCertifiedDCsValidator: boolean | null;
    casketValidator: boolean | null;
    insuranceDetailsValidator: boolean | null;
    hairValidator: boolean | null;
    facialHairValidator: boolean | null;
    nailsValidator: boolean | null;
    lipstickColorValidator: boolean | null;
    medicalExaminerReleaseNumberValidator: boolean | null;
    clothingDueByValidator: boolean | null;
    referredToUsByValidator: boolean | null;
    suffixValidator: boolean | null;
    birthLastNameValidator: boolean | null;
    hobbiesValidator: boolean | null;
    lodgesValidator: boolean | null;
    clubValidator: boolean | null;
    officesValidator: boolean | null;
    previousPlacesLivedValidator: boolean | null;
    casketPanelValidator: boolean | null;
    chapelValidator: boolean | null;
    insurancePolicyNumberValidator: boolean | null;
    isChangeDecedentNameDialogOpen: boolean;
    weightValidator: boolean | null;
    otherValidator: boolean | null;
    hasChildrenUnder18Validator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateAboutType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    button: {
        padding: '8px 20px',
        '&:hover': {
            backgroundColor: grey[700],
        },
    },
    inviteHelperForm: {
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        margin: 'auto',
        '@media (min-width: 420px)': {
            maxWidth: 360,
        }
    },
    memorialtoggleButton: {
        padding: '4px 20px',
    },
    inputTextWidth: {
        width: '100%',
        '@media (min-width: 421px)': {
            width: 360,
        },
    },
    marginBottom34: {
        marginBottom: 34
    },
    ssnIconButton: {
        width: 40,
        height: 40
    }
});

type StyledProps = Props & WithStyles<'root' | 'button' | 'inviteHelperForm'
    | 'memorialtoggleButton' | 'inputTextWidth' | 'marginBottom34' | 'ssnIconButton'>;

class AboutForm extends FormParent<DeathCertificateAboutType, StyledProps, State> {

    state: State = {
        localData: this.props.data,
        genderValidator: null,
        pronounsValidator: null,
        securityNumberValidator: null,
        showSecurityNumber: null,
        hasMemorialServiceValidator: null,
        akaValidator: null,
        ministerOfficiatingValidator: null,
        pickUpAddressValidator: null,
        dropOffAddressValidator: null,
        hasStairsValidator: null,
        numberCertifiedDCsValidator: null,
        casketValidator: null,
        insuranceDetailsValidator: null,
        hairValidator: null,
        facialHairValidator: null,
        nailsValidator: null,
        lipstickColorValidator: null,
        medicalExaminerReleaseNumberValidator: null,
        clothingDueByValidator: null,
        referredToUsByValidator: null,
        suffixValidator: null,
        birthLastNameValidator: null,
        hobbiesValidator: null,
        lodgesValidator: null,
        clubValidator: null,
        officesValidator: null,
        previousPlacesLivedValidator: null,
        casketPanelValidator: null,
        chapelValidator: null,
        insurancePolicyNumberValidator: null,
        isChangeDecedentNameDialogOpen: false,
        weightValidator: null,
        otherValidator: null,
        hasChildrenUnder18Validator: null,
    };

    openChangeDecedentNameDialog = () => {
        this.setState({
            isChangeDecedentNameDialogOpen: true,
        });
    };

    closeChangeDecedentNameDialog = () => {
        this.setState({
            isChangeDecedentNameDialogOpen: false,
        });
    };

    handleAkaChange = (value: string) => {
        this.updateData({ aka: value }, this.validateAka);
    };

    handleMinisterOfficiatingChange = (value: string) => {
        this.updateData({ ministerOfficiating: value }, this.validateMinisterOfficiating);
    };

    handlePickUpAddressChange = (value: GmapsSearchAddress) => {
        if (isLongAddress(value)) {
            this.updateData({ pickUpAddress: value }, this.validatePickUpAddress);
        }
    };

    handleDropOffAddressChange = (value: GmapsSearchAddress) => {
        if (isLongAddress(value)) {
            this.updateData({ dropOffAddress: value }, this.validateDropOffAddress);
        }
    };

    handleHasStairsChange = (value: YesNoUnknownEnum) => {
        this.updateData({ hasStairs: value }, this.validateHasStairs);
    };

    handleNumberCertifiedDCsChange = (value: string) => {
        this.updateData({ numberOfDCs: Number(value) }, this.validateNumberCertifiedDCs);
    };

    handleCasketChange = (value: string) => {
        this.updateData({ casket: value }, this.validateCasket);
    };

    handleInsuranceDetailsChange = (value: string) => {
        this.updateData({ insuranceDetails: value }, this.validateInsuranceDetails);
    };

    handleHairChange = (value: string) => {
        this.updateData({ hair: value }, this.validateHair);
    };

    handleFacialHairChange = (value: string) => {
        this.updateData({ facialHair: value }, this.validateFacialHair);
    };

    handleNailsChange = (value: string) => {
        this.updateData({ nails: value }, this.validateNails);
    };

    handleLipstickColorChange = (value: string) => {
        this.updateData({ lipstickColor: value }, this.validateLipstickColor);
    };

    handleMedicalExaminerReleaseChange = (value: string) => {
        this.updateData({ medicalExaminerReleaseNumber: value }, this.validateMedicalExaminerReleaseNumber);
    };

    handleClothingDueByChange = (value: string) => {
        this.updateData({ clothingDueBy: value }, this.validateClothingDueBy);
    };

    handleReferredToUsByChange = (value: string) => {
        this.updateData({ referredToUsBy: value }, this.validateReferredToUsBy);
    };

    handleBirthLastNameChange = (value: string) => {
        this.updateData({ birthLastName: value }, this.validateBirthLastName);
    };

    handleHobbiesChange = (value: string) => {
        this.updateData({ hobbies: value }, this.validateHobbies);
    };

    handleLodgeChange = (value: string) => {
        this.updateData({ lodges: value }, this.validateLodges);
    };

    handleClubChange = (value: string) => {
        this.updateData({ clubs: value }, this.validateClubs);
    };

    handleOfficesChange = (value: string) => {
        this.updateData({ offices: value }, this.validateOffices);
    };

    handlePreviousPlacesLivedChange = (value: string) => {
        this.updateData({ previousPlacesLived: value }, this.validatePreviousPlacesLived);
    };

    handleCasketPanelChange = (value: string) => {
        this.updateData({ casketPanel: value }, this.validateCasketPanel);
    };

    handleChapelChange = (value: string) => {
        this.updateData({ chapel: value }, this.validateChapel);
    };

    handleInsurancePolicyNumberChange = (value: string) => {
        this.updateData({ insurancePolicyNumber: value }, this.validateInsurancePolicyNumber);
    };

    handleWeightChange = (value: string) => {
        this.updateData({ weight: value }, this.validateWeight);
    };

    handleOtherChange = (value: string) => {
        this.updateData({ other: value }, this.validateOther);
    };

    handleHasChildrenUnder18 = (value: YesNoUnknownEnum) => {
        this.updateData({ hasChildrenUnder18: value }, this.validateHasStairs);
    };

    checkValid() {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;

        return aboutMasterValidators.valid(localData, dcConfig, activeCase, user);
    }

    checkIfTouched() {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;

        return aboutMasterValidators.touched(localData, dcConfig, activeCase, user);
    }

    validateSecurityNumber = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            securityNumberValidator: aboutValidators.securityNumber(localData, dcConfig, user),
        });
    };

    validateGender = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            genderValidator: aboutValidators.gender(localData, dcConfig, user),
        });
    };

    validatePronouns = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            pronounsValidator: aboutValidators.pronouns(localData, dcConfig, user),
        });
    };

    validateAka = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            akaValidator: aboutValidators.aka(localData, dcConfig, user)
        });
    };

    validateHasMemorialService = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            hasMemorialServiceValidator: aboutValidators.hasMemorialService(localData, dcConfig, user),
        });
    };

    validateMinisterOfficiating = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            ministerOfficiatingValidator: aboutValidators.ministerOfficiating(localData, dcConfig, user),
        });
    };

    validatePickUpAddress = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            pickUpAddressValidator: aboutValidators.pickUpAddress(localData, dcConfig, user),
        });
    };

    validateDropOffAddress = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            dropOffAddressValidator: aboutValidators.dropOffAddress(localData, dcConfig, user),
        });
    };

    validateHasStairs = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            hasStairsValidator: aboutValidators.hasStairs(localData, dcConfig, user),
        });
    };

    validateNumberCertifiedDCs = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            numberCertifiedDCsValidator: aboutValidators.numberOfDCs(localData, dcConfig, user),
        });
    };

    validateCasket = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            casketValidator: aboutValidators.casket(localData, dcConfig, user),
        });
    };

    validateInsuranceDetails = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            insuranceDetailsValidator: aboutValidators.insuranceDetails(localData, dcConfig, user),
        });
    };

    validateHair = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            hairValidator: aboutValidators.hair(localData, dcConfig, user),
        });
    };

    validateFacialHair = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            facialHairValidator: aboutValidators.facialHair(localData, dcConfig, user),
        });
    };

    validateNails = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            nailsValidator: aboutValidators.nails(localData, dcConfig, user),
        });
    };

    validateLipstickColor = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            lipstickColorValidator: aboutValidators.lipstickColor(localData, dcConfig, user),
        });
    };

    validateMedicalExaminerReleaseNumber = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            medicalExaminerReleaseNumberValidator: aboutValidators.medicalExaminerReleaseNumber(
                localData,
                dcConfig,
                user
            ),
        });
    };

    validateClothingDueBy = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            clothingDueByValidator: aboutValidators.clothingDueBy(localData, dcConfig, user),
        });
    };

    validateReferredToUsBy = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            referredToUsByValidator: aboutValidators.referredToUsBy(localData, dcConfig, user),
        });
    };
    validateSuffix = () => {
        const { dcConfig, user, activeCase } = this.props;

        this.setState({
            suffixValidator: aboutValidators.suffix(activeCase, dcConfig, user)
        });
    };

    validateBirthLastName = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            birthLastNameValidator: aboutValidators.birthLastName(localData, dcConfig, user)
        });
    };

    validateHobbies = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            hobbiesValidator: aboutValidators.hobbies(localData, dcConfig, user)
        });
    };

    validateLodges = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            lodgesValidator: aboutValidators.lodges(localData, dcConfig, user)
        });
    };

    validateClubs = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            clubValidator: aboutValidators.clubs(localData, dcConfig, user)
        });
    };

    validateOffices = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            officesValidator: aboutValidators.offices(localData, dcConfig, user)
        });
    };

    validatePreviousPlacesLived = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            previousPlacesLivedValidator: aboutValidators.previousPlacesLived(localData, dcConfig, user)
        });
    };

    validateCasketPanel = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            casketPanelValidator: aboutValidators.casketPanel(localData, dcConfig, user)
        });
    };

    validateChapel = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            chapelValidator: aboutValidators.chapel(localData, dcConfig, user)
        });
    };

    validateInsurancePolicyNumber = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            insurancePolicyNumberValidator: aboutValidators.insurancePolicyNumber(localData, dcConfig, user)
        });
    };

    validateWeight = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            weightValidator: aboutValidators.weight(localData, dcConfig, user)
        });
    };

    validateOther = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            otherValidator: aboutValidators.other(localData, dcConfig, user)
        });
    };

    validateHasChildrenUnder18 = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            hasChildrenUnder18Validator: aboutValidators.hasChildrenUnder18(localData, dcConfig, user),
        });
    };

    validateAll() {
        this.validateSuffix();
        this.validateBirthLastName();
        this.validateAka();
        this.validateGender();
        this.validatePronouns();
        this.validateSecurityNumber();
        this.validateHasMemorialService();
        this.validateMinisterOfficiating();
        this.validatePickUpAddress();
        this.validateDropOffAddress();
        this.validateHasStairs();
        this.validateNumberCertifiedDCs();
        this.validateCasket();
        this.validateInsuranceDetails();
        this.validateHair();
        this.validateFacialHair();
        this.validateNails();
        this.validateLipstickColor();
        this.validateMedicalExaminerReleaseNumber();
        this.validateClothingDueBy();
        this.validateReferredToUsBy();
        this.validateHobbies();
        this.validateLodges();
        this.validateClubs();
        this.validateOffices();
        this.validatePreviousPlacesLived();
        this.validateCasketPanel();
        this.validateChapel();
        this.validateInsurancePolicyNumber();
        this.validateWeight();
        this.validateOther();
        this.validateHasChildrenUnder18();
    }

    handleSSNUnknown = (isChecked: boolean) => {
        const changes: Partial<DeathCertificateAboutType> = {
            ssn_unknown: isChecked,
        };

        if (isChecked) {
            changes.ssn = '';
        }

        this.updateData(changes, this.validateSecurityNumber);
    };

    handleNoneSSN = (isChecked: boolean) => {
        const changes: Partial<DeathCertificateAboutType> = {
            ssn_none: isChecked,
        };

        if (isChecked) {
            changes.ssn = '';
        }

        this.updateData(changes, this.validateSecurityNumber);
    };

    handleSSNChange = (value: string) => {
        this.updateData({ ssn: value }, this.validateSecurityNumber);
    };

    handleGenderChange = (gender: GenderEnum) => {
        const { dcConfig } = this.props;
        const { localData } = this.state;

        this.updateData({
            gender,
        }, this.validateGender);
        const isPronounsEnabled = isFieldEnabled({
            key: ConfigurableAboutFieldKey.pronouns,
            config: dcConfig,
        });
        if (!localData.pronouns && isPronounsEnabled) {
            if (gender === GenderEnum.Female) {
                this.handlePronounsChange(PronounsEnum['she/her']);
            } else if (gender === GenderEnum.Male) {
                this.handlePronounsChange(PronounsEnum['he/him']);
            }
        }
    };

    handlePronounsChange = (pronouns: PronounsEnum) => {

        this.updateData({ pronouns }, this.validatePronouns);
    };

    handleMemorialServiceChange = (hasMemorialService: YesNoEnum) => {

        this.updateData({ hasMemorialService }, this.validateHasMemorialService);
    };

    handleClickShowSecurityNumber = () => {
        this.setState({ showSecurityNumber: !this.state.showSecurityNumber });
    };

    render() {
        const {
            classes,
            activeCase,
            isDeathCertificateLocked,
            isEditMode,
            handleOpenSnackbar,
            zIndex
        } = this.props;
        const {
            genderValidator,
            pronounsValidator,
            securityNumberValidator,
            showSecurityNumber,
            hasMemorialServiceValidator,
            localData,
            akaValidator,
            ministerOfficiatingValidator,
            pickUpAddressValidator,
            dropOffAddressValidator,
            hasStairsValidator,
            numberCertifiedDCsValidator,
            casketValidator,
            insuranceDetailsValidator,
            hairValidator,
            facialHairValidator,
            nailsValidator,
            lipstickColorValidator,
            medicalExaminerReleaseNumberValidator,
            clothingDueByValidator,
            referredToUsByValidator,
            hobbiesValidator,
            suffixValidator,
            birthLastNameValidator,
            lodgesValidator,
            clubValidator,
            officesValidator,
            previousPlacesLivedValidator,
            casketPanelValidator,
            chapelValidator,
            insurancePolicyNumberValidator,
            isChangeDecedentNameDialogOpen,
            weightValidator,
            otherValidator,
            hasChildrenUnder18Validator,
        } = this.state;

        return (
            <div ref={this.props.formRef}>
                <FormTitle
                    title={`About ${activeCase.fname}`}
                    complete={this.checkIfTouched() ? this.checkValid() : null}
                    rootClass={GStyles.margin0}
                />
                <form
                    className={classNames(GStyles.overflowHidden, classes.inviteHelperForm)}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container mt="20px" alignItems="flex-end" justifyContent="center">
                        <GButton
                            text="Change Decedent Name"
                            onClick={this.openChangeDecedentNameDialog}
                        />
                    </Grid>

                    <Grid container mt="20px">
                        <DCEntityDetails
                            data={activeCase}
                            fieldsToDisplay={['fname', 'mname', 'lname']}
                            hasMissingField={!activeCase.fname.trim() || !activeCase.lname.trim()}
                            label="Decedent’s Name"
                            requiredFields={['fname', 'lname']}
                            onEdit={this.openChangeDecedentNameDialog}
                        />
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                label="Last Name at Birth"
                                placeholder="(e.g. maiden name)"
                                id={ConfigurableAboutFieldKey.birthLastName}
                                error={birthLastNameValidator === false}
                                value={localData.birthLastName || ''}
                                onChange={this.handleBirthLastNameChange}
                                onBlur={this.validateBirthLastName}
                                disabled={isDeathCertificateLocked}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <GTooltip
                                title="Use the change Decedent Name button to change this"
                                contentDisabled={true}
                            >
                                <ConfigurableTextField
                                    label="Suffix"
                                    id={ConfigurableAboutFieldKey.suffix}
                                    error={suffixValidator === false}
                                    value={activeCase.suffix || ''}
                                    onChange={() => void 0}
                                    disabled={true}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    isEditMode={isEditMode}
                                />
                            </GTooltip>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.aka}
                                label="Also known as (AKA)"
                                value={localData.aka || ''}
                                onChange={this.handleAkaChange}
                                disabled={isDeathCertificateLocked}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                error={akaValidator === false}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={classNames(GStyles.width100, GStyles.marginTop20)}>
                            <ConfigurableToggleButtonsForGender
                                id={ConfigurableAboutFieldKey.gender}
                                options={values(GenderEnum)}
                                selected={localData.gender || null}
                                label={`What is ${activeCase.fname}'s Sex`}
                                disabled={isDeathCertificateLocked}
                                error={genderValidator === false}
                                isEditMode={isEditMode}
                                onSelect={this.handleGenderChange}
                                handleOpenSnackbar={handleOpenSnackbar}
                            />
                        </Grid>
                    </Grid>

                    {localData.gender &&
                        <Grid container alignItems="flex-end" justifyContent="center">
                            <Grid item className={classNames(GStyles.width100, GStyles.marginTop20)}>
                                <ConfigurableToggleButtonsForPronouns
                                    id={ConfigurableAboutFieldKey.pronouns}
                                    options={values(PronounsEnum)}
                                    selected={localData.pronouns || null}
                                    label={`What are ${activeCase.fname}'s Pronouns`}
                                    disabled={isDeathCertificateLocked}
                                    error={pronounsValidator === false}
                                    isEditMode={isEditMode}
                                    onSelect={this.handlePronounsChange}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.textFieldWithIcon}>
                            <ConfigurableFieldWithMultipleCheckboxes
                                id={ConfigurableAboutFieldKey.ssn}
                                error={securityNumberValidator === false}
                                disabled={isDeathCertificateLocked}
                                label="Social Security Number"
                                value={localData.ssn || ''}
                                inputType={showSecurityNumber ? 'text' : 'password'}
                                placeholder="555-55-5555"
                                onChange={this.handleSSNChange}
                                onBlur={this.validateSecurityNumber}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                textFieldClass={classNames(GStyles.width100, DATA_PRIVATE)}
                                rootClass={classNames(GStyles.textLeft)}
                                inputComponent={SecurityNumberMask}
                                pattern={'\d{3}-?\d{2}-?\d{4}'}
                                endAdornment={(
                                    <IconButton
                                        color="secondary"
                                        tabIndex={-1}
                                        className={classes.ssnIconButton}
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowSecurityNumber}
                                        size="large">
                                        {showSecurityNumber ? <NoEncryptionIcon /> : <Lock />}
                                    </IconButton>
                                )}
                                checkBoxData={[{
                                    label: 'SSN Unknown',
                                    onChange: this.handleSSNUnknown,
                                    isChecked: localData.ssn_unknown || false,
                                    disabled: localData.ssn_none || false
                                }, {
                                    label: 'None',
                                    onChange: this.handleNoneSSN,
                                    isChecked: localData.ssn_none || false,
                                    disabled: localData.ssn_unknown || false
                                }]}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={classNames(GStyles.width100, GStyles.marginTop20)}>
                            <ConfigurableToggleButtonsForYesNo
                                id={ConfigurableAboutFieldKey.hasMemorialService}
                                options={values(YesNoEnum)}
                                selected={localData.hasMemorialService || null}
                                label={`Will there be a funeral or memorial service to remember ${activeCase.fname}`}
                                disabled={isDeathCertificateLocked}
                                error={hasMemorialServiceValidator === false}
                                isEditMode={isEditMode}
                                onSelect={this.handleMemorialServiceChange}
                                handleOpenSnackbar={handleOpenSnackbar}
                                buttonClass={classes.memorialtoggleButton}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.ministerOfficiating}
                                value={localData.ministerOfficiating || ''}
                                label="Clergy or Officiant"
                                error={ministerOfficiatingValidator === false}
                                inputType="text"
                                onChange={this.handleMinisterOfficiatingChange}
                                onBlur={this.validateMinisterOfficiating}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableAddressSearch
                                id={ConfigurableAboutFieldKey.pickUpAddress}
                                label="Pick Up Address"
                                searchType={GmapsSearchType.longAddress}
                                value={localData.pickUpAddress || NullLongAddress}
                                onChange={this.handlePickUpAddressChange}
                                error={pickUpAddressValidator === false}
                                disabled={isDeathCertificateLocked}
                                handleOpenSnackbar={handleOpenSnackbar}
                                zIndex={zIndex}
                                isEditMode={isEditMode}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableAddressSearch
                                id={ConfigurableAboutFieldKey.dropOffAddress}
                                label="Drop Off Address"
                                searchType={GmapsSearchType.longAddress}
                                value={localData.dropOffAddress || NullLongAddress}
                                onChange={this.handleDropOffAddressChange}
                                error={dropOffAddressValidator === false}
                                disabled={isDeathCertificateLocked}
                                handleOpenSnackbar={handleOpenSnackbar}
                                zIndex={zIndex}
                                isEditMode={isEditMode}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableToggleButtonsForYesNoUnknown
                                id={ConfigurableAboutFieldKey.facilityHasStairs}
                                error={hasStairsValidator === false}
                                label="Does the facility have stairs?"
                                onSelect={this.handleHasStairsChange}
                                options={values(YesNoUnknownEnum)}
                                isEditMode={isEditMode}
                                selected={localData.hasStairs || null}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableToggleButtonsForYesNoUnknown
                                id={ConfigurableAboutFieldKey.hasChildrenUnder18}
                                error={hasChildrenUnder18Validator === false}
                                label="Does the Decedent have Children under 18?"
                                onSelect={this.handleHasChildrenUnder18}
                                options={values(YesNoUnknownEnum)}
                                isEditMode={isEditMode}
                                selected={localData.hasChildrenUnder18 || null}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.numberOfCertifiedDCs}
                                value={(localData.numberOfDCs && localData.numberOfDCs.toString()) || ''}
                                inputType="number"
                                label="Number of Certified Death Certificates"
                                error={numberCertifiedDCsValidator === false}
                                onChange={this.handleNumberCertifiedDCsChange}
                                onBlur={this.validateNumberCertifiedDCs}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.casket}
                                value={localData.casket || ''}
                                label="Casket"
                                error={casketValidator === false}
                                onChange={this.handleCasketChange}
                                onBlur={this.validateCasket}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.casketPanel}
                                value={localData.casketPanel || ''}
                                label="Casket Panel"
                                error={casketPanelValidator === false}
                                onChange={this.handleCasketPanelChange}
                                onBlur={this.validateCasketPanel}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.insuranceDetails}
                                value={localData.insuranceDetails || ''}
                                label="Insurance Company Name"
                                error={insuranceDetailsValidator === false}
                                onChange={this.handleInsuranceDetailsChange}
                                onBlur={this.validateInsuranceDetails}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.insurancePolicyNumber}
                                value={localData.insurancePolicyNumber || ''}
                                label="Insurance Policy Number"
                                error={insurancePolicyNumberValidator === false}
                                onChange={this.handleInsurancePolicyNumberChange}
                                onBlur={this.validateInsurancePolicyNumber}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.hair}
                                value={localData.hair || ''}
                                label="Hair"
                                error={hairValidator === false}
                                onChange={this.handleHairChange}
                                onBlur={this.validateHair}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.facialHair}
                                value={localData.facialHair || ''}
                                label="Facial Hair"
                                error={facialHairValidator === false}
                                onChange={this.handleFacialHairChange}
                                onBlur={this.validateFacialHair}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.nails}
                                value={localData.nails || ''}
                                label="Nails"
                                error={nailsValidator === false}
                                onChange={this.handleNailsChange}
                                onBlur={this.validateNails}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.lipstickColor}
                                value={localData.lipstickColor || ''}
                                label="Lipstick Color"
                                error={lipstickColorValidator === false}
                                onChange={this.handleLipstickColorChange}
                                onBlur={this.validateLipstickColor}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.medicalExaminerReleaseNumber}
                                value={localData.medicalExaminerReleaseNumber || ''}
                                label="Medical Examiner Release Number"
                                error={medicalExaminerReleaseNumberValidator === false}
                                onChange={this.handleMedicalExaminerReleaseChange}
                                onBlur={this.validateMedicalExaminerReleaseNumber}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableDateField
                                id={ConfigurableAboutFieldKey.clothingDueBy}
                                value={localData.clothingDueBy || ''}
                                label="Clothing Due By"
                                error={clothingDueByValidator === false}
                                onChange={this.handleClothingDueByChange}
                                onBlur={this.validateClothingDueBy}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.referredToUsBy}
                                value={localData.referredToUsBy || ''}
                                label="Referred To Us By"
                                error={referredToUsByValidator === false}
                                onChange={this.handleReferredToUsByChange}
                                onBlur={this.validateReferredToUsBy}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.hobbies}
                                value={localData.hobbies || ''}
                                label="Hobbies"
                                error={hobbiesValidator === false}
                                onChange={this.handleHobbiesChange}
                                onBlur={this.validateHobbies}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.lodges}
                                value={localData.lodges || ''}
                                label="Lodges"
                                error={lodgesValidator === false}
                                onChange={this.handleLodgeChange}
                                onBlur={this.validateLodges}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.clubs}
                                value={localData.clubs || ''}
                                label="Clubs"
                                error={clubValidator === false}
                                onChange={this.handleClubChange}
                                onBlur={this.validateClubs}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.offices}
                                value={localData.offices || ''}
                                label="Offices"
                                error={officesValidator === false}
                                onChange={this.handleOfficesChange}
                                onBlur={this.validateOffices}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.previousPlacesLived}
                                value={localData.previousPlacesLived || ''}
                                label="Previous Places Lived"
                                error={previousPlacesLivedValidator === false}
                                onChange={this.handlePreviousPlacesLivedChange}
                                onBlur={this.validatePreviousPlacesLived}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.chapel}
                                value={localData.chapel || ''}
                                label="Which Chapel?"
                                error={chapelValidator === false}
                                onChange={this.handleChapelChange}
                                onBlur={this.validateChapel}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.weight}
                                value={localData.weight || ''}
                                label="Weight"
                                error={weightValidator === false}
                                onChange={this.handleWeightChange}
                                onBlur={this.validateWeight}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={GStyles.width100}>
                            <ConfigurableTextField
                                id={ConfigurableAboutFieldKey.other}
                                value={localData.other || ''}
                                label="Other"
                                error={otherValidator === false}
                                onChange={this.handleOtherChange}
                                onBlur={this.validateOther}
                                handleOpenSnackbar={handleOpenSnackbar}
                                isEditMode={isEditMode}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                </form>
                <ChangeDecedentNameDialog
                    isOpen={isChangeDecedentNameDialogOpen}
                    gatherCase={activeCase}
                    zIndex={zIndex + 1}
                    onClose={this.closeChangeDecedentNameDialog}
                />
            </div>
        );
    }
}

export default withStyles(styles)(AboutForm);
