import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { getIntercomTargetProp } from '../../services';
import GTooltip from './GTooltip';

export interface ButtonProps<T extends string> {
    size?: 'small' | 'medium' | 'large';
    activeButton: T | null;
    disabled?: boolean;
    controlClasses?: Object;
    labelClass?: string;
    activeClass?: string;
    hidden?: boolean;
    useRememberTheme?: boolean;
    onClick: (value: T, target: HTMLElement) => void;
}

interface PrivateButtonProps<T extends string> {
    value: T;
    className: string | undefined;
    icon: JSX.Element | undefined;
    label: JSX.Element | string;
    badge: JSX.Element | undefined;
    sx?: SxProps<Theme>;
    tooltip?: string;
    tooltipDelay?: number;
    contentDisabledClass?: string;
    intercomTargetProp?: string;
}

type Props<T extends string> = ButtonProps<T> & PrivateButtonProps<T>;

interface MakeStylesProps {
    useRememberTheme: boolean | undefined;
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    groupButton: ({ useRememberTheme }: MakeStylesProps) => ({
        boxShadow: 'none',
        minWidth: 140,
        padding: '6px 0',
        borderRadius: 0,
        minHeight: 33,
        height: 33,
        border: 'none',
        '&:hover': {
            border: 'none',
        },
        '& svg': {
            paddingRight: 4,
            fontSize: 20,
        }
    }),
    label: {
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    }
}), { name: 'GGroupBaseButton' });

const GGroupBaseButton = <T extends string>(props: Props<T>) => {
    const {
        activeButton,
        onClick,
        size,
        disabled,
        controlClasses,
        labelClass,
        activeClass,
        value,
        className,
        icon,
        label,
        badge,
        hidden,
        sx,
        tooltip,
        tooltipDelay,
        contentDisabledClass,
        useRememberTheme,
        intercomTargetProp,
    } = props;

    const classes = useStyles({ useRememberTheme });

    if (hidden) {
        return null;
    }

    return (
        <GTooltip
            title={tooltip}
            contentDisabled={disabled}
            contentDisabledContainerClass={contentDisabledClass}
            enterDelay={tooltipDelay}
        >
            <Button
                color={useRememberTheme ? 'rememberSecondary' : 'primary'}
                size={size}
                variant={activeButton === value && 'contained' || 'outlined'}
                onClick={e => onClick(value, e.currentTarget)}
                className={classNames(
                    classes.groupButton,
                    controlClasses,
                    className,
                    activeButton === value && classNames(activeClass, 'selected'),
                )}
                sx={sx}
                disabled={disabled}
                tabIndex={-1}
                {...getIntercomTargetProp(intercomTargetProp)}
            >
                <span className={classNames(classes.label, labelClass)}>
                    {icon}
                    {label}
                    {badge}
                </span>
            </Button>
        </GTooltip>
    );
};

export default GGroupBaseButton;