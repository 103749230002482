import { isRight } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import {
    DeathCertificateAboutType,
    DeathCertificateConfigUX,
    GatherCaseUX,
    UserProfile,
    YesNoUnknownEnum,
} from '../../../shared/types';
import { formatGatherDate } from '../../utils';
import { isFieldOptional, ConfigurableAboutFieldKey } from './config';

export const aboutValidators = {
    firstName: function (gatherCase: GatherCaseUX): boolean {
        return Boolean(gatherCase.fname?.trim() && isRight(t.string.decode(gatherCase.fname)));
    },
    middleName: function (gatherCase: GatherCaseUX): boolean {
        return Boolean(gatherCase.mname?.trim() && isRight(t.string.decode(gatherCase.mname)));
    },
    lastName: function (gatherCase: GatherCaseUX): boolean {
        return Boolean(gatherCase.lname?.trim() && isRight(t.string.decode(gatherCase.lname)));
    },
    aka: function (
        { aka }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = aka && aka.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.aka, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    gender: function (
        aboutForm: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = aboutForm.gender && isRight(t.string.decode(aboutForm.gender));

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.gender, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    pronouns: function (
        aboutForm: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = aboutForm.pronouns && isRight(t.string.decode(aboutForm.pronouns));

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.pronouns, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    securityNumber: function (
        { ssn, ssn_unknown, ssn_none }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = ssn && ssn.length > 0;
        const isSSNUnknownOrNone = ssn_unknown === true || ssn_none === true;

        if (isValid || isSSNUnknownOrNone) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.ssn, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    hasMemorialService: function (
        aboutForm: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = aboutForm.hasMemorialService && isRight(t.string.decode(aboutForm.hasMemorialService));

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.hasMemorialService, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    ministerOfficiating: function (
        { ministerOfficiating }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = ministerOfficiating && ministerOfficiating.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.ministerOfficiating, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    pickUpAddress: function (
        { pickUpAddress }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValidLocation = pickUpAddress && pickUpAddress.description && pickUpAddress.description.length;
        if (isValidLocation) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.pickUpAddress, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    dropOffAddress: function (
        { dropOffAddress }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValidLocation = dropOffAddress && dropOffAddress.description && dropOffAddress.description.length;
        if (isValidLocation) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.dropOffAddress, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    hasStairs: function (
        { hasStairs }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = hasStairs && hasStairs.length && !!YesNoUnknownEnum[hasStairs];
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.facilityHasStairs, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    numberOfDCs: function (
        { numberOfDCs }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = numberOfDCs !== undefined && !Number.isNaN(numberOfDCs);
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.numberOfCertifiedDCs, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    casket: function (
        { casket }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = casket && casket.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.casket, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    insuranceDetails: function (
        { insuranceDetails }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = insuranceDetails && insuranceDetails.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.insuranceDetails, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    hair: function (
        { insuranceDetails }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = insuranceDetails && insuranceDetails.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.hair, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    facialHair: function (
        { facialHair }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = facialHair && facialHair.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.facialHair, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    nails: function (
        { nails }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = nails && nails.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.nails, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    lipstickColor: function (
        { lipstickColor }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = lipstickColor && lipstickColor.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.lipstickColor, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    medicalExaminerReleaseNumber: function (
        { medicalExaminerReleaseNumber }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = medicalExaminerReleaseNumber && medicalExaminerReleaseNumber.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.medicalExaminerReleaseNumber, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    clothingDueBy: function (
        { clothingDueBy }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = clothingDueBy && Boolean(formatGatherDate(clothingDueBy));
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.clothingDueBy, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    referredToUsBy: function (
        { referredToUsBy }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = referredToUsBy && referredToUsBy.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.referredToUsBy, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    suffix: function (
        gatherCase: GatherCaseUX,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = gatherCase.suffix && gatherCase.suffix.length;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.suffix, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    birthLastName: function (
        { birthLastName }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = birthLastName && birthLastName.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.birthLastName, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    hobbies: function (
        { hobbies }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = hobbies && hobbies.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.hobbies, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    lodges: function (
        { lodges }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = lodges && lodges.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.lodges, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    clubs: function (
        { clubs }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = clubs && clubs.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.clubs, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    offices: function (
        { offices }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = offices && offices.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.offices, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    previousPlacesLived: function (
        { previousPlacesLived }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = previousPlacesLived && previousPlacesLived.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.previousPlacesLived, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    casketPanel: function (
        { casketPanel }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = casketPanel && casketPanel.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.casketPanel, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    chapel: function (
        { chapel }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = chapel && chapel.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.chapel, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    insurancePolicyNumber: function (
        { insurancePolicyNumber }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = insurancePolicyNumber && insurancePolicyNumber.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.insurancePolicyNumber, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    weight: function (
        { weight }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = weight && weight.trim().length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.weight, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    other: function (
        { other }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = other && other.trim().length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.other, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    hasChildrenUnder18: function (
        { hasChildrenUnder18 }: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = hasChildrenUnder18 && hasChildrenUnder18.length && !!YesNoUnknownEnum[hasChildrenUnder18];
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableAboutFieldKey.hasChildrenUnder18, config, user)) {
            return !touchCheck;
        }

        return false;

    },
};

export const aboutMasterValidators = {
    valid: function (
        aboutForm: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return (
            aboutValidators.firstName(gatherCase) &&
            aboutValidators.lastName(gatherCase) &&
            aboutValidators.aka(aboutForm, config, user) &&
            aboutValidators.securityNumber(aboutForm, config, user) &&
            aboutValidators.gender(aboutForm, config, user) &&
            aboutValidators.pronouns(aboutForm, config, user) &&
            aboutValidators.hasMemorialService(aboutForm, config, user) &&
            aboutValidators.ministerOfficiating(aboutForm, config, user) &&
            aboutValidators.pickUpAddress(aboutForm, config, user) &&
            aboutValidators.dropOffAddress(aboutForm, config, user) &&
            aboutValidators.hasStairs(aboutForm, config, user) &&
            aboutValidators.numberOfDCs(aboutForm, config, user) &&
            aboutValidators.casket(aboutForm, config, user) &&
            aboutValidators.insuranceDetails(aboutForm, config, user) &&
            aboutValidators.hair(aboutForm, config, user) &&
            aboutValidators.facialHair(aboutForm, config, user) &&
            aboutValidators.nails(aboutForm, config, user) &&
            aboutValidators.lipstickColor(aboutForm, config, user) &&
            aboutValidators.medicalExaminerReleaseNumber(aboutForm, config, user) &&
            aboutValidators.clothingDueBy(aboutForm, config, user) &&
            aboutValidators.referredToUsBy(aboutForm, config, user) &&
            aboutValidators.suffix(gatherCase, config, user) &&
            aboutValidators.birthLastName(aboutForm, config, user) &&
            aboutValidators.hobbies(aboutForm, config, user) &&
            aboutValidators.lodges(aboutForm, config, user) &&
            aboutValidators.offices(aboutForm, config, user) &&
            aboutValidators.previousPlacesLived(aboutForm, config, user) &&
            aboutValidators.casketPanel(aboutForm, config, user) &&
            aboutValidators.chapel(aboutForm, config, user) &&
            aboutValidators.insurancePolicyNumber(aboutForm, config, user) &&
            aboutValidators.weight(aboutForm, config, user) &&
            aboutValidators.other(aboutForm, config, user) &&
            aboutValidators.hasChildrenUnder18(aboutForm, config, user)
        );
    },
    touched: function (
        aboutForm: DeathCertificateAboutType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return (
            aboutValidators.middleName(gatherCase) ||
            aboutValidators.aka(aboutForm, config, user, true) ||
            aboutValidators.gender(aboutForm, config, user, true) ||
            aboutValidators.pronouns(aboutForm, config, user, true) ||
            aboutValidators.hasMemorialService(aboutForm, config, user, true) ||
            aboutValidators.securityNumber(aboutForm, config, user, true) ||
            aboutValidators.ministerOfficiating(aboutForm, config, user, true) ||
            aboutValidators.pickUpAddress(aboutForm, config, user, true) ||
            aboutValidators.dropOffAddress(aboutForm, config, user, true) ||
            aboutValidators.hasStairs(aboutForm, config, user, true) ||
            aboutValidators.numberOfDCs(aboutForm, config, user, true) ||
            aboutValidators.casket(aboutForm, config, user, true) ||
            aboutValidators.insuranceDetails(aboutForm, config, user, true) ||
            aboutValidators.hair(aboutForm, config, user, true) ||
            aboutValidators.facialHair(aboutForm, config, user, true) ||
            aboutValidators.nails(aboutForm, config, user, true) ||
            aboutValidators.lipstickColor(aboutForm, config, user, true) ||
            aboutValidators.medicalExaminerReleaseNumber(aboutForm, config, user, true) ||
            aboutValidators.clothingDueBy(aboutForm, config, user, true) ||
            aboutValidators.referredToUsBy(aboutForm, config, user, true) ||
            aboutValidators.suffix(gatherCase, config, user, true) ||
            aboutValidators.birthLastName(aboutForm, config, user, true) ||
            aboutValidators.hobbies(aboutForm, config, user, true) ||
            aboutValidators.lodges(aboutForm, config, user, true) ||
            aboutValidators.offices(aboutForm, config, user, true) ||
            aboutValidators.previousPlacesLived(aboutForm, config, user, true) ||
            aboutValidators.casketPanel(aboutForm, config, user, true) ||
            aboutValidators.chapel(aboutForm, config, user, true) ||
            aboutValidators.insurancePolicyNumber(aboutForm, config, user, true) ||
            aboutValidators.weight(aboutForm, config, user, true) ||
            aboutValidators.other(aboutForm, config, user, true)
        );
    },
};
