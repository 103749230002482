import { CSSProperties } from 'react';
import { getIntercomTargetProp, getPhotoUrl } from '../../services';
import { CloudinaryTransformationsType } from '../../shared/types';


interface Props {
    publicId: string;
    width: number;
    height: number;
    cropMode?: string;
    className?: string;
    transforms?: CloudinaryTransformationsType;
    altText?: string;
    style?: CSSProperties;
    intercomTargetProp?: string;
    intercomTargetPropDialogName?: string;
}
const SimpleImage = ({
    publicId,
    width,
    height,
    cropMode,
    className,
    altText,
    transforms,
    style,
    intercomTargetProp,
    intercomTargetPropDialogName,
}: Props) => {
    const localTransforms = transforms || {};
    const transformations: CloudinaryTransformationsType[] = [{
        width,
        height,
        quality: 'auto',
        fetch_format: 'auto',
        crop: cropMode || 'fit',
        ...localTransforms
    }];
    return (
        <img
            src={getPhotoUrl(publicId, transformations) || ''}
            alt={altText || 'Image'}
            className={className}
            style={style}
            {...getIntercomTargetProp((intercomTargetProp && intercomTargetPropDialogName)
                ? `${intercomTargetProp}-${intercomTargetPropDialogName}`
                : (intercomTargetProp && !intercomTargetPropDialogName)
                ? intercomTargetProp : ``)}
        />
    );
};

export default SimpleImage;
