import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';

import GDialogCore, { Props as GDialogCoreProps } from './GDialogCore';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { GStyles } from '../../styles/GStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        '@media (min-width: 600px)': {
            width: '100%',
            maxWidth: '100% !important',
        },
        '& $dialogPaper': {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            '@media (min-width: 600px)': {
                minWidth: 560,
                maxWidth: 560,
                width: 560,
                margin: 32,
                maxHeight: '90vh',
            },
        },
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    header: {
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        '& p': {
            width: 'calc(100vw - 56px)',
            '@media (min-width: 600px)': {
                width: 'calc(560px - 62px)',
            }
        }
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        paddingLeft: 21,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 600px)': {
            fontSize: 28,
        }
    },
    content: {
        overflowY: 'auto',
        textAlign: 'center',
    },
    dialogPaper: {},
    flexContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}), { name: 'GDialog' });

interface Props extends GDialogCoreProps {
    zIndex: number;
    title: JSX.Element | string;
    titleIcon?: JSX.Element;
    closeDialogOutsideClick?: (() => void) | undefined;
}

const GDialog = (props: PropsWithChildren<Props>) => {
    const {
        children,
        title,
        titleIcon,
        onClose,
        closeDialogOutsideClick,
        contentClass,
        titleClass,
        paperClass,
        rootClass,
        disableTypography,
        ...others
    } = props;

    const classes = useStyles();

    return (
        <GDialogCore
            {...others}
            disableTypography={disableTypography}
            onClose={closeDialogOutsideClick ? closeDialogOutsideClick : onClose}
            rootClass={classNames(
                classes.root,
                rootClass,
            )}
            paperClass={classNames(classes.dialogPaper, paperClass)}
            contentClass={classNames(classes.content, contentClass)}
            titleClass={classNames(
                classes.header,
                GStyles.backgroundPrimary,
                titleClass,
            )}
            title={
                <>
                    <ClearIcon
                        sx={{ color: '#fff' }}
                        className={classes.clearIcon}
                        onClick={onClose}
                    />

                    {disableTypography && typeof title !== 'string'
                        ? title
                        :
                        <>
                            <Typography
                                align="center"
                                component="p"
                                noWrap
                                color="#fff"
                                className={classNames(classes.headerText, classes.flexContainer)}
                            >
                                {titleIcon}{title}
                            </Typography>
                        </>
                    }
                </>
            }
        >
            {children}
        </GDialogCore>
    );
};

export default GDialog;
