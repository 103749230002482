import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeGStyles from '../../../../../../styles/makeGStyles';
import StepComponentWrapper from '../StepComponentWrapper';
import { OnAnswerChangedFunction, OnValidityChangedFunction } from './types';
import { AnswerWithUUID, TextQuestionsConfig, TextQuestionsQuestion } from '../../../../../../shared/types';
import useDebounce from '../../../../../common/hooks/useDebounce';

const useStyles = makeGStyles(theme => ({
    root: {},
    customQuestionTextfield: {
        margin: '8px 0 8px 14px',
        width: 'calc(100% - 14px)',
    },
    marginLeft4: {
        marginLeft: 4
    }
}), { name: 'TextQuestionsComponent' });

interface Props {
    isStepCompleted: boolean;
    config: TextQuestionsConfig | null;
    componentId: number;
    saveAttempted: boolean;
    onValidityChanged: OnValidityChangedFunction;
    onAnswerChanged: OnAnswerChangedFunction;
}

const TextQuestionsComponent = (props: Props) => {
    const {
        isStepCompleted,
        config,
        componentId,
        saveAttempted,
        onValidityChanged,
        onAnswerChanged,
    } = props;

    const classes = useStyles();

    const headerText = config?.header_text;

    const [questions, setQuestions] = useState<TextQuestionsQuestion[]>([]);

    // update questions when config updates (especially necessary for Back Office preview)
    useEffect(() => {
        setQuestions(config?.questions ?? []);
    }, [config?.questions]);

    const isValid = useMemo(() => {
        return questions.every((q) => !q.is_required || q.answer?.trim());
    }, [questions]);

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    const debouncedAnswerChanged = useDebounce(onAnswerChanged);

    useEffect(() => {
        const answers: AnswerWithUUID[] = questions
            .filter((q) => q.answer !== undefined)
            .map((q) => ({
                uuid: q.uuid,
                answer: q.answer ?? '',
            }));
        debouncedAnswerChanged(componentId, answers);
    }, [debouncedAnswerChanged, componentId, questions]);

    const handleAnswerChange = (uuid: string, answer: string) => {
        const newQuestions: TextQuestionsQuestion[] = questions.map((q) => {
            if (q.uuid === uuid) {
                return {
                    ...q,
                    answer,
                };
            } else {
                return q;
            }
        });
        setQuestions(newQuestions);
    };

    return (
        <StepComponentWrapper
            hideBorder
            className={classes.padding_0_12}
        >
            {headerText &&
                <Typography color="secondary" className={classNames(classes.fontWeight500, classes.marginLeft4)}>
                    {headerText}
                </Typography>
            }

            {questions.map((question) => (
                <TextField
                    disabled={isStepCompleted}
                    key={question.uuid}
                    fullWidth={true}
                    label={question.question_text}
                    value={question.answer || ''}
                    onChange={(e) => !isStepCompleted && handleAnswerChange(question.uuid, e.currentTarget.value)}
                    className={classes.customQuestionTextfield}
                    required={question.is_required}
                    error={saveAttempted && !question.answer?.trim() && question.is_required}
                />
            ))}

        </StepComponentWrapper>
    );
};

export default TextQuestionsComponent;
