/* eslint-disable max-len */
import * as React from 'react';

export default class TerminalSVG extends React.Component<{ themeFillClass: string; iconStyle?: React.CSSProperties }> {
    render() {
        const { themeFillClass, iconStyle } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" style={{ ...iconStyle }}>
                <rect className={themeFillClass} x="18.45" y="3.67559" width="1.21" height="15.29"/>
                <path className={themeFillClass} d="M17.67,2.99559v18a2.00588,2.00588,0,0,1-2,2h-7a1.99733,1.99733,0,0,1-1.99-2v-18a1.99733,
                1.99733,0,0,1,1.99-2l7,.01A1.99733,1.99733,0,0,1,17.67,2.99559Zm-1.61,17.35v-1.38H14.12v1.38Zm0
                -2.35v-1.37H14.12v1.37Zm0-2.34v-1.38H14.12v1.38Zm0-3.56v-8.98H8.29v8.98Zm-2.92,8.25v-1.38H11.2v1.38Zm0
                -2.35v-1.37H11.2v1.37Zm0-2.34v-1.38H11.2v1.38Zm-2.91,4.69v-1.38H8.29v1.38Zm0-2.35v-1.37H8.29v1.37Zm0
                -2.34v-1.38H8.29v1.38Z"/>
            </svg>
        );
    }
}