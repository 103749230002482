import * as React from 'react';
import classNames from 'classnames';

import Popover, { PopoverReference } from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { AvatarUser, UserProfileSummary, UserRoles } from '../../../shared/types';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { joinNameParts } from '../../../shared/utils';
import HelperPopper from '../../family/helperPopper/HelperPopper';
import Tooltip from '@mui/material/Tooltip';
import UserAvatar from '../../common/UserAvatar';
import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Badge from '@mui/material/Badge';
import { isEqual, partition } from 'lodash';
import { GStyles } from '../../../styles/GStyles';
import CheckBoxItem, { ColorType, LabelType } from './CheckBoxItem';
import BaseAssignAvatar from './BaseAssignAvatar';
import { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { convertHexToRGBA, getFullNameFromCase } from '../../../services';
import { Theme } from '@mui/material/styles';
import { useGSelector } from '../../../types';
import LoadingSpinner from '../../common/LoadingSpinner';
import { Box } from '@mui/system';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import GroupsIcon from '@mui/icons-material/Groups';
import InvitingTeam from './InvitingTeam';
import SearchInput from '../../common/inputElements/SearchInput';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles(
    (theme: Theme) => ({
        checkboxList: {
            outline: 0,
            margin: '0px 10px 0px 20px',
            maxHeight: '400px',
            overflowX: 'hidden',
            overflowY: 'auto',
            '& $checkboxItem': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '&$assignPopover': {
                margin: 0,
                maxHeight: 360,
                // padding: '12px 8px',
                boxShadow: 'inset 0px 0px 6px 0px rgba(0, 0, 0, 0.2)',
                background: convertHexToRGBA(theme.palette.primary.main, 0.12),

            },
        },
        fontSize68: {
            fontSize: 68
        },
        menuClass: {
            borderRadius: '4px',
            maxWidth: 360,
            width: '100%',
            overflow: 'inherit',
        },
        menuHeader: {
            padding: '8px 0px',
            textAlign: 'center',
            outline: 0,
        },
        nonHelperButton: {
            '& $buttonLabel': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            },
            '& $primary': {
                fontWeight: 200,
                textTransform: 'none',
            },
            '& $secondary': {
                textTransform: 'none',
                fontStyle: 'italic',
                fontWeight: 200,
            },
        },
        badgeCount: {
            width: '100%',
            '& span': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                padding: 0,
                borderRadius: '50%',
                height: 32,
                width: 32,
                fontSize: 14,
            },
        },
        overlapDot: {
            top: 2,
            left: 2,
        },
        labelContainer: {
            marginLeft: 0,
            maxWidth: 210,
            '&$maxWidth240': {
                maxWidth: 240,
            },
            '& p': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                lineHeight: '1.1em',
                width: 'auto',
            },
            '& span': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
                lineHeight: '1.1em',
                width: 'auto',
            },
            '& $secondary': {
                fontSize: 14,
                textTransform: 'capitalize',
                fontWeight: 400,
                '&:last-child': {
                    textTransform: 'initial',
                },
            },
            '& $primary': {
                fontSize: 16,
            },
        },
        dividerBox: {
            padding: "5px 10px",
            color: "#FFF",
            textTransform: "uppercase",
            fontSize: "14px",
            backgroundColor: theme.palette.primary.main,
        },
        divider: {
            backgroundColor: theme.palette.primary.main,
            margin: '4px 0',
            height: '0.3px',
        },
        popperFooter: {
            textAlign: 'center',
            '& button': {
                margin: '12px 0',
            },
        },
        badge: {
            top: 6,
            right: 8,
        },
        height40: {
            height: 40,
        },
        itemPadding: {
            padding: '4px 8px',
        },
        lineHeight1_2: {
            lineHeight: '1.2 !important',
        },
        listItemText: {
            padding: '0 16px 0 8px',
            '& $secondary': {
                color: theme.palette.primary.main,
            },
        },
        disabled: {
            opacity: 0.5,
        },
        checkboxItem: {},
        maxWidth240: {},
        assignPopover: {},
        buttonLabel: {},
        primary: {},
        secondary: {},
        item: {},
    }),
    { name: 'BaseAssign' },
);


export interface BaseAssignEntity extends AvatarUser {
    email: string | null;
    user_id: number | null;
    entity_id: number;
    fname: string;
    isAdmin: boolean;
    secondaryText: string | null;
    user: UserProfileSummary | null;
    disabled?: boolean;
}

export interface BaseAssignProps<T extends BaseAssignEntity> {
    items: T[];
    zIndex: number;
    headerText: string;
    addButtonText?: string | JSX.Element;
    assignedTo?: T[];
    assignedToAll?: boolean;
    onAdd?: (zIndex: number) => void;
    onSelect: (
        e: React.MouseEvent<HTMLElement> | null,
        item: T | null,
        assignedTo?: T[],
        assignedToAll?: boolean,
    ) => void;
    popoverElement?: JSX.Element;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
    tooltip?: string;
    onSecondaryAdd?: (zIndex: number) => void;
    secondaryAddButtonText?: string | JSX.Element;
    isMultiSelect?: boolean;
    canAssignToAll?: boolean;
    isEmailRequired?: boolean;
    showToolipForAllUsers?: boolean;
    showTooltipOnCheckbox?: boolean;
    canUnassign?: boolean;
    addButtonDisabled?: boolean;
    addButtonDisabledToolTip?: string;
    addButtonClass?: string;
    anchorReference?: PopoverReference;
    isLoading?: boolean;
    onAddTeamMember?: (zIndex: number) => void;
    onSearch?: (searchText: string) => void;
    alwaysShowSearch?: boolean;
    showFamilyHelpers: boolean;
    showTeamMembers: boolean;
}

const BaseAssign = <T extends BaseAssignEntity>(props: BaseAssignProps<T>) => {
    const {
        items,
        zIndex,
        headerText,
        addButtonText,
        onAdd,
        onSelect,
        popoverElement,
        anchorEl,
        tooltip,
        onSecondaryAdd,
        secondaryAddButtonText,
        isMultiSelect,
        canAssignToAll,
        isEmailRequired,
        showToolipForAllUsers,
        showTooltipOnCheckbox,
        canUnassign,
        addButtonDisabled,
        addButtonDisabledToolTip,
        addButtonClass,
        anchorReference,
        isLoading,
        onAddTeamMember,
        onSearch,
        alwaysShowSearch,
        showTeamMembers,
        showFamilyHelpers,
    } = props;

    const classes = useStyles();

    const gatherCase = useGSelector(({ casesState }) => casesState.selectedCase);

    const shouldAutoFocus = useMediaQuery('@media (pointer: fine)');

    const [helperPopperAnchor, setHelperPopperAnchor] = useState<HTMLElement | null>(null);
    const [activeHelper, setActiveHelper] = useState<T | null>(null);
    const [assignedTo, setAssignedTo] = useState<T[]>(props.assignedTo || []);
    const [assignedToAll, setAssignedToAll] = useState<boolean>(props.assignedToAll || false);
    const [hasAssignedStateChanged, setHasAssignedStateChanged] = useState<boolean>(false);
    const [searchText, setSearchText] = useState("");

    const resetState = useCallback(() => {
        setHelperPopperAnchor(null);
        setActiveHelper(null);
        setAssignedTo(props.assignedTo || []);
        setAssignedToAll(props.assignedToAll || false);
        setHasAssignedStateChanged(false);
    }, [props.assignedTo, props.assignedToAll]);

    useEffect(() => {
        resetState();
    }, [anchorEl, resetState]);

    // reset search text when popover is opened
    useEffect(() => {
        if (anchorEl) {
            setSearchText('');
        }
    }, [anchorEl]);

    useEffect(() => {
        setAssignedTo(props.assignedTo || []);
        setHasAssignedStateChanged(!!anchorEl);
    }, [props.assignedTo, anchorEl]);

    useEffect(() => {
        setAssignedToAll(props.assignedToAll || false);
        setHasAssignedStateChanged(!!anchorEl);
    }, [props.assignedToAll, anchorEl]);

    const getSecondaryText = (person: T): LabelType | null => {
        if (!person.secondaryText?.trim()) {
            return null;
        }

        return {
            title: isEmailRequired && !person.email ? 'Click to add required Email' : person.secondaryText,
            color: ColorType.primary,
        };
    };

    const handleClickEventOnAvatar = (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        helper: T,
    ) => {
        setActiveHelper(helper);
        setHelperPopperAnchor(event.currentTarget);
    };

    const handleCloseHelperPopper = () => {
        setHelperPopperAnchor(null);
    };

    const toggleAssignToAll = (isAssignedToAll: boolean) => {
        setAssignedToAll(isAssignedToAll);
        setAssignedTo([]);
    };

    const assignToEntities = (person: T, isEntityBeingAssigned: boolean) => {
        const assignedToEntities = isEntityBeingAssigned
            ? [...assignedTo, person]
            : assignedTo.filter((at) => at.entity_id !== person.entity_id);

        setAssignedTo(assignedToEntities);
        setAssignedToAll(false);
    };

    const handleClose = () => {
        resetState();
        props.handleClose();
    };

    const handleSelect = (
        e: React.MouseEvent<HTMLElement> | null,
        item: T | null,
        _assignedTo?: T[],
        _assignedToAll?: boolean,
    ) => {
        onSelect(e, item, _assignedTo, _assignedToAll);
        handleClose();
    };

    const isDisabled = (person: T) => {
        return person.disabled || (isEmailRequired && !person.email);
    };

    const renderMultiSelectListItem = (person: T) => {
        const isAssigned = assignedTo && assignedTo.find((a) => a.entity_id === person.entity_id);
        const secondaryText = getSecondaryText(person);
        const tooltipText =
            (tooltip && (showToolipForAllUsers || !person.user_id) && tooltip.replace('{entityFname}', person.fname)) ||
            '';

        return (
            <CheckBoxItem
                uniqueKey={person.entity_id}
                key={person.entity_id}
                label={{
                    primary: { title: joinNameParts(person) },
                    secondary: (secondaryText && [secondaryText]) || undefined,
                }}
                avatar={
                    <BaseAssignAvatar
                        user={person}
                        isAdmin={person.isAdmin}
                        onAvatarClick={(e) => handleClickEventOnAvatar(e, person)}
                    />
                }
                isChecked={Boolean(isAssigned)}
                onCheckBoxChange={(checked) => assignToEntities(person, checked)}
                disabled={isDisabled(person)}
                tooltip={tooltipText}
                showTooltipOnCheckbox={showTooltipOnCheckbox}
            />
        );
    };

    const renderSingleSelectListItem = (person: T) => {
        const { user_id } = person;
        const finalTooltip = tooltip?.replace('{entityFname}', person.fname) || '';
        const secondaryText = getSecondaryText(person);

        return (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <ListItem className={classes.itemPadding} disabled={person.disabled}>
                    <Tooltip title={!user_id ? finalTooltip : ''} placement="top">
                        <UserAvatar
                            user={person}
                            showOverlapDot={person.isAdmin}
                            overlapDotClass={classes.overlapDot}
                            onClick={(e) => {
                                if (gatherCase) {
                                    e.stopPropagation();
                                    handleClickEventOnAvatar(e, person);
                                }
                            }}
                        />
                    </Tooltip>
                    <ListItemText
                        primary={joinNameParts(person)}
                        secondary={secondaryText && secondaryText.title}
                        className={classNames(classes.listItemText, classes.labelContainer, classes.maxWidth240)}
                        classes={{
                            primary: classNames(GStyles.textCapitalize, classes.lineHeight1_2),
                            secondary: classNames(classes.lineHeight1_2, classes.secondary),
                        }}
                    />
                </ListItem>
            </Grid>
        );
    };

    const renderInitialItems = () => {
        return (
            <>
                {(isMultiSelect && (
                    <Box px="10px">
                        <CheckBoxItem
                            key={'unassigned'}
                            uniqueKey={'unassigned'}
                            label={{
                                primary: { title: 'Unassigned' },
                            }}
                            avatar={<BaseAssignAvatar user={null} isAdmin={false} />}
                            isChecked={
                                assignedTo &&
                                assignedTo.length === 0 &&
                                assignedToAll !== undefined &&
                                assignedToAll === false
                            }
                            onCheckBoxChange={(checked) => {
                                if (!checked && !assignedToAll) {
                                    return;
                                }
                                toggleAssignToAll(!checked);
                            }}
                        />

                        {canAssignToAll && (
                            <CheckBoxItem
                                uniqueKey={'assignAll'}
                                key={'assignAll'}
                                label={{
                                    primary: { title: 'Assign to All Helpers' },
                                }}
                                avatar={<BaseAssignAvatar user={null} isAdmin={false} assignToAll />}
                                isChecked={assignedToAll}
                                onCheckBoxChange={(checked) => {
                                    if (!checked && assignedToAll) {
                                        return;
                                    }
                                    toggleAssignToAll(checked);
                                }}
                            />
                        )}
                    </Box>
                )) || (<>
                    {canUnassign && (
                        <div
                            key="Unassigned"
                            className={classNames(classes.item, GStyles.cursorPointer)}
                            onClick={(e) => handleSelect(e, null)}
                        >
                            <ListItem className={classes.itemPadding}>
                                <Avatar>
                                    <PersonOutlineIcon />
                                </Avatar>
                                <ListItemText
                                    primary="Unassigned"
                                    className={classes.listItemText}
                                    classes={{
                                        primary: classNames(GStyles.textCapitalize, classes.lineHeight1_2),
                                    }}
                                />
                            </ListItem>
                        </div>
                    )}
                </>)}
            </>
        );
    };

    const renderAddButtons = (label: string | JSX.Element, title?: string, onClick?: (zIndex: number) => void) => {
        const disableButton = Boolean(addButtonDisabled);
        return (
            <Box pl={!isMultiSelect ? 1 : 0} >
                <Tooltip title={addButtonDisabled ? addButtonDisabledToolTip || '' : ''} placement="top">
                    <Box display={!title ? 'flex' : 'block'} flexDirection={'column'}>
                        {addButtonText && (
                            <Button
                                color="primary"
                                size="large"
                                className={classNames(GStyles.textCapitalize, addButtonClass)}
                                onClick={disableButton ? undefined : (e) => onClick?.(zIndex + 1)}
                                disabled={disableButton}
                                sx={{
                                    padding: "0",
                                    justifyContent: !title ? 'center' : "flex-start",
                                    marginTop: !title ? '16px' : '2px',
                                }}
                            >
                                <Box display="flex" alignItems="center" gap="7px">
                                    <Box width={!title ? '10px' : '40px'} height="40px" sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        '& svg': {
                                            fontSize: !title ? "22px" : "32px",
                                            color: "primary"
                                        }
                                    }}>
                                        <AddIcon />
                                    </Box>
                                    <Box textAlign="start">
                                        <Typography fontSize="16px" lineHeight="16px">{label}</Typography>
                                        <Typography fontSize="14px" color="primary" textTransform="initial">
                                            {title}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Button>
                        )}
                        {(secondaryAddButtonText) && (
                            <Button
                                color="secondary"
                                onClick={disableButton ? undefined : (e) => onSecondaryAdd?.(zIndex + 1)}
                                className={classes.nonHelperButton}
                                classes={{ root: classes.buttonLabel }}
                                disabled={disableButton}
                            >
                                {secondaryAddButtonText}
                            </Button>
                        )}
                    </Box>
                </Tooltip>
            </Box>
        );
    };

    const userData = useGSelector(({ userSession }) => userSession.userData);

    const assignedCount = assignedToAll ? items.length : assignedTo.length || 0;
    const shouldCallOnSelect =
        (canAssignToAll && props.assignedToAll !== undefined && !isEqual(props.assignedToAll, assignedToAll)) ||
        !isEqual(props.assignedTo, assignedTo) ||
        hasAssignedStateChanged;
    const { top, left } = anchorEl ? anchorEl.getBoundingClientRect() : { top: 0, left: 0 };

    const [familyHelpers, teamMembers] = React.useMemo(() => {
        const trimmedSearchText = searchText.trim().toLocaleLowerCase();
        let filteredItems: T[] = items;
        if (trimmedSearchText.length > 0) {
            filteredItems = items.filter((item) => {
                const fullName = getFullNameFromCase({
                    fname: item.fname,
                    lname: item.lname || ''
                }).toLowerCase();
                const relationship = item.secondaryText?.toLowerCase() || '';

                return fullName.includes(trimmedSearchText)
                    || relationship.includes(trimmedSearchText);
            });
        }

        return partition(
            filteredItems,
            (item) => !item.user || UserRoles.isFamilyRole(item.user.role)
        );
    }, [items, searchText]);

    const handleSearch = (text: string) => {
        setSearchText(text);
        onSearch?.(text);
    };

    const isFamilyUser = UserRoles.isFamilyOnCase(userData, (gatherCase?.id || 0));
    const isSearchActive = searchText.trim().length > 0;
    const itemsNotEmpty = items.length > 0;
    const inviteHelpersText = isFamilyUser ?
        gatherCase !== null ? `Invite helpers to collaborate on ${gatherCase.fname}'s care.` :
            `Invite helpers to collaborate on your family's care.` :
        `Inviting helpers creates an amazing family experience.`;

    return (
        <div>
            {popoverElement}

            <Popover
                anchorEl={anchorEl === null ? undefined : anchorEl}
                open={Boolean(anchorEl)}
                onClose={(e) => {
                    if (isMultiSelect && shouldCallOnSelect) {
                        handleSelect(null, null, assignedTo, assignedToAll);
                    }
                    handleClose();
                }}
                classes={{ paper: classes.menuClass }}
                style={{ zIndex }}
                anchorPosition={anchorEl ? { top, left } : undefined}
                anchorReference={anchorReference}
            >
                <Badge
                    badgeContent={assignedCount}
                    showZero
                    className={classes.badgeCount}
                    classes={{
                        root: GStyles.justifyContentCenter,
                        badge: classes.badge,
                    }}
                    invisible={!isMultiSelect}
                >
                    <div className={classes.menuHeader}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {headerText}
                        </Typography>
                    </div>
                </Badge>
                <div className={classNames(classes.checkboxList, classes.assignPopover)} >
                    <div>
                        {(itemsNotEmpty || alwaysShowSearch) &&
                            <Box px="10px" pt={1} pb="10px">
                                <SearchInput
                                    autoFocus={shouldAutoFocus}
                                    searchHandler={handleSearch}
                                    inputLabel={'Type here to search...'}
                                    debounceTime={500}
                                    placeholder="By name or relationships"
                                    hideIcon
                                    />
                            </Box>
                        }
                        {isLoading && (
                            <Box m={2} >
                                <LoadingSpinner containerClass={GStyles.justifyContentCenter} />
                            </Box>
                        )}

                        {!isLoading && <>
                            {!isSearchActive && renderInitialItems()}

                            {showFamilyHelpers && <>

                                {(!isSearchActive || !isFamilyUser) && showFamilyHelpers &&
                                    <Box className={classes.dividerBox}>
                                        Family Helpers&nbsp;({familyHelpers.length})
                                    </Box>
                                }

                                <Box px={isMultiSelect ? "10px" : 0} pb={!searchText.trim ? "8px" : "0px"}>
                                    {familyHelpers.map((familyHelper) => (
                                        <div
                                            key={familyHelper.entity_id}
                                            className={classNames(
                                                classes.item,
                                                !isMultiSelect && !isDisabled(familyHelper) && GStyles.cursorPointer,
                                                isDisabled(familyHelper) && classes.disabled,
                                            )}
                                            onClick={
                                                isDisabled(familyHelper) || isMultiSelect
                                                    ? undefined
                                                    : (e) => handleSelect(e, familyHelper)
                                            }
                                        >
                                            {(isMultiSelect && renderMultiSelectListItem(familyHelper))
                                                || renderSingleSelectListItem(familyHelper)}
                                        </div>
                                    ))}

                                    {!isSearchActive &&
                                        renderAddButtons(
                                            "Add Helper", "Click to invite another helper", () => onAdd?.(zIndex + 1))
                                    }

                                    {!isSearchActive &&
                                        <InvitingTeam
                                            icon={<Diversity1Icon />}
                                            text={inviteHelpersText}
                                            iconClass={classes.fontSize68}
                                            intercomTarget={"InviteTeam-inviteHelpers"}
                                            learnMore={!isFamilyUser}
                                        />
                                    }
                                </Box>
                            </>}

                            {!isFamilyUser && !isSearchActive && showTeamMembers &&
                                <Box className={classes.dividerBox}>Team Members&nbsp;({teamMembers.length})</Box>
                            }

                            {!isFamilyUser && showTeamMembers && <Box px={isMultiSelect ? "10px" : "0px"}
                                pb={isSearchActive ? "2px" : "8px"}>
                                {teamMembers.map((teamMember) => (
                                    <div
                                        key={teamMember.entity_id}
                                        className={classNames(
                                            classes.item,
                                            !isMultiSelect && !isDisabled(teamMember) && GStyles.cursorPointer,
                                            isDisabled(teamMember) && classes.disabled,
                                        )}
                                        onClick={
                                            isDisabled(teamMember) || isMultiSelect
                                                ? undefined
                                                : (e) => handleSelect(e, teamMember)
                                        }
                                    >
                                        {(isMultiSelect &&
                                            renderMultiSelectListItem(
                                                teamMember)) || renderSingleSelectListItem(teamMember)}
                                    </div>
                                ))}
                                {renderAddButtons(
                                    "Add Team Member",
                                    "Click to invite another team member",
                                    onAddTeamMember ?? onAdd
                                )}

                                <InvitingTeam
                                    icon={<GroupsIcon />}
                                    text="Inviting your team into Gather helps increase team 
                                                efficiency and collaboration."
                                    iconClass={classes.fontSize68}
                                    intercomTarget={"InviteTeam-inviteTeam"}
                                    learnMore={true}
                                />
                            </Box>}

                            {addButtonText && !items.length &&
                                <Box mb={2}>
                                    {renderAddButtons(addButtonText, undefined, onAdd)}
                                </Box>
                            }
                        </>}

                    </div>
                </div>

                {(!isLoading && isMultiSelect && (
                    <Grid item xs={12} className={classes.popperFooter}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) =>
                                shouldCallOnSelect ? handleSelect(e, null, assignedTo, assignedToAll) : handleClose()
                            }
                        >
                            Save Assignment
                        </Button>
                    </Grid>
                )) || <div className={classes.height40} />}
            </Popover>

            {gatherCase && (
                <HelperPopper
                    key={activeHelper?.entity_id}
                    popperAnchorEle={helperPopperAnchor}
                    clickAwayListener={handleCloseHelperPopper}
                    closeHelperPopper={handleCloseHelperPopper}
                    activeEntityId={activeHelper?.entity_id ?? null}
                    selectedCase={gatherCase}
                    zIndex={zIndex + 1}
                />
            )}
        </div>
    );
};

export default BaseAssign;
