import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import ReactSignatureCanvas from 'react-signature-canvas';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
    CaseBelongingDecisionType,
    CaseBelongingUX,
    GatherCaseUX, getDecisionLabel,
} from '../../../../../shared/types';
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from '../../../../../constants/colorVariables';
import GDialogNoTitle from '../../../../common/GDialogNoTitle';
import makeGStyles from '../../../../../styles/makeGStyles';
import SignatureComponent from './components/SignatureComponent';
import QuestionText from './QuestionText';
import { YesNoUnknownEnum } from '../../../../../shared/types';
import { getDecisionText, getFinalDecisionText } from '../addCaseBelongingDialog/AddCaseBelonging.dialog';
import GToggleButtons from '../../../../common/GToggleButtons';
import { TrackingContentContext } from './CompleteStep.dialog';
import HelperPopper from '../../../helperPopper/HelperPopper';
import ImageNotSupported from '@mui/icons-material/ImageNotSupported';
import { usePopperState } from '../../../../common/hooks/usePopperState';
import ButtonBase from '@mui/material/ButtonBase';
import { useGDispatch, } from '../../../../../types';
import { openPhotoSwipeDialog } from '../../../../../actions/PhotoSwipe.action';
import { getIntercomTargetProp } from '../../../../../services';

const useStyles = makeGStyles<Props>(theme => ({
    root: {
        color: theme.palette.secondary.main,
        position: 'relative',
        padding: '4px 0 12px',
    },
    closeIcon: {
        position: 'absolute',
        top: 2,
        right: 2,
        fontSize: 28,
        cursor: 'pointer',
        '@media (min-width: 425px)': {
            fontSize: 34
        }
    },
    colorRed: {
        color: RED_COLOR
    },
    itemName: {
        fontSize: 20,
        margin: '0 auto',
        cursor: 'pointer',
        width: 'fit-content',
        '&$disabled': {
            cursor: 'auto',
            textDecoration: 'none !important'
        },
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    description: {
        fontSize: 16,
        cursor: 'pointer',
        width: 'fit-content',
        margin: '0 auto',
        '&$disabled': {
            cursor: 'auto',
            textDecoration: 'none !important',
        },
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    decision: {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        width: 'max-content',
        margin: '-14px auto 0',
        height: 'unset',
        color: theme.palette.common.white,
        backgroundColor: props => props.caseItem.completed_time ? GREEN_COLOR : ORANGE_COLOR,
        '& $label': {
            padding: '4px 12px',
        },
        '&:hover': {
            backgroundColor: props => props.caseItem.completed_time ? GREEN_COLOR : ORANGE_COLOR,
        }
    },
    photo: {
        objectFit: 'cover',
        display: 'block',
        borderRadius: 20,
        width: '100%',
        maxWidth: 300,
        border: props => props.caseItem.completed_time ? `2px solid ${GREEN_COLOR}` : `2px dashed ${ORANGE_COLOR}`,
    },
    editBtn: {
        fontWeight: 400,
        fontSize: 14,
        padding: 2,
        minHeight: 'unset',
        display: 'flex',
        margin: 'auto',
        '& svg': {
            fontSize: 16
        }
    },
    fontSize11: {
        fontSize: 11
    },
    dialogRoot: {
        height: 'unset !important'
    },
    dialogContent: {
        padding: '12px !important'
    },
    removeBtnColor: {
        borderColor: `${RED_COLOR} !important`
    },
    noPhotoPlaceholder: {
        width: '80%',
        maxWidth: 300,
        aspectRatio: '1',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 20,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        '& p': {
            fontSize: 14
        },
        '& svg': {
            fontSize: 80
        }
    },
    signatureContainer: {
        margin: '36px 20px 0',
        [theme.breakpoints.up(375)]: {
            margin: '48px 20px 0',
        }
    },
    signVisible: {
        opacity: 0.30,
        pointerEvents: 'none',
    },
    footerButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '20px 20px 0'
    },
    saveButton: {
        fontSize: 15,
        padding: '10px 8px'
    },
    answerSection: {
        width: 300,
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        margin: '8px auto',
    },
    nextSign: {
        borderTop: `2px solid ${theme.palette.primary.main}`,
        padding: '4px 0 6px'
    },
    padding_4_0: {
        padding: '4px 0'
    },
    toggleButton: {
        height: 28,
    },
    toggleButtonGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    questionText: {
        padding: '0 20px 8px',
        color: theme.palette.primary.main,
        '& p': {
            fontSize: 20,
            lineHeight: 1.25
        }
    },
    chipIcon: {
        fontSize: 14,
        color: `${theme.palette.common.white} !important`
    },
    centerContent: {
        fontSize: 11,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: 11
        }
    },
    disabled: {},
    label: {},
    btnLabel: {},
    hasNoPhoto: {},
    pendingDecision: {}
}), { name: 'CaseBelongingDetailDialog' });

interface Props {
    caseItem: CaseBelongingUX;
    caseFirstName: string;
    zIndex: number;
    isOpen: boolean;
    onClose: () => void;
    isCompleted: boolean;
    context: TrackingContentContext;
    isFHorGOMUserOnFH: boolean;
    selectedCase: GatherCaseUX;
    updateItem: (
        itemId: number,
        name: string,
        description: string | null,
        decision: string,
        signatureCanvas: ReactSignatureCanvas | null,
        decisionVerified: YesNoUnknownEnum
    ) => Promise<void>;
    onEditClick: (caseItem: CaseBelongingUX) => void;
    deleteItem: (caseItem: CaseBelongingUX) => void;
}

const DATE_FORMAT = 'M/D/YYYY @ h:mma z';
const CaseBelongingDetailDialog = (props: Props) => {
    const {
        zIndex,
        isOpen,
        caseItem,
        caseFirstName,
        context,
        isFHorGOMUserOnFH,
        selectedCase,
        onClose,
        updateItem,
        onEditClick,
        deleteItem,
    } = props;

    const {
        name,
        description,
        decision,
        completed_by_full_name,
        updated_by_full_name,
        updated_time,
        completed_time,
        photo_url,
        created_by_full_name,
        created_time,
        completed_by_signature_url,
        decision_signature_url
    } = caseItem;

    const isFamilyPageContext = context === TrackingContentContext.familyPage;
    const simpleView = isFamilyPageContext && !isFHorGOMUserOnFH;

    const classes = useStyles(props);
    const dispatch = useGDispatch();

    const [didSigned, setDidSigned] = useState(false);
    const [decisionVerified, setDecisionVerified] = useState<YesNoUnknownEnum>(YesNoUnknownEnum.Unknown);
    const [saving, setSaving] = useState(false);
    const [popperAnchor, anchorEvents, closePopper] = usePopperState();

    const signatureRef = useRef<ReactSignatureCanvas | null>(null);

    const createdFirstName = updated_by_full_name?.split(' ')[0] || created_by_full_name?.split(' ')[0];
    const createdTimeFormatted = moment.tz(updated_time || created_time, 'America/Boise').format(DATE_FORMAT);
    const completedFirstName = completed_by_full_name?.split(' ')[0];
    const completedTimeFormatted = moment.tz(completed_time, 'America/Boise').format(DATE_FORMAT);
    const label = decision && getDecisionLabel(decision, !!completed_time, caseFirstName);

    const renderEditButton = (buttonText: string) => (
        <Button
            size="small"
            color="secondary"
            className={classes.editBtn}
            onClick={e => onEditClick(caseItem)}
            {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-EditAnswerButton`)}
        >
            <EditIcon />&nbsp;{buttonText}
        </Button>
    );

    useEffect(() => signatureRef.current?.off(), [isOpen]);

    useEffect(() => {
        if (!signatureRef.current) {
            return;
        }

        if (decisionVerified === YesNoUnknownEnum.Yes) {
            signatureRef.current.on();
        } else {
            setDidSigned(false);
            signatureRef.current.clear();
        }
    }, [decisionVerified]);

    const handleSave = async () => {
        setSaving(true);

        await updateItem(
            caseItem.id,
            name,
            description,
            decision,
            signatureRef.current,
            decisionVerified
        );

        setSaving(false);
    };

    const handleImageClick = () => {
        dispatch(openPhotoSwipeDialog('CASE_BELONGINGS', photo_url));
    };

    const isDisableEdit = completed_time || simpleView;

    return (
        <GDialogNoTitle
            zIndex={zIndex}
            isOpen={isOpen}
            paperClass={classes.dialogRoot}
            contentClass={classes.dialogContent}
            onClose={onClose}
            hideCloseIcon={true}
        >
            <Grid
                item={true}
                xs={12}
                className={classes.root}
            >
                <ClearIcon color="secondary" className={classes.closeIcon} onClick={e => onClose()} />

                <Typography
                    align="center"
                    color="secondary"
                    className={classNames(
                        classes.itemName,
                        (isDisableEdit) && classes.disabled
                    )}
                    onClick={() => {
                        if (isDisableEdit) {
                            return;
                        }
                        onEditClick(caseItem);
                    }}
                    {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-ItemName`)}
                >
                    {name}
                </Typography>

                <Typography
                    align="center"
                    color="secondary"
                    className={classNames(classes.description, isDisableEdit && classes.disabled)}
                    onClick={e => !isDisableEdit && onEditClick(caseItem)}
                    {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-ItemDescription`)}
                >
                    {description}
                </Typography>

                {photo_url
                    ?
                    <ButtonBase
                        sx={{ mt: '4px', borderRadius: '20px' }}
                        onClick={handleImageClick}
                        {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-ItemPhoto`)}
                    >
                        <img
                            src={photo_url}
                            alt="Case Belonging"
                            width={300}
                            height={300}
                            className={classes.photo}
                        />
                    </ButtonBase>
                    : <div
                        className={classes.noPhotoPlaceholder}
                        {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-ItemPhoto`)}
                    >
                        <ImageNotSupported color="secondary" />
                        <Typography color="secondary" aria-hidden={true}>No Image</Typography>
                    </div>
                }

                {label &&
                    <Chip
                        icon={!completed_time || isDisableEdit ? <EditIcon className={classes.chipIcon} /> : undefined}
                        className={classes.decision}
                        classes={{ label: classes.label }}
                        onClick={completed_time || isDisableEdit ? undefined : e => onEditClick(caseItem)}
                        label={label}
                        {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-ItemDecisionLabel`)}
                    />
                }

                {!simpleView &&
                    <div className={classes.answerSection}>
                        <div
                            className={classes.padding_4_0}
                            {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-RecordersSignature`)}
                        >
                            <Typography align="center" color="secondary" className={classes.fontSize11}>
                                {createdFirstName} specified "{getDecisionText(caseFirstName, decision)}"
                            </Typography>

                            <img
                                src={decision_signature_url}
                                alt="signature"
                                width={300}
                                height="auto"
                                className={classes.backgroundWhite}
                            />
                            <Typography align="center" color="secondary" className={classes.fontSize13}>
                                {completed_by_full_name} on {createdTimeFormatted}
                            </Typography>
                        </div>

                        {(completed_time && decision && completed_by_signature_url) ?
                            <div
                                className={classes.nextSign}
                                {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-RecordersSignature`)}
                            >
                                <Typography align="center" color="secondary" className={classes.centerContent}>
                                    {completedFirstName} specified "{getFinalDecisionText(caseFirstName, decision)}"
                                </Typography>
                                <div >
                                    <img
                                        src={completed_by_signature_url}
                                        alt="signature"
                                        width={300}
                                        height="auto"
                                        className={classes.backgroundWhite}
                                    />
                                </div>
                                <Typography align="center" color="secondary" className={classes.fontSize13}>
                                    {completed_by_full_name} on {completedTimeFormatted}
                                </Typography>
                            </div>
                            :
                            renderEditButton('EDIT ANSWER')
                        }
                    </div>
                }

                {simpleView &&
                    <Grid
                        container
                        justifyContent="center"
                        className={classNames(classes.marginTop30, classes.marginBottom16)}
                    >
                        <Typography
                            color="secondary"
                            align="center"
                            className={classes.fontSize16}
                        >
                            Does something not look right?&nbsp;
                            <span
                                className={classNames(classes.fontWeight500, classes.clickable)}
                                onClick={anchorEvents.onClick}
                            >
                                Let us know
                            </span>
                        </Typography>
                    </Grid>
                }

                {!completed_time && decision && !simpleView &&
                    <div {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-ItemDecisionButtons`)}>
                        <div className={classes.questionText}>
                            <QuestionText
                                switchValue={decision}
                                caseFirstName={caseFirstName}
                            />
                        </div>
                        {decision !== CaseBelongingDecisionType.unsure &&
                            <GToggleButtons
                                className={classes.toggleButtonGroup}
                                selected={YesNoUnknownEnum[decisionVerified]}
                                onSelect={value => setDecisionVerified(YesNoUnknownEnum[value])}
                                buttons={[{
                                    value: YesNoUnknownEnum.Yes,
                                    label: YesNoUnknownEnum.Yes,
                                    className: classes.toggleButton
                                }, {
                                    value: YesNoUnknownEnum.No,
                                    label: 'Not Yet',
                                    className: classes.toggleButton
                                }]}
                            />
                        }
                    </div>
                }

                {!completed_time && !completed_by_full_name && !simpleView &&
                    <Grid
                        item={true}
                        xs={12}
                        className={classNames(
                            classes.signatureContainer,
                            decisionVerified !== YesNoUnknownEnum.Yes && classes.signVisible
                        )}
                    >
                        <SignatureComponent
                            signatureUrl={null} // completed signature shown elsewhere
                            signerFullName={completed_by_full_name ?? null}
                            hasSignError={false}
                            canvasRef={signatureRef}
                            hasSigned={didSigned}
                            clearSignature={() => {
                                signatureRef.current?.clear();
                                setDidSigned(false);
                            }}
                            onStopDrawing={() => setDidSigned(true)}
                            heading={<>
                                Sign below to verify and attest that this <br />
                                belonging was recorded accurately and completely
                            </>}
                            intercomTargetProp={`TrackingPage-RecordKeptBelonging-SignatureComponent`}
                            intercomTargetPropClearSignature={`TrackingPage-RecordKeptBelonging-ClearSignature`}
                        />
                    </Grid>}

                {simpleView &&
                    <Grid container justifyContent="center">
                        <Button color="primary" variant="contained" onClick={onClose}>
                            Close this window
                        </Button>
                    </Grid>
                }

                {!completed_time && !simpleView &&
                    <Grid item={true} xs={12} className={classes.footerButton}>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classNames(classes.colorRed, classes.removeBtnColor)}
                            onClick={e => deleteItem(caseItem)}
                            disabled={saving}
                            {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-RemoveItemButton`)}
                        >
                            <DeleteForeverIcon color="inherit" />&nbsp;Remove this item
                        </Button>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.saveButton}
                            loading={saving}
                            onClick={handleSave}
                            disabled={saving || decisionVerified === YesNoUnknownEnum.Unknown || !didSigned}
                            {...getIntercomTargetProp(`TrackingPage-RecordKeptBelonging-SaveButton`)}
                        >
                            Save
                        </LoadingButton>
                    </Grid>}
            </Grid>

            <HelperPopper
                selectedCase={selectedCase}
                activeEntityId={selectedCase.assignee_entity_id}
                popperAnchorEle={popperAnchor}
                clickAwayListener={closePopper}
                closeHelperPopper={closePopper}
                zIndex={zIndex + 1}
            />
        </GDialogNoTitle >
    );
};

export default CaseBelongingDetailDialog;
