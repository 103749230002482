import { Component } from 'react';
import classNames from 'classnames';
import { debounce, throttle } from 'lodash';

import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import withStyles, { WithStyles, StyleRulesCallback } from '@mui/styles/withStyles';

import withState from './utilHOC/WithState';

import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';

import { convertHexToRGBA } from '../../services';
import { StoreState } from '../../types';

const SCROLL_OFFSET = 245;

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    navigateLeft: {
        left: 0,
        alignItems: 'flex-start',
        background: 'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%,' +
            'rgba( 255, 255, 255, 1) 100%)',
        '& button': {
            marginLeft: 8
        }
    },
    navigateRight: {
        right: 0,
        alignItems: 'flex-end',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,' +
            'rgba( 255, 255, 255, 1) 100%)',
        '& button': {
            marginRight: 8
        }
    },
    navigationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 10,
        bottom: 1,
        position: 'absolute',
        minWidth: '4vw',
        '@media (min-width: 960px)': {
            minWidth: '10vw',
        },
        height: '100%',
        pointerEvents: 'none',
        '& button': {
            background: theme.palette.common.white,
            boxShadow: theme.shadows[2],
            display: 'none',
            pointerEvents: 'visible',
            '@media (min-width: 768px)': {
                display: 'inline-flex'
            },
            '&:not($hasActiveTheme):hover': {
                background: `${convertHexToRGBA(theme.palette.common.white, 0.8)}`
            }
        },
    },
    hasActiveTheme: {
        '& svg': {
            color: theme.palette.common.white
        }
    }
});

/**
 * get an array of values with (+error) and (-error)
 */
const getScrollValuesWithError = (value: number, error: number) => {
    let array = [value];
    for (let i = 1; i <= error; i++) {
        array.push(value + i);
        array.push(value - i);
    }
    return array;
};

const mapStateToProps = ({ whiteLabel }: StoreState) => {
    return {
        hasRememberTheme: Boolean(whiteLabel.rememberTheme),
        themeSecondaryColor: whiteLabel.rememberTheme?.secondary_color,
    };
};

type Classes = 'navigationContainer' | 'navigateRight' | 'navigateLeft' | 'hasActiveTheme';
type StyledProps = WithStyles<Classes>;

interface Props {
    scrollElement: HTMLElement | null;
    /**
     * Register Scroll & Resize Events
     */
    registerEvents: boolean;
    containerClass?: string;
    useApproxValue?: boolean;
    leftButtonClass?: string;
    rightButtonClass?: string;
}

interface State {
    windowWidth: number;
    leftScroll: number;
    isRegistered: boolean;
    eleScrollWidth: number;
}

type CombinedProps = Props & StyledProps & ReturnType<typeof mapStateToProps>;
class ScrollControls extends Component<CombinedProps, State> {
    state: State = {
        windowWidth: window.innerWidth,
        leftScroll: 0,
        isRegistered: false,
        eleScrollWidth: this.props.scrollElement && this.props.scrollElement.scrollWidth || 0
    };

    private debouncedResizeHandler = debounce(() => this.handleResize(), 500);
    private throttledScrollHandler = throttle(() => this.handleScroll(), 200);
    private scrollOptions: ScrollToOptions = {
        top: 0,
        behavior: 'smooth'
    };

    componentDidMount() {
        this.registerEvents();
    }

    registerEvents = () => {
        const { registerEvents, scrollElement } = this.props;

        if (registerEvents && scrollElement) {
            window.addEventListener('resize', this.debouncedResizeHandler, { passive: true });
            scrollElement.addEventListener('scroll', this.throttledScrollHandler, { passive: true });
            this.setState({ isRegistered: true });
        }
    };

    componentDidUpdate() {
        const { registerEvents, scrollElement } = this.props;
        const { isRegistered, eleScrollWidth } = this.state;

        if (registerEvents && !isRegistered) {
            this.registerEvents();
        }

        if (scrollElement) {
            const currentEleWidth = scrollElement.scrollWidth;
            if (eleScrollWidth !== currentEleWidth) {
                this.setState({ eleScrollWidth: currentEleWidth });
            }
        }
    }

    componentWillUnmount() {
        const { registerEvents, scrollElement } = this.props;

        if (registerEvents && scrollElement) {
            window.removeEventListener('resize', this.debouncedResizeHandler);
            scrollElement.removeEventListener('scroll', this.throttledScrollHandler);
        }

        this.debouncedResizeHandler.cancel();
        this.throttledScrollHandler.cancel();
    }

    render() {
        const {
            scrollElement,
            classes,
            containerClass,
            useApproxValue,
            hasRememberTheme,
            themeSecondaryColor,
            rightButtonClass,
            leftButtonClass,
        } = this.props;

        if (!scrollElement) {
            return null;
        }

        const scrollLeft = scrollElement.scrollLeft || 0;

        const renderRightButton = scrollElement.offsetWidth < scrollElement.scrollWidth;

        const hideRightButton = parseInt(`${scrollLeft}`, 10) ===
            (scrollElement.scrollWidth - scrollElement.offsetWidth);

        const scrollValues = getScrollValuesWithError(scrollLeft, 1);
        const hideRightButtonForApproxValue = scrollValues.find(value =>
            value === (scrollElement.scrollWidth - scrollElement.offsetWidth)
        );

        const showleftArrow = scrollElement.scrollLeft > 0;
        const showRightArrow = renderRightButton && (
            useApproxValue
                ? hideRightButtonForApproxValue === undefined
                : !hideRightButton
        );

        return (
            <>
                {showleftArrow &&
                    <div
                        className={classNames(
                            classes.navigateLeft,
                            classes.navigationContainer,
                            containerClass,
                            leftButtonClass
                        )}
                    >
                        <IconButton
                            className={hasRememberTheme && classes.hasActiveTheme || undefined}
                            onClick={e => this.scrollLeft(scrollElement)}
                            style={{ background: themeSecondaryColor }}
                        >
                            <NavigateBefore color="primary" />
                        </IconButton>
                    </div>
                }
                {showRightArrow &&
                    <div
                        className={classNames(
                            classes.navigateRight,
                            classes.navigationContainer,
                            containerClass,
                            rightButtonClass
                        )}
                    >
                        <IconButton
                            className={hasRememberTheme && classes.hasActiveTheme || undefined}
                            onClick={e => this.scrollRight(scrollElement)}
                            style={{ background: themeSecondaryColor }}
                        >
                            <NavigateNext color="primary" />
                        </IconButton>
                    </div>
                }
            </>
        );
    }

    getScrollBy = (element: HTMLElement) => {
        const isSmallerThanWindow = element.offsetWidth < window.innerWidth;

        if (isSmallerThanWindow) {
            return element.offsetWidth - 40;
        }

        return window.innerWidth - SCROLL_OFFSET;
    };

    scrollLeft = (scrollElement: HTMLElement) => {
        scrollElement.scrollBy(
            { ...this.scrollOptions, left: -this.getScrollBy(scrollElement) }
        );
    };

    scrollRight = (scrollElement: HTMLElement) => {
        scrollElement.scrollBy(
            { ...this.scrollOptions, left: this.getScrollBy(scrollElement) }
        );
    };

    handleResize = () => {
        const { windowWidth } = this.state;

        const currentWindowWidth = window.innerWidth;
        if (windowWidth !== currentWindowWidth) {
            this.setState({ windowWidth: window.innerWidth });
        }
    };

    handleScroll = () => {
        const { scrollElement } = this.props;
        const { leftScroll } = this.state;

        const currentLeftScroll = scrollElement && scrollElement.scrollLeft || 0;
        if (scrollElement && leftScroll !== currentLeftScroll) {
            this.setState({ leftScroll: currentLeftScroll });
        }
    };
}

export default withState(mapStateToProps)(withStyles(styles)(ScrollControls));