import React from 'react';
export { showIntercom, bootIntercom, reBootIntercom, shutdownIntercom } from './intercom.service';
import { showIntercom } from './intercom.service';
import { getPhotoUrl } from './photo.service';
import momentTz from 'moment-timezone';
export * from '../shared/utils';
import TagManager from 'react-gtm-module';

import { GATHER_ICON } from '../constants';

import {
    CloudinaryTransformationsType,
    FuneralHomeUX,
    GatherCaseUX,
    UserProfile,
    GatherCasePublic,
    UserRoles,
    EntityRecord,
    EntitySummary,
} from '../shared/types';

import { APP_PRIMARY_COLOR } from '../constants/colorVariables';
import {
    isValidPhoneNumber,
    getPhoneNumberParts,
    splitFullName,
    joinNameParts,
    rememberPageTitle,
} from '../shared/utils';
import { UserSession } from '../types';
import { DeathCertificateStatus } from '../shared/death_certificate/validators';
import { buildPathFromRoute, buildURLString, getDatesDiffDurations, PathType } from '.';
import { log } from '../logger';
import { AdminRoutePage, RouteBuilder } from '../shared/navigationUtils';
export {
    getFhAddress,
    getAddressFromGatherEvent,
    getAddressFromLongAddress,
    getEntityHomeAddress,
    getRolodexAddress,
    getUserAddress,
    emptyStringParser,
} from '../shared/utils/address';

const hexToRgba = require('hex-to-rgba');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var ga: any;

interface ManifestIcons {
    src: string;
    sizes: string;
    type: 'image/png';
}

class GenerateManifestIcon {
    src: string;
    sizes: string;
    type: 'image/png';
    constructor(srcUrl: string, iconSizes: string) {
        this.sizes = iconSizes;
        this.src = srcUrl;
    }
}

/**
 * This function formats the phone number as per US format
 *
 * Valid test cases:
 * +12123334444         (212) 333-4444
 * 12123334444          (212) 333-4444
 * 2123334444           (212) 333-4444
 * +1 (212) 333-4444    (212) 333-4444
 * +1 (212)333-4444     (212) 333-4444
 * +12123334444      (212) 333-4444
 * +1 212-333-4444      (212) 333-4444
 * (212) 333-4444       (212) 333-4444
 * (212)333-4444        (212) 333-4444
 * 212-333-4444         (212) 333-4444
 * 
 * Invalid test cases:
 * 212-333-44444        null
 * 212-333-444          null
 * +43 212-333-4444     null
 */
export const getFormattedPhoneNumber = (phoneNumber: string | null): string | null => {
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
        return phoneNumber;
    }
    // remove non-digits, then remove a leading '1' and require the remaining to be 10 digits
    const parts = getPhoneNumberParts(phoneNumber);
    if (!parts) {
        return phoneNumber;
    }
    return `(${parts.areaCode}) ${parts.prefix}-${parts.subscriber}`;
};

export const arePhoneNumbersEqual = (phone1: string | null, phone2: string | null) => {
    const phone1Formatted = getFormattedPhoneNumber(phone1);
    const phone2Formatted = getFormattedPhoneNumber(phone2);
    return phone1Formatted === phone2Formatted;
};

export const generateNameInitials = (firstName: string = '', lastName: string | null = '') => {
    const fullName = `${firstName} ${lastName || ''}`;
    const matches = fullName.match(/\b(\w)/g);
    const initals = matches !== null && matches !== undefined ? matches.join('') : '';

    return initals.substring(0, 2);
};

export const getHyphenedName = (fullName: string): string => {
    return fullName.toLowerCase().split(' ').join('-');
};

export const sortList = <T>(arraylist: Array<T>, property: string, orderBy: 'ASC' | 'DESC'): Array<T> => {
    return arraylist.sort((a: T, b: T) => orderBy === 'ASC' ?
        (b[property] > a[property] ? -1 : 1) : (b[property] < a[property] ? 1 : -1));
};

export const eliminateWhiteSpaces = (stringValue: string | null): string | null => {
    if (!stringValue) {
        return null;
    }
    return stringValue.replace(/  +/g, ' ');
};

export const getAgeString = (birthDate: string, deathDate: string): string => {
    if (birthDate === '' || deathDate === '') {
        return '-';
    }

    const years = momentTz(deathDate).diff(birthDate, 'years');
    const months = momentTz(deathDate).diff(birthDate, 'months');
    // const days = momentTz(deathDate).diff(birthDate, 'days');
    return months < 24 ? `${months} months` : `${years} years`;
};

export const getAgeInNumber = (birthDate: string, deathDate: string): number => {
    if (birthDate === '' || deathDate === '') {
        return 0;
    }
    return momentTz(deathDate).diff(birthDate);
};

type HasVitalDates = {
    dob_date: string | null;
    dod_start_date: string | null;
    dod_start_time: string;
};

export const deathOrAge = (
    activeCase: HasVitalDates,
    unknown: string = '',
    showYearsOnly: boolean = false,
    hideZeros?: boolean
): string => {
    let result: string = unknown;
    const deathDate = activeCase.dod_start_time && activeCase.dod_start_date
        ? momentTz(`${activeCase.dod_start_date}T${activeCase.dod_start_time}`)
        : momentTz(activeCase.dod_start_date || '');

    if (deathDate.isValid() && activeCase.dob_date) {
        const birthDate = momentTz(activeCase.dob_date);
        if (birthDate.isValid()) {
            const { years, months, days } = getDatesDiffDurations({
                older: birthDate,
                newer: deathDate,
            });

            const monthsInNumber = deathDate.diff(birthDate, 'months');

            const monthsPlural = months === 1 ? '' : 's';
            const daysPlural = days === 1 ? '' : 's';

            result = '';
            if (monthsInNumber < 24) {
                if (hideZeros) {
                    if (days !== 0 && monthsInNumber !== 0) {
                        result = `${monthsInNumber} Month${monthsPlural} and ${days} day${daysPlural} Old`;
                    } else if (monthsInNumber !== 0 && days === 0) {
                        result = `${monthsInNumber} Month${monthsPlural} Old`;
                    } else if (monthsInNumber === 0 && days !== 0) {
                        result = `${days} Day${daysPlural} Old`;
                    }
                } else {
                    result = `${monthsInNumber} Month${monthsPlural} Old`;
                }
            } else if (showYearsOnly || (days === 0 && months === 0 && hideZeros)) {
                result = `${years} Years Old`;
            } else if (hideZeros) {
                if (months !== 0 && days !== 0) {
                    result = `${years} Years ${months} Month${monthsPlural} and ${days} day${daysPlural} Old`;
                } else if (days === 0 && months !== 0) {
                    result = `${years} Years and ${months} Month${monthsPlural} Old`;
                } else if (months === 0 && days !== 0) {
                    result = `${years} Years and ${days} day${daysPlural} Old`;
                }
            } else {
                result = `${years} Years and ${months} Month${monthsPlural} Old`;
            }
        } else {
            const now = momentTz();
            const days = now.diff(deathDate, 'days');
            result = days === 0 ? 'Died Today' : days === 1 ? 'Died Yesterday' : `Died ${deathDate.fromNow()}`;
        }
    }
    return result;
};

export const emptyValueValidator = (stringValue: string | null): boolean => {
    return stringValue ? stringValue.length === 0 ? true : stringValue.trim().length === 0 ? true : false : true;
};

export const sortByProperty = <T extends object>(property: keyof T, isString: boolean, order: 'asc' | 'desc') =>
    (a: T, b: T): number => {
        let x = (property in a && a[property]) ?? '';
        if (isString && typeof x === 'string') {
            x = x.toLowerCase();
        }

        let y = (property in b && b[property]) ?? '';
        if (isString && typeof y === 'string') {
            y = y.toLowerCase();
        }

        return (x < y ? -1 : 1) * (order === 'desc' ? -1 : 1);
    };

export const isTouchDevice = (): boolean => {
    return (document.documentElement && 'ontouchstart' in document.documentElement)
        || 'ontouchstart' in window
        || (navigator && navigator.maxTouchPoints && navigator.maxTouchPoints > 0)
        // || (navigator && navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 0)
        || false;
};

export const hasScrollBar = (): boolean => {
    return document.body.scrollHeight > window.innerHeight;
};

export const convertHexToRGBA = (hex: string, opacity: number): string => {
    return hexToRgba(hex, opacity);
};

export const getFullNameFromCase = (c: { fname: string; lname: string }): string =>
    `${c.fname} ${c.lname}`.trim();

export const getDisplayNameFromCase = (c: { display_full_name: string }): string =>
    `${c.display_full_name}`.trim();

export const getFullNameFromUser = (u: UserProfile): string => joinNameParts(u).trim();

export const getFullNameFromEntity = (e: EntityRecord | EntitySummary): string => joinNameParts(e).trim();

export const setElementStyleById = (elementId: string, styleType: string, styleProp: string): void => {
    const htmlElement: HTMLElement | null = document.getElementById(elementId);
    if (htmlElement) {
        htmlElement.setAttribute('style', `${styleType}: ${styleProp} !important`);
    }
};

export const setElementStyleByName = (elementName: string, styleType: string, styleProp: string): void => {
    const htmlElement: HTMLElement | null = document.getElementsByName(elementName)[0];
    if (htmlElement) {
        htmlElement.setAttribute('style', `${styleType}: ${styleProp} !important`);
    }
};

export const setElementStyleByRef = <T extends HTMLElement>(
    ref: React.RefObject<T>,
    styleType: string,
    styleProp: string
): void => {
    const currentEl = ref.current;
    if (currentEl) {
        currentEl.setAttribute('style', `${styleType}: ${styleProp} !important`);
    }
};

export const openInterCom = () => {
    setElementStyleById('intercom-container', 'z-index', '1400');
    setElementStyleByName('intercom-launcher-frame', 'z-index', '1400');
    showIntercom();
};

export const getCurrentHostname = (): string => {
    return `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`;
};

export const isIOSDevice = () => {
    const userAgent = window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
};

export function isSafariBrowser() {
    const userAgent = navigator.userAgent;
    const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome');
    return isSafari;
}

export const isAndroidDevice = () => {
    const userAgent = window.navigator.userAgent;
    return /Android/.test(userAgent);
};

const GATHER_MANIFEST = {
    'short_name': 'Gather',
    'name': 'Gather',
    'icons': [],
    'start_url': '',
    'display': 'standalone',
    'theme_color': '#000000',
    'background_color': '#ffffff'
};

export const setAppManifest = (params: {
    name: string;
    shortName: string;
    startUrl: string;
    themeColor: string;
    iconPublicId: string;
    iconTransformations: CloudinaryTransformationsType[];
}) => {
    const { name, shortName, startUrl, themeColor, iconPublicId, iconTransformations } = params;
    const sizes = [72, 96, 128, 144, 152, 192, 384, 512];
    const icons: ManifestIcons[] = sizes.map((size) => {
        return new GenerateManifestIcon(
            getPhotoUrl(iconPublicId, [...iconTransformations, {
                width: size,
                height: size,
                crop: 'limit',
            }]),
            `${size}x${size}`,
        );
    });

    const iconSrcUrl = getPhotoUrl(iconPublicId, [...iconTransformations, {
        width: 192,
        height: 192,
        crop: 'limit',
    }]);

    const manifestJson = {
        ...GATHER_MANIFEST,
        name,
        short_name: shortName,
        start_url: `${getCurrentHostname()}${startUrl}`,
        theme_color: themeColor,
        icons
    };

    const blob = new Blob([JSON.stringify(manifestJson)], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);

    var gatherManifestEle: null | HTMLAnchorElement = window.document.querySelector('#gather-manifest');
    if (gatherManifestEle) {
        gatherManifestEle.href = manifestURL;
    }

    // Update the browser tab title
    window.document.title = name;

    // update the icons path on index.html
    var favIcon: null | HTMLAnchorElement = window.document.querySelector('#fav-icon');
    if (favIcon) {
        // NOTE: This favicon is OVERWRITING the favicon generated by the API in reactapp.ts
        favIcon.href = iconSrcUrl;
    }

    var favAppleIcon: null | HTMLAnchorElement = window.document.querySelector('#fav-apple-icon');
    if (favAppleIcon) {
        favAppleIcon.href = iconSrcUrl;
    }

    var favAppleIconPrecomposed: null | HTMLAnchorElement =
        window.document.querySelector('#fav-apple-icon-precomposed');
    if (favAppleIconPrecomposed) {
        favAppleIconPrecomposed.href = iconSrcUrl;
    }
};

export enum AppManifestType {
    GatherAdmin = 'GatherAdmin',
    FHDashboard = 'FHDashboard',
    Family = 'Family',
    Remember = 'Remember',
}

interface GenerateAppManifestBase {
    type: AppManifestType;
    gatherCase?: GatherCaseUX | GatherCasePublic;
    funeralHome?: FuneralHomeUX;
}

interface GatherAdminAppManifest extends GenerateAppManifestBase {
    type: AppManifestType.GatherAdmin;
}

interface FHDashboardAppManifest extends GenerateAppManifestBase {
    type: AppManifestType.FHDashboard;
    funeralHome: FuneralHomeUX;
}

interface FamilyAppManifest extends GenerateAppManifestBase {
    type: AppManifestType.Family;
    gatherCase: GatherCaseUX;
}

interface RememberAppManifest extends GenerateAppManifestBase {
    type: AppManifestType.Remember;
    gatherCase: GatherCasePublic;
}

type GenerateAppManifest = GatherAdminAppManifest | FHDashboardAppManifest | FamilyAppManifest | RememberAppManifest;

export const generateAppManifest = ({ type, funeralHome, gatherCase }: GenerateAppManifest): void => {
    let iconPublicId: string = '';
    let iconTransformations: CloudinaryTransformationsType[] = [];
    switch (type) {
        case AppManifestType.GatherAdmin: {

            const urlPath = buildPathFromRoute(RouteBuilder.Admin(AdminRoutePage.ROOT));
            const startUrl = urlPath.type === PathType.DISABLED ? '' : buildURLString(urlPath.urlParts);

            setAppManifest({
                name: GATHER_MANIFEST.name,
                shortName: GATHER_MANIFEST.short_name,
                startUrl,
                themeColor: APP_PRIMARY_COLOR,
                iconPublicId: GATHER_ICON,
                iconTransformations: [],
            });
            break;
        }
        case AppManifestType.FHDashboard: {

            iconPublicId = funeralHome.icon || GATHER_ICON;
            if (funeralHome.icon_transformations && funeralHome.icon_transformations.cloudinary) {
                iconTransformations = [funeralHome.icon_transformations.cloudinary];
            }

            const urlPath = buildPathFromRoute(RouteBuilder.FuneralHome(funeralHome.key));
            const startUrl = urlPath.type === PathType.DISABLED ? '' : buildURLString(urlPath.urlParts);

            setAppManifest({
                name: funeralHome.name ? funeralHome.name : GATHER_MANIFEST.name,
                shortName: splitFullName(funeralHome.name || GATHER_MANIFEST.short_name).fname,
                startUrl,
                themeColor: funeralHome.custom_assets ? funeralHome.custom_assets.themeColor : APP_PRIMARY_COLOR,
                iconPublicId,
                iconTransformations,
            });
            break;
        }
        case AppManifestType.Family: {

            iconPublicId = gatherCase.photo || gatherCase.funeral_home.icon || GATHER_ICON;
            if (gatherCase.photo_transformations && gatherCase.photo_transformations.cloudinary) {
                iconTransformations = [{
                    ...gatherCase.photo_transformations.cloudinary,
                    radius: 0, // make case photo square
                }];
            } else if (gatherCase.funeral_home.icon_transformations &&
                gatherCase.funeral_home.icon_transformations.cloudinary) {
                iconTransformations = [gatherCase.funeral_home.icon_transformations.cloudinary];
            }

            const fullName = joinNameParts(gatherCase);

            const urlPath = buildPathFromRoute(RouteBuilder.FamilyPage({
                caseName: gatherCase.name,
                funeralHomeKey: gatherCase.funeral_home.key,
            }));
            const startUrl = urlPath.type === PathType.DISABLED ? '' : buildURLString(urlPath.urlParts);

            setAppManifest({
                name: gatherCase.fname || gatherCase.lname ? fullName.trim() : GATHER_MANIFEST.name,
                shortName: gatherCase.fname || GATHER_MANIFEST.short_name,
                startUrl,
                themeColor: gatherCase.funeral_home.custom_assets
                    ? gatherCase.funeral_home.custom_assets.themeColor
                    : APP_PRIMARY_COLOR,
                iconPublicId,
                iconTransformations,
            });
            break;
        }
        case AppManifestType.Remember: {

            iconPublicId = gatherCase.photo || gatherCase.funeral_home.icon || GATHER_ICON;
            if (gatherCase.photo_transformations && gatherCase.photo_transformations.cloudinary) {
                iconTransformations = [{
                    ...gatherCase.photo_transformations.cloudinary,
                    radius: 0, // make case photo square
                }];
            } else if (gatherCase.funeral_home.icon_transformations &&
                gatherCase.funeral_home.icon_transformations.cloudinary) {
                iconTransformations = [gatherCase.funeral_home.icon_transformations.cloudinary];
            }

            const casename = rememberPageTitle(gatherCase, gatherCase.funeral_home);
            const urlPath = buildPathFromRoute(RouteBuilder.RememberPage(gatherCase.name));
            const startUrl = urlPath.type === PathType.DISABLED ? '' : buildURLString(urlPath.urlParts);

            setAppManifest({
                name: casename,
                shortName: gatherCase.fname || GATHER_MANIFEST.short_name,
                startUrl,
                themeColor: gatherCase.funeral_home.custom_assets
                    ? gatherCase.funeral_home.custom_assets.themeColor
                    : APP_PRIMARY_COLOR,
                iconPublicId,
                iconTransformations,
            });
            break;
        }
        default:
            log.warn('Unknown AppManifest type', { type });
            break;
    }
};

export const isViewAsGOM = (userSession: UserSession): boolean => {
    const { userData, hasFeaturesOverride } = userSession;
    return Boolean(UserRoles.isGOMUser(userData) && hasFeaturesOverride);
};

export const sanitizeCaseName = (caseName: string): { caseName: string; redirect: boolean } => {
    const sanitizedCaseName = caseName.toLowerCase().replace(/[^a-z0-9\-]/gi, '');
    return {
        redirect: sanitizedCaseName !== caseName,
        caseName: sanitizedCaseName,
    };
};

interface DataLayerItem {
    event?: string;
    pageUrl?: string;
    ecommerce?: object | null;
    data?: object;
}

declare global {
    // dataLayer is the variable that Google Tag Manager puts on the window.
    // See https://developers.google.com/tag-platform/tag-manager/datalayer
    interface Window {
        dataLayer?: DataLayerItem[];
    }
}

export const setupGoogleTagManager = () => {
    if (process.env.REACT_APP_GTM_ID) {
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GTM_ID,
        });
    }
};

interface GTMDataEvent extends DataLayerItem {
    pageUrl: string;
    event: string;
    data: object;
}

let lastFHKey: string | undefined = undefined;
export const setGTMFuneralHomeContext = (funeralHomeKey: string | undefined) => {
    if (funeralHomeKey && funeralHomeKey !== lastFHKey) {
        addGTMDataEvent({
            pageUrl: window.location.href,
            event: 'funeral-home-context',
            data: {
                funeralHomeKey,
            },
        });
        lastFHKey = funeralHomeKey;
    }
};

export const addGTMDataEvent = (event: GTMDataEvent) => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push(event);
};

export const addGTMEcommerceEvent = (params: { event: string; ecommerce: object }) => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        pageUrl: window.location.href,
        ...params,
    });
};

export const getDecedentAge = (gatherCase: { dob_date: string | null; dod_start_date: string | null }) => {
    const bDate = gatherCase.dob_date && momentTz(gatherCase.dob_date) || null;
    const dDate = gatherCase.dod_start_date && momentTz(gatherCase.dod_start_date) || null;

    const diff = bDate && dDate && (dDate || momentTz()).diff(bDate, 'years') || null;
    const age = diff && diff >= 1 && diff || null;

    return {
        bDate,
        dDate,
        diff,
        age
    };
};

export const getDeathCertificateStatus = (
    caseRecord: { death_certificate_percentage: number | null }
): DeathCertificateStatus => {
    const deathCertificateSections = 10;
    const completedSections = caseRecord.death_certificate_percentage ?
        caseRecord.death_certificate_percentage / deathCertificateSections : 0;
    return {
        validSections: completedSections,
        invalidSections: deathCertificateSections - completedSections,
        percentComplete: caseRecord.death_certificate_percentage ? caseRecord.death_certificate_percentage : 0,
    };
};

/** *this function can be used for the title*/
export const flagTitle = (name: string) => {
    return (`Flagging as inappropriate will alert someone at 
    ${name} to review. If blocked, this will be removed.`);
};

export const escapeRegExp = (str: string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
