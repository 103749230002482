// TODO: JJT (AKT) migrate to Redux ToolKit
import { combineReducers, Reducer } from 'redux';
import { each, orderBy, unionBy } from 'lodash';

import {
    SET_CASE_HELPER,
    SET_CASE_HELPER_PHOTO_SAVING,
    SET_INVITATION_STATUS,
    SET_POSSIBLE_USER_MATCHES,
} from '../actions/CaseHelper.action';

import { AppSnackbarAction, SET_APP_SNACKBAR, SET_APP_SNACKBAR_OPEN, } from '../actions/AppSnackbar.action';

import { GatherAction, REFRESH_APP_THEME, SetDeepLinkValue, TopLevelRoute, TopLevelSubRoute } from '../actions';
import {
    ATTEMPTING_LOGIN,
    CHECKING_USER_SESSION,
    LOGIN_FAILED,
    NO_USER_SESSION_FOUND,
    SET_FEATURES_OVERRIDE,
} from '../actions/UserSession.action';

import {
    AccessRestrictedState,
    AlbumState,
    AppSnackbarState,
    AppState,
    DeepLinkState,
    DeleteGlobalState,
    FinanceState,
    FuneralHomeState,
    GatherEventState,
    InvitationState,
    LocationState,
    ServiceDetailState,
    StoreState,
    TaskLocationState,
    TeamState,
    UserSession,
    WhiteLabel,
} from '../types';

import * as constants from '../constants';
import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../constants';
import { APP_PRIMARY_COLOR } from '../constants/colorVariables';

import {
    CLEAR_ACTIVE_FUNERALHOME,
    FAILED_LOAD_CASE_SWITCHER_DATA,
    FUNERAL_HOME_BELONGINGS_LOADED,
    FUNERAL_HOME_BELONGINGS_LOADING,
    FUNERAL_HOME_BELONGINGS_FAILED_TO_LOAD,
    FUNERAL_HOME_LOAD_FAILED,
    FUNERAL_HOME_LOADED,
    FUNERAL_HOME_LOADING,
    FUNERAL_HOME_PREVIEWS_LOAD_FAILED,
    FUNERAL_HOME_PREVIEWS_LOADED,
    FUNERAL_HOME_PREVIEWS_LOADING,
    FUNERAL_HOME_UPDATED,
    FUNERAL_HOMES_LOAD_FAILED,
    FUNERAL_HOMES_LOADED,
    FUNERAL_HOMES_LOADING,
    LOADED_CASE_SWITCHER_DATA,
    LOADED_PUBLIC_GPL,
    LOADING_CASE_SWITCHER_DATA,
    NEW_FUNERAL_HOME_SAVED,
    PATCH_EXTRACT_CONFIG,
    SET_FUNERAL_HOME_DEMO_SETTINGS,
    SET_FUNERAL_HOME_FEATURE,
    SET_FUNERAL_HOME_ICON_SAVING,
    SET_FUNERAL_HOME_PHOTO_SAVING,
    SET_FUNERAL_HOME_SAVING,
    SET_STRIPE_DATA,
    UPDATING_FUNERAL_HOME,
} from '../actions/FuneralHome.action';

import {
    LOADED_PRIVATE_CASE,
    LOADED_PUBLIC_CASE,
    ORGANIZE_PAGE_LOADED,
    REMEMBER_THEME_CHANGED,
} from '../actions/GatherCase.action';

import {
    SET_TEAM,
    SET_TEAM_INVITATION_PENDING,
    SET_TEAM_INVITATION_SUCCESS,
    SET_TEAM_LOADING,
    SET_TEAM_MEMBER,
    SET_TEAM_MEMBER_PHOTO_SAVING,
    UPDATE_TEAM_MEMBER,
} from '../actions/Team.action';

import {
    ADD_EVENT,
    DELETE_EVENT,
    GatherEventAction,
    LOADED_CASE_EVENTS,
    LOADED_EVENTS,
    LOADING_EVENTS,
    LOADING_EVENTS_FAILED,
    SET_ACTIVE_EVENT,
    SET_EVENT_SAVING,
    UPDATE_EVENT,
} from '../actions/GatherEvent.action';

import {
    ADD_LOCATION,
    DELETE_LOCATION,
    LocationAction,
    SET_LOCATION_SAVING,
    SET_LOCATIONS,
    SET_LOCATIONS_LOADING,
    UPDATE_LOCATION,
} from '../actions/Location.action';

import { CLOSE_GLOBAL_DELETE_DIALOG, DELETE_CASE_PHOTO, OPEN_GLOBAL_DELETE_DIALOG, } from '../actions/Photo.action';

import {
    EntitySummary,
    entitySummaryToPartialUserProfile,
    FuneralHomeForGatherView,
    FuneralHomePublic,
    FuneralHomeUX,
    funeralHomeUXToGatherView,
    funeralHomeUXToGatherViewPartial,
    GatherEvent,
    getUserFuneralHomeSettings,
    isAlbumEntry,
    LocationUX,
    UserFuneralHomeUX,
} from '../shared/types';
import {
    CLEAR_TEMPLATES,
    CREATE_DEFAULT_DETAIL_ERROR,
    CREATE_TEMPLATE_DETAIL_ERROR,
    CREATE_TEMPLATE_ERROR,
    CREATED_DEFAULT_DETAIL,
    CREATED_TEMPLATE,
    CREATED_TEMPLATE_DETAIL,
    DELETE_DEFAULT_DETAIL_ERROR,
    DELETE_TEMPLATE_ERROR,
    DELETED_DEFAULT_DETAIL,
    DELETED_TEMPLATE,
    GOT_CASKET_BEARER_TEMPLATE_DETAILS,
    GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR,
    GOT_DEFAULT_TEMPLATE_DETAILS,
    GOT_DEFAULT_TEMPLATE_DETAILS_ERROR,
    GOT_TEMPLATE,
    GOT_TEMPLATE_ERROR,
    GOT_TEMPLATE_FUNERAL_HOMES,
    GOT_TEMPLATE_FUNERAL_HOMES_ERROR,
    GOT_TEMPLATES,
    GOT_TEMPLATES_ERROR,
    LINK_TEMPLATE_DETAIL_ERROR,
    LINKED_TEMPLATE_DETAIL,
    SERVICE_TEMPLATES_LOADING,
    ServiceDetailAction,
    SET_ACTIVE_TAB_INDEX,
    SET_ACTIVE_TEMPLATE,
    UNLINK_TEMPLATE_DETAIL,
    UNLINK_TEMPLATE_DETAIL_ERROR,
    UPDATE_DEFAULT_DETAIL_ERROR,
    UPDATE_TEMPLATE_ERROR,
    UPDATED_DEFAULT_DETAIL,
    UPDATED_TEMPLATE
} from '../actions/ServiceDetail.action';

import { AppAction, HAS_INTERNET, HAS_NO_INTERNET, } from '../actions/App';

import { AppTheme } from '../styles';
import {
    getEmptyCaseTransactions,
    PAYMENT_PROCESS_STATE,
    PLAID_CHARGE_STATE,
    RECONCILE_PAYMENT,
    SET_EXTRACT_REPORT,
    SET_FEE_CONFIGURATION,
    SET_PAYMENT_REPORT,
    SET_PLAID_CHARGE_STATE,
    SET_PLAID_ERROR,
    SET_PLAID_LINK_TOKEN,
    SET_REVENUE_REPORT,
    SET_STRIPE_CHARGE_DETAILS,
    SET_STRIPE_CHARGE_STATE,
    SET_STRIPE_DISCOVER_RESULTS,
    SET_STRIPE_ERROR,
    SET_STRIPE_PAYMENT_STATE,
    SET_STRIPE_SELECTED_READER,
    SET_STRIPE_TERMINAL_STATE,
    SET_TRANSACTIONS,
    STRIPE_CHARGE_STATE,
    STRIPE_PAYMENT_STATE,
    STRIPE_TERMINAL_STATE,
} from '../actions/Finance.action';

import {
    AccessRestrictedAction,
    CLOSE_ACCESS_RESTRICTED_DIALOG_STATE,
    OPEN_ACCESS_RESTRICTED_DIALOG_STATE,
} from '../actions/AccessRestricted.action';

import {
    initData as productInitData,
    initSupplierData as productSupplierInitData,
    productState,
    productSupplierState,
} from './product.reducer';

import {
    caseDocInitData,
    caseDocState,
    docPacketInitData,
    docPacketState,
    funeralHomeDocInitData,
    funeralHomeDocState,
} from './doc.reducer';
import { dialogInitData, dialogState, } from './dialog.reducer';
import { entityTableState, personTableReduxInitData, } from './person.reducer';

import { taskInitData, tasksState, } from './task.reducer';
import { taskTemplateInitData, taskTemplateState, } from './taskTemplate.reducer';
import { workflowInitData, workflowState } from './workflow.reducer';

import { caseTableReduxInitData, caseTableState, } from './caseTable.reducer';

import { casePreviewReduxInitData, casePreviewState, } from './casePreview.reducer';

import { flowerSalesInitData, flowerSalesState, } from './flowerSales.reducer';
import { noteState, noteStateInitData } from './note.reducer';
import { SET_FUNERALHOME_STARTUP_DOCUMENTS } from '../actions/Doc.action';
import { liveStreamInitData, liveStreamState, } from './livestream.reducer';
import { docPacketTableReduxInitData, docPacketTableState, } from './docPacketTable.reducer';
import { photoSwipeState, photoSwipeStateInitData } from './photoSwipe.reducer';
import { confettiInitState, confettiState } from './confetti.reducer';
import {
    REMOVE_ALBUM_ENTRY_LOCALLY,
    SET_ALBUM_DOWNLOAD_STATE,
    SET_ALBUM_NETWORK_BUSY,
    SET_ALBUM_PHOTOS,
    SET_ALBUM_TASK_INFO,
    SET_ALBUM_TASK_INFO_MAP,
    UPDATE_ALBUM_ENTRY,
} from '../actions/Album.action';
import { deathCertificateConfigInitData, deathCertificateConfigState } from './deathCertificateConfig.reducer';
import { CREATED_DEATH_CERTIFICATE_CONFIG, } from '../actions/DeathCertificateConfig.action';
import { initRolodexState, rolodexState } from './rolodex.reducer';
import { initMemoryState, memoryState } from './memory.reducer';
import { SET_VISITOR, SET_VISITOR_PHOTO_SAVING } from '../actions/visitor.action';
import { moderationInitData, moderationState } from './moderation.reducer';
import {
    CASE_LABEL_CREATED,
    CASE_LABEL_DELETED,
    CASE_LABEL_UPDATED,
    CASE_LABELS_LOADED
} from '../actions/CaseLabel.action';
import {
    TASK_LOCATION_CREATED,
    TASK_LOCATION_DELETED,
    TASK_LOCATION_UPDATED,
    TASK_LOCATIONS_LOADED,
    TASK_LOCATIONS_LOADING,
    TASK_LOCATIONS_REORDERED
} from '../actions/TaskLocation.action';
import { log } from '../logger';
import { WHITEBOARD_LOADED, WHITEBOARD_LOADING } from '../actions/Whiteboard.action';
import { initWhiteboardState, whiteboardState } from './whiteboard.reducer';
import { activeCaseInitData, activeCaseState } from './activeCase.reducer';
import { casesState, casesStateInitData } from './cases.reducer';
import { keeptrackInitData, keeptrackState } from './keeptrack.reducer';
import { INITIALIZE_STEP_LOADED } from '../actions/task/Task.action';
import { initWebsiteState, websiteState } from './website.reducer';
import { keeptrackReportInitData, keeptrackReportState } from "./keeptrackReport.reducer";
import { setGTMFuneralHomeContext } from '../services';
import { caseInsuranceInitData, caseInsuranceState } from './insurance.reducer';

const initData: StoreState = {
    whiteLabel: {
        logo: constants.GATHER_LOGO,
        logo_transformations: {},
        theme_logo: constants.GATHER_LOGO,
        theme_logo_transformations: {},
        icon: constants.GATHER_ICON,
        icon_transformations: {},
        muiTheme: AppTheme.getMuiTheme(APP_PRIMARY_COLOR, null),
        funeralHomeTheme: null,
        rememberTheme: null,
    },
    userSession: {
        isUserPhotoSaving: false,
        isAuthCheckPending: true,
        userData: null,
        isUpdatingUser: false,
        isLoginPending: false,
        isLoginSuccess: false,
        passwordResetPending: false,
        passwordResetMessage: 'NOTSENT',
        loginError: undefined,
        hasFeaturesOverride: localStorage.getItem('gatherOverride') === 'True' || false,
        defaultCase: null,
        defaultFuneralHomeKey: null,
    },
    invitationState: {
        status: null,
        similarUsers: [],
    },
    teamState: {
        isInvitationPending: false,
        isInvitationSuccess: false,
        isTeamLoading: false,
        team: [],
    },
    funeralHomeState: {
        activeFuneralHome: null,
        publicFuneralHome: null,
        failedRouteKey: null,
        funeralHomeGatherView: {
            data: [],
            hasMoreData: false,
            isLoading: false,
            searchText: '',
            sortBy: 'name',
            sortDirection: 'desc',
        },
        funeralHomePreviews: {
            data: [],
            hasMoreData: false,
            isLoading: false,
            searchText: '',
            sortBy: 'name',
            sortDirection: 'desc',
        },
        isLoading: false,
        isSaving: false,
        isPhotoSaving: false,
        isIconSaving: false,
        demoSettings: null,
        activeCaseLabels: [],
        caseBelongings: {
            data: [],
            hasMoreData: false,
            isLoading: false,
            searchText: '',
            sortBy: 'name',
            sortDirection: 'desc',
        }
    },
    casesState: casesStateInitData,
    activeCaseState: activeCaseInitData,
    deepLinkState: {
        deepLink: null,
        isLoading: null,
        isInvitationAccepted: false,
    },
    appSnackbarState: {
        isOpen: false,
        message: '',
        variant: 'info',
    },
    tasksState: taskInitData,
    taskTemplateState: taskTemplateInitData,
    workflowState: workflowInitData,
    eventState: {
        gatherEvents: [],
        googleEvents: [],
        isSaving: false,
        isLoading: false,
        activeEventId: null,
    },
    locationState: {
        locations: [],
        isSaving: false,
        isLoading: false,
    },
    appState: {
        hasInternet: true,
    },
    serviceDetailState: {
        casketBearerTemplateDetails: [],
        defaultTemplateDetails: [],
        templates: [],
        isLoading: true,
        activeTemplateKey: null,
        activeTemplateFuneralHomes: [],
        activeTabIndex: null
    },
    financeState: {
        paymentProcessState: PAYMENT_PROCESS_STATE.NOT_STARTED,
        terminalState: STRIPE_TERMINAL_STATE.NOT_INITIALIZED,
        terminalPaymentState: STRIPE_PAYMENT_STATE.NOT_STARTED,
        terminalDiscoverResults: {
            error: null,
            discoveredReaders: []
        },
        chargeDetails: { loading: false },
        selectedReader: null,
        onlineChargeState: STRIPE_CHARGE_STATE.NOT_STARTED,
        plaidChargeState: PLAID_CHARGE_STATE.NOT_STARTED,
        plaidLinkToken: null,
        extractReport: {
            closedBatches: [],
            openBatches: [],
            nonBatches: [],
        },
        transactions: getEmptyCaseTransactions(),
        paymentReport: null,
        revenueReport: null,
        feeConfiguration: null
    },
    productState: productInitData,
    productSupplierState: productSupplierInitData,
    funeralHomeDocState: funeralHomeDocInitData,
    caseDocState: caseDocInitData,
    docPacketState: docPacketInitData,
    accessRestricted: {
        isDialogOpen: false,
        zIndex: 1350
    },
    dialogState: dialogInitData,
    entityTableState: personTableReduxInitData,
    caseTableState: caseTableReduxInitData,
    casePreviewState: casePreviewReduxInitData,
    liveStreamState: liveStreamInitData,
    flowerSalesState: flowerSalesInitData,
    noteState: noteStateInitData,
    docPacketTableState: docPacketTableReduxInitData,
    photoSwipeState: photoSwipeStateInitData,
    confettiState: confettiInitState,
    rolodexState: initRolodexState,
    albumState: {
        isNetworkBusy: false,
        albums: {},
        albumTaskInfoMap: {},
    },
    deleteGlobalState: {
        zIndex: 1500,
        isDialogOpen: false,
    },
    memoryState: initMemoryState,
    deathCertificateConfigState: deathCertificateConfigInitData,
    moderationState: moderationInitData,
    taskLocationState: {
        isLoading: false,
        taskLocations: []
    },
    whiteboardState: initWhiteboardState,
    assignKeeptrack: keeptrackInitData,
    keeptrackReport: keeptrackReportInitData,
    websites: initWebsiteState,
    caseInsuranceState: caseInsuranceInitData,
};

const invitationState = (
    state: InvitationState = initData.invitationState, action: GatherAction): InvitationState => {
    switch (action.type) {
        case SET_INVITATION_STATUS:
            return { ...state, status: action.status };
        case SET_POSSIBLE_USER_MATCHES:
            return { ...state, similarUsers: action.similarUsers };
        case USER_LOGGED_OUT:
            return initData.invitationState;
        default:
            return state;
    }
};

const teamState = (
    state: TeamState = initData.teamState,
    action: GatherAction,
): TeamState => {
    switch (action.type) {
        case SET_TEAM_INVITATION_PENDING:
            return { ...state, isInvitationPending: action.isInvitationPending };
        case SET_TEAM_INVITATION_SUCCESS:
            return { ...state, isInvitationSuccess: action.isInvitationSuccess };
        case LOADED_CASE_SWITCHER_DATA:
        case SET_TEAM:
            return {
                ...state,
                team: action.team ?? [],
            };
        case WHITEBOARD_LOADED:
            return { ...state, team: action.whiteboard.teamMembers, isTeamLoading: false };
        case UPDATE_TEAM_MEMBER:
            return {
                ...state,
                team: state.team.map((tm: EntitySummary) =>
                    tm.entity_id === action.entityId ? { ...tm, ...action.changes } : tm
                ),
            };
        case SET_TEAM_MEMBER:
            return {
                ...state,
                team: state.team.map((tm: EntitySummary) =>
                    tm.entity_id === action.teamMember.entity_id ? action.teamMember : tm
                ),
            };
        case SET_TEAM_LOADING:
            return { ...state, isTeamLoading: action.isLoading };
        case WHITEBOARD_LOADING:
            return { ...state, isTeamLoading: true };

        case FUNERAL_HOME_LOADING:
        case CLEAR_ACTIVE_FUNERALHOME:
        case USER_LOGGED_OUT:
            return initData.teamState;
        default:
            return state;
    }
};

interface PayerUpdate {
    userId: number;
    name?: string;
    email?: string | null;
    phone?: string | null;
}
const updateUserInPaymentTransactions = (state: FinanceState, user: PayerUpdate): FinanceState => {
    if (state.transactions && state.transactions.transactions) {
        return {
            ...state,
            transactions: {
                ...state.transactions,
                payments: state.transactions.payments.map(payment => {
                    if (payment.payer_user_id === user.userId) {
                        return {
                            ...payment,
                            payer_user_id: user.userId,
                            transactions: payment.transactions.map(txn => {
                                return {
                                    ...txn,
                                    payer_email: user.email !== undefined ? user.email : txn.payer_email,
                                    payer_name: user.name !== undefined ? user.name : txn.payer_name,
                                    payer_phone: user.phone !== undefined ? user.phone : txn.payer_phone,
                                    payer_user_id: user.userId,
                                };
                            })
                        };
                    } else {
                        return payment;
                    }
                })
            }
        };
    } else {
        return state;
    }
};

const financeState = (
    state: FinanceState = initData.financeState, action: GatherAction): FinanceState => {
    let paymentProcessState = state.paymentProcessState;
    switch (action.type) {
        case SET_STRIPE_TERMINAL_STATE:
            // Always set the overal payment process state when updating state of specific payment method
            if ([STRIPE_TERMINAL_STATE.CONNECTION_ERROR,
            STRIPE_TERMINAL_STATE.INITIALIZATION_ERROR].indexOf(action.terminalState) >= 0) {
                paymentProcessState = PAYMENT_PROCESS_STATE.FAILED;
            }
            // When the terminal's initiation/connection state changes, the terminal payment state must be reset
            return {
                ...state,
                terminalState: action.terminalState,
                terminalPaymentState: STRIPE_PAYMENT_STATE.NOT_STARTED,
                paymentProcessState
            };
        case SET_STRIPE_PAYMENT_STATE:
            // Overall payment process fails if stripe terminal payment fails
            if ([
                STRIPE_PAYMENT_STATE.CAPTURE_FAILED,
                STRIPE_PAYMENT_STATE.COLLECTION_FAILED,
                STRIPE_PAYMENT_STATE.INTENT_FAILED,
                STRIPE_PAYMENT_STATE.PROCESSING_FAILED].indexOf(action.paymentState) >= 0) {
                paymentProcessState = PAYMENT_PROCESS_STATE.FAILED;
            }
            return { ...state, terminalPaymentState: action.paymentState, paymentProcessState };
        case SET_STRIPE_DISCOVER_RESULTS:
            return { ...state, terminalDiscoverResults: action.discoverResult };
        case SET_STRIPE_SELECTED_READER:
            return { ...state, selectedReader: action.reader };
        case SET_STRIPE_ERROR:
            return { ...state, errorMessage: action.error };
        case SET_STRIPE_CHARGE_STATE:
            if ([
                STRIPE_CHARGE_STATE.TOKEN_FAILED,
                STRIPE_CHARGE_STATE.CHARGE_FAILED].indexOf(action.chargeState) >= 0) {
                paymentProcessState = PAYMENT_PROCESS_STATE.FAILED;
            }
            return { ...state, onlineChargeState: action.chargeState, paymentProcessState };
        case SET_STRIPE_CHARGE_DETAILS:
            return { ...state, chargeDetails: action.details };
        case SET_PLAID_ERROR:
            return { ...state, errorMessage: action.error };
        case SET_PLAID_CHARGE_STATE:
            if (action.chargeState === PLAID_CHARGE_STATE.AUTH_FAILED ||
                action.chargeState === PLAID_CHARGE_STATE.CHARGE_FAILED) {
                paymentProcessState = PAYMENT_PROCESS_STATE.FAILED;
            }
            return { ...state, plaidChargeState: action.chargeState, paymentProcessState };
        case SET_PLAID_LINK_TOKEN:
            return { ...state, plaidLinkToken: action.linkToken };
        case SET_TRANSACTIONS:
            return { ...state, transactions: action.caseTransactions };
        case SET_EXTRACT_REPORT:
            return { ...state, extractReport: action.extractReport };
        case SET_PAYMENT_REPORT:
            return { ...state, paymentReport: action.paymentReport };
        case SET_REVENUE_REPORT:
            return { ...state, revenueReport: action.revenueReport };
        case SET_FEE_CONFIGURATION:
            return { ...state, feeConfiguration: action.feeConfiguration };
        case UPDATE_TEAM_MEMBER:
            return updateUserInPaymentTransactions(state, {
                userId: action.userId,
                name: action.changes.fname,
                email: action.changes.email,
                phone: action.changes.phone,
            });
        case SET_TEAM_MEMBER:
            if (!action.teamMember.user_id) {
                return state;
            }
            return updateUserInPaymentTransactions(state, {
                userId: action.teamMember.user_id,
                name: action.teamMember.fname,
                email: action.teamMember.email,
                phone: action.teamMember.phone,
            });
        case SET_CASE_HELPER:
            if (!action.helper.user_id) {
                return state;
            }
            return updateUserInPaymentTransactions(state, {
                userId: action.helper.user_id,
                name: action.helper.fname,
                email: action.helper.email,
                phone: action.helper.phone,
            });
        case RECONCILE_PAYMENT:
            if (state.paymentReport && state.paymentReport.payments) {
                return {
                    ...state,
                    paymentReport: {
                        ...state.paymentReport,
                        payments: state.paymentReport.payments.map(p => {
                            if (p.id === action.paymentId) {
                                return {
                                    ...p,
                                    reconciled_time: action.isReconciled ? new Date() : null,
                                };
                            } else {
                                return p;
                            }
                        }),
                    }
                };
            } else {
                return state;
            }
        case USER_LOGGED_OUT:
        case FUNERAL_HOME_LOADING:
        case CLEAR_ACTIVE_FUNERALHOME: {
            return initData.financeState;
        }
        default:
            return state;
    }
};

const deepLinkState = (
    state: DeepLinkState = initData.deepLinkState, action: GatherAction): DeepLinkState => {
    switch (action.type) {
        case constants.SET_DEEP_LINK_LOADING:
            return { ...state, isLoading: action.isLoading };
        case constants.SET_DEEP_LINK_INVITATION_STATUS:
            return { ...state, isInvitationAccepted: action.isInvitationAccepted };
        case constants.SET_DEEP_LINK_VALUE:
            return {
                ...state,
                deepLink: action.deepLink,
                isLoading: false,
            };
        case USER_LOGGED_OUT:
            return initData.deepLinkState;
        default:
            return state;
    }
};

const whiteLabel = (state: WhiteLabel = initData.whiteLabel, action: GatherAction): WhiteLabel => {

    const getCustomAssetsFromFH = (funeralHome: FuneralHomeUX | FuneralHomePublic): WhiteLabel => {
        const newCustomAssets: WhiteLabel = {
            ...initData.whiteLabel,
            muiTheme: AppTheme.getMuiTheme(funeralHome.custom_assets.themeColor, state.rememberTheme),
            funeralHomeTheme: {
                primaryColor: funeralHome.custom_assets.themeColor,
            },
        };
        if (funeralHome.photo) {
            newCustomAssets.logo = funeralHome.photo;
            newCustomAssets.logo_transformations = funeralHome.photo_transformations;
        }
        if (funeralHome.theme_photo) {
            newCustomAssets.theme_logo = funeralHome.theme_photo;
            newCustomAssets.theme_logo_transformations = funeralHome.theme_photo_transformations;
        }
        if (funeralHome.icon) {
            newCustomAssets.icon = funeralHome.icon;
            newCustomAssets.icon_transformations = funeralHome.icon_transformations;
        }
        return newCustomAssets;
    };

    switch (action.type) {
        case LOADED_PUBLIC_GPL:
        case FUNERAL_HOME_LOADED:
        case FUNERAL_HOME_UPDATED: {
            if (action.funeralHome) {
                setGTMFuneralHomeContext(action.funeralHome.key);
                return {
                    ...state,
                    ...getCustomAssetsFromFH(action.funeralHome),
                };
            }
            return state;
        }
        case LOADED_PRIVATE_CASE: {
            return {
                ...state,
                ...getCustomAssetsFromFH(action.gatherCase.funeral_home),
                rememberTheme: action.gatherCase.theme,
            };
        }
        case REMEMBER_THEME_CHANGED: {
            return {
                ...state,
                rememberTheme: action.theme,
            };
        }
        case constants.SET_DEEP_LINK_VALUE:
            const { deepLink } = action;
            const customAssets = {
                ...initData.whiteLabel,
                muiTheme: AppTheme.getMuiTheme(deepLink && deepLink.fhThemeColor
                    || AppTheme.getThemeColor(initData.whiteLabel.muiTheme), state.rememberTheme),
            };
            if (deepLink && deepLink.fhLogo) {
                customAssets.logo = deepLink.fhLogo;
                if (deepLink.fhLogoTransformations) {
                    try {
                        customAssets.logo_transformations = JSON.parse(deepLink.fhLogoTransformations);
                    } catch (e) {
                        customAssets.logo_transformations = null;
                    }
                }
            }
            if (deepLink && deepLink.fhIcon) {
                customAssets.icon = deepLink.fhIcon;
                if (deepLink.fhIconTransformations) {
                    try {
                        customAssets.icon_transformations = JSON.parse(deepLink.fhIconTransformations);
                    } catch (e) {
                        customAssets.icon_transformations = null;
                    }
                }
            }
            return { ...state, ...customAssets };
        case CLEAR_ACTIVE_FUNERALHOME:
        case USER_LOGGED_OUT:
            return initData.whiteLabel;
        case LOADED_PUBLIC_CASE: {
            const { publicCase } = action;

            const newState = {
                ...state,
                ...getCustomAssetsFromFH(publicCase.funeral_home),
                rememberTheme: publicCase.theme,
            };

            return newState;
        }
        case REFRESH_APP_THEME: {
            if (action.topLevelRoute === TopLevelRoute.remember
                || action.topLevelRoute === TopLevelSubRoute.familyObituary) {
                return {
                    ...state,
                    muiTheme: AppTheme.getMuiTheme(
                        state.rememberTheme?.primary_color
                        ?? state.funeralHomeTheme?.primaryColor
                        ?? APP_PRIMARY_COLOR,
                        state.rememberTheme
                    ),
                };
            } else if (action.topLevelRoute === TopLevelRoute.gatherAdmin) {
                return initData.whiteLabel;
            } else {
                return {
                    ...state,
                    muiTheme: AppTheme.getMuiTheme(
                        state.funeralHomeTheme?.primaryColor
                        ?? APP_PRIMARY_COLOR,
                        state.rememberTheme
                    ),
                };
            }
        }
        default:
            return state;
    }
};

const userSession = (
    state: UserSession = initData.userSession,
    action: GatherAction | SetDeepLinkValue): UserSession => {
    switch (action.type) {
        case SET_VISITOR_PHOTO_SAVING:
        case SET_CASE_HELPER_PHOTO_SAVING:
        case SET_TEAM_MEMBER_PHOTO_SAVING:
            if (!state.userData || action.entityId !== state.userData.entity_id) {
                return state;
            }
            return {
                ...state,
                isUserPhotoSaving: action.isPhotoSaving,
            };
        case UPDATE_TEAM_MEMBER:
            if (!state.userData || action.userId !== state.userData.user_id) {
                return state;
            }
            const { funeral_home_settings, ...otherChanges } = action.changes;
            const newFuneralHomeUX: UserFuneralHomeUX[] | null = funeral_home_settings
                ? (state.userData.funeral_homes || []).map((fh) => {

                    const settings = funeral_home_settings
                        ? getUserFuneralHomeSettings(funeral_home_settings, fh.funeral_home_id)
                        : null;
                    return {
                        ...fh,
                        ...settings,
                    };
                })
                : state.userData.funeral_homes;
            const updatedUserData = { ...state.userData, ...otherChanges, funeral_homes: newFuneralHomeUX };
            return { ...state, userData: updatedUserData };
        case SET_TEAM_MEMBER:
            if (!state.userData || action.teamMember.entity_id !== state.userData.entity_id) {
                return state;
            }

            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...entitySummaryToPartialUserProfile(action.teamMember),
                },
            };
        case SET_CASE_HELPER:
            if (!state.userData || action.helper.entity_id !== state.userData.entity_id) {
                return state;
            }

            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...entitySummaryToPartialUserProfile(action.helper),
                },
            };
        case SET_VISITOR:
            if (!state.userData || action.visitor.entity_id !== state.userData.entity_id) {
                return state;
            }

            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...entitySummaryToPartialUserProfile(action.visitor),
                },
            };

        case constants.UPDATE_LOGIN_USER:
            return {
                ...state,
                userData: state.userData ? { ...state.userData, ...action.changes } : null,
            };
        case CHECKING_USER_SESSION: {
            return {
                ...state,
                isAuthCheckPending: true,
                isLoginPending: false,
                isLoginSuccess: false,
                loginError: undefined,
            };
        }
        case NO_USER_SESSION_FOUND: {
            return {
                ...state,
                isAuthCheckPending: false,
                isLoginPending: false,
                isLoginSuccess: false,
                loginError: undefined,
            };
        }
        case constants.SET_LOGIN_SUCCESS:
            return { ...state, isLoginSuccess: action.isLoginSuccess };
        case constants.SET_LOGIN_ERROR:
            return { ...state, loginError: action.loginError };
        case ATTEMPTING_LOGIN: {
            return {
                ...state,
                isAuthCheckPending: false,
                isLoginPending: true,
                isLoginSuccess: false,
                loginError: undefined,
            };
        }
        case LOGIN_FAILED: {
            return {
                ...state,
                isAuthCheckPending: false,
                isLoginPending: false,
                isLoginSuccess: false,
                loginError: action.message,
            };
        }
        case USER_LOGGED_IN:
            return {
                ...state,
                isAuthCheckPending: false,
                isLoginPending: false,
                isLoginSuccess: true,
                loginError: undefined,
                userData: action.data.userData,
                defaultCase: action.data.mostRecentCase,
                defaultFuneralHomeKey: action.data.defaultFuneralHome?.key ?? null,
            };
        case constants.SET_PASSWORD_RESET_MESSAGE:
            return { ...state, passwordResetMessage: action.message };
        case constants.SET_PASSWORD_RESET_PENDING:
            return { ...state, passwordResetPending: action.isPending };
        case SET_FEATURES_OVERRIDE:
            return {
                ...state,
                hasFeaturesOverride: action.hasFeaturesOverride,
            };
        case USER_LOGGED_OUT:
            return {
                ...initData.userSession,
                isAuthCheckPending: false,
            };
        default:
            return state;
    }
};

const funeralHomeState = (
    state: FuneralHomeState = initData.funeralHomeState,
    action: GatherAction,
): FuneralHomeState => {
    switch (action.type) {
        case USER_LOGGED_OUT:
        case CLEAR_ACTIVE_FUNERALHOME:
            return initData.funeralHomeState;
        case LOADED_PRIVATE_CASE: {
            return {
                ...state,
                activeFuneralHome: action.gatherCase.funeral_home,
            };
        }
        case FUNERAL_HOME_LOADING: {
            return {
                ...initData.funeralHomeState,
                isLoading: true,
            };
        }
        case FUNERAL_HOME_LOADED: {
            return {
                ...state,
                activeFuneralHome: action.funeralHome,
                isLoading: false,
                failedRouteKey: null,
            };
        }
        case FUNERAL_HOME_LOAD_FAILED: {
            return {
                ...state,
                isLoading: false,
                failedRouteKey: action.failedRouteKey,
            };
        }
        case LOADED_PUBLIC_GPL: {
            return {
                ...state,
                publicFuneralHome: action.funeralHome
            };
        }
        case SET_FUNERAL_HOME_SAVING:
            return { ...state, isSaving: action.isSaving };
        case NEW_FUNERAL_HOME_SAVED:
            return {
                ...state,
                funeralHomeGatherView: {
                    ...state.funeralHomeGatherView,
                    data: [funeralHomeUXToGatherView(action.funeralHome), ...state.funeralHomeGatherView.data],
                },
            };
        case CREATED_DEATH_CERTIFICATE_CONFIG:

            return {
                ...state,
                activeFuneralHome: !state.activeFuneralHome || !action.isDefault
                    ? state.activeFuneralHome
                    : {
                        ...state.activeFuneralHome,
                        default_dc_config_id: action.config.id,
                    },
            };
        case UPDATING_FUNERAL_HOME:
            return {
                ...state,
                isSaving: true,
                activeFuneralHome: !state.activeFuneralHome || state.activeFuneralHome.id !== action.id
                    ? null
                    : { ...state.activeFuneralHome, ...action.changes },
                funeralHomeGatherView: {
                    ...state.funeralHomeGatherView,
                    data: state.funeralHomeGatherView.data.map((fh) =>
                        fh.id === action.id
                            ? {
                                ...fh,
                                ...action.changes,
                            }
                            : fh
                    ),
                },
            };
        case FUNERAL_HOME_UPDATED:
            if (action.funeralHome.deleted_time) {
                return {
                    ...state,
                    isSaving: false,
                    activeFuneralHome: state.activeFuneralHome && state.activeFuneralHome.id === action.funeralHome.id
                        ? null
                        : state.activeFuneralHome,
                    funeralHomeGatherView: {
                        ...state.funeralHomeGatherView,
                        data: state.funeralHomeGatherView.data.filter((fh) => fh.id !== action.funeralHome.id),
                    },
                };
            } else {
                return {
                    ...state,
                    isSaving: false,
                    activeFuneralHome: state.activeFuneralHome && state.activeFuneralHome.id === action.funeralHome.id
                        ? action.funeralHome
                        : state.activeFuneralHome,
                    funeralHomeGatherView: {
                        ...state.funeralHomeGatherView,
                        data: state.funeralHomeGatherView.data.map((fh) =>
                            fh.id !== action.funeralHome.id ? fh : {
                                ...fh,
                                ...funeralHomeUXToGatherViewPartial(action.funeralHome)
                            }
                        ),
                    },
                };
            }
        case SET_FUNERAL_HOME_PHOTO_SAVING:
            return { ...state, isPhotoSaving: action.isPhotoSaving };
        case SET_FUNERAL_HOME_FEATURE: {
            const { activeFuneralHome } = state;
            return {
                ...state,
                activeFuneralHome: !activeFuneralHome ? null : {
                    ...activeFuneralHome,
                    features: {
                        ...activeFuneralHome.features,
                        [action.feature]: {
                            ...activeFuneralHome.features[action.feature],
                            enabled: action.enabled,
                        },
                    },
                },
            };
        }
        case SET_FUNERAL_HOME_ICON_SAVING:
            return { ...state, isIconSaving: action.isSaving };
        case SET_STRIPE_DATA:
            return {
                ...state,
                activeFuneralHome: !state.activeFuneralHome ? null : {
                    ...state.activeFuneralHome,
                    stripe_location: action.locationId,
                    stripe_account: action.accountId,
                    stripe_customer: action.customerId,
                }
            };
        case PATCH_EXTRACT_CONFIG:
            return {
                ...state,
                activeFuneralHome: !state.activeFuneralHome ? null : {
                    ...state.activeFuneralHome,
                    qb_tax_rate: action.qbTaxRate,
                    ledger_extract_type: action.ledgerExtractType,
                },
            };
        case SET_FUNERAL_HOME_DEMO_SETTINGS: {
            // Only set the settings if they belong to the active funeral home
            const { activeFuneralHome } = state;
            if (activeFuneralHome && action.demoSettings && activeFuneralHome.id === action.demoSettings.id) {
                return {
                    ...state,
                    demoSettings: action.demoSettings,
                };
            } else {
                return {
                    ...state,
                    demoSettings: null,
                };
            }
        }
        case FUNERAL_HOMES_LOADING: {
            return {
                ...state,
                funeralHomeGatherView: {
                    ...state.funeralHomeGatherView,
                    data: action.offset === 0 ? [] : state.funeralHomeGatherView.data,
                    isLoading: true,
                    searchText: action.searchText,
                    sortBy: action.sortBy,
                    sortDirection: action.sortDirection,
                },
            };
        }
        case FUNERAL_HOMES_LOADED: {
            // overwrite data if offset === 0
            const updatedData = action.offset === 0
                ? action.funeralHomes
                : [...state.funeralHomeGatherView.data, ...action.funeralHomes];
            return {
                ...state,
                funeralHomeGatherView: {
                    ...state.funeralHomeGatherView,
                    data: updatedData,
                    hasMoreData: action.hasMoreData,
                    isLoading: false,
                },
            };
        }
        case FUNERAL_HOMES_LOAD_FAILED: {
            return {
                ...state,
                funeralHomeGatherView: {
                    ...state.funeralHomeGatherView,
                    isLoading: false,
                },
            };
        }
        case FUNERAL_HOME_PREVIEWS_LOADING: {
            return {
                ...state,
                funeralHomePreviews: {
                    ...state.funeralHomePreviews,
                    data: action.offset === 0 ? [] : state.funeralHomePreviews.data,
                    isLoading: true,
                    searchText: action.searchText,
                    sortBy: action.sortBy,
                    sortDirection: action.sortDirection,
                },
            };
        }
        case FUNERAL_HOME_PREVIEWS_LOADED: {
            // overwrite data if offset === 0
            const updatedData = action.offset === 0
                ? action.funeralHomes
                : [...state.funeralHomePreviews.data, ...action.funeralHomes];
            return {
                ...state,
                funeralHomePreviews: {
                    ...state.funeralHomePreviews,
                    data: updatedData,
                    hasMoreData: action.hasMoreData,
                    isLoading: false,
                },
            };
        }
        case FUNERAL_HOME_PREVIEWS_LOAD_FAILED: {
            return {
                ...state,
                funeralHomePreviews: {
                    ...state.funeralHomePreviews,
                    isLoading: false,
                },
            };
        }
        case SET_FUNERALHOME_STARTUP_DOCUMENTS: {
            return {
                ...state,
                activeFuneralHome: state.activeFuneralHome ? {
                    ...state.activeFuneralHome,
                    startup_documents: action.startupDocuments
                } : null
            };
        }

        case CASE_LABELS_LOADED:
            return {
                ...state,
                activeCaseLabels: action.caseLabels
            };
        case WHITEBOARD_LOADED:
            return {
                ...state,
                activeCaseLabels: action.whiteboard.caseLabels
            };
        case CASE_LABEL_CREATED:
            return {
                ...state,
                activeCaseLabels: [
                    ...state.activeCaseLabels,
                    action.caseLabel
                ]
            };
        case CASE_LABEL_UPDATED:
            const updatedCaseLabels = state.activeCaseLabels.map(label => {
                if (label.id === action.caseLabel.id) {
                    return {
                        ...label,
                        ...action.caseLabel
                    };
                }
                return label;
            });
            return {
                ...state,
                activeCaseLabels: updatedCaseLabels
            };
        case CASE_LABEL_DELETED:
            const updatedLabels = state.activeCaseLabels.filter(({ id }) => id !== action.caseLabel.id);
            return {
                ...state,
                activeCaseLabels: updatedLabels
            };
        case FUNERAL_HOME_BELONGINGS_LOADING: {
            return {
                ...state,
                caseBelongings: {
                    ...state.caseBelongings,
                    data: action.offset === 0 ? [] : state.caseBelongings.data,
                    isLoading: true,
                    searchText: action.searchText,
                    sortBy: action.sortBy,
                    sortDirection: action.sortDirection
                }
            };
        }
        case FUNERAL_HOME_BELONGINGS_LOADED: {
            const updatedData = action.offset === 0
                ? action.belongings
                : [...state.caseBelongings.data, ...action.belongings];
            return {
                ...state,
                caseBelongings: {
                    ...state.caseBelongings,
                    data: updatedData,
                    isLoading: false,
                    hasMoreData: action.hasMoreData
                }
            };
        }
        case FUNERAL_HOME_BELONGINGS_FAILED_TO_LOAD: {
            return {
                ...state,
                caseBelongings: {
                    ...state.caseBelongings,
                    isLoading: false
                }
            };
        }
        default:
            return state;
    }
};

const appSnackbarState = (
    state: AppSnackbarState = initData.appSnackbarState, action: AppSnackbarAction): AppSnackbarState => {
    switch (action.type) {
        case SET_APP_SNACKBAR_OPEN:
            return { ...state, isOpen: action.isOpen };
        case SET_APP_SNACKBAR:
            return {
                ...state,
                message: action.message,
                variant: action.variant,
                isOpen: action.isOpen,
                secondaryMessage: action.secondaryMessage,
                snackbarOrigin: action.snackbarOrigin,
                autoHideDuration: action.autoHideDuration
            };
        default:
            return state;
    }
};

const appState = (
    state: AppState = initData.appState, action: AppAction): AppState => {
    switch (action.type) {
        case HAS_INTERNET:
            return { ...state, hasInternet: true };
        case HAS_NO_INTERNET:
            return { ...state, hasInternet: false };
        default:
            return state;
    }
};

const eventState = (
    state: GatherEventState = initData.eventState,
    action: GatherEventAction | GatherAction,
): GatherEventState => {
    switch (action.type) {
        case FUNERAL_HOME_LOADING:
        case CLEAR_ACTIVE_FUNERALHOME:
        case USER_LOGGED_OUT:
            return initData.eventState;
        case SET_EVENT_SAVING: {
            return {
                ...state,
                isSaving: action.isSaving,
            };
        }
        case LOADING_CASE_SWITCHER_DATA:
        case LOADING_EVENTS:
            return {
                ...state,
                isLoading: true,
            };
        case FAILED_LOAD_CASE_SWITCHER_DATA:
        case LOADING_EVENTS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case LOADED_CASE_SWITCHER_DATA: {

            const updatedGoogleEvents = !action.events
                ? state.googleEvents
                : action.events.googleEvents;

            const updatedGatherEvents = !action.events
                ? state.gatherEvents
                : orderBy(
                    unionBy(action.events.gatherEvents, state.gatherEvents, (event) => event.id),
                    (event: GatherEvent) => event.start_time,
                );
            return {
                ...state,
                gatherEvents: updatedGatherEvents,
                googleEvents: updatedGoogleEvents,
                isLoading: false,
            };
        }
        case ORGANIZE_PAGE_LOADED:
        case LOADED_CASE_EVENTS: {
            const updatedGatherEvents = orderBy(
                unionBy(action.caseEvents, state.gatherEvents, (event) => event.id),
                (event: GatherEvent) => event.start_time,
            );
            return {
                ...state,
                gatherEvents: updatedGatherEvents,
                googleEvents: [],
                isLoading: false,
            };
        }
        case LOADED_EVENTS: {

            const updatedGatherEvents = orderBy(
                unionBy(action.caseEvents, state.gatherEvents, (event) => event.id),
                (event: GatherEvent) => event.start_time,
            );
            return {
                ...state,
                gatherEvents: updatedGatherEvents,
                googleEvents: action.googleEvents,
                isLoading: false,
            };
        }
        case ADD_EVENT: {
            return {
                ...state,
                gatherEvents: [
                    ...state.gatherEvents,
                    action.event,
                ],
            };
        }
        case UPDATE_EVENT: {
            return {
                ...state,
                gatherEvents: state.gatherEvents.map((event) => {
                    if (event.id === action.eventId) {
                        return {
                            ...event,
                            ...action.event,
                        };
                    }
                    return event;
                }),
            };
        }
        case DELETE_EVENT: {
            return {
                ...state,
                gatherEvents: state.gatherEvents.filter((event) => {
                    return event.id !== action.event.id;
                }),
            };
        }
        case SET_ACTIVE_EVENT: {
            return {
                ...state,
                activeEventId: action.eventId,
            };
        }
        case LOADED_PUBLIC_CASE: {
            return {
                ...state,
                gatherEvents: action.events,
            };
        }
        default:
            return state;
    }
};

const locationState = (
    state: LocationState = initData.locationState,
    action: LocationAction | GatherAction,
): LocationState => {
    switch (action.type) {
        case USER_LOGGED_OUT:
            return initData.locationState;
        case SET_LOCATION_SAVING: {
            return {
                ...state,
                isSaving: action.isSaving,
            };
        }
        case SET_LOCATIONS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case SET_LOCATIONS: {
            return {
                ...state,
                locations: action.locations,
            };
        }
        case ADD_LOCATION: {
            return {
                ...state,
                locations: [
                    ...state.locations,
                    action.location,
                ],
            };
        }
        case UPDATE_LOCATION: {
            return {
                ...state,
                locations: state.locations.map((location): LocationUX => {
                    if (location.id === action.locationId) {
                        return {
                            ...location,
                            ...action.changes,
                        };
                    }
                    return location;
                }),
            };
        }
        case DELETE_LOCATION: {
            return {
                ...state,
                locations: state.locations.filter((loc) => loc.id !== action.locationId),
            };
        }
        case LOADED_PUBLIC_CASE: {
            return {
                ...state,
                locations: action.locations,
            };
        }
        default:
            return state;
    }
};

const serviceDetailState = (
    state: ServiceDetailState = initData.serviceDetailState,
    action: ServiceDetailAction | GatherAction,
): ServiceDetailState => {
    switch (action.type) {
        case USER_LOGGED_OUT:
            // every reducer needs to define USER_LOGGED_OUT
            return initData.serviceDetailState;
        case SERVICE_TEMPLATES_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case GOT_TEMPLATES: {
            return {
                ...state,
                templates: action.templates,
                isLoading: false
            };
        }
        case CLEAR_TEMPLATES: {
            return {
                ...state,
                templates: []
            };
        }
        case SET_ACTIVE_TEMPLATE: {
            return {
                ...state,
                activeTemplateKey: action.templateKey
            };
        }
        case GOT_TEMPLATE: {
            const { templates } = state;
            const { template } = action;
            const { id, key } = template;

            const filteredTemplates = templates
                .filter(x => x.id !== id)
                .concat([template]);

            return {
                ...state,
                isLoading: false,
                templates: filteredTemplates,
                activeTemplateKey: key
            };
        }
        case GOT_TEMPLATE_FUNERAL_HOMES: {
            const { response } = action;

            const activeTemplateFuneralHomes = response.selectedIds.reduce<FuneralHomeForGatherView[]>(
                (list, selectedId) => {
                    const templateFh = response.all.find((fh) => fh.id === selectedId);
                    if (templateFh) {
                        return [
                            ...list,
                            templateFh,
                        ];
                    } else {
                        return list;
                    }
                },
                [],
            );

            return {
                ...state,
                isLoading: false,
                activeTemplateFuneralHomes,
            };
        }
        case CREATED_TEMPLATE: {
            const { templates } = state;
            const { template } = action;

            return {
                ...state,
                isLoading: false,
                templates: templates.concat([template]),
                activeTemplateKey: template.key
            };
        }
        case UPDATED_TEMPLATE: {
            const { templates, activeTemplateKey } = state;
            const { template } = action;
            const { id, key } = template;
            const currentTemplate = templates.find(x => x.id === id);
            const defaultTemplateDetails = key === 'DEFAULT' ?
                template.service_details : state.defaultTemplateDetails;

            if (!currentTemplate) {
                return {
                    ...state,
                    isLoading: false
                };
            }

            return {
                ...state,
                isLoading: false,
                defaultTemplateDetails,
                templates: templates.map(t => (t.id === id) ? template : t),
                activeTemplateKey: (activeTemplateKey === currentTemplate.key) ?
                    key : activeTemplateKey
            };
        }
        case DELETED_TEMPLATE: {
            const { templates, activeTemplateKey } = state;
            const { template } = action;
            const { id, key } = template;
            return {
                ...state,
                isLoading: false,
                templates: templates.filter(t => t.id !== id),
                activeTemplateKey: (activeTemplateKey === key) ? null : activeTemplateKey
            };
        }
        case CREATED_TEMPLATE_DETAIL: {
            const { templates, activeTemplateKey, defaultTemplateDetails } = state;
            const key = activeTemplateKey || 'DEFAULT';
            const { detail } = action;

            const updatedTemplates = templates.map(t => {
                if (t.key !== key) {
                    return t;
                } else {
                    const currentServiceDetails = t.service_details;

                    return {
                        ...t,
                        service_details: currentServiceDetails.concat([detail])
                    };
                }
            });

            const updatedDefaultTemplateDetails =
                (key === 'DEFAULT') ?
                    defaultTemplateDetails.concat([detail]) :
                    defaultTemplateDetails;

            return {
                ...state,
                isLoading: false,
                templates: updatedTemplates,
                defaultTemplateDetails: updatedDefaultTemplateDetails
            };
        }
        case GOT_DEFAULT_TEMPLATE_DETAILS: {
            const { defaultDetails } = action;

            return {
                ...state,
                isLoading: false,
                defaultTemplateDetails: defaultDetails
            };
        }
        case LINKED_TEMPLATE_DETAIL: {
            const { templates } = state;
            const { templateKey, detail } = action;

            const updated = templates.map(template =>
                (template.key === templateKey) ? {
                    ...template,
                    service_detail_count: template.service_detail_count + 1,
                    service_details: template.service_details.concat([detail])
                } : template
            );

            return {
                ...state,
                isLoading: false,
                templates: updated
            };
        }
        case UNLINK_TEMPLATE_DETAIL: {
            const { templates } = state;
            const { templateKey, detail } = action;

            if (!detail.service_template_detail_id) {
                return {
                    ...state,
                    isLoading: false,
                };
            }

            const updated = templates.map(template =>
                (template.key === templateKey) ? {
                    ...template,
                    service_detail_count: template.service_detail_count - 1,
                    service_details: template.service_details.filter(
                        x => x.service_template_detail_id !== detail.service_template_detail_id
                    )
                } : template
            );

            return {
                ...state,
                isLoading: false,
                templates: updated
            };
        }
        case CREATED_DEFAULT_DETAIL: {
            const { defaultTemplateDetails } = state;
            const { detail } = action;

            return {
                ...state,
                isLoading: false,
                defaultTemplateDetails: defaultTemplateDetails.concat([detail])
            };
        }
        case UPDATED_DEFAULT_DETAIL: {
            const { templates, defaultTemplateDetails } = state;
            const { detail } = action;

            const updatedDefaultTemplateDetails =
                defaultTemplateDetails.map(d => {
                    if (d.id !== detail.id) {
                        return d;
                    }

                    return {
                        ...detail,
                        service_template_detail_id: d.service_template_detail_id
                    };
                });

            const updatedTempaltes =
                templates.map(template => {
                    return {
                        ...template,
                        service_details: template.service_details.map(d => {
                            if (d.id !== detail.id) {
                                return d;
                            }

                            return {
                                ...detail,
                                service_template_detail_id: d.service_template_detail_id
                            };
                        })
                    };
                });

            return {
                ...state,
                isLoading: false,
                templates: updatedTempaltes,
                defaultTemplateDetails: updatedDefaultTemplateDetails
            };

        }
        case DELETED_DEFAULT_DETAIL: {
            const { defaultTemplateDetails } = state;
            const { detail } = action;
            const { id } = detail;

            return {
                ...state,
                defaultTemplateDetails: defaultTemplateDetails.filter(x => x.id !== id)
            };
        }
        case GOT_CASKET_BEARER_TEMPLATE_DETAILS: {
            const { defaultDetails } = action;

            return {
                ...state,
                isLoading: false,
                casketBearerTemplateDetails: defaultDetails
            };
        }
        case GOT_CASKET_BEARER_TEMPLATE_DETAILS_ERROR:
        case DELETE_DEFAULT_DETAIL_ERROR:
        case UPDATE_DEFAULT_DETAIL_ERROR:
        case LINK_TEMPLATE_DETAIL_ERROR:
        case GOT_DEFAULT_TEMPLATE_DETAILS_ERROR:
        case CREATE_TEMPLATE_DETAIL_ERROR:
        case DELETE_TEMPLATE_ERROR:
        case UPDATE_TEMPLATE_ERROR:
        case GOT_TEMPLATES_ERROR:
        case CREATE_TEMPLATE_ERROR:
        case CREATE_DEFAULT_DETAIL_ERROR:
        case GOT_TEMPLATE_FUNERAL_HOMES_ERROR:
        case UNLINK_TEMPLATE_DETAIL_ERROR:
        case GOT_TEMPLATE_ERROR: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_ACTIVE_TAB_INDEX: {
            return {
                ...state,
                activeTabIndex: action.tabIndex
            };
        }
        default:
            return state;
    }
};

const accessRestricted = (
    state: AccessRestrictedState = initData.accessRestricted,
    action: AccessRestrictedAction
): AccessRestrictedState => {
    switch (action.type) {
        case OPEN_ACCESS_RESTRICTED_DIALOG_STATE:
            return {
                ...state,
                isDialogOpen: true,
                zIndex: action.zIndex
            };
        case CLOSE_ACCESS_RESTRICTED_DIALOG_STATE:
            return {
                ...state,
                isDialogOpen: false
            };
        default:
            return state;
    }
};

const fetchAlbum = (state: AlbumState, albumId: number) => {
    return state.albums[albumId] ? state.albums[albumId] : {
        downloadState: {
            status: null,
            count: 0,
            url: null
        }
    };
};

const albumState = (
    state: AlbumState = initData.albumState,
    action: GatherAction): AlbumState => {
    switch (action.type) {
        case DELETE_CASE_PHOTO: {
            const newMap = {};
            each(state.albums, (album, albumId) => {
                // somehow/sometimes photos is undefined here. Bandaid fix for now
                const newPhotos = album.photos?.filter(({ photo }) => {
                    if (isAlbumEntry(photo)) {
                        return action.albumEntryId !== photo.id;
                    }
                    return true;
                });
                newMap[albumId] = {
                    ...album,
                    // it is possible that the album is not loaded yet, so set photos to empty array
                    photos: newPhotos ?? [],
                };
            });
            return {
                ...state,
                albums: newMap,
            };
        }
        case SET_ALBUM_NETWORK_BUSY: {
            return {
                ...state,
                isNetworkBusy: action.isBusy,
            };
        }
        case SET_ALBUM_PHOTOS: {
            const album = fetchAlbum(state, action.albumId);
            return (
                {
                    ...state,
                    albums: {
                        ...state.albums,
                        [action.albumId]: {
                            ...album,
                            photos: action.photos,
                        }
                    }
                });
        }
        case SET_ALBUM_DOWNLOAD_STATE: {
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.albumId]: {
                        ...state.albums[action.albumId],
                        downloadState: action.state,
                    }
                }
            };
        }
        case SET_ALBUM_TASK_INFO: {
            return {
                ...state,
                albumTaskInfoMap: {
                    ...state.albumTaskInfoMap,
                    [action.taskId]: action.albumInfo,
                }
            };
        }
        case SET_ALBUM_TASK_INFO_MAP: {
            return {
                ...state,
                albumTaskInfoMap: {
                    ...state.albumTaskInfoMap,
                    ...action.albumInfoMap,
                }
            };
        }
        case REMOVE_ALBUM_ENTRY_LOCALLY: {
            if (!state.albums[action.albumId]) {
                return state;
            }
            const updatedAlbum = state.albums[action.albumId].photos.filter(entry => {
                if (isAlbumEntry(entry.photo)) {
                    const albumEntryId = entry.photo.id;
                    return action.albumEntryIds.find(id => id === albumEntryId) !== undefined;
                } else {
                    return true;
                }
            });
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.albumId]: {
                        ...state.albums[action.albumId],
                        photos: updatedAlbum
                    }
                }
            };
        }
        case UPDATE_ALBUM_ENTRY: {
            if (!state.albums[action.albumId]) {
                return state;
            }
            const updatedAlbum = state.albums[action.albumId].photos.map(entry => {
                if (isAlbumEntry(entry.photo)) {
                    if (entry.photo.id !== action.entryId) {
                        return entry;
                    }
                    return {
                        ...entry,
                        photo: {
                            ...entry.photo,
                            ...action.updatedFields,
                        }
                    };
                } else {
                    return entry;
                }
            });
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.albumId]: {
                        ...state.albums[action.albumId],
                        photos: updatedAlbum
                    }
                }
            };
        }
        default:
            return state;
    }
};

const deleteGlobalState = (
    state: DeleteGlobalState = initData.deleteGlobalState,
    action: GatherAction): DeleteGlobalState => {
    switch (action.type) {
        case OPEN_GLOBAL_DELETE_DIALOG: {
            return {
                isDialogOpen: true,
                gatherCase: action.gatherCase,
                zIndex: action.zIndex,
                albumEntryId: action.albumEntryId,
                references: action.references,
            };
        }
        case CLOSE_GLOBAL_DELETE_DIALOG: {
            return {
                ...state,
                isDialogOpen: false,
            };
        }
        default:
            return state;
    }
};

const taskLocationState = (
    state: TaskLocationState = initData.taskLocationState,
    action: GatherAction
): TaskLocationState => {
    switch (action.type) {
        case TASK_LOCATIONS_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case INITIALIZE_STEP_LOADED:
        case TASK_LOCATIONS_LOADED: {
            return {
                isLoading: false,
                taskLocations: action.taskLocations,
            };
        }
        case WHITEBOARD_LOADED: {
            return {
                isLoading: false,
                taskLocations: action.whiteboard.taskLocations
            };
        }
        case TASK_LOCATION_CREATED: {
            const addedTaskLocation = [action.taskLocation, ...state.taskLocations];
            const sortedTaskLocations = addedTaskLocation.sort((tlA, tlB) => tlA.rank - tlB.rank);
            return {
                ...state,
                taskLocations: sortedTaskLocations
            };
        }
        case TASK_LOCATION_UPDATED: {
            const taskLocations = state.taskLocations.map(taskLocation => (
                taskLocation.id === action.taskLocationId ? { ...taskLocation, ...action.taskLocation } : taskLocation
            ));
            return {
                ...state,
                taskLocations
            };
        }
        case TASK_LOCATION_DELETED: {
            return {
                ...state,
                taskLocations: state.taskLocations.filter(({ id }) => (id !== action.taskLocation.id))
            };
        }
        case TASK_LOCATIONS_REORDERED: {
            if (state.taskLocations.length !== action.taskLocationIds.length) {
                log.warn('Number Task locations does not match number of ids to reorder ', { state, action });
            }
            const rankedLocations = state.taskLocations.map(taskLocation => {
                const rank = action.taskLocationIds.indexOf(taskLocation.id);
                return rank === -1 ? taskLocation : { ...taskLocation, rank };
            });
            const sortedLocations = rankedLocations.sort((tlA, tlB) => tlA.rank - tlB.rank);
            return {
                ...state,
                taskLocations: sortedLocations
            };
        }
        default:
            return state;
    }
};


export function getRootReducer(): Reducer<StoreState> {
    return combineReducers<StoreState>({
        userSession,
        invitationState,
        teamState,
        funeralHomeState,
        casesState,
        activeCaseState,
        deepLinkState,
        whiteLabel,
        appSnackbarState,
        tasksState,
        taskTemplateState,
        workflowState,
        eventState,
        locationState,
        appState,
        serviceDetailState,
        financeState,
        productState,
        productSupplierState,
        funeralHomeDocState,
        caseDocState,
        docPacketState,
        accessRestricted,
        dialogState,
        entityTableState,
        caseTableState,
        casePreviewState,
        liveStreamState,
        flowerSalesState,
        noteState,
        docPacketTableState,
        confettiState,
        photoSwipeState,
        albumState,
        deleteGlobalState,
        rolodexState,
        memoryState,
        deathCertificateConfigState,
        moderationState,
        taskLocationState,
        whiteboardState,
        websites: websiteState,
        assignKeeptrack: keeptrackState,
        keeptrackReport: keeptrackReportState,
        caseInsuranceState: caseInsuranceState,
    });
}