import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import GPopper from '../../common/GPopper';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles({
    root: {},
    menuPopper: {
        width: 'fit-content',
        maxWidth: 300,
        position: 'fixed',
        '&[data-popper-placement*="bottom"]': {
            marginTop: '14px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '14px !important'
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-16px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '20px solid #fff',
            bottom: '-20px',
        },
    },
    menuPaper: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
        '-webkit-filter': 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
    },
    menuItem: {
        padding: '4px 8px'
    },
    arrow: {
        border: '18px solid transparent',
        right: 61,
        '@media (min-width: 600px)': {
            right: 69
        },
    },
}, { name: 'PopperMenu' });

interface PopperMenuItem {
    text: string;
    clickHandler: (event: React.MouseEvent<HTMLElement>, key: string | number) => Promise<void> | void;
    icon?: JSX.Element;
    classes?: string[];
    key?: string | number;
}

interface Props {
    zIndex: number;
    closePopperHandler: (event?: MouseEvent | TouchEvent) => void;
    anchorEl: HTMLElement | null;
    menuItems: PopperMenuItem[];
    arrowEnabled: boolean;
    children?: React.ReactNode;
};

const PopperMenu = (props: Props) => {
    const {
        zIndex,
        closePopperHandler,
        anchorEl,
        menuItems,
        arrowEnabled
    } = props;

    const classes = useStyles();

    return (
        <GPopper
            anchorEle={anchorEl}
            className={classes.menuPopper}
            arrowClass={classes.arrow}
            paperClass={classes.menuPaper}
            zIndex={zIndex + 2}
            closePopper={closePopperHandler}
            placement='bottom'
            hideArrow={!arrowEnabled}
        >
            {menuItems.map((item, index) => {
                const consumerStyles = item.classes || [];
                return (
                    <MenuItem
                        key={item.key || index}
                        className={classNames(
                            classes.menuItem,
                            ...consumerStyles
                        )}
                        onClick={(e) => item.clickHandler(e, item.key || index)}
                    >
                        {item.icon}&nbsp;
                        {item.text}
                    </MenuItem>
                );
            })}
        </GPopper>
    );
};

export default PopperMenu;
