import * as React from 'react';
import { registerAppError } from '../../../../actions/errors';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getDataURIFromFile } from '../../../../services';
import { SelfLoginSrcAction } from './SelfLoginDialog';
import UserAvatar from '../../../common/UserAvatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { PhotoScopeEnum, PhotoTransformationsType } from '../../../../shared/types';
import PhotoCropper from '../../../profileImage/PhotoCropper';
import { StoreState } from '../../../../types';
import { updateVisitorPhoto } from '../../../../actions/visitor.action';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import { GStyles } from '../../../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    message: {
        color: theme.palette.secondary.main,
        fontSize: 18,
        fontWeight: 200,
        marginTop: '8px',
        marginBottom: '30px',
        '@media (min-width: 768px)': {
            fontSize: 22,
        },

    },
    clickStyle: {
        color: ' #909090',
        fontSize: '15px',
        fontWeight: 700,
    },
    cursor: {
        cursor: 'pointer',
    },
    messageFontSize: {
        fontSize: 15,
        marginTop: '40px',
        fontWeight: 200,
    },
    linkTextDec: {
        '& a': {
            textDecoration: 'none',
        },
    },
    profileAvatarButton: {
        width: 120,
        height: 120,
        margin: '8px 0',
        overflow: 'hidden',
        '& svg': {
            fontSize: 140
        },
        '& img': {
            height: 'auto',
            width: '100%'
        }
    },
    uploadProfilePicContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
});

const mapStateToProps = ({ userSession }: StoreState) => {
    return {
        userData: userSession.userData,
    };
};

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    gotoLink: () => void;
    gotoDisplayMessage: string;
    srcAction: SelfLoginSrcAction;
    zIndex: number;
}

type StyledProps = WithStyles<'root' | 'message' | 'clickStyle' | 'cursor'
    | 'messageFontSize' | 'linkTextDec' | 'profileAvatarButton' | 'uploadProfilePicContainer'
>;
type CombinedProps = StyledProps & Props;

interface State {
    isPhotoCropperOpen: boolean;
    profilePhoto: string | null;
    transformations: PhotoTransformationsType;
}

class SelfLoginAccountCreationSuccess extends React.Component<CombinedProps, State> {
    protected fileUploadInput: HTMLInputElement | null = null;

    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            isPhotoCropperOpen: false,
            profilePhoto: props.userData && props.userData.photo ? props.userData.photo : null,
            transformations: {},
        };
    }

    componentDidUpdate = (prevProps: CombinedProps) => {
        const { userData } = this.props;
        const prevPhoto = prevProps.userData && prevProps.userData.photo ? prevProps.userData.photo : null;
        if (userData && (userData.photo !== prevPhoto)) {
            this.setState({
                profilePhoto: userData ? userData.photo : null
            });
        }
    };

    render() {
        const {
            classes, gotoLink, gotoDisplayMessage, userData, srcAction, zIndex
        } = this.props;
        const { profilePhoto, isPhotoCropperOpen } = this.state;

        const displayMessage = srcAction === SelfLoginSrcAction.sharePhoto ? 'Your photo has been shared. Thank you.'
            : srcAction === SelfLoginSrcAction.shareText ? 'Your messsage has been recorded. Thank you.'
                : srcAction === SelfLoginSrcAction.signGuestbook ? 'Thank you for signing the guestbook.'
                    : ' Thank you.';
        return (
            <div>

                <Grid container className={classes.root} justifyContent="center" >
                    <input
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        className={GStyles.displayNone}
                        ref={this.registerFileUpload}
                        onChange={this.handleFileUploadEvent}
                        onClick={e => {
                            const element = e.target as HTMLInputElement;
                            // clear this value to so that same photo can be chosen each time
                            element.value = '';
                        }}
                    />
                    <Grid item xs={12} sm={8} md={8} lg={6} m="auto">
                        <Typography variant="h5" component="p" align="center" className={classes.message}>
                            {displayMessage}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.linkTextDec}>

                        <Typography
                            variant="h5"
                            gutterBottom
                            noWrap
                            align="center"
                            component="p"
                            className={`${classes.clickStyle} ${classes.cursor}`}
                            onClick={() => gotoLink()}
                        >
                            {gotoDisplayMessage || ' Return to Log In '}
                        </Typography>

                    </Grid>

                    {userData && userData.photo ?
                        <UserAvatar
                            user={userData}
                            color="primary"
                            className={classes.profileAvatarButton}
                            size={120}
                        />
                        : <div className={classes.uploadProfilePicContainer}>
                            <IconButton
                                className={classes.profileAvatarButton}
                                color="primary"
                                onClick={(e) => this.fileUploadInput && this.fileUploadInput.click()}
                                size="large">

                                {profilePhoto ?
                                    <img src={profilePhoto} />
                                    : <AccountCircleIcon color="primary" />
                                }
                            </IconButton>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => this.fileUploadInput && this.fileUploadInput.click()}
                            >
                                <AddAPhotoIcon color="primary" />
                                &nbsp;Upload your profile photo
                            </Button>
                        </div>
                    }

                </Grid>
                <PhotoCropper
                    scope={PhotoScopeEnum.web}
                    imageURI={profilePhoto || ''}
                    callBackAction={this.handleSaveProfileImage}
                    isDialogOpen={isPhotoCropperOpen}
                    closeDialog={() => this.closePhotoCropper()}
                    zIndex={zIndex + 1}
                />
            </div>
        );
    }

    registerFileUpload = (fileUploadInput: HTMLInputElement) => {
        this.fileUploadInput = fileUploadInput;
    };

    handleFileUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length !== 0) {
            const dataURI = await getDataURIFromFile(event.target.files[0]);

            this.setState({ profilePhoto: dataURI });
            this.openPhotoCropper();
        }
    };

    handleSaveProfileImage = async (
        transformations: PhotoTransformationsType,
        newImageURI: string,
    ) => {
        const { userData, dispatch } = this.props;
        const { profilePhoto } = this.state;

        this.setState({ transformations });
        if (userData && profilePhoto) {
            const { entity_id: entityId } = userData;
            dispatch(updateVisitorPhoto(entityId, profilePhoto, transformations));
        } else {
            dispatch(registerAppError('Unable to update photo'));
        }
    };

    openPhotoCropper = () => {
        this.setState({ isPhotoCropperOpen: true });
    };

    closePhotoCropper = () => {
        this.setState({ isPhotoCropperOpen: false });
    };
}

export default withState(mapStateToProps)(withStyles(styles)(SelfLoginAccountCreationSuccess));
