import { useState } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { SUCCESS_COLOR } from '../../../../constants/colorVariables';
import ReorderStepIcon from './ReorderStepIcon';
import makeGStyles from '../../../../styles/makeGStyles';
import { CaseTaskUX, TaskState } from '../../../../shared/types';
import QRPendingStepsMenu from '../QRStepper/QRPendingStepsMenu';
import QRCompletedStepsMenu from '../QRStepper/QRCompletedStepsMenu';
import { useGSelector } from '../../../../types';
import { canCompleteTrackingSteps } from '../../../../shared/authority/can';
import { TOOLTIPS } from '../../../../constants';

const useStyles = makeGStyles(
    (theme) => ({
        headerIconContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textAlign: 'right',
        },
        taskContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        headerTitleContainer: {
            width: 'calc(100% - 84px)',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
        },
        headerIconButton: {
            height: 32,
            width: 32,
            verticalAlign: 'unset',
        },
        taskCard: {
            listStyle: 'none',
            background: theme.palette.common.white,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            '&$isFirstEle': {
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            },
        },
        headingText: {
            display: 'inline-block',
            maxWidth: 'calc(100% - 34px)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: 18,
        },
        headingTextComplete: {
            color: SUCCESS_COLOR,
        },
        isFirstEle: {},
    }),
    { name: 'ReorderSteps' },
);

interface Props {
    trackingStep: CaseTaskUX;
    isFirstItem?: boolean;
    openSkipStepDialog: (stepId: number) => void;
    onClick: (step: CaseTaskUX) => void;
    onDueDateClick: (stepId: number) => void;
}

const ReorderStep = (props: Props) => {
    const { isFirstItem, trackingStep, openSkipStepDialog, onClick, onDueDateClick } = props;

    const classes = useStyles();

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

    const selectedCase = useGSelector(({ casesState }) => casesState.selectedCase);
    const userData = useGSelector(({ userSession }) => userSession.userData);

    const canCompleteTracking = selectedCase && canCompleteTrackingSteps(userData, selectedCase.funeral_home_id);

    const isPending = trackingStep.state === TaskState.incomplete;
    const isCompleted = trackingStep.state === TaskState.complete;
    const isSkipped = trackingStep.state === TaskState.skipped;
    const title = isCompleted ? trackingStep.past_tense_title : trackingStep.title;

    const labelToolTip = isCompleted
        ? `Completed steps can't be edited`
        : isPending && !canCompleteTracking
        ? TOOLTIPS.DISABLED_FEATURE
        : '';

    return (
        <Grid item xs={12} className={classNames(classes.taskCard, isFirstItem && classes.isFirstEle)}>
            <Grid className={classes.taskContainer}>
                <div className={classes.headerTitleContainer}>
                    {!isCompleted && <ReorderStepIcon />}

                    <Tooltip title={labelToolTip} placement="top" enterDelay={600}>
                        <Typography
                            color="primary"
                            className={classNames(
                                classes.headingText,
                                isPending && !canCompleteTracking ? undefined : classes.clickable,
                                isCompleted ? classes.headingTextComplete : classes.colorOrange,
                                isSkipped && classes.strikeThrough,
                            )}
                            onClick={() => (isPending && !canCompleteTracking ? undefined : onClick(trackingStep))}
                        >
                            {title}
                        </Typography>
                    </Tooltip>
                </div>
                <div className={classes.headerIconContainer}>
                    <IconButton
                        className={classes.headerIconButton}
                        onClick={(evt) => setMenuAnchorEl(evt.currentTarget)}
                    >
                        <MoreVertIcon
                            className={classNames(isCompleted ? classes.headingTextComplete : classes.colorOrange)}
                        />
                    </IconButton>
                </div>
            </Grid>

            {isPending && selectedCase ? (
                <QRPendingStepsMenu
                    zIndex={1320}
                    selectedCase={selectedCase}
                    activeStep={{
                        label: trackingStep.title,
                        stepId: trackingStep.id,
                        completedByTime: trackingStep.complete_by_time,
                        onClick: () => onClick(trackingStep),
                    }}
                    menuAnchor={menuAnchorEl}
                    closeMenu={() => setMenuAnchorEl(null)}
                    openSkipStepDialog={openSkipStepDialog}
                    onDueDateClick={onDueDateClick}
                />
            ) : (
                <QRCompletedStepsMenu
                    zIndex={1320}
                    activeStep={{
                        stepId: trackingStep.id,
                        status: trackingStep.state,
                        type: trackingStep.tracking_step_type,
                        onClick: () => onClick(trackingStep),
                    }}
                    menuAnchor={menuAnchorEl}
                    openNotesDialog={() => void 0}
                    closeMenu={() => setMenuAnchorEl(null)}
                />
            )}
        </Grid>
    );
};

export default ReorderStep;
