import { ReactNode, useState } from "react";
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import BulkEditBaseContainer from "./BulkEditBaseContainer";
import { FormControl, TextField } from "@mui/material";
import { useBulkEditStyles } from "./BulkEditStyles";
import { useGDispatch } from '../../../../../types';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductUX } from '../../../../../shared/types';

interface EditDefaultUnitAmountProps {
    selectButton: BulkEditButtonType | null;
    children?: ReactNode | ReactNode[];
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const EditDefaultUnitAmount = (props: EditDefaultUnitAmountProps) => {
    const {
        selectButton,
        onSubmitClick,
        selectedProductIds,
        funeralHomeId,
    } = props;
    const classes = useBulkEditStyles();
    const dispatch = useGDispatch();

    const [inputText, setInputText] = useState('');
    const [isSpinning, setIsSpinning] = useState(false);

    const handleSubmitClick = async () => {
        const numericValue = parseFloat(inputText || '0');
        if (isNaN(numericValue)) {
            return;
        }
        // call api for bulk edit default unit amount
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.edit_default_unit_quantity,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: { var_default_quantity: numericValue },
        }));

        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <BulkEditBaseContainer
            disableSubmitButton={!inputText.trim()}
            onSubmitClick={handleSubmitClick}
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            isSpinning={isSpinning}
        >
            <FormControl className={classes.selectMenuForm}>
                <TextField
                    fullWidth
                    onChange={(evt) => setInputText(evt.target.value)}
                    autoComplete="off"
                    label="Default Unit Amount"
                    type="number"
                    value={inputText}
                />
            </FormControl>
        </BulkEditBaseContainer>
    );
};

export default EditDefaultUnitAmount;