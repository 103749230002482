import ReportIcon from '@mui/icons-material/Report';
import { makeStyles } from '@mui/styles';
import BaseErrorPage from '../common/BaseErrorPage';

const useStyles = makeStyles({
    root: {
        margin: 0,
        paddingTop: 60,
    },
}, { name: 'AppErrorPage' });

const AppErrorPage = () => {
    const heading = 'Oh no! Something went wrong...';
    const classes = useStyles();

    return (
        <BaseErrorPage
            HeadingIcon={ReportIcon}
            heading={heading}
            rootClass={classes.root}
            hideUserInfo={true}
        />
    );
};

export default AppErrorPage;
