import { SortableHandle } from 'react-sortable-hoc';

import Tooltip from '@mui/material/Tooltip';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { ORANGE_COLOR } from '../../../../constants/colorVariables';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    dragHandleIcon: {
        cursor: 'grab',
        '& svg': {
            fontSize: 26,
            margin: 0,
            color: ORANGE_COLOR,
        }
    },
}, { name: 'ReorderStepIcon' });

const DragHandle = SortableHandle(DragHandleIcon);

const ReorderStepIcon = () => {
    const classes = useStyles();

    const dragTitle = `Click to reorder this step`;

    return (
        <Tooltip title={dragTitle} placement="top" enterDelay={600} enterNextDelay={600}>
            <span className={classes.dragHandleIcon}>
                <DragHandle color="primary" />
            </span>
        </Tooltip>
    );
};

export default ReorderStepIcon;
