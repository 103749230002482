import { includes } from 'lodash';
import * as React from 'react';

export enum AcceptFileType {
    PNG = 'image/png',
    GIF = 'image/gif',
    JPEG = 'image/jpeg',
    PDF = 'application/pdf',
    MSWORD = 'application/msword',
    DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    CSV = 'text/csv',
}

export type FileSaveHandler = (files: FileList | null) => void;

interface Props {
    acceptTypes: AcceptFileType[];
    handleSave: FileSaveHandler;
    children: (inputRef: Readonly<HTMLInputElement>) => JSX.Element;
    multiple?: boolean;
    capture?: 'user' | 'environment';
}

interface State {
    inputEl: HTMLInputElement | null;
}

class FileUploader extends React.Component<Props, State> {
    state: State = {
        inputEl: null
    };

    setInputRefInState = (inputEl: HTMLInputElement | null) => {
        if (!this.state.inputEl) {
            this.setState({ inputEl });
        }
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { acceptTypes, handleSave } = this.props;

        const files = event.target.files;

        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const isAcceptedType = includes(acceptTypes, file.type);
                if (!isAcceptedType) {
                    return;
                }
            }
        }

        handleSave(files);
    };

    render() {
        const { children, acceptTypes, multiple, capture } = this.props;
        const { inputEl } = this.state;

        const acceptFileTypes = acceptTypes.join(', ');

        return (
            <>
                <input
                    type="file"
                    accept={acceptFileTypes}
                    ref={this.setInputRefInState}
                    style={{ display: 'none' }}
                    multiple={multiple}
                    capture={capture}
                    onClick={e => {
                        const element = e.target as HTMLInputElement;
                        // clear this value to so that same photo can be chosen each time
                        element.value = '';
                    }}
                    onChange={this.onChange}
                />

                {inputEl && children(inputEl)}
            </>
        );
    }
}

export default FileUploader;