import {
    EntityCaseRole,
    EntitySummary,
    TaskState,
} from '../shared/types';
import { MySettingsTab } from '../components/dialogs/mySettings';
import { SelfLoginSrcAction } from '../components/family/remember/selfLogin/SelfLoginDialog';
import {
    GuestPopperState,
    HelperInvitationDialogState,
    SelfLoginDialogState,
    TaskForFinalizeDialog,
} from '../types/DialogState';

// OPEN_HELPER_INVITATION_DIALOG
export const OPEN_HELPER_INVITATION_DIALOG = 'OPEN_HELPER_INVITATION_DIALOG';
export type OPEN_HELPER_INVITATION_DIALOG = typeof OPEN_HELPER_INVITATION_DIALOG;

export enum HelperInvitationDialogContext {
    payment = 'payment',
    addSurvivors = 'addSurvivors',
    addPreceededInDeath = 'addPreceededInDeath'
}
interface OpenHelperInvitationDialog extends Omit<HelperInvitationDialogState, 'isOpen'> {
    type: OPEN_HELPER_INVITATION_DIALOG;
}

interface OpenHelperInvitationDialogParams extends Partial<Omit<OpenHelperInvitationDialog, 'type'>> {
    zIndex: number;
    defaultTab: EntityCaseRole;
}

export function openHelperInvitationDialog({
    zIndex,
    defaultTab,
    sendInvite,
    selectedEntityId,
    relationshipType,
    relationship,
    relationToDeceased,
    onAddedCallback,
    context,
    aliveType,
    homeAddressOptional,
}: OpenHelperInvitationDialogParams): OpenHelperInvitationDialog {
    return {
        type: OPEN_HELPER_INVITATION_DIALOG,
        zIndex,
        defaultTab,
        sendInvite: sendInvite === undefined ? true : sendInvite,
        selectedEntityId: selectedEntityId || null,
        relationshipType: relationshipType || null,
        relationship: relationship || null,
        relationToDeceased: relationToDeceased || null,
        onAddedCallback: onAddedCallback || null,
        context: context || null,
        aliveType: aliveType || null,
        homeAddressOptional: homeAddressOptional || false,
    };
}

// CLOSE_HELPER_INVITATION_DIALOG
export const CLOSE_HELPER_INVITATION_DIALOG = 'CLOSE_HELPER_INVITATION_DIALOG';
export type CLOSE_HELPER_INVITATION_DIALOG = typeof CLOSE_HELPER_INVITATION_DIALOG;

interface CloseHelperInvitationDialog {
    type: CLOSE_HELPER_INVITATION_DIALOG;
}

export function closeHelperInvitationDialog(): CloseHelperInvitationDialog {
    return {
        type: CLOSE_HELPER_INVITATION_DIALOG,
    };
}

//  SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID
export const SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID = 'SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID';
export type SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID = typeof SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID;

interface SetHelperInvitationDialogSelectedEntityId {
    type: SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID;
    selectedEntityId: number | null;
}

export function setHelperInvitationDialogSelectedEntityId(
    entityId: number | null,
): SetHelperInvitationDialogSelectedEntityId {
    return {
        type: SET_HELPER_INVITATION_DIALOG_SELECTED_ENTITY_ID,
        selectedEntityId: entityId || null,
    };
}

// OPEN_TEAM_INVITATION_DIALOG
export const OPEN_TEAM_INVITATION_DIALOG = 'OPEN_TEAM_INVITATION_DIALOG';
export type OPEN_TEAM_INVITATION_DIALOG = typeof OPEN_TEAM_INVITATION_DIALOG;

interface OpenTeamInvitationDialog {
    type: OPEN_TEAM_INVITATION_DIALOG;
    zIndex: number;
    onAddedCallback?: (teamMember: EntitySummary) => void;
}

export function openTeamInvitationDialog(params: Omit<OpenTeamInvitationDialog, 'type'>): OpenTeamInvitationDialog {
    return {
        type: OPEN_TEAM_INVITATION_DIALOG,
        ...params,
    };
}

// CLOSE_TEAM_INVITATION_DIALOG
export const CLOSE_TEAM_INVITATION_DIALOG = 'CLOSE_TEAM_INVITATION_DIALOG';
export type CLOSE_TEAM_INVITATION_DIALOG = typeof CLOSE_TEAM_INVITATION_DIALOG;

interface CloseTeamInvitationDialog {
    type: CLOSE_TEAM_INVITATION_DIALOG;
}

export function closeTeamInvitationDialog(): CloseTeamInvitationDialog {
    return {
        type: CLOSE_TEAM_INVITATION_DIALOG,
    };
}

// OPEN_MY_SETTINGS_DIALOG
export const OPEN_MY_SETTINGS_DIALOG = 'OPEN_MY_SETTINGS_DIALOG';

interface OpenMySettingsDialog {
    type: typeof OPEN_MY_SETTINGS_DIALOG;
    zIndex: number;
    entityId: number;
    tab: MySettingsTab | null;
    callback: ((entity: EntitySummary) => void) | null;
}

export function openMySettingsDialog(
    zIndex: number,
    entityId: number,
    tab?: MySettingsTab,
    callback?: (entity: EntitySummary) => void
): OpenMySettingsDialog {
    return {
        type: OPEN_MY_SETTINGS_DIALOG,
        zIndex,
        entityId,
        tab: tab || null,
        callback: callback || null
    };
}

// CLOSE_MY_SETTINGS_DIALOG
export const CLOSE_MY_SETTINGS_DIALOG = 'CLOSE_MY_SETTINGS_DIALOG';

interface CloseMySettingsDialog {
    type: typeof CLOSE_MY_SETTINGS_DIALOG;
}

export function closeMySettingsDialog(): CloseMySettingsDialog {
    return {
        type: CLOSE_MY_SETTINGS_DIALOG,
    };
}

// OPENED_APP_OUTDATED_DIALOG
export const OPENED_APP_OUTDATED_DIALOG = 'OPENED_APP_OUTDATED_DIALOG';

interface OpenedAppOutdatedDialog {
    type: typeof OPENED_APP_OUTDATED_DIALOG;
}

export function openAppOutdatedDialog(): OpenedAppOutdatedDialog {
    return {
        type: OPENED_APP_OUTDATED_DIALOG,
    };
}

// CLOSED_APP_OUTDATED_DIALOG
export const CLOSED_APP_OUTDATED_DIALOG = 'CLOSED_APP_OUTDATED_DIALOG';

interface ClosedAppOutdatedDialog {
    type: typeof CLOSED_APP_OUTDATED_DIALOG;
}

export function closeAppOutdatedDialog(): ClosedAppOutdatedDialog {
    return {
        type: CLOSED_APP_OUTDATED_DIALOG,
    };
}

// OPEN_SELFLOGIN_DIALOG

interface OpenSelfLoginDialogParams extends Omit<SelfLoginDialogState, 'isOpen'> {
    srcAction: SelfLoginSrcAction; // make this required
}
export const OPEN_SELFLOGIN_DIALOG = 'OPEN_SELFLOGIN_DIALOG';

interface OpenSelfLoginDialog extends OpenSelfLoginDialogParams {
    type: typeof OPEN_SELFLOGIN_DIALOG;
}

export function openSelfLoginDialog(params: OpenSelfLoginDialogParams): OpenSelfLoginDialog {
    return {
        type: OPEN_SELFLOGIN_DIALOG,
        ...params,
    };
}

// CLOSE_SELFLOGIN_DIALOG
export const CLOSE_SELFLOGIN_DIALOG = 'CLOSE_SELFLOGIN_DIALOG';

interface CloseSelfLoginDialog {
    type: typeof CLOSE_SELFLOGIN_DIALOG;
}

export function closeSelfLoginDialog(): CloseSelfLoginDialog {
    return {
        type: CLOSE_SELFLOGIN_DIALOG,
    };
}

// OPEN_GUEST_POPPER
interface OpenGuestPopperParams extends Omit<GuestPopperState, 'parentId'> {
    parentId?: string;
}
export const OPEN_GUEST_POPPER = 'OPEN_GUEST_POPPER';

interface OpenGuestPopper extends GuestPopperState {
    type: typeof OPEN_GUEST_POPPER;
}

export function openGuestPopper(params: OpenGuestPopperParams): OpenGuestPopper {
    return {
        type: OPEN_GUEST_POPPER,
        ...params,
        parentId: params.parentId || null
    };
}

// CLOSE_GUEST_POPPER
export const CLOSE_GUEST_POPPER = 'CLOSE_GUEST_POPPER';

interface CloseGuestPopper {
    type: typeof CLOSE_GUEST_POPPER;
}

export function closeGuestPopper(): CloseGuestPopper {
    return {
        type: CLOSE_GUEST_POPPER,
    };
}

// OPEN_FINALIZE_TASK_DIALOG
export const OPEN_FINALIZE_TASK_DIALOG = 'OPEN_FINALIZE_TASK_DIALOG';

interface OpenFinalizeTaskDialog {
    type: typeof OPEN_FINALIZE_TASK_DIALOG;
    zIndex: number;
    task: TaskForFinalizeDialog;
    state: TaskState;
    onFinalized?: () => void;
}

export function openFinalizeTaskDialog(params: Omit<OpenFinalizeTaskDialog, 'type'>): OpenFinalizeTaskDialog {
    return {
        type: OPEN_FINALIZE_TASK_DIALOG,
        ...params,
    };
}

// CLOSE_FINALIZE_TASK_DIALOG
export const CLOSE_FINALIZE_TASK_DIALOG = 'CLOSE_FINALIZE_TASK_DIALOG';

interface CloseFinalizeTaskDialog {
    type: typeof CLOSE_FINALIZE_TASK_DIALOG;
}

export function closeFinalizeTaskDialog(): CloseFinalizeTaskDialog {
    return {
        type: CLOSE_FINALIZE_TASK_DIALOG,
    };
}

export type DialogAction =
    | OpenHelperInvitationDialog
    | CloseHelperInvitationDialog
    | SetHelperInvitationDialogSelectedEntityId
    | OpenTeamInvitationDialog
    | CloseTeamInvitationDialog
    | OpenMySettingsDialog
    | CloseMySettingsDialog
    | OpenedAppOutdatedDialog
    | ClosedAppOutdatedDialog
    | OpenSelfLoginDialog
    | CloseSelfLoginDialog
    | OpenGuestPopper
    | CloseGuestPopper
    | OpenFinalizeTaskDialog
    | CloseFinalizeTaskDialog
    ;
