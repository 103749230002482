import { WithStyles } from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { RolodexHelperResult } from '../../../shared/types';
import { RowClickHandlerFunc } from './utils';

type StyledProps = WithStyles<
    'heading' | 'firstItem' | 'subHeading' | 'profession' | 'location' | 'tabContainerDivider'
>;

type Props = {
    helper: RolodexHelperResult;
    rowClickHandler: RowClickHandlerFunc;
} & StyledProps;

const HelperRow = (props: Props) => {
    const { helper, rowClickHandler, classes } = props;
    const relationshipText = helper.relationship === null ? '' : ` (${helper.relationship})`;
    return (
        <>
            <Grid item xs={12} className={classes.firstItem} onClick={() => rowClickHandler(helper)}>
                <Typography color="primary" className={classes.heading}>
                    <SupervisedUserCircleIcon color="primary" />&nbsp;{helper.displayName}
                </Typography>
                <Grid item xs={12} className={classes.subHeading}>
                    <Typography color="secondary" className={classes.profession}>
                        {helper.role}{relationshipText}
                    </Typography>
                    <Typography color="secondary" className={classes.location}>
                        <ViewCarouselIcon color="secondary" />&nbsp;Case: {helper.caseName}
                    </Typography>
                </Grid>
            </Grid>
            <Divider color="secondary" className={classes.tabContainerDivider} />
        </>
    );
};

export default HelperRow;
