import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import FiberNewIcon from '@mui/icons-material/FiberNew';

import { useGDispatch, useGSelector } from '../../../types';
import { closeAppOutdatedDialog } from '../../../actions/Dialog.action';
import { SlideTransition } from '../../common/Transitions';
import { darken, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            '& $dialogPaper': {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-around',
                width: 300,
                maxWidth: 300,
                minWidth: 300,
                height: 'auto',
                borderRadius: 12,
                boxShadow: theme.shadows[5],
                [theme.breakpoints.up('md')]: {
                    maxWidth: 300,
                    minWidth: 300,
                    width: 300,
                    height: 'auto',
                    maxHeight: 'auto',
                },
            },
        },
        dialogContent: {
            zIndex: 0,
            padding: 16,
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        oudatedIcon: {
            color: 'inherit',
            fontSize: 120,
        },
        headerLine1: {
            fontSize: 24,
            color: 'inherit',
            textAlign: 'center',
            width: '100%',
        },
        headerLine2: {
            fontSize: 24,
            color: 'inherit',
            borderBottom: '1px solid',
            textAlign: 'center',
            width: '100%',
            paddingBottom: 5,
        },
        details: {
            color: 'inherit',
            fontSize: 14,
            fontWeight: 500,
            paddingTop: 10,
        },
        refreshButton: {
            background: theme.palette.common.white,
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '&:hover': {
                borderColor: theme.palette.primary.main,
                background: darken(theme.palette.common.white, 0.1),
            },
            margin: '24px auto 8px',
        },
        cancelButton: {
            margin: '24px auto 8px',
        },
        dialogPaper: {},
    }),
    { name: 'AppOutdatedDialog' },
);

const AppOutdatedDialog = () => {
    const classes = useStyles();

    const dispatch = useGDispatch();
    const isDialogOpen = useGSelector(({ dialogState }) => dialogState.appOutdated.isOpen);

    const closeDialog = () => {
        dispatch(closeAppOutdatedDialog());
    };

    const refreshApp = () => {
        window.location.reload();
        closeDialog();
    };

    return (
        <Dialog
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={closeDialog}
            className={classes.root}
            classes={{ paper: classes.dialogPaper }}
            style={{ zIndex: 1500 }}
        >
            {isDialogOpen && (
                <DialogContent className={classes.dialogContent}>
                    <Grid container justifyContent="center" alignItems="center">
                        <FiberNewIcon className={classes.oudatedIcon} />
                        <Typography className={classes.headerLine1}>Good news!</Typography>

                        <Typography className={classes.headerLine2}>We've improved the app</Typography>

                        <Typography className={classes.details}>Click below to get the latest updates</Typography>

                        <Typography className={classes.details} textAlign="center">
                            Click the heart on the top-left of your DashBoard to see what's new!
                        </Typography>

                        <Button
                            className={classes.cancelButton}
                            variant="outlined"
                            color="inherit"
                            onClick={closeDialog}
                        >
                            Not yet
                        </Button>

                        <Button className={classes.refreshButton} variant="contained" onClick={refreshApp}>
                            Get Latest
                        </Button>
                    </Grid>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AppOutdatedDialog;
