import { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import moment from "moment";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

import AddIcon from "@mui/icons-material/Add";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import RouteIcon from "@mui/icons-material/Route";
import VerifiedIcon from '@mui/icons-material/Verified';

import UserAvatar from "../../../../../../common/UserAvatar";
import makeGStyles from "../../../../../../../styles/makeGStyles";
import CaseNumberDialog from "../../../../../../cases/caseCard/dialogs/CaseNumber.dialog";
import AddLabelDialog from "../../../../../labels/addLabelDialog/AddLabel.dialog";
import {
    getDecedentAge,
    getFullNameFromCase,
    getIntercomTargetProp,
    joinNameParts
} from "../../../../../../../services";
import { CaseType, GatherCaseSummary } from "../../../../../../../shared/types";
import { ButtonType, CaseTypeButton } from "../../../../../../cases/type/widgets";
import { KEEP_TRACK_TRADEMARK } from "../../../../../../../constants";

const useStyles = makeGStyles(theme => ({
    avatarSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: -18,
        marginBottom: 20,
    },
    avatarIconButton: {
        height: 80,
        width: 80,
        fontSize: 36,
        textTransform: 'uppercase',
        fontWeight: 200
    },
    whiteColor: {
        color: `#fff !important`
    },
    text: {
        lineHeight: 1.25,
        fontSize: 12
    },
    caseNumber: {
        padding: 4,
        '& p': {
            fontSize: 12
        }
    },
    qrCode: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'default',
        '& $icon': {
            fontSize: 16
        }
    },
    chipsContainer: {
        margin: '6px 0',
        gap: '6px'
    },
    newLabelChip: {
        color: theme.palette.secondary.main,
        border: '2px dashed',
        background: '#fff',
        padding: '0 2px 0 6px',
    },
    chip: {
        height: 28,
        padding: '0 4px',
        fontSize: 14
    },
    caseNumberEllipsis: {
        width: 'fit-content',
        maxWidth: 90,
        minHeight: 33,
        maxHeight: 33,
        fontSize: 13,
        lineHeight: 1.25,
        fontStyle: 'italic',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        color: theme.palette.secondary.main,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        '&:hover': {
            textDecoration: 'underline'
        },
        '&$disabled': {
            cursor: 'initial',
            textDecoration: 'none !important'
        },
        '@media (min-width: 425px)': {
            maxWidth: 140,
        }
    },
    secondaryBackground: {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        opacity: '1 !important'
    },
    caseTypeButtonContainer: {
        position: 'absolute',
        transform: 'translateY(72px)',
        '& > button': {
            borderRadius: 24,
        },
    },
    disabled: {},
    icon: {}
}), { name: 'CaseSummary' });

const DATE_FORMAT = 'MM/DD/YYYY';

interface Props {
    zIndex: number;
    gatherCase: GatherCaseSummary;
    disableEdit?: boolean;
    selected?: boolean;
    onWorkflowClick?: () => void;
    intercomTargetPropCaseNumber?: string;
    intercomTargetPropTrackerNumber?: string;
    intercomTargetPropDecedentAvatar?: string;
    intercomTargetPropCaseType?: string;
    intercomTargetPropDecedentName?: string;
    intercomTargetPropWorkFlow?: string;
    intercomTargetPropCaseCreatedByOn?: string;
    intercomTargetPropDecedentDOD?: string;
    intercomTargetPropCaseFuneralHome?: string;
    intercomTargetPropCaseLabels?: string;
}

const CaseSummary = (props: PropsWithChildren<Props>) => {
    const {
        zIndex,
        gatherCase,
        children,
        disableEdit,
        selected,
        onWorkflowClick,
        intercomTargetPropCaseNumber,
        intercomTargetPropTrackerNumber,
        intercomTargetPropDecedentAvatar,
        intercomTargetPropCaseType,
        intercomTargetPropDecedentName,
        intercomTargetPropWorkFlow,
        intercomTargetPropCaseCreatedByOn,
        intercomTargetPropDecedentDOD,
        intercomTargetPropCaseFuneralHome,
        intercomTargetPropCaseLabels,
    } = props;

    const classes = useStyles();

    const [addLabelDialogOpen, setAddLabelDialogOpen] = useState(false);
    const [caseNumberDialogOpen, setCaseNumberDialogOpen] = useState(false);

    const openCaseNumberDialog = () => {
        setCaseNumberDialogOpen(true);
    };

    const closeCaseNumberDialog = () => {
        setCaseNumberDialogOpen(false);
    };

    const openAddLabelDialog = () => {
        setAddLabelDialogOpen(true);
    };

    const closeAddLabelDialog = () => {
        setAddLabelDialogOpen(false);
    };

    const caseFullName = getFullNameFromCase(gatherCase);
    const hasCaseNumber = !!gatherCase.case_number;
    const { keeptrack_key } = gatherCase;
    const keepTrackAssigned = !!keeptrack_key;
    const createdOn = moment(gatherCase.created_time || undefined).format(DATE_FORMAT);
    const createdBy = joinNameParts(gatherCase.assignee);
    const { bDate, dDate, age } = getDecedentAge({
        dob_date: gatherCase.dob_date,
        dod_start_date: gatherCase.dod_start_date
    });
    const title = gatherCase.case_number
        ? `Click to edit case number: ${gatherCase.case_number}`
        : 'Click to add case number';

    const selectedCaseType = gatherCase.case_type;
    const type: ButtonType = selectedCaseType === CaseType.pre_need && 'translucent'
        || selectedCaseType === CaseType.trade && 'dark'
        || selectedCaseType === CaseType.one_off && 'white' || 'default';

    return (
        <Grid item xs={12}>
            <Grid
                container={true}
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.caseNumber}
            >
                <Tooltip title={disableEdit ? '' : title} placement="top" enterDelay={400}>
                    <Typography
                        align="left"
                        className={!hasCaseNumber
                            ? classNames(classes.fontStyleItalic, classes.colorSecondary)
                            : undefined}
                        color="secondary"
                        {...getIntercomTargetProp(intercomTargetPropCaseNumber)}
                    >
                        <span
                            onClick={disableEdit ? undefined : openCaseNumberDialog}
                            className={classNames(classes.caseNumberEllipsis, disableEdit && classes.disabled)}
                        >
                            {`# ${gatherCase.case_number ?? 'No Case Number'}`}
                        </span>
                    </Typography>
                </Tooltip>

                <Tooltip title={<>{KEEP_TRACK_TRADEMARK} Number</>} placement="top">
                    <Typography
                        className={classNames(
                            classes.qrCode,
                            !keepTrackAssigned && classes.fontStyleItalic,
                            selected && classes.colorGreen
                        )}
                        color="secondary"
                        {...getIntercomTargetProp(intercomTargetPropTrackerNumber)}
                    >
                        {selected
                            ? <VerifiedIcon color="inherit" className={classes.icon} />
                            : <QrCode2Icon color="inherit" className={classes.icon} />
                        }&nbsp;
                        {selected
                            ? 'Selected'
                            : keepTrackAssigned ? keeptrack_key : 'No Tracker'
                        }
                    </Typography>
                </Tooltip>
            </Grid>

            <Grid className={classes.avatarSection}>
                <UserAvatar
                    size={80}
                    user={gatherCase}
                    className={classes.avatarIconButton}
                    {...getIntercomTargetProp(intercomTargetPropDecedentAvatar)}
                />

                <div className={classes.caseTypeButtonContainer}>
                    <CaseTypeButton
                        buttonStyle={type}
                        border={type === 'white'}
                        itemID={selectedCaseType}
                        active={false}
                        variant="contained"
                        buttonSize="xSmall"
                        onClick={onWorkflowClick}
                        disableElevation={!onWorkflowClick}
                        disableFocusRipple={!onWorkflowClick}
                        disableRipple={!onWorkflowClick}
                        disableTouchRipple={!onWorkflowClick}
                        tabIndex={!onWorkflowClick ? -1 : undefined}
                        {...getIntercomTargetProp(intercomTargetPropCaseType)}
                    >
                        {selectedCaseType}
                    </CaseTypeButton>
                </div>
            </Grid>

            <Grid container={true} direction="column" alignItems="center" justifyContent="center">
                <Typography
                    className={classes.fontSize18}
                    color="secondary"
                    {...getIntercomTargetProp(intercomTargetPropDecedentName)}>
                    {caseFullName}{age && `, ${age}`}
                </Typography>

                <Typography
                    className={classNames(classes.text, classes.flexCentred, onWorkflowClick && classes.clickable)}
                    color="secondary"
                    onClick={onWorkflowClick}
                    {...getIntercomTargetProp(intercomTargetPropWorkFlow)}
                >
                    <RouteIcon fontSize="inherit" />&nbsp;{gatherCase.workflow_name || 'No WorkFlow Selected'}
                </Typography>


                <Typography
                    className={classes.text}
                    color="secondary"
                    {...getIntercomTargetProp(intercomTargetPropCaseCreatedByOn)}
                >
                    Created  {createdOn && `on ${createdOn} `}by {createdBy}
                </Typography>

                {bDate &&
                    <Typography className={classes.text} color="secondary">
                        Date of Birth: {bDate.format(DATE_FORMAT)}
                    </Typography>
                }
                {dDate &&
                    <Typography
                        className={classes.text}
                        color="secondary" {...getIntercomTargetProp(intercomTargetPropDecedentDOD)}
                    >
                        Date of Death: {dDate.format(DATE_FORMAT)}
                    </Typography>
                }
                <Typography
                    className={classNames(classes.text, classes.fontSize13)}
                    color="secondary"
                    {...getIntercomTargetProp(intercomTargetPropCaseFuneralHome)}
                >
                    {gatherCase.funeral_home_name}
                </Typography>
            </Grid>

            <Grid
                container={true}
                justifyContent="center"
                alignItems="center"
                className={classes.chipsContainer}
                {...getIntercomTargetProp(intercomTargetPropCaseLabels)}
            >
                {gatherCase.labels.map(label =>
                    <Chip
                        className={classNames(classes.whiteColor, classes.chip)}
                        label={label.name}
                        style={{ background: label.color }}
                        key={label.id}
                        onClick={disableEdit
                            ? undefined
                            : evt => {
                                evt.stopPropagation();
                                openAddLabelDialog();
                            }}
                    />
                )}

                {!disableEdit &&
                    <Chip
                        icon={<AddIcon color="inherit" className={classes.fontSize16} />}
                        label="Label"
                        onClick={evt => {
                            evt.stopPropagation();
                            openAddLabelDialog();
                        }}
                        className={classNames(classes.chip, classes.newLabelChip)}
                    />
                }
            </Grid>

            {children}

            <CaseNumberDialog
                activeCase={gatherCase}
                isDialogOpen={caseNumberDialogOpen}
                closeDialog={closeCaseNumberDialog}
                zIndex={zIndex + 1}
            />

            <AddLabelDialog
                zIndex={zIndex + 1}
                isOpen={addLabelDialogOpen}
                gatherCase={gatherCase}
                funeralHomeId={gatherCase.funeral_home_id}
                onClose={closeAddLabelDialog}
            />
        </Grid>
    );
};

export default CaseSummary;
