import { Grid, alpha } from '@mui/material';
import { BulkEditButtonType, PrimaryButtonsEnum, ButtonType } from './BulkEditSelectedOptionContent';
import GGroupButtons from '../../../../common/GGroupButtons';
import { APP_SECONDARY_COLOR } from '../../../../../constants/colorVariables';
import { ReactNode } from 'react';
import BulkEditBaseContainer from './BulkEditBaseContainer';

interface BulkEditContainerProps {
    selectButton: BulkEditButtonType | null;
    buttons: ButtonType<PrimaryButtonsEnum>[];
    activePrimaryButton: PrimaryButtonsEnum | null;
    setActivePrimaryButton: (activePrimaryButton: PrimaryButtonsEnum | null) => void;
    children?: ReactNode | ReactNode[];
    selectedProductsCount: number;
    onSubmitClick: () => Promise<void>;
    disableSubmitButton: boolean;
    isSpinning: boolean;
}

const BulkEditContainer = (props: BulkEditContainerProps) => {
    const {
        selectButton,
        buttons,
        activePrimaryButton,
        setActivePrimaryButton,
        children,
        disableSubmitButton,
        onSubmitClick,
        selectedProductsCount,
        isSpinning,
    } = props;

    return (
        <BulkEditBaseContainer
            disableSubmitButton={disableSubmitButton}
            onSubmitClick={onSubmitClick}
            selectButton={selectButton}
            selectedProductsCount={selectedProductsCount}
            isSpinning={isSpinning}

        >
            <Grid item>
                <GGroupButtons
                    buttons={buttons}
                    activeButton={activePrimaryButton}
                    onClick={(option) => setActivePrimaryButton(option)}
                    sx={{
                        ['&.selected']: {
                            bgcolor: alpha(APP_SECONDARY_COLOR, 0.2),
                            color: '#000',
                        },
                        color: `${APP_SECONDARY_COLOR}`,
                        borderColor: `${APP_SECONDARY_COLOR} !important`,
                        minWidth: '120px !important',
                    }}
                />
            </Grid>
            {children}
        </BulkEditBaseContainer>
    );
};

export default BulkEditContainer;
