import classNames from 'classnames';

import UserAvatar from '../../../common/UserAvatar';
import { RED_COLOR } from '../../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../../services/app.service';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import { TaskPreview, EntitySummary, PhotoTransformationsType, TaskState } from '../../../../shared/types';
import Task from './Task';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import ListSubheader from '@mui/material/ListSubheader';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withGStyles from '../../../../styles/WithGStyles';
import { Theme } from '@mui/material/styles';
import GLink from '../../../common/GLink';
import { RouteBuilder } from '../../../../services';

interface Task {
    id: number;
    name: string;
    assignedTime: Date;
}

export interface CaseDetails {
    id: number;
    name: string;
    fname: string;
    lname?: string | null;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    fullName: string;
    tasks: TaskPreview[];
    funeralHomeKey: string;
}

interface Props {
    caseDetails: CaseDetails;
    isMe: boolean;
    teamMember: EntitySummary;
    onFinalizeTask: (task: TaskPreview, state: TaskState) => void;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    taskBorderRight: {
        color: theme.palette.primary.main,
        borderRight: '1px solid',
    },
    taskSectionBorderRadius: {
        borderRadius: '12px 0px 0px 12px',
    },
    badgeRoot: {
        display: 'flex',
        verticalAlign: 'middle',
        position: 'relative',
    },
    badge: {
        left: 'unset',
        top: 4,
        right: 4,
        borderRadius: 50,
        color: theme.palette.common.white,
        background: RED_COLOR,
        height: 28,
        width: 28,
        fontSize: 12,
    },
    avatarBadge: {
        left: 'unset',
        top: 13,
        right: 6,
        borderRadius: 50,
        color: theme.palette.common.white,
        background: RED_COLOR,
        height: 20,
        width: 20,
        fontSize: 12,
    },
    avatarRightSection: {
        paddingLeft: 8,
        '& $nameSection': {
            fontSize: 18,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    taskSection: {
        padding: '8px 4px 0px',
        borderBottom: '1px solid',
        color: convertHexToRGBA(theme.palette.common.white, 0.3),
        '&:last-child': {
            color: theme.palette.common.white,
            borderBottom: '1px solid',
        },
    },
    lineHeightForAvatar: {
        lineHeight: 1.25,
    },
    listSubHeader: {
        textTransform: 'uppercase',
        fontWeight: 400,
        lineHeight: '20px',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginBottom: '1px',
    },
    padding4: {
        padding: 4,
    },
    avatarDiv: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: 12,
        },
    },
    taskCaseBackground: {
        background: theme.palette.primary.light,
        color: convertHexToRGBA(theme.palette.common.white, 0.3),
        borderBottom: '1px solid',
    },
    avatarSize: {
        height: 40,
        width: 40,
        fontSize: 16,
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    iconButtonWidth: {
        maxHeight: 36,
        maxWidth: 36,
    },
    flexTaskSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconSize: {
        alignSelf: 'flex-end',
        '& svg': {
            fontSize: 24,
            color: theme.palette.common.white,
        },
    },
    subtitle: {},
    nameSection: {},
    lineHeight: {},
});

type StyledProps = Props &
    GLOBAL_STYLED_PROPS &
    WithStyles<
        | 'root'
        | 'badgeRoot'
        | 'badge'
        | 'avatarBadge'
        | 'avatarRightSection'
        | 'taskSection'
        | 'nameSection'
        | 'lineHeight'
        | 'avatarSize'
        | 'flexTaskSection'
        | 'listSubHeader'
        | 'padding4'
        | 'avatarDiv'
        | 'lineHeightForAvatar'
        | 'taskCaseBackground'
        | 'iconButtonWidth'
        | 'iconSize'
        | 'subtitle'
        | 'flexDayEvents'
    >;

const TasksForCase = (props: StyledProps) => {
    const { classes, caseDetails, teamMember, isMe, onFinalizeTask } = props;
    const { tasks } = caseDetails;

    const pendingTaskText =
        `${isMe ? 'You' : teamMember.fname} ${isMe ? 'have' : 'has'} ${tasks.length} ` +
        `pending task${tasks.length === 1 ? '' : 's'}`;
    return (
        <div>
            <ListSubheader
                color="inherit"
                className={classNames(classes.listSubHeader, classes.avatarDiv, classes.taskCaseBackground)}
            >
                <Badge
                    badgeContent={tasks.length}
                    classes={{
                        root: classes.badgeRoot,
                        badge: classes.avatarBadge,
                    }}
                >
                    <div className={classes.padding4}>
                        <GLink
                            to={RouteBuilder.FamilyPage({
                                caseName: caseDetails.name,
                                funeralHomeKey: caseDetails.funeralHomeKey,
                            })}
                            linkClass={classes.marginAuto}
                        >
                            <IconButton className={classes.iconButtonWidth}>
                                <UserAvatar user={caseDetails} capitalizeInitials className={classes.avatarSize} />
                            </IconButton>
                        </GLink>
                    </div>
                </Badge>
                <div className={classes.avatarRightSection}>
                    <GLink
                        to={RouteBuilder.FamilyPage({
                            caseName: caseDetails.name,
                            funeralHomeKey: caseDetails.funeralHomeKey,
                        })}
                        linkClass={classes.marginAuto}
                    >
                        <Typography
                            color="primary"
                            className={classNames(
                                classes.nameSection,
                                classes.fontWeight400,
                                classes.textTransformNone,
                                classes.lineHeightForAvatar,
                            )}
                        >
                            {caseDetails.fullName}
                        </Typography>
                    </GLink>
                    <Typography
                        component="p"
                        color="primary"
                        className={classNames(
                            classes.textTransformNone,
                            classes.fontWeight400,
                            classes.lineHeightForAvatar,
                        )}
                    >
                        {pendingTaskText}
                    </Typography>
                </div>
            </ListSubheader>
            {tasks.map((task) => (
                <Task
                    key={task.task_id}
                    task={task}
                    onComplete={() => onFinalizeTask(task, TaskState.complete)}
                    onSkip={() => onFinalizeTask(task, TaskState.skipped)}
                />
            ))}
        </div>
    );
};

export default withGStyles(styles)(TasksForCase);
