import { emptyStringParser } from "../../../../../services";
import { ConfigurableWorkHistoryFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { OverviewSectionCommonProps } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
}
const WorkHistoryOverview = (props: Props) => {
    const { deathCertificate, printMode, showHeading, divider, alwaysVisible } = props;

    const status: string | null = deathCertificate.workHistory.occupationListOfOptions || null;
    let occupation: string | null = !deathCertificate.workHistory.normalOccupation_unknown
        && emptyStringParser(deathCertificate.workHistory.normalOccupation) || null;
    if (deathCertificate.workHistory.normalOccupation_unknown) {
        occupation = 'unknown';
    }

    let businessKind: string | null = !deathCertificate.workHistory.normalOccupationIndustry_unknown
        && emptyStringParser(deathCertificate.workHistory.normalOccupationIndustry) || null;
    if (deathCertificate.workHistory.normalOccupationIndustry_unknown) {
        businessKind = 'unknown';
    }

    const companyName: string | null = emptyStringParser(deathCertificate.workHistory.currentEmployer) || null;
    const retiredYear: string | null = deathCertificate.workHistory.retiredInYear
        && deathCertificate.workHistory.retiredInYear.toString() || null;
    const lastEmployer: string | null = deathCertificate.workHistory.retiredLastEmployer
        && deathCertificate.workHistory.retiredLastEmployer.toString() || null;
    const yearsInOccuation: string | null = deathCertificate.workHistory.yearsInOccupation
        && deathCertificate.workHistory.yearsInOccupation.toString() || null;

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    WORK HISTORY
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Status"
                    html={status}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Normal Occupation"
                    html={occupation}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableWorkHistoryFieldKey.normalOccupation}
                />

                <OverviewLabel
                    label="Kind of Business"
                    html={businessKind}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableWorkHistoryFieldKey.kindOfBusiness}
                />

                <OverviewLabel
                    label="Name of Company"
                    html={companyName}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableWorkHistoryFieldKey.currentEmployer}
                />

                <OverviewLabel
                    label="Years in Occupation"
                    html={yearsInOccuation}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableWorkHistoryFieldKey.currentEmployer}
                />

                <OverviewLabel
                    label="Retirement Year"
                    html={retiredYear}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableWorkHistoryFieldKey.retiredInYear}
                />

                <OverviewLabel
                    label="Last Employer"
                    html={lastEmployer}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableWorkHistoryFieldKey.retiredLastEmployer}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default WorkHistoryOverview;