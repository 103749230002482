import * as React from 'react';

import Slide, { SlideProps } from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, withStyles, WithStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import withFullScreen from '../../common/utilHOC/WithFullScreen';
import withState from '../../common/utilHOC/WithState';
import { StoreState } from '../../../types';
import { closeFinalizeTaskDialog } from '../../../actions/Dialog.action';
import { ChecklistTaskCompleteRequest, CaseTaskSkipRequest, TaskState } from '../../../shared/types';
import { completeChecklistTask, skipTask } from '../../../actions/task/Task.action';
import { compose } from 'redux';
import { log } from '../../../logger';
import { AppDispatch } from '../../../store';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        padding: 0,
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        cursor: 'pointer',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    header: {
        zIndex: 1,
        padding: 14
    },
    formClass: {
        margin: theme.spacing()
    },
    buttonIcon: {
        textSize: '2.2vh',
        backgroundColor: ''
    },
    dialogFormGrid: {
        padding: '0px 15px 25px 15px',
    },
    dialogButtonGrid: {
        textAlign: 'center',
    },
    addTaskButton: {
        margin: theme.spacing(),
        color: theme.palette.background.paper,
        background: '#5B6E74',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    familySwitch: {
        fontSize: 14,
    },
    assignText: {
        fontSize: 14,
    },
    button: {
        margin: theme.spacing()
    },
    familySwitchLabel: {
        cursor: 'pointer',
    },
});

const Transition = React.forwardRef(
    function Transition(props: SlideProps, ref: React.Ref<unknown>) {
        return <Slide {...props} direction="up" mountOnEnter ref={ref} />;
    }
);

// don't use selectedCase or publicCase, access case properties from "finalizeTask.task" itself only 
function mapStateToProps({ tasksState, dialogState }: StoreState) {
    const { finalizeTask } = dialogState;
    return {
        isDialogOpen: finalizeTask.isOpen,
        isTaskSaving: tasksState.isSaving,
        modalType: finalizeTask.state,
        zIndex: finalizeTask.zIndex,
        task: finalizeTask.task,
        caseUuid: finalizeTask.task?.case_uuid,
        onFinalized: finalizeTask.onFinalized,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface DialogProps {
    fullScreen: boolean;
}

interface State {
    message: string;
    taskError: string;
}

type StyledProps = Props &
    WithStyles<'root' | 'clearIcon' | 'header' | 'completeSkipTaskDialog' | 'dialogPaper' | 'title'
        | 'formClass' | 'buttonIcon' | 'centerButton' | 'dialogButtonGrid' | 'addTaskButton'
        | 'dialogFormGrid' | 'buttonProgress' | 'familySwitch'
        | 'assignText' | 'button' | 'familySwitchLabel' | 'headerText'
    >;

class FinalizeTaskDialog extends React.Component<StyledProps & DialogProps, State> {
    state: State = {
        message: '',
        taskError: '',
    };

    protected topscroll: HTMLDivElement;

    registerTopscroll = (topscroll: HTMLDivElement) => {
        this.topscroll = topscroll;
    };

    componentDidUpdate(prevProps: Props) {
        const { task } = this.props;

        if (this.topscroll) {
            this.topscroll.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }

        if (task && !prevProps.task) {
            this.setState({ message: task.note || '' });
            return;
        }

        if ((task && task.note !== this.state.message) && (task !== prevProps.task)) {
            this.setState({ message: task.note || '' });
        }
    }

    handleClose = () => {
        const { task, dispatch } = this.props;

        this.setState({ taskError: '' });
        this.setState({ message: task && task.note || '' });
        dispatch(closeFinalizeTaskDialog());
    };

    handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ message: event.target.value });
    };

    resetTask() {
        this.setState({
            message: '',
            taskError: '',
        });
    }

    updateTask = () => {
        const { modalType, task, caseUuid, dispatch, onFinalized } = this.props;
        const { message } = this.state;

        if (!task) {
            log.warn('Somehow no task', { task, modalType });
            return;
        } else if (!caseUuid) {
            log.warn('Somehow no case uuid', { task, modalType });
            return;
        }

        if (modalType === TaskState.complete) {
            const completeRequest: ChecklistTaskCompleteRequest = {
                note: message,
            };

            dispatch(completeChecklistTask({
                taskId: task.id,
                caseUuid,
                taskTemplateType: task.template_type,
                completeRequest,
            }));
        } else {
            const skipRequest: CaseTaskSkipRequest = {
                signature: null,
                note: message,
            };
            dispatch(skipTask({
                taskId: task.id,
                caseUuid,
                taskTemplateType: task.template_type,
                skipRequest
            }));
        }

        if (onFinalized) {
            onFinalized();
        }
        this.handleClose();
    };

    render() {
        const {
            classes,
            isDialogOpen,
            fullScreen,
            isTaskSaving,
            modalType,
            task,
            zIndex,
        } = this.props;

        const { message } = this.state;

        if (!task) {
            return null;
        }

        const displayText = modalType === TaskState.complete ? 'Complete' : 'Skip';

        return (
            <div className={classes.root}>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                    transitionDuration={300}
                    aria-labelledby="complete-skip-task-modal"
                    aria-describedby="Modal to enter an optional completion message for this task."
                    className={classes.completeSkipTaskDialog}
                    classes={{ paper: classes.dialogPaper }}
                    maxWidth="xs"
                    style={{
                        zIndex,
                    }}
                >
                    {isDialogOpen && <>
                        <div ref={this.registerTopscroll} />
                        <DialogTitle
                            id="complete-skip-task-modal-title"
                            className={classes.header}
                        >
                            <Typography
                                color="secondary"
                                align="center"
                                component="p"
                                fontSize="20px"
                            >
                                {`${displayText} "${task.title}" Task`}
                            </Typography>
                        </DialogTitle>
                        <DialogContent classes={{ root: classes.root }}>
                            <Grid container>
                                <Grid
                                    className={classes.dialogFormGrid}
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                >
                                    <FormControl
                                        fullWidth
                                        required
                                    >
                                        <TextField
                                            margin="dense"
                                            id="task-complete-skip-message"
                                            label="Add optional message..."
                                            name="taskCompleteSkipMessage"
                                            type="text"
                                            fullWidth
                                            onChange={this.handleChangeEvent}
                                            value={message}
                                            required={false}
                                            multiline
                                            autoFocus
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    className={classes.dialogButtonGrid}
                                    container
                                    alignItems="flex-end"
                                    justifyContent="center"
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        textAlign="left"
                                    >
                                        <Button
                                            color="primary"
                                            variant="text"
                                            size="small"
                                            onClick={this.handleClose}
                                            className={classes.button}
                                            tabIndex={2}
                                        >
                                            CANCEL
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        textAlign="right"
                                    >
                                        <Button
                                            color="primary"
                                            variant="text"
                                            size="small"
                                            onClick={this.updateTask}
                                            disabled={isTaskSaving}
                                            className={classes.button}
                                        >
                                            {`${displayText.toUpperCase()} TASK`}
                                            {
                                                isTaskSaving &&
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </>}
                </Dialog>
            </div>
        );
    }
}

export default compose(
    withFullScreen('xs'),
    withState(mapStateToProps),
    withStyles(styles)
)(FinalizeTaskDialog) as React.ComponentType<{}>;
