import { RED_COLOR, GREEN_COLOR, ORANGE_COLOR, WARNING_COLOR } from '../constants/colorVariables';
import { convertHexToRGBA } from '../services';
import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import { grey, green } from '@mui/material/colors';

export const APP_STYLES =
    <Props extends object>(): StyleRulesCallback<Theme, Props, AppStylesClassKey> => theme => ({
        /**
         * COLOR
         */
        colorSecondary: {
            color: `${theme.palette.secondary.main} !important`
        },
        colorPrimary: {
            color: theme.palette.primary.main
        },
        colorTransparent: {
            color: 'transparent !important',
        },
        colorWhite: {
            color: theme.palette.common.white,
        },
        colorBlack: {
            color: theme.palette.common.black,
        },
        colorWarning: {
            color: WARNING_COLOR,
        },
        colorBlack2: {
            color: convertHexToRGBA(theme.palette.common.black, 0.87),
        },

        /**
         * FILL
         */

        fillPrimary: {
            fill: `${theme.palette.primary.main} !important`
        },
        fillSecondary: {
            fill: `${theme.palette.secondary.main} !important`
        },
        fillWhite: {
            fill: `${theme.palette.common.white} !important`
        },

        /**
         * BACKGROUND
         */
        backgroundSecondary: {
            background: theme.palette.secondary.main
        },
        backgroundPrimary: {
            background: theme.palette.primary.main
        },
        backgroundWhite: {
            background: theme.palette.common.white,
        },
        backgroundTransparent: {
            background: 'transparent',
        },
        /**
         * RGBA BACKGROUND
         */
        background_primary_0_2: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        },
        background_primary_0_0_4: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.04),
        },
        background_primary_0_0_8: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.08),
        },
        background_primary_0_0_12: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.12),
        },
        background_primary_0_0_10: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.10),
        },
        background_primary_0_1_8: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.18),
        },

        background_secondary_0_1: {
            background: convertHexToRGBA(theme.palette.secondary.main, 0.10),
        },

        /**
         * Custom buttons
         */
        buttonInvertedPrimary: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
            '&$buttonInvertedPrimaryDisabled': {
                backgroundColor: 'rgba(255,255,255,0.75)',
                color: theme.palette.primary.main,
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 255,255, 0.9)'
            }
        },
        buttonInvertedPrimaryDisabled: {},

        /**
         * Listing
         */
        list: {
            flexWrap: 'nowrap',
            transform: 'translateZ(0)',
            '-ms-transform': 'translateZ(0)',
            '-webkit-transform': 'translateZ(0)',
            '-webkit-overflow-scrolling': 'touch',
            margin: 'auto',
            display: 'flex',
            padding: '0 !important',
            overflowY: 'auto',
            listStyle: 'none',
            scrollBehavior: 'smooth',
            '& li': {
                margin: 'auto',
                boxSizing: 'border-box',
                flexShrink: 0,
                display: 'flex',
                padding: 0,
                flexWrap: 'wrap',
                overflowY: 'auto',
                listStyle: 'none',
                '-webkit-overflow-scrolling': 'touch',
            }
        },

        textGreen500: {
            color: green[500],
        },
        colorOrange: {
            color: ORANGE_COLOR
        },
        backgroundGrey200: {
            background: grey[200],
        },
        backgroundGrey400: {
            background: grey[400],
        },
        backgroundGrey700: {
            background: grey[700],
        },
        backgroundGreen500: {
            background: green[500],
        },

        backgroundRed: {
            background: RED_COLOR
        },
        colorRed: {
            color: RED_COLOR
        },
        colorGreen: {
            color: GREEN_COLOR
        },
        backgroundGreen: {
            background: GREEN_COLOR
        },
        backgroundOrange: {
            background: ORANGE_COLOR
        },

        textFeildWithIcon: {
            width: 'calc(100% - 32px)',
        },
        avtarIcon: {
            height: 40,
            width: 40,
        },
        inputWithError: {
            minHeight: 68,
        },
        transparentButtonDisabled: {
            color: 'rgba(0, 0, 0, 0.26)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.85)'
            }
        },
        disabledAnchorTag: {
            color: 'rgba(0, 0, 0, 0.16) !important',
        },
        themeSelectMenu: {
            '&:focus': {
                background: 'transparent',
            },
        },
    });

export type AppStylesClassKey =
    'backgroundGrey200'

    | 'transparentButtonDisabled'

    | 'textFeildWithIcon'
    | 'backgroundGrey700'

    | 'textGreen500'
    | 'backgroundGreen500'
    | 'avtarIcon'
    | 'inputWithError'

    | 'backgroundGrey400'
    | 'backgroundTransparent'

    | 'backgroundWhite'
    | 'disabledAnchorTag'
    | 'colorRed'

    | 'colorGreen'
    | 'backgroundGreen'

    | 'background_primary_0_2'
    | 'background_primary_0_0_4'
    | 'background_primary_0_0_8'
    | 'background_primary_0_0_10'
    | 'background_primary_0_0_12'
    | 'background_primary_0_1_8'

    | 'background_secondary_0_1'

    | 'colorSecondary'
    | 'colorPrimary'
    | 'colorTransparent'
    | 'colorWhite'
    | 'colorBlack'
    | 'colorWarning'
    | 'colorBlack2'

    | 'fillPrimary'
    | 'fillSecondary'
    | 'fillWhite'

    | 'backgroundPrimary'
    | 'backgroundRed'
    | 'backgroundOrange'
    | 'backgroundSecondary'

    | 'buttonInvertedPrimary'
    | 'buttonInvertedPrimaryDisabled'

    | 'colorOrange'

    | 'list'
    | 'themeSelectMenu';
export type APP_STYLED_PROPS = WithStyles<AppStylesClassKey>;
