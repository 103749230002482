import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import MoreVert from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TableBodyType, TableColumnType } from '../../../types';
import { AvatarUser } from '../../../shared/types/user';
import {
    HasIdProperty,
} from '../../../shared/types';

import { getPhotoUrl, RouteBuilder } from '../../../services';
import { CloudinaryTransformationsType } from '../../../shared/types';

import UserAvatar from '../../../components/common/UserAvatar';

import makeGStyles from '../../../styles/makeGStyles';
import ReportsEmptyState from '../../cases/ReportsEmptyState';
import GLink from '../GLink';

const useStyles = makeGStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableCell: {
        padding: '8px !important',
        whiteSpace: 'pre-wrap',
        fontSize: 14,
    },
    menuTableCell: {
        padding: '8px !important',
        width: 50,
    },
    aLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    lastRowCell: {
        borderBottom: 'none',
    },
    loadingCell: {
        paddingTop: 20,
    },
    loadMoreContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    userAvatar: {
        marginRight: 8,
    },
    tableCellInner: {
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    userAvatarInitials: {
        textDecoration: 'none !important',
        textTransform: 'uppercase',
        fontSize: 16,
        marginRight: 8,
    },
    linkCell: {
        textDecoration: 'none !important',
        '& span': {
            textDecoration: 'underline !important',
        },
    },
}), { name: 'InfiniteTableBody' });

export interface HasIdPropertyInfinite extends HasIdProperty {
    isDisabled?: boolean;
    isClickable?: boolean;
    disabledClass?: string;
}

interface Props<T extends HasIdPropertyInfinite> {
    tableData: TableBodyType<T>;
    hasMoreData: boolean;
    hideActionsMenu?: boolean;
    isDataLoading: boolean;
    handleMenuClick?: (event: React.MouseEvent<HTMLElement>, elementObject: T) => void;
    handleDataRowClick?: (event: React.MouseEvent<HTMLElement>, elementObject: T) => void;
    callBackAction?: (
        elementObject: T,
        apiString: string,
        event: React.MouseEvent<HTMLElement>
    ) => void;
    openCaseNumberDialog?: (caseId: number | null) => void;
    handleLoadMore: () => void;
    secondaryMenuItem?: (elementObject: T) => JSX.Element | null;
    emptyStateProps?: {
        icon: JSX.Element;
        heading: string;
        bottomText: string;
    };
    renderImagePlaceholder?: (elementObject: T) => void;
}

const InfiniteTableBody = <T extends HasIdPropertyInfinite>(props: Props<T>) => {
    const {
        tableData,
        isDataLoading,
        hideActionsMenu,
        hasMoreData,
        callBackAction,
        handleDataRowClick,
        handleMenuClick,
        handleLoadMore,
        secondaryMenuItem,
        openCaseNumberDialog,
        emptyStateProps,
    } = props;

    const classes = useStyles();

    const getTotalColumns = () => tableData.propertyList.length + (hideActionsMenu ? 0 : 1);

    const getKey = React.useCallback((propertyId: keyof T, elementId: number, index: number) =>
        `${JSON.stringify(propertyId)}-${elementId}-${index}`,
        []);

    const renderTableCell = (ele: T, property: TableColumnType<T>, index: number) => {
        const userAvatar = property.userAvatarProperty && ele[property.userAvatarProperty];

        const tableRowClass = property.tableRowClass && ele[property.tableRowClass];

        return (
            <TableCell
                component="th"
                scope="row"
                key={getKey(property.id, ele.id, index)}
                align={property.isString ? 'left' : 'right'}
                className={classes.tableCell}
            >
                <Tooltip
                    title={property.rowTooltipProperty && ele[property.rowTooltipProperty] || ''}
                    placement="top"
                    enterDelay={600}
                >
                    <Grid
                        container
                        className={classNames(userAvatar && classes.tableCellInner)}
                    >
                        {userAvatar && renderUserAvatar(ele, property, userAvatar)}
                        <Grid
                            item
                            xs={!userAvatar && 12 || undefined}
                            className={classNames(!userAvatar && classes.marginAuto, tableRowClass)}
                        >
                            {property.renderValue?.(ele) ?? ele[property.id]}
                        </Grid>
                    </Grid>
                </Tooltip>
            </TableCell>
        );
    };

    const renderUserAvatar = (ele: T, property: TableColumnType<T>, userAvatar: AvatarUser) => (
        <UserAvatar
            user={userAvatar}
            className={classNames(
                classes.userAvatar,
                property.userAvatarCallBack && classes.cursorPointer
            )}
            initialsClassName={classNames(
                classes.userAvatarInitials,
                property.userAvatarCallBack && classes.cursorPointer
            )}
            showOverlapDot={
                property.showUserAvatarOverlap
                && ele[property.showUserAvatarOverlap]
            }
            overlapDotClass={property.userAvatarOverlapClass}
            onClick={e => property.userAvatarCallBack && callBackAction?.(ele, '', e)}
        />
    );

    const renderActionsMenuTableCell = (ele: T) => {
        if (!handleMenuClick && !secondaryMenuItem) {
            return null;
        }
        return (
            <TableCell
                component="th"
                scope="row"
                key={`menu_${ele.id}`}
                className={classNames(
                    classes.menuTableCell,
                    secondaryMenuItem && !secondaryMenuItem(ele) && classes.textRight
                )}
            >
                <Grid
                    item
                    xs={12}
                    className={classes.tableCellInner}
                >
                    {secondaryMenuItem && secondaryMenuItem(ele)}
                    {handleMenuClick && <IconButton
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleMenuClick(event, ele);
                        }}
                        size="large">
                        <MoreVert color="primary" />
                    </IconButton>}
                </Grid>
            </TableCell>
        );
    };

    const renderCustomTableCell = (customRenderer: (ele: T) => JSX.Element, ele: T) => {

        return (
            <TableCell
                component="th"
                scope="row"
                key={`customCell_${ele.id}`}
                className={classes.menuTableCell}
            >
                <Grid item>
                    {customRenderer(ele)}
                </Grid>
            </TableCell>
        );
    };

    const renderTableCellWithLink = (ele: T, property: TableColumnType<T>, index: number) => {
        const transformations: CloudinaryTransformationsType[] = [{
            crop: 'limit',
            height: 50,
            width: 100,
            quality: 'auto',
            fetch_format: 'auto',
        }];

        const userAvatar = property.userAvatarProperty && ele[property.userAvatarProperty];

        const tableRowClass = property.tableRowClass && ele[property.tableRowClass];

        let imgUrl: string | null = null;
        const photo = ele[property.id];
        if (property.isImage && typeof photo === 'string') {
            imgUrl = getPhotoUrl(photo, transformations);
        }

        const linkClass = classNames(
            property.color && property.color === 'primary' && classes.colorPrimary,
            userAvatar && classes.tableCellInner,
            classes.linkCell,
        );
        const onClickFn: React.MouseEventHandler<HTMLElement> | undefined = property.enableCallBackAction
            && property.api
            && callBackAction
            ? event => property.api && callBackAction(ele, property.api, event)
            : undefined;

        return (
            <TableCell
                key={getKey(property.id, ele.id, index)}
                component="th"
                scope="row"
                className={classNames(
                    property.isString ? classes.textLeft : classes.textCenter,
                    classes.tableCell,
                )}
            >
                <GLink
                    to={property.generateLink?.(ele) ?? RouteBuilder.Disabled}
                    linkClass={linkClass}
                    disabledContainerClass={classNames(linkClass, onClickFn && classes.cursorPointer)}
                    onClick={onClickFn}
                    disabledOnClick={property.generateLink?.(ele) === RouteBuilder.Disabled
                        ? onClickFn
                        : undefined}
                >
                    {userAvatar && renderUserAvatar(ele, property, userAvatar)}
                    {!imgUrl && <span>{ele[property.id]}</span>}

                    {imgUrl && <img height={100} width={100} src={imgUrl} className={tableRowClass} />}

                    {!imgUrl && property.isImage && props.renderImagePlaceholder?.(ele)}
                </GLink>
            </TableCell>
        );
    };

    const renderTableCellWithImage = (ele: T, property: TableColumnType<T>, index: number) => {
        const transformations: CloudinaryTransformationsType[] = [{
            crop: 'limit',
            height: 50,
            width: 100,
            quality: 'auto',
            fetch_format: 'auto',
        }];
        const photo = ele[property.id];
        const url = typeof photo === 'string' ? getPhotoUrl(photo, transformations) : null;
        const tableRowClass = property.tableRowClass && ele[property.tableRowClass];

        return (
            <TableCell
                key={getKey(property.id, ele.id, index)}
                component="th"
                scope="row"
                align="center"
                className={classes.tableCell}
            >
                <Grid container>
                    <Grid item xs={12} className={classes.marginAuto}>
                        {url
                            ? <img height={100} width={100} src={url} className={tableRowClass} />
                            : props.renderImagePlaceholder?.(ele) || null
                        }
                    </Grid>
                </Grid>
            </TableCell>
        );
    };

    const renderEmptyTableCell = (ele: T, property: TableColumnType<T>, index: number) => {

        if (property.isImage && props.renderImagePlaceholder) {
            return property.generateLink
                ? renderTableCellWithLink(ele, property, index)
                : renderTableCellWithImage(ele, property, index);
        }

        return (
            <TableCell
                component="th"
                scope="row"
                key={getKey(property.id, ele.id, index)}
                className={classNames(
                    property.isString ? classes.textLeft : classes.textCenter,
                    classes.tableCell
                )}
            >
                {'-'}
            </TableCell>
        );
    };

    const renderTableCellWithButton = (ele: T, property: TableColumnType<T>, index: number) => {
        return (
            <TableCell
                component="th"
                scope="row"
                key={getKey(property.id, ele.id, index)}
                className={classNames(classes.textLeft, classes.tableCell)}
            >
                <a
                    className={classNames(classes.colorPrimary, classes.aLink)}
                    onClick={openCaseNumberDialog ? () => openCaseNumberDialog(ele.id) : undefined}
                >
                    {ele[property.id] ? ele[property.id] : '+ Case Number'}
                </a>
            </TableCell>
        );
    };

    const renderCells = (ele: T) => {
        const cells = tableData.propertyList.map((property, propertyIndex) => {
            if (property.isButton) {
                return renderTableCellWithButton(ele, property, propertyIndex);
            } else {
                if (ele.hasOwnProperty(property.id) && ele[property.id] !== null) {
                    if (property.customCellRenderer) {
                        return renderCustomTableCell(property.customCellRenderer, ele);
                    } else if (property.generateLink) {
                        return renderTableCellWithLink(ele, property, propertyIndex);
                    } else if (property.isImage) {
                        return renderTableCellWithImage(ele, property, propertyIndex);
                    } else {
                        return renderTableCell(ele, property, propertyIndex);
                    }
                } else {
                    return renderEmptyTableCell(ele, property, propertyIndex);
                }
            }
        });
        return (
            <>
                {cells}
                {!hideActionsMenu && renderActionsMenuTableCell(ele)}
            </>
        );
    };

    const renderLoadingRow = () => {
        return (
            <TableRow>
                <TableCell
                    className={classNames(classes.lastRowCell, classes.loadingCell)}
                    colSpan={getTotalColumns()}
                    align="center"
                >
                    <CircularProgress color="primary" />
                    <Typography color="secondary">
                        Loading
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    const renderLoadMoreRow = () => {
        let noMoreData: JSX.Element;
        if (tableData.data.length) {
            noMoreData = <Typography color="secondary">
                All results displayed ({tableData.data.length})
            </Typography>;
        } else {
            noMoreData = emptyStateProps
                ? renderEmptyState()
                : <Typography color="secondary">
                    No results
                </Typography>;
        }

        const loadMoreButton = (
            <Grid item className={classes.loadMoreContainer}>
                <ExpandMoreIcon color="primary" />
                <Typography color="secondary">
                    Load More
                </Typography>
            </Grid>
        );

        return (
            <TableRow
                onClick={!hasMoreData ? undefined : handleLoadMore}
                hover={hasMoreData}
                className={hasMoreData ? classes.cursorPointer : undefined}
            >
                <TableCell
                    className={classes.lastRowCell}
                    colSpan={getTotalColumns()}
                    align="center"
                >
                    {hasMoreData ? loadMoreButton : noMoreData}
                </TableCell>
            </TableRow>
        );
    };

    const renderEmptyState = () => {
        if (!emptyStateProps) {
            return <></>;
        }

        return (
            <ReportsEmptyState
                icon={emptyStateProps.icon}
                heading={emptyStateProps.heading}
                bottomText={emptyStateProps.bottomText}
            />
        );
    };

    return (
        <TableBody>
            {tableData.data.map((ele: T) => (
                <TableRow
                    hover
                    key={ele.id}
                    className={classNames(
                        classes.textCenter,
                        handleDataRowClick && ele.isClickable !== false && classes.cursorPointer,
                        ele.isDisabled
                            ? ele.disabledClass
                                ? ele.disabledClass
                                : classes.opacity50
                            : undefined,
                    )}
                    onClick={handleDataRowClick && ele.isClickable !== false
                        ? (e) => handleDataRowClick(e, ele)
                        : undefined}
                >
                    {renderCells(ele)}
                </TableRow>
            ))}
            {isDataLoading ? renderLoadingRow() : renderLoadMoreRow()}
        </TableBody>
    );
};

export default InfiniteTableBody;
