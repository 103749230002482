import * as React from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { GLOBAL_STYLED_PROPS } from '../../../styles';
import { UserSession } from '../../../types';
import { GatherCaseUX, UserRoles } from '../../../shared/types';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../styles/WithGStyles';
import withFullScreen from '../../common/utilHOC/WithFullScreen';

const Transition = React.forwardRef(
    function SlideTransition(props: SlideProps, ref: React.Ref<unknown>) {
        return <Slide {...props} direction="up" mountOnEnter unmountOnExit />;
    }
);

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    button: {
        margin: theme.spacing(),
    },
    completionStatus: {
        textAlign: 'center',
        margin: 12,
    },
    bottomMargin10: {
        marginBottom: '10px'
    },
    headerIconButton: {
        height: 30,
        width: 30,
        verticalAlign: 'unset'
    },
    lockUnlockIcon: {
        '& svg': {
            fontSize: 20,
        }
    },
    unlockIcon: {
        fontSize: 40,
        '& svg': {
            fontSize: 40
        }
    },
    lockLocked: {
        fontSize: 40,
        '& svg': {
            fontSize: 40
        }
    }
});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'button' | 'completionStatus' | 'bottomMargin10' | 'lockUnlockIcon'
        | 'headerIconButton' | 'unlockIcon' | 'lockLocked'>;

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    userSession: UserSession;
    activeCase: GatherCaseUX;
    renderOnlyIcon?: boolean;
    lockUnlockAlbum: (lockAndSave: boolean) => void;
    closeLockFormDialog?: () => void;
    locked: boolean | null;
    zIndex: number;
}

interface State {
    isOpenLockConfirmation: boolean;
}

type CombinedProps = DialogProps & StyledProps;

class LockContent extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            isOpenLockConfirmation: false
        };
    }

    lockAlbumConfirm = () => {
        const { lockUnlockAlbum, closeLockFormDialog } = this.props;
        lockUnlockAlbum(true);
        this.setState({
            isOpenLockConfirmation: false,
        });
        if (closeLockFormDialog !== undefined) {
            closeLockFormDialog();
        }
    };

    unlockAlbumConfirm = () => {
        const { lockUnlockAlbum, closeLockFormDialog } = this.props;
        lockUnlockAlbum(false);
        if (closeLockFormDialog !== undefined) {
            closeLockFormDialog();
        }
    };

    closeLockConfirmation = () => {
        this.setState({
            isOpenLockConfirmation: false,
        });
    };

    openLockConfirmation = () => {
        this.setState({
            isOpenLockConfirmation: true,
        });
    };

    renderUnlockView = () => {
        const { classes, activeCase, userSession, renderOnlyIcon } = this.props;
        const isFamilyUser = UserRoles.isFamilyOnCase(userSession.userData, activeCase.id);

        if (renderOnlyIcon) {
            return this.renderIcon();
        }

        return (
            <Grid
                item
                xs={12}
                className={classes.completionStatus}
            >
                <Icon
                    color="primary"
                    className={classes.unlockIcon}
                >
                    <LockOpenIcon />
                </Icon>

                <Typography
                    color="primary"
                    align="center"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight400,
                        classes.bottomMargin10,
                    )}
                >
                    This album is unlocked
                </Typography>
                {isFamilyUser ?
                    <Typography
                        color="primary"
                        align="center"
                        className={classNames(
                            classes.fontSize12,
                            classes.fontWeight400,
                            classes.bottomMargin10,
                        )}
                    >
                        Once complete, {activeCase.assignee.fname} will lock this album to ensure it remains secure.
                    </Typography>
                    :
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                        onClick={this.openLockConfirmation}
                    >
                        Lock album
                    </Button>}
            </Grid>
        );
    };

    renderLockView = () => {
        const { classes, renderOnlyIcon, activeCase, userSession } = this.props;
        const isFamilyUser = UserRoles.isFamilyOnCase(userSession.userData, activeCase.id);

        if (renderOnlyIcon) {
            return this.renderIcon();
        }

        return (
            <Grid
                item
                xs={12}
                className={classes.completionStatus}
            >
                <Icon
                    color="primary"
                    className={classes.lockLocked}
                >
                    <LockIcon />
                </Icon>

                <Typography
                    color="primary"
                    align="center"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight400,
                        classes.bottomMargin10
                    )}
                >
                    This album is locked
                </Typography>

                {isFamilyUser ?
                    <Typography
                        color="primary"
                        align="center"
                        className={classNames(
                            classes.fontSize12,
                            classes.fontWeight400,
                            classes.bottomMargin10,
                        )}
                    >
                        We want to make sure your photos stay in the order you decided.
                        Please reach out to {activeCase.assignee.fname} if you would like to make any changes.
                    </Typography>
                    :
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                        onClick={this.unlockAlbumConfirm}
                    >
                        Unlock Album
                    </Button>}
            </Grid>
        );
    };

    renderIcon = () => {
        const { classes, userSession, locked } = this.props;

        const isFHorGOMUser = UserRoles.isFHorGOMUser(userSession.userData);

        if (!isFHorGOMUser) {
            return (
                <Icon
                    color="primary"
                    className={classes.lockUnlockIcon}
                    onClick={locked ? this.unlockAlbumConfirm : this.openLockConfirmation}
                >
                    {locked ? <LockIcon /> : <LockOpenIcon />}
                </Icon>
            );
        }

        return (
            <Tooltip
                id="lock_unlock_album"
                title={locked ? 'Unlock Album' : 'Lock Album'}
                placement="top"
            >
                <IconButton
                    className={classNames(
                        classes.headerIconButton
                    )}
                    onClick={locked ?
                        this.unlockAlbumConfirm : this.openLockConfirmation}
                    size="large">
                    <Icon
                        color="primary"
                        className={classes.lockUnlockIcon}
                    >
                        {locked ? <LockIcon /> : <LockOpenIcon />}
                    </Icon>
                </IconButton>
            </Tooltip>
        );
    };

    renderLockConfirmation = () => {
        const { classes, zIndex } = this.props;
        const { isOpenLockConfirmation } = this.state;
        return (
            <div>
                <Dialog
                    open={isOpenLockConfirmation}
                    TransitionComponent={Transition}
                    keepMounted
                    transitionDuration={300}
                    onClose={this.closeLockConfirmation}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    style={{ zIndex }}
                >
                    <DialogTitle>
                        Are you sure?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            This action will prevent anyone else from editing this album. You, or anyone else from
                            your funeral home, can unlock this section later, as needed.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeLockConfirmation}>
                            Cancel
                        </Button>
                        <Button
                            onClick={this.lockAlbumConfirm}
                            variant="contained"
                            size="medium"
                            color="primary"
                            className={classes.button}
                        >
                            Lock Album
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    render() {
        const { classes, locked, renderOnlyIcon } = this.props;
        return (
            <div className={renderOnlyIcon ? classes.floatLeft : ''}>
                {locked ? this.renderLockView() : this.renderUnlockView()}
                {this.renderLockConfirmation()}
            </div>
        );
    }
}

export default withFullScreen()(withGStyles(styles)(LockContent));
