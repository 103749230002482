import classNames from 'classnames';
import { every } from 'lodash';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import {
    ProductUX,
    ProductPackageItemUX,
    ProductSubPackageItem,
    ProductContractItemUX,
    ProductItem,
    GPLContext,
} from '../../../../shared/types';
import ProductItemCard from '../widgets/ProductItemCard';
import {
    findContractItemsThatArePackageItemOptions,
} from '../../../../shared/goods_and_services/packages';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

interface Props {
    canUserEditContract: boolean;
    packageItem: ProductPackageItemUX | ProductSubPackageItem;
    productItems: ProductItem[];
    caseFirstName: string;
    context: GPLContext;
    onProductItemClick: (productItem: ProductItem) => void;
    onAddContractItem?: (product: ProductUX, quantity: number, packageItemId: number) => void;
    onUpdateContractItemQuantity?: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    imageContainer: {
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.common.white,
        width: 380,
        height: 235,
        borderRadius: 8
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 20,
        fontSize: 20,
        fontWeight: 200
    },
    content: {
        color: theme.palette.primary.main,
        borderTop: '1px solid',
        borderBottom: '1px solid',
        marginBottom: 28
    },
});

type StyledProps = Props & WithGStyles<'root' | 'imageContainer' | 'title' | 'content'>;

// disabled if item is on the contract but not one of the selected options
const isCardDisabled = (
    productItem: ProductItem,
    selectedOptions: ProductContractItemUX[],
    maxSelections: number,
) => {
    const { contractItem } = productItem;
    return selectedOptions.length === maxSelections &&
        (!contractItem || every(selectedOptions, (selected) => selected.id !== contractItem.id));
};

const PackageOptionProducts = (props: StyledProps) => {
    const {
        classes,
        productItems,
        caseFirstName,
        packageItem,
        onProductItemClick,
        onAddContractItem,
        onUpdateContractItemQuantity,
        onRemoveContractItem,
        canUserEditContract,
        context,
    } = props;

    const title = `Choose ${packageItem.max_selections} of ${packageItem.options.length} ${packageItem.name}`;

    // find all selected contractItem options
    const contractItems = productItems.map((productItem) => productItem.contractItem)
        .filter((ci): ci is ProductContractItemUX => ci !== undefined);
    const { selectedOptions } = findContractItemsThatArePackageItemOptions(packageItem, contractItems);

    return (
        <Grid container justifyContent="center" alignItems="center"
            className={classNames(classes.background_primary_0_2, classes.content)}
        >
            <Typography
                color="primary"
                variant="subtitle2"
                className={classes.title}
            >
                <LoyaltyIcon className={classes.verticalAlignMiddle} /> {title}
            </Typography>

            <ul className={classNames(classes.list, classes.root)}>
                {productItems.map((productItem) =>
                    <ProductItemCard
                        key={productItem.key}
                        productItem={productItem}
                        caseFirstName={caseFirstName}
                        onClick={() => onProductItemClick(productItem)}
                        onAddContractItem={(product, quantity) =>
                            onAddContractItem && onAddContractItem(product, quantity, packageItem.id)}
                        onUpdateQuantity={onUpdateContractItemQuantity}
                        onRemoveContractItem={onRemoveContractItem}
                        size={'xxlargeWide'}
                        isShowPrices={false}
                        disabled={isCardDisabled(productItem, selectedOptions, packageItem.max_selections)}
                        isFeatured
                        canUserEditContract={canUserEditContract}
                        context={context}
                    />
                )}
            </ul>
        </Grid>
    );
};

export default withGStyles(styles)(PackageOptionProducts);
