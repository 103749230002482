import classNames from 'classnames';

import GGroupBaseButton, { ButtonProps as GGroupBaseButtonProps } from './GGroupBaseButton';
import makeStyles from '@mui/styles/makeStyles';
import { SxProps, Theme } from '@mui/material/styles';

export interface GButtonType<T extends string> {
    label: string | JSX.Element;
    value: T;
    className?: string;
    icon?: JSX.Element;
    badge?: JSX.Element;
    hidden?: boolean;
    tooltip?: string;
    disabled?: boolean;
}

export interface Props<T extends string> extends GGroupBaseButtonProps<T> {
    buttons: GButtonType<T>[];
    sx?: SxProps<Theme>;
    tooltipDelay?: number;
    useRememberTheme?: boolean;
    intercomTargetProp?: string;
}

interface MakeStylesProps {
    useRememberTheme?: boolean;
}
const useGGroupButtonsStyles = makeStyles((theme: Theme) => ({
    contentDisabled: {
        '& > .Mui-disabled': {
            border: '1px solid',
            borderColor: (props: MakeStylesProps) => `${props.useRememberTheme
                ? theme.palette.rememberSecondary.main
                : theme.palette.primary.main} `,
            borderRight: 'none',
        },
        '&:first-of-type > button': {
            borderRadius: '8px 0 0 8px',
        },
        '&:last-of-type > button': {
            borderRadius: '0 8px 8px 0',
            borderRight: (props: MakeStylesProps) => `1px solid ${props.useRememberTheme
                ? theme.palette.rememberSecondary.main
                : theme.palette.primary.main}`,
        }
    },
    buttonBorder: {
        border: '1px solid',
        borderColor: (props: MakeStylesProps) => props.useRememberTheme
            ? theme.palette.rememberSecondary.main
            : theme.palette.primary.main,
        borderRight: 'none',
        '&:hover': {
            border: '1px solid',
            borderColor: (props: MakeStylesProps) => props.useRememberTheme
                ? theme.palette.rememberSecondary.main
                : theme.palette.primary.main,
            borderRight: 'none',
        },
        '&:first-of-type:not(:last-of-type)': {
            borderRadius: '8px 0 0 8px',
        },
        '&:last-of-type:not(:first-of-type)': {
            borderRadius: '0 8px 8px 0',
            borderRight: (props: MakeStylesProps) => `1px solid ${props.useRememberTheme
                ? theme.palette.rememberSecondary.main
                : theme.palette.primary.main}`,
        }
    },
}), { name: 'GGroupButtons' });

function GGroupButtons<T extends string>(props: Props<T>) {
    const {
        activeButton,
        buttons,
        onClick,
        size,
        disabled,
        controlClasses,
        activeClass,
        labelClass,
        sx,
        tooltipDelay,
        useRememberTheme,
        intercomTargetProp,
    } = props;

    const classes = useGGroupButtonsStyles({ useRememberTheme });

    return (
        <>
            {buttons.map((b) =>
                <GGroupBaseButton
                    activeButton={activeButton}
                    key={b.value}
                    value={b.value}
                    onClick={onClick}
                    className={classNames(b.className, classes.buttonBorder)}
                    icon={b.icon}
                    label={b.label}
                    badge={b.badge}
                    disabled={b.disabled || disabled}
                    size={size}
                    controlClasses={controlClasses}
                    activeClass={activeClass}
                    labelClass={labelClass}
                    hidden={b.hidden}
                    sx={sx}
                    tooltipDelay={tooltipDelay}
                    tooltip={b.tooltip}
                    contentDisabledClass={classes.contentDisabled}
                    useRememberTheme={useRememberTheme}
                    intercomTargetProp={intercomTargetProp ? `${intercomTargetProp}-${b.value}` : ``}
                />

            )}
        </>
    );
}

export default GGroupButtons;
