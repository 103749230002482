import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import classNames from "classnames";
import makeGStyles from "../../../styles/makeGStyles";
import { StickerType } from "./PrintQRStickers.dialog";

const useStyles = makeGStyles(theme => ({
    paddingLeft32: {
        paddingLeft: 32
    },
    divider1: {
        margin: '24px 0 4px'
    },
    divider2: {
        margin: '12px 0'
    },
    marginBottom12: {
        marginBottom: 12
    }
}), { name: 'Step1' });

interface Props {
    stickerType: StickerType;
}

const Step1 = (props: Props) => {
    const { stickerType } = props;

    const classes = useStyles();

    const is5160or2160Selected = stickerType === StickerType.QR_5160 || stickerType === StickerType.QR_2160;

    let stickerNumber = undefined;
    let stickerUrl = '';
    if (stickerType === StickerType.CREMAINS) {
        stickerNumber = '5163';
        stickerUrl = 'https://amzn.to/3GwbnLH';
    } else if (stickerType === StickerType.QR_5160) {
        stickerNumber = '5160';
        stickerUrl = 'https://amzn.to/3xXH1wY';
    } else if (stickerType === StickerType.QR_2160) {
        stickerNumber = '2160';
        stickerUrl = 'https://www.amazon.com/Avery-Mini-Sheets-Address-Labels-Pack/dp/B000093L2X/';
    }

    return (
        <>
            <Divider className={classes.divider1} />

            <Typography className={classNames(classes.fontSize20, classes.colorOrange)}>
                IMPORTANT
            </Typography>

            <Typography
                className={classNames(
                    classes.paragraphLineHeight,
                    classes.fontSize16,
                    classes.colorOrange
                )}
            >
                Always ensure that you place the sticker with the correct decedent by matching
                the 7-digit code on the {is5160or2160Selected && 'left side of the'} sticker with the
                QR code on the plastic wristband.
            </Typography>

            <Divider className={classes.divider2} />

            <Typography color="primary" className={classes.fontSize20}>
                What can I use {is5160or2160Selected ? 'QR' : 'Cremains'} Stickers for?
            </Typography>

            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize16,
                    classes.paragraphLineHeight,
                    classes.marginTop2,
                    is5160or2160Selected && classes.marginBottom16
                )}
            >
                {is5160or2160Selected && `QR Stickers will link directly to the Tracking page in Gather so you can
                quickly identify and access a case. Here are a few ideas:`}

                {!is5160or2160Selected
                    && 'This can be used as a label to place with the cremains for quick and accurate identification.'
                }
            </Typography>

            {is5160or2160Selected &&
                <ul
                    className={classNames(
                        classes.textLeft,
                        classes.margin_0,
                        classes.colorSecondary,
                        classes.paddingLeft32
                    )}
                >
                    <Typography
                        component="li"
                        color="secondary"
                        className={classNames(classes.paragraphLineHeight, classes.fontSize16)}
                    >
                        Place a sticker on recorded belongings
                    </Typography>
                    <Typography
                        component="li"
                        color="secondary"
                        className={classNames(classes.paragraphLineHeight, classes.fontSize16)}
                    >
                        Place a sticker on the urn
                    </Typography>
                    <Typography
                        component="li"
                        color="secondary"
                        className={classNames(classes.paragraphLineHeight, classes.fontSize16)}
                    >
                        Place a sticker on your case folder
                    </Typography>

                    {is5160or2160Selected &&
                        <Typography
                            component="li"
                            color="secondary"
                            className={classNames(classes.paragraphLineHeight, classes.fontSize16)}
                        >
                            Place sticker on wristband for identifying the decedent
                        </Typography>
                    }
                </ul>
            }

            <Divider className={classNames(classes.marginBottom12, classes.marginTop16)} />

            <Typography color="primary" className={classes.fontSize20}>
                How do I print{is5160or2160Selected && ` QR Stickers`}?
            </Typography>

            <Typography
                color="secondary"
                className={classNames(classes.paragraphLineHeight, classes.fontSize16, classes.marginTop2)}
            >
                The next screen will automatically generate a PDF containing your stickers
                which can then be printed on AVERY {stickerNumber} Labels.
            </Typography>

            <a
                target="_blank"
                rel="noreferrer"
                href={stickerUrl}
                className={classNames(classes.widthFitContent, classes.marginAuto, classes.displayBlock)}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.textClickable,
                        classes.fontSize16,
                        classes.fontStyleItalic,
                        classes.marginAuto,
                        classes.widthFitContent,
                        classes.paragraphLineHeight
                    )}
                >
                    (Click to purchase blank labels on Amazon)
                </Typography>
            </a>

            <br />

            <Typography
                color="secondary"
                className={classNames(classes.paragraphLineHeight, classes.fontSize16, classes.marginBottom16)}
            >
                As you will see on the next screen, we made it easy to print any number of
                labels from partial sheets so that nothing is wasted.
            </Typography>

            <Typography
                color="secondary"
                className={classNames(classes.paragraphLineHeight, classes.fontSize16, classes.marginBottom16)}
            >
                NOTE: If nothing happens when you click the "Generate PDF" button,
                you may need to disable the popup blocker in your browser as
                explained <a
                    className={classNames(classes.colorSecondary, classes.textClickable)}
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop&oco=1"
                >here</a>.
            </Typography>

            <Divider />
        </>
    );
};

export default Step1;