import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import { GREEN_COLOR, RED_COLOR } from '../../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';

interface Props {
    title: string;
    complete: boolean | null;
    rootClass?: string;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        height: 48,
        padding: '0px 12px',
        marginTop: 72,
        '@media (min-width: 600px)': {
            padding: '0 48px'
        }
    },
    container: {
        maxWidth: 820,
        height: 40,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '16px 16px 0px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
    },
    title: {
        fontSize: 17,
        color: 'white',
        textTransform: 'uppercase',
    },
    complete: {
        backgroundColor: GREEN_COLOR,
    },
    incomplete: {
        backgroundColor: RED_COLOR,
    },
    badge: {
        padding: '0 10px',
        color: 'white',
        fontSize: 18,
    },
    badgeIcon: {
        marginTop: 8,
        marginLeft: 4,
    },
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<'root' | 'container' | 'title'
    | 'complete' | 'incomplete' | 'touched' | 'badge' | 'badgeIcon'>;

function FormTitle(props: StyledProps) {
    const { classes, title, complete, rootClass } = props;

    const icon = complete
        ? <DoneIcon className={classes.badgeIcon} />
        : complete === false
            ? <CloseIcon className={classes.badgeIcon} />
            : null;

    return (
        <Grid className={classNames(classes.root, rootClass)}>
            <Grid
                className={classNames(
                    classes.container,
                    complete && classes.complete,
                    complete === false && classes.incomplete,
                )}
            >

                <Badge
                    badgeContent={icon}
                    className={classes.badge}
                >
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                </Badge>
            </Grid>
        </Grid>
    );
}

export default withGStyles(styles)(FormTitle);
