import * as React from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material';
import { SxProps, Theme } from '@mui/material';
import classNames from 'classnames';
import { GStyles } from '../../styles/GStyles';

const Span = styled('span')({});

const Anchor = styled('a')({});

interface Props extends React.HTMLProps<HTMLAnchorElement> {
    linkClass?: string;
    disabled?: boolean;
    tooltipProps?: Omit<TooltipProps, 'children'>;
    sx?: SxProps<Theme>;
}

const GAnchorWithTooltip = (props: Props) => {
    const { disabled, children, linkClass, tooltipProps, sx, ...others } = props;

    if (disabled) {
        return (
            <Span sx={sx} className={linkClass}>
                {children}
            </Span>
        );
    }

    const content = (
        <Anchor {...others} as="a" sx={sx} className={classNames(GStyles.textDecorationNone, linkClass)}>
            {children}
        </Anchor>
    );

    if (tooltipProps && tooltipProps.title) {
        return (
            <Tooltip placement="top" {...tooltipProps}>
                {content}
            </Tooltip>
        );
    }

    return content;
};

export default GAnchorWithTooltip;
