import * as t from 'io-ts';
import { getValidator } from './utils';

export interface ObituaryLinkRecord {
    id: number;
    gather_case_id: number;
    title: string;
    url: string;
    description: string | null;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
}

export interface ObituaryLinkUX {
    id: number;
    gatherCaseId: number;
    title: string;
    url: string;
    description?: string;
}

const ObituaryLinkRequired = t.type({
    title: t.string,
    url: t.string,
});

const ObituaryLinkOptional = t.partial({
    description: t.string,
});

export const ObituaryLinkRequestType = t.intersection([ObituaryLinkRequired, ObituaryLinkOptional]);
export interface ObituaryLinkRequest extends t.TypeOf<typeof ObituaryLinkRequestType> {
}

export class ObituaryLinkRequest {
    public static fromRequest = getValidator<ObituaryLinkRequest>(ObituaryLinkRequestType);
}
