import * as t from 'io-ts';
import { EntityRecord, getValidator, Nullable, UserRole } from '.';

export type DocStatus = 'uploading' | 'uploaded';

export enum DocCategory {
    general = 'general',
    form_dd214 = 'form_dd214',
}

export const DocCategoryDefinition = t.union([
    t.literal(DocCategory.general),
    t.literal(DocCategory.form_dd214),
]);

export interface DocUXDetail {
    uid: string;
    doc: DocUX | null;
    label: string;
    status: DocStatus | null;
    taskComponentId?: number;
}

export interface PdfInvalidFieldType {
    fieldname: string;
    message: string | null;
}
// Documents
export interface S3FileRecord {
    id: number;
    uploaded_by: number;
    uploaded_role: UserRole;
    uploaded_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    purged_time: Date | null;
    path: string;
    name: string;
    suffix: string;
    size: number | null;
    hash: string;
    mimetype: string;
}

export interface S3FileUX extends S3FileRecord { }

export interface DocRecord {
    id: number;
    parent_doc_id: number | null;
    s3_file_id: number;
    label: string | null;
    is_private: boolean;
    icon: string | null;
    default_icon: string | null;
    pdf_fields: string[] | null;
    pdf_status: DocPDFStatus;
    is_esign_enabled: boolean;
    pdf_invalid_fields: PdfInvalidFieldType[];
    pdf_markup_fields: PdfMarkupData[];
    doc_category: DocCategory;
}

export interface DocUXRecord extends Pick<DocRecord,
    | 'id'
    | 'parent_doc_id'
    | 's3_file_id'
    | 'is_private'
    | 'icon'
    | 'default_icon'
    | 'pdf_fields'
    | 'pdf_status'
    | 'is_esign_enabled'
    | 'pdf_invalid_fields'
    | 'pdf_markup_fields'
    | 'doc_category'
>, Pick<S3FileRecord,
    | 'uploaded_by'
    | 'uploaded_role'
    | 'uploaded_time'
    | 'deleted_by'
    | 'deleted_time'
    | 'purged_time'
    | 'name'
    | 'hash'
    | 'size'
    | 'path'
    | 'suffix'
    | 'mimetype'
> {
    label: string;
    uploaded_by_fname: EntityRecord['fname'];
    uploaded_by_lname: EntityRecord['lname'];
    deleted_by_fname: Nullable<EntityRecord>['fname'];
    deleted_by_lname: Nullable<EntityRecord>['lname'];
    funeral_home_id: number | null;
    startup_rank: number | null;
    case_file_case_id: number | null;
    case_file_task_component_id: number | null;
}

export interface DocUX extends DocUXRecord { }

export type S3FileInsertRecord = Omit<S3FileRecord, 'id'
    | 'purged_time' | 'deleted_by' | 'deleted_time' | 'uploaded_time' | 'uploaded_role'
>;

export type DocInsertRecord = Omit<DocRecord, 'id'
    | 'pdf_invalid_fields' | 'pdf_fields' | 'pdf_status' | 'is_esign_enabled'
    | 'default_icon' | 'icon' | 'pdf_markup_fields'>;

export type DocAndS3InsertRecord = Omit<DocInsertRecord, 's3_file_id'> & S3FileInsertRecord;

export type DocumentPatchRequest = Partial<DocRecord>;
export type DocFolder = '';

export type S3Action = 'putObject' |
    'postObject' |
    'getObject' |
    'deleteObject'
    ;

export enum DocPDFStatus {
    none = 'none',
    success = 'success',
    not_needed = 'not needed',
    no_fields = 'processed: no Gather fields found',
    error_general = 'error: general',
    error_create_failed = 'error: failed to create PDF',
    error_parse_failed = 'error: failed to parse PDF fields',
    error_signature = 'error: signer does not have a signature or initial field.'
}

export const DocPDFStatusDefinition = t.union([
    t.literal(DocPDFStatus.none),
    t.literal(DocPDFStatus.success),
    t.literal(DocPDFStatus.not_needed),
    t.literal(DocPDFStatus.no_fields),
    t.literal(DocPDFStatus.error_general),
    t.literal(DocPDFStatus.error_create_failed),
    t.literal(DocPDFStatus.error_parse_failed),
    t.literal(DocPDFStatus.error_signature),
]);

// ---> S3FileInsert <---
const s3FileCreateRequestDefinition = {
    name: t.string,
    hash: t.string,
    suffix: t.string,
    size: t.union([t.number, t.null]),
    path: t.string,
    mimetype: t.string,
};
export const S3FileCreateRequestType = t.type(s3FileCreateRequestDefinition);
export interface S3FileCreateRequest extends t.TypeOf<typeof S3FileCreateRequestType> {
}
export class S3FileCreateRequest {
    public static fromRequest = getValidator<S3FileCreateRequest>(S3FileCreateRequestType);
}

/**
 *  Doc Types between UI/API
 */

// ---> DocCreateRequest <--- 
const docCreateRequestDefinition = {
    parent_doc_id: t.union([t.number, t.null]),
    label: t.union([t.string, t.null]),
    is_private: t.boolean,
    suffix: t.string,
    icon: t.union([t.string, t.undefined, t.null]),
    doc_category: DocCategoryDefinition,
    pdf_status: DocPDFStatusDefinition,
    uploaded_by: t.number,
    name: t.string,
    hash: t.string,
    size: t.union([t.number, t.null]),
    path: t.string,
    mimetype: t.string,
    task_component_id: t.union([t.number, t.null]),
};
const DocCreateRequestType = t.type(docCreateRequestDefinition);
export interface DocCreateRequest extends t.TypeOf<typeof DocCreateRequestType> {
}
export class DocCreateRequest {
    public static fromRequest = getValidator<DocCreateRequest>(DocCreateRequestType);
}

// ---> DocUpdateRequest <---
const docUpdateRequestDefinition = {
    label: t.union([t.string, t.null]),
};

const DocUpdateRequestType = t.partial(docUpdateRequestDefinition);

export interface DocUpdateRequest extends t.TypeOf<typeof DocUpdateRequestType> {

}
export class DocUpdateRequest {
    public static fromRequest = getValidator<DocUpdateRequest>(DocUpdateRequestType);
}

// ---> DocDelete <---
export interface DocDelete {
    deleted_time: Date;
    deleted_by: number;
}

/**
 * End UI/API Doc funtions
 * 
 */

// 
// ---> DocPreUploadRequest <---
// - client side only (no io-ts needed)
export interface UploadFileRequest {
    hash: string;
    name: string;
    size: number;
    type: string;
    suffix: string | null;
    filecontents: ArrayBuffer;
}

export interface S3FileUploadPresignUrlResponse {
    path: string;
    presignedurl: string;
    encryptionType: string;
    encryptionKeyId: string | null;
}

export type S3FileUploadRequest = UploadFileRequest & S3FileUploadPresignUrlResponse;

export interface DocDownloadResponse {
    presignedurl: string;
    contenttype: string;
    downloadfilename: string;
    suffix: string;
}

export interface FileUploadResponse {
    presignedurl: string;
    contenttype: string;
    fullpathfile: string;
    suffix: string;
    encryption: AwsS3Encryption;
}

/* 
 * this type should be in a shared types file like gatheraws.ts  or something. 
 * there are other types in the doc.ts that could be move into the AWS s3 specific types
 *  - save for another day.
 */
export type ServerSideEncryption = 'AES256' | 'aws:kms';

export interface AwsS3Encryption {
    encryptionType: ServerSideEncryption;
    encryptionProvider?: 'AWS' | 'customer';   // used for a flag for which params are needed
    encryptionKeyId?: string | null;          // this will be SSEKMSKeyId (AWS && aws:kms)
    encryptionContext?: string | null;        // this will be SSEKMEncryptionContext (AWS && aws::kms)

    // see https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#getBucketEncryption-property
    serverSideEncriptionConfiguration?: object;
}

// ---> Doc Gather PDF Field Types <---
export type PdfFieldType = 'sig' | 'text' | 'date' | 'initial' | 'check';
export enum PdfFieldTypeEnum {
    sig = 'sig',
    text = 'text',
    date = 'date',
    initial = 'initial',
    check = 'check',
}

export enum PdfMarkupType {
    Header = 'Header',
    Subheader = 'Subheader',
    Question = 'Question',
    Note = 'Note',
}

export interface PdfMarkupData {
    id: string;
    rank: number;
    type: PdfMarkupType;
    group: string;
    value: string;
}

export enum PdfFieldRequired {
    req = 'req',
    notreq = 'notreq'
}

export interface PdfFieldData {
    id: string;
    rank: number;
    type: PdfFieldType;
    group: string;
    name: string;
    required: PdfFieldRequired;
    format: string | null;
    value: string;
}

// ---> PdfFiller <---
export interface FieldJSON {
    title: string;
    fieldType: string;
    fieldFlags: string;
    fieldValue: string | boolean;
}

const DocUploadSurveyResponseDefinition = {
    funeralHomeName: t.string,
    funeralHomeKey: t.string,
    uploadedBy: t.string,
    uploadedDate: t.string,
    fileNames: t.array(t.string),
    information: t.record(t.string, t.string),
};

const DocUploadSurveyResponseType = t.type(DocUploadSurveyResponseDefinition);

export interface DocUploadSurveyResponse extends t.TypeOf<typeof DocUploadSurveyResponseType>{
}
export class DocUploadSurveyResponse {
    public static fromRequest = getValidator<DocUploadSurveyResponse>(DocUploadSurveyResponseType);
}
