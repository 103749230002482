import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import {
    GatherCasePublic,
    UserProfile
} from '../../../../../shared/types';
import { SelfLoginStyledProps } from '../SelfLogin.styles';
import { SelfLoginSrcAction } from '../SelfLoginDialog';
import HangingPhotos from '../../hangingPhotos';
import MemoryContribution from '../../memories/postMemories/MemoryContribution';
import { RouteBuilder } from '../../../../../services';
import { PhotoStatusEnum, useGSelector } from '../../../../../types';
import { useMemoryUpload } from './PostCommentStep';
import classNames from 'classnames';
import { ShareOptionType } from '../../widgets/ShareLinkSection';

interface Props extends SelfLoginStyledProps {
    publicCase: GatherCasePublic;
    userData: UserProfile | null;
    srcAction: SelfLoginSrcAction;
    zIndex: number;
    isFlowersLinkEnabled: boolean;
    isTreesLinkEnabled: boolean;
    onCloseDialog: () => void;
    onShareOptionsClick: () => void;
    onSocialIconPopperOpen: (
        event: React.MouseEvent<HTMLElement>,
        shareOptionType: ShareOptionType,
        timeout?: number
    ) => void;
    onSocialIconPopperClose: () => void;
}
const PostPhotosStep = (props: Props) => {
    const {
        publicCase,
        classes,
        zIndex,
        userData,
        srcAction,
        isFlowersLinkEnabled,
        isTreesLinkEnabled,
        onCloseDialog,
        onShareOptionsClick,
        onSocialIconPopperClose,
        onSocialIconPopperOpen
    } = props;

    const postMemory = useMemoryUpload({
        gatherCase: publicCase,
        srcAction,
        zIndex
    });

    const casePhotos = useGSelector(({ casesState }) => casesState.casePhotos.filter(photo =>
        photo.status !== PhotoStatusEnum.failed
        && typeof photo.photo?.uploaded_by === 'number'
        && photo.photo.uploaded_by === userData?.user_id
    ));

    return (
        <Grid
            item
            xs={12}
            className={classes.step4}
        >
            <Grid item xs={12} mt="60px">
                <Typography className={classes.topText} align="center">
                    {publicCase.display_fname}'s life was important to many people.
                </Typography>

                <Typography color="secondary" className={classes.helperTopText} align="center">
                    Share a few of your favorite photos of <span>{publicCase.display_fname}</span>
                </Typography>
            </Grid>

            <HangingPhotos
                activeCase={publicCase}
                zIndex={zIndex + 2}
                casePhotos={casePhotos}
                disabledFbButton
                id="Post-Photos-Step"
                rootClass={classNames(
                    classes.hangingPhotosContainer,
                    casePhotos.length >= 2 && classes.twoOrMorePhotos
                )}
            />

            <Grid item xs={12} mt="40px">
                <MemoryContribution
                    question={<>A
                        <span className={classes.greatVibesFont}>
                            Photo
                        </span>
                        can be worth a thousand
                        <span className={classes.greatVibesFont}>
                            Words
                        </span>
                    </>}
                    linkProps={RouteBuilder.Disabled}
                    prompt="photo"
                    avatarPosition="top"
                    type="button"
                    buttonText="upload your favorites"
                    thanksScreenMessage={`Family & Friends will love seeing your 
                        photos of ${publicCase.display_fname}.`}
                    buttonIcon={<AddAPhotoIcon className={classes.marginBottom4} />}
                    userData={userData}
                    isUserPhotoLoading={false}
                    caseDisplayFname={publicCase.display_fname}
                    onSave={postMemory}
                    openShareOptionsDialog={onShareOptionsClick}
                    openSocialIconPopper={onSocialIconPopperOpen}
                    closeSocialIconPopper={onSocialIconPopperClose}
                />
            </Grid>

            <Button
                variant="contained"
                color="primary"
                onClick={onCloseDialog}
                className={classes.doneButton}
            >
                {isFlowersLinkEnabled || isTreesLinkEnabled ? 'Continue' : 'Done'}
            </Button>
        </Grid>
    );
};

export default PostPhotosStep;