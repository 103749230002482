import { useState } from 'react';

import { makeStyles } from '@mui/styles';

import { TrackingItemStatus, TrackingItemType } from '../BodyTrackingContent';
import GMenu, { GMenuItem } from '../../../common/GMenu';
import { useGDispatch, useGSelector } from '../../../../types';
import { uncompleteTask } from '../../../../actions/task/Task.action';
import { TaskState, TrackingStepType } from '../../../../shared/types';
import GPopper from '../../../common/GPopper';
import { usePopperState } from '../../../common/hooks/usePopperState';
import FamilySharingSwitch from '../../tasks/simpleLayout/completedStepsStepper/FamilySharingSwitch';

const useStyles = makeStyles({
    paperClass: {
        padding: '24px 8px 8px',
        textAlign: 'center',
        maxWidth: '360px',
        filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
        '-webkit-filter': 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
    },
    switchText: {
        fontSize: 18,
        color: 'unset',
    },
}, { name: 'QRCompletedStepsMenu' });

interface ActiveStep {
    stepId: number | undefined;
    status: TrackingItemStatus | TaskState;
    type: TrackingItemType | TrackingStepType | null;
    onClick: () => void;
}

interface Props {
    zIndex: number;
    activeStep: ActiveStep;
    menuAnchor: HTMLElement | null;
    closeMenu: VoidFunction;
    openNotesDialog: VoidFunction;
    isNote?: boolean;
}
const QRCompletedStepsMenu = (props: Props) => {
    const { zIndex, isNote, closeMenu, activeStep, menuAnchor, openNotesDialog } = props;
    const dispatch = useGDispatch();
    const classes = useStyles();

    const selectedCase = useGSelector(({ casesState }) => casesState.selectedCase);
    const [familyVisibilityPopperAnchor, setFamilyVisibilityPopperAnchor] = useState<HTMLElement | null>(null);
    usePopperState();

    const step = useGSelector(({ tasksState }) =>
        tasksState.trackingSteps.find(tStep => tStep.id === activeStep.stepId)
    );

    if (!step || !selectedCase) {
        return <></>;
    };

    const familyVisibilityAdjustmentPopper = () => {
        return (
            <GPopper
                anchorEle={familyVisibilityPopperAnchor}
                paperClass={classes.paperClass}
                zIndex={zIndex}
                closePopper={() => setFamilyVisibilityPopperAnchor(null)}
                hideArrow
            >
                <FamilySharingSwitch
                    stepId={step.id}
                    stepTitle={step.past_tense_title || step.title}
                    stepVisibleToFamily={step.visible_to_family}
                    caseUuid={selectedCase.uuid}
                    switchTextClass={classes.switchText}
                />
            </GPopper>
        );
    };

    const menuItems: GMenuItem[] = [{
        id: 1,
        content: isNote ? 'View Notes' : 'View Step Details',
        onClick: () => {
            if (isNote) {
                openNotesDialog();
            }

            activeStep.onClick();
            closeMenu();
        }
    }];
    if ((activeStep.status === TrackingItemStatus.skipped) || (activeStep.status === TaskState.skipped)) {
        menuItems.push({
            id: 2,
            content: 'Unskip Step',
            onClick: () => {
                if (activeStep.stepId !== undefined && selectedCase) {
                    dispatch(uncompleteTask(activeStep.stepId, selectedCase.uuid, null, null));
                }
                closeMenu();
            }
        });
    }
    const isVisibilityToggleDisabled = activeStep.type === TrackingItemType.initialize_step
        || activeStep.type === TrackingStepType.initialize
        || activeStep.type === TrackingItemType.finalize_step
        || activeStep.type === TrackingStepType.finalize;

    menuItems.push({
        id: 3,
        content: 'Adjust Family Visibility',
        disabled: isVisibilityToggleDisabled,
        tooltip: isVisibilityToggleDisabled
            ? 'The initialize and finalize steps cannot be hidden'
            : undefined,
        onClick: e => setFamilyVisibilityPopperAnchor(e.currentTarget)
    });

    return (
        <>
            <GMenu
                items={menuItems}
                anchorEl={menuAnchor}
                onClose={closeMenu}
                zIndex={zIndex}
            />

            {familyVisibilityAdjustmentPopper()}

        </>
    );
};

export default QRCompletedStepsMenu;