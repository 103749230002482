import SignatureCanvas from 'react-signature-canvas';
import { uploadSignature } from '../../../actions/Signature.action';
import { getDataFromBlob } from '../../../services/doc.service';
import { S3FileCreateRequest } from '../../../shared/types';
import { AppDispatch } from '../../../store';

export const uploadSignatureFromRef = async (params: {
    ref: SignatureCanvas | null;
    dispatch: AppDispatch;
    caseUuid: string;
}): Promise<S3FileCreateRequest | null> => {
    const { ref, dispatch, caseUuid } = params;

    if (!ref || ref.isEmpty()) {
        return null;
    }
    ref.off();
    const blob = await (await fetch(ref.toDataURL("image/png"))).blob();
    const uploadRequest = await getDataFromBlob(blob, 'signature');
    if (!uploadRequest) {
        return null;
    }

    return await dispatch(uploadSignature(uploadRequest, caseUuid));
};
