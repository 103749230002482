import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import ResetPasswordStepsContent from '../widgets/ResetPasswordStepsContent';
import { OnlineAccountType, ResetPasswordStepsProps } from '../utils';

const ResetPasswordSteps = (props: ResetPasswordStepsProps) => {
    const { activeCase, onStepChange } = props;

    const classes = useTaskDialogStyles();

    const resetPasswordUrl: string = 'https://www.amazon.com/ap/forgotpassword?' +
        '_encoding=UTF8&openid.assoc_handle=usflex&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2' +
        'Fidentifier_select&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid' +
        '.mode=checkid_setup&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.ns.pape=http%3A%2F%2Fspecs' +
        '.openid.net%2Fextensions%2Fpape%2F1.0&openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2F' +
        'www.amazon.com%2Fgp%2Fcss%2Faccount%2Fforgot-password%2Femail.html%3Fie%3DUTF8%26%252AVersion%252A%3D1%26' +
        '%252Aentries%252A%3D0_';

    const steps = [<>
        <a
            href={resetPasswordUrl}
            target="blank"
            className={classes.colorSecondary}
        >
            Click here
        </a>
        {' '} to reset {activeCase.fname}’s {OnlineAccountType.Amazon} password
    </>,
    `Enter ${activeCase.fname}’s email address`,
    `A reset password email will be sent to ${activeCase.fname}’s email`,
        'Follow the instructions in the email'
    ];

    return (
        <ResetPasswordStepsContent
            caseFName={activeCase.fname}
            accountType={OnlineAccountType.Amazon}
            steps={steps}
            onReturnClick={onStepChange}
            onAbleToLogin={onStepChange}
        />
    );
};

export default ResetPasswordSteps;
