import * as t from 'io-ts';
import { UserProfile, UserRoles, GuestListUser } from './user';
import { PaginatedResponse, PhotoTransformationsType, MemoryUX, getValidator } from '.';

export interface VisitorForModeration extends GuestListUser {
}

export interface ModerationPhotoContributor {
    user_id: number;
    fname: string;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    relationship: string | null;
}

export interface PhotoForModeration {
    contributor: ModerationPhotoContributor;
    photo_id: number;
    public_id: string;
    album_entry_id: number;
    uploaded_time: Date;
}

export interface MemoryForModeration extends MemoryUX {
}

export interface ModerationDecisionUser {
    user_id: number;
    fname: string;
    mname: string | null;
    lname: string;
    is_gom_user: boolean;
}

export enum ModerationStatus {
    approved = 'approved',
    pending = 'pending',
    blocked = 'blocked',
}

export const ModerationStatusDisplayLookup = {
    [ModerationStatus.approved]: 'Approved',
    [ModerationStatus.blocked]: 'Blocked',
    [ModerationStatus.pending]: 'Pending',
};

export const ModerationStatusDefinition = t.union([
    t.literal(ModerationStatus.approved),
    t.literal(ModerationStatus.blocked),
    t.literal(ModerationStatus.pending),
]);

export interface ModerationQueueRecord {
    id: number;
    case_entity_id: number | null;
    photo_id: number | null;
    memory_id: number | null;
}

export interface ModerationQueueInsertRecord extends Omit<ModerationQueueRecord, 'id'> { }

interface ModerationDecisionRecord {
    approval_status: ModerationStatus;
    reason: string | null;
    created_time: Date;
    created_by: number;
}

export interface ModerationDecisionUXRecord extends ModerationDecisionRecord {
    moderated_by_fname: string;
    moderated_by_mname: string | null;
    moderated_by_lname: string;
    moderated_by_user_id: number;
    is_gom_user: boolean;
}

export interface ModerationDecisionUX extends ModerationDecisionRecord {
    moderated_by: ModerationDecisionUser;
}

interface BaseModerationUX {
    case_id: number;
    case_uuid: string;
    case_name: string;
    case_lname: string;
    fh_key: string;
    funeral_home_id: number;
    case_fname: string;
    moderation_decision: ModerationDecisionUX | null;
    case_photo: string | null;
    case_photo_transformations: PhotoTransformationsType | null;
    dod_start_date: string | null;
    dob_date: string | null;
    dod_start_time: string;
    funeral_home_name: string;
}

export interface ModerationPhotoUX extends BaseModerationUX {
    photo: PhotoForModeration;
}

export interface ModerationVisitorUX extends BaseModerationUX {
    visitor: VisitorForModeration;
}

export interface ModerationMemoryUX extends BaseModerationUX {
    memory: MemoryForModeration;
}

export type ModerationItem = ModerationVisitorUX | ModerationPhotoUX | ModerationMemoryUX;

export enum ModerationCategory {
    photos = 'photos',
    memories = 'memories',
    visitors = 'visitors',
}

const ModerationCategoryDefinition = t.union([
    t.literal(ModerationCategory.memories),
    t.literal(ModerationCategory.photos),
    t.literal(ModerationCategory.visitors),
]);

export const isModerationStatus = (u: unknown): u is ModerationStatus => {
    return typeof u === 'string' && Boolean(ModerationStatus[u]);
};
export const isModerationCategory = (u: unknown): u is ModerationCategory => {
    return typeof u === 'string' && Boolean(ModerationCategory[u]);
};
export const isModerationPhoto = (item: ModerationItem): item is ModerationPhotoUX => {
    return Boolean('photo' in item && item.photo);
};
export const isModerationMemory = (item: ModerationItem): item is ModerationMemoryUX => {
    return Boolean('memory' in item && item.memory);
};
export const isModerationVisitor = (item: ModerationItem): item is ModerationVisitorUX => {
    return Boolean('visitor' in item && item.visitor);
};
export const isModerationPhotoList = (items: ModerationItem[]): items is ModerationPhotoUX[] => {
    return items.every(isModerationPhoto);
};
export const isModerationMemoryList = (items: ModerationItem[]): items is ModerationMemoryUX[] => {
    return items.every(isModerationMemory);
};
export const isModerationVisitorList = (items: ModerationItem[]): items is ModerationVisitorUX[] => {
    return items.every(isModerationVisitor);
};
export const isModerationCountsForCase = (
    totals: ModerationCountsForCase | ModerationPendingCountsForAllCases | null | undefined
): totals is ModerationCountsForCase => {
    return Boolean(totals && 'approved' in totals && totals.approved);
};
export const isModerationPendingCountsForAllCases = (
    totals: ModerationCountsForCase | ModerationPendingCountsForAllCases | null | undefined
): totals is ModerationPendingCountsForAllCases => {
    return Boolean(totals && 'all_cases' in totals && totals.all_cases);
};

export const ModerationCategorySingleDisplayLookup: Record<ModerationCategory, string> = {
    [ModerationCategory.memories]: 'Memory',
    [ModerationCategory.visitors]: 'Visitor',
    [ModerationCategory.photos]: 'Photo',
};

export interface ModerationCaseDetails {
    name: string;
    fname: string;
    lname: string;
    dod_start_date: string | null;
    dob_date: string | null;
    dod_start_time: string;
    photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    funeral_home_name: string;
    funeral_home_key: string;
}

export interface LoadModerationItemsResponse<T extends ModerationItem> extends PaginatedResponse<T> {
    totals?: ModerationCountsForCase | ModerationPendingCountsForAllCases | null;
    caseDetails: ModerationCaseDetails | null;
}

export interface ModerationCategoryCounts extends Record<ModerationCategory, number> {
    all: number;
}

export interface ModerationCountsForCase
    extends Record<ModerationStatus, ModerationCategoryCounts> {
    case_id: number;
    case_uuid: string;
    case_full_name: string;
}

export interface ModerationPendingCountsForAllCases {
    all_cases: ModerationCategoryCounts;
    cases: ModerationCountsForCase[];
}

// ---> ModerationDecisionRequestItem <---
const moderationDecisionItemRequestDefinition = {
    id: t.number,
    case_id: t.number,
};
const ModerationDecisionItemRequestType = t.type(moderationDecisionItemRequestDefinition);

export interface ModerationDecisionRequestItem extends t.TypeOf<typeof ModerationDecisionItemRequestType> { }

// ---> ModerationDecisionRequest <---
const moderationDecisionRequestDefinition = {
    category: ModerationCategoryDefinition,
    approval_status: ModerationStatusDefinition,
    reason: t.string,
    items: t.array(ModerationDecisionItemRequestType),
};
const ModerationDecisionRequestType = t.type(moderationDecisionRequestDefinition);

export interface ModerationDecisionRequest extends t.TypeOf<typeof ModerationDecisionRequestType> {
    category: ModerationCategory;
    items: ModerationDecisionRequestItem[];
}

export class ModerationDecisionRequest {
    public static fromRequest = getValidator<ModerationDecisionRequest>(ModerationDecisionRequestType);
}

// ---> ModerationFlagRequest <---
const ModerationFlagRequestDefinition = {
    category: ModerationCategoryDefinition,
    itemId: t.number,
};
const ModerationFlagRequestType = t.type(ModerationFlagRequestDefinition);

export interface ModerationFlagRequest extends t.TypeOf<typeof ModerationFlagRequestType> {
    category: ModerationCategory;
}

export class ModerationFlagRequest {
    public static fromRequest = getValidator<ModerationFlagRequest>(ModerationFlagRequestType);
}

const getModerationRequiredForUser = (params: {
    user: UserProfile | null;
    funeralHomeId: number | null;
    caseId: number | null;
}): boolean => {
    const { user, funeralHomeId, caseId } = params;
    if (!user || !funeralHomeId || !caseId) {
        return true;
    }
    if (UserRoles.isGOMUser(user)) {
        return false;
    } else if (UserRoles.isFHorGOMUserOnFH(user, funeralHomeId)) {
        return false;
    } else if (UserRoles.isInvitedFamilyOnCase(user, caseId)) {
        return false;
    } else if (UserRoles.isFamilyVisitor(user, caseId)) {
        return true;
    } else {
        return true;
    }
};

export const getCasePhotoModerationRequired = (params: {
    user: UserProfile | null;
    funeralHomeId: number | null;
    caseId: number | null;
}): boolean => {
    return getModerationRequiredForUser(params);
};

export const getMemoryModerationRequired = (params: {
    user: UserProfile | null;
    funeralHomeId: number | null;
    caseId: number | null;
}): boolean => {
    return getModerationRequiredForUser(params);
};
