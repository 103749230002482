import { useState } from 'react';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import MenuIcon from '@mui/icons-material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SyncIcon from '@mui/icons-material/Sync';

import { useGSelector } from '../../../types';
import { GatherCaseUX, CaseType } from '../../../shared/types';

import { DRAWER_WIDTH_DOWN_SM } from '../../../constants';

import CaseSwitcher from '../../cases/caseSwitcher/CaseSwitcher';
import UserAvatar from '../../../components/common/UserAvatar';
import { isViewAsGOM } from '../../../services';
import { WhenAnyEnabled, WebsiteSyncFeatures } from '../../common/AppFeature';
import WebsiteSyncPopper from '../../websiteSync/WebsiteSyncPopper';
import { canSyncCase } from '../../../shared/authority/can';
import GLink from '../../common/GLink';
import { RouteBuilder } from "../../../services";
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {
        position: 'fixed',
        marginLeft: DRAWER_WIDTH_DOWN_SM,
        color: '#fff',
    },
    relativeAppBar: {
        position: 'relative',
        margin: 0,
        color: '#fff',
    },
    headerAvatar: {
        float: 'right',
        border: '1px solid #fff',
        // position: 'absolute',
        zIndex: -1
    },
    toolbar: {
        height: 64,
        paddingLeft: 4,
        paddingRight: 8,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 8,
            paddingRight: 16,
        },
    },
    customLink: {
        color: '#fff',
        backgroundColor: 'transparent',
    },
    saveButton: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: '1px solid',
        width: '124px',
        fontSize: '12px',
        padding: '7px 0px',
        marginLeft: '8px',
        marginTop: '6px',
        '@media (min-width: 360px)': {
            width: '148px',
            fontSize: '14px',
        },
    },
    disabledSaveButton: {
        backgroundColor: 'transparent !important',
        color: '#fff !important',
        opacity: 0.6
    },
    progressWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        height: 48,
        width: 48,
        cursor: 'pointer'
    },
    fabProgress: {
        position: 'absolute',
        top: -2,
        left: -4,
        zIndex: 1,
    },
    progressText: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '10px',
        marginTop: '8px',
        height: '20px',
        paddingLeft: '2px'
    },
    checkIcon: {
        marginTop: '2px'
    },
    badgeIconContainer: {
        display: 'flex',
        width: 48,
        height: 48,
    },
    badgeIcon: {
        cursor: 'pointer',
        margin: 'auto',
        background: '#f00',
        color: '#fff',
        width: 30,
        minWidth: 30,
        height: 30,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: '50%',
        flexDirection: 'row',
        justifyContent: 'center',
        border: '1px solid',
        '@media (min-width : 350px)': {
            margin: 'auto 4px'
        },
        '& $checkIcon': {
            margin: 'auto'
        }
    },
    headerUserAvatarPrecedent: {
        fontSize: 14,
        lineHeight: '48px',
        opacity: .6,
        paddingRight: 8,
        '@media (max-width: 360px)': {
            width: '56px',
            display: 'inline-table',
            textAlign: 'center',
            marginTop: 8,
            lineHeight: '16px'
        },
    },
    iconButton: {
        height: 36,
        width: 36,
        margin: '6px 0',
        '@media (min-width: 600px)': {
            margin: 0,
            height: 48,
            width: 48,
        },
        '& $fileCopyIcon': {
            fontSize: 28
        },
    },
    lockIcon: {
        '& svg': {
            fontSize: 32,
            width: 32,
            height: 32
        }
    },
    rightSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    fileCopyIcon: {}
}), { name: 'FamilySecondaryAppBar' });

interface Props {
    activeCase: GatherCaseUX | null;
    position?: 'relative' | 'fixed';
    badgeValue?: number;
    userAvatarPrecedent?: boolean;
    dcProgress?: number;
    saveChangesButton?: SaveChangesButtonType;
    lockIcon?: JSX.Element;
    hasDocsIcon?: boolean;
    hasSyncIcon?: boolean;
    goBackCallBack?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    handleDrawerToggle?: () => void;
    openPopper?: (event: React.MouseEvent<HTMLElement>) => void;
    zIndex: number;
}

interface SaveChangesButtonType {
    text: string;
    isSaving: boolean;
    isDisabled: boolean;
    onClick: () => void;
}

const FamilySecondaryAppBar = (props: Props) => {
    const {
        activeCase,
        position,
        badgeValue,
        userAvatarPrecedent,
        dcProgress,
        saveChangesButton,
        lockIcon,
        hasDocsIcon,
        hasSyncIcon,
        goBackCallBack,
        handleDrawerToggle,
        openPopper,
        zIndex,
    } = props;

    const classes = useStyles();

    const [caseSwitcherAnchorEle, setCaseSwitcherAnchorEle] = useState<HTMLElement | null>(null);
    const [syncPopperAnchorEle, setSyncPopperAnchorEle] = useState<HTMLElement | null>(null);

    const userData = useGSelector(({ userSession }) => userSession.userData);
    const isUserPhotoSaving = useGSelector(({ userSession }) => userSession.isUserPhotoSaving);
    const viewAsGOM = useGSelector(({ userSession }) => isViewAsGOM(userSession));

    const toggleCaseSwitcher = (event?: React.MouseEvent<HTMLElement>) => {
        setCaseSwitcherAnchorEle(event && event.currentTarget || null);
    };

    const openSyncPopper = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.currentTarget;

        setSyncPopperAnchorEle(target);
    };

    const closeSyncPopper = () => {
        setSyncPopperAnchorEle(null);
    };

    const canUserSyncCase = canSyncCase(userData, activeCase && activeCase.funeral_home_id);

    const caseLink = activeCase
        ? RouteBuilder.FamilyPage({
            caseName: activeCase.name,
            funeralHomeKey: activeCase.funeral_home.key,
        })
        : RouteBuilder.Root;

    const floorDcProgress = Math.floor(dcProgress ? dcProgress : 0);


    return (
        <div>
            <AppBar
                className={classNames(
                    position && position === 'relative' ? classes.relativeAppBar : classes.root,
                    classes.backgroundPrimary
                )}
                style={{ zIndex }}
            >
                <Toolbar className={classes.toolbar}>
                    <GLink
                        to={caseLink}
                        linkClass={classes.customLink}
                        onClick={goBackCallBack}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="go back"
                            size="large"
                        >
                            <ArrowBack className={classes.fontSize32} />
                        </IconButton>
                    </GLink>

                    {handleDrawerToggle &&
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            size="large">
                            <MenuIcon className={classes.fontSize32} />
                        </IconButton>
                    }
                    {openPopper && badgeValue !== undefined &&
                        <div
                            className={classes.badgeIconContainer}
                            onClick={e => openPopper(e)}
                        >
                            <div className={classNames(
                                classes.badgeIcon,
                                badgeValue === 0 ? classes.backgroundGreen : classes.backgroundRed
                            )}>
                                {badgeValue === 0 ?
                                    <CheckIcon className={classes.checkIcon} />
                                    : <span>{badgeValue}</span>}
                            </div>
                        </div>
                    }
                    {openPopper && dcProgress !== undefined &&
                        <div
                            className={classes.progressWrapper}
                            onClick={e => openPopper(e)}
                        >
                            <div className={classes.positionRelative}>
                                {dcProgress === 100
                                    ? <CheckIcon className={classes.checkIcon} />
                                    : <span className={classes.progressText} >{floorDcProgress}%</span>}
                                <CircularProgress
                                    color="inherit"
                                    size={32}
                                    className={classes.fabProgress} variant="determinate" value={floorDcProgress}
                                />
                            </div>

                        </div>
                    }
                    {openPopper !== undefined && lockIcon &&
                        <IconButton
                            color="inherit"
                            aria-label="lock-icon"
                            onClick={(e) => openPopper(e)}
                            className={classes.lockIcon}
                            size="large"
                        >
                            {lockIcon}
                        </IconButton>
                    }
                    {canUserSyncCase && hasSyncIcon &&
                        <WhenAnyEnabled features={WebsiteSyncFeatures}>
                            <IconButton
                                color="inherit"
                                aria-label="sync"
                                className={classes.lockIcon}
                                onClick={openSyncPopper}
                                disabled={!activeCase || activeCase.case_type !== CaseType.at_need}
                                size="large">
                                <SyncIcon />
                            </IconButton>
                        </WhenAnyEnabled>
                    }
                    {openPopper && hasDocsIcon &&
                        <IconButton
                            color="inherit"
                            className={classNames(classes.pullRight, classes.iconButton)}
                            onClick={(e) => openPopper(e)}
                            size="large"
                        >
                            <FileCopyIcon className={classes.fileCopyIcon} />
                        </IconButton>
                    }

                    <div
                        className={classNames(
                            classes.width100,
                            classes.rightSection,
                            userAvatarPrecedent ? classes.textRight : ''
                        )}
                    >
                        {saveChangesButton &&
                            <div className={classes.floatLeft}>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    className={classNames(
                                        classes.saveButton,
                                        saveChangesButton.isDisabled ? classes.disabledSaveButton : ''
                                    )}
                                    onClick={saveChangesButton.onClick}
                                    disabled={saveChangesButton.isDisabled}
                                >
                                    {saveChangesButton.text}
                                    {saveChangesButton.isSaving &&
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    }
                                </Button>
                            </div>
                        }
                        {userAvatarPrecedent &&
                            <span
                                className={classNames(
                                    classes.headerUserAvatarPrecedent,
                                )}
                            >
                                <Hidden only={['xs', 'sm']}>
                                    <span>All changes saved automatically</span>
                                </Hidden>
                                <Hidden mdUp>
                                    <span>Changes saved</span>
                                </Hidden>
                            </span>
                        }
                        <IconButton
                            className={classes.pullRight}
                            onClick={toggleCaseSwitcher}
                            size="large">
                            <UserAvatar
                                user={userData}
                                className={classNames(classes.headerAvatar, viewAsGOM && classes.b_solid_orange_2)}
                                isLoading={isUserPhotoSaving}
                            />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <CaseSwitcher
                popperAnchorEle={caseSwitcherAnchorEle}
                closePopper={toggleCaseSwitcher}
                zIndex={zIndex + 1}
            />
            {activeCase &&
                <WebsiteSyncPopper
                    key={activeCase.id}
                    activeCase={activeCase}
                    fname={activeCase.fname}
                    anchorEle={syncPopperAnchorEle}
                    onClose={() => closeSyncPopper()}
                    obitStatus={activeCase.obituary_task_status}
                    zIndex={zIndex + 1}
                />
            }
        </div>
    );
};

export default FamilySecondaryAppBar;
