import { emptyStringParser, joinNameParts, eliminateWhiteSpaces } from "../../../../../services";
import { ConfigurableParentFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { EntitySummary, GatherCaseUX, getCaseEntityById } from "../../../../../shared/types";
import { OverviewSectionCommonProps, parseShortAddress } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
    activeCase: GatherCaseUX;
    helpers: EntitySummary[];
}
const ParentsOverview = (props: Props) => {
    const { deathCertificate, activeCase, helpers, divider, printMode, showHeading, alwaysVisible } = props;

    const { dc_father, dc_mother } = activeCase;

    const selectedFather: EntitySummary | null = dc_father &&
        helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_father))) || null;
    const selectedMother: EntitySummary | null = dc_mother &&
        helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_mother))) || null;

    const father: string | null = !selectedFather ? null :
        emptyStringParser(joinNameParts(selectedFather));

    let fcb: string | null = !deathCertificate.parents.fatherBirthPlace.unknown
        && parseShortAddress(deathCertificate.parents.fatherBirthPlace) || null;
    if (deathCertificate.parents.fatherBirthPlace.unknown) {
        fcb = 'unknown';
    }

    const mother = selectedMother && joinNameParts(selectedMother) || null;
    const motherMaidenName = deathCertificate.parents.motherName.unknown
        ? 'unknown'
        : emptyStringParser(eliminateWhiteSpaces(emptyStringParser(deathCertificate.parents.motherName.maidenName)));

    let mcb: string | null = !deathCertificate.parents.motherBirthPlace.unknown
        && parseShortAddress(deathCertificate.parents.motherBirthPlace) || null;
    if (deathCertificate.parents.motherBirthPlace.unknown) {
        mcb = 'unknown';
    }

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    PARENTS
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Father"
                    html={emptyStringParser(eliminateWhiteSpaces(father))}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Father's city of Birth"
                    html={fcb}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableParentFieldKey.fatherBirthCity}
                />

                <span style={{ display: 'block' }}>&nbsp;</span>

                <OverviewLabel
                    label="Mother"
                    html={emptyStringParser(eliminateWhiteSpaces(mother))}
                    alwaysVisible={alwaysVisible}
                    fieldKey={undefined}
                />

                <OverviewLabel
                    label="Mother Maiden Name"
                    html={motherMaidenName}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableParentFieldKey.motherMaidenName}
                />

                <OverviewLabel
                    label="Mother's city of Birth"
                    html={mcb}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableParentFieldKey.motherBirthCity}
                />
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default ParentsOverview;