import moment from 'moment';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import UserAvatar from '../../common/UserAvatar';
import { deathOrAge, getFullNameFromCase, RouteBuilder } from '../../../services';
import { ModerationCaseDetails } from '../../../shared/types';
import GLink from '../../common/GLink';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {
        background: theme.palette.primary.main,
        width: 300,
        borderRadius: 6,
        padding: '2px 6px 8px',
        position: 'relative',
        marginTop: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& p': {
            color: theme.palette.common.white,
            fontSize: 12
        }
    },
    content: {
        '& p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            maxWidth: 300,
            color: theme.palette.common.white,
        },
    },
    fhName: {
        fontSize: 12,
        lineHeight: 1,
    },
    caseName: {
        fontSize: 22,
        lineHeight: 1.25,
        marginTop: 8,
    },
    avatar: {
        position: 'absolute !important' as 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%, -28%)',
        width: 48,
        height: 48
    }
}), { name: 'ModerationCaseCard' });

interface Props {
    gatherCase: ModerationCaseDetails;
}

const ModerationCaseCard = (props: Props) => {
    const { gatherCase } = props;

    const classes = useStyles();

    const diedAt = moment(gatherCase.dod_start_date || moment()).fromNow();
    const age = deathOrAge(gatherCase, '', true);
    const caseFullName = getFullNameFromCase(gatherCase);

    return (
        <div className={classes.root}>
            <GLink to={RouteBuilder.FamilyPage({
                caseName: gatherCase.name,
                funeralHomeKey: gatherCase.funeral_home_key,
            })}>
                <UserAvatar user={gatherCase} className={classes.avatar} size={48} />
            </GLink>
            <Grid item xs={12} className={classes.header}>
                {diedAt && <Typography component="p">Died {diedAt}</Typography>}
                <Typography component="p">{age}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.content}>
                <Typography component="p" className={classes.caseName}>
                    {caseFullName}
                </Typography>
                <Typography component="p" className={classes.fhName}>
                    {gatherCase.funeral_home_name}
                </Typography>
            </Grid>
        </div>
    );
};

export default ModerationCaseCard;
