import momentTz from 'moment-timezone';
import {
    DateTimeFormat,
    formatDateString,
    dateToString,
} from '../shared/utils';
import { keys } from 'lodash';
import { emptyStringParser } from './app.service';

export interface MomentDurations {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
}

export const getDatesDiffDurations = (params: {
    older: Date | momentTz.Moment;
    newer: Date | momentTz.Moment;
}): MomentDurations => {
    const { older, newer } = params;

    let olderMoment = older instanceof Date ? momentTz(older) : older.clone();
    let newerMoment = newer instanceof Date ? momentTz(newer) : newer.clone();

    const years = newerMoment.diff(olderMoment, 'year');
    olderMoment.add(years, 'years');

    const months = newerMoment.diff(olderMoment, 'months');
    olderMoment.add(months, 'months');

    const days = newerMoment.diff(olderMoment, 'days');
    olderMoment.add(days, 'days');

    const hours = newerMoment.diff(olderMoment, 'hours');
    olderMoment.add(hours, 'hours');

    const minutes = newerMoment.diff(olderMoment, 'minutes');

    return {
        years,
        months,
        days,
        hours,
        minutes
    };
};

export const timezoneLookup = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'America/New_York': 'US/Eastern',
    'America/Chicago': 'US/Central',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'America/Los_Angeles': 'US/Pacific',
    'America/Denver': 'US/Mountain',
    'America/Anchorage': 'US/Alaska',
    'America/Phoenix': 'US/Arizona',
    'Pacific/Honolulu': 'US/Hawaii',
    'America/Indiana/Indianapolis': 'US/East-Indiana',
    'America/Indiana/Knox': 'US/Indiana-Starke',
    'America/Detroit': 'US/Michigan',
};

interface LookupWithOffsets {
    id: string;
    offset: number;
    label: string;
}

const lookupWithOffsets = (): LookupWithOffsets[] => keys(timezoneLookup).map((tzId) => ({
    id: tzId,
    offset: momentTz.tz(tzId).utcOffset(),
    label: timezoneLookup[tzId],
}));

export const getLabelByOffset = (offset: number): string => {
    const lookups = lookupWithOffsets();
    const tzObj: LookupWithOffsets | undefined = lookups.find((obj) => obj.offset === offset);
    return tzObj ? tzObj.label : '';
};

export const getTzLabel = (tz: string) => {
    let tzLabel: string | undefined = timezoneLookup[tz];
    if (!tzLabel) {
        tzLabel = getLabelByOffset(momentTz.tz(tz).utcOffset());
    }
    return tzLabel || '';
};

export const getTzByOffset = (offset: number): string => {
    const lookups = lookupWithOffsets();
    const tzObj: LookupWithOffsets | undefined = lookups.find((obj) => obj.offset === offset);
    return tzObj ? tzObj.id : '';
};

export const normalizeTz = (tz: string): string => {
    return timezoneLookup[tz] ? tz : getTzByOffset(momentTz.tz(tz).utcOffset());
};

export const setDateTimeString = (
    dateTime: string | null | undefined,
    time?: string | null
): string => {
    if (!emptyStringParser(dateTime) || !dateTime) {
        return '';
    }

    const fullDate: Date = momentTz(dateTime).toDate();

    if (emptyStringParser(time) && time) {
        const timeArray: Array<string> = time.split(':');
        fullDate.setHours(Number(timeArray[0]));
        fullDate.setMinutes(Number(timeArray[1]));
    } else {
        fullDate.setHours(0);
        fullDate.setMinutes(0);
    }

    return fullDate.toUTCString();
};


export const parseDateTimeString = (
    dateTime: string | null | undefined,
    responseType: 'DATE' | 'TIME'
): string | undefined => {
    if (!emptyStringParser(dateTime) || !dateTime) {
        return undefined;
    }

    let response: undefined | string = undefined;

    switch (responseType) {
        case 'DATE':
            response = momentTz(dateTime).format(DateTimeFormat.ShortDate);
            break;
        case 'TIME':
            const hours = momentTz(dateTime).format('HH');
            const minutes = momentTz(dateTime).format('mm');
            response = hours && minutes && `${hours}:${minutes}`;
            break;
        default:
            break;
    }
    return response;
};


export const formatTimeString = (time: string, format: string = 'h:mm A'): string => {
    if (time && time.trim && time.trim() !== '') {
        // build a date object with any month/day/year, and our time attached
        const dt = new Date(`12/15/2001 ${time}`);
        return dateToString(dt, format);
    }
    return '';
};

export const formatDateTimeStrings = (date: string, time: string, format?: string): string => {
    return `${formatDateString(date, format)} ${formatTimeString(time, '[at] h:mm A')}`.trim();
};

export const tzWithFallback = (tz: string | null | undefined) => {
    return tz || momentTz.tz.guess() || 'America/Denver';
};
