import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import { SaveProductStyledProps, saveProductStyles } from './styles';

import GSwitch from '../../common/inputElements/GSwitch';
import { ProductState } from './SaveProduct';
import withGStyles from '../../../styles/WithGStyles';

interface Props {
    isGlobalProduct: boolean;
    onStateChange: (name: keyof ProductState, value: string | boolean) => void;
    product: Partial<ProductState> ;
}

type CombinedProps = SaveProductStyledProps & Props;

const DisplayOptionsContent = (props: CombinedProps) => {

    const {
        classes,
        isGlobalProduct,
        onStateChange,
        product,
    } = props;

    const {
        isAlwaysDisplayed,
        useNAWhenAlwaysDisplayed,
        displayModelNumber,
        displayManufacturer,
        displayTags,
        persistentContractText,
        boldContractText,
        underlineContractText,
        indentContractText,
    } = product;

    return (
        <>
            <FormControl className={classNames(
                classes.formControl,
                classes.displayOptionsContainer
            )}>
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={boldContractText}
                    label={`Bold`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('boldContractText', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={underlineContractText}
                    label={`Underline`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('underlineContractText', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={indentContractText}
                    label={`Indent`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('indentContractText', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
            </FormControl>

            <FormControl className={classes.formControl}>
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={isAlwaysDisplayed}
                    label={`Display "Product Name" even if NOT added to statement`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('isAlwaysDisplayed', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
                <RadioGroup
                    className={classes.productNameRadioBtn}
                    row
                    value={useNAWhenAlwaysDisplayed ? 'true' : 'false'}
                    onChange={(e, value) => {
                        if (value === 'false' || value === 'true') {
                            onStateChange('useNAWhenAlwaysDisplayed', value === 'true');
                        }
                    }}
                >
                    <FormControlLabel
                        disabled={!isAlwaysDisplayed}
                        value="false"
                        control={<Radio color="primary" />}
                        label={`Use "___"`}
                        labelPlacement="end"
                        className={classes.radioLabel}
                    />
                    <FormControlLabel
                        disabled={!isAlwaysDisplayed}
                        value="true"
                        control={<Radio color="primary" />}
                        label={`Use "N/A"`}
                        labelPlacement="end"
                        className={classes.radioLabel}
                    />
                </RadioGroup>
            </FormControl>

            <FormControl className={classNames(classes.formControl, classes.marginTop0)}>
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={displayManufacturer}
                    label={`Display "Product Line Manufacturer" on statement`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('displayManufacturer', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
            </FormControl>

            <FormControl className={classNames(classes.formControl, classes.marginTop0)}>
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={displayModelNumber}
                    label={`Display "Model Number" on statement`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('displayModelNumber', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
            </FormControl>

            <FormControl className={classNames(classes.formControl, classes.marginTop0)}>
                <GSwitch
                    formControlLabelClasses={classes.height34}
                    checked={displayTags}
                    label={`Display "Product Tag(s)" on statement`}
                    labelClasses={classNames(classes.colorSecondary, classes.gSwitchLabel)}
                    onChangeCallBack={(isChecked) => onStateChange('displayTags', isChecked)}
                    disabled={isGlobalProduct}
                    disableToggleOnTextClick
                />
            </FormControl>

            <FormControl className={classNames(
                classes.formControl, classes.marginBottom16, classes.marginTop0
            )}>
                <TextField
                    fullWidth
                    label="Persistent Text (shown under product name)"
                    helperText=""
                    multiline
                    InputProps={{
                        classes: {
                            root: classes.textAreaMinHeight,
                            inputMultiline: classes.textAreaMinHeight,
                        }
                    }}
                    value={persistentContractText || ''}
                    onChange={(e) => onStateChange('persistentContractText', e.target.value)}
                    disabled={isGlobalProduct}
                />
            </FormControl>
        </>
    );
};

export default withGStyles(saveProductStyles<Props>())(DisplayOptionsContent);
