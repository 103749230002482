import * as React from 'react';
import { timezoneLookup } from '../../services';
import { keys } from 'lodash';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Clear from '@mui/icons-material/Clear';

import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    btn: {},
    dRoot: {
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        cursor: 'pointer',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    header: {
        zIndex: 1,
        padding: 14
    },
    dialog: {
    },
    dialogPaper: {
    },
    timezoneSelectMenu: {
        '&:focus': {
            background: 'transparent',
        },
    },
    timezoneSelect: {
        '&:after': {
            borderBottom: '2px solid #64bbff',
        },
        '&:focus': {
            background: 'transparent',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid #64bbff !important',
        },
        '&:hover:not($disabled):before': {
            height: 0,
            backgroundColor: 'transparent',
        },
    },
    focused: {},
    disabled: {},
    error: {},
    selectForm: {
        textAlign: 'left',
        marginTop: 10,
        marginBottom: 20,
        width: '100%',
    },
    dialogContent: {
        width: 200,
    },
});

export interface Props {
    tzSelected: string;
    isDialogOpen: boolean;
    title: string;
    zIndex: number;
    closeDialog: () => void;
    onChange: (tzId: string) => void;
}

interface DialogProps {
    fullScreen: boolean;
}

export interface State {
}

type StyledProps = Props & WithGStyles<'root' | 'clearIcon' | 'header' | 'dialogPaper' | 'dialog'
    | 'dialogContent' | 'dRoot' | 'timezoneSelectMenu' | 'timezoneSelect' | 'selectForm'>;

class ChooseTimeZoneDialog extends React.Component<StyledProps & DialogProps, State> {
    handleClose = () => {
        this.props.closeDialog();
    };

    handleTimezoneChanged = (event: SelectChangeEvent<string>) => {
        const { onChange } = this.props;
        if (typeof event.target.value === 'string') {
            onChange(event.target.value);
        }
        this.handleClose();
    };

    renderTimezoneOptions = () => {
        const menuItems = keys(timezoneLookup).map((tz) => (
            <MenuItem key={tz} value={tz}>
                {timezoneLookup[tz]}
            </MenuItem>
        ));
        return menuItems;
    };

    render() {
        const {
            classes,
            isDialogOpen,
            fullScreen,
            tzSelected,
            title,
            zIndex,
        } = this.props;

        const timezoneKeys = keys(timezoneLookup);
        const selected = timezoneKeys.find(tz => tz === tzSelected);

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                onClose={this.handleClose}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                className={classes.dialog}
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.dRoot,
                }}
                maxWidth="xs"
                style={{ zIndex }}
            >
                <DialogTitle className={classes.header}>
                    <Clear
                        color="secondary"
                        className={classes.clearIcon}
                        onClick={this.handleClose}
                    />
                    <Typography
                        color="secondary"
                        component="p"
                        className={classes.fontSize20}
                        align="left"
                    >
                        <span className={classes.textCapitalize}>{title}</span>
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                        >
                            <FormControl className={classes.selectForm}>
                                <InputLabel
                                    htmlFor="timezone-selection"
                                >
                                    Timezone
                                </InputLabel>
                                <Select
                                    value={selected || ''}
                                    onChange={this.handleTimezoneChanged}
                                    className={classes.timezoneSelect}
                                    inputProps={{
                                        name: 'timezoneSelection',
                                        id: 'timezone-selection',
                                    }}
                                    MenuProps={{
                                        style: {
                                            zIndex: zIndex + 1,
                                        }
                                    }}
                                    classes={{ select: classes.timezoneSelectMenu }}
                                >
                                    {this.renderTimezoneOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withGStyles(styles)(withFullScreen('xs')(ChooseTimeZoneDialog));
