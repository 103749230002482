import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useState } from "react";
import { Payer } from ".";
import { ORANGE_COLOR } from "../../../../constants/colorVariables";
import { getFormattedPhoneNumber } from "../../../../services";
import { PaymentRequest } from "../../../../shared/types";
import { useGSelector } from "../../../../types";
import GSwitch from "../../../common/inputElements/GSwitch";
import BaseOnlinePaymentDialog from "./baseOnlinePaymentDialog/BaseOnlinePayment.dialog";

const useStyles = makeStyles<Theme>((theme) => ({
    orangeText: {
        color: ORANGE_COLOR,
    },
    payerEmail: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    sendReceiptSwitch: {
        maxHeight: '24px',
        display: 'flex',
        color: theme.palette.secondary.main,
        alignItems: 'center',
        width: 'fit-content',
        margin: '0 auto',
        fontSize: 16,
        overflowY: 'hidden',
    },
    gSwitch: {
        width: '32px',
        paddingRight: '10px',
    },
}), { name: 'CaseOnlinePaymentDialog' });

interface Props {
    isDialogOpen: boolean;
    paymentInfo: PaymentRequest;
    isRestricted: boolean;
    payer: Payer;
    caseUuid: string;
    updatePayment: (paymentInfo: PaymentRequest) => void;
    closeDialog: (activeReceipt: number | null) => void;
    openMySettingsDialog: () => void;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    updateSentNotification: (sentNotification: boolean) => void;
    zIndex: number;
}

const CaseOnlinePaymentDialog = (props: Props) => {
    const {
        isDialogOpen,
        paymentInfo,
        isRestricted,
        payer,
        caseUuid,
        updatePayment,
        closeDialog,
        openMySettingsDialog,
        onAddNonUserContactInfoClick,
        updateSentNotification,
        zIndex,
    } = props;

    const classes = useStyles();
    const [sendNotification, setSendNotification] = useState(true);

    const onlineChargeState = useGSelector(({ financeState }) => financeState.onlineChargeState);

    const payerEmail = payer.payerEntity.email;
    const payerPhone = payer.payerEntity.phone;

    const isNonUser = !payer.payerEntity.user_profile_id;
    const hasEmailorPhone = payerEmail || getFormattedPhoneNumber(payerPhone);
    const sendReceiptText = isNonUser
        ? 'RECEIPT WILL NOT BE SENT UNLESS PAYER IS INVITED'
        : sendNotification && hasEmailorPhone
            ? 'Receipt will be sent to '
            : 'A receipt will NOT be sent. ';

    const dialogFooter = () => {
        return (
            <>
                <Typography color="secondary" className={classNames(
                    classes.fontSize12,
                    (hasEmailorPhone && !sendNotification) && classes.orangeText,
                    classes.marginBottom8,
                )}>
                    {sendReceiptText}
                    {sendNotification && !isNonUser &&
                        <span
                            className={classes.payerEmail}
                            onClick={e => {
                                closeDialog(null);
                                if (!!hasEmailorPhone) {
                                    openMySettingsDialog();
                                } else {
                                    onAddNonUserContactInfoClick(payer.payerEntity.id);
                                }
                            }}
                        >
                            {(hasEmailorPhone) || 'Click to add contact information.'}
                        </span>
                    }
                </Typography>

                {!isRestricted && !isNonUser &&
                    <div className={classes.sendReceiptSwitch}>
                        <div className={classes.gSwitch}>
                            <GSwitch
                                checked={!sendNotification}
                                onChangeCallBack={(ischecked) => setSendNotification(!ischecked)}
                                id="send-receipt-switch"
                            />
                        </div>
                        <label htmlFor="send-receipt-switch" className={classes.cursorPointer}>
                            Don't send a receipt
                        </label>
                    </div>
                }
            </>
        );
    };

    const openEditUserDialog = () => {
        if (!payerEmail && !payerPhone) {
            if (onAddNonUserContactInfoClick) {
                onAddNonUserContactInfoClick(payer.payerEntity.id);
            }
        } else {
            openMySettingsDialog();
        }
    };

    return (
        <BaseOnlinePaymentDialog
            isDialogOpen={isDialogOpen}
            paymentInfo={paymentInfo}
            isRestricted={isRestricted}
            payer={payer}
            caseUuid={caseUuid}
            updatePayment={updatePayment}
            closeDialog={closeDialog}
            openMySettingsDialog={openEditUserDialog}
            updateSentNotification={updateSentNotification}
            zIndex={zIndex}
            dialogFooter={dialogFooter()}
            sendNotification={sendNotification}
            onlineChargeState={onlineChargeState}
        />
    );
};

export default CaseOnlinePaymentDialog;
