import Typography from '@mui/material/Typography';
import { useGStyles } from '../../../../../common/hooks/useGStyles';
import StepComponentWrapper from '../StepComponentWrapper';

interface Props {
    finePrint: string | undefined | null;
}

const FinePrintComponent = (props: Props) => {
    const {
        finePrint,
    } = props;

    const classes = useGStyles();

    if (!finePrint || !finePrint.trim()) {
        return null;
    }

    return (
        <StepComponentWrapper
            hideBorder
            className={classes.padding_0_12}>
            <Typography
                color="secondary"
                className={classes.fontSize12}
            >
                {finePrint}
            </Typography>
        </StepComponentWrapper>
    );
};

export default FinePrintComponent;
