import classNames from 'classnames';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import EventIcon from '@mui/icons-material/Event';

import { GatherEvent, TaskTemplateType } from '../../../../shared/types';
import { ParentComponentType, ParentComponentTypeEnum } from '..';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import { getIntercomTargetProp } from '../../../../services';

interface Props {
    isFamilyUser?: boolean | null;
    hasEvents?: boolean;
    parentComponent?: ParentComponentType;
    hasArrangementEvent: boolean;
    openScheduleServicesDialog: (event: GatherEvent | null) => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    lastGridList: {
        minWidth: 16,
        width: 16,
        color: 'transparent'
    },
    gridListTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
        margin: '0 13px'
    },
    gridListTile: {
        margin: '0 auto',
    },
    buttonBoxShadow: {
        boxShadow: `${theme.shadows[5]} !important`,
        borderRadius: 8,
    },
    buttonLabel: {
        fontSize: 12,
        fontWeight: 200,
        width: '100%',
        display: 'inline-block',
        textTransform: 'capitalize',
        lineHeight: 1,
        margin: '5px 0 0'
    },
    button: {
        width: 72,
        height: 72,
        padding: 0,
        borderRadius: 8
    },
    taskCardButton: {
        margin: 4,
        '@media (min-width: 340px)': {
            margin: 8,
        }
    },
    addIcon: {
        height: 30,
        width: 30,
        color: '#fff',
        display: 'block',
        margin: '5px auto 0'
    },
});

type StyledProps = Props & WithGStyles<'root' | 'lastGridList' | 'gridListTitle' | 'gridListTile'
    | 'buttonBoxShadow' | 'buttonLabel' | 'button' | 'taskCardButton' | 'addIcon'>;

const AddEventButton = (props: StyledProps & Props) => {
    const {
        classes,
        openScheduleServicesDialog,
        hasEvents,
        isFamilyUser,
        parentComponent,
        hasArrangementEvent
    } = props;
    const renderDefaultButton = () => {
        return (
            <div {...getIntercomTargetProp(`Dashboard-ScheduleServicesButton`)}>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    aria-haspopup="true"
                    onClick={e => openScheduleServicesDialog(null)}
                >
                    <span className={classes.buttonLabel}>
                        Schedule Services
                        <EventIcon className={classes.addIcon} />
                    </span>
                </Button>
            </div>
        );
    };

    const handleScheduleServicesClick = () => {
        if (!hasArrangementEvent) {
            return;
        }
        openScheduleServicesDialog(null);
    };

    const renderTaskCardButton = () => {
        return (
            <Button
                className={classes.taskCardButton}
                size="large"
                color="primary"
                variant="contained"
                aria-haspopup="true"
                onClick={e => handleScheduleServicesClick()}
                disabled={Boolean(isFamilyUser) && !hasEvents}
            >
                Schedule Services
            </Button>
        );
    };

    const renderTaskCardLink = () => {
        return (
            <a
                onClick={e => openScheduleServicesDialog(null)}
            >
                Schedule Services
            </a>
        );
    };

    let eventButton: JSX.Element = renderDefaultButton();
    let isDefaultButton: boolean = true;

    switch (parentComponent) {
        case ParentComponentTypeEnum.DETAIL_TASKCARD:
            return renderTaskCardLink();
        case TaskTemplateType.schedule_services:
            eventButton = renderTaskCardButton();
            isDefaultButton = false;
            break;
        default:
            break;
    }

    return (
        // TODO(Jonathan): Look to replace this potentially with a List/ListItem
        <ImageListItem
            className={classNames(
                classes.gridListTitle,
                isDefaultButton ? classes.lastGridList : '',
                isDefaultButton ? classes.buttonBoxShadow : '',
                classes.textCenter
            )}
            classes={{
                img: classes.gridListTile,
            }}
        >
            {eventButton}
        </ImageListItem>
    );
};

export default withGStyles(styles)(AddEventButton);
