import {
    WorkflowCreateRequest,
    WorkflowUX,
    WorkflowUpdateRequest,
    WorkflowSummary,
    TaskTemplateSummary,
    WorkflowTaskUpdateRequest,
    WorkflowTaskUpdateRankRequest,
} from '../shared/types';
import { getFromAPI, postToAPI, deleteFromAPI, patchAPI, putToAPI } from '.';
import { registerAppError } from './errors';
import { AppDispatch } from '../store';
import { log } from '../logger';

// ----> Create Workflow <----
export const CREATING_WORKFLOW = 'CREATING_WORKFLOW';

interface CreatingWorkflow {
    type: typeof CREATING_WORKFLOW;
    optimisticWorkflow: WorkflowSummary;
}

function creatingWorkflow(optimisticWorkflow: WorkflowSummary): CreatingWorkflow {
    return {
        type: CREATING_WORKFLOW,
        optimisticWorkflow,
    };
}

export const CREATED_WORKFLOW = 'CREATED_WORKFLOW';

interface CreatedWorkflow {
    type: typeof CREATED_WORKFLOW;
    idToReplace: number;
    workflow: WorkflowSummary;
}

function createdWorkflow(idToReplace: number, workflow: WorkflowSummary): CreatedWorkflow {
    return {
        type: CREATED_WORKFLOW,
        idToReplace,
        workflow,
    };
}

export const CREATING_WORKFLOW_FAILED = 'CREATING_WORKFLOW_FAILED';

interface CreatingWorkflowFailed {
    type: typeof CREATING_WORKFLOW_FAILED;
    idToRemove: number;
}

function creatingWorkflowFailed(idToRemove: number): CreatingWorkflowFailed {
    return {
        type: CREATING_WORKFLOW_FAILED,
        idToRemove,
    };
}

export function createWorkflow(
    workflow: WorkflowCreateRequest,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<WorkflowSummary | null> => {
        try {
            WorkflowCreateRequest.fromRequest(workflow);
        } catch (ex) {
            log.warn('Failed to validate WorkflowCreateRequest:', { workflow, ex });
            return null;
        }

        const optimisticId: number = -(new Date().getSeconds());
        const optimisticWorkflow: WorkflowSummary = {
            ...workflow,
            id: optimisticId,
            rank: 1000, // put it at the end of the list
            selected_checklist_tasks: [],
            selected_tracking_steps: [],
            created_by: 0,
            updated_by: 0,
            updated_time: new Date(),
            created_time: new Date(),
            cloned_from: null,
            fhs_mirrored_with: [],
        };

        dispatch(creatingWorkflow(optimisticWorkflow));
        const route = `funeralhome/${funeralHomeId}/workflow`;
        const newWorkflow = await postToAPI<WorkflowSummary>(route, { workflow }, dispatch);
        if (newWorkflow) {
            dispatch(createdWorkflow(optimisticId, newWorkflow));
            return newWorkflow;
        }
        dispatch(creatingWorkflowFailed(optimisticId));
        dispatch(registerAppError('Unable to create new workflow.'));
        return null;
    };
}

// ----> Adding Task to Workflow <----
export const ADDING_TASK_TO_WORKFLOW = 'ADDING_TASK_TO_WORKFLOW';

interface AddingTaskToWorkflow {
    type: typeof ADDING_TASK_TO_WORKFLOW;
    workflowId: number;
    task: TaskTemplateSummary;
}

function addingTaskToWorkflow(workflowId: number, task: TaskTemplateSummary): AddingTaskToWorkflow {
    return {
        type: ADDING_TASK_TO_WORKFLOW,
        workflowId,
        task,
    };
}

export function addTaskToWorkflow(params: {
    workflowId: number;
    funeralHomeId: number;
    task: TaskTemplateSummary;
}) {
    return async (dispatch: AppDispatch): Promise<WorkflowUX | null> => {
        const { workflowId, funeralHomeId, task } = params;

        dispatch(addingTaskToWorkflow(workflowId, task));
        const route = `funeralhome/${funeralHomeId}/workflow/${workflowId}/task/${task.id}`;
        const workflow = await putToAPI<WorkflowUX>(route, {}, dispatch);
        if (workflow) {
            dispatch(updatedWorkflow(workflow));
            return workflow;
        }
        dispatch(registerAppError('Unable to update workflow.'));
        return null;
    };
}

// ----> Removing Task from Workflow <----
export const REMOVING_TASK_FROM_WORKFLOW = 'REMOVING_TASK_FROM_WORKFLOW';

interface RemovingTaskFromWorkflow {
    type: typeof REMOVING_TASK_FROM_WORKFLOW;
    workflowId: number;
    workflowTaskId: number;
}

function removingTaskFromWorkflow(workflowId: number, workflowTaskId: number): RemovingTaskFromWorkflow {
    return {
        type: REMOVING_TASK_FROM_WORKFLOW,
        workflowId,
        workflowTaskId,
    };
}

export function removeTaskFromWorkflow(params: {
    workflowId: number;
    funeralHomeId: number;
    workflowTaskId: number;
}) {
    return async (dispatch: AppDispatch): Promise<WorkflowUX | null> => {
        const { workflowId, funeralHomeId, workflowTaskId } = params;

        dispatch(removingTaskFromWorkflow(workflowId, workflowTaskId));
        const route = `funeralhome/${funeralHomeId}/workflow/${workflowId}/workflowtask/${workflowTaskId}`;
        const workflow = await deleteFromAPI<WorkflowUX>(route, dispatch);
        if (workflow) {
            dispatch(updatedWorkflow(workflow));
            return workflow;
        }
        dispatch(registerAppError('Unable to update workflow.'));
        return null;
    };
}

// ----> Updating Workflow Task <----
export const UPDATING_WORKFLOW_TASK = 'UPDATING_WORKFLOW_TASK';

interface UpdatingWorkflowTask {
    type: typeof UPDATING_WORKFLOW_TASK;
    workflowId: number;
    workflowTaskId: number;
    changes: WorkflowTaskUpdateRequest;
}

function updatingWorkflowTask(
    workflowId: number,
    workflowTaskId: number,
    changes: WorkflowTaskUpdateRequest,
): UpdatingWorkflowTask {
    return {
        type: UPDATING_WORKFLOW_TASK,
        workflowId,
        workflowTaskId,
        changes,
    };
}

export function updateWorkflowTask(params: {
    workflowTaskId: number;
    workflowTask: WorkflowTaskUpdateRequest;
    workflowId: number;
    funeralHomeId: number;
}) {
    return async (dispatch: AppDispatch): Promise<WorkflowUX | null> => {
        const { workflowId, funeralHomeId, workflowTaskId, workflowTask } = params;

        try {
            WorkflowTaskUpdateRequest.fromRequest(workflowTask);
        } catch (ex) {
            log.warn('Failed to validate WorkflowTaskUpdateRequest:', { workflowTask, ex });
            return null;
        }

        dispatch(updatingWorkflowTask(workflowId, workflowTaskId, workflowTask));
        const route = `funeralhome/${funeralHomeId}/workflow/${workflowId}/workflowtask/${workflowTaskId}`;
        const workflow = await patchAPI<WorkflowUX>(route, { workflowTask }, dispatch);
        if (workflow) {
            dispatch(updatedWorkflow(workflow));
            return workflow;
        }
        dispatch(registerAppError('Unable to update workflow.'));
        return null;
    };
}

export const WORKFLOW_STEP_RANKS_UPDATED = 'WORKFLOW_STEP_RANKS_UPDATED';

interface WorkflowStepRanksUpdated {
    type: typeof WORKFLOW_STEP_RANKS_UPDATED;
    workflowTaskIds: number[];
}

export function workflowStepRanksUpdated(workflowTaskIds: number[]): WorkflowStepRanksUpdated {
    return {
        type: WORKFLOW_STEP_RANKS_UPDATED,
        workflowTaskIds,
    };
}

export const WORKFLOW_TASK_RANKS_UPDATED = 'WORKFLOW_TASK_RANKS_UPDATED';

interface WorkflowTaskRanksUpdated {
    type: typeof WORKFLOW_TASK_RANKS_UPDATED;
    workflowTaskIds: number[];
}

export function workflowTaskRanksUpdated(workflowTaskIds: number[]): WorkflowTaskRanksUpdated {
    return {
        type: WORKFLOW_TASK_RANKS_UPDATED,
        workflowTaskIds,
    };
}

export function updateWorkflowTaskRanks(params: {
    workflowTaskIds: number[];
    workflowId: number;
    funeralHomeId: number;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { workflowId, funeralHomeId, workflowTaskIds } = params;

        const updateRequest: WorkflowTaskUpdateRankRequest = {
            workflow_task_ids: workflowTaskIds,
        };

        try {
            WorkflowTaskUpdateRankRequest.fromRequest(updateRequest);
        } catch (ex) {
            log.warn('Failed to validate WorkflowTaskUpdateRankRequest', { updateRequest, ex });
            return;
        }

        const route = `funeralhome/${funeralHomeId}/workflow/${workflowId}/workflowtask/rank`;
        const response = await postToAPI<void>(route, updateRequest, dispatch);
        if (response === null) {
            dispatch(registerAppError('Unable to update workflow.'));
        }
    };
}

// ----> Update Workflow <----
export const UPDATING_WORKFLOW = 'UPDATING_WORKFLOW';

interface UpdatingWorkflow {
    type: typeof UPDATING_WORKFLOW;
    workflowId: number;
    changes: WorkflowUpdateRequest;
}

function updatingWorkflow(workflowId: number, changes: WorkflowUpdateRequest): UpdatingWorkflow {
    return {
        type: UPDATING_WORKFLOW,
        workflowId,
        changes,
    };
}

export const UPDATED_WORKFLOW = 'UPDATED_WORKFLOW';

interface UpdatedWorkflow {
    type: typeof UPDATED_WORKFLOW;
    updatedWorkflow: WorkflowUX;
}

function updatedWorkflow(workflow: WorkflowUX): UpdatedWorkflow {
    return {
        type: UPDATED_WORKFLOW,
        updatedWorkflow: workflow,
    };
}

export const UPDATING_WORKFLOW_FAILED = 'UPDATING_WORKFLOW_FAILED';

interface UpdatingWorkflowFailed {
    type: typeof UPDATING_WORKFLOW_FAILED;
}

function updatingWorkflowFailed(
): UpdatingWorkflowFailed {
    return {
        type: UPDATING_WORKFLOW_FAILED,
    };
}

export function updateWorkflow(params: {
    workflowId: number;
    funeralHomeId: number;
    changes: WorkflowUpdateRequest;
}) {
    return async (dispatch: AppDispatch): Promise<WorkflowUX | null> => {
        const { workflowId, funeralHomeId, changes } = params;
        try {
            WorkflowUpdateRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate WorkflowUpdateRequest:', { changes, ex });
            return null;
        }

        dispatch(updatingWorkflow(workflowId, changes));
        const route = `funeralhome/${funeralHomeId}/workflow/${workflowId}`;
        const workflow = await patchAPI<WorkflowUX>(route, { workflow: changes }, dispatch);
        if (workflow) {
            dispatch(updatedWorkflow(workflow));
            return workflow;
        }
        dispatch(updatingWorkflowFailed());
        dispatch(registerAppError('Unable to update workflow.'));
        return null;
    };
}

// ----> Delete Workflow <----
export const DELETING_WORKFLOW = 'DELETING_WORKFLOW';

interface DeletingWorkflow {
    type: typeof DELETING_WORKFLOW;
    idToRemove: number;
}

function deletingWorkflow(idToRemove: number): DeletingWorkflow {
    return {
        type: DELETING_WORKFLOW,
        idToRemove,
    };
}

export const DELETED_WORKFLOW = 'DELETED_WORKFLOW';

interface DeletedWorkflow {
    type: typeof DELETED_WORKFLOW;
}

function deletedWorkflow(): DeletedWorkflow {
    return {
        type: DELETED_WORKFLOW,
    };
}

export const DELETING_WORKFLOW_FAILED = 'DELETING_WORKFLOW_FAILED';

interface DeletingWorkflowFailed {
    type: typeof DELETING_WORKFLOW_FAILED;
    workflowToRestore: WorkflowSummary;
}

function deletingWorkflowFailed(workflowToRestore: WorkflowSummary): DeletingWorkflowFailed {
    return {
        type: DELETING_WORKFLOW_FAILED,
        workflowToRestore,
    };
}

export function deleteWorkflow(workflow: WorkflowSummary, funeralHomeId: number) {
    return async (
        dispatch: AppDispatch,
    ): Promise<WorkflowUX | null> => {


        dispatch(deletingWorkflow(workflow.id));
        const route = `funeralhome/${funeralHomeId}/workflow/${workflow.id}`;
        const delWorkflow = await deleteFromAPI<WorkflowUX>(route, dispatch);
        if (delWorkflow) {
            dispatch(deletedWorkflow());
            return delWorkflow;
        }
        dispatch(deletingWorkflowFailed(workflow));
        dispatch(registerAppError('Unable to delete workflow.'));
        return null;
    };
}

// ----> Load Workflows <----
export const LOADING_WORKFLOWS = 'LOADING_WORKFLOWS';

interface LoadingWorkflows {
    type: typeof LOADING_WORKFLOWS;
}

function loadingWorkflows(): LoadingWorkflows {
    return {
        type: LOADING_WORKFLOWS,
    };
}

export const LOADED_WORKFLOWS = 'LOADED_WORKFLOWS';

export interface LoadedWorkflows {
    type: typeof LOADED_WORKFLOWS;
    workflows: WorkflowSummary[];
}

function loadedWorkflows(
    workflows: WorkflowSummary[],
): LoadedWorkflows {
    return {
        type: LOADED_WORKFLOWS,
        workflows,
    };
}

export const LOADING_WORKFLOWS_FAILED = 'LOADING_WORKFLOWS_FAILED';

interface LoadingWorkflowsFailed {
    type: typeof LOADING_WORKFLOWS_FAILED;
}

function loadingWorkflowsFailed(): LoadingWorkflowsFailed {
    return {
        type: LOADING_WORKFLOWS_FAILED,
    };
}

// Load the workflows for the funeral home ID provided.
// This will not store the resulting workflows in the Redux store.
// It is used when we want to load workflows but use local state rather than Redux for storing them
export function loadWorkflowsApi(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<WorkflowSummary[] | null> => {
        const route = `funeralhome/${funeralHomeId}/workflow/`;
        const workflows = await getFromAPI<WorkflowSummary[]>(route, dispatch);
        return workflows;
    };
}

// Load the workflows for the funeral home ID provided and store them in Redux
// This differs from loadWorkflowsApi in that it will store the workflows in the Redux store
// It should only be used when the active Funeral Home in Redux is the same as the funeralHomeId provided
export function loadWorkflows(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<WorkflowSummary[] | null> => {
        dispatch(loadingWorkflows());
        const workflows = await dispatch(loadWorkflowsApi(funeralHomeId));
        if (workflows) {
            dispatch(loadedWorkflows(workflows));
            return workflows;
        } else {
            dispatch(loadingWorkflowsFailed());
            dispatch(registerAppError('Unable to load workflows.'));
            return null;
        }
    };
}

// ----> Load Workflow <----
export const LOADING_WORKFLOW = 'LOADING_WORKFLOW';

interface LoadingWorkflow {
    type: typeof LOADING_WORKFLOW;
}

function loadingWorkflow(): LoadingWorkflow {
    return {
        type: LOADING_WORKFLOW,
    };
}

export const LOADED_WORKFLOW = 'LOADED_WORKFLOW';

export interface LoadedWorkflow {
    type: typeof LOADED_WORKFLOW;
    workflow: WorkflowUX;
}

function loadedWorkflow(
    workflow: WorkflowUX,
): LoadedWorkflow {
    return {
        type: LOADED_WORKFLOW,
        workflow,
    };
}

export const LOADING_WORKFLOW_FAILED = 'LOADING_WORKFLOW_FAILED';

interface LoadingWorkflowFailed {
    type: typeof LOADING_WORKFLOW_FAILED;
}

function loadingWorkflowFailed(): LoadingWorkflowFailed {
    return {
        type: LOADING_WORKFLOW_FAILED,
    };
}

export function loadWorkflow(funeralHomeId: number, workflowId: number) {
    return async (dispatch: AppDispatch): Promise<WorkflowUX | null> => {
        dispatch(loadingWorkflow());
        const route = `funeralhome/${funeralHomeId}/workflow/${workflowId}`;
        let workflow = await getFromAPI<WorkflowUX>(route, dispatch);
        if (workflow) {
            dispatch(loadedWorkflow(workflow));
            return workflow;
        } else {
            dispatch(loadingWorkflowFailed());
            dispatch(registerAppError('Unable to load workflow.'));
            return null;
        }
    };
}

// ----> Update Workflows Ranks <----
export const UPDATING_WORKFLOWS_RANKS = 'UPDATING_WORKFLOWS_RANKS';

interface UpdatingWorkflowsRanks {
    type: typeof UPDATING_WORKFLOWS_RANKS;
    optimisticWorkflows: WorkflowSummary[];
}

function updatingWorkflowsRanks(optimisticWorkflows: WorkflowSummary[]): UpdatingWorkflowsRanks {
    return {
        type: UPDATING_WORKFLOWS_RANKS,
        optimisticWorkflows,
    };
}

export const UPDATED_WORKFLOWS_RANKS = 'UPDATED_WORKFLOWS_RANKS';

interface UpdatedWorkflowsRanks {
    type: typeof UPDATED_WORKFLOWS_RANKS;
    updatedWorkflows: WorkflowSummary[];
}

function updatedWorkflowsRanks(updatedWorkflows: WorkflowSummary[]): UpdatedWorkflowsRanks {
    return {
        type: UPDATED_WORKFLOWS_RANKS,
        updatedWorkflows,
    };
}

export const UPDATING_WORKFLOWS_RANKS_FAILED = 'UPDATING_WORKFLOWS_RANKS_FAILED';

interface UpdatingWorkflowsRanksFailed {
    type: typeof UPDATING_WORKFLOWS_RANKS_FAILED;
    rollbackWorkflows: WorkflowSummary[];
}

function updatingWorkflowsRanksFailed(
    rollbackWorkflows: WorkflowSummary[],
): UpdatingWorkflowsRanksFailed {
    return {
        type: UPDATING_WORKFLOWS_RANKS_FAILED,
        rollbackWorkflows,
    };
}
export function updateWorkflowsRanks(
    workflows: WorkflowSummary[],
    existingWorkflows: WorkflowSummary[],
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<WorkflowSummary[] | null> => {
        const workflowIds = workflows.map((workflow) => workflow.id);
        const route = `funeralhome/${funeralHomeId}/workflow/rank`;

        // Temporary setting the ranking to show in correct order on UI
        const workflowsToUpdate = workflows.map((workflow, idx) => ({ ...workflow, rank: idx + 1 }));

        const untochedWorkflows = existingWorkflows
            .filter(workflow => !workflows.find(w => workflow.id === w.id));

        // Saving the existing unsorted workflows to the list so that they don't get removed on UI on sorting
        dispatch(updatingWorkflowsRanks([...workflowsToUpdate, ...untochedWorkflows]));
        const updatedWorkflows = await postToAPI<WorkflowSummary[]>(route, { workflowIds }, dispatch);
        if (updatedWorkflows) {
            dispatch(updatedWorkflowsRanks(updatedWorkflows));
            return updatedWorkflows;
        }
        dispatch(updatingWorkflowsRanksFailed(existingWorkflows));
        dispatch(registerAppError('Unable to update workflow order.'));
        return null;
    };
}

export type WorkflowAction =
    | CreatingWorkflow
    | CreatingWorkflowFailed
    | CreatedWorkflow
    | UpdatingWorkflow
    | UpdatingWorkflowFailed
    | UpdatedWorkflow
    | DeletingWorkflow
    | DeletingWorkflowFailed
    | DeletedWorkflow
    | AddingTaskToWorkflow
    | UpdatingWorkflowTask
    | RemovingTaskFromWorkflow
    | WorkflowStepRanksUpdated
    | WorkflowTaskRanksUpdated
    | LoadingWorkflows
    | LoadingWorkflowsFailed
    | LoadedWorkflows
    | LoadingWorkflow
    | LoadingWorkflowFailed
    | LoadedWorkflow
    | UpdatingWorkflowsRanks
    | UpdatingWorkflowsRanksFailed
    | UpdatedWorkflowsRanks
    ;
