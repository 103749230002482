import * as t from 'io-ts';
import generateUuid from 'uuid';
import { S3FileCreateRequest, S3FileCreateRequestType, S3FileRecord } from './doc';
import { getValidator } from './utils';

export enum FingerprintType {
    fingerprint = 'fingerprint',
    right_thumb = 'right_thumb',
    right_index_finger = 'right_index_finger',
    right_middle_finger = 'right_middle_finger',
    right_ring_finger = 'right_ring_finger',
    right_pinky_finger = 'right_pinky_finger',
    left_thumb = 'left_thumb',
    left_index_finger = 'left_index_finger',
    left_middle_finger = 'left_middle_finger',
    left_ring_finger = 'left_ring_finger',
    left_pinky_finger = 'left_pinky_finger',
    other_print = 'other_print',
    child_fingerprint = 'child_fingerprint',
    child_handprint = 'child_handprint',
    child_footprint = 'child_footprint',
    dog_pawprint = 'dog_pawprint',
    dog_noseprint = 'dog_noseprint',
    cat_pawprint = 'cat_pawprint',
    other_animal_print = 'other_animal_print',
}

export const FingerPrintDisplay = {
    [FingerprintType.fingerprint]: 'Fingerprint',
    [FingerprintType.right_thumb]: 'Right Thumb',
    [FingerprintType.right_index_finger]: 'Right Index Finger',
    [FingerprintType.right_middle_finger]: 'Right Middle Finger',
    [FingerprintType.right_ring_finger]: 'Right Ring Finger',
    [FingerprintType.right_pinky_finger]: 'Right Pinky Finger',
    [FingerprintType.left_thumb]: 'Left Thumb',
    [FingerprintType.left_index_finger]: 'Left Index Finger',
    [FingerprintType.left_middle_finger]: 'Left Middle Finger',
    [FingerprintType.left_ring_finger]: 'Left Ring Finger',
    [FingerprintType.left_pinky_finger]: 'Left Pinky Finger',
    [FingerprintType.other_print]: 'Other Print',
    [FingerprintType.child_fingerprint]: 'Child Fingerprint',
    [FingerprintType.child_handprint]: 'Child Handprint',
    [FingerprintType.child_footprint]: 'Child Footprint',
    [FingerprintType.dog_pawprint]: 'Dog Pawprint',
    [FingerprintType.dog_noseprint]: 'Dog Noseprint',
    [FingerprintType.cat_pawprint]: 'Cat Pawprint',
    [FingerprintType.other_animal_print]: 'Other Animal Print',
};

export interface FingerprintRecord {
    type: FingerprintType;
    gather_case_id: number;
    s3_file_id: number;
    s3_orig_file_id: number;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
}

export interface FingerprintUXRecord extends FingerprintRecord {
    created_by_name: string;
    s3_file: S3FileRecord;
}

export interface FingerprintUX extends Omit<FingerprintUXRecord, 's3_file'> {
    photo_url: string;
}

export const FINGER_PRINT_TYPE = t.union([
    t.literal(FingerprintType.fingerprint),
    t.literal(FingerprintType.right_thumb),
    t.literal(FingerprintType.right_index_finger),
    t.literal(FingerprintType.right_middle_finger),
    t.literal(FingerprintType.right_ring_finger),
    t.literal(FingerprintType.right_pinky_finger),
    t.literal(FingerprintType.left_thumb),
    t.literal(FingerprintType.left_index_finger),
    t.literal(FingerprintType.left_middle_finger),
    t.literal(FingerprintType.left_ring_finger),
    t.literal(FingerprintType.left_pinky_finger),
    t.literal(FingerprintType.other_print),
    t.literal(FingerprintType.child_fingerprint),
    t.literal(FingerprintType.child_handprint),
    t.literal(FingerprintType.child_footprint),
    t.literal(FingerprintType.dog_pawprint),
    t.literal(FingerprintType.dog_noseprint),
    t.literal(FingerprintType.cat_pawprint),
    t.literal(FingerprintType.other_animal_print),
]);

const fingerPrintCreateRequestDefinition = {
    s3_file: S3FileCreateRequestType,
    s3_orig_file: S3FileCreateRequestType,
    type: FINGER_PRINT_TYPE,
};
const FingerPrintCreateRequestType = t.type(fingerPrintCreateRequestDefinition);

export interface FingerPrintCreateRequest extends t.TypeOf<typeof FingerPrintCreateRequestType> {
    type: FingerprintType;
    s3_file: S3FileCreateRequest;
    s3_orig_file: S3FileCreateRequest;
}

export class FingerPrintCreateRequest {
    public static fromRequest = getValidator<FingerPrintCreateRequest>(FingerPrintCreateRequestType);
}

const fingerPrintUpdateRequestDefinition = {
    type: FINGER_PRINT_TYPE,
};
const FingerPrintUpdateRequestType = t.type(fingerPrintUpdateRequestDefinition);

export interface FingerPrintUpdateRequest extends t.TypeOf<typeof FingerPrintUpdateRequestType> {
    type: FingerprintType;
}

export class FingerPrintUpdateRequest {
    public static fromRequest = getValidator<FingerPrintUpdateRequest>(FingerPrintUpdateRequestType);
}


export interface CaseFingerprintUX {
    uuid: string;
    fingerprint: FingerprintUX | null;
    uploading: boolean;
}

export function fingerprintToCaseFingerprint(fingerprint: FingerprintUX): CaseFingerprintUX {
    return {
        uuid: generateUuid(),
        fingerprint,
        uploading: false
    };
}

export function isFingerprintType(val: string): val is FingerprintType {
    return val in FingerprintType;
}