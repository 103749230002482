import { Component } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActionArea from '@mui/material/CardActionArea';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import EditIcon from '@mui/icons-material/Edit';

import ClearIcon from '@mui/icons-material/Clear';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';
import { AppRoute } from '../../../../services';
import GLink from '../../../common/GLink';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
                minHeight: 416,
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
        '@media (min-width: 712px)': {
            padding: '0 16px',
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: theme.palette.common.white,
        filter: 'drop-shadow(0 0px 2px gray)',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        fontSize: 24,
        textAlign: 'center',
        color: theme.palette.common.white,
    },
    optionContainer: {
        '& $label': {
            margin: '32px 0 8px',
            fontSize: 18,
            fontWeight: 200,
            textAlign: 'center'
        },
        '& $inner': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            overflow: 'auto',
            paddingBottom: 24,
            '@media (min-width: 680px)': {
                justifyContent: 'center',
            },
            '& $last': {
                width: 1,
                height: 1,
                minWidth: 1,
            },
        },
        '& $card': {
            width: 320,
            height: 145,
            minWidth: 320,
            margin: '0 8px',
            borderRadius: 4,
            color: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: 'none',
            position: 'relative',
            '&$disabled': {
                borderWidth: 0,
                background: 'rgba(0, 0, 0, 0.12)',
                color: theme.palette.secondary.main,
                '& $cardHeading': {
                    color: theme.palette.secondary.main
                }
            },
            '& $lockIcon': {
                position: 'absolute',
                top: 4,
                right: 4,
                fontSize: 16
            },
            '& button': {
                height: '100%',
            },
            '& p': {
                color: theme.palette.secondary.main
            },
            '&:hover:not($disabled)': {
                boxShadow: theme.shadows[10],
            },
            '& $cardHeading': {
                fontSize: 18,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 16px',
                borderBottom: '1px solid',
                padding: '8px 0',
                color: theme.palette.primary.main,
                '& svg': {
                    fontSize: 28,
                },
            },
            '& $cardContent': {
                padding: '8px 8px 16px',
                '& p': {
                    fontWeight: 200,
                    textAlign: 'center',
                },
            },
        },
    },
    headerIcon: {
        color: theme.palette.common.white,
        fontSize: 88,
    },
    disabled: {},
    last: {},
    lockIcon: {},
    inner: {},
    label: {},
    card: {},
    cardHeader: {},
    cardHeading: {},
    cardContent: {},
    dialogPaper: {},
});

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogContent' | 'clearIcon' | 'heading'
    | 'optionContainer' | 'headerIcon' | 'last' | 'disabled' | 'dialogPaper'
    | 'card' | 'cardHeading' | 'cardContent'
    | 'label' | 'inner'>;

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    isDialogOpen: boolean;
    isContractFrozen: boolean;
    paymentLink: AppRoute;
    onAddToContract: () => void;
    onAddToPayments: () => void;
    closeDialog: () => void;
    zIndex: number;
}

interface State { }

const INITIAL_STATE: State = {};

type CombinedProps = DialogProps & StyledProps;
class ChooseItemDestination extends Component<CombinedProps, State> {
    state: State = INITIAL_STATE;

    renderOptions = () => {
        const {
            classes,
            closeDialog,
            paymentLink,
            onAddToContract,
            onAddToPayments,
            isContractFrozen,
        } = this.props;

        return (
            <Grid item xs={12} className={classes.optionContainer}>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.label,
                    )}
                >
                    Where would you like to add this item?
                </Typography>

                <div className={classes.inner}>
                    <DisabledPermissionTooltip permission={Permission.CONTRACT_UNFREEZE}>
                        {disabled =>
                            <Card className={classNames(classes.card, disabled && classes.disabled)}>
                                <CardActionArea
                                    disabled={isContractFrozen && disabled}
                                    onClick={() => {
                                        onAddToContract();
                                        closeDialog();
                                    }}
                                >
                                    <Typography className={classes.cardHeading}>
                                        Add Item to Statement
                                    </Typography>

                                    {!isContractFrozen ?
                                        <CardContent className={classes.cardContent}>
                                            <Typography>Statement will remain unfrozen</Typography>
                                        </CardContent>
                                        :
                                        <CardContent className={classes.cardContent}>
                                            <Typography>Statement will move to unfrozen</Typography>
                                            <Typography>New item will be tracked as a Statement revision</Typography>
                                            <Typography>You may need to obtain new signatures</Typography>
                                        </CardContent>
                                    }
                                </CardActionArea>
                            </Card>
                        }
                    </DisabledPermissionTooltip>

                    <DisabledPermissionTooltip permission={Permission.CONTRACT_ONE_OFF_ITEMS}>
                        {disabled =>
                            <Card className={classNames(classes.card, disabled && classes.disabled)}>
                                <CardActionArea
                                    disabled={disabled}
                                    onClick={() => {
                                        onAddToPayments();
                                        closeDialog();
                                    }}
                                >
                                    <GLink to={paymentLink}>
                                        <Typography className={classes.cardHeading}>
                                            Add Item Outside of Statement
                                        </Typography>
                                        <CardContent className={classes.cardContent}>
                                            <Typography>
                                                Statement will remain {isContractFrozen ? 'frozen' : 'unfrozen'}
                                            </Typography>
                                            <Typography>New item will be added to the Payment page</Typography>
                                            <Typography>Total amount due will be increased</Typography>
                                        </CardContent>
                                    </GLink>
                                </CardActionArea>
                            </Card>
                        }
                    </DisabledPermissionTooltip>

                    <div className={classes.last} />
                </div>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            fullScreen,
            isDialogOpen,
            closeDialog,
            isContractFrozen,
            zIndex
        } = this.props;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={e => closeDialog()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.dialogHeader}
                >
                    <ClearIcon
                        color="secondary"
                        className={classes.clearIcon}
                        onClick={e => closeDialog()}
                    />
                    {
                        isContractFrozen ? <AcUnitIcon className={classes.headerIcon} /> :
                            <EditIcon className={classes.headerIcon} />
                    }
                    <Typography
                        component="p"
                        className={classes.heading}
                    >
                        {`Statement is ${isContractFrozen ? 'Frozen' : 'Unfrozen'}`}
                    </Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {this.renderOptions()}
                </DialogContent>
            </Dialog >
        );
    }
}

export default withFullScreen()(withGStyles(styles)(ChooseItemDestination));
