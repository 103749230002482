import { DeathCertificateConfigUX, UserProfile, UserRoles, DeathCertificateConfigFieldUX } from '../../types';

export enum ConfigurableAboutFieldKey {
    gender = 'about.gender',
    pronouns = 'about.pronouns',
    hasMemorialService = 'about.hasMemorialService',
    aka = 'about.aka',
    ssn = 'about.ssn',
    ministerOfficiating = 'about.ministerOfficiating',
    pickUpAddress = 'about.pickUpAddress',
    dropOffAddress = 'about.dropOffAddress',
    facilityHasStairs = 'about.facilityHasStairs',
    numberOfCertifiedDCs = 'about.numberOfCertifiedDCs',
    casket = 'about.casket',
    insuranceDetails = 'about.insuranceDetails',
    hair = 'about.hair',
    facialHair = 'about.facialHair',
    nails = 'about.nails',
    lipstickColor = 'about.lipstickColor',
    medicalExaminerReleaseNumber = 'about.medicalExaminerReleaseNumber',
    clothingDueBy = 'about.clothingDueBy',
    referredToUsBy = 'about.referredToUsBy',
    suffix = 'about.suffix',
    birthLastName = 'about.birthLastName',
    hobbies = 'about.hobbies',
    lodges = 'about.lodges',
    clubs = 'about.clubs',
    offices = 'about.offices',
    previousPlacesLived = 'about.previousPlaceLived',
    casketPanel = 'about.casketPanel',
    chapel = 'about.chapel',
    insurancePolicyNumber = 'about.insurancePolicyNumber',
    weight = 'about.weight',
    other = 'about.other',
    hasChildrenUnder18 = 'about.hasChildrenUnder18',
}

export enum ConfigurableLifeFieldKey {
    deathPlace = 'life.deathPlace',
    deathLocationOptions = 'life.deathLocationOptions',
    birthPlace = 'life.birthPlace',
    causeOfDeath = 'life.causeOfDeath',
    physicianName = 'life.physicianName',
    physicianAddress = 'life.physicianAddress',
    physicianPhoneNumber = 'life.physicianPhoneNumber',
    physicianFaxNumber = 'life.physicianFaxNumber',
    primaryResidence = 'life.primaryResidence',
    primaryResidenceInCityLimits = 'life.primaryResidenceInCityLimits',
    yearsLivedInCounty = 'life.yearsLivedInCounty',
    dateOfPronouncement = 'life.dateOfPronouncement',
    timeOfPronouncement = 'life.timeOfPronouncement',
    hebrewDateOfBirth = 'life.hebrewDateOfBirth',
    hebrewDateOfDeath = 'life.hebrewDateOfDeath',
}

export enum ConfigurableRestingPlaceFieldKey {
    dispositionDate = 'restingPlace.dispositionDate',
    burialMethod = 'restingPlace.burialMethod',
    ownCemeteryPlot = 'restingPlace.ownCemetaryPlot',
    dispositionPlace = 'restingPlace.dispositionPlace',
    crematoryName = 'restingPlace.crematoryName',
    secondDispositionPlace = 'restingPlace.secondDispositionPlace',
    cemeteryPropertyDetails = 'restingPlace.cemeteryPropertyDetails',
    specifyOtherBurialMethod = 'restingPlace.specifyOtherBurialMethod',
}

export enum ConfigurableParentFieldKey {
    fatherBirthCity = 'parents.fatherBirthCity',
    motherBirthCity = 'parents.motherBirthPlace',
    motherMaidenName = 'parents.motherMaidenName',
}

export enum ConfigurableEducationFieldKey {
    educationListOfOptions = 'education.educationListOfOptions',
    educationHistory = 'education.educationHistory',
}

export enum ConfigurableMarriageFieldKey {
    spouseMaidenName = 'marriage.spouseMaidenName',
    marriagePlace = 'marriage.marriagePlace',
    maritalStatus = 'marriage.maritalStatus',
    dateOfMarriage = 'marriage.dateOfMarriage',
    spousesDateOfDeath = 'marriage.spousesDateOfDeath',
}

export enum ConfigurableWorkHistoryFieldKey {
    occupationListOfOptions = 'workHistory.occupationListOfOptions',
    normalOccupation = 'workHistory.normalOccupation',
    kindOfBusiness = 'workHistory.kindOfBusiness',
    currentEmployer = 'workHistoy.currentEmployer',
    yearsInOccupation = 'workHistory.yearsInOccupation',
    retiredInYear = 'workHistory.retiredInYear',
    retiredLastEmployer = 'workHistory.retiredLastEmployer',
}

export enum ConfigurableMilitaryFieldKey {
    militaryBranch = 'military.militaryBranch',
    militaryRank = 'military.militaryRank',
    yearsServed = 'military.yearsServered',
    militaryServiceNumber = 'military.militaryServiceNumber',
    militaryHonors = 'military.militaryHonors',
    serviceConnectedDisability = 'military.serviceConnectedDisability',
    fromServiceDate = 'military.fromServiceDate',
    toServiceDate = 'military.toServiceDate',
}

export enum ConfigurableRaceFieldKey {
    ancestry = 'race.ancestry',
    americanIndianTribe = 'race.americanIndianTribe',
    ofHispanicOrigins = 'race.hispanicOrigins',
    hispanicOriginOther = 'race.hispanicOriginOther',
    personRace = 'race.personRace',
    usCitizen = 'race.usCitizen',
    indianTribe = 'race.indianTribe',
    asianOther = 'race.asianOther',
    otherOtherAsian = 'race.otherOtherAsian',
    otherPacificIslander = 'race.otherPacificIslander',
    otherRace = 'race.otherRace',
    otherHispanicRace = 'race.otherHispanicRace',
}

export type ConfigurableFieldKey =
    | ConfigurableAboutFieldKey
    | ConfigurableLifeFieldKey
    | ConfigurableRestingPlaceFieldKey
    | ConfigurableEducationFieldKey
    | ConfigurableMarriageFieldKey
    | ConfigurableWorkHistoryFieldKey
    | ConfigurableMilitaryFieldKey
    | ConfigurableRaceFieldKey
    | ConfigurableParentFieldKey;

export const isFieldHiddenFromUser = (field: DeathCertificateConfigFieldUX, user: UserProfile): boolean => {
    if (!field.is_hidden_from_family) {
        return false;
    }

    if (UserRoles.isFHorGOMUser(user)) {
        return user.is_family_view_on;
    } else {
        return true;
    }
};

export const getFieldConfiguration = (params: {
    key: ConfigurableFieldKey;
    config: DeathCertificateConfigUX | null;
}): DeathCertificateConfigFieldUX | undefined => {
    const { config, key } = params;
    return config?.revision.fields.find((f) => f.ui_field_key === key);
};

export const isFieldEnabled = (params: {
    key: ConfigurableFieldKey;
    config: DeathCertificateConfigUX | null;
}): boolean => {
    return Boolean(getFieldConfiguration(params));
};

export const isFieldOptional = (
    key: ConfigurableFieldKey,
    config: DeathCertificateConfigUX | null,
    user: UserProfile,
): boolean => {
    if (!config) {
        return true;
    }
    const field = getFieldConfiguration({
        key,
        config,
    });
    if (!field || !field.is_required) {
        return true;
    }

    const isHiddenFromUser = isFieldHiddenFromUser(field, user);
    return isHiddenFromUser;
};
