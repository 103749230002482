import { createTask, updateTask } from '../../../actions/task/Task.action';
import { canModifyTaskDueDates } from '../../../shared/authority/can';
import { CaseTaskCreateRequestUX, CaseTaskUX, GatherCaseUX, TaskUpdateRequestUX } from '../../../shared/types';
import { useGDispatch, useGSelector } from '../../../types';
import GSaveTaskDialog, { CreateUpdateSaveTask } from '../../workflows/dialogs/GSaveTask.dialog';
import { ChecklistTaskType } from '../../workflows/editWorkFlow/editTasks/EditTasks';

interface Props {
    isOpen: boolean;
    activeCase: GatherCaseUX;
    existing: CaseTaskUX | null;
    zIndex: number;
    type: ChecklistTaskType;
    closeDialog: () => void;
}
const SaveTaskDialog = (props: Props) => {
    const { isOpen, activeCase, existing, zIndex, type, closeDialog } = props;

    const dispatch = useGDispatch();

    const handleCreateTask = (task: CreateUpdateSaveTask) => {
        const taskToCreate: CaseTaskCreateRequestUX = {
            title: task.title,
            subtitle: task.subtitle,
            description: task.description,
            is_after_care: task.is_after_care,
            visible_to_family: task.visible_to_family,
            assigned_to: task.assigned_to,
            assigned_to_all: task.assigned_to_all,
            event_id: null,
            complete_by_time: task.complete_by_time || null,
        };

        return dispatch(createTask(taskToCreate, activeCase));
    };

    const handleUpdateTask = async (task: CreateUpdateSaveTask) => {
        if (!existing) {
            return null;
        }

        const changes: TaskUpdateRequestUX = {
            title: task.title,
            subtitle: task.subtitle,
            description: task.description,
            visible_to_family: task.visible_to_family,
            is_after_care: task.is_after_care,
            assigned_to_all: task.assigned_to_all,
            assigned_to: task.assigned_to,
            complete_by_time: task.complete_by_time,
        };

        return dispatch(updateTask(existing.id, changes, activeCase.uuid, existing.template_type));
    };

    const showDueDate = useGSelector((state) => canModifyTaskDueDates(
       state.userSession.userData,
         activeCase.funeral_home_id, 
    ));

    return (
        <GSaveTaskDialog
            isDialogOpen={isOpen}
            activeCase={activeCase}
            existing={existing}
            zIndex={zIndex}
            type={type}
            showAssignTask
            closeDialog={closeDialog}
            onCreate={handleCreateTask}
            onUpdate={async (existingTask, changes) => await handleUpdateTask(changes)}
            showDueDate={showDueDate}
        />
    );
};

export default SaveTaskDialog;
