import { Grid, Typography } from "@mui/material";
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import DisplayOptionsContent from "../../../../allProducts/saveProduct/DisplayOptionsContent";
import { useState } from 'react';
import { ProductState } from '../../../../allProducts/saveProduct/SaveProduct';
import BulkEditDialogFooter from "./BulkEditDialogFooter";
import { useGDispatch } from '../../../../../types';
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { ProductBulkAction, ProductFormattingOptions, ProductUX } from '../../../../../shared/types';

interface BulkEditStylingProps {
    funeralHomeId: number | null;
    selectedProductIds: number[];
    selectButton: BulkEditButtonType | null;
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
}

const demoProduct: Partial<ProductState> = {};

    // name: '',
    // manufacturer: null,
    // taxRate: null,
    // isTaxable: false,
    // modelNumber: '',
    // description: '',
    // sku: null,
    // externalLink: null,
    // isPackageExclusive: false,
    // isHidden: false,
    // featuredRank: null,
    // tags: {},
    // photos: [],
    // pricingModel: PricingModelEnum.fixed,
    // basePrice: '',
    // hasDefaultQuantity: false,
    // defaultQuantity: '',
    // cost: '',
    // variablePrice: '',
    // variableIncrement: '',
    // units: '',
    // baseQuantity: '',
    // hasMaxQuantity: false,
    // maxQuantity: '',
    // isAlwaysDisplayed: false,
    // useNAWhenAlwaysDisplayed: false,
    // //// // displayManufacturer: false,
    // //// // displayModelNumber: false,
    // //// // displayTags: false,
    // //// // persistentContractText: '',
    // //// // boldContractText: false,
    // //// // underlineContractText: false,
    // //// // indentContractText: false,
    // showOnWebsite: false,
    // showPriceOnWebsite: false,
    // category: null // Set category from props
// };

const BulkEditStyling = (props: BulkEditStylingProps) => {
    const { 
        selectButton, 
        onSubmitClick, 
        selectedProductIds,
        funeralHomeId,
     } = props;

    const [productStyleOptions, setProduct] = useState(demoProduct);
    const dispatch = useGDispatch();

    const [isSpinning, setIsSpinning] = useState(false);

    const handleDisplayOptions = (name: keyof ProductState, value: string | boolean) => {
        setProduct(prevProduct => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSubmitclick = async () => {
        // call api for bulk edit styling
        // only submit the product styling options we want
        let formattingChanges: Partial<ProductFormattingOptions> = {};
        if (productStyleOptions.boldContractText !== undefined) {
            formattingChanges.bold_contract_text = productStyleOptions.boldContractText;
        }
        if (productStyleOptions.persistentContractText !== undefined) {
            formattingChanges.persistent_contract_text = productStyleOptions.persistentContractText;
        }
        if (productStyleOptions.underlineContractText !== undefined) {
            formattingChanges.underline_contract_text = productStyleOptions.underlineContractText;
        }
        if (productStyleOptions.indentContractText !== undefined) {
            formattingChanges.indent_contract_text = productStyleOptions.indentContractText;
        }
        if (productStyleOptions.displayManufacturer !== undefined) {
            formattingChanges.display_manufacturer = productStyleOptions.displayManufacturer;
        }
        if (productStyleOptions.displayModelNumber !== undefined) {
            formattingChanges.display_model_number = productStyleOptions.displayModelNumber;
        }
        if (productStyleOptions.displayTags !== undefined) {
            formattingChanges.display_tags = productStyleOptions.displayTags;
        }
        if (productStyleOptions.isAlwaysDisplayed !== undefined) {
            formattingChanges.is_always_displayed = productStyleOptions.isAlwaysDisplayed;
            formattingChanges.use_na_when_always_displayed = Boolean(productStyleOptions.useNAWhenAlwaysDisplayed);
        }
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.edit_styling_on_statement,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: formattingChanges,
        })); 
        setIsSpinning(false);
        onSubmitClick(updatedProducts);
    };

    return (
        <>
            <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                px={1.5}
            >
                <Grid item>
                    <Typography textAlign="center" fontSize={18} fontWeight={400} color="primary" pb={0.5}>
                        {selectButton}
                    </Typography>
                </Grid>
                <Typography
                    fontSize={14}
                    color='secondary'
                    pt={0.5}
                    textAlign='center'
                >
                    Adjust how the selected products will be displayed on the statement page.
                    This configuration will override any previous styling.
                </Typography>

                <DisplayOptionsContent
                    isGlobalProduct={false}
                    onStateChange={handleDisplayOptions}
                    product={productStyleOptions}
                />
            </Grid>

            <BulkEditDialogFooter
                selectedProductsCount={selectedProductIds.length}
                onSubmitClick={handleSubmitclick}
                disableSubmitButton={false}
                isSpinning={isSpinning}
            />
        </>
    );
};

export default BulkEditStyling;