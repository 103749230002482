import * as React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SwapVert from '@mui/icons-material/SwapVert';

import { GLOBAL_STYLED_PROPS } from '../../../../../styles';

import { StyledProps, styleWrapper, typographyStyle } from '../styles';
import { SubtotalItemGrid } from '.';
import {
    formatPrice,
    formatDinero,
    getDinero,
} from '../../../../../shared/goods_and_services/pricing';
import { ProductContractItemUX, ContractCategoryGroup, AssetType } from '../../../../../shared/types';
import withGStyles from '../../../../../styles/WithGStyles';
import DisabledPermissionTooltip from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';
import { usePermissionEnabled } from '../../../../common/hooks/usePermissionEnabled';

type Props = {
    isPrintMode: boolean;
    isCondensedView: boolean;
    isContractFrozen: boolean;
    canUserEditContract: boolean;
    allGroups: ContractCategoryGroup[];
    contractSubTotal: string;
    contractTaxTotal: string;
    contractGrandTotal: Dinero.Dinero;
    packageDiscounts: ProductContractItemUX[];
    allowanceCredits: ProductContractItemUX[];
    contractDiscounts: ProductContractItemUX[];
    onAdjustContractTotalClick?: (contractItemId?: string) => void;
    onTaxManagementClick?: () => void;
    isNewYorkContract: boolean;
    isDownloadingInvoice?: boolean;
};

const Totals: React.FunctionComponent<StyledProps & GLOBAL_STYLED_PROPS & Props> = (props) => {
    const {
        classes,
        isPrintMode,
        isCondensedView,
        isContractFrozen,
        canUserEditContract,
        allGroups,
        contractSubTotal,
        contractTaxTotal,
        contractGrandTotal,
        packageDiscounts,
        allowanceCredits,
        contractDiscounts,
        onAdjustContractTotalClick,
        onTaxManagementClick,
        isNewYorkContract,
        isDownloadingInvoice
    } = props;

    const canModifyPrices = usePermissionEnabled(Permission.MODIFY_PRICES);

    const goodsAndServicesSubtotal = formatDinero(allGroups.reduce<Dinero.Dinero>(
        (total, grp) => total.add(grp.subTotal),
        getDinero(0, AssetType.USD),
    ));

    const showDiscounts = packageDiscounts.length > 0 || allowanceCredits.length > 0
        || contractDiscounts.length > 0;

    return (
        <div
            className={classes.inner}
            style={{
                padding: !isCondensedView
                    ? 12
                    : isPrintMode
                        ? '12px 0 0 8px'
                        : '12px 4px 0px 8px',
                border: 'none',
                borderRadius: 10,
                maxWidth: 'none',
                float: 'none',
                width: !isCondensedView
                    ? 'auto'
                    : isPrintMode
                        ? 'calc(100% - 8px)'
                        : 'calc(100% - 12px)',
            }}
        >

            {isNewYorkContract &&
                <Typography
                    style={{
                        ...typographyStyle,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontSize: 14,
                        color: '#2e2e2e',
                        margin: '4px 0'
                    }}
                    color="secondary"
                >
                    III. summary of charges
                </Typography>
            }

            {allGroups.map((group) => (
                <SubtotalItemGrid
                    key={group.title}
                    classes={classes}
                    item={{
                        label: group.subTotalLabel,
                        price: formatDinero(group.subTotal),
                    }}
                    priceFontWeight={500}
                    fontSize={isCondensedView ? 12 : 16}
                    isCondensedView={isCondensedView}
                    printMode={isPrintMode}
                />
            ))}

            {canUserEditContract && !isPrintMode && !contractGrandTotal.isZero() &&
                <div className={classes.textCenter}>
                    <DisabledPermissionTooltip permission={Permission.CONTRACT_DISCOUNTS}>
                        {disabled =>
                            <Button
                                color="primary"
                                className={isCondensedView ? classes.addItemCompactButton : classes.addItemButton}
                                onClick={onAdjustContractTotalClick ? () => onAdjustContractTotalClick() : undefined}
                                disabled={disabled}
                            >
                                <SwapVert />&nbsp;Adjust Statement Total
                            </Button>
                        }
                    </DisabledPermissionTooltip>
                </div>
            }

            {showDiscounts &&
                <SubtotalItemGrid
                    classes={classes}
                    item={{
                        label: '',
                        price: goodsAndServicesSubtotal,
                    }}
                    isTopBorder
                    fontSize={isCondensedView ? 14 : 18}
                    priceFontWeight={600}
                    isCondensedView={isCondensedView}
                    printMode={isPrintMode}
                />
            }

            {packageDiscounts.map((discountItem) =>
                <SubtotalItemGrid
                    key={discountItem.id}
                    classes={classes}
                    item={{
                        label: discountItem.name,
                        price: formatPrice(discountItem.list_price, discountItem.asset_type),
                    }}
                    priceFontWeight={500}
                    isStriked={discountItem.delete_revision !== null}
                    fontSize={isCondensedView ? 12 : 16}
                    isCondensedView={isCondensedView}
                    printMode={isPrintMode}
                />
            )}

            {allowanceCredits.map((credit) =>
                <SubtotalItemGrid
                    key={credit.id}
                    classes={classes}
                    item={{
                        label: credit.name,
                        price: formatPrice(credit.list_price, credit.asset_type),
                    }}
                    priceFontWeight={500}
                    isStriked={credit.delete_revision !== null}
                    fontSize={isCondensedView ? 12 : 16}
                    isCondensedView={isCondensedView}
                    printMode={isPrintMode}
                />
            )}

            {contractDiscounts.map((contractDiscount) =>
                <SubtotalItemGrid
                    key={contractDiscount.id}
                    classes={classes}
                    item={{
                        label: contractDiscount.name,
                        price: formatPrice(contractDiscount.list_price, contractDiscount.asset_type),
                    }}
                    priceFontWeight={500}
                    isStriked={contractDiscount.delete_revision !== null}
                    fontSize={isCondensedView ? 12 : 16}
                    isCondensedView={isCondensedView}
                    hasMenu
                    onClick={onAdjustContractTotalClick && canModifyPrices
                        ? () => onAdjustContractTotalClick(contractDiscount.id)
                        : undefined
                    }
                    canUserEditContract={canUserEditContract}
                    printMode={isPrintMode}
                />
            )}

            <SubtotalItemGrid
                classes={classes}
                item={{
                    label: '',
                    price: contractSubTotal,
                }}
                isTopBorder
                fontSize={isCondensedView ? 14 : 18}
                priceFontWeight={600}
                isCondensedView={isCondensedView}
                printMode={isPrintMode}
            />

            <SubtotalItemGrid
                classes={classes}
                item={{
                    label: 'Tax',
                    price: contractTaxTotal,
                }}
                priceFontWeight={500}
                fontSize={isCondensedView ? 12 : 16}
                isCondensedView={isCondensedView}
                hasMenu
                onClick={onTaxManagementClick}
                canUserEditContract={canUserEditContract && !isContractFrozen}
                printMode={isPrintMode}
            />

            <SubtotalItemGrid
                classes={classes}
                item={{
                    label: isDownloadingInvoice ? `STATEMENT TOTAL` : `TOTAL`,
                    price: formatDinero(contractGrandTotal),
                }}
                fontSize={isCondensedView ? 16 : 20}
                isTopBorder
                priceFontWeight={700}
                labelFontWeight={700}
                isCondensedView={isCondensedView}
                printMode={isPrintMode}
            />
        </div>
    );
};

export default withGStyles(styleWrapper<Props>())(Totals);
