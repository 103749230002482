import { useState } from "react";
import { useGSelector } from "../../../../types";
import { usePopperState } from "../../../common/hooks/usePopperState";
import EventDetailsPopper from "../EventDetails.popper";
import EventNoteDialog from "../EventNote.dialog";

const useEventDetailPopperAndDialog = (
    activeEventId: number | undefined,
    zIndex: number,
    editEventHandler: () => void
) => {
    const [popperAnchor, { onClick: openEventDetailsPopper }, closeEventDetailsPopper] = usePopperState();

    const activeCase = useGSelector(({ casesState }) => casesState.selectedCase);
    const activeEvent = useGSelector(({ eventState }) =>
        eventState.gatherEvents.find(event => event.id === activeEventId)
    );

    const [eventNoteDialogOpen, setEventNoteDialogOpen] = useState(false);

    const eventDetailsPopperElement = activeEvent && activeCase && (
        <EventDetailsPopper
            key={activeEvent.id}
            zIndex={zIndex}
            popperAnchor={popperAnchor}
            event={activeEvent}
            activeCase={activeCase}
            onClose={closeEventDetailsPopper}
            onMessageClick={() => setEventNoteDialogOpen(true)}
            onEditClick={editEventHandler}
            onPageRedirect={closeEventDetailsPopper}
        />
    );

    const eventNoteDialogElement = activeEvent && activeCase && (
        <EventNoteDialog
            zIndex={zIndex + 1}
            activeCase={activeCase}
            activeEvent={activeEvent}
            isDialogOpen={eventNoteDialogOpen}
            editEvent={editEventHandler}
            closeDialog={() => setEventNoteDialogOpen(false)}
        />
    );

    return {
        eventDetailsPopperElement,
        eventNoteDialogElement,
        openEventDetailsPopper,
        closeEventDetailsPopper
    };
};

export default useEventDetailPopperAndDialog;