import classNames from 'classnames';
import * as appService from '../../../../services/app.service';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { GatherCaseUX } from '../../../../shared/types';

import Typography from '@mui/material/Typography';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;

}

const MemorializeFacebook = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
    } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    A memorialized account is a way for people on Facebook to remember and
                    celebrate {activeCase.fname}'s life. Memorialized accounts have the following key
                    features:
                </Typography>
                <ul
                    className={classNames(
                        classes.fontSize18,
                        classes.colorSecondary,
                        classes.textLeft,
                        classes.fontWeight300
                    )}
                >
                    <li>
                        The word "Remembering" will be shown next to {activeCase.fname}'s name on their
                        Facebook profile.
                    </li>
                    <li className={classes.marginTop10}>
                        Depending on the privacy settings of {activeCase.fname}'s account, friends can share
                        memories on {activeCase.fname}'s memorialized timeline.
                    </li>
                    <li className={classes.marginTop10}>
                        Content {activeCase.fname} shared (example: photos, posts) stays on Facebook
                        and is visible to the audience it was shared with.
                    </li>
                    <li className={classes.marginTop10}>
                        {activeCase.fname}'s memorialized profile won't appear in public spaces such as
                        in suggestions for people you may know, ads or birthday reminders.
                    </li>
                    <li className={classes.marginTop10}>
                        No one can log into a memorialized account.
                    </li>
                    <li className={classes.marginTop10}>
                        If {activeCase.fname} didn't have a lagacy contact, then the account can't be
                        changed.
                    </li>
                </ul>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={
                        classNames(
                            classes.detailBoxHeader,
                            classes.fontSize18,
                            classes.backgroundPrimary,
                            classes.padding10
                        )
                    }
                >
                    You will need the following...
                </Typography>
                <Grid
                    item

                    className={
                        classNames(
                            classes.padding10,
                            classes.textLeft
                        )
                    }
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        Name:
                        <span
                            className={classes.fontWeight500}
                            id="caseName"
                        >
                            {` ${activeCase.fname} ${activeCase.lname}`}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Passing:
                        <span className={classes.fontWeight500} id="deathDate">
                            {activeCase.dod_start_date
                                ? ` ${appService.formatDateString(activeCase.dod_start_date)} `
                                : ''
                            }
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        {'Optional: '}
                        <span className={classes.fontStyleItalic}>
                            A scan or photo of your loved one's obituary, death certificate, memorial card or
                            other documentation corfirming that they've passed away.
                        </span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <a
                    href="https://www.facebook.com/help/contact/234739086860192"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Memorialize {activeCase.fname}'s Facebook
                        &nbsp;<OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default MemorializeFacebook;
