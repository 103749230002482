import {
    DeathCertificateLifeType,
    GatherCaseUX,
    DeathCertificateConfigUX,
    UserProfile,
    CaseType,
    DeathLocationEnum,
} from '../../../shared/types';
import { isValidPhoneNumber } from '../../utils';
import { isFieldOptional, ConfigurableLifeFieldKey } from './config';
import { formatGatherDate } from '../../utils';

export const lifeValidators = {
    yearsCurrentCounty: function (
        { residencePlace }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = residencePlace && residencePlace.yearsInCounty && residencePlace.yearsInCounty > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.yearsLivedInCounty, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    birthDate: function (updatedCase: GatherCaseUX | null): boolean {
        if (!updatedCase) {
            return true;
        }

        return Boolean(updatedCase.dob_date && formatGatherDate(updatedCase.dob_date));
    },
    causeOfDeath: function (
        lifeForm: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const causeOfDeath = lifeForm.causeOfDeath;

        const isValid = Boolean(causeOfDeath);
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.causeOfDeath, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    deathDate: function (updatedCase: GatherCaseUX, touchCheck?: boolean): boolean {
        const isValid = updatedCase.dod_start_date && Boolean(formatGatherDate(updatedCase.dod_start_date));
        if (isValid) {
            return true;
        }

        if (updatedCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    deathDateRangeEnd: function (updatedCase: GatherCaseUX, touchCheck?: boolean): boolean {
        const isValid = updatedCase.death_date_unknown === false
            || (updatedCase.dod_end_date && Boolean(formatGatherDate(updatedCase.dod_end_date)));
        if (isValid) {
            return true;
        }
        if (updatedCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    pronouncementDate: function (
        { dop_date: dopDate, case_type: caseType }: GatherCaseUX,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = dopDate && !!formatGatherDate(dopDate);
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.dateOfPronouncement, config, user)
            || caseType === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    pronouncementTime: function (
        updatedCase: GatherCaseUX,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = updatedCase.dop_time.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.timeOfPronouncement, config, user)
            || updatedCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    birthLocation: function (
        { birthPlace }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValidLocation = birthPlace && birthPlace.description && birthPlace.description.length;
        const isPlaceUnknown = birthPlace?.unknown === true;

        if (isValidLocation || isPlaceUnknown) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.birthPlace, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    deathLocation: function (
        lifeForm: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const deathLocation = lifeForm.deathPlace;

        const isValid = deathLocation && deathLocation.description
            && typeof deathLocation.description === 'string';

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.deathPlace, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    cityLimits: function (
        { residencePlace }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const insideCityLimits = residencePlace?.insideCityLimits;
        const isValid = insideCityLimits && insideCityLimits.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.primaryResidenceInCityLimits, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    residenceLocation: function (
        { residencePlace }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = residencePlace && residencePlace.description && residencePlace.description.length > 0;
        if (isValid || residencePlace?.unknown) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.primaryResidence, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    deathLocationOptions: function (
        { deathLocation_listOfOptions: loc }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = loc && DeathLocationEnum[loc] && loc.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.deathLocationOptions, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    physicianName: function (
        { physicianName }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        { case_type }: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = physicianName && physicianName.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.physicianName, config, user) || case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    physicianPhoneNumber: function (
        { physicianPhoneNumber }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        { case_type }: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = physicianPhoneNumber && physicianPhoneNumber.length > 0;
        if (isValid && isValidPhoneNumber(physicianPhoneNumber)) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.physicianPhoneNumber, config, user)
            || case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    physicianFaxNumber: function (
        { physicianFaxNumber }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        { case_type }: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = physicianFaxNumber && physicianFaxNumber.length;
        if (isValid && isValidPhoneNumber(physicianFaxNumber)) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.physicianFaxNumber, config, user)
            || case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },
    physicianAddress: function (
        lifeForm: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const { physicianAddress } = lifeForm;

        const isValid = physicianAddress && physicianAddress.description
            && typeof physicianAddress.description === 'string';

        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.physicianAddress, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    },

    hebrewDateOfBirth: function (
        { hebrewDateOfBirth }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = hebrewDateOfBirth && hebrewDateOfBirth.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.hebrewDateOfBirth, config, user)) {
            return !touchCheck;
        }

        return false;
    },

    hebrewDateOfDeath: function (
        { hebrewDateOfDeath }: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
        touchCheck?: boolean,
    ): boolean {
        const isValid = hebrewDateOfDeath && hebrewDateOfDeath.length > 0;
        if (isValid) {
            return true;
        }

        if (isFieldOptional(ConfigurableLifeFieldKey.hebrewDateOfDeath, config, user)
            || gatherCase.case_type === CaseType.pre_need) {
            return !touchCheck;
        }

        return false;
    }
};

export const lifeMasterValidators = {
    valid: function (
        lifeForm: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return lifeValidators.birthLocation(lifeForm, config, user)
            && lifeValidators.birthDate(gatherCase)
            && lifeValidators.deathDate(gatherCase)
            && lifeValidators.causeOfDeath(lifeForm, config, gatherCase, user)
            && lifeValidators.deathLocation(lifeForm, config, gatherCase, user)
            && lifeValidators.residenceLocation(lifeForm, config, user)
            && lifeValidators.deathLocationOptions(lifeForm, config, gatherCase, user)
            && lifeValidators.cityLimits(lifeForm, config, user)
            && lifeValidators.physicianName(lifeForm, config, gatherCase, user)
            && lifeValidators.physicianAddress(lifeForm, config, gatherCase, user)
            && lifeValidators.physicianFaxNumber(lifeForm, config, gatherCase, user)
            && lifeValidators.physicianPhoneNumber(lifeForm, config, gatherCase, user)
            && lifeValidators.yearsCurrentCounty(lifeForm, config, user)
            && lifeValidators.hebrewDateOfBirth(lifeForm, config, user)
            && lifeValidators.hebrewDateOfDeath(lifeForm, config, gatherCase, user);
    },
    touched: function (
        lifeForm: DeathCertificateLifeType,
        config: DeathCertificateConfigUX | null,
        gatherCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return lifeValidators.birthLocation(lifeForm, config, user, true)
            || lifeValidators.birthDate(gatherCase)
            || lifeValidators.deathLocation(lifeForm, config, gatherCase, user, true)
            || lifeValidators.causeOfDeath(lifeForm, config, gatherCase, user, true)
            || lifeValidators.residenceLocation(lifeForm, config, user, true)
            || lifeValidators.deathLocationOptions(lifeForm, config, gatherCase, user, true)
            || lifeValidators.cityLimits(lifeForm, config, user, true)
            || lifeValidators.physicianName(lifeForm, config, gatherCase, user, true)
            || lifeValidators.physicianAddress(lifeForm, config, gatherCase, user, true)
            || lifeValidators.physicianFaxNumber(lifeForm, config, gatherCase, user, true)
            || lifeValidators.physicianPhoneNumber(lifeForm, config, gatherCase, user, true)
            || lifeValidators.yearsCurrentCounty(lifeForm, config, user, true)
            || lifeValidators.hebrewDateOfBirth(lifeForm, config, user, true)
            || lifeValidators.hebrewDateOfDeath(lifeForm, config, gatherCase, user, true);
    },
};
