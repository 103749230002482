import classNames from "classnames";

import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import SettingsIcon from "@mui/icons-material/Settings";

import { getPhotoUrl } from "../../../services";
import { CloudinaryTransformationsType, FuneralHomeUX, UserFuneralHomeUX } from "../../../shared/types";
import GButton from "../../common/GButton";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import makeStyles from "@mui/styles/makeStyles";
import { GStyles } from "../../../styles/GStyles";
import { Theme } from "@mui/material/styles";
import { GREEN_COLOR } from "../../../constants/colorVariables";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';

const useStyles = makeStyles((theme: Theme) => ({
    funeralHomeContainer: {
        '& $hasActive': {
            '& $imageWrapper:not($active)': {
                opacity: '0.2 !important',
                '&:hover': {
                    opacity: '0.8 !important'
                },
            },
        },
        '& $mainSection': {
            scrollbarWidth: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            maxWidth: 'fit-content',
            flexDirection: 'row',
            overflowX: 'auto',
            transition: 'opacity 50ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            margin: '0px auto 20px',
            padding: '0 10px',
            '&:hover $imageWrapper': {
                opacity: 0.5,
            },
            '& $active': {
                opacity: '1 !important',
                '&:hover': {
                    opacity: '1 !important'
                },
            },
            '& $imageWrapper': {
                width: 100,
                padding: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'inherit',
                position: 'relative',
                '&:hover': {
                    opacity: '1 !important',
                    cursor: 'pointer'
                },
            }
        },
    },
    divider: {
        height: 1,
        width: '100%',
        background: theme.palette.primary.main
    },
    settingsIcon: {
        color: '#fff',
        fontSize: 70,
    },
    settingsIconOuter: {
        background: '#BDBDBD',
        borderRadius: 16,
        width: 100,
        height: 100,
        margin: '10px auto',
        boxShadow: theme.shadows[5],
    },
    fhImage: {
        display: 'block',
        boxShadow: theme.shadows[5],
        width: 100,
        height: 100,
        borderRadius: 16,
        margin: '10px auto'
    },
    activeV2: {
        '& $settingsIconOuter, img': {
            boxSizing: 'border-box',
            border: `4px solid ${GREEN_COLOR}`,
            objectFit: 'cover',
        },
        '&$disabled': {
            pointerEvents: 'none',
            '& $settingsIconOuter, img': {
                border: `4px solid #000`
            }
        }
    },
    disabled: {},
    mainSection: {},
    imageWrapper: {},
    active: {},
    hasActive: {}
}), { name: 'FuneralHomeSelector' });

export interface FuneralHomeSelectorElement {
    id: number;
    name: string;
    city: string;
    state: string;
    icon: string | null;
    disabled?: boolean;
}

export const mapUserFuneralHomeUXToElement = (fh: UserFuneralHomeUX): FuneralHomeSelectorElement => ({
    id: fh.funeral_home_id,
    name: fh.funeral_home_name,
    city: fh.funeral_home_city,
    state: fh.funeral_home_state,
    icon: fh.funeral_home_icon,
});

export const mapFuneralHomeUXToElement = (fh: FuneralHomeUX): FuneralHomeSelectorElement => ({
    id: fh.id,
    name: fh.name,
    city: fh.city,
    state: fh.state,
    icon: fh.icon,
});

interface Props {
    variant?: 'new';
    funeralHomes: FuneralHomeSelectorElement[] | null;
    selectedFuneralHomeIds: number[];
    selectFuneralHome: (funeralHomeId: number) => void;
    onDeselectAll?: () => void;
    onSelectAll?: (fhIds: number[]) => void;
}

const FuneralHomeSelector = ({
    funeralHomes,
    selectedFuneralHomeIds,
    variant,
    selectFuneralHome,
    onDeselectAll,
    onSelectAll
}: Props) => {
    const classes = useStyles();

    const transformations: CloudinaryTransformationsType[] = [{
        height: 200,
        width: 200,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];

    const availableFuneralHomes = (funeralHomes || []).filter(fh => !fh.disabled);

    return (
        <Grid item xs={12} className={classes.funeralHomeContainer}>
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.mainSection,
                    classes.hasActive
                )}
            >
                {onSelectAll && onDeselectAll
                    && (funeralHomes || []).length >= 1
                    && <Grid container width="fit-content" flexDirection="column" mt="10px">
                        <GButton
                            variant="text"
                            color="primary"
                            disabled={selectedFuneralHomeIds.length === (funeralHomes || []).length}
                            onClick={() =>
                                onSelectAll(availableFuneralHomes.map(fh => fh.id))
                            }
                            sx={{
                                flexDirection: 'column',
                                p: '4px 6px',
                                fontSize: 12,
                                fontWeight: 400,
                                '& svg': {
                                    fontSize: 32
                                }
                            }}
                        >
                            <DoneAllIcon /> <span>Select All {availableFuneralHomes?.length}</span>
                        </GButton>

                        <GButton
                            variant="text"
                            color="primary"
                            disabled={!selectedFuneralHomeIds.length}
                            onClick={onDeselectAll}
                            sx={{
                                flexDirection: 'column',
                                p: '4xp 6px',
                                fontSize: 12,
                                fontWeight: 400,
                                '& svg': {
                                    fontSize: 32
                                }
                            }}
                        >
                            <RemoveDoneIcon /> <span>Deselect All</span>
                        </GButton>

                    </Grid>
                }

                {(funeralHomes || []).map(fh =>
                    <Tooltip
                        key={fh.id}
                        title={variant === 'new'
                            ? fh.disabled
                                ? `You don't have access to run this report for ${fh.name} (${fh.city}, ${fh.state}). 
                            Please contact an admin to request access.`
                                : `${fh.name} (${fh.city}, ${fh.state})`
                            : fh.name}
                        placement="top"
                        enterDelay={0}
                    >
                        <div>
                            <div
                                key={fh.id}
                                className={classNames(
                                    classes.imageWrapper,
                                    selectedFuneralHomeIds.includes(fh.id) && classes.active,
                                    (selectedFuneralHomeIds.includes(fh.id)
                                        || fh.disabled) && variant === 'new'
                                    && classNames(classes.activeV2, fh.disabled && classes.disabled),

                                )}
                                onClick={e => !fh.disabled && selectFuneralHome(fh.id)}
                            >
                                <Box position="relative">
                                    {(selectedFuneralHomeIds.includes(fh.id) || fh.disabled) && variant === 'new' &&
                                        <Box
                                            width="fit-content"
                                            position="absolute"
                                            top={12}
                                            right={2}
                                            bgcolor={fh.disabled ? '#000' : GREEN_COLOR}
                                            borderRadius="0 16px 0 12px"
                                            p="2px"
                                        >
                                            {fh.disabled
                                                ? <LockIcon htmlColor="#fff" />
                                                : <CheckIcon
                                                    htmlColor="#fff"
                                                    sx={{ display: 'block' }}
                                                />}
                                        </Box>}

                                    {!fh.icon || fh.icon === ''
                                        ? <div className={classNames(classes.settingsIconOuter, GStyles.flexCentred)}>
                                            <SettingsIcon className={classes.settingsIcon} />
                                        </div>
                                        : <img
                                            src={getPhotoUrl(fh.icon, transformations)}
                                            className={classes.fhImage}
                                            width={100}
                                            height={100}
                                        />
                                    }
                                </Box>

                                <Divider className={classes.divider} />

                                <Typography color="primary" align="center" sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: 'inherit',
                                }}>
                                    {fh.city}, {fh.state}
                                </Typography>
                            </div>
                        </div>
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    );
};

export default FuneralHomeSelector;