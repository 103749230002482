import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ForwardIcon from '@mui/icons-material/Forward';

import { GatherCaseUX } from '../../../../shared/types';

import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    activeCase: GatherCaseUX;
}

const ForwardMail = (props: Props) => {
    const { activeCase } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper
            heading={`Forward ${activeCase.fname}'s Mail`}
            icon={<ForwardIcon color="primary" />}
        >
            <Grid item>
                <Typography
                    align="left"
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    By forwarding {activeCase.fname}'s mail to a different address,
                    you will ensure important mail is received promptly.
                    Forwarding {activeCase.fname}'s mail can also help to eliminate
                    accumulating mail, which can attract unwanted attention.
                </Typography>
                <Typography
                    align="left"
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    In order to forward {activeCase.fname}’s mail to a different address,
                    you must file a request at your local Post Office. You will need to:
                </Typography>
                <ul
                    className={classNames(
                        classes.fontSize18,
                        classes.colorSecondary,
                        classes.textLeft,
                        classes.fontWeight300
                    )}
                >
                    <li>
                        Provide valid proof that you are the appointed executor or administrator
                    </li>
                    <li className={classes.marginTop10}>
                        Complete a change of address form at a Post Office location.
                    </li>
                </ul>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    For your convenience, we have provided the form you will need below.
                    We have also provided a link to help you find your closest post office.
                </Typography>
                <Typography
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop10
                    )}
                >
                    Note: If someone still shares a mailing address with {activeCase.fname} and
                    would normally receive {activeCase.fname}'s mail, you don't need to do anything.
                    You can simply continue to open and manage {activeCase.fname}'s mail.
                </Typography>
            </Grid>
            <Grid>
                <a
                    href="/static/forms/usps_change_of_address_form.pdf"
                    target="blank"
                    download="usps_change_of_address_form"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Download Form
                        <InsertDriveFileIcon className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
            <Grid>
                <a
                    href="https://www.usps.com/locator/"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Find a local post office <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default ForwardMail;