import { useCallback, useMemo, useState } from 'react';
import Clear from '@mui/icons-material/Clear';
import { Button, Dialog, DialogContent, DialogTitle, Fade, Grid, Theme, Typography } from '@mui/material';
import { FuneralHomeUX, GatherCaseSummary, InsurancePolicySummaryUX, InsurancePolicyUX } from '../../shared/types';
import { useGDispatch } from '../../types';
import GAutocomplete, { SuggestionType } from '../common/inputElements/GAutocomplete';
import { SlideTransition } from '../common/Transitions';
import { loadCaseSummaries } from '../../actions/GatherCase.action';
import GLink from '../common/GLink';
import { FamilyRoutePage, joinNameParts, RouteBuilder } from '../../services';
import { attachPolicyToCase, removePolicyFromDashboard } from '../../actions/Insurance.action';
import makeStyles from '@mui/styles/makeStyles';
import useDebounce from '../common/hooks/useDebounce';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            '& $dialogPaper': {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-around',
                width: '100%',
                maxWidth: '100%',
                [theme.breakpoints.up('md')]: {
                    maxWidth: 720,
                    width: 720,
                },
            },
        },
        dialogHeader: {
            padding: 14,
            zIndex: 1,
            paddingBottom: 0,
            height: 34,
        },
        clearIcon: {
            float: 'right',
            cursor: 'pointer',
        },
        dialogContent: {
            display: 'flex',
            zIndex: 0,
            padding: '0 14px',
            [theme.breakpoints.up('md')]: {
                padding: 0,
            },
        },
        main: {
            height: 600,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        attachCase: {
            '& $message': {
                fontSize: 18,
                fontWeight: 200,
                lineHeight: '1.46429em',
                maxWidth: 'calc(100% - 24px)',
                margin: 'auto',
                textAlign: 'center',
                marginBottom: 48,
                '@media (min-width: 700px)': {
                    maxWidth: 'calc(100% - 114px)',
                },
            },
        },
        dropDown: {
            maxWidth: 320,
            margin: 'auto',
        },
        selectTextField: {
            width: '100%',
            maxWidth: 320,
            marginTop: 24,
        },
        autoCompletePaper: {
            width: '100%',
            maxWidth: 320,
        },
        navigateButton: {
            width: 'fit-content',
            margin: '20px auto',
        },
        message: {},
        dialogPaper: {},
    }),
    { name: 'AttachExistingCaseDialog' },
);

interface AttachExistingCaseProps {
    funeralHome: Pick<FuneralHomeUX, 'key' | 'id'>;
    policyToAttach: InsurancePolicySummaryUX | InsurancePolicyUX;
    isExistingCaseDialogOpen: boolean;
    closeExisitingCaseDialog: () => void;
    zIndex: number;
}

const AttachExistingCaseDialog = (props: AttachExistingCaseProps) => {
    const { isExistingCaseDialogOpen, closeExisitingCaseDialog, zIndex, funeralHome, policyToAttach } = props;
    const [searchText, setSearchText] = useState('');
    const [selectedCaseUuid, setSelectedCaseUuid] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [casesFound, setCasesFound] = useState<GatherCaseSummary[]>([]);
    const classes = useStyles();

    const dispatch = useGDispatch();

    const availableCases: SuggestionType<string>[] = casesFound.map((c) => ({
        key: c.uuid,
        value: joinNameParts(c),
    }));

    const selectedCase = useMemo(
        () => casesFound.find((c) => c.uuid === selectedCaseUuid),
        [casesFound, selectedCaseUuid],
    );

    const caseFirstName = (selectedCase && selectedCase.fname) || 'Existing';

    const _search = useCallback(
        async (searchValue: string) => {
            if (searchValue) {
                setIsLoading(true);
                const result = await dispatch(
                    loadCaseSummaries({ funeralHomeId: funeralHome.id, searchText: searchValue, offset: 0 }),
                );
                setCasesFound(result?.data ?? []);
                setIsLoading(false);
            }
        },
        [dispatch, funeralHome.id],
    );
    const debouncedSearch = useDebounce(_search, 500, { cancel: true });

    const handleCaseSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        setSearchText(searchValue);

        debouncedSearch(searchValue);
    };

    const handleSelect = (suggestion: SuggestionType<string | number>) => {
        const _selectedCase = casesFound.find((c) => c.uuid === suggestion.key) || null;
        setSelectedCaseUuid(_selectedCase?.uuid ?? null);
        setSearchText(_selectedCase ? joinNameParts(_selectedCase) : searchText);
    };

    const handleAttachPolicy = () => {
        // Attach policy to case
        if (selectedCaseUuid) {
            dispatch(
                attachPolicyToCase({
                    policyId: policyToAttach.policy_id,
                    funeralHomeId: funeralHome.id,
                    caseUuid: selectedCaseUuid,
                }),
            );
        }
        dispatch(removePolicyFromDashboard({
            policyId: policyToAttach.policy_id,
            funeralHomeId: funeralHome.id
        }));
        return closeExisitingCaseDialog();
    };

    const button = (
        <Button
            color="primary"
            variant="contained"
            value="Yes"
            size="large"
            onClick={selectedCaseUuid ? handleAttachPolicy : () => setIsError(true)}
        >
            {`Attach Policy and Navigate to ${selectedCaseUuid === null ? 'Existing' : `${caseFirstName}'s`} Case`}
        </Button>
    );

    return (
        <Dialog
            open={isExistingCaseDialogOpen}
            onClose={closeExisitingCaseDialog}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            className={classes.root}
            classes={{
                paper: classes.dialogPaper,
            }}
            style={{ zIndex }}
        >
            <DialogTitle className={classes.dialogHeader}>
                <Clear color="secondary" className={classes.clearIcon} onClick={closeExisitingCaseDialog} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid item xs={12} className={classes.main}>
                    <Fade in timeout={1000}>
                        <Grid item xs={12} className={classes.attachCase}>
                            <Typography className={classes.message}>
                                Select an existing case below to attach this policy to.
                            </Typography>
                            <Grid item xs={12} className={classes.dropDown}>
                                <GAutocomplete
                                    id={'down_shift'}
                                    fullWidth
                                    placeholder="Start typing to view cases..."
                                    label={'Select Existing Case'}
                                    className={classes.selectTextField}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    popperClassName={classes.autoCompletePaper}
                                    selectedKey={selectedCaseUuid ?? ''}
                                    inputValue={searchText}
                                    suggestions={availableCases}
                                    onChange={handleCaseSearchChange}
                                    onSelectItem={handleSelect}
                                    loading={isLoading}
                                    error={isError}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.navigateButton}>
                                {selectedCase ? (
                                    <GLink
                                        to={RouteBuilder.FamilyPage({
                                            caseName: selectedCase.name,
                                            funeralHomeKey: funeralHome.key,
                                            page: FamilyRoutePage.DEFAULT,
                                        })}
                                    >
                                        {button}
                                    </GLink>
                                ) : (
                                    button
                                )}
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AttachExistingCaseDialog;
