import { Theme, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import { usePopperState } from "./hooks/usePopperState";
import GPopper from "./GPopper";
import Typography from "@mui/material/Typography";
import { paperClasses } from "@mui/material/Paper";
import { SKYBLUE_COLOR } from "../../constants/colorVariables";
import { ReactNode } from "react";

const InfoButton = styled(IconButton)({
    width: 36,
    height: 36,
    '& svg': {
        color: SKYBLUE_COLOR
    }
});

const styles = {
    popper: (theme: Theme) => ({
        width: '96%',
        maxWidth: 360,
        [`.${paperClasses.root}`]: {
            padding: 2,
            borderRadius: '12px',
            boxShadow: theme.shadows[10],
        }
    })
};

interface Props {
    zIndex: number;
    infoText: NonNullable<ReactNode>;
}
const FeatureDiscoverabilityPopper = ({ zIndex, infoText }: Props) => {
    const [popperAnchor, anchorProps, closePopper] = usePopperState();

    return (
        <>
            <InfoButton onClick={anchorProps.onClick}>
                <InfoIcon />
            </InfoButton>

            <GPopper
                zIndex={zIndex}
                anchorEle={popperAnchor}
                sx={styles.popper}
                closePopper={closePopper}
            >
                <Typography textAlign="center" color="secondary">
                    {infoText}
                </Typography>
            </GPopper>
        </>
    );
};

export default FeatureDiscoverabilityPopper;