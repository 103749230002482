/**
 *
 * Gather functions
 *
 */

import { log } from '../logger';
import { getDirectCaseUrl } from '../shared/utils';
import ZebraBrowserPrintWrapper from './zebraPrinter.service';
import { MAX_ZEBRA_HEALTH_CHECK_COUNT } from '../constants';

export { };

const browserPrint = new ZebraBrowserPrintWrapper();
const labelFuneralHomeNameMaxLength = 30;
const labelCaseNameMaxLength = 19;

// Zebra Template
// See http://labelary.com/viewer.html
// for online testing of the template
// NOTE: ^FX is for comments

// Font size: ^CF0,60

// ^FX QR Code Data
// ^FO10,76^BQN,2,2,M,7^FN3^FS

const getLabelTemplate = (): string => {

    const { REACT_APP_APP_URL } = process.env;
    let reactUrl = REACT_APP_APP_URL || 'unknown';
    let zoomlevel = 4;
    if (REACT_APP_APP_URL) {
        switch (REACT_APP_APP_URL) {
            case 'https://my.gatherqa.app':
            case 'https://branchtest.internal.gather.app:3000':
                zoomlevel = 3;
                break;
            default:
                zoomlevel = 4;
        }
    } else {
        zoomlevel = 2;
    }

    const LabelTemplate = `
    ^XA^DFR:GatherTrack.ZPL^FS
    ^FO3,73^GB390,2,2^FS
    ^FO125,73^GB2,125,2^FS
    ^FO125,130^GB270,2,2^FS
    ^FO325,73^GB2,57,2^FS
    ^CF0,40
    ^FX Funeral Home Name MAX CHAR LENGTH ${labelFuneralHomeNameMaxLength}
    ^FO8,10,0^FB400,20,2,L,0^A0,20,21^FR^FN1^FS
    ^FX DECEDENT NAME
    ^FO8,35,0^FN4^FS
    ^FX QR Code Data
    ^FX REACTAPP ${reactUrl}
    ^FO04,71^BQN,2,${zoomlevel},H,7^FN3^FS
    ^FX Date of Birth DOB
    ^FO130,83^A0,25,25^FDDOB ^FS
    ^FO180,83^A0,25,25^FN5^FS
    ^FX Date of Death DOD
    ^FO130,107^A0,25,25^FDDOD ^FS
    ^FO180,107^A0,25,25^FN6^FS
    ^FX Age in Years
    ^FO330,85^A0,24,35^FN7^FS
    ^FO330,110^A0,16,19^FDYRS^FS
    ^FX KeepTrack Tag ID
    ^FO130,135^A0,50,50^FN2^FS
    ^FX Case Name
    ^FO130,180^A0,20,20^FN8^FS
    ^XZ
    `;
    return LabelTemplate;

};

const sampleLabel = `
^XA
^XFR:GatherTrack.ZPL
^FX Funeral Home Name MAX CHAR LENGTH 30^FS
^FN1^FDPRICE FAMILY FUNERAL^FS
^FX Gather Tag ID
^FN2^FDX88MMBB^FS
^FX KeepTrack URL QRCode
^FN3^FDQA,https://gather.app/^FS

^FX Decedent Full Name^FS
^FN4^FDJohn Doe^FS

^FX Decedent Date of Birth^FS
^FN5^FD23 JUL 1984^FS

^FX Decedent Date of Death^FS
^FN6^FD23 JUL 2024^FS

^FX Decedent Age in Years^FS
^FN7^FD88^FS
^FX Case Name^FS
^FN8^FD2022-248-TRADE^FS
^XZ
`;
export interface LabelDetails {
    funeralHomeName: string; // 30 chars
    keeptrackTagId: string | null; // 6 Chars
    funeralHomeCaseAssocId: number; // will be encoded to QR Code
    fullNameDisplayStr: string; //
    dobDisplayStr: string | null; //  11 Chars  DAYofMonth MON YEAR eg 30 JAN 1922
    dodDisplayStr: string | null; //  11 Chars  DAYofMonth MON YEAR eg 30 JAN 1922
    ageInYears: number | null;
    fhCaseNumber: string | null; //  approx 19 chars
}
// const labelNameMaxLength = 16;
const createLabel = (data: LabelDetails): string => {
    const fhName = data?.funeralHomeName
        ? data.funeralHomeName.length > labelFuneralHomeNameMaxLength
            ? data.funeralHomeName.substring(0, labelFuneralHomeNameMaxLength - 4).toUpperCase() + '...'
            : data.funeralHomeName.toUpperCase()
        : 'UNKNOWN';
    const tagId = data?.keeptrackTagId ? data.keeptrackTagId : 'MISSING';
    const url = getDirectCaseUrl({ funeralHomeCaseAssocId: data.funeralHomeCaseAssocId }) || '';
    const name = data?.fullNameDisplayStr
        ? data.fullNameDisplayStr
        // ? data.fullNameDisplayStr.length > labelNameMaxLength
        // ? data.fullNameDisplayStr.substring(0, labelNameMaxLength - 4)+ '...'
        // : data.fullNameDisplayStr
        : 'ERROR: MISSING';
    const dob = data?.dobDisplayStr ? data.dobDisplayStr : 'MISSING';
    const dod = data?.dodDisplayStr ? data.dodDisplayStr : 'MISSING';
    const age = data?.ageInYears ? data.ageInYears : 0;
    const caseName = data?.fhCaseNumber
        ? data.fhCaseNumber.length > labelCaseNameMaxLength
            ? data.fhCaseNumber.substring(0, labelCaseNameMaxLength - 4) + '...'
            : data.fhCaseNumber
        : '';

    const labelString = ` 
    ^XA 
    ^XFR:GatherTrack.ZPL 
    ^FX Funeral Home Name MAX CHAR LENGTH 30^FS
    ^FN1^FD${fhName}^FS
    ^FX Gather Tag ID 
    ^FN2^FD${tagId}^FS 
    ^FX KeepTrack URL QRCode 
    ^FN3^FDQA,${url}^FS 
    ^FX Decedent Full Name^FS 
    ^FN4^FD${name}^FS
    ^FX Decedent Date of Birth^FS
    ^FN5^FD${dob}^FS
    ^FX Decedent Date of Death^FS 
    ^FN6^FD${dod}^FS 
    ^FX Decedent Age in Years^FS 
    ^FN7^FD${age}^FS 
    ^FX Case Name^FS 
    ^FN8^FD${caseName}^FS 
    ^XZ 
    `;
    return labelString;
};

export const getAlignmentLabel = (): string => {
    // eslint-disable-next-line
    // https://labelary.com/viewer.html?density=8&quality=grayscale&width=1.89&height=1&units=inches&index=0&rotation=0&zpl=%5EXA%0A%5EFX%20Bounding%20boxes%0A%5EFO1%2C1%5EGB365%2C200%2C4%5EFS%0A%5EFO10%2C10%5EGB347%2C182%2C2%5EFS%0A%5EFO21%2C21%5EGB326%2C161%2C1%5EFS%0A%5EXZ%0A
    const labelString = `
    ^XA
    ^FO1,1^GB365,200,4^FS
    ^FO10,10^GB347,182,2^FS
    ^FO21,21^GB326,161,1^FS
    ^XZ
    `;
    return labelString;
};
interface zebraAdjustment {
    top?: number;
    left?: number;
    darkness?: number;
    orientation?: 'normal' | 'inverted';
}

export const getAdjustmentCommands = (newValues: zebraAdjustment): string[] => {
    const fieldSeperator = '^FS';
    const commands: string[] = [];
    commands.push('^XA');

    if (typeof newValues.top === 'number' ) { // [-120, 120]
        const newTop = newValues.top > 120 ? 120 : newValues.top < -120 ? -120 : newValues.top;
        commands.push('^FX Set Top: negative shift down / positive shift up' + fieldSeperator);
        commands.push(`^LT${newTop}`);
    }
    if (typeof newValues.left === 'number' ) { // [-9999, 9999]
        const newLeft = newValues.left > 9999 ? 9999 : newValues.left < -9999 ? -9999 : newValues.left;
        commands.push('^FX Set Left: negative shift right / positive shift left' + fieldSeperator);
        commands.push(`^LS${newLeft}`);
    }
    if (typeof newValues.darkness === 'number') { // 0-30
        const newDarkness = newValues.darkness > 30 ? 30 : newValues.darkness < 0 ? 0 : newValues.darkness;
        commands.push('^FX set darkness' + fieldSeperator);
        commands.push(`~SD${newDarkness}`);
    }
    // ^PON vs ^POI ^PON = normal orientation, ^POI = inverted
    if (newValues.orientation === 'normal' || newValues.orientation === 'inverted') {
        const newOrientation = newValues.orientation === 'inverted' ? 'I' : 'N';
        commands.push('^FX set orientation' + fieldSeperator);
        commands.push(`^PO${newOrientation}`);
    }

    commands.push('^JUS'); // Save command
    commands.push('^XZ'); // End command
    return commands;
};

export const isZebraBrowserPrintListening = async (): Promise<boolean> => {
    try {
        if (browserPrint.healthCheckCount < MAX_ZEBRA_HEALTH_CHECK_COUNT) {
            return await browserPrint.isBrowserPrintRunning();
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const resetZebraBrowserPrintHealthCheckCount = (): void => {
    browserPrint.healthCheckCount = 0;
};

export const getZebraBrowserPrintHealthCheckCount = (): number => {
    return browserPrint.healthCheckCount;
};

export const isZebraDefaultPrinterAvailable = async (): Promise<boolean> => {
    try {
        const defaultPrinter = await browserPrint.getDefaultPrinter();
        if (defaultPrinter) {
            browserPrint.setPrinter(defaultPrinter);
            const defaultPrinterStatus = await browserPrint.checkPrinterStatus();
            if (defaultPrinterStatus?.isReadyToPrint) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    } catch (error) {
        return false;
    }
};

interface ZebraBrowserPrintStatus {
    printStatus: boolean;
    message: string;
    subMessage?: string;
}

export const PrintZebraLabel = async (details: LabelDetails, quantity: number
): Promise<ZebraBrowserPrintStatus> => {
    try {
        if (quantity === 0 || quantity > 10) {
            new Error(`Invalid quantity requested. Please select a number [1-10].`);
        }
        let labelData = '';
        for (let i = 0; i < quantity; i++) {
            labelData += createLabel(details);
        }
        const labelTemplate = getLabelTemplate();
        const zpl = labelTemplate + labelData;

        const defaultPrinter = await browserPrint.getDefaultPrinter();
        browserPrint.setPrinter(defaultPrinter);
        const printerStatus = await browserPrint.checkPrinterStatus();
        if (printerStatus.isReadyToPrint) {
            await browserPrint.print(zpl);
        } else {
            return {
                printStatus: false,
                message: `Failed to print. Please check the printer and try again.`,
                subMessage: printerStatus.errors.join(', '),
            };
        }
    } catch (error: unknown) {
        log.warn('Failed to print to Zebra printer', { error, details, quantity });
        if (error instanceof Error) {
            return {
                printStatus: false,
                message: `Could not print. ${error.message}`,
            };
        }
        return {
            printStatus: false,
            message: `Could not print. Unknown Error.`,
        };
    }
    return {
        printStatus: true,
        message: `printed ${quantity} labels`,
    };
};

export const PrintTestLabel = async () => {
    try {
        // eslint-disable-next-line
        log.warn(`PrintTestLabel 0: `, {});
        // Select default printer
        const defaultPrinter = await browserPrint.getDefaultPrinter();
        // Set the printer
        // eslint-disable-next-line
        log.warn(`PrintTestLabel 1: `, { defaultPrinter });
        browserPrint.setPrinter(defaultPrinter);
        // eslint-disable-next-line
        log.warn(`PrintTestLabel 2: after setPrinter `, { defaultPrinter });
        // Check printer status
        const printerStatus = await browserPrint.checkPrinterStatus();
        // eslint-disable-next-line
        log.warn(`PrintTestLabel 3: checkPrinterStatus `, { defaultPrinter, printerStatus });
        // Check if the printer is ready
        if (printerStatus.isReadyToPrint) {
            const labelTemplate = getLabelTemplate();
            const zpl =
                labelTemplate +
                sampleLabel +
                createLabel({
                    funeralHomeName: 'Price Family Funeral Home',
                    keeptrackTagId: 'X12345',
                    funeralHomeCaseAssocId: 1,
                    fullNameDisplayStr: 'Betty Boop',
                    dobDisplayStr: '01 JUN 1950',
                    dodDisplayStr: '06 MAY 2012',
                    ageInYears: 61,
                    fhCaseNumber: '', //
                });
            browserPrint.print(zpl);
        } else {
            // eslint-disable-next-line
            log.warn('Error/s', { error: printerStatus.errors });
        }
    } catch (error) {
        // eslint-disable-next-line
        log.warn(`ERROR: could not print`, { error });
    }
};

export const PrintAlignmentLabel = async (): Promise<string | null> => {
    try {
        const defaultPrinter = await browserPrint.getDefaultPrinter();
        browserPrint.setPrinter(defaultPrinter);
        const printerStatus = await browserPrint.checkPrinterStatus();
        if (printerStatus.isReadyToPrint) {
            const zpl = getAlignmentLabel();
            await browserPrint.print(zpl);
        } else {
            return printerStatus.errors.join(', ');
        }
    } catch (error) {
        // eslint-disable-next-line
        log.warn(`ERROR: could not print`, { error });
        if (error instanceof Error ) {
            return error.message;
        }
    }
    return null;
};

export const sendZebraCommandstoPrinter = async (cmds: string): Promise<boolean> => {
    try {
        const defaultPrinter = await browserPrint.getDefaultPrinter();
        browserPrint.setPrinter(defaultPrinter);
        const printerStatus = await browserPrint.checkPrinterStatus();
        if (printerStatus.isReadyToPrint) {
            await browserPrint.print(cmds);
        } else {
            return false;
        }
    } catch (error) {
        // eslint-disable-next-line
        log.warn(`ERROR: could not print`, { error });
        return false;
    }
    return true;
};

export const isZebraConfigAvailable = async (args: {ipaddress: string}): Promise<boolean> => {
    return browserPrint.isZebraConfigAvailable(args);
};