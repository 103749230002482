import { GatherCaseUX } from '../../../../shared/types';

import DoWeQualify from './DoWeQualify';
import SocialSecurityDollars from './SocialSecurityDollars';
import { SvgIconComponent } from '@mui/icons-material';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    label: string;
    Icon: SvgIconComponent;
    activeCase: GatherCaseUX;
    componentId: number;
}

const CommonSocialSecurity = (props: Props) => {
    const { Icon, label, activeCase, componentId } = props;

    return (
        <TaskDialogStepWrapper
            heading={label}
            icon={<Icon color="primary" />}
        >
            {componentId === 1 &&
                <DoWeQualify activeCase={activeCase} />
            }
            {componentId === 2 &&
                <SocialSecurityDollars activeCase={activeCase} />
            }
        </TaskDialogStepWrapper>
    );
};

export default CommonSocialSecurity;
