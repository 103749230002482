import * as React from 'react';
import DownloadPhotosDialog, { SharedProps } from './DownloadPhotos.dialog';
import { startAlbumDownload } from '../../../actions/Album.action';
import { useDispatch } from 'react-redux';

interface Props extends SharedProps { }

const DownloadCasePhotosDialog = (props: Props) => {
    const [downloading, setDownloading] = React.useState(false);
    const dispatch = useDispatch();

    const downloadPhotos = async () => {
        const { activeCase } = props;

        if (activeCase) {
            setDownloading(true);
            await dispatch(startAlbumDownload(activeCase.uuid, 'case'));
            setDownloading(false);
        }
    };

    return (
        <DownloadPhotosDialog
            downloadPhotos={downloadPhotos}
            isDownloading={downloading}
            {...props}
        />
    );
};

export default DownloadCasePhotosDialog;
