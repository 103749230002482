import { values } from 'lodash';

import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

import GButton from '../../../../common/GButton';
import {
    FingerprintDialogStyledProps,
} from './EditFingerprint.dialog';
import FingerprintImage from './FingerprintImage';
import Grid from '@mui/material/Grid';
import { FingerPrintDisplay, FingerprintType, FingerprintUX, isFingerprintType } from '../../../../../shared/types';
import classNames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { downloadFromURL } from '../../../../../services/doc.service';

interface Props extends FingerprintDialogStyledProps {
    disableEdit?: boolean;
    fingerprint: FingerprintUX;
    selectedType: FingerprintType;
    zIndex: number;
    caseFname: string;
    isFHorGOMUserOnFH: boolean;
    onSave: () => void;
    onClose: () => void;
    onTypeChange: (type: FingerprintType) => void;
    openConfirmationDialog: () => void;
}
const EditFingerprintDialogContent = (props: Props) => {
    const {
        classes,
        fingerprint,
        selectedType,
        zIndex,
        caseFname,
        disableEdit,
        isFHorGOMUserOnFH,
        onTypeChange,
        onSave,
        onClose,
        openConfirmationDialog,
    } = props;

    const uploadTimeFormatted = moment(fingerprint.created_time).format('M/DD/YYYY @ h:m a z');

    const downloadFingerprint = () => {
        downloadFromURL(fingerprint.photo_url, `Case Fingerprint: ${FingerPrintDisplay[fingerprint.type] ?? ''}`);
    };

    const footerText = isFHorGOMUserOnFH
        ? `To keep ${caseFname}'s fingerprints secure, ` +
        `family members are not able to download a digital version of this print.`
        : `To keep ${caseFname}'s fingerprints secure, if you need a certified copy, please contact
    your Account Manager and we can send the originals directly to you.`;

    return (
        <>
            <DialogTitle className={classes.dialogTitle}>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    className={classes.colorGreen}
                >
                    <Typography
                        color="inherit"
                        className={classNames(classes.fontSize16, classes.paragraphLineHeight)}
                    >
                        Uploaded by {fingerprint.created_by_name}
                    </Typography>

                    <Typography
                        color="inherit"
                        className={classNames(classes.fontSize12, classes.paragraphLineHeight)}
                    >
                        {uploadTimeFormatted}
                    </Typography>
                </Grid>

                <ClearIcon color="primary" className={classes.clearIcon} onClick={() => onClose()} />
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Grid item xs={12} className={classes.fingerprintContainer}>
                    <FingerprintImage s3DownloadURL={fingerprint.photo_url} />
                </Grid>

                <TextField
                    disabled={disableEdit}
                    label="Select Print Type"
                    value={selectedType}
                    onChange={({ target }) => {
                        if (isFingerprintType(target.value) && !disableEdit) {
                            onTypeChange(target.value);
                        }
                    }}
                    select
                    SelectProps={{ MenuProps: { style: { zIndex: zIndex + 1 } } }}
                    fullWidth
                >
                    {values(FingerprintType).map(type =>
                        <MenuItem key={type} value={type}>
                            {FingerPrintDisplay[type]}
                        </MenuItem>
                    )}
                </TextField>

                {!disableEdit &&
                    <GButton
                        text="save"
                        size="large"
                        onClick={onSave}
                    />
                }

                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(classes.paragraphLineHeight, disableEdit && classes.marginBottom16)}
                >
                    {footerText}&nbsp;
                    <span className={classes.textClickable} onClick={downloadFingerprint}>
                        Click here to download this print
                    </span>
                </Typography>

                {!disableEdit &&
                    <GButton
                        icon={<DeleteIcon />}
                        text="delete this print"
                        variant="text"
                        color="inherit"
                        className={classes.colorRed}
                        onClick={openConfirmationDialog}
                    />
                }
            </DialogContent>
        </>
    );
};

export default EditFingerprintDialogContent;
