import { Component } from 'react';
import classNames from 'classnames';
import {
    CoordinatesType,
    PhotoOrientationType
} from '../../services/photo.service';

import AvatarEditor from 'react-avatar-editor';

import withStyles, { WithStyles, StyleRulesCallback } from '@mui/styles/withStyles';

import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import RotateRight from '@mui/icons-material/RotateRight';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import { convertHexToRGBA } from '../../services';
import { PhotoScope, PhotoTransformationsType } from '../../shared/types';
import { Theme } from '@mui/material';
import { GStyles } from '../../styles/GStyles';

export function styles<StyleProps extends object>(): StyleRulesCallback<Theme, StyleProps> {
    return (theme) => ({
        root: {},
        sliderContainer: {
            width: 'auto',
            padding: '0px 24px',
        },
        slider: {
            padding: '0px',
        },
        rotateRight: {
            position: 'absolute',
            color: '#fff',
            top: 4,
            left: 4,
            cursor: 'pointer',
            zIndex: 9,
            '&$haveHeader': {
                top: 60,
            },
            '& svg': {
                fontSize: 30,
                filter: 'drop-shadow(0 0px 2px gray)',
            },
            '& p': {
                color: '#fff',
                fontSize: 11,
                lineHeight: '1em',
            },
        },
        button: {
            margin: theme.spacing(),
        },
        buttonGrey: {
            margin: theme.spacing(),
            color: theme.palette.background.paper,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                color: theme.palette.background.paper,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                color: theme.palette.background.paper,
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(91, 110, 116, 0.5)',
            },
        },
        dialogPaper: {
            overflowX: 'hidden',
            margin: 0,
            width: 320,
            minHeight: 320,
            maxHeight: '90vh',
        },
        orientationHeader: {
            background: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 56,
            '& $inner': {
                width: 70,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: '0 8px',
                opacity: 0.5,
                cursor: 'pointer',
                '&$active, &:hover': {
                    opacity: 1,
                    '& svg': {
                        filter: 'drop-shadow(0 0px 2px gray)',
                    },
                    '& p': {
                        filter: 'drop-shadow(0 0px 2px gray)',
                    },
                },
                '& svg': {
                    fontSize: 28,
                    color: theme.palette.common.white,
                },
                '& p': {
                    fontSize: 12,
                    color: theme.palette.common.white,
                    textTransform: 'capitalize',
                    lineHeight: '1em',
                },
            },
        },
        appbarHeader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& $inner': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
                '& svg': {
                    fontSize: 28,
                    color: theme.palette.common.white,
                },
                '& $stepNumber': {
                    margin: '2px 0',
                },
                '& p': {
                    fontSize: 12,
                    color: theme.palette.common.white,
                    textTransform: 'capitalize',
                    lineHeight: '1em',
                },
            },
        },
        avatarEditorOverlay: {
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 10,
            background: convertHexToRGBA(theme.palette.common.black, 0.4),
            '& p, svg': {
                color: theme.palette.common.white,
            },
            '& svg': {
                margin: '4px 0',
                fontSize: 48,
            },
            '& p': {
                fontSize: 16,
            },
        },
        appBarClass: {
            background: theme.palette.common.white,
            marginTop: 4,
            boxShadow: 'none',
            '& p, svg': {
                color: `${theme.palette.primary.main} !important`,
            },
        },
        labelContainer: {
            padding: '6px 0px !important',
        },
        pickDifferentButtonContainer: {
            height: 32,
            margin: '16px 0 8px',
            '& svg': {
                fontSize: 28,
            },
            '& button': {
                margin: 'auto',
                height: '32px',
                padding: '6px 8px',
                display: 'flex',
                minHeight: '32px',
                alignItems: 'center',
                textTransform: 'none',
            },
        },
        tabRoot: {
            minWidth: 132,
            maxWidth: 132
        },
        flexContainer: {
            justifyContent: 'space-around'
        },
        headerSize: {
            fontSize: 16,
            color: theme.palette.common.white
        },
        stepNumber: {},
        inner: {},
        active: {},
        haveHeader: {},
    });
}

export type StyledProps = WithStyles<'root' | 'slider' | 'rotateRight' | 'button' | 'buttonGrey' | 'dialogPaper'
    | 'avatarEditorOverlay' | 'orientationHeader' | 'inner' | 'active' | 'haveHeader' | 'appBarClass' | 'labelContainer'
    | 'appbarHeader' | 'stepNumber' | 'pickDifferentButtonContainer' | 'tabRoot' | 'flexContainer' | 'headerSize'
    | 'sliderContainer'>;

export interface PhotoCropperOptions {
    defaultOrientation?: PhotoOrientationType;
    orientationTypes?: Array<PhotoOrientationType>;
    hasAllOrientations?: boolean;
}

export interface PhotoCropperProps {
    options?: PhotoCropperOptions;
    radius?: number;
    isDialogOpen: boolean;
    imageURI: string;
    transformations?: PhotoTransformationsType;
    maxSize?: number;
    scope: PhotoScope;
    generateBlob?: boolean;
    closeDialog: () => void;
    callBackAction: (
        transformations: PhotoTransformationsType,
        newImageURI: string,
        blob?: Blob,
    ) => void;
    zIndex: number;
    showHeader?: boolean | undefined;
}

interface Props {
    options?: PhotoCropperOptions;
    radius?: number;
    imageURI: string;
    registerAvatarEditor: (ref: AvatarEditor | null) => void;
    rotateRight: () => void;
    handleScale: (event: Event, scale: number | number[]) => void;
    handleMouseMove: (event: TouchEvent) => void;
    handleMouseUp: () => void;
    handlePositionChange: () => void;
    orientation: PhotoOrientationType | null;
    scale: number;
    angle: number;
    isPositionChanged: boolean;
    position: CoordinatesType;
    landscapeAspectRatio: number;
    portraitAspectRatio: number;
}
interface State {
    hideAvatarOverlay: boolean;
}

interface TouchClients {
    clientX: number;
    clientY: number;
}

const DEFAULT_ORIENTATION: PhotoOrientationType = 'square';

export const getCropperOrientation = (options?: PhotoCropperOptions) => {
    if (!options || !options.defaultOrientation) {
        return DEFAULT_ORIENTATION;
    }
    return options.defaultOrientation;
};

export const getPhotoOrientationTypes = (options?: PhotoCropperOptions): Array<PhotoOrientationType> => {
    if (options && options.hasAllOrientations) {
        return ['square', 'portrait', 'landscape'];
    }

    if (!options || !options.orientationTypes) {
        return [];
    }

    return options.orientationTypes.filter((v, i) => (options.orientationTypes || []).indexOf(v) === i) || [];
};

const AVATAR_EDITOR_SIZE: number = 320;

export const MIN_SCALE: number = 1;
export const MAX_SCALE: number = 10;

export const downsizeCanvas = (original: HTMLCanvasElement, maxSize: number): HTMLCanvasElement | null => {
    let width = original.width;
    let height = original.height;

    if (width > height && width > maxSize) {
        height = (height * maxSize) / width;
        width = maxSize;
    } else if (height > maxSize) {
        width = (width * maxSize) / height;
        height = maxSize;
    }

    const resized = document.createElement('canvas');
    resized.width = width;
    resized.height = height;
    const context = resized.getContext('2d');
    if (!context) {
        return null;
    }

    // draw original canvas onto the smaller canvas
    context.drawImage(original, 0, 0, width, height);
    return resized;
};

export const getPointFromTouch = (touch: TouchClients, element: AvatarEditor) => {
    const rect = element.getCroppingRect();
    return {
        x: touch.clientX - rect.x,
        y: touch.clientY - rect.y,
    };
};

export const getDistanceBetweenPoints = (pointA: CoordinatesType, pointB: CoordinatesType) => (
    Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2))
);

export const between = (min: number, max: number, value: number) => Math.min(max, Math.max(min, value));

export const DEFAULT_SCALE: number = 1;
export const DEFAULT_ANGLE: number = 0;
export const DEFAULT_X_POSITION: number = 0.5;
export const DEFAULT_Y_POSITION: number = 0.5;
export const DEFAULT_RADIUS: number = 50;

class BasePhotoCropper extends Component<StyledProps & Props, State> {
    state: State = {
        hideAvatarOverlay: false
    };

    private imageOverlayTimer: NodeJS.Timer | null = null;

    imageOverlayTime = () => {
        this.imageOverlayTimer = setTimeout(
            () => !this.state.hideAvatarOverlay && this.setState({ hideAvatarOverlay: true }),
            2000
        );
    };

    componentWillUnmount = () => {
        if (this.imageOverlayTimer) {
            clearTimeout(this.imageOverlayTimer);
        }
    };

    render() {
        const {
            classes,
            imageURI,
            options,
            radius,
            registerAvatarEditor,
            rotateRight,
            handleScale,
            handleMouseMove,
            handleMouseUp,
            handlePositionChange,
            orientation,
            angle,
            scale,
            position,
            isPositionChanged,
            landscapeAspectRatio,
            portraitAspectRatio,
        } = this.props;
        const { hideAvatarOverlay } = this.state;
        const cropperOrientation = orientation || getCropperOrientation(options);

        const isRotationAngleVertical = (angle / 90) % 2 === 1;
        const width = AVATAR_EDITOR_SIZE;
        const height = cropperOrientation === 'landscape' && AVATAR_EDITOR_SIZE / landscapeAspectRatio
            || cropperOrientation === 'portrait' && AVATAR_EDITOR_SIZE / portraitAspectRatio
            || AVATAR_EDITOR_SIZE;

        const borderRadius = AVATAR_EDITOR_SIZE / (100 / (radius !== undefined ? radius : DEFAULT_RADIUS));

        return (
            <div className={classes.root}>
                <div className={GStyles.positionRelative}>
                    <AvatarEditor
                        image={imageURI}
                        width={isRotationAngleVertical ? height : width}
                        height={isRotationAngleVertical ? width : height}
                        border={0}
                        scale={scale}
                        rotate={angle}
                        ref={registerAvatarEditor}
                        position={!isPositionChanged && position ? position : undefined}
                        borderRadius={cropperOrientation === 'square' && borderRadius || 0}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onPositionChange={handlePositionChange}
                        onImageReady={(e) => this.imageOverlayTime()}
                    />
                    <div
                        className={classNames(
                            classes.avatarEditorOverlay,
                            hideAvatarOverlay && GStyles.displayNone
                        )}
                        style={{ height: isRotationAngleVertical ? width : height }}
                        onClick={e => this.setState({ hideAvatarOverlay: true })}
                    >
                        <Typography>Tap and hold to position</Typography>
                        <TouchAppIcon />
                        <Typography>Pinch to zoom</Typography>
                    </div>

                    <div
                        className={classes.rotateRight}
                    >
                        <RotateRight onClick={e => rotateRight()} />
                        <Typography>Rotate</Typography>
                    </div>
                </div>

                <Typography
                    color="primary"
                    align="center"
                    className={GStyles.paddingTop10}
                >
                    Slide to zoom
                </Typography>

                <div className={classes.sliderContainer}>
                    <Slider
                        step={1 / 5}
                        value={scale}
                        aria-labelledby="label"
                        onChange={handleScale}
                        size="small"
                        min={MIN_SCALE}
                        max={MAX_SCALE}
                        classes={{
                            root: classes.slider
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles<Props>())(BasePhotoCropper);
