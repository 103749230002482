import * as classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { ORANGE_COLOR } from '../../../constants/colorVariables';
import { TrackingTabs } from './BodyTrackingContent';
import makeGStyles from '../../../styles/makeGStyles';
import Badge from '@mui/material/Badge';
import { useGNavigate } from '../../common/hooks/useGNavigate';
import { RouteBuilder } from '../../../services';
import { TrackingTabsEnum } from '../../../shared/types';
import { getIntercomTargetProp } from '../../../services';

const useStyles = makeGStyles(theme => ({
    tabs: {
        minHeight: 40,
        maxWidth: 680,
        margin: '0 auto',
        width: '100%',
        overflowX: 'auto',
    },
    tabIndicator: {
        borderTop: `2px solid ${theme.palette.primary.main}`,
        color: 'inherit',
        height: 0,
        width: '80px !important',
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        color: 'inherit',
        '& svg': {
            fontSize: 36,
        },
    },
    borderTopOrange: {
        borderTop: `2px solid ${ORANGE_COLOR} !important`,
    },
    badge: {
        left: 14,
        top: 6,
        zIndex: 0
    },
    tabRoot: {
        minWidth: 80,
        '& p': {
            fontWeight: 500,
        },
    },
    labelContainer: {
        padding: '10px 8px',
    },
    flexContainer: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    paddingTop14: {
        paddingTop: 14
    },
    marginTop6: {
        marginTop: 6
    },
    scrollerOverflow: {
        overflow: 'auto !important',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    }
}), { name: 'BodyTrackingTabs' });

interface Props {
    selectedTab: TrackingTabsEnum;
    isBulkEditMode: boolean;
    tabs: TrackingTabs[];
    caseName: string;
    funeralHomeKey: string;
    className?: string;
}

const BodyTrackingTabs = (props: Props) => {
    const { className, caseName, tabs, selectedTab, funeralHomeKey, isBulkEditMode } = props;
    const classes = useStyles();
    const maxCount = 99;

    const navigate = useGNavigate();

    return (
        <Tabs
            value={selectedTab}
            onChange={(_, tab: TrackingTabsEnum) =>
                navigate(RouteBuilder.TrackingPage({
                    caseName,
                    funeralHomeKey,
                    tab,
                }), { replace: true })
            }
            textColor="primary"
            variant="standard"
            className={classNames(classes.tabs, className)}
            classes={{
                indicator: classNames(classes.tabIndicator, isBulkEditMode && classes.borderTopOrange),
                flexContainer: classes.flexContainer,
                scroller: classes.scrollerOverflow
            }}
        >
            {tabs.map(tab => (
                <Tab
                    key={tab.value}
                    value={tab.value}
                    {...getIntercomTargetProp(`TrackingPage-${tab.value}-Tab`)}
                    label={
                        <Grid
                            item
                            className={classNames(
                                classes.label,
                                isBulkEditMode && classes.colorOrange
                            )}
                        >
                            {!!tab.count &&
                                <Badge
                                    badgeContent={tab.count > maxCount ? `99+` : tab.count}
                                    className={classes.badge}
                                    color="primary"
                                />
                            }
                            {tab.icon}
                            <Typography
                                color="inherit"
                                className={classNames(tab.value === TrackingTabsEnum.Vitals && classes.marginTop6)}
                            >{tab.value}</Typography>
                        </Grid>
                    }
                    classes={{
                        root: classNames(
                            classes.tabRoot,
                            classes.labelContainer,
                            tab.value === TrackingTabsEnum.Items && classes.paddingTop14
                        ),
                    }}
                />
            )
            )}
        </Tabs>
    );
};

export default BodyTrackingTabs;
