import classNames from 'classnames';
import * as appService from '../../../../services/app.service';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { GatherCaseUX } from '../../../../shared/types';

import Typography from '@mui/material/Typography';
import { useTaskDialogStyles } from '../TaskDialogsStyles';
import TaskDialogStepWrapper from '../TaskDialogStepWrapper';

interface Props {
    heading: string;
    icon: JSX.Element;
    activeCase: GatherCaseUX;
}

const DeleteFacebook = (props: Props) => {
    const {
        icon,
        heading,
        activeCase,
    } = props;

    const classes = useTaskDialogStyles();

    return (
        <TaskDialogStepWrapper heading={heading} icon={icon}>
            <Grid item>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                    align="left"
                >
                    Many people choose to remove their loved one's profile from Facebook.
                    Deleting {activeCase.fname}'s account will make it so {activeCase.fname}'s Facebook profile
                    and data are no longer visible. Because this action is permanent, Facebook requires
                    that you are an immediate family member or an executor. Details and a link are provided
                    below and we are always here to help if you have any questions.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={9}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.detailBox,
                    classes.marginTop20,
                    classes.marginAuto
                )}
            >
                <Typography
                    className={classNames(
                        classes.detailBoxHeader,
                        classes.fontSize18,
                        classes.backgroundPrimary,
                        classes.padding10
                    )}
                >
                    You will need the following...
                </Typography>
                <Grid
                    item
                    className={classNames(
                        classes.padding10,
                        classes.textLeft
                    )}
                >
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                        )}
                    >
                        Name:
                        <span
                            className={classes.fontWeight500}
                            id="caseName"
                        >
                            {` ${activeCase.fname} ${activeCase.lname}`}
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Date of Passing:
                        <span className={classes.fontWeight500} id="deathDate">
                            {activeCase.dod_start_date
                                ? `${appService.formatDateString(activeCase.dod_start_date)} `
                                : ''
                            }
                        </span>
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Copy or photo of {activeCase.fname}'s death certificate
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        If you don't have {activeCase.fname}'s death certificate, you'll need to provide
                        proof of authority and proof that {activeCase.fname} has passed away.
                        Please see the documents that Facebook accepts below.
                    </Typography>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Submit one document to provide proof of authority:
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            Power of attorney
                        </li>
                        <li className={classes.marginTop10}>
                            Birth certificate
                        </li>
                        <li className={classes.marginTop10}>
                            Last will and testament
                        </li>
                        <li className={classes.marginTop10}>
                            Estate letter
                        </li>
                    </ul>
                    <Typography
                        color="secondary"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        Submit one document to provide proof that {activeCase.fname} has passed away:
                    </Typography>
                    <ul
                        className={classNames(
                            classes.fontSize18,
                            classes.colorSecondary,
                            classes.textLeft,
                            classes.fontWeight300
                        )}
                    >
                        <li>
                            Obituary
                        </li>
                        <li className={classes.marginTop10}>
                            Memorial card
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid>
                <a
                    href="https://www.facebook.com/help/contact/228813257197480"
                    target="blank"
                    className={classes.textDecorationNone}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                            classes.marginTop30
                        )}
                    >
                        Delete {activeCase.fname}'s Facebook <OpenInNew className={classes.openInNewIcon} />
                    </Button>
                </a>
            </Grid>
        </TaskDialogStepWrapper>
    );
};

export default DeleteFacebook;
