import AccessToEmailQue from '../widgets/AccessToEmailQue';
import CanLogin from './CanLogin';
import CanNotLogin from './CanNotLogin';
import { OnlineAccountStepLookup } from '../utils';
import ResetPasswordSteps from './ResetPasswordSteps';
import LogInToAccountQue from '../widgets/LogInToAccountQue';

export const AMAZON_STEPS_LOOKUP: OnlineAccountStepLookup = {
    LogInToAccountQue,
    AccessToEmailQue,
    ResetPasswordSteps,
    CanLogin,
    CanNotLogin
};