import { ReactNode, useState } from "react";
import { BulkEditButtonType } from "./BulkEditSelectedOptionContent";
import { useBulkEditStyles } from "./BulkEditStyles";
import BulkEditBaseContainer from "./BulkEditBaseContainer";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { bulkProductAction } from '../../../../../actions/product/FHProduct.action';
import { 
    PricingModel, 
    PricingModelDisplayLookup, 
    PricingModelEnum, 
    ProductBulkAction, 
    ProductUX,
    } from '../../../../../shared/types';
import { useGDispatch } from '../../../../../types';
import { values } from 'lodash';

interface EditPricingModelProps {
    selectButton: BulkEditButtonType | null;
    children?: ReactNode | ReactNode[];
    funeralHomeId: number | null;
    selectedProductIds: number[];
    onSubmitClick: (updatedProducts: ProductUX[]) => void;
    zIndex: number;
}

const EditPricingModel = (props: EditPricingModelProps) => {
    const {
        selectButton,
        onSubmitClick,
        selectedProductIds,
        funeralHomeId,
        zIndex,
    } = props;
    const classes = useBulkEditStyles();
    const dispatch = useGDispatch();

    const [selectedPricingModel, setPricingModel] = useState(PricingModelEnum.fixed);
    const [isSpinning, setIsSpinning] = useState(false);


    const handlePricingModelChange = (value: string ) => {
        switch (value) {
            case PricingModelEnum.fixed:
                setPricingModel(PricingModelEnum.fixed);
                break;
            case PricingModelEnum.variable:
                setPricingModel(PricingModelEnum.variable);
                break;
            case PricingModelEnum.allowance:
                setPricingModel(PricingModelEnum.allowance);
                break;
            case PricingModelEnum.base_plus_variable:
                setPricingModel(PricingModelEnum.base_plus_variable);
                break;
            case PricingModelEnum.manual:
                setPricingModel(PricingModelEnum.manual);
                break;
            default:
                break;
        }
    };

    const handleSubmitClick = async () => {
        setIsSpinning(true);
        const updatedProducts = await dispatch(bulkProductAction({
            bulkAction: ProductBulkAction.edit_pricing_model,
            funeralHomeId,
            productIds: selectedProductIds,
            changes: { pricing_model: selectedPricingModel },
        }));

        onSubmitClick(updatedProducts);
        setIsSpinning(false);
    };

    return (
        <BulkEditBaseContainer
            disableSubmitButton={!selectedPricingModel.trim()}
            onSubmitClick={handleSubmitClick}
            selectButton={selectButton}
            selectedProductsCount={selectedProductIds.length}
            isSpinning={isSpinning}
        >
            <FormControl className={classes.selectMenuForm}>
                <Select
                    value={selectedPricingModel}
                    onChange={(e) => handlePricingModelChange(e.target.value)}
                    MenuProps={{ style: { zIndex: zIndex + 1 } }}
                    inputProps={{
                        name: 'pricingModelListOfOptions',
                        id: 'pricingModelListOfOptions',
                    }}
                    classes={{ select: classes.selectMenu }}
                >
                    {(values(PricingModelEnum)).map((p: PricingModel) => (
                        <MenuItem key={p} value={p}>

                            <Typography>{PricingModelDisplayLookup[p]}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </BulkEditBaseContainer>
    );
};

export default EditPricingModel;