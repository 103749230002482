import { GatherCaseUX, WorkOnButtonLink } from '../../../shared/types';

import SocialMediaDialog from './socialMediaDialog';
import PhoneServiceDialog from './phoneServiceDialog';
import ForwardMailDialog from './forwardMailDialog';
import FreezeCreditDialog from './freezeCreditDialog';
import OnlineAccountDialog from './onlineAccountDialog';
import SocialSecurityDialog from './socialSecurityDialog';
import UnclaimedPropertyDialog from './unclaimedPropertyDialog';
import LifeInsuranceDialog from './lifeInsuranceDialog';
import FuneralReimbursementDialog from './funeralReimbursementDialog';
import MuiLink from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { getFormattedPhoneNumber } from '../../../services';

export const TASK_DIALOGS = {
    [WorkOnButtonLink.freezeCredit]: FreezeCreditDialog,
    [WorkOnButtonLink.closeSocialMedia]: SocialMediaDialog,
    [WorkOnButtonLink.contactSocialSecurity]: SocialSecurityDialog,
    [WorkOnButtonLink.cancelOnlineAccounts]: OnlineAccountDialog,
    [WorkOnButtonLink.cancelPhoneService]: PhoneServiceDialog,
    [WorkOnButtonLink.forwardMail]: ForwardMailDialog,
    [WorkOnButtonLink.unclaimedProperty]: UnclaimedPropertyDialog,
    [WorkOnButtonLink.lifeInsurance]: LifeInsuranceDialog,
    [WorkOnButtonLink.funeralReimbursement]: FuneralReimbursementDialog,
};

export const FhOrAssigneePhoneNumberLink = ({
    activeCase,
    capitalize
}: { activeCase: GatherCaseUX; capitalize?: boolean }) => {
    const assigneeHasPhoneNumber: boolean = activeCase.assignee.phone !== null && activeCase.assignee.phone !== '';
    const fhHasPhoneNumber: boolean = activeCase.funeral_home.phone !== null
        && activeCase.funeral_home.phone !== '';

    const phoneNumbeToolTip = assigneeHasPhoneNumber
        ? activeCase.assignee.phone
        : fhHasPhoneNumber
            ? activeCase.funeral_home.phone
            : null;

    return (
        <Tooltip title={getFormattedPhoneNumber(phoneNumbeToolTip) || 'No number available'} placement="top">
            <MuiLink
                underline="always"
                color="inherit"
                href={activeCase.assignee.phone ? `tel:${activeCase.assignee.phone}`
                    : fhHasPhoneNumber ? `tel:${activeCase.funeral_home.phone}`
                        : undefined}
                sx={{
                    fontWeight: 300,
                    fontSize: 18,
                    color: !assigneeHasPhoneNumber && !fhHasPhoneNumber
                        ? 'rgba(0, 0, 0, 0.16) !important'
                        : undefined
                }}
            >
                {capitalize ? 'Click' : 'click'} here
            </MuiLink>
        </Tooltip>
    );
};