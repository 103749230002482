import { Theme, lighten } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';

import { convertHexToRGBA } from '../../../services';
import { GRAY_COLOR_2 } from '../../../constants/colorVariables';

export type CaseCategoryStyledProps = WithStyles<'root' | 'label' | 'groupButton' | 'translucent' | 'dark'
    | 'default' | 'active' | 'caseButton' | 'text' | 'flat' | 'outlinedButton' | 'contained' | 'raised' | 'fab'
    | 'outlined' | 'small' | 'xSmall' | 'medium' | 'large' | 'arrow' | 'arrowRipple' | 'icon' | 'white'
    | 'border1px' | 'marginLeft10' | 'translucentTheme'>;

export function caseCategoryStyles<Props extends object>(): StyleRulesCallback<Theme, Props> {
    return (theme) => ({
        root: {},
        groupButton: {
            minWidth: 108,
            minHeight: 36,
            border: 0,
            borderTop: `1px solid ${theme.palette.primary.main}`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                border: 0,
                borderTop: `1px solid ${theme.palette.primary.main}`,
                borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
            '&:first-of-type': {
                borderLeft: `1px solid ${theme.palette.primary.main}`,
            },
            '&:last-child': {
                borderRight: `1px solid ${theme.palette.primary.main}`,
            },
        },

        default: {
            '&$outlined': {
                '&:hover': {
                    border: `1px solid ${theme.palette.common.white}`,
                    backgroundColor: `${convertHexToRGBA(theme.palette.common.white, 0.08)}`,
                },
            },
            '&$outlinedButton': {
                '&:hover': {
                    background: convertHexToRGBA(theme.palette.primary.main, 0.25),
                },
                '&$active': {
                    '&:hover': {
                        background: theme.palette.primary.dark,
                    },
                },
            },
            '&$contained': {
                boxShadow: 'none',
                background: theme.palette.primary.main,
                '&:hover': {
                    background: theme.palette.primary.dark,
                },
                '&$active': {
                    '&$arrow': {
                        '&:after': {
                            borderLeftColor: theme.palette.primary.main,
                        },
                        '&:hover:after': {
                            borderLeftColor: theme.palette.primary.dark,
                        },
                    },
                },
            },
        },

        translucent: {
            '&$outlined': {
                backgroundColor: convertHexToRGBA(theme.palette.common.white, 0.2),
                '&:hover': {
                    backgroundColor: `${convertHexToRGBA(theme.palette.common.white, 0.5)}`,
                },
            },
            '&$outlinedButton': {
                color: theme.palette.primary.main,
                '&:hover': {
                    background: convertHexToRGBA(theme.palette.primary.main, 0.2),
                },
                '&$active': {
                    color: theme.palette.primary.main,
                    background: convertHexToRGBA(theme.palette.primary.main, 0.2),
                    '&:hover': {
                        color: theme.palette.common.white,
                        background: theme.palette.primary.main,
                    },
                },
            },
            '&$contained': {
                boxShadow: 'none',
                color: theme.palette.primary.main,
                background: lighten(theme.palette.primary.main, 0.8),
                '& $icon': {
                    color: theme.palette.primary.main
                },
                '&:hover, &:focus': {
                    color: '#fff',
                    '& $icon': {
                        color: '#fff'
                    }
                },
                '&:hover': {
                    background: theme.palette.primary.main,
                },
                '&$active': {
                    '&$arrow': {
                        '&:after': {
                            borderLeftColor: lighten(theme.palette.primary.main, 0.8),
                        },
                        '&:hover:after': {
                            borderLeftColor: theme.palette.primary.main,
                        },
                    },
                },

            },
        },
        dark: {
            '&$outlined': {
                backgroundColor: GRAY_COLOR_2,
                '&:hover': {
                    backgroundColor: `${convertHexToRGBA(GRAY_COLOR_2, 0.85)}`,
                },
            },
            '&$outlinedButton': {
                color: GRAY_COLOR_2,
                '&:hover': {
                    background: convertHexToRGBA(GRAY_COLOR_2, 0.08),
                },
                '&$active': {
                    color: theme.palette.common.white,
                    background: GRAY_COLOR_2,
                    '&:hover': {
                        background: convertHexToRGBA(GRAY_COLOR_2, 0.65),
                    },
                },
            },
            '&$contained': {
                background: GRAY_COLOR_2,
                '&:hover': {
                    color: theme.palette.common.white,
                    background: convertHexToRGBA(GRAY_COLOR_2, 0.65),
                },
                '&$active': {
                    '&$arrow': {
                        '&:after': {
                            borderLeftColor: GRAY_COLOR_2,
                        },
                        '&:hover:after': {
                            borderLeftColor: convertHexToRGBA(GRAY_COLOR_2, 0.65),
                        },
                    },
                },
            },
        },
        white: {
            '& $icon': {
                color: `${theme.palette.primary.main} !important`,
            },
            '&$outlined': {
                backgroundColor: theme.palette.common.white,
                '&:hover': {
                    backgroundColor: `${convertHexToRGBA(theme.palette.common.white, 0.85)}`,
                },
            },
            '&$outlinedButton': {
                color: theme.palette.primary.main,
                '&:hover': {
                    background: convertHexToRGBA(theme.palette.common.white, 0.08),
                },
                '&$active': {
                    color: theme.palette.primary.main,
                    background: theme.palette.common.white,
                    '&:hover': {
                        background: convertHexToRGBA(theme.palette.common.white, 0.65),
                    },
                },
            },
            '&$contained': {
                color: theme.palette.primary.main,
                background: theme.palette.common.white,
                '&:hover': {
                    color: theme.palette.primary.main,
                    background: convertHexToRGBA(theme.palette.common.white, 0.65),
                },
                '&$active': {
                    '&$arrow': {
                        '&:after': {
                            borderLeftColor: theme.palette.common.white,
                        },
                        '&:hover:after': {
                            borderLeftColor: convertHexToRGBA(theme.palette.common.white, 0.65),
                        },
                    },
                },
            },
        },
        translucentTheme: {
            '&$outlined': {
                backgroundColor: convertHexToRGBA(theme.palette.common.white, 0.2),
                '&:hover': {
                    backgroundColor: `${convertHexToRGBA(theme.palette.common.white, 0.5)}`,
                },
            },
            '&$outlinedButton': {
                color: convertHexToRGBA(theme.palette.primary.main, 0.8),
                '&:hover': {
                    background: convertHexToRGBA(theme.palette.primary.main, 0.08),
                },
                '&$active': {
                    color: theme.palette.common.white,
                    background: convertHexToRGBA(theme.palette.primary.main, 0.8),
                    '&:hover': {
                        background: theme.palette.primary.main,
                    },
                },
            },
            '&$contained': {
                boxShadow: 'none',
                background: convertHexToRGBA(theme.palette.primary.main, 0.8),
                '&:hover': {
                    background: theme.palette.primary.main,
                },
                '&$active': {
                    '&$arrow': {
                        '&:after': {
                            borderLeftColor: convertHexToRGBA(theme.palette.primary.main, 0.8),
                        },
                        '&:hover:after': {
                            borderLeftColor: theme.palette.primary.main,
                        },
                    },
                },
            },
        },
        caseButton: {
            minWidth: 102,
            padding: 0,
            '& $icon': {
                color: theme.palette.common.white,
                marginRight: 4,
            },
        },
        contained: {
            boxShadow: 'none',
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
            '&$active': {
                boxShadow: 'none',
                // boxShadow: theme.shadows[5]
            },
        },
        outlined: {
            borderRadius: 24,
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
            border: `1px solid ${convertHexToRGBA(theme.palette.common.white, 0.5)} !important`,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
            },
        },
        xSmall: {
            height: 26,
            minHeight: 26,
            maxHeight: 26,
            minWidth: 92,
            fontSize: 12,
        },
        small: {
            height: 32,
            minHeight: 32,
            maxHeight: 32,
        },
        medium: {
            height: 36,
            minHeight: 36,
            maxHeight: 36,
        },
        arrow: {
            position: 'relative',
            boxSizing: 'border-box',
            transition: `all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
            '&:after': {
                content: `''`,
                position: 'absolute',
                width: 0,
                height: 0,
                top: 0,
                right: -13,
                border: `13px solid transparent`,
                borderRight: 'none',
                transition: `all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
            },
            // '&:hover': {
            //     boxShadow: `-3px 3px 5px -1px rgba(0,0,0,0.2), ` +
            //         `-6px 5px 8px 0px rgba(0,0,0,0.14), ` +
            //         `-9px 1px 14px 0px rgba(0,0,0,0.12)`
            // },
            '&$active': {
                '&:hover': {
                    boxShadow: 'none',
                },
                borderRadius: '4px 0 0 4px',
            },
            '& $arrowRipple': {
                // width: 'calc(100% + 14px)'
            },
        },
        border1px: {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        marginLeft10: {
            marginLeft: 10,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        icon: {},
        arrowRipple: {},
        large: {},
        outlinedButton: {},
        active: {},
    });
}