import { } from '../';
import { GatherAction } from '../actions';
import { LOADED_PUBLIC_CASE, UPDATING_CASE } from '../actions/GatherCase.action';
import {
    SET_CASE_NOTES,
    SET_NOTE_STATE_LOADING,
    SET_TASK_NOTES,
    DELETE_TASK_NOTE,
    FUNERAL_HOME_NOTES_LOADING,
    FUNERAL_HOME_NOTES_LOADED,
    FUNERAL_HOME_NOTES_FAILED_TO_LOAD,
    SET_NOTES_ACTIVE_CASE_UUID,
    SET_SHOW_HELPER_NOTES_ONLY_TOGGLE,
} from '../actions/Note.action';
import { UPDATE_TASK } from '../actions/task/Task.action';
import { joinNameParts, replaceStringTemplates } from '../services';
import { TaskNote } from '../shared/types';
import { NoteState } from '../types';

export const noteStateInitData: NoteState = {
    isLoading: false,
    activeCaseUuid: null,
    caseNotes: [],
    taskNotes: [],
    funeralHomeView: {
        data: [],
        hasMoreData: false,
        isLoading: false,
        searchText: '',
        sortBy: 'note_updated_time',
        sortDirection: 'desc',
        showHelperNotesOnly: false
    }
};

function sanitizeTaskNotes(taskNotes: TaskNote[]) {
    return taskNotes
        .filter(n => n.updated_by_user.time)
        .map(n => {
            return {
                ...n,
                task_title: replaceStringTemplates(n.task_title, { case: { fname: n.gather_case_fname } })
            };
        });
}

export function noteState(state: NoteState = noteStateInitData, action: GatherAction): NoteState {
    switch (action.type) {
        case SET_CASE_NOTES: {
            return {
                ...state,
                caseNotes: action.caseNotes
            };
        }
        case SET_TASK_NOTES: {
            return {
                ...state,
                taskNotes: sanitizeTaskNotes(action.taskNotes),
            };
        }
        case LOADED_PUBLIC_CASE: {
            if (!action.notes) {
                return state;
            }
            return {
                ...state,
                caseNotes: action.notes.caseNotes,
                taskNotes: sanitizeTaskNotes(action.notes.taskNotes),
                activeCaseUuid: action.publicCase.uuid,
            };
        }
        case SET_NOTE_STATE_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading
            };
        }
        case DELETE_TASK_NOTE: {
            return {
                ...state,
                taskNotes: state.taskNotes.filter(n => n.task_id !== action.taskId),
                funeralHomeView: {
                    ...state.funeralHomeView,
                    data: state.funeralHomeView.data.filter(n => n.task_id !== action.taskId)
                }
            };
        }
        case FUNERAL_HOME_NOTES_LOADING: {
            return {
                ...state,
                funeralHomeView: {
                    ...state.funeralHomeView,
                    data: action.offset === 0 ? [] : state.funeralHomeView.data,
                    isLoading: true,
                    searchText: action.searchText,
                    sortBy: action.sortBy,
                    sortDirection: action.sortDirection
                }
            };
        }
        case FUNERAL_HOME_NOTES_LOADED: {
            const updatedData = action.offset === 0
                ? action.notes
                : [...state.funeralHomeView.data, ...action.notes];
            return {
                ...state,
                funeralHomeView: {
                    ...state.funeralHomeView,
                    data: updatedData,
                    isLoading: false,
                    hasMoreData: action.hasMoreData
                }
            };
        }
        case FUNERAL_HOME_NOTES_FAILED_TO_LOAD: {
            return {
                ...state,
                funeralHomeView: {
                    ...state.funeralHomeView,
                    isLoading: false
                }
            };
        }
        case UPDATING_CASE: {
            const { assignee } = action.changes;
            if (assignee === undefined) {
                return state;
            }
            return {
                ...state,
                funeralHomeView: {
                    ...state.funeralHomeView,
                    data: state.funeralHomeView.data.map((note) => {
                        if (note.case_uuid !== action.caseUuid) {
                            return note;
                        }
                        return {
                            ...note,
                            case_assignee_full_name: joinNameParts(assignee),
                            case_assignee_id: assignee.user_id ?? -1,
                        };
                    }),
                },
            };
        }
        case SET_NOTES_ACTIVE_CASE_UUID: {
            return {
                ...state,
                activeCaseUuid: action.caseUuid,
            };
        }
        case SET_SHOW_HELPER_NOTES_ONLY_TOGGLE: {
            return {
                ...state,
                funeralHomeView: {
                    ...state.funeralHomeView,
                    showHelperNotesOnly: action.showHelperNotesOnly
                }
            };
        }
        case UPDATE_TASK: {
            const newNote = action.taskChanges.note;
            if (newNote === undefined) {
                return state;
            }
            const funeralHomeView = {
                ...state.funeralHomeView,
                data: state.funeralHomeView.data.map((d) => {
                    if (d.task_id === action.taskId) {
                        return {
                            ...d,
                            notes: newNote ? [newNote] : [],
                        };
                    } else {
                        return d;
                    }
                })
            };

            const taskNotes = state.taskNotes.map((tn) => {
                if (tn.task_id === action.taskId) {
                    return {
                        ...tn,
                        notes: newNote ? [newNote] : [],
                    };
                } else {
                    return tn;
                }
            });

            return {
                ...state,
                funeralHomeView,
                taskNotes,
            };
        }
        default:
            return state;
    }
}