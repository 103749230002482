import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import Fade from '@mui/material/Fade';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';
import { OnlineAccountDeactivateStep } from '../utils';

interface Props {
    onReturnClick: (questionType: OnlineAccountDeactivateStep) => void;
}

const CanNotLoginWrapper = (props: PropsWithChildren<Props>) => {
    const { children, onReturnClick } = props;

    const classes = useTaskDialogStyles();

    return (
        <Fade in>
            <Grid>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(
                        classes.marginBottom10,
                        classes.marginTop20,
                        classes.fontSize16,
                        classes.fontWeight300
                    )}
                >
                    <span
                        className={classes.cursorPointer}
                        onClick={() => onReturnClick(OnlineAccountDeactivateStep.AccessToEmailQueType)}
                    >
                        <IconButton color="primary" size="large">
                            <UndoIcon />
                        </IconButton>
                        Return to previous question
                    </span>
                </Typography>

                {children}
            </Grid>
        </Fade>
    );
};

export default CanNotLoginWrapper;
