import { GatherAction } from '../actions';
import { CasePreviewAction } from '../actions/GatherCase.action';
import * as constants  from '../constants';
import { CasePreviewReduxState, CASE_PREVIEW_STATES } from '../types';
import {
    LOADED_CASE_SWITCHER_DATA,
    LOADING_CASE_SWITCHER_DATA,
    FAILED_LOAD_CASE_SWITCHER_DATA,
} from '../actions/FuneralHome.action';

export const casePreviewReduxInitData: CasePreviewReduxState = {
    data: [],
    hasMoreData: true,
    searchText: '', 
    sortBy: 'id',
    sortDirection: 'desc',
    isLoading: false,
};

export const casePreviewState = (
    state: CasePreviewReduxState = casePreviewReduxInitData,
    action: CasePreviewAction | GatherAction,

): CasePreviewReduxState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return casePreviewReduxInitData;
        case LOADED_CASE_SWITCHER_DATA:
            return {
                ...state,
                data: action.casePreviews.data,
                isLoading: false,
                hasMoreData: false,
            };
        case CASE_PREVIEW_STATES.CASE_PREVIEW_LOADED:
            return {
                ...state,
                data: [...state.data, ...action.data],
                isLoading: false,
                hasMoreData: action.hasMoreData,
            };

        case CASE_PREVIEW_STATES.CASE_PREVIEW_LOADING:
            return {
                ...state,
                data: [...action.data],
                sortDirection: action.sortDirection,
                sortBy: action.sortBy,
                isLoading: true,
            };
        case LOADING_CASE_SWITCHER_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case FAILED_LOAD_CASE_SWITCHER_DATA:
        case CASE_PREVIEW_STATES.CASE_PREVIEW_LOAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default: 
            return state;
    }
};