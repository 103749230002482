import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import ImageListItem from "@mui/material/ImageListItem";
import classNames from "classnames";
import { GatherEvent, GoogleEvent, LocationUX } from "../../../../shared/types";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment-timezone";
import makeGStyles from "../../../../styles/makeGStyles";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { ORANGE_COLOR } from "../../../../constants/colorVariables";

const useStyles = makeGStyles(theme => ({
    gridListTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
        margin: '0 13px',
        '&$eventOverlapping button': {
            background: ORANGE_COLOR
        },
        '&:last-of-type': {
            margin: '0 0 0 13px',
            paddingRight: 13
        }
    },
    gridListTile: {
        margin: '0 auto',
    },
    buttonBoxShadow: {
        borderRadius: 8,
    },
    listTile: {
        overflow: 'unset'
    },
    buttonGroup: {
        minWidth: 270,
        height: 72,
        padding: 0,
        borderRadius: 8,
        position: 'relative',
        '&$scheduleArrangementConference': {
            minWidth: 320,
            width: 320,
            '& $buttonGroupPrepend': {
                verticalAlign: 'middle',
                display: 'table',
                '& $buttonPrependTypography': {
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    '& svg': {
                        fontSize: 40
                    }
                }
            },
            '& $buttonGroupSecondary': {
                '& $buttonSecondaryTypography': {
                    fontSize: 14,
                    '& $secondaryBigText': {
                        fontSize: 17,
                        lineHeight: 1,
                        marginTop: 2,
                        fontWeight: 400
                    },
                }
            }
        },
    },
    buttonGroupSecondary: {
        width: '100%',
        display: 'inline-block',
        '& $buttonSecondaryTypography': {
            position: 'relative',
            fontSize: 16,
            lineHeight: 1.2,
            fontWeight: 200,
            display: 'inline-block',
            padding: '0 26px',
            '& $secondaryBigText': {
                fontSize: 22,
                display: 'block',
                lineHeight: 1,
                marginTop: 4
            },
            '& $secondarySmallText': {
                fontSize: 12,
            }

        }
    },
    liveTvIcon: {
        fontSize: 18,
        marginRight: 8
    },
    eventOverlapping: {},
    buttonPrependTypography: {},
    buttonGroupPrepend: {},
    secondaryBigText: {},
    buttonSecondaryTypography: {},
    secondarySmallText: {},
    scheduleArrangementConference: {},
}), { name: 'EventListByDateItem' });

interface Props {
    event: GatherEvent | GoogleEvent;
    locations: LocationUX[];
    isEventOverlapping: boolean;
}
const EventListByDateItem = (props: Props) => {
    const { locations, event, isEventOverlapping } = props;

    const classes = useStyles();

    const { start_time, end_time } = event;
    if (!start_time || !end_time) {
        return null;
    }
    let location: LocationUX | undefined = undefined;
    let eventCaseStr: string = '';
    let isStreamable: boolean = false;
    let isAllDay: boolean = false;
    let isPrivateEvent: boolean = false;

    if (GatherEvent.isGatherEvent(event)) {
        location = locations.find(loc => loc.id === event.location_id);
        eventCaseStr = ` (${event.case_fname} ${event.case_lname})`;
        isStreamable = event.is_streamable;
        isPrivateEvent = event.is_private;
    } else {
        isAllDay = event.is_all_day;
    }

    const startMoment = moment(start_time);
    let eventTime = isAllDay ? 'All Day' : startMoment.format('h:mm');
    let eventTimePeriod = isAllDay ? undefined : startMoment.format('a');

    if (eventTime && eventTimePeriod && !isAllDay) {
        const endMoment = moment(end_time);
        eventTime = `${eventTime} - ${endMoment.format('h:mm')}`;
        eventTimePeriod = endMoment.format('a');
    }

    return (
        // TODO(Jonathan): Look to   this using List/ListItem
        <ImageListItem
            key={`${event.id} ${event.name}`}
            className={classNames(
                classes.gridListTitle,
                classes.buttonBoxShadow,
                { [classes.eventOverlapping]: isEventOverlapping }
            )}
            classes={{ img: classes.listTile }}
        >
            <ButtonBase
                disabled
                className={classNames(
                    classes.buttonGroup,
                    isEventOverlapping ? classes.colorWhite : classes.colorPrimary,
                    classes.b_solid_1,
                )}
            >
                <span className={classes.buttonGroupSecondary}>
                    <Typography
                        component="span"
                        color="inherit"
                        className={classes.buttonSecondaryTypography}
                    >
                        <span className={classes.displayFlex}>
                            {isPrivateEvent &&
                                <VisibilityOffIcon
                                    color="inherit"
                                    className={classes.liveTvIcon}
                                />
                            }
                            {isStreamable && <LiveTvIcon className={classes.liveTvIcon} color="inherit" />}
                            <span>{event.name}</span>
                            <span>{eventCaseStr}</span>
                        </span>
                        <span className={classes.secondaryBigText}>
                            <span>{eventTime}</span>
                            {eventTimePeriod && <span className={classes.fontSize16}>{eventTimePeriod}</span>}
                        </span>
                        {location &&
                            <span className={classes.secondarySmallText}>
                                {location.name}
                            </span>
                        }
                    </Typography>
                </span>
            </ButtonBase>
        </ImageListItem>
    );
};

export default EventListByDateItem;