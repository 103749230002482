import { MouseEventHandler, useState } from "react";
import classNames from "classnames";

import PushPinIcon from "@mui/icons-material/PushPin";
import LocationOn from "@mui/icons-material/LocationOn";

import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { alpha } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

import makeGStyles from "../../../../../styles/makeGStyles";

import GTooltip from "../../../../common/GTooltip";
import { getIntercomTargetProp } from '../../../../../services';

interface Props {
    name: string;
    imageUrl: string | null;
    casesInLocation: number | null;
    mainTooltip?: string;
    badgeTooltip?: string;
    durationText: string;
    durationTooltip: string;
    headerText?: string;
    isGreen: boolean;
    showPin: boolean;
    dashedBorder: boolean;
    isCompact?: boolean;
    tooltipPlacement?: TooltipProps['placement'];
    disableGutters?: boolean;
    onSelect?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    intercomTargetProp?: string;
}

const useStyles = makeGStyles<Props>(({ palette }) => ({
    listItem: {
        position: 'relative',
        marginLeft: props => props.disableGutters ? undefined : '20px',
        maxWidth: props => props.isCompact ? 70 : 100,
        '&:last-child': {
            maxWidth: props => props.isCompact
                ? 70
                : props.disableGutters ? 100 : 120,
            paddingRight: props => props.disableGutters ? undefined : '20px'
        }
    },
    imageBtn: {
        borderRadius: 8,
        width: props => props.isCompact ? 70 : 100,
        height: props => props.isCompact ? 70 : 100,
        border: props => !props.dashedBorder
            ? `2px solid ${props.isGreen ? palette.success.main : palette.secondary.main}`
            : `2px dashed ${palette.secondary.main}`,
        position: 'relative',
    },
    countBadge: {
        background: palette.primary.main,
        position: 'absolute',
        top: -2,
        left: -2,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 8,
        color: '#fff',
        minWidth: 20,
        minHeight: 20,
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        fontSize: 14,
        borderColor: props => props.isGreen
            ? palette.success.main
            : palette.secondary.main,
        borderWidth: props => !props.dashedBorder ? '2px 0 0 2px' : 0,
        borderStyle: 'solid',
        boxSizing: 'border-box',
        padding: props => props.dashedBorder ? undefined : '0 2px 2px 0'
    },
    locationImage: {
        objectFit: 'cover',
        borderRadius: 6,
        display: 'block'
    },
    currentLocation: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px'
    },
    imageBackground: {
        background: alpha('#fff', 0.5),
    },
    currentText: {
        position: 'absolute',
        left: '50%',
        top: -16,
        transform: 'translateX(-50%)',
        width: 'max-content',
        fontSize: 12,
        color: '#fff',
        lineHeight: 1,
        background: props => props.isGreen
            ? palette.success.main
            : palette.secondary.main,
        padding: '2px 8px 0',
        borderRadius: '4px 4px 0 0'
    },
    pushPinIcon: {
        fontSize: 60,
        margin: '12px auto auto',
        color: props => props.isGreen
            ? palette.success.main
            : palette.secondary.main,
    },
    timeContainer: {
        width: '100%',
        marginTop: 'auto',
        overflow: 'hidden',
        color: '#fff',
        background: props => props.isGreen
            ? palette.success.main
            : palette.secondary.main
    },
    time: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        lineHeight: 1,
        padding: props => props.isCompact ? '2px 0 0' : '2px 0',
        fontSize: props => props.isCompact ? 12 : undefined,
    },
    locationName: {
        lineClamp: props => props.isCompact ? 2 : 3,
        WebkitLineClamp: props => props.isCompact ? 2 : 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        marginTop: props => props.isCompact ? 2 : undefined,
        color: props => props.isGreen
            ? palette.success.main
            : palette.secondary.main,
        position: props => props.isCompact ? 'absolute' : undefined,
        width: props => props.isCompact ? '100%' : undefined
    },
    disableLocation: {
        opacity: '30%'
    }
}), { name: 'LocationItemDisplay' });

const commonTooltipProps: Partial<TooltipProps> = {
    enterDelay: 600,
    enterNextDelay: 600,
    placement: 'top',
};

const LocationItemDisplay = (props: Props) => {
    const {
        durationText,
        showPin,
        mainTooltip,
        badgeTooltip,
        durationTooltip,
        headerText,
        casesInLocation,
        tooltipPlacement,
        imageUrl,
        name,
        onSelect,
        disabled,
        isCompact,
        intercomTargetProp
    } = props;

    const classes = useStyles(props);
    const [badgeTooltipOpen, setBadgeTooltipOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [locationTooltipOpen, setLocationTooltipOpen] = useState(false);
    const selectFunc = disabled ? undefined : onSelect;

    return (
        <Grid
            item
            className={classNames(classes.listItem, disabled && classes.disableLocation)}
            {...getIntercomTargetProp(intercomTargetProp)}
        >
            <GTooltip
                {...commonTooltipProps}
                contentDisabled={!selectFunc}
                placement={tooltipPlacement ?? 'top'}
                open={badgeTooltipOpen || locationTooltipOpen ? false : tooltipOpen}
                title={mainTooltip || ''}
                onOpen={() => setTooltipOpen(true)}
                onClose={() => setTooltipOpen(false)}
            >
                <ButtonBase
                    className={classes.imageBtn}
                    onClick={selectFunc}
                    disabled={!selectFunc}
                >
                    {imageUrl
                        ? <img
                            src={imageUrl ?? ''}
                            style={{ objectFit: "cover" }}
                            width="100%"
                            height="100%"
                            className={classes.locationImage}
                        />
                        : <LocationOn
                            color="primary"
                            className={isCompact ? classes.fontSize48 : classes.fontSize74}
                        />
                    }
                    <Fade timeout={200} in={true} mountOnEnter unmountOnExit>
                        <Box className={classNames(classes.currentLocation, showPin && classes.imageBackground)}>
                            {headerText &&
                                <Typography className={classes.currentText}>
                                    {headerText}
                                </Typography>
                            }

                            {showPin && <PushPinIcon className={classes.pushPinIcon} />}

                            {durationText &&
                                <Tooltip
                                    {...commonTooltipProps}
                                    placement={tooltipPlacement}
                                    title={durationTooltip}
                                    open={locationTooltipOpen}
                                    onOpen={() => setLocationTooltipOpen(true)}
                                    onClose={() => setLocationTooltipOpen(false)}
                                >
                                    <Box className={classes.timeContainer}>
                                        <Typography
                                            color="inherit"
                                            className={classes.time}
                                        >
                                            {durationText}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            }
                        </Box>
                    </Fade>

                    {!!casesInLocation &&
                        <Tooltip
                            {...commonTooltipProps}
                            placement={tooltipPlacement}
                            title={badgeTooltip || ''}
                            open={badgeTooltipOpen}
                            onOpen={() => setBadgeTooltipOpen(true)}
                            onClose={() => setBadgeTooltipOpen(false)}
                        >
                            <Box className={classNames(
                                classes.flexCentred,
                                classes.countBadge,
                                'location-casesInLocation'
                            )}>
                                {casesInLocation}
                            </Box>
                        </Tooltip>
                    }
                </ButtonBase>
            </GTooltip>

            <Typography
                align="center"
                color="secondary"
                className={classNames(
                    classes.fontSize12,
                    classes.locationName,
                    classes.paragraphLineHeight,
                    classes.marginTop2
                )}
            >
                {name}
            </Typography>
        </Grid>
    );
};

export default LocationItemDisplay;