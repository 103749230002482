import * as React from 'react';

import Grid from '@mui/material/Grid';

import ModerationPhoto from './ModerationPhoto';

const Masonry = require('react-responsive-masonry/lib/Masonry/index');
const ResponsiveMasonry = require('react-responsive-masonry/lib/ResponsiveMasonry/index');

import {
    ModerationPhotoUX,
    ModerationStatus,
    ModerationCategory,
    ModerationPhotoContributor
} from '../../../../shared/types';
import BulkModerateButtons from '../BulkModerateButtons';

interface Props {
    photos: ModerationPhotoUX[];
    moderationStatus: ModerationStatus;
    isRememberPage: boolean;
    showIndividualCards: boolean;
    openMenu: (event: React.MouseEvent<HTMLElement>, photo: ModerationPhotoUX) => void;
    onDecision: (photos: ModerationPhotoUX[], isApproval: boolean) => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, contributor: ModerationPhotoContributor) => void;
}

const PhotosSection = (props: Props) => {
    const {
        photos,
        moderationStatus,
        isRememberPage,
        openMenu,
        onDecision,
        openGuestPopper,
        showIndividualCards,
    } = props;
    // Sort photos by case_id and photo.photo_id
    const sortedPhotos = photos.sort((a, b) => a.case_id === b.case_id
        ? a.photo.photo_id - b.photo.photo_id
        : a.case_id - b.case_id
    );
    return (
        <Grid item xs={12} p="0 20px">
            <ResponsiveMasonry columnsCountBreakPoints={{ 280: 1, 320: 2, 560: 3, 800: 4 }}>
                <Masonry gutter="15px">
                    {sortedPhotos.map((mPhoto) => (
                        <ModerationPhoto
                            key={mPhoto.photo.photo_id}
                            moderationPhoto={mPhoto}
                            openMenu={openMenu}
                            onApprove={() => onDecision([mPhoto], true)}
                            onBlock={() => onDecision([mPhoto], false)}
                            openGuestPopper={openGuestPopper}
                            isRememberPage={isRememberPage}
                            showHeader={showIndividualCards}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>

            {moderationStatus === ModerationStatus.pending &&
                <BulkModerateButtons
                    itemCount={photos.length}
                    category={ModerationCategory.photos}
                    onApprove={() => onDecision(photos, true)}
                    onBlock={() => onDecision(photos, false)}
                />
            }
        </Grid>
    );
};

export default PhotosSection;