// ---> Deep Link Data <---
import { UserRole } from './user';
import { ResetCodeReason } from '.';
import { AppRoute } from '../navigationUtils';

export enum DeepLinkTypeEnum {
    reset_password = 'reset_password',
    gather_admin = 'gather_admin',
    funeral_home = 'funeral_home',
    case = 'case',
    task = 'task',
    contract = 'contract',
    payment_receipt = 'payment_receipt',
    remember = 'remember',
    organize = 'organize',
    google_calendar = 'google_calendar',
}

export interface DeepLinkRecord {
    id: string;
    user_id: number | null;
    gather_case_id: number | null;
    funeral_home_id: number | null;
    task_id: number | null;
    public_link: boolean;
    link_type: DeepLinkTypeEnum;
    created_time: Date;
    updated_time: Date;
    accessed_time: Date;
}

export interface DeepLinkRecordInsert extends Pick<DeepLinkRecord,
    'id'
    | 'link_type'
>, Pick<Partial<DeepLinkRecord>,
    'user_id'
    | 'gather_case_id'
    | 'funeral_home_id'
    | 'task_id'
    | 'public_link'
    > {
}

export interface DeepLinkRecordUpdate extends Pick<DeepLinkRecord, 'accessed_time'> {}

export interface DeepLinkUX {
    type: DeepLinkTypeEnum;
    targetPage: AppRoute;
    publicLink: boolean;
    userId: number | null;
    caseId: number | null;
    taskId: number | null;
    funeralHomeId: number | null;
    fname: string | null;
    email: string | null;
    phone: string | null;
    role?: UserRole;
    configured: boolean;
    resetCode: string | null;
    resetCodeReason?: ResetCodeReason;
    caseName?: string;
    caseFirstName?: string;
    fhLogo?: string;
    fhLogoTransformations?: string;
    fhIcon?: string;
    fhIconTransformations?: string;
    fhThemeColor?: string;
}