import { isSentryEnabled, sentryCapture, originalConsoleWarn } from './sentry';
import { sendToSentry, SentryMetaData, SentryTags } from './shared/errorUtils';

const warn = (msg: string | Error, meta?: SentryMetaData, tags?: SentryTags, sendOnlyOnce?: boolean): void => {
    if (isSentryEnabled()) {
        sendToSentry({
            sentryCapture,
            msg,
            meta,
            tags,
            prefix: 'WARNING',
            onlyOnce: sendOnlyOnce,
        });
    }
    originalConsoleWarn(msg, meta);
};

const warnOneTime = (msg: string | Error, meta?: SentryMetaData, tags?: SentryTags): void => {
    warn(msg, meta, tags, true);
};

const error = (msg: string | Error, meta?: SentryMetaData, tags?: SentryTags): void => {
    if (isSentryEnabled()) {
        sendToSentry({
            sentryCapture,
            msg,
            meta,
            tags,
            prefix: 'ERROR',
        });
    }
    originalConsoleWarn(msg, meta);
};

export const log = {
    warn,
    error,
    warnOneTime,
};
