/* tslint:disable */
import classNames from 'classnames';
import { values } from 'lodash';

import Grid from '@mui/material/Grid';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import {
    GatherCaseUX,
    DeathCertificateMilitaryType,
    YesNoUnknownEnum,
    MilitaryBranchOption,
    CaseType,
    YesNoEnum,
    FeatureKey,
} from '../../../../shared/types';

import { FormParent, ParentProps } from './FormParent';
import { militaryMasterValidators, militaryValidators } from '../../../../shared/death_certificate/validators/military';

import FormDD214 from '../military/FormDD214';
import FormTitle from '../common/FormTitle';
import ConfigurableToggleButtonsWrapper from '../common/fields/toggleButtons/ConfigurableToggleButtons';
import { ConfigurableMilitaryFieldKey } from '../../../../shared/death_certificate/validators/config';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';
import ConfigurableDateField from '../common/fields/dateField/ConfigurableDateField';
import DCToggleButtons from '../common/fields/toggleButtons/DCToggleButtons';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import { ConfigurableToggleButtonsForYesNo } from './AboutForm';
import { WhenEnabled } from '../../../common/AppFeature';

const ConfigurableMilitaryBranchButtons = ConfigurableToggleButtonsWrapper<MilitaryBranchOption>();

const isMilitaryBranch = (str: string): str is MilitaryBranchOption => {
    return Boolean(MilitaryBranchOption[str]);
};

interface Props {
    activeCase: GatherCaseUX;
    canUserManageDD214OnCase: boolean;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateMilitaryType;
    isServiceInMilitaryValidator: boolean | null;
    militaryBranchValidator: boolean | null;
    militaryRankValidator: boolean | null;
    militaryYearsValidator: boolean | null;
    militaryServiceNumberValidator: boolean | null;
    militaryHonorsValidator: boolean | null;
    disabilityContributedToDeathValidator: boolean | null;
    fromServiceDateValidator: boolean | null;
    toServiceDateValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateMilitaryType>> = theme => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        margin: 'auto',
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
        },
    },
    toggleContainer: {
        height: 56,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '@media (max-width: 500px)': {
            marginTop: 10,
        },
    },
    toggleButton: {
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    disabilityToggle: {
        padding: '4px 20px',
    },
    toggleButtonGroupLabel: {
        position: 'relative',
        transform: 'none',
        textAlign: 'center',
    },
    inputTextWidth: {
        width: '100%',
        '@media (min-width: 421px)': {
            width: 360,
        },
    },
    displayFlex: {
        display: 'flex',
    },
    dateTimeGrid: {
        width: '100%',
        display: 'inline-block',
        '& $datePickerGrid': {
            width: '100%',
            maxWidth: 170,
        },
        '&$dateTimeGrid:nth-child(even)': {
            paddingLeft: 10,
            textAlign: 'left',
        },
        '&$dateTimeGrid:nth-child(odd)': {
            paddingRight: 10,
            textAlign: 'right',
        },
        '@media (max-width: 350px)': {
            '& input': {
                marginRight: '-10px',
            }
        },
    },
    datePickerGrid: {
        width: 190,
        '@media (min-width: 421px)': {
            width: 280,
        },
    }
});

type StyledProps = Props & GLOBAL_STYLED_PROPS & WithStyles<
    'root' | 'inviteHelperForm' | 'buttonSuccess' | 'toggleButton' | 'toggleButtonGroupLabel' |
    'toggleContainer' | 'inputTextWidth' | 'disabilityToggle' | 'dateTimeGrid' |
    'displayFlex' | 'datePickerGrid' | 'buttonClass'>;

class MilitaryForm extends FormParent<DeathCertificateMilitaryType, StyledProps, State> {

    state = {
        localData: this.props.data,
        isServiceInMilitaryValidator: null,
        militaryBranchValidator: null,
        militaryRankValidator: null,
        militaryYearsValidator: null,
        militaryServiceNumberValidator: null,
        militaryHonorsValidator: null,
        disabilityContributedToDeathValidator: null,
        isTouchedLocal: false,
        fromServiceDateValidator: null,
        toServiceDateValidator: null
    };

    handleServiceNumberChange = (value: string) => {
        this.updateData({ militaryServiceNumber: value }, this.validateMilitaryServiceNumber);
    };

    handleMilitaryRankChange = (value: string) => {
        this.updateData({ militaryRank: value }, this.validateMilitaryRank);
    };

    handleChangeMilitaryYearsEvent = (value: string) => {
        this.updateData({ militaryYears: Number(value) }, this.validateMilitaryYears);
    };

    handleIsServiceInMilitaryChange = (military: string) => {
        const militaryValue = YesNoUnknownEnum[military];
        const changes: Partial<DeathCertificateMilitaryType> = {
            military: militaryValue,
        };
        if (military === 'No' || military === 'Unknown') {
            changes.militaryBranch = [];
            changes.militaryRank = '';
            changes.militaryYears = undefined;
            changes.militaryServiceNumber = '';
            changes.militaryHonors = [];
            changes.disabilityContributedToDeath = undefined;
        }
        this.updateData(changes, this.validateMilitary);
    };

    handleServiceDisabilityChange = (disabilityContributedToDeath: boolean) => {
        this.updateData({ disabilityContributedToDeath }, this.validateDisabilityContributedToDead);
    };

    handleBranchNameChange = (branchName: string) => {

        if (!isMilitaryBranch(branchName)) {
            console.warn(`Invalid military branch: ${branchName}`);
            return;
        }
        this.updateData((prevData) => {
            let militaryBranch = prevData.militaryBranch;
            if (!militaryBranch) {
                militaryBranch = [];
            }

            // if for some reason, list from server is not a list, its a string, cast it
            if (!Array.isArray(militaryBranch)) {
                militaryBranch = [militaryBranch];
            }

            const isBranchFound = militaryBranch.some((item: string) => branchName === item);

            const tempList = isBranchFound
                ? militaryBranch.filter((item: string) => branchName !== item)
                : [...militaryBranch, branchName];

            this.setState({
                militaryBranchValidator: tempList.length === 0,
            });
            return {
                militaryBranch: tempList,
            };
        }, this.validateMilitaryBranch);
    };

    getMilitaryHonors = () => {
        const { localData } = this.state;
        return localData.militaryHonors ?
            localData.militaryHonors.toString() : '';
    };

    handleChangeMilitaryHonors = (value: string) => {
        const militaryHonors: string[] = value.split(',');

        this.updateData({ militaryHonors }, this.validateMilitaryHonors);
    };

    handleFromServiceDateChange = (value: string) => {
        this.updateData(
            { serviceDateFrom: value },
            () => {
                this.validateFromServiceDate();
                this.validateToServiceDate();
            }
        );
    };

    handleToServiceDateChange = (value: string) => {
        this.updateData(
            { serviceDateTo: value },
            () => {
                this.validateToServiceDate();
                this.validateFromServiceDate();
            }
        );
    };

    // VALIDATION

    validateMilitary = () => {
        const { localData } = this.state;
        this.setState({
            isServiceInMilitaryValidator: militaryValidators.military(localData),
        });
    };

    validateMilitaryBranch = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            militaryBranchValidator: militaryValidators.militaryBranch(localData, dcConfig, user),
        });
    };

    validateMilitaryRank = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            militaryRankValidator: militaryValidators.militaryRank(localData, dcConfig, user),
        });
    };

    validateMilitaryYears = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            militaryYearsValidator: militaryValidators.militaryYears(localData, dcConfig, user),
        });
    };

    validateMilitaryServiceNumber = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            militaryServiceNumberValidator: militaryValidators.militaryServiceNumber(localData, dcConfig, user),
        });
    };

    validateMilitaryHonors = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            militaryHonorsValidator: militaryValidators.militaryHonors(localData, dcConfig, user),
        });
    };

    validateDisabilityContributedToDead = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;

        this.setState({
            disabilityContributedToDeathValidator:
                militaryValidators.disabilityContributed(localData, dcConfig, activeCase, user),
        });
    };

    validateFromServiceDate = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            fromServiceDateValidator: militaryValidators.fromServiceDate(localData, dcConfig, user),
        });
    };

    validateToServiceDate = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            toServiceDateValidator: militaryValidators.toServiceDate(localData, dcConfig, user),
        });
    };

    checkValid = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        return militaryMasterValidators.valid(localData, dcConfig, activeCase, user);
    };

    checkIfTouched = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        return militaryMasterValidators.touched(localData, dcConfig, activeCase, user);
    };

    validateAll = () => {
        this.validateMilitary();
        this.validateMilitaryBranch();
        this.validateMilitaryRank();
        this.validateMilitaryYears();
        this.validateMilitaryServiceNumber();
        this.validateMilitaryHonors();
        this.validateDisabilityContributedToDead();
        this.validateFromServiceDate();
        this.validateToServiceDate();
    };

    getContentIfJobInMilitary = () => {
        const {
            classes,
            activeCase,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode,
        } = this.props;
        const {
            militaryBranchValidator,
            militaryRankValidator,
            localData,
            militaryYearsValidator,
            militaryServiceNumberValidator,
            militaryHonorsValidator,
            disabilityContributedToDeathValidator,
            fromServiceDateValidator,
            toServiceDateValidator
        } = this.state;

        let deathRelatedToDisabilityValue = null;
        if (localData.disabilityContributedToDeath !== undefined) {
            if (localData.disabilityContributedToDeath) {
                deathRelatedToDisabilityValue = YesNoEnum.Yes;
            } else {
                deathRelatedToDisabilityValue = YesNoEnum.No;
            }
        }

        return (
            <>
                <Grid container alignItems="flex-end" justifyContent="center" >
                    <Grid item className={classes.width100}>
                        <ConfigurableMilitaryBranchButtons
                            id={ConfigurableMilitaryFieldKey.militaryBranch}
                            label="Which Branch?"
                            options={values(MilitaryBranchOption)}
                            isEditMode={isEditMode}
                            selected={localData.militaryBranch || null}
                            onSelect={this.handleBranchNameChange}
                            error={militaryBranchValidator === false}
                            disabled={isDeathCertificateLocked}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-end" justifyContent="center" >
                    <Grid item className={classNames(classes.width100, classes.textCenter)}>
                        <ConfigurableTextField
                            id={ConfigurableMilitaryFieldKey.militaryRank}
                            value={localData.militaryRank || ''}
                            label="Highest Military Rank"
                            onChange={this.handleMilitaryRankChange}
                            onBlur={this.validateMilitaryRank}
                            disabled={isDeathCertificateLocked}
                            handleOpenSnackbar={handleOpenSnackbar}
                            isEditMode={isEditMode}
                            error={militaryRankValidator === false}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-end" justifyContent="center" >
                    <Grid item className={classNames(classes.width100, classes.textCenter)}>
                        <ConfigurableTextField
                            id={ConfigurableMilitaryFieldKey.yearsServed}
                            label="Number of Years Served"
                            value={(localData.militaryYears && localData.militaryYears.toString()) || ''}
                            onChange={this.handleChangeMilitaryYearsEvent}
                            onBlur={this.validateMilitaryYears}
                            inputType="number"
                            disabled={isDeathCertificateLocked}
                            handleOpenSnackbar={handleOpenSnackbar}
                            isEditMode={isEditMode}
                            error={militaryYearsValidator === false}
                        />
                    </Grid>
                </Grid>

                <div className={classes.displayFlex}>
                    <Grid
                        item
                        className={classNames(
                            classes.textCenter,
                            classes.marginTop20,
                            classes.dateTimeGrid
                        )}
                        xs={6}
                    >
                        <ConfigurableDateField
                            id={ConfigurableMilitaryFieldKey.fromServiceDate}
                            label="Service Dates (from)"
                            value={localData.serviceDateFrom || ''}
                            onChange={this.handleFromServiceDateChange}
                            disabled={isDeathCertificateLocked}
                            isEditMode={isEditMode}
                            error={fromServiceDateValidator === false}
                            handleOpenSnackbar={handleOpenSnackbar}
                            onBlur={this.validateFromServiceDate}
                            rootClass={classNames(
                                classes.datePickerGrid,
                                classes.textLeft,
                                classes.marginTop10,
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classNames(
                            classes.textCenter,
                            classes.marginTop20,
                            classes.dateTimeGrid
                        )}
                        xs={6}
                    >
                        <ConfigurableDateField
                            id={ConfigurableMilitaryFieldKey.toServiceDate}
                            label="Service Dates (to)"
                            value={localData.serviceDateTo || ''}
                            onChange={this.handleToServiceDateChange}
                            disabled={isDeathCertificateLocked}
                            isEditMode={isEditMode}
                            error={toServiceDateValidator === false}
                            handleOpenSnackbar={handleOpenSnackbar}
                            onBlur={this.validateToServiceDate}
                            rootClass={classNames(
                                classes.datePickerGrid,
                                classes.textLeft,
                                classes.marginTop10,
                            )}
                        />
                    </Grid>
                </div>

                <Grid container alignItems="flex-end" justifyContent="center" >
                    <Grid item className={classNames(classes.width100, classes.textCenter)}>
                        <ConfigurableTextField
                            id={ConfigurableMilitaryFieldKey.militaryServiceNumber}
                            label="Military Service Number"
                            value={localData.militaryServiceNumber || ''}
                            onChange={this.handleServiceNumberChange}
                            onBlur={this.validateMilitaryServiceNumber}
                            error={militaryServiceNumberValidator === false}
                            disabled={isDeathCertificateLocked}
                            isEditMode={isEditMode}
                            handleOpenSnackbar={handleOpenSnackbar}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-end" justifyContent="center" >
                    <Grid item className={classNames(classes.width100, classes.textCenter)}>
                        <ConfigurableTextField
                            id={ConfigurableMilitaryFieldKey.militaryHonors}
                            label="Military Honors"
                            value={this.getMilitaryHonors()}
                            onChange={this.handleChangeMilitaryHonors}
                            onBlur={this.validateMilitaryHonors}
                            error={militaryHonorsValidator === false}
                            multiline
                            disabled={isDeathCertificateLocked}
                            handleOpenSnackbar={handleOpenSnackbar}
                            isEditMode={isEditMode}
                        />
                    </Grid>
                </Grid>

                {activeCase.case_type !== CaseType.pre_need &&
                    <Grid container alignItems="flex-end" justifyContent="center" >
                        <Grid item className={classes.width100}>
                            <ConfigurableToggleButtonsForYesNo
                                id={ConfigurableMilitaryFieldKey.serviceConnectedDisability}
                                label={`Did a service-connected disability` +
                                    ` contribute to ${activeCase.fname}'s death?`}
                                options={values(YesNoEnum)}
                                isEditMode={isEditMode}
                                selected={deathRelatedToDisabilityValue}
                                onSelect={(value: string) => {
                                    const valAsBoolean = value === 'Yes' ? true : false;
                                    this.handleServiceDisabilityChange(valAsBoolean);
                                }}
                                error={disabilityContributedToDeathValidator === false}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </Grid>
                }
            </>
        );
    };

    render() {
        const {
            classes,
            activeCase,
            isDeathCertificateLocked,
            canUserManageDD214OnCase,
            handleOpenSnackbar,
        } = this.props;
        const {
            isServiceInMilitaryValidator,
            localData,
        } = this.state;

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Military" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(classes.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container alignItems="flex-end" justifyContent="center" >
                            <Grid item className={classes.width100}>
                                <DCToggleButtons
                                    id="military_service"
                                    required
                                    error={isServiceInMilitaryValidator === false}
                                    label={`Did ${activeCase.fname} serve in the U.S. Military?`}
                                    selected={localData.military || ''}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    options={values(YesNoUnknownEnum)}
                                    onSelect={this.handleIsServiceInMilitaryChange}
                                    disabled={isDeathCertificateLocked}
                                />
                            </Grid>
                        </Grid>
                        {localData.military === 'Yes' ? this.getContentIfJobInMilitary() : null}
                    </form>
                </Grid>


                {canUserManageDD214OnCase && localData.military === YesNoUnknownEnum.Yes &&
                    <WhenEnabled feature={FeatureKey.DOC_LIBRARY_AND_AUTOFORMS}>
                        <Grid container justifyContent="center" alignContent="center" className={classes.marginTop30}>
                            <FormDD214
                                activeCase={activeCase}
                                disabled={isDeathCertificateLocked}
                            />
                        </Grid>
                    </WhenEnabled>
                }
            </div>
        );
    }
}

export default withGStyles(styles)(MilitaryForm);
