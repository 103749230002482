import * as React from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { convertHexToRGBA, getFormattedPhoneNumber } from '../../../services/app.service';
import PhoneNumberMask from '../../common/PhoneNumberMask';
import {
    getUserFuneralHomeSettings,
    UsePreference,
    UserFuneralHomeSettings,
    UserFuneralHomeUX,
} from '../../../shared/types';
import { debounce } from 'lodash';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import FuneralHomeSelector, { mapUserFuneralHomeUXToElement } from './FuneralHomeSelector';

const styles: StyleRulesCallback<Theme, Props, Classes> = (theme) => ({
    root: {
        minHeight: 522
    },
    emailPhoneContainer: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.primary.main}`,
        background: convertHexToRGBA(theme.palette.primary.main, 0.08),
        padding: '8px 8px 16px !important',
        width: '100%',
        '& $containerHeader': {
            marginBottom: 8,
            lineHeight: 1.25,
        },
        '& form': {
            padding: 0
        },
    },
    otherLabel: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            marginRight: 8
        }
    },
    radioClass: {
        width: 36,
        height: 32,
        marginLeft: 8
    },
    labelClass: {
        display: 'flex',
        alignItems: 'center',
        '& p:nth-child(2)': {
            fontSize: 12
        }
    },
    minWidth100: {
        minWidth: '100%'
    },
    marginRight0: {
        marginRight: 0
    },
    form: {
        padding: '0 16px',
        margin: 'auto',
        maxWidth: 320,
        display: 'flex',
        justifyContent: 'center',
        '@media (min-width: 768px)': {
            maxWidth: '100%',
        }
    },
    formsContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 760,
        '@media (min-width: 768px)': {
            flexDirection: 'row',
            justifyContent: 'center'
        }
    },
    firstContainer: {
        maxWidth: 356,
        '@media (min-width: 768px)': {
            marginRight: 16
        }
    },
    secondContainer: {
        maxWidth: 356,
        marginTop: 16,
        '@media (min-width: 768px)': {
            margin: 0
        }
    },
    marginTop16: {
        marginTop: 16
    },
    bottomText: {
        fontSize: 12,
        textAlign: 'center',
        marginTop: 4,
        lineHeight: 1.25
    },
    whiteSpacePre: {
        whiteSpace: 'pre'
    },
    containerHeader: {},
});

type Classes = 'root' | 'containerHeader' | 'marginRight0'
    | 'labelClass' | 'radioClass' | 'otherLabel' | 'emailPhoneContainer' | 'form' | 'formsContainer' | 'marginTop16'
    | 'firstContainer' | 'secondContainer' | 'bottomText' | 'whiteSpacePre';
type StyledProps = WithGStyles<Classes>;

interface Props {
    fname: string;
    lname: string;
    primaryEmail: string;
    primaryPhone: string;
    funeralHomeSettings: UserFuneralHomeSettings[];
    onPreferenceChanges: (type: 'phone' | 'email', funeralHomeId: number, pref: UsePreference) => void;
    onPhoneChange: (phone: string) => void;
    onEmailChange: (email: string) => void;
    selectFuneralHome: (id: number) => void;
    isSaving: boolean;
    disableLoginCredentials: boolean;
    funeralHomes: UserFuneralHomeUX[];
    selectedFuneralHomeId: number | null;
    isEmailValid: boolean;
    isPhoneValid: boolean;
}

type CombinedProps = Props & StyledProps;

interface State {
    otherPhone: string;
    otherEmail: string;
}

const setStateFromProps = (props: CombinedProps): State => {
    const { funeralHomeSettings, selectedFuneralHomeId } = props;
    const thisUserFuneralHomeSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
    return {
        otherPhone: thisUserFuneralHomeSettings && thisUserFuneralHomeSettings.visible_phone
            ? thisUserFuneralHomeSettings.visible_phone : '',
        otherEmail: thisUserFuneralHomeSettings && thisUserFuneralHomeSettings.visible_email
            ? thisUserFuneralHomeSettings.visible_email : '',
    };
};
class CommunicationTab extends React.Component<CombinedProps, State> {

    debounceOtherEmailValue = debounce(
        (otherEmail: string) => {
            const { onEmailChange } = this.props;
            onEmailChange(otherEmail);
        },
        300,
    );

    debounceOtherPhoneValue = debounce(
        (otherPhone: string) => {
            const { onPhoneChange } = this.props;
            onPhoneChange(otherPhone);
        },
        300,
    );

    constructor(props: CombinedProps) {
        super(props);
        this.state = setStateFromProps(props);
    }

    componentWillUnmount() {
        // save the last changes before unmounting
        this.debounceOtherEmailValue.flush();
        this.debounceOtherPhoneValue.flush();
    }

    renderEmailPhoneContent = () => {
        const {
            classes,
            isEmailValid,
            isPhoneValid,
            isSaving,
            disableLoginCredentials,
            primaryEmail,
            primaryPhone,
            funeralHomes,
            selectedFuneralHomeId,
            funeralHomeSettings,
        } = this.props;

        const { otherEmail, otherPhone } = this.state;
        const funeralHome = funeralHomes.find(fh => fh.funeral_home_id === selectedFuneralHomeId);
        const fhEmail = funeralHome ? funeralHome.funeral_home_email : '';
        const fhPhone = funeralHome ? funeralHome.funeral_home_phone : '';

        const thisUserFuneralHomeSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
        const defaultEmailChoice =
            primaryEmail && primaryEmail !== '' ? UsePreference.user_primary
                : funeralHome && funeralHome.funeral_home_email !== '' ? UsePreference.funeral_home
                    : UsePreference.override;

        const defaultPhoneChoice =
            primaryPhone && primaryPhone !== '' ? UsePreference.user_primary
                : funeralHome && funeralHome.funeral_home_phone !== '' ? UsePreference.funeral_home
                    : UsePreference.override;
        const phoneRadio = thisUserFuneralHomeSettings && thisUserFuneralHomeSettings.use_phone_pref
            ? thisUserFuneralHomeSettings.use_phone_pref : defaultPhoneChoice;
        const emailRadio = thisUserFuneralHomeSettings && thisUserFuneralHomeSettings.use_email_pref
            ? thisUserFuneralHomeSettings.use_email_pref : defaultEmailChoice;

        return (
            <form
                className={classNames(classes.overflowHidden, classes.form)}
                autoComplete="off"
            >
                <Grid item className={classNames(classes.formsContainer, classes.marginTop16)}>
                    <Grid item xs={12} className={classes.firstContainer}>
                        <Grid
                            item
                            xs={12}
                            className={classes.emailPhoneContainer}
                        >
                            {funeralHome &&
                                <Typography color="primary" className={classes.containerHeader}>
                                    Which EMAIL would you like to display to families
                                    on {funeralHome.funeral_home_name} ({funeralHome.funeral_home_city})?
                                </Typography>
                            }

                            <RadioGroup value={emailRadio} onChange={this.handleEmailRadioChange}>
                                <FormControlLabel
                                    disabled={!primaryEmail}
                                    value={UsePreference.user_primary}
                                    control={<Radio color="primary" className={classes.radioClass} />}
                                    label={<span className={classes.labelClass}>
                                        <Typography color="primary">Primary</Typography>&nbsp;
                                        <Typography
                                            color="secondary"
                                        >
                                            ({primaryEmail})
                                        </Typography>
                                    </span>}
                                    labelPlacement="end"
                                    name="primaryEmail" />
                                <FormControlLabel
                                    disabled={!fhEmail}
                                    value={UsePreference.funeral_home}
                                    control={<Radio color="primary" className={classes.radioClass} />}
                                    label={<span className={classes.labelClass}>
                                        <Typography color="primary" className={classes.whiteSpacePre}>
                                            Funeral Home
                                        </Typography>&nbsp;
                                        <Typography
                                            color="secondary"
                                        >
                                            ({fhEmail && fhEmail || 'No Email Provided'})
                                        </Typography>
                                    </span>}
                                    classes={{ label: classes.colorPrimary }}
                                    onClick={(e) => this.handleOtherEmailValueChange('')}
                                    labelPlacement="end"
                                    name="fhEmail" />
                                <FormControlLabel
                                    value={UsePreference.override}
                                    name="otherEmail"
                                    labelPlacement="end"
                                    control={<Radio color="primary" className={classes.radioClass} />}
                                    label={<div className={classes.otherLabel}>
                                        <span>Other:</span>
                                        <TextField
                                            placeholder="Type Email Here"
                                            type="email"
                                            id="otherEmail"
                                            name="otherEmail"
                                            value={otherEmail}
                                            className={classes.width100}
                                            onChange={(e) => this.handleOtherEmailValueChange(e.target.value)}
                                            autoComplete="new-password"
                                            disabled={isSaving
                                                || disableLoginCredentials
                                                || emailRadio !== UsePreference.override}
                                            error={!isEmailValid}
                                            color="secondary"
                                            fullWidth />
                                    </div>}
                                    classes={{
                                        label: classNames(classes.colorPrimary, classes.width100),
                                        root: classes.marginRight0
                                    }}
                                />
                            </RadioGroup>
                        </Grid>
                        <Typography className={classNames(classes.bottomText, classes.colorRed)}>
                            <span className={classes.fontWeight500}>
                                {primaryEmail}
                            </span>
                            {primaryEmail && <span>
                                (PRIMARY) will be used to log in and for all direct communications from Gather.
                            </span>}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.secondContainer}>
                        <Grid
                            item
                            xs={12}
                            className={classes.emailPhoneContainer}
                        >
                            {funeralHome &&
                                <Typography color="primary" className={classes.containerHeader}>
                                    Which PHONE would you like to display to families
                                    on {funeralHome.funeral_home_name} ({funeralHome.funeral_home_city})?
                                </Typography>
                            }

                            <RadioGroup value={phoneRadio} onChange={this.handlePhoneRadioChange}>
                                <FormControlLabel
                                    disabled={!primaryPhone}
                                    value={UsePreference.user_primary}
                                    control={<Radio color="primary" className={classes.radioClass} />}
                                    label={<span className={classes.labelClass}>
                                        <Typography color="primary">Primary</Typography>&nbsp;
                                        <Typography color="secondary">
                                            {getFormattedPhoneNumber(primaryPhone)}
                                        </Typography>
                                    </span>}
                                    labelPlacement="end"
                                    name="primaryPhone" />
                                <FormControlLabel
                                    disabled={!fhPhone}
                                    value={UsePreference.funeral_home}
                                    control={<Radio color="primary" className={classes.radioClass} />}
                                    label={<span className={classes.labelClass}>
                                        <Typography color="primary" className={classes.whiteSpacePre}>
                                            Funeral Home
                                        </Typography>&nbsp;
                                        <Typography color="secondary">
                                            {fhPhone && getFormattedPhoneNumber(fhPhone) || '(No Phone Provided)'}
                                        </Typography>
                                    </span>}
                                    labelPlacement="end"
                                    name="fhPhone"
                                    onClick={(e) => this.handleOtherPhoneValueChange('')}
                                    classes={{ label: classes.colorPrimary }} />
                                <FormControlLabel
                                    value={UsePreference.override}
                                    control={<Radio color="primary" className={classes.radioClass} />}
                                    label={<div className={classes.otherLabel}>
                                        <span>Other:</span>
                                        <TextField
                                            placeholder="Type Phone Here"
                                            type="tel"
                                            id="phone_number"
                                            name="otherPhone"
                                            value={otherPhone}
                                            className={classes.width100}
                                            InputProps={{
                                                inputComponent: PhoneNumberMask
                                            }}
                                            onChange={(e) => this.handleOtherPhoneValueChange(e.target.value)}
                                            autoComplete="new-password"
                                            disabled={isSaving
                                                || disableLoginCredentials
                                                || phoneRadio !== UsePreference.override}
                                            error={!isPhoneValid}
                                            color="secondary" />
                                    </div>}
                                    labelPlacement="end"
                                    name="otherPhone"
                                    classes={{
                                        label: classNames(classes.colorPrimary, classes.width100),
                                        root: classes.marginRight0
                                    }} />
                            </RadioGroup>
                        </Grid>
                        <Typography className={classNames(classes.colorRed, classes.bottomText)}>
                            <span className={classes.fontWeight500}>
                                {getFormattedPhoneNumber(primaryPhone)}
                            </span>
                            {primaryPhone && <span>
                                (PRIMARY) will be used to log in and for all direct communications from Gather.
                            </span>}
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        );
    };

    render() {
        const { classes, funeralHomes, selectedFuneralHomeId, fname, lname, selectFuneralHome } = this.props;

        const thisFuneralHome = funeralHomes.find(fh => fh.funeral_home_id === selectedFuneralHomeId);

        return (
            <Grid container item xs={12} className={classes.root}>
                <FuneralHomeSelector
                    funeralHomes={funeralHomes.map(mapUserFuneralHomeUXToElement)}
                    selectedFuneralHomeIds={selectedFuneralHomeId ? [selectedFuneralHomeId] : []}
                    selectFuneralHome={selectFuneralHome}
                />

                <Grid item xs={12}>
                    <Typography
                        color="secondary"
                        align="center"
                        className={classNames(classes.fontWeight400, classes.marginTop30)}
                    >
                        The settings below apply for {fname} {lname} at&nbsp;
                        <span className={classNames(classes.colorPrimary, classes.fontWeight500)}>
                            {thisFuneralHome && thisFuneralHome.funeral_home_name}
                            &nbsp;({thisFuneralHome && thisFuneralHome.funeral_home_city})
                        </span>
                    </Typography>
                    {this.renderEmailPhoneContent()}
                </Grid>
            </Grid>
        );
    }

    handleEmailRadioChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const { onPreferenceChanges, selectedFuneralHomeId } = this.props;
        if (selectedFuneralHomeId && UsePreference[value]) {
            onPreferenceChanges('email', selectedFuneralHomeId, UsePreference[value]);
        }
    };

    handlePhoneRadioChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const { onPreferenceChanges, selectedFuneralHomeId } = this.props;
        if (selectedFuneralHomeId && UsePreference[value]) {
            onPreferenceChanges('phone', selectedFuneralHomeId, UsePreference[value]);
        }
    };

    handleOtherEmailValueChange = (otherEmail: string) => {
        this.setState({ otherEmail });
        this.debounceOtherEmailValue(otherEmail);
    };

    handleOtherPhoneValueChange = (otherPhone: string) => {
        this.setState({ otherPhone });
        this.debounceOtherPhoneValue(otherPhone);
    };
}

export default withGStyles(styles)(CommunicationTab);