import classNames from 'classnames';

import AddIcon from '@mui/icons-material/Add';

import { DefaultItemType, useAvailableItemsContext } from './AvailableDefaultItems.dialog';
import AvailableDefaultDialogNoResult from './AvailableDefaultDialogNoResult';
import makeGStyles from '../../../styles/makeGStyles';
import AvailableDefaultDialogContent from './AvailableDefaultDialogContent';
import Button from '@mui/material/Button';

const useStyles = makeGStyles(theme => ({
    addTaskButton: {
        margin: '24px 12px 6px',
        '@media (min-width: 480px)': {
            margin: '24px 10px 24px 12px',
        },
    },
    addAnotherTaskButton: {
        margin: '6px 12px 24px',
        '@media (min-width: 480px)': {
            margin: '24px 12px 24px 10px',
        },
    },
    addButton: {
        margin: '24px 10px 24px 12px'
    },
    footerList: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 80,
        borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
        marginBottom: 16,
        '@media (min-width: 480px)': {
            flexDirection: 'row'
        },
    },
    listStyle: {
        paddingRight: 0,
        paddingLeft: 0,
        minHeight: 68
    },
    opacity40: {
        opacity: 0.40
    },
    addLocationButton: {
        color: theme.palette.common.black
    },
    borderBottomNone: {
        borderBottom: 'none'
    }
}), { name: 'AvailableDefaultDialogItems' });

interface Props {
    zIndex: number;
}

const AvailableDefaultDialogItems = <T extends DefaultItemType>(props: Props) => {
    const { zIndex } = props;
    const {
        itemType,
        availableItems,
        isSortable,
        createAnotherButtonText,
        createNewButtonText,
        onCreateNewItemClick,
        onCreateAnotherButtonClick,
    } = useAvailableItemsContext<T>();

    const classes = useStyles(props);

    const renderButtons = () => (
        <div className={classNames(classes.footerList, isSortable && classes.borderBottomNone)}>
            {onCreateNewItemClick &&
                <Button
                    onClick={onCreateNewItemClick}
                    className={
                        classNames(createAnotherButtonText
                            ? classes.addTaskButton
                            : classes.addButton,
                            isSortable && classes.addLocationButton
                        )}
                >
                    <AddIcon />&nbsp;
                    <span className={classes.marginTop2}>{createNewButtonText}</span>
                </Button>
            }

            {createAnotherButtonText &&
                <Button
                    onClick={onCreateAnotherButtonClick}
                    className={classes.addAnotherTaskButton}
                >
                    <AddIcon />&nbsp;
                    <span className={classes.marginTop2}>{createAnotherButtonText}</span>
                </Button>
            }
        </div>
    );

    if (availableItems.length === 0) {
        return (
            <AvailableDefaultDialogNoResult
                itemType={itemType}
                onCreateNewItemClick={onCreateNewItemClick}
            />
        );
    }

    return (
        <>
            <AvailableDefaultDialogContent zIndex={zIndex} />

            {renderButtons()}
        </>
    );
};

export default AvailableDefaultDialogItems;
