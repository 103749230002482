import { every } from 'lodash';
import { getPhotoUrl } from '../services/photo.service';
import { CaseGiftPhotoState, CaseGiftPhotoTypeWithState, CloudinaryTransformationsType } from '../shared/types/photo';
import { GatherPhoto, PhotoStatusEnum, StoreState } from '../types';
import { isAlbumEntry } from '../shared/types';
import { AppDispatch } from '../store';

export const CLOSE_PHOTO_SWIPE = 'CLOSE_PHOTO_SWIPE';
export interface ClosePhotoSwipe {
    type: typeof CLOSE_PHOTO_SWIPE;
}

export function closePhotoSwipe(): ClosePhotoSwipe {
    return { type: CLOSE_PHOTO_SWIPE };
}

export const OPEN_PHOTO_SWIPE = 'OPEN_PHOTO_SWIPE';
export interface OpenPhotoSwipe {
    type: typeof OPEN_PHOTO_SWIPE;
    urls: Array<string>;
    options?: PhotoSwipe.Options;
}

export function openPhotoSwipe(urls: Array<string>, options?: PhotoSwipe.Options): OpenPhotoSwipe {
    return { type: OPEN_PHOTO_SWIPE, urls, options };
}

export const SET_PHOTO_SWIPE_OPTIONS = 'SET_PHOTO_SWIPE_OPTIONS';
export interface SetPhotoSwipeOptions {
    type: typeof SET_PHOTO_SWIPE_OPTIONS;
    options: PhotoSwipe.Options;
}

export function setPhotoSwipeOptions(options: PhotoSwipe.Options): SetPhotoSwipeOptions {
    return { type: SET_PHOTO_SWIPE_OPTIONS, options };
}

interface CaseGiftPhoto extends CaseGiftPhotoTypeWithState {
    url: string;
}

const isStringArray = (a: GatherPhoto[] | string[]): a is string[] =>
    every(a, (item: GatherPhoto | string): item is string => typeof item === 'string');

export type PhotoSwipeCategoryType =
    'GATHER_PHOTO'
    | 'FLOWER_AND_CARDS'
    | 'PRODUCT_OR_PACKAGE'
    | 'HANGING_PHOTOS'
    | 'CASE_BELONGINGS';
export function openPhotoSwipeDialog(
    category: PhotoSwipeCategoryType,
    activeImage: string | null,
    photos?: GatherPhoto[] | string[]
) {
    return (dispatch: AppDispatch, getState: () => StoreState): void => {
        const { casesState } = getState();

        let index: number = 0; // default
        let urls: Array<string> = [];
        const psTransformations: CloudinaryTransformationsType[] = [{
            height: window.innerHeight,
            width: window.innerWidth,
            quality: 'auto',
            fetch_format: 'auto',
            crop: 'limit'
        }];

        switch (category) {
            case 'FLOWER_AND_CARDS':
                urls = casesState.caseGiftPhotos
                    .reduce(
                        (caseGiftPhotos: Array<CaseGiftPhoto>, item: CaseGiftPhotoState, i: number) => {
                            const cardPhotoUrl = item.card_photo.public_id
                                && getPhotoUrl(item.card_photo.public_id, psTransformations)
                                || item.card_photo.dataURI
                                || undefined;

                            const giftPhotoUrl = item.gift_photo.public_id
                                && getPhotoUrl(item.gift_photo.public_id, psTransformations)
                                || item.gift_photo.dataURI
                                || undefined;

                            caseGiftPhotos = cardPhotoUrl
                                && [...caseGiftPhotos, { ...item.card_photo, url: cardPhotoUrl }]
                                || caseGiftPhotos;

                            caseGiftPhotos = giftPhotoUrl
                                && [...caseGiftPhotos, { ...item.gift_photo, url: giftPhotoUrl }]
                                || caseGiftPhotos;

                            return caseGiftPhotos;
                        },
                        []
                    )
                    .map((item, i) => {
                        if (item.public_id === activeImage || item.dataURI === activeImage) {
                            index = i;
                        }
                        return item.url;
                    });
                break;
            case 'PRODUCT_OR_PACKAGE':
                if (!photos || !isStringArray(photos)) {
                    break;
                }
                urls = photos.reduce(
                    (photoUrls: Array<string>, url: string, i: number) => {
                        const photoUrl = getPhotoUrl(url, psTransformations) || undefined;
                        if (url === activeImage) {
                            index = i;
                        }
                        return photoUrl && [...photoUrls, photoUrl] || photoUrls;
                    },
                    []
                );

                break;

            case 'CASE_BELONGINGS':
                urls = (casesState.belongings ?? []).reduce<string[]>((array, belonging) => {
                    if (belonging.photo_url) {
                        return [...array, belonging.photo_url];
                    }

                    return array;
                }, []);

                index = urls.findIndex((url) => url === activeImage);
                break;

            case 'GATHER_PHOTO':
            default:
                if (!photos || isStringArray(photos)) {
                    break;
                }

                urls = photos.reduce(
                    (photoUrls: Array<string>, item: GatherPhoto, i: number) => {
                        let photoUrl = undefined;
                        if (item.status !== PhotoStatusEnum.failed) {
                            if (isAlbumEntry(item.photo)) {
                                const trans = (item.photo.transformations && item.photo.transformations.cloudinary) ?
                                    [
                                        item.photo.transformations.cloudinary,
                                        ...psTransformations
                                    ] : psTransformations;
                                photoUrl = getPhotoUrl(item.photo.public_id, trans);
                            } else {
                                photoUrl = item.photo &&
                                    getPhotoUrl(item.photo.public_id, psTransformations) || undefined;
                            }
                        }

                        if (item.photo && item.photo.public_id === activeImage) {
                            index = i;
                        }
                        return photoUrl && [...photoUrls, photoUrl] || photoUrls;
                    },
                    []
                );

                break;
        }

        if (urls.length === 0) {
            return;
        }

        dispatch(openPhotoSwipe(urls, { index }));
    };
}

export type PhotoSwipeAction = ClosePhotoSwipe
    | OpenPhotoSwipe
    | SetPhotoSwipeOptions;