import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { InputBaseComponentProps } from '@mui/material';
import { forwardRef, Ref } from 'react';

const PostalCodeMask = forwardRef(
    function PostalCodeMask(props: InputBaseComponentProps, forwardedRef: Ref<HTMLElement>) {
        const { inputRef, ...other } = props;
        const maskedOtherProps = other as MaskedInputProps;

        return (
            <MaskedInput
                {...maskedOtherProps}
                ref={ref => ref?.inputElement && typeof forwardedRef === 'function' && forwardedRef(ref.inputElement)}
                mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                placeholderChar={'\u2000'}
                showMask
                guide={false}
            />
        );
    }
);

export default PostalCodeMask;
