import { NULL_NO_ANSWER } from "../../../../../constants";
import {
    emptyStringParser,
    getAddressFromLongAddress,
    formatDateString,
    DateTimeFormat
} from "../../../../../services";
import { ConfigurableRestingPlaceFieldKey } from "../../../../../shared/death_certificate/validators/config";
import { YesNoEnum, RestingPlaceOptionsEnum } from "../../../../../shared/types";
import { OverviewSectionCommonProps } from "./OverviewForm";
import OverviewLabel from "./OverviewLabel";
import { OverviewDivider, SectionTypography } from "./components";

interface Props extends OverviewSectionCommonProps {
}
const RestingPlaceOverview = (props: Props) => {
    const { deathCertificate, divider, printMode, showHeading, alwaysVisible } = props;

    let disposition: string | null = null;
    if (deathCertificate.restingPlace.options) {
        disposition = deathCertificate.restingPlace.options === 'Other'
            ? `Other: ${emptyStringParser(deathCertificate.restingPlace.specifyOther) ?? NULL_NO_ANSWER}`
            : deathCertificate.restingPlace.options;
    }

    const location: string | null = getAddressFromLongAddress(deathCertificate.restingPlace.dispositionPlace);
    const dod = formatDateString(deathCertificate.restingPlace.dispositionDate, DateTimeFormat.LongDate);
    const ownsCemeteryProperty: YesNoEnum | null = deathCertificate.restingPlace.ownsCemeteryProperty || null;
    const cemeteryPropertyDetails: string | null = deathCertificate.restingPlace.ownsCemeteryProperty ?
        deathCertificate.restingPlace.cemeteryPropertyDetails : null;

    const isCrematoryDisposition = disposition === RestingPlaceOptionsEnum.Cremation
        || disposition === RestingPlaceOptionsEnum['Cremation with Burial']
        || disposition === RestingPlaceOptionsEnum['Cremation with Entombment'];
    const crematoryName = emptyStringParser(deathCertificate.restingPlace.crematoryName) || null;
    const secondDispositionPlace = getAddressFromLongAddress(
        deathCertificate.restingPlace.secondDispositionPlace || null
    );

    return (
        <div>
            {showHeading &&
                <SectionTypography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 700,
                        fontSize: printMode ? 13 : 16,
                        textTransform: 'uppercase',
                        marginBottom: printMode ? 0 : 10,
                        lineHeight: printMode ? '1em' : undefined,
                        marginTop: printMode ? 14 : 16
                    }}
                >
                    RESTING PLACE
                </SectionTypography>
            }

            <SectionTypography
                variant="body1"
                style={{
                    fontWeight: 400,
                    fontSize: printMode ? 13 : 16,
                    textTransform: 'capitalize',
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <OverviewLabel
                    label="Disposition Option"
                    html={disposition}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRestingPlaceFieldKey.dispositionPlace}
                />

                <OverviewLabel
                    label="Location"
                    html={location}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRestingPlaceFieldKey.dispositionPlace}
                />

                <OverviewLabel
                    label="Date of disposition"
                    html={dod}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRestingPlaceFieldKey.dispositionDate}
                />

                <OverviewLabel
                    label="Owns Cemetery Property"
                    html={ownsCemeteryProperty}
                    alwaysVisible={alwaysVisible}
                    fieldKey={ConfigurableRestingPlaceFieldKey.ownCemeteryPlot}
                />

                {ownsCemeteryProperty === YesNoEnum.Yes &&
                    <OverviewLabel
                        label="Cemetery Property Details"
                        html={cemeteryPropertyDetails}
                        alwaysVisible={alwaysVisible}
                        fieldKey={ConfigurableRestingPlaceFieldKey.cemeteryPropertyDetails}
                    />
                }

                {isCrematoryDisposition &&
                    <>
                        <OverviewLabel
                            label="Crematory Name"
                            html={crematoryName}
                            alwaysVisible={alwaysVisible}
                            fieldKey={ConfigurableRestingPlaceFieldKey.crematoryName}
                        />

                        <OverviewLabel
                            label="Second Disposition Place"
                            html={secondDispositionPlace}
                            alwaysVisible={alwaysVisible}
                            fieldKey={ConfigurableRestingPlaceFieldKey.secondDispositionPlace}
                        />
                    </>
                }
            </SectionTypography>

            {divider && <OverviewDivider />}
        </div>
    );
};

export default RestingPlaceOverview;