import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import UserAvatar from '../../../common/UserAvatar';
import { StyledProps } from '../ModerationContent.styles';
import { ModerationVisitorUX, ModerationCategory, VisitorForModeration } from '../../../../shared/types';
import { getModerationVars } from '../utils';
import { joinNameParts } from '../../../../shared/utils';
import ModerateButtons from '../ModerateButtons';
import { GStyles } from '../../../../styles/GStyles';
import ModerationCaseCard from '../../dashboard/ModerationCaseCard';

interface Props {
    moderationVisitor: ModerationVisitorUX;
    isLastMemory: boolean;
    isRememberPage: boolean;
    showHeader?: boolean;
    openMenu: (event: React.MouseEvent<HTMLElement>, memory: ModerationVisitorUX) => void;
    onApprove: () => void;
    onBlock: () => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, visitor: VisitorForModeration) => void;
}

const ModerationVisitor: React.StatelessComponent<StyledProps & Props> = (props) => {
    const {
        classes,
        moderationVisitor,
        isLastMemory,
        isRememberPage,
        showHeader,
        openMenu,
        onApprove,
        onBlock,
        openGuestPopper
    } = props;
    const {
        visitor,
        case_fname,
        case_name,
        case_lname,
        fh_key,
        dod_start_date,
        dod_start_time,
        dob_date,
        case_photo,
        case_photo_transformations,
        funeral_home_name,
    } = moderationVisitor;

    const {
        isApproved,
        isBlocked,
        isPending,
        moderationText,
    } = getModerationVars(moderationVisitor, ModerationCategory.visitors);
    const sharedTime = moment(visitor.created_time).fromNow();

    return (
        <>
            {showHeader &&
                <ModerationCaseCard
                    gatherCase={{
                        name: case_name,
                        fname: case_fname,
                        lname: case_lname,
                        dod_start_date: dod_start_date,
                        dob_date: dob_date,
                        dod_start_time: dod_start_time,
                        photo: case_photo,
                        photo_transformations: case_photo_transformations,
                        funeral_home_name: funeral_home_name,
                        funeral_home_key: fh_key,
                    }}
                />
            }
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.memory,
                    classes.visitor,
                    isBlocked && classes.blocked,
                    isApproved && classes.approved,
                    isLastMemory && classes.lastMemory
                )}
            >
                <Grid
                    item
                    xs={12}
                    className={classNames(classes.visitorDetails)}
                >
                    <UserAvatar
                        user={visitor}
                        className={classNames(classes.visiterAvatar, isRememberPage && GStyles.cursorPointer)}
                        onClick={e => isRememberPage && openGuestPopper(e, visitor)}
                        size={60}
                    />
                    <Grid item className={classNames(classes.sharedByContainer, classes.marginLeft8)}>
                        <Typography color="secondary" fontWeight={500}>
                            {joinNameParts(visitor)}
                        </Typography>
                        <Typography color="secondary">
                            {visitor.relationship}
                        </Typography>
                        <Typography color="secondary">
                            <span className={classes.accountCreated}>Account Created </span>{sharedTime}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.visitorOptions}>
                    <Typography
                        color="secondary"
                        className={classNames(
                            GStyles.fontSize12,
                            GStyles.fontStyleItalic,
                            isBlocked && classes.blockColor,
                            isApproved && classes.approveColor
                        )}
                    >
                        {moderationText}
                        <IconButton
                            className={classes.menuIconButton}
                            onClick={e => openMenu(e, moderationVisitor)}
                            size="large">
                            <MoreVertIcon />
                        </IconButton>
                    </Typography>
                </Grid>

                {isPending &&
                    <ModerateButtons
                        onApprove={onApprove}
                        onBlock={onBlock}
                    />
                }
            </Grid>
        </>
    );
};

export default ModerationVisitor;