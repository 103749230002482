import * as React from 'react';
import { isEqual } from 'lodash';

import Grid from '@mui/material/Grid';

import {
    EntityCaseRole,
    GatherCasePublic,
    GuestListUser,
    isAlbumEntry,
    ModerationCategory,
    UserRoles
} from '../../../../shared/types';

import { GatherPhoto, StoreState } from '../../../../types';
import { closeGuestPopper, openGuestPopper, openHelperInvitationDialog } from '../../../../actions/Dialog.action';
import { MAX_HEIGHT } from './styles';
import { loadCasePhotos } from '../../../../actions/Photo.action';
import { performCasePhotoUpload, createUploadFromDispatch } from '../../../profileImage/utility';
import VirtualizedHangingPhotos from './VirtualHangingPhotos';
import { Collection } from 'react-virtualized/dist/commonjs/Collection';
import PhotoPopper from '../../photoSlideshow/PhotoPopper';
import { SelfLoginSrcAction } from '../selfLogin/SelfLoginDialog';
import { loginAndSaveMemory } from '../../../../actions/GatherCase.action';
import ModerationDialog from '../../../moderation/ModerationDialog';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import MessageAndControls from './widgets/MessageAndControls';
import MiddleSection from './widgets/MiddleSection';
import LeftSection from './widgets/LeftSection';
import RightSection from './widgets/RightSection';
import { alpha, styled } from '@mui/material';
import { getIntercomTargetProp } from '../../../../services';

const ParentRoot = styled(Grid)(({ theme }) => ({
    padding: '40px 0 12px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 40%,` +
        `${alpha(theme.palette.primary.main, 0.22)} 100%)`,
}));

interface RootProps {
    noPhotos: boolean;
    onePhoto: boolean;
    twoPhotos: boolean;
}
const Root = styled('div')<RootProps>(({ noPhotos, onePhoto, twoPhotos }) => ({
    display: 'flex',
    transition: 'all 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    width: '100%',
    height: noPhotos || onePhoto ? 480 : twoPhotos ? 500 : MAX_HEIGHT,
    '& .ReactVirtualized__Collection__innerScrollContainer': {
        margin: '0 auto',
        position: 'relative',
        overflow: 'visible !important',
        height: 500,
    },
    '& .ReactVirtualized__Collection': {
        outline: 'none',
        overflow: 'auto hidden !important',
        '&::-webkit-scrollbar': { // for chrome
            display: 'none'
        },
        msOverflowStyle: 'none',  // for IE
        scrollbarWidth: 'none'    // for firefox
    }
}));

const VirtualPhotosContainer = styled(Grid)({
    display: 'flex',
    minHeight: 410,
    '@media (min-width: 960px)': {
        minHeight: 430,
    },
});

const mapStateToProps = (
    {
        casesState,
        userSession,
        moderationState
    }: StoreState,
    ownProps: OwnProps
) => {
    const { activeCase } = ownProps;
    const { userData } = userSession;

    const caseCounts = moderationState.caseCounts.find((c) => c.case_id === activeCase.id);
    const pendingCounts = caseCounts && caseCounts.pending;

    return {
        isFHorGOMUser: UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home.id),
        pendingCounts,
        userData,
        guestListMap: casesState.guestListMap,
    };
};

interface OwnProps {
    casePhotos: GatherPhoto[];
    activeCase: GatherCasePublic;
    id?: string;
    rootClass?: string;
    disabledFbButton?: boolean;
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    zIndex: number;
}

interface State {
    rootRef: HTMLDivElement | null;
    activePhoto: GatherPhoto | null;
    photoPopperEl: HTMLElement | null;
    scrollRef: HTMLElement | null;
    isModerationDialogOpen: boolean;
    windowWidth: number;
}

class HangingPhotos extends React.Component<Props, State> {
    state: State = {
        rootRef: null,
        activePhoto: null,
        photoPopperEl: null,
        isModerationDialogOpen: false,
        scrollRef: null,
        windowWidth: window.innerWidth
    };
    protected collectionRef: Collection | null;
    protected fileUploadInput: HTMLInputElement | undefined;

    componentDidMount() {
        const { casePhotos, dispatch, activeCase } = this.props;

        window.addEventListener('resize', this.handleResize, { passive: true });

        // if photos haven't loaded load them in
        if (!casePhotos) {
            dispatch(loadCasePhotos(activeCase.uuid));
        }

        // fixes an issue with the initial ref sizing
        this.forceUpdate();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const {
            activeCase: prevCase,
            casePhotos,
            pendingCounts: prevPendingCounts
        } = prevProps;
        const { activeCase, dispatch, pendingCounts } = this.props;

        // if we have no case photos or case changed reload photos
        if (activeCase.uuid !== prevCase.uuid || !casePhotos) {
            dispatch(loadCasePhotos(activeCase.uuid));
        }

        if (!isEqual(prevPendingCounts, pendingCounts) && this.collectionRef) {
            this.collectionRef.forceUpdate();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    setCollectionRef = (ref: Collection) => {
        this.collectionRef = ref;
        const elementSelector = this.props.id
            ? `#${this.props.id} .ReactVirtualized__Collection`
            : '.ReactVirtualized__Collection';
        const scrollRef = document.querySelector<HTMLElement>(elementSelector);
        this.setState({ scrollRef });
    };

    onPhotoClick = (target: HTMLElement, photo: GatherPhoto) => {
        const { dispatch } = this.props;
        this.setState({
            activePhoto: photo || null,
            photoPopperEl: target,
        });
        dispatch(closeGuestPopper());
    };

    scrollToLeft = () => {
        this.state.scrollRef?.scrollTo({ behavior: 'smooth', left: 0 });
    };

    registerFileUpload = (fileUploadInput: HTMLInputElement) => {
        this.fileUploadInput = fileUploadInput;
    };

    handleFileUploadEvent = async (files: FileList | null, setLoading: (loading: boolean) => void) => {
        const { dispatch, zIndex, activeCase, userData } = this.props;
        this.scrollToLeft();

        const photosToUploadCount = files && files.length || 0;
        dispatch(loginAndSaveMemory({
            user: userData,
            publicCase: activeCase,
            zIndex: zIndex + 1,
            srcAction: SelfLoginSrcAction.sharePhoto,
            onLoginSuccess: () => this.processFileUpload(files, setLoading),
            onAbort: () => setLoading(false),
            onClose: null,
            photosToUploadCount
        }));
    };

    processFileUpload = async (files: FileList | null, setLoading: (loading: boolean) => void) => {
        const { activeCase, dispatch } = this.props;

        setLoading(true);
        this.collectionRef?.forceUpdate();

        await performCasePhotoUpload(files, activeCase, createUploadFromDispatch(dispatch));

        setLoading(false);
        this.collectionRef?.forceUpdate();
    };

    openInviteDialog = () => {
        const { dispatch, zIndex } = this.props;

        dispatch(openHelperInvitationDialog({
            zIndex: zIndex + 1,
            defaultTab: EntityCaseRole.guest,
        }));
    };

    closePopper = () => {
        this.setState({ photoPopperEl: null });
    };

    openGuestPopper = (
        event: React.MouseEvent<HTMLElement>,
        user: GuestListUser
    ) => {
        event.persist();
        const target = event.currentTarget;

        const { dispatch, zIndex } = this.props;

        dispatch(openGuestPopper({
            zIndex: zIndex + 1,
            getTarget: () => target,
            activeGuest: user
        }));
    };

    openModerationDialog = () => {
        this.setState({ isModerationDialogOpen: true });
    };
    closeModerationDialog = () => {
        this.setState({ isModerationDialogOpen: false });
    };

    shouldShowReviewPhotos = () => {
        const { pendingCounts, isFHorGOMUser } = this.props;

        const visitors = pendingCounts && pendingCounts.visitors;
        const photos = pendingCounts && pendingCounts.photos;

        return isFHorGOMUser && Boolean(visitors || photos);
    };

    renderMiddleSection = () => {
        const { activeCase, disabledFbButton } = this.props;

        return (
            <MiddleSection
                fhWebsiteUrl={activeCase.funeral_home.website_url}
                caseFname={activeCase.display_fname}
                fhName={activeCase.funeral_home.name}
                disabledFbButton={!!disabledFbButton}
            />
        );
    };

    renderLeftSide = () => {
        const { casePhotos, pendingCounts, activeCase } = this.props;

        const pendingVisitors = pendingCounts && pendingCounts.visitors || 0;
        const pendingPhotos = pendingCounts && pendingCounts.photos || 0;

        return (
            <LeftSection
                casePhotosCount={casePhotos.length}
                pendingVisitorsCount={pendingVisitors}
                pendingPhotosCount={pendingPhotos}
                caseFName={activeCase.display_fname}
                showReviewButton={this.shouldShowReviewPhotos()}
                onReview={this.openModerationDialog}
                onPhotosUpload={this.handleFileUploadEvent}
            />
        );
    };

    renderRightSide = () => {
        const { activeCase, casePhotos, disabledFbButton } = this.props;

        return (
            <RightSection
                caseFName={activeCase.display_fname}
                caseName={activeCase.name}
                casePhotosCount={casePhotos.length}
                disabledFbButton={!!disabledFbButton}
                onPhotosUpload={this.handleFileUploadEvent}
            />
        );
    };

    renderPhotoPopper = () => {
        const { casePhotos, activeCase, zIndex } = this.props;
        const { activePhoto, photoPopperEl } = this.state;

        const timeSinceUploaded = activePhoto && isAlbumEntry(activePhoto.photo) &&
            activePhoto.photo.timeSinceUploaded || undefined;

        return (
            <PhotoPopper
                key={activePhoto?.photo?.id}
                activeCase={activeCase}
                activePhoto={activePhoto}
                photoList={casePhotos}
                zIndex={zIndex + 1}
                timeSinceUploaded={timeSinceUploaded}
                isOpenPhotoPopper={Boolean(photoPopperEl)}
                popperEl={photoPopperEl}
                closePopper={this.closePopper}
                hideRemoveAlbum
            />
        );
    };

    render() {
        const {
            casePhotos,
            activeCase,
            zIndex,
            isFHorGOMUser,
            guestListMap,
            rootClass,
            id,
            disabledFbButton
        } = this.props;
        const { isModerationDialogOpen } = this.state;

        const casePhotosLength = casePhotos.length;

        return (
            <ParentRoot id={id} container item xs={12} className={rootClass}>
                <Root
                    noPhotos={casePhotosLength === 0}
                    onePhoto={casePhotosLength === 1}
                    twoPhotos={casePhotosLength === 2}
                    ref={ref => {
                        if (!this.state.rootRef) {
                            this.setState({ rootRef: ref });
                        }
                    }}
                    {...getIntercomTargetProp(`RememberPage-HangingPhotos-HangingPhotosContainer`)}
                >
                    {casePhotosLength > 1 &&
                        <MessageAndControls
                            fhWebsiteUrl={activeCase.funeral_home.website_url}
                            fhName={activeCase.funeral_home.name}
                            disabledFbButton={!!disabledFbButton}
                            scrollRef={this.state.scrollRef}
                        />
                    }
                    <VirtualPhotosContainer item>
                        {this.state.rootRef &&
                            <VirtualizedHangingPhotos
                                activeCase={activeCase}
                                casePhotos={casePhotos}
                                casePhotosLength={casePhotosLength}
                                fileUploadRef={this.fileUploadInput}
                                width={this.state.rootRef.offsetWidth}
                                height={this.state.rootRef.offsetHeight}
                                guestListMap={guestListMap}
                                onPhotoClick={this.onPhotoClick}
                                renderLeftSide={this.renderLeftSide}
                                renderMiddleSection={casePhotosLength < 2 ? this.renderMiddleSection : undefined}
                                renderRightSide={this.renderRightSide}
                                setCollectionRef={this.setCollectionRef}
                                showReviewPhotosButton={this.shouldShowReviewPhotos()}
                                openGuestPopper={this.openGuestPopper}
                            />
                        }
                    </VirtualPhotosContainer>
                </Root>

                {this.renderPhotoPopper()}
                {
                    isFHorGOMUser &&
                    <ModerationDialog
                        zIndex={zIndex + 1}
                        isOpen={isModerationDialogOpen}
                        handleClose={this.closeModerationDialog}
                        initialCategory={ModerationCategory.photos}
                    />
                }
            </ParentRoot >
        );
    }
}

export default withState(mapStateToProps)(HangingPhotos);
