import { useMemo } from 'react';
import classNames from 'classnames';
import makeGStyles from '../../../../styles/makeGStyles';
import Grid from '@mui/material/Grid';
import {
    TaskComponentType,
    TaskComponentUX,
    isChecklistQuestionsConfig,
    isTextQuestionsConfig,
    TextQuestionsQuestion,
    isMultipleChoiceConfig
} from '../../../../shared/types';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
const useStyles = makeGStyles(theme => ({
    answerContainer: {
        flexDirection: 'column',
    },
    answerText: {
        color: `${theme.palette.secondary.main} !important`,
        fontStyle: 'italic',
        fontSize: '0.875rem',
        paddingRight: 8,
        lineHeight: '20px',
    },
    centerIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    marginRight3: {
        marginRight: 3,
    },
}), { name: 'ComponentAnswers' });

interface Props {
    componentType: TaskComponentType;
    questions: TaskComponentUX[];
}

const ComponentAnswers = (props: Props) => {
    const { componentType, questions } = props;
    const classes = useStyles();

    const configurationAnswers = useMemo(() => {
        const configAnswers: string[] = [];

        if (!questions) {
            return;
        }
        switch (componentType) {
            case TaskComponentType.checklist_questions:
                for (const configItem of questions) {
                    if (isChecklistQuestionsConfig(configItem.configuration)
                        && configItem.type === TaskComponentType.checklist_questions) {
                        const checklistAnswerQuestions = configItem.configuration.questions;
                        const checklistAnswerAnswers = checklistAnswerQuestions.filter((answer) =>
                            answer.answer === 'true');
                        const checklistAnswerTexts = checklistAnswerAnswers.map((answer) => answer.question_text);
                        configAnswers.push(...checklistAnswerTexts);
                    }
                };
                break;
            case TaskComponentType.text_questions:
                for (const configItem of questions) {
                    if (isTextQuestionsConfig(configItem.configuration)
                        && configItem.type === TaskComponentType.text_questions) {
                        const textAnswerQuestions: TextQuestionsQuestion[] = configItem.configuration.questions;
                        for (const question of textAnswerQuestions) {
                            if (question.answer) {
                                configAnswers.push(`${question.question_text}: ${question.answer}`);
                            }
                        }
                    };
                };
                break;
            case TaskComponentType.multiple_choice_question:
                for (const configItem of questions) {
                    if (isMultipleChoiceConfig(configItem.configuration)
                        && configItem.type === TaskComponentType.multiple_choice_question) {
                        const multipleChoiceAnswer = configItem.configuration.answer;
                        const selectedOption = configItem.configuration.options.find((option) =>
                            option.uuid === multipleChoiceAnswer);
                        if (selectedOption && selectedOption.question_text) {
                            configAnswers.push(
                                `${configItem.configuration.question_text} ${selectedOption.question_text}`);
                        }
                    }
                };
                break;
            default:
                break;
        }
        return configAnswers;
    }, [componentType, questions]);

    return (
        <Grid>
            {configurationAnswers &&
                <Grid container className={classes.answerContainer}>
                    {componentType === TaskComponentType.checklist_questions ?
                        configurationAnswers.map((question, index) => (
                            <Grid item key={`question_${index}`}>
                                <div className={classNames(classes.answerText, classes.centerIcon)}>
                                    <ToggleOnIcon fontSize="small" className={classes.marginRight3} />
                                    {question}
                                </div>
                            </Grid>
                        ))
                        : null}
                    {componentType === TaskComponentType.text_questions ?
                        configurationAnswers.map((question, index) => (
                            <Grid item key={`question_${index}`}>
                                <div className={classNames(classes.answerText, classes.centerIcon)}>
                                    {question}
                                </div>
                            </Grid>
                        ))
                        : null
                    }
                    {componentType === TaskComponentType.multiple_choice_question ?
                        configurationAnswers.map((question, index) => (
                            <Grid item key={`question_${index}`}>
                                <div className={classNames(classes.answerText, classes.centerIcon)}>
                                    <RadioButtonCheckedIcon fontSize="small" className={classes.marginRight3} />
                                    {question}
                                </div>
                            </Grid>
                        ))
                        : null
                    }
                </Grid>}
        </Grid>
    );
};

export default ComponentAnswers;