export function generateS3Thumbnail(blob: Blob, width: number, height: number): Promise<Blob> {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            const scaleRatio = Math.min(width / img.width, height / img.height);
            const w = img.width * scaleRatio;
            const h = img.height * scaleRatio;
            canvas.width = w;
            canvas.height = h;
            if (!ctx) {
                return reject("could not get canvas");
            }
            ctx.drawImage(img, 0, 0, w, h);
            canvas.toBlob((newBlob) => {
                if (newBlob) {
                    resolve(newBlob);
                } else {
                    reject("could not extract blob");
                }
            });
        };
        img.src = URL.createObjectURL(blob);
    });
}

// this function will crop the image to the smallest of the given dimensions to retain a square
// aspect ratio
export function generateCenteredS3Crop(blob: Blob, thumbnailSize: number): Promise<Blob> {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            const minDimension = Math.min(img.width, img.height); // crop to smallest square
            canvas.width = thumbnailSize;
            canvas.height = thumbnailSize;
            // center the crop rectangle
            const sx = (img.width - minDimension) / 2;
            const sy = (img.height - minDimension) / 2;
            if (!ctx) {
                return reject("could not get canvas");
            }
            ctx.drawImage(img, sx, sy, minDimension, minDimension, 0, 0, thumbnailSize, thumbnailSize);
            canvas.toBlob((newBlob) => {
                if (newBlob) {
                    resolve(newBlob);
                } else {
                    reject("could not extract blob");
                }
            });
        };
        img.src = URL.createObjectURL(blob);
    });
}

