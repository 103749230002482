import * as ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';

import GatherRouter from './components/GatherRouter';
import { setupSentry, isSentryEnabled } from './sentry';
import { setupGoogleTagManager } from './services';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { StoreState, useGSelector } from './types';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'intersection-observer'; // polyfill for IntersectionObserver
import 'autotrack/lib/plugins/url-change-tracker';

import { LicenseInfo } from '@mui/x-data-grid-premium';
const { REACT_APP_MUI_LICENSE_KEY } = process.env;
if (REACT_APP_MUI_LICENSE_KEY) {
    LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY);
}

const smoothscroll = require('smoothscroll-polyfill'); // polyfill for scrollTo/scrollBy

smoothscroll.polyfill();

if (isSentryEnabled()) {
    setupSentry();
}

setupGoogleTagManager();

const RootComponent = () => {
    const theme = useGSelector(({ whiteLabel }: StoreState) => whiteLabel.muiTheme);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <GatherRouter />
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

ReactDOM.render(
    <StoreProvider store={store}>
        <RootComponent />
    </StoreProvider>,
    document.getElementById('root') as HTMLElement,
);
