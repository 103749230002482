import {
    GatherEvent,
    GoogleEvent,
    LoadEventsResponse,
    EventUpdateRequest,
    EventCreateRequest,
    GatherEventRecord,
    GatherCaseUX,
} from '../shared/types';
import { postToAPI, getFromAPI, deleteFromAPI, patchAPI } from '.';
import { registerAppError } from './errors';
import { AppDispatch } from '../store';
import { log } from '../logger';

export const SET_EVENT_SAVING = 'SET_EVENT_SAVING';
export type SET_EVENT_SAVING = typeof SET_EVENT_SAVING;

interface SetEventSaving {
    type: SET_EVENT_SAVING;
    isSaving: boolean;
}

function setEventSaving(isSaving: boolean): SetEventSaving {
    return {
        type: SET_EVENT_SAVING,
        isSaving,
    };
}

export const LOADED_EVENTS = 'LOADED_EVENTS';

interface LoadedEvents {
    type: typeof LOADED_EVENTS;
    caseEvents: GatherEvent[];
    googleEvents: GoogleEvent[];
}

function eventsLoaded(events: LoadEventsResponse): LoadedEvents {
    return {
        type: LOADED_EVENTS,
        caseEvents: events.gatherEvents,
        googleEvents: events.googleEvents,
    };
}

export const LOADED_CASE_EVENTS = 'LOADED_CASE_EVENTS';

interface LoadedCaseEvents {
    type: typeof LOADED_CASE_EVENTS;
    caseEvents: GatherEvent[];
}

function caseEventsLoaded(caseEvents: GatherEvent[]): LoadedCaseEvents {
    return {
        type: LOADED_CASE_EVENTS,
        caseEvents,
    };
}

export const ADD_EVENT = 'ADD_EVENT';
export type ADD_EVENT = typeof ADD_EVENT;

interface AddEvent {
    type: ADD_EVENT;
    event: GatherEvent;
}

function addEventInStore(event: GatherEvent): AddEvent {
    return {
        type: ADD_EVENT,
        event,
    };
}

export function addEvent(event: EventCreateRequest, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<GatherEvent | null> => {
        // Validate the object before sending to the server
        try {
            EventCreateRequest.fromRequest(event);
        } catch (ex) {
            console.warn('Failed to validate EventCreateRequest:', event, ex);
            return null;
        }

        dispatch(setEventSaving(true));
        const route = `api/case/${caseUuid}/event`;
        const savedEvent = await postToAPI<GatherEvent>(route, { event }, dispatch);
        dispatch(setEventSaving(false));
        if (!savedEvent) {
            dispatch(registerAppError('Unable to add event.'));
            return null;
        } else {
            dispatch(addEventInStore(savedEvent));
            return savedEvent;
        }
    };
}

export const UPDATE_EVENT = 'UPDATE_EVENT';

export interface UpdateEvent {
    type: typeof UPDATE_EVENT;
    eventId: number;
    event: EventUpdateRequest;
}

function updateEventInStore(eventId: number, event: EventUpdateRequest): UpdateEvent {
    return {
        type: UPDATE_EVENT,
        eventId,
        event,
    };
}

export function updateEvent(eventId: number, event: EventUpdateRequest, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<GatherEvent | null> => {
        try {
            EventUpdateRequest.fromRequest(event);
        } catch (ex) {
            log.warn('Failed to validate EventUpdateRequest', { event, ex });
            return null;
        }
        // make sure dialogs get closed
        dispatch(updateEventInStore(eventId, event));
        dispatch(setEventSaving(true));
        const route = `api/case/${caseUuid}/event/${eventId}`;
        const updatedEvent = await patchAPI<GatherEvent>(route, { event }, dispatch);
        dispatch(setEventSaving(false));
        if (!updatedEvent) {
            dispatch(registerAppError('Unable to update event.'));
            return null;
        } else {
            return updatedEvent;
        }
    };
}

export const DELETE_EVENT = 'DELETE_EVENT';

interface DeleteEvent {
    type: typeof DELETE_EVENT;
    event: GatherEvent;
}
function deleteEventInStore(event: GatherEvent): DeleteEvent {
    return {
        type: DELETE_EVENT,
        event
    };
}

export function deleteEvent(event: GatherEvent, gatherCase: Pick<GatherCaseUX, 'uuid'>) {
    return async (dispatch: AppDispatch): Promise<GatherEventRecord | null> => {

        // make sure dialogs get closed
        dispatch(deleteEventInStore(event));
        dispatch(setEventSaving(true));
        const route = `api/case/${gatherCase.uuid}/event/${event.id}`;
        const deletedEvent = await deleteFromAPI<GatherEventRecord>(route, dispatch);
        dispatch(setEventSaving(false));
        if (!deletedEvent) {
            dispatch(registerAppError('Unable to delete event.'));
            return null;
        } else {
            return deletedEvent;
        }
    };
}

export const LOADING_EVENTS = 'LOADING_EVENTS';

interface LoadingEvents {
    type: typeof LOADING_EVENTS;
}

function loadingEvents(): LoadingEvents {
    return {
        type: LOADING_EVENTS,
    };
}

export const LOADING_EVENTS_FAILED = 'LOADING_EVENTS_FAILED';

interface LoadingEventsFailed {
    type: typeof LOADING_EVENTS_FAILED;
}

function loadingEventsFailed(): LoadingEventsFailed {
    return {
        type: LOADING_EVENTS_FAILED,
    };
}

export function loadFuneralHomeEvents(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<LoadEventsResponse | null> => {
        dispatch(loadingEvents());
        const resource: string = `funeralhome/${funeralHomeId}/event/`;
        // optionally include daysMin (number) and daysMax (number) query parameters to
        // dictate the time range of events to fetch.
        // API default is ?daysMin=-7&daysMax=30
        const loadedEvents = await getFromAPI<LoadEventsResponse>(resource, dispatch);
        if (!loadedEvents) {
            dispatch(loadingEventsFailed());
            dispatch(registerAppError('Unable to load events.'));
            return null;
        } else {
            dispatch(eventsLoaded(loadedEvents));
            return loadedEvents;
        }
    };
}

export function loadCaseEvents(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<GatherEvent[] | null> => {
        dispatch(loadingEvents());
        const resource = `api/case/${caseUuid}/event/`;
        const loadedEvents = await getFromAPI<GatherEvent[]>(resource, dispatch);
        if (!loadedEvents) {
            dispatch(loadingEventsFailed());
            dispatch(registerAppError('Unable to load events.'));
            return null;
        } else {
            dispatch(caseEventsLoaded(loadedEvents));
            return loadedEvents;
        }
    };
}

export const SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT';
export type SET_ACTIVE_EVENT = typeof SET_ACTIVE_EVENT;

interface SetActiveEvent {
    type: SET_ACTIVE_EVENT;
    eventId: number | null;
}

export function setActiveEvent(eventId: number | null): SetActiveEvent {
    return {
        type: SET_ACTIVE_EVENT,
        eventId,
    };
}

export type GatherEventAction = SetEventSaving
    | LoadedEvents
    | LoadedCaseEvents
    | AddEvent
    | UpdateEvent
    | DeleteEvent
    | SetActiveEvent
    | LoadingEvents
    | LoadingEventsFailed
    | SetActiveEvent
;
