import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { GatherCasePublic } from '../../../../shared/types';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import UserAvatar from '../../../common/UserAvatar';
import Divider from '@mui/material/Divider';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    dialogPaper: {
        borderRadius: '14px 14px 8px 8px',
        width: '100%',
        maxWidth: 360,
        maxHeight: '100%',
        overflowY: 'visible',
        margin: 0
    },
    dialogTitle: {
        borderRadius: '12px 12px 0 0',
        padding: '0 12px 20px',
        background: theme.palette.primary.main,
        '& p': {
            color: theme.palette.common.white,
            textAlign: 'center'
        }
    },
    avatarButton: {
        zIndex: 1,
        margin: 0,
        background: theme.palette.common.white,
        padding: 0,
        transition: 'inherit',
        cursor: 'initial',
        width: 80,
        height: 80,
        '&:hover': {
            backgroundColor: theme.palette.common.white
        },
        '@media (min-width: 500px)': {
            width: 120,
            height: 120,
        }
    },
    avatar: {
        margin: 'auto',
        fontWeight: 200,
        fontSize: 52,
        textTransform: 'uppercase',
        width: 76,
        height: 76,
        '@media (min-width: 500px)': {
            width: 112,
            height: 112,
        }
    },
    caseAvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        transform: 'translateY(-45%)',
        marginBottom: '-10%',
        '@media (min-width: 500px)': {
            marginBottom: '-14%',
        }
    },
    divider: {
        background: theme.palette.common.white,
        opacity: 0.5,
        margin: '2px 0 4px'
    },
    continuingText: {
        fontWeight: 500,
        margin: '12px 0 20px !important'
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            textAlign: 'center'
        },
        '& $benefitText': {
            textAlign: 'center',
            marginBottom: 6
        }
    },
    continueButton: {
        width: '85%',
        marginTop: 20
    },
    privacyText: {
        margin: '12px 0',
        fontSize: 12,
        '@media (min-width: 340px)': {
            fontSize: 13
        }
    },
    maybeLaterButton: {
        textTransform: 'none'
    },
    headerHelperText: {
        lineHeight: 1.25,
        fontSize: 15
    },
    headerText: {
        fontSize: 24,
        '@media (min-width: 500px)': {
            fontSize: 26
        }
    },
    padding_0_8: {
        padding: '0 8px'
    },
    benefitText: {}
});

type StyledProps = WithStyles<'root' | 'avatarButton' | 'avatar' | 'dialogTitle'
    | 'caseAvatarContainer' | 'dialogPaper' | 'divider' | 'continuingText' | 'dialogContent' | 'benefitText'
    | 'continueButton' | 'privacyText' | 'maybeLaterButton' | 'headerHelperText' | 'headerText' | 'padding_0_8'>;

interface Props {
    isOpen: boolean;
    closeDialog: () => void;
    zIndex: number;
    gatherCase: GatherCasePublic;
    closeParentDialog: () => void;
    isUserLoggedIn: boolean;
}

type CombinedProps = StyledProps & Props;

const ConfirmCloseDialog = (props: CombinedProps) => {
    const {
        isOpen,
        closeDialog,
        zIndex,
        gatherCase,
        classes,
        closeParentDialog,
        isUserLoggedIn,
    } = props;

    const displayMessage = isUserLoggedIn
        ? 'Why link your account?'
        : 'Why create a free account?';
    return (
        <Dialog
            open={isOpen}
            onClose={e => closeDialog()}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            scroll="paper"
            style={{ zIndex }}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid item xs={12} className={classes.caseAvatarContainer}>
                    <IconButton
                        className={classes.avatarButton}
                        disableRipple
                        disableTouchRipple
                        size="large">
                        <UserAvatar
                            user={gatherCase}
                            size={120}
                            className={classes.avatar}
                        />
                    </IconButton>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.headerText}>{displayMessage}</Typography>
                    <Divider className={classes.divider} />
                    <Typography className={classes.headerHelperText}>
                        Memories of {gatherCase.display_fname} are important tributes that will last forever.
                        These should come from real people, not anonymous posts.
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.padding_0_8}>
                <Grid item xs={12} className={classes.dialogContent}>
                    <Typography color="secondary" className={classes.continuingText}>
                        By continuing, you will be able to...
                    </Typography>
                    <Typography color="secondary" className={classes.benefitText}>
                        Show your love & support
                    </Typography>
                    <Typography color="secondary" className={classes.benefitText}>
                        Connect with family & friends
                    </Typography>
                    <Typography color="secondary" className={classes.benefitText}>
                        Stay informed about service details
                    </Typography>
                    <Typography color="secondary" className={classes.benefitText}>
                        Share your favorite photos and memories
                    </Typography>
                    <Typography color="secondary" className={classes.benefitText}>
                        Get notified when other memories are shared
                    </Typography>

                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.continueButton}
                        onClick={e => closeDialog()}
                    >
                        Continue
                    </Button>

                    <Typography color="primary" className={classes.privacyText}>
                        Your contact information will never be shared publicly
                    </Typography>

                    <Button
                        color="secondary"
                        className={classes.maybeLaterButton}
                        onClick={e => closeParentDialog()}
                    >
                        <HighlightOffIcon />
                        I don't want to contribute, maybe later.
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withStyles(styles)(ConfirmCloseDialog);
