import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';
import makeGStyles from '../../../../../../styles/makeGStyles';

export interface Props<T extends string> {
    id: string;
    label: string;
    options: T[];
    selectedOptions: T[] | null;
    zIndex: number;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    helperElement?: JSX.Element;
    rootClass?: string;
    inputLabelClass?: string;
    selectMenuClass?: string;
    isHiddenFromFamily?: boolean;
    validator: () => void;
    onSelect: (option: T[]) => void;
    onClick?: () => void;
}

const useStyles = makeGStyles({
    root: {
        width: '100%',
        textAlign: 'left',
        marginTop: 20,
    },
}, { name: 'DCMultiSelect' });


const DCMultiSelect = <T extends string>(props: Props<T>) => {
    const {
        id,
        error,
        disabled,
        required,
        options,
        selectedOptions,
        zIndex,
        helperElement,
        label,
        rootClass,
        inputLabelClass,
        selectMenuClass,
        isHiddenFromFamily,
        validator,
        onSelect,
        onClick
    } = props;

    const classes = useStyles();

    const onChange = (e: SelectChangeEvent<T[]>) => {
        const value = e.target.value as unknown;

        if (Array.isArray(value)) {
            onSelect(value);
        }
    };

    return (
        <FormControl
            className={classNames(classes.root, rootClass)}
            required={required}
            error={error}
        >
            <InputLabel
                htmlFor={id}
                className={inputLabelClass}
                variant="standard"
            >
                {label}
            </InputLabel>
            <Select
                multiple
                value={selectedOptions || []}
                required={required}
                onChange={onChange}
                disabled={disabled}
                onClick={onClick}
                MenuProps={{ style: { zIndex }, }}
                inputProps={{
                    id,
                    className: classNames(!selectedOptions && 'notranslate'),
                }}
                endAdornment={
                    <HiddenFromFamilyAdornment
                        showMe={isHiddenFromFamily}
                        useAdornmentComponent
                    />
                }
                classes={{ select: classNames(classes.themeSelectMenu, selectMenuClass) }}
                onBlur={validator}
                autoComplete="new-password"
                error={error}
                renderValue={(selected) => {
                    if (Array.isArray(selected)) {
                        return selected.join(', ');
                    }
                    return null;
                }}
            >
                {options.map(option => (
                    <MenuItem value={option} key={option}>
                        <Checkbox
                            color="primary"
                            checked={!!(selectedOptions && selectedOptions.some(item => item === option))}
                            disabled={disabled}
                        />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
            {helperElement}
        </FormControl>
    );
};

export default DCMultiSelect;
