import LockIcon from '@mui/icons-material/Lock';
import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import { InitialStepListItem } from '../TaskDialogInitialState';
import CommonFreezeCredit from './CommonFreezeCredit';

export enum FreezeType {
    Equifax = 'Equifax',
    Experian = 'Experian',
    TransUnion = 'TransUnion'
};

export const FREEZE_TYPE_ADDRESS_LOOKUP: Record<FreezeType, { poBox: string; address: string }> = {
    [FreezeType.Equifax]: {
        poBox: 'PO Box 740241',
        address: 'Atlanta, GA 30374-2056'
    },
    [FreezeType.Experian]: {
        poBox: 'P.O. Box 9701',
        address: 'Allen, TX 75013'
    },
    [FreezeType.TransUnion]: {
        poBox: 'PO Box 2000',
        address: 'Chester, PA 19016'
    }
};

const FreezeCreditDialog = (props: TaskDialogComponentProps) => {
    const { activeCase, userData, activeFuneralHome, setAppSnackbar } = props;

    const description = (
        <>
            You and your family shouldn't have to worry about the hassle and burden of someone
            stealing {activeCase.fname}'s identity. We've made it simple to minimize this risk as
            much as possible by freezing {activeCase.fname}'s credit. Simple instructions for
            each of the three credit bureaus are listed below. Simply click each one
            and in a few minutes, you will stop identity thieves in their tracks. If you have questions,
            simply <FhOrAssigneePhoneNumberLink activeCase={activeCase} /> to call me directly.
        </>
    );

    const listItems: InitialStepListItem[] = [{
        id: 1,
        label: `Freeze ${activeCase.fname}'s Credit with ${FreezeType.Equifax}`,
        icon: <LockIcon />,
        content: (
            <CommonFreezeCredit
                activeCase={activeCase}
                setAppSnackbar={setAppSnackbar}
                userData={userData}
                freezeType={FreezeType.Equifax}
                funeralHomeData={activeFuneralHome}
            />
        ),
    }, {
        id: 2,
        icon: <LockIcon />,
        label: `Freeze ${activeCase.fname}'s Credit with ${FreezeType.Experian}`,
        content: (
            <CommonFreezeCredit
                activeCase={activeCase}
                setAppSnackbar={setAppSnackbar}
                userData={userData}
                freezeType={FreezeType.Experian}
                funeralHomeData={activeFuneralHome}
            />
        )
    }, {
        id: 3,
        icon: <LockIcon />,
        label: `Freeze ${activeCase.fname}'s Credit with ${FreezeType.TransUnion}`,
        content: (
            <CommonFreezeCredit
                activeCase={activeCase}
                setAppSnackbar={setAppSnackbar}
                userData={userData}
                freezeType={FreezeType.TransUnion}
                funeralHomeData={activeFuneralHome}
            />
        )
    }];

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: `Freeze ${activeCase.fname}'s Credit`,
                description,
                listItems
            }}
        />
    );
};

export default FreezeCreditDialog;