import * as React from 'react';

import Grid from '@mui/material/Grid';

import ModerationVisitor from './ModerationVisitor';
import {
    ModerationStatus,
    ModerationVisitorUX,
    ModerationCategory,
    VisitorForModeration
} from '../../../../shared/types';
import { StyledProps } from '../ModerationContent.styles';
import BulkModerateButtons from '../BulkModerateButtons';

interface Props {
    visitors: ModerationVisitorUX[];
    moderationStatus: ModerationStatus;
    isRememberPage: boolean;
    showIndividualCards: boolean;
    openMenu: (event: React.MouseEvent<HTMLElement>, visitor: ModerationVisitorUX) => void;
    onDecision: (photos: ModerationVisitorUX[], isApproval: boolean) => void;
    openGuestPopper: (event: React.MouseEvent<HTMLElement>, visitor: VisitorForModeration) => void;
}

const VisitorsSection = (props: StyledProps & Props) => {
    const {
        moderationStatus,
        visitors,
        classes,
        isRememberPage,
        showIndividualCards,
        openMenu,
        onDecision,
        openGuestPopper
    } = props;
    // Sort visitors by case_id and visitor.id
    const sortedVisitors = visitors.sort((a, b) => a.case_id === b.case_id
        ? a.visitor.case_entity_id - b.visitor.case_entity_id
        : a.case_id - b.case_id
    );
    let lastCase: number | null = null;
    let showHeader: boolean = false;
    return (
        <Grid item xs={12} className={classes.memoryContainer}>

            {sortedVisitors.map((modVisitor, i) => {
                if (lastCase !== modVisitor.case_id) {
                    lastCase = modVisitor.case_id;
                    showHeader = showIndividualCards;
                } else {
                    showHeader = false;
                }

                return <ModerationVisitor
                    key={modVisitor.visitor.case_entity_id}
                    moderationVisitor={modVisitor}
                    isLastMemory={i === (visitors.length - 1)}
                    openMenu={openMenu}
                    classes={classes}
                    onApprove={() => onDecision([modVisitor], true)}
                    onBlock={() => onDecision([modVisitor], false)}
                    openGuestPopper={openGuestPopper}
                    isRememberPage={isRememberPage}
                    showHeader={showHeader}
                />;
            })}

            {moderationStatus === ModerationStatus.pending &&
                <BulkModerateButtons
                    itemCount={visitors.length}
                    category={ModerationCategory.visitors}
                    onApprove={() => onDecision(visitors, true)}
                    onBlock={() => onDecision(visitors, false)}
                />
            }

        </Grid>
    );
};

export default VisitorsSection;