import { Component } from 'react';
import { DocPacketUX, GatherCaseUX } from '../../../shared/types';
import { RED_COLOR } from '../../../constants/colorVariables';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import { deleteDocPacket } from '../../../actions/DocPacket.action';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import GPopper from '../../common/GPopper';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    moreIcon: {
        position: 'absolute',
        right: 4,
        top: 4,
        width: 24,
        height: 24,
        zIndex: 1,
        '& svg': {
            fontSize: 16,
        },
    },
    paper: {
        overflow: 'auto',
    },
    deleteMenuItem: {
        color: RED_COLOR,
        '& svg': {
            color: RED_COLOR,
        },
    },
    deleteSubHeader: {
        color: RED_COLOR,
        fontWeight: 600,
    },
});

type StyledProps = Props & WithStyles<'root' | 'moreIcon' | 'paper' | 'deleteMenuItem' | 'deleteSubHeader'>;

interface Props {
    dispatch: AppDispatch;
    anchorEle: HTMLElement | null;
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
    setAnchorEl: (anchorEl: HTMLElement | null) => void;
    closeMenu: () => void;
    zIndex: number;
    afterDeleteAction?: () => void;
    hideIcon?: boolean;
}

interface State {
    isDeleteConfirmationDialogOpen: boolean;
}

class DocPacketMenu extends Component<StyledProps, State> {
    state: State = {
        isDeleteConfirmationDialogOpen: false,
    };

    renderMenuItems = () => {
        const { classes, anchorEle, zIndex } = this.props;

        return (
            <GPopper
                anchorEle={anchorEle}
                paperClass={classes.paper}
                zIndex={zIndex + 1}
                closePopper={(e) => !this.state.isDeleteConfirmationDialogOpen && this.closeMenu()}
                hideArrow
            >
                <MenuItem className={classes.deleteMenuItem} onClick={this.openDeletePacketConfirmation}>
                    Delete Packet
                    <DeleteIcon />
                </MenuItem>
            </GPopper>
        );
    };

    renderDeletePacketConfirmation = () => {
        const { zIndex, classes } = this.props;
        const { isDeleteConfirmationDialogOpen } = this.state;
        const deleteDialogHeader = 'Are you sure you want to delete this signature packet';
        const deleteDialogSubHeader = ' This action cannot be undone';
        const deleteDialogConfirmationButton = 'Delete Packet';
        const cancelButtonText = 'Never mind';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                subHeader={deleteDialogSubHeader}
                subHeaderStyles={classes.deleteSubHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={this.closeMenu}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={this.handleDeleteDocPacket}
                cancelButtonText={cancelButtonText}
                zIndex={zIndex + 2}
            />
        );
    };

    render() {
        const { classes, hideIcon } = this.props;
        return (
            <>
                {!hideIcon && (
                    <IconButton
                        className={classes.moreIcon}
                        onClick={(e) => this.openMenu(e.currentTarget)}
                        size="large"
                    >
                        <MoreVertIcon />
                    </IconButton>
                )}
                {this.renderMenuItems()}
                {this.renderDeletePacketConfirmation()}
            </>
        );
    }

    openMenu = (anchorEle: HTMLElement) => {
        const { setAnchorEl } = this.props;
        setAnchorEl(anchorEle);
    };

    closeMenu = () => {
        const { closeMenu } = this.props;
        closeMenu();
    };

    closeDeletePacketConfirmation = () => {
        this.setState({ isDeleteConfirmationDialogOpen: false }, this.closeMenu);
    };

    openDeletePacketConfirmation = () => {
        this.setState({ isDeleteConfirmationDialogOpen: true });
    };

    handleDeleteDocPacket = () => {
        const { dispatch, docPacket, activeCase, afterDeleteAction } = this.props;
        dispatch(deleteDocPacket(docPacket, activeCase));
        this.closeDeletePacketConfirmation();
        if (afterDeleteAction) {
            afterDeleteAction();
        }
    };
}

export default withState()(withStyles(styles)(DocPacketMenu));
