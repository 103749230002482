import { GatherAction } from '../actions';
import { PERSON_TABLE, PersonTableAction } from '../actions/Person.action';
import * as constants from '../constants';
import { EntityTableReduxState } from '../types';

export const personTableReduxInitData: EntityTableReduxState = {
    data: [],
    hasMoreData: true,
    searchText: '',
    sortBy: 'id',
    sortDirection: 'asc',
    isLoading: false,
    totalPersonCount: 0,
};
// data: T[];
// hasMoreData: boolean;
// searchText: string;
// sortBy: string;
// sortDirection: 'desc' | 'asc';
// isLoading: boolean;
export const entityTableState = (
    state: EntityTableReduxState = personTableReduxInitData,
    action: PersonTableAction | GatherAction,

): EntityTableReduxState => {

    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return personTableReduxInitData;
        case PERSON_TABLE.PERSON_TABLE_LOADED:
            return {
                ...state,
                data: [...state.data, ...action.data],
                isLoading: false,
                hasMoreData: action.hasMoreData,
            };
        case PERSON_TABLE.PERSON_TABLE_LOADING:
            return {
                ...state,
                data: [...action.data],
                searchText: action.searchText,
                sortDirection: action.sortDirection,
                sortBy: action.sortBy,
                isLoading: true,
            };
        case PERSON_TABLE.PERSON_TABLE_LOAD_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case PERSON_TABLE.SET_TOTAL_PERSON_COUNT:
            return {
                ...state,
                totalPersonCount: action.count
            };
        default:
            return state;
    }
};