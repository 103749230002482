import * as t from 'io-ts';
import { getValidator } from '../../shared/types';

const keepTrackECommQuoteDefinition = {
    funeralHomeName: t.string,
    funeralHomeKey: t.union([t.string, t.null]),
    userFName: t.string,
    userLName: t.string,
    locations: t.number,
    starterKits: t.number,
    keepTrackCards: t.number,
    familyMedallions: t.number,
};
const KeepTrackECommQuoteType = t.type(keepTrackECommQuoteDefinition);

export interface KeepTrackECommQuote extends t.TypeOf<typeof KeepTrackECommQuoteType> {
}

export class KeepTrackECommQuote {
    public static fromRequest = getValidator<KeepTrackECommQuote>(KeepTrackECommQuoteType);
}

export const KEEP_TRACK_SETTINGS = {
    costPerLocation: 100,
    costPerStarterKit: 349,
    keepTrackCard: {
        numberPerKit: 20,
        cost: 10,
        revenue: 40,
    },
    familyMedallion: {
        numberPerKit: 10,
        cost: 10,
        revenue: 60,
    }
};

const createPaymentIntentRequestDefinition = {
    quote: KeepTrackECommQuoteType,
    userName: t.string,
    email: t.string,
    address: t.string,
    funeralHomeKey: t.union([t.string, t.undefined]),
};
const CreatePaymentIntentRequestType = t.type(createPaymentIntentRequestDefinition);

export interface CreatePaymentIntentRequest extends t.TypeOf<typeof CreatePaymentIntentRequestType> {
}

export class CreatePaymentIntentRequest {
    public static fromRequest = getValidator<CreatePaymentIntentRequest>(CreatePaymentIntentRequestType);
}

const updatePaymentIntentRequestDefinition = {
    ...createPaymentIntentRequestDefinition,
    paymentIntentId: t.string,
};
const UpdatePaymentIntentRequestType = t.type(updatePaymentIntentRequestDefinition);
export interface UpdatePaymentIntentRequest extends t.TypeOf<typeof UpdatePaymentIntentRequestType> {
}
export class UpdatePaymentIntentRequest {
    public static fromRequest = getValidator<UpdatePaymentIntentRequest>(UpdatePaymentIntentRequestType);
}

const paymentIntentResponseDefinition = {
    id: t.string,
    client_secret: t.union([t.string, t.null]),
    // the connectedAccountId is optional when using the default account
    connectedAccountId: t.union([t.string, t.undefined]),
};
const PaymentIntentResponseType = t.type(paymentIntentResponseDefinition);

export interface PaymentIntentResponse extends t.TypeOf<typeof PaymentIntentResponseType> {}

export class PaymentIntentResponse {
    public static fromRequest = getValidator<PaymentIntentResponse>(PaymentIntentResponseType);
}

const ExistingPaymentMethodType = t.type({
    funeralHomeKey: t.string,
    customerId: t.string,
    paymentMethodId: t.string,
    last4: t.string,
    brand: t.string, // e.g. Visa
});

export interface ExistingPaymentMethod extends t.TypeOf<typeof ExistingPaymentMethodType> {
}

export class ExistingPaymentMethod {
    public static fromRequest = getValidator<ExistingPaymentMethod>(ExistingPaymentMethodType);
}

const CustomerPaymentDataType = t.type({
    caseUuid: t.string,
    subTotal: t.number,
    fees: t.number,
    name: t.string,
    email: t.string,
    memo: t.string,
});

export interface CustomerPaymentData extends t.TypeOf<typeof CustomerPaymentDataType> {}

export class CustomerPaymentData {
    public static fromRequest = getValidator<CustomerPaymentData>(CustomerPaymentDataType);
}

export interface KeepTrackQuoteLineItems {
    totalMonthlyCost: number;   // locations x locationCost
    totalStarterKitCost: number; // starterKits x starterKit Cost
    extraKeepTrackCost: number;
    extraKeepTrackRevenue: number;
    extraMedallionsCost: number;
    extraMedallionsRevenue: number;
    totalKeepTrackRevenue: number;
    totalMedallionRevenue: number;
    totalAdditionalInventory: number;
    totalRevenue: number;
    totalCost: number;
}

const KeepTrackInitNotificationType = t.type({
    name: t.string,
    funeralHomeName: t.string,
    userEmail: t.string,
});

export interface KeepTrackInitNotification extends t.TypeOf<typeof KeepTrackInitNotificationType> {
}

export class KeepTrackInitNotification {
    public static fromRequest = getValidator<KeepTrackInitNotification>(KeepTrackInitNotificationType);
}

const CourseApplicantType = t.type({
    id: t.string,
    fullName: t.string,
    email: t.string,
    canCustomize: t.boolean,
});

export interface CourseApplicant extends t.TypeOf<typeof CourseApplicantType> {
}

const CourseEnrollmentRequestType = t.type({
    applicants: t.array(CourseApplicantType),
    quote: KeepTrackECommQuoteType,
});

export interface CourseEnrollmentRequest extends t.TypeOf<typeof CourseEnrollmentRequestType> {
}

export class CourseEnrollmentRequest {
    public static fromRequest = getValidator<CourseEnrollmentRequest>(CourseEnrollmentRequestType);
}

export interface CourseApplicant {
    id: string;
    fullName: string;
    email: string;
    canCustomize: boolean;
}

export function calcTotalLocationMonthlyCost(numberLocations: number): number {
    const { costPerLocation } = KEEP_TRACK_SETTINGS;
    return costPerLocation * numberLocations;
}

export function calcTotalStarterKitCost(numberKits: number): number {
    const { costPerStarterKit } = KEEP_TRACK_SETTINGS;
    return costPerStarterKit * numberKits;
}

export function calcItemRevenue(
    numberKis: number,
    includedPerKit: number,
    pricePerIem: number,
    extraItems: number
): number {
    return pricePerIem * (numberKis * includedPerKit + extraItems);
}

export function fmtDollarWithCommas(amountStr: string): string {
    const amount = parseFloat(amountStr);
    return fmtDollar(amount, 2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function fmtDollar(amount: number, decimals: number = 0): string {
    return `$${amount.toFixed(decimals)}`;
}

export function calculateLineItems(quote: KeepTrackECommQuote): KeepTrackQuoteLineItems {
    const { familyMedallion, keepTrackCard } = KEEP_TRACK_SETTINGS;
    const { locations, starterKits } = quote;
    const totalMonthlyCost = calcTotalLocationMonthlyCost(locations);
    const totalStarterKitCost = calcTotalStarterKitCost(starterKits);
    const extraKeepTrackCost = keepTrackCard.revenue * quote.keepTrackCards;
    const extraKeepTrackRevenue = keepTrackCard.revenue * quote.keepTrackCards;
    const extraMedallionsCost = familyMedallion.cost * quote.familyMedallions;
    const extraMedallionsRevenue = familyMedallion.revenue * quote.familyMedallions;
    const totalAdditionalInventory = extraMedallionsCost + extraKeepTrackCost;
    const totalKeepTrackRevenue =
        calcItemRevenue(starterKits, keepTrackCard.numberPerKit, keepTrackCard.revenue, quote.keepTrackCards);
    const totalMedallionRevenue =
        calcItemRevenue(starterKits, familyMedallion.numberPerKit, familyMedallion.revenue, quote.familyMedallions);
    const totalRevenue = totalKeepTrackRevenue + totalMedallionRevenue;

    const totalCost = totalMonthlyCost + totalStarterKitCost + extraKeepTrackCost + extraMedallionsCost;

    return {
        totalMonthlyCost,
        totalStarterKitCost,
        extraKeepTrackRevenue,
        extraKeepTrackCost,
        extraMedallionsCost,
        extraMedallionsRevenue,
        totalAdditionalInventory,
        totalKeepTrackRevenue,
        totalMedallionRevenue,
        totalRevenue,
        totalCost,
    };
}
