import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';

import ClearIcon from '@mui/icons-material/Clear';
import { AppRoute, convertHexToRGBA } from '../../../../services';

import ButtonLink from '../../../common/ButtonLink';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
            }
        },
    },
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: `1px solid ${convertHexToRGBA(theme.palette.secondary.main, 0.26)}`,
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
        '@media (min-width: 712px)': {
            padding: '0 16px',
        },
        marginBottom: 16,
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        filter: 'drop-shadow(0 0px 2px gray)',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        fontSize: 18,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: 20,
        '& svg': {
            fontSize: 30
        },
        '@media (min-width: 400px)': {
            fontSize: 20,
        },
    },
    printOptionContainer: {
        '& $label': {
            margin: '24px 0 8px',
            fontSize: 18,
            fontWeight: 300,
            textAlign: 'center'
        },
        '& $inner': {
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            overflow: 'auto',
            paddingBottom: 24,
            '@media (min-width: 680px)': {
                justifyContent: 'center',
            },
            '& $last': {
                width: 1,
                height: 1,
                minWidth: 1,
            },
        },
        '& $card': {
            width: 320,
            minWidth: 320,
            margin: '0 8px',
            borderRadius: 4,
            color: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: 'none',
            position: 'relative',
            cursor: 'pointer',
            '& button': {
                height: '100%',
            },
            '& p': {
                color: theme.palette.secondary.main
            },
            '&$active': {
                boxShadow: theme.shadows[7],
                background: theme.palette.primary.main,
                border: '1px solid',
                '& p': {
                    color: theme.palette.common.white
                },
                '& svg': {
                    color: theme.palette.common.white
                },
            },
            '&:hover': {
                boxShadow: theme.shadows[10],
            },
            '& $cardHeading': {
                fontSize: 18,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 16px',
                borderBottom: '1px solid',
                padding: '8px 0',
                color: theme.palette.primary.main,
                '& svg': {
                    fontSize: 28,
                },
            },
            '& $cardContent': {
                padding: '8px 8px 16px',
                '& p': {
                    fontWeight: 300,
                    textAlign: 'center',
                },
            },
        },
    },
    footer: {
        margin: '24px auto 8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        '& p': {
            fontSize: 14,
            fontWeight: 300,
        },
        '@media (min-width: 712px)': {
            width: 'calc(100% - 32px)',
            '& p': {
                fontSize: 16,
            },
        },
        '@media (min-width: 960px)': {
            position: 'unset',
        },
    },
    footerInner: {
        width: 'fit-content',
        margin: '0 auto',
        textAlign: 'center',
        '& p': {
            paddingTop: 4,
            fontSize: 12,
            fontWeight: 300,
        }
    },
    paddingBottom16: {
        paddingBottom: 16,
    },
    last: {},
    inner: {},
    label: {},
    active: {},
    card: {},
    cardHeading: {},
    cardContent: {},
    dialogPaper: {},
});

type StyledProps = Props & WithGStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent'
    | 'clearIcon' | 'heading' | 'printOptionContainer' | 'card' | 'cardHeading' | 'cardContent' | 'label'
    | 'inner' | 'active' | 'footer' | 'last' | 'footerInner' | 'paddingBottom16'>;

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    onExited: () => void;
    heading: JSX.Element;
    optionsList: PrintOptionsList[];
    isDialogOpen: boolean;
    closeDialog: () => void;
    zIndex: number;
    footerContent: FooterDetails;
    getPrintLayoutButtons?: () => JSX.Element;
}

export const VISUAL_LAYOUT = 'visual_layout';
export const COMPACT_LAYOUT = 'compact_layout';

export enum PrintOptionsType {
    ItemizedStatement = 'Itemized Statement',
    QuickStatement = 'Quick Statement',
    OfficialStatement = 'Official Statement',
    DraftStatement = 'Draft Statement',
}

export interface PrintOptionsList {
    id: PrintOptionsType;
    primaryText: PrintOptionsType;
    secondaryText: JSX.Element;
    icon: JSX.Element;
    isActive: boolean;
    onClick: () => void;
    additionalClass?: string;
    lockIcon?: JSX.Element;
}

export interface FooterDetails {
    buttonText: JSX.Element;
    helperText: string;
    bottomText?: JSX.Element;
    onClick?: () => void;
    isButtonLink?: boolean;
    buttonLink?: AppRoute;
    disabled?: boolean;
}

type CombinedProps = DialogProps & StyledProps;
class PrintDialog extends React.Component<CombinedProps> {

    renderOptions = () => {
        const { classes, optionsList } = this.props;

        return (
            <Grid item xs={12} className={classes.printOptionContainer}>
                <Typography
                    color="secondary"
                    className={classes.label}
                >
                    What would you like to print?
                </Typography>

                <div className={classNames(classes.inner, classes.paddingBottom16)}>
                    {optionsList.map(option =>
                        <Card
                            className={classNames(
                                classes.card,
                                option.isActive && classes.active,
                                option.additionalClass
                            )}
                            key={option.id}
                        >
                            <CardActionArea
                                onClick={option.onClick}
                            >
                                {option.lockIcon}
                                <Typography className={classes.cardHeading}>
                                    {option.icon}&nbsp;{option.primaryText}
                                </Typography>

                                <CardContent className={classes.cardContent}>
                                    {option.secondaryText}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )}
                    <div className={classes.last} />
                </div>
            </Grid>
        );
    };

    renderFooter = () => {
        const { classes, footerContent } = this.props;
        const { isButtonLink, buttonText, buttonLink, disabled, helperText, bottomText, onClick } = footerContent;

        return (
            <>
                <Grid className={classes.footerInner}>
                    {isButtonLink && buttonLink
                        ? <ButtonLink
                            color="primary"
                            variant="contained"
                            link={buttonLink}
                            disabled={disabled}
                        >
                            {buttonText}
                        </ButtonLink>
                        : <Button
                            color="primary"
                            variant="contained"
                            onClick={onClick}
                        >
                            {buttonText}
                        </Button>
                    }
                    <Typography color="secondary">{helperText}</Typography>
                </Grid>

                {bottomText}
            </>
        );
    };

    render() {
        const {
            classes,
            fullScreen,
            isDialogOpen,
            closeDialog,
            heading,
            zIndex,
            onExited,
            getPrintLayoutButtons
        } = this.props;

        return (
            <Dialog
                TransitionProps={{ onExited }}
                fullScreen={fullScreen}
                open={isDialogOpen}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                onClose={() => closeDialog()}
                className={classes.root}
                classes={{ paper: classes.dialogPaper }}
                style={{ zIndex }}
            >
                <DialogTitle
                    className={classes.dialogHeader}
                >
                    <ClearIcon
                        color="secondary"
                        className={classes.clearIcon}
                        onClick={() => closeDialog()}
                    />
                    <Typography
                        component="p"
                        color="primary"
                        align="left"
                        className={classes.heading}
                    >
                        {heading}
                    </Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {getPrintLayoutButtons && getPrintLayoutButtons()}
                    {this.renderOptions()}

                    <Grid item xs={12} className={classes.footer}>
                        {this.renderFooter()}
                    </Grid>

                </DialogContent>
            </Dialog>
        );
    }
}

export default withFullScreen('md')(withGStyles(styles)(PrintDialog));
