import Delete from '@mui/icons-material/Delete';

import { PopoverReference } from '@mui/material/Popover';
import GMenu, { GMenuItem } from '../../../common/GMenu';
import { GStyles } from '../../../../styles/GStyles';

interface Props {
    menuAnchorEle: HTMLElement | null;
    zIndex: number;
    closeMenu: () => void;
    onViewClick: () => void;
    onDownloadClick: () => void;
    onRenameClick?: () => void;
    onDeleteClick: () => void;
    closeMenuAndCallAction: (callback: () => void) => () => void;
    isDD214?: boolean;
    additionalMenuItems?: AdditionalDocMenuItems[];
    hiddenMenuItems?: HiddenDocMenuItems[];
    anchorReference?: PopoverReference;
    disableDeleteButton: boolean;
}

export enum HiddenDocMenuItems {
    view = 'view',
    delete = 'delete',
    download = 'download',
    rename = 'rename'
}

export interface AdditionalDocMenuItems {
    content: string | JSX.Element;
    onClick?: () => void;
}

const DocItemMenu = (props: Props) => {
    const {
        menuAnchorEle,
        zIndex,
        isDD214,
        additionalMenuItems,
        hiddenMenuItems,
        anchorReference,
        disableDeleteButton,
        closeMenu,
        onViewClick,
        onDeleteClick,
        onRenameClick,
        onDownloadClick,
        closeMenuAndCallAction,
    } = props;

    const fileName = isDD214 ? 'DD-214' : 'File';
    const items = additionalMenuItems || [];
    const hiddenItems = hiddenMenuItems || [];
    const isViewHidden = hiddenItems.find(item => item === HiddenDocMenuItems.view);
    const isDownloadHidden = hiddenItems.find(item => item === HiddenDocMenuItems.download);
    const isRenameHidden = !onRenameClick || hiddenItems.find(item => item === HiddenDocMenuItems.rename);
    const isDeleteHidden = hiddenItems.find(item => item === HiddenDocMenuItems.delete);

    const menuItems: GMenuItem[] = [];
    if (!isViewHidden) {
        menuItems.push({
            id: 1,
            content: `View ${fileName}`,
            onClick: onViewClick,
        });
    }
    if (!isDownloadHidden) {
        menuItems.push({
            id: 2,
            content: `Download ${fileName}`,
            onClick: onDownloadClick,
        });
    }
    if (!isRenameHidden) {
        menuItems.push({
            id: 3,
            content: `Rename ${fileName}`,
            onClick: onRenameClick,
        });
    }
    if (!isDeleteHidden) {
        menuItems.push({
            id: 4,
            content: <><Delete />&nbsp;Delete {fileName}</>,
            onClick: onDeleteClick,
            className: GStyles.colorRed,
            disabled: disableDeleteButton,
            tooltip: disableDeleteButton
                ? 'This file was uploaded by Gather and is mapped into your system;' +
                ' to get it deleted, please reach out to support.'
                : undefined
        });
    }

    items.forEach((item, idx) => {
        menuItems.push({
            id: `AdditionalDocItem-${idx}`,
            onClick: item.onClick && closeMenuAndCallAction(item.onClick),
            content: item.content,
            useFragment: typeof item.content !== 'string'
        });
    });

    const anchorPosition = menuAnchorEle
        ? menuAnchorEle.getBoundingClientRect()
        : undefined;

    return (
        <GMenu
            zIndex={zIndex}
            anchorEl={menuAnchorEle}
            onClose={closeMenu}
            items={menuItems}
            anchorReference={anchorReference}
            anchorPosition={anchorPosition}
        />
    );
};

export default DocItemMenu;
