import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { RED_COLOR } from '../../../constants/colorVariables';
import { convertHexToRGBA } from '../../../services/app.service';
import { green } from '@mui/material/colors';

function styles<Props extends object>(): StyleRulesCallback<Theme, Props> {
    return (theme) => ({
        root: {
            '& $dialogPaper': {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                width: '100%',
                maxWidth: '100%',
                borderRadius: 0,
                height: '100%',
                backgroundColor: theme.palette.common.white,
                '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
                    backgroundColor: 'rgba(255,255,255,.68)',
                    backdropFilter: 'blur(60px)',
                },
                '@media (min-width: 768px)': {
                    maxWidth: 600,
                    width: 600,
                    borderRadius: 12,
                    height: 'auto',
                    margin: '48px auto',
                },
            },
        },
        dialogHeader: {
            zIndex: 1,
            padding: 0,
            backgroundColor: theme.palette.primary.main,
            minHeight: 64,
            '& h6': {
                margin: '8px 4px',
            },
        },
        dialogContent: {
            zIndex: 0,
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            overflowX: 'hidden',
            minHeight: 120,
            paddingTop: '0px !important',
            '& $inner': {
                position: 'relative',
            },
        },
        clearIcon: {
            position: 'absolute',
            top: 8,
            right: 10,
            fontSize: 30,
            '&:hover': {
                cursor: 'pointer',
            },
            '@media (min-width: 400px)': {
                fontSize: 36,
            },
            '@media (min-width: 768px)': {
                display: 'none',
            },
        },
        headerContent: {
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            margin: '4px 8px',
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        headerText: {
            fontSize: 24,
            lineHeight: '1.1em',
            color: theme.palette.primary.main,
        },
        textCenter: {
            textAlign: 'center',
        },
        tabAppbar: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            margin: '14px 0',
            marginBottom: 0,
            maxHeight: 22,
        },
        colorPrimary: {
            color: theme.palette.primary.main,
        },
        scrollButtons: {
            flex: 'none',
            width: 24,
        },
        tabRoot: {
            minWidth: 'fit-content',
            maxWidth: 'fit-content',
            minHeight: 24,
            textTransform: 'capitalize',
            '@media (min-width: 850px) and (max-width: 959px)': {
                minHeight: 28,
            },
        },
        labelContainer: {
            padding: '2px 6px',
        },
        divider: {
            width: 'calc(100% - 42px)',
            margin: '2px auto 0px auto',
        },
        scrollButtonsAuto: {
            display: 'block',
        },
        tabContentContainer: {
            margin: '0px auto 4px auto',
        },
        tabContainer: {
            width: '100%',
            margin: 'auto',
            position: 'relative',
            marginBottom: 18,
            '& p': {
                width: 'fit-content',
            },
        },
        textFieldContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            position: 'relative',
            maxWidth: 270,
            margin: 'auto',
            '@media (min-width: 400px)': {
                maxWidth: 320,
            },
            '@media (min-width: 600px)': {
                maxWidth: 500,
            },
            '& $svgContainer': {
                maxWidth: 'fit-content',
                position: 'absolute',
                left: '-26px',
                top: 28,
                '& svg': {
                    verticalAlign: 'bottom',
                    color: theme.palette.primary.main,
                },
            },
            '& $searchField': {
                margin: '8px 0px 20px 0',
                width: '100%',
            },
        },
        tabIndicator: {
            bottom: 2,
        },
        footerList: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 80,
            // marginBottom: 16
        },
        clickHereText: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        noResultsDivider: {
            width: '100%',
            margin: '8px 0',
        },
        noResultContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 400,
            margin: 'auto',
            marginTop: 80,
            marginBottom: 80,
            '& p': {
                fontSize: 18,
            },
        },
        tabsFlexContainer: {
            justifyContent: 'space-between',
        },
        textFieldRoot: {
            '& label': {
                fontSize: 12,
                '@media (min-width: 480px)': {
                    fontSize: 14,
                },
                '@media (min-width: 600px)': {
                    fontSize: 16,
                },
            },
        },
        filterTabAppbar: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.background.paper,
            boxShadow: 'none',
            margin: '5px 0',
            marginBottom: 0,
            maxHeight: 32,
        },
        filterTabIndicator: {
            bottom: 0,
        },
        filterTabsFlexContainer: {
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: 400,
            margin: 'auto',
        },
        filterTabsRoot: {
            width: '100%',
        },
        height60: {
            height: 60,
        },
        filterScrollButtons: {
            flex: 'none',
            width: 6,
        },
        firstItem: {
            maxWidth: 'calc(100% - 32px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '8px auto',
            '@media (min-width: 600px)': {
                maxWidth: 'calc(100% - 48px)',
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
        heading: {
            fontSize: 20,
            maxWidth: 280,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '@media (min-width: 580px)': {
                maxWidth: 500,
            },
            '& svg': {
                fontSize: 24,
                verticalAlign: 'sub',
            },
        },
        subHeading: {
            display: 'flex',
            alignItems: 'center',
        },
        profession: {
            fontSize: 14,
            maxWidth: 140,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            '@media (min-width: 580px)': {
                maxWidth: '100%',
            },
            paddingRight: 15,
        },
        location: {
            fontSize: 14,
            margin: '0px 8px',
            maxWidth: 140,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '@media (min-width: 580px)': {
                maxWidth: '100%',
            },
            '& svg': {
                fontSize: 18,
                verticalAlign: 'sub',
            },
        },
        assignments: {
            fontSize: 16,
            maxWidth: 140,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '@media (min-width: 580px)': {
                maxWidth: '100%',
            },
            '& svg': {
                fontSize: 20,
                verticalAlign: 'sub',
            },
        },
        tabContainerDivider: {
            width: 'calc(100% - 38px)',
            margin: 'auto',
        },
        addNewEntryButton: {
            whiteSpace: 'nowrap',
            minHeight: 30,
            padding: 0,
            fontSize: 12,
            marginTop: 20,
            marginLeft: 8,
            minWidth: 40,
            '@media (min-width: 400px)': {
                padding: '0px 24px',
            },
            '& svg': {
                fontSize: 20,
            },
            '& $buttonText': {
                display: 'none',
                '@media (min-width: 600px)': {
                    display: 'block',
                },
            },
        },
        headerIconButton: {
            width: 48,
            height: 48,
            '& svg': {
                fontSize: 32,
            },
        },
        pointerEventsNone: {
            pointerEvents: 'none',
        },
        stepTwoContainer: {
            margin: '12px 16px',
            '& $topSection': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                '& svg': {
                    fontSize: 44,
                },
                '& $officeName': {
                    fontSize: 20,
                    lineHeight: '1.15em',
                    '@media (min-width: 600px)': {
                        fontSize: 22,
                    },
                },
                '& $oganizationName': {
                    fontSize: 16,
                },
                '& $optionsButton': {
                    position: 'absolute',
                    top: '-8px',
                    fontSize: 12,
                    right: '-4px',
                    padding: 4,
                    minHeight: 26,
                    textTransform: 'capitalize',
                    '& svg': {
                        fontSize: 16,
                    },
                },
            },
        },
        headerContactButtons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 4,
            '& $contactButton': {
                borderRadius: 14,
                textTransform: 'capitalize',
                padding: '8px 6px 10px',
                fontSize: 12,
                minWidth: 64,
                maxWidth: 64,
                fontWeight: 400,
                margin: '0 6px',
                '@media (min-width: 960px)': {
                    minWidth: 58,
                },
                '&$buttonLabel': {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '& svg': {
                    fontSize: 28,
                },
                '& $contactText': {
                    lineHeight: '0.70em',
                    fontWeight: 300,
                },
            },
        },
        contactAndNotesSection: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            '@media (min-width: 660px)': {
                flexDirection: 'row',
                alignItems: 'inherit',
            },
            '& $leftSection': {
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 12px',
                flexDirection: 'column',
                maxWidth: 300,
                width: '100%',
                '& $contactButtonSection': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '& $contactButton': {
                        borderRadius: 14,
                        textTransform: 'capitalize',
                        padding: '8px 6px 10px',
                        fontSize: 12,
                        minWidth: 64,
                        maxWidth: 64,
                        fontWeight: 400,
                        '@media (min-width: 960px)': {
                            minWidth: 58,
                        },
                        '&$buttonLabel': {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        '& svg': {
                            fontSize: 28,
                        },
                        '& $contactText': {
                            lineHeight: '0.70em',
                            fontWeight: 300,
                        },
                    },
                },
                '& $organizationContactDetail': {
                    '& $contactLabel': {
                        fontSize: 12,
                        '& svg': {
                            fontSize: 16,
                            verticalAlign: 'bottom',
                        },
                    },
                    '& $orgnizationAddress': {
                        fontSize: 15,
                        margin: '0px 4px',
                        lineHeight: '1.3em',
                    },
                    '& $organizationContactDetailFooter': {
                        display: 'flex',
                        flexDrection: 'row',
                        justifyContent: 'space-between',
                    },
                },
            },
            '& $rightSection': {
                display: 'flex',
                margin: '16px 12px',
                flexDirection: 'column',
                maxWidth: 300,
                width: '100%',
                '& $internalNotesField': {
                    width: '100%',
                },
            },
        },
        docSectionContainer: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            padding: '12px 0px 20px',
            display: 'flex',
            '& $uploadButton': {
                padding: '6px 12px',
                textTransform: 'none',
                borderRadius: 6,
                fontWeight: 400,
                boxShadow: theme.shadows[3],
                minWidth: 145,
                '& svg': {
                    fontSize: 28,
                },
                '& $uploadButtonLabel': {
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 2,
                },
            },
            '& $docListContainer': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                maxWidth: 260,
                backgroundColor: theme.palette.common.white,
                borderRadius: 6,
                marginLeft: 6,
                boxShadow: theme.shadows[4],
                padding: '1px 0px',
                minWidth: 260,
                textTransform: 'none',
                '& svg': {
                    fontSize: 56,
                },
                '& $docLabelContainer': {
                    marginLeft: '-4px',
                    '& $docName': {
                        fontSize: 16,
                        maxWidth: 185,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                    '& $docUploadedBy': {
                        fontSize: 12,
                        lineHeight: '1.20em',
                    },
                    '& $docUploadedTime': {
                        fontSize: 12,
                        lineHeight: '1.20em',
                    },
                },
                '& $fileMoreIcon': {
                    width: 20,
                    height: 20,
                    position: 'absolute',
                    top: 6,
                    right: 4,
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
        marginTop8: {
            marginTop: 8,
        },
        marginBottom8: {
            marginBottom: '8px !important',
        },
        mainDivider: {
            width: 'calc(100% - 24px)',
            margin: '6px auto',
        },
        officeStaffContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '34px 0 12px',
            alignItems: 'center',
            '@media (min-width: 660px)': {
                flexDirection: 'row',
                alignItems: 'baseline',
            },
            '& $leftSection': {
                margin: '0px 12px',
                maxWidth: 300,
                width: '100%',
                backgroundColor: theme.palette.common.white,
                borderRadius: 10,
                '& $cardSection': {
                    position: 'relative',
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 10,
                    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.2),
                    '& $dummyDivAssignmentIcon': {
                        position: 'absolute',
                        fontSize: 40,
                        top: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: theme.palette.common.white,
                        width: 20,
                        height: 20,
                    },
                    '& $assignmentIcon': {
                        position: 'absolute',
                        fontSize: 40,
                        top: '-21px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    },
                    '& $staffName': {
                        fontSize: 20,
                        marginTop: 16,
                        lineHeight: '1.15em',
                    },
                    '& $staffProfession': {
                        fontSize: 15,
                        lineHeight: '1.30em',
                    },
                    '& $moreVertIcon': {
                        position: 'absolute',
                        top: 4,
                        right: 0,
                        width: 24,
                        height: 24,
                        '& svg': {
                            fontSize: 20,
                        },
                    },
                    '& $contactButtonSection': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: 200,
                        margin: '12px auto',
                        justifyContent: 'center',
                        '& $contactButton': {
                            border: `1px solid ${theme.palette.primary.main}`,
                            maxWidth: 38,
                            maxHeight: 38,
                            backgroundColor: theme.palette.common.white,
                            margin: '0px 6px',
                            '&:hover': {
                                backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.08),
                            },
                            '& svg': {
                                fontSize: 20
                            }
                        }
                    },
                    '& $contactDetailsSection': {
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '12px 8px',
                        '& $contactLabel': {
                            fontSize: 12,
                            '& svg': {
                                fontSize: 14,
                                verticalAlign: 'sub'
                            }
                        },
                        '& $contactText': {
                            marginLeft: 8,
                            lineHeight: '1.2em'
                        },
                        '& $contactDetailFooter': {
                            display: 'flex',
                            flexDrection: 'row',
                            justifyContent: 'space-between',
                        },
                    },
                },
            },
            '& $rightSection': {
                margin: '0px 12px',
                maxWidth: 300,
                minWidth: 300,
                marginTop: 32,
                width: '100%',
                backgroundColor: theme.palette.common.white,
                borderRadius: 10,
                '@media (min-width: 660px)': {
                    marginTop: 0,
                },
                '& $cardSection': {
                    position: 'relative',
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 10,
                    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.2),
                    '& $dummyDivAssignmentIcon': {
                        position: 'absolute',
                        fontSize: 40,
                        top: '-10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: theme.palette.common.white,
                        width: 20,
                        height: 20,
                    },
                    '& $assignmentIcon': {
                        position: 'absolute',
                        fontSize: 40,
                        top: '-21px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    },
                    '& $staffName': {
                        fontSize: 20,
                        marginTop: 16,
                        lineHeight: '1.15em',
                    },
                    '& $staffProfession': {
                        fontSize: 15,
                        lineHeight: '1.30em',
                    },
                    '& $moreVertIcon': {
                        position: 'absolute',
                        top: 4,
                        right: 0,
                        width: 24,
                        height: 24,
                        '& svg': {
                            fontSize: 20,
                        },
                    },
                    '& $contactButtonSection': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: 200,
                        margin: '12px auto',
                        justifyContent: 'center',
                        '& $contactButton': {
                            border: `1px solid ${theme.palette.primary.main}`,
                            maxWidth: 38,
                            maxHeight: 38,
                            backgroundColor: theme.palette.common.white,
                            margin: '0px 6px',
                            '&:hover': {
                                backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.08),
                            },
                            '& svg': {
                                fontSize: 20
                            }
                        }
                    },
                    '& $contactDetailsSection': {
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '12px 8px',
                        '& $contactLabel': {
                            fontSize: 12,
                            '& svg': {
                                fontSize: 14,
                                verticalAlign: 'sub'
                            }
                        },
                        '& $contactText': {
                            marginLeft: 8,
                            lineHeight: '1.2em'
                        },
                        '& $contactDetailFooter': {
                            display: 'flex',
                            flexDrection: 'row',
                            justifyContent: 'space-between',
                        },
                    },
                },
            },
        },
        marginBottom10: {
            marginBottom: 10,
        },
        marginTop28: {
            marginTop: 28,
        },
        menuPopper: {
            width: 'fit-content',
            maxWidth: 300,
            position: 'fixed',
            '&[data-popper-placement*="bottom"]': {
                marginTop: '14px !important',
            },
            '&[data-popper-placement*="top"]': {
                marginBottom: '14px !important',
            },
            '&[data-popper-placement*="bottom"] $arrow': {
                borderBottom: '20px solid #fff',
                borderTop: 0,
                top: '-16px',
            },
            '&[data-popper-placement*="top"] $arrow': {
                borderTop: '20px solid #fff',
                borderBottom: 0,
                bottom: '-20px',
            },
        },
        docMenuPopper: {
            width: 'fit-content',
            maxWidth: 300,
            position: 'fixed',
            top: 2,
        },
        arrow: {
            width: 0,
            height: 0,
            position: 'absolute',
            border: '18px solid transparent',
            overflow: 'hidden',
            boxShadow: '0 16px 10px -14px rgba(0, 0, 0, 0.5)',
            right: 61,
            '@media (min-width: 600px)': {
                right: 69,
            },
        },
        menuPaper: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 4,
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
            '-webkit-filter': 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
        },
        menuItem: {
            padding: '4px 8px',
        },
        deleteIcon: {
            color: RED_COLOR,
            '& svg': {
                color: RED_COLOR,
            },
        },
        caseDetailContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '12px auto',
            '& $avatar': {
                width: 50,
                height: 50,
            },
            '& $nameInitialsAvatar': {
                height: 90,
                width: 90,
                fontSize: 40,
                fontWeight: 200,
                textTransform: 'uppercase',
                '-webkit-font-smoothing': 'antialiased',
            },
            '& $caseName': {
                fontSize: 20,
                lineHeight: '1.1em',
            },
            '& $caseCreatedBy': {},
            '& $caseDateofDeath': {
                lineHeight: '1.1em',
            },
            '& $viewCaseSection': {
                padding: '4px 8px',
                margin: '16px 0',
                '& $avatarIcon': {
                    height: 32,
                    width: 32,
                    display: 'inline-flex !important',
                    verticalAlign: 'middle',
                },
            },
            '& $helperCardSection': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                '& $lastChild': {
                    marginBottom: '32px !important',
                },
                '& $leftSection': {
                    maxWidth: 300,
                    width: '100%',
                    margin: '32px 12px 0',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: 10,
                    '@media (min-width: 660px)': {
                        minWidth: 300,
                    },
                    '& $cardSection': {
                        position: 'relative',
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: 10,
                        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.2),
                        '& $avatarContainer': {
                            width: 'fit-content',
                            position: 'absolute',
                            top: '-24px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            '& $avatar': {
                                width: 44,
                                height: 44,
                            },
                            '& $nameInitialsAvatar': {
                                height: 44,
                                width: 44,
                                fontSize: 24,
                                fontWeight: 200,
                                textTransform: 'uppercase',
                                '-webkit-font-smoothing': 'antialiased',
                            },
                        },
                        '& $caseRelation': {
                            lineHeight: '1.1em',
                            marginBottom: 12,
                        },
                        '& $caseHelperName': {
                            fontSize: 18,
                            marginTop: 20,
                        },
                        '& $contactButtonSection': {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            maxWidth: 200,
                            margin: '12px auto',
                            justifyContent: 'center',
                            '& $contactButton': {
                                border: `1px solid ${theme.palette.primary.main}`,
                                maxWidth: 38,
                                maxHeight: 38,
                                backgroundColor: theme.palette.common.white,
                                margin: '0px 6px',
                                '&:hover': {
                                    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.08),
                                },
                                '& svg': {
                                    fontSize: 20,
                                },
                            },
                        },
                        '& $contactDetailsSection': {
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '12px 8px',
                            '& $contactLabel': {
                                fontSize: 12,
                                '& svg': {
                                    fontSize: 14,
                                    verticalAlign: 'sub',
                                },
                            },
                            '& $contactText': {
                                marginLeft: 8,
                                lineHeight: '1.2em',
                            },
                        },
                    },
                },
                '& $rightSection': {
                    maxWidth: 300,
                    width: '100%',
                    margin: '0 12px',
                    marginTop: 32,
                    backgroundColor: theme.palette.common.white,
                    borderRadius: 10,
                    '@media (min-width: 660px)': {
                        minWidth: 300,
                    },
                    '& $cardSection': {
                        position: 'relative',
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: 10,
                        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.2),
                        '& $avatarContainer': {
                            width: 'fit-content',
                            position: 'absolute',
                            top: '-24px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            '& $avatar': {
                                width: 44,
                                height: 44,
                            },
                            '& $nameInitialsAvatar': {
                                height: 44,
                                width: 44,
                                fontSize: 24,
                                fontWeight: 200,
                                textTransform: 'uppercase',
                                '-webkit-font-smoothing': 'antialiased',
                            },
                        },
                        '& $caseRelation': {
                            lineHeight: '1.1em',
                            marginBottom: 12,
                        },
                        '& $caseHelperName': {
                            fontSize: 18,
                            marginTop: 20,
                        },
                        '& $contactButtonSection': {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            maxWidth: 200,
                            margin: '12px auto',
                            justifyContent: 'center',
                            '& $contactButton': {
                                border: `1px solid ${theme.palette.primary.main}`,
                                maxWidth: 38,
                                maxHeight: 38,
                                backgroundColor: theme.palette.common.white,
                                margin: '0px 6px',
                                '&:hover': {
                                    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.08),
                                },
                                '& svg': {
                                    fontSize: 20,
                                },
                            },
                        },
                        '& $contactDetailsSection': {
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '12px 8px',
                            '& $contactLabel': {
                                fontSize: 12,
                                '& svg': {
                                    fontSize: 14,
                                    verticalAlign: 'sub',
                                },
                            },
                            '& $contactText': {
                                marginLeft: 8,
                                lineHeight: '1.2em',
                            },
                        },
                    },
                },
            },
        },
        createRolodexEntryButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            textTransform: 'initial',
            padding: '0px 10px',
            minHeight: 30,
            fontWeight: 400,
            marginBottom: 16,
            '& svg': {
                fontSize: 22,
            },
        },
        header: {
            zIndex: 1,
            padding: 0,
            textAlign: 'center',
            minHeight: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        footerContent: {
            margin: '6px 0px',
            display: 'none',
            '@media (min-width: 768px)': {
                display: 'block',
            },
            '& p': {
                fontWeight: 500,
                color: convertHexToRGBA(theme.palette.primary.main, 0.5),
            },
        },
        footerContainer: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
        shortcutButton: {
            marginBottom: 16,
            '@media (min-width: 420px)': {
                marginBottom: 0,
            },
        },
        minHeight0: {
            minHeight: 0,
        },
        docButton: {
            paddingRight: 12,
            '& $docListContainer': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        buttonDisabled: {
            pointerEvents: 'none',
            opacity: 0.5,
        },
        headerDivider: {
            width: 'calc(100% - 16px)',
            backgroundColor: theme.palette.primary.main,
            margin: 'auto',
        },
        headerTextContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        textDecorationNone: {
            textDecoration: 'none',
        },
        docScrollButtons: {
            width: 24,
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        docTabsIndicator: {
            display: 'none',
        },
        docTabLabelContainer: {
            padding: 6,
        },
        docTabRoot: {
            maxWidth: '100%',
            opacity: '1 !important'
        },
        justifyContentCenter: {
            justifyContent: 'center',
        },
        '& p': {
            fontWeight: 500,
            color: convertHexToRGBA(theme.palette.primary.main, 0.50),
        },
        backdrop: {
            '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
                WebkitBackdropFilter: 'saturate(180%) blur(20px)',
                backdropFilter: 'saturate(180%) blur(20px)',
                backgroundColor: 'rgba(255, 255, 255, 0.01)',
            },
        },
        lastChild: {},
        uploadButtonLabel: {},
        avatarIcon: {},
        caseHelperName: {},
        caseRelation: {},
        avatarContainer: {},
        helperCardSection: {},
        viewCaseSection: {},
        caseCreatedBy: {},
        avatar: {},
        nameInitialsAvatar: {},
        caseName: {},
        dummyDivAssignmentIcon: {},
        contactDetailFooter: {},
        contactDetailsSection: {},
        assignmentIcon: {},
        moreVertIcon: {},
        staffProfession: {},
        staffName: {},
        cardSection: {},
        fileMoreIcon: {},
        docUploadedBy: {},
        docName: {},
        docUploadedTime: {},
        docLabelContainer: {},
        docListContainer: {},
        uploadButton: {},
        internalNotesField: {},
        organizationContactDetail: {},
        organizationContactDetailFooter: {},
        orgnizationAddress: {},
        contactLabel: {},
        contactText: {},
        buttonLabel: {},
        contactButton: {},
        contactButtonSection: {},
        rightSection: {},
        leftSection: {},
        optionsButton: {},
        oganizationName: {},
        officeName: {},
        topSection: {},
        buttonText: {},
        searchField: {},
        svgContainer: {},
        inner: {},
        dialogPaper: {},
        caseDateofDeath: {}
    });
}

export default styles;
