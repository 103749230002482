import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '.';
import {
    RolodexCaseResult,
    RolodexEntry,
    RolodexEntryRequest,
    RolodexEntryUpdateRequest,
    RolodexSearchResultType,
    RolodexSearchResultDataTypes,
    RolodexHelperResult,
    RolodexOrgResult,
    RolodexContactResult,
    isSearchResultObj,
    RolodexCaseDetails,
    RolodexSearchResultCategoryFilter,
    OrganizationTypes,
    PaginatedResponse,
    RolodexTableType,
    DocDownloadResponse
} from '../shared/types';
import { AppDispatch } from '../store';
import { StoreState } from '../types';
import { registerAppError } from './errors';
import { log } from '../logger';

export const SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE = 'SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE';
export type SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE = typeof SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE;

interface SetRolodexListingDialogOpen {
    type: SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE;
    isOpen: boolean;
    inputValue?: string;
}

export function setRolodexListDialogOpen(isOpen: boolean = true, inputValue?: string): SetRolodexListingDialogOpen {
    return {
        type: SET_ROLODEX_LISTING_DIALOG_DISPLAY_STATE,
        isOpen,
        inputValue
    };
}

export const SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE = 'SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE';
export type SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE = typeof SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE;

interface SetRolodexCreateEditDialogOpen {
    type: SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE;
    isOpen: boolean;
}

export function setRolodexCreateEditDialogOpen(isOpen: boolean = true): SetRolodexCreateEditDialogOpen {
    return {
        type: SET_ROLODEX_CREATE_DIALOG_DISPLAY_STATE,
        isOpen
    };
}

export const SET_ROLODEX_ACTIVE_ENTRY = 'SET_ROLODEX_ACTIVE_ENTRY';
export type SET_ROLODEX_ACTIVE_ENTRY = typeof SET_ROLODEX_ACTIVE_ENTRY;

interface SetActiveRolodexEntry {
    type: SET_ROLODEX_ACTIVE_ENTRY;
    entry: RolodexEntry | null;
}

export function setActiveRolodexEntry(entry: RolodexEntry | null): SetActiveRolodexEntry {
    return {
        type: SET_ROLODEX_ACTIVE_ENTRY,
        entry
    };
}

export const REMOVE_ROLODEX_ENTRY = 'REMOVE_ROLODEX_ENTRY';

interface RemoveRolodexEntry {
    type: typeof REMOVE_ROLODEX_ENTRY;
    entry: RolodexEntry;
}

function removeRolodexEntry(entry: RolodexEntry): RemoveRolodexEntry {
    return {
        type: REMOVE_ROLODEX_ENTRY,
        entry,
    };
}

export const REMOVE_ROLODEX_CONTACT = 'REMOVE_ROLODEX_CONTACT';

interface RemoveRolodexContact {
    type: typeof REMOVE_ROLODEX_CONTACT;
    contactId: number;
}

export function removeRolodexContact(contactId: number): RemoveRolodexContact {
    return {
        type: REMOVE_ROLODEX_CONTACT,
        contactId,
    };
}

export const LOADED_ROLODEX_SEARCH_RESULTS = 'SET_ROLODEX_SEARCH_RESULTS';
export type LOADED_ROLODEX_SEARCH_RESULTS = typeof LOADED_ROLODEX_SEARCH_RESULTS;

interface SetRolodexSearchResults {
    type: LOADED_ROLODEX_SEARCH_RESULTS;
    searchResults: RolodexSearchResultDataTypes[] | null;
    searchResultCategories: RolodexSearchResultCategoryFilter[];
}

export function loadedRolodexSearchResults(
    searchResults: RolodexSearchResultDataTypes[] | null,
    categories: RolodexSearchResultCategoryFilter[]
): SetRolodexSearchResults {
    return {
        type: LOADED_ROLODEX_SEARCH_RESULTS,
        searchResults,
        searchResultCategories: categories
    };
}

export const ROLODEX_SEARCH_IS_LOADING = 'ROLODEX_SEARCH_IS_LOADING';
export type ROLODEX_SEARCH_IS_LOADING = typeof ROLODEX_SEARCH_IS_LOADING;

interface RolodexSearchIsLoading {
    type: ROLODEX_SEARCH_IS_LOADING;
    isLoading: boolean;
}

export function rolodexSearchIsLoading(isLoading: boolean): RolodexSearchIsLoading {
    return {
        type: ROLODEX_SEARCH_IS_LOADING,
        isLoading
    };
}

export const ROLODEX_REPORT_IS_LOADING = 'ROLODEX_REPORT_IS_LOADING';
export type ROLODEX_REPORT_IS_LOADING = typeof ROLODEX_REPORT_IS_LOADING;

interface RolodexReportIsLoading {
    type: ROLODEX_REPORT_IS_LOADING;
    isLoading: boolean;
}

export function rolodexReportIsLoading(isLoading: boolean): RolodexReportIsLoading {
    return {
        type: ROLODEX_REPORT_IS_LOADING,
        isLoading
    };
}

export const LOADED_ROLODEX_REPORT_DATA = 'UPDATE_ROLODEX_REPORT_DATA';
export type LOADED_ROLODEX_REPORT_DATA = typeof LOADED_ROLODEX_REPORT_DATA;

interface UpdateRolodexReportData {
    type: LOADED_ROLODEX_REPORT_DATA;
    reportData: RolodexTableType[];
    hasMoreData: boolean;
    totalCount: number;
    searchText: string;
    sortBy: keyof RolodexTableType;
    sortDirection: 'desc' | 'asc';
    update: boolean;
}

export function loadedRolodexReportData(
    paginatedTableData: RolodexTableType[],
    hasMoreData: boolean,
    totalCount: number,
    searchText: string,
    sortBy: keyof RolodexTableType,
    sortDirection: 'desc' | 'asc',
    update: boolean
): UpdateRolodexReportData {
    return {
        type: LOADED_ROLODEX_REPORT_DATA,
        reportData: paginatedTableData,
        hasMoreData,
        totalCount,
        searchText,
        sortBy,
        sortDirection,
        update
    };
}

export function createRolodexEntry(rolodexEntry: RolodexEntryRequest) {
    return async (dispatch: AppDispatch): Promise<RolodexEntry | null> => {
        const { funeralHomeId } = rolodexEntry;

        try {
            RolodexEntryRequest.fromRequest(rolodexEntry);
        } catch (error) {
            log.warn('Failed to validate RolodexEntryRequest', { rolodexEntry, error });
            return null;
        }

        const result = await postToAPI<RolodexEntry>(
            `funeralhome/${funeralHomeId}/rolodex`, rolodexEntry, dispatch
        );
        if (!result) {
            dispatch(registerAppError('Unable to create Rolodex Entry.'));
            return null;
        }

        return result;
    };
}

export function updateRolodexEntry(rolodexEntry: RolodexEntryUpdateRequest) {
    return async (
        dispatch: AppDispatch,
        getState: () => StoreState
    ): Promise<RolodexEntry | null> => {
        const { funeral_home_id, organization } = rolodexEntry;

        try {
            RolodexEntryUpdateRequest.fromRequest(rolodexEntry);
        } catch (error) {
            log.warn('Failed to validate RolodexEntryUpdateRequest', { rolodexEntry, error });
            return null;
        }

        const result = await putToAPI<RolodexEntry>(
            `funeralhome/${funeral_home_id}/rolodex/${organization.id}`, rolodexEntry, dispatch
        );

        if (!result) {
            dispatch(registerAppError('Unable to update Rolodex Entry.'));
            return null;
        }

        const { rolodexState } = getState();

        if (rolodexState.activeEntry !== null) {
            dispatch(setActiveRolodexEntry(result));
        }

        return result;
    };
}

export function deleteEntry(entry: RolodexEntry) {
    return async (dispatch: AppDispatch): Promise<RolodexEntry | null> => {
        const result = await deleteFromAPI<RolodexEntry>(
            `funeralhome/${entry.funeralHomeId}/rolodex/${entry.organization.id}`, dispatch
        );
        dispatch(removeRolodexEntry(entry));
        return result;
    };
}

const getUniqueOrgTypeCount = (results: RolodexSearchResultDataTypes[]): RolodexSearchResultCategoryFilter[] => {
    const countMap = new Map<OrganizationTypes, number>();

    for (const res of results) {
        if (isSearchResultObj<RolodexOrgResult>(res, RolodexSearchResultType.org)) {
            // Map<K, V>.has is not a type guard for Map<K, V>.get
            // see: https://github.com/microsoft/TypeScript/issues/18781
            if (res.orgType !== null) {
                if (countMap.has(res.orgType)) {
                    const count = countMap.get(res.orgType);
                    if (count) {
                        countMap.set(res.orgType, count + 1);
                    }
                } else {
                    countMap.set(res.orgType, 1);
                }
            }
        }
    }

    const uniqueOrgTypeCounts: RolodexSearchResultCategoryFilter[] = Array.from(countMap.entries())
        .map(([key, value]) => ({ key, count: value }));

    return uniqueOrgTypeCounts;
};

export function searchRolodex(funeralHomeId: number, searchText: string) {
    return async (dispatch: AppDispatch): Promise<RolodexSearchResultDataTypes[] | null> => {
        const result = await getFromAPI<RolodexSearchResultDataTypes[]>(
            `funeralhome/${funeralHomeId}/rolodex?searchText=${encodeURIComponent(searchText)}`, dispatch
        );

        if (result === null || !result.length) {
            dispatch(loadedRolodexSearchResults([], []));
        } else {
            const searchResultFilters = getUniqueOrgTypeCount(result);
            dispatch(loadedRolodexSearchResults(result, searchResultFilters));
        }
        dispatch(rolodexSearchIsLoading(false));

        return result;
    };
}

export async function getDisplayModel(
    dispatch: AppDispatch,
    item: RolodexSearchResultDataTypes,
    funeralHomeId: number
): Promise<RolodexCaseDetails | RolodexEntry | null> {
    let requestUrl: string = `funeralhome/${funeralHomeId}/rolodex`;

    if (isSearchResultObj<RolodexCaseResult>(item, RolodexSearchResultType.case)) {
        return getFromAPI<RolodexCaseDetails>(`${requestUrl}/case/${item.caseUuid}`, dispatch);
    }

    if (isSearchResultObj<RolodexHelperResult>(item, RolodexSearchResultType.helper)) {
        return getFromAPI<RolodexCaseDetails>(`${requestUrl}/case/${item.caseUuid ?? ''}`, dispatch);
    }

    if (isSearchResultObj<RolodexOrgResult>(item, RolodexSearchResultType.org)) {
        return getFromAPI<RolodexEntry>(`${requestUrl}/${item.id}`, dispatch);
    }

    if (isSearchResultObj<RolodexContactResult>(item, RolodexSearchResultType.contact)) {
        return getFromAPI<RolodexEntry>(`${requestUrl}/${item.orgId ?? ''}`, dispatch);
    }

    return null;
}

export function loadRolodexReportTableData(
    funeralHomeId: number,
    offset: number,
    searchText: string,
    sortBy: keyof RolodexTableType,
    sortDirection: 'desc' | 'asc',
    update: boolean = false
) {
    return async (dispatch: AppDispatch) => {
        let route = `funeralhome/${funeralHomeId}/rolodex/report?` +
            `offset=${offset}` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}` +
            `&search=${encodeURIComponent(searchText)}`;

        dispatch(rolodexReportIsLoading(true));
        const response = await getFromAPI<PaginatedResponse<RolodexTableType>>(route, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to load rolodex entries for funeral home.'));
            return null;
        }
        dispatch(rolodexReportIsLoading(false));

        const totalCount = response.totalcount || response.data.length;

        dispatch(loadedRolodexReportData(
            response.data,
            response.hasMoreData,
            totalCount,
            searchText,
            sortBy,
            sortDirection,
            update
        ));

        return response.data;
    };
}

export function downloadRolodexDoc(docId: number, orgId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<DocDownloadResponse | null> => {
        const downloadData = await getFromAPI<DocDownloadResponse>(
            `funeralhome/${funeralHomeId}/rolodex/${orgId}/doc/${docId}/download`,
            dispatch
        );

        if (!downloadData) {
            return null;
        }

        return downloadData;
    };
}

export function getRolodexEntryByOrgId(orgId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<RolodexEntry | null> => {
        const entry = await getFromAPI<RolodexEntry>(
            `funeralhome/${funeralHomeId}/rolodex/${orgId}`,
            dispatch
        );

        if (!entry) {
            return null;
        }

        return entry;
    };
}

export function getRolodexEntryByContactId(contactId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<RolodexEntry | null> => {
        const entry = await getFromAPI<RolodexEntry>(
            `funeralhome/${funeralHomeId}/rolodex/contact/${contactId}`,
            dispatch
        );

        if (!entry) {
            return null;
        }

        return entry;
    };
}

export type RolodexAction = SetRolodexCreateEditDialogOpen
    | SetRolodexListingDialogOpen
    | SetActiveRolodexEntry
    | SetRolodexSearchResults
    | RolodexReportIsLoading
    | UpdateRolodexReportData
    | RolodexSearchIsLoading
    | RemoveRolodexEntry
    | RemoveRolodexContact;
