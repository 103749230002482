import Grid from "@mui/material/Grid";
import ScrollControls from "../../../../common/ScrollControls";
import { PhotoPreservedText } from "./MiddleSection";

interface Props {
    fhWebsiteUrl: string | null;
    fhName: string;
    disabledFbButton: boolean;
    scrollRef: HTMLElement | null;
}
const MessageAndControls = ({ fhWebsiteUrl, fhName, disabledFbButton, scrollRef }: Props) => {
    return (
        <>
            <Grid
                item
                sx={{
                    zIndex: 2,
                    position: 'absolute',
                    bottom: 28,
                    left: 0,
                    right: 0,
                }}
            >
                <PhotoPreservedText
                    fhName={fhName}
                    fhWebsiteUrl={fhWebsiteUrl}
                    disabledFbButton={disabledFbButton}
                />
            </Grid>

            <ScrollControls
                scrollElement={scrollRef}
                registerEvents
                useApproxValue
            />
        </>
    );
};

export default MessageAndControls; 