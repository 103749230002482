import TextsmsIcon from "@mui/icons-material/Textsms";
import PhoneIcon from "@mui/icons-material/Phone";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { EntitySummary, GatherCaseUX, UserRoles } from "../../../shared/types";
import { WhenCanEditPhoneOrEmail } from "../../common/AppPermissions";
import GPopper, { GPopperProps } from "../../common/GPopper";
import Grid from "@mui/material/Grid";
import React from "react";
import { useGDispatch, useGSelector } from "../../../types";
import { setAppSnackbar } from "../../../actions/AppSnackbar.action";
import CreateIcon from "@mui/icons-material/Create";
import { Theme, styled } from "@mui/material/styles";
import { paperClasses } from "@mui/material";

const ClipboardButton = styled(Button)(({ theme }) => ({
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'uppercase',
    borderRadius: 0,
    maxWidth: '90%',
    minWidth: '90%',
    margin: 'auto',
    '& svg': {
        verticalAlign: 'bottom',
        fontSize: 20
    },
}));

interface EmailPhoneContainerProps {
    isEmailSelected: boolean;
}
const EmailPhoneContainer = styled(Grid, {
    shouldForwardProp: propName => propName !== 'isEmailSelected'
})<EmailPhoneContainerProps>(({ theme, isEmailSelected }) => ({
    display: 'flex',
    padding: 8,
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
    '& button': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: isEmailSelected ? 120 : 90,
        height: 80,
        textTransform: 'capitalize',
        '& p': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        '& svg': {
            fontSize: 36
        }
    },
}));

const Anchor = styled('a')(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.secondary.main
}));

const styles = {
    emailPhonePopper: {
        width: 220,
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-20px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '20px solid #fff',
            bottom: '-20px',
        },
        [`.${paperClasses.root}`]: {
            minWidth: 220,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 4,
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
        }
    },
    copyToClipboard: (theme: Theme) => ({
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: 0
    })
};

interface Props extends GPopperProps {
    activeEntity: EntitySummary;
    selectedCase: GatherCaseUX;
    isEmailSelected: boolean;
    closePopper: () => void;
    onMySettingsDialogOpen: () => void;
}
const EmailPhonePopper = (props: Props) => {
    const {
        zIndex,
        isEmailSelected,
        anchorEle,
        activeEntity,
        selectedCase,
        closePopper,
        onMySettingsDialogOpen
    } = props;

    const dispatch = useGDispatch();

    const isMySettingsDialogOpen = useGSelector(({ dialogState }) => dialogState.mySettings.isOpen);

    const copyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();

        dispatch(setAppSnackbar(
            `${activeEntity.fname}'s ${isEmailSelected ? 'Email' : 'Phone Number'} ` +
            'is copied to your clipboard',
            'success'
        ));

        closePopper();
    };

    /**
     * set the email
     */
    const mailToEmail = activeEntity.email;
    const mailToSubject =
        encodeURIComponent(`Help needed with ${selectedCase.fname} ${selectedCase.lname}'s Profile`);
    /**
     * set the active user's phone Number
     */

    // set the phone number
    let phoneNumber: string | null = activeEntity.phone;

    if ((UserRoles.isFHUser(activeEntity) || selectedCase.assignee_entity_id === activeEntity.entity_id)
        && !activeEntity.phone) {

        phoneNumber = selectedCase.assignee.phone
            ? selectedCase.assignee.phone
            : selectedCase.funeral_home.phone;
    }

    const content = (isEmailSelected ? activeEntity.email : activeEntity.phone) || null;

    return (
        <GPopper
            anchorEle={anchorEle}
            sx={styles.emailPhonePopper}
            zIndex={zIndex + 1}
            placement="top"
            closePopper={e => {
                if (!isMySettingsDialogOpen) {
                    closePopper();
                }
            }}
        >
            <WhenCanEditPhoneOrEmail target={activeEntity}>
                <ClipboardButton onClick={onMySettingsDialogOpen}>
                    <Typography color="primary">
                        <CreateIcon color="primary" />&nbsp;
                        edit {isEmailSelected && 'email address' || 'phone number'}
                    </Typography>
                </ClipboardButton>
            </WhenCanEditPhoneOrEmail>

            <EmailPhoneContainer item xs={12} isEmailSelected={isEmailSelected}>
                {isEmailSelected &&
                    <Anchor
                        href={
                            mailToEmail
                                ? `mailto:${mailToEmail}` +
                                `?subject=${mailToSubject}`
                                : undefined
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button>
                            <Typography color="primary">
                                <EmailIcon color="primary" />
                                Send Email
                            </Typography>
                        </Button>
                    </Anchor>
                }

                {!isEmailSelected &&
                    <>
                        <Anchor
                            href={phoneNumber ? `tel:${phoneNumber}` : undefined}
                        >
                            <Button>
                                <Typography color="primary">
                                    <PhoneIcon color="primary" />
                                    Call
                                </Typography>
                            </Button>
                        </Anchor>

                        <Anchor
                            href={phoneNumber ? `sms://${phoneNumber}` : undefined}
                        >
                            <Button>
                                <Typography color="primary">
                                    <TextsmsIcon color="primary" />
                                    Text
                                </Typography>
                            </Button>
                        </Anchor>
                    </>}
            </EmailPhoneContainer>

            <CopyToClipboard text={String(content)}>
                <ClipboardButton
                    onClick={copyToClipboard}
                    sx={styles.copyToClipboard}
                >
                    <Typography color="primary">
                        <FileCopyIcon color="primary" />&nbsp;
                        COPY TO CLIPBOARD
                    </Typography>
                </ClipboardButton>
            </CopyToClipboard>
        </GPopper>
    );
};

export default EmailPhonePopper;