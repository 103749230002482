import {
    DeathCertificateParentsType,
    EntitySummary,
    getCaseEntityById,
    GatherCaseUX,
    DeathCertificateConfigUX,
    UserProfile,
} from '../../../shared/types';
import { ConfigurableParentFieldKey, isFieldOptional } from './config';

export const parentsValidators = {
    father: function ({ dc_father }: GatherCaseUX, helpers: EntitySummary[]): boolean {
        const father = dc_father &&
            helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_father)));
        return Boolean(father && father.fname && father.lname);
    },
    mother: function ({ dc_mother }: GatherCaseUX, helpers: EntitySummary[]): boolean {
        const mother = dc_mother &&
            helpers.find((helper) => Boolean(getCaseEntityById(helper, dc_mother)));
        return Boolean(mother && mother.fname && mother.lname);
    },
    motherName: function (
        { motherName }: DeathCertificateParentsType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValid = !!motherName.maidenName && motherName.maidenName.length > 0;

        if (isValid || motherName.unknown) {
            return true;
        }

        if (isFieldOptional(ConfigurableParentFieldKey.motherMaidenName, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    fatherBorn: function (
        { fatherBirthPlace: birthLocation }: DeathCertificateParentsType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValidLocation = birthLocation && birthLocation.description && birthLocation.description.length > 0;
        const isPlaceUnknown = birthLocation.unknown === true;

        if (isValidLocation || isPlaceUnknown) {
            return true;
        }

        if (isFieldOptional(ConfigurableParentFieldKey.fatherBirthCity, config, user)) {
            return !touchCheck;
        }

        return false;
    },
    motherBorn: function (
        { motherBirthPlace: birthLocation }: DeathCertificateParentsType,
        config: DeathCertificateConfigUX | null,
        user: UserProfile,
        touchCheck?: boolean
    ): boolean {
        const isValidLocation = birthLocation && birthLocation.description && birthLocation.description.length > 0;
        const isPlaceUnknown = birthLocation.unknown === true;

        if (isValidLocation || isPlaceUnknown) {
            return true;
        }

        if (isFieldOptional(ConfigurableParentFieldKey.fatherBirthCity, config, user)) {
            return !touchCheck;
        }

        return false;
    },
};

export const parentsMasterValidators = {
    valid: function (
        parentsForm: DeathCertificateParentsType,
        config: DeathCertificateConfigUX | null,
        activeCase: GatherCaseUX,
        user: UserProfile,
        helpers: EntitySummary[],
    ) {
        return parentsValidators.father(activeCase, helpers) &&
            parentsValidators.mother(activeCase, helpers) &&
            parentsValidators.motherName(parentsForm, config, user) &&
            parentsValidators.fatherBorn(parentsForm, config, user) &&
            parentsValidators.motherBorn(parentsForm, config, user);
    },
    touched: function (
        parentsForm: DeathCertificateParentsType,
        config: DeathCertificateConfigUX | null,
        activeCase: GatherCaseUX,
        user: UserProfile,
    ) {
        return activeCase.dc_father !== null ||
            activeCase.dc_mother !== null ||
            parentsValidators.motherName(parentsForm, config, user, true) ||
            parentsValidators.fatherBorn(parentsForm, config, user, true) ||
            parentsValidators.motherBorn(parentsForm, config, user, true);
    },
};