import { find, uniq, flatten, some, every, includes, partition, groupBy, has, keyBy } from 'lodash';
import {
    ProductUX,
    ProductContractItemUX,
    ProductPackageUX,
    ProductPackageItemUX,
    ProductCategoryDisplayLookup,
    ProductSubPackageItem,
    ProductSubPackage,
    ProductPackageUXRecord,
    ProductPackageItemOptionUX,
    ProductSubPackageItemOption,
} from '../types';
import { isPackageDiscount, isAllowanceCredit } from './utils';

export const getContractItemsForPackage = (contractItems: ProductContractItemUX[], pkgId: number) => {
    return contractItems.filter((item) => !item.delete_revision && item.package_id === pkgId);
};

export const generateSubPackageFromPackageRecord = (
    pkgRecord: ProductPackageUXRecord,
    productHash: { [id: number]: ProductUX },
): ProductSubPackage | null => {
    return !pkgRecord ? null : {
        ...pkgRecord,
        items: pkgRecord.items.map((item): ProductSubPackageItem => ({
            ...item,
            options: item.options.map((opt) => ({ product: opt.product_id && productHash[opt.product_id] || null }))
                .filter((opt): opt is ProductSubPackageItemOption => opt.product !== null),
        })),
    };
};

export const convertPackageToSubPackage = (pkg: ProductPackageUX): ProductSubPackage => ({
    ...pkg,
    items: pkg.items.map((item) => ({
        ...item,
        options: item.options.map((opt) => ({ product: opt.product }))
            .filter((opt): opt is ProductSubPackageItemOption => opt.product !== null),
    })),
});

// a package is a valid subPackage if it does not have any subPackages
export const isValidSubPackage = (pkg: ProductPackageUX) => every(pkg.items, (item) => !item.options[0].sub_package);

export const getSubPackages = (pkg: ProductPackageUX) => {
    const subPackages: ProductSubPackage[] = [];
    pkg.items.forEach((item) => {
        item.options.forEach((opt) => {
            if (opt.sub_package) {
                subPackages.push(opt.sub_package);
            }
        });
    });
    return subPackages;
};

export const findContractPackageForContractItem = (
    contractItem: ProductContractItemUX,
    contractPackages: ProductPackageUX[],
): ProductPackageUX | null => {
    return contractItem.package_id && find(contractPackages, (pkg) => pkg.id === contractItem.package_id) || null;
};

export const findContractPackageForSubPackageId = (
    packageId: number,
    contractPackages: ProductPackageUX[],
): ProductPackageUX | null => {
    return find(contractPackages, (pkg) => {
        return some(pkg.items, (item) => {
            return some(item.options, (opt) => opt.sub_package && opt.sub_package.id === packageId);
        });
    }) || null;
};

// use contractItems to determine what packages are in the contract
// - ignore package_discounts, allowance credits, and deleted items
export const getPackagesInContract = (contractItems: ProductContractItemUX[], packages: ProductPackageUX[]) => {
    const packageIds = contractItems
        .filter((item) => item.delete_revision === null && !isPackageDiscount(item) && !isAllowanceCredit(item))
        .map((item) => item.package_id)
        .filter((id): id is number => id !== null);
    const uniquePackageIds = uniq(packageIds);
    const contractPackages: ProductPackageUX[] =
        packages.filter((pkg) => includes(uniquePackageIds, pkg.id));
    const contractSubPackages: ProductSubPackage[] = flatten(contractPackages.map(getSubPackages));

    return {
        contractPackages,
        contractSubPackages,
    };
};

export const findMultiOptionPackageItems = (
    packages: (ProductPackageUX | ProductSubPackage)[],
): (ProductPackageItemUX | ProductSubPackageItem)[] => {
    const listOfItemsLists = packages.map((pkg: ProductPackageUX | ProductSubPackage) => pkg.items);
    const allItems = flatten<ProductPackageItemUX | ProductSubPackageItem>(listOfItemsLists);
    return allItems.filter((item) => item.options.length > 1);
};

export const findMultiOptionPackageItem = (
    packages: (ProductPackageUX | ProductSubPackage)[],
    packageItemId: number,
    packageId?: number,
): ProductPackageItemUX | ProductSubPackageItem | undefined => {

    let multiOptionItems: (ProductPackageItemUX | ProductSubPackageItem)[];
    if (packageId) {
        const pkg = find(packages, (p) => p.id === packageId);
        if (!pkg) {
            return undefined;
        }
        multiOptionItems = findMultiOptionPackageItems([pkg]);
    } else {
        multiOptionItems = findMultiOptionPackageItems(packages);
    }
    return find(multiOptionItems, (item: ProductPackageItemUX | ProductSubPackageItem) => item.id === packageItemId);
};

export const findPackageItemsWithProductAsOption = (
    packageItems: (ProductPackageItemUX | ProductSubPackageItem)[],
    productId: number,
): (ProductPackageItemUX | ProductSubPackageItem)[] => {
    return packageItems.filter((item) => {
        return some(item.options, (opt: ProductPackageItemOptionUX | ProductSubPackageItemOption) =>
            opt.product && opt.product.id === productId);
    });
};

export const findPackageItemsWithProductAsMultiOption = (
    contractPackages: (ProductPackageUX | ProductSubPackage)[],
    productId: number,
): (ProductPackageItemUX | ProductSubPackageItem)[] => {
    const packageItemsWithMultipleOptions = findMultiOptionPackageItems(contractPackages);
    const packageItems = findPackageItemsWithProductAsOption(packageItemsWithMultipleOptions, productId);
    return packageItems;
};

export const shouldRemovingContractItemBreakPackage = (itemRemoved: ProductContractItemUX): boolean => {
    return itemRemoved.package_id !== null && itemRemoved.product !== null && !itemRemoved.package_item_id;
};

export const getProductOptionsForPackageItem = (packageItem: ProductPackageItemUX | ProductSubPackageItem) => {
    const productUX = packageItem.options.map<ProductUX | null>(
        (opt: ProductPackageItemOptionUX | ProductSubPackageItemOption) => opt.product
    );
    return productUX.filter((p): p is ProductUX => p !== null);
};

// find all contractItems that are also packageItemOptions
export const findContractItemsThatArePackageItemOptions = (
    packageItem: ProductPackageItemUX | ProductSubPackageItem,
    contractItems: ProductContractItemUX[],
) => {
    const productOptions = getProductOptionsForPackageItem(packageItem);
    const productsById = keyBy(productOptions, (p) => p.id);
    const allOptionItems = contractItems.filter((contractItem) =>
        !contractItem.delete_revision &&
        contractItem.product &&
        productsById[contractItem.product.id] &&
        (!contractItem.package_item_id || contractItem.package_item_id === packageItem.id)
    );
    const [selectedOptions, unselectedOptions] = partition(allOptionItems, (contractItem) =>
        contractItem.package_item_id === packageItem.id);
    return {
        selectedOptions,
        unselectedOptions,
    };
};

export const getPackageItemName = (item: ProductPackageItemUX): string => {
    const { product, sub_package } = item.options[0];

    if (product) {
        if (item.options.length > 1) {
            const itemName = item.name || ProductCategoryDisplayLookup[product.category];
            return `${item.max_selections} of ${item.options.length} select ${itemName}s`;
        } else {
            return product.name;
        }
    } else if (sub_package) {
        return sub_package.name;
    } else {
        return '';
    }
};

export const findValidPackageItemsToAddProduct = (
    productId: number,
    packageItems: (ProductPackageItemUX | ProductSubPackageItem)[],
    contractItems: ProductContractItemUX[],
): (ProductPackageItemUX | ProductSubPackageItem)[] => {
    return packageItems.filter((packageItem) => {
        // ensure productId is an option for packageItem
        const productOptions = getProductOptionsForPackageItem(packageItem);
        if (every(productOptions, (opt) => opt.id !== productId)) {
            return false;
        }
        // ensure there are available selections to be made and product isn't already selected
        const { selectedOptions } = findContractItemsThatArePackageItemOptions(packageItem, contractItems);
        return selectedOptions.length < packageItem.max_selections &&
            !some(selectedOptions, (selected) => selected.product && selected.product.id === productId);
    });
};

export const getAvailablePackages = (allPackages: ProductPackageUX[]) => {
    return allPackages.filter((pkg) => !pkg.deleted_time);
};

export const getVisiblePackagesForContract = (
    allPackages: ProductPackageUX[],
    contractPackageIds: { [id: number]: number },
) => {
    return allPackages.filter((pkg) => !pkg.deleted_time || has(contractPackageIds, pkg.id));
};

export const getRelatedPackages = (targetPackage: ProductPackageUX, allPackages: ProductPackageUX[]) => {

    const subPackages = getSubPackages(targetPackage);
    const allPackagesByOriginalId = groupBy(allPackages, (pkg) => pkg.original_package || pkg.id);

    const packageRevisionsLookup: { [id: number]: ProductPackageUX[] } = {};
    subPackages.forEach((subpkg) => {
        const packageRevisions = allPackagesByOriginalId[subpkg.id];
        if (packageRevisions) {
            packageRevisionsLookup[subpkg.id] = packageRevisions;
        }
    });
    return packageRevisionsLookup;
};
