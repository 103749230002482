import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Icon from '@mui/material/Icon';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { PaymentMethod, Transaction } from '../../../../../shared/types';
import { PaymentMethodIcon } from '..';
import { renderPaymentMethodLabel } from './Details';
import ContractItem from './ContractItem';
import { GLOBAL_STYLED_PROPS } from '../../../../../styles';
import withGStyles from '../../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, ItemGridProps> = theme => ({
    root: {},
    item: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        '& $label': {
            textTransform: 'capitalize',
            fontSize: 14,
            lineHeight: '1.25em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                '& span': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            },
            '& svg': {
                fontSize: 16,
                marginRight: 4,
                cursor: 'pointer',
                width: 16,
            },
        },
        '& $iconButton': {
            marginLeft: 4,
            height: 24,
            width: 24,
            '& svg': {
                fontSize: 16,
                margin: '0 !important'
            },
        },
        '&$disabled': {
            '& $label': {
                fontStyle: 'italic',
                opacity: 0.6,
                pointerEvents: 'none'
            },
            '& $iconButton': {
                opacity: 0,
                pointerEvents: 'none'
            }
        },
        '&$isSubItem': {
            '& $label': {
                marginLeft: 32,
                pointerEvents: 'none',
                marginTop: -2,
                lineHeight: '1em'
            },
            '& $iconButton': {
                opacity: 0,
                pointerEvents: 'none',
                height: 0
            }
        }
    },
    breakWord: {
        wordBreak: 'break-word'
    },
    isRestricted: {
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none'
    },
    isProposedInsuranceItem: {
        '& $iconButton': {
            opacity: '1 !important',
            pointerEvents: 'auto !important',
            height: 24,
        },
        '& $label': {
            '& span': {
                '&:hover': {
                    textDecoration: 'none'
                }
            }
        }
    },
    styleSVG: {
        '& svg': {
            verticalAlign: 'baseline'
        }
    },
    isSubItem: {},
    disabled: {},
    label: {},
    iconButton: {},
});

type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'item' | 'disabled' | 'isSubItem'
    | 'isProposedInsuranceItem' | 'label' | 'breakWord' | 'styleSVG' | 'iconButton' | 'isRestricted'>;

interface ItemGridProps {
    loggedInUserId: number | null;
    isFamilyUser: boolean | null;
    transaction: Transaction;
    openDetailMenu: (anchorEle: HTMLElement, transaction: Transaction) => void;
    showReceipt: (paymentId: number) => void;
    editInvoice: (invoice: Transaction) => void;
    caseFirstName: string;
    isContractFrozen: boolean;
    isPrintMode: boolean;
    canModifyPrices?: boolean;
    navigateToContract: () => void;
    outsideCharges?: boolean;
    isShowPayments?: boolean;
    fullWidth?: boolean;
}

const Item = (props: ItemGridProps & StyledProps) => {
    const {
        classes,
        transaction,
        openDetailMenu,
        showReceipt,
        editInvoice,
        caseFirstName,
        isContractFrozen,
        isFamilyUser,
        loggedInUserId,
        isPrintMode,
        outsideCharges,
        isShowPayments,
        fullWidth,
        canModifyPrices
    } = props;
    const isProposedInsuranceItem = transaction.is_proposed
        && transaction.type === 'PAYMENT'
        && transaction.method === PaymentMethod.insurance;
    const disabled = transaction.is_proposed;
    const isSubItem = transaction.type === 'INVOICE' && transaction.is_sub_item;
    const icon = transaction.type === 'INVOICE' && transaction.method
        ? null
        : <PaymentMethodIcon
            paymentMethod={transaction.method}
            classFillPrimary={classes.fillPrimary} />;
    const tooltip = transaction.type === 'INVOICE' && transaction.method === null ? ''
        : transaction.description || '';
    const amount = Transaction.dollarFormat(transaction);
    const paymentMemo = (transaction.description === 'Processing Fee')
        ? 'Convenience Fee' : transaction.description || '';
    const label = renderPaymentMethodLabel(
        transaction.method,
        transaction.type,
        transaction.payer_name,
        paymentMemo,
        transaction.external_id,
        amount,
        isFamilyUser
    );
    const isRestrictedUser = loggedInUserId !== null && loggedInUserId !== transaction.payer_user_id && isFamilyUser;

    const handleClick = () => {
        if (isRestrictedUser) {
            return;
        }

        if (transaction.type === 'PAYMENT' && transaction.payment_id) {
            showReceipt(transaction.payment_id);
            return;
        }

        if (!isFamilyUser
            && transaction.type === 'INVOICE'
            && transaction.method === null
            && canModifyPrices
        ) {
            editInvoice(transaction);
        }
    };

    if (transaction.contract_id !== null && isShowPayments !== true) {
        return (<ContractItem
            caseFirstName={caseFirstName}
            isContractFrozen={isContractFrozen}
            amount={amount}
            label={paymentMemo}
            openDetailMenu={target => openDetailMenu(target, transaction)}
            isPrintMode={isPrintMode}
        />);
    }

    return (
        <Grid
            item
            xs={12}
            className={classNames(
                classes.item,
                disabled && classes.disabled,
                isSubItem && classes.isSubItem,
                (isRestrictedUser || isShowPayments || !canModifyPrices) && classes.pointerEventsNone,
                isProposedInsuranceItem && classes.isProposedInsuranceItem
            )}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                margin: 'auto',
                maxWidth: fullWidth ? undefined : 420
            }}
        >
            <Typography
                color={isShowPayments ? 'default' : 'secondary'}
                className={classNames(
                    classes.label,
                    classes.breakWord,
                )}
                onClick={handleClick}
                style={{
                    textTransform: 'capitalize',
                    fontSize: isPrintMode ? 12 : 14,
                    display: 'table',
                    textAlign: 'left',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: isShowPayments ? 400 : isPrintMode ? 200 : 400,
                    margin: isPrintMode ? 0 : undefined,
                    fontStyle: isShowPayments ? 'normal' : undefined,
                    fontFamily: 'system-ui, sans-serif',
                    lineHeight: (isShowPayments && !isPrintMode) ? '18px' : isPrintMode ? '12px' : '14px',
                }}
            >
                {outsideCharges ?
                    paymentMemo
                    :
                    <>
                        <Icon
                            className={classes.styleSVG}
                            style={{
                                display: 'table-cell',
                                verticalAlign: 'middle',
                                padding: 0,
                                margin: 0,
                                height: 16,
                                fontSize: 16,
                                width: 16,
                            }}>
                            {icon}
                        </Icon>
                        <Tooltip
                            placement="top"
                            title={tooltip}
                            enterDelay={500}
                            leaveDelay={200}
                        >
                            <span
                                style={{
                                    display: 'table-cell',
                                    verticalAlign: 'middle',
                                    paddingRight: 8,
                                }}>
                                {label}
                            </span>
                        </Tooltip>
                    </>
                }
            </Typography>

            <Typography
                color={isShowPayments ? 'default' : 'secondary'}
                className={classes.label}
                style={{
                    fontSize: isPrintMode ? 12 : 14,
                    fontWeight: isShowPayments ? 400 : isPrintMode ? 200 : 400,
                    margin: isPrintMode ? 0 : undefined,
                    lineHeight: isPrintMode ? '12px' : '14px',
                    fontStyle: isShowPayments ? 'normal' : undefined,
                    fontFamily: 'system-ui, sans-serif',
                }}
            >
                <span
                    className="notranslate"
                    onClick={handleClick}
                >
                    {amount}
                </span>
                {
                    (!isShowPayments && !isPrintMode) && <IconButton
                        className={classNames(
                            classes.iconButton,
                            'icon-button',
                            isFamilyUser && classes.isRestricted
                        )}
                        sx={{ pointerEvents: 'all' }}
                        id="detail-more-button"
                        aria-label="More"
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation();

                            openDetailMenu(
                                e.currentTarget,
                                transaction
                            );
                        }}
                        size="large">
                        <MoreVertIcon />
                    </IconButton>}
            </Typography>
        </Grid >
    );
};

export default withGStyles(styles)(Item);
