import classNames from 'classnames';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {
    DocPacketUX,
    GatherCaseUX,
} from '../../../shared/types';
import makeGStyles from '../../../styles/makeGStyles';
import { AppRoute, joinNameParts } from '../../../services';
import GLink from '../../common/GLink';

const useStyles = makeGStyles((theme) => ({
    inner: {
        padding: '8px 8px',
        borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    docsList: {
        width: '100%',
        paddingBottom: 8,
        color: 'inherit',
        textDecoration: 'none',
        display: 'block',
        '& $label': {
            fontSize: 16,
        },
        '& $date': {
            fontSize: 12,
            fontWeight: 300
        }
    },
    docName: {
        fontSize: 14,
        margin: '0 6px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        lineHeight: '1.7em',
        '& svg': {
            fontSize: 16,
            marginRight: 4
        }
    },
    downloadButton: {
        maxWidth: 420,
        margin: 'auto',
        display: 'flex',
        marginTop: 16,
        fontSize: 13,
        '@media (min-width: 450px)': {
            fontSize: 14,
        }
    },
    label: {},
    date: {},
}), { name: 'DocumentList' });

interface Props {
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
    packetLink?: AppRoute;
    docNameClass?: string;
    onDownloadUnsignedPacket: (docPacket: DocPacketUX) => void;
}

export const DocumentList = (props: Props) => {
    const { docPacket, docNameClass, activeCase, packetLink, onDownloadUnsignedPacket } = props;

    const classes = useStyles();

    const dateTimeStr = moment(docPacket.created_time).format('M/D/YYYY @ h:mma');
    const author = joinNameParts({
        fname: docPacket.created_by_fname,
        lname: docPacket.created_by_lname
    });
    const packetType = docPacket.type === 'fill_print' ? 'Print & Sign Packet' : 'eSign Packet';

    const renderDocSection = (
        <>
            <Grid item xs={12} className={classes.inner}>
                <Typography color="secondary" className={classes.label} align="center">
                    {packetType}
                </Typography>
                <Typography color="secondary" className={classes.date} align="center">
                    Created {dateTimeStr} by {author}
                </Typography>
            </Grid>

            {docPacket.contracts.map((packetContract) =>
                <Typography
                    key={packetContract.contract_id}
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.docName,
                        docNameClass,
                    )}
                >
                    <AttachMoneyIcon color="primary" />
                    {activeCase.fname}'s Statement
                </Typography>
            )}

            {docPacket.docs.map((packetDoc) =>
                <Typography
                    key={packetDoc.doc.id}
                    color="secondary"
                    align="left"
                    className={classNames(
                        classes.docName,
                        docNameClass,
                    )}
                >
                    <InsertDriveFileIcon color="primary" />
                    {packetDoc.doc.label || packetDoc.doc.name}
                </Typography>
            )}
        </>
    );

    return (
        <>
            {packetLink
                ? <GLink to={packetLink} linkClass={classes.docsList}>
                    {renderDocSection}
                </GLink>
                : renderDocSection
            }

            {docPacket.type === 'fill_print' && docPacket.status !== 'started' &&
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.downloadButton}
                    aria-label="download-doc"
                    onClick={() => onDownloadUnsignedPacket(docPacket)}
                >
                    <CloudDownloadIcon />
                    &nbsp;Download Unsigned Documents
                </Button>
            }
        </>
    );
};
