import * as React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

import { GatherCaseUX, ProductContractItemUX } from '../../../../shared/types';
import { getFullNameFromCase } from '../../../../services';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import { SlideTransition } from '../../../common/Transitions';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            margin: 8,
            width: 360,
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
        },
    },
    clearIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        background: theme.palette.primary.main,
        zIndex: 1,
        padding: 14,
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        margin: ' auto',
        textAlign: 'center',
        overflow: 'hidden',
        padding: '24px 0 !important',
        width: 'calc(100% - 48px)'
    },
    headerText: {
        fontWeight: 300,
        fontSize: 22,
        color: theme.palette.common.white,
    },
    textField: {
        margin: '24px 0'
    },
    textAreaMinHeight: {
        minHeight: 80
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            margin: '8px 0'
        }
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    dialogPaper: {},
});

interface DialogProps {
    fullScreen: boolean;
}

interface Props {
    activeCase: GatherCaseUX;
    isDialogOpen: boolean;
    contractItem?: ProductContractItemUX;
    closeDialog: () => void;
    handleAddNote: (note: string | null) => void;
    zIndex: number;
}

interface State {
    note: string | null;
    saveAttempted: boolean;
}

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'header' | 'dialogContent' | 'textField'
    | 'clearIcon' | 'headerText' | 'textAreaMinHeight' | 'footer' | 'spaceBetween'>;

class NotesDialog extends React.Component<StyledProps & DialogProps, State> {

    constructor(props: StyledProps & DialogProps) {
        super(props);
        this.state = {
            note: this.props.contractItem ? this.props.contractItem.note : null,
            saveAttempted: false,
        };
    }

    render() {
        const {
            classes,
            isDialogOpen,
            activeCase,
            contractItem,
            zIndex
        } = this.props;

        const { note, saveAttempted } = this.state;

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.root,
                }}
                style={{ zIndex }}
                maxWidth="xs"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classes.header}
                >
                    <ClearIcon
                        className={classes.clearIcon}
                        onClick={() => this.closeDialog()}
                    />
                    <Typography
                        component="p"
                        noWrap
                        className={classes.headerText}
                    >
                        Product Note
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                    >
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize18}
                            >
                                Enter a note for&nbsp;
                                <span className={classes.fontWeight500}>{contractItem && contractItem.name}</span>
                                &nbsp;on {getFullNameFromCase(activeCase)}'s Statement
                            </Typography>
                            <TextField
                                name="note"
                                className={classes.textField}
                                fullWidth
                                value={note || ''}
                                multiline
                                label="Type your note here..."
                                // helperText="Last edited by Zach 2 days ago"
                                InputProps={{
                                    classes: {
                                        root: classes.textAreaMinHeight,
                                        inputMultiline: classes.textAreaMinHeight,
                                    }
                                }}
                                required
                                error={saveAttempted && !note}
                                onChange={this.handleNoteChange}
                            />

                            <div
                                className={classNames(
                                    classes.footer,
                                    contractItem && contractItem.note && classes.spaceBetween
                                )}
                            >
                                {contractItem && contractItem.note &&
                                    <Button
                                        color="primary"
                                        className={classes.margin_8}
                                        onClick={e => this.deleteNote()}
                                        disabled={!contractItem || !contractItem.note}
                                    >
                                        delete note
                                    </Button>
                                }

                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.margin_8}
                                    onClick={e => this.handleNoteSave()}
                                >
                                    Save note
                                </Button>
                            </div>

                            <Typography
                                align="center"
                                color="secondary"
                                className={classes.fontSize12}
                            >
                                This note WILL BE visible on {activeCase.fname}'s Statement
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    handleNoteSave = async () => {
        const { note } = this.state;
        const { handleAddNote } = this.props;
        if (!note) {
            this.setState({
                saveAttempted: true,
            });
            return;
        }
        handleAddNote(note);
        this.closeDialog();
    };

    deleteNote = async () => {
        const { handleAddNote } = this.props;

        handleAddNote(null);
        this.closeDialog();
    };

    handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        this.setState({ note: target.value });
    };

    closeDialog = () => {
        this.props.closeDialog();
        this.setState({
            note: null,
            saveAttempted: false,
        });
    };

}

export default compose(withGStyles(styles), withFullScreen('xs'))(NotesDialog) as React.ComponentType<Props>;
