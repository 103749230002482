import { Theme } from "@mui/material/styles";
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import { convertHexToRGBA } from "../../../../services";
import {
    EMAIL_COLOR,
    FACEBOOK_COLOR,
    LINKEDIN_COLOR,
    LINK_COLOR,
    MESSANGER_COLOR,
    QRCODE_COLOR,
    SHARE_COLOR,
    SMS_COLOR,
    TWITTER_COLOR,
    WHATSAPP_COLOR
} from "../utils";

export const optionStyles =
    <OptionStylesProps extends object>(): StyleRulesCallback<Theme, OptionStylesProps, OptionClasses> =>
        theme => ({
            button: {
                border: `1px solid`,
                margin: '4px 16px',
                width: 40,
                height: 40,
                minWidth: 'fit-content',
                cursor: 'pointer',
                '@media (min-width: 440px)': {
                    margin: '8px 16px',
                    width: 48,
                    height: 48
                },
                '@media (min-width: 960px)': {
                    margin: '0 16px',
                    width: 60,
                    height: 60
                },
                '& svg': {
                    width: 22,
                    height: 22,
                    '@media (min-width: 440px)': {
                        width: 24,
                        height: 24,
                    },
                    '@media (min-width: 960px)': {
                        width: 32,
                        height: 32,
                    }
                },
                '&$MESSENGER': {
                    '& svg': {
                        width: 20,
                        height: 20,
                        fill: MESSANGER_COLOR,
                        '@media (min-width: 440px)': {
                            width: 22,
                            height: 22,
                        },
                        '@media (min-width: 960px)': {
                            width: 28,
                            height: 28,
                        }
                    },
                    '&$xs': {
                        '& svg': {
                            width: `20px !important`,
                            height: `20px !important`,
                        }
                    }
                },
                '&$QRCODE': {
                    border: 'none',
                    '& svg': {
                        width: 24,
                        height: 24,
                        '@media (min-width: 440px)': {
                            width: 30,
                            height: 30,
                        },
                        '@media (min-width: 960px)': {
                            width: 46,
                            height: 46,
                        }
                    },
                    '&"hover svg': {
                        color: 'black',
                    }
                },
                '&$xs': {
                    margin: '0 4px !important',
                    width: `40px !important`,
                    height: `40px !important`,
                    '& svg': {
                        width: `22px !important`,
                        height: `22px !important`,
                    }
                }
            },
            FACEBOOK: {
                color: FACEBOOK_COLOR,
                border: `1px solid`,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(FACEBOOK_COLOR, 0.08)
                },
            },
            MESSENGER: {
                color: MESSANGER_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(MESSANGER_COLOR, 0.08)
                },
            },
            EMAIL: {
                color: EMAIL_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(EMAIL_COLOR, 0.08)
                },
            },
            WHATSAPP: {
                color: WHATSAPP_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(WHATSAPP_COLOR, 0.08)
                },
            },
            TWITTER: {
                color: TWITTER_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(TWITTER_COLOR, 0.08)
                },
            },
            LINKEDIN: {
                color: LINKEDIN_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(LINKEDIN_COLOR, 0.08)
                },
            },
            LINK: {
                color: LINK_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(LINK_COLOR, 0.08)
                },
            },
            SHARE: {
                color: SHARE_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(SHARE_COLOR, 0.08)
                },
            },
            QRCODE: {
                color: QRCODE_COLOR,
            },
            SMS: {
                fill: SMS_COLOR,
                color: SMS_COLOR,
                '&:hover': {
                    backgroundColor: convertHexToRGBA(`#9be473`, 0.08)
                },
            },
            xs: {}
        });

export type OptionClasses = 'button' | 'FACEBOOK' | 'MESSENGER' | 'TWITTER' | 'WHATSAPP' |
    'LINKEDIN' | 'EMAIL' | 'SMS' | 'SHARE' | 'LINK' | 'xs';
export type OptionStyledProps = WithStyles<OptionClasses>;
