import { ActiveCaseStatus, useGSelector } from '../../types';
import AppLoader from '../common/AppLoader';
import { extractCaseDataFromIndex } from '../../services/indexfile.service';
import { getCaseLoadingPage } from '../../shared/html/case/caseLoadingPage';

const CaseLoadingPage = () => {
    const caseData = extractCaseDataFromIndex();

    const activeCaseName = useGSelector(({ activeCaseState }) => activeCaseState.caseName);
    const isLoading = useGSelector(({ activeCaseState }) => activeCaseState.status === ActiveCaseStatus.loading);

    if (isLoading && activeCaseName !== caseData.caseName) {
        return <AppLoader />;
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: getCaseLoadingPage(caseData)
            }}
        />
    );
};

export default CaseLoadingPage;
