import { Component } from 'react';
import classNames from 'classnames';
import Dinero from 'dinero.js';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { PaymentRequest, FeeSchedule, PaymentMethod } from '../../../../shared/types';
import { PatchPaymentParameters } from '../../../../actions/Finance.action';
import { calculateProcessingFeesInCents, PaymentFieldToolTip } from '.';
import { formatDinero } from '../../../../shared/goods_and_services/pricing';
import { SlideTransition } from '../../../common/Transitions';
import { GStyles } from '../../../../styles/GStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 34,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    header: {
        zIndex: 1,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 1px 10px 1px rgba(0, 0, 0, 0.2)',
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        margin: 0,
        textAlign: 'center',
        overflow: 'hidden',
        overflowY: 'auto',
        position: 'relative',
        '& $inner': {
            margin: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }
    },
    dialogPaper: {
        margin: 'auto',
        width: '100%',
        maxHeight: '100vh',
        height: '100vh',
        borderRadius: 0,
        display: 'block',
        '@media (min-width: 600px)': {
            borderRadius: 4,
            width: 360,
            height: 'auto',
            maxHeight: 'calc(100% - 96px)',
            display: 'flex'
        },
    },
    textField: {
        width: 200,
        margin: '8px 0',
        '&$top': {
            margin: 8,
            width: 130,
            '& label': {
                width: 'max-content',
                fontSize: 14
            }
        },
        '& input': {
            padding: '12px 0',
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    },
    headerContent: {
        textAlign: 'center',
        margin: '8px 0',
        color: theme.palette.common.white,
        '& p': {
            color: 'inherit',
            fontSize: 24,
        },
        '& svg': {
            color: 'inherit',
            fontSize: 88,
        }
    },
    footer: {
        margin: '0 12px',
        fontSize: 12,
        fontWeight: 200,
        '& a': {
            color: 'inherit'
        }
    },
    textArea: {
        width: 280,
        '& $textAreaRoot': {
            minHeight: 80
        }
    },
    total: {
        width: 120,
        borderTop: '1px solid',
        margin: '8px 0',
        padding: '8px 20px',
        textAlign: 'center',
        fontSize: 18,
    },
    buttonContainer: {
        margin: '24px 0 8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        '& button': {
            margin: 8,
        }
    },
    textAreaRoot: {},
    inner: {},
    top: {}
});

type StyledProps = Props & WithStyles<'root' | 'dialogPaper'
    | 'dRoot' | 'header' | 'dialogContent' | 'clearIcon' | 'textField' | 'inner' | 'footer'
    | 'headerContent' | 'AttachMoneyIcon' | 'textArea' | 'textAreaRoot' | 'total' | 'top'
    | 'buttonContainer'>;

interface Props {
    isEditMode: boolean;
    isDialogOpen: boolean;
    caseUuid: string;
    closeDialog: () => void;
    updatePayment: (paymentInfo: PaymentRequest) => void;
    paymentInfo: PaymentRequest;
    patchPayment: (...params: PatchPaymentParameters) => void;
    feeSchedule: FeeSchedule | null;
    removePolicy: (payerId: number, payerName: string) => void;
    zIndex: number;
}

interface State {
    isLoading: boolean;
    isCompanyNameValid: boolean;
    isPolicyNumberValid: boolean;
    isAmountValid: boolean;
    isFeeValid: boolean;
}

function getInitialState(paymentInfo: PaymentRequest | null, feeSchedule: FeeSchedule | null): State {
    return {
        isLoading: false,
        isCompanyNameValid: true,
        isPolicyNumberValid: true,
        isAmountValid: true,
        isFeeValid: true
    };
}

class RecordInsurancePolicyDialog extends Component<StyledProps, State> {

    constructor(props: StyledProps) {
        super(props);
        this.state = getInitialState(props.paymentInfo, props.feeSchedule);
    }

    updatePayerName(payerName: string) {
        const updatedPaymentInfo = {
            ...this.props.paymentInfo,
            payer_name: payerName
        };
        this.props.updatePayment(updatedPaymentInfo);
        this.validateCompanyName(updatedPaymentInfo);
    }

    updateExternalId(externalId: string) {
        const updatedPaymentInfo = {
            ...this.props.paymentInfo,
            external_id: externalId
        };
        this.props.updatePayment(updatedPaymentInfo);
        this.validatePolicyNumber(updatedPaymentInfo);
    }

    updateAmount(value: string) {
        const amount = value
            && !Number.isNaN(Number(value))
            && Number(value) || 0;

        const updatedPaymentInfo = {
            ...this.props.paymentInfo,
            amount: Dinero({ amount: Math.round(amount * 100), currency: 'USD' }),
            merch_fee: Dinero({
                amount: amount > 0
                    ? calculateProcessingFeesInCents(amount * 100, this.props.feeSchedule, PaymentMethod.insurance)
                    : 0.00,
                currency: 'USD'
            })
        };

        this.props.updatePayment(updatedPaymentInfo);
        this.validateAmount(updatedPaymentInfo);
    }

    updateMerchantFee(value: string) {
        const merchFee = value
            && !Number.isNaN(Number(value))
            && Number(value) || 0;

        const updatedPaymentInfo = {
            ...this.props.paymentInfo,
            merch_fee: Dinero({ amount: Math.round(merchFee * 100), currency: 'USD' })
        };

        this.props.updatePayment(updatedPaymentInfo);
        this.validateFee(updatedPaymentInfo);
    }

    updateMemo(memo: string) {
        this.props.updatePayment({
            ...this.props.paymentInfo,
            memo
        });
    }

    render() {
        const {
            classes,
            isDialogOpen,
            paymentInfo,
            isEditMode,
            removePolicy,
            zIndex
        } = this.props;

        const {
            isCompanyNameValid,
            isPolicyNumberValid,
            isAmountValid,
            isFeeValid
        } = this.state;

        const { amount, memo, merch_fee, payer_name, external_id, payer_id } = paymentInfo;
        const totalAmount = Dinero({ amount: amount.add(merch_fee).getAmount(), currency: 'USD' });

        return (
            <Dialog
                open={isDialogOpen}
                onClose={() => this.closeDialog()}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper,
                    root: classes.dRoot,
                }}
                style={{ zIndex }}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={classNames(
                        classes.header,
                        GStyles.backgroundPrimary
                    )}
                >
                    <ClearIcon
                        className={classNames(classes.clearIcon, GStyles.colorWhite)}
                        onClick={() => this.closeDialog()}
                    />
                    <div className={classes.headerContent}>
                        <InsertDriveFileIcon />
                        <Typography>{isEditMode ? 'Adjust' : 'Record'} Insurance Policy</Typography>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            error={!isCompanyNameValid}
                            label="Company Name"
                            className={classNames(
                                classes.textField,
                                classes.top
                            )}
                            value={payer_name || ''}
                            onChange={(e) => this.updatePayerName(e.target.value)}
                            autoFocus
                        />
                        <TextField
                            error={!isPolicyNumberValid}
                            required
                            label="Policy Number"
                            className={classNames(
                                classes.textField,
                                classes.top
                            )}
                            value={external_id || ''}
                            onChange={(e) => this.updateExternalId(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.inner}>
                            <TextField
                                error={!isAmountValid}
                                required
                                label="Amount"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    classes: { notchedOutline: 'notranslate' }
                                }}
                                className={classes.textField}
                                value={amount && amount.getAmount() / 100 || ''}
                                onChange={(e) => this.updateAmount(e.target.value)}
                            />
                        <Tooltip
                            enterDelay={400}
                            placement="top"
                            title={PaymentFieldToolTip.paymentFee}
                        >
                            <TextField
                                error={!isFeeValid}
                                label="Convenience Fee"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    classes: { notchedOutline: 'notranslate' }
                                }}
                                className={classes.textField}
                                value={merch_fee && merch_fee.getAmount() / 100 || ''}
                                onChange={(e) => this.updateMerchantFee(e.target.value)}
                            />
                        </Tooltip>
                        <Typography className={classes.total} color="primary">
                            {formatDinero(totalAmount)}
                        </Typography>
                        <TextField
                            label="Memo (optional)"
                            multiline
                            margin="dense"
                            variant="outlined"
                            value={memo || ''}
                            className={classes.textArea}
                            InputProps={{
                                classes: {
                                    root: classes.textAreaRoot,
                                    notchedOutline: 'notranslate'
                                }
                            }}
                            onChange={(e) => this.updateMemo(e.target.value)}
                        />

                        <Grid
                            item
                            xs={12}
                            className={classNames(
                                classes.buttonContainer,
                                isEditMode && GStyles.spaceBetween
                            )}
                        >
                            {isEditMode && <Button
                                color="secondary"
                                onClick={(e) => {
                                    removePolicy(payer_id || -1, payer_name || '');
                                    this.closeDialog();
                                }}
                            >
                                remove policy
                            </Button>}

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={e => this.recordPolicy()}
                            >
                                <AttachMoneyIcon />&nbsp;{isEditMode ? 'update' : 'record'}&nbsp;
                                {amount.getAmount() !== 0 && <span>{formatDinero(amount)}&nbsp;</span>}
                                <span>policy</span>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    validateAmount(paymentInfo: PaymentRequest) {
        const { amount } = paymentInfo;
        const isAmountValid = amount && !amount.isZero() && amount.isPositive();
        this.setState({ isAmountValid });
        return isAmountValid;
    }

    validateFee(paymentInfo: PaymentRequest) {
        const { merch_fee } = paymentInfo;
        const isFeeValid = merch_fee && merch_fee.isPositive();
        this.setState({ isFeeValid });
        return isFeeValid;
    }

    validateCompanyName(paymentInfo: PaymentRequest) {
        const { payer_name } = paymentInfo;
        const isCompanyNameValid = payer_name && payer_name.trim() !== '' || false;
        this.setState({ isCompanyNameValid });
        return isCompanyNameValid;
    }

    validatePolicyNumber(paymentInfo: PaymentRequest) {
        const { external_id } = paymentInfo;
        const isPolicyNumberValid = external_id && external_id.trim() !== '' || false;
        this.setState({ isPolicyNumberValid });
        return isPolicyNumberValid;
    }

    recordPolicy = async () => {
        const { paymentInfo, caseUuid } = this.props;
        const isCompanyNameValid = this.validateCompanyName(paymentInfo);
        const isPolicyNumberValid = this.validatePolicyNumber(paymentInfo);
        const isAmountValid = this.validateAmount(paymentInfo);
        const isFeeValid = this.validateFee(paymentInfo);

        if (!isCompanyNameValid || !isPolicyNumberValid || !isAmountValid || !isFeeValid) {
            return;
        }

        this.props.patchPayment({ caseUuid, paymentInfo, collect: false, sendNotification: true });
        this.closeDialog();
    };

    closeDialog = () => {
        this.props.closeDialog();
        this.setState(getInitialState(null, this.props.feeSchedule));
    };
}

export default withStyles(styles)(RecordInsurancePolicyDialog);
