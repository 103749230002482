import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { LongAddress, longAddress } from './deathcertificate';
import { GatherCaseEssentials } from './case';
import { FuneralHomeRecord } from './funeralHome';
import { UserProfile } from './user';
import { getValidator } from './utils';
// To understand how/why we are using the io-ts library for DRY runtime type checking:
// https://lorefnon.tech/2018/03/25/typescript-and-validations-at-runtime-boundaries/
// io-ts library documentation/code: https://github.com/gcanti/io-ts

// ---> GatherData - an obect passed back to the client after API authentication succeeds
export interface GatherData {
    gather: string; // API version
    userData: UserProfile;
    defaultFuneralHome: Pick<FuneralHomeRecord, 'id' | 'key'> | null;
    mostRecentCase: GatherCaseEssentials | null;
}

export interface SessionResult {
    data: GatherData;
    token: string;
}

export enum ResetCodeReason {
    Valid = 'Valid',
    Used = 'Used',
    Expired = 'Expired',
}

export enum ConsumableSearchParam {
    open = 'open',
    cta = 'cta',
    data = 'data'
}

// ---> Obituary <---
const obituaryNewspaperType = t.type({
    name: t.union([t.null, t.string]),
    photos: t.array(t.string),
    obituary_instructions: t.union([t.null, t.string]),
    death_notice_text: t.union([t.null, t.string]),
    obituary: t.boolean,
    death_notice: t.boolean
});

export interface ObituaryNewspaper extends t.TypeOf<typeof obituaryNewspaperType> {
}

const obituaryNewspaperJsonType = t.type({
    data: t.array(obituaryNewspaperType)
});
export interface ObituaryNewspaperJson extends t.TypeOf<typeof obituaryNewspaperJsonType> {
}

export enum ObituaryPerspective {
    traditional = 'traditional',
    lovedOnes = 'lovedOnes',
    deceased = 'deceased'
}

const ObituaryPerspectiveType = t.union([
    t.literal(ObituaryPerspective.traditional),
    t.literal(ObituaryPerspective.lovedOnes),
    t.literal(ObituaryPerspective.deceased),
]);

export enum ObituaryWritingStyle {
    Formal = 'Formal',
    Heartfelt = 'Heartfelt',
    Hopeful = 'Hopeful',
    Humorous = 'Humorous',
    LightHearted = 'Light-Hearted',
    Poetic = 'Poetic',
    Religous = 'Religous',
    Respectful = 'Respectful',
    Traditonal = 'Traditonal',
    Uplifting = 'Uplifting',
}

const ObituaryWritingStyleType = t.union([
    t.literal(ObituaryWritingStyle.Formal),
    t.literal(ObituaryWritingStyle.Heartfelt),
    t.literal(ObituaryWritingStyle.Hopeful),
    t.literal(ObituaryWritingStyle.Humorous),
    t.literal(ObituaryWritingStyle.LightHearted),
    t.literal(ObituaryWritingStyle.Poetic),
    t.literal(ObituaryWritingStyle.Religous),
    t.literal(ObituaryWritingStyle.Respectful),
    t.literal(ObituaryWritingStyle.Traditonal),
    t.literal(ObituaryWritingStyle.Uplifting),
]);

const AutoObitConfigurationType = t.type({
    paragraphs: t.number,
    style: ObituaryWritingStyleType,
    adjectives: t.array(t.string),
    matteredMost: t.string,
    about: t.string,
    instructions: t.string,
    perspective: ObituaryPerspectiveType,
    helperEntityId: t.union([t.number, t.null]),
    helperFirstName: t.string,
    helperRelationship: t.string,
    includeLink: t.boolean,
    includeQuotes: t.boolean,
});

export interface AutoObitConfiguration extends t.TypeOf<typeof AutoObitConfigurationType> {
    perspective: ObituaryPerspective;
    style: ObituaryWritingStyle;
}

const AutoObitPersonalInfoType = t.type({
    nicknames: t.string,
    places: t.string,
    hobbies: t.string,
    awards: t.string,
    clubs: t.string,
    wasReligious: t.boolean,
    religion: t.string,
    religiousInvolvement: t.string,
});

export interface AutoObitPersonalInfo extends t.TypeOf<typeof AutoObitPersonalInfoType> {
}

const AutoObitParamsType = t.type({
    facts: t.array(t.string),
    excludeHelpers: t.array(t.number),
    excludeEvents: t.array(t.number),
    firstName: t.string,
    configuration: AutoObitConfigurationType,
    personalInfo: AutoObitPersonalInfoType,
    updated_time: t.union([DateFromISOString, t.null]),
    generate_start_time: t.union([DateFromISOString, t.undefined]),
});

export interface AutoObitParams extends t.TypeOf<typeof AutoObitParamsType> {
    configuration: AutoObitConfiguration;
};

export class AutoObitParams {
    public static fromRequest = getValidator<AutoObitParams>(AutoObitParamsType);
}

export interface Obituary {
    id: number;
    content: string;
    published_content: string | null;
    gather_case_id: number;
    updated_by: number | null;
    updated_by_fname: string | null;
    updated_by_lname: string | null;
    updated_time: Date | null;
    approved_by: number | null;
    approved_by_fname: string | null;
    approved_by_lname: string | null;
    approved_time: Date | null;
    locked: boolean;
    locked_by: number | null;
    post_to_fh_website: boolean;
    place_in_newspaper: boolean;
    newspapers: ObituaryNewspaperJson | null;
    auto_obit_content: string;
    auto_obit: AutoObitParams | null;
    auto_obit_error: string | null;
    auto_obit_time: Date | null;
    auto_obit_count: number;
}

const ObituaryRequestType = t.intersection([t.type({
    locked: t.boolean,
    updated_time_of_previous_version: t.union([DateFromISOString, t.null]),
}), t.partial({
    content: t.string,
    post_to_fh_website: t.boolean,
    place_in_newspaper: t.boolean,
    newspapers: t.union([t.null, obituaryNewspaperJsonType]),
    auto_obit: t.union([AutoObitParamsType, t.null]),
})]);

export const OBITUARY_TIMEOUT = 180000;

export interface ObituaryRequest extends t.TypeOf<typeof ObituaryRequestType> {
    updated_time_of_previous_version: Date | null;
    newspapers?: ObituaryNewspaperJson | null;
    auto_obit?: AutoObitParams | null;
}

export class ObituaryRequest {
    public static fromRequest = getValidator<ObituaryRequest>(ObituaryRequestType);
}

// ---> GoogleCalendarRecord <---
export interface GoogleCalendarRecord {
    id: number;
    name: string;
    google_id: string;
    sync_token: string | null;
    sync_max_date: Date | null;
    last_failure_time: Date | null;
    last_failure_message: string | null;
    consecutive_failure_count: number;
    deactivated_time: Date | null;
}

export interface GoogleCalendarInsertRecord {
    id?: number;
    name: string;
    google_id: string;
    sync_token: string | null;
    sync_max_date: Date | null;
}

// ---> GoogleCalendar <---
const googleCalendarRequiredDefinition = {
    id: t.string,
    summary: t.string,
    description: t.string,
};

const googleCalendarOptionalDefinition = {
    backgroundColor: t.string,
    foregroundColor: t.string,
    primary: t.boolean,
};

const GoogleCalendarType = t.intersection([
    t.type(googleCalendarRequiredDefinition),
    t.partial(googleCalendarOptionalDefinition),
]);
type GoogleCalendarType = t.TypeOf<typeof GoogleCalendarType>;

export interface GoogleCalendar extends GoogleCalendarType {
}

export class GoogleCalendar {
    public static fromRequest = getValidator<GoogleCalendar>(GoogleCalendarType);
}

// ---> AddressRecord <---
export interface AddressRecord {
    id: number;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    country: string | null;
    postal_code: string | null;
    timezone: string | null;
    description: string;
    long_address: LongAddress;
    use_description: boolean;
}

export type AddressCreateRecord = Omit<AddressRecord, 'id'>;

export const addressFromLongAddress = (
    longAddr: LongAddress,
    timezone: string | null,
    useDescription: boolean,
): AddressCreateRecord => ({
    address1: longAddr.address1,
    address2: longAddr.address2 || null,
    city: longAddr.city,
    state: longAddr.state,
    country: longAddr.country,
    postal_code: longAddr.postalCode,
    timezone,
    description: longAddr.description,
    long_address: longAddr,
    use_description: useDescription,
});

// ---> LocationRecord <---
export interface LocationRecord {
    id: number;
    funeral_home_id: number;
    name: string | null;
    address_id: number;
    is_saved: boolean;
    location_type: LocationType;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
}

export enum LocationType {
    funeral_home = 'funeral_home',
    event = 'event',
}

// ---> LocationRequest <---
const locationRequestDefinition = {
    name: t.union([t.string, t.null]),
    long_address: longAddress,
    timezone: t.string,
    is_saved: t.boolean,
    use_address_description: t.boolean,
};

const LocationRequestType = t.type(locationRequestDefinition);

export interface LocationRequest extends t.TypeOf<typeof LocationRequestType> {
}

export class LocationRequest {
    public static fromRequest = getValidator<LocationRequest>(LocationRequestType);
    public static fromPatchRequest = getValidator<Partial<LocationRequest>>(t.partial(LocationRequestType.props));
}

// ---> LocationUX <---
export interface LocationUX extends LocationRecord {
    address: AddressRecord;
}

export const GATHER_PHONE_NUMBER = '208-908-0488';

export * from './utils';
export * from './album';
export * from './product';
export * from './finance';
export * from './doc';
export * from './docpacket';
export * from './task';
export * from './case';
export * from './deathcertificate';
export * from './deathcertificateconfig';
export * from './photo';
export * from './event';
export * from './user';
export * from './video';
export * from './funeralHome';
export * from './flowersales';
export * from './note';
export * from './live_stream';
export * from './servicedetail';
export * from './hellosign';
export * from './rolodex';
export * from './themes';
export * from './memories';
export * from './moderation';
export * from './deepLink';
export * from './fingerprint';
export * from './workflow';
export * from './caselabel';
export * from './taskTemplate';
export * from './taskLocation';
export * from './taskComponent';
export * from './caseBelonging';
export * from './keeptrack';
export * from './website';
export * from './treeProject';
export * from './qrsticker';
export * from './checkout';
export * from './sendgrid';
export * from './standardReport';
export * from './report';
export * from './reportSchedule';
export * from './caseNumber';
export * from './subscription';
export * from './insurance';
export * from './themes';
