import { useEffect, useMemo } from "react";
import debounce from 'lodash/debounce';

function useDebounce<T extends (...args: Parameters<T>) => ReturnType<T>>(
    fn: T,
    debounceMs?: number,
    cleanup?: {
        flush?: true;
        cancel?: true;
    },
) {

    const debouncedFn = useMemo(() =>
        debounce((...params: Parameters<T>) => fn(...params), debounceMs ?? 500),
        [fn, debounceMs],
    );

    useEffect(() => function cleanupDebounce() {
        if (cleanup?.cancel) {
            debouncedFn.cancel();
        } else {
            debouncedFn.flush();
        }
    }, [debouncedFn, cleanup?.cancel]);

    return debouncedFn;
}

export default useDebounce;
