import { convertHexToRGBA } from '../../../../services';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import { GRAY_COLOR_2, GREEN_COLOR, ORANGE_COLOR } from '../../../../constants/colorVariables';
import { WithGStyles } from '../../../../styles/WithGStyles';

export function styleWrapper<P extends object>() {
    const styles: StyleRulesCallback<Theme, P> = (theme) => ({
        root: {
            '&$hidden': {
                height: 0,
                opacity: 0,
                width: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'none',
            },
            '& $captionGrid': {
                margin: 'auto',
                padding: '0 12px',
                '& $note': {
                    cursor: 'pointer',
                    display: 'flex',
                    '& $inner': {
                        fontStyle: 'italic',
                        padding: '0 !important',
                        borderBottom: 'none !important',
                        background: 'transparent !important',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    },
                },
                '& $label': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: 12,
                    lineHeight: '18px',
                },
                '& $clickable': {
                    cursor: 'pointer',
                    '& $inner': {
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    },
                    '&$restricted': {
                        cursor: 'auto !important',
                        '& $inner': {
                            '&:hover': {
                                textDecoration: 'none !important',
                            },
                        },
                    }
                }
            }
        },
        content: {
            color: theme.palette.primary.main,
            margin: 'auto',
            maxWidth: 800,
            '& $contentInner': {
                padding: '8px 8px 0 16px',
                '&$restricted': {
                    padding: '0 16px !important',
                }
            }
        },
        itemGrid: {
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            margin: 'auto',
            '& $label': {
                fontSize: 18,
                lineHeight: '1.25em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover:first-of-type': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
            '& $iconButton': {
                marginLeft: 4,
                height: 32,
                width: 32,
                color: '#2e2e2e',
                '& svg': {
                    fontSize: 20,
                },
                '&$restricted': {
                    width: 0,
                    opacity: 0,
                    margin: 0,
                    color: 'transparent',
                },
                '&$small': {
                    height: 24,
                    width: 24,
                    '& svg': {
                        fontSize: 12,
                    },
                },
                '&$smallWidth': {
                    marginLeft: 0,
                    height: 16,
                    width: 16,
                },
            },
            '& $smallIconButton': {
                marginLeft: 0,
                height: 16,
                width: 16,
                '& svg': {
                    fontSize: '16px !important',
                },
            },
            '& $clickable': {
                cursor: 'pointer !important',
                '&:hover': {
                    textDecoration: 'underline !important',
                },
            },
        },
        contractHeader: {
            margin: '8px auto 0',
            color: theme.palette.primary.main,
            '& $inner': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 'auto',
                flexDirection: 'column',
                padding: '8px 0',
                maxWidth: 800,
                textAlign: 'center',
                [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row',
                    padding: '4px 0',
                    textAlign: 'left',
                },
                '&$fiveCategoryGroups': {
                    flexDirection: 'column',
                    '@media (min-width: 768px)': {
                        flexDirection: 'row',
                    },
                    '& $caseDetails': {
                        '& p': {
                            'text-align': 'center',
                            '@media (min-width: 768px)': {
                                'text-align': 'right',
                            },
                        },
                    },
                    '& $funeralHomeDetails': {
                        '& p': {
                            'text-align': 'center',
                            '@media (min-width: 768px)': {
                                'text-align': 'left',
                            },
                        },
                    },
                },
                '& img': {
                    margin: '0 auto 8px',
                    maxWidth: 175,
                    maxHeight: 50,
                    [theme.breakpoints.up('sm')]: {
                        margin: 0,
                    },
                    '@media (min-width: 700px)': {
                        maxWidth: 320,
                        maxHeight: 92,
                    },
                },
                '& $typographyBig': {
                    fontSize: 16,
                    fontWeight: 400,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                },
                '& $typographySmall': {
                    fontSize: 12,
                    lineHeight: '1em',
                    fontWeight: 300,
                },
                '& $caseDetails': {
                    '& p': {
                        'text-align': 'center',
                        [theme.breakpoints.up('sm')]: {
                            'text-align': 'right',
                        }
                    },
                },
                '& $funeralHomeDetails': {
                    '& p': {
                        'text-align': 'center',
                        [theme.breakpoints.up('sm')]: {
                            'text-align': 'left',
                        }
                    },
                },
            },
        },
        packageSection: {
            borderBottom: '1px solid',
            color: theme.palette.primary.main,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
            },
            '& $package': {
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'capitalize',
                margin: 8,
                pointerEvents: 'none',
            },
        },
        addItemButton: {
            margin: '8px auto',
        },
        thumbnail: {
            backgroundColor: 'transparent',
            width: 'auto',
            minWidth: 'auto',
            height: 56,
            borderRadius: 8,
            color: theme.palette.primary.main,
            margin: 8,
            cursor: 'pointer',
            verticalAlign: 'top',
            [theme.breakpoints.up('sm')]: {
                margin: '8px 16px 0 0',
            },
        },
        photoGrid: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 8,
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
            '& $inner': {
                display: 'inline-block',
                width: '100%',
                padding: '0 !important',
                border: 'none !important',
                background: 'none !important',
            },
        },
        totalSection: {
            paddingBottom: 32,
            borderBottom: '1px solid',
            '&$noBorder': {
                border: 'none !important',
            },
            '& p': {
                textTransform: 'capitalize',
                fontSize: 20,
                textAlign: 'center',
            },
        },
        warningGrid: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& $value': {
                marginRight: 24,
            },
        },
        caseNumber: {
            borderRadius: 24,
            padding: '0 10px',
            minHeight: 18,
            color: theme.palette.common.black,
            textTransform: 'none',
        },
        background_primary_06: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.06),
        },
        settingSection: {
            maxWidth: 820,
            textAlign: 'center',
            '& $contractStatus': {
                margin: '8px auto',
                width: 'fit-content',
                cursor: 'pointer',
                '& svg': {
                    verticalAlign: 'bottom',
                    fontSize: 20,
                },
                '@media (min-width: 768px)': {
                    float: 'left',
                    margin: '24px 8px 0px',
                },
            },
        },
        printableSection: {
            padding: '0 24px',
            boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, 
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 3px 3px -2px`,
            border: `2px dashed ${convertHexToRGBA(theme.palette.primary.main, 0.36)}`,
            '&$noBorder': {
                border: 'none !important',
            },
        },
        layoutGButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '12px auto',
            '@media (min-width: 768px)': {
                margin: '8px 0 12px',
                float: 'right',
            },
            '& $iconButton': {
                marginLeft: 2,
                height: 32,
                width: 32,
                '& svg': {
                    fontSize: 24,
                },
            },
            '@supports (-webkit-touch-callout: none)': {
                '& button:nth-of-type(2)': {
                    marginTop: -1
                }
            }
        },
        contractContainer: {
            margin: '24px auto auto',
        },
        compactView: {
            [theme.breakpoints.down('sm')]: {
                display: 'block !important',
                padding: '0px !important',
            },
        },
        compactViewContent: {
            [theme.breakpoints.down('sm')]: {
                borderRight: '0px !important',
                width: '100% !important',
            },
        },
        signatoriesContainer: {
            display: 'flex',
            flexDirection: 'column',
            '&$isCondensedView': {
                '& $signatureGrid': {
                    '&$isCondensedView': {
                        '&:last-child': {
                            marginBottom: '0 !important',
                        },
                    },
                },
            },
        },
        signatureGrid: {
            width: '100%',
            '&$isCondensedView': {
                '@media (min-width: 600px)': {
                    marginBottom: 0,
                },
                '& p': {
                    margin: '0 !important',
                },
            },
        },
        footerBadge: {
            textAlign: 'center',
            '& button': {
                padding: '8px 36px',
                borderRadius: '0 0 8px 8px',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                '&:hover': {
                    textDecoration: 'underline',
                },
                '& $inner': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                '& $taxRate': {
                    textTransform: 'capitalize',
                    marginTop: 3,
                },
                '& svg': {
                    fontSize: 16,
                },
            },
            '&$restricted': {
                '& button': {
                    pointerEvents: 'none',
                    '&:hover': {
                        textDecoration: 'none',
                    },
                },
            },
            '&$fhView': {
                '& button': {
                    backgroundColor: ORANGE_COLOR,
                },
            },
        },
        addItemCompactButton: {
            margin: '0 auto',
            opacity: 0.4,
            padding: '2px 16px',
            minHeight: 'auto',
            fontSize: 12,
            '&:hover': {
                opacity: 1,
            },
        },
        background_primary_08: {
            background: convertHexToRGBA(theme.palette.primary.main, 0.08),
        },
        paymentCardContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '24px auto',
            marginTop: 64,
            flexDirection: 'column',
            '@media (min-width: 900px)': {
                maxWidth: 820,
                flexDirection: 'row',
            },
            '& $paymentCard': {
                boxShadow: theme.shadows[5],
                margin: '24px auto',
                width: '100%',
                maxWidth: 400,
                overflow: 'visible',
                borderRadius: 16,
                '@media (min-width: 900px)': {
                    width: 'calc(50% - 16px)',
                    minWidth: 'calc(50% - 16px)',
                    margin: 0,
                },
                '& $paymentCardContent': {
                    textAlign: 'center',
                    padding: '0 8px 8px !important',
                    '& button': {
                        margin: 8,
                    },
                    '& $contractStatus': {
                        fontSize: 22,
                        color: theme.palette.secondary.main,
                        lineHeight: '2.5em',
                        marginTop: '-40px',
                        marginBottom: 0,
                    },
                    '& $contractStatusIcon': {
                        border: `5px solid ${theme.palette.primary.main}`,
                        borderRadius: '50%',
                        height: 80,
                        width: 80,
                        transform: 'translate(0%, -50%)',
                        background: theme.palette.common.white,
                        margin: 'auto',
                        '& svg': {
                            fontSize: 48,
                            marginTop: 10,
                            color: theme.palette.primary.main,
                        },
                    },
                    '& $ulockedIcon': {
                        border: `5px solid ${convertHexToRGBA(theme.palette.primary.main, 0.12)}`,
                        '& svg': {
                            color: `${convertHexToRGBA(theme.palette.primary.main, 0.36)} !important`,
                        },
                    },
                },
            },
        },
        footerButton: {
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            '& button': {
                margin: 8,
            },
            '@media (min-width: 440px)': {
                display: 'flex',
            },
        },
        disclaimerSection: {
            paddingLeft: 8,
            '& p': {
                fontSize: 10,
            },
            '& $inner': {
                margin: '0',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
            },
            '& $label': {},
            '& $familyRequested': {
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
                cursor: 'pointer',
                '& svg': {
                    fontSize: 16,
                },
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
        settingsSection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: 16,
            '@media (min-width: 700px)': {
                flexDirection: 'row',
            },
            '& $settingsCard': {
                color: theme.palette.common.white,
                border: `1px solid ${ORANGE_COLOR}`,
                width: '100%',
                maxWidth: 360,
                borderRadius: 8,
                margin: '8px auto',
                '@media (min-width: 700px)': {
                    maxWidth: '100%',
                    margin: 8,
                },
                '& $content': {},
                '& $header': {
                    background: ORANGE_COLOR,
                    padding: 8,
                    '& span': {
                        textAlign: 'center',
                        color: theme.palette.common.white,
                        fontSize: 18,
                    },
                },
                '&$overrideDetailsCard': {
                    margin: 'auto',
                    maxWidth: 360,
                    textAlign: 'center',
                    '&$categoryGroupingsCard': {
                        '@media (min-width: 768px)': {
                            maxWidth: '100%',
                        },
                    },
                    '& $content': {
                        padding: '0 16px 8px',
                        '& p': {
                            fontSize: 13,
                            margin: '8px auto',
                            '&:first-of-type': {
                                margin: '16px auto 8px',
                            },
                        },
                    }
                },
            }
        },
        gSwitchChecked: {
            '& + $gSwitchBar': {
                backgroundColor: `${ORANGE_COLOR} !important`,
            },
        },
        gSwitchIcon: {
            color: `${ORANGE_COLOR} !important`,
        },
        labelWithTextField: {
            display: 'flex',
            alignItems: 'center',
            '& $label': {
                minWidth: 'fit-content',
                width: 'fit-content',
                marginRight: 8,
            },
        },
        gSwitchSmallIcon: {
            height: 16,
            width: 16,
        },
        gSwitchSmallBar: {
            width: 26,
            height: 10,
        },
        formControlLabel: {
            marginLeft: 32,
        },
        sectionHeading: {
            paddingTop: 8,
            margin: '0 4px',
            borderBottom: '1px solid',
            '& p': {
                lineHeight: '1em',
                textAlign: 'center',
                color: theme.palette.secondary.main,
                fontSize: 14,
            },
        },
        selectMenuForm: {
            textAlign: 'left',
            width: 170,
            margin: 10,
        },
        selectMenu: {
            '&:focus': {
                background: 'transparent',
            },
        },
        menu: {
            '& li': {
                color: theme.palette.common.black,
            },
        },
        borderTopPrimary: {
            borderTop: `1px solid ${theme.palette.primary.main}`,
        },
        addProductButton: {
            background: GREEN_COLOR,
            color: theme.palette.common.white,
            boxShadow: 'none !important',
            '&:hover': {
                background: convertHexToRGBA(GREEN_COLOR, 0.8),
            },
            '& svg': {
                fontSize: 16,
            },
        },
        caseType: {
            fontSize: 12,
            textTransform: 'capitalize',
            borderRadius: 24,
            color: theme.palette.common.white,
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            minHeight: 18,
            '&$atNeed': {
                background: theme.palette.primary.main,
            },
            '&$preNeed': {
                background: convertHexToRGBA(theme.palette.primary.main, 0.8),
            },
            '&$trade': {
                backgroundColor: GRAY_COLOR_2,
            },
            '&$oneOff': {
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
            },
        },
        equalSpacingContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
            },
        },
        compactViewContainer: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
        },
        categoriesContainer: {
            marginTop: 16,
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 768px)': {
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
            },
            '& $divider': {
                width: '100%',
                height: 1,
                background: ORANGE_COLOR,
                margin: '8px 0 16px',
                '@media (min-width: 768px)': {
                    height: 170,
                    width: 1,
                    margin: 0,
                },
            },
            '& p': {
                margin: '0 auto 8px !important',
            },
        },
        category: {
            maxWidth: 320,
            width: '100%',
            '@media (min-width: 768px) and (max-width: 800px)': {
                maxWidth: 300,
            },
        },
        maxWidth895: {
            maxWidth: 895,
        },
        packagePreferences: {
            '@media (min-width: 960px)': {
                maxWidth: '1200px !important',
            },
            '& $content': {
                padding: '0 24px 8px !important',
                maxWidth: '100%'
            },
        },
        preferencesContainer: {
            flexWrap: 'nowrap',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: 40,
            '@media (min-width: 960px)': {
                flexDirection: 'row'
            },
            '& $divider': {
                margin: '0 0 12px',
                width: '100%',
                background: ORANGE_COLOR,
                '@media (min-width: 960px)': {
                    margin: '0 24px',
                    height: 'inherit',
                    width: 1,
                }
            }
        },
        prefernce: {
            width: '100%',
            maxWidth: 360,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'stretch',
            justifyContent: 'center',
            '& $formControlLabelClass': {
                alignSelf: 'flex-end',
                justifySelf: 'center',
                margin: '4px 0 4px -24px',
                '& $radioChecked:not($radioDisabled)': {
                    color: `${ORANGE_COLOR} !important`,
                },
            },
            '& p': {
                margin: '0 !important'
            }
        },
        outsideChargesHeading: {
            marginTop: 4,
            fontSize: 14,
            fontWeight: 700,
        },
        grandTotal: {
            marginTop: 4,
            fontSize: 18,
            fontWeight: 700,
        },
        payments: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        paymentsText: {
            fontSize: 16,
            fontWeight: 400,
        },
        opacity60: {
            opacity: 0.6,
        },
        balance: {
            fontSize: 18,
            fontWeight: 500,
        },
        radioDisabled: {},
        radioChecked: {},
        formControlLabelClass: {},
        divider: {},
        categoryGroupingsCard: {},
        fiveCategoryGroups: {},
        atNeed: {},
        preNeed: {},
        trade: {},
        oneOff: {},
        overrideDetailsCard: {},
        gSwitchBar: {},
        settingsCard: {},
        header: {},
        familyRequested: {},
        fhView: {},
        paymentCard: {},
        paymentCardContent: {},
        smallIconButton: {},
        clickable: {},
        restricted: {},
        isCondensedView: {},
        noBorder: {},
        captionGrid: {},
        typographySmall: {},
        typographyBig: {},
        package: {},
        label: {},
        iconButton: {},
        note: {},
        caseDetails: {},
        funeralHomeDetails: {},
        inner: {},
        contentInner: {},
        value: {},
        hidden: {},
        small: {},
        smallWidth: {},
        contractStatus: {},
        contractStatusIcon: {},
        ulockedIcon: {},
        labelText: {},
        taxRate: {},
    });

    return styles;
}

export type StyledProps = WithGStyles<'root' | 'contractStatusIcon' | 'ulockedIcon' | 'contractStatus' | 'footerBadge'
    | 'restricted' | 'taxRate' | 'contractContainer' | 'maxWidth895' | 'hidden' | 'settingSection' | 'layoutGButton'
    | 'iconButton' | 'paymentCardContainer' | 'footerButton' | 'contentInner' | 'background_primary_06'
    | 'addItemCompactButton' | 'addItemButton' | 'typographySmall' | 'borderTopPrimary' | 'outsideChargesHeading'
    | 'payments' | 'grandTotal' | 'balance' | 'paymentsText' | 'opacity60' | 'printableSection' | 'noBorder'
    | 'content' | 'compactView' | 'addProductButton' | 'equalSpacingContainer' | 'compactViewContent'
    | 'compactViewContainer' | 'packageSection' | 'package' | 'background_primary_08' | 'sectionHeading'
    | 'caseDetails' | 'typographyBig' | 'caseNumber' | 'caseType' | 'atNeed' | 'preNeed' | 'trade' | 'oneOff'
    | 'funeralHomeDetails' | 'contractHeader' | 'inner' | 'fiveCategoryGroups' | 'captionGrid' | 'label'
    | 'warningGrid' | 'note' | 'clickable' | 'value' | 'photoGrid' | 'thumbnail' | 'labelText' | 'smallIconButton'
    | 'itemGrid' | 'small' | 'smallWidth' | 'totalSection' | 'signatureGrid' | 'isCondensedView' | 'paymentCard'
    | 'paymentCardContent' | 'disclaimerSection' | 'familyRequested' | 'settingsCard' | 'header'
    | 'signatoriesContainer' | 'gSwitchBar' | 'gSwitchSmallBar' | 'gSwitchIcon' | 'gSwitchChecked'
    | 'gSwitchSmallIcon' | 'formControlLabel' | 'formControlLabelClass' | 'radioChecked' | 'radioDisabled'
    | 'settingsSection' | 'overrideDetailsCard' | 'packagePreferences' | 'preferencesContainer' | 'prefernce'
    | 'divider' | 'categoryGroupingsCard' | 'categoriesContainer' | 'category' | 'selectMenuForm' | 'selectMenu'
    | 'menu' | 'labelWithTextField' | 'fhView'>;

export const captionLabelStyle = {
    fontFamily: 'system-ui, sans-serif',
    display: 'flex',
    alignItems: 'flex-start',
    margin: 0,
    fontSize: 12,
    lineHeight: '18px'
};

export const iconStyle = {
    fontSize: 24,
    width: '1em',
    height: '1em',
    display: 'inline-block',
    flexShrink: 0,
    fontFamily: 'Material Icons'
};

export const itemLabelStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 18,
    lineHeight: '1.25em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
};

export const typographyStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1.46429em',
    margin: 0
};

export const signatureNameStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 12,
    fontWeight: 200,
    borderTop: '1px solid',
    width: '100%',
    margin: '0 8px',
};

export const signatureDataStyle = {
    fontFamily: 'helvetica, sans-serif',
    fontSize: 10,
    fontWeight: 200,
    width: '100%',
    margin: '0 8px',
};

export const signatureTextTagStyle = {
    fontFamily: '"Courier New", Courier, monospace',
    fontSize: 12,
    fontWeight: 200,
    color: 'white',
    width: '100%',
    margin: '0 8px',
    lineHeight: '18px',
};
