import { useState } from "react";
import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { useGDispatch, useGSelector } from "../../types";

import makeGStyles from "../../styles/makeGStyles";
import { alpha } from "@mui/material";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';

import { getFormattedPhoneNumber, generateNameInitials } from '../../services';
import { GREEN_COLOR_2, SKYBLUE_COLOR_2, RED_COLOR } from '../../constants/colorVariables';

import {
    CaseTaskUX,
    TaskUpdateRequestUX,
    userToTaskAssignee,
    EntityCaseRole,
    EntitySummary,
    getCaseEntity,
    GatherCaseUX,
    TaskAssignee,
    CaseHelperUpdateRequest,
    FeatureKey,
    UserRoles,
} from '../../shared/types';
import { updateTask } from '../../actions/task/Task.action';
import SaveTaskDialog from '../family/tasks/SaveTask.dialog';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import HelperPopper from '../family/helperPopper/HelperPopper';
import { WhenCanAssignTask } from '../common/AppPermissions';
import { canEditNonSensitivePersonDetails } from '../../shared/authority/can';
import { joinNameParts } from '../../shared/utils';
import AssignTask from '../assignmentPoppers/AssignTask';
import QRPermissionsForHelpers from "./QRPermissionsForHelpers";
import { updateCaseHelper } from "../../actions/CaseHelper.action";
import { WhenEnabled } from "../common/AppFeature";
import GTooltip from "../common/GTooltip";
import { HelperInvitationDialogContext } from "../../actions/Dialog.action";

type UpdateRequest = Partial<Pick<CaseHelperUpdateRequest, 'can_view_tracking_steps' | 'can_view_belongings'>>;

interface Props {
    entityRole: EntityCaseRole;
    activeCase: GatherCaseUX;
    editHelper: () => void;
    resetInvitationForm: () => void;
    closeParentDialog: () => void;
    newHelper: EntitySummary;
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    zIndex: number;
    isDCEntity: boolean;
}

const useStyles = makeGStyles(theme => ({
    root: {
        textAlign: 'center',
    },
    topHeading: {
        textAlign: 'center',
        fontSize: 18
    },
    sentDetails: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 300,
        lineHeight: '1.25em'
    },
    mainContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '24px 16px',
        '& $inner': {
            borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.25)}`,
            marginBottom: 8,
            padding: '4px 0'
        },
        '& $header': {
            fontSize: 16
        },
        '& $subHeader': {
            fontStyle: 'italic',
            marginBottom: 8,
        },
        '& $divider': {
            height: 1,
            width: 'calc(100% - 48px)',
            maxWidth: 420,
            padding: '24px 0',
            '@media (min-width: 760px)': {
                height: '100%',
                width: 1,
                maxWidth: 1,
                padding: '0 24px',
            },
            '& $inner': {
                border: 'none',
                margin: 0,
                padding: 0,
                background: alpha(theme.palette.primary.main, 0.45),
                height: 1,
                width: '100%',
                maxWidth: 420,
                '@media (min-width: 760px)': {
                    position: 'absolute',
                    height: '100%',
                    width: 1,
                    maxWidth: '100%'
                },
            }
        },
        '& $leftSection': {
            width: '100%',
            maxWidth: 440,
            textAlign: 'center',
        },
        '& $rightSection': {
            width: '100%',
            maxWidth: 440,
            textAlign: 'center',
        },
        '@media (min-width: 760px)': {
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
    },
    task: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '4px 0',
        '& $iconButton': {
            width: 28,
            height: 28,
            '& svg': {
                fontSize: 18,
                color: GREEN_COLOR_2,
            },
            '&:hover': {
                backgroundColor: alpha(GREEN_COLOR_2, 0.08)
            }
        },
        '& $iconButton2': {
            width: 28,
            height: 28,
            '& svg': {
                fontSize: 18,
                color: theme.palette.primary.main,
            },
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08)
            }
        },
        '& $label': {
            fontSize: 16,
            margin: '0 4px',
        },
        '& $icon': {
            fontSize: 16,
            color: theme.palette.primary.main
        },
        '&$isAssigned': {
            color: GREEN_COLOR_2,
            '& $iconButton': {
                '& svg': {
                    color: theme.palette.primary.main,
                },
                '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.08)
                }
            },
            '& $iconButton2': {
                '& svg': {
                    color: GREEN_COLOR_2,
                },
                '&:hover': {
                    backgroundColor: alpha(GREEN_COLOR_2, 0.08)
                }
            },
            '& $label': {
                color: GREEN_COLOR_2,
            },
            '& $icon': {
                color: GREEN_COLOR_2,
            },
        },
        '@media (min-width: 760px)': {
            margin: 0
        }
    },
    colorGreen: {
        color: GREEN_COLOR_2
    },
    inviteHelperBtn: {
        margin: '24px 0',
    },
    createNewTaskButton: {
        margin: 8,
        fontWeight: 400
    },
    clickableText: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    overlapDot: {
        top: 4,
        left: 4,
        fontSize: 12,
        background: SKYBLUE_COLOR_2,
        height: '1em',
        width: '1em',
        borderRadius: '50%',
        zIndex: 1,
        position: 'absolute',
    },
    avatarContainer: {
        width: 90,
        height: 90,
        margin: '16px auto auto',
        position: 'relative',
    },
    nameInitialsAvatar: {
        width: 90,
        height: 90,
        margin: 'auto',
        background: grey[400],
        textTransform: 'uppercase',
        fontSize: 40,
        fontWeight: 200,
        '-webkit-font-smoothing': 'antialiased',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    badgeContainer: {
        position: 'absolute',
        right: 0,
        top: '-6px'
    },
    badge: {
        backgroundColor: RED_COLOR,
        right: 8,
        top: 14,
        color: theme.palette.common.white,
        height: 24,
        width: 24,
        borderRadius: 12,
    },
    iconButton2: {},
    isAssigned: {},
    icon: {},
    label: {},
    inner: {},
    iconButton: {},
    leftSection: {},
    rightSection: {},
    subHeader: {},
    header: {},
    divider: {},
}), { name: 'InvitationSuccess' });

type TaskType = {
    task: CaseTaskUX;
    newHelper: EntitySummary;
    canAssign: boolean;
    openTaskAssignmentWidget: (event: React.MouseEvent<HTMLElement>) => void;
    toggleTaskAssignment: (isAssignedToHelper: boolean) => void;
};

const TaskRow = (props: TaskType & Props) => {
    const {
        task,
        newHelper,
        canAssign,
        openTaskAssignmentWidget,
        toggleTaskAssignment
    } = props;

    const classes = useStyles();

    const assignedTo: string | null = task.assigned_to_all && 'Everyone'
        || task.assigned_to.length !== 0
        && `${task.assigned_to.length} ${task.assigned_to.length === 1 && 'person' || 'people'}`
        || null;

    const assignedToIcon = (task.assigned_to_all || task.assigned_to.length > 1)
        && <PeopleIcon className={classes.icon} />
        || <PersonIcon className={classes.icon} />;

    const assignedToTooltip = assignedTo
        ? `This task is currently assigned to ${assignedTo} `
        : '';

    const isAssigned = task.assigned_to_all ||
        Boolean(task.assigned_to.find((ta) => Boolean(newHelper.user_id && ta.user_id === newHelper.user_id)));
    const iconButtonTooltip = `Click to ${isAssigned ? 'unassign' : 'assign'} ` +
        `this task ${isAssigned ? 'from' : 'to'} ${newHelper.fname}`;
    const disabledTooltip = 'You cannot assign this task';

    return (
        <Grid
            item
            xs={12}
            className={classNames(
                classes.task,
                isAssigned && classes.isAssigned
            )}
        >
            <GTooltip
                title={canAssign ? iconButtonTooltip : disabledTooltip}
                placement="top"
                contentDisabled={!canAssign}
                enterDelay={1000}
                enterNextDelay={1000}
            >
                <IconButton
                    color="primary"
                    className={classes.iconButton}
                    onClick={e => toggleTaskAssignment(!isAssigned)}
                    disabled={!canAssign}
                    size="large">
                    {isAssigned ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
            </GTooltip>

            <Typography
                color="primary"
                className={classNames(classes.label, canAssign && classes.cursorPointer)}
                onClick={canAssign ? e => openTaskAssignmentWidget(e) : undefined}
            >
                {task.title}
            </Typography>
            {assignedTo &&
                <GTooltip
                    title={assignedToTooltip}
                    contentDisabled={!canAssign}
                    placement="top"
                >
                    <IconButton
                        className={classes.iconButton2}
                        onClick={e => openTaskAssignmentWidget(e)}
                        disabled={!canAssign}
                        size="large">
                        {assignedToIcon}
                    </IconButton>
                </GTooltip>
            }
        </Grid>
    );
};

const InvitationSuccess = (props: Props) => {

    const {
        editHelper,
        resetInvitationForm,
        closeParentDialog,
        newHelper,
        formType,
        activeCase,
        isDCEntity,
        zIndex,
        entityRole,
    } = props;

    const caseEntity = getCaseEntity(newHelper, activeCase.id);

    const helpers = useGSelector(({ casesState }) => casesState.helpers);
    const userData = useGSelector(({ userSession }) => userSession.userData);
    const checklistTasks = useGSelector(({ tasksState }) => tasksState.checklistTasks);
    const context = useGSelector(({ dialogState }) => dialogState.helperInvitation.context);

    const isFHorGOM = UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home_id);

    const classes = useStyles();
    const dispatch = useGDispatch();

    // const [createNewAfterTask, setCreateNewAfterTask] = useState(false);
    const [assignmentWidgetAnchorEle, setAssignmentWidgetAnchorEle] = useState<HTMLElement | null>(null);
    // const [isAddNewTaskDialogOpen, setIsAddNewTaskDialogOpen] = useState(false);
    const [activeTask, setActiveTask] = useState<CaseTaskUX | null>(null);
    const [newTaskType, setNewTaskType] = useState<null | 'standard' | 'after-care'>(null);
    const [popperAnchorEle, setPopperAnchorEle] = useState<HTMLElement | null>(null);
    const [activeEntityId, setActiveEntityId] = useState<number | null>(null);
    const [isSaveTaskDialogOpen, setIsSaveTaskDialogOpen] = useState(false);

    const [canViewQRTracking, setCanViewQRTracking] = useState(caseEntity?.can_view_tracking_steps ?? false);
    const [canViewCaseBelongings, setCanViewCaseBelongings] = useState(caseEntity?.can_view_belongings ?? false);

    if (!userData || !caseEntity) {
        return (<></>);
    }

    const canUserEditHelper = () => {
        if (newHelper && userData) {
            return canEditNonSensitivePersonDetails(
                userData,
                newHelper,
                activeCase.funeral_home_id,
                activeCase.id
            );
        }
        return false;
    };

    const renderLeftSection = (_newHelper: EntitySummary) => {
        if (!userData) {
            return (<></>);
        }
        const standardTasks: CaseTaskUX[] = checklistTasks.filter(t => (t.visible_to_family && !t.is_after_care));
        const standardAssignedTasks = standardTasks.filter(t => t.assigned_to_all
            || t.assigned_to.find((ta) => Boolean(_newHelper.user_id && ta.user_id === _newHelper.user_id)));
        // If this is an admin the viewable task count is just the count. 
        // if this user is a Guest then the viewable task count is what is assigned.    
        const taskLabel = standardAssignedTasks.length === 1 ? 'Standard Task' : 'Standard Tasks';

        const isFormTypeAdmin: boolean = formType === 'INVITE_ADMIN';
        const subHeaderText = `${isFormTypeAdmin && 'Admins' || 'Guest'}
            ${isFormTypeAdmin && 'can view ALL' || 'can only view assigned'} family tasks`;
        return (
            <Grid item xs={12} className={classes.leftSection}>
                <Grid item className={classes.inner}>
                    <Typography color="secondary" className={classes.header}>
                        {_newHelper.fname} is assigned&nbsp;
                        <span className={classes.colorGreen}>
                            {(standardAssignedTasks || []).length} {taskLabel}
                        </span>
                    </Typography>
                    <Typography color="secondary" className={classes.subHeader}>
                        {subHeaderText}
                    </Typography>
                </Grid>

                {standardTasks.map((t, i) =>
                    <TaskRow
                        {...props}
                        key={i}
                        task={t}
                        newHelper={_newHelper}
                        canAssign={isFHorGOM || t.can_reassign_by_family}
                        openTaskAssignmentWidget={e => openAssignmentWidget(e, t)}
                        toggleTaskAssignment={(isAssignedToHelper) =>
                            toggleTaskAssignment(isAssignedToHelper, _newHelper, t)}
                    />
                )}

                <Button
                    color="primary"
                    className={classes.createNewTaskButton}
                    onClick={e => handleSaveTask('standard')}
                >
                    CREATE NEW TASK
                </Button>
            </Grid>
        );
    };

    const renderRightSection = (__newHelper: EntitySummary) => {
        if (!userData) {
            return (<></>);
        }
        const afterCareTasks = checklistTasks.filter(t => (t.visible_to_family && t.is_after_care));

        const afterCareAssignedTasks = afterCareTasks.filter(t => t.assigned_to_all
            || t.assigned_to.find((ta) => Boolean(__newHelper.user_id && ta.user_id === __newHelper.user_id)));

        const taskLabel = afterCareAssignedTasks.length === 1 ? 'After-Care Task' : 'After-Care Tasks';

        const isFormTypeAdmin: boolean = formType === 'INVITE_ADMIN';
        const subHeaderText = `${isFormTypeAdmin && 'Admins' || 'Guest'}
            ${isFormTypeAdmin && 'can view ALL' || 'can only view assigned'} after-care tasks`;
        return (
            <Grid item xs={12} className={classes.rightSection}>

                <Grid item className={classes.inner}>
                    <Typography color="secondary" className={classes.header}>
                        {__newHelper.fname} is assigned&nbsp;
                        <span className={classes.colorGreen}>
                            {(afterCareAssignedTasks || []).length} {taskLabel}
                        </span>
                    </Typography>
                    <Typography color="secondary" className={classes.subHeader}>
                        {subHeaderText}
                    </Typography>
                </Grid>

                {afterCareTasks.map((t, i) =>
                    <TaskRow
                        {...props}
                        key={i}
                        task={t}
                        canAssign={isFHorGOM || t.can_reassign_by_family}
                        newHelper={__newHelper}
                        openTaskAssignmentWidget={e => openAssignmentWidget(e, t)}
                        toggleTaskAssignment={(isAssignedToHelper) =>
                            toggleTaskAssignment(isAssignedToHelper, __newHelper, t)}
                    />
                )}

                <Button
                    color="primary"
                    className={classes.createNewTaskButton}
                    // onClick={e => this.setState({ isAddNewTaskDialogOpen: true, createNewAfterTask: true })}
                    onClick={e => handleSaveTask('after-care')}
                >
                    CREATE NEW AFTER-CARE TASK
                </Button>
            </Grid>
        );
    };

    const handleChangeTask = (id: number, taskChanges: TaskUpdateRequestUX) => {
        setActiveTask(prev => prev && { ...prev, ...taskChanges } || null);
    };

    const renderAssignmentWidget = () => {
        if (!activeTask) {
            return null;
        }

        return (
            <AssignTask
                zIndex={zIndex + 1}
                task={activeTask}
                activeCase={activeCase}
                showAssignTaskLink
                closeAssignmentWidget={() => closeAssignmentWidget()}
                assignmentWidgetAnchorEle={assignmentWidgetAnchorEle}
                resetInvitationForm={resetInvitationForm}
                onChangeTask={handleChangeTask}
            />
        );
    };

    const renderSaveTaskDialog = () => {
        let taskType = newTaskType;
        if (!taskType) {
            taskType = 'standard';
        }

        return (
            <SaveTaskDialog
                key={activeTask ? activeTask.id : taskType}
                existing={activeTask}
                activeCase={activeCase}
                type={taskType}
                zIndex={zIndex + 1}
                isOpen={isSaveTaskDialogOpen}
                closeDialog={() => setIsSaveTaskDialogOpen(false)}
            />
        );
    };

    const renderNameInitialsAvatar = (_assignedTasks: CaseTaskUX[], _activeEntityId: number) => {
        const showOverlapDot = entityRole === EntityCaseRole.admin;

        return (
            <div className={classes.avatarContainer}>
                {showOverlapDot && <span className={classes.overlapDot} style={{ top: 12, left: 4 }} />}
                <div className={classes.badgeContainer}>
                    <Badge
                        badgeContent={_assignedTasks.length}
                        classes={{ badge: classes.badge }}
                        invisible={_assignedTasks.length === 0}
                        max={99}
                    >
                        {''}
                    </Badge>
                </div>
                <Avatar
                    className={classNames(classes.nameInitialsAvatar)}
                    onClick={(e) => openHelperPopper(e, _activeEntityId)}
                >
                    {generateNameInitials(newHelper.fname, newHelper.lname)}
                </Avatar>
            </div>
        );
    };

    const renderHelperPopper = () => {

        return (
            <HelperPopper
                key={activeEntityId}
                zIndex={zIndex + 2}
                clickAwayListener={clickAwayListener}
                selectedCase={activeCase}
                activeEntityId={activeEntityId}
                popperAnchorEle={popperAnchorEle}
                closeHelperPopper={() => closeHelperPopper()}
                closeParentDialog={closeParentDialog}
            />
        );
    };


    const openHelperPopper = (
        e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        __activeEntityId: number
    ) => {
        setActiveEntityId(__activeEntityId);
        setPopperAnchorEle(e.currentTarget);
    };

    const clickAwayListener = () => {
        setPopperAnchorEle(null);
    };

    const closeHelperPopper = () => {
        setPopperAnchorEle(null);
    };

    const toggleTaskAssignment = (isAssignedToHelper: boolean, helper: EntitySummary, task: CaseTaskUX) => {
        const taskAssignee = userToTaskAssignee(helper, userData);
        if (!taskAssignee) {
            return;
        }

        let changes: TaskUpdateRequestUX;
        if (isAssignedToHelper && task.assigned_to_all) {
            // can only have one or the other so this should not be allowed
            return;
        } else if (isAssignedToHelper) { // task.assigned_to_all === false
            changes = {
                assigned_to: [...task.assigned_to, taskAssignee]
            };
        } else if (!isAssignedToHelper && task.assigned_to_all) {
            // if the task was previously assigned to all, but now we want to exclude this
            // helper, we must remove the target helper and explicily add all the rest of 
            // the helpers.
            changes = {
                assigned_to_all: false,
                assigned_to: helpers.filter(h => h.user_id && h.user_id !== taskAssignee.user_id)
                    .map((h) => userToTaskAssignee(h, userData))
                    .filter((ta): ta is TaskAssignee => ta !== null),
            };
        } else { // isAssignedToHelper === false && task.assigned_to_all === false
            // This is the case was prevously assigned to a list of users, of which 
            // this helper was a part of, we can remove the user and they would no 
            // longer be able to see this task.
            changes = {
                assigned_to: task.assigned_to.filter((ta) => ta.user_id !== taskAssignee.user_id)
            };
        }
        dispatch(updateTask(task.id, changes, activeCase.uuid, null));
    };

    const openAssignmentWidget = (e: React.MouseEvent<HTMLElement>, task: CaseTaskUX) => {
        setAssignmentWidgetAnchorEle(e.currentTarget);
        setActiveTask(task);
    };

    const closeAssignmentWidget = () => {
        setAssignmentWidgetAnchorEle(null);
        setActiveTask(null);
    };

    const handleSaveTask = (_newTaskType: 'standard' | 'after-care') => {
        setNewTaskType(_newTaskType);
        setIsSaveTaskDialogOpen(true);
    };

    const updateQRPermissions = ({ can_view_belongings, can_view_tracking_steps }: UpdateRequest) => {

        if (caseEntity.case_role === EntityCaseRole.visitor) {
            return;
        }

        const entityToBeUpdated: CaseHelperUpdateRequest = {
            fname: newHelper.fname,
            mname: newHelper.mname,
            lname: newHelper.lname,
            email: newHelper.email,
            phone: newHelper.phone,
            home_phone: null,
            work_phone: null,
            relationship_type: caseEntity.relationship_type,
            relationship: caseEntity.relationship,
            relationship_alias: caseEntity.relationship_alias,
            home_address: newHelper.home_address,
            case_role: EntityCaseRole[caseEntity.case_role],
            state_license_number: newHelper.state_license_number,
            use_address_description: newHelper.use_address_description || false,
            can_view_belongings: can_view_belongings ?? canViewCaseBelongings,
            can_view_tracking_steps: can_view_tracking_steps ?? canViewQRTracking,
            partner_case_entity_id: caseEntity.partner_case_entity_id,
            case_entity_subscription_updates: [],
            case_entity_subscription_creates: [],
        };

        dispatch(updateCaseHelper({
            gatherCase: activeCase,
            existingEntity: newHelper,
            changes: entityToBeUpdated,
        }));
    };

    const invitedTime = moment(caseEntity.created_time).fromNow();
    const assignedTasks = checklistTasks.filter(t => t.assigned_to_all
        || t.assigned_to.find((ta) => Boolean(newHelper.user_id && ta.user_id === newHelper.user_id)));

    const _canUserEditHelper = canUserEditHelper();

    return (

        <Grid item className={classes.root}>
            {renderNameInitialsAvatar(assignedTasks, newHelper.entity_id)}
            <Typography color="secondary" className={classes.topHeading}>
                {joinNameParts(newHelper)} was invited {invitedTime}
            </Typography>
            {newHelper.phone && <Typography color="secondary" className={classes.sentDetails}>
                Text message sent to&nbsp;
                <span
                    className={_canUserEditHelper ? classes.clickableText : ''}
                    onClick={editHelper}
                >
                    {getFormattedPhoneNumber(newHelper.phone)}
                </span>
            </Typography>}

            {newHelper.email && <Typography color="secondary" className={classes.sentDetails}>
                Email sent to&nbsp;
                <span
                    className={_canUserEditHelper ? classes.clickableText : ''}
                    onClick={editHelper}
                >
                    {newHelper.email}
                </span>
            </Typography>}

            {newHelper.home_address && <Typography color="secondary" className={classes.sentDetails}>
                <span
                    className={_canUserEditHelper ? classes.clickableText : ''}
                    onClick={editHelper}
                >
                    {newHelper.home_address.address1}&nbsp;
                    {newHelper.home_address.address2}&nbsp;
                    {newHelper.home_address.city}&nbsp;
                    {newHelper.home_address.state}&nbsp;
                    {newHelper.home_address.country}&nbsp;
                </span>
            </Typography>}

            {isFHorGOM &&
                <WhenEnabled feature={FeatureKey.KEEP_TRACK}>
                    <QRPermissionsForHelpers
                        zIndex={zIndex + 1}
                        fhName={activeCase.funeral_home.name}
                        helperFname={newHelper.fname}
                        canViewQRTracking={canViewQRTracking}
                        setCanViewQRTracking={(c) => {
                            setCanViewQRTracking(c);
                            updateQRPermissions({ can_view_tracking_steps: c });
                        }}
                        canViewCaseBelongings={canViewCaseBelongings}
                        setCanViewCaseBelongings={(c) => {
                            setCanViewCaseBelongings(c);
                            updateQRPermissions({ can_view_belongings: c });
                        }}
                    />
                </WhenEnabled>
            }

            <Grid item xs={12} className={classes.mainContainer}>
                <WhenCanAssignTask target={newHelper}>
                    {renderLeftSection(newHelper)}
                </WhenCanAssignTask>
                <div className={classes.divider}>
                    <div className={classes.inner} />
                </div>
                <WhenCanAssignTask target={newHelper}>
                    {renderRightSection(newHelper)}
                </WhenCanAssignTask>
            </Grid>

            {context !== HelperInvitationDialogContext.payment &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.inviteHelperBtn}
                    onClick={e => isDCEntity ? closeParentDialog() : resetInvitationForm()}
                >
                    {isDCEntity && 'Done'}
                    {!isDCEntity && formType === 'INVITE_ADMIN' && 'invite another admin'}
                    {!isDCEntity && formType === 'INVITE_GUEST' && 'invite another guest'}
                </Button>
            }

            {renderAssignmentWidget()}
            {renderSaveTaskDialog()}
            {renderHelperPopper()}
        </Grid>
    );
};

export default InvitationSuccess;