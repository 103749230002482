import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import FileCopy from '@mui/icons-material/FileCopy';

import { UserRoles } from '../../../../../shared/types';

import { NULL_STRING } from '../../../../../constants';

import { useTaskDialogStyles } from '../../TaskDialogsStyles';

import {
    getFullNameFromCase,
    getFullNameFromUser,
    getFormattedPhoneNumber,
    emptyStringParser
} from '../../../../../services';
import CanNotLoginWrapper from '../widgets/CanNotLoginWrapper';
import { CanNotLoginProps } from '../utils';

const CanNotLogin = (props: CanNotLoginProps) => {
    const {
        activeCase,
        userData,
        onStepChange,
        setAppSnackbar
    } = props;

    const classes = useTaskDialogStyles();

    const copyEmailBody = () => {
        setAppSnackbar('Email copied to your clipboard', 'success');
    };

    const renderEmailBody = (
        _birthDate: string,
        _caseZipCode: string,
        _helperName: string,
        _helperEmail: string,
        _helperPhone: string
    ): string => {
        return `Dear Netflix Legal Team,

${getFullNameFromCase(activeCase)}, a Netflix customer, recently passed away and we need to delete their ` +
            `Netflix account and cancel any pending subscriptions, effective immediately.` +
            `The required information is listed below.

- Account holder's name: ${getFullNameFromCase(activeCase)}
- ${activeCase.fname}'s Birth Date: ${_birthDate}
- ${activeCase.fname}'s Zip Code: ${_caseZipCode}
- ${activeCase.fname}'s email: ${NULL_STRING}
- My name: ${_helperName}
- My email: ${_helperEmail}
- My phone number: ${_helperPhone}

Please let me know via email or phone regarding any additional information you may need.

Thanks for your help in this important matter,
${_helperName}`;
    };

    const isFhUser: boolean = UserRoles.isFHUser(userData);

    const netflixEmail: string = 'legal@netflix.com';
    const emailSubject: string = `Delete ${getFullNameFromCase(activeCase)}'s Netflix Account`;

    const birthDate = activeCase.dob_date !== '' ? activeCase.dob_date : NULL_STRING;

    const caseZipCode: string = activeCase.death_certificate
        && activeCase.death_certificate.life.residencePlace.postalCode
        && emptyStringParser(activeCase.death_certificate.life.residencePlace.postalCode)
        ? activeCase.death_certificate.life.residencePlace.postalCode : NULL_STRING;

    const helperName: string = userData ? getFullNameFromUser(userData) : NULL_STRING;
    const helperEmail: string = userData && userData.email || NULL_STRING;

    let helperPhone: string = NULL_STRING;
    if (userData && userData.phone) {
        const phoneNumber = getFormattedPhoneNumber(userData.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    } else if (isFhUser && activeCase.funeral_home.phone) {
        const phoneNumber = getFormattedPhoneNumber(activeCase.funeral_home.phone);
        helperPhone = phoneNumber ? phoneNumber : NULL_STRING;
    }

    const emailBody: string = renderEmailBody(
        birthDate || '',
        caseZipCode,
        helperName,
        helperEmail,
        helperPhone
    );

    const tooltipText = `This will copy this email to your device clipboard so that you can paste it 
                            in another program.`;

    return (
        <CanNotLoginWrapper onReturnClick={onStepChange}>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize28,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="center"
            >
                Send an Email to Netflix
            </Typography>
            <Typography
                color="secondary"
                className={classNames(
                    classes.fontSize18,
                    classes.fontWeight300,
                    classes.marginTop10
                )}
                align="left"
            >
                It looks like you won't be able to log in to {activeCase.fname}'s Netflix account. No worries,
                we can still help. You will need to send an email to
                <span className={classes.fontWeight500}>
                    &nbsp;legal@netflix.com
                </span>.
                We wanted to make this as easy as possible for you so we went ahead and typed up the email
                with as much of the information as we could fill out.
            </Typography>

            <Grid
                item
                xs={12}
                md={10}
                className={classNames(
                    classes.colorPrimary,
                    classes.b_solid_1,
                    classes.marginAuto,
                    classes.textLeft,
                    classes.padding10,
                    classes.marginTop20
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300
                    )}
                >
                    Dear Netflix Legal Team,
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    {getFullNameFromCase(activeCase)}, a Netflix customer, recently passed away and we
                    need to delete their Netflix account and cancel any pending subscriptions, effective
                    immediately. The required information is listed below.
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        - Account holder's name: <span className={classes.fontWeight500}>
                            {getFullNameFromCase(activeCase)} </span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}'s Birth Date: <span className={classes.fontWeight500}>
                            {birthDate}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}'s Zip Code: <span className={classes.fontWeight500}>
                            {caseZipCode}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - {activeCase.fname}'s email: {NULL_STRING}
                    </span>
                    <span className={classes.displayBlock}>
                        - My name: <span className={classes.fontWeight500}>{helperName}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My email: <span className={classes.fontWeight500}>{helperEmail}</span>
                    </span>
                    <span className={classes.displayBlock}>
                        - My phone number: <span className={classes.fontWeight500}>{helperPhone}</span>
                    </span>
                </Typography>

                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                        classes.marginTop20
                    )}
                >
                    <span className={classes.displayBlock}>
                        Please let me know via email or phone regarding any additional information you may need.
                    </span>

                    <span
                        className={classNames(
                            classes.displayBlock,
                            classes.marginTop20
                        )}
                    >
                        Thanks for your help in this important matter,
                        <span className={classes.displayBlock}>
                            {`${userData ? userData.fname : ''} 
                                ${userData ? userData.lname : ''}`}
                        </span>
                    </span>
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={8}
                className={classNames(
                    classes.marginAuto
                )}
            >
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize16,
                        classes.fontWeight300,
                        classes.marginTop12
                    )}
                >
                    Clicking below will open your email provider and allow you to edit and send this email.
                </Typography>
                <a
                    href={`mailto:${netflixEmail}?` +
                        `subject=${emailSubject}&body=` + encodeURIComponent(`${emailBody}`)}
                    className={classNames(
                        classes.cursorPointer,
                        classes.textDecorationNone,
                    )}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classNames(
                            classes.button,

                        )}
                    >
                        Click To Edit &amp; Send
                    </Button>
                </a>
                <Typography
                    color="secondary"
                    className={classNames(
                        classes.fontSize18,
                        classes.fontWeight300,
                    )}
                >
                    or...
                </Typography>
                <Tooltip
                    title={tooltipText}
                    enterDelay={1200}
                    placement="top"
                    classes={{
                        tooltip: classes.fontSize12
                    }}
                    className={classes.button}
                >
                    <div>
                        <CopyToClipboard text={emailBody}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={copyEmailBody}
                            >
                                <FileCopy />&nbsp;Copy this email
                            </Button>
                        </CopyToClipboard>
                    </div>
                </Tooltip>
            </Grid>
        </CanNotLoginWrapper>
    );
};

export default CanNotLogin;