import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { useTaskDialogStyles } from "../TaskDialogsStyles";
import FuneralReimbursementLogo from "./FuneralReimbursementLogo";
import UndoIcon from "@mui/icons-material/Undo";
import { PropsWithChildren } from "react";

interface Props {
    goBack(): void;
}

const NotQualified = (props: PropsWithChildren<Props>) => {
    const { children, goBack } = props;

    const classes = useTaskDialogStyles();

    return (
        <Grid item xs={12}>
            <Grid
                item
                xs={12}
                sm={10}
                className={classNames(
                    classes.marginAuto,
                    classes.textCenter,
                    classes.marginBottom20,
                    classes.secondStateMainGrid
                )}
            >
                <FuneralReimbursementLogo />

                <Grid>
                    <Typography
                        align="center"
                        color="secondary"
                        className={classNames(
                            classes.marginBottom10,
                            classes.marginTop20,
                            classes.fontSize16,
                            classes.fontWeight300
                        )}
                    >
                        <span
                            className={classes.cursorPointer}
                            onClick={() => goBack()}
                        >
                            <IconButton color="primary" size="large">
                                <UndoIcon />
                            </IconButton>
                            Return to previous question
                        </span>
                    </Typography>
                    <Typography
                        align="center"
                        color="secondary"
                        className={classNames(
                            classes.fontSize28,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        It looks like you may not qualify
                    </Typography>
                    <Typography
                        color="secondary"
                        align="left"
                        className={classNames(
                            classes.fontSize18,
                            classes.fontWeight300,
                            classes.marginTop10
                        )}
                    >
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NotQualified;