import { Fragment } from 'react';
import classNames from 'classnames';
import values from 'lodash/values';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import makeGStyles from '../../../../../styles/makeGStyles';
import { CaseBelongingDecisionType } from '../../../../../shared/types';
import { convertHexToRGBA } from '../../../../../services/app.service';
import { YesNoUnknownEnum } from '../../../../../shared/types';
import QuestionText from '../completeStepDialog/QuestionText';
import { getDecisionIcon, getDecisionText } from './AddCaseBelonging.dialog';
import GToggleButtons from '../../../../common/GToggleButtons';
import { getIntercomTargetProp } from '../../../../../services';

const useStyles = makeGStyles(theme => ({
    root: {},
    explainerText: {
        fontSize: 10,
        lineHeight: 1.25,
        width: 'fit-content',
        margin: '2px auto 4px',
        border: '1px solid',
        borderRadius: 8,
        padding: '4px 8px',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        '@media (min-width: 360px)': {
            fontSize: 12
        }
    },
    formControl: {
        width: 'fit-content',
        marginLeft: -4,
    },
    radio: {
        height: 32,
        width: 32
    },
    radioLabel: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        lineHeight: 1,
        '& svg, span': {
            fontSize: 20,
            marginRight: 2,
            color: 'inherit',
        },
    },
    textBelowChecked: {
        minHeight: 50,
        padding: '8px 12px',
        margin: '0 20px 0 32px',
        borderRadius: '0 0 8px 8px',
        border: `1px dashed ${theme.palette.primary.main}`,
        background: convertHexToRGBA(theme.palette.primary.main, 0.08),
        '& p': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    toggleButton: {
        height: 28,
    },
    toggleButtonGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        fontSize: 16,
        fontWeight: 500,
        paddingBottom: 4
    },
    selected: {}
}), { name: 'CaseBelongingDecision' });

interface Props {
    itemName: string;
    caseFirstName: string;
    decision: CaseBelongingDecisionType | null;
    isDecisionInvalid: boolean;
    toggleCheck: YesNoUnknownEnum;
    handleChangeItemDecision: (decision: CaseBelongingDecisionType) => void;
    handleToggleButton: (value: YesNoUnknownEnum) => void;
}

const CaseBelongingDecision = (props: Props) => {
    const {
        decision,
        toggleCheck,
        caseFirstName,
        isDecisionInvalid,
        handleToggleButton,
        handleChangeItemDecision
    } = props;
    const classes = useStyles();

    const renderSwitch = (value: CaseBelongingDecisionType) => {
        const DecisionIcon = getDecisionIcon(value);
        return (
            <FormControlLabel
                key={value}
                value={value}
                control={<Radio color="primary" className={classes.radio} />}
                label={
                    <>
                        {DecisionIcon}
                        {getDecisionText(caseFirstName, CaseBelongingDecisionType[value])}
                    </>
                }
                classes={{
                    label: classNames(
                        classes.radioLabel,
                        CaseBelongingDecisionType[value] === decision && classes.colorPrimary
                    )
                }}
                className={classes.formControl}
            />
        );
    };

    return (
        <Grid
            item
            xs={12}
            {...getIntercomTargetProp(`TrackingPage-AddBelonging-ItemDecisionRadioGroup`)}
        >
            <Typography
                color="secondary"
                align="center"
                className={classNames(
                    classes.content,
                    isDecisionInvalid && classes.colorRed
                )}
            >
                What does the family want to do with this item? *
            </Typography>

            <RadioGroup
                value={decision || ''}
                onChange={(_, v: string) => {
                    if (CaseBelongingDecisionType[v]) {
                        handleChangeItemDecision(CaseBelongingDecisionType[v]);
                    }
                }}
            >
                {values(CaseBelongingDecisionType).map(value => (
                    <Fragment key={value}>
                        {renderSwitch(value)}
                        {decision === value &&
                            <div
                                className={classNames(
                                    classes.textBelowChecked,
                                    value === CaseBelongingDecisionType.unsure && classes.flexCentred)
                                }
                            >
                                <QuestionText
                                    switchValue={CaseBelongingDecisionType[value]}
                                    caseFirstName={caseFirstName}
                                />
                                {value !== CaseBelongingDecisionType.unsure &&
                                    <GToggleButtons
                                        className={classes.toggleButtonGroup}
                                        selected={YesNoUnknownEnum[toggleCheck]}
                                        onSelect={handleToggleButton}
                                        buttons={[{
                                            value: YesNoUnknownEnum.Yes,
                                            label: YesNoUnknownEnum.Yes,
                                            className: classes.toggleButton
                                        }, {
                                            value: YesNoUnknownEnum.No,
                                            label: 'Not Yet',
                                            className: classes.toggleButton
                                        }]}
                                    />
                                }
                            </div>}
                    </Fragment>
                ))}

            </RadioGroup>
        </Grid>
    );
};

export default CaseBelongingDecision;
