import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import Tooltip from '@mui/material/Tooltip';

import Edit from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import PagesIcon from '@mui/icons-material/Pages';
import PublicIcon from '@mui/icons-material/Public';

import { getPhotoUrl } from '../../../../services';
import {
    getDisplayPrice,
    getVariablePriceDisplay,
    getProductQuantity,
} from '../../../../shared/goods_and_services/pricing';
import {
    ProductUX,
    PricingModelEnum,
    ProductContractItemUX,
    ProductItem,
    GPLContext,
    FeatureKey,
} from '../../../../shared/types';

import IncreaseDecreaseTextField from './IncreaseDecreaseTextField';
import ProductItemCardBase, { CardBadge } from './ProductItemCardBase';
import Badge from './Badge';
import PriceWidget from './PriceWidget';
import AllowancePopper, { AllowanceSummary } from './AllowancePopper';
import { getContractItemName } from '../../../../shared/goods_and_services/utils';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Permission } from '../../../../shared/types/permissions';
import { TOOLTIPS } from '../../../../constants';
import { useCallback, useMemo, useState } from 'react';
import useFeatureEnabled from '../../../common/hooks/useFeatureEnabled';
import { usePermissionEnabled } from '../../../common/hooks/usePermissionEnabled';

interface Props {
    canUserEditContract: boolean;
    productItem: ProductItem;
    caseFirstName: string;
    allowances?: AllowanceSummary[];
    isFeatured?: boolean;
    isSelectedProduct?: boolean;
    disabled?: boolean;
    size?: 'xxlargeWide' | 'xsmall' | 'auto';
    isShowPrices: boolean;
    context: GPLContext;
    hasTooltip?: boolean;
    onClick: () => void;
    onAddContractItem?: (product: ProductUX, quantity: number, allowanceItemId?: string) => void;
    onUpdateQuantity?: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    onEditClick?: (productId: number) => void;
    measure?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    heading: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 20,
        fontWeight: 200,
        lineHeight: '1.25em',
        paddingTop: 4,
        paddingBottom: 4
    },
    imageContainer: {
        height: 'auto',
        width: 280,
        '@media (min-width: 360px)': {
            width: 300,
        },
    },
    rate: {
        textAlign: 'center',
        fontWeight: 200,
        fontSize: 12,
        lineHeight: '1.25em'
    },
    blankSpace: {
        height: 54
    },
    priceDivider: {
        color: 'inherit',
        width: 280,
        margin: 'auto',
        marginBottom: 4,
        borderBottom: '1px solid'
    },
    cardWidthoutImage: {
        padding: '20px 0',
        '& p': {
            paddingBottom: 0
        }
    },
    productTitle: {
        textAlign: 'center',
        fontSize: 12,
        display: 'flex',
        height: 88,
        paddingLeft: 10,
        alignItems: 'center',
        width: 126,
        '& span': {
            overflow: 'hidden',
            position: 'relative',
            lineHeight: '1.2em',
            maxHeight: '56px',
            paddingRight: 7,
            width: '100%',
            '&:before': {
                content: '"..."',
                position: 'absolute',
                right: 0,
                bottom: 0
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                right: 0,
                width: '1em',
                height: '1em',
                marginTop: '0.2em',
                background: 'white'
            }
        }
    },
    onlyTitle: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        width: 142,
        height: 88,
        background: theme.palette.common.white,
        color: theme.palette.secondary.main,
    },
    noImageWithVariable: {
        padding: '48px 0 0',
        '& p': {
            paddingBottom: 0
        }
    },
    li: {
        margin: '0 auto !important',
        overflowY: 'unset !important' as 'unset',
    },
    featuredTitle: {
        width: '100%',
        height: 185,
        fontSize: 20,
        lineHeight: '20px',
        background: theme.palette.common.white
    },
    contractFeatured: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    hidden: {
        opacity: 0.5,
    },
    marginTop32: {
        marginTop: 32
    },
    verticalAlignMiddle: {
        verticalAlign: 'middle'
    },
    isSelectedProduct: {},
}), { name: 'ProductItemCard' });

const ProductItemCard = (props: Props) => {
    const {
        caseFirstName,
        productItem,
        onClick,
        onAddContractItem,
        onUpdateQuantity,
        onRemoveContractItem,
        isFeatured,
        size,
        isSelectedProduct,
        isShowPrices,
        disabled,
        canUserEditContract,
        allowances = [],
        context,
        onEditClick,
        hasTooltip,
        measure,
    } = props;

    const classes = useStyles();

    const isGPLFeatureEnabled = useFeatureEnabled(FeatureKey.PUBLIC_GPL);

    const [allowancePopperAnchor, setAllowancePopperAnchor] = useState<HTMLElement | null>(null);
    const [allowancePopperOffset, setAllowancePopperOffset] = useState(100);

    const isBackOffice = context === GPLContext.BackOffice;

    const { product, contractItem } = productItem;

    const quantity = getProductQuantity(product, contractItem);

    const isAddRemoveItemEnabled = usePermissionEnabled(contractItem
        ? Permission.REMOVE_ITEMS
        : Permission.ADD_ITEMS
    );

    const addItem = useCallback((allowanceItemId?: string) => {
        closeAllowancePopper();
        if (!product) {
            return;
        }
        const _quantity = product.base_quantity || product.var_default_quantity || 1;
        if (onAddContractItem) {
            onAddContractItem(product, _quantity, allowanceItemId);
        }
    }, [onAddContractItem, product]);

    // Allowance popper functions start
    const openAllowancePopper = useCallback((event: React.MouseEvent<HTMLElement>) => {
        if (allowancePopperAnchor && event.currentTarget === allowancePopperAnchor) {
            closeAllowancePopper();
            return;
        }
        const clientRects: DOMRectList = event.currentTarget.getClientRects();
        const leftOffset: number = Math.ceil(clientRects[0].left) - 8;
        const defaultLeftOffset: number = 100;
        setAllowancePopperOffset(leftOffset > 100 ? defaultLeftOffset : leftOffset);
        setAllowancePopperAnchor(event.currentTarget);
    }, [allowancePopperAnchor]);

    const clickAwayAllowanceListener = (event: MouseEvent | TouchEvent) => {
        const currentTarget = event.target;
        if (currentTarget !== allowancePopperAnchor) {
            closeAllowancePopper();
        }
    };

    const closeAllowancePopper = () => {
        setAllowancePopperAnchor(null);
    };

    const isVariablePrice = useMemo(() => {
        return product && (product.pricing_model === PricingModelEnum.base_plus_variable
            || product.pricing_model === PricingModelEnum.variable);
    }, [product]);

    const isManualPrice = useMemo(() => {
        return product && product.pricing_model === PricingModelEnum.manual;
    }, [product]);

    const handleQuantityChange = (newQuantity: number) => {
        if (!product) {
            return;
        }

        if (!contractItem && onAddContractItem) {
            // if quantity is changed and product hasn't been added to the contract -> add it
            onAddContractItem(product, newQuantity);
        } else if (contractItem && onUpdateQuantity) {
            onUpdateQuantity(contractItem, newQuantity);
        }
    };

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        if (isBackOffice) {
            return product && onEditClick && onEditClick(product.id);
        }
        if (contractItem && onRemoveContractItem) {
            onRemoveContractItem(contractItem);
        } else if (product) {
            if (allowances.length > 0) {
                openAllowancePopper(event);
            } else {
                addItem();
            }
        }
    }, [
        addItem,
        allowances.length,
        contractItem,
        isBackOffice,
        onEditClick,
        onRemoveContractItem,
        openAllowancePopper,
        product
    ]);

    const badges = useMemo((): CardBadge[] | undefined => {
        if (disabled) {
            return undefined;
        }
        if (isBackOffice) {
            const backOfficeBadges: CardBadge[] = [{
                position: 'topEnd',
                badge: <Badge
                    color="orange"
                    variant="extended"
                    label="Edit"
                    icon={{ root: <Edit /> }}
                />,
                tooltipTitle: 'Click to edit product details',
                onClick: handleClick,
            }];

            if (isGPLFeatureEnabled && product && product.show_on_website) {
                backOfficeBadges.push({
                    position: 'topStart',
                    badge: <Badge
                        color={product.show_price_on_website ? 'green' : 'orangeInverted'}
                        variant="round"
                        icon={{ root: <PublicIcon />, size: 36 }}
                    />,
                    tooltipTitle: `An orange icon indicates that this product is synced to your 
                            website. Green indicates that it is synced AND that the price is visible on your 
                            website.`,
                    tooltipEnterDelay: 200,
                    onClick: handleClick,
                });
            }
            return backOfficeBadges;
        }
        const isIncludedInPackage = Boolean(contractItem && contractItem.package_id);
        const _badges: CardBadge[] = [{
            position: 'topEnd',
            badge: <Badge
                color={contractItem ? 'inverted' : 'primary'}
                variant={contractItem ? 'round' : 'extended'}
                label={contractItem ? undefined : 'Add'}
                icon={{ root: contractItem ? <RemoveIcon /> : <AddIcon /> }}
            />,
            tooltipTitle: isAddRemoveItemEnabled
                ? `${contractItem ? 'Remove from' : '+ Add to'} ${caseFirstName}'s statement`
                : TOOLTIPS.ACCESS_DISABLED,
            onClick: isAddRemoveItemEnabled ? handleClick : undefined,
        }];

        // remove the badge if any of the conditions matches
        if (!canUserEditContract) {
            _badges.splice(0, 1);
        }

        if (isSelectedProduct) {
            return _badges;
        }

        if (contractItem) {
            _badges.push({
                position: 'topStart',
                badge: <Badge
                    color="inverted"
                    variant="extended"
                    label="Added"
                    icon={{
                        root: <DoneIcon />,
                        position: 'precedent'
                    }}
                    size="small"
                />,
            });
        }
        if (isIncludedInPackage) {
            _badges.push({
                position: 'topStart',
                badge: <Badge
                    color="inverted"
                    variant="extended"
                    label="Included in Package"
                    icon={{
                        root: <PagesIcon />,
                        position: 'precedent'
                    }}
                    size="small"
                />,
                size: 'small'
            });
        }
        return _badges;
    }, [
        canUserEditContract,
        caseFirstName,
        contractItem,
        disabled,
        handleClick,
        isAddRemoveItemEnabled,
        isBackOffice,
        isGPLFeatureEnabled,
        isSelectedProduct,
        product
    ]);

    const renderOutsideButtonContent = () => {
        if (!product || !isVariablePrice) {
            return undefined;
        }

        const variableUnitsStr = `Total ${product.var_increment_units || ''}${product.var_increment_units &&
            !product.var_increment_units.endsWith('s') ? 's' : ''}`;

        return (
            <IncreaseDecreaseTextField
                canUserEditContract={canUserEditContract}
                helperText={variableUnitsStr}
                counter={quantity}
                onCounterChange={handleQuantityChange}
                min={product.base_quantity || undefined}
                max={product.var_max_quantity || undefined}
                disabled={isBackOffice || !isAddRemoveItemEnabled}
            />
        );
    };

    const totalPrice = getDisplayPrice(product, contractItem);
    const variablePricing = product ? getVariablePriceDisplay(product) : undefined;
    const name = getContractItemName(productItem);
    const publicId = product ? product.photos[0] : undefined;

    const transformations = [{
        width: 300,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    }];

    const tooltipTitle = hasTooltip ? product && product.name : '';

    const showPrices = isShowPrices || Boolean(context === GPLContext.Public
        && product
        && product.show_price_on_website
    );

    return (
        <>
            <ImageListItem
                key={productItem.key}
                className={classNames(
                    classes.li,
                    isBackOffice && productItem.product && productItem.product.is_hidden && classes.hidden
                )}
            >
                <ProductItemCardBase
                    disabled={disabled}
                    disableFocus
                    color={contractItem && !disabled ? 'primary' : isBackOffice ? 'dashed' : 'inverted'}
                    size={size ? size : isVariablePrice ? showPrices ? 'withPrice' : 'noPrice'
                        : showPrices ? 'withPriceFlex' : 'noPriceFlex'}
                    onClick={onClick}
                    badges={badges}
                >
                    <div>
                        {publicId &&
                            <Tooltip
                                id="tooltip-productName"
                                title={tooltipTitle || ''}
                                placement="top"
                                enterDelay={400}
                            >
                                <img
                                    className={classNames(
                                        classes.imageContainer,
                                        isSelectedProduct && classes.isSelectedProduct,
                                        isFeatured && classes.verticalAlignMiddle
                                    )}
                                    src={getPhotoUrl(publicId, transformations)}
                                    onLoad={e => measure ? measure() : undefined}
                                />
                            </Tooltip>
                        }

                        {!publicId && (isSelectedProduct || isFeatured) &&
                            <Typography
                                className={classNames(
                                    classes.onlyTitle,
                                    isFeatured && classes.featuredTitle,
                                    isFeatured && contractItem && classes.contractFeatured
                                )}>
                                {name}
                            </Typography>
                        }

                        {(!isFeatured && !isSelectedProduct) &&
                            <div className={classNames(
                                !publicId && classes.cardWidthoutImage,
                                !publicId && isVariablePrice && classes.noImageWithVariable
                            )}>
                                <div>
                                    <Typography color="inherit" className={classes.heading}>
                                        {name}
                                    </Typography>

                                    {showPrices && (totalPrice || isManualPrice) &&
                                        <PriceWidget
                                            priceStr={totalPrice || 'Specify Price'}
                                            size={'medium'}
                                        />
                                    }
                                </div>
                                <div className={classes.marginTop32}>
                                    {variablePricing && <div className={classes.priceDivider} />}

                                    {showPrices && variablePricing &&
                                        <Typography color="inherit" className={classes.rate}>
                                            {variablePricing}
                                        </Typography>
                                    }

                                    {renderOutsideButtonContent()}
                                </div>
                            </div>
                        }
                    </div>
                </ProductItemCardBase>
            </ImageListItem>

            {allowances.length > 0 &&
                <AllowancePopper
                    popperAnchorEle={allowancePopperAnchor}
                    popperLeftOffset={allowancePopperOffset}
                    clickAwayListener={clickAwayAllowanceListener}
                    allowances={allowances}
                    addItem={addItem}
                    zIndex={1320}
                />
            }
        </>
    );
};

export default ProductItemCard;
