import classNames from 'classnames';

import UserAvatar from '../../common/UserAvatar';
import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PeopleIcon from '@mui/icons-material/People';
import { AssignStyleProps, styleWrapper } from '../Style';
import { AvatarUser } from '../../../shared/types';
import withStyles from '@mui/styles/withStyles';

type SizeType = 'small' | 'medium' | 'large';

interface Props {
    assignToAll?: boolean;
    user: AvatarUser | null;
    size?: SizeType;
    isNonUser?: boolean;
    onAvatarClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
    isAdmin: boolean;
}

const BaseAssignAvatar = (props: Props & AssignStyleProps) => {
    const { assignToAll, user, classes, size, isNonUser, onAvatarClick, isAdmin } = props;
    const className: string | undefined = classNames(
        classes.userAvatar,
        classes[size || 'medium'],
        isNonUser && classes.nonUserAvatar,
    );

    if (isNonUser) {
        return (
            <Avatar className={className}>
                <PersonAddDisabledIcon />
            </Avatar>
        );
    }

    if (assignToAll) {
        return (
            <Avatar className={className}>
                <PeopleIcon />
            </Avatar>
        );
    } else if (!user) {
        return (
            <Avatar className={className}>
                <PersonOutlineIcon />
            </Avatar>
        );
    }

    return (
        <UserAvatar
            user={user}
            className={className}
            overlapDotClass={classNames(classes.overlapDot, classes[size || 'medium'])}
            showOverlapDot={isAdmin}
            onClick={(e) => {
                if (onAvatarClick) {
                    e.stopPropagation();
                    e.preventDefault();
                    onAvatarClick(e);
                }
            }}
        />
    );
};

export default withStyles(styleWrapper<Props>())(BaseAssignAvatar);
