import classNames from 'classnames';

import DCSelect, { Props as DCSelectProps } from './DCSelect';
import ConfigurableField, { mapStateToProps } from '../ConfigurableField';
import HoverListener from '../../../../../common/HoverListener';
import { ORANGE_COLOR } from '../../../../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import withState from '../../../../../common/utilHOC/WithState';
import { AppDispatch } from '../../../../../../store';
import { ConfigurableFieldKey } from '../../../../../../shared/death_certificate/validators/config';
import { GStyles } from '../../../../../../styles/GStyles';

const styles = <StyleThemeProps extends object>(): StyleRulesCallback<Theme, StyleThemeProps> => (theme) => ({
    root: {
        backgroundColor: `${ORANGE_COLOR}4C`,
        borderRadius: '5px 5px 0px 0px',
    },
});

interface Props<T extends string> extends Omit<DCSelectProps<T>, 'required' | 'id'> {
    id: ConfigurableFieldKey;
    dispatch: AppDispatch;
    isEditMode: boolean;
}

type CombinedProps<T extends string> = WithStyles<'root'> & Props<T> & ReturnType<typeof mapStateToProps>;

class ConfigurableSelect<T extends string> extends ConfigurableField<T, CombinedProps<T>> {

    render() {
        const {
            isEditMode,
            classes,
            rootClass,
            inputLabelClass,
            selectMenuClass,
            ...otherProps
        } = this.props;
        const { visibleOptions, isVisible, isRequired, isEnabled, isHiddenFromFamily } = this.state;

        if (!isVisible) {
            return null;
        }

        return (
            <HoverListener
                onHoverEnter={this.openPopper}
                onHoverLeave={this.closePopper}
                enterDelayMs={300}
                leaveDelayMs={500}
            >
                <DCSelect
                    {...otherProps}
                    isHiddenFromFamily={isHiddenFromFamily}
                    required={isRequired}
                    inputLabelClass={classNames(inputLabelClass, !isEnabled && GStyles.strikeThrough)}
                    selectMenuClass={classNames(selectMenuClass, !isEnabled && GStyles.strikeThrough)}
                    rootClass={classNames(rootClass, isEditMode && classes.root)}
                    options={visibleOptions || []}
                />
                {this.renderPopper()}
                {this.renderConfigureOptionsDialog()}
            </HoverListener>
        );
    }
}

export default <T extends string>() =>
    withState(mapStateToProps)(withStyles(styles<Props<T>>())(ConfigurableSelect));
