import classNames from 'classnames';
import momentTz from 'moment-timezone';

import makeGStyles from '../../../../../styles/makeGStyles';
import { alpha, lighten } from '@mui/material/styles';

import IconButton from "@mui/material/IconButton";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { SvgIconComponent } from '@mui/icons-material';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import GPopper from '../../../../common/GPopper';
import { joinNameParts, getIntercomTargetProp } from '../../../../../services';
import { usePopperState } from '../../../../common/hooks/usePopperState';
import FamilySharingSwitch from './FamilySharingSwitch';
import { useGSelector } from '../../../../../types';

const useStyles = makeGStyles(theme => ({
    heading: {
        backgroundColor: props => alpha(theme.palette.primary.main, 0.18),
        padding: '8px 4px',
        borderRadius: '12px 12px 0px 0px',
        '& p': {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            margin: '0 auto',
            width: 'fit-content',
            '& svg': {
                fontSize: 20,
            },
        },
    },
    mainContent: {
        padding: '14px 8px 20px',
        textAlign: 'center',
    },
    stepTitle: {
        color: theme.palette.primary.main,
        fontSize: 20,
    },
    stepDetails: {
        fontSize: 14,
        color: theme.palette.primary.main,
        lineHeight: 1.25,
    },
    popoverPaper: {
        width: '100%',
        minWidth: 320,
        maxWidth: 360,
        boxShadow: `0px 6px 6px -3px rgb(0 0 0 / 20%), 
        0px 10px 14px 1px rgb(0 0 0 / 14%), 
        0px 4px 18px 3px rgb(0 0 0 / 12%)`,
        borderRadius: 12,
        borderBottomColor: 'inherit'
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            borderBottomColor: props => lighten(theme.palette.primary.main, 0.82),
            '& $arrow': {
                top: -20,
                borderBottom: `20px solid`,
                borderBottomColor: 'inherit'
            }
        },
        '&[data-popper-placement*="top"] $arrow': {
            bottom: -20,
            borderTop: `20px solid ${theme.palette.common.white}`,
        },
    },
    arrow: {
        border: '20px solid transparent',
    },
    iconButton: {
        width: 20,
        height: 20,
    },
}), { name: 'IconWithStepDetailPopper' });

interface Props {
    Icon: SvgIconComponent;
    activeStepId: number;
    caseUuid: string;
    iconButtonClass?: string;
    iconTooltip?: string;
    placement?: 'top' | 'bottom';
    intercomTargetProp?: string;
}
const IconWithStepDetailPopper = (props: Props) => {

    const {
        activeStepId,
        caseUuid,
        Icon,
        iconButtonClass,
        iconTooltip,
        placement,
        intercomTargetProp,
    } = props;

    const classes = useStyles();
    const [stepDetailsPopperAnchor, stepDetailsAnchorEvents, closeStepDetailsPopper] = usePopperState();

    const step = useGSelector(({ tasksState }) =>
        tasksState.trackingSteps.find(tStep => tStep.id === activeStepId)
    );

    if (!step) {
        return <></>;
    }

    const markedCompleteBy = step.marked_complete_by && joinNameParts(step.marked_complete_by);
    const performedBy = step.performed_by && joinNameParts(step.performed_by);

    const markedCompleteTime = step.marked_complete_time && momentTz.tz(step.marked_complete_time, 'America/Boise');
    const date = markedCompleteTime && markedCompleteTime.format('DD MMMM YYYY, h:mma z');

    const stepDetailsPopper = () => {

        return (
            <GPopper
                anchorEle={stepDetailsPopperAnchor}
                paperClass={classes.popoverPaper}
                className={classes.popper}
                arrowClass={classes.arrow}
                zIndex={1330}
                closePopper={closeStepDetailsPopper}
                placement='top'
            >
                <Grid>
                    <div className={classes.heading}>
                        <Typography color="primary">
                            <VisibilityOffIcon />
                            &nbsp;This popup is never visible to the family
                        </Typography>
                    </div>

                    <div className={classes.mainContent}>
                        <Typography className={classes.stepTitle}>{step.past_tense_title}</Typography>
                        {markedCompleteBy &&
                            <Typography className={classes.stepDetails}>
                                Step marked complete by {markedCompleteBy}
                            </Typography>
                        }

                        {performedBy &&
                            <Typography className={classes.stepDetails}>
                                Step performed by {performedBy}
                            </Typography>
                        }

                        {date &&
                            <Typography className={classes.stepDetails}>
                                {date}
                            </Typography>
                        }
                    </div>

                    <FamilySharingSwitch
                        stepId={step.id}
                        stepTitle={step.past_tense_title || step.title}
                        stepVisibleToFamily={step.visible_to_family}
                        caseUuid={caseUuid}
                    />
                </Grid>
            </GPopper>
        );
    };

    return (
        <div {...getIntercomTargetProp(intercomTargetProp)}>
            <Tooltip
                title={iconTooltip || ''}
                placement={placement || 'top'}
            >
                <IconButton
                    onClick={stepDetailsAnchorEvents.onClick}
                    className={classNames(classes.iconButton, iconButtonClass)}
                    color="primary"
                >
                    <Icon color="inherit" />
                </IconButton>
            </Tooltip>

            {stepDetailsPopper()}
        </div>
    );
};

export default IconWithStepDetailPopper;
