import { FhOrAssigneePhoneNumberLink } from '..';
import BaseTaskDialog from '../BaseTaskDialog';
import { TaskDialogComponentProps } from '../TaskDialog';
import FindUnclaimedProperty from './FindUnclaimedProperty';

const UnclaimedPropertyDialog = (props: TaskDialogComponentProps) => {

    const description = (
        <>
            Billions of dollars of property goes unclaimed each year. We want to make sure you
            and your family receive any cash or property to which {props.activeCase.fname} was entitled.
            Simply click below to perform a quick search with each state where {props.activeCase.fname}
            lived. In just a few moments you will learn if there is any property that belongs to you.
            Please <FhOrAssigneePhoneNumberLink activeCase={props.activeCase} /> to give me a
            call if you have any questions about this process.
        </>
    );

    return (
        <BaseTaskDialog
            {...props}
            initialStepData={{
                heading: 'Find Unclaimed Property',
                description,
                listItems: [{
                    id: 1,
                    label: `Find ${props.activeCase.fname}'s Unclaimed Property`,
                    content: <FindUnclaimedProperty activeCase={props.activeCase} />
                }]
            }}
        />
    );
};

export default UnclaimedPropertyDialog;
