import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { EntitySummary } from '../../shared/types';

import { generateNameInitials } from '../../services';
import { joinNameParts } from '../../shared/utils';
import makeStyles from "@mui/styles/makeStyles";
import { green } from '@mui/material/colors';
import { Theme } from '@mui/material';
import { HelperInvitationDialogContext } from '../../actions/Dialog.action';
import { useGSelector } from '../../types';

interface Props {
    resetInvitationForm: () => void;
    newHelper: EntitySummary | null;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textAlign: 'center',
        margin: '16px 24px'
    },
    topHeading: {
        textAlign: 'center',
        fontSize: 18
    },
    emptyAvatarSuccess: {
        width: 90,
        height: 90,
        margin: 'auto',
        border: 'none',
        color: theme.palette.background.paper,
        background: green[500],
        fontSize: 40,
        transition: 'all .8s ease-in-out !important',
    },
    inviteHelperBtn: {
        margin: '24px 0',
    },
    avatarContainer: {
        width: 90,
        height: 90,
        margin: 'auto',
        position: 'relative',
    },
}), { name: 'AddEntitySuccess' });

const AddEntitySuccess = (props: Props) => {

    const { newHelper, resetInvitationForm } = props;
    const classes = useStyles();

    const context = useGSelector(({ dialogState }) => dialogState.helperInvitation.context);

    const renderNameInitialsAvatar = () => {
        const firstName = newHelper && newHelper.fname ? newHelper.fname : '';
        const lastName = newHelper && newHelper.lname ? newHelper.lname : '';
        return (
            <div className={classes.avatarContainer}>
                <Avatar className={classes.emptyAvatarSuccess}>
                    {generateNameInitials(firstName, lastName)}
                </Avatar>
            </div>
        );
    };

    if (!newHelper) {
        return (<></>);
    }

    return (
        <Grid item className={classes.root}>
            {renderNameInitialsAvatar()}
            <Typography color="secondary" className={classes.topHeading}>
                {joinNameParts(newHelper)} was added.
            </Typography>

            {context !== HelperInvitationDialogContext.payment &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.inviteHelperBtn}
                    onClick={e => resetInvitationForm()}
                >
                    ADD ANOTHER GUEST
                </Button>
            }
        </Grid>
    );
};

export default AddEntitySuccess;
