import * as React from 'react';
import classNames from 'classnames';

import { StyledProps, styleWrapper } from '../styles';

import { DocPacketContractSignerRecord, DocPacketSignerUX, ContractOptionsUX } from '../../../../../shared/types';
import { generateHelloSignTextTag } from '../../../../../shared/docs/utils';
import { SignatureGrid } from '.';
import { joinNameParts } from '../../../../../shared/utils';
import { getEntityHomeAddress } from '../../../../../services';
import withGStyles from '../../../../../styles/WithGStyles';

type Props = {
    isPrintMode: boolean;
    isCondensedView: boolean;
    teamSigners: DocPacketContractSignerRecord[];
    helperSigners: DocPacketContractSignerRecord[];
    docPacketSigners: DocPacketSignerUX[];
    contractOptions: ContractOptionsUX;
};

const Signatures: React.FunctionComponent<Props & StyledProps> = (props) => {
    const { classes, isPrintMode, isCondensedView, teamSigners, helperSigners, docPacketSigners, contractOptions } =
        props;

    const {
        include_fh_extra_field,
        include_fam_extra_field,
        is_fh_extra_field_req,
        is_fam_extra_field_req,
        fh_extra_field_name,
        fam_extra_field_name,
        include_fh_license,
        include_fam_address,
        include_fam_email,
        include_fam_phone,
    } = contractOptions;

    return (
        <div
            className={classNames(
                classes.signatoriesContainer,
                isCondensedView && classes.isCondensedView
            )}
            style={{
                margin: '0px',
                flexDirection: isCondensedView ? 'column' : undefined,
                display: isCondensedView ? 'flex' : undefined,
                pageBreakInside: 'avoid',
            }}
        >
            {/* Funeral home signatories */}
            {teamSigners.map((teamSigner, i) => {
                const packetSigner = docPacketSigners.find((s) => s.id === teamSigner.doc_packet_signer_id);
                if (!packetSigner) {
                    return null;
                }
                const { person } = packetSigner;

                return <SignatureGrid
                    key={packetSigner.id}
                    classes={classes}
                    name={joinNameParts(person)}
                    userDataLine1={include_fh_license && person.state_license_number || undefined
                    }
                    extraFieldName={include_fh_extra_field && fh_extra_field_name || undefined}
                    helloSignSignatureTextTag={
                        generateHelloSignTextTag('sig', docPacketSigners, teamSigner) || ''
                    }
                    helloSignDateTextTag={
                        generateHelloSignTextTag('date', docPacketSigners, teamSigner) || ''
                    }
                    helloSignExtraTextTag={include_fh_extra_field
                        ? generateHelloSignTextTag(
                            'text',
                            docPacketSigners,
                            teamSigner,
                            is_fh_extra_field_req
                        ) || ''
                        : undefined
                    }
                    isCondensedView={isCondensedView}
                    isLast={i === teamSigners.length - 1}
                    isPrintMode={isPrintMode}
                />;
            })}

            {/* Family signatories */}
            {helperSigners.map((helperSigner, i) => {
                const packetSigner = docPacketSigners.find((s) => s.id === helperSigner.doc_packet_signer_id);
                if (!packetSigner) {
                    return null;
                }

                const { person } = packetSigner;
                const address = getEntityHomeAddress(person);
                const email = person.email || '';
                const phone = person.phone || '';

                return <SignatureGrid
                    key={packetSigner.id}
                    classes={classes}
                    name={joinNameParts(person)}
                    userDataLine1={include_fam_address && address ? address : undefined}
                    userDataLine2={include_fam_email && email ? email : undefined}
                    userDataLine3={include_fam_phone && phone ? phone : undefined}
                    extraFieldName={include_fam_extra_field && fam_extra_field_name || undefined}
                    helloSignSignatureTextTag={
                        generateHelloSignTextTag('sig', docPacketSigners, helperSigner) || ''
                    }
                    helloSignDateTextTag={
                        generateHelloSignTextTag('date', docPacketSigners, helperSigner) || ''
                    }
                    helloSignExtraTextTag={include_fam_extra_field
                        ? generateHelloSignTextTag(
                            'text',
                            docPacketSigners,
                            helperSigner,
                            is_fam_extra_field_req
                        ) || ''
                        : undefined
                    }
                    isCondensedView={isCondensedView}
                    isLast={i === helperSigners.length - 1}
                    isPrintMode={isPrintMode}
                />;
            }
            )}
        </div>
    );
};

export default withGStyles(styleWrapper<Props>())(Signatures);
