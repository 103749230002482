import * as constants from '../constants';


import { TaskTemplateState } from '../types';

import { GatherAction } from '../actions';
import {
    CREATING_TASK_COMPONENT,
    DELETING_TASK_COMPONENT,
    LOADED_TASK_COMPONENTS,
    UPDATING_TASK_COMPONENT,
} from '../actions/task/TaskComponent.action';
import { TaskComponentUX } from '../shared/types';

export const taskTemplateInitData: TaskTemplateState = {
    activeTaskTemplate: null,
};

export const taskTemplateState = (
    state: TaskTemplateState = taskTemplateInitData,
    action: GatherAction,
): TaskTemplateState => {

    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return taskTemplateInitData;
        case LOADED_TASK_COMPONENTS: {
            return {
                ...state,
                activeTaskTemplate: {
                    taskTemplateId: action.taskTemplateId,
                    components: action.taskComponents,
                },
            };
        }
        case CREATING_TASK_COMPONENT: {
            if (!state.activeTaskTemplate) {
                return state;
            }
            const newComponent: TaskComponentUX = {
                ...action.taskComponentRequest,
                id: -1,
                rank: 1,
            };
            return {
                ...state,
                activeTaskTemplate: {
                    ...state.activeTaskTemplate,
                    components: [newComponent, ...state.activeTaskTemplate.components],
                },
            };
        }
        case DELETING_TASK_COMPONENT: {
            if (!state.activeTaskTemplate) {
                return state;
            }
            return {
                ...state,
                activeTaskTemplate: {
                    ...state.activeTaskTemplate,
                    components: state.activeTaskTemplate.components.filter((c) => c.id !== action.idToRemove),
                },
            };
        }
        case UPDATING_TASK_COMPONENT: {
            if (!state.activeTaskTemplate) {
                return state;
            }
            return {
                ...state,
                activeTaskTemplate: {
                    ...state.activeTaskTemplate,
                    components: state.activeTaskTemplate.components.map((c) => {
                        if (c.id === action.taskComponentId) {
                            return {
                                ...c,
                                ...action.changes,
                            };
                        } else {
                            return c;
                        }
                    }),
                },
            };
        }
        default:
            return state;
    }
};
