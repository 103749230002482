import { useEffect } from 'react';

export enum CUSTOM_EVENT {
    user_profile_photo_update = 'gather.user_profile.photo_update',
    local_store_update = 'gather.local_store.update'
}

function useDocumentEvent(
    event: CUSTOM_EVENT,
    handler: (this: Window, ev: Event) => void,
    passive: boolean = false,
) {
    useEffect(() => {
        document.addEventListener(event, handler, passive);

        return function cleanp() {
            document.removeEventListener(event, handler);
        };
    }, [event, handler, passive]);
}

export default useDocumentEvent;
