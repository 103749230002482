import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import { DefaultItemType, useAvailableItemsContext } from './AvailableDefaultItems.dialog';
import AvailableDefaultDialogItems from './AvailableDefaultDialogItems';
import makeGStyles from '../../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {},
    searchField: {
        margin: '0  0 16px 0',
        width: 'calc(100% - 48px)'
    },
    searchForm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
    },
    addTaskButton: {
        margin: '24px 12px 6px',
        '@media (min-width: 480px)': {
            margin: '24px 10px 24px 12px',
        },
    },
    addAnotherTaskButton: {
        margin: '6px 12px 24px',
        '@media (min-width: 480px)': {
            margin: '24px 12px 24px 10px',
        },
    },
    addButton: {
        margin: '24px 10px 24px 12px'
    },
}), { name: 'AvailableDefaultItemDialogContent' });

interface Props {
    zIndex: number;
}

const AvailableDefaultItemDialogContent = <T extends DefaultItemType>(props: Props) => {
    const { zIndex } = props;

    const {
        searchText,
        createAnotherButtonText,
        availableItems,
        createNewButtonText,
        itemType,
        onCreateAnotherButtonClick,
        setSearchText,
        getHeader,
        onCreateNewItemClick,
        getSubHeader,
    } = useAvailableItemsContext<T>();
    const classes = useStyles();

    const itemsCount = availableItems.length;

    const renderCreateButton = !!(!getHeader && onCreateNewItemClick);
    const renderSecondaryCreateButton = !!(createAnotherButtonText && !getHeader);

    return (
        <Grid container={true}>
            <Grid item={true} xs={12} className={classes.textCenter}>
                {getHeader?.()}

                {!getHeader && onCreateNewItemClick &&
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onCreateNewItemClick}
                        className={createAnotherButtonText ? classes.addTaskButton : classes.addButton}
                    >
                        <AddIcon />&nbsp;
                        <span className={classes.marginTop2}>{createNewButtonText}</span>
                    </Button>
                }

                {renderSecondaryCreateButton &&
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onCreateAnotherButtonClick}
                        className={classes.addAnotherTaskButton}
                    >
                        <AddIcon />&nbsp;
                        <span className={classes.marginTop2}>{createAnotherButtonText}</span>
                    </Button>
                }

                {!getHeader &&
                    <FormControl
                        className={classNames(
                            classes.searchForm,
                            !renderCreateButton && !renderSecondaryCreateButton && classes.marginTop24,
                        )}
                    >
                        <TextField
                            autoComplete="off"
                            className={getSubHeader ? classes.marginAuto : classes.searchField}
                            fullWidth={true}
                            autoFocus={window.innerWidth >= 768}
                            value={searchText}
                            placeholder={`Type to search ${itemsCount} existing ${itemType.toLowerCase()}...`}
                            name="searchText"
                            onChange={({ currentTarget }) => setSearchText(currentTarget.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                }

                {getSubHeader?.()}

                <AvailableDefaultDialogItems zIndex={zIndex} />
            </Grid>
        </Grid>
    );
};

export default AvailableDefaultItemDialogContent;
