import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { convertHexToRGBA } from '../../../services';
import { WithGStyles } from '../../../styles/WithGStyles';

export const saveProductStyles = <Props extends {}>(): StyleRulesCallback<Theme, Props> => theme => ({
    root: {
        padding: '0 16px',
    },
    formControl: {
        display: 'block',
        width: '100%',
        marginTop: 8,
    },
    break768: {
        width: '100%'
    },
    chip: {
        margin: 8,
        height: 36,
        width: 'fit-content'
    },
    textAreaMinHeight: {
        minHeight: 38
    },
    priceSelector: {
        background: convertHexToRGBA(theme.palette.primary.main, 0.20),
        borderRadius: 8,
        padding: 16
    },
    radioGroup: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: 8,
    },
    textFieldContainer: {
        flexDirection: 'row',
        display: 'flex',
        marginBottom: 16,
        '& $textField': {
            margin: 'auto 8px',
            '& label': {
                minWidth: 110,
                whiteSpace: 'nowrap',
            },
            '&:first-of-type': {
                margin: 'auto 12px auto 0',
            },
            '&:last-child': {
                margin: 'auto 0 auto 12px',
            }
        },
        '&$second': {
            alignItems: 'flex-end'
        }
    },
    checkboxTextFieldGrid: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& label': {
            marginRight: 4,
        },
        '& $textField': {
            width: 50
        }
    },
    textField: {
        '& input': {
            '-moz-appearance': 'textfield',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    },
    productNameRadioBtn: {
        display: 'flex',
        justifyContent: 'center',
        height: 20,
        alignItems: 'center',
        '& $radioLabel': {
            height: 8,
        },
    },
    marginLeft0: {
        marginLeft: '0 !important'
    },
    defaultQuantityCheckbox: {
        marginLeft: -14,
    },
    defaultQuantityContainer: {
        alignItems: 'center',
    },
    defaultQuantityText: {
        cursor: 'pointer',
    },
    marginTop0: {
        marginTop: 0
    },
    marginTB16: {
        margin: '16px 0'
    },
    radioLabel: {},
    last: {}, // used in other CSS
    second: {}, // used in other CSS
    gSwitchLabel: {
        position: 'relative',
        transform: 'translate(0px)',
    },
    formControlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    displayOptionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    noBorder: {
        border: 'none',
    },
    height34: {
        height: 34,
    },
    photoContainerClass: {
        height: 130,
        width: 130,
    },
    marginBottom0: {
        marginBottom: 0,
    },
    paddingTop4: {
        paddingTop: '4px !important',
    },
    padding4: {
        padding: 4,
    },
});

export type SaveProductStyledProps = WithGStyles<'root' | 'formControl' | 'break768'
    | 'chip' | 'priceSelector' | 'textAreaMinHeight' | 'maxWidth100' | 'radioGroup' | 'textField'
    | 'textFieldContainer' | 'checkboxTextFieldGrid' | 'last' | 'second' | 'marginLeft0'
    | 'defaultQuantityCheckbox' | 'defaultQuantityContainer' | 'defaultQuantityText' | 'marginTop0'
    | 'noBorder' | 'toggleSwitchContainer' | 'marginTB16' | 'productNameRadioBtn' | 'radioLabel'
    | 'gSwitchLabel' | 'photoContainerClass' | 'formControlContainer' | 'displayOptionsContainer'
    | 'paddingTop4' | 'height34' | 'marginBottom0' | 'padding4'>;
