import { Component } from 'react';
import classNames from 'classnames';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

import InfoIcon from '@mui/icons-material/Info';

import { ORANGE_COLOR, RED_COLOR } from '../../../../../constants/colorVariables';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import GPopper from '../../../../common/GPopper';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: 50,
        },
    },
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        width: 280,
        boxShadow: theme.shadows[10]
    },
    popperContent: {
        textAlign: 'center',
        height: 'fit-content',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        paddingBottom: 8,
    },
    infoIcon: {
        fontSize: 12,
    },
    title: {
        padding: 4,
        margin: '0px 8px',
        marginBottom: 10,
        borderBottom: `1px solid ${ORANGE_COLOR}`,
        '& p': {
            color: ORANGE_COLOR,
        },
    },
    checkboxContainer: {
        textAlign: 'left',
        marginLeft: 18,
        display: 'flex',
    },
    formControl: {
        textAlign: 'left',
        flexDirection: 'row',
    },
    infoIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: -10,
    },
    checkboxSize: {
        height: 24,
        width: 24,
    },
    buttonContainer: {
        margin: '8px 0',
        textAlign: 'center',
    },
    removeColor: {
        color: `${RED_COLOR} !important`,
    }
});

type StyledProps = Props & WithGStyles<'root' | 'popper' | 'popoverPaper' | 'popperContent' | 'infoIcon'
    | 'title' | 'checkboxContainer' | 'formControl' | 'infoIconContainer' | 'checkboxSize' | 'buttonContainer'
    | 'removeColor'>;

interface Props {
    zIndex: number;
    anchorEle: HTMLElement | null;
    title: string;
    isRequired: boolean;
    isHiddenFromFamily: boolean;
    isRemoved: boolean;
    onConfigureOptionsClick?: () => void;
    onRemoveChange: () => void;
    onRequiredChange: () => void;
    onHideFromFamilyChange: () => void;
    closePopper: () => void;
}

class ConfigurablePopper extends Component<StyledProps> {

    render() {
        const {
            classes,
            anchorEle,
            zIndex,
            title,
            isRemoved,
            isRequired,
            isHiddenFromFamily,
            closePopper,
            onConfigureOptionsClick,
            onRemoveChange,
            onRequiredChange,
            onHideFromFamilyChange,
        } = this.props;
        return (
            <GPopper
                anchorEle={anchorEle}
                className={classes.popper}
                popperContentClass={classes.popperContent}
                paperClass={classes.popoverPaper}
                zIndex={zIndex}
                closePopper={closePopper}
                placement='top'
            >
                <Grid item className={classes.title}>
                    <Typography>
                        {title}
                    </Typography>
                </Grid>
                <Tooltip
                    title={'Removes this field from showing on any case for this' +
                        ' particular death certificate configuration'}
                    enterDelay={300}
                >
                    <Grid item className={classes.checkboxContainer}>
                        <FormControl
                            className={classes.formControl}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={onRemoveChange}
                                        checked={isRemoved}
                                        className={classes.removeColor}
                                        classes={{
                                            root: classes.checkboxSize,
                                            checked: classes.removeColor,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classNames(classes.fontSize12, classes.removeColor)
                                }}
                                label="Remove field completely"
                            />
                        </FormControl>
                        <Grid item className={classes.infoIconContainer}>
                            <InfoIcon color="primary" className={classes.infoIcon} />
                        </Grid>
                    </Grid>
                </Tooltip>
                <Tooltip
                    title={'Ensures that this question must be answered before a green ' +
                        'check shows on this section of the death certificate.'}
                    enterDelay={300}
                >
                    <Grid item className={classes.checkboxContainer}>
                        <FormControl
                            className={classes.formControl}
                            disabled={isRemoved}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={onRequiredChange}
                                        checked={isRequired && !isRemoved}
                                        color="primary"
                                        classes={{
                                            root: classes.checkboxSize,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classNames(classes.fontSize12, classes.colorSecondary)
                                }}
                                label="Make field required *"
                            />
                        </FormControl>
                        <Grid item className={classes.infoIconContainer}>
                            <InfoIcon color="primary" className={classes.infoIcon} />
                        </Grid>
                    </Grid>
                </Tooltip>
                <Tooltip
                    title={'Each question on your DC can be configured to show or hide to the ' +
                        'family. Switching to Family View will hide those fields from your screen.'}
                    enterDelay={300}
                    placement="top"
                >
                    <Grid item className={classes.checkboxContainer}>
                        <FormControl
                            className={classes.formControl}
                            disabled={isRemoved}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={onHideFromFamilyChange}
                                        checked={isHiddenFromFamily && !isRemoved}
                                        color="primary"
                                        classes={{
                                            root: classes.checkboxSize,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classNames(classes.fontSize12, classes.colorSecondary)
                                }}
                                label="Hide field from family"
                            />
                        </FormControl>
                        <Grid item className={classes.infoIconContainer}>
                            <InfoIcon color="primary" className={classes.infoIcon} />
                        </Grid>
                    </Grid>
                </Tooltip>
                {onConfigureOptionsClick !== undefined &&
                    <Grid item className={classes.buttonContainer}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={e => {
                                onConfigureOptionsClick();
                                closePopper();
                            }}
                            size="small"
                            disabled={isRemoved}
                        >
                            Configure Field Options
                        </Button>
                    </Grid>
                }
            </GPopper>
        );
    }
}

export default withGStyles(styles)(ConfigurablePopper);
