import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PagesIcon from '@mui/icons-material/Pages';

import { StyledProps, styleWrapper } from '../styles';
import { PrintModeType } from '../..';
import withGStyles from '../../../../../styles/WithGStyles';

type Props = {
    isCondensedView: boolean;
    title: string;
    subPackages: string[];
    typographyStyles: React.CSSProperties;
    isPrintMode: boolean;
    printModeType?: PrintModeType;
};

const PackageDisplay: React.FunctionComponent<Props & StyledProps> = (props) => {
    const { isCondensedView, subPackages, title, typographyStyles, isPrintMode, printModeType } = props;

    const titleStyles = {
        ...typographyStyles,
        fontFamily: 'system-ui, sans-serif',
    };

    if (subPackages.length) {
        titleStyles.marginBottom = isPrintMode ? 2 : 0;
    }

    return (
        <Grid style={{ marginRight: isCondensedView ? 0 : 12 }}>
            <Typography
                color={isCondensedView ? 'secondary' : 'primary'}
                style={titleStyles}
            >
                <PagesIcon
                    style={{
                        fontSize: isCondensedView ? 18 : 24,
                        width: '1em',
                        height: '1em',
                        display: 'inline-block',
                        flexShrink: 0,
                    }}
                />&nbsp;<span>{title}</span>
            </Typography>
            {subPackages.map((subPkg) => (
                <Typography
                    key={subPkg}
                    color={isCondensedView ? 'secondary' : 'primary'}
                    style={{
                        fontSize: 10,
                        marginTop: 0,
                        marginLeft: (isCondensedView ? 24 : 30) - (printModeType === 'draft' ? 3 : 0),
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontFamily: 'system-ui, sans-serif',
                        marginBottom: printModeType === 'official' ? 2 : 4
                    }}
                >
                    <PagesIcon
                        style={{
                            fontSize: 10,
                            width: '1em',
                            height: '1em',
                            display: 'inline-block',
                            flexShrink: 0,
                        }}
                        viewBox={'0 -4 24 24'}
                    />&nbsp;<span>{subPkg}</span>
                </Typography>
            ))}
        </Grid >
    );
};

export default withGStyles(styleWrapper<Props>())(PackageDisplay);
