
import { getIntercomTargetProp } from '../../services';
import SchoolIcon from '@mui/icons-material/School';
import { Theme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SvgIconComponent } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& p': {
            padding: '8px 8px 0px 0px',
            fontSize: 10,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
            '& svg': {
                fontSize: 16,
            },
            // [theme.breakpoints.up('xs')]: {
            //     display: 'none',
            // }
        },
    }
}), { name: 'LearnMoreButton' });

interface LearnMoreProps {
    icon?: SvgIconComponent;
    label?: string;
    intercomTargetProp?: string;
    onClick?: React.MouseEventHandler;
}

const LearnMoreButton = (props: LearnMoreProps) => {
    const { icon, label, intercomTargetProp, onClick } = props;
    const classes = useStyles();

    const buttonIcon = icon ?? <SchoolIcon />;
    const labelText = label ?? `Learn`;
    return (
        <Grid className={classes.root} {...getIntercomTargetProp(intercomTargetProp)}>
            <Typography color="secondary" onClick={onClick}>
                {buttonIcon}&nbsp;{labelText}
            </Typography>
        </Grid>
    );
};

export default LearnMoreButton;