import * as React from 'react';

import TaskFooterBar from './TaskFooterBar';
import TaskOverviewBar, { TaskBarListType } from './TaskOverviewBar';

interface Props {
    children: React.ReactNode;
    caseFirstName: string;
    taskName: string;
    taskCompletionValue: number;
    tasksLength: number;
    taskcompletedPct: number;
    isBulkEditMode: boolean;
    listType: TaskBarListType;
    footerText?: JSX.Element;
    disableFooterTextClick?: boolean;
    disableHeaderTextClick?: boolean;
    taskOverviewClass?: string;
    customText?: string;
    intercomTargetProp?: string;
    onBulkEditClick: () => void;
}

const TaskBar = (props: Props) => {
    const {
        children,
        caseFirstName,
        taskName,
        taskCompletionValue,
        tasksLength,
        taskcompletedPct,
        listType,
        isBulkEditMode,
        footerText,
        disableFooterTextClick,
        taskOverviewClass,
        customText,
        disableHeaderTextClick,
        intercomTargetProp,
        onBulkEditClick
    } = props;

    return (
        <>
            <TaskOverviewBar
                listType={listType}
                taskName={taskName}
                tasksLength={tasksLength}
                caseFirstName={caseFirstName}
                isBulkEditMode={isBulkEditMode}
                taskCompletedPct={taskcompletedPct}
                taskCompletionValue={taskCompletionValue}
                onBulkEditClick={onBulkEditClick}
                taskOverviewClass={taskOverviewClass}
                customText={customText}
                disableClick={disableHeaderTextClick}
                intercomTargetProp={intercomTargetProp}
            />

            {children}

            <TaskFooterBar
                text={footerText}
                listType={listType}
                isBulkEditMode={isBulkEditMode}
                disableClick={disableFooterTextClick}
                toggleBulkEdit={onBulkEditClick}
                footerWidthClass={taskOverviewClass}
            />
        </>
    );
};

export default TaskBar;
