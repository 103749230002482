import {
    AlbumDownloadState,
    AlbumDownloadStatus,
    AlbumEntryMappedType,
    AlbumIdOrCase,
    AlbumTaskInfo,
    AlbumTaskInfoMap,
    AlbumWithEntries,
    isAlbumEntry,
    PhotoTransformationsType,
} from '../shared/types';
import { omit } from 'lodash';
import { AlbumPhoto } from '../types';
import { getFromAPI, postToAPI, putToAPI } from './index';
import { registerAppError } from './errors';
import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import generateUuid from 'uuid';
import { AppDispatch } from '../store';

export const SET_ALBUM_PHOTOS = 'SET_ALBUM_PHOTOS';
export type SET_ALBUM_PHOTOS = typeof SET_ALBUM_PHOTOS;

interface SetAlbumPhotos {
    type: SET_ALBUM_PHOTOS;
    photos: AlbumPhoto[];
    albumId: number;
}

function setAlbumPhotos(albumId: number, photos: AlbumPhoto[]): SetAlbumPhotos {
    return {
        type: SET_ALBUM_PHOTOS,
        photos,
        albumId,
    };
}

export const SET_ALBUM_NETWORK_BUSY = 'SET_ALBUM_NETWORK_BUSY';
export type SET_ALBUM_NETWORK_BUSY = typeof SET_ALBUM_NETWORK_BUSY;
interface SetAlbumNetworkBusy {
    type: SET_ALBUM_NETWORK_BUSY;
    isBusy: boolean;
}
function setAlbumNetworkBusy(isBusy: boolean): SetAlbumNetworkBusy {
    return {
        type: SET_ALBUM_NETWORK_BUSY,
        isBusy,
    };
}

export const SET_ALBUM_TASK_INFO = 'SET_ALBUM_TASK_INFO';
export type SET_ALBUM_TASK_INFO = typeof SET_ALBUM_TASK_INFO;
interface SetAlbumTaskInfo {
    type: SET_ALBUM_TASK_INFO;
    taskId: number;
    albumInfo: AlbumTaskInfo;
}
function setAlbumTaskInfo(taskId: number, albumInfo: AlbumTaskInfo): SetAlbumTaskInfo {
    return {
        type: SET_ALBUM_TASK_INFO,
        taskId,
        albumInfo,
    };
}

export const SET_ALBUM_TASK_INFO_MAP = 'SET_ALBUM_TASK_INFO_MAP';
export type SET_ALBUM_TASK_INFO_MAP = typeof SET_ALBUM_TASK_INFO_MAP;
interface SetAlbumTaskInfoMap {
    type: SET_ALBUM_TASK_INFO_MAP;
    albumInfoMap: AlbumTaskInfoMap;
}
function setAlbumTaskInfoMap(albumInfoMap: AlbumTaskInfoMap): SetAlbumTaskInfoMap {
    return {
        type: SET_ALBUM_TASK_INFO_MAP,
        albumInfoMap,
    };
}

export const SET_ALBUM_DOWNLOAD_STATE = 'SET_ALBUM_DOWNLOAD_STATE';
export type SET_ALBUM_DOWNLOAD_STATE = typeof SET_ALBUM_DOWNLOAD_STATE;
interface SetAlbumDownloadState {
    type: SET_ALBUM_DOWNLOAD_STATE;
    state: AlbumDownloadState;
    albumId: AlbumIdOrCase;
}
export function setAlbumDownloadState(albumId: AlbumIdOrCase, state: AlbumDownloadState): SetAlbumDownloadState {
    return {
        type: SET_ALBUM_DOWNLOAD_STATE,
        state,
        albumId,
    };
}

export const SET_REMEMBER_PREVIEW_ALBUM = 'SET_REMEMBER_PREVIEW_ALBUM';
export type SET_REMEMBER_PREVIEW_ALBUM = typeof SET_REMEMBER_PREVIEW_ALBUM;
interface SetRememberPreviewAlbum {
    type: SET_REMEMBER_PREVIEW_ALBUM;
    caseUuid: string;
    album: AlbumWithEntries;
}
export function setRememberPreviewAlbum(caseUuid: string, album: AlbumWithEntries): SetRememberPreviewAlbum {
    return {
        type: SET_REMEMBER_PREVIEW_ALBUM,
        caseUuid,
        album,
    };
}

export const REMOVE_ALBUM_ENTRY_LOCALLY = 'REMOVE_ALBUM_ENTRY_LOCALLY';
export type REMOVE_ALBUM_ENTRY_LOCALLY = typeof REMOVE_ALBUM_ENTRY_LOCALLY;
interface RemoveAlbumEntryLocally {
    type: REMOVE_ALBUM_ENTRY_LOCALLY;
    albumId: number;
    albumEntryIds: number[];
}
function removeAlbumEntryLocally(albumId: number, albumEntryIds: number[]): RemoveAlbumEntryLocally {
    return {
        type: REMOVE_ALBUM_ENTRY_LOCALLY,
        albumId,
        albumEntryIds
    };
}

export const UPDATE_ALBUM_ENTRY = 'UPDATE_ALBUM_ENTRY';
export type UPDATE_ALBUM_ENTRY = typeof UPDATE_ALBUM_ENTRY;
interface ModifyAlbumEntry {
    type: UPDATE_ALBUM_ENTRY;
    albumId: number;
    entryId: number;
    updatedFields: Partial<AlbumEntryMappedType>;
}

function modifyAlbumEntry(
    albumId: number,
    entryId: number,
    updatedFields: Partial<AlbumEntryMappedType>
): ModifyAlbumEntry {
    return {
        type: UPDATE_ALBUM_ENTRY,
        albumId,
        entryId,
        updatedFields
    };
}

function entryToAlbumPhoto(entry: AlbumEntryMappedType): AlbumPhoto {
    return {
        gatherId: generateUuid(),
        photo: entry,
    };
}

export function loadAlbumPhotosForTask(caseUuid: string, taskId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(setAlbumNetworkBusy(true));
        const album = await getFromAPI<AlbumWithEntries>(`api/case/${caseUuid}/task/${taskId}/album/entries`, dispatch);
        if (album && album.entries) {
            const mappedPhotos = album.entries.map(entryToAlbumPhoto);
            dispatch(setAlbumPhotos(album.id, mappedPhotos));
            dispatch(setAlbumTaskInfo(taskId, { album: omit(album, 'entries'), count: mappedPhotos.length }));
            dispatch(setAlbumDownloadState(album.id, {
                status: album.download_status,
                count: album.download_count,
                url: album.download_url
            }));
        } else {
            dispatch(registerAppError('Unable to load album photos'));
        }
        dispatch(setAlbumNetworkBusy(false));
    };
}

export function reorderPhotos(caseUuid: string, albumId: number, sort: SortEnd, photos: AlbumPhoto[]) {
    return async (dispatch: AppDispatch) => {
        // reorder photos and update rank to match
        const newOrder = arrayMove(photos, sort.oldIndex, sort.newIndex);
        newOrder.forEach((albumPhoto, index) => {
            if (isAlbumEntry(albumPhoto.photo)) {
                albumPhoto.photo.rank = index + 1;
            }
        });
        // update locally for quick response
        dispatch(setAlbumPhotos(albumId, newOrder));

        // now try to update server
        const idMap = newOrder
            .filter(entry => entry.photo)
            .map((entry) => entry.photo ? entry.photo.id : -1);
        const response = await postToAPI<AlbumEntryMappedType[]>(
            `api/case/${caseUuid}/album/${albumId}/reorder`,
            { albumEntryIds: idMap },
            dispatch,
        );
        if (!response) {
            dispatch(registerAppError('Unable to save reordering.'));
        }
    };
}

export function loadRememberPreviewAlbum(caseName: string, caseUuid: string) {
    return async (dispatch: AppDispatch) => {
        await dispatch(setAlbumNetworkBusy(true));
        const response = await getFromAPI<AlbumWithEntries>(`app/remember/${caseName}/album/rememberpreview`, dispatch);
        if (response) {
            await dispatch(setRememberPreviewAlbum(caseUuid, response));
        } else {
            await dispatch(registerAppError('Unable to fetch remember preview album.'));
        }
        await dispatch(setAlbumNetworkBusy(false));
    };
}

export function updateRememberPreviewAlbum(
    caseUuid: string,
    albumId: number,
    photoId: number,
    transformations: PhotoTransformationsType |  null,
    rank: number,
    deleteEntry: AlbumEntryMappedType | undefined,

) {
    return async (dispatch: AppDispatch) => {
        dispatch(setAlbumNetworkBusy(true));
        const response = await postToAPI<AlbumWithEntries>(
            `api/case/${caseUuid}/album/${albumId}/rememberpreview/update`,
            {
                photoId,
                transformations,
                deleteEntry,
                rank,
            },
            dispatch,
        );
        if (response) {
            dispatch(setRememberPreviewAlbum(caseUuid, response));
        } else {
            dispatch(registerAppError('Unable to update album.'));
        }
        dispatch(setAlbumNetworkBusy(false));
    };
}

export function updateAlbumEntry(
    caseUuid: string,
    albumId: number,
    albumEntryId: number,
    updatedFields: Partial<AlbumEntryMappedType>
) {
    return async (dispatch: AppDispatch) => {
        dispatch(modifyAlbumEntry(albumId, albumEntryId, updatedFields));
        const albumEntries = await putToAPI<AlbumEntryMappedType>(
            `api/case/${caseUuid}/album/${albumId}/entry/${albumEntryId}`,
            { updatedFields },
            dispatch,
        );
        if (!albumEntries) {
            return dispatch(registerAppError('Unable to update album entry.'));
        }
    };
}

// this function is more of special case of the following function that allows a quicker update in
// the special case of removing an image from an album
export function removeAlbumEntry(caseUuid: string, albumId: number, albumEntryIds: number[]) {
    return async (dispatch: AppDispatch) => {
        dispatch(removeAlbumEntryLocally(albumId, albumEntryIds));
        const albumEntries = await postToAPI<AlbumEntryMappedType[]>(
            `api/case/${caseUuid}/album/${albumId}/update`,
            { addPhotoViewIds: [], albumEntryIds },
            dispatch,
        );
        if (!albumEntries) {
            return dispatch(registerAppError('Unable to update album.'));
        }
    };
}

export function updateAlbumEntries(
    caseUuid: string,
    albumId: number,
    addPhotoViewIds: number[],
    albumEntryIds: number[]
) {
    // we update the album by deleting any entries we no longer have selected
    // and copying any photo_views we don't have the pictures for.
    return async (dispatch: AppDispatch) => {
        dispatch(setAlbumNetworkBusy(true));
        const albumEntries = await postToAPI<AlbumEntryMappedType[]>(
            `api/case/${caseUuid}/album/${albumId}/update`,
            { addPhotoViewIds, albumEntryIds },
            dispatch,
        );
        dispatch(setAlbumNetworkBusy(false));
        if (!albumEntries) {
            return dispatch(registerAppError('Unable to update album.'));
        }
        const mappedPhotos = albumEntries.map(entryToAlbumPhoto);
        dispatch(setAlbumPhotos(albumId, mappedPhotos));
    };
}

export function loadAlbumTaskInfo(caseUuid: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setAlbumNetworkBusy(true));
        const albumInfoMap = await getFromAPI<AlbumTaskInfoMap>(`api/case/${caseUuid}/album/infomap`, dispatch);
        if (!albumInfoMap) {
            dispatch(registerAppError('Problem loading album info'));
            dispatch(setAlbumNetworkBusy(false));
            return;
        } else {
            dispatch(setAlbumTaskInfoMap(albumInfoMap));
        }
        dispatch(setAlbumNetworkBusy(false));
    };
}

function handleStatusReturn(
    dispatch: AppDispatch,
    albumId: AlbumIdOrCase,
    statusResult: AlbumDownloadState | null,
    errorMessage: string) {
    if (statusResult) {
        dispatch(setAlbumDownloadState(albumId, statusResult));
        if (statusResult.status === AlbumDownloadStatus.failed) {
            dispatch(registerAppError('The download failed, please try again'));
        }
    } else {
        dispatch(setAlbumDownloadState(albumId, {
            status: AlbumDownloadStatus.failed,
            count: 0,
            url: null
        }));
        dispatch(registerAppError(errorMessage));
    }
}

export function startAlbumDownload(caseUuid: string, albumId: AlbumIdOrCase) {
    return async (dispatch: AppDispatch) => {
        // reset for immediate response
        dispatch(setAlbumDownloadState(albumId, {
            status: AlbumDownloadStatus.started,
            count: 0,
            url: null
        }));
        const stateResult = await postToAPI<AlbumDownloadState>(
            `api/case/${caseUuid}/album/${albumId}/download/start`,
            {},
            dispatch
        );
        return handleStatusReturn(dispatch, albumId, stateResult, 'Problem starting album download');
    };
}

export function getAlbumDownloadStatus(caseUuid: string, albumId: AlbumIdOrCase) {
    return async (dispatch: AppDispatch) => {
        const stateResult = await getFromAPI<AlbumDownloadState>(
            `api/case/${caseUuid}/album/${albumId}/download/status`,
            dispatch
        );
        return handleStatusReturn(dispatch, albumId, stateResult, 'Problem updating album download status');
    };
}

export type AlbumAction =
    | SetAlbumNetworkBusy
    | SetAlbumPhotos
    | SetAlbumTaskInfo
    | SetAlbumTaskInfoMap
    | SetAlbumDownloadState
    | SetRememberPreviewAlbum
    | RemoveAlbumEntryLocally
    | ModifyAlbumEntry
    ;