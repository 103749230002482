import * as React from 'react';
import classNames from 'classnames';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';

import { ORANGE_COLOR } from '../../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        position: 'relative',
        margin: 18
    },
    card: {
        borderRadius: 8,
        width: 280,
        '@media (min-width: 360px)': {
            width: 300,
        },
        boxShadow: 'none',
        boxSizing: 'border-box',
        '& $actionAreaRoot': {
            '&:hover': {
                '& $disableFocus': {
                    opacity: 0
                },
            },
        },
        '& $cardContent': {
            padding: 0,
            '&$primary': {
                background: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        '& button': {},
        '&$inverted': {
            background: 'transparent',
            color: theme.palette.primary.main,
            border: '1px solid',
        },
        '&$primary': {
            boxShadow: theme.shadows[7],
            border: `1px solid ${theme.palette.primary.main}`,
            '&$packageCard': {
                background: theme.palette.primary.main,
                color: theme.palette.common.white
            }
        },
        '&$dashed': {
            background: 'transparent',
            color: theme.palette.primary.main,
            border: `1px dashed ${ORANGE_COLOR}`,
        },
        '&$small': {
            maxheight: 106
        },
        '&$medium': {
            maxheight: 190
        },
        '&$large': {
            maxheight: 223
        },
        '&$xlarge': {
            paddingBottom: 4
        },
        '&$auto': {
            height: 'auto'
        },
        '&$xxlargeWide': {
            width: 300
        },
        '&$xsmall': {
            height: 88,
            width: 'auto',
            '& img': {
                height: 88,
                width: 'auto',
            },
        },
        '&$noPrice': {
            height: 'auto',
            minHeight: 214,
        },
        '&$withPrice': {
            height: 'auto',
            minHeight: 252,
        },
        '&$noPriceFlex': {
            height: 'auto',
            minHeight: 214,
            display: 'flex',
        },
        '&$withPriceFlex': {
            height: 'auto',
            minHeight: 252,
            display: 'flex',
        },
    },
    badge: {
        position: 'relative',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '&$topEnd': {
            position: 'absolute',
            top: -18,
            right: -12
        },
        '&$topStart': {
            position: 'absolute',
            top: -13,
            left: -12
        },
        '&$top': {
            position: 'absolute',
            top: -18,
            left: '50%',
            transform: 'translateX(-50%)'
        },
        '&$bottom': {
            top: -18
        },
        '&$small': {
            top: -13,
            '&$top': {
                top: '-14px !important',
            }
        },
        '&$bottomStart': {
            position: 'absolute',
            left: 0,
            transform: 'translate(-40%, -60%)',
        },
    },
    hidden: {
        display: 'none !important'
    },
    disabled: {
        opacity: 0.3,
    },
    actionAreaRoot: {},
    disableFocus: {},
    active: {},
    small: {},
    medium: {},
    large: {},
    xlarge: {},
    auto: {},
    top: {},
    topStart: {},
    topEnd: {},
    bottom: {},
    bottomStart: {},
    primary: {},
    inverted: {},
    cardContent: {},
    xxlargeWide: {},
    xsmall: {},
    packageCard: {},
    dashed: {},
    noPrice: {},
    withPrice: {},
    noPriceFlex: {},
    withPriceFlex: {},
});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'card' | 'active' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlargeWide' | 'badge'
        | 'topStart' | 'topEnd' | 'bottom' | 'primary' | 'inverted' | 'cardContent' | 'disableFocus'
        | 'actionAreaRoot' | 'top' | 'auto' | 'hidden' | 'width380' | 'xsmall' | 'packageCard' | 'disabled'
        | 'noPrice' | 'withPrice' | 'noPriceFlex' | 'withPriceFlex' | 'dashed' | 'worldIcon' | 'bottomStart'>;

export interface CardBadge {
    position: 'topStart' | 'topEnd' | 'top' | 'bottom' | 'bottomStart';
    badge: JSX.Element;
    hidden?: boolean;
    tooltipTitle?: string;
    tooltipEnterDelay?: number;
    size?: 'small';
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface Props {
    size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlargeWide' | 'auto' | 'noPrice' | 'withPrice'
    | 'noPriceFlex' | 'withPriceFlex';
    color: 'primary' | 'inverted' | 'dashed';
    disableRipple?: boolean;
    badges?: CardBadge[];
    disableFocus?: boolean;
    disabled?: boolean;
    outsideButtonContent?: JSX.Element | null;
    productItems?: JSX.Element | null;
    onClick?: () => void;
}

const ProductItemCardBase = (props: React.PropsWithChildren<StyledProps>) => {

    const {
        classes,
        color,
        size,
        children,
        badges,
        disabled,
        disableFocus,
        disableRipple,
        outsideButtonContent,
        productItems,
        onClick
    } = props;

    return (
        <div className={classNames(classes.root, disabled && classes.disabled)}>
            <Card
                className={classNames(
                    classes.card,
                    classes[color],
                    classes[size],
                    !productItems && classes.packageCard
                )}
            >
                <CardActionArea
                    disabled={disabled}
                    disableRipple={disableRipple}
                    classes={{
                        root: classes.actionAreaRoot,
                        focusHighlight: disableFocus && classes.disableFocus || undefined
                    }}
                    onClick={e => onClick && onClick() || undefined}
                >
                    <CardContent className={classNames(classes.cardContent, classes[color])}>
                        {children}
                    </CardContent>
                    {productItems}
                </CardActionArea>
                {outsideButtonContent}
            </Card>
            {(badges || []).map((b, i) =>
                <Tooltip key={i} title={b.tooltipTitle || ''} placement="top" enterDelay={b.tooltipEnterDelay}>
                    <div
                        className={classNames(
                            classes.badge,
                            classes[b.position],
                            b.size && classes[b.size],
                            b.hidden && classes.hidden,
                            !b.onClick && classes.cursorDefault
                        )}
                        onClick={!b.onClick ? undefined : (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (b.onClick) {
                                b.onClick(e);
                            }
                        }}
                    >
                        {b.badge}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default withGStyles(styles)(ProductItemCardBase);
