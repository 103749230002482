import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import { WithGStyles } from '../../../../styles/WithGStyles';

export function styleWrapper<Props extends object>() {
    const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
        root: {
            position: 'relative',
            paddingTop: 24,
            '@media (min-width: 960px)': {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
            },
        },
        rightPane: {
            width: '100%',
            padding: 0,
            margin: '24px 0 0',
            '@media (min-width: 960px)': {
                width: 'calc(100% - 440px)',
                height: 'calc(100vh - 227px)',
                overflowY: 'auto',
                margin: 0,
            },
        },
        ulGrid: {
            overflowX: 'scroll',
            '-webkit-overflow-scrolling': 'touch',
            transition: 'all 1s ease',
            transform: 'translateY(100%) scale(0)',
            opacity: 0,
            '&$show': {
                opacity: 1,
                transform: 'translateY(0) scale(1)',
            },
            '& $ul': {
                overflow: 'unset',
                width: 'fit-content',
                flexDirection: 'row',
                alignItems: 'flex-start',
                margin: '12px auto',
                '& $li': {
                    margin: '0 12px',
                    color: theme.palette.primary.main,
                    overflow: 'hidden auto',
                    '&:first-of-type': {
                        marginLeft: 24,
                    },
                    '&:last-child': {
                        marginRight: 24,
                    },
                },
                '& $listTile': {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                },
            },
        },
        progressBar: {
            top: 0,
            left: 0,
            width: 'calc(100% - 48px)',
            margin: 24,
            '@media (min-width: 960px)': {
                width: 'calc(100% - 468px)',
                position: 'absolute',
                left: 420,
                top: 'auto',
                margin: '0 24px',
            }
        },
        linkStyle: {
            textDecoration: 'none',
            display: 'none'
        },
        transitionGroup: {
            display: 'flex',
            overflow: 'unset',
            width: 'fit-content',
            alignItems: 'flex-start',
        },
        fillPrimary: {
            fill: `${theme.palette.primary.main} !important`
        },
        listTile: {},
        ul: {},
        li: {},
        show: {},
    });

    return styles;
}

export type StyledProps = WithGStyles<'root' | 'ulGrid' | 'ul' | 'li' | 'listTile' | 'progressBar'
    | 'rightPane' | 'show' | 'linkStyle' | 'transitionGroup' | 'fillPrimary'>;
