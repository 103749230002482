import { useEffect, useMemo, useState } from 'react';

import { EntitySummary, GatherCaseDashboardUx, UserProfile } from '../../../shared/types';
import Typography from '@mui/material/Typography';

import AssignmentPopperForEntitySummary from '../AssignmentPopperForEntitySummary';
import { GStyles } from '../../../styles/GStyles';
import { canInviteOtherTeamMembers } from '../../../shared/authority/can';
import { TOOLTIPS } from '../../../constants';
import { log } from '../../../logger';
import makeStyles from '@mui/styles/makeStyles';
import { difference } from 'lodash';
import { Theme } from '@mui/material';
import { ButtonText } from '../AssignmentPopper';

const useStyles = makeStyles(
    (theme: Theme) => ({
        assignButton: {
            textTransform: 'uppercase',
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 500,
        },
    }),
    { name: 'AssignCaseWatchersPure' },
);

export interface AssignCaseWatchersPureProps {
    activeCase: Pick<GatherCaseDashboardUx, 'funeral_home_id' | 'uuid' | 'watchers'>;
    zIndex: number;
    addLabel?: string;
    textClass?: string;
    buttonTextOverride?: ButtonText;
    type?: 'button' | 'menuItem';
    userData: UserProfile;
    team: EntitySummary[];
    isLoading?: boolean;
    onWatchersChanged: (updatedWatcherUserIds: number[], watchers: EntitySummary[]) => void;
    onInviteTeamMember?: (zIndex: number, onAddedCallback?: (tm: EntitySummary) => void) => void;
    onPopperOpened?: () => void;
}

const AssignCaseWatchersPure = (props: AssignCaseWatchersPureProps) => {
    const {
        activeCase,
        zIndex,
        addLabel,
        textClass,
        buttonTextOverride,
        type = 'button',
        userData,
        team,
        isLoading,
        onWatchersChanged,
        onInviteTeamMember,
        onPopperOpened,
    } = props;

    const classes = useStyles();

    const existingWatcherUserIds = useMemo(() => activeCase.watchers &&
        activeCase.watchers.map((w) => w.user_id), [activeCase.watchers]);

    const [localWatcherUserIds, setLocalWatcherUserIds] = useState<number[]>(existingWatcherUserIds);
    // only used when type == 'menuItem'
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setLocalWatcherUserIds(existingWatcherUserIds);
    }, [existingWatcherUserIds]);

    const inviteTeamMemberAllowed = canInviteOtherTeamMembers(userData, activeCase.funeral_home_id || null);

    const handleUpdateWatchers = (watchers: EntitySummary[]) => {
        const updatedWatcherUserIds = watchers.map((w) => {
            if (!w.user_id) {
                // -1 should not happen. All team members are users
                log.warn('Watcher does not have a user_id', { watchers });
                return -1;
            }
            return w.user_id;
        });

        const added = difference(updatedWatcherUserIds, existingWatcherUserIds);
        const removed = difference(existingWatcherUserIds, updatedWatcherUserIds);

        if (type === 'menuItem') {
            setAnchorEl(null);
        }

        if (added.length === 0 && removed.length === 0) {
            return;
        }

        setLocalWatcherUserIds(updatedWatcherUserIds);
        onWatchersChanged(updatedWatcherUserIds, watchers);
    };

    const assignedTo = useMemo(() => {
        const mappedData = team.filter((tm) => localWatcherUserIds &&
            localWatcherUserIds.find((userId) => userId === tm.user_id));
        return mappedData;
    }, [team, localWatcherUserIds]);

    const buttonText = useMemo(() => {
        if (buttonTextOverride) {
            return buttonTextOverride;
        }
        let label: string | JSX.Element;
        if (assignedTo.length) {
            if (assignedTo.length === 1) {
                label = (
                    <span>
                        <span className={GStyles.textUnderline}>{assignedTo[0].fname}</span>
                        &nbsp;is a watcher
                    </span>
                );
            } else {
                label = (
                    <span>
                        <span className={GStyles.textUnderline}>{assignedTo.length}</span>
                        &nbsp;watchers
                    </span>
                );
            }
        } else {
            label = addLabel ?? '+ Case Watcher';
        }

        return {
            assignedTo: [],
            hideAvatar: true,
            label,
        };
    }, [assignedTo, addLabel, buttonTextOverride]);

    const menuItem = (
        <Typography
            component="span"
            className={textClass}
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                e.persist();
                setAnchorEl(e?.currentTarget || null);
            }}
        >
            {buttonText.label}
        </Typography>
    );

    const handleInviteTM = (_zIndex: number) => {
        const callbackOnInviteSuccess = (teamMember: EntitySummary) =>
            setLocalWatcherUserIds((prev) => {
                if (teamMember.user_id) {
                    return [...prev, teamMember.user_id];
                }
                return prev;
            });
        onInviteTeamMember?.(_zIndex, callbackOnInviteSuccess);
    };

    return (
        <AssignmentPopperForEntitySummary
            zIndex={zIndex}
            headerText="Add a Watcher to this case..."
            addButtonText="Invite Team Member"
            popoverTextClass={textClass ?? classes.assignButton}
            popoverElement={type === 'menuItem' ? menuItem : undefined}
            anchorElement={type === 'menuItem' && anchorEl ? anchorEl : undefined}
            onAdd={handleInviteTM}
            items={team}
            isLoading={isLoading}
            assignedTo={assignedTo}
            setAssignee={(_, watchers) => handleUpdateWatchers(watchers)}
            showToolipForAllUsers
            showTooltipOnCheckbox
            isMultiSelect
            buttonText={buttonText}
            addButtonDisabled={!inviteTeamMemberAllowed}
            addButtonDisabledToolTip={TOOLTIPS.DISABLED_FEATURE}
            onPopperOpened={onPopperOpened}
            showFamilyHelpers={false}
            showTeamMembers={true}
        />
    );
};

export default AssignCaseWatchersPure;
