import {
    CaseHelperCreateRequest,
    CaseHelperCreateResponse,
    CaseHelperUpdateRequest,
    EntitySummary,
    EntityUpdateValidateError,
    isEntityUpdateValidateError,
    LoadCaseHelpersResponse,
    PhotoTransformationsType,
    UserInviteStatus,
    UserInviteStatusEnum,
    UserPatchRequest,
    UserProfile,
    UserRoles,
} from '../shared/types';
import { advancedAPIRequest, deleteFromAPI, getFromAPI, parseJSON, patchAPI, postToAPI } from '.';
import { registerAppError } from './errors';

import { setSnackbarSuccess } from './AppSnackbar.action';
import { uploadPhoto } from './Photo.action';
import { updateLoginUser } from './UserSession.action';
import { log } from '../logger';
import { AppDispatch } from '../store';
import { canRetrievePrivateCaseData } from '../shared/authority/can';
import { StoreState } from '../types';

export const ADD_CASE_HELPER = 'ADD_CASE_HELPER';

interface AddCaseHelper {
    type: typeof ADD_CASE_HELPER;
    helper: EntitySummary;
}

function addCaseHelper(helper: EntitySummary): AddCaseHelper {
    return {
        helper,
        type: ADD_CASE_HELPER,
    };
}

export const SET_CASE_HELPER = 'SET_CASE_HELPER';

interface SetCaseHelper {
    type: typeof SET_CASE_HELPER;
    helper: EntitySummary;
}

function setCaseHelper(helper: EntitySummary): SetCaseHelper {
    return {
        type: SET_CASE_HELPER,
        helper,
    };
}

export const REMOVE_CASE_HELPER = 'REMOVE_CASE_HELPER';

interface RemoveCaseHelper {
    type: typeof REMOVE_CASE_HELPER;
    entityId: number;
}

function removeCaseHelper(entityId: number): RemoveCaseHelper {
    return {
        type: REMOVE_CASE_HELPER,
        entityId,
    };
}

export const SET_CASE_HELPERS = 'SET_CASE_HELPERS';

interface SetCaseHelpers {
    type: typeof SET_CASE_HELPERS;
    caseUuid: string;
    helpers: EntitySummary[];
}

function setCaseHelpers(caseUuid: string, helpers: EntitySummary[]): SetCaseHelpers {
    return {
        type: SET_CASE_HELPERS,
        caseUuid,
        helpers,
    };
}

export const CASE_HELPERS_LOADING = 'CASE_HELPERS_LOADING';

interface CaseHelpersLoading {
    type: typeof CASE_HELPERS_LOADING;
}

function caseHelpersLoading(): CaseHelpersLoading {
    return {
        type: CASE_HELPERS_LOADING,
    };
}

export const CASE_HELPERS_LOADED = 'CASE_HELPERS_LOADED';

interface CaseHelpersLoaded extends LoadCaseHelpersResponse {
    type: typeof CASE_HELPERS_LOADED;
}

function caseHelpersLoaded(response: LoadCaseHelpersResponse): CaseHelpersLoaded {
    return {
        type: CASE_HELPERS_LOADED,
        ...response,
    };
}

export const CASE_HELPERS_LOAD_FAILED = 'CASE_HELPERS_LOAD_FAILED';

interface CaseHelpersLoadFailed {
    type: typeof CASE_HELPERS_LOAD_FAILED;
}

function caseHelpersLoadFailed(): CaseHelpersLoadFailed {
    return {
        type: CASE_HELPERS_LOAD_FAILED,
    };
}

export const SET_INVITATION_STATUS = 'SET_INVITATION_STATUS';
export type SET_INVITATION_STATUS = typeof SET_INVITATION_STATUS;

interface SetInvitationStatus {
    type: SET_INVITATION_STATUS;
    status: UserInviteStatus | null;
}

function setInvitationStatus(status: UserInviteStatus | null): SetInvitationStatus {
    return {
        type: SET_INVITATION_STATUS,
        status,
    };
}

export const SET_POSSIBLE_USER_MATCHES = 'SET_POSSIBLE_USER_MATCHES';
export type SET_POSSIBLE_USER_MATCHES = typeof SET_POSSIBLE_USER_MATCHES;

export interface SetSimilarUsers {
    type: SET_POSSIBLE_USER_MATCHES;
    similarUsers: UserProfile[];
}

function setSimilarUsers(similarUsers: UserProfile[]): SetSimilarUsers {
    return {
        type: SET_POSSIBLE_USER_MATCHES,
        similarUsers,
    };
}

export const inviteCaseHelper = (caseUuid: string, user: CaseHelperCreateRequest) => {
    return async (dispatch: AppDispatch): Promise<CaseHelperCreateResponse | null> => {
        try {
            CaseHelperCreateRequest.fromRequest(user);
        } catch (ex) {
            log.warn('Failed to validate CaseHelperCreateRequest:', { user, ex });
            return null;
        }

        dispatch(setInvitationStatus(UserInviteStatusEnum.pending));
        const response = await postToAPI<CaseHelperCreateResponse>(`api/case/${caseUuid}/helper`, { user }, dispatch);
        if (response) {
            const { status, helpers, similarUsers } = response;
            dispatch(setInvitationStatus(status));
            if (helpers && status === UserInviteStatusEnum.success) {
                dispatch(setCaseHelpers(caseUuid, helpers));
            } else if (
                similarUsers &&
                (status === UserInviteStatusEnum.multiple_users_found || UserInviteStatusEnum.different_name_found)
            ) {
                dispatch(setSimilarUsers(similarUsers));
            }
            return response;
        } else {
            dispatch(registerAppError('Unable to invite helper'));
            dispatch(setInvitationStatus(null));
        }
        return null;
    };
};

export const resendInvitation = (caseUuid: string, helper: EntitySummary) => {
    return async (dispatch: AppDispatch): Promise<EntitySummary[] | null> => {
        if (!helper.user) {
            log.warn('Trying to resend invitation for non-user', { helper, caseUuid });
            return null;
        }
        const resource = `api/case/${caseUuid}/helper/${helper.entity_id}/resend`;
        const helpers = await postToAPI<EntitySummary[]>(resource, {}, dispatch);
        if (helpers) {
            dispatch(setCaseHelpers(caseUuid, helpers));
            dispatch(setSnackbarSuccess('Successfully resent invitation'));
            return helpers;
        } else {
            dispatch(registerAppError('Unable to resend invitation'));
            return null;
        }
    };
};

export const SET_CASE_HELPER_PHOTO_SAVING = 'SET_CASE_HELPER_PHOTO_SAVING';

interface SetCaseHelperPhotoSaving {
    type: typeof SET_CASE_HELPER_PHOTO_SAVING;
    entityId: number;
    isPhotoSaving: boolean;
}

function setCaseHelperPhotoSaving(entityId: number, isPhotoSaving: boolean): SetCaseHelperPhotoSaving {
    return {
        type: SET_CASE_HELPER_PHOTO_SAVING,
        entityId,
        isPhotoSaving,
    };
}

export function updateCaseHelperPhotoByEntityId(
    entityId: number,
    photo: string,
    transformations: PhotoTransformationsType,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch): Promise<EntitySummary | null> => {
        // need to upload photo to Cloudinary first
        dispatch(setCaseHelperPhotoSaving(entityId, true));

        const path = `api/case/${caseUuid}/helper/${entityId}/photo`;

        const gatherSignatureURL = `${path}/signature`;

        const cloudinartResult = await uploadPhoto(photo, gatherSignatureURL, dispatch);
        if (cloudinartResult) {
            const updatedHelper = await postToAPI<EntitySummary>(
                path,
                {
                    public_id: cloudinartResult.public_id,
                    width: cloudinartResult.width,
                    height: cloudinartResult.height,
                    transformations,
                },
                dispatch,
            );
            if (updatedHelper) {
                dispatch(setCaseHelper(updatedHelper));

                // Create an event for pages to listen for
                const event = new Event('gather.user_profile.photo_update', { bubbles: true, cancelable: true });
                document.dispatchEvent(event);
                dispatch(setCaseHelperPhotoSaving(entityId, false));
                return updatedHelper;
            } else {
                dispatch(registerAppError('Unable to set photo.'));
            }
        } else {
            dispatch(registerAppError('Unable to upload photo.'));
        }
        dispatch(setCaseHelperPhotoSaving(entityId, false));
        return null;
    };
}

export function updateCaseHelperPhoto(
    helper: EntitySummary,
    photo: string,
    transformations: PhotoTransformationsType,
    caseUuid: string,
) {
    return updateCaseHelperPhotoByEntityId(helper.entity_id, photo, transformations, caseUuid);
}

export const resetInvitationStatus = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setInvitationStatus(null));
    };
};
export const removeHelper = (caseUuid: string, helper: EntitySummary) => {
    return async (dispatch: AppDispatch) => {
        dispatch(removeCaseHelper(helper.entity_id));
        const helpers = await deleteFromAPI<EntitySummary[]>(
            `api/case/${caseUuid}/helper/${helper.entity_id}`,
            dispatch,
        );
        if (helpers) {
            dispatch(setCaseHelpers(caseUuid, helpers));
            dispatch(setSnackbarSuccess(`Successfully removed ${helper.fname}`));
        } else {
            // add helper back
            dispatch(addCaseHelper(helper));
            dispatch(registerAppError(`Unable to remove ${helper.fname}.`));
        }
    };
};

export function updateCaseHelper(params: {
    gatherCase: { id: number; uuid: string; funeral_home_id: number; name: string };
    existingEntity: EntitySummary;
    changes: CaseHelperUpdateRequest;
}) {
    return async (
        dispatch: AppDispatch,
        getState: () => StoreState,
    ): Promise<EntitySummary | EntityUpdateValidateError | null> => {
        const { gatherCase, existingEntity, changes } = params;
        const { userData } = getState().userSession;

        try {
            CaseHelperUpdateRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate CaseHelperUpdateRequest', { changes, ex });
            return null;
        }

        if (existingEntity.user || existingEntity.user_id) {
            const finalEmail = changes.email !== undefined ? changes.email : existingEntity.email;
            const finalPhone = changes.phone !== undefined ? changes.phone : existingEntity.phone;
            if (!finalEmail && !finalPhone) {
                dispatch(registerAppError('Case Helpers must have a valid phone or email.'));
                return null;
            }
        }

        const canGetPrivateData = canRetrievePrivateCaseData({
            target: userData,
            caseId: gatherCase.id,
            funeralHomeId: gatherCase.funeral_home_id,
        });

        const isVistitor = UserRoles.isFamilyVisitor(existingEntity, gatherCase.id);

        // NOTE example: http://localhost:3002/api/remember/john-doe/helper/1007
        const urlRoot = canGetPrivateData ? `api/case/${gatherCase.uuid}` : `api/remember/${gatherCase.name}`;
        const response = await advancedAPIRequest(
            `${urlRoot}/helper/${existingEntity.entity_id}`,
            'PUT',
            { entity: changes },
            dispatch,
        );

        if (response) {
            if (response.status === 409 || response.ok) {
                const body = await parseJSON(response);

                if (isEntityUpdateValidateError(body)) {
                    return body;
                }
                const helpers: EntitySummary[] = body;
                // TODO: JJT (AKT) visitors won't be in the helpers list
                // -- consider a different route or return the updated visitor in response
                if (isVistitor) {  // for visitors there will be no real data to update. 
                    return null;
                }

                const updatedHelper = helpers.find((helper) => helper.entity_id === existingEntity.entity_id);
                if (updatedHelper) {
                    dispatch(setCaseHelper(updatedHelper));
                }
                dispatch(setCaseHelpers(gatherCase.uuid, helpers));
                const updatedEntity = helpers.find((e) => e.entity_id === existingEntity.entity_id);
                return updatedEntity || null;
            }
        }
        dispatch(registerAppError('Unable to update helper.'));
        return null;
    };
}

export function patchCaseHelper(caseUuid: string, entityId: number, changes: UserPatchRequest) {
    return async (dispatch: AppDispatch): Promise<UserProfile | null> => {
        try {
            UserPatchRequest.fromRequest(changes);
        } catch (error) {
            log.warn('Failed to validate UserPatchRequest:', { changes, error });
            return null;
        }

        const helper = await patchAPI<UserProfile>(
            `api/case/${caseUuid}/helper/${entityId}`,
            { userData: changes },
            dispatch,
        );

        if (helper) {
            dispatch(updateLoginUser(changes));
            return helper;
        }

        return null;
    };
}

export function loadCaseHelpers(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<EntitySummary[] | null> => {
        dispatch(caseHelpersLoading());
        const response = await getFromAPI<LoadCaseHelpersResponse | null>(`api/case/${caseUuid}/helper/`, dispatch);
        if (!response) {
            dispatch(registerAppError('Could not load helpers.'));
            dispatch(caseHelpersLoadFailed());
            return null;
        }

        dispatch(caseHelpersLoaded(response));
        return response.helpers;
    };
}

export type InviteHelperAction =
    | SetInvitationStatus
    | SetSimilarUsers
    | AddCaseHelper
    | SetCaseHelper
    | RemoveCaseHelper
    | SetCaseHelpers
    | CaseHelpersLoaded
    | CaseHelpersLoading
    | CaseHelpersLoadFailed
    | SetCaseHelperPhotoSaving;
