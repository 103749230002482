import classNames from 'classnames';

import makeGStyles from '../../../../../styles/makeGStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import GSwitch from '../../../../common/inputElements/GSwitch';
import { updateStepVisibility } from '../../../../../actions/task/Task.action';
import { useGDispatch } from '../../../../../types';

const useStyles = makeGStyles(theme => ({
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        paddingBottom: 12,
        width: 'fit-content',
    },
    switchContainerInner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gSwitch: {
        '& label': {
            margin: 0,
            maxHeight: 20,
        },
    },
    primaryColor: {
        color: props => `${theme.palette.primary.main} !important`,
    },
    switchBar: {
        backgroundColor: props => `${theme.palette.primary.main} !important`,
    }
}), { name: 'FamilySharingSwitch' });

interface Props {
    stepId: number;
    stepTitle: string;
    stepVisibleToFamily: boolean;
    caseUuid: string;
    switchTextClass?: string;
}

const FamilySharingSwitch = (props: Props) => {

    const {
        stepId,
        stepTitle,
        stepVisibleToFamily,
        caseUuid,
        switchTextClass,
    } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const changeVisibility = (visibility: boolean) => {

        return dispatch(updateStepVisibility({
            stepId,
            visibleToFamily: visibility,
            caseUuid: caseUuid,
        }));
    };

    return (
        <div className={classes.padding_0_20}>
            <Grid className={classes.switchContainer}>
                <Typography color="secondary" className={switchTextClass}>
                    Is "{stepTitle}" visible to helpers?
                </Typography>
                <Grid
                    item
                    xs={12}
                    className={classes.switchContainerInner}
                >
                    <label
                        className={classNames(classes.colorSecondary, classes.cursorPointer)}
                        htmlFor="family-sharing-switch"
                    >
                        No
                    </label>
                    <div className={classes.gSwitch}>
                        <GSwitch
                            checked={stepVisibleToFamily}
                            onChangeCallBack={(checked) => changeVisibility(checked)}
                            id="family-sharing-switch"
                            iconCheckedClass={classes.primaryColor}
                            switchCheckedClass={classes.primaryColor}
                            barClass={classes.switchBar}
                        />
                    </div>
                    <label
                        htmlFor="family-sharing-switch"
                        className={classNames(
                            classes.cursorPointer,
                            stepVisibleToFamily ? classes.colorPrimary : classes.colorSecondary
                        )}
                    >
                        Yes
                    </label>
                </Grid>
            </Grid>
        </div>
    );
};

export default FamilySharingSwitch;
