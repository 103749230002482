import * as React from 'react';
import Slide, { SlideProps } from '@mui/material/Slide';
import Zoom, { ZoomProps } from '@mui/material/Zoom';
import { Fade, FadeProps } from '@mui/material';

export const SlideTransition = React.forwardRef(
    function SlideTransition(props: SlideProps, ref: React.Ref<unknown>) {
        return <Slide {...props} direction="up" ref={ref} timeout={300} />;
    }
);

export const ZoomTransition = React.forwardRef(
    function ZoomTransition(props: ZoomProps, ref: React.Ref<unknown>) {
        return <Zoom  {...props} ref={ref} />;
    }
);

export const FadeTransition = React.forwardRef(
    function FadeTransition(props: FadeProps, ref: React.Ref<unknown>) {
        return <Fade {...props} ref={ref} />;
    }
);
