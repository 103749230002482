import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import { convertHexToRGBA } from '../../../../services';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '&$none': {
            padding: 0
        },
        '&$y': {
            padding: '8px 0'
        },
        '&$x': {
            padding: '0 16px',
        },
        '&$primary': {
            color: convertHexToRGBA(theme.palette.primary.main, 0.8)
        },
        '&$secondary': {
            color: convertHexToRGBA(theme.palette.secondary.main, 0.8)
        },
        '&$inherit': {
            color: 'inherit'
        },
        '& $inner': {
            display: 'flex',
            margin: '0 0 0 12px',
            alignItems: 'center',
            '& $precedent': {
                marginRight: 8
            },
            '& $descendant': {
                marginLeft: 8
            }
        }
    },
    divider: {
        borderTop: '1px solid',
        height: 0
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 13,
        lineHeight: '32px'
    },
    precedent: {},
    descendant: {},
    primary: {},
    secondary: {},
    inherit: {},
    x: {},
    y: {},
    none: {},
    inner: {}
});

type StyledProps = Props & WithGStyles<'root' | 'divider' | 'label' | 'primary' | 'secondary' | 'inherit' | 'x' | 'y'
    | 'inner' | 'precedent' | 'descendant' | 'none'>;

export interface Props {
    color: 'primary' | 'secondary' | 'inherit';
    inset: 'x' | 'y' | 'none';
    label?: string;
    icon?: {
        root: JSX.Element;
        position?: 'precedent' | 'descendant';
    };
}

class HorizontalDivider extends Component<StyledProps> {

    render() {
        const { classes, label, color, inset, icon } = this.props;

        return (
            <Grid container justifyContent="center" alignItems="center">
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.root,
                        classes[color],
                        classes[inset]
                    )}
                >
                    <div className={classes.divider} />

                    <div className={classes.inner} >
                        {icon && icon.position && icon.position === 'precedent' &&
                            <SvgIcon color={color} className={classes[icon.position]}>
                                {icon.root}
                            </SvgIcon>
                        }

                        {label &&
                            <Typography
                                color="secondary"
                                variant="subtitle2"
                                className={classes.label}
                            >
                                {label}
                            </Typography>
                        }

                        {icon && icon.position && icon.position === 'descendant' &&
                            <SvgIcon color={color} className={classes[icon.position]}>
                                {icon.root}
                            </SvgIcon>
                        }
                    </div>

                </Grid>
            </Grid>
        );
    }
}

export default withGStyles(styles)(HorizontalDivider);
