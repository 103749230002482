import * as React from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ORANGE_COLOR } from '../../../../constants/colorVariables';

import ClearIcon from '@mui/icons-material/Clear';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PagesIcon from '@mui/icons-material/Pages';

import { GLOBAL_STYLED_PROPS } from '../../../../styles';

import Badge from '../widgets/Badge';
import PriceWidget from '../widgets/PriceWidget';
import {
    ProductPackageUX,
    GPLContext,
} from '../../../../shared/types';
import { getPhotoUrl } from '../../../../services';
import {
    calculatePackagePercentDiscount,
    getPackagePrice,
    calculateListPriceRangeOfPackage,
    formatDinero,
} from '../../../../shared/goods_and_services/pricing';
import { getPackageItemName } from '../../../../shared/goods_and_services/packages';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withGStyles from '../../../../styles/WithGStyles';
import { SlideTransition } from '../../../common/Transitions';
import useFullScreen from '../../../common/hooks/useFullScreen';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';

interface Props {
    caseAssignee: string;
    canUserEditContract: boolean;
    productPackage: ProductPackageUX | null;
    isSelected: boolean;
    isDialogOpen: boolean;
    context: GPLContext;
    closeDialog: () => void;
    onAddPackage?: (packageId: number) => void;
    onRemovePackage?: (packageId: number) => void;
    handleClickEventOnAvatar?: (event: React.MouseEvent<HTMLElement>) => void;
    onEditClick?: () => void;
    openPhotoSwipeDialog: (activeImage: string | null, photos: string[]) => void;
    zIndex: number;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            width: '100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 720,
                width: 720,
                maxHeight: '90vh'
            }
        },
    },
    dialogContent: {
        zIndex: 0,
        padding: 0,
        paddingTop: '0 !important',
    },
    clearIcon: {
        zIndex: 1,
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        cursor: 'pointer',
        filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,.8))',
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    ulGrid: {
        display: 'flex',
        justifyContent: 'center',
        '& $ul': {
            margin: 'auto',
            backgroundColor: theme.palette.common.white,
            '& $li': {
                margin: 0,
                overflow: 'hidden'
            }
        },
    },
    imageContainer: {
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.common.white,
        width: 380,
        height: 235,
        margin: 0,
    },
    detailsGrid: {
        padding: '8px 16px',
        [theme.breakpoints.up('sm')]: {
            flexBasis: '50%',
            padding: '0 16px',
        },
    },
    heading: {
        textAlign: 'center',
        fontSize: 32,
        textTransform: 'capitalize'
    },
    details: {
        marginTop: 16,
        fontSize: 18,
        fontWeight: 200,
        whiteSpace: 'pre-line'
    },
    button: {
        margin: '8px auto',
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: 400,
        textTransform: 'capitalize',
        padding: '0 16px 0 0',
        lineHeight: '1em',
        letterSpacing: 'normal',
    },
    footer: {
        backgroundColor: theme.palette.common.white,
        position: 'fixed',
        width: 'calc(100% - 32px)',
        bottom: 0,
        left: 16,
        margin: 0,
        color: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid',
        [theme.breakpoints.up('md')]: {
            margin: '0 16px',
            position: 'unset',
            width: '100%',
        }
    },
    saveBadge: {
        color: 'white',
        width: '100%',
        margin: 'auto',
        border: '2px solid',
        borderRadius: 24,
        height: 28,
        zIndex: 1
    },
    saveBadgeWithoutImages: {
        margin: '8px auto',
    },
    itemUlContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        [theme.breakpoints.up('sm')]: {
            flexBasis: '50%',
            marginBottom: 0,
            width: '50%',
        },
        '& $itemUl': {
            margin: 0,
            padding: 0,
            width: '100%',
            listStyleType: 'none',
            overflowY: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '& $li': {
                margin: 0,
                justifyContent: 'center',
                width: '100%',
                '& p': {
                    fontWeight: 200,
                    fontSize: 18,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '0 8px',
                    textAlign: 'center'
                },
                '& $special': {
                    fontWeight: 400,
                    fontStyle: 'italic'
                },
                '&:first-of-type': {
                    marginTop: 8,
                    marginBottom: 10
                },
                '&:last-child': {
                    marginBottom: 8,
                },
            }
        },
    },
    divider: {
        width: 'calc(100% - 32px)',
        height: 1,
        background: theme.palette.secondary.main,
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: 1,
            height: 'calc(100% - 32px)',
        }
    },
    main: {
        width: '100%',
        minHeight: 200,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            height: 'calc(100vh - 330px)'
        },
        [theme.breakpoints.up('md')]: {
            height: 'auto'
        }
    },
    mainWithoutImages: {
        height: 'calc(100vh - 80px)',
        [theme.breakpoints.up('md')]: {
            height: 'auto'
        }
    },
    restrictedFooter: {
        margin: '16px auto',
        '& span': {
            cursor: 'pointer'
        }
    },
    dashedBorder: {
        border: `1px dashed ${ORANGE_COLOR}`,
        borderRadius: 4,
    },
    editButton: {
        background: `${ORANGE_COLOR} !important`,
        color: theme.palette.common.white,
        margin: 8,
    },
    transformMe: {
        cursor: 'pointer',
        transform: 'scale(1)',
        transition: 'transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    itemUl: {},
    dialogPaper: {},
    li: {},
    ul: {},
    liTile: {},
    special: {},
    sign: {},
});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'dialogHeader' | 'dialogPaper' | 'dialogContent' | 'clearIcon' | 'imageContainer'
        | 'ul' | 'li' | 'heading' | 'details' | 'detailsGrid' | 'button' | 'restrictedFooter'
        | 'footer' | 'ulGrid' | 'liTile' | 'saveBadge' | 'saveBadgeWithoutImages' | 'divider'
        | 'itemUlContainer' | 'itemUl' | 'special' | 'main' | 'mainWithoutImages' | 'sign' | 'dashedBorder'
        | 'editButton' | 'transformMe'>;

const PackageDialog = (props: StyledProps) => {
    const {
        classes,
        productPackage,
        isSelected,
        isDialogOpen,
        closeDialog,
        onAddPackage,
        onRemovePackage,
        canUserEditContract,
        caseAssignee,
        handleClickEventOnAvatar,
        context,
        onEditClick,
        openPhotoSwipeDialog,
        zIndex,
    } = props;
    const fullScreen = useFullScreen();

    const topscrollRef = React.useRef<HTMLDivElement>(null);

    if (!productPackage) {
        return null;
    }

    const isBackOffice = context === GPLContext.BackOffice;
    const isPublicGPL = context === GPLContext.Public;

    const handleClose = () => {
        if (topscrollRef.current) {
            topscrollRef.current.scrollIntoView({
                block: 'start',
                inline: 'start',
            });
        }

        closeDialog();
    };

    const handleClick = () => {
        if (isSelected && onRemovePackage) {
            onRemovePackage(productPackage.id);
        } else if (!isSelected && onAddPackage) {
            onAddPackage(productPackage.id);
        }
    };

    const renderImages = (photos: string[]) => {
        const transformations = [{
            crop: 'crop',
            aspect_ratio: (380 / 235).toFixed(2),
        }, {
            width: 380,
            height: 235,
            quality: 'auto',
            fetch_format: 'auto',
            crop: 'limit',
        }];

        return (
            <Grid item xs={12} className={classes.ulGrid}>
                <ul className={classNames(classes.ul, classes.list)}>
                    {photos.map((photo) =>
                        <Zoom in timeout={1200} key={photo}>
                            <ImageListItem className={classes.li}>
                                <Tooltip title="Click to view full-screen" placement="top">
                                    <img
                                        className={classNames(classes.imageContainer, classes.transformMe)}
                                        src={getPhotoUrl(photo, transformations)}
                                        onClick={e => openPhotoSwipeDialog(photo, photos)}
                                    />
                                </Tooltip>
                            </ImageListItem>
                        </Zoom>
                    )}
                </ul>
            </Grid>
        );
    };

    const renderDetails = (pkg: ProductPackageUX) => {

        const packagePrice = getPackagePrice(pkg);
        const priceStr = packagePrice ? formatDinero(packagePrice) : null;
        const [, maxListPrice] = calculateListPriceRangeOfPackage(pkg.items);
        // only show discount if is_discount_display === true and price is a discount from list price
        const showDiscount = pkg.is_discount_displayed && packagePrice !== null
            && maxListPrice.greaterThan(packagePrice);
        const discountPercent = showDiscount && packagePrice ?
            calculatePackagePercentDiscount(packagePrice, maxListPrice)
            : null;

        const showPrice = !isPublicGPL || pkg.show_price_on_website;

        return (
            <Grid item xs={12} className={classes.detailsGrid}>
                {showDiscount &&
                    <div className={classes.saveBadge}>
                        <Badge
                            icon={{
                                root: <LoyaltyIcon />,
                                position: 'precedent',
                                size: 18
                            }}
                            color="primary"
                            variant="extended"
                            label={discountPercent || discountPercent === 0 ? `Save ${discountPercent}%` : ''}
                            size="small"
                            fontSize={12}
                            fontWeight={400}
                        />
                    </div>
                }
                <Typography color="secondary" className={classes.heading}>
                    {pkg.name}
                </Typography>

                {priceStr && showPrice &&
                    <PriceWidget
                        priceStr={priceStr}
                        color={'secondary'}
                        size={'large'}
                    />
                }

                <Typography color="secondary" className={classes.details}>
                    {pkg.description || ''}
                </Typography>
            </Grid>
        );
    };

    const renderItems = (pkg: ProductPackageUX) => {
        const itemsCountStr = `${pkg.items.length} Item${pkg.items.length > 1 ? 's' : ''} Included in Package`;
        return (
            <div className={classes.itemUlContainer}>
                <ul className={classes.itemUl}>
                    <li className={classes.li}>
                        <Badge
                            icon={{
                                root: <PagesIcon />,
                                position: 'precedent',
                            }}
                            color="inverted"
                            variant="extended"
                            label={itemsCountStr}
                            size="small"
                            fontSize={12}
                            fontWeight={200}
                        />
                    </li>
                    {pkg.items.map((item) =>
                        <li className={classes.li} key={item.id}>
                            <Typography
                                color="secondary"
                                className={item.options.length > 1 && classes.special || undefined}
                            >
                                {getPackageItemName(item)}
                            </Typography>
                        </li>
                    )}
                </ul>
            </div>
        );
    };

    const renderFooter = () => {
        if (isBackOffice) {
            return (
                <Button
                    variant="contained"
                    className={classes.editButton}
                    onClick={() => onEditClick && onEditClick()}
                >
                    Edit Package
                </Button>
            );
        }

        if (!canUserEditContract) {
            if (!handleClickEventOnAvatar) {
                return null;
            }

            return (
                <Typography color="secondary" className={classes.restrictedFooter}>
                    <span
                        className={classes.textUnderline}
                        onClick={handleClickEventOnAvatar}
                    >
                        Contact {caseAssignee}
                    </span>
                    {isSelected ? ' to remove this package' : ' to select this package'}
                </Typography>
            );
        }

        return (
            <DisabledPermissionTooltip permission={isSelected ? Permission.REMOVE_ITEMS : Permission.ADD_ITEMS}>
                {disabled =>
                    <Button
                        disabled={disabled}
                        color="primary"
                        variant="contained"
                        className={classes.margin_8}
                        onClick={handleClick}
                    >
                        {isSelected ? 'remove' : 'select'} package
                    </Button>
                }
            </DisabledPermissionTooltip>
        );
    };

    const hasImages = productPackage.photos.length > 0;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.root}
            classes={{
                paper: classes.dialogPaper,
            }}
            style={{ zIndex }}
        >
            <DialogContent
                className={classNames(
                    classes.dialogContent,
                    isBackOffice && classes.dashedBorder
                )}
            >
                <div ref={topscrollRef} />
                <ClearIcon
                    className={classes.clearIcon}
                    onClick={handleClose}
                />
                <Grid container>
                    {hasImages && renderImages(productPackage.photos)}

                    <div
                        className={classNames(
                            classes.main,
                            !hasImages && classes.mainWithoutImages
                        )}
                    >
                        {renderDetails(productPackage)}
                        <div className={classes.divider} />
                        {renderItems(productPackage)}
                    </div>

                    {renderFooter() !== null &&
                        <Grid item xs={12} className={classes.footer}>
                            {renderFooter()}
                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withGStyles(styles)(PackageDialog);
