import Typography from '@mui/material/Typography';
import LowPriority from '@mui/icons-material/LowPriority';

import { TaskTemplateSummary } from '../../../shared/types';
import AvailableDefaultItemsDialog from '../../common/AvailableDefaultItemsDialog/AvailableDefaultItems.dialog';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    afterCareIcon: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '11px',
            fontSize: 11,
            textTransform: 'uppercase'
        }
    }
}, { name: 'AvailableTaskTemplatesDialog' });

interface Props {
    zIndex: number;
    isDialogOpen: boolean;
    selectedTaskIds: number[];
    availableTasks: TaskTemplateSummary[];
    closeDialog: () => void;
    onCreateNewTaskClick: () => void;
    onTaskTemplateAdd: (task: TaskTemplateSummary) => Promise<void>;
}

const AvailableTaskTemplatesDialog = (props: Props) => {
    const {
        zIndex,
        isDialogOpen,
        selectedTaskIds,
        availableTasks,
        closeDialog,
        onCreateNewTaskClick,
        onTaskTemplateAdd,
    } = props;

    const classes = useStyles();

    return (
        <AvailableDefaultItemsDialog
            itemType="Available Tasks"
            zIndex={zIndex + 1}
            isDialogOpen={isDialogOpen}
            selectedItemIds={selectedTaskIds}
            availableItems={availableTasks}
            createNewButtonText="Create New Task"
            onDefaultItemAdd={onTaskTemplateAdd}
            onCreateNewItemClick={onCreateNewTaskClick}
            closeDialog={closeDialog}
            getEndAdornment={(task) =>
                <>
                    {task.is_after_care &&
                        <div className={classes.afterCareIcon}>
                            <LowPriority color="primary" />
                            <Typography color="primary">
                                <span>after</span>
                                <span>care</span>
                            </Typography>
                        </div>
                    }
                </>
            }
            getTitle={(task) =>
                <ListItemText
                    primary={task.title}
                    secondary={task.subtitle}
                />
            }
        />
    );
};

export default AvailableTaskTemplatesDialog;
