import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Clear from '@mui/icons-material/Clear';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { getPhotoUrl } from '../../../../services';
import { EXPORT_BATCHREPORT_IMAGE } from '../../../../constants';
import { CloudinaryTransformationsType } from '../../../../shared/types';
import { SlideTransition } from '../../../common/Transitions';
import useFullScreen from '../../../common/hooks/useFullScreen';
import makeGStyles from '../../../../styles/makeGStyles';

const useStyles = makeGStyles((theme) => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            '@media (min-width: 960px)': {
                maxWidth: 720,
                width: '100%',
                borderRadius: 4,
                height: 'auto'
            }
        },
    },
    dialogPaper: {},
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogContent: {
        zIndex: 0,
        padding: '48px 18px 20px 18px !important',
        overflowX: 'hidden',
    },
    createNewContainer: {
        width: '100%',
        textAlign: 'right',
        '& button': {
            margin: 8
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)'
    },
    listContainer: {
        height: '66vh',
        overflowY: 'auto',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    sorryText: {
        marginTop: 20
    },
    noResultsFoundSection: {
        textAlign: 'center',
        height: '66vh',
        '@media (min-width: 960px)': {
            height: '58vh',
        },
    },
    editIcon: {
        margin: 0,
        cursor: 'pointer',
    },
    backgroundPrimary: {
        background: theme.palette.primary.main
    },
    confirmation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    unitIcon: {
        fontSize: 98,
        color: theme.palette.common.white
    },
    confirmationHeading: {
        fontWeight: 200,
        fontSize: 36,
        color: theme.palette.common.white
    },
    confirmationSubHeading: {
        fontSize: 20,
        color: theme.palette.common.white,
        paddingBottom: 12,
        fontWeight: 200,
        lineHeight: '1em',
        textAlign: 'center'
    },
    detailMessage: {
        fontSize: 18,
        textAlign: 'center',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16
    },
    imgSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px 0',
        '& img': {
            boxShadow: theme.shadows[10],
            width: '100%',
            height: 'auto',
            maxWidth: 600
        }
    },
    rootDialogAction: {
        margin: 0,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '@media (min-width: 386px)': {
            justifyContent: 'space-between',
        }
    }
}));

interface FreezeContractDialogProps {
    isBatch: boolean;
    batchSize: number;
    isDialogOpen: boolean;
    caseName: string;
    closeDialog: (doFreeze: boolean) => void;
    zIndex: number;
}

const FreezeContractDialog = (props: FreezeContractDialogProps) => {
    const {
        isBatch,
        batchSize,
        isDialogOpen,
        caseName,
        closeDialog,
        zIndex
    } = props;
    const classes = useStyles();
    const transformations: CloudinaryTransformationsType[] = [{
        width: 600,
        crop: 'limit'
    }];
    const imageUrl = getPhotoUrl(EXPORT_BATCHREPORT_IMAGE, transformations);
    const fullScreen = useFullScreen('md');
    const moreThanOne = batchSize > 1;

    const subHeadingText = () => {
        if (isBatch) {
            return `Freezing ${batchSize} statement${moreThanOne
                ? 's will move them' : ' will move it'} into a frozen state. 
                This operation could take a couple of minutes`;
        } else {
            return `Freezing ${caseName}'s statement will move it into a frozen state.`;
        }
    };


    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            onClose={() => closeDialog(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.root}
            classes={{
                paper: classes.dialogPaper,
            }}
            style={{ zIndex }}
        >
            <DialogTitle
                id="alert-dialog-slide-title"
                className={classNames(
                    classes.dialogHeader,
                    classes.backgroundPrimary
                )}
            >
                <Grid item xs={12}>
                    <Clear
                        className={classNames(classes.clearIcon)}
                        onClick={() => closeDialog(false)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.confirmation}>
                    <AcUnitIcon className={classes.unitIcon} />
                    <Typography
                        className={classes.confirmationHeading}
                    >
                        Are you sure?
                    </Typography>
                    <Typography
                        className={classes.confirmationSubHeading}
                    >
                        {subHeadingText()}
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography color="secondary" className={classes.detailMessage}>
                    Any future edits to the selected statement(s) will require them to be unfrozen,
                    which means all edits will be tracked as statement revisions as shown below:
                </Typography>

                <Grid item xs={12} className={classes.imgSection}>
                    <img src={imageUrl} />
                </Grid>
            </DialogContent>
            <DialogActions
                className={classes.dialogActions}
                classes={{ root: classes.rootDialogAction }}
            >
                <Button
                    variant="text"
                    onClick={() => closeDialog(false)}
                    color="primary"
                    size="medium"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => closeDialog(true)}
                    color="primary"
                    size="medium"
                >
                    <AcUnitIcon />
                    &nbsp;Freeze {
                        isBatch ? `${batchSize} Statement${moreThanOne ? 's' : ''}` : `${caseName}'s Statement`
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FreezeContractDialog;
