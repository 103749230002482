import {
    EntitySummary,
    GatherCasePublic,
    GatherCaseSummary,
    GatherCaseUX,
    PublicCaseAssignee,
    UserProfile,
    UserRoles
} from "../types";
import { getPublicFHfromUX } from "./funeralHome";

export const getPublicCaseAssigneeFromEntitySummary = (assignee: EntitySummary): PublicCaseAssignee => {
    return {
        fname: assignee.fname,
        lname: assignee.lname,
        email: assignee.email,
        phone: assignee.phone,
        photo: assignee.photo,
        photo_transformations: assignee.photo_transformations,
    };
};

export const getPublicCaseFromUX = (gatherCase: GatherCaseUX): GatherCasePublic => {
    const publicCase: GatherCasePublic = {
        id: gatherCase.id,
        uuid: gatherCase.uuid,
        name: gatherCase.name,
        fname: gatherCase.fname,
        lname: gatherCase.lname,
        full_name: gatherCase.full_name,
        dob_date: gatherCase.dob_date,
        dod_start_date: gatherCase.dod_start_date,
        photo: gatherCase.photo,
        photo_transformations: gatherCase.photo_transformations,
        obituary_content: gatherCase.obituary_content,
        display_full_name: gatherCase.display_full_name,
        display_fname: gatherCase.display_fname,
        link_gofundme: gatherCase.link_gofundme,
        link_memorialvideo_youtube: gatherCase.link_memorialvideo_youtube,
        link_memorialvideo_tukios: gatherCase.link_memorialvideo_tukios,
        options: gatherCase.options,
        sync: gatherCase.sync,
        desktop_cover: gatherCase.desktop_cover,
        desktop_transformations: gatherCase.desktop_transformations,
        mobile_cover: gatherCase.mobile_cover,
        mobile_transformations: gatherCase.mobile_transformations,
        desktop_cover_id: gatherCase.desktop_cover_id,
        mobile_cover_id: gatherCase.mobile_cover_id,
        theme: gatherCase.theme,
        funeral_home_id: gatherCase.funeral_home_id,
        funeral_home: getPublicFHfromUX(gatherCase.funeral_home),
        belongings_count: gatherCase.belongings_count,
        fingerprints_count: gatherCase.fingerprints_count,
        assignee: getPublicCaseAssigneeFromEntitySummary(gatherCase.assignee),
        keeptrack_finalized_time: gatherCase.keeptrack_finalized_time,
        zero_expense: gatherCase.zero_expense,
        zero_balance: gatherCase.zero_balance,
    };

    return publicCase;
};

export const getCaseSummaryFromUX = (gatherCase: GatherCaseUX): GatherCaseSummary => {
    const summary: GatherCaseSummary = {
        id: gatherCase.id,
        uuid: gatherCase.uuid,
        name: gatherCase.name,
        fname: gatherCase.fname,
        lname: gatherCase.lname,
        gender: gatherCase.gender,
        dob_date: gatherCase.dob_date,
        dod_start_date: gatherCase.dod_start_date,
        photo: gatherCase.photo,
        photo_transformations: gatherCase.photo_transformations,
        dc_residence: gatherCase.dc_residence,
        labels: gatherCase.labels,
        funeral_home_name: `${gatherCase.funeral_home.name} (${gatherCase.funeral_home.city})`,
        funeral_home_id: gatherCase.funeral_home.id,
        owner_funeral_home_id: gatherCase.owner_funeral_home_id,
        keeptrack_key: gatherCase.keeptrack_key,
        assignee: gatherCase.assignee,
        workflow_id: gatherCase.workflow_id,
        workflow_name: gatherCase.workflow_name,
        current_task_location: !gatherCase.current_task_location
            ? null
            : {
                  id: gatherCase.current_task_location.id,
                  name: gatherCase.current_task_location.name,
                  moved_time: gatherCase.current_task_location.moved_time,
              },
        case_number: gatherCase.case_number,
        case_type: gatherCase.case_type,
        created_time: gatherCase.created_time,
        funeral_home_key: gatherCase.funeral_home.key,
        funeral_home_case_id: gatherCase.funeral_home_case_id,
        funeral_home_address1: gatherCase.funeral_home.address1,
    };

    return summary;
};

export const isValidUUID = (uuid: string | undefined): boolean => {
    if (uuid) {
        return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(uuid);
    } else {
        return false;
    }
};

export const encodeFHCaseId = (params: { id: number }): string | null => {
    // We limit the value to a zero padded 8 char hex value
    const maxCharCount = 8;
    const maxNumber = parseInt('0xffffffff', 16);
    if (!params?.id || params.id < 1) {
        console.warn(`ERROR: missing or invalid param gatherCaseId.`);
        return null;
    }
    if (params.id > maxNumber) {
        console.warn(`ERROR: param gatherCaseId is Too Large (> ${maxNumber}).`);
        return null;
    }
    const hexStr = params.id.toString(16);
    return hexStr.padStart(maxCharCount, '0');
};

export const decodeHexId = (params: { hexId: string }): number | null => {
    // We limit the value to a zero padded 8 char hex value
    const maxCharCount = 8;
    const re = new RegExp(`^[0-9A-Fa-f]{${maxCharCount}}$`);
    if (!params?.hexId || params.hexId.length > maxCharCount || !re.test(params.hexId)) {
        console.warn(`ERROR: missing or invalid param hexId.`);
        return null;
    }
    return parseInt('0x' + params.hexId, 16);
};

export const getDirectCaseUrl = (params: { funeralHomeCaseAssocId: number }): string | null => {
    const { REACT_APP_APP_URL } = process.env;
    const { funeralHomeCaseAssocId } = params;
    if (!funeralHomeCaseAssocId) {
        console.warn(`ERROR: misisng caseId`);
        return null;
    }
    const hexCaseId = encodeFHCaseId({ id: funeralHomeCaseAssocId });
    if (REACT_APP_APP_URL && hexCaseId) {
        const caseUrl = `${REACT_APP_APP_URL}/h/${hexCaseId}`;
        // const caseUrl = `https://my.gather.app/h/${hexCaseId}`;
        // const caseUrl = `https://my.gatherqa.app/h/FFFFFFFF`;
        // const caseUrl = `https://my.gather.app/h/FFFFFFFF`;
        return caseUrl;
    } else {
        console.warn(`ERROR: missing REACT_APP_APP_URL or invalid CaseID`);
        return null;
    }
};

export const isCaseAdmin = (params: {
    entity: EntitySummary | UserProfile | null;
    gatherCase: Pick<GatherCaseUX, 'id' | 'funeral_home_id'>;
}) => {
    return UserRoles.isFamilyAdmin(params.entity, params.gatherCase.id)
        || UserRoles.isFHorGOMUserOnFH(params.entity, params.gatherCase.funeral_home_id);
};
