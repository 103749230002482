import * as classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import { convertHexToRGBA } from '../../services';
import ChangeViewGSwitch from '../family/ChangeViewGSwitch';
import makeGStyles from '../../styles/makeGStyles';

const useStyles = makeGStyles(theme => ({
    root: {},
    familyViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px 8px 32px',
        color: theme.palette.primary.main,
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.20),
        '& p:not(:last-child)': {
            padding: '4px 0px 16px',
        },
        '& p:last-child': {
            padding: '16px 0 4px'
        }
    },
}));

interface Props {
    text: string;
    icon: JSX.Element;
    containerClass?: string;
    footerText?: JSX.Element | string;
}

const TabsInFamilyView = (props: Props) => {
    const { containerClass, text, icon, footerText } = props;
    const classes = useStyles();

    return (
        <Grid container={true} className={classNames(classes.familyViewContainer, containerClass)}>
            <SvgIcon color="primary" className={classes.fontSize30}>
                {icon}
            </SvgIcon>

            <Typography color="primary">{text}</Typography>

            <ChangeViewGSwitch />

            {footerText &&
                <Typography color="primary">
                    {footerText}
                </Typography>
            }
        </Grid>
    );
};

export default TabsInFamilyView;
