import styled from "@mui/material/styles/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GREEN_COLOR, RED_COLOR } from "../../../../constants/colorVariables";
import { PropsWithChildren } from "react";
import Tooltip from "@mui/material/Tooltip";
import isEmpty from "lodash/isEmpty";
import { SxProps, Theme } from "@mui/material/styles";

interface RootProps {
    hasMissingField: boolean;
    hasNoData: boolean;
}
const Root = styled(
    Grid,
    { shouldForwardProp: propName => propName !== 'hasMissingField' && propName !== 'hasNoData' }
)<RootProps>(({ hasMissingField, hasNoData, theme }) => ({
    position: 'relative',
    borderRadius: 12,
    borderWidth: 2,
    borderStyle: hasNoData ? 'dashed' : 'solid',
    borderColor: hasNoData
        ? theme.palette.primary.main
        : hasMissingField
            ? RED_COLOR
            : GREEN_COLOR,
}));

const LabelContainer = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    padding: '0 8px',
}));

interface TextProps {
    hasNoText: boolean;
}
const Text = styled(
    'span',
    { shouldForwardProp: propName => propName !== 'hasNoText' }
)<TextProps>(({ hasNoText }) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    ...(hasNoText ? {
        opacity: 0.6,
        fontStyle: 'italic',
        '&:hover': {
            textDecoration: 'underline !important',
            opacity: 1
        }
    } : undefined)
}));

export const DISPLAY_FIELD_LOOKUP = {
    'fname': 'First Name',
    'mname': 'Middle Name',
    'lname': 'Last Name',
    'full_name': 'Full Name',
    'home_address': 'Home Address',
    'phone': 'Phone Number',
};

interface Props<T extends object = object> {
    hasMissingField: boolean;
    label: string | null;
    data: T | null;
    tooltipText?: string;
    fieldsToDisplay: (keyof T)[];
    requiredFields: (keyof T)[];
    sx?: SxProps<Theme>;
    onEdit?: (event: React.MouseEvent<HTMLSpanElement>, field: keyof T) => void;
    valueMapper?: (key: keyof T) => string | null;
    fieldMapper?: (key: keyof T) => string | null;
}

const DCEntityDetails = <T extends object>(props: PropsWithChildren<Props<T>>) => {
    const {
        hasMissingField,
        label,
        children,
        fieldsToDisplay,
        requiredFields,
        tooltipText,
        data,
        sx,
        onEdit,
        valueMapper,
        fieldMapper
    } = props;

    const renderEditableText = (text: T[keyof T] | string | null, currentField: keyof T) => {
        if (!onEdit) {
            return text;
        }

        return (
            <Text
                hasNoText={!text}
                onClick={e => onEdit(e, currentField)}
            >
                {text || '+ Click to Add'}
            </Text>
        );
    };

    const hasNoData = isEmpty(data);

    return (
        <Root
            item
            xs={12}
            container={hasNoData}
            justifyContent={hasNoData ? 'center' : undefined}
            alignItems={hasNoData ? 'center' : undefined}
            hasMissingField={hasMissingField}
            hasNoData={hasNoData}
            sx={sx}
        >
            {children}

            <LabelContainer item>
                {label &&
                    <Typography noWrap color={hasMissingField ? RED_COLOR : GREEN_COLOR}>
                        {label}
                    </Typography>
                }
            </LabelContainer>

            {data &&
                <Grid m="10px">
                    {fieldsToDisplay.map(field => {
                        const isRequired = requiredFields.find(requiredField => requiredField === field);

                        const text = valueMapper ? valueMapper(field) : data[field];
                        const displayField = fieldMapper
                            ? fieldMapper(field)
                            : (DISPLAY_FIELD_LOOKUP as T)[field];

                        return (
                            <Tooltip
                                title={tooltipText}
                                placement="top"
                                enterDelay={600}
                                key={field.toString()}
                            >
                                <Typography
                                    color={isRequired && !text ? RED_COLOR : 'secondary'}
                                    align="left"
                                    display="flex"
                                >
                                    <span >
                                        {displayField}{isRequired && '*'}:&nbsp;
                                    </span>
                                    <span>
                                        {renderEditableText(text, field)}
                                    </span>
                                </Typography>
                            </Tooltip>
                        );
                    })}
                </Grid>
            }
        </Root>
    );
};

export default DCEntityDetails;
