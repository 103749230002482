import {
    KeepTrackFilterType,
    KeepTrackReportResponse,
    KeepTrackReportTaskItemUX
} from "../shared/types";
import { AppDispatch } from "../store";
import { log } from "../logger";
import { advancedAPIRequest, postToAPI } from "./index";

export const LOADING_KEEP_TRACK_REPORT = 'LOADING_KEEP_TRACK_REPORT';
interface LoadingKeepTrackReport {
    type: typeof LOADING_KEEP_TRACK_REPORT;
}

export function loadingKeepTrackReport(): LoadingKeepTrackReport {
    return {
        type: LOADING_KEEP_TRACK_REPORT,
    };
}

export const LOADED_KEEP_TRACK_REPORT = 'LOADED_KEEP_TRACK_REPORT';
interface LoadedKeepTrackReport {
    type: typeof LOADED_KEEP_TRACK_REPORT;
    report: KeepTrackReportResponse;
}

export function loadedKeepTrackReport(
    report: KeepTrackReportResponse,
): LoadedKeepTrackReport {
    return {
        type: LOADED_KEEP_TRACK_REPORT,
        report,
    };
}

export function loadKeepTrackReport(params: {
    funeralHomeId: number;
    sortBy: keyof KeepTrackReportTaskItemUX;
    sortDirection: 'asc' | 'desc';
    searchText: string;
    limit: number;
    offset: number;
    filters: KeepTrackFilterType;
    initialLoad: boolean;
}) {
    return async (dispatch: AppDispatch): Promise<KeepTrackReportResponse | null> => {
        const {
            funeralHomeId,
            sortBy,
            sortDirection,
            searchText,
            limit,
            offset,
            filters,
            initialLoad
        } = params;

        try {
            KeepTrackFilterType.fromRequest(filters);
        } catch (error) {
            log.warn('Failed to validate filters', { filters, error });
            return null;
        }
        dispatch(loadingKeepTrackReport());
        let route = `funeralhome/${funeralHomeId}/keeptrackreport?` +
            `offset=${offset}` +
            `&limit=${limit}` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}` +
            `&searchText=${encodeURIComponent(searchText)}` +
            `${initialLoad ? '&initialLoad' : ''}`;

        const response = await postToAPI<KeepTrackReportResponse>(route, { filters }, dispatch);
        if (!response) {
            log.warn('Failed to download report data', {
                funeralHomeId,
                sortBy,
                sortDirection,
                searchText,
                limit,
                offset,
                filters,
                initialLoad
            });
            return null;
        }
        dispatch(loadedKeepTrackReport(response));
        return response;
    };
}

export function printKeepTrackReport(params: {
    funeralHomeId: number;
    sortBy: keyof KeepTrackReportTaskItemUX;
    sortDirection: 'asc' | 'desc';
    searchText: string;
    filters: KeepTrackFilterType;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const {
            funeralHomeId,
            sortBy,
            sortDirection,
            searchText,
            filters,
        } = params;

        try {
            KeepTrackFilterType.fromRequest(filters);
        } catch (error) {
            log.warn('Failed to validate filters', { filters, error });
            return;
        }
        let route = `funeralhome/${funeralHomeId}/keeptrackreport/csv?` +
            `&sortBy=${encodeURIComponent(sortBy)}` +
            `&sortDir=${sortDirection}` +
            `&searchText=${encodeURIComponent(searchText)}`;

        const headersOverride: Record<string, string> = {
            Accept: 'text/csv; charset=utf-8'
        };
        const response = await advancedAPIRequest(
            route,
            'POST',
            { filters },
            dispatch,
            headersOverride
        );
        if (!response) {
            log.warn('Failed to download csv file', {
                funeralHomeId,
                sortBy,
                sortDirection,
                searchText,
                filters,
            });
            return;
        }
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'keeptrack_report.csv';
        link.click();
        setTimeout(() => URL.revokeObjectURL(link.href), 0);
    };
}

export type KeepTrackReportAction =
    | LoadingKeepTrackReport
    | LoadedKeepTrackReport
    ;

