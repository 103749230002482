import { registerAppError } from '../errors';
import {
    ProductSupplierRequest,
    ProductSupplierRecord,
    ProductSupplierUX,
    ProductCategory,
    ProductSupplierUXWithCategory
} from '../../shared/types';
import { postToAPI, putToAPI, deleteFromAPI, getFromAPI } from '..';
import { setSnackbarSuccess } from '../AppSnackbar.action';
import { AppDispatch } from '../../store';

const productSnackbarSuccess = (msg: string) => setSnackbarSuccess(msg, undefined, 2000);

export const SET_PRODUCT_SUPPLIERS = 'SET_PRODUCT_SUPPLIERS';
export type SET_PRODUCT_SUPPLIERS = typeof SET_PRODUCT_SUPPLIERS;
export interface SetProductSuppliers {
    type: SET_PRODUCT_SUPPLIERS;
    productSuppliers: ProductSupplierUX[];
}

export function setProductSuppliersInStore(productSuppliers: ProductSupplierUX[]): SetProductSuppliers {
    return {
        type: SET_PRODUCT_SUPPLIERS,
        productSuppliers,
    };
}

export const SET_PRODUCT_SUPPLIER = 'SET_PRODUCT_SUPPLIER';
export type SET_PRODUCT_SUPPLIER = typeof SET_PRODUCT_SUPPLIER;
interface SetProductSupplier {
    type: SET_PRODUCT_SUPPLIER;
    supplierId: number;
    productSupplier: ProductSupplierUX;
}

function setProductSupplierInStore(supplierId: number, productSupplier: ProductSupplierUX): SetProductSupplier {
    return {
        type: SET_PRODUCT_SUPPLIER,
        supplierId,
        productSupplier,
    };
}

export const ADD_PRODUCT_SUPPLIER = 'ADD_PRODUCT_SUPPLIER';
export type ADD_PRODUCT_SUPPLIER = typeof ADD_PRODUCT_SUPPLIER;
interface AddProductSupplier {
    type: ADD_PRODUCT_SUPPLIER;
    productSupplier: ProductSupplierUX;
}

function addProductSupplierInStore(productSupplier: ProductSupplierUX): AddProductSupplier {
    return {
        type: ADD_PRODUCT_SUPPLIER,
        productSupplier,
    };
}

export const REMOVE_PRODUCT_SUPPLIER = 'REMOVE_PRODUCT_SUPPLIER';
export type REMOVE_PRODUCT_SUPPLIER = typeof REMOVE_PRODUCT_SUPPLIER;
interface RemoveProductSupplier {
    type: REMOVE_PRODUCT_SUPPLIER;
    supplierId: number;
}

function removeProductSupplierInStore(supplierId: number): RemoveProductSupplier {
    return {
        type: REMOVE_PRODUCT_SUPPLIER,
        supplierId,
    };
}
export function loadProductSuppliers() {
    return async (dispatch: AppDispatch): Promise<ProductSupplierUX[] | null> => {
        const suppliers = await getFromAPI<ProductSupplierUX[]>(
            'api/productsupplier', dispatch
        );
        if (suppliers) {
            dispatch(setProductSuppliersInStore(suppliers));
        }
        return suppliers;
    };
}

// ------> Product Supplier <------ \\
export function createProductSupplier(supplier: ProductSupplierRequest) {
    return async (dispatch: AppDispatch): Promise<ProductSupplierRecord | null> => {
        try {
            ProductSupplierRequest.fromRequest(supplier);
        } catch (ex) {
            console.warn('Failed to validate ProductSupplierRequest:', supplier, ex);
            return null;
        }
        const newId = 0 - Math.round(Math.random() * 10000);
        dispatch(addProductSupplierInStore({
            ...supplier,
            id: newId,
        }));
        const createdSupplier = await postToAPI<ProductSupplierUX>(
            'api/productsupplier/', { supplier }, dispatch
        );
        if (createdSupplier) {
            dispatch(setProductSupplierInStore(newId, createdSupplier));
            dispatch(productSnackbarSuccess('Supplier created'));
            return createdSupplier;
        } else {
            dispatch(removeProductSupplierInStore(newId));
        }
        dispatch(registerAppError('Unable to create supplier.'));
        return null;
    };
}

/**
 * updateProductSupplier  
 *
 * @param supplier 
 * @param supplierUpdateRequest 
 * 
 * given a SupplierUX Record (id, name, [ photos: {public_id, youtube}])
 */

export function updateProductSupplier(supplier: ProductSupplierUX, supplierUpdateRequest: ProductSupplierRequest) {
    return async (dispatch: AppDispatch): Promise<ProductSupplierUX | null> => {
        try {
            ProductSupplierRequest.fromRequest(supplierUpdateRequest);
        } catch (ex) {
            console.warn('Failed to validate ProductSupplierRequest:', supplierUpdateRequest, ex);
            return null;
        }
        const updatedRecord: ProductSupplierUX = {
            ...supplier,
            ...supplierUpdateRequest,
        };
        dispatch(setProductSupplierInStore(supplier.id, updatedRecord ));
        const updatedSupplier = await putToAPI<ProductSupplierUX>(
            `api/productsupplier/${supplier.id}`, { supplierUpdateRequest }, dispatch
        );
        if (updatedSupplier) {
            dispatch(productSnackbarSuccess('Supplier updated'));
            return updatedSupplier;
        }
        dispatch(setProductSupplierInStore(supplier.id, supplier ));
        dispatch(registerAppError('Unable to update supplier.'));
        return null;
    };
}

/**
 * removeProudctSupplier
 *
 * @param supplier 
 */
export function removeProductSupplier(supplier: ProductSupplierUX) {
    return async (dispatch: AppDispatch): Promise<ProductSupplierRecord | null> => {
        dispatch(removeProductSupplierInStore(supplier.id));
        const removedSupplier = await deleteFromAPI<ProductSupplierRecord>(
            `api/productsupplier/${supplier.id}`, dispatch
        );
        if (removedSupplier) {
            dispatch(productSnackbarSuccess('Supplier removed'));
            return removedSupplier;
        } else {
            dispatch(addProductSupplierInStore(supplier));
        }
        dispatch(registerAppError('Unable to remove supplier.'));
        return null;
    };
}

/* ******************************
 * FuneralHome Supplier Actions
 * ******************************
 */
export const SET_FUNERALHOME_PRODUCT_SUPPLIERS = 'SET_FUNERALHOME_PRODUCT_SUPPLIERS';
export type SET_FUNERALHOME_PRODUCT_SUPPLIERS = typeof SET_FUNERALHOME_PRODUCT_SUPPLIERS;
interface SetFuneralHomeProductSuppliers {
    type: SET_FUNERALHOME_PRODUCT_SUPPLIERS;
    suppliers: ProductSupplierUXWithCategory[];
}

function setFuneralHomeProductSuppliersInStore(
    suppliers: ProductSupplierUXWithCategory[]
): SetFuneralHomeProductSuppliers {
    return {
        type: SET_FUNERALHOME_PRODUCT_SUPPLIERS,
        suppliers,
    };
}

export const ADD_FUNERALHOME_PRODUCT_SUPPLIER = 'ADD_FUNERALHOME_PRODUCT_SUPPLIER';
export type ADD_FUNERALHOME_PRODUCT_SUPPLIER = typeof ADD_FUNERALHOME_PRODUCT_SUPPLIER;
interface AddFuneralHomeProductSupplier {
    type: ADD_FUNERALHOME_PRODUCT_SUPPLIER;
    funeralHomeProductSupplier: ProductSupplierUXWithCategory;
}

function addFuneralHomeProductSupplierInStore(
    funeralHomeProductSupplier: ProductSupplierUXWithCategory
): AddFuneralHomeProductSupplier {
    return {
        type: ADD_FUNERALHOME_PRODUCT_SUPPLIER,
        funeralHomeProductSupplier,
    };
}

export const REMOVE_FUNERALHOME_PRODUCT_SUPPLIER = 'REMOVE_FUNERALHOME_PRODUCT_SUPPLIER';
export type REMOVE_FUNERALHOME_PRODUCT_SUPPLIER = typeof REMOVE_FUNERALHOME_PRODUCT_SUPPLIER;
interface RemoveFuneralHomeProductSupplier {
    type: REMOVE_FUNERALHOME_PRODUCT_SUPPLIER;
    funeralHomeProductSupplier: ProductSupplierUXWithCategory;
}

function removeFuneralHomeProductSupplierInStore(
    funeralHomeProductSupplier: ProductSupplierUXWithCategory
): RemoveFuneralHomeProductSupplier {
    return {
        type: REMOVE_FUNERALHOME_PRODUCT_SUPPLIER,
        funeralHomeProductSupplier,
    };
}

/**
 * loadFuneralHomeSuppliers
 * 
 */

export function loadFuneralHomeSuppliers(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductSupplierUXWithCategory[] | null> => {
        const suppliers = await getFromAPI<ProductSupplierUXWithCategory[]>(
            `funeralhome/${funeralHomeId}/productsupplier`, dispatch
        );
        if (suppliers) {
            dispatch(setFuneralHomeProductSuppliersInStore(suppliers));
        }
        return suppliers;
    };
}

/**
 * addFuneralHomeSupplier
 *
 * @param funeralHomeId 
 * @param supplier 
 * @param supplierCategory 
 */
export function addFuneralHomeSupplier(
    funeralHomeId: number,
    supplier: ProductSupplierUX,
    supplierCategory: ProductCategory) {
    return async (dispatch: AppDispatch): Promise<ProductSupplierUXWithCategory[] | null> => {
        dispatch(addFuneralHomeProductSupplierInStore({
            ...supplier,
            category: supplierCategory,
        }));
        const updatedSuppliers = await putToAPI<ProductSupplierUXWithCategory[]>(
            `funeralhome/${funeralHomeId}/productsupplier/${supplier.id}/category/${supplierCategory}`, {},
            dispatch
        );
        dispatch(setFuneralHomeProductSuppliersInStore(updatedSuppliers || []));
        return updatedSuppliers;
    };
}

/**
 * removeFuneralHomeSupplier
 *
 * @param funeralHomeId 
 * @param supplier 
 * @param supplierCategory 
 */
export function removeFuneralHomeSupplier(
    funeralHomeId: number,
    supplierToUnlink: ProductSupplierUXWithCategory,
    supplierCategory: ProductCategory,
) {
    return async (dispatch: AppDispatch): Promise<ProductSupplierUXWithCategory[] | null> => {
        dispatch(removeFuneralHomeProductSupplierInStore(supplierToUnlink));
        const updatedSuppliers = await deleteFromAPI<ProductSupplierUXWithCategory[]>(
            `funeralhome/${funeralHomeId}/productsupplier/${supplierToUnlink.id}/category/${supplierCategory}`,
            dispatch
        );
        if (updatedSuppliers) {
            dispatch(setFuneralHomeProductSuppliersInStore(updatedSuppliers || []));
        }
        return updatedSuppliers;
    };
}

export type ProductSupplierAction =
    | SetProductSuppliers
    | SetProductSupplier
    | AddProductSupplier
    | RemoveProductSupplier
    | SetFuneralHomeProductSuppliers
    | AddFuneralHomeProductSupplier
    | RemoveFuneralHomeProductSupplier
    ;