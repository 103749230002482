import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';

import HiddenFromFamilyAdornment from '../../HiddenFromFamilyAdornment';
import makeGStyles from '../../../../../../styles/makeGStyles';

export interface Props<T extends string> {
    id: string;
    label: string;
    options: T[];
    selectedOption: T | null;
    zIndex: number;
    error?: boolean;
    disabled?: boolean;
    required?: boolean;
    displayLookup?: Record<T, string>;
    helperElement?: JSX.Element;
    rootClass?: string;
    inputLabelClass?: string;
    selectMenuClass?: string;
    isHiddenFromFamily?: boolean;
    validator: () => void;
    onSelect: (option: T) => void;
    onClick?: () => void;
}

const useStyles = makeGStyles({
    root: {
        width: '100%',
        textAlign: 'left',
        marginTop: 20,
    },
}, { name: 'DCSelect' });

const DCSelect = <T extends string>(props: Props<T>) => {
    const {
        id,
        error,
        disabled,
        required,
        options,
        selectedOption,
        zIndex,
        displayLookup,
        helperElement,
        label,
        rootClass,
        inputLabelClass,
        selectMenuClass,
        isHiddenFromFamily,
        validator,
        onSelect,
        onClick,
    } = props;

    const classes = useStyles();

    return (
        <FormControl
            className={classNames(classes.root, rootClass)}
            required={required}
            error={error}
        >
            <InputLabel
                htmlFor={id}
                className={inputLabelClass}
                variant="standard"
            >
                {label}
            </InputLabel>
            <Select
                value={selectedOption || ''}
                required={required}
                onChange={(e) => onSelect(e.target.value as T)}
                disabled={disabled}
                onClick={onClick}
                MenuProps={{ style: { zIndex } }}
                inputProps={{
                    id,
                    className: classNames(!selectedOption && 'notranslate'),
                }}
                endAdornment={
                    <HiddenFromFamilyAdornment
                        showMe={isHiddenFromFamily}
                        useAdornmentComponent
                    />
                }
                classes={{ select: classNames(classes.themeSelectMenu, selectMenuClass) }}
                onBlur={validator}
                autoComplete="new-password"
                error={error}
            >
                {options.map((option: T) => (
                    <MenuItem value={option} key={option}>
                        {displayLookup ? displayLookup[option] : option}
                    </MenuItem>
                ))}
            </Select>
            {helperElement}
        </FormControl>
    );
};

export default DCSelect;
