import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import GPopper from '../../../../common/GPopper';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    popper: {
        width: '100%',
        maxWidth: 220,
        '&[data-popper-placement*="bottom"]': {
            '& $popoverPaper': {
                top: 12
            }
        },
        '&[data-popper-placement*="top"]': {
            '& $popoverPaper': {
                top: -12
            }
        },
    },
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
        height: 'auto',
        position: 'relative',
    },
    footerContainer: {
        marginTop: 24,
        minHeight: 64,
        padding: '0px 16px 16px',
        '& p': {
            lineHeight: '1.25em',
            fontSize: 12
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        padding: '8px 10px',
        fontWeight: 'bolder'
    },
    taxationSection: {
        padding: '10px 20px',
        '& p > span': {
            fontWeight: 'bolder'
        },
    }
});

type StyledProps = Props & WithGStyles<'root' | 'popper' | 'popoverPaper' | 'footerContainer' | 'header'
    | 'taxationSection'>;

interface Props {
    anchorEle: HTMLElement | null;
    onClose: () => void;
    zIndex: number;
    taxRate: string | null;
    taxAmount: string | null;
    taxCounty: string | null;
    isPackage: boolean;
}

class ProductTaxDetailPopper extends Component<StyledProps> {

    popperFooter = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.footerContainer}>
                <Typography>
                    This item is in a package. Tax on packaged items is calculated after
                    the package discount has been applied.
                </Typography>
            </Grid>
        );
    };

    texationSection = () => {
        const { classes, taxRate, taxAmount, taxCounty } = this.props;
        return (
            <Grid item xs={12} className={classes.taxationSection}>
                <Typography>
                    <span>Tax County:</span> {taxCounty}
                </Typography>
                <Typography>
                    <span>Tax Rates:</span> {taxRate}
                </Typography>
                <Typography>
                    <span>Tax Amount:</span> {taxAmount}
                </Typography>
            </Grid>
        );
    };

    headerText = () => {
        const { classes } = this.props;

        return (
            <Typography align="center" color="secondary" className={classes.header}>
                THIS ITEM IS TAXABLE
            </Typography>
        );
    };

    render() {
        const {
            classes,
            anchorEle,
            zIndex,
            isPackage,
            onClose
        } = this.props;

        return (
            <GPopper
                zIndex={zIndex}
                anchorEle={anchorEle}
                className={classes.popper}
                paperClass={classes.popoverPaper}
                closePopper={e => onClose()}
                placement='bottom'
            >
                {this.headerText()}
                {this.texationSection()}
                {isPackage && this.popperFooter()}
            </GPopper>
        );
    }
}

export default withGStyles(styles)(ProductTaxDetailPopper);
