import { putToAPI } from '.';
import {
    EntitySubscription,
    EntitySubscriptionUpdateRequest,
} from '../shared/types/subscription';
import { AppDispatch } from '../store';


export function updateCaseEntitySubscription(args: {
    entityId: number;
    uuid: string;
    notify: boolean;
}
) {
    return async (dispatch: AppDispatch) => {
        const path = `api/entity/${args.entityId}/subscription/${args.uuid}`;
        const subscriptionUpdate: EntitySubscriptionUpdateRequest = {
            uuid: args.uuid,
            deleted_time: args.notify ? null : new Date(),
        };
        const response = await putToAPI<EntitySubscription>(path, {subscriptionUpdate}, dispatch,);

        if (response) {
            // dispatch(setEntitySubscription(response));
            return true;
        }

        return false; // Add a default return statement
    };
};

export { };