import { useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import makeGStyles from '../../../../../../styles/makeGStyles';
import { alpha } from '@mui/material/styles';

import StepComponentWrapper from '../StepComponentWrapper';
import { NoteComponentConfig } from '../../../../../../shared/types';
import { OnAnswerChangedFunction, OnValidityChangedFunction } from './types';
import useDebounce from '../../../../../common/hooks/useDebounce';
import { getIntercomTargetProp } from '../../../../../../services';

const useStyles = makeGStyles(theme => ({
    footerButton: {
        padding: 2,
        fontSize: 13,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '0 0 8px 8px',
        margin: '0 24px',
        background: alpha(theme.palette.primary.main, 0.20),
        '& svg': {
            fontSize: 16,
            marginRight: 4
        },
    },
}), { name: 'NoteComponent' });

interface Props {
    config: NoteComponentConfig | null;
    componentId: number;
    saveAttempted: boolean;
    isDisabled?: boolean;
    onValidityChanged: OnValidityChangedFunction;
    onAnswerChanged: OnAnswerChangedFunction;
    intercomTargetProp?: string;
}

const NoteComponent = (props: Props) => {
    const {
        config,
        componentId,
        saveAttempted,
        isDisabled,
        onValidityChanged,
        onAnswerChanged,
        intercomTargetProp,
    } = props;

    const classes = useStyles();

    const label = config?.note_instructions;
    const isRequired = config?.is_required;

    const [note, setNote] = useState<string | null>(config?.answer ?? null);

    const isValid = useMemo(() => {
        if (isRequired) {
            return Boolean(note?.trim());
        } else {
            return true;
        }
    }, [isRequired, note]);

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    const debouncedAnswerChanged = useDebounce(onAnswerChanged);

    useEffect(() => {
        if (note !== null) {
            debouncedAnswerChanged(componentId, note);
        }
    }, [debouncedAnswerChanged, componentId, note]);

    return (
        <StepComponentWrapper
            hideBorder
            error={!isValid && saveAttempted}
            success={isValid}
            className={classes.padding_0_12}
        >
            <TextField
                label={label}
                required={isRequired}
                error={!isValid && saveAttempted}
                value={note || ''}
                onChange={({ currentTarget }) => setNote(currentTarget.value)}
                multiline={true}
                minRows={3}
                maxRows={12}
                fullWidth={true}
                variant="outlined"
                disabled={isDisabled}
                {...getIntercomTargetProp(intercomTargetProp)}
            />

            <Typography color="primary" className={classes.footerButton}>
                <VisibilityOffIcon color="primary" />
                Never visible to family. Will show in task notes.
            </Typography>
        </StepComponentWrapper>
    );
};

export default NoteComponent;
